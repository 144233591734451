import React from "react";
import { useExpandReportList } from "../hooks/useExpandReportList";
import ReportList from "../components/ReportList";
import { withGlobalSettings } from "../../../hocs/withGlobalSettings";

const ReportsList = ({ globalSettings }) => {
  const {
    expandedList,
    updateExpandedList,
    salesReports,
    paymentsReports,
    membershipsReports,
  } = useExpandReportList(globalSettings);

  return (
    <div className="flex-col gap-8">
      <ReportList
        list="sales"
        reports={salesReports}
        expandedList={expandedList}
        updateExpandedList={updateExpandedList}
      />
      <ReportList
        list="payments"
        reports={paymentsReports}
        expandedList={expandedList}
        updateExpandedList={updateExpandedList}
      />
      <ReportList
        list="memberships"
        reports={membershipsReports}
        expandedList={expandedList}
        updateExpandedList={updateExpandedList}
      />
    </div>
  );
};

export default withGlobalSettings(ReportsList);
