import React from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Group } from "../../shared/Group/Group";
import { Select } from "../../../../../../shared/Select/Select";
import { valueToSelectOption } from "../../MedicalHistory.utils";
import { Labeled } from "../../shared/Labeled/Labeled";

export function PatientInfo({ height, weight, onChange, options }) {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();

  return (
    <Group row compact label={tClients("medicalHistory.patientInfo.label")}>
      {options.height.length > 0 && (
        <Labeled label={tCommon("label.height")}>
          <Select
            size="small"
            value={valueToSelectOption(height)}
            options={options.height.map(valueToSelectOption)}
            onChange={(nextOption) => onChange("height", nextOption.value)}
          />
        </Labeled>
      )}
      {options.weight.length > 0 && (
        <Labeled label={tCommon("label.weight")}>
          <Select
            size="small"
            value={valueToSelectOption(weight)}
            options={options.weight.map(valueToSelectOption)}
            onChange={(nextOption) => onChange("weight", nextOption.value)}
          />
        </Labeled>
      )}
    </Group>
  );
}
