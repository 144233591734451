import { useDeleteFreeTaxableFileMutation } from "../../../../../../api/mutations/useDeleteFreeTaxableFileMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { isApiFile } from "../utilities";

export function useDeleteApiFile(removeFile) {
  const del = useDeleteFreeTaxableFileMutation({
    onSuccess: () => {
      removeFile();
      uiNotification.success("File deleted successfully");
    },
    onError: () => {
      uiNotification.error("Failed to delete file");
    },
  });

  const initiate = (file) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      if (isApiFile(file)) {
        del.mutate();
      } else {
        removeFile();
      }
    }
  };

  return {
    initiate,
    isLoading: del.isLoading,
  };
}
