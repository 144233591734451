import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  accountId: yup.number().required(),
  amount: yup.number().required(),
  invoiceId: yup.number().required(),
  deviceId: yup.number().required(),
  paymentSystem: yup.string().required(),
  transactionType: yup.string().required(),
});

export function useCheckoutPaymentMutation(options = {}) {
  return useMutation((payload) => {
    const req = requestSchema.validateSync(payload, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postCheckoutPayment(), {
      account_id: req.accountId,
      amount: req.amount,
      invoice: req.invoiceId,
      pos_device: req.deviceId,
      provider: req.paymentSystem,
      transaction_type: req.transactionType,
    });
  }, options);
}
