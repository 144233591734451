import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { useGiftCardsObOrderQuery } from "../../../../../../../api/queries/useGiftCardsObOrderQuery";

export function useGiftCards() {
  const { tSettings } = useAppTranslation.Settings();

  const { data, isLoading } = useGiftCardsObOrderQuery({
    options: {
      onError: () => {
        uiNotification.error(tSettings("giftCards.obOrder.error.fetch"));
      },
    },
  });

  const giftCards = useMemo(
    () =>
      data?.map((i) => ({
        id: i.id,
        content: i.name,
      })) || [],
    [data],
  );

  return {
    giftCards,
    isGiftCardsLoading: isLoading,
  };
}
