import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import { createQueryKeyFromShape } from "../../utilities/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

export const RETENTION_RATE_DETAILS_REPORT_ORDER_BY = {
  patient: "patient",
  mainAppointmentDateTime: "main_appt_datetime",
  mainProvider: "main_provider",
  mainServices: "main_services",
  retAppointmentDateTime: "ret_appt_datetime",
  retProvider: "ret_provider",
  retServices: "ret_services",
};

/*
  SCHEMAS
*/

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  order: yup
    .object({
      by: yup.string().required(),
      direction: yup.string().required(),
    })
    .nullable(),

  filter: yup.object({
    clinicIds: yup.array().of(yup.number()).nullable(),
    providerIds: yup.array().of(yup.number()).nullable(),
  }),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          patientName: yup.string().nullable(),
          mainAppointmentDateTime: yup.string().nullable(),
          mainProviderName: yup.string().nullable(),
          mainServices: yup.string().nullable(),
          retAppointmentDateTime: yup.string().nullable(),
          retProviderName: yup.string().nullable(),
          retServices: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

/*
  COMPOSERS
*/

const composeResponse = (res, req) => {
  const reports = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    reports: reports.map((report) => ({
      id: report.id,
      patientName: report.patient || null,
      mainAppointmentDateTime: report.main_appt_datetime || null,
      mainProviderName: report.main_provider || null,
      mainServices: report.main_services || null,
      retAppointmentDateTime: report.ret_appt_datetime || null,
      retProviderName: report.ret_provider || null,
      retServices: report.ret_services || null,
    })),
  };
};

// ---------

function createRetentionRateDetailsReportQueryKey({
  order,
  fromDate,
  toDate,
  pageSize,
  clinicIds,
  providerIds,
}) {
  return [
    QUERY_KEYS.retentionRateDetailsReport,
    order,
    fromDate,
    toDate,
    pageSize,
    clinicIds.join("-"),
    providerIds.join("-"),
  ];
}

const createKeyFromShape = (shape) =>
  createQueryKeyFromShape(removeNullishFromShape(shape));

export function useRetentionRateDetailsReportQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createRetentionRateDetailsReportQueryKey({
      order: createKeyFromShape(req.order),
      fromDate,
      toDate,
      pageSize: req.pageSize,
      clinicIds: req.filter.clinicIds || [],
      providerIds: req.filter.providerIds || [],
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.post(
        HTTP_ENDPOINTS.createRetentionRateDetailsReport(),
        removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          start_date: fromDate,
          end_date: toDate,
          sortBy: unwrapOr(() => req.order.by, null),
          order: unwrapOr(() => req.order.direction, null),
          clinic_ids: req.filter.clinicIds || null,
          provider_ids: req.filter.providerIds || null,
        }),
      );
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
