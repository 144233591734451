import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useUpdateShowSalesSummary = (id, options = {}) => {
  return useMutation(
    (dto) => http.patch(HTTP_ENDPOINTS.updateShowSalesSummary(id), dto),
    options,
  );
};
