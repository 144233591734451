import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

// Config

export const USER_LOG_DIFF_TYPE = {
  questionnaire: "questionnaire",
  consent: "consent",
};

export const USER_LOG_DIFF_ACTION = {
  add: "add",
  update: "update",
  delete: "delete",
};

// Request Schema

const requestSchema = yup.object({
  type: yup.string().required(),
  childId: yup.number().required(),
  objectId: yup.number().required(),
});

// Questionnaire Schemas

const simpleQuestionSchema = yup.object({
  id: yup.number().required(),
  question: yup.string().required(),
  question_type: yup.string().required(),
  questionnaire_id: yup.number().required(),
  question_choices: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        choice_type: yup.string().required(),
        display_labels: yup.number().nullable(),
        image: yup.string().nullable(),
        image_label: yup.string().nullable(),
        image_url: yup.string().nullable(),
        multiple_selection: yup.number().required(),
        order_by: yup.number().required(),
        question_id: yup.number().required(),
        status: yup.number().required(),
        text: yup.string().nullable(),
      }),
    )
    .required(),
});

const multiQuestionSchema = yup.object({
  id: yup.number().required(),
  description_text: yup.string().nullable(),
  question: yup.string().required(),
  question_type: yup.string().required(),
  questionnaire_id: yup.number().required(),
  procedure_template_question_option: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        order_by: yup.number().required(),
        procedure_template_question_id: yup.number().required(),
        question_option: yup.string().required(),
        questionnaire_id: yup.number().required(),
        status: yup.number().required(),
      }),
    )
    .required(),
  procedure_templates_logic: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        jump_to_question: yup.number().required(),
        procedure_question_id: yup.number().required(),
        procedure_question_option_id: yup.number().required(),
        questionnaire_id: yup.number().required(),
        type: yup.string().required(),
      }),
    )
    .required(),
});

// Response Schema By Type

const responseSchemaByType = {
  [USER_LOG_DIFF_TYPE.consent]: yup.array().of(
    yup.object({
      id: yup.number().required(),
      added_by: yup.string().required(),
      last_edited_by: yup.string().nullable(),
      consent_large_description: yup.string().nullable(),
      consent_small_description: yup.string().nullable(),
      consent_name: yup.string().required(),
      action: yup.string().required(),
      witness_signature: yup.number().required(),
      initials_per_page: yup.number().required(),
    }),
  ),
  [USER_LOG_DIFF_TYPE.questionnaire]: yup.array().of(
    yup.object({
      id: yup.number().required(),
      action: yup.string().required(),
      title: yup.string().required(),
      created_by_user: yup.string().required(),
      modified_by_user: yup.string().required(),
      simple_questions: yup.array().of(simpleQuestionSchema).required(),
      multi_questions: yup.array().of(multiQuestionSchema).required(),
    }),
  ),
};

// =======================

const composeResponse = (res, req) => {
  return responseSchemaByType?.[req.type]?.validateSync?.(
    res?.data?.data?.map((x) => ({
      ...x,
      action: x.action === "edit" ? USER_LOG_DIFF_ACTION.update : x.action,
    })),
    {
      strict: true,
    },
  );
};

const createUserLogsDiffQueryKey = (...args) => [
  QUERY_KEYS.userLogsDiff,
  ...args,
];

export function useUserLogsDiffQuery({ payload, options = {} }) {
  return useQuery(
    createUserLogsDiffQueryKey(payload.type, payload.childId, payload.objectId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.getUserLogsDiff({
          type: req.type,
          childId: req.childId,
          objectId: req.objectId,
        }),
      );
      return composeResponse(res, req);
    },
    options,
  );
}
