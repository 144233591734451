import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Modal } from "../../../../../shared/Modal/Modal";
import { ModalCreateEdit } from "./shared/ModalCreateEdit/ModalCreateEdit";
import { Header } from "./shared/Header/Header";
import { Graph } from "./shared/Graph/Graph";
import classes from "./ModalGraph.module.scss";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Empty } from "../../../../../shared/Empty/Empty";
import { patientVitals } from "../../../../../store/patients/vitals";
import { dispatch } from "../../../../../store/store";
import { getPathParams } from "../../PatientVitals.utils";
import { uiNotification } from "../../../../../services/UINotificationService";

var { selectors, actions } = patientVitals;

const mapStateToProps = (state) => ({
  isGraphMutating: selectors.selectIsGraphMutating(state),
  getGraphs: selectors.getSelectGraphs(state),
});

export const ModalGraph = connect(mapStateToProps)(
  ({ isGraphMutating, getGraphs, onClose }) => {
    var { patientId } = getPathParams();
    var graphs = getGraphs(patientId);

    var { tClients } = useAppTranslation.Clients();
    var [mutationInitialValues, setMutationInitialValues] = useState(null);

    var mutateGraph = (values) => {
      dispatch(
        actions.graphGenerate({
          patientId,
          field: values.field.value,
          startDate: values.period.from,
          endDate: values.period.to,
          id: mutationInitialValues?.id,
        }),
      ).then((status) => {
        switch (status) {
          case "success": {
            setMutationInitialValues(null);
            return uiNotification.success(
              tClients("vitals.success.generateGraph"),
            );
          }
          case "not_enough_data": {
            return uiNotification.info(
              tClients("vitals.info.emptyGeneratedGraph"),
            );
          }
          case "error": {
            return uiNotification.error(tClients("vitals.error.generateGraph"));
          }
          default: {
            return null;
          }
        }
      });
    };

    return (
      <Modal
        isOpen
        shouldCloseOnOverlayClick={false}
        size="large"
        onClose={onClose}
        header={<Header onCreate={() => setMutationInitialValues({})} />}
        contentClassName={classes.root}
      >
        {graphs.length === 0 ? (
          <Empty position="center" className={classes.empty}>
            {tClients("vitals.graph.empty")}
          </Empty>
        ) : (
          graphs.map((graph) => (
            <Graph
              key={graph.id}
              field={graph.field}
              data={graph.data}
              startDate={graph.startDate}
              endDate={graph.endDate}
              onDelete={() =>
                dispatch(actions.graphDelete({ patientId, id: graph.id }))
              }
              onEdit={() =>
                setMutationInitialValues({
                  id: graph.id,
                  field: graph.field,
                  period: {
                    from: moment.utc(graph.startDate).toDate(),
                    to: moment.utc(graph.endDate).toDate(),
                  },
                })
              }
            />
          ))
        )}
        {Boolean(mutationInitialValues) && (
          <ModalCreateEdit
            onClose={() => setMutationInitialValues(null)}
            onSubmit={mutateGraph}
            isEditMode={Boolean(mutationInitialValues.id)}
            isSubmitting={isGraphMutating}
            initialValues={mutationInitialValues}
          />
        )}
      </Modal>
    );
  },
);
