import React from "react";
import PropTypes from "prop-types";
import { svg } from "../../../../assets/svg";
import classes from "../../sass/CompleteIcon.module.scss";

export function CompleteIcon({ isCompleted }) {
  return (
    <img
      className={classes.root}
      src={isCompleted ? svg.checkCircleGreen : svg.crossCircleRed}
      width="20px"
      alt=""
    />
  );
}

CompleteIcon.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
};
