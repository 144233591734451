import * as yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { tSettings } from "../../../../../../i18n/useAppTranslation";
import { isNullish, passOr } from "../../../../../../utilities/general";
import {
  MULTI_QUESTION_LOGIC_JUMP_TYPES,
  MULTI_QUESTION_TYPES,
} from "../../../../../../api/questionnaires/config";
import {
  findLogicJumpsByQuestionId,
  getIsAllLogicJumpsFilled,
  getIsSomeLogicJumpsFilled,
} from "../MultiQuestionnaireCreateEdit.utils";
import { NodeId } from "../../../utilities";

// Config

export const INITIAL_IF_LOGIC_JUMP = {
  type: MULTI_QUESTION_LOGIC_JUMP_TYPES.jump,
  ifSelectedChoiceId: null,
  thenQuestionId: null,
};

export const INITIAL_ELSE_LOGIC_JUMP = {
  type: MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
  elseQuestionId: null,
};

const INITIAL_LOGIC_JUMPS = [INITIAL_IF_LOGIC_JUMP, INITIAL_ELSE_LOGIC_JUMP];

const YES_NO_CHOICES = [
  {
    id: NodeId.make(),
    value: "Yes",
  },
  {
    id: NodeId.make(),
    value: "No",
  },
];

const OPINION_SCALE_CHOICES = Array.from(Array(10).keys()).map((i) => ({
  id: NodeId.make(),
  value: String(i + 1),
}));

export const QUESTION_TYPES_WITH_LOGIC_JUMPS = [
  MULTI_QUESTION_TYPES.yesNo,
  MULTI_QUESTION_TYPES.singleChoice,
  MULTI_QUESTION_TYPES.opinionScale,
];

export const QUESTION_TYPES_WITH_CHOICES = [
  MULTI_QUESTION_TYPES.singleChoice,
  MULTI_QUESTION_TYPES.multiChoice,
];

export const DEFAULT_QUESTION_TYPE = MULTI_QUESTION_TYPES.textBox;

// Utilities

export function getNextInitialChoicesByQuestionType(questionType) {
  switch (questionType) {
    case MULTI_QUESTION_TYPES.yesNo:
      return YES_NO_CHOICES;
    case MULTI_QUESTION_TYPES.opinionScale:
      return OPINION_SCALE_CHOICES;
    default:
      return [];
  }
}

function getQuestionIdsWithUnfinishedLogicJumps(questions = []) {
  return questions.reduce((carry, question) => {
    const logicJumps = question?.logicJumps || [];

    if (
      getIsSomeLogicJumpsFilled(logicJumps) &&
      !getIsAllLogicJumpsFilled(logicJumps)
    ) {
      return [...carry, question.id];
    }

    return carry;
  }, []);
}

export function getNextInitialLogicJumpsByQuestionType(questionType) {
  return QUESTION_TYPES_WITH_LOGIC_JUMPS.includes(questionType)
    ? INITIAL_LOGIC_JUMPS
    : [];
}

// Schemas

function requireLogicJumpFieldSchemaIfSomeFilled(schema) {
  return yup.lazy((_, { context }) => {
    if (
      getIsSomeLogicJumpsFilled(context?.logicJumps || []) &&
      !getIsAllLogicJumpsFilled(context?.logicJumps || [])
    ) {
      return schema.required();
    }
    return schema.nullable();
  });
}

export const questionSchema = yup.object({
  id: yup.string().required(),

  question: yup
    .string()
    .required(tSettings("questionnaires.questionBuilder.questionRequired")),

  questionType: yup
    .string()
    .required(tSettings("questionnaires.questionBuilder.typeRequired")),

  isRequired: yup.bool().required(),

  description: yup.string().nullable(),

  allowCommentsOnScale: yup.bool().required(),

  logicJumps: yup.array().of(
    yup.object({
      type: yup.string().required(),
      ifSelectedChoiceId: requireLogicJumpFieldSchemaIfSomeFilled(yup.string()),
      thenQuestionId: requireLogicJumpFieldSchemaIfSomeFilled(yup.string()),
      elseQuestionId: requireLogicJumpFieldSchemaIfSomeFilled(yup.string()),
    }),
  ),

  choices: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .when("questionType", {
      is: (value) => QUESTION_TYPES_WITH_CHOICES.includes(value),
      then: (schema) =>
        schema.min(
          1,
          tSettings("questionnaires.questionBuilder.questionChoicesRequired"),
        ),
    }),
});

const validationSchema = yup.object({
  title: yup
    .string()
    .required(
      tSettings("questionnaires.form.errors.questionnaireNameRequired"),
    ),

  questions: yup
    .array()
    .of(questionSchema)
    .min(1, tSettings("questionnaires.form.errors.questionsMin")),
});

// Adapters

function logicJumpsAdapter({ logicNodes, questions }) {
  const jumpNodes = logicNodes.filter(
    (n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.jump,
  );
  const elseNodes = logicNodes.filter(
    (n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
  );

  function prepareId(id) {
    if (id) {
      return NodeId.make(id);
    }
    return null;
  }

  function jumpNodeAdapter(node) {
    return {
      type: MULTI_QUESTION_LOGIC_JUMP_TYPES.jump,
      elseQuestionId: null,
      ifSelectedChoiceId: prepareId(node.procedure_question_option_id),
      thenQuestionId: prepareId(questions?.[node.jump_to_question]?.id),
    };
  }

  function elseNodeAdapter(node) {
    return {
      type: MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
      ifSelectedChoiceId: null,
      thenQuestionId: null,
      elseQuestionId: prepareId(questions?.[node.jump_to_question]?.id),
    };
  }

  return [...jumpNodes.map(jumpNodeAdapter), ...elseNodes.map(elseNodeAdapter)];
}

function choicesAdapter(choiceNodes) {
  return choiceNodes.map((c) => ({
    id: NodeId.make(c.id),
    value: c.question_option,
  }));
}

function questionnaireAdapter(questionnaire) {
  const questions = questionnaire?.multi_questions || [];

  return {
    title: questionnaire?.title || "",
    questions:
      questions.map((q) => ({
        id: NodeId.make(q.id),
        question: q.question,
        questionType: q.question_type,
        isRequired: Boolean(q.required),
        description: q.description_text,
        allowCommentsOnScale: passOr(
          q.question_type === MULTI_QUESTION_TYPES.opinionScale,
          false,
          () => Boolean(q.why_choose),
        ),
        logicJumps: passOr(
          QUESTION_TYPES_WITH_LOGIC_JUMPS.includes(q.question_type),
          [],
          () =>
            logicJumpsAdapter({
              logicNodes: q.procedure_templates_logic || [],
              questions,
            }),
        ),
        choices: passOr(
          [
            ...QUESTION_TYPES_WITH_CHOICES,
            MULTI_QUESTION_TYPES.opinionScale,
            MULTI_QUESTION_TYPES.yesNo,
          ].includes(q.question_type),
          [],
          () => choicesAdapter(q.procedure_template_question_option || []),
        ),
      })) || [],
  };
}

// ===============================

export function useForm({ submitter, questionnaire }) {
  const [logicJumpErrorsQuestionIds, setLogicJumpErrorsQuestionIds] = useState(
    [],
  );

  const validateLogicJumps = (questions) => {
    setLogicJumpErrorsQuestionIds([]);

    const questionIdsWithUnfinishedLogicJumps =
      getQuestionIdsWithUnfinishedLogicJumps(questions);

    if (questionIdsWithUnfinishedLogicJumps.length) {
      setLogicJumpErrorsQuestionIds(questionIdsWithUnfinishedLogicJumps);
      return false;
    }

    return true;
  };

  const form = useFormik({
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (validateLogicJumps(values.questions)) {
        submitter(values);
      }
    },
    initialValues: questionnaireAdapter(questionnaire),
  });

  function getError(key) {
    if (form.touched[key] && form.errors[key]) {
      switch (key) {
        case "questions": {
          const error = form.errors[key];
          if (Array.isArray(error)) {
            return tSettings("questionnaires.form.errors.innerQuestionsError");
          }
          return error;
        }
        default: {
          return form.errors[key];
        }
      }
    }
    return null;
  }

  return {
    ...form,
    logicJumpErrorsQuestionIds,
    getError,
    questions: {
      value: form.values.questions,
      append: (next) => {
        if (next) {
          form.setFieldTouched("questions");
          form.setFieldValue("questions", [...form.values.questions, next]);
        }
      },
      replace: (next) => {
        if (next) {
          form.setFieldTouched("questions");
          form.setFieldValue("questions", next);
        }
      },
      duplicateByIndex: (index) => {
        if (!isNullish(index) && form.values.questions?.[index]) {
          form.setFieldTouched("questions");
          const duplicate = {
            ...form.values.questions[index],
            logicJumps: INITIAL_LOGIC_JUMPS,
            id: NodeId.make(),
          };
          form.setFieldValue("questions", [
            ...form.values.questions.slice(0, index + 1),
            duplicate,
            ...form.values.questions.slice(index + 1),
          ]);
        }
      },
      deleteByIndex: (index) => {
        if (!isNullish(index)) {
          let questions = form.values.questions;
          const question = questions[index];

          const affectedLogicJumps = findLogicJumpsByQuestionId({
            questions,
            questionId: question.id,
          });

          if (affectedLogicJumps.length) {
            affectedLogicJumps.forEach((jump) => {
              const { questionIndex, logicJumpIndex, logicJumpField } = jump;
              const nextQuestion = JSON.parse(
                JSON.stringify({ ...questions[questionIndex] }),
              );
              nextQuestion.logicJumps[logicJumpIndex][logicJumpField] = null;
              questions = [
                ...questions.slice(0, questionIndex),
                nextQuestion,
                ...questions.slice(questionIndex + 1),
              ];
            });
          }

          const nextQuestions = [
            ...questions.slice(0, index),
            ...questions.slice(index + 1),
          ];

          form.setFieldTouched("questions");
          form.setFieldValue("questions", nextQuestions);

          validateLogicJumps(nextQuestions);
        }
      },
      updateByIndex: (next, index) => {
        if (next) {
          const nextQuestions = [
            ...form.values.questions.slice(0, index),
            next,
            ...form.values.questions.slice(index + 1),
          ];

          form.setFieldTouched("questions");
          form.setFieldValue("questions", nextQuestions);

          validateLogicJumps(nextQuestions);
        }
      },
    },
  };
}
