import { useLeadQuery } from "../../../api/queries/useLeadQuery";
import { uiNotification } from "../../../services/UINotificationService";
import { getPathParams } from "../Event.utils";

export const useLeadAsClient = () => {
  const { leadId } = getPathParams();

  const { data, isFetching } = useLeadQuery(
    { id: leadId },
    {
      enabled: !!leadId,
      onError: () => {
        uiNotification.error("Unable to fetch lead details. Try again later");
      },
    },
  );

  return {
    value: data
      ? {
          id: data.patientId,
          fullName: `${data?.firstName} ${data.lastName}`,
          email: data?.email || "",
          phone: data?.phone || "",
          pinCode: "",
        }
      : {},
    isLoading: isFetching,
  };
};
