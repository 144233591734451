import React from "react";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { USER_PAYMENT_SYSTEMS } from "../../../../../consts/api";
import styles from "./ClearentDisclaimer.module.scss"

const ARTICLE_URL = "https://learn.aestheticrecord.com/en/articles/9243258-chargebacks-or-disputes-with-clearent";
const MERLINK_URL = "https://merlinkresponse.com";

export function ClearentDisclaimer() {
  const { data: user } = useCurrentAccountQuery();
  const isClearent = Boolean(user?.paymentSystem === USER_PAYMENT_SYSTEMS.clearent);

  if (isClearent) {
    return (
      <AlertBox className={styles.root}>
        <div className={styles.inner}>
          <h5 className={styles.header}>Disclaimer</h5>
          <p>
            As a Clearent POS user, please note that all information regarding disputes can only be accessed through the Partner Portal. Dispute details will not appear in the AR dispute folder.
          </p>
          <p>
            <b>Note: </b>
            <p>
              When a chargeback/cardholder dispute has been lodged against your account:
              <ul>
                <li>
                  You will receive a physical letter in the mail as well as an email from Merlink (<a href={MERLINK_URL} target="_blank" rel="noreferrer noopener">{MERLINK_URL}</a>) at your registered email address. DO NOT IGNORE THIS.
                </li>
                <li>
                  You will need to respond to the dispute through Merlink. Keep in mind that disputes are time-sensitive! Respond to them as soon as you receive them.
                </li>
              </ul>
            </p>
          </p>
          <p>
            For more information about the Partner Portal setup and managing disputes more effectively, please refer to the article: <a href={ARTICLE_URL} target="_blank" rel="noreferrer noopener">{ARTICLE_URL}</a>
          </p>
        </div>
      </AlertBox>
    );
  }

  return null;
}
