/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

export default class StaffReportTitle extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      languageData: languageData.business_insights,
    };
  }

  showCreateOptions = () => {
    this.props.showCreateOptions();
  };

  saveReport = () => {
    this.props.saveReport();
  };

  render() {
    return (
      <div className="membership-title">
        {this.props.parentState.grammar && (
          <div className={"col-sm-12 m-b-10"}>
            {this.props.parentState.grammar}
          </div>
        )}

        {!this.props.parentState.showLoader && (
          <div className="memberRightActions">
            {this.props.parentState.reportListData &&
              this.props.parentState.reportListData.length > 0 && (
                <a
                  onClick={() => this.props.downloadData()}
                  className="easy-link no-padding"
                >
                  <i className="fa fa-download m-r-5" />
                  {this.props.parentState.businessInsightLang.bi_download_excel}
                </a>
              )}

            {this.props.parentState.reportType !== "view" && (
              <a
                className="easy-link no-padding"
                onClick={this.showCreateOptions}
              >
                <i className="fa fa-pencil-alt m-r-5" />{" "}
                {this.props.parentState.businessInsightLang.bi_edit}
              </a>
            )}
            {this.props.parentState.dataLoadedOnce === true &&
              this.props.parentState.reportType !== "view" && (
                <a className="new-blue-btn" onClick={this.saveReport}>
                  {this.props.parentState.businessInsightLang.bi_save_report}
                </a>
              )}
          </div>
        )}
      </div>
    );
  }
}
