/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";

export const ReadFullNote = ({ text, maxLines, title }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div>
      <LinesEllipsis
        text={text}
        maxLine={maxLines}
        ellipsis={
          <span onClick={toggleShowFullText} className="read-more">
            ...Read more
          </span>
        }
        trimRight
        basedOn="letters"
      />
      {showFullText && (
        <ConfirmModal
          className={"confirm-modal-center"}
          isOpen
          title={
            <div className="word-wrap-break">
              <LinesEllipsis
                maxLine={1}
                ellipsis={"..."}
                basedOn="letters"
                text={title}
              />
            </div>
          }
          onConfirm={toggleShowFullText}
          confirmTitle="Ok"
        >
          <div className="read-full-note">{text}</div>
        </ConfirmModal>
      )}
    </div>
  );
};
