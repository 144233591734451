import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  removeNullishFromShape,
  unwrapOr,
} from "../../../../../../utilities/general";
import {
  getNextPaginationPage,
  composePaginationSchema,
  paginationSchema,
} from "../../../../../../schemas/pagination";
import { http } from "../../../../../../services/HttpService";
import {
  API_DATE_FORMAT,
  HTTP_ENDPOINTS,
  ORDER_TYPES,
  QUERY_KEYS,
} from "../../../../../../consts/api";
import { createQueryKeyFromShape } from "../../../../../../utilities/api";

const SHORT_TERM_LIABILITY_REPORT_ORDER_BY = {
  date: "date",
  patient: "client",
  product: "product_name",
  units: "units",
  dollarValue: "dollar_value",
  balanceUnits: "balance_units",
  balanceDollarValue: "balance_dollar_value",
};

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  limit: yup.number().nullable(),

  order: yup
    .object({
      by: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) =>
            Object.values(SHORT_TERM_LIABILITY_REPORT_ORDER_BY).includes(value),
        })
        .required(),

      direction: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) => Object.values(ORDER_TYPES).includes(value),
        })
        .required(),
    })
    .nullable(),

  searchTerm: yup.string().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    totalLiability: yup.string().required(),
    reports: yup
      .array()
      .of(
        yup.object({
          date: yup.string().required(),
          client: yup.string().nullable(),
          product_name: yup.string().required(),
          units: yup.number().required(),
          dollar_value: yup.string().required(),
          balance_units: yup.number().required(),
          balance_dollar_value: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => ({
  ...composePaginationSchema({
    response: res?.data?.data?.report_data || {},
    pageSize: req.pageSize,
  }),
  totalLiability: res?.data?.data?.total_liability,
  reports: res?.data?.data?.report_data?.data || [],
});

const createShortTermLiabilityReportQueryKey = (...args) => [
  QUERY_KEYS.shortTermLiabilityReport,
  ...args,
];

const createKeyFromShape = (shape) =>
  createQueryKeyFromShape(removeNullishFromShape(shape));

export function useShortTermLiability({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );

  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createShortTermLiabilityReportQueryKey(
      createKeyFromShape(req.order),
      fromDate,
      toDate,
      req.limit,
      req.pageSize,
      req.searchTerm,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getShortTermLiability(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          fromDate: fromDate,
          toDate: toDate,
          sortBy: req.order?.by,
          order: req.order?.direction,
          search_term: req.searchTerm,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
