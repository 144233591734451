import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  HTTP_ENDPOINTS,
  QUERY_KEYS,
  QUESTIONNAIRE_TYPES,
} from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import { SIMPLE_QUESTION_TYPES } from "./config";

// Schemas

const baseQuestionSchema = yup.object({
  question_type: yup.string().required(),
  question: yup.string().required(),
});

const questionSchemasByType = {
  [SIMPLE_QUESTION_TYPES.yesNo]: baseQuestionSchema.concat(
    yup.object({
      multiple_selection: yup.number().required(),
    }),
  ),

  [SIMPLE_QUESTION_TYPES.multiText]: baseQuestionSchema.concat(
    yup.object({
      multiple_selection: yup.number().required(),
      multi_text_data: yup
        .array()
        .of(
          yup.object({
            text: yup.string().required(),
          }),
        )
        .required(),
    }),
  ),

  [SIMPLE_QUESTION_TYPES.multiImage]: baseQuestionSchema.concat(
    yup.object({
      multiple_selection: yup.number().required(),
      display_labels: yup.number().required(),
      multi_image_data: yup
        .array()
        .of(
          yup.object({
            image: yup.string().required(),
            image_label: yup.string().required(),
          }),
        )
        .required(),
    }),
  ),
};

const isEveryQuestionNodeValid = (nodes) =>
  nodes.every((node) => {
    const schema = questionSchemasByType?.[node?.question_type];
    if (schema) {
      return schema.isValidSync(node, {
        strict: true,
      });
    }
    return false;
  });

const requestSchema = yup.object({
  questionnaire_id: yup.number().nullable(),
  title: yup.string().required(),
  question_data: yup.mixed().test({
    test: isEveryQuestionNodeValid,
  }),
});

// ========================

export function useSimpleQuestionnaireMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.questionnaires.createEditOne(),
        removeNullishFromShape({
          ...req,
          type: QUESTIONNAIRE_TYPES.simple,
        }),
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.questionnaires.getAll],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientProcedures],
        });
        options?.onSuccess?.();
      },
    },
  );
}
