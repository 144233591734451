import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  const categories = unwrapOr(() => res.data.data, []);
  return categories.map((c) => ({
    id: c.id,
    name: c.name,
  }));
};

function createServiceCategoriesQueryKey() {
  return [QUERY_KEYS.serviceCategories];
}

export function useServiceCategoriesQuery(options = {}) {
  return useQuery(
    createServiceCategoriesQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getServiceCategories());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
