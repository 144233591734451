import { useMemo } from "react";
import { useDebouncedState } from "../../../../utilities/hooks/useDebouncedState";
import { useChartingPackagesQuery } from "../../../../api/queries/useChartingPackagesQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export function useData() {
  const { tInventory } = useAppTranslation.Inventory();
  const [search, debouncedSearch, setSearch] = useDebouncedState("");

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useChartingPackagesQuery({
    payload: {
      pageSize: 20,
      searchTerm: debouncedSearch,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tInventory("pricing_variation.error.fetch"));
      },
    },
  });

  const preparedData = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.data).reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const isSoftLoading =
    !!debouncedSearch && isFetching && !isLoading && !isFetchingNextPage;

  return {
    data: {
      value: preparedData,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      isLoading: isLoading,
      fetchMore: fetchNextPage,
      isSoftLoading,
    },
    search: {
      value: search,
      onChange: setSearch,
      isLoading: !!debouncedSearch && isFetching && !isFetchingNextPage,
    },
  };
}
