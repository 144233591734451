export const QueryKeys = {
  // Waitlist
  GET_WAITLIST: "GET_WAITLIST",
  GET_WAITLIST_DETAILS: "GET_WAITLIST_DETAILS",
  GET_CLINICS_PROVIDERS_LIST: "GET_CLINICS_PROVIDERS_LIST",

  // PriceList
  GET_PRICELIST: "GET_PRICELIST",

  // Clinics
  GET_ALL_CLINICS: "GET_ALL_CLINICS",
  GET_CLINIC_LIST: "GET_CLINIC_LIST",

  // Providers
  GET_ALL_PROVIDERS: "GET_ALL_PROVIDERS",
  GET_PROVIDER_SCHEDULE: "GET_PROVIDER_SCHEDULE",
  GET_PROVIDER_SCHEDULES_FOR_DELETE: "GET_PROVIDER_SCHEDULES_FOR_DELETE",
  GET_PROVIDER_TEMPLATES: "GET_PROVIDER_TEMPLATES",
  PROVIDERS_SCHEDULE_OVERRIDES: "PROVIDERS_SCHEDULE_OVERRIDES",

  // Services
  GET_SERVICES_BY_PROVIDERS: "GET_SERVICES_BY_PROVIDERS",

  // Notes
  GET_NOTES_ACKNOWLEDGED_HISTORY: "GET_NOTES_ACKNOWLEDGED_HISTORY",
  GET_NOTES_HISTORY: "GET_NOTES_HISTORY",

  // User
  GET_USER_PROFILE: "GET_USER_PROFILE",

  // Device
  GET_DEVICE_DETAILS: "GET_DEVICE_DETAILS",

  // Membership
  GET_PATIENT_MEMBERSHIPS: "GET_PATIENT_MEMBERSHIPS",
  GET_NEW_MEMBERSHIP: "GET_NEW_MEMBERSHIP",
  GET_MEMBERSHIP_DETAILS: "GET_MEMBERSHIP_DETAILS",
  GET_MEMBERSHIP_AGREEMENT: "GET_MEMBERSHIP_AGREEMENT",
  GET_PASSCODE: "GET_PASSCODE",
  GET_ALL_ACTIVE_USER: "GET_ALL_ACTIVE_USER",
  UPDATE_SOLD_BY_MEMBERSHIP_DETAILS: "UPDATE_SOLD_BY_MEMBERSHIP_DETAILS",
  GET_PROVIDER_AND_MD_LIST_DATA: "GET_PROVIDER_AND_MD_LIST_DATA",
  DOWNLOAD_CHART_AUDIT_REPORT: "DOWNLOAD_CHART_AUDIT_REPORT",

  GET_PROVIDER_SCHEDULE_MONTH: "GET_PROVIDER_SCHEDULE_MONTH",
};
