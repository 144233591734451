import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { Table } from "../../../../../shared/Table/Table";
import { useClinicBdAspire } from "./hooks/useClinicBdAspire";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import Header from "../../../components/Header";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { tCommon } from "../../../../../i18n/useAppTranslation";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "bdAspireReport.info.title",
  rows: ["bdAspireReport.info.note"],
};

const ClinicBdAspire = ({
  match,
  history,
  period,
  selectedClinic,
  apiPeriod,
}) => {
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id: [match.params.subReport],
    detailClinicId: match.params.subReport,
  };
  const { data, isLoading } = useClinicBdAspire(requestParams, {
    cacheTime: 0,
  });

  const { data: user } = useCurrentUserQuery();

  const tableData = useMemo(
    () =>
      data?.pages
        ?.map((p) => p.reportData)
        .flat()
        .map((data) => ({
          invoiceId: data.invoice_number,
          paymentDate: data.payment_datetime,
          client: data.clientname,
          email: data.email,
          alle: data.bd_coupons || tCommon("symbol.longDash"),
          aspire: data.aspire_coupons || tCommon("symbol.longDash"),
          experience: data.xperience_coupons || tCommon("symbol.longDash"),
          evolusRewards: data.evolus_coupons || tCommon("symbol.longDash"),
        })),
    [data],
  );

  const tableDataEmpty = !isLoading && !tableData?.length;

  const discountPeriod = `${moment(period.value.fromDate).format(
    user?.dateFormat,
  )} - ${moment(period.value.toDate).format(user?.dateFormat)}`;

  useEffect(() => {
    if (!selectedClinic.value.value) {
      history.push("/refactor-business-insight/sales/bd_aspire_report");
    }
  }, []);

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title={`Discount - ${selectedClinic.value.label} (${discountPeriod})`}
        downloadRoute={HTTP_ENDPOINTS.getBdAspireReportDetail()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Invoice #", accessor: "invoiceId" },
              { data: "Payment Date", accessor: "paymentDate" },
              { data: "Client", accessor: "client" },
              { data: "Email", accessor: "email" },
              { data: "Allē", accessor: "alle" },
              { data: "Aspire", accessor: "aspire" },
              { data: "Xperience", accessor: "experience" },
              { data: "Evolus Rewards", accessor: "evolusRewards" },
            ]}
            data={tableData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ClinicBdAspire);
