import { useState } from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useRemoveInboxBlackListEntryMutation } from "../../../../api/inboxBlacklist/useRemoveInboxBlackListEntryMutation";

export function useRemove({ onSuccess }) {
  const { tInbox } = useAppTranslation.Inbox();
  const [inProgressIds, setInProgressIds] = useState([]);

  const { mutateAsync, isLoading } = useRemoveInboxBlackListEntryMutation();

  return {
    initiate: async (ids) => {
      try {
        setInProgressIds(ids);
        await mutateAsync({ ids });
        onSuccess();
        uiNotification.success(tInbox("blackList.success.remove"));
      } catch {
        uiNotification.error(tInbox("blackList.error.remove"));
      } finally {
        setInProgressIds([]);
      }
    },
    isLoading,
    inProgressIds,
  };
}
