import React from "react";
import { Header } from "./components/Header";
import classes from "./sass/NonDupPatients.module.scss";
import { Box } from "../../shared/Box/Box";
import { Table } from "../../shared/Table/Table";
import { Empty } from "../../shared/Empty/Empty";
import { Skeleton } from "../../shared/Skeleton/Skeleton";
import { useTableData } from "./hooks/useTableData";
import { useDebouncedState } from "../../utilities/hooks/useDebouncedState";
import { useMultiselectEntity } from "../../utilities/hooks/useMultiselectEntity";
import { LoadMoreObserver } from "../../shared/LoadMoreObserver/LoadMoreObserver";
import { PATIENTS_PER_PAGE, SKELETON_HEIGHT } from "./NonDupPatients.consts";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { useNonDupPatients } from "./hooks/useNonDupPatients";
import { useUnmarkNonDup } from "./hooks/useUnmarkNonDup";
import { LayoutMain } from "../../boxes/LayoutMain/LayoutMain";

export function NonDupPatients() {
  const { tCommon } = useAppTranslation.Common();
  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState("");

  const {
    patients,
    isPatientsLoading,
    isNextPatientsFetching,
    fetchNextPatients,
    hasMorePatients,
    refetchPatients,
  } = useNonDupPatients({ searchTerm: debouncedSearchTerm });

  const { resetSelected, selected, ...selectHandlers } = useMultiselectEntity({
    allEntities: patients.map((p) => p.groupId),
  });

  const { unmark, isPatientsUnmarking } = useUnmarkNonDup({
    selectedPatientGroupIds: selected,
    onSuccess: () => {
      refetchPatients();
      resetSelected();
    },
  });

  const { cols, data } = useTableData(patients, selectHandlers);

  const isDataVisible = !isPatientsLoading && data.length > 0;
  const isEmpty = !isPatientsLoading && data.length === 0;
  const isSearching = Boolean(searchTerm) && isPatientsLoading;

  return (
    <LayoutMain className={classes.root}>
      <Header
        selectedCount={selected.length}
        onUnmark={unmark}
        isUnmarking={isPatientsUnmarking}
        searchValue={searchTerm}
        onChangeSearch={setSearchTerm}
        isSearching={isSearching}
      />
      <Box elevation>
        {isPatientsLoading && !searchTerm && (
          <Skeleton
            count={PATIENTS_PER_PAGE}
            height={SKELETON_HEIGHT}
            borderRadius="0px"
          />
        )}
        {isDataVisible && <Table cols={cols} data={data} />}
        {isEmpty && <Empty size="big" position="center" />}
        {isSearching && (
          <Empty size="big" position="center">
            {tCommon("label.searching")}
          </Empty>
        )}
        {hasMorePatients && (
          <LoadMoreObserver
            noPaddingBottom
            loadMore={fetchNextPatients}
            isLoading={isNextPatientsFetching}
          />
        )}
      </Box>
    </LayoutMain>
  );
}
