import React, { useEffect, useState } from "react";
import { getUserPaymentSystem } from "../../utilities/localStorage";
import { USER_PAYMENT_SYSTEMS } from "../../consts/api";
import { initializeClearentSdk } from "../../Utils";
import { isEmpty } from "lodash";
import classes from "./sass/membership.module.scss";
import cx from "clsx";
import { useFormContext } from "react-hook-form";
import { uiNotification } from "../../services/UINotificationService";
import StripePaymentForm from "../Sales/FrontdeskCheckout/StripePaymentForm";
import { bindActionCreators } from "redux";
import { getSetupIntent } from "../../Actions/Stripe/StripeActions";
import { connect } from "react-redux";
import { Select } from "../../shared/Select/Select";
import { Tabs } from "../../shared/Tabs/Tabs";

const MembershipPayment = ({
  notDisplayTitle,
  clearentClinic,
  clientId,
  cardList,
  getSetupIntent,
  updateProceedState,
  updateCardSelected,
  validation,
  onSubmit,
  onCancel,
  isAddMembership,
  selectedClinicId,
  clearentCardsList,
  changeClearentCard,
  selectedClearentCard,
  cardOnFile,
  setCardOnFile,
}) => {
  const [cardType, setCardType] = useState("saved");
  const [cardSelected, setCardSelected] = useState("");

  const userPaymentSystem = getUserPaymentSystem();
  const isStripe = userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe;

  const { register } = useFormContext();

  useEffect(() => {
    if (
      !isEmpty(clearentClinic) &&
      userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent
    ) {
      if (clearentClinic.publickey === null) {
        uiNotification.error("POS is not setup for this clinic.");
      } else if (clearentClinic.publickey) {
        initializeClearentSdk(clearentClinic.publickey, "payment-form");
      }
    }
  }, [clearentClinic, userPaymentSystem, cardOnFile]);

  useEffect(() => {
    if (isStripe && selectedClinicId) {
      getSetupIntent({ patient_id: clientId, clinic_id: selectedClinicId });
    }

    if (cardList?.length) {
      setCardType("saved");
      updateProceedState(true);
    } else {
      setCardType("new");
      updateProceedState(false);
    }
  }, [selectedClinicId]);

  const handleInputChange = (e) => {
    if (cardType === "saved") {
      setCardType("new");
      updateProceedState(false);
    } else if (cardType === "new" && cardList?.length) {
      setCardType("saved");
      updateProceedState(true);
    }
  };

  const changeCardSelected = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setCardSelected(value);
    updateCardSelected(value);
  };

  useEffect(() => {
    if (cardList?.length === 1) {
      setCardSelected(cardList[0].id);
    }
  }, [cardList]);

  return (
    <div className={classes.payment}>
      <div className="row m-b-15">
        {userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe && (
          <>
            {!notDisplayTitle && (
              <div className="col-xs-12">
                <div className={classes.fieldTitle}>PAYMENT DETAILS</div>
              </div>
            )}
            <div className="row col-sm-12">
              <div className="col-xs-12">
                <div className="radio-outer">
                  {cardList?.length ? (
                    <span>
                      <input
                        type="radio"
                        name="cardType"
                        id="saved"
                        value="saved"
                        checked={cardType === "saved"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="saved">Saved Cards</label>
                    </span>
                  ) : (
                    ""
                  )}
                  <input
                    type="radio"
                    name="cardType"
                    id="new"
                    value="new"
                    checked={cardType === "new"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="new">New Card</label>
                </div>
                {cardType === "saved" ? (
                  <div className="m-t-80 col-xs-6">
                    <div className="new-field-label">Credit Card(s)</div>

                    <select
                      className="setting-select-box"
                      value={cardSelected}
                      onChange={changeCardSelected}
                    >
                      {!cardSelected && <option value="">Select card</option>}
                      {cardList?.length &&
                        cardList.map((obj) => {
                          const last4 = obj.card.last4
                            ? ` ending with ${obj.card.last4}`
                            : "";
                          return (
                            <option value={obj.id}>
                              {obj.card.brand}
                              {last4}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                ) : (
                  <StripePaymentForm
                    type="setupIntent"
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    validation={validation}
                    onSubmitText={isAddMembership ? "Proceed" : "Save"}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
          clearentClinic.publickey && (
            <>
              {!notDisplayTitle && (
                <div className="col-xs-12">
                  <div className={classes.fieldTitle}>PAYMENT DETAILS</div>
                </div>
              )}
              <div className="col-xs-12 flex justify-center m-b-20">
                <Tabs
                  variant="tiled"
                  active={cardOnFile ? "cardOnFile" : "newCard"}
                  options={[
                    {
                      label: "New Card",
                      value: "newCard",
                      onClick: () => setCardOnFile(false),
                    },
                    {
                      label: "Card On File",
                      value: "cardOnFile",
                      onClick: () => setCardOnFile(true),
                    },
                  ]}
                />
              </div>
              {cardOnFile ? (
                <div className="col-xs-12">
                  <div className="col-xs-12 p-l-0">
                    <div className={classes.fieldTitle}>Select Card</div>
                    <div className={cx(classes.formField, "p-0")}>
                      <Select
                        value={selectedClearentCard}
                        onChange={(value) => changeClearentCard(value)}
                        options={clearentCardsList}
                        placeholder="Select"
                        name="select-card"
                        size="small"
                        className="p-t-0"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div id="payment-form" className={classes.clearentForm} />
              )}

              <div className="col-sm-6 col-xs-12 payment-field">
                <div className=" clear-relative">
                  <div className={cx("simpleField", classes.formField)}>
                    <input
                      className="simpleInput"
                      name="clearentZip"
                      type="text"
                      autoComplete="off"
                      placeholder={"Zip"}
                      {...register("clearentZip")}
                    />
                  </div>

                  <div className={cx("simpleLabel", classes.paymentTitle)}>
                    Zip
                    <span className="required m-l-5">*</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xs-12 payment-field">
                <div className=" clear-relative">
                  <div className={cx("simpleField", classes.formField)}>
                    <input
                      className="simpleInput"
                      name="clearentEmail"
                      type="text"
                      autoComplete="off"
                      placeholder={"Email"}
                      {...register("clearentEmail")}
                    />
                  </div>

                  <div className={cx("simpleLabel", classes.paymentTitle)}>
                    Email
                    <span className="required m-l-5">*</span>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cardList: state.StripeReducer.cardList?.filter((c) => c.card.last4),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getSetupIntent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MembershipPayment);
