import { useMemo, useState } from "react";
import moment from "moment";
import { ORDER_TYPES } from "../../../../../../consts/api";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { hasLength } from "../../../../../../utilities/general";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { SALES_DECLINE_REPORT_PAYMENT_STATUSES } from "../../../../../../api/queries/useSalesDeclineReportsQuery";
import { useMembershipDeclineReportsQuery } from "./useMembershipDeclineReport";

const prepareReqOrder = (orderState) => {
  if (Object.values(orderState).every(Boolean)) {
    return {
      by: orderState.by,
      direction: orderState.direction,
    };
  }
  return null;
};

const prepareReqPeriod = (periodState) => {
  return {
    from: periodState.from || null,
    to: periodState.to || null,
  };
};

export function useReports() {
  const { tCommon } = useAppTranslation.Common();

  /* Modifiers */

  const [order, setOrder] = useState({
    by: "",
    direction: "",
  });

  const updateOrder = (orderFieldKey) => {
    setOrder({
      by: orderFieldKey,
      direction:
        order.direction === ORDER_TYPES.desc
          ? ORDER_TYPES.asc
          : ORDER_TYPES.desc,
    });
  };

  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: new Date(),
  });

  /* Data */

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useMembershipDeclineReportsQuery({
    payload: {
      pageSize: 15,
      order: prepareReqOrder(order),
      period: prepareReqPeriod(period),
      paymentStatus: SALES_DECLINE_REPORT_PAYMENT_STATUSES.failed,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  });

  const reports = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  /* Statuses */

  const isSoftLoading =
    Object.values({
      ...order,
      ...period,
    }).some(hasLength) &&
    isFetching &&
    !isLoading &&
    !isFetchingNextPage;

  // ---------

  return {
    reports: {
      value: reports,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      isLoading: isLoading,
      fetchMore: fetchNextPage,
      isSoftLoading,
    },
    order: {
      value: order,
      update: updateOrder,
    },
    period: {
      value: period,
      update: setPeriod,
    },
  };
}
