/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { autoScrolling } from "../../../../Utils/services.js";

class RetailReportDetail extends Component {
  constructor(props) {
    super(props);
    autoScrolling(true);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      languageData: languageData.business_insights,
      page: 1,
      pagesize: 15,
      startFresh: true,
      next_page_url: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.detailData !== undefined &&
      nextProps.detailData !== prevState.detailData
    ) {
      returnState.detailData = nextProps.detailData;
    }

    return returnState;
  }

  retailReportBack = () => {
    this.props.retailReportBack();
  };

  handleDetailExport = () => {
    this.props.fetchRetailDetail(
      {
        export_name: "retail_report_details",
        export_type: "xls",
        category_name:
          this.state.detailData && this.state.detailData.category_name,
      },
      1,
    );
  };

  render() {
    return (
      <div className="juvly-section setting-setion m-b-10 m-t-10">
        <div className="juvly-container memberShip-revenue p-b-20">
          <div className="juvly-title no-margin">
            {`Retail report of ${
              this.state.detailData && this.state.detailData.category_name
            } during ${this.props.detailDate}`}{" "}
            <a
              onClick={() => this.retailReportBack()}
              className="pull-right crossIcon m-b-10"
            >
              <img alt="" src="/images/close.png" />
            </a>
          </div>

          {this.props.detailData &&
            this.props.detailData.report_data &&
            this.props.detailData.report_data.length > 0 && (
              <div className="mainRightActions">
                <a
                  className="easy-link relative"
                  onClick={() => this.handleDetailExport()}
                >
                  <i className="fa fa-download"></i> Download Excel
                </a>
              </div>
            )}
        </div>

        <div className="table-responsive clients-table">
          <table className="table-updated setting-table table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                <th className="table-updated-th">
                  {this.props.detailHeader &&
                    this.props.detailHeader.provider_name}
                </th>
                <th className="table-updated-th">
                  {this.props.detailHeader && this.props.detailHeader.unit_sold}
                </th>
                <th className="table-updated-th">
                  {this.props.detailHeader && this.props.detailHeader.sub_total}
                </th>
                <th className="table-updated-th">
                  {this.props.detailHeader &&
                    this.props.detailHeader.item_discount}
                </th>
                <th className="table-updated-th">
                  {this.props.detailHeader &&
                    this.props.detailHeader.taxable_amount}
                </th>
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.props.detailData &&
                this.props.detailData.report_data &&
                this.props.detailData.report_data.length > 0 &&
                this.props.detailData.report_data.map((obj, idx) => {
                  return (
                    <tr key={idx} className="table-updated-tr">
                      <td className="table-updated-td break-all">
                        {obj.provider_name}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.unit_sold}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.sub_total}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.item_discount}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.taxable_amount}
                      </td>
                    </tr>
                  );
                })}

              {this.props.detailData &&
                this.props.detailData.report_data &&
                this.props.detailData.report_data.length === 0 && (
                  <tr className="table-updated-tr">
                    <td
                      className="table-updated-td no-record no-float"
                      colSpan="4"
                    >
                      {this.state.languageData.bi_no_record_found}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default RetailReportDetail;
