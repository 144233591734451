import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  clinicIds: yup.array().of(yup.number()).nullable(),
  fromDate: yup.string().required(),
  toDate: yup.string().required(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup.array().of(
      yup.object({
        patientName: yup.string().nullable(),
        invoiceNumber: yup.string().required(),
        invoiceDate: yup.string().required(),
        refundAmount: yup.number().required(),
        invoiceStatus: yup.string().nullable(),
        refundMode: yup.string().nullable(),
        refundDate: yup.string().required(),
      }),
    ),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data?.report_data || [];
  const refundModeLabels = res?.data?.data?.refund_mode_label || {};
  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    reports: data.map((invoice) => ({
      patientName: invoice.patient_name || null,
      invoiceNumber: invoice.invoice_number,
      invoiceDate: invoice.invoice_date || null,
      refundAmount: invoice.refund_amount || 0,
      invoiceStatus: invoice.invoice_status || null,
      refundMode:
        refundModeLabels[invoice.refund_mode] || invoice.refund_mode || null,
      refundDate: invoice.refunded_on,
    })),
  };
};

function createRefundsReportQueryKey({
  pageSize,
  clinicIds,
  fromDate,
  toDate,
}) {
  return [
    QUERY_KEYS.refundsReport,
    pageSize,
    clinicIds.join("-"),
    fromDate,
    toDate,
  ];
}

export function useRefundsReportQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createRefundsReportQueryKey({
      pageSize: req.pageSize,
      clinicIds: req.clinicIds || [],
      fromDate: req.fromDate,
      toDate: req.toDate,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.post(
        HTTP_ENDPOINTS.createRefundsReport(),
        removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          clinic_id: req.clinicIds.length > 0 ? req.clinicIds : null,
          fromDate: req.fromDate,
          toDate: req.toDate,
        }),
      );
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
