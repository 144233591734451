import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./sass/StatementDescriptor.module.scss";
import cx from "clsx";
import { Modal } from "../../../../../shared/Modal/Modal";
import { Input } from "../../../../../shared/Input/Input";
import { BUTTON_VARIANT, ERRORS } from "./StatementDescriptor.consts";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useApiDescriptor } from "./hooks/useApiDescriptor";
import { useForm } from "./hooks/useForm";
import { useApiUpdateDescriptor } from "./hooks/useApiUpdateDescriptor";
import Tooltip from "react-tooltip";
import { OpenButton } from "./components/OpenButton";
import { InputError } from "../../../../../shared/InputError/InputError";

export function StatementDescriptor({
  buttonVariant,
  stripeUserId,
  onSuccess,
  inlineBlock,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { update, isUpdating } = useApiUpdateDescriptor(stripeUserId);

  const {
    descriptor,
    isDescriptorError,
    isDescriptorLoading,
  } = useApiDescriptor(stripeUserId);

  const closeModal = (reset) => {
    setIsModalOpen(false);
    reset();
  };

  const {
    form,
    resetForm,
    handleChange,
    handleSubmit,
    isFormDirty,
    errors,
  } = useForm({
    initialValues: {
      descriptor: descriptor || "",
    },
    onSubmit: (values, { resetForm }) => {
      update(values).then(() => {
        closeModal(resetForm);
        onSuccess();
      });
    },
  });

  return (
    <div className={cx({ [classes.inlineBlock]: inlineBlock })}>
      <div data-tip data-for="errorTooltip">
        <OpenButton
          variant={buttonVariant}
          onClick={() => setIsModalOpen(true)}
          isDisabled={isDescriptorLoading}
          isError={isDescriptorError}
          isLoading={isDescriptorLoading}
        />
      </div>
      {isDescriptorError && (
        <Tooltip id="errorTooltip" aria-haspopup="true">
          Failed to fetch descriptor value
        </Tooltip>
      )}
      {isModalOpen && (
        <Modal
          footerNoBorder
          header={<Modal.Title>Update Statement Descriptor</Modal.Title>}
          isOpen={isModalOpen}
          onClose={() => closeModal(resetForm)}
          footer={
            <Button
              onClick={handleSubmit}
              isDisabled={!isFormDirty || isUpdating}
              leftAdornment={
                isUpdating ? (
                  <CircularProgress color="white" size="small" />
                ) : (
                  undefined
                )
              }
            >
              Update
            </Button>
          }
        >
          <Input
            autoFocus
            name="descriptor"
            value={form.descriptor}
            onChange={handleChange}
            placeholder="Value"
            isError={!!errors.descriptor}
          />
          {!!errors.descriptor && <InputError>{errors.descriptor}</InputError>}
          <ul className={classes.validationTips}>
            {Object.values(ERRORS)
              .filter((e) => "tip" in e)
              .map(({ tip }) => (
                <li key={tip}>{tip}</li>
              ))}
          </ul>
        </Modal>
      )}
    </div>
  );
}

StatementDescriptor.propTypes = {
  buttonVariant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  stripeUserId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

StatementDescriptor.defaultProps = {
  buttonVariant: BUTTON_VARIANT.contained,
};
