import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  logId: yup.number().required(),
});

export function useDeleteTimeLogMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.delete(HTTP_ENDPOINTS.deleteTimeLog(req.logId));
  }, options);
}
