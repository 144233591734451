import React from "react";
import Select from "react-select";

const colourStyles = (isValid) => {
  let colorCode =
    isValid != undefined ? (isValid ? "#cad2d6" : "#e74c3c") : "#cad2d6";
  return {
    menu: (styles) => {
      return {
        ...styles,
        top: 30,
        zIndex: 2,
      };
    },
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        border: isFocused
          ? `1px solid ${colorCode} !important`
          : `1px solid ${colorCode} !important`,
        borderRadius: "3px",
        boxShadow: "none",
      };
    },
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#047ec3" : "#fff",
        color: isFocused ? "#fff" : "#404040",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: isFocused ? "#047ec3" : "#fff",
          color: isFocused ? "#fff" : "#404040",
        },
        borderBottom: "1px solid #dedede",
      };
    },
  };
};

export default class ReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userChanged: false,
      isMulti: props.isMulti ? props.isMulti : false,
      value: props.value ? props.value : "id",
      label: props.label ? props.label : "name",
      placeholder: props.placeholder ? props.placeholder : "",
    };
  }

  handleSelect2Change = (option, mode) => {
    if (mode) {
      let returnState = {};
      let name = mode.name;
      if (mode.action == "select-option") {
        if (this.props.isMulti) {
          let selectedId = [];
          option.map((obj) => {
            selectedId.push(obj.value);
          });
          returnState[name] = selectedId;
          returnState[name + "Object"] = option;
        } else {
          let value = option.value;
          returnState[name] = value;
        }
        this.props.handleReactSelect(returnState);
      } else if (mode.action == "remove-value") {
        if (this.props.isMulti) {
          let selectedId = [];
          option.map((obj) => {
            selectedId.push(obj.value);
          });
          returnState[name] = selectedId;
          returnState[name + "Object"] = option;
        } else {
          returnState[name] = null;
        }
        this.props.handleReactSelect(returnState);
      } else if (mode.action == "clear") {
        returnState[name] = this.props.isMulti ? [] : null;
        if (this.props.isMulti) {
          returnState[name + "Object"] = [];
        }
        this.props.handleReactSelect(returnState);
      }
    }
  };

  select2Options = (list) => {
    let selectOptions = [];
    if (list && list.length > 0) {
      selectOptions = list.map((obj) => {
        return { value: obj[this.state.value], label: obj[this.state.label] };
      });
    }
    return selectOptions;
  };

  select2Value = (selectOptions, value) => {
    let selectValue;
    if (this.props.isMulti) {
      selectValue = selectOptions.filter((x) => value.indexOf(x.value) > -1);
      if (selectValue) {
        selectValue = selectValue ? selectValue : [];
      } else {
        selectValue = null;
      }
    } else {
      selectValue = selectOptions.find((x) => x.value == value);
      if (selectValue) {
        selectValue = selectValue ? selectValue : {};
      } else {
        selectValue = null;
      }
    }
    return selectValue;
  };

  render() {
    let selectOptions = this.select2Options(this.props.list);
    let selectValues = this.select2Value(selectOptions, this.props.selectedId);
    return (
      <Select
        onChange={this.handleSelect2Change}
        className={this.props.className}
        onInputChange={this.props.onInputChange}
        hideSelectedOptions={this.props.hideSelectedOptions}
        loadingMessage={this.props.loadingMessage}
        value={selectValues}
        options={selectOptions}
        name={this.props.name}
        styles={colourStyles(this.props.isValid)}
        isSearchable
        isMulti={this.state.isMulti}
        isClearable={this.state.isMulti}
        placeholder={this.props.placeholder ? this.props.placeholder : ""}
        noOptionsMessage={this.props.noOptionsMessage}
      />
    );
  }
}
