import React, { Component } from "react";
import { numberFormat } from "../../../../Utils/services";

class TipsPerProvider extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.reportData !== undefined) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  handleToggle = (index) => {
    const returnState = {};
    returnState[`isProviderToggled-${index}`] =
      !this.state[`isProviderToggled-${index}`];

    this.setState(returnState);
  };

  render() {
    return (
      <div className="table-responsive clients-table">
        <table className="table-updated setting-table table-min-width no-td-border  no-hover">
          <thead className="table-updated-thead">
            {
              <tr>
                <th className="table-updated-th">
                  {this.state.reportData?.report_header?.provider}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData?.report_header?.invoice_tips}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData?.report_header?.invoice_number}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData?.report_header?.payment_datetime}
                </th>
              </tr>
            }
          </thead>
          <tbody className="ajax_body">
            {this.state.reportData?.report_data?.length > 0 &&
              this.state.reportData?.report_data?.map((obj, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="table-updated-tr">
                      <td
                        className="table-updated-td bordersides"
                        onClick={() => this.handleToggle(index)}
                      >
                        {this.state[`isProviderToggled-${index}`] && (
                          <button className="easy-link no-padding-top no-padding-left">
                            <i className={`fas fa-angle-up toggle-arrow`} />
                          </button>
                        )}
                        {!this.state[`isProviderToggled-${index}`] && (
                          <button className="easy-link no-padding-top no-padding-left">
                            <i className={`fas fa-angle-down toggle-arrow`} />
                          </button>
                        )}
                        {obj.provider}
                      </td>
                      <td className="table-updated-td bordersides">
                        {obj.tip}
                      </td>
                      <td className="table-updated-td"></td>
                      <td className="table-updated-td"></td>
                    </tr>
                    {this.state[`isProviderToggled-${index}`] &&
                      Object.values(obj.invoices).map((invoice) => {
                        return (
                          <tr
                            className="table-updated-tr"
                            key={invoice.invoice_number}
                          >
                            <td className="table-updated-td bordersides p-l-35">
                              {obj.provider}
                            </td>
                            <td className="table-updated-td bordersides">
                              {numberFormat(invoice.invoice_tips, "currency")}
                            </td>
                            <td className="table-updated-td bordersides">
                              {invoice.invoice_number}
                            </td>
                            <td className="table-updated-td bordersides">
                              {invoice.payment_datetime}
                            </td>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            {this.state.reportData?.report_data?.length > 0 &&
              this.state.reportData?.total && (
                <tr className="table-updated-tr">
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.provider}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.tip}
                  </td>
                  <td className="table-updated-td"></td>
                  <td className="table-updated-td"></td>
                </tr>
              )}

            {this.state.reportData?.report_data?.length === 0 && (
              <tr className="table-updated-tr">
                <td
                  className="table-updated-td no-record no-float"
                  colSpan="10"
                >
                  {this.state.languageData.bi_no_record_found}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TipsPerProvider;
