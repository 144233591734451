import React from "react";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { InputError } from "../../../../shared/InputError/InputError";
import { Textarea } from "../../../../shared/Textarea/Textarea";
import styles from "./styles.module.scss";
import { Button } from "../../../../shared/Button/Button";
import { useForm } from "./hooks/useForm";
import { useSubmit } from "./hooks/useSubmit";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { InputCurrency } from "../../../../shared/InputCurrency/InputCurrency";
import { CURRENCY_SYMBOL } from "../../../../consts/general";

export const Form = ({ giftCardId, onSuccess, onCancel, initialValues }) => {
  const { tCommon } = useAppTranslation.Common();

  const submit = useSubmit(giftCardId, onSuccess);
  const form = useForm(submit.initiate, initialValues);

  return (
    <div className={styles.root}>
      <div className={styles.newExpiryDate}>
        <InputLabel>
          {tCommon("modalEditGiftCardBalance.form.nextBalance")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={CURRENCY_SYMBOL.usd}
          value={form.balance.value}
          isError={!!form.balance.getError()}
          onChange={form.balance.onChange}
        />
        {!!form.balance.getError() && (
          <InputError>{form.balance.getError()}</InputError>
        )}
      </div>
      <div>
        <InputLabel>
          {tCommon("modalEditGiftCardBalance.form.reason")}
        </InputLabel>
        <Textarea
          value={form.reason.value}
          onChange={form.reason.onChange}
          isError={!!form.reason.getError()}
        />
        {!!form.reason.getError() && (
          <InputError>{form.reason.getError()}</InputError>
        )}
      </div>
      <div className={styles.footer}>
        <Button variant="outlined" onClick={onCancel}>
          {tCommon("modalEditGiftCardBalance.form.cancel")}
        </Button>
        <Button
          onClick={form.submit}
          isDisabled={submit.isLoading}
          leftAdornment={
            submit.isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tCommon("modalEditGiftCardBalance.form.submit")}
        </Button>
      </div>
    </div>
  );
};
