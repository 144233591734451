import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./SelectSecondary.module.scss";

const VARIANTS = {
  legacy: "legacy",
};

export function SelectSecondary({
  options,
  className,
  name,
  onChange,
  value,
  isError,
  isDisabled,
  variant,
}) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.error]: isError,
      [classes.disabled]: isDisabled,
      [classes.vLegacy]: variant === VARIANTS.legacy,
    },
    className,
  );

  return (
    <select
      className={rootClasses}
      name={name}
      onChange={onChange}
      value={value}
    >
      {options.map((option, index) => (
        <option key={`${index}-${option.value}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

SelectSecondary.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

SelectSecondary.defaultProps = {
  options: [],
  className: undefined,
  name: undefined,
  onChange: () => {},
  value: undefined,
  isError: false,
  isDisabled: false,
};
