import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import {
  showFormattedDate,
} from '../../../Utils/services.js';
import {
  resendInvitationEmail,
  inactivateUser,
  getSupporters,
  reactivateUser,
  selectedPrescribingSupportUser
} from '../../../Actions/Settings/settingsActions.js';
import Loader from '../../Common/Loader.js'
import { Link } from "react-router-dom";

const SupportUsers = (props) => {
  const { supportAgents, getSupporters, showLoadFromProps, resendInvitationEmail, inactivateUser, loadAgain, reactivateUser, selectedPrescribingSupportUser } = props;
  const [showLoader, setShowLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [localAgents, setLocalAgents] = useState([]);
  const [status, setStatus] = useState('active');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [totalAgents, setTotalAgents] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [stateLoadAgain, setStateLoadAgain] = useState(false);
  const paginationRef = useRef();
  const ePrescriptionSetup = (localStorage.getItem('ePrescriptionSetup')) ? JSON.parse(localStorage.getItem('ePrescriptionSetup')) : null;

  if (!ePrescriptionSetup.is_enrolled) {
    props.history.push('/settings/setup-eprescription');
  }

  useEffect(() => {
    if (localAgents.length == 0) {
      setShowLoader(true);
      let formData = {
        'params': {
          page: page,
          pagesize: perPage,
        }
      }
      getSupporters(status, formData);
    }
    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <= 5) {
        if (paginationRef && paginationRef.current) {
          paginationRef.current.click();
        }
      }
    };
  }, [])

  useEffect(() => {
    if (supportAgents) {
      setShowLoader(false);
      if (supportAgents && supportAgents.data.length) {
        setTotalAgents(supportAgents.total)
        if (localAgents.length > 0) {
          setLocalAgents([...localAgents, ...supportAgents.data]);
        } else {
          setLocalAgents(supportAgents.data)
        }
        setNextPageUrl(supportAgents.next_page_url);
        if (supportAgents.next_page_url != null) {
          setPage(page + 1);
        }
      }
    }
  }, [supportAgents])

  useEffect(() => {
    if (showLoadFromProps) {
      setShowLoader(false);
    }
  }, [showLoadFromProps])

  useEffect(() => {
    if (!stateLoadAgain && loadAgain) {
      setLocalAgents([])
      setShowLoader(true);
      setStateLoadAgain(loadAgain);
      let pagingData = {
        'params': {
          page: 1,
          pagesize: perPage
        }
      }
      getSupporters(status, pagingData);
    }
  }, [loadAgain])


  const changeStatus = (newStatus) => {
    if (status !== newStatus) {
      setShowLoader(true);
      setLocalAgents([])
      setStatus(newStatus)
      let formData = {
        'params': {
          page: 1,
          pagesize: perPage
        }
      }
      getSupporters(newStatus);
    }
  }

  const loadMore = () => {
    if (nextPageUrl != null && !showLoader) {
      setShowLoader(true);
      let formData = {
        'params': {
          page: page,
          pagesize: perPage
        }
      }
      getSupporters(status, formData);
    }
  }

  const sendInvite = (id) => {
    setShowLoader(true)
    resendInvitationEmail(id, 'support');
  }

  const changeUserStatus = (id, type) => {
    setStateLoadAgain(false);
    setShowLoader(true)
    let formData = { id, user_type: "support" }

    if (type === 'deactivate')  {
      inactivateUser(formData)
    } else {
      reactivateUser(formData)
    }
    inactivateUser(formData)
  }


  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <Sidebar/>
        <div className="business-setion memberWalletOuter">
          <div className="setting-setion m-b-10">
            <div className="membership-title">
              Support Users
            </div>
          </div>
          <React.Fragment>
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <a className={(status == "active" ? "newTabs" : "newTabs disabled-tab")}
                   onClick={() => changeStatus('active')}>Active</a>
                <a className={(status == "inactive" ? "newTabs" : "newTabs disabled-tab")}
                   onClick={() => changeStatus('inactive')}>Inactive</a>
                <a className={(status == "in_progress" ? "newTabs" : "newTabs disabled-tab")}
                   onClick={() => changeStatus('in_progress')}>In Progress</a>
                <div className="pull-right">
                  <a className="new-blue-btn m-l-0" href={process.env.REACT_APP_SCRIPT_SURE_ADMIN_URL} target="_blank">Go
                    to Admin Portal</a>
                  {ePrescriptionSetup.support_staff_limit > totalAgents && ePrescriptionSetup.is_practice_active &&
                  <Link to={"/settings/setup-eprescription/add/support-agent"} className="new-blue-btn m-l-10">Add
                    Support User</Link>
                  }
                </div>
              </div>
            </div>

            <div className="setting-setion m-b-10">
              <div className="table-responsive">
                <table className="table-updated setting-table no-td-border no-hover">
                  <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-3 table-updated-th">{"Support User"}</th>
										<th className="col-xs-3 table-updated-th">{"Email"}</th>
                    <th className="col-xs-3 table-updated-th">{"Created Date"}</th>
                    {(status === "in_progress") && <th className="col-xs-3 table-updated-th"></th>}
                    {(status !== "in_progress") && <th className="col-xs-3 table-updated-th">{"Actions"}</th>}
                  </tr>
                  </thead>
                  <tbody>
                  {localAgents && localAgents.length > 0 && localAgents.map((obj, idx) => {
                    const selectedPrescriber = {
                      firstname: obj.first_name,
                      lastname: obj.last_name,
                      email: obj.email,
                      id: obj.provider_id
                    }

                    return (
                      <tr>
                        <td
                          className="col-xs-3 table-updated-td">{obj.first_name + ' ' + obj.last_name}</td>
                        <td className="col-xs-3 table-updated-td">{obj.email}</td>
                        <td className="col-xs-3 table-updated-td">{showFormattedDate(obj.created)}</td>
                        {(status === "in_progress") &&
                        <td onClick={() => sendInvite(obj.id)} className="col-xs-3 table-updated-td"><a
                          class="easy-link " data-title="Resend Invitation Email">Resend Invitation Email</a></td>}
                        {(status !== "in_progress") &&
                        <td className="col-xs-3 table-updated-td">
                           {status === "active" ? 
                          <button onClick={() => {
                            changeUserStatus(obj.id, 'deactivate')
                          }} className="new-blue-btn m-t-10 text-no-wrap no-margin width-100" type="button">Inactivate Support User
                          </button> 
                          :
                          <button onClick={() => {
                            changeUserStatus(obj.id, 'reactivate')
                          }} className="new-blue-btn m-t-10 text-no-wrap no-margin width-100" type="button">Reactivate Support User
                          </button>
                          }
                          {obj.is_upgradeable && <>
                            <Link to={`/settings/setup-eprescription/1/${obj.provider_id}`} onClick={() => selectedPrescribingSupportUser(selectedPrescriber)} className="new-line-btn m-t-10 no-margin width-100 text-center">Add EPCS Prescriber</Link>
                            <Link to={`/settings/setup-eprescription/2/${obj.provider_id}`} onClick={() => selectedPrescribingSupportUser(selectedPrescriber)} className="new-blue-btn m-t-10 text-no-wrap no-margin width-100">Add Non-EPCS Prescriber</Link>
                          </>
                          }
                        </td>
                        }
                      </tr>
                    )
                  })}

                  {localAgents && localAgents.length == 0 &&
                  <tr>
                    <td
                      className={(status == "in_progress") ? "col-xs-12 table-updated-th text-center" : "col-xs-9 table-updated-th text-center"}
                      colSpan={(status == "in_progress") ? 5 : 3}>
                      <div className={"no-record"}>
                        {"No Record Found"}
                      </div>
                    </td>
                  </tr>
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        </div>
        <button className="btn hide-btn invisible" onClick={() => loadMore()} ref={paginationRef}>Load More</button>
      </div>
      <Loader showLoader={showLoader} isFullWidth={true}/>
    </div>
  )
}

function mapStateToProps (state) {
  const returnState = {
    loadAgain: false
  };
  if (state.SettingReducer.action === "SUPPORT_AGENT_LIST") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoadFromProps = new Date()
    } else {
      returnState.supportAgents = state.SettingReducer.data.data
    }
  }

  if (state.SettingReducer.action === "SEND_EMAIL_SUPPORT_AGENT") {
    returnState.showLoadFromProps = new Date()
  }

  if (state.SettingReducer.action === "INACTIVATE_SUPPORT_AGENT") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoadFromProps = new Date()
    } else {
      returnState.loadAgain = true;
    }
  }

  return returnState;
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getSupporters,
    resendInvitationEmail,
    inactivateUser,
    reactivateUser,
    selectedPrescribingSupportUser
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportUsers);
