import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import {
  getSyncData,
  setSyncConfiguration,
  getEditSyncData,
  exportEmptyData,
  getZapierDetails,
  changeRefreshStatus,
  switchONZapier,
  switchOFFZapier
} from '../../../Actions/Integrations/integrationActions.js';
import { getIsAcceleratorPlan, positionFooterCorrectly, showFormattedDate } from '../../../Utils/services';
import UpgradeAccountModal from '../../Common/UpgradeAccountModal';
import { uiNotification } from '../../../services/UINotificationService.js';

class ZapierIntegration extends Component {
  constructor (props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      sync: false,
      isEditSyncSettings: false,
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      all_patient: false,
      newly_created: false,
      apiKeyError: false,
      showLoader: false,
      editClicked: false,
      touch_md_sync_type: 'new',
      account_id: '',
      id: '',
      touch_md_sync_all_status: '',
      hide: true,
      secret_key_encrypted: '',
      secret_key: '',
      api_key: '',
      status: 0,

      isShowUpgradeAlertModal: !this.isIntegrationToggleEnabled(),
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ showLoader: true });
    if (target.type === 'checkbox' && value == true && event.target.name == 'sync') {
      let formData = {
        refresh_credential: 0,
        sync_status: 1
      };
      this.props.switchONZapier(formData);
    }
    if (target.type === 'checkbox' && value == false && event.target.name == 'sync') {
      let formData = {
        refresh_credential: 0,
        sync_status: 0
      };
      this.props.switchOFFZapier(formData);

    }
  };

  componentDidMount () {
    if (this.isIntegrationToggleEnabled()) {
      this.setState({ showLoader: true });
      this.props.getZapierDetails();
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let returnState = {};

    if (nextProps.invokeRedirect) {
      nextProps.history.push('/settings/profile');
    }

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      nextProps.exportEmptyData();
      positionFooterCorrectly();
    }

    if (nextProps.zapierData != undefined && nextProps.zapierDataTime != prevState.zapierDataTime) {
      returnState.zapierDataTime = nextProps.zapierDataTime;
      returnState.sync = nextProps.zapierData.is_enabled == 1 ? true : false;
      returnState.api_key = nextProps.zapierData.api_key;

      let length = 0;
      let secret_key_encrypted = '';
      while (length < nextProps.zapierData.api_secret.length) {
        secret_key_encrypted += 'x';
        length = length + 1;
      }
      returnState.secret_key_encrypted = secret_key_encrypted;
      returnState.secret_key = nextProps.zapierData.api_secret;
      returnState.showLoader = false;
      returnState.modifiedDate = nextProps.zapierData && nextProps.zapierData.created ? nextProps.zapierData.created.date : '';
      // returnState.modified
    }

    if (nextProps.refreshCredzapierData != undefined && nextProps.refreshCredzapierDataTime != prevState.refreshCredzapierDataTime) {
      returnState.api_key = nextProps.refreshCredzapierData.api_key;
      returnState.showModal = false;
      returnState.refreshCredzapierDataTime = nextProps.refreshCredzapierDataTime;
      let length = 0;
      let secret_key_encrypted = '';
      while (length < nextProps.refreshCredzapierData.api_secret.length) {
        secret_key_encrypted += 'x';
        length = length + 1;
      }
      returnState.secret_key_encrypted = secret_key_encrypted;
      returnState.secret_key = nextProps.refreshCredzapierData.api_secret;
      returnState.modifiedDate = nextProps.refreshCredzapierData && nextProps.refreshCredzapierData.created ? nextProps.refreshCredzapierData.created : '';
      returnState.showLoader = false;
    }

    if (nextProps.zapierOffDataTime != prevState.zapierOffDataTime) {
      returnState.zapierOffDataTime = nextProps.zapierOffDataTime;
      returnState.sync = false;
    }

    if (nextProps.zapierOnData != undefined && nextProps.zapierOnDataTime != prevState.zapierOnDataTime) {
      returnState.zapierOnDataTime = nextProps.zapierOnDataTime;
      returnState.sync = true;
      returnState.api_key = nextProps.zapierOnData.api_key;
      returnState.secret_key = nextProps.zapierOnData.api_secret;
      returnState.modifiedDate = nextProps.zapierOnData.created ? nextProps.zapierOnData.created : '';
      let length = 0;
      let secret_key_encrypted = '';
      while (length < nextProps.zapierOnData.api_secret.length) {
        secret_key_encrypted += 'x';
        length = length + 1;
      }
      returnState.secret_key_encrypted = secret_key_encrypted;
    }
    return returnState;

  }

  showSecretKey = () => {
    this.setState({ hide: false, status: 1 });
  };

  hideSecretKey = () => {
    this.setState({ hide: true, status: 0 });
  };

  refreshCredentials = () => {
    this.setState({ showLoader: true });
    let formData = {
      refresh_credential: 1,
      sync_status: 1
    };
    this.props.changeRefreshStatus(formData);
  };

  copyAPIKey = () => {
    var copyText = document.getElementById('api_key_data');
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
  };

  copySecretKey = () => {
    var copyText = document.getElementById('secret_key_data');
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    if (this.state.hide == false && this.state.status == 0) {
      this.setState({ hide: true });
    }

  };

  showAndCopy = () => {
    this.setState({ hide: false }, () => {
      this.copySecretKey();
    });

  };
  openRefreshCredsPopup = () => {
    this.setState({ showModal: true });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState({ isShowUpgradeAlertModal: state.isShowUpgradeAlertModal });
  };

  isIntegrationToggleEnabled = () => getIsAcceleratorPlan();

  render () {
    return (
      <div id="content">
        <UpgradeAccountModal notificaionType={'integration'}
                             isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
                             handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}/>
        <div className="container-fluid content setting-wrapper">
          <Sidebar/>
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">Sync Zapier
                <div className="memberRightActions">
                  <span className="switch-text"></span>
                  <label className="setting-switch pull-right">
                    <input type="checkbox" name="sync" id="sync"
                           disabled={!this.isIntegrationToggleEnabled()}
                           checked={this.state.sync && this.isIntegrationToggleEnabled()}
                           onChange={this.handleInputChange}/>
                    <span className="setting-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {(this.state.sync === true && this.isIntegrationToggleEnabled()) &&
                <div className="col-sm-12">
                  <div className="setting-setion">
                    <div className="membershipSectionTitle">{this.state.settingsLang.label_sync_settings}</div>
                    <div className="wallet-edit-block">
                      <div className="membershipSectionContent  p20">
                        <div className="row">
                          <div className="col-xs-8">
                            <p className={'AuthorizedSync'}><i
                              className="fa fa-check-circle"></i> {this.state.clicked ? 'Authorized' : 'Authorized on' + ' ' + showFormattedDate(this.state.modifiedDate)}
                            </p>
                          </div>
                          <div className="col-xs-4">
                            <button className={'new-blue-btn pull-right m-l-0 m-b-10'}
                                    onClick={() => this.openRefreshCredsPopup()}>Refresh Credentials
                            </button>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-6">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">API Key<span className="setting-require">*</span></div>
                              <div className="setting-input-outer">
                                <input name="api_key" className={'newInputField'} type="text" id="api_key_data"
                                       value={this.state.api_key} readOnly={true}
                                       autoComplete="off"/>
                              </div>
                              {/* <a onClick={this.changeURLValue} className="editField"><i className='fa fa-clone'></i></a> */}
                            </div>
                          </div>
                          <div className="col-xs-6 p-l-0">
                            <a className="new-line-btn no-width no-margin m-t-20" onClick={this.copyAPIKey}>Copy</a>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-xs-6">
                            {this.state.hide == true && <div className="newInputFileldOuter">
                              <div className="newInputLabel">Secret Key<span className="setting-require">*</span></div>
                              <div className="setting-input-outer">
                                <input name="secret_key_encrypted" className={'newInputField'} type="text"
                                       value={this.state.secret_key_encrypted} readOnly={true}
                                       autoComplete="off"/>
                              </div>
                            </div>}
                            {this.state.hide == false && <div className="newInputFileldOuter">
                              <div className="newInputLabel">Secret Key<span className="setting-require">*</span></div>
                              <div className="setting-input-outer">
                                <input name="secret_key" className={'newInputField'} type="text" id="secret_key_data"
                                       value={this.state.secret_key} readOnly={true}
                                       autoComplete="off"/>
                              </div>
                            </div>}
                          </div>
                          <div className="col-xs-6 p-l-0">
                            <a className="new-line-btn no-width no-margin m-t-20" onClick={this.showAndCopy}>Copy</a>
                            {this.state.hide == true &&
                              <a className="new-line-btn no-width m-t-20" onClick={this.showSecretKey}>Show</a>}
                            {this.state.hide == false &&
                              <a className="new-line-btn no-width m-t-20" onClick={this.hideSecretKey}>Hide</a>}
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>}
            </div>
          </div>
          <div className={(this.state.showModal ? 'overlay' : '')}></div>
          <div id="filterModal" role="dialog"
               className={(this.state.showModal ? 'modal fade in displayBlock' : 'modal fade')}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" onClick={this.dismissModal}>×</button>
                  <h4 className="modal-title" id="model_title">Confirmation Required{this.state.showModal}</h4>
                </div>
                <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                  {'Are you sure you want to refresh credentials? Your previous credentials will not work anymore.'}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button type="button" className="btn  logout pull-right" data-dismiss="modal"
                            onClick={this.dismissModal}>{this.state.settingsLang.no_option}</button>
                    <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal"
                            onClick={this.refreshCredentials}>{this.state.settingsLang.yes_option}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Loader showLoader={this.state.showLoader} isFullWidth={true}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));

  const returnState = {};
  if (state.IntegrationReducer.data.status === 402) {
    returnState.invokeRedirect = true;
  }

  if (state.IntegrationReducer.action === 'GET_ZAPIER_DATA') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.zapierData = state.IntegrationReducer.data.data;
      returnState.zapierDataTime = new Date();
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'CHANGE_ZAPIER_REFRESH_CREDS') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.refreshCredzapierData = state.IntegrationReducer.data.data;
      returnState.refreshCredzapierDataTime = new Date();
      uiNotification.success('Credentials updated successfully');
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'SWITCH_ZAPIER_OFF') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.zapierOffDataTime = new Date();
      uiNotification.success('Sync status updated successfully.');
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'SWITCH_ZAPIER_ON') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.zapierOnData = state.IntegrationReducer.data.data;
      returnState.zapierOnDataTime = new Date();
      uiNotification.success('Sync status updated successfully.');
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  return returnState;
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getZapierDetails: getZapierDetails,
    switchONZapier: switchONZapier,
    switchOFFZapier: switchOFFZapier,
    changeRefreshStatus: changeRefreshStatus,
    getSyncData: getSyncData,
    setSyncConfiguration: setSyncConfiguration,
    getEditSyncData: getEditSyncData,
    exportEmptyData: exportEmptyData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ZapierIntegration);
