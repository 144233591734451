const initialState = {
  stripeIntent: {},
  stripeIntentError: {},
  cardList: null,
};

const StripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STRIPE_INTENT_RESET": {
      return { ...state, stripeIntent: {} };
    }
    case "CANCEL_STRIPE_SETUP_INTENT": {
      return { ...state, stripeIntent: null };
    }
    case "GET_STRIPE_INTENT_SUCCESS": {
      return { ...state, stripeIntent: action.payload.data };
    }
    case "GET_STRIPE_INTENT_ERROR": {
      return { ...state, stripeIntentError: action.payload.data };
    }
    case "GET_CLIENT_STRIPE_CARDS": {
      return { ...state, cardList: action.payload };
    }

    default:
      return state;
  }
};

export default StripeReducer;
