/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import Header from "../../../_legacy/Containers/Guest/Header.js";
import Footer from "../../../_legacy/Containers/Guest/Footer.js";
import FooterTouchMD from "../../../_legacy/Containers/Guest/FooterTouchMD.js";

class CandelaAccountSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadLink:
        window.location.origin + "/quick-start/Quick_Start_Training_Agenda.pdf",
      url: props.match.url,
    };
  }

  componentDidMount() {
    var _ubaq = _ubaq || [];
    _ubaq.push(["trackGoal", "convert"]);
    (function () {
      var ub_script = document.createElement("script");
      ub_script.type = "text/javascript";
      ub_script.src =
        ("https:" == document.location.protocol ? "https://" : "http://") +
        process.env.REACT_APP_CLOUDFRONT_URL;
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(ub_script, s);
    })();
  }

  render() {
    return (
      <div className="guest">
        {this.state.url == "/candela-account/success" ? (
          <div className="header touchMDHeader">
            <div className="wrapper">
              <div className="mdWrapper">
                <a className="logo">
                  <img alt="" src="/images/logo.png?v=123" />
                </a>
                <a className="candelaLogo">
                  <img alt="" src="/images/candelaMedicalLogo.png" />
                </a>
              </div>
              <div className="header-right">
                <ul className="nav">
                  <li>
                    <Link to="/login" className="login">
                      Login
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <Header />
        )}
        <div className="sign-up-account-setup">
          <div className="container">
            <div className="thanku signup-sucessful signup-quick-setup">
              <h2 id="currentStatus">
                Congratulations on Starting Your Aesthetic Record!
              </h2>
              <h3>
                {" "}
                You are one step closer to managing your entire practice with
                one simple solution.
              </h3>
              <iframe
                title="yt"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-VaDnIkK2k4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="signup-sucessful-wrapper">
                <p>
                  Download the Aesthetic Record Training Agenda &amp; Schedule
                  Your First Onboarding Session
                </p>
                <a href={this.state.downloadLink} target="blank">
                  <img
                    src="/images/PDF-screenshot.png"
                    alt="Quick Start Training Agenda"
                  />
                </a>
                <div>
                  <a href={this.state.downloadLink} className="downloadPDF">
                    Download PDF
                  </a>
                </div>
                <div>
                  <p />
                  <a
                    href="https://calendly.com/aesthetic-record-team"
                    className="signup-btn"
                  >
                    Book with your AR coach
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.url == "/candela-account/success" ? (
          <FooterTouchMD />
        ) : (
          <Footer />
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CandelaAccountSuccess));
