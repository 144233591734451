import React from "react";
import moment from "moment";
import { Box } from "../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../../../../consts/general";
import classes from "./Table.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";

export function Table({ data, restore }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const preparedData = data.value.map((r) => ({
    key: r.id,

    clinicName: r.clinic.clinic_name,

    patientName: r.patient.full_name,

    providerName: r.provider?.full_name || tCommon("symbol.longDash"),

    dateTime: moment(r.appointment_datetime).format(`
      ${user?.dateFormat || DEFAULT_DATE_FORMAT} ${
      user?.timeFormat || DEFAULT_TIME_FORMAT
    }`),

    action: (
      <Button
        size="small"
        isDisabled={restore.loadingIds.includes(r.id)}
        onClick={() => {
          restore.setOne(r.id);
          restore.requestConfirm();
        }}
        leftAdornment={
          restore.loadingIds.includes(r.id) ? (
            <CircularProgress size="tiny" color="white" />
          ) : undefined
        }
      >
        {tCommon("label.restore")}
      </Button>
    ),
  }));

  return (
    <Box>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={preparedData}
            cols={[
              {
                data: tCommon("label.clinic"),
                accessor: "clinicName",
                headColClassName: classes.colMaxWidth,
              },
              {
                data: tCommon("label.patientName"),
                accessor: "patientName",
                headColClassName: classes.colMaxWidth,
              },
              {
                data: tCommon("label.providerName"),
                accessor: "providerName",
                headColClassName: classes.colMaxWidth,
              },
              {
                data: tCommon("label.date"),
                accessor: "dateTime",
              },
              {
                data: "",
                accessor: "action",
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}
