import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { dateFormatPicker, showFormattedDate } from "../../../Utils/services";
import { apiDateFormat } from "../../../Utils/date-services"
import "./manageReferralCategories.scss";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addReferralSourceSubcategory, getReferralSources, changeReferralSubcategoryStatus, editReferralSubcategoryName, deleteSubcategorySource } from '../../../Actions/referralSourcesActions';
import Loader from '../../Common/Loader.js';
import ConfirmationModal from '../../Appointment/Calendar/ConfirmationModal/ConfirmationModal.js';

class ReferralSourcesManageCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceSubcategoryName: '',
            fromDate: '',
            toDate: '',
            showDeactivateModal: false,
            selectedSubcategory: {},
            editableSubcategory: '',
            showDeleteModal: false,
            editedSubcategoryName: '',
            activateModeSubcategory: '',
            editFromDate: '',
            editToDate: '',
        }
    }

    

    handleSubcategorySourceInput = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleFromDatePicker = (date, editMode) => {
        if (editMode) {
            this.setState({ editFromDate: apiDateFormat(date), editToDate: '' })
        } else {
            this.setState({ fromDate: apiDateFormat(date) })
        }
    }

    handleToDatePicker = (date, editMode) => {
        if (editMode) {
            this.setState({ editToDate: apiDateFormat(date) })
        } else {
            this.setState({ toDate: apiDateFormat(date) })
        }
    }

    toggleCalendar = () => {
        this.refToDatePicker.setOpen(false)
        this.refFromDatePicker.setOpen(false)
    }

    addSubcategory = () => {
        let formData = {
            source_code: this.props.selectedSource.code,
            subcategory_name: this.state.sourceSubcategoryName,
            from: this.state.fromDate,
            to: this.state.toDate,
        }
        this.props.addReferralSourceSubcategory(formData)
    }

    activateModeSubcategory = (subcategory) => { 
        this.setState({ activateModeSubcategory: subcategory.name, editableSubcategory: '', editFromDate: apiDateFormat(new Date(moment())) })
    }

    activateSubcategory = (id) => {
        const formData = {
            subcategory_id: id,
            source_code: this.props.selectedSource.code,
            status: true,
            from: this.state.editFromDate,
            to: this.state.editToDate,
        }
        this.props.changeReferralSubcategoryStatus(formData)
        this.setState({ activateModeSubcategory: '' })
    }

    changeStatus = (subcategory) => {
        const { id, status, from } = subcategory

        if (!status) {
            this.activateModeSubcategory(subcategory)
            return;
        }

        let formData = {
            subcategory_id: id,
            source_code: this.props.selectedSource.code,
            status: status ? false : true,
            to: apiDateFormat(new Date(moment())),
            from: from,
        }

        this.props.changeReferralSubcategoryStatus(formData)
    }

    editSubcategory = (subcategory) => {
        const editFromDate = new Date(subcategory.from).getTime() < new Date().getTime() ? apiDateFormat(new Date()) : subcategory.from

        this.setState({ editableSubcategory: subcategory.name, editedSubcategoryName: subcategory.name, selectedSubcategory: { name: subcategory.name, id: subcategory.id }, editFromDate: editFromDate, editToDate: subcategory.to, activateModeSubcategory: '' })
    }

    saveEditedSource = () => {
        let formData = {
            subcategory_name: this.state.editedSubcategoryName,
            source_code: this.props.selectedSource.code,
            subcategory_id: this.state.selectedSubcategory.id,
            from: this.state.editFromDate,
            to: this.state.editToDate,
        }
        this.props.editReferralSubcategoryName(formData)
        this.setState({ editableSubcategory: '' })
    }

    deleteSubcategory = () => {
        this.props.deleteSubcategorySource({ source_code: this.props.selectedSource.code, subcategory_id: this.state.selectedSubcategory.id })
        this.setState({ showDeleteModal: false })
    }

    handleSave = (subcategory) => {
        if (subcategory.name !== this.state.activateModeSubcategory) {
            this.saveEditedSource()
        } else {
            this.activateSubcategory(subcategory.id)
        }
    }

    renderSubcategories = () => {
        const selectedSource = Object.values(this.props.referralSources.data || {}).find(el => el.source_name === this.props.selectedSource.name)
        const subcategoriesTable = selectedSource.subcategories.map(subcategory => {
        const fromDate = subcategory.name !== this.state.activateModeSubcategory ? showFormattedDate(subcategory.from) : showFormattedDate(apiDateFormat(new Date(moment())))

            if(!subcategory.is_deleted) {
                return <tr key={subcategory.id}>
                        <td className="col-xs-3 table-updated-th">
                            {subcategory.name !== this.state.editableSubcategory ? 
                                subcategory.name :
                                <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Subcategory<span className="setting-require">*</span></div>
                                    <input type="text" placeholder="Subcategory Name" className="newInputField" name="editedSubcategoryName" value={this.state.editedSubcategoryName} onChange={(event) => this.handleSubcategorySourceInput(event)} autoFocus />
                                </div>
                            }
                        </td>
                        <td className="col-xs-2 table-updated-th text-center">
                            {subcategory.name !== this.state.editableSubcategory ? 
                            fromDate : 
                            <div className='field-icon appDateTime m-r-10'>
                                <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Activate On<span className="setting-require">*</span></div>
                                    <DatePicker
                                        onFocus={() =>  this.setState({ editFromDate: apiDateFormat(moment().toDate()) })}
                                        dateFormat={dateFormatPicker()}
                                        placeholderText={'mm/dd/yyyy'}
                                        value={this.state.editFromDate && showFormattedDate(this.state.editFromDate)}
                                        onChange={(date) => this.handleFromDatePicker(date, true)}
                                        className={"simpleInput"}
                                        name="editFromDate"
                                        autoComplete="off"
                                        ref={(refToDatePicker) => this.refToDatePicker = refToDatePicker}
                                        onClickOutside={this.toggleCalendar}
                                        minDate={new Date(moment())}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                         }}
                                        />
                                    <a className="simple-cal-icon iconPointEvents"></a>
                                </div>
                            </div>}
                            
                        </td>
                        <td className="col-xs-2 table-updated-th text-center">
                            {subcategory.name !== this.state.editableSubcategory && subcategory.name !== this.state.activateModeSubcategory ? 
                            showFormattedDate(subcategory.to) : 
                            <div className='field-icon appDateTime'>
                                <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Deactivate On <span className="setting-require">*</span></div>
                                    <DatePicker
                                        onFocus={() =>  this.setState({ editToDate: apiDateFormat(moment().toDate()) })}
                                        dateFormat={dateFormatPicker()}
                                        placeholderText={'mm/dd/yyyy'}
                                        value={this.state.editToDate && showFormattedDate(this.state.editToDate)}
                                        onChange={(date) => this.handleToDatePicker(date, true)}
                                        className={"newInputField"}
                                        name="editToDate"
                                        autoComplete="off"
                                        ref={(refFromDatePicker) => this.refFromDatePicker = refFromDatePicker}
                                        onClickOutside={this.toggleCalendar}
                                        minDate={new Date(moment(this.state.editFromDate))}
                                        placeholder={'---'}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                         }}
                                    />
                                    <a className="simple-cal-icon iconPointEvents"></a>
                                    </div>
                            </div>
                            }
                            
                        </td>
                        <td className="col-xs-4 table-updated-th text-nowrap">
                            {subcategory.name !== this.state.editableSubcategory && subcategory.name !== this.state.activateModeSubcategory ?
                            <>
                                <a className="new-line-btn no-margin m-l-5 text-center" onClick={() => this.changeStatus(subcategory)}>{subcategory.status ? 'Deactivate' : 'Activate'}</a>
                                <a className="m-r-0 edit_setting easy-link p-l-20" onClick={() => this.editSubcategory(subcategory)}><i className="fa fa-pencil-alt m-r-5" />Edit</a>
                                <a className="m-r-0 edit_setting easy-link p-l-5" onClick={() => this.setState({ showDeleteModal: true, selectedSubcategory: { id: subcategory.id, name: subcategory.name } })}><i className="fa fa-trash-alt m-r-5" />Delete</a>
                            </> : 
                            <>
                                <button type="button" className="new-blue-btn m-l-5 m-r-10 m-t-5" onClick={() => this.handleSave(subcategory)}>Save</button>
                                <button className="new-white-btn cancelAction m-t-5" onClick={() => this.setState({ editableSubcategory: '', activateModeSubcategory: '', editFromDate: '', editToDate: '' })}>Cancel</button>
                            </>}
                        </td>
                    </tr>
            }
        })
        return <table className="table-updated setting-table survey-table no-hover no-td-border">
                        <thead className="table-updated-thead">
                        <tr>
                            <th className="col-xs-3 table-updated-th">Subcategory</th>
                            <th className="col-xs-2 table-updated-th text-center">Active From</th>
                            <th className="col-xs-2 table-updated-th text-center">Expires At</th>
                            <th className="col-xs-4 table-updated-th text-center"></th>
                        </tr>
                    </thead>
                    <tbody className="ajax_body">
                        {subcategoriesTable}
                    </tbody>
                </table>

    }

    render() {
        return (
            <>
                <div className='juvly-section full-width'>
                        <div className='membership-title'>
                            Subcategories
                            <a className="pull-right" onClick={() => this.props.handleBackButton()} ><img src="/images/close.png" /></a>
                        </div>
                        <div className="row p-l-20 addSubcategoryRow">
                            <div className='col-xs-4'>
                                <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Add Subcategory</div>
                                    <input type="text" placeholder="Subcategory Name" className="newInputField" name="sourceSubcategoryName" value={this.state.sourceSubcategoryName} onChange={(event) => this.handleSubcategorySourceInput(event)} />
                                </div>
                            </div>
                            <div className='field-icon appDateTime m-r-10'>
                                <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Activate On<span className="setting-require">*</span></div>
                                    <DatePicker
                                        onFocus={() =>  this.setState({ fromDate: apiDateFormat(moment().toDate()) })}
                                        dateFormat={dateFormatPicker()}
                                        placeholderText={'mm/dd/yyyy'}
                                        value={this.state.fromDate && showFormattedDate(this.state.fromDate)}
                                        onChange={(date) => this.handleFromDatePicker(date)}
                                        className={"simpleInput"}
                                        name="fromDate"
                                        autoComplete="off"
                                        ref={(refToDatePicker) => this.refToDatePicker = refToDatePicker}
                                        onClickOutside={this.toggleCalendar}
                                        minDate={new Date(moment())}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                         }}
                                        />
                                    <a className="simple-cal-icon iconPointEvents"></a>
                                </div>
                            </div>
                            <div className='field-icon appDateTime'>
                                <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Deactivate On <span className="setting-require">*</span></div>
                                    <DatePicker
                                        onFocus={() =>  this.setState({ toDate: apiDateFormat(moment().toDate()) })}
                                        dateFormat={dateFormatPicker()}
                                        placeholderText={'mm/dd/yyyy'}
                                        value={this.state.toDate && showFormattedDate(this.state.toDate)}
                                        onChange={(date) => this.handleToDatePicker(date)}
                                        className={"newInputField"}
                                        name="toDate"
                                        autoComplete="off"
                                        ref={(refFromDatePicker) => this.refFromDatePicker = refFromDatePicker}
                                        onClickOutside={this.toggleCalendar}
                                        minDate={new Date(moment(this.state.fromDate))}
                                        placeholder={'---'}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                         }}
                                    />
                                    <a className="simple-cal-icon iconPointEvents"></a>
                                    </div>
                            </div>
                            <div className='col-xs-2 p-t-5'>
                                <a className="new-blue-btn pull-right edit_setting" onClick={() => this.addSubcategory()}>Add</a>
                            </div>
                        </div>
                        {this.renderSubcategories()}
                        
                </div>
                <Loader showLoader={this.props.showLoader} isFullWidth={true} />
                <ConfirmationModal isOpen={this.state.showDeleteModal} message={"Are you sure you want to detele this subcategory?"} confirmFn={() => this.deleteSubcategory()} cancelFn={() => this.setState({ showDeleteModal: false })} />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        referralSources: state.ReferralSourceReducer.referralSources,
        showLoader: state.ReferralSourceReducer.showLoader,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addReferralSourceSubcategory,
        getReferralSources,
        changeReferralSubcategoryStatus,
        editReferralSubcategoryName,
        deleteSubcategorySource
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSourcesManageCategories);