import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../shared/Button/Button";
import { svg } from "../../../../../../assets/svg";
import { DateRangePicker } from "../../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { ReportHeader } from "../../../../../BusinessInsights/components/ReportHeader/ReportHeader";
import { TotalCount } from "../../../../../BusinessInsights/components/TotalCount/TotalCount";
import DownloadDropdown from "../../../../components/DownloadDropdown";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export function Header({
  isFilterActive,
  onFilterOpen,
  period,
  isReportsLoading,
  totalCount,
  requestParams,
}) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader
      label={tBi("servicesPerformedReport.label")}
      left={<TotalCount>{totalCount}</TotalCount>}
    >
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />
      <DownloadDropdown
        params={requestParams}
        downloadRoute={HTTP_ENDPOINTS.getServiceSales()}
      />
      <Button
        size="small"
        variant="outlined"
        onClick={onFilterOpen}
        isDisabled={isReportsLoading}
        leftAdornment={
          isFilterActive ? (
            <img src={svg.filterPrimaryActive} alt="filter" width="16px" />
          ) : (
            <img src={svg.filterPrimary} alt="filter" width="14px" />
          )
        }
      >
        {tCommon("label.filter")}
      </Button>
    </ReportHeader>
  );
}

Header.propTypes = {
  isFilterActive: PropTypes.bool.isRequired,
  onFilterOpen: PropTypes.func.isRequired,
  period: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    update: PropTypes.func,
  }).isRequired,
  downloadExcel: PropTypes.shape({
    initiate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
};

Header.defaultProps = {
  totalCount: 0,
};
