import { QUESTIONNAIRE_TYPES } from "../../consts/api";
import { isNullish } from "../../utilities/general";

/**
 * @template {{
 *    id: number;
 *    question: string;
 * }} Question
 * @param {{
 *    questionnaire: {
 *      type: number;
 *      simple_questions: Array<Question>;
 *      multi_questions: Array<Question>;
 *    };
 *    answers: Array<{
 *      question_id: number;
 *      answer: Array<string>;
 *    }>;
 * }} patientQuestionnaire
 * @returns {Array<{
 *    id: number;
 *    question: string;
 *    answers: string[];
 * }>}
 */
export function collectAnsweredQuestions(patientQuestionnaire) {
  const questionnaireType = patientQuestionnaire?.questionnaire?.type;

  if (!isNullish(questionnaireType)) {
    const questions =
      questionnaireType === QUESTIONNAIRE_TYPES.simple
        ? patientQuestionnaire?.questionnaire?.simple_questions || []
        : patientQuestionnaire?.questionnaire?.multi_questions || [];

    const questionIdToAnswerMap = patientQuestionnaire?.answers?.reduce(
      (carry, answer) => ({
        ...carry,
        [answer.question_id]: answer,
      }),
      {},
    );

    return (
      questions?.map((question) => {
        const answer = questionIdToAnswerMap[question.id];
        return {
          id: question.id,
          question: question.question,
          answers: answer?.answer,
          comment: answer?.comment,
        };
      }) || []
    );
  }

  return [];
}
