import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./sass/File.module.scss";
import { svg } from "../../../../assets/svg";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { fileUtil } from "../../../../utilities/file";

export function File({
  name,
  isSelected,
  onSelect,
  onRemove,
  isPending,
  isDisabled,
}) {
  return (
    <div
      className={cx(classes.file, {
        [classes.fileSelected]: isSelected,
        [classes.disabled]: isDisabled,
      })}
    >
      <button
        className={classes.fileBtn}
        type="button"
        title={name}
        onClick={onSelect}
        disabled={isDisabled}
      >
        {fileUtil.shortenName(name)}
      </button>
      {isPending ? (
        <CircularProgress size="small" />
      ) : (
        <button
          type="button"
          className={classes.closeBtn}
          onClick={onRemove}
          disabled={isDisabled}
        >
          <img src={svg.closeRed} alt="x" width="12px" />
        </button>
      )}
    </div>
  );
}

File.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
