import { useVerifyOtpMutation } from "../../api/phone/useVerifyOtpMutation";
import { extractApiError } from "../../utilities/api";
import { uiNotification } from "../../services/UINotificationService";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { phoneUtil } from "../../utilities/phone";

export function useVerifyOtp({ onError, onSuccess } = {}) {
  const { tCommon } = useAppTranslation.Common();

  const verifyMutation = useVerifyOtpMutation({
    onError: (e) => {
      const message = extractApiError(e);
      uiNotification.error(message);
      onError?.();
    },
    onSuccess: () => {
      uiNotification.success(tCommon("success.verifyOtp"));
      onSuccess?.();
    },
  });

  const verify = async (phone, otp) => {
    if (!phoneUtil.isPossible(phoneUtil.compose(phone))) {
      return uiNotification.error(tCommon("error.invalidPhone"));
    }

    if (!otp) {
      return uiNotification.error(tCommon("error.invalidOtp"));
    }

    return await verifyMutation.mutateAsync({ phone_number: phone, otp });
  };

  return {
    initiate: verify,
    isLoading: verifyMutation.isLoading,
  };
}
