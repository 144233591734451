import { useFormik } from "formik";
import * as yup from "yup";

export function useForm({ initialClinic, onSubmit }) {
  const schema = yup.object({
    clinic: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .required(),
  });

  const form = useFormik({
    onSubmit,
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      clinic: initialClinic || null,
    },
  });

  return form;
}
