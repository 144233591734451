import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import { authWorkspaceSchema, composeAuthWorkspace } from "../../schemas/auth";

const requestSchema = yup.object({
  wpuId: yup.number().nullable(),
});

const responseSchema = yup.array().of(authWorkspaceSchema).required();

const composeResponse = (res) => {
  const workspaces = unwrapOr(() => res.data.data, []);
  return workspaces.map(composeAuthWorkspace);
};

function createWorkspacesQueryKey({ wpuId }) {
  return [QUERY_KEYS.workspaces, wpuId];
}

export function useWorkspacesQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createWorkspacesQueryKey({ wpuId: req.wpuId }),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getWorkspaces(req.wpuId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
