import React from "react";
import classes from "./Footer.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useClientQuery } from "../../../../api/queries/useClientQuery";

export function Footer({ witness, patient }) {
  const { tCommon } = useAppTranslation.Common();

  const { data: patientQueryData } = useClientQuery({
    clientId: patient.id,
  });

  return (
    <div className={classes.root}>
      <div>
        {witness.signatureUrl && (
          <div className={classes.signatureBlock}>
            <div
              className={classes.signature}
              style={{
                background: `url(${witness.signatureUrl}) no-repeat center center / contain`,
              }}
            />
            <span>{tCommon("label.witnessSignature")}</span>
            {witness.name}
            {witness.date && <i>{witness.date}</i>}
          </div>
        )}
      </div>
      <div>
        {patient.signatureUrl && (
          <div className={classes.signatureBlock}>
            <div
              className={classes.signature}
              style={{
                background: `url(${patient.signatureUrl}) no-repeat center center / contain`,
              }}
            />
            <span>{tCommon("label.patientSignature")}</span>
            {patient.name || patientQueryData.fullName}
            {patient.date && <i>{patient.date}</i>}
          </div>
        )}
      </div>
    </div>
  );
}
