import React, { useState } from "react";
import cx from "clsx";
import { Button } from "../../../../shared/Button/Button";
import styles from "../scss/RemoveExpirationActionButtons.module.scss";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useRemoveExpirationDate } from "../hooks/useRemoveExpirationDate";
import { useRemoveExpirationWarning } from "../hooks/useRemoveExpirationWarning";

const RemoveExpirationActionButtons = ({
  documentsSelected,
  patientId,
  refetchDocuments,
}) => {
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const { tClients } = useAppTranslation.Clients();

  const { mutate: removeExpirationDate, isLoading: isRemovingExpirationDate } =
    useRemoveExpirationDate();
  const {
    mutate: removeExpirationWarning,
    isLoading: isRemovingExpirationWarning,
  } = useRemoveExpirationWarning();

  const handleSubmit = () => {
    const removeAction =
      openModal.type === "date"
        ? removeExpirationDate
        : removeExpirationWarning;

    removeAction(
      {
        patient_id: patientId,
        documents: documentsSelected.map(({ object_id, type }) => ({
          id: object_id,
          type,
        })),
      },
      {
        onSuccess: () => {
          setOpenModal(false);
          refetchDocuments();
        },
      },
    );
  };

  const isConfirming = isRemovingExpirationDate || isRemovingExpirationWarning;

  return (
    <>
      <div className={styles.container}>
        <Button
          size="tiny"
          variant="text"
          className={cx(styles.buttons, styles.buttonsHover)}
          onClick={() => setOpenModal({ open: true, type: "date" })}
        >
          {tClients("documentsExpiration.removeExpirationDate", {
            count: documentsSelected.length,
          })}
        </Button>
        <Button
          size="tiny"
          variant="text"
          className={cx(styles.buttons, styles.buttonsHover)}
          onClick={() => setOpenModal({ open: true, type: "warning" })}
        >
          {tClients("documentsExpiration.removeExpirationWarning", {
            count: documentsSelected.length,
          })}
        </Button>
      </div>
      {openModal.open && (
        <ConfirmModal
          isOpen
          onCancel={() => setOpenModal(false)}
          onClose={() => {
            setOpenModal(false);
          }}
          onConfirm={handleSubmit}
          isConfirming={isConfirming}
        >
          {openModal.type === "date"
            ? tClients("documentsExpiration.removeDateConfirmation", {
                count: documentsSelected.length,
              })
            : tClients("documentsExpiration.removeWarningConfirmation", {
                count: documentsSelected.length,
              })}
        </ConfirmModal>
      )}
    </>
  );
};

export default RemoveExpirationActionButtons;
