import { useEffect } from "react";
import { PERMISSIONS } from "../../../../../consts/api";
import { usePermission } from "../../../../../hooks/usePermission";
import { history } from "../../../../../history";

export function useGuard() {
  const manageChartFilters = usePermission(PERMISSIONS.settings.manageChartFilters);

  useEffect(() => {
    if (!manageChartFilters.permitted) {
      history.goBack();
    }
  }, [manageChartFilters.permitted]);

  return null;
}
