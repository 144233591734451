import React from "react";
import PropTypes from "prop-types";
import { svg } from "../../../../assets/svg";
import { Input } from "../../../../shared/Input/Input";
import { SelectNative } from "../../../../shared/SelectNative/SelectNative";
import classes from "../sass/Settings.module.scss";
import { FILTERS } from "../hooks/useModifiers";

const FILTER_OPTIONS = [
  {
    label: "All",
    value: FILTERS.all,
  },
  {
    label: "Selected Services",
    value: FILTERS.selected,
  },
  {
    label: "Unselected Services",
    value: FILTERS.unselected,
  },
];

export function Modifiers({ search, filter }) {
  return (
    <div className={classes.root}>
      <Input
        onChange={(e) => search.onChange(e.target.value)}
        value={search.value}
        size="small"
        placeholder="Search by service name..."
        className={classes.search}
        leftAdornment={
          <img src={svg.searchPrimary} alt="search" width="16px" />
        }
      />
      <SelectNative
        name="filter"
        onChange={(e) => filter.onChange(e.target.value)}
        value={filter.value}
        size="small"
        options={FILTER_OPTIONS}
        className={classes.filter}
      />
    </div>
  );
}

const modifierPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
});

Modifiers.propTypes = {
  search: modifierPropType.isRequired,
  filter: modifierPropType.isRequired,
};
