import React from "react";
import PropTypes from "prop-types";
import classes from "./SelectPayment.module.scss";
import { svg } from "../../../../../assets/svg";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { ButtonSecondary } from "../../../../../shared/ButtonSecondary/ButtonSecondary";
import { useInvoice } from "../../hooks/invoice/useInvoice";

export function SelectPayment({ onSelect }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice } = useInvoice();

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <img src={svg.ccPrimary} alt="cc" />
        <div className={classes.title}>
          {tSales("checkoutInvoice.selectPayment")}
        </div>
      </div>
      <ButtonSecondary onClick={onSelect} className={classes.btn}>
        {invoice.totalAmount === 0
          ? tCommon("label.continue")
          : tCommon("label.select")}
      </ButtonSecondary>
    </div>
  );
}

SelectPayment.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
