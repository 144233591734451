import React, { Component } from "react";
import picClose from '../../../_legacy/images/popupClose.png';
import arLogo from '../../../_legacy/images/logo.png'

class ScriptSureFrame extends Component {
  constructor(props) {
    super(props);

    const languageData  = JSON.parse(localStorage.getItem('languageData'))

    this.state = {

    }

  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.parentState && nextProps.parentState !== prevState.parentState) {
      return {
        parentState : nextProps.parentState,
      }
    }
    return null;
  }

  render() {

    return (
      <>
          {this.state.parentState && this.state.parentState.scriptSureData && <div className={(this.state.parentState.showEprescribeIframe === true) ? "blackOverlay" : "blackOverlay no-display"}>
            <div className="vert-middle">
              <div className="loyaltyMembership treatmentPlan e-Prescribe">
                <div className="newPopupTitle">
                  <img className="eprescribeHeaderImg" src={arLogo}/>
                  e-Prescribe
                  <a className="popupClose"><img onClick={this.props.hideEprescribeIframe} src={picClose}/></a>
                </div>
                <div className="newPopupContent">
                  <div className="row m-b-15">
                    <div>
                      <iframe src={this.state.parentState && this.state.parentState.scriptSureData && this.state.parentState.scriptSureData.url} id="iframe1" frameBorder="0" width="1800" height="800"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </>
    )
  }
}

export default ScriptSureFrame;
