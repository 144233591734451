import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";

const requestSchema = yup.object({
  bannerId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  description: yup.string().required(),
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  monday: yup.number().required(),
  tuesday: yup.number().required(),
  wednesday: yup.number().required(),
  thursday: yup.number().required(),
  friday: yup.number().required(),
  saturday: yup.number().required(),
  sunday: yup.number().required(),
  status: yup.number().required(),
});

const composeResponse = (res) => {
  return res?.data?.data || {};
};

const createPPAnnouncementBannerQueryKey = (...args) => [
  QUERY_KEYS.patientPortal.announcementBanner.getOne,
  ...args,
];

export function usePPAnnoucementBannerQuery(payload, options = {}) {
  return useQuery(
    createPPAnnouncementBannerQueryKey(payload?.bannerId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.patientPortal.announcementBanner.getOne(req.bannerId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
