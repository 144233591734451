import React from "react";
import cx from "clsx";
import classes from "./Input.module.scss";

/**
 * @param {Object} param0
 * @param {string | number | undefined} param0.value
 * @param {(value: string) => void} param0.onChange
 * @param {string?} param0.type
 * @param {React.ReactNode?} param0.adornment
 * @param {boolean?} param0.isError
 */
export const Input = ({
  value,
  onChange,
  type = "text",
  adornment,
  isError,
}) => {
  return (
    <div className={classes.root}>
      <input
        data-vt_prevent_col_click
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={cx(classes.input, {
          [classes.error]: isError,
        })}
      />
      {adornment && <span className={classes.adornment}>{adornment}</span>}
    </div>
  );
};
