import React, { useState } from "react";
import classes from "../scss/CopyTimeDropdown.module.scss";
import { weekTemplate } from "../../../../../_legacy/Constants";
import { Button } from "../../../../../shared/Button/Button";
import { Dropdown } from "react-bootstrap";

const CopyTimeDropdown = ({
  close,
  isCopyScheduleDropdownOpen,
  handleChangeMultipleTimes,
}) => {
  const [copyDayScheduleArray, setCopyDayScheduleArray] = useState([]);

  const handleCheckbox = (dayIndex) => {
    if (copyDayScheduleArray.includes(dayIndex)) {
      setCopyDayScheduleArray(
        copyDayScheduleArray.filter((day) => day !== dayIndex),
      );
    } else {
      setCopyDayScheduleArray([...copyDayScheduleArray, dayIndex]);
    }
  };

  return (
    <Dropdown open>
      <Dropdown.Menu className={classes.dropdownMenu}>
        <div>
          <div className={classes.closeBtn}>
            <button onClick={close}>
              <img src="/images/close.png" alt="" />
            </button>
          </div>
          <div className={classes.header}>
            <div>COPY TIMES TO</div>
          </div>
          <>
            {weekTemplate.map((weekDayDetails, dayIndex) => {
              const { dayName } = weekDayDetails;
              const currentDayIndex = dayIndex + 1;
              const isDaySelected =
                copyDayScheduleArray.includes(currentDayIndex);

              return (
                currentDayIndex !== isCopyScheduleDropdownOpen && (
                  <div className={classes.dayOption}>
                    <span>{dayName}</span>
                    <span>
                      <input
                        type="checkbox"
                        name={dayName}
                        checked={isDaySelected}
                        className="filled-checkbox"
                        onChange={() => handleCheckbox(currentDayIndex)}
                      />
                    </span>
                  </div>
                )
              );
            })}
            <Button
              fullWidth
              size="small"
              onClick={() =>
                handleChangeMultipleTimes(
                  isCopyScheduleDropdownOpen,
                  copyDayScheduleArray,
                )
              }
              className={classes.copyDayScheduleButton}
            >
              Apply
            </Button>
          </>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CopyTimeDropdown;
