import React from "react";
import { arrayMoveImmutable } from "array-move";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { SortableContainer } from "../../shared/SortableContainer/SortableContainer";
import { AddTileButton } from "../../shared/AddTileButton/AddTileButton";
import classes from "./QuestionChoices.module.scss";
import { DND_PRESS_DELAY_MS } from "../../config";
import { ChoiceText } from "./boxes/ChoiceText/ChoiceText";
import { ChoiceImage } from "./boxes/ChoiceImage/ChoiceImage";

export function QuestionChoices({ onAdd, onSortEnd, children, choices }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();

  return (
    <div className={classes.root}>
      <InputLabel>{tCommon("label.questionChoices")}</InputLabel>
      <SortableContainer
        pressDelay={DND_PRESS_DELAY_MS}
        onSortEnd={({ oldIndex, newIndex }) => {
          onSortEnd(arrayMoveImmutable(choices, oldIndex, newIndex));
        }}
      >
        {choices.map(children)}
      </SortableContainer>
      <AddTileButton size="small" onClick={onAdd}>
        {tSettings("questionnaires.questionBuilder.addQuestionChoice")}
      </AddTileButton>
    </div>
  );
}

QuestionChoices.Text = ChoiceText;
QuestionChoices.Image = ChoiceImage;
