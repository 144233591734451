import React, { Component } from "react";
import * as yup from "yup";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cx from "clsx";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import {
  fetchSelectedConsent,
  createConsent,
  deleteConsent,
  updateSelectedConsent,
} from "../../../../Actions/Settings/settingsActions.js";
import Loader from "../../../../Components/Common/Loader.js";
import { FileParser } from "../../../../widgets/FileParser/FileParser";
import { uiNotification } from "../../../../services/UINotificationService";
import { FORM_TYPE } from "../consts.js";
import { Tabs } from "../../../../shared/Tabs/Tabs.js";
import classes from "../ManageClinics.module.scss";
import { MultiPostFromFiles } from "../MultiPostFromFiles/MultiPostFromFiles.js";
import { withMultiPostFromFiles } from "../MultiPostFromFiles/withMultiPostFromFiles.js";
import { MultiPostFromFilesContent } from "./components/MultiPostFromFilesContent.js";
import { http } from "../../../../services/HttpService.js";
import { HTTP_ENDPOINTS } from "../../../../consts/api.js";
import { Button } from "../../../../shared/Button/Button.js";
import { ASSIGN_TO_SERVICES_MODEL_TYPE } from "../../../../api/mutations/useAssignServiceToModelMutation.js";
import { SERVICES_BY_CATEGORIES_MODEL_TYPE } from "../../../../api/queries/useServicesGroupedByCategoriesQuery.js";
import { ModalAssignServices } from "../../../../widgets/ModalAssignServices/ModalAssignServices.js";

class CreateEditConsents extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      create_edit_consent_name:
        languageData.settings["create_edit_consent_name"],
      edit_consent: languageData.settings["edit_consent"],
      add_consent: languageData.settings["add_consent"],
      settings_add_consent: languageData.settings["settings_add_consent"],
      delete_confirmation: languageData.global["delete_confirmation"],
      create_edit_consent_body:
        languageData.settings["create_edit_consent_body"],
      consent_delete_warning: languageData.settings["consent_delete_warning"],
      yes_option: languageData.settings["yes_option"],
      no_option: languageData.settings["no_option"],

      consent_name: "",
      consent_large_description: "",
      id: this.props.match.params.id,
      showLoader: false,
      hideBtns: false,
      showModal: false,
      initials_per_page: false,
      witness_signature: false,
      formType: FORM_TYPE.single,
      editorFiles: [],
      isAssignToServicesModalOpen: false,
      assignedServiceIds: [],
    };
  }

  componentDidMount() {
    const consentId = this.props.match.params.id;

    if (consentId) {
      this.fetchConsent();
    }
  }

  fetchConsent = () => {
    this.setState({ showLoader: true });
      this.props
        .fetchSelectedConsent(this.props.match.params.id)
        .then((response) => {
          this.setState({
            consent_name: response?.data?.consent_name || "",

            consent_large_description:
              response?.data?.consent_large_description || "",

            initials_per_page: response?.data?.initials_per_page ? true : false,

            witness_signature: response?.data?.witness_signature ? true : false,

            assignedServiceIds:
              response?.data?.assigned_services?.map((s) => s.id) || [],
          });
        })
        .catch((response) => {
          uiNotification.error(
            languageData.global?.[response.message] ||
              "Unable to fetch consent. Try again later",
          );
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
      userChanged: true,
    });
  };

  showDeleteModal = () => {
    this.setState({ showModal: true });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  deleteConsent = () => {
    this.setState({ showLoader: true, hideBtns: true });
    this.dismissModal();
    this.props
      .deleteConsent(this.state.id)
      .then(() => {
        uiNotification.success("Consent has been successfully deleted");
        this.props.history.push("/settings/consents");
      })
      .catch(() => {
        uiNotification.error("Unable to delete consent. Try again later");
      })
      .finally(() => {
        this.setState({ showLoader: false, hideBtns: false });
      });
  };

  handleSubmit = () => {
    let error = false;

    this.setState({
      consent_name_Error: false,
      consent_large_description_Error: false,
      consent_small_Description_Error: false,
    });

    if (
      typeof this.state.consent_name === "undefined" ||
      this.state.consent_name === null ||
      this.state.consent_name === "" ||
      this.state.consent_name.trim() == ""
    ) {
      this.setState({
        consent_name_Error: true,
      });
      error = true;
    }
    if (
      typeof this.state.consent_large_description === "undefined" ||
      this.state.consent_large_description === null ||
      this.state.consent_large_description === "" ||
      this.state.consent_large_description.trim() == ""
    ) {
      this.setState({
        consent_large_description_Error: true,
      });
      error = true;
    }

    if (error === true) {
      return;
    }

    const consentId = this.props.match.params.id;

    let formData = {
      consent_name: this.state.consent_name,
      consent_large_description: this.state.consent_large_description,
      initials_per_page: this.state.initials_per_page ? 1 : 0,
      witness_signature: this.state.witness_signature ? 1 : 0,
    };

    this.setState({
      consent_name: this.state.consent_name,
      consent_large_description: this.state.consent_large_description,
      showLoader: true,
    });

    if (consentId) {
      this.props
        .updateSelectedConsent({
          ...formData,
          consent_id: consentId,
        })
        .then(() => {
          uiNotification.success("Consent has been successfully updated");
          this.props.history.push("/settings/consents");
        })
        .catch(() => {
          uiNotification.error("Unable to update consent. Try again later");
        })
        .finally(() => {
          this.setState({
            showLoader: false,
          });
        });
    } else {
      this.props
        .createConsent(formData)
        .then(() => {
          uiNotification.success("Consent has been successfully created");
          this.props.history.push("/settings/consents");
        })
        .catch(() => {
          uiNotification.error("Unable to create consent. Try again later");
        })
        .finally(() => {
          this.setState({
            showLoader: false,
          });
        });
    }
  };

  handleChangeTab = (formType) => {
    const msg = "Your changes will be lost. Confirm?";
    const confirmMap = {
      [FORM_TYPE.single]: this.state.editorFiles.length > 0,
    };
    if ((confirmMap[formType] && confirm(msg)) || !confirmMap[formType]) {
      this.setState({ formType });
    }
  };

  handleSaveSingleFile = (file, { onRemoveFile }) => {
    this.props.multiPostFromFiles
      .post([file])
      .then(() => {
        uiNotification.success.bind(uiNotification)(
          `Successfully Created '${file.consentName}' Consent`,
        );
        onRemoveFile(file.id);
      })
      .catch((e) => {
        uiNotification.error.bind(uiNotification)(e.message);
      });
  };

  handleSaveMultipleFiles = (files, { onRemoveAll }) => {
    if (
      confirm(
        "Are you sure you want to save all files? Some of them may not parsed correctly or include some mistakes that you don't want to submit without review. Continue anyway?",
      )
    ) {
      this.props.multiPostFromFiles
        .post(files)
        .then(() => {
          uiNotification.success.bind(uiNotification)(
            "Successfully Created Consents From Multiple Files",
          );
          onRemoveAll();
          this.props.history.push("/settings/consents");
        })
        .catch((e) => {
          uiNotification.error.bind(uiNotification)(e.message);
        });
    }
  };

  render() {
    const isEditMode = Boolean(this.props.match.params.id);
    const { multiPostFromFiles } = this.props;

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div
              className={cx("setting-setion", classes.multiPostFromFilesRoot)}
            >
              <div className="setting-container">
                <div className="items-center m-b-20 justify-between">
                  <div className="items-center">
                    <div className="setting-title-default">
                      {this.props.match.params.id
                        ? this.state.edit_consent
                        : this.state.settings_add_consent}
                    </div>
                    {isEditMode && (
                      <Button
                        size="small"
                        className="m-l-20"
                        variant="outlined"
                        onClick={() =>
                          this.setState({ isAssignToServicesModalOpen: true })
                        }
                      >
                        Assign to Services
                      </Button>
                    )}
                  </div>
                  <Link
                    to="/settings/consents"
                    className="pull-right cancelAction"
                  >
                    <img src={"../../../../images/close.png"} alt="x" />
                  </Link>
                </div>
                {!isEditMode && (
                  <Tabs
                    className="f-left m-b-20"
                    active={this.state.formType}
                    options={[
                      {
                        label: "Create",
                        value: FORM_TYPE.single,
                        onClick: () => this.handleChangeTab(FORM_TYPE.single),
                      },
                      {
                        label: "Upload Files",
                        value: FORM_TYPE.multipleFromFiles,
                        onClick: () =>
                          this.handleChangeTab(FORM_TYPE.multipleFromFiles),
                      },
                    ]}
                  />
                )}
                {this.state.formType === FORM_TYPE.single ? (
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          {this.state.create_edit_consent_name}
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          name="consent_name"
                          required="required"
                          className={
                            this.state.consent_name_Error === true
                              ? "consent_name newInputField field_error newInputField"
                              : "consent_name newInputField "
                          }
                          id="consent_name"
                          placeholder="Consent Name"
                          maxLength={255}
                          type="text"
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={this.state.consent_name}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          {this.state.create_edit_consent_body}
                          <span className="setting-require">*</span>
                        </div>
                        <textarea
                          name="consent_large_description"
                          id="large_desc"
                          className={cx(
                            classes.singlePostTextarea,
                            "col-sm-9",
                            "newtextareaField",
                            {
                              field_error:
                                this.state.consent_large_description_Error ===
                                true,
                            },
                          )}
                          placeholder="Description"
                          style={{ height: "300px" }}
                          rows={20}
                          cols={30}
                          onChange={this.handleInputChange}
                          value={this.state.consent_large_description}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <p className="m-b-10 m-t-20">
                        <input
                          id="chk-initials_per_page"
                          type="checkbox"
                          className="new-check"
                          name="initials_per_page"
                          onChange={this.handleInputChange}
                          checked={
                            this.state.initials_per_page ? "checked" : false
                          }
                        />
                        <label htmlFor="chk-initials_per_page">
                          {"Would you like initials on pages?"}
                        </label>
                      </p>
                    </div>
                    <div className="col-xs-12">
                      <p className="m-b-10">
                        <input
                          id="chk-witness_signature"
                          type="checkbox"
                          className="new-check"
                          name="witness_signature"
                          onChange={this.handleInputChange}
                          checked={
                            this.state.witness_signature ? "checked" : false
                          }
                        />
                        <label htmlFor="chk-witness_signature">
                          {"Would you like witness signature at the end?"}
                        </label>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={cx("row", classes.multiPostFromFilesRow)}>
                    <FileParser
                      withEditor
                      onError={uiNotification.error.bind(uiNotification)}
                      footerClassName={classes.multiPostFromFilesFooter}
                      renderFooter={({ isFilesSelected, onOpenEditor }) => (
                        <MultiPostFromFiles.Footer
                          isFilesSelected={isFilesSelected}
                          onOpenEditor={onOpenEditor}
                        />
                      )}
                      editor={{
                        isRemoveAllDisabled: multiPostFromFiles.isPosting,
                        isSelectFilesDisabled: multiPostFromFiles.isPosting,
                        isFilesDisabled: multiPostFromFiles.isPosting,
                        onRemoveAllCb:
                          multiPostFromFiles.resetValidationErrorLogs,
                        onRemoveFileCb:
                          multiPostFromFiles.resetValidationErrorLogs,
                        renderFooter: ({
                          currentFile,
                          files,
                          isFilesParsing,
                          onRemoveFile,
                          onRemoveAll,
                        }) => (
                          <MultiPostFromFiles.EditorFooter
                            onPostMultiple={() =>
                              this.handleSaveMultipleFiles(files, {
                                onRemoveAll,
                              })
                            }
                            onPostSingle={() =>
                              this.handleSaveSingleFile(currentFile, {
                                onRemoveFile,
                              })
                            }
                            currentFile={currentFile}
                            files={files}
                            isFilesParsing={isFilesParsing}
                            errorLogs={multiPostFromFiles.validationErrorLogs}
                            isPosting={multiPostFromFiles.isPosting}
                          />
                        ),
                        renderFilesMobileFooter: ({
                          files,
                          isFilesParsing,
                          onRemoveAll,
                        }) => (
                          <MultiPostFromFiles.EditorFilesMobileFooter
                            isFilesParsing={isFilesParsing}
                            onPostMultiple={() =>
                              this.handleSaveMultipleFiles(files, {
                                onRemoveAll,
                              })
                            }
                            errorLogs={multiPostFromFiles.validationErrorLogs}
                            isPosting={multiPostFromFiles.isPosting}
                          />
                        ),
                        renderContent: ({ currentFile, onEditFile }) => (
                          <MultiPostFromFilesContent
                            currentFile={currentFile}
                            onEditFile={onEditFile}
                            isPosting={multiPostFromFiles.isPosting}
                          />
                        ),
                        onFilesChange: (editorFiles) => {
                          this.setState({ editorFiles });
                        },
                      }}
                    />
                  </div>
                )}
              </div>
              {this.state.formType === FORM_TYPE.single && (
                <div className="footer-static">
                  {this.props.match.params.id != undefined && (
                    <input
                      className="new-red-btn pull-left confirm-model"
                      type="button"
                      value="Delete"
                      onClick={this.showDeleteModal}
                    />
                  )}
                  {!this.state.hideBtns && (
                    <button
                      type="button"
                      id="save_clinic"
                      className="new-blue-btn pull-right"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                  )}
                  {!this.state.hideBtns && (
                    <Link
                      to="/settings/consents"
                      className="new-white-btn pull-right cancelAction"
                    >
                      Cancel
                    </Link>
                  )}
                </div>
              )}
              <div className={this.state.showModal ? "overlay" : ""}></div>
              <div
                id="filterModal"
                role="dialog"
                className={
                  this.state.showModal
                    ? "modal fade in displayBlock"
                    : "modal fade"
                }
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={this.dismissModal}
                      >
                        x
                      </button>
                      <h4 className="modal-title" id="model_title">
                        {this.state.delete_confirmation}
                        {this.state.showModal}
                      </h4>
                    </div>
                    <div
                      id="errorwindow"
                      className="modal-body add-patient-form filter-patient"
                    >
                      {this.state.consent_delete_warning}
                    </div>
                    <div className="modal-footer">
                      <div className="col-md-12 text-left" id="footer-btn">
                        <button
                          type="button"
                          className="btn  logout pull-right"
                          data-dismiss="modal"
                          onClick={this.dismissModal}
                        >
                          {this.state.no_option}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success pull-right m-r-10"
                          data-dismiss="modal"
                          onClick={this.deleteConsent}
                        >
                          {this.state.yes_option}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
        {this.state.isAssignToServicesModalOpen && (
          <ModalAssignServices
            id={this.state.id}
            modelType={ASSIGN_TO_SERVICES_MODEL_TYPE.consent}
            servicesType={SERVICES_BY_CATEGORIES_MODEL_TYPE.all}
            pickedIds={this.state.assignedServiceIds}
            isOpen={this.state.isAssignToServicesModalOpen}
            onClose={() =>
              this.setState({ isAssignToServicesModalOpen: false })
            }
            onSuccess={() => this.fetchConsent()}
            title="Assign Services to Consent"
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSelectedConsent,
      createConsent,
      updateSelectedConsent,
      deleteConsent,
    },
    dispatch,
  );
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(
    withMultiPostFromFiles(CreateEditConsents, {
      submitFile: (file) =>
        http.post(HTTP_ENDPOINTS.postConsent(), {
          consent_large_description: file.text,
          consent_name: file.consentName,
          initials_per_page: file.isInitialsOnPage ? 1 : 0,
          witness_signature: file.isWitnessSignatureAtTheEnd ? 1 : 0,
        }),
      composeFileForSubmit: (file) => ({
        consentName: file.consentName,
        text: file.parsedContent,
        isInitialsOnPage: file.isInitialsOnPage,
        isWitnessSignatureAtTheEnd: file.isWitnessSignatureAtTheEnd,
      }),
      submitFileValidationSchema: yup.object({
        consentName: yup.string().required("Consent name is required"),
        text: yup.string().required("Consent body is required"),
        isInitialsOnPage: yup.bool(),
        isWitnessSignatureAtTheEnd: yup.bool(),
      }),
    }),
  ),
);
