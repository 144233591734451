import axios from "axios";

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_BASE_URL = "https://api.openai.com/v1";

export async function transcribeAudio(audioBlob) {
  const formData = new FormData();
  formData.append("file", audioBlob, "audio.webm");
  formData.append("model", "whisper-1");

  const response = await axios.post(
    `${OPENAI_BASE_URL}/audio/transcriptions`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data.text;
}

export async function formatNote(transcriptionText, customPrompt) {
  if (!transcriptionText) {
    throw new Error("No transcription text available for formatting");
  }

  const response = await axios.post(
    `${OPENAI_BASE_URL}/chat/completions`,
    {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content:
            "You are a medical assistant helping to format clinical notes.",
        },
        {
          role: "user",
          content: `Format the following transcribed text according to these instructions:\n\n${customPrompt}\n\nTranscribed text:\n${transcriptionText}`,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
    },
  );

  const resultMessage = response.data?.choices?.[0]?.message?.content;
  if (resultMessage) {
    return resultMessage.trim();
  }
  throw new Error("No content returned from API");
}

export const formatSOAPNote = (dictationText) =>
  formatNote(
    dictationText,
    "You are a medical assistant that formats transcribed text into a SOAP note structure.",
  );

export const formatProcedureNote = (dictationText) =>
  formatNote(
    dictationText,
    "You are an assistant that formats transcribed text into a detailed Procedure Note.",
  );

export const formatSurgicalNote = (dictationText) =>
  formatNote(
    dictationText,
    "You are a medical assistant that formats transcribed text into a detailed Surgical Note.",
  );

export const formatFollowUpVisit = (dictationText) =>
  formatNote(
    dictationText,
    "You are a medical assistant that formats transcribed text into a Follow-Up Visit Note.",
  );
