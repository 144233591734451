/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const HeaderCandela = () => {
  return (
    <div className="header touchMDHeader">
      <div className="wrapper">
        <div className="mdWrapper">
          <a className="logo">
            <img alt="" src="/images/logo.png?v=123" />
          </a>
          <a className="candelaLogo">
            <img alt="" src="/images/candelaMedicalLogo.png" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderCandela;
