import React, { createContext, useContext, useState } from "react";

const HeaderActionsContext = createContext();

export const HeaderActionsProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState({
    label: "All Clinics",
    value: 0,
  });

  const [period, setPeriod] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });

  const value = {
    selectedOption,
    setSelectedOption,
    period,
    setPeriod,
  };

  return (
    <HeaderActionsContext.Provider value={value}>
      {children}
    </HeaderActionsContext.Provider>
  );
};

export const useHeaderActionsContext = () => {
  return useContext(HeaderActionsContext);
};
