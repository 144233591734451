import React from "react";
import styles from "./styles.module.scss";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import { QUESTIONNAIRE_YES_NO_ANSWERS } from "../../../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireAnswersMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { BaseOptions } from "../../../../shared/BaseOptions/BaseOptions";

export function AnswerChoices({
  isMulti,
  choices,
  reason,
  options,
  onChangeChoices,
  onChangeReason,
}) {
  var { tCommon } = useAppTranslation.Common();

  var onSelect = (nextOption) => {
    var add = (o) => {
      if (isMulti) {
        onChangeChoices([...choices, o]);
      } else {
        onChangeChoices([o]);
      }
    };

    var remove = (o) => {
      onChangeChoices(choices.filter((i) => i !== o));
    };

    if (choices.includes(nextOption)) {
      remove(nextOption);
    } else {
      add(nextOption);
    }
  };

  return (
    <div className={styles.root}>
      <BaseOptions options={options} value={choices} onSelect={onSelect} />
      {choices[0] === QUESTIONNAIRE_YES_NO_ANSWERS.yes && onChangeReason && (
        <Textarea
          name="reason"
          value={reason}
          placeholder={tCommon("fillPatientQuestionnaire.comment")}
          onChange={(e) => onChangeReason(e.target.value)}
        />
      )}
    </div>
  );
}
