import { PAYMENT_MODES } from "../../../consts/api";
import { img } from "../../../assets/img";
import { svg } from "../../../assets/svg";

export const MOBILE_BREAKPOINT = 520;

export const PAYMENT_MODE_ICONS_BY_TYPE = {
  [PAYMENT_MODES.careCredit]: img.careCredit,
  [PAYMENT_MODES.cash]: svg.cashPrimary,
  [PAYMENT_MODES.cc]: svg.ccPrimary,
  [PAYMENT_MODES.cheque]: svg.checkPayPrimary,
  [PAYMENT_MODES.cherry]: svg.cherryPay,
  [PAYMENT_MODES.greenSky]: img.greenSky,
  [PAYMENT_MODES.wallet]: svg.arWalletPrimary,
  [PAYMENT_MODES.giftCard]: svg.giftCardPrimary,
  [PAYMENT_MODES.klarna]: img.klarna,
  [PAYMENT_MODES.link]: img.link,
};

export const MIN_PAY_AMOUNT = 0.5;

export const INVOICE_PAYMENT_AWAIT_REFRESH_RATE = 3000;
export const INVOICE_PAYMENT_AWAIT_TIMEOUT = 30000;

export const CHERRY_PAYMENT_AWAIT_REFRESH_RATE = 3000;

export const TIP_OPTIONS = {
  noTip: "0",
  custom: "custom",
  fifteenP: "0.15",
  eighteenP: "0.18",
  twentyP: "0.2",
};

export const CUSTOM_TIP_MAX_AMOUNT = 1e8;
export const CUSTOM_TIP_MIN_AMOUNT = 1;
