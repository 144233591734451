import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  fromDate: yup.string().required(),
  toDate: yup.string().required(),
});

const responseSchema = yup.object({
  clinic_net_sales: yup
    .array()
    .of(
      yup.object({
        clinic_name: yup.string().required(),
        gross_sales: yup.string().required(),
        amount: yup.number().required(),
      }),
    )
    .required(),
  provider_net_sales: yup
    .array()
    .of(
      yup.object({
        provider: yup.string().required(),
        provider_id: yup.number().required(),
        gross_sales: yup.string().required(),
        amount: yup.number().required(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  return res.data.data || null;
};

const createNetSalesQueryKey = (...args) => [QUERY_KEYS.netSales, ...args];

export const useNetSalesQuery = ({ payload, options = {} }) => {
  return useQuery(
    createNetSalesQueryKey(payload.fromDate, payload.toDate),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });

      const fromDate = moment(req.fromDate).format(API_DATE_FORMAT);
      const toDate = moment(req.toDate).format(API_DATE_FORMAT);

      const res = await http.post(HTTP_ENDPOINTS.getNetSales(), {
        fromDate,
        toDate,
      });

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
