import React from "react";
import { Link } from "react-router-dom";
import { UserCircleIcon } from "../../../../../../assets/Icons/UserCircleIcon";
import classes from "./Patient.module.scss";
import { CLIENTS_ROUTES } from "../../../../../../consts/routes";
import { useClientQuery } from "../../../../../../api/queries/useClientQuery";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";

export function Patient({ id }) {
  const { tCommon } = useAppTranslation.Common();

  const { data, isLoading } = useClientQuery(
    {
      clientId: id,
    },
    {
      enabled: Boolean(id),
      onError: () => {
        uiNotification.error(tCommon("error.fetchPatient"));
      },
    },
  );

  return (
    <>
      {isLoading ? (
        <Skeleton count={1} height={28.57} borderRadius="4px" width="200px" />
      ) : (
        <Link className={classes.root} to={CLIENTS_ROUTES.profile(id)}>
          <UserCircleIcon /> {data?.fullName}
        </Link>
      )}
    </>
  );
}
