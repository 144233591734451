import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clinic_id: yup.number().required(),
  patient_ids: yup.array().of(yup.number()).required(),
});

export function useAssignPatientsToClinicMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return http.put(
        HTTP_ENDPOINTS.noClinicPatientsReport.assignClinic(),
        req,
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.noClinicPatientsReport.get],
        });
        options.onSuccess?.(...args);
      },
    },
  );
}
