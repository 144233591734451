import { useQuery } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";

const createChartingFilterCategoriesQueryKey = () => [
  QUERY_KEYS.chartingFilterCategories,
];

export const useChartingFilterCategoriesQuery = (options = {}) => {
  return useQuery(
    createChartingFilterCategoriesQueryKey(),
    async () => {
      return http.get(HTTP_ENDPOINTS.getChartingFilterCategories(), {
        params: {
          with_procedures_count: true,
        },
      });
    },
    {
      ...options,
      select: (data) => data.data.data,
      cacheTime: 0,
    },
  );
};
