import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";

const requestSchema = yup.object({
  portalEmail: yup.string().required(),
  patientUserId: yup.number().required(),
  portalPassword: yup.string().required(),
});

export function useResetPatientPortalPasswordMutation({
  onSuccess,
  onError,
  onMutate,
  onSettled,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return http.post(
        HTTP_ENDPOINTS.patient.resetPortalPassword(req.patientUserId),
        {
          portal_email: req.portalEmail,
          patient_user_id: req.patientUserId,
          portal_password: req.portalPassword,
        },
      );
    },
    onMutate: () => {
      onMutate?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.client],
      });
      onSuccess?.();
    },
    onError: () => {
      onError?.();
    },
    onSettled: () => {
      onSettled?.();
    },
  });
}
