import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  const clinics = unwrapOr(() => res.data.data, []);
  return clinics.map((clinic) => ({
    id: clinic.id,
    name: clinic.clinic_name,
  }));
};

function createQualiphyEnrolledClinicsQueryKey() {
  return [QUERY_KEYS.qualiphy.enrolledClinics];
}

export function useQualiphyEnrolledClinicsQuery(options = {}) {
  return useQuery(
    createQualiphyEnrolledClinicsQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.qualiphy.getEnrolledClinics());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
