import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { http } from "../../../../../../services/HttpService";

const getEfaxTests = async () => {
  return await http.get(HTTP_ENDPOINTS.getEfaxTests());
};

export const useEfaxTests = (options = {}) => {
  const res = useQuery(["efaxTests"], () => getEfaxTests(), options);

  return res?.data?.data?.data?.panels;
};
