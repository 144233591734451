import { tClients, tCommon } from "../../../../../../i18n/useAppTranslation";

export function composeTobaccoOptionLabel(value = {}) {
  if (value?.tobacco_use) {
    return tClients("medicalHistory.merge.socialHistory.tobaccoOptionLabel", {
      packs: value?.tobacco_use_week || tCommon("label.none"),
      years: value?.tobacco_use_year || tCommon("label.none"),
    });
  }
  return tCommon("label.none");
}

export function composeDrugOptionLabel(value = {}) {
  if (value?.drug_use) {
    return tClients("medicalHistory.merge.socialHistory.drugOptionLabel", {
      times: value?.drug_use_week || tCommon("label.none"),
      period: value?.drug_use_period || tCommon("label.none"),
      type: value?.drug_use_type || tCommon("label.none"),
    });
  }
  return tCommon("label.none");
}

export function composeAlcoholOptionLabel(value = {}) {
  if (value?.alcohol_use) {
    return tClients("medicalHistory.merge.socialHistory.alcoholOptionLabel", {
      times: value?.alcohol_use_week || tCommon("label.none"),
    });
  }
  return tCommon("label.none");
}

export function composeExerciseOptionLabel(value = {}) {
  if (value?.exercise_use) {
    return tClients("medicalHistory.merge.socialHistory.exerciseOptionLabel", {
      times: value?.exercise_use_week || tCommon("label.none"),
    });
  }
  return tCommon("label.none");
}

export function composeWeightOptionLabel(value = {}) {
  if (value?.weight) {
    return tClients("medicalHistory.merge.socialHistory.weightOptionLabel", {
      amount: value?.weight_info || tCommon("label.none"),
      type:
        value.weight === 2
          ? tCommon("label.gain")
          : value.weight === 1
          ? tCommon("label.loss")
          : tCommon("label.none"),
    });
  }
  return tCommon("label.none");
}
