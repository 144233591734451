import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    clinic_name: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  return res?.data?.data || [];
};

const createPosSwitchClinicsQueryKey = () => [QUERY_KEYS.posSwitchClinics];

export const usePosSwitchClinicsQuery = (options = {}) => {
  return useQuery({
    queryKey: createPosSwitchClinicsQueryKey(),
    queryFn: async () => {
      const res = await http.get(HTTP_ENDPOINTS.getPosSwitchClinics());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    ...options,
  });
};
