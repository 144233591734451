import { matchPath } from "react-router";
import { SETTINGS_ROUTES } from "../../../consts/routes";
import { Maybe } from "../../../utilities/fp";

export const getEditPathParams = () => {
  const match = matchPath(window.location.pathname, {
    path: SETTINGS_ROUTES.editSalesGoal({ id: ":id", clinicId: ":clinicId" }),
    exact: true,
    strict: false,
  });

  return {
    goalId: Maybe.of(match)
      .map(({ params }) => Number(params.id))
      .orElse(undefined)
      .value(),

    clinicId: Maybe.of(match)
      .map(({ params }) => Number(params.clinicId))
      .orElse(undefined)
      .value(),

    isEditMode: Boolean(match?.params?.id && match?.params?.clinicId),
  };
};
