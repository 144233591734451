import React from "react";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { PLAN_CODES } from "../../../../../consts/api";
import { Header } from "./components/Header";
import TouchMDTimeline from "../../../TouchMDAppointments/TouchMD-appointments";
import { QualiphyExamProcedure } from "../../../components/QualiphyExamProcedure/QualiphyExamProcedure";
import CosmeticTimeline from "../../../Timeline/CosmeticTimeline.js";
import HealthTimeline from "../../../Timeline/HealthTimeline.js";
import { EmptyText } from "./components/EmptyText.js";
import { ScrollBox } from "./components/ScrollBox.js";
import { PrintArea } from "./components/PrintArea.js";
import { useProcedures } from "./hooks/useProcedures.js";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver.js";
import { Empty } from "../../../../../shared/Empty/Empty.js";
import ProcedureFilters from "../../../components/ProcedureFilters/ProcedureFilters.js";

export function Timeline({
  type,
  onChangeType,
  profile,
  onCreateAppointment,
  onCreateProcedure,
  isQualiphyEnabled,
  patientId,
  appointmentData,
  monthArray,
  openProcedureDetail,
  openLightbox,
  runCarousel,
  viewFilledSurvey,
  showSendInstructionPopup,
  viewQuestionnaires,
  viewQuestionnairesList,
  viewBAVideos,
  viewTraceAbility,
  viewNotes,
  viewConsents,
  getInvoiceDetails,
  handleChildState,
  handleEprescribe,
  viewProcedureDetail,
  showPrescriptionNew,
  viewHealthTraceability,
  goToInvoicePage,
  procedureDataText,
  getPrescribedPlanData,
  refetchClientData,
  showProgramDetails,
  cancelPlanByID,
  getPlanDetailsByID,
  performThisAction,
  viewBreakDown,
  startProTotal,
  startProMonthly,
  changePlanCard,
}) {
  const { data: account } = useCurrentAccountQuery();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch,
    procedurePeriod,
    categoryFilter,
    providerFilter,
    search,
    resetFilters,
  } = useProcedures(patientId);

  const isTouchMd = account?.preferences?.isTouchMd;
  const planCode = account?.subscription?.planCode;

  return (
    <div className="patient-right col-lg-8">
      <div className="merge-setion">
        <Header
          onChangeType={onChangeType}
          type={type}
          profile={profile}
          onCreateAppointment={onCreateAppointment}
          onCreateProcedure={onCreateProcedure}
          patientId={patientId}
          isQualiphyEnabled={isQualiphyEnabled}
          refetchProcedures={refetch}
        />
        {["procedure", "cosmetic", "health"].includes(type) && (
          <ProcedureFilters
            period={procedurePeriod.value}
            handlePeriod={procedurePeriod.update}
            searchValue={search.value}
            onChangeSearch={search.update}
            categoryFilter={categoryFilter}
            providerFilter={providerFilter}
            handleResetFilters={resetFilters}
          />)}
        {(type === "procedure" || type === "cosmetic" || type === "health") && (
          <div id="Client_Procedure_Timeline" className="time-line">
            {isLoading && (
              <Empty position="center" size="small">
                Loading procedures...
              </Empty>
            )}
            {!isLoading && !data?.length && (
              <Empty position="center" size="small">
                No procedures found
              </Empty>
            )}
            {isTouchMd && planCode === PLAN_CODES.booking ? (
              <div>
                {appointmentData?.length > 0 &&
                  appointmentData.map((hobj, hidx) => (
                    <div
                      key={"single-timeline-" + hidx}
                      className="row single-timeline-wrapper"
                    >
                      <TouchMDTimeline
                        hobj={hobj}
                        viewQuestionnairesList={viewQuestionnairesList}
                        viewConsents={viewConsents}
                        handleChildState={handleChildState}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              data?.length > 0 && (
                <div>
                  {data.map((hobj, hidx) => (
                    <div
                      key={"single-timeline-" + hidx}
                      className="row single-timeline-wrapper"
                    >
                      {hobj.procedure_type === "exam" && (
                        <QualiphyExamProcedure
                          procedure={hobj}
                          viewNotes={() => viewNotes(hobj.id)}
                          refetchProcedures={refetch}
                        />
                      )}
                      {hobj.procedure_type === "cosmetic" && (
                        <CosmeticTimeline
                          hobj={hobj}
                          openProcedureDetail={openProcedureDetail}
                          openLightbox={openLightbox}
                          runCarousel={runCarousel}
                          viewFilledSurvey={viewFilledSurvey}
                          showSendInstructionPopup={showSendInstructionPopup}
                          viewQuestionnaires={viewQuestionnaires}
                          viewBAVideos={viewBAVideos}
                          viewTraceAbility={viewTraceAbility}
                          viewNotes={viewNotes}
                          goToInvoicePage={goToInvoicePage}
                          viewConsents={viewConsents}
                          getInvoiceDetails={getInvoiceDetails}
                          handleEprescribe={handleEprescribe}
                          refetchProcedures={refetch}
                          handleChildState={handleChildState}
                        />
                      )}
                      {hobj.procedure_type === "health" && (
                        <HealthTimeline
                          hobj={hobj}
                          hidx={hidx}
                          openProcedureDetail={openProcedureDetail}
                          showSendInstructionPopup={showSendInstructionPopup}
                          viewProcedureDetail={viewProcedureDetail}
                          viewQuestionnaires={viewQuestionnaires}
                          viewConsents={viewConsents}
                          showPrescriptionNew={showPrescriptionNew}
                          viewHealthTraceability={viewHealthTraceability}
                          goToInvoicePage={goToInvoicePage}
                          viewNotes={viewNotes}
                          handleEprescribe={handleEprescribe}
                          refetchProcedures={refetch}
                        />
                      )}
                    </div>
                  ))}
                  {hasNextPage && (
                    <LoadMoreObserver
                      center
                      loadMore={fetchNextPage}
                      isLoading={isFetching}
                      hasMore={hasNextPage}
                    />
                  )}
                </div>
              )
            )}
            <EmptyText
              procedureDataText={procedureDataText}
              profile={profile}
              appointmentData={appointmentData}
            />
          </div>
        )}
        {type === "treatmentPlan" &&
          profile?.priscribed_treatment_plans?.length > 0 && (
            <ScrollBox
              profile={profile}
              getPrescribedPlanData={getPrescribedPlanData}
            />
          )}
        {type === "treatmentPlan" && profile.active_treamtment_plan === 1 && (
          <PrintArea
            profile={profile}
            refetchClientData={refetchClientData}
            showProgramDetails={showProgramDetails}
            cancelPlanByID={cancelPlanByID}
            getPlanDetailsByID={getPlanDetailsByID}
            performThisAction={performThisAction}
            monthArray={monthArray}
            viewBreakDown={viewBreakDown}
            startProTotal={startProTotal}
            startProMonthly={startProMonthly}
            changePlanCard={changePlanCard}
          />
        )}
      </div>
    </div>
  );
}
