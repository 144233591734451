import React, { Component } from "react";
import { bool, func, string } from "prop-types";

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
    };
  }

  render() {
    const {
      isOpen,
      confirmFn,
      cancelFn,
      message,
      title,
      confirmMessage,
      cancelMessage,
      isCancelMembership,
      cancelMembershipImmediately,
      setCancelMembershipImmediately,
    } = this.props;
    const { settingsLang } = this.state;

    return (
      <>
        <div className={isOpen ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            isOpen
              ? "modal fade in displayBlock responsive-modal"
              : "modal fade responsive-modal"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={cancelFn}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {title}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {message}
                {isCancelMembership && (
                  <div className="d-flex flex-column m-t-20">
                    <div className="d-flex gap-8">
                      <input
                        type="radio"
                        name="cancel-membership"
                        className="m-t-0"
                        onChange={() => setCancelMembershipImmediately(false)}
                        checked={!cancelMembershipImmediately}
                      />
                      <label
                        className="font-weight-normal"
                        htmlFor="radiobutton1"
                      >
                        Cancel - the membership is valid through their next pay
                        period
                      </label>
                    </div>
                    <div className="d-flex gap-8">
                      <input
                        type="radio"
                        name="cancel-membership"
                        className="m-t-0"
                        onChange={() => {
                          setCancelMembershipImmediately(true);
                        }}
                        checked={cancelMembershipImmediately}
                      />
                      <label
                        className="font-weight-normal"
                        htmlFor="radiobutton1"
                      >
                        Cancel Immediately - the membership is canceled at this
                        moment
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  {cancelFn && (
                    <button
                      type="button"
                      className="btn logout pull-right"
                      data-dismiss="modal"
                      onClick={cancelFn}
                    >
                      {cancelMessage || settingsLang.no_option}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={confirmFn}
                  >
                    {confirmMessage || settingsLang.yes_option}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ConfirmationModal.propTypes = {
  isOpen: bool.isRequired,
  confirmFn: func.isRequired,
  cancelFn: func.isRequired,
  message: string.isRequired,
  title: string,
  confirmMessage: string,
  cancelMessage: string,
};

ConfirmationModal.defaultProps = {
  title: "Confirmation Required!",
};

export default ConfirmationModal;
