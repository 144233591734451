const initialState = {
  isAccountOnHold: false,
  isAccountCancelled: false,
};

const AccountStatusRedirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCOUNT_STATUS": {
      return {
        isAccountOnHold: action.payload === "hold",
        isAccountCancelled: action.payload === "cancelled",
      };
    }
    default:
      return state;
  }
};

export default AccountStatusRedirectReducer;
