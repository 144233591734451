import * as yup from "yup";
import { useFormik } from "formik";
import { tSettings } from "../../../../../../i18n/useAppTranslation";
import { isNullish } from "../../../../../../utilities/general";
import { SIMPLE_QUESTION_TYPES } from "../../../../../../api/questionnaires/config";

// Schemas

export const questionSchema = yup.object({
  question: yup
    .string()
    .required(tSettings("questionnaires.questionBuilder.questionRequired")),

  questionType: yup
    .string()
    .required(tSettings("questionnaires.questionBuilder.typeRequired")),

  textChoices: yup.lazy((_, { parent }) => {
    if (parent.questionType === SIMPLE_QUESTION_TYPES.multiText) {
      return yup
        .array()
        .of(
          yup.object({
            text: yup.string().required(),
          }),
        )
        .min(
          1,
          tSettings("questionnaires.questionBuilder.questionChoicesRequired"),
        );
    }
    return yup.array();
  }),

  imageChoices: yup.lazy((_, { parent }) => {
    if (parent.questionType === SIMPLE_QUESTION_TYPES.multiImage) {
      return yup
        .array()
        .of(
          yup.object({
            image: yup.string().required(),
            label: yup.string().required(),
          }),
        )
        .min(
          1,
          tSettings("questionnaires.questionBuilder.questionChoicesRequired"),
        );
    }
    return yup.array();
  }),

  isMultipleSelection: yup.bool().required(),

  isDisplayLabels: yup.bool().required(),
});

const validationSchema = yup.object({
  title: yup
    .string()
    .required(
      tSettings("questionnaires.form.errors.questionnaireNameRequired"),
    ),

  questions: yup
    .array()
    .of(questionSchema)
    .min(1, tSettings("questionnaires.form.errors.questionsMin")),
});

// Adapters

function questionnaireAdapter(questionnaire) {
  function textChoicesAdapter(choices = [], type) {
    if (type === SIMPLE_QUESTION_TYPES.multiText) {
      return choices.map((c) => ({
        text: c.text,
      }));
    }
    return [];
  }

  function imageChoicesAdapter(choices = [], type) {
    if (type === SIMPLE_QUESTION_TYPES.multiImage) {
      return choices.map((c) => ({
        image: c.image_url,
        label: c.image_label,
      }));
    }
    return [];
  }

  return {
    title: questionnaire?.title || "",
    questions:
      questionnaire?.simple_questions?.map((q) => ({
        question: q.question,
        questionType: q.question_type,
        textChoices: textChoicesAdapter(q.question_choices, q.question_type),
        imageChoices: imageChoicesAdapter(q.question_choices, q.question_type),
        isMultipleSelection: Boolean(
          q.question_choices?.[0]?.multiple_selection,
        ),
        isDisplayLabels: Boolean(q.question_choices?.[0]?.display_labels),
      })) || [],
  };
}

// =============================

export function useForm({ submitter, questionnaire }) {
  const form = useFormik({
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitter,
    initialValues: questionnaireAdapter(questionnaire),
  });

  function getError(key) {
    if (form.touched[key] && form.errors[key]) {
      switch (key) {
        case "questions": {
          const error = form.errors[key];
          if (Array.isArray(error)) {
            return tSettings("questionnaires.form.errors.innerQuestionsError");
          }
          return error;
        }
        default: {
          return form.errors[key];
        }
      }
    }
    return null;
  }

  return {
    ...form,
    getError,
    questions: {
      value: form.values.questions,
      append: (next) => {
        if (next) {
          form.setFieldTouched("questions");
          form.setFieldValue("questions", [...form.values.questions, next]);
        }
      },
      replace: (next) => {
        if (next) {
          form.setFieldTouched("questions");
          form.setFieldValue("questions", next);
        }
      },
      deleteByIndex: (index) => {
        if (!isNullish(index)) {
          form.setFieldTouched("questions");
          form.setFieldValue("questions", [
            ...form.values.questions.slice(0, index),
            ...form.values.questions.slice(index + 1),
          ]);
        }
      },
      updateByIndex: (next, index) => {
        if (next) {
          form.setFieldTouched("questions");
          form.setFieldValue("questions", [
            ...form.values.questions.slice(0, index),
            next,
            ...form.values.questions.slice(index + 1),
          ]);
        }
      },
    },
  };
}
