import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import "react-phone-input-2/lib/style.css";
import { createKlarnaPayment } from "../../../../../Actions/Sales/klarnaActions";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ChargeFormKlarna = (props) => {
  const { chargeFormHandle, isTipsApplying } = props;
  const { showTipsModal } = usePaymentFlow();

  const { form, changeFormValue, hasError, errors } = chargeFormHandle;

  return !showTipsModal.value ? (
    <PaymentFormModal
      form={form}
      changeFormValue={changeFormValue}
      errors={errors}
      hasError={hasError}
      showTipsModal={showTipsModal}
    />
  ) : (
    <PaymentTipsModal
      email={form.email}
      phoneNumber={form.phoneNumber}
      showTipsModal={showTipsModal}
      isTipsApplying={isTipsApplying}
    />
  );
};

ChargeFormKlarna.propTypes = {
  createKlarnaPayment: PropTypes.func,
  chargeFormHandle: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
  };
};

const mapDispatchToProps = () => {
  return bindActionCreators(
    {
      createKlarnaPayment: createKlarnaPayment,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargeFormKlarna);
