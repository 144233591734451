import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import { format } from "date-fns";
import { isEqual, uniqWith } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchInvoices } from "../../../Actions/Sales/salesActions.js";
import SalesHeader from "../Common/SalesHeader.js";
import SalesFilter from "../Common/SalesFilter.js";
import { autoScrolling } from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import { tCommon } from "../../../i18n/useAppTranslation.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { svg } from "../../../assets/svg";

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

class Invoices extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      sales_header: {},
      upDown: false,
      report_header: {},
      monthlyNetValues: [],
      total_invoices: "",
      grouping: "",
      avg_gross_sales: "",
      avg_net_sales: "",
      gross_sales: "",
      net_sales: "",
      show_invoice: false,
      page: 1,
      loadMore: true,
      pagesize: 15,
      page_number: 1,
      hasMoreItems: true,
      reminder_before: "",
      reminder_type: "",
      locationArray: [],
      invoicesArray: [],
      employeeArray:
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.filterType &&
        this.props.match.params.filterType === "provider" &&
        this.props.match.params.filterID
          ? [parseInt(this.props.match.params.filterID)]
          : [],
      InvoicesList: [],
      clinic_id:
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.filterType &&
        this.props.match.params.filterType === "clinic" &&
        this.props.match.params.filterID
          ? [parseInt(this.props.match.params.filterID)]
          : [],
      user_id: [],
      invoice_status: [],
      userChanged: false,
      startFresh: true,
      showLoader: false,
      showModal: false,
      search_key: "",
      noRecordDisplayEnable: "no-record",
      noRecordDisplayDisable: "no-record no-display",
      globalLang: languageData.global,
      salesLang: languageData.sales,
      showLoadingText: false,
      selectedLocationIdList: [],
      selectedEmployeeIdList: [],
      selectedInvoicesIdList: [],
      clinicObjList: {},
      to_date: format(new Date(), "YYYY-MM-DD"),
      from_date: format(new Date(), "YYYY-MM-DD"),
      graphData: [],
      reportHeader: [],
      reportDataValues: [],
    };

    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );

      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.show_invoice
      ) {
        this.loadMore();
      }
    };
  }

  showDeleteModal = (e) => {
    this.setState({
      showModal: true,
      reminderId: e.currentTarget.dataset.userid,
    });
  };

  showEditModal = (e) => {
    this.setState({ userId: e.currentTarget.dataset.userid });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);

      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
        page_number: this.state.page_number + 1,
      });

      const dateData = localStorage.getItem("selectionRange-invoices");
      const valR = dateData ? JSON.parse(dateData) : "";
      const startD = valR.startDate;
      const endD = valR.endDate;

      let formData = {
        fromDate: apiDateFormat(startD),
        toDate: apiDateFormat(endD),
        search_key: this.state.search_key,
        page_number: this.state.page_number,
        clinic_id: this.state.clinic_id,
        user_id: this.state.user_id,
        invoice_status: this.state.invoice_status,
      };

      autoScrolling(true);

      this.props.fetchInvoices(formData);
    }
  };

  componentDidMount() {
    localStorage.setItem("sortOnly", false);

    const dateData = localStorage.getItem("selectionRange-invoices");
    const valR = dateData ? JSON.parse(dateData) : "";
    const localInvoicesArray = localStorage.getItem("localInvoicesArray");

    const localInvoicesArrays = localInvoicesArray
      ? JSON.parse(localInvoicesArray)
      : "";

    const startD = valR.startDate;
    const endD = valR.endDate;

    let formData = {
      fromDate: apiDateFormat(startD),
      toDate: apiDateFormat(endD),
      search_key: this.state.search_key,
      page_number: 1,
      clinic_id:
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.filterType &&
        this.props.match.params.filterType === "clinic" &&
        this.props.match.params.filterID
          ? [parseInt(this.props.match.params.filterID)]
          : "",
      user_id:
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.filterType &&
        this.props.match.params.filterType === "provider" &&
        this.props.match.params.filterID
          ? [parseInt(this.props.match.params.filterID)]
          : "",
      invoice_status: localInvoicesArrays ? localInvoicesArrays : "",
    };

    let user_id =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.filterType &&
      this.props.match.params.filterType === "provider" &&
      this.props.match.params.filterID
        ? [parseInt(this.props.match.params.filterID)]
        : [];

    this.setState({ showLoader: true, user_id: user_id });

    autoScrolling(true);

    this.props
      .fetchInvoices(formData)
      .catch((e) => {
        if (e.message) {
          uiNotification.error(tCommon(`apiError.${e.message}`, e.message));
        } else {
          uiNotification.error("Unable to fetch invoices. Try again later");
        }
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.InvoicesList && this.props.InvoicesList) {
      this.setState({
        reportDataValues: [...this.props.InvoicesList.data.invoices_data],
      });
    }

    if (
      prevProps.InvoicesList &&
      this.props.InvoicesList &&
      prevProps.InvoicesList.data.invoices_data !==
        this.props.InvoicesList.data.invoices_data
    ) {
      this.setState({
        reportDataValues: [
          ...prevState.reportDataValues,
          ...this.props.InvoicesList.data.invoices_data,
        ],
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }

    if (
      nextProps.InvoicesList != undefined &&
      nextProps.InvoicesList.data.invoices_data != prevState.reportDataValues &&
      nextProps.invoicesTime != prevState.invoicesTime
    ) {
      returnState.invoicesTime = nextProps.invoicesTime;
      if (prevState.reportDataValues.length == 0) {
        returnState.InvoicesList = nextProps.InvoicesList.data.invoices_data;
        returnState.sales_header = nextProps.InvoicesList.data.sales_header;
        returnState.total = nextProps.InvoicesList.data.total;
        returnState.locationArray = nextProps.InvoicesList.data.clinics;
        returnState.employeeArray = nextProps.InvoicesList.data.employees;
        returnState.invoicesArray = nextProps.InvoicesList.data.payment_status;
        returnState.reportHeader = nextProps.InvoicesList.data.report_header;
        returnState.grouping = nextProps.InvoicesList.data.grouping;
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.show_invoice = nextProps.InvoicesList.data.show_invoice;
      } else {
        returnState.sales_header = nextProps.InvoicesList.data.sales_header;
        returnState.total = nextProps.InvoicesList.data.total;
        returnState.locationArray = nextProps.InvoicesList.data.clinics;
        returnState.employeeArray = nextProps.InvoicesList.data.employees;
        returnState.invoicesArray = nextProps.InvoicesList.data.payment_status;
        returnState.reportHeader = nextProps.InvoicesList.data.report_header;
        returnState.grouping = nextProps.InvoicesList.data.grouping;
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.show_invoice = nextProps.InvoicesList.data.show_invoice;
      }

      autoScrolling(false);

      return returnState;
    }

    return null;
  }

  shouldComponentUpdate() {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  deleteReminder = () => {
    localStorage.setItem("isDelete", true);

    this.dismissModal();
    let reminders = this.state.InvoicesList;

    if (reminders.length) {
      reminders.map((obj, idx) => {
        if (obj.id == this.state.reminderId) {
          delete reminders[idx];
        }
      });
      this.setState({ InvoicesList: reminders });
    }

    this.props.deleteInvoicesList(this.state.reminderId);
    this.dismissModal();
  };

  handleChildSubmit = (childState) => {
    this.setState(childState);

    if (childState.canSubmit) {
      localStorage.setItem("sortOnly", false);

      let formData = {
        fromDate: apiDateFormat(childState.fromDate),
        toDate: apiDateFormat(childState.toDate),
        clinic_id: childState.selectedLocationIdList,
        user_id: childState.selectedEmployeeIdList,
        invoice_status: childState.selectedInvoicesIdList,
        search_key: childState.search_key,
        page_number: 1,
      };

      this.setState({
        reportDataValues: [],
        loadMore: true,
        clinic_id: childState.selectedLocationIdList,
        user_id: childState.selectedEmployeeIdList,
        invoice_status: childState.selectedInvoicesIdList,
        showLoader: true,
        page_number: 1,
      });

      autoScrolling(true);

      this.props
        .fetchInvoices(formData)
        .catch((e) => {
          if (e.message) {
            uiNotification.error(tCommon(`apiError.${e.message}`, e.message));
          } else {
            uiNotification.error("Unable to fetch invoices. Try again later");
          }
        })
        .finally(() => {
          this.setState({
            showLoader: false,
          });
        });
    }
  };

  userEdit = (id, patient_id) => {
    return (
      <div>
        {this.props.history.push(`/sales/invoice/${id}/${patient_id}/invoices`)}
      </div>
    );
  };

  displayLoader = () => {
    this.setState({ showLoader: true });
  };

  hideLoader = () => {
    this.setState({ showLoader: false });
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content">
          <div className="setting-setion m-b-10 auto-height full-width no-display">
            <div className="membership-title">
              <span className="cursor-pointer">
                <Link className="appointmentIndex" to="/sales/invoices">
                  Sales
                </Link>{" "}
              </span>
              <span className="breadCrumb-text">
                <i className="fa fa-chevron-right"></i> Invoices
              </span>
            </div>
          </div>

          <div className="setting-setion m-b-10 auto-height full-width">
            <div className="membership-title">
              <div className="newTabsOuter">
                <SalesHeader />
              </div>
            </div>
          </div>

          <div className="juvly-section full-width">
            <SalesFilter
              locationArray={this.state.locationArray}
              clinic_id={this.state.clinic_id}
              user_id={this.state.user_id}
              invoice_status={this.state.invoice_status}
              invoicesArray={this.state.invoicesArray}
              employeeArray={this.state.employeeArray}
              handleChildSubmit={this.handleChildSubmit}
              searchVal={this.state.search_key}
              clinicVal={this.state.selectedLocationIdList}
              dateRangePick={true}
              reportType={"invoices"}
              search={true}
              location={true}
              searchShow={true}
              invoices={true}
              employee={true}
              displayLoader={this.displayLoader}
              hideLoader={this.hideLoader}
            />
            <div className="table-responsive">
              <table className="table-updated setting-table sale-invoice-table min-w-1000   ">
                <thead className="table-updated-thead">
                  <tr>
                    {this.state.reportHeader.length > 0 &&
                      this.state.reportHeader.map((obj, idx) => {
                        return (
                          <React.Fragment key={`firstfrag_` + idx}>
                            <th
                              className="col-xs-1 table-updated-th"
                              data-url="/${actionType}/payment-history/${clientID}/${type}/${tabType}"
                            >
                              {Object.values(obj)}
                            </th>
                          </React.Fragment>
                        );
                      })}
                  </tr>
                </thead>
                <tbody className="ajax_body">
                  {this.state.reportDataValues.length > 0 &&
                    uniqWith(this.state.reportDataValues, isEqual).map(
                      (objRes, idxRes) => {
                        return (
                          <React.Fragment key={`secondfrag_` + idxRes}>
                            <tr className="table-updated-tr">
                              {this.state.reportHeader.length > 0 &&
                                this.state.reportHeader.map((obj, idx) => {
                                  const columnKey = Object.keys(obj)[0];
                                  const columnValue = obj[columnKey];
                                  const isStatusColumn =
                                    columnValue === "Status";

                                  return (
                                    <React.Fragment key={`thirdfrag_${idx}`}>
                                      <td
                                        className="col-xs-1 table-updated-td"
                                        onClick={this.userEdit.bind(
                                          this,
                                          objRes.id,
                                          objRes.patient_id,
                                        )}
                                      >
                                        {objRes[columnKey]}
                                        {isStatusColumn &&
                                        objRes.checkout_session_expired ===
                                          1 ? (
                                          <>
                                            <span className="text-nowrap">
                                              {" "}
                                              - Link Expired{" "}
                                              <img
                                                src={svg.alertTime}
                                                alt="Expire"
                                                style={{
                                                  marginLeft: "5px",
                                                  height: "20px",
                                                  width: "20px",
                                                }}
                                              />
                                            </span>
                                          </>
                                        ) : null}
                                      </td>
                                    </React.Fragment>
                                  );
                                })}
                            </tr>
                          </React.Fragment>
                        );
                      },
                    )}
                </tbody>
                {this.state.showLoader !== true &&
                  this.state.reportDataValues !== undefined &&
                  this.state.reportDataValues.length == 0 && (
                    <tbody className="ajax_body">
                      <tr className="table-updated-tr">
                        <td className="no-record no-float" colSpan={11}>
                          {this.state.salesLang.sales_no_record_found}
                        </td>
                      </tr>
                    </tbody>
                  )}
              </table>
            </div>
            <Loader showLoader={this.state.showLoader} isFullWidth={true} />
          </div>
        </div>
        <div
          className={
            this.state.showLoadingText
              ? "loading-please-wait"
              : "loading-please-wait no-display "
          }
        >
          {this.state.globalLang.loading_please_wait_text}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  localStorage.setItem("sortOnly", true);

  if (state.SalesReducer.action === "INVOICES_LIST") {
    if (state.SalesReducer.data.status === 200) {
      return {
        InvoicesList: state.SalesReducer.data,
        invoicesTime: new Date(),
      };
    }
  } else {
    return {};
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoices,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Invoices));
