import { useEffect } from "react";
import { useCurrentAccountQuery } from "../api/queries/useAccountQuery";
import { USER_PAYMENT_SYSTEMS } from "../consts/api";
import { delay, isNullish } from "../utilities/general";
import { initializeClearentSdk } from "../Utils/services";

export function useMountClearentInput({ publicKey, formId, condition }) {
  const account = useCurrentAccountQuery();

  useEffect(() => {
    const additionCondition = isNullish(condition) ? true : condition;
    if (
      publicKey &&
      account.data?.paymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
      additionCondition
    ) {
      delay(500).then(() => {
        initializeClearentSdk(publicKey, formId);
      });
    }
  }, [condition, publicKey, account.data?.paymentSystem]);
}
