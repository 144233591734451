import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import { useTaxes } from "./hooks/useTaxes";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "taxesReport.info.title",
  rows: ["taxesReport.info.note"],
};

const Taxes = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const { data, isLoading } = useTaxes(requestParams);

  const tableDataEmpty = !isLoading && !data?.reportData?.length;

  const tableData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        clinic: data.clinic_name,
        totalSales: data.gross_sales,
        netSales: data.net_sales,
        taxableAmount: data.taxable_sales,
        taxCollected: data.total_tax_collected,
      })),
    [data],
  );

  const totalDatas = [
    ...(tableData || []),
    !tableDataEmpty &&
      !isLoading && {
        clinic: <span className="bold">Total</span>,
        totalSales: <span className="bold">{data.total.gross_sales}</span>,
        netSales: <span className="bold">{data.total.net_sales}</span>,
        taxableAmount: <span className="bold">{data.total.taxable_sales}</span>,
        taxCollected: (
          <span className="bold">{data.total.total_tax_collected}</span>
        ),
      },
  ].filter(Boolean);

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Taxes"
        selectedClinic={selectedClinic}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getTaxes()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Clinic", accessor: "clinic" },
              { data: "Total Sales", accessor: "totalSales" },
              { data: "Net Sales", accessor: "netSales" },
              { data: "Taxable Amount", accessor: "taxableAmount" },
              { data: "Tax Collected", accessor: "taxCollected" },
            ]}
            data={totalDatas}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default Taxes;
