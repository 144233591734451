/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
  getProcedureNotes,
  saveProcedureNote,
  deleteProcedureNote,
  getAProcedureNote,
} from "../../Actions/ProcedureNotes/proNotesActions.js";
import {
  getUser,
  checkIfPermissionAllowed,
  showFormattedDate,
  displayName,
} from "../../Utils/services.js";
import "react-tagsinput/react-tagsinput.css";
import AcknowledgeHistoryModal from "../ClientNotes/AcknowledgeHistoryModal.js";
import { PatternAutocomplete } from "../../widgets/PatternAutocomplete/PatternAutocomplete.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { Dictation } from "../../widgets/Dictation/Dictation";
import { ThunderIcon } from "../../assets/Icons/ThunderIcon";

const prepareDotPhrases = (dotPhrasesList = []) => {
  return dotPhrasesList.map((i) => ({
    label: i.name,
    description: i?.title || null,
    value: i.phrase,
  }));
};

const prepareMedicalHistory = (medicalHistoryList = []) => {
  return medicalHistoryList.map((i) => ({
    label: i.title,
    value: `${i.title}: ${i.value}`,
    description: i.value,
  }));
};

class ProcedureNotes extends Component {
  constructor(props) {
    super(props);

    this.escFunction = this.escFunction.bind(this);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      action:
        this.props.match.params.type !== null &&
        this.props.match.params.type !== "" &&
        this.props.match.params.type
          ? this.props.match.params.type
          : "",
      procedureID:
        this.props.match.params.procedureID !== null &&
        this.props.match.params.procedureID !== "" &&
        this.props.match.params.procedureID
          ? this.props.match.params.procedureID
          : 0,
      patientID:
        this.props.match.params.patientID !== null &&
        this.props.match.params.patientID !== "" &&
        this.props.match.params.patientID
          ? this.props.match.params.patientID
          : 0,
      showLoader: false,
      procedureNotes: [],
      patientData: [],
      procedureNote: [],
      showModal: false,
      dataChanged: false,
      noteText: "",
      noteClass: "setting-textarea-box",
      tags: [],
      noteTobeDeleted: 0,
      noteTobeEdited: 0,
      roomType: this.props.match.url.split("/")[1],
      languageData: languageData.procedure,
      globalLang: languageData.global,
      editNoteID: this.props.match.params.noteID
        ? this.props.match.params.noteID
        : 0,
      showSearchResult: false,
      roomTypeParams:
        this.props.match.params.roomType !== null &&
        this.props.match.params.roomType !== "" &&
        this.props.match.params.roomType
          ? this.props.match.params.roomType
          : "",
      autoCompleteType: "",
      autoCompleteTerm: "",
      autoCompleteList: [],
      acknowledgeHistoryModalStatus: false,
      notesId: "",
    };

    this.dictationRef = React.createRef();
    this.chartSmartRef = React.createRef();

    window.onscroll = () => {
      return false;
    };
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.setState({ showSearchResult: false });
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);

    this.setState({ showLoader: true });

    let formData = {
      params: {
        patient_id: this.state.patientID,
        procedure_id: this.state.procedureID,
        note_id: this.state.editNoteID,
      },
    };

    this.showLoaderFunc();

    this.props.getProcedureNotes(formData).catch((res) => {
      uiNotification.error(
        this.state.globalLang[res.message] || "Unable To Retrieve Notes",
      );

      this.setState({ showLoader: false });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  showLoaderFunc = () => {
    this.setState({ showLoader: true });
    localStorage.setItem("showLoader", true);
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.procedureNotes !== undefined &&
      props.procedureNotes.status === 200 &&
      props.procedureNotes != state.procedureNotes
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        let returnState = {};

        returnState.procedureNotes = props.procedureNotes.data.procedure_notes;
        returnState.patientData = props.procedureNotes.data.patient_detail;
        returnState.showLoader = false;

        returnState.dotPhrasesList = prepareDotPhrases(
          props.procedureNotes.data.dot_phrases_list,
        );

        returnState.medicalHistoryList = prepareMedicalHistory(
          props.procedureNotes?.data?.medical_history,
        );

        if (
          state.editNoteID &&
          state.editNoteID > 0 &&
          checkIfPermissionAllowed("add-edit-procedure-notes")
        ) {
          if (props.procedureNotes.data.procedure_note) {
            returnState.noteTobeEdited = state.editNoteID;
          }
        }

        localStorage.setItem("showLoader", true);

        return returnState;
      }
    } else if (
      props.procedureNotes !== undefined &&
      props.procedureNotes.status !== 200 &&
      props.procedureNotes !== state.procedureNotes
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        return {
          procedureNotes:
            props.procedureNotes.data &&
            props.procedureNotes.data.procedure_notes &&
            props.procedureNotes.data.procedure_notes,
          patientData:
            props.procedureNotes.data &&
            props.procedureNotes.data.patient_detail &&
            props.procedureNotes.data.patient_detail,
          showLoader: false,
          noteTobeEdited: 0,
          dotPhrasesList: prepareDotPhrases(
            props.procedureNotes.data.dot_phrases_list,
          ),
        };
      }
    }

    if (
      props.procedureNote !== undefined &&
      props.procedureNote.status === 200 &&
      props.procedureNote.data &&
      props.procedureNote.data !== state.procedureNote.data &&
      state.noteTobeEdited > 0
    ) {
      let hashtags =
        props.procedureNote.data.hashtags &&
        props.procedureNote.data.hashtags !== undefined &&
        props.procedureNote.data.hashtags !== "" &&
        props.procedureNote.data.hashtags !== null
          ? props.procedureNote.data.hashtags.split(",")
          : "";

      if (localStorage.getItem("showLoader") == "false") {
        return {
          procedureNote: props.procedureNote.data,
          showLoader: false,
          tags: state.dataChanged ? state.tags : hashtags,
        };
      }
    } else if (
      props.procedureNote !== undefined &&
      props.procedureNote.status !== 200 &&
      props.procedureNote.data &&
      props.procedureNote.data !== state.procedureNote.data &&
      state.noteTobeEdited > 0
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        return {
          procedureNote: props.procedureNote.data,
          showLoader: false,
          noteTobeEdited: 0,
        };
      }
    }

    if (
      props.deleteNoteData !== undefined &&
      props.deleteNoteData.status === 200 &&
      props.deleteNoteData.data !== state.deleteNoteData &&
      state.noteTobeDeleted > 0
    ) {
      document.getElementById(state.noteTobeDeleted).style.display = "none";

      return {
        deleteNoteData: props.deleteNoteData.data,
        showLoader: false,
        noteTobeDeleted: 0,
        editNoteID: 0,
      };
    } else if (
      props.deleteNoteData !== undefined &&
      props.deleteNoteData.status !== 200 &&
      props.deleteNoteData.data !== state.deleteNoteData
    ) {
      return {
        deleteNoteData: props.deleteNoteData.data,
        showLoader: false,
      };
    }

    return null;
  }

  getLoggedInUserData = () => {
    let userData = JSON.parse(getUser());

    if (userData) {
      return userData.user.id;
    }

    return 0;
  };

  showDeleteModal = (obj) => {
    this.state.noteTobeDeleted = obj.id;
    this.setState({
      showModal: true,
      showSearchResult: false,
      dataChanged: false,
      noteTobeDeleted: obj.id,
    });
  };

  editThisNote = (e) => {
    e.preventDefault();
    this.state.noteTobeEdited = e.target.name;
    this.showLoaderFunc();

    if (this.state.noteTobeEdited > 0) {
      this.setState({ showSearchResult: false, dataChanged: false });
      this.props
        .getAProcedureNote(this.state.noteTobeEdited)
        .catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] || "Unable To Retrieve Note",
          );
        })
        .then((res) => {
          this.setState({
            noteText: res.data.notes || "",
          });
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
    }
  };

  dismissDeleteModal = () => {
    this.state.noteTobeDeleted = 0;
    this.setState({ showModal: false, noteTobeDeleted: 0 });
  };

  deleteNote = () => {
    let noteID = this.state.noteTobeDeleted;

    this.props.history.push(
      `/${this.state.roomType}/notes/${this.state.procedureID}/${this.state.patientID}/${this.state.action}`,
    );

    if (noteID > 0) {
      this.showLoaderFunc();
      this.setState({
        tags: [],
        dataChanged: false,
        procedureNote: [],
        noteClass: "setting-textarea-box",
        showLoader: true,
        showModal: false,
        noteTobeDeleted: noteID,
      });

      this.props
        .deleteProcedureNote(noteID)
        .then((res) => {
          uiNotification.success(
            this.state.globalLang[res.message] ||
              "Note Has Been Successfully Deleted",
          );
        })
        .catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] || "Unable To Delete Note",
          );
        });

      this.state.procedureNote = [];
      this.state.tags = [];
      this.state.noteText = "";
    } else {
      uiNotification.error("Can not delete this note at this time");
    }
  };

  handleSaveNote = async (e) => {
    e.preventDefault();

    if (!this.state.noteText.trim()) {
      uiNotification.error("Please enter note text");
      return;
    }

    this.setState({ showLoader: true });

    const formData = {
      patient_id: this.state.patientID,
      procedure_id: this.state.procedureID,
      notes: this.state.noteText,
      hashtags: this.state.tags.join(","),
      note_id: this.state.noteTobeEdited || null,
    };

    try {
      await this.props.saveProcedureNote(formData, this.state.noteTobeEdited);

      await this.props.getProcedureNotes({
        params: {
          patient_id: this.state.patientID,
          procedure_id: this.state.procedureID,
        },
      });

      this.setState({
        noteText: "",
        tags: [],
        noteTobeEdited: 0,
        dataChanged: false,
      });

      uiNotification.success("Note saved successfully");
    } catch (error) {
      uiNotification.error(error.message || "Failed to save note");
    } finally {
      this.setState({ showLoader: false });
    }
  };

  toggleAcknowledgementHistoryModal = (id) => {
    this.setState((prevState) => ({
      acknowledgeHistoryModalStatus: !prevState.acknowledgeHistoryModalStatus,
      notesId: !prevState.acknowledgeHistoryModalStatus ? id : "",
    }));
  };

  handleDictationChange = (formattedNote) => {
    if (typeof formattedNote === "object" && formattedNote.note) {
      this.setState({
        noteText: formattedNote.note,
        dataChanged: true,
      });
    } else {
      this.setState({
        noteText: formattedNote,
        dataChanged: true,
      });
    }
  };

  handleClear = () => {
    this.setState({
      noteText: "",
      tags: [],
      noteTobeEdited: 0,
      dataChanged: false,
    });

    if (this.dictationRef && this.dictationRef.current) {
      this.dictationRef.current.resetDictation();
    }

    if (this.chartSmartRef && this.chartSmartRef.current) {
      this.chartSmartRef.current.resetGeneration();
    }
  };

  render() {
    let returnUrl = "";

    if (this.state.roomType && this.state.roomType === "clients") {
      returnUrl = this.props.match.params.type
        ? "/" +
          this.state.roomType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.patientID
        : "/" + this.state.roomType;
    } else if (
      this.state.roomTypeParams &&
      (this.state.roomTypeParams === "provider-room" ||
        this.state.roomTypeParams === "md-room")
    ) {
      returnUrl = `/${this.state.roomTypeParams}/${this.state.action}`;
    } else {
      returnUrl =
        this.state.action !== ""
          ? `/${this.state.roomType}/procedure-detail/` +
            this.state.procedureID +
            `/` +
            this.state.action
          : "";
    }

    let noteTimelineClass =
      this.state.procedureNotes && this.state.procedureNotes.length > 0
        ? "note-timeline"
        : "note-timeline no-display";

    let noRecordClass =
      this.state.procedureNotes && this.state.procedureNotes.length > 0
        ? "no-record no-display"
        : "no-record";

    let firstname =
      this.state.patientData && this.state.patientData.firstname
        ? this.state.patientData.firstname
        : "";

    let lastname =
      this.state.patientData && this.state.patientData.lastname
        ? this.state.patientData.lastname
        : "";

    let patientName = firstname + " " + lastname;

    return (
      <div id="content">
        <div className="wide-popup">
          <div className="modal-blue-header">
            <Link to={returnUrl} className="popup-cross">
              ×
            </Link>
            {this.state.showLoader === false && (
              <span className="popup-blue-name">
                {patientName +
                  ` - ${this.state.languageData.pro_head_pro_notes_text}`}
              </span>
            )}
          </div>

          <div className="wide-popup-wrapper time-line procedure-notes-container">
            {checkIfPermissionAllowed("add-edit-procedure-notes") === true &&
              (this.state.roomTypeParams !== "md-room" ||
                this.state.roomType !== "md-room") && (
                <div>
                  <div className="add-note-section">
                    <div className="note-header">Add Note</div>

                    <div className="dictation-buttons-container">
                      <Dictation
                        ref={this.dictationRef}
                        value={this.state.noteText}
                        onChange={this.handleDictationChange}
                        buttonContent={{
                          dictation: (
                            <>
                              <i className="mic-icon" /> Start Dictation
                            </>
                          ),
                          chartSmart: (
                            <>
                              <ThunderIcon width="20px" />
                              Chart Smart
                            </>
                          ),
                        }}
                      />
                    </div>

                    <form
                      id="add-notes"
                      name="ar-add-notes-form"
                      className="nobottommargin"
                      onSubmit={this.handleSaveNote}
                    >
                      <div className="note-input-container">
                        <div className="note-label">
                          NOTE <span className="required">*</span>
                        </div>

                        <div className="note-editor-wrapper">
                          <PatternAutocomplete
                            value={this.state.noteText || ""}
                            onChange={(next) => {
                              this.setState({ noteText: next });
                            }}
                            placeholder="Type your notes and hashtags here"
                            patterns={[
                              {
                                match: ".",
                                substitutes: this.state.dotPhrasesList,
                              },
                              {
                                match: "@call",
                                substitutes: this.state.medicalHistoryList,
                              },
                            ]}
                          />
                        </div>
                      </div>

                      <div className="action-buttons">
                        <button
                          type="submit"
                          className="save-button"
                          disabled={
                            !this.state.noteText.trim() || this.state.showLoader
                          }
                        >
                          {this.state.showLoader ? "Saving..." : "Save"}
                        </button>
                        <button
                          type="button"
                          className="clear-button"
                          onClick={this.handleClear}
                          disabled={
                            !this.state.noteText.trim() || this.state.showLoader
                          }
                        >
                          Clear
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}

            <div
              className={this.state.showModal === true ? "overlay" : ""}
            ></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showModal === true
                  ? "modal fade in displayBlock"
                  : "modal fade no-display"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.dismissDeleteModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title" id="model_title">
                      {this.state.languageData.pro_delete_confirmation_text}
                    </h4>
                  </div>
                  <div
                    id="errorwindow"
                    className="modal-body add-patient-form filter-patient"
                  >
                    {this.state.languageData.pro_are_you_sure_text}
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 text-left" id="footer-btn">
                      <button
                        type="button"
                        className="btn  logout pull-right"
                        data-dismiss="modal"
                        onClick={this.dismissDeleteModal}
                      >
                        {this.state.languageData.pro_no_text}
                      </button>
                      <button
                        type="button"
                        className="btn btn-success pull-right m-r-10"
                        data-dismiss="modal"
                        onClick={this.deleteNote}
                      >
                        {this.state.languageData.pro_yes_text}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.showLoader === false && (
              <div className={noRecordClass}>
                {this.state.languageData.pro_no_notes_text}
              </div>
            )}
            {this.state.showLoader === true &&
              this.state.noteTobeEdited === 0 && (
                <div className="no-record">
                  {this.state.languageData.pro_loading_dot}
                </div>
              )}

            <div className={noteTimelineClass}>
              {checkIfPermissionAllowed("view-procedure-notes") &&
                this.state.procedureNotes !== undefined &&
                this.state.procedureNotes &&
                this.state.procedureNotes.length > 0 &&
                this.state.procedureNotes.map((noteobj, noteidx) => {
                  let noteToShow = "";

                  if (noteobj && noteobj.notes) {
                    noteToShow = noteobj.notes.replace(/ {1,}/g, " ");
                  }
                  return (
                    <div id={noteobj.id} className="row" key={noteidx}>
                      <div className="circle" />
                      <div className="col-sm-4 col-xs-12 col-md-3 note-subject">
                        <h4>
                          {noteobj.user !== null
                            ? displayName(noteobj.user)
                            : "Deleted User"}{" "}
                          {this.state.languageData.pro_left_a_note_text}
                        </h4>
                        <p>{showFormattedDate(noteobj.created, true)}</p>
                      </div>
                      <div
                        className="col-sm-6 col-xs-12 col-md-6"
                        id="note-content"
                      >
                        <div
                          className="note-content"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {noteToShow.trim()}
                          <br />
                          {noteobj.hashtags &&
                            noteobj.hashtags
                              .split(",")
                              .map((hashObj, hashIdx) => {
                                return (
                                  <span key={hashIdx} className="hashTag">
                                    {hashObj}{" "}
                                  </span>
                                );
                              })}
                        </div>
                        <textarea className="no-display" defaultValue="" />
                      </div>
                      {noteobj.user !== null && (
                        <div className="col-sm-2 col-xs-12 col-md-3 no-padding">
                          {checkIfPermissionAllowed(
                            "add-edit-procedure-notes",
                          ) &&
                          (this.getLoggedInUserData() === noteobj.user.id ||
                            noteobj.user.id ==
                              process.env.REACT_APP_SUPERUSER) &&
                          (this.state.roomTypeParams !== "md-room" ||
                            this.state.roomType !== "md-room") ? (
                            <button
                              id="edit-note"
                              className="easy-link"
                              name={noteobj.id}
                              onClick={this.editThisNote.bind(this)}
                            >
                              {this.state.languageData.pro_edit_btn_text}
                            </button>
                          ) : (
                            ""
                          )}
                          {checkIfPermissionAllowed(
                            "add-edit-procedure-notes",
                          ) ||
                          checkIfPermissionAllowed("view-procedure-notes") ||
                          (noteobj.user.id == process.env.REACT_APP_SUPERUSER &&
                            (this.state.roomTypeParams !== "md-room" ||
                              this.state.roomType !== "md-room")) ? (
                            <button
                              id="edit-note"
                              className="easy-link"
                              name={noteobj.id}
                              onClick={() =>
                                this.toggleAcknowledgementHistoryModal(
                                  noteobj.id,
                                )
                              }
                            >
                              Note History
                            </button>
                          ) : (
                            ""
                          )}

                          {checkIfPermissionAllowed(
                            "add-edit-procedure-notes",
                          ) &&
                          (this.getLoggedInUserData() === noteobj.user.id ||
                            noteobj.user.id ==
                              process.env.REACT_APP_SUPERUSER) &&
                          (this.state.roomTypeParams !== "md-room" ||
                            this.state.roomType !== "md-room") ? (
                            <button
                              id="delete-note"
                              className="easy-link"
                              name={noteobj.id}
                              onClick={() => this.showDeleteModal(noteobj)}
                            >
                              {this.state.languageData.pro_del_btn_text}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className={
              this.state.showLoader
                ? "new-loader text-left displayBlock"
                : "new-loader text-left"
            }
          >
            <div className="loader-outer">
              <img
                alt=""
                id="loader-outer"
                src="/images/Eclipse.gif"
                className="loader-img"
              />
              <div id="modal-confirm-text" className="popup-subtitle">
                {this.state.globalLang.Please_Wait}
              </div>
            </div>
          </div>
        </div>
        {this.state.acknowledgeHistoryModalStatus && (
          <AcknowledgeHistoryModal
            id={this.state.notesId}
            onClose={this.toggleAcknowledgementHistoryModal}
            isAcknowledgeHistorOrNotesHistory={
              !this.state.acknowledgeHistoryModalStatus
            }
            noteType="procedure-note"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  localStorage.setItem("showLoader", false);

  if (state.ProcedureNotesReducer.action === "GET_PROCEDURE_NOTES") {
    if (state.ProcedureNotesReducer.data.status !== 200) {
      if (
        state.ProcedureNotesReducer.data.message ===
          "validation_patient_id_exists" ||
        state.ProcedureNotesReducer.data.message ===
          "validation_procedure_id_exists"
      ) {
        setTimeout(function () {
          window.location.href = "/clients";
        }, 1700);
      } else {
        return {
          procedureNotes: state.ProcedureNotesReducer.data,
        };
      }
    } else {
      if (state.ProcedureNotesReducer.data.status === 200) {
        return {
          procedureNotes: state.ProcedureNotesReducer.data,
        };
      }
    }
  }

  if (state.ProcedureNotesReducer.action === "SAVE_PROCEDURE_NOTES") {
    if (state.ProcedureNotesReducer.data.status !== 201) {
      return {
        procedureNotes: state.ProcedureNotesReducer.data,
      };
    } else {
      state.ProcedureNotesReducer.data.status = 200;
      return {
        procedureNotes: state.ProcedureNotesReducer.data,
      };
    }
  }

  if (state.ProcedureNotesReducer.action === "DELETE_PROCEDURE_NOTE") {
    return {
      deleteNoteData: state.ProcedureNotesReducer.data,
    };
  }

  if (state.ProcedureNotesReducer.action === "UPDATE_PROCEDURE_NOTE") {
    return {
      procedureNotes: state.ProcedureNotesReducer.data,
    };
  }

  if (state.ProcedureNotesReducer.action === "GET_PROCEDURE_NOTE") {
    return {
      procedureNote: state.ProcedureNotesReducer.data,
    };
  }

  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProcedureNotes,
      saveProcedureNote,
      deleteProcedureNote,
      getAProcedureNote,
    },
    dispatch,
  );
}

ProcedureNotes.propTypes = {
  saveProcedureNote: PropTypes.func.isRequired,
  getProcedureNotes: PropTypes.func.isRequired,
  deleteProcedureNote: PropTypes.func.isRequired,
  getAProcedureNote: PropTypes.func.isRequired,
  procedureNotes: PropTypes.shape({
    status: PropTypes.number,
    data: PropTypes.shape({
      procedure_notes: PropTypes.array,
      patient_detail: PropTypes.object,
      dot_phrases_list: PropTypes.array,
      medical_history: PropTypes.array,
      procedure_note: PropTypes.object,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
      procedureID: PropTypes.string,
      patientID: PropTypes.string,
      noteID: PropTypes.string,
      roomType: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProcedureNotes));
