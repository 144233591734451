import { useEfaxTemplatesQuery } from "../../../../../api/efax/useEfaxTemplatesQuery";
import { useDebouncedQueryParamState } from "../../../../../helpers/hooks/useDebouncedQueryParamState";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { TABS } from "../config";
import { useFavorites } from "./useFavorites";

export const useData = (activeTab) => {
  const { tSettings } = useAppTranslation.Settings();

  const [search, debouncedSearch, setSearch] = useDebouncedQueryParamState(
    "search",
    "",
  );

  const {
    data,
    isLoading: isTemplatesLoading,
    isFetching: isFetchingTemplates,
  } = useEfaxTemplatesQuery(
    {
      searchTerm: debouncedSearch,
    },
    {
      enabled: activeTab.tab === TABS.templates,
      onError: () => {
        uiNotification.error(tSettings("efaxTemplates.error.fetch"));
      },
    },
  );

  const {
    value: favorites,
    isLoading: isFavoritesLoading,
    isFetching: isFetchingFavorites,
  } = useFavorites(
    { searchTerm: debouncedSearch },
    {
      enabled: activeTab.tab === TABS.favorites,
    },
  );

  const isSearching =
    !!debouncedSearch && (isFetchingTemplates || isFetchingFavorites);

  return {
    value:
      activeTab.tab === TABS.templates
        ? data?.templates || []
        : favorites || [],
    isTemplatesLoading,
    isFavoritesLoading,
    search: {
      value: search,
      onChange: setSearch,
      isSearching,
    },
  };
};
