import React from "react";
import PropTypes from "prop-types";
import { InputPhone as SharedInputPhone } from "../../../../../shared/InputPhone/InputPhone";
import { InputError } from "../../../../../shared/InputError/InputError";

export function InputPhone({ value, onChange, isError, isDisabled, error }) {
  return (
    <div>
      <SharedInputPhone
        fullWidth
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
        isError={Boolean(error) || isError}
      />
      {Boolean(error) && <InputError>{error}</InputError>}
    </div>
  );
}

InputPhone.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
};

InputPhone.defaultProps = {
  value: "",
  isError: false,
  isDisabled: false,
  onChange: () => {},
};
