import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  id: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
  clinicId: yup.number().nullable(),
  patientId: yup.number().nullable(),
});

const composeResponse = (res) => {
  const data = res?.data?.data || {};

  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    phone: data.client_phone_number,
    clinicId: data.clinic_id,
    patientId: data.patient_reference,
  };
};

const createLeadQueryKey = (id) => [QUERY_KEYS.lead, id];

export const useLeadQuery = (payload, options = {}) => {
  return useQuery(
    createLeadQueryKey(payload.id),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.getLead(req.id));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
