import { MUTATION_RECORD_CREATE_MODE_ID, REDUCER_NAME } from "./config";
import { createMutationRecordSnapshot } from "./utilities";

export const selectors = {
  selectActiveRecordId: (state) => state[REDUCER_NAME].activeRecordId,

  selectMutationRecord: (state) => state[REDUCER_NAME].mutationRecord,

  selectIsMutationRecordSet: (state) =>
    Boolean(state[REDUCER_NAME].mutationRecord),

  selectIsCreateMode: (state) =>
    Boolean(
      state[REDUCER_NAME].mutationRecord?.id === MUTATION_RECORD_CREATE_MODE_ID,
    ),

  selectMutationRecordFieldErrors: (state) =>
    state[REDUCER_NAME].mutationRecordFieldErrors,

  getSelectGraphs: (state) => (patientId) =>
    state[REDUCER_NAME].graphs?.[patientId] || [],

  selectIsGraphMutating: (state) => state[REDUCER_NAME].isGraphMutating,

  selectIsMutationRecordChanged: (state) => {
    const prevSnapshot = state[REDUCER_NAME].mutationRecordInitialSnapshot;
    const currentSnapshot = createMutationRecordSnapshot(
      state[REDUCER_NAME].mutationRecord,
    );

    return prevSnapshot !== currentSnapshot;
  },
};
