import React from "react";
import { Group } from "../../shared/Group/Group";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { MultiRowSelect } from "../../boxes/MultiRowSelect/MultiRowSelect";
import { getIsSomeTruthy } from "../../../../../../helpers/general";
import { getCurrentMedicationCheck } from "../../../MergeProfilesMain.utils";

export function CurrentMedications({ intersection, medications, onChange }) {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();
  const isSomeTruthy = getIsSomeTruthy(medications);

  return (
    <Group
      biggerGap
      label={tClients("medicalHistory.currentMedications.label")}
    >
      {isSomeTruthy(["prescription_medications"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.currentMedications.prescriptions")}
          value={intersection.prescription_medication || []}
          patientOptions={medications.map(
            (m) => m.prescription_medication || [],
          )}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              prescription_medication: value,
              prescription_medications: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getCurrentMedicationCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tCommon("label.medication"),
                  value: option.medication,
                },
                {
                  label: tCommon("label.dose"),
                  value: option.dose,
                },
                {
                  label: tCommon("label.frequency"),
                  value: option.frequency,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["vitamins"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.currentMedications.vitamins")}
          value={intersection.vitamin || []}
          patientOptions={medications.map((m) => m.vitamin || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              vitamin: value,
              vitamins: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getCurrentMedicationCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tCommon("label.medication"),
                  value: option.medication,
                },
                {
                  label: tCommon("label.dose"),
                  value: option.dose,
                },
                {
                  label: tCommon("label.frequency"),
                  value: option.frequency,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["over_medications"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.currentMedications.otcMedications")}
          value={intersection.over_medication || []}
          patientOptions={medications.map((m) => m.over_medication || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              over_medication: value,
              over_medications: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getCurrentMedicationCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tCommon("label.medication"),
                  value: option.medication,
                },
                {
                  label: tCommon("label.dose"),
                  value: option.dose,
                },
                {
                  label: tCommon("label.frequency"),
                  value: option.frequency,
                },
              ]}
            />
          )}
        />
      )}
    </Group>
  );
}
