import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "../../../../shared/Dropdown/Dropdown";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import { useWindowSize } from "../../../../utilities/hooks/useWindowSize";
import classes from "../sass/Category.module.scss";
import { MOBILE_BREAKPOINT } from "../Picker.consts";
import { limitList } from "../../../../utilities/general";
import { CategoryService } from "./CategoryService";

export function Category({ picker, category }) {
  const windowSize = useWindowSize();

  const isCategorySelected = picker.isCategorySelected(category.id);
  const isCategoryPartiallySelected = picker.isCategoryPartiallySelected(
    category.id,
  );
  const isCategoryNotSelected = picker.isCategoryNotSelected(category.id);

  return (
    <Dropdown
      fullWidth
      label={category.name}
      className={classes.root}
      labelClassName={classes.label}
      contentClassName={classes.content}
      headerInnerClassName={classes.header}
      leftAdornment={
        <Checkbox
          isChecked={isCategorySelected || isCategoryPartiallySelected}
          isIntermediate={isCategoryPartiallySelected}
          onChange={() => {
            if (isCategoryNotSelected) {
              picker.selectAllServices(category.id);
            } else {
              picker.deselectAllServices(category.id);
            }
          }}
        />
      }
      rightAdornment={
        windowSize.width > MOBILE_BREAKPOINT && (
          <div className={classes.location}>
            {limitList(category.clinicNames, 2)}
          </div>
        )
      }
    >
      {category.services.map((service) => (
        <CategoryService key={service.id} picker={picker} service={service} />
      ))}
    </Dropdown>
  );
}

Category.propTypes = {
  picker: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
};
