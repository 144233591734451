import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  clientId: yup.number().required(),
  cardId: yup.number().nullable(),
  token: yup.string().required(),
  clearentZip: yup.string().nullable(),
  clearentEmail: yup.string().nullable(),
});

export function useSaveClientCcMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.saveClientCC(req.clientId),
      removeNullishFromShape({
        card_id: req.cardId,
        token: req.token,
        clearent_zip: req.clearentZip,
        clearent_email_id: req.clearentEmail,
      }),
    );
  }, options);
}
