import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-date-range";
import calenLogo from "../../../../../_legacy/images/calender.svg";
import { showFormattedDate } from "../../../../../Utils/services";

const PaperworkHeader = ({
  handleSubmit,
  term,
  handleInputChange,
  calendarRef,
  showCalendar,
  dateRangePickerSelection,
  handleRangeChange,
  from_date,
  to_date,
  selected,
  handleRestoreModal,
}) => {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  const calendarInputValue = useMemo(() => {
    return from_date ? `${showFormattedDate(from_date, false)}-${showFormattedDate(to_date, false)}` : ""
  }, [from_date, to_date]);

  return (
    <div className="setting-setion m-b-10">
      <div className="membership-title">
        Paperwork
        <form onSubmit={handleSubmit} className="searchForm searchFormIpad">
        <span className="searchOuter">
          <i className="fa fa-search search-icon"/>
          <input
            className="setting-search-input search-key"
            name="term"
            placeholder={languageData.global.label_search}
            value={term}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </span>
        </form>
        <div className="memberRightActions">
          <div className="search-bg new-calender pull-left" ref={calendarRef}>
            <img src={calenLogo} />
            {showCalendar && (
              <DateRangePicker
                ranges={[dateRangePickerSelection]}
                onChange={(e) => handleRangeChange("dateRangePicker", e)}
                className="CalendarPreviewArea"
                maxDate={new Date()}
                dragSelectionEnabled={false}
              />
            )}
            <input
              type="text"
              className="input-cal setting-search-input p-b-5"
              name="calendar-input"
              value={calendarInputValue}
              autoComplete="off"
              onChange={handleInputChange}
            />
          </div>
          <button
            className={selected.length > 0 ? "new-blue-btn pull-right" : "new-blue-btn pull-right disable"}
            onClick={selected.length > 0 ? handleRestoreModal : null}
          >
            {languageData.settings.recently_deleted_restore}
          </button>
        </div>
      </div>
    </div>
  );
}

PaperworkHeader.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  term: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  calendarRef: PropTypes.func.isRequired,
  showCalendar: PropTypes.bool.isRequired,
  dateRangePickerSelection: PropTypes.object.isRequired,
  handleRangeChange: PropTypes.func.isRequired,
  from_date: PropTypes.string.isRequired,
  to_date: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  handleRestoreModal: PropTypes.func.isRequired,
}

export default PaperworkHeader;
