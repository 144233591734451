import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../shared/Modal/Modal";
import { Button } from "../../../../shared/Button/Button";
import classes from "./ManualTimeModal.module.scss";
import { DatePicker } from "../../../../shared/DatePicker/DatePicker";
import { useForm } from "./hooks/useForm";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import {
  getClockInBoundaries,
  getClockOutBoundaries,
  getHeader,
  unwrapFormTime,
  unwrapSelectedFormTime,
  wrapFormTime,
} from "./ManualTimeModal.utils";
import { TIME_PICKER_INTERVAL_MINUTES } from "./ManualTimeModal.consts";
import { InputError } from "../../../../shared/InputError/InputError";
import { useManualTimeLogMutation } from "../../../../api/mutations/useManualTimeLogMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { extractApiError } from "../../../../utilities/api";
import { tCommon } from "../../../../i18n/useAppTranslation";

export function ManualTimeModal({
  isOpen,
  onClose,
  refetchLogs,
  selectedUser,
}) {
  const timeClockInRef = useRef(null);
  const timeClockOutRef = useRef(null);

  const { mutate, isLoading } = useManualTimeLogMutation({
    onError: (e) => {
      const message = extractApiError(e);
      uiNotification.error(
        tCommon(`apiError.${message}`, "Unable To Add Time"),
      );
    },
    onSuccess: () => {
      refetchLogs();
      onClose();
      uiNotification.success("Time Has Been Added Successfully");
    },
  });

  const { form, setFormValue, submit, hasError, getError } = useForm({
    onSubmit: (values) =>
      mutate({
        userId: selectedUser.id,
        clockInDate: values.timeClockIn,
        clockOutDate: values.timeClockOut,
      }),
  });

  const clockInBoundaries = getClockInBoundaries();
  const clockOutBoundaries = getClockOutBoundaries(form.timeClockIn);

  return (
    <Modal
      headerNoBorder
      footerNoBorder
      header={<Modal.Title>{getHeader(selectedUser.name)}</Modal.Title>}
      isOpen={isOpen}
      onClose={onClose}
      headerClassName={classes.header}
      contentClassName={classes.content}
      footer={
        <Button
          isDisabled={isLoading}
          onClick={submit}
          leftAdornment={
            isLoading ? (
              <CircularProgress color="white" size="small" />
            ) : undefined
          }
        >
          Save
        </Button>
      }
    >
      <div>
        <InputLabel>Clock In Time:</InputLabel>
        <DatePicker
          showTimeSelect
          showDisabledMonthNavigation
          name="timeClockIn"
          ref={timeClockInRef}
          timeIntervals={TIME_PICKER_INTERVAL_MINUTES}
          value={unwrapFormTime(form.timeClockIn)}
          onChange={(next) => setFormValue("timeClockIn", wrapFormTime(next))}
          isError={hasError("timeClockIn")}
          selected={unwrapSelectedFormTime(form.timeClockIn)}
          isDisabled={isLoading}
          onClickOutside={() => timeClockInRef.current.setOpen(false)}
          onChangeRaw={(e) => e.preventDefault()}
          minDate={clockInBoundaries.minDate}
          maxDate={clockInBoundaries.maxDate}
          minTime={clockInBoundaries.minTime}
          maxTime={clockInBoundaries.maxTime}
        />
        {hasError("timeClockIn") && (
          <InputError>{getError("timeClockIn")}</InputError>
        )}
      </div>
      <div>
        <InputLabel>Clock Out Time:</InputLabel>
        <DatePicker
          showTimeSelect
          showDisabledMonthNavigation
          name="timeClockOut"
          ref={timeClockOutRef}
          timeIntervals={TIME_PICKER_INTERVAL_MINUTES}
          value={unwrapFormTime(form.timeClockOut)}
          onChange={(next) => setFormValue("timeClockOut", wrapFormTime(next))}
          isError={hasError("timeClockOut")}
          selected={unwrapSelectedFormTime(form.timeClockOut)}
          isDisabled={isLoading}
          onClickOutside={() => timeClockOutRef.current.setOpen(false)}
          onChangeRaw={(e) => e.preventDefault()}
          minDate={clockOutBoundaries.minDate}
          maxDate={clockOutBoundaries.maxDate}
          minTime={clockOutBoundaries.minTime}
          maxTime={clockOutBoundaries.maxTime}
        />
        {hasError("timeClockOut") && (
          <InputError>{getError("timeClockOut")}</InputError>
        )}
      </div>
    </Modal>
  );
}

ManualTimeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchLogs: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
};
