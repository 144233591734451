import React from "react";
import PropTypes from "prop-types";
import { EntityRow } from "../EntityRow";
import { Select } from "../../../../shared/Select/Select";

const OutOfOfficeProviders = ({ isError, onChange, options, values }) => (
  <EntityRow label="Select Providers">
    <Select
      onChange={(nextValues) => {
        onChange(
          nextValues.map((i) => ({
            id: i.value,
            name: i.label,
          })),
        );
      }}
      value={values.map((i) => ({
        value: i.id,
        label: i.name,
      }))}
      isClearable
      isSearchable
      options={options.map((i) => ({
        value: i.id,
        label: i.name,
      }))}
      isMulti
      isError={isError}
    />
  </EntityRow>
);

const nodeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

OutOfOfficeProviders.propTypes = {
  isError: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(nodeType),
  values: PropTypes.arrayOf(nodeType),
};

OutOfOfficeProviders.defaultProps = {
  isError: false,
  onChange: () => {},
  options: [],
  values: [],
};

export default OutOfOfficeProviders;
