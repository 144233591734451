import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./AfterFrontImage.module.scss";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { ImageUploader } from "../../../../../../../boxes/ImageUploader/ImageUploader";
import { IMAGE_UPLOAD_TYPE } from "../../CosmeticCreateEdit.consts";
import { useDefaultImage } from "../../hooks/useDefaultImage";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { useImageUploader } from "../../../../../../../hooks/useImageUploader";

export function AfterFrontImage({ image, isLoading }) {
  const { tClients } = useAppTranslation.Clients();
  const defaultImage = useDefaultImage();
  const uploader = useImageUploader({
    uploadType: IMAGE_UPLOAD_TYPE,
  });

  return (
    <div>
      <div className={classes.title}>
        {tClients("createEditCosmeticProcedure.images.afterFront")}
      </div>
      {isLoading ? (
        <Skeleton count={1} height={137} width="122px" borderRadius="4px" />
      ) : (
        <div>
          <InputLabel>
            {tClients("createEditCosmeticProcedure.images.front")}
          </InputLabel>
          <ImageUploader
            name="afterFrontImage"
            value={image.value}
            isLoading={uploader.isLoading}
            onChange={(e) =>
              uploader
                .htmlOnChange(e)
                .then(({ uploadedFileFullPath }) =>
                  image.onChange(uploadedFileFullPath),
                )
            }
            onRemove={() => image.onChange(null)}
            defaultImage={defaultImage}
          />
        </div>
      )}
    </div>
  );
}

AfterFrontImage.propTypes = {
  image: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};
