import React from "react";
import classes from "./Section.module.scss";
import { Button } from "../../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";

export const Section = ({
  label,
  children,
  contentClassName,
  onSubmit,
  onReset,
  isSubmitting,
  isDirty,
  isLoading,
}) => {
  const { tCommon } = useAppTranslation.Common();

  return (
    <section className={classes.root}>
      <h3 className={classes.label}>{label}</h3>
      <div className={classes.contentWrap}>
        <div className={contentClassName}>{children}</div>
        {Boolean(onReset && onSubmit) && (
          <div className={classes.footer}>
            <div className={classes.footerInner}>
              {isLoading ? (
                <>
                  <Skeleton borderRadius="4px" width="56px" height={36} />
                  <Skeleton borderRadius="4px" width="56px" height={36} />
                </>
              ) : (
                <>
                  <Button
                    size="small"
                    onClick={onSubmit}
                    isDisabled={isSubmitting || !isDirty}
                    leftAdornment={
                      isSubmitting ? (
                        <CircularProgress size="tiny" color="white" />
                      ) : undefined
                    }
                  >
                    {tCommon("label.save")}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={onReset}
                    isDisabled={isSubmitting || !isDirty}
                  >
                    {tCommon("label.reset")}
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
