import { useMemo } from "react";
import * as yup from "yup";
import { useServicesByProviderIdClinicIdAndTypeQuery } from "../../../api/queries/useServicesByProviderIdClinicIdAndTypeQuery";
import { uiNotification } from "../../../services/UINotificationService";

const schema = yup.object({
  services: yup.array().of(
    yup.object({
      id: yup.number(),
      name: yup.string(),
      durationInMin: yup.number(),
      price: yup.string(),
      isFree: yup.bool(),
    }),
  ),
  clearentClinic: yup.object({
    publickey: yup.string(),
  }),
});

const compose = (response) => {
  let result = {};
  if (response && response.data.data) {
    const services = response.data.data.services || [];
    result = {
      services: services.map((service) => ({
        id: service.id,
        name: service.name,
        durationInMin: Number(service.duration),
        price: service.price,
        isFree: String(service.is_service_free) === "1",
      })),
      clearentClinic: response.data.data.clinic || {},
    };
  }
  return result;
};

export function useApiServices({ providerId, clinicId, type }) {
  const { data, isFetching } = useServicesByProviderIdClinicIdAndTypeQuery(
    {
      providerId,
      clinicId,
      type,
    },
    {
      staleTime: 0,
      enabled: Boolean(providerId && clinicId && type),
      onError: () => {
        uiNotification.error("Unable to retrieve services. Try again later");
      },
    },
  );

  const { services, clearentClinic } = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  return {
    services,
    clearentClinic,
    isServicesFetching: isFetching,
  };
}
