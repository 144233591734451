import { useMutation } from "@tanstack/react-query";
import { http } from "../../services/HttpService";

export const useAddDocumentExpirationDate = (options = {}) => {
  const mutation = useMutation(
    async ({ documentId, type, patientId, expiryDate, parentID, subType }) => {
      const payload = {
        document_id: documentId,
        type,
        patient_id: patientId,
        expiration_date: expiryDate,
        parent_id: parentID,
        sub_type: subType,
      };

      return http.post("add-edit-document-expiration-date", payload);
    },
    options,
  );

  const isAddLoading = mutation.isLoading && mutation.variables.expiryDate;
  const isDeleteLoading = mutation.isLoading && !mutation.variables.expiryDate;

  return {
    mutation,
    isAddLoading,
    isDeleteLoading,
  };
};
