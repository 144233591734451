import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  membershipId: yup.number().required(),
});

const responseSchema = yup.object({
  patient: yup
    .object({
      id: yup.number().required(),
      fullName: yup.string().nullable(),
      countryName: yup.string().nullable(),
      imageUrl: yup.string().nullable(),
    })
    .required(),

  invoices: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        amount: yup.number().required(),
        invoiceNumber: yup.string().nullable(),
        modifiedAt: yup.string().nullable(),
        subscriptionType: yup.string().required(),
        paymentStatus: yup.string().nullable(),
        currency: yup.string().required(),
      }),
    )
    .required(),

  tier: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .required(),
});

const composeResponse = (res) => {
  const invoices = unwrapOr(() => res.data.data.invoices, []);
  const patient = unwrapOr(() => res.data.data.patient, {});
  const tier = unwrapOr(() => res.data.data.tier, {});
  const posData = unwrapOr(() => res.data.global_settings.pos_data, {});

  return {
    patient: {
      id: patient.id,
      fullName: patient.full_name || null,
      countryName: patient.country_name || null,
      imageUrl: patient.user_image_url || null,
    },
    invoices: invoices.map((invoice) => ({
      id: invoice.id,
      amount: invoice.amount,
      invoiceNumber: invoice.invoice_number || null,
      modifiedAt: invoice.modified || null,
      subscriptionType: invoice.payment_frequency,
      paymentStatus: invoice.payment_status || null,
      currency: posData.currency_code,
    })),
    tier: {
      id: tier.id,
      name: tier.tier_name,
    },
  };
};

function createMembershipInvoicesQueryKey({ membershipId }) {
  return [QUERY_KEYS.membershipInvoices, membershipId];
}

export function useMembershipInvoicesQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useQuery(
    createMembershipInvoicesQueryKey({
      membershipId: req.membershipId,
    }),
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.getMembershipInvoices(req.membershipId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
