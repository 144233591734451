import React from "react";
import moment from "moment";
import { Group } from "../../shared/Group/Group";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import classes from "./CurrentMedConditions.module.scss";
import { Labeled } from "../../shared/Labeled/Labeled";
import { Select } from "../../../../../../shared/Select/Select";
import { valueToSelectOption } from "../../MedicalHistory.utils";
import { MultiRowSelect } from "../../boxes/MultiRowSelect/MultiRowSelect";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../consts/general";
import { RadioRowSelect } from "../../boxes/RadioRowSelect/RadioRowSelect";
import { getIsSomeTruthy } from "../../../../../../helpers/general";
import { getCurrentMedicalHistoryCheck } from "../../../MergeProfilesMain.utils";

export function CurrentMedConditions({ intersection, conditions, onChange }) {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();
  const { data: currentUser } = useCurrentUserQuery();
  const isSomeTruthy = getIsSomeTruthy(conditions);

  const valueToOption = (value) => {
    return valueToSelectOption(value, (l) =>
      l === "1" ? tCommon("label.yes") : tCommon("label.no"),
    );
  };

  return (
    <Group
      biggerGap
      label={tClients("medicalHistory.currentMedConditions.label")}
    >
      <div className={classes.selects}>
        <Labeled label={tCommon("label.pregnancy")}>
          <Select
            size="small"
            value={valueToOption(intersection.pregnancy)}
            options={Array.from(
              new Set(conditions.map((c) => c.pregnancy || 0)),
            ).map(valueToOption)}
            onChange={(nextOption) => {
              const nextIntersection = {
                ...intersection,
                pregnancy: nextOption.value,
              };
              onChange({
                ...nextIntersection,
                check: getCurrentMedicalHistoryCheck(nextIntersection),
              });
            }}
          />
        </Labeled>
        <Labeled label={tCommon("label.breastfeeding")}>
          <Select
            size="small"
            value={valueToOption(intersection.breastfeeding)}
            options={Array.from(
              new Set(conditions.map((c) => c.breastfeeding || 0)),
            ).map(valueToOption)}
            onChange={(nextOption) => {
              const nextIntersection = {
                ...intersection,
                breastfeeding: nextOption.value,
              };
              onChange({
                ...nextIntersection,
                check: getCurrentMedicalHistoryCheck(nextIntersection),
              });
            }}
          />
        </Labeled>
      </div>
      {isSomeTruthy(["ongoing_conditions"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.currentMedConditions.ongoing")}
          value={intersection.ongoing_condition || []}
          patientOptions={conditions.map((c) => c.ongoing_condition || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              ongoing_condition: value,
              ongoing_conditions: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getCurrentMedicalHistoryCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tClients(
                    "medicalHistory.currentMedConditions.diagnosisType",
                  ),
                  value: option.type,
                },
                {
                  label: tClients(
                    "medicalHistory.currentMedConditions.diagnosisDate",
                  ),
                  value: moment(option.date).format(
                    currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                  ),
                },
                {
                  label: tClients(
                    "medicalHistory.currentMedConditions.physicianName",
                  ),
                  value: option.name,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["nutrition_history"]) && (
        <RadioRowSelect
          name={tClients("medicalHistory.currentMedConditions.diets")}
          value={intersection.nutrition_history}
          patientOptions={conditions.map((c) => c.nutrition_history)}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              nutrition_history: value,
              nutrition: String(value).trim() === "" || !value ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getCurrentMedicalHistoryCheck(nextIntersection),
            });
          }}
        />
      )}
    </Group>
  );
}
