/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import cx from "clsx";
import { toggleBodyScroll } from "../../Utils/services.js";
import picClose from "../../_legacy/images/popupClose.png";
import { TextEditor } from "../../shared/TextEditor/TextEditor.js";

export default class EditorWithPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreviewModal: false,
    };
  }

  handleEditorChange = (e) => {
    this.props.handleEditorChange({ [this.props.name]: e, userChanged: true });
  };

  togglePreviewModal = () => {
    toggleBodyScroll(!this.state.showPreviewModal);
    this.setState({ showPreviewModal: !this.state.showPreviewModal });
    this.props.fromMembershipWallet &&
      this.props.isPreviewBtnOpen(!this.state.showPreviewModal);
  };

  render() {
    return (
      <div
        className={
          this.props.className.indexOf("no-display") == -1 ? "" : "no-display"
        }
      >
        <div
          className={`${this.props.className} editor-with-preview`}
          id={`divToPrint-${this.props.name}`}
        >
          <TextEditor
            placeholder={this.props.placeholder || "Description"}
            value={this.props.value || ""}
            onChange={this.handleEditorChange}
          />
        </div>
        {this.props.showPreviewButton === true && (
          <a
            className="new-blue-btn pull-right m-t-20"
            onClick={this.togglePreviewModal}
          >
            Preview Email Template
          </a>
        )}
        {this.props.defaultTemplateData && (
          <a
            className="new-blue-btn pull-right m-t-20"
            onClick={() => {
              this.props.handleEditorChange({
                [this.props.name]: this.props.defaultTemplateData,
                userChanged: true,
              });
            }}
          >
            Use Template
          </a>
        )}

        {this.props.showPreviewButton === true && (
          <div
            className={
              this.state.showPreviewModal
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div
              className={cx({
                "preview-content-center": this.props.fromMembershipWallet,
                "vert-middle vert-middle-report-option":
                  !this.props.fromMembershipWallet,
              })}
            >
              <div className={"white-popup previewEditPopup large-popup"}>
                <div
                  className={cx({
                    "white-popup-override": this.props.fromMembershipWallet,
                    "white-popup-wrapper": !this.props.fromMembershipWallet,
                  })}
                >
                  <div
                    className={cx({
                      "preview-title-format": this.props.fromMembershipWallet,
                      membershipTypeTitle: !this.props.fromMembershipWallet,
                    })}
                  >
                    {"Preview"}
                    <a onClick={this.togglePreviewModal} className="popupClose">
                      <img alt="" src={picClose} />
                    </a>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Scrollbars
                        style={{ height: 500 }}
                        className={"custome-scroll"}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props.prePostHTML.replace(
                              /{{EMAIL_TEMPLATE_BODY}}/g,
                              `${this.props.value}`,
                            ),
                          }}
                        />
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
