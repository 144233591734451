import { useState } from "react";
import { dispatch } from "../../../../store/store";
import { providerRoom } from "../../../../store/providerRoom";
import { getProcedureStatuses } from "../../ProviderRoom.utils";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { unwrapOr } from "../../../../utilities/general";

export function useTabSignActions({
  selected,
  procedures,
  isProcedureCompletedPredicate,
  isNeedMd,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [completeSignFn, setCompleteSignFn] = useState(null);
  const [isIncompleteSignModalOpen, setIsIncompleteSignModalOpen] =
    useState(false);

  const handleSign = ({ isSendToMd, isConsult } = {}) => {
    setIsIncompleteSignModalOpen(false);
    dispatch(
      providerRoom.actions.signProceduresDataChange({
        procedureIds: selected,
        isSendToMd,
        isConsult,
      }),
    );
  };

  const handleSignWithIncomplete = ({ isSendToMd, isConsult } = {}) => {
    const selectedProcedures = procedures.filter((p) =>
      selected.includes(p.id),
    );

    const complete = () => {
      handleSign({
        isSendToMd,
        isConsult,
      });
    };

    if (
      selectedProcedures.some(
        (p) => !isProcedureCompletedPredicate(getProcedureStatuses(p)),
      )
    ) {
      setIsIncompleteSignModalOpen(true);
      setCompleteSignFn(() => complete);
    } else {
      complete();
    }
  };

  const actions = [
    {
      key: "sign",
      label: tCommon("label.sign"),
      onClick: handleSignWithIncomplete,
    },
    {
      key: "markAndSign",
      label: tCommon("providerRoom.action.signAndMarkConsult"),
      onClick: () => handleSignWithIncomplete({ isConsult: true }),
    },
  ];

  const withMdActions = [
    ...actions,
    {
      key: "signAndSend",
      label: tCommon("providerRoom.action.signAndSendToMd"),
      onClick: () => handleSignWithIncomplete({ isSendToMd: true }),
    },
  ];

  return {
    actions: isNeedMd ? withMdActions : actions,
    isIncompleteSignModalOpen,
    setIsIncompleteSignModalOpen,
    completeSign: () => unwrapOr(completeSignFn, null),
  };
}
