import React, { useMemo, useState } from "react";
import { Table } from "../../../../../shared/Table/Table";
import { useStaffTreatment } from "./hooks/useStaffTreatment";
import classes from "../../../newBusinessInsightsContent.module.scss";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "staffTreatmentReport.info.title",
  rows: ["staffTreatmentReport.info.note"],
};

const StaffTreatment = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };

  const { data, isLoading } = useStaffTreatment(requestParams);
  const [expandedRows, setExpandedRows] = useState([]);

  const isTableDataEmpty = !isLoading && !data?.reportData?.length;

  const reportData = useMemo(
    () =>
      data?.reportData?.map((data, index) => ({
        providerName: data.user_name.user_name,
        index,
        children: data.data.map((user) => ({
          providerName: user.user_name,
          firstName: user.firstname,
          lastName: user.lastname,
          treatmentDate: user.payment_datetime,
          invoiceItems: user.items,
        })),
      })),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const handleExpandRow = (row) => {
    if (!expandedRows.includes(row.index)) {
      setExpandedRows((prev) => [...prev, row.index]);
    } else {
      setExpandedRows((prev) => prev.filter((index) => index !== row.index));
    }
  };
  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Staff Treatment"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getStaffTreatments()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            colClassName="bordersides"
            headColClassName={classes.tableHeaderColumn}
            cols={[
              { data: "Provider", accessor: "providerName" },
              { data: "First Name", accessor: "firstName" },
              { data: "Last Name", accessor: "lastName" },
              { data: "Treatment Date", accessor: "treatmentDate" },
              { data: "Invoice Items", accessor: "invoiceItems" },
            ]}
            data={reportData}
            onRowClick={(row) => {
              if (row.children) {
                handleExpandRow(row);
              }
            }}
            expandedRows={expandedRows}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isTableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffTreatment;
