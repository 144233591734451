import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import classes from "./ProcedureTreatmentSummary.module.scss";

export function ProcedureTreatmentSummary({ injectionsList, info }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      {injectionsList.length > 0 || info.length > 0 ? (
        <>
          {injectionsList.map((injection, index) => (
            <div key={`${index}-${injection.price}`} className={classes.row}>
              <b>{injection.productName}</b> {injection.quantity}{" "}
              {injection.unit}
            </div>
          ))}
          {info.map((i) => (
            <div key={i.id} className={classes.row}>
              <b>{i.field}</b> {i.value} {i.unit}
            </div>
          ))}
        </>
      ) : (
        tCommon("symbol.longDash")
      )}
    </div>
  );
}

ProcedureTreatmentSummary.propTypes = {
  injectionsList: PropTypes.array.isRequired,
  info: PropTypes.array.isRequired,
};
