import { useMutation, useQuery } from "@tanstack/react-query";
import { uiNotification } from "../../services/UINotificationService";
import { QueryKeys } from "./QueryKeys";
import { http } from "../../services/HttpService";
import { handleApiErrorMessage } from "../../utilities/api";

const snoozeStickyNoteQuery = (payload) => {
  const { id, ...data } = payload;
  const url = `/sticky-note/snooze/${id}`;
  return http.post(url, data);
};

export const useSnoozeStickyNoteQuery = () => {
  return useMutation(snoozeStickyNoteQuery, {
    onSuccess: () => {
      uiNotification.success("Note snoozed");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const acknowledgeStickyNoteQuery = (id) => {
  const url = `/sticky-note/acknowledge/${id}`;
  return http.post(url);
};

export const useAcknowledgeStickyNoteQuery = () => {
  return useMutation(acknowledgeStickyNoteQuery, {
    onSuccess: () => {
      uiNotification.success("Note acknowledged");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

export const convertClientNote = (id) => {
  const url = `/client-note/convert/${id}`;
  return http.post(url);
};

export const useConvertClientNoteQuery = () => {
  return useMutation(convertClientNote, {
    onSuccess: () => {
      uiNotification.success("Restored note successfully");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const deleteStickyNote = (id) => {
  const url = `/client-notes/${id}`;
  return http.delete(url);
};

export const useDeleteStickyNoteQuery = () => {
  return useMutation(deleteStickyNote, {
    onSuccess: () => {
      uiNotification.success("Deleted note successfully");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const editStickyNote = (payload) => {
  const { id, ...data } = payload;
  const url = `/client-notes/${id}`;
  return http.patch(url, data);
};

export const useEditStickyNoteQuery = () => {
  return useMutation(editStickyNote, {
    onSuccess: () => {
      uiNotification.success("Updated note successfully");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const GetStickyNotesAcknowledgeHistory = (id) => {
  const url = `/sticky-note/acknowledge-history/${id}`;
  return http.get(url);
};

export const useGetStickyNotesAcknowledgeHistoryQuery = (id, noteType) => {
  return useQuery(
    [QueryKeys.GET_NOTES_ACKNOWLEDGED_HISTORY, id],
    () => GetStickyNotesAcknowledgeHistory(id),
    {
      enabled: !noteType,
      cacheTime: 0,
      onError: (error) => {
        uiNotification.error(handleApiErrorMessage(error));
      },
    },
  );
};

const GetClientNotesHistory = (id, noteType) => {
  const params = {
    note_type: "",
    sort_order: "DESC",
  };
  if (noteType === "client-note") {
    params["note_type"] = "customer_note";
  }
  if (noteType === "procedure-note") {
    params["note_type"] = "procedure_note";
  }
  const url = `/${noteType}/history/${id}`;
  return http.get(url, { params });
};

export const useGetGetClientNotesHistoryQuery = (id, noteType) => {
  return useQuery(
    [QueryKeys.GET_NOTES_HISTORY, id],
    () => GetClientNotesHistory(id, noteType),
    {
      enabled: Boolean(noteType),
      cacheTime: 0,
      onError: (error) => {
        uiNotification.error(handleApiErrorMessage(error));
      },
    },
  );
};
