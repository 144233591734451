import React from "react";
import styles from "../scss/addDocument.module.scss";

const StripeDocumentInput = ({ id, label, value, onChange }) => {
  return (
    <div className={styles.inputGroup}>
      <label htmlFor={id}>{label}</label>
      <input type="text" id={id} value={value} onChange={onChange} />
    </div>
  );
};

export default StripeDocumentInput;
