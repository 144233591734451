import { useEffect, useState } from "react";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";
import { extractToken, substitute } from "../utilities";
import { DEFAULT_MATCHED_TOKEN } from "../config";

/**
 * @param {{
 *    text: string;
 *    onTextChange: (nextText: string) => void;
 *    patterns: Pattern[];
 * }} param0
 * @returns {{
 *    token: ReturnType<typeof extractToken>;
 *    changeText: (nextText: string) => void;
 *    substitutePattern: (item: SubstituteItem) => void;
 * }}
 */
export function usePatternAutocomplete({ text, onTextChange, patterns }) {
  var [token, setToken] = useState(DEFAULT_MATCHED_TOKEN);
  var [caretPos, setCaretPos] = useState(-1);
  var [, debouncedValue, setDebouncedValue] = useDebouncedState("", 300);

  var reset = () => {
    setDebouncedValue("");
    setToken(DEFAULT_MATCHED_TOKEN);
    setCaretPos(-1);
  };

  var handleTextChange = (e) => {
    onTextChange(e.target.value);
    setDebouncedValue(e.target.value);
    setCaretPos(e.target.selectionStart);
  };

  var substitutePattern = (itemForSubstitution) => {
    if (token !== DEFAULT_MATCHED_TOKEN) {
      onTextChange(
        substitute(text, itemForSubstitution.value, token.start, token.end),
      );
      reset();
    }
  };

  useEffect(() => {
    const token = extractToken(debouncedValue, patterns, caretPos);

    if (token) {
      setToken(token);
    } else {
      setToken(DEFAULT_MATCHED_TOKEN);
    }
  }, [debouncedValue, patterns]);

  return {
    token,
    changeText: handleTextChange,
    substitutePattern,
    reset,
  };
}
