import React, { Component } from "react";
import { showFormattedDate } from '../../../Utils/services.js';
import { shortenClinicName } from "../../../helpers/general";

export default class TouchMDTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentData: props.appointmentData,
    };
  }

  render() {
    let serviceList = "";

    this.props.hobj.appointment_services.map((data) => {
      serviceList = serviceList
        ? serviceList + "," + data.service.name
        : data.service.name;
    });

    return (
      <div className="col-xs-12 cosmetic-timeline">
        <div className="row">
          <div className="col-sm-4 col-xs-12 m-b-15">
            <label className="popup-input-field-name">Date & Time</label>
            <p className="pro-time healthTimelineDetail m-b-0">
              {showFormattedDate(this.props.hobj.appointment_datetime, true)}
            </p>
          </div>
          <div className="col-sm-4 col-xs-12 m-b-15">
            <label className="popup-input-field-name">Provider</label>
            <span className="healthTimelineDetail m-b-0">
              {this.props.hobj.user ? this.props.hobj.user.full_name : ""}
            </span>
          </div>
          <div className="col-sm-4 col-xs-12 m-b-15">
            <label className="popup-input-field-name">Location</label>
            <span className="healthTimelineDetail m-b-0">
              {shortenClinicName(this.props.hobj.clinic.clinic_name)}
            </span>
          </div>
          <div className="col-sm-4 col-xs-12 m-b-15">
            <label className="popup-input-field-name">Service</label>
            <span className="healthTimelineDetail m-b-0">{serviceList}</span>
          </div>
          <div className="col-sm-4 col-xs-12 m-b-15">
            <label className="popup-input-field-name">Duration</label>
            <span className="healthTimelineDetail m-b-0">
              {this.props.hobj && this.props.hobj.duration
                ? this.props.hobj.duration
                : 0}{" "}
              mins
            </span>
          </div>
        </div>

        <div className="action-button-wrapper">
          <div className="action-button-inner">
            <div className="col-12 action-button-section">
              <h3 className="action-button-title">Paperwork</h3>
              <div className="action-button-ul">
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-xs-12">
                    {this.props.hobj.show_questionnaires_btn &&
                    this.props.hobj.show_questionnaires_btn == 1 ? (
                      <div
                        className="action-button-li"
                        onClick={() =>
                          this.props.viewQuestionnairesList(this.props.hobj.id)
                        }
                      >
                        Questionnaires
                        <i
                          className="fa fa-check pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div className="action-button-li">
                        Questionnaires
                        <i
                          className="fa fa-times pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-sm-6 col-xs-12">
                    {this.props.hobj.show_consents_btn &&
                    this.props.hobj.show_consents_btn == 1 ? (
                      <div
                        className="action-button-li"
                        onClick={() =>
                          this.props.viewConsents(this.props.hobj.id)
                        }
                      >
                        Consents
                        <i
                          className="fa fa-check pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div className="action-button-li">
                        Consents
                        <i
                          className="fa fa-times pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-sm-6 col-xs-12">
                    {this.props.hobj.pre_treatment_instruction_btn &&
                    this.props.hobj.pre_treatment_instruction_btn ==
                      1 ? null : (
                      <div className="action-button-li">
                        Pre Treatment Instructions
                        <i
                          className="fa fa-times pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-4 col-sm-6 col-xs-12">
                    {this.props.hobj.post_treatment_instruction_btn &&
                    this.props.hobj.post_treatment_instruction_btn ==
                      1 ? null : (
                      <div className="action-button-li">
                        Post Treatment Instructions
                        <i
                          className="fa fa-times pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
