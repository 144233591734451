const SignUpReducer = (state = { data: "", status: "" }, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
      };
    }
    case "SIGN_UP": {
      return {};
    }
    case "SIGN_UP_BASIC": {
      return {
        ...state,
        data: action.payload,
        action: "SIGN_UP_BASIC",
      };
    }
    case "GET_SIGNUP_ACCOUNT_STATUS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_SIGNUP_ACCOUNT_STATUS",
      };
    }
    case "SIGN_UP_PREMIUM": {
      return {
        ...state,
        data: action.payload,
        action: "SIGN_UP_PREMIUM",
      };
    }
    case "SIGN_UP_DIRECTORS": {
      return {
        ...state,
        data: action.payload,
        action: "SIGN_UP_DIRECTORS",
      };
    }
    case "UNIQUE_EMAIL": {
      return {
        ...state,
        data: action.payload,
        action: "UNIQUE_EMAIL",
      };
    }
    case "SIGN_UP_PLANS": {
      return {
        ...state,
        data: action.payload,
        action: "SIGN_UP_PLANS",
      };
    }
    case "SIGN_UP_VERIFY_ACCOUNT": {
      return {
        ...state,
        data: action.payload,
        action: "SIGN_UP_VERIFY_ACCOUNT",
      };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }

    case "CHECK_EMAIL_ON_SIGNUP": {
      return {
        ...state,
        data: action.payload,
        action: "CHECK_EMAIL_ON_SIGNUP",
      };
    }
    case "SIGN_UP_TOUCHMD": {
      return { ...state, data: action.payload, action: "SIGN_UP_TOUCHMD" };
    }
    case "SEARCH_PHONE_NUMBER_OAUTH": {
      return {
        ...state,
        data: action.payload,
        action: "SEARCH_PHONE_NUMBER_OAUTH",
      };
    }
    default: {
      return state;
    }
  }
};

export default SignUpReducer;
