import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Box } from "../../../../../shared/Box/Box";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../../shared/Input/Input";
import { InputError } from "../../../../../shared/InputError/InputError";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import classes from "../scss/quickbooks.module.scss";
import { useSaveQuickBooksCredential } from "../hooks/useSaveQuickBooksCredential";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

const QuickBooksForm = ({ credentials }) => {
  const { tSettings } = useAppTranslation.Settings();

  const validationSchema = yup.object({
    clientId: yup
      .string()
      .required(tSettings("quickBooksIntegration.form.error.clientIdRequired")),
    clientSecret: yup
      .string()
      .required(
        tSettings("quickBooksIntegration.form.error.clientSecretRequired"),
      ),
  });

  const { values, errors, isValid, setFieldValue, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      clientId: credentials.clientId || "",
      clientSecret: credentials.clientSecret || "",
    },
    onSubmit: () =>
      mutate({
        client_id: values.clientId,
        client_secret: values.clientSecret,
      }),
  });

  const { mutate, isLoading } = useSaveQuickBooksCredential({
    onSuccess: (data) => {
      window.location.href = data.data.data.authUrl;
    },
  });

  return (
    <Box className={classes.formBox}>
      <div>
        <InputLabel>
          {tSettings("quickBooksIntegration.form.clientId")}
        </InputLabel>
        <Input
          type="text"
          value={values.clientId}
          isError={errors.clientId}
          onChange={(event) => setFieldValue("clientId", event.target.value)}
        />
        {errors.clientId && <InputError>{errors.clientId}</InputError>}
      </div>
      <div>
        <InputLabel>
          {tSettings("quickBooksIntegration.form.clientSecret")}
        </InputLabel>
        <Input
          type="text"
          value={values.clientSecret}
          isError={errors.clientSecret}
          onChange={(event) =>
            setFieldValue("clientSecret", event.target.value)
          }
        />
        {errors.clientSecret && <InputError>{errors.clientSecret}</InputError>}
      </div>
      <div className={classes.formFooter}>
        <Button
          isDisabled={!isValid || isLoading}
          onClick={handleSubmit}
          leftAdornment={
            isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tSettings("quickBooksIntegration.form.connect")}
        </Button>
        {credentials?.refreshToken ? (
          <span>
            {tSettings("quickBooksIntegration.lastConnected")}{" "}
            {credentials?.updatedAt}
          </span>
        ) : (
          <span>{tSettings("quickBooksIntegration.unConnected")}</span>
        )}
      </div>
    </Box>
  );
};

export default QuickBooksForm;
