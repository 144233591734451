import React from "react";
import { useQuestionnaire } from "./hooks/useQuestionnaire";
import { Empty } from "../../../../shared/Empty/Empty";
import styles from "./styles.module.scss";
import { Footer } from "./shared/Footer/Footer";
import { Question } from "./boxes/Question/Question";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

/**
 * @template {ReturnType<usePatientQuestionnaire>["data"]} T
 * @param {{
 *    questionnaire: T;
 *    onSuccess: () => void;
 * }} param0
 */
export function Simple({ questionnaire, onSuccess }) {
  var { tCommon } = useAppTranslation.Common();

  var {
    questions,
    save,
    forceSave,
    choices,
    reasons,
    yesToAll,
    noToAll,
    isYesNoAvailable,
  } = useQuestionnaire({
    patientQuestionnaire: questionnaire,
    onSuccess,
  });

  return (
    <div>
      {isYesNoAvailable && (
        <div className={styles.top}>
          <Button size="small" onClick={yesToAll}>
            {tCommon("fillPatientQuestionnaire.yesToAll")}
          </Button>
          <Button size="small" onClick={noToAll}>
            {tCommon("fillPatientQuestionnaire.noToAll")}
          </Button>
        </div>
      )}
      {questions.length > 0 ? (
        <div className={styles.root}>
          {questions.map((question) => (
            <Question
              key={question.id}
              question={question}
              choices={choices.value[question.id] || []}
              reason={reasons.value[question.id] || ""}
              onChangeChoices={(next) => choices.update(question.id, next)}
              onChangeReason={(next) => reasons.update(question.id, next)}
            />
          ))}
          <Footer save={save} forceSave={forceSave} />
        </div>
      ) : (
        <Empty position="center" />
      )}
    </div>
  );
}
