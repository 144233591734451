import React from "react";
import ReactTooltip from "react-tooltip";
import { Toggle } from "../../../../../../../../shared/Toggle/Toggle";
import { useUpdateBannerStatus } from "../../../../hooks/useUpdateBannerStatus";
import { CircularProgress } from "../../../../../../../../shared/CircularProgress/CircularProgress";
import { TriangleAlertIcon } from "../../../../../../../../assets/Icons/TriangleAlertIcon";
import styles from "./styles.module.scss";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";

export function StatusToggle({ id, status, conflicts, setConflicts }) {
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();
  const updateStatus = useUpdateBannerStatus(setConflicts);

  return (
    <div className={styles.root}>
      <Toggle
        rtl
        isEnabled={!!status}
        isDisabled={updateStatus.isLoading(id)}
        onChange={() => {
          setConflicts(null);
          updateStatus.initiate(id, status);
        }}
      />
      {updateStatus.isLoading(id) ? (
        <CircularProgress size="tiny" />
      ) : (
        conflicts?.ids?.includes(id) && (
          <>
            <span data-tip data-for={"conflict" + id}>
              <TriangleAlertIcon width="17px" className={styles.icon} />
            </span>
            <ReactTooltip
              id={"conflict" + id}
              effect="solid"
              aria-haspopup="true"
            >
              {t("patientPortal.banner.conflictTooltip", {
                list: conflicts?.original
                  ?.filter((x) => x.id !== id)
                  ?.map((x) => x.name)
                  .join(", "),
              })}
            </ReactTooltip>
          </>
        )
      )}
    </div>
  );
}
