import React from "react";
import { PrivacyPolicyPopup } from "./PrivacyPolicyPopup";
import { IntroNotificationPopup } from "./IntroNotificationPopup";

export function GlobalPopups() {
  return (
    <>
      <PrivacyPolicyPopup />
      <IntroNotificationPopup />
    </>
  );
}
