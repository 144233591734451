import React from "react";

export const CancelMembershipIcon = () => {
  return (
    <svg width="21" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.95.07h8.847c3.46 0 5.776 2.087 5.776 5.192v7.168c0 3.097-2.316 5.183-5.776 5.183H5.95c-3.46 0-5.787-2.086-5.787-5.183V5.262C.163 2.157 2.49.07 5.95.07Zm7.49 11.403a.688.688 0 0 0 0-1.078l-1.816-1.562 1.816-1.562a.697.697 0 0 0 0-1.087c-.347-.299-.908-.299-1.265 0l-1.807 1.56-1.816-1.56c-.357-.299-.919-.299-1.266 0a.698.698 0 0 0 0 1.087l1.817 1.562-1.817 1.553c-.347.307-.347.79 0 1.087a.987.987 0 0 0 1.266 0l1.816-1.552 1.817 1.552a.912.912 0 0 0 .622.229c.235 0 .46-.08.633-.229Z"
        fill="#188BF6"
      />
    </svg>
  );
};
