import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Input } from "../../../../shared/Input/Input";
import { Button } from "../../../../shared/Button/Button";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { InputError } from "../../../../shared/InputError/InputError";
import classes from "../sass/SetPasswdForm.module.scss";

const schema = yup.object({
  password: yup
    .string()
    .min(5, "The password must be at least 5 characters")
    .required("Password is required"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required("Confirm password"),
});

export function SetPasswdForm({ set }) {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        passwordConfirm: "",
      },
      validationSchema: schema,
      enableReinitialize: true,
      onSubmit: ({ password }) => set.initiate({ password }),
    });

  const hasError = (field) => {
    return Boolean(touched[field] && errors[field]);
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.label}>New Password:</div>
        <Input
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={set.isLoading}
          isError={hasError("password")}
        />
        {hasError("password") && <InputError>{errors.password}</InputError>}
      </div>
      <div>
        <div className={classes.label}>Confirm Password:</div>
        <Input
          name="passwordConfirm"
          type="password"
          value={values.passwordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={set.isLoading}
          isError={hasError("passwordConfirm")}
        />
        {hasError("passwordConfirm") && (
          <InputError>{errors.passwordConfirm}</InputError>
        )}
      </div>
      <Button
        isDisabled={set.isLoading}
        onClick={handleSubmit}
        leftAdornment={
          set.isLoading && <CircularProgress color="white" size="small" />
        }
      >
        Send
      </Button>
    </div>
  );
}

SetPasswdForm.propTypes = {
  set: PropTypes.shape({
    initiate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};
