import moment from "moment";
import { stringify } from "query-string";
import { API_DATE_FORMAT } from "../../consts/api.js";
import { http } from "../../services/HttpService.js";

export function fetchLeads(
  page = 1,
  sort_by = "",
  sort_dir = "asc",
  filters = {},
  dateFrom = null,
  dateTo = null,
) {
  return (dispatch) => {
    dispatch({ type: "FETCH_LEADS_LOADING" });
    let opts = { page };

    if (sort_by) {
      opts.sort_by = sort_by;
      opts.sort_dir = sort_dir;
    }

    if (filters.opt_in.length) {
      opts.opt_in = filters.opt_in.join(",");
    }

    if (filters.clinics.length) {
      opts.clinics = filters.clinics.join(",");
    }

    if (filters.source.length) {
      opts.source = filters.source.join(",");
    }

    if (filters.letter_key.length) {
      opts.letter_key = filters.letter_key;
    }

    if (filters.term.length) {
      opts.term = filters.term;
    }

    if (dateFrom && dateTo) {
      opts.from_date = moment(dateFrom).format(API_DATE_FORMAT);
      opts.to_date = moment(dateTo).format(API_DATE_FORMAT);
    }

    const qs = stringify(opts, {});

    http
      .get(`leads/list?${qs}`)
      .then((response) => {
        const data = response.data.data;
        let result = {
          pagination: {
            current_page: data.current_page,
            last_page: data.last_page,
            is_first_page: data.current_page === 1,
            is_last_page: data.current_page === data.last_page,
            total: data.total,
          },
          leads: data.data,
          filters: data.filters,
        };
        dispatch({ type: "FETCH_LEADS_SUCCESS", payload: result });
      })
      .catch((e) => {
        dispatch({ type: "FETCH_LEADS_ERROR", payload: e.response.data });
      });
  };
}

export function deleteLead(entry_id) {
  return (dispatch) => {
    dispatch({ type: "DELETE_LEAD_LOADING" });
    http
      .post("leads/delete", { entry_id })
      .then((response) => {
        dispatch({
          type: "DELETE_LEAD_SUCCESS",
          payload: response.data.data.ids,
        });
      })
      .catch((e) => {
        dispatch({
          type: "DELETE_LEAD_ERROR",
          payload: e.response.data.message,
        });
      });
  };
}

export function bookConvertOrMessageLead(type, entry_id) {
  return (dispatch) => {
    dispatch({ type: "BOOK_CONVERT_OR_MESSAGE_LEAD_LOADING" });
    http
      .post(`leads/${type}`, { entry_id })
      .then((response) => {
        dispatch({
          type: "BOOK_CONVERT_OR_MESSAGE_LEAD_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "BOOK_CONVERT_OR_MESSAGE_LEAD_ERROR",
          payload: e.response.data.message,
        });
      });
  };
}

export function resetLeadCount() {
  return (dispatch) => {
    dispatch({ type: "RESET_LEADS_COUNT" });
  };
}
