import React, { useMemo } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import classes from "./sass/Workspaces.module.scss";
import { svg } from "../../assets/svg";
import { Workspace } from "./components/Workspace";
import {
  groupByNameFirstLetter,
  isWorkspaceInactive,
  sortGroupedAlphabetically,
} from "./Workspaces.utils";
import { WorkspacesBox } from "./components/WorkspacesBox";
import { Skeleton } from "../../shared/Skeleton/Skeleton";
import { useWorkspacesModifiers } from "./hooks/useWorkspacesModifiers";
import { Checkbox } from "../../shared/Checkbox/Checkbox";
import { LayoutGuest } from "../../boxes/LayoutGuest/LayoutGuest";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { useAutoLogout } from "./hooks/useAutoLogout";
import { useWorkspaces } from "./hooks/useWorkspaces";
import { Button } from "../../shared/Button/Button";
import { history } from "../../history";
import { InputSearch } from "../../boxes/InputSearch/InputSearch";
import { Empty } from "../../shared/Empty/Empty";
import { THEME } from "../../consts/theme";

export function Workspaces() {
  const { tCommon } = useAppTranslation.Common();
  const { tAuth } = useAppTranslation.Auth();
  const { workspaces, isWorkspacesLoading } = useWorkspaces();

  const groupedWorkspaces = useMemo(
    () => sortGroupedAlphabetically(groupByNameFirstLetter(workspaces)),
    [workspaces],
  );

  const { modifiedWorkspaces, search, inactiveVisible } =
    useWorkspacesModifiers(groupedWorkspaces);

  const isWorkspacesEmpty = Object.entries(modifiedWorkspaces).length === 0;
  const isNothingFound = !isWorkspacesLoading && isWorkspacesEmpty;
  const isWorkspacesVisible = !isWorkspacesEmpty && !isWorkspacesLoading;

  const composeWorkspaceNode = (workspace) => ({
    key: workspace.id,
    node: (
      <Workspace
        id={workspace.id}
        name={workspace.name}
        imageUrl={workspace.imageUrl}
        status={workspace.status}
      />
    ),
  });

  useAutoLogout();

  return (
    <LayoutGuest className={classes.layout}>
      <Button
        fontFamily="secondary"
        variant="text"
        size="small"
        onClick={history.goBack}
        className={classes.backBtn}
        leftAdornment={<img src={svg.chevronLeftPrimary} alt="<" width="7px" />}
      >
        {tCommon("label.back")}
      </Button>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          {tAuth("workspaces.all", {
            count: workspaces.length,
          })}
        </div>
        <div className={classes.headerRight}>
          {workspaces.some(isWorkspaceInactive) && (
            <Checkbox
              inverted
              size="small"
              label={tAuth("label.showInactiveWorkspaces")}
              isChecked={inactiveVisible.value}
              onChange={inactiveVisible.onToggle}
            />
          )}
          <InputSearch
            className={classes.search}
            value={search.value}
            onChange={search.onChange}
          />
        </div>
      </div>
      <div className={classes.content}>
        {isWorkspacesLoading && (
          <SkeletonTheme baseColor={THEME.colors.white}>
            <Skeleton
              count={3}
              height={131}
              borderRadius="8px"
              className={classes.skeleton}
            />
          </SkeletonTheme>
        )}
        {isNothingFound && <Empty position="center" size="big" color="gray2" />}
        {isWorkspacesVisible &&
          Object.entries(modifiedWorkspaces).map(([key, workspaces]) => (
            <WorkspacesBox
              key={key}
              header={<div className={classes.alphabetKey}>{key}</div>}
              workspaces={workspaces.map(composeWorkspaceNode)}
            />
          ))}
      </div>
    </LayoutGuest>
  );
}
