import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { EntityRow } from "../EntityRow";
import { Select } from "../../../../shared/Select/Select";

const AppointmentProvider = ({
  providerError,
  providerId,
  onChange,
  providers,
  isDisabled,
  isAdditionalProvider,
}) => {
  const value = useMemo(() => {
    const provider = providers.find((p) => p.id === providerId);
    if (provider) {
      return {
        label: provider.fullName,
        value: provider.id,
      };
    }
    return null;
  }, [providerId, providers]);

  return (
    <EntityRow label={isAdditionalProvider ? "Additonal Provider" : "Provider"}>
      <Select
        isSearchable
        isDisabled={isDisabled}
        isError={providerError}
        value={value}
        onChange={(option) => onChange(option.value)}
        placeholder={
          isAdditionalProvider ? "Select additonal provider" : "Select provider"
        }
        options={providers.map((p) => ({
          label: p.fullName,
          value: p.id,
        }))}
      />
    </EntityRow>
  );
};

AppointmentProvider.propTypes = {
  providerError: PropTypes.bool.isRequired,
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  providers: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  isAdditionalProvider: PropTypes.bool,
};

AppointmentProvider.defaultProps = {
  providerId: undefined,
  isDisabled: false,
  isAdditionalProvider: false,
};

export default AppointmentProvider;
