import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import cx from "clsx";
import classes from "./sass/Modal.module.scss";
import { svg } from "../../assets/svg";
import { Title } from "./components/Title";

export function Modal({
  isOpen,
  onClose,
  onAfterOpen,
  onAfterClose,
  shouldCloseOnOverlayClick,
  className,
  overlayClassName,
  withCloseBtn,
  children,
  header,
  footer,
  headerClassName,
  footerClassName,
  contentClassName,
  headerNoBorder,
  footerNoBorder,
  size,
}) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.rootSmall]: size === "small",
      [classes.rootDefault]: size === "default",
      [classes.rootLarge]: size === "large",
    },
    className,
  );

  const headerClasses = cx(
    classes.header,
    {
      [classes.headerNoBorder]: headerNoBorder,
    },
    headerClassName,
  );

  const contentClasses = cx(
    classes.content,
    {
      [classes.contentNoTopPadding]: headerNoBorder,
      [classes.contentNoBottomPadding]: footerNoBorder,
    },
    contentClassName,
  );

  const footerClasses = cx(
    classes.footer,
    {
      [classes.footerNoBorder]: footerNoBorder,
    },
    footerClassName,
  );

  useEffect(() => {
    const reset = () => (document.body.style.overflow = "unset");
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      reset();
    }
    return reset;
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        if (
          shouldCloseOnOverlayClick === undefined ||
          shouldCloseOnOverlayClick
        ) {
          onClose();
        }
      }}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      className={rootClasses}
      overlayClassName={cx(classes.overlay, overlayClassName)}
      ariaHideApp={false}
    >
      {withCloseBtn && (
        <button type="button" onClick={onClose} className={classes.closeBtn}>
          <img src={svg.close} alt="x" width="12px" />
        </button>
      )}
      {header && <div className={headerClasses}>{header}</div>}
      <div className={contentClasses}>{children}</div>
      {footer && <div className={footerClasses}>{footer}</div>}
    </ReactModal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAfterOpen: PropTypes.func,
  onAfterClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  withCloseBtn: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  headerNoBorder: PropTypes.bool,
  footerNoBorder: PropTypes.bool,
  size: PropTypes.oneOf(["small", "default", "large"]),
};

Modal.defaultProps = {
  onAfterOpen: () => {},
  onAfterClose: () => {},
  shouldCloseOnOverlayClick: undefined,
  className: undefined,
  overlayClassName: undefined,
  withCloseBtn: true,
  header: undefined,
  footer: undefined,
  headerClassName: undefined,
  footerClassName: undefined,
  contentClassName: undefined,
  headerNoBorder: false,
  footerNoBorder: false,
  size: "default",
};

Modal.Title = Title;
