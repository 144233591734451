import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./GlobalSearch.module.scss";
import { SearchIcon } from "../../assets/Icons/SearchIcon";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { InputSearch } from "../../boxes/InputSearch/InputSearch";
import { SearchResults } from "./boxes/SearchResults/SearchResults";
import { RecentSearches } from "./boxes/RecentSearches/RecentSearches";
import { globalSearch } from "../../store/globalSearch";
import { dispatch } from "../../store/store";
import { useGlobalSearchService } from "./hooks/useGlobalSearchService";
import { useDebouncedState } from "../../utilities/hooks/useDebouncedState";

function GlobalSearch({ isOpen }) {
  const { tCommon } = useAppTranslation.Common();
  const globalSearchService = useGlobalSearchService();

  const [searchValue, debouncedSearchValue, setSearchValue] = useDebouncedState(
    "",
    300,
  );

  const foundPatterns = useMemo(() => {
    return globalSearchService.findPatterns(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const onOpen = () => {
    dispatch(globalSearch.actions.searchOpenChange(true));
  };

  const onClose = () => {
    dispatch(globalSearch.actions.searchOpenChange(false));
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchValue("");
    }
  }, [isOpen]);

  return (
    <div>
      <button type="button" onClick={onOpen} className={classes.button}>
        <SearchIcon />
      </button>
      <Modal
        headerNoBorder
        header={<Modal.Title>{tCommon("globalSearch.label")}</Modal.Title>}
        isOpen={isOpen}
        onClose={onClose}
        contentClassName={classes.content}
      >
        <InputSearch
          autoFocus
          autoComplete="off"
          size="default"
          value={searchValue}
          onChange={setSearchValue}
        />
        {searchValue ? (
          <SearchResults foundPatterns={foundPatterns} />
        ) : (
          <RecentSearches />
        )}
      </Modal>
    </div>
  );
}

GlobalSearch.propTypes = {
  isOpen: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isOpen: globalSearch.selectors.selectIsOpen(state),
});

export default connect(mapStateToProps)(GlobalSearch);
