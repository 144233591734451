import React from "react";
import cx from "clsx";
import rootClasses from "../Column.module.scss";
import classes from "./ColumnAccessor.module.scss";

/**
 * @param {Object} param0
 * @param {boolean?} param0.stretch
 * @param {string?} param0.className
 * @param {Array<{
 *  label: React.ReactNode;
 *  value: string;
 * }>} param0.accessors
 * @param {boolean?} param0.accentFirstCell
 * @param {string?} param0.cellClassName
 */
export const ColumnAccessor = ({
  stretch,
  className,
  accessors,
  accentFirstCell,
  cellClassName,
}) => (
  <div
    className={cx(
      rootClasses.col,
      classes.root,
      {
        [rootClasses.stretch]: stretch,
      },
      className,
    )}
  >
    {accessors.map(({ label, value }, index) => (
      <h4
        key={value}
        className={cx(
          rootClasses.cell,
          classes.cell,
          {
            [classes.firstCellAccent]: accentFirstCell && index === 0,
          },
          cellClassName,
        )}
      >
        {label}
      </h4>
    ))}
  </div>
);
