import React from "react";
import PropTypes from "prop-types";
import classes from "./Labeled.module.scss";

export function Labeled({ label, children, divider }) {
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      {divider && <div className={classes.divider} />}
      {children}
    </div>
  );
}

Labeled.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
