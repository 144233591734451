import React, { useState, useEffect } from "react";
import _ from "lodash/fp";
import { useForm, Controller } from "react-hook-form";
import { Input, Select, CheckBox } from '../../../Common/ReactHookForm'
import StepRangeSlider from 'react-step-range-slider'
import ReactDatePicker from "react-datepicker";
import Cleave from "cleave.js/dist/cleave-react";
import 'cleave.js/dist/addons/cleave-phone.us';



const FormStep5Each = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const { handleFormStepChange, formValue, setContactIndex, setIsNewAddress, handleAddressSave, handleAddressDelete, isDeleteAddress, resources } = props;
  const { register, handleSubmit, watch, errors, control, getValues, setValue, trigger } = useForm({
    defaultValues: formValue,
    mode: "onChanges",
    reValidateMode: "onChange"
  });

  
  const [contactOwnerShip, setContactOwnerShip] = useState((formValue && formValue.ownershipAmount && parseInt(formValue.ownershipAmount) !== 'NaN') ? formValue.ownershipAmount : 100)

  const [contactTypes, setContactTypes] = useState([])
  useEffect(() => {
    if(!formValue.id) {
      setContactTypes([{contactTypeID: 1}, {contactTypeID: 2}])
      setValue('contactType-1', true);
      setValue('contactType-2', true);
    } else if(formValue.id && formValue['contactType-2']) {
      setContactTypes([{contactTypeID: 1}, {contactTypeID: 2}])
      setValue('contactType-1', true);
      setValue('contactType-2', true);
    } else {
      setContactTypes([{contactTypeID: 1}])
      setValue('contactType-1', true);
    }
  }, [])
  useEffect(() => {
    if(watch("ownershipAmount") != '' &&  !isNaN(parseInt(watch("ownershipAmount")))) {
      setContactOwnerShip(parseInt(watch("ownershipAmount")))
      setValue('ownershipAmount', parseInt(watch("ownershipAmount")))
      setValue('contactOwnerShip', parseInt(watch("ownershipAmount")))      
    }
  }, [watch("ownershipAmount")]);

  
  useEffect(() => {
    if(watch("ownershipAmount") != '' &&  !Number.isInteger(watch("ownershipAmount"))) {
      setContactOwnerShip(parseInt(watch("ownershipAmount")))
      setValue('ownershipAmount', parseInt(watch("contactOwnerShip")))
    }
  }, [watch("contactOwnerShip")]);

  const range = [
    { value: 0, step: 1 }, // acts as min value
    { value: 100 } // acts as max value
  ]

  const maxAge = new Date();
  maxAge.setFullYear(maxAge.getFullYear() - 13);

  const onSubmit = data => {
    data.phoneNumbers = []
    if(data.contact_phone) {
      let phoneNumberArr = data.contact_phone.split(' ');
      let areaCode = ''
      let phoneNumber = ''
      if(phoneNumberArr.length > 1) {
        areaCode = phoneNumberArr[0];
        phoneNumber = phoneNumberArr[1] + phoneNumberArr[2];
      } else {
        areaCode = data.contact_phone.substring(0,3);
        phoneNumber = data.contact_phone.substring(3);
      }
      data.phoneNumbers.push({phoneTypeCodeID: watch('phoneTypeCodeID'), areaCode: areaCode, phoneNumber: phoneNumber, extension:""})
    }
    if(contactTypes.length) {
      data.contactTypes = contactTypes;
    }
    if(formValue.id) {
      data.id = formValue.id;
    }
    handleAddressSave(data)

    //handleFormStepChange(5, data)
    //alert(JSON.stringify(data));
  }; // your form submit function which will invoke after successful validation

  useEffect(() => {
    let types = contactTypes;
    if(watch('contactType-1')) {
      let index = types.findIndex(e => e.contactTypeID == 1);
      if(index == -1) {
        types.push({contactTypeID: 1})
      }
    }
    setValue('contactType-1', true);
    setContactTypes(types);
  },[watch('contactType-1')])

  useEffect(() => {
    let types = contactTypes;
    if(watch('contactType-2')) {
      let index = types.findIndex(e => e.contactTypeID == 2);
      if(index == -1) {
        types.push({contactTypeID: 2})
      }
    } else {
      let index = types.findIndex(e => e.contactTypeID == 2);
      setValue('ownershipAmount', 0)
      types.splice(index,1);
    }
    setContactTypes(types);
  },[watch('contactType-2')])

  useEffect(() => {
    let types = contactTypes;
    if(watch('contactType-3')) {
      let index = types.findIndex(e => e.contactTypeID == 3);
      if(index == -1) {
        types.push({contactTypeID: 3})
      }
    } else {
      let index = types.findIndex(e => e.contactTypeID == 3);
      if(index > -1) {
        delete types[index];
      }
    }
    setContactTypes(types);
  },[watch('contactType-3')])

  const getStateOptions = (options) => {
    if(options && options.length > 0) {
      let finalOpt = options.map((obj, idx) => {
        return {value: obj.stateCode, label: obj.stateName}
      })
      return finalOpt;
    }
  }
  const getCountryOptions = (options) => {
    if(options && options.length > 0) {
      let finalOpt = options.map((obj, idx) => {
        return {value: obj.countryCode, label: obj.countryName}
      })
      return finalOpt;
    }
  }

  const handleDateChange = (date) => {
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`form-step-each`}>
        <div className="row">
          <div className="col-sm-6 col-xs-12 m-b-15">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">First Name<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.firstName) ? 'field_error' : ''}`}
                placeholder="First Name"
                name='firstName'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="col-sm-6 col-xs-12 m-b-15">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Last Name<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.lastName) ? 'field_error' : ''}`}
                placeholder="Last Name"
                name='lastName'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="p-text m-b-10">Choose what type of contact you're adding (select all that apply):</div>

        <div className="row no-margin m-b-20 mechantCheckboxOuter">
        {resources.ContactTypes && resources.ContactTypes.map((obj, idx) => {
          if(obj.contactTypeID > 2) {
            return false;
          }
          return (
                <div className="basic-checkbox-outer">
                  <CheckBox
                    className={`customRadio green ${(errors && errors.contactTypeID) ? 'field_error' : ''}`}
                    name={"contactType-"+obj.contactTypeID}
                    register={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                    value={obj.contactTypeID}
                  />
                  <label className="m-t-5" htmlFor={"chk-contactType-"+obj.contactTypeID}>{(obj.contactTypeDescription == "Signer") ? "Controlling Officer" : obj.contactTypeDescription}</label>
                </div>
            )
        })}
        </div>

        <div className="p-text m-b-10">Please provide Email and/or Phone Number:</div>

        <div className="row m-b-10">
          <div className="col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Email Address<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.emailAddress) ? 'field_error' : ''}`}
                placeholder="Email Address"
                name='emailAddress'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="col-sm-3 col-xs-12">
            <div className="newInputFileldOuter businessNote">
              <div className="newInputLabel">Phone Type<span className="setting-require">*</span></div>
              <Select
                className={`newSelectField ${(errors && errors.phoneTypeCodeID) ? 'field_error' : ''}`}
                name='phoneTypeCodeID'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
                options = {[{value:1, label: 'Mobile'}, {value: 5, label: 'Office'}]}
              />
            </div>
          </div>
          <div className="col-sm-3 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Phone Number<span className="setting-require">*</span>
              </div>
              <Controller
                name="contact_phone"
                className={`newInputField ${(errors && errors.contact_phone) ? 'field_error' : ''}`}
                control={control}
                errors={errors}
                as={
                  <Cleave
                    placeholder="555 222 0000"
                    options={ {phone: true, phoneRegionCode: 'US', numericOnly: true, maxLength: 1}}
                  />
                }
                rules={{ 
                    required: true, 
                    validate: value => value.split(' ').join('').length == 10  
                  }}
              />
            </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Date of Birth<span className="setting-require">*</span></div>
              <Controller
                control={control}
                name="dateOfBirth"
                isRequired={true}
                errors={errors}
                control={control}
                render={(props) => {
                  return (
                            <ReactDatePicker
                              className={`newInputField ${(errors && errors.dateOfBirth) ? 'field_error' : ''}`}
                              placeholderText="Select date"
                              onChange={(e) => props.onChange(e)}
                              selected={props.value}
                              maxDate={maxAge}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                    )}}
              />
            {/*<div className="newInputFileldOuter">
                          
                          <Input
                            className={`newInputField ${(errors && errors.dateOfBirth) ? 'field_error' : ''}`}
                            placeholder="Date of Birth"
                            name='dateOfBirth'
                            register={register}
                            isRequired={true}
                            errors={errors}
                            control={control}
                          />
                        </div>*/}
          </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">SSN<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.ssn) ? 'field_error' : ''}`}
                placeholder="SSN"
                name='ssn'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Home Address<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.line1) ? 'field_error' : ''}`}
                placeholder="Home Address"
                name='line1'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
                maxLength={60}
              />
            </div>
          </div>
          <div className="col-sm-2 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">City<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.city) ? 'field_error' : ''}`}
                placeholder="City"
                name='city'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="col-sm-2 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">State<span className="setting-require">*</span>
              </div>
              <Select
                  className={`newSelectField ${(errors && errors.stateCode) ? 'field_error' : ''}`}
                  name='stateCode'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  options = {getStateOptions(resources.StateOptions)}
                />
            </div>
          </div>
          <div className="col-sm-2 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Zip Code<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.zip) ? 'field_error' : ''}`}
                placeholder="Zip Code"
                name='zip'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="m-b-20 customRadioOuter">
          <React.Fragment>
            <input type="radio"
              
              className={`customRadio green`}
              name={'isAdmin'}
              value={1}
              id={'isAdmin_1'}
              ref={register}
              isRequired={true}
              errors={errors}
              control={control}
            />
            <input type="radio"
              
              className={`customRadio red`}
              name={'isAdmin'}
              value={2}
              id={'isAdmin_2'}
              ref={register}
              isRequired={true}
              errors={errors}
              control={control}
            />
            <label className="m-t-5">{"Is this user an AR Admin?"}</label>
          </React.Fragment>
          </div>

        <div className="row m-b-20">
          <div className="col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Title<span className="setting-require">*</span>
              </div>
              <Input
                className={`newInputField ${(errors && errors.title) ? 'field_error' : ''}`}
                placeholder="Title"
                name='title'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Country of Citizenship<span className="setting-require">*</span>
              </div>
              <Select
                className={`newSelectField ${(errors && errors.countryOfCitizenshipCode) ? 'field_error' : ''}`}
                name='countryOfCitizenshipCode'
                register={register}
                isRequired={true}
                errors={errors}
                control={control}
                options = {getCountryOptions(resources.CountryOptions)}
              />
            </div>
          </div>
        </div>

        <div className={(watch('contactType-2')) ? "row m-b-30 m-t-20" : "no-display"}>
          <div className="col-sm-2 col-xs-12 cardPresent m-b-20 p-r-0">
            <h3 className="m-t-5">Ownership %</h3>
          </div>
          <div className="col-sm-6 col-xs-12 m-b-20">
            <Controller
              name="contactOwnerShip"
              control={control}
              defaultValue={contactOwnerShip}
              onChange={(value) => {
              }}
              as={
                <StepRangeSlider
                  value={contactOwnerShip}
                  range={range}
                  onChange={(value) => {
                  }}
                  className="card-progress-outer"
                  children={<span>{contactOwnerShip}%</span>}
                  valueLabelDisplay="auto"
                />
              }
            />
          </div>
          <div className="col-sm-1 col-xs-12 text-center p-t-5 m-b-20">
            <span>or</span>
          </div>
          <div className="col-sm-3 col-xs-12">
            <div className="newInputFileldOuter ownershipField">
              <div className="newInputLabel">Ownership %<span className="setting-require">*</span>
              </div>
              <input
                className={`newInputField ${(errors && errors.ownershipAmount) ? 'field_error' : ''}`}
                placeholder="Ownership %"
                name='ownershipAmount'
                type="text"
                ref={register({
                    validate: {lessThanHundred: value => parseFloat(value) <= 100},
                    maxLength: 3,
                    required: true
                })}
                errors={errors}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="row m-l-20"><p>Please add any additional contact with 25% or more ownership of the business </p></div>

        <div className="row">
          <div className="col-xs-12 m-t-20">
            <button
              className="new-blue-btn pull-right m-b-20"
              type="submit"
              onClick={async () => {
                setIsNewAddress(false)
              }}
            >Save</button>
            <button
              className="new-line-btn pull-right"
              type="type"
              onClick={async () => {
                setIsNewAddress(true)
              }}>Save & Add Another Contact</button>
            {/*(isDeleteAddress) &&
              <button
                className="new-line-btn pull-right m-b-20"
                type="submit"
                onClick={async () => {
                  handleAddressDelete(setContactIndex)
                }}
              >Delete</button>
            */}
          </div>
        </div>
      </div>

    </form>

  );
}
export default FormStep5Each;
