import { useQueryClient } from "@tanstack/react-query";
import { useUpdateServicesObOrderMutation } from "../../../../../../api/mutations/useUpdateServicesObOrderMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { QUERY_KEYS } from "../../../../../../consts/api";

export function useSaveOrder() {
  const queryClient = useQueryClient();
  const { tCommon } = useAppTranslation.Common();

  const { isLoading, mutate } = useUpdateServicesObOrderMutation({
    onSuccess: () => {
      uiNotification.success(tCommon("success.reorder"));
      queryClient.invalidateQueries([QUERY_KEYS.obOrderedServices]);
    },
    onError: () => {
      uiNotification.error(tCommon("error.reorder"));
    },
  });

  const save = ({ selectedCategory, orderedServices }) => {
    mutate({
      categoryId: selectedCategory.value,
      serviceIds: orderedServices.map((p) => p.id),
    });
  };

  return {
    save,
    isSaving: isLoading,
  };
}
