import React from "react";
import { Modal } from "../../../../shared/Modal/Modal";
import classes from "./Header.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

export function Header() {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      <Modal.Title>{tCommon("fillPatientQuestionnaire.title")}</Modal.Title>
    </div>
  );
}
