import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./Images90.module.scss";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { ImageUploader } from "../../../../../../../boxes/ImageUploader/ImageUploader";
import { IMAGE_UPLOAD_TYPE } from "../../CosmeticCreateEdit.consts";
import { useDefaultImage } from "../../hooks/useDefaultImage";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { PROCEDURE_AREA } from "../Info/Info.consts";
import { useImageUploader } from "../../../../../../../hooks/useImageUploader";

export function Images90({
  procedureImage,
  leftImage,
  rightImage,
  isLoading,
  procedureArea,
}) {
  const { tClients } = useAppTranslation.Clients();
  const defaultImage = useDefaultImage();

  const uploader = useImageUploader({
    distinguishByName: true,
    uploadType: IMAGE_UPLOAD_TYPE,
  });

  return (
    <div>
      <div className={classes.title}>
        {tClients("createEditCosmeticProcedure.images.90degrees")}
      </div>
      {isLoading ? (
        <div className={classes.images}>
          {Array.from(Array(3).keys()).map((i) => (
            <Skeleton
              key={i}
              count={1}
              height={137}
              width="122px"
              borderRadius="4px"
            />
          ))}
        </div>
      ) : (
        <div className={classes.images}>
          {procedureArea !== PROCEDURE_AREA.coolSculpting && (
            <div>
              <InputLabel>
                {tClients("createEditCosmeticProcedure.images.procedure")}
              </InputLabel>
              <ImageUploader
                name="afterProcedureImage90"
                value={procedureImage.value}
                isLoading={
                  uploader.isLoading &&
                  uploader.uploadingName === "afterProcedureImage90"
                }
                onChange={(e) =>
                  uploader
                    .htmlOnChange(e)
                    .then(({ uploadedFileFullPath }) =>
                      procedureImage.onChange(uploadedFileFullPath),
                    )
                }
                onRemove={() => procedureImage.onChange(null)}
                defaultImage={defaultImage}
              />
            </div>
          )}
          <div>
            <InputLabel>
              {tClients("createEditCosmeticProcedure.images.left")}
            </InputLabel>
            <ImageUploader
              name="afterLeftImage90"
              value={leftImage.value}
              isLoading={
                uploader.isLoading &&
                uploader.uploadingName === "afterLeftImage90"
              }
              onChange={(e) =>
                uploader
                  .htmlOnChange(e)
                  .then(({ uploadedFileFullPath }) =>
                    leftImage.onChange(uploadedFileFullPath),
                  )
              }
              onRemove={() => leftImage.onChange(null)}
              defaultImage={defaultImage}
            />
          </div>
          <div>
            <InputLabel>
              {tClients("createEditCosmeticProcedure.images.right")}
            </InputLabel>
            <ImageUploader
              name="afterRightImage90"
              value={rightImage.value}
              isLoading={
                uploader.isLoading &&
                uploader.uploadingName === "afterRightImage90"
              }
              onChange={(e) =>
                uploader
                  .htmlOnChange(e)
                  .then(({ uploadedFileFullPath }) =>
                    rightImage.onChange(uploadedFileFullPath),
                  )
              }
              onRemove={() => rightImage.onChange(null)}
              defaultImage={defaultImage}
            />
          </div>
        </div>
      )}
    </div>
  );
}

Images90.propTypes = {
  procedureImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  leftImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  rightImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  procedureArea: PropTypes.string,
};
