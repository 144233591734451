import React from "react";
import { useFormik } from "formik";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../../widgets/EntityHeader/EntityHeader";
import styles from "./styles.module.scss";
import { DateRangePicker } from "../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { Button } from "../../../../../shared/Button/Button";
import {
  apiDateStringToDate,
  dateToApiDateString,
} from "../../../../../utilities/api";
import { unwrapOr } from "../../../../../utilities/general";
import { PERMISSIONS } from "../../../../../consts/api";
import { usePermission } from "../../../../../hooks/usePermission";
import { Select } from "../../../../../shared/Select/Select";
import { useSubmit } from "../ModalAdd/hooks/useSubmit";

/**
 * @param {object} param0
 * @param {{
 *  value: {
 *    from: string;
 *    to: string;
 *  };
 *  update: (value: {
 *    from: string;
 *    to: string;
 *  }) => void;
 * }} param0.period
 *
 * @param {{
 *  value: string;
 *  update: (v: string) => void;
 * }} param0.search
 *
 * @param {() => void} param0.onAdd
 */

const initialValues = {
  direction: "",
  isPatientAssigned: "",
};

export const Header = ({ period, search, onAdd, filters }) => {
  const { tInbox } = useAppTranslation.Inbox();
  const { data: user } = useCurrentUserQuery();
  const { isLoading } = useSubmit();

  const sendEfaxUserPermission = usePermission(
    PERMISSIONS.patientManagement.sendEfaxOrders,
  );

  const hasSendEfaxUserPermission =
    (sendEfaxUserPermission.loaded && sendEfaxUserPermission.permitted) ||
    user?.isMainAdmin;

  const directionOptions = [
    { value: "", label: "All directions" },
    { value: "incoming", label: "Incoming" },
    { value: "outgoing", label: "Outgoing" },
  ];

  const assignOptions = [
    { value: "", label: "All assignments" },
    { value: 1, label: "Assigned" },
    { value: 0, label: "Unassigned" },
  ];

  const { values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...filters.value,
    },
  });

  return (
    <EntityHeader
      title={tInbox("efaxOrders.title")}
      searchValue={search.value}
      onChangeSearch={search.update}
      className={styles.root}
      searchPlaceholder={tInbox("efaxOrders.search")}
      left={
        <div className={styles.left}>
          <Select
            size="small"
            placeholder={tInbox("efaxOrders.direction")}
            className={styles.filtersDirection}
            value={values.direction}
            onChange={(options) =>
              filters.update({ ...filters.value, direction: options })
            }
            options={directionOptions}
            isLoading={isLoading}
          />
          <Select
            size="small"
            placeholder={tInbox("efaxOrders.assign")}
            className={styles.filtersAssignment}
            value={values.isPatientAssigned}
            onChange={(options) =>
              filters.update({ ...filters.value, isPatientAssigned: options })
            }
            options={assignOptions}
            isLoading={isLoading}
          />
        </div>
      }
      right={
        <div className={styles.right}>
          <DateRangePicker
            isDisabled={false}
            size="small"
            dateFormat={user?.dateFormat}
            onChange={({ startDate, endDate }) => {
              period.update({
                from: dateToApiDateString(startDate),
                to: dateToApiDateString(endDate),
              });
            }}
            value={{
              startDate: unwrapOr(
                () => apiDateStringToDate(period.value.from),
                new Date(),
              ),
              endDate: unwrapOr(
                () => apiDateStringToDate(period.value.to),
                new Date(),
              ),
            }}
          />
          {hasSendEfaxUserPermission && (
            <Button size="small" variant="outlined" onClick={onAdd}>
              {tInbox("efaxOrders.add")}
            </Button>
          )}
        </div>
      }
    />
  );
};
