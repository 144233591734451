import { CLIENT_GLOBAL_NAME } from "../../consts/api";
import { objectUtil } from "../../utilities/object";

var keysMap = {
  [CLIENT_GLOBAL_NAME.client]: "client",
  [CLIENT_GLOBAL_NAME.patient]: "patient",
};

var isMatchedObject = (translationObject) => {
  return (
    objectUtil.isObject(translationObject) &&
    "patient" in translationObject &&
    "client" in translationObject
  );
};

var updatePath = ({ path, i18n, ns, alias }) => {
  const lang = i18n.language;
  const resources = i18n.getDataByLanguage(lang)?.[ns] || {};
  const translationObj = objectUtil.get(resources, path);

  if (isMatchedObject(translationObj)) {
    return `${path}.${keysMap[alias]}`;
  }

  return path;
};

export var clientAliasEnhancer = { updatePath };
