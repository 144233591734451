import React, { useState } from "react";
import clsx from "clsx";
import { PROVIDER_ROOM_PROCEDURE_VERSION } from "../../../api/queries/useProviderRoomProceduresQuery";
import { useProcedures } from "../hooks/useProcedures";
import { Layout } from "../components/Layout/Layout";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { useTableCosmetic } from "./hooks/useTableCosmetic";
import parentClasses from "../sass/ProviderRoom.module.scss";
import { Button } from "../../../shared/Button/Button";
import { Table } from "../../../shared/Table/Table";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useProceduresShow } from "../hooks/useProceduresShow";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { useApiClinics } from "../../Event/hooks/useApiClinics";
import ReactSelect from "../../../Components/Common/ReactSelect";

export function TabHiddenProcedures({ history }) {
  const { tCommon } = useAppTranslation.Common();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { showProcedures, isShowProceduresLoading } = useProceduresShow();
  const historyData = history?.location?.state;
  const chartAuditUserId = historyData?.chartUserId?.toString();

  const {
    procedures,
    fetchNextProcedures,
    proceduresStatus,
    proceduresSearchTerm,
    selectClinic,
  } = useProcedures({
    version: PROVIDER_ROOM_PROCEDURE_VERSION.hidden,
    clinicID: historyData?.clinic_id,
    providerUserId: chartAuditUserId,
  });
  const { cols, data, selected, resetSelected } = useTableCosmetic({
    procedures,
    showHideActionButton: historyData?.isFromChartAuditReport,
    providerUserId: chartAuditUserId,
  });
  const { clinics: availableClinicsList } = useApiClinics();

  const handleShow = () => {
    setIsConfirmModalOpen(false);
    showProcedures({ procedureIds: selected }).then(resetSelected);
  };

  const handleClinicChange = ({ id }) => {
    selectClinic.onChange(id.toString());
  };

  const classNames = clsx("col-12", "col-sm-5", "col-md-4", "col-lg-3", {
    "p-r-0": historyData?.isFromChartAuditReport,
  });

  return (
    <Layout
      noTabs
      search={proceduresSearchTerm}
      status={proceduresStatus}
      loadMore={fetchNextProcedures}
      topRight={
        <>
          <div className="d-flex col-lg-6  w-0 ms-4">&nbsp;</div>

          <div className={classNames}>
            <ReactSelect
              name={"id"}
              selectedId={selectClinic.value}
              list={[
                { name: "All Clinics", id: "" },
                ...availableClinicsList,
              ].map((i) => ({
                value: i.id,
                label: i.name,
              }))}
              value={"value"}
              label={"label"}
              handleReactSelect={handleClinicChange}
              isValid={true}
              placeholder={"Select Clinic"}
              isMulti={false}
            />
          </div>
          {!historyData?.isFromChartAuditReport && (
            <Button
              size="small"
              onClick={() => setIsConfirmModalOpen(true)}
              isDisabled={selected.length === 0 || isShowProceduresLoading}
              leftAdornment={
                isShowProceduresLoading ? (
                  <CircularProgress size="tiny" color="white" />
                ) : undefined
              }
            >
              {tCommon("label.show")} ({selected.length})
            </Button>
          )}
        </>
      }
    >
      {proceduresStatus.isVisible && (
        <Table
          bgColor="white"
          cols={cols}
          data={data}
          headClassName={parentClasses.tableHead}
        />
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleShow}
      >
        {tCommon("providerRoom.confirm.show")}
      </ConfirmModal>
    </Layout>
  );
}
