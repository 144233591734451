import React from "react";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import NoTipsInfo from "../../ViewTipsModal/components/NoTipsInfo";

const PaymentTipsModal = ({ maxAmountToPay }) => {
  return (
    <>
      <ViewTipsModal amountToPay={maxAmountToPay} />
      <NoTipsInfo method="wallet" />
    </>
  );
};

export default PaymentTipsModal;
