import React, { useState } from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Section } from "../../shared/Section/Section";
import { ButtonAdd } from "./shared/ButtonAdd/ButtonAdd";
import classes from "./BmiRanges.module.scss";
import { Node } from "./shared/Node/Node";
import { arrayFrom } from "../../../../../../../utilities/general";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { Form } from "./shared/Form/Form";
import { useSettingRanges } from "../../../../hooks/useSettingRanges";

export const BmiRanges = () => {
  const { tClients } = useAppTranslation.Clients();
  const [formPreloadedState, setFormPreloadedState] = useState(null);
  const ranges = useSettingRanges();

  return (
    <Section
      contentClassName={classes.root}
      label={tClients("vitals.settings.bmiRanges")}
    >
      {Boolean(formPreloadedState) && (
        <Form
          key={formPreloadedState.id || 0}
          preloadedState={formPreloadedState}
          reset={() => setFormPreloadedState(null)}
        />
      )}
      <div className={classes.ranges}>
        {ranges.isLoading ? (
          arrayFrom(5).map((k) => (
            <Skeleton key={k} height={32} borderRadius="4px" />
          ))
        ) : (
          <>
            {ranges.data?.map((n) => (
              <Node
                key={n.id}
                id={n.id}
                name={n.name}
                min={n.start_value}
                max={n.end_value}
                color={n.color}
                onEdit={() => {
                  setFormPreloadedState({
                    id: n.id,
                    name: n.name,
                    min: n.start_value,
                    max: n.end_value,
                    color: n.color,
                  });
                }}
              />
            ))}
            <ButtonAdd onClick={() => setFormPreloadedState({})} />
          </>
        )}
      </div>
    </Section>
  );
};
