import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Skeleton as SharedSkeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { THEME } from "../../../../../../consts/theme";
import classes from "../sass/Skeleton.module.scss";

export function Skeleton() {
  return (
    <SkeletonTheme baseColor={THEME.colors.white}>
      <div className={classes.root}>
        <SharedSkeleton count={1} height={66} />
        <SharedSkeleton count={1} height={340} />
      </div>
    </SkeletonTheme>
  );
}
