import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import classes from "./PatientConsentPreview.module.scss";
import { Divider } from "../../shared/Divider/Divider";
import { Header } from "./shared/Header/Header";
import { Footer } from "./shared/Footer/Footer";
import { useCurrentUserQuery } from "../../api/queries/useUserQuery";
import { PREVIEW_TIME_FORMAT } from "../../consts/general";

export function PatientConsentPreview({ patient, consent, noDivider }) {
  const { data: user } = useCurrentUserQuery();

  const formatDate = (date) => {
    if (date) {
      if (user?.previewDateFormat && user?.timeFormat) {
        return moment(date).format(
          `${user?.previewDateFormat} ${user?.timeFormat}`,
        );
      }

      return moment(consent.witnessSignedOn).format(PREVIEW_TIME_FORMAT);
    }

    return "";
  };

  return (
    <div className={classes.root}>
      <Header patientId={patient.id} />
      {!noDivider && <Divider />}
      <h3>{consent.name}</h3>
      <div
        className={classes.description}
        dangerouslySetInnerHTML={{
          __html: consent.description,
        }}
      />
      <Footer
        witness={{
          name: consent.witnessName,
          signatureUrl: consent.witnessSignatureUrl,
          date: formatDate(consent.witnessSignedOn),
        }}
        patient={{
          id: patient.id,
          name: consent.patientName,
          signatureUrl: consent.patientSignatureUrl,
          date: formatDate(consent.patientSignedOn),
        }}
      />
    </div>
  );
}

PatientConsentPreview.propTypes = {
  consent: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    witnessSignatureUrl: PropTypes.string,
    witnessName: PropTypes.string,
    patientSignatureUrl: PropTypes.string,
    patientName: PropTypes.string,
    patientSignedOn: PropTypes.string,
    witnessSignedOn: PropTypes.string,
  }).isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  noDivider: PropTypes.bool,
};
