import React from "react";

export const CalendarCrossIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6355 3.47668H3.36449C2.43541 3.47668 1.68224 4.22984 1.68224 5.15892V20.6356C1.68224 21.5646 2.43541 22.3178 3.36449 22.3178H20.6355C21.5646 22.3178 22.3178 21.5646 22.3178 20.6356V5.15892C22.3178 4.22984 21.5646 3.47668 20.6355 3.47668ZM3.36449 1.79443C1.50633 1.79443 0 3.30076 0 5.15892V20.6356C0 22.4937 1.50633 24 3.36449 24H20.6355C22.4937 24 24 22.4937 24 20.6356V5.15892C24 3.30077 22.4937 1.79443 20.6355 1.79443H3.36449Z"
    />
    <path d="M1.23364 6.84131H22.6542V8.6357H1.23364V6.84131Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.04675 0.841122C5.04675 0.376583 5.42334 0 5.88787 0C6.35241 0 6.729 0.376583 6.729 0.841121V4.31776C6.729 4.7823 6.35241 5.15888 5.88787 5.15888C5.42334 5.15888 5.04675 4.7823 5.04675 4.31776V0.841122ZM11.1028 0.841122C11.1028 0.376583 11.4794 0 11.9439 0C12.4085 0 12.785 0.376583 12.785 0.841121V4.31776C12.785 4.7823 12.4085 5.15888 11.9439 5.15888C11.4794 5.15888 11.1028 4.7823 11.1028 4.31776V0.841122ZM18 0C17.5355 0 17.1589 0.376583 17.1589 0.841122V4.31776C17.1589 4.7823 17.5355 5.15888 18 5.15888C18.4645 5.15888 18.8411 4.7823 18.8411 4.31776V0.841121C18.8411 0.376583 18.4645 0 18 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9937 11.4234C15.659 11.0887 15.1163 11.0887 14.7816 11.4234L11.9476 14.2575L9.1135 11.4234C8.77878 11.0887 8.2361 11.0887 7.90139 11.4234C7.56667 11.7581 7.56667 12.3008 7.90139 12.6355L10.7354 15.4696L7.89575 18.3093C7.56104 18.644 7.56104 19.1867 7.89575 19.5214C8.23047 19.8561 8.77315 19.8561 9.10786 19.5214L11.9476 16.6817L14.7873 19.5214C15.122 19.8561 15.6647 19.8561 15.9994 19.5214C16.3341 19.1867 16.3341 18.644 15.9994 18.3093L13.1597 15.4696L15.9937 12.6355C16.3284 12.3008 16.3284 11.7581 15.9937 11.4234Z"
    />
  </svg>
);
