import { useEffect } from "react";
import { SETTINGS_ROUTES } from "../consts/routes";
import { history } from "../history";
import { lockAppUntilAccountAddressUpdated } from "../utilities/localStorage";

export function useAppLockUntilAccountAddressUpdated() {
  useEffect(() => {
    let timeout;
    const isAppLocked = Boolean(lockAppUntilAccountAddressUpdated.get());

    if (isAppLocked) {
      timeout = setTimeout(() => {
        history.push(SETTINGS_ROUTES.arAccount());
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [window.location.pathname]);
}
