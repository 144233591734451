import { matchPath } from "react-router";
import { ROUTES } from "../../../../../consts/routes";
import { removeNullishFromShape } from "../../../../../utilities/general";

export function getHealthPathParams() {
  const match1 = matchPath(window.location.pathname, {
    path: ROUTES.createEditHealthProcedure({
      actionType: ":actionType",
      resourceType: ":resourceType",
      procedureId: ":procedureID?",
      clientId: ":clientID",
      type: ":type",
      isPrescribed: ":isPrescribed",
    }),
    exact: true,
    strict: false,
  });

  const match2 = matchPath(window.location.pathname, {
    path: ROUTES.createEditHealthProcedure({
      actionType: ":actionType",
      resourceType: ":resourceType",
      clientId: ":clientID",
      type: ":type",
      isPrescribed: ":isPrescribed",
    }),
    exact: true,
    strict: false,
  });

  const params = {
    ...removeNullishFromShape(match1?.params || {}),
    ...removeNullishFromShape(match2?.params || {}),
  };

  return {
    clientId: params.clientID ? Number(params.clientID) : null,
    procedureId: params.procedureID ? Number(params.procedureID) : null,
    isEditMode: Boolean(params.procedureID),
    isPrescribed: params.isPrescribed === "true",
  };
}
