import React from "react";
import { SelectSecondary } from "../../../../../shared/SelectSecondary/SelectSecondary";
import classes from "./FilterClinic.module.scss";
import { useClinicsQuery } from "../../../../../api/queries/useClinicsQuery";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

export function FilterClinic({ onChange, isFetching, clinicId }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: clinics, isLoading: isClinicsLoading } = useClinicsQuery();

  const clinicOptions = (clinics || []).map((clinic) => ({
    label: clinic.name,
    value: clinic.id,
  }));

  return (
    <div className={classes.root}>
      <div>{tCommon("label.clinic")}:</div>
      <SelectSecondary
        value={clinicId}
        isDisabled={isClinicsLoading || isFetching}
        variant="legacy"
        options={[
          {
            label: tCommon("label.select"),
            value: "",
          },
          ...clinicOptions,
        ]}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}
