import React from "react";
import { withRouter } from "react-router";
import { upgradeAcceleratorUrl } from "../../Utils/services.js";

class UpgradeAccount extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.state = {
      globalLang:
        languageData && languageData.global ? languageData.global : {},
      user_type: userData && userData.user_type ? userData.user_type : "",
      feature_is_not_available_upgrade_account:
        languageData &&
        languageData.global &&
        languageData.global[
          (props.notificaionType ? props.notificaionType : "feature") +
            "_is_not_available_upgrade_account"
        ]
          ? languageData.global[
              (props.notificaionType ? props.notificaionType : "feature") +
                "_is_not_available_upgrade_account"
            ]
          : "",
    };
  }

  redirectToUpgrade = () => {
    return <div>{this.props.history.push(upgradeAcceleratorUrl())}</div>;
  };

  render() {
    return (
      <div className="row p-t-20">
        <div className="col-lg-12 col-xs-12">
          <div className="feature-not-available">
            <p>{this.state.feature_is_not_available_upgrade_account}</p>
          </div>
          <div className="updgrade-to-accelerator ">
            {this.state.user_type != "superadmin" && (
              <p className="ContactYourAdmin">
                {this.state.globalLang.please_contact_your_admin}
              </p>
            )}
            {this.state.user_type == "superadmin" && (
              <button
                className="save-profile confirm-model new-blue-btn pull-right"
                data-confirm-url=""
                id="upgrade_account"
                type="button"
                onClick={this.redirectToUpgrade}
              >
                {this.state.globalLang.click_here_to_upgrade}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpgradeAccount);
