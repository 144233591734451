import React from "react";
import PropTypes from "prop-types";
import { EntityRow } from "../EntityRow";
import { Textarea } from "../../../../shared/Textarea/Textarea";

const CommonNotes = ({ notes, onNotesChange, isDisabled }) => (
  <EntityRow label="Appointment Notes">
    <Textarea
      name="notes"
      rows={5}
      value={notes}
      onChange={(e) => onNotesChange(e.target.value)}
      disabled={isDisabled}
    />
  </EntityRow>
);

CommonNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  onNotesChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

CommonNotes.defaultProps = {
  isDisabled: false,
};

export default CommonNotes;
