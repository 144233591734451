import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { patientVitalSchema } from "./schemas";

const requestSchema = yup.object({
  patientId: yup.number().required(),
});

const responseSchema = yup.array().of(patientVitalSchema);

const composeResponse = (res) => {
  return res.data?.data || [];
};

function createPatientVitalsQueryKey(patientId) {
  return [QUERY_KEYS.patientVitals.all, patientId];
}

export function usePatientVitalsQuery({ payload, options = {} } = {}) {
  return useQuery(
    createPatientVitalsQueryKey(payload.patientId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.patientVitals.getAll(req.patientId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
