export const schedulePrivacyPolicyPopup = () => {
  localStorage.setItem("oneTimePrivacyPolicyPopup", "true");
};

export const isPrivacyPolicyPopupScheduled = () => {
  return localStorage.getItem("oneTimePrivacyPolicyPopup") === "true";
};

export const removePrivacyPolicyPopupSchedule = () => {
  localStorage.removeItem("oneTimePrivacyPolicyPopup");
};
