import React, { useMemo } from "react";
import cx from "clsx";
import classes from "./VerticalTable.module.scss";
import { Column } from "./shared/Column/Column";
import { Empty } from "./shared/Empty/Empty";
import { VERTICAL_TABLE_EVENT_ATTRIBUTES } from "./VerticalTable.config";
import { prepareColumns, sortColEntries } from "./VerticalTable.utils";

/**
 * @typedef {{
 *  key?: string | number;
 *  [key:string]: React.ReactNode;
 * }} Col
 *
 * @param {Object} param0
 *
 * @param {Array<{
 *  label: React.ReactNode;
 *  value: string;
 * }>} param0.accessors
 *
 * @param {Col[]} param0.cols
 * @param {Col[]?} param0.prependedCols
 * @param {((col: Col) => string | number)?} param0.keyExtractor
 * @param {boolean?} param0.accentFirstLine
 * @param {boolean?} param0.stretch
 * @param {string | number | undefined} param0.activeColKey
 * @param {((col: Col) => void)?} param0.onColClick
 * @param {string?} param0.className
 * @param {string?} param0.accessorsColClassName
 * @param {string?} param0.accessorCelClassName
 * @param {string?} param0.colClassName
 * @param {string?} param0.cellClassName
 * @param {React.ReactNode?} param0.emptyNode
 * @param {("asc" | "desc")?} param0.numericColsSort
 */
export function VerticalTable({
  accessors,
  cols,
  prependedCols = [],
  keyExtractor = (col) => col.key,
  accentFirstLine,
  stretch,
  activeColKey,
  onColClick,
  className,
  accessorsColClassName,
  accessorCellClassName,
  colClassName,
  cellClassName,
  emptyNode,
  numericColsSort = "desc",
}) {
  const preparedCols = useMemo(
    () => prepareColumns({ cols, keyExtractor, accessors }),
    [accessors, cols],
  );

  const preparedPrependedCols = useMemo(
    () =>
      prepareColumns({
        cols: prependedCols,
        keyExtractor,
        accessors,
      }),
    [accessors, prependedCols],
  );

  const getHandleColClick = (key) => {
    if (onColClick) {
      return (e) => {
        if (onColClick) {
          const attributes = e.target.dataset;

          const preventColClick =
            attributes[VERTICAL_TABLE_EVENT_ATTRIBUTES.preventColClick] ===
            "true";

          if (!preventColClick) {
            onColClick(
              [...prependedCols, ...cols].find(
                (c) => String(keyExtractor(c)) === key,
              ),
            );
          }
        }
      };
    }
  };

  const colEntries = useMemo(
    () => sortColEntries(Object.entries(preparedCols), numericColsSort),
    [preparedCols],
  );

  const prependedColEntries = useMemo(
    () =>
      sortColEntries(Object.entries(preparedPrependedCols), numericColsSort),
    [preparedPrependedCols],
  );

  return (
    <div className={cx(classes.root, className)}>
      <Column.Accessor
        stretch={stretch}
        accessors={accessors}
        className={accessorsColClassName}
        accentFirstCell={accentFirstLine}
        cellClassName={accessorCellClassName}
      />
      {!colEntries.length && !prependedColEntries.length && (
        <Empty>{emptyNode}</Empty>
      )}
      {prependedColEntries.map(([key, rows]) => (
        <Column.Base
          key={key}
          parentKey={key}
          stretch={stretch}
          accentFirstCell={accentFirstLine}
          isActive={String(activeColKey) === key}
          onClick={getHandleColClick(key)}
          rows={rows}
          className={colClassName}
          cellClassName={cellClassName}
        />
      ))}
      {colEntries.map(([key, rows]) => (
        <Column.Base
          key={key}
          parentKey={key}
          stretch={stretch}
          accentFirstCell={accentFirstLine}
          isActive={String(activeColKey) === key}
          onClick={getHandleColClick(key)}
          rows={rows}
          className={colClassName}
          cellClassName={cellClassName}
        />
      ))}
      {!stretch && (
        <Column.Filler
          accessors={accessors}
          accentFirstCell={accentFirstLine}
        />
      )}
    </div>
  );
}
