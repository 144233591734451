import React from "react";
import { Box } from "../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { EditIcon } from "../../../../../assets/Icons/EditIcon";
import { history } from "../../../../../history";
import { INVENTORY_ROUTES } from "../../../../../consts/routes";
import styles from "./styles.module.scss";

/**
 * @param {object} param0
 * @param {{
 *    value: Array<Record<string, unknown>>;
 *    hasMore: boolean;
 *    isNextFetching: boolean;
 *    isLoading: boolean;
 *    fetchMore: () => void;
 *    isSoftLoading: boolean;
 * }} param0.data
 */
export function Table({ data }) {
  const { tCommon } = useAppTranslation.Common();
  const { tInventory } = useAppTranslation.Inventory();

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const tableData = data.value.map((i) => ({
    key: i.id,
    name: i.name,
    type: tInventory(`pricing_variation.product_type.${i.package_type}`),
    createdBy: i.user ? i.user.full_name : tCommon("symbol.longDash"),
    date: i.created,
    actions: (
      <Button
        size="tiny"
        variant="text"
        leftAdornment={<EditIcon width="16px" />}
        onClick={() => {
          history.push(INVENTORY_ROUTES.editPricingVariation(i.id));
        }}
      >
        {tInventory("pricing_variation.edit")}
      </Button>
    ),
  }));

  return (
    <Box className={styles.root}>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={tableData}
            cols={[
              {
                data: tInventory("pricing_variation.table.name"),
                accessor: "name",
                headColClassName: styles.colBig,
              },
              {
                data: tInventory("pricing_variation.table.type"),
                accessor: "type",
                headColClassName: styles.colSmall,
              },
              {
                data: tInventory("pricing_variation.table.created_by"),
                accessor: "createdBy",
                headColClassName: styles.colBig,
              },
              {
                data: tInventory("pricing_variation.table.created_date"),
                accessor: "date",
                headColClassName: styles.colSmall,
              },
              {
                data: tInventory("pricing_variation.table.actions"),
                accessor: "actions",
                headColClassName: styles.colSmall,
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}
