import ReactTooltip from "react-tooltip";
import React from "react";

export function MembershipProratedFeeInfo({ id, diff_days }) {
  return <div className="col-sm-12 m-t-4">
      <span className="colorAR m-r-3 font-size-16" data-tip data-for={`membership-prorated-fee-${id}`}><i className="fa fa-info-circle"></i></span>    <ReactTooltip id={`membership-prorated-fee-${id}`} place="top">
      <span>The prorated amount is always calculated on a 30 day basis.</span>
    </ReactTooltip>
    Prorated fee for {diff_days} days of membership
  </div>
}