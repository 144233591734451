import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../shared/Button/Button";
import { svg } from "../../../../../../assets/svg";
import { DateRangePicker } from "../../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { ReportHeader } from "../../../../../BusinessInsights/components/ReportHeader/ReportHeader";
import { TotalCount } from "../../../../../BusinessInsights/components/TotalCount/TotalCount";
import DownloadDropdown from "../../../../components/DownloadDropdown";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import classes from "../../../../newBusinessInsightsContent.module.scss";
import { Modal } from "../../../../../../shared/Modal/Modal";

const modalInfo = {
  title: "membershipChurnReport.info.title",
  rows: ["membershipChurnReport.info.note"],
};

export function Header({
  isFilterActive,
  onFilterOpen,
  period,
  isReportsLoading,
  totalCount,
  requestParams,
}) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <ReportHeader
      label={tBi("membershipChurnReport.label")}
      left={
        <Button
          size="small"
          variant="text"
          className={classes.infoButton}
          onClick={() => setIsInfoModalOpen(true)}
        >
          <img src={svg.infoPrimary} alt="info" width="20px" />
        </Button>
      }
    >
      <TotalCount>{totalCount}</TotalCount>
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />
      <DownloadDropdown
        downloadRoute={HTTP_ENDPOINTS.getMembershipChurnReport()}
        params={requestParams}
      />
      <Button
        size="small"
        variant="outlined"
        onClick={onFilterOpen}
        isDisabled={isReportsLoading}
        leftAdornment={
          isFilterActive ? (
            <img src={svg.filterPrimaryActive} alt="filter" width="16px" />
          ) : (
            <img src={svg.filterPrimary} alt="filter" width="14px" />
          )
        }
      >
        {tCommon("label.filter")}
      </Button>
      <Modal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        header={<Modal.Title>{tBi(modalInfo?.title)}</Modal.Title>}
        contentClassName={classes.infoModalContent}
      >
        {modalInfo?.rows.map((key) => (
          <p key={key}>
            <Trans
              i18nKey={tBi(key)}
              components={{
                b: <b />,
              }}
            />
          </p>
        ))}
      </Modal>
    </ReportHeader>
  );
}

Header.propTypes = {
  isFilterActive: PropTypes.bool.isRequired,
  onFilterOpen: PropTypes.func.isRequired,
  period: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    update: PropTypes.func,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
};

Header.defaultProps = {
  totalCount: 0,
};
