import { ACTION_TYPES } from "./consts";

const initialState = {
  isOpen: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.open: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case ACTION_TYPES.close: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default: {
      return state;
    }
  }
}
