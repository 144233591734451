import React, { useEffect } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useChargeForm } from "../../hooks/useChargeForm";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import PaymentFormModal from "./components/PaymentFormModal";
import { tCommon, tSales } from "../../../../../i18n/useAppTranslation";
import { img } from "../../../../../assets/img";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";

const ViewChargeCareCredit = ({ isTipsApplying }) => {
  const { invoice, amountToPay } = useInvoice();
  const { chargeCareCredit, paymentViewClose } = useInvoiceActions();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
    note: yup.string(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount, note }) => {
        chargeCareCredit.initiate({
          amount,
          note,
        });
      },
      initialValues: {
        amount: amountToPay || "",
        note: "",
      },
    });

  useEffect(() => {
    changeFormValue("amount", amountToPay);
  }, [amountToPay]);

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.careCredit")}
      imageSrc={img.careCredit}
      onCharge={submit}
      isCharging={chargeCareCredit.isLoading}
      chargeLabel={tCommon("label.charge")}
      amount={form.amount}
      isChargeDisabled={!isValid || isTipsApplying}
    >
      <ViewTipsModal amountToPay={form.amount} />
      <PaymentFormModal
        form={form}
        errors={errors}
        hasError={hasError}
        changeFormValue={changeFormValue}
      />
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCareCredit);
