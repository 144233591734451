import { useCurrentAccountQuery } from "../../../../../../api/queries/useAccountQuery";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { apiFileAdapter } from "../utilities";

export function useApiFile(init) {
  const accountQuery = useCurrentAccountQuery({
    onSuccess: (data) => {
      init(apiFileAdapter(data.businessInfo));
    },
    onError: () => {
      uiNotification.error("Failed to fetch tax free status file");
    },
  });

  return {
    value: apiFileAdapter(accountQuery.data?.businessInfo),
    isLoading: accountQuery.isFetching,
  };
}
