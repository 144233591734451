import React from "react";
import styles from "./styles.module.scss";

export function ChoiceWrap({ children, index }) {
  return (
    <div className={styles.root}>
      <div className={styles.index}>{index})</div>
      {children}
    </div>
  );
}
