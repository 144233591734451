import { http } from "../services/HttpService";
import { uiNotification } from "../services/UINotificationService";

export const getReferralSources = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .get("get-referral-sources", formData)
      .then((response) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const changeReferralSourceStatus = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("change-status-referral-source", formData)
      .then((response) => {
        dispatch({
          type: "CHANGE_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const addReferralSource = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("add-referral-source", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const addReferralSourceSubcategory = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("add-source-subcategory", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const changeReferralSubcategoryStatus = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("change-status-source-subcategory", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const editReferralSourceName = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("edit-referral-source", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const deleteReferralSource = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("delete-referral-source", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const editReferralSubcategoryName = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("edit-source-subcategory", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const deleteSubcategorySource = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post("delete-source-subcategory", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        uiNotification.error(error.response.data.message);
      });
  };
};
