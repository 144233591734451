import React from "react";
import { LayoutMain } from "../../../../boxes/LayoutMain/LayoutMain";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { useDiff } from "./hooks/useDiff";
import styles from "./styles.module.scss";
import { USER_LOG_DIFF_TYPE } from "../../../../api/queries/useUserLogsDiffQuery";
import { ConsentsDiff } from "./boxes/ConsentsDiff";
import { QuestionnairesDiff } from "./boxes/QuestionnairesDiff";
import { Loading } from "../../../../boxes/Loading/Loading";

export function UserLogsDiff() {
  const { tDashboard } = useAppTranslation.Dashbaord();
  const diff = useDiff();

  const diffMap = {
    [USER_LOG_DIFF_TYPE.consent]: <ConsentsDiff diff={diff.data} />,
    [USER_LOG_DIFF_TYPE.questionnaire]: <QuestionnairesDiff diff={diff.data} />,
  };

  return (
    <LayoutMain>
      <EntityHeader withBackBtn title={tDashboard("user_logs_diff.title")} />
      <div className={styles.wrapper}>
        {diff.isLoading ? <Loading center /> : diffMap[diff.type]}
      </div>
    </LayoutMain>
  );
}
