import React from "react";
import cx from "clsx";
import classes from "./styles.module.scss";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import { SaveDiskIcon } from "../../../../../../../assets/Icons/SaveDiskIcon";

export function SaveButton({ onClick, isLoading, noMargin }) {
  return (
    <button
      className={cx(classes.root, {
        [classes.rootNoMargin]: noMargin,
      })}
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <CircularProgress size="small" />
      ) : (
        <SaveDiskIcon width="18px" />
      )}
    </button>
  );
}
