import React from "react";
import { withRouter } from "react-router";
import Header from "../../../_legacy/Containers/Guest/Header.js";
import Footer from "../../../_legacy/Containers/Guest/Footer.js";

const KlarnaCancelPayment = () => {
  return (
    <div className="guest">
      <Header />
      <div className="wrapper">
        <div className="card-block">
          <div className="login-main m-t-60">
            <img
              alt=""
              className="height38px m-auto m-b-40"
              src="/images/klarna-black-logo.png"
            />
            <h1>Payment failed!</h1>
            <h4 className="m-b-40">
              The request was declined. Please choose a different payment
              method.
            </h4>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(KlarnaCancelPayment);
