import { useEffect, useState } from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function useSelectedCategory(categoriesList) {
  const { tCommon } = useAppTranslation.Common();

  const [category, setCategory] = useState(null);

  const selectCategory = ({ next, isTouched }) => {
    if (!isTouched || window.confirm(tCommon("confirm.changeReorderData"))) {
      setCategory(next);
    }
  };

  useEffect(() => {
    if (!category && categoriesList?.length > 0) {
      setCategory(categoriesList[0]);
    }
  }, [category, categoriesList?.length]);

  return {
    selectedCategory: category,
    selectCategory,
  };
}
