import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import Clearent from "./Clearent/Clearent";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { USER_PAYMENT_SYSTEMS } from "../../../../../consts/api";
import Stripe from "./Stripe/Stripe";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";

function ViewChargeCard({ isInvoiceWatchMode }) {
  const { tSales } = useAppTranslation.Sales();
  const { data: user, isLoading: isUserLoading } = useCurrentUserQuery();
  const { paymentViewClose } = useInvoiceActions();

  const onClose = () => {
    if (!isInvoiceWatchMode) {
      paymentViewClose();
    }
  };

  const formByPaymentSystem = {
    [USER_PAYMENT_SYSTEMS.clearent]: <Clearent />,
    [USER_PAYMENT_SYSTEMS.stripe]: <Stripe />,
  };

  return (
    <ModalCharge
      isOpen
      noFooter
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
    >
      {isUserLoading ? (
        <ModalCharge.Skeleton />
      ) : (
        formByPaymentSystem[user?.account?.paymentSystem]
      )}
    </ModalCharge>
  );
}

ViewChargeCard.propTypes = {
  isInvoiceWatchMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isInvoiceWatchMode: checkoutInvoice.selectors.selectIsInvoiceWatchMode(state),
});

export default connect(mapStateToProps)(ViewChargeCard);
