import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { DateRangePicker } from "../../../../../../shared/DateRangePicker/DateRangePicker";
import { ReportHeader } from "../../../../components/ReportHeader/ReportHeader";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";

export function Header({ period, isReportsLoading }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader label={tBi("shopifyOrdersReport.label")}>
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />
    </ReportHeader>
  );
}

Header.propTypes = {
  period: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    update: PropTypes.func,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
};
