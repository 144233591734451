import { useMemo, useState } from "react";
import { ORDER_TYPES } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { useNoClinicPatientsReportQuery } from "../../../../../../api/noClinicPatientsReport/useNoClinicPatientsReportQuery";

const prepareReqOrder = (orderState) => {
  if (Object.values(orderState).every(Boolean)) {
    return {
      by: orderState.by,
      direction: orderState.direction,
    };
  }
  return null;
};

export const useReport = () => {
  const { tCommon } = useAppTranslation.Common();

  const [order, setOrder] = useState({
    by: "",
    direction: "",
  });

  const updateOrder = (orderFieldKey) => {
    setOrder({
      by: orderFieldKey,
      direction:
        order.direction === ORDER_TYPES.desc
          ? ORDER_TYPES.asc
          : ORDER_TYPES.desc,
    });
  };

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useNoClinicPatientsReportQuery({
    payload: {
      pageSize: 15,
      order: prepareReqOrder(order),
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  });

  const reports = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const isOrderActive = Object.values(order).some(Boolean);

  const isSoftLoading =
    isOrderActive && isFetching && !isLoading && !isFetchingNextPage;

  return {
    reports: {
      value: reports,
      isLoading,
      isSoftLoading,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      fetchMore: fetchNextPage,
    },
    order: {
      value: order,
      update: updateOrder,
    },
  };
};
