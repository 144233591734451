import { SELECTED_TYPES } from "./SelectChip.consts";

export const getUnwrapSelected =
  ({ isMulti, selected, selectedType }) =>
  () => {
    const selectedList = (isMulti ? selected || [] : [selected]).filter(
      Boolean,
    );
    if (selectedType === SELECTED_TYPES.option) {
      return selectedList.map((s) => s.value);
    }
    return selectedList;
  };

export const getWrapSelected =
  ({ options, isMulti, selectedType }) =>
  (unwrappedSelected) => {
    const nextSelected = options.filter((o) =>
      unwrappedSelected.includes(o.value),
    );
    if (isMulti) {
      if (selectedType === SELECTED_TYPES.optionValue) {
        return nextSelected.map((s) => s.value);
      }
      return nextSelected;
    }
    if (selectedType === SELECTED_TYPES.optionValue) {
      return nextSelected?.[0]?.value;
    }
    return nextSelected?.[0];
  };
