import React from "react";
import cx from "clsx";
import { Box } from "../../../../shared/Box/Box";
import { useMergePatientsEfaxes } from "./hooks/useMergePatientsEfaxes";
import { Empty } from "../../../../shared/Empty/Empty";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";
import Orders from "./components/Orders";
import classes from "./scss/styles.module.scss";

const EfaxOrders = ({ selectedOrders, setSelectedOrders }) => {
  const { data, isLoading } = useMergePatientsEfaxes({
    onSuccess: (data) => {
      setSelectedOrders(data.faxes);
    },
  });

  const isEmpty = data?.faxes?.length === 0 && !isLoading;
  const hasData = !isLoading && !isEmpty;

  const handleAllCheckboxes = () => {
    if (data?.faxes?.length === selectedOrders.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(data?.faxes);
    }
  };

  return (
    <>
      {hasData && (
        <>
          <Box>
            <div
              className={cx(
                "flex",
                "items-center",
                "gap-16",
                classes.detailsBorder,
              )}
            >
              <input
                type="checkbox"
                checked={data?.faxes?.length === selectedOrders.length}
                onChange={handleAllCheckboxes}
              />
              <div className="flex gap-24 items-center">
                <h3>Orders</h3>
                <p className="m-0">
                  Please select the right information you want to keep in the
                  new merged profile.
                </p>
              </div>
            </div>
          </Box>
          {data?.faxes?.map((fax) => (
            <Orders
              key={fax.id}
              fax={fax}
              selectedOrders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
            />
          ))}
        </>
      )}
      {isLoading && (
        <Box>
          <Skeleton count={7} height={40.5} borderRadius="0px" />
        </Box>
      )}
      {isEmpty && (
        <Box>
          <Empty position="center" />
        </Box>
      )}
    </>
  );
};

export default EfaxOrders;
