import React from "react";
import PropTypes from "prop-types";
import { InputName } from "../components/InputName";
import { useApiFindClient } from "../../../hooks/useApiFindClient";
import classes from "../sass/ClientHandler.module.scss";
import { SuccessAlert } from "../components/SuccessAlert";
import { ButtonSecondary } from "../../../../../shared/ButtonSecondary/ButtonSecondary";
import { MODE } from "../ClientHandler.consts";
import { InputEmail } from "../components/InputEmail";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { InputPhone } from "../components/InputPhone";
import { useRandomKey } from "../../../../../utilities/hooks/useRandomKey";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

export function FormDefault({
  readonly,
  name,
  onNameChange,
  email,
  phone,
  isNameError,
  isEmailError,
  isPhoneError,
  isClientSelected,
  onSelectClient,
  setMode,
  alertMessage,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [phoneKey, refreshPhoneKey] = useRandomKey();
  const { findClientList, isClientsSearching } = useApiFindClient({
    name,
  });

  const handleSelectClient = (client) => {
    onSelectClient(client);
    refreshPhoneKey();
  };

  return (
    <div>
      <div className={classes.formGrid}>
        <InputName
          withSearch
          isError={isNameError}
          value={name}
          onChange={onNameChange}
          clientsList={findClientList}
          onSelect={handleSelectClient}
          onSetCreateMode={() => setMode(MODE.create)}
          isSearching={isClientsSearching}
          isDisabled={readonly}
        />
        <InputEmail
          isDisabled
          value={email}
          onChange={() => {}}
          isError={isEmailError}
        />
        <InputPhone
          key={phoneKey}
          isDisabled
          value={phone}
          isError={isPhoneError}
        />
      </div>
      {(!email || !phone) && isClientSelected && (
        <AlertBox size="small" className={classes.alert}>
          {tCommon("warn.patientPhoneOrEmailMissing")}
        </AlertBox>
      )}
      {!readonly && isClientSelected && (
        <div className={classes.formControls}>
          <ButtonSecondary
            size="small"
            fontFamily="secondary"
            onClick={() => setMode(MODE.edit)}
            className={classes.formBtn}
          >
            Edit
          </ButtonSecondary>
          {alertMessage && <SuccessAlert>{alertMessage}</SuccessAlert>}
        </div>
      )}
    </div>
  );
}

FormDefault.propTypes = {
  readonly: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  isNameError: PropTypes.bool.isRequired,
  isEmailError: PropTypes.bool.isRequired,
  isPhoneError: PropTypes.bool.isRequired,
  isClientSelected: PropTypes.bool.isRequired,
  onSelectClient: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  alertMessage: PropTypes.string,
};

FormDefault.defaultProps = {
  alertMessage: null,
};
