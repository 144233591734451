import { useMemo } from "react";
import { PATIENTS_PER_PAGE } from "../NonDupPatients.consts";
import { useNonDupPatientsQuery } from "../../../api/queries/useNonDupPatientsQuery";

export function useNonDupPatients({ searchTerm }) {
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useNonDupPatientsQuery({
    payload: {
      pageSize: PATIENTS_PER_PAGE,
      searchTerm,
    },
    options: {
      staleTime: 0,
    },
  });

  const patients = useMemo(() => {
    if (data && data.pages) {
      return data.pages
        .map((p) => p.patients)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data]);

  return {
    patients,
    isPatientsLoading: isLoading,
    isNextPatientsFetching: isFetchingNextPage,
    fetchNextPatients: fetchNextPage,
    hasMorePatients: hasNextPage,
    refetchPatients: refetch,
  };
}
