import React from "react";

export function Loader() {
  return (
    <div className="new-loader text-left displayBlock clientLoader clientProfileLoader healthPrescriptionLoader">
      <div className="loader-outer">
        <img
          id="loader-outer"
          src="/images/Eclipse.gif"
          className="loader-img"
        />
        <div id="modal-confirm-text" className="popup-subtitle">
          Loading.. Please wait
        </div>
      </div>
    </div>
  );
}
