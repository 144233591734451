import React from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Group } from "../../shared/Group/Group";
import { CategorySelect } from "../../boxes/CategorySelect/CategorySelect";
import {
  composeAlcoholOptionLabel,
  composeDrugOptionLabel,
  composeExerciseOptionLabel,
  composeTobaccoOptionLabel,
  composeWeightOptionLabel,
} from "./SocialHistory.utils";
import { getIsSomeTruthy } from "../../../../../../helpers/general";

export function SocialHistory({ intersection, socialHistories, onChange }) {
  const { tClients } = useAppTranslation.Clients();
  const isSomeTruthy = getIsSomeTruthy(socialHistories);

  return (
    <Group compact label={tClients("medicalHistory.socialHistory.label")}>
      {isSomeTruthy(["tobacco_use"]) && (
        <CategorySelect
          composeLabel={composeTobaccoOptionLabel}
          name={tClients("medicalHistory.socialHistory.tobaccoUse")}
          onChange={onChange}
          value={{
            tobacco_use: intersection.tobacco_use || 0,
            tobacco_use_week: intersection.tobacco_use_week || null,
            tobacco_use_year: intersection.tobacco_use_year || null,
          }}
          options={socialHistories.map((h) => ({
            tobacco_use: h.tobacco_use || 0,
            tobacco_use_week: h.tobacco_use_week || null,
            tobacco_use_year: h.tobacco_use_year || null,
          }))}
        />
      )}
      {isSomeTruthy(["drug_use"]) && (
        <CategorySelect
          composeLabel={composeDrugOptionLabel}
          name={tClients("medicalHistory.socialHistory.drugUse")}
          onChange={onChange}
          value={{
            drug_use: intersection.drug_use || 0,
            drug_use_period: intersection.drug_use_period || null,
            drug_use_type: intersection.drug_use_type || null,
            drug_use_week: intersection.drug_use_week || null,
          }}
          options={socialHistories.map((h) => ({
            drug_use: h.drug_use || 0,
            drug_use_period: h.drug_use_period || null,
            drug_use_type: h.drug_use_type || null,
            drug_use_week: h.drug_use_week || null,
          }))}
        />
      )}
      {isSomeTruthy(["alcohol_use"]) && (
        <CategorySelect
          composeLabel={composeAlcoholOptionLabel}
          name={tClients("medicalHistory.socialHistory.alcoholUse")}
          onChange={onChange}
          value={{
            alcohol_use: intersection.alcohol_use || 0,
            alcohol_use_week: intersection.alcohol_use_week || null,
          }}
          options={socialHistories.map((h) => ({
            alcohol_use: h.alcohol_use || 0,
            alcohol_use_week: h.alcohol_use_week || null,
          }))}
        />
      )}
      {isSomeTruthy(["exercise_use"]) && (
        <CategorySelect
          composeLabel={composeExerciseOptionLabel}
          name={tClients("medicalHistory.socialHistory.exercise")}
          onChange={onChange}
          value={{
            exercise_use: intersection.exercise_use || 0,
            exercise_use_week: intersection.exercise_use_week || null,
          }}
          options={socialHistories.map((h) => ({
            exercise_use: h.exercise_use || 0,
            exercise_use_week: h.exercise_use_week || null,
          }))}
        />
      )}
      {isSomeTruthy(["weight"]) && (
        <CategorySelect
          composeLabel={composeWeightOptionLabel}
          name={tClients("medicalHistory.socialHistory.weight")}
          onChange={onChange}
          value={{
            weight: intersection.weight || 0,
            weight_info: intersection.weight_info || null,
          }}
          options={socialHistories.map((h) => ({
            weight: h.weight || 0,
            weight_info: h.weight_info || null,
          }))}
        />
      )}
    </Group>
  );
}
