import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { usePieChartsData } from "./usePieChartsData";

export const useEgiftcards = (params, options = {}) => {
  return useQuery(
    ["eGiftcardsReport", params],
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getEgiftCard(), {
        params,
      });

      const pieCharts = usePieChartsData(res.data.data);

      const composeResponse = {
        clinics: res.data.data.clinics,
        reportData: res.data.data.report_data,
        giftCardSold: res.data.data.gift_card_purchased,
        giftCardRedeemed: res.data.data.gift_card_redeemed,
        giftCardTransactions: res.data.data.gift_card_transactions,
        pieCharts,
      };
      return composeResponse;
    },
    options,
  );
};
