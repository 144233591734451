import { ACTION_TYPES } from "./consts";

const initialState = {
  globalSettings: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.getGlobalSettings: {
      return {
        globalSettings: action.payload || null,
      };
    }
    default: {
      return state;
    }
  }
}
