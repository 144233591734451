import React, { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import queryString from "query-string";
import { Header } from "./components/Header";
import { Table } from "./components/Table";
import { ModalFilter } from "./components/ModalFilter";
import { ReportDetails } from "./ReportDetails/ReportDetails";
import { useReports } from "./hooks/useReports";
import { ReportLayout } from "../../../../BusinessInsights/components/ReportLayout/ReportLayout";
import { API_DATE_FORMAT } from "../../../../../consts/api";
import { ReportStats } from "../../../../../boxes/ReportStats/ReportStats";

function MembershipOverviewReport({ location }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { reports, filter, order, period, stats } = useReports();
  const queryParams = queryString.parse(location.search);
  const reportId = Number(queryParams.id);

  const requestParams = {
    fromDate: moment(period.value.from).format(API_DATE_FORMAT),
    toDate: moment(period.value.to).format(API_DATE_FORMAT),
    clinicIds: filter.preparedForRequest.clinicIds,
    tierIds: filter.preparedForRequest.tierIds,
    paymentFrequency: filter.preparedForRequest.subscriptionType,
    status: filter.preparedForRequest.status,
  };

  return reportId ? (
    <ReportDetails id={reportId} />
  ) : (
    <ReportLayout>
      <Header
        isFilterActive={filter.isActive}
        onFilterOpen={() => setIsFilterOpen(true)}
        period={period}
        requestParams={requestParams}
        isReportsLoading={reports.isLoading}
      />
      <ReportStats isLoading={reports.isLoading} data={stats} />
      <Table reports={reports} order={order} />
      <ModalFilter
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={filter}
      />
    </ReportLayout>
  );
}

export default withRouter(MembershipOverviewReport);
