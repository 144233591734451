import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";

export const useProviderDashboard = (options = {}) => {
  const res = useQuery(
    ["providerDashboard"],
    async () => {
      return http.get(HTTP_ENDPOINTS.getProviderDashboard());
    },
    {
      select: (data) => ({
        widgets: data.data.data.widgets,
        clinics: data.data.data.clinics,
      }),
      ...options,
    },
  );

  const widgetsList = Object.values(res.data?.widgets || []);
  const clinicList = res.data?.clinics;

  return {
    widgetsList,
    clinicList,
    isLoading: res.isLoading,
  };
};
