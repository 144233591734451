import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  ids: yup.array().of(yup.number()).required(),
});

export function useRestoreCustomerNotesMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return http.patch(HTTP_ENDPOINTS.deletedNotes.customer.restore(), {
        client_notes_ids: req.ids,
      });
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.deletedNotes.customer],
        });
        options.onSuccess?.(...args);
      },
    },
  );
}
