import { toast } from "react-toastify";
import uuid from "uuid";
import { tCommon } from "../i18n/useAppTranslation";

const defaultConfig = {
  placeholder: "",
  fallbackErrorMessage: tCommon("error.fallback"),
  fallbackSuccessMessage: tCommon("success.fallback"),
};

export class UINotificationService {
  constructor(repository, config = defaultConfig) {
    this.config = config;
    this.repo = repository;
    this.blockErrors = false;
  }

  error(message) {
    if (this.blockErrors) {
      return;
    }

    this.repo.error(message || this.config.fallbackErrorMessage, {
      toastId: uuid.v4(),
    });
  }

  warn(message) {
    this.repo.warn(message || this.config.placeholder, {
      toastId: uuid.v4(),
    });
  }

  info(message) {
    this.repo.info(message || this.config.placeholder, {
      toastId: uuid.v4(),
    });
  }

  success(message, options = {}) {
    this.repo.success(message || this.config.fallbackSuccessMessage, {
      toastId: uuid.v4(),
      ...options,
    });
  }

  clear() {
    this.repo.dismiss();
  }

  setBlockErrors(status) {
    this.blockErrors = status;
  }
}

const createUINotificationService = (config) => {
  return new UINotificationService(toast, config);
};

export const uiNotification = createUINotificationService();
