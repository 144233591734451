import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export function Tooltip({ children, id, text, className }) {
  return (
    <>
      <div className={className} data-tip data-for={id}>
        {children}
      </div>
      <ReactTooltip id={id} aria-haspopup="true">
        {text}
      </ReactTooltip>
    </>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  className: undefined,
};
