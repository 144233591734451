import { useQuery } from "@tanstack/react-query";
import { getDefaultTypeExpirationDate } from "../../Actions/Clients/clientsAction";

const fetchDefaultTypeExpirationDate = async ({
  documentType,
  createdDate,
}) => {
  const response = await getDefaultTypeExpirationDate({
    documentType,
    createdDate,
  });
  return response.data;
};

const useDocumentTypeDefaultExpirationDate = (params, options = {}) => {
  return useQuery(
    ["defaultTypeExpirationDate", params],
    () => fetchDefaultTypeExpirationDate(params),
    options,
  );
};

export default useDocumentTypeDefaultExpirationDate;
