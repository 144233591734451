import { sort } from "../../utilities/general";

export const prepareColumns = ({ cols, keyExtractor, accessors }) =>
  cols.reduce((result, col) => {
    return {
      ...result,
      [keyExtractor(col)]: accessors.reduce(
        (ac, { value }) => [...ac, col?.[value]],
        [],
      ),
    };
  }, {});

export const sortColEntries = (entries, numericColsSort) =>
  sort(entries, ([key1], [key2]) => {
    const n1 = Number(key1);
    const n2 = Number(key2);

    if (numericColsSort === "asc") {
      return n1 - n2;
    }

    return n2 - n1;
  });
