import React from "react";
import { Button } from "../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../../widgets/EntityHeader/EntityHeader";
import { history } from "../../../../../history";
import { INVENTORY_ROUTES } from "../../../../../consts/routes";

/**
 * @param {object} param0
 * @param {{
 *    value: string;
 *    onChange: (value: string) => void;
 *    isLoading: boolean;
 * }} param0.search
 */
export function Header({ search }) {
  const { tInventory } = useAppTranslation.Inventory();

  return (
    <EntityHeader
      title={tInventory("pricing_variation.label")}
      searchValue={search.value}
      onChangeSearch={search.onChange}
      isSearching={search.isLoading}
      right={
        <Button
          size="small"
          onClick={() =>
            history.push(INVENTORY_ROUTES.createPricingVariation())
          }
        >
          {tInventory("pricing_variation.create")}
        </Button>
      }
    />
  );
}
