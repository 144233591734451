import React from "react";
import cx from "clsx";
import classes from "./DragIcon.module.scss";

export function DragIcon({ className }) {
  return (
    <div className={cx(classes.root, className)}>
      <span />
      <span />
    </div>
  );
}
