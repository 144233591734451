import React from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../shared/Select/Select";
import { hasLength } from "../../../../../../utilities/general";
import { ReportModalFilter } from "../../../../../BusinessInsights/components/ReportModalFilter/ReportModalFilter";

const initialValues = {
  clinics: [],
  services: [],
  categories: [],
  provider: "",
};

const ModalFilter = ({ isOpen, onClose, filter, reportFilters, isLoading }) => {
  const { tCommon } = useAppTranslation.Common();

  const handleApply = () => {
    filter.update(values);
    onClose();
  };

  const { values, setFieldValue, dirty, handleSubmit, setValues } = useFormik({
    onSubmit: handleApply,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...filter.value,
    },
  });

  const clinicOptions = Object.entries(reportFilters.clinicsFilter).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const categoriesOptions = Object.entries(reportFilters.categoriesFilter).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const providersOptions = Object.entries(reportFilters.providersFilter).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const servicesOptions = Object.entries(reportFilters.servicesFilter).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <ReportModalFilter
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitDisabled={!dirty}
      isFilterTouched={Object.values(values).some(hasLength) || values.provider}
      onReset={() => setValues(initialValues)}
    >
      <div>
        <InputLabel>{tCommon("label.clinics")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.clinics}
          onChange={(options) => setFieldValue("clinics", options)}
          options={clinicOptions}
          isLoading={isLoading}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.serviceName")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.services}
          onChange={(options) => setFieldValue("services", options)}
          options={servicesOptions}
          isLoading={isLoading}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.category")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.categories}
          onChange={(options) => setFieldValue("categories", options)}
          options={categoriesOptions}
          isLoading={isLoading}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.provider")}</InputLabel>
        <Select
          isSearchable
          closeMenuOnSelect
          value={values.provider}
          onChange={(options) => setFieldValue("provider", options)}
          options={providersOptions}
          isLoading={isLoading}
        />
      </div>
    </ReportModalFilter>
  );
};

ModalFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    value: PropTypes.object,
    update: PropTypes.func,
  }).isRequired,
};

export default ModalFilter;
