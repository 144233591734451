import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  notification_phone: yup.string().nullable(),
  appt_canceled: yup.boolean().required(),
  appt_rescheduled: yup.boolean().required(),
  appt_booked: yup.boolean().required(),
});

export function useSmsApptNotificationsMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(HTTP_ENDPOINTS.smsApptNotifications.set(), req);
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.smsApptNotifications.get],
        });
        options?.onSuccess?.(...args);
      },
    },
  );
}
