import { ACTION_TYPES } from "./config";

const initialState = {
  dedupingDocs: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.startDocumentsDedup: {
      return {
        ...state,
        dedupingDocs: [
          ...new Set([...state.dedupingDocs, action.payload.docType]),
        ],
      };
    }
    case ACTION_TYPES.finishDocumentsDedup: {
      return {
        ...state,
        dedupingDocs: state.dedupingDocs.filter(
          (x) => x !== action.payload.docType,
        ),
      };
    }
    default: {
      return state;
    }
  }
}
