import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function fetchSurveys(formData) {
  return (dispatch) => {
    http
      .get("marketing/dashboard", formData)
      .then((response) => {
        dispatch({ type: "All_SURVEYS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "All_SURVEYS", payload: error.response.data });
      });
  };
}

export function surveysList(formData) {
  return (dispatch) => {
    http
      .post("marketing/survey_list", formData)
      .then((response) => {
        dispatch({ type: "SURVEYS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SURVEYS_LIST", payload: error.response.data });
      });
  };
}

export function surveysListQuestions(questionId, formData) {
  return (dispatch) => {
    http
      .get("marketing/survey_details/" + questionId, formData)
      .then((response) => {
        dispatch({ type: "SURVEYS_LIST_QUESTIONS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SURVEYS_LIST_QUESTIONS",
          payload: error.response.data,
        });
      });
  };
}

export function surveysListing(formData) {
  return (dispatch) => {
    http
      .get("survey", formData)
      .then((response) => {
        dispatch({ type: "SURVEYS_LISTING", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SURVEYS_LISTING", payload: error.response.data });
      });
  };
}

export function updateSortOrder(formData, type) {
  return async (dispatch) => {
    return http
      .put("update/row/order/" + type, formData)
      .then((response) => {
        dispatch({ type: "SORT_ORDER_UPDATE_SURVEY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SORT_ORDER_UPDATE_SURVEY",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export const getTemplateData = (templateId) => {
  return (dispatch) => {
    http
      .get("get-survey-by-id/" + templateId)
      .then((response) => {
        dispatch({ type: "SURVEY_TEMPLATE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SURVEY_TEMPLATE_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const saveTemplateData = (formData) => {
  return (dispatch) => {
    http
      .post("add-edit-survey", formData)
      .then((response) => {
        dispatch({ type: "SAVE_SURVEY_TEMPLATE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_SURVEY_TEMPLATE_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const deleteTemplate = (id) => {
  return (dispatch) => {
    http
      .delete("survey/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_SURVEY_TEMPLATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_SURVEY_TEMPLATE",
          payload: error.response.data,
        });
      });
  };
};

export const togglePublish = (id) => {
  return async (dispatch) => {
    return http
      .get("survey-published/" + id)
      .then((response) => {
        dispatch({ type: "SURVEY_PUBLISH_STATUS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SURVEY_PUBLISH_STATUS",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};
