import React from "react";
import PropTypes from "prop-types";
import { Box } from "../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { SERVICES_PERFORMED_REPORTS_ORDER_BY } from "../../../../../../api/queries/useServicesPerformedReportQuery";
import { SortAdornment } from "../../../../../../shared/SortAdornment/SortAdornment";

export function Table({ reports, order, currency }) {
  const { tCommon } = useAppTranslation.Common();

  const isEmpty = reports?.data?.length === 0 && !reports?.isSoftLoading;
  const isVisible = !reports?.isLoading && !isEmpty;

  const formatCurrency = (value) => {
    return value ? `${currency}${value}` : tCommon("symbol.longDash");
  };

  const data = reports.data.map((r) => ({
    serviceName: r.service_name || tCommon("symbol.longDash"),

    providerName: r.provider_name || tCommon("symbol.longDash"),

    category: r.category_name || tCommon("symbol.longDash"),

    unitsUsed: r.quantity_used || tCommon("symbol.longDash"),

    subTotal: formatCurrency(r.amount_sold) || tCommon("symbol.longDash"),

    itemDiscount:
      formatCurrency(r.amount_discount) || tCommon("symbol.longDash"),

    netAmount: formatCurrency(r.net_amount) || tCommon("symbol.longDash"),

    taxableAmount:
      formatCurrency(r.taxable_amount) || tCommon("symbol.longDash"),

    customDiscount:
      formatCurrency(r.custom_discount) || tCommon("symbol.longDash"),

    taxesOwed: formatCurrency(r.total_tax_amount) || tCommon("symbol.longDash"),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box>
      {reports.isLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            className="font-14"
            data={data}
            cols={[
              {
                data: renderSortWrap(
                  tCommon("label.serviceName"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.serviceName,
                ),
                accessor: "serviceName",
              },
              {
                data: renderSortWrap(
                  tCommon("label.providerName"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.providerName,
                ),
                accessor: "providerName",
              },
              {
                data: renderSortWrap(
                  tCommon("label.category"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.categoryName,
                ),
                accessor: "category",
              },
              {
                data: renderSortWrap(
                  tCommon("label.unitsUsed"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.unitsUsed,
                ),
                accessor: "unitsUsed",
              },
              {
                data: renderSortWrap(
                  tCommon("label.subTotal"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.subTotal,
                ),
                accessor: "subTotal",
              },
              {
                data: renderSortWrap(
                  tCommon("label.itemDiscounts"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.amountDiscount,
                ),
                accessor: "itemDiscount",
              },
              {
                data: renderSortWrap(
                  tCommon("label.customDiscount"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.customDiscount,
                ),
                accessor: "customDiscount",
              },
              {
                data: renderSortWrap(
                  tCommon("label.netAmount"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.netAmount,
                ),
                accessor: "netAmount",
              },
              {
                data: renderSortWrap(
                  tCommon("label.taxableAmount"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.taxableAmount,
                ),
                accessor: "taxableAmount",
              },
              {
                data: renderSortWrap(
                  tCommon("label.taxesOwed"),
                  SERVICES_PERFORMED_REPORTS_ORDER_BY.taxesOwed,
                ),
                accessor: "taxesOwed",
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};
