import React, { useState } from "react";
import cx from "clsx";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "../styles.module.scss";
import { useTemplateOptions } from "../hooks/useTemplateOptions";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { ChevronLeftIcon } from "../../../../../../assets/Icons/ChevronLeftIcon";
import { useClientQuery } from "../../../../../../api/queries/useClientQuery";
import { TextEditor } from "../../../../../../shared/TextEditor/TextEditor";

const TemplateForm = ({
  content,
  onClose,
  submit,
  isLoading,
  clientId,
  setShowTemplateForm,
  form,
}) => {
  const { tInbox } = useAppTranslation.Inbox();

  const { data: client } = useClientQuery({
    clientId: parseInt(clientId || form.patient.value?.value),
  });


  return (
    <Modal
      isOpen
      footerNoBorder
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      footerClassName={cx("flex", "justify-end")}
      header={
        <div className="flex items-center">
          <Button
            variant="text"
            className="m-r-10"
            size="small"
            onClick={() => setShowTemplateForm(false)}
          >
            <ChevronLeftIcon />
          </Button>
          <Modal.Title>{`${tInbox("efaxOrders.modalAdd.title")} ${
            client ? `for ${client.fullName}` : ""
          }`}</Modal.Title>
        </div>
      }
      footer={
        <Button
          onClick={() => submit()}
          isDisabled={isLoading}
          leftAdornment={
            isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tInbox("efaxOrders.modalAdd.submit")}
        </Button>
      }
      contentClassName={styles.root}
    >
      <div className="flex-col">
        <InputLabel>{tInbox("efaxOrders.modalAdd.clinic")}</InputLabel>
        <span className="bold fontSize14px">{form.clinic.value?.label}</span>
      </div>
      <div className="flex-col">
        <InputLabel>{tInbox("efaxOrders.modalAdd.user")}</InputLabel>
        <span className="bold fontSize14px">{form.user.value?.label}</span>
      </div>
      <div className="flex-col">
        <InputLabel>{tInbox("efaxOrders.modalAdd.toNumber")}</InputLabel>
        <span className="bold fontSize14px">{form.favorites.value?.label}</span>
        <span className={styles.favoritesLabel}>
          {form.favorites.value?.faxNumber}
        </span>
      </div>
      <InputLabel>{tInbox("efaxOrders.modalAdd.labForm")}</InputLabel>

      <TextEditor
        onChange={(value) => content.onChange(value)}
        value={content.value.value}
        className={styles.textarea}
        init={{
          content_style: "p, h1, h2, h3, h4, h5, h6 { margin: 0; }"
        }}
      />
      {Boolean(content.value.error) && (
        <InputError>{content.value.error}</InputError>
      )}
    </Modal>
  );
};

export default TemplateForm;
