import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "../sass/ConfirmForm.module.scss";
import { Input } from "../../../../shared/Input/Input";
import { Button } from "../../../../shared/Button/Button";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { InputError } from "../../../../shared/InputError/InputError";

const schema = yup.object({
  code: yup
    .number()
    .required("OTP code is required")
    .typeError("OTP code should be a number"),
});

export function ConfirmForm({ resend, confirm }) {
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: ({ code }) => confirm.initiate({ code }),
  });

  return (
    <div>
      <div className={classes.label}>Please enter OTP code</div>
      <div className={classes.block}>
        <Input
          autoFocus
          name="code"
          value={values.code}
          onChange={handleChange}
          isDisabled={confirm.isLoading}
          isError={Boolean(errors.code)}
        />
        <Button
          isDisabled={confirm.isLoading || !values.code}
          onClick={handleSubmit}
          className={classes.confirmBtn}
          leftAdornment={
            confirm.isLoading && <CircularProgress color="white" size="small" />
          }
        >
          Continue
        </Button>
      </div>
      <button
        type="button"
        className={cx(classes.resendBtn, {
          [classes.resendBtnDisabled]: resend.isLoading || confirm.isLoading,
        })}
        onClick={resend.initiate}
        disabled={resend.isLoading || confirm.isLoading}
      >
        {resend.isLoading && (
          <CircularProgress className={classes.resendBtnLoader} size="tiny" />
        )}
        Resend OTP code
      </button>
      {Boolean(errors.code) && <InputError>{errors.code}</InputError>}
    </div>
  );
}

ConfirmForm.propTypes = {
  resend: PropTypes.shape({
    initiate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  confirm: PropTypes.shape({
    initiate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};
