import React, { Component } from "react";

class LeadsMessageModal extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      settingsLang: languageData.settings,
    };
  }

  render() {
    const { isOpen, cancelFn, message, title } = this.props;

    return (
      <>
        <div className={isOpen ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={isOpen ? "modal fade in displayBlock" : "modal fade"}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={cancelFn}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {title}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {message}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LeadsMessageModal;
