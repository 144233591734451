import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  id: yup.number().required(),
});

export function useQuestionnaireDeleteMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.delete(HTTP_ENDPOINTS.questionnaires.deleteOne(req.id));
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.questionnaires.getAll],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientProcedures],
        });
        options?.onSuccess?.();
      },
    },
  );
}
