const nameInitialState = {
  action: "",
  isInvoiceDataLoading: false,
  cardReadersList: [],
  allClinics: [],
  chargeCardreader: null,
  chargeStripeCardReader: { status: "loading", data: null },
  mergedInvoice: { status: "loading", data: "" },
  invoiceTips: { status: "not-started", data: "" },
  showLoader: false,
  sendEmailLoader: false,
  stripePaymentIntent: null,
};

const sales = (state = nameInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
      };
    }
    case "SALES_CATEGORY_LIST": {
      return { ...state, data: action.payload, action: "SALES_CATEGORY_LIST" };
    }
    case "SEND_SELECTED_LOCATION": {
      return {
        ...state,
        data: action.payload,
        action: "SEND_SELECTED_LOCATION",
      };
    }
    case "MONTHLY_NET_SALES_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "MONTHLY_NET_SALES_LIST",
      };
    }
    case "PAYMENT_METHOD_LIST": {
      return { ...state, data: action.payload, action: "PAYMENT_METHOD_LIST" };
    }
    case "EMPLOYEE_SALES_REPORT_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "EMPLOYEE_SALES_REPORT_LIST",
      };
    }
    case "ITEM_SALES_LIST": {
      return { ...state, data: action.payload, action: "ITEM_SALES_LIST" };
    }
    case "CATEGORY_SALES_LIST": {
      return { ...state, data: action.payload, action: "CATEGORY_SALES_LIST" };
    }
    case "EXPORT_FILE": {
      return { ...state, data: action.payload, action: "EXPORT_FILE" };
    }
    case "INVOICES_LIST": {
      return { ...state, data: action.payload, action: "INVOICES_LIST" };
    }
    case "CREATE_EDIT_INVOICES": {
      return { ...state, data: action.payload, action: "CREATE_EDIT_INVOICES" };
    }
    case "DISCOUNTS_LIST": {
      return { ...state, data: action.payload, action: "DISCOUNTS_LIST" };
    }
    case "STAF_TREATMENT_LIST": {
      return { ...state, data: action.payload, action: "STAF_TREATMENT_LIST" };
    }
    case "MEMBERSHIP_LIST": {
      return { ...state, data: action.payload, action: "MEMBERSHIP_LIST" };
    }
    case "MEMBERSHIP_CHURN_REPORT": {
      return {
        ...state,
        data: action.payload,
        action: "MEMBERSHIP_CHURN_REPORT",
      };
    }
    case "SHORT_TERM_LIABILITY_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "SHORT_TERM_LIABILITY_LIST",
      };
    }
    case "TREATMENT_PLANS_LIST": {
      return { ...state, data: action.payload, action: "TREATMENT_PLANS_LIST" };
    }
    case "TAXES_LIST": {
      return { ...state, data: action.payload, action: "TAXES_LIST" };
    }
    case "COMMISSION_REPORTS_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "COMMISSION_REPORTS_LIST",
      };
    }
    case "EGIFT_CARDS_LIST": {
      return { ...state, data: action.payload, action: "EGIFT_CARDS_LIST" };
    }
    case "COST_TO_COMPANY_LIST": {
      return { ...state, data: action.payload, action: "COST_TO_COMPANY_LIST" };
    }
    case "GET_CASH_DRAWER": {
      return { ...state, data: action.payload, action: "GET_CASH_DRAWER" };
    }
    case "CHANGE_DRAWER_LOCATION": {
      return {
        ...state,
        data: action.payload,
        action: "CHANGE_DRAWER_LOCATION",
      };
    }
    case "VIEW_OPENED_DRAWER": {
      return { ...state, data: action.payload, action: "VIEW_OPENED_DRAWER" };
    }
    case "TAKE_DRAWER_CONTROL": {
      return { ...state, data: action.payload, action: "TAKE_DRAWER_CONTROL" };
    }
    case "GET_OPEN_CASH_DRAWER": {
      return { ...state, data: action.payload, action: "GET_OPEN_CASH_DRAWER" };
    }
    case "POST_OPEN_CASH_DRAWER": {
      return {
        ...state,
        data: action.payload,
        action: "POST_OPEN_CASH_DRAWER",
      };
    }
    case "CLOSE_CASH_DRAWER": {
      return { ...state, data: action.payload, action: "CLOSE_CASH_DRAWER" };
    }
    case "CASH_IN_DRAWER": {
      return { ...state, data: action.payload, action: "CASH_IN_DRAWER" };
    }
    case "CASH_OUT_DRAWER": {
      return { ...state, data: action.payload, action: "CASH_OUT_DRAWER" };
    }
    case "BANK_DROP_DRAWER": {
      return { ...state, data: action.payload, action: "BANK_DROP_DRAWER" };
    }
    case "CASH_REGISTER_LOGS": {
      return { ...state, data: action.payload, action: "CASH_REGISTER_LOGS" };
    }
    case "GET_CASH_DRAWER_HISTORY": {
      return {
        ...state,
        data: action.payload,
        action: "GET_CASH_DRAWER_HISTORY",
      };
    }
    case "EDIT_CLOSED_DRAWER": {
      return { ...state, data: action.payload, action: "EDIT_CLOSED_DRAWER" };
    }
    case "ADD_EDIT_POPUP_RECEIPT": {
      return {
        ...state,
        data: action.payload,
        action: "ADD_EDIT_POPUP_RECEIPT",
      };
    }
    case "EXPORT_CASH_DRAWER_LOG": {
      return {
        ...state,
        data: action.payload,
        action: "EXPORT_CASH_DRAWER_LOG",
      };
    }
    case "FETCH_INVOICES": {
      return { ...state, data: action.payload, action: "FETCH_INVOICES" };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    case "FETCH_MANAGE_OFFICE": {
      return { ...state, data: action.payload, action: "FETCH_MANAGE_OFFICE" };
    }
    case "CLINIC_LIST": {
      return { ...state, data: action.payload, action: "CLINIC_LIST" };
    }
    case "ADD_OFFICE_GOALS": {
      return { ...state, data: action.payload, action: "ADD_OFFICE_GOALS" };
    }
    case "FETCH_OFFICE_GOALS": {
      return { ...state, data: action.payload, action: "FETCH_OFFICE_GOALS" };
    }
    case "FETCH_OFFICE_GOALS_NM": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_OFFICE_GOALS_NM",
      };
    }
    case "FETCH_AVAILABLE_MONTHS": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_AVAILABLE_MONTHS",
      };
    }
    case "FETCH_TIPS_PER_PROVIDER": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_TIPS_PER_PROVIDER",
      };
    }
    case "FRONTDESK_INVOICES_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "FRONTDESK_INVOICES_LIST",
      };
    }
    case "FRONTDESK_INVOICE_DATA_LOADING": {
      return { ...state, isInvoiceDataLoading: true };
    }
    case "FRONTDESK_INVOICE_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "FRONTDESK_INVOICE_DATA",
        isInvoiceDataLoading: false,
      };
    }
    case "ADD_TIP": {
      return {
        ...state,
        data: action.payload,
        action: "ADD_TIP",
        isInvoiceDataLoading: false,
      };
    }
    case "ADD_GIFT_CARD": {
      return {
        ...state,
        data: action.payload,
        action: "ADD_GIFT_CARD",
        isInvoiceDataLoading: false,
      };
    }
    case "REMOVE_GIFT_CARDS": {
      return {
        ...state,
        data: action.payload,
        action: "REMOVE_GIFT_CARDS",
        isInvoiceDataLoading: false,
      };
    }
    case "VALIDATE_GIFT_CARD": {
      return {
        ...state,
        data: action.payload,
        action: "VALIDATE_GIFT_CARD",
        isInvoiceDataLoading: false,
      };
    }
    case "CHARGE_PAYMENT": {
      return {
        ...state,
        data: action.payload,
        action: "CHARGE_PAYMENT",
        isInvoiceDataLoading: false,
      };
    }
    case "SAVE_SIGNATURE": {
      return {
        ...state,
        data: action.payload,
        action: "SAVE_SIGNATURE",
        isInvoiceDataLoading: false,
      };
    }
    case "SEND_FRONTDESK_INVOICE": {
      return {
        ...state,
        data: action.payload,
        action: "SEND_FRONTDESK_INVOICE",
        isInvoiceDataLoading: false,
      };
    }
    case "MEMBERSHIP_REVENUE_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "MEMBERSHIP_REVENUE_LIST",
      };
    }
    case "MEMBERSHIP_REVENUE_DETAIL": {
      return {
        ...state,
        data: action.payload,
        action: "MEMBERSHIP_REVENUE_DETAIL",
      };
    }
    case "MEMBERSHIP_REVENUE_EXPORT": {
      return {
        ...state,
        data: action.payload,
        action: "MEMBERSHIP_REVENUE_EXPORT",
      };
    }
    case "CLINICS_LIST": {
      return { ...state, data: action.payload, action: "CLINICS_LIST" };
    }
    case "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID",
      };
    }
    case "IS_CHERRY_AVAILABLE": {
      return { ...state, data: action.payload, action: "IS_CHERRY_AVAILABLE" };
    }
    case "GET_CARDREADERS_SUCCESS": {
      return {
        ...state,
        cardReadersList: action.payload,
        action: "GET_CARDREADERS_SUCCESS",
      };
    }
    case "ALL_CLINIC_LIST": {
      return {
        ...state,
        allClinics: action.payload,
        action: "ALL_CLINIC_LIST",
      };
    }
    case "CHARGE_PAYMENT_CARDREADER": {
      return { ...state, chargeCardreader: action.payload };
    }
    case "CHARGE_STRIPE_CARDREADER_LOADING": {
      return {
        ...state,
        chargeStripeCardReader: { status: "loading", data: null },
      };
    }
    case "CHARGE_STRIPE_CARDREADER_SUCCESS": {
      return {
        ...state,
        chargeStripeCardReader: { status: "success", data: action.payload },
      };
    }
    case "CHARGE_STRIPE_CARDREADER_ERROR": {
      return {
        ...state,
        chargeStripeCardReader: { status: "error", data: action.payload },
      };
    }
    case "MERGE_AND_CHECKOUT_INVOICE_LOADING": {
      return {
        ...state,
        showLoader: true,
        mergedInvoice: { status: "loading", data: "" },
      };
    }
    case "MERGE_AND_CHECKOUT_INVOICE_SUCCESS": {
      return {
        ...state,
        showLoader: false,
        mergedInvoice: { status: "success", data: action.payload },
      };
    }
    case "MERGE_AND_CHECKOUT_INVOICE_ERROR": {
      return {
        ...state,
        showLoader: false,
        mergedInvoice: { status: "error", data: action.payload },
      };
    }
    case "MERGED_INVOICE_TIPS_ADD_LOADING": {
      return {
        ...state,
        showLoader: true,
        invoiceTips: { status: "loading", data: "" },
      };
    }
    case "MERGED_INVOICE_TIPS_ADD_SUCCESS": {
      return {
        ...state,
        showLoader: false,
        invoiceTips: { status: "success", data: action.payload },
      };
    }
    case "MERGED_INVOICE_TIPS_ADD_ERROR": {
      return {
        ...state,
        showLoader: false,
        invoiceTips: { status: "error", data: action.payload },
      };
    }
    case "GET_STRIPE_PAYMENT_INTENT_SUCCESS": {
      return { ...state, stripePaymentIntent: action.payload.data };
    }
    default:
      return state;
  }
};

export default sales;
