import React from "react";
import { LayoutSettings } from "../../../boxes/LayoutSettings/LayoutSettings";
import { Header } from "./components/Header";
import classes from "./sass/SettingsDeletedPatients.module.scss";
import { Box } from "../../../shared/Box/Box";
import { Table } from "../../../shared/Table/Table";
import { Empty } from "../../../shared/Empty/Empty";
import { Skeleton } from "../../../shared/Skeleton/Skeleton";
import { useTableData } from "./hooks/useTableData";
import { useRestorePatients } from "./hooks/useRestorePatients";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";
import { useDeletedPatients } from "./hooks/useDeletedPatients";
import { useMultiselectEntity } from "../../../utilities/hooks/useMultiselectEntity";
import { LoadMoreObserver } from "../../../shared/LoadMoreObserver/LoadMoreObserver";
import {
  PATIENTS_PER_PAGE,
  SKELETON_HEIGHT,
} from "./SettingsDeletedPatients.consts";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function SettingsDeletedPatients() {
  const { tCommon } = useAppTranslation.Common();
  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState("");

  const {
    patients,
    isPatientsLoading,
    isNextPatientsFetching,
    fetchNextPatients,
    hasMorePatients,
    refetchPatients,
  } = useDeletedPatients({ searchTerm: debouncedSearchTerm });

  const { resetSelected, selected, ...selectHandlers } = useMultiselectEntity({
    allEntities: patients.map((p) => p.id),
  });

  const { restorePatients, isPatientsRestoring } = useRestorePatients({
    selectedPatientIds: selected,
    onSuccess: () => {
      refetchPatients();
      resetSelected();
    },
  });

  const { cols, data } = useTableData(patients, selectHandlers);

  const isDataVisible = !isPatientsLoading && data.length > 0;
  const isEmpty = !isPatientsLoading && data.length === 0;
  const isSearching = Boolean(searchTerm) && isPatientsLoading;

  return (
    <LayoutSettings contentClassName={classes.root}>
      <Header
        restoreCount={selected.length}
        onRestore={restorePatients}
        isRestoring={isPatientsRestoring}
        searchValue={searchTerm}
        onChangeSearch={setSearchTerm}
        isSearching={isSearching}
      />
      <Box elevation>
        {isPatientsLoading && !searchTerm && (
          <Skeleton
            count={PATIENTS_PER_PAGE}
            height={SKELETON_HEIGHT}
            borderRadius="0px"
          />
        )}
        {isDataVisible && <Table cols={cols} data={data} />}
        {isEmpty && <Empty size="big" position="center" />}
        {isSearching && (
          <Empty size="big" position="center">
            {tCommon("label.searching")}
          </Empty>
        )}
        {hasMorePatients && (
          <LoadMoreObserver
            noPaddingBottom
            loadMore={fetchNextPatients}
            isLoading={isNextPatientsFetching}
          />
        )}
      </Box>
    </LayoutSettings>
  );
}
