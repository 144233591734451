import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CustomDatePickerHeader from "../CustomDatePickerHeader";
import { EntityRow } from "../EntityRow";
import { DatePicker } from "../../../../shared/DatePicker/DatePicker";
import { Input } from "../../../../shared/Input/Input";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import classes from "../../sass/Event.module.scss";
import TimePicker from "../../../../shared/TimePicker/TimePicker";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { previewFormDate } from "../../Event.utils";
import { formatUTC } from "../../../../Utils/dateHelper";
import { getUser } from "../../../../utilities/localStorage";

const AppointmentDateTime = ({
  date,
  includeDates,
  onDatePickerChange,
  onDatePickerOpen,
  onDatePickerMonthChange,
  onBookingOutsideScheduleChange,
  onTimeStartSelect,
  isLoading,
  isDatePickerError,
  isDatePickerDisabled,
  isTimePickerActive,
  isTimePickerError,
  isBookingOutsideSchedule,
  timeStart,
  timeEnd,
  openToDate,
  timeStartList,
  timeStartInsideScheduleList,
  onGoToPrevDate,
  onGoToNextDate,
  isDoubleBookingAllowed,
  onDoubleBookingAllowedChange,
}) => {
  const userData = getUser();
  const datePickerRef = useRef();

  const [confirmOutOfScheduleTime, setConfirmOutOfScheduleTime] =
    useState(null);

  const userPrivilege = userData?.permissions ? userData.permissions : [];

  const handleTimeStartSelect = (nextTime) => {
    if (
      !timeStartInsideScheduleList.includes(nextTime) &&
      isBookingOutsideSchedule
    ) {
      setConfirmOutOfScheduleTime(nextTime);
    } else {
      onTimeStartSelect(nextTime);
    }
  };

  const handleConfirmOutOfScheduleTimeSelect = () => {
    if (confirmOutOfScheduleTime) {
      onTimeStartSelect(confirmOutOfScheduleTime);
      setConfirmOutOfScheduleTime(null);
    }
  };

  const isFirstOfMonth =
    moment.utc(date).startOf("month").format("YYYY-MM-DD") === date;

  const isLastOfMonth =
    moment.utc(date).endOf("month").format("YYYY-MM-DD") === date;

  return (
    <>
      <EntityRow width="none" label="Date & Time">
        <div className={classes.appointmentDateTime}>
          <div className={classes.dateTime}>
            <DatePicker
              ref={datePickerRef}
              value={date ? previewFormDate(date) : null}
              onChange={onDatePickerChange}
              isError={isDatePickerError}
              dateFormat="yyyy-MM-dd"
              includeDates={includeDates.map((d) =>
                formatUTC(moment.utc(d).toDate()),
              )}
              name="searchPickerDate"
              selected={date ? formatUTC(moment.utc(date).toDate()) : null}
              isDisabled={isDatePickerDisabled}
              showDisabledMonthNavigation
              onClickOutside={() =>
                !isLoading && datePickerRef.current.setOpen(false)
              }
              onChangeRaw={(e) => e.preventDefault()}
              onMonthChange={onDatePickerMonthChange}
              onCalendarOpen={onDatePickerOpen}
              openToDate={formatUTC(moment.utc(openToDate).toDate())}
              renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                <CustomDatePickerHeader
                  increaseMonth={increaseMonth}
                  date={date}
                  decreaseMonth={decreaseMonth}
                />
              )}
              className={classes.dateTimePicker}
            />
            {Boolean(timeStart) && (
              <div className={classes.dateTimeInner}>
                <Input
                  isReadonly
                  isError={isTimePickerError}
                  value={timeStart}
                  className={classes.dateTimeSelect}
                />
                <div>to</div>
                <Input
                  isReadonly
                  value={timeEnd}
                  className={classes.dateTimeSelect}
                />
              </div>
            )}
          </div>
          <div className={classes.dateTimeCheckboxes}>
            {userPrivilege.indexOf("book-outside-of-scheduled-hours") > -1 && (
              <Checkbox
                name="booking-outside-schedule"
                isChecked={isBookingOutsideSchedule}
                onChange={onBookingOutsideScheduleChange}
                label="Allow Booking Outside of Schedule Hours"
              />
            )}
            <Checkbox
              name="allow_double"
              isChecked={isDoubleBookingAllowed}
              onChange={() =>
                onDoubleBookingAllowedChange(!isDoubleBookingAllowed)
              }
              label="Allow Double Booking"
            />
          </div>
          {isTimePickerActive && (
            <TimePicker
              searchPickerDate={moment.utc(date).format("D MMMM YYYY")}
              timeError={isTimePickerError}
              providerTime={timeStartList}
              timeInsideSchedule={timeStartInsideScheduleList}
              actualTime={timeStart}
              prevChangeDate={onGoToPrevDate}
              nextChangeDate={onGoToNextDate}
              onTimeslotClick={handleTimeStartSelect}
              onOSHTimeslotClick={handleTimeStartSelect}
              disablePrevDayNavigation={
                isDatePickerDisabled ||
                date === includeDates[0] ||
                isFirstOfMonth
              }
              disableNextDayNavigation={
                isDatePickerDisabled ||
                date === includeDates[includeDates.length - 1] ||
                isLastOfMonth
              }
              className={classes.appointmentDateTimePicker}
            />
          )}
        </div>
      </EntityRow>
      {Boolean(confirmOutOfScheduleTime) && (
        <ConfirmModal
          isOpen={Boolean(confirmOutOfScheduleTime)}
          onConfirm={handleConfirmOutOfScheduleTimeSelect}
          onCancel={() => {
            setConfirmOutOfScheduleTime(null);
          }}
          onClose={() => {
            setConfirmOutOfScheduleTime(null);
          }}
        >
          {`You are attempting to book outside the Provider's schedule hours.
          Click confirm to acknowledge and proceed.`}
        </ConfirmModal>
      )}
    </>
  );
};

AppointmentDateTime.propTypes = {
  date: PropTypes.string,
  includeDates: PropTypes.array.isRequired,
  onDatePickerChange: PropTypes.func.isRequired,
  onDatePickerOpen: PropTypes.func.isRequired,
  onDatePickerMonthChange: PropTypes.func.isRequired,
  onBookingOutsideScheduleChange: PropTypes.func.isRequired,
  onTimeStartSelect: PropTypes.func.isRequired,
  onGoToPrevDate: PropTypes.func.isRequired,
  onGoToNextDate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDatePickerError: PropTypes.bool,
  isDatePickerDisabled: PropTypes.bool,
  isTimePickerActive: PropTypes.bool,
  isTimePickerError: PropTypes.bool,
  isBookingOutsideSchedule: PropTypes.bool.isRequired,
  timeStart: PropTypes.string.isRequired,
  timeEnd: PropTypes.string.isRequired,
  openToDate: PropTypes.string.isRequired,
  timeStartList: PropTypes.array,
  timeStartInsideScheduleList: PropTypes.array.isRequired,
};

AppointmentDateTime.defaultProps = {
  date: null,
  isError: false,
  isLoading: false,
  isDatePickerDisabled: false,
  isTimePickerActive: true,
  isTimePickerError: false,
  timeStartList: [],
};

export default AppointmentDateTime;
