import React from "react";
import PropTypes from "prop-types";
import { Box } from "../../../../../../shared/Box/Box";
import { InputSearch } from "../../../../../../boxes/InputSearch/InputSearch";
import classes from "./Header.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function Header({ search, clinicName }) {
  const { tInbox } = useAppTranslation.Inbox();

  return (
    <Box className={classes.root}>
      <div className={classes.left}>
        <div className={classes.title}>{tInbox("leadAr.title")}</div>
        {clinicName && (
          <span className={classes.clinicName}>for {clinicName}</span>
        )}
      </div>
      <InputSearch value={search.value} onChange={search.onChange} />
    </Box>
  );
}

Header.propTypes = {
  search: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  clinicName: PropTypes.string,
};
