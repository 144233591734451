import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../../../../shared/Button/Button";
import { svg } from "../../../../../../../../../assets/svg";
import { DateRangePicker } from "../../../../../../../../../shared/DateRangePicker/DateRangePicker";
import { CircularProgress } from "../../../../../../../../../shared/CircularProgress/CircularProgress";
import { ReportHeader } from "../../../../../../../components/ReportHeader/ReportHeader";
import { useCurrentUserQuery } from "../../../../../../../../../api/queries/useUserQuery";
import { STATIC_RANGES } from "../../../../../../../../../shared/DateRangePicker/DateRangePicker.consts";

export function Header({ total, period, downloadExcel, isReportsLoading }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader
      withBackBtn
      label={tBi("liabilityReports.expired.label")}
      left={total}
    >
      <DateRangePicker
        size="small"
        isDisabled={isReportsLoading}
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
        staticRanges={[STATIC_RANGES.mtd, STATIC_RANGES.ytd, STATIC_RANGES.qtd]}
      />
      <Button
        size="small"
        variant="outlined"
        onClick={downloadExcel.initiate}
        isDisabled={downloadExcel.isLoading || isReportsLoading}
        leftAdornment={
          downloadExcel.isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <img src={svg.downloadPrimary} alt="dl" width="20px" />
          )
        }
      >
        {tCommon("label.downloadExcel")}
      </Button>
    </ReportHeader>
  );
}

Header.propTypes = {
  period: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    update: PropTypes.func,
  }).isRequired,
  downloadExcel: PropTypes.shape({
    initiate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
};
