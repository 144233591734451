import React from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { history } from "../../../../../../history";
import { StaticReportsBox } from "../../../../components/StaticReportsBox/StaticReportsBox";
import { Box } from "../../../../../../shared/Box/Box";
import { BI_ROUTES } from "../../../../../../consts/routes";

export function StaticReports() {
  const { tBi } = useAppTranslation.BusinessInsights();

  const reports = [
    {
      key: 1,
      label: tBi("liabilityReports.expired.label"),
      isVisible: true,
      onClick: () => history.push(BI_ROUTES.sales.expiredLiabilityReport()),
    },
    {
      key: 2,
      label: tBi("liabilityReports.expiring.label"),
      isVisible: true,
      onClick: () => history.push(BI_ROUTES.sales.expiringLiabilityReport()),
    },
  ];

  return <StaticReportsBox noLabel reports={reports} Wrapper={Box} />;
}
