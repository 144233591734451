import { useCallback, useEffect, useState } from "react";
import { QUESTIONNAIRE_TYPES } from "../../../../../../consts/api";
import { removeNullishFromShape } from "../../../../../../utilities/general";

export function useAnswers(patientQuestionnaire, idToAdaptedQuestionMap) {
  var [choices, setChoices] = useState({});
  var [reasons, setReasons] = useState({});

  var [isInitialized, setIsInitialized] = useState(false);

  var updateAnswer = (type) => (questionId, value) => {
    var typeToUpdater = {
      choices: setChoices,
      reason: setReasons,
    };

    typeToUpdater[type]((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  var getAnswers = useCallback(
    () =>
      Object.keys(idToAdaptedQuestionMap).reduce(
        (carry, questionId) => ({
          ...carry,
          [questionId]: {
            choices: choices[questionId] || [],
            reason: reasons[questionId] || null,
          },
        }),
        {},
      ),
    [choices, reasons, idToAdaptedQuestionMap],
  );

  useEffect(() => {
    if (patientQuestionnaire && !isInitialized) {
      var questionnaireType = patientQuestionnaire?.questionnaire?.type;

      if (!Object.values(QUESTIONNAIRE_TYPES).includes(questionnaireType)) {
        return;
      }

      var answers = patientQuestionnaire?.answers || [];

      setChoices(
        answers.reduce((carry, answer) => {
          if (answer.question_id) {
            return removeNullishFromShape({
              ...carry,
              [answer.question_id]: answer.answer || [],
            });
          }
          return carry;
        }, {}),
      );

      setReasons(
        answers.reduce((carry, answer) => {
          if (answer.question_id) {
            return removeNullishFromShape({
              ...carry,
              [answer.question_id]: answer.comment || null,
            });
          }
          return carry;
        }, {}),
      );

      setIsInitialized(true);
    }
  }, [
    isInitialized,
    patientQuestionnaire?.questionnaire?.type,
    patientQuestionnaire?.answers,
  ]);

  return {
    choices: {
      value: choices,
      update: updateAnswer("choices"),
    },
    reasons: {
      value: reasons,
      update: updateAnswer("reason"),
    },
    getAnswers,
  };
}
