import React from "react";
import drilldown from "highcharts-drilldown";
import Highcharts from "highcharts";

drilldown(Highcharts);

class PreviousVsCurrentPeriod extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.inventoryUsedChart = this.createChart(
      "inventoryUsedChartContainer",
      "Inventory Used",
    );
    this.inventorySoldChart = this.createChart(
      "inventorySoldChartContainer",
      "Inventory Sold",
    );
    this.inventoryShelfChart = this.createChart(
      "inventoryShelfChartContainer",
      "Inventory On The Shelf",
    );
  }

  getFormattedChartValues = () => {
    const inventoryUsed = [
      {
        name: "Previous Period",
        data: [0],
      },
      {
        name: "Current Period",
        data: [0],
      },
    ];
    const inventorySold = [
      {
        name: "Previous Period",
        data: [0],
      },
      {
        name: "Current Period",
        data: [0],
      },
    ];
    const inventoryShelf = [
      {
        name: "Previous Period",
        data: [0],
      },
      {
        name: "Current Period",
        data: [0],
      },
    ];
    const data = this.props.data;
    if (data && data[0]) {
      const d = data[0];
      inventorySold[0].data[0] =
        Number.parseFloat(d.bi_previous_year_report.quantity_sold) || 0;
      inventorySold[1].data[0] =
        Number.parseFloat(d.bi_this_year_report.quantity_sold) || 0;

      inventoryUsed[0].data[0] =
        Number.parseFloat(d.bi_previous_year_report.quantity_used) || 0;
      inventoryUsed[1].data[0] =
        Number.parseFloat(d.bi_this_year_report.quantity_used) || 0;

      inventoryShelf[0].data[0] =
        Number.parseFloat(d.bi_previous_year_report.quantity_on_shelf) || 0;
      inventoryShelf[1].data[0] =
        Number.parseFloat(d.bi_this_year_report.quantity_on_shelf) || 0;
    }
    return { inventoryUsed, inventorySold, inventoryShelf };
  };

  componentDidUpdate() {
    const data = this.getFormattedChartValues();
    this.inventoryUsedChart.update({ series: data.inventoryUsed });
    this.inventorySoldChart.update({ series: data.inventorySold });
    this.inventoryShelfChart.update({ series: data.inventoryShelf });
  }

  createChart = (elementId, title) => {
    return Highcharts.chart(elementId, {
      chart: {
        type: "column",
        height: 350,
      },
      title: {
        text: title,
      },
      legend: {
        layout: "horizontal",
        align: "right",
        verticalAlign: "top",
        x: -20,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        itemMarginBottom: 10,
        itemMarginTop: 10,
        symbolRadius: 3,
        squareSymbol: false,
        symbolHeight: 16,
        symbolWidth: 16,
        borderColor: "#FFFFFF",
        itemStyle: {
          fontSize: "16px",
          fontWeight: "normal",
        },
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: "Period",
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.25,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Previous Period",
          data: [0],
        },
        {
          name: "Current Period",
          data: [0],
        },
      ],
    });
  };

  render() {
    return (
      <div
        className="business-insight-graph previous-vs-current-year-graph"
        id="previousVsCurrentPeriodGraph"
        data-highcharts-chart="0"
      >
        <div
          className="business-insight-graph-container"
          id="inventoryUsedChartContainer"
        />
        <div
          className="business-insight-graph-container"
          id="inventorySoldChartContainer"
        />
        <div
          className="business-insight-graph-container"
          id="inventoryShelfChartContainer"
        />
      </div>
    );
  }
}

export default PreviousVsCurrentPeriod;
