import { squashSpaces, unwrapOr } from "../../utilities/general";
import { isWorkspaceStatusInactive } from "../../utilities/api";

export const groupByNameFirstLetter = (apiWorkspaces) => {
  if (Array.isArray(apiWorkspaces) && apiWorkspaces.length > 0) {
    return apiWorkspaces.reduce((res, w) => {
      const key = unwrapOr(() => squashSpaces(w.name)[0].toUpperCase(), null);
      if (typeof key === "string") {
        const keyWorkspaces = res[key] || [];
        return {
          ...res,
          [key]: [...keyWorkspaces, w],
        };
      }
      return res;
    }, {});
  }
  return {};
};

export const sortGroupedAlphabetically = (groupedByFirstLetter) => {
  const sortedKeys = Object.keys(groupedByFirstLetter).sort();
  return sortedKeys.reduce(
    (r, k) => ({ ...r, [k]: groupedByFirstLetter[k] }),
    {},
  );
};

export const searchInGroupedEntry = (result, groupedEntry, searchTerm) => {
  const [key, workspaces] = groupedEntry;
  const nextWorkspaces = workspaces.filter((w) =>
    w.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  if (nextWorkspaces.length > 0) {
    return [...result, [key, nextWorkspaces]];
  }
  return result;
};

export const excludeInactiveInGroupedEntry = ({
  result,
  groupedEntry,
  isInactivePredicate,
}) => {
  const [key, workspaces] = groupedEntry;
  const nextWorkspaces = workspaces.filter((w) => !isInactivePredicate(w));
  if (nextWorkspaces.length > 0) {
    return [...result, [key, nextWorkspaces]];
  }
  return result;
};

export const implodeGroupedEntries = (acc, groupedEntry) => ({
  ...acc,
  [groupedEntry[0]]: groupedEntry[1],
});

export const isWorkspaceInactive = (workspace) => {
  return isWorkspaceStatusInactive(workspace.status);
};
