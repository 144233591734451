import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchEGiftCardData,exportEmptyData, updateEGiftCard, createEGiftCard, deleteEGiftCard } from '../../../../Actions/Inventory/inventoryActions.js';
import { withRouter } from 'react-router';
import moment from 'moment';
import { numberFormat, getCurrencySymbol,isFormSubmit, getDateFormat,dateFormatPicker, showFormattedDate, autoScrolling, isNumber, toggleBodyScroll } from '../../../../Utils/services.js'
import DatePicker from "react-datepicker";
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../../../Components/Common/Loader.js'
import { ObOrder } from "./components/ObOrder/ObOrder.js";
import { uiNotification } from "../../../../services/UINotificationService.js";


class EGiftCard extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0)
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      loadMore: true,
      startFresh: true,
      showLoader: false,
      showModal: false,
      id: userData.user.id,
      page: 1,
      pagesize: 20,
      term: '',
      hasMoreItems: true,
      next_page_url: '',
      searchFunction: '',
      user_changed: false,
      tabClicked: false,
      EGiftCard: [],
      data: [],
      action: props.match.params.statusId,
      sortorder: 'asc',
      sortby: 'name',
			scopes: 'category',
      selected: [],
      selectAll: 0,
      categoryName: '',
      childCheck: false,
      show_below_stock: 0,
      name: '',
      custom_amount: 0,
      amount: '',
      price: '',
      active_from: new Date(),
      active_till: null ,//new Date(moment().add(1, 'days')),
      active_till_min_date: new Date(moment().add(1, 'days')),
      temp_active_from: new Date(),
      temp_active_till: null, //new Date(moment().add(1, 'days')),
      validity_period: '12',
      policy: '',

      globalLang: languageData.global,
      //dashboardLang: languageData.dashboard,
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      showLoadingText : false,
      showGiftCardData : false,
      is_available_online: 0,
      userData: userData,
      obPrivilegeEnabled: userData.permissions.includes('booking-portal-configuration'),
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    //window.scrollTo(0, 0);
    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <=5 && this.state.next_page_url != null) {
        this.loadMore();
      }
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let returnState = {};
    returnState[event.target.name] = value;

    if(target.name == "amount" || target.name == "custom_amount") returnState['price'] = (value != 'custom') ? value : ""

    this.setState(returnState);
  };

  componentDidMount() {
    this.props.exportEmptyData()
    let formData = {
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: 'asc',
        sortby: 'name',
        term: this.state.term,
        action: this.state.action,
        is_available_online: this.state.is_available_online
      }
    };
    this.setState({ 'showLoader': true });
    //document.addEventListener('click', this.handleClick, false);
    const datePicker1=document.getElementsByClassName("react-datepicker__input-container")[0];
    datePicker1.childNodes[0].setAttribute("readOnly",true);
    const datePicker2=document.getElementsByClassName("react-datepicker__input-container")[1];
    datePicker2.childNodes[0].setAttribute("readOnly",true);
    autoScrolling(true);
    this.props.fetchEGiftCardData(formData);
    toggleBodyScroll(false);
  }


  handleClick = (e) => {
    if (this.refDatePickerContainer &&!this.refDatePickerContainer.contains(e.target)) {
      this.refDatePicker.setOpen(false);
    }
    if (this.refRepeatDatePickerContainer && !this.refRepeatDatePickerContainer.contains(e.target)) {
      this.refRepeatDatePicker.setOpen(false);
    }
  }

  onSort = (sortby) => {
    let sortorder = (sortby == this.state.sortby) ? (this.state.sortorder === 'asc') ? 'desc' : 'asc' : 'asc';
    let formData = {'params':{
      page:1,
      pagesize:this.state.pagesize,
      sortby:sortby,
      sortorder: sortorder,
      term:this.state.term
      }
    }
    this.setState({
      page:1,
      pagesize:this.state.pagesize,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url:'',
      EGiftCard : []
    });
    localStorage.setItem('sortOnly', true);
    autoScrolling(true);
    this.props.fetchEGiftCardData(formData);
  }

  loadMore = () => {
    if(!autoScrolling()){
      localStorage.setItem("sortOnly", false);
      this.setState({ 'loadMore': true, startFresh: true, showLoader: false, showLoadingText: true });
      let formData = {
        'params': {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortorder: this.state.sortorder && this.state.sortorder === 'asc' ? 'asc' : this.state.sortorder == 'desc' ? 'desc' : 'asc',
          sortby: this.state.sortby,
  				term: this.state.term,
          action: this.state.action
  			//	scopes : this.state.scopes
        }
      };
      this.setState({ 'showLoader': true });
      autoScrolling(true);
      this.props.fetchEGiftCardData(formData);
    }
  };

  addEgiftCard = () => {
    toggleBodyScroll(true);
    autoScrolling(true);
    let returnState = {};
    returnState.name = '';
    returnState.price = '';
    returnState.custom_amount = 0;
    returnState.amount = '';
    returnState.active_from = new Date();
    returnState.active_till = null;//new Date(moment().add(1, 'days')),
    returnState.active_till_min_date = new Date(moment().add(1, 'days'));
    returnState.temp_active_from = new Date();
    returnState.temp_active_till = null; //new Date(moment().add(1, 'days')),
    returnState.validity_period = '12';
    returnState.policy = '';
    returnState.showGiftCardData = true;
    returnState.giftCardData = null;
    returnState.is_available_online = 0;
    this.setState(returnState)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.showLoader != undefined && nextProps.showLoader == false){
      nextProps.exportEmptyData();
      return {showLoader:false}
    }
    if (
         nextProps.EGiftCard && nextProps.EGiftCardTimeStamp != prevState.EGiftCardTimeStamp
    ) {


      nextProps.exportEmptyData();
      let returnState = {};
      returnState.showLoader = false;
      returnState.EGiftCardTimeStamp = nextProps.EGiftCardTimeStamp
      /*if (prevState.next_page_url == null) {
        localStorage.setItem("sortOnly", false);
        returnState.next_page_url = null
        autoScrolling(false);
        return returnState;
      }*/
      if ((prevState.EGiftCard.length == 0 && prevState.startFresh == true) || nextProps.afterProcess) {
          returnState.EGiftCard = nextProps.EGiftCard.data;
          if(nextProps.EGiftCard.next_page_url != null){
            returnState.page = prevState.page + 1;
          }
          returnState.next_page_url = nextProps.EGiftCard.next_page_url;
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
      } else if (
        prevState.EGiftCard != nextProps.EGiftCard.data &&
        prevState.EGiftCard.length != 0
      ) {
        returnState.EGiftCard = [
          ...prevState.EGiftCard,
          ...nextProps.EGiftCard.data
        ];
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.EGiftCard.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.name = '';
        returnState.price = '';
        returnState.custom_amount = 0;
        returnState.amount = '';
        returnState.active_from = new Date();
        returnState.active_till = null;//new Date(moment().add(1, 'days')),
        returnState.active_till_min_date = new Date(moment().add(1, 'days'));
        returnState.temp_active_from = new Date();
        returnState.temp_active_till = null; //new Date(moment().add(1, 'days')),
        returnState.validity_period = '12';
        returnState.policy = '';

      }
      if((returnState.page && returnState.page > 0) || returnState.next_page_url == null ){
        returnState.showGiftCardData = false;
      }
      autoScrolling(false);

      toggleBodyScroll(false);


      return returnState;
    }else if (nextProps.redirect != undefined && nextProps.redirect == true) {
      uiNotification.success(nextProps.message);
      return { showLoader: false, showGiftCardData: false }
    } else if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false }
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

/*  componentWillUnmount = () => {
    window.onscroll = () => {
      return false;
    }
  }*/

  inventoryEdit = (id)=> {
       //localStorage.setItem('userID', id)
       /*return (
         <div className = "no-display" >
           {this.props.history.push(`/settings/e-giftcards/${id}/edit`)}
         </div>
       );*/
       let giftCardData = this.state.EGiftCard.find(e => e.id == id);
       let returnState = {}
        if(giftCardData){
          toggleBodyScroll(true);
          autoScrolling(true);
          returnState.name = giftCardData.name
          returnState.price = (giftCardData.amount) ? giftCardData.amount : 0;
          returnState.amount = (giftCardData.type == 'custom') ? 'custom' : giftCardData.price;
          returnState.custom_amount = (giftCardData.price) ? giftCardData.price : 0;
          returnState.active_from =  moment(giftCardData.active_from).toDate()
          returnState.temp_active_from = returnState.active_from;
          returnState.active_till = moment(giftCardData.active_till).toDate()
          returnState.temp_active_till = returnState.active_till;
          returnState.active_till_min_date = (returnState.active_from) ? new Date(moment(returnState.active_from).add(1, 'days')) : new Date()
          returnState.validity_period = giftCardData.validity_period
          returnState.policy = giftCardData.policy
          returnState.showGiftCardData = true;
          returnState.giftCardData = giftCardData;
          returnState.is_available_online = giftCardData.is_available_online;
          this.setState(returnState)
        }

     }

     handleSubmit = event => {
       event.preventDefault();
   		localStorage.setItem('sortOnly', true);
       let formData = {
         'params': {
           page: 1,
           pagesize: this.state.pagesize,
           sortorder: 'asc',
           sortby: 'name',
   				term: this.state.term,
         }
       };
       this.setState({
         page: 1,
         pagesize: this.state.pagesize,
         sortorder: 'asc',
         sortby: 'name',
         startFresh: true,
         loadMore: true,
         next_page_url: "",
         EGiftCard: [],
         showLoader: true,
         filterValue: false
       });
       autoScrolling(true);
       this.props.fetchEGiftCardData(formData);
     };

  handleSave = (event, value) => {
    if (isFormSubmit()) {
      const id = (this.state.giftCardData) ? this.state.giftCardData.id : null;
      let error = false;
      event.preventDefault();
      localStorage.setItem('sortOnly', true);

      this.setState({
        name_Error: false,
        price_Error: false,
        amount_Error: false,
        active_from_Error: false,
        active_till_Error: false,
        validity_period_Error: false,
        policy_Error: false,
        custom_amount_Error: false
      });

      if (typeof this.state.name === undefined || this.state.name === null || this.state.name === '') {
        this.setState({
          name_Error: true
        })
        error = true;
      }
      if (typeof this.state.price === undefined || this.state.price === null || this.state.price === '') {
        this.setState({
          price_Error: true
        })
        error = true;
      } else if(!isNumber(this.state.price)) {
         this.setState({
          price_Error: true
        })
        error = true;
      }
      if (typeof this.state.amount === undefined || this.state.amount === null || this.state.amount === '') {
        this.setState({
          amount_Error: true
        })
        error = true;
      }
      if (typeof this.state.active_from === undefined || this.state.active_from === null || this.state.active_from === '') {
        this.setState({
          active_from_Error: true
        })
        error = true;
      }
      if (typeof this.state.active_till === undefined || this.state.active_till === null || this.state.active_till === '') {
        this.setState({
          active_till_Error: true
        })
        error = true;
      }
      if (typeof this.state.validity_period === undefined || this.state.validity_period === null || this.state.validity_period === '') {
        this.setState({
          validity_period_Error: true
        })
        error = true;
      }
      if (typeof this.state.policy === undefined || this.state.policy === null || this.state.policy === '') {
        this.setState({
          policy_Error: true
        })
        error = true;
      }
      if (this.state.amount === 'custom' && !isNumber(this.state.custom_amount)) {
        this.setState({
          custom_amount_Error: true
        })
        error = true;
      }
      //active_from_should_be_less_than_active_till
      if (error === true) {
        return;
      }

      let formData = {
        name: this.state.name,
        amount: this.state.price,
        price: this.state.amount === 'custom' ? this.state.custom_amount : this.state.amount,
        active_from: moment(this.state.active_from).format("YYYY-MM-DD"),
        active_till: moment(this.state.active_till).format("YYYY-MM-DD"),
        validity_period: this.state.validity_period,
        policy: this.state.policy,
        is_available_online: (this.state.is_available_online) ? 1 : 0,
        //type: this.state.amount === 'custom' ? 'custom' : ''
      };
      if (this.state.amount == 'custom') {
        formData.type = 'custom'
      }
      this.setState({ showLoader: true, page: 1 })
      if (id) {
        this.props.updateEGiftCard(formData, id);
      }
      else {
        this.props.createEGiftCard(formData);
      }
    }
  };

  showDeleteModal = () => {
    this.setState({ showModal: true })
  }

  dismissModal = () => {

    this.setState({ showModal: false })
  }

  deleteEGiftCard = () => {
    this.dismissModal();
    this.setState({ showLoader: true, page: 1  })
    const id = (this.state.giftCardData) ? this.state.giftCardData.id : null;

    this.props.deleteEGiftCard(id);
  }

  handleChange = (date) => {
    this.setState({
      active_from: date,
    });

    // reset active_till greater than from active_from
    if (date) {
      let active_till = this.state.active_till
      if(active_till){
        if (!moment(active_till).isAfter(date)) {
          this.setState({
            active_till: new Date(moment(date).add(1, 'days')),
            active_till_min_date: new Date(moment(date).add(1, 'days'))
          });
        } else {
          this.setState({
            active_till_min_date: new Date(moment(date).add(1, 'days'))
          });
        }
      } else {
        this.setState({
          active_till_min_date: new Date(moment(date).add(1, 'days'))
        });
      }
    }
  }

  handleChangeTill = (date) => {
    this.setState({
      'active_till': date
    });
  }

  toggleDatePicker = () => {
    this.refDatePicker.setFocus(true);
    this.refDatePicker.setOpen(true);
  }

  blurDatePicker = (date) => {
    this.refDatePicker.setOpen(true);
    this.setState({ 'showDatePicker': true });
  }

  focusDatePicker = (date) => {
    this.setState({ 'showDatePicker': true });
  }

  blurRepeatDatePicker = (date) => {
    this.refRepeatDatePicker.setOpen(true);
  }

  focusRepeatDatePicker = (date) => {
    this.setState({ 'showDatePicker': true });
  }

  toggleRepeatDatePicker = () => {
    this.refRepeatDatePicker.setFocus(true);
    this.refRepeatDatePicker.setOpen(true);
  }

  dismissShowGiftCardData = () => {
    toggleBodyScroll(false);
    autoScrolling(false);
    this.setState({showGiftCardData:false})
  }

  render() {
    return (

      <div id="content">
        <div className="container-fluid content setting-wrapper">
        <Sidebar/>
          <div className="business-setion memberWalletOuter">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                eGift Cards
                    <form  onSubmit={this.handleSubmit} className="searchForm">
                      <span className="searchOuter"><i className="fa fa-search search-icon" />
                          <input className="setting-search-input search-key" placeholder="Search" name="term" autoComplete="off" value={this.state.term} onChange={this.handleInputChange} />
                      </span>
                    </form>
                  <div className="memberRightActions">
                  <a onClick={this.addEgiftCard} className="new-blue-btn pull-right m-l-10">{this.state.inventoryLang.dashboard_Add_EGift_Cards}</a>
                  <ObOrder />
                  </div>
                </div>
              </div>
          <div className="juvly-section full-width">
            <div className="table-responsive">
              <table className="table-updated setting-table no-td-border">
                <thead className="table-updated-thead">
                  <tr>
                      <th
                        className="col-xs-2 table-updated-th sorting"
                        onClick={() => this.onSort("name")}
                        data-sort="name"
                        data-order="DESC"
                      >
                        {this.state.inventoryLang.survey_nps_name}
                        <i className={(this.state.sortorder === 'asc' && this.state.sortby === 'name') ? "blue-gray" : (this.state.sortorder === 'desc' && this.state.sortby === 'name') ? "gray-blue" : "gray-gray"} />
                      </th>
                    <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.egift_card_price}</th>
                      <th
                        className="col-xs-2 table-updated-th sorting"
                        onClick={() => this.onSort("value")}
                        data-sort="value"
                        data-order="DESC"
                      >
                        {this.state.inventoryLang.egift_card_value_label}
                        <i className={(this.state.sortorder === 'asc' && this.state.sortby === 'value') ? "blue-gray" : (this.state.sortorder === 'desc' && this.state.sortby === 'value') ? "gray-blue" : "gray-gray"} />
                      </th>
                    <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.dashboard_Available_for_Purchase_on}</th>
                    <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.inventory_Available_for_purchase_until}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  this.state.EGiftCard !== undefined  && this.state.EGiftCard.map((obj, idx) => {
                    let statusId = this.state.action;
                  return (
                      <tr className="table-updated-tr" onClick = {this.inventoryEdit.bind(this, obj.id)} key= {idx} >
                        <td className="table-updated-td">{(obj.name) ? obj.name : ''}</td>
                        <td className="table-updated-td">{(obj.price) ? numberFormat(obj.price, 'currency', 2) : ''}</td>
                        <td className="table-updated-td">{(obj.amount) ? numberFormat(obj.amount, 'currency', 2) : ''}</td>
                        <td className="table-updated-td">{showFormattedDate(obj.active_from)}</td>
                        <td className="table-updated-td">{showFormattedDate(obj.active_till)}</td>
                      </tr>
                      );
                    })}
                </tbody>
              </table>
              {(this.state.showLoader === false) && <div className={(this.state.EGiftCard != undefined && this.state.EGiftCard.length == 0) ? 'no-record' : 'no-record no-display'} >
                <div className="" style={{float: "left", width: "100%", fontSize: "13px", textAlign: "center", marginTop: "0px", padding: "0px" }} >
                  {this.state.inventoryLang.dashboard_No_record_found}
                </div>
              </div>

              }
            </div>

          </div>
          <div className={(this.state.showLoadingText) ? "loading-please-wait" : "loading-please-wait no-display "}>{this.state.globalLang.loading_please_wait_text}</div>
        </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
        <div className={(this.state.showGiftCardData) ? "blackOverlay" : "blackOverlay no-display"}>
          <div className="vert-middle">
            <div className="white-popup large-popup">
              <div className="white-popup-wrapper">
              <div className="membershipTypeTitle">{(this.state.giftCardData) ? "Edit eGift Card" : "Create eGift Card"}<a onClick={this.dismissShowGiftCardData} className="popupClose"><img src="/images/popupClose.png" /></a></div>
              <div className="row">
                <div className="col-sm-12 col-xs-12">
                  <div className="juvly-subtitle m-b-20">{this.state.inventoryLang.inventory_eGift_Card_Information}</div>

                      <div className="row">
                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.inventoryLang.inventory_eGift_Card_Name} <span className="setting-require">*</span></div>
                            <input className={this.state.name_Error === true ? "newInputField field_error" : "newInputField"} type="text" name="name" value={this.state.name} placeholder="eGift Card Name" autoComplete="off" onChange={this.handleInputChange} />
                          </div>
                        </div>
                        <div className="col-sm-6  col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.inventoryLang.egift_card_price} <span className="setting-require">*</span></div>
                            <div className="row">
                              <div className="col-xs-6">
                                <select className={this.state.amount_Error === true ? "newSelectField field_error" : "newSelectField"} name="amount" value={this.state.amount} onChange={this.handleInputChange} >
                                  <option value="" >{this.state.inventoryLang.inventory_Select_Value}</option>
                                  <option value="5">{getCurrencySymbol() + '5'}</option>
                                  <option value="25">{getCurrencySymbol() + '25'}</option>
                                  <option value="50">{getCurrencySymbol() + '50'}</option>
                                  <option value="100">{getCurrencySymbol() + '100'}</option>
                                  <option value="custom">{this.state.inventoryLang.inventory_custom}</option>
                                </select>
                              </div>
                              <div className={this.state.amount === 'custom' ? "col-xs-6 no-padding-left" : "col-xs-6 no-display no-padding-left"}>
                                <input className={this.state.custom_amount_Error === true ? "newInputField field_error" : "newInputField"} type="text" name="custom_amount" value={this.state.custom_amount} min="1" autoComplete="off" onChange={this.handleInputChange} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.inventoryLang.egift_card_value} <span className="setting-require">*</span></div>
                            <input className={this.state.price_Error === true ? "newInputField field_error" : "newInputField"} type="text" name="price" value={this.state.price} placeholder="eGift Card Value" autoComplete="off" onChange={this.handleInputChange} />
                          </div>
                        </div>
                        <div className="col-sm-6  col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.inventoryLang.inventory_Available_for_purchase_on} <span className="setting-require">*</span><span className="help-icon-form" title={this.state.inventoryLang.inventory_Available_for_purchase_on_tootip} >?</span></div>
                            <div className="setting-input-outer field-icon" ref={(refDatePickerContainer) => this.refDatePickerContainer = refDatePickerContainer}>
                              <span className="client-treat-cal simple-cal-icon" onClick={this.toggleDatePicker}>

                              </span>
                              <DatePicker
                                //openToDate={(moment(this.state.active_from).isAfter(new Date())) ? this.state.active_from : moment(new Date()).toDate()}
                                className={this.state.active_from_Error === true ? "newInputField field_error" : "newInputField"}
                                selected={(this.state.active_from) ? this.state.active_from : null}
                                dateFormat={dateFormatPicker()}
                                placeholderText={dateFormatPicker().toLowerCase()}
                                onChange={this.handleChange}
                                onBlur={this.blurDatePicker}
                                onFocus={this.focusDatePicker}
                                minDate={new Date()}
                                maxDate={new Date(moment().add(10, 'years'))}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                ref={(refDatePicker) => this.refDatePicker = refDatePicker}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6  col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.inventoryLang.inventory_Available_for_purchase_until} <span className="setting-require">*</span><span className="help-icon-form" title={this.state.inventoryLang.inventory_Available_for_purchase_until_tootip}>?</span></div>
                            <div className="setting-input-outer field-icon" ref={(refRepeatDatePickerContainer) => this.refRepeatDatePickerContainer = refRepeatDatePickerContainer}>
                              <span className="client-treat-cal simple-cal-icon" onClick={this.toggleRepeatDatePicker}>

                              </span>
                              <DatePicker
                                //openToDate={(moment(this.state.active_till).isAfter(new Date())) ? this.state.active_till : moment(new Date()).toDate()}
                                className={this.state.active_till_Error === true ? "newInputField field_error" : "newInputField"}
                                selected={(this.state.active_till) ? this.state.active_till : null}
                                dateFormat={dateFormatPicker()}
                                placeholderText={dateFormatPicker().toLowerCase()}
                                onChange={this.handleChangeTill}
                                onBlur={this.blurRepeatDatePicker}
                                onFocus={this.focusRepeatDatePicker}
                                minDate={(this.state.active_till_min_date) ? this.state.active_till_min_date : new Date()}
                                maxDate={new Date(moment().add(10, 'years'))}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                ref={(refRepeatDatePicker) => this.refRepeatDatePicker = refRepeatDatePicker}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6  col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.inventoryLang.inventory_Validity_of_eGift_after} <span className="setting-require">*</span>
                              <span className="help-icon-form" title={this.state.inventoryLang.inventory_Validity_of_eGift_after_tootip}>?</span></div>
                            <select className={this.state.validity_period_Error === true ? "newInputField field_error" : "newSelectField"} name="validity_period" value={this.state.validity_period} onChange={this.handleInputChange} >
                              <option value="1">{this.state.inventoryLang.inventory_one_month}</option>
                              <option value="2">{this.state.inventoryLang.inventory_two_months}</option>
                              <option value="3">{this.state.inventoryLang.inventory_three_months}</option>
                              <option value="4">{this.state.inventoryLang.inventory_four_months}</option>
                              <option value="5">{this.state.inventoryLang.inventory_five_months}</option>
                              <option value="6">{this.state.inventoryLang.inventory_six_months}</option>
                              <option value="7">{this.state.inventoryLang.inventory_seven_months}</option>
                              <option value="8">{this.state.inventoryLang.inventory_eight_months}</option>
                              <option value="9">{this.state.inventoryLang.inventory_nine_months}</option>
                              <option value="10">{this.state.inventoryLang.inventory_ten_months}</option>
                              <option value="11">{this.state.inventoryLang.inventory_eleven_months}</option>
                              <option value="12">{this.state.inventoryLang.inventory_one_year}</option>
                              <option value="600">{this.state.inventoryLang.inventory_never}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.inventoryLang.inventory_eGift_Card_Policy}<span className="setting-require">*</span></div>
                            <textarea className={this.state.policy_Error === true ? "newtextareaField field_error" : "newtextareaField"} name="policy" placeholder="eGift Card Policy " value={this.state.policy} onChange={this.handleInputChange} />
                          </div>
                        </div>
                      </div>
                </div>
              </div>
                {Boolean(this.state.obPrivilegeEnabled) && <div className="row">
                  <div className="col-xs-12">
                    <p className="m-t-25">
                      <div className="m-b-10">Patient Portal activation is required to enable eCommerce options.</div>
                      <input id="is_available_online" type="checkbox" className="new-check" name="is_available_online" onChange={this.handleInputChange} checked={(this.state.is_available_online) ? "checked" : false}/>
                      <label htmlFor="is_available_online">Available For Purchase On Booking Portal</label>
                    </p>
                  </div>
                </div>}
              </div>
              <div className={(this.state.showModal ? 'overlay' : '')}></div>
              <div id="filterModal" role="dialog" className={(this.state.showModal ? 'modal fade in displayBlock' : 'modal fade')}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" onClick={this.dismissModal}>×</button>
                      <h4 className="modal-title" id="model_title">{this.state.inventoryLang.inventory_Confirmation_required}{this.state.showModal}</h4>
                    </div>
                    <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                      {this.state.inventoryLang.inventory_del_egift_card}
                    </div>
                    <div className="modal-footer" >
                      <div className="col-md-12 text-left" id="footer-btn">
                        <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.dismissModal}>{this.state.inventoryLang.inventory_No}</button>
                          <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.deleteEGiftCard}>{this.state.inventoryLang.inventory_Yes}</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="footer-static">
              {this.state.giftCardData &&
                <button className="new-red-btn pull-left" onClick={this.showDeleteModal}>Delete</button>
              }
                <a className="new-blue-btn pull-right" onClick={this.handleSave}>Save</a>
                <a className="new-white-btn pull-right" data-dismiss="modal" onClick={this.dismissShowGiftCardData} >Cancel</a>
              </div>


            </div>
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  let returnState = {}
  localStorage.setItem("showLoader", false);
  localStorage.setItem("sortOnly", false);
  if (state.InventoryReducer.action === "EGIFT_CARD_LIST") {
    if(state.InventoryReducer.data.status === 200){
      returnState.EGiftCard = state.InventoryReducer.data.data;
      returnState.EGiftCardTimeStamp = new Date();
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  }
  if (state.InventoryReducer.action === "CREATE_EGIFTCARD") {
    if (state.InventoryReducer.data.status === 200) {
      localStorage.setItem("sortOnly", false);
      returnState.EGiftCard = state.InventoryReducer.data.data;
      returnState.EGiftCardTimeStamp = new Date();
      returnState.afterProcess = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  }
  if (state.InventoryReducer.action === "UPDATE_EGIFTCARD") {
    if (state.InventoryReducer.data.status === 200) {
      localStorage.setItem("sortOnly", false);
      returnState.EGiftCard = state.InventoryReducer.data.data;
      returnState.EGiftCardTimeStamp = new Date();
      returnState.afterProcess = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  }
  if (state.InventoryReducer.action === "DELETE_EGIFTCARD") {
    if (state.InventoryReducer.data.status === 200) {
      localStorage.setItem("sortOnly", false);
      returnState.EGiftCard = state.InventoryReducer.data.data;
      returnState.EGiftCardTimeStamp = new Date();
      returnState.afterProcess = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);
    } else {
      uiNotification.clear();
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchEGiftCardData: fetchEGiftCardData,exportEmptyData:exportEmptyData,  updateEGiftCard: updateEGiftCard, createEGiftCard: createEGiftCard, deleteEGiftCard: deleteEGiftCard}, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EGiftCard));
