import React from "react";
import { FileParser } from "../../../../../../widgets/FileParser/FileParser";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { useForm } from "./hooks/useForm";
import { EditorFooter } from "./shared/EditorFooter/EditorFooter";
import { useEfaxTemplateCreateMutation } from "../../../../../../api/efax/useEfaxTemplateCreateMutation";
import { history } from "../../../../../../history";
import { ROUTES } from "../../../../../../consts/routes";
import { EditorFilesMobileFooter } from "./shared/EditorFilesMobileFooter/EditorFilesMobileFooter";
import { FormCreate } from "../../shared/FormCreate/FormCreate";

export const TabImport = ({ setIsFilesSelected }) => {
  const { tSettings } = useAppTranslation.Settings();
  const createMutation = useEfaxTemplateCreateMutation();
  const form = useForm(createMutation.mutateAsync);

  const handleSaveSingleFile = (file, { onRemoveFile }) => {
    form
      .submit([file])
      .then(() => {
        uiNotification.success(
          tSettings("efaxTemplates.success.createFromFile", {
            fileName: file.title,
          }),
        );
        onRemoveFile(file.id);
      })
      .catch((e) => {
        uiNotification.error(e.message);
      });
  };

  const handleSaveMultipleFiles = (files, { onRemoveAll }) => {
    if (confirm(tSettings("efaxTemplates.confirm.bulkImport"))) {
      form
        .submit(files)
        .then(() => {
          uiNotification.success(
            tSettings("efaxTemplates.success.createFromMultipleFiles"),
          );
          onRemoveAll();
          history.push(ROUTES.settings.efaxTemplates());
        })
        .catch((e) => {
          uiNotification.error(e.message);
        });
    }
  };

  return (
    <FileParser
      withEditor
      output="html"
      onError={uiNotification.error}
      renderFooter={({ isFilesSelected, onOpenEditor }) => (
        <>
          {isFilesSelected && (
            <Button variant="outlined" size="small" onClick={onOpenEditor}>
              {tSettings("efaxTemplates.openFileEditor")}
            </Button>
          )}
        </>
      )}
      footerClassName={styles.footer}
      editor={{
        isRemoveAllDisabled: form.isLoading,
        isSelectFilesDisabled: form.isLoading,
        isFilesDisabled: form.isLoading,
        onRemoveAllCb: form.resetValidationErrorLogs,
        onRemoveFileCb: form.resetValidationErrorLogs,
        renderFooter: ({
          currentFile,
          files,
          isFilePending,
          isFilesParsing,
          onRemoveFile,
          onRemoveAll,
        }) => (
          <EditorFooter
            onSubmitMultiple={() =>
              handleSaveMultipleFiles(files, {
                onRemoveAll,
              })
            }
            onSubmitSingle={() =>
              handleSaveSingleFile(currentFile, {
                onRemoveFile,
              })
            }
            currentFile={currentFile}
            files={files}
            isFilePending={isFilePending}
            isFilesParsing={isFilesParsing}
            errorLogs={form.validationErrorLogs}
            isSubmitting={form.isLoading}
          />
        ),
        renderFilesMobileFooter: ({
          files,
          isFilesParsing,
          isFilePending,
          onRemoveAll,
        }) => (
          <EditorFilesMobileFooter
            isFilePending={isFilePending}
            isFilesParsing={isFilesParsing}
            onSubmitMultiple={() =>
              handleSaveMultipleFiles(files, {
                onRemoveAll,
              })
            }
            errorLogs={form.validationErrorLogs}
            isSubmitting={form.isLoading}
          />
        ),
        renderContent: ({ currentFile, onEditFile, isFilePending }) => (
          <FormCreate
            isLoading={isFilePending || !currentFile}
            name={{
              value: currentFile?.templateName || "",
              onChange: (v) => onEditFile(currentFile?.id, { templateName: v }),
            }}
            content={{
              value: currentFile?.parsedContent || "",
              onChange: (v) =>
                onEditFile(currentFile?.id, { parsedContent: v }),
            }}
          />
        ),
        onFilesChange: (editorFiles) => {
          if (editorFiles.length) {
            setIsFilesSelected(true);
          } else {
            setIsFilesSelected(false);
          }
        },
      }}
    />
  );
};
