/**
 * @param {Array<string | number | null | undefined>} options
 * @returns {Array<string | number>}
 */
export function filterValues(options) {
  const filterPrimitives = (o) =>
    o !== null && o !== undefined && String(o)?.trim() !== "";

  const filterObjects = (o) => {
    if (typeof o === "object" && o !== null) {
      return Object.values(o).length > 0;
    }
    return true;
  };

  return [...new Set(options.filter(filterPrimitives).filter(filterObjects))];
}

/**
 * @template {Base} T
 * @param {T} value
 * @returns {{
 *    label: string,
 *    value: T
 * }}
 */
export function valueToSelectOption(value, transformLabel) {
  const preparedLabel = value ? String(value) : "";
  const label =
    typeof transformLabel === "function"
      ? transformLabel(preparedLabel)
      : preparedLabel;
  return {
    label,
    value,
  };
}
