import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./LayoutMain.module.scss";

export function LayoutMain({ children, className }) {
  return <div className={cx(classes.root, className)}>{children}</div>;
}

LayoutMain.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
  className: PropTypes.string,
};

LayoutMain.defaultProps = {
  className: undefined,
};
