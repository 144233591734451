import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { http } from "../../../../../services/HttpService";

export const useRepeatMdIntegration = (options = {}) => {
  return useQuery(
    ["repeatMdIntegration"],
    async () => {
      return http.get(
        HTTP_ENDPOINTS.repeatMdIntegration.getRepeatMdIntegration(),
      );
    },
    { ...options, cacheTime: 0, select: (data) => data.data.data },
  );
};
