import { US_STATES } from "../../consts/general";

export const GENDER_OPTIONS = [
  {
    label: "Male",
    value: 0,
  },
  {
    label: "Female",
    value: 1,
  },
  {
    label: "Prefer Not To Say",
    value: 2,
  },
];

export const STATE_OPTIONS = US_STATES.map((state) => ({
  label: state.name,
  value: state.abbr,
}));
