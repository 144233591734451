import React from "react";
import queryString from "query-string";
import moment from "moment";
import PropTypes from "prop-types";
import { Box } from "../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import classes from "../sass/Table.module.scss";
import { history } from "../../../../../../history";
import { BI_ROUTES } from "../../../../../../consts/routes";
import { API_DATE_FORMAT } from "../../../../../../consts/api";
import { removeNullishFromShape } from "../../../../../../utilities/general";

export function Table({ reports, period }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const data = reports.value.map((r, index) => ({
    key: `${r.providerId}${index}`,

    providerId: r.providerId,

    providerName: r.providerName || tCommon("symbol.longDash"),

    returningAmount: r.returningAmount || tCommon("symbol.longDash"),

    allAppointmentsAmount:
      r.allAppointmentsAmount || tCommon("symbol.longDash"),

    percentage: r.percentage || tCommon("symbol.longDash"),
  }));

  const onRowClick = (row) => {
    const fromDate = period.value.from
      ? moment(period.value.from).format(API_DATE_FORMAT)
      : null;

    const toDate = period.value.from
      ? moment(period.value.to).format(API_DATE_FORMAT)
      : null;

    const params = queryString.stringify(
      removeNullishFromShape({
        providerId: row.providerId,
        from: fromDate,
        to: toDate,
      }),
    );

    history.push(`${BI_ROUTES.retentionRateDetailsReport()}?${params}`);
  };

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={39.06} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            keyExtractor={(row) => row.id}
            data={data}
            onRowClick={onRowClick}
            cols={[
              {
                data: tBi("retentionRateReport.table.provider"),
                accessor: "providerName",
              },
              {
                data: tBi("retentionRateReport.table.returningAmount"),
                accessor: "returningAmount",
                className: classes.center,
              },
              {
                data: tBi("retentionRateReport.table.allAppointmentsAmount"),
                accessor: "allAppointmentsAmount",
                className: classes.center,
              },
              {
                data: tBi("retentionRateReport.table.percentage"),
                accessor: "percentage",
                className: classes.center,
              },
            ]}
          />
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
};
