import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { fadeIn, fadeOut } from "react-animations";
import Radium, { StyleRoot } from "radium";
import * as _ from "lodash";
import {
  checkIfPermissionAllowed,
  getIsPosEnabled,
  getIsAcceleratorPlan,
  getTimeClockSetting,
} from "../../Utils/services.js";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeOut: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeOut, "fadeOut"),
  },
};

class InsightsSideBar extends Component {
  constructor(props) {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
    super(props);
    this.state = {
      globalLang: languageData.global,
      user_type: userData && userData.user_type ? userData.user_type : "",
      isTouchMD: userData.account.account_preference.is_touch_md,
      plan_selected: userData.account.account_subscription.plan_code,
    };
  }

  manageHeaderClass = (modulePath) => {
    return window.location && window.location.pathname
      ? window.location.pathname.startsWith(modulePath)
        ? "new-setting-subtabs-a sel-submenu"
        : "new-setting-subtabs-a"
      : "new-setting-subtabs-a";
  };

  render() {
    return (
      <div className="setting-left-menu business-insight">
        <div className="setting-title">Business Insights</div>
        {this.state.isTouchMD == 1 &&
        this.state.plan_selected == "practice_booking" ? (
          <StyleRoot>
            <ul
              className="new-setting-tabs business-insight-ul"
              style={styles.fadeIn}
            >
              <li className="new-setting-tabs-li">
                <Link
                  className={this.manageHeaderClass(
                    "/business-insight/appointments",
                  )}
                  to="/business-insight/appointments"
                >
                  {" "}
                  {this.state.globalLang.header_appointments}{" "}
                </Link>
              </li>
            </ul>
          </StyleRoot>
        ) : (
          <StyleRoot>
            <ul
              className="new-setting-tabs business-insight-ul"
              style={styles.fadeIn}
            >
              {getIsPosEnabled() === true &&
                (this.state.user_type === "superadmin" ||
                  checkIfPermissionAllowed("business-insight-sales")) && (
                  <li className="new-setting-tabs-li">
                    <Link
                      className={this.manageHeaderClass(
                        "/business-insight/sales",
                      )}
                      to="/business-insight/sales"
                    >
                      {" "}
                      {this.state.globalLang.header_sales}{" "}
                    </Link>
                  </li>
                )}
              <li className="new-setting-tabs-li">
                <Link
                  className={this.manageHeaderClass(
                    "/business-insight/clients",
                  )}
                  to="/business-insight/clients"
                >
                  {" "}
                  {this.state.globalLang.header_clients}{" "}
                </Link>
              </li>
              <li className="new-setting-tabs-li">
                <Link
                  className={this.manageHeaderClass(
                    "/business-insight/inventory",
                  )}
                  to="/business-insight/inventory"
                >
                  {" "}
                  {this.state.globalLang.header_inventory}{" "}
                </Link>
              </li>
              <li className="new-setting-tabs-li">
                <Link
                  className={this.manageHeaderClass(
                    "/business-insight/appointments",
                  )}
                  to="/business-insight/appointments"
                >
                  {" "}
                  {this.state.globalLang.header_appointments}{" "}
                </Link>
              </li>

              <li className="new-setting-tabs-li">
                <Link
                  className={this.manageHeaderClass(
                    "/business-insight/reputation-management",
                  )}
                  to="/business-insight/reputation-management"
                >
                  {" "}
                  {this.state.globalLang.header_reputation_management}{" "}
                </Link>
              </li>

              <li className="new-setting-tabs-li">
                <Link
                  className={this.manageHeaderClass("/business-insight/staff")}
                  to="/business-insight/staff"
                >
                  {" "}
                  {this.state.globalLang.header_staff}{" "}
                </Link>
              </li>

              {getIsAcceleratorPlan() === true &&
                getTimeClockSetting() === true &&
                (this.state.user_type === "superadmin" ||
                  checkIfPermissionAllowed("view-time-card")) && (
                  <li className="new-setting-tabs-li">
                    <Link
                      className={this.manageHeaderClass(
                        "/business-insight/time-card",
                      )}
                      to="/business-insight/time-card"
                    >
                      {" "}
                      {"Time Card"}{" "}
                    </Link>
                  </li>
                )}
              {(this.state.user_type === "superadmin" ||
                checkIfPermissionAllowed("view-chart-audit")) && (
                <li className="new-setting-tabs-li">
                  <Link
                    className={this.manageHeaderClass(
                      "/business-insight/chart-audit",
                    )}
                    to="/business-insight/chart-audit"
                  >
                    {" "}
                    {"Chart Audit"}{" "}
                  </Link>
                </li>
              )}
            </ul>
          </StyleRoot>
        )}

        <StyleRoot></StyleRoot>
      </div>
    );
  }
}

export default withRouter(InsightsSideBar);
