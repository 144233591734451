import React from "react";
import queryString from "query-string";
import { Box } from "../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import ProductSelect from "../ProductSelect/ProductSelect";
import { Empty } from "../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EditIcon } from "../../../../../assets/Icons/EditIcon";
import { Button } from "../../../../../shared/Button/Button";
import styles from "./styles.module.scss";
import { CrossIcon } from "../../../../../assets/Icons/CrossIcon";
import { useUnbindShopifyProductMutation } from "../../../../../api/mutations/useUnbindShopifyProductMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { ClinicsSelect } from "../ClinicsSelect/ClinicsSelect";

/**
 * @param {object} param0
 * @param {{
 *    value: Array<Record<string, unknown>>;
 *    hasMore: boolean;
 *    isNextFetching: boolean;
 *    isLoading: boolean;
 *    fetchMore: () => void;
 *    isSoftLoading: boolean;
 * }} param0.data
 */
export function Table({ data, editedRows, onProductSelect, onClinicSelect }) {
  const { tInventory } = useAppTranslation.Inventory();
  const [unbindCandidate, setUnbindCandidate] = React.useState(null);
  const searchParams = queryString.parse(window.location.search);
  const [editIds, setEditIds] = React.useState([]);

  const unbind = useUnbindShopifyProductMutation({
    onSuccess: () => {
      setUnbindCandidate(null);
      uiNotification.success(tInventory("shopify_product.success.unbind"));
    },
    onError: () => {
      uiNotification.error(tInventory("shopify_product.error.unbind"));
    },
  });

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const tableData = data.value.map((i) => {
    const value =
      i.shopify_product_id && i.shopify_product_name
        ? {
            label: i.shopify_product_name,
            value: i.shopify_product_id,
          }
        : null;

    return {
      key: i.id,
      product_name: i.product_name,
      shopify_product_name:
        searchParams.mapped === "true" && !editIds.includes(i.id) ? (
          <div className={styles.shopifyNameWrapper}>
            <span>{editedRows.get(i.id)?.label || i.shopify_product_name}</span>
            <Button
              size="tiny"
              variant="text"
              leftAdornment={<EditIcon width="16px" />}
              onClick={() => {
                onProductSelect(i.id, value);
                setEditIds((prev) => [...prev, i.id]);
                onClinicSelect(
                  i.id,
                  editedRows.get(i.id)?.clinicId || i.shopify_default_clinic_id,
                );
              }}
            >
              {tInventory("shopify_product.edit")}
            </Button>
            <Button
              color="error"
              size="tiny"
              variant="text"
              leftAdornment={
                unbind.isLoading && unbindCandidate === i.id ? (
                  <CircularProgress size="small" color="red" />
                ) : (
                  <CrossIcon width="16px" />
                )
              }
              isDisabled={unbind.isLoading && unbindCandidate === i.id}
              onClick={() => setUnbindCandidate(i.id)}
            >
              {tInventory("shopify_product.unbind")}
            </Button>
          </div>
        ) : (
          <ProductSelect
            value={value}
            onSelect={(selected) => {
              onProductSelect(i.id, selected);
              if (searchParams.mapped === "true") {
                onClinicSelect(
                  i.id,
                  editedRows.get(i.id)?.clinicId || i.shopify_default_clinic_id,
                );
              }
              setEditIds((prev) => prev.filter((id) => id !== i.id));
            }}
          />
        ),
      shopify_default_clinic_id: (
        <ClinicsSelect
          value={i.shopify_default_clinic_id}
          onSelect={(selected) => {
            onClinicSelect(i.id, selected);
            if (searchParams.mapped === "true") {
              const prevValue = editedRows.get(i.id)?.value;
              const prevLabel = editedRows.get(i.id)?.label;
              onProductSelect(
                i.id,
                prevValue && prevLabel
                  ? { value: prevValue, label: prevLabel }
                  : value,
              );
            }
          }}
        />
      ),
    };
  });

  return (
    <Box className={styles.root}>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={tableData}
            cols={[
              {
                data: tInventory("shopify_product.table.name"),
                accessor: "product_name",
                headColClassName: styles.colBig,
              },
              {
                data: tInventory("shopify_product.table.shopify_product_name"),
                accessor: "shopify_product_name",
                headColClassName: styles.colSmall,
              },
              {
                data: tInventory("shopify_product.table.default_clinic"),
                accessor: "shopify_default_clinic_id",
                headColClassName: styles.colSmall,
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
      {Boolean(unbindCandidate) && (
        <ConfirmModal
          isOpen
          onClose={() => setUnbindCandidate(null)}
          onCancel={() => setUnbindCandidate(null)}
          onConfirm={() => unbind.mutate({ productId: unbindCandidate })}
          isConfirming={unbind.isLoading}
        >
          {tInventory("shopify_product.confirm.unbind")}
        </ConfirmModal>
      )}
    </Box>
  );
}
