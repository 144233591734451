import React from "react";
import { Table } from "../../../../../shared/Table/Table";

const ClinicCommissions = ({ tableData }) => {
  return (
    <Table
      bgColor="white"
      cols={[
        { data: "Clinic Name", accessor: "clinicName" },
        { data: "Total Sales", accessor: "totalSales" },
        { data: "Net Sales", accessor: "netSales" },
        {
          data: "Total Procedure Sales",
          accessor: "totalProcedureSales",
        },
        { data: "Commission Amount", accessor: "commissionAmount" },
      ]}
      data={tableData}
    />
  );
};

export default ClinicCommissions;
