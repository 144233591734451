import React from "react";
import Basicbar from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const CHART_CONTAINER_STYLES = {
  minWidth: "210px",
  maxWidth: "550px",
  height: "170px",
  margin: "0 auto",
};

export const WidgetBarChart = ({
  categories,
  valuePrefix,
  data,
  dataLabelsFormat,
}) => (
  <div
    className="highcharts-container"
    data-highcharts-chart="0"
    style={CHART_CONTAINER_STYLES}
  >
    <div className="dashboardSectionContent">
      <Basicbar
        highcharts={Highcharts}
        options={{
          chart: {
            type: "bar",
            height: 335,
            margin: [50, 50, 50, 100],
          },
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          xAxis: {
            categories,
            title: {
              text: null,
            },
          },
          yAxis: {
            min: 0,
            title: {
              text: "",
              align: "high",
            },
            labels: {
              overflow: "justify",
            },
          },
          tooltip: {
            valuePrefix,
          },
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            x: 150,
            y: 150,
            floating: true,
            borderWidth: 1,
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
            shadow: true,
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: dataLabelsFormat,
              },
            },
          },
          series: [
            {
              name: "",
              data,
              color: "#047ec3",
            },
          ],
        }}
      />
    </div>
  </div>
);
