import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../consts/api";
import { http } from "../../../services/HttpService";
import { removeNullishFromShape } from "../../../utilities/general";

export const QUESTIONNAIRE_YES_NO_ANSWERS = {
  yes: "Yes",
  no: "No",
};

const requestSchema = yup.object({
  patient_questionnaire_id: yup.number().required(),
  answers: yup.array().of(
    yup.object({
      question_id: yup.number().required(),
      question_type: yup.string().required(),
      score: yup.string().nullable(),
      answer: yup.array().of(yup.string()).required(),
      comment: yup.string().nullable(),
    }),
  ),
  step_history: yup.array().of(yup.number()).nullable(),
});

export function usePatientQuestionnaireAnswersMutation(options = {}) {
  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.patientQuestionnaires.saveAnswers(),
        removeNullishFromShape({
          ...req,
          answers: req.answers
            .filter((x) => !!x.score || x.answer?.length > 0)
            .map((answer) => ({
              ...answer,
              answer: JSON.stringify(answer.answer),
            })),
        }),
      );
    },
    {
      ...options,
    },
  );
}
