import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  product_id: yup.number().required(),
  price_list: yup.array().of(yup.mixed().required()).required(),
  regular_price: yup.boolean().nullable(),
  price_same_for_all_clinic: yup.boolean().nullable(),
});

export function useEditProductPriceListMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });

    return http.post(
      HTTP_ENDPOINTS.editProductPriceList(req.product_id),
      removeNullishFromShape({
        price_list: req.price_list,
        regular_price: req.regular_price,
        price_same_for_all_clinic: req.price_same_for_all_clinic,
      }),
    );
  }, options);
}
