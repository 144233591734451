import React from "react";
import moment from "moment";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    document.body.classList.remove("guest-body");
    const languageData = localStorage.getItem("languageData")
      ? JSON.parse(localStorage.getItem("languageData"))
      : null;

    this.state = {
      email: "",
      password: "",
      globalLang:
        languageData && languageData.global ? languageData.global : {},
      isDesktop: false,
      year: moment().format("YYYY"),
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("onload", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("onunload", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({
      isDesktop: window.innerWidth > document.documentElement.clientWidth,
    });
  }

  render() {
    return (
      <footer
        className={this.state.isDesktop ? "main-footer" : "main-footer"}
        id="protected-footer-fixed"
      >
        <div className="container-fluid">
          <span className="pull-right">
            <label>
              {this.state.globalLang !== undefined &&
              this.state.globalLang !== null
                ? "© " +
                  this.state.year +
                  " " +
                  this.state.globalLang.global_footer
                : ""}
            </label>
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
