import React, { useState } from "react";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { useDeleteProcedure } from "../../../../../../hooks/useDeleteProcedure";
import { getHealthPathParams } from "../../../../HealthCreateEdit.utils";
import SharedFooter from "../../../../../../shared/Footer/Footer";

export function Footer({
  isSubmitting,
  isFormError,
  isLoading,
  questionnaireIds,
  consentIds,
  submit,
}) {
  const { tClients } = useAppTranslation.Clients();
  const { isEditMode, procedureId } = getHealthPathParams();
  const del = useDeleteProcedure(procedureId);
  const [isSubmittingDefault, setIsSubmittingDefault] = useState(false);
  const [isSubmittingWithFill, setIsSubmittingWithFill] = useState(false);
  const [isSubmittingWithSign, setIsSubmittingWithSign] = useState(false);

  const onSubmitDefault = () => {
    setIsSubmittingDefault(true);
    submit().finally(() => {
      setIsSubmittingDefault(false);
    });
  };

  const onSubmitWithFill = () => {
    setIsSubmittingWithFill(true);
    submit({ redirectToFillQuestionnaires: true }).finally(() => {
      setIsSubmittingWithFill(false);
    });
  };

  const onSubmitWithSign = () => {
    setIsSubmittingWithSign(true);
    submit({ redirectToSignConsents: true }).finally(() => {
      setIsSubmittingWithSign(false);
    });
  };

  const additionFooterButtons = [
    {
      label: tClients("createEditHealthProcedure.submitWithQuestionnaires"),
      onClick: onSubmitWithFill,
      isLoading: isSubmittingWithFill,
      isDisabled: isSubmitting,
      isVisible: questionnaireIds.length > 0,
    },
    {
      label: tClients("createEditHealthProcedure.submitWithConsents"),
      onClick: onSubmitWithSign,
      isLoading: isSubmittingWithSign,
      isDisabled: isSubmitting,
      isVisible: consentIds.length > 0,
    },
  ];

  return (
    <SharedFooter
      onSubmit={onSubmitDefault}
      onDelete={del.initiate}
      isSubmitting={isSubmittingDefault}
      isDeleting={del.isLoading}
      isFormError={isFormError}
      isLoading={isLoading}
      isEditMode={isEditMode}
      submitLabel={tClients("createEditHealthProcedure.submit")}
      isSubmitDisabled={isSubmitting}
      additionButtons={additionFooterButtons}
    />
  );
}
