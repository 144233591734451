import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { BreadCrumbs } from "../../Components/Common";
import { EVENT_TYPES } from "../../store/event/consts";
import Loader from "./components/Loader";
import Appointment from "./containers/Appointment";
import OutOfOffice from "./containers/OutOfOffice";
import { useApiEditEvent } from "./hooks/useApiEditEvent";
import { APPOINTMENT_ROUTES } from "../../consts/routes";

const breadCrumbs = [
  { name: "Appointments", link: APPOINTMENT_ROUTES.calendar },
  { name: "Edit Event" },
];

function EditEvent({ match: { params } }) {
  const { editEvent, isEditEventFetching } = useApiEditEvent({
    eventId: params.editEventId,
  });

  return (
    <div id="content">
      <div className="container-fluid content">
        <div className="createEventOuter">
          <BreadCrumbs breadCrumbs={breadCrumbs} />
          <hr className="divider" />
          {!isEditEventFetching && editEvent.id && (
            <div className="juvly-section full-width p2030 p-t-40">
              {editEvent.type === EVENT_TYPES.appointment && (
                <Appointment
                  editEventId={editEvent.id}
                  editEventData={editEvent.appointment}
                />
              )}
              {editEvent.type === EVENT_TYPES.outOfOffice && (
                <OutOfOffice
                  editEventId={editEvent.id}
                  editEventData={editEvent.outOfOffice}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {isEditEventFetching && <Loader />}
    </div>
  );
}

EditEvent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

export default withRouter(EditEvent);
