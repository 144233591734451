/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { autoScrolling } from "../../../../Utils/services.js";

class MrrReportDetail extends Component {
  constructor(props) {
    super(props);
    autoScrolling(true);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      languageData: languageData.business_insights,
      page: 1,
      pagesize: 15,
      startFresh: true,
      next_page_url: "",
      detailData: [],
      mrrReportDetail: {},
    };

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  loadMore = () => {
    if (!autoScrolling()) {
      if (this.state.next_page_url) {
        this.setState({ showLoadingText: true });
        autoScrolling(true);
        this.props.fetchMrrReportDetail({
          detailMembershipTierId: this.props.detailMembershipTierId,
          detailMembershipName: this.props.detailMembershipName,
          detailClinicId: this.props.detailClinicId,
          detailClinicName: this.props.detailClinicName,
          page: this.state.page,
        });
      }
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.mrrReportDetail &&
      nextProps.mrrReportDetail != prevState.mrrReportDetail
    ) {
      returnState.mrrReportDetail =
        nextProps.mrrReportDetail && nextProps.mrrReportDetail.report_data
          ? nextProps.mrrReportDetail
          : prevState.mrrReportDetail;
      returnState.detailData = [
        ...prevState.detailData,
        ...nextProps.detailData,
      ];
      returnState.page = prevState.page + 1;
      returnState.next_page_url = nextProps.mrrReportDetail.next_page_url;
      returnState.showLoadingText = false;
      autoScrolling(false);
    }

    return returnState;
  }

  mrrReportBack = () => {
    this.props.mrrReportBack();
  };

  handleDetailExport = () => {
    this.props.fetchMrrReportDetail(
      {
        export_name: "mrr_report_details",
        export_type: "xls",
        detailMembershipTierId: this.props.detailMembershipTierId,
        detailMembershipName: this.props.detailMembershipName,
        detailClinicId: this.props.detailClinicId,
        detailClinicName: this.props.detailClinicName,
      },
      1,
    );
  };

  render() {
    return (
      <div className="juvly-section setting-setion m-b-10 m-t-10">
        <div className="juvly-container memberShip-revenue p-b-20">
          <div className="juvly-title no-margin">
            {this.state.languageData.bi_mrr_report +
              " in " +
              this.props.detailClinicName +
              " for " +
              this.props.detailMembershipName}{" "}
            <a
              onClick={() => this.mrrReportBack()}
              className="pull-right crossIcon m-b-10"
            >
              <img alt="" src="/images/close.png" />
            </a>
          </div>

          {this.props.detailData && this.props.detailData.length > 0 && (
            <div className="mainRightActions">
              <a
                className="easy-link relative"
                onClick={() => this.handleDetailExport()}
              >
                <i className="fa fa-download"></i> Download Excel
              </a>
            </div>
          )}
        </div>

        <div className="table-responsive clients-table">
          <table className="table-updated setting-table table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                <th className="table-updated-th">
                  {this.props.detailHeader && this.props.detailHeader.patient}
                </th>
                <th className="table-updated-th">
                  {this.props.detailHeader &&
                    this.props.detailHeader.day_of_withdrawal}
                </th>
                <th className="table-updated-th">
                  {this.props.detailHeader &&
                    this.props.detailHeader.type_of_transaction}
                </th>
                <th className="table-updated-th">
                  {this.props.detailHeader && this.props.detailHeader.amount}
                </th>
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.state.detailData &&
                this.state.detailData.length > 0 &&
                this.state.detailData.map((obj, idx) => {
                  return (
                    <tr key={idx} className="table-updated-tr">
                      <td className="table-updated-td break-all">
                        {obj.patient}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.day_of_withdrawal}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.type_of_transaction}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.amount}
                      </td>
                    </tr>
                  );
                })}

              {this.state.detailData && this.state.detailData.length === 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan="4"
                  >
                    {this.state.languageData.bi_no_record_found}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          className={
            this.state.showLoadingText
              ? "loading-please-wait no-margin-top"
              : "loading-please-wait no-margin-top no-display"
          }
        >
          {this.state.globalLang.loading_please_wait_text}
        </div>
      </div>
    );
  }
}

export default MrrReportDetail;
