/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { history } from "../../../../history";
import { BI_ROUTES } from "../../../../consts/routes";
import { ModalEditGiftCardExpiryDate } from "../../../../widgets/ModalEditGiftCardExpiryDate/ModalEditGiftCardExpiryDate";
import { ModalEditGiftCardBalance } from "../../../../widgets/ModalEditGiftCardBalance/ModalEditGiftCardBalance";
import { withPermission } from "../../../../hooks/usePermission";
import { PERMISSIONS } from "../../../../consts/api";

class EGiftCardsRevenue extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
      searchedTerm: "",
      editExpireGiftCardData: null,
      editBalanceGiftCardData: null,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData
    ) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    const isEditGiftCardBalancesPermitted =
      this.props.editGiftCardBalancesPermission.loaded &&
      this.props.editGiftCardBalancesPermission.permitted;

    return (
      <div className="table-responsive clients-table">
        <table className="table-updated setting-table min-w-900 no-td-borde no-hover ">
          <thead className="table-updated-thead">
            {
              <tr>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.name}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.purchased_on}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.from}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.to}
                </th>
                <th className="table-updated-th th-width-80">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.redemption_code}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.amount}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.price}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.balance}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.expiry_date}
                </th>
                <th className="table-updated-th">Actions</th>
              </tr>
            }
          </thead>
          <tbody className="ajax_body">
            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length > 0 &&
              this.state.reportData.report_data.map((obj, idx) => {
                return (
                  <tr key={idx} className="table-updated-tr">
                    <td className="table-updated-td">{obj.name}</td>
                    <td className="table-updated-td break-all">
                      {obj.purchased_on}
                    </td>
                    <td className="table-updated-td overflow-wrap">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: obj.from,
                        }}
                      />
                    </td>
                    <td className="table-updated-td overflow-wrap">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: obj.to,
                        }}
                      />
                    </td>
                    <td className="table-updated-td break-all">
                      {obj.redemption_code}
                    </td>
                    <td className="table-updated-td">{obj.amount}</td>
                    <td className="table-updated-td">{obj.price}</td>
                    <td className="table-updated-td">{obj.balance}</td>
                    <td className="table-updated-td break-all">
                      {obj.never_expire === 1 ? "Never" : obj.expiry_date}
                    </td>
                    <td className="table-updated-td">
                      {isEditGiftCardBalancesPermitted && (
                        <>
                          <a
                            onClick={() => {
                              this.setState({
                                editBalanceGiftCardData: {
                                  id: obj.voucher_id,
                                  name: obj.name,
                                  purchaseDate: obj.purchased_on,
                                  recipient: obj.to,
                                  expireDate: obj.expiry_date,
                                  balance: obj.balance,
                                },
                              });
                            }}
                            className="easy-link pull-right no-padding"
                          >
                            Edit Balance
                          </a>
                          <a
                            onClick={() => {
                              this.setState({
                                editExpireGiftCardData: {
                                  id: obj.voucher_id,
                                  name: obj.name,
                                  purchaseDate: obj.purchased_on,
                                  recipient: obj.to,
                                  expireDate: obj.expiry_date,
                                  balance: obj.balance,
                                },
                              });
                            }}
                            className="easy-link pull-right no-padding"
                          >
                            Expire Card
                          </a>
                          <a
                            onClick={() => {
                              history.push(
                                BI_ROUTES.giftCardHistory(obj.voucher_id),
                              );
                            }}
                            className="easy-link pull-right no-padding"
                          >
                            View History
                          </a>
                        </>
                      )}
                      <a
                        onClick={this.props.resendEGiftCardMail.bind(
                          this,
                          obj.resend_email,
                        )}
                        className="easy-link pull-right no-padding"
                      >
                        Resend Mail
                      </a>
                    </td>
                  </tr>
                );
              })}

            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length > 0 &&
              this.state.reportData.total && (
                <tr className="table-updated-tr">
                  <td className="table-updated-td bold">
                    {this.state.languageData.bi_total}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.purchased_on}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.from}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.to}
                  </td>
                  <td className="table-updated-td bold th-width-80">
                    {this.state.reportData.total.redemption_code}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.amount}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.price}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.balance}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.expiry_date}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.resend_email}
                  </td>
                </tr>
              )}

            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length === 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan="10"
                  >
                    {this.state.languageData.bi_no_record_found}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        {this.state.editExpireGiftCardData && (
          <ModalEditGiftCardExpiryDate
            isOpen
            onClose={() => this.setState({ editExpireGiftCardData: null })}
            giftCardData={this.state.editExpireGiftCardData}
            onSuccess={() => {
              this.props.refetchReport();
              this.setState({ editExpireGiftCardData: null });
            }}
          />
        )}
        {this.state.editBalanceGiftCardData && (
          <ModalEditGiftCardBalance
            isOpen
            onClose={() => this.setState({ editBalanceGiftCardData: null })}
            giftCardData={this.state.editBalanceGiftCardData}
            onSuccess={() => {
              this.props.refetchReport();
              this.setState({ editBalanceGiftCardData: null });
            }}
          />
        )}
      </div>
    );
  }
}

export default withPermission(
  PERMISSIONS.patientManagement.editGiftCardBalances,
  "editGiftCardBalancesPermission",
)(EGiftCardsRevenue);
