import React from "react";
import { Header } from "./shared/Header/Header";
import { LayoutInventory } from "../../../boxes/LayoutInventory/LayoutInventory";
import styles from "./styles.module.scss";
import { useData } from "./hooks/useData";
import { Table } from "./shared/Table/Table";

export function PricingVariation() {
  const { data, search } = useData();

  return (
    <LayoutInventory contentClassName={styles.root}>
      <Header search={search} />
      <Table data={data} />
    </LayoutInventory>
  );
}
