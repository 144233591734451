import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useQuickBooksCredentials = (options = {}) => {
  return useQuery(
    ["quickbooks-credentials"],
    async () => {
      const response = await http.get(
        HTTP_ENDPOINTS.quickBooks.getQuickBooksCredentials(),
      );
      return response.data;
    },
    options,
  );
};
