import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader.js'
import SetTimeModal from './SetTimeModal.js';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { formatInboxTime, showFormattedDate, getTimeFormat, getTimeClockSetting, checkIfPermissionAllowed, toggleBodyScroll, positionFooterCorrectly } from '../../../Utils/services.js';
import {emptyClockReducer, getClockList, changeClockStatus } from '../../../Actions/Clock/clockActions';
import { fetchClockStatus } from '../../../Actions/Clock/clockDataActions.js';
import { uiNotification } from '../../../services/UINotificationService.js';
class TimeClock extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingLang: languageData.settings,
      globalLang: languageData.global,
      term:'',
      clockListData : [],
      clockList: [],
      showLoader: true,

      showConfirmationModal: false,
      confirmationMsg: '',
      confirmationAction: '',
      modalTitle : 'Clock In',
      clock_time : moment().format('HH:mm'),
      start_time: '00:00',// : '12:00 AM',
      end_time: moment().format('HH:mm'),// : '11:55 PM',
      minute_interval : 5,
      clock_time_list:[],
      manualUserId: 0,
      manualUserName: '',
      changeClockStatusData:{},
      userClockStatusData:{},
      timeFormat : getTimeFormat()


    };
  }

  componentDidMount() {
    this.props.getClockList();
    if(!getTimeClockSetting()){
      return this.props.history.push('/settings/time-clock/settings')
    }
    positionFooterCorrectly();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {}
    if(!getTimeClockSetting()){
      return nextProps.history.push('/settings/time-clock/settings')
    }
    if (nextProps.clockListData && nextProps.clockListData !== prevState.clockListData) {
      nextProps.emptyClockReducer();
      returnState.clockListData = (nextProps.clockListData) ? nextProps.clockListData : [];
      const clockList = [];
      if(returnState.clockListData.length > 0){
        returnState.clockListData.map((obj,idx) => {
          let clock = {}
          clock.user_id = obj.id;
          clock.user_name = obj.firstname+" "+ obj.lastname;
          if(obj.clock_data){
            clock.clock_status = (obj.clock_data && obj.clock_data.clock_status ===  'in') ? 'in' : 'out';
            clock.clock_in = ((clock.clock_status === 'in' || clock.clock_status === 'out') && obj.clock_data.clock_in) ?  showFormattedDate(obj.clock_data.clock_in, true) : '';
            clock.clock_out = ((clock.clock_status === 'in' || clock.clock_status === 'out') && obj.clock_data.clock_out && obj.clock_data.clock_out !== "0000-00-00 00:00:00") ?  showFormattedDate(obj.clock_data.clock_out, true) : '';
            clock.clock_label = (clock.clock_status === 'out') ?  'Clock In' : 'Clock Out';
          } else {
            clock.clock_status = 'out';
            clock.clock_in = '';
            clock.clock_out = '';
            clock.clock_label = 'Clock In';
          }
          clockList.push(clock);
        })
      }
      returnState.clockList = clockList;
      returnState.showLoader = false
      toggleBodyScroll(false);
    } else if (nextProps.userClockStatusData && nextProps.userClockStatusData !== prevState.userClockStatusData) {
      nextProps.emptyClockReducer();
      returnState.userClockStatusData = nextProps.userClockStatusData;
      returnState.showLoader = false

      const currentDate = moment().format('YYYY-MM-DD');
      const timeFormat = getTimeFormat();
      //let start_time = (timeFormat == 'HH:mm') ? '00:00' : '12:00 AM';
      let start_time = moment().format('YYYY-MM-DD 00:00');
      let end_time = moment().format('YYYY-MM-DD HH:mm');
      let confirmationAction = 'in';
      let modalTitle = 'Clock In'
      let confirmationMsg = 'Are you sure you want to clock in manually for '+ prevState.manualUserName;

      if(returnState.userClockStatusData.id > 0 && returnState.userClockStatusData.user_id > 0){
        if(returnState.userClockStatusData.clock_status === 'in'){
          confirmationMsg = 'Are you sure you want to clock out manually for '+ prevState.manualUserName;
          confirmationAction = 'out';
          modalTitle = 'Clock Out'
          if(returnState.userClockStatusData.clock_in && returnState.userClockStatusData.clock_in !== "0000-00-00 00:00:00"){
            const clockDate = moment(returnState.userClockStatusData.clock_in).format('YYYY-MM-DD');
            if(currentDate === clockDate){
              start_time = moment(returnState.userClockStatusData.clock_in).format('YYYY-MM-DD HH:mm');//.local();
            }
          }
        } else {
          if(returnState.userClockStatusData.clock_out && returnState.userClockStatusData.clock_out !== "0000-00-00 00:00:00"){
            const clockDate = moment(returnState.userClockStatusData.clock_out).format('YYYY-MM-DD');
            if(currentDate === clockDate){
              start_time = moment(returnState.userClockStatusData.clock_out).format('YYYY-MM-DD HH:mm');
            }
          }
        }
      }
      returnState.start_time = start_time;
      returnState.end_time = end_time;
      returnState.clock_time = end_time;
      //returnState.clock_time_list = clock_time_list;

      returnState.confirmationAction = confirmationAction;
      returnState.confirmationMsg = confirmationMsg;
      returnState.modalTitle = modalTitle;
      returnState.showConfirmationModal =  true;
      toggleBodyScroll(true);
    } else if (nextProps.changeClockStatusData && nextProps.changeClockStatusData !== prevState.changeClockStatusData) {
      nextProps.emptyClockReducer();
      returnState.changeClockStatusData = nextProps.changeClockStatusData;
      let formData = {}
      if(prevState.term){
          formData = {
            params : {
              term : prevState.term
            }
          }
      }
      nextProps.getClockList(formData);
      toggleBodyScroll(false);
    } else if (nextProps.showLoader === false) {
      returnState.showLoader = false;
      nextProps.emptyClockReducer()
    }
    positionFooterCorrectly();
    return returnState;
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });
  };


  handleSubmit = event => {
    event.preventDefault();
    let formData = {}
    this.setState({showLoader:true,clockList:[]});
    if(this.state.term){
        formData = {
          params : {
            term : this.state.term
          }
        }
    }
    this.props.getClockList(formData);
  }


  handleClockAction = (obj) => {
    this.setState({showLoader:true,manualUserId: obj.user_id, modalTitle:obj.clock_label, manualUserName :obj.user_name })
    this.props.fetchClockStatus(obj.user_id);
  }

  hideMembershipTypeModal = (resetState) => {
    this.setState(resetState)
  }

  confirmationAction = () => {
    if(this.state.manualUserId){
      this.setState({showLoader:true})
      this.props.changeClockStatus({
        clock_status: this.state.confirmationAction,
        user_id:this.state.manualUserId,
        clock_time:moment(this.state.clock_time).format("YYYY-MM-DD HH:mm:00"),
        current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        time_zone : momentTimeZone.tz.guess(true)
      })
    }

  }

  onResetConfirmation = (resetState) => {
    this.setState(resetState)
    toggleBodyScroll(false);
  }

  handleChildState = (state) => {
    this.setState(state)
  }



  render() {
    return (
      <div className="main protected users-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            {(this.state.showConfirmationModal === true) && <SetTimeModal showConfirmationModal={this.state.showConfirmationModal} confirmationMsg={this.state.confirmationMsg} confirmationAction={this.confirmationAction} onResetConfirmation={this.onResetConfirmation} modalTitle={this.state.modalTitle} start_time={this.state.start_time} end_time={this.state.end_time} clock_time={this.state.clock_time} handleChildState={this.handleChildState} />
            }
            <div className="memberWalletOuter business-section">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  <span className="cursor-pointer">{this.state.settingLang.label_time_clock}</span>


                    <form onSubmit={this.handleSubmit} className="searchForm">
                      <span className="searchOuter">
                        <i className="fas fa-search" />
                        <input className="setting-search-input search-key" placeholder="Search" name="term"
                          value={this.state.term} autoComplete="off" onChange={this.handleInputChange} />
                      </span>
                    </form>

                </div>
              </div>
              <div className="table-responsive">
                <table className="table-updated setting-table no-td-border no-hover">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-3 table-updated-th" >{'Employee'}</th>
                      <th className="col-xs-1 table-updated-th" >{'Status'}</th>
                      <th className="col-xs-3 table-updated-th" >{'Clock In'}</th>
                      <th className="col-xs-3 table-updated-th" >{'Clock Out'}</th>
                      {(checkIfPermissionAllowed('clock-in-out-on-time-clock') === true) &&
                        <th className="col-xs-2 table-updated-th" >{this.state.settingLang.label_manual_action}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.clockList && this.state.clockList.length > 0 ? this.state.clockList.map((obj, idx) => {
                      return (
                        <tr key={'clockList-'+idx} className="table-updated-tr">
                          <td className="col-xs-3 table-updated-td">{obj.user_name}</td>
                          <td className="col-xs-1 table-updated-td text-uppercase">{(obj.clock_in) ? obj.clock_status : ''}</td>
                          <td className="col-xs-3 table-updated-td">{obj.clock_in}</td>
                          <td className="col-xs-3 table-updated-td">{obj.clock_out}</td>
                          {(checkIfPermissionAllowed('clock-in-out-on-time-clock') === true) &&
                            <td className="col-xs-2 table-updated-td">
                              <a className="easy-link " key={idx}
                               onClick = {this.handleClockAction.bind(this, obj)}>{obj.clock_label}</a>
                            </td>
                          }
                        </tr>
                      )
                    }) : <tr className="table-updated-tr">
                        <td colSpan='8' className="text-center table-updated-td">{this.state.globalLang.no_data_found}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.ClockReducer.action === "CLOCK_LIST") {
    if (state.ClockReducer.data.status === 200) {
      returnState.clockListData = state.ClockReducer.data.data;
    } else {
      uiNotification.clear();
      uiNotification.error(languageData.global[state.ClockReducer.data.message]);
      returnState.showLoader = false
    }
  }
  if (state.ClockReducer.action === "USER_CLOCK_STATUS") {
    if (state.ClockReducer.data.status === 200) {
      returnState.userClockStatusData = state.ClockReducer.data.data;
    } else {
      uiNotification.clear();
      uiNotification.error(languageData.global[state.ClockReducer.data.message]);
      returnState.showLoader = false
    }
  }
  if (state.ClockReducer.action === "CHANGE_SYSTEM_CLOCK_STATUS") {
    uiNotification.clear();
    if (state.ClockReducer.data.status === 200) {
      returnState.changeClockStatusData = state.ClockReducer.data.data;
      uiNotification.success(languageData.global[state.ClockReducer.data.message]);
    } else {
      uiNotification.error(languageData.global[state.ClockReducer.data.message]);
      returnState.showLoader = false
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    emptyClockReducer:emptyClockReducer,
    getClockList:getClockList,
    changeClockStatus:changeClockStatus,
    fetchClockStatus:fetchClockStatus
    }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TimeClock));
