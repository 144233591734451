import cx from "clsx";
import React, { useState, createContext, useContext } from "react";
import { checkIfPermissionAllowed } from "../../../Utils";
import { AdditionalContactInformation } from "./shared/AdditionalContactInformation/AdditionalContactInformation";
import { ClientPersonalInfo } from "./shared/ClientPersonalInfo/ClientPersonalInfo";
import { ClientAccess } from "./shared/ClientAccess/ClientAccess";
import { ClientSettings } from "./shared/ClientSettings/ClientSettings";
import { ClientFinanceInfo } from "./shared/ClientFinanceInfo/ClientFinanceInfo";
import { ClientLoyaltyProgram } from "./shared/ClientLoyaltyProgram/ClientLoyaltyProgram";
import { ClientEmergencyInfo } from "./shared/ClientEmergencyInfo/ClientEmergencyInfo";
import { ClientReferral } from "./shared/ClientReferral/ClientReferral";
import { ClientInsurance } from "./shared/ClientInsurance/ClientInsurance";
import { useClientQuery } from "../../../api/queries/useClientQuery";
import { PageOverlayLoader } from "../../../shared/PageOverlayLoader/PageOverlayLoader";
import { getPathParams } from "./utils/utils";
import styles from "./ClientInfo.module.scss";
import { history } from "../../../history";
import { ClientSkinTest } from "./shared/ClientSkinTest/ClientSkinTest";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

const InfoContext = createContext({
  showLoader: () => {},
  hideLoader: () => {},
});

export const useClientInfo = () => useContext(InfoContext);

export function ClientInfo() {
  const { clientId } = getPathParams();
  const { data: client } = useClientQuery({ clientId });
  const [showLoader, setShowLoader] = useState(false);

  const { tClients } = useAppTranslation.Clients();

  const isClientDataAndSourceRefFilled = () => {
    return [
      client?.address1,
      client?.city,
      client?.state,
      client?.country,
      client?.pinCode,
      client?.referralSource
    ].every(Boolean);
  };

  const isAdditionalInfo = () => {
    return [client?.phone2, client?.phone3, client?.email2, client?.email3].some(Boolean);
  };

  const editClientProfile = () => {
    history.push(`/clients/${clientId}/profile`);
  };

  const onShowLoader = () => {
    setShowLoader(true);
  };

  const onHideLoader = () => {
    setShowLoader(false);
  };

  return (
    <InfoContext.Provider
      value={{
        showLoader: onShowLoader,
        hideLoader: onHideLoader,
      }}
    >
      <div className="patient-left col-lg-4 merge-info">
        <div className="merge-setion">
          <div className={cx(styles.sectionTitle, "header-blue")}>
            <div className={styles.infoContainer}>
              <div className={styles.infoBlock}>
                <div className="section-title-name">{tClients("profile.label")}</div>
                {!!client?.hasAllergy && <div className={styles.allergyIcon} />}
              </div>
              <div className={styles.idSection}>
                <div className={styles.idTitle}>id:</div>
                <div className={styles.userId}>{client?.id}</div>
              </div>
            </div>
          </div>
          {!showLoader &&
            !isClientDataAndSourceRefFilled() &&
            checkIfPermissionAllowed("add-update-patients") && (
              <div className="profile-header-red">
                Address/Referral Source is missing{" "}
                <button
                  className={styles.btnRedirectToEdit}
                  onClick={editClientProfile}
                >
                  Click Here
                </button>
                To Add
              </div>
            )}
          <ClientPersonalInfo />
          <div className={styles.portalData}>
            <div className="row">
              <ClientAccess />
              <ClientSettings />
              <ClientFinanceInfo />
              <ClientLoyaltyProgram />
              {Boolean(client?.patientsSkinScore?.points) && <ClientSkinTest />}
              <ClientEmergencyInfo />
              {isAdditionalInfo() && <AdditionalContactInformation />}
              <ClientReferral />
              <ClientInsurance />
            </div>
          </div>
        </div>
      </div>
      {showLoader && (
        <PageOverlayLoader message="Loading data. Please wait..." />
      )}
    </InfoContext.Provider>
  );
}
