import { useEfaxFavouriteUpdateMutation } from "../../../../../../../api/efax/useEfaxFavouriteUpdateMutation";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";

export const useEdit = () => {
  const { tSettings } = useAppTranslation.Settings();

  const { mutateAsync, isLoading } = useEfaxFavouriteUpdateMutation({
    onError: () => {
      uiNotification.error(
        tSettings("efaxIntegration.favourites.error.update"),
      );
    },
    onSuccess: () => {
      uiNotification.success(
        tSettings("efaxIntegration.favourites.success.update"),
      );
    },
  });

  return {
    isLoading,
    initiate: ({ id, name, faxNumber, template }) =>
      mutateAsync({ id, name, faxNumber, template }),
  };
};
