import { useState } from "react";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useDeleteSalesGoalMutation } from "../../../../api/mutations/useDeleteSalesGoalMutation";

export function useDelete() {
  const { tSettings } = useAppTranslation.Settings();

  const [idsToRestore, setIdsToRestore] = useState([]);
  const [loadingIds, setLoadingIds] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { mutateAsync } = useDeleteSalesGoalMutation();

  const reset = () => {
    setIdsToRestore([]);
    setLoadingIds([]);
  };

  const declineConfirm = () => {
    setIsConfirmOpen(false);
    reset();
  };

  const initiate = async () => {
    try {
      setLoadingIds(idsToRestore);
      setIsConfirmOpen(false);
      await mutateAsync({ id: idsToRestore[0] });
      uiNotification.success(tSettings("salesGoals.success.delete"));
    } catch (e) {
      uiNotification.error(tSettings("salesGoals.error.delete"));
    } finally {
      reset();
    }
  };

  return {
    setOne: (id) => setIdsToRestore((prev) => [...prev, id]),
    reset,
    loadingIds,
    initiate,
    isConfirmOpen,
    requestConfirm: () => setIsConfirmOpen(true),
    declineConfirm,
  };
}
