import React from "react";
import { ArrowUpIcon } from "../../assets/Icons/ArrowUpIcon";
import classes from "./ButtonScrollTop.module.scss";

export const ButtonScrollTop = () => {
  return (
    <button
      className={classes.root}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <ArrowUpIcon width="24px" />
    </button>
  );
};
