import * as yup from "yup";
import { useFormik } from "formik";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { useMountClearentInput } from "../../../../../../hooks/useMountClearentInput";

export const CLEARENT_FORM_ID = "client-cc-clearent-form";

export function useClearentForm({ publicKey, billingEmail, billingZip }) {
  const { tCommon } = useAppTranslation.Common();

  const schema = yup.object({
    zip: yup.string().required(tCommon("formError.zipCodeRequired")),
    email: yup
      .string()
      .email(tCommon("formError.emailInvalid"))
      .required(tCommon("formError.emailRequired")),
  });

  const { values, handleChange, errors, validateForm } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      zip: billingZip || "",
      email: billingEmail || "",
    },
  });

  const validate = (onSuccess) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        onSuccess();
      }
    });
  };

  useMountClearentInput({
    publicKey,
    formId: CLEARENT_FORM_ID,
  });

  return {
    values,
    errors,
    handleChange,
    validate,
  };
}
