import React, { useState } from "react";
import cx from "clsx";
import { Header } from "./shared/Header/Header";
import classes from "./BlackList.module.scss";
import { useMultiselectEntity } from "../../../utilities/hooks/useMultiselectEntity";
import { Box } from "../../../shared/Box/Box";
import { Table } from "./shared/Table/Table";
import { useBlackList } from "./hooks/useBlackList";
import { useRemove } from "./hooks/useRemove";
import { useAdd } from "./hooks/useAdd";
import { AddModal } from "./shared/AddModal/AddModal";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function BlackList() {
  const { tInbox } = useAppTranslation.Inbox();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [idsToRemove, setIdsToRemove] = useState([]);

  const blackList = useBlackList();

  const selectedItems = useMultiselectEntity({
    allEntities: blackList.value.map((m) => m.id),
    initialSelect: [],
  });

  const remove = useRemove({
    onSuccess: () => {
      blackList.refetch();
      selectedItems.resetSelected();
      setIdsToRemove([]);
    },
  });

  const add = useAdd({
    onSuccess: () => {
      blackList.refetch();
      setIsAddModalOpen(false);
    },
  });

  return (
    <div className={cx("memberWalletOuter business-section", classes.root)}>
      <Header
        search={blackList.search}
        selectedLength={
          blackList.value.filter((i) => selectedItems.selected.includes(i.id))
            .length
        }
        onAdd={() => setIsAddModalOpen(true)}
        onRemoveMany={() => setIdsToRemove(selectedItems.selected)}
        isRemovingMany={remove.isLoading}
        isAdding={add.isLoading}
      />
      <Box>
        <Table
          blackList={blackList}
          selectedItems={selectedItems}
          onRemoveOne={(id) => setIdsToRemove([id])}
          removingIds={remove.inProgressIds}
        />
      </Box>
      {isAddModalOpen && (
        <AddModal
          isOpen
          onClose={() => setIsAddModalOpen(false)}
          onAdd={({ phone }) => add.initiate(phone)}
          isAdding={add.isLoading}
        />
      )}
      {idsToRemove.length > 0 && (
        <ConfirmModal
          isOpen
          onClose={() => setIdsToRemove([])}
          onCancel={() => setIdsToRemove([])}
          onConfirm={() => remove.initiate(idsToRemove)}
          isConfirming={remove.isLoading}
        >
          {tInbox("blackList.confirm.remove")}
        </ConfirmModal>
      )}
    </div>
  );
}
