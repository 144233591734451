import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const responseSchema = yup.object({
  isAvailable: yup.bool().required(),
});

const composeResponse = (res) => {
  const details = unwrapOr(() => res.data.data, {});
  return {
    isAvailable: details.cherryAvailable || false,
  };
};

function createCherryAvailabilityQueryKey() {
  return [QUERY_KEYS.cherry.availability];
}

export function useCherryAvailabilityQuery({ options = {} } = {}) {
  return useQuery(
    createCherryAvailabilityQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.cherry.getAvailability());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
