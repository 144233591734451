import React, { useState } from "react";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { getPathParams } from "./utilities";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { Box } from "../../../../shared/Box/Box";
import { Tabs } from "../../../../shared/Tabs/Tabs";
import { useQueryParamsState } from "../../../../utilities/hooks/useQueryParamsState";
import { TabImport } from "./boxes/TabImport/TabImport";
import { TabManual } from "./boxes/TabManual/TabManual";
import { history } from "../../../../history";
import { ROUTES } from "../../../../consts/routes";
import { TABS } from "./config";

export const EfaxTemplateCreateEdit = () => {
  const { templateId } = getPathParams();
  const { tSettings } = useAppTranslation.Settings();
  const [isFilesSelected, setIsFilesSelected] = useState(false);

  const [state, setState] = useQueryParamsState({
    tab: TABS.manual,
  });

  const getTitle = () => {
    if (templateId) {
      return tSettings("efaxTemplates.edit");
    }

    return tSettings("efaxTemplates.create");
  };

  const tabOptions = [
    {
      label: tSettings("efaxTemplates.createTabs.manual"),
      value: TABS.manual,
      onClick: () => {
        if (
          (isFilesSelected &&
            window.confirm(
              tSettings("efaxTemplates.confirm.leaveImportTab"),
            )) ||
          !isFilesSelected
        ) {
          setState({ tab: TABS.manual });
        }
      },
    },
    {
      label: tSettings("efaxTemplates.createTabs.import"),
      value: TABS.import,
      onClick: () => setState({ tab: TABS.import }),
    },
  ];

  return (
    <LayoutSettings contentClassName={styles.root}>
      <EntityHeader
        withBackBtn
        onBack={() => history.push(ROUTES.settings.efaxTemplates())}
        title={getTitle()}
      />
      <div className={styles.root}>
        <Box className={styles.content}>
          {templateId ? (
            <TabManual />
          ) : (
            <>
              <Tabs active={state.tab} options={tabOptions} />
              {state.tab === TABS.manual && <TabManual />}
              {state.tab === TABS.import && (
                <TabImport setIsFilesSelected={setIsFilesSelected} />
              )}
            </>
          )}
        </Box>
      </div>
    </LayoutSettings>
  );
};
