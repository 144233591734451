import { useQueryClient } from "@tanstack/react-query";
import { useSetDiscountPackagesObOrderMutation } from "../../../../../../../../api/mutations/useSetDiscountPackagesObOrderMutation";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { QUERY_KEYS } from "../../../../../../../../consts/api";

export function useSaveOrder() {
  const queryClient = useQueryClient();
  const { tSettings } = useAppTranslation.Settings();

  const { isLoading, mutate } = useSetDiscountPackagesObOrderMutation({
    onSuccess: () => {
      uiNotification.success(
        tSettings("discountPackages.obOrder.success.update"),
      );
      queryClient.invalidateQueries([QUERY_KEYS.discountPackagesObOrder]);
    },
    onError: () => {
      uiNotification.error(tSettings("discountPackages.obOrder.error.update"));
    },
  });

  const save = ({ selectedClinic, orderedPackages }) => {
    mutate({
      clinicId: selectedClinic.value,
      packageIds: orderedPackages.map((p) => p.id),
    });
  };

  return {
    save,
    isSaving: isLoading,
  };
}
