import React from "react";
import styles from "../scss/ProviderDashboard.module.scss";
import { Empty } from "../../../../shared/Empty/Empty";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";

const CardContent = ({ list, isLoading }) => {
  return (
    <div className={styles.cardContent}>
      {list?.map((item, index) => (
        <div key={index} className={styles.cardData}>
          <span>{item.name}</span>
          <span>{item.amount}</span>
        </div>
      ))}
      {isLoading && <Skeleton height={30} count={3} />}
      {list?.length === 0 && <Empty position="center">No data available</Empty>}
    </div>
  );
};

export default CardContent;
