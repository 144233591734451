import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import classes from "../../../newBusinessInsightsContent.module.scss";
import styles from "./scss/eGiftCards.module.scss";
import { useEgiftcards } from "./hooks/useEgiftcards";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import PieGraph from "./components/PieGraph";

const modalInfo = {
  title: "egiftCardSoldReport.info.title",
  rows: ["egiftCardSoldReport.info.note"],
};

const EGiftCards = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const { data, isLoading } = useEgiftcards(requestParams);

  const isReportDataEmpty = !isLoading && !data?.reportData?.length;

  const reportData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        giftCardName: data.name,
        totalPurchased: data.purchased,
        totalRedeemed: data.redemption,
        outstandingLiability: data.outstanding_liability,
      })),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="eGift Cards"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getEgiftCard()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className={styles.chartsContainer}>
        {Boolean(data?.giftCardSold?.length) && (
          <PieGraph
            title="Types of eGift Cards Sold"
            options={data?.pieCharts.giftCardsPurchased}
          />
        )}
        {Boolean(data?.giftCardRedeemed?.length) && (
          <PieGraph
            title="Types of eGift Cards Redeemed"
            options={data?.pieCharts.giftCardsRedeemed}
          />
        )}
        {Boolean(
          data?.giftCardRedeemed?.length || data?.giftCardSold.length,
        ) && (
          <PieGraph
            title="Types of eGift Cards Sold vs. Redeemed"
            totalLabel="Total Gift Card Transactions"
            total={
              data.giftCardTransactions.total_redemption +
              data.giftCardTransactions.total_sold
            }
            options={data?.pieCharts.giftCardTransactions}
          />
        )}
      </div>
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Gift Card Name", accessor: "giftCardName" },
              { data: "Total Purchased", accessor: "totalPurchased" },
              { data: "Total Redeemed", accessor: "totalRedeemed" },
              {
                data: "Outstanding Liability",
                accessor: "outstandingLiability",
              },
            ]}
            data={reportData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isReportDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default EGiftCards;
