import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./Consent.module.scss";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EyeIcon } from "../../../../../assets/Icons/EyeIcon";
import { PenLineIcon } from "../../../../../assets/Icons/PenLineIcon";
import { ModalPatientConsentSign } from "../../../../../widgets/ModalPatientConsentSign/ModalPatientConsentSign";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { Modal } from "../../../../../shared/Modal/Modal";
import { PatientConsentPreview } from "../../../../../widgets/PatientConsentPreview/PatientConsentPreview";
import { getIsPatientConsentNeedsSign } from "../../../../../utilities/api";
import { useMarkOnFile } from "../../hooks/useMarkOnFile";
import { Params } from "./boxes/Params/Params";
import { OnFileParams } from "../../../shared/OnFileParams/OnFileParams";
import { OnFileButton } from "../../../shared/OnFileButton/OnFileButton";

export function Consent({ patientConsent, refetchConsents, procedureId }) {
  const { tCommon } = useAppTranslation.Common();
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);
  const [isViewerModalOpen, setIsViewerModalOpen] = useState(false);
  const onFile = useMarkOnFile();

  const { data: patient } = useClientQuery({
    clientId: patientConsent.patient_id,
  });

  const isPatientSigned = !!patientConsent.signature_image;
  const isWitnessSigned = !!patientConsent.witness_signature;
  const isWitnessSignatureRequired = !!patientConsent.consent.witness_signature;
  const isMarkedAsOnFile = !!patientConsent.already_on_file;

  const isMarkAsOnFileAllowed =
    !isPatientSigned && !isWitnessSigned && !!procedureId;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <h3>{patientConsent.consent.consent_name}</h3>
          <Params
            isMarkedAsOnFile={isMarkedAsOnFile}
            isPatientSigned={isPatientSigned}
            isWitnessSignatureRequired={isWitnessSignatureRequired}
            isWitnessSigned={isWitnessSigned}
          />
          {isMarkedAsOnFile && (
            <OnFileParams
              date={patientConsent.already_on_file}
              userName={patientConsent.user_marked_already_on_file}
            />
          )}
        </div>
        <div className={classes.footer}>
          <button onClick={() => setIsViewerModalOpen(true)}>
            <EyeIcon width="16px" />
            {tCommon("label.view")}
          </button>
          {getIsPatientConsentNeedsSign(patientConsent) &&
            !isMarkedAsOnFile && (
              <button onClick={() => setIsSignModalOpen(true)}>
                <PenLineIcon width="16px" />
                {tCommon("label.sign")}
              </button>
            )}
          {isMarkAsOnFileAllowed && !isMarkedAsOnFile && (
            <OnFileButton
              isMarked={isMarkedAsOnFile}
              isLoading={onFile.isLoading}
              onClick={() => onFile.mark(procedureId, patientConsent.id)}
            />
          )}
          {isMarkedAsOnFile && (
            <OnFileButton
              isMarked={isMarkedAsOnFile}
              isLoading={onFile.isLoading}
              onClick={() => onFile.unmark(procedureId, patientConsent.id)}
            />
          )}
        </div>
      </div>
      {isViewerModalOpen && (
        <Modal size="large" isOpen onClose={() => setIsViewerModalOpen(false)}>
          <PatientConsentPreview
            consent={{
              name: patientConsent.consent.consent_name,
              description: patientConsent.consent.consent_large_description,
              witnessSignatureUrl: patientConsent.witness_signature_image_url,
              witnessName: patientConsent.witness_name,
              patientSignatureUrl: patientConsent.signature_image_url,
              patientName: patientConsent.initials,
              patientSignedOn: patientConsent.signed_signature_on,
              witnessSignedOn: patientConsent.signed_witness_on,
            }}
            patient={{
              id: patientConsent.patient_id,
            }}
          />
        </Modal>
      )}
      {isSignModalOpen && (
        <ModalPatientConsentSign
          isOpen
          onClose={() => setIsSignModalOpen(false)}
          onSuccess={() => {
            setIsSignModalOpen(false);
            refetchConsents();
          }}
          consent={{
            id: patientConsent.id,
            name: patientConsent.consent.consent_name,
            patientSignatureUrl: patientConsent.signature_image_url,
            witnessSignatureUrl: patientConsent.witness_signature_image_url,
            witnessName: patientConsent.witness_name,
            isWitnessSignatureRequired,
            patientName: patient?.fullName,
          }}
        />
      )}
    </>
  );
}

Consent.propTypes = {
  patientConsent: PropTypes.object.isRequired,
  refetchConsents: PropTypes.func,
};
