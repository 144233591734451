import React from "react";
import cx from "clsx";
import PropTypes from "prop-types";
import { Box } from "../../../../shared/Box/Box";
import classes from "./ReportHeader.module.scss";
import { Button } from "../../../../shared/Button/Button";
import { ChevronLeftIcon } from "../../../../assets/Icons/ChevronLeftIcon";
import { history } from "../../../../history";

export function ReportHeader({ withBackBtn, label, left, children, media }) {
  return (
    <Box
      className={cx(classes.root, {
        [classes.rootMediaSmall]: media === "small",
        [classes.rootMediaDefault]: !media,
      })}
    >
      <div className={classes.titleWrap}>
        {withBackBtn && (
          <Button variant="text" size="small" onClick={() => history.goBack()}>
            <ChevronLeftIcon />
          </Button>
        )}
        <div className={classes.title}>{label}</div>
        {left}
      </div>
      <div className={classes.right}>{children}</div>
    </Box>
  );
}

ReportHeader.propTypes = {
  label: PropTypes.string.isRequired,
  left: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  media: PropTypes.oneOf(["small"]),
};

ReportHeader.defaultProps = {
  left: null,
  children: null,
  media: undefined,
};
