import React from "react";
import classes from "./SimpleQuestionnaireCreateEdit.module.scss";
import { Header } from "../../shared/Header/Header";
import { Input } from "../../../../../shared/Input/Input";
import { questionSchema, useForm } from "./hooks/useForm";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useSubmit } from "./hooks/useSubmit";
import { useQuestionnaire } from "../../hooks/useQuestionnaire";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Layout } from "../../shared/Layout/Layout";
import { getSimpleQuestionnairesPathParams } from "./SimpleQuestionnaireCreateEdit.utils";
import { Footer } from "../../shared/Footer/Footer";
import { Questions } from "../../boxes/Questions/Questions";
import { useQuestionBuilderModal } from "../../shared/ModalQuestionBuilder/hooks/useQuestionBuilderModal";
import { SimpleQuestionBuilderModal } from "./shared/SimpleQuestionBuilderModal/SimpleQuestionBuilderModal";

export function SimpleQuestionnaireCreateEdit() {
  const { questionnaireId } = getSimpleQuestionnairesPathParams();

  const { tSettings } = useAppTranslation.Settings();
  const submit = useSubmit();
  const questionnaire = useQuestionnaire(questionnaireId);
  const builderModal = useQuestionBuilderModal();

  const form = useForm({
    submitter: submit.initiate,
    questionnaire: questionnaire.data || {},
  });

  return (
    <Layout>
      <Header
        questionnaireId={questionnaireId}
        title={
          questionnaireId
            ? tSettings("questionnaires.simple.edit")
            : tSettings("questionnaires.simple.create")
        }
      />
      <div className={classes.formBox}>
        {questionnaire.isFetching ? (
          <Skeleton count={1} height={48} />
        ) : (
          <Input
            name="title"
            value={form.values.title}
            onChange={form.handleChange}
            onBlur={form.onBlur}
            placeholder={tSettings("questionnaires.form.title")}
            isError={Boolean(form.getError("title"))}
          />
        )}
      </div>
      <Questions
        value={form.questions.value}
        onSortEnd={form.questions.replace}
        onQuestionAdd={() => builderModal.onOpen()}
        isLoading={questionnaire.isFetching}
      >
        {(q, i) => (
          <Questions.Tile
            key={i}
            index={i}
            question={q.question}
            type={tSettings(`questionnaires.simple.types.${q.questionType}`)}
            onDelete={() => form.questions.deleteByIndex(i)}
            isError={
              !questionSchema.isValidSync(q, {
                strict: true,
              })
            }
            onEdit={() =>
              builderModal.onOpen({
                ...q,
                index: i,
              })
            }
          />
        )}
      </Questions>
      <Footer
        questionnaireId={questionnaireId}
        onSubmit={form.handleSubmit}
        isSubmitting={submit.isLoading}
        error={form.getError("questions")}
      />
      {builderModal.isOpen && (
        <SimpleQuestionBuilderModal
          isOpen
          onAdd={(next) => {
            form.questions.append(next);
            builderModal.onClose();
          }}
          onUpdateByIndex={(...args) => {
            form.questions.updateByIndex(...args);
            builderModal.onClose();
          }}
          preloadedQuestion={builderModal.preloadedQuestion}
          onClose={builderModal.onClose}
        />
      )}
    </Layout>
  );
}
