import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import classes from "./AddEditCardModal.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import { useCreditCards } from "../../hooks/useCreditCards";
import { USER_PAYMENT_SYSTEMS } from "../../../../../../consts/api";
import { InputStripeCard } from "../../../../../../shared/InputStripeCard/InputStripeCard";
import { useMutateCard } from "../../hooks/useMutateCard";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import {
  CLEARENT_FORM_ID,
  useClearentForm,
} from "../../hooks/forms/useClearentForm";
import { Input } from "../../../../../../shared/Input/Input";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSetupIntent, getClientCardDataStripe } from "../../../../../../Actions/Stripe/StripeActions";

const AddEditCardModal = ({ cardId, clientId, isOpen, onClose, getClientCardDataStripe, removeStripeCard }) => {
  const { tCommon } = useAppTranslation.Common();
  const cc = useCreditCards(clientId);
  const card = cardId ? cc.list.find((card) => card.id === cardId) : null;


  const clearentForm = useClearentForm({
    publicKey: cc.clearent.publicKey,
    billingEmail: cc.clearent.billingEmail,
    billingZip: card?.billingZip || cc.clearent.billingZip,
  });

  const mutateCard = useMutateCard({
    clientId,
    cardId,
    clearentForm,
    onSuccess: () => {
      onClose();
      cc.refetch();
    },
  });

  const handleSubmit = async () => {
    if (cc.paymentSystem === USER_PAYMENT_SYSTEMS.clearent) {
      clearentForm.validate(mutateCard.initiate);
    } else {
      await getClientCardDataStripe({ patient_id: clientId });
      onClose()
    }
  };

  const updateStripeCard = async (id) => {
    removeStripeCard()
    await getClientCardDataStripe({ patient_id: clientId });
    onClose()
  }

  return (
    <Modal
      footerNoBorder
      size="small"
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      header={
        <div className={classes.header}>
          <Modal.Title>
            {cardId ? tCommon("label.editCC") : tCommon("label.addCC")}
          </Modal.Title>
          {card?.cardNumber && (
            <div className={classes.cardNumber}>{card.cardNumber}</div>
          )}
        </div>
      }
      footer={
        <div className={classes.footer}>
          {cc.paymentSystem === USER_PAYMENT_SYSTEMS.clearent ? <>
          <Button
            onClick={handleSubmit}
            isDisabled={mutateCard.isLoading}
            leftAdornment={
              mutateCard.isLoading ? (
                <CircularProgress color="white" size="small" />
              ) : undefined
            }
          >
            {cardId ? tCommon("label.save") : tCommon("label.add")}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            isDisabled={mutateCard.isLoading}
          >
            {tCommon("label.cancel")}
          </Button>
          </> : <></>}
        </div>
      }
    >
      {cc.paymentSystem === USER_PAYMENT_SYSTEMS.stripe ? (
        <InputStripeCard
          onClose={onClose}
          handleSubmit={cardId ? updateStripeCard : handleSubmit}
        />
      ) : (
        <div className={classes.clearentForm}>
          <div id={CLEARENT_FORM_ID}></div>
          <div>
            <Input
              name="zip"
              value={clearentForm.values.zip}
              onChange={clearentForm.handleChange}
              placeholder={tCommon("label.zipCode")}
              isError={!!clearentForm.errors.zip}
            />
            {!!clearentForm.errors.zip && (
              <InputError>{clearentForm.errors.zip}</InputError>
            )}
          </div>
          <div>
            <Input
              name="email"
              value={clearentForm.values.email}
              onChange={clearentForm.handleChange}
              placeholder={tCommon("label.email")}
              isError={!!clearentForm.errors.email}
            />
            {!!clearentForm.errors.email && (
              <InputError>{clearentForm.errors.email}</InputError>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

AddEditCardModal.propTypes = {
  cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cardNumber: PropTypes.string,
  billingZip: PropTypes.string,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    stripeIntent: state.StripeReducer.stripeIntent
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getSetupIntent,
    getClientCardDataStripe
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCardModal);