import React from "react";

export const CreditCardIcon = () => (
  <svg width="30" height="21" viewBox="0 0 30 21" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.35 1.5H1.65C1.56716 1.5 1.5 1.56716 1.5 1.65V19.35C1.5 19.4328 1.56716 19.5 1.65 19.5H28.35C28.4328 19.5 28.5 19.4328 28.5 19.35V1.65C28.5 1.56716 28.4328 1.5 28.35 1.5ZM1.65 0C0.73873 0 0 0.73873 0 1.65V19.35C0 20.2613 0.73873 21 1.65 21H28.35C29.2613 21 30 20.2613 30 19.35V1.65C30 0.73873 29.2613 0 28.35 0H1.65ZM2.54997 4.35029C2.54997 4.18461 2.68429 4.05029 2.84997 4.05029H27.15C27.3157 4.05029 27.45 4.18461 27.45 4.35029V7.50029C27.45 7.66598 27.3157 7.80029 27.15 7.80029H2.84997C2.68429 7.80029 2.54997 7.66598 2.54997 7.50029V4.35029ZM23.1 13.6499C22.7686 13.6499 22.5 13.9185 22.5 14.2499V16.9499C22.5 17.2813 22.7686 17.5499 23.1 17.5499H25.8C26.1314 17.5499 26.4 17.2813 26.4 16.9499V14.2499C26.4 13.9185 26.1314 13.6499 25.8 13.6499H23.1Z"
    />
  </svg>
);
