import {
  extractFrom,
  getPrecision,
  shortenString,
  squashSpaces,
} from "../utilities/general";

/**
 * Returns function that determines if some shape has truthy
 * value by following nesting keys
 *
 * @template {Base} T
 * @template {Base} V
 * @param {T[]} shapes
 * @param {(value: V) => boolean} predicate
 * @returns {boolean}
 */
export const getIsSomeTruthy = (shapes = [], globalPredicate = Boolean) => {
  return (nesting, predicate = globalPredicate) => {
    return shapes.some((shape) => predicate(extractFrom(shape, nesting)));
  };
};

/* Returns function which will find a nested value in first n-th shape
 *
 * @template {Base} T
 * @template {Base} F
 * @template {Base} R
 * @param {T[]} shapes
 * @param {F} globalFallback
 * @returns {R | F}
 */
export const getExtractFirst =
  ({ shapes, globalFallback = null, globalCondition = () => true }) =>
  (nesting, fallback = globalFallback, condition = globalCondition) => {
    for (let i = 0; i < shapes.length; i++) {
      const result = extractFrom(shapes[i], nesting);

      if (result !== null && result !== undefined && condition(result)) {
        return result;
      }

      if (i === shapes.length - 1) {
        return fallback;
      }

      continue;
    }
  };

/**
 * Determines if `target` is the number that you can get from incrementing
 * by `increment` from `0` unknown amount of times.
 *
 * @param {number} target
 * @param {number} increment
 * @returns {boolean}
 */
export function isDerivedFromIncrement(target, increment) {
  if ([...arguments].some((x) => typeof x !== "number" || Number.isNaN(x))) {
    return false;
  }

  if ([...arguments].some((x) => x === 0)) {
    return true;
  }

  if (
    !([...arguments].every((x) => x > 0) || [...arguments].every((x) => x < 0))
  ) {
    return false;
  }

  const incrementPrecision = getPrecision(increment);
  const multiplier = 10 ** incrementPrecision;

  return ((target * multiplier) / (increment * multiplier)) % 1 === 0;
}

/**
 * @param str
 * @returns {string | T}
 */
export const shortenClinicName = (str) => {
  return shortenString(str || "", 45);
};

/**
 * @param {string[]} strings
 * @returns {string[]}
 */
export function findDuplicatesInStrings(strings) {
  var duplicates = [];
  var seen = [];

  strings.map(squashSpaces).forEach((string) => {
    if (seen.includes(string)) {
      duplicates.push(string);
    } else {
      seen.push(string);
    }
  });

  return duplicates;
}
