import React, { useState } from "react";
import queryString from "query-string";
import { Button } from "../../../../../../../../shared/Button/Button";
import { DeleteIcon } from "../../../../../../../../assets/Icons/DeleteIcon";
import { ConfirmModal } from "../../../../../../../../boxes/ConfirmModal/ConfirmModal";
import { useEfaxTemplateDeleteMutation } from "../../../../../../../../api/efax/useEfaxTemplateDeleteMutation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../../../../shared/CircularProgress/CircularProgress";
import styles from "./styles.module.scss";
import { useDeleteFavorites } from "../../../../hooks/useDeleteFavorites";
import { TABS } from "../../../../config";

export const DeleteAction = ({ id }) => {
  const { tSettings } = useAppTranslation.Settings();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const params = queryString.parse(window.location.search);

  const { isLoading: isDeletingTemplate, mutate } =
    useEfaxTemplateDeleteMutation({
      onError: () => {
        uiNotification.error(tSettings("efaxTemplates.error.delete"));
      },
      onSuccess: () => {
        uiNotification.success(tSettings("efaxTemplates.success.delete"));
      },
    });

  const { isLoading: isDeletingFavorite, initiate: deleteFavorite } =
    useDeleteFavorites();

  const isLoading = isDeletingFavorite || isDeletingTemplate;

  return (
    <>
      <Button
        size="tiny"
        variant="text"
        color="error"
        className={styles.root}
        onClick={() => setIsConfirmOpen(true)}
      >
        {isLoading ? (
          <CircularProgress size="tiny" color="red" />
        ) : (
          <DeleteIcon width="16px" />
        )}
      </Button>
      {isConfirmOpen && (
        <ConfirmModal
          isOpen
          onClose={() => setIsConfirmOpen(false)}
          onCancel={() => setIsConfirmOpen(false)}
          onConfirm={() => {
            setIsConfirmOpen(false);
            if (params.tab === TABS.templates) {
              mutate({ id });
            } else {
              deleteFavorite(id);
            }
          }}
        >
          {tSettings("efaxTemplates.confirm.delete")}
        </ConfirmModal>
      )}
    </>
  );
};
