import React, { useEffect, useState } from "react";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { Box } from "../../../shared/Box/Box";
import classes from "./PatientVitals.module.scss";
import { Header } from "./shared/Header/Header";
import { ModalGraph } from "./shared/ModalGraph/ModalGraph";
import { Table } from "./shared/Table/Table";
import { ModalSettings } from "./shared/ModalSettings/ModalSettings";
import { Errors } from "./shared/Errors/Errors";
import { dispatch } from "../../../store/store";
import { patientVitals } from "../../../store/patients/vitals";

const { actions } = patientVitals;

export const PatientVitals = () => {
  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.dropMutationRecord());
  }, []);

  return (
    <LayoutMain>
      <Box className={classes.root}>
        <Header
          onGraphOpen={() => setIsGraphOpen(true)}
          onSettingsOpen={() => setIsSettingsOpen(true)}
        />
        <Errors />
        <Table />
      </Box>
      {isGraphOpen && <ModalGraph onClose={() => setIsGraphOpen(false)} />}
      {isSettingsOpen && (
        <ModalSettings onClose={() => setIsSettingsOpen(false)} />
      )}
    </LayoutMain>
  );
};
