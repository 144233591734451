import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import { createQueryKeyFromShape } from "../../utilities/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

export const QUALIPHY_REPORT_ORDER_BY = {
  patient: "patient",
  clinic: "clinic",
  provider: "provider",
  exam: "exam",
  status: "status",
  completedAt: "completed_on",
};

/*
  SCHEMAS
*/

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  order: yup
    .object({
      by: yup.string().required(),
      direction: yup.string().required(),
    })
    .nullable(),

  filter: yup.object({
    clinicIds: yup.array().of(yup.number()).nullable(),
    providerIds: yup.array().of(yup.number()).nullable(),
  }),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          clinicName: yup.string().nullable(),
          patientName: yup.string().nullable(),
          providerName: yup.string().nullable(),
          examName: yup.string().nullable(),
          status: yup.string().nullable(),
          completedAt: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

/*
  COMPOSERS
*/

const composeResponse = (res, req) => {
  const reports = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    reports: reports.map((report) => ({
      id: report.id,
      clinicName: report.clinic || null,
      patientName: report.patient || null,
      providerName: report.provider || null,
      examName: report.exam || null,
      status: report.status || null,
      completedAt: report.completed_on || null,
    })),
  };
};

// ---------

function createQualiphyReportQueryKey({
  order,
  fromDate,
  toDate,
  pageSize,
  clinicIds,
  providerIds,
}) {
  return [
    QUERY_KEYS.qualiphy.report,
    order,
    fromDate,
    toDate,
    pageSize,
    clinicIds.join("-"),
    providerIds.join("-"),
  ];
}

const createKeyFromShape = (shape) =>
  createQueryKeyFromShape(removeNullishFromShape(shape));

export function useQualiphyReportQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createQualiphyReportQueryKey({
      order: createKeyFromShape(req.order),
      fromDate,
      toDate,
      pageSize: req.pageSize,
      clinicIds: req.filter.clinicIds || [],
      providerIds: req.filter.providerIds || [],
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.post(
        HTTP_ENDPOINTS.qualiphy.createReport(),
        removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          start_date: fromDate,
          end_date: toDate,
          sortBy: unwrapOr(() => req.order.by, null),
          order: unwrapOr(() => req.order.direction, null),
          clinic_ids: req.filter.clinicIds || null,
          provider_ids: req.filter.providerIds || null,
        }),
      );
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
