import moment from "moment";

export const viewDateFormat = (date = moment()) => {
  return moment(date).format("MMMM DD, YYYY");
};

export const apiDateFormat = (date = moment()) => {
  return moment(date).format("YYYY-MM-DD");
};

export const showSelectedWeek = (
  date = moment(),
  isCustomRange = false,
  isAllowForFuture = false,
) => {
  let showSelectedLabel = "";
  const currentDate = moment(moment().format("YYYY-MM-DD 00:00:00"));

  date = moment(date).format("YYYY-MM-DD 00:00:00");

  let startOfWeek = !isCustomRange
    ? moment(date).startOf("week")
    : moment(date);

  let endOfWeek = !isCustomRange
    ? moment(date).endOf("week")
    : moment(endDateOfWeek(startOfWeek));

  if (isAllowForFuture) {
    if (endOfWeek.isAfter(currentDate)) {
      endOfWeek = currentDate;
    }
  }

  if (startOfWeek.format("YYYY") != endOfWeek.format("YYYY")) {
    showSelectedLabel =
      startOfWeek.format("MMM DD, YY - ") + endOfWeek.format("MMM DD, YY");
  } else if (startOfWeek.format("MM") != endOfWeek.format("MM")) {
    showSelectedLabel =
      startOfWeek.format("MMM DD - ") + endOfWeek.format("MMM DD, YYYY");
  } else {
    showSelectedLabel =
      startOfWeek.format("MMM ") +
      startOfWeek.format("DD - ") +
      endOfWeek.format("DD, YYYY");
  }

  return {
    label_date: showSelectedLabel,
    start_date: startOfWeek.toDate(),
    end_date: endOfWeek.toDate(),
  };
};

export const showSelectedMonth = (
  date = moment(),
  isCustomRange = false,
  isAllowForFuture = false,
) => {
  let showSelectedLabel = "";
  const currentDate = moment(moment().format("YYYY-MM-DD 00:00:00"));

  date = moment(date).format("YYYY-MM-DD 00:00:00");

  let startOfMonth = !isCustomRange
    ? moment(date).startOf("month")
    : moment(date);

  let endOfMonth = !isCustomRange
    ? moment(date).endOf("month")
    : moment(endDateOfMonth(startOfMonth));

  if (isAllowForFuture) {
    if (endOfMonth.isAfter(currentDate)) {
      endOfMonth = currentDate;
    }
  }

  if (startOfMonth.format("YYYY") != endOfMonth.format("YYYY")) {
    showSelectedLabel =
      startOfMonth.format("MMM DD, YY - ") + endOfMonth.format("MMM DD, YY");
  } else if (startOfMonth.format("MM") != endOfMonth.format("MM")) {
    showSelectedLabel =
      startOfMonth.format("MMM DD - ") + endOfMonth.format("MMM DD, YYYY");
  } else {
    showSelectedLabel =
      startOfMonth.format("MMM ") +
      startOfMonth.format("DD - ") +
      endOfMonth.format("DD, YYYY");
  }

  return {
    label_date: showSelectedLabel,
    start_date: startOfMonth.toDate(),
    end_date: endOfMonth.toDate(),
  };
};

const endDateOfWeek = (date = moment()) => {
  date = moment(date).add(6, "days");
  return date;
};

const endDateOfMonth = (date = moment()) => {
  date = moment(date).subtract(1, "days").add(1, "months").toDate();
  return date;
};

export const calulateDiffDuration = (date = moment()) => {
  let duration = {
    days: 0,
    hours: 0,
    milliseconds: 0,
    minutes: 0,
    months: 0,
    seconds: 0,
    years: 0,
  };
  const diff = moment.duration(moment().diff(moment(date)));

  if (diff) {
    duration = diff._data;
  }

  return duration;
};

export const dateLeftPadding = (date) =>
  date ? date.toString().padStart(2, "0") : "01";
