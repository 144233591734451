import React, { useEffect } from "react";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { Toggle } from "../../../../../shared/Toggle/Toggle";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { useForm } from "./hooks/useForm";
import { useSubmit } from "./hooks/useSubmit";
import { InputPhone } from "../../../../../shared/InputPhone/InputPhone";
import { Checkbox } from "../../../../../shared/Checkbox/Checkbox";
import { Button } from "../../../../../shared/Button/Button";
import { InputError } from "../../../../../shared/InputError/InputError";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useSendOtp } from "../../../../../hooks/phone/useSendOtp";
import { useVerifyOtp } from "../../../../../hooks/phone/useVerifyOtp";
import { phoneUtil } from "../../../../../utilities/phone";
import { Input } from "../../../../../shared/Input/Input";

export function SmsApptNotifications() {
  const { tSettings } = useAppTranslation.Settings();

  const [isConfirmDisableOpen, setIsConfirmDisableOpen] = React.useState(false);
  const [isOtpVerified, setIsOtpVerified] = React.useState(true);
  const [isOtpSent, setIsOtpSent] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  const submit = useSubmit();
  const form = useForm(submit.initiate);

  const sendOtp = useSendOtp({
    onError: () => {
      setIsOtpSent(false);
      setIsOtpVerified(false);
    },
    onSuccess: () => {
      setOtp("");
      setIsOtpSent(true);
      setIsOtpVerified(false);
    },
  });

  const verifyOtp = useVerifyOtp({
    onError: () => {
      setIsOtpSent(false);
      setIsOtpVerified(false);
      setOtp("");
    },
    onSuccess: () => {
      setOtp("");
      setIsOtpVerified(true);
      setIsOtpSent(false);
      form.originalPhone.reinit();
    },
  });

  const onChangeStatus = () => {
    if (form.isEnabled.value && form.isEnabledOriginal) {
      return setIsConfirmDisableOpen(true);
    }

    form.isEnabled.onChange(!form.isEnabled.value);
  };

  const onDisable = () => {
    submit.disable().then(() => {
      form.isEnabled.onChange(false);
      setIsConfirmDisableOpen(false);
    });
  };

  const onSubmit = () => {
    if (isOtpVerified) {
      form.submit();
    }
  };

  const reset = () => {
    form.phone.reinit();
    setIsOtpVerified(true);
    setIsOtpSent(false);
    setOtp("");
  };

  useEffect(() => {
    const phonesEqual =
      phoneUtil.compose(form.phone.value) ===
      phoneUtil.compose(form.originalPhone.value);

    if (!phonesEqual) {
      setIsOtpSent(false);
    }

    setIsOtpVerified(phonesEqual);
  }, [form.phone.value, form.originalPhone.value]);

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>
        {tSettings("smsApptNotifications.title")}
      </h3>
      <Toggle
        isDisabled={submit.isLoading || form.isLoading}
        isEnabled={form.isEnabled.value}
        onChange={onChangeStatus}
      >
        {form.isEnabled.value
          ? tSettings("smsApptNotifications.enabled")
          : tSettings("smsApptNotifications.disabled")}
      </Toggle>
      {form.isEnabled.value && (
        <div className={styles.form}>
          <div>
            <InputPhone
              value={form.phone.value}
              onChange={form.phone.onChange}
              isError={!!form.phone.error}
              isDisabled={isOtpSent && !isOtpVerified}
            />
            {!!form.phone.error && <InputError>{form.phone.error}</InputError>}
          </div>
          {isOtpSent && !isOtpVerified && (
            <Input
              type="number"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className={styles.otpInput}
              placeholder={tSettings("smsApptNotifications.otpPlaceholder")}
            />
          )}
          <div>{tSettings("smsApptNotifications.notificationType")}</div>
          <div className={styles.checkboxes}>
            <Checkbox
              inverted
              label={tSettings("smsApptNotifications.booking")}
              isChecked={form.booking.value}
              onChange={form.booking.toggle}
            />
            <Checkbox
              inverted
              label={tSettings("smsApptNotifications.rescheduling")}
              isChecked={form.rescheduling.value}
              onChange={form.rescheduling.toggle}
            />
            <Checkbox
              inverted
              label={tSettings("smsApptNotifications.cancellation")}
              isChecked={form.cancelling.value}
              onChange={form.cancelling.toggle}
            />
          </div>
          {isOtpVerified ? (
            <Button
              size="small"
              className={styles.submitBtn}
              onClick={onSubmit}
              isDisabled={submit.isLoading}
              leftAdornment={
                submit.isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              {tSettings("smsApptNotifications.submit")}
            </Button>
          ) : (
            <div className={styles.otpButtons}>
              <Button
                className={styles.submitBtn}
                size="small"
                onClick={() => sendOtp.initiate(form.phone.value)}
                isDisabled={sendOtp.isLoading}
                leftAdornment={
                  sendOtp.isLoading ? (
                    <CircularProgress size="small" color="white" />
                  ) : undefined
                }
              >
                {isOtpSent
                  ? tSettings("smsApptNotifications.resendOtp")
                  : tSettings("smsApptNotifications.sendOtp")}
              </Button>
              <Button
                className={styles.submitBtn}
                size="small"
                onClick={() => verifyOtp.initiate(form.phone.value, otp)}
                isDisabled={verifyOtp.isLoading || !isOtpSent}
                leftAdornment={
                  verifyOtp.isLoading ? (
                    <CircularProgress size="small" color="white" />
                  ) : undefined
                }
              >
                {tSettings("smsApptNotifications.verifyOtp")}
              </Button>
              {isOtpSent && (
                <Button
                  variant="outlined"
                  className={styles.submitBtn}
                  size="small"
                  onClick={reset}
                >
                  {tSettings("smsApptNotifications.useAnotherPhone")}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
      {isConfirmDisableOpen && (
        <ConfirmModal
          isOpen
          isConfirming={submit.isLoading}
          onConfirm={onDisable}
          onClose={() => setIsConfirmDisableOpen(false)}
          onCancel={() => setIsConfirmDisableOpen(false)}
        >
          {tSettings("smsApptNotifications.confirm.disable")}
        </ConfirmModal>
      )}
    </div>
  );
}
