import { yupResolver } from "@hookform/resolvers/yup";
import cx from "clsx";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  useAddNewMembershipQuery,
  useGetAllActiveUserQuery,
  useGetMembershipAgreementQuery,
  useGetMemershipDetailsQuery,
  useGetNewMemershipQuery,
  useUploadSignatureQuery,
  useValidateCouponCodeQuery,
} from "../../_legacy/Queries";
import { getClearentToken, numberFormat } from "../../Utils";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";
import { AppTranslation, useAppTranslation } from "../../i18n/useAppTranslation";
import { uiNotification } from "../../services/UINotificationService";
import { Button } from "../../shared/Button/Button";
import { Input } from "../../shared/Input/Input";
import { Select } from "../../shared/Select/Select";
import { CommonDialog } from "../Common";
import ClientSignatureCanvas from "./ClientSignatureCanvas";
import MembershipFeeSchedule from "./MembershipFeeSchedule";
import MembershipPayment from "./MembershipPayment";
import classes from "./sass/membership.module.scss";
import Loader from "../Common/Loader";
import { connect } from "react-redux";
import { USER_PAYMENT_SYSTEMS } from "../../consts/api";
import { getUserPaymentSystem } from "../../utilities/localStorage";
import { getClientCardDataStripe } from "../../Actions/Stripe/StripeActions";
import { bindActionCreators } from "redux";
import { shortenClinicName } from "../../helpers/general";

const addMembershipSchema = yup.object().shape({
  selectedClinic: yup.object().shape({
    value: yup.number().typeError("Required").required("Required"),
  }),
  paymentOption: yup.string().required().oneOf(["monthly", "yearly"]),
  membershipTierType: yup.object().shape({
    value: yup.number().typeError("Required").required("Required"),
  }),
  soldBy: yup.object().shape({
    value: yup.number().typeError("Required").required("Required"),
  }),
  patientEmail: yup.string().email("Provide valid email"),
  clearentZip: yup.number().when("pos_gateway", {
    is: (pos_gateway) => pos_gateway === "clearent",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

const paymentFrequencyOptions = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];


const monthDates = Array.from(Array(31 + 1).keys())
  .slice(1)
  .map((monthDay) => ({ label: monthDay, value: monthDay }));

const AddMembershipModal = (props) => {
  const { onClose, clientId, clientData, stripeIntent, getClientCardDataStripe, cardList } = props;

  const [displayProceedButton, setDisplayProceedButton] = useState('');
  const [cardSelected, setCardSelected] = useState('');
  const [cardOnFile, setCardOnFile] = useState(false)
  const userPaymentSystem = getUserPaymentSystem();
  const updateProceedState = (value) => {
    setDisplayProceedButton(value);
  };
  const updateCardSelected = (value) => {
    setCardSelected(value);
  };

  const isStripe = userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe;

  const membershipSketchRef = useRef();

  const clearentCardsList = useMemo(() => {
    return clientData?.card_on_files?.map((card) => ({
      label: card.card_number,
      value: card.card_on_file
  }))
  }, [clientData])

  const { data: newMembershipData, dataUpdatedAt } =
    useGetNewMemershipQuery(clientId);

  const { tCommon } = useAppTranslation.Common();

  const formMethods = useForm({
    defaultValues: {
      selectedClinic: {
        label: null,
        value: null,
      },
      isSetupFeeWaived: 0,
      chargeFullFee: false,
      patientEmail: clientData.email,
      clearentEmail: clientData.email,
      clearentEmailId: clientData.email,
      clearentZip: clientData.account?.pos_gateway === USER_PAYMENT_SYSTEMS.clearent ? clientData.pincode : null,
      isDatePickerOpen: false,
      subscriptionStartsAt: new Date(),
      feeChargeDay: new Date().getDate(),
      isCouponApplied: false,
      feeSchedule: [],
      displayWaveSetupFee: false,
      signatureDetails: {
        isSignatureSaved: false,
      },
      agreementDetails: {
        isAgreementAccepted: false,
        agreementText: "",
        openAgreementModal: false,
      },
      pos_gateway: JSON.parse(localStorage.getItem("userData"))?.account
        ?.pos_gateway,
      selectedClearentCard: clearentCardsList[0]
    },
    resolver: yupResolver(addMembershipSchema),
  });

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const {
    selectedClinic,
    isSetupFeeWaived,
    chargeFullFee,
    paymentOption,
    membershipTierType,
    patientEmail,
    isDatePickerOpen,
    subscriptionStartsAt,
    couponCode,
    feeChargeDay,
    isCouponApplied,
    signatureDetails,
    agreementDetails,
    displayWaveSetupFee,
    soldBy,
    selectedClearentCard,
  } = watch();

  const { data: allActiveUser } =
    useGetAllActiveUserQuery(selectedClinic?.value);

  const {
    clinicsList,
    membership_tier,
    recurly_program_name,
    membershipTierTypes,
  } = useMemo(() => {
    if (dataUpdatedAt) {
      const { clinic_list, membership_tier_types } =
        newMembershipData.data.data;
      const clinicsList = clinic_list.map((clinicDetails) => ({
        ...clinicDetails,
        name: clinicDetails.name,
        label: shortenClinicName(clinicDetails.name),
        value: clinicDetails.id,
      }));

      const membershipTierTypes = membership_tier_types.map(
        (membershipDetails) => ({
          ...membershipDetails,
          label: membershipDetails.tier_name,
          value: membershipDetails.id,
        }),
      );
      return {
        ...newMembershipData.data.data,
        clinicsList,
        membershipTierTypes,
      };
    }
    return {};
  }, [dataUpdatedAt]);

  const activeUsers = useMemo(() => {
    return (
      allActiveUser?.data?.data?.map((user) => ({
        ...user,
        label: user.full_name,
        value: user.id,
      })) || []
    );
  }, [allActiveUser?.data?.data]);

  const {
    data: membershipFeeScheduleDetails,
    dataUpdatedAt: membershipDetailsUpdatedAt,
  } = useGetMemershipDetailsQuery({
    clinic_id: selectedClinic.value,
    waive_off_one_time_fee: isSetupFeeWaived,
    charge_full_fee: Number(chargeFullFee),
    start_date: moment(subscriptionStartsAt).format("Y-MM-DD"),
    membership_tier,
    membership_tier_id: membershipTierType?.id,
    payment_frequency:
      paymentOption || membershipTierType?.membership_payment_options,
    draw_day: feeChargeDay,
    coupon_code: isCouponApplied ? couponCode : null,
  });

  const updateFeeSchedule = (updatedFeeSchedule) => {
    setValue("feeSchedule", updatedFeeSchedule);
    setValue(
      "displayWaveSetupFee",
      updatedFeeSchedule.some((feeDetails) => feeDetails.one_time_setup),
    );
  };

  useEffect(() => {
    if (membershipDetailsUpdatedAt) {
      setValue(
        "displayWaveSetupFee",
        membershipFeeScheduleDetails.data.data.fee_schedule.some(
          (feeDetails) => feeDetails.one_time_setup,
        ),
      );
      if (isCouponApplied) {
        validateCouponCode();
      } else {
        updateFeeSchedule(membershipFeeScheduleDetails.data.data.fee_schedule);
      }
    }
  }, [membershipDetailsUpdatedAt]);

  const { mutate: getCouponDetails } = useValidateCouponCodeQuery();
  const { mutate: addNewMembership, isLoading: isAddingNewMembership } =
    useAddNewMembershipQuery();
  const { mutateAsync: uploadClientsSignature } = useUploadSignatureQuery();
  const { refetch: fetchMembershipAgreement } = useGetMembershipAgreementQuery(
    membershipTierType?.id,
  );

  function validateCouponCode(applyCoupon) {
    getCouponDetails(
      {
        coupon_code: couponCode,
        membership_tier_id: membershipTierType?.id,
        payment_frequency:
          paymentOption || membershipTierType?.membership_payment_options,
        draw_day: feeChargeDay,
        start_date: moment(subscriptionStartsAt).format("Y-MM-DD"),
        clientId,
        waive_off_one_time_fee: isSetupFeeWaived,
        charge_full_fee: Number(chargeFullFee),
      },
      {
        onSuccess: (response) => {
          if (applyCoupon) {
            uiNotification.success("Coupon applied successfully");
          }
          setValue("isCouponApplied", true);
          updateFeeSchedule(response.data.data.fee_schedule);
        },
      },
    );
  }

  const handleChange = (field, value) => {
    setValue(field, value, {
      shouldValidate: Boolean(errors[field]),
    });

    const fieldMappings = {
      membershipTierType: () => {
        if (["monthly", "yearly"].includes(value.membership_payment_options?.toLowerCase())) {
          setValue("paymentOption", value.membership_payment_options, {
            shouldValidate: Boolean(errors.paymentOption),
          });
        } else {
          setValue("paymentOption", null);
        }
      },
    };

    if (field in fieldMappings) {
      fieldMappings[field]();
    }
  };

  const handleToggleWaiveSetupFee = () => {
    setValue("isSetupFeeWaived", isSetupFeeWaived ? 0 : 1);
  };

  const handleToggleChargeFull = () => {
    setValue("chargeFullFee", !chargeFullFee);
  };

  const toggleDatePicker = () => {
    setValue("isDatePickerOpen", !isDatePickerOpen);
  };

  const handleChangeStartDate = (selectedDay) => {
    setValue("subscriptionStartsAt", selectedDay);
    toggleDatePicker();
  };

  const checkClientsSignature = async () => {
    if (signatureDetails.isSignatureSaved) {
      return true;
    }

    if (membershipSketchRef.current?.toJSON().objects.length !== 0) {
      await uploadClientsSignature(
        {
          image_data: membershipSketchRef.current.toDataURL(),
          upload_type: "patient_signatures",
        },
        {
          onSuccess: (response) => {
            const { file_name, signature_url } = response.data.data;

            setValue("signatureDetails", {
              isSignatureSaved: true,
              patient_signature: file_name,
              signature_preview: signature_url,
            });
          },
        },
      );
      return true;
    } else {
      uiNotification.error(
        tCommon(`membership.formErrors.clientSignatureRequired`),
      );
      return false;
    }
  };

  const fetchAgreement = async () => {
    const agreementResponse = await fetchMembershipAgreement(100);

    if (agreementResponse) {
      const { agreement_text, id } = agreementResponse.data.data.data;

      setValue("agreementDetails", {
        isAgreementAccepted: false,
        agreementText: agreement_text,
        openAgreementModal: true,
        agreementId: id,
      });
    }
  };

  useEffect(() => {
    if (agreementDetails.isAgreementAccepted) {
      saveNewMembership(watch());
    }
  }, [agreementDetails.isAgreementAccepted]);

  const getPaymentToken = async () => {
    let ccTokenError = false;
    let token = {};

    const { pos_gateway } = watch();

    if (pos_gateway === "stripe") {
      if (!displayProceedButton) {
        if (stripeIntent?.setupIntentId) {
          token = { setup_intent_id: stripeIntent.setupIntentId }
        } else {
          ccTokenError = true;
        }
      } else {
        if (cardSelected)
          token = { payment_method_id: cardSelected }
        else {
          uiNotification.error("Select payment method");
          ccTokenError = true;
        }
      }
    } else {
      // Clearent
      let cardDataToken 
      if (!cardOnFile) {
        cardDataToken = await getClearentToken();
      }

      if (cardDataToken || cardOnFile) {
        const { clearentEmail, clearentZip } = watch();
        token = {
          token_id: cardDataToken || "",
          clearent_email_id: clearentEmail,
          clearent_zip: clearentZip,
        };
      } else {
        ccTokenError = true;
        uiNotification.error("Invalid Card Details.");
      }
    }

    if (!ccTokenError) {
      return token;
    }
    return false;
  };

  const saveNewMembership = async (membershipData) => {
    const paymentTokens = await getPaymentToken();

    const payload = {
      clientId,
      ...paymentTokens,
      waive_off_one_time_fee: isSetupFeeWaived,
      charge_full_fee: Number(chargeFullFee),
      email: patientEmail,
      subscription_started_at:
        moment(subscriptionStartsAt).format("Y-MM-DD"),
      membership_total: membershipData.feeSchedule[0].total,
      applied_discount_coupon: isCouponApplied,
      coupon_code: couponCode,
      draw_day: feeChargeDay,
      membership_tier,
      clinic_id: selectedClinic.value,
      membership_agreement_id: agreementDetails.agreementId,
      patient_signature: signatureDetails.patient_signature,
      membership_tier_id: membershipTierType.value,
      subscription_type: paymentOption,
      sold_by: soldBy.value,
      saved_card: cardOnFile,
      card_on_file: cardOnFile ? selectedClearentCard.value : null
    }

    if (paymentTokens || cardOnFile) {
      if (!agreementDetails.isAgreementAccepted) {
        fetchAgreement();
      } else {
        addNewMembership(payload, { onSuccess: onClose })
        if (isStripe) {
          getClientCardDataStripe({patient_id: clientId});
        }
      }
    }
  };

  const formErrors = (errors) => {
    if (errors.selectedClinic) {
      uiNotification.error("Select Clinic");
    }
    if (errors.membershipTierType) {
      uiNotification.error("Select membership type");
    } else if (errors.paymentOption) {
      uiNotification.error("Select payment frequency");
    }
    if (errors.patientEmail) {
      uiNotification.error("Provide valid email");
    }
    if (errors.clearentZip) {
      uiNotification.error("Please Provide a Zip code");
    }
  };

  const handleChangeField = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setValue(name, value, { shouldValidate: Boolean(errors[name]) });
  };

  const clearCouponCode = () => {
    setValue("couponCode", null);
    setValue("isCouponApplied", false);
  };

  const acceptAgreement = async (isAccepted) => {
    if (isAccepted) {
      const isClientSignatureSaved = await checkClientsSignature();

      if (isClientSignatureSaved) {
        setValue("agreementDetails", {
          isAgreementAccepted: isAccepted,
          agreementId: agreementDetails.agreementId,
          openAgreementModal: false,
        });
      }
    } else {
      setValue("agreementDetails", {
        isAgreementAccepted: false,
        openAgreementModal: false,
      });
    }
  };

  const formStripeErrors = () => {
    let error = false;

    if (!selectedClinic.value) {
      error = true;
      uiNotification.error("Select Clinic");
    }
    if (!membershipTierType) {
      error = true;
      uiNotification.error("Select membership type");
    } else if (!paymentOption) {
      error = true;
      uiNotification.error("Select payment frequency");
    }
    if (!patientEmail) {
      error = true;
      uiNotification.error("Provide valid email");
    }
    return error;
  };
  const validation = () => {
    return formStripeErrors();
  }

  useEffect(() => {
    if (cardList?.length === 1) {
      setCardSelected(cardList[0].id)
    }
  }, [cardList])

  console.log(window)

  return (
    <CommonDialog title="Add Membership" onClose={onClose}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={(displayProceedButton || userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent) ? handleSubmit(saveNewMembership, formErrors) : ""}
          className={classes.addMembershipForm}
        >
          <div>
            <div className="row m-t-10">
              <div className="col-xs-12 col-sm-6">
                <div className={classes.fieldTitle}>Select Clinic</div>
                <div className={classes.formField}>
                  <Select
                    isError={Boolean(errors.selectedClinic)}
                    value={selectedClinic}
                    onChange={(clinicDetails) => {
                      handleChange("selectedClinic", clinicDetails);
                    }}
                    options={clinicsList}
                    isSearchable
                    placeholder="Select"
                    name="select-clinic"
                    size="small"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className={classes.fieldTitle}>Membership Plan</div>
                <div className={classes.formField}>
                  <Input
                    size="small"
                    disabled
                    value={recurly_program_name}
                    name="membership-plan"
                    placeholder="Membership Plan"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className={classes.fieldTitle}>Membership Type</div>
                <div className={classes.formField}>
                  <Select
                    isError={Boolean(errors.membershipTierType)}
                    value={membershipTierType}
                    onChange={(membershipTierType) => {
                      handleChange("membershipTierType", membershipTierType);
                    }}
                    options={membershipTierTypes}
                    isSearchable
                    placeholder="Select"
                    name="select-membership-type"
                    size="small"
                  />
                </div>
              </div>
              {membershipTierType?.membership_payment_options?.toLowerCase() === "both" && (
                <div className="col-xs-12 col-sm-6">
                  <div className={classes.fieldTitle}>
                    Payment Frequency Option
                  </div>
                  <div className={classes.formField}>
                    <Select
                      isError={Boolean(errors.paymentOption)}
                      value={{ label: paymentOption, value: paymentOption }}
                      onChange={(selectedFrequency) => {
                        handleChange("paymentOption", selectedFrequency.value);
                      }}
                      options={paymentFrequencyOptions}
                      isSearchable
                      placeholder="Select"
                      name="select-frequency"
                      size="small"
                    />
                  </div>
                </div>
              )}
              <div className="col-xs-12 col-sm-6">
                <div className={classes.fieldTitle}>Sold By</div>
                <div className={classes.formField}>
                  <Select
                    isError={Boolean(errors.soldBy)}
                    value={soldBy}
                    onChange={(soldBy) => {
                      handleChange("soldBy", soldBy);
                    }}
                    options={activeUsers}
                    isSearchable
                    placeholder="Select"
                    name="select-membership-type"
                    size="small"
                  />
                </div>
              </div>
            </div>

            <div>
              {membershipTierType?.tier_products?.length > 0 && (
                <div>
                  <div className={classes.fieldTitle}>
                    Free Monthly Products
                  </div>
                  <div className={classes.formField}>
                    <textarea
                      className={classes.freeProductsDisplayBox}
                      row="2"
                      name="free-products"
                      value={membershipTierType?.tier_products
                        .map((productDetails) => {
                          return `${productDetails.product.product_name} - ${productDetails.units} units`;
                        })
                        .join(", ")}
                    ></textarea>
                  </div>
                </div>
              )}

              {membershipTierType?.tier_products?.length > 0 &&
                membershipTierType?.discount_percentage > 0 && (
                  <div>
                    <div className={classes.formField}>
                      Discount on all items{" "}
                      {numberFormat(
                        membershipTierType?.discount_percentage,
                        "decimal",
                        2,
                      ) + "%"}
                    </div>
                  </div>
                )}
            </div>

            {/*  Profile Details :  */}
            <div className="row">
              <div
                className={cx("col-xs-12", classes.fieldsReponsiveContainer)}
              >
                <div className={classes.fieldsContainer}>
                  <div className={classes.field}>
                    <div className={classes.fieldTitle}><AppTranslation.Common path="label.patient" /> Email</div>
                    <div className={classes.formField}>
                      <Input
                        size="small"
                        isDisabled={Boolean(clientData.email)}
                        value={patientEmail}
                        name="patientEmail"
                        placeholder="Email"
                        type="text"
                        onChange={handleChangeField}
                      />
                    </div>
                  </div>
                  {paymentOption?.toLowerCase() === "monthly" && (
                    <>
                      <div className={classes.field}>
                        <div className={classes.fieldTitle}>START DATE</div>
                        <div className={classes.formField}>
                          <DatePicker
                            onChange={handleChangeStartDate}
                            minDate={new Date()}
                            autoComplete="off"
                            className={classes.startDate}
                            name="subscription_started_at"
                            showMonthDropdown
                            showYearDropdown
                            selected={subscriptionStartsAt}
                            open={isDatePickerOpen}
                            onFocus={toggleDatePicker}
                            onClickOutside={toggleDatePicker}
                          />
                        </div>
                      </div>
                      <div className={classes.field}>
                        <div className={classes.fieldTitle}>
                          MEMBERSHIP FEE CHARGE DAY
                        </div>
                        <div className={classes.formField}>
                          <Select
                            value={{ label: feeChargeDay, value: feeChargeDay }}
                            onChange={(chargeDay) => {
                              handleChange("feeChargeDay", chargeDay.value);
                            }}
                            options={monthDates}
                            isSearchable
                            placeholder="Select"
                            name="select-fee-charge-day"
                            size="small"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className={classes.field}>
                    <div className={classes.fieldTitle}>COUPON CODE</div>
                    <div
                      className={cx(classes.formField, classes.coupnCodeField)}
                    >
                      <Input
                        className={classes.coupnCodeDiv}
                        inputClassName={classes.coupnCodeInput}
                        size="small"
                        isDisabled={isCouponApplied}
                        value={couponCode}
                        name="couponCode"
                        placeholder="Enter code"
                        type="text"
                        onChange={handleChangeField}
                      />
                      <Button
                        size="small"
                        type="button"
                        isDisabled={!couponCode}
                        color={isCouponApplied ? "warn" : "primary"}
                        onClick={() => {
                          if (isCouponApplied) {
                            clearCouponCode();
                          } else {
                            validateCouponCode(1);
                          }
                        }}
                      >
                        {isCouponApplied ? "Cancel" : "Apply"}
                      </Button>
                    </div>
                    {isCouponApplied && (
                      <div className={classes.couponAppliedText}>
                        "{couponCode}" code applied successfully to next full
                        installment(s)
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {Boolean(displayWaveSetupFee || isSetupFeeWaived) && (
                <span
                  className={classes.waiveSetupFeeCheckbox}
                  onClick={handleToggleWaiveSetupFee}
                >
                  <input
                    type="checkbox"
                    name="waive-setup-fee"
                    checked={isSetupFeeWaived}
                    className="filled-checkbox mr-10"
                  />
                  <span>Waive Setup Fee</span>
                </span>
              )}
              {(membershipFeeScheduleDetails?.data?.data?.fee_schedule?.[0]?.pro_rated_days || chargeFullFee) ?
                <span className={classes.waiveSetupFeeCheckbox} onClick={handleToggleChargeFull} >
                  <input
                    type="checkbox"
                    name="waive-setup-fee"
                    checked={chargeFullFee}
                    className="filled-checkbox mr-10"
                  />
                  <span>Charge Full Membership Fee</span>
                </span>
                : ""
              }
            </div>
            {/* <FeeSchedule /> */}
            <MembershipFeeSchedule />
            <div>
              <MembershipPayment
                clearentClinic={selectedClinic}
                clientId={clientId}
                updateProceedState={updateProceedState}
                updateCardSelected={updateCardSelected}
                validation={validation}
                onSubmit={handleSubmit(saveNewMembership)}
                isAddMembership={true}
                selectedClinicId={selectedClinic.id}
                clearentCardsList={clearentCardsList}
                selectedClearentCard={selectedClearentCard}
                changeClearentCard={(value) => setValue("selectedClearentCard", value)}
                cardOnFile={cardOnFile}
                setCardOnFile={setCardOnFile}
              />
            </div>
            {displayProceedButton && userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe &&
              <div className={classes.footer}>
                <button type="submit" className="new-blue-btn">Proceed</button>
              </div>
            }
            {userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
              <div className={classes.footer}>
                <Button type="submit" size="small">
                  Proceed
                </Button>
              </div>
            }
          </div>
        </form>
        <>
          {agreementDetails.openAgreementModal && (
            <ConfirmModal
              className={cx("confirm-modal-center", classes.agreementModal)}
              isOpen={agreementDetails.openAgreementModal}
              onClose={() => acceptAgreement(false)}
              title="Membership Agreement"
            >
              <>
                <Scrollbars
                  style={{ height: "66vh" }}
                  className="juvly-Agreement-content"
                >
                  <div className={classes.agreementContainer}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: agreementDetails.agreementText,
                      }}
                      className="grow"
                    />

                    <ClientSignatureCanvas
                      membershipSketchRef={membershipSketchRef}
                    />

                    <div className={classes.footer}>
                      <Button
                        type="button"
                        size="small"
                        onClick={() => acceptAgreement(true)}
                      >
                        Agree
                      </Button>
                    </div>
                  </div>
                </Scrollbars>
              </>
            </ConfirmModal>
          )}
        </>
      </FormProvider>
      {Boolean(isAddingNewMembership) && <Loader showLoader />}
    </CommonDialog>
  );
};

const mapStateToProps = (state) => ({
  stripeIntent: state.StripeReducer.stripeIntent,
  cardList: state.StripeReducer.cardList?.filter((c) => c.card.last4),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClientCardDataStripe
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMembershipModal);
