import { useAppTranslation } from "../../i18n/useAppTranslation";
import { uiNotification } from "../../services/UINotificationService";
import { useTwilioTranscriptionQuery } from "../../api/twilio/useTwilioTranscriptionQuery";
import { extractApiError } from "../../utilities/api";

export function TwilioTranscription({ appointmentId, onChange }) {
  const { tCommon } = useAppTranslation.Common();

  useTwilioTranscriptionQuery({
    payload: {
      appointmentId: Number(appointmentId),
    },
    options: {
      enabled: Boolean(appointmentId),
      retry: 0,
      onError: (error) => {
        const message = extractApiError(error);
        uiNotification.error(
          message || tCommon("twilio.transcription.error.fetch"),
        );
      },
      onSuccess: (data) => {
        if (data.transcriptionText) {
          onChange?.(data.transcriptionText);
        } else {
          uiNotification.error(
            tCommon("twilio.transcription.error.notAvailable"),
          );
        }
      },
    },
  });

  return null;
}
