import React from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Box } from "../../../../../../../shared/Box/Box";
import styles from "./styles.module.scss";

export const Total = ({ value }) => {
  const { tBi } = useAppTranslation.BusinessInsights();

  return (
    <Box className={styles.root}>
      <span>{tBi("shortTermLiabilityReport.total")}</span>
      <span>{value}</span>
    </Box>
  );
};
