import { useMemo } from "react";
import { useSettingViews } from "../../../../../hooks/useSettingViews";
import { Maybe } from "../../../../../../../../utilities/fp";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import {
  GRAPH_FIELDS,
  SETTING_VIEW_NAMES,
} from "../../../../../../../../api/patientVitals/config";

const SETTING_VIEW_GRAPH_FIELD_MAP = {
  [SETTING_VIEW_NAMES.weight]: [GRAPH_FIELDS.weight],
  [SETTING_VIEW_NAMES.bmi]: [GRAPH_FIELDS.bmi],
  [SETTING_VIEW_NAMES.pulse]: [GRAPH_FIELDS.pulse],
  [SETTING_VIEW_NAMES.bodyFat]: [GRAPH_FIELDS.bodyFat],
  [SETTING_VIEW_NAMES.respiration]: [GRAPH_FIELDS.respiration],
  [SETTING_VIEW_NAMES.temperature]: [GRAPH_FIELDS.temperature],
  [SETTING_VIEW_NAMES.bloodPressure]: [
    GRAPH_FIELDS.bloodPressureSystolic,
    GRAPH_FIELDS.bloodPressureDiastolic,
  ],
};

export const useFieldOptions = () => {
  const { tClients } = useAppTranslation.Clients();
  const settingViews = useSettingViews();

  return useMemo(
    () =>
      Maybe.of(settingViews.data)
        .map((v) =>
          v.filter((setting) => setting in SETTING_VIEW_GRAPH_FIELD_MAP),
        )
        .map((v) =>
          v.reduce((c, i) => [...c, ...SETTING_VIEW_GRAPH_FIELD_MAP[i]], []),
        )
        .map((v) => {
          return v.map((i) => ({
            label: tClients(`vitals.graph.fieldOptions.${i}`),
            value: i,
          }));
        })
        .orElse([])
        .value(),
    [settingViews.data],
  );
};
