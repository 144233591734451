import React from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { history } from "../../../../history";
import { BI_ROUTES } from "../../../../consts/routes";
import { StaticReportsBox } from "../../components/StaticReportsBox/StaticReportsBox";

export function AppointmentsStaticReports() {
  const { tBi } = useAppTranslation.BusinessInsights();

  const reports = [
    {
      key: 1,
      label: tBi("retentionRateReport.label"),
      isVisible: true,
      onClick: () => history.push(BI_ROUTES.retentionRateReport()),
    },
    {
      key: 2,
      label: tBi("retentionRateDetailsReport.label"),
      isVisible: true,
      onClick: () => history.push(BI_ROUTES.retentionRateDetailsReport()),
    },
  ];

  return <StaticReportsBox reports={reports} />;
}
