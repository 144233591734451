import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../api/queryClient";
import { http } from "../../services/HttpService";
import formatUrlParams from "../../Utils/formatUrlParams";
import { QueryKeys } from "./QueryKeys";

const GetPriceList = (payload) => {
  const params = formatUrlParams({
    clinic_id: payload.clinic,
    sortby: payload.sort_by,
    sortdir: payload.sort_dir,
    perpage: payload.per_page,
    page: payload.page,
    term: payload.search,
    type: payload.type,
  });
  return http.get(`/price/list`, { params });
};

export const useGetPriceListQuery = (payload) => {
  const { params } = payload;
  return useQuery(
    [QueryKeys.GET_PRICELIST, payload],
    () => GetPriceList(params),
    {
      enabled: Boolean(params.type && params.per_page && params.clinic),
    },
  );
};

const BulkUpdatePriceList = (data) => {
  return http.post(`/price/bulk-update`, data);
};

export const useBulkUpdatePriceListQuery = () => {
  return useMutation(BulkUpdatePriceList, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GET_PRICELIST]);
    },
  });
};
