/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  fetchProcedureQuestionnaire,
  updateProcedureQuestionnaire,
  getQuestionnaireById,
} from "../../../Actions/Settings/settingsActions.js";
import { displayName } from "../../../Utils/services.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class ProviderQuestionnaires extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      id: props.match.params.id,
      questionsAllData: [],
      questionsMultipleData: [],
      questionspatientData: {},
      defaultOptions: [],
      selectedOption: null,
      userChanged: false,
      select_Default_Clinic: [],
      showCommentBox: false,
      showLoader: false,
      value: null,
      options: null,
      clinics_array: [],
      yes: null,
      no: null,
      consultation_title: "accordion-discription-row consultTitle no-display",
      checkBox: "",
      roomsLang: languageData.rooms,
      dataList: [
        {
          questionnaires: [
            {
              id: null,
              questions: [
                {
                  id: null,
                  question_type: "yesno",
                  answers: [
                    {
                      answer: null,
                      comment: "string",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      roomType: this.props.match.url.split("/")[1],
      action: props.match.params.type ? props.match.params.type : "pending",
    };
    window.onscroll = () => {
      return false;
    };
  }
  componentDidMount() {
    if (this.state.id) {
      this.setState({ showLoader: true });
      this.props.fetchProcedureQuestionnaire(this.state.id);
    } else {
      this.props.fetchAllQuestionnaires();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.questionsData !== undefined &&
      nextProps.questionsData != prevState.questionsData
    ) {
      let selectedQ = [],
        returnState = {};
      if (
        nextProps.questionsData.questionnaires &&
        nextProps.questionsData.questionnaires &&
        prevState.userChanged == false
      ) {
        selectedQ = nextProps.questionsData.questionnaires;
        selectedQ.map((obj) => {
          returnState["q-class-" + obj.id] = "accordion-title";
          if (obj.questions) {
            obj.questions.map((a) => {
              if (a.question_type == "yesno") {
                if (a.answers.length) {
                  returnState["q-" + obj.id + "-" + a.id + "-yesno"] =
                    a.answers[0].answer;
                  if (a.answers[0].answer) {
                    returnState["q-" + obj.id + "-" + a.id + "-comment"] =
                      a.answers[0].comment;
                  }
                }
              } else {
                a.question_choices.map((x) => {
                  let index = a.answers.findIndex(
                    (y) => y.question_choice_id === x.id,
                  );
                  if (a.multiple_selection) {
                    returnState["q-" + obj.id + "-" + a.id + "-" + x.id] =
                      index > -1 ? 1 : 0;
                  } else {
                    let selection = a.answers.find(
                      (y) => y.question_choice_id === x.id,
                    );
                    if (selection) {
                      returnState["q-" + obj.id + "-" + a.id] = x.id;
                    }
                  }
                });
              }
            });
          }
        });
      }
      let questionnaireList = nextProps.questionsData.questionnaires
        ? nextProps.questionsData.questionnaires
        : [];
      questionnaireList.map((obj, idx) => {
        questionnaireList[idx]["is_filled"] = false;
        let isFilled = obj.questions.find(
          (x) => x.answers && x.answers.length > 0,
        );
        if (isFilled) {
          questionnaireList[idx]["is_filled"] = true;
        }
      });

      returnState.questionsData = nextProps.questionsData;
      returnState.selectedQuestionnaire = questionnaireList;
      returnState.questionsAllData = nextProps.questionsData.all_questionnaries;
      returnState.questionspatientData = nextProps.questionsData.patient
        ? nextProps.questionsData.patient
        : {};
      returnState.showLoader = false;
      return returnState;
    }

    if (
      nextProps.questionnaireData !== undefined &&
      nextProps.questionnaireData.status === 200 &&
      nextProps.questionnaireData.data != prevState.questionnaireData
    ) {
      let tempQuestionnaire = prevState.selectedQuestionnaire;
      tempQuestionnaire.push(nextProps.questionnaireData.data);
      return {
        selectedQuestionnaire: tempQuestionnaire,
        questionnaireData: nextProps.questionnaireData.data,
        showLoader: false,
      };
    }
    if (nextProps.showLoader == false) {
      return { showLoader: false };
    }
    return null;
  }

  handleImageChange = (event) => {
    let val = event.currentTarget.dataset.id;
    let noMultiVal = event.currentTarget.dataset.val;

    this.setState({
      [val]: noMultiVal ? noMultiVal : !this.state[val],
      userChanged: true,
    });
  };

  handleChange = (_selectedOption, x) => {
    if (x.action != "remove-value") {
      let obj = this.state.questionsAllData.find(
        (y) => y.id === x.option.value,
      );
      this.setState({ showLoader: true });
      this.props.getQuestionnaireById(obj.id);
    } else {
      let tempQuestionnaire = this.state.selectedQuestionnaire;
      let index = tempQuestionnaire.findIndex(
        (y) => y.id === x.removedValue.value,
      );

      let key;
      let stateObj = this.state;

      for (key in stateObj) {
        if (stateObj.hasOwnProperty(key)) {
          if (key.startsWith("q-" + x.removedValue.value)) {
            delete stateObj[key];
          }
          if (stateObj["q-class-" + x.removedValue.value]) {
            delete stateObj["q-class-" + x.removedValue.value];
          }
        }
      }

      tempQuestionnaire.splice(index, 1);
      this.setState({
        selectedQuestionnaire: tempQuestionnaire,
      });
    }
  };

  handleSubmit = () => {
    let formData = {},
      flag = false,
      errorState = {};
    formData.questionnaires = [];

    if (this.state.selectedQuestionnaire) {
      this.state.selectedQuestionnaire.map((obj) => {
        let questions = [];
        if (obj.questions) {
          obj.questions.map((a) => {
            let answers = [];
            if (a.question_type == "yesno") {
              if (
                this.state["q-" + obj.id + "-" + a.id + "-yesno"] != undefined
              ) {
                answers.push({
                  answer: this.state["q-" + obj.id + "-" + a.id + "-yesno"],
                  comment: this.state["q-" + obj.id + "-" + a.id + "-comment"]
                    ? this.state["q-" + obj.id + "-" + a.id + "-comment"]
                    : "",
                });
              }
            } else {
              if (
                a.question_choices.length > 0 &&
                a.question_choices[0].multiple_selection == 1
              ) {
                a.question_choices.map((x) => {
                  if (this.state["q-" + obj.id + "-" + a.id + "-" + x.id]) {
                    answers.push({ question_choice_id: x.id });
                  }
                });
              } else {
                if (this.state["q-" + obj.id + "-" + a.id]) {
                  answers.push({
                    question_choice_id: this.state["q-" + obj.id + "-" + a.id],
                  });
                }
              }
            }
            if (answers.length) {
              questions.push({
                id: a.id,
                question_type: a.question_type,
                answers: answers,
              });
            }
          });
        }
        if (!questions.length) {
          errorState["q-class-" + obj.id] = " field-error";
          flag = true;
        } else {
          errorState["q-class-" + obj.id] = "";
        }
        formData.questionnaires.push({ id: obj.id, questions: questions });
      });
    }

    if (flag) {
      this.setState(errorState);
      uiNotification.error(
        this.state.roomsLang.rooms_please_answer_one_question,
      );
      return false;
    }
    errorState.showLoader = true;
    this.setState(errorState);
    this.props.updateProcedureQuestionnaire(this.state.id, formData);
  };

  handleDrop = (event) => {
    this.setState({
      active_consultation: event.currentTarget.dataset.id,
      consultation_title: "accordion-discription-row consultTitle display",
    });
  };

  addComment = () => {
    this.setState({ showCommentBox: true });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
      userChanged: true,
    });
  };

  editQuestionnaire = (questionnaireID, _isFilled, event) => {
    event.preventDefault();
    if (this.state.roomType == "md-room") {
      return false;
    }
    let returnState = {};
    const state = this.state;
    Object.keys(state).forEach(function (key) {
      if (typeof key === "string" && key.startsWith("showQuestionnaire-")) {
        returnState[key] = false;
      }
    });
    returnState["showQuestionnaire-" + questionnaireID] = true;
    this.setState(returnState);
  };

  render() {
    var defaultOptions = [];
    var options = [];
    if (
      this.state.selectedQuestionnaire != undefined &&
      this.state.selectedQuestionnaire.length
    ) {
      this.state.selectedQuestionnaire.map((obj) => {
        defaultOptions.push({ value: obj.id, label: obj.consultation_title });
      });
    }

    if (
      this.state.questionsAllData != undefined &&
      this.state.questionsAllData.length > 0
    ) {
      options = this.state.questionsAllData.map((obj) => {
        return { value: obj.id, label: obj.consultation_title };
      });
    }

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="wide-popup">
            <div className="modal-blue-header questHealtimeTitle">
              <a
                onClick={() => this.props.history.goBack()}
                className="popup-cross"
              >
                ×
              </a>
              {this.state.showLoader === false && this.state.questionsData && (
                <span className="popup-blue-name">
                  {displayName(this.state.questionspatientData)} -{" "}
                  {this.state.questionsData &&
                    this.state.questionsData.procedure_name}{" "}
                  - {this.state.roomsLang.rooms_questionnnaires_details}
                </span>
              )}
            </div>
            <div className="wide-popup-wrapper time-line questHealthTimeline">
              {this.state.roomType !== "md-room" && (
                <div className="list-all-questionnaire">
                  <div className="setting-field-outer m-b-20">
                    <div className="new-field-label">{"Add Questionnaire"}</div>
                    <div className="setting-input-outer">
                      <div className="tag-auto-select">
                        {options && (
                          <Select
                            onChange={this.handleChange}
                            value={defaultOptions}
                            isClearable
                            isSearchable
                            options={options}
                            isMulti={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="accordion questionnaireAccordion"
                id="accordionExample"
              >
                {this.state.selectedQuestionnaire &&
                  this.state.selectedQuestionnaire.map((obj, idx) => {
                    return (
                      <div
                        key={"questionnaireList-" + idx}
                        className={`membershipAccordion ${
                          this.state["q-class-" + obj.id]
                        }`}
                      >
                        <div
                          className="membershipAccordionHead"
                          id={"headingOne" + idx}
                        >
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link cursor-default"
                              type="button"
                              data-toggle={obj.is_filled ? "collapse" : ""}
                              data-target={"#questionnaire-" + obj.id}
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <span>
                                {idx + 1}. {obj.consultation_title}{" "}
                              </span>
                              {this.state.roomType != "md-room" && (
                                <span
                                  onClick={this.editQuestionnaire.bind(
                                    this,
                                    obj.id,
                                    obj.is_filled,
                                  )}
                                  style={{ float: "right" }}
                                  className={
                                    "easy-link no-padding cursor-pointer"
                                  }
                                >
                                  {obj.is_filled ? (
                                    <i className="fa fa-check m-r-5 text-success"></i>
                                  ) : (
                                    <i className="fa fa-times m-r-5 text-danger"></i>
                                  )}
                                  Edit
                                </span>
                              )}
                            </button>
                          </h2>
                        </div>
                        <div
                          id={"questionnaire-" + obj.id}
                          className={
                            this.state["showQuestionnaire-" + obj.id] == true
                              ? "collapse show"
                              : "collapse"
                          }
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className={"proQuesAccordionOuter"}>
                            {/* COSMETIC QUESTIONNAIRE - START */}
                            {obj.questions &&
                              obj.questions.map((obj1) => {
                                return (
                                  <div
                                    key={"templateQuestionList-" + obj1.id}
                                    className="accordion-discription-row"
                                  >
                                    <div
                                      className="accordian-section col-xs-6 no-border-left"
                                      name="questionId"
                                      value={obj1.id}
                                    >
                                      {obj1.question}
                                    </div>
                                    {obj1.question_type === "yesno" && (
                                      <div className="accordian-section col-xs-6 border-left">
                                        <div>
                                          <div className="basic-checkbox-outer">
                                            <input
                                              id="yes"
                                              type="radio"
                                              checked={
                                                this.state[
                                                  "q-" +
                                                    obj.id +
                                                    "-" +
                                                    obj1.id +
                                                    "-yesno"
                                                ] == 1
                                                  ? "checked"
                                                  : false
                                              }
                                              onChange={this.handleInputChange}
                                              className="basic-form-checkbox"
                                              name={
                                                "q-" +
                                                obj.id +
                                                "-" +
                                                obj1.id +
                                                "-yesno"
                                              }
                                              value="1"
                                            />
                                            <label
                                              className="basic-form-text"
                                              htmlFor="yes"
                                            >
                                              {this.state.roomsLang.yes_text}
                                            </label>
                                          </div>
                                          <div className="basic-checkbox-outer">
                                            <input
                                              id="no"
                                              type="radio"
                                              checked={
                                                this.state[
                                                  "q-" +
                                                    obj.id +
                                                    "-" +
                                                    obj1.id +
                                                    "-yesno"
                                                ] == 0
                                                  ? "checked"
                                                  : false
                                              }
                                              onChange={this.handleInputChange}
                                              className="basic-form-checkbox"
                                              name={
                                                "q-" +
                                                obj.id +
                                                "-" +
                                                obj1.id +
                                                "-yesno"
                                              }
                                              value="0"
                                            />
                                            <label
                                              className="basic-form-text"
                                              htmlFor="no"
                                            >
                                              {
                                                this.state.roomsLang
                                                  .rooms_no_text
                                              }
                                            </label>
                                          </div>
                                          {this.state[
                                            "q-" +
                                              obj.id +
                                              "-" +
                                              obj1.id +
                                              "-yesno"
                                          ] == 1 && (
                                            <div className="setting-field-outer m-t-20 m-b-0">
                                              <div className="new-field-label">
                                                {
                                                  this.state.roomsLang
                                                    .rooms_type_your_answer
                                                }
                                              </div>
                                              <textarea
                                                className="setting-textarea-box"
                                                cols="30"
                                                rows="2"
                                                name={
                                                  "q-" +
                                                  obj.id +
                                                  "-" +
                                                  obj1.id +
                                                  "-comment"
                                                }
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                value={
                                                  this.state[
                                                    "q-" +
                                                      obj.id +
                                                      "-" +
                                                      obj1.id +
                                                      "-comment"
                                                  ]
                                                }
                                              ></textarea>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {obj1.question_type === "multitext" && (
                                      <div className="accordian-section col-xs-6 border-left">
                                        {obj1.question_choices != undefined &&
                                          obj1.question_choices.map(
                                            (obj2, idx2) => {
                                              return (
                                                <div key={"multitext-" + idx2}>
                                                  {obj2.multiple_selection ==
                                                    1 && (
                                                    <div
                                                      key={"choices-" + idx2}
                                                    >
                                                      <div className="basic-checkbox-outer">
                                                        <input
                                                          id={
                                                            "q-" +
                                                            obj.id +
                                                            "-" +
                                                            obj1.id +
                                                            "-" +
                                                            obj2.id
                                                          }
                                                          type="checkbox"
                                                          defaultChecked={
                                                            obj2.is_selected ==
                                                            1
                                                              ? "checked"
                                                              : null
                                                          }
                                                          name={
                                                            "q-" +
                                                            obj.id +
                                                            "-" +
                                                            obj1.id +
                                                            "-" +
                                                            obj2.id
                                                          }
                                                          className="basic-form-checkbox"
                                                          onChange={
                                                            this
                                                              .handleInputChange
                                                          }
                                                        />
                                                        <label
                                                          className="basic-form-text"
                                                          htmlFor={
                                                            "q-" +
                                                            obj.id +
                                                            "-" +
                                                            obj1.id +
                                                            "-" +
                                                            obj2.id
                                                          }
                                                        >
                                                          {obj2.text}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {obj2.multiple_selection ==
                                                    0 && (
                                                    <div className="basic-checkbox-outer">
                                                      <input
                                                        id="yes"
                                                        type="radio"
                                                        checked={
                                                          this.state[
                                                            "q-" +
                                                              obj.id +
                                                              "-" +
                                                              obj1.id
                                                          ] == obj2.id
                                                            ? "checked"
                                                            : false
                                                        }
                                                        onChange={
                                                          this.handleInputChange
                                                        }
                                                        className="basic-form-checkbox"
                                                        name={
                                                          "q-" +
                                                          obj.id +
                                                          "-" +
                                                          obj1.id
                                                        }
                                                        value={obj2.id}
                                                      />
                                                      <label
                                                        className="basic-form-text"
                                                        htmlFor="yes"
                                                      >
                                                        {obj2.text}
                                                      </label>
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            },
                                          )}
                                      </div>
                                    )}
                                    {obj1.question_type === "multiimage" && (
                                      <div className="accordian-section col-xs-6 border-left">
                                        {obj1.question_choices != undefined &&
                                          obj1.question_choices.map(
                                            (obj2, idx2) => {
                                              return (
                                                <div
                                                  className="question-img-outer"
                                                  key={"multiimage-" + idx2}
                                                >
                                                  {obj2.multiple_selection ==
                                                    1 && (
                                                    <div
                                                      className={
                                                        this.state[
                                                          "q-" +
                                                            obj.id +
                                                            "-" +
                                                            obj1.id +
                                                            "-" +
                                                            obj2.id
                                                        ]
                                                          ? "question-img-outer sel"
                                                          : "question-img-outer"
                                                      }
                                                      key={
                                                        "multiquestion-" + idx2
                                                      }
                                                    >
                                                      <div
                                                        className="question-img"
                                                        data-id={
                                                          "q-" +
                                                          obj.id +
                                                          "-" +
                                                          obj1.id +
                                                          "-" +
                                                          obj2.id
                                                        }
                                                        onClick={
                                                          this.handleImageChange
                                                        }
                                                      >
                                                        <img
                                                          alt=""
                                                          src={obj2.image_url}
                                                        />
                                                      </div>
                                                      <div className="qusst-name">
                                                        {obj2.image_label}
                                                      </div>
                                                      <div className="check-quest"></div>
                                                    </div>
                                                  )}
                                                  {obj2.multiple_selection ==
                                                    0 && (
                                                    <div
                                                      className={
                                                        this.state[
                                                          "q-" +
                                                            obj.id +
                                                            "-" +
                                                            obj1.id
                                                        ] == obj2.id
                                                          ? "question-img-outer sel"
                                                          : "question-img-outer"
                                                      }
                                                      key={
                                                        "multiquestion-" + idx2
                                                      }
                                                    >
                                                      <div
                                                        className="question-img"
                                                        data-id={
                                                          "q-" +
                                                          obj.id +
                                                          "-" +
                                                          obj1.id
                                                        }
                                                        data-val={obj2.id}
                                                        onClick={
                                                          this.handleImageChange
                                                        }
                                                      >
                                                        <img
                                                          alt=""
                                                          src={obj2.image_url}
                                                        />
                                                      </div>
                                                      <div className="qusst-name">
                                                        {obj2.image_label}
                                                      </div>
                                                      <div className="check-quest"></div>
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            },
                                          )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            {/* COSMETIC QUESTIONNAIRE - END */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="footer-static">
              <button
                onClick={this.handleSubmit}
                className="new-blue-btn pull-right"
                value="Save"
              >
                {this.state.roomsLang.rooms_save}
              </button>
              <Link
                to="/provider-room/pending"
                className="new-white-btn pull-right"
              >
                {this.state.roomsLang.rooms_cancel}
              </Link>
            </div>
            <div
              className={
                this.state.showLoader
                  ? "new-loader text-left displayBlock"
                  : "new-loader text-left"
              }
            >
              <div className="loader-outer">
                <img
                  alt=""
                  id="loader-outer"
                  src="/images/Eclipse.gif"
                  className="loader-img"
                />
                <div id="modal-confirm-text" className="popup-subtitle">
                  {this.state.roomsLang.room_please_wait}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  uiNotification.clear();
  if (state.SettingReducer.action === "PROCEDURE_QUESTIONNAIRE") {
    if (state.SettingReducer.data.status === 200) {
      return {
        questionsData: state.SettingReducer.data.data,
      };
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
    }
    return {};
  }

  if (state.SettingReducer.action === "UPDATE_PROCEDURE_QUESTIONNAIRE") {
    if (state.SettingReducer.data.status === 200) {
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
      return {
        questionsData: state.SettingReducer.data.data,
      };
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      return { showLoader: false };
    }
  }

  if (state.SettingReducer.action === "GET_QUESTIONNAIRE") {
    let returnState = {};
    if (state.SettingReducer.data.status == 200) {
      returnState.questionnaireData = state.SettingReducer.data;
    } else {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
    return returnState;
  }
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProcedureQuestionnaire: fetchProcedureQuestionnaire,
      updateProcedureQuestionnaire: updateProcedureQuestionnaire,
      getQuestionnaireById: getQuestionnaireById,
    },
    dispatch,
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProviderQuestionnaires));
