import * as yup from "yup";

export const selectOptionSchema = yup.object({
  label: yup.string().required(),
  value: yup
    .mixed()
    .test({
      test: (value) => typeof value === "number" || typeof value === "string",
    })
    .required(),
});
