import { ACTION_TYPES } from "./consts";

const initialState = {
  isOpen: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.searchOpenChange: {
      return {
        ...state,
        isOpen: action.payload.isOpen || false,
      };
    }
    default: {
      return state;
    }
  }
}
