import React, { useMemo } from "react";
import { useSalesSummary } from "./hooks/useSalesSummary";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import Header from "../../../components/Header";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Table } from "../../../../../shared/Table/Table";
import { Box } from "../../../../../shared/Box/Box";
import summaryClasses from "./scss/salesSummary.module.scss";
import SummaryTable from "./components/SummaryTable";
import { useSalesSummaryReport } from "./hooks/useSalesSummaryReport";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { tBi } from "../../../../../i18n/useAppTranslation";
import DownloadDropdown from "../../../components/DownloadDropdown";

const modalInfo = {
  title: "salesSummaryReport.info.title",
  rows: [
    "salesSummaryReport.info.totalSalesReport",
    "salesSummaryReport.info.salesFromProceduresAndRetail",
    "salesSummaryReport.info.totalSales",
    "salesSummaryReport.info.netSales",
    "salesSummaryReport.info.depositSlipReport",
    "salesSummaryReport.info.paymentMethodNetReport",
    "salesSummaryReport.info.dailyDiscountReport",
  ],
};

const SalesSummary = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };

  const { data, isLoading } = useSalesSummary(requestParams);
  const {
    data: reportData,
    isLoading: isReportLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useSalesSummaryReport(requestParams);

  const tableDataEmpty =
    !isReportLoading && !reportData?.pages[0]?.reportData?.length;

  const redirectTo = (url, label) => {
    return (
      <a
        href={url}
        target="_blank"
        className={summaryClasses.newTabOpen}
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  };

  const totalSalesReport = useMemo(() => {
    const reportHeader = ["Total Sales Report", "(All Sources)"];
    const reportData = [
      {
        label: redirectTo(
          "/refactor-business-insight/sales/gross_sales",
          tBi("salesSummaryReport.staticData.procedureAndRetailSales"),
        ),
        value: data?.dailyTotalSales?.daily_gross_sales,
      },
      {
        label: redirectTo(
          "/refactor-business-insight/sales/membership_overview",
          tBi("membershipOverviewReport.label"),
        ),
        value: data?.dailyTotalSales?.daily_membership_revenue,
      },
      {
        label: "Wallet Credits",
        value: data?.dailyTotalSales?.pre_paid_products,
      },
      {
        label: redirectTo(
          "/business-insight/sales/egift_cards_revenue",
          tBi("salesSummaryReport.staticData.giftCardPurchases"),
        ),
        value: data?.dailyTotalSales?.gift_card_purchase,
      },
      {
        label: "Appointment Cancellation Fee",
        value: data?.dailyTotalSales?.cancellation_feecollected,
      },
    ];

    const negativeDatas = [
      {
        label: "Wallet Debits",
        value: data?.dailyTotalSales?.wallet_debits,
      },
      {
        label: "eGift Cards Redemption",
        value: data?.dailyTotalSales?.gift_card_redemptions,
      },
      {
        label: "Refunds (Total)",
        value: data?.dailyTotalSales?.refunds,
      },
      {
        label: redirectTo(
          "/refactor-business-insight/sales/gross_sales",
          tBi("salesSummaryReport.staticData.tips"),
        ),
        value: data?.dailyTotalSales?.tips,
      },
      {
        label: "Tax",
        value: data?.dailyTotalSales?.tax,
      },
      {
        label: "Processing & Business Fees",
        value: data?.dailyTotalSales?.processing_and_bussiness_fees,
      },
      {
        label: "Discount & Complimentary",
        value: data?.dailyTotalSales?.discount_complementry,
      },
      {
        label: "Membership Coupons",
        value: data?.dailyTotalSales?.membership_coupons,
      },
    ];

    const total = {
      label: "Total Net Sales",
      value: data?.dailyTotalSales?.daily_total_net_Sales,
    };
    return { reportHeader, reportData, negativeDatas, total };
  }, [data]);

  const depositSlipReport = useMemo(() => {
    const reportHeader = ["Deposit Slip Report", ""];

    const reportData = [
      {
        label: redirectTo(
          "/refactor-business-insight/sales/gross_sales",
          tBi("salesSummaryReport.staticData.procedureAndRetailSales"),
        ),
        value: data?.dailyDepositSlip?.deposit_daily_gross_sales,
      },
      {
        label: redirectTo(
          "/refactor-business-insight/sales/membership_overview",
          tBi("membershipOverviewReport.label"),
        ),
        value: data?.dailyDepositSlip?.deposit_daily_membership_revenue,
      },
      {
        label: "Wallet Credits",
        value: data?.dailyDepositSlip?.deposit_pre_paid_products,
      },
      {
        label: redirectTo(
          "/business-insight/sales/egift_cards_revenue",
          tBi("salesSummaryReport.staticData.giftCardPurchases"),
        ),
        value: data?.dailyDepositSlip?.gift_card_purchase,
      },
      {
        label: "Appointment Cancellation Fee",
        value: data?.dailyDepositSlip?.cancellation_feecollected,
      },
    ];

    const negativeDatas = [
      {
        label: "Wallet Debits",
        value: data?.dailyDepositSlip?.deposit_wallet_debits,
      },
      {
        label: "Discount & Complimentary",
        value: data?.dailyDepositSlip?.deposit_discount_complementry,
      },
      {
        label: "Membership Coupons",
        value: data?.dailyDepositSlip?.deposit_membership_coupons,
      },
      {
        label: "Evolus Rewards",
        value: data?.dailyDepositSlip?.deposit_evolus_coupons,
      },
      {
        label: "eGift Cards Redemption",
        value: data?.dailyDepositSlip?.deposit_gift_card,
      },
      {
        label: "Refunds",
        value: data?.dailyDepositSlip?.deposit_refunds,
      },
      {
        label: "Processing & Business Fees",
        value: data?.dailyDepositSlip?.deposit_processing_and_bussiness_fees,
      },
    ];

    const total = {
      label: "Deposit Amount",
      value: data?.dailyDepositSlip?.daily_deposit_total_net_Sales,
    };
    return { reportHeader, reportData, negativeDatas, total };
  }, [data]);

  const paymentMethodNetReport = useMemo(() => {
    const reportHeader = ["Payment Method Net Report", ""];

    const reportData = [
      {
        label: "Cash",
        value: data?.dailyPaymentMethod?.daily_payment_cash,
      },
      {
        label: "Check",
        value: data?.dailyPaymentMethod?.daily_payment_check,
      },
      {
        label: "Credit Card",
        value: data?.dailyPaymentMethod?.daily_payment_credit,
      },
      {
        label: "Cherry",
        value: data?.dailyPaymentMethod?.daily_payment_cherry,
      },
      {
        label: "Greensky",
        value: data?.dailyPaymentMethod?.daily_payment_greensky,
      },
      {
        label: "Care Credit",
        value: data?.dailyPaymentMethod?.daily_payment_care_credit,
      },
      {
        label: "Aspire Coupons",
        value: data?.dailyPaymentMethod?.daily_payment_aspire_coupons,
      },
      {
        label: "Allē Coupons",
        value: data?.dailyPaymentMethod?.daily_payment_bd_coupons,
      },
      {
        label: "Xperience Coupons",
        value: data?.dailyPaymentMethod?.daily_payment_xperience_coupons,
      },
      {
        label: "Link",
        value: data?.dailyPaymentMethod?.daily_payment_link,
      },
      {
        label: "Klarna",
        value: data?.dailyPaymentMethod?.daily_payment_klarna,
      },
    ];

    const total = {
      label: "Total Payments",
      value: data?.dailyPaymentMethod?.daily_payment_total_net_Sales,
    };
    return { reportHeader, reportData, total };
  }, [data]);

  const tableData = useMemo(
    () =>
      reportData?.pages
        ?.map((p) => p.reportData)
        .flat()
        .map((data) => ({
          patientId: data.patient_id,
          invoiceId: data.invoice_id,
          patient: data.patient_name,
          discountType: data.discount_type,
          provider: data.provider,
          checkoutPerson: data.checkout_person,
          discountAmount: data.amount,
        })),
    [reportData],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const goToInvoice = (row) => {
    window.open(`/sales/invoice/${row.invoiceId}/${row.patientId}`);
  };

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Sales Summary"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        requestParams={requestParams}
        downloadRoute={HTTP_ENDPOINTS.getDailySalesReport()}
        modalInfo={modalInfo}
      />
      <div className={summaryClasses.reportsContainer}>
        <SummaryTable report={totalSalesReport} isLoading={isLoading} />
        <SummaryTable report={paymentMethodNetReport} isLoading={isLoading} />
        <SummaryTable report={depositSlipReport} isLoading={isLoading} />
      </div>
      <div className="flex-col reportTable">
        <Box className="flex justify-between items-center">
          <h3 className={summaryClasses.reportTitle}>
            {tBi("salesSummaryReport.table.label")}
          </h3>
          <DownloadDropdown
            params={requestParams}
            downloadRoute={HTTP_ENDPOINTS.getDailyDiscountReport()}
          />
        </Box>
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Patient", accessor: "patient" },
              { data: "Discount Type", accessor: "discountType" },
              { data: "Provider", accessor: "provider" },
              { data: "Checkout Person", accessor: "checkoutPerson" },
              { data: "Discount Amount", accessor: "discountAmount" },
            ]}
            data={tableData}
            onRowClick={(row) => goToInvoice(row)}
          />
          {hasNextPage && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={fetchNextPage}
              isLoading={isFetchingNextPage}
            />
          )}
          {isReportLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesSummary;
