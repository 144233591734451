import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Radio.module.scss";

export function Radio({
  name,
  label,
  isChecked,
  onChange,
  isDisabled,
  className,
  labelClassName,
  checkMarkClassName,
}) {
  return (
    <label
      className={cx(
        classes.root,
        {
          [classes.disabled]: isDisabled,
        },
        className,
      )}
    >
      <span className={cx(classes.label, labelClassName)}>{label}</span>
      <input
        type="radio"
        name={name}
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />
      <span className={cx(classes.checkMark, checkMarkClassName)}></span>
    </label>
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  checkMarkClassName: PropTypes.string,
};

Radio.defaultProps = {
  isDisabled: false,
  className: undefined,
  labelClassName: undefined,
  checkMarkClassName: undefined,
};
