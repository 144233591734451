import { useEffect, useState } from "react";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";

export function useSelectedClinic(clinicsList) {
  const { tSettings } = useAppTranslation.Settings();

  const [clinic, setClinic] = useState(null);

  const selectClinic = ({ next, isTouched }) => {
    if (
      !isTouched ||
      window.confirm(tSettings("discountPackages.obOrder.confirm.changeClinic"))
    ) {
      setClinic(next);
    }
  };

  useEffect(() => {
    if (!clinic && clinicsList?.length > 0) {
      setClinic(clinicsList[0]);
    }
  }, [clinic, clinicsList?.length]);

  return {
    selectedClinic: clinic,
    selectClinic,
  };
}
