export const tFnNames = {
  Common: "tCommon",
  Auth: "tAuth",
  Settings: "tSettings",
  BusinessInsights: "tBi",
  Sales: "tSales",
  Inbox: "tInbox",
  Clients: "tClients",
  Appointments: "tAppointments",
  Inventory: "tInventory",
  Dashboard: "tDashboard",
  SmartConfig: "tSmartConfig",
};
