const nameInitialState = {
  action: "",
};

const integrationsData = (state = nameInitialState, action) => {
  switch (action.type) {
    case "GET_SYNC_DATA": {
      return { ...state, data: action.payload, action: "GET_SYNC_DATA" };
    }
    case "SET_SYNC_CONFIG": {
      return { ...state, data: action.payload, action: "SET_SYNC_CONFIG" };
    }

    case "GET_EDIT_DATA": {
      return { ...state, data: action.payload, action: "GET_EDIT_DATA" };
    }

    case "GET_ZOHO_DATA": {
      return { ...state, data: action.payload, action: "GET_ZOHO_DATA" };
    }

    case "SHARE_ZOHO_DATA": {
      return { ...state, data: action.payload, action: "SHARE_ZOHO_DATA" };
    }

    case "GET_KEAP_DATA": {
      return { ...state, data: action.payload, action: "GET_KEAP_DATA" };
    }

    case "SHARE_KEAP_DATA": {
      return { ...state, data: action.payload, action: "SHARE_KEAP_DATA" };
    }

    case "GET_INTERCOM_DATA": {
      return { ...state, data: action.payload, action: "GET_INTERCOM_DATA" };
    }

    case "SHARE_INTERCOM_DATA": {
      return { ...state, data: action.payload, action: "SHARE_INTERCOM_DATA" };
    }

    case "GET_SHOPIFY_DATA": {
      return { ...state, data: action.payload, action: "GET_SHOPIFY_DATA" };
    }

    case "SHARE_SHOPIFY_DATA": {
      return { ...state, data: action.payload, action: "SHARE_SHOPIFY_DATA" };
    }

    case "GET_HUBSPOT_DATA": {
      return { ...state, data: action.payload, action: "GET_HUBSPOT_DATA" };
    }

    case "SHARE_HUBSPOT_DATA": {
      return { ...state, data: action.payload, action: "SHARE_HUBSPOT_DATA" };
    }

    case "GET_MAILCHIMP_DATA": {
      return { ...state, data: action.payload, action: "GET_MAILCHIMP_DATA" };
    }

    case "SHARE_MAILCHIMP_DATA": {
      return { ...state, data: action.payload, action: "SHARE_MAILCHIMP_DATA" };
    }
    case "GET_ACTIVE_COMPAIGN_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "GET_ACTIVE_COMPAIGN_DATA",
      };
    }
    case "SET_ACTIVE_COMPAIGN_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "SET_ACTIVE_COMPAIGN_DATA",
      };
    }
    case "SHARE_ACTIVE_COMPAIGN_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "SHARE_ACTIVE_COMPAIGN_DATA",
      };
    }
    case "GET_CONSTANT_CONTACT_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "GET_CONSTANT_CONTACT_DATA",
      };
    }
    case "SHARE_CONSTANT_CONTACT_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "SHARE_CONSTANT_CONTACT_DATA",
      };
    }

    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    case "GET_ZAPIER_DATA": {
      return { ...state, data: action.payload, action: "GET_ZAPIER_DATA" };
    }
    case "SWITCH_ZAPIER_OFF": {
      return { ...state, data: action.payload, action: "SWITCH_ZAPIER_OFF" };
    }
    case "SWITCH_ZAPIER_ON": {
      return { ...state, data: action.payload, action: "SWITCH_ZAPIER_ON" };
    }

    case "CHANGE_ZAPIER_REFRESH_CREDS": {
      return {
        ...state,
        data: action.payload,
        action: "CHANGE_ZAPIER_REFRESH_CREDS",
      };
    }

    case "GET_CHERRY_CONFIG": {
      return { ...state, data: action.payload, action: "GET_CHERRY_CONFIG" };
    }

    case "PUT_CHERRY_CONFIG": {
      return { ...state, data: action.payload, action: "PUT_CHERRY_CONFIG" };
    }

    case "PATCH_CHERRY_CONFIG": {
      return { ...state, data: action.payload, action: "PATCH_CHERRY_CONFIG" };
    }
    default:
      return state;
  }
};

export default integrationsData;
