import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { EntityRow } from "../EntityRow";
import { Select } from "../../../../shared/Select/Select";

const CommonClinic = ({
  clinicError,
  clinicId,
  onChange,
  clinics,
  isDisabled,
}) => {
  const value = useMemo(() => {
    const clinic = clinics.find((c) => c.id === clinicId);
    if (clinic) {
      return {
        label: clinic.name,
        value: clinic.id,
      };
    }
    return null;
  }, [clinicId, clinics]);

  return (
    <EntityRow label="Clinic">
      <Select
        isSearchable
        isDisabled={isDisabled}
        isError={clinicError}
        value={value}
        onChange={(option) => onChange(option.value)}
        placeholder="Select clinic"
        options={clinics.map((c) => ({
          label: c.name,
          value: c.id,
        }))}
      />
    </EntityRow>
  );
};

CommonClinic.propTypes = {
  clinicError: PropTypes.bool.isRequired,
  clinicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  clinics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isDisabled: PropTypes.bool,
};

CommonClinic.defaultProps = {
  clinicId: undefined,
  isDisabled: false,
};

export default CommonClinic;
