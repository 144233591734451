/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  exportEmptyData,
  fetchOrderDetails,
  saveOrderInfo,
  searchProductByName,
  createEditSupplier,
  getSupplierClinics,
  searchPOProduct,
} from "../../../Actions/Inventory/inventoryActions.js";
import {
  autoScrolling,
  isFormSubmit,
  capitalizeFirstLetter,
  isNumber,
  isPositiveNumber,
  roundOffNum,
  getCurrencySymbol,
  displayName,
  positionFooterInstantly,
  decimalPlaces,
} from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import CreateEditSupplierModal from "./CreateEditSupplierModal.js";
import InventorySidebar from "../InventorySidebar.js";
import { shortenClinicName } from "../../../helpers/general";
import { uiNotification } from "../../../services/UINotificationService.js";

const roundToFixed = (value, fixed) => {
  const precision = parseInt("1" + "0".repeat(fixed));
  return parseFloat(Math.round(value * precision) / precision).toFixed(fixed);
};

const initAddProduct = () => {
  return {
    product_name: "",
    product_id: "",
    units: "",
    price_per_unit: "",
    tax: "",
    total: "",
    productError: false,
    productClass: "tableInput",
  };
};

const initAddClass = () => {
  return {
    product_name: "tableInput",
    product_id: "tableInput",
    units: "tableInput",
    price_per_unit: "tableInput",
    tax: "tableInput",
  };
};

const removeItem = (items, i) => {
  let x = items.slice(0, i - 1);
  let y = items.slice(i, items.length);

  let z = x.concat(y);
  return z;
};

class CreatePurchaseOrder extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.state = {
      status: "",
      showLoadingText: false,
      userData: "",
      page: 1,
      pagesize: 15,
      sortby: "",
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      supplierList: [],
      clinicList: [],
      orderDetails: {},
      addProduct: [initAddProduct()],
      addProductClass: [initAddClass()],
      productClass: "tableInput",
      loadMore: true,
      startFresh: true,
      showLoader: false,
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      globalLang: languageData.global,
      pageNum: 1,
      showAddModal: false,
      defaultCountry: localStorage.getItem("cCode")
        ? localStorage.getItem("cCode")
        : "us",
      supplierId: this.props.match.params.id ? this.props.match.params.id : 0,
      supplierName: this.props.match.params.name
        ? this.props.match.params.name
        : "",
      contactNumber: "",
      userChanged: false,
      isRender: false,
      showModal: false,
      productList: [],
      autoSuggestionProducts: [],
      totalQty: 0,
      finalQty: [],
      totalTax: "",
      grandTotal: 0,
      showSearchResult: false,
      productData: [],
      proIndex: "",
      clinicId: "",
      supplierClass: "simpleSelect",
      clinicClass: "simpleSelect",
      noteClass: "simpleTextarea h-85",
      note: "",
      subTotalPrice: [],
      subTotal: 0,
      finalTax: 0,
      completeTax: [],
      showOrderTotals: false,

      userList: [],
      order_placed_by:
        userData && userData.user && userData.user.id ? userData.user.id : "",
      order_placed_byClass: "simpleSelect",

      isShowSupplierModal: false,
      countryList: [],
      supplierDetails: null,
      clinicListAll: [],
      paymentTermsClass: "simpleTextarea h-85",
      md_id: "",
      md_idClass: "simpleSelect",
      custom_md: "",
      custom_mdClass: "simpleInput",
      mdList: [],
      payment_term_type: "last_4_digits_of_cc",
      payment_term_typeClass: "simpleSelect",
      last_four_value: "",
      last_four_valueClass: "simpleInput",
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    let isRender = false;
    let formData = {};
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      user_Create_UserBtn: languageData.settings["user_Create_UserBtn"],
      user_First_Name: languageData.settings["user_First_Name"],
      user_Role: languageData.settings["user_Role"],
      user_Last_Name: languageData.settings["user_Last_Name"],
      clinics_Search: languageData.settings["clinics_Search"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
      user_Status: languageData.settings["user_Status"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      setting_create_user_button_error__trail_account_type:
        languageData.settings[
          "setting_create_user_button_error__trail_account_type"
        ],
      isRender: isRender,
      supplierId: this.props.match.params.id ? this.props.match.params.id : 0,
    });
    this.setState({ showLoader: true });
    autoScrolling(true);
    this.props.fetchOrderDetails(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.exportEmptyData();
      return { showLoader: false };
    }

    if (
      nextProps.purchaseOrderSaved != undefined &&
      nextProps.purchaseOrderSaved
    ) {
      if (prevState.supplierId) {
        nextProps.history.push(
          `/inventory/suppliers/${prevState.supplierId}/view`,
        );
      } else {
        nextProps.history.push(`/inventory/purchase-order`);
      }
    }

    if (
      nextProps.orderDetails != undefined &&
      nextProps.orderDetails.data !== prevState.orderDetails &&
      nextProps.supplierTime !== prevState.supplierTime
    ) {
      nextProps.exportEmptyData();
      returnState.showLoader = false;
      returnState.supplierTime = nextProps.supplierTime;
      returnState.orderDetails = nextProps.orderDetails;
      returnState.supplierList = nextProps.orderDetails.supplier_list
        ? nextProps.orderDetails.supplier_list
        : [];
      returnState.clinicList = nextProps.orderDetails.clinic_list
        ? nextProps.orderDetails.clinic_list
        : [];
      returnState.clinicListAll = nextProps.orderDetails.clinic_list
        ? nextProps.orderDetails.clinic_list
        : [];
      returnState.userList = nextProps.orderDetails.users
        ? nextProps.orderDetails.users
        : [];
      returnState.countryList = nextProps.orderDetails.countries
        ? nextProps.orderDetails.countries
        : [];
      returnState.isRender = true;
      returnState.autoSuggestionProducts = [];
      returnState.mdList = nextProps.orderDetails.md_list
        ? nextProps.orderDetails.md_list
        : [];
      let supplierList = nextProps.orderDetails.supplier_list
        ? nextProps.orderDetails.supplier_list
        : [];
      let supplierId = prevState.supplierId;
      if (supplierId) {
        supplierList.map((obj) => {
          if (obj.id == supplierId) {
            returnState.supplierName = obj.supplier_name;
          }
        });
        returnState.supplier_id = prevState.supplierId;
      }
      if (nextProps.match.params.id) {
        returnState.showLoader = true;
        nextProps.getSupplierClinics(nextProps.match.params.id);
      }
    } else if (
      nextProps.productData !== undefined &&
      nextProps.productData.status === 200 &&
      nextProps.productData.data &&
      nextProps.productData.data.products !== prevState.productData &&
      nextProps.productDataTimestamp != prevState.productDataTimestamp
    ) {
      nextProps.exportEmptyData();
      returnState.productData = nextProps.productData.data.products;
      returnState.showSearchResult = true;
    }
    if (
      nextProps.supplierDetails &&
      nextProps.supplierDetails !== prevState.supplierDetails
    ) {
      nextProps.exportEmptyData();
      returnState.supplierDetails = nextProps.supplierDetails;
      returnState.isShowSupplierModal = false;
      if (returnState.supplierDetails.id) {
        let supplierList = prevState.supplierList;
        supplierList.push(returnState.supplierDetails);
        returnState.supplierList = supplierList;
        returnState.supplier_id = returnState.supplierDetails.id;
        nextProps.getSupplierClinics(returnState.supplier_id);
      } else {
        returnState.showLoader = false;
      }
    }
    if (
      nextProps.supplierClinics &&
      nextProps.supplierClinics !== prevState.supplierClinics
    ) {
      nextProps.exportEmptyData();
      returnState.supplierClinics = nextProps.supplierClinics;
      returnState.clinicList = nextProps.supplierClinics
        ? nextProps.supplierClinics
        : [];
      returnState.showLoader = false;
    }
    if (nextProps.userChanged !== undefined && nextProps.userChanged == true) {
      nextProps.exportEmptyData();
      returnState.showLoader = false;
      returnState.userChanged = false;
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (event.target.name === "last_4_digits_of_cc") {
      if (value) {
        if (value.length === 5) {
          return;
        }
      }
    }

    this.setState({
      [event.target.name]: value,
    });

    if (event.target.name === "supplier_id") {
      if (value === "addSupplier") {
        this.setState({ isShowSupplierModal: true, supplier_id: "" });
      } else if (value > 0) {
        this.setState({ showLoader: true });
        this.props.getSupplierClinics(value);
      }
    }

    if (event.target.name === "md_id") {
      if (value === "" || value !== "custom") {
        this.setState({ custom_md: "", custom_mdClass: "simpleInput" });
      }
    }
  };

  supplierDetails = () => {
    return <div>{this.props.history.push(`/inventory/suppliers`)}</div>;
  };

  redirectToSuppliers = () => {
    return <div>{this.props.history.push(`/inventory/suppliers`)}</div>;
  };

  redirectToPurchaseOrder = () => {
    return <div>{this.props.history.push(`/inventory/purchase-order`)}</div>;
  };

  handleTaxOnBlur = (event) => {
    const index = event.target.dataset.index;
    const newAddProduct = JSON.parse(JSON.stringify(this.state.addProduct));
    const newAddProductClass = JSON.parse(
      JSON.stringify(this.state.addProductClass),
    );
    if (isPositiveNumber(event.target.value, null, null, 4)) {
      newAddProductClass[index][event.target.name] = "tableInput";
    }
    newAddProduct[index].tax = Number(event.target.value).toFixed(3);
    this.setState({
      addProduct: newAddProduct,
      addProductClass: newAddProductClass,
    });
  };

  handleProductInput = (event) => {
    const target = event.target;
    let value = target.value;
    const index = event.target.dataset.index;

    if (event.target.name === "units") {
      if (decimalPlaces(event.target.value) > 3) {
        return;
      }
    }

    let addProduct = JSON.parse(JSON.stringify(this.state.addProduct));
    let addProductClass = JSON.parse(
      JSON.stringify(this.state.addProductClass),
    );
    let quantityNum = [];
    let subTotalPrice = [];
    let totalTax = [];

    if (
      value != undefined &&
      value.trim() != "" &&
      event.target.name != "product_name"
    ) {
      if (
        !isPositiveNumber(value, null, null, 4) &&
        !(event.target.name === "tax" && isPositiveNumber(value, null, null, 4))
      ) {
        addProduct[index][event.target.name] = value;
        addProductClass[index][event.target.name] = "tableInput field_error";
        addProduct[index]["total"] = 0;
        this.setState({
          addProductClass: addProductClass,
          addProduct: addProduct,
          showOrderTotals: false,
        });
        return false;
      } else {
        addProductClass[index][event.target.name] = "tableInput";
      }
    }

    addProduct[index][event.target.name] = value;

    if (value.length >= 2 && event.target.name == "product_name") {
      this.setState({ proIndex: index, addProduct: addProduct });
      let formData = { params: { term: value, limit: 20 } };
      this.props.searchPOProduct(formData);
    }

    if (value.length <= 2 && event.target.name == "product_name") {
      this.setState({ showSearchResult: false, addProduct: addProduct });
    }

    if (event.target.name != "product_name") {
      addProduct.map((obj) => {
        let units = obj.units ? roundToFixed(obj.units, 3) : 0;
        let price_per_unit =
          obj.price_per_unit != "" ? roundOffNum(obj.price_per_unit) : 0;
        let tax = obj.tax != "" ? parseFloat(obj.tax) : 0;
        let totalprice = units && price_per_unit ? units * price_per_unit : 0;
        let aftertax = tax ? tax / 100 : 0;
        let final = totalprice && aftertax ? totalprice * aftertax : 0;
        let finalPrice = final + totalprice;
        obj.total = roundToFixed(finalPrice, 3);
        quantityNum.push(parseFloat(units));
        subTotalPrice.push(
          roundOffNum(roundOffNum(units) * roundOffNum(price_per_unit)),
        );
        if (!isNaN(aftertax)) {
          totalTax.push(roundOffNum(final));
        }
      });
      let totalQty = quantityNum.reduce((to, num) => {
        return roundOffNum(parseFloat(to) + parseFloat(num), 1);
      });
      let subTotal = subTotalPrice.reduce((to, num) => {
        return roundOffNum(parseFloat(to) + parseFloat(num));
      });

      let finalTax = totalTax.length
        ? totalTax.reduce((to, num) => {
            return roundOffNum(parseFloat(to) + parseFloat(num));
          })
        : [];

      let returnState = {
        totalQty: totalQty,
        finalQty: quantityNum,
        subTotal: subTotal,
        subTotalPrice: subTotalPrice,
        finalTax: finalTax,
        completeTax: totalTax,
        grandTotal: roundOffNum(parseFloat(finalTax) + parseFloat(subTotal)),
      };

      if (subTotal > 0) {
        returnState.showOrderTotals = true;
      }

      returnState.addProduct = addProduct;
      returnState.addProductClass = addProductClass;
      this.setState(returnState);
    }
  };

  addProduct = () => {
    let addProduct = this.state.addProduct;
    let multipleClassList = this.state.addProductClass;
    addProduct.push(initAddProduct());
    multipleClassList.push(initAddClass());
    this.setState({
      addProduct: addProduct,
      addProductClass: multipleClassList,
    });
    positionFooterInstantly();
  };

  resetProduct = () => {
    this.setState({
      addProduct: [initAddProduct()],
      subTotal: "0.00",
      totalQty: "0.00",
      finalTax: "0.00",
      totalTax: "0.00",
      grandTotal: "0.00",
    });
  };

  deleteProduct = (index) => {
    if (this.state.addProduct.length == 1) {
      return false;
    }

    let addProductInit = this.state.addProduct;

    let totalQty = 0,
      subTotal = 0,
      finalTax = 0,
      grandTotal = 0;

    let addProduct = removeItem(addProductInit, index + 1);
    this.setState({ addProduct: addProduct });
    addProduct.map((obj) => {
      if (obj.units) {
        totalQty = roundOffNum(
          parseFloat(totalQty) + parseFloat(roundOffNum(obj.units)),
        );
      }

      if (obj.units && obj.price_per_unit) {
        let rowSubTotal = roundOffNum(
          parseFloat(obj.units) * parseFloat(obj.price_per_unit),
        );
        subTotal += parseFloat(roundOffNum(rowSubTotal));
        if (obj.tax) {
          let rowTax = parseFloat(
            (parseFloat(obj.tax) * parseFloat(rowSubTotal)) / 100,
          );
          let rowTotal = roundOffNum(parseFloat(rowSubTotal) + rowTax);
          finalTax = roundOffNum(parseFloat(finalTax) + parseFloat(rowTax));
          grandTotal = roundOffNum(
            parseFloat(grandTotal) + parseFloat(rowTotal),
          );
        }
      }
    });

    let multipleClassList = this.state.addProductClass;
    this.setState({
      addProduct: addProduct,
      addProductClass: multipleClassList,
      totalQty: totalQty,
      subTotal: roundOffNum(subTotal),
      finalTax: finalTax,
      grandTotal: grandTotal,
    });
    positionFooterInstantly();
  };

  selectProduct = (obj) => {
    let productID = 0;
    let productName = "";

    if (obj && obj.id) {
      productID = obj.id;
      productName = obj.product_name;
    }

    const addProduct = this.state.addProduct;
    let proIndex = this.state.proIndex;

    if (productID && productID > 0 && productName) {
      if (proIndex) {
        addProduct[proIndex]["product_id"] = productID;
        addProduct[proIndex]["product_name"] = productName;
      }
    }
    this.setState({
      showSearchResult: false,
      ProIndex: -1,
      addProduct: addProduct,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (isFormSubmit()) {
      let formData = {};
      let multipleClassList = this.state.addProductClass;
      let error = false;
      let supplierId = this.state.supplier_id;
      let clinicId = this.state.clinicId;
      let note = this.state.note;
      let order_placed_by = this.state.order_placed_by;

      let medicalDirector = this.state.md_id;
      let customMD = this.state.custom_md;
      let paymentTerms = this.state.payment_terms;
      let paymentTermType = this.state.payment_term_type;
      let last4Digits = this.state.last_4_digits_of_cc;

      this.setState({
        supplierClass: "simpleSelect",
        clinicClass: "simpleSelect",
        noteClass: "simpleTextarea",
        md_idClass: "simpleSelect",
        custom_mdClass: "simpleInput",
        payment_term_typeClass: "simpleSelect",
        last_four_valueClass: "simpleInput",
      });

      if (supplierId == undefined || supplierId == null || supplierId == "") {
        error = true;
        this.setState({ supplierClass: "simpleSelect field_error" });
      }

      if (clinicId == undefined || clinicId == null || clinicId == "") {
        error = true;
        this.setState({ clinicClass: "simpleSelect field_error" });
      }

      if (
        order_placed_by == undefined ||
        order_placed_by == null ||
        order_placed_by == ""
      ) {
        this.setState({ order_placed_byClass: "simpleSelect field_error" });
        error = true;
      } else {
        this.setState({ order_placed_byClass: "simpleSelect" });
      }

      if (note == undefined || note == null || note == "") {
        error = true;
        this.setState({ noteClass: "simpleTextarea h-85 field_error" });
      }

      if (
        medicalDirector == undefined ||
        medicalDirector == null ||
        medicalDirector == ""
      ) {
        error = true;
        this.setState({ md_idClass: "simpleSelect field_error" });
      }

      if (medicalDirector && medicalDirector === "custom") {
        if (customMD == undefined || customMD == null || customMD == "") {
          error = true;
          this.setState({ custom_mdClass: "simpleInput field_error" });
        }
      }

      if (
        paymentTermType &&
        (paymentTermType === "both" ||
          paymentTermType === "last_4_digits_of_cc")
      ) {
        if (
          last4Digits == undefined ||
          last4Digits == null ||
          last4Digits == ""
        ) {
          error = true;
          this.setState({ last_four_valueClass: "simpleInput field_error" });
        } else {
          if (last4Digits) {
            if (last4Digits.length !== 4) {
              error = true;
              this.setState({
                last_four_valueClass: "simpleInput field_error",
              });
            }
          }
        }
      }

      this.state.addProduct.map((obj, idx) => {
        if (!obj.product_id) {
          multipleClassList[idx]["product_name"] = "tableInput field_error";
          error = true;
        } else {
          multipleClassList[idx]["product_name"] = "tableInput";
        }

        if (!isNumber(obj.units)) {
          multipleClassList[idx]["units"] = "tableInput field_error";
          error = true;
        } else {
          multipleClassList[idx]["units"] = "tableInput";
        }
        if (!isNumber(obj.price_per_unit)) {
          multipleClassList[idx]["price_per_unit"] = "tableInput field_error";
          error = true;
        } else {
          multipleClassList[idx]["price_per_unit"] = "tableInput";
        }
        if (!isNumber(obj.tax)) {
          multipleClassList[idx]["tax"] = "tableInput field_error";
          error = true;
        } else {
          multipleClassList[idx]["tax"] = "tableInput";
        }
      });
      this.setState({
        addProductClass: multipleClassList,
      });

      if (error) {
        return;
      }

      formData.supplier_id = supplierId;
      formData.clinic_id = clinicId;
      formData.order_placed_by = order_placed_by;
      formData.note = note;
      formData.purchase_order_items = this.state.addProduct;

      formData.md_id = medicalDirector;

      if (medicalDirector && medicalDirector === "custom") {
        formData.custom_md = customMD;
      }

      formData.payment_term_type = paymentTermType;

      if (
        paymentTermType &&
        (paymentTermType === "both" ||
          paymentTermType === "last_4_digits_of_cc")
      ) {
        formData.last_4_digits_of_cc = last4Digits;
      }

      formData.payment_terms = paymentTerms;

      this.setState({ showLoader: true });
      this.props.saveOrderInfo(formData);
    }
  };

  showSupplierModal = () => {
    this.setState({ isShowSupplierModal: true });
  };
  dismissSupplierModal = () => {
    this.setState({ isShowSupplierModal: false });
  };
  createEditSupplier = (formData) => {
    formData.mode = "view";
    this.setState({ showLoader: true, supplierDetails: null });
    this.props.createEditSupplier(formData);
  };

  render() {
    let title = "Purchase Order";
    if (this.state.supplierName) {
      title = "Suppliers";
    }
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <InventorySidebar />
            {this.state.isShowSupplierModal === true && (
              <CreateEditSupplierModal
                createEditSupplier={this.createEditSupplier}
                dismissSupplierModal={this.dismissSupplierModal}
                countryList={this.state.countryList}
                clinicList={this.state.clinicListAll}
              />
            )}
            <div className="business-setion memberWalletOuter">
              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span
                      className="cursor-pointer"
                      onClick={
                        this.state.supplierName
                          ? this.redirectToSuppliers
                          : this.redirectToPurchaseOrder
                      }
                    >
                      {title}
                    </span>
                    {this.state.supplierName && (
                      <span className="breadCrumb">
                        <i className="fa fa-chevron-right" />{" "}
                        <span className="breadCrumb-text">
                          {this.state.supplierName}
                        </span>
                      </span>
                    )}
                    <span className="breadCrumb">
                      {" "}
                      <i className="fa fa-chevron-right" />{" "}
                      <span className="breadCrumb-text">
                        {"New Purchase Order"}
                      </span>
                    </span>
                    <div className="memberRightActions">
                      <button
                        className="new-blue-btn pull-right"
                        onClick={this.handleSubmit}
                      >
                        {"Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"juvly-section full-width m-b-15"}>
                <div className="membership-title border-bottom">
                  {"Order Info"}
                </div>
                <div className="row p20">
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        Supplier Name<span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.supplierClass}
                        name="supplier_id"
                        onChange={this.handleInputChange}
                        value={this.state.supplier_id}
                        disabled={this.state.supplierId ? true : false}
                      >
                        <option value="">Select</option>
                        <option
                          value="addSupplier"
                          className="sel-opt-add sel-opt-add-supplier bold"
                        >
                          + Add New Supplier
                        </option>
                        {this.state.supplierList.map((obj, id) => {
                          return (
                            <option key={"supplierList-" + id} value={obj.id}>
                              {obj.supplier_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        Clinic Name<span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.clinicClass}
                        name="clinicId"
                        onChange={this.handleInputChange}
                        value={this.state.clinicId}
                      >
                        <option value="">Select</option>
                        {this.state.clinicList.map((obj, id) => {
                          return (
                            <option key={"clinicList-" + id} value={obj.id}>
                              {shortenClinicName(obj.clinic_name) +
                                (obj.account_number
                                  ? " - " + obj.account_number
                                  : "")}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        PO Created By<span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.order_placed_byClass}
                        name="order_placed_by"
                        onChange={this.handleInputChange}
                        value={this.state.order_placed_by}
                      >
                        <option value="">Select</option>
                        {this.state.userList.map((obj, id) => {
                          return (
                            <option key={"userList-" + id} value={obj.id}>
                              {displayName(obj)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        Medical Director
                        <span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.md_idClass}
                        name="md_id"
                        onChange={this.handleInputChange}
                        value={this.state.md_id}
                      >
                        <option value="">Select</option>
                        {this.state.mdList &&
                          this.state.mdList.map((obj, id) => {
                            return (
                              <option key={"mdList-" + id} value={obj.id}>
                                {displayName(obj)}
                              </option>
                            );
                          })}
                        <option value="custom">Custom</option>
                      </select>
                    </div>
                  </div>

                  {this.state.md_id && this.state.md_id === "custom" ? (
                    <div className="col-lg-3 col-sm-6 col-xs-12 pull-right">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          Medical Director Name
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          type="text"
                          name={"custom_md"}
                          value={this.state.custom_md}
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          className={this.state.custom_mdClass}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-lg-12 col-xs-12">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        Note<span className="setting-require">*</span>
                      </div>
                      <textarea
                        name="note"
                        className={this.state.noteClass}
                        value={this.state.note}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        Payment Terms Type
                        <span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.payment_term_typeClass}
                        name="payment_term_type"
                        onChange={this.handleInputChange}
                        value={this.state.payment_term_type}
                      >
                        <option value="last_4_digits_of_cc">
                          Last 4 digits of CC
                        </option>
                        <option value="payment_term_text">Payment terms</option>
                        <option value="both">Both</option>
                      </select>
                    </div>
                  </div>

                  {this.state.payment_term_type === "last_4_digits_of_cc" ||
                  this.state.payment_term_type === "both" ? (
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          Last 4 digits Of CC
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          type="text"
                          name={"last_4_digits_of_cc"}
                          value={this.state.last_4_digits_of_cc}
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          className={this.state.last_four_valueClass}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {(this.state.payment_term_type === "payment_term_text" ||
                    this.state.payment_term_type === "both") && (
                    <div className="col-lg-12 col-xs-12">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">Payment Terms</div>
                        <textarea
                          name="payment_terms"
                          className={this.state.paymentTermsClass}
                          value={this.state.payment_terms}
                          onChange={this.handleInputChange}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>

                <div className="membership-title">
                  <a
                    className="new-blue-btn pull-right no-padding"
                    onClick={this.addProduct}
                  >
                    <i className="fa fa-plus m-t-10"></i> Add
                  </a>
                </div>
                <div className="table-responsive fixed-header-table orderInfoTable">
                  <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-3 table-updated-th">Product</th>
                        <th className="col-xs-2 table-updated-th">Quantity</th>
                        <th className="col-xs-2 table-updated-th">
                          Price per Quantity
                        </th>
                        <th className="col-xs-2 table-updated-th no-padding-right">
                          Tax (in %)
                        </th>
                        <th className="col-xs-2 table-updated-th p-r-0">
                          Total
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-0"></th>
                      </tr>
                    </thead>

                    <tbody className={"ajax_body no-scroll"}>
                      {this.state.addProduct &&
                        this.state.addProduct.map((obj, id) => {
                          const InputClass = this.state.addProductClass[id]
                            ? this.state.addProductClass[id]
                            : "tableInput";
                          return (
                            <tr
                              key={"addProductClass-" + id}
                              className="table-updated-tr"
                            >
                              <td className="col-xs-3 table-updated-td word-break">
                                <div className="setting-input-outer">
                                  <input
                                    type="text"
                                    name={"product_name"}
                                    value={obj.product_name}
                                    autoComplete="off"
                                    onChange={(e) => this.handleProductInput(e)}
                                    data-index={id}
                                    className={InputClass.product_name}
                                  />
                                  <ul
                                    key={`row_found-` + id}
                                    className={
                                      this.state.productData &&
                                      this.state.productData.length &&
                                      this.state.showSearchResult &&
                                      id === parseInt(this.state.proIndex)
                                        ? " search-dropdown productTableDropdown"
                                        : "cal-dropdown clinicname-dropdown no-display"
                                    }
                                  >
                                    {this.state.productData &&
                                      this.state.productData.length &&
                                      this.state.productData.map(
                                        (objx, idx) => {
                                          return (
                                            <li
                                              key={
                                                "addProductClass-" +
                                                id +
                                                "-product-" +
                                                idx
                                              }
                                              onClick={() =>
                                                this.selectProduct(objx)
                                              }
                                            >
                                              <a>
                                                {objx &&
                                                  objx.product_name &&
                                                  capitalizeFirstLetter(
                                                    objx.product_name,
                                                  )}
                                              </a>
                                            </li>
                                          );
                                        },
                                      )}
                                  </ul>
                                  <ul
                                    key={`row_not_found_` + id}
                                    className={
                                      this.state.showSearchResult &&
                                      id === parseInt(this.state.proIndex)
                                        ? "search-dropdown productTableDropdown productTableDropdownnorecord"
                                        : "cal-dropdown clinicname-dropdown no-display"
                                    }
                                  >
                                    {this.state.productData &&
                                      this.state.productData.length === 0 && (
                                        <li>
                                          There were no matches, please enter
                                          relevant keywords
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </td>
                              <td className="col-xs-2 table-updated-td word-break">
                                <input
                                  type="text"
                                  name={"units"}
                                  value={obj.units}
                                  onChange={(e) => this.handleProductInput(e)}
                                  data-index={id}
                                  className={InputClass.units}
                                />
                              </td>
                              <td className="col-xs-2 table-updated-td text-left">
                                <input
                                  type="text"
                                  name="price_per_unit"
                                  value={obj.price_per_unit}
                                  onChange={(e) => this.handleProductInput(e)}
                                  data-index={id}
                                  className={InputClass.price_per_unit}
                                />
                              </td>
                              <td className="col-xs-2 table-updated-td">
                                <input
                                  type="number"
                                  min={0}
                                  name={"tax"}
                                  value={obj.tax}
                                  onChange={(e) => this.handleProductInput(e)}
                                  onBlur={this.handleTaxOnBlur}
                                  data-index={id}
                                  className={InputClass.tax}
                                  placeholder="0.000"
                                />
                              </td>
                              <td className="col-xs-2 table-updated-td p-r-0 orderInfoTotal">
                                {getCurrencySymbol() + "" + obj.total
                                  ? obj.total
                                  : ""}
                              </td>
                              <td
                                className={
                                  this.state.addProduct.length > 1
                                    ? "col-xs-1 table-updated-td p-r-0 p-l-0 orderInfoTotal"
                                    : "no-display"
                                }
                              >
                                <a
                                  className={"easy-link p-l-0 p-r-0"}
                                  key={id}
                                  onClick={this.deleteProduct.bind(this, id)}
                                >
                                  <i className="fa fa-trash-alt m-r-5 m-t-2" />
                                  Delete
                                </a>
                              </td>
                              <td
                                className={
                                  this.state.addProduct.length == 1 && id == 0
                                    ? "col-xs-1 table-updated-td p-r-0 p-l-0 orderInfoTotal"
                                    : "no-display"
                                }
                              >
                                <a
                                  className={"easy-link p-l-0 p-r-0"}
                                  key={id}
                                  onClick={(e) => this.resetProduct(e)}
                                >
                                  Reset
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      {this.state.showLoader === false &&
                        (!this.state.addProduct ||
                          this.state.addProduct.length <= 0) && (
                          <tr>
                            <td
                              colSpan={6}
                              className="col-xs-12 table-updated-td text-center "
                            >
                              <div
                                className={
                                  this.state.addProduct &&
                                  this.state.addProduct.length
                                    ? "no-record no-display"
                                    : "no-record"
                                }
                              >
                                {this.state.clinic_No_Record_Found}
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <div
                  className={
                    this.state.showOrderTotals
                      ? "col-lg-3 col-sm-4 col-xs-12 supplierDetailsLeft p20 pull-right purchaseOrderInfo"
                      : "no-display"
                  }
                >
                  <div className="detail-row">
                    <label>{"Total Qty"}:</label>
                    <span>
                      {!isNaN(this.state.totalQty) ? this.state.totalQty : 0}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>{"Sub Total (" + getCurrencySymbol() + ")"}:</label>
                    <span>{this.state.subTotal}</span>
                  </div>
                  <div className="detail-row">
                    <label>{"Total Tax (" + getCurrencySymbol() + ")"}:</label>
                    <span>{this.state.finalTax}</span>
                  </div>
                  <div className="detail-row">
                    <label>
                      {"Grand Total (" + getCurrencySymbol() + ")"}:
                    </label>
                    <span>{this.state.grandTotal}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "GET_ORDER_DETAILS") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.orderDetails = state.InventoryReducer.data.data;
      returnState.supplierTime = new Date();
      returnState.supplierUpdate = true;
    }
  }

  if (state.InventoryReducer.action === "SAVE_PURCHASE_ORDER") {
    if (state.InventoryReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.purchaseOrderSaved = state.InventoryReducer.data;
      returnState.purchaseOrderSavedTime = new Date();
    }
  }

  if (state.SettingReducer.action === "USERS_SEARCH") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.userSearch = state.SettingReducer.data.data;
    }
  }

  if (state.InventoryReducer.action === "SEARCH_PO_PRODUCT") {
    if (state.InventoryReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = true;
    } else {
      returnState.productData = state.InventoryReducer.data;
      returnState.productDataTimestamp = new Date();
    }
  }

  if (state.InventoryReducer.action === "ADD_EDIT_SUPPLIER") {
    uiNotification.clear();
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.supplierDetails = state.InventoryReducer.data.data;
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
    }
  }
  if (state.InventoryReducer.action === "GET_SUPPLIER_CLINICS") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.supplierClinics = state.InventoryReducer.data.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchProductByName: searchProductByName,
      exportEmptyData: exportEmptyData,
      fetchOrderDetails: fetchOrderDetails,
      saveOrderInfo: saveOrderInfo,
      createEditSupplier: createEditSupplier,
      getSupplierClinics: getSupplierClinics,
      searchPOProduct: searchPOProduct,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePurchaseOrder);
