const initialState = {
  data: "",
  status: "",
  patientSavedReports: [],
  patientReportOptions: [],
  appointmentsSavedReports: [],
  appointmentsOptions: [],
  inventorySavedReports: [],
  inventoryOptions: [],
};

const BusinessInsightReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        ...state,
        action: "RESET_ALL",
      };
    }
    case "BUSINESS_INSIGHT_RESET_ACTION": {
      return {
        ...state,
        action: "BUSINESS_INSIGHT_RESET_ACTION",
      };
    }
    case "GET_CLIENTS_REPORT": {
      return {
        ...state,
        patientSavedReports: action.savedReports,
        patientReportOptions: action.reportOptions,
        action: "GET_CLIENTS_REPORT",
        data: action.payload,
      };
    }
    case "UPDATE_CLIENTS_REPORT_OPTIONS": {
      return {
        ...state,
        patientReportOptions: action.reportOptions,
        action: "UPDATE_CLIENTS_REPORT_OPTIONS",
        data: action.payload,
      };
    }
    case "UPDATE_CLIENTS_SAVED_REPORTS": {
      return {
        ...state,
        patientSavedReports: action.savedReports,
        action: "UPDATE_CLIENTS_SAVED_REPORTS",
        data: action.payload,
      };
    }
    case "GET_APPOINTMENTS_REPORT": {
      return {
        ...state,
        appointmentsSavedReports: action.savedReports,
        appointmentsOptions: action.reportOptions,
        action: "GET_APPOINTMENTS_REPORT",
        data: action.payload,
      };
    }
    case "GET_REPORT_VARIABLES":
      return {
        ...state,
        data: action.payload,
        action: "GET_REPORT_VARIABLES",
      };
    case "GET_REPORT_DATA":
      return {
        ...state,
        data: action.payload,
        action: "GET_REPORT_DATA",
      };
    case "GET_INVENTORY_REPORT": {
      return {
        ...state,
        inventorySavedReports: action.savedReports,
        inventoryReportOptions: action.reportOptions,
        action: "GET_INVENTORY_REPORT",
        data: action.payload,
      };
    }
    case "UPDATE_INVENTORY_REPORT_OPTIONS": {
      return {
        ...state,
        inventoryReportOptions: action.reportOptions,
        action: "UPDATE_INVENTORY_REPORT_OPTIONS",
        data: action.payload,
      };
    }
    case "UPDATE_INVENTORY_SAVED_REPORTS": {
      return {
        ...state,
        inventorySavedReports: action.savedReports,
        action: "UPDATE_INVENTORY_SAVED_REPORTS",
        data: action.payload,
      };
    }
    case "UPDATE_APPOINTMENTS_SAVED_REPORTS": {
      return {
        ...state,
        inventorySavedReports: action.savedReports,
        action: "UPDATE_APPOINTMENTS_SAVED_REPORTS",
        data: action.payload,
      };
    }
    case "DELETE_THIS_REPORT": {
      return {
        ...state,
        action: "DELETE_THIS_REPORT",
        data: action.payload,
      };
    }
    case "UPDATE_REPORT_ORDER": {
      return {
        ...state,
        action: "UPDATE_REPORT_ORDER",
        data: action.payload,
      };
    }
    case "DOWNLOAD_REPORT_DATA": {
      return {
        ...state,
        action: "DOWNLOAD_REPORT_DATA",
        data: action.payload,
      };
    }
    case "GET_BI_SALES_DETAILS": {
      return {
        ...state,
        action: "GET_BI_SALES_DETAILS",
        data: action.payload,
      };
    }
    case "GET_ASPIRE_REDEEMED_CERTIFICATES": {
      return {
        ...state,
        action: "GET_ASPIRE_REDEEMED_CERTIFICATES",
        data: action.payload,
      };
    }
    case "BI_EXPORT_FILE": {
      return {
        ...state,
        action: "BI_EXPORT_FILE",
        data: action.payload,
      };
    }
    case "MEMBERSHIP_REVENUE_DETAIL": {
      return {
        ...state,
        data: action.payload,
        action: "MEMBERSHIP_REVENUE_DETAIL",
      };
    }
    case "MEMBERSHIP_REVENUE_EXPORT": {
      return {
        ...state,
        data: action.payload,
        action: "MEMBERSHIP_REVENUE_EXPORT",
      };
    }
    case "GET_REPORT_DETAIL": {
      return { ...state, data: action.payload, action: "GET_REPORT_DETAIL" };
    }
    case "GET_REPUTATION_REPORT": {
      return {
        ...state,
        reputationSavedReports: action.savedReports,
        repuationOptions: action.reportOptions,
        action: "GET_REPUTATION_REPORT",
        data: action.payload,
      };
    }
    case "UPDATE_REPUTATION_SAVED_REPORTS": {
      return {
        ...state,
        reputationSavedReports: action.savedReports,
        action: "UPDATE_REPUTATION_SAVED_REPORTS",
        data: action.payload,
      };
    }
    case "GET_STAFF_REPORT": {
      return {
        ...state,
        staffSavedReports: action.savedReports,
        staffOptions: action.reportOptions,
        action: "GET_STAFF_REPORT",
        data: action.payload,
      };
    }
    case "UPDATE_STAFF_SAVED_REPORTS": {
      return {
        ...state,
        staffSavedReports: action.savedReports,
        action: "UPDATE_STAFF_SAVED_REPORTS",
        data: action.payload,
      };
    }
    case "DOWNLOAD_SURVEY_REPORT_DATA": {
      return {
        ...state,
        action: "DOWNLOAD_SURVEY_REPORT_DATA",
        data: action.payload,
      };
    }
    case "SHARE_REPORT_DATA": {
      return {
        ...state,
        action: "SHARE_REPORT_DATA",
        data: action.payload,
      };
    }
    case "DISCOUNT_CLINIC_DETAIL": {
      return {
        ...state,
        action: "DISCOUNT_CLINIC_DETAIL",
        data: action.payload,
      };
    }
    case "MRR_REPORT_DETAIL": {
      return {
        ...state,
        action: "MRR_REPORT_DETAIL",
        data: action.payload,
      };
    }
    case "CHRUN_REPORT_DETAIL": {
      return {
        ...state,
        action: "CHRUN_REPORT_DETAIL",
        data: action.payload,
      };
    }

    case "SAVE_SMS_LIST": {
      return { ...state, action: "SAVE_SMS_LIST", data: action.payload };
    }
    case "MEMBERSHIP_DECLINE_DETAIL": {
      return {
        ...state,
        action: "MEMBERSHIP_DECLINE_DETAIL",
        data: action.payload,
      };
    }
    case "HIDE_MEMBERSHIP_DECLINE_INVOICE": {
      return {
        ...state,
        action: "HIDE_MEMBERSHIP_DECLINE_INVOICE",
        data: action.payload,
      };
    }
    case "RESEND_EGIFT_CARD_MAIL": {
      return {
        ...state,
        action: "RESEND_EGIFT_CARD_MAIL",
        data: action.payload,
      };
    }
    case "BDASPIRE_DETAILS": {
      return {
        ...state,
        action: "BDASPIRE_DETAILS",
        data: action.payload,
      };
    }
    case "TIME_CARD_REPORT": {
      return {
        ...state,
        action: "TIME_CARD_REPORT",
        data: action.payload,
      };
    }
    case "TIME_CARD_REPORT_DETAILS": {
      return {
        ...state,
        action: "TIME_CARD_REPORT_DETAILS",
        data: action.payload,
      };
    }
    case "TIME_CARD_REPORT_EXPORT": {
      return {
        ...state,
        action: "TIME_CARD_REPORT_EXPORT",
        data: action.payload,
      };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }

    case "RETAIL_REPORT_DETAIL": {
      return {
        ...state,
        action: "RETAIL_REPORT_DETAIL",
        data: action.payload,
      };
    }

    case "UPDATE_TIME_TRACKING": {
      return {
        ...state,
        action: "UPDATE_TIME_TRACKING",
        data: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default BusinessInsightReducer;
