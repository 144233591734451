import React from "react";
import { LayoutSettings } from "../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../widgets/EntityHeader/EntityHeader";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { FeatureBox } from "./components/FeatureBox";
import { BetaIcon } from "./components/BetaIcon";
import { Box } from "../../../shared/Box/Box";
import classes from "./scss/betaProgram.module.scss";

export const BetaProgram = () => {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <LayoutSettings>
      <EntityHeader title={tSettings("betaProgram.title")} />
      <Box className={classes.mt}>
        <div className={classes.welcomeSection}>
          <div className={classes.row}>
            <div className={classes.textSection}>
              <div className={classes.planTitle}>
                {tSettings("betaProgram.welcomeSection.planTitle")}
              </div>
              <div className={classes.context}>
                {tSettings("betaProgram.welcomeSection.context")}
              </div>
            </div>
            <div className={classes.imageSection}>
              <BetaIcon />
            </div>
          </div>
        </div>
      </Box>
      <FeatureBox />
    </LayoutSettings>
  );
};
