/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { WIDGET_NAMES } from "../Dashboard.consts";
import picClose from "../../../_legacy/images/popupClose.png";
import { getLanguageData } from "../../../utilities/localStorage";

const globalLangData = getLanguageData("global");

const scrollBarsStyles = { height: 500 };

export function AddWidgetModal({
  isOpen,
  onClose,
  selectedWidget,
  dashWidgetList,
  PopInactiveWid,
  PopActiveWid,
  onCreateDashboard,
  onSelectImage,
}) {
  return (
    <div className={isOpen ? "blackOverlay" : "blackOverlay no-display"}>
      <div className="vert-middle vert-middle-report-option">
        <div className="white-popup extra-large-popup">
          <div className="white-popup-wrapper">
            <div className="membershipTypeTitle">
              Add Widgets
              <button onClick={onClose} className="popupClose">
                <img src={picClose} alt="close" />
              </button>
            </div>
            <div className="dashboardTypeTitle">
              What type of dashboard do you want to display?
            </div>
            <Scrollbars
              style={scrollBarsStyles}
              className="custome-scroll dashboardPopupContainer"
            >
              <div className="row dashboardTypeRow">
                {dashWidgetList.map((obj, idx) => {
                  let t = 1;
                  return (
                    <div
                      className={
                        selectedWidget.find((r) => r == obj.id)
                          ? "col-md-4 col-sm-6 col-xs-12 dashboardTypeCol active"
                          : "col-md-4 col-sm-6 col-xs-12 dashboardTypeCol"
                      }
                      id={obj.id}
                      name={"clinic-" + obj.id}
                      key={idx}
                      onClick={
                        PopInactiveWid.find((r) => r == obj.id)
                          ? () => {}
                          : () => onSelectImage(obj.id)
                      }
                    >
                      <div
                        className={
                          PopInactiveWid.find((r) => r == obj.id)
                            ? "dashSection lockDashboard"
                            : "dashSection"
                        }
                      >
                        <div className={"dashCheckOuter"}>
                          {PopInactiveWid.find((r) => r == obj.id) ? (
                            <>
                              <i className="fa fa-lock"></i>
                              <p>Locked by admin</p>{" "}
                            </>
                          ) : (
                            <i className="fa fa-check"></i>
                          )}
                        </div>
                        <img
                          src={"images/" + obj.name + ".png?t=" + t}
                          alt=""
                        />
                        <div className="dashFooter">
                          {globalLangData[obj.name] || WIDGET_NAMES[obj.name]}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Scrollbars>
          </div>
          <div className="footer-static">
            {PopActiveWid.length > 0 && (
              <>
                <button
                  className="new-blue-btn pull-right"
                  onClick={onCreateDashboard}
                >
                  {globalLangData.label_save}
                </button>
                <button className="new-white-btn pull-right" onClick={onClose}>
                  {globalLangData.label_cancel}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

AddWidgetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWidget: PropTypes.array,
  dashWidgetList: PropTypes.array,
  PopInactiveWid: PropTypes.array,
  PopActiveWid: PropTypes.array,
  onCreateDashboard: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
};

AddWidgetModal.defaultProps = {
  selectedWidget: [],
  dashWidgetList: [],
  PopInactiveWid: [],
  PopActiveWid: [],
};
