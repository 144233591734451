import React from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { ReportHeader } from "../../../../../components/ReportHeader/ReportHeader";
import { Button } from "../../../../../../../shared/Button/Button";

/**
 * @param {Object} param0
 * @param {Function} param0.onUpdate
 * @param {number} param0.selectedCount
 */
export const Header = ({ onUpdate, selectedCount }) => {
  const { tBi } = useAppTranslation.BusinessInsights();

  return (
    <ReportHeader label={tBi("noClinicPatientsReport.label")} media="small">
      <Button size="small" onClick={onUpdate} isDisabled={selectedCount === 0}>
        {tBi("noClinicPatientsReport.action.updateSelected", {
          count: selectedCount,
        })}
      </Button>
    </ReportHeader>
  );
};
