import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import { Toggle } from "../../../../../shared/Toggle/Toggle";
import { Table } from "../../../../../shared/Table/Table";
import { Input } from "../../../../../shared/Input/Input";

/**
 * @param {object} param0
 *
 * @param {{
 *    value: boolean,
 *    onChange: (value: boolean) => void,
 * }} param0.toggle
 *
 * @param {{
 *    [key:number]: string;
 * }} param0.taxes
 *
 * @param {{
 *    [key:number]: string;
 * }} param0.clinicNames
 *
 * @param {{
 *    [key:number]: boolean;
 * }} param0.errors
 *
 * @param {({
 *    clinicId: number;
 *    tax: string;
 * }) => void} param0.onChangeTax
 *
 */
export const ClinicTaxes = ({
  toggle,
  taxes,
  clinicNames,
  errors,
  onChangeTax,
}) => {
  const data = useMemo(
    () =>
      Object.entries(taxes).map(([k, v]) => ({
        key: k,
        name: clinicNames?.[k],
        tax: (
          <Input
            type="number"
            size="small"
            value={v}
            isError={errors?.[k]}
            onChange={(e) => onChangeTax({ clinicId: k, tax: e.target.value })}
          />
        ),
      })),
    [taxes, clinicNames, errors],
  );

  return (
    <div className={styles.root}>
      <Toggle
        rtl
        fitContent
        isEnabled={toggle.value}
        onChange={() => toggle.onChange(!toggle.value)}
      >
        Custom Tax Rule (In Percentage)
      </Toggle>
      {toggle.value && (
        <Table
          bgColor="white"
          data={data}
          cols={[
            {
              data: "Clinic Name",
              accessor: "name",
            },
            {
              data: "Tax (In Percentage)",
              accessor: "tax",
            },
          ]}
        />
      )}
    </div>
  );
};
