import React from "react";
import cx from "clsx";
import styles from "./styles.module.scss";
import { Header } from "./shared/Header/Header";
import { useQueryParamsState } from "../../../utilities/hooks/useQueryParamsState";
import { useEfaxes } from "./hooks/useEfaxes";
import { Table } from "./shared/Table/Table";
import ModalAdd from "../../Clients/Efax/shared/ModalAdd/ModalAdd";

export const EfaxOrders = () => {
  const [state, setState] = useQueryParamsState({
    add: false,
  });

  const { eFaxes, search, period, order, filters } = useEfaxes();

  return (
    <div className={cx("memberWalletOuter business-section", styles.root)}>
      <Header
        search={search}
        period={period}
        onAdd={() => setState({ add: true })}
        filters={filters}
      />
      <Table eFaxes={eFaxes} order={order} />
      {state.add === "true" && (
        <ModalAdd onClose={() => setState({ add: false })} />
      )}
    </div>
  );
};
