import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  OUT_OF_OFFICE_BOOKING_TIMES,
  OUT_OF_OFFICE_BOOKING_TIMES_START,
} from "../../Event.consts";
import { EntityRow } from "../EntityRow";
import { DatePicker } from "../../../../shared/DatePicker/DatePicker";
import classes from "../../sass/Event.module.scss";
import { SelectNative } from "../../../../shared/SelectNative/SelectNative";
import { previewFormDate } from "../../Event.utils";
import { formatUTC } from "../../../../Utils/dateHelper";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { DEFAULT_TIME_FORMAT } from "../../../../consts/general";

const OutOfOfficeDateTime = ({
  date,
  onDateChange,
  isDateError,
  timeStart,
  onTimeStartChange,
  isTimeStartError,
  timeEnd,
  onTimeEndChange,
  isTimeEndError,
}) => {
  const datePickerRef = useRef();
  const { data: user } = useCurrentUserQuery();

  const prepareTimeSlots = useCallback(
    (timeSlots) => {
      const timeFormat = user?.timeFormat || DEFAULT_TIME_FORMAT;
      return timeSlots.map((t) => moment(t, "h:mma").format(timeFormat));
    },
    [user?.timeFormat],
  );

  return (
    <EntityRow
      width="none"
      label="Date & Time"
      contentClassName={classes.dateTime}
    >
      <DatePicker
        value={date ? previewFormDate(date) : null}
        onChange={onDateChange}
        isError={isDateError}
        minDate={new Date()}
        dateFormat="yyyy-MM-dd"
        name="eventDate"
        selected={date ? formatUTC(moment.utc(date).toDate()) : null}
        showDisabledMonthNavigation
        ref={datePickerRef}
        onClickOutside={() => datePickerRef.current.setOpen(false)}
        onChangeRaw={(e) => e.preventDefault()}
        className={classes.dateTimePicker}
      />
      <div className={classes.dateTimeInner}>
        <SelectNative
          name="outFrom"
          onChange={(e) => onTimeStartChange(e.target.value)}
          isError={isTimeStartError}
          className={classes.dateTimeSelect}
          value={timeStart}
          options={prepareTimeSlots(OUT_OF_OFFICE_BOOKING_TIMES_START).map(
            (t) => ({
              value: t,
              label: t,
            }),
          )}
        />
        <div>to</div>
        <SelectNative
          name="outTo"
          onChange={(e) => onTimeEndChange(e.target.value)}
          isError={isTimeEndError}
          className={classes.dateTimeSelect}
          value={timeEnd}
          options={prepareTimeSlots(OUT_OF_OFFICE_BOOKING_TIMES).map((t) => ({
            value: t,
            label: t,
            isDisabled:
              moment(timeStart, user?.timeFormat).isAfter(
                moment(t, user?.timeFormat),
              ) || timeStart === t,
          }))}
        />
      </div>
    </EntityRow>
  );
};

OutOfOfficeDateTime.propTypes = {
  date: PropTypes.string,
  onDateChange: PropTypes.func,
  isDateError: PropTypes.bool,
  timeStart: PropTypes.string,
  onTimeStartChange: PropTypes.func,
  isTimeStartError: PropTypes.bool,
  timeEnd: PropTypes.string,
  onTimeEndChange: PropTypes.func,
  isTimeEndError: PropTypes.bool,
};

OutOfOfficeDateTime.defaultProps = {
  date: "",
  onDateChange: () => {},
  isDateError: false,
  timeStart: "",
  onTimeStartChange: () => {},
  isTimeStartError: false,
  timeEnd: "",
  onTimeEndChange: () => {},
  isTimeEndError: false,
};

export default OutOfOfficeDateTime;
