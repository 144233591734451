import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  tCommon,
  useAppTranslation,
} from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { useChargeForm } from "../../hooks/useChargeForm";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useCherry } from "../../hooks/useCherry";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { PaymentAwaitLoader } from "./shared/PaymentAwaitLoader/PaymentAwaitLoader";
import { PendingPaymentAlert } from "./shared/PendingPaymentAlert/PendingPaymentAlert";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import PaymentFormModal from "./components/PaymentFormModal";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";
import { useAvailablePaymentMethods } from "../../hooks/useAvailablePaymentMethods";

function ViewChargeCherry({ isCherryPaymentWatchMode, isTipsApplying }) {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay } = useInvoice();
  const { paymentViewClose } = useInvoiceActions();

  const {
    chargeCherry,
    cancelOngoingCherryPayment,
    cancelPendingCherryPayment,
    ignorePendingCherryPayment,
    pendingCherryPayment,
  } = useCherry({
    onPaymentSuccess: () =>
      dispatch(checkoutInvoice.actions.paymentViewClose()),
  });

  const { data: cherryBalance } = useAvailablePaymentMethods(invoice.id, {
    select: (data) => data.cherry.balance,
  });

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: Math.min(cherryBalance, amountToPay),
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeCherry.initiate({ amount });
      },
      initialValues: {
        amount: Math.min(cherryBalance, amountToPay) || "",
      },
    });

  const isChargeDisabled = Boolean(
    !isValid ||
      chargeCherry.isLoading ||
      isCherryPaymentWatchMode ||
      pendingCherryPayment ||
      isTipsApplying,
  );

  const cancelOnGoingPayment = () => {
    cancelOngoingCherryPayment.initiate();
    dispatch(checkoutInvoice.actions.changeIsCherryPaymentWatchMode(false));
  };

  const maxAmountToPay = useMemo(
    () => Math.min(cherryBalance, amountToPay),
    [amountToPay, cherryBalance],
  );

  useEffect(() => {
    changeFormValue("amount", maxAmountToPay);
  }, [amountToPay]);

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.cherry")}
      imageSrc={svg.cherryPay}
      onCharge={submit}
      isCharging={chargeCherry.isLoading}
      isCancelDisabled={isCherryPaymentWatchMode}
      isChargeDisabled={isChargeDisabled}
      chargeLabel={tCommon("label.charge")}
      amount={form.amount}
    >
      <ViewTipsModal amountToPay={maxAmountToPay} />
      <PaymentFormModal
        form={form}
        errors={errors}
        hasError={hasError}
        changeFormValue={changeFormValue}
        balance={cherryBalance}
      />
      {isCherryPaymentWatchMode && (
        <PaymentAwaitLoader
          onCancelPayment={cancelOnGoingPayment}
          isCancelling={cancelOngoingCherryPayment.isLoading}
        />
      )}
      {pendingCherryPayment && (
        <PendingPaymentAlert
          message={pendingCherryPayment.message}
          onCancelPayment={cancelPendingCherryPayment.initiate}
          onIgnore={ignorePendingCherryPayment}
          isCancelling={cancelPendingCherryPayment.isLoading}
        />
      )}
    </ModalCharge>
  );
}

ViewChargeCherry.propTypes = {
  isCherryPaymentWatchMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isCherryPaymentWatchMode:
    checkoutInvoice.selectors.selectIsCherryPaymentWatchMode(state),
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCherry);
