import React from "react";
import { Group } from "../../shared/Group/Group";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { MultiRowSelect } from "../../boxes/MultiRowSelect/MultiRowSelect";
import { getFamilyHealthCheck } from "../../../MergeProfilesMain.utils";

export function FamilyHealth({ intersection, healthHistories, onChange }) {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();

  return (
    <Group label={tClients("medicalHistory.familyHealth.label")}>
      <MultiRowSelect
        value={intersection.medical_issues || []}
        patientOptions={healthHistories.map((h) => h.medical_issues || [])}
        onChange={(value) => {
          const nextIntersection = {
            ...intersection,
            medical_issues: value,
          };
          onChange({
            ...nextIntersection,
            check: getFamilyHealthCheck(nextIntersection),
          });
        }}
        renderOption={(option) => (
          <MultiRowSelect.Option
            data={[
              {
                label: tCommon("label.diagnosis"),
                value: option.diagnosis,
              },
              {
                label: tClients(
                  "medicalHistory.familyHealth.relationshipToPatient",
                ),
                value: option.relationship,
              },
            ]}
          />
        )}
      />
    </Group>
  );
}
