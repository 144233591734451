import React from "react";
import moment from "moment";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import { PREVIEW_DATE_FORMAT } from "../../../../consts/general";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

export function useTableData(users, selectHandlers) {
  const { tCommon } = useAppTranslation.Common();

  const {
    onToggleOne,
    onToggleAll,
    isSelected,
    isSelectedAll,
    isSelectedPartial,
  } = selectHandlers;

  const cols = [
    {
      data: (
        <Checkbox
          inverted
          size="small"
          isChecked={isSelectedAll()}
          isIntermediate={isSelectedPartial()}
          onChange={onToggleAll}
        />
      ),
      accessor: "checkbox",
    },
    {
      data: tCommon("label.firstName"),
      accessor: "firstName",
    },
    {
      data: tCommon("label.lastName"),
      accessor: "lastName",
    },
    {
      data: tCommon("label.email"),
      accessor: "email",
    },
    {
      data: tCommon("label.deletedAt"),
      accessor: "deletedAt",
    },
  ];

  const data = users.map((u) => ({
    key: u.id,
    firstName: u.firstName,
    lastName: u.lastName || "—",
    email: u.email || "—",
    deletedAt: moment.utc(u.deletedAt).format(PREVIEW_DATE_FORMAT),
    checkbox: (
      <Checkbox
        size="small"
        isChecked={isSelected(u.id)}
        onChange={() => onToggleOne(u.id)}
      />
    ),
  }));

  return {
    cols,
    data,
  };
}
