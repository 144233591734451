import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import moment from "moment";
import { svg } from "../../assets/svg";
import classes from "./MonthRangeSwitch.module.scss";
import { PREVIEW_DATE_FORMAT } from "../../consts/general";
import { useAppTranslation } from "../../i18n/useAppTranslation";

const MOMENT_METHOD_BY_STEP = {
  back: "subtract",
  forward: "add",
};

export function MonthRangeSwitch({
  step,
  startDate,
  endDate,
  onChange,
  className,
  isDisabled,
  dateFormat,
  isNextDisabled,
  isPrevDisabled,
}) {
  const { tCommon } = useAppTranslation.Common();

  const getFormattedRange = () => {
    const start = startDate
      ? moment(startDate).format(dateFormat)
      : tCommon("symbol.longDash");

    const end = endDate
      ? moment(endDate).format(dateFormat)
      : tCommon("symbol.longDash");

    return `${start} - ${end}`;
  };

  const onStep = (method) => {
    const start = moment(startDate)[method](step, "months").toDate();
    onChange({
      startDate: start,
      endDate: moment(start).endOf("month").toDate(),
    });
  };

  const rootClasses = cx(
    classes.root,
    {
      [classes.disabled]: isDisabled,
    },
    className,
  );

  return (
    <div className={rootClasses}>
      <button
        onClick={() => onStep(MOMENT_METHOD_BY_STEP.back)}
        disabled={isDisabled || isPrevDisabled}
      >
        <img src={svg.chevronDownPrimary} alt="<" />
      </button>
      <div className={classes.range}>{getFormattedRange()}</div>
      <button
        onClick={() => onStep(MOMENT_METHOD_BY_STEP.forward)}
        disabled={isDisabled || isNextDisabled}
      >
        <img src={svg.chevronDownPrimary} alt=">" />
      </button>
    </div>
  );
}

MonthRangeSwitch.propTypes = {
  step: PropTypes.number,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  isNextDisabled: PropTypes.bool,
  isPrevDisabled: PropTypes.bool,
};

MonthRangeSwitch.defaultProps = {
  step: 1,
  startDate: null,
  endDate: null,
  className: undefined,
  isDisabled: false,
  dateFormat: PREVIEW_DATE_FORMAT,
  isNextDisabled: false,
  isPrevDisabled: false,
};
