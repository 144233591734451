import * as yup from "yup";
import { useAppTranslation } from "../../../../../../../../../../i18n/useAppTranslation";
import { useExtendedFormik } from "../../../../../../../../../../hooks/useExtendedFormik";
import { SETTING_BMI_RANGE_MAX } from "../../../../../../../../../../api/patientVitals/config";

export const useForm = ({ initialValues, onSubmit }) => {
  const { tClients } = useAppTranslation.Clients();

  const schema = yup.object({
    id: yup.number().nullable(),

    name: yup
      .string()
      .required(tClients("vitals.settings.createBmi.nameRequired")),

    min: yup
      .number()
      .min(0, tClients("vitals.settings.createBmi.minOutOfRange", { min: 0 }))
      .test({
        message: tClients("vitals.settings.createBmi.minMaxDifference"),
        test: (v, context) => {
          return context.parent.max ? v < context.parent.max : true;
        },
      })
      .required(tClients("vitals.settings.createBmi.minRequired")),

    max: yup
      .number()
      .max(
        SETTING_BMI_RANGE_MAX,
        tClients("vitals.settings.createBmi.maxValue", {
          max: SETTING_BMI_RANGE_MAX,
        }),
      )
      .nullable(),

    color: yup
      .string()
      .required(tClients("vitals.settings.createBmi.colorRequired")),
  });

  const form = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues,
    onSubmit,
  });

  return form;
};
