import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  patientId: yup.number().required(),
  procedureId: yup.number().required(),
});

export function useDeletePatientProcedureMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.delete(
      HTTP_ENDPOINTS.deletePatientProcedure({
        patientId: req.patientId,
        procedureId: req.procedureId,
      }),
    );
  }, options);
}
