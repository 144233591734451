import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchStripeTosUpdate,
  fetchStripeUpdateCheck,
  fetchStripeUpdateUrl,
} from "../../Actions/stripeUpdateInfoActions";

class StripeInfoUpdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.userData = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      _tosIsShown: false,
      _infoIsShown: false,
    };
  }

  componentDidMount() {
    if (this.userData && this.userData.user_type === "superadmin") {
      this.props.fetchStripeUpdateCheck();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.stripeUpdateUrlData.status === "loading" &&
      this.props.stripeUpdateUrlData.status === "success"
    ) {
      window.location.replace(this.props.stripeUpdateUrlData.url);
    }

    if (prevProps.tosNeedsUpdate === null && this.props.tosNeedsUpdate) {
      this.setState({ _tosIsShown: true });
    }

    if (
      prevProps.tosNeedsUpdate === null &&
      !this.props.tosNeedsUpdate &&
      this.props.accountNeedsUpdate
    ) {
      this.setState({ _infoIsShown: true });
    }
  }

  get isFetchingStripeUrl() {
    return this.props.stripeUpdateUrlData.status === "loading";
  }

  closeModal = () => {
    this.setState({ _infoIsShown: false });
  };

  handleUpdateStripeInfo = () => {
    this.props.fetchStripeUpdateUrl(this.props.accountId);
  };

  handleUpdateStripeTos = () => {
    this.props.fetchStripeTosUpdate(this.props.accounts);

    if (this.props.AccountNeedsUpdate) {
      this.setState({ _tosIsShown: false, _infoIsShown: true });
    }

    this.setState({ _tosIsShown: false });
  };

  render() {
    return (
      <>
        <div className={this.state._tosIsShown ? "overlay" : ""} />
        <div
          role="dialog"
          className={
            this.state._tosIsShown
              ? "modal fade in displayBlock text-center"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header no-border">
                <h4 className="modal-title text-center" id="model_title">
                  Please Review and Accept Stripe’s Terms Of Service{" "}
                </h4>
              </div>
              <div className="modal-body text-center">
                By agreeing to{" "}
                <a
                  href="https://stripe.com/en-gb-ro/connect-account/legal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stripe Connected Account Agreement
                </a>
                , which includes the{" "}
                <a
                  href="https://stripe.com/en-gb-ro/legal"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Stripe Terms of Service
                </a>
                , you agree to be bound by the Stripe Services Agreement, as the
                same may be modified by Stripe from time to time.
              </div>
              <div className="modal-footer no-border">
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={this.handleUpdateStripeTos}
                  >
                    I agree
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state._infoIsShown ? "overlay" : ""} />
        <div
          role="dialog"
          className={
            this.state._infoIsShown
              ? "modal fade in displayBlock"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.closeModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  Stripe information update required
                </h4>
              </div>
              <div className="modal-body">
                Stripe requires additional business information from you. Please
                click the button below to start the update process.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success pull-right"
                  data-dismiss="modal"
                  onClick={this.handleUpdateStripeInfo}
                >
                  {this.isFetchingStripeUrl
                    ? "Loading"
                    : "Update Stripe Information"}
                </button>
                <button
                  type="button"
                  className="btn  logout pull-right m-r-10"
                  data-dismiss="modal"
                  onClick={this.closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const props = {};

  props.actionRequired =
    state.StripeInfoUpdateReducer.stripeCheckData.message ===
    "actions_required";
  props.accountId = state.StripeInfoUpdateReducer.stripeCheckData.accountId;
  props.accountNeedsUpdate =
    state.StripeInfoUpdateReducer.stripeCheckData.accountNeedsUpdate;
  props.tosNeedsUpdate =
    state.StripeInfoUpdateReducer.stripeCheckData.tosNeedsUpdate;
  props.accounts = state.StripeInfoUpdateReducer.stripeCheckData.accounts;

  props.stripeUpdateUrlData = state.StripeInfoUpdateReducer.stripeUpdateUrlData;

  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchStripeUpdateCheck,
      fetchStripeUpdateUrl,
      fetchStripeTosUpdate,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripeInfoUpdateModal);
