import { useEffect } from "react";
import moment from "moment";
import queryString from "query-string";
import { useProvidersQuery } from "../../../../../../api/queries/useProvidersQuery";

export function usePrefill({ filter, period }) {
  const { data: providers } = useProvidersQuery();

  useEffect(() => {
    const providerId = Number(
      queryString.parse(window.location.search)?.providerId,
    );
    const provider = (providers?.data?.data || []).find(
      (provider) => provider.id === providerId,
    );
    if (
      provider &&
      !filter.value.providers.find((p) => p.value === provider.id)
    ) {
      filter.update((prev) => ({
        ...prev,
        providers: [
          ...prev.providers,
          {
            label: provider.full_name,
            value: provider.id,
          },
        ],
      }));
    }
  }, [window.location.search, providers?.data?.data]);

  useEffect(() => {
    const fromDate = queryString.parse(window.location.search)?.from;
    const toDate = queryString.parse(window.location.search)?.to;
    period.update({
      from: moment(fromDate).toDate(),
      to: moment(toDate).toDate(),
    });
  }, [window.location.search]);
}
