import React, { useState } from "react";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { Button } from "../../../shared/Button/Button";
import { usePermission } from "../../../hooks/usePermission";
import { PERMISSIONS } from "../../../consts/api";
import Loader from "../../Common/Loader";
import { Input } from "../../../shared/Input/Input";
import {
  useBulkUnlockProcedureQuery,
  useCheckValidUserQuery,
} from "../../../_legacy/Queries";
import { uiNotification } from "../../../services/UINotificationService";
import { handleApiErrorMessage } from "../../../utilities/api";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateProcedureLockStatus } from "../../../Actions/Clients/clientsAction";

const ConfirmUnlockModal = ({
  isOpen,
  handleClose,
  procedureIds,
  refetchProcedures,
  downloadProcedures,
  updateProcedureLockStatus,
}) => {
  const { mutate: bulkUnlockProcedureQuery, isLoading } =
    useBulkUnlockProcedureQuery();
  const { mutate: checkValidUserQuery, isLoading: isInvalidateLoading } =
    useCheckValidUserQuery();

  const [passwordModal, setpasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const lockUnlockPermission = usePermission(
    PERMISSIONS.patientManagement.lockUnlockProcedure,
  );

  const login = (formValues) => {
    checkValidUserQuery(formValues, {
      onSuccess: () => lockThisProcedure(),
      onError: (error) => uiNotification.error(handleApiErrorMessage(error)),
    });
  };

  const validateUser = () => {
    setpasswordModal(true);
  };

  const handleVerify = () => {
    login({ password: password });
  };

  const handleSetValue = (e) => {
    setPassword(e.target.value);
  };

  const lockThisProcedure = async () => {
    await bulkUnlockProcedureQuery(
      {
        procedure_ids: procedureIds,
      },
      {
        onSuccess: async () => {
          handleClose();
          for (const id of procedureIds) {
            await updateProcedureLockStatus({
              id: id,
              is_lock: 0,
            });
          }
          await refetchProcedures();
          await downloadProcedures();
        },
      },
    );
  };

  const hasLockUnlockPermissions = lockUnlockPermission.loaded && lockUnlockPermission.permitted;

  return (
    <>
      {hasLockUnlockPermissions && !passwordModal && (
        <ConfirmModal
          className="confirm-modal-center validate-modal"
          isOpen={isOpen}
          onConfirm={validateUser}
          onCancel={handleClose}
          confirmTitle={"Unlock All"}
        >
          Please unlock all procedure to export{" "}
          {isLoading && <Loader showLoader />}
        </ConfirmModal>
      )}
      {!hasLockUnlockPermissions && !passwordModal && (
        <ConfirmModal
          className="confirm-modal-center validate-modal"
          isOpen={isOpen}
        >
          <div className="flex-col items-center  m-b-20">
            <p> This selected procedure is locked, please contact admin </p>
            <Button onClick={handleClose}>Ok </Button>
          </div>
        </ConfirmModal>
      )}

      {passwordModal && (
        <ConfirmModal
          className="confirm-modal-center validate-modal"
          isOpen={passwordModal}
          onClose={handleClose}
          title="Verify Your Credentials"
        >
          <Input
            type="password"
            name="password"
            value={password}
            onChange={handleSetValue}
            placeholder="Password"
          />{" "}
          <div className="d-flex float-right m-t-20 m-b-20 ">
            <Button variant="outlined" className="m-r-10" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              isDisabled={isInvalidateLoading}
              leftAdornment={
                isInvalidateLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
              onClick={handleVerify}
            >
              Verify
            </Button>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateProcedureLockStatus,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ConfirmUnlockModal);
