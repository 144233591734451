import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { LoadingAlert } from "../../../../../../../boxes/LoadingAlert/LoadingAlert";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";

export function PaymentAwaitLoader({ onCancelPayment, isCancelling }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();

  return (
    <LoadingAlert>
      <Trans
        i18nKey={tSales("checkoutInvoice.warn.awaitingForCherryPayment")}
        components={{
          b: <b />,
        }}
      />
      <Button
        size="small"
        color="warn"
        onClick={onCancelPayment}
        isDisabled={isCancelling}
        leftAdornment={
          isCancelling ? (
            <CircularProgress size="tiny" color="white" />
          ) : undefined
        }
      >
        {tCommon("label.cancelPayment")}
      </Button>
    </LoadingAlert>
  );
}

PaymentAwaitLoader.propTypes = {
  onCancelPayment: PropTypes.func.isRequired,
  isCancelling: PropTypes.bool.isRequired,
};
