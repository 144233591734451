import React from "react";
import PropTypes from "prop-types";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function ModalConfirmCreateExam({ isOpen, onClose, onSend }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onSend}
      title={tCommon("event.sendExamModal.title")}
      cancelTitle={tCommon("event.sendExamModal.cancel")}
      confirmTitle={tCommon("event.sendExamModal.confirm")}
    >
      {tCommon("event.sendExamModal.text")}
    </ConfirmModal>
  );
}

ModalConfirmCreateExam.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};
