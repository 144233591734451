import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useAgreementData = ({ membershipId, objectId }, options = {}) => {
  return useQuery(
    ["agreementData"],
    () => {
      return http.get(HTTP_ENDPOINTS.getAgreementData(membershipId, objectId));
    },
    { ...options, enabled: false },
  );
};
