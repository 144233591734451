import React from "react";
import cx from "clsx";
import { FileDropzone } from "../../../../../shared/FileDropzone/FileDropzone";
import { uiNotification } from "../../../../../services/UINotificationService";
import styles from "./styles.module.scss";
import { Modal } from "../../../../../shared/Modal/Modal";
import { Button } from "../../../../../shared/Button/Button";
import { DeleteIcon } from "../../../../../assets/Icons/DeleteIcon";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { droppedFileAdapter, isApiFile } from "./utilities";
import { useApiFile } from "./hooks/useApiFile";
import { useUploadFile } from "./hooks/useUploadFile";
import { useDeleteApiFile } from "./hooks/useDeleteApiFile";

export function TaxFreeStatus() {
  const [userFile, setUserFile] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const apiFile = useApiFile(setUserFile);
  const upload = useUploadFile();
  const del = useDeleteApiFile(() => setUserFile(null));

  const file = userFile || apiFile.value;

  return (
    <>
      <button
        disabled={apiFile.isLoading}
        className={cx("easy-link no-padding", styles.modalBtn)}
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
      >
        Tax Free Status
        {apiFile.isLoading && <CircularProgress size="tiny" />}
      </button>
      {isModalOpen && (
        <Modal
          footerNoBorder
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          header={<Modal.Title>Upload File</Modal.Title>}
          footer={
            <Button
              isDisabled={
                !file || isApiFile(file) || upload.isLoading || del.isLoading
              }
              onClick={() => upload.initiate(file)}
              leftAdornment={
                upload.isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              Save
            </Button>
          }
        >
          <FileDropzone
            multiple={false}
            isDisabled={upload.isLoading}
            supportedExtensions={[".pdf", ".jpg", ".png", ".jpeg"]}
            onError={uiNotification.error.bind(uiNotification)}
            onDrop={(files) => {
              setUserFile(droppedFileAdapter(files[0]));
            }}
          />
          {file && (
            <div className={styles.file}>
              <a href={file.path} target="_blank" rel="noopener noreferrer">
                {file.name}
              </a>
              <Button
                size="tiny"
                variant="text"
                color="error"
                onClick={() => {
                  if (
                    !del.isLoading &&
                    !apiFile.isLoading &&
                    !upload.isLoading
                  ) {
                    return del.initiate(file);
                  }
                }}
              >
                {del.isLoading ? (
                  <CircularProgress size="tiny" color="red" />
                ) : (
                  <DeleteIcon width="16px" />
                )}
              </Button>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}
