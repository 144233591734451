import React from "react";
import { PlusIcon } from "../../../../../../../../../assets/Icons/PlusIcon";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import classes from "./ButtonAdd.module.scss";

/**
 * @param {Object} param0
 *
 * @param {() => void} param0.onClick
 */
export const ButtonAdd = ({ onClick }) => {
  const { tCommon } = useAppTranslation.Common();

  return (
    <button onClick={onClick} className={classes.root}>
      <PlusIcon width="12px" />
      {tCommon("label.add")}
    </button>
  );
};
