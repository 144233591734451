import React from "react";
import { AlertBox } from "../../../../shared/AlertBox/AlertBox";
import classes from "./Attachments.module.scss";
import { FileDropzone } from "../../../../shared/FileDropzone/FileDropzone";
import { uiNotification } from "../../../../services/UINotificationService";

export const Attachments = ({ value, onChange, message }) => (
  <div className={classes.root}>
    {message && <AlertBox variant="success">{message}</AlertBox>}
    <FileDropzone
      multiple={false}
      onDrop={onChange}
      supportedExtensions={[".jpeg", ".jpg", ".png", ".pdf"]}
      onError={uiNotification.error}
      onRemoveFile={(id) => onChange(value.filter((f) => f.id !== id))}
      selectedFiles={value}
    />
  </div>
);
