import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  messageIds: yup.array().of(yup.number()).required(),
  isUnread: yup.bool().required(),
});

export function useUpdateLeadArSmsReadStatusMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.put(HTTP_ENDPOINTS.putLeadArSmsReadUnreadStatus(), {
      id: req.messageIds,
      read_status: req.isUnread ? 0 : 1,
    });
  }, options);
}
