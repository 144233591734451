import React from "react";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../shared/Input/Input";
import { InputError } from "../../../../shared/InputError/InputError";

export function InputText({ label, value, placeholder, onChange, error }) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Input
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        isError={!!error}
      />
      {!!error && <InputError>{error}</InputError>}
    </div>
  );
}
