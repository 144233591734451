import React from "react";
import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const responseSchema = yup.object({
  isAvailable: yup.bool().required(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return {
    isAvailable: Boolean(data.available),
  };
};

function createLeadArAvailabilityStatusQueryKey() {
  return [QUERY_KEYS.leadArAvailabilityStatus];
}

function useLeadArAvailabilityStatusQuery({ options = {} } = {}) {
  return useQuery(
    createLeadArAvailabilityStatusQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getLeadArAvailabilityStatus());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}

export const withLeadArAvailabilityStatusQuery = (Component) => {
  return function Wrapper(props) {
    const query = useLeadArAvailabilityStatusQuery();
    return <Component {...props} leadArAvailabilityStatusQuery={query} />;
  };
};
