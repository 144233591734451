import React from "react";
import "../chartAudit.scss"; // Import the SCSS file
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
const ChartAuditListView = ({ allCountData }) => {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { pending, sent_to_md, signed_by_md, signed } = allCountData;

  return (
    <div className="chart-audit-container">
      <div className="box p-2 ">
        <div className="text-wrapper text-wrapper-fixed">
          <h4>
            {tBi(
              "chartAuditReport.totalNumberOfChartData.chart_awaiting_provider",
            )}
          </h4>
        </div>
        <span className="span-fixed">{pending}</span>
      </div>
      <div className="box p-2">
        <div className="text-wrapper text-wrapper-fixed">
          <h4>
            {tBi("chartAuditReport.table.ProviderCol.chart_provider_signed")}
          </h4>
        </div>
        <span className="span-fixed">{signed}</span>
      </div>
      <div className="box p-2">
        <div className="text-wrapper text-wrapper-fixed">
          <h4>
            {tBi("chartAuditReport.totalNumberOfChartData.chart_awaiting_md")}
          </h4>
        </div>
        <span className="span-fixed">{sent_to_md}</span>
      </div>
      <div className="box p-2">
        <div className="text-wrapper text-wrapper-fixed">
          <h4>
            {tBi("chartAuditReport.totalNumberOfChartData.chart_signed_return")}
          </h4>
        </div>
        <span className="span-fixed">{signed_by_md}</span>
      </div>
    </div>
  );
};
export default ChartAuditListView;
