import React, { useMemo } from "react";
import { useClinicPaymentAbilitiesAndCancelPolicyQuery } from "../../../api/queries/useClinicPaymentAbilitiesAndCancelPolicyQuery";

export const withToggleGuard = (Component) => {
  return function Wrapper(props) {
    const { data, isFetching } =
      useClinicPaymentAbilitiesAndCancelPolicyQuery();

    const isCanEnableCancellationPolicy = useMemo(() => {
      let result = false;
      if (data && !isFetching) {
        const clinics = data.data.data.clinics
          ? Object.values(data.data.data.clinics)
          : [];
        result = clinics.some((c) => c.can_take_payment);
      }
      return result;
    }, [data, isFetching]);

    return (
      <Component
        {...props}
        toggleGuard={{
          isCanEnableCancellationPolicy,
        }}
      />
    );
  };
};
