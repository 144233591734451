import React from "react";
import classes from "./Maintenance.module.scss";
import maintenance from "../../../src/_legacy/images/maintenance.png";

const MaintenancePage = () => {
  return (
    <div className={classes.maintenanceOverlay}>
      <div className={classes.maintenanceContainer}>
        <h3>
          Pardon Our Progress. We are upgrading our site to better serve you.
        </h3>
        <div>
          <div
            className={classes.maintenanceImage}
            style={{
              backgroundImage: `url(${maintenance})`,
            }}
          >
            <div className={classes.maintenanceAnimationContainer}>
              <img
                src="https://media4.giphy.com/media/PnsF0HweRIw2A7K9yp/giphy.gif"
                alt="Loading animation sticker"
                className={classes.animationImage}
              />
            </div>
          </div>
        </div>
        <p>Please contact us directly if you need further assistance.</p>
      </div>
    </div>
  );
};

export default MaintenancePage;
