import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function viewTreatmentMarkings(procedureID) {
  return (dispatch) => {
    http
      .get("view_treatment_marking_images/" + procedureID)
      .then((response) => {
        dispatch({ type: "VIEW_TREATMENT_MARKINGS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "VIEW_TREATMENT_MARKINGS",
          payload: error.response.data,
        });
      });
  };
}
