import { useMemo } from "react";
import moment from "moment";
import { API_DATE_FORMAT, ORDER_TYPES } from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useQueryParamsState } from "../../../../utilities/hooks/useQueryParamsState";
import { useEfaxesQuery } from "../../../../api/efax/useEfaxesQuery";

const prepareReqOrder = (state) => {
  if (state.sortBy && state.sortOrder) {
    return {
      sortBy: state.sortBy,
      sortOrder: state.sortOrder,
    };
  }

  return {};
};

export const useEfaxes = (match) => {
  const { tInbox } = useAppTranslation.Inbox();

  const [state, setState] = useQueryParamsState({
    sortBy: "",
    sortOrder: "",

    from: moment().startOf("month").format(API_DATE_FORMAT),
    to: moment().endOf("month").format(API_DATE_FORMAT),
  });

  const updateOrder = (orderFieldKey) => {
    setState((state) => ({
      sortBy: orderFieldKey,
      sortOrder:
        state.sortOrder === ORDER_TYPES.desc
          ? ORDER_TYPES.asc
          : ORDER_TYPES.desc,
    }));
  };

  const updatePeriod = ({ from, to }) => {
    setState({
      from: from,
      to: to,
    });
  };

  const { clientID } = match.params;
  const patientId = parseInt(clientID, 10);

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useEfaxesQuery({
    payload: {
      pageSize: 15,
      patientId: patientId,
      fromDate: state.from,
      toDate: state.to,
      ...prepareReqOrder(state),
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      enabled: Boolean(state.from),
      onError: () => {
        uiNotification.error(tInbox("efaxOrders.error.fetchFaxes"));
      },
    },
  });

  const eFaxes = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.eFaxes).reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const isOrderActive = [state.sortBy, state.sortOrder].some(Boolean);
  const isPeriodActive = [state.from, state.to].some(Boolean);

  const isSoftLoading =
    (isOrderActive || isPeriodActive) &&
    isFetching &&
    !isLoading &&
    !isFetchingNextPage;

  return {
    eFaxes: {
      value: eFaxes,
      isLoading,
      isSoftLoading,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      fetchMore: fetchNextPage,
    },
    order: {
      value: {
        by: state.sortBy,
        direction: state.sortOrder,
      },
      update: updateOrder,
    },
    period: {
      value: {
        from: state.from,
        to: state.to,
      },
      update: updatePeriod,
    },
  };
};
