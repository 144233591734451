import React from "react";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import { Input } from "../../../../../../../../shared/Input/Input";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { usePaymentFlow } from "../../../../../hooks/usePaymentFlow";
import { Checkbox } from "../../../../../../../../shared/Checkbox/Checkbox";

const PaymentFormModal = ({
  isLoading,
  isValid,
  submit,
  form,
  errors,
  hasError,
  changeFormValue,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { isGuest } = useInvoice();
  const { isTipsAvailable } = usePaymentFlow();

  return (
    <>
      <ModalCharge.Content>
        <div id="payment-form" />
        <ModalCharge.InputRow>
          <ModalCharge.InputWrap fullWidth>
            <InputLabel>{tCommon("label.email")}</InputLabel>
            <Input
              type="email"
              size="medium"
              value={form.email}
              isError={hasError("email")}
              onChange={(e) => changeFormValue("email", e.target.value)}
              placeholder={tCommon("formPlaceholder.email")}
            />
            {hasError("email") && <InputError>{errors.email}</InputError>}
          </ModalCharge.InputWrap>
          <ModalCharge.InputWrap width="170px">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.zipCode")}
            </InputLabel>
            <Input
              type="number"
              size="medium"
              value={form.zipCode}
              isError={hasError("zipCode")}
              onChange={(e) => changeFormValue("zipCode", e.target.value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.zipCode",
              )}
            />
            {hasError("zipCode") && <InputError>{errors.zipCode}</InputError>}
          </ModalCharge.InputWrap>
        </ModalCharge.InputRow>
        {!isGuest && (
          <ModalCharge.InputWrap>
            <Checkbox
              size="small"
              label={tSales("checkoutInvoice.saveCcToAccount")}
              isChecked={form.saveCard}
              onChange={() => changeFormValue("saveCard", !form.saveCard)}
            />
          </ModalCharge.InputWrap>
        )}
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        isCharging={isLoading}
        onCharge={submit}
        isChargeDisabled={!isValid || isLoading}
        chargeLabel={
          isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
        }
      />
    </>
  );
};

export default PaymentFormModal;
