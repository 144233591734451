import * as yup from "yup";
import { LOCAL_STORAGE_KEYS } from "../../../consts/localStorage";
import {
  TOKENS,
  CLIENT_TOKENS,
  PATIENT_TOKENS,
  TOKENS_RESTRICTIONS,
} from "../GlobalSearch.consts";
import { useCurrentAccountQuery } from "../../../api/queries/useAccountQuery";
import { CLIENT_GLOBAL_NAME } from "../../../consts/api";

class GlobalSearchService {
  storageKey = LOCAL_STORAGE_KEYS.globalSearchUsedPatterns;
  storagePatternsSchema = yup.array().of(yup.string());
  savedPatternsLimit = 4;

  constructor(tokens) {
    /**
     * @type {Record<string, string}
     */
    this.tokens = tokens;

    /**
     * @type {string[]}
     */
    this.patterns = Object.keys(tokens);
  }

  /**
   *
   * @param {string} pattern
   * @returns {string}
   */
  _preparePatternForSearch(pattern) {
    if (typeof pattern === "string") {
      return pattern.toLowerCase().replace(/\s+/g, " ").trim();
    }
    throw new Error('Type of "pattern" argument should be a string');
  }

  /**
   *
   * @param {string[]} patterns
   * @returns {boolean}
   */
  _validatePatterns(patterns) {
    try {
      const isValid = Boolean(
        this.storagePatternsSchema.validateSync(patterns, {
          strict: true,
        }),
      );
      return isValid && patterns.length > 0;
    } catch {
      return false;
    }
  }

  /**
   *
   * @param {string[]} patterns
   * @returns {string[]}
   */
  _filterPatterns(patterns) {
    return patterns
      .filter((p) => this.patterns.includes(p))
      .reduce((r, p) => (r.includes(p) ? r : [...r, p]), []);
  }

  /**
   *
   * @param {string} userPattern
   * @returns {string[]}
   */
  findPatterns(userPattern) {
    const userPatternForSearch = this._preparePatternForSearch(userPattern);
    return this.patterns.filter((p) => {
      const preparedPattern = this._preparePatternForSearch(p);
      const isTokenRestricted = TOKENS_RESTRICTIONS[p];
      return (
        preparedPattern.includes(userPatternForSearch) && !isTokenRestricted
      );
    });
  }

  /**
   *
   * @param {string} pattern
   * @returns {string}
   */
  getTokenValueByPattern(pattern) {
    return this.tokens[pattern];
  }

  /**
   *
   * @param {string[]} patterns
   */
  savePattern(pattern) {
    const nextPatterns = [pattern, ...this.getSavedPatterns()];
    if (this._validatePatterns(nextPatterns)) {
      const filteredPatterns = this._filterPatterns(nextPatterns);
      localStorage.setItem(
        this.storageKey,
        JSON.stringify(filteredPatterns.slice(0, this.savedPatternsLimit)),
      );
    }
  }

  /**
   *
   * @returns {string[]}
   */
  getSavedPatterns() {
    const parsedPatterns = JSON.parse(localStorage.getItem(this.storageKey));
    if (this._validatePatterns(parsedPatterns)) {
      return this._filterPatterns(parsedPatterns);
    }
    return [];
  }
}

export function useGlobalSearchService() {
  const accountQuery = useCurrentAccountQuery();

  const preparedTokens = (() => {
    const clientGlobalName = accountQuery.data?.preferences?.clientGlobalName;
    return {
      ...TOKENS,
      ...(clientGlobalName === CLIENT_GLOBAL_NAME.patient
        ? PATIENT_TOKENS
        : CLIENT_TOKENS),
    };
  })();

  return new GlobalSearchService(preparedTokens);
}
