import { useEffect, useState } from "react";

export function useDebouncedState(initialState, ms = 500) {
  const [state, setState] = useState(initialState);
  const [debouncedState, setDebouncedState] = useState(initialState);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedState(state);
    }, ms);
    return () => {
      clearInterval(debounce);
    };
  }, [ms, state]);

  return [state, debouncedState, setState];
}
