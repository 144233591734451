import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import PropTypes from "prop-types";
import classes from "./ReportStats.module.scss";
import { Skeleton } from "../../shared/Skeleton/Skeleton";
import { Box } from "../../shared/Box/Box";
import { THEME } from "../../consts/theme";

export function ReportStats({ isLoading, data }) {
  return (
    <div className={classes.root}>
      {isLoading && (
        <SkeletonTheme baseColor={THEME.colors.white}>
          <Skeleton
            count={data.length}
            containerClassName={classes.skeleton}
            height={93.7}
          />
        </SkeletonTheme>
      )}
      {!isLoading &&
        data.map((node) => (
          <Box key={node.id} className={classes.node}>
            <div className={classes.nodeCount}>{node.data}</div>
            <div className={classes.nodeName}>{node.name}</div>
          </Box>
        ))}
    </div>
  );
}

ReportStats.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
};

ReportStats.defaultProps = {
  isLoading: false,
  data: [],
};
