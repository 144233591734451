import { useMemo } from "react";
import { useConsentsQuery } from "../../../../../../api/consents/useConsentsQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import {
  PATIENT_CONSENTS_ENTITY_TYPES,
  usePatientConsentsQuery,
} from "../../../../../../api/consents/patientConsents/usePatientConsentsQuery";
import { useProcedureQuery } from "../../../../../../api/procedure/useProcedureQuery";
import { mergeDocumentOptions } from "../Documents.utils";
import { procedureDocumentId } from "../../../utilities";

export function useConsentOptions({
  appointmentId,
  procedureId,
  serviceId,
  select,
}) {
  const { tCommon } = useAppTranslation.Common();

  const { data: consents, isLoading: isConsentsLoading } = useConsentsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.consents.fetchAll"));
    },
  });

  const { data: procedure, isFetching: isProcedureLoading } = useProcedureQuery(
    {
      payload: {
        procedureId,
      },
      options: {
        enabled: Boolean(procedureId),
      },
    },
  );

  const { data: patientConsents, isFetching: isPatientConsentsLoading } =
    usePatientConsentsQuery({
      payload: {
        entityId: appointmentId,
        entityType: PATIENT_CONSENTS_ENTITY_TYPES.appointment,
        serviceId,
      },
      options: {
        enabled: Boolean(appointmentId),
        onError: () => {
          uiNotification.error(
            tCommon("error.patientConsents.fetchByAppointment"),
          );
        },
        onSuccess: (data) => {
          select(
            data?.map((c) => ({
              id: c.id,
              templateId: c.consent.id,
            })) || [],
          );
        },
      },
    });

  const consentOptions = useMemo(
    () =>
      consents?.map((c) => ({
        label: c.consent_name,
        value: String(c.id),
        isDisabled: Boolean(
          procedure?.patientConsents?.find((pc) => pc.consentId === c.id) ||
            patientConsents?.find((pc) => pc.consent_id === c.id),
        ),
      })) || [],
    [consents, procedure?.patientConsents, patientConsents],
  );

  const procedureConsentOptions = useMemo(
    () =>
      procedure?.patientConsents?.map((c) => ({
        label: c.name,
        value: procedureDocumentId.compose(c.consentId, c.id),
        isCompleted: c.isSigned,
      })) || [],
    [procedure?.patientConsents],
  );

  const patientConsentOptions = useMemo(
    () =>
      patientConsents?.map((c) => ({
        label: c.consent?.consent_name,
        value: String(c.consent_id),
        isCompleted: Boolean(c.status),
      })) || [],
    [patientConsents],
  );

  return useMemo(() => {
    return {
      data: mergeDocumentOptions(
        [...procedureConsentOptions, ...patientConsentOptions],
        consentOptions,
      ),
      isLoading:
        isProcedureLoading || isConsentsLoading || isPatientConsentsLoading,
      patientDocuments: [...patientConsentOptions, ...procedureConsentOptions],
    };
  }, [
    consentOptions,
    procedureConsentOptions,
    patientConsentOptions,
    isConsentsLoading,
    isProcedureLoading,
    isPatientConsentsLoading,
  ]);
}
