import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.object({
  count: yup.number().required(),
  orders: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
        totalPrice: yup.string().required(),
        createdAt: yup.string().required(),
        currencyCode: yup.string().required(),
        displayFulfillmentStatus: yup.string().required(),
        displayFinancialStatus: yup.string().required(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  return res.data.data || null;
};

const createNetSalesQueryKey = (...args) => [QUERY_KEYS.shopify, ...args];

export const useShopifyQuery = ({ options = {} }) => {
  return useQuery(
    createNetSalesQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getShopifyOrders());

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
