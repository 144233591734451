import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./PageHeader.module.scss";
import { Button } from "../../shared/Button/Button";
import { ChevronLeftIcon } from "../../assets/Icons/ChevronLeftIcon";
import { history } from "../../history";

export function PageHeader({
  withBackBtn,
  title,
  rightAdornment,
  className,
  noBorderBottom,
  titleRightAdornment,
  bottomAdornment,
  noPadding,
  onBack,
}) {
  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.rootNoBorderBottom]: noBorderBottom,
          [classes.rootNoPadding]: noPadding,
        },
        className,
      )}
    >
      <div className={classes.inner}>
        <div className={classes.left}>
          {withBackBtn && (
            <Button
              variant="text"
              size="small"
              onClick={() => (onBack ? onBack() : history.goBack())}
            >
              <ChevronLeftIcon />
            </Button>
          )}
          <h2 className={classes.title}>{title}</h2>
          {titleRightAdornment}
        </div>
        {rightAdornment}
      </div>
      {bottomAdornment}
    </div>
  );
}

PageHeader.propTypes = {
  withBackBtn: PropTypes.bool,
  title: PropTypes.node.isRequired,
  rightAdornment: PropTypes.node,
  className: PropTypes.string,
  noBorderBottom: PropTypes.bool,
  titleRightAdornment: PropTypes.node,
  noPadding: PropTypes.bool,
};

PageHeader.defaultProps = {
  withBackBtn: true,
  noBorderBottom: false,
  noPadding: false,
};
