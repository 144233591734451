import { matchPath } from "react-router";
import { ROUTES } from "../../../consts/routes";

export function getPathParams() {
  const { params } = matchPath(window.location.pathname, {
    path: ROUTES.patientQuestionnaires.one(":id"),
    exact: true,
    strict: false,
  });

  return {
    patientQuestionnaireId: params.id ? Number(params.id) : undefined,
  };
}
