import moment from "moment";
import validator from "validator";
import { clinicTimezone } from "../utilities/localStorage";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";
import { http } from "../services/HttpService";

export function isLoggedIn() {
  return localStorage.getItem("isLoggedIn");
}

export function getUser() {
  return localStorage.getItem("userData");
}

export function setToken(access_token) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.accessToken, access_token);
  return true;
}

export function getToken() {
  let token = localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken);

  if (token) {
    return token;
  } else {
    return false;
  }
}

export async function logout() {
  return http
    .get("user/logout")
    .then(() => {
      clearToken();
      clearUserData();
      window.location.href = "/login";
      return;
    })
    .catch(() => {
      clearToken();
      clearUserData();
      window.location.href = "/login";
    });
}

export function clearToken() {
  localStorage.removeItem("access_token");
  return;
}

export function handleInvalidToken(redirect = true) {
  clearToken();
  clearUserData();
  if (redirect) {
    window.location.href = "/login";
  }
}

export function clearUserData() {
  localStorage.removeItem("userData");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("globalPrivileges");
  localStorage.removeItem("tempLoggedUserData");
  localStorage.removeItem("calendarFilter");
  localStorage.removeItem("selectionRange");
  localStorage.removeItem("selectionRange-invoices");
  localStorage.removeItem("selectionRange-report");
  localStorage.removeItem("dcsDateRange");
  localStorage.removeItem("cnsDateRange");
  localStorage.removeItem("psDateRange");
  localStorage.removeItem("pnsDateRange");
  localStorage.removeItem("prosalesDate");
  localStorage.removeItem("mudDateRange");
  localStorage.removeItem("bpcDateRange");
  localStorage.removeItem("bppDateRange");
  localStorage.removeItem("providerWeekFilter");
  localStorage.removeItem("resourceData");
  localStorage.removeItem("jwt");
  localStorage.removeItem("applicationData");

  clinicTimezone.remove();

  let languageData = JSON.parse(localStorage.getItem("languageData"));

  for (let x in languageData) {
    if (x != "guest" && x != "global") {
      delete languageData[x];
    }
  }

  localStorage.setItem("languageData", JSON.stringify(languageData));
  localStorage.removeItem("insight-sales-tier-id");
  localStorage.removeItem("insight-sales-date");
  localStorage.removeItem("insight-sales-clinicid");
  localStorage.removeItem("countVal");
  localStorage.removeItem("showLoader");
  localStorage.removeItem("sortOnly");
  localStorage.removeItem("currentUserRole");
  localStorage.removeItem("loadFresh");
  localStorage.removeItem("login_attempts");
  localStorage.removeItem("defTimeLine");
  localStorage.removeItem("timeFormat");
  localStorage.removeItem("dateFormat");
  localStorage.removeItem("stripeConnection");
  localStorage.removeItem("isPosEnabled");
  localStorage.removeItem("connectionFee");
  localStorage.removeItem("redirectTo");
  localStorage.removeItem("flashToast");
  localStorage.removeItem("currencySymbol");
  localStorage.removeItem("currencyLabel");
  localStorage.removeItem("subscriptionPlan");
  localStorage.removeItem("subscriptionType");
  localStorage.removeItem("accountType");
  localStorage.removeItem("accountStatus");
  localStorage.removeItem("twilioFromNumber");
  localStorage.removeItem("timeClockSetting");
  localStorage.removeItem("cSortBy");
  localStorage.removeItem("cSortOrder");
  localStorage.removeItem("cLetterKey");
  localStorage.removeItem("cTerm");
  localStorage.removeItem("cFilterID");
  localStorage.removeItem("cFilterName");
  localStorage.removeItem("RecentlySelectionRange");
  localStorage.removeItem("UserLogSelectionRange");
  localStorage.removeItem("recentlyViewedData");
  localStorage.removeItem("recentlyViewedData");
  localStorage.removeItem("selectId");
  localStorage.removeItem("AppointmentReportRange");
  localStorage.removeItem("mdPathState");

  // remove active-products filter (iapf = inventory active product filter)
  localStorage.removeItem("iapfCategory");
  localStorage.removeItem("iapfStock");
  localStorage.removeItem("iapfTerm");
  localStorage.removeItem("iapfSortBy");
  localStorage.removeItem("iapfSortOrder");

  // remove inactive-products filter (iiapf = inventory in-active product filter)
  localStorage.removeItem("iiapfCategory");
  localStorage.removeItem("iiapfStock");
  localStorage.removeItem("iiapfTerm");
  localStorage.removeItem("iiapfSortBy");
  localStorage.removeItem("iiapfSortOrder");

  // remove discount-packages filter (idpf = inventory discount package filter)
  localStorage.removeItem("idpfSortBy");
  localStorage.removeItem("idpfSortOrder");
  localStorage.removeItem("idpfStatus");
  localStorage.removeItem("idpfType");
  localStorage.removeItem("idpfTerm");

  // remove recenlty-deleted filter (rdf = recently deleted filter)
  localStorage.removeItem("rdfDateRange");

  localStorage.removeItem("localInvoicesArray");
  localStorage.removeItem("localSelectedEmployeeIdList");
  localStorage.removeItem("localSelectedLocationIdList");

  // remove default country code
  localStorage.removeItem("cCode");

  // remove business insights report variables
  localStorage.removeItem("reportOptionFilter");
  localStorage.removeItem("selectionRange-businessInsight");
  localStorage.removeItem("shareReportOptionFilter");

  //remove useractivity type
  localStorage.removeItem("objName");

  //remove forcefully cancel clock-out modal/popup
  localStorage.removeItem("isLoggedClockCancelled");
  localStorage.removeItem("clockPromptCancelTime");
  localStorage.removeItem("isClockPromptLoginDisplay");

  //remove inbox clinic filter
  localStorage.removeItem("inboxClinicId");
  localStorage.removeItem("inboxClinicName");

  //remove dispute tab type
  localStorage.removeItem("disputeTabType");

  Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
    localStorage.removeItem(key);
  });

  return;
}

export function checkIfPermissionAllowed(givenPrivilege) {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let allPermission = [];

  if (userData && givenPrivilege) {
    allPermission = userData.permissions;

    if (allPermission) {
      if (allPermission.indexOf(givenPrivilege) > -1) {
        return true;
      }
    }
  }

  return false;
}

/*
  style = 'currency', 'decimal' or 'percent'
*/
export function numberFormat(
  amount,
  style,
  minimumFractionDigits,
  maximumFractionDigits,
  allowNegativeAmount,
) {
  let locale = "en";
  let currency = getCurrencyLabel();

  style = style || "decimal";
  maximumFractionDigits = maximumFractionDigits || 2;

  var options = {
    style: style,
    currency: currency,
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
  };

  var formatter = new Intl.NumberFormat(locale, options);

  if (amount && amount !== null && amount !== "" && amount > 0) {
    return formatter.format(amount);
  }

  if (allowNegativeAmount && amount && amount !== null && amount !== "") {
    return formatter.format(amount);
  }

  return formatter.format(0);
}

/*
time12h = '02:55 AM', '05:30:00 am'
*/
export function convertTime12to24(time12h, isSeconds = false) {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier.toLowerCase() === "pm") {
    hours = parseInt(hours, 10) + 12;
  }

  if (isSeconds) {
    return hours + ":" + minutes + ":00";
  } else {
    return hours + ":" + minutes;
  }
}

/*
time = '14:45:55', '19:50'
*/
export function convertTime24to12(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[0] = +time[0] % 12 || 12;
    if (time[0] < 10) {
      time[0] = "0" + time[0];
    }
  }

  delete time[time.length - 1];

  return time.join("");
}

/*
time = '14:45:55', '19:50'
*/
export function getAmPm(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    return +time[0] < 12 ? "AM" : "PM";
  }

  return null;
}

/*
convert test to Test
*/
export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
}

export function displayName(obj) {
  let returningName = "";

  if (obj && obj.firstname) {
    returningName += capitalizeFirstLetter(obj.firstname);
  }

  if (returningName !== "") {
    returningName += " ";
  }

  if (obj && obj.middlename) {
    returningName += capitalizeFirstLetter(obj.middlename);
    returningName += " ";
  }

  if (obj && obj.lastname) {
    returningName += capitalizeFirstLetter(obj.lastname);
  }

  return returningName;
}

function setCurrencyLabel(currencyLabel) {
  currencyLabel = currencyLabel || "usd";
  localStorage.setItem("currencyLabel", currencyLabel);
  return true;
}

export function getCurrencyLabel() {
  let currencyLabel = localStorage.getItem("currencyLabel");

  if (currencyLabel) {
    return currencyLabel;
  } else {
    return "usd";
  }
}

export function formatBytes(a, b) {
  if (0 == a) {
    return "0 Bytes";
  }

  var c = 1024,
    d = b || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));

  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
}

// date should be string
export function showFormattedDate(
  date,
  showTime,
  specifiedFormat,
  incomingDateFormat,
) {
  showTime = showTime || false;
  specifiedFormat = specifiedFormat || "";

  const dateFormat = getDateFormat();
  const timeFormat = getTimeFormat();

  let userFormat = getDateFormat();
  let returnFormat = getDateFormat();
  let defDate = "";

  if (
    date === "0000-00-00 00:00:00" ||
    date === "0000-00-00" ||
    date === "0000/00/00 00:00:00" ||
    date === "0000/00/00"
  ) {
    return "";
  }

  if (date === "" || date === undefined || date === null) {
    if (specifiedFormat != "") {
      returnFormat = specifiedFormat;
    }

    defDate = moment(moment(), userFormat).format(returnFormat);
    defDate = defDate.split(" ")[0];
    date = defDate;

    return date;
  }

  if (date && dateFormat && timeFormat) {
    if (showTime) {
      userFormat = dateFormat + " " + timeFormat;
      returnFormat = userFormat;
    } else {
      userFormat = dateFormat;
      returnFormat = dateFormat;
    }

    if (specifiedFormat != "") {
      returnFormat = specifiedFormat;
    }

    if (date.indexOf("/") > -1 || date.indexOf("-") > -1) {
      let dateTimeArray = date.split(" ");
      let dateArray = dateTimeArray[0];

      if (dateArray.indexOf("/") !== -1) {
        dateArray = dateArray.split("/");
      } else {
        dateArray = dateArray.split("-");
      }

      if (dateFormat == "DD/MM/YYYY") {
        return moment(date, incomingDateFormat).format(returnFormat);
      }

      return moment(date, incomingDateFormat).format(returnFormat);
    } else {
      return moment.unix(date, incomingDateFormat).format(returnFormat);
    }
  }
}

// date format e.g March 01, 2020
export function showMonthFormattedDate(date, showTime) {
  showTime = showTime || false;

  let format = "MMMM DD, YYYY";
  let dateFormat = "YYYY/MM/DD";
  let timeFormat = "hh:mm A";

  if (date == "0000-00-00 00:00:00" || date == "0000-00-00") {
    return "";
  }

  if (date && dateFormat && timeFormat) {
    if (showTime) {
      return moment(date).format(format) + " " + timeFormat;
    } else {
      return moment(date).format(format);
    }
  }
}

export function isNumber(n) {
  if (Math.sign(n) === -1) {
    return false;
  }

  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function setConfigData(confgData) {
  if (confgData.pos_data !== undefined) {
    if (
      confgData.pos_data.is_pos_enabled !== undefined &&
      confgData.pos_data.is_pos_enabled !== null &&
      confgData.pos_data.is_pos_enabled >= 0
    ) {
      setIsPosEnabled(confgData.pos_data.is_pos_enabled);
    }

    if (
      confgData.pos_data.stripe_connection_fee !== undefined &&
      confgData.pos_data.stripe_connection_fee !== null &&
      confgData.pos_data.stripe_connection_fee >= 0
    ) {
      setConnectionFee(confgData.pos_data.stripe_connection_fee);
    }

    if (
      confgData.pos_data.stripe_connection !== undefined &&
      confgData.pos_data.stripe_connection !== null &&
      confgData.pos_data.stripe_connection !== ""
    ) {
      setStripeConnection(confgData.pos_data.stripe_connection);
    }

    if (
      confgData.pos_data.currency_symbol !== undefined &&
      confgData.pos_data.currency_symbol !== null &&
      confgData.pos_data.currency_symbol !== ""
    ) {
      setCurrencySymbol(confgData.pos_data.currency_symbol);
    }

    if (
      confgData.pos_data.currency_code !== undefined &&
      confgData.pos_data.currency_code !== null &&
      confgData.pos_data.currency_code !== ""
    ) {
      setCurrencyLabel(confgData.pos_data.currency_code);
    }

    if (
      confgData.pos_data.subscription_plan !== undefined &&
      confgData.pos_data.subscription_plan !== null &&
      confgData.pos_data.subscription_plan !== ""
    ) {
      setSubscriptionPlan(confgData.pos_data.subscription_plan);
    }

    if (
      confgData.pos_data.is_membership_enable !== undefined &&
      confgData.pos_data.is_membership_enable !== null &&
      confgData.pos_data.is_membership_enable >= 0
    ) {
      setIsMembershipEnabled(confgData.pos_data.is_membership_enable);
    }

    if (
      confgData.pos_data.membership_tier !== undefined &&
      confgData.pos_data.membership_tier !== null &&
      confgData.pos_data.membership_tier !== ""
    ) {
      setMembershipTier(confgData.pos_data.membership_tier);
    }

    if (
      confgData.pos_data.account_status !== undefined &&
      confgData.pos_data.account_status !== null &&
      confgData.pos_data.account_status !== ""
    ) {
      setAccountStatus(confgData.pos_data.account_status);
    }

    if (
      confgData.pos_data.account_type !== undefined &&
      confgData.pos_data.account_type !== null &&
      confgData.pos_data.account_type !== ""
    ) {
      setAccountType(confgData.pos_data.account_type);
    }

    if (
      confgData.pos_data.subscription_type !== undefined &&
      confgData.pos_data.subscription_type !== null &&
      confgData.pos_data.subscription_type !== ""
    ) {
      setSubscriptionType(confgData.pos_data.subscription_type);
    }

    if (
      confgData.pos_data.twilio_from_number !== undefined &&
      confgData.pos_data.twilio_from_number !== null &&
      confgData.pos_data.twilio_from_number !== ""
    ) {
      setTwilioFromNumber(confgData.pos_data.twilio_from_number);
    } else {
      setTwilioFromNumber("");
    }

    if (
      confgData.pos_data.time_clock_setting !== undefined &&
      confgData.pos_data.time_clock_setting !== null &&
      confgData.pos_data.time_clock_setting !== ""
    ) {
      setTimeClockSetting(confgData.pos_data.time_clock_setting);
    } else {
      setTimeClockSetting(0);
    }
  }

  if (
    confgData.time_format !== undefined &&
    confgData.time_format !== "" &&
    confgData.time_format !== null
  ) {
    setTimeFormat(confgData.time_format);
  }

  if (
    confgData.date_format !== undefined &&
    confgData.date_format !== "" &&
    confgData.date_format !== null
  ) {
    setDateFormat(confgData.date_format);
  }

  if (
    confgData.twilio_config !== undefined &&
    confgData.twilio_config !== "" &&
    confgData.twilio_config !== null
  ) {
    setTwilioConfig(confgData.twilio_config);
  }

  if (
    confgData.script_sure !== undefined &&
    confgData.script_sure !== "" &&
    confgData.script_sure !== null
  ) {
    setEprescriptionConfig(JSON.stringify(confgData.script_sure));
  }
}

function setTimeClockSetting(timeClockSetting) {
  timeClockSetting = timeClockSetting || "";
  localStorage.setItem("timeClockSetting", timeClockSetting);

  return true;
}

export function getTimeClockSetting() {
  let timeClockSetting = localStorage.getItem("timeClockSetting");

  if (timeClockSetting) {
    return true;
  } else {
    return false;
  }
}

function setTwilioFromNumber(twilioFromNumber) {
  twilioFromNumber = twilioFromNumber || "";
  localStorage.setItem("twilioFromNumber", twilioFromNumber);
  return true;
}

export function getTwilioFromNumber() {
  let twilioFromNumber = localStorage.getItem("twilioFromNumber");

  if (twilioFromNumber) {
    return twilioFromNumber;
  } else {
    return "";
  }
}

function setSubscriptionType(subscriptionType) {
  subscriptionType = subscriptionType || "monthly";
  localStorage.setItem("subscriptionType", subscriptionType);
  return true;
}

function setAccountType(accountType) {
  accountType = accountType || "trial";
  localStorage.setItem("accountType", accountType);
  return true;
}

function getAccountType() {
  let accountType = localStorage.getItem("accountType");

  if (accountType) {
    return accountType;
  } else {
    return "trial";
  }
}

function setAccountStatus(accountStatus) {
  accountStatus = accountStatus || "active";
  localStorage.setItem("accountStatus", accountStatus);
  return true;
}

function setSubscriptionPlan(subscriptionPlan) {
  subscriptionPlan = subscriptionPlan || "essential";
  localStorage.setItem("subscriptionPlan", subscriptionPlan);
  return true;
}

export function getSubscriptionPlan() {
  let subscriptionPlan = localStorage.getItem("subscriptionPlan");

  if (subscriptionPlan) {
    return subscriptionPlan;
  } else {
    return "essential";
  }
}

function setIsMembershipEnabled(isMembershipEnabled) {
  isMembershipEnabled = isMembershipEnabled || false;
  localStorage.setItem("isMembershipEnabled", isMembershipEnabled);
  return true;
}

function setMembershipTier(membershipTier) {
  membershipTier = membershipTier || "single";
  localStorage.setItem("membershipTier", membershipTier);
  return true;
}

function setCurrencySymbol(currencySymbol) {
  currencySymbol = currencySymbol || "$";
  localStorage.setItem("currencySymbol", currencySymbol);
  return true;
}

export function getCurrencySymbol() {
  let currencySymbol = localStorage.getItem("currencySymbol");

  if (currencySymbol) {
    return currencySymbol;
  } else {
    return "$";
  }
}

function setStripeConnection(stripeConnection) {
  stripeConnection = stripeConnection || "";
  localStorage.setItem("stripeConnection", stripeConnection);
  return true;
}

function setIsPosEnabled(isPosEnabled) {
  isPosEnabled = isPosEnabled || false;
  localStorage.setItem("isPosEnabled", isPosEnabled);
  return true;
}

function setConnectionFee(fee) {
  localStorage.setItem("connectionFee", fee);
  return true;
}

export function getIsPosEnabled() {
  let isPosEnabled = localStorage.getItem("isPosEnabled");

  if (
    typeof isPosEnabled !== "undefined" &&
    typeof isPosEnabled !== undefined
  ) {
    if (
      (typeof isPosEnabled === "string" && isPosEnabled === "true") ||
      (typeof isPosEnabled === "boolean" && isPosEnabled === true)
    ) {
      return true;
    }
  }

  return false;
}

export function setTwilioConfig(isTwilioSetupEnabled) {
  isTwilioSetupEnabled = isTwilioSetupEnabled || false;
  localStorage.setItem("twilioSetup", isTwilioSetupEnabled);

  return true;
}

function setEprescriptionConfig(isEprescriptionSetup) {
  isEprescriptionSetup = isEprescriptionSetup || false;
  localStorage.setItem("ePrescriptionSetup", isEprescriptionSetup);

  return true;
}

export function getTwilioConfig() {
  let isTwilioSetupEnabled = localStorage.getItem("twilioSetup");

  if (
    typeof isTwilioSetupEnabled !== "undefined" &&
    typeof isTwilioSetupEnabled !== undefined
  ) {
    if (
      (typeof isTwilioSetupEnabled === "string" &&
        isTwilioSetupEnabled === "1") ||
      (typeof isTwilioSetupEnabled === "boolean" &&
        isTwilioSetupEnabled === true)
    ) {
      return true;
    }
  }

  return false;
}

function setTimeFormat(timeFormat) {
  switch (timeFormat) {
    case "h:i a":
      timeFormat = "hh:mm A";
      break;
    case "H:i":
      timeFormat = "HH:mm";
      break;
    default:
      timeFormat = "hh:mm A";
      break;
  }

  localStorage.setItem("timeFormat", timeFormat);

  return true;
}

export function formatTime(time) {
  let timeFormat = getTimeFormat();
  return moment(time, "HH:mm:ss").format(timeFormat);
}

export function getTimeFormat() {
  let timeFormat = localStorage.getItem("timeFormat");

  if (timeFormat) {
    return timeFormat;
  } else {
    return "hh:mm A";
  }
}

function setDateFormat(dateFormat) {
  switch (dateFormat) {
    case "Y/m/d":
      dateFormat = "YYYY/MM/DD";
      break;
    case "d/m/Y":
      dateFormat = "DD/MM/YYYY";
      break;
    case "m/d/Y":
      dateFormat = "MM/DD/YYYY";
      break;
    default:
      dateFormat = "YYYY/MM/DD";
      break;
  }

  localStorage.setItem("dateFormat", dateFormat);

  return true;
}

export function getDateFormat() {
  let dateFormat = localStorage.getItem("dateFormat");

  if (dateFormat) {
    return dateFormat;
  } else {
    return "YYYY/MM/DD";
  }
}

export function handleScheduleMasking(
  value,
  selection,
  cursorPosition,
  userInput,
) {
  if (value.endsWith(":") && userInput !== ":") {
    if (cursorPosition === value.length) {
      cursorPosition--;
      selection = { start: cursorPosition, end: cursorPosition };
    }
    value = value.slice(0, -1);
  }

  if (cursorPosition == 2) {
    if (value > 12) {
      value = 12;
    }
  }

  var tmpArr = value.split(":");

  if (parseInt(tmpArr[0]) > 12) {
    if (cursorPosition == 1) {
      tmpArr[0] = "0" + tmpArr[0].substring(0, 1);
    } else {
      tmpArr[0] = "0" + +tmpArr[0].substring(1);
    }
    selection = { start: 3, end: 3 };
  }

  if (parseInt(tmpArr[0]) <= 0) {
    tmpArr[0] = "00";
  }

  if (parseInt(tmpArr[1]) > 59) {
    tmpArr[1] = "0" + tmpArr[1].substring(0, 1);
    selection = { start: 5, end: 5 };
  }

  if (tmpArr[0] == undefined || tmpArr[0] == null) {
    tmpArr[0] = "12";
  }
  if (tmpArr[1] == undefined || tmpArr[1] == null) {
    tmpArr[1] = "00";
  }
  if (value.length < 5) {
    value = value.padEnd(5, "0");
  }

  tmpArr[0] = tmpArr[0].toString();
  tmpArr[0] = tmpArr[0].padEnd(2, "0");
  tmpArr[1] = tmpArr[1].toString();
  tmpArr[1] = tmpArr[1].padEnd(2, "0");
  value = tmpArr.join(":");
  return {
    value,
    selection,
  };
}

export function setRedirectTo(redirectTo) {
  localStorage.setItem("redirectTo", redirectTo);
  return true;
}

export function getRedirectTo() {
  let redirectTo = localStorage.getItem("redirectTo");

  if (redirectTo) {
    return "/" + redirectTo;
  } else {
    return "";
  }
}

export function toggleBodyScroll(isScroll) {
  if (isScroll !== undefined && isScroll === true) {
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.top = "0";
    document.body.style.bottom = "0";
    document.body.style.left = "0";
    document.body.style.right = "0";
  } else {
    document.body.style.overflow = "";
    document.body.style.position = "inherit";
  }
}

export function positionFooterCorrectly() {
  let footer = document.getElementById("protected-footer-fixed");

  if (footer != null && footer != undefined) {
    setTimeout(function () {
      let rootHeight = document.getElementById("root").clientHeight;
      let scrollHeight = document.getElementById("root").scrollHeight;
      let clHeight = "";

      if (document.getElementById("protected-footer-fixed")) {
        clHeight = document.getElementById(
          "protected-footer-fixed",
        ).clientHeight;

        let footerHeight = clHeight;

        if (rootHeight + footerHeight > window.innerHeight) {
          footer.classList.remove("footer-fixed");
        } else {
          footer.classList.add("footer-fixed");

          if (
            window.location.pathname &&
            (window.location.pathname.startsWith("/clients/after-photos") ||
              window.location.pathname.startsWith(
                "/clients/treatment-markings",
              ))
          ) {
            if (scrollHeight < 800) {
              footer.classList.add("footer-fixed");
            } else {
              footer.classList.remove("footer-fixed");
            }
          }
        }
      }
    }, 700);
  }
}

export function isFormSubmit(interval) {
  interval = interval || 1;

  let isFormSubmit = true;
  let onSubmitTime = localStorage.getItem("onSubmitTime");

  if (onSubmitTime) {
    let currentTime = moment();
    if (currentTime.diff(moment.unix(onSubmitTime), "seconds") <= interval) {
      isFormSubmit = false;
    } else {
      localStorage.setItem("onSubmitTime", moment().format("X"));
    }
  } else {
    localStorage.setItem("onSubmitTime", moment().format("X"));
  }

  return isFormSubmit;
}

export function isPositiveNumber(
  value,
  minimumNumber,
  maximumNumber,
  afterDecimal,
) {
  minimumNumber = minimumNumber || 0;
  maximumNumber = maximumNumber || 9999999999;
  afterDecimal = afterDecimal || 3;
  value = String(value);

  let isValid = false;
  const regex = /^[+]?([.]\d+|\d+[.]?\d*)$/;

  if (regex.test(value)) {
    let decimalDigit = "0";
    if (value.indexOf(".") > -1) {
      decimalDigit = value.split(".").pop();
    }
    if (
      validator.isFloat(value, { min: minimumNumber, max: maximumNumber }) &&
      decimalDigit.length <= afterDecimal
    ) {
      isValid = true;
    }
  }

  return isValid;
}

export function isInteger(value, minimumNumber, maximumNumber) {
  minimumNumber = minimumNumber || 0;
  maximumNumber = maximumNumber || 9999999999;
  value = String(value);

  let isValid = false;
  const regex = /^[+]?([.]\d+|\d+[.]?\d*)$/;

  if (regex.test(value)) {
    if (
      validator.isInt(value, {
        min: minimumNumber,
        max: maximumNumber,
        allow_leading_zeroes: true,
      })
    ) {
      isValid = true;
    }
  }

  return isValid;
}

export function dateFormatPicker() {
  let format = "yyyy-MM-dd";

  if (getDateFormat()) {
    switch (getDateFormat()) {
      case "YYYY/MM/DD":
        format = "yyyy-MM-dd";
        break;
      case "DD/MM/YYYY":
        format = "dd/MM/yyyy";
        break;
      case "MM/DD/YYYY":
        format = "MM/dd/yyyy";
        break;
      default:
        format = "yyyy-MM-dd";
        break;
    }
  }

  return format;
}

export function getIsDashboardEnabled() {
  let userData = JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  if (userData && userData.user) {
    if (
      userData.user.is_dashboard_enabled === "true" ||
      userData.user.is_dashboard_enabled === true
    ) {
      return true;
    }
  }

  return false;
}

export function isInt(n) {
  n = parseFloat(n);
  return parseInt(n) === n;
}

export function autoScrolling(autoScrolling) {
  if (autoScrolling !== null && autoScrolling !== undefined) {
    localStorage.setItem("autoScrolling", autoScrolling);

    return true;
  } else {
    autoScrolling = localStorage.getItem("autoScrolling");

    if (autoScrolling == true || autoScrolling == "true") {
      return true;
    } else {
      return false;
    }
  }
}

export function insightDateArray() {
  let dateArray = [
    { label: "MTD", value: "mtd" },
    { label: "QTD", value: "qtd" },
    { label: "YTD", value: "ytd" },
    { label: "Custom period", value: "custom" },
  ];

  return dateArray;
}

export function revenuePerHourDateArray() {
  const dateArray = [
    { label: "Current Month", value: "current" },
    { label: "Last Month", value: "last" },
    { label: "Custom period", value: "custom" },
  ];
  return dateArray;
}

export function monthOfArray() {
  return [
    { slug: "Jan", label: "January", value: "1" },
    { slug: "Feb", label: "February", value: "2" },
    { slug: "Mar", label: "March", value: "3" },
    { slug: "Apr", label: "April", value: "4" },
    { slug: "May", label: "May", value: "5" },
    { slug: "Jun", label: "June", value: "6" },
    { slug: "Jul", label: "July", value: "7" },
    { slug: "Aug", label: "August", value: "8" },
    { slug: "Sep", label: "September", value: "9" },
    { slug: "Oct", label: "October", value: "10" },
    { slug: "Nov", label: "November", value: "11" },
    { slug: "Dec", label: "December", value: "12" },
  ];
}

export function fromToDateRange(type = "mtd") {
  const date = moment();
  switch (type) {
    case "ytd":
      type = "year";
      break;
    case "qtd":
      type = "quarter";
      break;
    case "current":
      return {
        from_date: moment(date).startOf("month").format("YYYY-MM-DD"),
        to_date: moment(date).endOf("month").format("YYYY-MM-DD"),
      };
    case "last":
      return {
        from_date: moment(date)
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        to_date: moment(date)
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
    default:
      type = "month";
      break;
  }

  return {
    from_date: moment(date).startOf(type).format("YYYY-MM-DD"),
    to_date: moment(date).endOf(type).format("YYYY-MM-DD"),
  };
}

export function getNumberWithOrdinal(i) {
  var j = i % 10,
    k = i % 100;

  if (j == 1 && k != 11) {
    return i + "st";
  }

  if (j == 2 && k != 12) {
    return i + "nd";
  }

  if (j == 3 && k != 13) {
    return i + "rd";
  }

  return i + "th";
}

export function positionFooterInstantly() {
  let footer = document.getElementById("protected-footer-fixed");

  if (footer != null && footer != undefined) {
    let rootHeight = document.getElementById("root").clientHeight;
    let clHeight = "";

    if (document.getElementById("protected-footer-fixed")) {
      clHeight = document.getElementById("protected-footer-fixed").clientHeight;
      let footerHeight = clHeight;

      if (rootHeight + footerHeight > window.innerHeight) {
        footer.classList.remove("footer-fixed");
      } else {
        footer.classList.add("footer-fixed");
      }
    }
  }
}

export function getIsAcceleratorPlan() {
  return getSubscriptionPlan() === "practice_accelerator";
}

export function getIsTouchMDPracticeBooking() {
  const userData = JSON.parse(getUser());
  const accountPreference = userData?.account?.account_preference || {};
  const accountSubscription = userData?.account?.account_subscription || {};

  return (
    (accountPreference.is_touch_md || 0) === 1 &&
    (accountSubscription.plan_code || "") === "practice_booking"
  );
}

export const getIsEvolusEnabled = () => {
  const userData = localStorage.getItem("userData");
  return JSON.parse(userData)?.account?.is_evolus_enabled;
};

export function upgradeAcceleratorUrl() {
  return "/settings/manage-subscription-upgrade";
}

export function getIsAccountTypePaid() {
  if (getAccountType() === "paid") {
    return true;
  } else {
    return false;
  }
}

export function formatInboxTime(time) {
  let timeFormat = getTimeFormat();
  return moment(time).format(timeFormat);
}

export function getDegreeDesignation() {
  return [
    "DO/MD",
    "DDS",
    "NP",
    "RN",
    "PA",
    "LPN",
    "Aesthetician",
    "Bachelors",
    "Masters",
    "Associates",
    "High School",
  ];
}

export const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export function roundOffNum(num, fixedTo) {
  fixedTo = fixedTo || 2;

  return parseFloat(Math.round(num * 100) / 100).toFixed(fixedTo);
}

export function weekOfArray() {
  return [
    { slug: "sunday", label: "Sunday", value: 1 },
    { slug: "monday", label: "Monday", value: 2 },
    { slug: "tuesday", label: "Tuesday", value: 3 },
    { slug: "wednesday", label: "Wednesday", value: 4 },
    { slug: "thursday", label: "Thursday", value: 5 },
    { slug: "friday", label: "Friday", value: 6 },
    { slug: "saturday", label: "Saturday", value: 7 },
  ];
}

export function decimalPlaces(num) {
  var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

  if (!match) {
    return 0;
  }

  return Math.max(
    0,
    (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0),
  );
}

export function showWithoutRounding(num) {
  if (!num) {
    return "0";
  }

  var with1Decimals = num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];

  return with1Decimals;
}

export function getClearentToken() {
  try {
    return new Promise((resolve, reject) => {
      window.ClearentSDK.getPaymentToken()
        .then(
          (res) => {
            let token = res.payload["mobile-jwt"].jwt;
            resolve(token);
          },
          (err) => {
            reject(err);
          },
        )
        .catch((error) => {
          reject(error);
        });
    })
      .then((res) => {
        return res;
      })
      .catch(() => {
        return false;
      });
  } catch (error) {
    return false;
  }
}

export function initializeClearentSdk(key, id) {
  if (window.ClearentSDK) {
    window.ClearentSDK.reset();
  }

  let clearentConfig = {
    baseUrl: process.env.REACT_APP_CLEARENT_URL,
    pk: key,
    styles:
      ".has-error{border:1px solid #dddddd!important;border-bottom:1px solid #e52817 !important;}#card-group.has-error #card, #card-group.has-error #card-icon, #card-group.has-error #card-image{color: #7f8284;border-color:#ddd} .clearent-payment-errors {color:#e52817}#exp-date-group.has-error #date-icon, #exp-date-group.has-error #exp-date, #exp-date-group.has-error span{color: #7f8284;border-color:#ddd} #cvc-group.has-error #csc, #cvc-group.has-error #cvc-icon, #cvc-group.has-error span {color: #7f8284;border-color:#ddd;text-align:center;}#card-form .input-group span, #card-form .input-group input{border:none;text-align:left!important;}#card-form .input-group{border:1px solid #dddddd;}#card-form .input-group span{width:50px;}#exp-date-group, #cvc-group{border-top:none!important;}",
    enableReader: true,
    deviceType: "IDTECH",
  };

  if (id) {
    clearentConfig.paymentFormId = id;
  }

  if (window.ClearentSDK) {
    window.ClearentSDK.init(clearentConfig);
  }
}

export function resolveServerSideMessage(
  preferredModule,
  response,
  fallbackMessage,
) {
  if (response?.data?.custom_message !== undefined) {
    return response.data.custom_message;
  }

  const key = response.message;
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (
    languageData[preferredModule] !== undefined &&
    languageData[preferredModule][key] !== undefined
  ) {
    return languageData[preferredModule][key];
  }

  if (languageData.global[key] !== undefined) {
    return languageData.global[key];
  }

  return fallbackMessage;
}

export const toggleServicesPerformed = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permittedAccounts = [1300, 9374, 16997];

  return permittedAccounts.includes(userData?.account?.id);
};

export const featureToggleAccounts = [1300, 9374, 1650, 16997];
