import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import classes from "./ReportModalFilter.module.scss";
import { Button } from "../../../../shared/Button/Button";

export function ReportModalFilter({
  isOpen,
  onClose,
  onSubmit,
  isSubmitDisabled,
  isFilterTouched,
  onReset,
  children,
}) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Title>{tCommon("label.filter")}</Modal.Title>}
      contentClassName={classes.root}
      shouldCloseOnOverlayClick={false}
      footer={
        <div className={classes.footer}>
          <Button onClick={onSubmit} isDisabled={isSubmitDisabled}>
            {tCommon("label.apply")}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {tCommon("label.cancel")}
          </Button>
          {isFilterTouched && (
            <Button variant="outlined" onClick={onReset}>
              {tCommon("label.reset")}
            </Button>
          )}
        </div>
      }
    >
      {children}
    </Modal>
  );
}

ReportModalFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  isFilterTouched: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
};
