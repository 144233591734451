import React, { useMemo } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Table } from "../../../../../shared/Table/Table";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import Header from "../../../components/Header";
import { useBdAspire } from "./hooks/useBdAspire";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "bdAspireReport.info.title",
  rows: ["bdAspireReport.info.note"],
};

const AllBdAspire = ({ history, period, selectedClinic, apiPeriod }) => {
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const { data, isLoading } = useBdAspire(requestParams);

  const baseUrl = history.location.pathname;

  const tableDataEmpty = !isLoading && !data?.reportData?.length;

  const tableData = data?.reportData.map(
    (data) => ({
      clinicId: data.invoice_clinic_id,
      aspireCoupons: data.aspire_coupons,
      bdCoupons: data.bd_coupons,
      clinicName: data.client_name,
      evolusCoupons: data.evolus_coupons,
      xperienceCoupons: data.xperience_coupons,
      viewDetails: (
        <Link
          className={classes.detailsButton}
          to={`${baseUrl}/${data.invoice_clinic_id}`}
          onClick={() =>
            selectedClinic.update({
              label: data.client_name,
              value: data.invoice_clinic_id,
            })
          }
        >
          View Details
        </Link>
      ),
    }),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Allē + Aspire + Evolus + Xperience"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getBdAspireReport()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Clinic Name", accessor: "clinicName" },
              { data: "Allē", accessor: "bdCoupons" },
              { data: "Aspire", accessor: "aspireCoupons" },
              { data: "Evolus Rewards", accessor: "evolusCoupons" },
              { data: "Xperience", accessor: "xperienceCoupons" },
              { data: "View Details", accessor: "viewDetails" },
            ]}
            data={tableData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(AllBdAspire);
