import { REASON_TYPES } from "./CancelPlanModal.consts";

export const getReasonMessage = (formValues) => {
  switch (formValues.reasonType) {
    case REASON_TYPES.missingKeyFeature:
      return formValues.keyFeatureMessage;
    case REASON_TYPES.other:
      return formValues.otherMessage;
    default:
      return null;
  }
};
