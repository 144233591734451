import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "../sass/Title.module.scss";

export function Title({ children, className }) {
  return <h3 className={cx(classes.root, className)}>{children}</h3>;
}

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: undefined,
};
