import * as yup from "yup";
import { MIN_PAY_AMOUNT } from "./SalesCheckoutInvoice.consts";
import { tCommon, tSales } from "../../../i18n/useAppTranslation";
import { formatCurrency } from "../../../utilities/general";

export const composeAmountSchema = ({ amountToPay, currency }, cashPayment) => {
  const minPayAmount = cashPayment ? 0 : MIN_PAY_AMOUNT;
  return yup
    .number()
    .min(
      minPayAmount,
      tSales("checkoutInvoice.formError.amountMin", {
        min: formatCurrency(MIN_PAY_AMOUNT, currency),
      }),
    )
    .max(
      amountToPay,
      tSales("checkoutInvoice.formError.amountMax", {
        max: formatCurrency(amountToPay, currency),
      }),
    )
    .required(tSales("checkoutInvoice.formError.amountRequired"));
};

export const composeEmailSchema = (
  { isRequired } = {
    isRequired: true,
  },
) => {
  const schema = yup.string().email(tCommon("formError.emailInvalid"));
  return isRequired
    ? schema.required(tCommon("formError.emailRequired"))
    : schema;
};

export const composeZipCodeSchema = (
  { isRequired } = {
    isRequired: true,
  },
) => {
  const schema = yup.string();
  return isRequired
    ? schema.required(tCommon("formError.zipCodeRequired"))
    : schema;
};
