import {
  PROCEDURE_TYPE,
  useProcedureCreateUpdateMutation,
} from "../../../../../../api/procedure/useProcedureCreateUpdateMutation";
import { history } from "../../../../../../history";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { getCosmeticPathParams } from "../CosmeticCreateEdit.utils";
import { getLastUrlEntry } from "../../../../../../utilities/general";
import { ROUTES } from "../../../../../../consts/routes";
import { PATIENT_QUESTIONNAIRE_ENTITY_TYPES } from "../../../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery";
import { extractApiError } from "../../../../../../utilities/api";
import { PATIENT_CONSENTS_ENTITY_TYPES } from "../../../../../../api/consents/patientConsents/usePatientConsentsQuery";

export function useSubmit() {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();
  const { clientId, procedureId } = getCosmeticPathParams();
  const createMutation = useProcedureCreateUpdateMutation();

  const initiate = async ({
    form,
    redirectToFillQuestionnaires,
    redirectToSignConsents,
  }) => {
    try {
      const result = await createMutation.mutateAsync({
        id: procedureId,
        patientId: clientId,
        procedureType: PROCEDURE_TYPE.cosmetic,
        appointmentId: form.appointmentId,
        serviceId: form.serviceId,
        providerId: form.providerId,
        clinicId: form.clinicId,
        procedureArea: form.procedureArea,
        procedureName: form.procedureName,
        procedureDate: form.procedureDate,
        images: {
          front: getLastUrlEntry(form.frontImage),
          image45: getLastUrlEntry(form.image45),
          image45Left: getLastUrlEntry(form.image45Left),
          image45Right: getLastUrlEntry(form.image45Right),
          image90: getLastUrlEntry(form.image90),
          image90Left: getLastUrlEntry(form.image90Left),
          image90Right: getLastUrlEntry(form.image90Right),
          back: getLastUrlEntry(form.backImage),
          back45Left: getLastUrlEntry(form.back45LeftImage),
          back45Right: getLastUrlEntry(form.back45RightImage),
        },
        afterImages: {
          front: getLastUrlEntry(form.afterFrontImage),
          image45Left: getLastUrlEntry(form.afterImage45Left),
          image45Right: getLastUrlEntry(form.afterImage45Right),
          image90Left: getLastUrlEntry(form.afterImage90Left),
          image90Right: getLastUrlEntry(form.afterImage90Right),
          back: getLastUrlEntry(form.afterBackImage),
          back45Left: getLastUrlEntry(form.afterBack45LeftImage),
          back45Right: getLastUrlEntry(form.afterBack45RightImage),
        },
        questionnaireIds: form.questionnaireIds,
        consentIds: form.consentIds,
        type: form.type?.value,
        patientImage: form.patientImage,
      });

      uiNotification.success(tCommon("success.createProcedure"));

      const redirectProcedureId = procedureId || result.data?.data?.id;

      if (redirectToFillQuestionnaires && redirectProcedureId) {
        history.push(
          ROUTES.patientQuestionnaires.manyByEntity({
            type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
            id: redirectProcedureId,
          }),
          {
            backToProfile: true,
          },
        );
      } else if (redirectToSignConsents && redirectProcedureId) {
        history.push(
          ROUTES.patientConsents.manyByEntity({
            type: PATIENT_CONSENTS_ENTITY_TYPES.procedure,
            id: redirectProcedureId,
          }),
          {
            backToProfile: true,
          },
        );
      } else {
        history.goBack();
      }
    } catch (e) {
      const error = extractApiError(e);
      uiNotification.error(
        tClients(
          `createEditCosmeticProcedure.apiError.${error}`,
          tCommon("error.createProcedure"),
        ),
      );
    }
  };

  return {
    initiate,
    isLoading: createMutation.isLoading,
  };
}
