import React from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../consts/general";
import { SortAdornment } from "../../../../../shared/SortAdornment/SortAdornment";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { Table } from "../../../../../shared/Table/Table";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { Checkbox } from "../../../../../shared/Checkbox/Checkbox";
import { DELETED_CUSTOMER_NOTES_SORT_FIELDS } from "../../../../../api/deletedNotes/useDeletedCustomerNotesQuery";
import styles from "./styles.module.scss";

/**
 * @param {object} param0
 *
 * @param {{
 *  value: Array<{ [key:string]: string | number | object }>;
 *  isLoading: boolean;
 *  isSoftLoading: boolean;
 *  hasMore: boolean;
 *  fetchMore: () => void;
 *  isNextFetching: boolean;
 * }} param0.data
 *
 * @param {{
 *  value: { direction: string; by: string },
 *  onChange: (f: string) => void;
 * }} param0.order
 *
 * @param {{
 *  isSelected: (id: number | string) => boolean;
 *  onToggleOne: (id: number | string) => void;
 * }} param0.rowSelector
 */
export const TableCustomer = ({ data, order, rowSelector }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const tableData = data.value.map((i) => ({
    key: i.id,

    patient: i.patient || tCommon("symbol.longDash"),

    createdBy: i.added_by,

    deletedDate: moment(i.deleted_at).format(
      user?.previewDateFormat || PREVIEW_DATE_FORMAT,
    ),

    checkbox: (
      <Checkbox
        size="small"
        isChecked={rowSelector.isSelected(i.id)}
        onChange={() => rowSelector.onToggleOne(i.id)}
      />
    ),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.onChange(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <Table
            bgColor="white"
            data={tableData}
            cols={[
              {
                data: "",
                accessor: "checkbox",
                headColClassName: styles.colCheckbox,
              },
              {
                data: renderSortWrap(
                  tSettings("deletedNotes.customer.table.patient"),
                  DELETED_CUSTOMER_NOTES_SORT_FIELDS.patient,
                ),
                accessor: "patient",
                headColClassName: styles.colMin,
              },
              {
                data: renderSortWrap(
                  tSettings("deletedNotes.customer.table.createdBy"),
                  DELETED_CUSTOMER_NOTES_SORT_FIELDS.createdBy,
                ),
                accessor: "createdBy",
                headColClassName: styles.colMin,
              },
              {
                data: renderSortWrap(
                  tSettings("deletedNotes.customer.table.deletedDate"),
                  DELETED_CUSTOMER_NOTES_SORT_FIELDS.deletedAt,
                ),
                accessor: "deletedDate",
                headColClassName: styles.colMin,
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </>
  );
};
