import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../_legacy/Containers/Settings/sidebar.js";
import { showFormattedDate } from "../../../Utils/services.js";
import {
  getPrescribers,
  resendInvitationEmail,
  inactivateUser,
  reactivateUser,
} from "../../../Actions/Settings/settingsActions.js";
import Loader from "../../Common/Loader.js";
import { Link } from "react-router-dom";

const Prescribers = (props) => {
  const {
    prescribers,
    getPrescribers,
    showLoadFromProps,
    resendInvitationEmail,
    inactivateUser,
    loadAgain,
    reactivateUser,
  } = props;
  const [showLoader, setShowLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [localPrescribers, setLocalPrescribers] = useState([]);
  const [status, setStatus] = useState("active");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [stateLoadAgain, setStateLoadAgain] = useState(false);
  const paginationRef = useRef();
  const ePrescriptionSetup = localStorage.getItem("ePrescriptionSetup")
    ? JSON.parse(localStorage.getItem("ePrescriptionSetup"))
    : null;

  if (!ePrescriptionSetup.is_enrolled) {
    props.history.push("/settings/setup-eprescription");
  }

  useEffect(() => {
    if (localPrescribers.length == 0) {
      setShowLoader(true);
      let formData = {
        params: {
          page: page,
          pagesize: perPage,
        },
      };
      getPrescribers(status, formData);
    }
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
        5
      ) {
        if (paginationRef && paginationRef.current) {
          paginationRef.current.click();
        }
      }
    };
  }, []);

  useEffect(() => {
    if (prescribers) {
      setShowLoader(false);
      if (prescribers && prescribers.data.length) {
        if (localPrescribers.length > 0) {
          setLocalPrescribers([...localPrescribers, ...prescribers.data]);
        } else {
          setLocalPrescribers(prescribers.data);
        }
        setNextPageUrl(prescribers.next_page_url);
        if (prescribers.next_page_url != null) {
          setPage(page + 1);
        }
      }
    }
  }, [prescribers]);

  useEffect(() => {
    if (showLoadFromProps) {
      setShowLoader(false);
    }
  }, [showLoadFromProps]);

  useEffect(() => {
    if (!stateLoadAgain && loadAgain) {
      setLocalPrescribers([]);
      setShowLoader(true);
      setStateLoadAgain(loadAgain);
      let pagingData = {
        params: {
          page: 1,
          pagesize: perPage,
        },
      };
      getPrescribers(status, pagingData);
    }
  }, [loadAgain]);

  const changeStatus = (newStatus) => {
    if (status !== newStatus) {
      setShowLoader(true);
      setLocalPrescribers([]);
      setStatus(newStatus);
      let formData = {
        params: {
          page: 1,
          pagesize: perPage,
        },
      };
      getPrescribers(newStatus, formData);
    }
  };

  const loadMore = () => {
    if (nextPageUrl != null && !showLoader) {
      setShowLoader(true);
      let formData = {
        params: {
          page: page,
          pagesize: perPage,
        },
      };
      getPrescribers(status, formData);
    }
  };

  const sendInvite = (id) => {
    setShowLoader(true);
    resendInvitationEmail(id, "prescriber");
  };

  const deactivateUser = (id) => {
    setStateLoadAgain(false);
    setShowLoader(true);
    let formData = { id, user_type: "prescriber" };
    inactivateUser(formData);
  };

  const handleActivateUser = (obj) => {
    let formData = {
      params: {
        page: page,
        pagesize: perPage,
      },
    };

    setShowLoader(true);
    reactivateUser({
      id: obj.id,
      user_type: obj.user_type,
    })
      .then(() => {
        setLocalPrescribers([]);
        getPrescribers("inactive", formData);
      })
      .finally(() => setShowLoader(false));
  };

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <Sidebar />
        <div className="business-setion memberWalletOuter">
          <div className="setting-setion m-b-10">
            <div className="membership-title">Prescribers</div>
          </div>
          <React.Fragment>
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <a
                  className={
                    status == "active" ? "newTabs" : "newTabs disabled-tab"
                  }
                  onClick={() => changeStatus("active")}
                >
                  Active
                </a>
                <a
                  className={
                    status == "expired" ? "newTabs" : "newTabs disabled-tab"
                  }
                  onClick={() => changeStatus("expired")}
                >
                  Expired
                </a>
                <a
                  className={
                    status == "inactive" ? "newTabs" : "newTabs disabled-tab"
                  }
                  onClick={() => changeStatus("inactive")}
                >
                  Inactive
                </a>
                <a
                  className={
                    status == "in_progress" ? "newTabs" : "newTabs disabled-tab"
                  }
                  onClick={() => changeStatus("in_progress")}
                >
                  In Progress
                </a>
                {ePrescriptionSetup.is_practice_active && (
                  <div className="pull-right">
                    <Link
                      to={"/settings/setup-eprescription/1"}
                      className="new-blue-btn m-r-20"
                    >
                      Add EPCS User
                    </Link>
                    <Link
                      to={"/settings/setup-eprescription/2"}
                      className="new-blue-btn m-l-0"
                    >
                      Add Non-EPCS User
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="setting-setion m-b-10">
              <div className="table-responsive">
                <table className="table-updated setting-table no-td-border no-hover">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-3 table-updated-th">
                        {"Prescriber Name"}
                      </th>
                      <th className="col-xs-2 table-updated-th">{"Email"}</th>
                      <th className="col-xs-2 table-updated-th">
                        {"Start Date"}
                      </th>
                      <th className="col-xs-2 table-updated-th">
                        {"Renewal Date"}
                      </th>
                      <th className="col-xs-2 table-updated-th">Type</th>
                      {status == "in_progress" && (
                        <th className="col-xs-3 table-updated-th"></th>
                      )}
                      {status !== "in_progress" && (
                        <th className="col-xs-3 table-updated-th">
                          {"Actions"}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {localPrescribers &&
                      localPrescribers.length > 0 &&
                      localPrescribers.map((obj, idx) => {
                        return (
                          <tr key={obj.id}>
                            <td className="col-xs-3 table-updated-td">
                              {obj.first_name + " " + obj.last_name}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {obj.email}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {obj.state.start_date !== ""
                                ? showFormattedDate(obj.state.start_date)
                                : "No subscription"}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {obj.state.end_date !== ""
                                ? showFormattedDate(obj.state.end_date)
                                : "No subscription"}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {obj.state.plan_type}
                              {status == "active" &&
                                obj.state.requires_renew && (
                                  <p className="font-weight-6 m-t-15">
                                    <i className="fa fa-circle-exclamation" />
                                    {obj.state.expires_in_days !== ""
                                      ? ` expires in: ${obj.state.expires_in_days} days`
                                      : " expires today"}
                                  </p>
                                )}
                            </td>
                            {status == "in_progress" && (
                              <td
                                onClick={() => sendInvite(obj.id)}
                                className="col-xs-3 table-updated-td"
                              >
                                <a
                                  class="easy-link "
                                  data-title="Resend Invitation Email"
                                >
                                  Resend Invitation Email
                                </a>
                              </td>
                            )}
                            {(status == "active" || status == "expired") && (
                              <td className="col-xs-3 table-updated-td">
                                <button
                                  onClick={() => {
                                    deactivateUser(obj.id);
                                  }}
                                  className="new-blue-btn text-no-wrap no-margin width-100"
                                  type="button"
                                >
                                  Inactivate Prescriber
                                </button>
                                {obj.state.requires_renew && (
                                  <Link
                                    to={`/settings/renew-eprescription/${
                                      obj.id
                                    }/${obj.state.plan_id || 1}`}
                                    className="new-blue-btn m-t-10 text-no-wrap no-margin width-100"
                                  >
                                    Renew Subscription
                                  </Link>
                                )}
                              </td>
                            )}
                            {status === "inactive" && (
                              <td className="col-xs-3 table-updated-td">
                                {!obj.state.requires_renew ? (
                                  <button
                                    onClick={() => {
                                      handleActivateUser(obj);
                                    }}
                                    className="new-blue-btn text-no-wrap no-margin width-100"
                                    type="button"
                                  >
                                    Activate
                                  </button>
                                ) : (
                                  <Link
                                    to={`/settings/renew-eprescription/${
                                      obj.id
                                    }/${obj.state.plan_id || 1}`}
                                    className="new-blue-btn text-no-wrap no-margin width-100"
                                  >
                                    Activate & Renew
                                  </Link>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}

                    {localPrescribers && localPrescribers.length == 0 && (
                      <tr>
                        <td
                          className={
                            status == "in_progress"
                              ? "col-xs-12 table-updated-th text-center"
                              : "col-xs-9 table-updated-th text-center"
                          }
                          colSpan={6}
                        >
                          <div className={"no-record"}>{"No Record Found"}</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        </div>
        <button
          className="btn hide-btn invisible"
          onClick={() => loadMore()}
          ref={paginationRef}
        >
          Load More
        </button>
      </div>
      <Loader showLoader={showLoader} isFullWidth={true} />
    </div>
  );
};

function mapStateToProps(state) {
  const returnState = {
    loadAgain: false,
  };
  if (state.SettingReducer.action === "PRESCRIBERS_LIST") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoadFromProps = new Date();
    } else {
      returnState.prescribers = state.SettingReducer.data.data;
    }
  }

  if (state.SettingReducer.action === "SEND_EMAIL_PRESCRIBERS") {
    returnState.showLoadFromProps = new Date();
  }

  if (state.SettingReducer.action === "INACTIVATE_PRESCRIBER") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoadFromProps = new Date();
    } else {
      returnState.loadAgain = true;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPrescribers,
      resendInvitationEmail,
      inactivateUser,
      reactivateUser,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Prescribers);
