"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.getSlotMetrics = getSlotMetrics;
var _dates = _interopRequireDefault(require("./dates"));
var getDstOffset = function getDstOffset(start, end) {
  return start.getTimezoneOffset() - end.getTimezoneOffset();
};
var getKey = function getKey(min, max, step, slots) {
  return "" + +_dates.default.startOf(min, 'minutes') + ("" + +_dates.default.startOf(max, 'minutes')) + (step + "-" + slots);
};
var DisplayDstSwitchDates = function DisplayDstSwitchDates() {
  var year = new Date().getYear();
  if (year < 1000) year += 1900;
  var firstSwitch = 0;
  var secondSwitch = 0;
  var lastOffset = 99;

  // Loop through every month of the current year
  for (var i = 0; i < 12; i++) {
    // Fetch the timezone value for the month
    var newDate = new Date(Date.UTC(year, i, 0, 0, 0, 0, 0));
    var tz = -1 * newDate.getTimezoneOffset() / 60;

    // Capture when a timzezone change occurs
    if (tz > lastOffset) firstSwitch = i - 1;else if (tz < lastOffset) secondSwitch = i - 1;
    lastOffset = tz;
  }

  // Go figure out date/time occurrences a minute before
  // a DST adjustment occurs
  var secondDstDate = FindDstSwitchDate(year, secondSwitch);
  var firstDstDate = FindDstSwitchDate(year, firstSwitch);
  if (firstDstDate == null && secondDstDate == null) return false;else return {
    first: firstDstDate,
    last: secondDstDate
  };
};
var FindDstSwitchDate = function FindDstSwitchDate(year, month) {
  // Set the starting date
  var baseDate = new Date(Date.UTC(year, month, 0, 0, 0, 0, 0));
  var changeDay = 0;
  var changeMinute = -1;
  var baseOffset = -1 * baseDate.getTimezoneOffset() / 60;
  var dstDate;

  // Loop to find the exact day a timezone adjust occurs
  for (var day = 0; day < 50; day++) {
    var tmpDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    var tmpOffset = -1 * tmpDate.getTimezoneOffset() / 60;

    // Check if the timezone changed from one day to the next
    if (tmpOffset != baseOffset) {
      var minutes = 0;
      changeDay = day;

      // Back-up one day and grap the offset
      tmpDate = new Date(Date.UTC(year, month, day - 1, 0, 0, 0, 0));
      tmpOffset = -1 * tmpDate.getTimezoneOffset() / 60;

      // Count the minutes until a timezone change occurs
      while (changeMinute == -1) {
        tmpDate = new Date(Date.UTC(year, month, day - 1, 0, minutes, 0, 0));
        tmpOffset = -1 * tmpDate.getTimezoneOffset() / 60;

        // Determine the exact minute a timezone change
        // occurs
        if (tmpOffset != baseOffset) {
          // Back-up a minute to get the date/time just
          // before a timezone change occurs
          tmpOffset = new Date(Date.UTC(year, month, day - 1, 0, minutes - 1, 0, 0));
          changeMinute = minutes;
          break;
        } else minutes++;
      }

      // Add a month (for display) since JavaScript counts
      // months from 0 to 11
      dstDate = tmpOffset.getMonth() + 1;

      // Pad the month as needed
      if (dstDate < 10) dstDate = "0" + dstDate;

      // Add the day and year
      dstDate += '/' + tmpOffset.getDate() + '/' + year + ' ';

      // Capture the time stamp
      tmpDate = new Date(Date.UTC(year, month, day - 1, 0, minutes - 1, 0, 0));
      //dstDate += tmpDate.toTimeString().split(' ')[0];
      return dstDate;
    }
  }
};
function getSlotMetrics(_ref) {
  Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };
  Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
  };
  var start = _ref.min,
    end = _ref.max,
    step = _ref.step,
    timeslots = _ref.timeslots;
  var key = getKey(start, end, step, timeslots);
  var totalMin = 1 + _dates.default.diff(start, end, 'minutes') + getDstOffset(start, end);
  var minutesFromMidnight = _dates.default.diff(_dates.default.startOf(start, 'day'), start, 'minutes');
  var numGroups = Math.ceil(totalMin / (step * timeslots));
  var numSlots = numGroups * timeslots;
  var groups = new Array(numGroups);
  var slots = new Array(numSlots); // Each slot date is created from "zero", instead of adding `step` to
  // the previous one, in order to avoid DST oddities

  var dateIsDST = false;
  var dstDates = DisplayDstSwitchDates();
  if (_ref.range == undefined) {
    var customMinsFromMidnight = parseInt(_ref.min.getHours()) * 60 + parseInt(_ref.min.getMinutes());
    if (customMinsFromMidnight != minutesFromMidnight) {
      minutesFromMidnight = customMinsFromMidnight;
    }
    /*if(start.isDstObserved()) {
      dateIsDST = true;
    } else {
      var firstDate = new Date(JSON.parse(JSON.stringify(start)))
      for(var i=1;i<7;i++){
        firstDate.setDate(firstDate.getDate() + i)
        if(firstDate.isDstObserved()) {
          dateIsDST = true;
        }
      }      
    }*/
    //firstDate.setDate(firstDate.getDate() + 1)
    /*var firstDate = start;
    var dummyDates = []
    */
    /*var dummyDates = [start]
    _ref.range.map((obj, idx) => {
      if(obj.isDstObserved()) {
        dateIsDST = true
      }
    })*/
  } else {
    /*if(start.isDstObserved()) {
      dateIsDST = true;
    }*/
  }
  for (var grp = 0; grp < numGroups; grp++) {
    groups[grp] = new Array(timeslots);
    for (var slot = 0; slot < timeslots; slot++) {
      var slotIdx = grp * timeslots + slot;
      var minFromStart = slotIdx * step; // A date with total minutes calculated from the start of the day

      /*if(grp == 0 && slot == 0 && dateIsDST){
        //if(start.getDate() == dstDates.last.split('/')[1] ) {
          //minutesFromMidnight = minutesFromMidnight + 60;
        //}
      }*/

      slots[slotIdx] = groups[grp][slot] = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, minutesFromMidnight + minFromStart, 0, 0);
    }
  } // Necessary to be able to select up until the last timeslot in a day
  var lastSlotMinFromStart = slots.length * step;
  slots.push(new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, minutesFromMidnight + lastSlotMinFromStart, 0, 0));
  function positionFromDate(date) {
    var diff = _dates.default.diff(start, date, 'minutes') + getDstOffset(start, date);
    return Math.min(diff, totalMin);
  }
  return {
    groups: groups,
    update: function update(args) {
      if (getKey(args) !== key) return getSlotMetrics(args);
      return this;
    },
    dateIsInGroup: function dateIsInGroup(date, groupIndex) {
      var nextGroup = groups[groupIndex + 1];
      return _dates.default.inRange(date, groups[groupIndex][0], nextGroup ? nextGroup[0] : end, 'minutes');
    },
    nextSlot: function nextSlot(slot) {
      var next = slots[Math.min(slots.indexOf(slot) + 1, slots.length - 1)]; // in the case of the last slot we won't a long enough range so manually get it

      if (next === slot) next = _dates.default.add(slot, step, 'minutes');
      return next;
    },
    closestSlotToPosition: function closestSlotToPosition(percent) {
      var slot = Math.min(slots.length - 1, Math.max(0, Math.floor(percent * numSlots)));
      return slots[slot];
    },
    closestSlotFromPoint: function closestSlotFromPoint(point, boundaryRect) {
      var range = Math.abs(boundaryRect.top - boundaryRect.bottom);
      return this.closestSlotToPosition((point.y - boundaryRect.top) / range);
    },
    closestSlotFromDate: function closestSlotFromDate(date, offset) {
      if (offset === void 0) {
        offset = 0;
      }
      if (_dates.default.lt(date, start, 'minutes')) return slots[0];
      var diffMins = _dates.default.diff(start, date, 'minutes');
      return slots[(diffMins - diffMins % step) / step + offset];
    },
    startsBeforeDay: function startsBeforeDay(date) {
      return _dates.default.lt(date, start, 'day');
    },
    startsAfterDay: function startsAfterDay(date) {
      return _dates.default.gt(date, end, 'day');
    },
    startsBefore: function startsBefore(date) {
      return _dates.default.lt(_dates.default.merge(start, date), start, 'minutes');
    },
    startsAfter: function startsAfter(date) {
      return _dates.default.gt(_dates.default.merge(end, date), end, 'minutes');
    },
    getRange: function getRange(rangeStart, rangeEnd) {
      rangeStart = _dates.default.min(end, _dates.default.max(start, rangeStart));
      rangeEnd = _dates.default.min(end, _dates.default.max(start, rangeEnd));
      var rangeStartMin = positionFromDate(rangeStart);
      var rangeEndMin = positionFromDate(rangeEnd);
      var top = rangeStartMin / (step * numSlots) * 100;
      return {
        top: top,
        height: rangeEndMin / (step * numSlots) * 100 - top,
        start: positionFromDate(rangeStart),
        startDate: rangeStart,
        end: positionFromDate(rangeEnd),
        endDate: rangeEnd
      };
    }
  };
}