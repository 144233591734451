import { matchPath } from "react-router";
import { SETTINGS_ROUTES } from "../../../../../consts/routes";

export function getSimpleQuestionnairesPathParams() {
  const match = matchPath(window.location.pathname, {
    path: SETTINGS_ROUTES.questionnaires.simple.edit(":id"),
    exact: true,
    strict: false,
  });

  return {
    questionnaireId: match?.params?.id ? Number(match.params.id) : null,
  };
}
