import { useMemo } from "react";
import { useEfaxIntegrationStatusQuery } from "../../../../../api/efax/useEfaxIntegrationStatusQuery";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useEfaxIntegrationEnableMutation } from "../../../../../api/efax/useEfaxIntegrationEnableMutation";
import { useEfaxIntegrationDisableMutation } from "../../../../../api/efax/useEfaxIntegrationDisableMutation";
import { extractApiError } from "../../../../../utilities/api";

export const useIntegration = () => {
  const { tSettings } = useAppTranslation.Settings();

  const statusQuery = useEfaxIntegrationStatusQuery({
    onError: () => {
      uiNotification.error(tSettings("efaxIntegration.error.fetchStatus"));
    },
  });

  const enableMutation = useEfaxIntegrationEnableMutation({
    onSuccess: () => {
      uiNotification.success(tSettings("efaxIntegration.success.enable"));
    },
    onError: (e) => {
      const message = extractApiError(e);
      uiNotification.error(
        message || tSettings("efaxIntegration.error.enable"),
      );
    },
  });

  const disableMutation = useEfaxIntegrationDisableMutation({
    onSuccess: () => {
      uiNotification.success(tSettings("efaxIntegration.success.disable"));
    },
    onError: () => {
      uiNotification.error(tSettings("efaxIntegration.error.disable"));
    },
  });

  const packages = useMemo(() => {
    return (
      statusQuery.data?.packages?.map((p) => ({
        label: `${p.pages} Pages @ $${p.price}`,
        value: p.pages,
      })) || []
    );
  }, [statusQuery.data?.packages]);

  return {
    status: {
      isLoading: statusQuery.isLoading,
      isEnabled: !!statusQuery.data?.isEnabled,
      faxNumber: statusQuery.data?.faxNumber,
    },
    enable: {
      isLoading: enableMutation.isLoading,
      initiate: ({ countryCode, areaCode, addonValue }) =>
        enableMutation.mutateAsync({ countryCode, areaCode, addonValue }),
    },
    disable: {
      isLoading: disableMutation.isLoading,
      initiate: disableMutation.mutateAsync,
    },
    packages,
  };
};
