import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { dispatch } from "../../../../store/store";
import { providerRoom } from "../../../../store/providerRoom";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

function TabHealthRowActions({ procedureId, isSentToMd, isSigning }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Button
      size="small"
      isDisabled={isSentToMd || isSigning}
      onClick={() => {
        dispatch(
          providerRoom.actions.signProceduresDataChange({
            procedureIds: [procedureId],
            isSendToMd: true,
          }),
        );
      }}
      leftAdornment={
        isSigning ? <CircularProgress size="tiny" color="white" /> : undefined
      }
    >
      {tCommon("providerRoom.action.sendToMd")}
    </Button>
  );
}

TabHealthRowActions.propTypes = {
  procedureId: PropTypes.number.isRequired,
  isSentToMd: PropTypes.bool.isRequired,
  isSigning: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isSigning: providerRoom.selectors.selectProcedureSigningStatus(state),
  };
}

export default connect(mapStateToProps)(TabHealthRowActions);
