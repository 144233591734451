import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "./QueryKeys";
import { uiNotification } from "../../services/UINotificationService";
import { http } from "../../services/HttpService";
import { handleApiErrorMessage } from "../../utilities/api";

const GetDeviceDetails = (id) => {
  const url = `/devices/${id}`;
  return http.get(url);
};

export const useGetDeviceDetailsQuery = (id) => {
  return useQuery([QueryKeys.GET_NOTES_ACKNOWLEDGED_HISTORY, id], () =>
    GetDeviceDetails(id),
  );
};

const UpdateDeviceDetails = (payload) => {
  const { id, ...data } = payload;
  const url = `/devices/${id}`;
  return http.put(url, data);
};

export const useUpdateDeviceDetailsQuery = (id) => {
  const queryClient = useQueryClient();
  return useMutation(UpdateDeviceDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_NOTES_ACKNOWLEDGED_HISTORY, id],
      });
    },
  });
};

const CreateNewDevice = (data) => {
  const url = `/devices/0`;
  return http.post(url, data);
};

export const useCreateNewDeviceQuery = () => {
  return useMutation(CreateNewDevice, {
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const DeleteDevice = (id) => {
  const url = `/devices/${id}`;
  return http.delete(url);
};

export const useDeleteDeviceQuery = () => {
  return useMutation(DeleteDevice, {
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};
