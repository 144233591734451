import React from "react";
import { useCurrentAccountQuery } from "../../../../../../api/queries/useAccountQuery";
import { PLAN_CODES } from "../../../../../../consts/api";

export function EmptyText({ procedureDataText, profile, appointmentData }) {
  const { data: account } = useCurrentAccountQuery();

  const isTouchMd = account?.preferences?.isTouchMd;
  const planCode = account?.subscription?.planCode;

  return (
    <>
      {isTouchMd &&
        planCode === PLAN_CODES.booking &&
        procedureDataText === true && (
          <div className={"no-record"}>Appointments data loading...</div>
        )}
      {isTouchMd &&
        planCode === PLAN_CODES.booking &&
        procedureDataText === false &&
        profile &&
        appointmentData?.length === 0 && (
          <div className={"no-record"}>No appointments found.</div>
        )}
    </>
  );
}
