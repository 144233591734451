/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import moment from "moment";

const showSelectedMonth = (date = moment()) => {
  let showSelectedLabel = "";
  let startOfMonth = moment(date).startOf("month");
  let ednOfMonth = moment(date).endOf("month");
  let maxDate = moment().format("YYYY-MM-DD");
  let minDate = moment("2017-01-01").format("YYYY-MM-DD");

  if (moment(date).format("YYYYMM") >= moment().format("YYYYMM")) {
    startOfMonth = moment(maxDate).startOf("month");
    showSelectedLabel =
      startOfMonth.format("MMM 01 -") + moment().format("DD, YYYY");
  } else if (
    moment(date).format("YYYYMM") <= moment("2017-01-01").format("YYYYMM")
  ) {
    ednOfMonth = moment(minDate).endOf("month");
    showSelectedLabel =
      ednOfMonth.format("MMM 01 - ") + ednOfMonth.format("DD, YYYY");
  } else if (startOfMonth.format("YYYY") != ednOfMonth.format("YYYY")) {
    showSelectedLabel =
      startOfMonth.format("MMM DD, YY - ") + ednOfMonth.format("MMM DD, YY");
  } else if (startOfMonth.format("MM") != ednOfMonth.format("MM")) {
    showSelectedLabel =
      startOfMonth.format("MMM DD - ") + ednOfMonth.format("MMM DD, YYYY");
  } else {
    showSelectedLabel =
      startOfMonth.format("MMM ") +
      startOfMonth.format("DD - ") +
      ednOfMonth.format("DD, YYYY");
  }

  return showSelectedLabel;
};

export default class FilterMonth extends Component {
  constructor(props) {
    super(props);
    const currentDate = moment().format("DD");
    const selectedMonth = props.selectedMonth
      ? props.selectedMonth
      : moment().format("MM");
    const selectedYear = props.selectedYear
      ? props.selectedYear
      : moment().format("YYYY");
    const selectedDate = moment(
      selectedYear + "-" + selectedMonth + "-" + currentDate,
    ).toDate();
    const showSelectedDate = showSelectedMonth(selectedDate);
    this.state = {
      showSelectedDate: showSelectedDate,
      selectedDate: selectedDate,
      selectedMonth: props.selectedMonth
        ? props.selectedMonth
        : moment().format("MM"),
      selectedYear: props.selectedYear
        ? props.selectedYear
        : moment().format("YYYY"),
      calendarView: "month",
      minDate: new Date("2017-01-02"),
      maxDate: new Date(),
      isBackDateDisabled: false,
      isNextDateDisabled: true,
    };
    props.handleChildState({ selectedDate: showSelectedDate });
  }

  static getDerivedStateFromProps() {
    let returnState = {};

    return returnState;
  }

  handleNextPrevDate = (navigate) => {
    var selectedDate = moment(this.state.selectedDate);
    let returnState = {};
    returnState.userChanged = true;
    returnState.isNextDateDisabled = false;
    returnState.isBackDateDisabled = false;
    if (navigate == "next") {
      if (moment(selectedDate).format("YYYYMM") >= moment().format("YYYYMM")) {
        this.setState({ isNextDateDisabled: true });
        return false;
      }
      let newSelectedDate = selectedDate.add(1, "month").toDate();
      returnState.selectedDate = newSelectedDate;
      returnState.showSelectedDate = showSelectedMonth(newSelectedDate);
      returnState.searchPickerDate = moment(returnState.selectedDate)
        .startOf("month")
        .toDate();
      returnState.isChangeWeek = true;
      if (moment(selectedDate).format("YYYYMM") >= moment().format("YYYYMM")) {
        returnState.isNextDateDisabled = true;
      }
    } else if (navigate == "prev") {
      if (
        moment(selectedDate).format("YYYYMM") <=
        moment("2017-01-01").format("YYYYMM")
      ) {
        this.setState({ isBackDateDisabled: true });
        return false;
      }
      let newSelectedDate = selectedDate.subtract(1, "month").toDate();
      returnState.selectedDate = newSelectedDate;
      returnState.showSelectedDate = showSelectedMonth(newSelectedDate);
      returnState.searchPickerDate = moment(returnState.selectedDate)
        .startOf("month")
        .toDate();
      returnState.isChangeWeek = true;
      if (
        moment(selectedDate).format("YYYYMM") <=
        moment("2017-01-01").format("YYYYMM")
      ) {
        returnState.isBackDateDisabled = true;
      }
    }

    returnState.selectedMonth = moment(returnState.selectedDate).format("MM");
    returnState.selectedYear = moment(returnState.selectedDate).format("YYYY");
    this.setState(returnState);

    this.props.handleFilterMonth({
      month: returnState.selectedMonth,
      year: returnState.selectedYear,
      selectedDate: returnState.showSelectedDate,
    });
    this.props.handleChildState({ selectedDate: returnState.showSelectedDate });
  };

  render() {
    return (
      <button
        className={
          this.props.className
            ? this.props.className
            : "calendar-btn cal-date-btn"
        }
      >
        <a
          className="cal-arrow pull-left"
          onClick={this.handleNextPrevDate.bind(this, "prev")}
        >
          <img
            alt=""
            src={
              this.state.isBackDateDisabled
                ? "/images/date-left.svg"
                : "/images/cal-left.svg"
            }
          />
        </a>
        {this.state.showSelectedDate}
        <a
          className="cal-arrow pull-right"
          onClick={this.handleNextPrevDate.bind(this, "next")}
        >
          <img
            alt=""
            src={
              this.state.isNextDateDisabled
                ? "/images/date-right.svg"
                : "/images/cal-right.svg"
            }
          />
        </a>
      </button>
    );
  }
}
