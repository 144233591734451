import React from "react";
import { withRouter } from "react-router";
import DiscountByClinic from "./DiscountByClinic";
import AllDiscounts from "./AllDiscounts";
import { useHeaderActions } from "../../../hooks/useHeaderActions";

const Discounts = ({ match }) => {
  const { period, selectedClinic, apiPeriod } = useHeaderActions();

  return !match.params.subReport ? (
    <AllDiscounts
      period={period}
      apiPeriod={apiPeriod}
      selectedClinic={selectedClinic}
    />
  ) : (
    <DiscountByClinic
      period={period}
      apiPeriod={apiPeriod}
      selectedClinic={selectedClinic}
    />
  );
};

export default withRouter(Discounts);
