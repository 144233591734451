import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../../schemas/pagination";

const PAGE_SIZE = 20;

const responseSchema = paginationSchema.concat(
  yup.object({
    data: yup.array().of(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
        start_date: yup.string().required(),
        end_date: yup.string().required(),
        status: yup.number().required(),
      }),
    ),
  }),
);

const composeResponse = (res) => {
  const data = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: PAGE_SIZE,
    }),
    data,
  };
};

const createPPAnnouncementBannersQueryKey = () => [
  QUERY_KEYS.patientPortal.announcementBanner.getAll,
];

export function usePPAnnouncementBannersQuery(options = {}) {
  return useInfiniteQuery({
    queryKey: createPPAnnouncementBannersQueryKey(),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(
        HTTP_ENDPOINTS.patientPortal.announcementBanner.getAll(),
        {
          params: {
            page: pageParam,
            pageSize: PAGE_SIZE,
          },
        },
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
