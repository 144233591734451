import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { useVitals } from "../../hooks/useVitals";
import { Notes } from "./shared/Notes/Notes";
import { findBmiRange } from "./Table.utils";
import { useSettingRanges } from "../../hooks/useSettingRanges";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { DEFAULT_DATE_FORMAT } from "../../../../../consts/general";
import { Input } from "./shared/Input/Input";
import { dispatch } from "../../../../../store/store";
import { patientVitals } from "../../../../../store/patients/vitals";
import classes from "./Table.module.scss";
import {
  MUTATION_RECORD_CREATE_MODE_ID,
  MUTATION_RECORD_FIELDS,
} from "../../../../../store/patients/vitals/config";
import { SETTING_VIEW_NAMES } from "../../../../../api/patientVitals/config";
import { cond } from "../../../../../utilities/fp";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

const { actions } = patientVitals;

const getChangeHandler = (field) => (value) =>
  dispatch(actions.patchMutationRecordField({ field, value }));

export const useColumns = ({
  isCreateMode,
  mutationRecord,
  mutationRecordFieldErrors,
  setIsColSelectDisabled,
}) => {
  const { tCommon } = useAppTranslation.Common();
  const user = useCurrentUserQuery();
  const account = useCurrentAccountQuery();
  const vitals = useVitals();
  const settingRanges = useSettingRanges();

  const getBmiColor = useCallback(
    (bmiValue) => findBmiRange(bmiValue, settingRanges.data)?.color,
    [settingRanges.data],
  );

  const formatDate = useCallback(
    (date) => moment(date).format(user.data?.dateFormat || DEFAULT_DATE_FORMAT),
    [user.data?.dateFormat],
  );

  const hasError = (field) => {
    return field in mutationRecordFieldErrors;
  };

  const dynamicInputs = (() => {
    const pref = account.data?.preferences || {};

    return {
      [SETTING_VIEW_NAMES.height]: (
        <Input
          type="number"
          value={mutationRecord?.[MUTATION_RECORD_FIELDS.height]}
          onChange={getChangeHandler(MUTATION_RECORD_FIELDS.height)}
          adornment={pref.lengthUnit}
          isError={hasError(MUTATION_RECORD_FIELDS.height)}
        />
      ),

      [SETTING_VIEW_NAMES.weight]: (
        <Input
          type="number"
          value={mutationRecord?.[MUTATION_RECORD_FIELDS.weight]}
          onChange={getChangeHandler(MUTATION_RECORD_FIELDS.weight)}
          adornment={pref.weightUnit}
          isError={hasError(MUTATION_RECORD_FIELDS.weight)}
        />
      ),

      [SETTING_VIEW_NAMES.bmi]: (
        <Input
          type="number"
          value={mutationRecord?.[MUTATION_RECORD_FIELDS.bmi]}
          onChange={getChangeHandler(MUTATION_RECORD_FIELDS.bmi)}
          isError={hasError(MUTATION_RECORD_FIELDS.bmi)}
        />
      ),

      [SETTING_VIEW_NAMES.bodyFat]: (
        <Input
          type="number"
          value={mutationRecord?.[MUTATION_RECORD_FIELDS.bodyFat]}
          onChange={getChangeHandler(MUTATION_RECORD_FIELDS.bodyFat)}
          adornment="%"
          isError={hasError(MUTATION_RECORD_FIELDS.bodyFat)}
        />
      ),

      [SETTING_VIEW_NAMES.bloodPressure]: (
        <div className={classes.inputWrap}>
          <Input
            type="number"
            value={
              mutationRecord?.[MUTATION_RECORD_FIELDS.bloodPressureSystolic]
            }
            onChange={getChangeHandler(
              MUTATION_RECORD_FIELDS.bloodPressureSystolic,
            )}
            isError={hasError(MUTATION_RECORD_FIELDS.bloodPressureSystolic)}
          />
          /
          <Input
            type="number"
            value={
              mutationRecord?.[MUTATION_RECORD_FIELDS.bloodPressureDiastolic]
            }
            onChange={getChangeHandler(
              MUTATION_RECORD_FIELDS.bloodPressureDiastolic,
            )}
            isError={hasError(MUTATION_RECORD_FIELDS.bloodPressureDiastolic)}
          />
        </div>
      ),

      [SETTING_VIEW_NAMES.pulse]: (
        <Input
          type="number"
          value={mutationRecord?.[MUTATION_RECORD_FIELDS.pulse]}
          onChange={getChangeHandler(MUTATION_RECORD_FIELDS.pulse)}
          isError={hasError(MUTATION_RECORD_FIELDS.pulse)}
        />
      ),

      [SETTING_VIEW_NAMES.temperature]: (
        <Input
          type="number"
          value={mutationRecord?.[MUTATION_RECORD_FIELDS.temperature]}
          onChange={getChangeHandler(MUTATION_RECORD_FIELDS.temperature)}
          adornment={`°${pref.temperatureUnit}`}
          isError={hasError(MUTATION_RECORD_FIELDS.temperature)}
        />
      ),

      [SETTING_VIEW_NAMES.respiration]: (
        <Input
          type="number"
          value={mutationRecord?.[MUTATION_RECORD_FIELDS.respiration]}
          onChange={getChangeHandler(MUTATION_RECORD_FIELDS.respiration)}
          isError={hasError(MUTATION_RECORD_FIELDS.respiration)}
        />
      ),
    };
  })();

  const cols = useMemo(() => {
    const pref = account.data?.preferences || {};

    return (
      vitals.data?.map((vital) => ({
        key: vital.id,

        date: formatDate(vital.vital_date),

        ...cond(
          () => ({
            [SETTING_VIEW_NAMES.height]: vital.height
              ? `${vital.height} ${pref.lengthUnit}`
              : null,

            [SETTING_VIEW_NAMES.weight]: vital.weight
              ? `${vital.weight} ${pref.weightUnit}`
              : null,

            [SETTING_VIEW_NAMES.bmi]: vital.bmi ? (
              <span style={{ color: getBmiColor(vital.bmi) }}>{vital.bmi}</span>
            ) : null,

            [SETTING_VIEW_NAMES.bodyFat]: vital.body_fat
              ? `${vital.body_fat}%`
              : null,

            [SETTING_VIEW_NAMES.bloodPressure]: `${
              vital.blood_pressure_systolic || tCommon("symbol.longDash")
            }/${
              vital.blood_pressure_diastolic || tCommon("symbol.longDash")
            } mm/HG`,

            [SETTING_VIEW_NAMES.pulse]: vital.pulse
              ? `${vital.pulse} bpm`
              : null,

            [SETTING_VIEW_NAMES.temperature]: vital.temperature
              ? `${vital.temperature} °${pref.temperatureUnit}`
              : null,

            [SETTING_VIEW_NAMES.respiration]: vital.respiration
              ? `${vital.respiration} bpm`
              : null,
          }),
          [(v) => v === mutationRecord?.id, () => dynamicInputs],
        )(vital.id),

        provider: vital.creator.full_name,

        notes: (
          <Notes
            notesCount={vital.notes_count || 0}
            vitalId={vital.id}
            onOpen={() => setIsColSelectDisabled(true)}
            onClose={() => setIsColSelectDisabled(false)}
          />
        ),
      })) || []
    );
  }, [
    vitals.data,
    account.data?.preferences?.weightUnit,
    account.data?.preferences?.temperatureUnit,
    user.data?.dateFormat,
    getBmiColor,
    formatDate,
  ]);

  const prependedCols = (() => {
    if (isCreateMode) {
      return [
        {
          key: MUTATION_RECORD_CREATE_MODE_ID,

          date: formatDate(mutationRecord?.[MUTATION_RECORD_FIELDS.date]),

          ...dynamicInputs,

          provider: user.data?.fullName,

          notes: (
            <Notes
              onOpen={() => setIsColSelectDisabled(true)}
              onClose={() => setIsColSelectDisabled(false)}
              notesCount={mutationRecord.notes?.length || 0}
            />
          ),
        },
      ];
    }

    return [];
  })();

  return {
    cols,
    prependedCols,
    isColumnsLoading:
      settingRanges.isLoading ||
      vitals.isLoading ||
      account.isLoading ||
      user.isLoading,
  };
};
