import { useMemo } from "react";
import moment from "moment";
import { usePatientUpcomingAppointmentsQuery } from "../../../../api/queries/usePatientUpcomingAppointmentsQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import {
  DEFAULT_TIME_FORMAT,
  PREVIEW_DATE_FORMAT,
} from "../../../../consts/general";
import { useProcedureQuery } from "../../../../api/procedure/useProcedureQuery";
import { Maybe, cond, pipe } from "../../../../utilities/fp";

export function useAppointmentOptions({ clientId, procedureId }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user, isLoading: isUserLoading } = useCurrentUserQuery();

  const { data, isLoading } = usePatientUpcomingAppointmentsQuery({
    payload: {
      patientId: clientId,
      procedureId,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      onError: () => {
        uiNotification.error(tCommon("error.fetchUpcomingAppointments"));
      },
    },
  });

  const { data: procedure, isFetching: isProcedureFetching } =
    useProcedureQuery({
      payload: {
        procedureId,
      },
      options: {
        enabled: !!procedureId,
      },
    });

  const composeLabel = (appointment) => {
    return `${tCommon(`appointmentType.${appointment.type}`)} - ${moment(
      appointment.dateTime,
    ).format(
      `${user?.previewDateFormat || PREVIEW_DATE_FORMAT} ${
        user?.timeFormat || DEFAULT_TIME_FORMAT
      }`,
    )}`;
  };

  return useMemo(() => {
    const upcomingAppointments =
      data?.map((i) => ({
        label: composeLabel(i),
        value: i.id,
        providerId: i.providerId,
        clinicId: i.clinicId,
        serviceIds: i.services?.map((s) => s.id) || [],
      })) || [];

    const procedureAppointment = Maybe.of(procedure?.appointment)
      .map((x) => ({
        label: composeLabel(x),
        value: x.id,
        providerId: procedure?.providerId,
        clinicId: procedure?.clinicId,
        serviceIds:
          procedure?.serviceId === 0
            ? null
            : [procedure?.serviceId].filter(Boolean),
      }))
      .orElse(null)
      .value();

    const preparedData = Maybe.of(procedureAppointment)
      .map((appt) =>
        pipe(
          appt,
          cond(
            () => upcomingAppointments,
            [
              (x) => !upcomingAppointments?.find((y) => y.value === x.value),
              (x) => [...upcomingAppointments, x],
            ],
          ),
        ),
      )
      .orElse(upcomingAppointments)
      .value();

    return {
      data: preparedData,
      isLoading: isLoading || isUserLoading || isProcedureFetching,
    };
  }, [data, procedure, isLoading, isUserLoading, isProcedureFetching]);
}
