import { ACTION_TYPES } from "./config";

const startDocumentsDedup = (docType) => ({
  type: ACTION_TYPES.startDocumentsDedup,
  payload: { docType },
});

const finishDocumentsDedup = (docType) => ({
  type: ACTION_TYPES.finishDocumentsDedup,
  payload: { docType },
});

export const actions = {
  startDocumentsDedup,
  finishDocumentsDedup,
};
