import { useMemo, useState } from "react";
import {
  PROVIDER_ROOM_PROCEDURE_TYPE,
  useProviderRoomProceduresQuery,
} from "../../../api/queries/useProviderRoomProceduresQuery";
import { TABLE_SIZE } from "../ProviderRoom.consts";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";
import { uiNotification } from "../../../services/UINotificationService";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function useProcedures({ version, clinicID = "", providerUserId }) {
  const [type, setType] = useState(PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic);
  const [clinicId, setClinicId] = useState(clinicID);
  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState("");

  const { tCommon } = useAppTranslation.Common();

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useProviderRoomProceduresQuery({
    payload: {
      pageSize: TABLE_SIZE,
      procedureVersion: version,
      procedureType: type,
      searchTerm: debouncedSearchTerm,
      clinicId,
      provider_user_id: providerUserId || "",
    },
    options: {
      staleTime: 0,
      onError: () => {
        uiNotification.error(tCommon("providerRoom.error.fetchProcedures"));
      },
    },
  });

  const procedures = useMemo(() => {
    if (data && data.pages) {
      return data.pages
        .map((p) => p.procedures)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data]);

  const isEmpty = !isLoading && procedures.length === 0;

  return {
    procedures,
    refetchProcedures: refetch,
    fetchNextProcedures: fetchNextPage,

    proceduresSearchTerm: {
      value: searchTerm,
      onChange: setSearchTerm,
    },

    proceduresType: {
      value: type,
      onChange: setType,
    },

    selectClinic: {
      value: clinicId,
      onChange: setClinicId,
    },

    proceduresStatus: {
      isLoading: isLoading && !searchTerm,
      isNextFetching: isFetchingNextPage,
      isSearching: isLoading && Boolean(searchTerm),
      isVisible: !isLoading && !isEmpty,
      isEmpty,
      hasMore: hasNextPage,
    },
  };
}
