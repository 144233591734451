import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../shared/Button/Button";
import { svg } from "../../../../../../assets/svg";
import { DateRangePicker } from "../../../../../../shared/DateRangePicker/DateRangePicker";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { ReportHeader } from "../../../../components/ReportHeader/ReportHeader";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { STATIC_RANGES } from "../../../../../../shared/DateRangePicker/DateRangePicker.consts";
import { Modal } from "../../../../../../shared/Modal/Modal";

export function Header({
  period,
  downloadExcel,
  isReportsLoading,
  isFilterActive,
  onFilterOpen,
}) {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader
      label={tBi("retentionRateDetailsReport.label")}
      left={
        <Button
          size="small"
          variant="text"
          onClick={() => setIsInfoModalOpen(true)}
        >
          <img src={svg.infoPrimary} alt="info" width="20px" />
        </Button>
      }
    >
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
        staticRanges={[STATIC_RANGES.mtd, STATIC_RANGES.qtd, STATIC_RANGES.ytd]}
      />
      <Button
        size="small"
        variant="outlined"
        onClick={downloadExcel.initiate}
        isDisabled={downloadExcel.isLoading || isReportsLoading}
        leftAdornment={
          downloadExcel.isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <img src={svg.downloadPrimary} alt="dl" width="20px" />
          )
        }
      >
        {tCommon("label.downloadExcel")}
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={onFilterOpen}
        isDisabled={isReportsLoading}
        leftAdornment={
          isFilterActive ? (
            <img src={svg.filterPrimaryActive} alt="filter" width="16px" />
          ) : (
            <img src={svg.filterPrimary} alt="filter" width="14px" />
          )
        }
      >
        {tCommon("label.filter")}
      </Button>
      <Modal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        header={<Modal.Title>{tBi("retentionReportInfo.title")}</Modal.Title>}
      >
        {tBi("retentionReportInfo.description")}
      </Modal>
    </ReportHeader>
  );
}

Header.propTypes = {
  period: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    update: PropTypes.func,
  }).isRequired,
  downloadExcel: PropTypes.shape({
    initiate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  onFilterOpen: PropTypes.func.isRequired,
};
