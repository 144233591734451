import { useUpdateGiftCardExpiryDateMutation } from "../../../../../api/businessInsight/useUpdateGiftCardExpiryDateMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export const useSubmit = (giftCardId, onSuccess) => {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useUpdateGiftCardExpiryDateMutation({
    onError: () => {
      uiNotification.error(tCommon("modalEditGiftCardExpiryDate.error.update"));
    },
    onSuccess: () => {
      uiNotification.success(
        tCommon("modalEditGiftCardExpiryDate.success.update"),
      );
      onSuccess?.();
    },
  });

  return {
    isLoading,
    initiate: (formValues) =>
      mutateAsync({
        giftCardId,
        expiryDate: formValues.date,
        reason: formValues.reason,
      }),
  };
};
