import React from "react";
import { Modal } from "../../../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./Header.module.scss";
import { DateRangePicker } from "../../../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";

export function Header({ isPickerDisabled, period }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  return (
    <Modal.Title className={classes.root}>
      {tCommon("label.inventoryLog")}
      <DateRangePicker
        isDisabled={isPickerDisabled}
        size="small"
        dateFormat={user?.dateFormat}
        onClear={period.reset}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />
    </Modal.Title>
  );
}
