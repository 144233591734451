import React, { useState } from "react";

export const withMultiPostFromFiles = (
  Component,
  { submitFile, composeFileForSubmit, submitFileValidationSchema }
) => (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrorLogs, setValidationErrorLogs] = useState([]);

  const compose = (files) => {
    if (Array.isArray(files) && files.length > 0) {
      return files.map((file) => ({
        composed: composeFileForSubmit(file),
        fileName: file.name,
      }));
    } else {
      throw new Error("Files Are Empty");
    }
  };

  const validate = (composedFiles) =>
    composedFiles.reduce(
      (r, f) => {
        const nextRes = r;
        try {
          nextRes.valid.push(
            submitFileValidationSchema.validateSync(f.composed, {
              abortEarly: false,
            })
          );
        } catch (e) {
          nextRes.errors.push({
            fileName: f.fileName,
            errors: e.errors,
          });
        }
        return nextRes;
      },
      {
        errors: [],
        valid: [],
      }
    );

  const postMultiple = async (files) => {
    try {
      setIsLoading(true);
      setValidationErrorLogs([]);

      const { valid: nextFiles, errors } = validate(compose(files));

      if (errors.length > 0) {
        setValidationErrorLogs(errors);
        throw new Error("Unable To Create. See Error Logs");
      }

      await Promise.all(nextFiles.map(submitFile));
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Component
      {...props}
      multiPostFromFiles={{
        post: postMultiple,
        isPosting: isLoading,
        validationErrorLogs,
        resetValidationErrorLogs: () => setValidationErrorLogs([]),
      }}
    />
  );
};
