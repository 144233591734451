import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Button.module.scss";
import { isNullish } from "../../utilities/general";

export function Button({
  type,
  onClick,
  children,
  variant,
  size,
  color,
  fontFamily,
  leftAdornment,
  rightAdornment,
  className,
  leftAdornmentClassName,
  rightAdornmentClassName,
  isDisabled,
  fullWidth,
  isHovered,
  form,
  widthFit,
}) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.vText]: variant === "text",
      [classes.vOutlined]: variant === "outlined",
      [classes.vContained]: variant === "contained",
      [classes.sTiny]: size === "tiny",
      [classes.sSmall]: size === "small",
      [classes.sDefault]: size === "default",
      [classes.sBig]: size === "big",
      [classes.cPrimary]: color === "primary",
      [classes.cError]: color === "error",
      [classes.cWarn]: color === "warn",
      [classes.cSuccess]: color === "success",
      [classes.cGray]: color === "gray",
      [classes.cOrangeDark]: color === "orangeDark",
      [classes.fontPrimary]: fontFamily === "primary",
      [classes.fontSecondary]: fontFamily === "secondary",
      [classes.disabled]: isDisabled,
      [classes.fullWidth]: Boolean(fullWidth),
      [classes.hovered]: isHovered,
      [classes.widthFit]: Boolean(widthFit),
    },
    className,
  );

  return (
    <button type={type} onClick={onClick} className={rootClasses} form={form}>
      {!isNullish(leftAdornment) && (
        <div
          className={cx(
            classes.leftAdornmentBase,
            classes.leftAdornment,
            leftAdornmentClassName,
          )}
        >
          {leftAdornment}
        </div>
      )}
      <div className={classes.content}>{children}</div>
      {!isNullish(rightAdornment) && (
        <div
          className={cx(
            classes.rightAdornmentBase,
            classes.rightAdornment,
            rightAdornmentClassName,
          )}
        >
          {rightAdornment}
        </div>
      )}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  size: PropTypes.oneOf(["tiny", "small", "default", "big"]),
  color: PropTypes.oneOf([
    "primary",
    "error",
    "warn",
    "success",
    "orangeDark",
    "gray",
  ]),
  fontFamily: PropTypes.oneOf(["primary", "secondary"]),
  leftAdornment: PropTypes.node,
  rightAdornment: PropTypes.node,
  className: PropTypes.string,
  leftAdornmentClassName: PropTypes.string,
  rightAdornmentClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isHovered: PropTypes.bool,
  widthFit: PropTypes.bool,
  form: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  variant: "contained",
  size: "default",
  color: "primary",
  fontFamily: "primary",
  leftAdornment: undefined,
  rightAdornment: undefined,
  className: undefined,
  leftAdornmentClassName: undefined,
  rightAdornmentClassName: undefined,
  isDisabled: false,
  fullWidth: false,
  isHovered: false,
  widthFit: false,
};
