import { useMemo } from "react";
import { useObOrderedServicesQuery } from "../../../../../../api/queries/useObOrderedServicesQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useServices(categoryId) {
  const { tCommon } = useAppTranslation.Common();

  const { data, isLoading } = useObOrderedServicesQuery({
    payload: {
      categoryId: Number(categoryId),
    },
    options: {
      enabled: Boolean(categoryId),
      onError: () => {
        uiNotification.error(tCommon("error.fetchServices"));
      },
    },
  });

  const services = useMemo(
    () =>
      data?.map((i) => ({
        id: i.id,
        content: i.name,
      })) || [],
    [data],
  );

  return {
    services,
    isServicesLoading: isLoading,
  };
}
