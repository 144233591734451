import React from "react";
import PropTypes from "prop-types";
import classes from "./InfoRow.module.scss";

export function InfoRow({ label, value, adornment }) {
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <div>{label}</div>
        {adornment}
      </div>
      <div>{value}</div>
    </div>
  );
}

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  adornment: PropTypes.node,
};
