import React from "react";
import moment from "moment";
import { Box } from "../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../shared/SortAdornment/SortAdornment";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { PREVIEW_DATE_FORMAT } from "../../../../../consts/general";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { EFAXES_SORT_FIELDS } from "../../../../../api/efax/useEfaxesQuery";
import { phoneUtil } from "../../../../../utilities/phone";
import { pipe } from "../../../../../utilities/fp";
import styles from "./styles.module.scss";
import { Button } from "../../../../../shared/Button/Button";

export const Table = ({ eFaxes, order }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tInbox } = useAppTranslation.Inbox();

  const { data: user } = useCurrentUserQuery();

  const isEmpty = eFaxes.value.length === 0 && !eFaxes.isLoading;
  const isVisible = !eFaxes.isLoading && !isEmpty;

  const data = eFaxes.value.map((r) => ({
    key: r.id,
    patientName: r.patientName || tCommon("symbol.longDash"),
    faxNumber: (
      <>
        <p className={styles.noMargin}>
          {pipe(r.faxNumber, phoneUtil.compose, phoneUtil.formatWithDashes) ||
            tCommon("symbol.longDash")}
        </p>
        <p className={styles.faxNumberName} title={r.faxNumberName}>
          {r.faxNumberName}
        </p>
      </>
    ),
    documentTemplate: r.documentTemplate || tCommon("symbol.longDash"),
    file: r.file,
    status: r.status,
    sentBy: r.sentBy || tCommon("symbol.longDash"),
    date: moment(r.datetime).format(
      user?.previewDateFormat || PREVIEW_DATE_FORMAT,
    ),
    action: (
      <Button
        onClick={() => openPDFFile(r.file)}
        variant="text"
        size="small"
        isDisabled={!r.file}
      >
        {tInbox("efaxOrders.table.view")}
      </Button>
    ),
  }));

  const openPDFFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box className={styles.root}>
      {eFaxes.isLoading && !eFaxes.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={eFaxes.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={data}
            onRowClick={() => {}}
            cols={[
              {
                data: renderSortWrap(
                  tInbox("efaxOrders.table.patient"),
                  EFAXES_SORT_FIELDS.patientName,
                ),
                accessor: "patientName",
                headColClassName: styles.col,
              },
              {
                data: tInbox("efaxOrders.table.orders"),
                accessor: "documentTemplate",
              },
              {
                data: renderSortWrap(
                  tInbox("efaxOrders.table.status"),
                  EFAXES_SORT_FIELDS.status,
                ),
                accessor: "status",
                headColClassName: styles.col,
              },
              {
                data: renderSortWrap(
                  tInbox("efaxOrders.table.date"),
                  EFAXES_SORT_FIELDS.datetime,
                ),
                accessor: "date",
                headColClassName: styles.col,
              },
              {
                data: renderSortWrap(
                  tInbox("efaxOrders.table.faxNumber"),
                  EFAXES_SORT_FIELDS.faxNumber,
                ),
                accessor: "faxNumber",
                headColClassName: styles.col,
              },
              {
                data: renderSortWrap(
                  tInbox("efaxOrders.table.sentBy"),
                  EFAXES_SORT_FIELDS.sentBy,
                ),
                accessor: "sentBy",
                headColClassName: styles.col,
              },
              {
                data: tInbox("efaxOrders.table.file"),
                accessor: "action",
                headColClassName: styles.center,
                className: styles.flexCenter,
              },
            ]}
          />
          {eFaxes.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={eFaxes.fetchMore}
              isLoading={eFaxes.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
};
