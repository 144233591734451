import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import classes from "./ModalError.module.scss";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { CrossCircleIcon } from "../../assets/Icons/CrossCircleIcon";

export function ModalError({ isOpen, onClose, onOk, children }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      footerClassName={classes.footer}
      footer={
        <Button color="error" onClick={onOk}>
          {tCommon("label.ok")}
        </Button>
      }
      header={
        <Modal.Title className={classes.header}>
          <CrossCircleIcon />
          {tCommon("label.error")}
        </Modal.Title>
      }
    >
      {children}
    </Modal>
  );
}

ModalError.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
