import queryString from "query-string";
import { usePPAnnouncementBannerMutation } from "../../../../../../../api/patientPortal/announcementBanner/usePPAnnouncementBannerMutation";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { extractApiErrorData } from "../../../../../../../utilities/api";

export function useSubmit({ onIntersect, onSuccess }) {
  const bannerId = queryString.parse(window.location.search)?.bannerId;
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();

  const { mutate, isLoading } = usePPAnnouncementBannerMutation({
    onSuccess: () => {
      if (Number(bannerId)) {
        uiNotification.success(t("patientPortal.banner.success.update"));
      } else {
        uiNotification.success(t("patientPortal.banner.success.create"));
      }
      onSuccess();
    },
    onError: (response) => {
      const intersects = extractApiErrorData(response);

      if (intersects?.length) {
        onIntersect(intersects.filter((x) => x.id));
      }

      uiNotification.error(t("patientPortal.banner.error.save"));
    },
  });

  return {
    initiate: mutate,
    isLoading,
  };
}
