import React from "react";

class HeaderTouchMD extends React.Component {
  render() {
    return (
      <div className="header touchMDHeader">
        <div className="wrapper">
          <div className="mdWrapper">
            <span className="logo">
              <img src="/images/logo.png?v=123" alt="" />
            </span>
            <span className="touchMDlogo">
              <img
                alt=""
                src="https://images.squarespace-cdn.com/content/5d71a41af5026a0001fa93f8/1567735388033-TVI087B1Q1HUDYOXES5H/tmd_logo_wh.png?content-type=image%2Fpng"
              />
            </span>
          </div>
          <div className="header-right">
            <ul className="nav"></ul>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderTouchMD;
