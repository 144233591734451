import React from "react";
import { getPathParams } from "../../utils/utils";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import styles from "./ClientInsurance.module.scss";
import cx from "clsx";

export function ClientInsurance() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });

  return (
    <div className={cx(styles.carrierWrapper, "col-xs-12")}>
      <div>
        <div className={styles.title}>Carrier</div>
        <div className={styles.itemText}>{clientData?.patientInsurance?.carrier || ""}</div>
      </div>
      <div>
        <div className={styles.title}>Prescription Card</div>
        <div className={styles.itemText}>{clientData?.patientInsurance?.prescription_card || ""}</div>
      </div>
    </div>
  )
}