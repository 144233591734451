import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { img } from "../../../../../../assets/img";
import classes from "../ViewChargeKlarna.module.scss";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { createKlarnaPayment } from "../../../../../../Actions/Sales/klarnaActions";
import { dispatch } from "../../../../../../store/store";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { useChargeForm } from "../../../hooks/useChargeForm";
import { composeAmountSchema } from "../../../SalesCheckoutInvoice.schemas";
import RemainingBalanceDue from "../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentTipsModal = ({
  isTipsApplying,
  email,
  phoneNumber,
  showTipsModal,
  createKlarnaPayment,
}) => {
  const { invoice, amountToPay } = useInvoice();
  const [isCharging, setIsCharging] = useState(false);

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
  });

  const { form, errors, submit, changeFormValue } = useChargeForm({
    schema,
    onSubmit: async ({ amount }) => {
      setIsCharging(true);

      await createKlarnaPayment({
        requestedAmount: amount,
        invoiceId: invoice.id,
        patientPhone: phoneNumber,
        patientEmail: email,
      });

      setIsCharging(false);
    },
    initialValues: {
      amount: amountToPay || "",
      phoneNumber: invoice?.patient?.phoneNumber || "",
      email: invoice?.patient?.email || "",
    },
  });

  const onClose = () => {
    showTipsModal.update(false);
  };

  useEffect(() => {
    changeFormValue("amount", amountToPay);
  }, [amountToPay]);

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={`${tSales(
        "checkoutInvoice.paymentOptions.klarna",
      )}: ${phoneNumber}`}
      imageSrc={img.klarna}
      imageClassName={classes.titleKlarna}
      onCharge={submit}
      isCharging={isCharging}
      chargeLabel={tCommon("label.continue")}
      amount={form.amount}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal />
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount || ""}
          isError={errors.amount}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {errors.amount && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <RemainingBalanceDue amount={form.amount} />
    </ModalCharge>
  );
};

const mapDispatchToProps = () => {
  return bindActionCreators(
    {
      createKlarnaPayment,
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(PaymentTipsModal);
