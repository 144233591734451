import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";
import {
  useAcknowledgeStickyNoteQuery,
  useDeleteStickyNoteQuery,
  useConvertClientNoteQuery,
  useSnoozeStickyNoteQuery,
} from "../../_legacy/Queries/Notes";
import CustomerNoteWithActions from "./CustomerNoteWithActions";
import { useCheckUpdateUserPrivilege } from "../../hooks/useUpdatePrivilege";
import NoteEditModal from "./NoteEditModal";
import { Button } from "../../shared/Button/Button";
import { useAcknowledgeAll } from "./hook/useAcknowledgeAll";
import { uiNotification } from "../../services/UINotificationService";

const languageData = JSON.parse(localStorage.getItem("languageData"));

const LoaderBackdrop = ({
  isLoading,
  loadingText = languageData && languageData.global.loading_please_wait_text,
}) => {
  return (
    <div
      className={
        isLoading
          ? "new-loader text-left displayBlock items-center "
          : "new-loader text-left"
      }
    >
      <div className="loader-outer">
        <div id="modal-confirm-text" className="popup-subtitle">
          {loadingText}
        </div>
      </div>
    </div>
  );
};

const StickyNotesModal = ({ notes = [], handleClose }) => {
  const filterVisibleNotes = useCallback((notes) => {
    return notes.filter((noteDetails) => {
      const { is_sticky, patient_note_user } = noteDetails;
      const { is_acknowledged, patient_note_user_snooze } =
        patient_note_user || {};
      const { is_snoozed } = patient_note_user_snooze || {};
      return Boolean(is_sticky && !(is_acknowledged || is_snoozed));
    });
  }, []);

  const [clientNotes, setClientNotes] = useState(filterVisibleNotes(notes));

  const [selectedNote, setSelectedNote] = useState(null);

  const editStickyNote = (noteDetails) => {
    setSelectedNote({
      action: "edit",
      noteClass: "setting-textarea-box",
      ...noteDetails,
    });
  };

  const deleteStickyNote = (noteDetails) => {
    setSelectedNote({
      action: "delete",
      ...noteDetails,
    });
  };

  const converToNote = (noteDetails) => {
    setSelectedNote({
      action: "convert",
      ...noteDetails,
    });
  };

  const closeNoteActionModal = () => {
    setSelectedNote(null);
  };

  const { checkIfPermissionAllowed } = useCheckUpdateUserPrivilege();

  const { userCanEdit, userCanDelete } = useMemo(
    () => ({
      userCanEdit: checkIfPermissionAllowed("add-update-customer-notes"),
      userCanDelete: checkIfPermissionAllowed("delete-customer-notes"),
    }),
    [checkIfPermissionAllowed],
  );

  const { mutate: acknowledgeNote, isLoading: isAcknowledgingNote } =
    useAcknowledgeStickyNoteQuery();
  const { mutate: restoreNote, isLoading: isRestoringNote } =
    useConvertClientNoteQuery();
  const { mutate: snoozeNote, isLoading: isSnoozingNote } =
    useSnoozeStickyNoteQuery();
  const { mutate: deleteNote, isLoading: isDeletingNote } =
    useDeleteStickyNoteQuery();

  const removeNoteFromList = useCallback(
    (id) => {
      setClientNotes((currentStickyNotes) =>
        currentStickyNotes.filter(
          (clientNoteDetails) => clientNoteDetails.id !== id,
        ),
      );
    },
    [clientNotes, setClientNotes],
  );

  const confirmDeleteNote = useCallback(() => {
    deleteNote(selectedNote.id, {
      onSuccess: () => {
        removeNoteFromList(selectedNote.id);
        closeNoteActionModal();
      },
    });
  }, [selectedNote, deleteNote, removeNoteFromList]);

  const acknowledgeClientNote = (id) => {
    acknowledgeNote(id, {
      onSuccess: () => removeNoteFromList(id),
    });
  };

  const snoozeClientNote = (payload) => {
    snoozeNote(payload, {
      onSuccess: () => removeNoteFromList(payload.id),
    });
  };

  const restoreClientNote = useCallback(() => {
    restoreNote(selectedNote.id, {
      onSuccess: () => {
        removeNoteFromList(selectedNote.id);
        closeNoteActionModal();
      },
    });
  }, [selectedNote, restoreNote, removeNoteFromList]);

  useEffect(() => {
    if (clientNotes.length === 0) {
      handleClose();
    }
  }, [clientNotes]);

  const patient_id = clientNotes.length > 0 ? clientNotes[0].patient_id : null;

  const { mutate: acknowledgeAll, isLoading: isAcknowledgingAll } =
    useAcknowledgeAll();

  const isLoading = useMemo(
    () =>
      Boolean(
        isAcknowledgingNote ||
          isRestoringNote ||
          isSnoozingNote ||
          isAcknowledgingAll,
      ),
    [isAcknowledgingNote, isRestoringNote, isSnoozingNote, isAcknowledgingAll],
  );

  const handleAcknowledgeAll = useCallback(() => {
    acknowledgeAll(
      { patient_id },
      {
        onSuccess: () => {
          setClientNotes([]);
          uiNotification.success("Acknowledged with success");
          handleClose();
        },
      },
    );
  }, [acknowledgeAll, patient_id]);

  return (
    <ConfirmModal
      isOpen={clientNotes.length > 0}
      title={
        <div className="headerSticky">
          <span>Sticky Notes</span>
          <Button
            size="small"
            className="acknowledgeBtn"
            onClick={handleAcknowledgeAll}
            disabled={isLoading}
          >
            Acknowledge all
          </Button>
        </div>
      }
      className="sticky-notes-modal confirm-modal-center"
      headerClassName={"sticky-notes-modal-head"}
    >
      <div>
        <LoaderBackdrop isLoading={isLoading} />
        <div className="displayBlock">
          {clientNotes.map((noteDetails) => (
            <CustomerNoteWithActions
              noteobj={noteDetails}
              languageData={languageData}
              noteidx={noteDetails.id}
              key={noteDetails.id}
              editNote={editStickyNote}
              deleteNote={deleteStickyNote}
              userCanEdit={userCanEdit}
              userCanDelete={userCanDelete}
              acknowledgeNote={acknowledgeClientNote}
              snoozeNote={snoozeClientNote}
              restoreNote={converToNote}
            />
          ))}
        </div>
        {selectedNote && selectedNote.action === "edit" && (
          <NoteEditModal
            noteDetails={selectedNote}
            closeNoteActionModal={closeNoteActionModal}
            updateClientNotesList={setClientNotes}
          />
        )}
        {selectedNote && selectedNote.action === "delete" && (
          <ConfirmModal
            className="confirm-modal-center"
            isOpen
            onConfirm={confirmDeleteNote}
            onCancel={closeNoteActionModal}
            onClose={closeNoteActionModal}
            confirmTitle="Delete"
          >
            <>
              Are you sure you want to delete this note ?
              <br />
              <div className="delete-note-confirmation-display">
                {`"${selectedNote.notes}"`}
              </div>
              <LoaderBackdrop
                isLoading={isDeletingNote}
                loadingText="Deleting Note. Please wait..."
              />
            </>
          </ConfirmModal>
        )}

        {selectedNote && selectedNote.action === "convert" && (
          <ConfirmModal
            className="confirm-modal-center"
            isOpen
            onConfirm={restoreClientNote}
            onCancel={closeNoteActionModal}
            onClose={closeNoteActionModal}
            confirmTitle="Confirm"
          >
            <>
              Are you sure you want to convert this sticky note to a normal
              note?
              <br />
              <div className="delete-note-confirmation-display">
                {`"${selectedNote.notes}"`}
              </div>
              <LoaderBackdrop
                isLoading={isRestoringNote}
                loadingText="Converting Note. Please wait..."
              />
            </>
          </ConfirmModal>
        )}
      </div>
    </ConfirmModal>
  );
};

export default StickyNotesModal;
