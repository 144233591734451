import React, { Component } from "react";
import { Link } from "react-router-dom";
import { checkIfPermissionAllowed } from "../../Utils/services.js";

class InventoryHeader extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      showLoadingText: false,
      activeMenuTag: "",
    };
  }

  static getDerivedStateFromProps(props) {
    let returnState = {};
    if (props.activeMenuTag != undefined) {
      returnState.activeMenuTag = props.activeMenuTag;
    }
    return returnState;
  }

  render() {
    return (
      <div>
        <ul className="sub-menu">
          {checkIfPermissionAllowed("view-products-inventory") && (
            <li>
              <Link
                to="/inventory/products/active"
                className={
                  this.state.activeMenuTag == "products-active" ? "active" : ""
                }
                name="ProductsActive"
              >
                {this.state.inventoryLang.inventory_Products_Active}
              </Link>
            </li>
          )}

          {checkIfPermissionAllowed("view-products-inventory") && (
            <li>
              <Link
                to="/inventory/products/inactive"
                className={
                  this.state.activeMenuTag == "products-inactive"
                    ? "active"
                    : ""
                }
                name="ProductsInactive"
              >
                {this.state.inventoryLang.ar_products_directory}
              </Link>
            </li>
          )}

          {checkIfPermissionAllowed("view-product-categories") && (
            <li>
              <Link
                to="/inventory/products-categories"
                className={
                  this.state.activeMenuTag == "products-categories"
                    ? "active"
                    : ""
                }
                name="categories"
              >
                {this.state.inventoryLang.inventory_Categories}
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export default InventoryHeader;
