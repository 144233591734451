import React from "react";

const DownloadAgreementIcon = () => {
  return (
    <svg width="17" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.661 15.073h5.202c.392 0 .717-.354.717-.781 0-.427-.325-.771-.717-.771H5.661c-.392 0-.717.344-.717.77 0 .428.325.782.717.782Zm3.232-6.76H5.661c-.392 0-.717.354-.717.78 0 .428.325.772.717.772h3.232c.392 0 .717-.344.717-.771 0-.427-.325-.782-.717-.782Zm6.824-.911c.225-.003.47-.006.693-.006.238 0 .43.208.43.469v8.375c0 2.583-1.922 4.677-4.294 4.677H4.934c-2.486 0-4.513-2.198-4.513-4.907V4.781C.42 2.198 2.352.083 4.733.083H9.84c.248 0 .44.22.44.48v3.354c0 1.906 1.444 3.468 3.194 3.479.409 0 .769.003 1.084.006.245.002.464.004.656.004.136 0 .313-.002.503-.004Zm.264-1.52c-.786.002-1.712 0-2.379-.008-1.058 0-1.929-.949-1.929-2.101V1.027c0-.449.5-.672.786-.348a19619.963 19619.963 0 0 1 3.85 4.362c.28.315.075.84-.328.84Z"
        fill="#fff"
      />
    </svg>
  );
};

export default DownloadAgreementIcon;
