import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export const useUsersQuery = () => {
  return useQuery(["usersQuery"], async () => {
    const res = await http.get(HTTP_ENDPOINTS.getAllUsers());
    const composeResponse = res.data.data;
    return composeResponse;
  });
};
