import React from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { useCurrentUserQuery } from "../../../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../../../consts/general";
import { Skeleton } from "../../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../../shared/Empty/Empty";
import { Table as SharedTable } from "../../../../../../../../shared/Table/Table";
import { ContentLoader } from "../../../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import styles from "./styles.module.scss";
import { Box } from "../../../../../../../../shared/Box/Box";
import { Maybe, pipe } from "../../../../../../../../utilities/fp";
import { phoneUtil } from "../../../../../../../../utilities/phone";

/**
 * @param {object} param0
 *
 * @param {{
 *  value: Array<{ [key:string]: string | number }>;
 *  isLoading: boolean;
 *  isSoftLoading: boolean;
 *  hasMore: boolean;
 *  fetchMore: () => void;
 *  isNextFetching: boolean;
 * }} param0.data
 */
export const Table = ({ data }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const tableData = data.value.map((i) => ({
    key: i.id,

    patient: Maybe.of(i.patient)
      .map((x) => x.full_name)
      .orElse(tCommon("symbol.longDash"))
      .value(),

    faxNumber: pipe(
      i.fax_number,
      phoneUtil.compose,
      phoneUtil.formatWithDashes,
    ),

    date: moment(i.created_at).format(
      user?.previewDateFormat || PREVIEW_DATE_FORMAT,
    ),

    direction: tSettings(`eFaxUsageLog.direction.${i.direction}`),

    pages: i.pages,

    status: tSettings(`eFaxUsageLog.status.${i.status}`),
  }));

  return (
    <Box className={styles.root}>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={tableData}
            cols={[
              {
                data: tSettings("eFaxUsageLog.table.patient"),
                accessor: "patient",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("eFaxUsageLog.table.faxNumber"),
                accessor: "faxNumber",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("eFaxUsageLog.table.date"),
                accessor: "date",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("eFaxUsageLog.table.direction"),
                accessor: "direction",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("eFaxUsageLog.table.pages"),
                accessor: "pages",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("eFaxUsageLog.table.status"),
                accessor: "status",
                headColClassName: styles.colMin,
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
};
