import React from "react";
import PropTypes from "prop-types";
import { showFormattedDate } from "../../../../../Utils/services";

const PaperworkTableRow = ({
  item,
  selected,
  handlChecked,
}) => {
  return (
    <tr className="table-updated-tr">
      <td className="col-xs-0 table-checkbox table-updated-td checkbox-recently-deleted">
        <input
          type="checkbox"
          name="innerbox[]"
          className="checkbox-recently-deleted"
          checked={selected.indexOf(item.id) > -1}
          value={selected.indexOf(item.id) > -1}
          id={`deleted-${item.id}`}
          onChange={(e) => handlChecked(item.id, e)}
        />
      </td>
      <td className="col-xs-6 table-updated-td">
        {item.title || ""}
      </td>
      <td className="col-xs-6 table-updated-td p-l-5 p-r-0 break-all">
        {showFormattedDate(item.deleted_at, true)}
      </td>
    </tr>
  );
}

PaperworkTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  handlChecked: PropTypes.func.isRequired,
}

export default PaperworkTableRow;
