import { EVENT_TYPES, ACTION_TYPES } from "./consts";

const initialState = {
  eventType: EVENT_TYPES.appointment,
  isSubmitActive: true,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.eventTypeChange: {
      return {
        ...state,
        eventType: action.payload.eventType || null,
      };
    }
    case ACTION_TYPES.isSubmitActiveChange: {
      return {
        ...state,
        isSubmitActive: action.payload.status,
      };
    }
    default: {
      return state;
    }
  }
}
