import React, { useRef, useState } from "react";
import cx from "clsx";
import { Button } from "../../../shared/Button/Button";
import classes from "../scss/downloadDropdown.module.scss";
import { useClickOutside } from "../../../utilities/hooks/useClickOutside";
import { useExportReport } from "../BusinessInsightsContent/Reports/SalesSummary/hooks/useExportReport";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";

const EXPORT_TYPES = {
  xlsx: "xlsx",
  csv: "csv",
};

const EXPORT_LABEL = {
  excel: "Excel",
  csv: "CSV",
};

const DownloadDropdown = ({ params, downloadRoute }) => {
  const [showDownloadList, setShowDownloadList] = useState(false);
  const downloadList = useRef(null);

  useClickOutside(downloadList, () => setShowDownloadList(false));

  const { refetch: downloadExcel, isFetching: isDownloadingExcel } =
    useExportReport(
      downloadRoute,
      { ...params, exportType: EXPORT_TYPES.xlsx },
      { enabled: false },
    );
  const { refetch: downloadCSV, isFetching: isDownloadingCSV } =
    useExportReport(downloadRoute, { ...params, exportType: EXPORT_TYPES.csv });

  const isDownloading = isDownloadingExcel || isDownloadingCSV;

  const handleDownload = (type) => {
    if (type === EXPORT_TYPES.xlsx) {
      downloadExcel();
    } else if (type === EXPORT_TYPES.csv) {
      downloadCSV();
    }
    setShowDownloadList(false);
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        onClick={() => setShowDownloadList(!showDownloadList)}
        isDisabled={isDownloading}
        leftAdornment={
          isDownloading ? <CircularProgress size="small" /> : undefined
        }
      >
        Download
      </Button>
      {showDownloadList && (
        <div className={cx(classes.downloadOptions)} ref={downloadList}>
          <ul>
            <li>
              <button onClick={() => handleDownload(EXPORT_TYPES.xlsx)}>
                {EXPORT_LABEL.excel}
              </button>
            </li>
            <li>
              <button onClick={() => handleDownload(EXPORT_TYPES.csv)}>
                {EXPORT_LABEL.csv}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DownloadDropdown;
