export const REFUND_TO_KEYS = {
  wallet: "wallet",
  default: "default",
};

export const PAYMENT_MODE_KEYS = {
  cc: "cc",
  wallet: "wallet",
  check: "check",
  careCredit: "care_credit",
  greenSky: "greensky",
  giftCard: "gift_card",
};
