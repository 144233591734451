import React from "react";
import { Checkbox } from "../../../../../../../../../shared/Checkbox/Checkbox";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { QuestionChoices } from "../../../../../../boxes/QuestionChoices/QuestionChoices";
import { passOr } from "../../../../../../../../../utilities/general";

export function MultiText({ isMultipleSelection, textChoices }) {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <>
      <Checkbox
        label={tSettings("questionnaires.questionBuilder.multiSelection")}
        isChecked={isMultipleSelection.value}
        onChange={isMultipleSelection.toggle}
      />
      <QuestionChoices
        choices={textChoices.value}
        onAdd={textChoices.add}
        onSortEnd={textChoices.replace}
      >
        {(choice, index) => (
          <QuestionChoices.Text
            key={index}
            index={index}
            value={choice.text}
            onChange={(e) =>
              textChoices.changeByIndex({
                key: "text",
                value: e.target.value,
                index,
              })
            }
            onRemove={passOr(
              index !== 0,
              undefined,
              () => () => textChoices.deleteByIndex(index),
            )}
          />
        )}
      </QuestionChoices>
    </>
  );
}
