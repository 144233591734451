import { positionFooterCorrectly } from "../../Utils/services.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getSyncData() {
  return (dispatch) => {
    http
      .get("get-touch-md")
      .then((response) => {
        dispatch({ type: "GET_SYNC_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_SYNC_DATA", payload: error.response.data });
      });
  };
}

export function setSyncConfiguration(formData) {
  return (dispatch) => {
    http
      .put("save-touch-md", formData)
      .then((response) => {
        dispatch({ type: "SET_SYNC_CONFIG", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SET_SYNC_CONFIG", payload: error.response.data });
      });
  };
}

export function getEditSyncData() {
  return (dispatch) => {
    http
      .get("get-touch-md")
      .then((response) => {
        dispatch({ type: "GET_EDIT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_EDIT_DATA", payload: error.response.data });
      });
  };
}

export function getZohoData() {
  return (dispatch) => {
    http
      .get("zoho-auth")
      .then((response) => {
        dispatch({ type: "GET_ZOHO_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ZOHO_DATA", payload: error.response.data });
      });
  };
}

export function shareIntegrationData(formData) {
  return (dispatch) => {
    http
      .post("zoho-save-details", formData)
      .then((response) => {
        dispatch({ type: "SHARE_ZOHO_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SHARE_ZOHO_DATA", payload: error.response.data });
      });
  };
}

export function getShopifyData() {
  return (dispatch) => {
    http
      .get("shopify/get-details")
      .then((response) => {
        dispatch({ type: "GET_SHOPIFY_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_SHOPIFY_DATA", payload: error.response.data });
      });
  };
}

export function shareIntegrationShopifyData(formData) {
  return async (dispatch) => {
    return http
      .post("shopify/save-details", formData)
      .then((response) => {
        dispatch({ type: "SHARE_SHOPIFY_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        uiNotification.error("Something went wrong. Try again later");
        dispatch({ type: "SHARE_SHOPIFY_DATA", payload: error.response.data });
        return Promise.reject(error.response?.data || {});
      });
  };
}

export function getHubspotData() {
  return async (dispatch) => {
    return http
      .get("hubspot-auth")
      .then((response) => {
        dispatch({ type: "GET_HUBSPOT_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_HUBSPOT_DATA", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function shareIntegrationHubspotData(formData) {
  return async (dispatch) => {
    return http
      .post("hubspot-save-details", formData)
      .then((response) => {
        dispatch({ type: "SHARE_HUBSPOT_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SHARE_HUBSPOT_DATA", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function getMailchimpData() {
  return async (dispatch) => {
    return http
      .get("mailchimp-auth")
      .then((response) => {
        dispatch({ type: "GET_MAILCHIMP_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_MAILCHIMP_DATA", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function shareIntegrationMailchimpData(formData) {
  return (dispatch) => {
    http
      .post("mailchimp-save-details", formData)
      .then((response) => {
        dispatch({ type: "SHARE_MAILCHIMP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SHARE_MAILCHIMP_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getActiveCampaignData() {
  return (dispatch) => {
    http
      .get("active-campaign-auth")
      .then((response) => {
        dispatch({ type: "GET_ACTIVE_COMPAIGN_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_ACTIVE_COMPAIGN_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function shareIntegrationActiveCampaignData(formData) {
  return (dispatch) => {
    http
      .post("active-campaign-save-details", formData)
      .then((response) => {
        dispatch({
          type: "SHARE_ACTIVE_COMPAIGN_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SHARE_ACTIVE_COMPAIGN_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getConstantContactData() {
  return (dispatch) => {
    http
      .get("constant-contact-auth")
      .then((response) => {
        dispatch({ type: "GET_CONSTANT_CONTACT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CONSTANT_CONTACT_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function shareIntegrationConstantContactData(formData) {
  return (dispatch) => {
    http
      .post("constant-contact-save-details", formData)
      .then((response) => {
        dispatch({
          type: "SHARE_CONSTANT_CONTACT_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SHARE_CONSTANT_CONTACT_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function getZapierDetails() {
  return (dispatch) => {
    http
      .get("zapier/authorize_detail")
      .then((response) => {
        dispatch({ type: "GET_ZAPIER_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ZAPIER_DATA", payload: error.response.data });
      });
  };
}

export function switchONZapier(formData) {
  return (dispatch) => {
    http
      .post("zapier/authorize", formData)
      .then((response) => {
        dispatch({ type: "SWITCH_ZAPIER_ON", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SWITCH_ZAPIER_ON", payload: error.response.data });
      });
  };
}

export function switchOFFZapier(formData) {
  return (dispatch) => {
    http
      .post("zapier/authorize", formData)
      .then((response) => {
        dispatch({ type: "SWITCH_ZAPIER_OFF", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SWITCH_ZAPIER_OFF", payload: error.response.data });
      });
  };
}

export function changeRefreshStatus(formData) {
  return (dispatch) => {
    http
      .post("zapier/authorize", formData)
      .then((response) => {
        dispatch({
          type: "CHANGE_ZAPIER_REFRESH_CREDS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_ZAPIER_REFRESH_CREDS",
          payload: error.response.data,
        });
      });
  };
}

export function getCherryConfig() {
  return (dispatch) => {
    http
      .get("cherry-config")
      .then((response) => {
        dispatch({ type: "GET_CHERRY_CONFIG", payload: response.data });
      })
      .catch((error) => {
        uiNotification.error(error.response.data.message);
        dispatch({ type: "GET_CHERRY_CONFIG", payload: error.response.data });
      });
  };
}

export function putCherryConfig(params) {
  return (dispatch) => {
    http
      .put("cherry-config", {
        cherryConfig: params,
      })
      .then((response) => {
        if (response.data.data.is_connected) {
          uiNotification.success(`Cherry integration successful!`);
        } else {
          uiNotification.error(
            `Couldn't connect to Cherry. Please check you API Key!`,
          );
        }
        dispatch({ type: "PUT_CHERRY_CONFIG", payload: response.data });
      })
      .catch((error) => {
        uiNotification.error(error.response.data.message);
        dispatch({ type: "PUT_CHERRY_CONFIG", payload: error.response.data });
      });
  };
}

export function patchCherryConfig(params) {
  return (dispatch) => {
    http
      .patch("cherry-config", {
        cherryConfig: params,
      })
      .then((response) => {
        if (!params.hasOwnProperty("clinics")) {
          uiNotification.info(
            response.data.data.is_active
              ? "Cherry is enabled!"
              : "Cherry is disabled!",
          );
        } else {
          uiNotification.success("Cherry clinics updated!");
        }
        dispatch({ type: "PATCH_CHERRY_CONFIG", payload: response.data });
      })
      .catch((error) => {
        uiNotification.error(error.response.data.message);
        dispatch({ type: "PATCH_CHERRY_CONFIG", payload: error.response.data });
      });
  };
}
