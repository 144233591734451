import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { questionnaireSchema } from "./schemas";

const requestSchema = yup.object({
  id: yup.number().required(),
});

const responseSchema = questionnaireSchema.concat(
  yup.object({
    assigned_services: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          name: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res) => {
  return res?.data?.data || {};
};

function createQuestionnaireQueryKey(id) {
  return [QUERY_KEYS.questionnaires.getOne, id];
}

export function useQuestionnaireQuery({ payload = {}, options = {} } = {}) {
  return useQuery(
    createQuestionnaireQueryKey(payload.id),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.questionnaires.getOne(req.id));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
