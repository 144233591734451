import React from "react";
import { Table } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { Box } from "../../../../../../shared/Box/Box";
import { Action } from "../../shared/Action";
import { getDiffs } from "../../utils";

/**
 * @param {Object} param0
 * @param {Array<{
 *    id: number;
 *    added_by: string;
 *    last_edited_by: string;
 *    consent_large_description?: string;
 *    consent_small_description?: string;
 *    consent_name: string;
 *    action: string;
 *    initials_per_page: number;
 *    witness_signature: number;
 * }>} param0.diff
 */
export function ConsentsDiff({ diff }) {
  const { tCommon } = useAppTranslation.Common();
  const { tDashboard } = useAppTranslation.Dashbaord();
  const action = diff[0].action;

  const { diff1, diff2 } = getDiffs(diff, action);

  const numberToYesNo = (number) => {
    switch (number) {
      case 1:
        return tCommon("label.yes");
      case 0:
        return tCommon("label.no");
      default:
        return "";
    }
  };

  return (
    <Box className={styles.root}>
      <Action
        type={action}
        action={tDashboard(`user_logs_diff.consents.action.${action}`)}
      />
      <Table
        cols={[
          {
            data: "",
            accessor: "field",
            className: styles.field,
            headColClassName: styles.fieldCol,
          },
          {
            data: tDashboard("user_logs_diff.before"),
            accessor: "old",
            headColClassName: styles.diffCol,
            className: styles.diff,
          },
          {
            data: tDashboard("user_logs_diff.after"),
            accessor: "new",
            headColClassName: styles.diffCol,
            className: styles.diff,
          },
        ]}
        data={[
          {
            key: "name",
            field: tDashboard("user_logs_diff.consents.name"),
            old: diff1.consent_name,
            new: diff2.consent_name,
          },
          {
            key: "large_description",
            field: tDashboard("user_logs_diff.consents.large_description"),
            old: diff1.consent_large_description,
            new: diff2.consent_large_description,
          },
          {
            key: "added_by",
            field: tDashboard("user_logs_diff.consents.added_by"),
            old: diff1.added_by,
            new: diff2.added_by,
          },
          {
            key: "last_edited_by",
            field: tDashboard("user_logs_diff.consents.last_edited_by"),
            old: diff1.last_edited_by,
            new: diff2.last_edited_by,
          },
          {
            key: "initials_per_page",
            field: tDashboard("user_logs_diff.consents.initials_per_page"),
            old: numberToYesNo(diff1.initials_per_page),
            new: numberToYesNo(diff2.initials_per_page),
          },
          {
            key: "witness_signature",
            field: tDashboard("user_logs_diff.consents.witness_signature"),
            old: numberToYesNo(diff1.witness_signature),
            new: numberToYesNo(diff2.witness_signature),
          },
        ]}
      />
    </Box>
  );
}
