import React, { useState } from "react";
import { Trans } from "react-i18next";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { ReportHeader } from "../../../../../../BusinessInsights/components/ReportHeader/ReportHeader";
import DownloadDropdown from "../../../../../components/DownloadDropdown";
import { HTTP_ENDPOINTS } from "../../../../../../../consts/api";
import { Button } from "../../../../../../../shared/Button/Button";
import { svg } from "../../../../../../../assets/svg";
import { Modal } from "../../../../../../../shared/Modal/Modal";
import classes from "../../../../../newBusinessInsightsContent.module.scss";

export const Header = ({ requestParams, modalInfo }) => {
  const { tBi } = useAppTranslation.BusinessInsights();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <ReportHeader
      withBackBtn
      media="small"
      label={tBi("shortTermLiabilityReport.label")}
      left={
        <Button
          size="small"
          variant="text"
          onClick={() => setIsInfoModalOpen(true)}
          className={classes.infoButton}
        >
          <img src={svg.infoPrimary} alt="info" width="20px" />
        </Button>
      }
    >
      {modalInfo && (
        <Button
          size="small"
          variant="text"
          onClick={() => setIsInfoModalOpen(true)}
        >
          <img src={svg.infoPrimary} alt="info" width="20px" />
        </Button>
      )}
      <DownloadDropdown
        params={requestParams}
        downloadRoute={HTTP_ENDPOINTS.getShortTermLiability()}
      />
      <Modal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        header={<Modal.Title>{tBi(modalInfo?.title)}</Modal.Title>}
        // contentClassName={classes.infoModalContent}
      >
        {modalInfo?.rows.map((key) => (
          <p key={key}>
            <Trans
              i18nKey={tBi(key)}
              components={{
                b: <b />,
              }}
            />
          </p>
        ))}
      </Modal>
    </ReportHeader>
  );
};
