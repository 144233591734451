import React, { useState } from "react";
import moment from "moment";
import classes from "./PatientDocumentInfo.module.scss";
import { useAppointmentQuery } from "../../../../api/queries/useAppointmentQuery";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { PREVIEW_TIME_FORMAT } from "../../../../consts/general";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Modal } from "../../../../shared/Modal/Modal";
import { useProcedureQuery } from "../../../../api/procedure/useProcedureQuery";
import { Patient } from "./shared/Patient/Patient";
import { Section } from "./shared/Section/Section";
import { Button } from "../../../../shared/Button/Button";
import { InfoCircleIcon } from "../../../../assets/Icons/InfoCircleIcon";
import { Maybe } from "../../../../utilities/fp";
import { capitalize } from "../../../../utilities/general";

export function PatientDocumentInfo({ patientId, appointmentId, procedureId }) {
  const { data: user } = useCurrentUserQuery();
  const { tCommon } = useAppTranslation.Common();
  const [isOpen, setIsOpen] = useState(false);

  const appointment = useAppointmentQuery({
    payload: {
      appointmentId,
    },
    options: {
      enabled: Boolean(appointmentId),
    },
  });

  const procedure = useProcedureQuery({
    payload: {
      procedureId,
    },
    options: {
      enabled: Boolean(procedureId),
    },
  });

  const appointmentTypeText = (() => {
    const type = tCommon(
      `appointmentType.${appointment.data?.appointmentType}`,
    );
    const addition = appointment.data?.isOutOfOffice
      ? `, ${tCommon("label.outOfOffice")}`
      : "";
    return `${type}${addition}`;
  })();

  return (
    <>
      <Button variant="text" size="small" onClick={() => setIsOpen(true)}>
        <InfoCircleIcon width="20px" />
      </Button>
      {isOpen && (
        <Modal
          size="small"
          isOpen
          onClose={() => setIsOpen(false)}
          contentClassName={classes.modal}
        >
          <Patient id={patientId} />
          {Boolean(appointmentId) && (
            <Section
              label={tCommon("label.appointment")}
              isLoading={appointment.isLoading}
              options={[
                {
                  label: tCommon("label.services"),
                  value:
                    appointment.data?.services
                      ?.map((service) => service.name)
                      ?.join(", ") || null,
                },
                {
                  label: tCommon("label.type"),
                  value: appointmentTypeText,
                },
                {
                  label: tCommon("label.clinic"),
                  value: appointment.data?.clinic?.name,
                },
                {
                  label: tCommon("label.provider"),
                  value: appointment.data?.provider?.name,
                },
                {
                  label: tCommon("label.date"),
                  value: moment(appointment.data?.date).format(
                    `${user?.previewDateFormat} ${user?.timeFormat}` ||
                      PREVIEW_TIME_FORMAT,
                  ),
                },
              ]}
            />
          )}
          {Boolean(procedureId) && (
            <Section
              label={tCommon("label.procedure")}
              isLoading={procedure.isLoading}
              options={[
                {
                  label: tCommon("label.name"),
                  value: procedure.data?.name,
                },
                {
                  label: tCommon("label.type"),
                  value: Maybe.of(procedure.data?.procedureType)
                    .map(capitalize)
                    .orElse(undefined)
                    .value(),
                },
                {
                  label: tCommon("label.providerName"),
                  value: procedure.data?.doctorName,
                },
              ]}
            />
          )}
        </Modal>
      )}
    </>
  );
}
