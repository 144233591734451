import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  globalSettings: state.globalSettings.globalSettings,
});

export const withGlobalSettings = (Component) => {
  const Wrapper = (props) => {
    return <Component {...props} />;
  };

  return connect(mapStateToProps)(Wrapper);
};
