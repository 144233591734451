import React, {useEffect, useState} from "react";
import _ from "lodash/fp";
import { useForm, Controller } from "react-hook-form";
import { Scrollbars } from "react-custom-scrollbars";
import { Input, Select, CheckBox, RadioButton } from '../../../Common/ReactHookForm'
import Cleave from "cleave.js/dist/cleave-react";
import 'cleave.js/dist/addons/cleave-phone.us';


const FormStep2 = (props) => {
  const { handleFormStepChange, formValue, resources, merchantNumber } = props;
  const { register, handleSubmit, watch, errors, control, getValues, setValue } = useForm({ defaultValues: formValue });
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  const onSubmit = data => {
    data.phones = []
    if(data.business_phone) {
      let phoneNumberArr = data.business_phone.split(' ');
      let areaCode = ''
      let phoneNumber = ''
      if(phoneNumberArr.length > 1) {
        areaCode = phoneNumberArr[0];
        phoneNumber = phoneNumberArr[1] + phoneNumberArr[2];
      } else {
        areaCode = data.business_phone.substring(0,3);
        phoneNumber = data.business_phone.substring(3);
      }
      data.phones.push({phoneTypeCodeID: watch('phoneTypeCodeID') , areaCode: areaCode, phoneNumber: phoneNumber, extension:""})
    }
    

    if(data.is_mailing_address_sameas_business == "true") {
      data.mailing_address = {
        "line1": data.line1,
        "city": data.city,
        "stateCode": data.stateCode,
        "zip": data.zip,
        "countrycode": 840
      }
    } else {
      data.mailing_address = {
        line1: watch('mailing_line1'),
        city: watch('mailing_city'),
        stateCode: watch('mailing_stateCode'),
        zip: watch('mailing_zip'),
        countrycode: 840,
      }
    }
    handleFormStepChange(3, data)
    //alert(JSON.stringify(data));
  }; // your form submit function which will invoke after successful validation


  
  useEffect(() => {
    if(watch('companyTypeId') != 1) {
      setValue('tinTypeID', '2');
    } else {
      setValue('tinTypeID', '1');
    }
  }, [watch('companyTypeId')])  

  const getStateOptions = (options) => {
    if(options && options.length > 0) {
      let finalOpt = options.map((obj, idx) => {
        return {value: obj.stateCode, label: obj.stateName}
      })
      return finalOpt;
    }
  }
  const getPreviousProcessors = (options) => {
    if(options && options.length > 0) {
      let finalOpt = options.map((obj, idx) => {
        return {value: obj.id, label: obj.description}
      })
      return finalOpt;
    }
  }

  const handleBack = () => {
    handleFormStepChange(1, getValues());
  }

  const getContactTypes = (types) => {
    if(types && types.length > 0) {
      let options = []
      types.map((obj, idx) => {
        if(obj.id == 0) 
          return false;

        options.push({value: obj.id, label: obj.description})
      })
      return options;
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="setting-setion">
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={window.innerHeight-240}
        className="full-width"
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
      >
      <div className="setting-container p20">
			
			<a className="easy-link p-l-0" onClick={() => handleBack()}><i className="fa fa-angle-left m-r-5"></i> Go Back</a>
      {merchantNumber != '' && <a className="easy-link p-l-0 pull-right"> Merchant Id - {merchantNumber}</a>}
        
          <div className="merchant-title">Merchant Application</div>
          <p className="p-text m-b-20">Welcome to the merchant facing application! Thank you for taking the time to help speed up your company's boarding process. Please fill out the application below to the best of your ability and click send when you're finished. This will alert your local rep.</p>

          <div className="settings-subtitle m-b-20 merchantSteps">1 out of 4: General Business Information </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Business Name<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.dbaName) ? 'field_error' : ''}`}
                  placeholder="Business Name"
                  name='dbaName'
                  register={register}
                  isRequired={true}
                  maxLength={120}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Business Address<span className="setting-require">*</span></div>
                <Input
                  className={`newInputField ${(errors && errors.line1) ? 'field_error' : ''}`}
                  placeholder="Business Address"
                  name='line1'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  maxLength={60}
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">City<span className="setting-require">*</span></div>
                <Input
                  className={`newInputField ${(errors && errors.city) ? 'field_error' : ''}`}
                  placeholder="City"
                  name='city'
                  register={register}
                  isRequired={true}
                  maxLength={120}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">State<span className="setting-require">*</span></div>
                <Select
                  className={`newSelectField ${(errors && errors.stateCode) ? 'field_error' : ''}`}
                  name='stateCode'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  options = {getStateOptions(resources.StateOptions)}
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Zip Code<span className="setting-require">*</span></div>
                <Input
                  className={`newInputField ${(errors && errors.zip) ? 'field_error' : ''}`}
                  placeholder="Zip Code"
                  name='zip'
                  register={register}
                  isRequired={true}
                  maxLength={10}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-3 col-xs-12">
              <div className="newInputFileldOuter businessNote">
                <div className="newInputLabel">Phone Type<span className="setting-require">*</span></div>
                <Select
                  className={`newSelectField ${(errors && errors.phoneTypeCodeID) ? 'field_error' : ''}`}
                  name='phoneTypeCodeID'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  options = {[{value:1, label: 'Mobile'}, {value: 5, label: 'Office'}]}
                />
              </div>
            </div>
            <div className="col-sm-5 col-xs-12">
              <div className="newInputFileldOuter businessNote">
                <div className="newInputLabel">Business Phone Number<span className="setting-require">*</span></div>
                <Controller
                    name="business_phone"
                    className={`newInputField ${(errors && errors.business_phone) ? 'field_error' : ''}`}
                    control={control}
                    errors={errors}
                    as={
                      <Cleave
                        placeholder="111 222 0000"
                        options={ {phone: true, phoneRegionCode: 'US'}}
                      />
                    }
                    rules={{ 
                      required: true, 
                      validate: value => value.split(' ').join('').length == 10  
                    }}
                  />
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Business Email Address<span className="setting-require">*</span></div>
                <Input
                  className={`newInputField ${(errors && errors.emailAddress) ? 'field_error' : ''}`}
                  placeholder="Business Email Address"
                  name='emailAddress'
                  register={register}
                  isRequired={true}
                  maxLength={50}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Business Web Address or Social Media URL</div>
                <Input
                  className={`newInputField ${(errors && errors.webSite) ? 'field_error' : ''}`}
                  placeholder="Business Web Address or Social Media URL"
                  name='webSite'
                  register={register}
                  isRequired={false}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
          </div>

          <div className="m-t-10 customRadioOuter">
                <React.Fragment>
                  <input 
                    type="radio"
                    defaultValue={formValue.is_mailing_address_sameas_business}
                    className={`customRadio green`}
                    name={'is_mailing_address_sameas_business'}
                    value={true}
                    id={'is_mailing_address_sameas_business_1'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <input 
                    type="radio"
                    defaultValue={formValue.is_mailing_address_sameas_business}
                    className={`customRadio red`}
                    name={'is_mailing_address_sameas_business'}
                    value={false}
                    id={'is_mailing_address_sameas_business_2'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <label className="m-t-5">{"My mailing address is the same as above."}</label>
                </React.Fragment>
              
          </div>

          <div className={((watch('is_mailing_address_sameas_business') == "false") ? "row" : "no-display")}>
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Business Address<span className="setting-require">*</span></div>
                <Input
                  className={`newInputField ${(errors && errors.line1) ? 'field_error' : ''}`}
                  placeholder="Business Address"
                  name='mailing_line1'
                  register={register}
                  isRequired={(watch('is_mailing_address_sameas_business') == "false") ? true : false}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">City<span className="setting-require">*</span></div>
                <Input
                  className={`newInputField ${(errors && errors.city) ? 'field_error' : ''}`}
                  placeholder="City"
                  name='mailing_city'
                  register={register}
                  isRequired={(watch('is_mailing_address_sameas_business') == "false") ? true : false}
                  maxLength={120}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">State<span className="setting-require">*</span></div>
                <Select
                  className={`newSelectField ${(errors && errors.stateCode) ? 'field_error' : ''}`}
                  name='mailing_stateCode'
                  register={register}
                  isRequired={(watch('is_mailing_address_sameas_business') == "false") ? true : false}
                  errors={errors}
                  control={control}
                  options = {getStateOptions(resources.StateOptions)}
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Zip Code<span className="setting-require">*</span></div>
                <Input
                  className={`newInputField ${(errors && errors.zip) ? 'field_error' : ''}`}
                  placeholder="Zip Code"
                  name='mailing_zip'
                  register={register}
                  isRequired={(watch('is_mailing_address_sameas_business') == "false") ? true : false}
                  maxLength={10}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
          </div>

          <div className="m-t-10 customRadioOuter">
            <React.Fragment>
                  <input type="radio"
                    
                    className={`customRadio green`}
                    name={'acceptsPaperStatements'}
                    value={true}
                    id={'acceptsPaperStatements_1'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <input type="radio"
                    
                    className={`customRadio red`}
                    name={'acceptsPaperStatements'}
                    value={false}
                    id={'acceptsPaperStatements_2'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <label className="m-t-5">{"I'd like to receive my statements online only."}</label>
                </React.Fragment>
              
          </div>

          <div className="m-t-10 customRadioOuter">
                <React.Fragment>
                  <input 
                    type="radio"                    
                    className={`customRadio green`}
                    name={'acceptsPaperTaxForms'}
                    value={true}
                    id={'acceptsPaperTaxForms_1'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <input type="radio"
                    
                    className={`customRadio red`}
                    name={'acceptsPaperTaxForms'}
                    value={false}
                    id={'acceptsPaperTaxForms_2'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <label className="m-t-5">{"I'd like to receive my tax form online only."}</label>
                </React.Fragment>
          </div>

          <div className="settings-subtitle m-b-10 m-t-20">Legal Information</div>

          <div className="row">
            <div className="col-sm-8 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Business Type<span className="setting-require">*</span></div>
                <Select
                  className={`newSelectField ${(errors && errors.companyTypeId) ? 'field_error' : ''}`}
                  name='companyTypeId'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  options = {getContactTypes(resources.CompanyTypes)}
                />
              </div>
            </div>
            <React.Fragment>
                <div className="col-sm-6 col-xs-12">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">{watch('companyTypeId') == 1 ? "Legal First Name" : "Business Entity Name"}<span className="setting-require">*</span>
                    </div>
                    <Input
                      className={`newInputField ${(errors && errors.legalFirstName) ? 'field_error' : ''}`}
                      placeholder="Legal First Name"
                      name='legalFirstName'
                      register={register}
                      isRequired={true}
                      errors={errors}
                      control={control}
                    />
                    {_.get("legalFirstName.type", errors) === "maxLength" && (
                      <p>Legal first name cannot exceed 20 characters</p>
                    )}
                  </div>
                </div>
                {watch('companyTypeId') == 1 &&
                  <div className="col-sm-6 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">Legal Last Name<span className="setting-require">*</span>
                      </div>
                      <Input
                        className={`newInputField ${(errors && errors.legalLastName) ? 'field_error' : ''}`}
                        placeholder="Legal Last Name"
                        name='legalLastName'
                        register={register}
                        isRequired={true}
                        errors={errors}
                        control={control}
                      />
                    </div>
                  </div>
                }
              </React.Fragment>
            </div>

          <div className={(watch('companyTypeId') == 1 || watch('companyTypeId') == 4) ? "no-display" : "m-t-15 customRadioOuter"}>
                <React.Fragment>
                  <input type="radio"
                    
                    className={`customRadio green`}
                    name={'tinTypeID'}
                    value={1}
                    id={'tinTypeID_1'}
                    ref={register}
                    isRequired={true}
                    errors={errors}
                    control={control}
                  />
                  <input type="radio"
                    
                    className={`customRadio red`}
                    name={'tinTypeID'}
                    value={2}
                    id={'tinTypeID_2'}
                    ref={register}
                    isRequired={true}
                    errors={errors}
                    control={control}
                  />
                  <label className="m-t-5">{"Is your Federal Tax ID the signer's SSN?"}</label>
                </React.Fragment>
             
          </div>

          <div className={ "row"}>
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">{(watch('tinTypeID') == "1") ? "Social Security Number" : "Federal Tax ID"}  <span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.tin) ? 'field_error' : ''}`}
                  placeholder={(watch('tinTypeID') == "1") ? "Enter Your Social Security Number" : "Enter Your Federal Tax ID"}
                  name='tin'
                  register={register}
                  isRequired={(watch('tinTypeID') == '' || watch('tinTypeID') == '2') ? false : true}
                  maxLength={30}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Select State Incorporated<span className="setting-require">*</span>
                </div>
                <Select
                  className={`newSelectField ${(errors && errors.stateIncorporatedCode) ? 'field_error' : ''}`}
                  name='stateIncorporatedCode'
                  register={register}
                  isRequired={(watch('tinTypeID') == '' || watch('tinTypeID') == '0') ? false : true}
                  errors={errors}
                  control={control}
                  options = {getStateOptions(resources.StateOptions)}
                />
              </div>
            </div>
          </div>

          <div className="m-t-10 customRadioOuter">
          <React.Fragment>
            <input type="radio"
                    
                    className={`customRadio green`}
                    name={'previouslyAcceptedPaymentCards'}
                    value={true}
                    id={'previouslyAcceptedPaymentCards_1'}
                    ref={register}
                    isRequired={true}
                    errors={errors}
                    control={control}
                  />
                  <input type="radio"
                    
                    className={`customRadio red`}
                    name={'previouslyAcceptedPaymentCards'}
                    value={false}
                    id={'previouslyAcceptedPaymentCards_2'}
                    ref={register}
                    isRequired={true}
                    errors={errors}
                    control={control}
                  />
                  <label className="m-t-5">{'Do you accept or have previously accepted payment cards?'}</label>
                </React.Fragment>
             
          </div>
          {errors.previouslyAcceptedPaymentCards && <div className="row clearent-error-message">Please select one option.</div>}
          <div className={(watch('previouslyAcceptedPaymentCards') == "true") ? "row" : "no-display"}>
            <div className="col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Because you answered "yes" above, please select competitor<span className="setting-require">*</span>
                </div>
                <Select
                  className={`newSelectField ${(errors && errors.previousProcessorId) ? 'field_error' : ''}`}
                  name='previousProcessorId'
                  register={register}
                  isRequired={(watch('previouslyAcceptedPaymentCards') == '' || watch('previouslyAcceptedPaymentCards') == 'false') ? false : true}
                  errors={errors}
                  control={control}
                  options = {getPreviousProcessors(resources.PreviousProcessors)}
                />
              </div>
            </div>
          </div>

          <div className="m-t-10 customRadioOuter">
                <React.Fragment>
                  <input type="radio"
                    
                    className={`customRadio green`}
                    name={'previouslyTerminatedOrIdentifiedByRiskMonitoring'}
                    value={true}
                    id={'previouslyTerminatedOrIdentifiedByRiskMonitoring_1'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <input type="radio"
                    
                    className={`customRadio red`}
                    name={'previouslyTerminatedOrIdentifiedByRiskMonitoring'}
                    value={false}
                    id={'previouslyTerminatedOrIdentifiedByRiskMonitoring_2'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <label className="m-t-5">{'Have you been terminated/identified by Risk Monitoring?'}</label>
                </React.Fragment>
              
          </div>
          {watch("previouslyTerminatedOrIdentifiedByRiskMonitoring") == "true" && 
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <div className="newInputFileldOuter">
                  <div className="newInputLabel">{"Reason"}
                  </div>
                  <Input
                    className={`newInputField ${(errors && errors.reasonPreviouslyTerminatedOrIdentifiedByRisk) ? 'field_error' : ''}`}
                    placeholder="Reason"
                    name='reasonPreviouslyTerminatedOrIdentifiedByRisk'
                    register={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                </div>
              </div>
            </div>
          }
          <div className="m-t-10 customRadioOuter">
                <React.Fragment>
                  <input type="radio"
                    
                    className={`customRadio green`}
                    name={'currentlyOpenForBusiness'}
                    value={true}
                    id={'currentlyOpenForBusiness_1'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <input type="radio"
                    
                    className={`customRadio red`}
                    name={'currentlyOpenForBusiness'}
                    value={false}
                    id={'currentlyOpenForBusiness_2'}
                    ref={register}
                    isRequired={false}
                    errors={errors}
                    control={control}
                  />
                  <label className="m-t-5">{'Are you currently open for business?'}</label>
                </React.Fragment>
             
          </div>
        </div>
		
		 </Scrollbars>
		<div className="footer-static merchantFooter">
			<div className="footerProgressOuter"> 
			 <p><span>Great!</span> Next,you'll Complete</p>
			 <p>Your Profile Information</p>
			<div className="footerProgressBg"><span className="footerProgressBar"></span></div>
			</div>
			<button className="new-blue-btn m-t-5" type="submit">Next</button>
		</div>
		
		
        {/*<div className="footer-static">
                  <button className="new-blue-btn pull-right" type="submit">Next</button>
                  <button className="new-line-btn pull-left" type="button" onClick={() => {
                    handleFormStepChange(1)
                  }}>Back</button>
                </div>*/}
      </div>
    </form>
  );
}
export default FormStep2;
