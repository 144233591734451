import React from "react";
import CrossImage from "../../../../_legacy/images/cross-icon.png";

const RestrictionModal = (props) => {
  const { closeModal, children } = props;

  return (
    <>
      <div className="overlay"></div>
      <div
        id="filterModal"
        role="dialog"
        className="modal fade in displayBlock"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header no-border">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
              >
                ×
              </button>
            </div>
            <div
              id="errorwindow"
              className="modal-body add-patient-form filter-patient d-flex flex-col items-center"
            >
              <div className="m-b-20">
                <img src={CrossImage} alt="cross-icon" width={50} />
              </div>
              {children}
            </div>
            <div className="modal-footer">
              <div className="col-md-12 text-left" id="footer-btn">
                <button
                  type="button"
                  className="btn btn-success pull-right m-r-10"
                  data-dismiss="modal"
                  onClick={closeModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestrictionModal;
