import { mutateProcedure } from "../../../../store/patients/mutateProcedure";
import { dispatch } from "../../../../store/store";
import { procedureDocumentId } from "../utilities";

/**
 * @param {{
 *    questionnaireIds: string[];
 *    consentIds: string[];
 * }} formValues
 */
export function prepareSubmitValues(formValues) {
  return {
    ...formValues,
    questionnaireIds: formValues.questionnaireIds.map(
      procedureDocumentId.parse,
    ),
    consentIds: formValues.consentIds.map(procedureDocumentId.parse),
  };
}

/**
 * @param {Array<{
 *    value: number | string;
 * }>} options
 */
export function extractDocumentIdsFromOptions(options) {
  return options?.map((o) => String(o.value)) || [];
}

/**
 * @param {{
 *    serviceIds?: number[];
 * }} appointmentOption
 *
 * @param {Array<{
 *    value: number;
 * }>} serviceOptions
 */
export function getNextServiceOptionOnApptChange(
  appointmentOption,
  serviceOptions = [],
) {
  return (
    serviceOptions.find(
      (s) => s.value === appointmentOption?.serviceIds?.[0],
    ) || null
  );
}

/**
 * @param {Array<{
 *    id: number;
 *    consentId: number;
 *    isRequired: boolean;
 * }>} consents
 *
 * @param {Array<{
 *    id: number;
 *    questionnaireId: number;
 *    isRequired: boolean;
 * }>} questionnaires
 */
export function prepareProcedureDocumentIds(
  consents = [],
  questionnaires = [],
) {
  return {
    questionnaireIds: questionnaires.map((q) =>
      procedureDocumentId.compose(q.questionnaireId, q.id),
    ),

    consentIds: consents.map((c) =>
      procedureDocumentId.compose(c.consentId, c.id),
    ),

    nonDeletableConsentIds: consents
      .filter((x) => x.isRequired)
      .map((x) => procedureDocumentId.compose(x.consentId, x.id)),

    nonDeletableQuestionnaireIds: questionnaires
      .filter((x) => x.isRequired)
      .map((x) => procedureDocumentId.compose(x.questionnaireId, x.id)),
  };
}

/**
 * Logic:
 * - append mode is enabled when the user is editing procedure (default = false)
 * - if appendMode=false then replace previous documents with new documents from service
 * - if appendMode=true then replace previous documents with new documents from service
 *   but preserve previous documents that are marked as @procedureDocs
 * - non deletable documents are always unique
 *
 * @param {object} param0
 *
 * @param {{
 *    value: number;
 *    consentIds: number[];
 *    questionnaireIds: number[];
 * }} param0.option
 *
 * @param {string[]} param0.selectedConsentIds
 *
 * @param {string[]} param0.selectedQuestionnaireIds
 *
 * @param {string[]} param0.nonDeletableConsentIds
 *
 * @param {string[]} param0.nonDeletableQuestionnaireIds
 *
 * @param {boolean?} param0.appendMode
 */
export function getNextDocumentsFromServiceOption({
  option,
  selectedConsentIds,
  selectedQuestionnaireIds,
  nonDeletableConsentIds,
  nonDeletableQuestionnaireIds,
  appendMode = false,
}) {
  const requiredConsents = option?.consentIds?.map(String) || [];
  const requiredQuestionnaires = option?.questionnaireIds?.map(String) || [];

  const filterForAppend = (ids = []) => {
    return ids.filter(procedureDocumentId.isComposed);
  };

  return {
    nextServiceId: option?.value || null,

    nextConsentIds: [
      ...(appendMode ? filterForAppend(selectedConsentIds) : []),
      ...requiredConsents,
    ],

    nextQuestionnaireIds: [
      ...(appendMode ? filterForAppend(selectedQuestionnaireIds) : []),
      ...requiredQuestionnaires,
    ],

    nextNonDeletableConsentIds: [
      ...new Set([...nonDeletableConsentIds, ...requiredConsents]),
    ],

    nextNonDeletableQuestionnaireIds: [
      ...new Set([...nonDeletableQuestionnaireIds, ...requiredQuestionnaires]),
    ],
  };
}

/**
 * @param {Array<string>} documentIds
 * @param {string} docType
 */
export function initDedupForProcedureDocuments(documentIds, docType) {
  if (
    documentIds.length > 1 &&
    documentIds.some(procedureDocumentId.isComposed)
  ) {
    dispatch(mutateProcedure.actions.startDocumentsDedup(docType));
  }
}

/**
 * @param {Array<{
 *  id: number;
 *  templateId: number;
 * }>} documents
 *
 * @param {Array<selectedIds>} selectedIds
 */
export function filterNextRequiredDocuments(documents, selectedIds) {
  const composedSelected =
    selectedIds?.filter(procedureDocumentId.isComposed) || [];

  if (composedSelected.length === 0) {
    return documents;
  }

  return documents.filter(
    (d) =>
      !composedSelected.find(
        (x) => procedureDocumentId.parseRecordId(x) === d.id,
      ),
  );
}
