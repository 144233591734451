import { useMemo } from "react";
import { useDotPhrasesQuery } from "../../../../../../api/queries/useDotPhrasesQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useDotPhrasesPatterns() {
  const { tCommon } = useAppTranslation.Common();

  const { data } = useDotPhrasesQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchDotPhrases"));
    },
  });

  const preparedData = useMemo(() => {
    return (
      data?.map((i) => ({
        label: i.name,
        description: i?.title || null,
        value: i.phrase,
      })) || []
    );
  }, [data]);

  return {
    data: preparedData,
  };
}
