import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../Utils";
import { QueryKeys } from "./";
import { uiNotification } from "../../services/UINotificationService";
import { http } from "../../services/HttpService";
import { useAppTranslation } from "../../i18n/useAppTranslation";

const GetProviders = ({ selectedClinics }) => {
  const selectedClinicsList = Array.isArray(selectedClinics);
  const url = selectedClinicsList
    ? `waitlist/get-providers-by-clinics`
    : "/all-providers";

  const params = selectedClinicsList ? { clinic_ids: selectedClinics } : {};

  return axios(url, { params });
};

export const useGetProvidersQuery = (payload) => {
  return useQuery([QueryKeys.GET_ALL_PROVIDERS, payload], () =>
    GetProviders(payload),
  );
};

const UpdateProviderSchedule = (payload) => {
  const { scheduleId: _, ...data } = payload;
  const url = `provider-schedules-new`;
  return http.put(url, data);
};

export const useUpdateProviderScheduleQuery = () => {
  const { tCommon } = useAppTranslation.Common();

  const queryClient = useQueryClient();

  return useMutation(UpdateProviderSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PROVIDER_TEMPLATES);
      uiNotification.success(
        tCommon("providerSchedule.success.updatedDaySchedule"),
      );
      queryClient.invalidateQueries(QueryKeys.PROVIDERS_SCHEDULE_OVERRIDES);
    },
  });
};

const DeleteDaySchedule = (payload) => {
  const { providerId, ...data } = payload;
  const url = `provider-schedules-new/delete/${providerId}`;
  return http.post(url, data);
};

export const useDeleteDayScheduleQuery = () => {
  const { tCommon } = useAppTranslation.Common();

  const queryClient = useQueryClient();

  return useMutation(DeleteDaySchedule, {
    onSuccess: () => {
      uiNotification.success(
        tCommon("providerSchedule.success.deletedSchedule"),
      );
      queryClient.invalidateQueries(QueryKeys.GET_PROVIDER_TEMPLATES);
      queryClient.invalidateQueries(QueryKeys.PROVIDERS_SCHEDULE_OVERRIDES);
    },
    onError: (error) => {
      uiNotification.error(
        tCommon([
          `providerSchedule.error.${error.response?.data?.message}`,
          `error.fallback`,
        ]),
      );
    },
  });
};

const UpdateSchedule = (payload) => {
  const { isNewTemplate, id, ...data } = payload;
  if (isNewTemplate) {
    return http.post(`/provider-schedules-new/template/create`, data);
  }
  return http.put(`provider-schedules-new/template/update/${id}`, data);
};

export const useUpdateScheduleQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(UpdateSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PROVIDER_TEMPLATES);
    },
  });
};

const GetProviderSchedulesForDelete = ({ providerId, clinic_ids }) => {
  const url = `get-provider-schedule-for-delete-view/${providerId}`;
  return http.post(url, { clinic_ids });
};

export const useGetProviderSchedulesForDeleteQuery = ({
  providerId,
  clinic_ids,
}) => {
  return useQuery(
    [
      QueryKeys.GET_PROVIDGET_PROVIDER_SCHEDULES_FOR_DELETEER_SCHEDULE,
      providerId,
    ],
    () => GetProviderSchedulesForDelete({ providerId, clinic_ids }),
  );
};

const deleteProviderSchedule = (payload) => {
  const url = `delete-selected-provider-scheduled`;
  return http.post(url, payload);
};

export const useDeleteProviderScheduleQuery = (displayToast = true) => {
  const queryClient = useQueryClient();
  const { tCommon } = useAppTranslation.Common();

  return useMutation(deleteProviderSchedule, {
    onSuccess: (response) => {
      if (displayToast) {
        const schedulesDeleted = response.data.data.i;
        const schedulesWithAppointment = response.data.data.j;

        if (schedulesDeleted > 0) {
          uiNotification.success(
            tCommon("providerSchedule.success.selected_scheduled_deleted", {
              schedulesDeleted,
            }),
          );
        }
        if (schedulesWithAppointment > 0) {
          uiNotification.error(
            tCommon("providerSchedule.error.selected_scheduled_deleted", {
              schedulesWithAppointment,
            }),
          );
        }
      }
      queryClient.invalidateQueries(
        QueryKeys.GET_PROVIDGET_PROVIDER_SCHEDULES_FOR_DELETEER_SCHEDULE,
      );
    },
    onError: (error) => {
      if (displayToast) {
        uiNotification.error(
          tCommon(`providerSchedule.error.${error.response.data.message}`),
        );
      }
    },
  });
};

const GetProviderTempaltes = ({ id }) => {
  const url = `/provider-schedules-new/template/list/${id}`;

  return http.get(url);
};

export const useGetProviderTempaltesQuery = (payload) => {
  return useQuery(
    [QueryKeys.GET_PROVIDER_TEMPLATES, payload],
    () => GetProviderTempaltes(payload),
    {
      enabled: Boolean(payload.id),
    },
  );
};

const UpdateTemplateName = ({ id, ...data }) => {
  const url = `provider-schedules-new/template/update-name/${id}`;
  return http.put(url, data);
};

export const useUpdateTemplateNameQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(UpdateTemplateName, {
    onSuccess: () => {
      uiNotification.success("Name updated successfully.");
      queryClient.invalidateQueries(QueryKeys.GET_PROVIDER_TEMPLATES);
    },
  });
};

const OverrideProviderSchedule = (data) => {
  const url = `provider-schedules-new/override/${data.provider_id}`;
  return http.post(url, data);
};

export const useOverrideProviderScheduleQuery = () => {
  const { tCommon } = useAppTranslation.Common();

  const queryClient = useQueryClient();

  return useMutation(OverrideProviderSchedule, {
    onSuccess: () => {
      uiNotification.success("Date Override Successful");
      queryClient.invalidateQueries(QueryKeys.PROVIDERS_SCHEDULE_OVERRIDES);
    },
    onError: (error) => {
      uiNotification.error(
        tCommon([
          `providerSchedule.error.${error.response?.data?.message}`,
          `error.fallback`,
        ]),
      );
    },
  });
};

const GetProvidersScheduleOverride = (providerId) => {
  const url = `provider-schedules-new/override/${providerId}`;
  return http.get(url);
};

export const useGetProvidersScheduleOverrideQuery = (providerId) => {
  return useQuery([QueryKeys.PROVIDERS_SCHEDULE_OVERRIDES, providerId], () =>
    GetProvidersScheduleOverride(providerId),
  );
};

const DeleteScheduleOverrides = (data) => {
  const url = `delete-provider-scheduled`;
  return http.post(url, data);
};

export const useDeleteScheduleOverridesQuery = () => {
  const { tCommon } = useAppTranslation.Common();

  const queryClient = useQueryClient();

  return useMutation(DeleteScheduleOverrides, {
    onSuccess: () => {
      uiNotification.success(
        tCommon("providerSchedule.success.deletedSchedule"),
      );
      queryClient.invalidateQueries(QueryKeys.PROVIDERS_SCHEDULE_OVERRIDES);
    },
    onError: (error) => {
      uiNotification.error(
        tCommon([
          `providerSchedule.error.${error.response?.data?.message}`,
          `error.fallback`,
        ]),
      );
    },
  });
};
