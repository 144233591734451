import React from "react";
import parentClasses from "../../HealthCreateEdit.module.scss";
import Footer from "../../../../shared/Footer/Footer";
import { Info } from "./shared/Info/Info";
import { useSubmit } from "./hooks/useSubmit";
import { useForm } from "./hooks/useForm";
import { getHealthPathParams } from "../../HealthCreateEdit.utils";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Notes } from "../../../../shared/Notes/Notes";
import { useDeleteProcedure } from "../../../../hooks/useDeleteProcedure";

export function FormPrescribed() {
  const { tCommon } = useAppTranslation.Common();
  const { isEditMode, procedureId, clientId } = getHealthPathParams();

  const submit = useSubmit();
  const del = useDeleteProcedure(procedureId);
  const form = useForm(submit.initiate);

  return (
    <div className={parentClasses.root}>
      <Info chiefComplaint={form.chiefComplaint} isLoading={form.isLoading} />
      <Notes
        clientId={clientId}
        value={form.notes.value}
        onChange={form.notes.onChange}
        isLoading={form.isLoading}
      />
      <Footer
        onSubmit={form.handleSubmit}
        onDelete={del.initiate}
        isSubmitting={submit.isLoading}
        isDeleting={del.isLoading}
        isFormError={form.isError}
        isLoading={form.isLoading}
        isEditMode={isEditMode}
        submitLabel={tCommon("label.ePrescribe")}
      />
    </div>
  );
}
