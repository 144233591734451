import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useInviteOTPMutation(options = {}) {
  return useMutation((dto) => {
    return http.post(HTTP_ENDPOINTS.postInviteOTP(), {
      workspace_id: dto.workspaceId,
    });
  }, options);
}
