import { http } from "../services/HttpService";

export const fetchStockEmailAlerts = () => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_STOCK_EMAIL_ALERTS",
      payload: { status: "loading" },
    });
    http
      .get("user/stock-alert-email-days")
      .then((response) => {
        dispatch({
          type: "FETCH_STOCK_EMAIL_ALERTS",
          payload: {
            status: "success",
            isEnabled: Boolean(response.data.data.stock_alert_email_status),
            selectedDays: response.data.data.stock_alert_email_days,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "FETCH_STOCK_EMAIL_ALERTS",
          payload: { status: "error" },
        });
      });
  };
};

export const saveStockEmailAlerts = (isEnabled, selectedDays) => {
  return (dispatch) => {
    dispatch({
      type: "SAVE_STOCK_EMAIL_ALERTS",
      payload: { status: "loading" },
    });
    http
      .post("user/stock-alert-email-days", {
        stock_alert_email_status: isEnabled,
        stock_alert_email_days: selectedDays,
      })
      .then((response) => {
        dispatch({
          type: "SAVE_STOCK_EMAIL_ALERTS",
          payload: { status: "success" },
        });
        dispatch({
          type: "FETCH_STOCK_EMAIL_ALERTS",
          payload: {
            status: "success",
            isEnabled: Boolean(response.data.data.stock_alert_email_status),
            selectedDays: response.data.data.stock_alert_email_days,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "SAVE_STOCK_EMAIL_ALERTS",
          payload: { status: "error" },
        });
      });
  };
};

export function resetStockEmailAlertStatus() {
  return (dispatch) => {
    dispatch({ type: "RESET_STOCK_EMAIL_ALERTS" });
  };
}
