import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
  paymentId: yup.string().nullable(),
});

const responseSchema = yup.object({
  id: yup.string().required(),
  amount: yup.number().nullable(),
  isCancelled: yup.bool().required(),
  isFulfilled: yup.bool().required(),
  isProcessing: yup.bool().required(),
});

const composeResponse = (res) => {
  const details = unwrapOr(() => res.data.data.cherryPayment, {});
  return {
    id: details.id,
    amount: details.amount || null,
    isCancelled: Boolean(details.cancelled),
    isFulfilled: Boolean(details.fulfilled),
    isProcessing: Boolean(details.processing),
  };
};

function createCherryPaymentQueryKey({ invoiceId, paymentId }) {
  return [QUERY_KEYS.cherry.payment, invoiceId, paymentId];
}

export function useCherryPaymentQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createCherryPaymentQueryKey({
      invoiceId: req.invoiceId,
      paymentId: req.paymentId,
    }),
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.cherry.getPayment({
          invoiceId: req.invoiceId,
          paymentId: req.paymentId,
        }),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
