import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import classes from "./AnswerFile.module.scss";
import { useImageUploader } from "../../../../../../hooks/useImageUploader";
import { UPLOAD_MEDIA_TYPE } from "../../../../../../api/mutations/useUploadMediaMutation";
import { ImageUploader } from "../../../../../../boxes/ImageUploader/ImageUploader";

export function AnswerFile({
  isRequired,
  initialValues,
  renderFooter,
  onSubmit,
}) {
  const uploader = useImageUploader({
    uploadType: UPLOAD_MEDIA_TYPE.questionnaires,
  });

  const validationSchema = yup.object({
    file: yup.lazy(() => {
      const schema = yup.string();
      if (isRequired) {
        return schema.required();
      }
      return schema;
    }),
  });

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    initialValues: {
      file: initialValues.file || "",
    },
  });

  const isError = errors.file && touched.file;

  const onFinishLater = () => {
    onSubmit(values, { saveForLater: true });
  };

  return (
    <div className={classes.root}>
      <ImageUploader
        name="answerFile"
        value={values.file}
        onRemove={() => setFieldValue("file", "")}
        isLoading={uploader.isLoading}
        onChange={(e) =>
          uploader
            .htmlOnChange(e)
            .then(({ uploadedFileFullPath }) =>
              setFieldValue("file", uploadedFileFullPath),
            )
        }
      />
      {renderFooter(handleSubmit, isError, onFinishLater)}
    </div>
  );
}

AnswerFile.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    file: PropTypes.string,
  }).isRequired,
  renderFooter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
