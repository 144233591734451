import React, { useState } from "react";
import { Box } from "../../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../../../shared/SortAdornment/SortAdornment";
import { ContentLoader } from "../../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import classes from "./Table.module.scss";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";
import { NO_CLINIC_PATIENTS_REPORT_SORT_FIELDS } from "../../../../../../../api/noClinicPatientsReport/useNoClinicPatientsReportQuery";
import { Button } from "../../../../../../../shared/Button/Button";
import { ModalUpdate } from "../../shared/ModalUpdate/ModalUpdate";

export function Table({ reports, order, selectedPatients }) {
  const { tCommon } = useAppTranslation.Common();
  const [updatePatientId, setUpdatePatientId] = useState(undefined);

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const data = reports.value.map((r) => ({
    id: r.id,
    checkbox: (
      <Checkbox
        size="small"
        isChecked={selectedPatients.isSelected(r.id)}
        onChange={() => selectedPatients.onToggleOne(r.id)}
      />
    ),
    firstName: r.firstname || tCommon("symbol.longDash"),
    lastName: r.lastname || tCommon("symbol.longDash"),
    email: r.email || tCommon("symbol.longDash"),
    action: (
      <Button size="small" onClick={() => setUpdatePatientId(r.id)}>
        {tCommon("label.update")}
      </Button>
    ),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            keyExtractor={(row) => row.id}
            data={data}
            cols={[
              {
                data: "",
                accessor: "checkbox",
                className: classes.checkboxCol,
              },
              {
                data: renderSortWrap(
                  tCommon("label.firstName"),
                  NO_CLINIC_PATIENTS_REPORT_SORT_FIELDS.firstName,
                ),
                accessor: "firstName",
                className: classes.col,
              },
              {
                data: renderSortWrap(
                  tCommon("label.lastName"),
                  NO_CLINIC_PATIENTS_REPORT_SORT_FIELDS.lastName,
                ),
                accessor: "lastName",
                className: classes.col,
              },
              {
                data: renderSortWrap(
                  tCommon("label.email"),
                  NO_CLINIC_PATIENTS_REPORT_SORT_FIELDS.email,
                ),
                accessor: "email",
                className: classes.emailCol,
              },
              {
                data: "",
                accessor: "action",
                className: classes.actionCol,
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
      {Boolean(updatePatientId) && (
        <ModalUpdate
          onClose={() => setUpdatePatientId(undefined)}
          onSuccess={() => selectedPatients.onRemoveOne(updatePatientId)}
          patientIds={[updatePatientId]}
        />
      )}
    </Box>
  );
}
