import React, { useState } from "react";
import moment from "moment";
import { WIDGET_NAMES } from "../../Dashboard.consts";
import { WidgetBox } from "../../boxes/WidgetBox/WidgetBox.js";
import { useRevenuePerHour } from "./hooks/useRevenuePerHour.js";
import RevenuePerHourChart from "./components/RevenuePerHourChart.js";
import { API_DATE_FORMAT } from "../../../../consts/api.js";

const WidgetRevenuePerHour = ({ fromReportData }) => {
  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  });

  const params = fromReportData || {
    from_date: moment(period.from).format(API_DATE_FORMAT),
    to_date: moment(period.to).format(API_DATE_FORMAT),
  };

  const { data: widgetData, isLoading: isWidgetDataLoading } =
    useRevenuePerHour(params, {
      select: (data) => data.data.data.data || [],
      enabled: Boolean(params.from_date),
    });

  const chartData = widgetData?.map((data) => {
    return {
      x: data.gross_revenue_hour_raw.toFixed(2),
      y: data.minutes_worked_period / 60,
      name: data.provider_name,
      color: data.color,
      yLabel: data.hours_worked_period,
    };
  });

  const monthlyRange = !fromReportData
    ? {
        dates: {
          start: period.from,
          end: period.to,
        },
        update: setPeriod,
        isNextDisabled: moment(period.to).isSameOrAfter(moment(), "month"),
      }
    : null;

  return (
    <WidgetBox
      label={WIDGET_NAMES.revenue_per_hour}
      isLoading={isWidgetDataLoading}
      isEmpty={widgetData?.length === 0}
      headerClassName="flex justify-between items-center"
      monthlyRange={monthlyRange}
    >
      <RevenuePerHourChart chartData={chartData} />
    </WidgetBox>
  );
};

export default WidgetRevenuePerHour;
