import React from "react";
import classes from "./Empty.module.scss";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { PlusIcon } from "../../../../../../../assets/Icons/PlusIcon";

export const Empty = ({ onCreate }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();

  return (
    <>
      {tClients("vitals.nothingFound")}
      <button className={classes.createBtn} onClick={onCreate}>
        {tCommon("label.create")}
        <PlusIcon />
      </button>
    </>
  );
};
