import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.object({
  phone: yup.string().nullable(),
  userId: yup.number().required(),
  rescheduling: yup.boolean().required(),
  cancelling: yup.boolean().required(),
  booking: yup.boolean().required(),
  isEnabled: yup.boolean().required(),
});

const composeResponse = (res) => {
  const data = res?.data?.data || {};

  return {
    phone: data.notification_phone,
    userId: data.user_id,
    rescheduling: data.appt_rescheduled,
    cancelling: data.appt_canceled,
    booking: data.appt_booked,
    isEnabled: [
      data.appt_rescheduled,
      data.appt_canceled,
      data.appt_booked,
    ].some(Boolean),
  };
};

const createSmsApptNotificationsQueryKey = () => [
  QUERY_KEYS.smsApptNotifications.get,
];

export function useSmsApptNotificationsQuery(options = {}) {
  return useQuery(
    createSmsApptNotificationsQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.smsApptNotifications.get());

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
