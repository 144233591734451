import React from "react";
import { Button } from "../../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../../shared/CircularProgress/CircularProgress";
import { ErrorLogs } from "../../../../../../../../shared/ErrorLogs/ErrorLogs";
import styles from "./styles.module.scss";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";

export function EditorFilesMobileFooter({
  isFilePending,
  isFilesParsing,
  onSubmitMultiple,
  isSubmitting,
  errorLogs,
}) {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <div>
      <Button
        fullWidth
        onClick={onSubmitMultiple}
        size="small"
        isDisabled={isFilePending || isFilesParsing || isSubmitting}
        leftAdornment={
          isSubmitting ? (
            <CircularProgress size="tiny" color="white" />
          ) : undefined
        }
      >
        {tSettings("efaxTemplates.createFromAll")}
      </Button>
      {errorLogs.length > 0 && (
        <ErrorLogs
          logs={errorLogs.map((e) => ({
            label: e.fileName,
            errors: e.errors,
          }))}
          type="nested"
          className={styles.errorLogs}
        />
      )}
    </div>
  );
}
