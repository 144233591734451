import { useQueryClient } from "@tanstack/react-query";
import { useProviderRoomProcedureAfterPhotosStatusMutation } from "../../../api/mutations/useProviderRoomProcedureAfterPhotosStatusMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { QUERY_KEYS } from "../../../consts/api";

export function useProcedureAfterPhotosChange({ procedureId }) {
  const { tCommon } = useAppTranslation.Common();
  const queryClient = useQueryClient();

  const { mutate, isLoading } =
    useProviderRoomProcedureAfterPhotosStatusMutation({
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.providerRoomProcedures],
          refetchType: "active",
        });
        uiNotification.success(
          tCommon(
            variables.isAfterPhotos
              ? "providerRoom.success.afterPhotosMark"
              : "providerRoom.success.afterPhotosUnmark",
          ),
        );
      },
      onError: (_, variables) => {
        uiNotification.error(
          tCommon(
            variables.isAfterPhotos
              ? "providerRoom.error.afterPhotosMark"
              : "providerRoom.error.afterPhotosUnmark",
          ),
        );
      },
    });

  return {
    changeAsAfterPhotos: (isAfterPhotos) =>
      mutate({ procedureId, isAfterPhotos }),
    isChangingAsAfterPhotos: isLoading,
  };
}
