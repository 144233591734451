import { useMemo, useState } from "react";
import moment from "moment";
import { ORDER_TYPES } from "../../../../../consts/api";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import {
  formatCurrency,
  hasLength,
  packedMapOr,
  unwrapOr,
} from "../../../../../utilities/general";
import { uiNotification } from "../../../../../services/UINotificationService";
import { CURRENCY } from "../../../../../consts/general";
import { useReconciliationReportsQuery } from "../../../../../api/queries/useReconciliationReportsQuery";

const prepareReqOrder = (orderState) => {
  if (Object.values(orderState).every(Boolean)) {
    return {
      by: orderState.by,
      direction: orderState.direction,
    };
  }
  return null;
};

const prepareReqPeriod = (periodState) => {
  return {
    from: periodState.from || null,
    to: periodState.to || null,
  };
};

const prepareReqFilter = (filterState) => {
  return {
    clinicIds: packedMapOr(
      filterState.clinics.map((c) => c.value),
      null,
    ),
    status: filterState.status || null,
  };
};

export function useReports() {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();

  /* Modifiers */

  const [order, setOrder] = useState({
    by: "",
    direction: "",
  });

  const updateOrder = (orderFieldKey) => {
    setOrder({
      by: orderFieldKey,
      direction:
        order.direction === ORDER_TYPES.desc
          ? ORDER_TYPES.asc
          : ORDER_TYPES.desc,
    });
  };

  const [filter, setFilter] = useState({
    clinics: [],
    status: "",
  });

  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  });

  /* Data */

  const preparedReqFilter = prepareReqFilter(filter);

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useReconciliationReportsQuery({
    payload: {
      pageSize: 15,
      period: prepareReqPeriod(period),
      filter: preparedReqFilter,
      order: prepareReqOrder(order),
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  });

  const reports = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const responseStats = (() => {
    if (data?.pages?.length > 0) {
      return data.pages[0];
    }
    return {};
  })();

  const currency = unwrapOr(() => reports[0].currency, CURRENCY.usd);

  const stats = [
    {
      id: "totalFeeCollected",
      data: formatCurrency(responseStats.total_cc_collected || 0, currency),
      name: tBi("reconciliationReport.totalCredit"),
    },
    {
      id: "totalSetupFeeCollected",
      data: formatCurrency(responseStats.total_payouts || 0, currency),
      name: tBi("reconciliationReport.totalPayouts"),
    },
  ];

  /* Statuses */

  const isFilterActive = Object.values(filter).some(hasLength);
  const isOrderActive = Object.values(order).some(Boolean);
  const isPeriodActive = Object.values(period).some(Boolean);

  const isSoftLoading =
    (isFilterActive || isOrderActive || isPeriodActive) &&
    isFetching &&
    !isLoading &&
    !isFetchingNextPage;

  return {
    reports: {
      value: reports,
      isLoading,
      isSoftLoading,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      fetchMore: fetchNextPage,
    },
    filter: {
      value: filter,
      isActive: isFilterActive,
      update: setFilter,
      preparedForRequest: preparedReqFilter,
    },
    order: {
      value: order,
      update: updateOrder,
    },
    period: {
      value: period,
      update: setPeriod,
    },
    stats,
  };
}
