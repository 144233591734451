import React, { useState } from "react";
import cx from "clsx";
import classes from "./Node.module.scss";
import { EditIcon } from "../../../../../../../../../assets/Icons/EditIcon";
import { DeleteIcon } from "../../../../../../../../../assets/Icons/DeleteIcon";
import { ConfirmModal } from "../../../../../../../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { useDelete } from "./hooks/useDelete";
import { CircularProgress } from "../../../../../../../../../shared/CircularProgress/CircularProgress";

/**
 * @param {Object} param0
 *
 * @param {number} param0.id
 * @param {string} param0.name
 * @param {number} param0.min
 * @param {number?} param0.max
 * @param {string} param0.color
 * @param {() => void} param0.onEdit
 */
export const Node = ({ id, name, min, max, color, onEdit }) => {
  const { tClients } = useAppTranslation.Clients();
  const del = useDelete(id);

  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);

  return (
    <>
      <div className={classes.root}>
        <div style={{ color }} className={classes.name}>
          {`${name} ${min} - ${
            max || tClients("vitals.settings.bmiMaxBoundaryPlaceholder")
          }`}
        </div>
        <div className={classes.actions}>
          <button
            className={cx(classes.action, classes.editAction)}
            onClick={onEdit}
            disabled={del.isLoading}
          >
            <EditIcon width="14px" />
          </button>
          <button
            className={cx(classes.action, classes.deleteAction)}
            onClick={() => setIsDeleteConfirmModalOpen(true)}
            disabled={del.isLoading}
          >
            {del.isLoading ? (
              <CircularProgress size="tiny" color="red" />
            ) : (
              <DeleteIcon width="14px" />
            )}
          </button>
        </div>
      </div>
      {isDeleteConfirmModalOpen && (
        <ConfirmModal
          isOpen
          onClose={() => setIsDeleteConfirmModalOpen(false)}
          onCancel={() => setIsDeleteConfirmModalOpen(false)}
          onConfirm={() => {
            del.initiate();
            setIsDeleteConfirmModalOpen(false);
          }}
        >
          {tClients("vitals.settings.confirm.deleteBmiRange")}
        </ConfirmModal>
      )}
    </>
  );
};
