import React from "react";
import PropTypes from "prop-types";
import { Input } from "../../../../../shared/Input/Input";
import classes from "../../ManageClinics.module.scss";
import { Checkbox } from "../../../../../shared/Checkbox/Checkbox";
import { Textarea } from "../../../../../shared/Textarea/Textarea";

export function MultiPostFromFilesContent({
  currentFile,
  onEditFile,
  isPosting,
}) {
  const onChangeTitle = (e) => {
    onEditFile(currentFile.id, {
      consentName: e.target.value,
    });
  };

  const onEditorChange = (e) => {
    onEditFile(currentFile.id, {
      parsedContent: e.target.value,
    });
  };

  return (
    <div className={classes.multiPostFromFilesEditorContent}>
      {currentFile && (
        <>
          <Input
            value={currentFile.consentName ? currentFile.consentName : ""}
            onChange={onChangeTitle}
            placeholder="Consent Name"
            isDisabled={isPosting}
          />
          <Textarea
            name="desc"
            placeholder="Consent Body"
            rows={5}
            value={currentFile.parsedContent ? currentFile.parsedContent : ""}
            onChange={onEditorChange}
            disabled={isPosting}
            className={classes.multiPostFromFilesTextarea}
          />
          <Checkbox
            isChecked={Boolean(currentFile.isInitialsOnPage)}
            label="Would you like initials on pages?"
            onChange={() =>
              onEditFile(currentFile.id, {
                isInitialsOnPage: !currentFile.isInitialsOnPage,
              })
            }
          />
          <Checkbox
            isChecked={Boolean(currentFile.isWitnessSignatureAtTheEnd)}
            label="Would you like witness signature at the end?"
            onChange={() =>
              onEditFile(currentFile.id, {
                isWitnessSignatureAtTheEnd: !currentFile.isWitnessSignatureAtTheEnd,
              })
            }
          />
        </>
      )}
    </div>
  );
}

MultiPostFromFilesContent.propTypes = {
  currentFile: PropTypes.object,
  onEditFile: PropTypes.func.isRequired,
  isPosting: PropTypes.bool.isRequired,
};

MultiPostFromFilesContent.defaultProps = {
  currentFile: null,
};
