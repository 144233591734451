import React, { useMemo, useState } from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { dateToApiDateString } from "../../../../../../../utilities/api";
import { useEfaxUsageLogQuery } from "../../../../../../../api/efax/useEfaxUsageLogQuery";

const initialFromDate = new Date();

const setDay = (date, day) =>
  dateToApiDateString(moment(date).set("date", day).toDate());

const nextMonth = (date) => moment(date).add(1, "months").toDate();

export function useReport() {
  const [hasFetchedOnce, setHasFetchedOnce] = useState(false);
  const { tSettings } = useAppTranslation.Settings();

  /* Period */

  const [period, setPeriod] = useState({
    from: dateToApiDateString(initialFromDate),
    to: dateToApiDateString(nextMonth(new Date())),
  });

  const [apiFromDate, setApiFromDate] = useState(
    dateToApiDateString(initialFromDate),
  );

  /* Data */

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useEfaxUsageLogQuery({
    payload: {
      pageSize: 15,
      period: {
        from: apiFromDate,
      },
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tSettings("eFaxUsageLog.error.fetch"));
      },
      onSuccess: (data) => {
        if (!hasFetchedOnce) {
          const drawDay = data?.pages?.[0]?.drawDay;
          if (!drawDay) return;

          const calculatePeriod = (from, drawDay) => {
            const fromMoment = moment(from);
            const fromDay = fromMoment.day();
            let start, end;

            if (fromDay > drawDay) {
              start = fromMoment.set("date", drawDay);
              end = start.clone().add(1, "months");
            } else {
              end = fromMoment.set("date", drawDay);
              start = end.clone().subtract(1, "months");
            }

            return { start, end };
          };

          const { start, end } = calculatePeriod(period.from, drawDay);

          setPeriod({
            from: dateToApiDateString(start.toDate()),
            to: dateToApiDateString(end.toDate()),
          });
          setHasFetchedOnce(true);
        }
      },
    },
  });

  const drawDay = data?.pages?.[0]?.drawDay;

  const report = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.data).reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const stats = [
    {
      id: "totalPages",
      data: data?.pages?.[0]?.totalPages || 0,
      name: tSettings("eFaxUsageLog.stats.totalPages"),
    },
    {
      id: "remainingPages",
      data: data?.pages?.[0]?.remainingPages || 0,
      name: tSettings("eFaxUsageLog.stats.remainingPages"),
    },
  ];

  /* Statuses */

  const isPeriodActive = Object.values(period).some(Boolean);

  const isSoftLoading =
    isPeriodActive && isFetching && !isLoading && !isFetchingNextPage;

  return {
    reports: {
      value: report,
      isLoading,
      isSoftLoading,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      fetchMore: fetchNextPage,
    },
    period: {
      value: period,
      update: ({ from, to }) => {
        setApiFromDate(from);
        setPeriod({
          from: setDay(from, drawDay),
          to: setDay(nextMonth(to), drawDay),
        });
      },
    },
    stats,
  };
}

export function withEfaxUsageLogReport(Component) {
  return function UseReportComponent(props) {
    const report = useReport();
    return <Component {...props} efaxUsageLogReport={report} />;
  };
}
