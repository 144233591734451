import {
  SALES_REPORT_EXPORT_NAMES,
  useSalesReportExportMutation,
} from "../../../../../../../../api/mutations/useSalesReportExportMutation";
import { FILE_EXPORT_TYPES } from "../../../../../../../../consts/api";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { composeDownloadDataUrl } from "../../../../../../../../utilities/api";

export function useDownloadExcel() {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useSalesReportExportMutation({
    onError: () => {
      uiNotification.error(tCommon("error.exportXlsReports"));
    },
  });

  const initiate = async () => {
    const res = await mutateAsync({
      type: FILE_EXPORT_TYPES.xls,
      name: SALES_REPORT_EXPORT_NAMES.expiringLiabilityReport,
    });

    window.open(composeDownloadDataUrl(res.fileName), "_blank");
  };

  return {
    initiate,
    isLoading,
  };
}
