export const procedureDocumentId = {
  compose: (id, recordId) => `${id}/${recordId}@procedureDoc`,

  parse: (composedId = "") => {
    const data = String(composedId).split("@procedureDoc")[0];
    return Number(data.split("/")[0]);
  },

  parseRecordId: (composedId = "") => {
    const data = String(composedId).split("@procedureDoc")[0];
    return Number(data.split("/")[1]);
  },

  isComposed: (id = "") => String(id).endsWith("@procedureDoc"),
};
