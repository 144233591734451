import { useEffect, useRef, useState } from "react";

export function useMultiselectEntity({
  allEntities = [],
  initialSelect = [],
  isSelectAllOnLoadMore = false,
}) {
  const [selected, setSelected] = useState(initialSelect);
  const [entities, setEntities] = useState(allEntities);
  const prevSelectedCount = useRef(0);

  useEffect(() => {
    if (!isSelectAllOnLoadMore) return;
    if (
      selected.length > 0 &&
      prevSelectedCount.current === selected.length &&
      prevSelectedCount.current !== allEntities.length
    ) {
      onToggleAll();
    }

    if (prevSelectedCount.current !== allEntities.length) {
      prevSelectedCount.current = allEntities.length;
    }
  }, [allEntities]);

  const isSelectedPartial = () => {
    return selected.length < entities.length && selected.length !== 0;
  };

  const isSelectedAll = () => {
    return selected.length > 0 && selected.length === allEntities.length;
  };

  const isSelected = (entity) => {
    return selected.includes(entity);
  };

  const onRemoveOne = (entity) => {
    setSelected((prev) => prev.filter((i) => i !== entity));
  };

  const onToggleOne = (entity) => {
    setSelected((prev) =>
      isSelected(entity)
        ? prev.filter((item) => item !== entity)
        : [...prev, entity],
    );
  };

  const onToggleAll = () => {
    setSelected(isSelectedAll() ? [] : allEntities);
  };

  const resetSelected = () => {
    setSelected([]);
  };

  return {
    onToggleOne,
    onToggleAll,
    onRemoveOne,
    isSelected,
    isSelectedAll,
    isSelectedPartial,
    resetSelected,
    selected,
    setSelected,
    setEntities,
  };
}
