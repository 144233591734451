import { matchPath } from "react-router";
import { SETTINGS_ROUTES } from "../../../../../consts/routes";
import { isNullish } from "../../../../../utilities/general";
import { MULTI_QUESTION_LOGIC_JUMP_TYPES } from "../../../../../api/questionnaires/config";

export function getMultiQuestionnairesPathParams() {
  const match = matchPath(window.location.pathname, {
    path: SETTINGS_ROUTES.questionnaires.multi.edit(":id"),
    exact: true,
    strict: false,
  });

  return {
    questionnaireId: match?.params?.id ? Number(match.params.id) : null,
  };
}

export function getIsSomeLogicJumpsFilled(nodes = []) {
  return nodes.some(
    (node) =>
      !isNullish(node.ifSelectedChoiceId) ||
      !isNullish(node.thenQuestionId) ||
      !isNullish(node.elseQuestionId),
  );
}

export function getIsAllLogicJumpsFilled(nodes) {
  const jumpNodes = nodes.filter(
    (n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.jump,
  );
  const elseNodes = nodes.filter(
    (n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
  );
  const isAllJumpNodesFilled = jumpNodes.every(
    (n) => !isNullish(n.ifSelectedChoiceId) && !isNullish(n.thenQuestionId),
  );
  const isAllElseNodesFilled = elseNodes.every(
    (n) => !isNullish(n.elseQuestionId),
  );
  return isAllJumpNodesFilled && isAllElseNodesFilled;
}

export function findLogicJumpsByQuestionId({ questions, questionId }) {
  const result = [];

  for (let q = 0; q < questions.length; q++) {
    const question = questions[q];

    if (question?.logicJumps?.length) {
      for (let j = 0; j < question.logicJumps.length; j++) {
        const jump = question.logicJumps[j];

        if (jump.thenQuestionId === questionId) {
          result.push({
            questionIndex: q,
            logicJumpIndex: j,
            logicJumpField: "thenQuestionId",
          });
        }

        if (jump.elseQuestionId === questionId) {
          result.push({
            questionIndex: q,
            logicJumpIndex: j,
            logicJumpField: "elseQuestionId",
          });
        }
      }
    }
  }

  return result;
}
