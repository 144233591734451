/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { capitalizeFirstLetter } from "../../../Utils/services.js";

export default class AppointmentsReportTitle extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,
      globalLang: languageData.global,
    };
  }

  showCreateOptions = () => {
    this.props.showCreateOptions();
  };

  saveReport = () => {
    this.props.saveReport();
  };

  render() {
    return (
      <div className="membership-title row">
        <div className="col-sm-12 m-b-10">
          {this.props.parentState.reportListData &&
          this.props.parentState.insightReportData &&
          this.props.parentState.insightReportData.grammar
            ? capitalizeFirstLetter(
                this.props.parentState.insightReportData.grammar,
              )
            : ""}
        </div>
        {!this.props.parentState.showLoader && (
          <div className="memberRightActions">
            {((this.props.parentState.reportListData &&
              this.props.parentState.reportListData.length > 0) ||
              this.props.parentState.insightReportData) && (
              <a
                onClick={() => this.props.downloadData()}
                className="easy-link no-padding"
              >
                <i className="fa fa-download m-r-5" />
                {this.props.parentState.businessInsightLang.bi_download_excel}
              </a>
            )}
            {this.props.reportMode !== "view" && (
              <a
                className="easy-link no-padding"
                onClick={this.showCreateOptions}
              >
                <i className="fa fa-pencil-alt m-r-5" />{" "}
                {this.props.parentState.businessInsightLang.bi_edit}
              </a>
            )}
            {this.props.parentState.dataLoadedOnce === true &&
              this.props.reportMode !== "view" && (
                <a onClick={this.saveReport} className="new-blue-btn">
                  {this.props.parentState.businessInsightLang.bi_save_report}
                </a>
              )}
          </div>
        )}
      </div>
    );
  }
}
