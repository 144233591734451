import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./TemplateSelector.module.scss";

export function TemplateSelector({
  onTemplateSelect,
  onClose,
  currentTranscription,
}) {
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [customPrompt, setCustomPrompt] = useState("");
  const [saveAsProcedure, setSaveAsProcedure] = useState(false);

  const templates = [
    {
      id: 1,
      label: "SOAP Note",
    },

    {
      id: 2,
      label: "Procedure Note",
    },

    {
      id: 3,
      label: "Follow-up Visit",
    },

    {
      id: 4,
      label: "Surgical Note",
    },
  ];

  const handleTemplateClick = (template) => {
    setSelectedTemplateId(template.id);

    setCustomPrompt(template.defaultPrompt);
  };

  const handleSubmit = () => {
    const selectedTemplate = templates.find((t) => t.id === selectedTemplateId);
    if (!selectedTemplate) {
      alert("Please select a template");
      return;
    }

    onTemplateSelect({
      ...selectedTemplate,
      customPrompt: customPrompt || selectedTemplate.defaultPrompt,
      saveAsProcedure,
      transcription: currentTranscription,
    });
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Select your ChartSmart template</h2>

        <div className={styles.templateGrid}>
          {templates.map((template) => (
            <button
              key={template.id}
              onClick={() => handleTemplateClick(template)}
              className={clsx(
                styles.templateButton,
                selectedTemplateId === template.id &&
                  styles.templateButtonSelected,
              )}
            >
              <div className={styles.templateInfo}>
                <div className={styles.templateTitle}>{template.label}</div>
                <div className={styles.templateDescription}>
                  {template.description}
                </div>
              </div>
            </button>
          ))}
        </div>

        <div className={styles.customPromptSection}>
          <textarea
            className={styles.customPromptInput}
            placeholder="Modify the template instructions or write your own custom formatting instructions..."
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
          />
        </div>

        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="saveAsProcedure"
            checked={saveAsProcedure}
            onChange={(e) => setSaveAsProcedure(e.target.checked)}
            className={styles.checkbox}
          />
          <label htmlFor="saveAsProcedure" className={styles.checkboxLabel}>
            Save my dictation as a procedure note.
          </label>
        </div>

        <div className={styles.buttonContainer}>
          <button onClick={handleSubmit} className={styles.saveButton}>
            Save
          </button>
          <button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

TemplateSelector.propTypes = {
  onTemplateSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentTranscription: PropTypes.string.isRequired,
};
