import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";
import { createQueryKeyFromShape } from "../../utilities/api";

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.string().nullable(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    data: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          updated_at: yup.string().required(),
          direction: yup.string().nullable(),
          message_sid: yup.string().required(),
          status: yup.string().required(),
          patient: yup
            .object({
              id: yup.number().required(),
              full_name: yup.string().required(),
            })
            .nullable(),
          phone_number: yup.string().required(),
          segments: yup.number().required(),
        }),
      )
      .required(),
    totalSegments: yup.number().required(),
    remainingSegments: yup.number().required(),
    drawDay: yup.number().nullable(),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data || {};

  return {
    ...composePaginationSchema({
      response: res?.data?.data?.log || {},
      pageSize: req.pageSize,
    }),
    data: data.log?.data || [],
    totalSegments: Number(data.total) || 0,
    remainingSegments: Number(data.remaining) || 0,
    drawDay: data.draw_day,
  };
};

const createSmsUsageLogQueryKey = (...args) => [
  QUERY_KEYS.smsUsageLog,
  ...args,
];

const createKeyFromShape = (shape) =>
  unwrapOr(() => createQueryKeyFromShape(removeNullishFromShape(shape)), null);

export function useSmsUsageLogQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createSmsUsageLogQueryKey(
      createKeyFromShape(payload.period),
      req.pageSize,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getSmsUsageLog(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          start_date: req.period.from,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
