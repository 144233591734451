import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";

export const useMergePatientsEfaxes = (options = {}) => {
  const formData = {
    ids: JSON.parse(localStorage.getItem("profiles_id")),
  };
  return useQuery(
    ["mergePatientsEfaxes"],
    async () => {
      const res = await http.post("/get_merge_patients/efaxes", formData);
      return {
        faxes: res.data.data,
      };
    },
    options,
  );
};
