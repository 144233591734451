import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useChargeForm } from "../../hooks/useChargeForm";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { useAvailablePaymentMethods } from "../../hooks/useAvailablePaymentMethods";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";

const ViewChargeArWallet = ({ isTipsApplying }) => {
  const { invoice, amountToPay } = useInvoice();
  const { chargeWallet, paymentViewClose } = useInvoiceActions();

  const { data: maxWalletAmount } = useAvailablePaymentMethods(invoice.id, {
    select: (data) => data.wallet.maxAmount,
  });

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: Math.min(maxWalletAmount, amountToPay),
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeWallet.initiate({ amount });
      },
      initialValues: {
        amount: Math.min(maxWalletAmount, amountToPay) || "",
      },
    });

  const maxAmountToPay = Math.min(maxWalletAmount, parseFloat(form?.amount));

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.arWallet")}
      imageSrc={svg.arWalletPrimary}
      onCharge={submit}
      isCharging={chargeWallet.isLoading}
      chargeLabel={tCommon("label.charge")}
      amount={form.amount}
      isChargeDisabled={!isValid || isTipsApplying}
    >
      <PaymentTipsModal maxAmountToPay={maxAmountToPay} />
      <PaymentFormModal
        form={form}
        errors={errors}
        hasError={hasError}
        changeFormValue={changeFormValue}
      />
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: state.checkoutInvoice.isTipsApplying,
});

export default connect(mapStateToProps)(ViewChargeArWallet);
