import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import classes from "./BalanceDue.module.scss";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { PAYMENT_VIEW_TYPES } from "../../../../../store/checkoutInvoice/consts";
import { SelectPayment } from "../../shared/SelectPayment/SelectPayment";
import { Divider } from "../../../../../shared/Divider/Divider";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { formatCurrency } from "../../../../../utilities/general";
import { prettifyPaymentInfo } from "../../SalesCheckoutInvoice.utils";
import { InfoRow } from "../Summary/shared/InfoRow/InfoRow";
import { DeleteBtn } from "../Summary/shared/DeleteBtn/DeleteBtn";
import { useGiftCardActions } from "../../hooks/useGiftCardActions";

export function BalanceDue() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, balanceDue } = useInvoice();
  const { removeGiftCard } = useGiftCardActions();
  const queryClient = useQueryClient();

  const onPaymentOptionsOpen = () => {
    queryClient.invalidateQueries("availablePaymentMethods");
    dispatch(
      checkoutInvoice.actions.paymentViewChange({
        paymentView: PAYMENT_VIEW_TYPES.optionSelection,
      }),
    );
  };

  return (
    <LabeledBox label={tCommon("label.balanceDue")}>
      <div className={classes.amount}>
        {formatCurrency(balanceDue, invoice?.currency)}
      </div>
      <SelectPayment onSelect={onPaymentOptionsOpen} />
      <Divider gap="25px" />
      <div className={classes.list}>
        <div className={classes.listRow}>
          <div>{tCommon("label.totalAmount")}</div>
          <div>{formatCurrency(invoice?.totalAmount, invoice?.currency)}</div>
        </div>
        {invoice?.redeemedGiftCards?.map((c) => (
          <InfoRow
            key={c.id}
            label={tSales("checkoutInvoice.summary.eGiftCardAmount", {
              code: String(c.redemptionCode),
            })}
            value={`-${formatCurrency(c.redeemedAmount, invoice?.currency)}`}
            adornment={
              <DeleteBtn
                onClick={() => removeGiftCard.initiate(c.id)}
                isDisabled={removeGiftCard.isLoading}
                isLoading={
                  removeGiftCard.isLoading && removeGiftCard.removingId === c.id
                }
              />
            }
          />
        ))}
        {invoice?.paymentTransaction?.payments?.map((payment) => {
          const { modeLabel, amount } = prettifyPaymentInfo(
            payment,
            invoice?.currency,
          );

          return (
            <div key={payment.id} className={classes.listRow}>
              <div>{modeLabel}</div>
              <div>{`-${amount}`}</div>
            </div>
          );
        })}
        <div className={classes.total}>
          <div>{tCommon("label.balanceDue")}</div>
          <div>{formatCurrency(balanceDue, invoice?.currency)}</div>
        </div>
      </div>
    </LabeledBox>
  );
}
