import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import classes from "./AnswerScale.module.scss";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function AnswerScale({
  isRequired,
  initialValues,
  options,
  renderFooter,
  onSubmit,
  withReason,
}) {
  const { tCommon } = useAppTranslation.Common();

  const validationSchema = yup.object({
    choice: yup.lazy(() => {
      const schema = yup.string();
      if (isRequired) {
        return schema.required();
      }
      return schema;
    }),
    reason: yup.string(),
  });

  const { values, setFieldValue, handleSubmit, handleChange, errors, touched } =
    useFormik({
      validationSchema,
      onSubmit,
      enableReinitialize: true,
      initialValues: {
        choice: initialValues.choice || "",
        reason: initialValues.reason || "",
      },
    });

  const onSelect = (value) => {
    if (values.choice === value) {
      setFieldValue("choice", "");
    } else {
      setFieldValue("choice", value);
    }
  };

  const isError = errors.choice && touched.choice;

  const onFinishLater = () => {
    onSubmit(values, { saveForLater: true });
  };

  return (
    <div className={classes.root}>
      <div className={classes.options}>
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => onSelect(option.value)}
            className={
              option.value === values.choice ? classes.selected : undefined
            }
          >
            {option.label}
          </button>
        ))}
      </div>
      {withReason && values.choice && (
        <Textarea
          name="reason"
          value={values.reason}
          onChange={handleChange}
          placeholder={tCommon(
            "fillPatientQuestionnaire.opinionReasonPlaceholder",
          )}
        />
      )}
      {renderFooter(handleSubmit, isError, onFinishLater)}
    </div>
  );
}

AnswerScale.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    choice: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  renderFooter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  withReason: PropTypes.bool,
};
