import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchDiscountPackagesData, emptyInventoryReducer } from '../../../../../Actions/Inventory/inventoryActions.js';
import { withRouter } from 'react-router';
import { numberFormat, autoScrolling } from "../../../../../Utils/services.js";
import Sidebar from '../../../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../../../Common/Loader.js'
import { ObOrder } from "./components/ObOrder/ObOrder.js";
import { tCommon } from "../../../../../i18n/useAppTranslation.js";
import { CLONABLE_DISCOUNT_TYPES } from "../../../../../consts/api.js";
import { uiNotification } from "../../../../../services/UINotificationService.js";

localStorage.removeItem('idpfType');
localStorage.removeItem('idpfStatus');
localStorage.removeItem('idpfSortBy');
localStorage.removeItem('idpfSortOrder');


class DiscountPackages extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0)
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const idpfType = localStorage.getItem("idpfType")
    const idpfStatus = localStorage.getItem("idpfStatus")
    const idpfSortBy = localStorage.getItem("idpfSortBy")
    const idpfSortOrder = localStorage.getItem("idpfSortOrder")
    this.state = {
      loadMore: true,
      startFresh: true,
      showLoader: false,
      page: 1,
      pagesize: 15,
      term: '',
      hasMoreItems: true,
      next_page_url: '',
      searchFunction: '',
      user_changed: false,
      tabClicked: false,
      DiscountPackageData: [],
      data: [],
      childCheck: false,
      action: props.match.params.statusId,
      sortorder: (idpfSortOrder) ? idpfSortOrder : 'asc',
      sortby: (idpfSortBy) ? idpfSortBy : 'name',
      scopes: 'category',
      selected: [],
      selectAll: 0,
      categoryName: '',
      show_below_stock: 0,
      package_types: {},
      value: '',
      status: (idpfStatus) ? idpfStatus : 'active',
      type: (idpfType) ? idpfType : 'all',

      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      showLoadingText: false,
      dataTimeStamp: new Date(),
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <= 5 && this.state.next_page_url != null) {
        if (!autoScrolling() && !this.state.showLoader) {
          this.loadMore();
        }
      }
    };
  }

  getDiscountPackagesData = (formData) => {
    this.setState({ showLoader: true });
    this.props.fetchDiscountPackagesData(formData).finally(() => {
      this.setState({ showLoader: false });
    });
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });
  };

  componentDidMount() {
    let formData = {
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder,
        sortby: this.state.sortby,
        term: this.state.term,
        action: this.state.action,
        filter_by: this.state.type,
        status: this.state.status,
      }
    };
    autoScrolling(true)
    this.getDiscountPackagesData(formData);
  }

  onSort = (sortby) => {
    let sortorder = (this.state.sortorder === 'asc') ? 'desc' : 'asc';
    let formData = {
      'params': {
        page: 1,
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term,
        filter_by: this.state.type,
        status: this.state.status,
      }
    }
    this.setState({
      page: 1,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      next_page_url: '',
      DiscountPackageData: []
    });
    localStorage.setItem('sortOnly', true);
    autoScrolling(true)
    this.getDiscountPackagesData(formData);
    localStorage.setItem('idpfSortBy', sortby);
    localStorage.setItem('idpfSortOrder', sortorder);
  }

  loadMore = () => {
    localStorage.setItem("sortOnly", false);
    this.setState({ 'loadMore': true, startFresh: true, showLoader: false, showLoadingText: true });

    let formData = {
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder && this.state.sortorder === 'asc' ? 'asc' : this.state.sortorder == 'desc' ? 'desc' : '',
        sortby: this.state.sortby,
        term: this.state.term,
        action: this.state.action,
        filter_by: this.state.type,
        status: this.state.status,
      }
    };
    autoScrolling(true)
    this.getDiscountPackagesData(formData);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.emptyInventoryReducer();
      return { showLoader: false }
    }
    if (
      nextProps.DiscountPackageData != undefined &&
      nextProps.DiscountPackageData.data.next_page_url !== prevState.next_page_url &&
      nextProps.dataTimeStamp != prevState.dataTimeStamp
    ) {
      let returnState = {};
      if (prevState.next_page_url == null) {
        localStorage.setItem("sortOnly", false);
        autoScrolling(false)
        return (returnState.next_page_url = null);
      }

      if (prevState.DiscountPackageData.length == 0 && prevState.startFresh == true) {
        if (localStorage.getItem("sortOnly") == "false") {
          returnState.DiscountPackageData = nextProps.DiscountPackageData.data.data;
          if (nextProps.DiscountPackageData.data.next_page_url != null) {
            returnState.page = prevState.page + 1;
          } else {
            returnState.next_page_url = nextProps.DiscountPackageData.data.next_page_url;
          }
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
          returnState.package_types = nextProps.DiscountPackageData.data.package_types;
        } else {
          localStorage.setItem("sortOnly", false);
        }
      } else if (
        prevState.DiscountPackageData != nextProps.DiscountPackageData.data.data &&
        prevState.DiscountPackageData.length != 0
      ) {
        returnState.DiscountPackageData = [
          ...prevState.DiscountPackageData,
          ...nextProps.DiscountPackageData.data.data
        ];
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.DiscountPackageData.data.next_page_url;
        returnState.package_types = nextProps.DiscountPackageData.data.package_types;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      }
      autoScrolling(false)
      return returnState;
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  inventoryEdit = (statusId, id) => {
    localStorage.setItem('userID', id)
    return (
      <div>
        {this.props.history.push(`/settings/discount-packages/${statusId}/edit`)}
      </div>
    );
  }

  inventoryClone = (statusId, id) => {
    localStorage.setItem('userID', id)
    return (
      <div>
        {this.props.history.push(`/settings/discount-packages/${statusId}/edit?clone=true`)}
      </div>
    );
  }

  handleSubmit = event => {
    event.preventDefault();
    var y = event.target.value
    var name = event.target.name
    localStorage.setItem('sortOnly', true);
    let formData = {
      'params': {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: 'asc',
        sortby: 'name',
        term: this.state.term,
        status: (name == "status") ? y : this.state.status,
        filter_by: (name == "type") ? y : this.state.type
      }
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortorder: 'asc',
      sortby: 'name',
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      DiscountPackageData: [],
      status: (name == "status") ? y : this.state.status,
      type: (name == "type") ? y : this.state.type
    });
    this.setState({ filterValue: 'false' });
    autoScrolling(true)
    this.getDiscountPackagesData(formData);
    localStorage.setItem('idpfSortBy', 'name');
    localStorage.setItem('idpfSortOrder', 'asc');
    localStorage.setItem('idpfStatus', (name == "status") ? y : this.state.status);
    localStorage.setItem('idpfType', (name == "type") ? y : this.state.type);
    //localStorage.setItem('idpfTerm', this.state.term);


  };

  refetchDiscounts = () => {
    this.setState({ 'loadMore': true, startFresh: true, showLoader: false, showLoadingText: true });
    let formData = {
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder && this.state.sortorder === 'asc' ? 'asc' : this.state.sortorder == 'desc' ? 'desc' : '',
        sortby: this.state.sortby,
        term: this.state.term,
        action: this.state.action,
        filter_by: this.state.type,
        status: this.state.status,
      }
    };
    this.getDiscountPackagesData(formData);
  };

  render() {
    var output = [];
    
    if (this.state.package_types != null) {
      output = Object.entries(this.state.package_types).map(([key, value]) => ({ key, value }));
    }

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    {'Promotions'}
                    <div className="memberRightActions">
                      <Link to="/settings/discount-packages/create" className="new-blue-btn pull-right m-l-10">{'Create discount'}</Link>
                      <ObOrder />
                      <Link to="/settings/discount-groups" className="easy-link no-padding"><i className="fa fa-list-ul m-r-5" />{'View Discount Groups list'}</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"setting-setion m-b-10"}>
                <div className="setting-search-outer">
                  <form onSubmit={this.handleSubmit}>
                    <div className="search-bg new-search">
                      <i className="fas fa-search" />
                      <input className="setting-search-input search-key" placeholder="Search" name="term"
                        value={this.state.term} autoComplete="off" onChange={this.handleInputChange} />
                    </div>
                  </form>
                  <div className="dropdown pull-right page-export">

                    <div className="filter-type pull-right">
                      <span className="search-text">{this.state.inventoryLang.inventory_Type}:</span>
                      <div className="header-select">
                        <form onChange={this.handleSubmit}>
                          <select name="type" value={this.state.type} onChange={this.handleInputChange} >
                            {
                              output.length > 0 && output.map((obj, idx) => {
                                return (
                                  <option key={'type'+idx} value={obj.key}>{obj.value && obj.value ? obj.value : ''}</option>
                                );
                              })
                            }
                          </select>
                        </form>
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>
                    <div className="filter-type pull-right">
                      <span className="search-text"><b>{this.state.inventoryLang.inventory_Filter_by}: </b>{this.state.inventoryLang.inventory_Status}:</span>
                      <div className="header-select">
                        <form onChange={this.handleSubmit}>
                          <select name="status" onChange={this.handleInputChange} value={this.state.status}>
                            <option value="all">{this.state.inventoryLang.inventory_all}</option>
                            <option value="active">{this.state.inventoryLang.inventory_active}</option>
                            <option value="expired">Expired</option>
                            <option value="inactive">{this.state.inventoryLang.inventory_Deleted}</option>
                          </select>
                        </form>
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="juvly-section full-width">
                <div className="table-responsive">
                  <table className="table-updated juvly-table no-td-border">
                    <thead className="table-updated-thead">
                      <tr>
                        <th
                          className="col-xs-2 table-updated-th cursor-pointer sorting"
                          onClick={() => this.onSort("name")}
                          data-sort="name"
                          data-order="DESC"
                        >
                          {this.state.inventoryLang.inventory_Discount_Name}
                          <i className={(this.state.sortorder === 'asc' && this.state.sortby === 'name') ? "blue-gray" : (this.state.sortorder === 'desc' && this.state.sortby === 'name') ? "gray-blue" : "gray-gray"} />
                        </th>
                        <th
                          className="col-xs-2 table-updated-th cursor-pointer sorting"
                          onClick={() => this.onSort("online_booking_name")}
                          data-sort="online_booking_name"
                          data-order="DESC"
                        >
                          Online Booking Name
                          <i className={(this.state.sortorder === 'asc' && this.state.sortby === 'online_booking_name') ? "blue-gray" : (this.state.sortorder === 'desc' && this.state.sortby === 'online_booking_name') ? "gray-blue" : "gray-gray"} />
                        </th>
                        <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.inventory_Type}</th>
                        <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.inventory_Price}</th>
                        <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.inventory_Discount}</th>
                        <th className="col-xs-2 table-updated-th" >Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.DiscountPackageData !== undefined && this.state.DiscountPackageData.map((obj, idx) => {
                          let statusId = this.state.action;
                          let price = 0;
                          let discount = 0;
                          let typeVal = obj.type;
                          var firstLetter = typeVal[0] || typeVal.charAt(0);
                          let typeValues = typeVal.replace(/^./, firstLetter.toUpperCase());
                          switch (obj.type) {
                            case 'percentage':
                              price = '';
                              discount = (obj.discount_percentage > 0) ? Number(obj.discount_percentage).toFixed(3) + '%' : '';
                              break;
                            case 'dollars':
                              price = '';
                              discount = (obj.discount_dollars > 0) ? numberFormat(obj.discount_dollars, 'currency', 2) : '';
                              break;
                            case 'package':
                              price = (obj.package_bogo_price > 0) ? numberFormat(obj.package_bogo_price, 'currency', 2) : '';
                              discount = '';
                              break;
                            case 'bogo':
                              if (obj.bogo_buy_type !== 'group') {
                                price = (obj.package_bogo_price > 0) ? numberFormat(obj.package_bogo_price, 'currency', 2) : '';
                                discount = '';
                              } else {
                                if (!obj.bogo_discount_percentage) {
                                  price = '';
                                  discount = this.state.inventoryLang.inventory_Free
                                } else {
                                  price = '';
                                  discount = (obj.bogo_discount_percentage > 0) ? numberFormat(obj.bogo_discount_percentage, 'decimal', 0) + '% ' + this.state.inventoryLang.inventory_OFF : '';
                                }
                              }
                              break;
                            default:
                              price = "";
                              discount = "";
                          }
                          return (
                            <tr className="table-updated-tr" key={idx} >
                              <td className="table-updated-td" onClick={this.inventoryEdit.bind(this, obj.id, statusId)}>{obj.name && obj.name ? obj.name : ''} </td>
                              <td className="table-updated-td" onClick={this.inventoryEdit.bind(this, obj.id, statusId)}>{obj.promotion_name_for_booking_portal && obj.is_available_online ? obj.promotion_name_for_booking_portal : ''}</td>
                              <td className="table-updated-td" onClick={this.inventoryEdit.bind(this, obj.id, statusId)}>{obj.type && obj.type != 'bogo' ? typeValues : (obj.bogo_buy_type == "group" ? this.state.inventoryLang.inventory_bogo_disc_group : typeValues)}</td>
                              <td className="table-updated-td" onClick={this.inventoryEdit.bind(this, obj.id, statusId)}>
                                {price}
                              </td>
                              <td className="table-updated-td" onClick={this.inventoryEdit.bind(this, obj.id, statusId)}>
                                {discount}
                              </td>
                              <td className="table-updated-td">
                                {CLONABLE_DISCOUNT_TYPES.includes(obj.type) && (
                                  <button
                                    onClick={this.inventoryClone.bind(this, obj.id, statusId)}
                                    className="link-btn d-flex align-center gap-8"
                                  >
                                    {tCommon("label.clone")}
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {(this.state.showLoader === false) && <div className={(this.state.DiscountPackageData != undefined && this.state.DiscountPackageData.length == 0) ? 'no-record' : 'no-record no-display'} >
                    <div className="" style={{ float: "left", width: "100%", fontSize: "13px", textAlign: "center", marginTop: "0px", padding: "0px" }} >
                      {this.state.inventoryLang.inventory_No_record_found}
                    </div>
                  </div>}

                </div>
              </div>
              <div className={(this.state.showLoadingText) ? "loading-please-wait" : "loading-please-wait no-display "}>{this.state.inventoryLang.inventory_loading_please_wait}</div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  let returnState = {}
  if (state.InventoryReducer.action === "PACKAGES_LIST") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.DiscountPackageData = state.InventoryReducer.data;
      returnState.dataTimeStamp = new Date();
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  }
  else if (state.InventoryReducer.action === "EMPTY_INVENTROY") {
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchDiscountPackagesData,
    emptyInventoryReducer,
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DiscountPackages));
