import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";
import classes from "./sass/ClientHandler.module.scss";
import { EntityRow } from "../../components/EntityRow";
import { MODE } from "./ClientHandler.consts";
import { FormDefault } from "./containers/FormDefault";
import { FormEdit } from "./containers/FormEdit";
import { useAutoResetState } from "../../../../utilities/hooks/useAutoResetState";
import { FormCreate } from "./containers/FormCreate";
import { QUERY_KEYS } from "../../../../consts/api";
import { getLabel } from "./ClientHandler.utils";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { APPOINTMENT_TYPES } from "../../Event.consts";
import { phoneUtil } from "../../../../utilities/phone";

export function ClientHandler({
  readonly,
  id,
  name,
  email,
  phone,
  isNameError,
  isEmailError,
  isPhoneError,
  onSelect,
  onUpdate,
  onNameChange,
  changeIsSubmitActive,
  appointmentType,
}) {
  const { tCommon } = useAppTranslation.Common();
  const queryClient = useQueryClient();
  const [mode, setMode] = useState(MODE.default);
  const [formAlert, setFormAlert] = useAutoResetState(null, null);

  const onUpdateSuccess = (id, formValues, alertMessage) => {
    onUpdate({
      id,
      ...formValues,
      phone: phoneUtil.compose(formValues.phone),
    });
    setMode(MODE.default);
    setFormAlert(alertMessage);
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.patientByPattern],
    });
  };

  const isPhoneRequired = appointmentType === APPOINTMENT_TYPES.virtual;

  useEffect(() => {
    if (mode !== MODE.default) {
      changeIsSubmitActive(false);
    } else {
      changeIsSubmitActive(true);
    }
  }, [mode]);

  return (
    <EntityRow width="none" label={getLabel()} className={classes.clientEntity}>
      {(readonly || mode === MODE.default) && (
        <FormDefault
          readonly={readonly}
          name={name}
          onNameChange={onNameChange}
          email={email}
          phone={phone}
          isNameError={isNameError}
          isEmailError={isEmailError}
          isPhoneError={isPhoneError}
          onSelectClient={onSelect}
          setMode={setMode}
          alertMessage={formAlert}
          isClientSelected={Boolean(id)}
        />
      )}
      {mode === MODE.edit && (
        <FormEdit
          id={id}
          name={name}
          email={email}
          phone={phone}
          onSuccess={(id, values) =>
            onUpdateSuccess(id, values, tCommon("info.updatePatientProfile"))
          }
          setMode={setMode}
          isPhoneRequired={isPhoneRequired}
        />
      )}
      {mode === MODE.create && (
        <FormCreate
          name={name}
          onSuccess={(id, values) =>
            onUpdateSuccess(id, values, tCommon("info.createPatientProfile"))
          }
          setMode={setMode}
          isPhoneRequired={isPhoneRequired}
        />
      )}
    </EntityRow>
  );
}

ClientHandler.propTypes = {
  readonly: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  isNameError: PropTypes.bool,
  isEmailError: PropTypes.bool,
  isPhoneError: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onPhoneCodeChange: PropTypes.func.isRequired,
  changeIsSubmitActive: PropTypes.func.isRequired,
  appointmentType: PropTypes.string.isRequired,
};

ClientHandler.defaultProps = {
  readonly: false,
  id: null,
  name: "",
  email: "",
  phone: "",
  isNameError: false,
  isEmailError: false,
  isPhoneError: false,
};
