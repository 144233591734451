import { useClinicsQuery } from "../../../../../../../api/queries/useClinicsQuery";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";

export function useClinics() {
  const { tCommon } = useAppTranslation.Common();

  const clinicsQuery = useClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchClinics"));
    },
  });

  const clinicsList = clinicsQuery?.data?.map((clinic) => ({
    label: clinic.name,
    value: clinic.id,
  }));

  return {
    isClinicsLoading: clinicsQuery.isLoading,
    clinicsList,
  };
}
