import { MULTI_QUESTION_LOGIC_JUMP_TYPES } from "../../../../../api/questionnaires/config";
import { isNullish } from "../../../../../utilities/general";

export function getAlreadySelectedChoiceIds(nodes) {
  return nodes
    .filter((n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.jump)
    .map((n) => n.ifSelectedChoiceId)
    .filter((v) => !isNullish(v));
}

export function choiceToSelectOption(choice) {
  return {
    label: String(choice.value),
    value: choice.id,
  };
}

export function getAlreadySelectedQuestionIds(nodes) {
  return nodes
    .reduce(
      (carry, node) => [...carry, node.thenQuestionId, node.elseQuestionId],
      [],
    )
    .filter((v) => !isNullish(v));
}

export function questionToSelectOptions(question) {
  return {
    label: question.question,
    value: question.id,
  };
}

export function filterOptionByLabelPredicate(option) {
  return Boolean(option.label?.trim());
}
