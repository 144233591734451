import React from "react";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { Header } from "./shared/Header/Header";
import classes from "./styles.module.scss";
import { Empty } from "../../shared/Empty/Empty";
import { usePatientQuestionnaire } from "./hooks/usePatientQuestionnaire";
import { QUESTIONNAIRE_TYPES } from "../../consts/api";
import { Multi } from "./widgets/Multi/Multi";
import { Simple } from "./widgets/Simple/Simple";

export function ModalPatientQuestionnaireFill({
  isOpen,
  onClose,
  patientQuestionnaireId,
  preloadedPatientQuestionnaire,
  onSuccess,
}) {
  var { tCommon } = useAppTranslation.Common();

  var questionnaire = usePatientQuestionnaire({
    patientQuestionnaireId,
    preloadedPatientQuestionnaire,
  });

  var questionnaireByType = {
    [QUESTIONNAIRE_TYPES.multi]: (
      <Multi questionnaire={questionnaire.data} onSuccess={onSuccess} />
    ),
    [QUESTIONNAIRE_TYPES.simple]: (
      <Simple questionnaire={questionnaire.data} onSuccess={onSuccess} />
    ),
  };

  return (
    <Modal
      size="large"
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      header={<Header />}
    >
      {patientQuestionnaireId || preloadedPatientQuestionnaire ? (
        <>
          {questionnaire.isLoading ? (
            <Empty position="center">{tCommon("label.loading")}...</Empty>
          ) : (
            questionnaireByType[questionnaire.data?.questionnaire?.type] || null
          )}
        </>
      ) : (
        <Empty position="center">
          {tCommon("fillPatientQuestionnaire.notFound")}
        </Empty>
      )}
    </Modal>
  );
}
