import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { useProviderRoomProcedureHideMutation } from "../../../api/mutations/useProviderRoomProcedureHideMutation";
import { QUERY_KEYS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";

export function useProcedureHide({ procedureId }) {
  const { tCommon } = useAppTranslation.Common();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useProviderRoomProcedureHideMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.providerRoomProcedures],
        refetchType: "active",
      });
      uiNotification.success(tCommon("providerRoom.success.hide"));
    },
    onError: () => {
      uiNotification.error(tCommon("providerRoom.error.hide"));
    },
  });

  return {
    hideProcedure: () => mutate({ procedureId }),
    isProcedureHiding: isLoading,
  };
}
