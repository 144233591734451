import React from "react";
import PropTypes from "prop-types";
import parentClasses from "../../sass/ProviderRoom.module.scss";
import { ProcedureTabs } from "../../components/ProcedureTabs/ProcedureTabs";
import { PROVIDER_ROOM_PROCEDURE_TYPE } from "../../../../api/queries/useProviderRoomProceduresQuery";
import { Table } from "../../../../shared/Table/Table";
import { useTableHealth } from "../hooks/useTableHealth";
import ReactSelect from "../../../../Components/Common/ReactSelect";

export function TabHealth({
  onChangeTab,
  isVisible,
  procedures,
  availableClinicsList = [],
  onChangeClinic,
  clinicId,
  showHideActionButton,
  providerUserId,
}) {
  const { cols, data } = useTableHealth({
    procedures,
    showHideActionButton,
    providerUserId,
  });

  const handleChangeTab = (type) => {
    onChangeTab(type);
    onChangeClinic("");
  };

  const handleClinicChange = ({ id }) => {
    onChangeClinic(id.toString());
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-7 col-md-8 col-lg-9">
          <ProcedureTabs
            value={PROVIDER_ROOM_PROCEDURE_TYPE.health}
            onChange={handleChangeTab}
          />
        </div>
        <div className="col-12 col-sm-5 col-md-4 col-lg-3">
          <ReactSelect
            name={"id"}
            selectedId={clinicId}
            list={[
              { name: "All Clinics", id: "" },
              ...availableClinicsList,
            ].map((i) => ({
              value: i.id,
              label: i.name,
            }))}
            value={"value"}
            label={"label"}
            handleReactSelect={handleClinicChange}
            isValid={true}
            placeholder={"Select Clinic"}
            isMulti={false}
          />
        </div>
      </div>
      {isVisible && (
        <Table
          bgColor="white"
          cols={cols}
          data={data}
          headClassName={parentClasses.tableHead}
        />
      )}
    </>
  );
}

TabHealth.propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  procedures: PropTypes.array.isRequired,
};
