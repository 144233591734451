import { useAssignPatientsToClinicMutation } from "../../../../../../../../api/noClinicPatientsReport/useAssignPatientsToClinicMutation";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";

export const useUpdate = () => {
  const { tBi } = useAppTranslation.BusinessInsights();

  const { mutateAsync, isLoading } = useAssignPatientsToClinicMutation({
    onError: () => {
      uiNotification.error(tBi("noClinicPatientsReport.assignClinic.error"));
    },
    onSuccess: () => {
      uiNotification.success(
        tBi("noClinicPatientsReport.assignClinic.success"),
      );
    },
  });

  return {
    initiate: ({ patientIds, clinicId }) =>
      mutateAsync({ patient_ids: patientIds, clinic_id: clinicId }),
    isLoading,
  };
};
