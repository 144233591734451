import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  procedureId: yup.number().required(),
  productId: yup.number().required(),
});

export function useDeleteProcedureRetailProductMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });

    return http.post(HTTP_ENDPOINTS.deleteProcedureRetailProduct(), {
      procedure_id: req.procedureId,
      product_id: req.productId,
    });
  }, options);
}
