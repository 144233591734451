import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.array().of(yup.string());

const composeResponse = (res) => {
  return res?.data?.data?.placeholders || [];
};

const createEfaxTemplatePlaceholdersQueryKey = () => [
  QUERY_KEYS.efax.templatePlaceholders,
];

export const useEfaxTemplatePlaceholdersQuery = (options = {}) => {
  return useQuery(
    createEfaxTemplatePlaceholdersQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.efax.getTemplatePlaceholders());

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
