import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classes from "./Header.module.scss";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";
import { useClientQuery } from "../../../../api/queries/useClientQuery";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { passOr } from "../../../../utilities/general";
import { PREVIEW_DATE_FORMAT } from "../../../../consts/general";

export function Header({ patientId }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const { data, isLoading } = useClientQuery(
    {
      clientId: patientId,
    },
    {
      onError: () => {
        uiNotification.error(tCommon("error.fetchPatient"));
      },
    },
  );

  const birthDay = passOr(data?.dateOfBirth && user, null, () =>
    moment(data.dateOfBirth).format(
      user.previewDateFormat || PREVIEW_DATE_FORMAT,
    ),
  );

  if (isLoading) {
    return (
      <div className={classes.skeleton}>
        <Skeleton width="200px" height={23} borderRadius="4px" />
        <Skeleton width="85px" height={20} borderRadius="4px" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.name}>{data.fullName}</div>
      {birthDay && <div className={classes.birthday}>{birthDay}</div>}
    </div>
  );
}

Header.propTypes = {
  patientId: PropTypes.number.isRequired,
};
