import { SIMPLE_QUESTION_TYPES } from "../../../../../../api/questionnaires/config";
import { useSimpleQuestionnaireMutation } from "../../../../../../api/questionnaires/useSimpleQuestionnaireMutation";
import { history } from "../../../../../../history";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import {
  boolToNumber,
  getLastUrlEntry,
} from "../../../../../../utilities/general";
import { getSimpleQuestionnairesPathParams } from "../SimpleQuestionnaireCreateEdit.utils";

export function useSubmit() {
  const { tCommon } = useAppTranslation.Common();
  const { questionnaireId } = getSimpleQuestionnairesPathParams();

  const { mutate, isLoading } = useSimpleQuestionnaireMutation({
    onError: () => {
      uiNotification.error(tCommon("error.saveQuestionnaire"));
    },
    onSuccess: () => {
      uiNotification.success(tCommon("success.saveQuestionnaire"));
      history.goBack();
    },
  });

  function initiate(formValues) {
    const getRestQuestionData = (q) => {
      switch (q.questionType) {
        case SIMPLE_QUESTION_TYPES.yesNo:
          return {
            multiple_selection: boolToNumber(q.isMultipleSelection),
          };
        case SIMPLE_QUESTION_TYPES.multiText:
          return {
            multiple_selection: boolToNumber(q.isMultipleSelection),
            multi_text_data: q.textChoices?.map((c) => ({
              text: c.text,
            })),
          };
        case SIMPLE_QUESTION_TYPES.multiImage:
          return {
            multiple_selection: boolToNumber(q.isMultipleSelection),
            display_labels: boolToNumber(q.isDisplayLabels),
            multi_image_data: q.imageChoices?.map((c) => ({
              image: getLastUrlEntry(c.image),
              image_label: c.label,
            })),
          };
        default:
          return {};
      }
    };

    mutate({
      questionnaire_id: questionnaireId,
      title: formValues.title,
      question_data: formValues.questions.map((q) => ({
        question: q.question,
        question_type: q.questionType,
        ...getRestQuestionData(q),
      })),
    });
  }

  return {
    initiate,
    isLoading,
  };
}
