import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useStaffTreatment = (params, options = {}) => {
  return useQuery(
    ["staffTreatmentReport", params],
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getStaffTreatments(), {
        params,
      });
      const composeResponse = {
        clinics: res.data.data.clinics,
        reportHeader: res.data.data.report_header,
        reportData: res.data.data.report_data,
        total: res.data.data.total,
      };
      return composeResponse;
    },
    options,
  );
};
