import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

const IS_DISABLED = process.env.REACT_APP_DISABLE_RECAPTCHA === "true";

export const Recaptcha = forwardRef(
  ({ style, theme, onChange, onExpired, onError, asyncScriptOnLoad }, ref) => {
    useEffect(() => {
      if (IS_DISABLED) {
        onChange("test-token");
      }
    }, []);

    if (!IS_DISABLED) {
      return (
        <ReCAPTCHA
          ref={ref}
          style={style}
          theme={theme}
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={onChange}
          onExpired={onExpired}
          onErrored={onError}
          asyncScriptOnLoad={asyncScriptOnLoad}
        />
      );
    }

    return null;
  },
);

Recaptcha.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onExpired: PropTypes.func,
  onError: PropTypes.func,
  asyncScriptOnLoad: PropTypes.func,
};

Recaptcha.defaultProps = {
  style: {},
  theme: "light",
  onExpired: () => {},
  onError: () => {},
  asyncScriptOnLoad: () => {},
};
