import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { tCommon } from "../../../../../../i18n/useAppTranslation";

export const useDownloadStripeDocument = (options = {}) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const { mutate: downloadZipMutation } = useMutation(
    (fileName) =>
      http.post(
        HTTP_ENDPOINTS.downloadStripeZip(),
        {
          file: fileName,
        },
        { responseType: "blob" },
      ),
    {
      onSuccess: async (data) => {
        const blobZip = data.data;
        window.location.href = URL.createObjectURL(blobZip);
      },
    },
  );

  const downloadZip = (data) => {
    const failedDocuments = data?.data?.data?.failed_documents || [];
    const fileName = data?.data?.data?.zip_file;

    if (failedDocuments.length > 0) {
      uiNotification.error(
        tCommon("error.documentsDownloadForFiles", {
          files: failedDocuments.join(", "),
        }),
      );
    }

    downloadZipMutation(fileName);
  };

  const mutation = useMutation(
    (dto) =>
      http.post(
        HTTP_ENDPOINTS.prepareStripeZipDownload(),
        dto || {
          document_ids: selectedDocuments,
        },
      ),
    {
      onSuccess: (data) => downloadZip(data),
      onError: (error) => uiNotification.error(error.response.message),
      ...options,
    },
  );

  const handleSelectDocument = (documentId) => {
    if (selectedDocuments.includes(documentId)) {
      setSelectedDocuments(selectedDocuments.filter((id) => id !== documentId));
    } else {
      setSelectedDocuments([...selectedDocuments, documentId]);
    }
  };

  const handleSelectAllDocuments = (documents) => {
    if (selectedDocuments.length === 0) {
      setSelectedDocuments(documents.map((document) => document.id));
    } else {
      setSelectedDocuments([]);
    }
  };

  return {
    mutation,
    selectedDocuments: {
      value: selectedDocuments,
      update: setSelectedDocuments,
      handleSelectDocument,
      handleSelectAllDocuments,
    },
  };
};
