/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";
import CommonCCDetails from "../../pages/Event/components/common/CommonCCDetails";
import CommonCCForm from "../../pages/Event/components/common/CommonCCForm";
import { useApiPaymentStatuses } from "../../pages/Event/hooks/useApiPaymentStatuses";
import { usePaymentInfoWaitList } from "../../pages/Event/hooks/usePaymentInfoWaitList";
import StripePaymentForm from "../Sales/FrontdeskCheckout/StripePaymentForm";
import {
  clearSetupIntent,
  getSetupIntent,
} from "../../Actions/Stripe/StripeActions";
import Loader from "../../pages/Event/components/Loader";

const CCDetails = ({
  stripeCardNumberRef,
  stripeInstance,
  patientId,
  stripeIntent,
  getSetupIntent,
  handleSave,
  handleConvert,
  clearSetupIntent,
  posGateway,
  validation,
}) => {
  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const [
    selectedClinics,
    checkCC,
    cardNumberOnFile,
    changeCardNumber,
    clearentEmail,
    clearentZipCode,
  ] = watch([
    "selectedClinics",
    "checkCC",
    "cardNumberOnFile",
    "changeCardNumber",
    "clearentEmail",
    "clearentZipCode",
  ]);

  const { paymentStatuses } = useApiPaymentStatuses();
  const [showLoader, setShowLoader] = useState(false);

  const { isCanTakePayment, clearentPublicKey } = usePaymentInfoWaitList({
    paymentStatuses,
    clinics: selectedClinics,
  });

  const clearentClinic = useMemo(() => {
    return clearentPublicKey ? { publickey: clearentPublicKey } : {};
  }, [clearentPublicKey]);

  const onClearentZipCodeChange = useCallback(
    (nextClearentZipCode) => {
      setValue("clearentZipCode", nextClearentZipCode);
    },
    [setValue],
  );

  const onClearentEmailChange = useCallback(
    (nextClearentEmail) => {
      setValue("clearentEmail", nextClearentEmail);
    },
    [setValue],
  );

  const onToggleCCForm = useCallback(async () => {
    if (posGateway === "stripe") {
      setShowLoader(true);
      await getSetupIntent({
        patient_id: patientId,
        clinic_id: selectedClinics[0].id,
      });
      setShowLoader(false);
    }

    setValue("changeCardNumber", !changeCardNumber);
  }, [setValue, changeCardNumber]);

  const toggleCheckCreditCard = useCallback(async () => {
    if (posGateway === "stripe" && checkCC) {
      clearSetupIntent();
    }

    setValue("changeCardNumber", false);
    setValue("checkCC", !checkCC);
  }, [setValue, checkCC]);

  const displaySavedCCText = useMemo(
    () => Boolean(cardNumberOnFile && !changeCardNumber && !checkCC),
    [cardNumberOnFile, changeCardNumber, checkCC],
  );

  return (
    <div className="cc-details">
      {isCanTakePayment && (
        <div className="schedule-waitlist pos-fields">
          <div className="schedule-slots row mx-0 flex">
            <span
              className="items-center gap-3 cursor-pointer col-xs-6 p-l-0"
              onClick={toggleCheckCreditCard}
            >
              <input
                type="checkbox"
                name="check-cc"
                checked={checkCC}
                className="filled-checkbox"
              />
              <label>Enter Credit Card Details</label>
            </span>
            {displaySavedCCText && (
              <span className="col-xs-6 ">({cardNumberOnFile})</span>
            )}
          </div>
        </div>
      )}

      {checkCC && (
        <>
          {!(cardNumberOnFile && !changeCardNumber) &&
            stripeIntent &&
            stripeIntent.setupIntentId &&
            posGateway === "stripe" && (
              <StripePaymentForm
                type="setupIntent"
                onSubmit={handleSave}
                onCancel={handleConvert}
                validation={validation}
                onSubmitText="Save"
                onCancelText="Convert to Appointment"
              />
            )}
          {!(cardNumberOnFile && !changeCardNumber) &&
            posGateway !== "stripe" && (
              <CommonCCForm
                ref={stripeCardNumberRef}
                stripeInstance={stripeInstance}
                isClearentZipError={Boolean(errors.clearentZipCode)}
                clearentZip={clearentZipCode}
                onClearentZipChange={onClearentZipCodeChange}
                isClearentEmailError={Boolean(errors.clearentEmail)}
                clearentEmail={clearentEmail}
                onClearentEmailChange={onClearentEmailChange}
                clearentClinic={clearentClinic}
                size="small"
              />
            )}
          {cardNumberOnFile && !changeCardNumber && (
            <CommonCCDetails
              cardNumber={cardNumberOnFile}
              toggleCCForm={onToggleCCForm}
              isClearentZipError={Boolean(errors.clearentZipCode)}
              clearentZip={clearentZipCode}
              onClearentZipChange={onClearentZipCodeChange}
              isClearentEmailError={Boolean(errors.clearentEmail)}
              clearentEmail={clearentEmail}
              onClearentEmailChange={onClearentEmailChange}
              size="small"
            />
          )}
        </>
      )}
      {showLoader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  stripeIntent: state.StripeReducer.stripeIntent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSetupIntent,
      clearSetupIntent,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CCDetails));
