/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import $ from "jquery";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchSupplier,
  exportEmptyData,
  createEditSupplier,
  deleteSupplier,
} from "../../../Actions/Inventory/inventoryActions.js";
import { autoScrolling } from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import CreateEditSupplierModal from "./CreateEditSupplierModal.js";
import InventorySidebar from "../InventorySidebar.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class ViewEditSupplier extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      status: "",
      showLoadingText: false,
      userData: "",
      page: 1,
      pagesize: 15,
      sortby: "",
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      supplierDetails: {},
      orderLisr: [],
      loadMore: true,
      startFresh: true,
      showLoader: false,
      email: "",
      showModal: false,
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      pageNum: 1,
      showEditModal: false,
      defaultCountry: localStorage.getItem("cCode")
        ? localStorage.getItem("cCode")
        : "us",
      supplierId: this.props.match.params.id ? this.props.match.params.id : 0,
      isShowSupplierModal: false,
      clinicList: [],
      clinic_account_number: [],

      isAccordionOpen: false,
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    let supplierId = this.state.supplierId;
    let isRender = false;
    if (!supplierId) {
      isRender = true;
    }
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      user_Create_UserBtn: languageData.settings["user_Create_UserBtn"],
      user_First_Name: languageData.settings["user_First_Name"],
      user_Role: languageData.settings["user_Role"],
      user_Last_Name: languageData.settings["user_Last_Name"],
      clinics_Search: languageData.settings["clinics_Search"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
      user_Status: languageData.settings["user_Status"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      setting_create_user_button_error__trail_account_type:
        languageData.settings[
          "setting_create_user_button_error__trail_account_type"
        ],
      isRender: isRender,
    });
    this.setState({ showLoader: true });
    autoScrolling(true);
    this.props.fetchSupplier(supplierId);

    const _self = this;
    $("#accordionClinicAccounsView").on(
      "hidden.bs.collapse, hide.bs.collapse",
      function () {
        _self.setState({ isAccordionOpen: false });
      },
    );
    $("#accordionClinicAccounsView").on(
      "shown.bs.collapse, show.bs.collapse",
      function () {
        _self.setState({ isAccordionOpen: true });
      },
    );
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  handleSubmit = (event, value) => {
    let role_id = this.state.role_id;
    let status = this.state.inActive ? "Inactive" : "Active";
    if (event === "role_id") {
      role_id = value;
    } else if (event === "role_id_inactive") {
      role_id = value;
    } else if (event) {
      event.preventDefault();
    } else localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        status: status,
        page: 1,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        sortorder: this.state.sortorder,
        term: this.state.term,
      },
    };

    if (role_id) {
      formData.params.role_id = role_id;
    }
    this.setState({
      page: 1,
      sortby: this.state.sortby,
      pagesize: this.state.pagesize,
      sortorder: this.state.sortorder == "asc" ? "asc" : "desc",
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      orderLisr: [],
      showLoader: true,
    });
    autoScrolling(true);
  };

  onSort = (sortby) => {
    let sortorder = this.state.sortorder === "asc" ? "desc" : "asc";
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      orderLisr: [],
    });
    localStorage.setItem("sortOnly", true);
    autoScrolling(true);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.exportEmptyData();
      return { showLoader: false };
    }

    if (
      nextProps.supplierDetails != undefined &&
      nextProps.supplierDetailsTime !== prevState.supplierDetailsTime
    ) {
      returnState.showLoader = false;
      returnState.supplierDetailsTime = nextProps.supplierDetailsTime;
      returnState.supplierDetails = nextProps.supplierDetails;
      returnState.countryList = nextProps.supplierDetails.countries
        ? nextProps.supplierDetails.countries
        : [];
      returnState.supplierName = nextProps.supplierDetails.supplier_name;
      returnState.supplierEmail = nextProps.supplierDetails.supplier_email;
      returnState.supplierPhone = nextProps.supplierDetails.supplier_phone;
      returnState.supplierAddress = nextProps.supplierDetails.address_1;
      returnState.supplierCity = nextProps.supplierDetails.city;
      returnState.supplierState = nextProps.supplierDetails.state;
      returnState.supplierCountry = nextProps.supplierDetails.country;
      returnState.countryName = nextProps.supplierDetails.country_name
        ? nextProps.supplierDetails.country_name
        : "";
      returnState.supplierZip = nextProps.supplierDetails.zipcode;
      returnState.firstName =
        nextProps.supplierDetails.contact_person_firstname;
      returnState.lastName = nextProps.supplierDetails.contact_person_lastname;
      returnState.email = nextProps.supplierDetails.contact_person_email;
      returnState.contactNumber =
        nextProps.supplierDetails.contact_person_phone;
      returnState.status =
        nextProps.supplierDetails.status == 0 ? "Active" : "Inactive";
      returnState.isRender = true;

      returnState.account_number = nextProps.supplierDetails.account_number;
      returnState.distributor_name = nextProps.supplierDetails.distributor_name;
      returnState.ordering_phone = nextProps.supplierDetails.ordering_phone;
      returnState.ordering_url = nextProps.supplierDetails.ordering_url;

      returnState.clinicList = nextProps.supplierDetails.clinics
        ? nextProps.supplierDetails.clinics
        : [];
      const clinic_account_number_temp = nextProps.supplierDetails
        .clinic_account_number
        ? nextProps.supplierDetails.clinic_account_number
        : [];
      let clinic_account_number = [];
      returnState.clinicList.map((obj) => {
        let accountNumber = {
          account_number: "",
          account_numberClass: "simpleInput",
          clinic_id: obj.id,
          clinic_name: obj.clinic_name,
        };
        const clinicData = clinic_account_number_temp.find(
          (x) => x.clinic_id == obj.id,
        );
        if (clinicData) {
          accountNumber.id = clinicData.id;
          accountNumber.account_number = clinicData.account_number;
        }
        clinic_account_number.push(accountNumber);
      });
      returnState.clinic_account_number = clinic_account_number;

      let purchaseOrders = nextProps.supplierDetails.purchase_orders
        ? nextProps.supplierDetails.purchase_orders
        : [];
      if (purchaseOrders.length > 0) {
        purchaseOrders.map((obj) => {
          let total = 0;
          let totalReceived = 0;
          if (obj.purchase_order_items && obj.purchase_order_items.length > 0) {
            if (obj.purchase_order_items.length == 1) {
              obj.product_name =
                obj.purchase_order_items &&
                obj.purchase_order_items[0] &&
                obj.purchase_order_items[0].products
                  ? obj.purchase_order_items[0].products.product_name
                  : "";
            } else {
              obj.product_name =
                obj.purchase_order_items &&
                obj.purchase_order_items[0] &&
                obj.purchase_order_items[0].products
                  ? obj.purchase_order_items[0].products.product_name +
                    " & more"
                  : "";
            }
            obj.purchase_order_items.map((objInner) => {
              total += parseFloat(objInner.units);
              totalReceived += parseFloat(objInner.units_receivied);
            });
            obj.total_units = total;
            obj.total_units_received = totalReceived;
          }
        });
      }
      returnState.orderLisr = purchaseOrders;
      if (
        nextProps.supplierUpdate !== undefined &&
        nextProps.supplierUpdate == true
      ) {
        returnState.showEditModal = false;
        returnState.isShowSupplierModal = false;
      }

      return returnState;
    }
    if (nextProps.redirect !== undefined && nextProps.redirect == true) {
      returnState.showModal = false;
      returnState.isShowSupplierModal = false;

      nextProps.history.push(`/inventory/suppliers`);
    }
    if (nextProps.userChanged !== undefined && nextProps.userChanged == true) {
      returnState.showLoader = false;
      returnState.userChanged = false;
    }
    autoScrolling(false);
    nextProps.exportEmptyData();

    return null;
  }

  supplierDetails = () => {
    return <div>{this.props.history.push(`/inventory/suppliers`)}</div>;
  };

  redirectToSuppliers = () => {
    return <div>{this.props.history.push(`/inventory/suppliers`)}</div>;
  };

  deleteSupplier = () => {
    this.setState({ showModal: true });
  };
  dismissDelModal = () => {
    this.setState({ showModal: false });
  };

  deleteFinal = () => {
    let supplierId = this.state.supplierId;
    this.props.deleteSupplier(supplierId);
  };

  orderDetails = (id) => {
    return (
      <div>
        {this.props.history.push(`/inventory/purchase-order/view/${id}`)}
      </div>
    );
  };
  addNewOrder = () => {
    let supplierId = this.state.supplierId;

    return (
      <div>
        {this.props.history.push(`/inventory/purchase-order/add/${supplierId}`)}
      </div>
    );
  };

  showSupplierModal = () => {
    this.setState({ isShowSupplierModal: true });
  };
  dismissSupplierModal = () => {
    this.setState({ isShowSupplierModal: false });
  };
  createEditSupplier = (formData) => {
    this.setState({ showLoader: true });
    this.props.createEditSupplier(formData);
  };

  render() {
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <InventorySidebar />
            {this.state.isShowSupplierModal === true && (
              <CreateEditSupplierModal
                createEditSupplier={this.createEditSupplier}
                dismissSupplierModal={this.dismissSupplierModal}
                deleteSupplier={this.deleteSupplier}
                countryList={this.state.countryList}
                supplierId={this.state.supplierId}
                supplierDetails={this.state.supplierDetails}
                clinicList={this.state.clinicList}
                clinic_account_number={this.state.clinic_account_number}
              />
            )}
            <div className="business-setion memberWalletOuter">
              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span
                      className="cursor-pointer"
                      onClick={this.redirectToSuppliers}
                    >
                      {"Suppliers"}
                    </span>{" "}
                    <span className="breadCrumb">
                      <i className="fa fa-chevron-right" />{" "}
                      <span className="breadCrumb-text">
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.supplier_name
                          : "Unknown"}
                      </span>
                    </span>
                    <div className="mainRightActions">{this.state.status}</div>
                  </div>
                </div>
              </div>
              <div className="juvly-section supplierInformation">
                <a
                  onClick={this.showSupplierModal}
                  className="new-blue-btn no-width editSupplieInfoBtn"
                >
                  Edit
                </a>
                <div className="row">
                  <div className="col-sm-6 supplierDetailsLeft">
                    <div className="stockTransferTitle m-b-5 no-border">
                      {this.state.inventoryLang.supplier_info}
                    </div>
                    <div className="detail-row">
                      <label>
                        {this.state.inventoryLang.inventory_supplier_name}:
                      </label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.supplier_name
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>{"Sold-To Account Number"}:</label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.account_number
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>{this.state.inventoryLang.email}:</label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.supplier_email
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>{this.state.settingsLang.pos_phone}:</label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.supplier_phone
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>{this.state.settingsLang.pos_address}:</label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.address_1
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>{this.state.settingsLang.pos_city}:</label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.city
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>{this.state.settingsLang.editUsers_State}:</label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.state
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>
                        {this.state.settingsLang.editUsers_Country}:
                      </label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.countryName
                            ? this.state.countryName
                            : this.state.supplierDetails.country
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>
                        {this.state.settingsLang.editUsers_Zip_Code}:
                      </label>
                      <span>
                        {this.state.supplierDetails
                          ? this.state.supplierDetails.zipcode
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="supplier-contact-person contactPersonBorder">
                      <div className="stockTransferTitle m-b-5 no-border">
                        {this.state.inventoryLang.contact_person}
                      </div>
                      <div className="detail-row">
                        <label>{this.state.inventoryLang.name_label}:</label>
                        <span>
                          {this.state.supplierDetails
                            ? this.state.supplierDetails
                                .contact_person_firstname +
                              " " +
                              this.state.supplierDetails.contact_person_lastname
                            : ""}
                        </span>
                      </div>
                      <div className="detail-row">
                        <label>{this.state.inventoryLang.email}:</label>
                        <span>
                          {this.state.supplierDetails
                            ? this.state.supplierDetails.contact_person_email
                            : ""}
                        </span>
                      </div>
                      <div className="detail-row">
                        <label>{this.state.settingsLang.pos_phone}:</label>
                        <span>
                          {this.state.supplierDetails
                            ? this.state.supplierDetails.contact_person_phone
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="supplier-order-information">
                      <div className="stockTransferTitle m-b-5 no-border">
                        {"Order Information"}
                      </div>
                      <div className="detail-row">
                        <label>{"Distributor Name"}:</label>
                        <span>
                          {this.state.supplierDetails
                            ? this.state.supplierDetails.distributor_name
                            : ""}
                        </span>
                      </div>
                      <div className="detail-row">
                        <label>{"Ordering Phone"}:</label>
                        <span>
                          {this.state.supplierDetails
                            ? this.state.supplierDetails.ordering_phone
                            : ""}
                        </span>
                      </div>
                      <div className="detail-row">
                        <label>{"Ordering URL"}:</label>
                        <span>
                          {this.state.supplierDetails
                            ? this.state.supplierDetails.ordering_url
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12  m-t-10">
                    <div
                      className="supplierAccordion"
                      data-toggle="collapse"
                      href="#accordionClinicAccounsView"
                      role="button"
                      aria-expanded="false"
                      aria-controls="accordionClinicAccounsView"
                    >
                      <label
                        className={
                          this.state.isAccordionOpen === true
                            ? "active border-top"
                            : "border-top"
                        }
                      >
                        {"Ship-To Account Number"}
                      </label>
                    </div>

                    <div
                      className="row collapse"
                      id="accordionClinicAccounsView"
                    >
                      <div className="col-sm-12 col-xs-12">
                        {this.state.clinic_account_number &&
                          this.state.clinic_account_number.length > 0 && (
                            <div
                              className={"setting-container no-padding p-b-0 "}
                              id="clinic-acount-number-form-title"
                            >
                              <div
                                className={
                                  "table-responsive fixed-header-table m-t-15"
                                }
                              >
                                <table className="table-updated juvly-table no-hover no-td-border no-border table-min-width m-b-25 table-fixed">
                                  <thead className="table-updated-thead">
                                    <tr>
                                      <th className="table-updated-th col-xs-6">
                                        {
                                          this.state.inventoryLang
                                            .inventory_clinic
                                        }
                                      </th>
                                      <th className="table-updated-th text-left col-xs-5">
                                        {"Account Number"}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ajax_body">
                                    <Scrollbars
                                      autoHeight
                                      autoHeightMax={250}
                                      className="full-width"
                                      renderTrackHorizontal={(props) => (
                                        <div
                                          {...props}
                                          style={{ display: "none" }}
                                          className="track-horizontal"
                                        />
                                      )}
                                    >
                                      {this.state.clinic_account_number.map(
                                        (obj, index) => {
                                          return (
                                            <tr
                                              className="table-updated-tr"
                                              key={
                                                "clinicsAccountNumbers-" + index
                                              }
                                            >
                                              <td className="table-updated-td  col-xs-6">
                                                {obj.clinic_name}
                                              </td>
                                              <td className="table-updated-td  col-xs-6">
                                                {obj.account_number
                                                  ? obj.account_number
                                                  : "N/A"}
                                              </td>
                                            </tr>
                                          );
                                        },
                                      )}
                                    </Scrollbars>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="juvly-section full-width auto-height">
                <div className="setting-search-outer">
                  <span className="newTableTitle">
                    {this.state.inventoryLang.previous_orders}
                  </span>
                  <div className="header-select pull-right">
                    <a
                      className="new-blue-btn pull-right"
                      onClick={this.addNewOrder}
                    >
                      {this.state.inventoryLang.place_new_order}
                    </a>
                  </div>
                </div>
                <div className="table-responsive fixed-header-table">
                  <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-2 table-updated-th">PO#</th>
                        <th className="col-xs-3 table-updated-th">
                          Order Date & Time
                        </th>
                        <th className="col-xs-2 table-updated-th">Product</th>
                        <th className="col-xs-1 table-updated-th">
                          Order Quantity
                        </th>
                        <th className="col-xs-2 table-updated-th no-padding-right">
                          Received Quantity
                        </th>
                        <th className="col-xs-1 table-updated-th p-l-0 p-r-0">
                          Total
                        </th>
                        <th className="col-xs-1 table-updated-th p-l-0 p-r-0"></th>
                      </tr>
                    </thead>
                    <Scrollbars
                      className="custome-scroll"
                      autoHeight
                      autoHeightMax={376}
                    >
                      <tbody
                        className={
                          this.state.inActive ? "no-display" : "ajax_body"
                        }
                      >
                        {this.state.orderLisr !== undefined &&
                          this.state.orderLisr.length > 0 &&
                          this.state.orderLisr.map((obj, idx) => {
                            return (
                              <tr className="table-updated-tr" key={idx}>
                                <td
                                  className="col-xs-2 table-updated-td word-break"
                                  data-id={obj.id}
                                >
                                  {obj.po_number}
                                </td>
                                <td
                                  className="col-xs-3 table-updated-td word-break"
                                  data-id={obj.id}
                                >
                                  {obj.placed_on}
                                </td>
                                <td
                                  className="col-xs-2 table-updated-td word-break"
                                  data-id={obj.id}
                                >
                                  {obj.product_name}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td text-left"
                                  data-id={obj.id}
                                >
                                  {obj.total_units}
                                </td>
                                <td
                                  className="col-xs-2 table-updated-td"
                                  data-id={obj.id}
                                >
                                  {obj.total_units_received}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td p-l-0 p-r-0"
                                  data-id={obj.id}
                                >
                                  {obj.total}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td p-l-0 p-r-0"
                                  data-id={obj.id}
                                >
                                  <a
                                    className="easy-link p-l-0"
                                    key={idx}
                                    onClick={this.orderDetails.bind(
                                      this,
                                      obj.id,
                                    )}
                                  >
                                    Detail
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        {this.state.showLoader === false &&
                          (!this.state.orderLisr ||
                            this.state.orderLisr.length <= 0) && (
                            <tr>
                              <td
                                colSpan={6}
                                className="col-xs-12 table-updated-td text-center "
                              >
                                <div
                                  className={
                                    this.state.orderLisr &&
                                    this.state.orderLisr.length
                                      ? "no-record no-display"
                                      : "no-record"
                                  }
                                >
                                  {this.state.clinic_No_Record_Found}
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Scrollbars>
                  </table>
                </div>
              </div>
              {/*   Active MODAL - START  */}
              <div className={this.state.showModal ? "overlay" : ""}></div>
              <div
                id="filterModal"
                role="dialog"
                className={
                  this.state.showModal
                    ? "modal fade in displayBlock"
                    : "modal fade"
                }
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={this.dismissDelModal}
                      >
                        ×
                      </button>
                      <h4 className="modal-title" id="model_title">
                        {this.state.settingsLang.settings_Confirmation_required}
                        {this.state.showModal}
                      </h4>
                    </div>
                    <div
                      id="errorwindow"
                      className="modal-body add-patient-form filter-patient"
                    >
                      {"Are you sure you want to delete this supplier?"}
                    </div>
                    <div className="modal-footer">
                      <div className="col-md-12 text-left" id="footer-btn">
                        <button
                          type="button"
                          className="btn  logout pull-right"
                          data-dismiss="modal"
                          onClick={this.dismissDelModal}
                        >
                          {this.state.settingsLang.no_option}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success pull-right m-r-10"
                          data-dismiss="modal"
                          onClick={this.deleteFinal}
                        >
                          {this.state.settingsLang.yes_option}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*   Active MODAL - END  */}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "ADD_EDIT_SUPPLIER") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.supplierDetails = state.InventoryReducer.data.data;
      returnState.supplierDetailsTime = new Date();
      returnState.supplierUpdate = true;
      uiNotification.success("Supplier updated successfully");
    }
  }

  if (state.InventoryReducer.action === "SUPPLIER_DETAILS") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.supplierDetails = state.InventoryReducer.data.data;
      returnState.supplierDetailsTime = new Date();
    }
  }

  if (state.SettingReducer.action === "USERS_SEARCH") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.userSearch = state.SettingReducer.data.data;
    }
  }

  if (state.InventoryReducer.action === "DELETE_SUPPLIER") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.redirect = true;
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSupplier: fetchSupplier,
      exportEmptyData: exportEmptyData,
      createEditSupplier: createEditSupplier,
      deleteSupplier: deleteSupplier,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewEditSupplier);
