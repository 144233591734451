import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getProcedureNotes(formData) {
  return async (dispatch) => {
    return http
      .get("procedure-notes", formData)
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_NOTES", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_PROCEDURE_NOTES", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveProcedureNote(formData, noteID) {
  noteID = noteID || 0;

  if (noteID && noteID > 0) {
    return async (dispatch) => {
      return http
        .patch("procedure-notes/" + noteID, formData)
        .then((response) => {
          dispatch({
            type: "IS_RELOAD_CLIENT_PROCEDURES",
            isReloadClientProcedure: true,
          });
          dispatch({ type: "UPDATE_PROCEDURE_NOTE", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "UPDATE_PROCEDURE_NOTE",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    };
  } else {
    return async (dispatch) => {
      return http
        .post("procedure-notes", formData)
        .then((response) => {
          dispatch({
            type: "IS_RELOAD_CLIENT_PROCEDURES",
            isReloadClientProcedure: true,
          });
          dispatch({ type: "SAVE_PROCEDURE_NOTES", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "SAVE_PROCEDURE_NOTES",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    };
  }
}

export function deleteProcedureNote(noteID) {
  return async (dispatch) => {
    return http
      .delete("procedure-notes/" + noteID)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "DELETE_PROCEDURE_NOTE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_PROCEDURE_NOTE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function getAProcedureNote(noteID) {
  return async (dispatch) => {
    return http
      .get("procedure-notes/" + noteID)
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_NOTE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_PROCEDURE_NOTE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}
