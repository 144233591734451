import { ACTION_TYPES } from "./consts";

export const actions = {
  getBetaFeatures: (betaFeatures) => ({
    type: ACTION_TYPES.getBetaFeatures,
    payload: betaFeatures,
  }),
  setBetaFeatures: (feature) => ({
    type: ACTION_TYPES.setBetaFeatures,
    payload: feature,
  }),
};
