import React, { useEffect } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import ViewTipsModal from "../../../../ViewTipsModal/ViewTipsModal";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { svg } from "../../../../../../../../assets/svg";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import RemainingBalanceDue from "../../../../../shared/RemainingBalanceDue/RemainingBalanceDue";
import { useChargeForm } from "../../../../../hooks/useChargeForm";
import { composeAmountSchema } from "../../../../../SalesCheckoutInvoice.schemas";
import { dispatch } from "../../../../../../../../store/store";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";
import { getStripePaymentIntent } from "../../../../../../../../Actions/Stripe/StripeActions";

const PaymentTipsModal = ({
  selectedCard,
  showLoader,
  showTipsModal,
  isTipsApplying,
  setShowLoader,
  getStripePaymentIntent,
}) => {
  const { invoice, amountToPay, refetchInvoice } = useInvoice();

  const completePayment = async (amount = form.amount) => {
    let stripeFormData = {};
    stripeFormData.payment_method_id = selectedCard.value;
    stripeFormData.invoice_id = invoice.id;
    stripeFormData.amount = amount;

    setShowLoader(true);
    try {
      await getStripePaymentIntent(stripeFormData);
      refreshInvoiceDetails();
    } catch (error) {
      uiNotification.error(error.message);
      showTipsModal.update(false);
    }
  };

  const refreshInvoiceDetails = async () => {
    await refetchInvoice();

    let invoiceDetailInterval = setInterval(() => {
      refetchInvoice().then((response) => {
        if (response?.data?.status !== "pending") {
          clearInterval(invoiceDetailInterval);
          dispatch(checkoutInvoice.actions.paymentViewClose());
          uiNotification.success("Payment done successfully");
          setShowLoader(false);
        }
      });
    }, 2000);
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
  });

  const { form, errors, changeFormValue, isValid, submit } = useChargeForm({
    schema,
    onSubmit: () => {
      completePayment();
    },
    initialValues: {
      amount: amountToPay || "",
    },
  });

  const onClose = () => {
    showTipsModal.update(false);
  };

  useEffect(() => {
    changeFormValue("amount", amountToPay);
  }, [amountToPay]);

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
      onCharge={submit}
      isCharging={showLoader}
      chargeLabel={tCommon("label.charge")}
      amount={form.amount}
      isChargeDisabled={isTipsApplying || !isValid}
    >
      <ViewTipsModal amountToPay={form.amount} />
      <div className="p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={errors.amount}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {errors.amount && <InputError>{errors.amount}</InputError>}
      </div>
      <div className="col-sm-12 p-b-10">
        <RemainingBalanceDue amount={form.amount} />
      </div>
    </ModalCharge>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getStripePaymentIntent }, dispatch);

export default connect(null, mapDispatchToProps)(PaymentTipsModal);
