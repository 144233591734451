import React from "react";
import cx from "clsx";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./AvailableBalance.module.scss";

export function AvailableBalance({ amount, className }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={cx(classes.root, className)}>
      {tCommon("label.availableBalance")}: <b>{amount}</b>
    </div>
  );
}

AvailableBalance.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
