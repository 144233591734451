import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function fetchDotPhrases(formData) {
  return (dispatch) => {
    http
      .get("dot-phrase", formData)
      .then((response) => {
        dispatch({ type: "GET_PHRASE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PHRASE_DATA", payload: error.response.data });
      });
  };
}

export function deleteDotPhrase(id) {
  return async (dispatch) => {
    return http
      .delete("dot-phrase/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_PHRASE_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "DELETE_PHRASE_DATA", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveDotPhrase(formData) {
  return (dispatch) => {
    http
      .post("dot-phrase", formData)
      .then((response) => {
        dispatch({ type: "SAVE_PHRASE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_PHRASE_DATA", payload: error.response.data });
      });
  };
}

export function getDotPhrase(id) {
  return (dispatch) => {
    http
      .get("dot-phrase/" + id)
      .then((response) => {
        dispatch({ type: "GET_A_PHRASE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_A_PHRASE_DATA", payload: error.response.data });
      });
  };
}

export function updateDotPhrase(formData, id) {
  return (dispatch) => {
    http
      .put("dot-phrase/" + id, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_PHRASE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_PHRASE_DATA", payload: error.response.data });
      });
  };
}
