import React, { useMemo, useState } from "react";
import { Table } from "../../../../../shared/Table/Table";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import classes from "../../../newBusinessInsightsContent.module.scss";
import styles from "./scss/eGiftCardRevenue.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { useeGiftCardRevenue } from "./hooks/useeGiftCardRevenue";
import { HTTP_ENDPOINTS, PERMISSIONS } from "../../../../../consts/api";
import { history } from "../../../../../history";
import { BI_ROUTES } from "../../../../../consts/routes";
import { ModalEditGiftCardExpiryDate } from "../../../../../widgets/ModalEditGiftCardExpiryDate/ModalEditGiftCardExpiryDate";
import { ModalEditGiftCardBalance } from "../../../../../widgets/ModalEditGiftCardBalance/ModalEditGiftCardBalance";
import { usePermission } from "../../../../../hooks/usePermission";
import { useResendGiftCardEmail } from "./hooks/useResendGiftCardEmail";
import { tCommon } from "../../../../../i18n/useAppTranslation";

const modalInfo = {
  title: "egiftCardRevenueReport.info.title",
  rows: ["egiftCardRevenueReport.info.note"],
};

const EGiftCardRevenue = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const { data, isLoading, refetch } = useeGiftCardRevenue(requestParams);

  const { mutate: resendEmail } = useResendGiftCardEmail();

  const [editExpireCardData, setEditExpireCardData] = useState(null);
  const [editBalanceCardData, setEditBalanceCardData] = useState(null);

  const tableDataEmpty = !isLoading && !data?.reportData?.length;

  const editGiftCardBalancePermission = usePermission(
    PERMISSIONS.patientManagement.editGiftCardBalances,
  );

  const isEditGiftCardBalancePermitted =
    editGiftCardBalancePermission.loaded &&
    editGiftCardBalancePermission.permitted;

  const tableData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        name: data.name,
        purchasedOn: data.purchased_on,
        buyer: <div dangerouslySetInnerHTML={{ __html: data.from }} />,
        recipient: <div dangerouslySetInnerHTML={{ __html: data.to }} />,
        code: data.redemption_code,
        amount: data.amount,
        price: data.price,
        balance: data.balance,
        expiryDate: data.expiry_date,
        actions: (
          <div>
            {isEditGiftCardBalancePermitted && (
              <>
                <button
                  className="easy-link no-padding text-no-wrap"
                  onClick={() =>
                    setEditBalanceCardData({
                      id: data.egift_voucher_id,
                      name: data.name,
                      purchaseDate: data.purchased_on,
                      recipient: data.to,
                      expireDate: data.expiry_date,
                      balance: data.balance,
                    })
                  }
                >
                  Edit Balance
                </button>
                <button
                  className="easy-link no-padding text-no-wrap"
                  onClick={() =>
                    setEditExpireCardData({
                      id: data.egift_voucher_id,
                      name: data.name,
                      purchaseDate: data.purchased_on,
                      recipient: data.to,
                      expireDate: data.expiry_date,
                      balance: data.balance,
                    })
                  }
                >
                  Expire Card
                </button>
                <button
                  className="easy-link no-padding text-no-wrap"
                  onClick={() =>
                    history.push(
                      BI_ROUTES.giftCardHistory(data.egift_voucher_id),
                    )
                  }
                >
                  View History
                </button>
              </>
            )}
            <button
              className="easy-link no-padding text-no-wrap"
              onClick={() => resendEmail(data.resend_email)}
            >
              Resend Email
            </button>
          </div>
        ),
      })),
    [data, isEditGiftCardBalancePermitted],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const totalDatas = [
    ...(tableData || []),
    !tableDataEmpty &&
      !isLoading && {
        name: <span className="bold">Total</span>,
        amount: <span className="bold">{data?.total.amount}</span>,
        price: <span className="bold">{data?.total.price}</span>,
        balance: <span className="bold">{data?.total.balance}</span>,
        purchasedOn: tCommon("symbol.longDash"),
        buyer: tCommon("symbol.longDash"),
        recipient: tCommon("symbol.longDash"),
        code: tCommon("symbol.longDash"),
        expiryDate: tCommon("symbol.longDash"),
      },
  ].filter(Boolean);

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="eGift Cards Revenue"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getEgiftCardRevenue()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            colClassName={styles.tableCol}
            cols={[
              { data: "Name", accessor: "name" },
              { data: "Purchased On", accessor: "purchasedOn" },
              { data: "Buyer", accessor: "buyer" },
              { data: "Recipient", accessor: "recipient" },
              { data: "Code", accessor: "code" },
              { data: "Amount", accessor: "amount" },
              { data: "Price", accessor: "price" },
              { data: "Balance", accessor: "balance" },
              { data: "Expiry Date", accessor: "expiryDate" },
              { data: "Actions", accessor: "actions" },
            ]}
            data={totalDatas}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
      {editExpireCardData && (
        <ModalEditGiftCardExpiryDate
          isOpen
          onClose={() => setEditExpireCardData(null)}
          giftCardData={editExpireCardData}
          onSuccess={() => {
            setEditExpireCardData(null);
            refetch();
          }}
        />
      )}
      {editBalanceCardData && (
        <ModalEditGiftCardBalance
          isOpen
          onClose={() => setEditBalanceCardData(null)}
          giftCardData={editBalanceCardData}
          onSuccess={() => {
            setEditBalanceCardData(null);
            refetch();
          }}
        />
      )}
    </div>
  );
};

export default EGiftCardRevenue;
