import React, { useMemo } from "react";
import { useQuestionnairesQuery } from "../../../../../../api/questionnaires/useQuestionnairesQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import {
  PATIENT_QUESTIONNAIRE_ENTITY_TYPES,
  usePatientQuestionnairesQuery,
} from "../../../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery";
import { useProcedureQuery } from "../../../../../../api/procedure/useProcedureQuery";
import { mergeDocumentOptions } from "../Documents.utils";
import { QUESTIONNAIRE_TYPES } from "../../../../../../consts/api";
import { QuestionnaireIcons } from "../../../../../../assets/Icons/QuestionnaireIcons";
import { procedureDocumentId } from "../../../utilities";

export function useQuestionnaireOptions({
  appointmentId,
  procedureId,
  serviceId,
  select,
}) {
  const { tCommon } = useAppTranslation.Common();

  const { data: questionnaires, isLoading: isQuestionnairesLoading } =
    useQuestionnairesQuery({
      options: {
        onError: () => {
          uiNotification.error(tCommon("error.questionnaires.fetchAll"));
        },
      },
    });

  const { data: procedure, isFetching: isProcedureLoading } = useProcedureQuery(
    {
      payload: {
        procedureId,
      },
      options: {
        enabled: Boolean(procedureId),
      },
    },
  );

  const {
    data: patientQuestionnaires,
    isFetching: isPatientQuestionnairesLoading,
  } = usePatientQuestionnairesQuery({
    payload: {
      entityId: appointmentId,
      entityType: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.appointment,
      serviceId,
    },
    options: {
      enabled: Boolean(appointmentId),
      onError: () => {
        uiNotification.error(
          tCommon("error.patientQuestionnaires.fetchByAppointment"),
        );
      },
      onSuccess: (data) => {
        select(
          data?.map((i) => ({
            id: i.id,
            templateId: i.questionnaire.id,
          })) || [],
        );
      },
    },
  });

  const iconByType = {
    [QUESTIONNAIRE_TYPES.simple]: <QuestionnaireIcons.Simple />,
    [QUESTIONNAIRE_TYPES.multi]: <QuestionnaireIcons.Multi />,
  };

  const questionnaireOptions = useMemo(
    () =>
      questionnaires?.map((i) => ({
        label: i.title,
        value: String(i.id),
        icon: iconByType[i.type],
        isDisabled: Boolean(
          procedure?.patientQuestionnaires?.find(
            (pq) => pq.questionnaireId === i.id,
          ) ||
            patientQuestionnaires?.find((pq) => pq.questionnaire.id === i.id),
        ),
      })) || [],
    [questionnaires, procedure?.patientQuestionnaires, patientQuestionnaires],
  );

  const procedureQuestionnaireOptions = useMemo(
    () =>
      procedure?.patientQuestionnaires?.map((q) => ({
        label: q.name,
        value: procedureDocumentId.compose(q.questionnaireId, q.id),
        icon: iconByType[q.questionnaireType],
        isCompleted: q.isFilled,
      })) || [],
    [procedure?.patientQuestionnaires],
  );

  const patientQuestionnaireOptions = useMemo(
    () =>
      patientQuestionnaires?.map((q) => ({
        label: q.questionnaire?.title,
        value: String(q.questionnaire.id),
        icon: iconByType[q.questionnaire?.type],
        isCompleted: Boolean(q.status),
      })) || [],
    [patientQuestionnaires],
  );

  return useMemo(
    () => ({
      data: mergeDocumentOptions(
        [...procedureQuestionnaireOptions, ...patientQuestionnaireOptions],
        questionnaireOptions,
      ),
      isLoading:
        isQuestionnairesLoading ||
        isProcedureLoading ||
        isPatientQuestionnairesLoading,
      patientDocuments: [
        ...patientQuestionnaireOptions,
        ...procedureQuestionnaireOptions,
      ],
    }),
    [
      questionnaireOptions,
      procedureQuestionnaireOptions,
      patientQuestionnaireOptions,
      isQuestionnairesLoading,
      isProcedureLoading,
      isPatientQuestionnairesLoading,
    ],
  );
}
