import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { Maybe } from "../../utilities/fp";

const requestSchema = yup.object({
  procedureId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  appointment: yup
    .object({
      id: yup.number().required(),
      type: yup.string().required(),
      dateTime: yup.string().required(),
    })
    .nullable(),
  clinicId: yup.number().nullable(),
  patientId: yup.number().nullable(),
  providerId: yup.number().nullable(),
  serviceId: yup.number().nullable(),
  area: yup.string().nullable(),
  doctorName: yup.string().nullable(),
  procedureType: yup.string().nullable(),
  name: yup.string().nullable(),
  date: yup.string().nullable(),
  images: yup
    .object({
      front: yup.string().nullable(),
      image45: yup.string().nullable(),
      image45Left: yup.string().nullable(),
      image45Right: yup.string().nullable(),
      image90: yup.string().nullable(),
      image90Left: yup.string().nullable(),
      image90Right: yup.string().nullable(),
      back: yup.string().nullable(),
      back45Left: yup.string().nullable(),
      back45Right: yup.string().nullable(),
    })
    .required(),
  afterImages: yup
    .object({
      front: yup.string().nullable(),
      image45Left: yup.string().nullable(),
      image45Right: yup.string().nullable(),
      image90Left: yup.string().nullable(),
      image90Right: yup.string().nullable(),
      back: yup.string().nullable(),
      back45Left: yup.string().nullable(),
      back45Right: yup.string().nullable(),
    })
    .required(),
  patientQuestionnaires: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        questionnaireId: yup.number().required(),
        name: yup.string().nullable(),
        questionnaireType: yup.number().required(),
        isRequired: yup.boolean().required(),
        isFilled: yup.boolean().required(),
      }),
    )
    .required(),
  patientConsents: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        consentId: yup.number().required(),
        name: yup.string().nullable(),
        isRequired: yup.boolean().required(),
        isSigned: yup.boolean().required(),
      }),
    )
    .required(),
  notes: yup.string().nullable(),
  chartingCategoryId: yup.number().nullable(),
});

const composeResponse = (res) => {
  const data = res?.data?.data || {};
  const images = data?.procedure_image_data || {};
  const afterImages = data?.procedure_after_photos || {};

  return {
    id: data.id,
    appointment: Maybe.of(data.appointment)
      .map((x) => ({
        id: x.id,
        type: x.appointment_type,
        dateTime: x.appointment_datetime,
      }))
      .orElse(null)
      .value(),
    clinicId: data.clinic_id,
    providerId: data.user_id,
    serviceId: data.service_id,
    area: data.type || null,
    doctorName: data.doctor_name || null,
    procedureType: data.procedure_type || null,
    name: data.procedure_name || null,
    date: data.procedure_date || null,
    images: {
      front: images?.patient_image_front_url || null,
      image45: data?.procedure_image_45_url || null,
      image45Left: images?.patient_image_left_45_url || null,
      image45Right: images?.patient_image_right_45_url || null,
      image90: data?.procedure_image_url || null,
      image90Left: images?.patient_image_left_url || null,
      image90Right: images?.patient_image_right_url || null,
      back: images?.patient_image_back_url || null,
      back45Left: images?.patient_image_back_left_45_url || null,
      back45Right: images?.patient_image_back_right_45_url || null,
    },
    afterImages: {
      front: afterImages?.patient_image_front_url || null,
      image45Left: afterImages?.patient_image_left_45_url || null,
      image45Right: afterImages?.patient_image_right_45_url || null,
      image90Left: afterImages?.patient_image_left_url || null,
      image90Right: afterImages?.patient_image_right_url || null,
      back: afterImages?.patient_image_back_url || null,
      back45Left: afterImages?.patient_image_back_left_45_url || null,
      back45Right: afterImages?.patient_image_back_right_45_url || null,
    },
    patientQuestionnaires:
      data?.patient_questionnaires?.map((i) => ({
        id: i.id,
        questionnaireId: i.questionnaire_id,
        name: i.questionnaire?.title,
        questionnaireType: i.questionnaire?.type || 0,
        isRequired: data.required_questionnaires_ids?.includes(
          i.questionnaire_id,
        ),
        isFilled: Boolean(i.status),
      })) || [],
    patientConsents:
      data?.patient_consents?.map((i) => ({
        id: i.id,
        consentId: i.consent_id,
        name: i.consent?.consent_name,
        isRequired: data.required_consents_ids?.includes(i.consent_id),
        isSigned: Boolean(i.status),
      })) || [],
    notes: data?.note || null,
    chartingCategoryId: data?.charting_category_id || null,
  };
};

function createProcedureQueryKey(id) {
  return [QUERY_KEYS.procedure, id];
}

export function useProcedureQuery({ payload, options = {} } = {}) {
  return useQuery(
    createProcedureQueryKey(payload.procedureId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.procedure.get(req.procedureId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
