import React, { useState } from "react";
import { useDeletePosBankMutation } from "../../../../api/mutations/useDeletePosBankMutation";
import { uiNotification } from "../../../../services/UINotificationService";

export const withDeleteBankInfo = (Component) => (props) => {
  const { mutateAsync, isLoading: isDeleting } = useDeletePosBankMutation();
  const [deletingIds, setDeletingIds] = useState([]);

  const deleteById = async (id) => {
    try {
      setDeletingIds(prev => [...prev, id]);
      await mutateAsync({ bankId: id });
    } catch {
      uiNotification.error("Unable to delete bank info");
    } finally {
      setDeletingIds(prev => prev.filter(i => i !== id));
    }
  }

  return (
    <Component
      {...props}
      deleteBankInfo={{
        deleteById,
        deletingIds,
        isDeleting,
      }}
    />
  );
};
