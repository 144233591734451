import React, { useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useRefundsReportQuery } from "../../../../api/queries/useRefundsReportQuery";
import { uiNotification } from "../../../../services/UINotificationService";
import { useCurrentAccountQuery } from "../../../../api/queries/useAccountQuery";
import { CLIENT_GLOBAL_NAME } from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { LoadMoreObserver } from "../../../../shared/LoadMoreObserver/LoadMoreObserver";
import LoaderLow from "../../../Common/LoaderLow";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { DEFAULT_DATE_FORMAT } from "../../../../consts/general";

export function Refund({ clinicIds, fromDate, toDate }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const accountQuery = useCurrentAccountQuery();
  const { data: user } = useCurrentUserQuery();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useRefundsReportQuery({
      payload: {
        pageSize: 15,
        clinicIds,
        fromDate,
        toDate,
      },
      options: {
        staleTime: 0,
        onError: () => {
          uiNotification.error(tBi("refundsReport.error.fetchData"));
        },
      },
    });

  const invoices = useMemo(() => {
    if (data && data.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, i) => [...r, ...i], []);
    }
    return [];
  }, [data]);

  const formatDate = (date) => {
    return moment.utc(date).format(user?.dateFormat || DEFAULT_DATE_FORMAT);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table-updated setting-table table-min-width no-td-border  no-hover">
          <thead className="table-updated-thead">
            <tr>
              <th className="table-updated-th col">
                {accountQuery.data?.preferences?.clientGlobalName ||
                  CLIENT_GLOBAL_NAME.client}
              </th>
              <th className="table-updated-th col">
                {tBi("refundsReport.table.invoiceNumber")}
              </th>
              <th className="table-updated-th col">
                {tBi("refundsReport.table.invoiceDate")}
              </th>
              <th className="table-updated-th col">
                {tBi("refundsReport.table.refundAmount")}
              </th>
              <th className="table-updated-th col">
                {tBi("refundsReport.table.refundStatus")}
              </th>
              <th className="table-updated-th col p-l-0 p-r-0">
                {tBi("refundsReport.table.refundMode")}
              </th>
              <th className="table-updated-th col p-l-10">
                {tBi("refundsReport.table.refundDate")}
              </th>
            </tr>
          </thead>
          <tbody className="ajax_body">
            {invoices.length > 0 ? (
              invoices.map((invoice, index) => {
                return (
                  <tr
                    className="table-updated-tr"
                    key={`${index}-${invoice.invoiceNumber}`}
                  >
                    <td className="col table-updated-td word-break">
                      {invoice.patientName}
                    </td>
                    <td className="col table-updated-td word-break">
                      {invoice.invoiceNumber}
                    </td>
                    <td className="col table-updated-td word-break">
                      {formatDate(invoice.invoiceDate)}
                    </td>
                    <td className="col table-updated-td word-break">
                      {invoice.refundAmount}
                    </td>
                    <td className="col table-updated-td word-break text-capitalize">
                      {invoice.invoiceStatus}
                    </td>
                    <td className="col table-updated-td word-break p-l-0 p-r-0">
                      {invoice.refundMode}
                    </td>
                    <td className="col table-updated-td word-break p-l-10">
                      {formatDate(invoice.refundDate)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="table-updated-tr">
                <td className="table-updated-td no-record no-float" colSpan={8}>
                  {tCommon("label.noRecordFound")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {hasNextPage && (
          <LoadMoreObserver
            noPaddingBottom
            loadMore={fetchNextPage}
            isLoading={isFetchingNextPage}
          />
        )}
      </div>
      {isLoading && <LoaderLow showLoader isFullWidth={true} />}
    </>
  );
}

Refund.propTypes = {
  clinicIds: PropTypes.arrayOf(PropTypes.number),
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
};

Refund.defaultProps = {
  clinicIds: [],
};
