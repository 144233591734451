import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../shared/Modal/Modal";
import { Button } from "../../../shared/Button/Button";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { Picker } from "../Picker/Picker";
import classes from "./sass/PickerInModal.module.scss";
import { serviceCategoryPropType } from "../Picker/Picker.types";

export function PickerInModal({
  isOpen,
  onClose,
  onPick,
  isPickLoading,
  title,
  pickTitle,
  pickedIds,
  servicesByCategory,
  isServicesLoading,
}) {
  const [pickedServices, setPickedServices] = useState([]);

  const handlePick = () => {
    onPick(pickedServices);
  };

  const isNothingChanged = useMemo(
    () =>
      pickedServices.map((s) => s.id).every((id) => pickedIds.includes(id)) &&
      pickedServices.length === pickedIds.length,
    [pickedServices, pickedIds],
  );

  return (
    <Modal
      footerNoBorder
      size="large"
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Title>{title}</Modal.Title>}
      footer={
        <Button
          leftAdornment={
            isPickLoading ? (
              <CircularProgress color="white" size="small" />
            ) : undefined
          }
          onClick={handlePick}
          isDisabled={isPickLoading || isServicesLoading || isNothingChanged}
        >
          {pickTitle}
        </Button>
      }
    >
      <Picker
        pickedIds={pickedIds}
        onChange={(services) => setPickedServices(services.filter(Boolean))}
        contentClassName={classes.pickerContent}
        servicesByCategory={servicesByCategory}
        isServicesLoading={isServicesLoading}
      />
    </Modal>
  );
}

PickerInModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired,
  isPickLoading: PropTypes.bool,
  title: PropTypes.string,
  pickTitle: PropTypes.string,
  pickedIds: PropTypes.arrayOf(PropTypes.number),
  servicesByCategory: PropTypes.arrayOf(serviceCategoryPropType),
  isServicesLoading: PropTypes.bool,
};

PickerInModal.defaultProps = {
  isPickLoading: false,
  title: "Pick Services",
  pickTitle: "Pick",
  pickedIds: [],
  servicesByCategory: [],
  isServicesLoading: false,
};
