import React from "react";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { useForm } from "./hooks/useForm";
import { InputLabel } from "../../shared/InputLabel/InputLabel";
import { Select } from "../../shared/Select/Select";
import { InputError } from "../../shared/InputError/InputError";
import { Button } from "../../shared/Button/Button";
import classes from "./ModalCreateQualiphyProcedure.module.scss";
import { useEnrolledClinics } from "./hooks/useEnrolledClinics";
import { useExams } from "./hooks/useExams";
import {
  getAlreadyRequestedFormExams,
  useCreateInvite,
} from "./hooks/useCreateInvite";
import { CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS as ADDITION_FIELDS } from "../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { passOr } from "../../utilities/general";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import { AlreadyRequestedBox } from "./shared/AlreadyRequestedBox/AlreadyRequestedBox";
import { Attachments } from "./shared/Attachments/Attachments";
import { useQualiphyMessageQuery } from "../../api/qualiphy/useQualiphyMessageQuery";
import { InputText } from "./shared/InputText/InputText";
import { InputPhone } from "./shared/InputPhone/InputPhone";
import { DobPicker } from "./shared/DobPicker/DobPicker";
import { Dropdown } from "./shared/Dropdown/Dropdown";
import { GENDER_OPTIONS, STATE_OPTIONS } from "./config";
import { usePharmacyPackages } from "./hooks/usePharmacyPackages";
import { Checkbox } from "../../shared/Checkbox/Checkbox";
import { useAdditionFields } from "./hooks/useAdditionFields";
import { useQualiphyRxMessageQuery } from "../../api/qualiphy/useQualiphyRxMessageQuery";

export function ModalCreateQualiphyProcedure({
  isOpen,
  onClose,
  onSuccess,
  patientId,
  title,
  initialValues,
  appointmentId,
}) {
  const { tCommon } = useAppTranslation.Common();

  const {
    additionFields,
    setAdditionFields,
    setShippingAddressFieldsVisibility,
    setAddressFieldsVisibility,
    isLoading,
  } = useAdditionFields({ patientId });

  const createInvite = useCreateInvite({
    onSuccess,
    patientId,
    appointmentId,
    setAdditionFields,
  });

  const { clinicOptions, isClinicsFetching } = useEnrolledClinics();
  const { data: message } = useQualiphyMessageQuery();
  const { data: rxMessage } = useQualiphyRxMessageQuery();

  const {
    form,
    getError,
    setFormValue,
    isValid,
    submit,
    exams,
    sameShipping,
    patientHasState,
  } = useForm({
    onSubmit: (values) => createInvite.initiate(values),
    additionFields,
    setShippingAddressFieldsVisibility,
    setAddressFieldsVisibility,
    initialValues: initialValues || {},
    patientId,
  });

  const { examOptions, isExamsFetching } = useExams({
    clinicId: form.clinicId,
  });

  const pharmacyPackages = usePharmacyPackages({
    patientId,
    clinicId: form.clinicId,
    examIds: exams.value.map((x) => x.value),
    state: sameShipping.value ? form.state : form.shippingState,
    isEnabled: exams.isRxSelected(),
    onSuccess: (data) => {
      setFormValue("isPharmacyPackagesAvailable", data.length > 0);
    },
  });

  const alreadyRequestedExams = getAlreadyRequestedFormExams(
    exams.value,
    createInvite.alreadyRequestedExams,
  );

  const isAttachmentsRequired = exams.value.some(
    (exam) => exam.isAttachmentsRequired,
  );

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      shouldCloseOnOverlayClick={false}
      header={
        <Modal.Title>
          {title || tCommon("createQualifyProcedure.title")}
        </Modal.Title>
      }
      footer={
        <div className={classes.footer}>
          <Button variant="outlined" onClick={onClose}>
            {tCommon("label.cancel")}
          </Button>
          <Button
            onClick={submit}
            isDisabled={
              !isValid ||
              createInvite.isLoading ||
              pharmacyPackages.isLoading ||
              pharmacyPackages.isError
            }
            leftAdornment={passOr(createInvite.isLoading, undefined, () => (
              <CircularProgress size="small" color="white" />
            ))}
          >
            {tCommon("label.create")}
          </Button>
        </div>
      }
    >
      <Dropdown
        label={tCommon("label.clinic")}
        options={clinicOptions}
        value={form.clinicId}
        isLoading={isClinicsFetching}
        error={getError("clinicId")}
        onChange={(value) => {
          setFormValue("clinicId", value);
          if (exams.value.length > 0 && value !== form.clinicId) {
            setFormValue("exams", []);
          }
        }}
      />
      {form.clinicId && (
        <div>
          <InputLabel>{tCommon("label.exams")}</InputLabel>
          <Select
            isMulti
            isSearchable
            closeMenuOnSelect={false}
            options={examOptions}
            isLoading={isExamsFetching}
            isError={!!exams.getError()}
            onChange={exams.onChange}
            value={exams.value}
          />
          {!!exams.getError() && <InputError>{exams.getError()}</InputError>}
        </div>
      )}
      {isAttachmentsRequired && (
        <Attachments
          message={exams.isRxSelected() ? rxMessage : message}
          value={form.attachments}
          onChange={(next) => setFormValue("attachments", next)}
        />
      )}
      {exams.isRxSelected() && (
        <Checkbox
          isChecked={sameShipping.value}
          onChange={() => sameShipping.onToggle(!sameShipping.value)}
          label={tCommon("createQualifyProcedure.formLabel.sameShipping")}
        />
      )}
      {additionFields.some((f) => Object.values(ADDITION_FIELDS).includes(f)) &&
        !isLoading && (
          <div className={classes.additionData}>
            {additionFields.includes(ADDITION_FIELDS.firstName) && (
              <InputText
                label={tCommon("createQualifyProcedure.formLabel.firstName")}
                value={form.firstName}
                placeholder={tCommon("label.firstName")}
                onChange={(next) => setFormValue("firstName", next)}
                error={getError("firstName")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.lastName) && (
              <InputText
                label={tCommon("createQualifyProcedure.formLabel.lastName")}
                value={form.lastName}
                placeholder={tCommon("label.lastName")}
                onChange={(next) => setFormValue("lastName", next)}
                error={getError("lastName")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.email) && (
              <InputText
                label={tCommon("createQualifyProcedure.formLabel.email")}
                value={form.email}
                placeholder={tCommon("formPlaceholder.email")}
                onChange={(next) => setFormValue("email", next)}
                error={getError("email")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.phone) && (
              <InputPhone
                value={form.phone}
                onChange={(phone) => setFormValue("phone", phone)}
                error={getError("phone")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.dateOfBirth) && (
              <DobPicker
                value={form.dateOfBirth}
                onChange={(next) => setFormValue("dateOfBirth", next)}
                error={getError("dateOfBirth")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.gender) && (
              <Dropdown
                label={tCommon("createQualifyProcedure.formLabel.gender")}
                options={GENDER_OPTIONS}
                value={form.gender}
                onChange={(next) => setFormValue("gender", next)}
                error={getError("gender")}
              />
            )}
          </div>
        )}
      {(exams.isRxSelected() || !patientHasState) && (
        <Dropdown
          label={tCommon("createQualifyProcedure.formLabel.state")}
          options={STATE_OPTIONS}
          value={form.state}
          onChange={(next) => setFormValue("state", next)}
          error={getError("state")}
        />
      )}
      {exams.isRxSelected() && (
        <div className={classes.additionData}>
          <InputText
            label={tCommon("createQualifyProcedure.formLabel.city")}
            value={form.city}
            placeholder={tCommon("createQualifyProcedure.formLabel.city")}
            onChange={(next) => setFormValue("city", next)}
            error={getError("city")}
          />
          <InputText
            label={tCommon("createQualifyProcedure.formLabel.pincode")}
            value={form.pincode}
            placeholder={tCommon("createQualifyProcedure.formLabel.pincode")}
            onChange={(next) => setFormValue("pincode", next)}
            error={getError("pincode")}
          />
          <InputText
            label={tCommon("createQualifyProcedure.formLabel.address")}
            value={form.address}
            placeholder={tCommon("createQualifyProcedure.formLabel.address")}
            onChange={(next) => setFormValue("address", next)}
            error={getError("address")}
          />
        </div>
      )}
      {additionFields.some((f) => Object.values(ADDITION_FIELDS).includes(f)) &&
        exams.isRxSelected() &&
        !isLoading && (
          <div className={classes.additionData}>
            {additionFields.includes(ADDITION_FIELDS.shippingState) && (
              <Dropdown
                label={tCommon(
                  "createQualifyProcedure.formLabel.shippingState",
                )}
                options={STATE_OPTIONS}
                value={form.shippingState}
                onChange={(next) => setFormValue("shippingState", next)}
                error={getError("shippingState")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.shippingCity) && (
              <InputText
                label={tCommon("createQualifyProcedure.formLabel.shippingCity")}
                value={form.shippingCity}
                placeholder={tCommon(
                  "createQualifyProcedure.formLabel.shippingCity",
                )}
                onChange={(next) => setFormValue("shippingCity", next)}
                error={getError("shippingCity")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.shippingZipcode) && (
              <InputText
                label={tCommon(
                  "createQualifyProcedure.formLabel.shippingZipcode",
                )}
                value={form.shippingZipcode}
                placeholder={tCommon(
                  "createQualifyProcedure.formLabel.shippingZipcode",
                )}
                onChange={(next) => setFormValue("shippingZipcode", next)}
                error={getError("shippingZipcode")}
              />
            )}
            {additionFields.includes(ADDITION_FIELDS.shippingAddress) && (
              <InputText
                label={tCommon(
                  "createQualifyProcedure.formLabel.shippingAddress",
                )}
                value={form.shippingAddress}
                placeholder={tCommon(
                  "createQualifyProcedure.formLabel.shippingAddress",
                )}
                onChange={(next) => setFormValue("shippingAddress", next)}
                error={getError("shippingAddress")}
              />
            )}
          </div>
        )}
      {exams.isRxSelected() && form.isPharmacyPackagesAvailable && (
        <div>
          <InputLabel>{tCommon("label.pharmacyPackage")}</InputLabel>
          <Select
            isSearchable
            options={pharmacyPackages.options}
            isLoading={pharmacyPackages.isLoading}
            isError={!!getError("pharmacyPackage")}
            onChange={(next) => setFormValue("pharmacyPackage", next)}
            value={form.pharmacyPackage}
            isDisabled={pharmacyPackages.isLoading}
          />
          {!!getError("pharmacyPackage") && (
            <InputError>{getError("pharmacyPackage")}</InputError>
          )}
        </div>
      )}
      {alreadyRequestedExams.length > 0 && (
        <AlreadyRequestedBox exams={alreadyRequestedExams} />
      )}
    </Modal>
  );
}
