import React, { useMemo, useState } from "react";
import {
  getWidgetCalendarTodayDate,
  slicePaginationData,
} from "../Dashboard.utils";
import { API_DATE_FORMAT } from "../../../consts/api";
import { toExactMoment } from "../../../utilities/date";
import { useNetSalesQuery } from "../../../api/queries/useNetSalesQuery.js";
import { WIDGET_NAMES } from "../Dashboard.consts";
import { WidgetBox } from "../boxes/WidgetBox/WidgetBox.js";
import { WidgetBarChart } from "../components/WidgetBarChart.js";
import { useCurrentAccountQuery } from "../../../api/queries/useAccountQuery.js";
import classes from "../sass/WidgetNetSalesByProvider.module.scss";

const ITEMS_PER_PAGE = 6;
const PAGINATION_RANGE = 5;
const LOCAL_STORAGE_KEY = "nspWidget";

export function WidgetNetSalesByProvider() {
  const savedRange = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  const [activePage, setActivePage] = useState(1);
  const { data: account } = useCurrentAccountQuery();

  const [dates, setDates] = useState({
    start: savedRange?.startDate || getWidgetCalendarTodayDate(),
    end: savedRange?.endDate || getWidgetCalendarTodayDate(),
  });

  const { data: reportQueryData, isLoading: isReportQueryDataLoading } =
    useNetSalesQuery({
      payload: {
        fromDate: toExactMoment(dates.start).format(API_DATE_FORMAT),
        toDate: toExactMoment(dates.end).format(API_DATE_FORMAT),
      },
    });

  const slicedReportData = useMemo(
    () =>
      slicePaginationData({
        activePage,
        itemsPerPage: ITEMS_PER_PAGE,
        data: reportQueryData?.provider_net_sales,
      }),
    [reportQueryData?.provider_net_sales, activePage],
  );

  const providerNames = useMemo(
    () => slicedReportData.map((r) => r.provider),
    [slicedReportData],
  );

  const prices = useMemo(
    () => slicedReportData.map((r) => r.amount),
    [slicedReportData],
  );

  const onSelectDateRange = (result) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(result));
    setActivePage(1);
    setDates({
      start: result.startDate,
      end: result.endDate,
      startRaw: result.startDateRaw,
      endRaw: result.endDateRaw,
    });
  };

  return (
    <WidgetBox
      label={WIDGET_NAMES.net_sales_by_provider}
      dateRange={{
        dates,
        onSelect: onSelectDateRange,
      }}
      pagination={{
        activePage,
        itemsCountPerPage: ITEMS_PER_PAGE,
        total: reportQueryData?.provider_net_sales?.length,
        range: PAGINATION_RANGE,
        setActivePage,
      }}
      isLoading={isReportQueryDataLoading}
      isEmpty={reportQueryData?.provider_net_sales?.length === 0}
      contentClassName={classes.content}
    >
      <WidgetBarChart
        categories={providerNames}
        valuePrefix={account?.globalSettings?.currencySymbol}
        data={prices}
        dataLabelsFormat={
          account?.globalSettings?.currencySymbol + "{point.y:.2f}"
        }
      />
    </WidgetBox>
  );
}
