import { positionFooterCorrectly } from "../Utils/services.js";
import { http } from "../services/HttpService.js";

positionFooterCorrectly();

export const fetchReports = (formData, id) => {
  return (dispatch) => {
    http
      .get("reports/" + id, formData)
      .then((response) => {
        dispatch({ type: "REPORTS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "REPORTS_LIST", payload: error.response.data });
      });
  };
};

export const getReportTypes = (formData, id) => {
  return (dispatch) => {
    http
      .get("reports/edit/" + id, formData)
      .then((response) => {
        dispatch({ type: "GET_REPORT_TYPES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_REPORT_TYPES", payload: error.response.data });
      });
  };
};

export const createReports = (formData) => {
  return (dispatch) => {
    http
      .post("reports/" + 0, formData)
      .then((response) => {
        dispatch({ type: "CREATE_REPORTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_REPORTS", payload: error.response.data });
      });
  };
};

export const updateReports = (formData, id) => {
  return (dispatch) => {
    http
      .put("reports/" + id, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_REPORTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_REPORTS", payload: error.response.data });
      });
  };
};

export const deleteReports = (formData, id) => {
  return (dispatch) => {
    http
      .delete("reports/" + id, formData)
      .then((response) => {
        dispatch({ type: "DELETE_REPORTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_REPORTS", payload: error.response.data });
      });
  };
};

export const exportReports = (formData, report_id, type) => {
  return (dispatch) => {
    http
      .get("reports/export/" + report_id + "/" + type, formData)
      .then((response) => {
        dispatch({ type: "EXPORT_REPORTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EXPORT_REPORTS", payload: error.response.data });
      });
  };
};
