import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import { EDIT_DURATION_RANGE } from "../Event.consts";
import { InputError } from "../../../shared/InputError/InputError";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { EntityRow } from "./EntityRow";
import { Input } from "../../../shared/Input/Input";

const DurationDetailsModal = ({
  isOpen,
  onClose,
  onSubmit,
  serviceDetails,
}) => {
  const schema = yup.object({
    duration: yup
      .number()
      .min(EDIT_DURATION_RANGE.min)
      .max(EDIT_DURATION_RANGE.max)
      .test(
        "multiplicity",
        "duration must be a multiple of 5",
        (value) => Number(value) % 5 === 0,
      )
      .required(),
  });

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      duration: serviceDetails.durationInMin || 0,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      onCancel={onClose}
      title="Edit duration"
    >
      <EntityRow width="none" label="Service Name:">
        {serviceDetails.name}
      </EntityRow>
      <EntityRow width="none" label="Duration (in mins):" noMarginBottom>
        <Input
          name="duration"
          type="number"
          value={values.duration}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={Boolean(errors.duration)}
        />
        {Boolean(errors.duration) && <InputError>{errors.duration}</InputError>}
      </EntityRow>
    </ConfirmModal>
  );
};

DurationDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serviceDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    durationInMin: PropTypes.number.isRequired,
  }).isRequired,
};

export default DurationDetailsModal;
