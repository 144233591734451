import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./ContentLoader.module.scss";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";

export function ContentLoader({
  children,
  isLoading,
  className,
  loaderClassName,
  size,
  color,
}) {
  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.disabled]: isLoading,
        },
        className,
      )}
    >
      {isLoading && (
        <div className={cx(classes.loader, loaderClassName)}>
          <CircularProgress size={size} color={color} />
        </div>
      )}
      {children}
    </div>
  );
}

ContentLoader.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

ContentLoader.defaultProps = {
  className: undefined,
  loaderClassName: undefined,
  size: "big",
  color: "primary",
};
