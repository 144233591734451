import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useDeleteFreeTaxableFileMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return http.delete(HTTP_ENDPOINTS.deleteFreeTaxabelFile());
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.account],
        });
        options.onSuccess?.(...args);
      },
    },
  );
}
