import moment from "moment";
import { squashSpaces } from "../../utilities/general";

/**
 * @typedef {{
 *  id: number | string;
 *  type: string;
 *  date: string;
 *  author: {
 *    id: number | string;
 *    fullName: string;
 *    imageUrl: string | null;
 *  };
 *  body: string;
 * }} Message
 */

/**
 * @param {Array<Message>} messages
 * @returns {Array<Message>}
 */
export function sortMessagesByDate(messages = []) {
  const nextMessages = [...messages];
  nextMessages.sort(
    (m1, m2) => +moment(m1.date).toDate() - +moment(m2.date).toDate(),
  );
  return nextMessages;
}

/**
 * @param {string} fullName
 * @returns string;
 */
export function getAuthorInitials(fullName = "") {
  const preparedName = squashSpaces(fullName);
  if (preparedName === "") {
    return "";
  }
  const split = preparedName.split(" ");
  return `${split?.[0]?.[0]} ${split?.[1]?.[0]}`.trim().toUpperCase();
}

/**
 * @param {string} dateTime
 * @returns {string | null}
 */
export function extractDate(dateTime) {
  if (typeof dateTime === "string") {
    return squashSpaces(dateTime).split(" ")?.[0] || null;
  }
  return null;
}
