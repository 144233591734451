import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
import Skeleton from "react-loading-skeleton";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import classes from "./scss/repeatMd.module.scss";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Box } from "../../../../shared/Box/Box";
import { useRepeatMdIntegration } from "./hooks/useRepeatMdIntegration";
import { useSaveRepeatMdIntegration } from "./hooks/useSaveRepeatMdIntegration";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import AuthorizationBox from "./components/AuthorizationBox";
import ClinicRow from "./components/ClinicRow";
import { Button } from "../../../../shared/Button/Button";
import { uiNotification } from "../../../../services/UINotificationService";
import { Select } from "../../../../shared/Select/Select";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { integrationTypeOptions } from "./RepeatMd.consts";

const RepeatMd = () => {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();
  const [isEnabled, setIsEnabled] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [integrationType, setIntegrationType] = useState(null);

  const isLocationIntegration = integrationType === "location";

  const { data, isLoading } = useRepeatMdIntegration({
    onSuccess: (data) => {
      setIsEnabled(data.isActive);
      setIsConnected(data.isConnected);
      setIntegrationType(data.integrationType);
    },
  });
  const initialValues = useMemo(
    () =>
      data?.clinics.reduce((acc, clinic) => {
        return { ...acc, [clinic.clinicId]: clinic.locationId };
      }, {}),
    [data],
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      const valuesToSave = Object.entries(values).map(
        ([clinicId, locationId]) => ({
          clinicId: clinicId,
          locationId: locationId,
        }),
      );
      mutate(
        {
          isActive: true,
          clinics: isLocationIntegration ? valuesToSave : [],
          integrationType,
        },
        {
          onSuccess: () =>
            uiNotification.success(
              tSettings("repeatMdIntegration.locationSave.success"),
            ),
        },
      );
    },
  });

  const { mutate, isLoading: isSaving } = useSaveRepeatMdIntegration();

  return (
    <LayoutSettings contentClassName={classes.root}>
      <EntityHeader
        title={tSettings("repeatMdIntegration.title")}
        right={
          <div>
            {isSaving ? (
              <CircularProgress size="small" color="primary" />
            ) : (
              <Toggle
                isEnabled={isEnabled}
                onChange={() =>
                  mutate(
                    {
                      isActive: !isEnabled,
                    },
                    { onSuccess: () => setIsEnabled(!isEnabled) },
                  )
                }
                isDisabled={false}
              />
            )}
          </div>
        }
      />
      {isEnabled && (
        <Box className={classes.clinicsBox}>
          {isLoading ? (
            <Skeleton count={5} height={"35px"} />
          ) : (
            <>
              <div className={classes.authorizationContainer}>
                <AuthorizationBox
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  updatedAt={data?.updatedAt}
                  updateApiKey={mutate}
                  isUpdating={isSaving}
                />
                <div className={classes.integrationType}>
                  <InputLabel>
                    {tSettings("repeatMdIntegration.integrationType")}
                  </InputLabel>
                  <Select
                    size="small"
                    options={integrationTypeOptions}
                    value={integrationTypeOptions.find(
                      (integration) => integration.value === integrationType,
                    )}
                    onChange={(option) => setIntegrationType(option.value)}
                  />
                </div>
              </div>
              <>
                {Boolean(data?.clinics.length) &&
                  isLocationIntegration &&
                  data?.clinics.map((clinic) => (
                    <ClinicRow
                      key={clinic.clinicId}
                      clinicId={clinic.clinicId}
                      clinicName={clinic.clinicName}
                      locations={data.repeatMdLocations}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                <div className={classes.saveBtn}>
                  <Button
                    size="small"
                    onClick={handleSubmit}
                    className={classes.updateButton}
                    isDisabled={isSaving}
                    leftAdornment={
                      isSaving ? (
                        <CircularProgress size="small" color="white" />
                      ) : undefined
                    }
                  >
                    {tCommon("label.save")}
                  </Button>
                </div>
              </>
            </>
          )}
        </Box>
      )}
    </LayoutSettings>
  );
};

export default RepeatMd;
