import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  clinicId: yup.number().required(),
});

const responseSchema = yup
  .array()
  .of(
    yup.object({
      id: yup.number().required(),
      fullName: yup.string().required(),
      order: yup.number().nullable(),
    }),
  )
  .required();

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, []);
  return data.map((d) => ({
    id: d.id,
    fullName: d.full_name,
    order: d.ob_order || null,
  }));
};

function createUsersObOrderQueryKey({ clinicId }) {
  return [QUERY_KEYS.usersObOrder, clinicId];
}

export function useUsersObOrderQuery({ payload, options = {} }) {
  return useQuery(
    createUsersObOrderQueryKey({
      clinicId: payload.clinicId,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.getUsersObOrder(req.clinicId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
