import { useEfaxFavouriteDeleteMutation } from "../../../../../api/efax/useEfaxFavouriteDeleteMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export const useDeleteFavorites = () => {
  const { tSettings } = useAppTranslation.Settings();

  const { mutateAsync, isLoading } = useEfaxFavouriteDeleteMutation({
    onError: () => {
      uiNotification.error(
        tSettings("efaxIntegration.favourites.error.delete"),
      );
    },
    onSuccess: () => {
      uiNotification.success(
        tSettings("efaxIntegration.favourites.success.delete"),
      );
    },
  });

  return {
    isLoading,
    initiate: (id) => mutateAsync({ id }),
  };
};
