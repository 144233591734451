import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";

const options = (type, reportData) => {
  let data = [];
  if (type === "first") {
    let free_percentage = parseFloat(reportData.free_percentage);
    let occupied_percentage = parseFloat(reportData.occupied_percentage);

    data = [
      {
        title: "Free",
        name: "Free " + "  " + free_percentage + "%",
        y: free_percentage,
        z: free_percentage,
      },
      {
        title: "Occupied",
        name: "Occupied " + "  " + occupied_percentage + "%",
        y: occupied_percentage,
        z: occupied_percentage,
      },
    ];
  }

  if (type === "second") {
    if (reportData && reportData.length > 0) {
      reportData.map((obj2) => {
        let percent = parseFloat(obj2.service_category_percentage);
        let tempObj = {};
        tempObj.title = obj2.service_category_name;
        tempObj.name = obj2.service_category_name + "  " + percent + "%";
        tempObj.y = percent;
        tempObj.z = percent;
        tempObj.totalNum = obj2.service_category_duration;
        data.push(tempObj);
      });
    }
  }

  return {
    chart: {
      type: "pie",
      margin: [-10, 250, 50, 15],
      height: 530,
      width: 600,
      backgroundColor: "rgba(255, 255, 255, 0.0)",
    },
    title: {
      text: "",
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "<b>{point.name}</b><br/>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      maxHeight: 165,
      symbolHeight: 14,
      symbolWidth: 14,
      symbolRadius: 3,
      width: 320,
      itemMarginTop: 4,
      itemMarginBottom: 2,
      x: 150,
      y: 150,
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "50%",
        zMin: 0,
        data: data,
      },
    ],
  };
};

class BookedAppointmentsPerClinic extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (nextProps.reportData && nextProps.reportData !== prevState.reportData) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    return (
      <div>
        <div className="row new-sale-row">
          {this.state.reportData &&
            this.state.reportData.total_availability && (
              <div className="col-lg-12 new-sale-col">
                <div className="setting-setion m-b-15">
                  <div className="graphHeading">{"Booked Appointment"}</div>
                  <div className="table-responsive scroll-x">
                    <div
                      className="dash-box-content egiftCardGraph businessGraphOuter bookedAppointmentGraph"
                      data-highcharts-chart="0"
                    >
                      <div
                        className="highcharts-container "
                        data-highcharts-chart="0"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          width: "400px",
                          height: "200px",
                          textAlign: "left",
                          lineHeight: "normal",
                          zIndex: "0",
                          WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                        }}
                      >
                        <PieChart
                          highcharts={Highcharts}
                          options={options(
                            "first",
                            this.state.reportData.total_availability,
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {this.state.reportData &&
            this.state.reportData.service_category_data &&
            this.state.reportData.service_category_data.length > 0 && (
              <div className="col-lg-12 new-sale-col">
                <div className="setting-setion m-b-15">
                  <div className="graphHeading">
                    {"Booked Appointment Per Categry"}
                  </div>
                  <div className="table-responsive scroll-x">
                    <div
                      className="dash-box-content egiftCardGraph businessGraphOuter bookedAppointmentGraph"
                      data-highcharts-chart="0"
                    >
                      <div
                        className="highcharts-container "
                        data-highcharts-chart="0"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          width: "400px",
                          height: "200px",
                          textAlign: "left",
                          lineHeight: "normal",
                          zIndex: "0",
                          WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                        }}
                      >
                        <PieChart
                          highcharts={Highcharts}
                          options={options(
                            "second",
                            this.state.reportData.service_category_data,
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default BookedAppointmentsPerClinic;
