import { uiNotification } from "../../../services/UINotificationService";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { useUnmarkNoDupPatientsMutation } from "../../../api/mutations/useUnmarkNoDupPatientsMutation";

export function useUnmarkNonDup({ selectedPatientGroupIds, onSuccess }) {
  const { tCommon } = useAppTranslation.Common();

  const { mutate, isLoading } = useUnmarkNoDupPatientsMutation({
    onSuccess: () => {
      uiNotification.success(tCommon("nonDupPatients.success.unmark"));
      onSuccess();
    },
    onError: () => {
      uiNotification.error(tCommon("nonDupPatients.error.unmark"));
    },
  });

  return {
    unmark: () => mutate({ groupIds: selectedPatientGroupIds }),
    isPatientsUnmarking: isLoading,
  };
}
