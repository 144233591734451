import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";

const requestSchema = yup.object({
  appointmentId: yup.number().required(),
});

const responseSchema = yup.object({
  transcriptionText: yup.string().nullable(),
});

const composeResponse = (res) => {
  return {
    transcriptionText: res?.data?.transcriptions?.join("\n") || null,
  };
};

const createTwilioTranscriptionQueryKey = (...args) => [
  QUERY_KEYS.twilio.transcription,
  ...args,
];

export const useTwilioTranscriptionQuery = ({
  payload = {},
  options = {},
} = {}) => {
  return useQuery(
    createTwilioTranscriptionQueryKey(payload.appointmentId),
    async () => {
      const req = requestSchema.validateSync(payload, { strict: true });

      const res = await http.get(HTTP_ENDPOINTS.twilio.getTranscription(), {
        params: {
          appointment_id: req.appointmentId,
        },
      });

      return responseSchema.validateSync(composeResponse(res.data), {
        strict: true,
      });
    },
    options,
  );
};
