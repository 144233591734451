import React, { useEffect } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import ViewTipsModal from "../../../../ViewTipsModal/ViewTipsModal";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";
import { useConfirmPayment } from "../../hooks/useConfirmPayment";
import { useStripePaymentIntent } from "../../hooks/useStripePaymentIntent";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { svg } from "../../../../../../../../assets/svg";
import { tSales } from "../../../../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import RemainingBalanceDue from "../../../../../shared/RemainingBalanceDue/RemainingBalanceDue";
import { useChargeForm } from "../../../../../hooks/useChargeForm";
import { composeAmountSchema } from "../../../../../SalesCheckoutInvoice.schemas";

const PaymentTipsModal = ({ saveCard, showTipsModal, isTipsApplying }) => {
  const { invoice, amountToPay } = useInvoice();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
  });

  const { confirmPayment, isLoading } = useConfirmPayment();

  const handleSubmit = async () => {
    generateIntent({
      invoice_id: invoice.id,
      amount: form.amount,
    })
      .then((res) => {
        const paymentIntentId = res.data.data.paymentIntentId;
        confirmPayment({
          paymentIntentId,
          saveCard,
        }).catch(() => {
          showTipsModal.update(false);
        });
      })
      .catch((error) => {
        uiNotification.error(error.message);
      });
  };

  const { form, changeFormValue, errors, isValid, submit } = useChargeForm({
    schema,
    onSubmit: ({ amount }) => {
      handleSubmit(amount);
    },
    initialValues: {
      amount: amountToPay,
    },
  });

  const { mutateAsync: generateIntent, isLoading: isGeneratingIntent } =
    useStripePaymentIntent({
      onError: (error) => {
        uiNotification.error(error.message);
      },
    });

  useEffect(() => {
    changeFormValue("amount", amountToPay);
  }, [amountToPay]);

  return (
    <ModalCharge
      isOpen
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
      onClose={() => {
        showTipsModal.update(false);
      }}
      onCharge={submit}
      isCharging={isLoading || isGeneratingIntent}
      isChargeDisabled={isTipsApplying || !isValid}
      amount={form.amount}
    >
      <ViewTipsModal amountToPay={form.amount} />
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={errors.amount}
          onChange={(value) => {
            changeFormValue("amount", value);
          }}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {errors.amount && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <div className="col-sm-8">
        <RemainingBalanceDue amount={form.amount} />
      </div>
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(PaymentTipsModal);
