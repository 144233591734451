import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  userId: yup.number().required(),
  phone: yup.string().required(),
});

export const useUpdateUserPhoneMutation = (options = {}) => {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.put(HTTP_ENDPOINTS.user.updatePhone(), {
      user_id: req.userId,
      phone: req.phone,
    });
  }, options);
};
