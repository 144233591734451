import React, { useState } from "react";
import InsightsSideBar from "../../../InsightsSideBar";
import { useReports } from "./hooks/useReports";
import { useDownloadExcel } from "./hooks/useDownloadExcel";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { Header } from "./components/Header";
import { ModalFilter } from "./components/ModalFilter";
import { Table } from "./components/Table";

export function RetentionRateReport() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { reports, filter, period } = useReports();

  const downloadExcel = useDownloadExcel({
    period,
    filter: filter.preparedForRequest,
  });

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <InsightsSideBar />
        <div className="memberWalletOuter business-section">
          <ReportLayout>
            <Header
              period={period}
              onFilterOpen={() => setIsFilterOpen(true)}
              downloadExcel={downloadExcel}
              isReportsLoading={reports.isLoading}
              isFilterActive={filter.isActive}
            />
            <Table reports={reports} period={period} />
            <ModalFilter
              isOpen={isFilterOpen}
              onClose={() => setIsFilterOpen(false)}
              filter={filter}
            />
          </ReportLayout>
        </div>
      </div>
    </div>
  );
}
