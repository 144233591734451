import React, { useState, useEffect } from "react";
import _ from "lodash/fp";
import { useForm, Controller } from "react-hook-form";
import { Input, Select, CheckBox } from '../../../Common/ReactHookForm'
import FormStep5Each from './FormStep5Each'
import { Scrollbars } from "react-custom-scrollbars";
import { uiNotification } from "../../../../services/UINotificationService";



const FormStep5 = (props) => {
  const { handleFormStepChange, formValue, initStep5, resources, merchantNumber, deleteContact } = props;
  const { register, handleSubmit, watch, errors, control } = useForm({ formValue });
  const [contactIndex, setContactIndex] = useState(0);
  const [isShow, setIsShow] = useState((formValue.length > 0 && formValue[0].id) ? true : false);
  const [isNewAddress, setIsNewAddress] = useState(false)
  const [isDeleteAddress, setIsDeleteAddress] = useState((formValue.length > 0 && formValue[0].id) ? true : false)
  const handleAddressSave = (data) => {
    let tempFormValue = formValue
    tempFormValue[contactIndex] = data
    if (isNewAddress) {
      tempFormValue.push(initStep5)
      handleFormStepChange(5, tempFormValue)
      setContactIndex(contactIndex + 1)
      setIsShow(false)
    } else {
      handleFormStepChange(5, tempFormValue)
      setIsShow(true)
    }
    setIsDeleteAddress((tempFormValue.length >= 1))
  }

  const handleAddressAdd = () => {
    let tempFormValue = formValue
    tempFormValue.push(initStep5)
    handleFormStepChange(5, tempFormValue)
    setContactIndex(tempFormValue.length - 1)
    setIsShow(false)
    setIsDeleteAddress((tempFormValue.length >= 1))
  }

  const handleAddressDelete = async(index) => {
    let tempFormValue = formValue;
    if(formValue[index] && formValue[index].businessContactId && formValue[index].businessContactId != '') {
      let formData = {
        deleted_contacts: [{businessContactId: formValue[index].businessContactId, id: formValue[index].id}],
        clearent_business_information_id: formValue[index].clearent_business_information_id
      }
      deleteContact(formData);
    }

    tempFormValue.splice(index, 1);
    if(tempFormValue.length == 0) {
      await handleFormStepChange(5, tempFormValue);
      tempFormValue.push(initStep5)
      await handleFormStepChange(5, tempFormValue);
      setIsShow(false);
    } else {
      await handleFormStepChange(5, tempFormValue);
      setIsShow(true);
    }
    setIsDeleteAddress((tempFormValue.length >= 1));
  }

  const onSubmit = data => {
    //handleFormStepChange(5, data)
    //alert(JSON.stringify(data));
  }; // your form submit function which will invoke after successful validation


  const submitContacts = () => {
    var contactError = false;
    var ssnArr = formValue.map(function(item){ return item.ssn });
    var isDuplicateSSN = ssnArr.some(function(item, idx){ 
        return ssnArr.indexOf(item) != idx 
    });

    if(isDuplicateSSN) {
      uiNotification.error("SSN can't be same for different contacts!");
      contactError = true
      return false;
    }
    var emailArr = formValue.map(function(item){ return item.emailAddress });
    var isDuplicateEmail = emailArr.some(function(item, idx){ 
        return emailArr.indexOf(item) != idx 
    });

    if(isDuplicateEmail) {
      uiNotification.error("Contact email address should be unique");
      contactError = true
      return false;
    }

    var ownerAmtArr = formValue.map(function(item){ return (item['contactType-2'] ? item.ownershipAmount : 0) });
    if(ownerAmtArr.length > 1) {
      let sum = ownerAmtArr.reduce((a, b) => parseInt(a) + parseInt(b), 0);
      if(sum > 100) {
        uiNotification.error("Ownership amount cannot exceed 100 percent!");
        contactError = true
        return false;   
      }
    }

    if(formValue.length > 0) {
      formValue.map((obj, idx) => {
        let contactTypes = []
        let phoneNumbers = []

        if(!obj.contactTypes) {
          if(obj['contactType-1']) {
            contactTypes.push({contactTypeID: 1})
          }
          if(obj['contactType-2']) {
            contactTypes.push({contactTypeID: 2})
          }
          if(obj['contactType-3']) {
            contactTypes.push({contactTypeID: 3})
          }
          obj.contactTypes = contactTypes;
          var contactTypeThisContact = obj.contactTypes.map(function(item){ return item.contactTypeID });
          if(contactTypeThisContact.indexOf("2") == -1) {
            obj.ownershipAmount = 0;
          }

          if(obj.contact_phone) {
            let phoneNumberArr = obj.contact_phone.split(' ');
            let areaCode = ''
            let phoneNumber = ''
            if(phoneNumberArr.length > 1) {
              areaCode = phoneNumberArr[0];
              phoneNumber = phoneNumberArr[1] + phoneNumberArr[2];
            } else {
              areaCode = obj.contact_phone.substring(0,3);
              phoneNumber = obj.contact_phone.substring(3);
            }
            phoneNumbers.push({phoneTypeCodeID: obj.phoneTypeCodeID, areaCode: areaCode, phoneNumber: phoneNumber, extension:""})
          }
          if(obj.contact_fax) {
            phoneNumbers.push({phoneTypeCodeID:2, areaCode: 555, phoneNumber: obj.contact_fax, extension:""})
          }

          obj.phoneNumbers = phoneNumbers;
        }
        formValue[idx] = obj;
      })
    }
    if(!contactError) {
      handleFormStepChange(6, formValue);
    }
    return false;
  }

  return (
    <div>
      <div className="setting-setion">
			<Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={window.innerHeight-240}
        className="full-width"
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
      >
        <div className="setting-container p20">
			 <a className="easy-link p-l-0" onClick={() => {
              handleFormStepChange(4)
            }}><i className="fa fa-angle-left m-r-5"></i> Go Back</a>
            {merchantNumber != '' && <a className="easy-link p-l-0 pull-right"> Merchant Id - {merchantNumber}</a>}

          <div className="merchant-title">Merchant Application</div>
          <p className="p-text m-b-20">Welcome to the merchant facing application! Thank you for taking the time to help speed up your company's boarding process. Please fill out the application below to the best of your ability and click send when you're finished. This will alert your local rep.</p>

          <div className="settings-subtitle m-b-20 merchantSteps">4 out of 4: General Business Information </div>
          <div className={`${(!isShow) ? '' : 'no-display'} `}>
            {(formValue && formValue.length > 0) &&
              formValue.map((obj, idx) => {
                return (
                  <div
                    key={'formValue-' + idx}
                    className={`form-step-each-container ${(idx === contactIndex) ? '' : 'no-display'}`}
                  >
                    <FormStep5Each
                      handleFormStepChange={handleFormStepChange}
                      formValue={obj}
                      isShow={isShow}
                      setIsShow={setIsShow}
                      contactIndex={contactIndex}
                      setContactIndex={setContactIndex}
                      setIsNewAddress={setIsNewAddress}
                      handleAddressSave={handleAddressSave}
                      isDeleteAddress={isDeleteAddress}
                      handleAddressDelete={handleAddressDelete}
                      resources={resources}

                    />
                  </div>
                )
              })}
          </div>
        </div>

        <div className={`${(isShow) ? 'setting-container p20 p-t-0' : 'no-display'} `}>
          {(formValue && formValue.length > 0) &&
            formValue.map((obj, idx) => {
              return (
                <div
                  key={'formValue-show-' + idx} >
                  <div className="contactRow">
                    <div className="pull-left">
                      <h3>{`${obj.firstName} ${obj.lastName}`}</h3>
                      <p>{obj.contact_email}</p>
                    </div>
                    <div className="contactActionBtn">
                    <button
                      className="new-line-btn pull-right no-width"
                      onClick={() => {
                        setContactIndex(idx);
                        setIsShow(false)
                      }} >Edit</button>
                        {(isDeleteAddress) &&
                          <button
                            className="new-line-btn pull-right no-width"
                            type="button"
                            onClick={async () => {
                              handleAddressDelete(idx)
                            }}
                          >Delete</button>
                        }
                    </div>
                  </div>
                </div>
              )
            })}
          <div className="row">
            <div className="col-xs-12 m-b-20">
              <button
                className={`new-blue-btn pull-right m-t-20 m-b-30 m-r-0`}
                type="button"
                onClick={() => {
                  handleAddressAdd()
                }}
              >Add Another Contact</button>
            </div>
          </div>
        </div>

        </Scrollbars>
        <div className="footer-static merchantFooter">
			<div className="footerProgressOuter">
       <p><span>Wohoo!</span> Complete this page</p>
       <p>and you are done!</p>
			<div className="footerProgressBg"><span className="footerProgressBar" style={{width: "85%"}}></span></div>
			</div>
			<button
            className={`new-blue-btn m-t-5 ${(!isShow) ? 'disable' : ''}`}
            type="button"
            onClick={() => {
              if (isShow) {
                submitContacts();
              }
            }}
          >Next</button>
		</div>


       { /*<div className="footer-static">
                 <button
                   className={`new-blue-btn m-t-5 ${(!isShow) ? 'disable' : ''}`}
                   type="button"
                   onClick={() => {
                     if (isShow) {
                       handleFormStepChange(6, formValue)
                     }
                   }}
                 >Next</button>
                 <button
                   className="new-line-btn pull-left" type="button"
                   onClick={() => {
                     handleFormStepChange(4)
                   }}>Back</button>
               </div>*/}








      </div>
    </div>
  );
}
export default FormStep5;
