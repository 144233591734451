/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import cx from "clsx";
import { Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";
import { convertTime12to24, handleScheduleMasking } from "../../../Utils";
import CopyIcon from "../../../_legacy/images/copy.svg";
import DeleteIcon from "../../../_legacy/images/delete-dustbin.svg";
import PlusIcon from "../../../_legacy/images/plus.svg";
import { Button } from "../../../shared/Button/Button";
import { templateSchedule, weekTemplate } from "../../../_legacy/Constants";
import classes from "../resource.module.scss";

const SelectWeeklySchedule = () => {
  const formMethods = useFormContext();

  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = formMethods;

  const formValues = watch();

  const {
    clinicSchedules,
    copyDayScheduleToArray,
    isCopyScheduleDropdownOpen,
  } = formValues;

  const openCopyScheduleDropdown = async (dayIndex, isSelected) => {
    if (isSelected) {
      const isValid = await trigger(`clinicSchedules[${dayIndex}]`);
      if (isValid) {
        setValue(
          "isCopyScheduleDropdownOpen",
          isCopyScheduleDropdownOpen === dayIndex ? null : dayIndex,
        );
      }
    } else {
      const weekScheduleArray = [...clinicSchedules];
      weekScheduleArray[dayIndex].isSelected = true;
      setValue("clinicSchedules", weekScheduleArray);
    }
  };

  const addNewTimeSlotToDay = (event) => {
    const dayIndex = event.currentTarget.dataset.dayindex;

    const weekScheduleArray = [...clinicSchedules];
    if (weekScheduleArray[dayIndex].isSelected) {
      weekScheduleArray[dayIndex].timeSchedules = [
        ...weekScheduleArray[dayIndex].timeSchedules,
        templateSchedule,
      ];
    } else {
      weekScheduleArray[dayIndex].isSelected = true;
    }

    setValue("clinicSchedules", weekScheduleArray);
  };

  const toggleDayAvailability = (dayIndex) => {
    const updatedArray = [...clinicSchedules];
    updatedArray[dayIndex].isSelected = !updatedArray[dayIndex].isSelected;
    setValue("clinicSchedules", updatedArray);
  };

  const setDayTime = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const {
      dayindex: dayIndex,
      scheduleindex: scheduleIndex,
      shouldvalidate,
    } = event.currentTarget.dataset;

    const editingSchedule = {
      ...clinicSchedules[dayIndex].timeSchedules[scheduleIndex],
    };

    editingSchedule[name] = value;

    switch (name) {
      case "fromTimeHour":
      case "fromTimeOption": {
        const { fromTimeHour, fromTimeOption } = editingSchedule;

        const convertedFromTime = convertTime12to24(
          [fromTimeHour, fromTimeOption].join(" "),
        );

        editingSchedule.fromTime =
          convertedFromTime === "00:00" ? null : convertedFromTime;

        break;
      }

      case "toTimeHour":
      case "toTimeOption": {
        const { toTimeHour, toTimeOption } = editingSchedule;

        const convertedToTime = convertTime12to24(
          [toTimeHour, toTimeOption].join(" "),
        );

        editingSchedule.toTime =
          convertedToTime === "00:00" ? null : convertedToTime;

        break;
      }

      default:
        break;
    }

    setValue(
      `clinicSchedules[${dayIndex}].timeSchedules[${scheduleIndex}]`,
      editingSchedule,
      {
        shouldValidate: Boolean(shouldvalidate),
      },
    );
  };

  const deleteScheduleItem = (
    dayIndex,
    scheduleIndex,
    shouldValidate = false,
  ) => {
    const providerSchedule = { ...clinicSchedules[dayIndex] };

    if (providerSchedule.timeSchedules.length === 1) {
      providerSchedule.timeSchedules = [templateSchedule];
      providerSchedule.isSelected = false;
    } else {
      providerSchedule.timeSchedules.splice(scheduleIndex, 1);
    }

    setValue(`clinicSchedules[${dayIndex}]`, providerSchedule, {
      shouldValidate,
    });
  };

  const maskChange = (newState, _, userInput) => {
    const { value } = newState;
    const selection = newState.selection;
    const cursorPosition = selection ? selection.start : null;
    return handleScheduleMasking(value, selection, cursorPosition, userInput);
  };

  const copyDaySchedules = () => {
    const selectedDaySchedule = [
      ...clinicSchedules[isCopyScheduleDropdownOpen].timeSchedules,
    ];

    const providerSchedule = [...clinicSchedules];

    copyDayScheduleToArray.forEach((selectedDayIndex) => {
      providerSchedule[selectedDayIndex].isSelected = true;
      providerSchedule[selectedDayIndex].timeSchedules = [
        ...selectedDaySchedule,
      ];
    });

    setValue("clinicSchedules", providerSchedule);

    setValue("isCopyScheduleDropdownOpen", null);
    setValue("copyDayScheduleToArray", []);
    setValue("clinicSchedules", providerSchedule);
  };

  const handleSelectDayForCopy = (selectedDayIndex) => {
    setValue(
      "copyDayScheduleToArray",
      copyDayScheduleToArray.includes(selectedDayIndex)
        ? copyDayScheduleToArray.filter(
            (dayIndex) => dayIndex !== selectedDayIndex,
          )
        : [...copyDayScheduleToArray, selectedDayIndex],
    );
  };

  return (
    <div>
      {/* Set Weekly Hours */}
      <div className={classes.weekDaysSchedule}>
        {clinicSchedules.map((weekDayDetails, weekDayIndex) => {
          const { id, dayFullName, isSelected, timeSchedules } = weekDayDetails;

          return (
            <div
              className={cx(classes.daySchedule, "flex")}
              key={`schedule-weekday-${id}`}
            >
              <div className={cx(classes.dayRow, "row grow")}>
                <div className="col-xs-4">
                  <span
                    className={cx(
                      classes.scheduleWeekday,
                      "items-center cursor-pointer",
                    )}
                    onClick={() => toggleDayAvailability(weekDayIndex)}
                  >
                    <input
                      type="checkbox"
                      name={dayFullName}
                      checked={isSelected}
                      className="filled-checkbox"
                    />
                    <label>{dayFullName}</label>
                  </span>
                </div>
                {isSelected ? (
                  <>
                    <div className={cx(classes.dayScheduleSlots, "col-xs-8")}>
                      {timeSchedules.map(
                        (dayScheduleDetails, scheduleItemIndex) => {
                          const {
                            fromTimeHour,
                            toTimeHour,
                            fromTimeOption,
                            toTimeOption,
                          } = dayScheduleDetails;

                          const {
                            fromTime: fromTimeError,
                            toTime: toTimeError,
                          } =
                            errors?.clinicSchedules?.[weekDayIndex]
                              ?.timeSchedules[scheduleItemIndex] || {};
                          return (
                            <>
                              <div
                                className={cx(
                                  classes.scheduleRow,
                                  "fromandtoTime  no-padding row ",
                                )}
                              >
                                <div className={classes.scheduleTimeFields}>
                                  <div
                                    className={cx(
                                      classes.newInputFileldOuter,
                                      "newInputFileldOuter",
                                    )}
                                  >
                                    <InputMask
                                      name="fromTimeHour"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={fromTimeError}
                                      value={fromTimeHour}
                                      mask="99:99"
                                      className={cx(
                                        {
                                          [classes.timeFieldError]:
                                            fromTimeError,
                                        },
                                        classes.inputTime,
                                        `setting-input-box hours-time from-time-input `,
                                      )}
                                      placeholder="00:00"
                                      onChange={setDayTime}
                                      maskChar=""
                                      beforeMaskedValueChange={maskChange}
                                      autoComplete="off"
                                    />
                                    <select
                                      name="fromTimeOption"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={fromTimeError}
                                      value={fromTimeOption}
                                      className={cx(
                                        classes.inputTimeOption,
                                        {
                                          [classes.timeOptionFieldError]:
                                            fromTimeError,
                                        },
                                        `newSelectField hours-pm fromTimeOption `,
                                      )}
                                      onChange={setDayTime}
                                    >
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                    </select>
                                  </div>
                                  <div
                                    className={cx(
                                      classes.newInputFileldOuter,
                                      "newInputFileldOuter",
                                    )}
                                  >
                                    &nbsp;-&nbsp;
                                  </div>
                                  <div
                                    className={cx(
                                      classes.newInputFileldOuter,
                                      "newInputFileldOuter",
                                    )}
                                  >
                                    <InputMask
                                      name="toTimeHour"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={toTimeError}
                                      value={toTimeHour}
                                      mask="99:99"
                                      className={cx(
                                        {
                                          [classes.timeFieldError]: toTimeError,
                                        },
                                        classes.inputTime,
                                        `setting-input-box hours-time from-time-input `,
                                      )}
                                      placeholder="00:00"
                                      onChange={setDayTime}
                                      maskChar=""
                                      beforeMaskedValueChange={maskChange}
                                      autoComplete="off"
                                    />
                                    <select
                                      name="toTimeOption"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={toTimeError}
                                      value={toTimeOption}
                                      className={cx(
                                        classes.inputTimeOption,
                                        {
                                          [classes.timeOptionFieldError]:
                                            toTimeError,
                                        },
                                        `newSelectField hours-pm fromTimeOption `,
                                      )}
                                      onChange={setDayTime}
                                    >
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                    </select>
                                  </div>
                                </div>

                                <div
                                  className={classes.deleteScheduleItem}
                                  onClick={() =>
                                    deleteScheduleItem(
                                      weekDayIndex,
                                      scheduleItemIndex,
                                      Boolean(fromTimeError || toTimeError),
                                    )
                                  }
                                >
                                  <img src={DeleteIcon} alt="delete" />
                                </div>
                              </div>
                            </>
                          );
                        },
                      )}
                    </div>
                  </>
                ) : (
                  <div className={cx(classes.scheduleUnavailable, "col-xs-8")}>
                    <span className="">Unavailable</span>
                  </div>
                )}
              </div>
              <div className={classes.dayActions}>
                <span
                  onClick={addNewTimeSlotToDay}
                  data-dayindex={weekDayIndex}
                >
                  <img src={PlusIcon} alt="new" />
                </span>

                <Dropdown
                  autoClose="outside"
                  open={isCopyScheduleDropdownOpen === weekDayIndex}
                  dropup={isCopyScheduleDropdownOpen > 4}
                >
                  <Dropdown.Menu className={classes.dropdownMenu}>
                    <div className={classes.dropdownContent}>
                      <div className={classes.menuTitle}>COPY TIMES TO</div>
                      <>
                        {weekTemplate.map((weekDayDetails, dayIndex) => {
                          const { dayName } = weekDayDetails;
                          const isDaySelected =
                            copyDayScheduleToArray.includes(dayIndex);

                          return (
                            dayIndex !== isCopyScheduleDropdownOpen && (
                              <div
                                className={classes.dayOption}
                                onClick={() => handleSelectDayForCopy(dayIndex)}
                              >
                                <span>{dayName}</span>
                                <span>
                                  <input
                                    type="checkbox"
                                    name={dayName}
                                    checked={isDaySelected}
                                    className="filled-checkbox"
                                  />
                                </span>
                              </div>
                            )
                          );
                        })}
                        <Button
                          fullWidth
                          size="small"
                          onClick={copyDaySchedules}
                          className={classes.copyDayScheduleButton}
                        >
                          Apply
                        </Button>
                      </>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <span
                  className="dropdown-toggle"
                  onClick={() =>
                    openCopyScheduleDropdown(weekDayIndex, isSelected)
                  }
                >
                  <img src={CopyIcon} alt="copy" />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectWeeklySchedule;
