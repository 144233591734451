import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.object({
  isEnabled: yup.boolean().required(),
  faxNumber: yup.string().nullable(),
  packages: yup.array().of(
    yup.object({
      price: yup.number().required(),
      pages: yup.number().required(),
    }),
  ),
});

const composeResponse = (res) => {
  const data = res?.data?.data || {};

  return {
    isEnabled: data.isEnabled,
    faxNumber: data.faxNumber,
    packages: Object.entries(data.packages).map(([key, value]) => ({
      price: Number(value.price),
      pages: Number(key),
    })),
  };
};

const createEfaxIntegrationStatusQueryKey = () => [QUERY_KEYS.efax.status];

export const useEfaxIntegrationStatusQuery = (options = {}) => {
  return useQuery(
    createEfaxIntegrationStatusQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.efax.getStatus());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    { cacheTime: 0, ...options },
  );
};
