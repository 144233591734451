import React from "react";
import styles from "./PageOverlayLoader.module.scss";

export function PageOverlayLoader({ message }) {
  return (
    <div className={styles.loader}>
      <div className={styles.loaderOuter}>
        <img alt="loader" id="loader-outer" src="/images/Eclipse.gif" />
        <div id="modal-confirm-text">{message}</div>
      </div>
    </div>
  );
}
