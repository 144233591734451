import React, { useState } from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { ReorderModal } from "../../../../../../../boxes/ReorderModal/ReorderModal";
import { Select } from "../../../../../../../shared/Select/Select";
import { useClinics } from "./hooks/useClinics";
import { usePackages } from "./hooks/usePackages";
import { useSelectedClinic } from "./hooks/useSelectedClinic";
import { useSaveOrder } from "./hooks/useSaveOrder";

export function ObOrder() {
  const { tCommon } = useAppTranslation.Common();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { clinicsList, isClinicsLoading } = useClinics();
  const { selectedClinic, selectClinic } = useSelectedClinic(clinicsList);
  const { packages, isPackagesLoading } = usePackages(selectedClinic?.value);
  const { save, isSaving } = useSaveOrder();

  const submit = (orderedPackages) => {
    save({
      selectedClinic,
      orderedPackages,
    });
  };

  return (
    <>
      <button
        className="easy-link no-padding"
        onClick={() => setIsModalOpen(true)}
      >
        {tCommon("label.obOrder")}
      </button>
      {isModalOpen && (
        <ReorderModal
          isLoading={isClinicsLoading || isPackagesLoading}
          isSaving={isSaving}
          isOpen={isModalOpen}
          data={packages}
          onClose={() => setIsModalOpen(false)}
          title={tCommon("label.obOrder")}
          onSave={submit}
          renderSubheader={({ isTouched }) => (
            <Select
              isDisabled={isSaving}
              options={clinicsList}
              value={selectedClinic}
              onChange={(next) => selectClinic({ next, isTouched })}
            />
          )}
        />
      )}
    </>
  );
}
