import React, { useMemo } from "react";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import { Select } from "../../../../../../../../shared/Select/Select";
import { Input } from "../../../../../../../../shared/Input/Input";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";

const PaymentFormModal = ({
  isValid,
  form,
  errors,
  hasError,
  changeFormValue,
  showTipsModal,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();

  const savedCards = useMemo(() => {
    return invoice?.patient?.cardOnFiles?.map((card) => ({
      value: card.cardOnFile,
      label: card.number,
      billingZip: card.billingZip,
    }));
  }, [invoice?.patient?.cardOnFiles]);

  const handleSubmit = () => {
    showTipsModal.update(true);
  };

  return (
    <>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.cardOnFile")}
          </InputLabel>
          <Select
            options={savedCards}
            value={form.cardOnFile}
            onChange={(value) => changeFormValue("cardOnFile", value)}
          />
        </ModalCharge.InputWrap>
        <ModalCharge.InputRow>
          <ModalCharge.InputWrap fullWidth>
            <InputLabel>{tCommon("label.email")}</InputLabel>
            <Input
              type="email"
              size="medium"
              value={form.email}
              isError={hasError("email")}
              onChange={(e) => changeFormValue("email", e.target.value)}
              placeholder={tCommon("formPlaceholder.email")}
            />
            {hasError("email") && <InputError>{errors.email}</InputError>}
          </ModalCharge.InputWrap>
          <ModalCharge.InputWrap width="170px">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.zipCode")}
            </InputLabel>
            <Input
              type="number"
              size="medium"
              value={form.zipCode}
              isError={hasError("zipCode")}
              onChange={(e) => changeFormValue("zipCode", e.target.value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.zipCode",
              )}
            />
            {hasError("zipCode") && <InputError>{errors.zipCode}</InputError>}
          </ModalCharge.InputWrap>
        </ModalCharge.InputRow>
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        onCharge={handleSubmit}
        chargeLabel={tCommon("label.continue")}
        isChargeDisabled={!isValid}
      />
    </>
  );
};

export default PaymentFormModal;
