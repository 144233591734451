import { ACTION_TYPES } from "./config";
import { createMutationRecordSnapshot } from "./utilities";

const initialState = {
  activeRecordId: undefined,
  mutationRecord: null,
  mutationRecordInitialSnapshot: undefined,
  mutationRecordFieldErrors: {},
  graphs: {},
  isGraphMutating: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.activeRecordIdChange: {
      return {
        ...state,
        activeRecordId: action.payload.id,
      };
    }
    case ACTION_TYPES.setMutationRecord: {
      return {
        ...state,
        mutationRecord: action.payload.mutationRecord,
        activeRecordId: action.payload.mutationRecord.id,
        mutationRecordInitialSnapshot: createMutationRecordSnapshot(
          action.payload.mutationRecord,
        ),
      };
    }
    case ACTION_TYPES.dropMutationRecord: {
      return {
        ...state,
        mutationRecord: null,
        activeRecordId: undefined,
        mutationRecordFieldErrors: {},
        mutationRecordInitialSnapshot: undefined,
      };
    }
    case ACTION_TYPES.patchMutationRecordField: {
      return {
        ...state,
        mutationRecord: {
          ...state.mutationRecord,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case ACTION_TYPES.appendMutationRecordFieldErrors: {
      return {
        ...state,
        mutationRecordFieldErrors: {
          ...state.mutationRecordFieldErrors,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.clearMutationRecordFieldErrors: {
      return {
        ...state,
        mutationRecordFieldErrors: {},
      };
    }
    case ACTION_TYPES.graphDelete: {
      if (action.payload.patientId) {
        const patientGraphs = state.graphs[action.payload.patientId] || [];

        return {
          ...state,
          graphs: {
            ...state.graphs,
            [action.payload.patientId]: patientGraphs.filter(
              (g) => g.id !== action.payload.id,
            ),
          },
        };
      }

      return state;
    }
    case ACTION_TYPES.graphAdd: {
      if (action.payload.patientId) {
        return {
          ...state,
          graphs: {
            ...state.graphs,
            [action.payload.patientId]: [
              action.payload.graph,
              ...(state.graphs?.[action.payload.patientId] || []),
            ],
          },
        };
      }

      return state;
    }
    case ACTION_TYPES.graphUpdate: {
      if (action.payload.patientId) {
        const patientGraphs = state.graphs[action.payload.patientId] || [];
        const index = patientGraphs.findIndex(
          (g) => g.id === action.payload.id,
        );

        if (index !== -1) {
          const next = [...patientGraphs];
          next[index] = action.payload.graph;

          return {
            ...state,
            graphs: {
              ...state.graphs,
              [action.payload.patientId]: next,
            },
          };
        }
      }

      return state;
    }
    case ACTION_TYPES.graphSetIsMutating: {
      return {
        ...state,
        isGraphMutating: action.payload.status,
      };
    }
    case ACTION_TYPES.addMutationRecordNote: {
      return {
        ...state,
        mutationRecord: {
          ...state.mutationRecord,
          notes: [...state.mutationRecord?.notes, action.payload.note],
        },
      };
    }
    case ACTION_TYPES.updateMutationRecordNote: {
      return {
        ...state,
        mutationRecord: {
          ...state.mutationRecord,
          notes: state.mutationRecord?.notes?.map((n) =>
            n.id === action.payload.id
              ? {
                  ...n,
                  note: action.payload.note,
                  author: action.payload.author || n.author,
                }
              : n,
          ),
        },
      };
    }
    case ACTION_TYPES.deleteMutationRecordNote: {
      return {
        ...state,
        mutationRecord: {
          ...state.mutationRecord,
          notes: state.mutationRecord?.notes?.filter(
            (n) => n.id !== action.payload.id,
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
}
