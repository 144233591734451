export const initTransfer = () => {
  return {
    product_id: "",
    product_idClass: "simpleInput",
    product_name: "",
    product_nameClass: "simpleInput",
    type: "",
    typeClass: "simpleInput",
    batches: [],
    inventory_id: "",
    inventory_idClass: "simpleSelect",
    batch_id: "",
    batch_idClass: "simpleSelect",
    transfer_units: "",
    transfer_unitsClass: "simpleInput",
    available_units: 0,
    available_unitsClass: "textOnly",
  };
};

export const initTransferBatchStock = () => {
  return {
    inventory_id: "",
    batch_id: "",
    available_units: 0,
  };
};
