import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Box.module.scss";

const SIZES = {
  small: "small",
};

export function Box({
  children,
  className,
  noPadding,
  elevation,
  size,
  noOverflow,
  overflowXScroll,
}) {
  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.rootNoPadding]: noPadding,
          [classes.rootElevation]: elevation,
          [classes.rootNoOverflow]: noOverflow,
          [classes.rootSmall]: size === SIZES.small,
          [classes.rootOverflowXScroll]: overflowXScroll,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

Box.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  elevation: PropTypes.bool,
  noOverflow: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  overflowXScroll: PropTypes.bool,
};

Box.defaultProps = {
  className: undefined,
  noPadding: false,
  elevation: true,
  noOverflow: false,
  size: undefined,
  overflowXScroll: false,
};
