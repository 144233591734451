import React from "react";
import { usePPAnnouncementBannerActivateMutation } from "../../../../../api/patientPortal/announcementBanner/usePPAnnouncementBannerActivateMutation";
import { usePPAnnouncementBannerDeactivateMutation } from "../../../../../api/patientPortal/announcementBanner/usePPAnnouncementBannerDeactivateMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { extractApiErrorData } from "../../../../../utilities/api";

export function useUpdateBannerStatus(onConflict) {
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();
  const [id, setId] = React.useState(null);

  const activate = usePPAnnouncementBannerActivateMutation({
    onError: (response) => {
      const conflicts = extractApiErrorData(response);
      if (conflicts?.length) {
        uiNotification.error(t("patientPortal.banner.error.activateConflict"));
        onConflict?.({
          ids: conflicts.map((c) => c.id),
          original: conflicts,
        });
      } else {
        uiNotification.error(t("patientPortal.banner.error.activate"));
      }
    },
  });

  const deactivate = usePPAnnouncementBannerDeactivateMutation({
    onError: () => {
      uiNotification.error(t("patientPortal.banner.error.deactivate"));
    },
  });

  const initiate = async (id, isActive) => {
    try {
      setId(id);
      if (isActive) {
        await deactivate.mutateAsync(id);
      } else {
        await activate.mutateAsync(id);
      }
    } finally {
      setId(null);
    }
  };

  return {
    initiate,
    isLoading: (id_) =>
      (activate.isLoading || deactivate.isLoading) && id_ === id,
  };
}
