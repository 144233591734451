import * as yup from "yup";
import { TWO_FACTOR_TYPES } from "../consts/api";
import { isNullish, removeNullishFromShape } from "../utilities/general";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";
import { clearUserData } from "../Utils";

/**
 * Since we have 2FA - auth data is split into 2 types
 *
 * Login Data - data that is saved to localStorage after successful login (accessToken and wpuId)
 *  This data will persist in storage until logout action.
 * Pre-Login Data - data that is temporary saved to localStorage that is needed for completing 2FA.
 *  Will be erased after user is completed 2FA
 */

export const loginSchema = yup.object({
  accessToken: yup.string().required(),
  wpuId: yup.number().required(),
});

export const preLoginSchema = yup.object({
  wpuId: yup.number().required(),

  twoFactorType: yup
    .string()
    .test({
      test: (value) =>
        Object.values(TWO_FACTOR_TYPES).includes(value) || isNullish(value),
    })
    .nullable(),

  googleAuthCode: yup.string().nullable(),
});

export class AuthService {
  setLoginData(loginData) {
    const data = loginSchema.validateSync(loginData, {
      strict: true,
    });
    localStorage.setItem(LOCAL_STORAGE_KEYS.accessToken, data.accessToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.wpuId, data.wpuId);
  }

  getLoginData() {
    try {
      return loginSchema.validateSync(
        {
          accessToken: localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken),
          wpuId: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.wpuId)),
        },
        {
          strict: true,
        },
      );
    } catch {
      return {
        accessToken: null,
        wpuId: null,
      };
    }
  }

  setPreLoginData(data) {
    const validData = preLoginSchema.validateSync(data, {
      strict: true,
    });
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.preLoginData,
      JSON.stringify(removeNullishFromShape(validData)),
    );
  }

  getPreLoginData() {
    try {
      const preLoginData = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.preLoginData),
      );
      return preLoginSchema.validateSync(preLoginData, {
        strict: true,
      });
    } catch {
      return {
        wpuId: null,
        twoFactorType: null,
        googleAuthCode: null,
      };
    }
  }

  afterLogin() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.preLoginData);
  }

  logout() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.preLoginData);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.accessToken);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.wpuId);
    clearUserData();
  }

  isLoggedIn() {
    return Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken));
  }
}

export const authService = new AuthService();
