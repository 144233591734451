import React from "react";
import cx from "clsx";
import classes from "./styles.module.scss";
import InventorySidebar from "../../Components/Inventory/InventorySidebar";

export function LayoutInventory({ children, contentClassName }) {
  return (
    <div className={classes.root}>
      <InventorySidebar />
      <div className={cx(classes.content, contentClassName)}>{children}</div>
    </div>
  );
}
