import * as yup from "yup";

export const settingRecordSchema = yup.object({
  id: yup.number().required(),
  type: yup.string().required(),
  name: yup.string().required(),
  start_value: yup.number().nullable(),
  end_value: yup.number().nullable(),
  color: yup.string().nullable(),
});

export const patientVitalSchema = yup.object({
  id: yup.number().required(),
  patient_id: yup.number().required(),
  height: yup.number().nullable(),
  weight: yup.number().nullable(),
  bmi: yup.number().nullable(),
  body_fat: yup.number().nullable(),
  blood_pressure_systolic: yup.number().nullable(),
  blood_pressure_diastolic: yup.number().nullable(),
  pulse: yup.number().nullable(),
  temperature: yup.number().nullable(),
  respiration: yup.number().nullable(),
  vital_date: yup.string().required(),
  creator: yup.object({
    id: yup.number().required(),
    full_name: yup.string().required(),
  }),
  modifier: yup.object({
    id: yup.number().required(),
    full_name: yup.string().required(),
  }),
  notes_count: yup.number().required(),
});

export const graphNodeSchema = yup.object({
  date: yup.string().required(),
  value: yup.number().required(),
});
