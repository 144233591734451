import React, { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Footer } from "../../../../../shared/ModalCharge/Footer";
import { usePaymentFlow } from "../../../../../hooks/usePaymentFlow";
import PaymentTipsModal from "./PaymentTipsModal";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { Checkbox } from "../../../../../../../../shared/Checkbox/Checkbox";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";

const StripeCheckoutPaymentForm = () => {
  const { isGuest } = useInvoice();
  const { paymentViewClose } = useInvoiceActions();
  const { showTipsModal } = usePaymentFlow();
  const [formCompleted, setFormCompleted] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    showTipsModal.update(true);
  };

  return (
    <form>
      <PaymentElement onChange={(event) => setFormCompleted(event.complete)} />
      {!isGuest && (
        <div className="m-t-25">
          <Checkbox
            size="small"
            label={tSales("checkoutInvoice.saveCcToAccount")}
            isChecked={saveCard}
            onChange={() => setSaveCard(!saveCard)}
          />
        </div>
      )}
      {showTipsModal.value && (
        <PaymentTipsModal saveCard={saveCard} showTipsModal={showTipsModal} />
      )}

      <Footer
        className={"m-t-0"}
        onClose={(e) => {
          e.preventDefault();
          paymentViewClose();
        }}
        onCharge={handleSubmit}
        isChargeDisabled={!formCompleted}
        chargeLabel={tCommon("label.continue")}
      />
    </form>
  );
};

export default StripeCheckoutPaymentForm;
