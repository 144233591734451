import React, { Component } from 'react';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReferralSources, changeReferralSourceStatus, addReferralSource, editReferralSourceName, deleteReferralSource } from '../../../Actions/referralSourcesActions'
import ConfirmationModal from '../../Appointment/Calendar/ConfirmationModal/ConfirmationModal.js';
import Loader from '../../Common/Loader.js';
import ReferralSourcesManageCategories from './ReferralSourcesManageCategories.js';
import { uiNotification } from '../../../services/UINotificationService.js';

class ReferralSources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeactivateModal: false,
            showAddModal: false,
            selectedSource: {},
            addSourceName: '',
            viewType: 'sources',
            editableSource: '',
            editedSourceName: '',
            showDeleteModal: false,
            sortby: '',
            sortorder: 'desc',
        }
    }

    componentDidMount() {
        this.props.getReferralSources()
    }

    handleInput(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleBackButton() {
        this.setState({ viewType: 'sources' })
    }

    editSource = (source) => {
        this.setState({ editableSource: source.source_name, editedSourceName: source.source_name, selectedSource: { name: source.source_name, code: source.source_code } })
    }

    saveEditedSource = () => {
        let formData = {
            source_name: this.state.editedSourceName,
            source_code: this.state.selectedSource.code,
            sort_by: this.state.sortby, 
            sort_order: this.state.sortorder
        }
        this.props.editReferralSourceName(formData)
        this.setState({ editableSource: '' })
    }

    deleteSource = () => {
        this.props.deleteReferralSource({ source_code: this.state.selectedSource.code, sort_by: this.state.sortby, sort_order: this.state.sortorder })
        this.setState({ showDeleteModal: false })
    }

    onSort = (sortBy) => {
        let sortOrder = (this.state.sortorder === 'asc') ? 'desc' : 'asc';
        this.setState({
          sortby: sortBy,
          sortorder: sortOrder,
        }, () => {
            this.props.getReferralSources({params: { sort_by: this.state.sortby, sort_order: this.state.sortorder }})
        });
    }

    handleActivateButton = () => {
        if(this.state.selectedSource.status) {
            this.setState({ showDeactivateModal: true })
        }
        if(!this.state.selectedSource.status) {
            this.props.changeReferralSourceStatus({ source_code: this.state.selectedSource.code, status: 1, sort_by: this.state.sortby, sort_order: this.state.sortorder })
        }
    }

    render() {
        const userPermissions = JSON.parse(localStorage.getItem('userData')).permissions;

        if(!userPermissions.includes('edit-referral-sources')) {
            uiNotification.error('You are not authorized to access this page. Please contact your admin!')
            this.props.history.push('/settings/profile')
            return null;
        }
        return (
            <>
                <div id="content">
                    <div className="container-fluid content setting-wrapper">
                        <Sidebar />
                        <div className="business-setion memberWalletOuter">
                            <div className="setting-setion m-b-10">
                                <div className="membership-title">
                                    Referral Sources {this.state.viewType === 'manageSubcategories' && '> ' + this.state.selectedSource.name}
                                    {this.state.viewType === 'sources' && <div className="memberRightActions">
                                        <a className="new-blue-btn pull-right edit_setting" onClick={() => this.setState({ showAddModal: true })}>Add New Source</a>
                                    </div>}
                                </div>
                            </div>
                            {this.state.viewType === 'sources' && <div className="juvly-section full-width">
                                <div className="table-responsive">
                                    <table className="table-updated setting-table survey-table no-hover no-td-border">
                                        <thead className="table-updated-thead">
                                            <tr>
                                                <th className="col-xs-1 table-updated-th sorting" onClick={() => this.onSort('referral_source')}>
                                                    Source Name
                                                    <i className={(this.state.sortorder === 'asc' && this.state.sortby === 'referral_source') ? "blue-gray referralSourcesSortIcon" : (this.state.sortorder === 'desc' && this.state.sortby === 'referral_source') ? "gray-blue referralSourcesSortIcon"  : "gray-gray referralSourcesSortIcon"}/>
                                                </th>
                                                <th className="col-xs-4 table-updated-th text-center">Action</th>
                                                <th className="col-xs-4 table-updated-th"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="ajax_body">
                                            {this.props.referralSources.data && Object.values(this.props.referralSources.data || {}).map(source => {
                                               if(!source.is_deleted){ 
                                                    return (
                                                        <tr key={source.source_name}>
                                                            <td className={`${this.state.editableSource ? 'col-xs-3' : 'col-xs-1 text-nowrap'} table-updated-th`}>
                                                                {this.state.editableSource !== source.source_name ?
                                                                    source.source_name :
                                                                    <div className="newInputFileldOuter">
                                                                        <input type="text" placeholder="Category Name" className="newInputField" name="editedSourceName" value={this.state.editedSourceName} onChange={(event) => this.handleInput(event)} autoFocus />
                                                                        <button type="button" className="new-blue-btn m-l-0 m-r-5 m-t-5" onClick={() => this.saveEditedSource()}>Save</button>
                                                                        <a className="new-white-btn cancelAction m-t-5" onClick={() => this.setState({ editableSource: '' })}>Cancel</a>
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className="col-xs-4 table-updated-th text-center text-nowrap">
                                                                {!source.is_from_leads && <>
                                                                    <a className={`new-line-btn no-margin m-l-2`} onClick={() => this.setState({ selectedSource: { name: source.source_name, status: source.source_status, code: source.source_code } }, () => this.handleActivateButton())}>{source.source_status ? 'Deactivate' : 'Activate'}</a>
                                                                    <a className="m-r-0 edit_setting easy-link p-l-20" onClick={() => this.editSource(source)}><i className="fa fa-pencil-alt m-r-5" />Edit</a>
                                                                    <a className="m-r-0 edit_setting easy-link p-l-5" onClick={() => this.setState({ showDeleteModal: true, selectedSource: { name: source.source_name, status: source.source_status, code: source.source_code } })}><i className="fa fa-trash-alt m-r-5" />Delete</a>
                                                                </>}
                                                            </td>
                                                            <td className="col-xs-3 table-updated-th text-center">{(Boolean(source.source_status) && !source.is_from_leads) && <a className="new-line-btn no-width no-margin m-l-2" onClick={() => this.setState({ viewType: "manageSubcategories", selectedSource: { name: source.source_name, status: source.source_status, code: source.source_code, subcategories: source.subcategories }, editableSource: '' })}>Manage Subcategories</a>}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>}
                            {this.state.viewType === "manageSubcategories" && <ReferralSourcesManageCategories selectedSource={this.state.selectedSource} handleBackButton={() => this.handleBackButton()}/>}
                        </div>
                    </div>
                </div>
                <ConfirmationModal isOpen={this.state.showDeleteModal} message={"Are you sure you want to delete this source?"} confirmFn={() => this.deleteSource()} cancelFn={() => this.setState({ showDeleteModal: false })} />
                <ConfirmationModal isOpen={this.state.showDeactivateModal} message={`Are you sure you want to deactivate this source?`} confirmFn={() => { this.props.changeReferralSourceStatus({ source_code: this.state.selectedSource.code, status: this.state.selectedSource.status ? 0 : 1, sort_by: this.state.sortby, sort_order: this.state.sortorder }); this.setState({ showDeactivateModal: false }) }} cancelFn={() => this.setState({ showDeactivateModal: false })} />
                <Loader showLoader={this.props.showLoader} isFullWidth={true} />
                {this.state.showAddModal && <div className='modalOverlay'>
                    <div className="small-popup-outer">
                        <div className="small-popup-header p-l-15">
                            <div className="popup-name">Add New Source</div>
                            <a className="small-cross" onClick={() => this.setState({ showAddModal: false })} >×</a>
                        </div>
                        <div className="small-popup-content">
                            <div className="juvly-container">
                                <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Source Name <span className="setting-require">*</span></div>
                                    <input type="text" className="newInputField" name="addSourceName" value={this.state.addSourceName} onChange={(event) => this.handleInput(event)} />
                                </div>
                            </div>
                            <button className="new-blue-btn pull-right m-b-10 m-r-10" onClick={() => {this.props.addReferralSource({ source_name: this.state.addSourceName }) ;this.setState({ showAddModal: false, addSourceName: '' })}}>Add</button>
                        </div>
                    </div>
                </div>}
                
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        referralSources: state.ReferralSourceReducer.referralSources,
        showLoader: state.ReferralSourceReducer.showLoader
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getReferralSources,
        changeReferralSourceStatus,
        addReferralSource,
        editReferralSourceName,
        deleteReferralSource
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSources);
