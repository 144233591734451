import * as yup from "yup";
import { useFormik } from "formik";
import { tAuth } from "../../../i18n/useAppTranslation";

const schema = yup.object({
  email: yup
    .string()
    .email(tAuth("formError.emailInvalid"))
    .required(tAuth("formError.emailRequired")),

  password: yup.string().required(tAuth("formError.passwordRequired")),

  isRecaptchaValid: yup.bool().test({
    message: tAuth("formError.reCaptchaRequired"),
    test: Boolean,
  }),
});

export function useFormLogin({ onSubmit }) {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      isRecaptchaValid: false,
    },
    validationSchema: schema,
    onSubmit,
  });

  const hasError = (field) => {
    return Boolean(errors[field] && touched[field]);
  };

  const onRecaptchaStatusChange = (nextState) => {
    setFieldValue("isRecaptchaValid", nextState);
  };

  return {
    form: values,
    isFormValid: isValid,
    hasError,
    submit: handleSubmit,
    onFormValueChange: handleChange,
    onFormValueBlur: handleBlur,
    onRecaptchaStatusChange,
    errors,
  };
}
