import * as yup from "yup";
import moment from "moment";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { useExtendedFormik } from "../../../../../hooks/useExtendedFormik";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import {
  apiDateStringToDate,
  dateToApiDateString,
} from "../../../../../utilities/api";
import { PREVIEW_DATE_FORMAT } from "../../../../../consts/general";

export const useForm = (onSubmit) => {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const { values, changeField, getError, handleSubmit } = useExtendedFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues: {
      date: "",
      reason: "",
    },
    validationSchema: yup.object({
      date: yup
        .string()
        .required(
          tCommon("modalEditGiftCardExpiryDate.form.error.dateRequired"),
        ),
      reason: yup
        .string()
        .required(
          tCommon("modalEditGiftCardExpiryDate.form.error.reasonRequired"),
        ),
    }),
  });

  return {
    submit: handleSubmit,

    date: {
      value: values.date ? apiDateStringToDate(values.date) : null,
      formattedValue: values.date
        ? moment(values.date).format(
            user?.previewDateFormat || PREVIEW_DATE_FORMAT,
          )
        : null,
      onChange: (next) => changeField("date", dateToApiDateString(next)),
      getError: () => getError("date"),
    },

    reason: {
      value: values.reason,
      onChange: (e) => changeField("reason", e.target.value),
      getError: () => getError("reason"),
    },
  };
};
