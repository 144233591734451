import { QUESTIONNAIRE_YES_NO_ANSWERS } from "../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireAnswersMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function useYesNoOptions() {
  var { tCommon: t } = useAppTranslation.Common();

  return [
    {
      value: QUESTIONNAIRE_YES_NO_ANSWERS.yes,
      slot: t("label.yes"),
    },
    {
      value: QUESTIONNAIRE_YES_NO_ANSWERS.no,
      slot: t("label.no"),
    },
  ];
}
