import moment from "moment";
import { matchPath } from "react-router";
import numberToWords from "number-to-words";
import { convertTime24to12, getAmPm } from "../../Utils/services";
import {
  FORM_SERVICE_PLACEHOLDER_ID,
  OUT_OF_OFFICE_BOOKING_TIMES,
  OUT_OF_OFFICE_BOOKING_TIMES_START,
} from "./Event.consts";
import { getDateMonthsDayOccurrences } from "../../utilities/date";

export const composeFormService = ({
  id,
  name,
  durationInMin,
  isDepositConfirmed,
  isFree,
  isSkipPaymentCheck = false,
  isPlaceholder = false,
} = {}) => {
  if (isPlaceholder) {
    return {
      id: FORM_SERVICE_PLACEHOLDER_ID,
      name: "",
    };
  }
  return {
    id,
    name,
    durationInMin,
    isDepositConfirmed,
    isFree,
    isSkipPaymentCheck,
  };
};

export const getCalendarStartDate = (date = undefined) => {
  const d = date || moment.now();
  return moment(d).startOf("month");
};

export const wrapFormDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const previewFormDate = (formDate) => {
  return moment.utc(formDate).format("MMMM DD, YYYY");
};

export const castTo12FormatTime = (time) => {
  if (time) {
    return `${convertTime24to12(time)} ${getAmPm(time)}`;
  }
  return "";
};

export const initOutOfOfficeTimeStart = (time) => {
  let result = OUT_OF_OFFICE_BOOKING_TIMES_START[0];
  if (time && OUT_OF_OFFICE_BOOKING_TIMES_START.find((t) => t === time)) {
    result = time;
  }
  return result;
};

export const initOutOfOfficeTimeEnd = (timeStart) => {
  let result = OUT_OF_OFFICE_BOOKING_TIMES[1];
  if (timeStart) {
    const timeStartIndex = OUT_OF_OFFICE_BOOKING_TIMES.findIndex(
      (t) => t === timeStart,
    );
    if (
      timeStartIndex !== -1 &&
      timeStartIndex !== OUT_OF_OFFICE_BOOKING_TIMES.length - 1
    ) {
      result = OUT_OF_OFFICE_BOOKING_TIMES[timeStartIndex + 1];
    }
  }
  return result;
};

export const initFormDate = ({ date, boundaryDate }) => {
  let result = date;
  if (!date || moment(date).isBefore(boundaryDate)) {
    result = boundaryDate;
  }
  return result;
};

export const composeFrequencyRepeatMonthlyValue = (date) => {
  if (date) {
    const daysOccurrences = getDateMonthsDayOccurrences(date);
    const dayName = moment(date).format("dddd");
    const dayNumber = parseInt(moment(date).format("D"));
    const dayNumbers = daysOccurrences?.[dayName.toLowerCase()] || [];
    const dayIndex = dayNumbers.findIndex((d) => d === dayNumber) + 1;

    if (dayIndex !== 0) {
      if (dayIndex === dayNumbers.length) {
        return `last ${dayName}`;
      }
      return `${numberToWords.toWordsOrdinal(dayIndex)} ${dayName}`;
    }
  }

  return "";
};

export const validateWaitListMonths = ({ months = [], years }) => {
  if (years && years.id === new Date().getFullYear()) {
    return months.filter((i) => i.id >= new Date().getMonth() + 1);
  }
  return months;
};

export const getPathParams = () => {
  const leadMatch = matchPath(window.location.pathname, {
    path: "/appointment/create/lead/:leadId",
    exact: true,
    strict: false,
  });

  return {
    leadId: leadMatch?.params?.leadId
      ? Number(leadMatch.params.leadId)
      : undefined,
  };
};
