import React, {Component} from 'react';
import { getCurrencySymbol, numberFormat } from '../../../Utils/services';

class Wallet extends Component{
	constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
			select_all : false,
			walletData : props.walletData,
			clientLang : languageData.clients,
			globalLang : languageData.global,
			biLang     : languageData.business_insights
		}
	}

	static getDerivedStateFromProps(nextProps, prevState){
		let returnState = {};
		if(nextProps.walletDataTime != prevState.walletDataTime){
		if(nextProps.productsData != undefined){
			returnState.productsData = nextProps.productsData;
			nextProps.productsData.map((data,index) => {
				returnState['product-' + index] = data.selected_status;
			})
			if(nextProps.productsData.length > 0){
			let enableSelectAll;
			enableSelectAll = nextProps.productsData.find(y => y.selected_status == false);
			if(enableSelectAll){
				returnState.select_all = false;
			} else {
				returnState.select_all = true;
			}}
		}
		if(nextProps.walletData != undefined ){
			returnState.walletData = nextProps.walletData;
			returnState.import_collective = nextProps.walletData.collectiveData;
		}
		returnState.walletDataTime = nextProps.walletDataTime;
	}
		return returnState;

	}


	handleInputChange = (event) => {
		const target = event.target;
		let value= target.value;
		let name = event.target.name;
		let returnState = {}
		switch(target.type) {
			case 'checkbox': {
				value = target.checked;
				break;
			}
	  
			case 'radio' :{
			  value = target.value;
			  break;
		  }
		}
		if(target.type == 'checkbox' && target.checked == true && target.name == 'select_all') {
			let data = this.state.productsData;
			data.map((obj, index) => {
				returnState['product-' + index] = true;
			})

		} else if(target.type == 'checkbox' && target.checked == false && target.name == 'select_all') {
			let data = this.state.productsData;
			data.map((obj, index) => {
				returnState['product-' + index] = false;
			})
		}
	  returnState[event.target.name] = value;
      this.setState(returnState, () => this.sendDataToParent());
	}  

	sendDataToParent = () => {
		let checkStatus = [];
		this.state.productsData.map((data,index) => {
			if(this.state['product-' + index] == false){
				checkStatus.push(data);
			}
		})
		if(checkStatus.length > 0){
			this.setState({ select_all : false });
		} else {
			this.setState({ select_all : true });
		}
		let dataToSend = [];
		let walletData = {
			dollarCredit : this.state.walletData.dollarCredit,
            bdCredit : this.state.walletData.bdCredit,
            aspireCredit : this.state.walletData.aspireCredit,
            collectiveData : this.state.import_collective
		};
		this.state.productsData.map((data, index) => {
			dataToSend.push({
			    balance: data.balance,
				balance_units: data.balance_units,
				credit_type: data.credit_type,
				date: data.date,
				discount_package_name: data.discount_package_name,
				discount_package_type: data.discount_package_type,
				product_name: data.product_name,
				row_type: data.row_type,
				total_units: data.total_units,
				id : data.id,
				selected_status : this.state['product-' + index] ? this.state['product-' + index] : false
			})
		})

		let formData = {
			step3Data : {
				walletData : walletData,
				productsData : dataToSend
			}
		}
		this.props.handleChildState(formData);
	}
    render(){
        return(
        <div>
        <div className="merge-title full-width">
        <div className="row backgroundColorGray">
		<div className="col-md-4 col-xs-12">
			<div className="setting-setion full-width mergeLeftSection"> 
				<div className="infoSectionTitle">{this.state.globalLang.inv_wallet}</div>
				<div className="infoContent">
					
					<div className="info-row">
						<label className='info-row-label'>{this.state.clientLang.merge_dollar_credit}</label>
						<span>{numberFormat(this.state.walletData.dollarCredit,'currency',2)}</span>
					</div>
					
					<div className="info-row">
						<label className='info-row-label'>{this.state.clientLang.merge_bd_credit}</label>
						<span>{numberFormat(this.state.walletData.bdCredit,'currency',2)}</span>
					</div>
					<div className="info-row">
						<label className='info-row-label'>{this.state.clientLang.merge_xperience_credit}</label>
						<span>{numberFormat(this.state.walletData.xperienceCredit,'currency',2)}</span>
					</div>
					
					<div className="info-row">
						<label className='info-row-label'>{this.state.clientLang.merge_aspire_credit}</label>
						<span>{numberFormat(this.state.walletData.aspireCredit,'currency',2)}</span>
					</div>
					
					{/* <div className="importLog">
						<label className="setting-switch m-r-5">
						<input type="checkbox" name="import_collective" className="setting-custom-switch-input" 
						 checked= {(this.state.import_collective) ? 'checked': false}
						 value={this.state.import_collective || ''} onChange={this.handleInputChange}
						  /><span className="setting-slider"></span></label>
						{this.state.clientLang.merge_import_collective_log}
					</div> */}
					
				</div>
			</div>
		</div>
		<div className="col-md-8 col-xs-12 mergeRightSection">
			<div className="setting-setion full-width heightAuto">
				<div className="merge-main-title checkOuter">
				{this.state.productsData && this.state.productsData.length > 0 && <input type="checkbox" id="double-booking" name="select_all" value={this.state.select_all} checked={(this.state.select_all) ? "checked" : false} onChange={this.handleInputChange} />}
					<span>{this.state.clientLang.merge_products_packages}</span>
					{this.state.productsData && this.state.productsData.length > 0 && <p>{this.state.clientLang.merge_right_information_message}</p>}
				</div>
				<div className="table-responsive">
				<table className="table-updated juvly-table table-min-width no-td-border">
					<thead className="table-updated-thead">
						<tr>
							<th className="col-xs-0 table-updated-th table-checkbox">
								&nbsp;
							</th>
							<th className="col-xs-4 table-updated-th">{this.state.biLang.bi_product_name}</th>
							<th className="col-xs-4 table-updated-th p-l-5 p-r-0">{this.state.clientLang.wallet_balance_unit}</th>
							<th className="col-xs-4 table-updated-th p-l-5 p-r-0">{getCurrencySymbol()} {this.state.clientLang.wallet_balance}</th>
						</tr>
					</thead>
					<tbody className="ajax_body">
						{this.state.productsData && this.state.productsData.length>0 && this.state.productsData.map((obj,index) => {
							return(
                            <tr className="table-updated-tr" key={index}>
							<td className="col-xs-0 table-checkbox table-updated-td checkbox-recently-deleted">
							<input type="checkbox" value={obj.selected_status} name={"product-"+ index} 
							checked={(this.state["product-"+ index]) ? "checked" : false} 
							autoComplete="off" onChange={this.handleInputChange} />
							</td>
							<td className="col-xs-4 table-updated-td break-all">{obj.product_name}</td>
							<td className="col-xs-4 table-updated-td p-l-5 p-r-0 break-all">{obj.balance_units}</td>
							<td className="col-xs-4 table-updated-td p-l-5 p-r-0 break-all">{numberFormat(obj.balance,'currency',2)}</td>
						</tr>
							)
						})}
						
					</tbody>
				</table>
				{this.state.productsData && this.state.productsData.length == 0 && 
				<div className="no-record">{this.state.clientLang.clientprofile_no_rec_found}</div>}
</div>
			</div>
		</div>
		
	</div>
    </div>
    </div>
        )
    }
}

export default Wallet;
