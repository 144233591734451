import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { SketchField, Tools } from "react-sketch";
import uuid from "uuid";
import classes from "./Signature.module.scss";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";

export function Signature({
  width,
  height,
  lineColor,
  lineWidth,
  tool,
  className,
  signatureUrl,
  onChange,
  onClear,
  onReset,
  onEdit,
  isError,
  isDisabled,
  buttonsRight,
  buttonsRightClassName,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isEditMode, setIsEditMode] = useState(!signatureUrl);
  const sketchRef = useRef();
  const [sketchKey, setSketchKey] = useState(uuid.v4());

  const handleClear = () => {
    if (sketchRef) {
      sketchRef.current.clear();
      setSketchKey(uuid.v4());
      if (onClear) {
        onClear();
      }
    }
  };

  const handleChange = (e) => {
    if (
      sketchRef &&
      (e.type === "mouseup" || e.type === "touchend") &&
      !isDisabled
    ) {
      onChange(sketchRef.current.toDataURL());
    }
  };

  const handleEditModeOn = () => {
    setIsEditMode(true);
    if (onEdit) {
      onEdit();
    }
  };

  const handleReset = () => {
    handleClear();
    setIsEditMode(false);
    setSketchKey(uuid.v4());
    if (onReset) {
      onReset();
    }
  };

  useEffect(() => {
    setIsEditMode(!signatureUrl);
  }, [signatureUrl]);

  const rootClasses = cx(
    classes.root,
    {
      [classes.disabled]: isDisabled,
    },
    className,
  );

  return (
    <div className={rootClasses}>
      <div
        className={cx(classes.signature, {
          [classes.error]: isError,
        })}
      >
        <div className={classes.signBlock}>
          {!isEditMode && signatureUrl ? (
            <img src={signatureUrl} alt="sig" height={height} />
          ) : (
            <SketchField
              key={sketchKey}
              ref={sketchRef}
              width={width}
              height={height}
              tool={tool}
              lineColor={lineColor}
              lineWidth={lineWidth}
              onChange={handleChange}
            />
          )}
        </div>
      </div>
      <div className={classes.buttonsWrap}>
        <div className={classes.buttonsInner}>
          {!isEditMode ? (
            <Button
              size="small"
              onClick={handleEditModeOn}
              isDisabled={isDisabled}
            >
              {tCommon("label.editSignature")}
            </Button>
          ) : (
            <>
              <Button
                size="small"
                onClick={handleClear}
                isDisabled={isDisabled}
              >
                {tCommon("label.clear")}
              </Button>
              {signatureUrl && (
                <Button
                  size="small"
                  onClick={handleReset}
                  variant="outlined"
                  isDisabled={isDisabled}
                >
                  {tCommon("label.reset")}
                </Button>
              )}
            </>
          )}
        </div>
        {buttonsRight && (
          <div className={cx(classes.buttonsInner, buttonsRightClassName)}>
            {buttonsRight}
          </div>
        )}
      </div>
    </div>
  );
}

Signature.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  lineColor: PropTypes.string,
  lineWidth: PropTypes.number,
  tool: PropTypes.string,
  className: PropTypes.string,
  signatureUrl: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onReset: PropTypes.func,
  onEdit: PropTypes.func,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  buttonsRight: PropTypes.node,
  buttonsRightClassName: PropTypes.string,
};

Signature.defaultProps = {
  width: "400px",
  height: "200px",
  lineColor: "black",
  lineWidth: 6,
  tool: Tools.Pencil,
  className: undefined,
  signatureUrl: undefined,
  onChange: undefined,
  onClear: undefined,
  onReset: undefined,
  onEdit: undefined,
  isError: false,
  isDisabled: false,
  buttonsRight: null,
  buttonsRightClassName: undefined,
};
