import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useDebouncedState } from "../../../../../../utilities/hooks/useDebouncedState";
import { useEfaxFavouritesQuery } from "../../../../../../api/efax/useEfaxFavouritesQuery";

export const useFavoritesOptions = () => {
  const { tInbox } = useAppTranslation.Inbox();
  const [_, debouncedSearch, setSearch] = useDebouncedState("");

  const { data, isLoading } = useEfaxFavouritesQuery({
    payload: {
      searchTerm: debouncedSearch,
    },
    options: {
      onError: () => {
        uiNotification.error(tInbox("efaxOrders.error.fetchTemplates"));
      },
    },
  });

  const options = useMemo(
    () =>
      data?.data
        ?.map((f) => ({
          label: f.name,
          value: f.id,
          faxNumber: f.faxNumber,
          templateId: f.templateId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    [data?.data],
  );

  return {
    value: options,
    isLoading,
    search: {
      onChange: setSearch,
    },
  };
};
