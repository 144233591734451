import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Input.module.scss";
import { isNullish } from "../../utilities/general";

const SIZES = {
  medium: "medium",
  small: "small",
  default: "default",
};

export const Input = forwardRef(
  (
    {
      name,
      type,
      value,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      leftAdornment,
      rightAdornment,
      className,
      inputClassName,
      leftAdornmentClassName,
      rightAdornmentClassName,
      isError,
      isDisabled,
      isReadonly,
      autoComplete,
      autoFocus,
      size,
      maxLength,
      style,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const rootClasses = cx(
      classes.root,
      {
        [classes.error]: isError,
        [classes.disabled]: isDisabled,
        [classes.focused]: isFocused,
        [classes.readonly]: isReadonly,
        [classes.mediumInput]: size === SIZES.medium,
        [classes.smallInput]: size === SIZES.small,
      },
      className,
    );

    const leftAdornmentClasses = cx(
      classes.leftAdornment,
      {
        [classes.leftAdornmentSmall]: size === SIZES.small,
      },
      leftAdornmentClassName,
    );

    const rightAdornmentClasses = cx(
      classes.rightAdornment,
      {
        [classes.rightAdornmentSmall]: size === SIZES.small,
      },
      rightAdornmentClassName,
    );

    const handleBlur = (e) => {
      if (isFocused) {
        setIsFocused(false);
      }
      if (onBlur) {
        onBlur(e);
      }
    };

    const handleFocus = (e) => {
      if (!isFocused) {
        setIsFocused(true);
      }
      if (onFocus) {
        onFocus(e);
      }
    };

    return (
      <div className={rootClasses} style={style}>
        {!isNullish(leftAdornment) && (
          <div className={leftAdornmentClasses}>{leftAdornment}</div>
        )}
        <input
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={cx(classes.input, inputClassName)}
          onFocus={handleFocus}
          disabled={isDisabled}
          readOnly={isReadonly}
          ref={ref}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          maxLength={maxLength}
        />
        {!isNullish(rightAdornment) && (
          <div className={rightAdornmentClasses}>{rightAdornment}</div>
        )}
      </div>
    );
  },
);

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  leftAdornmentClassName: PropTypes.string,
  rightAdornmentClassName: PropTypes.string,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  maxLength: PropTypes.number,
  style: PropTypes.object,
};

Input.defaultProps = {
  name: undefined,
  type: "text",
  value: undefined,
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  placeholder: undefined,
  className: undefined,
  inputClassName: undefined,
  leftAdornmentClassName: undefined,
  rightAdornmentClassName: undefined,
  isError: false,
  isDisabled: false,
  isReadonly: false,
  autoComplete: undefined,
  autoFocus: false,
  size: SIZES.default,
  maxLength: undefined,
  style: undefined,
};
