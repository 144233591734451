import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, ORDER_TYPES } from "../../consts/api";
import { http } from "../../services/HttpService";
import { passOr, removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  page: yup.number().required(),
  sort_by: yup.string().nullable(),
  sort_dir: yup.string().nullable(),
  opt_in: yup.array().of(yup.mixed()).nullable(),
  clinics: yup.array().of(yup.mixed()).nullable(),
  source: yup.array().of(yup.mixed()).nullable(),
  letter_key: yup.string().nullable(),
  term: yup.string().nullable(),
  from_date: yup.date().nullable(),
  to_date: yup.date().nullable(),
});

const responseSchema = yup.object({
  fileName: yup.string().required(),
});

const composeResponse = (res) => {
  return { fileName: res.data?.data?.file };
};

export const useExportLeadsMutation = (options = {}) => {
  return useMutation(async (payload) => {
    const req = requestSchema.validateSync(payload, {
      strict: true,
    });

    const fromDate = passOr(req.from_date, null, () =>
      moment(req.from_date).format(API_DATE_FORMAT),
    );

    const toDate = passOr(req.to_date, null, () =>
      moment(req.to_date).format(API_DATE_FORMAT),
    );

    const res = await http.get(HTTP_ENDPOINTS.exportLeads(), {
      params: removeNullishFromShape({
        page: req.page,
        sort_by: req.sort_by || undefined,
        sort_dir: req.sort_dir || ORDER_TYPES.asc,
        opt_in: req.opt_in?.join(",") || null,
        clinics: req.clinics?.join(",") || null,
        source: req.source?.join(",") || null,
        letter_key: req.letter_key || undefined,
        term: req.term || undefined,
        from_date: fromDate || undefined,
        to_date: toDate || undefined,
      }),
    });

    return responseSchema.validateSync(composeResponse(res), { strict: true });
  }, options);
};
