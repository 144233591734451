import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import { useAppTranslation } from "../../../../../../../../../../../../i18n/useAppTranslation";
import { Row } from "../../../../../../shared/Row";
import { ChoiceWrap } from "../../../../../../shared/ChoiceWrap";

export function Question({ data }) {
  const { tDashboard } = useAppTranslation.Dashbaord();

  const logicJumps = useMemo(() => {
    const jumps = data.procedure_templates_logic
      .filter((x) => x.type === "jump")
      .reduce((acc, curr) => {
        const optionIndex = data.procedure_template_question_option.findIndex(
          (x) => x.id === curr.procedure_question_option_id,
        );

        return [
          ...acc,
          tDashboard("user_logs_diff.questionnaires.jump", {
            option: optionIndex + 1,
            question: curr.jump_to_question + 1,
          }),
        ];
      }, []);

    const elses = data.procedure_templates_logic
      .filter((x) => x.type === "else")
      .reduce((acc, curr) => {
        return [
          ...acc,
          tDashboard("user_logs_diff.questionnaires.else", {
            question: curr.jump_to_question + 1,
          }),
        ];
      }, []);

    return [...jumps, ...elses];
  }, [data.procedure_templates_logic]);

  return (
    <div className={styles.root}>
      <div>{data.question}</div>
      <div className={styles.type}>{data.question_type}</div>
      <Row label={tDashboard("user_logs_diff.questionnaires.description")}>
        {data.description_text}
      </Row>
      <div className={styles.choices}>
        {data.procedure_template_question_option.map((o, i) => (
          <ChoiceWrap key={i} index={i + 1}>
            {o.question_option}
          </ChoiceWrap>
        ))}
      </div>
      {logicJumps.length > 0 && (
        <Row
          gap="24px"
          label={tDashboard("user_logs_diff.questionnaires.logic_jumps")}
        >
          <ul>
            {logicJumps.map((j, i) => (
              <li key={i}>{j}</li>
            ))}
          </ul>
        </Row>
      )}
    </div>
  );
}
