import { useMemo } from "react";
import * as yup from "yup";
import { useClinicsByProviderIdQuery } from "../../../api/queries/useClinicsByProviderIdQuery";
import { uiNotification } from "../../../services/UINotificationService";

const schema = yup.array().of(
  yup.object({
    id: yup.number(),
    name: yup.string(),
  }),
);

const compose = (response) => {
  let result = [];
  if (response && Array.isArray(response.data.data)) {
    result = response.data.data.map((clinic) => ({
      id: clinic.id,
      name: clinic.clinic_name,
    }));
  }
  return result;
};

export function useApiClinicsByProvider({ providerId }) {
  const { data, isFetching } = useClinicsByProviderIdQuery(
    {
      providerId,
    },
    {
      staleTime: 0,
      enabled: Boolean(providerId),
      onError: () => {
        uiNotification.error("Unable to retrieve clinics. Try again later");
      },
    },
  );

  const clinics = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  return {
    clinics,
    isClinicsFetching: isFetching,
  };
}
