import * as yup from "yup";
import { useState } from "react";
import { useExtendedFormik } from "../../../../../../../hooks/useExtendedFormik";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";

export const useForm = ({ initialValues, onSubmit }) => {
  const { tSettings } = useAppTranslation.Settings();

  const [content, setContent] = useState({
    value: "",
    error: null,
  });

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(tSettings("efaxTemplates.formError.nameRequired")),
  });

  const { values, changeField, getError, handleSubmit } = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues,
    onSubmit,
  });

  const handleContent = (value) => {
    if (!value) {
      setContent({
        value: "",
        error: tSettings("efaxTemplates.formError.contentRequired"),
      });
    } else {
      setContent({ value: value, error: null });
    }
  };

  return {
    submit: handleSubmit,
    name: {
      value: values.name,
      onChange: (nextValue) => changeField("name", nextValue),
      getError: () => getError("name"),
    },
    content: {
      value: content.value,
      onChange: handleContent,
      getError: () => content.error,
    },
  };
};
