import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { showFormattedDate } from "../../../../../Utils";

const DATE_FORMAT = "MM/DD/YYYY hh:mm A";

export function useInfo(procedure) {
  const { tCommon } = useAppTranslation.Common();
  const exams = procedure?.exams || [];

  return {
    exams,

    patientId: Number(procedure?.patient_id),

    procedureId: Number(procedure?.id),

    clinicId: Number(procedure?.clinic_id),

    procedureName: procedure.procedure_name,

    requestedDate: exams?.[0]?.created_at
      ? showFormattedDate(exams?.[0]?.created_at, true, DATE_FORMAT)
      : tCommon("symbol.longDash"),

    requestedProvider: procedure?.user?.full_name || tCommon("symbol.longDash"),

    requestedClinic:
      procedure?.clinic?.clinic_name || tCommon("symbol.longDash"),

    completedDate: exams?.[0]?.updated_at
      ? showFormattedDate(exams?.[0]?.updated_at, true, DATE_FORMAT)
      : tCommon("symbol.longDash"),

    completedProvider: exams?.[0]?.provider_name || tCommon("symbol.longDash"),

    completedClinic: exams?.[0]?.clinic_name || tCommon("symbol.longDash"),

    isCompleted: exams.length > 0 && exams[0]?.status !== null,

    isExpired: Boolean(procedure?.exam_expired),

    examLink:
      procedure?.exam_link &&
      exams?.some((e) => e.status === null) &&
      !procedure?.exam_expired
        ? procedure.exam_link
        : null,
  };
}
