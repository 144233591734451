import { matchPath } from "react-router";
import { ROUTES } from "../../../../consts/routes";

export const getPathParams = () => {
  const clientMatch = matchPath(window.location.pathname, {
    path: ROUTES.clients.profile(":id"),
    exact: false,
    strict: false,
  });
  return {
    clientId: clientMatch?.params?.id ? Number(clientMatch.params.id) : null,
  };
};
