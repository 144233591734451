import React, { useEffect, useState } from "react";
import cx from "clsx";
import classes from "./StickyBox.module.scss";

/**
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {("left" | "right")?} param0.position
 * @param {number?} param0.visibleAfterOffset
 */
export const StickyBox = ({
  children,
  position = "right",
  visibleAfterOffset = 0,
}) => {
  const [isVisible, setIsVisible] = useState(!visibleAfterOffset);

  useEffect(() => {
    if (visibleAfterOffset > 0) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > visibleAfterOffset) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    }
  }, [visibleAfterOffset, window.scrollY]);

  return isVisible ? (
    <div
      className={cx(classes.root, {
        [classes.left]: position === "left",
        [classes.right]: position === "right",
      })}
    >
      {children}
    </div>
  ) : null;
};
