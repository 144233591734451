/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InboxSidebar from "../InboxSidebar.js";
import Loader from "../../Common/Loader.js";
import {
  autoScrolling,
  showFormattedDate,
  dateFormatPicker,
  displayName,
} from "../../../Utils/services.js";
import picClose from "../../../_legacy/images/popupClose.png";
import {
  getSmsCampaignList,
  createSmsCampaign,
  getSmsCampaignById,
  exportEmptyData,
  deleteCampaign,
  addNewSMSTemplate,
} from "../../../Actions/Inbox/inboxAction.js";
import ComingSoon from "../../Common/ComingSoon.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const formatType = "YYYY/MM/DD hh:mm A";

const dateFormat = (date) => {
  return moment(date).format(formatType);
};

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

class SmsCampaign extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      businessInsightLang: languageData.business_insights,
      globalLang: languageData.global,
      showCreateOptions: this.props.match.params.listID ? true : false,
      showTableSection: true,
      smsCampaignList: [],
      showNewTemplateFields: false,
      sms_template_id: 0,
      sms_list_id: 0,
      sending_type: "instantly",
      page: 1,
      pagesize: 20,
      smsTemplateError: false,
      nameError: false,
      listError: false,
      saveCalled: false,
      next_page_url: "",
    };

    window.onscroll = () => {
      return false;
    };
  }

  loadMore = () => {
    if (!autoScrolling()) {
      this.setState({ showLoader: true, showLoadingText: true });
      let formData = {
        page: this.state.page,
        pagesize: this.state.pagesize,
      };
      autoScrolling(true);
      this.props.getSmsCampaignList(formData);
    }
  };

  render() {
    return <ComingSoon sidebar={"inbox"} />;
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let name = target.name;
    let returnState = {};

    returnState[event.target.name] = value;
    this.setState(returnState);

    if (name == "sms_list_id" && value == "new") {
      this.props.history.push("/inbox/lists");
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.errorTime != undefined &&
      nextProps.errorTime != prevState.errorTime
    ) {
      returnState.errorTime = nextProps.errorTime;
      returnState.showLoader = false;
    }

    if (
      nextProps.smsTemplateData != undefined &&
      nextProps.smsTemplateDataTime != prevState.smsTemplateDataTime
    ) {
      returnState.smsTemplates = nextProps.smsTemplateData.data;
      returnState.showLoader = false;
      returnState.smsTemplateDataTime = nextProps.smsTemplateDataTime;
      returnState.sms_template_id = 0;
    }

    if (
      nextProps.smsCampaignData != undefined &&
      nextProps.smsCampaignDataTime != prevState.smsCampaignDataTime
    ) {
      let campaignData = nextProps.smsCampaignData.data.data;
      returnState.name = campaignData.name;
      returnState.sms_list_id = campaignData.sms_list_id;
      returnState.sms_template_id = campaignData.sms_template_id;
      returnState.sending_type = campaignData.sending_type;
      returnState.send_on = campaignData.send_on;
      returnState.campaignId = campaignData.id;
      returnState.showLoader = false;
      returnState.showCreateOptions = true;
      returnState.showConfirmation = false;
      returnState.smsCampaignDataTime = nextProps.smsCampaignDataTime;
    }

    if (
      nextProps.smsCampaignList != undefined &&
      nextProps.smsCampaignListTime != prevState.smsCampaignListTime
    ) {
      returnState.smsCampaignListTime = nextProps.smsCampaignListTime;
      returnState.smsCampaignList = nextProps.smsCampaignList.data;
      returnState.smsList = nextProps.smsCampaignList.data.sms_list;
      returnState.smsTemplates = nextProps.smsCampaignList.data.sms_templates;
      returnState.showLoader = false;
      returnState.sms_list_id = nextProps.match.params.listID
        ? nextProps.match.params.listID
        : 0;
      returnState.name = "";
      returnState.sms_template_id = 0;
      returnState.sending_type = "instantly";
      returnState.showConfirmation = false;
      returnState.send_on = dateFormat(new Date(moment().add(1, "days")));

      if (prevState.smsCampaignList.length == 0) {
        if (nextProps.smsCampaignList.data.next_page_url !== null) {
          returnState.page = prevState.page + 1;
        } else {
          returnState.next_page_url =
            nextProps.smsCampaignList.data.next_page_url;
        }
        returnState.smsCampaignList = nextProps.smsCampaignList.data.data;
      } else {
        if (nextProps.smsCampaignList.data.next_page_url !== null) {
          returnState.page = prevState.page + 1;
        } else {
          returnState.next_page_url =
            nextProps.smsCampaignList.data.next_page_url;
        }
        returnState.smsCampaignList = [
          ...prevState.smsCampaignList,
          ...nextProps.smsCampaignList.data.data,
        ];
        returnState.page = prevState.page + 1;
      }
      if (nextProps.saveCalled != undefined && nextProps.saveCalled == true) {
        returnState.showCreateOptions = false;
        nextProps.history.push("/inbox/campaign");
      }
      autoScrolling(false);
      nextProps.exportEmptyData();
    }
    return returnState;
  }

  dateChange = (date) => {
    this.setState({ send_on: dateFormat(date) });
  };

  saveCampaign = () => {
    let formData = {};
    if (!this.state.name || this.state.name.trim() === "") {
      this.setState({ nameError: true });
      return;
    }

    if (this.state.sms_list_id == "") {
      this.setState({ listError: true });
      return;
    }
    if (this.state.sms_template_id == 0 || this.state.sms_template_id == "0") {
      this.setState({ smsTemplateError: true });
      return;
    }

    formData.name = this.state.name;
    formData.sms_list_id = this.state.sms_list_id;
    formData.sms_template_id = this.state.sms_template_id;
    formData.sending_type = this.state.sending_type;
    formData.sending_status = "pending";
    if (this.state.sending_type == "delay") {
      formData.send_on = apiDateFormat(this.state.send_on);
    }
    formData.status = 0;
    if (this.state.campaignId) {
      formData.sms_campaign_id = this.state.campaignId;
    }
    this.setState({ showLoader: true, smsCampaignList: [] });
    this.props.createSmsCampaign(formData);
  };

  editCampaign = (id) => {
    this.setState({
      showLoader: true,
      listError: false,
      smsTemplateError: false,
      nameError: false,
    });
    this.props.getSmsCampaignById(id);
  };

  deleteCampaign = () => {
    let list = [];
    this.setState({ showLoader: true, smsCampaignList: list });
    this.props.deleteCampaign(this.state.deleteId);
  };

  saveTemplate = () => {
    let formData = {};
    formData.message = this.state.message;
    formData.title = this.state.title;
    this.setState({ showLoader: true });
    this.props.addNewSMSTemplate(formData);
  };

  createCampaignPopup = () => {
    this.setState(
      {
        showCreateOptions: true,
        listError: false,
        smsTemplateError: false,
        sending_type: "instantly",
        title: "",
        message: "",
        name: "",
        sms_list_id: this.props.match.params.listID
          ? this.props.match.params.listID
          : "",
        campaignId: "",
        sms_template_id: 0,
        nameError: false,
      },
      () => {},
    );
  };

  closePopup = () => {
    this.setState({ showCreateOptions: false });
    this.props.history.push("/inbox/campaign");
  };

  render1() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InboxSidebar />

          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                {this.state.globalLang.sms_campaign}
                <div className="memberRightActions">
                  <a
                    className="new-blue-btn"
                    onClick={this.createCampaignPopup}
                  >
                    {this.state.globalLang.label_create_campaign}
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.smsCampaignList.length > 0
                  ? "setting-setion m-b-15"
                  : "setting-setion m-b-15 no-display"
              }
            >
              <div className="table-responsive clients-table no-border">
                <table className="table-updated  table-min-width no-td-border no-hover">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="table-updated-th">
                        {this.state.globalLang.label_name}
                      </th>
                      <th className="table-updated-th">
                        {this.state.globalLang.global_template}
                      </th>
                      <th className="table-updated-th">
                        {this.state.globalLang.label_list}
                      </th>
                      <th className="table-updated-th">
                        {this.state.globalLang.label_status}
                      </th>
                      <th className="table-updated-th">Created</th>
                      <th className="table-updated-th"></th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.smsCampaignList &&
                      this.state.smsCampaignList.length > 0 &&
                      this.state.smsCampaignList.map((obj, idx) => {
                        return (
                          <tr key={"list-" + idx} className="table-updated-tr">
                            <td className={"table-updated-td break-all"}>
                              {obj.name}
                            </td>
                            <td className={"table-updated-td break-all"}>
                              {obj.sms_template_name}
                            </td>
                            <td className={"table-updated-td break-all"}>
                              {obj.sms_list_name}
                            </td>
                            <td className={"table-updated-td break-all"}>
                              {obj.sending_status == "pending"
                                ? "In Queue"
                                : displayName(obj.sending_status)}
                            </td>
                            <td className={"table-updated-td break-all"}>
                              {showFormattedDate(obj.created, true)}
                            </td>
                            <td className="col-xs-3 table-updated-td text-right no-padding">
                              <a
                                className="easy-link no-padding m-r-20"
                                onClick={this.editCampaign.bind(this, obj.id)}
                              >
                                <i className="fa fa-pencil-alt m-r-5" />
                                Edit
                              </a>
                              <a
                                className="easy-link no-padding m-r-15"
                                onClick={() => {
                                  this.setState({
                                    showConfirmation: true,
                                    deleteId: obj.id,
                                  });
                                }}
                              >
                                <i className="fa fa-trash-alt m-r-5" />
                                Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={
                this.state.smsCampaignList.length == 0
                  ? "not-create-report"
                  : "not-create-report no-display"
              }
            >
              <h3>{this.state.globalLang.label_no_campaign_created}</h3>
              <a
                className="new-blue-btn m-t-25"
                onClick={() => this.setState({ showCreateOptions: true })}
              >
                {this.state.globalLang.label_create_campaign}
              </a>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showCreateOptions
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle vert-middle-report-option">
            <div className="white-popup small-popup">
              <div className="white-popup-wrapper">
                <div className="membershipTypeTitle">
                  {this.state.campaignId ? "Edit" : "New"} Campaign
                  <a onClick={this.closePopup} className="popupClose">
                    <img alt="" src={picClose} />
                  </a>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.globalLang.label_campaign_name}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={
                          this.state.nameError == false
                            ? "newInputField"
                            : "newInputField field_error"
                        }
                        name="name"
                        type="text"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.globalLang.label_list}
                      </div>
                      <div className="setting-input-outer">
                        <select
                          value={this.state.sms_list_id}
                          name="sms_list_id"
                          id=""
                          className={
                            this.state.listError
                              ? "newSelectField field_error"
                              : "newSelectField"
                          }
                          onChange={this.handleInputChange}
                        >
                          <option value="">
                            {this.state.globalLang.label_choose_list}
                          </option>
                          {this.state.smsList &&
                            this.state.smsList.length > 0 &&
                            this.state.smsList.map((obj, idx) => {
                              return (
                                <option key={"list-" + idx} value={obj.id}>
                                  {obj.name}
                                </option>
                              );
                            })}
                          <option value="new">
                            {this.state.globalLang.label_create_new}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.globalLang.label_sms_template}
                      </div>
                      <div className="setting-input-outer">
                        <select
                          value={this.state.sms_template_id}
                          name="sms_template_id"
                          id=""
                          className={
                            this.state.smsTemplateError
                              ? "newSelectField field_error"
                              : "newSelectField"
                          }
                          onChange={this.handleInputChange}
                        >
                          <option value="0">
                            {this.state.globalLang.label_choose_sms_template}
                          </option>
                          {this.state.smsTemplates &&
                            this.state.smsTemplates.length > 0 &&
                            this.state.smsTemplates.map((obj, idx) => {
                              return (
                                <option key={"templates-" + idx} value={obj.id}>
                                  {obj.title}
                                </option>
                              );
                            })}
                          <option value="new">
                            {this.state.globalLang.label_create_new_template}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <div
                      className={
                        this.state.sms_template_id === "new"
                          ? "newInputFileldOuter"
                          : "no-display"
                      }
                    >
                      <div className="newInputLabel">
                        {this.state.globalLang.label_title_SMS}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className="newInputField"
                        name="title"
                        type="text"
                        onChange={this.handleInputChange}
                        value={this.state.title}
                      />
                    </div>
                  </div>

                  <div
                    className={
                      this.state.sms_template_id === "new"
                        ? "col-xs-12"
                        : "no-display"
                    }
                  >
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.globalLang.label_text_message}
                      </div>
                      <textarea
                        className={"newtextareaField"}
                        name="message"
                        onChange={this.handleInputChange}
                        value={this.state.message}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.sms_template_id === "new"
                        ? "col-xs-12"
                        : "no-display"
                    }
                  >
                    <a
                      className="easy-link no-padding m-b-10"
                      onClick={this.saveTemplate}
                    >
                      <i className="fa fa-save m-r-5" />
                      {this.state.globalLang.label_save_template}
                    </a>
                  </div>

                  <div className="col-sm-6 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.globalLang.label_sending_time}
                      </div>
                      <div className="setting-input-outer">
                        <select
                          value={this.state.sending_type}
                          name="sending_type"
                          id=""
                          className="newSelectField"
                          onChange={this.handleInputChange}
                        >
                          <option value="instantly">Instantly</option>
                          <option value="delay">Delay</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.sending_type == "delay"
                        ? "col-sm-6 col-xs-12"
                        : "no-display"
                    }
                  >
                    <div className="simpleField m-b-15 field-icon">
                      <div className="simpleLabel no-margin-right">
                        {"Date"} <span className="setting-require">*</span>
                        <a href="javascript:void(0);" title={"Date"}></a>
                      </div>
                      <div className="relative">
                        <div className="dateTimePicker">
                          <DatePicker
                            value={showFormattedDate(this.state.send_on, true)}
                            onChange={this.dateChange}
                            className={"simpleInput"}
                            name={"active_untill"}
                            selected={""}
                            minDate={new Date(moment().add(1, "days"))}
                            maxDate={new Date(moment().add(10, "years"))}
                            autoComplete="off"
                            showMonthDropdown
                            showYearDropdown
                            showTimeSelect
                            timeIntervals={60}
                            timeCaption="Time"
                            dropdownMode="select"
                            dateFormat={dateFormatPicker()}
                            placeholderText={dateFormatPicker().toLowerCase()}
                            ref={(refDatePicker) =>
                              (this.refDatePicker = refDatePicker)
                            }
                          />
                          <a
                            name="calendar-input"
                            className="simple-cal-icon"
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.saveCampaign}
                >
                  {this.state.globalLang.label_create}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={this.closePopup}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showConfirmation == true
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        ></div>
        <div
          role="dialog"
          className={
            this.state.showConfirmation == true
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => this.setState({ showConfirmation: false })}
                >
                  ×
                </button>
                <h4 className="modal-title">{"Confirmation required!"}</h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {this.state.globalLang.delete_campaign_confirmation}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={() => this.setState({ showConfirmation: false })}
                  >
                    {this.state.globalLang.label_no}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.deleteCampaign}
                  >
                    {this.state.globalLang.label_yes}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  if (state.InboxReducer.action === "GET_SMS_CAMPAIGN_LIST") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.errorTime = new Date();
    } else {
      returnState.smsCampaignList = state.InboxReducer.data;
      returnState.smsCampaignListTime = new Date();
      returnState.saveCalled = false;
    }
  }

  if (state.InboxReducer.action === "CREATE_SMS_CAMPAIGN_LIST") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.errorTime = new Date();
    } else {
      uiNotification.success(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.smsCampaignList = state.InboxReducer.data;
      returnState.smsCampaignListTime = new Date();
      returnState.saveCalled = true;
    }
  }

  if (state.InboxReducer.action === "EDIT_SMS_CAMPAIGN_LIST") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.errorTime = new Date();
    } else {
      returnState.smsCampaignData = state.InboxReducer.data;
      returnState.smsCampaignDataTime = new Date();
    }
  }
  if (state.InboxReducer.action === "DELETE_CAMPAIGN") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.errorTime = new Date();
    } else {
      uiNotification.success(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.smsCampaignList = state.InboxReducer.data;
      returnState.smsCampaignListTime = new Date();
    }
  }

  if (state.InboxReducer.action == "ADD_NEW_SMS_TEMPLATE") {
    if (
      state.InboxReducer.data.status == 200 ||
      state.InboxReducer.data.status == 201
    ) {
      returnState.smsTemplateData = state.InboxReducer.data.data;
      returnState.smsTemplateDataTime = new Date();
      uiNotification.success(
        languageData.global[state.InboxReducer.data.message],
      );
    } else {
      returnState.errorTime = new Date();
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.datetime = new Date();
    }
  }

  return returnState;
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSmsCampaignList: getSmsCampaignList,
      createSmsCampaign: createSmsCampaign,
      getSmsCampaignById: getSmsCampaignById,
      exportEmptyData: exportEmptyData,
      deleteCampaign: deleteCampaign,
      addNewSMSTemplate: addNewSMSTemplate,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SmsCampaign));
