export const usePieChartsOptions = (data) => {
  return {
    chart: {
      type: "pie",
      margin: [-10, 250, 50, 15],
      height: 380,
      width: 430,
      backgroundColor: "rgba(255, 255, 255, 0.0)",
    },
    title: {
      text: "",
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "<b>{point.name}</b><br/>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      maxHeight: 160,
      symbolHeight: 14,
      symbolWidth: 14,
      symbolRadius: 3,
      width: 230,
      itemMarginBottom: 5,
      x: 0,
      y: 70,
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "50%",
        zMin: 0,
        data: data,
      },
    ],
  };
};
