import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../queryClient";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";

export const useBulkUpdateShopifyProductListQuery = (options = {}) => {
  return useMutation(
    (data) => http.post(HTTP_ENDPOINTS.shopifyProductList.update(), data),
    {
      ...options,
      onSuccess: () => {
        options?.onSuccess?.();
        queryClient.invalidateQueries([QUERY_KEYS.shopifyProductList.get]);
      },
    },
  );
};
