import React from "react";
import { useReports } from "./hooks/useReports";
import { Header } from "./components/Header/Header";
import { Table } from "./components/Table/Table";
import { AlertBox } from "../../../../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { Box } from "../../../../../../../shared/Box/Box";
import InsightsSideBar from "../../../../../../BusinessInsights/InsightsSideBar";
import { ReportLayout } from "../../../../../../BusinessInsights/components/ReportLayout/ReportLayout";

export function NewExpiringLiabilityReport() {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { reports } = useReports();

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <InsightsSideBar />
        <div className="memberWalletOuter business-section">
          <ReportLayout>
            <Header
              total={reports.total}
              isReportsLoading={reports.isLoading}
              isDownloadExcelDisabled={!reports.isWalletExpirationConfigured}
            />
            {reports.isAccountLoading ? (
              <Box>
                <Skeleton count={15} height={39.06} borderRadius="0px" />
              </Box>
            ) : (
              <>
                {reports.isWalletExpirationConfigured ? (
                  <Table reports={reports} />
                ) : (
                  <AlertBox>
                    {tBi("liabilityReports.expiring.disabled")}
                  </AlertBox>
                )}
              </>
            )}
          </ReportLayout>
        </div>
      </div>
    </div>
  );
}
