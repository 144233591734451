import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useShowEfax = ({ clientId, fileId }, options = {}) => {
  return useQuery(
    ["showEfax"],
    () => {
      return http.get(HTTP_ENDPOINTS.showEfax(fileId), {
        params: { patient_id: clientId },
      });
    },
    { ...options, enabled: false },
  );
};
