import Intercom, {
  shutdown as intercomShutdown,
} from "@intercom/messenger-js-sdk";
import { THEME } from "../consts/theme";

export class IntercomService {
  #color = THEME.colors.primary;

  /**
   * @param {{
   *   boot: Function
   *  shutdown: Function
   * }} repo
   */
  constructor(repo) {
    this.repo = repo;
  }

  boot({ appId, userId, name, email, createdAt }) {
    const config = {
      app_id: appId,
      user_id: userId,
      name,
      email,
      created_at: createdAt,
      action_color: this.#color,
    };

    this.repo.boot(config);
  }

  shutdown() {
    this.repo.shutdown();
  }
}

export const intercomService = new IntercomService({
  boot: Intercom,
  shutdown: intercomShutdown,
});
