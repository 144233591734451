/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import "react-datepicker/dist/react-datepicker.css";
import {
  useDeleteProviderScheduleQuery,
  useGetProviderSchedulesForDeleteQuery,
} from "../../../_legacy/Queries";
import { formatTime, showFormattedDate } from "../../../Utils/services.js";
import classes from "./sass/schedule.module.scss";
import { uiNotification } from "../../../services/UINotificationService.js";

const ProviderScheduleDelete = ({
  providerId,
  backToProviderSchedule,
  selectedClinics: selectedClinicsId,
}) => {
  const [
    { showClinics, selectedClinics, selectedSchedules, showModal },
    setState,
  ] = useState({
    showClinics: false,
    selectedClinics: [],
    selectedSchedules: [],
    showModal: false,
  });

  const updateState = (newState) => {
    setState((oldState) => ({ ...oldState, ...newState }));
  };

  const refClinicButton = useRef();
  const {
    data: providerSchedules,
    dataUpdatedAt: schedulesFetchedAt,
    isLoading,
    refetch: refetchSchedules,
  } = useGetProviderSchedulesForDeleteQuery({
    providerId,
    ...(selectedClinics.length > 0 && { clinic_ids: selectedClinics }),
  });

  const { mutate: deleteSchedules, isLoading: isDeleteing } =
    useDeleteProviderScheduleQuery();

  const { associated_clinics = [], provider_schedules = [] } =
    providerSchedules?.data?.data || {};

  useEffect(() => {
    if (!selectedClinics.length && associated_clinics.length > 0) {
      updateState({
        selectedClinics: associated_clinics.map(
          (clinicDetails) => clinicDetails.clinic_id,
        ),
      });
    }
  }, [schedulesFetchedAt]);

  useEffect(() => {
    if (selectedClinicsId.length) {
      updateState({
        selectedClinics: selectedClinicsId,
      });
    }
  }, [selectedClinicsId]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        refClinicButton.current &&
        !refClinicButton.current.contains(event.target)
      ) {
        updateState({ showClinics: false });
      }
    };

    if (showClinics) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showClinics]);

  const toggleClinics = (event) => {
    if (
      event.target.className == "multi-sel-btn" ||
      event.target.className == "line-btn pull-right text-center"
    ) {
      updateState({ showClinics: !showClinics });
    }
  };

  const showDeleteModal = () => {
    if (selectedSchedules.length) {
      updateState({ showModal: true });
    } else {
      uiNotification.error("Please select at least one schedule to delete!");
    }
  };

  const dismissModal = () => {
    updateState({ showModal: false });
  };

  const deleteSchedule = () => {
    dismissModal();
    deleteSchedules(
      {
        schedule_ids: selectedSchedules,
        provider_id: providerId,
      },
      {
        onSuccess: () => {
          updateState({ selectedSchedules: [] });
        },
      },
    );
  };

  const toggleAllClinics = () => {
    updateState({
      selectedClinics:
        associated_clinics.length !== selectedClinics.length
          ? associated_clinics.map((clinicDetails) => clinicDetails.clinic_id)
          : [],
    });
  };

  const handleSelectClinic = (event) => {
    const { clinicId, indexInSelectedClinics } = event.currentTarget.dataset;

    const tempSelectedSchedules = [...selectedClinics];

    if (indexInSelectedClinics > -1) {
      tempSelectedSchedules.splice(parseInt(indexInSelectedClinics), 1);
    } else {
      tempSelectedSchedules.push(parseInt(clinicId));
    }

    updateState({ selectedClinics: tempSelectedSchedules });
  };

  const toggleAllSchedules = (event) => {
    updateState({
      selectedSchedules: event.target.checked
        ? provider_schedules.map((scheduleDetails) => scheduleDetails.id)
        : [],
    });
  };

  const handleSelectSchedule = (event) => {
    const { scheduleId, indexInSelectedSchedules } =
      event.currentTarget.dataset;

    const tempSelectedSchedules = [...selectedSchedules];

    if (indexInSelectedSchedules > -1) {
      tempSelectedSchedules.splice(parseInt(indexInSelectedSchedules), 1);
    } else {
      tempSelectedSchedules.push(parseInt(scheduleId));
    }

    updateState({ selectedSchedules: tempSelectedSchedules });
  };

  return (
    <div>
      <div>
        <div className="juvly-section full-width">
          <div className="setting-search-outer">
            <a
              onClick={backToProviderSchedule}
              className="pull-left go-back go-back-arrow"
            />
            <div
              className={clsx("multi-sel-btn", { active: showClinics })}
              onClick={toggleClinics}
              ref={refClinicButton}
            >
              {associated_clinics.length == 0
                ? "No Clinic"
                : selectedClinics.length == 0
                ? "All Clinics"
                : "Clinics (" + selectedClinics.length + ")"}
              <ul
                className={clsx("new-dropdown-menu Providers", {
                  "no-visible": !showClinics,
                })}
              >
                <li id="select_btn_li">
                  <a
                    className={clsx("line-btn pull-right text-center", {
                      "no-display": selectedClinics.length === 0,
                    })}
                    onClick={refetchSchedules}
                  >
                    Apply
                  </a>
                  <a
                    className={clsx("line-btn pull-right", {
                      "no-display": associated_clinics.length === 0,
                    })}
                    onClick={toggleAllClinics}
                    disabled={associated_clinics.length}
                  >
                    {associated_clinics.length === selectedClinics.length
                      ? "Unselect All"
                      : "Select All"}
                  </a>
                </li>
                {associated_clinics.length > 0 &&
                  associated_clinics.map(
                    ({ clinic_id, clinic: { clinic_name } }) => {
                      const indexInSelectedClinics =
                        selectedClinics.indexOf(clinic_id);

                      return (
                        <li key={"clinics-" + clinic_id}>
                          <label>
                            <span
                              onClick={handleSelectClinic}
                              data-clinic-id={clinic_id}
                              data-index-in-selected-clinics={
                                indexInSelectedClinics
                              }
                            >
                              <input
                                type="checkbox"
                                checked={indexInSelectedClinics > -1}
                              />
                            </span>

                            {clinic_name}
                          </label>
                        </li>
                      );
                    },
                  )}
              </ul>
            </div>
            <div className="right-sign-btn">
              <input
                className="pull-left sel-all-visible"
                type="checkbox"
                id="select-all-pending-procedures"
                checked={
                  provider_schedules.length === selectedSchedules.length &&
                  provider_schedules.length > 0
                }
                onChange={toggleAllSchedules}
              />
              <label className="search-text">Select All</label>
              <button
                type="submit"
                className="new-blue-btn pull-right consent-model"
                id="sign"
                onClick={showDeleteModal}
              >
                Delete Schedule
              </button>
            </div>
          </div>

          <div className="table-responsive min-h-200">
            <table className="table-updated setting-table min-w-1000 ajax-view">
              <thead className="table-updated-thead">
                <tr>
                  <th className="table-checkbox table-updated-th"></th>
                  <th className="col-xs-4 table-updated-th">Clinic Name</th>
                  <th className="col-xs-4 table-updated-th">Date</th>
                  <th className="col-xs-4 table-updated-th">Schedule</th>
                </tr>
              </thead>
              <tbody className="patient-list">
                {provider_schedules &&
                  provider_schedules.length > 0 &&
                  provider_schedules.map(
                    ({ id, title, schedule_date, start_time, end_time }) => {
                      const indexInSelectedSchedules =
                        selectedSchedules.indexOf(id);

                      return (
                        <tr
                          className="table-updated-tr md-rooms-checkbox"
                          key={`schedule-${id}`}
                        >
                          <td
                            className="table-checkbox table-updated-td"
                            data-schedule-id={id}
                            data-index-in-selected-schedules={
                              indexInSelectedSchedules
                            }
                            onClick={handleSelectSchedule}
                          >
                            <input
                              type="checkbox"
                              checked={indexInSelectedSchedules > -1}
                              className="cursor-pointer select_pending_procedure"
                            />
                          </td>
                          <td className="col-xs-4 table-updated-td">{title}</td>
                          <td className="col-xs-4 table-updated-td">
                            {" "}
                            {showFormattedDate(schedule_date)}
                          </td>
                          <td className="col-xs-4 table-updated-td modal-link">
                            {formatTime(start_time)} - {formatTime(end_time)}
                          </td>
                        </tr>
                      );
                    },
                  )}
              </tbody>
            </table>
            {provider_schedules && provider_schedules.length == 0 && (
              <div className={classes.noRecordFoundToDelete}>
                Sorry, No Record Found
              </div>
            )}
          </div>
        </div>
        <div className={showModal ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={showModal ? "modal fade in displayBlock" : "modal fade"}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={dismissModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {"Confirmation Required"}
                  {showModal}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                Are you sure you want to delete selected schedules?
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn logout pull-right"
                    data-dismiss="modal"
                    onClick={dismissModal}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={deleteSchedule}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isLoading || isDeleteing
              ? "new-loader text-left displayBlock full-fixed-loader"
              : "new-loader text-left full-fixed-loader"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderScheduleDelete;
