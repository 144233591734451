import { isNullish } from "../../../../../utilities/general";

/**
 * @typedef {{
 *  start_value: number;
 *  end_value?: number;
 *  color: string;
 * }} R
 *
 * @param {number} bmiValue
 * @param {Array<R>} bmiRanges
 *
 * @returns {R}
 */
export const findBmiRange = (bmiValue, bmiRanges = []) => {
  const boundaryNodes = bmiRanges
    .filter((n) => isNullish(n.end_value))
    .map((n) => ({
      ...n,
      end_value: Infinity,
    }));

  const restNodes = bmiRanges.filter((n) => !isNullish(n.end_value));

  return [...boundaryNodes, ...restNodes].find(
    (n) => bmiValue >= n.start_value && bmiValue <= n.end_value,
  );
};
