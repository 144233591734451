import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

export const GET_PHARMACY_PACKAGES_ADDITION_FIELDS = {
  state: "state",
};

const requestSchema = yup.object({
  patient_id: yup.number().required(),
  exam_ids: yup.array().of(yup.number()).required(),
  clinic_id: yup.number().required(),
  state: yup.string().nullable(),
});

const responseSchema = yup.array().of(
  yup.object({
    pharmacy_id: yup.number().required(),
    package_id: yup.number().required(),
    pharmacy_name: yup.string().required(),
    package_name: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  return res?.data?.data || [];
};

const createQualiphyPharmacyPackagesQueryKey = (...keys) => [
  QUERY_KEYS.qualiphy.pharmacyPackages,
  ...keys,
];

export function useQualiphyPharmacyPackagesQuery(payload, options = {}) {
  return useQuery(
    createQualiphyPharmacyPackagesQueryKey(
      payload.patient_id,
      payload.exam_ids.join(","),
      payload.clinic_id,
      payload.state,
    ),
    async () => {
      const req = requestSchema.validateSync(payload, { strict: true });
      const res = await http.post(
        HTTP_ENDPOINTS.qualiphy.getPharmacyPackages(),
        req,
      );

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
