import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { ErrorLogs } from "../../../../../shared/ErrorLogs/ErrorLogs";
import classes from "./EditorFilesMobileFooter.module.scss";

export function EditorFilesMobileFooter({
  isFilePending,
  isFilesParsing,
  onPostMultiple,
  isPosting,
  errorLogs,
}) {
  return (
    <div>
      <Button
        fullWidth
        onClick={onPostMultiple}
        size="small"
        isDisabled={isFilePending || isFilesParsing || isPosting}
        leftAdornment={
          isPosting ? <CircularProgress size="tiny" color="white" /> : undefined
        }
      >
        Upload All
      </Button>
      {errorLogs.length > 0 && (
        <ErrorLogs
          logs={errorLogs.map((e) => ({
            label: e.fileName,
            errors: e.errors,
          }))}
          type="nested"
          className={classes.errorLogs}
        />
      )}
    </div>
  );
}

EditorFilesMobileFooter.propTypes = {
  isFilePending: PropTypes.bool,
  isFilesParsing: PropTypes.bool.isRequired,
  onPostMultiple: PropTypes.func.isRequired,
  isPosting: PropTypes.bool.isRequired,
  errorLogs: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
};

EditorFilesMobileFooter.defaultProps = {
  isFilePending: false,
  errorLogs: [],
};
