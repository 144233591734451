import React, { useState } from "react";
import PropTypes from "prop-types";
import parentClasses from "../../sass/ProviderRoom.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Button } from "../../../../shared/Button/Button";
import { ProcedureTabs } from "../../components/ProcedureTabs/ProcedureTabs";
import { PROVIDER_ROOM_PROCEDURE_TYPE } from "../../../../api/queries/useProviderRoomProceduresQuery";
import { Table } from "../../../../shared/Table/Table";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useTableHealth } from "../hooks/useTableHealth";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import ReactSelect from "../../../../Components/Common/ReactSelect";

export function TabHealth({
  onChangeTab,
  isVisible,
  procedures,
  unsend,
  isUnsending,
  availableClinicsList = [],
  onChangeClinic,
  clinicId,
  showHideActionButton,
  providerUserId,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { cols, data, selected, resetSelected } = useTableHealth({
    procedures,
    showHideActionButton,
    providerUserId,
  });

  const handleChangeTab = (type) => {
    onChangeTab(type);
    resetSelected();
    onChangeClinic("");
  };

  const handleUnsend = () => {
    setIsConfirmModalOpen(false);
    unsend({ procedureIds: selected }).then(resetSelected);
  };

  const handleClinicChange = ({ id }) => {
    onChangeClinic(id.toString());
  };

  return (
    <>
      <div className={parentClasses.procedureTabsRow}>
        <ProcedureTabs
          value={PROVIDER_ROOM_PROCEDURE_TYPE.health}
          onChange={handleChangeTab}
        />
        <div className="col-12 col-sm-5 col-md-4 col-lg-3 p-r-0">
          <ReactSelect
            name={"id"}
            selectedId={clinicId}
            list={[
              { name: "All Clinics", id: "" },
              ...availableClinicsList,
            ].map((i) => ({
              value: i.id,
              label: i.name,
            }))}
            value={"value"}
            label={"label"}
            handleReactSelect={handleClinicChange}
            isValid={true}
            placeholder={"Select Clinic"}
            isMulti={false}
          />
        </div>
        {!showHideActionButton && (
          <Button
            size="small"
            onClick={() => setIsConfirmModalOpen(true)}
            isDisabled={selected.length === 0 || isUnsending}
            leftAdornment={
              isUnsending ? (
                <CircularProgress size="tiny" color="white" />
              ) : undefined
            }
          >
            {tCommon("label.unsend")} ({selected.length})
          </Button>
        )}
      </div>
      {isVisible && (
        <Table
          bgColor="white"
          cols={cols}
          data={data}
          headClassName={parentClasses.tableHead}
        />
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onCancel={() => setIsConfirmModalOpen(false)}
        onConfirm={handleUnsend}
      >
        {tCommon("providerRoom.confirm.unsendFromMd")}
      </ConfirmModal>
    </>
  );
}

TabHealth.propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  procedures: PropTypes.array.isRequired,
  unsend: PropTypes.func.isRequired,
  isUnsending: PropTypes.bool.isRequired,
};
