import * as yup from "yup";
import { useFormik } from "formik";
import { USER_PAYMENT_SYSTEMS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";
import { WAIT_LIST_YEARS } from "../Event.consts";
import { phoneUtil } from "../../../utilities/phone";

const schema = yup.object({
  userPaymentSystem: yup.string(),

  clinics: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "Select at least one clinic"),

  providers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "Select at least one provider"),

  services: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "Select at least one service"),

  days: yup
    .array()
    .of(yup.number())
    .min(1, "Select at lease one preferred day"),

  times: yup
    .array()
    .of(yup.number())
    .min(1, "Select at least one preferred time slot"),

  months: yup
    .array()
    .of(
      yup.object({
        id: yup.number(),
        name: yup.string(),
      }),
    )
    .min(1, "Select at lease one preferred month"),

  years: yup.object({
    id: yup.number(),
    name: yup.string(),
  }),

  clientId: yup.number().test({
    name: "clientIdRequired",
    message: "Seems like you forgot to select client from the search list",
    test: function (value) {
      return !(!value && this.parent.clientName);
    },
  }),

  clientName: yup.string().required("Select client from the search list"),

  clientEmail: yup.string().email("Provide valid client email"),

  clientPhone: yup.string().test({
    message: "Provide valid client phone number",
    test: function (value) {
      if (!value) {
        return true;
      }
      return phoneUtil.isPossible(phoneUtil.compose(value));
    },
  }),

  notes: yup.string(),

  clearentZipCode: yup.string().test({
    name: "clearentZipCodeRequired",
    message: "Provide Zip Code",
    test: function (value) {
      const paymentSystem = this.parent.userPaymentSystem;
      const isEnterCreditCardDetails = this.parent.isEnterCreditCardDetails;
      if (
        paymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
        isEnterCreditCardDetails &&
        !value
      ) {
        return false;
      }
      return true;
    },
  }),

  clearentEmail: yup
    .string()
    .test({
      name: "clearentEmailRequired",
      message: "Provide Email for credit card data",
      test: function (value) {
        const paymentSystem = this.parent.userPaymentSystem;
        const isEnterCreditCardDetails = this.parent.isEnterCreditCardDetails;
        if (
          paymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
          isEnterCreditCardDetails &&
          !value
        ) {
          return false;
        }
        return true;
      },
    })
    .email("Provide valid email for credit card data"),

  isAddNewCard: yup.bool().required(""),

  isEnterCreditCardDetails: yup.bool().required(),
});

export const initWaitListForm = ({ userPaymentSystem, clientById }) => ({
  userPaymentSystem,
  clinics: [],
  providers: [],
  services: [],
  days: [],
  times: [],
  months: [],
  years: WAIT_LIST_YEARS[0],
  clientId: clientById.id || "",
  clientName: clientById.fullName || "",
  clientEmail: clientById.email || "",
  clientPhone: clientById.phone || "",
  notes: "",
  clearentZipCode: clientById.pinCode || "",
  clearentEmail: clientById.email || "",
  isAddNewCard: false,
  isEnterCreditCardDetails: false,
});

export function useFormWaitList(initialValues) {
  const { values, errors, setFieldValue, validateForm } = useFormik({
    initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const submit = (submitter) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length === 0) {
        submitter(values);
      } else {
        Object.values(errors).forEach((message) => {
          if (message) {
            uiNotification.error(message);
          }
        });
      }
    });
  };

  const hasError = (field) => {
    return Object.keys(errors).includes(field);
  };

  return {
    form: values,
    setFormValue: setFieldValue,
    submit,
    hasError,
  };
}
