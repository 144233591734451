import React from "react";
import styles from "./styles.module.scss";
import { useAppTranslation } from "../../../../../../../../../../../../i18n/useAppTranslation";
import { Image } from "./shared/Image";
import { ChoiceWrap } from "../../../../../../shared/ChoiceWrap";
import { Text } from "./shared/Text";

export function Question({ data }) {
  const { tDashboard } = useAppTranslation.Dashbaord();

  const getChoice = (c, i) => {
    let choice = null;

    switch (c.choice_type) {
      case "image":
        choice = (
          <Image
            displayLabels={c.display_labels > 0}
            label={c.image_label}
            url={c.image_url}
            multiSelect={c.multiple_selection > 0}
          />
        );
        break;
      case "text":
        choice = <Text text={c.text} multiSelect={c.multiple_selection > 0} />;
        break;
      default:
        choice = null;
        break;
    }

    return (
      <ChoiceWrap key={c.id} index={i + 1}>
        {choice}
      </ChoiceWrap>
    );
  };

  return (
    <div className={styles.root}>
      <div>{data.question}</div>
      <div className={styles.type}>
        {tDashboard(
          `user_logs_diff.questionnaires.simple_type.${data.question_type}`,
        )}
      </div>
      <div className={styles.choices}>
        {data.question_choices.map(getChoice)}
      </div>
    </div>
  );
}
