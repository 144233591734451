import React from "react";
import classes from "./Amount.module.scss";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { formatCurrency } from "../../../../../utilities/general";

export function Amount() {
  const { tCommon } = useAppTranslation.Common();
  const { invoice, beforeTipAmount, isTipsEnabled } = useInvoice();

  const formattedTipAmount = formatCurrency(
    invoice?.tipAmount,
    invoice?.currency,
  );
  const formattedBeforeTipAmount = formatCurrency(
    beforeTipAmount,
    invoice?.currency,
  );

  return (
    <LabeledBox label={tCommon("label.totalAmount")}>
      <div className={classes.amountRow}>
        <div className={classes.amount}>
          {formatCurrency(invoice?.totalAmount, invoice?.currency)}
        </div>
        {isTipsEnabled() && (
          <div className={classes.tip}>
            {`(${formattedBeforeTipAmount} + ${tCommon(
              "label.tip",
            )} ${formattedTipAmount})`}
          </div>
        )}
      </div>
    </LabeledBox>
  );
}
