/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InsightsSideBar from "../InsightsSideBar.js";
import InventoryTitle from "./InventoryTitle.js";
import InventoryReportTitle from "./InventoryReportTitle.js";
import InventoryReportOptions from "./InventoryReportOptions.js";
import Loader from "../../Common/Loader.js";
import {
  autoScrolling,
  getCurrencySymbol,
  numberFormat,
} from "../../../Utils/services.js";
import {
  resetReducerAction,
  getOptionAndList,
  saveReport,
  getReportVariables,
  getReportData,
  downloadReportData,
} from "../../../Actions/BusinessInsights/businessInsightActions.js";
import SaveReport from "../SaveReport.js";
import PreviousVsCurrentYear from "./PreviousVsCurrentYear.js";
import PreviousVsCurrentPeriod from "./PreviousVsCurrentPeriod.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class InventoryInsightReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      businessInsightLang: languageData.business_insights,
      showCreateOptions: false,
      type: "inventory",
      report_type_sysname: "",
      report_type_id: "",
      reportOptionAndList: {},
      reportOptions: props.reportOptions ? props.reportOptions : [],
      reportVariableData: [],
      reportData: {},
      allData: {},

      languageData: languageData.business_insights,
      page: 1,
      pagesize: 35,
      startFresh: true,
      next_page_url: "",
      showLoadingText: false,
      tableColumns: [],
      reportListData: [],
      totalRow: null,
      grammar: null,
      graphData: {
        product_name: "Unknown",
        month_name: "Unknown",
        this_year: 0,
        last_year: 0,
        percentage_change: 0,
      },

      reportType: this.props.match.params.reportType
        ? this.props.match.params.reportType
        : "create",
      reportID: this.props.match.params.reportID
        ? this.props.match.params.reportID
        : 0,
      isBreadcrumbs:
        this.props.match.params && this.props.match.params.reportID > 0
          ? false
          : true,
      insightID:
        this.props.match.params && this.props.match.params.insightID > 0
          ? this.props.match.params.insightID
          : 0,

      showTableSection: false,
      dataLoadedOnce: false,
      showNameSection: false,
      reportNameClass: "simpleInput",

      downloadData: {},

      tableBodyClass: "ajax_body",
    };
    window.onscroll = () => {
      if (
        this.state.selected_report_type_sysname &&
        this.state.selected_report_type_sysname === "product-usage-per-provider"
      ) {
        return false;
      }
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    autoScrolling(true);
    this.setState({ showLoader: true });
    let reportOptionFilter = {};
    if (
      this.state.reportType === "view" &&
      this.state.reportID &&
      this.state.insightID
    ) {
      this.setState({
        dataLoadedOnce: true,
        selected_report_type_id: this.state.insightID,
      });
      reportOptionFilter.page = this.state.page;
      reportOptionFilter.pagesize = this.state.pagesize;
      reportOptionFilter.business_insight_reports_id = this.state.reportID;
      this.props.getReportData(this.state.insightID, reportOptionFilter);
    } else {
      if (this.state.reportType === "edit" && this.state.reportID > 0) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
      }
      this.props.getOptionAndList(this.state.type, reportOptionFilter);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData &&
      nextProps.reportData.next_page_url !== prevState.next_page_url
    ) {
      returnState.reportData = nextProps.reportData;
      returnState.tableColumns = nextProps.reportData.columns
        ? nextProps.reportData.columns
        : prevState.tableColumns;
      if (prevState.next_page_url === null) {
        autoScrolling(false);
        return (returnState.next_page_url = null);
      }

      if (
        prevState.reportListData &&
        prevState.reportListData.length === 0 &&
        prevState.startFresh === true
      ) {
        returnState.insightReportData = nextProps.reportData;

        if (nextProps.reportData.next_page_url !== null) {
          returnState.page = prevState.page + 1;
        } else {
          returnState.next_page_url = nextProps.reportData.next_page_url;
        }
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.reportListData = nextProps.reportData.data
          ? nextProps.reportData.data
          : [];
        returnState.showTableSection = true;
        returnState.dataLoadedOnce = true;
        returnState.totalRow = nextProps.reportData.total;
        returnState.grammar = nextProps.reportData.grammar;
        returnState.tableBodyClass = "ajax_body";
      } else if (
        prevState.insightReportData !== nextProps.reportData &&
        prevState.insightReportData.length !== 0
      ) {
        returnState.reportListData = [
          ...prevState.reportListData,
          ...(nextProps.reportData.data || []),
        ];
        returnState.insightReportData = nextProps.reportData;
        returnState.next_page_url = nextProps.reportData.next_page_url;
        returnState.showLoader = false;
        returnState.page = prevState.page + 1;
        returnState.showLoadingText = false;
      }

      if (prevState.selected_report_type_id == 26) {
        returnState.next_page_url = null;
        returnState.graphData = {
          product_name: "Unknown",
          month_name: "Unknown",
          this_year: 0,
          last_year: 0,
          percentage_change: 0,
        };

        if (returnState.reportListData && returnState.reportListData[0]) {
          returnState.graphData.month_name = returnState.reportListData[0][
            "month_name"
          ]
            ? returnState.reportListData[0]["month_name"]
            : "Unknown";
          returnState.graphData.product_name = returnState.reportListData[0][
            "bi_product_name"
          ]
            ? returnState.reportListData[0]["bi_product_name"]
            : "Unknown";
          returnState.graphData.percentage_change = returnState
            .reportListData[0]["bi_percentage_change"]
            ? returnState.reportListData[0]["bi_percentage_change"]
            : 0;
          returnState.graphData.this_year =
            returnState.reportListData[0]["bi_product_name"] &&
            returnState.reportListData[0]["bi_this_year_report"][
              "quantity_sold"
            ]
              ? returnState.reportListData[0]["bi_this_year_report"][
                  "quantity_sold"
                ]
              : 0;
          returnState.graphData.last_year =
            returnState.reportListData[0]["bi_previous_year_report"] &&
            returnState.reportListData[0]["bi_previous_year_report"][
              "quantity_sold"
            ]
              ? returnState.reportListData[0]["bi_previous_year_report"][
                  "quantity_sold"
                ]
              : 0;
        }
      } else if (
        prevState.selected_report_type_id == 20 &&
        returnState.reportListData &&
        returnState.reportListData.length > 0
      ) {
        returnState.tableBodyClass = "ajax_body tr-bottom-bold";
      }
      autoScrolling(false);
    } else if (
      nextProps.reportOptionAndList !== undefined &&
      nextProps.reportOptionAndList !== prevState.reportOptionAndList
    ) {
      returnState.reportOptionAndList = nextProps.reportOptionAndList;
      returnState.reportOptions = nextProps.reportOptionAndList.reportOptions;
      returnState.showLoader = false;
      returnState.allData = nextProps.reportOptionAndList.allData
        ? nextProps.reportOptionAndList.allData
        : {};
      if (
        prevState.reportType !== "view" &&
        prevState.showCreateOptions === false &&
        prevState.dataLoadedOnce === false
      ) {
        returnState.showCreateOptions = true;
      }
    } else if (
      nextProps.reportOptions !== undefined &&
      nextProps.reportOptions !== prevState.reportOptions
    ) {
      returnState.reportOptions = nextProps.reportOptions;
      if (
        prevState.reportType !== "view" &&
        prevState.showCreateOptions === false &&
        prevState.dataLoadedOnce === false
      ) {
        returnState.showCreateOptions = true;
      }
    } else if (
      nextProps.reportVariableData &&
      nextProps.reportVariableData !== prevState.reportVariableData
    ) {
      returnState.reportVariableData = nextProps.reportVariableData;
      returnState.showLoader = false;
    } else if (
      nextProps.downloadData !== undefined &&
      nextProps.downloadData !== prevState.downloadData
    ) {
      returnState.showLoader = false;
      returnState.downloadData = nextProps.downloadData;
      nextProps.resetReducerAction();
      window.open(
        process.env.REACT_APP_API_URL +
          "download-data/" +
          nextProps.downloadData.file,
        "_blank",
      );
    } else if (
      nextProps.redirect !== undefined &&
      nextProps.redirect === true
    ) {
      nextProps.resetReducerAction();
      uiNotification.success(nextProps.message);
      nextProps.history.push(`/business-insight/${prevState.type}`);
    } else if (
      nextProps.showLoader !== undefined &&
      nextProps.showLoader === false
    ) {
      returnState.showLoader = false;
      nextProps.resetReducerAction();
    }

    return returnState;
  }

  componentDidUpdate = (_, prevState) => {
    if (
      this.state.reportSavedData !== null &&
      this.state.reportSavedData !== "" &&
      this.state.reportSavedData !== prevState.reportSavedData &&
      this.state.savedStatus !== null &&
      this.state.savedStatus !== ""
    ) {
      uiNotification.clear();
      uiNotification.success(this.state.globalLang[this.state.savedStatus]);

      return (
        <div>
          {this.props.history.push(`/business-insight/${this.state.type}`)}
        </div>
      );
    }
  };

  loadMore = () => {
    if (!autoScrolling()) {
      let reportOptionFilter = {};
      if (
        this.state.reportType === "view" &&
        this.state.reportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;
        this.setState({ showLoadingText: true, startFresh: false });
        this.props.getReportData(this.state.insightID, reportOptionFilter);
      } else {
        reportOptionFilter = this.state.reportFilter;
        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;
        if (reportOptionFilter) {
          this.setState({ showLoadingText: true, startFresh: false });
          autoScrolling(true);
          this.props.getReportData(
            reportOptionFilter.report_type_id,
            reportOptionFilter,
          );
        }
      }
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });
  };

  showCreateOptions = (reportType) => {
    if (reportType === "create") {
      return (
        <div>
          {this.props.history.push(
            `/business-insight/${this.state.type}/${reportType}`,
          )}
        </div>
      );
    } else {
      this.setState({ showCreateOptions: true });
    }
  };

  hideCreateOptions = (childSate) => {
    if (this.state.dataLoadedOnce === false) {
      return (
        <div>
          {this.props.history.push(`/business-insight/${this.state.type}`)}
        </div>
      );
    } else {
      this.setState(childSate);
    }
  };

  getData = (childSate) => {
    autoScrolling(true);
    this.setState(childSate);

    let formData = childSate.reportFilter;
    formData.page = 1;
    formData.pagesize = this.state.pagesize;

    if (childSate.report_type_sysname === "previous-period-vs-current-period") {
      const periodType = formData.current_period.type;
      const periodValue = formData.current_period.value;
      let current_period = "";
      let previous_period = "";

      switch (periodType) {
        case "month": {
          const currentMonth = moment().month(periodValue);
          const previousMonth = currentMonth.clone().subtract(1, "year");
          const cStart = currentMonth.startOf("month").format("yyyy-MM-DD");
          const cEnd = currentMonth.endOf("month").format("yyyy-MM-DD");
          const pStart = previousMonth.startOf("month").format("yyyy-MM-DD");
          const pEnd = previousMonth.endOf("month").format("yyyy-MM-DD");
          current_period = `${cStart}|${cEnd}`;
          previous_period = `${pStart}|${pEnd}`;
          break;
        }

        case "quarter": {
          const currentQuarter = moment().quarter(periodValue);
          const previousQuarter = moment()
            .quarter(periodValue)
            .subtract(1, "year");
          const cqStart = currentQuarter
            .startOf("quarter")
            .format("yyyy-MM-DD");
          const cqEnd = currentQuarter.endOf("quarter").format("yyyy-MM-DD");
          const pqStart = previousQuarter
            .startOf("quarter")
            .format("yyyy-MM-DD");
          const pqEnd = previousQuarter.endOf("quarter").format("yyyy-MM-DD");
          current_period = `${cqStart}|${cqEnd}`;
          previous_period = `${pqStart}|${pqEnd}`;
          break;
        }

        case "yoy": {
          const currentYear = moment().subtract(1, "year");
          const previousYear = moment().subtract(2, "year");
          const cyStart = currentYear.startOf("year").format("yyyy-MM-DD");
          const cyEnd = currentYear.endOf("year").format("yyyy-MM-DD");
          const pyStart = previousYear.startOf("year").format("yyyy-MM-DD");
          const pyEnd = previousYear.endOf("year").format("yyyy-MM-DD");
          current_period = `${cyStart}|${cyEnd}`;
          previous_period = `${pyStart}|${pyEnd}`;
          break;
        }

        default:
          break;
      }
      formData.current_period = current_period;
      formData.previous_period = previous_period;
    }

    this.setState({
      reportListData: [],
      showLoader: true,
      page: 1,
      startFresh: true,
      next_page_url: "",
      selected_report_type_id: childSate.report_type_id,
      selected_report_type_sysname: childSate.report_type_sysname,
      totalRow: null,
      grammar: null,
    });
    this.props.getReportData(childSate.report_type_id, formData);
  };

  getReportVariables = (childSate, isVariableData) => {
    this.setState(childSate);
    if (isVariableData) {
      this.props.getReportVariables(childSate.report_type_id);
    }
  };

  downloadData = () => {
    let reportOptionFilter = {};
    if (
      this.state.reportType === "view" &&
      this.state.reportID &&
      this.state.insightID
    ) {
      reportOptionFilter.business_insight_reports_id = this.state.reportID;
      reportOptionFilter.is_download = 1;
      this.setState({ showLoader: true });
      this.props.downloadReportData(this.state.insightID, reportOptionFilter);
    } else {
      reportOptionFilter = this.state.reportFilter;
      reportOptionFilter.is_download = 1;
      this.setState({ showLoader: true });
      this.props.downloadReportData(
        reportOptionFilter.report_type_id,
        reportOptionFilter,
      );
    }
  };

  getReportName = () => {
    this.setState({ showNameSection: true });
  };

  hideNameSection = (childState) => {
    this.setState(childState);
  };

  saveThisReport = () => {
    let reportName = this.state.reportName;
    if (this.state.grammar === null || this.state.grammar === undefined) {
      uiNotification.clear();
      uiNotification.error("Grammar is missing");
      return false;
    }
    if (reportName && reportName.trim() !== "") {
      this.setState({
        showLoader: true,
        reportNameClass: "simpleInput",
        reportName: "",
        showNameSection: false,
      });

      let reportFilter = this.state.reportFilter;
      reportFilter.business_insight_id = this.state.reportFilter.report_type_id;
      reportFilter.name = reportName;
      if (this.state.grammar) {
        reportFilter.grammar = this.state.grammar;
      }
      if (
        this.state.reportID &&
        this.state.reportID > 0 &&
        this.state.reportType === "edit"
      ) {
        reportFilter.business_insight_report_id = this.state.reportID;
      }
      this.props.saveReport(this.state.type, reportFilter);
    } else {
      this.setState({ reportNameClass: "simpleInput field-error" });
      return;
    }
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  goToClientProfile = (clientId) => {
    if (clientId) {
      return window.open(`/clients/profile/${clientId}`, "_blank");
    }
  };

  renderGeneralReport() {
    return (
      <div className="table-responsive clients-table no-border">
        <table className="table-updated setting-table table-min-width no-td-border no-hover">
          <thead className="table-updated-thead">
            <tr>
              {this.state.tableColumns &&
                this.state.tableColumns.length > 0 &&
                this.state.tableColumns.map((tobj, tidx) => {
                  return (
                    <th key={tidx} className="table-updated-th">
                      {this.state.languageData[tobj]}
                    </th>
                  );
                })}
              {this.state.selected_report_type_id == 25 && (
                <th key={"profile"} className="table-updated-th">
                  &nbsp;
                </th>
              )}
            </tr>
          </thead>
          <tbody className={this.state.tableBodyClass}>
            {this.state.reportListData &&
              this.state.reportListData.length > 0 &&
              this.state.reportListData.map((robj, ridx) => {
                return (
                  <tr key={ridx} className="table-updated-tr">
                    <>
                      {this.state.tableColumns &&
                        this.state.tableColumns.length > 0 &&
                        this.state.tableColumns.map((tobj, tidx) => {
                          return (
                            <td
                              key={ridx + `_` + tidx}
                              className="table-updated-td"
                            >
                              {["bi_amount_sold"].indexOf(tobj) > -1
                                ? getCurrencySymbol()
                                : null}
                              {[
                                "bi_avg_units_per_appt",
                                "bi_stock_as_per_forecast",
                              ].indexOf(tobj) > -1
                                ? numberFormat(robj[tobj], "decimal", 2, 2)
                                : robj[tobj]}
                            </td>
                          );
                        })}
                      {this.state.selected_report_type_id == 25 && (
                        <td key={"profile"} className="table-updated-td">
                          <a
                            className="easy-link no-padding"
                            onClick={() =>
                              this.goToClientProfile(robj.bi_patient_id)
                            }
                          >
                            {this.state.businessInsightLang.bi_view_profile}
                          </a>
                        </td>
                      )}
                    </>
                  </tr>
                );
              })}
            {this.state.reportListData &&
              this.state.reportListData.length <= 0 &&
              this.state.showLoader === false &&
              this.state.startFresh === false && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan={10}
                  >
                    {this.state.businessInsightLang.bi_no_record_found}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    );
  }

  renderReport() {
    switch (Number.parseInt(this.state.selected_report_type_id)) {
      case 26:
        return <PreviousVsCurrentYear graphData={this.state.graphData} />;
      case 48:
        return <PreviousVsCurrentPeriod data={this.state.reportListData} />;
      default:
        return this.renderGeneralReport();
    }
  }

  render() {
    return (
      <div id="content">
        <SaveReport
          showNameSection={this.state.showNameSection}
          hideNameSection={this.hideNameSection}
          reportNameClass={this.state.reportNameClass}
          saveThisReport={this.saveThisReport}
          reportName={this.state.reportName}
          handleChildState={this.handleChildState}
          reportMode={this.state.reportType}
          editReportID={this.state.reportID}
          propReportType={this.state.reportType}
        />

        <InventoryReportOptions
          showCreateOptions={this.state.showCreateOptions}
          getData={this.getData}
          hideCreateOptions={this.hideCreateOptions}
          getReportVariables={this.getReportVariables}
          reportOptions={this.state.reportOptions}
          reportVariableData={this.state.reportVariableData}
          type={this.state.type}
          reportID={this.state.reportID}
          allData={this.state.allData}
          businessInsightLang={this.state.businessInsightLang}
          globalLang={this.state.globalLang}
          history={this.props.history}
        />

        <div className="container-fluid content setting-wrapper">
          <InsightsSideBar />

          <div className="memberWalletOuter business-section">
            <InventoryTitle
              history={this.props.history}
              type={this.state.type}
              isCreateButton={this.state.reportType === "view" ? true : false}
              isBreadcrumbs={this.state.isBreadcrumbs}
              showCreateOptions={this.showCreateOptions}
              businessInsightLang={this.state.businessInsightLang}
              globalLang={this.state.globalLang}
            />

            <div
              className={
                this.state.dataLoadedOnce
                  ? "setting-setion m-b-10"
                  : "setting-setion m-b-10 no-display"
              }
            >
              <InventoryReportTitle
                downloadData={this.downloadData}
                showCreateOptions={this.showCreateOptions}
                saveReport={this.getReportName}
                parentState={this.state}
              />
              {this.renderReport()}
              <div
                className={
                  this.state.showLoadingText
                    ? "loading-please-wait no-margin-top"
                    : "loading-please-wait no-margin-top no-display"
                }
              >
                {this.state.globalLang.loading_please_wait_text}
              </div>
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (state.BusinessInsightReducer.action === "GET_INVENTORY_REPORT") {
    if (state.BusinessInsightReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportOptionAndList = {
        savedReports: state.BusinessInsightReducer.inventorySavedReports,
        reportOptions: state.BusinessInsightReducer.inventoryReportOptions,
        allData: state.BusinessInsightReducer.data.data,
      };
    }
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_VARIABLES") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      returnState.showLoader = false;
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      returnState.reportVariableData = state.BusinessInsightReducer.data.data;
    }
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportData = state.BusinessInsightReducer.data.data;
    }
  }

  if (state.BusinessInsightReducer.action === "GET_INVENTORY_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportData = state.BusinessInsightReducer.data.data;
    }
  }

  if (
    state.BusinessInsightReducer.action === "UPDATE_INVENTORY_SAVED_REPORTS"
  ) {
    if (state.BusinessInsightReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      returnState.savedReports =
        state.BusinessInsightReducer.inventorySavedReports;
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.BusinessInsightReducer.data.message];
    }
  }

  if (state.BusinessInsightReducer.action === "DOWNLOAD_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.downloadData = state.BusinessInsightReducer.data.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetReducerAction: resetReducerAction,
      getOptionAndList: getOptionAndList,
      saveReport: saveReport,
      getReportVariables: getReportVariables,
      getReportData: getReportData,
      downloadReportData: downloadReportData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InventoryInsightReport));
