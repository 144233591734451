import { useCallback, useEffect } from "react";
// eslint-disable-next-line import/no-restricted-paths
import { useGetUserProfileDetailsQuery } from "../_legacy/Queries";
import { setLocalStorageItem } from "../Utils";
import { getUser } from "../utilities/localStorage";
import { unwrapOr, packedMapOr } from "../utilities/general";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";

/**
 *
 * @deprecated Use `useCurrentUser` hook to retrieve privileges
 * Use `usePermission` hook to get permitted action
 */
export const useCheckUpdateUserPrivilege = () => {
  const userData = getUser();
  const { data, dataUpdatedAt, isSuccess } = useGetUserProfileDetailsQuery(
    userData && userData.user.id,
  );

  useEffect(() => {
    if (dataUpdatedAt && isSuccess) {
      /**
       * Since class based components are using checkIfPermissionAllowed util
       * and it depeneds on permissions from localStorage,
       * so this side effect will update privileges in localStorage
       */
      const privileges = unwrapOr(() => data.data.data.privileges, []);
      const nextUserData = packedMapOr(userData, null);

      if (nextUserData) {
        setLocalStorageItem(LOCAL_STORAGE_KEYS.userData, {
          ...nextUserData,
          permissions: privileges,
        });
      }
    }
  }, [dataUpdatedAt]);

  const checkIfPermissionAllowed = useCallback(
    (privilegeToCheck) => {
      if (isSuccess && data.data.data) {
        const { privileges } = data.data.data;

        return privileges.indexOf(privilegeToCheck) > -1;
      }
      return false;
    },
    [dataUpdatedAt],
  );

  return { checkIfPermissionAllowed };
};
