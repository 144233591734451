import React from "react";
import PropTypes from "prop-types";
import { svg } from "../../../../../../../assets/svg";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import classes from "./DeleteBtn.module.scss";

export function DeleteBtn({ onClick, isLoading, isDisabled }) {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled || isLoading}
      className={classes.root}
    >
      {isLoading ? (
        <CircularProgress size="tiny" color="red" />
      ) : (
        <img src={svg.trashRed} alt="" />
      )}
    </button>
  );
}

DeleteBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

DeleteBtn.defaultProps = {
  isLoading: false,
  isDisabled: false,
};
