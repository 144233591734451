import React, {Component} from 'react';
import { getCurrencySymbol,showFormattedDate,numberFormat } from '../../../Utils/services';
import Orders from './EfaxOrders/components/Orders';

class ReviewMerge extends Component{
	constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
			selectedHeader : 1,
			clientLang : languageData.clients,
			globalLang : languageData.global,
			salesLang  : languageData.sales,
			monthArray : ['January','February','March','April','May','June','July','August','September','October','November','December'],
			profileDropDowns : props.profileDropDowns
		}
	}

	handleClickOnHeader = (number) =>{
		this.setState({ selectedHeader : number })
	}
	static getDerivedStateFromProps(nextProps, prevState){
		let returnState = {};
		let procedureData = [];
		let productsData = [];
		let membershipData = [];
		let treatmentPlan = [];
		// ****STEP1 PROFILE INFO DATA****************
		if(nextProps.defaultProfileData != undefined){
			returnState.defaultProfileData = nextProps.defaultProfileData;

		}
		// *****STEP2 PROCEDURE DATA******************
		if(nextProps.procedureData != undefined){
			nextProps.procedureData.map((data,index) => {
				if(data.selected_status == true){
					procedureData.push(data);
				}
			})
			returnState.procedureData = procedureData;
		}
		// *****STEP3 PRODUCTS DATA*******************
		if(nextProps.productsData != undefined){
			nextProps.productsData.map((data,index) => {
				if(data.selected_status == true){
					productsData.push(data);
				}
			})
			returnState.productsData = productsData;
		}
		// *****STEP3 WALLET DATA*********************
		if(nextProps.walletData != undefined){
			returnState.walletData = nextProps.walletData;
		}
		// ****STEP4 MEMBERSHIP DATA******************
		if(nextProps.membershipData != undefined){
			nextProps.membershipData.map((data,index) => {
				if(data.selected == true){
					membershipData.push(data)
				}
			})
			returnState.membershipData = membershipData;
		}
		// ****STEP 4 TREATMENT PLANS******************
		if(nextProps.treatmentPlans != undefined){
			nextProps.treatmentPlans.map((data,index) => {
				if(data.selected == true){
					treatmentPlan.push(data);
				}
			})
			returnState.treatmentPlan = treatmentPlan;
		}
		return returnState;
	}
    render(){
		let user_image = this.state.defaultProfileData.user_image_url ? this.state.defaultProfileData.user_image_url : '/images/appfemale.png';
		
        return(
        <div>
        <div className="merge-title full-width">
        <div className="row backgroundColorGray">
		<div className="col-md-4 col-xs-12">
			<div className="setting-setion full-width mergeLeftSection"> 
				<div className="infoSectionTitle">{this.state.clientLang.merge_profile_information}</div>
				<div className="infoContent">
					<div className="preview-profile-pic">
						<div className="preview-picture">
							<img src={user_image} className="mergeProfileImg"/>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_first_name}</label>
							<span>{this.state.defaultProfileData.firstname}</span>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_last_name}</label>
							<span>{this.state.defaultProfileData.lastname}</span>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_gender}</label>
							<span>{this.state.defaultProfileData.gender == '0' ? 'Male' : this.state.defaultProfileData.gender == '1' ? 'Female' : 'Not Disclosed'}</span>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_date_of_birth}</label>
							<span>{this.state.defaultProfileData.date_of_birth ? showFormattedDate(this.state.defaultProfileData.date_of_birth, false) : this.state.profileDropDowns.dob && this.state.profileDropDowns.dob.length > 0 ? showFormattedDate(this.state.profileDropDowns.dob[0],false) : ''}</span>
						</div>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_email}</label>
						<span>{this.state.defaultProfileData.email && !this.state.defaultProfileData.selected_emailObject && <p>{this.state.defaultProfileData.email} <span><i className='fa fa-check m-l-5 greenTick'></i></span></p>}
						</span>
						<span>{!this.state.defaultProfileData.email && !this.state.defaultProfileData.selected_emailObject && this.state.profileDropDowns.email && this.state.profileDropDowns.email.length > 0 && <p>{this.state.profileDropDowns.email[0].value} <span><i className='fa fa-check m-l-5 greenTick'></i></span></p>}
						</span>
						<span>{this.state.defaultProfileData.selected_emailObject && this.state.defaultProfileData.selected_emailObject.map((data,index) =>{
							return(
								<p key={index}>{data.label}  {data.label == this.state.defaultProfileData.default_email && <span><i className='fa fa-check m-l-5 greenTick'></i></span>}</p>
							)
						})}
						</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_phone}</label>
					<span>{this.state.defaultProfileData.phoneNumber && !this.state.defaultProfileData.selected_phonesObject && <p>{this.state.defaultProfileData.phoneNumber} <span><i className='fa fa-check m-l-5 greenTick'></i></span></p>}</span>
					<span>{!this.state.defaultProfileData.phoneNumber && !this.state.defaultProfileData.selected_phonesObject && this.state.profileDropDowns.phone && this.state.profileDropDowns.phone.length > 0 && <p>{this.state.profileDropDowns.phone[0].value} <span><i className='fa fa-check m-l-5 greenTick'></i></span></p>}
						</span>
					<span>{this.state.defaultProfileData.selected_phonesObject && this.state.defaultProfileData.selected_phonesObject.map((data,index) =>{
						return(
							<p key={index}>{data.label} {data.label == this.state.defaultProfileData.default_phone && <span><i className='fa fa-check m-l-5 greenTick'></i></span>}</p>
						)
					})}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.clientprofile_address}</label>
						<span>{this.state.defaultProfileData.address_line_1 ? this.state.defaultProfileData.address_line_1 : this.state.profileDropDowns.address && this.state.profileDropDowns.address.length > 0 ? this.state.profileDropDowns.address[0] : ''} {this.state.defaultProfileData.address_line_1 && this.state.defaultProfileData.address_line_2 && <span>,</span>} {this.state.defaultProfileData.address_line_2 ? this.state.defaultProfileData.address_line_2 : this.state.defaultProfileData.addressline2 && this.state.defaultProfileData.addressline2.length > 0 ? this.state.defaultProfileData.addressline2[0] : ''}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_city}</label>
						<span>{this.state.defaultProfileData.city ? this.state.defaultProfileData.city : this.state.profileDropDowns.city && this.state.profileDropDowns.city.length > 0 ? this.state.profileDropDowns.city[0] : ''}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_state}</label>
						<span>{this.state.defaultProfileData.state ? this.state.defaultProfileData.state : this.state.profileDropDowns.state && this.state.profileDropDowns.state.length > 0 ? this.state.profileDropDowns.state[0] : ''}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_country}</label>
						<span>{this.state.defaultProfileData.country_name ? this.state.defaultProfileData.country_name : this.state.profileDropDowns.country && this.state.profileDropDowns.country.length > 0 ? this.state.profileDropDowns.country[0].full_name : ''} </span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_zip_code}</label>
						<span>{this.state.defaultProfileData.pincode ? this.state.defaultProfileData.pincode : this.state.profileDropDowns.pincode && this.state.profileDropDowns.pincode.length > 0 ? this.state.profileDropDowns.pincode[0] : ''}</span>
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-8 col-xs-12 mergeRightSection">
			<div className="setting-setion full-width">
				<ul className="reviewTabs">
					<li className={this.state.selectedHeader == 1 ? 'active' : ''} onClick={() => this.handleClickOnHeader(1)}><a>{this.state.clientLang.merge_procedures}</a></li>
					<li className={this.state.selectedHeader == 2 ? 'active' : ''} onClick={() => this.handleClickOnHeader(2)}><a>{this.state.globalLang.inv_wallet}</a></li>
					<li className={this.state.selectedHeader == 3 ? 'active' : ''} onClick={() => this.handleClickOnHeader(3)}><a>{this.state.salesLang.sales_membership}</a></li>
					<li className={this.state.selectedHeader == 4 ? 'active' : ''} onClick={() => this.handleClickOnHeader(4)}><a>{this.state.salesLang.sales_treatment_plans}</a></li>
					{Boolean(this.props.selectedOrders.length) && <li className={this.state.selectedHeader == 5 ? 'active' : ''} onClick={() => this.handleClickOnHeader(5)}><a>Orders</a></li>}
				</ul>

				{/* ***************PROCEDURES********************* */}
				{this.state.selectedHeader == 1 && 
				<div>
					{this.state.procedureData && this.state.procedureData.map((data,index) => {
						return(
					<div className="procedure-row review-merge-pics" key={index}>
					<div className="procedureName">{data.name}</div>
					<div className="procedureInformation">
						<div className="procedureThreeImages">
						<img src={data.patient_image_right_45_thumb_url ? data.patient_image_right_45_thumb_url : '/images/no-image-vertical.png'}/>
							<img src={data.procedure_image_45_thumb_url ? data.procedure_image_45_thumb_url : '/images/no-image-vertical.png'}/>
							<img src={data.patient_image_left_45_thumb_url ? data.patient_image_left_45_thumb_url : '/images/no-image-vertical.png'}/>
						</div>
						<div className="procedureDetails">
							<div className="info-row">
								<label>{this.state.clientLang.client_name}</label>
								<span>{data.name}</span>
							</div>
							<div className="info-row">
								<label>{this.state.salesLang.sales_date_time}</label>
								<span>{showFormattedDate(data.date, true)}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.client_provider_name}</label>
								<span>{data.provider_name}</span>
							</div>
						</div>
					</div>
				</div>
						)
					})}
				{this.state.procedureData.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float p-b-15" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
					
				</div>}

				{/* **************WALLET***************** */}
				{this.state.selectedHeader == 2 && <div>
					<div className="infoContent review-wallet">
					
					<div className="info-row">
						<label>{this.state.clientLang.merge_dollar_credit}</label>
						<span>{numberFormat(this.state.walletData.dollarCredit,'currency',2)}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.merge_bd_credit}</label>
						<span>{numberFormat(this.state.walletData.bdCredit,'currency',2)}</span>
					</div>
					<div className="info-row">
						<label>{this.state.clientLang.merge_xperience_credit}</label>
						<span>{numberFormat(this.state.walletData.xperienceCredit,'currency',2)}</span>
					</div>
					<div className="info-row">
						<label>{this.state.clientLang.merge_aspire_credit}</label>
						<span>{numberFormat(this.state.walletData.aspireCredit,'currency',2)}</span>
					</div>
					
					{/* <div className="importLog m-b-15">
						<label className="setting-switch m-r-5"><input name="sync" value={this.state.walletData.collectiveData} checked={this.state.walletData.collectiveData ? 'checked' : false} id="sync" type="checkbox" /><span className="setting-slider"></span></label>
						<span className="radioLabel">{this.state.clientLang.merge_import_collective_log}</span>
					</div> */}
					
					<div className="info-row">
						<label>{this.state.clientLang.merge_products_packages}</label>
						<span>
							{this.state.productsData && this.state.productsData.map((data,index) => {
								return(
									<p>{data.product_name} ({data.balance_units + ' units'})</p>
								)
							})}
						</span>
					</div>
					
				</div>
					</div>}
				
				{/* *************MEMBERSHIPS*************** */}
				{this.state.selectedHeader == 3 && <div>
					{this.state.membershipData && this.state.membershipData.length > 0 && this.state.membershipData.map((data,index) => {
						return(
                    <div className="procedure-row review-merge-pics" key={index}>
					<div className="procedureName">{data.tier_name ? data.tier_name : data.recurly_program_name}</div>
					<div className="procedureInformation">
						<div className="procedureDetails p-l-0">	
							{data.payment_frequency=='monthly' && <div className="info-row">
								<label>{this.state.clientLang.label_price_per_month}</label>
								<span>{numberFormat(data.mothly_membership_fees,'currency',2)}</span>
							</div>}
							{data.payment_frequency == 'yearly' && <div className="info-row">
								<label>{'Price Per Year'}</label>
								<span>{numberFormat(data.yearly_membership_fee,'currency',2)}</span>
							</div>}
							<div className="info-row">
								<label>{this.state.globalLang.one_time_setup_fees}</label>
								<span>{numberFormat(data.one_time_membership_setup,'currency',2)} </span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_start_date}</label>
								<span>{showFormattedDate(data.subscription_started_at, true)}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_client_card}</label>
								<span>{data.card_details}</span>
							</div>
						</div>
					</div>
				</div>
						)
					})}
				{this.state.membershipData.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float p-b-15" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
					
					
					</div>}

				{/* ***************TREATMENT PLANS****************** */}
				{this.state.selectedHeader == 4 && <div>
					{this.state.treatmentPlan && this.state.treatmentPlan.map((data,index) => {
						return(
							<div className="procedure-row review-merge-pics">
					<div className="procedureName">{data.plan_type =='payg' ? 'Pay as you go' : 'Monthly'} Treatment Plan</div>
					<div className="procedureInformation">
						<div className="procedureDetails p-l-0 review-treatmentplan">
							{data.plan_type=='monthly' && <div className="info-row">
								<label>{this.state.clientLang.merge_skin_care_goal}</label>
								<span>{data.skincare_goal}</span>
							</div>}
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_provider}</label>
								<span>{data.provider}</span>
							</div>
							<div className="info-row">
								<label>{this.state.salesLang.frontdesk_products}</label>
								<span>{data.plan_type == 'monthly' && data.patient_treatment_plan_schedule.map((data1,i) =>{
								return(
								<p key={i}>{data1.product ? data1.product.product_name : 'N/A'} ({data1.units} units in {this.state.monthArray[data1.month - 1]} )</p>)

							})}
							
							{data.plan_type == 'payg' && data.patient_treatment_plan_schedule.map((data1,i) =>{
								return(
								<p key={i}>{data1.product_name} ({data1.units} units every {data1.repeat_val} {data1.repeat_by})</p>)

							})}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_total}</label>
								<span>{getCurrencySymbol()}{data.total_amount}</span>
							</div>
						</div>
					</div>
				</div>
						)
					})}
					{this.state.treatmentPlan.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float p-b-15" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
					</div>}
					{this.state.selectedHeader == 5 && 
						this.props.selectedOrders.map(fax => <Orders key={fax.id} fax={fax} />)}

			</div>
		</div>
		
	</div>
        </div>
        </div>)
    }}

export default ReviewMerge;