import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { settingRecordSchema } from "./schemas";

const responseSchema = yup.array().of(settingRecordSchema);

const composeResponse = (res) => {
  return res.data?.data || [];
};

function createPatientVitalsSettingViewsQueryKey() {
  return [QUERY_KEYS.patientVitals.settingViews];
}

export function usePatientVitalsSettingViewsQuery({ options = {} } = {}) {
  return useQuery(
    createPatientVitalsSettingViewsQueryKey(),
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.patientVitals.getSettingViews(),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
