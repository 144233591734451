import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { useClientCreditCardsQuery } from "../../../../../api/queries/useClientCreditCardsQuery";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import {
  CLIENT_GLOBAL_NAME,
  USER_PAYMENT_SYSTEMS,
} from "../../../../../consts/api";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export function useCreditCards(clientId, cardList) {
  const { tCommon } = useAppTranslation.Common();
  const accountQuery = useCurrentAccountQuery();

  const clientQuery = useClientQuery(
    {
      clientId: Number(clientId),
    },
    {
      enabled: accountQuery.isFetched,
      onError: () => {
        const msg =
          accountQuery?.data?.preferences?.clientGlobalName ===
          CLIENT_GLOBAL_NAME.client
            ? tCommon("error.fetchClient")
            : tCommon("error.fetchPatient");

        uiNotification.error(msg);
      },
    },
  );

  const cardsQuery = useClientCreditCardsQuery(
    {
      clientId: Number(clientId),
    },
    {
      enabled: accountQuery.isFetched,
      onError: () => {
        const msg =
          accountQuery?.data?.preferences?.clientGlobalName ===
          CLIENT_GLOBAL_NAME.client
            ? tCommon("error.fetchClientCreditCards")
            : tCommon("error.fetchPatientCreditCards");

        uiNotification.error(msg);
      },
    },
  );

  const getList = () => {
    if (accountQuery.data?.paymentSystem === USER_PAYMENT_SYSTEMS.stripe) {
      const list = cardList?.map((card) => {
        const last4 = `ending with ${card.card.last4}`;
        return {
          id: card.id,
          cardNumber:
            card.type === "card"
              ? `${card.card.brand} ${last4}`
              : `${card.card.brand} - ${card.card.email}`,
        };
      });
      return list || [];
    }
    return cardsQuery.data?.creditCards || [];
  };

  return {
    isLoading:
      accountQuery.isLoading || clientQuery.isLoading || cardsQuery.isLoading,
    list: getList(),
    canAdd: clientQuery.data?.canAddCreditCard || false,
    paymentSystem: accountQuery.data?.paymentSystem || null,
    clearent: {
      publicKey: cardsQuery.data?.clinicClearentPublicKey || null,
      billingZip: clientQuery.data?.pinCode || null,
      billingEmail: clientQuery.data?.emails?.[0] || null,
    },
    isPosEnabled: accountQuery.data?.isPosEnabled || false,
    refetch: cardsQuery.refetch,
  };
}
