import React from "react";
import { tCommon } from "../../../../i18n/useAppTranslation";

export default function EmaiSmsConfigration({
  declined_payment_template_status,
  handleChangeEmailAndSms,
}) {
  return (
    <div>
      <span className="factor_status m-r-10">
        {tCommon("label.configureDeclineEmailSMS")}
      </span>
      <label className="setting-switch pull-right">
        <input
          type="checkbox"
          name="declined_payment_template_status"
          className="setting-custom-switch-input"
          checked={declined_payment_template_status}
          value={declined_payment_template_status}
          onChange={handleChangeEmailAndSms}
        />
        <span className="setting-slider" />
      </label>
    </div>
  );
}
