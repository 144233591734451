import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { WEEK_DAYS } from "../../../../consts/general";
import {
  FREQUENCY_REPEAT_MEASURE_TYPES,
  FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES,
} from "../../Event.consts";
import { EntityRow } from "../EntityRow";
import classes from "../../sass/Event.module.scss";
import { Chip } from "../../../../shared/Chip/Chip";
import { InputSecondary } from "../../../../shared/InputSecondary/InputSecondary";
import { SelectSecondary } from "../../../../shared/SelectSecondary/SelectSecondary";

const OutOfOfficeRepeatEvery = ({
  eventDate,
  frequencyRepeatCount,
  onFrequencyRepeatCountChange,
  isFrequencyRepeatCountError,
  frequencyRepeatMeasure,
  onFrequencyRepeatMeasureChange,
  frequencyRepeatMonthlyValue,
  onFrequencyRepeatMonthlyValueChange,
  frequencyRepeatOnWeekDays,
  onFrequencyRepeatOnWeekDaysChange,
}) => {
  const handleChangeFrequencyRepeatOnWeekDays = (nextDay) => {
    if (frequencyRepeatOnWeekDays.includes(nextDay)) {
      onFrequencyRepeatOnWeekDaysChange(
        frequencyRepeatOnWeekDays.filter((d) => d !== nextDay),
      );
    } else {
      onFrequencyRepeatOnWeekDaysChange([
        ...frequencyRepeatOnWeekDays,
        nextDay,
      ]);
    }
  };

  const getMonthFrequencyOptions = (date) => {
    const dayNumber = moment.utc(date).format("D");
    const dayName = moment.utc(date).format("dddd");

    const getFrequencyRepeatMonthlyValue = (day, date) =>
      date ? day + " " + moment.utc(date).format("dddd") : "";

    return [
      {
        label: `Monthly on day ${dayNumber}`,
        value: FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES.eventDate,
      },
      {
        label: `Monthly on the ${getFrequencyRepeatMonthlyValue(
          "first",
          eventDate,
        )}`,
        value: getFrequencyRepeatMonthlyValue("first", eventDate),
      },
      {
        label: `Monthly on the ${getFrequencyRepeatMonthlyValue(
          "second",
          eventDate,
        )}`,
        value: getFrequencyRepeatMonthlyValue("second", eventDate),
      },
      {
        label: `Monthly on the ${getFrequencyRepeatMonthlyValue(
          "third",
          eventDate,
        )}`,
        value: getFrequencyRepeatMonthlyValue("third", eventDate),
      },
      {
        label: `Monthly on the ${getFrequencyRepeatMonthlyValue(
          "fourth",
          eventDate,
        )}`,
        value: getFrequencyRepeatMonthlyValue("fourth", eventDate),
      },
      {
        label: `Monthly on the last ${dayName}`,
        value: `last ${dayName}`,
      },
    ];
  };

  return (
    <div className={classes.repeatEvery}>
      <EntityRow label="Repeat every" contentClassName={classes.repeatEveryRow}>
        <InputSecondary
          isError={isFrequencyRepeatCountError}
          name="repeatEveryNumber"
          type="number"
          min={1}
          max={100}
          value={frequencyRepeatCount}
          onChange={(e) => onFrequencyRepeatCountChange(e.target.value)}
        />
        <SelectSecondary
          name="repeatEveryDuration"
          onChange={(e) => onFrequencyRepeatMeasureChange(e.target.value)}
          value={frequencyRepeatMeasure}
          options={[
            {
              label: "day",
              value: FREQUENCY_REPEAT_MEASURE_TYPES.day,
            },
            {
              label: "week",
              value: FREQUENCY_REPEAT_MEASURE_TYPES.week,
            },
            {
              label: "month",
              value: FREQUENCY_REPEAT_MEASURE_TYPES.month,
            },
            {
              label: "year",
              value: FREQUENCY_REPEAT_MEASURE_TYPES.year,
            },
          ]}
        />
        {frequencyRepeatMeasure === FREQUENCY_REPEAT_MEASURE_TYPES.month && (
          <SelectSecondary
            name="repeat_type"
            onChange={(e) =>
              onFrequencyRepeatMonthlyValueChange(e.target.value)
            }
            value={frequencyRepeatMonthlyValue}
            options={getMonthFrequencyOptions(eventDate)}
          />
        )}
      </EntityRow>
      {frequencyRepeatMeasure === FREQUENCY_REPEAT_MEASURE_TYPES.week && (
        <EntityRow
          label="Repeat on"
          contentClassName={classes.repeatEveryWeekWrap}
        >
          {WEEK_DAYS.map((name, idx) => (
            <Chip
              key={idx}
              isSelected={frequencyRepeatOnWeekDays.indexOf(name) > -1}
              onSelect={() => handleChangeFrequencyRepeatOnWeekDays(name)}
            >
              {name[0]}
            </Chip>
          ))}
        </EntityRow>
      )}
    </div>
  );
};

OutOfOfficeRepeatEvery.propTypes = {
  eventDate: PropTypes.string.isRequired,
  frequencyRepeatCount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onFrequencyRepeatCountChange: PropTypes.func.isRequired,
  isFrequencyRepeatCountError: PropTypes.bool.isRequired,
  frequencyRepeatMeasure: PropTypes.string.isRequired,
  onFrequencyRepeatMeasureChange: PropTypes.func.isRequired,
  frequencyRepeatMonthlyValue: PropTypes.string.isRequired,
  onFrequencyRepeatMonthlyValueChange: PropTypes.func.isRequired,
  frequencyRepeatOnWeekDays: PropTypes.array.isRequired,
  onFrequencyRepeatOnWeekDaysChange: PropTypes.func.isRequired,
};

export default OutOfOfficeRepeatEvery;
