/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import Autocomplete from "react-google-autocomplete";
import { isPositiveNumber } from "../../Utils/services.js";
import Loader from "./Loader";
import { shortenClinicName } from "../../helpers/general";

class ClinicsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.clinicsList != undefined &&
      nextProps.clinicsList != prevState.clinicsList &&
      nextProps.clinicListDataTime != prevState.clinicListDataTime
    ) {
      returnState.clinicsList = nextProps.clinicsList;
      returnState.clinicListDataTime = nextProps.clinicListDataTime;
      nextProps.clinicsList &&
        nextProps.clinicsList.length > 0 &&
        nextProps.clinicsList.map((data) => {
          returnState["city-" + data.id] = data.clinic_city;
          returnState["state-" + data.id] = data.clinic_state;
          returnState["zipcode-" + data.id] = data.clinic_zipcode;
          returnState["geoLocationClass-" + data.id] = false;
          returnState["state-error-" + data.id] = false;
          returnState["zipcode-error-" + data.id] = false;
          returnState["city-error-" + data.id] = false;
        });
    }
    return returnState;
  }

  componentDidMount() {
    this.state.clinicsList &&
      this.state.clinicsList.length > 0 &&
      this.state.clinicsList.map((data) => {
        let id = "new-input-" + data.id;
        document.getElementById(id).value = data.clinic_zipcode;
      });
  }

  selectLocation = (description, id) => {
    let city;
    let state;
    let zipcode;
    let returnState = {};

    if (description && description.structured_formatting) {
      let checkValue = Number(description.structured_formatting.main_text);
      if (isPositiveNumber(checkValue)) {
        zipcode = description.structured_formatting.main_text;
        returnState["zipcode-" + id] = zipcode;
        this.setState(returnState);
        let secondary_text = description.structured_formatting.secondary_text
          ? description.structured_formatting.secondary_text.split(",")
          : [];
        city = secondary_text[secondary_text.length - 3]
          ? secondary_text[secondary_text.length - 3]
          : secondary_text[secondary_text.length - 2]
          ? secondary_text[secondary_text.length - 2]
          : "";
        state = secondary_text[secondary_text.length - 2]
          ? secondary_text[secondary_text.length - 2]
          : "";
      } else {
        zipcode = "";
        returnState["zipcode-" + id] = "  ";
        this.setState(returnState);
        let secondary_text = description.structured_formatting.secondary_text
          ? description.structured_formatting.secondary_text.split(",")
          : [];
        city = description.structured_formatting.main_text;
        state = secondary_text[secondary_text.length - 2]
          ? secondary_text[secondary_text.length - 2]
          : "";
      }
      returnState["city-" + id] = city;
      returnState["state-" + id] = state;
      this.setState(returnState);
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let changedVal = {
      [event.target.name]: value,
      userChanged: true,
    };
    this.setState(changedVal);
  };

  handleSubmit = () => {
    let error = false;
    let returnState = {};
    this.state.clinicsList &&
      this.state.clinicsList.length > 0 &&
      this.state.clinicsList.map((data) => {
        if (
          this.state["city-" + data.id] == "" ||
          this.state["city-" + data.id] == undefined ||
          this.state["city-" + data.id] == null
        ) {
          returnState["city-error-" + data.id] = true;
          this.setState(returnState);
          error = true;
        } else {
          returnState["city-error-" + data.id] = false;
          this.setState(returnState);
        }
        if (
          this.state["state-" + data.id] == "" ||
          this.state["state-" + data.id] == undefined ||
          this.state["state-" + data.id] == null
        ) {
          returnState["state-error-" + data.id] = true;
          this.setState(returnState);
          error = true;
        } else {
          returnState["state-error-" + data.id] = false;
          this.setState(returnState);
        }

        if (
          this.state["zipcode-" + data.id] == "" ||
          this.state["zipcode-" + data.id] == undefined ||
          this.state["zipcode-" + data.id].trim() == "" ||
          this.state["zipcode-" + data.id] == null
        ) {
          returnState["zipcode-error-" + data.id] = true;
          this.setState(returnState);
          error = true;
        } else {
          returnState["zipcode-error-" + data.id] = false;
          this.setState(returnState);
        }
      });

    if (error) {
      return;
    }
    this.setState({ showLoader: true });
    let dataToSend = [];
    this.state.clinicsList &&
      this.state.clinicsList.length > 0 &&
      this.state.clinicsList.map((data) => {
        dataToSend.push({
          id: data.id,
          clinic_city: this.state["city-" + data.id],
          clinic_state: this.state["state-" + data.id],
          clinic_zipcode: this.state["zipcode-" + data.id],
          suite_number: "",
        });
      });
    let formData = {
      clinics_data: dataToSend,
    };
    this.props.updateClinicsData(formData);
  };

  logout = () => {
    this.props.logout();
  };

  selectLocation2 = (place, id) => {
    let zipcode = "";
    let city = "";
    let state = "";
    let returnState = {};
    let searchID = "new-input-" + id;
    document.getElementById(searchID).value = "";
    if (place.address_components) {
      place.address_components.map((data) => {
        if (data.types[0] == "postal_code") {
          zipcode = data.long_name;
          document.getElementById(searchID).value = zipcode;
        }
        if (data.types[0] == "locality" || data.types[0] == "postal_town") {
          city = data.long_name;
        }
        if (data.types[0] == "administrative_area_level_1") {
          state = data.short_name;
        }
      });
      if (zipcode == "") {
        place.address_components.map((data) => {
          if (data.types[0] == "postal_code_prefix") {
            zipcode = data.long_name;
            document.getElementById(searchID).value = zipcode;
          }
        });
      }
      if (city == "") {
        place.address_components.map((data) => {
          if (data.types[0] == "administrative_area_level_2") {
            city = data.long_name;
          }
        });
      }
      returnState["city-" + id] = city;
      returnState["state-" + id] = state;
      returnState["zipcode-" + id] = zipcode;
      this.setState(returnState);
    }
  };

  render() {
    return (
      <div className="confirmation-modal-wrapper">
        <div
          className={
            this.props.showClinicsModal ? "modalOverlay" : "no-display"
          }
        >
          <div className="small-popup-outer subscription-popup">
            <div className="small-popup-header">
              <div className="popup-name">Update Clinic Addresses</div>
              <a className="small-cross" onClick={this.logout}>
                ×
              </a>
            </div>

            <div className="small-popup-content">
              <div className="juvly-container">
                {this.state.clinicsList &&
                  this.state.clinicsList.length > 0 &&
                  this.state.clinicsList.map((data, index) => {
                    return (
                      <div key={index} className="monthly-estimate-block">
                        <div className="p-t-10">
                          <div className="accordian-section text-left">
                            <div className="featureNoteTitle fontSize14px">
                              {index + 1}. {shortenClinicName(data.clinic_name)}
                            </div>
                          </div>
                          <div className="accordian-section text-left">
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-lg-12 p-l-0 p-r-0 p-b-10 bottomBorder">
                                <div className="col-sm-12 col-xs-12 col-lg-4">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">
                                      {"Zipcode"}
                                      <span className="setting-require">*</span>
                                    </div>
                                    <div
                                      className={
                                        this.state[
                                          "geoLocationClass-" + data.id
                                        ] == false
                                          ? "googleLocationInput"
                                          : "googleLocationInput field_error"
                                      }
                                    >
                                      <Autocomplete
                                        id={"new-input-" + data.id}
                                        placeholder="Zipcode"
                                        className={
                                          this.state[
                                            "zipcode-error-" + data.id
                                          ] == true
                                            ? "newInputField field_error"
                                            : "newInputField"
                                        }
                                        onPlaceSelected={(place) =>
                                          this.selectLocation2(place, data.id)
                                        }
                                        types={["(regions)"]}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-12 col-xs-12 col-lg-4">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">
                                      City
                                      <span className="setting-require">*</span>
                                    </div>
                                    <input
                                      name={"city-" + data.id}
                                      readOnly="true"
                                      className={
                                        this.state["city-error-" + data.id] ===
                                        true
                                          ? "newInputField field_error"
                                          : "newInputField"
                                      }
                                      autoComplete="off"
                                      placeholder="City"
                                      maxLength="255"
                                      type="text"
                                      value={this.state["city-" + data.id]}
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-12 col-xs-12 col-lg-4">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">
                                      State
                                      <span className="setting-require">*</span>
                                    </div>
                                    <input
                                      name={"state-" + data.id}
                                      readOnly="true"
                                      className={
                                        this.state["state-error-" + data.id] ===
                                        true
                                          ? "newInputField field_error"
                                          : "newInputField"
                                      }
                                      autoComplete="off"
                                      placeholder="State"
                                      maxLength="255"
                                      type="text"
                                      value={this.state["state-" + data.id]}
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="footer-static">
              <a
                className="new-blue-btn pull-right"
                name="btn_create_schedule"
                onClick={() => this.handleSubmit()}
              >
                Continue
              </a>
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}

export default ClinicsModal;
