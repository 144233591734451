import React, { useState } from "react";
import cx from "clsx";
import { Box } from "../../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import classes from "./Table.module.scss";
import { ModalProducts } from "./shared/ModalProducts";

export function Table({ reports }) {
  const { tCommon } = useAppTranslation.Common();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;
  const [products, setProducts] = useState(null);

  const data = reports.value.map((r) => ({
    id: r.id,
    bi_order: r.bi_order || tCommon("symbol.longDash"),
    bi_client_name: r.bi_client_name || tCommon("symbol.longDash"),
    bi_email: r.bi_email || tCommon("symbol.longDash"),
    bi_phone: r.bi_phone || tCommon("symbol.longDash"),
    bi_total: r.bi_total || tCommon("symbol.longDash"),
    bi_payment_status: r.bi_payment_status || tCommon("symbol.longDash"),
    bi_fulfillment_status:
      r.bi_fulfillment_status || tCommon("symbol.longDash"),
    bi_items: r.bi_items || tCommon("symbol.longDash"),
    bi_date: r.bi_date || tCommon("symbol.longDash"),
    products: r.products || [],
  }));

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader
          isLoading={reports.isSoftLoading}
          className={classes.tableWrapper}
        >
          <SharedTable
            bgColor="white"
            keyExtractor={(row) => row.id}
            data={data}
            onRowClick={(row) => {
              setProducts(row.products);
            }}
            headColClassName={classes.headCol}
            cols={[
              {
                data: tCommon("label.order"),
                accessor: "bi_order",
                className: classes.col,
              },
              {
                data: tCommon("label.patientName"),
                accessor: "bi_client_name",
                className: classes.col,
              },
              {
                data: tCommon("label.email"),
                accessor: "bi_email",
                className: classes.emailCol,
              },
              {
                data: tCommon("label.phone"),
                accessor: "bi_phone",
                className: classes.col,
              },
              {
                data: tCommon("label.totalSales"),
                accessor: "bi_total",
                className: classes.col,
              },
              {
                data: tCommon("label.paymentStatus"),
                accessor: "bi_payment_status",
                className: classes.col,
              },
              {
                data: tCommon("label.fulfillmentStatus"),
                accessor: "bi_fulfillment_status",
                className: cx(classes.col, classes.fulfillmentStatus),
              },
              {
                data: tCommon("label.items"),
                accessor: "bi_items",
                className: classes.col,
              },
              {
                data: tCommon("label.date"),
                accessor: "bi_date",
                className: classes.col,
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
      {Boolean(products) && (
        <ModalProducts
          isOpen
          onClose={() => setProducts(null)}
          products={products}
        />
      )}
    </Box>
  );
}
