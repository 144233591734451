import * as yup from "yup";
import { graphNodeSchema } from "../../../api/patientVitals/schemas";

export const graphEntrySchema = yup.object({
  id: yup.string().required(),
  field: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  data: yup.array().of(graphNodeSchema).required(),
});
