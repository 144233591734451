import React, { useState } from "react";
import * as yup from "yup";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { Button } from "../../../../../../shared/Button/Button";
import { InputPhone } from "../../../../../../shared/InputPhone/InputPhone";
import { useExtendedFormik } from "../../../../../../hooks/useExtendedFormik";
import { phoneUtil } from "../../../../../../utilities/phone";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { useUpdateUserPhoneMutation } from "../../../../../../api/user/useUpdateUserPhoneMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";

export const UpdateUserPhone = ({ userId, phone, onSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState();

  const update = useUpdateUserPhoneMutation({
    onError: () => {
      uiNotification.error("Failed to update phone");
    },
    onSuccess: () => {
      onSuccess?.();
      uiNotification.success("Phone has been successfully updated");
    },
  });

  const {
    values,
    getError,
    changeField,
    handleSubmit,
    dirty,
    resetForm,
  } = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      phone: yup.string().required("Phone is required").test({
        test: (x) => phoneUtil.isPossible(phoneUtil.compose(x)),
        message: "Invalid phone number",
      }),
    }),
    initialValues: {
      phone,
    },
    onSubmit: (values, { resetForm }) => {
      update.mutateAsync({
        userId,
        phone: phoneUtil.compose(values.phone),
      }).then(() => {
        resetForm();
        setIsModalOpen(false);
      });
    },
  });

  return (
    <>
      <button
        className="easy-link"
        onClick={() => setIsModalOpen(true)}
      >
        Update Phone
      </button>
      {isModalOpen && (
        <Modal
          isOpen
          headerNoBorder
          footerNoBorder
          size="small"
          onClose={() => {
            setIsModalOpen(false);
            resetForm();
          }}
          header={<Modal.Title>Update Phone</Modal.Title>}
          footer={
            <Button
              onClick={handleSubmit}
              isDisabled={!dirty || update.isLoading}
              leftAdornment={
                update.isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              Save
            </Button>
          }
        >
          <InputPhone
            fullWidth
            value={values.phone}
            onChange={(x) => changeField("phone", x)}
            isError={!!getError("phone")}
          />
          {!!getError("phone") && (
            <InputError>
              {getError("phone")}
            </InputError>
          )}
        </Modal>
      )}
    </>
  )
}
