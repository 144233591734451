import React from "react";
import styles from "../scss/ProviderDashboard.module.scss";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";

const GridSkeleton = () => {
  return (
    <div className={styles.gridSkeleton}>
      <Skeleton height={200} />
      <Skeleton height={200} />
      <Skeleton height={200} />
      <Skeleton height={200} />
      <Skeleton height={200} />
      <Skeleton height={200} />
    </div>
  );
};

export default GridSkeleton;
