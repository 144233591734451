import { useQuery } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../consts/api";

export const useRepeatMdWallet = (patientId, options = {}) => {
  return useQuery(
    ["repeatMdWallet"],
    async () => {
      return http.get(
        HTTP_ENDPOINTS.repeatMdIntegration.getRepeatMdPatientWallet(patientId),
      );
    },
    { ...options, select: (data) => data.data.data, cacheTime: 0 },
  );
};
