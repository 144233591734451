import React from "react";
import { useDowngradeToEssentialsMutation } from "../../../../api/mutations/useDowngradeToEssentialsMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { unwrapOr } from "../../../../utilities/general";

export const withDowngradeToEssentials = (Component) => (props) => {
  const { data: activeUser } = useCurrentUserQuery();

  const isAvailable = unwrapOr(() => Boolean(activeUser.isMainAdmin), false);

  const {
    mutateAsync,
    isLoading: isDowngrading,
  } = useDowngradeToEssentialsMutation();

  const downgrade = async () => {
    if (isAvailable) {
      try {
        await mutateAsync();
        uiNotification.success(
          "Your subscription plan has been successfully downgraded to 'Essentials'",
        );
      } catch (e) {
        uiNotification.error("Unable To Downgrade");
        throw e;
      }
    } else {
      uiNotification.error(
        "Please contact the owner of your account, they will need to edit the subscription",
      );
      return Promise.reject();
    }
  };

  return (
    <Component
      {...props}
      downgradeToEssentials={{
        downgrade,
        isDowngrading,
        isAvailable,
      }}
    />
  );
};
