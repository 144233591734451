/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CloseIcon from "../../_legacy/images/close-icon.svg";

const CommonDialog = (props) => {
  const {
    title,
    onClose,
    children,
    className,
    footer,
    style,
    footerClassName,
  } = props;
  return (
    <div
      className={clsx("blackOverlay flex-col common-dialog", {
        "primary-modal-styles": style === "primary",
      })}
    >
      <div
        className={`small-popup-outer  displayBlock large-popup modal-body common-modal-body m-auto max-h-[90vh]  ${className}`}
      >
        <div className="small-popup-header removeBackgroundColor common-modal-header">
          {onClose && (
            <img
              src={CloseIcon}
              alt="x"
              className="small-cross"
              onClick={onClose}
            />
          )}
          <div className="modal-title ">
            <h4 className="m-l-10">{title}</h4>
          </div>
        </div>
        <div
          className={`small-popup-content scroll-y grow`}
          style={{ maxHeight: "96%" }}
        >
          <div className="common-modal-content">{children}</div>
        </div>
        {footer && (
          <div className={clsx("common-modal-footer", footerClassName)}>
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonDialog;

CommonDialog.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  footer: PropTypes.node,
  style: PropTypes.oneOf(["basic", "primary"]),
  footerClassName: PropTypes.string,
};

CommonDialog.defaultProps = {
  title: "",
  onClose: undefined,
  className: "",
  footer: undefined,
  style: "basic",
  footerClassName: "",
};
