import React from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./SimpleQuestionBuilderModal.module.scss";
import { useForm } from "./hooks/useForm";
import { Input } from "../../../../../../../shared/Input/Input";
import { Select } from "../../../../../../../shared/Select/Select";
import { SIMPLE_QUESTION_TYPES } from "../../../../../../../api/questionnaires/config";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { MultiText } from "./boxes/MultiText/MultiText";
import { MultiImage } from "./boxes/MultiImage/MultiImage";
import { ModalQuestionBuilder } from "../../../../shared/ModalQuestionBuilder/ModalQuestionBuilder";

export function SimpleQuestionBuilderModal({
  isOpen,
  onAdd,
  onUpdateByIndex,
  preloadedQuestion,
  onClose,
}) {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();

  const typeOptions = Object.values(SIMPLE_QUESTION_TYPES).map((type) => ({
    label: tSettings(`questionnaires.simple.types.${type}`),
    value: type,
  }));

  const form = useForm({
    preloadedQuestion,
    onSubmit: (formValues) => {
      if (preloadedQuestion) {
        onUpdateByIndex(formValues, preloadedQuestion.index);
      } else {
        onAdd(formValues);
      }
    },
  });

  return (
    <ModalQuestionBuilder
      isOpen={isOpen}
      onClose={onClose}
      isEditMode={Boolean(preloadedQuestion)}
      onSubmit={form.handleSubmit}
      onReset={form.dirty ? form.resetForm : undefined}
      error={form.getError("textChoices") || form.getError("imageChoices")}
    >
      <div className={classes.row}>
        <div>
          <InputLabel>{tCommon("label.question")}</InputLabel>
          <Input
            name="question"
            value={form.values.question}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isError={Boolean(form.getError("question"))}
          />
          {Boolean(form.getError("question")) && (
            <InputError>{form.getError("question")}</InputError>
          )}
        </div>
        <div>
          <InputLabel>{tCommon("label.questionType")}</InputLabel>
          <Select
            value={typeOptions.find((o) => o.value === form.questionType.value)}
            options={typeOptions}
            onChange={(next) => form.questionType.onChange(next.value)}
            isError={Boolean(form.getError("questionType"))}
          />
          {Boolean(form.getError("questionType")) && (
            <InputError>{form.getError("questionType")}</InputError>
          )}
        </div>
      </div>
      {form.questionType.value === SIMPLE_QUESTION_TYPES.multiText && (
        <MultiText
          isMultipleSelection={form.isMultipleSelection}
          textChoices={form.textChoices}
        />
      )}
      {form.questionType.value === SIMPLE_QUESTION_TYPES.multiImage && (
        <MultiImage
          isMultipleSelection={form.isMultipleSelection}
          isDisplayLabels={form.isDisplayLabels}
          imageChoices={form.imageChoices}
        />
      )}
    </ModalQuestionBuilder>
  );
}
