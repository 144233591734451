import React from "react";
import PropTypes from "prop-types";
import { AlertBox } from "../../../../../../../shared/AlertBox/AlertBox";
import classes from "./PendingPaymentAlert.module.scss";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";

export function PendingPaymentAlert({
  message,
  onIgnore,
  onCancelPayment,
  isCancelling,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();

  return (
    <AlertBox>
      <div className={classes.root}>
        <b>{tSales("checkoutInvoice.alert.pendingPayment")}</b>
        <p>
          {message || tSales("checkoutInvoice.alert.pendingPaymentFallback")}
        </p>
        <div className={classes.buttons}>
          <Button
            size="small"
            color="warn"
            variant="outlined"
            onClick={onIgnore}
          >
            {tCommon("label.ignore")}
          </Button>
          <Button
            size="small"
            color="warn"
            onClick={onCancelPayment}
            isDisabled={isCancelling}
            leftAdornment={
              isCancelling ? (
                <CircularProgress size="tiny" color="white" />
              ) : undefined
            }
          >
            {tCommon("label.cancelPayment")}
          </Button>
        </div>
      </div>
    </AlertBox>
  );
}

PendingPaymentAlert.propTypes = {
  message: PropTypes.string,
  onIgnore: PropTypes.func.isRequired,
  onCancelPayment: PropTypes.func.isRequired,
  isCancelling: PropTypes.bool.isRequired,
};

PendingPaymentAlert.defaultProps = {
  message: null,
};
