/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  getTwilioConfig,
  getIsAcceleratorPlan,
} from "../../../Utils/services.js";

const hideTotalCountForSysNames = ["patient-discounts"];

export default class PatientsReportTitle extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      showShareButton: getTwilioConfig(),
      clinicName: "All Clinics",
      reportTypeId: 0,
    };
    document.addEventListener("click", this.handleClick, false);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.parentState.selected_report_type_id != state.reportTypeId ||
      props.parentState.clinic_id != state.clinicId
    ) {
      return {
        clinicId: 0,
        reportTypeId: props.parentState.selected_report_type_id,
        clinicName: "All Clinics",
      };
    }
    return null;
  }
  showCreateOptions = () => {
    this.props.showCreateOptions();
  };

  saveReport = () => {
    this.props.saveReport();
  };

  shareReport = (obj) => {
    if (obj.name === "Mailchimp" && this.props.isMailchimpLoading) {
      return;
    }
    this.props.shareReport(obj);
  };

  saveList = () => {
    this.props.saveList();
  };

  toggleClinic = () => {
    this.setState({ showClinics: !this.state.showClinics });
  };

  changeClinic = (clinicId) => {
    let clinic = this.props.parentState.clinics.find((e) => e.id == clinicId);
    this.setState({
      clinicName: clinicId ? clinic.clinic_name : "All Clinics",
      clinicId: clinicId,
    });
    this.props.showReportByClinic(clinicId);
  };

  handleClick = (event) => {
    if (
      event.target.className !== "easy-link no-padding relative clinics" &&
      this.state.showClinics === true
    ) {
      this.setState({ showClinics: false });
    }
    if (
      event.target.className !== "easy-link no-padding relative sources" &&
      this.state.showSources === true
    ) {
      this.setState({ showSources: false });
    }
  };

  handleInputChange = (event) => {
    const name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });

    if (name === "object_name") {
      this.setState({ showLoader: true, page: 1, documentList: [] });
    }
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true });
    this.setState({ page: 1, documentList: [] });
  };

  render() {
    const sysName = this.props.parentState?.insightReportData?.sys_name;
    return (
      <div className="membership-title">
        <div className="m-b-10">
          {!hideTotalCountForSysNames.includes(sysName) &&
          [11, "11", 14, "14", 15, "15"].indexOf(
            this.props.parentState.selected_report_type_id,
          ) == -1
            ? (this.props.parentState.totalRow || "") + " "
            : ""}
          {this.props.parentState.grammar}
        </div>
        {sysName === "documents-expiration" && (
          <form
            name="submitForm"
            className="nobottommargin"
            onSubmit={this.props.handleSearchSubmit}
          >
            <div className="search-bg new-search col-xs-3 col-lg-3">
              <i className="fa fa-search m-l-10"></i>
              <input
                className="setting-search-input search-key font-14"
                onChange={this.props.handleSearchInputChange}
                placeholder="Search by name"
                name="term"
                autoComplete="off"
                value={this.props.parentState.searchTerm}
              />
            </div>
          </form>
        )}
        {!this.props.parentState.showLoader && (
          <div className="memberRightActions businessClientsRightAction">
            {this.props.parentState.insightReportData &&
              this.props.parentState.reports_without_clinics.indexOf(
                this.props.parentState.insightReportData.sys_name,
              ) == -1 &&
              this.props.parentState.clinics &&
              this.props.parentState.clinics.length > 0 && (
                <span
                  className="easy-link no-padding relative clinics"
                  onClick={this.toggleClinic}
                >
                  {this.state.clinicName}
                  <i className="fa fa-map-marker-alt"></i>

                  <div
                    className={
                      this.state.showClinics
                        ? "actionDropdownOuter clinicDropdown"
                        : "no-display"
                    }
                    ref={(clinicNode) => {
                      this.clinicNode = clinicNode;
                    }}
                  >
                    <Scrollbars
                      style={{ minHeight: "200px" }}
                      className="full-width"
                      renderTrackHorizontal={(props) => (
                        <div
                          {...props}
                          style={{ display: "none" }}
                          className="track-horizontal"
                        />
                      )}
                    >
                      <ul className="actionDropdown">
                        <li>
                          <a
                            onClick={this.changeClinic.bind(this, 0)}
                            className={
                              this.state.clinicId == 0 ? "selected" : ""
                            }
                          >
                            {this.props.parentState.languageData.bi_all_clinics}
                          </a>
                        </li>
                        {this.props.parentState.clinics &&
                          this.props.parentState.clinics.map((obj) => {
                            return (
                              <li
                                key={"clinic-" + obj.id}
                                data-clinic-id={obj.id}
                              >
                                <a
                                  onClick={this.changeClinic.bind(this, obj.id)}
                                  className={
                                    this.state.clinicId == obj.id
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {obj.clinic_name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </Scrollbars>
                  </div>
                </span>
              )}
            {this.props.parentState.reportListData &&
              this.props.parentState.reportListData.length > 0 && (
                <a
                  onClick={() => this.props.downloadData()}
                  className="easy-link no-padding"
                >
                  <i className="fa fa-download m-r-5" />
                  {this.props.parentState.businessInsightLang.bi_download_excel}
                </a>
              )}
            {this.props.parentState.reportType !== "view" && (
              <a
                className="easy-link no-padding"
                onClick={this.showCreateOptions}
              >
                <i className="fa fa-pencil-alt m-r-5" />{" "}
                {this.props.parentState.businessInsightLang.bi_edit}
              </a>
            )}
            {getIsAcceleratorPlan() === true &&
              this.props.parentState.reportListData &&
              this.props.parentState.reportListData.length > 0 &&
              this.props.parentState.selected_report_type_id != 11 &&
              this.props.parentState.share_login_url &&
              this.props.parentState.share_login_url.length > 0 && (
                <React.Fragment>
                  <button
                    className="line-btn pull-right social-share"
                    type="button"
                    id="dropdownMenuShare"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    {"Share"}
                    <i className="fas fa-angle-down"></i>
                  </button>
                  <ul
                    className="dropdown-menu socialDropdownOuter"
                    aria-labelledby="dropdownMenuShare"
                  >
                    {this.props.parentState.share_login_url.map((obj, idx) => {
                      return (
                        <li key={"share_login_url-" + idx}>
                          <a
                            className={
                              "header-unselect-btn modal-link socialDropdownIcon " +
                              obj.name
                            }
                            onClick={() => this.shareReport(obj)}
                          >
                            {obj.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </React.Fragment>
              )}
            {this.props.parentState.dataLoadedOnce === true &&
              this.props.parentState.reportType !== "view" && (
                <a
                  className="new-blue-btn SaveReport"
                  onClick={this.saveReport}
                >
                  {this.props.parentState.businessInsightLang.bi_save_report}
                </a>
              )}
          </div>
        )}
      </div>
    );
  }
}
