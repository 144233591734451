import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Header from "../../_legacy/Containers/Guest/Header.js";
import Footer from "../../_legacy/Containers/Guest/Footer.js";
import { AvailableJobs, RunJob } from "../../Actions/signinAction";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Common/Loader.js";

const JobSimulator = (props) => {
  const { AvailableJobs, RunJob, JobSimulatorData } = props;

  useEffect(() => {
    AvailableJobs();
  }, []);

  useEffect(() => {
    let optionsList = [];
    if (JobSimulatorData.length > 1) {
      for (let i = 0; i <= JobSimulatorData.length; i++) {
        optionsList.push({
          label: JobSimulatorData[i],
          value: JobSimulatorData[i],
        });
      }
      setOptions(optionsList);
    }
  }, [JobSimulatorData]);

  const isLogIn = JobSimulatorData.message === "invalid_token";
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [accountIds, setAccountIds] = useState("");
  const [patientIds, setPatientIds] = useState("");
  const [jobs, setJobs] = useState([]);
  const [options, setOptions] = useState([]);
  const [shownData, setShownData] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = (e) => {
    setShowLoader(true);
    e.preventDefault();
    let selectedValue = [];
    jobs.map((obj) => {
      selectedValue.push(obj.value);
    });
    let formData = {};
    formData.start_date = format(startDate, "YYYY-MM-DD");
    formData.end_date = format(endDate, "YYYY-MM-DD");
    formData.account_ids = accountIds.split(",").map(Number);
    formData.patient_ids = patientIds.split(",").map(Number);
    formData.jobs = selectedValue;
    RunJob(formData).then((response) => {
      setShowLoader(false);
      setShownData({
        cron: jobs.map((job) => job.label).join(", "),
        startDate: format(startDate, "YYYY-MM-DD"),
        endDate: format(endDate, "YYYY-MM-DD"),
        account: accountIds.split(","),
        patient: patientIds.split(","),
        timestamp: format(new Date(), "YYYY-MM-DD h:mm:ss"),
        response: response,
      });
    });
  };

  return (
    <div className="guest">
      <Header />
      <div className="wrapper">
        <div className="card-block">
          <div className="login-main m-t-60">
            <h1>Membership Jobs Simulator</h1>
            <form onSubmit={handleSubmit} id="external-form">
              <div className="form-group row">
                <div className="col-lg-6 col-sm-12 m-b-10">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      Start Date:<span className="setting-require">*</span>
                    </div>
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      required
                      minDate={new Date()}
                      className="form-control"
                      form="external-form"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 m-b-10">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      End Date:<span className="setting-require">*</span>
                    </div>
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      required
                      minDate={new Date()}
                      className="form-control"
                      form="external-form"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 m-b-10">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      Account IDs:<span className="setting-require">*</span>
                    </div>
                    <input
                      className="form-control newInputField"
                      required
                      type="text"
                      name="account_ids"
                      id="account_ids"
                      onChange={(e) => setAccountIds(e.target.value)}
                      placeholder="1,2,3..."
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 m-b-10">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      Patient IDs:<span className="setting-require">*</span>
                    </div>
                    <input
                      className="form-control newInputField"
                      required
                      type="text"
                      name="patient_ids"
                      id="patient_ids"
                      onChange={(e) => setPatientIds(e.target.value)}
                      placeholder="1,2,3..."
                    />
                  </div>
                </div>
                {!isLogIn && (
                  <div>
                    <div className="col-sm-12 m-b-10">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Job<span className="setting-require">*</span>
                        </div>
                        {options.length > 0 && (
                          <MultiSelect
                            options={options}
                            value={jobs}
                            onChange={setJobs}
                            labelledBy="Select"
                            displayValue="Name"
                            hasSelectAll={false}
                            form="external-form"
                            disableSearch
                            shouldToggleOnHover={false}
                            required
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 m-b-40">
                      <button
                        style={{ width: "100px" }}
                        className={`btn btn-default btn-sm pull-right ${
                          jobs.length <= 0 ? "disable" : ""
                        }`}
                        type="submit"
                      >
                        Run Job
                      </button>
                    </div>
                    {shownData.response && (
                      <div className="col-sm-12 m-b-10">
                        <p>Completed at {shownData.timestamp}</p>
                        <ul>
                          <li> cron: {shownData.cron} </li>
                          <li> account: {shownData.account}; </li>
                          <li> patient: {shownData.patient}; </li>
                          <li> start date: {shownData.startDate} </li>
                          <li> end date: {shownData.endDate} </li>
                        </ul>
                        <p>Response: {shownData.response}</p>
                      </div>
                    )}
                  </div>
                )}
                {isLogIn && (
                  <div className="col-sm-12 m-b-10 text-center m-t-30">
                    <h2>Please login first</h2>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <Loader showLoader={showLoader} isFullWidth />
    </div>
  );
};

const mapStateToProps = (state) => ({
  JobSimulatorData: state.UserReducer.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AvailableJobs,
      RunJob,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(JobSimulator));
