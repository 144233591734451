import * as yup from "yup";
import { useExtendedFormik } from "../../../../../hooks/useExtendedFormik";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

export const useForm = (onSubmit, initialValues = {}) => {
  const { tCommon } = useAppTranslation.Common();

  const { values, changeField, getError, handleSubmit } = useExtendedFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues: {
      balance: initialValues?.balance || "",
      reason: initialValues?.reason || "",
    },
    validationSchema: yup.object({
      balance: yup
        .string()
        .required(
          tCommon("modalEditGiftCardBalance.form.error.balanceRequired"),
        ),
      reason: yup
        .string()
        .required(
          tCommon("modalEditGiftCardBalance.form.error.reasonRequired"),
        ),
    }),
  });

  return {
    submit: handleSubmit,

    balance: {
      value: values.balance,
      onChange: (next) => changeField("balance", next),
      getError: () => getError("balance"),
    },

    reason: {
      value: values.reason,
      onChange: (e) => changeField("reason", e.target.value),
      getError: () => getError("reason"),
    },
  };
};
