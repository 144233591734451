import { http } from "../services/HttpService";

export function UserResetPasswordForm(userData) {
  return (dispatch) => {
    http
      .post("password/email", userData)
      .then((response) => {
        dispatch({
          type: "FORGOT_PASSWORD_SUCCESSFULL",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FORGOT_PASSWORD_ERROR",
          payload: error.response.data,
        });
      });
  };
}
