/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import queryString from "query-string";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import validator from "validator";
import {
  getAppointment,
  updateAppointment,
  saveCovidEmailSchedulling,
  daleteCovidEmailSchedulling,
  getCovidEmailSchedulling,
  exportEmptyData,
} from "../../Actions/Settings/settingsActions.js";
import AppointmentConfigNavbar from "./AppointmentConfigNavbar.js";
import ConfirmationModal from "../Common/ConfirmationModal.js";
import { toggleBodyScroll } from "../../Utils/services.js";

import {
  htmlEmailPreview,
  contentCovidCommunication,
  contentAppointmentBooked,
  contentAppointmentBookedVirtual,
  contentAppointmentReminder,
  contentAppointmentReminderVirtual,
} from "../../Utils/email-template.js";
import EditorWithPreview from "../Common/EditorWithPreview.js";
import MaxCharCount from "../Common/MaxCharCount.js";
import { tCommon } from "../../i18n/useAppTranslation.js";
import { withQueryParamsState } from "../../utilities/hooks/useQueryParamsState.js";
import { unwrapOr, findBadWordsIn } from "../../utilities/general.js";
import { SmsChargeDisclaimer } from "./shared/SmsChargeDisclaimer/SmsChargeDisclaimer.js";
import { uiNotification } from "../../services/UINotificationService.js";

const TAB_TYPES = {
  specialInstruction: "specialInstruction",
  bookingConfirmation: "inPersonBooking",
  virtualBookingConfirmation: "virtualBooking",
  cancellationConfirmation: "cancellation",
  appointmentReminder: "inPersonReminder",
  virtualAppointmentReminder: "virtualReminder",
  covidCommunication: "covidCommunication",
  appointmentReschedule: "reschedule",
  surveyEmail: "survey",
};

const snippetVariableErrorTemplate = (s) =>
  `Your message with substituted snippet variables is too long. Please reduce the length of your message ${
    s || ""
  }`;

const TAB_NAME_BY_SNIPPET_VARIABLE_ERROR = {
  appointment_booking_status: "In-Person tab",
  appointment_virtual_booking_status: "Virtual tab",
  appointment_cancel_status: "Cancellation tab",
  appointment_reminder_status: "In-Person Reminder tab",
  appointment_virtual_reminder_status: "Virtual Reminder tab",
  appointment_reschedule_status: "Reschedule tab",
  appointment_survey_status: "Survey tab",
  declined_payment_sms_body: "Membership Decline Sms",
};

const smsFieldMaxCharCount = 1300;
const smsFieldNames = [
  "appointment_booking_sms",
  "appointment_virtual_booking_sms",
  "appointment_canceled_sms",
  "reminder_sms",
  "virtual_reminder_sms",
  "appointment_reschedule_sms",
  "appointment_survey_sms",
];
const initCovidEmailScheduling = () => {
  return {
    covid_email_type: "",
    covid_email_typeClass: "text-inline-select select-fill-box filled-border",
    covid_email_days: "",
    covid_email_daysClass: "text-inline-select select-fill-box filled-border",
  };
};

class AppointmentCommunication extends Component {
  constructor(props) {
    super(props);

    let daysList = [];
    daysList.push(<option value={""}>{"Select"}</option>);
    for (var count = 1; count <= 30; count++) {
      daysList.push(<option value={count}>{count}</option>);
    }

    const langData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      langData: langData,
      appointmentData: {},
      clinicList: [],
      appointment_cancel_status: false,
      appointment_booking_status: false,
      appointment_reschedule_status: false,
      appointment_reminder_status: false,
      userChanged: false,
      bookingEmail: true,
      bookingSMS: false,
      cancellationEmail: true,
      cancellationSMS: false,
      reminderEmail: true,
      surveyEmailMsg: true,
      reminderSMS: false,
      surveySMS: false,
      rescheduleEmail: true,
      rescheduleSMS: false,

      checkboxClosed: "switch-accordian-row closed",
      checkboxOn: "switch-accordian-row",

      clinic_name: "",
      contact_no: "",
      city: "",
      country: "",
      address: "",
      tax: "",
      status: true,
      appointment_notification_emails: "",
      clinic_color: "",
      sms_notifications_phone: "",
      reminder_email_subject: "",
      reminder_sms: "",
      reminder_email: "",
      appointment_reminder: "",
      from_email: "",
      appointment_booking_email: "",
      appointment_booking_sms: "",
      appointment_canceled_email: "",
      appointment_canceled_sms: "",
      email_special_instructions: "",
      appointment_reschedule_email: "",
      appointment_reschedule_sms: "",
      appointment_reminder_email_subject: "",
      appointment_reminder_sms: "",
      appointment_reminder_email_body: "",
      AppointmentCancelEnable: "setting-container",
      AppointmentCancelDisable: "setting-container no-display",
      showLoader: false,

      covid_email_status: false,
      covid_email_subject: "",
      covid_email_body: "",
      covid_email_subjectClass: "newInputField",
      covid_email_bodyClass: "editor_textarea editor-preview",
      covid_email_type: "after",
      covid_email_days: "",
      daysList: daysList,
      covidEmailSchedulling: [],
      deleteCovideSchedullingId: 0,
      editCovideSchedullingId: 0,

      showConfirmationModal: false,
      confirmationMsg: "",
      confirmationActionType: "",

      globalLang: langData && langData.global ? langData.global : null,

      from_emailClass: "newInputField",
      appointment_booking_smsClass: "newtextareaField",
      appointment_booking_emailClass: "editor_textarea editor-preview",
      appointment_canceled_smsClass: "newtextareaField",
      appointment_canceled_emailClass: "editor_textarea editor-preview",
      reminder_smsClass: "newtextareaField",
      reminder_emailClass: "editor_textarea editor-preview",
      reminder_email_subjectClass: "newInputField",
      appointment_reschedule_smsClass: "newtextareaField",
      appointment_reschedule_emailClass: "editor_textarea editor-preview",
      appointment_survey_emailClass: "editor_textarea editor-preview",
      appointment_survey_smsClass: "newtextareaField",
      survey_thankyou_messageClass: "newtextareaField",
      survey_thankyou_message: "",

      email_header_footer: htmlEmailPreview(),

      virtualBookingEmail: true,
      virtualBookingSMS: false,
      appointment_virtual_booking_status: false,
      appointment_virtual_booking_email: "",
      appointment_virtual_booking_sms: "",
      appointment_virtual_booking_emailClass: "editor_textarea editor-preview",
      appointment_virtual_booking_smsClass: "newtextareaField",

      virtualReminderEmail: true,
      virtualReminderSMS: false,
      appointment_virtual_reminder_status: false,
      virtual_reminder_email_subject: "",
      virtual_reminder_email: "",
      virtual_reminder_sms: "",
      virtual_reminder_email_subjectClass: "newInputField",
      virtual_reminder_emailClass: "editor_textarea editor-preview",
      virtual_reminder_smsClass: "newtextareaField",
    };
  }

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      AppointmentEmailSMS_Special_Instructions:
        languageData.settings["AppointmentEmailSMS_Special_Instructions"],
      AppointmentEmailSMS_From_Email:
        languageData.settings["AppointmentEmailSMS_From_Email"],
      AppointmentEmailSMS_Instructions:
        languageData.settings["AppointmentEmailSMS_Instructions"],
      AppointmentEmailSMS_Appointment_Booking_Confirmation:
        languageData.settings[
          "AppointmentEmailSMS_Appointment_Booking_Confirmation"
        ],
      Survey_email: languageData.settings["Survey_email"],
      survey_email_message: languageData.settings["survey_email_message"],
      survey_sms: languageData.settings["survey_sms"],
      survey_sms_message: languageData.settings["survey_sms_message"],
      AppointmentEmailSMS_Appointment_Cancellation_Confirmation:
        languageData.settings[
          "AppointmentEmailSMS_Appointment_Cancellation_Confirmation"
        ],
      AppointmentEmailSMS_Appointment_Reschedule_Confirmation:
        languageData.settings[
          "AppointmentEmailSMS_Appointment_Reschedule_Confirmation"
        ],
      AppointmentEmailSMS_Appointment_Reminder:
        languageData.settings["AppointmentEmailSMS_Appointment_Reminder"],
      AppointmentEmailSMS_Submitbtn:
        languageData.settings["AppointmentEmailSMS_Submitbtn"],
      AppointmentEmailSMS_Your_Demo_Clinic:
        languageData.settings["AppointmentEmailSMS_Your_Demo_Clinic"],
      AppointmentEmailSMS_EMAIL_SUBJECT:
        languageData.settings["AppointmentEmailSMS_EMAIL_SUBJECT"],
      AppointmentEmailSMS_EMAIL_BODY:
        languageData.settings["AppointmentEmailSMS_EMAIL_BODY"],
      editUsers_CancelBtn: languageData.settings["editUsers_CancelBtn"],
      AppointmentEmailSMS_Patient_Name:
        languageData.settings["AppointmentEmailSMS_Patient_Name"],
      AppointmentEmailSMS_Special_Appointment_Date_Time:
        languageData.settings[
          "AppointmentEmailSMS_Special_Appointment_Date_Time"
        ],
      clinics_Clinic_Name: languageData.settings["clinics_Clinic_Name"],
      AppointmentEmailSMS_Special_Clinic_Location:
        languageData.settings["AppointmentEmailSMS_Special_Clinic_Location"],
      AppointmentEmailSMS_Clinic_Instructions:
        languageData.settings["AppointmentEmailSMS_Clinic_Instructions"],
      AppointmentEmailSMS_Booked_Services:
        languageData.settings["AppointmentEmailSMS_Booked_Services"],
      AppointmentEmailSMS_Cancellation_Fee_Charge_Days:
        languageData.settings[
          "AppointmentEmailSMS_Cancellation_Fee_Charge_Days"
        ],
      AppointmentEmailSMS_Cancellation_Fee:
        languageData.settings["AppointmentEmailSMS_Cancellation_Fee"],
      AppointmentEmailSMS_InstructionsLabel:
        languageData.settings["AppointmentEmailSMS_InstructionsLabel"],
      AppointmentEmailSMS_Provider_Name:
        languageData.settings["AppointmentEmailSMS_Provider_Name"],
      AppointmentEmailSMS_Business_Name:
        languageData.settings["AppointmentEmailSMS_Business_Name"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      user_save_btn_text: languageData.settings["user_save_btn_text"],
      booking_status_enable: languageData.settings["booking_status_enable"],
      booking_status_disable: languageData.settings["booking_status_disable"],
      appointment_cancellation_disable:
        languageData.settings["appointment_cancellation_disable"],
      appointment_cancellation_enable:
        languageData.settings["appointment_cancellation_enable"],
      appointment_reminder_disable:
        languageData.settings["appointment_reminder_disable"],
      appointment_reminder_enable:
        languageData.settings["appointment_reminder_enable"],
      appointment_reschedule_enable:
        languageData.settings["appointment_reschedule_enable"],
      appointment_reschedule_disable:
        languageData.settings["appointment_reschedule_disable"],
      AppointmentEmailSMS_Covide:
        languageData.settings["AppointmentEmailSMS_Covide"],
      appointment_covide_communication_enable:
        languageData.settings["appointment_covide_communication_enable"],
      appointment_covide_communication_disable:
        languageData.settings["appointment_covide_communication_disable"],
      showLoader: true,
    });
    this.setState({ showLoader: true });
    this.props.getAppointment();

    const tab = this.props.queryParamsState?.t;
    if (!Object.values(TAB_TYPES).includes(tab)) {
      this.changeTab(TAB_TYPES.equipment);
    }
  }

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (props.showLoader === false) {
      props.exportEmptyData();
      returnState.showLoader = false;
    } else if (props.isReloadSchedullingList) {
      props.exportEmptyData();

      props.getCovidEmailSchedulling({
        params: {
          page: 1,
          pagesize: 50,
        },
      });
    } else if (
      props.covidSchedullingData &&
      props.covidSchedullingData !== state.covidSchedullingData
    ) {
      props.exportEmptyData();
      returnState.covidSchedullingData = props.covidSchedullingData;
      let covidEmailSchedulling = [];

      returnState.covidSchedullingData.map((obj) => {
        let schedulling = initCovidEmailScheduling();
        schedulling.id = obj.id;
        schedulling.covid_email_type = obj.type;
        schedulling.covid_email_days = obj.reminder_before;

        schedulling.covid_email_type_temp = obj.type;
        schedulling.covid_email_days_temp = obj.reminder_before;
        schedulling.isEditFlag = false;

        const oldSchedulling = state.covidEmailSchedulling.find(
          (x) => x.id == obj.id,
        );
        if (oldSchedulling) {
          schedulling.isEditFlag =
            obj.id == state.editCovideSchedullingId
              ? false
              : oldSchedulling.isEditFlag;
        }

        covidEmailSchedulling.push(schedulling);
      });
      returnState.covidEmailSchedulling = covidEmailSchedulling;
      returnState.editCovideSchedullingId = 0;
      returnState.deleteCovideSchedullingId = 0;

      returnState.showLoader = false;
    } else if (
      props.appointmentData != undefined &&
      props.appointmentData.data !== state.appointmentData &&
      props.appointmentDataTimeStamp != state.appointmentDataTimeStamp
    ) {
      props.exportEmptyData();
      returnState.appointmentData = props.appointmentData.data;
      returnState.appointmentDataTimeStamp = props.appointmentDataTimeStamp;
      if (props.appointmentData.data.clinics.length) {
        props.appointmentData.data.clinics.map((obj) => {
          returnState["email_special_instructions-" + obj.id] =
            obj.email_special_instructions;
        });
      }
      let accData =
        props.appointmentData.data.account_details.account_preference;
      returnState.appointment_survey_email = state.userChanged
        ? state.appointment_survey_email
        : props.appointmentData.data.account_details.appointment_survey_email;
      returnState.appointment_survey_sms = state.userChanged
        ? state.appointment_survey_sms
        : props.appointmentData.data.account_details.appointment_survey_sms;
      returnState.survey_thankyou_message = state.userChanged
        ? state.survey_thankyou_message
        : props.appointmentData.data.account_details.account_preference
            .survey_thankyou_message
        ? props.appointmentData.data.account_details.account_preference
            .survey_thankyou_message
        : "";
      returnState.from_email = state.userChanged
        ? state.from_email
        : accData.from_email
        ? accData.from_email
        : "";
      returnState.appointment_booking_status =
        props.appointmentData.data.account_details.appointment_booking_status;
      returnState.appointment_booking_email = state.userChanged
        ? state.appointment_booking_email
        : props.appointmentData.data.account_details.appointment_booking_email;
      returnState.appointment_booking_sms = state.userChanged
        ? state.appointment_booking_sms
        : props.appointmentData.data.account_details.appointment_booking_sms;
      returnState.appointment_cancel_status =
        props.appointmentData.data.account_details.appointment_cancel_status;
      returnState.appointment_canceled_email = state.userChanged
        ? state.appointment_canceled_email
        : props.appointmentData.data.account_details.appointment_canceled_email;
      returnState.appointment_canceled_sms =
        props.appointmentData.data.account_details.appointment_canceled_sms;
      returnState.appointment_reschedule_status =
        props.appointmentData.data.account_details.appointment_reschedule_status;
      returnState.appointment_reschedule_email = state.userChanged
        ? state.appointment_reschedule_email
        : props.appointmentData.data.account_details
            .appointment_reschedule_email;
      returnState.appointment_reschedule_sms = state.userChanged
        ? state.appointment_reschedule_sms
        : props.appointmentData.data.account_details.appointment_reschedule_sms;
      returnState.appointment_reminder_status =
        props.appointmentData.data.account_details.appointment_reminder_status;
      returnState.reminder_email_subject = state.userChanged
        ? state.reminder_email_subject
        : accData.reminder_email_subject;
      returnState.reminder_email = state.userChanged
        ? state.reminder_email
        : accData.reminder_email;
      returnState.reminder_sms = state.userChanged
        ? state.reminder_sms
        : accData.reminder_sms;
      returnState.email_special_instructions = state.userChanged
        ? state.email_special_instructions
        : accData.email_special_instructions;
      returnState.clinicList = state.userChanged
        ? state.clinicList
        : props.appointmentData.data.clinics;
      returnState.showLoader = false;

      returnState.covid_email_status = accData.covid_email_status
        ? true
        : false;
      returnState.covid_email_subject = state.userChanged
        ? state.covid_email_subject
        : accData.covid_email_subject
        ? accData.covid_email_subject
        : "";
      returnState.covid_email_body = state.userChanged
        ? state.covid_email_body
        : accData.covid_email_body
        ? accData.covid_email_body
        : "";

      returnState.appointment_virtual_booking_status =
        props.appointmentData.data.account_details.appointment_virtual_booking_status;
      returnState.appointment_virtual_booking_email = state.userChanged
        ? state.appointment_virtual_booking_email
        : props.appointmentData.data.account_details
            .appointment_virtual_booking_email;
      returnState.appointment_virtual_booking_sms = state.userChanged
        ? state.appointment_virtual_booking_sms
        : props.appointmentData.data.account_details
            .appointment_virtual_booking_sms;

      returnState.appointment_virtual_reminder_status =
        props.appointmentData.data.account_details.appointment_virtual_reminder_status;
      returnState.virtual_reminder_email_subject = state.userChanged
        ? state.virtual_reminder_email_subject
        : accData.virtual_reminder_email_subject;
      returnState.virtual_reminder_email = state.userChanged
        ? state.virtual_reminder_email
        : accData.virtual_reminder_email;
      returnState.virtual_reminder_sms = state.userChanged
        ? state.virtual_reminder_sms
        : accData.virtual_reminder_sms;

      const covid_email_schedule = accData.covid_email_schedule
        ? accData.covid_email_schedule
        : [];
      let covidEmailSchedulling = state.covidEmailSchedulling
        ? state.covidEmailSchedulling
        : [];
      if (covidEmailSchedulling.length <= 0) {
        covid_email_schedule.map((obj) => {
          let schedulling = initCovidEmailScheduling();
          schedulling.id = obj.id;
          schedulling.covid_email_type = obj.type;
          schedulling.covid_email_days = obj.reminder_before;

          schedulling.covid_email_type_temp = obj.type;
          schedulling.covid_email_days_temp = obj.reminder_before;

          schedulling.isEditFlag = false;

          covidEmailSchedulling.push(schedulling);
        });
      }
      returnState.covidEmailSchedulling = covidEmailSchedulling;
      returnState.editCovideSchedullingId = 0;
      returnState.deleteCovideSchedullingId = 0;

      const email_template = props.appointmentData.data.email_template;
      if (email_template) {
        let email_header_footer = htmlEmailPreview();
        email_header_footer = email_header_footer.replace(
          /{{ACCOUNT_LOGO}}/g,
          `${email_template.account_logo}`,
        );

        if (email_template.clinic) {
          email_header_footer = email_header_footer.replace(
            /{{CLINIC_ADDRESS}}/g,
            `${email_template.clinic.address}`,
          );

          let clinicCityStateZip = email_template.clinic.clinic_city
            ? `${email_template.clinic.clinic_city}, `
            : `${email_template.clinic.city}, `;
          clinicCityStateZip += email_template.clinic.clinic_state
            ? `${email_template.clinic.clinic_state}, `
            : `${email_template.clinic.country}, `;
          clinicCityStateZip += email_template.clinic.clinic_zipcode
            ? email_template.clinic.clinic_zipcode
            : "";
          email_header_footer = email_header_footer.replace(
            /{{CLINIC_CITY_STATE_COUNTRY_ZIP}}/g,
            `${clinicCityStateZip}`,
          );
        }

        returnState.email_header_footer = email_header_footer;
      }
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }
    }
    let statusArr = [
      "appointment_cancel_status",
      "appointment_booking_status",
      "appointment_reminder_status",
      "appointment_reschedule_status",
      "covid_email_status",
      "appointment_virtual_booking_status",
      "appointment_virtual_reminder_status",
    ];
    if (statusArr.indexOf(target.name) > -1) {
      let formData = {};
      formData.account_details = {};
      formData.account_details[target.name] = value;
      if (target.name === "covid_email_status") {
        const account_preference = {
          covid_email_status: value,
        };
        formData.account_details.account_preference = account_preference;
      }
      this.setState({ showLoader: true });
      this.props.updateAppointment(formData);
    } else {
      if (
        !(
          smsFieldNames.includes(target.name) &&
          target.value.length > smsFieldMaxCharCount &&
          target.value.length > this.state[target.name].length
        )
      ) {
        this.setState({ [event.target.name]: value, userChanged: true });
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let error = false;

    let newInputFieldClass = {
      from_emailClass: "newInputField",
      appointment_booking_smsClass: "newtextareaField",
      appointment_booking_emailClass: "editor_textarea editor-preview",
      appointment_canceled_smsClass: "newtextareaField",
      appointment_canceled_emailClass: "editor_textarea editor-preview",
      reminder_smsClass: "newtextareaField",
      reminder_emailClass: "editor_textarea editor-preview",
      reminder_email_subjectClass: "newInputField",
      covid_email_subjectClass: "newInputField",
      covid_email_bodyClass: "editor_textarea editor-preview",
      appointment_reschedule_smsClass: "newtextareaField",
      appointment_reschedule_emailClass: "editor_textarea editor-preview",
      appointment_survey_emailClass: "editor_textarea editor-preview",
      appointment_survey_smsClass: "newtextareaField",
      survey_thankyou_messageClass: "newtextareaField",
      appointment_virtual_booking_emailClass: "editor_textarea editor-preview",
      appointment_virtual_booking_smsClass: "newtextareaField",
      virtual_reminder_email_subjectClass: "newInputField",
      virtual_reminder_emailClass: "editor_textarea editor-preview",
      virtual_reminder_smsClass: "newtextareaField",
    };

    const tab = this.props.queryParamsState?.t;

    if (tab === TAB_TYPES.specialInstruction) {
      const badWords = this.state.clinicList.reduce((res, clinic) => {
        const clinicText = unwrapOr(
          () => this.state["email_special_instructions-" + clinic.id],
          "",
        );
        return [...res, ...findBadWordsIn(clinicText)];
      }, []);

      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }

      if (
        this.state.from_email != "" &&
        !validator.isEmail(this.state.from_email)
      ) {
        uiNotification.clear();
        uiNotification.error("Incorrect email address");
        newInputFieldClass.from_emailClass = "newInputField field_error";
        error = true;
      }
    }

    if (tab === TAB_TYPES.bookingConfirmation) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.appointment_booking_sms || ""),
        ...findBadWordsIn(this.state.appointment_booking_email || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
      if (
        typeof this.state.appointment_booking_sms === undefined ||
        this.state.appointment_booking_sms === null ||
        this.state.appointment_booking_sms === "" ||
        this.state.appointment_booking_sms.trim() === ""
      ) {
        newInputFieldClass.appointment_booking_smsClass =
          "newtextareaField field_error";
        error = true;
      }

      if (
        typeof this.state.appointment_booking_email === undefined ||
        this.state.appointment_booking_email === null ||
        this.state.appointment_booking_email === "" ||
        this.state.appointment_booking_email.trim() === ""
      ) {
        newInputFieldClass.appointment_booking_emailClass =
          "editor_textarea editor-preview field_error";
        error = true;
      }
    }

    if (tab === TAB_TYPES.virtualBookingConfirmation) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.appointment_virtual_booking_sms || ""),
        ...findBadWordsIn(this.state.appointment_virtual_booking_email || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
      if (
        typeof this.state.appointment_virtual_booking_sms === undefined ||
        this.state.appointment_virtual_booking_sms === null ||
        this.state.appointment_virtual_booking_sms === "" ||
        this.state.appointment_virtual_booking_sms.trim() === ""
      ) {
        newInputFieldClass.appointment_virtual_booking_smsClass =
          "newtextareaField field_error";
        error = true;
        this.setState({ virtualBookingEmail: false, virtualBookingSMS: true });
      }

      if (
        typeof this.state.appointment_virtual_booking_email === undefined ||
        this.state.appointment_virtual_booking_email === null ||
        this.state.appointment_virtual_booking_email === "" ||
        this.state.appointment_virtual_booking_email.trim() === ""
      ) {
        newInputFieldClass.appointment_virtual_booking_emailClass =
          "editor_textarea editor-preview field_error";
        error = true;
        this.setState({ virtualBookingEmail: true, virtualBookingSMS: false });
      }
    }

    if (tab === TAB_TYPES.cancellationConfirmation) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.appointment_canceled_sms || ""),
        ...findBadWordsIn(this.state.appointment_canceled_email || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
    }

    if (this.state.cancellationEmail) {
      if (
        typeof this.state.appointment_canceled_sms === undefined ||
        this.state.appointment_canceled_sms === null ||
        this.state.appointment_canceled_sms === "" ||
        this.state.appointment_canceled_sms.trim() === ""
      ) {
        newInputFieldClass.appointment_canceled_smsClass =
          "newtextareaField field_error";
        error = true;
      }

      if (
        typeof this.state.appointment_canceled_email === undefined ||
        this.state.appointment_canceled_email === null ||
        this.state.appointment_canceled_email === "" ||
        this.state.appointment_canceled_email.trim() === ""
      ) {
        newInputFieldClass.appointment_canceled_emailClass =
          "editor_textarea editor-preview field_error";
        error = true;
      }
    }

    if (tab === TAB_TYPES.appointmentReminder) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.reminder_sms || ""),
        ...findBadWordsIn(this.state.reminder_email || ""),
        ...findBadWordsIn(this.state.reminder_email_subject || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
      if (
        typeof this.state.reminder_sms === undefined ||
        this.state.reminder_sms === null ||
        this.state.reminder_sms === "" ||
        this.state.reminder_sms.trim() === ""
      ) {
        newInputFieldClass.reminder_smsClass = "newtextareaField field_error";
        error = true;
      }

      if (
        typeof this.state.reminder_email === undefined ||
        this.state.reminder_email === null ||
        this.state.reminder_email === "" ||
        this.state.reminder_email.trim() === ""
      ) {
        newInputFieldClass.reminder_emailClass =
          "editor_textarea editor-preview field_error";
        error = true;
      }

      if (
        typeof this.state.reminder_email_subject === undefined ||
        this.state.reminder_email_subject === null ||
        this.state.reminder_email_subject === "" ||
        this.state.reminder_email_subject.trim() === ""
      ) {
        newInputFieldClass.reminder_email_subjectClass =
          "newInputField field_error";
        error = true;
      }
    }

    if (tab === TAB_TYPES.virtualAppointmentReminder) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.virtual_reminder_sms || ""),
        ...findBadWordsIn(this.state.virtual_reminder_email || ""),
        ...findBadWordsIn(this.state.virtual_reminder_email_subject || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
      if (
        typeof this.state.virtual_reminder_sms === undefined ||
        this.state.virtual_reminder_sms === null ||
        this.state.virtual_reminder_sms === "" ||
        this.state.virtual_reminder_sms.trim() === ""
      ) {
        newInputFieldClass.virtual_reminder_smsClass =
          "newtextareaField field_error";
        error = true;
        this.setState({
          virtualReminderEmail: false,
          virtualReminderSMS: true,
        });
      }

      if (
        typeof this.state.virtual_reminder_email === undefined ||
        this.state.virtual_reminder_email === null ||
        this.state.virtual_reminder_email === "" ||
        this.state.virtual_reminder_email.trim() === ""
      ) {
        newInputFieldClass.virtual_reminder_emailClass =
          "editor_textarea editor-preview field_error";
        error = true;
        this.setState({
          virtualReminderEmail: true,
          virtualReminderSMS: false,
        });
      }

      if (
        typeof this.state.virtual_reminder_email_subject === undefined ||
        this.state.virtual_reminder_email_subject === null ||
        this.state.virtual_reminder_email_subject === "" ||
        this.state.virtual_reminder_email_subject.trim() === ""
      ) {
        newInputFieldClass.virtual_reminder_email_subjectClass =
          "newInputField field_error";
        error = true;
        this.setState({
          virtualReminderEmail: true,
          virtualReminderSMS: false,
        });
      }
    }

    if (tab === TAB_TYPES.covidCommunication) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.covid_email_subject || ""),
        ...findBadWordsIn(this.state.covid_email_body || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
      if (
        typeof this.state.covid_email_subject === undefined ||
        this.state.covid_email_subject === null ||
        this.state.covid_email_subject === "" ||
        this.state.covid_email_subject.trim() === ""
      ) {
        newInputFieldClass.covid_email_subjectClass =
          "newInputField field_error";
        error = true;
      }

      if (
        typeof this.state.covid_email_body === undefined ||
        this.state.covid_email_body === null ||
        this.state.covid_email_body === "" ||
        this.state.covid_email_body.trim() === ""
      ) {
        newInputFieldClass.covid_email_bodyClass =
          "editor_textarea editor-preview field_error";
        error = true;
      }
    }

    if (tab === TAB_TYPES.appointmentReschedule) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.appointment_reschedule_sms || ""),
        ...findBadWordsIn(this.state.appointment_reschedule_email || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
      if (
        typeof this.state.appointment_reschedule_sms === undefined ||
        this.state.appointment_reschedule_sms === null ||
        this.state.appointment_reschedule_sms === "" ||
        this.state.appointment_reschedule_sms.trim() === ""
      ) {
        newInputFieldClass.appointment_reschedule_smsClass =
          "newtextareaField field_error";
        error = true;
      }

      if (
        typeof this.state.appointment_reschedule_email === undefined ||
        this.state.appointment_reschedule_email === null ||
        this.state.appointment_reschedule_email === "" ||
        this.state.appointment_reschedule_email.trim() === ""
      ) {
        newInputFieldClass.appointment_reschedule_emailClass =
          "editor_textarea editor-preview field_error";
        error = true;
      }
    }

    if (tab === TAB_TYPES.surveyEmail) {
      const badWords = _.uniq([
        ...findBadWordsIn(this.state.appointment_survey_sms || ""),
        ...findBadWordsIn(this.state.survey_thankyou_message || ""),
        ...findBadWordsIn(this.state.appointment_survey_email || ""),
      ]);
      if (badWords.length > 0) {
        return uiNotification.error(tCommon("error.badWords"));
      }
      if (
        typeof this.state.appointment_survey_email === undefined ||
        this.state.appointment_survey_email === null ||
        this.state.appointment_survey_email === "" ||
        this.state.appointment_survey_email.trim() === ""
      ) {
        newInputFieldClass.aappointment_survey_emailClass =
          "editor_textarea editor-preview field_error";
        error = true;
      }
      if (
        typeof this.state.appointment_survey_sms === undefined ||
        this.state.appointment_survey_sms === null ||
        this.state.appointment_survey_sms === "" ||
        this.state.appointment_survey_sms.trim() === ""
      ) {
        newInputFieldClass.appointment_survey_smsClass =
          "newtextareaField field_error";
        error = true;
      }
    }

    this.setState(newInputFieldClass);

    if (error) {
      return;
    }
    let formData = {};
    formData.account_details = {};
    if (tab === TAB_TYPES.specialInstruction) {
      formData.clinics = [];
      if (
        this.state.clinicList != undefined &&
        this.state.clinicList.length > 0
      ) {
        formData.clinics = this.state.clinicList.map((obj) => {
          return {
            id: obj.id,
            email_special_instructions:
              this.state["email_special_instructions-" + obj.id],
          };
        });
      }
      formData.account_details.account_preference = {};
      formData.account_details.account_preference.from_email =
        this.state.from_email;
    }

    if (tab === TAB_TYPES.bookingConfirmation) {
      formData.account_details.appointment_booking_email =
        this.state.appointment_booking_email;
      formData.account_details.appointment_booking_sms =
        this.state.appointment_booking_sms;
      formData.account_details.appointment_booking_status =
        this.state.appointment_booking_status;
    }
    if (tab === TAB_TYPES.virtualBookingConfirmation) {
      formData.account_details.appointment_virtual_booking_email =
        this.state.appointment_virtual_booking_email;
      formData.account_details.appointment_virtual_booking_sms =
        this.state.appointment_virtual_booking_sms;
      formData.account_details.appointment_virtual_booking_status =
        this.state.appointment_virtual_booking_status;
    }
    if (tab === TAB_TYPES.cancellationConfirmation) {
      formData.account_details.appointment_canceled_email =
        this.state.appointment_canceled_email;
      formData.account_details.appointment_canceled_sms =
        this.state.appointment_canceled_sms;
      formData.account_details.appointment_cancel_status =
        this.state.appointment_cancel_status;
    }
    if (tab === TAB_TYPES.appointmentReminder) {
      formData.account_details.account_preference = {};
      formData.account_details.account_preference.reminder_email_subject =
        this.state.reminder_email_subject;
      formData.account_details.account_preference.reminder_email =
        this.state.reminder_email;
      formData.account_details.account_preference.reminder_sms =
        this.state.reminder_sms;
      formData.account_details.appointment_reminder_status =
        this.state.appointment_reminder_status;
    }
    if (tab === TAB_TYPES.virtualAppointmentReminder) {
      formData.account_details.account_preference = {};
      formData.account_details.account_preference.virtual_reminder_email_subject =
        this.state.virtual_reminder_email_subject;
      formData.account_details.account_preference.virtual_reminder_email =
        this.state.virtual_reminder_email;
      formData.account_details.account_preference.virtual_reminder_sms =
        this.state.virtual_reminder_sms;
      formData.account_details.appointment_virtual_reminder_status =
        this.state.appointment_virtual_reminder_status;
    }
    if (tab === TAB_TYPES.covidCommunication) {
      let account_preference = {};
      account_preference.covid_email_subject = this.state.covid_email_subject;
      account_preference.covid_email_body = this.state.covid_email_body;
      formData.account_details.account_preference = account_preference;
    }
    if (tab === TAB_TYPES.appointmentReschedule) {
      formData.account_details.appointment_reschedule_email =
        this.state.appointment_reschedule_email;
      formData.account_details.appointment_reschedule_sms =
        this.state.appointment_reschedule_sms;
      formData.account_details.appointment_reschedule_status =
        this.state.appointment_reschedule_status;
    }
    if (tab === TAB_TYPES.surveyEmail) {
      formData.account_details.account_preference = {};
      formData.account_details.appointment_survey_email =
        this.state.appointment_survey_email;
      formData.account_details.appointment_survey_sms =
        this.state.appointment_survey_sms;
      formData.account_details.account_preference.survey_thankyou_message =
        this.state.survey_thankyou_message;
    }

    this.setState({ showLoader: true });
    this.props.updateAppointment(formData);
  };

  changeTab = (tab) => {
    this.props.setQueryParamsState({
      t: tab,
    });
  };

  changeInternalTab = (mode) => {
    if (mode == "bookingEmail") {
      this.setState({ bookingEmail: true, bookingSMS: false });
    } else if (mode == "bookingSMS") {
      this.setState({ bookingSMS: true, bookingEmail: false });
    } else if (mode == "virtualBookingEmail") {
      this.setState({ virtualBookingEmail: true, virtualBookingSMS: false });
    } else if (mode == "virtualBookingSMS") {
      this.setState({ virtualBookingSMS: true, virtualBookingEmail: false });
    } else if (mode == "cancellationEmail") {
      this.setState({ cancellationEmail: true, cancellationSMS: false });
    } else if (mode == "cancellationSMS") {
      this.setState({ cancellationSMS: true, cancellationEmail: false });
    } else if (mode == "reminderEmail") {
      this.setState({ reminderEmail: true, reminderSMS: false });
    } else if (mode == "reminderSMS") {
      this.setState({ reminderSMS: true, reminderEmail: false });
    } else if (mode == "virtualReminderEmail") {
      this.setState({ virtualReminderEmail: true, virtualReminderSMS: false });
    } else if (mode == "virtualReminderSMS") {
      this.setState({ virtualReminderSMS: true, virtualReminderEmail: false });
    } else if (mode == "rescheduleEmail") {
      this.setState({ rescheduleEmail: true, rescheduleSMS: false });
    } else if (mode == "rescheduleSMS") {
      this.setState({ rescheduleSMS: true, rescheduleEmail: false });
    } else if (mode == "surveyEmailMsg") {
      this.setState({ surveyEmailMsg: true, surveySMS: false });
    } else if (mode == "surveySMS") {
      this.setState({ surveyEmailMsg: false, surveySMS: true });
    }
  };

  addCovidSchedullingRow = () => {
    let covidEmailSchedulling = this.state.covidEmailSchedulling;
    if (covidEmailSchedulling && covidEmailSchedulling.length >= 50) {
      return false;
    }
    let error = false;
    covidEmailSchedulling.map((schedulling) => {
      if (
        typeof schedulling.covid_email_type === undefined ||
        schedulling.covid_email_type === null ||
        schedulling.covid_email_type === ""
      ) {
        schedulling.covid_email_typeClass =
          "text-inline-select select-fill-box filled-border field-error";
        error = true;
      } else if (schedulling.covid_email_type) {
        schedulling.covid_email_typeClass =
          "text-inline-select select-fill-box filled-border";
      }

      if (
        typeof schedulling.covid_email_days === undefined ||
        schedulling.covid_email_days === null ||
        schedulling.covid_email_days === ""
      ) {
        schedulling.covid_email_daysClass =
          "text-inline-select select-fill-box filled-border field-error";
        error = true;
      } else if (schedulling.covid_email_days) {
        schedulling.covid_email_daysClass =
          "text-inline-select select-fill-box filled-border";
      }
    });
    if (!error) {
      covidEmailSchedulling.unshift(initCovidEmailScheduling());
    }
    this.setState({
      covidEmailSchedulling: covidEmailSchedulling,
      deleteCovideSchedullingId: 0,
      editCovideSchedullingId: 0,
    });
  };

  cancelCovidSchedullingRow = (index) => {
    let covidEmailSchedulling = this.state.covidEmailSchedulling;
    let schedulling = covidEmailSchedulling[index];
    schedulling.isEditFlag = false;
    schedulling.covid_email_type = schedulling.covid_email_type_temp;
    schedulling.covid_email_days = schedulling.covid_email_days_temp;
    schedulling.covid_email_daysClass =
      "text-inline-select select-fill-box filled-border";
    schedulling.covid_email_typeClass =
      "text-inline-select select-fill-box filled-border";

    covidEmailSchedulling[index] = schedulling;
    this.setState({
      covidEmailSchedulling: covidEmailSchedulling,
      deleteCovideSchedullingId: 0,
      editCovideSchedullingId: 0,
    });
  };

  editCovidSchedullingRow = (index) => {
    let covidEmailSchedulling = this.state.covidEmailSchedulling;
    let schedulling = covidEmailSchedulling[index];
    schedulling.isEditFlag = true;
    covidEmailSchedulling[index] = schedulling;
    this.setState({
      covidEmailSchedulling: covidEmailSchedulling,
      deleteCovideSchedullingId: 0,
      editCovideSchedullingId: 0,
    });
  };

  saveCovidSchedullingRow = (index, obj, event) => {
    event.preventDefault();
    let error = false;
    let errorMsg = "";

    let schedulling = obj;
    if (
      typeof schedulling.covid_email_type === undefined ||
      schedulling.covid_email_type === null ||
      schedulling.covid_email_type === ""
    ) {
      schedulling.covid_email_typeClass =
        "text-inline-select select-fill-box filled-border field-error";
      error = true;
    } else if (schedulling.covid_email_type) {
      schedulling.covid_email_typeClass =
        "text-inline-select select-fill-box filled-border";
    }

    if (
      typeof schedulling.covid_email_days === undefined ||
      schedulling.covid_email_days === null ||
      schedulling.covid_email_days === ""
    ) {
      schedulling.covid_email_daysClass =
        "text-inline-select select-fill-box filled-border field-error";
      error = true;
    } else if (schedulling.covid_email_days) {
      schedulling.covid_email_daysClass =
        "text-inline-select select-fill-box filled-border";
    }

    let covidEmailSchedulling = this.state.covidEmailSchedulling;
    if (covidEmailSchedulling.length > 1) {
      covidEmailSchedulling.map((obj1, idx1) => {
        if (
          schedulling.covid_email_type &&
          schedulling.covid_email_days &&
          index != idx1 &&
          obj1.covid_email_type == schedulling.covid_email_type &&
          obj1.covid_email_days == schedulling.covid_email_days
        ) {
          error = true;
          errorMsg = this.state.globalLang.covid_combination_already_exists
            ? this.state.globalLang.covid_combination_already_exists
            : "This schedule combination already exists";
        }
      });
    }

    if (error) {
      covidEmailSchedulling[index] = schedulling;
      this.setState({ covidEmailSchedulling: covidEmailSchedulling });
      if (errorMsg) {
        uiNotification.clear();
        uiNotification.error(errorMsg);
      }
      return;
    }

    let formData = {};
    let schedule = {
      reminder_before: schedulling.covid_email_days,
      type: schedulling.covid_email_type,
      reminder_type: "days",
      is_custom: 0,
    };
    let editCovideSchedullingId = 0;
    if (schedulling.id) {
      schedule.id = schedulling.id;
      editCovideSchedullingId = schedulling.id;
    }
    formData.schedule = [schedule];

    this.setState({
      showLoader: true,
      editCovideSchedullingId: editCovideSchedullingId,
    });
    this.props.saveCovidEmailSchedulling(formData);
    return false;
  };

  deletCovidSchedullingRow = (index, obj) => {
    let covidEmailSchedulling = this.state.covidEmailSchedulling;
    if (obj && obj.id) {
      this.setState({
        showConfirmationModal: true,
        confirmationMsg: "Are you sure you want to delete this schedule?",
        confirmationActionType: "daleteCovidEmailSchedulling",
        deleteCovideSchedullingId: obj.id,
      });
      toggleBodyScroll(true);
    } else {
      covidEmailSchedulling.splice(index, 1);
      this.setState({
        covidEmailSchedulling: covidEmailSchedulling,
        deleteCovideSchedullingId: 0,
      });
    }
  };

  handleInputChangeSchedullingRow = (index, event) => {
    const target = event.target;
    let value = target.value;

    let covidEmailSchedulling = this.state.covidEmailSchedulling;
    let schedulling = covidEmailSchedulling[index];
    if (event.target.name === "covid_email_type") {
      schedulling.covid_email_type = value;
      if (typeof value === undefined || value === null || value === "") {
        schedulling.covid_email_typeClass =
          "text-inline-select select-fill-box filled-border field-error";
      } else if (value) {
        schedulling.covid_email_typeClass =
          "text-inline-select select-fill-box filled-border";
      }
    } else if (event.target.name === "covid_email_days") {
      schedulling.covid_email_days = value;
      if (typeof value === undefined || value === null || value === "") {
        schedulling.covid_email_daysClass =
          "text-inline-select select-fill-box filled-border field-error";
      } else if (value) {
        schedulling.covid_email_daysClass =
          "text-inline-select select-fill-box filled-border";
      }
    }
    covidEmailSchedulling[index] = schedulling;
    this.setState({ covidEmailSchedulling: covidEmailSchedulling });
  };

  onResetConfirmation = (resetState) => {
    toggleBodyScroll(false);
    this.setState(resetState);
  };

  confirmationAction = () => {
    toggleBodyScroll(false);
    if (this.state.confirmationActionType === "daleteCovidEmailSchedulling") {
      let formData = {
        id: this.state.deleteCovideSchedullingId,
      };
      this.setState({ showLoader: true });
      this.props.daleteCovidEmailSchedulling(formData);
    }
  };

  handleEditorChange = (childState) => {
    this.setState(childState);
  };

  render() {
    var clinicName = [];
    if (
      this.state.clinicList != undefined &&
      this.state.clinicList.length > 0
    ) {
      clinicName = this.state.clinicList.map((obj, idx) => {
        return (
          <div className="col-sm-6 col-xs-12" key={idx}>
            <div className="newInputFileldOuter m-b-40">
              <div className="newInputLabel">{obj.clinic_name}</div>
              <input type="hidden" name="clinic_ids[]" defaultValue={1} />
              <textarea
                name={"email_special_instructions-" + obj.id}
                className="newtextareaField"
                placeholder={
                  "Clinic " +
                  this.state.langData["appointments"]["app-instructions"]
                }
                value={this.state["email_special_instructions-" + obj.id] || ""}
                cols={30}
                rows={6}
                style={{ height: "130px", resize: "none" }}
                onChange={this.handleInputChange}
              />{" "}
            </div>
          </div>
        );
      });
    }

    const tab = this.props.queryParamsState?.t;

    return (
      <div id="content">
        <div className="container-fluid content">
          <AppointmentConfigNavbar activeCommunication={true} />
          {this.state.showConfirmationModal === true && (
            <ConfirmationModal
              showConfirmationModal={this.state.showConfirmationModal}
              confirmationMsg={this.state.confirmationMsg}
              confirmationAction={this.confirmationAction}
              onResetConfirmation={this.onResetConfirmation}
            />
          )}
          <form
            name="appointment_Sms_And_Email-form"
            className="nobottommargin"
            action="#"
            method="post"
            onSubmit={this.handleSubmit}
          >
            <div className="setting-setion full-width">
              <div className="appointment-container full-width">
                <div className="juvly-title">
                  {this.state.langData["appointments"]["app-communications"]}
                </div>

                <ul className="appointment-tabs communication-tabs">
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.specialInstruction,
                      )}
                      className={
                        tab === TAB_TYPES.specialInstruction ? "active" : ""
                      }
                    >
                      {
                        this.state.langData["appointments"][
                          "app-special-instructions"
                        ]
                      }
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.bookingConfirmation,
                      )}
                      className={
                        tab === TAB_TYPES.bookingConfirmation ? "active" : ""
                      }
                    >
                      In-Person{" "}
                      {this.state.langData["appointments"]["app-booking-email"]}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.virtualBookingConfirmation,
                      )}
                      className={
                        tab === TAB_TYPES.virtualBookingConfirmation
                          ? "active"
                          : ""
                      }
                    >
                      Virtual{" "}
                      {this.state.langData["appointments"]["app-booking-email"]}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.cancellationConfirmation,
                      )}
                      className={
                        tab === TAB_TYPES.cancellationConfirmation
                          ? "active"
                          : ""
                      }
                    >
                      {
                        this.state.langData["appointments"][
                          "app-cancellation-email"
                        ]
                      }
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.appointmentReminder,
                      )}
                      className={
                        tab === TAB_TYPES.appointmentReminder ? "active" : ""
                      }
                    >
                      In-Person{" "}
                      {
                        this.state.langData["appointments"][
                          "app-reminder-email"
                        ]
                      }
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.virtualAppointmentReminder,
                      )}
                      className={
                        tab === TAB_TYPES.virtualAppointmentReminder
                          ? "active"
                          : ""
                      }
                    >
                      Virtual{" "}
                      {
                        this.state.langData["appointments"][
                          "app-reminder-email"
                        ]
                      }
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.covidCommunication,
                      )}
                      className={
                        tab === TAB_TYPES.covidCommunication ? "active" : ""
                      }
                    >
                      {this.state.langData["appointments"]["app-covid-email"]}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(
                        this,
                        TAB_TYPES.appointmentReschedule,
                      )}
                      className={
                        tab === TAB_TYPES.appointmentReschedule ? "active" : ""
                      }
                    >
                      {
                        this.state.langData["appointments"][
                          "app-reshcedule-email"
                        ]
                      }
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.changeTab.bind(this, TAB_TYPES.surveyEmail)}
                      className={tab === TAB_TYPES.surveyEmail ? "active" : ""}
                    >
                      {this.state.langData["appointments"]["app-survey-email"]}
                    </a>
                  </li>
                </ul>

                <div
                  className={
                    tab === TAB_TYPES.specialInstruction
                      ? "specialInstruction"
                      : "no-display"
                  }
                >
                  <div className="row m-b-40">
                    <div className="col-md-5 col-xs-12">
                      <div className="newInputFileldOuter m-b-30">
                        <div className="newInputLabel">
                          {this.state.AppointmentEmailSMS_From_Email}
                          <span className="setting-require" />
                        </div>
                        <div className="setting-input-outer">
                          <input
                            type="text"
                            name="from_email"
                            className={
                              this.state.from_emailError === true
                                ? this.state.from_emailClassError
                                : this.state.from_emailClass
                            }
                            value={this.state.from_email || ""}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">{clinicName}</div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.bookingConfirmation
                      ? "bookingConfirmation"
                      : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className={
                          this.state.appointment_booking_status == true
                            ? this.state.checkboxOn
                            : this.state.checkboxClosed
                        }
                        id="book"
                      >
                        {
                          this.state
                            .AppointmentEmailSMS_Appointment_Booking_Confirmation
                        }
                        <a
                          title="Your Appointment Booking Confirmation Email/SMS"
                          className="help-icon m-t-0"
                        >
                          ?
                        </a>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name="appointment_booking_status"
                            className="setting-custom-switch-input"
                            checked={
                              this.state.appointment_booking_status
                                ? "checked"
                                : false
                            }
                            value={this.state.appointment_booking_status || ""}
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                        <span className="factor_status">
                          {this.state.appointment_booking_status
                            ? this.state.booking_status_enable
                            : this.state.booking_status_disable}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="appointment-tabs">
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "bookingEmail",
                            )}
                            className={this.state.bookingEmail ? "active" : ""}
                          >
                            {this.state.langData["appointments"]["app-"]}
                            {this.state.langData["appointments"]["app-email"]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "bookingSMS",
                            )}
                            className={this.state.bookingSMS ? "active" : ""}
                          >
                            {this.state.langData["appointments"]["app-"]}
                            {this.state.langData["appointments"]["app-sms"]}
                          </a>
                        </li>
                      </ul>

                      <div
                        className={`newInputFileldOuter m-b-40 ${
                          this.state.bookingEmail ? "prevEmailTemplate" : ""
                        }`}
                      >
                        <EditorWithPreview
                          name={"appointment_booking_email"}
                          placeholder={"Email Body"}
                          value={this.state.appointment_booking_email}
                          className={`${
                            this.state.appointment_booking_emailClass
                          } ${this.state.bookingEmail ? "" : "no-display"}`}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `YOUR APPOINTMENT IS CONFIRMED!`,
                          )}
                          defaultTemplateData={contentAppointmentBooked()}
                        />
                        <div
                          className={
                            this.state.bookingSMS
                              ? "newInputLabel"
                              : "no-display"
                          }
                        >
                          {
                            this.state.langData["appointments"][
                              "app-instructions"
                            ]
                          }
                        </div>
                        {this.state.bookingSMS && (
                          <>
                            <textarea
                              placeholder="SMS Message"
                              name="appointment_booking_sms"
                              value={this.state.appointment_booking_sms || ""}
                              rows={10}
                              className={
                                this.state.appointment_booking_smsClass
                              }
                              onChange={this.handleInputChange}
                              style={{ resize: "none" }}
                            />
                            <MaxCharCount
                              currentValue={
                                this.state.appointment_booking_sms || ""
                              }
                              maxCharCount={1300}
                              rightText={
                                <b>
                                  <i>
                                    {tCommon(
                                      "communication_character_count_info",
                                    )}
                                  </i>
                                </b>
                              }
                            />
                            <SmsChargeDisclaimer className="m-t-10" />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Provider_Name}:</b>{" "}
                          {`{{PROVIDERNAME}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Clinic_Instructions}
                            :
                          </b>
                          {`{{CLINICINSTRUCTIONS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Booked_Services}:
                          </b>
                          {`{{BOOKEDSERVICES}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Cancellation_Fee_Charge_Days
                            }
                            :
                          </b>
                          {`{{CANFEECHARGEDAYS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Cancellation_Fee}:
                          </b>
                          {`{{CANCELATIONFEES}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Business_Name}:</b>
                          {`{{BUSINESSNAME}}`}
                        </p>
                        <p>
                          <b>Patient Dashboard Login﻿:</b>
                          {`{{CLIENTPATIENTURL}}`}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.virtualBookingConfirmation
                      ? "virtualBookingConfirmation"
                      : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className={
                          this.state.appointment_virtual_booking_status == true
                            ? this.state.checkboxOn
                            : this.state.checkboxClosed
                        }
                        id="book"
                      >
                        {
                          this.state
                            .AppointmentEmailSMS_Appointment_Booking_Confirmation
                        }
                        <a
                          title="Your Appointment Booking Confirmation Email/SMS"
                          className="help-icon m-t-0"
                        >
                          ?
                        </a>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name="appointment_virtual_booking_status"
                            className="setting-custom-switch-input"
                            checked={
                              this.state.appointment_virtual_booking_status
                                ? "checked"
                                : false
                            }
                            value={
                              this.state.appointment_virtual_booking_status ||
                              ""
                            }
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                        <span className="factor_status">
                          {this.state.appointment_virtual_booking_status
                            ? this.state.booking_status_enable
                            : this.state.booking_status_disable}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="appointment-tabs">
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "virtualBookingEmail",
                            )}
                            className={
                              this.state.virtualBookingEmail ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-"]}
                            {this.state.langData["appointments"]["app-email"]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "virtualBookingSMS",
                            )}
                            className={
                              this.state.virtualBookingSMS ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-"]}
                            {this.state.langData["appointments"]["app-sms"]}
                          </a>
                        </li>
                      </ul>

                      <div
                        className={`newInputFileldOuter m-b-40 ${
                          this.state.virtualBookingEmail
                            ? "prevEmailTemplate"
                            : ""
                        }`}
                      >
                        <EditorWithPreview
                          name={"appointment_virtual_booking_email"}
                          placeholder={"Email Body"}
                          value={this.state.appointment_virtual_booking_email}
                          className={`${
                            this.state.appointment_virtual_booking_emailClass
                          } ${
                            this.state.virtualBookingEmail ? "" : "no-display"
                          }`}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `YOUR APPOINTMENT IS CONFIRMED!`,
                          )}
                          defaultTemplateData={contentAppointmentBookedVirtual()}
                        />
                        <div
                          className={
                            this.state.virtualBookingSMS
                              ? "newInputLabel"
                              : "no-display"
                          }
                        >
                          {
                            this.state.langData["appointments"][
                              "app-instructions"
                            ]
                          }
                        </div>
                        {this.state.virtualBookingSMS && (
                          <>
                            <textarea
                              placeholder="SMS Message"
                              name="appointment_virtual_booking_sms"
                              value={
                                this.state.appointment_virtual_booking_sms || ""
                              }
                              rows={10}
                              className={
                                this.state.appointment_virtual_booking_smsClass
                              }
                              onChange={this.handleInputChange}
                              style={{ resize: "none" }}
                            />
                            <MaxCharCount
                              currentValue={
                                this.state.appointment_virtual_booking_sms || ""
                              }
                              maxCharCount={1300}
                              rightText={
                                <b>
                                  <i>
                                    {tCommon(
                                      "communication_character_count_info",
                                    )}
                                  </i>
                                </b>
                              }
                            />
                            <SmsChargeDisclaimer className="m-t-10" />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Provider_Name}:</b>{" "}
                          {`{{PROVIDERNAME}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Clinic_Instructions}
                            :
                          </b>
                          {`{{CLINICINSTRUCTIONS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Booked_Services}:
                          </b>
                          {`{{BOOKEDSERVICES}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Cancellation_Fee_Charge_Days
                            }
                            :
                          </b>
                          {`{{CANFEECHARGEDAYS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Cancellation_Fee}:
                          </b>
                          {`{{CANCELATIONFEES}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Business_Name}:</b>
                          {`{{BUSINESSNAME}}`}
                        </p>
                        <p>
                          <b>Appointment Meeting Link:</b>
                          {`{{MEETINGLINK}}`}{" "}
                        </p>
                        <p>
                          <b>Patient Dashboard Login﻿:</b>
                          {`{{CLIENTPATIENTURL}}`}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.cancellationConfirmation
                      ? "cancellationConfirmation"
                      : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className={
                          this.state.appointment_cancel_status == true
                            ? this.state.checkboxOn
                            : this.state.checkboxClosed
                        }
                        id="cancel"
                      >
                        {
                          this.state
                            .AppointmentEmailSMS_Appointment_Cancellation_Confirmation
                        }
                        <a
                          title="Your Appointment Cancellation Confirmation Email/SMS"
                          className="help-icon m-t-0"
                        >
                          ?
                        </a>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name="appointment_cancel_status"
                            className="setting-custom-switch-input"
                            checked={
                              this.state.appointment_cancel_status
                                ? "checked"
                                : false
                            }
                            value={this.state.appointment_cancel_status || ""}
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                        <span className="factor_status">
                          {this.state.appointment_cancel_status
                            ? this.state.appointment_cancellation_enable
                            : this.state.appointment_cancellation_disable}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="appointment-tabs">
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "cancellationEmail",
                            )}
                            className={
                              this.state.cancellationEmail ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-email"]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "cancellationSMS",
                            )}
                            className={
                              this.state.cancellationSMS ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-sms"]}
                          </a>
                        </li>
                      </ul>
                      <div
                        className={`newInputFileldOuter m-b-40 ${
                          this.state.cancellationEmail ? "" : ""
                        }`}
                      >
                        <EditorWithPreview
                          name={"appointment_canceled_email"}
                          placeholder={"Email Body"}
                          value={this.state.appointment_canceled_email}
                          className={`${
                            this.state.appointment_canceled_emailClass
                          } ${
                            this.state.cancellationEmail ? "" : "no-display"
                          }`}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `YOUR APPOINTMENT IS CANCELLED!`,
                          )}
                        />
                        <div
                          className={
                            this.state.cancellationSMS
                              ? "newInputLabel"
                              : "no-display"
                          }
                        >
                          {
                            this.state.langData["appointments"][
                              "app-instructions"
                            ]
                          }
                        </div>
                        {this.state.cancellationSMS && (
                          <>
                            <textarea
                              className={
                                this.state.appointment_canceled_smsClass
                              }
                              placeholder="SMS Message"
                              id="appointment_canceled_sms"
                              value={this.state.appointment_canceled_sms || ""}
                              rows={10}
                              name="appointment_canceled_sms"
                              onChange={this.handleInputChange}
                              style={{ resize: "none" }}
                            />
                            <MaxCharCount
                              currentValue={
                                this.state.appointment_canceled_sms || ""
                              }
                              maxCharCount={1300}
                              rightText={
                                <b>
                                  <i>
                                    {tCommon(
                                      "communication_character_count_info",
                                    )}
                                  </i>
                                </b>
                              }
                            />
                            <SmsChargeDisclaimer className="m-t-10" />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Provider_Name}:</b>{" "}
                          {`{{PROVIDERNAME}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Clinic_Instructions}
                            :
                          </b>
                          {`{{CLINICINSTRUCTIONS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Booked_Services}:
                          </b>
                          {`{{BOOKEDSERVICES}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Cancellation_Fee_Charge_Days
                            }
                            :
                          </b>
                          {`{{CANFEECHARGEDAYS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Cancellation_Fee}:
                          </b>
                          {`{{CANCELATIONFEES}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Business_Name}:</b>
                          {`{{BUSINESSNAME}}`}
                        </p>
                        <p>
                          <b>Appointment Meeting Link:</b>
                          {`{{MEETINGLINK}}`}{" "}
                        </p>
                        <p>
                          <b>Patient Dashboard Login﻿:</b>
                          {`{{CLIENTPATIENTURL}}`}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.appointmentReminder
                      ? "appointmentReminder"
                      : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className={
                          this.state.appointment_reminder_status == true
                            ? this.state.checkboxOn
                            : this.state.checkboxClosed
                        }
                        id="reminder"
                      >
                        {this.state.AppointmentEmailSMS_Appointment_Reminder}
                        <a
                          title="Your Appointment Reminder Email/SMS"
                          className="help-icon m-t-0"
                        >
                          ?
                        </a>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name="appointment_reminder_status"
                            className="setting-custom-switch-input"
                            checked={
                              this.state.appointment_reminder_status
                                ? "checked"
                                : false
                            }
                            value={this.state.appointment_reminder_status || ""}
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                        <span className="factor_status">
                          {this.state.appointment_reminder_status
                            ? this.state.appointment_reminder_enable
                            : this.state.appointment_reminder_disable}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="appointment-tabs">
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "reminderEmail",
                            )}
                            className={this.state.reminderEmail ? "active" : ""}
                          >
                            {this.state.langData["appointments"]["app-email"]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "reminderSMS",
                            )}
                            className={this.state.reminderSMS ? "active" : ""}
                          >
                            {this.state.langData["appointments"]["app-sms"]}
                          </a>
                        </li>
                      </ul>
                      <div
                        className={
                          this.state.reminderEmail
                            ? "newInputFileldOuter m-b-30"
                            : "newInputFileldOuter m-b-30 no-display"
                        }
                      >
                        <div
                          className={
                            this.state.reminderEmail
                              ? "newInputLabel"
                              : "newInputLabel no-display"
                          }
                        >
                          Email Subject
                        </div>
                        <input
                          className={`${
                            this.state.reminder_email_subjectClass
                          } ${this.state.reminderEmail ? "" : "no-display"}`}
                          placeholder="Email Subject"
                          name="reminder_email_subject"
                          value={this.state.reminder_email_subject || ""}
                          type="text"
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div
                        className={`newInputFileldOuter m-b-40 ${
                          this.state.reminderEmail ? "prevEmailTemplate" : ""
                        }`}
                      >
                        <EditorWithPreview
                          name={"reminder_email"}
                          placeholder={"Email Body"}
                          value={this.state.reminder_email}
                          className={`${this.state.reminder_emailClass} ${
                            this.state.reminderEmail ? "" : "no-display"
                          }`}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `UPCOMING APPOINTMENT REMINDER`,
                          )}
                          defaultTemplateData={contentAppointmentReminder()}
                        />
                        <div
                          className={
                            this.state.reminderSMS
                              ? "newInputLabel"
                              : "no-display"
                          }
                        >
                          {
                            this.state.langData["appointments"][
                              "app-instructions"
                            ]
                          }
                        </div>
                        {this.state.reminderSMS && (
                          <>
                            <textarea
                              className={this.state.reminder_smsClass}
                              placeholder="SMS Message"
                              name="reminder_sms"
                              id="reminder_sms"
                              rows={10}
                              value={this.state.reminder_sms || ""}
                              onChange={this.handleInputChange}
                              style={{ height: "162px", resize: "none" }}
                            />
                            <MaxCharCount
                              currentValue={this.state.reminder_sms || ""}
                              maxCharCount={1300}
                              rightText={
                                <b>
                                  <i>
                                    {tCommon(
                                      "communication_character_count_info",
                                    )}
                                  </i>
                                </b>
                              }
                            />
                            <SmsChargeDisclaimer className="m-t-10" />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Provider_Name}:</b>{" "}
                          {`{{PROVIDERNAME}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Clinic_Instructions}
                            :
                          </b>
                          {`{{CLINICINSTRUCTIONS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Booked_Services}:
                          </b>
                          {`{{BOOKEDSERVICES}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Cancellation_Fee_Charge_Days
                            }
                            :
                          </b>
                          {`{{CANFEECHARGEDAYS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Cancellation_Fee}:
                          </b>
                          {`{{CANCELATIONFEES}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Business_Name}:</b>
                          {`{{BUSINESSNAME}}`}
                        </p>
                        <p>
                          <b>Patient Dashboard Login﻿:</b>
                          {`{{CLIENTPATIENTURL}}`}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.virtualAppointmentReminder
                      ? "virtualAppointmentReminder"
                      : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className={
                          this.state.appointment_virtual_reminder_status == true
                            ? this.state.checkboxOn
                            : this.state.checkboxClosed
                        }
                        id="reminder"
                      >
                        {this.state.AppointmentEmailSMS_Appointment_Reminder}
                        <a
                          title="Your Appointment Reminder Email/SMS"
                          className="help-icon m-t-0"
                        >
                          ?
                        </a>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name="appointment_virtual_reminder_status"
                            className="setting-custom-switch-input"
                            checked={
                              this.state.appointment_virtual_reminder_status
                                ? "checked"
                                : false
                            }
                            value={
                              this.state.appointment_virtual_reminder_status ||
                              ""
                            }
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                        <span className="factor_status">
                          {this.state.appointment_virtual_reminder_status
                            ? this.state.appointment_reminder_enable
                            : this.state.appointment_reminder_disable}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="appointment-tabs">
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "virtualReminderEmail",
                            )}
                            className={
                              this.state.virtualReminderEmail ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-email"]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "virtualReminderSMS",
                            )}
                            className={
                              this.state.virtualReminderSMS ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-sms"]}
                          </a>
                        </li>
                      </ul>
                      <div
                        className={
                          this.state.virtualReminderEmail
                            ? "newInputFileldOuter m-b-30"
                            : "newInputFileldOuter m-b-30 no-display"
                        }
                      >
                        <div
                          className={
                            this.state.virtualReminderEmail
                              ? "newInputLabel"
                              : "newInputLabel no-display"
                          }
                        >
                          Email Subject
                        </div>
                        <input
                          className={`${
                            this.state.virtual_reminder_email_subjectClass
                          } ${
                            this.state.virtualReminderEmail ? "" : "no-display"
                          }`}
                          placeholder="Email Subject"
                          name="virtual_reminder_email_subject"
                          value={
                            this.state.virtual_reminder_email_subject || ""
                          }
                          type="text"
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div
                        className={`newInputFileldOuter m-b-40 ${
                          this.state.virtualReminderEmail
                            ? "prevEmailTemplate"
                            : ""
                        }`}
                      >
                        <EditorWithPreview
                          name={"virtual_reminder_email"}
                          placeholder={"Email Body"}
                          value={this.state.virtual_reminder_email}
                          className={`${
                            this.state.virtual_reminder_emailClass
                          } ${
                            this.state.virtualReminderEmail ? "" : "no-display"
                          }`}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `UPCOMING APPOINTMENT REMINDER`,
                          )}
                          defaultTemplateData={contentAppointmentReminderVirtual()}
                        />
                        <div
                          className={
                            this.state.virtualReminderSMS
                              ? "newInputLabel"
                              : "no-display"
                          }
                        >
                          {
                            this.state.langData["appointments"][
                              "app-instructions"
                            ]
                          }
                        </div>
                        {this.state.virtualReminderSMS && (
                          <>
                            <textarea
                              className={this.state.virtual_reminder_smsClass}
                              placeholder="SMS Message"
                              name="virtual_reminder_sms"
                              id="virtual_reminder_sms"
                              rows={10}
                              value={this.state.virtual_reminder_sms || ""}
                              onChange={this.handleInputChange}
                              style={{ height: "162px", resize: "none" }}
                            />
                            <MaxCharCount
                              currentValue={
                                this.state.virtual_reminder_sms || ""
                              }
                              maxCharCount={1300}
                              rightText={
                                <b>
                                  <i>
                                    {tCommon(
                                      "communication_character_count_info",
                                    )}
                                  </i>
                                </b>
                              }
                            />
                            <SmsChargeDisclaimer className="m-t-10" />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Provider_Name}:</b>{" "}
                          {`{{PROVIDERNAME}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Clinic_Instructions}
                            :
                          </b>
                          {`{{CLINICINSTRUCTIONS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Booked_Services}:
                          </b>
                          {`{{BOOKEDSERVICES}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Cancellation_Fee_Charge_Days
                            }
                            :
                          </b>
                          {`{{CANFEECHARGEDAYS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Cancellation_Fee}:
                          </b>
                          {`{{CANCELATIONFEES}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Business_Name}:</b>
                          {`{{BUSINESSNAME}}`}
                        </p>
                        <p>
                          <b>Appointment Meeting Link:</b>
                          {`{{MEETINGLINK}}`}{" "}
                        </p>
                        <p>
                          <b>Patient Dashboard Login﻿:</b>
                          {`{{CLIENTPATIENTURL}}`}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.covidCommunication
                      ? "covidCommunication"
                      : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className={
                          this.state.covid_email_status == true
                            ? this.state.checkboxOn
                            : this.state.checkboxClosed
                        }
                        id="covidCommunicationToggle"
                      >
                        {this.state.AppointmentEmailSMS_Covide}
                        <a
                          title="Your Appointment COVID Communication Email"
                          className="help-icon m-t-0"
                        >
                          ?
                        </a>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name="covid_email_status"
                            className="setting-custom-switch-input"
                            checked={
                              this.state.covid_email_status ? "checked" : false
                            }
                            value={this.state.covid_email_status || ""}
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                        <span className="factor_status">
                          {this.state.covid_email_status
                            ? this.state.appointment_covide_communication_enable
                            : this.state
                                .appointment_covide_communication_disable}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className={"newInputFileldOuter m-b-20"}>
                        <div className={"newInputLabel"}>Email Subject</div>
                        <input
                          className={this.state.covid_email_subjectClass}
                          placeholder="Email Subject"
                          name="covid_email_subject"
                          value={this.state.covid_email_subject}
                          type="text"
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div className="newInputFileldOuter m-b-40 prevEmailTemplate">
                        <EditorWithPreview
                          name={"covid_email_body"}
                          placeholder={"Email Body"}
                          value={this.state.covid_email_body}
                          className={this.state.covid_email_bodyClass}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `IMPORTANT INFORMATION REGARDING YOUR UPCOMING APPOINTMENT`,
                          )}
                          defaultTemplateData={contentCovidCommunication()}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Provider_Name}:</b>{" "}
                          {`{{PROVIDERNAME}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Clinic_Instructions}
                            :
                          </b>
                          {`{{CLINICINSTRUCTIONS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Booked_Services}:
                          </b>
                          {`{{BOOKEDSERVICES}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Cancellation_Fee_Charge_Days
                            }
                            :
                          </b>
                          {`{{CANFEECHARGEDAYS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Cancellation_Fee}:
                          </b>
                          {`{{CANCELATIONFEES}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Business_Name}:</b>
                          {`{{BUSINESSNAME}}`}
                        </p>
                        <p>
                          <b>Appointment Meeting Link:</b>
                          {`{{MEETINGLINK}}`}{" "}
                        </p>
                        <p>
                          <b>Patient Dashboard Login﻿:</b>
                          {`{{CLIENTPATIENTURL}}`}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.covidCommunication
                      ? "appointment-container no-padding"
                      : "no-display"
                  }
                >
                  <div className="membership-title no-padding m-b-20">
                    {"Email Scheduling"}

                    <a
                      className="new-blue-btn no-width"
                      onClick={this.addCovidSchedullingRow}
                    >
                      <i className="fa fa-plus -m-l-5"></i> Add
                    </a>
                  </div>

                  <div className={"covidCommunication"}>
                    {this.state.covidEmailSchedulling &&
                      this.state.covidEmailSchedulling.length > 0 &&
                      this.state.covidEmailSchedulling.map((obj, idx) => {
                        return (
                          <div
                            key={"covidEmailSchedulling-" + idx}
                            className="covidColOuter"
                          >
                            <div className="covidCol">
                              <div className="covid-scheduling">
                                <div
                                  className={
                                    obj.id && obj.isEditFlag === false
                                      ? "covid-scheduling-view"
                                      : "covid-scheduling-edit"
                                  }
                                >
                                  <div className="p-text">
                                    {"Send Email "}
                                    {obj.id && obj.isEditFlag === false ? (
                                      <React.Fragment>
                                        <div className="text-inline-select select-fill-box filled-border covidFilledView no-margin no-padding">
                                          {obj.covid_email_type}
                                        </div>
                                        &nbsp;
                                        <div className="text-inline-select select-fill-box filled-border covidFilledView no-margin no-padding">
                                          {obj.covid_email_days}
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <select
                                          className={obj.covid_email_typeClass}
                                          name="covid_email_type"
                                          value={obj.covid_email_type}
                                          onChange={this.handleInputChangeSchedullingRow.bind(
                                            this,
                                            idx,
                                          )}
                                        >
                                          <option value="">Select</option>
                                          <option value="after">after</option>
                                          <option value="before">before</option>
                                        </select>
                                        <select
                                          className={obj.covid_email_daysClass}
                                          name="covid_email_days"
                                          value={obj.covid_email_days}
                                          onChange={this.handleInputChangeSchedullingRow.bind(
                                            this,
                                            idx,
                                          )}
                                        >
                                          {this.state.daysList}
                                        </select>
                                      </React.Fragment>
                                    )}
                                    {" days of appointment"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="covidCol covidColAciton">
                              {obj.id && obj.isEditFlag === true && (
                                <React.Fragment>
                                  <a
                                    className="easy-link p-t-5"
                                    onClick={this.saveCovidSchedullingRow.bind(
                                      this,
                                      idx,
                                      obj,
                                    )}
                                  >
                                    <i className="fa fa-save m-r-5 m-t-2"></i>{" "}
                                    Save
                                  </a>
                                  <a
                                    className="easy-link p-t-5"
                                    onClick={this.cancelCovidSchedullingRow.bind(
                                      this,
                                      idx,
                                      obj,
                                    )}
                                  >
                                    <i className="fa fa-times m-r-5 m-t-2"></i>{" "}
                                    Cancel
                                  </a>
                                </React.Fragment>
                              )}

                              {obj.id && obj.isEditFlag === false && (
                                <React.Fragment>
                                  <a
                                    className="easy-link"
                                    onClick={this.editCovidSchedullingRow.bind(
                                      this,
                                      idx,
                                      obj,
                                    )}
                                  >
                                    <i className="fa fa-pencil-alt m-r-5 m-t-2"></i>{" "}
                                    Edit
                                  </a>
                                  <a
                                    className="easy-link"
                                    onClick={this.deletCovidSchedullingRow.bind(
                                      this,
                                      idx,
                                      obj,
                                    )}
                                  >
                                    <i className="fa fa-trash-alt m-r-5 m-t-2"></i>{" "}
                                    Delete
                                  </a>
                                </React.Fragment>
                              )}

                              {(obj.id == undefined || obj.id <= 0) && (
                                <React.Fragment>
                                  <a
                                    className="easy-link p-t-5"
                                    onClick={this.saveCovidSchedullingRow.bind(
                                      this,
                                      idx,
                                      obj,
                                    )}
                                  >
                                    <i className="fa fa-save m-r-5 m-t-2"></i>{" "}
                                    Save
                                  </a>
                                  <a
                                    className="easy-link p-t-5"
                                    onClick={this.deletCovidSchedullingRow.bind(
                                      this,
                                      idx,
                                      obj,
                                    )}
                                  >
                                    <i className="fa fa-trash-alt m-r-5 m-t-2"></i>{" "}
                                    Delete
                                  </a>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.appointmentReschedule
                      ? "appointmentReschedule"
                      : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className={
                          this.state.appointment_reschedule_status == true
                            ? this.state.checkboxOn
                            : this.state.checkboxClosed
                        }
                        id="reschedule"
                      >
                        {
                          this.state
                            .AppointmentEmailSMS_Appointment_Reschedule_Confirmation
                        }
                        <a
                          title="Your Appointment Reschedule Confirmation Email/SMS"
                          className="help-icon m-t-0"
                        >
                          ?
                        </a>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name="appointment_reschedule_status"
                            className="setting-custom-switch-input"
                            checked={
                              this.state.appointment_reschedule_status
                                ? "checked"
                                : false
                            }
                            value={
                              this.state.appointment_reschedule_status || ""
                            }
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                        <span className="factor_status">
                          {this.state.appointment_reschedule_status
                            ? this.state.appointment_reschedule_enable
                            : this.state.appointment_reschedule_disable}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <ul className="appointment-tabs">
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "rescheduleEmail",
                            )}
                            className={
                              this.state.rescheduleEmail ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-email"]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "rescheduleSMS",
                            )}
                            className={this.state.rescheduleSMS ? "active" : ""}
                          >
                            {this.state.langData["appointments"]["app-sms"]}
                          </a>
                        </li>
                      </ul>
                      <div
                        className={`newInputFileldOuter m-b-40 ${
                          this.state.rescheduleEmail ? "" : ""
                        }`}
                      >
                        <EditorWithPreview
                          name={"appointment_reschedule_email"}
                          placeholder={"Email Body"}
                          value={this.state.appointment_reschedule_email}
                          className={`${
                            this.state.appointment_reschedule_emailClass
                          } ${this.state.rescheduleEmail ? "" : "no-display"}`}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `YOUR APPOINTMENT IS RESCHEDULED!`,
                          )}
                        />
                        <div
                          className={
                            this.state.rescheduleSMS
                              ? "newInputLabel"
                              : "no-display"
                          }
                        >
                          {
                            this.state.langData["appointments"][
                              "app-instructions"
                            ]
                          }
                        </div>
                        {this.state.rescheduleSMS && (
                          <>
                            <textarea
                              className={
                                this.state.appointment_reschedule_smsClass
                              }
                              placeholder="SMS Message"
                              id="appointment_reschedule_sms"
                              value={
                                this.state.appointment_reschedule_sms || ""
                              }
                              rows={10}
                              name="appointment_reschedule_sms"
                              onChange={this.handleInputChange}
                              style={{ height: "162px", resize: "none" }}
                            />
                            <MaxCharCount
                              currentValue={
                                this.state.appointment_reschedule_sms || ""
                              }
                              maxCharCount={1300}
                              rightText={
                                <b>
                                  <i>
                                    {tCommon(
                                      "communication_character_count_info",
                                    )}
                                  </i>
                                </b>
                              }
                            />
                            <SmsChargeDisclaimer className="m-t-10" />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Provider_Name}:</b>{" "}
                          {`{{PROVIDERNAME}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Clinic_Instructions}
                            :
                          </b>
                          {`{{CLINICINSTRUCTIONS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Booked_Services}:
                          </b>
                          {`{{BOOKEDSERVICES}}`}{" "}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Cancellation_Fee_Charge_Days
                            }
                            :
                          </b>
                          {`{{CANFEECHARGEDAYS}}`}
                        </p>
                        <p>
                          <b>
                            {this.state.AppointmentEmailSMS_Cancellation_Fee}:
                          </b>
                          {`{{CANCELATIONFEES}}`}
                        </p>
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Business_Name}:</b>
                          {`{{BUSINESSNAME}}`}
                        </p>
                        <p>
                          <b>Appointment Meeting Link:</b>
                          {`{{MEETINGLINK}}`}{" "}
                        </p>
                        <p>
                          <b>Patient Dashboard Login﻿:</b>
                          {`{{CLIENTPATIENTURL}}`}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === TAB_TYPES.surveyEmail ? "surveyEmail" : "no-display"
                  }
                >
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <ul className="appointment-tabs">
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "surveyEmailMsg",
                            )}
                            className={
                              this.state.surveyEmailMsg ? "active" : ""
                            }
                          >
                            {this.state.langData["appointments"]["app-email"]}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.changeInternalTab.bind(
                              this,
                              "surveySMS",
                            )}
                            className={this.state.surveySMS ? "active" : ""}
                          >
                            {this.state.langData["appointments"]["app-sms"]}
                          </a>
                        </li>
                      </ul>
                      <div
                        className={`newInputFileldOuter m-b-40 ${
                          this.state.surveyEmailMsg ? "" : ""
                        }`}
                      >
                        <EditorWithPreview
                          name={"appointment_survey_email"}
                          placeholder={"Email Body"}
                          value={this.state.appointment_survey_email}
                          className={`${
                            this.state.appointment_survey_emailClass
                          } ${this.state.surveyEmailMsg ? "" : "no-display"}`}
                          handleEditorChange={this.handleEditorChange}
                          showPreviewButton={true}
                          prePostHTML={this.state.email_header_footer.replace(
                            /{{APPOINTMENT_EMAIL_HEADING}}/g,
                            `SURVEY REGARDING YOUR APPOINTMENT`,
                          )}
                        />
                        <div
                          className={
                            this.state.surveySMS
                              ? "newInputLabel"
                              : "no-display"
                          }
                        >
                          {
                            this.state.langData["appointments"][
                              "app-instructions"
                            ]
                          }
                        </div>
                        {this.state.surveySMS && (
                          <>
                            <textarea
                              className={this.state.appointment_survey_smsClass}
                              placeholder="SMS Message"
                              name="appointment_survey_sms"
                              id="appointment_survey_sms"
                              rows={10}
                              value={this.state.appointment_survey_sms || ""}
                              onChange={this.handleInputChange}
                              style={{ height: "162px", resize: "none" }}
                            />
                            <MaxCharCount
                              currentValue={
                                this.state.appointment_survey_sms || ""
                              }
                              maxCharCount={1300}
                              rightText={
                                <b>
                                  <i>
                                    {tCommon(
                                      "communication_character_count_info",
                                    )}
                                  </i>
                                </b>
                              }
                            />
                            <SmsChargeDisclaimer className="m-t-10" />
                          </>
                        )}
                      </div>
                      <div className="newInputFileldOuter m-b-40">
                        <div className="newInputLabel">
                          {
                            this.state.langData["appointments"][
                              "app-survey-thankyou"
                            ]
                          }
                        </div>
                        <textarea
                          onChange={this.handleInputChange}
                          id="survey_thankyou_message"
                          placeholder="Content"
                          name="survey_thankyou_message"
                          rows="10"
                          value={this.state.survey_thankyou_message}
                          className="newtextareaField"
                          style={{ height: "210px", resize: "none" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="AppointmentSubtitle">
                        {this.state.langData["appointments"]["app-snippets"]}
                      </div>
                      <div className="setting-variable snippets m-b-40">
                        <p>
                          <b>{this.state.AppointmentEmailSMS_Patient_Name}:</b>{" "}
                          {`{{PATIENTNAME}}`}
                        </p>
                        <p>
                          <b>Provider Name:</b> {`{{PROVIDERNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Appointment_Date_Time
                            }
                            :
                          </b>{" "}
                          {`{{APPOINTMENTDATETIME}}`} &nbsp;&nbsp;
                        </p>
                        <p>
                          <b>{this.state.clinics_Clinic_Name}:</b>
                          {`{{CLINICNAME}}`}
                        </p>
                        <p>
                          <b>
                            {
                              this.state
                                .AppointmentEmailSMS_Special_Clinic_Location
                            }
                            :
                          </b>
                          {`{{CLINICLOCATION}}`}
                        </p>
                        <p>
                          <b>Survey Name:</b>
                          {`{{SURVEYNAME}}`}
                        </p>
                        <p>
                          <b>Survey Service:</b>
                          {`{{SURVEYSERVICE}}`}
                        </p>
                        <p>
                          <b>Survey Url:</b>
                          {`{{SURVEYURL}}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.handleSubmit}
                >
                  Save
                </a>
              </div>

              <div
                className={
                  this.state.showLoader
                    ? "new-loader text-left displayBlock full-width"
                    : "new-loader text-left"
                }
              >
                <div className="loader-outer">
                  <img
                    alt=""
                    id="loader-outer"
                    src="/images/Eclipse.gif"
                    className="loader-img"
                  />
                  <div id="modal-confirm-text" className="popup-subtitle">
                    {this.state.clinic_Please_Wait}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.SettingReducer.action === "APPOINTMENT_GET") {
    uiNotification.clear();
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.appointmentData = state.SettingReducer.data;
      returnState.appointmentDataTimeStamp = new Date();
    }
  }

  if (state.SettingReducer.action === "APPOINTMENT_UPDATE") {
    uiNotification.clear();
    const tab = queryString.parse(window.location.search)?.t;

    if (state.SettingReducer.data.status != 200) {
      const message = state.SettingReducer.data.message;

      if (Object.keys(TAB_NAME_BY_SNIPPET_VARIABLE_ERROR).includes(message)) {
        uiNotification.error(
          snippetVariableErrorTemplate(
            tab === TAB_TYPES.specialInstruction
              ? `for ${TAB_NAME_BY_SNIPPET_VARIABLE_ERROR?.[message]}`
              : "",
          ),
        );
      } else {
        uiNotification.error(
          languageData.global[state.SettingReducer.data.message] ||
            "Unexpected error. Try again later",
        );
      }

      returnState.showLoader = false;
    } else {
      returnState.appointmentDataTimeStamp = new Date();
      returnState.appointmentData = state.SettingReducer.data;
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
    }
  }
  if (state.SettingReducer.action === "SAVE_COVID_EMAIL_SCHEDULLING") {
    uiNotification.clear();
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.isReloadSchedullingList = true;
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
    }
  }
  if (state.SettingReducer.action === "DELETE_COVID_EMAIL_SCHEDULLING") {
    uiNotification.clear();
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.isReloadSchedullingList = true;
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
    }
  }
  if (state.SettingReducer.action === "GET_COVID_EMAIL_SCHEDULLING") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.covidSchedullingData = state.SettingReducer.data.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAppointment: getAppointment,
      updateAppointment: updateAppointment,
      saveCovidEmailSchedulling: saveCovidEmailSchedulling,
      daleteCovidEmailSchedulling: daleteCovidEmailSchedulling,
      getCovidEmailSchedulling: getCovidEmailSchedulling,
      exportEmptyData: exportEmptyData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withQueryParamsState({
    t: TAB_TYPES.specialInstruction,
  })(AppointmentCommunication),
);
