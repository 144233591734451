import React, { useMemo, useRef, useState } from "react";
import Highcharts from "highcharts";
import domToImage from "dom-to-image";
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import classes from "./Graph.module.scss";
import { Button } from "../../../../../../../shared/Button/Button";
import { DownloadIcon } from "../../../../../../../assets/Icons/DownloadIcon";
import { EditIcon } from "../../../../../../../assets/Icons/EditIcon";
import { DeleteIcon } from "../../../../../../../assets/Icons/DeleteIcon";
import { THEME } from "../../../../../../../consts/theme";
import { fileUtil } from "../../../../../../../utilities/file";
import { ConfirmModal } from "../../../../../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";
import {
  DEFAULT_DATE_FORMAT,
  PREVIEW_DATE_FORMAT,
} from "../../../../../../../consts/general";
import { useClientQuery } from "../../../../../../../api/queries/useClientQuery";
import { getPathParams } from "../../../../PatientVitals.utils";
import { squashSpaces } from "../../../../../../../utilities/general";

/**
 * @param {Object} param0
 * @param {string} param0.field
 * @param {Array<{ date: string; value: number }>} param0.data
 * @param {string} param0.startDate
 * @param {string} param0.endDate
 * @param {() => void} param0.onDelete
 * @param {() => void} param0.onEdit
 */
export const Graph = ({
  field,
  data,
  startDate,
  endDate,
  onDelete,
  onEdit,
}) => {
  const { patientId } = getPathParams();
  const { tClients } = useAppTranslation.Clients();
  const rootRef = useRef(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const { data: patient } = useClientQuery({ clientId: patientId });

  const { data: user } = useCurrentUserQuery();

  const handleDownload = () => {
    if (rootRef.current) {
      const patientName = patient?.fullName
        ? `${squashSpaces(patient.fullName).replace(/\s+/, "-").toLowerCase()}`
        : "";

      domToImage.toJpeg(rootRef.current).then((res) => {
        fileUtil.download(
          res,
          `${patientName}_${field}_${startDate}_${endDate}`,
        );
      });
    }
  };

  const handleDelete = () => {
    setIsDeleteConfirmOpen(true);
  };

  const formatPreviewDate = (date) =>
    moment(date).format(user?.previewDateFormat || PREVIEW_DATE_FORMAT);

  const dataToDisplay = useMemo(() => data.map((i) => i.value), [data]);

  const dates = useMemo(
    () =>
      data.map((i) =>
        moment(i.date).format(user?.dateFormat || DEFAULT_DATE_FORMAT),
      ),
    [data, user?.dateFormat],
  );

  return (
    <>
      <div ref={rootRef} className={classes.root}>
        <div className={classes.header}>
          <h3>
            {tClients(`vitals.graph.fieldOptions.${field}`)} |{" "}
            {formatPreviewDate(startDate)} - {formatPreviewDate(endDate)}
          </h3>
          <div className={classes.options}>
            <Button size="tiny" variant="text" onClick={handleDownload}>
              <DownloadIcon width="20px" />
            </Button>
            <Button size="tiny" variant="text" onClick={onEdit}>
              <EditIcon width="18px" />
            </Button>
            <Button size="tiny" variant="text" onClick={handleDelete}>
              <DeleteIcon width="18px" />
            </Button>
          </div>
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              zoomType: "x",
            },
            title: {
              text: undefined,
            },
            xAxis: {
              categories: dates,
            },
            yAxis: {
              title: {
                text: undefined,
              },
            },
            legend: {
              enabled: false,
            },
            series: [
              {
                type: "area",
                name: tClients(`vitals.graph.fieldOptions.${field}`),
                data: dataToDisplay,
              },
            ],
            plotOptions: {
              area: {
                fillColor: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                  },
                  stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [
                      1,
                      Highcharts.color(Highcharts.getOptions().colors[0])
                        .setOpacity(0)
                        .get("rgba"),
                    ],
                  ],
                },
                marker: {
                  radius: 2,
                },
                lineWidth: 1,
                states: {
                  hover: {
                    lineWidth: 1,
                  },
                },
                threshold: null,
              },
            },
            colors: [THEME.colors.primary],
          }}
        />
      </div>
      {isDeleteConfirmOpen && (
        <ConfirmModal
          isOpen
          onClose={() => setIsDeleteConfirmOpen(false)}
          onCancel={() => setIsDeleteConfirmOpen(false)}
          onConfirm={() => {
            setIsDeleteConfirmOpen(false);
            onDelete();
          }}
        >
          {tClients("vitals.confirm.deleteGraph")}
        </ConfirmModal>
      )}
    </>
  );
};
