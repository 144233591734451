import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";

const dtoSchema = yup.object({
  id: yup.number().nullable(),
  name: yup.string().required(),
  text: yup.string().required(),
  startDate: yup.string().nullable(),
  endDate: yup.string().nullable(),
  monday: yup.boolean().required(),
  tuesday: yup.boolean().required(),
  wednesday: yup.boolean().required(),
  thursday: yup.boolean().required(),
  friday: yup.boolean().required(),
  saturday: yup.boolean().required(),
  sunday: yup.boolean().required(),
  status: yup.boolean().required(),
});

export function usePPAnnouncementBannerMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      const dto = dtoSchema.validateSync(payload, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.patientPortal.announcementBanner.mutate(),
        {
          id: dto.id,
          name: dto.name,
          description: dto.text,
          start_date: dto.startDate,
          end_date: dto.endDate,
          monday: dto.monday ? 1 : 0,
          tuesday: dto.tuesday ? 1 : 0,
          wednesday: dto.wednesday ? 1 : 0,
          thursday: dto.thursday ? 1 : 0,
          friday: dto.friday ? 1 : 0,
          saturday: dto.saturday ? 1 : 0,
          sunday: dto.sunday ? 1 : 0,
          status: dto.status ? 1 : 0,
        },
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientPortal.announcementBanner.getOne],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientPortal.announcementBanner.getAll],
        });

        options?.onSuccess?.();
      },
    },
  );
}
