import React, { useState } from "react";
import { useFormik } from "formik";
import { Button } from "../../../../../../shared/Button/Button";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { InputWrap } from "../../../shared/ModalCharge/InputWrap";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import styles from "../scss/ChangeCalculator.module.scss";
import { formatCurrency } from "../../../../../../utilities/general";
import { tSales } from "../../../../../../i18n/useAppTranslation";
import { dispatch } from "../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../store/checkoutInvoice";
import { InfoIcon } from "../../../../../../assets/Icons/InfoIcon";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";

const ChangeCalculator = ({ invoiceAmount }) => {
  const [showModal, setShowModal] = useState(false);
  const { invoice } = useInvoice();

  const { values, setFieldValue } = useFormik({
    initialValues: {
      amountReceived: "",
    },
  });

  const calculatedChange = values.amountReceived - invoiceAmount;

  const handleClose = () => {
    setShowModal(false);
    dispatch(checkoutInvoice.actions.changeCalculatedChange(calculatedChange));
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className={styles.changeCalculatorButton}
      >
        {tSales("changeModal.buttonLabel")}
      </button>
      {showModal && (
        <Modal
          isOpen
          onClose={handleClose}
          header={<Modal.Title>{tSales("changeModal.title")}</Modal.Title>}
          footer={
            <Button onClick={handleClose} size={"small"}>
              {"Close"}
            </Button>
          }
          headerNoBorder
          footerNoBorder
          className={styles.root}
          contentClassName={styles.content}
          footerClassName={styles.footer}
        >
          <InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.invoiceAmount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={invoiceAmount || 0}
              onChange={() => {}}
              isDisabled
            />
          </InputWrap>
          <InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amountReceived")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={values.amountReceived}
              onChange={(value) => setFieldValue("amountReceived", value)}
            />
          </InputWrap>
          {values.amountReceived < invoiceAmount && (
            <AlertBox className={styles.infoBox}>
              <span data-tip data-for="tipInfoIcon" className={styles.infoIcon}>
                <InfoIcon width="17px" />
              </span>
              <span>{tSales("changeModal.infoText")}</span>
            </AlertBox>
          )}
          <InputLabel className={styles.change}>
            Change:{" "}
            <span className={styles.amount}>
              {formatCurrency(
                calculatedChange > 0 ? calculatedChange : 0,
                invoice?.currency,
              )}
            </span>
          </InputLabel>
        </Modal>
      )}
    </>
  );
};

export default ChangeCalculator;
