import { useMemo } from "react";
import * as yup from "yup";
import { useProvidersQuery } from "../../../api/queries/useProvidersQuery";
import { uiNotification } from "../../../services/UINotificationService";

const schema = yup.array().of(
  yup.object({
    id: yup.number(),
    fullName: yup.string(),
  }),
);

const compose = (response) => {
  let result = [];
  if (response && Array.isArray(response.data.data)) {
    result = response.data.data.map((provider) => ({
      id: provider.id,
      fullName: provider.full_name,
    }));
  }
  return result;
};

export function useApiProviders() {
  const { data, isFetching } = useProvidersQuery({
    staleTime: 0,
    onError: () => {
      uiNotification.error("Unable to retrieve providers. Try again later");
    },
  });

  const providers = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  return {
    providers,
    isProvidersFetching: isFetching,
  };
}
