import { ACTION_TYPES } from "./consts";

const initialState = {
  signProceduresData: null,
  isSigning: false,
  isSigned: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.signProceduresDataChange: {
      return {
        ...state,
        signProceduresData: action.payload || null,
      };
    }
    case ACTION_TYPES.signProceduresDataReset: {
      return {
        ...state,
        signProceduresData: null,
      };
    }
    case ACTION_TYPES.proceduresSigningStatusChange: {
      return {
        ...state,
        isSigning: action.payload.status,
      };
    }
    case ACTION_TYPES.proceduresSignedStatusChange: {
      return {
        ...state,
        isSigned: action.payload.status,
      };
    }
    default: {
      return state;
    }
  }
}
