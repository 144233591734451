import React from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Param } from "../../shared/Param/Param";
import styles from "./styles.module.scss";
import { OnFileMark } from "../../../../../shared/OnFileMark/OnFileMark";

export function Params({
  isMarkedAsOnFile,
  isPatientSigned,
  isWitnessSignatureRequired,
  isWitnessSigned,
}) {
  const { tCommon: t } = useAppTranslation.Common();

  return (
    <div className={styles.root}>
      {isMarkedAsOnFile ? (
        <OnFileMark />
      ) : (
        <>
          <Param isCompleted={isPatientSigned}>
            {t("patientConsents.patientSigned")}:
          </Param>
          {isWitnessSignatureRequired && (
            <Param isCompleted={isWitnessSigned}>
              {t("patientConsents.witnessSigned")}:
            </Param>
          )}
        </>
      )}
    </div>
  );
}
