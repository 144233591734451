import { useMemo, useState } from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { hasLength } from "../../../../utilities/general";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppointmentsNoShowsQuery } from "../../../../api/appointments/useAppointmentsNoShowsQuery";

const prepareReqPeriod = (periodState) => {
  return {
    from: periodState.from || null,
    to: periodState.to || null,
  };
};

const prepareReqFilter = (filterState) => {
  return {
    clinicIds: filterState.clinics.map((c) => c.value),
    providerIds: filterState.providers.map((t) => t.value),
  };
};

export const useNoShows = () => {
  const { tAppointments } = useAppTranslation.Appointments();

  /* Modifiers */

  const [filter, setFilter] = useState({
    clinics: [],
    providers: [],
  });

  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: new Date(),
  });

  /* Data */

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useAppointmentsNoShowsQuery({
    payload: {
      period: prepareReqPeriod(period),
      filter: prepareReqFilter(filter),
      pageSize: 15,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tAppointments("noShows.error.fetch"));
      },
    },
  });

  const preparedData = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.data).reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  /* Statuses */

  const isSoftLoading =
    Object.values({
      ...filter,
      ...period,
    }).some(hasLength) &&
    isFetching &&
    !isLoading &&
    !isFetchingNextPage;

  const isFilterActive = Object.values(filter).some(hasLength);

  // ---------

  return {
    data: {
      value: preparedData,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      isLoading: isLoading,
      fetchMore: fetchNextPage,
      isSoftLoading,
    },
    filter: {
      value: filter,
      isActive: isFilterActive,
      update: setFilter,
    },
    period: {
      value: period,
      update: setPeriod,
    },
  };
};
