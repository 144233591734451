/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import picClose from "../../_legacy/images/popupClose.png";

export default class SaveReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      showNameSection: props.showNameSection ? props.showNameSection : false,
      reportNameClass: props.reportNameClass
        ? props.reportNameClass
        : "simpleInput",
      reportName: props.reportName ? props.reportName : "",
      reportMode: props.reportMode ? props.reportMode : "create",
      editReportID: props.editReportID ? props.editReportID : 0,
      propReportType: props.propReportType ? props.propReportType : "create",
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (
      nextProps.showNameSection !== undefined &&
      nextProps.showNameSection === true
    ) {
      returnState.showNameSection = nextProps.showNameSection;
    } else {
      returnState.showNameSection = false;
    }

    if (
      nextProps.reportNameClass !== undefined &&
      nextProps.reportNameClass !== ""
    ) {
      returnState.reportNameClass = nextProps.reportNameClass;
    }

    if (nextProps.reportName !== undefined) {
      returnState.reportName = nextProps.reportName;
    }

    return returnState;
  }

  hideNameSection = () => {
    let returnState = {
      showNameSection: false,
      reportName: "",
      reportNameClass: "simpleInput",
    };

    if (this.state.reportMode && this.state.reportMode === "edit") {
      delete returnState.reportName;
      delete returnState.reportNameClass;
    }

    this.setState(returnState);
    this.props.hideNameSection(returnState);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let name = event.target.name;

    this.props.handleChildState({ [name]: value });
  };

  render() {
    return (
      <div
        className={
          this.state.showNameSection
            ? "blackOverlay"
            : "blackOverlay no-display"
        }
      >
        <div className="vert-middle">
          <div className="white-popup small-popup">
            <div className="white-popup-wrapper">
              <div className="membershipTypeTitle">
                Save report
                <a onClick={this.hideNameSection} className="popupClose">
                  <img src={picClose} alt="" />
                </a>
              </div>

              <div className="col-sm-12 col-xs-12">
                <div className="simpleField">
                  <div className="simpleLabel">Report Name</div>
                  <input
                    type="text"
                    className={this.state.reportNameClass}
                    name="reportName"
                    value={this.state.reportName}
                    onChange={this.handleInputChange}
                    placeholder={"Enter report name"}
                  />
                </div>
              </div>
            </div>
            <div className="footer-static">
              <a
                className="new-blue-btn pull-right"
                onClick={() => this.props.saveThisReport()}
              >
                Save
              </a>
              <a
                className="new-white-btn pull-right"
                onClick={this.hideNameSection}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
