import { MONTHS } from "../../consts/general";

export const APPOINTMENT_TYPES = {
  inPerson: "in_person",
  virtual: "virtual",
  waitList: "waitlist",
};

export const FORM_SERVICE_PLACEHOLDER_ID = "placeholder";

export const EDIT_DURATION_RANGE = {
  min: 5,
  max: 720,
};

export const TIME_END_DURATION_IN_MIN_FALLBACK = 30;

export const CUSTOM_FREQUENCY_TYPE_SUFFIX = "_custom";

export const FREQUENCY_TYPES = {
  noRepeat: "norepeat",
  daily: "daily",
  weekday: "weekday",
  annual: "annual",
  custom: "custom",
};

export const FREQUENCY_REPEAT_MEASURE_TYPES = {
  day: "day",
  week: "week",
  month: "month",
  year: "year",
};

export const FREQUENCY_END_TYPE = {
  date: "days",
  occurrence: "occurrence",
};

export const FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES = {
  eventDate: "date",
};

export const OUT_OF_OFFICE_BOOKING_TIMES = [
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "01:00 AM",
  "01:15 AM",
  "01:30 AM",
  "01:45 AM",
  "02:00 AM",
  "02:15 AM",
  "02:30 AM",
  "02:45 AM",
  "03:00 AM",
  "03:15 AM",
  "03:30 AM",
  "03:45 AM",
  "04:00 AM",
  "04:15 AM",
  "04:30 AM",
  "04:45 AM",
  "05:00 AM",
  "05:15 AM",
  "05:30 AM",
  "05:45 AM",
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
];

export const OUT_OF_OFFICE_BOOKING_TIMES_START =
  OUT_OF_OFFICE_BOOKING_TIMES.slice(0, -1);

export const NOTIFICATION_TYPES = {
  email: "email",
  sms: "sms",
  emailAndSms: "emailAndSms",
};

export const EVENT_EDIT_MODES = {
  reschedule: "reschedule",
};

export const OUT_OF_OFFICE_EDIT_TYPE = {
  current: "current",
  allFuture: "allFuture",
};

export const APPT_API_TIME_FORMAT = "HH:mm:ss";

export const WAIT_LIST_TIME_SLOTS = [
  {
    id: 0,
    name: "Any",
  },
  {
    id: 1,
    name: "8:00 AM - 10:00 AM",
  },
  {
    id: 2,
    name: "10:00 AM - 12:00 PM",
  },
  {
    id: 3,
    name: "12:00 PM - 2:00 PM",
  },
  {
    id: 4,
    name: "2:00 PM - 4:00 PM",
  },
  {
    id: 5,
    name: "4:00 PM - 6:00 PM",
  },
  {
    id: 6,
    name: "6:00 PM - 8:00 PM",
  },
  {
    id: 7,
    name: "After 8:00 PM",
  },
];

export const WAIT_LIST_DAYS = [
  {
    id: 0,
    name: "Any",
  },
  {
    id: 1,
    name: "Mon",
  },
  {
    id: 2,
    name: "Tue",
  },
  {
    id: 3,
    name: "Wed",
  },
  {
    id: 4,
    name: "Thu",
  },
  {
    id: 5,
    name: "Fri",
  },
  {
    id: 6,
    name: "Sat",
  },
  {
    id: 7,
    name: "Sun",
  },
];

export const WAITLIST_ANY_MONTH = {
  id: 13,
  name: "Any",
};

export const WAIT_LIST_MONTHS = [WAITLIST_ANY_MONTH, ...MONTHS];

export const WAIT_LIST_YEARS = [
  {
    id: new Date().getUTCFullYear(),
    name: String(new Date().getUTCFullYear()),
  },
  {
    id: new Date().getUTCFullYear() + 1,
    name: String(new Date().getUTCFullYear() + 1),
  },
];
