import { useInfiniteQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { getNextPaginationPage } from "../../../../../../schemas/pagination";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useClinicBdAspire = (params, options = {}) => {
  return useInfiniteQuery(
    ["clinicBdAspireReport", params],
    async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getBdAspireReportDetail(), {
        params: {
          ...params,
          pagesize: 15,
          page: pageParam,
        },
      });
      const composeResponse = {
        clinics: res.data.data.clinics,
        reportData: res.data.data.report_data,
        reportHeader: res.data.data.report_header,
        totalApplied: res.data.data.total_applied,
        totalAmount: res.data.data.total_amount,
      };
      return composeResponse;
    },
    options,
    getNextPaginationPage,
  );
};
