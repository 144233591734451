import { useClientQuery } from "../../../../../../api/queries/useClientQuery";
import { GENDERS } from "../../../../../../consts/api";
import { getCosmeticPathParams } from "../CosmeticCreateEdit.utils";

export function useDefaultImage() {
  const { clientId } = getCosmeticPathParams();
  const { data: client } = useClientQuery({
    clientId,
  });

  return client?.gender === GENDERS.male
    ? "/images/appmale.png"
    : "/images/appfemale.png";
}
