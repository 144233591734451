import { useState } from "react";

export default function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export const setLocalStorageItem = (item, data) => {
  localStorage &&
    localStorage.setItem(
      item,
      typeof data === "object" ? JSON.stringify(data) : data,
    );
};

export const getLocalStorageItem = (item) => {
  const receivedItem =
    localStorage && item in localStorage ? localStorage.getItem(item) : "";

  try {
    return JSON.parse(receivedItem);
  } catch (e) {
    return receivedItem;
  }
};

export const getPaginationParams = (defaultParams) => {
  try {
    let pageLocalStorageParams = getLocalStorageItem(
      typeof window !== "undefined" &&
        window.location &&
        window.location.pathname,
    );

    pageLocalStorageParams = Object.assign(
      {},
      pageLocalStorageParams,
      defaultParams,
    );

    let pageParams = Object.assign(
      {},
      ...Object.keys(pageLocalStorageParams)
        .filter(
          (queryProperty) =>
            (Boolean(pageLocalStorageParams[queryProperty]) ||
              Number.isInteger(pageLocalStorageParams[queryProperty])) &&
            queryProperty !== "handlePagination",
        )
        .map((queryProperty) => ({
          [queryProperty]: pageLocalStorageParams[queryProperty],
        })),
    );

    return pageParams;
  } catch (error) {
    return {};
  }
};

export const localStorageKeys = {
  access_token: "access_token",
};
