import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  procedureIds: yup.array().of(yup.number()).required(),
});

export function useProviderRoomUnhideProceduresMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.patch(
      HTTP_ENDPOINTS.patchProviderRoomUnhideProcedures(
        req.procedureIds.join(","),
      ),
    );
  }, options);
}
