import React from "react";
import { Tabs as SharedTabs } from "../../../../../shared/Tabs/Tabs";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { TABS } from "../../config";

export const Tabs = ({ onChange, active }) => {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <SharedTabs
      active={active}
      options={[
        {
          label: tSettings("deletedNotes.procedure.label"),
          value: TABS.procedure,
          onClick: () => onChange(TABS.procedure),
        },
        {
          label: tSettings("deletedNotes.customer.label"),
          value: TABS.customer,
          onClick: () => onChange(TABS.customer),
        },
      ]}
    />
  );
};
