import React from "react";
import InsightsSideBar from "../../../../../InsightsSideBar";
import { useReports } from "./hooks/useReports";
import { useDownloadExcel } from "./hooks/useDownloadExcel";
import { ReportLayout } from "../../../../../components/ReportLayout/ReportLayout";
import { Header } from "./components/Header/Header";
import { Table } from "./components/Table/Table";

export function ExpiredLiabilityReport() {
  const { reports, period } = useReports();

  const downloadExcel = useDownloadExcel({
    period,
  });

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <InsightsSideBar />
        <div className="memberWalletOuter business-section">
          <ReportLayout>
            <Header
              total={reports.total}
              period={period}
              downloadExcel={downloadExcel}
              isReportsLoading={reports.isLoading}
            />
            <Table reports={reports} />
          </ReportLayout>
        </div>
      </div>
    </div>
  );
}
