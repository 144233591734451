import React, { useEffect } from "react";
import Loader from "../../Common/Loader";
import { http } from "../../../services/HttpService";
import { uiNotification } from "../../../services/UINotificationService";

const StripeCompletedPayment = (props) => {
  const invoiceId = props.match.params.invoiceId;
  const type = props.match.params.type;

  const redirectTo = {
    checkout: `/sales/checkout-invoice/${invoiceId}/stripe-payment-success/`,
  };

  useEffect(() => {
    http
      .post("stripe/update-invoice-to-pending", { invoice_id: invoiceId })
      .then(() => (window.location.href = redirectTo[type]))
      .catch((error) => {
        uiNotification.error(error.response.data.message);
        setTimeout(() => (window.location.href = "/"), 2000);
      });
  }, []);

  return (
    <>
      <Loader showLoader={true} isFullWidth={false} />
    </>
  );
};

export default StripeCompletedPayment;
