import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import { tCommon } from "../../i18n/useAppTranslation";
import { authService } from "../../services/AuthService";

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="main protected">
          <div className="something-wrong">
            <img src="/images/something-wrong.png" alt="error" />
            {authService.isLoggedIn && (
              <Link to={ROUTES.logout()} className="click-logout">
                {tCommon("label.clickToLogout")}
              </Link>
            )}
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
