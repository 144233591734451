import React from "react";
import styles from "../scss/chartFilters.module.scss";
import * as yup from "yup";
import { Modal } from "../../../../../shared/Modal/Modal";
import { Button } from "../../../../../shared/Button/Button";
import { useFormik } from "formik";
import { useCreateChartingFilterCategories } from "../hooks/useCreateChartingFilterCategories";
import { uiNotification } from "../../../../../services/UINotificationService";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../../shared/Input/Input";
import { InputError } from "../../../../../shared/InputError/InputError";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

const CreateCategoryModal = ({ onClose, refetchCategories }) => {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync: createCategory, isLoading } =
    useCreateChartingFilterCategories();

  const handleCreate = async () => {
    try {
      const res = await createCategory({ name: values.name });
      uiNotification.success(res.data.message);
      onClose();
      refetchCategories();
    } catch (e) {
      uiNotification.error(e.response.data.message);
    }
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        tSettings(
          "clinicalDocumentation.chartFilters.errors.categoryNameRequired",
        ),
      ),
  });

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    onSubmit: handleCreate,
    validationSchema: schema,
    initialValues: { name: "" },
  });

  return (
    <Modal
      isOpen
      headerNoBorder
      footerNoBorder
      onClose={onClose}
      className={styles.modal}
      footerClassName={styles.modalFooter}
      header={
        <Modal.Title>
          {tSettings("clinicalDocumentation.chartFilters.createButton")}
        </Modal.Title>
      }
      footer={
        <>
          <Button variant="outlined" onClick={onClose} isDisabled={isLoading}>
            {tCommon("label.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            leftAdornment={
              isLoading ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
            isDisabled={isLoading}
          >
            {tCommon("label.create")}
          </Button>
        </>
      }
    >
      <InputLabel>
        {" "}
        {tSettings("clinicalDocumentation.chartFilters.categoryName")}
      </InputLabel>
      <Input
        placeholder="Category Name"
        value={values.name}
        onChange={(event) => setFieldValue("name", event.target.value)}
        isError={Boolean(errors.name)}
      />
      {errors.name && <InputError>{errors.name}</InputError>}
    </Modal>
  );
};

export default CreateCategoryModal;
