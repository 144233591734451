import React from "react";
import PropTypes from "prop-types";

const ServiceDurationSelect = ({
  label,
  selectClassName,
  selectValue,
  handleChangeSelect,
}) => {
  return (
    <div className="newInputFileldOuter">
      <div className="newInputLabel">
        {label}
        <span className="setting-require">*</span>
      </div>
      <select
        className={selectClassName}
        name="duration"
        value={selectValue}
        onChange={handleChangeSelect}
      >
        <option value="0">Please select</option>
        <option value="15">15 minutes</option>
        <option value="30">30 minutes</option>
        <option value="45">45 minutes</option>
        <option value="60">1 hour</option>
        <option value="75">1 hour 15 minutes</option>
        <option value="90">1 hour 30 minutes</option>
        <option value="custom">Custom</option>
      </select>
    </div>
  );
};

ServiceDurationSelect.propTypes = {
  label: PropTypes.string.isRequired,
  selectClassName: PropTypes.string.isRequired,
  selectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
};

export default ServiceDurationSelect;
