/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { autoScrolling } from "../../../../Utils/services.js";
import ConfirmationModal from "../../../Common/ConfirmationModal.js";

class BdAspireDetail extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,

      page: 1,
      pagesize: 15,
      startFresh: true,
      next_page_url: "",
      detailData: [],
      BdAspireDetail: {},

      showConfirmationModal: false,
      confirmationMsg: "",
      hideDeclineInvoiceId: 0,
    };

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  loadMore = () => {
    if (!autoScrolling()) {
      if (this.state.next_page_url) {
        this.setState({ showLoadingText: true });
        autoScrolling(true);
        this.props.fetchBdAspireDetail({
          detailClinicId: this.props.detailClinicId,
          detailClinicName: this.props.detailClinicName,
          page: this.state.page,
        });
      }
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.BdAspireDetail &&
      nextProps.BdAspireDetail != prevState.BdAspireDetail
    ) {
      returnState.BdAspireDetail =
        nextProps.BdAspireDetail && nextProps.BdAspireDetail.report_data
          ? nextProps.BdAspireDetail
          : prevState.BdAspireDetail;
      if (returnState.BdAspireDetail.current_page == 1) {
        returnState.detailData = nextProps.detailData;
      } else if (
        returnState.next_page_url != nextProps.BdAspireDetail.next_page_url ||
        nextProps.BdAspireDetail.next_page_url == null
      ) {
        returnState.detailData = [
          ...prevState.detailData,
          ...nextProps.detailData,
        ];
      }
      returnState.page = prevState.page + 1;
      returnState.next_page_url = nextProps.BdAspireDetail.next_page_url;
      returnState.showLoadingText = false;
      autoScrolling(false);
    }

    return returnState;
  }

  BdAspireDetailBack = () => {
    this.props.BdAspireDetailBack();
  };

  handleDetailExport = () => {
    this.props.fetchBdAspireDetail(
      {
        export_name: "bd_aspire_report_detail",
        export_type: "xls",
        detailClinicId: this.props.detailClinicId,
        detailClinicName: this.props.detailClinicName,
      },
      1,
    );
  };

  onResetConfirmation = (resetState) => {
    this.setState(resetState);
  };

  hideDeclineInvoice = () => {
    if (this.state.hideDeclineInvoiceId) {
      this.props.hideDeclineInvoice(this.state.hideDeclineInvoiceId);
    }
  };

  render() {
    return (
      <div className="juvly-section setting-setion m-b-10 m-t-10">
        <div className="juvly-container memberShip-revenue p-b-20">
          <div className="juvly-title no-margin">
            {"Allē + Aspire + Evolus + Xperience Report" +
              " in " +
              this.props.detailClinicName +
              " during " +
              this.props.detailDate}{" "}
            <a
              onClick={() => this.BdAspireDetailBack()}
              className="pull-right crossIcon m-b-10"
            >
              <img src="/images/close.png" alt="" />
            </a>
          </div>
          {this.props.detailData && this.props.detailData.length > 0 && (
            <div className="mainRightActions">
              <a
                className="easy-link relative"
                onClick={() => this.handleDetailExport()}
              >
                <i className="fa fa-download"></i> Download Excel
              </a>
            </div>
          )}
        </div>

        <div className="table-responsive">
          <table className="table-updated setting-table table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                <th className="table-updated-th col-xs-2">
                  {this.props.detailHeader &&
                    this.props.detailHeader.invoice_number}
                </th>
                <th className="table-updated-th col-xs-2">
                  {this.props.detailHeader &&
                    this.props.detailHeader.payment_datetime}
                </th>
                <th className="table-updated-th col-xs-2">
                  {this.props.detailHeader &&
                    this.props.detailHeader.clientname}
                </th>
                <th className="table-updated-th col-xs-2">
                  {this.props.detailHeader?.email}
                </th>
                <th className="table-updated-th col-xs-1 p-r-0">
                  {this.props.detailHeader &&
                    this.props.detailHeader.bd_discount}
                </th>
                <th className="table-updated-th col-xs-1 p-r-0">
                  {this.props.detailHeader &&
                    this.props.detailHeader.aspire_discount}
                </th>
                <th className="table-updated-th col-xs-1 p-r-0">
                  {this.props.detailHeader?.xperience_discount}
                </th>
                <th className="table-updated-th col-xs-1 p-r-0">
                  {this.props.detailHeader &&
                    this.props.detailHeader.evolus_discount}
                </th>
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.state.detailData &&
                this.state.detailData.length > 0 &&
                this.state.detailData.map((obj, idx) => {
                  return (
                    <tr key={idx} className="table-updated-tr">
                      <td className="table-updated-td break-all">
                        {obj.invoice_number}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.payment_datetime}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.clientname}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj.email}
                      </td>
                      <td className="table-updated-td p-r-0">
                        {obj.bd_discount}
                      </td>
                      <td className="table-updated-td break-all p-r-0">
                        {obj.aspire_discount}
                      </td>
                      <td className="table-updated-td break-all p-r-0">
                        {obj.xperience_discount}
                      </td>
                      <td className="table-updated-td break-all p-r-0">
                        {obj.evolus_discount}
                      </td>
                    </tr>
                  );
                })}

              {this.props.detailData && this.props.detailData.length === 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan="8"
                  >
                    {this.state.languageData.bi_no_record_found}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          className={
            this.state.showLoadingText
              ? "loading-please-wait no-margin-top"
              : "loading-please-wait no-margin-top no-display"
          }
        >
          {this.state.globalLang.loading_please_wait_text}
        </div>

        {this.state.showConfirmationModal === true && (
          <ConfirmationModal
            showConfirmationModal={this.state.showConfirmationModal}
            confirmationMsg={this.state.confirmationMsg}
            confirmationAction={this.hideDeclineInvoice}
            onResetConfirmation={this.onResetConfirmation}
          />
        )}
      </div>
    );
  }
}

export default BdAspireDetail;
