const formatUrlParams = (payload) => {
  const params = new URLSearchParams();

  Object.entries(payload).forEach((item) => {
    const [key, value] = item;
    if (value !== undefined && value !== null && value !== "") {
      try {
        value.forEach((element) => {
          params.append(key, element);
        });
      } catch (e) {
        params.append(key, value);
      }
    }
  });

  return params;
};

export default formatUrlParams;
