import { fileUtil } from "../../utilities/file";

export const filterFiles = (files = [], supportedExtensions = []) => {
  const extensionMismatchedFiles = [];
  const filteredFiles = files.filter((file) => {
    const condition = supportedExtensions.includes(
      fileUtil.getExtensionFromName(file.name),
    );
    if (!condition && supportedExtensions.length > 0) {
      extensionMismatchedFiles.push(file);
    }
    return condition;
  });
  return {
    filteredFiles,
    extensionMismatchedFiles,
  };
};

export const handleExtensionMismatchedFiles = (
  files = [],
  { onError, supportedExtensions } = {},
) => {
  const composeExtensionsSeq = () => {
    const exts = supportedExtensions.map((ext) => ext.slice(1).toUpperCase());
    if (exts.length === 1) {
      return exts[0];
    }
    if (exts.length === 2) {
      return exts.join(" or ");
    }
    return `${exts.slice(0, -1).join(", ")} or ${exts[exts.length - 1]}`;
  };

  if (files.length > 0) {
    const fileNames = files.map((f) => f.name);
    const message = `Unsupported File Type in: ${fileNames.join(
      ", ",
    )}. Please Use ${composeExtensionsSeq()}`;
    if (onError) {
      onError(message);
    } else {
      throw new Error(message);
    }
  }
};
