import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";

export const useResendGiftCardEmail = () => {
  return useMutation(
    ["resendGiftCardEmail"],
    async (id) => {
      return http.patch(HTTP_ENDPOINTS.patchResendGiftCard(id));
    },
    {
      onSuccess: () =>
        uiNotification.success("Gift card email sent successfully"),
      onError: () => uiNotification.error("Failed to send gift card email"),
    },
  );
};
