import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import { Match } from "../../utilities/fp";

export const QUALIPHY_EXAM_TYPES = {
  simple: "simple",
  rx: "rx",
};

const requestSchema = yup.object({
  clinicId: yup.number().required(),
});

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    name: yup.string().required(),
    rx: yup.number().required(),
    type: yup.string().nullable(),
    isAttachmentsRequired: yup.bool().required(),
  }),
);

const composeResponse = (res) => {
  const exams = unwrapOr(() => res.data.data.exams, []);
  return exams.map((exam) => ({
    id: exam.id,

    name: exam.title,

    rx: exam.rx,
    type: Match.of(exam.rx)
      .on(1, () => QUALIPHY_EXAM_TYPES.simple)
      .on(2, () => QUALIPHY_EXAM_TYPES.rx)
      .or(null)
      .join(),

    isAttachmentsRequired: Boolean(exam.attachments_required),
  }));
};

function createQualiphyExamsQuery({ clinicId }) {
  return [QUERY_KEYS.qualiphy.exams, clinicId];
}

export function useQualiphyExamsQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createQualiphyExamsQuery({ clinicId: req.clinicId }),
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.qualiphy.getExamsByClinicId(req.clinicId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
