import {
  MULTI_QUESTION_LOGIC_JUMP_TYPES,
  MULTI_QUESTION_TYPES,
} from "../../../../../../api/questionnaires/config";
import { useMultiQuestionnaireMutation } from "../../../../../../api/questionnaires/useMultiQuestionnaireMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { getMultiQuestionnairesPathParams } from "../MultiQuestionnaireCreateEdit.utils";
import { history } from "../../../../../../history";
import { isNullish } from "../../../../../../utilities/general";
import { NodeId } from "../../../utilities";

export function useSubmit() {
  const { tCommon } = useAppTranslation.Common();
  const { questionnaireId } = getMultiQuestionnairesPathParams();

  const { mutate, isLoading } = useMultiQuestionnaireMutation({
    onError: () => {
      uiNotification.error(tCommon("error.saveQuestionnaire"));
    },
    onSuccess: () => {
      uiNotification.success(tCommon("success.saveQuestionnaire"));
      history.goBack();
    },
  });

  function initiate(formValues) {
    const optionsAdapter = (q) => {
      return {
        options: q.choices?.map((c) => String(c.value)) || [],
      };
    };

    const jumpLogicAdapter = (q) => {
      const jumps =
        q.logicJumps?.filter(
          (j) => j.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.jump,
        ) || [];

      const elses =
        q.logicJumps?.filter(
          (j) => j.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
        ) || [];

      const filterPredicate = (x) => !isNullish(x) && x !== -1;

      return {
        logicJumpsAns: jumps
          .map((x) =>
            q.choices.findIndex((c) => NodeId.same(c.id, x.ifSelectedChoiceId)),
          )
          .filter(filterPredicate),

        logicJumpsQue: jumps
          .map((j) =>
            formValues.questions.findIndex((q) =>
              NodeId.same(q.id, j.thenQuestionId),
            ),
          )
          .filter(filterPredicate),

        logicJumpsElse: elses
          .map((j) =>
            formValues.questions.findIndex((q) =>
              NodeId.same(q.id, j.elseQuestionId),
            ),
          )
          .filter(filterPredicate)[0],
      };
    };

    const getRestQuestionData = (q) => {
      switch (q.questionType) {
        case MULTI_QUESTION_TYPES.yesNo:
        case MULTI_QUESTION_TYPES.singleChoice: {
          return {
            ...optionsAdapter(q),
            ...jumpLogicAdapter(q),
          };
        }
        case MULTI_QUESTION_TYPES.multiChoice: {
          return optionsAdapter(q);
        }
        case MULTI_QUESTION_TYPES.opinionScale: {
          return {
            ...optionsAdapter(q),
            ...jumpLogicAdapter(q),
            why_choose: q.allowCommentsOnScale,
          };
        }
      }
    };

    mutate({
      questionnaire_id: questionnaireId,
      title: formValues.title,
      question_data: formValues.questions.map((q) => ({
        question: q.question,
        question_type: q.questionType,
        required: q.isRequired,
        description: Boolean(q.description),
        descriptionText: q.description?.trim() || null,
        ...getRestQuestionData(q),
      })),
    });
  }

  return {
    initiate,
    isLoading,
  };
}
