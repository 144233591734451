import React, { useState } from "react";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { Header } from "./components/Header";
import { Table } from "./components/Table";
import ModalFilter from "./components/ModalFilter";
import { useReports } from "./hooks/useReports";
import { useExportExcel } from "./hooks/useExportExcel";

const ServicesPerformed = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { period, report, order, currency, reportFilters, filter } =
    useReports();
  const exportReport = useExportExcel({
    ...filter.formatedValue,
    ...period.apiValue,
    ...{ order: order.value.order, orderBy: order.value.by },
    export: true,
  });

  return (
    <ReportLayout>
      <Header
        onFilterOpen={() => setIsFilterOpen(true)}
        period={period}
        totalCount={report.total}
        exportReport={exportReport}
        isReportsLoading={report.isLoading}
      />
      <Table reports={report} order={order} currency={currency} />
      <ModalFilter
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        reportFilters={reportFilters}
        filter={filter}
        isLoading={report.isLoading}
      />
    </ReportLayout>
  );
};

export default ServicesPerformed;
