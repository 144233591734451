import React from "react";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { Table } from "./shared/Table/Table";
import { Form } from "./shared/Form/Form";
import styles from "./styles.module.scss";
import { usePermission } from "../../hooks/usePermission";
import { PERMISSIONS } from "../../consts/api";
import { AlertBox } from "../../shared/AlertBox/AlertBox";

/**
 * @param {Object} param0
 * @param {boolean} param0.isOpen
 * @param {Function} param0.onClose
 * @param {{
 *    id: number;
 *    name: string;
 *    purchaseDate: string;
 *    recipient: string;
 *    expireDate: string;
 *    balance: string;
 * }} param0.giftCardData
 * @param {Function?} param0.onSuccess
 */
export const ModalEditGiftCardExpiryDate = ({
  isOpen,
  onClose,
  giftCardData,
  onSuccess,
}) => {
  const { tCommon } = useAppTranslation.Common();

  const permission = usePermission(
    PERMISSIONS.patientManagement.editGiftCardBalances,
  );

  return (
    <Modal
      size="large"
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={styles.root}
      header={
        <Modal.Title>
          {tCommon("modalEditGiftCardExpiryDate.title")}
        </Modal.Title>
      }
    >
      <Table data={giftCardData} />
      {permission.loaded && permission.permitted ? (
        <Form
          giftCardId={giftCardData.id}
          onCancel={onClose}
          onSuccess={onSuccess}
        />
      ) : (
        <AlertBox>{tCommon("label.permissionDenied")}</AlertBox>
      )}
    </Modal>
  );
};
