import React, { useEffect, useMemo, useState } from "react";
import { WidgetDateRange } from "../../components/WidgetDateRange";
import styles from "../scss/ProviderDashboard.module.scss";
import { getWidgetCalendarTodayDate } from "../../Dashboard.utils";
import CardContent from "./CardContent";
import { useProviderDashboardWidget } from "../hooks/useProviderDashboardWidget";
import { apiDateFormat } from "../../../../Utils";
import ClinicDropdown from "../../../NewBusinessInsights/components/ClinicDropdown";
import { ProviderSingleDateCalendar } from "./ProviderSingleDateCalendar";

const ProviderWidgetCard = ({
  title,
  globalDate,
  url,
  clinicList,
  filters,
}) => {
  const [dates, setDates] = useState({
    start: getWidgetCalendarTodayDate(),
    end: getWidgetCalendarTodayDate(),
  });
  const [selectedOption, setSelectedOption] = useState({
    label: "All Clinics",
    value: 0,
  });

  const params = {
    fromDate: apiDateFormat(dates.start),
    toDate: apiDateFormat(dates.end),
    clinicId: selectedOption.value,
  };

  const clinicsOptions = useMemo(() => {
    return clinicList?.map((clinic) => ({
      label: clinic.clinic_name,
      value: clinic.id,
    }));
  }, [clinicList]);

  useEffect(() => {
    setDates(globalDate);
  }, [globalDate]);

  const { data, isLoading } = useProviderDashboardWidget(url, params);

  return (
    <div className={styles.widgetCard}>
      <div className={styles.title}>
        <span className={styles.label}>{title}</span>
        <div className={styles.filtersContainer}>
          {filters.clinics && (
            <ClinicDropdown
              className={styles.locationsLabel}
              options={clinicsOptions}
              selectedClinic={{
                value: selectedOption,
                update: setSelectedOption,
              }}
            />
          )}
          {filters.singleDate ? (
            <ProviderSingleDateCalendar
              selectedDate={dates.start}
              onChange={(value) => setDates({ start: value, end: value })}
            />
          ) : (
            <WidgetDateRange
              className={styles.dateRange}
              ranges={{
                startDate: dates.start,
                endDate: dates.end,
              }}
              onChange={(result) =>
                setDates({ start: result.startDate, end: result.endDate })
              }
              dates={dates}
            />
          )}
        </div>
      </div>
      <CardContent list={data} isLoading={isLoading} />
    </div>
  );
};

export default ProviderWidgetCard;
