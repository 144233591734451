import { useDeletePatientProcedureMutation } from "../../../../../api/mutations/useDeletePatientProcedureMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { extractApiError } from "../../../../../utilities/api";

export function useDelete({ onSuccess }) {
  const { tCommon } = useAppTranslation.Common();

  const deleteMutation = useDeletePatientProcedureMutation({
    onSuccess,
    onError: (e) => {
      const message = extractApiError(e);
      uiNotification.error(
        tCommon([`apiError.qualiphy.${message}`, "error.deleteProcedure"]),
      );
    },
  });

  const deleteProcedure = ({ patientId, procedureId }) => {
    if (window.confirm(tCommon("confirm.deleteProcedure"))) {
      deleteMutation.mutate({
        patientId,
        procedureId,
      });
    }
  };

  return {
    deleteProcedure,
    isDeleting: deleteMutation.isLoading,
  };
}
