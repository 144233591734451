import { REDUCER_NAME } from "./config";

export const selectors = {
  selectNotification: (state) => {
    const id = Number(state[REDUCER_NAME]?.id);
    const message = state[REDUCER_NAME]?.message;

    if (id && message) {
      return { id, message };
    }

    return null;
  },
};
