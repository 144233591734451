import React, { useState } from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { ReorderModal } from "../../../../../../boxes/ReorderModal/ReorderModal";
import { useGiftCards } from "./hooks/useGiftCards";
import { useSaveOrder } from "./hooks/useSaveOrder";

export function ObOrder() {
  const { tCommon } = useAppTranslation.Common();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { giftCards, isGiftCardsLoading } = useGiftCards();
  const { save, isSaving } = useSaveOrder({
    onSuccess: () => {
      setIsModalOpen(false)
    }
  });

  return (
    <>
      <button
        className="easy-link no-padding"
        onClick={() => setIsModalOpen(true)}
      >
        {tCommon("label.obOrder")}
      </button>
      {isModalOpen && (
        <ReorderModal
          isLoading={isGiftCardsLoading}
          isSaving={isSaving}
          isOpen={isModalOpen}
          data={giftCards}
          onClose={() => setIsModalOpen(false)}
          title={tCommon("label.obOrder")}
          onSave={save}
        />
      )}
    </>
  );
}
