import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import { useItemSales } from "./hooks/useItemSales";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "productSalesReport.info.title",
  rows: [
    "productSalesReport.info.definition",
    "productSalesReport.info.unitsUsed",
    "productSalesReport.info.unitsSold",
    "productSalesReport.info.subTotal",
  ],
};

const ProductSales = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const { data, isLoading } = useItemSales(requestParams);

  const tableDataEmpty = !isLoading && !data?.reportData?.length;

  const tableData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        item: data.item,
        category: data.category,
        unitsUsed: data.unit_used,
        unitsSold: data.unit_sold,
        netAmount: data.net_amount,
        taxableAmount: data.taxable_amount,
        itemDiscount: data.discount_percentage,
        subTotal: data.gross_sales,
      })),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const totalDatas = [
    ...(tableData || []),
    !tableDataEmpty &&
      !isLoading && {
        item: <span className="bold">Total</span>,
        unitsUsed: <span className="bold">{data.total.unit_used}</span>,
        unitsSold: <span className="bold">{data.total.unit_sold}</span>,
        subTotal: <span className="bold">{data.total.gross_sales}</span>,
        itemDiscount: (
          <span className="bold">{data.total.discount_percentage}</span>
        ),
        netAmount: <span className="bold">{data.total.net_amount}</span>,
        taxableAmount: (
          <span className="bold">{data.total.taxable_amount}</span>
        ),
      },
  ].filter(Boolean);

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Product Sales"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getProductSales()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Item", accessor: "item" },
              { data: "Category", accessor: "category" },
              { data: "Units Used", accessor: "unitsUsed" },
              { data: "Units Sold", accessor: "unitsSold" },
              { data: "Sub-Total", accessor: "subTotal" },
              { data: "Item Discount", accessor: "itemDiscount" },
              { data: "Net Amount", accessor: "netAmount" },
              { data: "Taxable Amount", accessor: "taxableAmount" },
            ]}
            data={totalDatas}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSales;
