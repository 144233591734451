import React, { useState } from "react";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { Header } from "./shared/Header/Header";
import { useNoShows } from "./hooks/useNoShows";
import { ModalFilter } from "./shared/ModalFilter/ModalFilter";
import classes from "./NoShows.module.scss";
import { Table } from "./shared/Table/Table";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { useRestore } from "./hooks/useRestore";

export function AppointmentNoShows() {
  const { tAppointments } = useAppTranslation.Appointments();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { data, filter, period } = useNoShows();
  const restore = useRestore();

  return (
    <LayoutMain className={classes.root}>
      <Header
        isFilterActive={filter.isActive}
        onFilterOpen={() => setIsFilterOpen(true)}
        period={period}
        isReportsLoading={data.isLoading}
      />
      <Table data={data} restore={restore} />
      <ModalFilter
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={filter}
      />
      {restore.isConfirmOpen && (
        <ConfirmModal
          isOpen
          onClose={restore.declineConfirm}
          onCancel={restore.declineConfirm}
          onConfirm={restore.initiate}
        >
          {tAppointments("noShows.confirm.restore")}
        </ConfirmModal>
      )}
    </LayoutMain>
  );
}
