import { useMemo } from "react";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";
import {
  useQualiphyPharmacyPackagesQuery,
  GET_PHARMACY_PACKAGES_ADDITION_FIELDS,
} from "../../../api/qualiphy/useQualiphyPharmacyPackagesQuery";

export function usePharmacyPackages({
  patientId,
  clinicId,
  examIds,
  isEnabled,
  onSuccess,
  state,
}) {
  const { tCommon: t } = useAppTranslation.Common();

  const { data, isFetching, status } = useQualiphyPharmacyPackagesQuery(
    {
      patient_id: Number(patientId),
      clinic_id: Number(clinicId),
      exam_ids: examIds.map(Number),
      state,
    },
    {
      enabled:
        isEnabled && !!patientId && !!clinicId && !!examIds.length && !!state,
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onError: (e) => {
        const field = extractApiError(e);

        if (
          Object.values(GET_PHARMACY_PACKAGES_ADDITION_FIELDS).includes(field)
        ) {
          uiNotification.warn(
            t("createQualifyProcedure.error.provideStateForPharmacyPackages"),
          );
        } else {
          uiNotification.error(
            t("createQualifyProcedure.error.fetchPharmacyPackages"),
          );
        }
      },
      onSuccess: (data) => {
        onSuccess?.(data);
      },
    },
  );

  const options = useMemo(
    () =>
      data?.map((x) => ({
        label: `${x.pharmacy_name} - ${x.package_name}`,
        value: x.package_id,
        pharmacyId: x.pharmacy_id,
      })),
    [data],
  );

  return {
    options,
    isLoading: isFetching,
    isError: status === "error",
  };
}
