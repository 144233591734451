import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import { useRefunds } from "./hooks/useRefunds";
import classes from "../../../newBusinessInsightsContent.module.scss";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { Box } from "../../../../../shared/Box/Box";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "refundsReport.info.title",
  rows: ["refundsReport.info.note"],
};

const Refunds = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
  };
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useRefunds(requestParams);

  const isTableDataEmpty = !isLoading && !data?.pages[0].reportData?.length;

  const refundLabels = data?.pages[0].refundModeLabels;

  const tableData = useMemo(
    () =>
      data?.pages
        ?.map((p) => p.reportData)
        .flat()
        .map((report) => ({
          patient: report.patient_name,
          invoiceNumber: report.invoice_number,
          invoiceDate: report.invoice_date,
          refundedAmount: report.refund_amount,
          refundedStatus: report.invoice_status,
          refundedMode: refundLabels[report.refund_mode],
          refundedOn: report.refunded_on,
        })),
    [data],
  );

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Refunds Report"
        selectedClinic={selectedClinic}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getRefundsReport()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Patient", accessor: "patient" },
              { data: "Invoice#", accessor: "invoiceNumber" },
              { data: "Invoice Date", accessor: "invoiceDate" },
              { data: "Refunded Amount", accessor: "refundedAmount" },
              { data: "Refunded Status", accessor: "refundedStatus" },
              { data: "Refund Mode", accessor: "refundedMode" },
              { data: "Refunded On", accessor: "refundedOn" },
            ]}
            data={tableData}
          />{" "}
          {hasNextPage && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={fetchNextPage}
              isLoading={isFetchingNextPage}
            />
          )}
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isTableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default Refunds;
