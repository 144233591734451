import { http } from "../../services/HttpService";

export function accountReset() {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_RESET" });
  };
}

export function signTosAgreement(formData) {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_RESET" });

    http
      .post("sign-tos-agreement", formData)
      .then((response) => {
        dispatch({ type: "ACCEPT_TOS_AGREEMENT", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "ACCEPT_TOS_AGREEMENT",
            payload: error.response.data,
          });
        }
      });
  };
}

export function upgradeTrailToPaid(formData) {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_RESET" });

    http
      .post("upgrade-plan", formData)
      .then((response) => {
        dispatch({ type: "UPGRADE_TRAIL_TO_PAID", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "UPGRADE_TRAIL_TO_PAID",
            payload: error.response.data,
          });
        }
      });
  };
}

export function upgradeRecurlyToStripe(formData) {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_RESET" });

    http
      .post("upgrade-account-to-stripe", formData)
      .then((response) => {
        dispatch({ type: "UPGRADE_RECURLY_TO_STRIPE", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "UPGRADE_RECURLY_TO_STRIPE",
            payload: error.response.data,
          });
        }
      });
  };
}
