import React from "react";
import styles from "./styles.module.scss";
import { InputSearch } from "../../../../../../../boxes/InputSearch/InputSearch";
import { DateRangePicker } from "../../../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";

export const TableHeading = ({ isReportsLoading, period, search }) => {
  const { data: user } = useCurrentUserQuery();

  return (
    <div className={styles.root}>
      <InputSearch value={search.value} onChange={search.onChange} />
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />
    </div>
  );
};
