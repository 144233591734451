import React from "react";
import cx from "clsx";
import rootClasses from "../Column.module.scss";
import classes from "./ColumnFiller.module.scss";

export const ColumnFiller = ({ accessors, accentFirstCell }) => (
  <div className={classes.root}>
    {accessors.map(({ value }, index) => (
      <div
        key={value}
        className={cx(rootClasses.cell, {
          [rootClasses.firstCellAccent]: accentFirstCell && index === 0,
        })}
      />
    ))}
  </div>
);
