import React from "react";
import PropTypes from "prop-types";
import classes from "../sass/CategoryService.module.scss";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";

export function CategoryService({ picker, service }) {
  const isSelected = picker.isServiceSelected(service.id);

  return (
    <div className={classes.root}>
      <Checkbox
        size="small"
        label={service.name}
        isChecked={isSelected}
        labelClassName={classes.label}
        onChange={() => {
          if (isSelected) {
            picker.deselectService(service.id);
          } else {
            picker.selectService(service.id);
          }
        }}
      />
      <div className={classes.location}>{service.clinicName}</div>
    </div>
  );
}

CategoryService.propTypes = {
  picker: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
};
