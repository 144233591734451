import { format } from "date-fns";
import cx from "clsx";
import { ModalInfo } from "../../../../../boxes/ModalInfo/ModalInfo";
import React, { useState } from "react";
import { getPathParams } from "../../utils/utils";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import styles from "./ClientSkinTest.module.scss";

export function ClientSkinTest() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });
  const { data: currentUser } = useCurrentUserQuery();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <div className={cx(styles.wrapper, "col-xs-12")}>
      <div className={styles.fitzpatrickBlock}>
        <div className={styles.title}>Fitzpatrick Skin Test</div>
        <div className={styles.testInfoBloc}>
          <div>
            Your Points: {clientData?.patientsSkinScore?.points}
          </div>
          <div>
            Skin Type: {clientData?.patientsSkinScore?.skin_type}
          </div>
          <div>
            Last Taken On:{" "}
            {format(
              clientData?.patientsSkinScore?.created,
              currentUser?.previewDateFormat,
            ) || ""}
          </div>
        </div>
        <button
          className={cx(styles.skinDescp, "line-btn", "pull-right")}
          type="button"
          onClick={onOpen}
        >
          View Details
        </button>
      </div>
      <ModalInfo
        onClose={onClose}
        isOpen={isOpen}
        onOk={onClose}
        titleSuffix="Skin Details"
      >
        {clientData?.patientsSkinScore?.skin_discription || ""}
      </ModalInfo>
    </div>
  );
}