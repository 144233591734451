import { useState } from "react";

export function useQuestionBuilderModal() {
  const [state, setState] = useState({
    isOpen: false,
    preloadedQuestion: null,
  });

  function onOpen(preloadedQuestion = null) {
    setState({
      isOpen: true,
      preloadedQuestion,
    });
  }

  function onClose() {
    setState({
      isOpen: false,
      preloadedQuestion: null,
    });
  }

  return {
    isOpen: state.isOpen,
    preloadedQuestion: state.preloadedQuestion,
    onOpen,
    onClose,
  };
}
