import { useSendChangePasswordOtpQuery } from "../../../api/queries/useSendChangePasswordOtpQuery";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";

export function useSendOtp(wpuId) {
  var CACHE_TIME = 15000;

  var { tCommon: t } = useAppTranslation.Common();

  var { isLoading, refetch } = useSendChangePasswordOtpQuery({
    payload: {
      wpuId,
    },
    options: {
      enabled: !!wpuId,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: (data) => {
        if (data.message) {
          uiNotification.success(
            t(`changePassword.apiSuccess.${data.message}`, {
              last4: data.last4,
            }),
          );
        } else {
          uiNotification.success(t("changePassword.success.sendOtp"));
        }
      },
      onError: (e) => {
        const message = extractApiError(e);
        uiNotification.error(
          t([
            `changePassword.apiError.${message}`,
            "changePassword.error.sendOtp",
          ]),
        );
      },
    },
  });

  return {
    isLoading,
    refetch,
  };
}
