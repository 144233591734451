import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./ButtonMenu.module.scss";
import { useClickOutside } from "../../utilities/hooks/useClickOutside";
import { useWindowSize } from "../../utilities/hooks/useWindowSize";

export function ButtonMenu({
  renderButton,
  options,
  className,
  optionClassName,
  menuClassName,
  closeOnOptionClick,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosClassName, setMenuPosClassName] = useState(
    classes.posBottomRight,
  );

  const rootRef = useRef(null);
  const { width, height } = useWindowSize();
  const menuClasses = cx(classes.menu, menuClassName, menuPosClassName);

  const handleOptionClick = (fn, closeOnClick) => {
    fn();
    if (closeOnClick) {
      setIsMenuOpen(false);
    }
  };

  const handleToggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!rootRef) {
      return;
    }

    const top = rootRef.current.getBoundingClientRect().top;
    const left = rootRef.current.getBoundingClientRect().left;
    const isRight = () => left > width / 2;
    const isTop = () => top < height / 2;

    if (isTop()) {
      if (isRight()) {
        setMenuPosClassName(classes.posBottomRight);
      } else {
        setMenuPosClassName(classes.posBottomLeft);
      }
    } else {
      if (isRight()) {
        setMenuPosClassName(classes.posTopRight);
      } else {
        setMenuPosClassName(classes.posTopLeft);
      }
    }
  }, [rootRef, height, width, window?.scrollX, window?.scrollY]);

  useClickOutside(rootRef, () => setIsMenuOpen(false));

  return (
    <div ref={rootRef} className={cx(classes.root, className)}>
      {renderButton({ isMenuOpen, onToggleMenu: handleToggleMenu })}
      {isMenuOpen && options.length > 0 && (
        <div className={menuClasses}>
          {options.map((option) => (
            <button
              key={option.key}
              className={cx(classes.option, optionClassName, option.className)}
              onClick={() =>
                handleOptionClick(
                  option.onClick,
                  option.closeOnClick || closeOnOptionClick,
                )
              }
            >
              {option.renderLabel
                ? option.renderLabel({ isMenuOpen })
                : option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

ButtonMenu.propTypes = {
  renderButton: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      className: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      renderLabel: PropTypes.func,
      label: PropTypes.string.isRequired,
      closeOnClick: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  optionClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  closeOnOptionClick: PropTypes.bool,
};

ButtonMenu.defaultProps = {
  options: [],
  className: undefined,
  optionClassName: undefined,
  menuClassName: undefined,
  closeOnOptionClick: true,
};
