import { isEmpty } from "lodash";
import { useMemo } from "react";
import * as yup from "yup";
import { useClinicPaymentAbilitiesAndCancelPolicyQuery } from "../../../api/queries/useClinicPaymentAbilitiesAndCancelPolicyQuery";

const schema = yup.object({
  clinicCanTakePaymentStatusById: yup.lazy((value) => {
    if (isEmpty(value)) {
      return yup.object();
    } else {
      return yup.object(
        Object.keys(value).reduce(
          (acc, val) => ({
            ...acc,
            [val]: yup.bool(),
          }),
          {},
        ),
      );
    }
  }),
  clearentPublicKeyByClinicIds: yup.lazy((value) => {
    if (isEmpty(value)) {
      return yup.object();
    } else {
      return yup.object(
        Object.keys(value).reduce(
          (acc, val) => ({
            ...acc,
            [val]: yup.string().nullable(),
          }),
          {},
        ),
      );
    }
  }),
  isCancelationPolicyEnabled: yup.bool(),
});

const compose = (response) => {
  let result = {};
  if (response && response.data.data) {
    const clinics = response.data.data.clinics || {};
    result = {
      clinicCanTakePaymentStatusById: Object.keys(clinics).reduce(
        (r, i) => ({ ...r, [i]: Boolean(clinics[i].can_take_payment) }),
        {},
      ),
      clearentPublicKeyByClinicIds: Object.keys(clinics).reduce(
        (r, i) => ({ ...r, [i]: clinics[i].public_key || null }),
        {},
      ),
      isCancelationPolicyEnabled: response.data.data.cancelationPolicy,
    };
  }
  return result;
};

export function useApiPaymentStatuses() {
  const { data, isFetching } = useClinicPaymentAbilitiesAndCancelPolicyQuery({
    staleTime: 0,
  });

  const paymentStatuses = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  return {
    paymentStatuses,
    isPaymentStatusesFetching: isFetching,
  };
}
