import { matchPath } from "react-router";
import { ROUTES } from "../../../consts/routes";
import { passOr } from "../../../utilities/general";
import { PATIENT_QUESTIONNAIRE_ENTITY_TYPES } from "../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery";

export function getPathParams() {
  const { params } = matchPath(window.location.pathname, {
    path: ROUTES.patientQuestionnaires.manyByEntity({
      type: ":type",
      id: ":id",
    }),
    exact: true,
    strict: false,
  });

  const isEntityTypeValid = Object.values(
    PATIENT_QUESTIONNAIRE_ENTITY_TYPES,
  ).includes(params.type);

  return {
    entityId: params.id ? Number(params.id) : null,
    entityType: passOr(isEntityTypeValid, null, () => params.type),
    isEntityTypeValid,
  };
}
