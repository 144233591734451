import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  id: yup.number().nullable(),
  vitalId: yup.number().required(),
  note: yup.string().required(),
});

export const usePatientVitalNotesMutation = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.patientVitals.createVitalNote(),
        removeNullishFromShape({
          id: req.id,
          patient_vital_id: req.vitalId,
          note: req.note,
        }),
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.vitalNotes],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.all],
        });
        options?.onSuccess?.(...args);
      },
    },
  );
};
