import React, { Component } from "react";
import * as yup from "yup";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import {
  fetchSelectedAgreement,
  createAgreement,
  deleteAgreement,
  exportEmptyData,
  updateSelectedAgreement,
} from "../../../../Actions/Settings/settingsActions.js";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loader from "../../../../Components/Common/Loader.js";
import { FileParser } from "../../../../widgets/FileParser/FileParser.js";
import { uiNotification } from "../../../../services/UINotificationService.js";
import { Tabs } from "../../../../shared/Tabs/Tabs.js";
import classes from "../ManageClinics.module.scss";
import cx from "clsx";
import { MultiPostFromFilesContent } from "./components/MultiPostFromFilesContent.js";
import { MultiPostFromFiles } from "../MultiPostFromFiles/MultiPostFromFiles.js";
import { withMultiPostFromFiles } from "../MultiPostFromFiles/withMultiPostFromFiles.js";
import { http } from "../../../../services/HttpService.js";
import { HTTP_ENDPOINTS } from "../../../../consts/api.js";
import { FORM_TYPE } from "../consts.js";
import { TextEditor } from "../../../../shared/TextEditor/TextEditor.js";

class CreateEditMembershipAgreement extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.state = {
      formType: FORM_TYPE.single,
      agreement_name: "",
      agreement_text: "",
      agreeData: {},
      userId: userData.user.id,
      id: this.props.match.params.id,
      page: 1,
      pagesize: 15,
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      showLoader: false,
      scopes: "business_hours",
      user_changed: false,
      hideBtns: false,
      showModal: false,
      editorFiles: [],
    };
    window.onscroll = () => {
      return false;
    };
  }
  componentDidMount() {
    window.onscroll = () => {
      return false;
    };
    const agreementId = this.props.match.params.id;
    if (agreementId) {
      this.setState({ agreementId: agreementId });
    }
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      create_edit_agreement_name:
        languageData.settings["create_edit_agreement_name"],
      settings_add_consent: languageData.settings["settings_add_consent"],
      delete_confirmation: languageData.global["delete_confirmation"],
      create_edit_consent_body:
        languageData.settings["create_edit_consent_body"],
      consent_delete_warning: languageData.settings["consent_delete_warning"],
      yes_option: languageData.settings["yes_option"],
      no_option: languageData.settings["no_option"],
      add_agreement: languageData.settings["add_agreement"],
      edit_agreement: languageData.settings["edit_agreement"],
    });
    let formData = { params: {} };

    if (agreementId) {
      this.setState({ showLoader: true });
      this.props.fetchSelectedAgreement(formData, agreementId);
    } else {
      this.props.exportEmptyData({});
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showLoader != undefined && props.showLoader == false) {
      return { showLoader: false };
    }
    if (
      props.agreeData !== undefined &&
      props.agreeData.status === 200 &&
      props.consentDataTime != state.consentDataTime
    ) {
      return {
        agreement_name: props.agreeData.data.name
          ? props.agreeData.data.name
          : "",
        agreement_text: props.agreeData.data.agreement_text
          ? props.agreeData.data.agreement_text
          : "",
        showLoader: false,
        consentDataTime: props.consentDataTime,
      };
    } else if (props.redirect != undefined && props.redirect == true) {
      uiNotification.success(props.message);
      props.history.push("/settings/membership-agreements");
    }
    return null;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
      userChanged: true,
    });
  };

  showDeleteModal = () => {
    this.setState({ showModal: true });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  deleteAgreement = () => {
    this.setState({ showLoader: true, hideBtns: true });
    this.dismissModal();
    let cId = this.state.id;
    this.props.deleteAgreement(cId);
  };

  handleSubmit = () => {
    let error = false;
    this.setState({
      agreement_name_Error: false,
      agreement_text_Error: false,
    });
    if (
      typeof this.state.agreement_name === "undefined" ||
      this.state.agreement_name === null ||
      this.state.agreement_name === "" ||
      this.state.agreement_name.trim() == ""
    ) {
      this.setState({
        agreement_name_Error: true,
      });
      error = true;
    }
    if (
      typeof this.state.agreement_text === "undefined" ||
      this.state.agreement_text === null ||
      this.state.agreement_text === "" ||
      this.state.agreement_text.trim() == ""
    ) {
      this.setState({
        agreement_text_Error: true,
      });
      error = true;
    }

    if (error === true) {
      return;
    }

    const agreementId = this.props.match.params.id;

    let formData = {
      name: this.state.agreement_name,
      agreement_text: this.state.agreement_text,
      id: this.state.agreementId,
    };

    this.setState({
      membership_name: this.state.agreement_name,
      agreement_text: this.state.agreement_text,
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      consentData: [],
      showLoader: true,
    });

    if (agreementId) {
      this.props.updateSelectedAgreement(formData);
    } else {
      this.props.createAgreement(formData);
    }
  };

  handleEditorChange = (content) => {
    this.setState({ agreement_text: content });
  };

  handleChangeTab = (formType) => {
    const msg = "Your changes will be lost. Confirm?";
    const confirmMap = {
      [FORM_TYPE.single]: this.state.editorFiles.length > 0,
    };
    if ((confirmMap[formType] && confirm(msg)) || !confirmMap[formType]) {
      this.setState({ formType });
    }
  };

  handleSaveSingleFile = (file, { onRemoveFile }) => {
    this.props.multiPostFromFiles
      .post([file])
      .then(() => {
        uiNotification.success.bind(uiNotification)(
          `Successfully Created '${file.title}' Membership Agreement`
        );
        onRemoveFile(file.id);
      })
      .catch((e) => {
        uiNotification.error.bind(uiNotification)(e.message);
      });
  };

  handleSaveMultipleFiles = (files, { onRemoveAll }) => {
    if (
      confirm(
        "Are you sure you want to save all files? Some of them may not parsed correctly or include some mistakes that you don't want to submit without review. Continue anyway?"
      )
    ) {
      this.props.multiPostFromFiles
        .post(files)
        .then(() => {
          uiNotification.success.bind(uiNotification)(
            "Successfully Created Membership Agreement From Multiple Files"
          );
          onRemoveAll();
          this.props.history.push("/settings/membership-agreements");
        })
        .catch((e) => {
          uiNotification.error.bind(uiNotification)(e.message);
        });
    }
  };

  render() {
    const isEditMode = Boolean(this.state.id);
    const { multiPostFromFiles } = this.props;

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div
              className={cx("setting-setion", classes.multiPostFromFilesRoot)}
            >
              <div className="setting-container">
                <div className="setting-title m-b-20">
                  {this.state.agreementId
                    ? this.state.edit_agreement
                    : this.state.add_agreement}
                  <Link
                    to="/settings/membership-agreements"
                    className="pull-right cancelAction"
                  >
                    <img src={"../../../../images/close.png"} alt="x" />
                  </Link>
                </div>
                {!isEditMode && (
                  <Tabs
                    className="f-left m-b-20"
                    active={this.state.formType}
                    options={[
                      {
                        label: "Create",
                        value: FORM_TYPE.single,
                        onClick: () => this.handleChangeTab(FORM_TYPE.single),
                      },
                      {
                        label: "Upload Files",
                        value: FORM_TYPE.multipleFromFiles,
                        onClick: () =>
                          this.handleChangeTab(FORM_TYPE.multipleFromFiles),
                      },
                    ]}
                  />
                )}
                {this.state.formType === FORM_TYPE.single ? (
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          {"Agreement Title"}
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          name="agreement_name"
                          required="required"
                          className={
                            this.state.agreement_name_Error === true
                              ? "_name newInputField field_error newInputField"
                              : "consent_name newInputField "
                          }
                          id="agreement_name"
                          placeholder="Agreement Title"
                          maxLength={255}
                          type="text"
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={this.state.agreement_name}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div
                        className={cx(
                          classes.singlePostRichEditor,
                          "newInputFileldOuter",
                          {
                            field_error: this.state.agreement_text_Error,
                          }
                        )}
                      >
                        <TextEditor
                          value={this.state.agreement_text || ""}
                          className={this.state.membership_agreement_textClass}
                          onChange={this.handleEditorChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={cx("row", classes.multiPostFromFilesRow)}>
                    <FileParser
                      withEditor
                      selectFileAsync
                      output="html"
                      onError={uiNotification.error.bind(uiNotification)}
                      footerClassName={classes.multiPostFromFilesFooter}
                      renderFooter={({ isFilesSelected, onOpenEditor }) => (
                        <MultiPostFromFiles.Footer
                          isFilesSelected={isFilesSelected}
                          onOpenEditor={onOpenEditor}
                        />
                      )}
                      editor={{
                        isRemoveAllDisabled: multiPostFromFiles.isPosting,
                        isSelectFilesDisabled: multiPostFromFiles.isPosting,
                        isFilesDisabled: multiPostFromFiles.isPosting,
                        onRemoveAllCb:
                          multiPostFromFiles.resetValidationErrorLogs,
                        onRemoveFileCb:
                          multiPostFromFiles.resetValidationErrorLogs,
                        renderFooter: ({
                          currentFile,
                          files,
                          isFilePending,
                          isFilesParsing,
                          onRemoveFile,
                          onRemoveAll,
                        }) => (
                          <MultiPostFromFiles.EditorFooter
                            onPostMultiple={() =>
                              this.handleSaveMultipleFiles(files, {
                                onRemoveAll,
                              })
                            }
                            onPostSingle={() =>
                              this.handleSaveSingleFile(currentFile, {
                                onRemoveFile,
                              })
                            }
                            currentFile={currentFile}
                            files={files}
                            isFilePending={isFilePending}
                            isFilesParsing={isFilesParsing}
                            errorLogs={multiPostFromFiles.validationErrorLogs}
                            isPosting={multiPostFromFiles.isPosting}
                          />
                        ),
                        renderFilesMobileFooter: ({
                          files,
                          isFilesParsing,
                          isFilePending,
                          onRemoveAll,
                        }) => (
                          <MultiPostFromFiles.EditorFilesMobileFooter
                            isFilePending={isFilePending}
                            isFilesParsing={isFilesParsing}
                            onPostMultiple={() =>
                              this.handleSaveMultipleFiles(files, {
                                onRemoveAll,
                              })
                            }
                            errorLogs={multiPostFromFiles.validationErrorLogs}
                            isPosting={multiPostFromFiles.isPosting}
                          />
                        ),
                        renderContent: ({
                          currentFile,
                          onEditFile,
                          isFilePending,
                        }) => (
                          <MultiPostFromFilesContent
                            currentFile={currentFile}
                            onEditFile={onEditFile}
                            isFilePending={isFilePending}
                            isPosting={multiPostFromFiles.isPosting}
                          />
                        ),
                        onFilesChange: (editorFiles) => {
                          this.setState({ editorFiles });
                        },
                      }}
                    />
                  </div>
                )}
              </div>
              {this.state.formType === FORM_TYPE.single && (
                <div className="footer-static">
                  {this.props.match.params.id != undefined && (
                    <input
                      className="new-red-btn pull-left confirm-model"
                      type="button"
                      value="Delete"
                      onClick={this.showDeleteModal}
                    />
                  )}
                  {!this.state.hideBtns && (
                    <button
                      type="button"
                      id="save_clinic"
                      className="new-blue-btn pull-right"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                  )}
                  {!this.state.hideBtns && (
                    <Link
                      to="/settings/membership-agreements"
                      className="new-white-btn pull-right cancelAction"
                    >
                      Cancel
                    </Link>
                  )}
                </div>
              )}
              <div className={this.state.showModal ? "overlay" : ""}></div>
              <div
                id="filterModal"
                role="dialog"
                className={
                  this.state.showModal
                    ? "modal fade in displayBlock"
                    : "modal fade"
                }
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={this.dismissModal}
                      >
                        ×
                      </button>
                      <h4 className="modal-title" id="model_title">
                        {this.state.delete_confirmation}
                        {this.state.showModal}
                      </h4>
                    </div>
                    <div
                      id="errorwindow"
                      className="modal-body add-patient-form filter-patient"
                    >
                      {
                        "Are you sure you want to delete this membership agreement?"
                      }
                    </div>
                    <div className="modal-footer">
                      <div className="col-md-12 text-left" id="footer-btn">
                        <button
                          type="button"
                          className="btn  logout pull-right"
                          data-dismiss="modal"
                          onClick={this.dismissModal}
                        >
                          {this.state.no_option}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success pull-right m-r-10"
                          data-dismiss="modal"
                          onClick={this.deleteAgreement}
                        >
                          {this.state.yes_option}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.SettingReducer.action === "SELECTED_AGREEMENT_LIST") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.agreeData = state.SettingReducer.data;
      returnState.consentDataTime = new Date();
    }
  } else if (state.SettingReducer.action === "CREATE_AGREEMENT") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
    } else {
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
      returnState.redirect = true;
    }
  } else if (state.SettingReducer.action === "UPDATE_SELECTED_AGREEMENT") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
    } else {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  } else if (state.SettingReducer.action === "AGREEMENT_DELETED") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
    } else {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  }
  if (state.SettingReducer.action === "EMPTY_DATA") {
    return {};
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSelectedAgreement: fetchSelectedAgreement,
      createAgreement: createAgreement,
      updateSelectedAgreement: updateSelectedAgreement,
      deleteAgreement: deleteAgreement,
      exportEmptyData: exportEmptyData,
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withMultiPostFromFiles(CreateEditMembershipAgreement, {
      submitFile: (file) =>
        http.post(HTTP_ENDPOINTS.postMembership(), {
          name: file.title,
          agreement_text: file.text,
        }),
      composeFileForSubmit: (file) => ({
        title: file.title || "",
        text: file.parsedContent || "",
      }),
      submitFileValidationSchema: yup.object({
        title: yup.string().required("Agreement title is required"),
        text: yup.string().required("Agreement body is required"),
      }),
    })
  )
);
