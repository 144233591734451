import React, { useEffect, useState } from "react";
import cx from "clsx";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../shared/Select/Select";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { useClinicsOptions } from "../hooks/useClinicsOptions";
import { usePatientOptions } from "../../../../../Inbox/EfaxOrders/shared/ModalAdd/hooks/usePatientOptions";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import FavoritesHandler from "./FavoritesHandler";
import { useEfaxTests } from "../hooks/useEfaxTests";
import EfaxTest from "./EfaxTest";
import styles from "../styles.module.scss";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import Loader from "../../../../../Common/Loader";
import { useClientQuery } from "../../../../../../api/queries/useClientQuery";
import { Checkbox } from "../../../../../../shared/Checkbox/Checkbox";

const AddFaxForm = ({ clientId, form, onClose, isLoading }) => {
  const { tInbox } = useAppTranslation.Inbox();

  const [createMode, setCreateMode] = useState(false);
  const [expanded, setExpanded] = useState([]);

  const clinicsOptions = useClinicsOptions();
  const patientOptions = usePatientOptions();
  const tests = useEfaxTests();
  const { data: client } = useClientQuery({
    clientId: parseInt(clientId || form.patient.value?.value),
  });

  const modalLoading = form.favorites.options?.isLoading || tests?.isLoading;

  useEffect(() => {
    if (client?.clinicId && !form.clinic.value) {
      const clinic = clinicsOptions?.find(
        (clinic) => clinic.value === client.clinicId,
      );
      if (clinic) {
        form.clinic.onChange(clinic);
      }
    }
  }, [client, clinicsOptions, form.user]);

  return (
    <Modal
      isOpen
      footerNoBorder
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      footerClassName={cx("flex", "justify-end")}
      header={
        <Modal.Title>{`${tInbox("efaxOrders.modalAdd.title")} ${
          client ? `for ${client.fullName}` : ""
        }`}</Modal.Title>
      }
      footer={
        <Button
          onClick={() => form.submit()}
          isDisabled={createMode || isLoading}
          leftAdornment={
            isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tInbox("efaxOrders.modalAdd.continue")}
        </Button>
      }
      contentClassName={styles.root}
    >
      <div>
        <InputLabel>{tInbox("efaxOrders.modalAdd.clinic")}</InputLabel>
        <Select
          isSearchable
          value={form.clinic.value}
          onChange={form.clinic.onChange}
          options={clinicsOptions}
          isError={Boolean(form.clinic.getError())}
        />
        {Boolean(form.clinic.getError()) && (
          <InputError>{form.clinic.getError()}</InputError>
        )}
      </div>

      <div>
        <InputLabel>{tInbox("efaxOrders.modalAdd.user")}</InputLabel>
        <Select
          isSearchable
          value={form.user.value}
          onChange={form.user.onChange}
          options={form.user.options}
        />
        {Boolean(form.user.getError()) && (
          <InputError>{form.user.getError()}</InputError>
        )}
      </div>

      {!clientId && (
        <div>
          <InputLabel>{tInbox("efaxOrders.modalAdd.patient")}</InputLabel>
          <Select
            isSearchable
            value={form.patient.value}
            onChange={form.patient.onChange}
            onInputChange={(x) => patientOptions.search.onChange(x)}
            options={patientOptions.value}
            isError={Boolean(form.patient.getError())}
            isLoading={
              patientOptions.isLoading || patientOptions.isNextFetching
            }
            menuListFooter={
              <LoadMoreObserver
                noPaddingBottom
                loadMore={patientOptions.fetchMore}
                isLoading={patientOptions.isNextFetching}
              />
            }
          />
          {Boolean(form.patient.getError()) && (
            <InputError>{form.patient.getError()}</InputError>
          )}
        </div>
      )}
      <div>
        <InputLabel>{`${tInbox("efaxOrders.modalAdd.toNumber")} ${
          form.favorites?.value?.label || ""
        }`}</InputLabel>
        <FavoritesHandler
          favorites={form.favorites}
          favoritesOptions={form.favorites.options.value}
          faxNumber={form.faxNumber}
          createMode={createMode}
          setCreateMode={setCreateMode}
        />
      </div>
      <div>
        <InputLabel>{tInbox("efaxOrders.modalAdd.template")}</InputLabel>
        <Select
          isSearchable
          value={form.template.value}
          onChange={form.template.onChange}
          options={form.template.options}
          isError={Boolean(form.template.getError())}
        />
        {Boolean(form.template.getError()) && (
          <InputError>{form.template.getError()}</InputError>
        )}
        <Checkbox
          label={tInbox("efaxOrders.modalAdd.makeDefaultTemplate")}
          className={cx("m-t-10")}
          isChecked={form.makeDefault.value}
          onChange={form.makeDefault.onChange}
        />
      </div>
      <div className={styles.testsContainer}>
        <InputLabel>{tInbox("efaxOrders.modalAdd.panels")}</InputLabel>
        {tests?.map((test) => (
          <EfaxTest
            key={test.name}
            expanded={expanded}
            setExpanded={setExpanded}
            checked={form.checkedTests.value}
            setChecked={form.checkedTests.onChange}
            name={test.name}
            tests={test.tests}
          />
        ))}
        {Boolean(form.checkedTests.getError()) && (
          <InputError>{form.checkedTests.getError()}</InputError>
        )}
      </div>
      {modalLoading && <Loader showLoader />}
    </Modal>
  );
};

export default AddFaxForm;
