import { useEffect, useState } from "react";
import { CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS as ADDITION_FIELDS } from "../../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { useClientQuery } from "../../../api/queries/useClientQuery";
import { Maybe } from "../../../utilities/fp";

const addressFields = [
  ADDITION_FIELDS.address,
  ADDITION_FIELDS.city,
  ADDITION_FIELDS.state,
  ADDITION_FIELDS.pincode,
];

const shippingAddressFields = [
  ADDITION_FIELDS.shippingAddress,
  ADDITION_FIELDS.shippingCity,
  ADDITION_FIELDS.shippingState,
  ADDITION_FIELDS.shippingZipcode,
];

const toggleFieldsVisibility = (fields, value, scopeFields) =>
  Object.entries(fields).reduce(
    (carry, [field, fieldDesc]) => ({
      ...carry,
      [field]: {
        ...fieldDesc,
        visible: scopeFields.includes(field) ? value : fieldDesc.visible,
      },
    }),
    {},
  );

const selectRequiredAndVisible = (fields) => {
  return Object.entries(fields)
    .filter(([_, fieldDesc]) => fieldDesc.visible && fieldDesc.required)
    .map(([field]) => field);
};

const initAdditionFields = (fields) => {
  return fields.reduce(
    (c, x) => ({
      ...c,
      [x]: {
        required: true,
        visible: true,
      },
    }),
    {},
  );
};

const getPatientsAdditionFields = (patient) => {
  const fieldsMap = {
    [ADDITION_FIELDS.firstName]: patient.firstName,
    [ADDITION_FIELDS.lastName]: patient.lastName,
    [ADDITION_FIELDS.email]: patient.email,
    [ADDITION_FIELDS.phone]: patient.phone,
    [ADDITION_FIELDS.dateOfBirth]: patient.dateOfBirth,
    [ADDITION_FIELDS.gender]: patient.gender,
    [ADDITION_FIELDS.city]: patient.city,
    [ADDITION_FIELDS.state]: patient.state,
    [ADDITION_FIELDS.pincode]: patient.pinCode,
    [ADDITION_FIELDS.address]: patient.address1,
  };

  return Object.entries(fieldsMap).reduce(
    (carry, [key, field]) => ({
      ...carry,
      [key]: {
        required: !field,
        visible: !field,
      },
    }),
    {},
  );
};

export function useAdditionFields({ patientId }) {
  const [isInitialized, setIsInitialized] = useState(false);

  const [additionFields, setAdditionFields] = useState(
    initAdditionFields(Object.values(ADDITION_FIELDS)),
  );

  const replaceAdditionFields = (next) =>
    setAdditionFields(initAdditionFields(next));

  const { data: patient, isLoading } = useClientQuery(
    { clientId: Maybe.of(patientId).map(Number).orElse(0).value() },
    {
      enabled: !!patientId,
    },
  );

  const setShippingAddressFieldsVisibility = (value) => {
    setAdditionFields((prev) =>
      toggleFieldsVisibility(prev, value, shippingAddressFields),
    );
  };

  const setAddressFieldsVisibility = (value) => {
    setAdditionFields((prev) =>
      toggleFieldsVisibility(prev, value, addressFields),
    );
  };

  useEffect(() => {
    if (patient && !isInitialized) {
      setAdditionFields((prev) => ({
        ...prev,
        ...getPatientsAdditionFields(patient),
      }));

      setShippingAddressFieldsVisibility(false);
      setAddressFieldsVisibility(false);
      setIsInitialized(true);
    }
  }, [patient, isInitialized]);

  return {
    additionFields: selectRequiredAndVisible(additionFields),
    setAdditionFields: replaceAdditionFields,
    setShippingAddressFieldsVisibility,
    setAddressFieldsVisibility,
    isLoading,
  };
}
