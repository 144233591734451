import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { passOr, removeNullishFromShape } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  productId: yup.number().required(),
  pageSize: yup.number().required(),
  dateFrom: yup.date().nullable(),
  dateTo: yup.date().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    logs: yup.array().of(
      yup.object({
        id: yup.number().required(),
        action: yup.string().nullable(),
        cost_to_company: yup.number().nullable(),
        inventory: yup
          .object({
            id: yup.number().nullable(),
            clinic: yup
              .object({
                id: yup.number().nullable(),
                clinic_name: yup.string().nullable(),
              })
              .nullable(),
            batch_id: yup.string().nullable(),
          })
          .nullable(),
        product_id: yup.number().required(),
        reason: yup.string().nullable(),
        stock: yup.number().nullable(),
        unit_label: yup.string().nullable(),
        units: yup.number().nullable(),
        user: yup
          .object({
            id: yup.number().nullable(),
            firstname: yup.string().nullable(),
            lastname: yup.string().nullable(),
          })
          .nullable(),
        created: yup.string().required(),
      }),
    ),
  }),
);

const composeResponse = (res, req) => {
  return {
    logs: res?.data?.data?.data || [],
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
  };
};

function createProductInventoryLogsQueryKey({
  productId,
  pageSize,
  dateFrom,
  dateTo,
}) {
  return [
    QUERY_KEYS.productInventoryLogs,
    productId,
    pageSize,
    dateFrom,
    dateTo,
  ].filter(Boolean);
}

export function useProductInventoryLogsQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = passOr(req.dateFrom, null, () =>
    moment(req.dateFrom).format(API_DATE_FORMAT),
  );

  const toDate = passOr(req.dateTo, null, () =>
    moment(req.dateTo).format(API_DATE_FORMAT),
  );

  return useInfiniteQuery({
    queryKey: createProductInventoryLogsQueryKey({
      productId: req.productId,
      pageSize: req.pageSize,
      dateFrom: fromDate,
      dateTo: toDate,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(
        HTTP_ENDPOINTS.getProductInventoryLogs(req.productId),
        {
          params: removeNullishFromShape({
            page: pageParam,
            pagesize: req.pageSize,
            date_from: fromDate,
            date_to: toDate,
          }),
        },
      );
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
