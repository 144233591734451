import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Divider.module.scss";

export function Divider({ className, gap }) {
  return (
    <div
      className={cx(classes.root, className)}
      style={{
        margin: `${gap} 0px`,
      }}
    />
  );
}

Divider.propTypes = {
  className: PropTypes.string,
  gap: PropTypes.string,
};

Divider.defaultProps = {
  className: undefined,
  gap: "0px",
};
