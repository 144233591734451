import { useMemo } from "react";
import { useProvidersQuery } from "../../../../api/queries/useProvidersQuery";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

export function useProviderOptions() {
  const { tCommon } = useAppTranslation.Common();

  const { data, isLoading } = useProvidersQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchProviders"));
    },
  });

  return useMemo(() => {
    const preparedData =
      data?.data?.data?.map((p) => ({
        label: p.full_name,
        value: p.id,
      })) || [];

    return {
      data: preparedData,
      isLoading,
    };
  }, [data?.data?.data, isLoading]);
}
