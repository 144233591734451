const initalRecentlyDeletedPaperworkState = {
  isFetching: false,
  paperworkData: {
    isError: null,
    data: {},
  },
  restorePaperworkData: {
    isError: null,
    data: "",
  },
};

export const settingRecentlyDeletedPaperworkReducer = (
  state = initalRecentlyDeletedPaperworkState,
  action,
) => {
  switch (action.type) {
    case "CLEAR_RESTORE_PAPERWORK": {
      return {
        ...state,
        restorePaperworkData: {
          ...initalRecentlyDeletedPaperworkState.restorePaperworkData,
        },
      };
    }
    case "CLEAR_RECENTLY_DELETED_PAPERWORK": {
      return {
        ...state,
        paperworkData: { ...initalRecentlyDeletedPaperworkState.paperworkData },
        restorePaperworkData: {
          ...initalRecentlyDeletedPaperworkState.restorePaperworkData,
        },
      };
    }
    case "RECENTLY_DELETED_PAPERWORK_REQUEST": {
      return {
        ...state,
        isFetching: true,
      };
    }
    case "RECENTLY_DELETED_PAPERWORK_SUCCESS": {
      return {
        ...state,
        isFetching: false,
        paperworkData: {
          isError: null,
          data: action.data,
        },
      };
    }
    case "RECENTLY_DELETED_PAPERWORK_FAILURE": {
      return {
        ...state,
        isFetching: false,
        paperworkData: {
          isError: action.error,
          data: null,
        },
      };
    }
    case "RESTORE_PAPERWORK_SUCCESS": {
      return {
        ...state,
        isFetching: false,
        restorePaperworkData: {
          isError: null,
          data: action.data,
        },
      };
    }
    case "RESTORE_PAPERWORK_FAILURE": {
      return {
        ...state,
        isFetching: false,
        restorePaperworkData: {
          isError: action.error,
          data: null,
        },
      };
    }
    default:
      return state;
  }
};
