import checkPrimary from "./svg/ic_check-primary.svg";
import checkGreen from "./svg/ic_check-green.svg";
import chevronDown from "./svg/ic_chevron-down.svg";
import chevronDownWhite from "./svg/ic_chevron-down-white.svg";
import chevronDownPrimary from "./svg/ic_chevron-down-primary.svg";
import close from "./svg/ic_close.svg";
import plusPrimary from "./svg/ic_plus-primary.svg";
import closeRed from "./svg/ic_close-red.svg";
import calendarPrimary from "./svg/ic_calendar-primary.svg";
import calendarGray0 from "./svg/ic_calendar-gray0.svg";
import calendarError from "./svg/ic_calendar-error.svg";
import calendar from "./svg/ic_calendar.svg";
import chevronLeftPrimary from "./svg/ic_chevron-left-primary.svg";
import arrowLeftPrimary from "./svg/ic_arrow-left-primary.svg";
import addFileGray1 from "./svg/ic_add-file-gray1.svg";
import addFilePrimary from "./svg/ic_add-file-primary.svg";
import noImage from "./svg/ic_no-image.svg";
import searchGray1 from "./svg/ic_search-gray1.svg";
import searchPrimary from "./svg/ic_search-primary.svg";
import errorRed from "./svg/ic_error-red.svg";
import errorWhite from "./svg/ic_error-white.svg";
import checkCircleGreen from "./svg/ic_check-circle-green.svg";
import crossCircleRed from "./svg/ic_cross-circle-red.svg";
import followLinkPrimary from "./svg/ic_follow-link-primary.svg";
import triangleUpPrimary from "./svg/ic_triangle-up-primary.svg";
import filterPrimary from "./svg/ic_filter-primary.svg";
import filterPrimaryActive from "./svg/ic_filter-primary-active.svg";
import downloadPrimary from "./svg/ic_download-primary.svg";
import infoBlue from "./svg/ic_info-blue.svg";
import calendar2Black from "./svg/ic_calendar2-black.svg";
import ccPrimary from "./svg/ic_cc-primary.svg";
import infoPrimary from "./svg/ic_info-primary.svg";
import cashPrimary from "./svg/ic_cash-primary.svg";
import arWalletPrimary from "./svg/ic_ar-wallet-primary.svg";
import cherryPay from "./svg/ic_cherry-pay.svg";
import checkPayPrimary from "./svg/ic_check-pay-primary.svg";
import giftCardPrimary from "./svg/ic_gift-card-primary.svg";
import chevronRightGray from "./svg/ic_chevron-right-gray.svg";
import checkCircleGreen2 from "./svg/ic_check-circle-green2.svg";
import trashRed from "./svg/ic_trash-red.svg";
import text2Pay from "./svg/ic_text2pay.svg";
import alertTime from "./svg/ic_alert-time.svg";

/**
 * @deprecated Do not use SVG as image URLs. Create SVG as component inside `./Icons` instead.
 * You can copy svg code from https://lucide.dev/icons/
 */
export const svg = {
  checkPrimary,
  checkGreen,
  chevronDown,
  chevronDownWhite,
  chevronDownPrimary,
  close,
  plusPrimary,
  closeRed,
  calendarPrimary,
  calendarGray0,
  calendar,
  calendarError,
  chevronLeftPrimary,
  arrowLeftPrimary,
  addFileGray1,
  addFilePrimary,
  noImage,
  searchGray1,
  searchPrimary,
  errorRed,
  errorWhite,
  checkCircleGreen,
  crossCircleRed,
  followLinkPrimary,
  triangleUpPrimary,
  filterPrimary,
  filterPrimaryActive,
  downloadPrimary,
  infoBlue,
  calendar2Black,
  ccPrimary,
  infoPrimary,
  cashPrimary,
  arWalletPrimary,
  cherryPay,
  checkPayPrimary,
  giftCardPrimary,
  chevronRightGray,
  checkCircleGreen2,
  trashRed,
  alertTime,
  text2Pay,
};
