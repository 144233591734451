import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

export function usePatientWelcomeEmailMutation({
  onError,
  onSuccess,
  onMutate,
  onSettled,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, { strict: true });

      return http.get(HTTP_ENDPOINTS.patient.getWelcomeEmail(req.clientId));
    },
    onMutate: () => {
      onMutate?.();
    },
    onSettled: () => {
      onSettled?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.client],
      });
      onSuccess?.();
    },
    onError: () => {
      onError?.();
    },
  });
}
