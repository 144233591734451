import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DateRangePicker as ReactDateRange } from "react-date-range";
import cx from "clsx";
import moment from "moment";
import classes from "./DateRangePicker.module.scss";
import { useWindowSize } from "../../utilities/hooks/useWindowSize";
import { useClickOutside } from "../../utilities/hooks/useClickOutside";
import { svg } from "../../assets/svg";
import {
  DEFAULT_VALUE,
  EMPTY_RANGE_PREVIEW,
  PICKER_KEY,
  SIZE,
} from "./DateRangePicker.consts";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { getStaticRanges } from "./DatePickerRange.utils";
import { DEFAULT_DATE_FORMAT } from "../../consts/general";
import "./DateRangePicker.css";
import { CrossIcon } from "../../assets/Icons/CrossIcon";

export function DateRangePicker({
  value,
  onChange,
  className,
  pickerClassName,
  maxDate,
  dragSelectionEnabled,
  size,
  buttonClassName,
  staticRanges,
  isDisabled,
  dateFormat,
  onClear,
  isError,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [pickerPosClassName, setPickerPosClassName] = useState(
    classes.posBottomRight,
  );

  const rootRef = useRef(null);
  const { width, height } = useWindowSize();

  const handleChange = (data) => {
    const { startDate, endDate } = data[PICKER_KEY] || {};
    onChange({
      startDate: moment.utc(startDate).toDate(),
      endDate: moment.utc(endDate).toDate(),
    });
  };

  useEffect(() => {
    if (!rootRef) {
      return;
    }

    const isRight = () => rootRef.current.offsetLeft > width / 2;
    const isTop = () => rootRef.current.offsetTop < height / 2;

    if (isTop()) {
      if (isRight()) {
        setPickerPosClassName(classes.posBottomRight);
      } else {
        setPickerPosClassName(classes.posBottomLeft);
      }
    } else {
      if (isRight()) {
        setPickerPosClassName(classes.posTopRight);
      } else {
        setPickerPosClassName(classes.posTopLeft);
      }
    }
  }, [rootRef, height, width]);

  useClickOutside(rootRef, () => setIsPickerOpen(false));
  const pickerClasses = cx(classes.picker, pickerClassName, pickerPosClassName);

  const buttonClasses = cx(
    classes.button,
    {
      [classes.buttonSmall]: size === SIZE.small,
      [classes.buttonDisabled]: isDisabled,
      [classes.buttonError]: isError,
    },
    buttonClassName,
  );

  const isClearAvailable = onClear && Object.values(value).some(Boolean);

  return (
    <div
      ref={rootRef}
      className={cx(
        classes.root,
        "DateRangePicker_shared-component",
        className,
      )}
    >
      <button
        type="button"
        className={buttonClasses}
        disabled={isDisabled}
        onClick={() => setIsPickerOpen((prev) => !prev)}
      >
        {!value.startDate && !value.endDate ? (
          <div
            className={cx(classes.placeholder, {
              [classes.placeholderError]: isError,
            })}
          >
            {tCommon("label.selectDateRange")}
          </div>
        ) : (
          `${
            value.startDate
              ? moment(value.startDate).format(dateFormat)
              : EMPTY_RANGE_PREVIEW
          } - ${
            value.endDate
              ? moment(value.endDate).format(dateFormat)
              : EMPTY_RANGE_PREVIEW
          }`
        )}
        {isClearAvailable ? (
          <button
            className={classes.invisibleBtn}
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          >
            <CrossIcon width="18px" />
          </button>
        ) : (
          <img src={svg.calendar2Black} alt="calendar" width="20px" />
        )}
      </button>
      {isPickerOpen && (
        <ReactDateRange
          className={pickerClasses}
          ranges={[
            {
              startDate: value.startDate || undefined,
              endDate: value.endDate || undefined,
              key: PICKER_KEY,
            },
          ]}
          onChange={handleChange}
          maxDate={maxDate}
          dragSelectionEnabled={dragSelectionEnabled}
          staticRanges={getStaticRanges(staticRanges)}
          dateDisplayFormat={dateFormat}
        />
      )}
    </div>
  );
}

DateRangePicker.propTypes = {
  value: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  dragSelectionEnabled: PropTypes.bool,
  pickerClassName: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZE)),
  buttonClassName: PropTypes.string,
  staticRanges: PropTypes.array,
  isDisabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  onClear: PropTypes.func,
  isError: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  value: DEFAULT_VALUE,
  className: undefined,
  maxDate: new Date(),
  dragSelectionEnabled: false,
  pickerClassName: undefined,
  size: undefined,
  buttonClassName: undefined,
  staticRanges: undefined,
  isDisabled: false,
  dateFormat: DEFAULT_DATE_FORMAT,
  isError: false,
};
