const initialState = {
  evolusConfigData: {
    status: "pending",
    error: null,
  },
  saveEvolusConfigData: {
    status: "pending",
    error: null,
  },
  evolusFacilitiesData: {
    status: "pending",
    error: null,
  },
};

const EvolusIntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EVOLUS_CONFIG_LOADING": {
      return {
        ...state,
        evolusConfigData: {
          ...state.evolusConfigData,
          status: "loading",
          error: null,
        },
      };
    }
    case "FETCH_EVOLUS_CONFIG_SUCCESS": {
      return {
        ...state,
        evolusConfigData: {
          ...state.evolusConfigData,
          ...action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case "FETCH_EVOLUS_CONFIG_ERROR": {
      return {
        ...state,
        evolusConfigData: {
          ...state.evolusConfigData,
          status: "error",
          error: action.payload,
        },
      };
    }
    case "SAVE_EVOLUS_CONFIG_LOADING": {
      return {
        ...state,
        saveEvolusConfigData: {
          status: "loading",
          error: null,
        },
      };
    }
    case "SAVE_EVOLUS_CONFIG_SUCCESS": {
      return {
        ...state,
        saveEvolusConfigData: {
          status: "success",
          error: null,
        },
        evolusConfigData: {
          ...state.evolusConfigData,
          ...action.payload,
          status: "success",
          error: null,
        },
        evolusFacilitiesData: initialState.evolusFacilitiesData,
      };
    }
    case "SAVE_EVOLUS_CONFIG_ERROR": {
      return {
        ...state,
        saveEvolusConfigData: {
          status: "error",
          error: action.payload,
        },
      };
    }
    case "FETCH_EVOLUS_FACILITIES_LOADING": {
      return {
        ...state,
        evolusFacilitiesData: {
          ...state.evolusFacilitiesData,
          status: "loading",
          error: null,
        },
      };
    }
    case "FETCH_EVOLUS_FACILITIES_SUCCESS": {
      return {
        ...state,
        evolusFacilitiesData: {
          ...state.evolusFacilitiesData,
          ...action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case "FETCH_EVOLUS_FACILITIES_ERROR": {
      return {
        ...state,
        evolusFacilitiesData: {
          ...state.evolusFacilitiesData,
          status: "error",
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default EvolusIntegrationReducer;
