import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { WAIT_LIST_DAYS, WAIT_LIST_TIME_SLOTS } from "../../Event.consts";
import classes from "../../sass/Event.module.scss";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import { Select } from "../../../../shared/Select/Select";
import { validateWaitListMonths } from "../../Event.utils";

const WaitListDayTimes = ({ days, timeSlots, months, years }) => {
  const handleSelectEntity = (id, { entity, data }) => {
    if (!entity.value.includes(id)) {
      if (id === data[0].id) {
        entity.onChange(data.map((d) => d.id));
      } else {
        entity.onChange([...entity.value, id]);
      }
    } else {
      if (id === data[0].id) {
        entity.onChange([]);
      } else {
        entity.onChange(
          entity.value.filter((i) => i !== id).filter((i) => i !== data[0].id),
        );
      }
    }
  };

  const monthsOptions = useMemo(
    () =>
      validateWaitListMonths({
        months: months.options,
        years: years.values,
      }).map((i) => ({
        label: i.name,
        value: i.id,
      })),
    [months.options, years.values],
  );

  return (
    <div className={classes.waitListDateTime}>
      <div className={classes.waitListDateTimeRow}>
        <div className={classes.waitListDateTimeRow}>
          <div className={classes.waitListDateTimeLabel}>Day Preference:</div>
          <div className={classes.waitListDateTimeDays}>
            {WAIT_LIST_DAYS.map((day) => (
              <Checkbox
                name={`$day-${day.id}`}
                key={`day-${day.id}`}
                isChecked={days.value.includes(day.id)}
                onChange={() =>
                  handleSelectEntity(day.id, {
                    entity: days,
                    data: WAIT_LIST_DAYS,
                  })
                }
                label={day.name}
              />
            ))}
          </div>
        </div>
        <div>
          <div className={classes.waitListDateTimeLabel}>
            Month and Year Preference:
          </div>
          <div className={classes.waitListDateTimeMonthYear}>
            <Select
              isError={months.isError}
              value={months.values.map((i) => ({
                value: i.id,
                label: i.name,
              }))}
              onChange={(nextValues) => {
                months.onChange(
                  nextValues.map((i) => ({
                    id: i.value,
                    name: i.label,
                  })),
                );
              }}
              options={monthsOptions}
              isSearchable
              isMulti
              placeholder="Select month"
              name="months"
            />
            <Select
              isError={years.isError}
              value={{
                value: years.values.id,
                label: years.values.name,
              }}
              onChange={(nextValues) => {
                years.onChange({
                  id: nextValues.value,
                  name: nextValues.label,
                });
              }}
              options={years.options.map((i) => ({
                value: i.id,
                label: i.name,
              }))}
              isSearchable
              placeholder="Select year"
              name="years"
              className={classes.selectYearDropdown}
            />
          </div>
        </div>
      </div>
      <div className={classes.waitListDateTimeTimesWrap}>
        <div className={classes.waitListDateTimeLabel}>Time Preference:</div>
        <div className={classes.waitListDateTimeTimes}>
          {WAIT_LIST_TIME_SLOTS.map((time) => (
            <Checkbox
              name={`time-${time.id}`}
              key={`time-${time.id}`}
              isChecked={timeSlots.value.includes(time.id)}
              onChange={() =>
                handleSelectEntity(time.id, {
                  entity: timeSlots,
                  data: WAIT_LIST_TIME_SLOTS,
                })
              }
              label={time.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const entity = PropTypes.shape({
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
});

WaitListDayTimes.propTypes = {
  days: entity.isRequired,
  timeSlots: entity.isRequired,
  months: PropTypes.object,
  years: PropTypes.object,
};

export default WaitListDayTimes;
