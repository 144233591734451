import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";

export const useRetailReport = (options = {}) => {
  return useQuery(
    ["retailReport"],
    async () => {
      const res = await http.get("bi/retail_report", {
        params: { fromDate: "2024-04-24", toDate: "2024-04-24" },
      });
      const composeResponse = {
        clinics: res.data.data.clinics,
        grouping: res.data.data.grouping,
        reportData: res.data.data.report_data,
        reportHeader: res.data.data.report_header,
        salesHeader: res.data.data.sales_header,
        total: res.data.data.total,
      };
      return composeResponse;
    },
    options,
  );
};
