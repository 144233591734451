import React from "react";
import Header from "../../_legacy/Containers/Guest/Header.js";
import Footer from "../../_legacy/Containers/Guest/Footer.js";

class BlockIP extends React.Component {
  render() {
    return (
      <div className="guest">
        <Header />
        <div className="thanku">
          <h1>Your IP has been blocked temporarily</h1>
          <h3>
            Please contact support at{" "}
            <a href="mailto:support@aestheticrecord.com">
              support@aestheticrecord.com
            </a>
          </h3>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BlockIP;
