import { useQueryClient } from "@tanstack/react-query";
import { useCreateStatementDescriptorMutation } from "../../../../../../api/mutations/useCreateStatementDescriptorMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { createStatementDescriptorQueryKey } from "../../../../../../api/queries/useStatementDescriptorQuery";
import { tCommon } from "../../../../../../i18n/useAppTranslation";

export function useApiUpdateDescriptor(stripeUserId) {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useCreateStatementDescriptorMutation();

  const update = async (formValues) => {
    try {
      if (stripeUserId) {
        await mutateAsync({
          stripeUserId,
          descriptor: formValues.descriptor,
        });
        uiNotification.success(tCommon("success.statementDescriptorUpdate"));
        queryClient.refetchQueries({
          queryKey: createStatementDescriptorQueryKey(stripeUserId),
          type: "active",
          exact: true,
        });
      } else {
        throw Error("Missing stripe user id");
      }
    } catch (e) {
      uiNotification.error(tCommon("error.statementDescriptorUpdate"));
      throw e;
    }
  };

  return {
    update,
    isUpdating: isLoading,
  };
}
