import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { checkIfPermissionAllowed } from "../../../Utils/services.js";

class SalesHeader extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      salesLang: languageData.sales,
      isTouchMd:
        userData && userData.account
          ? userData.account.account_preference.is_touch_md
          : 0,
      plan_selected: userData.account.account_subscription.plan_code,
    };
  }

  render() {
    var urlR = window.location.href;
    var res = urlR.split("/");
    let navLink = res[res.length - 1];
    let midNavLink = res[res.length - 2];
    return (
      <>
        {checkIfPermissionAllowed("view-sales-invoices") && (
          <Link
            className={
              navLink == "sales" || navLink == "invoices"
                ? "newTabs"
                : "newTabs disabled-tab"
            }
            to="/sales/invoices"
          >
            {this.state.salesLang.sales_invoices}
          </Link>
        )}
        {this.state.isTouchMd == 1 &&
        this.state.plan_selected == "practice_booking"
          ? ""
          : checkIfPermissionAllowed("view-cash-drawer") && (
              <Link
                className={
                  this.props.location.pathname
                    ? this.props.location.pathname.indexOf("cash") > -1
                      ? "newTabs"
                      : "newTabs disabled-tab"
                    : "newTabs disabled-tab"
                }
                to="/sales/cash-drawer"
              >
                {this.state.salesLang.sales_cash_drawer}
              </Link>
            )}

        {this.state.isTouchMd == 1 &&
        this.state.plan_selected == "practice_booking"
          ? ""
          : checkIfPermissionAllowed("virtual-terminal") && (
              <Link
                className={
                  navLink == "virtual-terminal" ||
                  midNavLink == "checkout-invoice" ||
                  navLink == "edit"
                    ? "newTabs"
                    : "newTabs disabled-tab"
                }
                to="/sales/virtual-terminal"
              >
                Desktop Terminal
              </Link>
            )}
      </>
    );
  }
}

export default withRouter(SalesHeader);
