import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { isNullish } from "../../utilities/general";

export const SERVICES_BY_CATEGORIES_MODEL_TYPE = {
  cosmeticQuestionnaire: "cosmetic_questionnaire",
  healthQuestionnaire: "health_questionnaire",
  all: "all",
};

const dtoSchema = yup.object({
  search: yup.string(),
  providerId: yup.number(),
  modelType: yup
    .string()
    .test("oneOfModelType", "", (value) =>
      value === undefined
        ? true
        : Object.values(SERVICES_BY_CATEGORIES_MODEL_TYPE).includes(value),
    ),
});

function createServicesGroupedByCategoriesQueryKey(
  search,
  providerId,
  modelType,
) {
  return [
    QUERY_KEYS.servicesGroupedByCategories,
    search,
    providerId,
    modelType,
  ].filter((k) => !isNullish(k));
}

export function useServicesGroupedByCategoriesQuery({ payload = {}, options }) {
  const dto = dtoSchema.validateSync(payload, {
    strict: true,
  });

  return useQuery(
    createServicesGroupedByCategoriesQueryKey(
      dto.search,
      dto.providerId,
      dto.modelType,
    ),
    () =>
      http.get(HTTP_ENDPOINTS.getServicesGroupedByCategories(), {
        params: {
          term: dto.search || "",
          provider_id: dto.providerId || "",
          for_model: dto.modelType || "",
        },
      }),
    options,
  );
}
