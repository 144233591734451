import React from "react";
import PropTypes from "prop-types";
import classes from "./ConsentHeader.module.scss";
import { Skeleton } from "../../shared/Skeleton/Skeleton";

export function ConsentHeader({ name, birthDay, isLoading }) {
  if (isLoading) {
    return (
      <div className={classes.skeleton}>
        <Skeleton width="200px" height={23} borderRadius="4px" />
        <Skeleton width="85px" height={20} borderRadius="4px" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.name}>{name}</div>
      {birthDay && <div className={classes.birthday}>{birthDay}</div>}
    </div>
  );
}

ConsentHeader.propTypes = {
  name: PropTypes.string.isRequired,
  birthDay: PropTypes.string,
  isLoading: PropTypes.bool,
};

ConsentHeader.defaultProps = {
  isLoading: false,
  birthDay: null,
};
