import React, { useMemo } from "react";
import classes from "./PatientBlock.module.scss";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { Checkbox } from "../../../../../../../../shared/Checkbox/Checkbox";
import { Empty } from "../../../../../../../../shared/Empty/Empty";

export function PatientBlock({
  label,
  onChange,
  options,
  value,
  renderOption,
}) {
  const { tCommon } = useAppTranslation.Common();

  const valueIds = useMemo(() => value.map((i) => i.id), [value]);
  const patientValueIds = useMemo(() => options.map((i) => i.id), [options]);
  const patientSelectedValuesCount = patientValueIds.filter((i) =>
    valueIds.includes(i),
  ).length;

  const isPartialSelected =
    patientSelectedValuesCount < patientValueIds.length &&
    patientSelectedValuesCount !== 0;

  const isSelectedAll =
    isPartialSelected || patientValueIds.every((i) => valueIds.includes(i));

  const onToggleAll = () => {
    if (isSelectedAll) {
      onChange(value.filter((i) => !patientValueIds.includes(i.id)));
    } else {
      onChange([...value, ...options.filter((o) => !valueIds.includes(o.id))]);
    }
  };

  const onToggleOption = (option) => {
    const isChecked = valueIds.find((i) => i === option.id);
    if (isChecked) {
      onChange(value.filter((i) => i.id !== option.id));
    } else {
      onChange([...value, option]);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.label}>{label}</div>
        {patientValueIds.length > 0 && (
          <Checkbox
            inverted
            size="small"
            label={tCommon("label.all")}
            isChecked={isSelectedAll}
            isIntermediate={isPartialSelected}
            onChange={onToggleAll}
          />
        )}
      </div>
      {options.length > 0 ? (
        options.map((option, index) => (
          <div key={`${option.id}-${index}`} className={classes.option}>
            <Checkbox
              inverted
              size="small"
              isChecked={Boolean(valueIds.find((i) => i === option.id))}
              onChange={() => onToggleOption(option)}
            />
            <div>{renderOption(option)}</div>
          </div>
        ))
      ) : (
        <Empty size="small" color="gray2">
          {tCommon("label.none")}
        </Empty>
      )}
    </div>
  );
}
