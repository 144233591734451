import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../../shared/Modal/Modal";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import classes from "./ModalInventoryLogs.module.scss";
import { useLogs } from "./hooks/useLogs";
import { Empty } from "../../../../../shared/Empty/Empty";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Header } from "./shared/Header/Header";
import {
  isNullish,
  passOr,
  squashSpaces,
  toFixed,
} from "../../../../../utilities/general";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

export function ModalInventoryLogs({ productId, isOpen, onClose }) {
  const { tCommon } = useAppTranslation.Common();
  const logs = useLogs(productId);
  const { data: user } = useCurrentUserQuery();

  function composeLogLabel(log) {
    const costToCompany = passOr(log.cost_to_company > 0, "", () => {
      return `with cost to company ${user.currencySymbol}${log.cost_to_company}/unit`;
    });

    const batch = passOr(log.inventory?.batch_id, "", () => {
      return `of Batch ${log.inventory.batch_id}`;
    });

    const clinic = passOr(log.inventory.clinic?.clinic_name, "", () => {
      return `for clinic: ${log.inventory.clinic.clinic_name}`;
    });

    const reason = passOr(log.reason, "", () => {
      return `with reason ${log.reason}`;
    });

    const userFullName = passOr(
      Object.values(log?.user || {}).some(Boolean),
      undefined,
      () => {
        return `${log.user.firstname} ${log.user.lastname}`.trim();
      },
    );

    const units = (() => {
      if (isNullish(log.stock)) {
        return tCommon("label.pluralUnits", {
          count: Number(toFixed(Number(log.units), 3)),
        });
      }

      return `${log.stock} ${log.unit_label || "units"} (${tCommon(
        "label.pluralUnits",
        {
          count: Number(toFixed(Number(log.units), 3)),
        },
      )})`;
    })();

    return (
      <div>
        {userFullName && (
          <span className={classes.userName}>{userFullName} </span>
        )}
        {squashSpaces(
          `${log.action} ${units} ${costToCompany} ${batch} ${clinic} ${reason}`,
        )}
      </div>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      header={<Header isPickerDisabled={logs.isLoading} period={logs.period} />}
    >
      {logs.isEmpty && <Empty position="center" />}
      {logs.isLoading &&
        Array.from(Array(15).keys()).map((i) => (
          <Skeleton key={i} count={1} borderRadius="4px" height={62} />
        ))}
      {!logs.isEmpty && !logs.isLoading && (
        <>
          {logs.value.map((log) => (
            <div className={classes.log} key={log.id}>
              <span className={classes.date}>{log.created}</span>
              {composeLogLabel(log)}
            </div>
          ))}
          {logs.hasNextPage && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={logs.fetchNextPage}
              isLoading={logs.isFetchingNextPage}
            />
          )}
        </>
      )}
    </Modal>
  );
}

ModalInventoryLogs.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
