import { useEfaxAssignPatientMutation } from "../../../../../../api/efax/useEfaxAssignPatientMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";

export const useSubmit = () => {
  const { tInbox } = useAppTranslation.Inbox();

  const { mutateAsync, isLoading } = useEfaxAssignPatientMutation({
    onError: () => {
      uiNotification.error(tInbox("efaxOrders.error.assign"));
    },
    onSuccess: () => {
      uiNotification.success(tInbox("efaxOrders.success.assign"));
    },
  });

  return {
    isLoading,
    initiate: ({ patientId, faxId }) => {
      return mutateAsync({ patientId, faxId });
    },
  };
};
