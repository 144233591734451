import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import {
  composeAmountSchema,
  composeEmailSchema,
  composeZipCodeSchema,
} from "../../../../SalesCheckoutInvoice.schemas";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import PaymentFormModal from "../components/CardOnFile/PaymentFormModal";
import PaymentTipsModal from "../components/CardOnFile/PaymentTipsModal";

const FormCardOnFile = ({ isTipsApplying }) => {
  const { invoice, amountToPay } = useInvoice();
  const { chargeCard } = useInvoiceActions();
  const { showTipsModal } = usePaymentFlow();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
    email: composeEmailSchema(),
    zipCode: composeZipCodeSchema(),
  });

  const { form, changeFormValue, hasError, errors, isValid } = useChargeForm({
    schema,
    onSubmit: (values) => {
      chargeCard.initiate({
        clearentEmail: values.email,
        clearentZip: values.zipCode,
        isSavedCard: true,
        amount: values.amount,
        clearentCardToken: values.cardOnFile.value,
      });
    },
    initialValues: {
      amount: amountToPay || "",
      email: invoice?.patient?.email || "",
      zipCode: invoice?.patient?.cardOnFiles?.[0]?.billingZip || "",
      cardOnFile: {
        label: invoice?.patient?.cardOnFiles?.[0]?.number,
        value: invoice?.patient?.cardOnFiles?.[0]?.cardOnFile,
      },
    },
  });

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          isValid={isValid}
          form={form}
          errors={errors}
          hasError={hasError}
          changeFormValue={changeFormValue}
          showTipsModal={showTipsModal}
        />
      ) : (
        <PaymentTipsModal
          isTipsApplying={isTipsApplying}
          form={form}
          errors={errors}
          changeFormValue={changeFormValue}
          isValid={isValid}
          showTipsModal={showTipsModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(FormCardOnFile);
