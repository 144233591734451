import React from "react";
import { Route, Redirect } from "react-router-dom";
import BiUnavailable from "../../Components/BusinessInsights/BiUnavailable";
import { ROUTES } from "../../consts/routes";
import { isLoggedIn } from "../../Utils/services";

export const BiPrivateRoute = ({ component: Component, ...rest }) => {
  const isBiUnavailable = process.env.REACT_APP_BI_UNAVAILABLE === "yes";
  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn() ? (
          <Route
            {...rest}
            render={(props) =>
              isBiUnavailable ? <BiUnavailable /> : <Component {...props} />
            }
          />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login(),
            }}
          />
        )
      }
    />
  );
};
