import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { Modal } from "../../../../shared/Modal/Modal";
import { Button } from "../../../../shared/Button/Button";

const AgreementModal = ({ onClose, agreementModal }) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      footerNoBorder
      header={<Modal.Title>Membership Agreement</Modal.Title>}
      footer={<Button onClick={onClose}>Close</Button>}
    >
      {
        <Scrollbars
          style={{ height: "400px" }}
          className="juvly-Agreement-content"
        >
          <div className="agreementContent">
            <div
              dangerouslySetInnerHTML={{
                __html: agreementModal.content,
              }}
            />
          </div>
          <div className="memberAgreemenSign m-r-20">
            <img
              className="consent-signature imp-patient-signature pull-right m-t-0"
              src={agreementModal.patientSignature}
              alt=""
            />
            <p>{agreementModal.patientFullName}</p>
          </div>
        </Scrollbars>
      }
    </Modal>
  );
};

export default AgreementModal;
