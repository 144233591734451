import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
  patientEmail: yup.string().required(),
});

export function useSendFrontDeskInvoiceMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postSendFrontDeskInvoice(req.invoiceId), {
      patient_email: req.patientEmail,
    });
  }, options);
}
