import { useMemo } from "react";
import * as yup from "yup";
import { usePatientCardDetailsQuery } from "../../../api/queries/usePatientCardDetailsQuery";

const schema = yup.object({
  cardNumber: yup.string(),
  pinCode: yup.string().nullable(),
});

const compose = (response) => {
  let result = {};
  if (response && response.data.data) {
    result = {
      cardNumber: response.data.data.card_number,
      pinCode: response.data.data.billing_zip,
    };
  }
  return result;
};

export function useApiPatientCardDetails({ patientId }) {
  const { data, isFetching } = usePatientCardDetailsQuery(
    {
      patientId,
    },
    {
      staleTime: 0,
      enabled: Boolean(patientId),
    },
  );

  const patientCardDetails = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  return {
    patientCardDetails,
    isPatientCardDetailsFetching: isFetching,
  };
}
