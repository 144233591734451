import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { THEME } from "../../../../../consts/theme";
import { StageGrid } from "../../shared/StageGrid/StageGrid";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";

export function GridSkeleton() {
  return (
    <SkeletonTheme baseColor={THEME.colors.white}>
      <StageGrid>
        <Skeleton height={290} />
        <Skeleton height={609} />
        <Skeleton height={290} />
      </StageGrid>
    </SkeletonTheme>
  );
}
