import React from "react";
import PropTypes from "prop-types";
import classes from "./ReportLayout.module.scss";

export function ReportLayout({ children }) {
  return <div className={classes.root}>{children}</div>;
}

ReportLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
