import React from "react";
import { Box } from "../../../../../shared/Box/Box";
import classes from "../scss/styles.module.scss";
import { tCommon } from "../../../../../i18n/useAppTranslation";
import { showFormattedDate } from "../../../../../Utils";

const Orders = ({ fax, selectedOrders, setSelectedOrders }) => {
  const labels = {
    faxNumber: "Fax Number",
    patientName: "Patient",
    sentBy: "Sent By",
    direction: "Direction",
    status: "Status",
    datetime: "Date Time",
  };

  const handleCheckboxChange = (order) => {
    if (selectedOrders.map((order) => order.id).includes(order.id)) {
      setSelectedOrders(
        selectedOrders.filter((orderId) => orderId.id !== order.id),
      );
    } else {
      setSelectedOrders([...selectedOrders, order]);
    }
  };

  const formatValue = (key, value) => {
    if (key === "datetime") {
      return showFormattedDate(value, true);
    }
    return value || tCommon("symbol.longDash");
  };

  return (
    <Box className={classes.detailsBorder} key={fax.id}>
      <div className="flex gap-16">
        {setSelectedOrders && (
          <input
            type="checkbox"
            checked={selectedOrders.map((order) => order.id).includes(fax.id)}
            onChange={() => handleCheckboxChange(fax)}
          />
        )}
        <div className="flex-col gap-24">
          <div className={classes.faxDetails}>
            {Object.entries(fax).map(([key, value]) => {
              if (labels[key]) {
                return (
                  <div className="info-row" key={key}>
                    <label>{labels[key]}</label>
                    <span>{formatValue(key, value)}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Orders;
