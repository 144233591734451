import React from "react";
import { Box } from "../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../shared/SortAdornment/SortAdornment";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import classes from "./Table.module.scss";
import { MONTHS } from "../../../../../consts/general";
import { numberFormat } from "../../../../../Utils";
import { Button } from "../../../../../shared/Button/Button";
import { EditIcon } from "../../../../../assets/Icons/EditIcon";
import { DeleteIcon } from "../../../../../assets/Icons/DeleteIcon";
import { SALES_GOALS_ORDER_BY } from "../../../../../api/queries/useSalesGoalsQuery";
import { history } from "../../../../../history";
import { SETTINGS_ROUTES } from "../../../../../consts/routes";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { shortenClinicName } from "../../../../../helpers/general";

export function Table({ data, order, del }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const tableData = data.value.map((i) => ({
    key: i.id,

    clinicName: shortenClinicName(i.clinic.clinic_name),

    year: i.year,

    month:
      MONTHS.find((m) => m.id === parseInt(i.month))?.name ||
      tCommon("symbol.longDash"),

    goal: numberFormat(i.goal, "currency", 2),

    actions: (
      <div className={classes.actions}>
        <Button
          size="tiny"
          variant="text"
          onClick={() => {
            history.push(
              SETTINGS_ROUTES.editSalesGoal({
                id: i.id,
                clinicId: i.clinic.id,
              }),
            );
          }}
        >
          <EditIcon width="18px" />
        </Button>
        <Button
          size="tiny"
          variant="text"
          color="error"
          isDisabled={del.loadingIds.includes(i.id)}
          onClick={() => {
            del.setOne(i.id);
            del.requestConfirm();
          }}
        >
          {del.loadingIds.includes(i.id) ? (
            <CircularProgress size="tiny" color="red" />
          ) : (
            <DeleteIcon width="18px" />
          )}
        </Button>
      </div>
    ),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={tableData}
            cols={[
              {
                data: tSettings("salesGoals.table.clinic"),
                accessor: "clinicName",
                headColClassName: classes.colMaxWidth,
              },
              {
                data: renderSortWrap(
                  tSettings("salesGoals.table.year"),
                  SALES_GOALS_ORDER_BY.year,
                ),
                accessor: "year",
              },
              {
                data: renderSortWrap(
                  tSettings("salesGoals.table.month"),
                  SALES_GOALS_ORDER_BY.month,
                ),
                accessor: "month",
              },
              {
                data: tSettings("salesGoals.table.goal"),
                accessor: "goal",
                headColClassName: classes.colMaxWidth,
              },
              {
                data: tSettings("salesGoals.table.actions"),
                accessor: "actions",
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}
