/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import Header from "../../_legacy/Containers/Guest/Header.js";
import Footer from "../../_legacy/Containers/Guest/Footer.js";
import { signUp, verifyAccount } from "../../Actions/SignUp/signUpAction.js";
import { clearToken, clearUserData } from "../../Utils/services.js";
import { uiNotification } from "../../services/UINotificationService.js";

class VerifyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: this.props.match.params.verificationCode
        ? this.props.match.params.verificationCode
        : "",
      showLoader: true,
    };
  }

  componentDidMount() {
    localStorage.removeItem("accountSetupFile");
    localStorage.removeItem("accountSetupStatus");
    clearToken();
    clearUserData();
    this.props.signUp();
    this.props.verifyAccount(this.state.verificationCode);
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};
    if (nextProps.isSuccess != undefined && nextProps.message != undefined) {
      nextProps.signUp();
      let message = nextProps.message ? nextProps.message : "";
      uiNotification.clear();
      if (nextProps.isSuccess) {
        uiNotification.success(message, {
          onClose: () => {
            nextProps.history.push("/login", {
              showDelayMessage: true,
            });
          },
        });
      } else {
        uiNotification.error(message, {
          onClose: () => {
            nextProps.history.push("/login");
          },
        });
      }
    }

    return returnState;
  }

  render() {
    return (
      <div className="guest no-padding arNextLoginguest">
        <Header />

        <div className="login-main relative">
          <h1>{"Login to Aesthetic Record"}</h1>
          <form>
            <div className="form-group">
              <label htmlFor="usr">{"Email address"}:</label>
              <input
                name="email"
                className="form-control"
                placeholder={"Email address"}
                maxLength="500"
                type="text"
                id="UserEmailId"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">{"Password"}:</label>
              <input
                name="password"
                className="form-control"
                placeholder={"Password"}
                type="password"
                id="UserPassword"
                value={this.state.password}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="form-group">
              <a
                className={"button login-form-submit login-form-submit-anchor"}
                disable={"disable"}
              >
                Login
              </a>
            </div>
            <div className="text-center forgot-password">
              <a href="javascript:void(0);">{"Forgot Password?"}?</a>
            </div>
          </form>

          <div
            className={
              this.state.showLoader
                ? "new-loader text-left displayBlock"
                : "new-loader text-left"
            }
          >
            <div className="loader-outer">
              <img
                alt=""
                id="loader-outer"
                src="/images/Eclipse.gif"
                className="loader-img"
              />
              <div id="modal-confirm-text" className="popup-subtitle">
                {"Processing. Please wait..."}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const returnState = {};
  if (state.SignUpReducer.action === "SIGN_UP_VERIFY_ACCOUNT") {
    if (state.SignUpReducer.data.status != 201) {
      returnState.isSuccess = false;
    } else {
      returnState.isSuccess = true;
    }
    returnState.message = state.SignUpReducer.data.message;
  }
  return returnState;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signUp: signUp,
      verifyAccount: verifyAccount,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(VerifyAccount));
