import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  cherryPatientId: yup.string().required(),
  cherryPaymentId: yup.string().required(),
  invoiceId: yup.number().required(),
  patientId: yup.number().required(),
});

export function useCancelCherryPaymentMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.cherry.cancelPayment(), {
      payment: {
        // order matters
        patientId: req.patientId,
        cherryPatientId: req.cherryPatientId,
        invoiceId: req.invoiceId,
        cherryPaymentId: req.cherryPaymentId,
      },
    });
  }, options);
}
