import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.string().required();

const composeResponse = (res) => {
  return res?.data?.data;
};

const createQualiphyMessageQueryKey = () => {
  return [QUERY_KEYS.qualiphy.message];
};

export const useQualiphyMessageQuery = (options = {}) => {
  return useQuery(
    createQualiphyMessageQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.qualiphy.getMessage());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
