import React, { useState } from 'react';

const EditStripeOwnerModal = ({
  stripeOwnerInfo,
  onModalSave,
  onModalClose,
  stripe_user_id,
}) => {
  const [ssn, setSsn] = useState({
    value: '',
    error: false,
  });

  const handleSsn = ({ target }) => {
    const { value } = target;
    if (value.toString().length < 10) {
      setSsn({ value, error: false });
    }
  }

  const saveHandler = () => {
    let error = false;
    if (ssn.value.toString().length < 9 || ssn.value.toString().length > 10) {
      setSsn({ value: ssn.value, error: true });
      error = true;
    }

    if (error) {
      return;
    }

    const formData = {
      id: stripeOwnerInfo.id,
      id_number: `${ssn.value.slice(0,3)}-${ssn.value.slice(3, 5)}-${ssn.value.slice(5)}`,
      stripe_user_id,
    }

    onModalSave(formData);
  }

  return (
    <div className='modalOverlay'>
      <div className="small-popup-outer">
        <div className="small-popup-header">
          <div className="popup-name">Edit Owner's Info</div>
          <a className="small-cross" onClick={onModalClose}>×</a>
        </div>
        <div className="small-popup-content">
          <div className="juvly-container">
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <div className="newInputFileldOuter">
                  <div className="newInputLabel">
                    SSN
                    <span className="required">*</span>
                  </div>
                  <div className="setting-input-outer">
                    <input
                      name='ssn'
                      value={ssn.value}
                      onChange={handleSsn}
                      className={ssn.error ? 'simpleInput field_error' : 'simpleInput' }
                      type='number'
                      min='0'
                      placeholder='Enter SSN (9 numbers)'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-static">
          <a className="new-blue-btn pull-right" onClick={saveHandler}>Save</a>
        </div>
      </div>
    </div>
  );
};

export default EditStripeOwnerModal;
