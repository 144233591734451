import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Sidebar from "../InventorySidebar";
import {
  getStockTransfers,
  emptyInventoryReducer,
} from "../../../Actions/Inventory/inventoryActions.js";
import { showFormattedDate, autoScrolling } from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class StockTransfer extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    let languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      stockTransferData: {},
      stockTransferList: [],
      showLoader: false,
      loadMore: true,
      startFresh: true,
      page: 1,
      pagesize: 20,
      term: "",
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
    };
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    autoScrolling(true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: "",
      },
    };
    this.setState({ showLoader: true });
    this.props.getStockTransfers({ formData });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader) {
      returnState.showLoader = false;
      nextProps.emptyInventoryReducer();
    }
    if (
      nextProps.stockTransferData &&
      nextProps.stockTransferData != prevState.stockTransferData
    ) {
      returnState.stockTransferData = nextProps.stockTransferData;
      autoScrolling(false);
      nextProps.emptyInventoryReducer();
      if (prevState.stockTransferList.length == 0) {
        if (nextProps.stockTransferData.next_page_url != null) {
          returnState.page = prevState.page + 1;
        }
        returnState.next_page_url = nextProps.stockTransferData.next_page_url;
        returnState.stockTransferList = nextProps.stockTransferData.data
          ? nextProps.stockTransferData.data
          : [];
      } else {
        returnState.stockTransferList = [
          ...prevState.stockTransferList,
          ...nextProps.stockTransferData.data,
        ];
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.stockTransferData.next_page_url;
      }
      returnState.showLoadingText = false;
      returnState.showLoader = false;
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  handleSubmit = (event) => {
    autoScrolling(true);
    event.preventDefault();
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
      },
    };

    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      loadMore: true,
      next_page_url: "",
      showLoader: true,
      stockTransferList: [],
      startFresh: true,
    });
    this.props.getStockTransfers(formData);
  };

  loadMore = () => {
    if (!autoScrolling()) {
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          term: this.state.term,
        },
      };
      autoScrolling(true);
      this.props.getStockTransfers(formData);
    }
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <span className="cursor-pointer">Stock Transfers</span>
                <form
                  onSubmit={this.handleSubmit}
                  className="searchForm settingAddProductOuter yourInventorySearch"
                >
                  <span className="searchOuter">
                    <i className="fa fa-search search-icon" />
                    <input
                      className="setting-search-input search-key"
                      placeholder={"Search by Tran. No#"}
                      name="term"
                      autoComplete="off"
                      value={this.state.term}
                      onChange={this.handleInputChange}
                    />
                  </span>
                </form>
                <div className="memberRightActions">
                  <Link
                    to="/inventory/stock-transfers/create"
                    name="filter"
                    className="new-line-btn no-width"
                  >
                    {"Start New Transfer"}
                  </Link>
                </div>
              </div>
            </div>
            <div className="juvly-section full-width">
              <div className="table-responsive">
                <table className="table-updated setting-table min-w-850 no-td-border">
                  <thead className="table-updated-thead">
                    <tr className="">
                      <th className="col-xs-2 table-updated-th">
                        Transfer No#
                      </th>
                      <th className="col-xs-2 table-updated-th">From</th>
                      <th className="col-xs-2 table-updated-th">To</th>
                      <th className="col-xs-2 table-updated-th">
                        Initiation Date
                      </th>
                      <th className="col-xs-2 table-updated-th">
                        Received Date
                      </th>
                      <th className="col-xs-1 table-updated-th">Status</th>
                      <th className="col-xs-1 table-updated-th">Action</th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.stockTransferList &&
                      this.state.stockTransferList.length > 0 &&
                      this.state.stockTransferList.map((obj, idx) => {
                        return (
                          <tr
                            className="table-updated-tr"
                            key={"stockTransferList-" + idx}
                          >
                            <td className="table-updated-td p-r-0">
                              {obj.transfer_number}
                            </td>
                            <td className="table-updated-td word-break p-r-0">
                              {obj.from_clinic &&
                                obj.from_clinic.clinic_name &&
                                obj.from_clinic.clinic_name}
                            </td>
                            <td className="table-updated-td word-break p-r-0">
                              {obj.to_clinic &&
                                obj.to_clinic.clinic_name &&
                                obj.to_clinic.clinic_name}
                            </td>
                            <td className="table-updated-td p-r-0">
                              {obj.transfer_on &&
                                showFormattedDate(obj.transfer_on)}
                            </td>
                            <td className="table-updated-td p-r-0">
                              {obj.received_on &&
                                showFormattedDate(obj.received_on)}
                            </td>
                            <td className="table-updated-td p-r-0">
                              {obj.shipping_status == "completed"
                                ? "Completed"
                                : "In Transit"}
                            </td>
                            <td className="table-updated-td">
                              <Link
                                to={
                                  "/inventory/stock-transfers/" +
                                  obj.id +
                                  "/view/"
                                }
                                className="line-btn stockDetail-btn no-width"
                              >
                                Details
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    {this.state.stockTransferList &&
                      this.state.stockTransferList.length == 0 && (
                        <tr>
                          <td
                            colSpan="10"
                            className="col-xs-12 table-updated-td text-center"
                          >
                            No Record Found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              {this.state.showLoadingText === true && (
                <div className={"loading-please-wait no-margin-top"}>
                  {this.state.globalLang.loading_please_wait_text}
                </div>
              )}
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "GET_STOCK_TRANSFERS") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.stockTransferData = state.InventoryReducer.data.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getStockTransfers: getStockTransfers,
      emptyInventoryReducer: emptyInventoryReducer,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(StockTransfer));
