import { useSalesGoalMutation } from "../../../../api/mutations/useSalesGoalMutation";
import { SETTINGS_ROUTES } from "../../../../consts/routes";
import { history } from "../../../../history";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { getEditPathParams } from "../utils";

export function useSubmit() {
  const { goalId } = getEditPathParams();
  const { tSettings } = useAppTranslation.Settings();

  const { mutate, isLoading } = useSalesGoalMutation({
    onError: () => {
      uiNotification.error(tSettings("salesGoalMutate.error.update"));
    },
    onSuccess: () => {
      history.push(SETTINGS_ROUTES.salesGoals());
      uiNotification.success(tSettings("salesGoalMutate.success.update"));
    },
  });

  const initiate = (formValues) => {
    mutate({
      clinicId: formValues.clinic.value,
      goal: formValues.goal,
      goalId,
      month: formValues.month.value,
      year: formValues.year.value,
    });
  };

  return {
    initiate,
    isLoading,
  };
}
