import React, { useState, useMemo } from "react";
import InsightsSideBar from "./InsightsSideBar";
import SalesActions from "./Sales/SalesActions";
import Loader from "../Common/Loader";
import { Table } from "../../shared/Table/Table";
import "./ChartAudit/chartAudit.scss";
import classes from "./ChartAudit/ProviderAndMdChartAuditTab.module.scss";
import ChartAuditListView from "./ChartAudit/components/ChartAuditListView";
import { useProviderAndMd } from "./ChartAudit/hooks/useProviderAndMd";
import { ProviderAndMdChartAuditTab } from "./ChartAudit/components/ProviderAndMdChartAuditTab";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import {
  useProviderAndMdQuery,
  useDownloadChartAuditReportQuery,
} from "../../api/queries/useChartAuditQuery";
import { TABLE_SIZE } from "../../pages/ProviderRoom/ProviderRoom.consts";
import { uiNotification } from "../../services/UINotificationService";
import { useClinicsQuery } from "../../api/queries/useClinicsQuery";
import { apiDateFormat } from "../../Utils";
import { LoadMoreObserver } from "../../shared/LoadMoreObserver/LoadMoreObserver";
import { Empty } from "../../shared/Empty/Empty";
import { useCurrentUserQuery } from "../../api/queries/useUserQuery";
import { history } from "../../history";
import { composeDownloadDataUrl } from "../../utilities/api";

const ChartAudit = () => {
  const { data: user } = useCurrentUserQuery();
  const UserRolesType =
    user?.role === "provider" || user?.role === "md" ? user?.role : "provider";
  const dateData = JSON.parse(localStorage.getItem("insight-sales-date"));
  let endDate = dateData ? dateData.endDate : new Date();
  const formattedEndDate = apiDateFormat(endDate);
  const [filterKey, setFilterKey] = useState({
    selectedType: UserRolesType,
    clinic_id: null,
    endDate: formattedEndDate,
    is_active: true,
  });
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();

  const {
    data: providerAndMdData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useProviderAndMdQuery({
    payload: {
      pageSize: TABLE_SIZE,
      ...filterKey,
    },
    options: {
      staleTime: 0,
      onError: () => {
        uiNotification.error(tBi("chartAuditReport.error"));
      },
    },
  });

  const { mutateAsync: downloadChartAuditReport } =
    useDownloadChartAuditReportQuery({
      options: {
        staleTime: 0,
        onSuccess: () => {
          uiNotification.success(tBi("chartAuditReport.download_success"));
        },
        onError: () => {
          uiNotification.error(tBi("chartAuditReport.download_error"));
        },
      },
    });

  const { chartDataList, totalChartCount } = useMemo(() => {
    if (providerAndMdData?.pages) {
      const [{ totalChartCount }] = providerAndMdData.pages;
      const charReports = providerAndMdData.pages
        .map((p) => p.charReports)
        .reduce((r, p) => [...r, ...p], []);

      return { chartDataList: charReports, totalChartCount };
    }
    return { chartDataList: [], totalChartCount: {} };
  }, [providerAndMdData]);

  const { data: clinics } = useClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchClinics"));
    },
  });

  const options = useMemo(
    () => clinics?.map((c) => ({ clinic_name: c.name, id: c.id })),
    [clinics],
  );

  const handleChartReviewRoute = (userId) => {
    const { id } = user || {};
    const isChartAndUserIdSame = userId === id;
    const isTabIsProvider = filterKey?.selectedType === "provider";
    let type = isTabIsProvider ? "provider-room" : "md-room";

    const state = {
      clinic_id: filterKey?.clinic_id?.toString(),
      isFromChartAuditReport: !isChartAndUserIdSame,
      chartUserId: userId,
    };

    history.push({
      pathname: `/${type}/pending`,
      state,
    });
  };

  const { data, cols } = useProviderAndMd({
    providerAndMdData: chartDataList,
    selectedType: filterKey.selectedType,
    onButtonClick: handleChartReviewRoute,
  });
  const isEmpty = !chartDataList || chartDataList.length === 0;

  const handleFilter = (data) => {
    const { clinic_id, toDate } = data;
    // Check if clinic_id is an array and has length greater than 1
    const filteredClinicId =
      Array.isArray(clinic_id) && clinic_id.length > 1 ? null : clinic_id?.[0];
    setFilterKey({
      ...filterKey,
      clinic_id: filteredClinicId,
      endDate: toDate,
    });
    localStorage.getItem("insight-sales-clinicid") &&
      localStorage.removeItem("insight-sales-clinicid");
  };

  const downloadChartAudit = async (data) => {
    const { clinic_id, fromDate, toDate } = data;
    const clinicId =
      Array.isArray(clinic_id) && clinic_id.length > 1 ? null : clinic_id?.[0];
    const downloadResponse = await downloadChartAuditReport({
      start_date: fromDate,
      end_date: toDate,
      clinic_id: clinicId,
      report_type: filterKey?.selectedType,
      status: filterKey?.is_active ? "Active" : "Inactive",
    });
    if (downloadResponse?.status === 200) {
      window.open(
        composeDownloadDataUrl(downloadResponse?.data?.data?.file),
        "_blank",
      );
    }
  };

  const handleChange = (key, newValue) => {
    setFilterKey((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <InsightsSideBar />
        <div className="memberWalletOuter business-section">
          <div className="setting-setion m-b-10">
            <div className="chart-audit-title">
              <span>{tBi("chartAuditReport.label")}</span>
              <SalesActions
                actionDetails={{
                  canShowLocFilter: true,
                  canShowDateFilter: false,
                  canShowCalFilter: false,
                  canShowDownloadBtn: true,
                  showSingleDateFilter: true,
                }}
                reportSlug={""}
                handleGrandChildDownload={(data) => {
                  downloadChartAudit(data);
                }}
                handleGrandChildFilter={(data) => {
                  handleFilter(data);
                }}
                clinicList={options}
              />
            </div>
          </div>
          <ChartAuditListView allCountData={totalChartCount} />
          <div className="row m-l-0 m-r-0 m-b-10 procedur-div d-flex">
            <ProviderAndMdChartAuditTab
              value={filterKey.selectedType}
              onChange={(key, value) => handleChange(key, value)}
              isActiveUser={filterKey.is_active}
              isTabListNew={true}
            />
          </div>
          {!isEmpty && (
            <div className="row m-l-0 m-r-0 m-b-10">
              <Table
                bgColor="white"
                cols={cols}
                data={data}
                rowClassName={classes.chartTdWidth}
                headClassName={classes.chartTableHead}
                colClassName={classes.chartTableCol}
              />
            </div>
          )}
          {isEmpty && !isLoading && (
            <Empty position="center" className={classes.chartTableHead} />
          )}

          {hasNextPage && (
            <LoadMoreObserver
              loadMore={fetchNextPage}
              isLoading={isFetchingNextPage}
            />
          )}
        </div>
      </div>
      <Loader showLoader={isLoading} isFullWidth={true} />
    </div>
  );
};

export default ChartAudit;
