import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  name: yup.string().required(),
  content: yup.string().required(),
});

export function useEfaxTemplateCreateMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) =>
      http.post(
        HTTP_ENDPOINTS.efax.createTemplate(),
        requestSchema.validateSync(dto, {
          strict: true,
        }),
      ),
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.efax.templates],
        });

        options?.onSuccess?.(...args);
      },
    },
  );
}
