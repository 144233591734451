import React, { useState } from "react";
import { withRouter } from "react-router";
import { Button } from "../../../../shared/Button/Button";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { useCancelPendingInvoiceMutattion } from "../../../../api/mutations/useCancelPendingInvoiceMutation";
import { passOr } from "../../../../utilities/general";
import { tCommon } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { history } from "../../../../history";
import classes from "./CancelPendingInvoice.module.scss";

const CancelPendingInvoice = ({
  invoiceId,
  isMerger,
  refetchInvoiceDetails,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const SALES_ROUTE = "/sales/invoices";

  const cancelPendingInvoice = useCancelPendingInvoiceMutattion({
    onError: (e) => {
      uiNotification.error(e.response.data.message);
    },
    onSuccess: () => {
      uiNotification.success("Invoice has been cancelled");
      if (isMerger) {
        history.push(SALES_ROUTE);
      } else {
        refetchInvoiceDetails();
      }
      setIsConfirmModalOpen(false);
    },
  });

  const onCancelInvoice = () => {
    cancelPendingInvoice.mutate({ invoiceId });
  };

  return (
    <div className={classes.root}>
      <Button
        size="small"
        color="error"
        onClick={() => setIsConfirmModalOpen(true)}
        isDisabled={cancelPendingInvoice.isLoading}
        leftAdornment={passOr(cancelPendingInvoice.isLoading, undefined, () => (
          <CircularProgress color="white" size="tiny" />
        ))}
      >
        {tCommon("label.cancelPendingInvoice")}
      </Button>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={onCancelInvoice}
        isConfirming={cancelPendingInvoice.isLoading}
      >
        {!isMerger
          ? tCommon("confirm.cancelPendingInvoice")
          : tCommon("confirm.cancelPendingInvoiceMerger")}
      </ConfirmModal>
    </div>
  );
};

export default withRouter(CancelPendingInvoice);
