import React from "react";
import styles from "./styles.module.scss";
import { InputSearch } from "../../../../../../../boxes/InputSearch/InputSearch";
import { DateRangePicker } from "../../../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";
import { Button } from "../../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useCurrentAccountQuery } from "../../../../../../../api/queries/useAccountQuery";
import { apiDateStringToDate } from "../../../../../../../utilities/api";
import { squashSpaces } from "../../../../../../../utilities/general";

export const TableHeading = ({ isReportsLoading, period, search }) => {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();
  const { data: account, isLoading: isAccountLoading } =
    useCurrentAccountQuery();

  return (
    <div className={styles.root}>
      <InputSearch value={search.value} onChange={search.onChange} />
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />
      <Button
        size="small"
        variant="outlined"
        isDisabled={isAccountLoading || !account.createdAt}
        onClick={() =>
          period.update({
            from: apiDateStringToDate(
              squashSpaces(account.createdAt).split(" ")[0],
            ),
            to: new Date(),
          })
        }
      >
        {tBi("shortTermLiabilityReport.allTimePeriod")}
      </Button>
    </div>
  );
};
