import React from "react";
import classes from "./styles.module.scss";
import { compareAnswerWithOption } from "../../utilities";

/**
 * @param {{
 *    options: Array<{
 *        value: string;
 *        slot?: React.ReactNode;
 *        renderSlot: ({ isSelected: boolean }) => React.ReactNode;
 *    }>;
 *    onSelect: (value: string) => void;
 *    value: Array<string>;
 * }} param0
 */
export function BaseOptions({ options, onSelect, value }) {
  return (
    <div className={classes.root}>
      {options.map((option, index) => {
        const isSelected = !!value.find((x) =>
          compareAnswerWithOption(x, option.value),
        );

        return (
          <button
            key={index}
            onClick={() => onSelect(option.value)}
            className={isSelected ? classes.selected : undefined}
          >
            {option.slot || option.renderSlot({ isSelected })}
          </button>
        );
      })}
    </div>
  );
}
