import React from "react";
import PropTypes from "prop-types";
import classes from "./ModalCharge.module.scss";

export function Content({ children }) {
  return <div className={classes.content}>{children}</div>;
}

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
