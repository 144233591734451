/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import ConfirmationModal from "../../Common/ConfirmationModal.js";
import { displayName, showWithoutRounding } from "../../../Utils/services.js";

class StockTransferPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_clinic_idClass: "simpleSelect",
      to_clinic_idClass: "simpleSelect",
      shipping_providerClass: "simpleInput",
      shipping_tracking_numberClass: "simpleInput",
      responsible_staff_idClass: "simpleSelect",
      tracking_numberClass: "simpleInput",
      provider_nameClass: "simpleInput",
      product_nameClass: "simpleInput",
      stockClass: "simpleInput",
      product_name: "",
      from_clinic_id: "",
      to_clinic_id: "",
      clinicList: [],
      transfer_type: "shipping_provider",
      shipping_provider: "",
      shipping_tracking_number: "",
      provider_name: "",
      tracking_number: "",
      responsible_staff_id: "",
      userList: [],
      transfer: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.stockTransferDataTime != prevState.stockTransferDataTime) {
      returnState.stockTransferDataTime = nextProps.stockTransferDataTime;
      returnState.clinicList = nextProps.stockTransferData.clinics;
      returnState.userList = nextProps.stockTransferData.users;
      returnState.product_name = nextProps.productName;
      returnState.product_id = nextProps.productId;
      returnState.from_clinic_id = nextProps.selectedClinicOrderId;
      returnState.batch = nextProps.batchId;
      returnState.availableStock = nextProps.availableStock;
      returnState.inventory_id = nextProps.inventory_id;
      returnState.to_clinic_id = "";
      returnState.transfer_type = "shipping_provider";
      returnState.shipping_provider = "";
      returnState.shipping_tracking_number = "";
      returnState.provider_name = "";
      returnState.tracking_number = "";
      returnState.responsible_staff_id = "";
      returnState.transfer = [];
      returnState.type = nextProps.type;
      returnState.stock = "";
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    let returnState = {
      editRowIndex: null,
    };

    if (
      event.target.name === "from_clinic_id" &&
      this.state.transfer.length > 0
    ) {
      returnState.showConfirmationModal = true;
      returnState.confirmationMsg =
        "Stock initiated can't be reverted. Click Yes to create.";
      returnState.confirmationActionType = "resetTransferList";
      returnState.from_clinic_id_changed = value;
    } else {
      returnState[event.target.name] = value;
    }
    this.setState(returnState);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true });
    let error = false;
    let returnState = {};
    if (this.state.to_clinic_id == null || this.state.to_clinic_id == "") {
      returnState.to_clinic_idClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.to_clinic_idClass = "simpleSelect";
    }

    if (
      this.state.transfer_type == "shipping_provider" &&
      (this.state.shipping_provider == "" ||
        this.state.shipping_provider == null)
    ) {
      returnState.shipping_providerClass = "simpleInput field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.shipping_providerClass = "simpleSelect";
    }

    if (
      this.state.transfer_type == "shipping_provider" &&
      (this.state.shipping_tracking_number == "" ||
        this.state.shipping_tracking_number == null)
    ) {
      returnState.shipping_tracking_numberClass = "simpleInput field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.shipping_tracking_numberClass = "simpleInput";
    }

    if (
      this.state.transfer_type == "courier_service" &&
      (this.state.provider_name == "" || this.state.provider_name == null)
    ) {
      returnState.provider_nameClass = "simpleInput field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.provider_nameClass = "simpleInput";
    }

    if (
      this.state.transfer_type == "courier_service" &&
      (this.state.tracking_number == "" || this.state.tracking_number == null)
    ) {
      returnState.tracking_numberClass = "simpleInput field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.tracking_numberClass = "simpleInput";
    }

    if (
      this.state.transfer_type == "responsible_staff" &&
      (this.state.responsible_staff_id == "" ||
        this.state.responsible_staff_id == null)
    ) {
      returnState.responsible_staff_idClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.responsible_staff_idClass = "simpleSelect";
    }

    if (this.state.stock == "" || this.state.stock == null) {
      returnState.stockClass = "simpleInput field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.stockClass = "simpleInput";
    }

    if (Number(this.state.stock) > Number(this.state.availableStock)) {
      returnState.stockClass = "simpleInput field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.stockClass = "simpleInput";
    }
    this.setState(returnState);
    if (error) {
      return;
    }

    let formData = {
      from_clinic_id: this.state.from_clinic_id,
      to_clinic_id: this.state.to_clinic_id,
      transfer_type: this.state.transfer_type,
    };
    if (this.state.transfer_type === "shipping_provider") {
      formData.shipping_provider = this.state.shipping_provider;
      formData.shipping_tracking_number = this.state.shipping_tracking_number;
    } else if (this.state.transfer_type === "courier_service") {
      formData.provider_name = this.state.provider_name;
      formData.tracking_number = this.state.tracking_number;
    } else {
      formData.responsible_staff_id = this.state.responsible_staff_id;
    }
    let transfer = [];
    let batches = [];
    batches.push({
      available_units: this.state.availableStock,
      batch_id: this.state.batch == "N/A" ? "" : this.state.batch,
      inventory_id: this.state.inventory_id,
      transfer_units: this.state.stock,
    });
    transfer.push({
      product_id: this.state.product_id,
      product_name: this.state.product_name,
      type: this.state.type,
      batches: batches,
    });

    formData.transfer = transfer;
    formData.type = "inventory";
    formData.product_id = this.state.product_id;
    this.props.transferStock(formData);
  };

  dismissStockTransferPopup = () => {
    this.props.dismissStockTransferPopup();
  };

  render() {
    return (
      <div className="content no-scroll">
        <div className="juvly-container setPadding0">
          {this.state.showConfirmationModal === true && (
            <ConfirmationModal
              showConfirmationModal={this.state.showConfirmationModal}
              confirmationMsg={this.state.confirmationMsg}
              confirmationAction={this.confirmationAction}
              onResetConfirmation={this.onResetConfirmation}
            />
          )}
          <div className="row">
            <div className="col-sm-6 supplierDetailsLeft">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  From Clinic<span className="setting-require">*</span>
                </div>
                <select
                  className={this.state.from_clinic_idClass}
                  name="from_clinic_id"
                  onChange={this.handleInputChange}
                  value={this.state.from_clinic_id}
                  disabled={this.state.supplierId ? true : false}
                >
                  <option value="">Select</option>
                  {this.state.clinicList.map((obj, id) => {
                    return (
                      <option key={"from_clinic_id-" + id} value={obj.id}>
                        {obj.clinic_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-sm-6 supplierDetailsLeft">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  To Clinic<span className="setting-require">*</span>
                </div>
                <select
                  className={this.state.to_clinic_idClass}
                  name="to_clinic_id"
                  onChange={this.handleInputChange}
                  value={this.state.to_clinic_id}
                >
                  <option value="">Select</option>
                  {this.state.clinicList.map((obj, id) => {
                    return (
                      <option key={"to_clinic_id-" + id} value={obj.id}>
                        {obj.clinic_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="juvly-section full-width supplierInformation no-padding">
              <div className="mainSectionTitle bold m-b-0">
                Transfer Logistics
              </div>
              <div className="row p20">
                <div className="col-xs-12">
                  <div className="basic-checkbox-outer m-t-0">
                    <input
                      id="rbShippingProvider"
                      className="basic-form-checkbox"
                      name="transfer_type"
                      type="radio"
                      value="shipping_provider"
                      onChange={this.handleInputChange}
                      checked={
                        this.state.transfer_type === "shipping_provider"
                          ? "checked"
                          : false
                      }
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="rbShippingProvider"
                    >
                      Shipping Provider
                    </label>
                  </div>
                  <div className="basic-checkbox-outer m-t-0">
                    <input
                      id="rbCourierServices"
                      className="basic-form-checkbox"
                      name="transfer_type"
                      type="radio"
                      value="courier_service"
                      onChange={this.handleInputChange}
                      checked={
                        this.state.transfer_type === "courier_service"
                          ? "checked"
                          : false
                      }
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="rbCourierServices"
                    >
                      Courier Service
                    </label>
                  </div>
                  <div className="basic-checkbox-outer m-t-0">
                    <input
                      id="rbResponsibleStaff"
                      className="basic-form-checkbox"
                      name="transfer_type"
                      type="radio"
                      value="responsible_staff"
                      onChange={this.handleInputChange}
                      checked={
                        this.state.transfer_type === "responsible_staff"
                          ? "checked"
                          : false
                      }
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="rbResponsibleStaff"
                    >
                      Responsible Staff
                    </label>
                  </div>
                </div>

                {this.state.transfer_type === "shipping_provider" && (
                  <React.Fragment>
                    <div className="col-sm-6 col-xs-12 shippingInfo">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          Shipping Provider
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.shipping_providerClass}
                          name="shipping_provider"
                          onChange={this.handleInputChange}
                          value={this.state.shipping_provider}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 shippingInfo">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          Tracking Number
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.shipping_tracking_numberClass}
                          name="shipping_tracking_number"
                          onChange={this.handleInputChange}
                          value={this.state.shipping_tracking_number}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {this.state.transfer_type === "courier_service" && (
                  <React.Fragment>
                    <div className="col-sm-6 col-xs-12 shippingInfo">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          Provider Name
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.provider_nameClass}
                          name="provider_name"
                          onChange={this.handleInputChange}
                          value={this.state.provider_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 shippingInfo">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          Tracking Number
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.tracking_numberClass}
                          name="tracking_number"
                          onChange={this.handleInputChange}
                          value={this.state.tracking_number}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {this.state.transfer_type === "responsible_staff" && (
                  <React.Fragment>
                    <div className="col-sm-6 col-xs-12 shippingInfo">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          Staff<span className="setting-require">*</span>
                        </div>
                        <select
                          className={this.state.responsible_staff_idClass}
                          name="responsible_staff_id"
                          onChange={this.handleInputChange}
                          value={this.state.responsible_staff_id}
                        >
                          <option value="">Select</option>
                          {this.state.userList.map((obj, id) => {
                            return (
                              <option
                                key={"responsible_staff_id-" + id}
                                value={obj.id}
                              >
                                {displayName(obj)}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="juvly-section full-width m-b-10 auto-height">
              <div className="setting-search-outer">
                <span className="newTableTitle bold full-width">
                  <b>Product Details</b>
                </span>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-xs-12 shippingInfo">
                  <div className="simpleField m-b-15">
                    <div className="simpleLabel">
                      Product Name<span className="setting-require">*</span>
                    </div>
                    <input
                      readOnly={true}
                      className={this.state.product_nameClass}
                      name="product_name"
                      value={this.state.product_name}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-xs-12 shippingInfo">
                  <div className="simpleField m-b-15">
                    <div className="simpleLabel">
                      Batch<span className="setting-require">*</span>
                    </div>
                    <input
                      readOnly={true}
                      className={"simpleInput"}
                      name="batch"
                      onChange={this.handleInputChange}
                      value={this.state.batch}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-xs-12 shippingInfo">
                  <div className="simpleField m-b-15">
                    <div className="simpleLabel">
                      Stock<span className="setting-require">*</span>
                    </div>
                    <input
                      className={this.state.stockClass}
                      name="stock"
                      onChange={this.handleInputChange}
                      value={this.state.stock}
                    />
                    <span className="maxCharacterInstruction">
                      Max: {showWithoutRounding(this.state.availableStock)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-static">
          <a
            className={"new-blue-btn pull-right"}
            onClick={this.handleSubmit}
          >{`Start Transfer`}</a>
          <a
            className="new-white-btn pull-left"
            onClick={this.dismissStockTransferPopup}
          >
            Cancel
          </a>
        </div>
      </div>
    );
  }
}

export default StockTransferPopup;
