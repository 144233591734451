import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

const responseSchema = yup.object({
  clinicClearentPublicKey: yup.string().nullable(),

  creditCards: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        cardNumber: yup.string().required(),
        billingZip: yup.string().nullable(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  const data = res?.data?.data || {};
  const cards = data?.cards_data || [];
  return {
    clinicClearentPublicKey: data.default_clinic_publickey || null,

    creditCards: cards?.map((card) => ({
      id: card.id,
      cardNumber: card.card_number,
      billingZip: card.billing_zip || null,
      clearentCardNumber: card.card_on_file || null,
    })),
  };
};

function createClientCreditCardsQueryKey({ clientId }) {
  return [QUERY_KEYS.clientCreditCards, clientId];
}

export function useClientCreditCardsQuery(payload, options = {}) {
  return useQuery(
    createClientCreditCardsQueryKey({
      clientId: payload?.clientId,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.getClientCreditCards(req.clientId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
