import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import cx from "clsx";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./CustomTip.module.scss";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { Button } from "../../../../../shared/Button/Button";
import { InputError } from "../../../../../shared/InputError/InputError";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import {
  CUSTOM_TIP_MAX_AMOUNT,
  CUSTOM_TIP_MIN_AMOUNT,
} from "../../SalesCheckoutInvoice.consts";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { formatCurrency } from "../../../../../utilities/general";

export function CustomTip({
  onSubmit,
  value,
  isSubmitting,
  className,
  currencySymbol,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice } = useInvoice();

  const schema = yup.object({
    amount: yup
      .number()
      .min(
        CUSTOM_TIP_MIN_AMOUNT,
        tSales("checkoutInvoice.formError.customTipMin", {
          min: formatCurrency(CUSTOM_TIP_MIN_AMOUNT, invoice?.currency),
        }),
      )
      .max(
        CUSTOM_TIP_MAX_AMOUNT,
        tSales("checkoutInvoice.formError.customTipMax", {
          max: formatCurrency(CUSTOM_TIP_MAX_AMOUNT, invoice?.currency),
        }),
      )
      .required(tSales("checkoutInvoice.formError.customTipRequired")),
  });

  const {
    values,
    errors,
    touched,
    dirty,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit,
    initialValues: {
      amount: value,
    },
  });

  const hasError = (field) => {
    return Boolean(touched[field] && errors[field]);
  };

  const fieldChange = (field, value) => {
    setFieldTouched(field);
    setFieldValue(field, value);
  };

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.inputWrap}>
        <InputCurrency
          size="small"
          value={values.amount}
          isDisabled={isSubmitting}
          prefix={currencySymbol}
          onChange={(amount) => fieldChange("amount", amount)}
          isError={hasError("amount")}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.customTip")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </div>
      <Button
        size="small"
        isDisabled={!dirty || !isValid || isSubmitting}
        onClick={handleSubmit}
        leftAdornment={
          isSubmitting ? (
            <CircularProgress size="tiny" color="white" />
          ) : undefined
        }
      >
        {tCommon("label.add")}
      </Button>
    </div>
  );
}

CustomTip.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSubmitting: PropTypes.bool,
  className: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
};

CustomTip.defaultProps = {
  value: "",
  isSubmitting: false,
  className: undefined,
};
