const nameInitialState = {
  action: "",
};

const commonValues = (state = nameInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
      };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    default:
      return state;
  }
};

export default commonValues;
