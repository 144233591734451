import React from "react";
import classes from "./Small.module.scss";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { ImagePlusIcon } from "../../../../assets/Icons/ImagePlusIcon";
import { CrossIcon } from "../../../../assets/Icons/CrossIcon";

export function Small({ name, handleChange, image, isLoading, handleRemove }) {
  return (
    <div className={classes.root}>
      {image ? (
        <div className={classes.imageWrap}>
          <button className={classes.removeBtn} onClick={handleRemove}>
            <CrossIcon />
          </button>
          <img src={image} alt={name} />
        </div>
      ) : (
        <label htmlFor={name} className={classes.upload}>
          {isLoading ? (
            <CircularProgress size="tiny" />
          ) : (
            <ImagePlusIcon width="17px" />
          )}
          <input
            name={name}
            id={name}
            type="file"
            onChange={handleChange}
            disabled={isLoading}
          />
        </label>
      )}
    </div>
  );
}
