import React from "react";
import IntlTelInput from "react-intl-tel-input";

export default class contactNumber extends React.Component {
  contactNumberChanged = (inputFiled, t, x, y) => {
    const phoneRegex = new RegExp(/^\+[1-9]\d{1,14}$/);
    const dialCode = y && y.dialCode ? y.dialCode : "";
    const number = typeof x === "string" ? x : t;

    let contactNumber = `+${dialCode}${number.replace(/[-\s]/g, "")}`;
    let contactNumberError;
    let contactNumberClass;

    this.setState({ userChanged: true });

    inputFiled = inputFiled.split("_");

    if (phoneRegex.test(contactNumber) || contactNumber === "") {
      contactNumberError = false;
      contactNumberClass = "form-control p-l-55";
    } else {
      contactNumberError = true;
      contactNumberClass = "form-control p-l-55 field_error";
    }

    this.props.handleChildChange({
      [inputFiled]: contactNumber,
      [inputFiled + "Error"]: contactNumberError,
      [inputFiled + "Class"]: contactNumberClass,
    });
  };

  render() {
    return (
      <div className="field-group">
        <label htmlFor="usr">
          {this.props.label}
          {this.props.isRequired ? <span className="required">*</span> : null}
        </label>
        {this.props.isRender && (
          <IntlTelInput
            preferredCountries={["us"]}
            css={["intl-tel-input", this.props.class]}
            utilsScript={"libcontactNumber.js"}
            defaultValue={this.props.value ? this.props.value : ""}
            defaultCountry={localStorage.getItem("cCode")}
            fieldName={this.props.name}
            onPhoneNumberChange={this.contactNumberChanged.bind(
              this,
              this.props.name,
            )}
            onPhoneNumberBlur={this.contactNumberChanged.bind(
              this,
              this.props.name,
            )}
            autoComplete="off"
            separateDialCode={`true`}
            onSelectFlag={this.contactNumberChanged.bind(this, this.props.name)}
            autoPlaceholder={true}
          />
        )}
        {!this.props.isRender && (
          <IntlTelInput
            preferredCountries={["us"]}
            css={["intl-tel-input", this.props.class]}
            utilsScript={"libcontactNumber.js"}
            defaultValue={this.props.value ? this.props.value : ""}
            defaultCountry={localStorage.getItem("cCode")}
            fieldName={this.props.name}
            onPhoneNumberChange={this.contactNumberChanged.bind(
              this,
              this.props.name,
            )}
            onPhoneNumberBlur={this.contactNumberChanged.bind(
              this,
              this.props.name,
            )}
            autoComplete="off"
            separateDialCode={`true`}
            onSelectFlag={this.contactNumberChanged.bind(this, this.props.name)}
            autoPlaceholder={true}
          />
        )}
      </div>
    );
  }
}
