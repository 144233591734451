import { useDeletePatientMutation } from "../../../../../../api/patient/useDeletePatientMutation";
import { history } from "../../../../../../history";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function useDelete(clientId, onClose) {
  const { tClients} = useAppTranslation.Clients();

  const onDelete = useDeletePatientMutation({
    onSuccess: () => {
      onClose();
      let recentlyViewedSaved = JSON.parse(
        localStorage.getItem("recentlyViewedData"),
      );

      if (recentlyViewedSaved?.length > 0) {
        recentlyViewedSaved.map((obj, idx) => {
          if (obj.profile_id === parseInt(clientId)) {
            recentlyViewedSaved.splice(idx, 1);
          }
        });
        localStorage.removeItem("recentlyViewedData");
        localStorage.setItem(
          "recentlyViewedData",
          JSON.stringify(recentlyViewedSaved),
        );
      }

      history.push(`/clients`);
      uiNotification.success(tClients("profile.delete.success"));
    },

    onError: () => {
      uiNotification.error(tClients("profile.delete.error"));
    },
  });

  return {onDelete}
}