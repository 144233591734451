import React from "react";
import cx from "clsx";
import { Skeleton as SharedSkeleton } from "../../../../../shared/Skeleton/Skeleton";
import classes from "./ModalCharge.module.scss";

export function Skeleton() {
  return (
    <div className={classes.content}>
      <SharedSkeleton borderRadius="8px" height={36} width="276px" />
      <SharedSkeleton borderRadius="8px" height={44} />
      <SharedSkeleton borderRadius="8px" height={44} />
      <SharedSkeleton borderRadius="8px" height={44} />
      <div className={cx(classes.footer, classes.skeletonFooter)}>
        <SharedSkeleton width="195px" height={44} />
        <SharedSkeleton width="196px" height={44} />
      </div>
    </div>
  );
}
