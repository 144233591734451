import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { phoneUtil } from "../../../../utilities/phone";
import { useCreateInboxBlackListEntryMutation } from "../../../../api/inboxBlacklist/useCreateInboxBlackListEntryMutation";

export function useAdd({ onSuccess }) {
  const { tInbox } = useAppTranslation.Inbox();

  const { mutate, isLoading } = useCreateInboxBlackListEntryMutation({
    onError: () => {
      uiNotification.success(tInbox("blackList.error.add"));
    },
    onSuccess: () => {
      uiNotification.success(tInbox("blackList.success.add"));
      onSuccess();
    },
  });

  return {
    initiate: (phone) => mutate({ phoneNumber: phoneUtil.compose(phone) }),
    isLoading,
  };
}
