/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from "moment";
import React, { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  useDeleteScheduleOverridesQuery,
  useGetProvidersScheduleOverrideQuery,
} from "../../../../_legacy/Queries";
import DeleteIcon from "../../../../_legacy/images/delete-dustbin.svg";
import useAdvancedState from "../../../../utilities/hooks/useAdvancedState";
import classes from "../sass/schedule.module.scss";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import OverrideScheduleModal from "./OverrideScheduleModal";
import { sortEvents } from "../../../../utilities/time";

const SetCustomHours = ({
  providerId,
  clinicsAssociated,
  refetchSchedule,
  defaultClinic,
}) => {
  const {
    state: { isModalOpen, deleteScheduleDetails },
    updateState,
    toggleField,
  } = useAdvancedState({
    isModalOpen: false,
    deleteScheduleDetails: null,
  });

  const {
    data: scheduleOverridesData,
    dataUpdatedAt: scheduleOverridesFetchedAt,
  } = useGetProvidersScheduleOverrideQuery(providerId);

  const { mutate, isLoading } = useDeleteScheduleOverridesQuery();

  const scheduleOverrides = useMemo(() => {
    if (scheduleOverridesFetchedAt) {
      return scheduleOverridesData.data.data.map(
        ({ id, date_scheduled, from_time, to_time }) => ({
          id,
          date_scheduled,
          from_time,
          to_time,
          displayFromTime: moment(from_time, "HH:mm:ss").format("hh:mm A"),
          displayToTime: moment(to_time, "HH:mm:ss").format("hh:mm A"),
          displayDate: moment(date_scheduled).format("MMM DD YYYY"),
        }),
      );
    }
    return [];
  }, [scheduleOverridesFetchedAt]);

  const toggleCutomScheduleModal = () => {
    toggleField("isModalOpen");
  };

  const handleDeleteSchedule = (event) => {
    const { eventindex } = event.currentTarget.dataset;

    updateState({ deleteScheduleDetails: scheduleOverrides[eventindex] });
  };

  const deleteSchedule = () => {
    mutate(
      { provider_id: providerId, schedule_id: deleteScheduleDetails.id },
      {
        onSuccess: () => {
          refetchSchedule();
          updateState({ deleteScheduleDetails: null });
        },
      },
    );
  };

  return (
    <>
      <div className={classes.setCustomHours}>
        <div className={classes.selectTitle}>Set Custom Hours</div>

        <div className={classes.availabilityTitle}>
          Add dates when your availability changes from your weekly hours
        </div>

        <button
          className={classes.setCustomHoursButton}
          onClick={toggleCutomScheduleModal}
        >
          Set Custom Hours
        </button>

        <div>
          <hr className="m-b-0" />
        </div>

        <div className={classes.scheduleOverridesList}>
          <div>
            {Array.isArray(scheduleOverrides) &&
              sortEvents(
                scheduleOverrides,
                "displayDate",
                "displayFromTime",
              ).map(
                (
                  { id, displayDate, displayFromTime, displayToTime },
                  scheduleIndex,
                ) => {
                  return (
                    <div
                      key={`custom-schedule-${id}`}
                      className={classes.dateOverrideSchedule}
                    >
                      <span>{displayDate}</span>
                      <span>
                        {displayFromTime}
                        &nbsp;-&nbsp;
                        {displayToTime}
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={handleDeleteSchedule}
                        data-eventindex={scheduleIndex}
                      >
                        <img src={DeleteIcon} alt="delete" />
                      </span>
                    </div>
                  );
                },
              )}
          </div>
        </div>

        {isModalOpen && (
          <OverrideScheduleModal
            isOpen
            onClose={toggleCutomScheduleModal}
            clinicsAssociated={clinicsAssociated}
            providerId={providerId}
            refetchSchedule={refetchSchedule}
            defaultClinic={defaultClinic}
          />
        )}

        {deleteScheduleDetails?.id && (
          <ConfirmModal
            className="confirm-modal-center"
            isOpen={!!deleteScheduleDetails?.id}
            onConfirm={deleteSchedule}
            onCancel={() => updateState({ deleteScheduleDetails: null })}
            onClose={() => updateState({ deleteScheduleDetails: null })}
            isStoppingSchedule={isLoading}
          >
            Are you sure you want to delete this schedule for{" "}
            {deleteScheduleDetails.displayDate},{" "}
            {deleteScheduleDetails.displayFromTime}
            &nbsp;-&nbsp;
            {deleteScheduleDetails.displayToTime}
          </ConfirmModal>
        )}
      </div>
    </>
  );
};

export default SetCustomHours;
