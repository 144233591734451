import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPageParam,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup
      .array()
      .of(
        yup.object({
          bi_order: yup.string().required(),
          bi_client_name: yup.string().required(),
          bi_email_phone: yup.string().required(),
          bi_email: yup.string().required(),
          bi_phone: yup.string().required(),
          bi_total: yup.string().required(),
          bi_payment_status: yup.string().required(),
          bi_fulfillment_status: yup.string().required(),
          bi_items: yup.number().required(),
          bi_date: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const reports = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    reports,
  };
};

function createShopifyOrdersReportQueryKey(...args) {
  return [QUERY_KEYS.shopifyOrdersReport.get, ...args];
}

export function useShopifyOrdersReportQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );

  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createShopifyOrdersReportQueryKey(fromDate, toDate),
    queryFn: async ({ pageParam = {} }) => {
      const res = await http.get(HTTP_ENDPOINTS.shopifyOrdersReport.get(), {
        params: removeNullishFromShape({
          ...pageParam,
          pagesize: req.pageSize,
          start_date: fromDate,
          end_date: toDate,
        }),
      });

      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPageParam,
  });
}
