/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import validator from "validator";
import picClose from "../../_legacy/images/popupClose.png";

export default class SaveShareList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let name = event.target.name;
    this.props.handleShareNameListState({ [name]: value });
  };

  handleSubmit = () => {
    let error = false;
    let returnState = {
      shareNameListClass: "simpleInput",
      shareApiKeyListClass: "simpleInput",
      shareApiUrlClass: "simpleInput",
    };
    if (
      this.props.shareNameList === null ||
      this.props.shareNameList === undefined ||
      this.props.shareNameList === ""
    ) {
      returnState.shareNameListClass = "simpleInput field_error";
      error = true;
    }

    if (this.props.shareReportType === "ActiveCampaign") {
      if (
        this.props.shareApiKey === null ||
        this.props.shareApiKey === undefined ||
        this.props.shareApiKey === ""
      ) {
        returnState.shareApiKeyListClass = "simpleInput field_error";
        error = true;
      }

      if (
        this.props.shareApiUrl === null ||
        this.props.shareApiUrl === undefined ||
        this.props.shareApiUrl === "" ||
        !validator.isURL(this.props.shareApiUrl, { protocols: ["https"] })
      ) {
        returnState.shareApiUrlClass = "simpleInput field_error";
        error = true;
      }
    }

    if (error) {
      this.props.handleShareNameListState(returnState);
      return false;
    }

    this.props.handleShareNameListSubmit();
  };

  handleShareNameListModal = () => {
    this.props.handleShareNameListState({
      showShareNameModal: false,
      shareQuery: {},
      shareNameList: "",
      shareNameListClass: "simpleInput",
    });
    localStorage.removeItem("shareReportOptionFilter");
  };

  render() {
    return (
      <div
        className={
          this.props.showShareNameModal
            ? "blackOverlay"
            : "blackOverlay no-display"
        }
      >
        <div className="vert-middle">
          <div className="white-popup small-popup">
            <div className="white-popup-wrapper">
              <div className="membershipTypeTitle">
                Contact List
                <a
                  onClick={this.handleShareNameListModal}
                  className="popupClose"
                >
                  <img alt="" src={picClose} />
                </a>
              </div>

              <div className="col-sm-12 col-xs-12">
                <div className="simpleField">
                  <div className="simpleLabel">Contact List</div>
                  <input
                    type="text"
                    className={this.props.shareNameListClass}
                    name="shareNameList"
                    value={this.props.shareNameList}
                    onChange={this.handleInputChange}
                    placeholder={"Enter contact list"}
                  />
                </div>
              </div>

              {this.props.shareReportType === "ActiveCampaign" && (
                <React.Fragment>
                  <div className="col-sm-12 col-xs-12">
                    <div className="simpleField">
                      <div className="simpleLabel">Active Campaign API Key</div>
                      <input
                        type="text"
                        className={this.props.shareApiKeyClass}
                        name="shareApiKey"
                        value={this.props.shareApiKey}
                        onChange={this.handleInputChange}
                        placeholder={"Enter list name"}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-xs-12">
                    <div className="simpleField">
                      <div className="simpleLabel">Active Campaign URL</div>
                      <input
                        type="text"
                        className={this.props.shareApiUrlClass}
                        name="shareApiUrl"
                        value={this.props.shareApiUrl}
                        onChange={this.handleInputChange}
                        placeholder={"Enter list name"}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="footer-static">
              <a
                className="new-blue-btn pull-right"
                onClick={this.handleSubmit}
              >
                Save
              </a>
              <a
                className="new-white-btn pull-right"
                onClick={this.handleShareNameListModal}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
