import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Summary } from "../../widgets/Summary/Summary";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { SelectPayment } from "../../shared/SelectPayment/SelectPayment";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { PAYMENT_VIEW_TYPES } from "../../../../../store/checkoutInvoice/consts";
import { StageGrid } from "../../shared/StageGrid/StageGrid";
import { Amount } from "../../widgets/Amount/Amount";
import { useInvoice } from "../../hooks/invoice/useInvoice";

export function StageStart() {
  const { tCommon } = useAppTranslation.Common();
  const { invoice } = useInvoice();
  const queryClient = useQueryClient();

  const onPaymentOptionsOpen = () => {
    queryClient.invalidateQueries("availablePaymentMethods");
    if (invoice.totalAmount === 0) {
      dispatch(
        checkoutInvoice.actions.paymentViewChange({
          paymentView: PAYMENT_VIEW_TYPES.cash,
        }),
      );
    } else {
      dispatch(
        checkoutInvoice.actions.paymentViewChange({
          paymentView: PAYMENT_VIEW_TYPES.optionSelection,
        }),
      );
    }
  };

  return (
    <StageGrid>
      <Amount />
      <Summary />
      <LabeledBox label={tCommon("label.payment")}>
        <SelectPayment onSelect={onPaymentOptionsOpen} />
      </LabeledBox>
    </StageGrid>
  );
}
