export function getSummarySectionsVisibilityStatuses(intersection) {
  const socialHistory = intersection?.social_history || {};
  const allergy = intersection?.allergy || {};
  const currentMedications = intersection?.current_medication || {};
  const familyHealth = intersection?.family_health_history || {};
  const currentMedConditions = intersection?.current_medical_history || {};
  const pastMedConditions = intersection?.past_medical_history || {};

  return {
    patientInfo: Boolean(intersection?.height || intersection?.weight),

    socialHistory: [
      socialHistory.tobacco_use,
      socialHistory.drug_use,
      socialHistory.alcohol_use,
      socialHistory.weight,
    ].some(Boolean),

    allergies: [allergy.drugs, allergy.foods, allergy.environment].some(
      Boolean,
    ),

    currentMedications: [
      currentMedications.prescription_medications,
      currentMedications.vitamins,
      currentMedications.over_medications,
    ].some(Boolean),

    familyHealth: Boolean(familyHealth.check === 0),

    currentMedConditions: [
      currentMedConditions.ongoing_conditions,
      currentMedConditions.nutrition,
      currentMedConditions.pregnancy,
      currentMedConditions.breastfeeding,
    ].some(Boolean),

    pastMedConditions: [
      pastMedConditions.surgeries,
      pastMedConditions.hospitalizations,
      pastMedConditions.implants,
      pastMedConditions.vaccines,
    ].some(Boolean),
  };
}
