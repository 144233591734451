import React from "react";
import { Box } from "../../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import {
  tCommon,
  useAppTranslation,
} from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { DeleteAction } from "./shared/DeleteAction/DeleteAction";
import { history } from "../../../../../../history";
import { ROUTES } from "../../../../../../consts/routes";

/**
 * @param {Object} param0
 * @param {{
 *  value: Array<{
 *    id: number;
 *    name: string;
 *    lastModifiedBy: string;
 *  }>;
 *  isLoading: boolean;
 * }} param0.templates
 */
export const TemplatesTable = ({
  templates,
  hasAddEditEfaxTemplatesPermission,
}) => {
  const { tSettings } = useAppTranslation.Settings();

  const data = templates.value.map((r) => ({
    key: r.id,
    name: r.name,
    lastModifiedBy: r.lastModifiedBy || tCommon("symbol.longDash"),
    actions: hasAddEditEfaxTemplatesPermission ? (
      <div className={styles.actions}>
        <DeleteAction id={r.id} />
      </div>
    ) : null,
  }));

  const isEmpty = !templates.isTemplatesLoading && !templates.value.length;
  const isVisible = !templates.isTemplatesLoading && !isEmpty;

  return (
    <Box>
      {templates.isTemplatesLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <SharedTable
          bgColor="white"
          data={data}
          onRowClick={(row) => {
            if (hasAddEditEfaxTemplatesPermission) {
              history.push(ROUTES.settings.editEfaxTemplate(row.key));
            }
          }}
          preventRowClickForAccessors={["actions"]}
          cols={[
            {
              data: tSettings("efaxTemplates.table.name"),
              accessor: "name",
              headColClassName: styles.col,
            },
            {
              data: tSettings("efaxTemplates.table.lastModifiedBy"),
              accessor: "lastModifiedBy",
              headColClassName: styles.col,
            },
            {
              data: "",
              accessor: "actions",
            },
          ]}
        />
      )}
    </Box>
  );
};
