const initialState = {
  cardreaders: [],
  clinics: [],
  error: "",
  cardreadersModels: [],
};

const CardreaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CARDREADERS_SUCCESS": {
      return { ...state, cardreaders: action.payload };
    }
    case "GET_CARDREADERS_ERROR": {
      return { ...state, cardreaders: [] };
    }
    case "CLINIC_LIST": {
      return { ...state, clinics: action.payload };
    }
    case "ADD_CARDREADER_ERROR": {
      return { ...state, error: action.payload };
    }
    case "GET_CARDREADERS_MODELS": {
      return { ...state, cardreadersModels: action.payload };
    }
    default:
      return state;
  }
};

export default CardreaderReducer;
