import React from "react";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { Table } from "./boxes/Table/Table";
import { ButtonScrollTop } from "../../../../../shared/ButtonScrollTop/ButtonScrollTop";
import { StickyBox } from "../../../../../shared/StickyBox/StickyBox";
import { useReport } from "./hooks/useReport";
import { Header } from "./boxes/Header";

export const ShopifyOrdersReport = () => {
  const { reports, period } = useReport();

  return (
    <ReportLayout>
      <Header period={period} isReportsLoading={reports.isLoading} />
      <Table reports={reports} />
      <StickyBox position="left" visibleAfterOffset={300}>
        <ButtonScrollTop />
      </StickyBox>
    </ReportLayout>
  );
};
