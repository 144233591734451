import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./Questionnaire.module.scss";
import { EyeIcon } from "../../../../../assets/Icons/EyeIcon";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { QUESTIONNAIRE_TYPES } from "../../../../../consts/api";
import { CrossIcon } from "../../../../../assets/Icons/CrossIcon";
import { CheckIcon } from "../../../../../assets/Icons/CheckIcon";
import { ModalPatientQuestionnaireFill } from "../../../../../widgets/ModalPatientQuestionnaireFill/ModalPatientQuestionnaireFill";
import { PatientQuestionnairePreview } from "../../../../../widgets/PatientQuestionnairePreview/PatientQuestionnairePreview";
import { Modal } from "../../../../../shared/Modal/Modal";
import { extractQuestionnaireStatus } from "../../../../../utilities/api";
import { OnFileMark } from "../../../shared/OnFileMark/OnFileMark";
import { OnFileParams } from "../../../shared/OnFileParams/OnFileParams";
import { OnFileButton } from "../../../shared/OnFileButton/OnFileButton";
import { useMarkAsOnFile } from "../../hooks/useMarkAsOnFile";
import { FillButton } from "../../../shared/FillButton/FillButton";

export function Questionnaire({
  patientQuestionnaire,
  refetchQuestionnaires,
  procedureId,
}) {
  const { tCommon } = useAppTranslation.Common();
  const status = extractQuestionnaireStatus(patientQuestionnaire);

  const onFile = useMarkAsOnFile();

  const [isFillModalOpen, setIsFillModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const typeLabelsMap = {
    [QUESTIONNAIRE_TYPES.multi]: tCommon("label.multiScale"),
    [QUESTIONNAIRE_TYPES.simple]: tCommon("label.simple"),
  };

  const isMarkAsOnFileAllowed = status !== "filled" && !!procedureId;

  function renderCompletionIndicator() {
    switch (status) {
      case "notFilled":
        return (
          <div className={classes.notFilled}>
            {tCommon("label.notFilled")}
            <CrossIcon />
          </div>
        );
      case "filled":
        return (
          <div className={classes.filled}>
            {tCommon("label.filled")}
            <CheckIcon />
          </div>
        );
      case "onFile":
        return <OnFileMark />;
      default:
        return null;
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <h3>{patientQuestionnaire.questionnaire.title}</h3>
          <div className={classes.params}>
            <div className={classes.type}>
              {typeLabelsMap[patientQuestionnaire.questionnaire.type]}
            </div>
            {renderCompletionIndicator()}
          </div>
          {status === "onFile" && (
            <OnFileParams
              date={patientQuestionnaire.already_on_file}
              userName={patientQuestionnaire.user_marked_already_on_file}
            />
          )}
        </div>
        <div className={classes.footer}>
          <button onClick={() => setIsViewModalOpen(true)}>
            <EyeIcon width="16px" />
            {tCommon("label.view")}
          </button>
          {status !== "onFile" && (
            <FillButton
              onClick={() => setIsFillModalOpen(true)}
              status={status}
              isPartiallyFilled={patientQuestionnaire.step_history?.length > 0}
            />
          )}
          {isMarkAsOnFileAllowed && status !== "onFile" && (
            <OnFileButton
              isMarked={false}
              isLoading={onFile.isLoading}
              onClick={() => onFile.mark(procedureId, patientQuestionnaire.id)}
            />
          )}
          {status === "onFile" && (
            <OnFileButton
              isMarked={true}
              isLoading={onFile.isLoading}
              onClick={() =>
                onFile.unmark(procedureId, patientQuestionnaire.id)
              }
            />
          )}
        </div>
      </div>
      {isFillModalOpen && (
        <ModalPatientQuestionnaireFill
          isOpen
          onClose={() => setIsFillModalOpen(false)}
          preloadedPatientQuestionnaire={patientQuestionnaire}
          onSuccess={() => {
            refetchQuestionnaires();
            setIsFillModalOpen(false);
          }}
        />
      )}
      {isViewModalOpen && (
        <Modal isOpen onClose={() => setIsViewModalOpen(false)}>
          <PatientQuestionnairePreview
            preloadedPatientQuestionnaire={patientQuestionnaire}
          />
        </Modal>
      )}
    </>
  );
}

Questionnaire.propTypes = {
  patientQuestionnaire: PropTypes.object.isRequired,
  refetchQuestionnaires: PropTypes.func,
};
