import React from "react";
import classes from "./HealthCreateEdit.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Header } from "../../shared/Header/Header";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { getHealthPathParams } from "./HealthCreateEdit.utils";
import { FormPrescribed } from "./widgets/FormPrescribed/FormPrescribed";
import { FormDefault } from "./widgets/FormDefault/FormDefault";
import { LayoutMain } from "../../../../../boxes/LayoutMain/LayoutMain";

export function HealthCreateEdit() {
  const { tClients } = useAppTranslation.Clients();
  const { isEditMode, clientId, isPrescribed } = getHealthPathParams();

  return (
    <LayoutMain>
      <Box className={classes.root}>
        <Header clientId={clientId}>
          {isEditMode
            ? tClients("createEditHealthProcedure.editLabel")
            : tClients("createEditHealthProcedure.createLabel")}
        </Header>
        {isPrescribed ? <FormPrescribed /> : <FormDefault />}
      </Box>
    </LayoutMain>
  );
}
