import { usePatientVitalNoteDeleteMutation } from "../../../../../../../../api/patientVitals/usePatientVitalNoteDeleteMutation";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { patientVitals } from "../../../../../../../../store/patients/vitals";
import { dispatch } from "../../../../../../../../store/store";

const { actions } = patientVitals;

export const useDeleteNote = (vitalId) => {
  const { tClients } = useAppTranslation.Clients();

  const { mutateAsync, isLoading } = usePatientVitalNoteDeleteMutation({
    options: {
      onError: () => {
        uiNotification.error(tClients("vitals.error.deleteNote"));
      },
      onSuccess: () => {
        uiNotification.success(tClients("vitals.success.deleteNote"));
      },
    },
  });

  const initiate = (id) => {
    if (vitalId) {
      return mutateAsync({ id });
    }
    return new Promise((res) => {
      dispatch(actions.deleteMutationRecordNote(id));
      res();
    });
  };

  return {
    initiate,
    isLoading,
  };
};
