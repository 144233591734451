import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Tabs.module.scss";

const VARIANTS = {
  default: "default",
  tiled: "tiled",
};

export function Tabs({
  options,
  active,
  className,
  tabClassName,
  variant,
  isDisabled,
}) {
  const rootClasses = cx(
    {
      [classes.root]: variant === VARIANTS.default,
      [classes.rootTiled]: variant === VARIANTS.tiled,
      [classes.disabled]: isDisabled,
    },
    className,
  );

  const getOptionClasses = (option) => {
    return cx(
      {
        [classes.tab]: variant === VARIANTS.default,

        [classes.tabTiled]: variant === VARIANTS.tiled,

        [classes.tabSelected]:
          active === option.value && variant === VARIANTS.default,

        [classes.tabTiledSelected]:
          active === option.value && variant === VARIANTS.tiled,
      },
      tabClassName,
      option.className,
    );
  };

  return (
    <div className={rootClasses}>
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          onClick={option.onClick}
          className={getOptionClasses(option)}
          disabled={isDisabled}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}

Tabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      onClick: PropTypes.func.isRequired,
      className: PropTypes.string,
    }),
  ).isRequired,
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  tabClassName: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  isDisabled: PropTypes.bool,
};

Tabs.defaultProps = {
  className: undefined,
  tabClassName: undefined,
  variant: VARIANTS.default,
};
