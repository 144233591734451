import React from "react";
import cx from "clsx";
import styles from "./scss/membershipbadge.module.scss";

export default class MembershipBadge extends React.Component {
  constructor(props) {
    super(props);
    this.badge = React.createRef();
    this.state = {
      color: this.props.color ? this.props.color : "#F5CB42",
      badgeWidth: null,
      badgeHeight: null,
    };
  }

  componentDidMount() {
    const badgeWidth = this.badge.current?.offsetWidth;
    const badgeHeight = this.badge.current?.offsetHeight;

    this.setState({
      badgeWidth: badgeWidth,
      badgeHeight: badgeHeight,
    });
  }

  render() {
    return (
      <div
        className={cx(Boolean(this.props.onHold) && styles.onHoldContainer)}
        style={{
          width: this.state.badgeHeight,
          height: this.state.badgeHeight,
        }}
      >
        {Boolean(this.props.onHold) && (
          <div
            className={styles.obliqueLine}
            style={{ height: this.state.badgeHeight }}
          ></div>
        )}
        <span
          className={cx(this.props.displayPage, "membership-bedge-block")}
          style={{ color: this.props.color }}
          ref={this.badge}
        >
          <span
            className={cx("membership-bedge-circle")}
            style={{ borderColor: this.props.color, color: this.props.color }}
          >
            <i className="membership-bedge-icon fa fa-star"> </i>
          </span>
        </span>
      </div>
    );
  }
}
