import { ACTION_TYPES } from "./consts";

export const actions = {
  searchOpenChange: (isOpen) => ({
    type: ACTION_TYPES.searchOpenChange,
    payload: {
      isOpen,
    },
  }),
};
