import * as yup from "yup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import {
  API_GENDERS_MAP,
  API_NON_BINARY_BODY_TEMPLATE_MAP,
  HTTP_ENDPOINTS,
  QUERY_KEYS,
} from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

const responseSchema = yup.object({
  alleStatus: yup.number().required(),

  aspireStatus: yup.number().required(),

  xperienceStatus: yup.number().required(),

  evolusStatus: yup.number().required(),

  repeatMdStatus: yup.number().required(),

  id: yup.number().required(),

  fullName: yup.string().required(),

  firstName: yup.string().required().nullable(),

  lastName: yup.string().required().nullable(),

  nickName: yup.string().nullable(),

  dateOfBirth: yup.string().nullable(),

  emails: yup.array().of(yup.string()).required(),

  email: yup.string().nullable(),

  email2: yup.string().nullable(),

  email3: yup.string().nullable(),

  phones: yup.array().of(yup.string()).required(),

  phone: yup.string().nullable(),

  phone2: yup.string().nullable(),

  phone3: yup.string().nullable(),

  pinCode: yup.string().nullable(),

  gender: yup.string().nullable(),

  documentsExpirationDate: yup.string().nullable(),

  canAddCreditCard: yup.bool().required(),

  state: yup.string().nullable(),

  referralSource: yup.string().nullable(),

  referralPerson: yup.string().nullable(),

  emergencyContactName: yup.string().nullable(),

  emergencyContactNumber: yup.string().nullable(),

  referralSourceSubcategory: yup.string().nullable(),

  country: yup.string().nullable(),

  city: yup.string().nullable(),

  totalSales: yup.number().nullable(),

  address1: yup.string().nullable(),

  membershipBenefits: yup.number().nullable(),

  imageUrl: yup.string().nullable(),

  address: yup.array().of(yup.string()).required(),

  brilliantAccNumber: yup.string().nullable(),

  aspireAccEmail: yup.string().nullable(),

  evolus: yup.string().nullable(),

  xperienceUsername: yup.string().nullable(),

  patientMembershipSubscription: yup.array().of(
    yup.object({
      id: yup.number(),
      membership_tier_id: yup.number(),
      membership_on_hold: yup.number(),
      last_invoice: yup
        .object({
          payment_status: yup.string().nullable(),
        })
        .nullable(),
      membership_tier: yup.object({
        membership_color: yup.string(),
        tier_name: yup.string(),
      }),
    }),
  ),

  accountPreferences: yup.object({
    patient_sign_up: yup.number(),
    membership_color: yup.string(),
  }),

  patientAccount: yup
    .object({
      patient_user: yup
        .object({
          id: yup.number(),
          email: yup.string(),
          status: yup.number(),
        })
        .nullable(),
    })
    .nullable(),

  patientInsurance: yup
    .object({
      carrier: yup.string().nullable(),
      employer: yup.string().nullable(),
      id: yup.number(),
      insurance_provider: yup.string().nullable(),
      phone: yup.string().nullable(),
      policy_group: yup.string().nullable(),
      policy_id: yup.string().nullable(),
      prescription_card: yup.string(),
    })
    .nullable(),

  allergies: yup.array().of(yup.string()).required(),

  lastProcedureDate: yup.string().nullable(),

  patientsSkinScore: yup
    .object({
      created: yup.string(),
      id: yup.number(),
      points: yup.number(),
      skin_discription: yup.string(),
      skin_type: yup.string,
    })
    .nullable(),

  hasAllergy: yup.boolean(),

  accessPortal: yup.number(),

  doNotCall: yup.boolean().nullable(),

  doNotEmail: yup.boolean().nullable(),

  doNotSms: yup.boolean().nullable(),

  isFired: yup.boolean().nullable(),

  clinics: yup
    .array()
    .of(
      yup.object({
        clinic_name: yup.string(),
        id: yup.number(),
      }),
    )
    .nullable(),

  nonBinaryBodyTemplate: yup.string().nullable(),

  creditCards: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        cardNumber: yup.string().required(),
        billingZip: yup.string().nullable(),
      }),
    )
    .required(),

  fullNameWithMiddle: yup.string(),

  clinicId: yup.number().nullable(),

  profileImageUrl: yup.string().nullable(),

  isMonthlyMembership: yup.boolean().nullable(),
});

const composeResponse = (res) => {
  const client = res?.data?.data || {};
  const cards = client?.card_on_files || [];
  return {
    id: client.id,

    totalSales: client?.total_sale_relationship,

    fullName: client.full_name,

    firstName: client.firstname,

    alleStatus: client?.alle_status,

    aspireStatus: client?.aspire_status,

    xperienceStatus: client?.xperience_status,

    evolusStatus: client?.evolus_status,

    repeatMdStatus: client?.repeatmd_status,

    dateOfBirth: client.date_of_birth || null,

    lastName: client.lastname,

    patientInsurance: client?.patient_insurence || null,

    evolus: client?.evolus || null,

    xperienceUsername: client?.xperience_username || null,

    fullNameWithMiddle: [
      client?.firstname,
      client?.middlename,
      client?.lastname,
    ]
      .filter(Boolean)
      .join(" "),

    emails: [
      client.email,
      client.email_2,
      client.email_3,
      client.email_4,
    ].filter(Boolean),

    email: client?.email,

    email2: client?.email_2,

    email3: client?.email_3,

    phones: [
      client.phoneNumber,
      client.phoneNumber_2,
      client.phoneNumber_3,
      client.phoneNumber_4,
    ].filter(Boolean),

    phone: client?.phoneNumber,

    phone2: client?.phoneNumber_2,

    phone3: client?.phoneNumber_3,

    pinCode: client?.pincode || null,

    referralPerson: client?.referral_person || null,

    canAddCreditCard: Boolean(client.can_add_card),

    documentsExpirationDate: client?.documentsExpirationDate,

    emergencyContactName: client?.emergency_contact_name || null,

    emergencyContactNumber: client?.emergency_contact_number || null,

    gender: API_GENDERS_MAP?.[client.gender] || null,

    nonBinaryBodyTemplate:
      API_NON_BINARY_BODY_TEMPLATE_MAP?.[client.non_binary_body_template] ||
      null,

    creditCards: cards?.map((card) => ({
      id: card.id,
      cardNumber: card.card_number,
      billingZip: card.billing_zip || null,
    })),

    accountPreferences: client?.account_prefrences || {},

    patientMembershipSubscription:
      client?.patient_membership_subscription || [],

    accessPortal: client?.access_portal,

    referralSource: client?.referral_source || null,

    referralSourceSubcategory: client?.referral_source_subcategory || null,

    lastProcedureDate: client?.last_procedure_date || null,

    address1: client?.address_line_1 || null,

    allergies: client?.allergies?.split(",").filter(Boolean) || [],

    patientAccount: client?.patient_account || null,

    hasAllergy: Boolean(client?.is_have_allergy) || false,

    clinicId: client?.clinic_id,

    nickName: client?.nick_name || null,

    country: client?.country || null,

    city: client?.city || null,

    patientsSkinScore: client?.patients_skin_score || null,

    membershipBenefits: client?.membership_benefits_this_year || null,

    doNotCall: Boolean(client?.do_not_call),

    doNotEmail: Boolean(client?.do_not_email),

    doNotSms: Boolean(client?.do_not_sms),

    state: client?.state || null,

    brilliantAccNumber: client?.brilliant_acc_number || null,

    aspireAccEmail: client?.aspire_acc_email || null,

    address: [
      client?.address_line_1,
      client?.city,
      client?.state,
      client?.pincode,
      client?.country,
      client?.address_line_2,
    ].filter(Boolean),

    imageUrl: client?.user_image_url || null,

    clinics: client?.clinics || null,

    isFired: Boolean(client?.is_fired),

    profileImageUrl: client.user_image_url || null,

    isMonthlyMembership: Boolean(client?.is_monthly_membership),
  };
};

function createClientQueryKey({ clientId }) {
  return [QUERY_KEYS.client, clientId];
}

export function useClientQuery(payload, options = {}) {
  return useQuery(
    createClientQueryKey({
      clientId: payload?.clientId,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.getClient(req.clientId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}

export const withClientRefetch = (Component) => (props) => {
  const client = useQueryClient();
  const refetchClientData = () => {
    client.invalidateQueries({
      queryKey: [QUERY_KEYS.client],
    });
  };

  return <Component {...props} refetchClient={() => refetchClientData()} />;
};
