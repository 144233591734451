import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  paginationSchema,
  getNextPaginationPage,
} from "../../schemas/pagination";

export const EFAXES_SORT_FIELDS = {
  faxNumber: "faxNumber",
  direction: "direction",
  status: "status",
  patientName: "patientName",
  sentBy: "sentBy",
  datetime: "datetime",
};

const requestSchema = yup.object({
  patientId: yup.number().nullable(),
  patientName: yup.string().nullable(),
  fromDate: yup.string().nullable(),
  toDate: yup.string().nullable(),
  sortBy: yup.string().nullable(),
  sortOrder: yup.string().nullable(),
  pageSize: yup.number().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    eFaxes: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          file: yup.string().nullable(),
          faxNumber: yup.string().required(),
          direction: yup.string().required(),
          status: yup.string().required(),
          documentTemplate: yup.string().nullable(),
          patientName: yup.string().nullable(),
          sentBy: yup.string().nullable(),
          datetime: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, page) => {
  const data = res?.data?.data || {};

  return {
    currentPage: page,
    total: 0,
    totalPages: data.lastPage,
    hasNextPage: page < data.lastPage,
    eFaxes: data.efaxes || [],
  };
};

const createEfaxesQueryKey = (...args) => [QUERY_KEYS.efax.faxes, ...args];

export const useEfaxesQuery = ({ payload = {}, options = {} } = {}) => {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createEfaxesQueryKey(
      req.patientId,
      req.patientName,
      req.fromDate,
      req.toDate,
      req.sortBy,
      req.sortOrder,
      req.pageSize,
      req.direction,
      req.isPatientAssigned,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.efax.getFaxes(), {
        params: removeNullishFromShape({
          ...req,
          page: pageParam,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, pageParam), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
};
