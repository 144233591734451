import React, { useRef } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FREQUENCY_END_TYPE } from "../../Event.consts";
import { EntityRow } from "../EntityRow";
import classes from "../../sass/Event.module.scss";
import { Radio } from "../../../../shared/Radio/Radio";
import { InputSecondary } from "../../../../shared/InputSecondary/InputSecondary";
import { DatePickerSecondary } from "../../../../shared/DatePickerSecondary/DatePickerSecondary";
import { previewFormDate } from "../../Event.utils";
import { formatUTC } from "../../../../Utils/dateHelper";

const OutOfOfficeEnds = ({
  eventDate,
  frequencyEndType,
  onFrequencyEndTypeChange,
  frequencyEndDate,
  onFrequencyEndDateChange,
  isFrequencyEndDateError,
  frequencyEndOccurrences,
  onFrequencyEndOccurrencesChange,
  isFrequencyEndOccurrencesError,
}) => {
  const datePickerRef = useRef();
  return (
    <EntityRow label="Ends" contentClassName={classes.ends}>
      <div className={classes.endsRow}>
        <Radio
          name="frequencyType"
          isChecked={frequencyEndType === FREQUENCY_END_TYPE.date}
          onChange={() => onFrequencyEndTypeChange(FREQUENCY_END_TYPE.date)}
          label="On"
        />
        <DatePickerSecondary
          showMonthDropdown
          showYearDropdown
          showDisabledMonthNavigation
          value={
            frequencyEndDate ? previewFormDate(frequencyEndDate) : undefined
          }
          onChange={onFrequencyEndDateChange}
          dateFormat="YYYY-MM-dd"
          name="endsOnDate"
          selected={
            frequencyEndDate
              ? formatUTC(moment.utc(frequencyEndDate).toDate())
              : null
          }
          minDate={formatUTC(moment.utc(eventDate).toDate())}
          isDisabled={frequencyEndType === FREQUENCY_END_TYPE.occurrence}
          ref={datePickerRef}
          onClickOutside={() => datePickerRef.current.setOpen(false)}
          isError={isFrequencyEndDateError}
          className={classes.endsInput}
        />
      </div>
      <div className={classes.endsRow}>
        <Radio
          name="frequencyType"
          isChecked={frequencyEndType === FREQUENCY_END_TYPE.occurrence}
          onChange={() =>
            onFrequencyEndTypeChange(FREQUENCY_END_TYPE.occurrence)
          }
          label="After"
        />
        <div>
          <InputSecondary
            className={classes.endsInput}
            type="number"
            min={1}
            value={frequencyEndOccurrences}
            name="occurrenceDays"
            onChange={(e) => onFrequencyEndOccurrencesChange(e.target.value)}
            isDisabled={frequencyEndType === FREQUENCY_END_TYPE.date}
            isError={isFrequencyEndOccurrencesError}
          />
          <div className={classes.endsOccurrenceLabel}>occurrences</div>
        </div>
      </div>
    </EntityRow>
  );
};

OutOfOfficeEnds.propTypes = {
  eventDate: PropTypes.string.isRequired,
  frequencyEndType: PropTypes.string.isRequired,
  onFrequencyEndTypeChange: PropTypes.func.isRequired,
  frequencyEndDate: PropTypes.string.isRequired,
  onFrequencyEndDateChange: PropTypes.func.isRequired,
  isFrequencyEndDateError: PropTypes.bool.isRequired,
  frequencyEndOccurrences: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onFrequencyEndOccurrencesChange: PropTypes.func.isRequired,
  isFrequencyEndOccurrencesError: PropTypes.bool.isRequired,
};

export default OutOfOfficeEnds;
