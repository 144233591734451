import { EDITOR_FILES_ACTIONS } from "./FileParser.consts";

export const dedupFiles = (rawNext, current) => {
  const currentSet = new Set(current.map((f) => f.name));
  return rawNext.reduce(
    (r, f) => {
      const nextRes = { ...r };
      if (currentSet.has(f.file.name)) {
        nextRes.duplicates.push(f.file.name);
      } else {
        nextRes.next.push(f);
      }
      return nextRes;
    },
    {
      duplicates: [],
      next: [],
    },
  );
};

export const editorFilesReducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case EDITOR_FILES_ACTIONS.updateFile: {
      if (payload.id && state[payload.id]) {
        const file = state[payload.id];
        return {
          ...state,
          [payload.id]: {
            ...file,
            ...payload.data,
          },
        };
      }
      return state;
    }
    case EDITOR_FILES_ACTIONS.setFile: {
      return {
        ...state,
        [payload.id]: payload.file,
      };
    }
    case EDITOR_FILES_ACTIONS.removeFile: {
      const next = Object.assign({}, state);
      delete next[payload.id];
      return next;
    }
    case EDITOR_FILES_ACTIONS.removeAll: {
      return {};
    }
    default:
      return state;
  }
};
