/**
 * @param {object} shape
 * @param {string | number} prefix
 * @returns {string}
 */
export function shapeToString(shape = {}) {
  return Object.entries(shape)
    .reduce((r, [k, v]) => (r += `${k}->${v}|`), "")
    .slice(0, -1);
}

/**
 * @param {Array<{ label: string, value: string | number }>} options
 * @returns {Array<{ label: string, value: string | number }>}
 */
export function dedupSelectOptions(options = []) {
  return options.reduce((r, o) => {
    if (r.find((i) => i.label === o.label)) {
      return r;
    }
    return [...r, o];
  }, []);
}
