import React, { useMemo } from "react";
import { useClinicsQuery } from "../../../../../api/queries/useClinicsQuery";
import { Select } from "../../../../../shared/Select/Select";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export function ClinicsSelect({ value, onSelect }) {
  const { tCommon } = useAppTranslation.Common();
  const [selected, setSelected] = React.useState(value);

  const { data, isLoading, isFetching } = useClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchClinics"));
    },
  });

  const options = useMemo(() => {
    return (
      data?.map((clinic) => ({
        label: clinic.name,
        value: clinic.id,
      })) || []
    );
  }, [data]);

  return (
    <Select
      isSearchable
      isLoading={isLoading || isFetching}
      size="small"
      options={options}
      value={options.find((option) => option.value === selected) || null}
      onChange={(option) => {
        onSelect(option.value);
        setSelected(option.value);
      }}
    />
  );
}
