import React from "react";

function ExpiredPaymentIcon() {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="25px"
        height="25px"
        viewBox="0 0 300.000000 300.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(50.000000,250.000000) scale(0.100000,-0.100000)"
          fill="#d23638"
          stroke="none"
        >
          <path
            d="M1205 2450 c-33 -5 -90 -18 -125 -30 -237 -80 -397 -221 -499 -442
-51 -108 -71 -203 -71 -328 0 -236 85 -437 251 -596 139 -132 286 -200 478
-223 92 -10 131 -2 131 26 0 10 7 31 15 47 8 15 15 30 15 32 0 2 -18 4 -40 4
l-40 0 0 50 c0 42 -3 50 -19 50 -22 0 -31 -19 -31 -66 0 -40 -3 -40 -103 -19
-73 15 -187 62 -187 77 0 5 9 26 19 47 15 29 17 41 8 50 -16 16 -35 14 -41 -6
-4 -10 -14 -28 -24 -41 l-18 -24 -58 48 c-58 46 -146 155 -146 179 0 6 16 20
35 31 41 22 49 38 29 55 -12 10 -23 8 -55 -10 -22 -13 -42 -21 -45 -19 -16 16
-55 161 -61 223 l-6 74 49 3 c42 3 49 6 52 26 3 20 0 22 -48 22 l-52 0 7 57
c6 53 53 199 75 233 7 12 15 11 50 -9 73 -41 100 0 30 44 l-41 26 21 29 c26
37 139 140 178 162 l30 18 26 -40 c14 -22 31 -40 36 -40 20 0 23 32 6 68 -16
33 -16 37 -1 48 23 17 159 51 228 57 l57 6 0 -49 c0 -34 4 -50 16 -54 26 -10
34 2 34 50 l0 44 39 0 c56 0 226 -56 259 -85 2 -2 -7 -21 -19 -43 -20 -34 -21
-42 -10 -56 16 -19 34 -8 57 36 8 15 19 28 23 28 30 0 210 -191 211 -222 0 -4
-18 -17 -40 -28 -40 -21 -52 -46 -26 -56 8 -3 32 4 54 15 l41 21 20 -56 c27
-70 38 -124 43 -194 l3 -55 -45 -3 c-53 -4 -68 -15 -50 -37 8 -9 29 -15 57
-15 39 0 44 -2 38 -19 -4 -14 7 -36 37 -75 24 -30 46 -55 50 -55 12 -2 23 111
22 219 -3 227 -92 423 -262 581 -180 166 -419 241 -667 209z"
          />
          <path
            d="M1360 1901 l0 -170 -30 -16 c-29 -15 -60 -60 -60 -88 0 -14 -15 -19
-126 -47 -52 -13 -71 -32 -49 -50 13 -10 31 -9 100 10 l85 23 25 -26 c14 -14
37 -28 51 -31 36 -9 90 10 114 41 40 51 17 150 -40 168 -18 6 -20 18 -22 178
-3 170 -3 172 -25 175 l-23 3 0 -170z"
          />
          <path
            d="M1855 1506 c-27 -8 -49 -25 -73 -55 -50 -66 -252 -413 -340 -584 -21
-42 -11 -121 19 -154 48 -51 61 -53 441 -53 395 0 400 1 446 70 41 60 29 110
-68 280 -206 362 -279 477 -313 489 -46 17 -73 19 -112 7z m70 -361 l0 -150
-27 -3 -28 -3 0 149 c0 82 3 152 7 156 4 4 16 6 28 4 19 -3 20 -10 20 -153z
m0 -295 c0 -52 -1 -55 -25 -55 -22 0 -25 5 -28 44 -4 57 3 73 30 69 21 -3 23
-9 23 -58z"
          />
        </g>
      </svg>{" "}
    </>
  );
}

export default ExpiredPaymentIcon;
