import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import ViewTipsModal from "../../../../ViewTipsModal/ViewTipsModal";
import { svg } from "../../../../../../../../assets/svg";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { usePaymentsChangeWatcher } from "../../../../../hooks/usePaymentsChangeWatcher";
import { dispatch } from "../../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import RemainingBalanceDue from "../../../../../shared/RemainingBalanceDue/RemainingBalanceDue";
import { LoadingAlert } from "../../../../../../../../boxes/LoadingAlert/LoadingAlert";

const PaymentTipsModal = ({
  isTipsApplying,
  form,
  errors,
  isValid,
  changeFormValue,
  showTipsModal,
}) => {
  const { invoice, amountToPay } = useInvoice();
  const { transferPaymentToReader } = useInvoiceActions();
  const { watchPaymentsChange } = usePaymentsChangeWatcher({
    onPaymentsUpdated: () =>
      dispatch(checkoutInvoice.actions.paymentViewClose()),
  });

  const onClose = () => {
    showTipsModal.update(false);
  };

  const handleSubmit = () => {
    transferPaymentToReader
      .initiate({
        amount: form.amount,
        cardReaderId: form.readerId,
      })
      .then(() => {
        watchPaymentsChange.initiate();
        uiNotification.success(
          tSales("checkoutInvoice.success.transferPaymentToReader"),
        );
      })
      .catch(onClose);
  };

  useEffect(() => {
    changeFormValue("amount", amountToPay);
  }, [amountToPay]);

  const isChargeDisabled =
    isTipsApplying || !isValid || transferPaymentToReader.isLoading;

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
      onCharge={handleSubmit}
      isCharging={transferPaymentToReader.isLoading}
      chargeLabel={tCommon("label.charge")}
      amount={form.amount}
      isChargeDisabled={isChargeDisabled}
    >
      <ViewTipsModal amountToPay={amountToPay} />
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={errors.amount}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {errors.amount && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <RemainingBalanceDue amount={form.amount} />
      {watchPaymentsChange.isLoading && (
        <LoadingAlert>
          <Trans
            i18nKey={tSales("checkoutInvoice.warn.awaitingForReaderPayment")}
            components={{
              b: <b />,
            }}
          />
        </LoadingAlert>
      )}
    </ModalCharge>
  );
};

export default PaymentTipsModal;
