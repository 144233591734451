import { useMemo, useState } from "react";
import moment from "moment";
import { useServicesPerformedReportQuery } from "../../../../../../api/queries/useServicesPerformedReportQuery";
import { API_DATE_FORMAT, ORDER_TYPES } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { tCommon } from "../../../../../../i18n/useAppTranslation";

export const useReports = () => {
  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: new Date(),
  });
  const [filter, setFilter] = useState({
    clinics: [],
    services: [],
    categories: [],
    provider: {},
  });

  const formatedFilter = useMemo(() => {
    return {
      clinicsIds: filter.clinics.map((c) => c.value),
      serviceIds: filter.services.map((s) => s.value),
      categoryIds: filter.categories.map((c) => c.value),
      provider_id: filter.provider.value,
    };
  }, [filter]);

  const [order, setOrder] = useState({
    by: "",
    order: "",
  });

  const updateOrder = (orderFieldKey) => {
    setOrder({
      by: orderFieldKey,
      order:
        order.order === ORDER_TYPES.desc ? ORDER_TYPES.asc : ORDER_TYPES.desc,
    });
  };

  const preparePeriod = (period) => {
    return {
      fromDate: moment(period.from).format(API_DATE_FORMAT),
      toDate: moment(period.to).format(API_DATE_FORMAT),
    };
  };

  const servicesPerformedReport = useServicesPerformedReportQuery(
    { ...preparePeriod(period), ...formatedFilter, ...order },
    {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  );

  const reports = useMemo(() => {
    if (servicesPerformedReport?.data?.pages) {
      return servicesPerformedReport?.data?.pages.flatMap((p) => p.data.data);
    }
    return [];
  }, [servicesPerformedReport?.data?.pages]);

  const reportData =
    useMemo(() => reports?.flatMap((r) => r.data), [reports]) || [];
  const reportHeaders = reports[0]?.columns || [];
  const clinicsFilter = reports[0]?.filters?.clinics || {};
  const categoriesFilter = reports[0]?.filters?.categories || {};
  const providersFilter = reports[0]?.filters?.providers || {};
  const servicesFilter = reports[0]?.filters?.services || {};
  const totalDatas = reports[0]?.total || 0;
  const currency =
    servicesPerformedReport?.data?.pages[0]?.data.global_settings.pos_data
      .currency_symbol;

  const isSoftLoading =
    servicesPerformedReport.isFetching &&
    !servicesPerformedReport.isFetchingNextPage;

  return {
    report: {
      isLoading: servicesPerformedReport.isLoading,
      isNextFetching: servicesPerformedReport.isFetchingNextPage,
      data: reportData,
      headers: reportHeaders,
      hasMore: Boolean(servicesPerformedReport.hasNextPage),
      fetchMore: servicesPerformedReport.fetchNextPage,
      isSoftLoading,
      total: totalDatas,
    },
    reportFilters: {
      clinicsFilter,
      categoriesFilter,
      providersFilter,
      servicesFilter,
    },
    period: {
      value: period,
      apiValue: preparePeriod(period),
      update: setPeriod,
    },
    filter: {
      value: filter,
      formatedValue: formatedFilter,
      update: setFilter,
    },
    order: {
      value: order,
      update: updateOrder,
    },
    currency,
  };
};
