import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";
import cx from "clsx";
import classes from "./InputCurrency.module.scss";

const SIZES = {
  medium: "medium",
  small: "small",
};

export function InputCurrency({
  id,
  name,
  placeholder,
  defaultValue,
  decimalsLimit,
  onChange,
  className,
  isDisabled,
  suffix,
  prefix,
  size,
  isError,
  value,
}) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.rootMedium]: size === SIZES.medium,
      [classes.rootSmall]: size === SIZES.small,
      [classes.rootDisabled]: isDisabled,
      [classes.rootError]: isError,
    },
    className,
  );

  return (
    <CurrencyInput
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      decimalsLimit={decimalsLimit}
      onValueChange={onChange}
      disabled={isDisabled}
      suffix={suffix}
      prefix={prefix}
      className={rootClasses}
    />
  );
}

InputCurrency.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalsLimit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  isError: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(Object.values(SIZES)),
};

InputCurrency.defaultProps = {
  id: undefined,
  name: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  decimalsLimit: 2,
  className: undefined,
  isDisabled: false,
  suffix: undefined,
  prefix: undefined,
  isError: false,
  value: undefined,
};
