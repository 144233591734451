import React from "react";
import PropTypes from "prop-types";
import { EVENT_TYPES } from "../../../../store/event/consts";
import { Radio } from "../../../../shared/Radio/Radio";
import classes from "../../sass/Event.module.scss";

const CommonType = ({ eventType, onChange }) => (
  <>
    <div className={classes.mb10}>
      <Radio
        name="type"
        isChecked={eventType === EVENT_TYPES.appointment}
        onChange={() => onChange(EVENT_TYPES.appointment)}
        label="Appointment"
      />
    </div>
    <div>
      <Radio
        name="type"
        isChecked={eventType === EVENT_TYPES.outOfOffice}
        onChange={() => onChange(EVENT_TYPES.outOfOffice)}
        label="Out of office"
      />
    </div>
  </>
);

CommonType.propTypes = {
  eventType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CommonType;
