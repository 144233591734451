/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

class BiUnavailable extends React.Component {
  render() {
    return (
      <div className="main-content protected">
        <div
          id="content"
          style={{
            display: "flex",
            justifyContent: "center",
            lineHeight: "22px",
          }}
        >
          <div className="content">
            <div className="title">
              <label className="patient-count patient-name">
                Business Insights is currently unavailable!
              </label>
            </div>
            <div>
              You can access all BI reports from 7:00 PM EST- 9:00 AM EST only
              on the following days: <br />
              <br />
              Friday, November 26th <br />
              Saturday, November 27th <br />
              Monday, November 29th <br />
              <br />
              Normal functionality will return at 7:00 PM EST on Monday,
              November 29th.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BiUnavailable;
