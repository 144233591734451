import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./ModalCharge.module.scss";

export function InputRow({ children }) {
  return <div className={cx(classes.inputRow)}>{children}</div>;
}

InputRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
