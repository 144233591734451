import { findDuplicatesInStrings } from "../../../../helpers/general";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export function useCheckAnswerDuplicates() {
  const { tSettings: t } = useAppTranslation.Settings();

  return (answers = []) => {
    const duplicates = findDuplicatesInStrings(answers);

    if (duplicates.length) {
      uiNotification.error(
        `${t("questionnaires.error.answerDuplicates")}: ${duplicates.join(
          ", ",
        )}`,
      );
      return false;
    }

    return true;
  };
}
