import React, { useState } from "react";
import { connect } from "react-redux";
import { VerticalTable } from "../../../../../shared/VerticalTable/VerticalTable";
import classes from "./Table.module.scss";
import { Empty } from "./shared/Empty/Empty";
import { Skeleton } from "./shared/Skeleton/Skeleton";
import { patientVitals } from "../../../../../store/patients/vitals";
import { dispatch } from "../../../../../store/store";
import { vitalToMutationRecordAdapter } from "../../../../../store/patients/vitals/utilities";
import { useColumns } from "./useColumns";
import { useAccessors } from "./useAccessors";

const { selectors, actions } = patientVitals;

const mapStateToProps = (state) => ({
  activeRecordId: selectors.selectActiveRecordId(state),
  isCreateMode: selectors.selectIsCreateMode(state),
  mutationRecord: selectors.selectMutationRecord(state),
  mutationRecordFieldErrors: selectors.selectMutationRecordFieldErrors(state),
});

export const Table = connect(mapStateToProps)(
  ({
    activeRecordId,
    isCreateMode,
    mutationRecord,
    mutationRecordFieldErrors,
  }) => {
    const [isColSelectDisabled, setIsColSelectDisabled] = useState(false);
    const { accessors, isAccessorsLoading } = useAccessors();

    const { cols, prependedCols, isColumnsLoading } = useColumns({
      isCreateMode,
      mutationRecord,
      mutationRecordFieldErrors,
      setIsColSelectDisabled,
    });

    return (
      <div className={classes.root}>
        {isAccessorsLoading || isColumnsLoading ? (
          <Skeleton />
        ) : (
          <VerticalTable
            accentFirstLine
            accessors={accessors}
            cols={cols}
            prependedCols={prependedCols}
            activeColKey={activeRecordId}
            colClassName={classes.col}
            onColClick={({ key }) => {
              if (!isColSelectDisabled) {
                dispatch(
                  actions.activeRecordIdChange(
                    activeRecordId === key ? undefined : key,
                  ),
                );
              }
            }}
            emptyNode={
              <Empty
                onCreate={() =>
                  dispatch(
                    actions.setMutationRecord(vitalToMutationRecordAdapter()),
                  )
                }
              />
            }
          />
        )}
      </div>
    );
  },
);
