/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "clsx";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { weekTemplate } from "../../../_legacy/Constants";
import {
  useGetProviderTempaltesQuery,
  useUpdateScheduleQuery,
} from "../../../_legacy/Queries";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import CalendarIcon from "../../../_legacy/images/calendar-hollow.svg";
import EditIcon from "../../../_legacy/images/edit-action.svg";
import { uiNotification } from "../../../services/UINotificationService";
import { Button } from "../../../shared/Button/Button";
import { Select } from "../../../shared/Select/Select";
import {
  checkOverlappingTime,
  truncateString,
} from "../../../utilities/general";
import { CommonDialog } from "../../Common";
import Loader from "../../Common/Loader";
import SelectWeeklyHours from "./SelectWeeklyHours";
import classes from "./sass/schedule.module.scss";
import { Input } from "../../../shared/Input/Input";
import useAdvancedState from "../../../utilities/hooks/useAdvancedState";
import PlusIcon from "../../../_legacy/images/plus.svg";
import SetCustomHours from "./components/SetCustomHours";
import EditTemplateName from "./components/EditTemplateName";
import { Tabs } from "../../../shared/Tabs/Tabs";

const providerScheduleSchema = yup.object().shape({
  name: yup.string().when("isCreatingNewTemplateSchedule", {
    is: true,
    then: yup
      .string()
      .required("Name is required")
      .min(3, "Name must be atleast 3 characters")
      .max(30, "Name can not exceed 30 characters"),
  }),
  selectedClinic: yup.object().shape({
    value: yup.number().typeError("Required").required("Required"),
  }),
  scheduleDate: yup.date().required("Start Date is required"),
  repeatDate: yup
    .date()
    .nullable()
    .when("editScheduleIndefinitely", {
      is: false,
      then: yup.date().required("End Date is required"),
    })
    .test(
      "Invalid date range",
      "Please select a valid date range",
      (repeatTillDate, formContext) => {
        const { scheduleDate } = formContext.parent;
        return repeatTillDate
          ? moment(repeatTillDate) >= moment(scheduleDate).startOf("day")
          : true;
      },
    ),
  clinicSchedules: yup
    .array()
    .test("select at least 1 day", "Select at least 1 day", (clinicSchedules) =>
      clinicSchedules.some((schedule) => schedule.isSelected),
    )
    .required("Please select at least 1 day")
    .of(
      yup.object().shape({
        timeSchedules: yup.array().when("isSelected", {
          is: true,
          then: yup.array().of(
            yup.object().shape({
              fromTime: yup.string().required("Required"),
              toTime: yup
                .string()
                .required("Required")
                .test(
                  "to time is greater than from time",
                  "Invalid Schedule",
                  (toTime, context) => {
                    const { fromTime } = context.parent;
                    return (
                      toTime &&
                      fromTime &&
                      parseInt(toTime.replace(":", "")) >
                        parseInt(fromTime.replace(":", ""))
                    );
                  },
                ),
              availableFor: yup.string().required("Required"),
            }),
          ),
        }),
      }),
    ),
});

const groupSchedulesForWeek = (schedules) => {
  return schedules.reduce((groupedSchedule, scheduleDetails) => {
    const category = scheduleDetails.day_of_week;

    if (!groupedSchedule[category]) {
      groupedSchedule[category] = [];
    }

    groupedSchedule[category].push(scheduleDetails);

    return groupedSchedule;
  }, {});
};
const UpdateProviderSchedule = (props) => {
  const {
    onClose,
    providerId,
    refetchSchedule,
    selectedPickerDate,
    clinicsAssociated,
    setSchedulingErrors,
    isNewTemplate,
    activeTemplate,
    defaultClinic,
  } = props;

  const {
    state: {
      editTemplateNameModal,
      isNeedToRefetchSchedule,
      regularSchedulesTab,
    },
    updateState,
    toggleField,
  } = useAdvancedState({
    isSettingsMenuOpen: false,
    editTemplateNameModal: false,
    isNeedToRefetchSchedule: false,
    regularSchedulesTab: true,
  });

  const [warnClinicChangeModalStatus, setWarnClinicChangeModalStatus] =
    useState({
      isOpen: false,
    });

  const { tCommon } = useAppTranslation.Common();

  const { data: providerTemplatesData, dataUpdatedAt: templatesDataFetchedAt } =
    useGetProviderTempaltesQuery({ id: providerId });

  const providerTemplates = useMemo(
    () =>
      providerTemplatesData?.data?.data?.filter(
        (templateData) => templateData.is_active,
      ) || [],
    [templatesDataFetchedAt],
  );

  const { mutate: updateSchedule, isLoading: isCreatingSchedule } =
    useUpdateScheduleQuery();

  const { register, ...formMethods } = useForm({
    defaultValues: {
      clinicSchedules: selectedPickerDate
        ? weekTemplate.map((dayDetails) => ({
            ...dayDetails,
            isSelected: dayDetails.id === moment(selectedPickerDate).day() + 1,
          }))
        : weekTemplate,
      isScheduleDatePickerOpen: false,
      isRepeatDatePickerOpen: false,
      copyDayScheduleToArray: [],
      isCopyScheduleDropdownOpen: null,
      selectedClinic: clinicsAssociated?.length
        ? {
            label: defaultClinic.clinic_name,
            value: defaultClinic.id,
          }
        : {
            label: "",
            value: null,
          },
      editScheduleIndefinitely: true,
      scheduleDate: selectedPickerDate || new Date(),
      availableClinicsOptions: clinicsAssociated.map((clinicDetails) => ({
        value: clinicDetails.id,
        label: clinicDetails.clinic_name,
      })),
      name: isNewTemplate ? "" : activeTemplate.name,
      selectedTemplateSchedule: activeTemplate || null,
      isCreatingNewTemplateSchedule: isNewTemplate,
    },
    resolver: yupResolver(providerScheduleSchema),
  });

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    setError,
    reset,
  } = formMethods;

  const setFormValue = (field, value, config) => {
    setValue(field, value, { shouldDirty: true, ...config });
  };

  const formValues = watch();

  const {
    selectedClinic,
    scheduleDate,
    repeatDate,
    isScheduleDatePickerOpen,
    isRepeatDatePickerOpen,
    editScheduleIndefinitely,
    availableClinicsOptions,
    name,
    selectedTemplateSchedule,
    isCreatingNewTemplateSchedule,
  } = formValues;

  const weekDayIndex = useCallback(
    (weekDay) =>
      weekTemplate.findIndex(
        (weekDayDetails) => weekDayDetails.dayFullName === weekDay,
      ),
    [weekTemplate],
  );

  useEffect(() => {
    if (selectedTemplateSchedule?.id && templatesDataFetchedAt) {
      const selectedTemplate = providerTemplates.find(
        (templateDetails) => templateDetails.id == selectedTemplateSchedule.id,
      );

      const groupedSchedules = groupSchedulesForWeek(
        selectedTemplate.template_provider_schedule_slots,
      );

      setValue("name", selectedTemplate.name);

      const selectedClinic = clinicsAssociated.find(
        (clinicDetails) => clinicDetails.id === selectedTemplate.clinic_id,
      );
      setValue("selectedClinic", {
        ...selectedClinic,
        label: selectedClinic.clinic_name,
        value: selectedClinic.id,
      });

      setValue(
        "scheduleDate",
        moment(selectedTemplate.start_date, "YYYY-MM-DD").toDate(),
      );

      const scheduleHasEndDate = selectedTemplate.end_date;

      setValue(
        "repeatDate",
        scheduleHasEndDate
          ? moment(selectedTemplate.end_date, "YYYY-MM-DD").toDate()
          : null,
      );
      setValue("editScheduleIndefinitely", !scheduleHasEndDate);

      const templateSchedule = JSON.parse(JSON.stringify(weekTemplate));

      Object.keys(groupedSchedules).forEach((weekDay) => {
        const dayIndex = weekDayIndex(weekDay);

        templateSchedule[dayIndex] = {
          ...templateSchedule[dayIndex],
          isSelected: true,

          timeSchedules: groupedSchedules[weekDay]
            .sort((a, b) => {
              const timeA = a.start_time;
              const timeB = b.start_time;
              return timeA.localeCompare(timeB, { numeric: true });
            })
            .map((timeSlots) => {
              const { end_time, start_time, available_for, clinic_id, id } =
                timeSlots;

              const fromTimeMoment = moment(start_time, "HH:mm:ss");
              const endTimeMoment = moment(end_time, "HH:mm:ss");

              return {
                fromTimeHour: fromTimeMoment.format("hh:mm"),
                fromTime: fromTimeMoment.format("HH:mm"),
                fromTimeOption: fromTimeMoment.format("A"),
                toTimeHour: endTimeMoment.format("hh:mm"),
                toTime: endTimeMoment.format("HH:mm"),
                toTimeOption: endTimeMoment.format("A"),
                availableFor: available_for,
                clinic_id,
                isAldreadyExistingSlot: !!id,
              };
            }),
        };
      });
      setFormValue("clinicSchedules", templateSchedule);

      setFormValue("isUpdatingTemplate", Object.keys(groupedSchedules).length);

      reset(watch());
    }
  }, [selectedTemplateSchedule?.id, templatesDataFetchedAt]);

  const toggleShceduleDatePicker = () => {
    setFormValue("isScheduleDatePickerOpen", !isScheduleDatePickerOpen);
  };
  const toggleRepeatDatePicker = () => {
    setFormValue("isRepeatDatePickerOpen", !isRepeatDatePickerOpen);
  };

  const setDay = (date, name, shouldValidate = false) => {
    setFormValue(name, date, { shouldValidate });
    switch (name) {
      case "scheduleDate":
        toggleShceduleDatePicker();
        break;
      case "repeatDate":
        toggleRepeatDatePicker();
        break;
      default:
        break;
    }
  };

  const deleteRepeatTillDate = () => {
    setFormValue("repeatDate", null);
  };

  const needToRefetchSchedule = () => {
    if (!isNeedToRefetchSchedule) {
      updateState({ isNeedToRefetchSchedule: true });
    }
  };

  const closeModal = () => {
    if (isNeedToRefetchSchedule) {
      refetchSchedule();
    }
    onClose();
  };

  const saveProviderSchedule = async (formData) => {
    if (isCreatingSchedule) {
      return false;
    }
    const { scheduleDate, repeatDate, selectedClinic, clinicSchedules } =
      formData;

    let overlappingTimeError = false;

    clinicSchedules.forEach((dayDetails, dayIndex) => {
      if (dayDetails.isSelected) {
        const isTimeSlotsOverlapping = checkOverlappingTime(
          dayDetails.timeSchedules.filter((x) => !x.isDeleted),
        );
        if (isTimeSlotsOverlapping) {
          overlappingTimeError = true;
          isTimeSlotsOverlapping.forEach((slotIndex) => {
            setError(
              `clinicSchedules[${dayIndex}].timeSchedules[${slotIndex}].fromTime`,
            );
            setError(
              `clinicSchedules[${dayIndex}].timeSchedules[${slotIndex}].toTime`,
            );
          });
        }
      }
    });

    if (overlappingTimeError) {
      uiNotification.error("Time entered for clinic is overlapping");
      return false;
    }

    const scheduleData = {
      provider_id: providerId,
      clinic_id: selectedClinic.value,
      name,
      isNewTemplate: isCreatingNewTemplateSchedule,
    };

    if (isCreatingNewTemplateSchedule) {
      scheduleData.start_date = moment(scheduleDate).format("YYYY-MM-DD");
    } else {
      scheduleData.id = selectedTemplateSchedule.id;
    }

    if (editScheduleIndefinitely) {
      scheduleData.end_date = null;
    } else {
      scheduleData.end_date = moment(repeatDate).format("YYYY-MM-DD");
    }

    scheduleData.template_schedules = clinicSchedules
      .map((scheduleDetails) => ({
        day_of_week: scheduleDetails.dayFullName,
        time_schedules: scheduleDetails.timeSchedules
          .filter((x) => x.fromTime && x.toTime)
          .map((scheduleItemDetails) => ({
            start_time: scheduleItemDetails.fromTime,
            end_time: scheduleItemDetails.toTime,
            available_for: scheduleItemDetails.availableFor,
            is_deleted:
              scheduleItemDetails.isDeleted ||
              !scheduleDetails.isSelected ||
              false,
          })),
      }))
      .filter((schedule) => !!schedule.time_schedules?.length);

    updateSchedule(scheduleData, {
      onSuccess: () => {
        uiNotification.success(
          tCommon(
            `providerSchedule.success.${
              isCreatingNewTemplateSchedule
                ? `scheduleOverrideSuccess`
                : `scheduleTemplateUpdated`
            }`,
          ),
        );

        refetchSchedule();
        closeModal();
      },
      onError: (error) => {
        if (error.response.data.data?.length) {
          setSchedulingErrors({
            ...error.response.data,
            message: tCommon(
              `providerSchedule.error.${error.response.data.message}`,
            ),
          });
        } else {
          uiNotification.error(
            tCommon([
              `providerSchedule.error.${error.response?.data?.message}`,
              `error.fallback`,
            ]),
          );
        }
        needToRefetchSchedule();
      },
    });
  };

  const displayScheduleErrors = (errors) => {
    if (errors.clinicSchedules) {
      if (Array.isArray(errors.clinicSchedules)) {
        uiNotification.error("Please enter a valid time");
      } else {
        uiNotification.error(errors.clinicSchedules.message);
      }
    }
    if (errors.selectedClinic) {
      uiNotification.error("Select clinic");
    }
    if (errors.repeatDate) {
      uiNotification.error(errors.repeatDate.message);
    }
    if (errors.name) {
      uiNotification.error(errors.name.message);
    }
  };

  const toggleScheduleIndefinitely = () => {
    setFormValue("editScheduleIndefinitely", !editScheduleIndefinitely);
  };

  const clinicChange = (clinicDetails) => {
    if (selectedClinic.value !== clinicDetails.value) {
      if (isDirty) {
        // Warn users before discarding changes
        setWarnClinicChangeModalStatus({
          isOpen: true,
          newClinicDetails: clinicDetails,
          actionMessage: "change selected clinic for this template",
          onConfirm: () => {
            setFormValue("selectedClinic", clinicDetails, {
              shouldValidate: !!errors.selectedClinic,
            });
          },
        });
      } else {
        setFormValue("selectedClinic", clinicDetails, {
          shouldValidate: !!errors.selectedClinic,
        });
      }
    }
  };

  const confirmDiscardScheduleChanges = (actionMessage, onConfirm) => {
    if (isDirty) {
      setWarnClinicChangeModalStatus({
        isOpen: true,
        actionMessage,
        onConfirm,
      });
    } else {
      onConfirm();
    }
  };

  const discardChangesConfirmClinicChange = () => {
    warnClinicChangeModalStatus.onConfirm();
    closeWarnClinicChangeModal();
  };

  const closeWarnClinicChangeModal = () => {
    setWarnClinicChangeModalStatus({ isOpen: false });
  };

  const selectActiveTemplate = (event) => {
    const { templateId } = event.currentTarget.dataset;

    if (templateId === selectedTemplateSchedule?.id) {
      return false;
    }

    // Check if currently editing any template and prompt to save unsaved changes or discard
    confirmDiscardScheduleChanges("edit another template", () => {
      reset({
        ...watch(),
        isScheduleDatePickerOpen: false,
        isRepeatDatePickerOpen: false,
        copyDayScheduleToArray: [],
        isCopyScheduleDropdownOpen: null,
        editScheduleIndefinitely: true,
        isCreatingNewTemplateSchedule: false,
        selectedTemplateSchedule: { id: templateId },
      });
    });
  };

  const addNewTemplate = () => {
    confirmDiscardScheduleChanges("create a new template", () => {
      setValue("isCreatingNewTemplateSchedule", true);

      reset({
        ...watch(),
        clinicSchedules: weekTemplate,
        isScheduleDatePickerOpen: false,
        isRepeatDatePickerOpen: false,
        copyDayScheduleToArray: [],
        isCopyScheduleDropdownOpen: null,
        editScheduleIndefinitely: true,
        scheduleDate: new Date(),
        name: "",
        selectedTemplateSchedule: null,
        isCreatingNewTemplateSchedule: true,
        repeatDate: null,
      });

      if (defaultClinic) {
        const selectedClinic = {
          ...defaultClinic,
          label: defaultClinic.clinic_name,
          value: defaultClinic.id,
        };
        setValue("selectedClinic", selectedClinic);
      }
    });
  };

  const editTemplateConfirmation = () => {
    toggleField("editTemplateNameModal");
  };

  const updateTemplateName = (newName) => {
    setValue("name", newName);
  };

  const toggleMobileView = () => {
    toggleField("regularSchedulesTab");
  };

  return (
    <div className={classes.modalRoot}>
      <CommonDialog
        title={
          isCreatingNewTemplateSchedule ? "Create Schedule" : `Update ${name}`
        }
        onClose={closeModal}
        footer={
          <div className={classes.footerWrapper}>
            <div className={classes.footer}>
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button
                type="submit"
                form="create-provider-schedule-form"
                className={cx(classes.saveButton, {
                  [classes.saveButtonResponsive]: regularSchedulesTab,
                })}
              >
                Save
              </Button>
            </div>
          </div>
        }
        className={cx(classes.updateProviderSchedule, {
          [classes.updateManualSchedule]: !regularSchedulesTab,
        })}
        style="primary"
        footerClassName={classes.responsiveFooter}
      >
        <div className={classes.mobileViewTabs}>
          <Tabs
            variant="tiled"
            active={
              regularSchedulesTab ? "regularSchedules" : "manualSchedules"
            }
            options={[
              {
                label: "Regular Schedules",
                value: "regularSchedules",
                onClick: toggleMobileView,
              },
              {
                label: "Manual Schedules",
                value: "manualSchedules",
                onClick: toggleMobileView,
              },
            ]}
          />
        </div>
        <div
          className={cx(classes.templatesList, classes.templateForm, {
            [classes.displayFlex]: regularSchedulesTab,
          })}
        >
          {providerTemplates.map(({ name, id }) => {
            const isTemplateSelected = id == selectedTemplateSchedule?.id;

            return (
              <span
                className={cx(classes.templateDetails, {
                  [classes.selectedTemplateDetail]: isTemplateSelected,
                })}
                onClick={selectActiveTemplate}
                data-template-id={id}
                key={`schedule-template-button-${id}`}
              >
                {truncateString(name)}
              </span>
            );
          })}

          <span
            className={cx(classes.templateDetails, {
              [classes.selectedTemplateDetail]: isCreatingNewTemplateSchedule,
            })}
            onClick={addNewTemplate}
          >
            {!isCreatingNewTemplateSchedule && (
              <>
                <img src={PlusIcon} alt="+" />
                &nbsp;
              </>
            )}
            {!isCreatingNewTemplateSchedule
              ? "Add New"
              : name || "New Template"}
          </span>
        </div>

        <div className="flex">
          <form
            onSubmit={handleSubmit(saveProviderSchedule, displayScheduleErrors)}
            id="create-provider-schedule-form"
            className={cx(classes.templateForm, {
              [classes.displaytemplateForm]: regularSchedulesTab,
            })}
          >
            <div className="row">
              <div className="col-xs-12">
                <div
                  className={cx(classes.selectTitle, classes.templateNameField)}
                >
                  Schedule Name{" "}
                  {!isCreatingNewTemplateSchedule && (
                    <div className={classes.templateSettingsButton}>
                      <img
                        alt=""
                        src={EditIcon}
                        onClick={editTemplateConfirmation}
                        className="cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                <div className={classes.selectClinicDropdown}>
                  <Input
                    size="small"
                    isError={Boolean(errors.name)}
                    name="name"
                    type="text"
                    isDisabled={!isCreatingNewTemplateSchedule}
                    {...register("name")}
                    maxLength={30}
                  />
                </div>
              </div>
              <div className="col-xs-12">
                <div className={classes.selectTitle}>Clinics</div>
                <div className={classes.selectClinicDropdown}>
                  <Select
                    isError={Boolean(errors.selectedClinic)}
                    value={selectedClinic}
                    onChange={clinicChange}
                    options={availableClinicsOptions}
                    isSearchable
                    placeholder="Select Clinic"
                    name="select-clinic"
                    size="small"
                    isDisabled={!isCreatingNewTemplateSchedule}
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 flex-col">
                <span
                  className={cx(
                    classes.repeatIndefinitely,
                    "flex items-center cursor-pointer",
                  )}
                  onClick={toggleScheduleIndefinitely}
                >
                  <input
                    type="checkbox"
                    name="repeatIndefinitely"
                    checked={editScheduleIndefinitely}
                    className="filled-checkbox"
                  />
                  <label>Repeat Indefinitely</label>
                </span>
              </div>
            </div>
            <div className={classes.selectDates}>
              <div className={cx(classes.datePicker, "flex-col")}>
                <div className={classes.selectTitle}>
                  Start Date&nbsp;
                  <span className="required">*</span>
                </div>
                <div className="setting-input-outer">
                  <a
                    className={cx(
                      classes.dateCalendarIcon,
                      classes.calendarIcon,
                    )}
                  >
                    <img src={CalendarIcon} alt="" />
                  </a>
                  <div>
                    <DatePicker
                      onChange={(selectedDay) =>
                        setDay(selectedDay, "scheduleDate")
                      }
                      minDate={moment().toDate()}
                      maxDate={new Date(moment().add(5, "years").toDate())}
                      autoComplete="off"
                      className="setting-input-box p-r-40"
                      name="scheduleDate"
                      showMonthDropdown
                      showYearDropdown
                      selected={scheduleDate}
                      open={isScheduleDatePickerOpen}
                      onFocus={toggleShceduleDatePicker}
                      onClickOutside={toggleShceduleDatePicker}
                      disabled={!isCreatingNewTemplateSchedule}
                    />
                  </div>
                </div>
              </div>

              {!editScheduleIndefinitely && (
                <div className={cx(classes.datePicker, "flex-col")}>
                  {/* Repeat till */}
                  <div className={classes.selectTitle}>
                    Repeat Until&nbsp;<span className="required">*</span>
                  </div>
                  <div className="setting-input-outer">
                    <a
                      className={cx(
                        classes.datepickerAction,
                        classes.calendarIcon,
                      )}
                      onClick={toggleRepeatDatePicker}
                    >
                      <img src={CalendarIcon} alt="" />
                    </a>
                    {repeatDate && (
                      <a
                        className={cx(
                          classes.calendarIcon,
                          classes.datepickerAction,
                          classes.deleteRepeatDateIcon,
                        )}
                        onClick={deleteRepeatTillDate}
                      >
                        <i className="fas fa-times" />
                      </a>
                    )}
                    <DatePicker
                      onChange={(selectedDay) =>
                        setDay(selectedDay, "repeatDate", errors.repeatDate)
                      }
                      minDate={
                        new Date(
                          moment
                            .max(
                              moment(scheduleDate).add(1, "days"),
                              moment(new Date()),
                            )
                            .toDate(),
                        )
                      }
                      maxDate={new Date(moment().add(5, "years").toDate())}
                      autoComplete="off"
                      className={cx("setting-input-box p-r-40", {
                        "field-error": Boolean(errors.repeatDate),
                      })}
                      name="repeatDate"
                      showMonthDropdown
                      showYearDropdown
                      selected={repeatDate}
                      open={isRepeatDatePickerOpen}
                      onFocus={toggleRepeatDatePicker}
                      onClickOutside={toggleRepeatDatePicker}
                    />
                  </div>
                </div>
              )}
            </div>

            <FormProvider {...formMethods}>
              <SelectWeeklyHours />
            </FormProvider>
          </form>
          <div className={classes.verticalBorder} />
          <hr />
          <div
            className={cx(classes.manualSchedulesSection, {
              [classes.displayManualSchedule]: !regularSchedulesTab,
            })}
          >
            <SetCustomHours
              providerId={providerId}
              clinicsAssociated={clinicsAssociated}
              refetchSchedule={needToRefetchSchedule}
              defaultClinic={defaultClinic}
            />
          </div>
        </div>
        {isCreatingSchedule && <Loader showLoader />}
      </CommonDialog>

      {warnClinicChangeModalStatus.isOpen && (
        <ConfirmModal
          className="confirm-modal-center"
          isOpen={warnClinicChangeModalStatus.isOpen}
          onConfirm={discardChangesConfirmClinicChange}
          onCancel={closeWarnClinicChangeModal}
          onClose={closeWarnClinicChangeModal}
        >
          Are you sure you want to {warnClinicChangeModalStatus?.actionMessage}?
          This will discard your current schedule changes.
        </ConfirmModal>
      )}

      {editTemplateNameModal && (
        <EditTemplateName
          templateId={selectedTemplateSchedule.id}
          templateName={name}
          onClose={() => toggleField("editTemplateNameModal")}
          providerId={providerId}
          updateTemplateName={updateTemplateName}
        />
      )}
    </div>
  );
};
export default UpdateProviderSchedule;
