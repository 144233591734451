import moment from "moment";

/**
 * @param {string} timeString
 * @returns {{
 *    hours: number;
 *    minutes: number;
 *    seconds: number;
 * }}
 */
export function parseTimeString(timeString) {
  const result = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (typeof timeString === "string" && timeString.includes(":")) {
    const parsed = timeString.split(":").map((n) => parseInt(n));
    result.hours = parsed[0] || result.hours;
    result.minutes = parsed[1] || result.minutes;
    result.seconds = parsed[2] || result.seconds;
  }

  return result;
}

/**
 * @param {number} hours
 * @returns {number}
 */
export function hoursToMs(hours) {
  if (typeof hours === "number") {
    return hours * 60 * 60000;
  }
  return 0;
}

/**
 * @param {number} minutes
 * @returns {number}
 */
export function minutesToMs(minutes) {
  if (typeof minutes === "number") {
    return minutes * 60000;
  }
  return 0;
}

/**
 * @param {number} seconds
 * @returns {number}
 */
export function secondsToMs(seconds) {
  if (typeof seconds === "number") {
    return seconds * 1000;
  }
  return 0;
}

/**
 * @param {string} tz
 * @returns {string}
 */
export function getTimezonedNow(tz = moment.tz.guess()) {
  return moment.tz(tz).format("YYYY-MM-DD HH:mm");
}

/**
 * @param {array<object>} events
 * @param {string} dateKey
 * @param {string} timeKey
 * @returns {array<object>}
 */

export const sortEvents = (events, dateKey, timeKey) => {
  return [...events].sort((a, b) => {
    let dateA = moment(a[dateKey], "MMM DD YYYY");
    let dateB = moment(b[dateKey], "MMM DD YYYY");

    if (dateA.isBefore(dateB)) return -1;
    if (dateA.isAfter(dateB)) return 1;

    let timeA = moment(a[timeKey], "hh:mm A");
    let timeB = moment(b[timeKey], "hh:mm A");

    if (timeA.isBefore(timeB)) return -1;
    if (timeA.isAfter(timeB)) return 1;

    return 0;
  });
};
