import { useMemo } from "react";
import { useSettingViews } from "../../hooks/useSettingViews";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

export const useAccessors = () => {
  const { tClients } = useAppTranslation.Clients();
  const settingViews = useSettingViews();

  const accessors = useMemo(() => {
    const dynamicOptions =
      settingViews.data?.map((key) => ({
        label: tClients(`vitals.settingViewLabel.${key}`),
        value: key,
      })) || [];

    return [
      {
        label: tClients("vitals.settingViewLabel.date"),
        value: "date",
      },
      ...dynamicOptions,
      {
        label: tClients("vitals.settingViewLabel.provider"),
        value: "provider",
      },
      {
        label: tClients("vitals.settingViewLabel.notes"),
        value: "notes",
      },
    ];
  }, [settingViews.data]);

  return {
    accessors,
    isAccessorsLoading: settingViews.isLoading,
  };
};
