import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../shared/Modal/Modal";
import { Button } from "../../../../shared/Button/Button";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { unwrapOr } from "../../../../utilities/general";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";
import { useAllMdsQuery } from "../../../../api/queries/useAllMdsQuery";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../shared/Select/Select";
import { useFormModalSign } from "./useFormModalSign";
import { InputError } from "../../../../shared/InputError/InputError";
import classes from "./ModalSign.module.scss";
import { Signature } from "../../../../boxes/Signature/Signature";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import { useSign } from "../../hooks/useSign";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

export function ModalSign({ isOpen, onClose, data }) {
  const { tCommon } = useAppTranslation.Common();

  const { sign, isSigning } = useSign();
  const { data: user, isFetching: isUserFetching } = useCurrentUserQuery();
  const { data: mds, isFetching: isMdsFetching } = useAllMdsQuery();

  const savedSignature = unwrapOr(() => user.signatureUrl, null);

  const { form, errors, hasError, setFormValue, submit } = useFormModalSign({
    defaultMdId: unwrapOr(() => user.mdId, null),
    savedSignature,
    isSendToMd: data.isSendToMd,
    onSubmit: (values) => {
      sign({
        isConsult: data.isConsult,
        mdId: values.mdId,
        procedureIds: data.procedureIds,
        signature: values.signature,
        prevSignature: savedSignature,
        saveSignature: values.isSaveSignature,
      });
    },
  });

  const getMdSelectValue = () => {
    const md = mds.find((m) => m.id === form.mdId);
    if (md) {
      return {
        label: md.fullName,
        value: md.id,
      };
    }
    return undefined;
  };

  const isPreparing = isUserFetching || isMdsFetching;

  const getTitle = () => {
    if (data.isConsult && data.isSendToMd) {
      return tCommon("label.sign");
    }
    if (data.isConsult) {
      return tCommon("providerRoom.signModal.signAndMarkAsConsult");
    }
    if (data.isSendToMd) {
      return tCommon("providerRoom.signModal.signAndSendToMd");
    }
    return tCommon("label.sign");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.form}
      header={<Modal.Title>{getTitle()}</Modal.Title>}
      footer={
        isPreparing ? (
          <Skeleton height={44} borderRadius="4px" width="145px" />
        ) : (
          <Button
            onClick={submit}
            isDisabled={isSigning}
            leftAdornment={
              isSigning ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {tCommon("label.complete")}
          </Button>
        )
      }
    >
      {isPreparing ? (
        <Skeleton height={200} borderRadius="8px" />
      ) : (
        <>
          <div>
            <InputLabel>
              {tCommon("providerRoom.signModal.signBelow")}
            </InputLabel>
            <Signature
              onChange={(data) => setFormValue("signature", data)}
              onClear={() => setFormValue("signature", "")}
              onEdit={() => setFormValue("signature", "")}
              onReset={() => setFormValue("signature", savedSignature)}
              signatureUrl={savedSignature}
              isError={hasError("signature")}
              isDisabled={isSigning}
              buttonsRightClassName={classes.signatureButtonsRight}
              buttonsRight={
                <Checkbox
                  size="small"
                  label={tCommon("label.saveSignature")}
                  isChecked={form.isSaveSignature}
                  isDisabled={isSigning}
                  onChange={() =>
                    setFormValue("isSaveSignature", !form.isSaveSignature)
                  }
                />
              }
            />
            {hasError("signature") && (
              <InputError>{errors.signature}</InputError>
            )}
          </div>
          {data.isSendToMd && mds && (
            <div>
              <InputLabel>
                {tCommon("providerRoom.signModal.selectMd")}
              </InputLabel>
              <Select
                value={getMdSelectValue()}
                onChange={({ value }) => setFormValue("mdId", value)}
                isError={hasError("mdId")}
                isDisabled={isSigning}
                options={mds.map((md) => ({
                  label: md.fullName,
                  value: md.id,
                }))}
              />
              {hasError("mdId") && <InputError>{errors.mdId}</InputError>}
            </div>
          )}
        </>
      )}
    </Modal>
  );
}

ModalSign.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    procedureIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSendToMd: PropTypes.bool.isRequired,
    isConsult: PropTypes.bool.isRequired,
  }).isRequired,
};
