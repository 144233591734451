import { useState } from "react";
import { useAppointmentsRestoreNoShowMutation } from "../../../../api/appointments/useAppointmentsRestoreNoShowMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { extractApiError } from "../../../../utilities/api";

export function useRestore() {
  const { tAppointments } = useAppTranslation.Appointments();

  const [idsToRestore, setIdsToRestore] = useState([]);
  const [loadingIds, setLoadingIds] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { mutateAsync } = useAppointmentsRestoreNoShowMutation();

  const reset = () => {
    setIdsToRestore([]);
    setLoadingIds([]);
  };

  const declineConfirm = () => {
    setIsConfirmOpen(false);
    reset();
  };

  const initiate = async () => {
    try {
      setLoadingIds(idsToRestore);
      setIsConfirmOpen(false);
      await mutateAsync({ appointment_id: idsToRestore[0] });
      uiNotification.success(tAppointments("noShows.success.restore"));
    } catch (e) {
      const message = extractApiError(e);
      uiNotification.error(
        tAppointments([`noShows.apiError.${message}`, "noShows.error.restore"]),
      );
    } finally {
      reset();
    }
  };

  return {
    setOne: (id) => setIdsToRestore((prev) => [...prev, id]),
    reset,
    loadingIds,
    initiate,
    isConfirmOpen,
    requestConfirm: () => setIsConfirmOpen(true),
    declineConfirm,
  };
}
