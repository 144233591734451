/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { positionFooterInstantly } from "../../../../Utils/services.js";

class StaffTreatment extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
      upDown: false,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData
    ) {
      returnState.reportData = nextProps.reportData;

      let reportData = nextProps.reportData.report_data;

      if (reportData && reportData.length) {
        reportData.map((obj, idx) => {
          returnState["upDown-" + idx] = false;
        });
      }
    }

    return returnState;
  }

  toggleAnchor = (idx) => {
    let returnState = {};
    returnState["upDown-" + idx] = !this.state["upDown-" + idx];
    this.setState(returnState, function () {
      positionFooterInstantly();
    });
  };

  render() {
    return (
      <div className="setting-setion">
        <div className="table-responsive clients-table">
          <div className="accordion-table sales-accordian accord-min-width monthly-net-sale-table">
            <div className="accordion-row accordion-head">
              {this.state.reportData &&
                this.state.reportData.report_header &&
                this.state.reportData.report_header.length > 0 &&
                this.state.reportData.report_header.map((obj, idx) => {
                  return (
                    <div
                      className="accordian-section col-xs-2 text-left"
                      key={idx}
                    >
                      {Object.values(obj)}
                    </div>
                  );
                })}
            </div>
            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length > 0 &&
              this.state.reportData.report_data.map((obj, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div className="accordion-row" key={idx}>
                      <div className="accordion-title profile">
                        {this.state.reportData &&
                          this.state.reportData.report_header &&
                          this.state.reportData.report_header.length > 0 &&
                          this.state.reportData.report_header.map(
                            (objInner, idxInner) => {
                              return (
                                <div
                                  className="accordian-section col-xs-2 text-left inner-box no-wrap no-border"
                                  key={idxInner}
                                >
                                  {idxInner === 0 && obj.data.length > 0 && (
                                    <React.Fragment key={idxInner}>
                                      <a
                                        className={
                                          this.state.reportData.grouping ==
                                            "yes" &&
                                          this.state["upDown-" + idx] == false
                                            ? ""
                                            : "no-display"
                                        }
                                        name="upDown"
                                        value={this.state.upDown}
                                        onClick={this.toggleAnchor.bind(
                                          this,
                                          idx,
                                        )}
                                      >
                                        <i className="fas fa-angle-down toggle-arrow" />
                                      </a>

                                      <a
                                        className={
                                          this.state.reportData.grouping ==
                                            "yes" &&
                                          this.state["upDown-" + idx] == true
                                            ? ""
                                            : "no-display"
                                        }
                                        name="upDown"
                                        value={this.state.upDown}
                                        onClick={this.toggleAnchor.bind(
                                          this,
                                          idx,
                                        )}
                                      >
                                        <i className="fas fa-angle-up toggle-arrow" />
                                      </a>
                                    </React.Fragment>
                                  )}{" "}
                                  {obj.user_name &&
                                    obj.user_name[Object.keys(objInner)]}
                                </div>
                              );
                            },
                          )}
                      </div>
                      {obj.data.length > 0 &&
                        this.state.reportData &&
                        this.state.reportData.report_header &&
                        this.state.reportData.report_header.length > 0 &&
                        obj.data.map((dataSet, dataIdx) => {
                          return (
                            <div
                              className={
                                this.state.reportData.grouping == "yes" &&
                                this.state["upDown-" + idx] == true
                                  ? "accordion-discription-row bg-white"
                                  : "accordion-discription-row no-display"
                              }
                              key={dataIdx}
                            >
                              {this.state.reportData.report_header.map(
                                (objInner, idxInner) => {
                                  return (
                                    <div
                                      className="accordian-section col-xs-2 text-left"
                                      key={idxInner}
                                    >
                                      {dataSet[Object.keys(objInner)]}
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </React.Fragment>
                );
              })}
            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length === 0 && (
                <div className="no-record" colSpan={11}>
                  {this.state.languageData.bi_no_record_found}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default StaffTreatment;
