import React from "react";
import { Router } from "react-router-dom";

/**
 * @param {Object} props
 * @param {Object} props.history
 * @param {React.ReactNode} props.children
 */
export const RouterProvider = ({ history, children }) => {
  return <Router history={history}>{children}</Router>;
};
