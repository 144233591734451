import React from "react";

export function SearchIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.7027C0 4.79176 4.79176 0 10.7027 0C16.6136 0 21.4054 4.79175 21.4054 10.7027C21.4054 13.3078 20.4746 15.6956 18.9275 17.5515L23.715 22.339C24.095 22.719 24.095 23.3351 23.715 23.715C23.3351 24.095 22.719 24.095 22.339 23.715L17.5515 18.9275C15.6956 20.4746 13.3078 21.4054 10.7027 21.4054C4.79175 21.4054 0 16.6136 0 10.7027ZM10.7027 1.94595C5.86647 1.94595 1.94595 5.86647 1.94595 10.7027C1.94595 15.5389 5.86648 19.4595 10.7027 19.4595C15.5389 19.4595 19.4595 15.5389 19.4595 10.7027C19.4595 5.86648 15.5389 1.94595 10.7027 1.94595Z"
        fill="currentColor"
      />
    </svg>
  );
}
