import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "../../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../../shared/CircularProgress/CircularProgress";
import { ErrorLogs } from "../../../../../../../../shared/ErrorLogs/ErrorLogs";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";

export function EditorFooter({
  onSubmitMultiple,
  onSubmitSingle,
  currentFile,
  files,
  isFilePending,
  isFilesParsing,
  errorLogs,
  isSubmitting,
  leftAdornment,
}) {
  const { tSettings } = useAppTranslation.Settings();

  const [isPostingSingle, setIsPostingSingle] = useState(false);
  const [isPostingMultiple, setIsPostingMultiple] = useState(false);

  const handlePostSingle = () => {
    setIsPostingSingle(true);
    onSubmitSingle();
  };

  const handlePostMultiple = () => {
    setIsPostingMultiple(true);
    onSubmitMultiple();
  };

  useEffect(() => {
    if (!isSubmitting) {
      setIsPostingMultiple(false);
      setIsPostingSingle(false);
    }
  }, [isSubmitting]);

  return (
    <div>
      <div className={styles.inner}>
        {leftAdornment}
        {files?.length > 1 && (
          <Button
            size="small"
            variant="outlined"
            onClick={handlePostMultiple}
            isDisabled={isFilePending || isFilesParsing || isSubmitting}
            leftAdornment={
              isPostingMultiple && isSubmitting ? (
                <CircularProgress size="tiny" color="primary" />
              ) : undefined
            }
          >
            {tSettings("efaxTemplates.createFromAll")}
          </Button>
        )}
        {currentFile && (
          <Button
            size="small"
            onClick={handlePostSingle}
            isDisabled={isFilePending || isFilesParsing || isSubmitting}
            leftAdornment={
              isPostingSingle && isSubmitting ? (
                <CircularProgress size="tiny" color="white" />
              ) : undefined
            }
          >
            {tSettings("efaxTemplates.createFromCurrent")}
          </Button>
        )}
      </div>
      {errorLogs?.length > 0 && (
        <ErrorLogs
          logs={errorLogs.map((e) => ({
            label: e.fileName,
            errors: e.errors,
          }))}
          type="nested"
          className={styles.errorLogs}
        />
      )}
    </div>
  );
}
