import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  clearentEmail: yup.string().nullable(),
  clearentZip: yup.string().nullable(),
  invoiceId: yup.number().required(),
  isSavedCard: yup.bool().required(),
  saveCard: yup.bool().nullable(),
  amount: yup.number().required(),
  stripeToken: yup.string().nullable(),
  clearentToken: yup.string().nullable(),
  paymentSystem: yup.string().required(),
});

export function useChargeWithCardMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });

    return http.post(
      HTTP_ENDPOINTS.postChargeInvoiceWithCardClearent(),
      removeNullishFromShape({
        clearent_email_id: req.clearentEmail,
        clearent_zip: req.clearentZip,
        invoice_id: req.invoiceId,
        is_saved_card: req.isSavedCard,
        save_card: req.saveCard,
        requested_payment: req.amount,
        stripeToken: req.stripeToken,
        token_id: req.clearentToken,
        card_token: dto.clearentCardToken,
      }),
    );
  }, options);
}
