import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../../shared/Button/Button";

export function Footer({ isFilesSelected, onOpenEditor }) {
  return (
    <>
      {isFilesSelected && (
        <Button variant="outlined" size="small" onClick={onOpenEditor}>
          Open Editor
        </Button>
      )}
    </>
  );
}

Footer.propTypes = {
  isFilesSelected: PropTypes.bool,
  onOpenEditor: PropTypes.func,
};

Footer.defaultProps = {
  isFilesSelected: false,
  onOpenEditor: () => {},
};
