/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getSupplierList,
  exportEmptyData,
  exportSupplierData,
  createEditSupplier,
} from "../../../Actions/Inventory/inventoryActions.js";
import { autoScrolling } from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import CreateEditSupplierModal from "./CreateEditSupplierModal.js";
import InventorySidebar from "../InventorySidebar.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class Suppliers extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      status: "active",
      showLoadingText: false,
      SupplierData: "",
      page: 1,
      pagesize: 15,
      sortby: "supplier_name",
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      supplierList: [],
      loadMore: true,
      startFresh: true,
      showLoader: false,
      add_user_limit_reached: 0,
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      pageNum: 1,
      role_id_inactive: null,
      inActive: false,
      activeNo: 0,
      inactiveNo: 0,
      isShowSupplierModal: false,
      clinicList: [],
      globalLang: languageData.global,
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  showLoaderFunc = () => {
    this.setState({ showLoader: true });
    localStorage.setItem("showLoader", true);
  };

  componentDidMount() {
    let isRender = true;

    let formData = {
      params: {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        sortorder: "asc",
        term: this.state.term,
      },
    };
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      clinics_Search: languageData.settings["clinics_Search"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      render: isRender,
    });
    this.setState({ showLoader: true });
    autoScrolling(true);
    this.props.getSupllierList(formData);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  handleSubmit = (event, value) => {
    let role_id = this.state.role_id;
    let status = this.state.inActive ? "Inactive" : "Active";
    if (event === "role_id") {
      role_id = value;
    } else if (event === "role_id_inactive") {
      role_id = value;
    } else if (event) {
      event.preventDefault();
    } else localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        status: status,
        page: 1,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        sortorder: this.state.sortorder,
        term: this.state.term,
      },
    };

    if (role_id) {
      formData.params.role_id = role_id;
    }
    this.setState({
      page: 1,
      sortby: this.state.sortby,
      pagesize: this.state.pagesize,
      sortorder: this.state.sortorder == "asc" ? "asc" : "desc",
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      supplierList: [],
      showLoader: true,
    });
    autoScrolling(true);
    this.props.getSupllierList(formData);
  };

  onSort = (sortby) => {
    let sortorder = this.state.sortorder === "asc" ? "desc" : "asc";
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term,
      },
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      supplierList: [],
    });
    localStorage.setItem("sortOnly", true);
    autoScrolling(true);
    this.props.getSupllierList(formData);
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = {};
      if (this.state.inActive == true) {
        formData = {
          params: {
            page: this.state.page,
            pagesize: this.state.pagesize,
            sortby: this.state.sortby,
            sortorder: this.state.sortorder,
            role_id: this.state.role_id_inactive,
            term: this.state.term,
            status: "Inactive",
          },
        };
      } else {
        formData = {
          params: {
            page: this.state.page,
            pagesize: this.state.pagesize,
            sortby: this.state.sortby,
            sortorder: this.state.sortorder,
            role_id: this.state.role_id,
            term: this.state.term,
          },
        };
      }
      autoScrolling(true);
      this.props.getSupllierList(formData);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.exportEmptyData();
      return { showLoader: false };
    }
    if (
      nextProps.supplierList != undefined &&
      nextProps.supplierListTime !== prevState.supplierListTime
    ) {
      returnState.countryList = nextProps.supplierList.countries
        ? nextProps.supplierList.countries
        : [];
      returnState.clinicList = nextProps.supplierList.clinics
        ? nextProps.supplierList.clinics
        : [];
      let active = 0;
      let inactive = 0;
      let list = nextProps.supplierList.data ? nextProps.supplierList.data : [];
      list.map((obj) => {
        if (obj.status == 0) {
          active = active + 1;
        } else {
          inactive = inactive + 1;
        }
      });

      returnState.activeNo = active;
      returnState.inactiveNo = inactive;

      returnState.supplierListTime = nextProps.supplierListTime;
      if (prevState.supplierList.length == 0) {
        if (nextProps.supplierList.next_page_url != null) {
          returnState.page = prevState.page + 1;
        }
        returnState.next_page_url = nextProps.supplierList.next_page_url;
        returnState.supplierList = nextProps.supplierList.data
          ? nextProps.supplierList.data
          : [];
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.render = true;
        returnState.isShowSupplierModal = false;
      } else if (
        prevState.supplierList != nextProps.supplierList.data &&
        prevState.supplierList.length != 0 &&
        nextProps.redirect == true
      ) {
        returnState.supplierList = nextProps.supplierList.data;
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.supplierList.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.isShowSupplierModal = false;
        returnState.render = false;
        // nextProps.exportEmptyData();
      } else if (
        prevState.supplierList != nextProps.supplierList.data &&
        prevState.supplierList.length != 0
      ) {
        returnState.supplierList = [
          ...prevState.supplierList,
          ...nextProps.supplierList.data,
        ];
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.supplierList.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        // nextProps.exportEmptyData();
      }
      autoScrolling(false);
      nextProps.exportEmptyData();
      return returnState;
    }
    if (
      nextProps.exportDetails != undefined &&
      nextProps.exportDetails != prevState.exportDetails
    ) {
      nextProps.exportEmptyData();
      returnState.showLoader = false;
      returnState.exportDetails = nextProps.exportDetails;
      window.open(
        process.env.REACT_APP_API_URL +
          "download-data/" +
          nextProps.exportDetails.data.file,
        "_blank",
      );
      return returnState;
    }
    return null;
  }

  supplierEdit = (id) => {
    return (
      <div>{this.props.history.push(`/inventory/suppliers/${id}/view`)}</div>
    );
  };
  handleDownload = () => {
    let download_type = "csv";
    this.setState({ showLoader: true });
    this.props.exportSupplierData(download_type);
  };

  showSupplierModal = () => {
    this.setState({ isShowSupplierModal: true });
  };
  dismissSupplierModal = () => {
    this.setState({ isShowSupplierModal: false });
  };
  createEditSupplier = (formData) => {
    this.setState({ showLoader: true });
    this.props.createEditSupplier(formData);
  };

  render() {
    return (
      <div className="main protected users-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <InventorySidebar />
            {this.state.isShowSupplierModal === true && (
              <CreateEditSupplierModal
                createEditSupplier={this.createEditSupplier}
                dismissSupplierModal={this.dismissSupplierModal}
                countryList={this.state.countryList}
                clinicList={this.state.clinicList}
              />
            )}
            {/*Clinic Head Start*/}
            <div className="memberWalletOuter business-section">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  {this.state.inventoryLang.supplier_label}
                  <form onSubmit={this.handleSubmit} className="searchForm">
                    <span className="searchOuter">
                      <i className="fa fa-search search-icon" />
                      <input
                        className="setting-search-input search-key"
                        data-url="/settings/clinics"
                        name="term"
                        placeholder={"Search by supplier name"}
                        autoComplete="off"
                        value={this.state.term}
                        onChange={this.handleInputChange}
                      />
                    </span>
                  </form>
                  <div className="memberRightActions">
                    {
                      <Link
                        to={"/inventory/suppliers/import"}
                        className="easy-link no-padding"
                      >
                        <i className="fa fa-upload"></i>
                        {this.state.inventoryLang.import_supplier}{" "}
                      </Link>
                    }
                    <a
                      className="easy-link no-padding"
                      onClick={this.handleDownload}
                    >
                      <i className="fa fa-download"></i>
                      {this.state.inventoryLang.download_cvs}
                    </a>
                    <button
                      onClick={this.showSupplierModal}
                      className="new-blue-btn pull-right"
                    >
                      {this.state.inventoryLang.add_new_supplier}
                    </button>
                  </div>
                </div>
              </div>
              {/*Clinic Head END*/}
              <div className="juvly-section full-width">
                <div className="table-responsive">
                  <table className="table-updated setting-table table-min-width no-td-border">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-2 table-updated-th">
                          {this.state.inventoryLang.inventory_supplier_name}
                        </th>
                        <th className="col-xs-2 table-updated-th">
                          {"Sold-To Account Number"}
                        </th>
                        <th className="col-xs-2 table-updated-th">
                          {this.state.inventoryLang.contact_person}
                        </th>
                        <th className="col-xs-2 table-updated-th">
                          {this.state.inventoryLang.email}
                        </th>
                        <th className="col-xs-2 table-updated-th">
                          {this.state.settingsLang.pos_phone}
                        </th>
                        <th className="col-xs-2 table-updated-th">
                          {this.state.inventoryLang.status}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      className={
                        this.state.inActive ? "no-display" : "ajax_body"
                      }
                    >
                      {this.state.supplierList &&
                        this.state.supplierList.length > 0 &&
                        this.state.supplierList.map((obj, idx) => {
                          return (
                            <tr
                              className="table-updated-tr"
                              onClick={this.supplierEdit.bind(this, obj.id)}
                              key={idx}
                            >
                              <td
                                className="table-updated-td word-break"
                                data-id={obj.id}
                              >
                                {obj.supplier_name}
                              </td>
                              <td className="table-updated-td" data-id={obj.id}>
                                {obj.account_number}
                              </td>
                              <td
                                className="table-updated-td word-break"
                                data-id={obj.id}
                              >
                                {obj.contact_person_firstname +
                                  " " +
                                  obj.contact_person_lastname}
                              </td>
                              <td
                                className="table-updated-td word-break"
                                data-id={obj.id}
                              >
                                {obj.contact_person_email}
                              </td>
                              <td className="table-updated-td" data-id={obj.id}>
                                {obj.contact_person_phone}
                              </td>
                              <td
                                className="table-updated-td "
                                data-id={obj.id}
                              >
                                {obj.status == 0 ? "Active" : "Inactive"}
                              </td>
                            </tr>
                          );
                        })}
                      {this.state.showLoader === false &&
                        (!this.state.supplierList ||
                          this.state.supplierList.length <= 0) && (
                          <tr>
                            <td
                              colSpan={6}
                              className="col-xs-12 table-updated-td text-center "
                            >
                              <div
                                className={
                                  this.state.supplierList.length
                                    ? "no-record no-display"
                                    : "no-record"
                                }
                              >
                                {this.state.clinic_No_Record_Found}
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                {this.state.showLoadingText === true && (
                  <div className={"loading-please-wait no-margin-top"}>
                    {this.state.globalLang.loading_please_wait_text}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "GET_SUPPLIER_LIST") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.supplierList = state.InventoryReducer.data.data;
      returnState.supplierListTime = new Date();
    }
  }
  if (state.SettingReducer.action === "USERS_SEARCH") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.userSearch = state.SettingReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "IN_EXPORT_FILE") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.exportDetails = state.InventoryReducer.data;
    }
  }

  if (state.InventoryReducer.action === "ADD_EDIT_SUPPLIER") {
    uiNotification.clear();
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.supplierList = state.InventoryReducer.data.data;
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.supplierListTime = new Date();
      returnState.redirect = true;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSupllierList: getSupplierList,
      exportEmptyData: exportEmptyData,
      exportSupplierData: exportSupplierData,
      createEditSupplier: createEditSupplier,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
