import React from "react";
import PropTypes from "prop-types";
import { SortableContainer } from "react-sortable-hoc";
import { SortableItem } from "../../shared/SortableItem/SortableItem";
import classes from "./SortableList.module.scss";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../shared/Empty/Empty";

export const SortableList = SortableContainer(
  ({ items, isLoading, isSaving }) => {
    return (
      <div className={classes.root}>
        {isLoading ? (
          <Skeleton
            containerClassName={classes.root}
            count={6}
            borderRadius="4px"
            height={43.42}
          />
        ) : items?.length > 0 ? (
          items.map((value, index) => (
            <SortableItem
              key={value.id}
              index={index}
              disabled={isSaving}
              isDisabled={isSaving}
            >
              {value.content}
            </SortableItem>
          ))
        ) : (
          <Empty position="center" />
        )}
      </div>
    );
  },
);

SortableList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};
