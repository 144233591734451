/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from "moment";
import React, { Component } from "react";
import BigCalendar from "react-big-calendar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from "redux";
import { getProviderScheduleById } from "../../../Actions/Appointment/appointmentAction.js";
import {
  checkIfPermissionAllowed,
  convertTime12to24,
  formatTime,
  showFormattedDate,
} from "../../../Utils/services.js";
import CalendarIcon from "../../../_legacy/images/calendar-with-event.svg";
import calenLogo from "../../../_legacy/images/calender.svg";
import TemplateIcon from "../../../_legacy/images/repeat-event.svg";
import EditProviderSchedule from "./EditProviderSchedule";
import UpdateProviderSchedule from "./UpdateProviderSchedule.js";
import OverrideScheduleModal from "./components/OverrideScheduleModal.js";
import { ProviderClinicsDropdown } from "./components/ProviderClinicsDropdown.js";
import { ProviderTemplatesDropdown } from "./components/ProviderTemplatesDropdown.js";
import EditScheduleTemplateButton from "./components/EditScheduleTemplateButton.js";
import classes from "./sass/schedule.module.scss";
import { withPermission } from "../../../hooks/usePermission.js";
import { PERMISSIONS } from "../../../consts/api.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const localizer = BigCalendar.momentLocalizer(moment);

const showSelectedWeek = (date = moment()) => {
  let showSelectedLabel = "";
  let startOfWeek = moment(date).startOf("week");
  let ednOfWeek = moment(date).endOf("week");
  if (startOfWeek.format("YYYY") != ednOfWeek.format("YYYY")) {
    showSelectedLabel =
      startOfWeek.format("MMM DD, YY - ") + ednOfWeek.format("MMM DD, YY");
  } else if (startOfWeek.format("MM") != ednOfWeek.format("MM")) {
    showSelectedLabel =
      startOfWeek.format("MMM DD - ") + ednOfWeek.format("MMM DD, YYYY");
  } else {
    showSelectedLabel =
      startOfWeek.format("MMM ") +
      startOfWeek.format("DD - ") +
      ednOfWeek.format("DD, YYYY");
  }
  return showSelectedLabel;
};

const showSelectedMonth = (date = moment()) => {
  let showSelectedLabel = "";
  let startOfWeek = moment(date).startOf("month");
  let ednOfWeek = moment(date).endOf("month");
  if (startOfWeek.format("YYYY") != ednOfWeek.format("YYYY")) {
    showSelectedLabel =
      startOfWeek.format("MMM DD, YY - ") + ednOfWeek.format("MMM DD, YY");
  } else if (startOfWeek.format("MM") != ednOfWeek.format("MM")) {
    showSelectedLabel =
      startOfWeek.format("MMM DD - ") + ednOfWeek.format("MMM DD, YYYY");
  } else {
    showSelectedLabel =
      startOfWeek.format("MMM ") +
      startOfWeek.format("DD - ") +
      ednOfWeek.format("DD, YYYY");
  }
  return showSelectedLabel;
};

const startEndDateOfWeek = (date) => {
  return {
    startDate: moment(date).startOf("week"),
    endDate: moment(date).endOf("week"),
  };
};

const startEndDateOfMonth = (date) => {
  return {
    startDate: moment(date).startOf("month"),
    endDate: moment(date).endOf("month"),
  };
};

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const viewDateFormat = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};

const initClinicSchedule = () => {
  return {
    id: "",
    name: "",
    schedules: [
      {
        from_time: "00:00",
        from_time_option: "AM",
        from_time_24: "00:00",
        to_time: "00:00",
        to_time_option: "PM",
        to_time_24: "00:00",
      },
    ],
  };
};

const initSchedule = () => {
  return {
    from_time: "00:00",
    from_time_option: "AM",
    from_time_24: "00:00",
    to_time: "00:00",
    to_time_option: "PM",
    to_time_24: "00:00",
  };
};

const initScheduleError = () => {
  return {
    from_time: "setting-input-box hours-time from-time-input",
    from_time_option: "setting-select-box hours-pm from_time_option",
    to_time: "setting-input-box hours-time to-time-input",
    to_time_option: "setting-select-box hours-pm to_time_option",
  };
};

class ProviderScheduleView extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      appointmentLang: languageData.appointments,
      providerId: 0,
      events: [],
      showSelectedDate: showSelectedMonth(),
      selectedDate: moment().toDate(),
      calendarView: "month",
      calendarStep: 5,
      calenderTimeslots: 3,
      searchPickerDate: moment().toDate(),
      isDatePickerOpen: false,
      isChangeWeek: false,
      providerScheduleData: {},
      allClinics: [],
      associatedClinics: [],
      providerScheduleList: [],
      providerInfo: [],
      selectedClinicId: 0, // 0 for all
      selectedClinics: [], // 0 for all
      isShowClinicModal: false,
      showLoader: false,
      isCreateProviderScheduleModal: false,
      editProviderScheduleModalOpen: false,
      userChanged: false,
      clinicScheduleList: [initClinicSchedule()],
      selectedPickerDate: null,
      selectedRepeatPickerDate: null,
      showDatePicker: false,
      showRepeatDatePicker: false,
      checkMode: false,
      selectedEvent: {
        id: null,
        clinic_id: null,
        clinic_name: null,
        from_time: "00:00",
        from_time_option: "AM",
        from_time_24: "00:00",
        to_time: "00:00",
        to_time_option: "AM",
        to_time_24: "00:00",
        date: null,
        isPassedDateForDelete: false, // if selected event is passed
      },
      selectedPickerDateClass: "setting-input-box p-r-40",
      selectedRepeatPickerDateClass: "setting-input-box p-r-40",
      clinicScheduleListError: [initScheduleError()],
      selectedEventError: {
        date: "setting-input-box p-r-40",
        from_time: "setting-input-box hours-time from-time-input",
        from_time_option: "setting-select-box hours-pm from_time_option",
        to_time: "setting-input-box hours-time to-time-input",
        to_time_option: "setting-select-box hours-pm to_time_option",
      },
      schedule_type: "daily",
      day: "Monday",
      errorDates: [],
      errorMessageModal: false,
      errorText: "",
      createdTimestamp: new Date(),
      available_for: "both",
      isCreateProviderScheduleModalNew: false,
      isCreateScheduleOverride: false,
      activeTemplate: null,
      editingTemplate: null,
      isNewTemplate: false,
      dayListManualOrScheduleClass: {},
      defaultClinic: null,
      defaultClinicId: null,
    };
    this.moveEvent = this.moveEvent.bind(this);
  }

  componentDidUpdate(_, state) {
    ReactTooltip.rebuild();

    if (
      this.props.createdTimestamp != undefined &&
      this.props.createdTimestamp != state.createdTimestamp
    ) {
      this.props.getProviderScheduleById(this.state.formData, state.providerId);
    }
  }

  componentDidMount() {
    const providerId = this.props.providerId;
    if (providerId) {
      this.setState({ providerId: providerId, showLoader: true });
      let startEndDateWeek = startEndDateOfMonth(moment());
      let formData = {
        params: {
          start_date: apiDateFormat(
            moment(startEndDateWeek.startDate).toDate(),
          ),
          end_date: apiDateFormat(moment(startEndDateWeek.endDate).toDate()),
        },
      };
      this.props.getProviderScheduleById(formData, providerId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (
      props.errorDates !== undefined &&
      props.errorDates !== state.errorDates
    ) {
      returnState.errorDates = props.errorDates ? props.errorDates : [];
      if (props.errorDates) {
        returnState.errorMessageModal = true;
        returnState.isCreateProviderScheduleModal = true;
        returnState.errorText = props.errorText;
      }
    }
    if (
      props.providerScheduleData !== undefined &&
      props.providerScheduleData !== state.providerScheduleData
    ) {
      returnState.providerScheduleData = props.providerScheduleData;
      returnState.providerScheduleList =
        props.providerScheduleData.provider_schedule;
      returnState.showLoader = false;

      let associatedClinics = [];
      if (!state.userChanged) {
        returnState.allClinics = props.providerScheduleData.all_clinics;
        returnState.providerInfo = props.providerScheduleData.provider_info;
        if (!state.defaultClinic?.isSelected) {
          if (props.providerScheduleData.provider_info.clinic_id) {
            returnState.defaultClinic =
              props.providerScheduleData.all_clinics.find(
                (clinicDetails) =>
                  clinicDetails.id ===
                  props.providerScheduleData.provider_info.clinic_id,
              );
            returnState.defaultClinicId =
              props.providerScheduleData.provider_info.clinic_id;
          } else {
            returnState.defaultClinic =
              props.providerScheduleData.all_clinics[0];
            returnState.defaultClinicId =
              props.providerScheduleData.all_clinics[0].id;
          }
        }
        let clinicScheduleList = [];
        let clinicScheduleListError = [];
        props.providerScheduleData.clinics_associated.map((obj) => {
          let clinic = obj;
          props.providerScheduleData.all_clinics.map((obj1) => {
            if (obj1.id == obj.id) {
              clinic["color"] = obj1.clinic_color;
            }
          });
          clinic["checked"] = false;
          associatedClinics.push(clinic);

          let clinicSchedule = {};
          clinicSchedule.id = obj.id;
          clinicSchedule.name = obj.clinic_name;
          clinicSchedule.clientName = obj.clinic_name;
          clinicSchedule.title = obj.clinic_name;
          clinicSchedule.schedules = [initSchedule()];
          clinicScheduleList.push(clinicSchedule);

          let clinicScheduleError = {};
          clinicScheduleError.id = obj.id;
          clinicScheduleError.name = obj.clinic_name;
          clinicScheduleError.schedules = [initScheduleError()];
          clinicScheduleListError.push(clinicScheduleError);
        });
        returnState.associatedClinics = associatedClinics;
        returnState.clinicScheduleList = clinicScheduleList;
        returnState.clinicScheduleListError = clinicScheduleListError;
      } else {
        associatedClinics = state.associatedClinics;
      }

      let events = [];
      let dayListManualOrScheduleClass = {};
      const allClinicsDictionary =
        props.providerScheduleData.all_clinics.reduce(
          (clinicDictionary, currentClinicDetails) => ({
            ...clinicDictionary,
            [currentClinicDetails.id]: currentClinicDetails,
          }),
          {},
        );
      props.providerScheduleData.provider_schedule.forEach((obj) => {
        const { clinic_color, clinic_name } =
          allClinicsDictionary[obj.clinic_id];
        let dateArr = obj.date_scheduled_event.split("-");
        let startTime = obj.from_time.split(":");
        let endTime = obj.to_time.split(":");
        let schedule = {};
        schedule.available_for = obj.available_for ? obj.available_for : "both";
        schedule.id = obj.id + "_" + obj.clinic_id;
        schedule.title = clinic_name || "";
        schedule.clientName = `${schedule.title}${
          obj?.template_name ? `<br/>Template : ${obj?.template_name}` : ""
        } <br>Available for: ${
          schedule.available_for == "inperson"
            ? "In-Person Only"
            : schedule.available_for == "virtual"
            ? "Virtual Only"
            : "All Appointment Types"
        }`;
        schedule.clinic_name = schedule.title;
        schedule.clinicId = obj.clinic_id;
        schedule.scheduleDate = obj.date_scheduled;
        schedule.fromTime = obj.from_time;
        schedule.toTime = obj.to_time;
        schedule.title =
          formatTime(obj.from_time) + " - " + formatTime(obj.to_time);
        schedule.clinicColor = clinic_color;
        schedule.start = new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          startTime[0],
          startTime[1],
          "00",
        );
        schedule.end = new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          endTime[0],
          endTime[1],
          "00",
        );
        schedule.color = clinic_color;
        schedule.is_provider_schedule = true;
        schedule.schedule_type = obj.schedule_type;
        events.push(schedule);

        switch (obj.schedule_type) {
          case "template":
            dayListManualOrScheduleClass[Number(dateArr[2])] =
              "template-schedule-cell";
            break;
          case "manual":
            dayListManualOrScheduleClass[Number(dateArr[2])] =
              "custom-schedule-cell";
            break;
        }
      });
      returnState.events = events;
      returnState.dayListManualOrScheduleClass = dayListManualOrScheduleClass;
    } else if (
      props.reload != undefined &&
      props.reload == true &&
      props.createdTimestamp != state.createdTimestamp
    ) {
      let startEndDateWeek = "";
      if (state.calendarView == "week") {
        startEndDateWeek = startEndDateOfWeek(state.selectedDate);
      } else if (state.calendarView == "day") {
        startEndDateWeek = {
          startDate: state.selectedDate,
          endDate: state.selectedDate,
        };
      } else {
        startEndDateWeek = startEndDateOfMonth(state.selectedDate);
      }
      let formData = {
        params: {
          start_date: apiDateFormat(startEndDateWeek.startDate),
          end_date: apiDateFormat(startEndDateWeek.endDate),
        },
      };
      if (state.selectedClinicId) {
        formData.params["clinic_id"] = state.selectedClinicId;
      }

      returnState.formData = formData;
      returnState.showLoader = true;
      returnState.isCreateProviderScheduleModal = false;
      returnState.editProviderScheduleModalOpen = false;
      returnState.createdTimestamp = props.createdTimestamp;
      //props.getProviderScheduleById(formData, state.providerId);
    } else if (props.redirect != undefined && props.redirect == true) {
      uiNotification.success(props.message);
      props.history.push("/appointment/provider-schedule");
    } else if (props.showLoader != undefined && props.showLoader == false) {
      returnState.showLoader = false;
    }
    return returnState;
  }

  onSelectEvent = (event) => {
    const { scheduleDate, clinicId, clinic_name } = event;

    const selectedDayClinicSchedules = this.state.events
      .filter(
        (scheduleDetails) =>
          scheduleDetails.clinicId === clinicId &&
          scheduleDetails.scheduleDate === scheduleDate,
      )
      .sort((a, b) => {
        const timeA = a.fromTime;
        const timeB = b.fromTime;
        return timeA.localeCompare(timeB, { numeric: true });
      });

    this.setState({
      selectedDayClinicSchedules,
      editProviderScheduleModalOpen: true,
      editScheduleModalMetaData: {
        scheduleDate: event.start,
        clinic: {
          value: clinicId,
          label: clinic_name,
        },
        isEditable: moment(event.start).isSameOrAfter(new Date(), "day"),
      },
    });
  };

  onSelectSlot = ({ start, _end, _slots, action }) => {
    if (!this.props.reschedulePermission.permitted) {
      return;
    }
    let returnState = {};
    let currentDate = moment(new Date());
    let eventOriginalDate = moment(start);
    var currentDayCheck = eventOriginalDate.diff(currentDate, "days");
    if (action == "click" && currentDayCheck >= 0) {
      let dt = moment(start).format("dddd");
      if (this.state.schedule_type == "weekly") {
        returnState.day = dt;
      }
      let clinicScheduleList = this.state.clinicScheduleList;
      let clinicScheduleListError = this.state.clinicScheduleListError;
      clinicScheduleList.map((_, idx) => {
        clinicScheduleList[idx]["schedules"] = [initSchedule()];
        clinicScheduleListError[idx]["schedules"] = [initScheduleError()];
      });
      returnState.clinicScheduleList = clinicScheduleList;
      returnState.clinicScheduleListError = clinicScheduleListError;
      returnState.isCreateScheduleOverride = true;
      returnState.selectedPickerDate = start;
      returnState.selectedRepeatPickerDate = null;
      this.setState(returnState);
    }
  };

  toggleScheduleOverrideModal = () => {
    this.setState({
      isCreateScheduleOverride: !this.state.isCreateScheduleOverride,
    });
  };

  closeProviderSchedule = () => {
    this.setState(
      {
        isCreateProviderScheduleModalNew: false,
        activeTemplate: null,
        editingTemplate: null,
        isNewTemplate: false,
        selectedPickerDate: null,
      },
      () => {
        this.applyFilter();
      },
    );
  };

  onNavigate = (newDate) => {
    this.setState({
      selectedDate: newDate,
      showSelectedDate: viewDateFormat(moment(newDate)),
    });
  };

  onView = (e) => {
    this.setState({ calendarView: e });
  };

  moveEvent({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot }) {
    const { events } = this.state;

    if (event.resourceId != resourceId) {
      return;
    }

    const idx = events.indexOf(event);
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const updatedEvent = { ...event, start, end, resourceId, allDay };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents,
    });
  }

  handleInputChange = (event) => {
    let returnState = {};
    const target = event.target;
    let value = target.value;
    const inputName = target.name;
    const scheduleIndex = event.target.dataset.scheduleindex;
    const clinicIndex = event.target.dataset.clinicindex;
    const updateschedule = target.dataset.updateschedule;
    if (clinicIndex && scheduleIndex) {
      let clinicScheduleList = this.state.clinicScheduleList;
      clinicScheduleList[clinicIndex]["schedules"][scheduleIndex][inputName] =
        value;
      clinicScheduleList[clinicIndex]["schedules"][scheduleIndex][
        "from_time_24"
      ] = convertTime12to24(
        clinicScheduleList[clinicIndex]["schedules"][scheduleIndex][
          "from_time"
        ] +
          " " +
          clinicScheduleList[clinicIndex]["schedules"][scheduleIndex][
            "from_time_option"
          ],
      );
      clinicScheduleList[clinicIndex]["schedules"][scheduleIndex][
        "to_time_24"
      ] = convertTime12to24(
        clinicScheduleList[clinicIndex]["schedules"][scheduleIndex]["to_time"] +
          " " +
          clinicScheduleList[clinicIndex]["schedules"][scheduleIndex][
            "to_time_option"
          ],
      );
      this.setState({ clinicScheduleList: clinicScheduleList });
    } else if (updateschedule) {
      let selectedEvent = this.state.selectedEvent;
      selectedEvent[
        inputName === "available_for1" ? "available_for" : inputName
      ] = value;
      selectedEvent.from_time_24 = convertTime12to24(
        selectedEvent.from_time + " " + selectedEvent.from_time_option,
      );
      selectedEvent.to_time_24 = convertTime12to24(
        selectedEvent.to_time + " " + selectedEvent.to_time_option,
      );
      this.setState({ selectedEvent: selectedEvent });
    } else {
      switch (target.type) {
        case "checkbox": {
          value = target.checked;
          const clinicId = parseInt(target.dataset.clinicid);
          let allSelectedClinics = this.state.selectedClinics;
          let returnState = {};
          if (clinicId) {
            let selectedClinicId = value ? clinicId : 0;
            let index = allSelectedClinics.indexOf(clinicId);
            if (index > -1) {
              if (!value) {
                allSelectedClinics.splice(index, 1);
              }
            } else {
              if (value) {
                allSelectedClinics.push(clinicId);
              }
            }
            if (
              this.state.associatedClinics.length == allSelectedClinics.length
            ) {
              returnState.checkMode = true;
            } else {
              returnState.checkMode = false;
            }
            returnState.selectedClinics = allSelectedClinics;
            returnState.selectedClinicId = selectedClinicId;
            this.setState(returnState);
            const associatedClinics = this.state.associatedClinics;
            associatedClinics.map((obj, idx) => {
              associatedClinics[idx]["checked"] =
                clinicId == obj.id ? value : false;
            });
            this.setState({ associatedClinics: associatedClinics });
          }
          break;
        }
        case "radio": {
          value = target.value;
          break;
        }
      }

      if (event.target.name == "schedule_type" && value == "weekly") {
        let dt = moment(this.state.selectedPickerDate).format("dddd");
        returnState.day = dt;
      }
      returnState[event.target.name] = value;
      returnState.userChanged = true;
      this.setState(returnState);
    }
  };

  handleNextPrevDate = (navigate) => {
    var selectedDate = moment(this.state.selectedDate);
    let calendarView = this.state.calendarView;
    let returnState = {};
    returnState.userChanged = true;
    switch (calendarView) {
      case "week":
        if (navigate == "next") {
          let newSelectedDate = selectedDate.add(1, "weeks").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = showSelectedWeek(newSelectedDate);
          returnState.searchPickerDate = moment(returnState.selectedDate)
            .startOf("week")
            .toDate();
          returnState.isChangeWeek = true;
        } else if (navigate == "prev") {
          let newSelectedDate = selectedDate.subtract(1, "weeks").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = showSelectedWeek(newSelectedDate);
          returnState.searchPickerDate = moment(returnState.selectedDate)
            .startOf("week")
            .toDate();
          returnState.isChangeWeek = true;
        } else {
          returnState.selectedDate = moment().toDate();
          returnState.showSelectedDate = showSelectedWeek();
          returnState.searchPickerDate = moment(
            returnState.selectedDate,
          ).toDate();
          returnState.isChangeWeek = false;
        }
        break;
      case "month":
        if (navigate == "next") {
          let newSelectedDate = selectedDate.add(1, "months").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = showSelectedMonth(newSelectedDate);
          returnState.searchPickerDate = moment(returnState.selectedDate)
            .startOf("month")
            .toDate();
          returnState.isChangeWeek = true;
        } else if (navigate == "prev") {
          let newSelectedDate = selectedDate.subtract(1, "months").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = showSelectedMonth(newSelectedDate);
          returnState.searchPickerDate = moment(returnState.selectedDate)
            .startOf("month")
            .toDate();
          returnState.isChangeWeek = true;
        } else {
          returnState.selectedDate = moment().toDate();
          returnState.showSelectedDate = showSelectedMonth();
          returnState.searchPickerDate = moment(
            returnState.selectedDate,
          ).toDate();
          returnState.isChangeWeek = false;
        }
        break;
      case "day":
        if (navigate == "next") {
          let newSelectedDate = selectedDate.add(1, "days").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = viewDateFormat(
            moment(newSelectedDate),
          );
        } else if (navigate == "prev") {
          let newSelectedDate = selectedDate.subtract(1, "days").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = viewDateFormat(
            moment(newSelectedDate),
          );
        } else {
          returnState.selectedDate = moment().toDate();
          returnState.showSelectedDate = viewDateFormat(moment());
        }
        returnState.searchPickerDate = moment(
          returnState.selectedDate,
        ).toDate();
        break;
    }
    this.setState(returnState);
    this.getProviderScheduleById(
      this.state.calendarView,
      returnState.selectedDate,
      this.state.selectedClinics,
    );
  };

  handleCalendarView = (nextCalendarView) => {
    let returnState = {};
    returnState.calendarView = nextCalendarView;
    returnState.userChanged = true;
    returnState.selectedDate = this.state.selectedDate;
    if (nextCalendarView == "week") {
      returnState.showSelectedDate = showSelectedWeek(returnState.selectedDate);
    } else if (nextCalendarView == "day") {
      returnState.isChangeWeek = false;
      if (this.state.returnState) {
        returnState.selectedDate = moment(returnState.selectedDate)
          .startOf("week")
          .toDate();
      }
      returnState.showSelectedDate = viewDateFormat(returnState.selectedDate);
    } else {
      returnState.showSelectedDate = showSelectedMonth(
        returnState.selectedDate,
      );
    }
    returnState.searchPickerDate = moment(returnState.selectedDate).toDate();
    this.setState(returnState);
    this.getProviderScheduleById(
      returnState.calendarView,
      returnState.selectedDate,
      this.state.selectedClinics,
    );
  };

  getProviderScheduleById = (calendarView, selectedDate, selectedClinicId) => {
    if (this.state.providerId) {
      let startEndDateWeek = "";
      if (calendarView == "week") {
        startEndDateWeek = startEndDateOfWeek(selectedDate);
      } else if (calendarView == "day") {
        startEndDateWeek = { startDate: selectedDate, endDate: selectedDate };
      } else {
        startEndDateWeek = startEndDateOfMonth(selectedDate);
      }
      let formData = {
        params: {
          start_date: apiDateFormat(startEndDateWeek.startDate),
          end_date: apiDateFormat(startEndDateWeek.endDate),
        },
      };
      if (selectedClinicId) {
        formData.params["clinic_ids"] = selectedClinicId;
      }
      if (this.state.activeTemplate) {
        formData.params["template_id"] = this.state.activeTemplate.id;
      }
      this.setState({
        showLoader: true,
        isCreateProviderScheduleModal: false,
        editProviderScheduleModalOpen: false,
      });
      this.props.getProviderScheduleById(formData, this.state.providerId);
    }
  };

  handleSearchDatePicker = (date) => {
    this.setState({ searchPickerDate: date, isDatePickerOpen: false });
    this.setState({ showLoader: true });
    let returnState = {};
    returnState.isChangeWeek = false;
    returnState.selectedDate = date;
    returnState.userChanged = true;
    returnState.showSelectedDate = viewDateFormat(returnState.selectedDate);
    returnState.calendarView = "day";
    this.setState(returnState);
    this.getProviderScheduleById(
      returnState.calendarView,
      returnState.selectedDate,
      this.state.selectedClinicId,
    );
  };

  handleShowClinicModal = (event) => {
    event.stopPropagation();
    if (
      event.target.className != "line-btn pull-right" &&
      event.target.className != "provider_clinic_id"
    ) {
      this.setState({ isShowClinicModal: !this.state.isShowClinicModal });
    }
  };

  handleGetProviderScheduleModal = () => {
    this.setState({
      editProviderScheduleModalOpen: !this.state.editProviderScheduleModalOpen,
    });
  };

  toggleDatePicker = () => {
    this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
  };

  handleErrorModal = () => {
    this.setState({ errorMessageModal: false });
  };

  toggleAllClinics = (event) => {
    let allClinics = this.state.associatedClinics;
    let returnState = {};

    let x = event.target.dataset.checkmode == "true" ? false : true;
    let selectedClinics = [];
    returnState.checkMode = x;
    allClinics.map((obj) => {
      returnState["provider_clinic_id-" + obj.id] = x;
      selectedClinics.push(obj.id);
    });
    returnState.selectedClinics = x ? selectedClinics : [];
    returnState.isShowClinicModal = true;
    this.setState(returnState);
  };

  applyFilter = () => {
    this.getProviderScheduleById(
      this.state.calendarView,
      this.state.selectedDate,
      this.state.selectedClinics,
    );
  };

  updateSelectedClinics = (selectedClinics) => {
    if (selectedClinics.length === 1) {
      const defaultClinic = this.state.associatedClinics.find(
        ({ id }) => id === selectedClinics[0],
      );
      this.setState({
        defaultClinic: {
          ...defaultClinic,
          label: defaultClinic.clinic_name,
          value: defaultClinic.id,
          isSelected: true,
        },
      });
    } else {
      const defaultClinic = this.state.associatedClinics.find(
        ({ id }) => id === this.state.defaultClinicId,
      );
      this.setState({
        defaultClinic: {
          ...defaultClinic,
          label: defaultClinic.clinic_name,
          value: defaultClinic.id,
          isSelected: false,
        },
      });
    }
    this.setState({ selectedClinics }, () => {
      this.applyFilter();
      this.props.updateSelectedClinicsIds(selectedClinics);
    });
  };

  openDeleteSchedule = () => {
    this.props.openDeleteSchedule(this.state.providerId);
  };

  setSchedulingErrors = (schedulingErrors) => {
    this.setState({
      errorDates: schedulingErrors.data,
      errorText:
        this.state.globalLang[schedulingErrors.message] ||
        schedulingErrors.message,
      errorMessageModal: true,
      modalTitle: schedulingErrors.modalTitle || "Time Conflict",
    });
  };

  selectTemplate = (activeTemplate) => {
    this.setState(
      {
        activeTemplate,
        isNewTemplate: false,
      },
      () => this.applyFilter(),
    );
  };

  editTemplate = (editingTemplate) => {
    this.setState({
      editingTemplate,
      isNewTemplate: false,
      isCreateProviderScheduleModalNew: true,
    });
  };

  addNewTemplate = () => {
    this.setState({
      isNewTemplate: true,
      isCreateProviderScheduleModalNew: true,
      activeTemplate: null,
      editingTemplate: null,
    });
  };

  render() {
    document.body.style.overflow = "hidden !important";

    return (
      <div>
        <div className="row toolbar-container">
          <div className="col-lg-3 col-md-5 col-sm-6 cal-month-week-day-outer provider-m-d-w">
            <button
              className={
                this.state.calendarView == "month"
                  ? "calendar-btn btn-week selected"
                  : "calendar-btn btn-week"
              }
              onClick={this.handleCalendarView.bind(this, "month")}
            >
              Month
            </button>
            <button
              className={
                this.state.calendarView == "week"
                  ? "calendar-btn btn-week selected"
                  : "calendar-btn btn-week"
              }
              onClick={this.handleCalendarView.bind(this, "week")}
            >
              {this.state.globalLang.label_week}
            </button>
            <button
              className={
                this.state.calendarView == "day"
                  ? "calendar-btn btn-day selected"
                  : "calendar-btn btn-day"
              }
              onClick={this.handleCalendarView.bind(this, "day")}
            >
              {this.state.globalLang.label_day}
            </button>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-6 cal-date-btn-outer">
            <button
              className="calendar-btn today-btn"
              onClick={this.handleNextPrevDate.bind(this, "today")}
            >
              {this.state.globalLang.label_today}
            </button>
            <button className="calendar-btn cal-date-btn">
              <a
                className="cal-arrow pull-left"
                onClick={this.handleNextPrevDate.bind(this, "prev")}
              >
                <img alt="" src="/images/cal-left.svg" />
              </a>
              {this.state.showSelectedDate}
              <a
                className="cal-arrow pull-right"
                onClick={this.handleNextPrevDate.bind(this, "next")}
              >
                <img alt="" src="/images/cal-right.svg" />
              </a>
            </button>
            <div
              className="search-bg new-calender"
              onClick={() => {
                if (!this.state.isDatePickerOpen) {
                  this.toggleDatePicker();
                }
              }}
            >
              <img alt="" src={calenLogo} />
              <DatePicker
                value={
                  this.state.searchPickerDate
                    ? viewDateFormat(this.state.searchPickerDate)
                    : null
                }
                onChange={this.handleSearchDatePicker}
                dateFormat="YYYY-MM-dd"
                minDate={new Date(moment().subtract(10, "years"))}
                selected={this.state.searchPickerDate}
                name="searchPickerDate"
                autoComplete="off"
                open={this.state.isDatePickerOpen}
                onClickOutside={this.toggleDatePicker}
              />
            </div>
          </div>
          <div className="col-lg-5  cal-filter provider-cal-filter">
            <ProviderClinicsDropdown
              clinicsAssociated={this.state.associatedClinics}
              updateSelectedClinics={this.updateSelectedClinics}
              selectedClinics={this.state.selectedClinics}
            />

            <ProviderTemplatesDropdown
              providerId={this.state.providerId}
              selectTemplate={this.selectTemplate}
              activeTemplate={this.state.activeTemplate}
              addNewTemplate={this.addNewTemplate}
              selectedClinics={this.state.selectedClinics}
            />

            <EditScheduleTemplateButton
              providerId={this.state.providerId}
              refetchSchedule={this.applyFilter}
              editTemplate={this.editTemplate}
              selectedClinics={this.state.selectedClinics}
            />

            {checkIfPermissionAllowed("manage-provider-schedules") === true && (
              <a className="new-blue-btn" onClick={this.openDeleteSchedule}>
                Delete&nbsp;Date&nbsp;Overrides
              </a>
            )}
          </div>
        </div>

        <div className={classes.legendContainer}>
          <div className={classes.scheduleLegend}>
            <div className={classes.dateOverride}>
              <img src={CalendarIcon} alt="blue calendar icon" />
              :&nbsp;<span>Date Override</span>
            </div>
            <div className={classes.regularSchedule}>
              <img
                src={TemplateIcon}
                alt="blue calendar icon"
                className="mr-2"
              />
              :&nbsp;<span>Regular Schedules</span>
            </div>
          </div>
        </div>

        <div
          className="calendar-provider-schedule juvly-section full-width m-t-10"
          id="juvly-section"
        >
          <BigCalendar
            selectable={"ignoreEvents"}
            localizer={localizer}
            views={["week", "day", "month"]}
            events={this.state.events}
            onEventDrop={this.moveEvent}
            defaultView={"month"}
            view={this.state.calendarView}
            defaultDate={new Date(2019, 0, 29)}
            showMultiDayTimes
            toolbar={false}
            onSelectSlot={this.onSelectSlot}
            onSelectEvent={this.onSelectEvent}
            date={this.state.selectedDate}
            onNavigate={this.onNavigate}
            onView={this.onView}
            dayPropGetter={(date) => {
              const cellDate = new Date(date).getDate();
              return {
                className:
                  this.state.dayListManualOrScheduleClass[cellDate] || "",
              };
            }}
          />
        </div>

        {/* Create Provider Schedule Modal - START */}
        {this.state.isCreateProviderScheduleModalNew && (
          <>
            <UpdateProviderSchedule
              providerId={this.state.providerId}
              refetchSchedule={this.applyFilter}
              onClose={this.closeProviderSchedule}
              selectedPickerDate={this.state.selectedPickerDate}
              setSchedulingErrors={this.setSchedulingErrors}
              clinicsAssociated={this.state.associatedClinics}
              activeTemplate={
                this.state.editingTemplate || this.state.activeTemplate
              }
              isNewTemplate={this.state.isNewTemplate}
              defaultClinic={this.state.defaultClinic}
            />
          </>
        )}
        {/* Create Provider Schedule Modal - END */}
        {/* Get Provider Schedule Modal - START */}
        {this.state.editProviderScheduleModalOpen && (
          <EditProviderSchedule
            refetchSchedule={this.applyFilter}
            selectedDayClinicSchedules={this.state.selectedDayClinicSchedules}
            onClose={this.handleGetProviderScheduleModal}
            providerId={this.state.providerId}
            setSchedulingErrors={this.setSchedulingErrors}
            editScheduleModalMetaData={this.state.editScheduleModalMetaData}
          />
        )}

        {/* Get Provider Schedule Modal - END */}

        {/* Schedule Override */}
        {this.state.isCreateScheduleOverride && (
          <OverrideScheduleModal
            isOpen
            onClose={this.toggleScheduleOverrideModal}
            clinicsAssociated={this.state.associatedClinics}
            providerId={this.state.providerId}
            selectedPickerDate={this.state.selectedPickerDate}
            refetchSchedule={this.applyFilter}
            defaultClinic={this.state.defaultClinic}
          />
        )}

        <div className={this.state.errorMessageModal ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.errorMessageModal
              ? "modal fade in displayBlock"
              : "modal fade no-display"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content time-conflict-modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.handleErrorModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {this.state.modalTitle}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                <p className="p-text conflict-date">{this.state.errorText}</p>
                <p className="p-text">
                  {this.state.errorDates &&
                    this.state.errorDates.length > 0 &&
                    this.state.errorDates.map((obj, idx) => {
                      return (
                        <React.Fragment key={"asd-" + idx}>
                          <span>{showFormattedDate(obj, false)}</span> <br />
                        </React.Fragment>
                      );
                    })}
                </p>
              </div>
              <div className="modal-footer conflict-date-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.handleErrorModal}
                  >
                    {"Ok"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock full-fixed-loader"
              : "new-loader text-left full-fixed-loader"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.appointmentLang.appointment_processing_please_wait}
            </div>
          </div>
        </div>
        <ReactTooltip effect="float" multiline={true} place="right" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  if (state.AppointmentReducer.action === "PROVIDER_SCHEDULE_DATA") {
    if (state.AppointmentReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.providerScheduleData = state.AppointmentReducer.data.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProviderScheduleById: getProviderScheduleById,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  DragDropContext(HTML5Backend)(
    withPermission(
      PERMISSIONS.appointments.manageProvidersSchedules,
      "reschedulePermission",
    )(ProviderScheduleView),
  ),
);
