import React from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Table as SharedTable } from "../../../../shared/Table/Table";
import styles from "./styles.module.scss";

export const Table = ({ data }) => {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={styles.root}>
      <SharedTable
        bgColor="white"
        data={[
          {
            name: data.name,
            purchaseDate: data.purchaseDate,
            recipient: (
              <span dangerouslySetInnerHTML={{ __html: data.recipient }} />
            ),
            expireDate: data.expireDate,
            balance: data.balance,
          },
        ]}
        cols={[
          {
            data: tCommon("modalEditGiftCardExpiryDate.table.name"),
            accessor: "name",
            headColClassName: styles.col,
          },
          {
            data: tCommon("modalEditGiftCardExpiryDate.table.purchaseDate"),
            accessor: "purchaseDate",
            headColClassName: styles.col,
          },
          {
            data: tCommon("modalEditGiftCardExpiryDate.table.recipient"),
            accessor: "recipient",
            headColClassName: styles.col,
          },
          {
            data: tCommon("modalEditGiftCardExpiryDate.table.expireDate"),
            accessor: "expireDate",
            headColClassName: styles.col,
          },
          {
            data: tCommon("modalEditGiftCardExpiryDate.table.balance"),
            accessor: "balance",
            headColClassName: styles.col,
          },
        ]}
      />
    </div>
  );
};
