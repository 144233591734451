import { useState } from "react";
import { useValidateRedemptionCodeMutation } from "../../../../api/mutations/useValidateRedemptionCodeMutation";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { extractApiError } from "../../../../utilities/api";
import { useApplyGiftCardsToInvoiceMutation } from "../../../../api/mutations/useApplyGiftCardsToInvoiceMutation";
import { useInvoice } from "./invoice/useInvoice";
import { prepareAmount } from "../SalesCheckoutInvoice.utils";
import { useRemoveInvoiceGiftCardsMutation } from "../../../../api/mutations/useRemoveInvoiceGiftCardsMutation";
import { prepareRedemptionCode } from "../../../../api/queries/useInvoiceQuery";
import { dispatch } from "../../../../store/store";
import { checkoutInvoice } from "../../../../store/checkoutInvoice";

export function useGiftCardActions() {
  const { tSales } = useAppTranslation.Sales();
  const { refetchInvoice, invoice } = useInvoice();
  const [removingId, setRemovingId] = useState(null);

  const { mutateAsync: validate, isLoading: isValidating } =
    useValidateRedemptionCodeMutation({
      onError: (e) => {
        const errorKey = extractApiError(e);
        uiNotification.error(
          tSales([
            `checkoutInvoice.apiError.${errorKey}`,
            "checkoutInvoice.error.validateRedemptionCode",
          ]),
        );
      },
    });

  const { mutateAsync: applyGift, isLoading: isGiftApplying } =
    useApplyGiftCardsToInvoiceMutation({
      onSuccess: refetchInvoice,
      onError: () => {
        uiNotification.error(tSales("checkoutInvoice.error.applyGiftCard"));
      },
    });

  const { mutateAsync: removeAllGiftCards, isLoading: isAllGiftCardsRemoving } =
    useRemoveInvoiceGiftCardsMutation({
      onSuccess: refetchInvoice,
      onError: () => {
        uiNotification.error(tSales("checkoutInvoice.error.giftCardAction"));
      },
    });

  const invoiceRedeemedCardToApplyGiftCard = (redeemedCard) => ({
    id: redeemedCard.id,
    availableAmount: redeemedCard.availableAmount,
    redeemAmount: redeemedCard.redeemedAmount,
    redemptionCode: redeemedCard.redemptionCode,
  });

  const applyGiftCard = async ({
    redeemAmount,
    giftCardId,
    redemptionCode,
    giftCardAvailableAmount,
  }) => {
    return applyGift({
      invoiceId: invoice?.id,
      giftCards: [
        {
          id: giftCardId,
          availableAmount: giftCardAvailableAmount,
          redeemAmount: prepareAmount(redeemAmount),
          redemptionCode,
        },
      ],
    }).then(() => dispatch(checkoutInvoice.actions.paymentViewClose()));
  };

  const removeGiftCardById = async (id) => {
    const redeemedCards = invoice?.redeemedGiftCards || [];
    setRemovingId(id);
    if (redeemedCards.length === 1 && id === redeemedCards[0].id) {
      await removeAllGiftCards({ invoiceId: invoice?.id });
    } else if (redeemedCards.length > 1) {
      await applyGift({
        invoiceId: invoice?.id,
        giftCards: redeemedCards
          .filter((c) => c.id !== id)
          .map(invoiceRedeemedCardToApplyGiftCard),
      });
    }
    setRemovingId(id);
  };

  return {
    validateGiftCard: {
      isLoading: isValidating,
      initiate: ({ redemptionCode }) =>
        validate({ redemptionCode: prepareRedemptionCode(redemptionCode) }),
    },
    applyGiftCard: {
      isLoading: isGiftApplying,
      initiate: applyGiftCard,
    },
    removeGiftCard: {
      isLoading: isGiftApplying || isAllGiftCardsRemoving,
      initiate: removeGiftCardById,
      removingId,
    },
  };
}
