import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Checkbox.module.scss";
import { svg } from "../../assets/svg";

const SIZES = {
  small: "small",
};

export function Checkbox({
  name,
  label,
  isChecked,
  onChange,
  isDisabled,
  className,
  labelClassName,
  checkMarkClassName,
  size,
  isIntermediate,
  inverted,
  testId,
}) {
  return (
    <label
      className={cx(
        classes.root,
        {
          [classes.disabled]: isDisabled,
          [classes.rootNoLabel]: !label,
          [classes.rootSmall]: size === SIZES.small,
        },
        className,
      )}
    >
      {label && (
        <span className={cx(classes.label, labelClassName)}>{label}</span>
      )}
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
        data-testid={testId}
      />
      <span
        className={cx(
          classes.checkMark,
          {
            [classes.invertedCheckMark]: inverted,
          },
          checkMarkClassName,
        )}
      >
        {isChecked && !isIntermediate && <img src={svg.checkPrimary} alt="" />}
        {isChecked && isIntermediate && (
          <span className={classes.intermediateIcon} />
        )}
      </span>
    </label>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  checkMarkClassName: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  isIntermediate: PropTypes.bool,
  inverted: PropTypes.bool,
  testId: PropTypes.string,
};

Checkbox.defaultProps = {
  name: undefined,
  label: undefined,
  isDisabled: false,
  className: undefined,
  labelClassName: undefined,
  checkMarkClassName: undefined,
  size: undefined,
  isIntermediate: false,
  inverted: false,
};
