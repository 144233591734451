import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { passOr, unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  patientId: yup.number().nullable(),
  cherryPatientId: yup.string().nullable(),
});

const responseSchema = yup.object({
  id: yup.string().required(),
  canPay: yup.bool().required(),
  creationProcessing: yup.bool().required(),
  isActive: yup.bool().required(),
  phone: yup.string().nullable(),
  refreshingProcessing: yup.bool().required(),
  requiresRefresh: yup.bool().required(),
  balance: yup
    .object({
      amount: yup.number().required(),
      expireAt: yup.string().nullable(),
    })
    .nullable(),
});

const composeResponse = (res) => {
  const details = unwrapOr(() => res.data.data.cherryPatient, {});
  return {
    id: details.id,
    canPay: Boolean(details.canPay),
    creationProcessing: Boolean(details.creationProcessing),
    isActive: Boolean(details.isActive),
    phone: details.phone || null,
    refreshingProcessing: Boolean(details.refreshingProcessing),
    requiresRefresh: Boolean(details.requiresRefresh),
    balance: passOr(details?.balance, null, () => ({
      amount: details.balance.amount,
      expireAt: details.balance.expireAt,
    })),
  };
};

function createCherryPatientProfileQueryKey({ patientId, cherryPatientId }) {
  return [QUERY_KEYS.cherry.patientProfile, patientId, cherryPatientId];
}

export function useCherryPatientProfileQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createCherryPatientProfileQueryKey({
      patientId: req.patientId,
      cherryPatientId: req.cherryPatientId,
    }),
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.cherry.getPatientProfile({
          patientId: req.patientId,
          cherryPatientId: req.cherryPatientId,
        }),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
