import * as yup from "yup";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { createPasswordSchema } from "../../../schemas/auth";
import { useExtendedFormik } from "../../../hooks/useExtendedFormik";
import {
  PASS_CODE_LENGTH,
  createPasscodeSchema,
} from "../../../schemas/passcode";
import { OTP_LENGTH, createOtpSchema } from "../../../schemas/otp";

export function useForm({ submitter, requirePasscode }) {
  var { tCommon: t } = useAppTranslation.Common();
  var { tAuth } = useAppTranslation.Auth();

  var schema = yup.object({
    oldPassword: yup
      .string()
      .required(t("changePassword.error.oldPasswordRequired")),

    newPassword: createPasswordSchema({
      minError: tAuth("password.error.min"),
      noSpacesError: tAuth("password.error.noSpaces"),
      specialCharError: tAuth("password.error.specialChar"),
      lowerLetterError: tAuth("password.error.lowerLetter"),
      capitalLetterError: tAuth("password.error.capitalLetter"),
    })
      .notOneOf(
        [yup.ref("oldPassword")],
        t("changePassword.error.passwordMatchOld"),
      )
      .required(t("changePassword.error.newPasswordRequired")),

    newPasswordConfirm: yup
      .string()
      .oneOf(
        [yup.ref("newPassword")],
        t("changePassword.error.passwordsNotMatch"),
      )
      .required(t("changePassword.error.confirmPasswordRequired")),

    otp: createOtpSchema({
      invalidError: t("changePassword.error.otpLength", {
        length: OTP_LENGTH,
      }),
    }).required(t("changePassword.error.otpRequired")),

    passCode: yup.lazy(() => {
      if (requirePasscode) {
        return createPasscodeSchema({
          invalidError: t("changePassword.error.passCodeLength", {
            length: PASS_CODE_LENGTH,
          }),
        }).required(t("changePassword.error.passCodeRequired"));
      }
      return yup.string().nullable();
    }),
  });

  var { values, handleChange, handleBlur, handleSubmit, getError } =
    useExtendedFormik({
      onSubmit: submitter,
      enableReinitialize: true,
      validationSchema: schema,
      initialValues: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        otp: "",
        passCode: "",
      },
    });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    getError,
  };
}
