import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
  patientId: yup.number().required(),
});

export function useDeleteInvoiceMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postDeleteInvoice(), {
      invoice_id: req.invoiceId,
      patient_id: req.patientId,
    });
  }, options);
}
