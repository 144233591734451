import { useEffect } from "react";
import { PERMISSIONS } from "../../../../consts/api";
import { usePermission } from "../../../../hooks/usePermission";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { history } from "../../../../history";

export const usePageGuard = () => {
  const { tCommon } = useAppTranslation.Common();

  const permission = usePermission(
    PERMISSIONS.patientManagement.editGiftCardBalances,
  );

  useEffect(() => {
    if (permission.loaded && !permission.permitted) {
      uiNotification.error(tCommon("label.permissionDenied"));
      history.goBack();
    }
  }, [permission.loaded, permission.permitted]);
};
