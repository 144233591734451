import React from "react";

const CheckBox = (props) => {
  const {
    name,
    className = "new-check",
    register,
    isRequired = false,
    id = "",
  } = props;
  return (
    <React.Fragment>
      <input
        type="checkbox"
        className={className}
        name={name}
        id={id ? id : `chk-${name}`}
        ref={register({
          required: isRequired,
        })}
      />
    </React.Fragment>
  );
};

export default CheckBox;
