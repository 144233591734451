import React from "react";
import { useQuestionnaire } from "./hooks/useQuestionnaire";
import { Question } from "./boxes/Question/Question";

/**
 * @template {ReturnType<usePatientQuestionnaire>["data"]} T
 * @param {{
 *    questionnaire: T;
 *    onSuccess: () => void;
 * }} param0
 */
export function Multi({ questionnaire, onSuccess }) {
  const {
    currentQuestion,
    currentAnswer,
    navigator,
    updateAnswer,
    onNext,
    onFinishLater,
    isSubmitting,
    questionnaireStatus,
  } = useQuestionnaire({
    onSuccess,
    patientQuestionnaire: questionnaire,
  });

  return (
    <>
      {currentQuestion ? (
        <Question
          key={currentQuestion.id}
          question={currentQuestion}
          answer={currentAnswer}
          updateAnswer={updateAnswer}
          onNext={onNext}
          onPrev={navigator.goBackward}
          onFinishLater={onFinishLater}
          canGoNext={navigator.canGoForward}
          canGoPrev={navigator.canGoBackward}
          isSubmitting={isSubmitting}
          isQuestionnaireFilled={questionnaireStatus === "filled"}
        />
      ) : null}
    </>
  );
}
