import React, { Component } from "react";

class Taxes extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.reportData !== undefined) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    return (
      <div className="table-responsive clients-table">
        <table className="table-updated setting-table table-min-width no-td-border  no-hover">
          <thead className="table-updated-thead">
            {
              <tr>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.clinic_name}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.gross_sales}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.net_sales}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.taxable_sales}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.total_tax_collected}
                </th>
              </tr>
            }
          </thead>
          <tbody className="ajax_body">
            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length > 0 &&
              this.state.reportData.report_data.map((obj, idx) => {
                return (
                  <tr key={idx} className="table-updated-tr">
                    <td className="table-updated-td">{obj.clinic_name}</td>
                    <td className="table-updated-td">{obj.gross_sales}</td>
                    <td className="table-updated-td">{obj.net_sales}</td>
                    <td className="table-updated-td">{obj.taxable_sales}</td>
                    <td className="table-updated-td">
                      {obj.total_tax_collected}
                    </td>
                  </tr>
                );
              })}

            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length > 0 &&
              this.state.reportData.total && (
                <tr className="table-updated-tr">
                  <td className="table-updated-td bold">Total</td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.gross_sales}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.net_sales}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.taxable_sales}
                  </td>
                  <td className="table-updated-td bold">
                    {this.state.reportData.total.total_tax_collected}
                  </td>
                </tr>
              )}

            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length === 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan="10"
                  >
                    {this.state.languageData.bi_no_record_found}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Taxes;
