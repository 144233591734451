import { useServiceCategoriesQuery } from "../../../../../../api/queries/useServiceCategoriesQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useCategories() {
  const { tCommon } = useAppTranslation.Common();

  const categoriesQuery = useServiceCategoriesQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchServiceCategories"));
    },
  });

  const categoriesList = categoriesQuery?.data?.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  return {
    isCategoriesLoading: categoriesQuery.isLoading,
    categoriesList,
  };
}
