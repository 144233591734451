import React from "react";

const TimerIcon = ({ color, width, height }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 300.000000 343.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,343.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M1024 3331 c-2 -2 -4 -71 -4 -153 l0 -148 465 0 465 0 0 150 0 150 -312 0 c-172 0 -380 2 -461 3 -82 1 -151 0 -153 -2z" />
        <path d="M1250 2846 c-569 -101 -1004 -513 -1138 -1080 -23 -99 -26 -131 -27 -301 0 -163 3 -205 23 -294 123 -549 523 -951 1077 -1082 88 -21 124 -24 295 -24 208 1 266 9 432 61 351 111 660 376 826 709 166 332 193 719 76 1071 -35 106 -103 240 -175 348 l-60 89 111 112 112 113 -101 101 c-55 55 -107 101 -113 101 -7 0 -60 -48 -119 -106 l-106 -106 -84 58 c-144 98 -333 180 -509 219 -100 23 -415 29 -520 11z m500 -316 c413 -108 722 -431 811 -844 27 -125 26 -329 -1 -454 -49 -224 -147 -399 -317 -563 -298 -288 -718 -381 -1118 -248 -561 187 -873 803 -693 1369 127 397 457 685 873 760 91 16 350 5 445 -20z" />
        <path d="M1330 1775 l0 -465 150 0 150 0 0 465 0 465 -150 0 -150 0 0 -465z" />
      </g>
    </svg>
  );
};

export default TimerIcon;
