import React from "react";
import ReactTooltip from "react-tooltip";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";
import { InfoIcon } from "../../../../../../assets/Icons/InfoIcon";
import styles from "../scss/viewTipsModal.module.scss";
import { useAvailablePaymentMethods } from "../../../hooks/useAvailablePaymentMethods";
import { useInvoice } from "../../../hooks/invoice/useInvoice";

const NoTipsInfo = ({ method }) => {
  const { invoice } = useInvoice();
  const { data: infoMessage } = useAvailablePaymentMethods(invoice.id, {
    select: (data) => data[method].warning,
  });

  return (
    <>
      <AlertBox className={styles.infoBox}>
        <span data-tip data-for="tipInfoIcon" className={styles.infoIcon}>
          <InfoIcon width="17px" />
        </span>
        <span>{infoMessage.message}</span>
      </AlertBox>
      <ReactTooltip id="tipInfoIcon" place="top" border>
        <span>{infoMessage.details}</span>
      </ReactTooltip>
    </>
  );
};

export default NoTipsInfo;
