import React from "react";
import drilldown from "highcharts-drilldown";
import Highcharts from "highcharts";
import moment from "moment";

const This_Year = moment().format("YYYY");
const Last_Year = moment().subtract(1, "year").format("YYYY");
const Current_Month = moment().format("MMMM");
drilldown(Highcharts);

class PreviousVsCurrentYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: this.props.graphData ? this.props.graphData : {},
      reportDataValues: [],
    };
  }
  componentDidMount() {
    this.renderHighChart();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.graphData && nextProps.graphData !== prevState.graphData) {
      returnState.graphData = nextProps.graphData;
      if (prevState.highcharts && prevState.highcharts.series != undefined) {
        let highcharts = prevState.highcharts;
        highcharts.update({
          series: [
            {
              name:
                This_Year +
                " " +
                nextProps.graphData.month_name +
                ", " +
                nextProps.graphData.this_year +
                (nextProps.graphData.percentage_change > 0
                  ? " (" + nextProps.graphData.percentage_change + "%)"
                  : ""),
              data: [parseFloat(nextProps.graphData.this_year)],
            },
            {
              name:
                Last_Year +
                " " +
                nextProps.graphData.month_name +
                ", " +
                nextProps.graphData.last_year,
              data: [parseFloat(nextProps.graphData.last_year)],
            },
          ],
          xAxis: {
            categories: [nextProps.graphData.month_name],
            crosshair: true,
          },
          yAxis: {
            min: 0,
            title: {
              text: nextProps.graphData.product_name,
            },
          },
        });
      }
    }
    return returnState;
  }

  renderHighChart = () => {
    let highcharts = Highcharts.chart("previousVsCurrentYearContainer", {
      chart: {
        type: "column",
        height: 350,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      legend: {
        layout: "horizontal",
        align: "right",
        verticalAlign: "top",
        x: -20,
        borderWidth: 1,
        itemMarginBottom: 10,
        itemMarginTop: 10,
        symbolRadius: 3,
        squareSymbol: false,
        symbolHeight: 16,
        symbolWidth: 16,
        borderColor: "#FFFFFF",
        backgroundColor: "#FFFFFF",
        itemStyle: {
          fontSize: "16px",
          fontWeight: "normal",
        },
      },
      xAxis: {
        categories: [Current_Month],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Unknown",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}</td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.25,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: This_Year + " " + Current_Month,
          data: [0],
        },
        {
          name: Last_Year + " " + Current_Month,
          data: [0],
        },
      ],
    });

    this.setState({ highcharts: highcharts });
    return highcharts;
  };

  render() {
    return (
      <div
        className="business-insight-graph previous-vs-current-year-graph"
        id="previousVsCurrentYearGraph"
        data-highcharts-chart="0"
      >
        <div
          className="business-insight-graph-container previous-vs-current-year-container"
          id="previousVsCurrentYearContainer"
        />
      </div>
    );
  }
}

export default PreviousVsCurrentYear;
