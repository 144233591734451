const initialState = {
  suggestions: { status: "pending", data: [], hasData: false },
};

const CountrySuggestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SUGGESTIONS_LOADING": {
      return {
        ...state,
        suggestions: { status: "loading", data: [], hasData: false },
      };
    }
    case "FETCH_SUGGESTIONS_SUCCESS": {
      return {
        ...state,
        suggestions: {
          status: "success",
          data: action.payload,
          hasData: !!action.payload.length,
        },
      };
    }
    case "FETCH_SUGGESTIONS_ERROR": {
      return {
        ...state,
        suggestions: { status: "error", data: [], hasData: false },
      };
    }
    case "RESET_SUGGESTIONS_LOADING": {
      return {
        ...state,
        suggestions: { status: "pending", data: [], hasData: false },
      };
    }
    default:
      return state;
  }
};

export default CountrySuggestionsReducer;
