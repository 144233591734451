import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useExpiredAgreementData = (
  { membershipId, objectId },
  options = {},
) => {
  return useQuery(
    ["expiredAgreementData"],
    () => {
      return http.get(
        HTTP_ENDPOINTS.getExpiredAgreementData(membershipId, objectId),
      );
    },
    { ...options, enabled: false },
  );
};
