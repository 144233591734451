import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import Loader from "../../../../../../../Components/Common/Loader";
import { getClientCardDataStripe } from "../../../../../../../Actions/Stripe/StripeActions";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import PaymentFormModal from "../components/CardOnFile/PaymentFormModal";
import PaymentTipsModal from "../components/CardOnFile/PaymentTipsModal";

const FormCardOnFile = ({ cardList, isTipsApplying }) => {
  const { showTipsModal } = usePaymentFlow();

  const [showLoader, setShowLoader] = useState(false);

  const cardOptions = useMemo(() => {
    return (
      cardList?.length &&
      cardList.map((c) => {
        const last4 = `ending with ${c.card.last4}`;
        return {
          label: c.type === "card" ? `${c.card.brand} ${last4}` : c.card.email,
          value: c.id,
        };
      })
    );
  }, [cardList]);

  const schema = yup.object({
    selectedCard: yup.object().required("Please select a card"),
  });

  const { form, errors, changeFormValue, isValid, submit } = useChargeForm({
    schema,
    onSubmit: () => {
      showTipsModal.update(true);
    },
    initialValues: {
      selectedCard: cardOptions[0] || null,
    },
  });

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          cardOptions={cardOptions}
          isValid={isValid}
          form={form}
          errors={errors}
          changeFormValue={changeFormValue}
          submit={submit}
        />
      ) : (
        <PaymentTipsModal
          selectedCard={form.selectedCard}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
          showTipsModal={showTipsModal}
          isTipsApplying={isTipsApplying}
        />
      )}

      <Loader isFullWidth={true} showLoader={showLoader} />
    </div>
  );
};

FormCardOnFile.propTypes = {
  cardList: PropTypes.array,
};

const mapStateToProps = (state, { type }) => {
  const list =
    type === "card"
      ? state.StripeReducer.cardList.filter((card) => card.type === "card")
      : state.StripeReducer.cardList.filter((card) => card.type === "link");

  return {
    cardList: list,
    isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getClientCardDataStripe }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FormCardOnFile));
