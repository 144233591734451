import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import { getAccountDetails, updateAccountDetails } from '../../../Actions/Settings/settingsActions.js';
import defLogo from '../../../_legacy/images/no-image-vertical.png';
import Loader from '../../../Components/Common/Loader.js'
import { uiNotification } from '../../../services/UINotificationService.js';

class Payroll extends Component {

  constructor(props) {
    super(props);

    const userData = JSON.parse(localStorage.getItem('userData'));
    this.state = {
      pictures: [],
      userId: userData.user.id,
      accountID: userData.user.account_id,
      pos_enabled:userData.pos_enabled,
      user_type:userData.user_type,
      accDetails: {},
      dateFormats: [{ val: 'mm/dd/yyyy', show: 'mm/dd/yyyy' }, { val: 'dd/mm/yyyy', show: 'dd/mm/yyyy' }, { val: 'yyyy/mm/dd', show: 'yyyy/mm/dd' }],
      logo: '',
      defLogo: defLogo,
      name: '',
      address: '',
      default_template: '',
      website: '',
      date_format: '',
      time_format:'',
      selectedUserEmailOptions:[],
      userEmailOptions:[],
      activeUsersList:[],
      is_bba_signed:false,
      bba_signed_date:'',
      commission_enabled:false,
      commission_type:'',
      commissionTypeError:false,
      nameErrorClass: 'setting-input-box',
      dataChanged: false,
      defImageCls: 'no-display',
      cameraInPreviewCls: 'camra-icon dz-clickable no-image',
      uploadedFile: '',
      dzImgObj: {},
      logo_url: '',
      dzCSS: '',
      dbBusinessName: '',
      showLoader: false,
      loader: false,
      accountsScopes: 'accountPreference,activeUsers',
      client_replacement_text: 'Client'
    }
    window.onscroll = () => {
     return false;
    }

  }

  handleInputChange = (event) => {
    let returnState ={dataChanged: true}
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    //this.setState({ [event.target.name]: value, dataChanged: true });
    if(target.name === 'commission_enabled' && !value){
      returnState.commission_type = '';
      returnState.commissionTypeError = false
    } else if(target.name === 'commission_type' && this.state.commission_enabled){
      returnState.commissionTypeError = false
    }
    returnState[event.target.name] = value;
    this.setState(returnState, () => {
      if(target.name === 'commission_enabled' && !value){
        this.updateAccountDetails();
      }
    })
  }
  handleSelectChange = (selectedUserEmailOptions) => {
    this.setState({
      selectedUserEmailOptions: selectedUserEmailOptions,
      userChanged:true,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //====Frontend validation=================
    let error = false;
    this.setState({
      nameError: false,
    });
    if (this.state.commission_enabled && (typeof this.state.commission_type === undefined || this.state.commission_type === null || this.state.commission_type === '')) {
      this.setState({commissionTypeError: true})
      error = true
    } else if (this.state.commission_type) {
      this.setState({commissionTypeError: false})
    }
    if(error){
      return
    }
    this.updateAccountDetails();
  }

  updateAccountDetails = (id) => {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    let  notification_msg;
    if(this.state.commission_enabled){

      notification_msg = "payroll_commission_report_enabled"
    }
    else{
      notification_msg = "payroll_commission_report_disabled"
    }

    let formData = {
      commission_enabled: (this.state.commission_enabled) ? 1 : 0,
       notification_msg :notification_msg,
      commission_type:(this.state.commission_enabled) ? this.state.commission_type : '',
      client_replacement_text: this.state.client_replacement_text
    }
    this.setState({ showLoader: true })
    this.props.updateAccountDetails(formData, this.state.accountID)
      .then(res => {
        uiNotification.success(languageData.global[res.message] || "Updated");
      })
      .catch(res => {
        uiNotification.error(languageData.global[res.message] || "Unable To Update");
      });
  }

  handleChildChange = (stateToUpdate) => {
    this.setState(stateToUpdate);
 }


 /*componentDidMount() {

   const languageData = JSON.parse(localStorage.getItem('languageData'))
   this.setState({
     arAccount_header: languageData.settings['arAccount_header'],
     ar_business_name: languageData.settings['ar_business_name'],
     ar_address : languageData.settings['ar_address'],
     ar_default_timeline: languageData.settings['ar_default_timeline'],
     ar_cosmetic: languageData.settings['ar_cosmetic'],
     ar_health: languageData.settings['ar_health'],
     ar_website_url: languageData.settings['ar_website_url'],
     ar_default_date: languageData.settings['ar_default_date'],
     clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],
     user_save_btn_text: languageData.settings['user_save_btn_text'],
     showLoader: true
  })
  this.setState({ showLoader: true, loader: true })
   this.props.getAccountDetails(this.state.accountID);
 }*/


  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (props.accountData !== undefined && props.accountData.status === 200 && props.accountData.data != state.accDetails) {
      returnState.showLoader = false
      returnState.loader = false
      returnState.accDetails= props.accountData.data;
      returnState.logo= props.accountData.data.logo;
      returnState.logo_url= props.accountData.data.logo_url;
      returnState.name= (state.dataChanged) ? state.name : props.accountData.data.name;
      returnState.address= (state.dataChanged) ? state.address : props.accountData.data.address;
      returnState.default_template= (state.dataChanged) ? state.default_template : props.accountData.data.account_preference.default_template;
      returnState.website= (state.dataChanged) ? state.website : props.accountData.data.website;
      returnState.date_format= (state.dataChanged) ? state.date_format : props.accountData.data.account_preference.date_format;
      returnState.time_format= (state.dataChanged) ? state.time_format : props.accountData.data.account_preference.time_format;
      returnState.is_bba_signed= (state.dataChanged) ? state.is_bba_signed : (props.accountData.data.account_preference.is_bba_signed == 1) ? true : false;
      returnState.bba_signed_date= props.accountData.data.account_preference.bba_signed_date;
      returnState.commission_enabled= (state.dataChanged) ? state.commission_enabled : props.accountData.data.account_preference.commission_enabled;
      returnState.commission_type= (state.dataChanged) ? state.commission_type : props.accountData.data.account_preference.commission_type;
      returnState.dbBusinessName= props.accountData.data.name;
      returnState.activeUsersList = (props.accountData.data.active_users != undefined) ? props.accountData.data.active_users : [];

      returnState.client_replacement_text= (state.dataChanged) ? state.client_replacement_text : props.accountData.data.account_preference.client_replacement_text;

      let userEmailOptions = [];
      if(returnState.activeUsersList != undefined && returnState.activeUsersList.length) {
        userEmailOptions = returnState.activeUsersList.map((lobj, lidx) => {
          return {value: lobj.id,  label: lobj.email_id}
        })
      }
      returnState.userEmailOptions = userEmailOptions;
      if(!state.dataChanged){
        let selectedUserEmailOptions = [];
        if(props.accountData.data.account_preference!= undefined && props.accountData.data.account_preference.monthly_sales_email_receivers != undefined && props.accountData.data.account_preference.monthly_sales_email_receivers != '' && props.accountData.data.account_preference.monthly_sales_email_receivers != null  && returnState.activeUsersList.length){
          const monthlySalesEmailReceivers= props.accountData.data.account_preference.monthly_sales_email_receivers.split(',');
          returnState.activeUsersList.map((obj, idx) => {
            if(monthlySalesEmailReceivers.indexOf(obj.email_id) > -1) {
              selectedUserEmailOptions.push({value: obj.id,  label: obj.email_id})
            }
          })
        }
        returnState.selectedUserEmailOptions = selectedUserEmailOptions
      }
      returnState.showLoader= false;
    returnState.loader = false;
    }
    return returnState;
  }

  componentDidMount() {

    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.setState({
      arAccount_header: languageData.settings['arAccount_header'],
      ar_business_name: languageData.settings['ar_business_name'],
      ar_address: languageData.settings['ar_address'],
      ar_default_timeline: languageData.settings['ar_default_timeline'],
      ar_cosmetic: languageData.settings['ar_cosmetic'],
      ar_health: languageData.settings['ar_health'],
      ar_website_url: languageData.settings['ar_website_url'],
      ar_default_date: languageData.settings['ar_default_date'],
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],

      ar_default_time: languageData.settings['ar_default_time'],
      ar_send_monthly_sales_report_to: languageData.settings['ar_send_monthly_sales_report_to'],
      ar_aesthetic_record_terms_of_service: languageData.settings['ar_aesthetic_record_terms_of_service'],
      ar_accepted_on: languageData.settings['ar_accepted_on'],
      ar_not_accepted_yet: languageData.settings['ar_not_accepted_yet'],
      ar_view_terms_of_service: languageData.settings['ar_view_terms_of_service'],
      ar_hipaa_business_associate_addendum: languageData.settings['ar_hipaa_business_associate_addendum'],
      ar_view_hipaa_terms_of_service: languageData.settings['ar_view_hipaa_terms_of_service'],
      ar_payroll_commission_report: languageData.settings['ar_payroll_commission_report'],
      ar_net_value_services_performed: languageData.settings['ar_net_value_services_performed'],
      ar_net_value_services_performed_formula: languageData.settings['ar_net_value_services_performed_formula'],
      ar_net_sales: languageData.settings['ar_net_sales'],
      ar_net_sales_formula: languageData.settings['ar_net_sales_formula'],
      settings_net_sales: languageData.settings['settings_net_sales'],
      ar_gross_sales: languageData.settings['ar_gross_sales'],
      ar_gross_sales_formula: languageData.settings['ar_gross_sales_formula'],
      ar_net_profit: languageData.settings['ar_net_profit'],
      settings_twenty_four_hours:  languageData.settings['settings_twenty_four_hours'],
      Edit_Appointment_Twelve_Hours: languageData.settings['Edit_Appointment_Twelve_Hours'],
      ar_net_profit_formula: languageData.settings['ar_net_profit_formula'],
      ar_please_select_payroll_commission_report: languageData.settings['ar_please_select_payroll_commission_report'],
      showLoader: true

    })
    this.setState({ showLoader: true, loader: true })
    this.props.getAccountDetails(this.state.accountID,this.state.accountsScopes);
  }




  render() {
    let optData = '';

    if (this.state.accDetails.account_preference !== undefined) {
      optData = this.state.dateFormats.map((dateFormat) => {
        return <option key={dateFormat.val} value={dateFormat.val}>{dateFormat.show}</option>;
      })
    }

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  Payroll/Commission Report
                    <label className="setting-switch pull-right">
                      <input type="checkbox" name="commission_enabled" className="setting-custom-switch-input" value={this.state.commission_enabled}  checked={this.state.commission_enabled ? 'checked' : false } onChange={this.handleInputChange} />
                      <span className="setting-slider"></span>
                    </label>
                </div>
              </div>
              {(this.state.commission_enabled == true) &&
               <div className="setting-setion m-b-10">
              <form id="ar-account-form" name="ar-account-form" className="nobottommargin" action="#" method="post" onSubmit={this.handleSubmit}>
                  <>
                    <div className="switch-accordian-outer">

                        <div className={this.state.commission_enabled ? "p20" : 'no-display'}>

                        <div id="payroll_selection_div" className="radio-outer">
                          <div className="setting-input-outer">
                            <input id="payroll1" type="radio" name="commission_type" value="Net Value Services Performed"  checked={this.state.commission_type == "Net Value Services Performed" ? 'checked' : false } onChange={this.handleInputChange} />
                            <label htmlFor="payroll1" className="payroll-label cursor-pointer">{this.state.ar_net_value_services_performed}</label>
                            <div className="payroll-formula">NVSP = Total Sales - Tips - Taxes</div>
                          </div>
                          <div className="setting-input-outer">
                            <input id="payroll1" type="radio" name="commission_type" value="Total Net Profit"  checked={this.state.commission_type == "Total Net Profit" ? 'checked' : false } onChange={this.handleInputChange} />
                            <label htmlFor="payroll1" className="payroll-label cursor-pointer">Net Profit</label>
                            <div className="payroll-formula">Net Profit = Total Sales - Tips - Taxes - Cost to company</div>
                          </div>
                          <div className="setting-input-outer">
                            <input id="payroll2" type="radio" name="commission_type" value="Net Sales"  checked={this.state.commission_type == "Net Sales" ? 'checked' : false } onChange={this.handleInputChange} />
                            <label htmlFor="payroll2" className="payroll-label cursor-pointer">{this.state.ar_net_sales}</label>
                            <div className="payroll-formula">
                            Net Sales = Total Sales From All Sources - Wallet Debits - Refunds - Tips - Taxes - Processing Fee - Discounts
                            </div>
                          </div>
                          <div className="setting-input-outer">
                            <input id="payroll3" type="radio" name="commission_type" value="Gross Sales"  checked={this.state.commission_type == "Gross Sales" ? 'checked' : false } onChange={this.handleInputChange} />
                            <label htmlFor="payroll3" className="payroll-label cursor-pointer">{this.state.ar_gross_sales}</label>
                            <div className="payroll-formula">Gross Sales = Total Sales - Discounts - Tips - Tax</div>
                          </div>
                          <div className="setting-input-outer">
                            <input id="payroll4" type="radio" name="commission_type" value="Net Profit"  checked={this.state.commission_type == "Net Profit" ? 'checked' : false } onChange={this.handleInputChange} />
                            <label htmlFor="payroll4" className="payroll-label cursor-pointer">{this.state.ar_net_profit}</label>
                            <div className="payroll-formula">Total Procedure Sales = Total Sales - Discounts - Tips - Taxes - Wallet Credits</div>
                          </div>
                          { this.state.commissionTypeError &&
                            <div id="payroll_error">{this.state.ar_please_select_payroll_commission_report}</div>
                          }
                        </div>
                        </div>
                    </div>
                    <div className="footer-static">
                      <input className="new-blue-btn pull-right" type="submit" value="Save" id="save-profile" />
                    </div>
                    </>
              </form>

              </div>
            }

            </div>
            </div>
            <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};
  if (state.SettingReducer.action === "ACCOUNT_GET") {
    if(state.SettingReducer.data.status != 200){

    }
    else {
      returnState.accountData = state.SettingReducer.data

    }
  }
  else if (state.SettingReducer.action === "ACCOUNT_PUT") {
    if (state.SettingReducer.data.status == 200) {
      returnState.accountData = state.SettingReducer.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getAccountDetails: getAccountDetails, updateAccountDetails: updateAccountDetails }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);
