import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  clinicIds: yup.array().of(yup.number()).nullable(),
  fromDate: yup.string().required(),
  toDate: yup.string().required(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup
      .array()
      .of(
        yup.object({
          invoiceId: yup.number().required(),
          clientId: yup.number().required(),
          clientName: yup.string().required(),
          discountType: yup.string().nullable(),
          providerName: yup.string().nullable(),
          checkoutPerson: yup.string().nullable(),
          amount: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const reports = res?.data?.data?.daily_discount_report || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    reports: reports.map((r) => ({
      invoiceId: r.invoice_id,
      clientId: r.patient_id,
      clientName: r.patient_name,
      discountType: r.discount_type || null,
      providerName: r.provider || null,
      checkoutPerson: r.checkout_person || null,
      amount: r.amount,
    })),
  };
};

function createDailySalesReportQueryKey({
  pageSize,
  clinicIds,
  fromDate,
  toDate,
}) {
  return [
    QUERY_KEYS.dailySalesReport,
    pageSize,
    clinicIds.join("-"),
    fromDate,
    toDate,
  ];
}

export function useDailySalesReportQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createDailySalesReportQueryKey({
      pageSize: req.pageSize,
      clinicIds: req.clinicIds || [],
      fromDate: req.fromDate,
      toDate: req.toDate,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.post(
        HTTP_ENDPOINTS.createDailySalesReport(),
        removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          clinic_id: req.clinicIds.length > 0 ? req.clinicIds : null,
          fromDate: req.fromDate,
          toDate: req.toDate,
        }),
      );
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
