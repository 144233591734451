import { useUpdateGiftCardBalanceMutation } from "../../../../../api/businessInsight/useUpdateGiftCardBalanceMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export const useSubmit = (giftCardId, onSuccess) => {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useUpdateGiftCardBalanceMutation({
    onError: () => {
      uiNotification.error(tCommon("modalEditGiftCardBalance.error.update"));
    },
    onSuccess: () => {
      uiNotification.success(
        tCommon("modalEditGiftCardBalance.success.update"),
      );
      onSuccess?.();
    },
  });

  return {
    isLoading,
    initiate: (formValues) =>
      mutateAsync({
        giftCardId,
        balance: formValues.balance,
        reason: formValues.reason,
      }),
  };
};
