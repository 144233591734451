import React from "react";
import PropTypes from "prop-types";
import { ButtonMenu } from "../../../../shared/ButtonMenu/ButtonMenu";
import { Button } from "../../../../shared/Button/Button";
import { svg } from "../../../../assets/svg";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useProcedureAfterPhotosChange } from "../../hooks/useProcedureAfterPhotosChange";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { useProcedureHide } from "../../hooks/useProcedureHide";

export function TabCosmeticRowActions({ procedureId, isAfterPhotos }) {
  const { tCommon } = useAppTranslation.Common();

  const { changeAsAfterPhotos, isChangingAsAfterPhotos } =
    useProcedureAfterPhotosChange({ procedureId });

  const { hideProcedure, isProcedureHiding } = useProcedureHide({
    procedureId,
  });

  const isAfterPhotosActions = [
    {
      key: "unMarkAfterPhotos",
      label: tCommon("providerRoom.action.unMarkAfterPhotos"),
      onClick: () => changeAsAfterPhotos(false),
    },
    {
      key: "hide",
      label: tCommon("label.hide"),
      onClick: hideProcedure,
    },
  ];

  const isBeforePhotosActions = [
    {
      key: "maskAfterPhotos",
      label: tCommon("providerRoom.action.maskAfterPhotos"),
      onClick: () => changeAsAfterPhotos(true),
    },
  ];

  const isLoading = isChangingAsAfterPhotos || isProcedureHiding;

  return (
    <ButtonMenu
      options={isAfterPhotos ? isAfterPhotosActions : isBeforePhotosActions}
      renderButton={({ onToggleMenu, isMenuOpen }) => (
        <Button
          size="small"
          variant="text"
          onClick={onToggleMenu}
          isHovered={isMenuOpen}
          isDisabled={isLoading}
          rightAdornment={
            isLoading ? (
              <CircularProgress size="tiny" />
            ) : (
              <img src={svg.chevronDownPrimary} alt="" width="16px" />
            )
          }
        >
          {tCommon("label.action")}
        </Button>
      )}
    />
  );
}

TabCosmeticRowActions.propTypes = {
  procedureId: PropTypes.number.isRequired,
  isAfterPhotos: PropTypes.bool.isRequired,
};
