import React from "react";

export function PlusIcon({ width }) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width }}
    >
      <path
        d="M13.0211 5.9486H1.5697M7.29541 10.9812L7.29541 0.916016"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
