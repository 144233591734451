import React, { Component } from "react";

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData
    ) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="setting-setion m-b-15">
            <div className="table-responsive no-border">
              <table className="records-table no-margin  no-hover no-border">
                <thead style={{ backgroundColor: "#dbeefd" }}>
                  {this.state.reportData &&
                    this.state.reportData.report_header && (
                      <tr style={{ color: "#188bf6" }}>
                        <th className="col-xs-3">
                          {this.state.reportData.report_header.payment_method}
                        </th>
                        <th>
                          {this.state.reportData.report_header.transactions}
                        </th>
                        <th>
                          {this.state.reportData.report_header.total_collected}
                        </th>
                        <th>
                          {this.state.reportData.report_header.total_refunds}
                        </th>
                        <th>
                          {this.state.reportData.report_header.processing_fees}
                        </th>
                        <th>
                          {this.state.reportData.report_header.net_collected}
                        </th>
                      </tr>
                    )}
                </thead>
                <tbody>
                  {this.state.reportData &&
                    this.state.reportData.report_data &&
                    this.state.reportData.report_data.length > 0 &&
                    this.state.reportData.report_data.map((obj, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{obj && obj.payment_method}</td>
                          <td>{obj && obj.transactions}</td>
                          <td>{obj && obj.total_collected}</td>
                          <td>{obj && obj.total_refunds}</td>
                          <td>{obj && obj.processing_fees}</td>
                          <td>{obj && obj.net_collected}</td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  {this.state.reportData &&
                    this.state.reportData.report_data &&
                    this.state.reportData.report_data.length > 0 &&
                    this.state.reportData.total && (
                      <tr className="bold">
                        <td>{this.state.reportData.total.payment_method}</td>
                        <td>{this.state.reportData.total.transactions}</td>
                        <td>{this.state.reportData.total.total_collected}</td>
                        <td>{this.state.reportData.total.total_refunds}</td>
                        <td>{this.state.reportData.total.processing_fees}</td>
                        <td>{this.state.reportData.total.net_collected}</td>
                      </tr>
                    )}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="setting-setion m-b-15">
            <div className="table-responsive no-border">
              <table className="records-table no-margin  no-hover no-border">
                <thead style={{ backgroundColor: "#dbeefd" }}>
                  {this.state.reportData?.report_header &&
                    this.state.reportData?.report_partial_data && (
                      <tr style={{ color: "#188bf6" }}>
                        <th className="col-xs-3">
                          {
                            this.state.reportData?.report_header
                              ?.partial_payments
                          }
                        </th>
                        <th>
                          {this.state.reportData?.report_header?.transactions}
                        </th>
                        <th>
                          {
                            this.state.reportData?.report_header
                              ?.total_collected
                          }
                        </th>
                        <th>
                          {this.state.reportData?.report_header?.total_refunds}
                        </th>
                        <th>
                          {
                            this.state.reportData?.report_header
                              ?.processing_fees
                          }
                        </th>
                        <th>
                          {this.state.reportData?.report_header?.net_collected}
                        </th>
                      </tr>
                    )}
                </thead>
                <tbody>
                  {this.state.reportData?.report_partial_data?.length &&
                    this.state.reportData.report_partial_data.map(
                      (obj, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{obj?.payment_method}</td>
                            <td>{obj?.transactions}</td>
                            <td>{obj?.total_collected}</td>
                            <td>{obj?.total_refunds}</td>
                            <td>{obj?.processing_fees}</td>
                            <td>{obj?.net_collected}</td>
                          </tr>
                        );
                      },
                    )}
                </tbody>
                <tfoot>
                  {this.state.reportData?.total_partial && (
                    <>
                      <tr
                        className="bold"
                        style={{ borderTop: "none", borderBottom: "none" }}
                      >
                        <td>
                          {this.state.reportData.total_partial.payment_method}
                        </td>
                        <td>
                          {this.state.reportData.total_partial.transactions}
                        </td>
                        <td>
                          {this.state.reportData.total_partial.total_collected}
                        </td>
                        <td>
                          {this.state.reportData.total_partial.total_refunds}
                        </td>
                        <td>
                          {this.state.reportData.total_partial.processing_fees}
                        </td>
                        <td>
                          {this.state.reportData.total_partial.net_collected}
                        </td>
                      </tr>
                      <tr className="bold">
                        <td>
                          Balance Due{" "}
                          <span className="font-weight-normal displayBlock">
                            (including draft invoices)
                          </span>
                        </td>
                        <td>{this.state.reportData.balance_due}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </>
                  )}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentMethods;
