import React from "react";
import { Group } from "../../shared/Group/Group";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { MultiRowSelect } from "../../boxes/MultiRowSelect/MultiRowSelect";
import { getIsSomeTruthy } from "../../../../../../helpers/general";
import { getAllergyCheck } from "../../../MergeProfilesMain.utils";

export function Allergies({ intersection, allergies, onChange }) {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();
  const isSomeTruthy = getIsSomeTruthy(allergies);

  return (
    <Group biggerGap label={tClients("medicalHistory.allergies.label")}>
      {isSomeTruthy(["drugs"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.allergies.drug")}
          value={intersection.allergy_drug || []}
          patientOptions={allergies.map((a) => a?.allergy_drug || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              allergy_drug: value,
              drugs: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getAllergyCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tCommon("label.medication"),
                  value: option.medication,
                },
                {
                  label: tCommon("label.reaction"),
                  value: option.reaction,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["foods"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.allergies.food")}
          value={intersection.allergy_food || []}
          patientOptions={allergies.map((a) => a?.allergy_food || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              allergy_food: value,
              foods: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getAllergyCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tCommon("label.food"),
                  value: option.food,
                },
                {
                  label: tCommon("label.reaction"),
                  value: option.reaction,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["environment"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.allergies.env")}
          value={intersection.allergy_environment || []}
          patientOptions={allergies.map((a) => a?.allergy_environment || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              allergy_environment: value,
              environment: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getAllergyCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tCommon("label.environment"),
                  value: option.environment,
                },
                {
                  label: tCommon("label.reaction"),
                  value: option.reaction,
                },
              ]}
            />
          )}
        />
      )}
    </Group>
  );
}
