import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import classes from "./ReportDetails.module.scss";
import { Box } from "../../../../../../shared/Box/Box";
import { Table } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import {
  capitalize,
  formatCurrency,
} from "../../../../../../utilities/general";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { Button } from "../../../../../../shared/Button/Button";
import { history } from "../../../../../../history";
import { svg } from "../../../../../../assets/svg";
import { useMembershipInvoicesQuery } from "../../../../../../api/queries/useMembershipInvoicesQuery";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { BI_ROUTES } from "../../../../../../consts/routes";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";

export function ReportDetails({ id }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();

  const { data, isLoading } = useMembershipInvoicesQuery({
    payload: {
      membershipId: id,
    },
    options: {
      staleTime: 0,
    },
  });

  const invoices = data?.invoices || [];
  const patient = data?.patient || {};

  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <Button
          fontFamily="secondary"
          variant="text"
          size="small"
          className={classes.backBtn}
          onClick={() =>
            history.push(BI_ROUTES.sales.membershipOverviewReport())
          }
        >
          <img src={svg.chevronLeftPrimary} alt="<" width="8px" />
        </Button>
        <div className={classes.title}>
          {isLoading ? (
            <Skeleton width="320px" height={28.5} borderRadius="4px" />
          ) : (
            tBi("membershipOverviewReport.detailsLabel") +
              " " +
              patient.fullName || tCommon("symbol.longDash")
          )}
        </div>
      </Box>
      <Box>
        {isLoading && <Skeleton count={15} height={40} borderRadius="0px" />}
        {!isLoading && invoices.length > 0 ? (
          <Table
            cols={[
              {
                data: tCommon("label.amount"),
                accessor: "amount",
              },
              {
                data: tCommon("label.invoiceNumber"),
                accessor: "invoiceNumber",
              },
              {
                data: tCommon("label.status"),
                accessor: "status",
              },
              {
                data: tCommon("label.membershipType"),
                accessor: "subscriptionType",
              },
              {
                data: tCommon("label.paidOn"),
                accessor: "modifiedAt",
              },
            ]}
            data={invoices.map((i) => ({
              key: i.id,
              amount: formatCurrency(i.amount, i.currency),
              invoiceNumber: i.invoiceNumber || tCommon("symbol.longDash"),
              status: capitalize(i.paymentStatus) || tCommon("symbol.longDash"),
              subscriptionType: capitalize(i.subscriptionType),
              modifiedAt: i.modifiedAt
                ? moment(i.modifiedAt).format(
                    `${user?.previewDateFormat} ${user?.timeFormat}`,
                  )
                : tCommon("symbol.longDash"),
            }))}
          />
        ) : (
          <Empty position="center" />
        )}
      </Box>
    </div>
  );
}

ReportDetails.propTypes = {
  id: PropTypes.number.isRequired,
};
