import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../hooks/useChargeForm";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ViewChargeCheck = ({ isTipsApplying }) => {
  const { tSales } = useAppTranslation.Sales();
  const { showTipsModal } = usePaymentFlow();

  const schema = yup.object({
    checkNumber: yup
      .string()
      .required(tSales("checkoutInvoice.formError.checkNumberRequired")),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: () => {
        showTipsModal.update(true);
      },
      initialValues: {
        checkNumber: "",
      },
    });

  return !showTipsModal.value ? (
    <PaymentFormModal
      isValid={isValid}
      form={form}
      submit={submit}
      errors={errors}
      hasError={hasError}
      changeFormValue={changeFormValue}
    />
  ) : (
    <>
      <PaymentTipsModal
        isTipsApplying={isTipsApplying}
        checkNo={form.checkNumber}
        showTipsModal={showTipsModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCheck);
