import { useMemo } from "react";

/* 
  APT_PAY:

  I. CC Details checkbox is VISIBLE when:
    1. (isCanTakePaymentWithDeposit) Clinic CAN take payment
      and at least one of selected services is confirmed
      and was selected by user during current appointment creation *
    2. (isCanTakePaymentWithCancelationPolicy) OR Clinic CAN take payment
      and Cancellation policy is enabled
      and at least one of selected services is NON Free
      and was selected by user during current appointment creation *
  CC Details form is visible when checkbox is checked

  II. Confirm deposit modal is showing when:
    1. Clinic CAN take payment and this service price is > 0
    2. While we're converting waitlist to appointment 
      and we did not confirm minimum desposit for services while creating waitlist
    3.list of services includes any service that required minimum deposit confirmation
  
  III. Confirm cancellation policy excluding modal is showing when:
    1. isCanTakePaymentWithCancelationPolicy=True
      and user is NOT checked CC details checkbox
      and user is NOT confirmed it before for current appointment form submission

  IV. Cancellation policy tip is visible when:
    1. Clinic CAN'T take payment
      and Cancellation policy is ON
      and selected clinic id is valid **
    
  V. Appointment cannot be submitted when:
    1. isCanTakePaymentWithDeposit = True and user is not checked CC details checkbox

  * - services can be selected automatically when user is editing previously created
  appointment. In this case we should remove this services from checking.

  ** - it can be the case when user provided clinicId in url param and its id is not in clinics list.

  (!) If you made any changes related to this logic, make sure to check if all of
    the above still working as declared. Search for APT_PAY marks in code and 
    leave this mark for related code in future.
*/

export function usePaymentInfoAppointment({
  paymentStatuses,
  services,
  clinicId,
  clinics,
}) {
  const isClinicIdValid = useMemo(
    () => Boolean(clinics.find((c) => c.id === clinicId)),
    [clinics, clinicId],
  );

  const isClinicCanTakePayment = useMemo(
    () =>
      Boolean(
        paymentStatuses.clinicCanTakePaymentStatusById &&
          paymentStatuses.clinicCanTakePaymentStatusById[clinicId],
      ),
    [paymentStatuses.clinicCanTakePaymentStatusById, clinicId],
  );

  // APT_PAY: See point I.1 above
  const isCanTakePaymentWithDeposit = useMemo(
    () =>
      Boolean(
        services.some((s) => s.isDepositConfirmed && !s.isSkipPaymentCheck) &&
          isClinicCanTakePayment,
      ),
    [services, isClinicCanTakePayment],
  );

  // APT_PAY: See point I.2 above
  const isCanTakePaymentWithCancelationPolicy = useMemo(
    () =>
      Boolean(
        paymentStatuses.isCancelationPolicyEnabled &&
          services.some((s) => !s.isFree && !s.isSkipPaymentCheck) &&
          isClinicCanTakePayment,
      ),
    [
      services,
      paymentStatuses.isCancelationPolicyEnabled,
      isClinicCanTakePayment,
    ],
  );

  // APT_PAY: See point IV.1 above
  const isCancellationPolicyTipVisible = useMemo(
    () =>
      Boolean(
        clinicId &&
          !isClinicCanTakePayment &&
          isClinicIdValid &&
          paymentStatuses.isCancelationPolicyEnabled,
      ),
    [
      clinicId,
      isClinicCanTakePayment,
      isClinicIdValid,
      paymentStatuses.isCancelationPolicyEnabled,
    ],
  );

  return {
    isClinicCanTakePayment,
    isCanTakePaymentWithDeposit,
    isCanTakePaymentWithCancelationPolicy,
    isCancellationPolicyTipVisible,
  };
}
