import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useCreateOutOfOfficeEventMutation(options = {}) {
  return useMutation((dto) => {
    return http.post(HTTP_ENDPOINTS.postOutOfOfficeEvent(), {
      provider_id: dto.providerIds,
      clinic_id: dto.clinicId,
      date: dto.date,
      from_time: dto.fromTime,
      to_time: dto.toTime,
      frequency: dto.frequency,
      name: dto.name,
      cancel_existing_appointments: dto.cancelExistingAppointments,
      frequency_type: dto.frequencyType,
      ends_on: dto.endsOn,
      occurrence: dto.occurrence,
      interval: dto.interval,
      repeat_type: dto.repeatType,
      week_days_arr: dto.weekDays,
      id: dto.id,
      appointment_id: dto.appointmentId,
      all_following: dto.allFollowing,
    });
  }, options);
}
