import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./EntityHeader.module.scss";
import { Box } from "../../shared/Box/Box";
import { InputSearch } from "../../boxes/InputSearch/InputSearch";
import { Button } from "../../shared/Button/Button";
import { history } from "../../history";
import { ChevronLeftIcon } from "../../assets/Icons/ChevronLeftIcon";

export function EntityHeader({
  searchValue,
  onChangeSearch,
  isSearching,
  title,
  right,
  className,
  withBackBtn,
  onBack,
  left,
  searchPlaceholder,
}) {
  return (
    <Box elevation className={cx(classes.root, className)}>
      <div className={classes.left}>
        <div className={classes.titleWrap}>
          {withBackBtn && (
            <Button
              variant="text"
              size="small"
              onClick={() => (onBack ? onBack() : history.goBack())}
            >
              <ChevronLeftIcon />
            </Button>
          )}
          <div className={classes.title}>{title}</div>
        </div>
        {onChangeSearch && (
          <InputSearch
            className={classes.search}
            value={searchValue || ""}
            onChange={onChangeSearch}
            isSearching={isSearching}
            placeholder={searchPlaceholder}
          />
        )}
        {left}
      </div>
      {right && <div className={classes.right}>{right}</div>}
    </Box>
  );
}

EntityHeader.propTypes = {
  searchValue: PropTypes.string,
  onChangeSearch: PropTypes.func,
  isSearching: PropTypes.bool,
  title: PropTypes.node.isRequired,
  right: PropTypes.node,
  className: PropTypes.string,
  withBackBtn: PropTypes.bool,
  left: PropTypes.node,
  onBack: PropTypes.func,
  searchPlaceholder: PropTypes.string,
};

EntityHeader.defaultProps = {
  searchValue: "",
  onChangeSearch: undefined,
  isSearching: false,
  className: undefined,
  withBackBtn: false,
};
