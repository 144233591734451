import React, {Component} from 'react';
import { positionFooterCorrectly,showFormattedDate } from '../../../Utils/services';
class Procedures extends Component{
    constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
		select_all : false,
		clientLang : languageData.clients,
		salesLang : languageData.sales
		}
        
	}
	
	static getDerivedStateFromProps(nextProps,prevState){
		let returnState = {};
		if(nextProps.procedureData != undefined && nextProps.procedureDataTime != prevState.procedureDataTime ){
			positionFooterCorrectly();
			returnState.showLoader = false;
			returnState.procedureData = nextProps.procedureData;
			returnState.procedureDataTime = nextProps.procedureDataTime;
			nextProps.procedureData.map((data,index) => {
				returnState['service-' + data.id] = data.selected_status;
			})
			if(nextProps.procedureData.length > 0){
			let enableSelectAll = {};
			enableSelectAll = nextProps.procedureData.find(y => y.selected_status == false);
			if(enableSelectAll){
				returnState.select_all = false;
			} else {
				returnState.select_all = true;
			}}
		}
		return returnState;
	}

	setSelectAll = (event) => {
		this.setState({select_all : !event.target.value});
	}

	handleInputChange = (event) => {
		const target = event.target;
		let value= target.value;
		let name = event.target.name;
		let returnState = {}
		switch(target.type) {
			case 'checkbox': {
				value = target.checked;
				break;
			}
	  
			case 'radio' :{
			  value = target.value;
			  break;
		  }
		}
		if(target.type == 'checkbox' && target.checked == true && target.name == 'select_all') {
			let data = this.state.procedureData;
			data.map((obj, index) => {
				returnState['service-' + obj.id] = true;
			})

		} else if(target.type == 'checkbox' && target.checked == false && target.name == 'select_all') {
			let data = this.state.procedureData;
			data.map((obj, index) => {
				returnState['service-' + obj.id] = false;
			})
		}
		
	  returnState[event.target.name] = value;
      this.setState(returnState, () => this.sendDataToParent());
	}

	sendDataToParent = () => {
		let checkStatus = [];
		this.state.procedureData.map((data,index) => {
			if(this.state['service-' + data.id] == false){
				checkStatus.push(data);
			}
		})
		if(checkStatus.length > 0){
			this.setState({ select_all : false });
		} else {
			this.setState({ select_all : true });
		}
		let dataToSend = [];
		this.state.procedureData.map((data,index) => {
			dataToSend.push({
				name : data.name,
                date : data.date,
                provider_name : data.provider_name,
                id : data.id,
                procedure_image_45_thumb_url : data.procedure_image_45_thumb_url,
                patient_image_left_45_thumb_url : data.patient_image_left_45_thumb_url,
                patient_image_right_45_thumb_url : data.patient_image_right_45_thumb_url,
				selected_status : this.state['service-' + data.id] ? this.state['service-' + data.id] : false, 
			})
		})
		let formData = {
			step2Data : dataToSend
		}
		this.props.handleChildState(formData);
	}
    render(){
		
		return(<div>
            <div className="row">
		<div className="col-xs-12">
			<div className="setting-setion full-width">
				<div className="merge-main-title checkOuter">
				{this.state.procedureData && this.state.procedureData.length > 0 && <input type="checkbox" id="double-booking" name="select_all" value={this.state.select_all} checked={(this.state.select_all) ? "checked" : false} onChange={this.handleInputChange} />}
					<span>{this.state.clientLang.merge_procedures}</span>
					{this.state.procedureData && this.state.procedureData.length > 0 && <p>{this.state.clientLang.merge_right_information_message}</p>}
				</div>
			
				{this.state.procedureData && this.state.procedureData.map((obj,index) => {
					return(
                    <div className="procedure-row checkOuter" key={obj.id}>
					<input type="checkbox" value={obj.selected_status} name={"service-"+obj.id} 
					checked={(this.state["service-"+obj.id]) ? "checked" : false} autoComplete="off" onChange={this.handleInputChange} />
					<div className="procedureName">{obj.name}</div>
					<div className="procedureInformation">
						<div className="procedureThreeImages">
							<img src={obj.patient_image_right_45_thumb_url ? obj.patient_image_right_45_thumb_url : '/images/no-image-vertical.png'}/>
							<img src={obj.procedure_image_45_thumb_url ? obj.procedure_image_45_thumb_url : '/images/no-image-vertical.png'}/>
							<img src={obj.patient_image_left_45_thumb_url ? obj.patient_image_left_45_thumb_url : '/images/no-image-vertical.png'}/>
						</div>


						<div className="procedureDetails">
							<div className="info-row">
								<label>{this.state.clientLang.client_name}</label>
								<span>{obj.name}</span>
							</div>
							<div className="info-row">
								<label>{this.state.salesLang.sales_date_time}</label>
								<span>{showFormattedDate(obj.date, true)}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.client_provider_name}</label>
								<span>{obj.provider_name}</span>
							</div>
							
						</div>
					</div>
				</div>
					)
				})}
					{this.state.procedureData.length == 0 && <div>
					<div className="table-responsive">
               <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
				
				
			
			</div>
		</div>
		
	</div>

        </div>)
    }
}
export default Procedures;
