import React, { useState } from "react";
import StarBox from "../../../../assets/Icons/StarBox";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useEnableNewFeatures } from "../hooks/useEnableNewFeatures";
import { useFeaturesList } from "../hooks/useFeaturesList";
import classes from "../scss/betaProgram.module.scss";
import cx from "clsx";
import { betaFeatures } from "../../../../store/betaFeatures";
import { dispatch } from "../../../../store/store";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { Button } from "../../../../shared/Button/Button";
import FeedbackModal from "./FeedbackModal";

export function FeatureBox({}) {
  const { tSettings } = useAppTranslation.Settings();
  const [featureStates, setFeatureStates] = useState({});
  const [isLoadingFeatureToggleId, setIsLoadingFeatureToggle] = useState(null);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);

  const { data: response } = useFeaturesList({
    onSuccess: (data) => {
      const formattedResponse = data.data.reduce((acc, feature) => {
        acc[feature.id] = feature.toggled;
        return { ...acc, [feature.id]: feature.toggled };
      }, {});
      setFeatureStates(formattedResponse);
    },
  });

  const { mutate } = useEnableNewFeatures();

  const toggleEnabled = (featureId) => {
    const selectedFeature = response.data.find(
      (feature) => feature.id === featureId,
    );
    setIsLoadingFeatureToggle(featureId);
    mutate(featureId, {
      onSuccess: (data) => {
        setFeatureStates((prevState) => ({
          ...prevState,
          [featureId]: prevState[featureId] === "on" ? "off" : "on",
        }));
        dispatch(betaFeatures.actions.setBetaFeatures(selectedFeature.label));
        uiNotification.success(tSettings(data.data.message));
      },
      onSettled: () => setIsLoadingFeatureToggle(null),
    });
  };

  const isFeatureEnabled = (featureId) => {
    return featureStates[featureId] === "on";
  };

  return (
    <>
      {response?.data.map((feature) => (
        <div className={cx(classes.boxFeature, classes.mt)} key={feature.id}>
          <div className={classes.headBox}>
            <span className={classes.planTitle}>
              <StarBox />
              {feature.header}
            </span>
            <div className={classes.toggleBox}>
              {isLoadingFeatureToggleId === feature.id ? (
                <CircularProgress size="small" color="primary" />
              ) : (
                <Toggle
                  isEnabled={isFeatureEnabled(feature.id)}
                  onChange={() => toggleEnabled(feature.id)}
                />
              )}
            </div>
          </div>
          <div className={classes.bodyBox}>
            <span className={classes.bodyTitle}>{feature.title}</span>
            <p
              className={classes.contentText}
              dangerouslySetInnerHTML={{ __html: feature.description }}
            />
          </div>
          <div className={classes.footerBox}>
            <span className={classes.liveDay}>{feature.eta_live_time}</span>
            <Button
              size="small"
              onClick={() => setSelectedFeedbackId(feature.id)}
            >
              {tSettings("betaProgram.submitFeedback")}
            </Button>
          </div>
        </div>
      ))}
      {selectedFeedbackId && (
        <FeedbackModal
          onClose={() => setSelectedFeedbackId(null)}
          selectedFeedbackId={selectedFeedbackId}
        />
      )}
    </>
  );
}
