import React, { useMemo } from "react";
import cx from "clsx";
import styles from "./styles.module.scss";
import { AngleDownIcon } from "../../../../../../assets/Icons/AngleDownIcon";
import { AngleRightIcon } from "../../../../../../assets/Icons/AngleRightIcon";

const EfaxTest = ({
  name,
  tests,
  expanded,
  setExpanded,
  checked,
  setChecked,
}) => {
  const testsIds = useMemo(() => tests.map((test) => test.id), [tests]);

  const handleExpand = () => {
    if (expanded.includes(name)) {
      setExpanded(expanded.filter((item) => item !== name));
    } else {
      setExpanded([...expanded, name]);
    }
  };

  const handleCheckCategory = () => {
    if (testsIds.every((id) => checked.includes(id))) {
      setChecked(checked.filter((item) => !testsIds.includes(item)));
    } else {
      setChecked([...checked, ...testsIds]);
      setExpanded([...expanded, name]);
    }
  };

  const handleCheckTest = (testId) => {
    if (checked.includes(testId)) {
      setChecked(checked.filter((item) => item !== testId));
    } else {
      setChecked([...checked, testId]);
    }
  };

  const allTestsChecked = testsIds.every((id) => checked.includes(id));

  return (
    <div className={styles.efaxTests}>
      <div className={styles.testsRow}>
        <button onClick={handleExpand}>
          {expanded.includes(name) ? <AngleRightIcon /> : <AngleDownIcon />}
        </button>
        <input
          className={cx(!allTestsChecked && styles.squareCheck)}
          type="checkbox"
          checked={testsIds.some((id) => checked.includes(id))}
          onChange={handleCheckCategory}
        />
        <span>{name}</span>
      </div>
      {expanded.includes(name) && (
        <ul>
          {tests.map((test) => (
            <li key={test.id} className={styles.testsRow}>
              <input
                type="checkbox"
                checked={checked.includes(test.id)}
                onChange={() => handleCheckTest(test.id)}
              />
              <span>{test.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default EfaxTest;
