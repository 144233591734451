import React from "react";
import StripeCheckoutPayment from "../components/Manual/StripeCheckoutPayment";

function FormManual() {
  return (
    <div className="p-t-10 p-b-20">
      {
        <div className="p-t-10 p-b-20">
          <StripeCheckoutPayment />
        </div>
      }
    </div>
  );
}

export default FormManual;
