import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  vitalId: yup.number().required(),
});

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    note: yup.string().required(),
    updated_at: yup.string().required(),
    modifier: yup
      .object({
        id: yup.number().required(),
        full_name: yup.string().required(),
      })
      .required(),
  }),
);

const composeResponse = (res) => {
  return res.data?.data || [];
};

const createPatientVitalNotesQueryKey = (vitalId) => {
  return [QUERY_KEYS.patientVitals.vitalNotes, vitalId];
};

export const usePatientVitalNotesQuery = ({ payload, options = {} } = {}) => {
  return useQuery(
    createPatientVitalNotesQueryKey(payload.vitalId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });

      const res = await http.get(
        HTTP_ENDPOINTS.patientVitals.getVitalNotes(req.vitalId),
      );

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
