const initialState = {
  klarnaAvailability: { status: "pending", data: null },
  createKlarnaPayment: { status: "pending", data: null },
  klarnaPaymentStatus: { status: "pending", data: null },
  ongoingKlarnaPayment: { status: "pending", data: null },
  notifyViaSMSKlarnaPayment: { status: "pending", data: null },
  notifyViaEmailKlarnaPayment: { status: "pending", data: null },
  cancelKlarnaPayment: { status: "pending", data: null },
};

const Klarna = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_KLARNA_PAYMENT": {
      return { ...state, createKlarnaPayment: action.payload };
    }
    case "FETCH_KLARNA_PAYMENT": {
      return { ...state, klarnaPaymentStatus: action.payload };
    }
    case "ONGOING_KLARNA_PAYMENT": {
      return { ...state, ongoingKlarnaPayment: action.payload };
    }
    case "NOTIFY_VIA_SMS_KLARNA_PAYMENT": {
      return { ...state, notifyViaSMSKlarnaPayment: action.payload };
    }
    case "NOTIFY_VIA_EMAIL_KLARNA_PAYMENT": {
      return { ...state, notifyViaEmailKlarnaPayment: action.payload };
    }
    case "CANCEL_KLARNA_PAYMENT": {
      return { ...state, cancelKlarnaPayment: action.payload };
    }
    case "KLARNA_AVAILABILITY": {
      return { ...state, klarnaAvailability: action.payload };
    }
    case "RESET_KLARNA_PAYMENT": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default Klarna;
