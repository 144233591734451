import React, { Component } from "react";
import { withRouter } from "react-router";

class SalesStatBoxes extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      reportSlug: props.reportSlug,
      languageData: languageData.business_insights,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.reportSlug !== undefined) {
      returnState.reportSlug = nextProps.reportSlug;
    }

    if (nextProps.reportData !== undefined) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    return (
      <div className="sale-row">
        <div className="salesStats">
          <div className="dash-box business-stats">
            <div className="inventry-title">
              {this.state.reportData &&
                this.state.reportData.sales_header &&
                this.state.reportData.sales_header.total_invoices}
            </div>
            <div className="dash-box-content">
              {this.state.languageData.bi_sales}
            </div>
          </div>
        </div>

        <div className="salesStats">
          <div className="dash-box business-stats">
            <div className="inventry-title">
              {this.state.reportData &&
                this.state.reportData.sales_header &&
                this.state.reportData.sales_header.avg_gross_sales}
            </div>
            <div className="dash-box-content">
              {this.state.languageData.bi_avg_gross_sales}
            </div>
          </div>
        </div>

        <div className="salesStats">
          <div className="dash-box business-stats">
            <div className="inventry-title">
              {this.state.reportData &&
                this.state.reportData.sales_header &&
                this.state.reportData.sales_header.gross_sales}
            </div>
            <div className="dash-box-content">
              {this.state.languageData.bi_gross_sales}
            </div>
          </div>
        </div>

        <div className="salesStats">
          <div className="dash-box business-stats">
            <div className="inventry-title">
              {this.state.reportData &&
                this.state.reportData.sales_header &&
                this.state.reportData.sales_header.avg_net_sales}
            </div>
            <div className="dash-box-content">
              {this.state.languageData.bi_avg_net_sales}
            </div>
          </div>
        </div>

        <div className="salesStats">
          <div className="dash-box business-stats">
            <div className="inventry-title">
              {this.state.reportData &&
                this.state.reportData.sales_header &&
                this.state.reportData.sales_header.net_sales}
            </div>
            <div className="dash-box-content">
              {this.state.languageData.bi_net_sales}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SalesStatBoxes);
