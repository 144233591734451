import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

function createProvidersByClinicIdsQueryKey({ clinicIds }) {
  return [QUERY_KEYS.providersByClinicIds, ...clinicIds];
}

export function useProvidersByClinicIdsQuery({ clinicIds }, options = {}) {
  const url = HTTP_ENDPOINTS.getProvidersByClinicIds();
  const config = {
    params: {
      clinic_ids: clinicIds,
    },
  };
  return useQuery(
    createProvidersByClinicIdsQueryKey({ clinicIds }),
    () => http.get(url, config),
    options,
  );
}
