import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { initializeClearentSdk } from "../../../../Utils/services";
import { LANGUAGE_DATA_KEYS } from "../../../../consts/localStorage";
import { USER_PAYMENT_SYSTEMS } from "../../../../consts/api";
import {
  getLanguageData,
  getUserPaymentSystem,
} from "../../../../utilities/localStorage";
import { EntityRow } from "../EntityRow";
import { Input } from "../../../../shared/Input/Input";
import { uiNotification } from "../../../../services/UINotificationService";
import { InputStripeCard } from "../../../../shared/InputStripeCard/InputStripeCard";

const CommonCCForm = forwardRef(
  (
    {
      isClearentZipError,
      clearentZip,
      onClearentZipChange,
      isClearentEmailError,
      clearentEmail,
      onClearentEmailChange,
      clearentClinic,
      stripeInstance,
      size,
      notShowEmail,
    },
    ref,
  ) => {
    const userPaymentSystem = getUserPaymentSystem();
    const appointmentLang = getLanguageData(LANGUAGE_DATA_KEYS.appointments);

    useEffect(() => {
      if (
        !isEmpty(stripeInstance) &&
        userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe
      ) {
        const elements = stripeInstance.elements();
        const cardNumber = elements.create("cardNumber");
        const cardExpiry = elements.create("cardExpiry");
        const cardCvc = elements.create("cardCvc");
        ref.current = cardNumber;

        cardNumber.mount("#card-number");
        cardExpiry.mount("#card-expiry");
        cardCvc.mount("#card-cvc");

        return () => {
          cardNumber.unmount();
          cardExpiry.unmount();
          cardCvc.unmount();
          ref.current = null;
        };
      }
    }, [stripeInstance, userPaymentSystem]);

    useEffect(() => {
      if (
        !isEmpty(clearentClinic) &&
        userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent
      ) {
        if (clearentClinic.publickey === null) {
          uiNotification.error("POS is not setup for this clinic.");
        } else {
          initializeClearentSdk(clearentClinic.publickey, "payment-form");
        }
      }
    }, [clearentClinic, userPaymentSystem]);

    return (
      <div>
        <EntityRow
          label={appointmentLang.appointment_credit_card_no}
          size={size}
        >
          <>
            {userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe && (
              <InputStripeCard
                cardNumberId="card-number"
                expiryId="card-expiry"
                cvcId="card-cvc"
                size={size}
              />
            )}
            <div id="payment-form" />
          </>
        </EntityRow>
        {userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent && (
          <>
            <EntityRow label="Zip" size={size}>
              <Input
                isError={isClearentZipError}
                placeholder="Enter Zip Code"
                name="clearent_zip"
                value={clearentZip}
                onChange={(e) => onClearentZipChange(e.target.value)}
                size={size}
              />
            </EntityRow>
            {!notShowEmail && (
              <EntityRow label="Email" size={size}>
                <Input
                  isError={isClearentEmailError}
                  placeholder="Enter Email Address"
                  name="clearent_email_id"
                  value={clearentEmail}
                  onChange={(e) => onClearentEmailChange(e.target.value)}
                  size={size}
                />
              </EntityRow>
            )}
          </>
        )}
      </div>
    );
  },
);

CommonCCForm.propTypes = {
  isClearentZipError: PropTypes.bool,
  clearentZip: PropTypes.string,
  onClearentZipChange: PropTypes.func,
  isClearentEmailError: PropTypes.bool,
  clearentEmail: PropTypes.string,
  onClearentEmailChange: PropTypes.func,
  clearentClinic: PropTypes.object,
  stripeInstance: PropTypes.object,
  size: PropTypes.oneOf(["small", "large"]),
};

CommonCCForm.defaultProps = {
  isClearentZipError: false,
  clearentZip: "",
  onClearentZipChange: () => {},
  isClearentEmailError: false,
  clearentEmail: "",
  onClearentEmailChange: () => {},
  clearentClinic: {},
  stripeInstance: {},
  size: "large",
};

export default CommonCCForm;
