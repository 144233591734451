/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchProcedureConsents,
  exportEmptyData,
} from "../../../Actions/Settings/settingsActions.js";
import {
  fetchSelectedConsentData,
  fetchAppointmentConsentData,
} from "../../../Actions/Clients/clientsAction.js";
import {
  displayName,
  capitalizeFirstLetter,
  showFormattedDate,
} from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import { passOr } from "../../../utilities/general.js";
import { LOCAL_STORAGE_KEYS } from "../../../consts/localStorage.js";
import { ConsentHeader } from "../../../boxes/ConsentHeader/ConsentHeader.js";
import { withCurrentAccountQuery } from "../../../api/queries/useAccountQuery.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const initCurrentConsent = (consentData, index) => {
  let currentConsent = {
    index: index ? index : 0,
    consent_name: "",
    consent_large_description: "",
    consent_large_description_array: "",
    consent_signature_url: "",
    witness_signature_url: "",
    initials_per_page: 0,
    consent_page_index: 0,
    consent_signature_date: "",
    witness_signature_date: "",
    witness_name: "",
    patient_name: "",
  };

  if (consentData && consentData.consent) {
    currentConsent.consent_name = consentData.consent.consent_name
      ? consentData.consent.consent_name
      : "";
    currentConsent.consent_large_description = consentData.consent
      .consent_large_description
      ? consentData.consent.consent_large_description
      : "";
    currentConsent.consent_large_description_array = consentData.consent
      .consent_large_description_array
      ? consentData.consent.consent_large_description_array
      : [];
    currentConsent.initials_per_page = consentData.consent.initials_per_page
      ? 1
      : 0;
    currentConsent.consent_signature_url = consentData.signature_thumb_image_url
      ? consentData.signature_thumb_image_url
      : consentData.signature_image_url
      ? consentData.signature_image_url
      : "";
    currentConsent.witness_signature_url =
      consentData.witness_signature_thumb_image_url
        ? consentData.witness_signature_thumb_image_url
        : consentData.witness_signature_url
        ? consentData.witness_signature_url
        : "";
    currentConsent.consent_signature_date =
      consentData.signed_on_format_date ||
      (consentData.signed_on
        ? showFormattedDate(consentData.signed_on, true)
        : "");
    currentConsent.witness_signature_date =
      currentConsent.consent_signature_date;
    currentConsent.witness_name = consentData?.witness_name || "";

    let consent_large_description_array = [];
    if (consentData.initials && consentData.initials.length > 0) {
      currentConsent.consent_large_description_array.map((obj, idx) => {
        let content_image = {};
        content_image.content = obj;
        if (consentData.initials.length > idx && consentData.initials[idx]) {
          content_image.image = consentData.initials[idx]
            .signature_thumb_image_url
            ? consentData.initials[idx].signature_thumb_image_url
            : consentData.initials[idx].signature_image_url
            ? consentData.initials[idx].signature_image_url
            : "";
        } else {
          content_image.image = currentConsent.consent_signature_url;
        }
        consent_large_description_array.push(content_image);
      });
    } else {
      let content_image = {
        content: currentConsent.consent_large_description,
        image: currentConsent.consent_signature_url,
      };
      consent_large_description_array.push(content_image);
    }
    currentConsent.consent_large_description_array =
      consent_large_description_array;
  }
  return currentConsent;
};

class ProcedureConsentsDetail extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    window.scrollTo(0, 0);
    this.state = {
      id: props.match.params.id,
      consentsAllData: [],
      consentSingleData: [],
      roomType: this.props.match.url.split("/")[1],
      action: props.match.params.type ? props.match.params.type : "pending",
      showLoader: true,
      globalLang: languageData.global,
      settingsLang: languageData.settings,
      returnTo: "",
      currentConsent: initCurrentConsent(),
      currentIndex: 0,
      consent_name: "",
      consent_large_description: "",
      consent_large_description_array: "",
      signature_thumb_image_url: "",
      witness_signature_url: "",
      initials_per_page: 0,
      consent_page_index: 0,
      consentID: props.match.params.id,
      clientID: props.match.params.clientID,
      consentList: [],
      client_name: "",
      procdure_name: "",
      type: props.match.params.type,
    };
    window.onscroll = () => {
      return false;
    };
  }
  componentDidMount() {
    this.setState({ showLoader: true });
    if (this.state.type == "appointment") {
      this.props.fetchAppointmentConsentData(this.state.id);
    } else {
      if (this.state.consentID) {
        if (this.props.match.params.consentID) {
          this.props.fetchSelectedConsentData(
            this.state.clientID,
            this.state.consentID,
          );
        } else {
          this.props.fetchProcedureConsents(this.state.id);
        }
      } else {
        this.props.fetchProcedureConsents(this.state.id);
      }
    }

    let returnTo = "";
    if (this.state.roomType && this.state.roomType === "clients") {
      returnTo = this.props.match.params.type
        ? "/" +
          this.state.roomType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.clientID
        : "/" + this.state.roomType;
    } else {
      returnTo = this.state.action
        ? "/" + this.state.roomType + "/" + this.state.action
        : "pending";
    }
    this.setState({ returnTo: returnTo });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.consentsData !== undefined &&
      nextProps.consentsData.status === 200 &&
      nextProps.consentsData.data.consents !== prevState.consentsAllData
    ) {
      nextProps.exportEmptyData();
      let currentConsent = initCurrentConsent(null, 0);
      let consentsAllData = [];
      consentsAllData = nextProps.consentsData.data.consents
        ? nextProps.consentsData.data.consents
        : [];
      let returnState = {
        procedureData: nextProps.consentsData.data.procedure_data,
        showLoader: false,
      };
      if (returnState.procedureData) {
        returnState.procedure_name = returnState.procedureData.procedure_name
          ? returnState.procedureData.procedure_name
          : "";
        returnState.client_name =
          returnState.procedureData.patient &&
          returnState.procedureData.patient.firstname
            ? returnState.procedureData.patient.firstname +
              (returnState.procedureData.patient.lastname
                ? " " + returnState.procedureData.patient.lastname
                : "")
            : "";
      }

      if (consentsAllData && consentsAllData.length > 0) {
        consentsAllData.map((obj, index) => {
          consentsAllData[index]["custom"] = initCurrentConsent(
            consentsAllData[index],
            index,
          );
        });
        currentConsent = initCurrentConsent(consentsAllData[0], 0);
      }
      returnState.consentsAllData = consentsAllData;
      returnState.currentConsent = currentConsent;

      const patient =
        nextProps.consentsData?.data?.patient ||
        nextProps.consentsData?.data?.procedure_data?.patient ||
        null;
      returnState.patient_name = patient
        ? capitalizeFirstLetter(displayName(patient))
        : "";

      returnState.patientBirthday = passOr(
        patient?.date_of_birth && moment(patient.date_of_birth).isValid(),
        null,
        () =>
          moment
            .utc(patient.date_of_birth)
            .format(localStorage.getItem(LOCAL_STORAGE_KEYS.dateFormat)),
      );

      return returnState;
    } else if (
      nextProps.consentsData !== undefined &&
      nextProps.consentsData.status !== 200 &&
      nextProps.consentsData.data &&
      nextProps.consentsData.data.consents !== prevState.consentsAllData
    ) {
      nextProps.exportEmptyData();
      return {
        consentsAllData: nextProps.consentsData.data.consents,
        procedureData: nextProps.consentsData.data.procedure_data,
        showLoader: false,
      };
    } else if (
      nextProps.consentsData !== undefined &&
      nextProps.consentsData.status !== 200 &&
      nextProps.consentsData.data === null &&
      nextProps.isRedirect === true
    ) {
      nextProps.history.push(prevState.returnTo);
    }
    return null;
  }

  showNextPrevious = (action) => {
    let returnState = {};
    let currentConsent = initCurrentConsent(null, 0);
    let currentIndex = this.state.currentConsent.index;
    if (this.state.consentsAllData && this.state.consentsAllData.length > 0) {
      window.scrollTo(0, 0);
      if (action == "previous" && currentIndex > 0) {
        currentIndex = currentIndex - 1;
        currentConsent = initCurrentConsent(
          this.state.consentsAllData[currentIndex],
          currentIndex,
        );
      } else if (
        action == "next" &&
        this.state.currentConsent.index + 1 < this.state.consentsAllData.length
      ) {
        currentIndex = currentIndex + 1;
        currentConsent = initCurrentConsent(
          this.state.consentsAllData[currentIndex],
          currentIndex,
        );
      }
    }
    returnState.currentConsent = currentConsent;
    this.setState(returnState);
  };

  showNextPage = (action, consentId, index, consentData) => {
    let currentConsent;
    if (consentId > 0 && consentData) {
      currentConsent = consentData;
    } else {
      currentConsent = this.state.currentConsent;
    }
    let consent_page_index = currentConsent.consent_page_index;
    if (action == "previous" && consent_page_index > 0) {
      consent_page_index = consent_page_index - 1;
    } else if (
      action == "next" &&
      currentConsent.consent_page_index + 1 <
        currentConsent.consent_large_description_array.length
    ) {
      consent_page_index = consent_page_index + 1;
    }
    currentConsent.consent_page_index = consent_page_index;
    if (consentId > 0 && consentData) {
      let consentsAllData = this.state.consentsAllData;
      consentsAllData[index]["custom"] = currentConsent;
      this.setState({ consentsAllData: consentsAllData });
    } else {
      this.setState({ currentConsent: currentConsent });
    }
  };

  setCollapse = (selectedConsentId, isSigned, event) => {
    event.preventDefault();
    if (isSigned) {
      let returnState = {};
      const state = this.state;
      Object.keys(state).forEach(function (key) {
        if (typeof key === "string" && key.startsWith("showConsent-")) {
          returnState[key] = false;
        }
      });
      returnState["showConsent-" + selectedConsentId] = true;
      this.setState(returnState);
    }
  };

  renderConsents = (consent, index, consentId) => {
    if (consent.consent_large_description) {
      return (
        <div
          className="accordion-discription-row p20"
          key={"renderConsents" + index}
        >
          <div className="col-xs-12 p-b-50">
            <ConsentHeader
              name={this.state.patient_name}
              birthDay={this.state.patientBirthday}
            />
            <div
              className="p-text"
              dangerouslySetInnerHTML={{
                __html:
                  consent.consent_large_description_array[
                    consent.consent_page_index ? consent.consent_page_index : 0
                  ]["content"],
              }}
            ></div>
          </div>

          {(consent.consent_signature_url ||
            (consent.consent_signature_url &&
              consent.consent_page_index + 1 ===
                consent.consent_large_description_array.length)) && (
            <>
              <div className="col-xs-6">
                {consent.witness_signature_url &&
                  consent.consent_page_index + 1 ===
                    consent.consent_large_description_array.length && (
                    <div className="div-consent-signature witness-signature">
                      <div>
                        <img
                          alt=""
                          src={consent.witness_signature_url}
                          className="consent-signature imp-witness-signature"
                        />
                      </div>
                      <b className="signed-date">
                        Date: {consent.witness_signature_date}
                      </b>
                      <label className="signed-label full-width">
                        Witness Signature
                      </label>
                      {consent?.witness_name ? (
                        <label className="signed-label full-width">
                          {consent?.witness_name}
                        </label>
                      ) : null}
                    </div>
                  )}
              </div>
              <div className="col-xs-6">
                {consent.consent_signature_url &&
                  consent.consent_page_index + 1 ===
                    consent.consent_large_description_array.length && (
                    <div className="div-consent-signature patient-signature pull-right">
                      <div>
                        <img
                          alt=""
                          src={consent.consent_signature_url}
                          className="consent-signature imp-patient-signature pull-right"
                        />
                      </div>
                      <b className="signed-date text-right pull-right">
                        Date: {consent.consent_signature_date}
                      </b>
                      <label className="signed-label text-right pull-left full-width">
                        {this.props.currentAccountQuery?.data?.preferences
                          ?.clientGlobalName || "Client"}{" "}
                        Signature
                      </label>
                      {this.state.patient_name ? (
                        <label className="signed-label text-right pull-left full-width">
                          {this.state.patient_name}
                        </label>
                      ) : null}
                    </div>
                  )}
              </div>
            </>
          )}
          {consent.consent_large_description_array.length > 1 && (
            <div className="col-xs-12 p-t-40">
              <div className="footer-static p-r-0 p-l-0">
                <div className="pull-right">
                  <button
                    disabled={
                      consent.consent_page_index == 0 ? "disabled" : false
                    }
                    className={
                      consent.consent_page_index == 0
                        ? "new-white-btn consent-next-prev"
                        : "new-blue-btn consent-next-prev"
                    }
                    type="button"
                    onClick={this.showNextPage.bind(
                      this,
                      "previous",
                      consentId,
                      index,
                      consent,
                    )}
                  >
                    <span>&#8249;</span>&nbsp; Prev
                  </button>
                  <label className="label-page-navigation">
                    {" "}
                    {`Page ${consent.consent_page_index + 1} of ${
                      consent.consent_large_description_array.length
                    }`}
                  </label>
                  <button
                    disabled={
                      consent.consent_page_index + 1 ==
                      consent.consent_large_description_array.length
                        ? "disabled"
                        : false
                    }
                    className={
                      consent.consent_page_index + 1 ==
                      consent.consent_large_description_array.length
                        ? "new-white-btn  m-l-10 consent-next-prev"
                        : "new-blue-btn m-l-10 consent-next-prev"
                    }
                    type="button"
                    onClick={this.showNextPage.bind(
                      this,
                      "next",
                      consentId,
                      index,
                      consent,
                    )}
                  >
                    {" "}
                    Next &nbsp;<span>&#8250;</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="wide-popup">
            <div className="modal-blue-header">
              <a
                onClick={() => this.props.history.goBack()}
                className="popup-cross"
              >
                ×
              </a>

              {this.state.showLoader === false && (
                <span className="popup-blue-name">
                  {this.state.client_name ? this.state.client_name + " - " : ""}
                  {this.state.procedure_name
                    ? this.state.procedure_name + " - "
                    : ""}
                  {"Consent"}
                </span>
              )}
            </div>
            <div className="wide-popup-wrapper time-line consent-wide-popup">
              <div
                className="accordion questionnaireAccordion"
                id="accordionExample"
              >
                {this.state.consentsAllData &&
                  this.state.consentsAllData.length > 0 &&
                  this.state.consentsAllData.map((obj, index) => {
                    if (obj.custom) {
                      return (
                        <div
                          key={"questionnaireList-" + index}
                          className="membershipAccordion"
                        >
                          <div
                            className="membershipAccordionHead"
                            id="headingOne"
                          >
                            <h2 className="mb-0">
                              <button
                                className="btn btn-link cursor-default"
                                type="button"
                                data-toggle={
                                  obj.custom.consent_signature_url
                                    ? "collapse"
                                    : ""
                                }
                                data-target={"#consent-" + obj.id}
                                aria-expanded="false"
                                aria-controls="collapseOne"
                              >
                                <span className="span">
                                  {index + 1}. {obj.custom.consent_name} {"-"}
                                </span>{" "}
                                <span
                                  onClick={this.setCollapse.bind(
                                    this,
                                    obj.id,
                                    obj.custom.consent_signature_url
                                      ? true
                                      : false,
                                  )}
                                  className={
                                    obj.custom.consent_signature_url
                                      ? "span easy-link no-padding"
                                      : "span easy-link no-padding disable"
                                  }
                                >
                                  {"Click to View"}
                                </span>
                                <span
                                  style={{ float: "right" }}
                                  className={
                                    "span easy-link no-padding cursor-pointer"
                                  }
                                >
                                  {obj.custom.consent_signature_url ? (
                                    <i className="fa fa-check m-r-5 text-success"></i>
                                  ) : (
                                    <i className="fa fa-times m-r-5 text-danger"></i>
                                  )}
                                </span>
                              </button>
                            </h2>
                          </div>
                          <div
                            id={"consent-" + obj.id}
                            className={
                              this.state["showConsent-" + obj.id] === true
                                ? "collapse show"
                                : "collapse"
                            }
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div className={"proQuesAccordionOuter"}>
                              {this.renderConsents(obj.custom, index, obj.id)}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>

          {this.state.currentConsent.consent_large_description && (
            <div className="wide-popup-wrapper time-line consent-wide-popup">
              <div className="row">
                <div className="col-xs-12">
                  <div className="setting-title">
                    <div className="juvly-title">
                      {this.state.currentConsent.consent_name}
                    </div>
                  </div>
                  <ConsentHeader
                    name={this.state.patient_name}
                    birthDay={this.state.patientBirthday}
                  />
                  <div
                    className="p-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.currentConsent
                          .consent_large_description_array[
                          this.state.currentConsent.consent_page_index
                            ? this.state.currentConsent.consent_page_index
                            : 0
                        ]["content"],
                    }}
                  ></div>
                </div>
              </div>

              {(this.state.currentConsent.consent_signature_url ||
                (this.state.currentConsent.consent_signature_url &&
                  this.state.currentConsent.consent_page_index + 1 ==
                    this.state.currentConsent.consent_large_description_array
                      .length)) && (
                <div className="row p-t-50">
                  <div className="col-xs-6">
                    {this.state.currentConsent.witness_signature_url &&
                      this.state.currentConsent.consent_page_index + 1 ==
                        this.state.currentConsent
                          .consent_large_description_array.length && (
                        <div className="div-consent-signature witness-signature">
                          <div>
                            <img
                              alt=""
                              src={
                                this.state.currentConsent.witness_signature_url
                              }
                              className="consent-signature imp-witness-signature"
                            />
                          </div>
                          <b className="signed-date">
                            Date:{" "}
                            {this.state.currentConsent.witness_signature_date}
                          </b>
                          <label className="signed-label full-width">
                            Witness Signature
                          </label>
                          {this.state.currentConsent?.witness_name ? (
                            <label className="signed-label full-width">
                              {this.state.currentConsent?.witness_name}
                            </label>
                          ) : null}
                        </div>
                      )}
                  </div>
                  <div className="col-xs-6">
                    {this.state.currentConsent
                      .consent_large_description_array &&
                      this.state.currentConsent.consent_large_description_array[
                        this.state.currentConsent.consent_page_index
                      ] &&
                      this.state.currentConsent.consent_large_description_array[
                        this.state.currentConsent.consent_page_index
                      ]["image"] && (
                        <div className="div-consent-signature patient-signature pull-right">
                          <div>
                            <img
                              alt=""
                              src={
                                this.state.currentConsent
                                  .consent_large_description_array[
                                  this.state.currentConsent.consent_page_index
                                ]["image"]
                              }
                              className="consent-signature imp-patient-signature pull-right"
                            />
                          </div>
                          <b className="signed-date text-right pull-right">
                            Date:{" "}
                            {this.state.currentConsent.consent_signature_date}
                          </b>
                          <label className="signed-label text-right pull-left full-width">
                            {this.props.currentAccountQuery?.data?.preferences
                              ?.clientGlobalName || "Client"}{" "}
                            Signature
                          </label>
                          {this.state.patient_name ? (
                            <label className="signed-label text-right pull-left full-width">
                              {this.state.patient_name}
                            </label>
                          ) : null}
                        </div>
                      )}
                  </div>
                </div>
              )}
              {this.state.currentConsent.consent_large_description_array
                .length > 1 && (
                <div className="row p-t-50">
                  <div className="col-xs-12">
                    <div className="footer-static p-r-0 p-l-0">
                      <div className="pull-right">
                        <button
                          disabled={
                            this.state.currentConsent.consent_page_index == 0
                              ? "disabled"
                              : false
                          }
                          className={
                            this.state.currentConsent.consent_page_index == 0
                              ? "new-white-btn consent-next-prev"
                              : "new-blue-btn consent-next-prev"
                          }
                          type="button"
                          onClick={this.showNextPage.bind(
                            this,
                            "previous",
                            0,
                            null,
                          )}
                        >
                          <span className="span">&#8249;</span>&nbsp; Prev
                        </button>
                        <label className="label-page-navigation">
                          {" "}
                          {`Page ${
                            this.state.currentConsent.consent_page_index + 1
                          } of ${
                            this.state.currentConsent
                              .consent_large_description_array.length
                          }`}
                        </label>
                        <button
                          disabled={
                            this.state.currentConsent.consent_page_index + 1 ==
                            this.state.currentConsent
                              .consent_large_description_array.length
                              ? "disabled"
                              : false
                          }
                          className={
                            this.state.currentConsent.consent_page_index + 1 ==
                            this.state.currentConsent
                              .consent_large_description_array.length
                              ? "new-white-btn  m-l-10 consent-next-prev"
                              : "new-blue-btn m-l-10 consent-next-prev"
                          }
                          type="button"
                          onClick={this.showNextPage.bind(
                            this,
                            "next",
                            0,
                            null,
                          )}
                        >
                          {" "}
                          Next &nbsp;<span className="span">&#8250;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <Loader showLoader={this.state.showLoader} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (state.SettingReducer.action === "PROCEDURE_CONSENTS") {
    if (state.SettingReducer.data.status === 200) {
      return {
        consentsData: state.SettingReducer.data,
      };
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      let returnState = { consentsData: state.SettingReducer.data };
      if (
        state.SettingReducer.data.message === "validation_procedure_id_invalid"
      ) {
        returnState.isRedirect = true;
      }
      return returnState;
    }
  }

  if (state.ClientsReducer.action === "GET_SELECTED_CONSENT_DETAILS") {
    if (state.ClientsReducer.data.status === 200) {
      return {
        consentsData: state.ClientsReducer.data,
      };
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      let returnState = { consentsData: state.ClientsReducer.data };
      if (
        state.ClientsReducer.data.message === "validation_procedure_id_invalid"
      ) {
        returnState.isRedirect = true;
      }
      return returnState;
    }
  }

  if (state.ClientsReducer.action == "GET_APPOINTMENT_CONSENTS") {
    if (state.ClientsReducer.data.status === 200) {
      return {
        consentsData: state.ClientsReducer.data,
      };
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
    }
  } else {
    return {};
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProcedureConsents: fetchProcedureConsents,
      exportEmptyData: exportEmptyData,
      fetchSelectedConsentData: fetchSelectedConsentData,
      fetchAppointmentConsentData: fetchAppointmentConsentData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCurrentAccountQuery(withRouter(ProcedureConsentsDetail)));
