import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { MIN_LOG_INTERVAL_MINUTES } from "../ManualTimeModal.consts";

const schema = yup.object({
  timeClockIn: yup.string().required("Select Clock In Time"),
  timeClockOut: yup
    .string()
    .test({
      name: "beforeOrEqualClockIn",
      message: "Clock out time cannot be the same or before clock in time",
      test: function (value) {
        if (!value) {
          return true;
        }
        const timeClockIn = this.parent.timeClockIn;
        return !moment(value).isSameOrBefore(moment(timeClockIn));
      },
    })
    .test({
      name: "moreThen1Hour",
      message: "You cannot log less then 1 hour",
      test: function (value) {
        if (!value) {
          return true;
        }
        const timeClockIn = this.parent.timeClockIn;
        const diff = moment(value).diff(moment(timeClockIn), "minutes");
        return diff >= MIN_LOG_INTERVAL_MINUTES;
      },
    })
    .required("Select Clock Out Time"),
});

export function useForm({ onSubmit }) {
  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      timeClockIn: "",
      timeClockOut: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit,
  });

  const hasError = (field) => {
    return Object.keys(errors).includes(field);
  };

  const getError = (field) => {
    return errors[field];
  };

  return {
    form: values,
    setFormValue: setFieldValue,
    submit: handleSubmit,
    hasError,
    getError,
  };
}
