import { useMemo } from "react";
import * as yup from "yup";
import { useAppointmentFeesQuery } from "../../../api/queries/useAppointmentFeesQuery";

const schema = yup.object({
  cancelationFee: yup.number(),
});

const compose = (response) => {
  let result = {};
  if (response && response.data.data) {
    result = {
      cancelationFee: Number(response.data.data.cancellation_fee) || 0,
    };
  }
  return result;
};

export function useApiAppointmentFees({ appointmentId }) {
  const { data, isFetching } = useAppointmentFeesQuery(
    { appointmentId },
    {
      staleTime: 0,
      enabled: Boolean(appointmentId),
    },
  );

  const appointmentFees = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  return {
    appointmentFees,
    isAppointmentFeesFetching: isFetching,
  };
}
