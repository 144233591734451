import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import { getIsAcceleratorPlan, isFormSubmit } from "../../../Utils";
import { getShopifyData, shareIntegrationShopifyData } from "../../../Actions/Integrations/integrationActions.js";
import { exportEmptyData } from '../../../Actions/Appointment/appointmentAction.js';
import UpgradeAccountModal from '../../Common/UpgradeAccountModal';
import moment from 'moment';
import { uiNotification } from '../../../services/UINotificationService.js';

class Shopify extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      showLoader: false,

      is_enabled: false,
      sync_products: false,
      shop_domain: '',
      admin_api_access_token: '',
      api_key: '',
      api_secret_key: '',

      isShowUpgradeAlertModal: !this.isIntegrationToggleEnabled(),
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });

    if (target.type === 'checkbox' && event.target.name === 'is_enabled') {
      let formData = {
        is_enabled: 0,
        sync_products: this.state.sync_products === true ? 1 : 0,
        shop_domain: this.state.shop_domain,
        admin_api_access_token: this.state.admin_api_access_token,
        api_key: this.state.api_key,
        api_secret_key: this.state.api_secret_key,
      };

      if (value) {
        formData.is_enabled = 1;
      }

      this.setState({ showLoader: true });
      this.props.shareIntegrationShopifyData(formData)
        .then(() => {
          if (formData.is_enabled === 0) {
            uiNotification.success("Shopify integration disabled successfully");
          } else {
            uiNotification.success("Shopify integration enabled successfully");
          }
        });
    }
  };

  handleSubmit = (event) => {
    if (isFormSubmit()) {
      event.preventDefault();
      const formData = {
        is_enabled: this.state.is_enabled === true ? 1 : 0,
        sync_products: this.state.sync_products === true ? 1 : 0,
        shop_domain: this.state.shop_domain,
        admin_api_access_token: this.state.admin_api_access_token,
        api_key: this.state.api_key,
        api_secret_key: this.state.api_secret_key,
      };

      this.setState({ showLoader: true });
      this.props.shareIntegrationShopifyData(formData)
        .then(() => {
          uiNotification.success("Shopify settings updated successfully");
        });
    }
  };

  componentDidMount() {
    if (this.isIntegrationToggleEnabled()) {
      this.setState({ showLoader: true });
      this.props.getShopifyData();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (nextProps.invokeRedirect) {
      nextProps.history.push('/settings/profile');
    }

    if (nextProps.syncData !== undefined && nextProps.syncDataTime !== prevState.syncDataTime) {
      returnState.showLoader = false;
      returnState.syncDataTime = nextProps.syncDataTime;
      returnState.is_enabled = nextProps.syncData.is_enabled === 1;
      returnState.sync_products = nextProps.syncData.sync_products === 1;
      returnState.shop_domain = nextProps.syncData.shop_domain;
      returnState.admin_api_access_token = nextProps.syncData.admin_api_access_token;
      returnState.api_key = nextProps.syncData.api_key;
      returnState.api_secret_key = nextProps.syncData.api_secret_key;
      returnState.updated_at = nextProps?.syncData?.updated_at;
    }

    if (nextProps.setSyncData !== undefined && nextProps.setSyncDataTime !== prevState.setSyncDataTime) {
      uiNotification.success(nextProps.message);
      returnState.showLoader = false;
      returnState.setSyncDataTime = nextProps.setSyncDataTime;
      returnState.is_enabled = nextProps.setSyncData.is_enabled === 1;
      returnState.sync_products = nextProps.setSyncData.sync_products === 1;
      returnState.shop_domain = nextProps.setSyncData.shop_domain;
      returnState.admin_api_access_token = nextProps.setSyncData.admin_api_access_token;
      returnState.api_key = nextProps.setSyncData.api_key;
      returnState.api_secret_key = nextProps.setSyncData.api_secret_key;
      returnState.updated_at = nextProps?.setSyncData?.updated_at;
    }

    return returnState;
  }

  handleShowUpgradeAlertModal = (state) => {
    this.setState({ isShowUpgradeAlertModal: state.isShowUpgradeAlertModal });
  };

  isIntegrationToggleEnabled = () => getIsAcceleratorPlan();

  render() {
    return (
      <div id="content">
        <UpgradeAccountModal notificaionType={'integration'}
          isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
          handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal} />
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">Sync Shopify
                <div className="memberRightActions">
                  <span className="switch-text"></span>
                  <label className="setting-switch pull-right">
                    <input type="checkbox" name="is_enabled" id="is_enabled"
                      disabled={!this.isIntegrationToggleEnabled()}
                      checked={this.state.is_enabled && this.isIntegrationToggleEnabled()}
                      onChange={this.handleInputChange} />
                    <span className="setting-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {(this.state.is_enabled === true && this.isIntegrationToggleEnabled()) &&
                <div className="col-sm-12">
                  <div className="setting-setion">
                    <div className="membershipSectionTitle">{this.state.settingsLang.label_sync_settings} </div>
                    <div className="wallet-edit-block">
                      <div className="membershipSectionContent row">
                        {this.state.updated_at && (
                          <p className="AuthorizedSync p-l-20">
                            <i className="fa fa-check-circle" />
                            Authorized on {moment(this.state.updated_at).format("MM/DD/YYYY hh:mm A")}
                          </p>
                        )}
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Shop url<span className="setting-require">*</span>
                            </div>
                            <div className="setting-input-outer">
                              <input
                                name="shop_domain"
                                type="text"
                                value={this.state.shop_domain}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                className="newInputField"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Admin API access token<span className="setting-require">*</span>
                            </div>
                            <div className="setting-input-outer">
                              <input
                                name="admin_api_access_token"
                                type="text"
                                value={this.state.admin_api_access_token}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                className="newInputField"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              API key<span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <input
                                name="api_key"
                                type="text"
                                value={this.state.api_key}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                className="newInputField"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              API secret key<span className="setting-require">*</span>
                            </div>
                            <div className="setting-input-outer">
                              <input
                                name="api_secret_key"
                                type="text"
                                value={this.state.api_secret_key}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                className="newInputField"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12">
                          <div className="setting-custom-switch product-active pull-left m-t-10">
                            <label className="setting-switch pull-left no-margin">
                              <input type="checkbox" className="setting-custom-switch-input" name="sync_products"
                                autoComplete="off" checked={this.state.sync_products}
                                onChange={this.handleInputChange} />
                              <span className="setting-slider"></span>
                            </label>
                            <span id="membership_lable"
                              className="m-l-15 radioLabel">Sync product stock</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-static">
                        <button className="new-blue-btn pull-right"
                          onClick={this.handleSubmit}>{this.state.globalLang.label_save}</button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  uiNotification.clear();
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.IntegrationReducer.data?.status === 402) {
    returnState.invokeRedirect = true;
  }

  if (state.IntegrationReducer.action === "GET_SHOPIFY_DATA") {
    if (state.IntegrationReducer.data?.status === 200 || state.IntegrationReducer.data?.status === 201) {
      returnState.syncData = state.IntegrationReducer.data.data;
      returnState.syncDataTime = new Date();
    } else {
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'SHARE_SHOPIFY_DATA') {
    if (state.IntegrationReducer.data?.status === 200 || state.IntegrationReducer.data?.status === 201) {
      returnState.setSyncData = state.IntegrationReducer.data.data;
      returnState.setSyncDataTime = new Date();
      returnState.message = languageData.global[state.IntegrationReducer.data.message];
    } else {
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    exportEmptyData: exportEmptyData,
    getShopifyData: getShopifyData,
    shareIntegrationShopifyData: shareIntegrationShopifyData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Shopify));
