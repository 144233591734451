import React from "react";
import {
  getTwilioConfig,
  isLoggedIn,
  getRedirectTo,
  getIsPosEnabled,
  getSubscriptionPlan,
  getIsAcceleratorPlan,
  getIsAccountTypePaid,
} from "./services.js";
import { acceleratorInsightsSales } from "./privilege.js";
import { checkPrivilegeLevel0 } from "./privilege-level.js";
import { dispatch } from "../store/store";
import { fetchNotificationsPopupsMenu } from "../Actions/Dashboard/dashboardActions";
import { getIsMainAdmin } from "../utilities/localStorage.js";
import { http } from "../services/HttpService.js";
import { uiNotification } from "../services/UINotificationService.js";

window.ClearentTokenSuccess = function (_, jsonResponse) {
  let jwt = jsonResponse.payload["mobile-jwt"].jwt;
  localStorage.setItem("clearentToken", jwt);
};

window.ClearentTokenError = function () {
  localStorage.removeItem("clearentToken");
};

let redirectTo = getRedirectTo();
let currenctPath = "";

const getModuleOrigin = (string) => {
  let moduleOrigin = "";
  string = string.split("/");

  if (string && string.length > 2 && string[1]) {
    moduleOrigin = string[1];
  }

  return moduleOrigin;
};

const getPathByIndex = (string, index) => {
  let path = "";
  string = string.split("/");

  if (string && string.length >= index && string[index]) {
    path = string[index];
  }

  return path;
};

export function mainAdminGuard(fn, fallbackFn) {
  if (getIsMainAdmin()) {
    return fn();
  } else {
    return fallbackFn ? fallbackFn() : undefined;
  }
}

const Authorization =
  (allowedPrivileges, { mainAdminOnly = false } = {}) =>
  (WrappedComponent) => {
    return class WithAuthorization extends React.Component {
      constructor(props) {
        super(props);
        currenctPath = props.location.pathname ? props.location.pathname : "";
        redirectTo = getRedirectTo();
        if (!isLoggedIn()) {
          this.props.history.push("/login");
        }

        let languageEndpoint = allowedPrivileges[allowedPrivileges.length - 1];
        languageEndpoint =
          [
            "manage-invoices",
            "manage-subscription",
            "practice-accelerator",
            "dispute-management-access",
          ].indexOf(languageEndpoint) > -1
            ? allowedPrivileges[allowedPrivileges.length - 2]
            : languageEndpoint;

        const tempLocalStorageData = JSON.parse(
          localStorage.getItem("languageData"),
        );

        this.state = localStorage.getItem("globalPrivileges")
          ? {
              permissions: JSON.parse(localStorage.getItem("globalPrivileges"))
                .permissions,
              currentUserRole: localStorage.getItem("currentUserRole"),
              userData: JSON.parse(localStorage.getItem("userData")),
              languageData: tempLocalStorageData,
              languageEndpoint: languageEndpoint,
            }
          : { permissions: {}, roles: {} };

        if (
          tempLocalStorageData === null ||
          tempLocalStorageData === undefined ||
          tempLocalStorageData[languageEndpoint] === null ||
          tempLocalStorageData[languageEndpoint] === undefined ||
          tempLocalStorageData[languageEndpoint] === ""
        ) {
          http.get("getLanguageText/1/" + languageEndpoint).then((response) => {
            tempLocalStorageData[languageEndpoint] =
              response.data.data[languageEndpoint];
            localStorage.setItem(
              "languageData",
              JSON.stringify(tempLocalStorageData),
            );
            this.setState({
              languageData: tempLocalStorageData,
              languageEndpoint: languageEndpoint,
            });
          });
        }

        if (getTwilioConfig()) {
          dispatch(fetchNotificationsPopupsMenu());
        }
      }

      changeRoute = () => {
        if (this.state.userData && this.state.userData.user) {
          let you_are_not_authorized_to_access_this_page_contact_your_admin =
            this.state.languageData &&
            this.state.languageData.global &&
            this.state.languageData.global
              .you_are_not_authorized_to_access_this_page_contact_your_admin
              ? this.state.languageData.global
                  .you_are_not_authorized_to_access_this_page_contact_your_admin
              : "You are not authorized to access this page. Please contact your admin!";

          uiNotification.clear();

          uiNotification.error(
            you_are_not_authorized_to_access_this_page_contact_your_admin,
          );

          if (redirectTo && redirectTo != currenctPath) {
            return <div>{this.props.history.push(redirectTo)}</div>;
          } else {
            return <div>{this.props.history.push("/settings/profile")}</div>;
          }
        } else {
          return <div>{this.props.history.push("/login")}</div>;
        }
      };

      componentDidMount() {
        let footer = document.getElementById("protected-footer-fixed");

        if (footer != null && footer != undefined) {
          setTimeout(function () {
            let clHeight = "";
            if (document.getElementById("protected-footer-fixed")) {
              let rootHeight = document.getElementById("root").clientHeight;

              clHeight = document.getElementById(
                "protected-footer-fixed",
              ).clientHeight;

              let footerHeight = clHeight;

              if (rootHeight + footerHeight > window.innerHeight) {
                footer.classList.remove("footer-fixed");
              } else {
                footer.classList.add("footer-fixed");
              }
            }
          }, 200);
        }
      }

      componentWillMount() {
        this.unlisten = this.props.history.listen(() => {});
      }

      componentWillUnmount() {
        this.unlisten();
      }

      componentDidCatch() {
        this.setState({ hasError: true });
      }

      render() {
        const { permissions, userData, languageData, languageEndpoint } =
          this.state;

        if (mainAdminOnly) {
          return mainAdminGuard(
            () => <WrappedComponent {...this.props} />,
            this.changeRoute,
          );
        }

        if (getIsMainAdmin()) {
          return <WrappedComponent {...this.props} />;
        }

        const conditionalEndpoint =
          allowedPrivileges[4] !== undefined &&
          allowedPrivileges[4] !== null &&
          allowedPrivileges[4] !== ""
            ? allowedPrivileges[4]
            : "";

        const conditionalPrivileges = [
          "manage-subscription",
          "manage-invoices",
          "dispute-management-access",
        ];

        let currentPrivilege = [];

        if (localStorage.getItem("currentUserRole") && userData?.user) {
          currentPrivilege =
            permissions?.[userData?.user?.role_name]?.[allowedPrivileges[1]] ||
            [];
        }

        const userPriviliges = userData?.permissions
          ? userData.permissions
          : [];

        const privilegeLevelParam = {
          routePrivileges: allowedPrivileges,
          userPriviliges: userPriviliges,
          rolePrivilege: currentPrivilege,
          languageData: languageData ? languageData : {},
          user: userData && userData.user ? userData.user : {},
          account: userData && userData.account ? userData.account : {},
        };

        const privilegeLevel = conditionalEndpoint.includes("privileges-level")
          ? conditionalEndpoint.substring(
              conditionalEndpoint.lastIndexOf("-") + 1,
              conditionalEndpoint.length,
            )
          : "";

        const curPath =
          this.props.history &&
          this.props.history.location &&
          this.props.history.location.pathname;

        if (privilegeLevel !== "") {
          if (checkPrivilegeLevel0(privilegeLevel, privilegeLevelParam)) {
            if (
              curPath === "/settings/pos" ||
              curPath === "/settings/pos-payment-settings"
            ) {
              if (userData && userData.user_type === "superadmin") {
                return <WrappedComponent {...this.props} />;
              } else {
                return this.changeRoute();
              }
            } else {
              return <WrappedComponent {...this.props} />;
            }
          } else {
            return this.changeRoute();
          }
        } else if (
          "dashboard" === allowedPrivileges[0] ||
          currentPrivilege?.find((str) => str === allowedPrivileges[0])
        ) {
          if (
            languageData &&
            languageData !== undefined &&
            languageData !== null
          ) {
            if (
              "dashboard" === allowedPrivileges[0] ||
              userPriviliges.find((str) => str === allowedPrivileges[0])
            ) {
              if (languageData[languageEndpoint] !== undefined) {
                if (conditionalEndpoint === "dashboard") {
                  let isDashEnabled =
                    userData && userData.user
                      ? userData.user.is_dashboard_enabled
                      : false;
                  if (userData && isDashEnabled === true) {
                    return <WrappedComponent {...this.props} />;
                  } else {
                    return this.changeRoute();
                  }
                }

                if (conditionalEndpoint === "practice-accelerator") {
                  if (getSubscriptionPlan() === "practice_accelerator") {
                    return <WrappedComponent {...this.props} />;
                  } else {
                    let moduleOrigin = getModuleOrigin(currenctPath);
                    let restrictedPrivilege = null;

                    switch (moduleOrigin) {
                      case "business-insight": {
                        let screenPath = getPathByIndex(currenctPath, 3);

                        restrictedPrivilege = acceleratorInsightsSales().find(
                          (x) => x.slug == screenPath,
                        );

                        break;
                      }
                    }

                    if (restrictedPrivilege) {
                      if (!getIsAcceleratorPlan()) {
                        if (
                          userData.user_type === "superadmin" &&
                          getIsAccountTypePaid() === false
                        ) {
                          return (
                            <div>
                              {this.props.history.push(
                                "/settings/manage-subscription",
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              {this.props.history.push(
                                "/settings/manage-subscription-upgrade",
                              )}
                            </div>
                          );
                        }
                      }
                    } else {
                      return <WrappedComponent {...this.props} />;
                    }
                  }
                }

                if (languageEndpoint === "sales") {
                  if (
                    getIsPosEnabled() === true &&
                    userPriviliges.indexOf("view-sales") > -1
                  ) {
                    return <WrappedComponent {...this.props} />;
                  } else {
                    return this.changeRoute();
                  }
                }

                return <WrappedComponent {...this.props} />;
              } else {
                return this.changeRoute();
              }
            } else {
              return this.changeRoute();
            }
          } else {
            return this.changeRoute();
          }
        } else if (
          (userData &&
            userData.user &&
            (allowedPrivileges[0] == userData.user.role_name ||
              allowedPrivileges[0] == userData.user_type ||
              (allowedPrivileges[0] === "provider" &&
                userData.user.is_provider))) ||
          conditionalPrivileges.indexOf(conditionalEndpoint) > -1 ||
          userData?.permissions?.indexOf("view-chart-audit")
        ) {
          if (languageData[languageEndpoint] !== undefined) {
            if (conditionalPrivileges.indexOf(conditionalEndpoint) > -1) {
              if (
                userPriviliges.indexOf("dispute-management-access") > -1 ||
                userData.user_type === "superadmin"
              ) {
                return <WrappedComponent {...this.props} />;
              }
              if (
                userData.user.account_id !=
                  process.env.REACT_APP_JUVLY_ACC_ID &&
                userData.user.account_id != process.env.REACT_APP_CC_ACC_ID &&
                (userPriviliges.indexOf("manage-subscription-details") > -1 ||
                  userData.user_type === "superadmin")
              ) {
                if (
                  conditionalEndpoint == "manage-subscription" ||
                  (conditionalEndpoint == "manage-invoices" &&
                    userData.account.account_subscription.account_code.indexOf(
                      "cus_",
                    ) > -1)
                ) {
                  return <WrappedComponent {...this.props} />;
                } else {
                  return this.changeRoute();
                }
              } else {
                return this.changeRoute();
              }
            }

            return <WrappedComponent {...this.props} />;
          } else {
            return this.changeRoute();
          }
        } else if (allowedPrivileges[0] == "access-all") {
          return <WrappedComponent {...this.props} />;
        } else {
          return this.changeRoute();
        }
      }
    };
  };

export default Authorization;
