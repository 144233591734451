import { usePatientVitalsSettingRangeMutation } from "../../../../../../../../../../api/patientVitals/usePatientVitalsSettingRangeMutation";
import { useAppTranslation } from "../../../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../../../services/UINotificationService";
import { extractApiError } from "../../../../../../../../../../utilities/api";
import { useSettingRanges } from "../../../../../../../hooks/useSettingRanges";

export const useSubmit = () => {
  const { tClients } = useAppTranslation.Clients();
  const settingRanges = useSettingRanges();

  const { isLoading, mutateAsync } = usePatientVitalsSettingRangeMutation({
    onError: (e) => {
      const error = extractApiError(e);
      uiNotification.error(
        tClients([
          `vitals.settings.apiError.${error}`,
          "vitals.settings.error.createBmi",
        ]),
      );
    },
  });

  const initiate = (formValues) => {
    const boundaryNode = settingRanges.data?.find(
      (v) => !v.end_value && v.id !== formValues.id,
    );

    if (boundaryNode && !formValues.max) {
      return uiNotification.error(
        tClients("vitals.settings.error.boundaryBmiDuplicate"),
      );
    }

    return mutateAsync({
      id: formValues.id || undefined,
      name: formValues.name,
      min: formValues.min,
      max: formValues.max || undefined,
      color: formValues.color,
    });
  };

  return {
    initiate,
    isLoading,
  };
};
