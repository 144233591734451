import { TIME_PERIODS } from "../consts/general";

export const makeDurationInTime = (duration, actualTime) => {
  const MIN_SEC = 60;
  const DIVIDER = ":";
  const REMNANT = "0";
  const DOUBLE_REMNANT = "00";

  const timeConvert = (n) => {
    var num = n;
    var hours = num / MIN_SEC;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * MIN_SEC;
    var rminutes = Math.round(minutes);

    return rhours + DIVIDER + rminutes;
  };

  if (typeof actualTime === "string") {
    let durationInHoursMinsArr = timeConvert(duration).split(DIVIDER);
    let hours = parseInt(durationInHoursMinsArr[0]);
    let mins = parseInt(durationInHoursMinsArr[1]);

    let actualTimeArr = actualTime.split(" ");
    let timeArr = actualTimeArr[0].split(DIVIDER);
    let finalHours = 0;
    let finalMins = 0;

    finalHours = parseInt(timeArr[0]) + hours;
    finalMins = parseInt(timeArr[1]) + mins;

    if (finalMins > MIN_SEC) {
      let finalDuration = timeConvert(finalMins).split(DIVIDER);
      finalHours = parseInt(finalHours) + parseInt(finalDuration[0]);
      finalMins = finalDuration[1];
    }

    if (finalMins == MIN_SEC) {
      finalHours = finalHours + 1;
      finalMins = DOUBLE_REMNANT;
    }

    if (finalMins == 0) {
      finalMins = DOUBLE_REMNANT;
    }

    if (finalMins < 10 && finalMins > 0) {
      finalMins = REMNANT + finalMins.toString();
    }

    if (parseInt(finalHours) > 12 && actualTimeArr[1] == TIME_PERIODS.am) {
      finalHours = finalHours - 12;
      actualTimeArr[1] = TIME_PERIODS.pm;
    } else if (
      parseInt(finalHours) > 12 &&
      actualTimeArr[1] == TIME_PERIODS.pm
    ) {
      finalHours = finalHours - 12;
    }

    if (finalHours == 12 && actualTimeArr[1] == TIME_PERIODS.am) {
      actualTimeArr[1] = TIME_PERIODS.pm;
    }

    if (finalHours <= 9) {
      finalHours = REMNANT + finalHours.toString();
    }

    const suffix = actualTimeArr[1] ? " " + actualTimeArr[1] : "";

    return finalHours + DIVIDER + finalMins + suffix;
  }

  return "";
};

export const formatUTC = (date) => {
  if (date) {
    const offset = date.getTimezoneOffset();
    const offsetDate = new Date();

    offsetDate.setTime(date.getTime() + offset * 60000);

    return offsetDate;
  }

  return date;
};
