/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import classes from "../ProviderAndMdChartAuditTab.module.scss";
import { Tabs } from "../../../../shared/Tabs/Tabs";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { PROVIDER_AND_MD_TAB_TYPE } from "../../../../api/queries/useChartAuditQuery";

export function ProviderAndMdChartAuditTab({ value, onChange, isActiveUser }) {
  const { tBi } = useAppTranslation.BusinessInsights();

  const tabs = [
    {
      label: tBi("chartAuditReport.tab.provider_audit"),
      type: PROVIDER_AND_MD_TAB_TYPE.provider,
    },
    {
      label: tBi("chartAuditReport.tab.md_audit"),
      type: PROVIDER_AND_MD_TAB_TYPE.md,
    },
  ];

  return (
    <>
      <Tabs
        className={classes.root}
        tabClassName={classes.tab}
        active={value}
        options={tabs.map((tab) => ({
          label: tab.label,
          value: tab.type,
          onClick: () => onChange("selectedType", tab.type),
        }))}
      />
      <span className="m-t-20 pull-right m-r-10">
        <span className={classes.tab}>
          {" "}
          {isActiveUser
            ? tBi("chartAuditReport.active")
            : tBi("chartAuditReport.inactive")}
        </span>
        <label className="setting-switch pull-right" htmlFor="active_user">
          <input
            type="checkbox"
            name="active_user"
            id="active_user"
            checked={isActiveUser}
            onChange={(e) => onChange("is_active", e.target.checked)}
          />
          <span className="setting-slider"></span>
        </label>
      </span>
    </>
  );
}

ProviderAndMdChartAuditTab.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isActiveUser: PropTypes.bool,
};
