import { useMutation } from "@tanstack/react-query";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { http } from "../../../../../../../services/HttpService";

export const useInvoiceToPending = () => {
  const { invoice } = useInvoice();
  return useMutation(() =>
    http.post(`stripe/update-invoice-to-pending`, { invoice_id: invoice.id }),
  );
};
