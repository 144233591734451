const KEY = "oneTimeIntroNotificationPopup";

export const scheduleIntroNotificationPopup = ({ id, message }) => {
  localStorage.setItem(KEY, JSON.stringify({ id, message }));
};

export const getScheduledIntroNotificationPopup = () => {
  try {
    return JSON.parse(localStorage.getItem(KEY));
  } catch {
    return null;
  }
};

export const removeIntroNotificationPopupSchedule = () => {
  localStorage.removeItem(KEY);
};
