import { useQuery } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { QueryKeys } from "./QueryKeys";
import { HTTP_ENDPOINTS } from "../../consts/api";

const GetUserProfileDetails = (id) => {
  return http.get(HTTP_ENDPOINTS.getUser(id), "profile");
};

export const useGetUserProfileDetailsQuery = (id) => {
  return useQuery(
    [QueryKeys.GET_USER_PROFILE, id],
    () => GetUserProfileDetails(id),
    {
      cacheTime: 0,
      enabled: Boolean(id),
    },
  );
};
