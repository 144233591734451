import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutPaymentForm from "./StripeCheckoutPaymentForm";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { useStripePaymentIntent } from "../../hooks/useStripePaymentIntent";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { Skeleton } from "../../../../../../../../shared/Skeleton/Skeleton";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeCheckoutPayment = () => {
  const { invoice } = useInvoice();

  const { data, mutateAsync: generateIntent } = useStripePaymentIntent({
    onError: (error) => {
      uiNotification.error(error.message);
    },
  });

  const options = {
    clientSecret: data?.clientSecret,
  };

  useEffect(() => {
    generateIntent({
      invoice_id: invoice.id,
      amount: invoice.totalAmount,
    });
  }, []);

  return data ? (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckoutPaymentForm stripeData={data} />
    </Elements>
  ) : (
    <Skeleton count={5} borderRadius="4px" height={43.42} />
  );
};

export default StripeCheckoutPayment;
