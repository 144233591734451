import React from "react";
import { Link } from "react-router-dom";
import RightIcon from "../../_legacy/images/arrow-next.svg";

const BreadCrumbs = (props) => {
  const { breadCrumbs } = props;
  return (
    <div className="items-center breadcrumbs">
      {breadCrumbs.map((historyItem, historyIndex) => {
        const { name, link } = historyItem;
        return historyIndex === breadCrumbs.length - 1 ? (
          <React.Fragment key={`breadcrumb-current-page`}>
            {name}
          </React.Fragment>
        ) : (
          <React.Fragment key={`breadcrumb-link-${name}`}>
            <Link to={link}>{name}</Link>
            <img src={RightIcon} alt=">" className="right-icon" />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
