import React from "react";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../widgets/EntityHeader/EntityHeader";
import { useHistory } from "./hooks/useHistory";
import { Table } from "./shared/Table/Table";
import styles from "./styles.module.scss";
import { usePageGuard } from "./hooks/usePageGuard";

export const BiGiftCardHistory = () => {
  usePageGuard();

  const { tBi } = useAppTranslation.BusinessInsights();
  const history = useHistory();

  return (
    <LayoutMain className={styles.root}>
      <EntityHeader withBackBtn title={tBi("giftCardHistory.title")} />
      <Table data={history.value} isLoading={history.isLoading} />
    </LayoutMain>
  );
};
