import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../shared/Button/Button";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { QUALIPHY_EXAM_STATUS } from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";
import { useQualiphyResendExamMutation } from "../../../../api/qualiphy/useQualiphyResendExamMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { extractApiError } from "../../../../utilities/api";

export function QualiphyStatusBtn({
  clinicId,
  appointmentId,
  status,
  onOpenPatientProfile,
  onOpenCreateExamModal,
  onRefreshSuccess,
  isLoading,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const resendMutation = useQualiphyResendExamMutation({
    onError: (e) => {
      const msg = extractApiError(e);
      uiNotification.error(
        tCommon([`apiError.qualiphy.${msg}`, "error.qualiphy.resend"]),
      );
    },
    onSuccess: onRefreshSuccess,
  });

  const resend = () => {
    resendMutation.mutate({
      clinicId: Number(clinicId),
      appointmentId: Number(appointmentId),
    });
  };

  const render = () => {
    if (isLoading) {
      return <Skeleton width="154px" height={36} />;
    }
    if (user?.account?.integrations?.isQualiphyEnabled) {
      const buttonsByExamStatus = {
        [QUALIPHY_EXAM_STATUS.notExist]: (
          <Button size="small" onClick={onOpenCreateExamModal}>
            {tCommon("appointmentDetailsModal.qualiphyBtn.notExist")}
          </Button>
        ),
        [QUALIPHY_EXAM_STATUS.sent]: (
          <Button color="gray" size="small" onClick={onOpenPatientProfile}>
            {tCommon("appointmentDetailsModal.qualiphyBtn.sent")}
          </Button>
        ),
        [QUALIPHY_EXAM_STATUS.received]: (
          <Button color="success" size="small" onClick={onOpenPatientProfile}>
            {tCommon("appointmentDetailsModal.qualiphyBtn.received")}
          </Button>
        ),
        [QUALIPHY_EXAM_STATUS.error]: (
          <Button
            color="error"
            size="small"
            onClick={onOpenPatientProfile}
            leftAdornment={
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
            }
          >
            {tCommon("appointmentDetailsModal.qualiphyBtn.error")}
          </Button>
        ),
        [QUALIPHY_EXAM_STATUS.expired]: (
          <Button
            color="error"
            size="small"
            onClick={resend}
            isDisabled={resendMutation.isLoading}
            leftAdornment={
              resendMutation.isLoading ? (
                <CircularProgress color="white" size="small" />
              ) : undefined
            }
          >
            {tCommon("appointmentDetailsModal.qualiphyBtn.expired")}
          </Button>
        ),
      };

      return buttonsByExamStatus?.[status] || null;
    }

    return null;
  };

  return render();
}

QualiphyStatusBtn.propTypes = {
  clinicId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  appointmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  status: PropTypes.string.isRequired,
  onOpenPatientProfile: PropTypes.func.isRequired,
  onOpenCreateExamModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
