import React from "react";
import classes from "./Header.module.scss";
import { Modal } from "../../../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../../shared/Button/Button";
import { PlusIcon } from "../../../../../../../assets/Icons/PlusIcon";

export const Header = ({ onCreate }) => {
  const { tClients } = useAppTranslation.Clients();

  return (
    <div className={classes.root}>
      <Modal.Title>{tClients("vitals.graph.title")}</Modal.Title>
      <Button
        size="small"
        variant="text"
        leftAdornment={<PlusIcon />}
        onClick={onCreate}
      >
        {tClients("vitals.graph.create")}
      </Button>
    </div>
  );
};
