import { http } from "../services/HttpService";

export const fetchCountrySuggestions = (searchText) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUGGESTIONS_LOADING" });
    http
      .get(`countries/suggestion?q=${searchText}&limit=5`)
      .then((response) => {
        dispatch({
          type: "FETCH_SUGGESTIONS_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch(() => {
        dispatch({ type: "FETCH_SUGGESTIONS_ERROR" });
      });
  };
};

export const resetCountrySuggestions = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_SUGGESTIONS_LOADING" });
  };
};
