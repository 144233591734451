"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.default = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
var _react = _interopRequireDefault(require("react"));
var _moment = _interopRequireDefault(require("./localizers/moment"));
var tConvert = function tConvert(time) {
  var timeFormat = localStorage.getItem("timeFormat");
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    if (timeFormat === "hh:mm A") {
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
  }
  delete time[3];
  if (time[0] < 10) {
    time[0] = '0' + Number(time[0]);
  }
  return time.join(''); // return adjusted time or original string
};
var Header = function Header(_ref) {
  var finalText = "";
  var finalHtml = {};
  var schedules = _ref.schedules;
  var label = _ref.label;
  var labelArr = label.split(" ");
  labelArr[0] + " <br/> " + labelArr[1];
  if (schedules && typeof schedules === 'object' && schedules.schedule) {
    if (typeof schedules.schedule[0] === 'object') {
      schedules.schedule.map(function (obj, idx) {
        finalText += '<br>Starts ' + tConvert(obj.from_time) + '<br> Ends ' + tConvert(obj.to_time);
      });
    }
  }
  finalHtml = {
    __html: finalText
  };
  var tagLine = [_react.default.createElement("span", {
    key: "labelspan"
  }, labelArr[0]), _react.default.createElement("p", {
    key: "labelp"
  }, labelArr[1]), _react.default.createElement("div", {
    className: "providerDuration",
    dangerouslySetInnerHTML: finalHtml,
    key: "labeldiv"
  })];
  return tagLine;
};
Header.propTypes = {
  label: _propTypes.default.node
};
var _default = Header;
exports.default = _default;
module.exports = exports["default"];