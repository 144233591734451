import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  patientIds: yup.array().of(yup.number().required()).required(),
});

export function useDeleteManyPatientsMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.delete(HTTP_ENDPOINTS.deleteManyPatients(), {
      data: {
        patient_ids: req.patientIds,
      },
    });
  }, options);
}
