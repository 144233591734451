import { useEfaxFavouriteCreateMutation } from "../../../../../../../api/efax/useEfaxFavouriteCreateMutation";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";

export const useCreate = () => {
  const { tSettings } = useAppTranslation.Settings();

  const { mutateAsync, isLoading } = useEfaxFavouriteCreateMutation({
    onError: () => {
      uiNotification.error(
        tSettings("efaxIntegration.favourites.error.create"),
      );
    },
    onSuccess: () => {
      uiNotification.success(
        tSettings("efaxIntegration.favourites.success.create"),
      );
    },
  });

  return {
    isLoading,
    initiate: ({ name, faxNumber, template }) =>
      mutateAsync({ name, faxNumber, template }),
  };
};
