import { useState } from "react";
import { getClearentToken } from "../../../../../Utils";
import { useSaveClientCcMutation } from "../../../../../api/mutations/useSaveClientCcMutation";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { USER_PAYMENT_SYSTEMS } from "../../../../../consts/api";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export function useMutateCard({
  clientId,
  cardId,
  stripeForm,
  clearentForm,
  onSuccess,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { data: account } = useCurrentAccountQuery();
  const [isMutating, setIsMutating] = useState(false);
  const { mutateAsync } = useSaveClientCcMutation();

  const getToken = async () => {
    if (account?.paymentSystem === USER_PAYMENT_SYSTEMS.stripe) {
      const stripeToken = await stripeForm.createToken();
      if (stripeToken) {
        return stripeToken;
      }
      throw new Error();
    } else if (account?.paymentSystem === USER_PAYMENT_SYSTEMS.clearent) {
      const cardDataToken = await getClearentToken();
      if (cardDataToken) {
        return cardDataToken;
      } 
      throw new Error();
    }
    throw new Error();
  };

  const initiate = async () => {
    try {
      setIsMutating(true);
      const token = await getToken();
      await mutateAsync({
        cardId,
        token,
        clientId: clientId ? Number(clientId) : null,
        clearentZip: clearentForm.values.zip,
        clearentEmail: clearentForm.values.email,
      });
      onSuccess();
    } catch (e) {
      if (cardId) {
        uiNotification.error(tCommon("error.updateCC"));
      } else {
        uiNotification.error(tCommon("error.addCC"));
      }
    } finally {
      setIsMutating(false);
    }
  };

  return { initiate, isLoading: isMutating };
}