import React from "react";
import { LayoutSettings } from "../../../boxes/LayoutSettings/LayoutSettings";
import { Box } from "../../../shared/Box/Box";
import { PageHeader } from "../../../boxes/PageHeader/PageHeader";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { getEditPathParams } from "./utils";
import { InputLabel } from "../../../shared/InputLabel/InputLabel";
import { Select } from "../../../shared/Select/Select";
import { Input } from "../../../shared/Input/Input";
import { Button } from "../../../shared/Button/Button";
import classes from "./styles.module.scss";
import { history } from "../../../history";
import { useForm } from "./hooks/useForm";
import { InputError } from "../../../shared/InputError/InputError";
import { useSubmit } from "./hooks/useSubmit";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";

export function SalesGoalMutate() {
  const { isEditMode } = getEditPathParams();

  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();

  const submit = useSubmit();
  const form = useForm(submit.initiate);

  return (
    <LayoutSettings>
      <Box>
        <PageHeader
          title={
            isEditMode
              ? tSettings("salesGoalMutate.updateTitle")
              : tSettings("salesGoalMutate.createTitle")
          }
        />
        <div className={classes.form}>
          <div>
            <InputLabel>{tCommon("label.clinic")}</InputLabel>
            <Select
              options={form.clinic.options}
              value={form.clinic.value}
              onChange={form.clinic.onChange}
              isError={!!form.clinic.getError()}
              isLoading={form.clinic.isLoading}
              isDisabled={form.isLoading}
            />
            {!!form.clinic.getError() && (
              <InputError>{form.clinic.getError()}</InputError>
            )}
          </div>
          <div className={classes.row}>
            <div>
              <InputLabel>{tCommon("label.year")}</InputLabel>
              <Select
                options={form.year.options}
                value={form.year.value}
                onChange={form.year.onChange}
                isError={!!form.year.getError()}
                isDisabled={form.isLoading}
              />
              {!!form.year.getError() && (
                <InputError>{form.year.getError()}</InputError>
              )}
            </div>
            <div>
              <InputLabel>{tCommon("label.month")}</InputLabel>
              <Select
                options={form.month.options}
                value={form.month.value}
                onChange={form.month.onChange}
                isError={!!form.month.getError()}
                isLoading={form.month.isLoading}
                isDisabled={form.isLoading}
              />
              {!!form.month.getError() && (
                <InputError>{form.month.getError()}</InputError>
              )}
            </div>
          </div>
          <div>
            <InputLabel>{tCommon("label.goal")}</InputLabel>
            <Input
              type="number"
              name="goal"
              onChange={form.goal.onChange}
              onBlur={form.goal.onBlur}
              value={form.goal.value}
              isError={!!form.goal.getError()}
              isDisabled={form.isLoading}
            />
            {!!form.goal.getError() && (
              <InputError>{form.goal.getError()}</InputError>
            )}
          </div>
          <div className={classes.row}>
            <Button variant="outlined" onClick={() => history.goBack()}>
              {tCommon("label.cancel")}
            </Button>
            <Button
              onClick={form.submit}
              isDisabled={form.isLoading || submit.isLoading || !form.dirty}
              leftAdornment={
                submit.isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              {isEditMode ? tCommon("label.save") : tCommon("label.add")}
            </Button>
          </div>
        </div>
      </Box>
    </LayoutSettings>
  );
}
