import { useFormik } from "formik";
import { useEffect } from "react";
import { SIMPLE_QUESTION_TYPES } from "../../../../../../../../api/questionnaires/config";
import { isNullish } from "../../../../../../../../utilities/general";
import { questionSchema } from "../../../hooks/useForm";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { useCheckAnswerDuplicates } from "../../../../../hooks/useCheckAnswerDuplicates";

function getDefaultQuestionChoice(questionType) {
  switch (questionType) {
    case SIMPLE_QUESTION_TYPES.multiText: {
      return {
        text: "",
      };
    }
    case SIMPLE_QUESTION_TYPES.multiImage: {
      return {
        image: "",
        label: "",
      };
    }
    default:
      return null;
  }
}

export function useForm({ onSubmit, preloadedQuestion }) {
  const { tSettings } = useAppTranslation.Settings();
  const checkAnswerDuplicates = useCheckAnswerDuplicates();

  const form = useFormik({
    enableReinitialize: true,
    validationSchema: questionSchema,
    initialValues: {
      question: preloadedQuestion?.question || "",
      questionType:
        preloadedQuestion?.questionType || SIMPLE_QUESTION_TYPES.yesNo,
      textChoices: preloadedQuestion?.textChoices || [],
      imageChoices: preloadedQuestion?.imageChoices || [],
      isMultipleSelection: preloadedQuestion?.isMultipleSelection || false,
      isDisplayLabels: preloadedQuestion?.isDisplayLabels || false,
    },
    onSubmit: (values) => {
      if (checkAnswerDuplicates(values.textChoices?.map((c) => c.text))) {
        onSubmit(values);
      }
    },
  });

  function getError(key) {
    if (form.touched[key] && form.errors[key]) {
      switch (key) {
        case "textChoices":
        case "imageChoices": {
          return tSettings("questionnaires.questionBuilder.choicesError");
        }
        default: {
          return form.errors[key];
        }
      }
    }
    return null;
  }

  function getDeleteChoiceByIndex(entity) {
    return function (index) {
      if (!isNullish(index)) {
        form.setFieldValue(entity, [
          ...form.values[entity].slice(0, index),
          ...form.values[entity].slice(index + 1),
        ]);
      }
    };
  }

  function getChangeChoiceByIndex(entity) {
    return function ({ key, value, index }) {
      if (form.values[entity][index]) {
        const optionToChange = { ...form.values[entity][index] };
        optionToChange[key] = value;
        const nextChoices = [...form.values[entity]];
        nextChoices[index] = optionToChange;
        form.setFieldValue(entity, nextChoices);
      }
    };
  }

  function getReplaceChoiceByIndex(entity) {
    return function (next) {
      form.setFieldValue(entity, next);
    };
  }

  useEffect(() => {
    if (preloadedQuestion && Object.values(form.touched).length === 0) {
      Object.keys(preloadedQuestion).forEach((key) =>
        form.setFieldTouched(key),
      );
    }
  }, [preloadedQuestion, form.touched]);

  return {
    ...form,

    getError,

    questionType: {
      value: form.values.questionType,
      onChange: (next) => {
        form.setFieldValue("questionType", next);
        form.setFieldValue("textChoices", [
          getDefaultQuestionChoice(SIMPLE_QUESTION_TYPES.multiText),
        ]);
        form.setFieldValue("imageChoices", [
          getDefaultQuestionChoice(SIMPLE_QUESTION_TYPES.multiImage),
        ]);
        form.setFieldValue("isMultipleSelection", false);
        form.setFieldValue("isDisplayLabels", false);
      },
    },

    isMultipleSelection: {
      value: form.values.isMultipleSelection,
      toggle: () => {
        form.setFieldValue(
          "isMultipleSelection",
          !form.values.isMultipleSelection,
        );
      },
    },

    isDisplayLabels: {
      value: form.values.isDisplayLabels,
      toggle: () => {
        form.setFieldValue("isDisplayLabels", !form.values.isDisplayLabels);
      },
    },

    textChoices: {
      value: form.values.textChoices,

      add: () => {
        if (
          form.values.textChoices.every((choice) =>
            Boolean(choice.text?.trim()),
          )
        ) {
          form.setFieldValue("textChoices", [
            ...form.values.textChoices,
            getDefaultQuestionChoice(SIMPLE_QUESTION_TYPES.multiText),
          ]);
        }
      },

      deleteByIndex: getDeleteChoiceByIndex("textChoices"),
      changeByIndex: getChangeChoiceByIndex("textChoices"),
      replace: getReplaceChoiceByIndex("textChoices"),
    },

    imageChoices: {
      value: form.values.imageChoices,

      add: () => {
        if (
          form.values.imageChoices.every((choice) =>
            Boolean(choice.image && choice.label?.trim()),
          )
        ) {
          form.setFieldValue("imageChoices", [
            ...form.values.imageChoices,
            getDefaultQuestionChoice(SIMPLE_QUESTION_TYPES.multiImage),
          ]);
        }
      },

      deleteByIndex: getDeleteChoiceByIndex("imageChoices"),
      changeByIndex: getChangeChoiceByIndex("imageChoices"),
      replace: getReplaceChoiceByIndex("imageChoices"),
    },
  };
}
