import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useDeleteTimeLogMutation } from "../../../../api/mutations/useDeleteTimeLogMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { extractApiError } from "../../../../utilities/api";

export function DeleteTimeLog({ id, refetchLogs }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { mutate, isLoading } = useDeleteTimeLogMutation({
    onSuccess: () => {
      uiNotification.success(tBi("timeCard.delete.success"));
      refetchLogs();
    },
    onError: (e) => {
      const key = extractApiError(e);
      uiNotification.error(
        tBi([`timeCard.delete.apiError.${key}`, "timeCard.delete.error"]),
      );
    },
  });

  return (
    <>
      <Button
        size="tiny"
        color="error"
        onClick={() => setIsConfirmModalOpen(true)}
      >
        {tBi("timeCard.delete.label")}
      </Button>
      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen
          onCancel={() => setIsConfirmModalOpen(false)}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={() => mutate({ logId: id })}
          isConfirming={isLoading}
        >
          {tBi("timeCard.delete.confirm")}
        </ConfirmModal>
      )}
    </>
  );
}

DeleteTimeLog.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  refetchLogs: PropTypes.func.isRequired,
};
