import React from "react";
import PropTypes from "prop-types";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useCreateLeadArShareReportMutation } from "../../../../api/mutations/useCreateLeadArShareReportMutation";

export function LeadArShare({
  reportId,
  insightId,
  isOpen,
  setIsOpen,
  reportFilter,
}) {
  const { tCommon } = useAppTranslation.Common();

  const shareReport = useCreateLeadArShareReportMutation({
    onSuccess: () => {
      setIsOpen(false);
      uiNotification.success(tCommon("success.shareLeadAr"));
    },
    onError: () => {
      uiNotification.error(tCommon("error.shareLeadAr"));
    },
  });

  const onClose = () => {
    if (!shareReport.isLoading) {
      setIsOpen(false);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      isConfirming={shareReport.isLoading}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() =>
        shareReport.mutate({
          reportId: reportId ? Number(reportId) : null,
          insightId: Number(insightId),
          reportFilter: !reportId ? reportFilter || {} : {},
        })
      }
    >
      {tCommon("confirm.shareLeadAr")}
    </ConfirmModal>
  );
}

LeadArShare.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  reportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  insightId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  reportFilter: PropTypes.object.isRequired,
};
