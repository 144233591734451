import { usePatientConsentsMarkOnFileMutation } from "../../../../api/consents/patientConsents/usePatientConsentsMarkOnFileMutation";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export function useMarkOnFile() {
  const { tCommon: t } = useAppTranslation.Common();

  const { mutate, isLoading } = usePatientConsentsMarkOnFileMutation({
    onError: () => {
      uiNotification.error(t("error.fallback"));
    },
    onSuccess: () => {
      uiNotification.success(t("patientConsents.success.update"));
    },
  });

  return {
    mark: (procedureId, consentId) => {
      mutate({
        procedureId,
        consentIds: [consentId],
        mark: true,
      });
    },

    unmark: (procedureId, consentId) => {
      mutate({
        procedureId,
        consentIds: [consentId],
        mark: false,
      });
    },

    isLoading,
  };
}
