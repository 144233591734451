import React from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import classes from "./Empty.module.scss";

export const Empty = ({ children }) => {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      {children || tCommon("info.nothingFound")}
    </div>
  );
};
