import React from "react";
import { withRouter } from "react-router";
import AllBdAspire from "./AllBdAspire";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import ClinicBdAspire from "./ClinicBdAspire";

const BdAspire = ({ match }) => {
  const { period, selectedClinic, apiPeriod } = useHeaderActions();

  return !match.params.subReport ? (
    <AllBdAspire
      period={period}
      apiPeriod={apiPeriod}
      selectedClinic={selectedClinic}
    />
  ) : (
    <ClinicBdAspire
      period={period}
      apiPeriod={apiPeriod}
      selectedClinic={selectedClinic}
    />
  );
};

export default withRouter(BdAspire);
