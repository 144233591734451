import React from "react";
import { Modal } from "../../../../../../../../../shared/Modal/Modal";
import styles from "./styles.module.scss";
import { useCurrentAccountQuery } from "../../../../../../../../../api/queries/useAccountQuery";

export function ModalProducts({ isOpen, onClose, products }) {
  const { data: account } = useCurrentAccountQuery();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={styles.root}
      header={<Modal.Title>Products</Modal.Title>}
    >
      {products.map((product) => (
        <div key={product.id} className={styles.product}>
          <div className={styles.inner}>
            <img alt={product.name} src={product.image} />
            <div className={styles.name}>{product.name}</div>
          </div>
          <div>
            <div className={styles.price}>
              {account.globalSettings.currencySymbol}
              {product.price}
            </div>
            <div className={styles.quantity}>Quantity: x{product.quantity}</div>
          </div>
        </div>
      ))}
    </Modal>
  );
}
