/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import cx from "clsx";
import { Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";
import { convertTime12to24, handleScheduleMasking } from "../../../Utils";
import CopyIcon from "../../../_legacy/images/copy.svg";
import DeleteIcon from "../../../_legacy/images/delete-dustbin.svg";
import PlusIcon from "../../../_legacy/images/plus.svg";
import { Button } from "../../../shared/Button/Button";
import { templateSchedule, weekTemplate } from "../../../_legacy/Constants";
import classes from "./sass/schedule.module.scss";
import { Input } from "../../../shared/Input/Input";

const SelectWeeklyHours = () => {
  const formMethods = useFormContext();

  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = formMethods;

  const setFormValue = (field, value, config) => {
    setValue(field, value, { shouldDirty: true, ...config });
  };

  const formValues = watch();

  const {
    clinicSchedules,
    copyDayScheduleToArray,
    isCopyScheduleDropdownOpen,
    viewAllClinics,
    allClinicsDictionary,
  } = formValues;

  const openCopyScheduleDropdown = async (dayIndex, isSelected) => {
    if (isSelected) {
      const isValid = await trigger(`clinicSchedules[${dayIndex}]`);
      if (isValid) {
        setFormValue(
          "isCopyScheduleDropdownOpen",
          isCopyScheduleDropdownOpen === dayIndex ? null : dayIndex,
        );
      }
    } else {
      const weekScheduleArray = [...clinicSchedules];
      weekScheduleArray[dayIndex].isSelected = true;
      setFormValue("clinicSchedules", weekScheduleArray);
    }
  };

  const addNewTimeSlotToDay = (event) => {
    const dayIndex = event.currentTarget.dataset.dayindex;

    const weekScheduleArray = [...clinicSchedules];
    if (!weekScheduleArray[dayIndex].isSelected) {
      weekScheduleArray[dayIndex].isSelected = true;
    }
    weekScheduleArray[dayIndex].timeSchedules = [
      ...weekScheduleArray[dayIndex].timeSchedules,
      templateSchedule,
    ];

    setFormValue("clinicSchedules", weekScheduleArray);
  };

  const toggleDayAvailability = (dayIndex) => {
    const updatedArray = [...clinicSchedules];
    if (updatedArray[dayIndex].isSelected) {
      const providerSchedule = { ...clinicSchedules[dayIndex] };
      const needToAddEmptyTemplateSchedle =
        providerSchedule.timeSchedules.length ===
        providerSchedule.timeSchedules.filter((x) => x.isDeleted).length;
      if (needToAddEmptyTemplateSchedle) {
        providerSchedule.timeSchedules.push(templateSchedule);
      }
      providerSchedule.isSelected = false;
      if (isCopyScheduleDropdownOpen === dayIndex) {
        setFormValue("isCopyScheduleDropdownOpen", null);
      }
      setFormValue(`clinicSchedules[${dayIndex}]`, providerSchedule);
    } else {
      updatedArray[dayIndex].isSelected = true;
      setFormValue("clinicSchedules", updatedArray);
    }
  };

  const setDayTime = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const {
      dayindex: dayIndex,
      scheduleindex: scheduleIndex,
      shouldvalidate,
    } = event.currentTarget.dataset;

    const editingSchedule = {
      ...clinicSchedules[dayIndex].timeSchedules[scheduleIndex],
    };

    editingSchedule[name] = value;

    switch (name) {
      case "fromTimeHour":
      case "fromTimeOption": {
        const { fromTimeHour, fromTimeOption } = editingSchedule;

        const convertedFromTime = convertTime12to24(
          [fromTimeHour, fromTimeOption].join(" "),
        );

        editingSchedule.fromTime =
          convertedFromTime === "00:00" ? null : convertedFromTime;

        break;
      }

      case "toTimeHour":
      case "toTimeOption": {
        const { toTimeHour, toTimeOption } = editingSchedule;

        const convertedToTime = convertTime12to24(
          [toTimeHour, toTimeOption].join(" "),
        );

        editingSchedule.toTime =
          convertedToTime === "00:00" ? null : convertedToTime;

        break;
      }

      default:
        break;
    }

    setFormValue(
      `clinicSchedules[${dayIndex}].timeSchedules[${scheduleIndex}]`,
      editingSchedule,
      {
        shouldValidate: Boolean(shouldvalidate),
      },
    );
  };

  const deleteScheduleItem = (
    dayIndex,
    scheduleIndex,
    shouldValidate = false,
  ) => {
    const providerSchedule = { ...clinicSchedules[dayIndex] };

    if (providerSchedule.timeSchedules[scheduleIndex].isAldreadyExistingSlot) {
      providerSchedule.timeSchedules[scheduleIndex].isDeleted = true;
    } else {
      providerSchedule.timeSchedules.splice(scheduleIndex, 1);
    }

    if (
      providerSchedule.timeSchedules.filter((x) => !x.isDeleted).length === 0
    ) {
      providerSchedule.timeSchedules = [
        templateSchedule,
        ...providerSchedule.timeSchedules,
      ];
      providerSchedule.isSelected = false;
      if (isCopyScheduleDropdownOpen === dayIndex) {
        setFormValue("isCopyScheduleDropdownOpen", null);
      }
    }

    setFormValue(`clinicSchedules[${dayIndex}]`, providerSchedule, {
      shouldValidate,
    });
  };

  const maskChange = (newState, _, userInput) => {
    const { value } = newState;
    const selection = newState.selection;
    const cursorPosition = selection ? selection.start : null;
    return handleScheduleMasking(value, selection, cursorPosition, userInput);
  };

  const copyDaySchedules = () => {
    const selectedDaySchedule = [
      ...clinicSchedules[isCopyScheduleDropdownOpen].timeSchedules,
    ];

    const providerSchedule = [...clinicSchedules];

    copyDayScheduleToArray.forEach((selectedDayIndex) => {
      providerSchedule[selectedDayIndex].isSelected = true;
      providerSchedule[selectedDayIndex].timeSchedules = [
        ...selectedDaySchedule,
      ];
    });

    setFormValue("isCopyScheduleDropdownOpen", null);
    setFormValue("copyDayScheduleToArray", []);
    setFormValue("clinicSchedules", providerSchedule);
  };

  const handleSelectDayForCopy = (selectedDayIndex) => {
    setFormValue(
      "copyDayScheduleToArray",
      copyDayScheduleToArray.includes(selectedDayIndex)
        ? copyDayScheduleToArray.filter(
            (dayIndex) => dayIndex !== selectedDayIndex,
          )
        : [...copyDayScheduleToArray, selectedDayIndex],
    );
  };

  return (
    <div>
      {/* Set Weekly Hours */}
      <div className={classes.selectTitle}>SET WEEKLY HOURS</div>
      <div className={classes.weekDaysSchedule}>
        {clinicSchedules.map((weekDayDetails, weekDayIndex) => {
          const { id, dayName, isSelected, timeSchedules } = weekDayDetails;

          return (
            <div
              className={cx(classes.daySchedule, "flex")}
              key={`schedule-weekday-${id}`}
            >
              <div className="row grow">
                <div className="col-xs-2">
                  <span
                    className={cx(
                      classes.scheduleWeekday,
                      "items-center cursor-pointer",
                    )}
                    onClick={() => toggleDayAvailability(weekDayIndex)}
                  >
                    <input
                      type="checkbox"
                      name={dayName}
                      checked={!!isSelected}
                      className="filled-checkbox"
                    />
                    <label>{dayName}</label>
                  </span>
                </div>
                {isSelected ? (
                  <>
                    <div
                      className={cx(
                        classes.dayScheduleSlots,
                        "col-xs-10 p-l-0 p-r-0 ",
                      )}
                    >
                      {timeSchedules.map(
                        (dayScheduleDetails, scheduleItemIndex) => {
                          const {
                            fromTimeHour,
                            toTimeHour,
                            fromTimeOption,
                            toTimeOption,
                            availableFor,
                            clinic_id,
                          } = dayScheduleDetails;

                          const {
                            fromTime: fromTimeError,
                            toTime: toTimeError,
                          } =
                            errors?.clinicSchedules?.[weekDayIndex]
                              ?.timeSchedules[scheduleItemIndex] || {};

                          return (
                            !dayScheduleDetails?.isDeleted && (
                              <div
                                className={cx(
                                  classes.scheduleRow,
                                  "fromandtoTime  no-padding row schedule-row",
                                )}
                                key={`timeSchedules-${scheduleItemIndex}`}
                              >
                                <div className={classes.scheduleTimeFields}>
                                  <div
                                    className={cx(classes.newInputFileldOuter, {
                                      newInputFileldOuter: !viewAllClinics,
                                      [classes.viewAllClinicsField]:
                                        viewAllClinics,
                                    })}
                                  >
                                    <InputMask
                                      name="fromTimeHour"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={fromTimeError}
                                      value={fromTimeHour}
                                      mask="99:99"
                                      className={cx(
                                        {
                                          [classes.timeFieldError]:
                                            fromTimeError,
                                        },
                                        classes.inputTime,
                                        `setting-input-box hours-time from-time-input `,
                                      )}
                                      placeholder="00:00"
                                      onChange={setDayTime}
                                      maskChar=""
                                      beforeMaskedValueChange={maskChange}
                                      autoComplete="off"
                                    />
                                    <select
                                      name="fromTimeOption"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={fromTimeError}
                                      value={fromTimeOption}
                                      className={cx(
                                        classes.inputTimeOption,
                                        {
                                          [classes.timeOptionFieldError]:
                                            fromTimeError,
                                        },
                                        `newSelectField hours-pm fromTimeOption `,
                                      )}
                                      onChange={setDayTime}
                                    >
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                    </select>
                                  </div>
                                  <div className={classes.newInputFileldOuter}>
                                    &nbsp;-&nbsp;
                                  </div>
                                  <div
                                    className={cx(classes.newInputFileldOuter, {
                                      newInputFileldOuter: !viewAllClinics,
                                      [classes.viewAllClinicsField]:
                                        viewAllClinics,
                                    })}
                                  >
                                    <InputMask
                                      name="toTimeHour"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={toTimeError}
                                      value={toTimeHour}
                                      mask="99:99"
                                      className={cx(
                                        classes.inputTime,
                                        {
                                          [classes.timeFieldError]: toTimeError,
                                        },
                                        `setting-input-box hours-time from-time-input`,
                                      )}
                                      placeholder="00:00"
                                      onChange={setDayTime}
                                      maskChar=""
                                      beforeMaskedValueChange={maskChange}
                                      autoComplete="off"
                                    />
                                    <select
                                      name="toTimeOption"
                                      data-dayindex={weekDayIndex}
                                      data-scheduleindex={scheduleItemIndex}
                                      data-shouldvalidate={toTimeError}
                                      value={toTimeOption}
                                      className={cx(
                                        classes.inputTimeOption,
                                        {
                                          [classes.timeOptionFieldError]:
                                            toTimeError,
                                        },
                                        `newSelectField hours-pm fromTimeOption `,
                                      )}
                                      onChange={setDayTime}
                                    >
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                    </select>
                                  </div>
                                </div>

                                <div
                                  className={cx(
                                    classes.newInputFileldOuter,
                                    classes.appointmentTypeField,
                                    {
                                      newInputFileldOuter: !viewAllClinics,
                                    },
                                  )}
                                >
                                  <select
                                    name="availableFor"
                                    data-dayindex={weekDayIndex}
                                    data-scheduleindex={scheduleItemIndex}
                                    value={availableFor}
                                    className={cx(
                                      classes.appointmentTypeSelect,
                                      " newSelectField ",
                                    )}
                                    onChange={setDayTime}
                                  >
                                    <option value="both">
                                      All Appointment Types
                                    </option>
                                    <option value="inperson">
                                      In-Person Only
                                    </option>
                                    <option value="virtual">
                                      Virtual Only
                                    </option>
                                  </select>
                                </div>

                                {viewAllClinics ? (
                                  <div>
                                    <Input
                                      size="small"
                                      type="text"
                                      value={
                                        allClinicsDictionary[clinic_id]
                                          ?.clinic_name
                                      }
                                      className={classes.clinicNameField}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={classes.deleteScheduleItem}
                                    onClick={() =>
                                      deleteScheduleItem(
                                        weekDayIndex,
                                        scheduleItemIndex,
                                        Boolean(fromTimeError || toTimeError),
                                      )
                                    }
                                  >
                                    <img src={DeleteIcon} alt="delete" />
                                  </div>
                                )}
                              </div>
                            )
                          );
                        },
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className={cx(
                      classes.scheduleUnavailable,
                      "col-xs-10 p-l-0 p-r-0",
                    )}
                  >
                    <span className="">Unavailable</span>
                  </div>
                )}
              </div>
              {!viewAllClinics && (
                <div className={classes.dayActions}>
                  <span
                    onClick={addNewTimeSlotToDay}
                    data-dayindex={weekDayIndex}
                  >
                    <img src={PlusIcon} alt="new" />
                  </span>

                  <Dropdown
                    open={isCopyScheduleDropdownOpen === weekDayIndex}
                    dropup={isCopyScheduleDropdownOpen > 4}
                  >
                    <Dropdown.Menu className={classes.dropdownMenu}>
                      <div className={classes.dropdownContent}>
                        <div className={classes.menuTitle}>COPY TIMES TO</div>
                        <>
                          {weekTemplate.map((weekDayDetails, dayIndex) => {
                            const { dayName } = weekDayDetails;
                            const isDaySelected =
                              copyDayScheduleToArray.includes(dayIndex);

                            return (
                              dayIndex !== isCopyScheduleDropdownOpen && (
                                <div
                                  className={classes.dayOption}
                                  onClick={() =>
                                    handleSelectDayForCopy(dayIndex)
                                  }
                                >
                                  <span>{dayName}</span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name={dayName}
                                      checked={isDaySelected}
                                      className="filled-checkbox"
                                    />
                                  </span>
                                </div>
                              )
                            );
                          })}
                          <Button
                            fullWidth
                            size="small"
                            onClick={copyDaySchedules}
                            className={classes.copyDayScheduleButton}
                          >
                            Apply
                          </Button>
                        </>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  <span
                    className="dropdown-toggle"
                    onClick={() =>
                      openCopyScheduleDropdown(weekDayIndex, isSelected)
                    }
                  >
                    <img src={CopyIcon} alt="copy" />
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectWeeklyHours;
