import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./ModalCharge.module.scss";

export function InputWrap({ children, fullWidth, width, className }) {
  return (
    <div
      style={{ width }}
      className={cx(
        classes.inputWrap,
        {
          [classes.inputWrapFullWidth]: !width && fullWidth,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

InputWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  fullWidth: PropTypes.bool,
  width: PropTypes.string,
  className: PropTypes.string,
};

InputWrap.defaultProps = {
  fullWidth: false,
  width: undefined,
  className: undefined,
};
