import React, { useState } from "react";
import { Button } from "../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { ModalAssignServices } from "../../../../../widgets/ModalAssignServices/ModalAssignServices";
import { ASSIGN_TO_SERVICES_MODEL_TYPE } from "../../../../../api/mutations/useAssignServiceToModelMutation";
import { SERVICES_BY_CATEGORIES_MODEL_TYPE } from "../../../../../api/queries/useServicesGroupedByCategoriesQuery";
import { useQuestionnaire } from "../../hooks/useQuestionnaire";
import { PageHeader } from "../../../../../boxes/PageHeader/PageHeader";

export function Header({ questionnaireId, title }) {
  const isEditMode = Boolean(questionnaireId);
  const { tCommon } = useAppTranslation.Common();
  const questionnaire = useQuestionnaire(questionnaireId);

  const [isAssignToServicesModalOpen, setIsAssignToServicesModalOpen] =
    useState(false);

  return (
    <>
      <PageHeader
        title={title}
        rightAdornment={
          isEditMode ? (
            <Button
              size="small"
              variant="outlined"
              isDisabled={questionnaire.isLoading}
              onClick={() => setIsAssignToServicesModalOpen(true)}
            >
              {tCommon("label.assignToServices")}
            </Button>
          ) : undefined
        }
      />
      {isAssignToServicesModalOpen && (
        <ModalAssignServices
          isOpen
          title={tCommon("label.assignServicesToQuestionnaire")}
          id={questionnaireId}
          modelType={ASSIGN_TO_SERVICES_MODEL_TYPE.questionnaire}
          servicesType={SERVICES_BY_CATEGORIES_MODEL_TYPE.cosmeticQuestionnaire}
          pickedIds={
            questionnaire.data?.assigned_services?.map((s) => s.id) || []
          }
          onClose={() => setIsAssignToServicesModalOpen(false)}
          onSuccess={questionnaire.refetch}
        />
      )}
    </>
  );
}
