import { useUpdateInvoiceClinicMutation } from "../../../../../api/mutations/useUpdateInvoiceClinicMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export function useSubmit({ invoiceId, onSuccess }) {
  const { tSales } = useAppTranslation.Sales();

  const { mutate, isLoading } = useUpdateInvoiceClinicMutation({
    onError: (error) => {
      uiNotification.error(
        error.response?.data?.message ||
          tSales("invoice.changeClinic.error.update"),
      );
    },
    onSuccess: () => {
      onSuccess();
      uiNotification.success(tSales("invoice.changeClinic.success.update"));
    },
  });

  const initiate = (clinicId) => {
    mutate({ invoiceId: Number(invoiceId), clinicId });
  };

  return {
    initiate,
    isLoading,
  };
}
