import { useQualiphyEnrolledClinicsQuery } from "../../../api/qualiphy/useQualiphyEnrolledClinicsQuery";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";

export function useEnrolledClinics() {
  const { tCommon } = useAppTranslation.Common();

  const enrolledClinicsQuery = useQualiphyEnrolledClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.qualiphy.getEnrolledClinics"));
    },
  });

  const clinicOptions =
    enrolledClinicsQuery.data?.map((c) => ({
      label: c.name,
      value: c.id,
    })) || [];

  return {
    clinicOptions,
    isClinicsFetching: enrolledClinicsQuery.isFetching,
  };
}
