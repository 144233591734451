import React from "react";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import classes from "./Qualiphy.module.scss";
import { EnrollClinic } from "./widgets/EnrollClinic/EnrollClinic";
import { EnrolledClinics } from "./widgets/EnrolledClinics/EnrolledClinics";
import { Info } from "./shared/Info/Info";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { useIsEnabled } from "./hooks/useIsEnabled";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

export function Qualiphy() {
  const { tSettings } = useAppTranslation.Settings();
  const { isEnabled, toggleEnabled, isLoading } = useIsEnabled();

  return (
    <LayoutSettings contentClassName={classes.root}>
      <EntityHeader
        title={tSettings("qualiphyIntegration.title")}
        right={
          <div className={classes.toggleWrap}>
            {isLoading && <CircularProgress size="small" color="gray1" />}
            <Toggle
              isEnabled={isEnabled}
              onChange={toggleEnabled}
              isDisabled={isLoading}
            />
          </div>
        }
      />
      <Info />
      {isEnabled && (
        <>
          <EnrollClinic />
          <EnrolledClinics />
        </>
      )}
    </LayoutSettings>
  );
}
