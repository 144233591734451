import React, { useState } from "react";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useDeletePosConnectionMutation } from "../../../../../api/mutations/useDeletePosConnectionMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import RestrictionModal from "../../../ManageClinics/Clinics/RestrictionModal";

export function DeleteConnection({ clinicId, onSuccess }) {
  const { tCommon } = useAppTranslation.Common();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [showErrorDisablePosModal, setShowErrorDisablePosModal] =
    useState(false);

  const { mutate, isLoading } = useDeletePosConnectionMutation({
    onError: (error) => {
      if (
        error.response.data.message === "disconnect_pos_active_membership_error"
      ) {
        setShowErrorDisablePosModal(true);
      } else {
        uiNotification.error(tCommon("error.deletePosConnection"));
      }
    },
    onSuccess: () => {
      uiNotification.success(tCommon("success.deletePosConnection"));
      onSuccess();
    },
  });

  const handleConfirm = () => {
    setIsConfirmModalOpen(false);
    mutate({ clinicId: Number(clinicId) });
  };

  return (
    <>
      <button
        onClick={() => setIsConfirmModalOpen(true)}
        className="link-btn d-flex align-center gap-8 color-error"
        disabled={isLoading}
      >
        {isLoading && <CircularProgress size="tiny" color="red" />}
        {tCommon("label.disconnectAccount")}
      </button>
      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onCancel={() => setIsConfirmModalOpen(false)}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleConfirm}
        >
          {tCommon("confirm.deletePosConnection")}
        </ConfirmModal>
      )}
      {showErrorDisablePosModal && (
        <RestrictionModal closeModal={() => setShowErrorDisablePosModal(false)}>
          <p className="text-center">
            {tCommon("apiError.disconnect_pos_active_membership_error")}
          </p>
        </RestrictionModal>
      )}
    </>
  );
}
