import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { Table } from "../../../../../shared/Table/Table";
import { useDiscountByClinic } from "./hooks/useDiscountByClinic";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import Header from "../../../components/Header";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "discountsReport.info.title",
  rows: ["discountsReport.info.note"],
};

const DiscountByClinic = ({
  match,
  history,
  period,
  selectedClinic,
  apiPeriod,
}) => {
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id: match.params.subReport !== 0 ? [match.params.subReport] : [],
  };
  const { data, isLoading } = useDiscountByClinic(requestParams, {
    cacheTime: 0,
  });
  const { data: user } = useCurrentUserQuery();

  const tableDataEmpty = !isLoading && !data?.reportData?.length;

  const tableData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        discountName: data.discount_name,
        timesApplied: data.times_applied,
        discountAmount: data.amount,
      })),
    [data],
  );

  const discountPeriod = `${moment(period.value.fromDate).format(
    user?.dateFormat,
  )} - ${moment(period.value.toDate).format(user?.dateFormat)}`;

  useEffect(() => {
    if (!selectedClinic.value.value) {
      history.push("/refactor-business-insight/sales/discounts");
    }
  }, []);

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title={`Discount - ${selectedClinic.value.label} (${discountPeriod})`}
        selectedClinic={selectedClinic}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getDiscountByClinic()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Discount Name", accessor: "discountName" },
              { data: "Times Applied", accessor: "timesApplied" },
              { data: "Discount Amount", accessor: "discountAmount" },
            ]}
            data={tableData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(DiscountByClinic);
