import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  id: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  lastModifiedBy: yup.string().nullable(),
  content: yup.string().required(),
});

const composeResponse = (res) => {
  return res?.data?.data || {};
};

const createEfaxTemplateQueryKey = (id) => [QUERY_KEYS.efax.template, id];

export const useEfaxTemplateQuery = (payload = {}, options = {}) => {
  return useQuery(
    createEfaxTemplateQueryKey(payload.id),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });

      const res = await http.get(HTTP_ENDPOINTS.efax.getTemplate(req.id));

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
