import React from "react";
import { AlertBox } from "../../../../shared/AlertBox/AlertBox";

export const SmsChargeDisclaimer = ({ className }) => {
  return (
    <AlertBox size="small" className={className}>
      Text messages are charged by segment, and segments are based on character
      count, line breaks and emojis. Find the expected segment count for your
      message by{" "}
      <a
        className="link-btn"
        href="https://twiliodeved.github.io/message-segment-calculator/"
        target="_blank"
        rel="noopener noreferrer"
      >
        CLICKING HERE.
      </a>
    </AlertBox>
  );
};
