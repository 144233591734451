import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import classes from "./scss/quickbooks.module.scss";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useEnableQuickBooksIntegration } from "./hooks/useEnableQuickBooksIntegration";
import QuickBooksForm from "./components/QuickBooksForm";
import { useQuickBooksCredentials } from "./hooks/useQuickBooksCredentials";
import { uiNotification } from "../../../../services/UINotificationService";
import { useQuickBookTokens } from "./hooks/useQuickBooksTokens";
import { Button } from "../../../../shared/Button/Button";
import { svg } from "../../../../assets/svg";
import { INFO_URL } from "./QuickBooks.consts";

const QuickBooks = ({ history, location }) => {
  const { tSettings } = useAppTranslation.Settings();
  const urlParams = new URLSearchParams(location.search);

  const [isEnabled, setIsEnabled] = useState(false);

  const toggleEnabled = () => {
    mutate({ enabled: !isEnabled });
  };

  const { data: credentials } = useQuickBooksCredentials({
    cacheTime: 0,
    onSuccess: (data) => setIsEnabled(Boolean(data.data.enabled)),
  });

  const { mutate } = useEnableQuickBooksIntegration({
    onSuccess: (data) => {
      setIsEnabled(!isEnabled);
      uiNotification.success(tSettings(data.data.message));
    },
  });

  const { mutate: saveTokens } = useQuickBookTokens({
    onSuccess: (data) => {
      history.replace(location.pathname);
      uiNotification.success(data.data.message);
    },
  });

  useEffect(() => {
    const code = urlParams.get("code");
    const realmId = urlParams.get("realmId");

    if (code && realmId) {
      saveTokens({
        code,
        realmId,
      });
    }
  }, []);

  return (
    <LayoutSettings contentClassName={classes.root}>
      <EntityHeader
        title={tSettings("quickBooksIntegration.title")}
        left={
          <Button
            size="small"
            variant="text"
            className={classes.infoBtn}
            onClick={() => window.open(INFO_URL, "_blank")}
          >
            <img src={svg.infoPrimary} alt="info" width="20px" />
          </Button>
        }
        right={
          <div className={classes.toggleWrap}>
            {false && <CircularProgress size="small" color="gray1" />}
            <Toggle
              isEnabled={isEnabled}
              onChange={toggleEnabled}
              isDisabled={false}
            />
          </div>
        }
      />
      {Boolean(isEnabled) && (
        <QuickBooksForm
          credentials={{
            clientId: credentials.data.client_id,
            clientSecret: credentials.data.client_secret,
            refreshToken: credentials.data.refresh_token,
            updatedAt: credentials.data.updated_at,
          }}
        />
      )}
    </LayoutSettings>
  );
};

export default withRouter(QuickBooks);
