import React from "react";
import { useClinicsQuery } from "../../../../api/queries/useClinicsQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { Tree } from "../../shared/Tree";

export function ClinicsList({
  inboxClinicId,
  handleClinicSelection,
  navPath,
  selectedClinicId,
}) {
  const { tCommon } = useAppTranslation.Common();

  const { data } = useClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchClinics"));
    },
  });

  if (data?.length > 0) {
    return (
      <Tree
        name={"Clinic"}
        styleClass={`new-setting-tabs-li`}
        styleLinkClass="parent_class new-setting-subtabs-a"
        childClass={"setting-submenu child"}
        activeMenu={true}
      >
        {data.map((clinic) => (
          <Tree
            name={`- ${clinic.name}`}
            styleClass="new-setting-subtabs-li"
            styleLinkClass={`new-setting-subtabs-a text-capitalize p-l-30 ${
              clinic.id == inboxClinicId || clinic.id == selectedClinicId
                ? "sel-submenu"
                : ""
            }`}
            navigateTo={navPath + clinic.id}
            key={clinic.id}
            handleClinicSelection={handleClinicSelection}
            clinicObj={{
              id: clinic.id,
              clinic_name: clinic.name,
            }}
          />
        ))}
      </Tree>
    );
  }

  return null;
}
