import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { QUERY_KEYS } from "../../../../../../../consts/api";
import { useSetGiftCardsObOrderMutation } from "../../../../../../../api/mutations/useSetGiftCardsObOrderMutation";

export function useSaveOrder({ onSuccess }) {
  const queryClient = useQueryClient();
  const { tSettings } = useAppTranslation.Settings();

  const { isLoading, mutate } = useSetGiftCardsObOrderMutation({
    onSuccess: () => {
      uiNotification.success(tSettings("giftCards.obOrder.success.update"));
      queryClient.invalidateQueries([QUERY_KEYS.giftCardsObOrder]);
      onSuccess();
    },
    onError: () => {
      uiNotification.error(tSettings("giftCards.obOrder.error.update"));
    },
  });

  const save = (orderedGiftCards) => {
    mutate({
      giftCardIds: orderedGiftCards.map((p) => p.id),
    });
  };

  return {
    save,
    isSaving: isLoading,
  };
}
