import { useQueryClient } from "@tanstack/react-query";
import { useProviderRoomCancelSignaturesMutation } from "../../../api/mutations/useProviderRoomCancelSignaturesMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { QUERY_KEYS } from "../../../consts/api";

export function useCancelSign() {
  const queryClient = useQueryClient();
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useProviderRoomCancelSignaturesMutation({
    onSuccess: () => {
      uiNotification.success(tCommon("providerRoom.success.unsendFromMd"));
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.providerRoomProcedures],
        refetchType: "active",
      });
    },
    onError: () => {
      uiNotification.error(tCommon("providerRoom.error.unsendFromMd"));
    },
  });

  return {
    cancelSign: mutateAsync,
    isSignCancelling: isLoading,
  };
}
