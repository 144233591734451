import { usePatientVitalDeleteMutation } from "../../../../../../api/patientVitals/usePatientVitalDeleteMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { patientVitals } from "../../../../../../store/patients/vitals";
import { dispatch } from "../../../../../../store/store";

const { actions } = patientVitals;

export const useDelete = (vitalRecordId) => {
  const { tClients } = useAppTranslation.Clients();

  const { mutateAsync, isLoading } = usePatientVitalDeleteMutation({
    onError: () => {
      uiNotification.error(tClients("vitals.error.delete"));
    },
    onSuccess: () => {
      uiNotification.success(tClients("vitals.success.delete"));
    },
  });

  return {
    initiate: async () => {
      return mutateAsync({ id: vitalRecordId }).then(() => {
        dispatch(actions.activeRecordIdChange(undefined));
      });
    },
    isLoading,
  };
};
