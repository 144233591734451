import * as yup from "yup";
import { useFormik } from "formik";
import { REASON_TYPES } from "../CancelPlanModal.consts";

const schema = yup.object({
  reasonType: yup.string().required("Please choose a reason"),
  keyFeatureMessage: yup.string().test({
    name: "required",
    message: "Message is required",
    test: function(value) {
      return !(
        this.parent.reasonType === REASON_TYPES.missingKeyFeature && !value
      );
    },
  }),
  otherMessage: yup.string().test({
    name: "required",
    message: "Message is required",
    test: function(value) {
      return !(this.parent.reasonType === REASON_TYPES.other && !value);
    },
  }),
});

export function useForm({ onSubmit }) {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      reasonType: "",
      keyFeatureMessage: "",
      otherMessage: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit,
  });

  const setValue = (field, value) => {
    setFieldTouched(field);
    setFieldValue(field, value);
  };

  const getError = (field) => {
    if (touched[field] && errors[field]) {
      return errors[field];
    }
    return null;
  };

  return {
    reasonType: {
      value: values.reasonType,
      onChange: (next) => setValue("reasonType", next),
    },
    keyFeatureMessage: {
      value: values.keyFeatureMessage,
      onChange: (next) => setValue("keyFeatureMessage", next),
      error: getError("keyFeatureMessage"),
    },
    otherMessage: {
      value: values.otherMessage,
      onChange: (next) => setValue("otherMessage", next),
      error: getError("otherMessage"),
    },
    submit: handleSubmit,
  };
}
