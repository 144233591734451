import React, { memo } from "react";
import PropTypes from "prop-types";
import { SearchResultTile } from "../../shared/SearchResultTile/SearchResultTile";
import classes from "./SearchResults.module.scss";
import { Empty } from "../../../../shared/Empty/Empty";

export const SearchResults = memo(({ foundPatterns }) => {
  return (
    <div className={classes.root}>
      {foundPatterns.length > 0 ? (
        foundPatterns.map((pattern) => (
          <SearchResultTile key={pattern} pattern={pattern} />
        ))
      ) : (
        <Empty position="center" />
      )}
    </div>
  );
});

SearchResults.propTypes = {
  foundPatterns: PropTypes.arrayOf(PropTypes.string).isRequired,
};
