import React from "react";
import { LayoutSettings } from "../../../boxes/LayoutSettings/LayoutSettings";
import { Header } from "./components/Header";
import classes from "./sass/SettingsDeletedSurveys.module.scss";
import { Box } from "../../../shared/Box/Box";
import { Table } from "../../../shared/Table/Table";
import { Empty } from "../../../shared/Empty/Empty";
import { Skeleton } from "../../../shared/Skeleton/Skeleton";
import { useTableData } from "./hooks/useTableData";
import { useRestoreSurveys } from "./hooks/useRestoreSurveys";
import { useDeletedSurveys } from "./hooks/useDeletedSurveys";
import { useMultiselectEntity } from "../../../utilities/hooks/useMultiselectEntity";
import { LoadMoreObserver } from "../../../shared/LoadMoreObserver/LoadMoreObserver";
import {
  SURVEYS_PER_PAGE,
  SKELETON_HEIGHT,
} from "./SettingsDeletedSurveys.consts";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function SettingsDeletedSurveys() {
  const { tCommon } = useAppTranslation.Common();

  const {
    surveys,
    isSurveysLoading,
    isNextSurveysFetching,
    fetchNextSurveys,
    hasMoreSurveys,
    refetchSurveys,
    searchTerm,
    setSearchTerm,
  } = useDeletedSurveys();

  const { resetSelected, selected, ...selectHandlers } = useMultiselectEntity({
    allEntities: surveys.map((p) => p.id),
  });

  const { restoreSurveys, isSurveysRestoring } = useRestoreSurveys({
    selectedSurveyIds: selected,
    onSuccess: () => {
      refetchSurveys();
      resetSelected();
    },
  });

  const { cols, data } = useTableData(surveys, selectHandlers);

  const isDataVisible = !isSurveysLoading && data.length > 0;
  const isEmpty = !isSurveysLoading && data.length === 0;
  const isSearching = Boolean(searchTerm) && isSurveysLoading;

  return (
    <LayoutSettings contentClassName={classes.root}>
      <Header
        restoreCount={selected.length}
        onRestore={restoreSurveys}
        isRestoring={isSurveysRestoring}
        searchValue={searchTerm}
        onChangeSearch={setSearchTerm}
        isSearching={isSearching}
      />
      <Box elevation>
        {isSurveysLoading && !searchTerm && (
          <Skeleton
            count={SURVEYS_PER_PAGE}
            height={SKELETON_HEIGHT}
            borderRadius="0px"
          />
        )}
        {isDataVisible && <Table cols={cols} data={data} />}
        {isEmpty && <Empty size="big" position="center" />}
        {isSearching && (
          <Empty size="big" position="center">
            {tCommon("label.searching")}
          </Empty>
        )}
        {hasMoreSurveys && (
          <LoadMoreObserver
            noPaddingBottom
            loadMore={fetchNextSurveys}
            isLoading={isNextSurveysFetching}
          />
        )}
      </Box>
    </LayoutSettings>
  );
}
