/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { isEqual } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ServicesList from "./components/ServicesList";
import {
  fetchServicesProvider,
  dynamicOrderingProviderSchedule,
  updateProviderScheduleSortOrder,
  exportEmptyData,
  saveServicesProvider,
} from "../../../Actions/Appointment/appointmentAction.js";

class ServicesProvided extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      appointmentLang: languageData.appointments,
      showLoader: false,
      dynamic_providers_ordering: false,
      providerServicesProviders: [],
      saveProviderServicesProviders: [],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
      Please_Wait: languageData.global["Please_Wait"],
      checked: "all",
      selectProvider: [],
      provider_id: "",
      display_type: "",
      term: "",
      checkedServices: {},
      checkedServicesId: [],
      label_success: languageData.global["provided_services_list_updated"],
      label_error: languageData.global["label_off"],
    };

    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
  }

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      label_search: languageData.global["label_search"],
      Please_Wait: languageData.global["Please_Wait"],
      sorry_no_record_found: languageData.global["sorry_no_record_found"],
    });

    let formData = {
      params: {
        term: this.state.term,
        checked: this.state.checked,
        provider_id: this.props.providerId,
        display_type: this.state.display_type,
      },
    };

    if (!this.props.providerServicesProviders) {
      this.setState({ showLoader: true });
    }

    this.props.fetchServicesProvider(formData);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.providerServicesProviders &&
      this.props.providerServicesProviders
    ) {
      const selectedCategories = Object.values(
        this.props.providerServicesProviders,
      ).map((el) => {
        return {
          ...el,
          services: el.services.filter((service) => service.is_selected),
        };
      });
      const selectedFormatedServices = selectedCategories.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.id]: curr.services.map(
              (service) => `${service.id} - ${service.name}`,
            ),
          };
        },
        {},
      );

      const idsArray = Object.values(selectedFormatedServices)
        .flat()
        .map((el) => {
          return parseInt(el.split("-")[0]);
        });

      this.setState({
        showLoader: false,
        checkedServices: {
          ...this.state.checkedServices,
          ...selectedFormatedServices,
        },
        checkedServicesId: [...idsArray],
      });
    }
    if (
      prevProps.providerServicesProvidersLoading &&
      !this.providerServicesProvidersLoading
    ) {
      this.setState({ showLoader: false });
    }
    if (!isEqual(prevState.checkedServices, this.state.checkedServices)) {
      const idsArray = [
        ...Object.values(this.state.checkedServices).flat(),
      ].map((el) => {
        return parseInt(el.split("-")[0]);
      });
      this.setState({ checkedServicesId: [...idsArray] });
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });

    if (event.target.name === "checked") {
      this.handleSubmit(event, value);
    }
  };

  handleSubmit = (event, value) => {
    event.preventDefault();
    const selectFilter = {
      selected: 1,
      unselected: 2,
    };
    const displayType = selectFilter[value] || "";
    let formData = {
      params: {
        term: this.state.term,
        checked: value ? value : this.state.checked,
        provider_id: this.props.providerId,
        display_type: displayType,
      },
    };
    this.setState({
      providerServicesProviders: [],
      showLoader: true,
    });

    this.props.fetchServicesProvider(formData);
  };

  saveServicesProvider = () => {
    let formData = {
      services: [...this.state.checkedServicesId],
      provider_id: this.props.providerId,
      term: this.state.term,
    };

    this.props.saveServicesProvider(this.props.providerId, formData);
    this.props.cancelServicesProvidedView();
  };

  cancelProviderSchedule = () => {
    this.props.cancelServicesProvidedView();
  };

  checkServices = (checked, category) => {
    this.setState({
      checkedServices: {
        ...this.state.checkedServices,
        [category.id]: checked,
      },
    });
  };

  render() {
    return (
      <div className="full-width">
        <div
          className="setting-search-outer p15"
          style={{ margin: "-51px 0 0 0" }}
        >
          <h4>{this.props.providerName}</h4>
        </div>

        <div className="setting-search-outer p15">
          <span className="searchPageName">Services Provided</span>
          <form onSubmit={this.handleSubmit}>
            <div className="search-bg new-search">
              <i className="fas fa-search" />
              <input
                className="setting-search-input search-key"
                name="term"
                placeholder={this.state.label_search}
                value={this.state.term}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </div>
          </form>
          <div className="filter-type">
            <span className="search-text">Display Services:</span>
            <div className="header-select">
              <select
                name="checked"
                onChange={this.handleInputChange}
                value={this.state.checked}
              >
                <option value="all">All</option>
                <option value="selected">Selected Services</option>
                <option value="unselected">Unselected Services</option>
              </select>
              <i className="fas fa-angle-down"></i>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table-updated setting-table no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                <th className="col-xs-3 table-updated-th">
                  {"Category/Service Name"}
                </th>
                <th className="col-xs-3 table-updated-th">{"Location"}</th>
              </tr>
            </thead>
            <ServicesList
              providerServicesProviders={this.props.providerServicesProviders}
              checkedServices={this.state.checkedServices}
              checkServices={this.checkServices}
            />
          </table>
          <table className="table-updated setting-table no-td-border no-hover">
            <tbody>
              <tr className="table-updated-tr">
                <td className="table-updated-td services-location">
                  <a
                    className="new-blue-btn pull-right m-r-1"
                    onClick={this.saveServicesProvider}
                  >
                    Save
                  </a>
                  <a
                    className="new-white-btn pull-right"
                    onClick={this.cancelProviderSchedule}
                  >
                    Cancel
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.Please_Wait}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLoadingText
              ? "loading-please-wait"
              : "loading-please-wait no-display"
          }
        >
          {this.state.loading_please_wait_text}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    providerServicesProviders:
      state.AppointmentReducer.providerServicesProviders.data,
    providerServicesProvidersLoading:
      state.AppointmentReducer.providerServicesProvidersLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchServicesProvider: fetchServicesProvider,
      dynamicOrderingProviderSchedule: dynamicOrderingProviderSchedule,
      updateProviderScheduleSortOrder: updateProviderScheduleSortOrder,
      exportEmptyData: exportEmptyData,
      saveServicesProvider: saveServicesProvider,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesProvided);
