import { matchPath } from "react-router";
import { BI_ROUTES } from "../../../consts/routes";

export const getPathParams = () => {
  const { params } = matchPath(window.location.pathname, {
    path: BI_ROUTES.giftCardHistory(":id"),
    exact: true,
    strict: false,
  });

  return {
    giftCardId: params.id ? Number(params.id) : undefined,
  };
};
