import React, { memo, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./Documents.module.scss";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../shared/Select/Select";
import { useConsentOptions } from "./hooks/useConsentOptions";
import { useQuestionnaireOptions } from "./hooks/useQuestionnaireOptions";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import {
  dedupSelectedDocumentOptions,
  pickSelectedOptions,
} from "./Documents.utils";
import { mutateProcedure } from "../../../../../store/patients/mutateProcedure";
import { DOC_DEDUP_TYPE } from "../../../../../store/patients/mutateProcedure/config";
import { dispatch } from "../../../../../store/store";

const { selectors, actions } = mutateProcedure;

const mapStateToProps = (state) => ({
  isConsentsDeduping: selectors.selectIsDocsDeduping(DOC_DEDUP_TYPE.consent)(
    state,
  ),

  isQuestionnairesDeduping: selectors.selectIsDocsDeduping(
    DOC_DEDUP_TYPE.questionnaire,
  )(state),
});

export const Documents = memo(
  connect(mapStateToProps)(
    ({
      appointmentId,
      procedureId,
      serviceId,
      questionnaireIds,
      consentIds,
      isLoading,
      label,
      isConsentsDeduping,
      isQuestionnairesDeduping,
    }) => {
      const { tCommon } = useAppTranslation.Common();

      const consentOptions = useConsentOptions({
        appointmentId,
        procedureId,
        serviceId,
        select: consentIds.appendAsNonDeletable,
      });

      const questionnaireOptions = useQuestionnaireOptions({
        appointmentId,
        procedureId,
        serviceId,
        select: questionnaireIds.appendAsNonDeletable,
      });

      const selectedQuestionnaireOptions = useMemo(
        () =>
          pickSelectedOptions(
            questionnaireIds.value,
            questionnaireOptions.data,
          ),
        [questionnaireOptions.data, questionnaireIds.value],
      );

      const selectedConsentOptions = useMemo(
        () => pickSelectedOptions(consentIds.value, consentOptions.data),
        [consentOptions.data, consentIds.value],
      );

      useEffect(() => {
        if (isQuestionnairesDeduping && procedureId) {
          const nextQuestionnaireIds = dedupSelectedDocumentOptions(
            questionnaireIds.value,
            questionnaireOptions.patientDocuments,
          );

          dispatch(actions.finishDocumentsDedup(DOC_DEDUP_TYPE.questionnaire));
          questionnaireIds.replaceRawIds(nextQuestionnaireIds);
        }
      }, [
        questionnaireIds.value,
        questionnaireOptions.patientDocuments,
        isQuestionnairesDeduping,
        procedureId,
      ]);

      useEffect(() => {
        if (isConsentsDeduping && procedureId) {
          const nextConsentIds = dedupSelectedDocumentOptions(
            consentIds.value,
            consentOptions.patientDocuments,
          );

          dispatch(actions.finishDocumentsDedup(DOC_DEDUP_TYPE.consent));
          consentIds.replaceRawIds(nextConsentIds);
        }
      }, [
        consentIds.value,
        consentOptions.patientDocuments,
        isConsentsDeduping,
        procedureId,
      ]);

      return (
        <div>
          {label && <div className={classes.title}>{label}</div>}
          {isLoading ? (
            <div className={classes.selects}>
              {Array.from(Array(2).keys()).map((i) => (
                <Skeleton key={i} count={1} height={50} />
              ))}
            </div>
          ) : (
            <div className={classes.selects}>
              <div>
                <InputLabel>{tCommon("label.selectQuestionnaires")}</InputLabel>
                <Select
                  isMulti
                  isSearchable
                  options={questionnaireOptions.data}
                  value={selectedQuestionnaireOptions}
                  onChange={questionnaireIds.onChange}
                  isLoading={questionnaireOptions.isLoading}
                  isOptionClearable={(option) =>
                    questionnaireIds.isDeletable(option.value)
                  }
                />
              </div>
              <div>
                <InputLabel>{tCommon("label.selectConsents")}</InputLabel>
                <Select
                  isMulti
                  isSearchable
                  options={consentOptions.data}
                  value={selectedConsentOptions}
                  onChange={consentIds.onChange}
                  isLoading={consentOptions.isLoading}
                  isOptionClearable={(option) =>
                    consentIds.isDeletable(option.value)
                  }
                />
              </div>
            </div>
          )}
        </div>
      );
    },
  ),
);

Documents.propTypes = {
  appointmentId: PropTypes.number,
  procedureId: PropTypes.number,
  questionnaireIds: PropTypes.shape({
    value: PropTypes.array,
    onChange: PropTypes.func,
    appendAsNonDeletable: PropTypes.func,
    replaceRawIds: PropTypes.func,
    isDeletable: PropTypes.func,
  }),
  consentIds: PropTypes.shape({
    value: PropTypes.array,
    onChange: PropTypes.func,
    appendAsNonDeletable: PropTypes.func,
    replaceRawIds: PropTypes.func,
    isDeletable: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};
