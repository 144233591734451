import React, { Component } from 'react'
import './cardreaders.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCardreaders, fetchClinics, addCardreader, updateCardreader, getCardreadersModels } from '../../../../Actions/Cardreaders/cardreadersAction'
import ConfirmationModal from '../../../Appointment/Calendar/ConfirmationModal/ConfirmationModal'
import { shortenClinicName } from "../../../../helpers/general";

class CardReaders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputIsShown: false,
            serialNumber: "",
            clinic: "",
            cardreaderModel: "",
            serialNumberError: false,
            clinicError: false,
            modelError: false,
            clinicId: null,
            showDetachModal: false,  
            unassignObjId: null,
            unassignFormData: null,   
        }
    }

    handleInputShow() {
        this.setState( { inputIsShown: !this.state.inputIsShown } )
    }

    handleInput(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    componentDidMount() {
        this.accountId = JSON.parse(localStorage.getItem('userData')).account.id
        this.props.getCardreaders(this.accountId);
        this.props.fetchClinics();
        this.props.getCardreadersModels("clearent")
    }

    handleDisplayConfirmDialog(cardreaderId, formData) {
        this.setState({ showDetachModal: true, unassignObjId: cardreaderId, unassignFormData: formData })
    }

    renderCardreaders() {
        if(!this.props.cardreaders) {
            return;
        }
        if(!this.props.clinics) {
            return;
        }

        return this.props.cardreaders.map(obj => {
            let getClinicName = this.props.clinics.find(el => el.id === obj.clinic_id);
            let formData = { 
                'account_id': this.accountId, 
                'clinic_id': obj.clinic_id, 
                "payworks_status": "inactive" 
            };
            if(getClinicName) {
                return <tbody>
                <tr> 
                    <td>{obj.serial_number}</td>
                    <td>{shortenClinicName(getClinicName.clinic_name)} </td>
                    <td>{obj.status}</td>
                    <td><a className="easy-link no-padding" onClick={() => this.handleDisplayConfirmDialog(obj.id, formData)}>Unassign Card Reader</a></td>
                </tr>
                </tbody>
                
            }
        })
    }

    handleSave() {
        let formData = {
            serial_number: this.state.serialNumber,
            clinic_id: this.state.clinicId,
            account_id: this.accountId,
            payworks_status: 'active',
            model: this.state.cardreaderModel,
        }

        let error = false;
        if(!this.state.serialNumber.trim()) {
            this.setState({ serialNumberError: true })
            error = true
        } else {
            this.setState({ serialNumberError: false })
        }
        if(!this.state.clinic) {
            this.setState({ clinicError: true })
            error = true
        } else {
            this.setState({ clinicError: false })
        }

        if(!this.state.cardreaderModel) {
            this.setState({ modelError: true })
            error = true
        } else {
            this.setState({ modelError: false })
        }

        if(!error) {
            this.props.addCardreader(formData);
            this.setState({
                clinic: "",
                cardreaderModel: "",
                serialNumber: "",
                inputIsShown: false
            })
        }
    }

    handleCancel() {
        this.setState({
            clinic: "",
            cardreaderModel: "",
            serialNumber: "",
            inputIsShown: false
        })
    }

    handleClinic(event) {
        let clinicValue = event.target.value;
        let getClinicId = this.props.clinics.find(el => el.clinic_name === clinicValue)
        this.setState( {clinic: clinicValue} );
        this.setState( {clinicId: getClinicId.id} )
    }

    handleModel(event) {
        let modelValue = event.target.value;
        this.setState( {cardreaderModel: modelValue} )
    }

    handleUnassign() {
        this.props.updateCardreader(this.state.unassignObjId, this.state.unassignFormData) 
        this.setState( {showDetachModal: false })
    }

    render() {
        return (
            <>
                <div className="setting-setion m-b-15 card-readers">
                    <div className="row-m-0 m-b-25">
                        <b>Clearent Card Reader(s)</b>
                        <button className="new-blue-btn pull-right" onClick={() => this.handleInputShow()}>Attach Wi-Fi Card Reader</button>
                    </div>
                    {this.state.inputIsShown && <div className="row-m-0 cardreader-form">
                        <div className="col-xs-12 col-md-5 col-sm-5 no-padding-left">
                            <div className="newInputFileldOuter">
                                <label htmlFor="serial-number" className="cardreader-label">Serial Number<span className="setting-require">*</span></label>
                                <input className={!this.state.serialNumberError ? "newInputField" : "newInputField field_error"} name="serialNumber" placeholder='Enter serial number of the device' onChange={(event) => this.handleInput(event)}></input>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-2 col-sm-2 no-padding">
                            <div className="newInputFileldOuter">
                                <label htmlFor="clinic" className="cardreader-label">Clinic<span className="setting-require">*</span></label>
                                <select name="clinic" className={!this.state.clinicError ? "newSelectField" : "newSelectField field_error"} value={this.state.clinic} onChange={(event) => this.handleClinic(event)}>
                                    <option value="" disabled>Select clinic</option>
                                    {this.props.clinics && this.props.clinics.map(clinic => {
                                        return <option key={clinic.id} value={clinic.clinic_name}>{clinic.clinic_name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-2 col-sm-2">
                            <div className="newInputFileldOuter">
                                <label htmlFor="clinic" className="cardreader-label">Model<span className="setting-require">*</span></label>
                                <select name="clinic" className={!this.state.modelError ? "newSelectField" : "newSelectField field_error"} value={this.state.cardreaderModel} onChange={(event) => this.handleModel(event)}>
                                    <option value="" disabled>Select model</option>
                                    {this.props.cardreadersModels && this.props.cardreadersModels.map(model => {
                                        return <option key={model} value={model}>{model}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-3 col-sm-3 cardreader-buttons no-padding-right">
                            <button className="new-blue-btn" onClick={() => this.handleSave()} >Save</button>
                            <button className="new-white-btn" onClick={() => this.handleCancel()}>Cancel</button>
                        </div>
                    </div>}
                    <table className="table-updated juvly-table cardreader-table">
                        <thead>
                            <tr>
                                <th>Serial Number</th>
                                <th>Clinic</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {this.renderCardreaders()}
                    </table>
                </div>

                <ConfirmationModal
                    isOpen={this.state.showDetachModal}
                    confirmFn={() => this.handleUnassign()}
                    cancelFn={() => this.setState( {showDetachModal: false })}
                    message={"Are you sure you want to unassign the card reader?"}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
      cardreaders: state.CardreaderReducer.cardreaders.data,
      clinics: state.CardreaderReducer.clinics.data,
      error: state.CardreaderReducer.error,
      cardreadersModels: state.CardreaderReducer.cardreadersModels
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({getCardreaders, fetchClinics, addCardreader, updateCardreader, getCardreadersModels}, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(CardReaders);