import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

const composeServicesByIdState = (apiServicesByCategory) =>
  apiServicesByCategory.reduce(
    (r, c) => ({
      ...r,
      ...c.services.reduce(
        (sr, s) => ({
          ...sr,
          [s.id]: s,
        }),
        {},
      ),
    }),
    {},
  );

const composeServiceIdsByCategoryIdState = (apiServicesByCategory) =>
  apiServicesByCategory.reduce(
    (r, c) => ({ ...r, [c.id]: c.services.map((s) => s.id) }),
    {},
  );

export function usePicker({ servicesByCategory, pickedIds, onChange }) {
  const [pickedServiceIds, setPickedServiceIds] = useState(pickedIds);
  const [servicesById, setServicesById] = useState({});
  const [serviceIdsByCategoryId, setServiceIdsByCategoryId] = useState({});

  // Flag getters

  const isServiceSelected = (serviceId) => {
    return pickedServiceIds.includes(serviceId);
  };

  const isCategorySelected = (categoryId) => {
    if (categoryId in serviceIdsByCategoryId) {
      return serviceIdsByCategoryId[categoryId].every((i) =>
        pickedServiceIds.includes(i),
      );
    }
    return false;
  };

  const isCategoryNotSelected = (categoryId) => {
    if (categoryId in serviceIdsByCategoryId) {
      return serviceIdsByCategoryId[categoryId].every(
        (i) => !pickedServiceIds.includes(i),
      );
    }
    return false;
  };

  const isCategoryPartiallySelected = (categoryId) => {
    if (categoryId in serviceIdsByCategoryId) {
      return (
        !isCategorySelected(categoryId) && !isCategoryNotSelected(categoryId)
      );
    }
    return false;
  };

  // Actions

  const selectService = (serviceId) => {
    if (serviceId) {
      setPickedServiceIds((prev) => [...prev, serviceId]);
    }
  };

  const deselectService = (serviceId) => {
    if (serviceId) {
      setPickedServiceIds((prev) => prev.filter((id) => id !== serviceId));
    }
  };

  const selectAllServices = (categoryId) => {
    if (
      !isCategorySelected(categoryId) &&
      categoryId in serviceIdsByCategoryId
    ) {
      setPickedServiceIds((prev) => [
        ...prev,
        ...serviceIdsByCategoryId[categoryId],
      ]);
    }
  };

  const deselectAllServices = (categoryId) => {
    if (categoryId in serviceIdsByCategoryId) {
      setPickedServiceIds((prev) =>
        prev.filter((id) => !serviceIdsByCategoryId[categoryId].includes(id)),
      );
    }
  };

  // Side effects

  useEffect(() => {
    setServicesById(composeServicesByIdState(servicesByCategory));
    setServiceIdsByCategoryId(
      composeServiceIdsByCategoryIdState(servicesByCategory),
    );
  }, [servicesByCategory]);

  useEffect(() => {
    if (!isEmpty(servicesById)) {
      onChange(pickedServiceIds.map((id) => servicesById[id]));
    }
  }, [pickedServiceIds, servicesById]);

  // ---------

  return {
    pickedServiceIds,
    selectService,
    deselectService,
    selectAllServices,
    deselectAllServices,
    isServiceSelected,
    isCategorySelected,
    isCategoryNotSelected,
    isCategoryPartiallySelected,
  };
}
