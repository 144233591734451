import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Select from 'react-select';
import {
  displayName,
} from '../../../Utils/services.js';
import {
  getPlans,
  addPrescriber,
  addSupportStaff,
  getAllUsers,
  getProvidersList,
  getPrescriber,
  renewPrescriber
} from '../../../Actions/Settings/settingsActions.js';
import Loader from '../../Common/Loader.js'
import { Link } from "react-router-dom";
import { uiNotification } from '../../../services/UINotificationService.js';

const Prescription = (props) => {
  const {
    allProviders,
    allPlans,
    getProvidersList,
    getPlans,
    addPrescriber,
    getPrescriber,
    renewPrescriber,
    prescribers,
    selectedPrescriber = null,
    showLoadFromProps,
    getAllUsers,
    allUsers,
    amountEnrollUsers,
    support_agents,
    addSupportStaff,
    allProvidersTime,
    prescribingSupportUser,
    redirectToListing = false
  } = props;

  let params = props.match.params;

  let location = props.location.pathname;
  let pathArr = location.split('/');
  const lastPath = pathArr[pathArr.length - 1];
  const [prescriptionSetup, setPrescriptionSetup] = useState((lastPath == 'prescribers' || lastPath == 'support-users') ? true : false);
  const [showLoader, setShowLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [defaultProviders, setDefaultProviders] = useState([]);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [plans, setPlans] = useState([])
  const [step, setStep] = useState(0);
  const [providerId, setProviderId] = useState(0);
  const [isExternalAgent, setIsExternalAgent] = useState(false);
  const [addExternalAgent, setAddExternalAgent] = useState(false);
  const [supportProviderId, setSupportProviderId] = useState(0);
  const [selectedSupportAgent, setSelectedSupportAgent] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [alternativePlan, setAlternativePlan] = useState(null);
  const [selectedCommitment, setSelectedCommitment] = useState(null);
  const [adminType, selectAdminType] = useState(null);
  const [commitmentId, setCommitmentId] = useState(0);
  const [supportFirstName, setSupportFirstName] = useState('');
  const [supportLastName, setSupportLastName] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [providerEmail, setProviderEmail] = useState('');
  const [dea, setDea] = useState('');
  const [npi, setNpi] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [state, setState] = useState('');
  const [supportFirstNameError, setSupportFirstNameError] = useState(false);
  const [supportLastNameError, setSupportLastNameError] = useState(false);
  const [supportEmailError, setSupportEmailError] = useState(false);
  const [providerEmailError, setProviderEmailError] = useState(false);
  const [providerError, setProviderError] = useState(false);
  const [npiError, setNpiError] = useState(false);
  const [deaError, setDeaError] = useState(false);
  const [localAllUsers, setLocalAllUsers] = useState([])
  const [renewingPrescriber, setRenewingPrescriber] = useState(null);

  const selectedPrescriberFullname = selectedPrescriber ? `${selectedPrescriber.first_name} ${selectedPrescriber.last_name}` : ""

  const ePrescriptionSetup = (localStorage.getItem('ePrescriptionSetup')) ? JSON.parse(localStorage.getItem('ePrescriptionSetup')) : null;
  if (ePrescriptionSetup.is_enrolled && location === '/settings/setup-eprescription' && step === 0) {
    props.history.push('/settings/eprescription/prescribers');
  }

  useEffect(() => {
    if (plans.length == 0) {
      setShowLoader(true);
      getPlans();
      if (params.userType == 'support-agent') {
        getAllUsers();
      }
    }
  }, [])

  useEffect(() => {
    if (allPlans && allPlans.length) {
      setPlans(allPlans);
      setShowLoader(false);
    }
  }, [allPlans])

  useEffect(() => {
    if (plans && plans.length) {
      if (params.planId) {
        selectPlan(params.planId, 1);
        setStep(2);
      }
      if (params && params.userType == 'support-agent') {
        setSelectedCommitment(plans[0].commitmentTypes[0])
      }
    }
  }, [plans])

  useEffect(() => {
    if (plans && plans.length && params.planId) {
      selectPlan(params.planId, 1);
      setStep(2);
    }
    if (params.prescriberId && renewingPrescriber === null) {
      setShowLoader(true);
      getPrescriber(params.prescriberId);
    }
  }, [params]);

  useEffect(() => {
    if (selectedPrescriber !== null) {
      setRenewingPrescriber(selectedPrescriber);
    }
  }, [selectedPrescriber]);

  useEffect(() => {
    if (showLoadFromProps) {
      setShowLoader(false);
    }
  }, [showLoadFromProps])

  useEffect(() => {
    if (prescribers && prescribers.data.length) {
      setProviderId(0);
      setNpi('')
      setDea('')
      setProviderEmail('')
      setShowLoader(false)
      if (prescribers.data.length == 1) {
        setStep(6);
      } else {
        props.history.push('/settings/eprescription/prescribers')
      }
    }
  }, [prescribers])


  useEffect(() => {
    if (support_agents && support_agents.data.length) {
      if (support_agents.data.length == 1) {
        setStep(6);
      } else {
        props.history.push('/settings/eprescription/support-users')
      }
      setShowLoader(false);
    }
  }, [support_agents])

  useEffect(() => {
    if (step == 3 && !allProviders) {
      setShowLoader(true);
      getProvidersList();
    }
  }, [step])

  useEffect(() => {
    if (redirectToListing === true) {
      return props.history.push('/settings/eprescription/prescribers')
    }
  }, [redirectToListing])

  useEffect(() => {
    setShowLoader(false);
    if (allProviders && allProviders.length > 0) {
      allProviders.map((obj, idx) => {
        defaultProviders.push({ value: obj.id, label: displayName(obj) })
      })
    }
  }, [allProviders, allProvidersTime])

  useEffect(() => {
    if (allUsers && allUsers.length > 0) {
      setShowLoader(false);
      let users = []
      allUsers.map((obj, idx) => {
        users.push({ value: obj.id, label: displayName(obj) })
      })
      setDefaultUsers(users);
      if ((allUsers && params.userType == 'support-agent') || adminType == 'admin') {
        selectPlan(1, 1);
        setStep(4);
      }
      setLocalAllUsers(allUsers);
      setShowLoader(false);
    } else {
      if (amountEnrollUsers) {
        setDefaultUsers([]);
        setShowLoader(false);
        if ((amountEnrollUsers && params.userType == 'support-agent') || adminType == 'admin') {
          selectPlan(1, 1);
          setStep(4);
        }
        setLocalAllUsers([]);
      }
    }
  }, [allUsers, amountEnrollUsers])

  useEffect(() => { 
    if (params.userId && prescribingSupportUser) {
      setProviderId({ value: prescribingSupportUser.id, label: displayName(prescribingSupportUser), user: prescribingSupportUser });
      setProviderEmail(prescribingSupportUser.email);
    }
    if (params.userId && !prescribingSupportUser) {
      props.history.push('/settings/eprescription/prescribers')
    }
  }, [])

  const selectPlan = (id, step) => {
    const plan = plans.find(e => e.planId == id);
    setSelectedPlan(plan);

    const alternativePlan = plans.find(e => e.planId != id);
    setAlternativePlan(alternativePlan);

    if (step) {
      setStep(step);
      setPrescriptionSetup(false)
    }
    //setStep(2);
  }

  const handleSupportUserChange = (value) => {
    if (value && localAllUsers) {
      let user = localAllUsers.find(e => e.id == value.value);
      setSelectedSupportAgent(user);
      setSupportProviderId({ value: user.id, label: displayName(user), user: user });
    }
  }
  const handleProviderChange = (value) => {
    if (value) {
      let user = allProviders.find(e => e.id == value.value);
      setProviderId({ value: user.id, label: displayName(user), user: user });
      setProviderEmail(user.email_id);
    }
  }

  const goBackToPlanSelection = () => {
    setSelectedCommitment(null);
    setStep(2);
  }

  const confirmRenewal = () => {
    const formData = {
      plan_id: selectedPlan.planId,
      commitment_type_id: selectedCommitment.id,
    };
    setShowLoader(true);
    renewPrescriber(renewingPrescriber.id, formData);
  }

  const confirmEnrollment = () => {
    setProviderError(false);
    setProviderEmailError(false);
    setDeaError(false);
    setNpiError(false);
    if (providerId == 0) {
      setProviderError(true);
      return false;
    }
    if (providerEmail == '' || providerEmail == undefined) {
      setProviderEmailError(true);
      return false;
    }
    if (npi == '' || npi.trim() == '') {
      setNpiError(true);
      return false;
    }
    if ((dea == '' || dea.trim() == '') && selectedPlan.planId == 1) {
      setDeaError(true);
      return false;
    }
    setShowLoader(true);
    let formData = {
      "provider_id": providerId.value,
      "plan_type": selectedPlan.planName,
      "first_name": providerId.user.firstname,
      "last_name": providerId.user.lastname,
      "email": providerEmail,
      "npi": npi,
      "dea": dea,
      "state": "",
      "amount": selectedCommitment.totalDue,
      "plan_id": selectedPlan.planId,
      "commitment_types": selectedCommitment
    }
    if (params.userId) {
      formData.is_upgrade = true
    }
    addPrescriber(formData);
    setShowLoader(true);
  }
  const addSupportAgent = () => {
    setSupportFirstNameError(false);
    setSupportLastNameError(false);
    setSupportEmailError(false);

    if (isExternalAgent) {
      if (supportFirstName.trim() == '' || supportFirstName == undefined) {
        setSupportFirstNameError(true);
        return false
      }
      if (supportLastName.trim() == '' || supportLastName == undefined) {
        setSupportLastNameError(true);
        return false
      }
      if (supportEmail.trim() == '' || supportEmail == undefined) {
        setSupportEmailError(true);
        return false
      }
    }
    setShowLoader(true);
    let formData = {
      "first_name": (isExternalAgent) ? supportFirstName : selectedSupportAgent.firstname,
      "last_name": (isExternalAgent) ? supportLastName : selectedSupportAgent.lastname,
      "email": (isExternalAgent) ? supportEmail : selectedSupportAgent.email_id,
    }
    if (!isExternalAgent) {
      formData.provider_id = supportProviderId.value;
    }
    addSupportStaff(formData);
    setShowLoader(true);
  }

  const openAddSupport = () => {
    setSupportProviderId(0)
    setSelectedSupportAgent({})
    setShowLoader(true);
    setStep(4);
    getAllUsers();
    //props.history.push('/settings/setup-eprescription/add/support-agent')
  }

  const startEnrollment = () => {
    if (adminType == 'prescriber_admin') {
      setStep(1);
    } else {
      openAddSupport();
    }
  }

  const finalPrice = !renewingPrescriber?.exclude_token_fee ? selectedPlan?.commitmentTypes[0].totalDue : selectedPlan?.commitmentTypes[0].totalDue - selectedPlan?.commitmentTypes[0].tokenActivationPrice;

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <Sidebar/>
        {plans && plans.length > 0 &&
        <div className="business-setion memberWalletOuter">
          <div className="setting-setion m-b-10">
            <div className="membership-title">
              {(params.planId) ? (renewingPrescriber ? "Renew " : "Add ") + (selectedPlan && selectedPlan.planName) + ` User ${selectedPrescriber ? `- ${selectedPrescriberFullname}` : ""}` : (params.userType) ? "Add Support User" : "Enroll in ePrescribing"}
              <div className="memberRightActions">
                {(renewingPrescriber ?
                    (step === 2 ?
                        <Link to={`/settings/eprescription/prescribers`} className="new-blue-btn pull-right">
                          <i className="fa fa-angle-left"></i>Back
                        </Link>
                        :
                        <button className="new-blue-btn pull-right"
                                onClick={() => goBackToPlanSelection()}>
                          <i className="fa fa-angle-left"></i>Back
                        </button>
                    )
                    :
                    (!params.planId && !params.userType && adminType != 'admin' && step > 1 && step < 6 &&
                      <button className="new-blue-btn pull-right" onClick={() => {
                        setStep(step - 1)
                      }}>
                        <i className="fa fa-angle-left"></i>Back
                      </button>)
                )}
              </div>
            </div>
          </div>
          {
            !prescriptionSetup && step == 0 &&
            <div className="setting-setion m-b-10 m-h-400">
              <div className="setting-container p2030">
                <div className="setting-title m-t-10">Enroll My Practice in ePrescribe</div>

                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <div className="pos-stripe-outer m-b-10 m-t-0">
                      <input type="radio" className="factor-type pos-stripe-input pos-stripe-option"
                             id="prescriber_admin" name="admin_type"
                             onChange={() => selectAdminType('prescriber_admin')}
                             checked={(adminType == 'prescriber_admin') ? true : false}/>
                      <label className="pos-stripe-discrip" htmlFor="prescriber_admin">
														<span className="pos-stripe-title authenti-radio-label">
															I am a Prescriber & an Account Administrator
														</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <div className="pos-stripe-outer m-b-10 m-t-0">
                      <input type="radio" className="factor-type pos-stripe-input pos-stripe-option" id="admin"
                             name="admin_type" onChange={() => selectAdminType('admin')}
                             checked={(adminType == 'admin') ? true : false}/>
                      <label className="pos-stripe-discrip" htmlFor="admin">
														<span className="pos-stripe-title authenti-radio-label">
															I am an Account Administrator Only
														</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="commitmentBtn pull-right m-t-20">
                  {adminType != null &&
                  <button className="new-blue-btn" onClick={() => startEnrollment()}>Continue</button>}
                </div>
              </div>
            </div>
          }


          {
            !prescriptionSetup && step == 1 &&
            <div className="setting-setion m-b-10 m-h-400">
              <div className="setting-container p2030">
                <div className="setting-title m-t-10">Choose The Preferred ERX Enrollment Plan</div>

                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <div className="pos-stripe-outer m-b-10 m-t-0">
                      <input type="radio" className="factor-type pos-stripe-input pos-stripe-option" id="EPCS"
                             name="verification_type" onChange={() => selectPlan(plans[0].planId, 0)}
                             checked={(selectedPlan && selectedPlan.planId == plans[0].planId) ? true : false}/>
                      <label className="pos-stripe-discrip" htmlFor="EPCS">
														<span className="pos-stripe-title authenti-radio-label">
															{plans[0].planDescription}
														</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <div className="pos-stripe-outer m-b-10 m-t-0">
                      <input type="radio" className="factor-type pos-stripe-input pos-stripe-option" id="notECPS"
                             name="verification_type" onChange={() => selectPlan(plans[1].planId, 0)}
                             checked={(selectedPlan && selectedPlan.planId == plans[1].planId) ? true : false}/>
                      <label className="pos-stripe-discrip" htmlFor="notECPS">
														<span className="pos-stripe-title authenti-radio-label">
															{plans[1].planDescription}
														</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row text-center initial-setup-text">
                  Each Prescriber must be enrolled separately. There will be an option to enroll additional Prescribers
                  once the first enrollment is complete. The Practice must enroll at least one free Support User before
                  a Provider is eligible to prescribe.
                </div>

                <div className="commitmentBtn pull-right m-t-20">
                  {selectedPlan != null &&
                  <button className="new-blue-btn" onClick={() => setStep(2)}>Continue</button>}
                </div>
              </div>
            </div>
          }

          {
            selectedPlan !== null && step == 2 &&
            <div className="setting-setion m-b-10">
              <div className="membership-title m-t-10 text-center">{selectedPlan.planTitle}*</div>
              <div className="commitmentOuter">
                <div
                  className={(selectedPlan.commitmentTypes[0].id == commitmentId) ? "commitmentSection selected" : "commitmentSection"}
                  onClick={() => {
                    setSelectedCommitment(selectedPlan.commitmentTypes[0]);
                    setCommitmentId(selectedPlan.commitmentTypes[0].id);
                    setStep(3);
                  }}>
                  <div className="commitmentTitle">{selectedPlan.commitmentTypes[0].name}</div>
                  <div className="commitmentRow">
                    <h1>${selectedPlan.commitmentTypes[0].providerPrice} per Provider/Month</h1>
                    <p>{selectedPlan.commitmentTypes[0].providerPriceDescription}</p>
                  </div>
                  {
                    selectedPlan.commitmentTypes[0].tokenActivationPrice !== '' &&
                    <div className="commitmentRow">
                      <h1 className={`${renewingPrescriber?.exclude_token_fee ? "lineThrough" : ""}`}>${selectedPlan.commitmentTypes[0].tokenActivationPrice} Required ID Token Activation</h1>
                      <p>Paid Annually</p>
                    </div>
                  }
                  <div className="commitmentRow">
                    <h1>Up to {selectedPlan.commitmentTypes[0].agentsIncluded} Support Agents per Prescriber
                      Included</h1>
                  </div>
                  <div className="commitmentTotalDue">
                    Total Due Upon Enrollment: ${finalPrice}
                  </div>
                  <div className="commitmentBtn">
                    <button className="new-blue-btn">{renewingPrescriber ? 'RENEW' : 'ENROLL'} for
                       ${finalPrice}</button>
                  </div>
                </div>
                <div
                  className={(selectedPlan.commitmentTypes[1].id == commitmentId) ? "commitmentSection selected" : "commitmentSection "}
                  onClick={() => {
                    setSelectedCommitment(selectedPlan.commitmentTypes[1]);
                    setCommitmentId(selectedPlan.commitmentTypes[1].id);
                    setStep(3)
                  }}>
                  <div className="commitmentTitle">{selectedPlan.commitmentTypes[1].name}</div>
                  <div className="commitmentRow">
                    <h1>${selectedPlan.commitmentTypes[1].providerPrice} per Provider/Month</h1>
                    <p>{selectedPlan.commitmentTypes[1].providerPriceDescription}</p>
                  </div>
                  {
                    selectedPlan.commitmentTypes[1].tokenActivationPrice !== '' &&
                    <div className="commitmentRow">
                      <h1>${selectedPlan.commitmentTypes[1].tokenActivationPrice} Required ID Token Activation</h1>
                      <p>Paid Annually</p>
                    </div>
                  }
                  <div className="commitmentRow">
                    <h1>Up to {selectedPlan.commitmentTypes[1].agentsIncluded} Support Agents per Prescriber
                      Included</h1>
                  </div>
                  <div className="commitmentTotalDue">
                    Total Due Upon Enrollment: ${selectedPlan.commitmentTypes[1].totalDue}
                  </div>
                  <div className="commitmentBtn">
                    <button className="new-blue-btn">{renewingPrescriber ? 'RENEW' : 'ENROLL'} for
                      ${selectedPlan.commitmentTypes[1].totalDue}</button>
                  </div>
                </div>
                {renewingPrescriber ?
                  <div className="m-b-20">

                    <Link to={`/settings/renew-eprescription/${renewingPrescriber.id}/${alternativePlan.planId}`}
                          className="line-btn m-t-10">
                      {renewingPrescriber.state.plan_id === selectedPlan.planId ?
                        `Change Existing Prescriber Plan to ${alternativePlan.planName}`
                        :
                        `Change back to ${alternativePlan.planName}`
                      }
                    </Link>
                  </div>
                  :
                  <div className="row text-center initial-setup-text">
                    *Enrolling in a subscription option does not guarantee approval. Prescriber eligibility is
                    determined by ScriptSure during the onboarding process. Prescriber must comply with the End User
                    Licensing Agreement and any additional requirements put forth by ScriptSure to access this feature.
                    In the event a Prescriber is unable to meet the ScriptSure qualifications for enrollment, Aesthetic
                    Record will return any portion of the unused funds to the account.
                  </div>
                }
              </div>
            </div>
          }


          {selectedCommitment != null && step == 3 && renewingPrescriber === null &&
          <div className="setting-setion m-h-400">
            <div className="setting-container p2030">
              <div className="setting-title m-t-10">Enroll Provider in Preferred Plan</div>
              <div className="row m-b-20">
                <div className="col-sm-6 col-xs-12">
                  <div className="newInputFileldOuter eprescriptionDropdown">
                    {defaultProviders.length > 0 &&
                    <>
                      <div className="newInputLabel">Select Your Provider<span className="setting-require">*</span>
                      </div>
                      <div className={providerError ? "setting-input-outer field-error" : "setting-input-outer"}>
                      {params.userId ? 
                          <input readOnly className="newInputField" value={`${prescribingSupportUser?.firstname} ${prescribingSupportUser?.lastname}`} />
                          :
                        <Select
                          onChange={(value) => handleProviderChange(value)}
                          name={"provider_id"}
                          value={providerId}
                          isClearable={false}
                          isSearchable
                          options={defaultProviders}
                          isMulti={false}
                        />}
                      </div>
                    </>
                    }
                    {defaultProviders.length == 0 &&
                    <div className="newInputLabel">No Provider available to add as Prescriber</div>
                    }
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="settings-subtitle m-b-10">Prescriber Info</div>
                  {/*<div className="newInputFileldOuter">
																							<div className="newInputLabel">Clinic Name<span className="setting-require">*</span></div>
																							<input className="newInputField" type="text" value={clinicName} onChange={e => setClinicName(e.target.value)}/>
																						</div>*/}
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Email<span className="setting-require">*</span></div>
                    <input readOnly className={(providerEmailError) ? "newInputField field-error" : "newInputField"}
                           value={providerEmail} onChange={e => setProviderEmail(e.target.value)}/>
                  </div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">NPI<span className="setting-require">*</span></div>
                    <input className={(npiError) ? "newInputField field-error" : "newInputField"} value={npi}
                           onChange={e => setNpi(e.target.value)}/>
                  </div>
                  {selectedPlan.planId == 1 &&
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">DEA<span className="setting-require">*</span></div>
                    <input className={(deaError) ? "newInputField field-error" : "newInputField"} value={dea}
                           onChange={e => setDea(e.target.value)}/>
                  </div>
                  }
                  {/*<div className="newInputFileldOuter">
																							<div className="newInputLabel">Provider License Number<span className="setting-require">*</span></div>
																							<input className="newInputField" type="text" value={licenseNumber} onChange={e => setLicenseNumber(e.target.value)}/>
																						</div>
																						<div className="newInputFileldOuter">
																							<div className="newInputLabel">State<span className="setting-require">*</span></div>
																							<input className="newInputField" type="text" value={state} onChange={e => setState(e.target.value)}/>
																						</div>*/}
                </div>
                <div className="col-sm-6 col-xs-12">
                  <div className="confirmEnrollOuter">
                    <p className="m-b-20">{selectedPlan.planTitle}<br/> {selectedCommitment.name}</p>
                    <p>${selectedCommitment.totalDue} Due Today</p>
                    <a className="new-blue-btn m-l-0" onClick={() => confirmEnrollment()}>Confirm Enrollment</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

          {selectedCommitment != null && step == 3 && renewingPrescriber !== null &&
          <div className="setting-setion m-h-400">
            <div className="setting-container p2030">
              <div className="setting-title m-t-10">Renew Provider in Preferred Plan</div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="settings-subtitle m-b-10">Prescriber Info</div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Provider<span className="setting-require">*</span></div>
                    <input className="newInputField"
                           value={`${renewingPrescriber.first_name} ${renewingPrescriber.last_name}`} disabled={true}/>
                  </div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Email<span className="setting-require">*</span></div>
                    <input className="newInputField" value={renewingPrescriber.email} disabled={true}/>
                  </div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">NPI<span className="setting-require">*</span></div>
                    <input className="newInputField" value={renewingPrescriber.npi} disabled={true}/>
                  </div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">DEA<span className="setting-require">*</span></div>
                    <input className="newInputField" value={renewingPrescriber.dea} disabled={true}/>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <div className="confirmEnrollOuter">
                    <p className="m-b-20">{selectedPlan.planTitle}<br/> {selectedCommitment.name}</p>
                    <p>${selectedCommitment.id === 1 ? finalPrice : selectedCommitment.totalDue} Due Today</p>
                    <a className="new-blue-btn m-l-2" onClick={() => confirmRenewal()}>Confirm Renewal</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

          {step == 4 &&
          <div className="setting-setion m-h-400">
            <div className="setting-container p2030">
              {Object.keys(params) == 0 && adminType != 'admin' &&
              <div className="setting-title m-t-10">Enroll at least One Support User in Plan</div>}
              {adminType == 'admin' &&
              <div className="setting-title m-t-10">Enroll as a Support User in Plan</div>
              }
              {defaultUsers && defaultUsers.length > 0 &&
              <div className="row m-b-20">
                <div className="col-sm-6 col-xs-12">
                  <div className="newInputFileldOuter eprescriptionDropdown">
                    <div className="newInputLabel">Select Support User<span className="setting-require">*</span></div>
                    <div className="setting-input-outer">
                      <Select
                        onChange={(value) => {
                          handleSupportUserChange(value)
                        }}
                        name={"support_provider_id"}
                        value={supportProviderId}
                        isClearable={false}
                        isSearchable
                        options={defaultUsers}
                        isMulti={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              }
              {selectedSupportAgent && Object.keys(selectedSupportAgent).length > 0 &&
              <div className="row">
                <div className="col-sm-6">
                  <div class="detail-row agentPlan"><label>Support User
                    Name: </label><span>{displayName(selectedSupportAgent)}</span></div>
                  <div class="detail-row agentPlan"><label>Support User Email:</label>{selectedSupportAgent.email_id}
                  </div>
                </div>
              </div>
              }

              {adminType != 'admin' &&
              <div className="row m-b-20">
                <div className="col-sm-6">
                  <a className="new-blue-btn m-l-0" onClick={() => {
                    setStep(5);
                    setSupportProviderId(0);
                    setSupportLastName('');
                    setSupportFirstName('');
                    setSelectedSupportAgent({});
                    setIsExternalAgent(true)
                  }}>Add external support agent</a>
                </div>
              </div>
              }
              <a className={(selectedSupportAgent.email_id) ? "new-blue-btn m-l-0" : "no-display"} onClick={() => {
                addSupportAgent()
              }}>Confirm Enrollment</a>
              {adminType != 'admin' && <div className="confirmEnrollOuter m-t-20">
                <p className="m-b-20">In order to activate ERX privileges for a Prescriber, there must be a Support
                  User associated with the Practice. If you do not have an Aesthetic Record teammate to add as a
                  Support User, you may invite an external user to fulfill this role. This Support User will not be
                  added your Aesthetic Record account, and you will not be charged an ERX fee for this Support
                  User.</p>
              </div>
              }
            </div>
          </div>
          }
          {selectedCommitment != null && step == 5 &&
          <div className="setting-setion m-b-10 m-h-400">
            <div className="setting-container p2030">
              <div className="setting-title m-t-10">Enroll an External Support User in Plan</div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="settings-subtitle m-b-10">External Support User Info</div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">First Name<span className="setting-require">*</span></div>
                    <input className={(supportFirstNameError) ? "newInputField field-error" : "newInputField"}
                           type="text" value={supportFirstName} onChange={e => setSupportFirstName(e.target.value)}/>
                  </div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Last Name<span className="setting-require">*</span></div>
                    <input className={(supportLastNameError) ? "newInputField field-error" : "newInputField"}
                           type="text" value={supportLastName} onChange={e => setSupportLastName(e.target.value)}/>
                  </div>
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Email<span className="setting-require">*</span></div>
                    <input className={(supportEmailError) ? "newInputField field-error" : "newInputField"} type="text"
                           value={supportEmail} onChange={e => setSupportEmail(e.target.value)}/>
                  </div>
                </div>
              </div>
              <div className="confirmEnrollOuter m-t-20">
                <p className="m-b-20">
                  Please note, an External Support User will not have access to any Aesthetic Record portal. The
                  function of this agent is for administrative verification of providers only. In order to view or
                  manage Patient Charts, the External Support User must be added as a user to the monthly AR
                  subscription plan.
                </p>
                <a className="new-blue-btn m-l-0" onClick={() => addSupportAgent()}>{"Confirm Enrollment"}</a>
              </div>
            </div>
          </div>
          }
          {step == 6 &&
          <div className="setting-setion m-h-400">
            <div className="setting-container p2030">
              <div className="success-msg m-0">
                <div className="successIcon"><i className="fa fa-check"></i></div>
                <h2>Success!</h2>
                <h4>Thanks for Enrolling Your Practice in the Aesthetic Record Cloud ERX Program.</h4>
                <p>You will receive an email invitation shortly that contains a unique link to onboard your practice to
                  the eRX platform. In order to enroll additional Prescribers or Support Users, you must set up your
                  practice and complete your enrollment first. Once you have successfully done so, you may return to
                  this menu and continue enrolling additional users from your AR account. Please reach out to our
                  Customer Support Team via chat or email at <a className="easy-link"
                                                                href="mailto:info@aestheticrecord.com">info@aestheticrecord.com</a> if
                  you do not receive this invitation within 24 hours</p>
                {/*<a className="new-blue-btn m-t-20" onClick={() => setStep(7)}>Add More Users</a>*/}
              </div>

            </div>
          </div>
          }

          {step == 7 &&
          <div className="setting-setion m-h-400">
            <div className="setting-container p2030">
              <div className="setting-title m-t-10">Select the type of user to add</div>

              <div className="row m-t-10">
                <div className="col-sm-4 col-xs-12">
                  <div className="enroll-user-outer m-b-10 m-t-0" onClick={() => {
                    selectPlan(1, 1);
                    setStep(2)
                  }}>

                    <label htmlFor="EPCS">
                      Enroll Another Provider in the
                      Cloud ERX ePrescribe +
                      Electronically Prescribed
                      Controlled Substances (EPCS)
                    </label>
                  </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                  <div className="enroll-user-outer m-b-10 m-t-0" onClick={() => {
                    selectPlan(2, 1);
                    setStep(2)
                  }}>

                    <label htmlFor="notECPS">
                      Enroll Another Provider in the
                      Cloud ERX ePrescribe non-EPCS
                      Option
                    </label>
                  </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                  <div className="enroll-user-outer m-b-10 m-t-0" onClick={() => {
                    openAddSupport()
                  }}>

                    <label htmlFor="notECPS">
                      Enroll A Free Support User in
                      Cloud ERX ePrescribe

                      (Up to 5 Free Support Users
                      Allowed per Prescriber)
                    </label>
                  </div>
                </div>
              </div>


            </div>
          </div>
          }
        </div>
        }
      </div>
      <Loader showLoader={showLoader} isFullWidth={true}/>
    </div>
  )
}

function mapStateToProps (state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {
    redirectToListing: false
  };
  if (state.SettingReducer.action === "PROVIDERS_LIST") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoadFromProps = new Date()
    } else {
      returnState.allProviders = state.SettingReducer.data.data.users;
      returnState.allProvidersTime = new Date()
    }
  }
  if (state.SettingReducer.action === "GET_EPRESCRIPTION_PLANS") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoadFromProps = new Date()
    } else {
      returnState.allPlans = state.SettingReducer.data.data
    }
  }
  if (state.SettingReducer.action === "SUPPORT_USERS_LIST") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoadFromProps = new Date()
    } else {
      returnState.allUsers = state.SettingReducer.data.data.users;
      returnState.amountEnrollUsers = state.SettingReducer.data.data.amountEnrollUsers;
    }
  }
  if (state.SettingReducer.action === "ADD_PRESCRIBER") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoadFromProps = new Date()
    } else {
      returnState.prescribers = state.SettingReducer.data.data
    }
  }
  if (state.SettingReducer.action === 'GET_PRESCRIBER') {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoadFromProps = new Date();
      returnState.redirectToListing = true;
    }
  }
  if (state.SettingReducer.action === 'RENEW_PRESCRIBER') {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoadFromProps = new Date();
    }
    returnState.redirectToListing = true;
  }
  if (state.SettingReducer.action === "ADD_SUPPORT_STAFF") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoadFromProps = new Date()
    } else {
      returnState.support_agents = state.SettingReducer.data.data
    }
  }
  returnState.prescribingSupportUser = state.SettingReducer.prescribingSupportUser;
  returnState.selectedPrescriber = state.SettingReducer.selectedPrescriber?.data || null;

  return returnState;
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getProvidersList: getProvidersList,
    getPlans: getPlans,
    addPrescriber: addPrescriber,
    getAllUsers,
    getPrescriber,
    renewPrescriber,
    addSupportStaff
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Prescription);
