import { useEffect } from "react";
import { getPathParams } from "../utilities";
import { ROUTES } from "../../../consts/routes";
import { uiNotification } from "../../../services/UINotificationService";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { history } from "../../../history";

export function useGuard() {
  var { tAuth: t } = useAppTranslation.Auth();
  var { wpuId } = getPathParams();

  useEffect(() => {
    if (!wpuId) {
      uiNotification.error(t("changePassword.error.invalidWpuId"));
      history.push(ROUTES.login());
    }
  }, [wpuId]);
}
