const initialState = {
  referralSources: { status: "pending", data: null },
  showLoader: false,
};

const referralSources = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REFERRAL_SOURCES_LOADING": {
      return { ...state, showLoader: true };
    }
    case "GET_REFERRAL_SOURCES_SUCCESS": {
      return {
        ...state,
        referralSources: { status: "success", data: action.payload.data },
        showLoader: false,
      };
    }
    case "GET_REFERRAL_SOURCES_ERROR": {
      return { ...state, showLoader: false };
    }
    case "CHANGE_REFERRAL_SOURCES_SUCCESS": {
      return {
        ...state,
        referralSources: {
          status: "success",
          data: action.payload.data.sources,
        },
        showLoader: false,
      };
    }
    case "ADD_REFERRAL_SOURCES_SUCCESS": {
      return {
        ...state,
        referralSources: {
          status: "success",
          data: action.payload.data.sources,
        },
        showLoader: false,
      };
    }
    case "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS": {
      return {
        ...state,
        referralSources: {
          status: "success",
          data: action.payload.data.sources,
        },
        showLoader: false,
      };
    }

    default:
      return state;
  }
};

export default referralSources;
