import React from "react";

export const SaveDiskIcon = ({ width }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width }}
    >
      <path
        d="M15.8763 0.325134H2.33997C1.11565 0.325134 0.115967 1.46366 0.115967 2.85519V19.3005C0.115967 20.6921 1.11565 21.8306 2.33997 21.8306H16.796C18.0203 21.8306 19.02 20.6921 19.02 19.3005V4.28088L15.8763 0.325134ZM14.572 7.9153C14.572 8.6098 14.0716 9.18033 13.46 9.18033H5.67597C5.06437 9.18033 4.56397 8.6098 4.56397 7.9153V1.59016H14.572V7.9153ZM11.236 2.85519H13.46V7.9153H11.236V2.85519Z"
        fill="#047EC3"
      />
    </svg>
  );
};
