import { useChangePasswordMutation } from "../../../api/mutations/useChangePasswordMutation";
import { usePingServerMutation } from "../../../api/mutations/usePingServerMutation";
import { ROUTES } from "../../../consts/routes";
import { logout } from "../../../helpers/auth";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";
import { history } from "../../../history";

export function useSubmit({ wpuId, onSuccess, checkAuthBeforeSubmit }) {
  var { tCommon: t } = useAppTranslation.Common();
  var pingServer = usePingServerMutation();

  var { isLoading, mutate } = useChangePasswordMutation({
    onSuccess: () => {
      uiNotification.success(t("changePassword.success.submit"));
      onSuccess?.();
    },
    onError: (e) => {
      const message = extractApiError(e);
      uiNotification.error(
        t([
          `changePassword.apiError.${message}`,
          "changePassword.error.submit",
        ]),
      );
    },
  });

  /**
   * @param {{
   *    oldPassword: string;
   *    newPassword: string;
   *    otp: number;
   *    passCode: string | number;
   * }} formValues
   */
  var initiate = (formValues) => {
    var submit = () =>
      mutate({
        wpu_id: wpuId,
        new_password: formValues.newPassword,
        old_password: formValues.oldPassword,
        otp: String(formValues.otp),
        pass_code: formValues.passCode || undefined,
      });

    if (checkAuthBeforeSubmit) {
      pingServer
        .mutateAsync()
        .then(submit)
        .catch(() => {
          uiNotification.error(t("changePassword.error.sessionExpired"));
          logout();
          history.push(ROUTES.login());
        });
    } else {
      submit();
    }
  };

  return {
    isLoading: isLoading || pingServer.isLoading,
    initiate,
  };
}
