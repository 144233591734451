import React from "react";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { withRouter } from "react-router";
import { Box } from "../../../../../../shared/Box/Box";
import { LayoutMain } from "../../../../../../boxes/LayoutMain/LayoutMain";
import styles from "../scss/addDocument.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import FormContainer from "./FormContainer";
import { SETTINGS_ROUTES } from "../../../../../../consts/routes";
import { useUploadStripeDocument } from "../hooks/useUploadStripeDocument";
import { schema } from "../utils";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../../../services/UINotificationService";

const AddDocument = ({ history }) => {
  const onClose = () => {
    history.push(SETTINGS_ROUTES.posStripeDocuments());
  };

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUploadStripeDocument({
    onSuccess: () => {
      queryClient.invalidateQueries("stripeDocuments");
    },
  });

  const { values, errors, setValues, handleSubmit } = useFormik({
    validationSchema: schema,
    onSubmit: ({ documents }) => {
      documents.forEach((document) => {
        const formData = new FormData();
        formData.append("file", document.file);
        formData.append("file_name", document.name);
        formData.append("file_type", document.type);
        formData.append("clinic_id", document.clinic);

        mutate(formData, {
          onSuccess: onClose,
          onError: (error) => uiNotification.error(error.response.data.message),
        });
      });
    },
    initialValues: {
      documents: [
        {
          name: "",
          type: "",
          clinic: "",
          file: null,
        },
      ],
    },
  });

  const handleChange = (index, field, value) => {
    const updatedDocuments = values.documents.map((doc, i) =>
      i === index ? { ...doc, [field]: value } : doc,
    );

    setValues({
      ...values,
      documents: updatedDocuments,
    });
  };

  const handleAddDocument = () => {
    setValues((prevValues) => ({
      documents: [
        ...prevValues.documents,
        { name: "", type: "", clinic: "", file: "" },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    const updatedDocuments = values.documents.filter((_, i) => i !== index);
    setValues({
      ...values,
      documents: updatedDocuments,
    });
  };

  return (
    <LayoutMain>
      <Box className={styles.container}>
        <div className={styles.headerRow}>
          <h4>Add Document</h4>
          <button onClick={onClose} className={styles.closeButton}>
            <img src="/images/close.png" alt="close" />
          </button>
        </div>
        <span>Document Information</span>

        {values.documents.map((document, idx) => (
          <div key={idx} className={styles.uploadFormContainer}>
            <FormContainer
              index={idx}
              fileName={document.name}
              fileType={document.type}
              uploadedFile={document.file}
              clinic={document.clinic}
              handleChange={handleChange}
              handleRemoveDocument={handleRemoveDocument}
              errors={errors.documents}
            />
            {values.documents.length > 1 && (
              <button onClick={() => handleRemoveDocument(idx)}>-</button>
            )}
          </div>
        ))}

        <div className={styles.addMoreContainer}>
          <span>Allowed file types: Images, PDF documents</span>
          <Button size="small" onClick={handleAddDocument}>
            Add More Documents
          </Button>
        </div>
        <div className={styles.footer}>
          <Button
            size="small"
            variant="outlined"
            onClick={onClose}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleSubmit}
            isDisabled={isLoading}
            leftAdornment={
              isLoading ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            Save
          </Button>
        </div>
      </Box>
    </LayoutMain>
  );
};

export default withRouter(AddDocument);
