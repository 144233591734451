import { useEditProductPriceListMutation } from "../../../../../../api/mutations/useEditProductPriceListMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { productPricingMember } from "../../../../../../store/inventory/productPricingMember";
import { productPricingRegular } from "../../../../../../store/inventory/productPricingRegular";
import { dispatch } from "../../../../../../store/store";

export const useUpdate = (refetch) => {
  const { mutate, isLoading } = useEditProductPriceListMutation({
    onError: () => {
      uiNotification.error("Failed to update price list");
    },
    onSuccess: () => {
      uiNotification.success("Price list updated successfully");
      refetch().then(() => {
        dispatch(productPricingRegular.actions.setInitialized(false));
        dispatch(productPricingMember.actions.setInitialized(false));
      });
    },
  });

  /**
   * @param {{
   *    productId: string | number;
   *    priceList: Array<object>;
   *    isRegularPrice?: boolean;
   *    isForAllClinics?: boolean;
   * }} param0
   */
  const initiate = ({
    productId,
    priceList,
    isRegularPrice,
    isForAllClinics,
  }) => {
    mutate({
      product_id: Number(productId),
      price_list: priceList || [],
      regular_price: Boolean(isRegularPrice),
      price_same_for_all_clinic: isForAllClinics,
    });
  };

  return {
    initiate,
    isLoading,
  };
};
