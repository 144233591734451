import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchAppointmentConsents,
  fetchHealthProcedureConsents,
} from "../../../Actions/Clients/clientsAction";
import { showFormattedDate } from "../../../Utils/services";
import { Header } from "./Header";
import { withCurrentAccountQuery } from "../../../api/queries/useAccountQuery";
import { uiNotification } from "../../../services/UINotificationService";

class DocumentConsents extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      consentID: props.match.params.consentID,
      clientID: props.match.params.clientID,
      appointmentID: props.match.params.appointmentID
        ? props.match.params.appointmentID
        : "",
      globalLang: languageData.global,
      type: props.match.params.type,
      consent: {},
      savedConsent: {},
      patient: {},
    };
  }

  componentDidMount() {
    this.fetchConsent();
  }

  fetchConsent = () => {
    this.setState({ showLoader: true });
    const initiate = () => {
      if (this.state.type == "appointment") {
        let formData = {
          patient_consent_id: this.state.consentID,
          appointment_id: this.state.appointmentID,
          patient_id: this.state.clientID,
        };
        return this.props.fetchAppointmentConsents(formData);
      } else if (this.state.type == "health-appointment") {
        let formData = {
          patient_consent_id: this.state.consentID,
          procedure_id: this.state.appointmentID,
          patient_id: this.state.clientID,
        };
        return this.props.fetchHealthProcedureConsents(formData);
      }
    };
    initiate()
      .then((data) => {
        this.setState({
          consent: data?.data?.consent || {},
          savedConsent: data?.data?.saved_consent || {},
          patient: data?.data?.patient || {},
        });
      })
      .catch(() => {
        uiNotification.error("Unable to fetch consent data. Try again later");
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="wide-popup">
            <div className="modal-blue-header">
              <a
                onClick={() => this.props.history.goBack()}
                className="popup-cross"
              >
                ×
              </a>
              <span className="popup-blue-name">
                {this.state.consent?.consent_name || ""}
              </span>
            </div>
            {this.state.consent?.consent_large_description && (
              <div className="wide-popup-wrapper time-line consent-wide-popup">
                <div className="row">
                  <div className="col-xs-12">
                    <Header clientId={this.state.clientID} />
                    <div
                      className="p-text whitespace-pre-wrap"
                      dangerouslySetInnerHTML={{
                        __html:
                          this.state.consent?.consent_large_description || "",
                      }}
                    ></div>
                    <div className="p-text whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: (this.state.consentData && this.state.consentData.consent ? this.state.consentData.consent.consent_large_description : '')}}></div>
                  </div>
                </div>
                <div className="row p-t-50">
                  {this.state.savedConsent
                    ?.witness_signature_thumb_image_url && (
                    <div className="col-xs-6">
                      {this.state.savedConsent
                        ?.witness_signature_thumb_image_url && (
                        <div className="div-consent-signature witness-signature">
                          <div>
                            <img
                              src={
                                this.state.savedConsent
                                  .witness_signature_thumb_image_url
                              }
                              className="consent-signature imp-witness-signature"
                            />
                          </div>
                          {this.state.savedConsent?.signed_on && (
                            <label className="signed-date">
                              Date:{" "}
                              {showFormattedDate(
                                this.state.savedConsent.signed_on,
                                true,
                              )}
                            </label>
                          )}
                          <label className="signed-label full-width">
                            Witness Signature
                          </label>
                          {this.state.savedConsent?.witness_name && (
                            <label className="signed-label full-width">
                              {this.state.savedConsent.witness_name}
                            </label>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.savedConsent?.signature_image && (
                    <div className="col-xs-6 pull-right">
                      <div className="div-consent-signature patient-signature pull-right">
                        <div>
                          <img
                            src={
                              this.state.savedConsent.signature_thumb_image_url
                            }
                            className="consent-signature imp-patient-signature pull-right"
                          />
                        </div>
                        <b className="signed-label text-right pull-left full-width">
                          Date:{" "}
                          {showFormattedDate(
                            this.state.savedConsent?.signed_on,
                            true,
                          )}
                        </b>
                        <label className="signed-label text-right pull-left full-width">
                          {this.props.currentAccountQuery?.data?.preferences
                            ?.clientGlobalName || "Client"}{" "}
                          Signature
                        </label>
                        {this.state.patient?.full_name && (
                          <label className="signed-label text-right pull-left full-width">
                            {this.state.patient.full_name}
                          </label>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            className={
              this.state.showLoader
                ? "new-loader text-left displayBlock proDetailLoader"
                : "new-loader text-left"
            }
          >
            <div className="loader-outer">
              <img
                id="loader-outer"
                src="/images/Eclipse.gif"
                className="loader-img"
              />
              <div id="modal-confirm-text" className="popup-subtitle">
                {this.state.globalLang.Please_Wait}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAppointmentConsents,
      fetchHealthProcedureConsents,
    },
    dispatch,
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(withCurrentAccountQuery(DocumentConsents)));
