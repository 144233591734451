import { useMemo, useState } from "react";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";
import {
  DEFAULT_SEARCH_DELAY,
  DEFAULT_SEARCH_TERM,
} from "../Workspaces.consts";
import {
  excludeInactiveInGroupedEntry,
  implodeGroupedEntries,
  isWorkspaceInactive,
  searchInGroupedEntry,
} from "../Workspaces.utils";

export function useWorkspacesModifiers(groupedWorkspaces) {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState(
    DEFAULT_SEARCH_TERM,
    DEFAULT_SEARCH_DELAY,
  );

  const [isInactiveVisible, setIsInactiveVisible] = useState(false);

  const modifiedWorkspaces = useMemo(() => {
    let result = Object.entries(groupedWorkspaces);

    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      result = result.reduce(
        (acc, entry) => searchInGroupedEntry(acc, entry, debouncedSearchTerm),
        [],
      );
    }

    if (!isInactiveVisible) {
      result = result.reduce(
        (result, groupedEntry) =>
          excludeInactiveInGroupedEntry({
            result,
            groupedEntry,
            isInactivePredicate: isWorkspaceInactive,
          }),
        [],
      );
    }

    return result.reduce(implodeGroupedEntries, {});
  }, [debouncedSearchTerm, groupedWorkspaces, isInactiveVisible]);

  return {
    search: {
      value: searchTerm,
      onChange: setSearchTerm,
    },
    inactiveVisible: {
      value: isInactiveVisible,
      onToggle: () => setIsInactiveVisible((prev) => !prev),
    },
    modifiedWorkspaces,
  };
}
