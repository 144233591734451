import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useStripeDocuments = (params, options = {}) => {
  return useQuery(
    ["stripeDocuments", params],
    async () => {
      return http.get(HTTP_ENDPOINTS.getStripeDocuments(), { params });
    },
    { cacheTime: 0, ...options },
  );
};
