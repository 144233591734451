/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import moment from "moment";
import { withRouter } from "react-router";
import {
  formatInboxTime,
  showFormattedDate,
  getTimeFormat,
  getDateFormat,
} from "../../Utils/services.js";
import { calulateDiffDuration } from "../../Utils/date-services.js";

class ClockOutModal extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang:
        languageData && languageData.global ? languageData.global : {},
      currentDate: moment().format(getDateFormat()),
      currentTime: moment().format(getTimeFormat()),
    };
  }

  dismissClockOutModal = () => {
    this.props.dismissClockOutModal();
  };

  handleClockAction = () => {
    this.props.handleClockAction();
  };

  render() {
    const diff = calulateDiffDuration(this.props.clock_in);
    const diffLabel = (() => {
      const hours = diff.hours === 1 ? "Hour" : "Hours";
      return `${diff.hours} ${hours} ${diff.minutes}  Min`;
    })();

    return (
      <div className="clockOut-modal-wrapper">
        <div
          className={
            this.props.isShowClockOutModal ? "modalOverlay" : "no-display"
          }
        >
          <div className="small-popup-outer subscription-popup">
            <div className="small-popup-header p-l-15">
              <div className="popup-name">{this.props.clock_label}</div>
              <a className="small-cross" onClick={this.dismissClockOutModal}>
                ×
              </a>
            </div>
            <div className="small-popup-content clockContent">
              <div className="juvly-container">
                <div className="clockTime">{this.state.currentTime}</div>
                <div className="clockDate">{this.state.currentDate}</div>
                <div className="clockName">Hello, {this.props.user_name}</div>
                {this.props.clock_action_current === "in" &&
                  this.props.clock_in && (
                    <p className="clockDetail">
                      You clocked in on{" "}
                      <span>{showFormattedDate(this.props.clock_in)}</span> at{" "}
                      <span>{formatInboxTime(this.props.clock_in)}</span>
                    </p>
                  )}
                {this.props.clock_action_current === "out" &&
                  this.props.clock_out && (
                    <p className="clockDetail">
                      You clocked out on{" "}
                      <span>{showFormattedDate(this.props.clock_out)}</span> at{" "}
                      <span>{formatInboxTime(this.props.clock_out)}</span>
                    </p>
                  )}
                {this.props.clock_action_current === "in" && (
                  <div className="ClockOuter">
                    <div className="clockSection col-sm-5 col-xs-12 text-left">
                      <span>
                        {this.props.clock_in
                          ? formatInboxTime(this.props.clock_in)
                          : "00:00"}
                      </span>
                      <p>Start Time</p>
                    </div>
                    <div className="clockSection col-sm-2 col-xs-12 text-center no-padding">
                      <img alt="" src="/images/clock.png" />
                    </div>
                    <div className="clockSection col-sm-5 col-xs-12 text-right">
                      <span>{diffLabel}</span>
                      <p>Duration</p>
                    </div>
                  </div>
                )}
                <div className="text-center">
                  <a
                    onClick={this.handleClockAction}
                    className="new-blue-btn clockOutBtn"
                  >
                    {this.props.clock_label}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClockOutModal);
