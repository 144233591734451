/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InboxSidebar from "../InboxSidebar.js";
import Loader from "../../Common/Loader.js";
import ReportsFilters from "../../BusinessInsights/ReportsFilters.js";
import {
  getOptionAndList,
  getOptionsOnly,
  saveSmsFilterReport,
  getFilterReportData,
  deleteList,
} from "../../../Actions/Inbox/inboxAction.js";
import {
  fromToDateRange,
  getCurrencySymbol,
  autoScrolling,
} from "../../../Utils/services.js";
import picClose from "../../../_legacy/images/popupClose.png";
import ComingSoon from "../../Common/ComingSoon.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const FromToMonthDate = fromToDateRange("mtd");
const ApiDateFormat = "YYYY-MM-DD";

const initReportFilter = () => {
  return {
    date: "mtd",
    from_date: FromToMonthDate.from_date,
    to_date: FromToMonthDate.from_date,
    service: "",
    month_of_birth: "1",
    spent_money: "",
    discount_type: "0",
    provider: "0",
    cashier: "0",
    product: "",
    cancellation_fee_charge: false,
    lot_number: "",
    month: "1",
    month_year: "2017-01",
    year: "2017",
    clinic: "0",
    filterListDataShow: false,
  };
};

const initReportFilterClass = () => {
  return {
    date: "simpleSelect",
    from_date: FromToMonthDate.from_date,
    to_date: FromToMonthDate.from_date,
    service: "simpleSelect",
    month_of_birth: "simpleSelect",
    spent_money: "TP-discount-outer noShadow",
    discount_type: "simpleSelect",
    provider: "simpleSelect",
    cashier: "simpleSelect",
    product: "simpleSelect",
    cancellation_fee_charge: false,
    lot_number: "simpleInput",
    month: "simpleSelect",
    month_year: "simpleSelect",
    year: "simpleSelect",
  };
};

class List extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      languageData: languageData.business_insights,
      businessInsightLang: languageData.business_insights,
      salesLang: languageData.sales,
      is_edit_card: false,
      showClients: false,
      showCreateOptions: false,
      createSMS: true,
      reportNameError: true,
      page: 1,
      pagesize: 20,
      sortorder: "asc",
      term: "",
      mode: "all",
      smsList: [],
      templates: [],
      showTableSection: true,
      reportFilter: JSON.parse(JSON.stringify(initReportFilter())),
      reportFilterClass: initReportFilterClass(),
      saveListPage: false,
      showNameSection: false,
      filterListData: [],
      next_page_url: "",
      tableColumns: [],
      clinicList: [],
    };
    window.onscroll = () => {
      return false;
    };
  }

  render() {
    return <ComingSoon sidebar={"inbox"} />;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    autoScrolling(true);
    this.props.getOptionAndList("2way-sms");
  };

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    if (name == "report_type_sysname") {
      this.setState({ [event.target.name]: value, showLoader: true }, () => {
        this.props.getOptionsOnly(value);
      });
    } else {
      this.setState({ [event.target.name]: value });
    }
  };

  createSMSOpener = () => {
    this.setState({ createSMS: false });
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = this.state.reportFilter;
      formData.page = this.state.page;
      formData.pagesize = this.state.pagesize;
      let id = this.state.report_type_sysname;
      autoScrolling(true);
      this.props.getFilterReportData(id, formData);
    }
  };

  filterSMS = (mode) => {
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        read: mode == "all" ? 0 : 1,
      },
    };
    this.setState({ showLoader: true, mode: mode, smsList: [] });
    autoScrolling(true);
    this.props.listSMS(formData);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.errorTime != undefined &&
      nextProps.errorTime != prevState.errorTime
    ) {
      returnState.errorTime = nextProps.errorTime;
      returnState.showLoader = false;
    }

    if (
      nextProps.smsList != undefined &&
      nextProps.smsListTime != prevState.smsListTime
    ) {
      returnState.showLoader = false;
      returnState.saveListPage = false;
      returnState.showTableSection = true;
      returnState.smsList = nextProps.smsList.data.reports;
      returnState.smsListTime = nextProps.smsListTime;
      returnState.smsTemplate = nextProps.smsList.data.templates;
      returnState.showConfirmation = false;
      returnState.filterListDataShow = false;
      returnState.filterListData = [];
      returnState.reportName = "";
      returnState.reportNameError = true;
    }

    if (
      nextProps.optionsList != undefined &&
      nextProps.optionsListTime != prevState.optionsListTime
    ) {
      returnState.showLoader = false;
      returnState.reportVariableData = nextProps.optionsList.data.data;
      returnState.optionsListTime = nextProps.optionsListTime;
      returnState.reportFilter = JSON.parse(JSON.stringify(initReportFilter()));
      returnState.report_type_sysname =
        nextProps.optionsList.data.data[0].sms_list_filter_id;
      if (prevState.reportID) {
        returnState.showCreateOptions = true;
        if (
          nextProps.optionsList.data &&
          nextProps.optionsList.data.selected_data.length > 0
        ) {
          nextProps.optionsList.data.selected_data.map((obj) => {
            if (obj.variable === "product") {
              returnState.reportFilter.product = obj.value ? obj.value : "";
            }
            if (obj.variable === "provider") {
              returnState.reportFilter.provider = obj.value ? obj.value : 0;
            }
            if (obj.variable === "month_year") {
              returnState.reportFilter.month_year = obj.value;
            }
            if (obj.variable === "year") {
              returnState.reportFilter.year = obj.value;
            }
            if (obj.variable === "month_of_birth") {
              returnState.reportFilter.month_of_birth = obj.value;
            }
            if (obj.variable === "spent_money") {
              returnState.reportFilter.spent_money = obj.value;
            }
            if (obj.variable === "cashier") {
              returnState.reportFilter.cashier = obj.value ? obj.value : 0;
            }

            if (obj.variable === "clinic") {
              returnState.reportFilter.clinic = obj.value ? obj.value : 0;
            }
            if (obj.variable === "discount_type") {
              returnState.reportFilter.discount_type = obj.value
                ? obj.value
                : 0;
            }
            if (obj.variable === "service") {
              returnState.reportFilter.service = obj.value ? obj.value : 0;
            }
            if (obj.variable === "date") {
              returnState.reportFilter.date = obj.value;

              if (
                obj.value !== "qtd" &&
                obj.value !== "mtd" &&
                obj.value !== "ytd"
              ) {
                returnState.reportFilter.date = "custom";
                if (obj.value) {
                  let dateArray = obj.value.split("|");
                  returnState.startDate = moment(dateArray[0]).toDate();
                  returnState.endDate = moment(dateArray[1]).toDate();

                  returnState.reportFilter.from_date = moment(
                    dateArray[0],
                  ).format(ApiDateFormat);
                  returnState.reportFilter.to_date = moment(
                    dateArray[1],
                  ).format(ApiDateFormat);
                }
              }
            }
          });
        }

        returnState.preSelectedData = {
          report_type_sysname: returnState.report_type_sysname,
          report_type_id: returnState.report_type_id,
          reportFilter: returnState.reportFilter,
        };
      }
    }

    if (
      nextProps.filterListData != undefined &&
      nextProps.filterListDataTime != prevState.filterListDataTime
    ) {
      returnState.showTableSection = false;
      returnState.showLoader = false;
      returnState.saveListPage = true;
      returnState.showCreateOptions = false;
      returnState.filterListDataShow = true;
      returnState.tableColumns = nextProps.filterListData.data.columns;
      if (prevState.filterListData.length == 0) {
        if (nextProps.filterListData.data.next_page_url !== null) {
          returnState.page = prevState.page + 1;
          autoScrolling(false);
        } else {
          returnState.next_page_url =
            nextProps.filterListData.data.next_page_url;
          autoScrolling(true);
        }
        returnState.filterListData = nextProps.filterListData.data.data;
      } else {
        if (nextProps.filterListData.data.next_page_url !== null) {
          returnState.page = prevState.page + 1;
          autoScrolling(false);
        } else {
          returnState.next_page_url =
            nextProps.filterListData.data.next_page_url;
          autoScrolling(true);
        }
        returnState.filterListData = [
          ...prevState.filterListData,
          ...nextProps.filterListData.data.data,
        ];
      }

      returnState.selectedGrammar = nextProps.filterListData.data.grammar;
      returnState.filterListDataTime = nextProps.filterListDataTime;
    }

    if (
      nextProps.downloadExcelTime != prevState.downloadExcelTime &&
      nextProps.file != undefined
    ) {
      returnState.showLoader = false;
      returnState.file = nextProps.file;
      returnState.downloadExcelTime = nextProps.downloadExcelTime;
      delete prevState.reportFilter["is_download"];
      returnState.reportFilter = prevState.reportFilter;
      window.open(
        process.env.REACT_APP_API_URL + "download-data/" + nextProps.file,
      );
    }

    return returnState;
  }

  handleClick = (e) => {
    if (
      this.refAutoSuggestionClients &&
      !this.refAutoSuggestionClients.contains(e.target)
    ) {
      this.setState({ showClients: false });
    }
  };

  selectClient = (id) => {
    this.props.history.push("/inbox/sms/create/" + id);
  };
  openChat = (id) => {
    this.props.history.push("/inbox/sms/edit/" + id);
  };

  handleCalendarChange = (childState) => {
    this.setState(childState);
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  saveReport = () => {
    let formData = this.state.reportFilter;
    formData.page = 1;
    formData.pagesize = this.state.pagesize;
    let id = this.state.report_type_sysname;
    this.setState({ showLoader: true });
    this.props.getFilterReportData(id, formData);
  };

  hideNameSection = () => {
    this.setState({ showNameSection: false });
  };

  saveThisReport = () => {
    let reportName = this.state.reportName;
    if (
      this.state.selectedGrammar === null ||
      this.state.selectedGrammar === undefined
    ) {
      uiNotification.clear();
      uiNotification.error("Grammar is missing");
      return false;
    }
    if (reportName && reportName.trim() !== "") {
      this.setState({
        showLoader: true,
        reportNameError: true,
        reportName: "",
        showNameSection: false,
      });

      let reportFilter = this.state.reportFilter;
      reportFilter.business_insight_id = this.state.reportFilter.report_type_id;
      reportFilter.name = reportName;
      reportFilter.sms_list_filter_id = this.state.report_type_sysname;
      reportFilter.sms_list_filter_report_id = this.state.reportID;
      if (this.state.selectedGrammar) {
        reportFilter.grammar = this.state.selectedGrammar;
      }
      if (this.state.reportID && this.state.reportID > 0) {
        reportFilter.business_insight_report_id = this.state.reportID;
      }
      this.props.saveSmsFilterReport(reportFilter);
    } else {
      this.setState({ reportNameError: false });
      return;
    }
  };
  downLoadReport = () => {
    let formData = this.state.reportFilter;
    formData.page = 1;
    formData.pagesize = this.state.pagesize;
    formData.is_download = 1;
    let id = this.state.report_type_sysname;
    this.setState({ showLoader: true });
    this.props.getFilterReportData(id, formData);
  };
  editList = (reportID, tId, name) => {
    this.setState(
      {
        showLoader: true,
        reportID: reportID,
        report_type_sysname: tId,
        reportName: name,
      },
      () => {
        this.props.getOptionsOnly(tId, reportID);
      },
    );
  };

  deleteThisReport = () => {
    this.setState({ showLoader: true });
    this.props.deleteList({ sms_list_filter_report_id: this.state.deleteId });
  };

  createList = () => {
    this.setState({
      reportFilter: JSON.parse(JSON.stringify(initReportFilter())),
      report_type_sysname: "",
      showCreateOptions: true,
      reportVariableData: undefined,
      reportName: "",
      reportNameError: true,
      reportID: undefined,
    });
  };

  render1() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InboxSidebar />

          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              {!this.state.saveListPage ? (
                <div className="membership-title">
                  {this.state.globalLang.header_reports}
                  <div className="memberRightActions">
                    <a className="new-blue-btn" onClick={this.createList}>
                      {this.state.globalLang.label_create_list}
                    </a>
                  </div>
                </div>
              ) : (
                <div className="membership-title">
                  <span
                    className="cursor-pointer"
                    onClick={this.redirectToMain}
                  >
                    {this.state.globalLang.label_lists}
                  </span>
                  <span className="breadCrumb">
                    <i className="fa fa-chevron-right" />{" "}
                    <span className="breadCrumb-text">
                      {this.state.globalLang.label_create_list}
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div
              className={
                this.state.saveListPage ? "setting-setion m-b-10" : "no-display"
              }
            >
              <div className="membership-title">
                <div>{this.state.selectedGrammar}</div>
                <div className="memberRightActions">
                  <a
                    onClick={this.downLoadReport}
                    className={
                      this.state.reportID
                        ? "easy-link no-padding"
                        : "no-display"
                    }
                  >
                    <i className="fa fa-download m-r-5" />
                    {"Download"}
                  </a>
                  <a
                    className={
                      this.state.reportID
                        ? "easy-link no-padding"
                        : "no-display"
                    }
                    onClick={() => this.setState({ showCreateOptions: true })}
                  >
                    <i className="fa fa-pencil-alt m-r-5" /> {"Edit"}
                  </a>
                  <a
                    className="new-blue-btn"
                    onClick={() => this.setState({ showNameSection: true })}
                  >
                    {this.state.globalLang.label_save_list}
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.showTableSection
                  ? "setting-setion m-b-15"
                  : "setting-setion m-b-15 no-display"
              }
            >
              <div className="table-responsive clients-table no-border">
                <table className="table-updated  table-min-width no-td-border no-hover">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="table-updated-th">
                        {this.state.globalLang.label_name}
                      </th>
                      <th className="table-updated-th">
                        {this.state.salesLang.sales_type}
                      </th>
                      <th className="table-updated-th"></th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.smsList &&
                      this.state.smsList.map((obj, idx) => {
                        return (
                          <tr
                            key={obj.name + "-" + idx}
                            className="table-updated-tr"
                          >
                            <td className={"table-updated-td break-all"}>
                              {obj.name}
                            </td>
                            <td className={"table-updated-td break-all"}>
                              {obj.grammar}
                            </td>
                            <td className="col-xs-3 table-updated-td text-right no-padding">
                              <a
                                className="easy-link no-padding m-r-20"
                                onClick={this.editList.bind(
                                  this,
                                  obj.sms_list_filter_report_id,
                                  obj.sms_list_filter_id,
                                  obj.name,
                                )}
                              >
                                <i className="fa fa-pencil-alt m-r-5" />
                                Edit
                              </a>
                              <a
                                className="easy-link no-padding m-r-15"
                                onClick={() =>
                                  this.setState({
                                    showConfirmation: true,
                                    deleteId: obj.sms_list_filter_report_id,
                                  })
                                }
                              >
                                <i className="fa fa-trash-alt m-r-5" />
                                Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={
                this.state.filterListDataShow
                  ? "setting-setion m-b-15"
                  : "setting-setion m-b-15 no-display"
              }
            >
              <div className="table-responsive clients-table no-border">
                <table className="table-updated  table-min-width no-td-border no-hover">
                  <thead className="table-updated-thead">
                    <tr>
                      {this.state.tableColumns &&
                        this.state.tableColumns.length > 0 &&
                        this.state.tableColumns.map((tobj, tidx) => {
                          return (
                            <th key={tidx} className="table-updated-th">
                              {this.state.languageData[tobj]}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.filterListData &&
                      this.state.filterListData.length > 0 &&
                      this.state.filterListData.map((robj, ridx) => {
                        return (
                          <tr key={ridx} className="table-updated-tr">
                            <>
                              {this.state.tableColumns &&
                                this.state.tableColumns.length > 0 &&
                                this.state.tableColumns.map((tobj, tidx) => {
                                  return this.state.showGeneralReport ===
                                    true && tobj === "bi_view_details" ? (
                                    <td
                                      key={ridx + `_` + tidx}
                                      className={"table-updated-td"}
                                    >
                                      {(this.state.selected_report_type_id ==
                                        14 ||
                                        this.state.selected_report_type_id ==
                                          15 ||
                                        this.state.selected_report_type_id ==
                                          17) && (
                                        <a
                                          className="easy-link no-padding"
                                          onClick={() =>
                                            this.goToDetail(
                                              robj.business_insight_child_id,
                                              robj.clinic_id,
                                              robj.bi_clinic_name,
                                            )
                                          }
                                        >
                                          {"View Details"}
                                        </a>
                                      )}
                                    </td>
                                  ) : (
                                    <td
                                      key={ridx + `_` + tidx}
                                      className={
                                        ["bi_email", "bi_phone"].indexOf(tobj) >
                                        -1
                                          ? "table-updated-td break-all"
                                          : "table-updated-td"
                                      }
                                    >
                                      {[
                                        "bi_amount_of_money_spent",
                                        "bi_refund",
                                        "bi_average_transection",
                                        "bi_amount_of_funds_spent",
                                        "bi_discount",
                                      ].indexOf(tobj) > -1
                                        ? getCurrencySymbol()
                                        : null}
                                      {robj[tobj]}
                                    </td>
                                  );
                                })}
                            </>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={
                this.state.smsList.length == 0
                  ? "not-create-report"
                  : "not-create-report  no-display"
              }
            >
              <h3>{this.state.globalLang.no_list_create}</h3>
              <a
                className="new-blue-btn m-t-25"
                onClick={() => this.setState({ showCreateOptions: true })}
              >
                {this.state.globalLang.label_create_list}
              </a>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showCreateOptions
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle vert-middle-report-option">
            <div className="white-popup small-popup">
              <div className="white-popup-wrapper">
                <div className="membershipTypeTitle">
                  {this.state.globalLang.label_create_list}
                  <a
                    onClick={() => this.setState({ showCreateOptions: false })}
                    className="popupClose"
                  >
                    <img alt="" src={picClose} />
                  </a>
                </div>
                <div className="question-name-report">
                  {this.state.globalLang.label_what_type_of_list}
                </div>
                {this.state.smsTemplate &&
                  this.state.smsTemplate.map((obj, idx) => {
                    return (
                      <div
                        key={obj.name + "-" + idx}
                        className="report-ans-outer"
                      >
                        <input
                          className="basic-form-checkbox"
                          name="report_type_sysname"
                          value={obj.id}
                          checked={this.state.report_type_sysname == obj.id}
                          type="radio"
                          onChange={this.handleInputChange}
                        />
                        <label className="basic-form-text" htmlFor="">
                          {obj.name}
                        </label>
                      </div>
                    );
                  })}
                {this.state.reportVariableData &&
                  this.state.reportVariableData.length > 0 && (
                    <ReportsFilters
                      reportVariableData={this.state.reportVariableData}
                      reportSection={`sms`}
                      handleInputChange={this.handleInputChange}
                      parentState={this.state}
                      handleCalendarChange={this.handleCalendarChange}
                      handleChildState={this.handleChildState}
                      type={"date"}
                    />
                  )}
              </div>

              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.saveReport}
                >
                  {this.state.globalLang.label_continue}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={() => this.setState({ showCreateOptions: false })}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.showNameSection
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle">
            <div className="white-popup small-popup">
              <div className="white-popup-wrapper">
                <div className="membershipTypeTitle">
                  {this.state.businessInsightLang.bi_save_report}
                  <a onClick={this.hideNameSection} className="popupClose">
                    <img alt="" src={picClose} />
                  </a>
                </div>

                <div className="col-sm-12 col-xs-12">
                  <div className="simpleField">
                    <div className="simpleLabel">
                      {this.state.businessInsightLang.bi_report_name}
                    </div>
                    <input
                      type="text"
                      className={
                        this.state.reportNameError
                          ? "simpleInput"
                          : "simpleInput field_error"
                      }
                      name="reportName"
                      value={this.state.reportName}
                      onChange={this.handleInputChange}
                      placeholder={"Enter report name"}
                    />
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.saveThisReport}
                >
                  {this.state.businessInsightLang.bi_save}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={this.hideNameSection}
                >
                  {this.state.businessInsightLang.bi_cancel}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.showConfirmation == true
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        ></div>
        <div
          role="dialog"
          className={
            this.state.showConfirmation === true
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => this.setState({ showConfirmation: false })}
                >
                  ×
                </button>
                <h4 className="modal-title">
                  {this.state.globalLang.delete_confirmation}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {
                  this.state.businessInsightLang
                    .bi_are_you_sure_you_want_to_delete_this_report
                }
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={() => this.setState({ showConfirmation: false })}
                  >
                    {this.state.globalLang.label_no}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.deleteThisReport}
                  >
                    {this.state.globalLang.label_yes}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  if (state.InboxReducer.action === "GET_SMS_LIST") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.errorTime = new Date();
    } else {
      returnState.smsList = state.InboxReducer.data;
      returnState.smsListTime = new Date();
    }
  }
  if (state.InboxReducer.action === "SAVE_SMS_LIST") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.errorTime = new Date();
    } else {
      returnState.smsList = state.InboxReducer.data;
      returnState.smsListTime = new Date();
      uiNotification.success(
        languageData.global[state.InboxReducer.data.message],
      );
    }
  }
  if (state.InboxReducer.action === "GET_OPTIONS_LIST") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.errorTime = new Date();
    } else {
      returnState.optionsList = state.InboxReducer.data;
      returnState.optionsListTime = new Date();
    }
  }
  if (state.InboxReducer.action === "GET_LIST_DATA") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.errorTime = new Date();
    } else {
      returnState.filterListData = state.InboxReducer.data;
      returnState.filterListDataTime = new Date();
    }
  }
  if (state.InboxReducer.action === "DELETE_DATA") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.errorTime = new Date();
    } else {
      returnState.smsList = state.InboxReducer.data;
      returnState.smsListTime = new Date();
      uiNotification.success(
        languageData.global[state.InboxReducer.data.message],
      );
    }
  }

  if (state.InboxReducer.action === "DOWNLOAD_EXCEL_FILE") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.file = state.InboxReducer.data.data.file;
      returnState.downloadExcelTime = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getOptionAndList: getOptionAndList,
      getOptionsOnly: getOptionsOnly,
      saveSmsFilterReport: saveSmsFilterReport,
      getFilterReportData: getFilterReportData,
      deleteList: deleteList,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));
