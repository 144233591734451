const clientsInitialState = {
  action: "",
  clientId: 0,
  clientProcedureData: null,
  clientShopifyData: null,
  isReloadClientProcedure: false,
  clientProfileData: null,
  exportMembershipDocument: {
    status: "pending",
    downloadLink: null,
    error: null,
  },
  exportConsentDocument: { status: "pending", downloadLink: null, error: null },
  planDetailsStatus: "pending",
  paymentDetail: { status: "pending", data: null, error: null },
  exportProcedureDetails: { status: "pending", data: null },
  clientDocumentData: { status: "pending", data: null },
  fetchingClientWallet: false,
  fetchingPurchasedShopify: false,
};

const ClientsReducer = (state = clientsInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
        clientId: state.clientId,
        clientProcedureData: state.clientProcedureData,
        isReloadClientProcedure: state.isReloadClientProcedure,
        clientProfileData: state.clientProfileData,
        exportMembershipDocument: clientsInitialState.exportMembershipDocument,
        exportConsentDocument: clientsInitialState.exportConsentDocument,
        exportProcedureDetails: clientsInitialState.exportProcedureDetails,
        clientDocumentData: clientsInitialState.clientDocumentData,
      };
    }
    case "CLIENTS_LIST":
      return {
        ...state,
        data: action.payload,
        action: "CLIENTS_LIST",
      };
    case "CREATE_FILTER":
      return {
        ...state,
        data: action.payload,
        action: "CREATE_FILTER",
      };
    case "GET_CPP_DATA":
      return {
        ...state,
        data: action.payload,
        action: "GET_CPP_DATA",
      };
    case "UPDATE_FILTER":
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_FILTER",
      };
    case "GET_ALL_FILTERS":
      return {
        ...state,
        data: action.payload,
        action: "GET_ALL_FILTERS",
      };
    case "DELETE_FILTER":
      return {
        ...state,
        data: action.payload,
        action: "DELETE_FILTER",
      };
    case "GET_ONE_FILTER":
      return {
        ...state,
        data: action.payload,
        action: "GET_ONE_FILTER",
      };
    case "SAVE_CLIENT_FIELD":
      return {
        ...state,
        data: action.payload,
        action: "SAVE_CLIENT_FIELD",
      };
    case "GET_CLIENT_DETAIL":
      return {
        ...state,
        data: action.payload,
        action: "GET_CLIENT_DETAIL",
      };
    case "FIRE_CLIENT":
      return {
        ...state,
        data: action.payload,
        action: "FIRE_CLIENT",
      };
    case "DND_CLIENT":
      return {
        ...state,
        data: action.payload,
        action: "DND_CLIENT",
      };
    case "CHANGE_PORTAL_CLIENT":
      return {
        ...state,
        data: action.payload,
        action: "CHANGE_PORTAL_CLIENT",
      };
    case "GET_CLIENT_CARDS":
      return {
        ...state,
        data: action.payload,
        action: "GET_CLIENT_CARDS",
      };
    case "SAVE_CLIENT_CARD":
      return {
        ...state,
        data: action.payload,
        action: "SAVE_CLIENT_CARD",
      };
    case "UPDATE_MEDICAL_HISTORY":
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_MEDICAL_HISTORY",
      };
    case "UPDATE_NEW_MEDICAL_HISTORY":
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_NEW_MEDICAL_HISTORY",
      };
    case "RESEND_WELCOME_EMAIL":
      return {
        ...state,
        data: action.payload,
        action: "RESEND_WELCOME_EMAIL",
      };
    case "RESET_CLIENT_PORTAL_PASSWORD":
      return {
        ...state,
        data: action.payload,
        action: "RESET_CLIENT_PORTAL_PASSWORD",
      };
    case "CREATE_CLIENT":
      return {
        ...state,
        data: action.payload,
        action: "CREATE_CLIENT",
      };
    case "UPDATE_CLIENT":
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_CLIENT",
      };
    case "DELETE_CLIENT":
      return {
        ...state,
        data: action.payload,
        action: "DELETE_CLIENT",
      };
    case "GET_CLIENT_SHOPIFY_PURCHASED":
      return {
        ...state,
        clientShopifyData: action.payload,
        action: "GET_CLIENT_SHOPIFY_PURCHASED",
      };
    case "GET_CLIENT_SHOPIFY_DETAIL":
      return {
        ...state,
        data: action.payload,
        action: "GET_CLIENT_SHOPIFY_DETAIL",
      };
    case "GET_CLIENT_WALLET":
      return {
        ...state,
        data: action.payload,
        action: "GET_CLIENT_WALLET",
      };
    case "ADD_CREDIT_TO_WALLET":
      return {
        ...state,
        data: action.payload,
        action: "ADD_CREDIT_TO_WALLET",
      };
    case "REMOVE_CREDIT_FROM_WALLET":
      return {
        ...state,
        data: action.payload,
        action: "REMOVE_CREDIT_FROM_WALLET",
      };
    case "UPDATE_WALLET_PACKAGE":
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_WALLET_PACKAGE",
      };
    case "REMOVE_WALLET_PACKAGE":
      return {
        ...state,
        data: action.payload,
        action: "REMOVE_WALLET_PACKAGE",
      };
    case "REMOVE_INVENTORY_VARIATION":
      return {
        ...state,
        data: action.payload,
        action: "REMOVE_INVENTORY_VARIATION",
      };
    case "UPDATE_MEMBERSHIP_CC":
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_MEMBERSHIP_CC",
      };
    case "CANCEL_MEMBERSHIP":
      return {
        ...state,
        data: action.payload,
        action: "CANCEL_MEMBERSHIP",
      };
    case "ADD_MONTHLY_MEMBERSHIP":
      return {
        ...state,
        data: action.payload,
        action: "ADD_MONTHLY_MEMBERSHIP",
      };
    case "SEARCH_WALLET_PRODUCT":
      return {
        ...state,
        data: action.payload,
        action: "SEARCH_WALLET_PRODUCT",
      };
    case "ADD_PACKAGE_PRODUCT":
      return {
        ...state,
        data: action.payload,
        action: "ADD_PACKAGE_PRODUCT",
      };
    case "GET_BOGO_PACKAGE_DETAILS":
      return {
        ...state,
        data: action.payload,
        action: "GET_BOGO_PACKAGE_DETAILS",
      };
    case "GET_PRODUCT_PRICE_BY_CLINIC":
      return {
        ...state,
        data: action.payload,
        action: "GET_PRODUCT_PRICE_BY_CLINIC",
      };
    case "EXPORT_FILE": {
      return { ...state, data: action.payload, action: "EXPORT_FILE" };
    }
    case "EXPORT_CLIENT_PDF": {
      return { ...state, data: action.payload, action: "EXPORT_CLIENT_PDF" };
    }
    case "REFUND_FEES": {
      return { ...state, data: action.payload, action: "REFUND_FEES" };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    case "EXPORT_CLIENT_PROCEDURES": {
      return {
        ...state,
        data: action.payload,
        action: "EXPORT_CLIENT_PROCEDURES",
      };
    }
    case "CLIENT_BULK_UPLOAD": {
      return { ...state, data: action.payload, action: "CLIENT_BULK_UPLOAD" };
    }
    case "VIEW_FILLED_SURVEYS": {
      return { ...state, data: action.payload, action: "VIEW_FILLED_SURVEYS" };
    }
    case "SEND_POST_INSTRUCTION": {
      return {
        ...state,
        data: action.payload,
        action: "SEND_POST_INSTRUCTION",
      };
    }
    case "CLIENT_DOCUMENT_LIST": {
      return { ...state, data: action.payload, action: "CLIENT_DOCUMENT_LIST" };
    }
    case "CLIENT_DOCUMENT_DATA": {
      return { ...state, data: action.payload, action: "CLIENT_DOCUMENT_DATA" };
    }
    case "CLIENT_DOCUMENT_SAVE": {
      return { ...state, data: action.payload, action: "CLIENT_DOCUMENT_SAVE" };
    }
    case "CLIENT_DOCUMENT_UPDATE": {
      return {
        ...state,
        data: action.payload,
        action: "CLIENT_DOCUMENT_UPDATE",
      };
    }
    case "CLIENT_DOCUMENT_DELETE": {
      return {
        ...state,
        data: action.payload,
        action: "CLIENT_DOCUMENT_DELETE",
      };
    }
    case "VIEW_AFTER_PHOTOS": {
      return { ...state, data: action.payload, action: "VIEW_AFTER_PHOTOS" };
    }
    case "GET_MEMBERSHIP_DETAILS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_MEMBERSHIP_DETAILS",
      };
    }
    case "APPLY_DISCOUNT_COUPON": {
      return {
        ...state,
        data: action.payload,
        action: "APPLY_DISCOUNT_COUPON",
      };
    }
    case "PAST_DUE_INVOICE_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "PAST_DUE_INVOICE_DATA",
      };
    }
    case "CHANGE_MEMBERSHIP_CLIENT": {
      return {
        ...state,
        data: action.payload,
        action: "CHANGE_MEMBERSHIP_CLIENT",
      };
    }
    case "GET_DUPLICATE_PROFILES": {
      return {
        ...state,
        data: action.payload,
        action: "GET_DUPLICATE_PROFILES",
      };
    }
    case "GET_MERGE_PROFILES_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "GET_MERGE_PROFILES_DATA",
      };
    }
    case "GET_PROCEDURE_DATA": {
      return { ...state, data: action.payload, action: "GET_PROCEDURE_DATA" };
    }
    case "GET_WALLET_DATA": {
      return { ...state, data: action.payload, action: "GET_WALLET_DATA" };
    }
    case "GET_TREATMENT_PLANS": {
      return { ...state, data: action.payload, action: "GET_TREATMENT_PLANS" };
    }
    case "GET_MEDICAL_HISTORY": {
      return { ...state, data: action.payload, action: "GET_MEDICAL_HISTORY" };
    }
    case "GET_UPCOMING_APPOINTMENT_AND_PAYMENT_HISTORY": {
      return {
        ...state,
        data: action.payload,
        action: "GET_UPCOMING_APPOINTMENT_AND_PAYMENT_HISTORY",
      };
    }
    case "GET_CUSTOMER_NOTES": {
      return { ...state, data: action.payload, action: "GET_CUSTOMER_NOTES" };
    }
    case "GET_PATIENT_DOCUMENTS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PATIENT_DOCUMENTS",
      };
    }
    case "UPDATE_MEMBERSHIP_STATUS": {
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_MEMBERSHIP_STATUS",
      };
    }
    case "VIEW_BAVIDEOS": {
      return { ...state, data: action.payload, action: "VIEW_BAVIDEOS" };
    }
    case "GET_MEMBERSHIP_AGREEMENT": {
      return {
        ...state,
        data: action.payload,
        action: "GET_MEMBERSHIP_AGREEMENT",
      };
    }
    case "MERGE_PATIENT_DATA": {
      return { ...state, data: action.payload, action: "MERGE_PATIENT_DATA" };
    }
    case "MARK_NOT_DUPLICATE": {
      return { ...state, data: action.payload, action: "MARK_NOT_DUPLICATE" };
    }
    case "GET_TREAT_TEMPLATES": {
      return { ...state, data: action.payload, action: "GET_TREAT_TEMPLATES" };
    }
    case "GET_PLAN_TEMPLATE_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PLAN_TEMPLATE_DATA",
      };
    }
    case "SAVE_PAYG_TREATMENT_PLAN": {
      return {
        ...state,
        data: action.payload,
        action: "SAVE_PAYG_TREATMENT_PLAN",
      };
    }
    case "GET_PLAN_DATA_BY_ID": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PLAN_DATA_BY_ID",
        planDetailsStatus: "success",
      };
    }
    case "GET_PLAN_DATA_BY_ID_LOADING": {
      return { ...state, planDetailsStatus: "loading" };
    }
    case "SAVE_MONTHLY_TREATMENT_PLAN": {
      return {
        ...state,
        data: action.payload,
        action: "SAVE_MONTHLY_TREATMENT_PLAN",
      };
    }
    case "GET_START_PROGRAM_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "GET_START_PROGRAM_DATA",
      };
    }
    case "START_PROGRAM_APPLY_DISCOUNT": {
      return {
        ...state,
        data: action.payload,
        action: "START_PROGRAM_APPLY_DISCOUNT",
      };
    }
    case "CHANGE_PLAN_CLINIC": {
      return { ...state, data: action.payload, action: "CHANGE_PLAN_CLINIC" };
    }
    case "VIEW_PRICE_BREAK_UP": {
      return { ...state, data: action.payload, action: "VIEW_PRICE_BREAK_UP" };
    }
    case "START_TREATMENT_PROGRAM": {
      return {
        ...state,
        data: action.payload,
        action: "START_TREATMENT_PROGRAM",
      };
    }
    case "CANCEL_TREATMENT_PLAN": {
      return {
        ...state,
        data: action.payload,
        action: "CANCEL_TREATMENT_PLAN",
      };
    }
    case "SAVE_PLAN_AS_TEMPLATE": {
      return {
        ...state,
        data: action.payload,
        action: "SAVE_PLAN_AS_TEMPLATE",
      };
    }
    case "UPDATE_PLAN_CARD": {
      return { ...state, data: action.payload, action: "UPDATE_PLAN_CARD" };
    }
    case "GET_PRESCRIBE_ONLY_DETAILS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PRESCRIBE_ONLY_DETAILS",
      };
    }
    case "UPDATE_PRESCRIBE_ONLY": {
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_PRESCRIBE_ONLY",
      };
    }
    case "PERFORM_TREATMENT_ACTIONS": {
      return {
        ...state,
        data: action.payload,
        action: "PERFORM_TREATMENT_ACTIONS",
      };
    }
    case "GET_NEW_MEMBERSHIP_DETAILS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_NEW_MEMBERSHIP_DETAILS",
      };
    }
    case "ADD_NEW_MEMBERSHIP_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "ADD_NEW_MEMBERSHIP_DATA",
      };
    }
    case "UPDATE_DRAW_DAY": {
      return { ...state, data: action.payload, action: "UPDATE_DRAW_DAY" };
    }
    case "SAVE_CLIENT_CLINIC": {
      return { ...state, data: action.payload, action: "SAVE_CLIENT_CLINIC" };
    }
    case "SAVE_HEALTH_PRO_PRES": {
      return { ...state, data: action.payload, action: "SAVE_HEALTH_PRO_PRES" };
    }
    case "SAVE_PHARMACY": {
      return { ...state, data: action.payload, action: "SAVE_PHARMACY" };
    }
    case "GENERATE_PRESC_PDF": {
      return { ...state, data: action.payload, action: "GENERATE_PRESC_PDF" };
    }
    case "FAX_PRESC_PDF": {
      return { ...state, data: action.payload, action: "FAX_PRESC_PDF" };
    }
    case "EMAIL_PRESC_PDF": {
      return { ...state, data: action.payload, action: "EMAIL_PRESC_PDF" };
    }
    case "DOWNLOAD_MEMBERSHIP_AGREEMENT": {
      return {
        ...state,
        data: action.payload,
        action: "DOWNLOAD_MEMBERSHIP_AGREEMENT",
      };
    }
    case "GET_SELECTED_QUESTIONNAIRE_DETAILS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_SELECTED_QUESTIONNAIRE_DETAILS",
      };
    }
    case "GET_SELECTED_CONSENT_DETAILS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_SELECTED_CONSENT_DETAILS",
      };
    }
    case "GET_AGREEMENT_DATA": {
      return { ...state, data: action.payload, action: "GET_AGREEMENT_DATA" };
    }
    case "EXPORT_PROCEDURE_DATA": {
      return { ...state, exportProcedureDetails: action.payload };
    }
    case "GET_CLIENT_DOCUMENT": {
      return { ...state, clientDocumentData: action.payload };
    }
    case "GET_APPOINTMENT_QUESTIONNAIRE": {
      return {
        ...state,
        data: action.payload,
        action: "GET_APPOINTMENT_QUESTIONNAIRE",
      };
    }
    case "GET_APPOINTMENT_HEALTH_QUESTIONNAIRE": {
      return {
        ...state,
        data: action.payload,
        action: "GET_APPOINTMENT_HEALTH_QUESTIONNAIRE",
      };
    }
    case "GET_APPOINTMENT_CONSENTS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_APPOINTMENT_CONSENTS",
      };
    }
    case "GET_PROCEDURE_HEALTH_QUESTIONNAIRE": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PROCEDURE_HEALTH_QUESTIONNAIRE",
      };
    }
    case "GET_PROCEDURE_INVOICE": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PROCEDURE_INVOICE",
      };
    }
    case "SAVE_HEALTH_PROCEDURE_INVOICE": {
      return {
        ...state,
        data: action.payload,
        action: "SAVE_HEALTH_PROCEDURE_INVOICE",
      };
    }
    case "TAKE_PAYMENT_INVOICE": {
      return { ...state, data: action.payload, action: "TAKE_PAYMENT_INVOICE" };
    }
    case "SAVE_WHILE_WORKING": {
      return { ...state, data: action.payload, action: "SAVE_WHILE_WORKING" };
    }
    case "SEARCH_PRODUCT_FOR_INVOICE": {
      return {
        ...state,
        data: action.payload,
        action: "SEARCH_PRODUCT_FOR_INVOICE",
      };
    }
    case "SEARCH_SERVICE_FOR_INVOICE": {
      return {
        ...state,
        data: action.payload,
        action: "SEARCH_SERVICE_FOR_INVOICE",
      };
    }
    case "CLIENT_PROFILE_DATA": {
      if (
        window.location &&
        window.location.pathname &&
        window.location.pathname.indexOf(
          "/clients/profile/" + action.clientId,
        ) > -1
      ) {
        const clientProfileData =
          action.payload && action.payload.status === 200 && action.payload.data
            ? action.payload
            : null;
        return {
          ...state,
          data: action.payload,
          action: "CLIENT_PROFILE_DATA",
          clientProfileData: clientProfileData,
          clientId: action.clientId,
          isQualiphyEnabled: action.isQualiphyEnabled,
        };
      } else {
        return state;
      }
    }
    case "CLIENT_PROCEDURES_DATA": {
      if (
        window.location &&
        window.location.pathname &&
        window.location.pathname.indexOf(
          "/clients/profile/" + action.clientId,
        ) > -1
      ) {
        const clientProcedureData =
          action.payload && action.payload.status === 200 && action.payload.data
            ? action.payload
            : null;
        return {
          ...state,
          data: action.payload,
          action: "CLIENT_PROCEDURES_DATA",
          clientId: action.clientId,
          clientProcedureData: clientProcedureData,
          isReloadClientProcedure: false,
        };
      } else {
        return state;
      }
    }
    case "UPDATE_PROCEDURE_LOCK_STATUS": {
      const tempProcedureData = state.clientProcedureData;

      const { id, is_lock } = action;
      const procedureIndex =
        tempProcedureData.data.all_procedures.data.findIndex(
          (procedureDetails) => procedureDetails.id === id,
        );

      if (procedureIndex > -1) {
        tempProcedureData.data.all_procedures.data[procedureIndex].is_lock =
          is_lock;
      }
      return { ...state, clientProcedureData: tempProcedureData };
    }
    case "IS_RELOAD_CLIENT_PROCEDURES": {
      return {
        ...state,
        action: "IS_RELOAD_CLIENT_PROCEDURES",
        isReloadClientProcedure: action.isReloadClientProcedure,
        clientProcedureData: action.isReloadClientProcedure
          ? null
          : action.isReloadClientProcedure,
      };
    }
    case "WRITE_CLIENT_PROCEDURE_DATA": {
      return {
        ...state,
        action: "EMPTY_DATA",
        clientProcedureData: action.clientProcedureData,
      };
    }
    case "READ_CLIENT_PROCEDURE_DATA": {
      return {
        ...state,
        action: "CLIENT_PROCEDURES_DATA",
        data: state.clientProcedureData,
      };
    }
    case "READ_CLIENT_PROFILE_DATA": {
      return {
        ...state,
        action: "GET_CLIENT_DETAIL",
        data: state.clientProfileData,
      };
    }
    case "READ_CLIENT_PROFILE_AND_PROOCURE_DATA": {
      return { ...state, action: "READ_CLIENT_PROFILE_AND_PROOCURE_DATA" };
    }
    case "GET_ALL_CLIENT_PROCEDURES": {
      return {
        ...state,
        data: action.payload,
        action: "GET_ALL_CLIENT_PROCEDURES",
      };
    }
    case "GET_PROCEDURE_PRESCRIPTION_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PROCEDURE_PRESCRIPTION_DATA",
      };
    }
    case "VERIFY_PORTAL_EMAIL": {
      return { ...state, data: action.payload, action: "VERIFY_PORTAL_EMAIL" };
    }
    case "ISSUE_REFUND_FROM_HISTORY": {
      return {
        ...state,
        data: action.payload,
        action: "ISSUE_REFUND_FROM_HISTORY",
      };
    }
    case "GET_CLEARENT_KEY": {
      return { ...state, data: action.payload, action: "GET_CLEARENT_KEY" };
    }

    case "GET_SCRIPT_SURE_PATIENT": {
      return {
        ...state,
        data: action.payload,
        action: "GET_SCRIPT_SURE_PATIENT",
      };
    }

    case "SAVE_SCRIPT_SURE_PATIENT": {
      return {
        ...state,
        data: action.payload,
        action: "SAVE_SCRIPT_SURE_PATIENT",
      };
    }

    case "GET_APPOINTMENT_TIMELINE": {
      return {
        ...state,
        data: action.payload,
        action: "GET_APPOINTMENT_TIMELINE",
      };
    }
    case "CALL_BASIC_CLIENTS": {
      return { ...state, data: action.payload, action: "CALL_BASIC_CLIENTS" };
    }
    case "SEND_POST_INSTRUCTION_FOR_APPOINTMENT": {
      return {
        ...state,
        data: action.payload,
        action: "SEND_POST_INSTRUCTION_FOR_APPOINTMENT",
      };
    }
    case "GET_APPOINTMENT_QUESTIONNAIRES": {
      return {
        ...state,
        data: action.payload,
        action: "GET_APPOINTMENT_QUESTIONNAIRES",
      };
    }
    case "SEND_PRE_INSTRUCTION_FOR_APPOINTMENT": {
      return {
        ...state,
        data: action.payload,
        action: "SEND_PRE_INSTRUCTION_FOR_APPOINTMENT",
      };
    }
    case "CLOSE_PRESCRIPTION_IFRAME": {
      return {
        ...state,
        data: action.payload,
        action: "CLOSE_PRESCRIPTION_IFRAME",
      };
    }
    case "DOWNLOAD_PRESCRIPTION_PDF": {
      return { ...state, clientDocumentData: action.payload };
    }
    case "GET_MEDICINE_NAMES": {
      return { ...state, data: action.payload, action: "GET_MEDICINE_NAMES" };
    }
    case "EXPORT_MEMBERSHIP_DOCUMENT": {
      return {
        ...state,
        exportMembershipDocument: action.payload,
        action: "EXPORT_MEMBERSHIP_DOCUMENT",
      };
    }
    case "EXPORT_CONSENT_DOCUMENT": {
      return {
        ...state,
        exportConsentDocument: action.payload,
        action: "EXPORT_CONSENT_DOCUMENT",
      };
    }
    case "GET_MEMBERSHIP_PAYMENT_DETAILS_LOADING": {
      return {
        ...state,
        action: "GET_MEMBERSHIP_PAYMENT_DETAILS_LOADING",
        paymentDetail: { status: "loading", data: null },
      };
    }
    case "GET_MEMBERSHIP_PAYMENT_DETAILS_SUCCESS": {
      return {
        ...state,
        action: "GET_MEMBERSHIP_PAYMENT_DETAILS_SUCCESS",
        paymentDetail: { status: "success", data: action.payload },
      };
    }
    case "GET_MEMBERSHIP_PAYMENT_DETAILS_ERROR": {
      return {
        ...state,
        action: "GET_MEMBERSHIP_PAYMENT_DETAILS_ERROR",
        paymentDetail: { status: "error", data: null },
      };
    }
    case "RESET_MEMBERSHIP_PAYMENT_DETAILS": {
      return {
        ...state,
        action: "RESET_MEMBERSHIP_PAYMENT_DETAILS",
        paymentDetail: clientsInitialState.paymentDetail,
      };
    }
    case "GET_CLIENT_WALLET_LOADING": {
      return { ...state, fetchingClientWallet: true };
    }
    case "GET_CLIENT_WALLET_DONE": {
      return { ...state, fetchingClientWallet: false };
    }
    case "GET_CLIENT_SHOPIFY_PURCHASED_LOADING": {
      return { ...state, fetchingPurchasedShopify: true };
    }
    case "GET_CLIENT_SHOPIFY_PURCHASED_DONE": {
      return { ...state, fetchingPurchasedShopify: false };
    }
    default:
      return state;
  }
};

export default ClientsReducer;
