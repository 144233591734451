import React from "react";
import {
  PROVIDER_ROOM_PROCEDURE_TYPE,
  PROVIDER_ROOM_PROCEDURE_VERSION,
} from "../../../api/queries/useProviderRoomProceduresQuery";
import { useProcedures } from "../hooks/useProcedures";
import { TabCosmetic } from "./components/TabCosmetic";
import { TabHealth } from "./components/TabHealth";
import { Layout } from "../components/Layout/Layout";
import { useApiClinics } from "../../Event/hooks/useApiClinics";

export function TabSignedProcedures({ history }) {
  const historyData = history?.location?.state;
  const chartAuditUserId = historyData?.chartUserId?.toString();
  const {
    procedures,
    fetchNextProcedures,
    proceduresStatus,
    proceduresSearchTerm,
    proceduresType,
    selectClinic,
  } = useProcedures({
    version: PROVIDER_ROOM_PROCEDURE_VERSION.signed,
    clinicID: historyData?.clinic_id,
    providerUserId: chartAuditUserId,
  });

  const { clinics: availableClinicsList } = useApiClinics();

  const tabByType = {
    [PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic]: (
      <TabCosmetic
        isVisible={proceduresStatus.isVisible}
        onChangeTab={proceduresType.onChange}
        procedures={procedures}
        availableClinicsList={availableClinicsList}
        onChangeClinic={selectClinic.onChange}
        clinicId={selectClinic.value}
        showHideActionButton={Boolean(historyData?.isFromChartAuditReport)}
        providerUserId={chartAuditUserId}
      />
    ),
    [PROVIDER_ROOM_PROCEDURE_TYPE.health]: (
      <TabHealth
        isVisible={proceduresStatus.isVisible}
        onChangeTab={proceduresType.onChange}
        procedures={procedures}
        availableClinicsList={availableClinicsList}
        onChangeClinic={selectClinic.onChange}
        clinicId={selectClinic.value}
        showHideActionButton={Boolean(historyData?.isFromChartAuditReport)}
        providerUserId={chartAuditUserId}
      />
    ),
  };
  return (
    <Layout
      search={proceduresSearchTerm}
      status={proceduresStatus}
      loadMore={fetchNextProcedures}
    >
      {tabByType[proceduresType.value]}
    </Layout>
  );
}
