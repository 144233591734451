import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../shared/Button/Button";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../widgets/EntityHeader/EntityHeader";

export function Header({
  selectedCount,
  onUnmark,
  isUnmarking,
  searchValue,
  onChangeSearch,
  isSearching,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  return (
    <>
      <EntityHeader
        title={tCommon("nonDupPatients.header")}
        searchValue={searchValue}
        onChangeSearch={onChangeSearch}
        isSearching={isSearching}
        right={
          <Button
            isDisabled={selectedCount === 0 || isUnmarking}
            size="small"
            onClick={() => setIsConfirmModalOpen(true)}
            leftAdornment={
              isUnmarking ? (
                <CircularProgress size="tiny" color="white" />
              ) : undefined
            }
          >
            {tCommon("nonDupPatients.unmark")} ({selectedCount})
          </Button>
        }
      />
      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={() => {
            onUnmark();
            setIsConfirmModalOpen(false);
          }}
        >
          {tCommon("nonDupPatients.confirm.unmark")}
        </ConfirmModal>
      )}
    </>
  );
}

Header.propTypes = {
  selectedCount: PropTypes.number.isRequired,
  onUnmark: PropTypes.func.isRequired,
  isUnmarking: PropTypes.bool.isRequired,
  searchValue: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
};
