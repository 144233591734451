export const DOCUMENT_TYPES = {
  procedure: "procedure",
  questionnaries: "questionnaries",
  consent: "consent",
  agreement: "agreement",
  file: "file",
  ePrescription: "ePrescription",
  health_questionnaries: "health_questionnaries",
  efax: "efax",
  gfe: "gfe",
};

export const DOCUMENT_SUB_TYPES = {
  procedure: "procedure",
  appointment: "appointment",
  health: "health",
  expired_agreement: "expired_agreement",
};
