import React from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { BookmarkIcon } from "../../../../assets/Icons/BookmarkIcon";
import styles from "./styles.module.scss";

export function OnFileMark() {
  const { tCommon: t } = useAppTranslation.Common();

  return (
    <div className={styles.root}>
      {t("label.onFile")}
      <BookmarkIcon width="16px" />
    </div>
  );
}
