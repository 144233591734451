import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { surveysListQuestions } from "../../Actions/Surveys/surveyActions.js";
import Loader from "../../Components/Common/Loader.js";
import Sidebar from "../../_legacy/Containers/Settings/sidebar.js";

class SurveyData extends React.Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      },
      to_date: format(new Date(), "YYYY-MM-DD"),
      from_date: format(new Date(), "YYYY-MM-DD"),
      viewAllsurveysListQuestions: [],
      viewSurveyData: [],
      viewSurveyDetails: {},
      id: this.props.match.params.id,
      surveyID: this.props.match.params.surveyID,
      globalLang: languageData.global,
    };
  }

  componentDidMount() {
    let questionId = this.state.id;
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.setState({
      survey_coolsculpting: languageData.dashboard["survey_coolsculpting"],
      surveys_insights: languageData.surveys["surveys_insights"],
      surveys_survey: languageData.surveys["surveys_survey"],
      survey_que: languageData.dashboard["survey_que"],
      survey_ans: languageData.dashboard["survey_ans"],
      showLoader: true,
    });

    this.props.surveysListQuestions(questionId);
  }

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendar = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendar = true;
    } else {
      showCalendar = false;
    }

    this.setState({ showCalendar: showCalendar });
  };

  handleRangeChange = (which, payload) => {
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    startDate = format(startDate, "YYYY-MM-DD");
    endDate = format(endDate, "YYYY-MM-DD");

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      showCalendar: false,
      from_date: startDate,
      to_date: endDate,
    });

    this.handleSubmit(which, { from_date: startDate, to_date: endDate });
  };

  handleSubmit = (event, value) => {
    let from_date = "";
    let to_date = "";

    if (typeof event === "object") {
      event.preventDefault();
    } else {
      from_date = value.from_date;
      to_date = value.to_date;
    }

    let formData = {
      params: {
        to_date: to_date ? to_date : this.state.to_date,
        from_date: from_date ? from_date : this.state.from_date,
      },
    };

    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      showLoader: true,
      objectNames: [],
      userLogList: [],
      dataFiltered: true,
    });

    this.props.surveysListQuestions(formData);
  };

  handleClick = (e) => {
    if (this.node.contains(e.target) && this.state.showCalendar === true) {
      return;
    }

    this.toggleCalendar(e.target);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }

    if (
      nextProps.viewAllsurveysListQuestions != undefined &&
      nextProps.viewAllsurveysListQuestions.data !=
        prevState.viewAllsurveysListQuestions &&
      nextProps.viewAllsurveysListQuestions.status == 200
    ) {
      if (localStorage.getItem("loadAgain") == "false") {
        localStorage.setItem("loadAgain", false);
        return {
          viewAllsurveysListQuestions:
            nextProps.viewAllsurveysListQuestions.data,
          viewSurveyData:
            nextProps.viewAllsurveysListQuestions.data.questions_data,
          viewSurveyDetails:
            nextProps.viewAllsurveysListQuestions.data.survey_details.survey,
          showLoader: false,
        };
      }
    }

    return null;
  }

  render() {
    let returnTo = ``;

    if (this.state.surveyID && this.state.surveyID > 0) {
      returnTo = `/settings/surveys/${this.state.surveyID}/view-all`;
    } else {
      returnTo = `/settings/surveys/view-all`;
    }

    return (
      <div className="main protected">
        <form onSubmit={this.handleSubmit}>
          <div id="content">
            <div className="container-fluid content setting-wrapper">
              <Sidebar />
              <div className="juvly-section ">
                <div className="juvly-container">
                  <div className="juvly-title">
                    {this.state.viewSurveyDetails &&
                    this.state.viewSurveyDetails.title
                      ? this.state.viewSurveyDetails.title
                      : ""}
                    <Link to={returnTo} className="pull-right close_survey">
                      <img alt="" src="/images/close.png" />
                    </Link>
                  </div>
                  {this.state.viewSurveyData !== undefined &&
                    this.state.viewSurveyData.map((qobj, qidx) => {
                      let comment = "";

                      if (qobj.survey_comment) {
                        comment = "(Comment : " + qobj.survey_comment + ")";
                      }

                      return (
                        <div key={`ques` + qidx} className="survey-qus-ans">
                          <div className="survey-ques">
                            <span className="que-label">
                              <b>Ques:</b>
                            </span>
                            {qobj && qobj.question ? qobj.question : ""}
                          </div>

                          {qobj.question_type === "Multiple Choice" ||
                          qobj.question_type === "Single Choice" ? (
                            qobj.multi_choice_answers &&
                            qobj.multi_choice_answers.length > 0 ? (
                              <div className="survey-ans">
                                <span className="ans-label">
                                  <b>Ans:</b>
                                </span>
                                {qobj.multi_choice_answers.join(", ")}
                              </div>
                            ) : (
                              <span className="survy-ans">____</span>
                            )
                          ) : qobj.question_type === "Opinion Scale" ||
                            qobj.question_type === "scale" ? (
                            qobj.score ? (
                              <div className="survey-ans">
                                <span className="ans-label">
                                  <b>Ans:</b>
                                </span>
                                {qobj.score} <br /> {comment}
                              </div>
                            ) : (
                              <div className="survey-ans">
                                <span className="ans-label">
                                  <b>Ans:</b>
                                </span>
                                ____
                                <br /> {comment}
                              </div>
                            )
                          ) : qobj.answer ? (
                            <div className="survey-ans">
                              <span className="ans-label">
                                <b>Ans:</b>
                              </span>
                              {qobj.answer}
                            </div>
                          ) : (
                            <span className="survy-ans">____</span>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </form>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.surveyReducer.action === "SURVEYS_LIST_QUESTIONS") {
    if (state.surveyReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.viewAllsurveysListQuestions = state.surveyReducer.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      surveysListQuestions,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyData);
