import React from "react";
import classes from "../sass/Header.module.scss";

export function Header() {
  return (
    <div className={classes.root}>
      <div>Category / Service Name</div>
      <div className={classes.location}>Location</div>
    </div>
  );
}
