import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Group.module.scss";

export function Group({
  row,
  compact,
  biggerGap,
  label,
  children,
  contentClassName,
  noPadding,
  labelGray,
}) {
  return (
    <div
      className={cx(classes.root, {
        [classes.rootNoPadding]: noPadding,
      })}
    >
      <h3
        className={cx(classes.label, {
          [classes.labelGray]: labelGray,
        })}
      >
        {label}
      </h3>
      <div
        className={cx(
          classes.content,
          {
            [classes.contentRow]: row,
            [classes.contentCompact]: compact,
            [classes.contentBiggerGap]: biggerGap,
          },
          contentClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
}

Group.propTypes = {
  row: PropTypes.bool,
  compact: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
  noPadding: PropTypes.bool,
  labelGray: PropTypes.bool,
};
