const initialState = {
  action: "",
  leadsApiSources: { status: "pending", data: null },
  registeredApiKeys: { status: "pending", data: null },
  createApiKey: { status: "pending", data: null },
  deleteApiKey: { status: "pending", data: null },
};

const LeadsAPIReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LEADS_SOURCES_LOADING": {
      return { ...state, leadsApiSources: { status: "loading", data: null } };
    }
    case "GET_LEADS_SOURCES_SUCCESS": {
      return {
        ...state,
        leadsApiSources: { status: "success", data: action.payload },
      };
    }
    case "GET_REGISTERED_KEYS_LOADING": {
      return { ...state, registeredApiKeys: { status: "loading", data: null } };
    }
    case "GET_REGISTERED_KEYS_SUCCESS": {
      return {
        ...state,
        registeredApiKeys: { status: "success", data: action.payload },
      };
    }
    case "CREATE_LEADS_API_KEY_SUCCESS": {
      return {
        ...state,
        createApiKey: { status: "success", data: action.payload },
      };
    }
    case "DELETE_LEADS_API_KEY_SUCCESS": {
      return {
        ...state,
        deleteApiKey: { status: "success", data: action.payload },
      };
    }

    default:
      return state;
  }
};

export default LeadsAPIReducer;
