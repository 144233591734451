import React from "react";
import { withRouter } from "react-router";
import Header from "./shared/Header/Header";
import { useQueryParamsState } from "../../../utilities/hooks/useQueryParamsState";
import ModalAdd from "./shared/ModalAdd/ModalAdd";
import { useEfaxes } from "./hooks/useEfaxes";
import { Table } from "./shared/Table/Table";

const ClientFax = ({ clientId, match }) => {
  const [state, setState] = useQueryParamsState({
    add: false,
  });

  const { eFaxes, period, order } = useEfaxes(match);

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <Header period={period} onAdd={() => setState({ add: true })} />
        <Table eFaxes={eFaxes} order={order} clientId={clientId} />
        {state.add === "true" && (
          <ModalAdd onClose={() => setState({ add: false })} />
        )}
      </div>
    </div>
  );
};

export default withRouter(ClientFax);
