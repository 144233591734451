import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import { useRetailReport } from "./hooks/useRetailReport";

const RetailReport = () => {
  const { data } = useRetailReport();

  const tableData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        category: data.category_name,
        unitsSold: data.units_sold,
        subTotal: data.sub_total,
        itemDiscount: data.item_discount,
        taxableAmount: data.taxable_amount,
        viewDetails: "View Details",
      })),
    [data],
  );

  return (
    <Table
      bgColor="white"
      cols={[
        { data: "Category", accessor: "category" },
        { data: "Units Sold", accessor: "unitsSold" },
        { data: "Sub-Total", accessor: "subTotal" },
        { data: "Item Discount", accessor: "itemDiscount" },
        { data: "Taxable Amount", accessor: "taxableAmount" },
        { data: "View Details", accessor: "viewDetails" },
      ]}
      data={tableData}
    />
  );
};

export default RetailReport;
