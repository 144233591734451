export const MEMBERSHIP_REPORT_SLUGS = {
  membership: "membership-overview",
  revenue: "membership_revenue",
  churn: "membership_churn_report",
  decline: "membership_decline_report",
  mrr: "mrr_report",
  tipReport: "tip-report",
  paymentMethods: "payment_methods",
  giftCards: "egift_cards",
  constToCompany: "cost_to_company",
  categorySales: "category_sales",
  packageSales: "package_sales",
  ivPackageSales: "iv_package_sales",
  itemSales: "item_sales",
  netSales: "net_sales",
  grossSales: "gross_sales",
  commission: "commission_report",
  discounts: "discounts",
  bdAspire: "bd_aspire_report",
  redeemedAspire: "redeemed_aspire_certificates",
  dailySales: "daily_sales_report",
  taxes: "taxes",
  treatmentPlans: "treatment_plans",
  shortTermLiability: "short_term_liability",
  staffTreatments: "staff_treatments",
  giftCardsRevenue: "egift_cards_revenue",
  cancelationFees: "cancelation_fees",
  refunds: "refunds_report",
  retail: "retail_report",
  servicesPerformed: "services_performed",
  shopifyProducts: "shopify-products",
  shopifySales: "shopify-sales",
  shopifyOrdersReport: "shopify-orders",
  repeatMdRedemption: "repeatmd_redemption",
};
