import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

function createPatientCardDetailsQueryKey({ patientId }) {
  return [QUERY_KEYS.patientCardDetails, patientId];
}

export function usePatientCardDetailsQuery({ patientId }, options = {}) {
  return useQuery(
    createPatientCardDetailsQueryKey({ patientId }),
    () =>
      http.get(HTTP_ENDPOINTS.getPatientCardDetails(patientId), {
        patient_id: patientId,
      }),
    options,
  );
}
