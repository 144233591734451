import React from "react";
import classes from "./EventWrapper.module.scss";

const toPercent = (n) => {
  if (n) {
    return `${n}%`;
  }
  return 0;
};

export function EventWrapper({ style, event, children }) {
  const availableTimeWidth = "100%";
  const gridLeft = 0;

  const noInteration = event.noClickEvents;

  return (
    <div
      data-tip={noInteration ? event.title : undefined}
      className={classes.root}
      style={{
        ...style,
        height: toPercent(style?.height),
        width: event.availabilityGrid
          ? availableTimeWidth
          : toPercent(style?.width),
        top: toPercent(style?.top),
        left: event.availabilityGrid ? gridLeft : toPercent(style?.xOffset),
        zIndex: event.zIndex,
      }}
    >
      {noInteration ? (
        <div className={classes.noInteraction}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
}
