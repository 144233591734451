import React, { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { positionFooterCorrectly } from "../../Utils/services.js";
import { shortenClinicName } from "../../helpers/general";

const allUsStates = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

positionFooterCorrectly();

function UpgradeClinicModal({
  data,
  states,
  allClinics,
  setAllClinics,
  updatedClinics,
  setUpdatedClinics,
  ...props
}) {
  const [modalData, setModalData] = useState({
    id: "",
    address: "",
    suite_number: "",
    clinic_name: "",
    clinic_city: "",
    clinic_state: "",
    clinic_zipcode: "",
    country: "",
  });
  const [modalErrors, setModalErrors] = useState({
    address_error: "",
    clinic_city_error: "",
    clinic_zipcode_error: "",
    suite_number_error: "",
  });
  const [addressState, setAddressState] = useState(modalData.address);
  const [suiteNumberState, setSuiteNumberState] = useState(
    modalData.suite_number,
  );
  const [cityState, setCityState] = useState(modalData.clinic_city);
  const [zipState, setZipState] = useState(modalData.clinic_zipcode);
  const [stateState, setStateState] = useState(modalData.clinic_state);
  const fieldRequired = "This field is required!";
  const [isChecked, setIsChecked] = useState(false);

  function updateClinics(property, inputValue) {
    let getClinics = [...allClinics];
    for (let index = 0; index < getClinics.length; index++)
      if (getClinics[index].id === modalData.id) {
        getClinics[index][property] = inputValue;

        setAllClinics([...getClinics]);
      }
  }

  function handleErrors(property, inputValue) {
    const errors = { ...modalErrors };
    if (!inputValue.trim()) {
      errors[property] = fieldRequired;
      setModalErrors(errors);
      return;
    }
    if (property === "clinic_zipcode_error") {
      if (inputValue.length < 3) {
        errors[property] = "Zip Code is too short";
        setModalErrors(errors);
        return;
      }
    }
    errors[property] = "";
    setModalErrors(errors);
  }

  function handleAddressChange(e) {
    const inputValue = e.target.value;
    handleErrors("address_error", inputValue);
    setAddressState(inputValue);
    updateClinics("address", inputValue);
  }

  function handleCityChange(e) {
    const inputValue = e.target.value;
    handleErrors("clinic_city_error", inputValue);
    setCityState(inputValue);
    updateClinics("clinic_city", inputValue);
  }

  function handleSuiteNumberChange(e) {
    const inputValue = e.target.value;
    setSuiteNumberState(inputValue);
    updateClinics("suite_number", inputValue);
  }

  function handleZipChange(e) {
    const inputValue = e.target.value;
    handleErrors("clinic_zipcode_error", inputValue);
    setZipState(inputValue);
    updateClinics("clinic_zipcode", inputValue);
  }

  function handleStateChange(e) {
    const inputValue = e.target.value;
    setStateState(inputValue);
    updateClinics("clinic_state", inputValue);
  }

  function handleAutocomplete(place) {
    let zipcode = "";
    let city = "";
    let state = "";
    let country = "";
    let address = "";
    if (place.address_components) {
      place.address_components.forEach((data) => {
        if (
          data.types[0] === "route" ||
          data.types[0] === "street_number" ||
          data.types[0] === "neighborhood"
        ) {
          address = data.long_name ? address + data.long_name + " " : "";
        }
        if (data.types[0] === "locality" || data.types[0] === "postal_town") {
          city = data.long_name ? data.long_name : "";
        }
        if (city === "") {
          if (data.types[0] === "administrative_area_level_2") {
            city = data.long_name ? data.long_name : "";
          }
        }
        if (data.types[0] === "country") {
          country = data.short_name ? data.short_name : "";
        }
        if (data.types[0] === "postal_code") {
          zipcode = data.long_name ? data.long_name : "";
        }
        if (zipcode === "") {
          if (data.types[0] === "postal_code_prefix") {
            zipcode = data.long_name ? data.long_name : "";
          }
        }
        if (data.types[0] === "administrative_area_level_1") {
          state = data.short_name ? data.short_name : "";
        }

        setAddressState(address);
        handleErrors("address_error", address);
        updateClinics("address", address);
      });
      setCityState(city);
      setZipState(zipcode);
      setStateState(state);
      handleErrors("clinic_city_error", city);
      handleErrors("clinic_zipcode_error", zipcode);
      updateClinics("clinic_city", city);
      if (!isNaN(parseInt(state))) updateClinics("clinic_zipcode", zipcode);
      updateClinics("clinic_state", state);
      setModalData({ ...modalData, country: country });
      updateClinics("country", country);
    }
  }

  function handleCheckbox() {
    let checkboxValue = !isChecked;
    setIsChecked(checkboxValue);
    if (checkboxValue) {
      setUpdatedClinics([...updatedClinics, data]);
    } else {
      let newUpdatedClinics = updatedClinics.filter((el) => {
        return el.id !== data.id;
      });
      setUpdatedClinics(newUpdatedClinics);
    }
  }

  useEffect(() => {
    setModalData(data);
    setAddressState(data.address);
    setSuiteNumberState(data.suite_number);
    setCityState(data.clinic_city);
    setZipState(data.clinic_zipcode);
  }, []);

  return (
    <div {...props} className="UpgradeClinic-modal-wrapper">
      <div
        style={{ margin: "0" }}
        className="small-popup-outer subscription-popup"
      >
        <div className="small-popup-content">
          <div className="setting-container">
            <div className="row">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="col"
              >
                <input
                  style={{ marginRight: "10px", marginTop: 0 }}
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckbox}
                />
                <p style={{ margin: 0 }}>
                  {shortenClinicName(modalData.clinic_name)}
                </p>
              </div>
              <div className="row">
                <div className="col col-lg-6 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter m-t-0">
                    <div className="newInputLabel">
                      Street Address<span className="setting-require">*</span>
                    </div>
                    <Autocomplete
                      onPlaceSelected={(place) => handleAutocomplete(place)}
                      onChange={handleAddressChange}
                      name="address"
                      id="address"
                      value={addressState}
                      className="newInputField"
                      type="text"
                      types={[]}
                      fields={[
                        "address_component",
                        "formatted_address",
                        "geometry",
                        "name",
                        "place_id",
                        "plus_code",
                        "type",
                      ]}
                    />
                    {modalErrors.address_error.length > 0 && (
                      <p style={{ color: "red" }}>
                        {modalErrors.address_error}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col col-lg-6 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter m-t-0">
                    <div className="newInputLabel">Unit/Suite Number</div>
                    <input
                      onChange={handleSuiteNumberChange}
                      name="suite_number"
                      id="suite_number"
                      className="newInputField"
                      value={suiteNumberState}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-lg-4 col-md-4 col-sm-4">
                  <div className="newInputFileldOuter m-t-0">
                    <div className="newInputLabel">
                      City<span className="setting-require">*</span>
                    </div>
                    <input
                      onChange={handleCityChange}
                      name="clinic_city"
                      id="clinic_city"
                      className="newInputField"
                      value={cityState}
                      type="text"
                    />
                    {modalErrors.clinic_city_error.length > 0 && (
                      <p style={{ color: "red" }}>
                        {modalErrors.clinic_city_error}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col col-lg-4 col-md-4 col-sm-4">
                  <div className="newInputFileldOuter m-t-0">
                    <div className="newInputLabel">
                      State<span className="setting-require">*</span>
                    </div>
                    {modalData.country !== "US" ||
                    (stateState && !allUsStates.includes(stateState)) ? (
                      <input
                        type="text"
                        className="newInputField"
                        onChange={handleStateChange}
                        value={modalData.clinic_state}
                      />
                    ) : (
                      <select
                        onChange={handleStateChange}
                        value={stateState}
                        name="clinic_state"
                        id="clinic_state"
                        className="newSelectField"
                        type="text"
                      >
                        <option value={modalData.clinic_state}>
                          {modalData.clinic_state}
                        </option>
                        {states.length > 0 &&
                          allUsStates.map((usState) => (
                            <option key={usState} value={usState}>
                              {usState}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                </div>
                <div className="col col-lg-4 col-md-4 col-sm-4">
                  <div className="newInputFileldOuter m-t-0">
                    <div className="newInputLabel">
                      Zip code<span className="setting-require">*</span>
                    </div>
                    <input
                      onChange={handleZipChange}
                      name="clinic_zipcode"
                      id="clinic_zipcode"
                      className="newInputField"
                      value={zipState}
                      maxLength="10"
                      type="text"
                    />
                    {modalErrors.clinic_zipcode_error.length > 0 && (
                      <p style={{ color: "red" }}>
                        {modalErrors.clinic_zipcode_error}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeClinicModal;
