import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  clinicId: yup.number().required(),
  appointmentId: yup.number().nullable(),
  procedureId: yup.number().nullable(),
});

export function useQualiphyResendExamMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.qualiphy.resendExam(),
      removeNullishFromShape({
        clinic_id: req.clinicId,
        appointment_id: req.appointmentId,
        procedure_id: req.procedureId,
      }),
    );
  }, options);
}
