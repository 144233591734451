import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  imageData: yup.string().required(),
  uploadType: yup.string().required(),
});

const responseSchema = yup.object({
  fileName: yup.string().required(),
  signatureUrl: yup.string().required(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return {
    fileName: data.file_name,
    signatureUrl: data.signature_url,
  };
};

export function useUploadSignatureMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    const res = await http.post(HTTP_ENDPOINTS.postSignature(), {
      image_data: req.imageData,
      upload_type: req.uploadType,
    });
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
