import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useUpdateTemplateNameQuery } from "../../../../_legacy/Queries";
import { Button } from "../../../../shared/Button/Button";
import { Input } from "../../../../shared/Input/Input";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";

const EditTemplateName = ({
  templateId: id,
  templateName,
  onClose,
  providerId,
  updateTemplateName,
}) => {
  const [name, setName] = useState(templateName);

  const { mutate, isLoading } = useUpdateTemplateNameQuery();

  const updateName = (e) => {
    setName(e.target.value);
  };

  const updateNameTemplate = () => {
    mutate(
      {
        id,
        name,
        provider_id: providerId,
      },
      {
        onSuccess: () => {
          updateTemplateName(name);
          onClose();
        },
      },
    );
  };
  return (
    <ConfirmModal
      className="confirm-modal-center validate-modal"
      isOpen
      onClose={onClose}
      title="Update Name"
      isConfirming={isLoading}
    >
      <Input
        name="name"
        value={name}
        onChange={updateName}
        placeholder="Name"
        maxLength={30}
      />
      <div className="d-flex float-right m-t-20 m-b-20 ">
        <Button isDisabled={!name} onClick={updateNameTemplate}>
          Update
        </Button>
      </div>
    </ConfirmModal>
  );
};

export default EditTemplateName;
