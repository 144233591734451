import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
  giftCards: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        availableAmount: yup.number().required(),
        redeemAmount: yup.number().required(),
        redemptionCode: yup.string().required(),
      }),
    )
    .required(),
});

export function useApplyGiftCardsToInvoiceMutation(options = {}) {
  return useMutation(async (payload) => {
    const req = requestSchema.validateSync(payload, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postApplyGiftCardToInvoice(), {
      invoice_id: req.invoiceId,
      redemption_code: req.giftCards[0].redemptionCode,
      redemption_amount: req.giftCards[0].redeemAmount,
      egift_Card_amount: req.giftCards.reduce(
        (sum, card) => (sum += card.redeemAmount),
        0,
      ),
      gift_card_redeem: req.giftCards.map((card) => ({
        id: card.id,
        amount: card.availableAmount,
        redemption_code: card.redemptionCode,
        redeemed_Amount: card.redeemAmount,
      })),
    });
  }, options);
}
