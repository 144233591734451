import React, { useEffect, useState } from "react";
import {
  useBulkUpdatePriceListQuery,
  useGetPriceListQuery,
} from "../../../_legacy/Queries/Pricelist";
import { Button } from "../../../shared/Button/Button";
import { Table } from "../../Common";
import Loader from "../../Common/Loader";
import InventorySidebar from "../InventorySidebar";
import {
  PriceListColumns,
  pricelistOptions,
  PRICE_TYPES,
} from "./pricelist.consts";
import { getFormattedCells } from "./pricelist.utils";
import classes from "../pricelist.module.scss";
import { useApiClinics } from "../../../pages/Event/hooks/useApiClinics";
import { RouterPrompt } from "../../../widgets/RouterPrompt/RouterPrompt";
import { unwrapOr } from "../../../utilities/general";
import { usePermission } from "../../../hooks/usePermission";
import { PERMISSIONS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";
import { Export } from "./components/Export";

const List = () => {
  const editPermission = usePermission(PERMISSIONS.editProduct);
  const [tableRefetch] = useState(Date.now());
  const { clinics: cliniciList, isClinicsFetching } = useApiClinics();
  const [localUpdate, setLocalUpdate] = useState(false);
  const [pageFilters, setPageFilters] = useState({
    type: pricelistOptions[0].value,
    clinic: cliniciList[0]?.id,
  });
  const [priceListTableData, setPriceListData] = useState({
    listData: [],
    paginationInformaton: {},
  });

  const hasEditPermissions = editPermission.loaded && editPermission.permitted;

  const { listData, paginationInformation } = priceListTableData;
  const { mutate: bulkUpdatePriceList, isLoading: isUpdating } =
    useBulkUpdatePriceListQuery();

  const {
    data: priceListData,
    isFetching,
    isLoading,
    dataUpdatedAt: priceListDataUpdatedAT,
    isSuccess: isPriceListSuccess,
  } = useGetPriceListQuery({
    params: pageFilters,
    tableRefetch,
  });

  const [editableColumns, setEditableColumns] = useState(new Set());
  const [editedRows, setEditedRows] = useState({});

  const handleOnChangeRow = (key, value, row) => {
    const {
      product_price_per_clinic_id,
      id,
      price_per_unit_members,
      price_per_unit,
    } = row.original;
    setEditedRows({
      ...editedRows,
      [id]: {
        price_per_unit,
        price_per_unit_members,
        product_id: id,
        id: product_price_per_clinic_id,
        ...editedRows[id],
        [key]: Number(value),
      },
    });
  };

  const resetEditStates = () => {
    setEditedRows({});
    setEditableColumns(new Set());
  };

  const handleRowSave = () => {
    if (editPermission.loaded && !editPermission.permitted) {
      uiNotification.error("You don't have permissions to edit");
      return;
    }
    if (Object.keys(editedRows).length > 0) {
      const payload = {
        price_list: Object.values(editedRows).map((value) => value),
      };
      bulkUpdatePriceList(payload, {
        onSuccess: () => {
          const updatedPriceList = {
            ...priceListData,
            listData: listData.map((item) => {
              const { id } = item;
              const updatedRow = {
                price_per_unit: unwrapOr(
                  () => editedRows[id].price_per_unit,
                  item.price_per_unit,
                ),
                price_per_unit_members: unwrapOr(
                  () => editedRows[id].price_per_unit_members,
                  item.price_per_unit_members,
                ),
              };
              return { ...item, ...updatedRow };
            }),
          };

          setPriceListData(updatedPriceList);
          setLocalUpdate(true);
          resetEditStates();
          uiNotification.success("Price updated successfully");
        },
        onError: () => {
          resetEditStates();
          uiNotification.error("Something went wrong");
        },
      });
    } else {
      resetEditStates();
    }
  };

  const selectBoxList = [
    {
      label: "Select Clinic",
      key: "clinic",
      options: cliniciList?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
      isDisabled:
        pageFilters.type === PRICE_TYPES.inventory.toLowerCase() &&
        editableColumns.size > 0,
    },
    {
      label: "Select Type",
      key: "type",
      options: pricelistOptions,
      isDisabled:
        pageFilters.type === PRICE_TYPES.inventory.toLowerCase() &&
        editableColumns.size > 0,
    },
  ];
  const buttons = {
    cancelButton: {
      label: "Cancel",
      onClick: () => resetEditStates(),
      variant: "text",
      fontFamily: "secondary",
      className: classes.bgLightBlue,
      disabled: false,
    },
    editButton:
      editableColumns.size > 0
        ? {
            label: "Save",
            onClick: () => handleRowSave(),
            className: "",
            fontFamily: "primary",
            disabled: false,
          }
        : {
            label: "Edit Price",
            onClick: () => {
              setEditableColumns(
                new Set(["price_per_unit", "price_per_unit_members"]),
              );
            },
            variant: "text",
            fontFamily: "secondary",
            className: classes.bgLightBlue,
            disabled: false,
          },
  };

  const toolbarButtons = pageFilters.type ===
    PRICE_TYPES.inventory.toLowerCase() &&
    hasEditPermissions && (
      <div className={classes.headerButtons}>
        {editableColumns.size > 0 && (
          <Button
            onClick={buttons.cancelButton.onClick}
            variant={buttons.cancelButton.variant}
            fontFamily={buttons.cancelButton.fontFamily}
            className={buttons.cancelButton.className}
            isDisabled={buttons.cancelButton.disabled}
          >
            {buttons.cancelButton.label}
          </Button>
        )}
        <Button
          onClick={buttons.editButton.onClick}
          variant={buttons.editButton.variant}
          fontFamily={buttons.editButton.fontFamily}
          className={buttons.editButton.className}
          isDisabled={buttons.editButton.disabled}
        >
          {buttons.editButton.label}
        </Button>
      </div>
    );

  useEffect(() => {
    if (isPriceListSuccess && !localUpdate) {
      const { current_page, per_page, last_page } =
        priceListData.data.data[`${pageFilters.type}_list`];
      const pageDetails = {
        page: current_page,
        per_page: per_page,
        canFetchMore: current_page < last_page,
      };
      setPriceListData((currentListData) => {
        return {
          listData:
            current_page === 1
              ? priceListData.data.data[`${pageFilters.type}_list`].data
              : [
                  ...currentListData.listData,
                  ...priceListData.data.data[`${pageFilters.type}_list`].data,
                ],
          paginationInformation: pageDetails,
        };
      });
    }
    isPriceListSuccess && localUpdate && setLocalUpdate(false);
  }, [priceListDataUpdatedAT]);

  return (
    <div className="main protected users-settings">
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InventorySidebar />
          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <span className="cursor-pointer">Price List</span>
                <div className="memberRightActions">
                  <Export
                    type={pageFilters.type}
                    clinicId={pageFilters.clinic}
                    searchTerm={pageFilters.search}
                    sortBy={pageFilters.sort_by}
                    sortDir={pageFilters.sort_dir}
                  />
                </div>
              </div>
            </div>
            <div id="content">
              <div>
                {cliniciList[0]?.id && (
                  <Table
                    containerClassName={classes.tableContainer}
                    buttonsClassName={classes.toolbarButtons}
                    toolbarClassName={classes.toolbarContainer}
                    className={classes.priceListTable}
                    getFormattedCells={getFormattedCells}
                    isFetching={isFetching}
                    isTopDivider={false}
                    columns={
                      pageFilters.type
                        ? PriceListColumns[`${pageFilters.type}Columns`]
                        : PriceListColumns.inventoryColumns
                    }
                    data={listData}
                    editMode={
                      pageFilters.type ===
                        PRICE_TYPES.inventory.toLowerCase() &&
                      editableColumns.size > 0
                    }
                    editableColumns={editableColumns}
                    handleOnChangeRow={handleOnChangeRow}
                    toolbarSelects={selectBoxList}
                    toolbarButtons={toolbarButtons}
                    paginationInformation={paginationInformation}
                    updateFilters={setPageFilters}
                    moreFilters={{
                      type: pricelistOptions[0].value,
                      clinic: cliniciList[0]?.id,
                    }}
                    defaultSort={{
                      inventory: "product_name",
                      variation: "name",
                      promotion: "name",
                    }}
                    style="old"
                    resetEditStates={resetEditStates}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Loader
          showLoader={
            isUpdating ||
            isClinicsFetching ||
            (isLoading && pageFilters.page === 1)
          }
          isFullWidth={true}
        />

        <RouterPrompt
          when={
            pageFilters.type === PRICE_TYPES.inventory.toLowerCase() &&
            editableColumns.size > 0
          }
          title="Warning"
          text="Are you sure you want to exit this page? Your work will not be saved. Please hit cancel and save your work"
        />
      </div>
    </div>
  );
};

export default List;
