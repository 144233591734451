/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { withRouter } from "react-router";
import { upgradeAcceleratorUrl } from "../../Utils/services.js";

class UpgradeAccountModal extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.state = {
      globalLang:
        languageData && languageData.global ? languageData.global : {},
      user_type: userData && userData.user_type ? userData.user_type : "",
      feature_is_not_available_upgrade_account:
        languageData &&
        languageData.global &&
        languageData.global[
          (props.notificaionType ? props.notificaionType : "feature") +
            "_is_not_available_upgrade_account"
        ]
          ? languageData.global[
              (props.notificaionType ? props.notificaionType : "feature") +
                "_is_not_available_upgrade_account"
            ]
          : "",
    };
  }

  redirectToUpgrade = () => {
    return <div>{this.props.history.push(upgradeAcceleratorUrl())}</div>;
  };

  handleShowUpgradeAlertModal = () => {
    this.props.handleShowUpgradeAlertModal({
      isShowUpgradeAlertModal: !this.props.isShowUpgradeAlertModal,
    });
  };

  render() {
    return (
      <div className="confirmation-modal-wrapper">
        <div
          className={
            this.props.isShowUpgradeAlertModal ? "modalOverlay" : "no-display"
          }
        >
          <div className="small-popup-outer subscription-popup">
            <div className="small-popup-header">
              <div className="popup-name">
                {this.state.globalLang.upgrade_plan}
              </div>
              <a
                className="small-cross"
                onClick={this.handleShowUpgradeAlertModal}
              >
                ×
              </a>
            </div>
            <div className="small-popup-content">
              <div className="juvly-container">
                <div className="monthly-estimate-block">
                  <div className="">
                    <div className="accordian-section text-left">
                      <div className="featureNoteTitle">
                        {this.state.feature_is_not_available_upgrade_account}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                {this.state.user_type == "superadmin" && (
                  <a
                    className="new-blue-btn pull-right"
                    name="btn_create_schedule"
                    onClick={() => this.redirectToUpgrade()}
                  >
                    {this.state.globalLang.click_here_to_upgrade}
                  </a>
                )}
                {this.state.user_type != "superadmin" && (
                  <p className="ContactYourAdmin">
                    {this.state.globalLang.please_contact_your_admin}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpgradeAccountModal);
