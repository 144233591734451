import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  paginationSchema,
  composePaginationSchema,
  getNextPaginationPage,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  searchTerm: yup.string().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    list: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          patientNames: yup.array().of(yup.string()).required(),
          phoneNumber: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    list: data.map((entry) => ({
      id: entry.id,
      patientNames: entry.patient_names || [],
      phoneNumber: entry.phone_number,
    })),
  };
};

function createInboxBlacklistQueryKey({ pageSize, searchTerm }) {
  return [QUERY_KEYS.inboxBlacklist.get, pageSize, searchTerm];
}

export function useInboxBlacklistQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createInboxBlacklistQueryKey({
      pageSize: req.pageSize,
      searchTerm: req.searchTerm,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.inboxBlacklist.get(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          search: req.searchTerm || null,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
