import { numberFormat } from "../../../Utils";

export const getFormattedCells = (obj, header) => {
  if (!obj.type) return "N/A";
  let price = 0;
  let discount = 0;
  const typeVal = obj.type;
  const firstLetter = typeVal[0] || typeVal.charAt(0);
  const typeValues = typeVal.replace(/^./, firstLetter.toUpperCase());
  if (header.id === "type")
    return obj.type && obj.type != "bogo"
      ? typeValues
      : obj.bogo_buy_type == "group"
      ? "Bogo Disc Group"
      : typeValues;
  switch (obj.type) {
    case "percentage":
      price = "N/A";
      discount =
        obj.discount_percentage > 0
          ? Number(obj.discount_percentage).toFixed(3) + "%"
          : "N/A";
      break;
    case "dollars":
      price = "N/A";
      discount =
        obj.discount_dollars > 0
          ? numberFormat(obj.discount_dollars, "currency", 2)
          : "N/A";
      break;
    case "package":
      price =
        obj.package_bogo_price > 0
          ? numberFormat(obj.package_bogo_price, "currency", 2)
          : "N/A";
      discount = "N/A";
      break;
    case "bogo":
      if (obj.bogo_buy_type !== "group") {
        price =
          obj.package_bogo_price > 0
            ? numberFormat(obj.package_bogo_price, "currency", 2)
            : "N/A";
        discount = "N/A";
      } else {
        if (!obj.bogo_discount_percentage) {
          price = "N/A";
          discount = "Free";
        } else {
          price = "N/A";
          discount =
            obj.bogo_discount_percentage > 0
              ? numberFormat(obj.bogo_discount_percentage, "decimal", 0) +
                "% " +
                "OFF"
              : "N/A";
        }
      }
      break;
    default:
      price = "N/A";
      discount = "N/A";
  }
  if (header.id === "package_bogo_price") {
    return price;
  }
  return discount;
};
