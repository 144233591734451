import moment from "moment";
import {
  SALES_REPORT_EXPORT_NAMES,
  useSalesReportExportMutation,
} from "../../../../../../api/mutations/useSalesReportExportMutation";
import {
  API_DATE_FORMAT,
  FILE_EXPORT_TYPES,
} from "../../../../../../consts/api";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { composeDownloadDataUrl } from "../../../../../../utilities/api";
import { passOr } from "../../../../../../utilities/general";

export const useDownloadExcel = ({ period, order, search }) => {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useSalesReportExportMutation({
    onError: () => {
      uiNotification.error(tCommon("error.exportXlsReports"));
    },
  });

  const fromDate = passOr(period.value.from, null, () =>
    moment(period.value.from).format(API_DATE_FORMAT),
  );

  const toDate = passOr(period.value.to, null, () =>
    moment(period.value.to).format(API_DATE_FORMAT),
  );

  const initiate = async () => {
    const res = await mutateAsync({
      rawPayload: {
        start_date: fromDate,
        end_date: toDate,
        sortBy: order.value?.by,
        order: order.value?.direction,
        search_term: search.value,
      },
      type: FILE_EXPORT_TYPES.xls,
      name: SALES_REPORT_EXPORT_NAMES.shortTermLiability,
    });

    window.open(composeDownloadDataUrl(res.fileName), "_blank");
  };

  return {
    initiate,
    isLoading,
  };
};
