/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { withRouter } from "react-router";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import {
  showSelectedWeek,
  showSelectedMonth,
  viewDateFormat,
  apiDateFormat,
  dateLeftPadding,
} from "../../../Utils/date-services.js";

class TimeCardFilter extends Component {
  constructor(props) {
    super(props);
    const selectedDate = props.selectedDate ? props.selectedDate : new Date();
    let calculatedDate1;
    if (props.calendarView === "week") {
      calculatedDate1 = showSelectedWeek(selectedDate, true, true);
    } else {
      calculatedDate1 = showSelectedMonth(selectedDate, false, true);
    }
    const currentDate = moment(moment().format("YYYY-MM-DD 00:00:00")).toDate();
    this.state = {
      selectedDate: selectedDate,
      currentDate: currentDate,
      startDate: calculatedDate1.start_date,
      fromDate: moment(calculatedDate1.start_date).format(
        "YYYY-MM-DD 00:00:00",
      ),
      endDate: calculatedDate1.end_date,
      toDate: moment(calculatedDate1.end_date).format("YYYY-MM-DD 00:00:00"),
      showSelectedDate: calculatedDate1.label_date,
      calendarView: props.calendarView ? props.calendarView : "month",
      isNextDateDisabled:
        moment(currentDate).isBefore(moment(calculatedDate1.end_date)) ||
        moment(currentDate).isSame(moment(calculatedDate1.end_date))
          ? true
          : false,
      isBackDateDisabled: false,
      pay_period_on_start:
        props.pay_period_on_start >= 0 ? props.pay_period_on_start : "1",
    };
  }

  handleDownload = () => {
    this.props.handleDownload();
  };

  handleChildState = (childState) => {
    if (this.props.handleChildState) {
      this.props.handleChildState(childState);
    }
  };

  handleNextPrevDate = (navigate) => {
    var selectedDate = moment(this.state.selectedDate);
    let calendarView = this.props.calendarView;
    let returnState = {};
    let newSelectedDate = "";
    switch (calendarView) {
      case "week": {
        if (navigate == "next") {
          if (this.state.isNextDateDisabled) return false;
          newSelectedDate = selectedDate.add(1, "weeks").toDate();
          returnState.isChangeWeek = true;
        } else if (navigate == "prev") {
          if (this.state.isBackDateDisabled) return false;
          newSelectedDate = selectedDate.subtract(1, "weeks").toDate();
          returnState.isChangeWeek = true;
        } else {
          let weekStartDay = this.state.pay_period_on_start;
          let dateOfDay = moment()
            .day(weekStartDay)
            .format("YYYY-MM-DD 00:00:00");
          let weekCurrentDay = moment().day();
          if (weekStartDay > weekCurrentDay) {
            dateOfDay = moment(dateOfDay).subtract(1, "weeks").toDate();
          }
          newSelectedDate = moment(dateOfDay).toDate();
          returnState.isChangeWeek = false;
        }
        returnState.selectedDate = newSelectedDate;
        const calculatedDateWeek = showSelectedWeek(
          newSelectedDate,
          true,
          true,
        );
        returnState.startDate = calculatedDateWeek.start_date;
        returnState.endDate = calculatedDateWeek.end_date;
        returnState.showSelectedDate = calculatedDateWeek.label_date;
        returnState.isNextDateDisabled =
          moment(this.state.currentDate).isBefore(
            moment(returnState.endDate),
          ) ||
          moment(this.state.currentDate).isSame(moment(returnState.endDate))
            ? true
            : false;
        returnState.isBackDateDisabled = false;
        break;
      }

      case "month": {
        if (navigate == "next") {
          if (this.state.isNextDateDisabled) return false;
          newSelectedDate = selectedDate.add(1, "months").toDate();
          returnState.isChangeWeek = true;
        } else if (navigate == "prev") {
          if (this.state.isBackDateDisabled) return false;
          newSelectedDate = selectedDate.subtract(1, "months").toDate();
          returnState.isChangeWeek = true;
        } else {
          let pay_period_on_start = dateLeftPadding(
            this.state.pay_period_on_start,
          );
          let monthStartDay = this.state.pay_period_on_start;
          let dateOfDay = moment().format(
            "YYYY-MM-" + pay_period_on_start + " 00:00:00",
          );
          let monthCurrentDay = moment().format("D");
          if (monthStartDay > monthCurrentDay) {
            dateOfDay = moment(dateOfDay).subtract(1, "months").toDate();
          }
          newSelectedDate = moment(dateOfDay).toDate();
        }
        returnState.selectedDate = newSelectedDate;
        const calculatedDateMonth = showSelectedMonth(
          newSelectedDate,
          false,
          true,
        );
        returnState.startDate = calculatedDateMonth.start_date;
        returnState.endDate = calculatedDateMonth.end_date;
        returnState.showSelectedDate = calculatedDateMonth.label_date;
        returnState.isNextDateDisabled =
          moment(this.state.currentDate).isBefore(
            moment(returnState.endDate),
          ) ||
          moment(this.state.currentDate).isSame(moment(returnState.endDate))
            ? true
            : false;
        returnState.isBackDateDisabled = false;
        break;
      }

      case "day":
        if (navigate == "next") {
          newSelectedDate = selectedDate.add(1, "days").toDate();
        } else if (navigate == "prev") {
          newSelectedDate = selectedDate.subtract(1, "days").toDate();
        } else {
          newSelectedDate = this.state.currentDate;
        }
        returnState.selectedDate = newSelectedDate;
        returnState.startDate = returnState.selectedDate;
        returnState.endDate = returnState.selectedDate;
        returnState.showSelectedDate = viewDateFormat(moment(newSelectedDate));
        returnState.isNextDateDisabled = true;
        returnState.isBackDateDisabled = false;
        break;
    }
    this.setState(returnState);
    this.getData(calendarView, returnState.selectedDate, returnState);
  };

  handleCalendarView = (nextCalendarView) => {
    let returnState = {};
    returnState.calendarView = nextCalendarView;
    returnState.selectedDate = this.state.selectedDate;
    let calculatedDateView;
    if (nextCalendarView == "week") {
      calculatedDateView = showSelectedWeek(
        returnState.selectedDate,
        true,
        true,
      );
      returnState.startDate = calculatedDateView.start_date;
      returnState.endDate = calculatedDateView.end_date;
      returnState.showSelectedDate = calculatedDateView.label_date;
      returnState.isNextDateDisabled =
        moment(this.state.currentDate).isBefore(moment(returnState.endDate)) ||
        moment(this.state.currentDate).isSame(moment(returnState.endDate))
          ? true
          : false;
      returnState.isBackDateDisabled = false;
    } else if (nextCalendarView == "day") {
      returnState.startDate = returnState.selectedDate;
      returnState.endDate = returnState.selectedDate;
      returnState.showSelectedDate = viewDateFormat(returnState.selectedDate);
      returnState.isNextDateDisabled = true;
      returnState.isBackDateDisabled = false;
    } else {
      calculatedDateView = showSelectedMonth(
        returnState.selectedDate,
        true,
        true,
      );
      returnState.startDate = calculatedDateView.start_date;
      returnState.endDate = calculatedDateView.end_date;
      returnState.showSelectedDate = calculatedDateView.label_date;
      returnState.isNextDateDisabled =
        moment(this.state.currentDate).isBefore(moment(returnState.endDate)) ||
        moment(this.state.currentDate).isSame(moment(returnState.endDate))
          ? true
          : false;
      returnState.isBackDateDisabled = false;
    }
    this.setState(returnState);
    this.getData(
      returnState.calendarView,
      returnState.selectedDate,
      returnState,
    );
  };

  getData = (calendarView, selectedDate, stateData) => {
    let returnState = {
      calendarView: calendarView,
      startDate: stateData.startDate,
      fromDate: apiDateFormat(stateData.startDate),
      endDate: stateData.endDate,
      toDate: apiDateFormat(stateData.endDate),
    };
    if (this.props.isDetailSelectedDate) {
      returnState.detailSelectedDate = selectedDate;
    } else {
      returnState.selectedDate = selectedDate;
    }
    returnState.pay_period_type =
      calendarView === "week" ? "weekly" : "monthly";
    this.props.handleDateFilter(returnState);
  };

  render() {
    return (
      <div className="setting-setion m-b-10">
        <div className="membership-title">
          <div className="row">
            <div className="month-week-day-filter TimeCardFilter">
              <div className="cal-month-week-day-outer TimeCardMonthWeek p-l-0 no-display">
                <button
                  className={
                    this.props.calendarView == "month" ||
                    this.props.calendarView == "monthly"
                      ? "calendar-btn btn-week selected"
                      : "calendar-btn btn-week"
                  }
                  onClick={this.handleCalendarView.bind(this, "month")}
                >
                  Month
                </button>
                <button
                  className={
                    this.props.calendarView == "week" ||
                    this.props.calendarView == "weekly"
                      ? "calendar-btn btn-week selected"
                      : "calendar-btn btn-week"
                  }
                  onClick={this.handleCalendarView.bind(this, "week")}
                >
                  {this.props.globalLang.label_week}
                </button>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-6 cal-date-btn-outer businessSaleCal p-l-15">
                <button className="calendar-btn cal-date-btn">
                  <a
                    className="cal-arrow pull-left"
                    onClick={this.handleNextPrevDate.bind(this, "prev")}
                  >
                    <img
                      alt=""
                      src={
                        this.state.isBackDateDisabled
                          ? "/images/date-left.svg"
                          : "/images/cal-left.svg"
                      }
                    />
                  </a>
                  {this.state.showSelectedDate}
                  <a
                    className="cal-arrow pull-right"
                    onClick={this.handleNextPrevDate.bind(this, "next")}
                  >
                    <img
                      alt=""
                      src={
                        this.state.isNextDateDisabled
                          ? "/images/date-right.svg"
                          : "/images/cal-right.svg"
                      }
                    />
                  </a>
                </button>
                <button
                  className="calendar-btn today-btn"
                  onClick={this.handleNextPrevDate.bind(this, "today")}
                >
                  {this.props.calendarView == "week" ||
                  this.props.calendarView == "weekly"
                    ? "This Week"
                    : "This Month"}
                </button>
              </div>
            </div>
            <a className="easy-link pull-right" onClick={this.handleDownload}>
              {" "}
              <i className="fa fa-download"></i> Download Excel
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TimeCardFilter);
