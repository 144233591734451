import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { isNullish, removeNullishFromShape } from "../../utilities/general";
import { SETTING_BMI_NULL, SETTING_BMI_RANGE_MAX } from "./config";

const requestSchema = yup.object({
  id: yup.number().nullable(),
  name: yup.string().required(),
  min: yup.number().required(),
  max: yup.number().max(SETTING_BMI_RANGE_MAX).nullable(),
  color: yup.string().required(),
});

export function usePatientVitalsSettingRangeMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.patientVitals.createSettingRange(),
        removeNullishFromShape({
          id: req.id,
          name: req.name,
          start_value: req.min,
          end_value: isNullish(req.max) ? SETTING_BMI_NULL : req.max,
          color: req.color,
        }),
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.settingRanges],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.all],
        });

        options?.onSuccess?.(...args);
      },
    },
  );
}
