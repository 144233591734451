import React from "react";
import { SIMPLE_QUESTION_TYPES } from "../../../../../../api/questionnaires/config";
import { AnswerChoices } from "../../shared/AnswerChoices/AnswerChoices";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { useYesNoOptions } from "../../../../hooks/useYesNoOptions";
import { ChoiceImage } from "../../shared/ChoiceImage/ChoiceImage";

/**
 * @param {{
 *    question: ReturnType<questionAdapter>[number];
 *    choices: string[];
 *    reason: string | null;
 *    onChangeChoices: (next: string[]) => void;
 *    onChangeReason?: (next: string) => void;
 * }} param0
 */
export function Question({
  question,
  choices,
  reason,
  onChangeChoices,
  onChangeReason,
}) {
  var { tCommon } = useAppTranslation.Common();
  var yesNoOptions = useYesNoOptions();

  var answerComponentsMap = {
    [SIMPLE_QUESTION_TYPES.yesNo]: (
      <AnswerChoices
        isMulti={false}
        options={yesNoOptions}
        choices={choices}
        reason={reason}
        onChangeChoices={onChangeChoices}
        onChangeReason={onChangeReason}
      />
    ),
    [SIMPLE_QUESTION_TYPES.multiText]: (
      <AnswerChoices
        isMulti={question.isMultiSelection}
        choices={choices}
        onChangeChoices={onChangeChoices}
        options={question.choices?.map((choice) => ({
          value: choice.text,
          slot: choice.text,
        }))}
      />
    ),
    [SIMPLE_QUESTION_TYPES.multiImage]: (
      <AnswerChoices
        isMulti={question.isMultiSelection}
        choices={choices}
        onChangeChoices={onChangeChoices}
        options={question.choices?.map((c) => ({
          value: String(c.imageUrl),
          slot: (
            <ChoiceImage
              url={c.imageUrl}
              label={question.isImageLabelsVisible ? c.imageLabel : undefined}
            />
          ),
        }))}
      />
    ),
  };

  var answerComponent = answerComponentsMap?.[question.type];

  return (
    <div className={styles.root}>
      {answerComponent ? (
        <>
          <div>
            <h3 className={styles.question}>
              {question.question}
              {question.isRequired && <span>*</span>}
            </h3>
            {question.isDescriptionVisible && question.description && (
              <p className={styles.description}>{question.description}</p>
            )}
          </div>
          {answerComponent}
        </>
      ) : (
        <AlertBox>
          {tCommon("fillPatientQuestionnaire.error.noQuestion")}
        </AlertBox>
      )}
    </div>
  );
}
