import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  patientIds: yup.array().of(yup.number()).required(),
});

export function useRestorePatientsMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postRestorePatients(), {
      patient_ids: req.patientIds,
    });
  }, options);
}
