import React, { useState } from "react";
import classes from "./Footer.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useDelete } from "../../hooks/useDelete";
import { useQuestionnaire } from "../../hooks/useQuestionnaire";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";

export function Footer({ questionnaireId, onSubmit, isSubmitting, error }) {
  const isEditMode = Boolean(questionnaireId);

  const { tCommon } = useAppTranslation.Common();
  const del = useDelete(questionnaireId);
  const questionnaire = useQuestionnaire(questionnaireId);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Button
          isDisabled={questionnaire.isFetching || isSubmitting}
          onClick={onSubmit}
          leftAdornment={
            isSubmitting ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tCommon("label.save")}
        </Button>
        {isEditMode && (
          <Button
            variant="outlined"
            color="error"
            isDisabled={questionnaire.isFetching || del.isLoading}
            onClick={() => setIsConfirmDeleteModalOpen(true)}
            leftAdornment={
              del.isLoading ? (
                <CircularProgress size="small" color="red" />
              ) : undefined
            }
          >
            {tCommon("label.delete")}
          </Button>
        )}
      </div>
      {error && (
        <AlertBox variant="error" size="small">
          {error}
        </AlertBox>
      )}
      {isConfirmDeleteModalOpen && (
        <ConfirmModal
          isOpen
          onCancel={() => setIsConfirmDeleteModalOpen(false)}
          onClose={() => setIsConfirmDeleteModalOpen(false)}
          onConfirm={() => {
            setIsConfirmDeleteModalOpen(false);
            del.initiate();
          }}
        >
          {tCommon("confirm.deleteQuestionnaire")}
        </ConfirmModal>
      )}
    </div>
  );
}
