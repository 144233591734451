import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getPosPaymentSettingData,
  getPosBankAccountFields,
  addPosBankAccount,
  setPosDefaultBnkAccount,
  getPosPayoutScheduleData,
  updatePosPayoutScheduleData,
  exportEmptyData,
  changePosStatus,
} from '../../../../Actions/Settings/settingsActions.js';
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../../Common/Loader.js'
import { capitalizeFirstLetter, toggleBodyScroll, getIsPosEnabled } from '../../../../Utils/services.js';
import PosTitle from './../PosTitle.js';
import { FormStep4 } from '../Clearance';
import { withDeleteBankInfo } from './withDeleteBankInfo.js';
import { ConfirmModal } from '../../../../boxes/ConfirmModal/ConfirmModal.js';
import { CircularProgress } from '../../../../shared/CircularProgress/CircularProgress.js';
import { shortenClinicName } from "../../../../helpers/general";
import { uiNotification } from '../../../../services/UINotificationService.js';


class PosPaymentSettings extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      posPaymentData :{},
      pos_gateway :'',
      selectedBank: {},
      bankConfigClinic: [],
      bankConfigGlobal: [],
      connectionMode :'clinic',
      defaultCurrency: "usd",
      defaultCountryName: "United States",
      hasClinicBankAccount: false,
      showBankNotAddedAlert: false,
      pos_enabled: false,
      showLoader: false,
      showEditBankData: false,

      addBankClinicId:0,
      posBankAccountData: {},
      account_input_label:'',
      account_input_place_holder:'',
      account_input_value:'',
      account_holder_name:'',
      routing_number:'',
      transit_number:'',
      institution_number:'',
      show_routing_number: false,
      bankDefaultCountry:{},
      bankDefaultCurrency:'',
      accountInputLableCalss:'newInputField',
      accountHolderNameCalss:'newInputField',
      routingNumberCalss:'newInputField',
      transitNumberCalss:'newInputField',
      institutionNumberCalss:'newInputField',
      showLoaderAddBank: false,
      isShowBankAccountModal:false,

      payoutScheduleClinicId:0,
      payout_anchor:'',
      payout_type:'automatic',
      payout_inteval:'daily',
      payout_daily_delay_days:'2',
      payoutMonthList:{},
      payoutWeekList:{},
      payoutTypeCalss:'newSelectField',
      payoutIntervalCalss:'newSelectField',
      payoutAnchorCalss:'newSelectField',
      showLoaderPayoutSchedule: false,
      isShowPayoutScheduleModal:false,

      isShowAlertModal:false,
      defaultBankIdentifier:'',

      deleteBankId: null,
    }
  }

  componentDidMount() {
    this.setState({showLoader:true})
    this.fetchPaymentSettings();
  }

  fetchPaymentSettings = () => {
    this.props.getPosPaymentSettingData()
      .catch(res => {
        uiNotification.error(this.state.globalLang[res.message] || "Unable To Load Payment Data");
      })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {}
    if (nextProps.posPaymentData !== undefined && nextProps.posPaymentData !== prevState.posPaymentData) {
      returnState.posPaymentData = nextProps.posPaymentData;
      returnState.bankConfigClinic= returnState.posPaymentData.clinic_bank_config;
      returnState.bankConfigGlobal= returnState.posPaymentData.global_bank_config;
      returnState.connectionMode= returnState.posPaymentData.connection_method;
      returnState.defaultCurrency= returnState.posPaymentData.default_currency;
      returnState.defaultCountryName= (returnState.posPaymentData.country_name) ? returnState.posPaymentData.country_name : "United States";
      returnState.hasClinicBankAccount= returnState.posPaymentData.has_clinic_bank_account;
      returnState.showBankNotAddedAlert= returnState.posPaymentData.show_bank_not_added_alert;
      returnState.pos_enabled = returnState.posPaymentData.pos_enabled;
      returnState.showLoader = false;
      returnState.posBankAccountData = {}
      returnState.showLoaderAddBank = false;
      returnState.isShowBankAccountModal = false;
      returnState.posPayoutScheduleData = {}
      returnState.showLoaderPayoutSchedule = false;
      returnState.isShowPayoutScheduleModal  = false;
      returnState.payoutMonthList = nextProps.posPaymentData.monthly_opions;
      returnState.payoutWeekList = nextProps.posPaymentData.weekly_options;
      returnState.pos_gateway = nextProps.posPaymentData.pos_gateway;
      returnState.addBankClinicId = 0;
      returnState.payoutScheduleClinicId = 0;
      returnState.defaultBankIdentifier =  ''
      toggleBodyScroll(false)
    } else if (nextProps.posBankAccountData != undefined && nextProps.posBankAccountData !== prevState.posBankAccountData) {
      returnState.showLoader = false;
      returnState.posBankAccountData = nextProps.posBankAccountData;
      returnState.account_input_label = nextProps.posBankAccountData.account_input_label;
      returnState.account_input_place_holder = nextProps.posBankAccountData.account_place_holder;
      returnState.account_input_value = '';
      returnState.account_holder_name = '';
      returnState.routing_number = '';
      returnState.transit_number = '';
      returnState.institution_number = '';
      returnState.show_routing_number =  nextProps.posBankAccountData.show_routing_number;
      returnState.bankDefaultCountry = nextProps.posBankAccountData.default_country;
      returnState.bankDefaultCurrency = nextProps.posBankAccountData.default_currency;
      returnState.defaultCountryName= (returnState.posBankAccountData.country_name) ? returnState.posBankAccountData.country_name : "United States";
      returnState.accountInputLableCalss = 'newInputField';
      returnState.accountHolderNameCalss = 'newInputField';
      returnState.routingNumberCalss = 'newInputField';
      returnState.transitNumberCalss = 'newInputField';
      returnState.institutionNumberCalss = 'newInputField';
      returnState.isShowBankAccountModal  = true;
      toggleBodyScroll(true)
      nextProps.exportEmptyData();
    } else if (nextProps.changePosMode != undefined && nextProps.changePosMode == true) {
      returnState.showLoader = false;
      returnState.pos_enabled = !prevState.pos_enabled;
      nextProps.exportEmptyData();
    }  else if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      returnState.showLoaderAddBank = false;
      returnState.showLoaderPayoutSchedule = false;
      nextProps.exportEmptyData();
      toggleBodyScroll(false)
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let returnState = {}
    returnState.dataChanged = true;
    if(name === this.state.account_input_label){
      returnState.account_input_value = value;
    } else {
      if(name === 'payout_interval'){
        returnState.payout_anchor = '';
      }
      returnState[name] = value;
    }
    this.setState(returnState);
  }

  handleSubmit = (event) => {
    return
  }

  handleBankAccountModal = (event) => {
    toggleBodyScroll(!this.state.isShowBankAccountModal)
    this.setState({isShowBankAccountModal: !this.state.isShowBankAccountModal})
  }

  getPosBankAccountFields = (event) => {
    const clinicId = event.target.dataset.clinicId;
    if(clinicId >= 0){
      this.setState({showLoader:true,addBankClinicId:clinicId})
      this.props.getPosBankAccountFields(clinicId)
        .catch(res => {
          const fallbackMsg = "Unable To Get Account Fields";
          if (res.message == 'third_party_error') {
            uiNotification.error(res.data || fallbackMsg);
          } else {
            uiNotification.error(this.state.globalLang[res.message] || fallbackMsg);
          }
        });
    }
  }

  addPosBankAccount = (event) => {
    let error = false;

    if (typeof this.state.account_holder_name === undefined || this.state.account_holder_name === null || this.state.account_holder_name.trim() === '') {
      this.setState({accountHolderNameCalss:'newInputField field_error'})
      error = true;
    } else if(this.state.account_holder_name) {
      this.setState({accountHolderNameCalss:'newInputField'})
    }

    if (typeof this.state.account_input_value === undefined || this.state.account_input_value === null || this.state.account_input_value.trim() === '') {
      this.setState({accountInputLableCalss:'newInputField field_error'})
      error = true;
    } else if(this.state.account_input_value) {
      this.setState({accountInputLableCalss:'newInputField'})
    }

    if(this.state.show_routing_number){
      if(this.state.bankDefaultCountry && this.state.bankDefaultCountry.value != 'ca'){
        if (typeof this.state.routing_number === undefined || this.state.routing_number === null || this.state.routing_number.trim() === '' ) {
          this.setState({routingNumberCalss:'newInputField field_error'})
          error = true;
        } else if(this.state.routing_number) {
          this.setState({routingNumberCalss:'newInputField'})
        }
      } else {
        if (typeof this.state.transit_number === undefined || this.state.transit_number === null || this.state.transit_number.trim() === '' ) {
          this.setState({transitNumberCalss:'newInputField field_error'})
          error = true;
        } else if(this.state.transit_number) {
          this.setState({transitNumberCalss:'newInputField'})
        }
        if (typeof this.state.institution_number === undefined || this.state.institution_number === null || this.state.institution_number.trim() === '' ) {
          this.setState({institutionNumberCalss:'newInputField field_error'})
          error = true;
        } else if(this.state.institution_number) {
          this.setState({institutionNumberCalss:'newInputField'})
        }
      }

    }
    if(error){
      return
    }

    let formData = {
      account_number :  this.state.account_input_value,
      clinic_id :  this.state.addBankClinicId,
      account_holder_name : this.state.account_holder_name,
      country : (this.state.bankDefaultCountry !== undefined && this.state.bankDefaultCountry.value !== undefined) ? this.state.bankDefaultCountry.value : '',
      currency : this.state.bankDefaultCurrency,
    }
    if(this.state.show_routing_number){
      if(this.state.bankDefaultCountry && this.state.bankDefaultCountry.value != 'ca'){
        formData.routing_number = this.state.routing_number
      } else {
        formData.transit_number = this.state.transit_number
        formData.institution_number = this.state.institution_number
      }
    }

    if(this.state.addBankClinicId >=  0){
      this.setState({showLoaderAddBank:true})
      this.props.addPosBankAccount(formData)
        .then(res => {
          uiNotification.success(this.state.globalLang[res.message] || "Bank Created Successfully");
        })
        .catch(res => {
          const fallbackMsg = "Unable To Create Bank";
          if (res.message == 'third_party_error') {
            uiNotification.error(res.data || fallbackMsg);
          } else {
            uiNotification.error(this.state.globalLang[res.message] || fallbackMsg);
          }
        })
    }
  }


  setPosDefaultBnkAccount = () => {
    if(this.state.defaultBankIdentifier !== ''){
        this.setState({showLoader:true})
        this.props.setPosDefaultBnkAccount({bank_identifier:this.state.defaultBankIdentifier})
          .then((res) => {
            uiNotification.success(this.state.globalLang[res.message] || "Default Bank Changed Successfully");
          })
          .catch((res) => {
            const fallbackMsg = "Unable to Set Default Bank";
            if (res.message == 'third_party_error') {
              uiNotification.error(res.data || fallbackMsg);
            } else {
              uiNotification.error(this.state.globalLang[res.message] || fallbackMsg);
            }
          })
    }
    this.handleAlertModal();
  }

  handleAlertModal = (defaultBankIdentifier) => {
    toggleBodyScroll(!this.state.isShowAlertModal)
    defaultBankIdentifier = (defaultBankIdentifier !== '') ? defaultBankIdentifier : ''
    this.setState({isShowAlertModal: !this.state.isShowAlertModal,defaultBankIdentifier:defaultBankIdentifier})
  }

  handlePayoutScheduleModal = () => {
    toggleBodyScroll(!this.state.isShowPayoutScheduleModal)
    this.setState({isShowPayoutScheduleModal: !this.state.isShowPayoutScheduleModal})
  }

  setPosPayoutScheduleData = (clinicId,data) => {
    this.setState({
        payout_type: (data.payout_interval == 'manual') ? 'manual' : 'automatic',
        payout_interval : (data.payout_interval == 'manual') ? 'daily' : data.payout_interval,
        payout_anchor : (data.payout_anchor) ?  data.payout_anchor : '',
        payoutScheduleClinicId:clinicId,
        isShowPayoutScheduleModal:true
    })
    toggleBodyScroll(true)
  }

  updatePosPayoutScheduleData = (event) => {
    let error = false;
    if (typeof this.state.payout_type === undefined || this.state.payout_type === null || this.state.payout_type === '') {
      this.setState({payoutTypeCalss:'newSelectField field_error'})
      error = true;
    } else if(this.state.payout_type) {
      this.setState({payoutTypeCalss:'newSelectField'})
    }

    if(this.state.payout_type === 'automatic'){
      if (typeof this.state.payout_interval === undefined || this.state.payout_interval === null || this.state.payout_interval === '') {
        this.setState({payoutIntervalCalss:'newSelectField field_error'})
        error = true;
      } else if(this.state.payout_interval) {
        this.setState({payoutIntervalCalss:'newSelectField'})
      }

      if(this.state.payout_interval === 'weekly' || this.state.payout_interval === 'monthly') {
        if (typeof this.state.payout_anchor === undefined || this.state.payout_anchor === null || this.state.payout_anchor === '') {
          this.setState({payoutAnchorCalss:'newSelectField field_error'})
          error = true;
        } else if(this.state.payout_anchor) {
          this.setState({payoutAnchorCalss:'newSelectField'})
        }
      }
    }

    if(error){
      return
    }

    let formData = {
      payout_type :  this.state.payout_type,
      payout_interval : this.state.payout_interval,
      clinic_id :  this.state.payoutScheduleClinicId,
    }
    if(this.state.payout_anchor){
      formData.payout_anchor = this.state.payout_anchor
    }
    if(this.state.payoutScheduleClinicId >=  0){
      this.setState({showLoaderPayoutSchedule:true})
      this.props.updatePosPayoutScheduleData(formData)
        .then(res => {
          uiNotification.success(this.state.globalLang[res.message] || "Successfully Updated");
        })
        .catch(res => {
          if (res.message == 'third_party_error') {
            uiNotification.error(res.data || "Unable to Update");
          } else {
            uiNotification.error(this.state.globalLang[res.message] || "Unable to Update");
          }
        })
    }
  }

  renderObjectOption = (list,key) => {
    let htmlList = [<option value="">{(key==='weekly') ? this.state.settingsLang.pos_select_day_of_week : this.state.settingsLang.pos_select_a_day}</option>];

      Object.keys(list).forEach((idx) => {
        htmlList.push(<option key={key+'-'+idx} value={idx} >{list[idx]}</option>);
      })
    return htmlList;
  }

  renderPayoutSchedule = (payoutData) => {
    let scheduleType = ''
    let scheduleInterval = ''
    if(payoutData.payout_interval){
      if(payoutData.payout_interval === 'manual') {
        scheduleInterval = this.state.settingsLang.pos_you_will_no_longer_be_able_to_see_transactions
        scheduleType= this.state.settingsLang.pos_payout_manual
      } else {
        scheduleType= this.state.settingsLang.pos_payout_automatic
        if(payoutData.payout_interval == 'daily') {
          scheduleInterval = `${capitalizeFirstLetter(payoutData.payout_interval)} ${payoutData.payout_daily_delay_days} ${this.state.settingsLang.pos_day_rolling_basis}`;
        } else if(payoutData.payout_interval == 'weekly') {
          scheduleInterval = `${capitalizeFirstLetter(payoutData.payout_interval)} ${this.state.settingsLang.pos_every} ${capitalizeFirstLetter(payoutData.payout_anchor)}`;
        } else if(payoutData.payout_interval == 'monthly') {
          scheduleInterval = `${capitalizeFirstLetter(payoutData.payout_interval)} ${this.state.settingsLang.pos_on_the_day} ${payoutData.payout_anchor}`;
        }
      }
    } else {
      scheduleInterval = this.state.settingsLang.Npos_no_schedule_found
    }
    return (
      <div className="bank-account">
        <strong>{scheduleType} - {scheduleInterval}</strong>
      </div>
    )
  }

  toggleLoader = (loader) => {
    this.setState({showLoader: loader})
  }

  editBankDetails = (clinicObj) => {
    toggleBodyScroll(!this.state.showEditBankData)
    let bank = clinicObj.account_clearent_config.clearent_setup.business_information.bank[0];
    let returnState = {}
    let selectedBank = {
          "bankName": bank.bankName,
          "bankAccountTypeID": bank.bankAccountTypeID.toString(),
          "bankAccountNameTypeID": bank.bankAccountNameTypeID.toString(),
          "isNameSameAsLegalOrDBAName": true,//(bank) ? bank.isNameSameAsLegalOrDBAName.toString() : step4.isNameSameAsLegalOrDBAName,
          "nameOnAccount": bank.nameOnAccount, 
          "aba": bank.aba ,
          "accountNumber":  bank.accountNumber ,
          "hasFees":  true,
          "hasFunds": true,
          "hasChargebacks": true,
          "voidedCheckDocumentID": bank.voidedCheckDocumentID ,
          "siteTypeID" : 2,
      }
    returnState.selectedBank = selectedBank;
    returnState.showEditBankData = true;
    this.setState(returnState);
  }

  closeEditOverlay = () => {
    toggleBodyScroll(!this.state.showEditBankData);
    this.setState({showEditBankData: false, selectedBank: {}});
  }

  handleBankEditSubmit = (id, data) => {
    let selectedBank = {...this.state.selectedBank, data}
  }

  handleDeleteBankInfo = () => {
    const { deleteBankInfo } = this.props;
    if (this.state.deleteBankId) {
      this.setState({ deleteBankId: null });
      deleteBankInfo
        .deleteById(this.state.deleteBankId)
        .then(this.fetchPaymentSettings);
    }
  }

  render() {
    const { deleteBankInfo } = this.props;
    let userData = JSON.parse(localStorage.getItem('userData'));

    const isMainAdmin = userData.account.admin_id === userData.user.id;

    return (
      <div className="main protected">
        <div id="content" className="content-pos-payment-settings">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <PosTitle history={this.props.history} settingsLang={this.state.settingsLang} globalLang={this.state.globalLang} activeMenu={'pos-payment-settings'} />
            {(getIsPosEnabled() === true ) && <div className="setting-setion">

                <div className="membership-title">{this.state.settingsLang.pos_bank_accounts}
                  {((this.state.connectionMode == 'global' && this.state.bankConfigGlobal.stripe_user_id !== undefined && this.state.bankConfigGlobal.stripe_user_id !== null && this.state.bankConfigGlobal.stripe_user_id !== '') && (this.state.bankConfigGlobal.account_bank_config !== undefined &&  this.state.bankConfigGlobal.account_bank_config.length >= 0)) &&
                  <div className="memberRightActions">
                    <a onClick={this.getPosBankAccountFields} data-account-type="clinic" data-clinic-id={0} className="new-blue-btn">{this.state.settingsLang.pos_add_new_bank_account}</a>
                  </div>
                }
                </div>


              <div className="setting-container p20">
                <div id="per-clinic-settings">
                  {(this.state.connectionMode == 'clinic' && this.state.bankConfigClinic.length > 0) && this.state.pos_gateway == 'stripe' &&
                    this.state.bankConfigClinic.map((clinicObj,clinicIdx) => {
                      if(clinicObj.stripe_user_id !== undefined && clinicObj.stripe_user_id !== null && clinicObj.stripe_user_id !== ''){
                        return (
                          <div key={'clinicList-'+clinicIdx} className="clinic-account">
                            <div className="bankOuter">
                              <div className="settings-subtitle m-b-20">{shortenClinicName(clinicObj.clinic_name)}
                                    <div className="memberRightActions">
                                      <a onClick={this.getPosBankAccountFields} data-account-type="clinic" data-clinic-id={clinicObj.id}  className="new-blue-btn addBank" >{this.state.settingsLang.pos_add_new_bank_account}</a>
                                  </div>
                              </div>
                              <div className="row">
                              {(this.state.hasClinicBankAccount && clinicObj.account_bank_config !== undefined &&  clinicObj.account_bank_config.length > 0) ?
                                clinicObj.account_bank_config.map((bankObj,bankIdx) => {
                                  return (
                                      <div key={'clinic-account-'+bankIdx} className={(clinicObj.account_bank_config.length > 1) ? "col-md-4 col-md-6 bankSection" : "col-md-4 col-md-6 bankSection170"}>
                                        <div className="bankName"><i className="fa fa-university"></i> {bankObj.bank_name}</div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Account number</label>
                                          <span className="bankDetails">{bankObj.bank_account_number}</span>
                                        </div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Country</label>
                                          <span className="bankDetails">{capitalizeFirstLetter(this.state.defaultCountryName)}</span>
                                        </div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Currency</label>
                                          <span className="bankDetails">{capitalizeFirstLetter(this.state.defaultCurrency)}</span>
                                        </div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Routing number</label>
                                          <span className="bankDetails">{bankObj.routing_number}</span>
                                        </div>
                                        {bankObj.is_default != 1 &&
                                          <div className="bankRow">
                                            <button
                                              onClick={this.handleAlertModal.bind(this,bankObj.bank_identifier) }
                                              data-account-type="clinic"
                                              data-clinic-id={clinicObj.id}
                                              className="new-blue-btn no-margin new-blue-s"
                                              disabled={deleteBankInfo.isDeleting && deleteBankInfo.deletingIds.includes(bankObj.id)}
                                            >
                                              {this.state.settingsLang.pos_set_as_default}
                                            </button>
                                            {isMainAdmin && (
                                              <button
                                                type="button"
                                                className="new-blue-btn m-l-5 new-blue-s items-center bg-red"
                                                disabled={deleteBankInfo.isDeleting && deleteBankInfo.deletingIds.includes(bankObj.id)}
                                                onClick={() => this.setState({ deleteBankId: bankObj.id })}
                                              >
                                                {deleteBankInfo.isDeleting && deleteBankInfo.deletingIds.includes(bankObj.id) && (
                                                  <CircularProgress
                                                    size="tiny"
                                                    className="m-r-5"
                                                    color="white"
                                                  />
                                                )}
                                                Delete Banking Info
                                              </button>
                                            )}
                                          </div>
                                        }
                                      </div>
                                    )
                                  })
                                  :
                                  <div className="col-md-12 col-xs-12">
                                    <div className="bank-account"> <span className='text-danger'><i className="fas fa-exclamation-triangle field-warning-color" /> {this.state.settingsLang.pos_no_bank_account_found}.&nbsp;</span>
                                      <span className='text-danger'>Please add a {this.state.defaultCurrency.toUpperCase()} {this.state.settingsLang.pos_denominated_bank_account_to_pay_out_your} {this.state.defaultCurrency.toUpperCase()} {this.state.settingsLang.pos_balance} </span>
                                    </div>
                                  </div>
                                }

                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                  }
                  {(this.state.connectionMode == 'clinic' && this.state.bankConfigClinic.length > 0) && this.state.pos_gateway == 'clearent' &&
                    this.state.bankConfigClinic.map((clinicObj,clinicIdx) => {
                        return (
                          <div key={'clinicList-'+clinicIdx} className="clinic-account">
                            <div className="bankOuter">
                              <div className="settings-subtitle m-b-20">{clinicObj.clinic_name}
                                {clinicObj.account_clearent_config !== undefined &&  clinicObj.account_clearent_config != null && clinicObj.account_clearent_config.clearent_setup.status == 'completed' && 
                                    <div className="memberRightActions">
                                      <a onClick={() => this.editBankDetails(clinicObj)} data-account-type="clinic" data-clinic-id={clinicObj.id}  className="new-blue-btn addBank" >Edit Bank Details</a>
                                    </div>
                                  }
                              </div>
                              <div className="row">
                              {(clinicObj.account_clearent_config !== undefined &&  clinicObj.account_clearent_config != null && clinicObj.account_clearent_config.clearent_setup.status == 'completed') ?


                                <React.Fragment>
                                {clinicObj.account_clearent_config && clinicObj.account_clearent_config.clearent_setup && clinicObj.account_clearent_config.clearent_setup.business_information && clinicObj.account_clearent_config.clearent_setup.business_information.bank &&

                                      <div key={'clinic-account'} className={"col-md-4 col-md-6 bankSection"}>
                                        <div className="bankName"><i className="fa fa-university"></i> {clinicObj.account_clearent_config.clearent_setup.business_information.bank[0].bankName}</div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Account number</label>
                                          <span className="bankDetails">{clinicObj.account_clearent_config.clearent_setup.business_information.bank[0].accountNumber}</span>
                                        </div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Country</label>
                                          <span className="bankDetails">{capitalizeFirstLetter(this.state.defaultCountryName)}</span>
                                        </div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Currency</label>
                                          <span className="bankDetails">{capitalizeFirstLetter(this.state.defaultCurrency)}</span>
                                        </div>
                                        <div className="bankRow">
                                          <label className="bankLabel">Routing number</label>
                                          <span className="bankDetails">{clinicObj.account_clearent_config.clearent_setup.business_information.bank[0].aba}</span>
                                        </div>
                                        
                                      </div>
                                    }
                                  </React.Fragment>
                                  
                                  :
                                  <div className="col-md-12 col-xs-12">
                                    <div className="bank-account"> <span className='text-danger'><i className="fas fa-exclamation-triangle field-warning-color" /> {this.state.settingsLang.pos_no_bank_account_found}.&nbsp;</span>
                                      <span className='text-danger'>Please add a {this.state.defaultCurrency.toUpperCase()} {this.state.settingsLang.pos_denominated_bank_account_to_pay_out_your} {this.state.defaultCurrency.toUpperCase()} {this.state.settingsLang.pos_balance} </span>
                                    </div>
                                  </div>
                                }

                              </div>
                            </div>
                          </div>
                        )
                    })
                  }

                  {(this.state.connectionMode == 'global' && this.state.bankConfigGlobal.stripe_user_id !== undefined && this.state.bankConfigGlobal.stripe_user_id !== null && this.state.bankConfigGlobal.stripe_user_id !== '') &&
                        <div  className="clinic-account">
                          {(this.state.bankConfigGlobal.account_bank_config !== undefined &&  this.state.bankConfigGlobal.account_bank_config.length > 0) ?
                          <div className="bank-account-list">
                            {this.state.bankConfigGlobal.account_bank_config.map((bankObj,bankIdx) => {
                              return (

                                <div className={(this.state.bankConfigGlobal.account_bank_config.length > 1) ? "col-md-4 col-md-6 bankSection" : "col-md-4 col-md-6 bankSection170"}>
                                 <div className="bankName"><i className="fa fa-university"></i> {bankObj.bank_name}</div>
                                  <div className="bankRow">
                                    <label className="bankLabel">Account number</label>
                                    <span className="bankDetails">{bankObj.bank_account_number}</span>
                                  </div>
                                  <div className="bankRow">
                                    <label className="bankLabel">Country</label>
                                    <span className="bankDetails">{capitalizeFirstLetter(this.state.defaultCountryName)}</span>
                                  </div>
                                  <div className="bankRow">
                                    <label className="bankLabel">Currency</label>
                                    <span className="bankDetails">{capitalizeFirstLetter(this.state.defaultCurrency)}</span>
                                  </div>
                                  <div className="bankRow">
                                    <label className="bankLabel">Routing number</label>
                                    <span className="bankDetails">{bankObj.routing_number}</span>
                                  </div>
                                  {(bankObj.is_default != 1) &&
                                    <div className="bankRow">
                                      <button
                                        onClick={this.handleAlertModal.bind(this,bankObj.bank_identifier)}
                                        data-account-type="clinic"
                                        data-clinic-id={0}
                                        className="new-blue-btn no-margin new-blue-s"
                                        disabled={deleteBankInfo.isDeleting && deleteBankInfo.deletingIds.includes(bankObj.id)}
                                      >
                                        {this.state.settingsLang.pos_set_as_default}
                                      </button>
                                      {isMainAdmin && (
                                        <button
                                          type="button"
                                          className="new-blue-btn m-l-5 new-blue-s items-center bg-red"
                                          disabled={deleteBankInfo.isDeleting && deleteBankInfo.deletingIds.includes(bankObj.id)}
                                          onClick={() => this.setState({ deleteBankId: bankObj.id })}
                                        >
                                          {deleteBankInfo.isDeleting && deleteBankInfo.deletingIds.includes(bankObj.id) && (
                                            <CircularProgress
                                              size="tiny"
                                              className="m-r-5"
                                              color="white"
                                            />
                                          )}
                                          Delete Banking Info
                                        </button>
                                      )}
                                    </div>
                                  }
                                </div>
                              )
                            })}
                          </div>
                          :
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <div className="bank-account"> <span className='text-danger'><i className="fas fa-exclamation-triangle field-warning-color" /> {this.state.settingsLang.pos_no_bank_account_found}.&nbsp;</span>
                                {((this.state.connectionMode === 'global' && this.state.bankConfigGlobal !== undefined && (typeof this.state.bankConfigGlobal.account_bank_config === undefined || (typeof this.state.bankConfigGlobal.account_bank_config !== undefined && this.state.bankConfigGlobal.account_bank_config.length === 0))) || (this.state.connectionMode === 'clinic' && this.state.hasClinicBankAccount === false)) &&
                                  <span className='text-danger'>Please add a {this.state.defaultCurrency.toUpperCase()} {this.state.settingsLang.pos_denominated_bank_account_to_pay_out_your} {this.state.defaultCurrency.toUpperCase()} {this.state.settingsLang.pos_balance} </span>
                                }
                              </div>
                            </div>
                          </div>
                          }
                        </div>
                  }
                </div>


              </div>
              {this.state.pos_gateway == 'stripe' && 
                <div className="setting-section border-top">
                  <div className="membership-title border-top">{this.state.settingsLang.pos_payout_schedule}
                    {(this.state.connectionMode == 'global' && this.state.bankConfigGlobal.stripe_user_id !== undefined && this.state.bankConfigGlobal.stripe_user_id !== null && this.state.bankConfigGlobal.stripe_user_id !== '') &&
                    <div className="pull-right m-t-5">
                        <a className="easy-link no-padding m-r-15" onClick={this.setPosPayoutScheduleData.bind(this,0,this.state.bankConfigGlobal)} data-account-type="clinic" data-clinic-id={0} ><i className="fa fa-pencil-alt m-r-5" /> {this.state.globalLang.label_edit}</a>
                    </div>
                  }
                  <p className="p-text m-t-20">{this.state.settingsLang.pos_set_a_schedule_to_automatically_receive_payouts}</p>
                  </div>

                  <div className="p20">
                  <div id="per-clinic-settings-payout">
                    {(this.state.hasClinicBankAccount && this.state.connectionMode == 'clinic' && this.state.bankConfigClinic.length > 0) &&
                      this.state.bankConfigClinic.map((clinicObj,clinicIdx) => {
                        if(clinicObj.stripe_user_id !== undefined && clinicObj.stripe_user_id !== null && clinicObj.stripe_user_id !== '' && (clinicObj.account_bank_config !== undefined &&  clinicObj.account_bank_config.length > 0)){
                          return (
                            <div key={'bankConfigClinic-payoutSchedule-'+clinicIdx} className="row m-b-20">
                              <div className="col-md-4 col-xs-12"><b>{clinicObj.clinic_name}</b></div>
                              <div className="col-md-6 col-xs-12">
                                {this.renderPayoutSchedule(clinicObj)}
                              </div>
                              <div className="col-md-2 col-xs-12 text-right">
                                <a className="easy-link no-padding" onClick={this.setPosPayoutScheduleData.bind(this,clinicObj.id,clinicObj)} data-account-type="clinic" data-clinic-id={clinicObj.id} ><i className="fa fa-pencil-alt" /> {this.state.globalLang.label_edit}</a>
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                    {(this.state.connectionMode == 'global' && this.state.bankConfigGlobal.stripe_user_id !== undefined && this.state.bankConfigGlobal.stripe_user_id !== null && this.state.bankConfigGlobal.stripe_user_id !== '') &&

                    <div key={'bankConfigClinic-payoutSchedule-'+0} className="row m-b-20">
                      <div className="col-md-10 col-xs-12">
                        {this.renderPayoutSchedule(this.state.bankConfigGlobal)}
                      </div>
                    </div>
                  }
                  </div>
                </div>
                </div>
              }
            </div>
          }
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
          </div>
          {/*  Add Bank Details - START */}
          <div className={this.state.isShowBankAccountModal ? "modalOverlay" : 'no-display' }>
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">{this.state.settingsLang.pos_add_bank_details}</div>
                <a className="small-cross" onClick={this.handleBankAccountModal} >×</a>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">{this.state.settingsLang.pos_person_business_name} <span className="setting-require">*</span></div>
                        <div className="setting-input-outer">
                          <input name="account_holder_name" value={this.state.account_holder_name} className={this.state.accountHolderNameCalss} onChange={this.handleInputChange} placeholder="Person/Business name" type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">{this.state.account_input_label} <span className="setting-require">*</span></div>
                        <div className="setting-input-outer">
                          <input name={this.state.account_input_label}  value={this.state.account_input_value} className={this.state.accountInputLableCalss} onChange={this.handleInputChange} placeholder={this.state.account_input_place_holder} type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">{this.state.globalLang.label_country} <span className="setting-require">*</span></div>
                        <div className="setting-input-outer">
                          <input  name="country" value={(this.state.bankDefaultCountry !== undefined && this.state.bankDefaultCountry.label !== undefined) ? this.state.bankDefaultCountry.label : ''} className="newInputField" onChange={this.handleInputChange} readOnly="readonly" type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">{this.state.settingsLang.setting_currency} <span className="setting-require">*</span></div>
                        <div className="setting-input-outer">
                          <input name="currency" value={this.state.bankDefaultCurrency.toUpperCase()} onChange={this.handleInputChange} className="newInputField"  readOnly="readonly" type="text" />
                        </div>
                      </div>
                    </div>
                    {(this.state.show_routing_number) &&
                      (this.state.bankDefaultCountry && this.state.bankDefaultCountry.value != 'ca') ?
                      <div className="col-sm-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.pos_routing_sort_number} <span className="setting-require">*</span></div>
                          <div className="setting-input-outer">
                            <input name="routing_number" value={this.state.routing_number} className={this.state.routingNumberCalss} onChange={this.handleInputChange}   placeholder={'11000000'} type="text" />
                          </div>
                        </div>
                      </div>
                      :
                      <>
                      <div className="col-sm-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{'Transit Number'} <span className="setting-require">*</span></div>
                          <div className="setting-input-outer">
                            <input name="transit_number" value={this.state.transit_number} className={this.state.transitNumberCalss} onChange={this.handleInputChange}   placeholder={'11000'} type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{'Institution Number'} <span className="setting-require">*</span></div>
                          <div className="setting-input-outer">
                            <input name="institution_number" value={this.state.institution_number} className={this.state.institutionNumberCalss} onChange={this.handleInputChange}   placeholder={'000'} type="text" />
                          </div>
                        </div>
                      </div>
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a className="new-blue-btn pull-right" onClick={this.addPosBankAccount}>{this.state.globalLang.label_save}</a>
                <a className="new-white-btn pull-right" onClick={this.handleBankAccountModal}>{this.state.globalLang.label_cancel}</a>
              </div>
              <Loader showLoader={this.state.showLoaderAddBank} />
            </div>
          </div>
          {/*  Add Bank Details - END */}
          {/*  Set Payout Schedule - START */}
          <div className={this.state.isShowPayoutScheduleModal ? "modalOverlay" : 'no-display' }>
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">{this.state.settingsLang.pos_set_payout_schedule}</div>
                <a className="small-cross" onClick={this.handlePayoutScheduleModal} >×</a>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">{this.state.settingsLang.pos_payout_type} </div>
                        <div className="setting-input-outer">
                          <select name="payout_type" value={this.state.payout_type} onChange={this.handleInputChange} className={this.state.payoutTypeCalss}>
                            <option value="manual">{this.state.settingsLang.pos_payout_manual}</option>
                            <option value="automatic">{this.state.settingsLang.pos_payout_automatic}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    { (this.state.payout_type === 'automatic') ?
                      <div className="payout-automatic-options ">
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.settingsLang.pos_payout_type} </div>
                            <div className="setting-input-outer">
                              <select name="payout_interval" value={this.state.payout_interval} onChange={this.handleInputChange} className={this.state.payoutIntervalCalss}>
                                <option value="daily">{this.state.settingsLang.pos_days}</option>
                                <option value="weekly">{this.state.settingsLang.pos_every_week}</option>
                                <option value="monthly">{this.state.settingsLang.pos_every_month}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        { (this.state.payout_interval === 'weekly' || this.state.payout_interval === 'monthly') &&
                          <div className="col-sm-6 show-hide-payout-on">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">{this.state.settingsLang.pos_payout_on} <span className="setting-require">*</span></div>
                              <div className="setting-input-outer">
                                <select name="payout_anchor" value={this.state.payout_anchor} onChange={this.handleInputChange}  className={this.state.payoutAnchorCalss}>
                                  {(this.state.payout_interval === 'weekly') ?
                                    this.renderObjectOption(this.state.payoutWeekList,'weekly')
                                    :
                                    this.renderObjectOption(this.state.payoutMonthList,'monthly')
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      :
                      <div className="col-sm-12 payout-manual-options">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.pos_you_will_no_longer_be_able_to_see_transactions}</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="footer-static">
              <a className="new-blue-btn pull-right" onClick={this.updatePosPayoutScheduleData}>{this.state.globalLang.label_save}</a>
              <a className="new-white-btn pull-right" onClick={this.handlePayoutScheduleModal}>{this.state.globalLang.label_cancel}</a>
              </div>
              <Loader showLoader={this.state.showLoaderPayoutSchedule} />
            </div>
          </div>
          {/*  Set Payout Schedule - END */}

          {/*  Edit Clearent Bank Details - START */}
          <div className={this.state.showEditBankData ? "modalOverlay" : 'no-display' }>
            <div className="wide-popup">
              <div className="modal-blue-header">
                <a className="popup-cross" onClick={this.closeEditOverlay} >×</a>
                <div className="popup-blue-name">{"Edit Bank Details"}</div>
              </div>
              <div className="wide-popup-wrapper time-line m-t-30">
                  {Object.keys(this.state.selectedBank).length > 0 && 
                    <FormStep4
                      formValue={this.state.selectedBank}
                      handleFormStepChange={(id,data) => this.handleBankEditSubmit(id, data)}
                      resources={this.state.clearanceAllResources}
                      toggleLoader = {this.toggleLoader}
                    />
                  }
              </div>
            </div>
          </div>
        {/*  Edit Clearent Bank Details - Ends */}



          {/* Alert Modal - START */}
          <div className={this.state.isShowAlertModal ? "modalOverlay" : 'no-display' }>
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">{this.state.globalLang.label_alert}</div>
                <a className="small-cross" onClick={this.handleAlertModal} >×</a>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="new-field-label font-size-12 alert-modal-content">You are going to set this bank account as default!</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a className="new-white-btn pull-right" onClick={this.handleAlertModal}>{this.state.globalLang.label_cancel}</a>
                <a className="new-red-btn pull-left" onClick={this.setPosDefaultBnkAccount} >{this.state.globalLang.label_yes}</a>
              </div>
            </div>
          </div>
          {/* Alert Modal - END */}
          </div>
        </div>
        {this.state.deleteBankId !== null && (
          <ConfirmModal
            isOpen={this.state.deleteBankId !== null}
            onCancel={() => this.setState({ deleteBankId: null })}
            onClose={() => this.setState({ deleteBankId: null })}
            onConfirm={this.handleDeleteBankInfo}
            confirmTitle="Confirm and Delete"
          >
            Are you sure you want to delete this bank account?
            This cannot be undone.
          </ConfirmModal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};
  if (state.SettingReducer.action === "GET_POS_PAYMENT_SETTING_DATA") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    } else {
      returnState.posPaymentData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "GET_POS_BANK_ACCOUNT_FIELDS") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    } else {
      returnState.posBankAccountData = state.SettingReducer.data.data;
    }
  } else if (state.SettingReducer.action === "ADD_POS_BANK_ACCOUNT") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    } else {
      returnState.posPaymentData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SET_POS_DEFAULT_BANK_ACCOUNT") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.posPaymentData = state.SettingReducer.data.data;
    }
  } else if (state.SettingReducer.action === "UPDATE_POS_PAYOUT_SCHEDULE_DATA") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    } else {
      returnState.posPaymentData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "CHANGE_POS_STATUS") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    } else {
      returnState.changePosMode = true;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getPosPaymentSettingData,
    getPosBankAccountFields,
    addPosBankAccount,
    setPosDefaultBnkAccount,
    getPosPayoutScheduleData,
    updatePosPayoutScheduleData,
    exportEmptyData,
    changePosStatus,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withDeleteBankInfo(PosPaymentSettings)
);
