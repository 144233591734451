import React, { memo, useState, useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import clsx from "clsx";
import styles from "./discountcoupons.module.scss";
import { showFormattedDate } from "../../../Utils/services";
import Loader from "../../Common/Loader";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

const formatString = (inputString) => {
  let replacedString = inputString.replace(/_/g, " ");
  return replacedString.replace(/\b\w/g, (char) => char.toUpperCase());
};

const MembershipUserLogList = ({
  membershipLogData,
  loadMore,
  isLoadingMore,
}) => {
  const { tCommon } = useAppTranslation.Common();
  const scrollbarsRef = useRef(null);
  const [allMembershipLogData, setAllMembershipLogData] = useState([]);

  useEffect(() => {
    if (membershipLogData?.data) {
      setAllMembershipLogData((prevData) => {
        const newMemberShipListData = [...membershipLogData.data, ...prevData];
        const uniqueDataList = _.uniqBy(newMemberShipListData, "id");
        const sortedDataList = _.sortBy(uniqueDataList, "created").reverse();
        return sortedDataList;
      });
    }
    if (scrollbarsRef.current && membershipLogData?.current_page === 1) {
      scrollbarsRef.current.scrollToTop();
    }
  }, [membershipLogData?.data]);

  const loadMoreUserLogOnScroll = _.debounce((values) => {
    const { scrollTop, clientHeight, scrollHeight } = values;
    const shouldLoadMore = scrollHeight - (scrollTop + clientHeight) <= 5;

    if (
      shouldLoadMore &&
      membershipLogData?.next_page_url &&
      allMembershipLogData?.length < membershipLogData?.total
    ) {
      loadMore(membershipLogData.current_page + 1);
    }
  }, 100);

  return (
    <div className="setting-setion m-b-15">
      <div className="membershipSectionTitle background-white-outer">
        {tCommon("membership.membershipUserLogs.headTitle")}
      </div>
      <div className="wallet-view-block">
        <div className="membershipSectionContent">
          <div className="table-responsive fixed-header-table">
            <table className="table-updated juvly-table table-fixed">
              <thead
                className={clsx(
                  "user-log-table-updated-th",
                  styles.discountTableUpdatedThead,
                )}
              >
                <tr>
                  <th className="col-xs-2 fs-15 table-updated-th p-r-0 p-t-0 ">
                    {tCommon(
                      "membership.membershipUserLogs.membershipTypeName",
                    )}
                  </th>
                  <th className="col-xs-3 table-updated-th p-r-0">
                    {tCommon("membership.membershipUserLogs.editedBy")}
                  </th>
                  <th className="col-xs-4 table-updated-th p-r-0">
                    {tCommon("membership.membershipUserLogs.editedFields")}
                  </th>
                  <th className="col-xs-3 table-updated-th p-r-0 text-center">
                    {tCommon("membership.membershipUserLogs.editedAt")}
                  </th>
                </tr>
              </thead>
              <Scrollbars
                style={{ minHeight: "200px" }}
                ref={scrollbarsRef}
                className="custome-scroll"
                onScrollFrame={loadMoreUserLogOnScroll}
              >
                <tbody>
                  {allMembershipLogData?.map((obj) => (
                    <tr className="table-updated-tr" key={obj.id}>
                      <td className="table-updated-td col-xs-2">
                        {obj?.tier_name}
                      </td>
                      <td className="table-updated-td col-xs-3">
                        {obj.user?.full_name}
                      </td>
                      <td className="table-updated-td col-xs-4 p-l-1">
                        {(formatString(obj?.child || ''))}
                      </td>
                      <td className="table-updated-td col-xs-3 text-center">
                        {showFormattedDate(obj.created, false, "MM/DD/YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Scrollbars>
              {isLoadingMore && (
                <Loader showLoader={isLoadingMore} isFullWidth={false} />
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MembershipUserLogList);
