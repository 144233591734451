import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useDeleteInvoiceMutation } from "../../../../api/mutations/useDeleteInvoiceMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { passOr } from "../../../../utilities/general";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { history } from "../../../../history";
import classes from "./DeleteInvoice.module.scss";
import { extractApiError } from "../../../../utilities/api";
import { usePermission } from "../../../../hooks/usePermission";
import { PERMISSIONS } from "../../../../consts/api";
import { useCurrentAccountQuery } from "../../../../api/queries/useAccountQuery";

export function DeleteInvoice({ invoiceId, patientId, takePaymentRoute }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const viewInvoicesPermission = usePermission(PERMISSIONS.viewSalesInvoices);
  const { data: accountData } = useCurrentAccountQuery();

  const deleteInvoice = useDeleteInvoiceMutation({
    onError: (e) => {
      const errorKey = extractApiError(e);
      uiNotification.error(
        tSales(`invoicePreview.apiError.deleteInvoice.${errorKey}`),
      );
    },
    onSuccess: () => {
      history.goBack();
      uiNotification.success(tSales("invoicePreview.apiSuccess.deleteInvoice"));
    },
  });

  const onDelete = () => {
    if (invoiceId && patientId) {
      setIsConfirmModalOpen(false);
      deleteInvoice.mutate({
        invoiceId: Number(invoiceId),
        patientId: Number(patientId),
      });
    }
  };
  const onTakePayment = () => {
    window.location.href = takePaymentRoute;
  };

  const canViewInvoices =
    viewInvoicesPermission.loaded && viewInvoicesPermission.permitted;

  return canViewInvoices ? (
    <div className={classes.root}>
      {takePaymentRoute && (
        <div className="m-r-20">
          {accountData?.isPosEnabled && (
            <Button
              size="small"
              color="primary"
              onClick={() => onTakePayment()}
            >
              Take Payment
            </Button>
          )}
        </div>
      )}
      <Button
        size="small"
        color="error"
        onClick={() => setIsConfirmModalOpen(true)}
        isDisabled={deleteInvoice.isLoading}
        leftAdornment={passOr(deleteInvoice.isLoading, undefined, () => (
          <CircularProgress color="white" size="tiny" />
        ))}
      >
        {tCommon("label.delete")}
      </Button>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={onDelete}
      >
        {tCommon("confirm.deleteInvoice")}
      </ConfirmModal>
    </div>
  ) : null;
}

DeleteInvoice.propTypes = {
  invoiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DeleteInvoice.defaultProps = {
  invoiceId: null,
  patientId: null,
};
