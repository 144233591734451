import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import { isLoggedIn } from "../../Utils/services";

export const PrivateRoute = ({
  component: Component,
  restrictCondition,
  ...rest
}) => {
  const notRestricted = isLoggedIn() && !restrictCondition;

  return (
    <Route
      {...rest}
      render={() =>
        notRestricted ? (
          <Route {...rest} render={(props) => <Component {...props} />} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login(),
            }}
          />
        )
      }
    />
  );
};
