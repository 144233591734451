import { useMemo } from "react";
import * as yup from "yup";
import { useServicesGroupedByCategoriesQuery } from "../../../api/queries/useServicesGroupedByCategoriesQuery";
import { uiNotification } from "../../../services/UINotificationService";
import { unwrapOr } from "../../../utilities/general";

const schema = yup.array().of(
  yup.object({
    id: yup.number(),
    name: yup.string(),
    clinicNames: yup.array().of(yup.string()),
    services: yup.array().of(
      yup.object({
        id: yup.number(),
        name: yup.string(),
        clinicName: yup.string(),
      }),
    ),
  }),
);

const compose = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return Object.values(data).map((c) => ({
    id: c.id,
    name: c.name,
    clinicNames: c.clinics,
    services: c.services.map((s) => ({
      id: s.id,
      name: s.name,
      clinicName: s.clinic_name,
    })),
  }));
};

export function useApiServicesByCategory({ servicesType }) {
  const { data, isLoading } = useServicesGroupedByCategoriesQuery({
    payload: {
      modelType: servicesType,
    },
    options: {
      onError: () => {
        uiNotification.error("Unable To Retrieve Services");
      },
    },
  });

  const servicesByCategory = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  return {
    servicesByCategory,
    isLoading,
  };
}
