import { useInfiniteQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import {
  getNextPaginationPage,
  composePaginationSchema,
} from "../../../../../../schemas/pagination";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useSalesSummaryReport = (params, options = {}) => {
  return useInfiniteQuery({
    queryKey: ["salesSummaryReportDatas", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getDailyDiscountReport(), {
        params: {
          ...params,
          pagesize: 15,
          page: pageParam,
        },
      });

      const composeResponse = {
        ...composePaginationSchema({
          response: res?.data?.data || {},
          pageSize: 15,
        }),
        clinics: res.data.data.clinics,
        reportData: res.data.data.daily_discount_report,
        reportHeader: res.data.data.daily_discount_report_header,
      };
      return composeResponse;
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
};
