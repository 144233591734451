import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Chat.module.scss";
import { useChat } from "./hooks/useChat";
import { MessagesList } from "./shared/MessagesList/MessagesList";
import { PatientInfo } from "./shared/PatientInfo/PatientInfo";

export function Chat({ id }) {
  const chat = useChat(id);

  return (
    <div className={cx("memberWalletOuter business-section", classes.root)}>
      <MessagesList messages={chat.messages} isLoading={chat.isLoading} />
      <PatientInfo patient={chat.patient} isLoading={chat.isLoading} />
    </div>
  );
}

Chat.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
