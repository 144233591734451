import React, { useState } from "react";
import { useViewClientDocument } from "./components/hooks/useViewClientDocument";

export const withExpirationActionButtons = (Component) => {
  const WrappedComponent = (props) => {
    const [selectedDocument, setSelectedDocument] = useState(null);

    const redirectTo = (path) => {
      props.history.push(path);
    };

    const viewClientDocumentData = useViewClientDocument(
      selectedDocument,
      redirectTo,
    );

    return (
      <Component
        {...props}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        viewClientDocumentData={viewClientDocumentData}
      />
    );
  };

  return WrappedComponent;
};
