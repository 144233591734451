import { useQuery } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../consts/api";

export const useCheckProcedure = (procedureId, options = {}) => {
  return useQuery(
    ["traceabilityCheckProcedure"],
    async () => {
      return http.get(HTTP_ENDPOINTS.traceabilityCheckProcedure(procedureId));
    },
    { enabled: false, ...options },
  );
};
