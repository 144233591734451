import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { useUsersObOrderQuery } from "../../../../../../../api/queries/useUsersObOrderQuery";

export function useUsers(clinicId) {
  const { tSettings } = useAppTranslation.Settings();

  const { data, isLoading } = useUsersObOrderQuery({
    payload: {
      clinicId: clinicId,
    },
    options: {
      enabled: Boolean(clinicId),
      onError: () => {
        uiNotification.error(tSettings("users.obOrder.error.fetch"));
      },
    },
  });

  const users = useMemo(
    () =>
      data?.map((i) => ({
        id: i.id,
        content: i.fullName,
      })) || [],
    [data],
  );

  return {
    users,
    isUsersLoading: isLoading,
  };
}
