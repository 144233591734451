import { usePatientConsentsQuery } from "../../../../api/consents/patientConsents/usePatientConsentsQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { getPathParams } from "../PatientConsents.utils";

export function usePatientConsents() {
  const { tCommon } = useAppTranslation.Common();
  const { entityId, entityType } = getPathParams();

  const { data, isLoading, refetch } = usePatientConsentsQuery({
    payload: {
      entityId,
      entityType,
      withMarkedAsOnFile: true,
    },
    options: {
      onError: () => {
        uiNotification.error(tCommon("error.fetchConsents"));
      },
    },
  });

  const isEmpty = !isLoading && data?.length === 0;

  return {
    data: data || [],
    refetch,
    isLoading,
    isEmpty,
    isVisible: !isLoading && !isEmpty,
    patientId: data?.[0]?.patient_id,
    appointmentId: data?.[0]?.appointment_id,
    procedureId: data?.[0]?.procedure?.[0]?.id,
  };
}
