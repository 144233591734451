import * as yup from "yup";
import moment from "moment";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useExtendedFormik } from "../../../../../../../hooks/useExtendedFormik";
import {
  apiDateStringToDate,
  dateToApiDateString,
} from "../../../../../../../utilities/api";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../../consts/general";
import { API_DATE_FORMAT } from "../../../../../../../consts/api";

const days = {
  all: "All",
  monday: "M",
  tuesday: "T",
  wednesday: "W",
  thursday: "Th",
  friday: "F",
  saturday: "S",
  sunday: "Su",
};

const initDaysWithAll = (days_) =>
  days_.length === Object.values(days).length - 1 && !days_.includes(days.all)
    ? [...days_, days.all]
    : days_;

const prepareValue = (value) => (value ? apiDateStringToDate(value) : null);

const prepareFormattedValue = (value, format) =>
  value ? moment(value).format(format || PREVIEW_DATE_FORMAT) : null;

const bannerDayKeyMap = {
  monday: days.monday,
  tuesday: days.tuesday,
  wednesday: days.wednesday,
  thursday: days.thursday,
  friday: days.friday,
  saturday: days.saturday,
  sunday: days.sunday,
};

export function useForm(banner, submitter) {
  const { data: user } = useCurrentUserQuery();
  const { tCommon: t } = useAppTranslation.Common();

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    getError,
  } = useExtendedFormik({
    enableReinitialize: true,
    onSubmit: (values) => {
      submitter({
        id: banner.id,
        name: values.name,
        text: values.text,
        startDate: values.startDate,
        endDate: values.endDate,
        monday: values.days.includes(days.monday),
        tuesday: values.days.includes(days.tuesday),
        wednesday: values.days.includes(days.wednesday),
        thursday: values.days.includes(days.thursday),
        friday: values.days.includes(days.friday),
        saturday: values.days.includes(days.saturday),
        sunday: values.days.includes(days.sunday),
        status: Boolean(values.status),
      });
    },
    initialValues: {
      name: banner.name || "",

      text: banner.description || "",

      startDate: banner
        ? moment(banner.start_date).format(API_DATE_FORMAT)
        : "",

      endDate: banner ? moment(banner.end_date).format(API_DATE_FORMAT) : "",

      days: initDaysWithAll(
        Object.entries(bannerDayKeyMap).reduce(
          (carry, [key, value]) => (banner[key] ? [...carry, value] : carry),
          [],
        ),
      ),

      status: banner?.status || false,
    },
    validationSchema: yup.object({
      name: yup.string().required(t("formError.required")),
      text: yup.string().required(t("formError.required")),
      startDate: yup.string().required(t("formError.required")),
      endDate: yup.string().required(t("formError.required")),
      days: yup.array().of(yup.string()).min(1, t("formError.required")),
      status: yup.boolean().required(),
    }),
  });

  return {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    getError,
    setFieldValue,
    days: {
      list: Object.values(days),
      value: values.days,
      onToggle: (day) => {
        if (day === days.all) {
          const isAllSelected =
            values.days.length >= Object.values(days).length - 1;

          return setFieldValue(
            "days",
            isAllSelected ? [] : Object.values(days),
          );
        }

        let next = [...values.days];

        if (values.days.includes(day)) {
          next = next.filter((x) => x !== day);
          next = next.filter((x) => x !== days.all);
        } else {
          next.push(day);
        }

        if (next.length >= Object.values(days).length - 1) {
          next.push(days.all);
        }

        setFieldValue("days", next);
      },
    },
    startDate: {
      value: prepareValue(values.startDate),
      formattedValue: prepareFormattedValue(
        values.startDate,
        user?.previewDateFormat,
      ),
      onChange: (next) => {
        const nextDate = dateToApiDateString(next);
        setFieldValue("startDate", nextDate);

        if (moment(nextDate).isAfter(values.endDate)) {
          setFieldValue("endDate", "");
        }
      },
    },
    endDate: {
      value: prepareValue(values.endDate),
      formattedValue: prepareFormattedValue(
        values.endDate,
        user?.previewDateFormat,
      ),
      onChange: (next) => setFieldValue("endDate", dateToApiDateString(next)),
    },
  };
}
