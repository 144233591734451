import React from "react";
import { Summary } from "../../widgets/Summary/Summary";
import { StageGrid } from "../../shared/StageGrid/StageGrid";
import { BalanceDue } from "../../widgets/BalanceDue/BalanceDue";
import { Success } from "../../widgets/Success/Success";

export function StageBalanceDue() {
  return (
    <StageGrid>
      <Success />
      <Summary />
      <BalanceDue />
    </StageGrid>
  );
}
