import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import cx from "clsx";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import classes from "./LoadMoreObserver.module.scss";

export function LoadMoreObserver({
  loadMore,
  isLoading,
  hasMore,
  center,
  className,
  noPaddingBottom,
}) {
  const { ref, inView } = useInView();
  const { tCommon } = useAppTranslation.Common();

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  const isNothingMore = !isLoading && hasMore === false;

  return (
    <div
      ref={ref}
      className={cx(
        {
          [classes.root]: isLoading || isNothingMore,
          [classes.rootCenter]: center,
          [classes.noPaddingBottom]: noPaddingBottom,
        },
        className,
      )}
    >
      {isLoading && tCommon("label.loadingMore")}
      {isNothingMore && tCommon("label.nothingMoreLoad")}
    </div>
  );
}

LoadMoreObserver.propTypes = {
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  noPaddingBottom: PropTypes.bool,
};

LoadMoreObserver.defaultProps = {
  hasMore: undefined,
  center: true,
  className: undefined,
  noPaddingBottom: false,
};
