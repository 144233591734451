import React from "react";
import moment from "moment";
import {
  capitalizeFirstLetter,
  displayName,
  numberFormat,
  showFormattedDate,
} from "../../../../../../Utils/services";
import { TreatmentPlanActions } from "../../../../components/TreatmentPlanActions/TreatmentPlanActions";

export function PrintArea({
  profile,
  refetchClientData,
  showProgramDetails,
  cancelPlanByID,
  getPlanDetailsByID,
  performThisAction,
  monthArray,
  viewBreakDown,
  startProTotal,
  startProMonthly,
  changePlanCard,
}) {
  return (
    <div id="print_area">
      <div className="treatmentPlanTitle">
        <span>
          {profile.current_treatment_plan?.plan_name}
        </span>
        {profile?.current_treatment_plan?.prescribe_only === 0 && (
          <TreatmentPlanActions
            plan={profile?.current_treatment_plan}
            refetchClientData={refetchClientData}
            onStartProgram={showProgramDetails}
            onCancelPlan={() => {
              cancelPlanByID(
                profile.current_treatment_plan.id,
                profile.current_treatment_plan.treatment_plan_subscription.id,
              );
            }}
            onEditPlan={() =>
              getPlanDetailsByID(profile.current_treatment_plan.id, "new", 0)
            }
            onEditPlanAsProvider={() => {
              getPlanDetailsByID(
                profile.current_treatment_plan.id,
                "new",
                profile.current_treatment_plan.treatment_plan_subscription.id,
              );
            }}
            onPrintPlan={() =>
              performThisAction(profile.current_treatment_plan.id, "print")
            }
          />
        )}
      </div>
      <div className="treatment-timeline">
        <div className="row">
          <div className="col-sm-7 col-xs-12">
            {profile?.current_treatment_plan?.plan_type === "payg" &&
              profile?.current_treatment_plan?.pay_as_you_go?.length > 0 &&
              profile?.current_treatment_plan?.pay_as_you_go?.map(
                (obj, idx) => {
                  return (
                    <div key={idx} className="treatmentSection">
                      {
                        <div className="procedureDate payg-schedule">
                          {obj.product_name}:{" "}
                          <label className="smallDetails">
                            {obj.units} Units (Every {obj.repeat_val}{" "}
                            {obj.repeat_by})
                          </label>{" "}
                        </div>
                      }
                    </div>
                  );
                },
              )}
            {monthArray &&
              monthArray.length > 0 &&
              monthArray.map((mobj, idx) => {
                const mobjDate = moment("01 " + mobj[0]).format("M-YYYY");
                let show = false;

                let returnedArray =
                  profile?.current_treatment_plan?.patient_treatment_plan_schedule?.map(
                    (mmobj, midx) => {
                      let monthToCompare =
                        parseInt(mmobj.month) + "-" + parseInt(mmobj.year);
                      let productName = mmobj.product
                        ? capitalizeFirstLetter(mmobj.product.product_name)
                        : "N/A";
                      let units = mmobj.units
                        ? ", " + mmobj.units + " units"
                        : "";

                      if (mobjDate === monthToCompare) {
                        show = true;
                        return (
                          <div key={midx}>
                            {productName}
                            {units}
                          </div>
                        );
                      }
                    },
                  ) || [];

                return (
                  <div
                    key={idx}
                    className={
                      show ? "treatmentSection" : "treatmentSection no-display"
                    }
                  >
                    <div className="procedureDate">{mobj[0]}</div>
                    <div className="treatmentContent">
                      <div className="treatmentDetails">{returnedArray}</div>
                    </div>
                  </div>
                );
              })}
          </div>
          {profile?.current_treatment_plan && (
            <div className="col-sm-5 col-xs-12 treatment-timeline">
              <div className="row">
                {profile.current_treatment_plan?.plan_type === "monthly" && (
                  <div className="col-xs-12">
                    <div className="treat-text">Skin goal</div>
                    <div className="TP-total">
                      {profile?.current_treatment_plan?.skincare_goal}
                    </div>
                  </div>
                )}
                <div className="col-xs-12">
                  <div className="treat-text">Prescribed by</div>
                  <div className="TP-total">
                    {profile?.current_treatment_plan?.user &&
                      displayName(profile.current_treatment_plan.user)}
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="treat-text">
                    Total{" "}
                    <a
                      onClick={viewBreakDown}
                      title="View detailed breakdown of this amount"
                      className="help-icon sm-help"
                    >
                      ?
                    </a>
                  </div>
                  <div className="TP-total">
                    {numberFormat(startProTotal, "currency")}
                  </div>
                </div>
                {profile?.current_treatment_plan?.plan_type === "monthly" && (
                  <div className="col-xs-6">
                    <div className="treat-text">Per month</div>
                    <div className="TP-total">
                      {numberFormat(startProMonthly, "currency")}
                    </div>
                  </div>
                )}
                <div className="col-xs-12">
                  <div className="treat-text">Plan type</div>
                  <div className="TP-total">
                    {profile?.current_treatment_plan?.plan_type === "payg"
                      ? "Pay As You Go"
                      : "Monthly"}
                  </div>
                </div>
                {profile?.current_treatment_plan
                  ?.treatment_plan_subscription && (
                    <div className="col-xs-12">
                      <div className="treat-text">Plan started on</div>
                      <div className="TP-total">
                        {showFormattedDate(
                          profile.current_treatment_plan
                            .treatment_plan_subscription.started_on,
                          false,
                          "DD MMMM YYYY",
                        )}
                      </div>
                    </div>
                  )}
                {profile?.current_treatment_plan
                  ?.treatment_plan_subscription && (
                    <div className="col-xs-12 no-padding-right">
                      <div className="treat-text">Plan duration</div>
                      <div className="TP-total">
                        {profile.current_treatment_plan.duration_date}
                      </div>
                    </div>
                  )}
                {profile?.current_treatment_plan
                  ?.treatment_plan_subscription && (
                    <div className="col-xs-12 no-padding-right">
                      <div className="treat-text">Card in use</div>
                      <div className="TP-total pull-left">
                        {
                          profile.current_treatment_plan
                            .treatment_plan_subscription.card_number
                        }
                      </div>
                      <a
                        onClick={changePlanCard}
                        className="line-btn pull-right no-width"
                      >
                        Change
                      </a>
                    </div>
                  )}
                {profile?.current_treatment_plan?.clinic && (
                  <div className="col-xs-12">
                    <div className="treat-text">Clinic</div>
                    <div className="TP-total">
                      {profile.current_treatment_plan.clinic.clinic_name}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
