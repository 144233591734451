import { useMemo, useState } from "react";
import { useDebouncedState } from "../../../../utilities/hooks/useDebouncedState";

export const FILTERS = {
  all: "all",
  selected: "selected",
  unselected: "unselected",
};

const search = (res, category, term) => {
  if (typeof term === "string") {
    const preparedTerm = term.toLowerCase();
    const searchedServices = category.services.filter((s) =>
      s.name.toLowerCase().includes(preparedTerm),
    );
    if (searchedServices.length === 0) {
      return res;
    }
    return [
      ...res,
      {
        ...category,
        services: searchedServices,
      },
    ];
  }
  return res;
};

const filter = (res, category, filterValue, pickedIds) => {
  if (filterValue === FILTERS.selected || filterValue === FILTERS.unselected) {
    const nextServices = category.services.filter((s) => {
      if (filterValue === FILTERS.selected) {
        return pickedIds.includes(s.id);
      }
      return !pickedIds.includes(s.id);
    });
    if (nextServices.length > 0) {
      return [
        ...res,
        {
          ...category,
          services: nextServices,
        },
      ];
    } else {
      return res;
    }
  } else {
    return [...res, category];
  }
};

export function useModifiers({ servicesByCategory, pickedServiceIds }) {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState("");
  const [filterValue, setFilterValue] = useState(FILTERS.all);

  const modifiedData = useMemo(
    () =>
      servicesByCategory
        .reduce((r, c) => search(r, c, debouncedSearchTerm), [])
        .reduce((r, c) => filter(r, c, filterValue, pickedServiceIds), []),
    [servicesByCategory, debouncedSearchTerm, filterValue, pickedServiceIds],
  );

  return {
    modifiedData,
    search: {
      value: searchTerm,
      onChange: setSearchTerm,
    },
    filter: {
      value: filterValue,
      onChange: setFilterValue,
    },
  };
}
