import React, { useMemo } from "react";
import classes from "../scss/repeatMd.module.scss";
import { Select } from "../../../../../shared/Select/Select";

const ClinicRow = ({
  clinicName,
  clinicId,
  locations,
  values,
  setFieldValue,
}) => {
  const locationOptions = locations.map((location) => ({
    value: location.locationId,
    label: location.locationName,
  }));

  const selectedLocation = useMemo(
    () =>
      locationOptions?.find((option) => option.value === values[clinicId]) || {
        value: "",
        label: "No Set",
      },
    [values, locationOptions],
  );

  const availableLocationOptions = locationOptions.filter((location) => {
    if (
      !Object.values(values).includes(location.value) ||
      location.value === values[clinicId]
    ) {
      return location;
    }
  });

  return (
    <div className={classes.clinicRow}>
      <span className={classes.clinicName}>{clinicName}</span>
      <Select
        size="small"
        className={classes.locationSelect}
        options={[{ value: "", label: "No Set" }, ...availableLocationOptions]}
        value={selectedLocation}
        onChange={(option) => setFieldValue(clinicId, option.value)}
      />
    </div>
  );
};

export default ClinicRow;
