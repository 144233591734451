import React from "react";
import * as yup from "yup";
import { Modal } from "../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Button } from "../../../../shared/Button/Button";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { useSubmitFeedback } from "../hooks/useSubmitFeedback";
import styles from "../scss/betaProgram.module.scss";
import { Textarea } from "../../../../shared/Textarea/Textarea";
import { useFormik } from "formik";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../shared/InputError/InputError";
import { uiNotification } from "../../../../services/UINotificationService";

const FeedbackModal = ({ onClose, selectedFeedbackId }) => {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();

  const { isLoading, mutate } = useSubmitFeedback({
    onSuccess: (data) => {
      uiNotification.success(tSettings(data.data.message));
      onClose();
    },
    onError: (error) => {
      uiNotification.error(tSettings(error.data.message));
    },
  });

  const schema = yup.object({
    feedback: yup
      .string()
      .required(tSettings("betaProgram.feedbackModal.feedbackRequired"))
      .min(3, tSettings("betaProgram.feedbackModal.feedbackMinLength"))
      .max(1000, tSettings("betaProgram.feedbackModal.feedbackMaxLength")),
  });

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    validationSchema: schema,
    onSubmit: ({ feedback }) => {
      mutate({ id: selectedFeedbackId, feedback });
    },
    initialValues: {
      feedback: "",
    },
  });

  return (
    <Modal
      isOpen
      onClose={onClose}
      header={
        <Modal.Title>
          {tSettings("betaProgram.feedbackModal.title")}
        </Modal.Title>
      }
      footerClassName={styles.modalFooter}
      footerNoBorder
      footer={
        <>
          <Button variant="outlined" onClick={onClose} isDisabled={isLoading}>
            {tCommon("label.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            leftAdornment={
              isLoading ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
            isDisabled={isLoading}
          >
            {tCommon("label.submit")}
          </Button>
        </>
      }
    >
      <InputLabel>
        {tSettings("betaProgram.feedbackModal.feedbackLabel")}
      </InputLabel>
      <Textarea
        value={values.feedback}
        onChange={(e) => setFieldValue("feedback", e.target.value)}
        maxLength={1000}
      />
      <span className={styles.charsLength}>{values.feedback.length}/1000</span>
      {Boolean(errors.feedback) && <InputError>{errors.feedback}</InputError>}
    </Modal>
  );
};

export default FeedbackModal;
