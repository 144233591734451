import * as yup from "yup";
import { useMemo } from "react";
import { useExtendedFormik } from "../../../../hooks/useExtendedFormik";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useSalesGoalQuery } from "../../../../api/queries/useSalesGoalQuery";
import { getEditPathParams } from "../utils";
import { uiNotification } from "../../../../services/UINotificationService";
import { arrayFrom } from "../../../../utilities/general";
import { Maybe } from "../../../../utilities/fp";
import { useSalesGoalAvailableMonthsQuery } from "../../../../api/queries/useSalesGoalAvailableMonthsQuery";

var yearOptions = (() => {
  const startYear = new Date().getFullYear();
  const endYear = startYear + 12;

  return arrayFrom(endYear - startYear).map((i) => ({
    label: startYear + i,
    value: startYear + i,
  }));
})();

function useAvailableMonths({ clinicId, year, isNeeded }) {
  const { tSettings } = useAppTranslation.Settings();

  const availableMonths = useSalesGoalAvailableMonthsQuery(
    {
      clinicId,
      year,
    },
    {
      enabled: isNeeded,
      retry: false,
      onError: () => {
        uiNotification.error(
          tSettings("salesGoalMutate.error.fetchAvailableMonths"),
        );
      },
    },
  );

  const options = useMemo(() => {
    return (
      availableMonths.data?.map((m) => ({
        label: m.name,
        value: m.id,
        isDisabled: m.isDisabled,
      })) || []
    );
  }, [availableMonths.data]);

  return {
    options,
    isLoading: availableMonths.isFetching,
  };
}

function useInitialValues() {
  const { clinicId, goalId } = getEditPathParams();
  const { tSettings } = useAppTranslation.Settings();

  const { data, isLoading } = useSalesGoalQuery(
    {
      clinicId,
      goalId,
    },
    {
      onError: () => {
        uiNotification.error(tSettings("salesGoalMutate.error.fetch"));
      },
    },
  );

  const clinicOptions = useMemo(() => {
    return (
      data?.clinics?.map((c) => ({
        label: c.name,
        value: c.id,
      })) || []
    );
  }, [data?.clinics]);

  const monthOptions = useMemo(() => {
    return (
      data?.months?.map((m) => ({
        label: m.name,
        value: m.id,
        isDisabled: m.isDisabled,
      })) || []
    );
  }, [data?.months]);

  const values = useMemo(
    () => ({
      clinic: Maybe.of(data?.clinicId)
        .map((id) => clinicOptions.find((o) => o.value === id))
        .orElse(null)
        .value(),

      year: Maybe.of(data?.year)
        .map((year) => yearOptions.find((o) => o.value === year))
        .orElse(null)
        .value(),

      month: Maybe.of(data?.month)
        .map((id) => monthOptions.find((o) => o.value === id))
        .orElse(null)
        .value(),

      goal: data?.goal || "",
    }),
    [data?.clinicId, data?.year, data?.month, data?.goal, clinicOptions],
  );

  return {
    data: values,
    isLoading,
    clinicOptions,
    monthOptions,
  };
}

export function useForm(submitter) {
  const { tSettings } = useAppTranslation.Settings();
  const initialValues = useInitialValues();

  const optionSchema = yup
    .object({
      label: yup.string().required(),
      value: yup.mixed().required(),
    })
    .nullable();

  const schema = yup.object({
    clinic: optionSchema.required(
      tSettings("salesGoalMutate.error.clinicRequired"),
    ),
    year: optionSchema.required(
      tSettings("salesGoalMutate.error.yearRequired"),
    ),
    month: optionSchema.required(
      tSettings("salesGoalMutate.error.monthRequired"),
    ),
    goal: yup
      .number()
      .typeError(tSettings("salesGoalMutate.error.goalNumber"))
      .required(tSettings("salesGoalMutate.error.goalRequired")),
  });

  const {
    values,
    getError,
    handleSubmit,
    changeField,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    dirty,
  } = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: submitter,
    initialValues: initialValues.data,
  });

  const isAvailableMonthsNeeded = Boolean(
    values.clinic?.value &&
      values.year?.value &&
      (touched.clinic || touched.year),
  );

  const availableMonths = useAvailableMonths({
    clinicId: values.clinic?.value,
    year: values.year?.value,
    isNeeded: isAvailableMonthsNeeded,
  });

  return {
    dirty,
    isLoading: initialValues.isLoading,
    clinic: {
      value: values.clinic,
      onChange: (next) => changeField("clinic", next),
      options: initialValues.clinicOptions,
      getError: () => getError("clinic"),
      isLoading: initialValues.isLoading,
    },
    year: {
      value: values.year,
      onChange: (next) => {
        setFieldValue("month", null);
        changeField("year", next);
      },
      options: yearOptions,
      getError: () => getError("year"),
    },
    month: {
      value: values.month,
      onChange: (next) => changeField("month", next),
      options: isAvailableMonthsNeeded
        ? availableMonths.options
        : initialValues.monthOptions,
      getError: () => getError("month"),
      isLoading: initialValues.isLoading || availableMonths.isLoading,
    },
    goal: {
      value: values.goal,
      onChange: handleChange,
      onBlur: handleBlur,
      getError: () => getError("goal"),
    },
    submit: handleSubmit,
  };
}
