import React, { Component } from "react";
import {bindActionCreators} from "redux";
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import {connect} from "react-redux";
import {createCherryPatient} from "../../Actions/Cherry/cherryActions";
import { uiNotification } from "../../services/UINotificationService";

class CherryEnrollModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: (props.phoneNumber && isValidPhoneNumber(props.phoneNumber, { defaultCountry: 'US', extract: false})) ? parsePhoneNumber(props.phoneNumber, {
        defaultCountry: 'US',
        extract: false
      }).nationalNumber : ""
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.patientCreated && this.props.patientCreated) {
      uiNotification.success(this.props.message);
      this.props.onPatientCreated(this.props.cherryPatientId);
    }

    if (!prevProps.error && this.props.error) {
      uiNotification.error(this.props.error);
    }
  }

  setPhoneNumber(phoneNumber) {
    this.setState({ phoneNumber })
  }

  createCherryPatient = (e) => {
    e.preventDefault();
    if(isValidPhoneNumber(this.state.phoneNumber, { defaultCountry: 'US', extract: false})) {
      this.props.createCherryPatient(this.props.patientId, parsePhoneNumber(this.state.phoneNumber, {
        defaultCountry: 'US',
        extract: false
      }).nationalNumber);
    }
  }

  render() {
    return <div className='modalOverlay'>
      <form onSubmit={this.createCherryPatient}>
        <div className='small-popup-outer displayBlock'>
          <div className="small-popup-header">
            <div className="popup-name">Enroll patient</div>
            <a onClick={this.props.onClose} className="small-cross">×</a>
          </div>
          <div className="small-popup-content">
            <div className="juvly-container no-padding-bottom">

              <div className="prescription-content">
                <div className="doc-section">
                  <div className="col-xs-12 no-margin">

                    <div className="row">
                      <div className="col-sm-6 col-xs-12">
                        <div className="setting-field-outer">
                          <div className="new-field-label">Phone number <span className="setting-require">*</span></div>
                          <div className="setting-input-outer">
                            <input
                              name="phoneNumber"
                              className="setting-input-box"
                              placeholder="Enter Phone Number"
                              type="text"
                              autoComplete="off"
                              autoFocus
                              value={this.state.phoneNumber}
                              disabled={this.props.isLoading}
                              onChange={(e) => this.setPhoneNumber(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-static">
            <input
              type="submit"
              className="new-blue-btn pull-right"
              disabled={this.props.isLoading}
              value={this.props.isLoading ? "Loading" : "Enroll"} />
          </div>
        </div>
      </form>
    </div>
  }
}

function mapStateToProps(state) {
  const createPatientState = state.CherryReducer.createCherryPatient;
  return  {
    isLoading: createPatientState.status === "loading",
    patientCreated: createPatientState.status === "success",
    cherryPatientId: createPatientState.status === "success" ? createPatientState.data.data.cherryPatient.id : null,
    error: createPatientState.status === "error" ? createPatientState.data.message : null,
    message: createPatientState.status === "success" ? createPatientState.data.message : null
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createCherryPatient: createCherryPatient
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps) (CherryEnrollModal);
