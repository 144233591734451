/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AppointmentConfigNavbar from "./AppointmentConfigNavbar.js";
import EquipmentSchedule from "./EquipmentSchedule/EquipmentSchedule.js";
import ResourceSchedule from "./ResourceSchedule/ResourceSchedule.js";
import { withQueryParamsState } from "../../utilities/hooks/useQueryParamsState.js";

const TAB_TYPES = {
  equipment: "equipment",
  resource: "resource",
};

class OtherResources extends Component {
  constructor(props) {
    super(props);

    const langData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      mode: null,
      langData: langData,
    };
  }

  componentDidMount() {
    const tab = this.props.queryParamsState?.t;
    if (!Object.values(TAB_TYPES).includes(tab)) {
      this.changeTab(TAB_TYPES.equipment);
    }
  }

  changeTab = (type) => {
    this.setState({ mode: null });
    this.props.setQueryParamsState({
      t: type,
    });
  };

  addEquipment = (id) => {
    import("./EquipmentSchedule/CreateEditEquipmentSchedule.js").then(
      (component) => {
        let returnState = {};
        returnState.CreateEditEquipmentSchedule = component.default;
        returnState.showCreateEditEquipment = true;
        returnState.showCreateEditResource = false;
        returnState.equipmentScheduleId = id ? id : undefined;
        returnState.mode = "CreateEditEquipmentSchedule";
        this.setState(returnState);
      },
    );
  };

  addResource = (mode, id) => {
    import("./ResourceSchedule/CreateEditResourceSchedule.js").then(
      (component) => {
        let returnState = {};
        returnState.CreateEditResourceSchedule = component.default;
        returnState.showCreateEditEquipment = false;
        returnState.showCreateEditResource = true;
        returnState.resourceScheduleId = id ? id : undefined;
        returnState.mode = "CreateEditResourceSchedule";
        this.setState(returnState);
      },
    );
  };

  render() {
    const {
      CreateEditEquipmentSchedule,
      CreateEditResourceSchedule,
      resourceScheduleId,
      equipmentScheduleId,
    } = this.state;

    const tab = this.props.queryParamsState?.t;

    return (
      <div id="content">
        <div className="container-fluid content">
          <AppointmentConfigNavbar activeResources={true} />
          <div className="setting-setion full-width">
            <div className="">
              <ul
                className={
                  tab == TAB_TYPES.equipment || tab == TAB_TYPES.resource
                    ? "appointment-tabs m-b-0 table-tabs"
                    : "appointment-tabs m-b-0 table-tabs no-display"
                }
              >
                <li>
                  <a
                    className={tab == TAB_TYPES.equipment ? "active" : ""}
                    onClick={() => this.changeTab(TAB_TYPES.equipment)}
                  >
                    {this.state.langData["appointments"]["app-equipment"]}
                  </a>
                </li>
                <li>
                  <a
                    className={tab == TAB_TYPES.resource ? "active" : ""}
                    onClick={() => this.changeTab(TAB_TYPES.resource)}
                  >
                    {this.state.langData["appointments"]["app-resources"]}
                  </a>
                </li>
              </ul>
              {!this.state.mode && tab == TAB_TYPES.equipment && (
                <EquipmentSchedule addEquipment={this.addEquipment} />
              )}
              {!this.state.mode && tab == TAB_TYPES.resource && (
                <ResourceSchedule addResource={this.addResource} />
              )}
              {this.state.mode == "CreateEditEquipmentSchedule" &&
                CreateEditEquipmentSchedule && (
                  <CreateEditEquipmentSchedule
                    openListSchedule={this.changeTab}
                    equipmentScheduleId={equipmentScheduleId}
                  />
                )}
              {this.state.mode == "CreateEditResourceSchedule" &&
                CreateEditResourceSchedule && (
                  <CreateEditResourceSchedule
                    openListSchedule={this.changeTab}
                    resourceScheduleId={resourceScheduleId}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  null,
  mapDispatchToProps,
)(
  withRouter(
    withQueryParamsState({
      t: TAB_TYPES.equipment,
    })(OtherResources),
  ),
);
