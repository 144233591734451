import React, { useState } from "react";
import { DeleteIcon } from "../../../../assets/Icons/DeleteIcon";
import { Button } from "../../../../shared/Button/Button";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useDeleteTraceabilityMutation } from "../../../../api/mutations/useDeleteTraceabilityMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

export const DeleteTraceability = ({ id, onSuccess }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { mutate, isLoading } = useDeleteTraceabilityMutation({
    onError: () => {
      uiNotification.error("Failed to delete traceability");
    },
    onSuccess: () => {
      onSuccess?.();
      uiNotification.success("Traceability deleted successfully");
    },
  });

  return (
    <>
      <Button
        size="tiny"
        variant="text"
        color="error"
        onClick={() => setIsConfirmOpen(true)}
        isDisabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size="tiny" color="red" />
        ) : (
          <DeleteIcon width="15px" />
        )}
      </Button>
      {isConfirmOpen && (
        <ConfirmModal
          isOpen
          onClose={() => setIsConfirmOpen(false)}
          onCancel={() => setIsConfirmOpen(false)}
          onConfirm={() => {
            mutate({ id });
            setIsConfirmOpen(false);
          }}
        >
          Are you sure you want to delete this traceability?
        </ConfirmModal>
      )}
    </>
  );
};
