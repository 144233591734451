import mammoth from "mammoth";
import { FILE_EXTENSIONS } from "../../consts/general";
import { composeParseFn, composeParseResult } from "./utils";
import { fileUtil } from "../../utilities/file";

const PARSE_METHODS = {
  extractRawText: "extractRawText",
  convertToHtml: "convertToHtml",
};

export function useDocxParser() {
  const parse = async (file, { method, extra }) => {
    try {
      if (!file) {
        throw new Error("File is empty");
      }
      if (fileUtil.getExtensionFromName(file.name) !== FILE_EXTENSIONS.docx) {
        throw new Error("File is not .docx");
      }
      const readerResult = await fileUtil.read(file);
      const arrayBuffer = new Uint8Array(readerResult);
      const content = await mammoth[method]({ arrayBuffer });
      return composeParseResult({
        content: content.value,
        extra,
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const parseToHtml = composeParseFn((file, extra) => {
    return parse(file, {
      method: PARSE_METHODS.convertToHtml,
      extra,
    });
  });

  const parseToText = composeParseFn((file, extra) => {
    return parse(file, {
      method: PARSE_METHODS.extractRawText,
      extra,
    });
  });

  return {
    parseToHtml,
    parseToText,
  };
}
