import React from "react";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { useWorkspacesQuery } from "../../../api/queries/useWorkspacesQuery";
import { authService } from "../../../services/AuthService";

export const withWorkspaces = (Component) => {
  return function Wrapper(props) {
    const wpuId = authService.getLoginData().wpuId;
    const { data: currentUser } = useCurrentUserQuery();
    const { data: workspaces } = useWorkspacesQuery({
      payload: {
        wpuId,
      },
    });

    const preparedWorkspaces = (() => {
      if (workspaces && currentUser) {
        const isUserWorkspaceInWorkspacesList = Boolean(
          workspaces.find((w) => w.accountId === currentUser.account?.id),
        );
        if (!isUserWorkspaceInWorkspacesList && currentUser.account) {
          return [
            {
              id: currentUser.account.id,
              accountId: currentUser.account.id,
              name: currentUser.account.name,
              status: currentUser.account.status,
              imageUrl: currentUser.account.imageUrl,
            },
            ...workspaces,
          ];
        }
        return workspaces;
      }
      return [];
    })();

    return (
      <Component
        {...props}
        workspaces={preparedWorkspaces}
        activeAccount={currentUser?.account ? currentUser.account : {}}
      />
    );
  };
};
