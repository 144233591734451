import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { Button } from "../../../../shared/Button/Button";
import { getCurrencySymbol } from "../../../../Utils/services";
import classes from "../../sass/Event.module.scss";

const ConfirmServicesDepositModal = ({
  services,
  handleUpdateServices,
  handleClose,
}) => {
  const [selectedServices, setSelectedServices] = useState(
    services.map((service) => ({ ...service, isDepositConfirmed: true })),
  );

  const setServiceDepositChoice = (serviceId, depositChoice) => {
    const currentServicesList = [...selectedServices];

    const selectedServiceIndex = currentServicesList.findIndex(
      (serviceDetails) => serviceDetails.id === serviceId,
    );

    currentServicesList[selectedServiceIndex].isDepositConfirmed =
      depositChoice;

    setSelectedServices(currentServicesList);
  };

  const saveDepositChoices = () => {
    handleUpdateServices(selectedServices);
    handleClose();
  };

  const modalHeader = useMemo(
    () =>
      selectedServices.length === 1
        ? "This service requires a minimum deposit"
        : "These services require a minimum deposit",
    [selectedServices.length],
  );

  return (
    <ConfirmModal
      isOpen
      onConfirm={saveDepositChoices}
      onClose={saveDepositChoices}
      confirmTitle="Save"
    >
      <div className={classes.waitlistServicesConfirmModal}>
        <div className={classes.modalHeading}>{modalHeader}</div>
        <table>
          {selectedServices
            .filter((serviceDetail) => Boolean(serviceDetail.price))
            .map((serviceDetails) => {
              const { name, price, id, isDepositConfirmed } = serviceDetails;
              return (
                <tr className="flex" key={id}>
                  <td className="grow">{name}</td>
                  <td className="priceColumn">
                    {getCurrencySymbol()}&nbsp;{price}
                  </td>
                  <td className="">
                    <Button
                      size="small"
                      onClick={() => setServiceDepositChoice(id, true)}
                      variant={isDepositConfirmed ? "contained" : "outlined"}
                    >
                      Confirm
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="small"
                      variant={isDepositConfirmed ? "outlined" : "contained"}
                      onClick={() => setServiceDepositChoice(id, false)}
                    >
                      Waive
                    </Button>
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </ConfirmModal>
  );
};

ConfirmServicesDepositModal.propTypes = {
  services: PropTypes.array,
  handleUpdateServices: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ConfirmServicesDepositModal;
