import { useSmsApptNotificationsMutation } from "../../../../../../api/smsApptNotifications/useSmsApptNotificationsMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { phoneUtil } from "../../../../../../utilities/phone";

export function useSubmit() {
  const { tSettings } = useAppTranslation.Settings();

  const mutation = useSmsApptNotificationsMutation({
    onSuccess: () => {
      uiNotification.success(tSettings("smsApptNotifications.success.update"));
    },
    onError: () => {
      uiNotification.error(tSettings("smsApptNotifications.error.update"));
    },
  });

  const initiate = (formValues) => {
    return mutation.mutateAsync({
      notification_phone: phoneUtil.compose(formValues.phone),
      appt_canceled: formValues.cancelling,
      appt_rescheduled: formValues.rescheduling,
      appt_booked: formValues.booking,
    });
  };

  const disable = () => {
    return initiate({
      phone: "",
      cancelling: false,
      rescheduling: false,
      booking: false,
    });
  };

  return {
    initiate,
    disable,
    isLoading: mutation.isLoading,
  };
}
