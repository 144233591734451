import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./CreditCard.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { usePermission } from "../../../../../../hooks/usePermission";
import {
  PERMISSIONS,
  USER_PAYMENT_SYSTEMS,
} from "../../../../../../consts/api";
import { useDeleteCC } from "../../hooks/useDeleteCC";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { useCreditCards } from "../../hooks/useCreditCards";
import AddEditCardModal from "../../shared/AddEditCardModal/AddEditCardModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getSetupIntent,
  getClientCardDataStripe,
} from "../../../../../../Actions/Stripe/StripeActions";
import { uiNotification } from "../../../../../../services/UINotificationService";
import Loader from "../../../../../Common/Loader";
import { http } from "../../../../../../services/HttpService";

const CreditCard = ({
  id,
  cardNumber,
  clientId,
  getSetupIntent,
  getClientCardDataStripe,
  cardList,
  clientClinicId,
}) => {
  const { tCommon } = useAppTranslation.Common();
  const cc = useCreditCards(clientId, cardList);
  const deleteCC = useDeleteCC({ onSuccess: cc.refetch });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const deleteCcPermission = usePermission(
    PERMISSIONS.patientManagement.deleteCC,
  );

  const openEditModal = () => {
    setIsEditModalOpen(true);
    if (cc.paymentSystem === USER_PAYMENT_SYSTEMS.stripe) {
      getSetupIntent({ patient_id: clientId, clinic_id: clientClinicId });
    }
  };

  const removeStripeCard = async (id) => {
    setShowLoader(true);
    await http
      .post("stripe/detach-payment-method", {
        patient_id: clientId,
        payment_method_id: id,
      })
      .then(() => {
        uiNotification.success("Patient's CC Deleted Successfully");
      })
      .catch((error) => {
        if (error.response) {
          uiNotification.error(error.response.data.message);
        }
      })
      .finally(() => {
        getClientCardDataStripe({ patient_id: clientId });
      });
    setShowLoader(false);
  };

  const isNotUpdatable = () => {
    const notUpdatableMethods = [
      "klarna",
      "link",
    ];
    return notUpdatableMethods.includes(cardNumber.toLowerCase());
  }

  const isDeleteCcPermitted = deleteCcPermission.loaded && deleteCcPermission.permitted;

  return (
    <div className={classes.root}>
      <div title={cardNumber} className={classes.number}>
        {cardNumber}
      </div>
      {cc.isPosEnabled && (
        <div className={classes.buttons}>
          {!isNotUpdatable() && (
            <Button
              variant="text"
              size="tiny"
              onClick={() => openEditModal()}
              isDisabled={deleteCC.isLoading}
            >
              {tCommon("label.update")}
            </Button>
          )}
          {isDeleteCcPermitted && (
            <Button
              variant="text"
              size="tiny"
              color="error"
              isDisabled={deleteCC.isLoading}
              onClick={() =>
                cc.paymentSystem === USER_PAYMENT_SYSTEMS.stripe
                  ? removeStripeCard(id)
                  : deleteCC.initiate(id)
              }
              leftAdornment={
                deleteCC.isLoading ? (
                  <CircularProgress size="tiny" color="red" />
                ) : undefined
              }
            >
              {tCommon("label.delete")}
            </Button>
          )}
        </div>
      )}
      {isEditModalOpen && (
        <AddEditCardModal
          cardId={id}
          clientId={clientId}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          removeStripeCard={() => removeStripeCard(id)}
        />
      )}
      <Loader showLoader={showLoader} isFullWidth={true} />
    </div>
  );
};

CreditCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  cardNumber: PropTypes.string.isRequired,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

const mapStateToProps = (state) => {
  return {
    cardList: state.StripeReducer.cardList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSetupIntent,
      getClientCardDataStripe,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
