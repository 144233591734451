import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import classes from "./Chat.module.scss";
import { extractDate, sortMessagesByDate } from "./Chat.utils";
import { messagePropType } from "./Chat.propTypes";
import { Message } from "./shared/Message/Message";
import { DEFAULT_TIME_FORMAT, PREVIEW_DATE_FORMAT } from "../../consts/general";

export function Chat({
  messages,
  className,
  header,
  footer,
  dateFormat,
  timeFormat,
}) {
  const messagesRef = useRef(null);

  const preparedMessages = useMemo(() => {
    return sortMessagesByDate(messages);
  }, [messages]);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollToBottom();
    }
  }, [messages]);

  return (
    <div className={cx(classes.root, className)}>
      {header}
      <Scrollbars ref={messagesRef} className={classes.messages}>
        {preparedMessages.map((message, index) => {
          const prevDate = extractDate(preparedMessages?.[index - 1]?.date);
          const date = extractDate(message.date);
          const isDateVisible = prevDate !== date;

          return (
            <div key={message.id}>
              {isDateVisible && (
                <div className={classes.dateDivider}>
                  {moment(date).format(dateFormat)}
                </div>
              )}
              <Message
                type={message.type}
                time={moment(message.date).format(timeFormat)}
                author={message.author}
              >
                {message.body}
              </Message>
            </div>
          );
        })}
      </Scrollbars>
      {footer}
    </div>
  );
}

Chat.propTypes = {
  messages: PropTypes.arrayOf(messagePropType),
  className: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
};

Chat.defaultProps = {
  messages: [],
  className: undefined,
  header: null,
  footer: null,
  dateFormat: PREVIEW_DATE_FORMAT,
  timeFormat: DEFAULT_TIME_FORMAT,
};
