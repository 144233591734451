import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Input } from "../../../../../shared/Input/Input";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../../shared/InputError/InputError";
import classes from "./SendReceipts.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { history } from "../../../../../history";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { delay } from "../../../../../utilities/general";

export function SendReceipts() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice } = useInvoice();
  const { sendReceipt } = useInvoiceActions();

  const schema = yup.object({
    email: yup
      .string()
      .email(tCommon("formError.emailInvalid"))
      .required(tCommon("formError.emailRequired")),
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: ({ email }) => {
      sendReceipt.initiate({ patientEmail: email }).then(() => {
        delay(1200).then(history.goBack);
      });
    },
    initialValues: {
      email: invoice?.patient?.email || "",
    },
  });

  const hasError = (field) => {
    return Boolean(touched?.[field] && errors?.[field]);
  };

  return (
    <LabeledBox
      label={tSales("checkoutInvoice.stageSuccess.sendReceipts.title")}
      contentClassName={classes.root}
    >
      <div>
        <InputLabel>{tCommon("label.patientEmail")}</InputLabel>
        <Input
          name="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={tCommon("formPlaceholder.email")}
          isError={hasError("email")}
          className={classes.email}
        />
        {hasError("email") && <InputError>{errors.email}</InputError>}
      </div>
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          onClick={() => history.goBack()}
          isDisabled={sendReceipt.isLoading}
        >
          {tSales("checkoutInvoice.stageSuccess.sendReceipts.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          isDisabled={!isValid || sendReceipt.isLoading}
          leftAdornment={
            sendReceipt.isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tSales("checkoutInvoice.stageSuccess.sendReceipts.submit")}
        </Button>
      </div>
    </LabeledBox>
  );
}
