import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  accountId: yup.number().required(),
});

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    accountId: yup.number().required(),
    clinicId: yup.number().required(),
    isDefault: yup.bool().required(),
    model: yup.string().required(),
    payWorksStatus: yup.string().nullable(),
    identifier: yup.string().nullable(),
    serialNumber: yup.string().nullable(),
    status: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  const readers = unwrapOr(() => res.data.data, []);
  return readers.map((r) => ({
    id: r.id,
    accountId: r.account_id,
    clinicId: r.clinic_id,
    isDefault: Boolean(r.is_default),
    model: r.model,
    payWorksStatus: r.payworks_status,
    identifier: r.reader_identifier,
    serialNumber: r.serial_number,
    status: r.status,
  }));
};

function createCardReadersQueryKey({ accountId }) {
  return [QUERY_KEYS.cardReaders, accountId];
}

export function useCardReadersQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useQuery({
    queryKey: createCardReadersQueryKey({ accountId: req.accountId }),
    queryFn: async () => {
      const res = await http.get(HTTP_ENDPOINTS.getCardReaders(req.accountId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    ...options,
  });
}
