import { usePatientConsentSignMutation } from "../../../api/consents/patientConsents/usePatientConsentSignMutation";
import { useUploadSignatureMutation } from "../../../api/mutations/useUploadSignatureMutation";
import { usePatientConsentSignWitnessMutation } from "../../../api/consents/patientConsents/usePatientConsentSignWitnessMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";

export function useSubmit({ consent, onSuccess }) {
  const { tCommon } = useAppTranslation.Common();

  const signConsent = usePatientConsentSignMutation();
  const signWitnessConsent = usePatientConsentSignWitnessMutation();
  const uploadSignature = useUploadSignatureMutation();

  const uploadSignatureFile = (signature) => {
    return uploadSignature.mutateAsync({
      imageData: signature,
      uploadType: "patient_signatures",
    });
  };

  const signOnlyWitness = async (formValues) => {
    const uploadedWitnessSignature = await uploadSignatureFile(
      formValues.witnessSignature,
    );
    await signWitnessConsent.mutateAsync({
      patientConsentId: consent.id,
      witnessName: formValues.witnessName,
      witnessSignature: uploadedWitnessSignature.fileName,
    });
  };

  const signOnlyPatient = async (formValues) => {
    const uploadedPatientSignature = await uploadSignatureFile(
      formValues.patientSignature,
    );
    await signConsent.mutateAsync({
      patientConsentId: consent.id,
      patientName: formValues.patientName,
      patientSignature: uploadedPatientSignature.fileName,
    });
  };

  const signBoth = async (formValues) => {
    const uploadedPatientSignature = await uploadSignatureFile(
      formValues.patientSignature,
    );

    let witnessData = {};

    if (formValues.witnessSignature) {
      const uploadedWitnessSignature = await uploadSignatureFile(
        formValues.witnessSignature,
      );
      witnessData.witnessSignature = uploadedWitnessSignature.fileName;
      witnessData.witnessName = formValues.witnessName;
    }

    await signConsent.mutateAsync({
      patientConsentId: consent.id,
      patientName: formValues.patientName,
      patientSignature: uploadedPatientSignature.fileName,
      ...witnessData,
    });
  };

  const initiate = async (formValues) => {
    const isOnlyWitness =
      !consent?.witnessSignatureUrl && !!consent?.patientSignatureUrl;

    const isOnlyPatient =
      !consent?.patientSignatureUrl && !!consent?.witnessSignatureUrl;

    const isBoth =
      !consent?.witnessSignatureUrl && !consent?.patientSignatureUrl;

    try {
      if (isOnlyWitness) {
        await signOnlyWitness(formValues);
      } else if (isOnlyPatient) {
        await signOnlyPatient(formValues);
      } else if (isBoth) {
        await signBoth(formValues);
      }
      onSuccess?.();
      uiNotification.success(tCommon("signPatientConsent.success.fallback"));
    } catch (e) {
      const error = extractApiError(e);

      if (
        ["already_signed_by_patient", "need_patient_signature"].includes(error)
      ) {
        uiNotification.error(tCommon(`signPatientConsent.apiError.${error}`));
      } else {
        uiNotification.error(
          tCommon([
            `signPatientConsent.apiError.${error}`,
            "signPatientConsent.error.fallback",
          ]),
        );
      }
    }
  };

  return {
    initiate,
    isLoading:
      signConsent.isLoading ||
      signWitnessConsent.isLoading ||
      uploadSignature.isLoading,
  };
}
