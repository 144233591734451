import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Dropdown.module.scss";
import { svg } from "../../assets/svg";

export function Dropdown({
  isOpenInitial,
  fullWidth,
  label,
  leftAdornment,
  rightAdornment,
  className,
  contentClassName,
  labelClassName,
  leftAdornmentClassName,
  rightAdornmentClassName,
  children,
  headerInnerClassName,
}) {
  const [isOpen, setIsOpen] = useState(isOpenInitial);

  const rootClasses = cx(
    classes.root,
    {
      [classes.rootFullWidth]: fullWidth,
    },
    className,
  );

  return (
    <div className={rootClasses}>
      <div className={classes.header}>
        <div className={cx(classes.headerInner, headerInnerClassName)}>
          {leftAdornment && (
            <div className={leftAdornmentClassName}>{leftAdornment}</div>
          )}
          <button
            type="button"
            className={cx(classes.label, labelClassName)}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {label}
            <img
              src={svg.chevronDown}
              alt=""
              className={cx({
                [classes.chevronOpen]: isOpen,
              })}
            />
          </button>
        </div>
        {rightAdornment && (
          <div className={rightAdornmentClassName}>{rightAdornment}</div>
        )}
      </div>
      {isOpen && (
        <div className={cx(classes.content, contentClassName)}>{children}</div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  isOpenInitial: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.node.isRequired,
  leftAdornment: PropTypes.node,
  rightAdornment: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  headerInnerClassName: PropTypes.string,
  leftAdornmentClassName: PropTypes.string,
  rightAdornmentClassName: PropTypes.string,
};

Dropdown.defaultProps = {
  isOpenInitial: false,
  fullWidth: false,
  leftAdornment: undefined,
  rightAdornment: undefined,
  className: undefined,
  contentClassName: undefined,
  labelClassName: undefined,
  headerInnerClassName: undefined,
};
