import React from "react";

const GfeLineIcon = ({ width, height, color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "50px"}
      height={height || "50px"}
      viewBox="0 0 251.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      {" "}
      <g
        transform="translate(0.000000,300.000000) scale(0.050000,-0.050000)"
        fill={color || "#000000"}
        stroke="none"
      >
        {" "}
        <path d="M1449 5731 c-21 -16 -52 -30 -69 -31 -55 -1 -130 -139 -130 -238 l0 -90 -324 -1 c-231 -1 -331 -8 -349 -26 -14 -14 -42 -25 -62 -25 -21 0 -51 -13 -66 -28 -16 -16 -50 -46 -76 -66 -130 -103 -123 45 -123 -2467 l0 -2271 97 -113 c96 -113 123 -130 241 -150 135 -24 3620 -27 3702 -4 149 43 246 98 269 155 12 29 38 81 57 114 52 92 63 4427 11 4568 -46 125 -112 211 -186 241 -33 14 -61 34 -61 43 0 22 -14 23 -440 26 l-350 3 6 90 c5 65 -4 102 -30 133 -20 23 -53 65 -73 93 -46 63 54 57 -1054 66 -818 7 -956 4 -990 -22z m1871 -631 l0 -380 -892 5 -891 5 0 360 c0 198 1 367 2 375 0 8 401 15 891 15 l890 0 0 -380z m-2089 -21 c7 -12 15 -121 17 -242 l4 -220 88 -83 87 -84 983 0 983 0 93 70 c110 84 121 120 111 378 l-7 192 322 0 c361 0 416 -14 436 -115 16 -80 17 -4360 1 -4402 -37 -94 -19 -93 -1902 -93 -1828 0 -1793 -1 -1892 78 -60 48 -54 4360 6 4444 23 32 59 58 80 58 22 0 39 9 39 20 0 28 533 27 551 -1z" />{" "}
        <path d="M1049 4243 c-131 -24 -158 -198 -39 -243 31 -12 599 -20 1465 -20 l1413 -1 47 55 c30 35 43 70 36 95 -6 23 -11 47 -11 54 -1 49 -2668 104 -2911 60z" />{" "}
        <path d="M1017 3846 c-104 -44 -112 -170 -15 -221 37 -19 381 -24 1471 -25 l1424 0 41 66 c44 72 38 141 -15 168 -58 28 -2840 40 -2906 12z" />{" "}
        <path d="M961 3417 c-39 -64 -22 -149 37 -186 10 -6 664 -11 1454 -12 l1435 -1 46 56 c59 73 58 88 -9 152 l-56 54 -1434 0 -1435 0 -38 -63z" />{" "}
        <path d="M966 3035 c-34 -58 -36 -73 -12 -130 l26 -65 1425 -2 c1620 -3 1470 -8 1517 49 50 59 47 129 -7 174 -43 36 -106 38 -1478 38 l-1433 1 -38 -65z" />{" "}
        <path d="M2415 2725 l-1414 -5 -31 -59 c-36 -71 -39 -144 -6 -177 18 -18 374 -24 1479 -24 l1455 0 31 44 c53 76 21 172 -69 213 -16 7 -667 11 -1445 8z" />{" "}
        <path d="M1010 2302 c-70 -41 -94 -157 -43 -200 31 -25 2933 -36 2974 -11 25 15 25 169 0 169 -10 0 -24 18 -31 39 -18 59 -2801 61 -2900 3z" />{" "}
        <path d="M1005 1920 c-79 -48 -94 -163 -25 -200 42 -22 2925 -31 2961 -9 24 15 25 169 1 169 -9 0 -22 18 -28 39 -16 63 -2807 64 -2909 1z" />{" "}
        <path d="M1005 1540 c-76 -46 -96 -184 -31 -209 45 -17 2908 -17 2953 0 65 25 44 163 -32 209 -102 62 -2788 62 -2890 0z" />{" "}
        <path d="M1026 1176 c-98 -26 -123 -169 -41 -237 68 -56 2814 -60 2913 -4 82 46 90 166 14 215 -51 34 -2766 58 -2886 26z" />{" "}
      </g>{" "}
    </svg>
  );
};

export default GfeLineIcon;
