import moment from "moment";
import { useState } from "react";
import { useHeaderActionsContext } from "../components/HeaderActionsProvider";

export const useHeaderActions = () => {
  const { selectedOption, setSelectedOption, period, setPeriod } =
    useHeaderActionsContext();

  const [exportType, setExportType] = useState(null);

  return {
    selectedClinic: {
      value: selectedOption,
      update: setSelectedOption,
    },
    period: {
      value: {
        fromDate: period.fromDate,
        toDate: period.toDate,
      },
      update: setPeriod,
    },
    apiPeriod: {
      fromDate: moment(period.fromDate).format("YYYY-MM-DD"),
      toDate: moment(period.toDate).format("YYYY-MM-DD"),
    },
    exportType: {
      value: exportType,
      update: setExportType,
    },
  };
};
