import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Empty.module.scss";
import { useAppTranslation } from "../../i18n/useAppTranslation";

const POSITION = {
  center: "center",
  left: "left",
};

const SIZE = {
  big: "big",
  medium: "medium",
  small: "small",
};

const COLOR = {
  gray2: "gray2",
  gray1: "gray1",
};

export function Empty({ children, className, position, size, color }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.pCenter]: position === POSITION.center,
          [classes.pLeft]: position === POSITION.left,
          [classes.sBig]: size === SIZE.big,
          [classes.sMedium]: size === SIZE.medium,
          [classes.sSmall]: size === SIZE.small,
          [classes.cGray2]: color === COLOR.gray2,
          [classes.cGray1]: color === COLOR.gray1,
        },
        className,
      )}
    >
      {children || tCommon("info.nothingFound")}
    </div>
  );
}

Empty.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.oneOf(Object.values(POSITION)),
  size: PropTypes.oneOf(Object.values(SIZE)),
  color: PropTypes.oneOf(Object.values(COLOR)),
};

Empty.defaultProps = {
  children: null,
  className: undefined,
  position: POSITION.left,
  size: SIZE.medium,
  color: undefined,
};
