import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "../sass/Workspace.module.scss";
import { svg } from "../../../assets/svg";
import { Button } from "../../../shared/Button/Button";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { tCommon, useAppTranslation } from "../../../i18n/useAppTranslation";
import { WORKSPACE_STATUS } from "../../../consts/api";
import { authService } from "../../../services/AuthService";
import { useSwitchWorkspace } from "../../../hooks/useSwitchWorkspace";

const labelByStatus = {
  [WORKSPACE_STATUS.hold]: tCommon("workspaceStatus.hold"),
  [WORKSPACE_STATUS.active]: tCommon("workspaceStatus.active"),
  [WORKSPACE_STATUS.inactive]: tCommon("workspaceStatus.inactive"),
  [WORKSPACE_STATUS.cancelled]: tCommon("workspaceStatus.cancelled"),
};

export function Workspace({ id, name, imageUrl, status }) {
  const { tCommon } = useAppTranslation.Common();
  const { switchWorkspace, isWorkspaceSwitching } = useSwitchWorkspace();

  const statusClasses = cx(classes.status, {
    [classes.statusHold]: status === WORKSPACE_STATUS.hold,
    [classes.statusActive]: status === WORKSPACE_STATUS.active,
    [classes.statusInactive]: status === WORKSPACE_STATUS.inactive,
    [classes.statusCancelled]: status === WORKSPACE_STATUS.cancelled,
  });

  const handleLaunch = (workspaceId) => {
    switchWorkspace({ workspaceId }).then(authService.afterLogin);
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div
          className={classes.image}
          style={
            imageUrl
              ? {
                  background: `url('${imageUrl}') no-repeat center center / cover`,
                }
              : undefined
          }
        >
          {!imageUrl && <img src={svg.noImage} alt="no logo" />}
        </div>
        <div className={classes.nameWrap}>
          <div className={classes.name}>{name}</div>
          {Object.values(WORKSPACE_STATUS).includes(status) && (
            <div className={statusClasses}>{labelByStatus[status]}</div>
          )}
        </div>
      </div>
      <Button
        fontFamily="secondary"
        onClick={() => handleLaunch(id)}
        className={classes.launchBtn}
        isDisabled={isWorkspaceSwitching}
        leftAdornment={
          isWorkspaceSwitching ? (
            <CircularProgress color="white" size="small" />
          ) : undefined
        }
      >
        {tCommon("label.launch")}
      </Button>
    </div>
  );
}

Workspace.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  status: PropTypes.oneOf(Object.values(WORKSPACE_STATUS)).isRequired,
};

Workspace.defaultProps = {
  imageUrl: null,
};
