import { matchPath } from "react-router";
import { DASHBOARD_ROUTES } from "../../../../consts/routes";
import { USER_LOG_DIFF_ACTION } from "../../../../api/queries/useUserLogsDiffQuery";

export const getPathParams = () => {
  const leadMatch = matchPath(window.location.pathname, {
    path: DASHBOARD_ROUTES.userLogDiff({
      type: ":type",
      childId: ":childId",
      objectId: ":objectId",
    }),
    exact: true,
    strict: false,
  });

  return {
    type: leadMatch?.params.type,

    childId: leadMatch?.params.childId
      ? Number(leadMatch.params.childId)
      : undefined,

    objectId: leadMatch?.params.objectId
      ? Number(leadMatch.params.objectId)
      : undefined,
  };
};

export const getDiffs = (diff, action) => {
  const diff1 = action === USER_LOG_DIFF_ACTION.add ? {} : diff[0];

  const diff2 = (() => {
    if (action === USER_LOG_DIFF_ACTION.add) {
      return diff[0];
    }

    if (action === USER_LOG_DIFF_ACTION.delete) {
      return {};
    }

    return diff?.[1] || {};
  })();

  return { diff1, diff2 };
};
