import React from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { Button } from "../../../../shared/Button/Button";
import { PlusIcon } from "../../../../assets/Icons/PlusIcon";
import styles from "./styles.module.scss";
import { TemplatesTable } from "./shared/Table/TemplatesTable";
import { useData } from "./hooks/useData";
import { history } from "../../../../history";
import { ROUTES } from "../../../../consts/routes";
import { PERMISSIONS } from "../../../../consts/api";
import { usePermission } from "../../../../hooks/usePermission";
import { Tabs } from "../../../../shared/Tabs/Tabs";
import { Box } from "../../../../shared/Box/Box";
import { InputSearch } from "../../../../boxes/InputSearch/InputSearch";
import { useQueryParamsState } from "../../../../utilities/hooks/useQueryParamsState";
import { TABS } from "./config";
import { FavoritesTable } from "./shared/Table/FavoritesTable";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";

export const EfaxTemplates = () => {
  const { tSettings } = useAppTranslation.Settings();

  const [activeTab, setActiveTab] = useQueryParamsState({
    tab: TABS.templates,
  });

  const { search, ...data } = useData(activeTab);

  const { data: userData } = useCurrentUserQuery();

  const tabOptions = [
    {
      label: tSettings("efaxTemplates.configurationTabs.templates"),
      value: TABS.templates,
      onClick: () => setActiveTab({ tab: TABS.templates }),
    },
    {
      label: tSettings("efaxTemplates.configurationTabs.favorites"),
      value: TABS.favorites,
      onClick: () => setActiveTab({ tab: TABS.favorites }),
    },
  ];

  const addEditEfaxTemplatesPermission = usePermission(
    PERMISSIONS.patientManagement.addEditEfaxTemplates,
  );

  const hasAddEditEfaxTemplatesPermission =
    (addEditEfaxTemplatesPermission.loaded &&
      addEditEfaxTemplatesPermission.permitted) ||
    userData?.isMainAdmin;

  return (
    <LayoutSettings contentClassName={styles.root}>
      <EntityHeader title={tSettings("efaxTemplates.title")} />
      <Box className={styles.tabsBox}>
        <Tabs active={activeTab.tab} options={tabOptions} />
        <InputSearch
          value={search.value || ""}
          onChange={search.onChange}
          isSearching={search.isSearching}
        />
        {activeTab.tab === TABS.templates ? (
          hasAddEditEfaxTemplatesPermission && (
            <Button
              size="small"
              className={styles.createButton}
              leftAdornment={<PlusIcon />}
              onClick={() => history.push(ROUTES.settings.createEfaxTemplate())}
            >
              {tSettings("efaxTemplates.addTemplate")}
            </Button>
          )
        ) : (
          <Button
            size="small"
            className={styles.createButton}
            leftAdornment={<PlusIcon />}
            onClick={() => history.push(ROUTES.settings.createEfaxFavorite())}
          >
            {tSettings("efaxTemplates.addFavorite")}
          </Button>
        )}
      </Box>
      {activeTab.tab === TABS.templates ? (
        <TemplatesTable
          templates={data}
          hasAddEditEfaxTemplatesPermission={hasAddEditEfaxTemplatesPermission}
        />
      ) : (
        <FavoritesTable favorites={data} />
      )}
    </LayoutSettings>
  );
};
