import { usePPAnnouncementBannerDeleteMutation } from "../../../../../api/patientPortal/announcementBanner/usePPAnnouncementBannerDeleteMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export function useDeleteBanner(onSuccess) {
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();

  const { mutate, isLoading } = usePPAnnouncementBannerDeleteMutation({
    onError: () => {
      uiNotification.error(t("patientPortal.banner.error.delete"));
    },
    onSuccess: () => {
      uiNotification.success(t("patientPortal.banner.success.delete"));
      onSuccess?.();
    },
  });

  return {
    initiate: mutate,
    isLoading,
  };
}
