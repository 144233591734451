import React, { Component } from "react";
import Tooltip from "react-tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import {
  checkIfPermissionAllowed,
  showFormattedDate,
} from "../../../Utils/services.js";
import ExpiredPaymentIcon from "./icons/ExpiredPaymentIcon.js";
import { shortenClinicName } from "../../../helpers/general";
import { SignIcon } from "./components/SignIcon/SignIcon.js";
import MarkGfeButton from "./components/MarkGfeButton.js";
import GfeLineIcon from "../../../assets/Icons/GfeLineIcon.js";
import FilterCategorizationSelect from './components/FilterCategorizationSelect.js';

export default class HealthTimeline extends Component {
  constructor(props) {
    super(props);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const ePrescriptionSetup = localStorage.getItem("ePrescriptionSetup")
      ? JSON.parse(localStorage.getItem("ePrescriptionSetup"))
      : null;

    this.state = {
      userData: userData,
      isTouchMd: userData.account.account_preference.is_touch_md,
      plan_selected: userData.account.account_subscription.plan_code,
      ePrescriptionSetup: ePrescriptionSetup,
      isMarkedAsGfe: this.props.hobj.is_marked_as_gfe,
    };
  }

  get invoiceStatusIcon() {
    const status = {
      check: [
        "paid",
        "paid_by_merge",
        "refunded",
        "partial_refunded",
        "refunded_from_merge",
      ],
      alert: ["partial paid", "partial_paid"],
    };
    const invoiceStatus = this.props.hobj.pos_invoices?.invoice_status;
    const hasInvoice =
      this.props.hobj.invoice_id > 0 && this.props.hobj.pos_invoices;
    const isSessionExpired =
      this.props.hobj.checkout_session_expired && !hasInvoice;

    let iconClass = "fa fa-times";

    if (hasInvoice) {
      if (status.check.includes(invoiceStatus)) {
        iconClass = "fa fa-check";
      }
      if (status.alert.includes(invoiceStatus)) {
        iconClass = "alertIcon pull-right m-t-1";
      }
    }

    const handleClick = () => {
      this.props.goToInvoicePage(this.props.hobj);
    };

    return (
      <li className="action-button-li pending-payment" onClick={handleClick}>
        Invoice{" "}
        {invoiceStatus === "pending" && (
          <span className="inline-block">{"(pending payment)"}</span>
        )}
        {isSessionExpired ? (
          <ExpiredPaymentIcon />
        ) : (
          <i className={iconClass} aria-hidden="true"></i>
        )}
      </li>
    );
  }

  render() {
    const canSendPosInstructions = this.props.hobj?.post_t_x_count > 0;

    return (
      <div className="col-xs-12 health-timeline">
        <div className="row">
          {this.state.isTouchMd == 1 &&
          this.state.plan_selected == "practice_booking" ? (
            <div className="col-sm-3 col-xs-12 m-b-15">
              <label className="popup-input-field-name">Date & Time</label>
              <p className="pro-time healthTimelineDetail m-b-0">
                {showFormattedDate(this.props.hobj.procedure_date, true)}
              </p>
            </div>
          ) : (
            <div className="col-sm-3 col-xs-12 m-b-15">
              {checkIfPermissionAllowed("manage-procedures") ? (
                <a
                  onClick={() =>
                    this.props.openProcedureDetail(
                      this.props.hobj.id,
                      this.props.hobj.procedure_type,
                    )
                  }
                  className="modal-link"
                >
                  <h4 className="procedure-name">
                    {this.props.hobj.procedure_name}
                  </h4>
                </a>
              ) : (
                <a className="modal-link pro-name">
                  <h4>{this.props.hobj.procedure_name}</h4>
                </a>
              )}
              <p className="pro-time healthTimelineDetail m-b-0">
                {showFormattedDate(this.props.hobj.procedure_date, true)}
              </p>
            </div>
          )}
          <div className="col-sm-3 col-xs-12 m-b-15">
            <label className="popup-input-field-name">Provider</label>
            <span className="healthTimelineDetail m-b-0">
              {this.props.hobj.doctor_name}
            </span>
          </div>
          <div className="col-sm-3 col-xs-12 m-b-15">
            {this.props.hobj.clinic && (
              <div>
                <label className="popup-input-field-name">Location</label>
                <span className="healthTimelineDetail">
                  {shortenClinicName(this.props?.hobj?.clinic?.clinic_name)}
                </span>
              </div>
            )}
          </div>
          <div className="col-sm-3 col-xs-12 m-b-15 text-right">
            <GfeLineIcon
              color={this.state.isMarkedAsGfe ? "#03bf62" : "#bcbcbc"}
            />
            <FilterCategorizationSelect
              value={this.props.hobj.charting_category_id}
              procedureId={this.props.hobj.id}
              refetchProcedures={this.props.refetchProcedures}
            />
          </div>
        </div>
        {this.props.hobj.note && (
          <div className="row p-b-0 p-t-0">
            <div className="col-md-12">
              <div className="health-smry-outer auto-height m-b-25 ">
                <label className="title-1 p-t-20">Details</label>
                <div className="newInputFileldOuter">
                  <div className="newInputLabel">Encounter Note</div>
                  <div className="newtextareaField DiagnosisDetailstextbox">
                    <Scrollbars
                      autoHeight
                      autoHeightMax="160px"
                      className={"custome-scroll"}
                    >
                      <div
                        style={{
                          paddingRight: "15px",
                          fontSize: "13px!important",
                        }}
                        className="activity-detail"
                        dangerouslySetInnerHTML={{
                          __html: this.props.hobj.note,
                        }}
                      ></div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.props.hobj.procedure_prescription &&
          this.props.hobj.procedure_prescription.length > 0 && (
            <div className="row p-b-10 p-t-0">
              <div className="col-md-12">
                <div className="health-smry-outer auto-height m-b-25">
                  {this.props.hobj.procedure_prescription.length > 0 && (
                    <h4 className="title-1 m-b-10">Treatment Summary</h4>
                  )}
                  {this.props.hobj.procedure_prescription.length > 0 && (
                    <ul className="treat-sumry profile-treat-sumry no-padding">
                      {this.props.hobj.procedure_prescription.map(
                        (ppObj, ppIdx) => {
                          return (
                            <li
                              key={"procedure-health-" + ppIdx}
                              className="treat-sumry-li"
                            >
                              <label>{ppObj.medicine_name}</label>{" "}
                              <span>{ppObj.dosage ? ppObj.dosage : ""}</span>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        {this.props.hobj.scriptsure_prescription_medicine?.length > 0 && (
          <div className="row p-b-10 p-t-0">
            <div className="col-md-12">
              <div className="health-smry-outer auto-height m-b-25">
                {this.props.hobj.scriptsure_prescription_medicine?.length >
                  0 && <h4 className="title-1 m-b-10">Prescriptions</h4>}
                {this.props.hobj.scriptsure_prescription_medicine?.length >
                  0 && (
                  <ul className="treat-sumry profile-treat-sumry no-padding">
                    {this.props.hobj.scriptsure_prescription_medicine?.map(
                      (ppObj, ppIdx) => {
                        return (
                          <li
                            key={"procedure-health-ePrescribe" + ppIdx}
                            className="treat-sumry-li"
                          >
                            <label style={{ width: "100%" }}>
                              {ppObj.medicine}
                            </label>
                          </li>
                        );
                      },
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12">
            <div className="action-button-wrapper">
              <div className="action-button-inner">
                {this.props.hobj.erx_prescription == "0" && (
                  <div className="col-sm-3 col-xs-12 action-button-section">
                    <h3 className="action-button-title">Paperwork</h3>
                    <ul className="action-button-ul">
                      {this.props.hobj.patient_questionnaires_count > 0 ? (
                        <li
                          className="action-button-li"
                          onClick={() =>
                            this.props.viewQuestionnaires(this.props.hobj.id)
                          }
                        >
                          Questionnaires
                          <div className="d-flex align-center gap-4 justify-end">
                            {this.props.hobj
                              ?.patient_questionnaires_not_filled_count > 0 && (
                              <div data-tip data-for="pendingQuestionnaires">
                                <SignIcon />
                              </div>
                            )}
                            <i
                              className="fa fa-check pull-right"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </li>
                      ) : (
                        <li className="action-button-li">
                          Questionnaires
                          <i
                            className="fa fa-times pull-right"
                            aria-hidden="true"
                          ></i>
                        </li>
                      )}
                      <Tooltip id="pendingQuestionnaires" aria-haspopup="true">
                        Some questionnaires are not filled
                      </Tooltip>
                      {this.props.hobj?.patient_consents_count > 0 ? (
                        <li
                          className="action-button-li"
                          onClick={() =>
                            this.props.viewConsents(this.props.hobj.id)
                          }
                        >
                          Consent{" "}
                          <div className="d-flex align-center gap-4 justify-end">
                            {this.props.hobj
                              ?.patient_consents_not_signed_count > 0 && (
                              <div data-tip data-for="pendingWitnessSignature">
                                <SignIcon />
                              </div>
                            )}
                            <i
                              className="fa fa-check pull-right"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </li>
                      ) : (
                        <li className="action-button-li">
                          Consent{" "}
                          <i
                            className="fa fa-times pull-right"
                            aria-hidden="true"
                          ></i>
                        </li>
                      )}
                      <Tooltip
                        id="pendingWitnessSignature"
                        aria-haspopup="true"
                      >
                        Some consents need a witness signature
                      </Tooltip>
                      {canSendPosInstructions && (
                        <li
                          className="action-button-li"
                          onClick={() =>
                            this.props.showSendInstructionPopup(
                              this.props.hobj.id,
                            )
                          }
                        >
                          Post Treatment Instructions{" "}
                          <i
                            className="fa fa-check pull-right"
                            aria-hidden="true"
                          ></i>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {this.state.isTouchMd == 1 &&
                this.state.plan_selected == "practice_booking" ? (
                  ""
                ) : (
                  <div className="col-sm-3 col-xs-12 action-button-section">
                    <h3 className="action-button-title">Medication</h3>
                    <ul className="action-button-ul">
                      {this.props.hobj.procedure_prescription.length > 0 &&
                        this.state.ePrescriptionSetup != null &&
                        !this.state.ePrescriptionSetup.e_prescription_new && (
                          <li
                            className="action-button-li"
                            onClick={() =>
                              this.props.showPrescriptionNew(
                                this.props.hobj.id,
                                this.props.hidx,
                                true,
                              )
                            }
                          >
                            Prescription{" "}
                            <i
                              className="fa fa-check pull-right"
                              aria-hidden="true"
                            ></i>
                          </li>
                        )}
                      {this.props.hobj.procedure_prescription.length == 0 &&
                        this.state.ePrescriptionSetup != null &&
                        !this.state.ePrescriptionSetup.e_prescription_new && (
                          <li
                            className="action-button-li"
                            onClick={() =>
                              this.props.showPrescriptionNew(
                                this.props.hobj.id,
                                this.props.hidx,
                                false,
                              )
                            }
                          >
                            Prescription{" "}
                            <i
                              className="fa fa-times pull-right"
                              aria-hidden="true"
                            ></i>
                          </li>
                        )}

                      {this.state.ePrescriptionSetup != null &&
                        this.state.ePrescriptionSetup.e_prescription_new && (
                          <li
                            className="action-button-li"
                            onClick={() =>
                              this.props.handleEprescribe(this.props.hobj)
                            }
                          >
                            e-Prescribe
                          </li>
                        )}
                      {checkIfPermissionAllowed("manage-tracebility-info") && (
                        <li
                          className="action-button-li"
                          onClick={() =>
                            this.props.viewHealthTraceability(
                              this.props.hobj.id,
                            )
                          }
                        >
                          {"Traceability"}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {this.state.isTouchMd == 1 &&
                this.state.plan_selected == "practice_booking" ? (
                  ""
                ) : (
                  <div className="col-sm-3 col-xs-12 action-button-section">
                    <h3 className="action-button-title">Payment</h3>
                    <ul className="action-button-ul">
                      {this.invoiceStatusIcon}
                    </ul>
                  </div>
                )}
                {this.state.isTouchMd == 1 &&
                this.state.plan_selected == "practice_booking" ? (
                  ""
                ) : (
                  <div className="col-sm-3 col-xs-12 action-button-section">
                    <h3 className="action-button-title">More</h3>
                    <ul className="action-button-ul">
                      <li
                        className="action-button-li"
                        onClick={() => this.props.viewNotes(this.props.hobj.id)}
                      >
                        View Notes ({this.props.hobj.procedure_notes_count})
                      </li>
                      {checkIfPermissionAllowed("manage-procedures") ===
                        true && (
                        <li
                          className="action-button-li"
                          onClick={() =>
                            this.props.openProcedureDetail(
                              this.props.hobj.id,
                              this.props.hobj.procedure_type,
                            )
                          }
                        >
                          Edit Procedure
                        </li>
                      )}
                      {this.state.isMarkedAsGfe !== null && (
                        <li className={`p-r-0 action-button-li`}>
                          <MarkGfeButton
                            procedureId={this.props.hobj.id}
                            isMarkedAsGfe={this.state.isMarkedAsGfe}
                            changeMarkedAsGfe={() => {
                              this.setState({
                                isMarkedAsGfe: !this.state.isMarkedAsGfe,
                              });
                            }}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
