import { useEffect, useMemo, useState } from "react";
import { arrayMoveImmutable } from "array-move";

const extractId = (i) => i.id;

export function useReorder(data) {
  const [orderedData, setOrderedData] = useState([]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setOrderedData(arrayMoveImmutable(orderedData, oldIndex, newIndex));
  };

  const onSortReset = () => {
    setOrderedData(data);
  };

  const isTouched = useMemo(
    () =>
      data?.map(extractId)?.join("") !== orderedData.map(extractId).join(""),
    [data, orderedData],
  );

  useEffect(() => {
    setOrderedData(data);
  }, [data]);

  return {
    orderedData,
    onSortEnd,
    onSortReset,
    isTouched,
  };
}
