import React from "react";
import PropTypes from "prop-types";

export function Empty({ onCreate }) {
  return (
    <div className="not-create-report">
      <h3>{`You haven't created any dashboard yet`}</h3>
      <button onClick={onCreate} className="new-blue-btn m-t-25">
        Create Dashboard
      </button>
    </div>
  );
}

Empty.propTypes = {
  onCreate: PropTypes.func.isRequired,
};
