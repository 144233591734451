import React from "react";
import PropTypes from "prop-types";
import classes from "./Info.module.scss";
import { Skeleton } from "../../../../../../../../../shared/Skeleton/Skeleton";
import { InputLabel } from "../../../../../../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { Input } from "../../../../../../../../../shared/Input/Input";
import { getHealthPathParams } from "../../../../HealthCreateEdit.utils";
import { InputError } from "../../../../../../../../../shared/InputError/InputError";

export function Info({ chiefComplaint, isLoading }) {
  const { isEditMode } = getHealthPathParams();
  const { tClients } = useAppTranslation.Clients();

  return isLoading ? (
    <div className={classes.root}>
      <Skeleton count={1} height={50} />
    </div>
  ) : (
    <div className={classes.root}>
      <div>
        <InputLabel>
          {tClients("createEditHealthProcedure.info.chiefComplaint")}{" "}
          {isEditMode && <span className={classes.requiredAsterisk}>*</span>}
        </InputLabel>
        <Input
          value={chiefComplaint.value || ""}
          onChange={(e) => chiefComplaint.onChange(e.target.value)}
          isError={Boolean(chiefComplaint.getError())}
        />
        {Boolean(chiefComplaint.getError()) && (
          <InputError>{chiefComplaint.getError()}</InputError>
        )}
      </div>
    </div>
  );
}

Info.propTypes = {
  chiefComplaint: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};
