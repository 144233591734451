import React, { useState } from "react";
import PropTypes from "prop-types";
import CreditCard from "./boxes/CreditCard/CreditCard";
import classes from "./ClientCreditCards.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";
import { Button } from "../../../../shared/Button/Button";
import { svg } from "../../../../assets/svg";
import { useCreditCards } from "./hooks/useCreditCards";
import AddEditCardModal from "./shared/AddEditCardModal/AddEditCardModal";
import { getSetupIntent } from "../../../../Actions/Stripe/StripeActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { USER_PAYMENT_SYSTEMS } from "../../../../consts/api";

const ClientCreditCards = ({ clientId, getSetupIntent, cardList, clientClinicId }) => {
  const { tCommon } = useAppTranslation.Common();
  const cc = useCreditCards(clientId, cardList);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const isEmpty = !cc.isLoading && cc.list.length === 0;
  const isVisible = !cc.isLoading && !isEmpty;
  const isAddBtnVisible = cc.canAdd && cc.isPosEnabled;

  const openAddModal = () => {
    setIsAddModalOpen(true)
    if (cc.paymentSystem === USER_PAYMENT_SYSTEMS.stripe) {
      getSetupIntent({ patient_id: clientId, clinic_id: clientClinicId })
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.cards}>
        {cc.isLoading && <Skeleton count={3} height={25} borderRadius="4px" />}
        {isEmpty && (
          <div className={classes.empty}>{tCommon("label.noCreditCards")}</div>
        )}
        {isVisible &&
          cc.list.map((card) => (
            <CreditCard
              key={card.id}
              id={card.id}
              cardNumber={card.cardNumber}
              clientId={clientId}
              clientClinicId={clientClinicId}
            />
          ))}
      </div>
      {isAddBtnVisible && (
        <Button
          widthFit
          variant="text"
          size="tiny"
          leftAdornment={<img src={svg.plusPrimary} alt="+" width="11px" />}
          onClick={() => openAddModal()}
        >
          {tCommon("label.addCC")}
        </Button>
      )}
      {isAddModalOpen && (
        <AddEditCardModal
          clientId={clientId}
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
        />
      )}
    </div>
  );
}

ClientCreditCards.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const mapStateToProps = (state) => {
  return {
    cardList: state.StripeReducer.cardList
  }
} 

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getSetupIntent
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientCreditCards);