/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

class RetailReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData
    ) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  fetchRetailDetail = (obj) => {
    if (obj) {
      let returnState = {
        category_name: obj.category_name,
      };

      this.props.fetchRetailDetail(returnState);
    }
  };

  render() {
    return (
      <div className="table-responsive clients-table">
        <table className="table-updated setting-table table-min-width no-td-border no-hover">
          <thead className="table-updated-thead">
            {
              <tr>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.category_name}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.unit_sold}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.sub_total}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.item_discount}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.taxable_amount}
                </th>

                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.view_details}
                </th>
              </tr>
            }
          </thead>
          <tbody className="ajax_body">
            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length > 0 &&
              this.state.reportData.report_data.map((obj, idx) => {
                return (
                  <tr key={idx} className="table-updated-tr">
                    <td className="table-updated-td">{obj.category_name}</td>
                    <td className="table-updated-td">{obj.unit_sold}</td>
                    <td className="table-updated-td">{obj.sub_total}</td>
                    <td className="table-updated-td">{obj.item_discount}</td>
                    <td className="table-updated-td">{obj.taxable_amount}</td>

                    <td className="table-updated-td">
                      <a
                        className="easy-link no-padding"
                        onClick={this.fetchRetailDetail.bind(this, obj)}
                      >
                        {this.state.languageData.bi_view_details}
                      </a>
                    </td>
                  </tr>
                );
              })}

            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length === 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan="10"
                  >
                    {this.state.languageData.bi_no_record_found}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default RetailReport;
