import React from "react";
import PropTypes from "prop-types";
import { numberFormat } from "../../../../Utils/services";
import { getLanguageData } from "../../../../utilities/localStorage";
import { LANGUAGE_DATA_KEYS } from "../../../../consts/localStorage";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";

const AppointmentReschedulingModal = ({
  isOpen,
  onClose,
  onCharge,
  onCancel,
  cancellationFee,
}) => {
  const appointmentLang = getLanguageData(LANGUAGE_DATA_KEYS.appointments);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onCharge}
      cancelTitle={appointmentLang.appointment_save_do_not_charge}
      confirmTitle={appointmentLang.appointment_save_charge}
      title={appointmentLang.appointment_rescheduling_fees}
    >
      {appointmentLang.appointment_rescheduling_fee}{" "}
      {numberFormat(cancellationFee, "currency")}.{" "}
      {appointmentLang.appointment_want_to_do}
    </ConfirmModal>
  );
};

AppointmentReschedulingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCharge: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancellationFee: PropTypes.number.isRequired,
};

export default AppointmentReschedulingModal;
