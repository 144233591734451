import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getProcedureData(procedureID) {
  return (dispatch) => {
    http
      .get(`get-procedure/${procedureID}`)
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PROCEDURE_DATA", payload: error.response.data });
      });
  };
}

export function vieweHealthProcedureData(formData, procedureId) {
  return (dispatch) => {
    http
      .get("clients/health-procedure-view/" + procedureId, formData)
      .then((response) => {
        dispatch({
          type: "VIEW_HEALTH_PROCEDURE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "VIEW_HEALTH_PROCEDURE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedurePrescription(procedureId) {
  return (dispatch) => {
    http
      .get("clients/prescription/" + procedureId)
      .then((response) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function saveProcedurePrescription(procedureId, formData) {
  return (dispatch) => {
    http
      .post("clients/prescription/" + procedureId, formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
        dispatch({
          type: "EMPTY_DATA",
          payload: { data: "", status: 200, message: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function deleteProcedurePrescription(procedureId) {
  return (dispatch) => {
    http
      .delete("clients/prescription/" + procedureId)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "DELETE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedureConsent(procedureId) {
  return (dispatch) => {
    http
      .get("clients/health-procedure-consents/" + procedureId)
      .then((response) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_CONSENT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_CONSENT",
          payload: error.response.data,
        });
      });
  };
}

export function updateProcedureConsent(procedureId, formData) {
  return (dispatch) => {
    http
      .put("clients/health-procedure-consents/" + procedureId, formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "UPDATE_HEALTH_PROCEDURE_CONSENT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_HEALTH_PROCEDURE_CONSENT",
          payload: error.response.data,
        });
      });
  };
}

export function sendProcedurePrescription(procedureId, clientID) {
  return (dispatch) => {
    http
      .get("clients/send-prescription/" + procedureId + "/" + clientID)
      .then((response) => {
        dispatch({
          type: "SEND_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SEND_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function emptyProcedureReducer() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_PROCEDURE_REDUCER",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function fetchSelectMD() {
  return (dispatch) => {
    http
      .get("user/get-all-mds")
      .then((response) => {
        dispatch({ type: "PRO_MDS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PRO_MDS_LIST", payload: error.response.data });
      });
  };
}

const serialize = function (obj) {
  var str = [];

  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
};

export function signProcedure(formData, fetchRows, listData, roomType) {
  fetchRows = fetchRows || false;
  listData = listData || { params: {} };

  return (dispatch) => {
    http
      .post(roomType + "/sign-consents?" + serialize(listData.params), formData)
      .then((response) => {
        response.data.status = 201;
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "SIGN_HEALTH_PROCEDURE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SIGN_HEALTH_PROCEDURE",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedureQuestionnaireList(formData) {
  return (dispatch) => {
    http
      .get(
        `patient-questionnaires/procedure/${formData.params.procedure_id}`,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedureQuestionnaireData(formData) {
  return (dispatch) => {
    http
      .get("get-procedure-questionnaire-detail", formData)
      .then((response) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function saveProcedureQuestionnaireHealth(formData) {
  return (dispatch) => {
    http
      .post("save-healthtimeline-answers", formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "SAVE_PROCEDURE_QUESTIONNAIRE_HEALTH",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_PROCEDURE_QUESTIONNAIRE_HEALTH",
          payload: error.response.data,
        });
      });
  };
}

export function addEditProceudreQuestionnaireIds(formData) {
  return (dispatch) => {
    http
      .post("clients/add-edit-procedure-questionnaire-ids", formData)
      .then((response) => {
        dispatch({
          type: "ADD_EDIT_PROCEDURE_QUESTIONNAIRE_IDS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_EDIT_PROCEDURE_QUESTIONNAIRE_IDS",
          payload: error.response.data,
        });
      });
  };
}
