import React, { useState } from "react";
import { withRouter } from "react-router";
import queryString from "query-string";
import { Header } from "./components/Header";
import { Table } from "./components/Table";
import { ModalFilter } from "./components/ModalFilter";
import { ReportDetails } from "./ReportDetails/ReportDetails";
import { useReports } from "./hooks/useReports";
import { useDownloadExcel } from "./hooks/useDownloadExcel";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { ReportStats } from "../../../../../boxes/ReportStats/ReportStats";

function MembershipOverviewReport({ location }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { reports, filter, order, period, stats, clinics } = useReports();
  const queryParams = queryString.parse(location.search);
  const reportId = Number(queryParams.id);

  const downloadExcel = useDownloadExcel({
    period,
    filter: filter.preparedForRequest,
  });

  return reportId ? (
    <ReportDetails id={reportId} />
  ) : (
    <ReportLayout>
      <Header
        isFilterActive={filter.isActive}
        onFilterOpen={() => setIsFilterOpen(true)}
        period={period}
        downloadExcel={downloadExcel}
        isReportsLoading={reports.isLoading}
      />
      <ReportStats isLoading={reports.isLoading} data={stats} />
      <Table reports={reports} order={order} />
      <ModalFilter
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={filter}
        clinics={clinics}
      />
    </ReportLayout>
  );
}

export default withRouter(MembershipOverviewReport);
