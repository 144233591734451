import React from 'react';
import styles from "./styles.module.scss";
import { PencilBoxIcon } from '../../../../../assets/Icons/PencilBoxIcon';

export const SignIcon = () => {
  return (
    <div className={styles.root}>
      <PencilBoxIcon width="16px" height="14px" />
    </div>
  )
}
