import { useWorkspacesQuery } from "../../../api/queries/useWorkspacesQuery";
import { WORKSPACE_STATUS } from "../../../consts/api";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { authService } from "../../../services/AuthService";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";

export function useWorkspaces() {
  const wpuId = authService.getLoginData().wpuId;
  const { tCommon } = useAppTranslation.Common();
  const { tAuth } = useAppTranslation.Auth();

  const { data, isLoading } = useWorkspacesQuery({
    payload: {
      wpuId,
    },
    options: {
      staleTime: 0,
      enabled: Boolean(wpuId),
      onError: (e) => {
        const errorKey = extractApiError(e);
        uiNotification.error(
          tCommon(`apiError.${errorKey}`, tAuth("workspaces.error.fetch")),
        );
      },
    },
  });

  const preparedWorkspaces = data?.map((workspace) => ({
    ...workspace,
    status:
      workspace.status === WORKSPACE_STATUS.cancelled
        ? WORKSPACE_STATUS.inactive
        : workspace.status,
  }));

  return {
    workspaces: preparedWorkspaces || [],
    isWorkspacesLoading: isLoading,
  };
}
