import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getAppointmentSurveys(formData) {
  return (dispatch) => {
    http
      .get("appointments/setting/survey_email_sms", formData)
      .then((response) => {
        dispatch({ type: "APPOINTMENT_SURVEY_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "APPOINTMENT_SURVEY_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function postAppointmentSurveys(formData) {
  return (dispatch) => {
    http
      .put("appointments/setting/survey_email_sms", formData)
      .then((response) => {
        dispatch({ type: "APPOINTMENT_SURVEY_UPDATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "APPOINTMENT_SURVEY_UPDATE",
          payload: error.response.data,
        });
      });
  };
}
