import React, { useEffect, useState } from "react";
import InsightsSideBar from "../../../InsightsSideBar";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { Header } from "./boxes/Header/Header";
import { useReport } from "./hooks/useReport";
import { Table } from "./boxes/Table/Table";
import { ModalUpdate } from "./shared/ModalUpdate/ModalUpdate";
import { useMultiselectEntity } from "../../../../../utilities/hooks/useMultiselectEntity";
import { ButtonScrollTop } from "../../../../../shared/ButtonScrollTop/ButtonScrollTop";
import { StickyBox } from "../../../../../shared/StickyBox/StickyBox";

export const NoClinicPatientsReport = () => {
  const { reports, order } = useReport();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const selectedPatients = useMultiselectEntity({ initialSelect: [] });

  useEffect(() => {
    if (reports.value.length > 0) {
      selectedPatients.setEntities(reports.value.map((r) => r.id));
    }
  }, [reports.value]);

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper overflow-auto">
        <InsightsSideBar />
        <div className="memberWalletOuter business-section">
          <ReportLayout>
            <Header
              onUpdate={() => setIsUpdateModalOpen(true)}
              selectedCount={selectedPatients.selected.length}
            />
            <Table
              reports={reports}
              order={order}
              selectedPatients={selectedPatients}
            />
            {isUpdateModalOpen && (
              <ModalUpdate
                onClose={() => setIsUpdateModalOpen(false)}
                onSuccess={selectedPatients.resetSelected}
                patientIds={selectedPatients.selected}
              />
            )}
          </ReportLayout>
        </div>
      </div>
      <StickyBox position="left" visibleAfterOffset={300}>
        <ButtonScrollTop />
      </StickyBox>
    </div>
  );
};
