import React, { useState } from "react";
import cx from "clsx";
import classes from "./Notes.module.scss";
import { Form } from "./shared/Form/Form";
import { ContentLoader } from "../../boxes/ContentLoader/ContentLoader";
import { Note } from "./shared/Note/Note";

/**
 * @param {object} prop0
 * @param {Array<{
 *  id: number;
 *  note: string;
 *  date: string;
 *  author: string;
 * }>} prop0.data
 * @param {string?} prop0.className
 * @param {(data: { id?: number; note: string; }) => void} prop0.onSave
 * @param {(id: number) => void} prop0.onDelete
 * @param {boolean?} prop0.isDeleting
 * @param {boolean?} prop0.isSaving
 * @param {string?} prop0.notesClassName
 * @param {string?} prop0.formClassName
 */
export const Notes = ({
  data,
  className,
  onSave,
  onDelete,
  isDeleting,
  isSaving,
  notesClassName,
  formClassName,
}) => {
  const [editNode, setEditNode] = useState(null);

  return (
    <ContentLoader
      isLoading={isDeleting || isSaving}
      className={cx(classes.root, className)}
    >
      <Form
        className={formClassName}
        initialValues={editNode}
        onCancel={() => setEditNode(null)}
        onSubmit={(formValues, { resetForm }) =>
          onSave(
            { id: formValues.id, note: formValues.text },
            {
              resetForm: () => {
                resetForm();
                setEditNode(null);
              },
            },
          )
        }
      />
      {data.length > 0 && (
        <div className={cx(classes.notes, notesClassName)}>
          {data.map((note) => (
            <Note
              key={note.id}
              text={note.note}
              date={note.date}
              author={note.author}
              onDelete={() => onDelete(note.id)}
              onEdit={() => setEditNode({ id: note.id, text: note.note })}
            />
          ))}
        </div>
      )}
    </ContentLoader>
  );
};
