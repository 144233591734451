import { useMutation } from "@tanstack/react-query";
import { http } from "../../services/HttpService";

const LockProcedure = ({ procedureId, isLock }) => {
  const url = `procedure-info-access/${procedureId}`;
  return http.post(url, { is_lock: isLock });
};

export const useLockProcedureQuery = () => {
  return useMutation(LockProcedure);
};

const BulkUnlockProcedure = (payload) => {
  const url = `bulk-unlock-procedure-info`;
  return http.post(url, payload);
};

export const useBulkUnlockProcedureQuery = () => {
  return useMutation(BulkUnlockProcedure);
};

const checkValidUser = (payload) => {
  const url = `check-valid-user`;
  return http.post(url, payload);
};

export const useCheckValidUserQuery = () => {
  return useMutation(checkValidUser);
};
