export const REDUCER_NAME = "patients/vitals";

export const ACTION_TYPES = {
  activeRecordIdChange: `${REDUCER_NAME}/activeRecordIdChange`,
  setMutationRecord: `${REDUCER_NAME}/setMutationRecord`,
  dropMutationRecord: `${REDUCER_NAME}/dropMutationRecord`,
  patchMutationRecordField: `${REDUCER_NAME}/patchMutationRecordField`,
  appendMutationRecordFieldErrors: `${REDUCER_NAME}/appendMutationRecordFieldErrors`,
  clearMutationRecordFieldErrors: `${REDUCER_NAME}/clearMutationRecordFieldErrors`,
  graphDelete: `${REDUCER_NAME}/graphDelete`,
  graphAdd: `${REDUCER_NAME}/graphAdd`,
  graphUpdate: `${REDUCER_NAME}/graphUpdate`,
  graphSetIsMutating: `${REDUCER_NAME}/graphSetIsMutating`,
  addMutationRecordNote: `${REDUCER_NAME}/addMutationRecordNote`,
  updateMutationRecordNote: `${REDUCER_NAME}/updateMutationRecordNote`,
  deleteMutationRecordNote: `${REDUCER_NAME}/deleteMutationRecordNote`,
};

export const MUTATION_RECORD_FIELDS = {
  id: "id",
  date: "date",
  height: "height",
  weight: "weight",
  bmi: "bmi",
  bodyFat: "bodyFat",
  bloodPressureSystolic: "bloodPressureSystolic",
  bloodPressureDiastolic: "bloodPressureDiastolic",
  pulse: "pulse",
  temperature: "temperature",
  respiration: "respiration",
  notes: "notes",
};

export const MUTATION_RECORD_CREATE_MODE_ID = 0;
