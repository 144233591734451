import { ACTION_TYPES } from "./consts";

const initialState = {
  betaFeatures: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.getBetaFeatures: {
      return {
        betaFeatures: action.payload || [],
      };
    }
    case ACTION_TYPES.setBetaFeatures: {
      const modifiedState = state.betaFeatures.map((feature) => {
        if (feature.label === action.payload) {
          return {
            ...feature,
            toggled: feature.toggled === "on" ? "off" : "on",
          };
        }
        return feature;
      });
      return {
        betaFeatures: modifiedState,
      };
    }
    default: {
      return state;
    }
  }
}
