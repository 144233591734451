import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    firstName: yup.string().required(),
    lastName: yup.string().nullable(),
    fullName: yup.string().required(),
    email: yup.string().nullable(),
    phone: yup.string().nullable(),
    imageUrl: yup.string().nullable(),
    city: yup.string().nullable(),
    country: yup.string().nullable(),
    roleId: yup.number().required(),
    accountId: yup.number().required(),
    isAvailableOnline: yup.bool().required(),
    isConsentRequired: yup.bool().required(),
    isProvider: yup.bool().required(),
    createdAt: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  const users = unwrapOr(() => res.data.data, []);
  return users.map((u) => ({
    id: u.id,
    firstName: u.firstname,
    lastName: u.lastname || null,
    fullName: u.full_name,
    email: u.email_id || u.email_id_2 || u.email_id_3 || null,
    phone:
      u.contact_number_1 ||
      u.contact_number_2 ||
      u.contact_number_3 ||
      u.contact_number_4 ||
      null,
    imageUrl: u.user_image_url || null,
    city: u.city || null,
    country: u.country || null,
    roleId: u.user_role_id,
    accountId: u.account_id,
    isAvailableOnline: Boolean(u.is_available_online),
    isConsentRequired: Boolean(u.is_md_consent_required),
    isProvider: Boolean(u.is_provider),
    createdAt: u.created,
  }));
};

function createAllMdsQueryKey() {
  return [QUERY_KEYS.allMds];
}

export function useAllMdsQuery({ options = {} } = {}) {
  return useQuery({
    queryKey: createAllMdsQueryKey(),
    queryFn: async () => {
      const res = await http.get(HTTP_ENDPOINTS.getAllMds());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    ...options,
  });
}
