import * as yup from "yup";
import { SETTING_VIEW_NAMES } from "../../../../../../api/patientVitals/config";
import { usePatientVitalsMutation } from "../../../../../../api/patientVitals/usePatientVitalsMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { patientVitals } from "../../../../../../store/patients/vitals";
import {
  MUTATION_RECORD_CREATE_MODE_ID,
  MUTATION_RECORD_FIELDS,
} from "../../../../../../store/patients/vitals/config";
import { dispatch } from "../../../../../../store/store";
import { useSettingViews } from "../../../hooks/useSettingViews";
import { cond, identity, pipe } from "../../../../../../utilities/fp";
import { getPathParams } from "../../../PatientVitals.utils";

const { actions } = patientVitals;

export const useSave = () => {
  const { tClients } = useAppTranslation.Clients();
  const { patientId } = getPathParams();

  const settingViews = useSettingViews();
  const { mutateAsync, isLoading } = usePatientVitalsMutation();

  const mutationRecordFieldToRequestPayloadFieldMap = {
    [MUTATION_RECORD_FIELDS.height]: "height",

    [MUTATION_RECORD_FIELDS.weight]: "weight",

    [MUTATION_RECORD_FIELDS.bmi]: "bmi",

    [MUTATION_RECORD_FIELDS.bodyFat]: "body_fat",

    [MUTATION_RECORD_FIELDS.pulse]: "pulse",

    [MUTATION_RECORD_FIELDS.respiration]: "respiration",

    [MUTATION_RECORD_FIELDS.bloodPressureSystolic]: "blood_pressure_systolic",

    [MUTATION_RECORD_FIELDS.bloodPressureDiastolic]: "blood_pressure_diastolic",

    [MUTATION_RECORD_FIELDS.temperature]: "temperature",
  };

  const settingViewToValidationSchemaMap = {
    [SETTING_VIEW_NAMES.height]: yup.object({
      [MUTATION_RECORD_FIELDS.height]: yup
        .string()
        .required(tClients("vitals.saveValidationErrors.heightRequired")),
    }),

    [SETTING_VIEW_NAMES.weight]: yup.object({
      [MUTATION_RECORD_FIELDS.weight]: yup
        .number()
        .min(1, tClients("vitals.saveValidationErrors.weightMin", { min: 1 }))
        .required(tClients("vitals.saveValidationErrors.weightRequired")),
    }),

    [SETTING_VIEW_NAMES.bmi]: yup.object({
      [MUTATION_RECORD_FIELDS.bmi]: yup
        .number()
        .min(0, tClients("vitals.saveValidationErrors.bmiMin", { min: 0 }))
        .required(tClients("vitals.saveValidationErrors.bmiRequired")),
    }),

    [SETTING_VIEW_NAMES.bodyFat]: yup.object({
      [MUTATION_RECORD_FIELDS.bodyFat]: yup
        .number()
        .min(0, tClients("vitals.saveValidationErrors.bodyFatMin", { min: 0 }))
        .max(
          100,
          tClients("vitals.saveValidationErrors.bodyFatMax", { max: 100 }),
        )
        .required(tClients("vitals.saveValidationErrors.bodyFatRequired")),
    }),

    [SETTING_VIEW_NAMES.pulse]: yup.object({
      [MUTATION_RECORD_FIELDS.pulse]: yup
        .number()
        .integer(tClients("vitals.saveValidationErrors.pulseInteger"))
        .min(0, tClients("vitals.saveValidationErrors.pulseMin", { min: 0 }))
        .required(tClients("vitals.saveValidationErrors.pulseRequired")),
    }),

    [SETTING_VIEW_NAMES.respiration]: yup.object({
      [MUTATION_RECORD_FIELDS.respiration]: yup
        .number()
        .integer(tClients("vitals.saveValidationErrors.respirationInteger"))
        .min(
          0,
          tClients("vitals.saveValidationErrors.respirationMin", { min: 0 }),
        )
        .required(tClients("vitals.saveValidationErrors.respirationRequired")),
    }),

    [SETTING_VIEW_NAMES.bloodPressure]: yup.object({
      [MUTATION_RECORD_FIELDS.bloodPressureSystolic]: yup
        .number()
        .integer(
          tClients("vitals.saveValidationErrors.bloodPressureSystolicInteger"),
        )
        .min(
          0,
          tClients("vitals.saveValidationErrors.bloodPressureSystolicMin", {
            min: 0,
          }),
        )
        .required(
          tClients("vitals.saveValidationErrors.bloodPressureSystolicRequired"),
        ),
      [MUTATION_RECORD_FIELDS.bloodPressureDiastolic]: yup
        .number()
        .integer(
          tClients("vitals.saveValidationErrors.bloodPressureDiastolicInteger"),
        )
        .min(
          0,
          tClients("vitals.saveValidationErrors.bloodPressureDiastolicMin", {
            min: 0,
          }),
        )
        .required(
          tClients(
            "vitals.saveValidationErrors.bloodPressureDiastolicRequired",
          ),
        ),
    }),

    [SETTING_VIEW_NAMES.temperature]: yup.object({
      [MUTATION_RECORD_FIELDS.temperature]: yup
        .number()
        .min(
          0,
          tClients("vitals.saveValidationErrors.temperatureMin", { min: 0 }),
        )
        .required(tClients("vitals.saveValidationErrors.temperatureRequired")),
    }),
  };

  const staticSchema = yup.object({
    date: yup.string().required(),
    notes: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          note: yup.string().required(),
        }),
      )
      .nullable(),
  });

  const initiate = async (mutationRecord) => {
    try {
      const dynamicFields = settingViews.data;
      if (dynamicFields) {
        dispatch(actions.clearMutationRecordFieldErrors());

        const validationSchema = dynamicFields.reduce(
          (schema, dynamicField) =>
            schema.concat(settingViewToValidationSchemaMap[dynamicField]),
          staticSchema,
        );

        const validRecord = await validationSchema.validate(mutationRecord, {
          abortEarly: false,
        });

        const payload = Object.entries(validRecord).reduce(
          (carry, [key, value]) => {
            const payloadKey = mutationRecordFieldToRequestPayloadFieldMap[key];

            if (payloadKey) {
              return {
                ...carry,
                [payloadKey]: value,
              };
            }

            return carry;
          },
          {
            id: pipe(
              validRecord[MUTATION_RECORD_FIELDS.id],
              cond(identity, [
                (v) => v === MUTATION_RECORD_CREATE_MODE_ID,
                () => undefined,
              ]),
            ),
            vital_date: validRecord[MUTATION_RECORD_FIELDS.date],
            patient_id: patientId,
            notes: pipe(
              validRecord[MUTATION_RECORD_FIELDS.id],
              cond(
                () => null,
                [
                  (v) => v === MUTATION_RECORD_CREATE_MODE_ID,
                  () =>
                    validRecord[MUTATION_RECORD_FIELDS.notes]?.map(
                      (n) => n.note,
                    ),
                ],
              ),
            ),
          },
        );

        await mutateAsync(payload);

        dispatch(actions.dropMutationRecord());
        uiNotification.success(tClients("vitals.success.save"));
      }
    } catch (e) {
      if (e.inner) {
        const fieldErrors = e.inner.reduce(
          (c, e) => ({
            ...c,
            [e.path]: e.errors?.[0],
          }),
          {},
        );
        dispatch(actions.appendMutationRecordFieldErrors(fieldErrors));
      } else {
        uiNotification.error(tClients("vitals.error.save"));
      }
    }
  };

  return {
    initiate,
    isLoading,
  };
};
