import React, { useState } from "react";
import cx from "clsx";
import { Trans } from "react-i18next";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import styles from "./styles.module.scss";
import { useIntegration } from "./hooks/useIntegration";
import { Form } from "./shared/Form/Form";
import { useForm } from "./hooks/useForm";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { Box } from "../../../../shared/Box/Box";
import { submitAdapter } from "./utilities";
import { getIsEfaxIntegrationAccessible } from "../../../../helpers/integrations";
import UpgradeAccountModal from "../../../../Components/Common/UpgradeAccountModal";

export const Efax = () => {
  const { tSettings } = useAppTranslation.Settings();
  const { status, enable, disable, packages } = useIntegration();
  const [showUpgradeModal, setShowUpgradeModal] = useState(
    !getIsEfaxIntegrationAccessible(),
  );

  const form = useForm({
    onSubmit: (formValues) => {
      enable.initiate(submitAdapter(formValues));
    },
    initialValues: {
      isEnabled: status.isEnabled,
      faxNumber: status.faxNumber,
      package: 0,
    },
  });

  const isToggleLoading = status.isLoading || disable.isLoading;
  const isToggleDisabled =
    isToggleLoading || enable.isLoading || !getIsEfaxIntegrationAccessible();

  return (
    <LayoutSettings contentClassName={styles.root}>
      <EntityHeader
        title={tSettings("efaxIntegration.title")}
        right={
          <div
            className={cx(
              styles.toggleWrap,
              isToggleDisabled && "cursor-not-allowed",
            )}
          >
            {isToggleLoading && <CircularProgress size="small" color="gray1" />}
            <Toggle
              isEnabled={form.isEnabled.value}
              onChange={form.isEnabled.toggle}
              isDisabled={isToggleDisabled}
            />
          </div>
        }
      />
      {form.isEnabled.value && (
        <Box>
          <div className={styles.content}>
            <Form
              onSubmit={form.submit}
              isSubmitting={enable.isLoading}
              isIntegrationEnabled={status.isEnabled}
              packageVal={form.package}
              faxNumber={form.faxNumber}
              packages={packages}
            />
          </div>
        </Box>
      )}
      {form.isDisableConfirmOpen.value && (
        <ConfirmModal
          isOpen
          isConfirming={disable.isLoading}
          onClose={form.isDisableConfirmOpen.close}
          onCancel={form.isDisableConfirmOpen.close}
          onConfirm={() =>
            disable.initiate().then(form.isDisableConfirmOpen.close)
          }
        >
          <Trans i18nKey={tSettings("efaxIntegration.confirm.disable")} />
        </ConfirmModal>
      )}
      {!getIsEfaxIntegrationAccessible() && (
        <UpgradeAccountModal
          notificaionType={"integration"}
          isShowUpgradeAlertModal={showUpgradeModal}
          handleShowUpgradeAlertModal={() =>
            setShowUpgradeModal(!showUpgradeModal)
          }
        />
      )}
    </LayoutSettings>
  );
};
