import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getMembershipPaymentDetails(data) {
  return (dispatch) => {
    dispatch({ type: "GET_MEMBERSHIP_PAYMENT_DETAILS_LOADING" });
    http
      .post("get-membership-details", data)
      .then((response) => {
        dispatch({
          type: "GET_MEMBERSHIP_PAYMENT_DETAILS_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_PAYMENT_DETAILS_ERROR",
          payload: error.response.data,
        });
      });
  };
}

export function getMembershipPaymentDetailsWithCoupon(clientId, data) {
  return (dispatch) => {
    dispatch({ type: "GET_MEMBERSHIP_PAYMENT_DETAILS_LOADING" });
    http
      .post(`validate-coupon-code/${clientId}`, data)
      .then((response) => {
        dispatch({
          type: "GET_MEMBERSHIP_PAYMENT_DETAILS_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_PAYMENT_DETAILS_ERROR",
          payload: error.response.data,
        });
      });
  };
}

export function resetMembershipDetailsData() {
  return (dispatch) => {
    dispatch({ type: "RESET_MEMBERSHIP_PAYMENT_DETAILS" });
  };
}
