import { useMutation, useQuery } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";

export const useSubmitFeedback = (options = {}) => {
  return useMutation(
    (dto) =>
      http.post(HTTP_ENDPOINTS.betaProgram.submitFeedback(dto.id), {
        feedback: dto.feedback,
      }),
    options,
  );
};
