import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

function createClinicsByProviderIdQueryKey({ providerId }) {
  return [QUERY_KEYS.clinicsByProviderId, providerId];
}

export function useClinicsByProviderIdQuery({ providerId }, options = {}) {
  return useQuery(
    createClinicsByProviderIdQueryKey({ providerId }),
    () => http.get(HTTP_ENDPOINTS.getClinicsByProviderId(providerId)),
    options,
  );
}
