import { usePatientVitalNotesMutation } from "../../../../../../../../api/patientVitals/usePatientVitalNotesMutation";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { patientVitals } from "../../../../../../../../store/patients/vitals";
import { dispatch } from "../../../../../../../../store/store";

const { actions } = patientVitals;

export const useSaveNote = (vitalId) => {
  const { tClients } = useAppTranslation.Clients();

  const { mutateAsync, isLoading } = usePatientVitalNotesMutation({
    options: {
      onError: () => {
        uiNotification.error(tClients("vitals.error.saveNote"));
      },
      onSuccess: () => {
        uiNotification.success(tClients("vitals.success.saveNote"));
      },
    },
  });

  const initiate = ({ id, note }) => {
    if (vitalId) {
      return mutateAsync({ id, vitalId, note });
    }
    return new Promise((res) => {
      dispatch(actions.updateMutationRecordNote({ id, note }));
      res();
    });
  };

  return {
    initiate,
    isLoading,
  };
};
