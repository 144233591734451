import React from "react";
import classes from "./Question.module.scss";
import { AnswerChoices } from "../../shared/AnswerChoices/AnswerChoices";
import { MULTI_QUESTION_TYPES } from "../../../../../../api/questionnaires/config";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Footer } from "../../shared/Footer/Footer";
import { AnswerText } from "../../shared/AnswerText/AnswerText";
import { AnswerScale } from "../../shared/AnswerScale/AnswerScale";
import { AnswerFile } from "../../shared/AnswerFile/AnswerFile";
import { useYesNoOptions } from "../../../../hooks/useYesNoOptions";

export function Question({
  question,
  answer,
  onNext,
  onPrev,
  onFinishLater,
  updateAnswer,
  canGoNext,
  canGoPrev,
  isSubmitting,
  isQuestionnaireFilled,
}) {
  const { tCommon } = useAppTranslation.Common();
  const yesNoOptions = useYesNoOptions();

  const renderFooter = (onSubmit, isQuestionNotFilled, onFinishLater) => (
    <Footer
      onPrev={onPrev}
      onNext={onSubmit}
      onFinishLater={onFinishLater}
      isSubmitVisible={!canGoNext}
      isPrevDisabled={!canGoPrev}
      isSubmitting={isSubmitting}
      isQuestionNotFilled={isQuestionNotFilled}
      isQuestionnaireFilled={isQuestionnaireFilled}
    />
  );

  const submitAnswer = (answerValuesUpdater, options = {}) => {
    const nextAnswers = answerValuesUpdater();
    if (options.saveForLater) {
      onFinishLater(nextAnswers);
    } else {
      onNext(nextAnswers);
    }
  };

  const answerComponentsMap = {
    [MULTI_QUESTION_TYPES.yesNo]: (
      <AnswerChoices
        isMulti={false}
        isRequired={question.isRequired}
        initialValues={{
          choices: answer?.choices,
          reason: answer?.reason,
        }}
        options={yesNoOptions}
        renderFooter={renderFooter}
        onSubmit={({ choices, reason }, options) => {
          submitAnswer(() => updateAnswer({ choices, reason }), options);
        }}
      />
    ),
    [MULTI_QUESTION_TYPES.textBox]: (
      <AnswerText
        isRequired={question.isRequired}
        initialValues={{
          text: answer?.choices?.[0],
        }}
        renderFooter={renderFooter}
        onSubmit={({ text }, options) => {
          submitAnswer(() => updateAnswer({ choices: [text] }), options);
        }}
      />
    ),
    [MULTI_QUESTION_TYPES.singleChoice]: (
      <AnswerChoices
        isMulti={false}
        isRequired={question.isRequired}
        initialValues={{
          choices: answer?.choices,
        }}
        renderFooter={renderFooter}
        options={question.choices?.map((choice) => ({
          value: choice.text,
          slot: choice.text,
        }))}
        onSubmit={({ choices }, options) => {
          submitAnswer(() => updateAnswer({ choices }), options);
        }}
      />
    ),
    [MULTI_QUESTION_TYPES.multiChoice]: (
      <AnswerChoices
        isMulti
        isRequired={question.isRequired}
        initialValues={{
          choices: answer?.choices,
        }}
        renderFooter={renderFooter}
        options={question.choices?.map((choice) => ({
          value: choice.text,
          slot: choice.text,
        }))}
        onSubmit={({ choices }, options) => {
          submitAnswer(() => updateAnswer({ choices }), options);
        }}
      />
    ),
    [MULTI_QUESTION_TYPES.opinionScale]: (
      <AnswerScale
        withReason={question.isCommentsOnScaleAllowed}
        isRequired={question.isRequired}
        initialValues={{
          choice: answer?.choices?.[0],
          reason: answer?.reason,
        }}
        renderFooter={renderFooter}
        options={question.choices?.map((c) => ({
          label: c.text,
          value: c.text,
        }))}
        onSubmit={({ choice, reason }, options) => {
          submitAnswer(
            () =>
              updateAnswer({
                choices: [choice],
                reason,
              }),
            options,
          );
        }}
      />
    ),
    [MULTI_QUESTION_TYPES.fileUpload]: (
      <AnswerFile
        isRequired={question.isRequired}
        initialValues={{
          file: answer?.choices?.[0],
        }}
        renderFooter={renderFooter}
        onSubmit={({ file }, options) => {
          submitAnswer(() => updateAnswer({ choices: [file] }), options);
        }}
      />
    ),
  };

  const answerComponent = answerComponentsMap?.[question.type];

  return (
    <div className={classes.root}>
      {answerComponent ? (
        <>
          <div>
            <h3 className={classes.question}>
              {question.question}
              {question.isRequired && <span>*</span>}
            </h3>
            {question.isDescriptionVisible && question.description && (
              <p className={classes.description}>{question.description}</p>
            )}
          </div>
          {answerComponent}
        </>
      ) : (
        <AlertBox>
          {tCommon("fillPatientQuestionnaire.error.noQuestion")}
        </AlertBox>
      )}
    </div>
  );
}
