import { useCreateAppointmentNotificationMutation } from "../../../api/mutations/useCreateAppointmentNotificationMutation";
import { uiNotification } from "../../../services/UINotificationService";

export function useCreateAppointmentNotification() {
  const { mutateAsync, isLoading } = useCreateAppointmentNotificationMutation({
    onSuccess: () => {
      uiNotification.success("Notification sent successfully");
    },
    onError: () => {
      uiNotification.error("Unable to send notification. Try again later");
    },
  });

  return {
    createAppointmentNotification: mutateAsync,
    isAppointmentNotificationCreating: isLoading,
  };
}
