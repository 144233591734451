import { useEffect } from "react";
import { dispatch } from "../../../../../../../store/store";
import { preparePrices } from "../../../../../../../store/inventory/productPricingRegular/utilities";
import { productPricingRegular } from "../../../../../../../store/inventory/productPricingRegular";

const { actions } = productPricingRegular;

export function useInit(initData, isInitialized) {
  const { clinics, product } = initData || {
    clinics: [],
    product: {},
  };

  useEffect(() => {
    if (!isInitialized) {
      const prices = [...(product?.product_price_per_clinic || [])];
      prices.reverse();

      dispatch(
        actions.setForAllClinics(Boolean(product?.price_same_for_all_clinic)),
      );

      dispatch(
        actions.setPrices(
          preparePrices({
            clinics: clinics || [],
            rawPrices: prices,
          }),
          prices,
        ),
      );

      dispatch(
        actions.setClinics(
          clinics.map((c) => ({
            id: c.id,
            name: c.clinic_name,
          })),
        ),
      );

      dispatch(actions.setInitialized(true));
    }
  }, [clinics, product?.product_price_per_clinic, isInitialized]);

  return { clinics };
}
