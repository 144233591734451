/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

export default class AlertModal extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      alertAction: props.alertAction ? props.alertAction : "",
      alertMsg: props.alertMsg ? props.alertMsg : "",
      showAlertModal: props.showAlertModal ? props.showAlertModal : false,
    };
  }

  static getDerivedStateFromProps() {
    let returnState = {};
    return returnState;
  }

  alertAction = () => {
    if (this.props.alertAction) {
      this.props.alertAction();
      this.resetAlert();
    } else {
      this.resetAlert();
    }
  };

  resetAlert = () => {
    this.props.onResetAlert({
      alertAction: "",
      alertMsg: "",
      showAlertModal: false,
    });
  };

  render() {
    return (
      <div className="alert-modal-wrapper">
        <div
          className={this.state.showAlertModal ? "modalOverlay" : "no-display"}
        >
          <div className="small-popup-outer">
            <div className="small-popup-header">
              <div className="popup-name">
                {this.state.globalLang.label_alert}
              </div>
              <a
                className="small-cross"
                data-dismiss="modal"
                onClick={() => this.resetAlert()}
              >
                ×
              </a>
            </div>
            <div className="small-popup-content">
              <div className="juvly-container">
                <div className="row">
                  <div className="col-sm-12 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="new-field-label font-size-12 alert-modal-title">
                        {this.state.globalLang.are_you_sure}
                      </div>
                      <div className="new-field-label font-size-12 alert-modal-content">
                        {this.props.alertMsg ? this.props.alertMsg : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-static">
              <a
                className="new-white-btn pull-right"
                data-dismiss="modal"
                onClick={() => this.resetAlert()}
              >
                {this.props.alertBtnCancelLabel
                  ? this.props.alertBtnCancelLabel
                  : this.state.globalLang.label_cancel}
              </a>
              <a
                className="new-red-btn pull-left"
                data-dismiss="modal"
                onClick={this.alertAction}
              >
                {this.props.alertBtnActionLabel
                  ? this.props.alertBtnActionLabel
                  : this.state.globalLang.label_yes}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
