import React from "react";
import PropTypes from "prop-types";
import classes from "./StageGrid.module.scss";

export function StageGrid({ children }) {
  return (
    <div className={classes.root}>
      {children.filter(Boolean).map((c, i) => {
        if (i === 1) {
          return (
            <div key={i} style={{ gridRowStart: `span ${children.length}` }}>
              {c}
            </div>
          );
        }
        return c;
      })}
    </div>
  );
}

StageGrid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
