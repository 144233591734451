import { useSetUserPasswordMutation } from "../../../../api/mutations/useSetUserPasswordMutation";
import { extractApiError } from "../../../../utilities/api";
import { Exception } from "../../../../utilities/general";

const ERROR_FALLBACK = "Unable To Set Password";

export function useApiSetPasswd() {
  const { mutateAsync, isLoading } = useSetUserPasswordMutation();

  const initiate = async ({ password, wpuId }) => {
    try {
      if (password && wpuId) {
        await mutateAsync({ password, wpuId });
      } else {
        throw new Exception(ERROR_FALLBACK);
      }
    } catch (e) {
      if (e instanceof Exception) {
        throw new Error(e.message);
      } else {
        throw new Error(extractApiError(e) || ERROR_FALLBACK);
      }
    }
  };

  return {
    initiate,
    isLoading,
  };
}
