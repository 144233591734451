import React from "react";
import { useCurrentUserQuery } from "../api/queries/useUserQuery";

export function usePermission(permission) {
  const { data: userData, isSuccess: isUserSettled } = useCurrentUserQuery();

  return {
    loaded: isUserSettled,
    permitted: isUserSettled && userData.privileges.includes(permission),
  };
}

export const withPermission = (permissionName, name) => (Component) =>
  function Wrapper(props) {
    const permission = usePermission(permissionName);

    const nextProps = {
      ...props,
      [name]: permission,
    };

    return <Component {...nextProps} />;
  };
