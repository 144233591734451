import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

export function useExportClientPdfMutation({
  onError,
  onSettled,
  onMutate,
  onSuccess,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return http.post(HTTP_ENDPOINTS.patient.exportClientPdf(), {
        patient_id: req?.clientId,
      });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.client],
      });
      onSuccess?.(response);
    },
    onMutate: () => {
      onMutate?.();
    },
    onError,
    onSettled: () => {
      onSettled?.();
    },
  });
}
