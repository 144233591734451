import React from "react";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { usePatient } from "./hooks/usePatient";
import { PageHeader } from "../../../../../boxes/PageHeader/PageHeader";

export function Header({ children, clientId }) {
  const patient = usePatient(clientId);

  const getTitle = () => {
    const patientName = patient.data?.fullName || "";
    return `${patientName} ${patientName ? "-" : ""} ${children}`;
  };

  return (
    <PageHeader
      title={getTitle()}
      titleRightAdornment={
        patient.isLoading ? (
          <CircularProgress size="tiny" color="gray1" />
        ) : undefined
      }
    />
  );
}
