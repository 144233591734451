import React, { Component } from "react";
import { formatBillingCurrency } from "../YourBilling.utils.js";

class SubscriptionMonthlyEstimate extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      isShowEstimatedUser: false,
      isShowEstimatedSms: false,
      isShowEstimatedEmail: false,
      isShowEstimatedData: false,
      isShowPosConnectFeeData: false,
      estimatedData: {},
      userChanged: false
    };
  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.estimatedData != undefined && nextProps.estimatedData !== prevState.estimatedData) {
      returnState.estimatedData = nextProps.estimatedData;
      returnState.isShowEstimatedUser = false;
      returnState.isShowEstimatedSms = false;
      returnState.isShowEstimatedEmail = false;
      returnState.isShowEstimatedData = false;
      returnState.isShowPosConnectFeeData = false;
    }
    return returnState;
  }


  toggleState = (type) => {
    this.setState({ [type]: !this.state[type], userChanged: true });
  }


  render() {
    return (
      <div className="plan-estimate-block">
        <div className="profile accordion-row">
          <div className="accordian-section col-xs-6 text-left inner-box bold" onClick={this.toggleState.bind(this, 'isShowEstimatedUser')}>
            <a className={(this.state.estimatedData.user_array.length > 0 && this.state.isShowEstimatedUser) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-up" /></a>
            <a className={(this.state.estimatedData.user_array.length > 0 && !this.state.isShowEstimatedUser) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-down" /></a>
            {this.state.settingsLang.subscription_your_monthly_subscription_for} {this.state.estimatedData.user_array.length} {this.state.settingsLang.subscription_users}
          </div>
          <div className="accordian-section col-xs-6 text-right bold">{(this.state.estimatedData.user_sum <= 0) ? '$0.00' : formatBillingCurrency(this.state.estimatedData.user_sum)}</div>
        </div>

          {this.state.isShowEstimatedUser && this.state.estimatedData.user_array.map((userObj, userIndx) => {
            return (
              <div key={'monthly-users-' + userIndx} className="accordion-row profile" style={{ display: 'table-row' }}>
                <div className="accordian-section col-xs-6 text-left dash-icon p-l-30">{userObj.name}</div>
                <div className="accordian-section col-xs-6 text-right">{(userObj.amount <= 0) ? '$0.00' : formatBillingCurrency(userObj.amount)}</div>
              </div>
            )
          })
          }
          {(this.state.estimatedData.addon_array) &&
            <React.Fragment>
              {(this.state.estimatedData.addon_array.email && this.state.estimatedData.addon_array.email.total_additional_cost > 0) &&
                <React.Fragment>
                <div className="accordion-row profile">
                  <div className="accordian-section col-xs-6 text-left inner-box bold" onClick={this.toggleState.bind(this, 'isShowEstimatedEmailModal')}>
                    <a className={(this.state.estimatedData.addon_array.email.total_additional_units > 0 && this.state.isShowEstimatedEmailModal) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-up" /></a>
                    <a className={(this.state.estimatedData.addon_array.email.total_additional_units > 0 && !this.state.isShowEstimatedEmailModal) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-down" /></a>
                    {(this.state.settingsLang.subscription_email_charges_for_additional_purchase) ? this.state.settingsLang.subscription_email_charges_for_additional_purchase.replace(/{EMAIL}/g, `${(this.state.estimatedData.addon_array.email.total_additional_units) ? this.state.estimatedData.addon_array.email.total_additional_units : 0}`) : ''}
                  </div>
                  <div className="accordian-section col-xs-6 text-right bold">{(this.state.estimatedData.addon_array.email.total_additional_cost <= 0) ? '$0.00' : formatBillingCurrency(this.state.estimatedData.addon_array.email.total_additional_cost)}</div>
                </div>
                {this.state.isShowEstimatedEmailModal && this.state.estimatedData.addon_array.email.additional_units_detail.map((emailObj, emailIndx) => {
                  return (
                    <div key={'monthly-email-' + emailIndx} className="accordion-row profile" style={{ display: 'table-row' }}>
                      <div className="accordian-section col-xs-6 text-left dash-icon p-l-30">{emailObj.detail}</div>
                      <div className="accordian-section col-xs-6 text-right">{(emailObj.amount <= 0) ? '$0.00' : formatBillingCurrency(emailObj.amount)}</div>
                    </div>
                  )
                })
                }
              </React.Fragment>
            }
              {(this.state.estimatedData.addon_array.sms && this.state.estimatedData.addon_array.sms.total_additional_cost > 0) &&
                <React.Fragment>
                <div className="accordion-row profile">
                  <div className="accordian-section col-xs-6 text-left inner-box bold" onClick={this.toggleState.bind(this, 'isShowEstimatedSmsModal')}>
                    <a className={(this.state.estimatedData.addon_array.sms.total_additional_units > 0 && this.state.isShowEstimatedSmsModal) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-up" /></a>
                    <a className={(this.state.estimatedData.addon_array.sms.total_additional_units > 0 && !this.state.isShowEstimatedSmsModal) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-down" /></a>
                    {(this.state.settingsLang.subscription_sms_charges_for_additional_purchase) ? this.state.settingsLang.subscription_sms_charges_for_additional_purchase.replace(/{SMS}/g, `${(this.state.estimatedData.addon_array.sms.total_additional_units) ? this.state.estimatedData.addon_array.sms.total_additional_units : 0}`) : ''}
                  </div>
                  <div className="accordian-section col-xs-6 text-right bold">{(this.state.estimatedData.addon_array.sms.total_additional_cost <= 0) ? '$0.00' : formatBillingCurrency(this.state.estimatedData.addon_array.sms.total_additional_cost)}</div>
                </div>
                {this.state.isShowEstimatedSmsModal && this.state.estimatedData.addon_array.sms.additional_units_detail.map((smsObj, smsIndx) => {
                  return (
                    <div key={'monthly-sms-' + smsIndx} className="accordion-row profile" style={{ display: 'table-row' }}>
                      <div className="accordian-section col-xs-6 text-left dash-icon p-l-30">{smsObj.detail}</div>
                      <div className="accordian-section col-xs-6 text-right">{(smsObj.amount <= 0) ? '$0.00' : formatBillingCurrency(smsObj.amount)}</div>
                    </div>
                  )
                })
                }
              </React.Fragment>
            }

              {(this.state.estimatedData.addon_array.storage && this.state.estimatedData.addon_array.storage.total_additional_cost > 0) &&
                <React.Fragment>
                <div className="accordion-row profile">
                  <div className="accordian-section col-xs-6 text-left inner-box bold" onClick={this.toggleState.bind(this, 'isShowEstimatedDataModal')}>
                    <a className={(this.state.estimatedData.addon_array.storage.total_additional_units > 0 && this.state.isShowEstimatedDataModal) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-up" /></a>
                    <a className={(this.state.estimatedData.addon_array.storage.total_additional_units > 0 && !this.state.isShowEstimatedDataModal) ? 'toggle-angle' : 'toggle-angle no-display'}><i id="icon-1" className="fa dasdasd fa-angle-down" /></a>
                    {(this.state.settingsLang.subscription_storage_charges_for_additional_purchase) ? this.state.settingsLang.subscription_storage_charges_for_additional_purchase.replace(/{STORAGE}/g, `${(this.state.estimatedData.addon_array.storage.total_additional_units) ? this.state.estimatedData.addon_array.storage.total_additional_units : 0}`) : ''}                    
                    </div>
                  <div className="accordian-section col-xs-6 text-right bold">{(this.state.estimatedData.addon_array.storage.total_additional_cost <= 0) ? '$0.00' : formatBillingCurrency(this.state.estimatedData.addon_array.storage.total_additional_cost)}</div>
                </div>
                {this.state.isShowEstimatedDataModal && this.state.estimatedData.addon_array.storage.additional_units_detail.map((dataObj, dataIndx) => {
                  return (
                    <div key={'monthly-data-' + dataIndx} className="accordion-row profile" style={{ display: 'table-row' }}>
                      <div className="accordian-section col-xs-6 text-left dash-icon p-l-30">{dataObj.detail}</div>
                      <div className="accordian-section col-xs-6 text-right">{(dataObj.amount <= 0) ? '$0.00' : formatBillingCurrency(dataObj.amount)}</div>
                    </div>
                  )
                })
                }
              </React.Fragment>
            }

              {(this.state.estimatedData.addon_array.pos_connect_fee && this.state.estimatedData.addon_array.pos_connect_fee.additional_units_detail) &&
                <React.Fragment>
                  {this.state.estimatedData.addon_array.pos_connect_fee.additional_units_detail.map((emailObj, emailIndx) => {
                    return (
                      <div key={'monthly-pos_connect_fee-' + emailIndx} className="accordion-row profile" style={{ display: 'table-row' }}>
                        <div className="accordian-section col-xs-6 text-left dash-icon bold">{emailObj.detail}</div>
                        <div className="accordian-section col-xs-6 text-right bold">{(emailObj.amount <= 0) ? '$0.00' : formatBillingCurrency(emailObj.amount)}</div>
                      </div>
                    )
                  })
                  }
                </React.Fragment>
              }

            </React.Fragment>
          }
      </div>);
  }
}


export default (SubscriptionMonthlyEstimate)
