import React, { useState } from "react";
import { SortableElement } from "react-sortable-hoc";
import cx from "clsx";
import classes from "./QuestionTile.module.scss";
import { DragIcon } from "../../../../../../../shared/DragIcon/DragIcon";
import { Button } from "../../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { ConfirmModal } from "../../../../../../../boxes/ConfirmModal/ConfirmModal";
import { shortenString } from "../../../../../../../utilities/general";

export const QuestionTile = SortableElement(
  ({
    question,
    type,
    onEdit,
    onDelete,
    onDuplicate,
    isRequired,
    description,
    typeRightAdornment,
    isError,
  }) => {
    const { tSettings } = useAppTranslation.Settings();

    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
      useState(false);

    return (
      <>
        <div
          className={cx(classes.root, {
            [classes.rootError]: isError,
          })}
        >
          <div className={classes.left}>
            <DragIcon className={classes.dragIcon} />
            <div className={classes.questionWrap}>
              <div className={classes.typeWrap}>
                {type}
                {typeRightAdornment && (
                  <span className={classes.adornment}>
                    {typeRightAdornment}
                  </span>
                )}
              </div>
              <div className={classes.questionInner}>
                {isRequired && (
                  <span className={classes.requiredSymbol}>*</span>
                )}
                {question}
              </div>
              {description && (
                <div className={classes.desc}>
                  {shortenString(description, 200)}
                </div>
              )}
            </div>
          </div>
          <div className={classes.actions}>
            <Button size="small" variant="text" onClick={onEdit}>
              <i className="fa fa-pencil-alt" aria-hidden="true"></i>
            </Button>
            {onDuplicate && (
              <Button size="small" variant="text" onClick={onDuplicate}>
                <i className="fa fa-clone" aria-hidden="true"></i>
              </Button>
            )}
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={() => setIsConfirmDeleteModalOpen(true)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
        {isConfirmDeleteModalOpen && (
          <ConfirmModal
            isOpen
            onClose={() => setIsConfirmDeleteModalOpen(false)}
            onCancel={() => setIsConfirmDeleteModalOpen(false)}
            onConfirm={() => {
              onDelete();
              setIsConfirmDeleteModalOpen(false);
            }}
          >
            {tSettings("questionnaires.confirm.removeQuestion")}
          </ConfirmModal>
        )}
      </>
    );
  },
);
