import React, { useState } from "react";
import { useGuard } from "./hooks/useGuard";
import InsightsSideBar from "../../../InsightsSideBar";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { Header } from "./components/Header";
import { useReports } from "./hooks/useReports";
import { useDownloadExcel } from "./hooks/useDownloadExcel";
import { Table } from "./components/Table";
import { ModalFilter } from "./components/ModalFilter";
import { Skeleton } from "./components/Skeleton";

export function QualiphyPaidExamsReport() {
  const { isAvailable } = useGuard();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { reports, filter, order, period } = useReports();

  const downloadExcel = useDownloadExcel({
    period,
    filter: filter.preparedForRequest,
  });

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <InsightsSideBar />
        <div className="memberWalletOuter business-section">
          {isAvailable ? (
            <ReportLayout>
              <Header
                period={period}
                onFilterOpen={() => setIsFilterOpen(true)}
                downloadExcel={downloadExcel}
                isReportsLoading={reports.isLoading}
                isFilterActive={filter.isActive}
              />
              <Table reports={reports} order={order} />
              <ModalFilter
                isOpen={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
                filter={filter}
              />
            </ReportLayout>
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
    </div>
  );
}
