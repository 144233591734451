import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useDeleteNonSavedTreatmentPlanMutation } from "../../../../api/mutations/useDeleteNonSavedTreatmentPlanMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { unwrapOr } from "../../../../utilities/general";
import { checkIfPermissionAllowed } from "../../../../Utils";
import {
  PERMISSIONS,
  SUBSCRIPTION_STATUS,
  TREATMENT_PLAN_STATUS,
  USER_ROLE_NAMES,
} from "../../../../consts/api";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import cx from "clsx";

export function TreatmentPlanActions({
  plan,
  refetchClientData,
  onStartProgram,
  onCancelPlan,
  onEditPlan,
  onEditPlanAsProvider,
  onPrintPlan,
}) {
  const [
    isConfirmDeletePlanModalOpen,
    setIsConfirmDeletePlanModalOpen,
  ] = useState(false);

  // Api

  const { data: user } = useCurrentUserQuery();

  const {
    mutate: deletePlan,
    isLoading: isDeleting,
  } = useDeleteNonSavedTreatmentPlanMutation({
    onSuccess: refetchClientData,
    onError: () => {
      uiNotification.error("Unable To Delete Treatment Plan");
    },
  });

  // Handlers

  const handleDeletePlan = () => {
    setIsConfirmDeletePlanModalOpen(false);
    const planId = unwrapOr(() => plan.id, null);
    if (planId) {
      deletePlan({ planId });
    }
  };

  // Permission flags

  const isManageTreatmentPlanPermitted = checkIfPermissionAllowed(
    PERMISSIONS.patientManagement.manageTreatmentPlans,
  );

  const isStartProgramVisible = unwrapOr(
    () => !plan.treatment_plan_subscription,
    false,
  );

  const isCancelPlanVisible = unwrapOr(
    () =>
      plan.treatment_plan_subscription.subscription_status ===
      SUBSCRIPTION_STATUS.active,
    false,
  );

  const isEditPlanVisible = unwrapOr(
    () =>
      isManageTreatmentPlanPermitted &&
      (plan.status === TREATMENT_PLAN_STATUS.saved ||
        plan.status === TREATMENT_PLAN_STATUS.notSaved ||
        plan.status === TREATMENT_PLAN_STATUS.draft) &&
      !plan.treatment_plan_subscription,
    false,
  );

  const isEditPlanAsProviderVisible = unwrapOr(
    () =>
      isManageTreatmentPlanPermitted &&
      plan.treatment_plan_subscription.subscription_status ===
        SUBSCRIPTION_STATUS.active &&
      user.role === USER_ROLE_NAMES.provider,
    false,
  );

  const isPrintPlanVisible = Boolean(plan);

  const isDeletePlanVisible = unwrapOr(
    () =>
      isManageTreatmentPlanPermitted &&
      plan.status === TREATMENT_PLAN_STATUS.notSaved,
    false,
  );

  return (
    <span className="pull-right">
      {isStartProgramVisible && (
        <button
          type="button"
          className={cx("blue-btn pull-right", { disabled: isDeleting })}
          onClick={onStartProgram}
          disabled={isDeleting}
        >
          Start Program
        </button>
      )}
      {isCancelPlanVisible && (
        <button
          onClick={onCancelPlan}
          className={cx("line-btn pull-right", { disabled: isDeleting })}
          disabled={isDeleting}
        >
          Cancel Plan
        </button>
      )}
      {isEditPlanVisible && (
        <button
          type="button"
          onClick={onEditPlan}
          className={cx("line-btn pull-right", { disabled: isDeleting })}
          disabled={isDeleting}
        >
          Edit
        </button>
      )}
      {isEditPlanAsProviderVisible && (
        <button
          type="button"
          onClick={onEditPlanAsProvider}
          className={cx("line-btn pull-right", { disabled: isDeleting })}
          disabled={isDeleting}
        >
          Edit
        </button>
      )}
      {isPrintPlanVisible && (
        <button
          type="button"
          onClick={onPrintPlan}
          className={cx("line-btn pull-right", { disabled: isDeleting })}
          disabled={isDeleting}
        >
          Print
        </button>
      )}
      {isDeletePlanVisible && (
        <button
          type="button"
          className={cx("line-btn pull-right items-center", {
            disabled: isDeleting,
          })}
          onClick={() => setIsConfirmDeletePlanModalOpen(true)}
          disabled={isDeleting}
        >
          {isDeleting && <CircularProgress size="tiny" className="m-r-5" />}
          Delete
        </button>
      )}
      <ConfirmModal
        isOpen={isConfirmDeletePlanModalOpen}
        onCancel={() => setIsConfirmDeletePlanModalOpen(false)}
        onClose={() => setIsConfirmDeletePlanModalOpen(false)}
        onConfirm={handleDeletePlan}
      >
        Are you sure you want to delete this treatment plan?
      </ConfirmModal>
    </span>
  );
}

TreatmentPlanActions.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  refetchClientData: PropTypes.func,
  onStartProgram: PropTypes.func,
  onCancelPlan: PropTypes.func,
  onEditPlan: PropTypes.func,
  onEditPlanAsProvider: PropTypes.func,
  onPrintPlan: PropTypes.func,
};

TreatmentPlanActions.defaultProps = {
  refetchClientData: () => {},
  onStartProgram: () => {},
  onCancelPlan: () => {},
  onEditPlan: () => {},
  onEditPlanAsProvider: () => {},
  onPrintPlan: () => {},
};
