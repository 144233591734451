import React from "react";
import cx from "clsx";
import { tCommon } from "../../../../i18n/useAppTranslation";

export default function SmsAndEmailTabForConfigration({
  classes,
  isSelectEmail,
  handleEmailOrSms,
}) {
  return (
    <ul
      className={cx(
        classes.emailSmsTabs,
        "d-flex list-unstyled cursor-pointer",
      )}
    >
      <li>
        <span
          className={cx({ [classes.active]: !isSelectEmail })}
          onClick={() => handleEmailOrSms(false)}
        >
           {tCommon('label.emailLabel')}
        </span>
      </li>
      <li>
        <span
          className={cx({ [classes.active]: isSelectEmail })}
          onClick={() => handleEmailOrSms(true)}
        >
          {tCommon('label.TextMessage')}
        </span>
      </li>
    </ul>
  );
}
