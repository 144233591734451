import {
  getIsAcceleratorPlan,
  getIsAccountTypePaid,
  getIsPosEnabled,
  getTimeClockSetting,
} from "./services.js";

export const checkPrivilegeLevel0 = (level, params) => {
  let isPrivilegePass = false;

  if (level) {
    switch (level) {
      case "1":
        isPrivilegePass = checkPrivilegeLevel1(params);
        break;
      case "1_5":
        isPrivilegePass = checkPrivilegeLevel1_5(params);
        break;
      case "2":
        isPrivilegePass = checkPrivilegeLevel2(params);
        break;
      case "3":
        isPrivilegePass = checkPrivilegeLevel3(params);
        break;
      case "4":
        isPrivilegePass = checkPrivilegeLevel4(params);
        break;
      case "5":
        isPrivilegePass = checkPrivilegeLevel5(params);
        break;
      case "6":
        isPrivilegePass = checkPrivilegeLevel6(params);
        break;
      case "7":
        isPrivilegePass = checkPrivilegeLevel7(params);
        break;
      case "8":
        isPrivilegePass = checkPrivilegeLevel8(params);
        break;
      case "9":
        isPrivilegePass = checkPrivilegeLevel9(params);
        break;
      case "10":
        isPrivilegePass = checkPrivilegeLevel10(params);
        break;
      case "11":
        isPrivilegePass = checkPrivilegeLevel11(params);
        break;
      case "14":
        isPrivilegePass = checkPrivilegeLevel14(
          params?.user?.usertype,
          params?.userPriviliges,
        );
        break;
      default:
    }
  }

  return isPrivilegePass;
};

// Only for super admin
function checkPrivilegeLevel1(params) {
  let isPrivilegePass = false;
  const { user } = params;

  if (user && user.usertype === "superadmin") {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// Only for super admin or admins
function checkPrivilegeLevel1_5(params) {
  let isPrivilegePass = false;
  const { user } = params;

  if (user && (user.usertype === "superadmin" || user.user_role_id === 1)) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// Either superadmin or privilege
function checkPrivilegeLevel2(params) {
  let isPrivilegePass = false;
  const { routePrivileges, userPriviliges, user } = params;

  if (
    (user && user.usertype === "superadmin") ||
    userPriviliges.indexOf(routePrivileges[0]) > -1
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// Only for superadmin with paid account
function checkPrivilegeLevel3(params) {
  let isPrivilegePass = false;
  const { user } = params;

  if (
    user &&
    (user.usertype === "superadmin" || user.user_role_id === 1) &&
    getIsAccountTypePaid() === true
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// Either superadmin or privilege with paid account
function checkPrivilegeLevel4(params) {
  let isPrivilegePass = false;
  const { routePrivileges, userPriviliges, user } = params;

  if (
    ((user && user.usertype === "superadmin") ||
      userPriviliges.indexOf(routePrivileges[0]) > -1) &&
    getIsAccountTypePaid() === true
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// Only for superadmin with pos enable and paid account
function checkPrivilegeLevel5(params) {
  let isPrivilegePass = false;
  const { user } = params;

  if (
    user &&
    (user.usertype === "superadmin" || user.user_role_id === 1) &&
    getIsAccountTypePaid() === true &&
    getIsPosEnabled() === true
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// Either superadmin or privilege with pos enable and paid account
function checkPrivilegeLevel6(params) {
  let isPrivilegePass = false;
  const { routePrivileges, userPriviliges, user } = params;

  if (
    ((user && user.usertype === "superadmin") ||
      userPriviliges.indexOf(routePrivileges[0]) > -1) &&
    getIsAccountTypePaid() === true &&
    getIsPosEnabled() === true
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// Only for superadmin/admin with paid account
function checkPrivilegeLevel7(params) {
  let isPrivilegePass = false;
  const { user } = params;

  if (
    user &&
    (user.usertype === "superadmin" || user.user_role_id === 1) &&
    getIsAcceleratorPlan()
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// privilege with accelerator plan
function checkPrivilegeLevel8(params) {
  let isPrivilegePass = false;
  const { user, routePrivileges, userPriviliges } = params;

  if (
    user &&
    (user.usertype === "superadmin" ||
      userPriviliges.indexOf(routePrivileges[0]) > -1) &&
    getIsAcceleratorPlan() === true
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// privilege with accelerator plan and time clock settings
function checkPrivilegeLevel9(params) {
  let isPrivilegePass = false;
  const { user, routePrivileges, userPriviliges } = params;

  if (
    ((user && user.usertype === "superadmin") ||
      userPriviliges.indexOf(routePrivileges[0]) > -1) &&
    getIsAcceleratorPlan() === true &&
    getTimeClockSetting() === true
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// privilege with pos enabled
function checkPrivilegeLevel10(params) {
  let isPrivilegePass = false;
  const { user, routePrivileges, userPriviliges } = params;

  if (
    ((user && user.usertype === "superadmin") ||
      userPriviliges.indexOf(routePrivileges[0]) > -1) &&
    getIsPosEnabled() === true
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// privilege with either manage provider schedule enabled or manage appointment settings enabled
function checkPrivilegeLevel11(params) {
  let isPrivilegePass = false;
  const { userPriviliges } = params;

  if (
    userPriviliges.indexOf("manage-provider-schedules") > -1 ||
    userPriviliges.indexOf("manage-appointment-settings") > -1
  ) {
    isPrivilegePass = true;
  }

  return isPrivilegePass;
}

// privilege with view chart audit report only for admin and superadmin with permission
const checkPrivilegeLevel14 = (userType, userPriviliges) => {
  return (
    userType === "superadmin" ||
    userPriviliges?.indexOf("view-chart-audit") > -1
  );
};
