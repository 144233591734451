import { http } from "../../services/HttpService";

export function fetchVerticals(formData) {
  return (dispatch) => {
    http
      .get("verticals", formData)
      .then((response) => {
        dispatch({ type: "VERTICAL_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VERTICAL_LIST", payload: error.response.data });
      });
  };
}

export function createVertical(formData) {
  return (dispatch) => {
    http
      .post("verticals", formData)
      .then((response) => {
        dispatch({ type: "CREATE_VERTICAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_VERTICAL", payload: error.response.data });
      });
  };
}

export function updateVertical(verticalID, formData) {
  return (dispatch) => {
    http
      .put("verticals/" + verticalID, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_VERTICAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_VERTICAL", payload: error.response.data });
      });
  };
}

export function verticalEnableView(formData) {
  return (dispatch) => {
    http
      .post("verticals/enable-view", formData)
      .then((response) => {
        dispatch({ type: "VERTICAL_ENABLE_VIEW", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "VERTICAL_ENABLE_VIEW",
          payload: error.response.data,
        });
      });
  };
}

export function deleteVertical(verticalID) {
  return (dispatch) => {
    http
      .delete("verticals/" + verticalID)
      .then((response) => {
        dispatch({
          type: "DELETE_VERTICAL",
          payload: response.data,
          verticalID: verticalID,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_VERTICAL",
          payload: error.response.data,
          verticalID: verticalID,
        });
      });
  };
}
