import { useMemo, useState } from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { useExpiredLiability } from "./useExpiredLiability";

const prepareReqPeriod = (periodState) => {
  return {
    from: periodState.from || null,
    to: periodState.to || null,
  };
};

export function useReports() {
  const { tCommon } = useAppTranslation.Common();

  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  });

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useExpiredLiability({
    payload: {
      period: prepareReqPeriod(period),
      pageSize: 15,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  });

  const isPeriodActive = Object.values(period).some(Boolean);

  const isSoftLoading =
    isPeriodActive && isFetching && !isLoading && !isFetchingNextPage;

  const reports = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  return {
    reports: {
      total: data?.pages?.[0]?.stats?.total,
      value: reports,
      isLoading,
      isSoftLoading,
      hasMore: Boolean(hasNextPage),
      fetchMore: fetchNextPage,
      isNextFetching: isFetchingNextPage,
    },
    period: {
      value: period,
      update: setPeriod,
    },
  };
}
