import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useDeleteClientDocuments = (documentId, options = {}) => {
  return useMutation(
    () => http.delete(HTTP_ENDPOINTS.deleteClientDocument(documentId)),
    options,
  );
};
