import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useDeletePosBankMutation(options = {}) {
  return useMutation((dto) => {
    return http.post(HTTP_ENDPOINTS.postDeletePosBank(), {
      bank_account_id: dto.bankId,
    });
  }, options);
}
