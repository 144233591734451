import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  cardReaderId: yup.number().required(),
  accountId: yup.number().required(),
  clinicId: yup.number().required(),
  isDefault: yup.bool(),
  payWorksStatus: yup.string(),
  status: yup.string(),
});

export function usePatchCardReaderMutation(options = {}) {
  return useMutation((payload) => {
    const req = requestSchema.validateSync(payload, {
      strict: true,
    });
    return http.patch(
      HTTP_ENDPOINTS.patchCardReader(req.cardReaderId),
      removeNullishFromShape({
        account_id: req.accountId,
        clinic_id: req.clinicId,
        is_default: req.isDefault ? 1 : 0,
        payworks_status: req.payWorksStatus,
        status: req.status,
      }),
    );
  }, options);
}
