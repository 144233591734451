import { useMemo } from "react";

export const usePaymentMethodsList = (cardList) => {
  const linkList = useMemo(
    () => cardList?.filter((obj) => !obj.card.last4),
    [cardList],
  );
  const cardsList = useMemo(
    () => cardList?.filter((obj) => obj.card.last4),
    [cardList],
  );

  const haveLinkAndCardSaved = linkList?.length > 0 && cardsList?.length > 0;

  return {
    linkList,
    cardsList,
    haveLinkAndCardSaved,
  };
};
