import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import {
  HTTP_ENDPOINTS,
  QUERY_KEYS,
  QUESTIONNAIRE_TYPES,
} from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import { questionnaireSchema } from "./schemas";

const requestSchema = yup.object({
  type: yup.number().nullable(),
});

const responseSchema = yup.array().of(questionnaireSchema);

const composeResponse = (res) => {
  return res?.data?.data || [];
};

function createQuestionnairesQueryKey(type) {
  return [QUERY_KEYS.questionnaires.getAll, type];
}

const getRequestTypeParam = (type) => {
  switch (type) {
    case QUESTIONNAIRE_TYPES.simple:
      return "simple";
    case QUESTIONNAIRE_TYPES.multi:
      return "multi";
    default:
      return null;
  }
};

export function useQuestionnairesQuery({ payload = {}, options = {} } = {}) {
  return useQuery(
    createQuestionnairesQueryKey(payload.type),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.questionnaires.getAll(), {
        params: removeNullishFromShape({
          type: getRequestTypeParam(req.type),
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    options,
  );
}
