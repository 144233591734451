import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Message.module.scss";
import { CHAT_MESSAGE_TYPE } from "../../Chat.consts";
import { messageAuthorPropType } from "../../Chat.propTypes";
import { getAuthorInitials } from "../../Chat.utils";

export function Message({ type, children, time, author, className }) {
  const isSent = type === CHAT_MESSAGE_TYPE.sent;

  const rootClasses = cx(
    classes.root,
    {
      [classes.rootRight]: isSent,
    },
    className,
  );

  const tileClasses = cx(classes.tile, {
    [classes.tileRight]: isSent,
  });

  const imageStyles = author.imageUrl
    ? {
        background: `url(${author.imageUrl}) no-repeat center center / cover`,
      }
    : undefined;

  return (
    <div className={rootClasses}>
      <div className={tileClasses}>
        <div className={classes.image} style={imageStyles}>
          {!author.imageUrl ? getAuthorInitials(author.fullName) : ""}
        </div>
        <div className={classes.info}>
          <div className={classes.infoAuthor}>
            <span>{author.fullName},</span> {time}
          </div>
          <div className={classes.message}>{children}</div>
        </div>
      </div>
    </div>
  );
}

Message.propTypes = {
  type: PropTypes.oneOf(Object.values(CHAT_MESSAGE_TYPE)).isRequired,
  children: PropTypes.node.isRequired,
  time: PropTypes.string.isRequired,
  author: messageAuthorPropType.isRequired,
  className: PropTypes.string,
};

Message.defaultProps = {
  className: undefined,
};
