/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSelect from "../../Common/ReactSelect";
import {
  createVertical,
  updateVertical,
} from "../../../Actions/Verticals/verticalsAction";
import { getClientDetail } from "../../../Actions/Clients/clientsAction";
import { uiNotification } from "../../../services/UINotificationService";

class CreateEditVerticals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: true,
      showLoader: false,
      languageData: JSON.parse(localStorage.getItem("languageData")),
      form: {
        name: "",
        color: "",
        clinics_array: [],
        services_array: [],
      },
      verticalColors: [
        "#00008B",
        "#0000CD",
        "#8B0000",
        "#FF8C00",
        "#32CD32",
        "#808000",
        "#FF6F61",
        "#008080",
        "#8A2BE2",
        "#00BFFF",
        "#37C5AB",
        "#FF0000",
        "#1E90FF",
        "#FF00FF",
        "#E15D44",
        "#FC91AD",
        "#92A8D1",
        "#BDB76B",
        "#D2B48C",
        "#696969",
      ],
      message: "",
      closeModal: false,
      clinicValid: true,
      serviceValid: true,
      colorValid: true,
      nameValid: true,
      excludeVerticalClinicUrl: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let returnState = {};
    if (props.closeModal != undefined && props.closeModal == true) {
      returnState.closeModal = false;
      uiNotification.success(props.message);
      props.toggleVerticalModal(true);
    } else if (
      props.unavailableServices &&
      props.unavailableServices !== state.unavailableServices
    ) {
      const services = props.unavailableServices
        .map((service) => props.servicesList.find((e) => e.id === service).name)
        .join(",");
      uiNotification.clear();
      uiNotification.error(
        services +
          " " +
          languageData.global[
            props.unavailableServices.length === 1
              ? "vertical_exists_with_data_1"
              : "vertical_exists_with_data_2"
          ],
      );
      returnState.unavailableServices = props.unavailableServices;
      returnState.showLoader = false;
    } else if (props.showLoader != undefined && props.showLoader == false) {
      returnState.showLoader = false;
    }
    return returnState;
  }

  componentDidMount() {
    if (this.props.vertical) {
      this.setState({
        isNew: false,
        form: {
          name: this.props.vertical.name,
          color: this.props.vertical.color,
          clinics_array: this.props.vertical.locations.map(
            (l) => l.location_id,
          ),
          services_array: this.props.vertical.services_id,
        },
        excludeVerticalClinicUrl:
          this.props.vertical.is_excluded_primary_clinic_url,
      });
    }
  }

  handleInputChange = (event) => {
    this.setState({
      form: { ...this.state.form, name: event.target.value },
      nameValid: true,
    });
  };

  handleCheckChange = (event) => {
    const target = event.target;
    if (target.name === "excludeVerticalClinicUrl") {
      this.setState({
        excludeVerticalClinicUrl: !this.state.excludeVerticalClinicUrl,
      });
    }
  };

  handleReactSelect = (selectState) => {
    if (selectState["clinics_array"]) {
      this.setState({
        form: {
          ...this.state.form,
          clinics_array: selectState["clinics_array"],
        },
        clinicValid: true,
      });
    } else if (selectState["services_array"]) {
      this.setState({
        form: {
          ...this.state.form,
          services_array: Object.values(selectState["services_array"]),
        },
        serviceValid: true,
      });
    }
  };

  handleSubmit = () => {
    const formData = {};
    let error = false;

    formData.excludeVerticalClinicUrl = this.state.excludeVerticalClinicUrl
      ? 1
      : 0;

    if (this.state.form.name === "") {
      error = true;
      this.setState({ nameValid: false });
    } else {
      formData.name = this.state.form.name;
    }

    if (this.state.form.color === "") {
      error = true;
      this.setState({ colorValid: false });
    } else {
      this.setState({ colorValid: true });
      formData.color = this.state.form.color;
    }

    if (this.state.form.clinics_array.length === 0) {
      this.setState({ clinicValid: false });
      error = true;
    } else {
      formData.clinics_array = this.state.form.clinics_array;
    }

    if (this.state.form.services_array.length === 0) {
      this.setState({ serviceValid: false });
      error = true;
    } else {
      formData.services_array = this.state.form.services_array;
    }

    if (!error) {
      this.setState({ showLoader: true });
      if (this.state.isNew) {
        this.props.createVertical(formData);
      } else {
        this.props.updateVertical(this.props.vertical.id, formData);
      }
    }
  };

  render() {
    return (
      <div className="vertical-form-wrapper setting-setion full-width">
        <div className="setting-search-outer setting-verticals-outer">
          <span className="searchPageName">
            {this.state.isNew
              ? this.state.languageData.verticals["vertical_create_vertical"]
              : this.state.languageData.verticals["vertical_edit_vertical"]}
          </span>
          <a onClick={() => this.props.toggleVerticalModal()} className="link">
            <img alt="" src="/images/close.png" />
          </a>
        </div>
        <div className="vertical-form">
          <div className="row">
            <div className="col-sm-6">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">
                  {this.state.languageData.verticals["vertical_vertical_name"]}
                  <span className="setting-require">*</span>
                </div>
                <div className="setting-input-outer">
                  <input
                    name="name"
                    className={
                      this.state.nameValid
                        ? "newInputField"
                        : "newInputField field_error"
                    }
                    maxLength={255}
                    type="text"
                    value={
                      this.state.form.name !== null ? this.state.form.name : ""
                    }
                    onChange={this.handleInputChange}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="preferred-color position-relative dropdown m-t-20">
                <label className="m-r-15">
                  {this.state.languageData.verticals["vertical_vertical_color"]}{" "}
                  <span className="setting-require">*</span>
                </label>
                <a
                  className="new-white-btn changeColor"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Change
                </a>
                <span
                  className="current-color"
                  style={{
                    backgroundColor: this.state.form.color
                      ? this.state.form.color
                      : "#fff",
                    borderColor: this.state.form.color
                      ? this.state.form.color
                      : this.state.colorValid
                      ? "#667680"
                      : "#e74c3c",
                  }}
                />
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu-preferredColor"
                >
                  {this.state.verticalColors.map((color, idx) => {
                    return (
                      <li key={"vertical_colors-" + idx} data-color={color}>
                        <span
                          className="color-palette"
                          onClick={() =>
                            this.setState({
                              form: { ...this.state.form, color: color },
                              colorValid: false,
                            })
                          }
                          style={{ backgroundColor: color, borderColor: color }}
                        >
                          {color == this.state.form.color && (
                            <span className="color-palette-checked">
                              <i
                                className="fa fa-check"
                                style={{ color: "#fff" }}
                              />
                            </span>
                          )}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="basic-checkbox-outer preferred-color position-relative dropdown m-t-20">
                <input
                  id="excludeVerticalClinicUrl"
                  type="checkbox"
                  className="basic-form-checkbox"
                  name="excludeVerticalClinicUrl"
                  checked={
                    this.state.excludeVerticalClinicUrl ? "checked" : false
                  }
                  onChange={this.handleCheckChange}
                />
                <label
                  className="basic-form-text"
                  htmlFor="excludeVerticalClinicUrl"
                >
                  Exclude Vertical from Primary Clinic URL
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="simpleField">
                <div className="simpleLabel">
                  {
                    this.state.languageData.verticals[
                      "vertical_select_locations"
                    ]
                  }
                  <span className="setting-require">*</span>
                </div>
                <div className="addSaleSelect">
                  <ReactSelect
                    name="clinics_array"
                    selectedId={this.state.form.clinics_array}
                    list={this.props.locationsList}
                    value="id"
                    label="clinic_name"
                    handleReactSelect={this.handleReactSelect}
                    isValid={this.state.clinicValid}
                    isMulti={true}
                    noOptionsMessage={() => "Location not found"}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="simpleField">
                <div className="simpleLabel">
                  {
                    this.state.languageData.verticals[
                      "vertical_select_services"
                    ]
                  }
                  <span className="setting-require">*</span>
                </div>
                <div className="addSaleSelect">
                  <ReactSelect
                    name="services_array"
                    selectedId={this.state.form.services_array}
                    list={this.props.servicesList}
                    value="id"
                    label="name"
                    handleReactSelect={this.handleReactSelect}
                    isValid={this.state.serviceValid}
                    isMulti={true}
                    noOptionsMessage={() => "Service not found"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-static">
          <a className="new-blue-btn pull-right" onClick={this.handleSubmit}>
            {this.state.languageData.global["label_save"]}
          </a>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock clientLoader clientProfileLoader"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img alt="" src="/images/Eclipse.gif" className="loader-img" />
            <div className="popup-subtitle">
              {this.state.languageData.global["loading_please_wait_text"]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.VerticalsReducer.action === "CREATE_VERTICAL") {
    if (state.VerticalsReducer.data.status != 201) {
      state.VerticalsReducer.action = undefined;
      if (state.VerticalsReducer.data.data) {
        returnState.unavailableServices =
          state.VerticalsReducer.data.data.filter((item, pos) => {
            return state.VerticalsReducer.data.data.indexOf(item) == pos;
          });
        returnState.showLoader = false;
        returnState.nameValid = false;
      } else {
        uiNotification.clear();
        uiNotification.error(
          languageData.global[state.VerticalsReducer.data.message],
        );
        returnState.showLoader = false;
        returnState.nameValid = false;
      }
    } else {
      returnState.message =
        languageData.global[state.VerticalsReducer.data.message];
      returnState.closeModal = true;
    }
  }
  if (state.VerticalsReducer.action === "UPDATE_VERTICAL") {
    if (state.VerticalsReducer.data.status != 200) {
      if (state.VerticalsReducer.data.data) {
        returnState.unavailableServices =
          state.VerticalsReducer.data.data.filter((item, pos) => {
            return state.VerticalsReducer.data.data.indexOf(item) == pos;
          });
        returnState.showLoader = false;
        returnState.nameValid = false;
      } else {
        uiNotification.clear();
        uiNotification.error(
          languageData.global[state.VerticalsReducer.data.message],
        );
        returnState.showLoader = false;
        returnState.nameValid = false;
      }
    } else {
      returnState.message =
        languageData.global[state.VerticalsReducer.data.message];
      returnState.closeModal = true;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createVertical: createVertical,
      updateVertical: updateVertical,
      getClientDetail: getClientDetail,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateEditVerticals);
