import React, { useState } from "react";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import {
  useCheckValidUserQuery,
  useLockProcedureQuery,
} from "../../../_legacy/Queries";
import Loader from "../../Common/Loader";
import { Input } from "../../../shared/Input/Input";
import { Button } from "../../../shared/Button/Button";
import { uiNotification } from "../../../services/UINotificationService";
import { usePermission } from "../../../hooks/usePermission";
import { PERMISSIONS } from "../../../consts/api";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateProcedureLockStatus } from "../../../Actions/Clients/clientsAction";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

const LockProcedure = ({
  isLocked,
  updateLockState,
  procedureId,
  lockUnlockby,
  refetchProcedures,
  isdocuments,
  updateProcedureLockStatus,
}) => {
  const { mutate, isLoading } = useLockProcedureQuery();
  const { mutate: checkValidUserQuery, isLoading: isInvalidateLoading } =
    useCheckValidUserQuery();
  const { tCommon } = useAppTranslation.Common();
  const [
    { lockConfirmationModal, passwordModal },
    setState,
  ] = useState({
    lockConfirmationModal: false,
    passwordModal: false,
  });
  const lockUnlockPermission = usePermission(
    PERMISSIONS.patientManagement.lockUnlockProcedure,
  );
  const [password, setPassword] = useState("");
  const [isDocumentsPermission, setDocumentsPermission] = useState(false);

  const login = (formValues) => {
    checkValidUserQuery(formValues, {
      onSuccess: () => lockThisProcedure(),
      onError: (error) => {
        uiNotification.error(
          tCommon([
            `apiError.${error?.response?.data?.message}`,
            'error.fallback',
          ]),
        );
      },
    });
  };

  const updateState = (newState) => {
    setState((oldValues) => ({
      ...oldValues,
      ...newState,
    }));
  };

  const toggleConfirmationModal = () => {
    updateState({
      lockConfirmationModal: !lockConfirmationModal,
    });
  };

  const togglePasswordModal = () => {
    updateState({
      passwordModal: !passwordModal,
    });
  };

  const validateUser = () => {
    toggleConfirmationModal();
    togglePasswordModal();
  };

  const lockThisProcedure = () => {
    mutate(
      {
        procedureId,
        isLock: +!isLocked,
      },
      {
        onSuccess: () => {
          updateLockState && updateLockState(+!isLocked);
          updateState({
            lockConfirmationModal: false,
            passwordModal: false,
          });
          refetchProcedures();
          updateProcedureLockStatus({
            id: procedureId,
            is_lock: +!isLocked,
          });
        },
      },
    );
  };

  const handleVerify = () => {
    login({ password: password });
  };

  const handleSetValue = (e) => {
    setPassword(e.target.value);
  };

  const hasLockUnlockPermissions = lockUnlockPermission.loaded && lockUnlockPermission.permitted;

  return (
    <>
      <div
        className={`text-center ${hasLockUnlockPermissions ? "cursor-pointer" : "cursor: not-allowed"
          }`}
        onClick={hasLockUnlockPermissions ? toggleConfirmationModal : () => {}}
        id="unlock-procedure"
      >
        {!isLocked ? (
          <div className="">
            {hasLockUnlockPermissions && (
              <div className="lock-unlock-icon">
                <img
                  src="/images/unlock.png"
                  alt="unlock"
                  width={16}
                  height={16}
                />
              </div>
            )}

            {lockUnlockby && (
              <p className="m-b-0">{`Unlocked by ${lockUnlockby}`}</p>
            )}
          </div>
        ) : (
          <section>
            {hasLockUnlockPermissions && (
              <div className="lock-unlock-icon">
                <img src="/images/lock.png" alt="lock" width={16} height={16} />
              </div>
            )}
            {lockUnlockby && (
              <p className="m-b-0">{`Locked by ${lockUnlockby}`}</p>
            )}
          </section>
        )}
      </div>

      {isdocuments && !hasLockUnlockPermissions && (
        <div
          onClick={() => setDocumentsPermission(true)}
          className="lock-unlock-icon cursor-pointer"
        >
          <img src="/images/lock.png" alt="lock" width={16} height={16} />
        </div>
      )}

      {lockConfirmationModal && (
        <ConfirmModal
          className="confirm-modal-center validate-modal"
          isOpen={lockConfirmationModal}
          onConfirm={validateUser}
          onCancel={toggleConfirmationModal}
          onClose={toggleConfirmationModal}
          confirmTitle={isLocked ? "Unlock" : "Lock"}
        >
          Are you sure you want to {isLocked ? "unlock" : "lock"} this procedure
          ?{isLoading && <Loader showLoader />}
        </ConfirmModal>
      )}

      {passwordModal && (
        <ConfirmModal
          className="confirm-modal-center validate-modal"
          isOpen={passwordModal}
          onClose={togglePasswordModal}
          title="Verify Your Credentials"
        >
          <Input
            type="password"
            name="password"
            value={password}
            onChange={handleSetValue}
            placeholder="Password"
          />{" "}
          <div className="d-flex float-right m-t-20 m-b-20 ">
            <Button
              variant="outlined"
              className="m-r-10"
              onClick={togglePasswordModal}
            >
              Cancel
            </Button>
            <Button
              isDisabled={isInvalidateLoading || !Boolean(password)}
              leftAdornment={
                isInvalidateLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
              onClick={handleVerify}
            >
              Verify
            </Button>
          </div>
        </ConfirmModal>
      )}
      {isDocumentsPermission && (
        <ConfirmModal
          className="confirm-modal-center validate-modal"
          isOpen={isDocumentsPermission}
        >
          <div className="flex-col items-center  m-b-20">
            <p> This procedure is locked, please contact admin </p>
            <Button onClick={() => setDocumentsPermission(false)}>Ok </Button>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateProcedureLockStatus,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(LockProcedure);
