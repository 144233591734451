import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./File.module.scss";
import { svg } from "../../../../assets/svg";
import { fileUtil } from "../../../../utilities/file";

export function File({ name, isDisabled, onRemove }) {
  return (
    <div
      className={cx(classes.file, {
        [classes.fileDisabled]: isDisabled,
      })}
    >
      <div title={name}>{fileUtil.shortenName(name)}</div>
      <button type="button" onClick={onRemove} disabled={isDisabled}>
        <img src={svg.closeRed} alt="x" width="10px" />
      </button>
    </div>
  );
}

File.propTypes = {
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
};
