import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import {
  getCherryConfig, putCherryConfig, patchCherryConfig,
} from '../../../Actions/Integrations/integrationActions.js';
import { getIsPosEnabled, positionFooterCorrectly, showFormattedDate } from '../../../Utils/services';

class CherryIntegration extends Component {
  constructor (props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.settingsLang = languageData.settings;
    this.globalLang = languageData.global;

    this.state = {
      cherryConfig: {},

      showLoader: false,

      api_keyClass: 'newInputField',

      register_link: '',
      help_link: '',
      clinics: [],
      is_active: false,
      is_connected: false,
      key: '',
      practices: [],
      updated_at: '',
    };
  }

  handleToggleActive = (event) => {
    this.setState({ showLoader: true });
    this.props.patchConfig({ is_active: event.target.checked });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'text' || target.type === 'password') {
      this.setState({
        [target.name]: value
      });
    }
    if (target.name === 'clinics') {
      const clinics = this.state.clinics;
      const clinicId = parseInt(target.dataset.clinicId);
      const selectedPracticeId = target.value;
      const clinic = clinics.find((clinic) => clinic.id === clinicId);
      const index = this.state.clinics.indexOf(clinic);

      clinic.cherry_practice_id = selectedPracticeId;
      clinics[index] = clinic;
      this.setState({
        clinics: clinics
      });
    }
  };

  componentDidMount () {
    if (this.isIntegrationToggleEnabled()) {
      this.setState({ showLoader: true });
      this.props.getConfig();
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let returnState = prevState;

    if (nextProps.showLoader !== undefined && nextProps.showLoader === false) {
      positionFooterCorrectly();
    }

    if (nextProps.cherryConfig !== undefined && nextProps.cherryConfig !== prevState.cherryConfig) {
      returnState = { ...prevState, ...nextProps.cherryConfig };
      returnState.cherryConfig = nextProps.cherryConfig;
      returnState.showLoader = nextProps.showLoader;
    }

    return returnState;
  }

  cancelTask = () => {
    this.setState({ showLoader: true });
    this.props.getConfig();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let error = false;
    let formData = {
      is_active: this.state.is_active, clinics: this.state.clinics,
    };

    if (this.state.key) {
      formData.key = this.state.key;
    }

    this.setState({ showLoader: true });

    if (this.state.is_connected) {
      this.props.patchConfig(formData);
    } else {
      this.props.putConfig(formData);
    }
  };

  handleUpdateCherrySettings = () => {
    this.setState({ is_connected: false });
  };

  isIntegrationToggleEnabled = () => getIsPosEnabled();

  render () {
    return (<div id="content">
      <div className="container-fluid content setting-wrapper">
        <Sidebar/>
        {/* ****SYNC HEADING****** */}
        <div className="memberWalletOuter relative">
          <div className="setting-setion m-b-10">
            <div className="membership-title">Cherry Integration
              <div className="memberRightActions">
                <span className="switch-text"/>
                <label className="setting-switch pull-right">
                  <input type="checkbox" name="is_active" id="is_active"
                         disabled={!this.isIntegrationToggleEnabled()}
                         checked={this.state.is_active && this.isIntegrationToggleEnabled()}
                         onChange={this.handleToggleActive}/>
                  <span className="setting-slider"/>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            {!getIsPosEnabled() ? (
              <div className="col-sm-12">
                <div className="setting-setion membershipSectionTitle">
                  <p className="font-weight-6 m-t-15 warning">
                    <i className="fa fa-circle-exclamation" />
                    In order to use this integration, please activate the AR
                    Point-of-Sale. Once this is completed, you can configure and connect to third party service!</p>
                </div>
              </div>
            ) : (
              (this.state.is_active === true && this.isIntegrationToggleEnabled()) && <div className="col-sm-12">
                <div className="setting-setion">
                  <div className="membershipSectionTitle">Cherry Settings</div>
                  <div className="wallet-edit-block">
                    <div className="membershipSectionContent p5">
                      <div className="row">
                        <div className="col-xs-12 m-t-20 m-b-15">
                          <div className="col-lg-6 col-xs-12">

                            {!this.state.is_connected ? (<>
                              <div className="newInputFileldOuter">
                                <div className="newInputLabel">API Key <span className="setting-require">*</span>
                                </div>
                                <div className="setting-input-outer">
                                  <input
                                    name="key"
                                    className={this.state.api_keyClass}
                                    type="text"
                                    value={this.state.key}
                                    autoComplete="off"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </>) : (<>
                              <p className="AuthorizedSync">
                                <i className="fa fa-check-circle"/>
                                Authorized on {showFormattedDate(this.state.updated_at, true, null, "MM/DD/YYYY hh:mm A")}
                              </p>
                              <p className="p-text m-t-15">If you would like to link a different Cherry API key,
                                please
                                click the update button bellow.</p>
                              <button className="new-blue-btn pull-left m-t-15 m-b-10 m-l-0"
                                      onClick={this.handleUpdateCherrySettings}>Update API key
                              </button>
                            </>)}

                          </div>
                          <div className="col-lg-6 col-xs-12 m-t-15">
                            <div className="guidanceDetail">
                              {this.state.is_connected === false && <div className="col-sm-12">
                                <div className="juvly-subtitle m-b-10">Where is my Cherry API key?</div>
                                <ul className="guidanceDetailSteps">
                                  <li>Register on <a className="easy-link no-padding"
                                                     href={this.state.register_link}
                                                     target="_blank">Cherry</a>
                                  </li>
                                  <li><a className="easy-link no-padding"
                                         href={this.state.help_link}
                                         target="_blank">Need help ?</a>
                                  </li>
                                </ul>
                              </div>}
                            </div>
                          </div>
                        </div>

                        {this.state.is_connected && this.state.clinics.length > 0 &&
                          <div className="col-xs-12 m-t-20 m-b-15">
                            <div className="col-lg-6 col-xs-12 m-t-20">
                              <div className="settings-subtitle m-b-10">Clinics Info</div>
                              {this.state.clinics.map((clinic) => <div key={clinic.id} className="newInputFileldOuter">
                                <div className="newInputLabel">{clinic.clinic_name}</div>

                                <select name="clinics"
                                        data-clinic-id={clinic.id}
                                        className="newSelectField"
                                        value={clinic.cherry_practice_id} onChange={this.handleInputChange}>
                                  <option value="0"> None selected</option>
                                  <option value="disabled"> Disabled</option>
                                  {this.state.practices.map((practice) => {
                                    return (<option
                                      key={practice.id}
                                      value={practice.id}> {practice.name}</option>);
                                  })}
                                </select>

                              </div>)}
                            </div>
                          </div>}
                      </div>

                    </div>
                    <div className="footer-static">
                      <button className="new-blue-btn pull-right"
                              onClick={this.handleSubmit}>{this.globalLang.label_save}</button>
                      <button className="new-white-btn pull-right"
                              onClick={this.cancelTask}>{this.globalLang.label_cancel}</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true}/>
      </div>
    </div>);
  }
}

function mapStateToProps (state) {
  const returnState = { showLoader: false, cherryConfig: {} };
  if (!['GET_CHERRY_CONFIG', 'PUT_CHERRY_CONFIG', 'PATCH_CHERRY_CONFIG'].includes(state.IntegrationReducer.action)) {
    return returnState;
  }

  if (state.IntegrationReducer.data.status === 200) {
    returnState.cherryConfig = state.IntegrationReducer.data.data;
  }

  returnState.showLoader = false;

  return returnState;
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getConfig: getCherryConfig, putConfig: putCherryConfig, patchConfig: patchCherryConfig,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CherryIntegration);
