import React from "react";
import PropTypes from "prop-types";
import { BUTTON_VARIANT } from "../StatementDescriptor.consts";
import { svg } from "../../../../../../assets/svg";
import cx from "clsx";
import classes from "../sass/OpenButton.module.scss";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";

const errorIconByVariant = {
  [BUTTON_VARIANT.contained]: svg.errorWhite,
  [BUTTON_VARIANT.text]: svg.errorRed,
};

const loaderColorByVariant = {
  [BUTTON_VARIANT.contained]: "white",
  [BUTTON_VARIANT.text]: "primary",
};

export function OpenButton({
  variant,
  isDisabled,
  isError,
  onClick,
  isLoading,
}) {
  return (
    <button
      type="button"
      className={cx({
        [classes.text]: variant === BUTTON_VARIANT.text,
        [classes.contained]: variant === BUTTON_VARIANT.contained,
        [classes.disabled]: isDisabled,
        [classes.error]: isError,
      })}
      onClick={onClick}
      disabled={isDisabled}
    >
      {isError && <img src={errorIconByVariant[variant]} alt="!" />}
      {isLoading && (
        <CircularProgress size="tiny" color={loaderColorByVariant[variant]} />
      )}
      Statement Descriptor
    </button>
  );
}

OpenButton.propTypes = {
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
