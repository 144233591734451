import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../shared/Button/Button";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { useCurrentAccountQuery } from "../../../../api/queries/useAccountQuery";
import { CLIENT_GLOBAL_NAME } from "../../../../consts/api";

export function Header({
  restoreCount,
  onRestore,
  isRestoring,
  searchValue,
  onChangeSearch,
  isSearching,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const accountQuery = useCurrentAccountQuery();

  const getTitle = () => {
    if (
      accountQuery.data?.preferences?.clientGlobalName ===
      CLIENT_GLOBAL_NAME.client
    ) {
      return tSettings("deletedPatients.headerClients");
    }
    return tSettings("deletedPatients.headerPatients");
  };

  return (
    <>
      <EntityHeader
        title={getTitle()}
        searchValue={searchValue}
        onChangeSearch={onChangeSearch}
        isSearching={isSearching}
        right={
          <Button
            isDisabled={restoreCount === 0 || isRestoring}
            size="small"
            onClick={() => setIsConfirmModalOpen(true)}
            leftAdornment={
              isRestoring ? (
                <CircularProgress size="tiny" color="white" />
              ) : undefined
            }
          >
            {tCommon("label.restore")} ({restoreCount})
          </Button>
        }
      />
      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={() => {
            onRestore();
            setIsConfirmModalOpen(false);
          }}
        >
          {tSettings("deletedPatients.confirm.restore")}
        </ConfirmModal>
      )}
    </>
  );
}

Header.propTypes = {
  restoreCount: PropTypes.number.isRequired,
  onRestore: PropTypes.func.isRequired,
  isRestoring: PropTypes.bool.isRequired,
  searchValue: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
};
