import {
  SALES_REPORT_EXPORT_NAMES,
  useSalesReportExportMutation,
} from "../../../../../../../api/mutations/useSalesReportExportMutation";
import { FILE_EXPORT_TYPES } from "../../../../../../../consts/api";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { apiDateStringToDate, composeDownloadDataUrl } from "../../../../../../../utilities/api";

export function useDownload({ period }) {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useSalesReportExportMutation({
    onError: () => {
      uiNotification.error(tCommon("error.exportXlsReports"));
    },
  });

  const initiate = async () => {
    const res = await mutateAsync({
      type: FILE_EXPORT_TYPES.xls,
      name: SALES_REPORT_EXPORT_NAMES.smsUsage,
      fromDate: apiDateStringToDate(period.value.from),
      toDate: apiDateStringToDate(period.value.to),
    });

    window.open(composeDownloadDataUrl(res.fileName), "_blank");
  };

  return {
    initiate,
    isLoading,
  };
}
