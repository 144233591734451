import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkoutInvoice } from "../../../../../../store/checkoutInvoice";
import { usePaymentFlow } from "../../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";

const ApplyGiftAmount = ({ giftCard, isTipsApplying }) => {
  const { showTipsModal } = usePaymentFlow();
  const availableAmount = giftCard?.availableAmount || 0;

  return (
    <div>
      <PaymentFormModal
        maxGiftcardAmount={availableAmount}
        isTipsApplying={isTipsApplying}
        giftCard={giftCard}
        showTipsModal={showTipsModal}
      />
    </div>
  );
};

ApplyGiftAmount.propTypes = {
  giftCard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    availableAmount: PropTypes.number.isRequired,
    redemptionCode: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ApplyGiftAmount);
