import React from "react";
import PropTypes from "prop-types";
import _ from "lodash/fp";

const Input = (props) => {
  const {
    name,
    placeholder,
    className,
    register,
    isRequired,
    maxLength,
    errors,
    onCopy,
    type,
  } = props;

  const copyCallback = (e) => {
    if (onCopy == false) {
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <input
        autoComplete="off"
        type={type}
        className={className}
        placeholder={placeholder}
        name={name}
        onCopy={(e) => copyCallback(e)}
        onPaste={(e) => copyCallback(e)}
        onCut={(e) => copyCallback(e)}
        ref={register({
          required: isRequired,
          maxLength: maxLength,
        })}
      />
      {_.get(`${name}.type`, errors) === "maxLength" && (
        <p>
          {placeholder} cannot exceed {maxLength} characters
        </p>
      )}
    </React.Fragment>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  errors: PropTypes.object.isRequired,
  onCopy: PropTypes.bool,
  onPaste: PropTypes.bool,
  type: PropTypes.string,
};

Input.defaultValues = {
  placeholder: "",
  className: "newInputField",
  isRequired: false,
  maxLength: 255,
  type: "text",
  onCopy: false,
  onPaste: false,
};

export default Input;
