import { useState } from "react";

const useAdvancedState = (defaultValues) => {
  const [state, setState] = useState(defaultValues);
  const [defaultState] = useState(defaultValues);

  const updateState = (updatedState) => {
    setState((prevState) => ({
      ...prevState,
      ...updatedState,
    }));
  };

  const toggleField = (fieldName) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const resetState = () => {
    setState(defaultState);
  };

  return { state, setState, updateState, toggleField, resetState };
};

export default useAdvancedState;
