import React, { useEffect, useRef, useState } from "react";
import cx from "clsx";
import classes from "../scss/clinicDropdown.module.scss";
import { useClickOutside } from "../../../utilities/hooks/useClickOutside";

const ClinicDropdown = ({ options, selectedClinic, className }) => {
  const [showClinicList, setShowClinicList] = useState(false);
  const clinicList = useRef(null);
  const activeOption = useRef(null);

  const handleShowClinicList = () => {
    if (options) {
      setShowClinicList(!showClinicList);
    }
  };

  const handleSelectClinic = (option) => {
    selectedClinic.update(option);
    setShowClinicList(false);
  };

  useEffect(() => {
    if (showClinicList && activeOption.current) {
      activeOption.current.scrollIntoView({ block: "nearest" });
    }
  }, [showClinicList]);

  useClickOutside(clinicList, () => setShowClinicList(false));

  return (
    <div className={cx("flex items-center", className)}>
      <i className={cx("fa fa-map-marker-alt m-b-3", classes.clinicIcon)}></i>
      <div className={cx(classes.clinicSelect)}>
        <button onClick={handleShowClinicList}>
          {selectedClinic.value.label}
        </button>
        {showClinicList && (
          <div className={cx(classes.clinicOptions)} ref={clinicList}>
            <ul>
              {[
                {
                  label: "All Clinics",
                  value: 0,
                },
                ...options,
              ]?.map((option) => (
                <li
                  key={option.value}
                  className={cx(
                    selectedClinic.value.value === option.value &&
                      classes.active,
                  )}
                  ref={(ref) => {
                    if (selectedClinic.value.value === option.value)
                      activeOption.current = ref;
                  }}
                >
                  <button
                    onClick={() => {
                      handleSelectClinic(option);
                    }}
                  >
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClinicDropdown;
