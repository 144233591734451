import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./TimePicker.module.scss";
import { svg } from "../../assets/svg";

const TimePicker = ({
  searchPickerDate,
  providerTime,
  timeInsideSchedule,
  actualTime,
  prevChangeDate,
  nextChangeDate,
  onTimeslotClick,
  onOSHTimeslotClick,
  disablePrevDayNavigation,
  disableNextDayNavigation,
  className,
}) => (
  <div className={cx(classes.root, className)}>
    <div className={classes.title}>Select slot from below</div>
    <div className={classes.nav}>
      <button
        type="button"
        onClick={prevChangeDate}
        disabled={disablePrevDayNavigation}
        className={cx(classes.navBtn, {
          [classes.navBtnDisabled]: disablePrevDayNavigation,
        })}
      >
        <img src={svg.chevronLeftPrimary} alt="<" />
        Prev Day
      </button>
      <span>{searchPickerDate}</span>
      <button
        type="button"
        onClick={nextChangeDate}
        disabled={disableNextDayNavigation}
        className={cx(classes.navBtn, {
          [classes.navBtnDisabled]: disableNextDayNavigation,
        })}
      >
        Next Day
        <img src={svg.chevronLeftPrimary} alt=">" />
      </button>
    </div>
    <div className={classes.content}>
      {providerTime.length > 0 && timeInsideSchedule.length
        ? providerTime.map((time, idx) => {
            const timeInSchedule = timeInsideSchedule.includes(time);
            return (
              <button
                key={idx}
                className={cx(classes.timeTile, {
                  [classes.timeTileSelected]: time === actualTime,
                  [classes.timeTileOutOfSchedule]: !timeInSchedule,
                })}
                type="button"
                onClick={() => onOSHTimeslotClick(time, timeInSchedule)}
              >
                {time}
              </button>
            );
          })
        : providerTime.map((time, idx) => (
            <button
              key={idx}
              className={cx(classes.timeTile, {
                [classes.timeTileSelected]: time === actualTime,
              })}
              type="button"
              onClick={() => onTimeslotClick(time)}
            >
              {time}
            </button>
          ))}
      {providerTime.length === 0 && (
        <div className={classes.empty}>
          No slot available. Please select other date.
        </div>
      )}
    </div>
  </div>
);

TimePicker.propTypes = {
  searchPickerDate: PropTypes.string.isRequired,
  providerTime: PropTypes.array.isRequired,
  timeInsideSchedule: PropTypes.array,
  actualTime: PropTypes.string,
  prevChangeDate: PropTypes.func.isRequired,
  nextChangeDate: PropTypes.func.isRequired,
  onTimeslotClick: PropTypes.func.isRequired,
  onOSHTimeslotClick: PropTypes.func.isRequired,
  disablePrevDayNavigation: PropTypes.bool.isRequired,
  disableNextDayNavigation: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

TimePicker.defaultProps = {
  timeInsideSchedule: [],
  actualTime: "",
};

export default TimePicker;
