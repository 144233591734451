import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  wpu_id: yup.number().required(),
  new_password: yup.string().required(),
  old_password: yup.string().required(),
  otp: yup.string().required(),
  pass_code: yup.string().nullable(),
});

export function useChangePasswordMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.changePassword(),
      removeNullishFromShape(req),
    );
  }, options);
}
