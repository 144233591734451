import React, { useState } from "react";
import { connect } from "react-redux";
import { PageHeader } from "../../../../../boxes/PageHeader/PageHeader";
import { usePatient } from "../../hooks/usePatient";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { PlusIcon } from "../../../../../assets/Icons/PlusIcon";
import classes from "./Header.module.scss";
import { SaveIcon } from "../../../../../assets/Icons/SaveIcon";
import { DeleteIcon } from "../../../../../assets/Icons/DeleteIcon";
import { SettingsIcon } from "../../../../../assets/Icons/SettingsIcon";
import { BarChartIcon } from "../../../../../assets/Icons/BarChartIcon";
import { EditIcon } from "../../../../../assets/Icons/EditIcon";
import { useVitals } from "../../hooks/useVitals";
import { dispatch } from "../../../../../store/store";
import { patientVitals } from "../../../../../store/patients/vitals";
import { vitalToMutationRecordAdapter } from "../../../../../store/patients/vitals/utilities";
import { CrossIcon } from "../../../../../assets/Icons/CrossIcon";
import { useSave } from "./hooks/useSave";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useDelete } from "./hooks/useDelete";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";

const { actions, selectors } = patientVitals;

const mapStateToProps = (state) => ({
  activeRecordId: selectors.selectActiveRecordId(state),
  isMutationRecordSet: selectors.selectIsMutationRecordSet(state),
  mutationRecord: selectors.selectMutationRecord(state),
  isMutationRecordChanged: selectors.selectIsMutationRecordChanged(state),
});

export const Header = connect(mapStateToProps)(
  ({
    onGraphOpen,
    onSettingsOpen,
    activeRecordId,
    isMutationRecordSet,
    mutationRecord,
    isMutationRecordChanged,
  }) => {
    const { tClients } = useAppTranslation.Clients();
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const [isConfirmDropOpen, setIsConfirmDropOpen] = useState(false);

    const patient = usePatient();
    const vitals = useVitals();
    const save = useSave();
    const del = useDelete(activeRecordId);

    const isButtonsDisabled = patient.isLoading || vitals.isLoading;

    const isDeselectRecordButtonVisible = isMutationRecordSet;
    const isAddRecordButtonVisible = !isMutationRecordSet;
    const isEditRecordButtonVisible = Boolean(
      activeRecordId && !isMutationRecordSet,
    );
    const isSaveRecordButtonVisible = isMutationRecordSet;
    const isDeleteRecordButtonVisible = Boolean(activeRecordId);

    const onDrop = () => {
      if (isMutationRecordChanged) {
        setIsConfirmDropOpen(true);
      } else {
        dispatch(actions.dropMutationRecord());
      }
    };

    return (
      <>
        <PageHeader
          title={
            patient.isLoading ? (
              <Skeleton
                count={1}
                width="200px"
                height={30}
                borderRadius="4px"
              />
            ) : (
              tClients("vitals.title", {
                patientName: patient.data?.fullName,
              })
            )
          }
          className={classes.root}
          rightAdornment={
            <div className={classes.rightAdornment}>
              {isDeselectRecordButtonVisible && (
                <Button
                  variant="text"
                  size="tiny"
                  color="error"
                  isDisabled={isButtonsDisabled}
                  onClick={onDrop}
                >
                  <CrossIcon width="17px" />
                </Button>
              )}
              {isAddRecordButtonVisible && (
                <Button
                  variant="text"
                  size="tiny"
                  isDisabled={isButtonsDisabled || isMutationRecordSet}
                  onClick={() =>
                    dispatch(
                      actions.setMutationRecord(vitalToMutationRecordAdapter()),
                    )
                  }
                >
                  <PlusIcon />
                </Button>
              )}
              {isEditRecordButtonVisible && (
                <Button
                  variant="text"
                  size="tiny"
                  isDisabled={
                    isButtonsDisabled || isMutationRecordSet || del.isLoading
                  }
                  onClick={() =>
                    dispatch(
                      actions.setMutationRecord(
                        vitalToMutationRecordAdapter(
                          vitals.data?.find((v) => v.id === activeRecordId),
                        ),
                      ),
                    )
                  }
                >
                  <EditIcon width="18px" />
                </Button>
              )}
              {isSaveRecordButtonVisible && (
                <Button
                  variant="text"
                  size="tiny"
                  isDisabled={isButtonsDisabled || save.isLoading}
                  onClick={() => save.initiate(mutationRecord)}
                >
                  {save.isLoading ? (
                    <CircularProgress size="tiny" />
                  ) : (
                    <SaveIcon width="18px" />
                  )}
                </Button>
              )}
              {isDeleteRecordButtonVisible && (
                <Button
                  variant="text"
                  size="tiny"
                  isDisabled={isButtonsDisabled || del.isLoading}
                  onClick={() => setIsConfirmDeleteOpen(true)}
                >
                  {del.isLoading ? (
                    <CircularProgress size="tiny" />
                  ) : (
                    <DeleteIcon width="18px" />
                  )}
                </Button>
              )}
              {vitals.data?.length > 0 && (
                <Button
                  variant="text"
                  size="tiny"
                  isDisabled={isButtonsDisabled}
                  onClick={onGraphOpen}
                >
                  <BarChartIcon width="18px" />
                </Button>
              )}
              <Button
                variant="text"
                size="tiny"
                isDisabled={isButtonsDisabled}
                onClick={onSettingsOpen}
              >
                <SettingsIcon width="18px" />
              </Button>
            </div>
          }
        />
        {isConfirmDeleteOpen && (
          <ConfirmModal
            isOpen
            onClose={() => setIsConfirmDeleteOpen(false)}
            onCancel={() => setIsConfirmDeleteOpen(false)}
            onConfirm={() => {
              del.initiate();
              setIsConfirmDeleteOpen(false);
            }}
          >
            {tClients("vitals.confirm.delete")}
          </ConfirmModal>
        )}
        {isConfirmDropOpen && (
          <ConfirmModal
            isOpen
            onClose={() => setIsConfirmDropOpen(false)}
            onCancel={() => setIsConfirmDropOpen(false)}
            onConfirm={() => {
              setIsConfirmDropOpen(false);
              dispatch(actions.dropMutationRecord());
            }}
          >
            {tClients("vitals.confirm.drop")}
          </ConfirmModal>
        )}
      </>
    );
  },
);
