import React from "react";
import styles from "../scss/addDocument.module.scss";

const StripeDocumentSelect = ({ id, label, value, onChange, options }) => {
  return (
    <div className={styles.inputGroup}>
      <label htmlFor={id}>{label}</label>
      <select id={id} value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StripeDocumentSelect;
