import React from "react";

export function WidgetLoader() {
  return (
    <div className="new-loader text-left displayBlock">
      <div className="loader-outer">
        <img
          id="loader-outer"
          src="/images/Eclipse.gif"
          className="loader-img"
          alt="loader"
        />
        <div id="modal-confirm-text" className="popup-subtitle">
          Loading data. Please wait...
        </div>
      </div>
    </div>
  );
}
