import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const dtoSchema = yup.object({
  stripeUserId: yup.string().required(),
  descriptor: yup.string().required(),
});

export function useCreateStatementDescriptorMutation(options = {}) {
  return useMutation((payload) => {
    const dto = dtoSchema.validateSync(payload, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postStatementDescriptor(), {
      stripe_user_id: dto.stripeUserId,
      statement_descriptor: dto.descriptor,
    });
  }, options);
}
