import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import classes from "../scss/repeatMd.module.scss";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { Input } from "../../../../../shared/Input/Input";
import { InputError } from "../../../../../shared/InputError/InputError";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";

const AuthorizationBox = ({
  isConnected,
  setIsConnected,
  updatedAt,
  updateApiKey,
  isUpdating,
}) => {
  const { tCommon } = useAppTranslation.Common();

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    validationSchema: yup.object({
      apiKey: yup.string().required("Required"),
    }),
    onSubmit: () => {
      updateApiKey(
        {
          isActive: true,
          apiKey: values.apiKey,
        },
        { onSuccess: () => setIsConnected(true) },
      );
    },
    initialValues: {
      apiKey: "",
    },
  });

  return isConnected ? (
    <div className={classes.authorizationBox}>
      <p className={classes.autorizationText}>
        <i className="fa fa-check-circle"></i>
        <span>
          {tCommon("integration.authorizedOn", {
            date: moment.utc(updatedAt).format("MM/DD/YYYY hh:mm A"),
            interpolation: { escapeValue: false },
          })}{" "}
        </span>
      </p>
      <p className={classes.changeSettingsText}>
        {tCommon("integration.changeSettings")}
      </p>
      <Button
        size="small"
        className={classes.updateButton}
        onClick={() => setIsConnected(false)}
      >
        {tCommon("label.updateApiKey")}
      </Button>
    </div>
  ) : (
    <div className={classes.authorizationBox}>
      <InputLabel>{tCommon("label.apiKey")}</InputLabel>
      <Input
        size="small"
        className={classes.apiKeyInput}
        value={values.apiKey}
        onChange={(event) => setFieldValue("apiKey", event.target.value)}
      />
      {errors.apiKey && <InputError>{errors.apiKey}</InputError>}
      <div className={classes.updateApiKeyFooter}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setIsConnected(true)}
        >
          {tCommon("label.cancel")}
        </Button>
        <Button
          size="small"
          isDisabled={isUpdating}
          leftAdornment={
            isUpdating ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
          onClick={handleSubmit}
        >
          {tCommon("label.save")}
        </Button>
      </div>
    </div>
  );
};

export default AuthorizationBox;
