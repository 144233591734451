import * as yup from "yup";
import { useFormik } from "formik";
import { tAuth } from "../../../i18n/useAppTranslation";

const schema = yup.object({
  code: yup.string().required(tAuth("formError.twoStepCodeRequired")),
});

export function useFormTwoFactorLogin({ onSubmit }) {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
    },
    validationSchema: schema,
    onSubmit,
  });

  const hasError = (field) => {
    return Boolean(errors[field] && touched[field]);
  };

  return {
    form: values,
    isFormValid: isValid,
    hasError,
    submit: handleSubmit,
    onFormValueChange: handleChange,
    onFormValueBlur: handleBlur,
    errors,
  };
}
