import { useRestoreCustomerNotesMutation } from "../../../../api/deletedNotes/useRestoreCustomerNotesMutation";
import { useRestoreProcedureNotesMutation } from "../../../../api/deletedNotes/useRestoreProcedureNotesMutation";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { TABS } from "../config";

export const useRestore = ({ tab, onSuccess }) => {
  const { tSettings } = useAppTranslation.Settings();

  const restoreProcedureNotes = useRestoreProcedureNotesMutation({
    onError: () => {
      uiNotification.error(tSettings("deletedNotes.procedure.error.restore"));
    },
    onSuccess: () => {
      onSuccess();
      uiNotification.success(
        tSettings("deletedNotes.procedure.success.restore"),
      );
    },
  });

  const restoreCustomerNotes = useRestoreCustomerNotesMutation({
    onError: () => {
      uiNotification.error(tSettings("deletedNotes.customer.error.restore"));
    },
    onSuccess: () => {
      onSuccess();
      uiNotification.success(
        tSettings("deletedNotes.customer.success.restore"),
      );
    },
  });

  const initiate = (ids) => {
    if (tab === TABS.customer) {
      restoreCustomerNotes.mutate({ ids });
    } else if (tab === TABS.procedure) {
      restoreProcedureNotes.mutate({ ids });
    }
  };

  return {
    initiate,
    isLoading:
      restoreProcedureNotes.isLoading || restoreCustomerNotes.isLoading,
  };
};
