import { Maybe, identity } from "../../../utilities/fp";
import { ACTION_TYPES } from "./config";

export const initialState = {
  initialized: false,
  forAllClinics: true,
  clinics: [],
  prices: {
    original: {
      perUnit: undefined,
      perClinic: {},
    },
    changed: {
      perUnit: undefined,
      perClinic: {},
    },
  },
};

function setForAllClinics(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    forAllClinics: status || false,
  };
}

function setPrices(state, action) {
  const { preparedPrices, raw } = action.payload;
  const isForAllClinics = state.forAllClinics;

  const perUnit = isForAllClinics
    ? Maybe.of(Object.values(preparedPrices || {})[0]?.perUnit)
        .map(identity)
        .orElse("")
        .value()
    : "";

  return {
    ...state,

    forAllClinics: isForAllClinics,

    prices: {
      raw,
      original: {
        perUnit,
        perClinic: {
          ...preparedPrices,
        },
      },
      changed: {
        perUnit,
        perClinic: {
          ...preparedPrices,
        },
      },
    },
  };
}

function changePrice(state, action) {
  const { value, clinicId } = action.payload;
  const nextChanged = state.prices?.changed || {};

  if (clinicId) {
    nextChanged.perClinic[clinicId] = {
      ...nextChanged.perClinic[clinicId],
      perUnit: value,
    };
  } else {
    nextChanged.perUnit = value;
  }

  return {
    ...state,
    prices: {
      ...state.prices,
      changed: nextChanged,
    },
  };
}

function setInitialized(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    initialized: status || false,
  };
}

function setClinics(state, action) {
  const { clinics } = action.payload;

  return {
    ...state,
    clinics,
  };
}

function revertChangedPrices(state) {
  return {
    ...state,
    prices: {
      ...state.prices,
      changed: {
        ...state.prices.original,
      },
    },
  };
}

function setOriginalToChangedPrices(state) {
  return {
    ...state,
    prices: {
      ...state.prices,
      original: {
        ...state.prices.changed,
      },
    },
  };
}

export function reducer(state = initialState, action) {
  const actionByType = {
    [ACTION_TYPES.setForAllClinics]: setForAllClinics,
    [ACTION_TYPES.setPrices]: setPrices,
    [ACTION_TYPES.changePrice]: changePrice,
    [ACTION_TYPES.setInitialized]: setInitialized,
    [ACTION_TYPES.setClinics]: setClinics,
    [ACTION_TYPES.revertChangedPrices]: revertChangedPrices,
    [ACTION_TYPES.setOriginalToChangedPrices]: setOriginalToChangedPrices,
  };

  return actionByType?.[action.type]?.(state, action) || state;
}
