export const timeSlots = [
  {
    id: 8,
    name: "Any",
  },
  {
    id: 1,
    name: "8:00 - 10:00 AM",
  },
  {
    id: 2,
    name: "10:00 - 12:00PM",
  },
  {
    id: 3,
    name: "12:00 - 2:00PM",
  },
  {
    id: 4,
    name: "2:00 - 4:00PM",
  },
  {
    id: 5,
    name: "4:00 - 6:00PM",
  },
  {
    id: 6,
    name: "6:00 - 8:00PM",
  },
  {
    id: 7,
    name: "After 8:00PM",
  },
];

export const daysOfWeek = [
  {
    id: 8,
    name: "Any",
  },
  {
    id: 1,
    name: "Mon",
  },
  {
    id: 2,
    name: "Tue",
  },
  {
    id: 3,
    name: "Wed",
  },
  {
    id: 4,
    name: "Thu",
  },
  {
    id: 5,
    name: "Fri",
  },
  {
    id: 6,
    name: "Sat",
  },
  {
    id: 7,
    name: "Sun",
  },
];

export const monthsOfYear = [
  {
    id: 13,
    name: "Any",
  },
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

const currentYear = new Date().getUTCFullYear();

export const availableYears = [
  {
    id: currentYear,
    name: `${currentYear}`,
  },
  {
    id: currentYear + 1,
    name: `${currentYear + 1}`,
  },
];
