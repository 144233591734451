import React, { useMemo, useState } from "react";
import { useCommissions } from "./hooks/useCommissions";
import classes from "../../../newBusinessInsightsContent.module.scss";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import ClinicCommissions from "./ClinicCommissions";
import ProviderCommissions from "./ProviderCommissions";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "commissionsReport.info.title",
  rows: [
    "commissionsReport.info.commissionByClinic",
    "commissionsReport.info.commissionByProvider",
  ],
};

const Commissions = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const [reportType, setReportType] = useState({
    value: "clinic",
    label: "Clinic",
  });

  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
    report_type: reportType.value,
  };
  const { data, isLoading } = useCommissions(requestParams);

  const isTableDataEmpty =
    !isLoading &&
    !data?.reportDataClinic?.length &&
    !data?.reportDataProvider?.length;

  const clinicData = data?.reportDataClinic.map(
    (data) => ({
      clinicName: data.clinic_name,
      totalSales: data.pos_invoice_sub_total,
      netSales: data.emp_total_net_sales,
      totalProcedureSales: data.payroll_amount,
      commissionAmount: data.commission_amount,
    }),
    [data],
  );

  const providerData = data?.reportDataProvider.map(
    (data) => ({
      employee: data.user_name,
      totalSales: data.pos_invoice_sub_total,
      netSales: data.emp_total_net_sales,
      totalProcedureSales: data.payroll_amount,
      commissionPercent: data.commission_percentage,
      commissionAmount: data.commission_amount,
    }),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const handleChangeReportType = (type) => {
    setReportType(type);
  };
  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Commissions"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        reportType={reportType}
        reportTypeOptions={[
          { label: "Clinic", value: "clinic" },
          { label: "Provider", value: "employee" },
        ]}
        handleChangeReportType={handleChangeReportType}
        downloadRoute={HTTP_ENDPOINTS.getCommissionReport()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          {reportType.value === "clinic" && (
            <ClinicCommissions tableData={clinicData} />
          )}
          {reportType.value === "employee" && (
            <ProviderCommissions tableData={providerData} />
          )}
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isTableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default Commissions;
