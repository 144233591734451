import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import moment from "moment";
import { Box } from "../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import classes from "../sass/Table.module.scss";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../../shared/SortAdornment/SortAdornment";
import { history } from "../../../../../../history";
import { SALES_MEMBERSHIP_REPORTS_ORDER_BY } from "../../../../../../api/queries/useSalesMembershipReportsQuery";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import {
  addEnumerableSuffix,
  capitalize,
  formatCurrency,
} from "../../../../../../utilities/general";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { splitMonthsByYearsAndMonths } from "../../../../../../utilities/date";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../consts/general";

export function Table({ reports, order }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const goToReport = (report) => {
    history.push(
      window.location.pathname +
        `?${queryString.stringify({
          id: report.id,
        })}`,
    );
  };

  const formatLoyalty = (loyalty) => {
    if (loyalty === 0) {
      return tCommon("symbol.longDash");
    }

    const loyaltyWithYears = splitMonthsByYearsAndMonths(loyalty);

    const years =
      loyaltyWithYears.years > 0
        ? tCommon("label.yearsCount", {
            count: loyaltyWithYears.years,
          })
        : "";

    const months =
      loyaltyWithYears.months > 0
        ? tCommon("label.monthsCount", {
            count: loyaltyWithYears.months,
          })
        : "";

    return `${years} ${months}`;
  };

  const formatMembershipFee = (fee, currency) => {
    if (fee === 0) {
      return tCommon("label.free");
    }
    if (!fee) {
      return tCommon("symbol.longDash");
    }
    return formatCurrency(fee, currency);
  };

  const data = reports.value.map((r) => ({
    id: r.id,

    clientName: r.clientName || tCommon("label.patientDeleted"),

    clinicName: r.clinicName || tCommon("symbol.longDash"),

    tierName: r.tierName || tCommon("symbol.longDash"),

    membershipFee: formatMembershipFee(r.membershipFeeAmount, r.currency),

    drawDay: addEnumerableSuffix(r.drawDay) || tCommon("symbol.longDash"),

    startDate: r.startDate
      ? moment(r.startDate).format(
          user?.previewDateFormat || PREVIEW_DATE_FORMAT,
        )
      : tCommon("symbol.longDash"),

    subscriptionType:
      capitalize(r.subscriptionType) || tCommon("symbol.longDash"),

    status: capitalize(r.status) || tCommon("symbol.longDash"),

    loyalty: formatLoyalty(r.loyalty),

    nextAppointmentDate: r.nextAppointmentDate
      ? moment(r.nextAppointmentDate).format(
          user?.previewDateFormat || PREVIEW_DATE_FORMAT,
        )
      : tCommon("symbol.longDash"),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={39.06} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            headClassName={classes.tableHead}
            bgColor="white"
            keyExtractor={(row) => row.id}
            onRowClick={goToReport}
            rowClassName={classes.row}
            colClassName={classes.col}
            data={data}
            cols={[
              {
                data: renderSortWrap(
                  tCommon("label.patientName"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.clientName,
                ),
                accessor: "clientName",
                headColClassName: classes.bigColWidth,
              },
              {
                data: renderSortWrap(
                  tCommon("label.clinic"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.clinicName,
                ),
                accessor: "clinicName",
              },
              {
                data: renderSortWrap(
                  tCommon("label.membershipName"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.tierName,
                ),
                accessor: "tierName",
                headColClassName: classes.biggerColWidth,
              },
              {
                data: renderSortWrap(
                  tCommon("label.membershipFee"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.membershipFeeAmount,
                ),
                accessor: "membershipFee",
              },
              {
                data: renderSortWrap(
                  tCommon("label.drawDay"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.drawDay,
                ),
                accessor: "drawDay",
              },
              {
                data: renderSortWrap(
                  tCommon("label.startDate"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.startDate,
                ),
                accessor: "startDate",
              },
              {
                data: renderSortWrap(
                  tCommon("label.membershipType"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.subscriptionType,
                ),
                accessor: "subscriptionType",
              },
              {
                data: renderSortWrap(
                  tCommon("label.status"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.status,
                ),
                accessor: "status",
              },
              {
                data: renderSortWrap(
                  tBi("membershipOverviewReport.table.loyalty"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.loyalty,
                ),
                accessor: "loyalty",
              },
              {
                data: renderSortWrap(
                  tCommon("label.nextAppointmentShort"),
                  SALES_MEMBERSHIP_REPORTS_ORDER_BY.nextAppointmentDate,
                ),
                accessor: "nextAppointmentDate",
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};
