import React, { useEffect } from "react";
import { FormCreate } from "../../shared/FormCreate/FormCreate";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { getPathParams } from "../../utilities";
import styles from "./styles.module.scss";
import { useTemplate } from "./hooks/useTemplate";
import { useSubmit } from "./hooks/useSubmit";
import { useForm } from "./hooks/useForm";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { history } from "../../../../../../history";
import { ROUTES } from "../../../../../../consts/routes";

export const TabManual = () => {
  const { templateId } = getPathParams();
  const { tSettings } = useAppTranslation.Settings();

  const template = useTemplate();
  const submit = useSubmit();

  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      name: template.data?.name || "",
      content: "",
    },
    onSubmit: (formValues) => {
      if (!form.content.value) {
        form.content.onChange("");
      } else {
        submit
          .initiate({ ...formValues, content: form.content.value })
          .then(() => {
            history.push(ROUTES.settings.efaxTemplates());
          });
      }
    },
  });

  useEffect(() => {
    if (template.data) {
      form.content.onChange(template.data?.content);
    }
  }, [template.data]);

  return (
    <div className={styles.root}>
      <FormCreate
        isLoading={template.isLoading}
        name={{
          value: form.name.value,
          onChange: form.name.onChange,
          error: form.name.getError(),
        }}
        content={{
          value: form.content.value,
          onChange: form.content.onChange,
          error: form.content.getError(),
        }}
      />
      <Button
        onClick={form.submit}
        isDisabled={submit.isLoading || template.isLoading}
        leftAdornment={
          submit.isLoading ? (
            <CircularProgress size="small" color="white" />
          ) : undefined
        }
      >
        {templateId
          ? tSettings("efaxTemplates.form.updateSubmit")
          : tSettings("efaxTemplates.form.createSubmit")}
      </Button>
    </div>
  );
};
