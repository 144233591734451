const nameInitialState = {
  action: "",
};

const treatmentMarkingData = (state = nameInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
      };
    }
    case "VIEW_TREATMENT_MARKINGS":
      return {
        ...state,
        data: action.payload,
        action: "VIEW_TREATMENT_MARKINGS",
      };
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }

    default:
      return state;
  }
};

export default treatmentMarkingData;
