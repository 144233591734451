const verticalsInitialState = {
  action: "",
};

const VerticalsReducer = (state = verticalsInitialState, action) => {
  switch (action.type) {
    case "VERTICAL_LIST": {
      return { ...state, data: action.payload, action: "VERTICAL_LIST" };
    }
    case "CREATE_VERTICAL": {
      return { ...state, data: action.payload, action: "CREATE_VERTICAL" };
    }
    case "UPDATE_VERTICAL": {
      return { ...state, data: action.payload, action: "UPDATE_VERTICAL" };
    }
    case "VERTICAL_ENABLE_VIEW": {
      return { ...state, data: action.payload, action: "VERTICAL_ENABLE_VIEW" };
    }
    case "DELETE_VERTICAL": {
      return {
        ...state,
        data: action.payload,
        verticalID: action.verticalID,
        action: "DELETE_VERTICAL",
      };
    }
    default:
      return state;
  }
};

export default VerticalsReducer;
