import { useQuery } from "@tanstack/react-query";
import { axios } from "../../Utils";
import { QueryKeys } from "./";

const GetClinics = () => {
  const url = "/clinics?sortorder=asc&scopes=business_hours";
  return axios({ url });
};

export const useGetClinicsQuery = () => {
  return useQuery([QueryKeys.GET_ALL_CLINICS], GetClinics, {
    enabled: true,
  });
};
