import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, ORDER_TYPES, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";
import { createQueryKeyFromShape } from "../../utilities/api";

export const NO_CLINIC_PATIENTS_REPORT_SORT_FIELDS = {
  firstName: "firstname",
  lastName: "lastname",
  email: "email",
};

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  order: yup
    .object({
      by: yup
        .string()
        .test({
          test: (v) =>
            Object.values(NO_CLINIC_PATIENTS_REPORT_SORT_FIELDS).includes(v),
        })
        .required(),
      direction: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) => Object.values(ORDER_TYPES).includes(value),
        })
        .required(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          firstname: yup.string().nullable(),
          lastname: yup.string().nullable(),
          email: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const reports = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    reports,
  };
};

function createNoClinicsPatientsReportQueryKey(...args) {
  return [QUERY_KEYS.noClinicPatientsReport.get, ...args];
}

const createKeyFromShape = (shape) =>
  unwrapOr(() => createQueryKeyFromShape(removeNullishFromShape(shape)), null);

export function useNoClinicPatientsReportQuery({
  payload = {},
  options = {},
} = {}) {
  return useInfiniteQuery({
    queryKey: createNoClinicsPatientsReportQueryKey(
      createKeyFromShape(payload.order),
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.noClinicPatientsReport.get(), {
        params: removeNullishFromShape({
          page: pageParam,
          page_size: req.pageSize,
          sort_by: req.order?.by,
          order: req.order?.direction,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
