import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./InputSecondary.module.scss";

export function InputSecondary({
  className,
  type,
  min,
  max,
  value,
  name,
  onChange,
  isDisabled,
  isError,
  placeholder,
}) {
  return (
    <input
      className={cx(
        classes.root,
        {
          [classes.error]: isError,
          [classes.disabled]: isDisabled,
        },
        className,
      )}
      type={type}
      min={min}
      max={max}
      value={value}
      name={name}
      onChange={onChange}
      disabled={isDisabled}
      placeholder={placeholder}
    />
  );
}

InputSecondary.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  placeholder: PropTypes.string,
};

InputSecondary.defaultProps = {
  className: undefined,
  type: "text",
  min: undefined,
  max: undefined,
  value: undefined,
  name: undefined,
  onChange: () => {},
  isDisabled: false,
  isError: false,
  placeholder: undefined,
};
