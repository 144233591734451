import React from "react";
import PropTypes from "prop-types";
import { ConsentHeader } from "../../../boxes/ConsentHeader/ConsentHeader";
import moment from "moment";
import { passOr } from "../../../utilities/general";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { useClientQuery } from "../../../api/queries/useClientQuery";

export function Header({ clientId }) {
  const clientQuery = useClientQuery({ clientId: Number(clientId) });
  const currentUserQuery = useCurrentUserQuery();

  const fullName = clientQuery.data?.fullName || "";
  const birthDay = clientQuery.data?.dateOfBirth;

  const formattedBirthDay = passOr(birthDay && moment(birthDay).isValid(), null, () =>
    moment.utc(birthDay).format(currentUserQuery.data?.dateFormat),
  );

  return (
    <ConsentHeader
      name={fullName}
      birthDay={formattedBirthDay}
      isLoading={clientQuery.isLoading || currentUserQuery.isLoading}
    />
  );
}

Header.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
