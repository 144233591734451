import React from "react";
import cx from "clsx";
import { withRouter } from "react-router";
import NewBusinessInsightsMenu from "./NewBusinessInsightsMenu";
import NewBusinessInsightsContent from "./NewBusinessInsightsContent";
import classes from "./newBusinessInsights.module.scss";
import { HeaderActionsProvider } from "./components/HeaderActionsProvider";

const NewBusinessInsights = () => {
  return (
    <div
      id="content"
      className={cx(classes.container, "content", "setting-wrapper")}
    >
      <NewBusinessInsightsMenu />
      <HeaderActionsProvider>
        <NewBusinessInsightsContent />
      </HeaderActionsProvider>
    </div>
  );
};

export default withRouter(NewBusinessInsights);
