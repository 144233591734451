import { PROCEDURE_AREA } from "../../shared/Info/Info.consts";

export const TEMPLATE_IMAGES_URL = "/images/procedure_template_images";

export const TEMPLATE_IMAGE_NAMES = {
  front: "front.png",
  left: "left.png",
  right: "right.png",
};

export const REQUIRED_IMAGE_FORM_FIELDS = [
  "frontImage",
  "image45Left",
  "image45Right",
];

export const REQUIRED_FORM_IMAGE_FIELDS_TO_NAMES_MAP = {
  [REQUIRED_IMAGE_FORM_FIELDS[0]]: TEMPLATE_IMAGE_NAMES.front,
  [REQUIRED_IMAGE_FORM_FIELDS[1]]: TEMPLATE_IMAGE_NAMES.left,
  [REQUIRED_IMAGE_FORM_FIELDS[2]]: TEMPLATE_IMAGE_NAMES.right,
};

export const IS_ONE_IMAGE_REQUIRED = (procedureArea) =>
  [PROCEDURE_AREA.laser, PROCEDURE_AREA.coolSculpting].includes(procedureArea);

export const IS_NONE_IMAGES_REQUIRED = (procedureArea) =>
  procedureArea === PROCEDURE_AREA.other;
