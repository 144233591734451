import PropTypes from "prop-types";

const servicePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  clinicName: PropTypes.string.isRequired,
});

export const serviceCategoryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  clinicNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  services: PropTypes.arrayOf(servicePropType).isRequired,
});
