import { useQuery } from "@tanstack/react-query";
import { axios } from "../../Utils";
import { QueryKeys } from "./QueryKeys";

export const GeServicesList = ({ providerIds, clinicIds }) => {
  const clinic_ids = Array.isArray(clinicIds);
  const provider_ids = Array.isArray(providerIds);
  const url =
    clinic_ids || provider_ids
      ? `/waitlist/get-services-by-clinics-providers`
      : `/services`;

  const params =
    clinic_ids || provider_ids
      ? {
          clinic_ids: clinicIds,
          provider_ids: providerIds,
        }
      : {};

  return axios(url, { params });
};

export const useGetServicesListQuery = (payload) => {
  return useQuery([QueryKeys.GET_SERVICES_BY_PROVIDERS, payload], () =>
    GeServicesList(payload),
  );
};
