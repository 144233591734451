import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { useGrossSales } from "./hooks/useGrossSales";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import Header from "../../../components/Header";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { Box } from "../../../../../shared/Box/Box";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "totalSalesReport.info.title",
  rows: [
    "totalSalesReport.info.clinicTotalSales",
    "totalSalesReport.info.providerTotalSales",
  ],
};

const TotalSales = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };

  const { data, isLoading } = useGrossSales(requestParams);

  const isClinicDataEmpty = !isLoading && !data?.clinicGrossSales?.length;
  const isProviderDataEmpty = !isLoading && !data?.providerGrossSales?.length;

  const clinicData = useMemo(
    () =>
      data?.clinicGrossSales.map((clinic) => ({
        clinicName: clinic.clinic_name,
        clinicTotalSales: clinic.gross_sales,
        clinicId: clinic.clinic_id,
        id: clinic.clinic_id,
      })),
    [data],
  );

  const providerData = useMemo(() =>
    data?.providerGrossSales.map(
      (provider) => ({
        providerName: provider.provider,
        providerTotalSales: provider.gross_sales,
        providerId: provider.provider_id,
        id: provider.provider_id,
      }),
      [data],
    ),
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Total Sales Report"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getGrossSales()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            keyExtractor={(row) => row.clinicId}
            bgColor="white"
            onRowClick={(row) =>
              window.open(`/sales/invoices/clinic/${row.clinicId}`)
            }
            cols={[
              { data: "Clinic Name", accessor: "clinicName" },
              { data: "Clinic Total Sales", accessor: "clinicTotalSales" },
            ]}
            data={clinicData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isClinicDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
        <div className={classes.tableContainer}>
          <Table
            keyExtractor={(row) => row.providerId}
            bgColor="white"
            onRowClick={(row) =>
              window.open(`/sales/invoices/provider/${row.providerId}`)
            }
            cols={[
              { data: "Provider Name", accessor: "providerName" },
              { data: "Provider Total Sales", accessor: "providerTotalSales" },
            ]}
            data={providerData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isProviderDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalSales;
