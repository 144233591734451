import React from "react";
import PropTypes from "prop-types";
import classes from "./ImageUploader.module.scss";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import { CloseIcon } from "../../assets/Icons/CloseIcon";
import { Small } from "./shared/Small/Small";
import { ImageIcon } from "../../assets/Icons/ImageIcon";

const SIZES = {
  small: "small",
};

export function ImageUploader({
  // name should be unique on page
  name,
  value,
  onChange,
  onRemove,
  defaultImage,
  size,
  isLoading,
}) {
  const { tCommon } = useAppTranslation.Common();

  if (size === SIZES.small) {
    return (
      <Small
        name={name}
        handleChange={onChange}
        image={value}
        isLoading={isLoading}
        handleRemove={onRemove}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.imageBox}>
        {!!value && !isLoading && (
          <button className={classes.remove} onClick={onRemove}>
            <CloseIcon />
          </button>
        )}
        {!value ? (
          <>
            {defaultImage ? (
              <img src={defaultImage} alt="default" />
            ) : (
              <ImageIcon />
            )}
          </>
        ) : (
          <img src={value || defaultImage} alt={name} />
        )}
      </div>
      <label htmlFor={name} className={classes.upload}>
        {isLoading && <CircularProgress size="tiny" />}
        {tCommon("label.upload")}
        <input
          type="file"
          name={name}
          id={name}
          onChange={onChange}
          disabled={isLoading}
        />
      </label>
    </div>
  );
}

ImageUploader.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  isLoading: PropTypes.bool,
};
