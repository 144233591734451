import React from "react";
import PropTypes from "prop-types";
import { Box } from "../../../../../shared/Box/Box";
import { InputSearch } from "../../../../../boxes/InputSearch/InputSearch";
import classes from "./Header.module.scss";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { PlusIcon } from "../../../../../assets/Icons/PlusIcon";

export function Header({
  search,
  selectedLength,
  onRemoveMany,
  onAdd,
  isRemovingMany,
  isAdding,
}) {
  const { tInbox } = useAppTranslation.Inbox();

  return (
    <Box className={classes.root}>
      <div className={classes.side}>
        <div className={classes.title}>{tInbox("blackList.title")}</div>
        <InputSearch value={search.value} onChange={search.onChange} />
      </div>
      <div className={classes.side}>
        {selectedLength > 0 && (
          <Button
            size="small"
            color="error"
            onClick={onRemoveMany}
            isDisabled={isRemovingMany}
            leftAdornment={
              isRemovingMany ? (
                <CircularProgress size="tiny" color="white" />
              ) : undefined
            }
          >
            {tInbox("blackList.action.removeMany", {
              count: selectedLength || 0,
            })}
          </Button>
        )}
        <Button
          size="small"
          onClick={onAdd}
          isDisabled={isAdding}
          leftAdornment={
            isAdding ? (
              <CircularProgress color="white" size="tiny" />
            ) : (
              <PlusIcon />
            )
          }
        >
          {tInbox("blackList.action.add")}
        </Button>
      </div>
    </Box>
  );
}

Header.propTypes = {
  search: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  selectedLength: PropTypes.number.isRequired,
  onRemoveMany: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
