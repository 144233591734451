/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

export default class ClockMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClockAction = () => {
    this.props.handleClockAction();
  };

  render() {
    return (
      <div className="clockIn">
        <a
          className="show-desktop ClockLoggedInBtn"
          title="Notifications"
          onClick={this.handleClockAction}
        >
          {this.props.clock_label}
        </a>
      </div>
    );
  }
}
