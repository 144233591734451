import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../shared/Select/Select";
import { useClinicsQuery } from "../../../../../api/queries/useClinicsQuery";
import { selectOptionSchema } from "../../../../../shared/Select/Select.schemas";
import { hasLength } from "../../../../../utilities/general";
import { Modal } from "../../../../../shared/Modal/Modal";
import { Button } from "../../../../../shared/Button/Button";
import classes from "./ModalFilter.module.scss";
import { useProvidersQuery } from "../../../../../api/queries/useProvidersQuery";

const schema = yup.object({
  clinics: yup.array().of(selectOptionSchema),
  providers: yup.array().of(selectOptionSchema),
});

const initialValues = {
  clinics: [],
  providers: [],
};

export function ModalFilter({ isOpen, onClose, filter }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: clinics, isLoading: isClinicsLoading } = useClinicsQuery();
  const { data: providers, isLoading: isProvidersLoading } =
    useProvidersQuery();

  const handleApply = () => {
    filter.update(values);
    onClose();
  };

  const { values, setFieldValue, dirty, handleSubmit, setValues } = useFormik({
    onSubmit: handleApply,
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...filter.value,
    },
  });

  const clinicOptions =
    clinics?.map((c) => ({
      label: c.name,
      value: c.id,
    })) || [];

  const providerOptions = (providers?.data?.data || []).map((provider) => ({
    label: provider.full_name,
    value: provider.id,
  }));

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Title>{tCommon("label.filter")}</Modal.Title>}
      contentClassName={classes.root}
      shouldCloseOnOverlayClick={false}
      footer={
        <div className={classes.footer}>
          <Button onClick={handleSubmit} isDisabled={!dirty}>
            {tCommon("label.apply")}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {tCommon("label.cancel")}
          </Button>
          {Object.values(values).some(hasLength) && (
            <Button variant="outlined" onClick={() => setValues(initialValues)}>
              {tCommon("label.reset")}
            </Button>
          )}
        </div>
      }
    >
      <div>
        <InputLabel>{tCommon("label.clinics")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.clinics}
          onChange={(options) => setFieldValue("clinics", options)}
          options={clinicOptions}
          isLoading={isClinicsLoading}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.providers")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.providers}
          onChange={(options) => setFieldValue("providers", options)}
          options={providerOptions}
          isLoading={isProvidersLoading}
        />
      </div>
    </Modal>
  );
}

ModalFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    value: PropTypes.object,
    update: PropTypes.func,
  }).isRequired,
};
