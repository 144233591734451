import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { consentSchema } from "./schema";

const responseSchema = yup.array().of(consentSchema);

const composeResponse = (res) => {
  if (Array.isArray(res?.data?.data)) {
    return res.data.data;
  }
  return [];
};

function createConsentsQueryKey() {
  return [QUERY_KEYS.consents.getAll];
}

export function useConsentsQuery(options = {}) {
  return useQuery(
    createConsentsQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.consents.getAll());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
