import { useMemo } from "react";
import { useClinicsQuery } from "../../../../../../../../api/queries/useClinicsQuery";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";

export const useClinicOptions = () => {
  const { tCommon } = useAppTranslation.Common();

  const { data: clinics, isLoading } = useClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchClinics"));
    },
  });

  const options = useMemo(
    () => clinics?.map((c) => ({ label: c.name, value: c.id })),
    [clinics],
  );

  return {
    data: options,
    isLoading,
  };
};
