import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";

export function usePPAnnouncementBannerDeactivateMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (bannerId) =>
      http.patch(
        HTTP_ENDPOINTS.patientPortal.announcementBanner.deactivate(bannerId),
      ),
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.patientPortal.announcementBanner.getAll],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.patientPortal.announcementBanner.getOne],
      });
      options?.onSuccess?.();
    },
  });
}
