import React, { useEffect } from "react";
import moment from "moment";
import { LayoutSettings } from "../../../boxes/LayoutSettings/LayoutSettings";
import { ButtonScrollTop } from "../../../shared/ButtonScrollTop/ButtonScrollTop";
import { Header } from "./shared/Header/Header";
import styles from "./styles.module.scss";
import { Tabs } from "./shared/Tabs/Tabs";
import { TABS } from "./config";
import { Box } from "../../../shared/Box/Box";
import { StickyBox } from "../../../shared/StickyBox/StickyBox";
import { TableProcedure } from "./shared/TableProcedure/TableProcedure";
import { TableCustomer } from "./shared/TableCustomer/TableCustomer";
import { useProcedureNotes } from "./hooks/useProcedureNotes";
import { useCustomerNotes } from "./hooks/useCustomerNotes";
import { useQueryParamsState } from "../../../utilities/hooks/useQueryParamsState";
import { useRestore } from "./hooks/useRestore";
import { useMultiselectEntity } from "../../../utilities/hooks/useMultiselectEntity";
import { useDebouncedQueryParamState } from "../../../helpers/hooks/useDebouncedQueryParamState";
import { API_DATE_FORMAT } from "../../../consts/api";
import {
  apiDateStringToDate,
  dateToApiDateString,
} from "../../../utilities/api";
import { unwrapOr } from "../../../utilities/general";

export const SettingsDeletedNotes = () => {
  const [search, debouncedSearch, setSearch] = useDebouncedQueryParamState(
    "search",
    "",
  );

  const [state, setState] = useQueryParamsState({
    tab: TABS.procedure,
    from: moment().startOf("month").format(API_DATE_FORMAT),
    to: moment().endOf("month").format(API_DATE_FORMAT),
  });

  const rowSelector = useMultiselectEntity({
    allEntities: [],
  });

  const restore = useRestore({
    tab: state.tab,
    onSuccess: rowSelector.resetSelected,
  });

  const procedureNotes = useProcedureNotes({
    isActive: state.tab === TABS.procedure,
    searchTerm: debouncedSearch,
    period: { from: state.from, to: state.to },
  });

  const customerNotes = useCustomerNotes({
    isActive: state.tab === TABS.customer,
    searchTerm: debouncedSearch,
    period: { from: state.from, to: state.to },
  });

  useEffect(() => {
    if (state.tab === TABS.procedure) {
      rowSelector.resetSelected();
      rowSelector.setEntities(procedureNotes.data.value.map((x) => x.id));
    } else if (state.tab === TABS.customer) {
      rowSelector.resetSelected();
      rowSelector.setEntities(customerNotes.data.value.map((x) => x.id));
    }
  }, [state.tab, procedureNotes.data.value, customerNotes.data.value]);

  return (
    <LayoutSettings contentClassName={styles.root}>
      <Header
        search={{
          value: search,
          onChange: setSearch,
        }}
        restore={{
          count: rowSelector.selected.length,
          isLoading: restore.isLoading,
          initiate: () => restore.initiate(rowSelector.selected),
        }}
        period={{
          value: {
            from: unwrapOr(() => apiDateStringToDate(state.from), new Date()),
            to: unwrapOr(() => apiDateStringToDate(state.to), new Date()),
          },
          onChange: ({ from, to }) =>
            setState({
              from: dateToApiDateString(from),
              to: dateToApiDateString(to),
            }),
        }}
      />
      <Box className={styles.content}>
        {!!state.tab && (
          <Tabs active={state.tab} onChange={(tab) => setState({ tab })} />
        )}
        <div className={styles.tableWrap}>
          {state.tab === TABS.procedure && (
            <TableProcedure
              data={procedureNotes.data}
              order={procedureNotes.order}
              rowSelector={rowSelector}
            />
          )}
          {state.tab === TABS.customer && (
            <TableCustomer
              data={customerNotes.data}
              order={customerNotes.order}
              rowSelector={rowSelector}
            />
          )}
        </div>
      </Box>
      <StickyBox>
        <ButtonScrollTop />
      </StickyBox>
    </LayoutSettings>
  );
};
