import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

export const BI_GIFT_CARD_HISTORY_CHANGE_TYPES = {
  editBalance: "edit_balance",
  expireCard: "edit_expiration_date",
};

const requestSchema = yup.object({
  giftCardId: yup.number().required(),
});

const responseSchema = yup.object({
  history: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        after: yup.string().required(),
        before: yup.string().required(),
        created_at: yup.string().required(),
        egift_voucher_id: yup.number().required(),
        reason: yup.string().nullable(),
        type: yup.string().required(),
        updated_at: yup.string().required(),
        user_id: yup.number().required(),
        user_name: yup.string().required(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  return {
    history: res?.data?.data || [],
  };
};

const createBiGiftCardHistoryQueryKey = (id) => [
  QUERY_KEYS.bi.giftCardHistory,
  id,
];

export function useBiGiftCardHistoryQuery({ payload = {}, options = {} } = {}) {
  return useQuery(
    createBiGiftCardHistoryQueryKey(payload.giftCardId),
    async () => {
      const req = requestSchema.validateSync(payload, { strict: true });

      const res = await http.get(HTTP_ENDPOINTS.bi.getGiftCardHistory(), {
        params: {
          egift_voucher_id: req.giftCardId,
        },
      });

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
