import { procedureDocumentId } from "../../utilities";

/**
 * Accept optionsToMerge and merge them into targetOptions.
 * If some option from optionsToMerge is already present in
 * targetOptions (compare by value) - they will be replaced
 * with option from optionsToMerge.
 *
 * @template {Array<{
 *    label: string;
 *    value: string;
 * }>} T
 * @param {T} optionsToMerge
 * @param {T} targetOptions
 * @returns T
 */
export function mergeDocumentOptions(optionsToMerge, targetOptions) {
  return optionsToMerge.reduce(
    (carry, optionToMerge) => {
      var duplicateIndex = carry.findIndex(
        (i) => i.value === optionToMerge.value,
      );

      if (duplicateIndex !== -1) {
        const nextCarry = [...carry];
        nextCarry[duplicateIndex] = {
          ...carry[duplicateIndex],
          ...optionToMerge,
        };
        return nextCarry;
      }

      return [...carry, optionToMerge];
    },
    [...targetOptions],
  );
}

/**
 * The purpose of this function is to remove duplicates from selectedOptions.
 *
 * Logic:
 *
 * If selectedIds has duplicates then:
 *  - If all duplicates are NOT completed - leave one
 *  - If all duplicates are completed - leave all
 *  - If some duplicates are completed - leave only completed
 *
 * @param {Array<string>} selectedIds
 *
 * @param {Array<{
 *    value: string;
 *    isCompleted?: boolean;
 * }>} patientDocumentOptions
 */
export function dedupSelectedDocumentOptions(
  selectedIds,
  patientDocumentOptions,
) {
  if (selectedIds.length === 0 || selectedIds.length === 1) {
    return selectedIds;
  }

  const duplicatesMap = selectedIds.reduce(
    (carry, id) => {
      var idKey = procedureDocumentId.parse(id);

      if (carry[idKey]) {
        return carry;
      }

      var duplicates = carry.rest.filter(
        (x) => procedureDocumentId.parse(x) === idKey,
      );

      var nextRest = carry.rest.filter((x) => !duplicates.includes(x));

      if (duplicates.length > 1) {
        return {
          ...carry,
          [idKey]: duplicates,
          rest: nextRest,
        };
      }

      return carry;
    },
    {
      rest: selectedIds,
    },
  );

  return Object.entries(duplicatesMap).reduce((carry, [key, values]) => {
    if (key === "rest") {
      return [...carry, ...values];
    }

    var completed = values.filter(
      (id) => patientDocumentOptions.find((o) => o.value === id)?.isCompleted,
    );

    if (!completed.length) {
      return [...carry, values[0]];
    }

    return [...carry, ...completed];
  }, []);
}

/**
 * @param {Array<string>} ids
 * @param {Array<{
 *    value: string;
 * }>} options
 */
export function pickSelectedOptions(ids, options) {
  if (!ids?.length) {
    return [];
  }

  var result = [];

  ids.forEach((id) => {
    const option = options.find((o) => o.value === id);
    if (option) {
      result.push(option);
    }
  });

  return result;
}
