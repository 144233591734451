import React from "react";
import PropTypes from "prop-types";
import classes from "./SearchResultTile.module.scss";
import { dispatch } from "../../../../store/store";
import { globalSearch } from "../../../../store/globalSearch";
import { history } from "../../../../history";
import { useGlobalSearchService } from "../../hooks/useGlobalSearchService";

export function SearchResultTile({ pattern }) {
  const globalSearchService = useGlobalSearchService();

  const follow = () => {
    dispatch(globalSearch.actions.searchOpenChange(false));
    history.push(globalSearchService.getTokenValueByPattern(pattern));
    globalSearchService.savePattern(pattern);
  };

  return (
    <button className={classes.root} onClick={follow}>
      <i className="fa fa-file" aria-hidden="true"></i>
      <div className={classes.pattern}>{pattern}</div>
    </button>
  );
}

SearchResultTile.propTypes = {
  pattern: PropTypes.string.isRequired,
};
