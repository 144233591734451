import { ACTION_TYPES } from "./consts";

export const actions = {
  open: () => ({
    type: ACTION_TYPES.open,
  }),
  close: () => ({
    type: ACTION_TYPES.close,
  }),
};
