import { DEFAULT_MEMBERSHIP_COLOR } from "../../../../../../../consts/general";
import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import MembershipBadge from "../../../../../../Common/MembershipBadge";
import TIMER_ICON from "../../../../../Documents/icons/TimerIcon";
import { getPathParams } from "../../../../utils/utils";
import { useClientQuery } from "../../../../../../../api/queries/useClientQuery";
import styles from "./MemberTypes.module.scss";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";

export function MemberType() {
  const { clientId } = getPathParams();
  const { data: client } = useClientQuery({ clientId });
  const { data: user } = useCurrentUserQuery();

  return (
    <div className={styles.memberTypeOuter}>
      {client?.patientMembershipSubscription?.map((data, index) => {
        const getMembershipColor = () => {
          if (data?.membership_tier_id > 0) {
            return (
              data?.membership_tier?.membership_color ||
              DEFAULT_MEMBERSHIP_COLOR
            );
          }
          if (data?.membership_tier_id === 0) {
            return (
              client?.accountPreferences?.membership_color ||
              DEFAULT_MEMBERSHIP_COLOR
            );
          }
          return DEFAULT_MEMBERSHIP_COLOR;
        };

        if (data?.last_invoice?.payment_status === "past_due") {
          return (
            <Fragment key={data.id}>
              <span
                className="colorRed exclamationPatientProfile"
                data-for={`client-payment-error-${data.id}`}
              >
                <i className="fa fa-exclamation-circle"></i>
              </span>
              <ReactTooltip
                className="client-payment-error-tooltip"
                id={`client-payment-error-${data.id}`}
                place="right"
                border
                textColor="red"
                backgroundColor="#fff"
                borderColor="red"
                arrowColor="red"
              >
                <span>
                  Payment declined for {data.membership_tier?.tier_name}!
                </span>
              </ReactTooltip>
            </Fragment>
          );
        }
        return (
          <MembershipBadge
            key={"patientMembershipSubscription-" + index}
            color={getMembershipColor()}
            displayPage="bedge-client-profile"
            onHold={data.membership_on_hold}
          />
        );
      })}
      {[
        process.env.REACT_APP_JUVLY_ACC_ID,
        process.env.REACT_APP_CC_ACC_ID,
      ].includes(user?.account?.id) && <img alt="infoImg" />}
      {Boolean(client?.documentsExpirationDate) && (
        <a className="cursor-default flex" data-tip data-for="client-timer">
          <TIMER_ICON width="32px" height="32px" color="red" />
          <ReactTooltip
            className="client-payment-error-tooltip"
            place="right"
            border
            textColor="red"
            backgroundColor="#fff"
            borderColor="red"
            arrowColor="red"
            id="client-timer"
          >
            <span>Patient has a document that will expire soon.</span>
          </ReactTooltip>
        </a>
      )}
    </div>
  );
}