import React from "react";
import { LANGUAGE_DATA_KEYS } from "../../../consts/localStorage";
import { getLanguageData } from "../../../utilities/localStorage";

const Loader = () => {
  const langData = getLanguageData(LANGUAGE_DATA_KEYS.appointments);

  return (
    <div className="new-loader text-left displayBlock positionFixed">
      <div className="loader-outer">
        <img
          id="loader-outer"
          src="/images/Eclipse.gif"
          className="loader-img"
          alt="loading"
        />
        <div id="modal-confirm-text" className="popup-subtitle">
          {langData.appointment_processing_please_wait}
        </div>
      </div>
    </div>
  );
};

export default Loader;
