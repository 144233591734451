import React from "react";
import { Link } from "react-router-dom";
import cx from "clsx";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import styles from "../sass/Header.module.scss";
import { useProviderDashboard } from "../ProviderDashboard/hooks/useProviderDashboard";
import { getIsAcceleratorPlan } from "../../../Utils";

const Header = ({ onAddWidget, match }) => {
  const { widgetsList } = useProviderDashboard();

  const isProviderDashboardAvailable =
    Boolean(widgetsList?.length) && getIsAcceleratorPlan();

  return (
    <div className="setting-setion m-b-10">
      <div className={cx("membership-title", styles.headerContainer)}>
        <span className="cursor-pointer">Dashboard</span>
        {isProviderDashboardAvailable && (
          <div className={styles.dashboardTabs}>
            <Link
              to="/dashboard"
              className={cx(!match.path.includes("/provider") && styles.active)}
            >
              Practice
            </Link>
            <Link
              to="/dashboard/provider"
              className={cx(match.path.includes("/provider") && styles.active)}
            >
              Provider
            </Link>
          </div>
        )}
        <div className={cx("memberRightActions", styles.addWidgetButton)}>
          {onAddWidget && (
            <button onClick={onAddWidget} className="new-blue-btn pull-right">
              Add Widgets
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  onAddWidget: PropTypes.func,
};

Header.defaultProps = {
  onAddWidget: undefined,
};

export default withRouter(Header);
