const initialState = {
  action: "",
  leads: { status: "pending", data: null },
  deleteLead: { status: "pending", data: null },
  bookConvertOrMessageLead: { status: "pending", data: null },
};

const leads = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_LEADS_LOADING": {
      return {
        ...state,
        leads: { status: "loading", data: null, error: null },
      };
    }
    case "FETCH_LEADS_SUCCESS": {
      return {
        ...state,
        leads: { status: "success", data: action.payload, error: null },
      };
    }
    case "FETCH_LEADS_ERROR": {
      return {
        ...state,
        leads: { status: "error", data: null, error: action.payload },
      };
    }

    case "DELETE_LEAD_LOADING": {
      return {
        ...state,
        deleteLead: { status: "loading", data: null, error: null },
      };
    }
    case "DELETE_LEAD_SUCCESS": {
      return {
        ...state,
        deleteLead: { status: "success", data: action.payload, error: null },
      };
    }
    case "DELETE_LEAD_ERROR": {
      return {
        ...state,
        deleteLead: { status: "error", data: null, error: action.payload },
      };
    }

    case "BOOK_CONVERT_OR_MESSAGE_LEAD_LOADING": {
      return {
        ...state,
        bookConvertOrMessageLead: {
          status: "loading",
          data: null,
          error: null,
        },
      };
    }
    case "BOOK_CONVERT_OR_MESSAGE_LEAD_SUCCESS": {
      return {
        ...state,
        bookConvertOrMessageLead: {
          status: "success",
          data: action.payload,
          error: null,
        },
      };
    }
    case "BOOK_CONVERT_OR_MESSAGE_LEAD_ERROR": {
      return {
        ...state,
        bookConvertOrMessageLead: {
          status: "error",
          data: null,
          error: action.payload,
        },
      };
    }

    case "RESET_LEADS_COUNT": {
      return { ...state, action: "RESET_LEADS_COUNT" };
    }

    default:
      return state;
  }
};

export default leads;
