import React, { useRef } from "react";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import { DatePicker } from "../../../../../shared/DatePicker/DatePicker";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import {
  DEFAULT_TIME_FORMAT,
  PREVIEW_DATE_FORMAT,
} from "../../../../../consts/general";

export function DateSelect({ value, onChange }) {
  const datePickerRef = useRef();
  const { data: user } = useCurrentUserQuery();

  const timeFormat = user?.timeFormat || DEFAULT_TIME_FORMAT;

  const getMaxTodayDate = () => {
    return setHours(
      setMinutes(new Date(), parseInt(moment().format("mm"))),
      parseInt(moment().format("HH")),
    );
  };

  const getMinTodayDate = () => {
    return setHours(setMinutes(new Date(), 0), 0);
  };

  const handleChange = (nextDate) => {
    const maxTodayDate = getMaxTodayDate();
    if (
      moment().isSame(nextDate, "day") &&
      moment(nextDate).isAfter(maxTodayDate)
    ) {
      onChange(maxTodayDate);
    } else {
      onChange(nextDate);
    }
  };

  return (
    <DatePicker
      showTimeSelect
      ref={datePickerRef}
      value={
        value
          ? moment(value).format(
              `${user?.previewDateFormat || PREVIEW_DATE_FORMAT} ${
                user?.timeFormat || DEFAULT_TIME_FORMAT
              }`,
            )
          : null
      }
      onClickOutside={() => datePickerRef.current.setOpen(false)}
      maxDate={new Date()}
      minTime={
        value && moment().isSame(value, "day") ? getMinTodayDate() : undefined
      }
      maxTime={
        value && moment().isSame(value, "day") ? getMaxTodayDate() : undefined
      }
      timeFormat={timeFormat === DEFAULT_TIME_FORMAT ? "hh:mm aa" : timeFormat}
      timeIntervals={1}
      selected={value}
      onChange={handleChange}
    />
  );
}
