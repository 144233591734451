import React from "react";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const PieGraph = ({ title, totalLabel, total, options }) => {
  return (
    <div className="new-sale-col">
      <div className="setting-setion m-b-0 full-width auto-height">
        <div className="graphHeading">{title}</div>
        {total && (
          <h3 className="totalTransaction">
            {totalLabel}
            {total}
          </h3>
        )}
        <div
          className="dash-box-content egiftCardGraph businessGraphOuter"
          data-highcharts-chart="0"
        >
          <div className="highcharts-container" data-highcharts-chart="0">
            <PieChart highcharts={Highcharts} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieGraph;
