import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.object({
  data: yup.array().of(
    yup.object({
      id: yup.number().required(),
      name: yup.string().required(),
      faxNumber: yup.string().required(),
    }),
  ),
});

const composeResponse = (res) => {
  return res?.data || [];
};

const createEfaxFavouritesQueryKey = (...args) => [
  QUERY_KEYS.efax.favourites,
  ...args,
];

export const useEfaxFavouritesQuery = (payload = {}, options = {}) => {
  return useQuery(
    createEfaxFavouritesQueryKey(payload),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.efax.getFavourites(), {
        params: {
          name: payload.searchTerm,
        },
      });
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
