import React from "react";
import { Box } from "../../../../../shared/Box/Box";
import Table from "./components/Table";
import Header from "./components/Header";
import { useReport } from "./hooks/useReport";
import ReportLayout from "./components/ReportLayout";
import { useDownloadStripeDocument } from "./hooks/useDownloadStripeDocument";
import { LayoutSettings } from "../../../../../boxes/LayoutSettings/LayoutSettings";
import { tSettings } from "../../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../../widgets/EntityHeader/EntityHeader";
import classes from "./scss/stripeDocuments.module.scss";

const StripeDocuments = () => {
  const { report, headerForm } = useReport();
  const { selectedDocuments, mutation } = useDownloadStripeDocument();
  return (
    <LayoutSettings contentClassName={classes.root}>
      <EntityHeader title={tSettings("stripeDocuments.title")} />
      <Box>
        <ReportLayout>
          <Header
            headerForm={headerForm}
            selectedDocuments={selectedDocuments}
            download={mutation}
          />
          <Table report={report} selectedDocuments={selectedDocuments} />
        </ReportLayout>
      </Box>
    </LayoutSettings>
  );
};

export default StripeDocuments;
