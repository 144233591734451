import { ACTION_TYPES } from "./config";

const initialState = {
  id: undefined,
  message: undefined,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.open: {
      return {
        ...state,
        id: action.payload.id,
        message: action.payload.message,
      };
    }
    case ACTION_TYPES.close: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}
