import React from "react";
import * as yup from "yup";
import cx from "clsx";
import { useExtendedFormik } from "../../../../hooks/useExtendedFormik";
import { Textarea } from "../../../../shared/Textarea/Textarea";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import classes from "./Form.module.scss";
import { Button } from "../../../../shared/Button/Button";

export const Form = ({ initialValues, onSubmit, onCancel, className }) => {
  const { tCommon } = useAppTranslation.Common();

  const {
    values,
    handleBlur,
    handleChange,
    getError,
    handleSubmit,
    resetForm,
  } = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: yup.object({
      id: yup.mixed().nullable(),
      text: yup.string().required(),
    }),
    initialValues: initialValues || {
      id: null,
      text: "",
    },
    onSubmit,
  });

  return (
    <div className={cx(classes.root, className)}>
      <Textarea
        name="text"
        maxRows={7}
        value={values.text}
        onChange={handleChange}
        onBlur={handleBlur}
        isError={Boolean(getError("text"))}
        placeholder={tCommon("label.addNote")}
      />
      <div className={classes.footer}>
        <Button size="small" onClick={handleSubmit}>
          {tCommon("label.save")}
        </Button>
        {values.id && (
          <Button
            size="small"
            color="error"
            onClick={() => {
              resetForm();
              onCancel();
            }}
          >
            {tCommon("label.cancel")}
          </Button>
        )}
      </div>
    </div>
  );
};
