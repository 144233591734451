import React from "react";
import * as yup from "yup";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Modal } from "../../../../../../../shared/Modal/Modal";
import { useExtendedFormik } from "../../../../../../../hooks/useExtendedFormik";
import { Select } from "../../../../../../../shared/Select/Select";
import { useClinicOptions } from "./hooks/useClinicOptions";
import classes from "./ModalUpdate.module.scss";
import { Button } from "../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import { useUpdate } from "./hooks/useUpdate";

export const ModalUpdate = ({ onClose, onSuccess, patientIds }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const options = useClinicOptions();
  const update = useUpdate();

  const { values, changeField, getError, handleSubmit } = useExtendedFormik({
    enableReinitialize: true,
    initialValues: {
      clinic: null,
    },
    onSubmit: (values) => {
      update
        .initiate({
          patientIds: patientIds,
          clinicId: values.clinic.value,
        })
        .then(() => {
          onClose();
          onSuccess();
        });
    },
    validationSchema: yup.object({
      clinic: yup
        .object({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .required(tCommon("formError.required")),
    }),
  });

  return (
    <Modal
      isOpen
      size="small"
      onClose={onClose}
      contentClassName={classes.root}
      header={
        <Modal.Title>
          {tBi("noClinicPatientsReport.assignClinic.label")}
        </Modal.Title>
      }
    >
      <Select
        isSearchable
        options={options.data}
        value={values.clinic}
        isError={Boolean(getError("clinic"))}
        onChange={(value) => changeField("clinic", value)}
      />
      <div className={classes.footer}>
        <Button
          isDisabled={update.isLoading}
          onClick={handleSubmit}
          leftAdornment={
            update.isLoading ? (
              <CircularProgress color="white" size="small" />
            ) : undefined
          }
        >
          {tCommon("label.save")}
        </Button>
        <Button onClick={onClose} variant="outlined">
          {tCommon("label.cancel")}
        </Button>
      </div>
    </Modal>
  );
};
