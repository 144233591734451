import React from "react";
import PropTypes from "prop-types";
import classes from "./MedicalHistory.module.scss";
import { PatientInfo } from "./widgets/PatientInfo/PatientInfo";
import { filterValues as fv } from "./MedicalHistory.utils";
import { SocialHistory } from "./widgets/SocialHistory/SocialHistory";
import { Allergies } from "./widgets/Allergies/Allergies";
import { CurrentMedications } from "./widgets/CurrentMedications/CurrentMedications";
import { FamilyHealth } from "./widgets/FamilyHealth/FamilyHealth";
import { CurrentMedConditions } from "./widgets/CurrentMedConditions/CurrentMedConditions";
import { PastMedHistory } from "./widgets/PastMedHistory/PastMedHistory";
import {
  getNextIntersection,
  getSectionVisibilityStatuses,
} from "../MergeProfilesMain.utils";
import { Empty } from "../../../../shared/Empty/Empty";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { ModalSummary } from "./boxes/ModalSummary/ModalSummary";

export function MedicalHistory({ patients, intersection, onChange }) {
  const { tClients } = useAppTranslation.Clients();

  const visibilityStatuses = getSectionVisibilityStatuses(
    patients?.map((p) => p.new_medical_history || {}) || [],
  );

  const medicalHistories =
    patients?.map((p) => p?.new_medical_history || {}) || [];

  const isNothingVisible = Object.values(visibilityStatuses).every((v) => !v);

  const handleChange = (type, value) => {
    onChange(
      getNextIntersection({
        intersection,
        actionType: type,
        value,
      }),
    );
  };

  return (
    <div className={classes.root}>
      {isNothingVisible && (
        <Empty color="gray1" position="center">
          {tClients("medicalHistory.merge.empty")}
        </Empty>
      )}
      {visibilityStatuses.patientInfo && (
        <PatientInfo
          height={intersection?.height}
          weight={intersection?.weight}
          onChange={handleChange}
          options={{
            height: fv(medicalHistories.map((h) => h.height)),
            weight: fv(medicalHistories.map((h) => h.weight)),
          }}
        />
      )}
      {visibilityStatuses.socialHistory && (
        <SocialHistory
          onChange={(next) => handleChange("socialHistory", next)}
          intersection={intersection?.social_history || {}}
          socialHistories={medicalHistories.map((h) => h.social_history || {})}
        />
      )}
      {visibilityStatuses.allergies && (
        <Allergies
          onChange={(next) => handleChange("allergies", next)}
          intersection={intersection?.allergy || {}}
          allergies={medicalHistories.map((h) => h.allergy || {})}
        />
      )}
      {visibilityStatuses.currentMedications && (
        <CurrentMedications
          onChange={(next) => handleChange("currentMedications", next)}
          intersection={intersection?.current_medication || {}}
          medications={medicalHistories.map((h) => h.current_medication || {})}
        />
      )}
      {visibilityStatuses.familyHealth && (
        <FamilyHealth
          onChange={(next) => handleChange("familyHealth", next)}
          intersection={intersection?.family_health_history || {}}
          healthHistories={medicalHistories.map(
            (h) => h.family_health_history || {},
          )}
        />
      )}
      {visibilityStatuses.currentMedConditions && (
        <CurrentMedConditions
          onChange={(next) => handleChange("currentMedConditions", next)}
          intersection={intersection?.current_medical_history || {}}
          conditions={medicalHistories.map(
            (h) => h.current_medical_history || {},
          )}
        />
      )}
      {visibilityStatuses.pastMedHistory && (
        <PastMedHistory
          onChange={(next) => handleChange("pastMedHistory", next)}
          intersection={intersection?.past_medical_history || {}}
          pastHistories={medicalHistories.map(
            (h) => h.past_medical_history || {},
          )}
        />
      )}
    </div>
  );
}

MedicalHistory.ModalSummary = ModalSummary;

MedicalHistory.propTypes = {
  patients: PropTypes.array,
  onChange: PropTypes.func,
  intersection: PropTypes.object,
};
