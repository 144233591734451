import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
  clinicId: yup.number().required(),
});

export function useUpdateInvoiceClinicMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.updateInvoiceClinic(), {
      invoice_id: req.invoiceId,
      clinic_id: req.clinicId,
    });
  }, options);
}
