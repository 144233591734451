import React, { useState, useEffect } from "react";
import _ from "lodash/fp";
import ReactTooltip from "react-tooltip";
import { useForm, Controller } from "react-hook-form";
import { Input, Select } from '../../../Common/ReactHookForm'
import StepRangeSlider from 'react-step-range-slider';
import { Scrollbars } from "react-custom-scrollbars";
import { http } from "../../../../services/HttpService";

const FormStep3 = (props) => {
  const { handleFormStepChange, formValue, resources, merchantNumber } = props;
  const { register, handleSubmit, watch, errors, control, setValue } = useForm({ defaultValues: formValue });
  const [cardPresent, setCardPresent] = useState((formValue && formValue.cardPresentPercentage) ? formValue.cardPresentPercentage : 0)
  const [cardPresentNot, setCardPresentNot] = useState((formValue && formValue.cardPresentPercentage_not) ? formValue.cardPresentPercentage_not : 0)

  const [fileName, setFilename] = useState('');
  const license_photo = watch('firearms_license_photo');
  const range = [
    { value: 0, step: 1 }, // acts as min value
    { value: 100 } // acts as max value
  ]

  useEffect(() => {
    setCardPresent(watch("cardPresentPercentage"))
    setCardPresentNot(100 - watch("cardPresentPercentage"))
    setValue('cardPresentPercentage_not', (100 - watch("cardPresentPercentage")))
  }, [watch("cardPresentPercentage")]);

  useEffect(() => {
    setCardPresentNot(watch("cardPresentPercentage_not"))
    setCardPresent(100 - watch("cardPresentPercentage_not"))
    setValue('cardPresentPercentage', (100 - watch("cardPresentPercentage_not")))
  }, [watch("cardPresentPercentage_not")]);

  useEffect(() => {
    if (license_photo) {
      http.post("media/upload?upload_type=clearent", ({ image_data: license_photo, upload_type: 'clearent' })).then(response => {
        if (response.data && response.data.status === 200) {
          setFilename(response.data.data.file_name);
        }
      })
    }
  }, [license_photo])

  const onSubmit = data => {
    if (fileName) {
      data.documents = []
      data.documents.push({ type: "federal_license", fileName: fileName });
    }
    handleFormStepChange(4, data);
  }; // your form submit function which will invoke after successful validation

  const handleChildChange = (fileName) => {
    setFilename(fileName);
  }

  const getMccCode = (codes) => {
    if (codes && codes.length > 0) {
      let options = []
      codes.map((obj, idx) => {
        if (obj.mccCode == 7298 || obj.mccCode == 8099) {
          options.push({ value: obj.mccCode, label: obj.mccDescription })
        } else {
          return false;
        }

      })
      return options;
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="setting-setion">
        <ReactTooltip id="global" aria-haspopup="true" role="example">
          <p className='info-text'>a) Estimated High Ticket is the highest dollar amount that a merchant expects to charge in a single transaction. <br />b) Estimated Annual Volume refers to the amount that a merchant processes in a year's time. <br />c) Estimated Average Ticket Amount refers to the general transaction size. To identify this, you can take the total sales divided by the number of transactions or invoices completed.<br /></p>
        </ReactTooltip>
        <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax={window.innerHeight - 240}
          className="full-width"
          renderTrackHorizontal={props => (
            <div
              {...props}
              style={{ display: "none" }}
              className="track-horizontal"
            />
          )}
        >
          <div className="setting-container p20">

            <a className="easy-link p-l-0" onClick={() => {
              handleFormStepChange(2)
            }}><i className="fa fa-angle-left m-r-5"></i> Go Back</a>
            {merchantNumber != '' && <a className="easy-link p-l-0 pull-right"> Merchant Id - {merchantNumber}</a>}

            <div className="merchant-title">Merchant Application</div>
            <p className="p-text m-b-20">Welcome to the merchant facing application! Thank you for taking the time to help speed up your company's boarding process. Please fill out the application below to the best of your ability and click send when you're finished. This will alert your local rep.</p>

            <div className="settings-subtitle m-b-20 merchantSteps">2 out of 4: General Business Information </div>


            <div className="merchant-subtitle m-b-20">Type of Sales:</div>


            <div className="row m-b-20">
              <div className="col-sm-4 col-xs-12 cardPresent">
                <h3>Card Present</h3>
                <p>{cardPresent}% of sale that are done in person with a card present at purchase.</p>
              </div>
              <div className="col-sm-8 col-xs-12">
                <Controller
                  name="cardPresentPercentage"
                  control={control}
                  defaultValue={cardPresent}
                  onChange={(value) => {
                  }}
                  as={
                    <StepRangeSlider
                      value={cardPresent}
                      range={range}
                      onChange={value => {
                      }}
                      className="card-progress-outer"
                      children={<span>{cardPresent}%</span>}
                      valueLabelDisplay="auto"
                    />
                  }
                />
              </div>
            </div>

            <div className="row m-b-20">
              <div className="col-sm-4 col-xs-12 cardPresent">
                <h3>Card Not Present</h3>
                <p>{cardPresentNot}% of sale that are done online, via phone or mail, etc. This includes saved cards on file including memberships, cancellation fees, etc.</p>
              </div>
              <div className="col-sm-8 col-xs-12 m-t-10">

                <Controller
                  name="cardPresentPercentage_not"
                  control={control}
                  defaultValue={cardPresentNot}
                  onChange={(value) => {
                  }}
                  as={
                    <StepRangeSlider
                      value={cardPresentNot}
                      range={range}
                      onChange={value => {
                      }}
                      className="card-progress-outer"
                      children={<span>{cardPresentNot}%</span>}
                      valueLabelDisplay="auto"
                    />
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-11 no-padding relative">
                <span className="average-ticket-info"
                  data-tip
                  data-for='global'

                ><i class="fa fa-info"></i></span>
                <div className="col-sm-4 col-xs-12 m-b-10">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Estimated Annual Volume<span className="setting-require">*</span></div>

                    <Input
                      className={`newInputField ${(errors && errors.annualVolume) ? 'field_error' : ''}`}
                      placeholder="Estimated Annual Volume"
                      name='annualVolume'
                      register={register}
                      isRequired={true}
                      errors={errors}
                      control={control}
                      type="number"
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-xs-12 m-b-10">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Estimated Average Ticket<span className="setting-require">*</span></div>

                    <Input
                      className={`newInputField ${(errors && errors.averageTicket) ? 'field_error' : ''}`}
                      placeholder="Estimated Average Ticket"
                      name='averageTicket'
                      register={register}
                      isRequired={true}
                      errors={errors}
                      control={control}
                      type="number"
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-xs-12 m-b-10">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Estimated High Ticket<span className="setting-require">*</span></div>

                    <Input
                      className={`newInputField ${(errors && errors.highTicket) ? 'field_error' : ''}`}
                      placeholder="Estimated High Ticket"
                      name='highTicket'
                      register={register}
                      isRequired={true}
                      errors={errors}
                      control={control}
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-sm-8 col-xs-12">
                <div className="newInputFileldOuter">
                  <div className="newInputLabel">Business Category<span className="setting-require">*</span></div>
                  <Select
                    className={`newSelectField ${(errors && errors.mccCode) ? 'field_error' : ''}`}
                    name='mccCode'
                    register={register}
                    isRequired={true}
                    errors={errors}
                    control={control}
                    options={getMccCode(resources.MccCodes)}
                  />
                </div>
              </div>
            </div>

            <div className="merchant-subtitle m-b-10 m-t-10">Business Profile</div>

            <div className="row">
              <div className="col-sm-12 col-xs-12">
                <div className="newInputFileldOuter">
                  <div className="newInputLabel">Return/Refund Policy<span className="setting-require">*</span>
                  </div>
                  <textarea
                    className={`newtextareaField ${(errors && errors.returnRefundPolicy) ? 'field_error' : ''}`}
                    placeholder="Return/Refund Policy"
                    name='returnRefundPolicy'
                    ref={register({
                      required: true,
                      maxLength: 250
                      //pattern: /^[A-Za-z -_0-9]+$/i
                    })}
                    isRequired={true}
                    errors={errors}
                    control={control}
                    rows="5"
                    cols="50"
                  />
                  <div class="field-note">Note: Max 250 characters.</div>
                </div>
              </div>
              <div className="col-sm-12 col-xs-12">
                <div className="newInputFileldOuter">
                  <div className="newInputLabel">Product/Services Sold<span className="setting-require">*</span>
                  </div>
                  <textarea
                    className={`newtextareaField ${(errors && errors.productsSold) ? 'field_error' : ''}`}
                    placeholder="Product/Services Sold"
                    name='productsSold'
                    ref={register({
                      required: true,
                      maxLength: 250
                      //pattern: /^[A-Za-z -_0-9]+$/i
                    })}
                    isRequired={true}
                    errors={errors}
                    control={control}
                    rows="5"
                    cols="50"
                  />
                  <div class="field-note">Note: Max 250 characters.</div>
                </div>
              </div>
            </div>

          </div>
        </Scrollbars>

        <div class="footer-static merchantFooter">
          <div class="footerProgressOuter">
            <p><span>Great!</span> Next,you'll Complete</p>
            <p>Your Location Info and Banking Information</p>
            <div class="footerProgressBg"><span class="footerProgressBar" style={{ width: "50%" }}></span></div>
          </div>
          <button class="new-blue-btn m-t-5" type="submit">Next</button>
        </div>


        {/*<div className="footer-static">
                  <button className="new-blue-btn pull-right" type="submit">Next</button>
                  <button className="new-line-btn pull-left" type="button" onClick={() => {
                    handleFormStepChange(2)
                  }}>Back</button>
                </div>*/}




      </div>
    </form>
  );
}
export default FormStep3;
