import React from "react";
import cx from "clsx";
import { Link } from "react-router-dom";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { AlertBox } from "../../../shared/AlertBox/AlertBox";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { Toggle } from "../../../shared/Toggle/Toggle";
import classes from "./sass/SettungsInbox.module.scss";
import Setup from "./components/Setup";
import { useInbox } from "./hooks/useInbox";
import { Box } from "../../../shared/Box/Box";
import { LayoutSettings } from "../../../boxes/LayoutSettings/LayoutSettings";

export function SettingsInbox() {
  const { isInboxEnabled, toggle, disable } = useInbox();

  const isSetupVisible = !isInboxEnabled && toggle.value;
  const isAlertVisible = isInboxEnabled && toggle.value;

  return (
    <LayoutSettings>
      <Box elevation>
        <div className={cx("membership-title", classes.title)}>Inbox</div>
        <Toggle
          rtl
          isEnabled={toggle.value}
          onChange={toggle.onChange}
          isDisabled={disable.isLoading}
        >
          <div className="items-center">
            {toggle.value ? "Enabled" : "Disabled"}
            {disable.isLoading && (
              <CircularProgress size="tiny" color="gray1" className="m-l-5" />
            )}
          </div>
        </Toggle>
        {disable.isNeedConfirm && (
          <ConfirmModal
            isOpen={disable.isNeedConfirm}
            onCancel={disable.cancelConfirm}
            onClose={disable.cancelConfirm}
            onConfirm={disable.initiate}
          >
            Are you sure you want to disable your SMS inbox? This cannot be
            undone!
          </ConfirmModal>
        )}
        {isAlertVisible && (
          <AlertBox className={classes.alert} variant="success">
            SMS Inbox Is Set Up. <Link to="/inbox/sms/all/-1">AR Messages</Link>
          </AlertBox>
        )}
        {isSetupVisible && <Setup />}
      </Box>
    </LayoutSettings>
  );
}
