import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLoggedIn } from "../../Utils/services";
import { SETTINGS_ROUTES } from "../../consts/routes";

export const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: SETTINGS_ROUTES.profile(),
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
