import React from "react";
import PropTypes from "prop-types";
import {
  getLanguageData,
  getUserPaymentSystem,
} from "../../../../utilities/localStorage";
import { LANGUAGE_DATA_KEYS } from "../../../../consts/localStorage";
import { USER_PAYMENT_SYSTEMS } from "../../../../consts/api";
import { EntityRow } from "../EntityRow";
import classes from "../../sass/Event.module.scss";
import { Input } from "../../../../shared/Input/Input";
import { ButtonSecondary } from "../../../../shared/ButtonSecondary/ButtonSecondary";
import { svg } from "../../../../assets/svg";
import { Select } from "../../../../shared/Select/Select";

const CommonCCDetails = ({
  toggleCCForm,
  isClearentZipError,
  clearentZip,
  onClearentZipChange,
  isClearentEmailError,
  clearentEmail,
  onClearentEmailChange,
  size,
  cardOnFileStripe,
  clearentCardList,
  onClearentCardChange,
}) => {
  const userPaymentSystem = getUserPaymentSystem();
  const appointmentLang = getLanguageData(LANGUAGE_DATA_KEYS.appointments);

  return (
    <div className={classes.ccDetails}>
      <EntityRow
        label={
          !cardOnFileStripe ? appointmentLang.appointment_ur_card_details : ""
        }
        contentClassName={classes.ccDetailsCard}
        size={size}
      >
        {!cardOnFileStripe ? (
          <Select options={clearentCardList} onChange={onClearentCardChange} />
        ) : (
          ""
        )}
        <ButtonSecondary
          onClick={toggleCCForm}
          leftAdornment={<img src={svg.plusPrimary} alt="+" />}
          size={size}
        >
          {appointmentLang.appointment_add_edit_card}
        </ButtonSecondary>
      </EntityRow>
      {userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent && (
        <>
          <EntityRow label="Zip" size={size}>
            <Input
              isError={isClearentZipError}
              placeholder="Enter Zip Code"
              name="clearent_zip"
              value={clearentZip}
              onChange={(e) => onClearentZipChange(e.target.value)}
              size={size}
            />
          </EntityRow>
          <EntityRow label="Email" size={size}>
            <Input
              isError={isClearentEmailError}
              placeholder="Enter Email Address"
              name="clearent_email_id"
              value={clearentEmail}
              onChange={(e) => onClearentEmailChange(e.target.value)}
              size={size}
            />
          </EntityRow>
        </>
      )}
    </div>
  );
};

CommonCCDetails.propTypes = {
  cardNumber: PropTypes.string,
  toggleCCForm: PropTypes.func,
  isClearentZipError: PropTypes.bool,
  clearentZip: PropTypes.string,
  onClearentZipChange: PropTypes.func,
  isClearentEmailError: PropTypes.bool,
  clearentEmail: PropTypes.string,
  onClearentEmailChange: PropTypes.func,
  size: PropTypes.oneOf(["small", "large"]),
  cardOnFileStripe: PropTypes.bool,
};

CommonCCDetails.defaultProps = {
  cardNumber: "",
  toggleCCForm: () => {},
  isClearentZipError: false,
  clearentZip: "",
  onClearentZipChange: () => {},
  isClearentEmailError: false,
  clearentEmail: "",
  onClearentEmailChange: () => {},
  size: "large",
};

export default CommonCCDetails;
