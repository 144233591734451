import React from "react";
import { SortableElement } from "react-sortable-hoc";
import cx from "clsx";
import classes from "./Choice.module.scss";
import { DragIcon } from "../../../../../../../shared/DragIcon/DragIcon";
import { Button } from "../../../../../../../shared/Button/Button";

export const Choice = SortableElement(({ onRemove, children }) => {
  return (
    <div className={cx(classes.root)}>
      <div className={classes.left}>
        <DragIcon className={classes.dragIcon} />
        {children}
      </div>
      {onRemove && (
        <Button size="small" variant="text" color="error" onClick={onRemove}>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      )}
    </div>
  );
});
