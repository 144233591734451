import { useInfiniteQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  per_page: yup.number().required(),
  sort_order: yup.string().nullable(),
  display_status: yup.string().nullable(),
  client_id: yup.string().required(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    upcomingAppointment: yup
      .array()
      .of(
        yup
          .object({
            id: yup.number().required(),
            user_id: yup.number().required(),
            appointment_datetime: yup.string().required(),
            status: yup.string().nullable(),
            display_status: yup.string().nullable(),
            appointment_services: yup
              .array()
              .of(
                yup
                  .object({
                    service: yup
                      .object({
                        id: yup.number().required(),
                        name: yup.string().nullable(),
                      })
                      .required(),
                  })
                  .required(),
              )
              .required(),
            provider: yup
              .object({
                id: yup.number().required(),
                full_name: yup.string().required(),
              })
              .nullable(),
            clinic: yup
              .object({
                id: yup.number().required(),
                clinic_name: yup.string().required(),
              })
              .required(),
            appointment_note: yup
              .object({
                appointment_id: yup.number().required(),
                appointment_notes: yup.string().nullable(),
              })
              .required(),
          })
          .required(),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const upcomingAppointment = res?.data?.data.data || [];
  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    upcomingAppointment,
  };
};

const useUpcomingAppointment = ({
  payload: { per_page, sort_order, status, client_id },
  options = {},
}) => {
  const params = { per_page, sort_order, display_status: status };

  return useInfiniteQuery({
    queryKey: [QUERY_KEYS.appointments.upComingAppointments, params],
    queryFn: async ({ pageParam = 1 }) => {
      const req = requestSchema.validateSync(
        { ...params, client_id },
        { strict: true },
      );
      const res = await http.get(
        HTTP_ENDPOINTS.appointments.getUpcomingAppointments(client_id),
        { params: { page: pageParam, ...params } },
      );

      return responseSchema.validateSync(
        composeResponse(res, { pageSize: req.per_page }),
        {
          strict: true,
        },
      );
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
};

export default useUpcomingAppointment;
