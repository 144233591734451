import { useMemo, useState } from "react";
import { useProductInventoryLogsQuery } from "../../../../../../api/queries/useProductInventoryLogsQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";

const INITIAL_PERIOD = {
  from: undefined,
  to: undefined,
};

export function useLogs(productId) {
  const { tCommon } = useAppTranslation.Common();
  const [period, setPeriod] = useState(INITIAL_PERIOD);

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useProductInventoryLogsQuery({
      payload: {
        productId: Number(productId),
        pageSize: 15,
        dateFrom: period.from,
        dateTo: period.to,
      },
      options: {
        staleTime: 0,
        onError: () => {
          uiNotification.error(tCommon("error.fetchInventoryLogs"));
        },
      },
    });

  const logs = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.logs).reduce((c, l) => [...c, ...l], []);
    }
    return [];
  }, [data?.pages]);

  const isEmpty = !isLoading && logs.length === 0;

  return {
    value: logs,
    isLoading,
    isEmpty,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    period: {
      value: period,
      update: setPeriod,
      reset: () => setPeriod(INITIAL_PERIOD),
    },
  };
}
