import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../../services/HttpService";

export const useStripePaymentIntent = (options = {}) => {
  const res = useMutation(
    (dto) => http.post("/stripe/payment-intent", dto),
    options,
  );

  const [previousData, setPreviousData] = useState(null);

  useEffect(() => {
    if (res.data?.data?.data) {
      setPreviousData(res.data?.data?.data);
    }
  }, [res.data]);

  return {
    data: res.data?.data?.data || previousData,
    mutateAsync: res.mutateAsync,
    isLoading: res.isLoading,
  };
};
