import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import { useAspireRedemption } from "./hooks/useAspireRedemption";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { SortAdornment } from "../../../../../shared/SortAdornment/SortAdornment";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";

const modalInfo = {
  title: "aspireRedemptionReport.info.title",
  rows: ["aspireRedemptionReport.info.note"],
};

const AspireRedemptionReport = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    order,
  } = useAspireRedemption(requestParams);

  const tableDataEmpty = !isLoading && !data?.pages[0]?.reportData?.length;

  const tableData = useMemo(
    () =>
      data?.pages
        ?.map((p) => p.reportData)
        .flat()
        .map((data) => ({
          invoiceDate: data.invoice_date,
          invoiceNo: data.invoice_number,
          type: data.type,
          certificateCode: data.certificate_code,
          patientName: data.client,
          employee: data.employee,
          amount: data.amount,
        })),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.pages[0].clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Aspire Redemption Report"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getAspireRedemptionReport()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              {
                data: renderSortWrap("Invoice Date", "invoice_date"),
                accessor: "invoiceDate",
              },
              {
                data: renderSortWrap("Invoice #", "invoice_number"),
                accessor: "invoiceNo",
              },
              { data: renderSortWrap("Type", "type"), accessor: "type" },
              {
                data: renderSortWrap("Certificate Code", "certificate_code"),
                accessor: "certificateCode",
              },
              {
                data: renderSortWrap("Patient Name", "client"),
                accessor: "patientName",
              },
              {
                data: renderSortWrap("Employee", "employee"),
                accessor: "employee",
              },
              { data: renderSortWrap("Amount", "amount"), accessor: "amount" },
            ]}
            data={tableData}
          />
          {hasNextPage && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={fetchNextPage}
              isLoading={isFetchingNextPage}
            />
          )}
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default AspireRedemptionReport;
