import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import AppointmentHeader from "./AppointmentHeader.js";

class AppointmentConfigNavbar extends Component {
  constructor(props) {
    super(props);
    const langData = JSON.parse(localStorage.getItem("languageData"));
    let userData = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      langData: langData,
      userData: userData,
    };
  }

  render() {
    const userPriviliges =
      this.state.userData && this.state.userData.permissions
        ? this.state.userData.permissions
        : [];

    return (
      <div>
        <div className="setting-setion m-b-10 auto-height full-width">
          <div className="membership-title">
            <span className="cursor-pointer">
              <Link className="appointmentIndex" to="/appointment/index">
                Calendar
              </Link>{" "}
            </span>
            <span className="breadCrumb-text">
              <i className="fa fa-chevron-right"></i> Smart Configuration
            </span>
            <AppointmentHeader />
          </div>
        </div>
        <div className="setting-setion m-b-10 auto-height full-width">
          <div className="membership-title">
            <div className="newTabsOuter">
              {
                <Link
                  to="/appointment/config"
                  className={
                    this.props.configActive ? "newTabs" : "newTabs disabled-tab"
                  }
                >
                  Provider
                </Link>
              }
              {userPriviliges.indexOf("manage-appointment-settings") > -1 && (
                <Link
                  to="/appointment/services-main"
                  className={
                    this.props.activeService
                      ? "newTabs"
                      : "newTabs disabled-tab"
                  }
                >
                  Services
                </Link>
              )}
              {userPriviliges.indexOf("manage-verticals") > -1 && (
                <Link
                  to="/appointment/verticals"
                  className={
                    this.props.activeVerticals
                      ? "newTabs"
                      : "newTabs disabled-tab"
                  }
                >
                  Verticals
                </Link>
              )}
              {userPriviliges.indexOf("manage-appointment-settings") > -1 && (
                <Link
                  to="/appointment/equipment-schedule"
                  className={
                    this.props.activeResources
                      ? "newTabs"
                      : "newTabs disabled-tab"
                  }
                >
                  Other Resources
                </Link>
              )}
              {userPriviliges.indexOf("manage-appointment-settings") > -1 && (
                <Link
                  to="/appointment/communication"
                  className={
                    this.props.activeCommunication
                      ? "newTabs"
                      : "newTabs disabled-tab"
                  }
                >
                  Communication
                </Link>
              )}
              {userPriviliges.indexOf("manage-appointment-settings") > -1 && (
                <Link
                  to="/appointment/booking-portal"
                  className={
                    this.props.activeBooking
                      ? "newTabs"
                      : "newTabs disabled-tab"
                  }
                >
                  Booking Portal
                </Link>
              )}
              {userPriviliges.indexOf("manage-appointment-settings") > -1 && (
                <Link
                  to="/appointment/other-settings"
                  className={
                    this.props.activeSettings
                      ? "newTabs"
                      : "newTabs disabled-tab"
                  }
                >
                  Other Settings
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppointmentConfigNavbar));
