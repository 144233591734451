import { useEffect } from "react";
import { history } from "../../../../../../history";
import { useCurrentAccountQuery } from "../../../../../../api/queries/useAccountQuery";

export function useGuard() {
  const { data: account, isFetched } = useCurrentAccountQuery();

  useEffect(() => {
    if (!account?.integrations?.isQualiphyEnabled && isFetched) {
      history.goBack();
    }
  }, [account?.integrations?.isQualiphyEnabled, isFetched]);

  return { isAvailable: account?.integrations?.isQualiphyEnabled };
}
