import React from "react";
import classes from "./Param.module.scss";
import { CheckIcon } from "../../../../../../../assets/Icons/CheckIcon";
import { CrossIcon } from "../../../../../../../assets/Icons/CrossIcon";

export function Param({ children, isCompleted }) {
  return (
    <div className={classes.root}>
      {children}
      {isCompleted ? (
        <span className={classes.valueCheck}>
          <CheckIcon width="17px" />
        </span>
      ) : (
        <span className={classes.valueCross}>
          <CrossIcon width="17px" />
        </span>
      )}
    </div>
  );
}
