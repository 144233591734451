import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import moment from "moment";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Checkbox } from "../../../../../../shared/Checkbox/Checkbox";
import { history } from "../../../../../../history";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import {
  DEFAULT_TIME_FORMAT,
  PREVIEW_DATE_FORMAT,
} from "../../../../../../consts/general";
import classes from "./Table.module.scss";
import { shortenString } from "../../../../../../utilities/general";
import { MESSAGES_TYPE } from "../../hooks/useMessages";

export function Table({ messages, selectedMessages }) {
  const { tCommon } = useAppTranslation.Common();
  const { tInbox } = useAppTranslation.Inbox();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = messages.value.length === 0 && !messages.isLoading;
  const isVisible = !messages.isLoading && !isEmpty;

  const data = messages.value.map((m) => ({
    key: m.id,

    action: (
      <Checkbox
        size="small"
        isChecked={selectedMessages.isSelected(m.id)}
        onChange={() => selectedMessages.onToggleOne(m.id)}
      />
    ),

    patientName: (
      <div className={classes.author}>
        <div
          className={classes.authorImage}
          style={{
            background: `url(${
              m.author.imageUrl || "/images/user.png"
            }) no-repeat center center / cover`,
          }}
        />
        {`${m.author.firstName} ${m.author.lastName}`}
      </div>
    ),

    message: shortenString(m.body, 100),

    date: m.date
      ? moment(m.date).format(user?.previewDateFormat || PREVIEW_DATE_FORMAT)
      : tCommon("symbol.longDash"),

    time: m.date
      ? moment(m.date).format(user?.timeFormat || DEFAULT_TIME_FORMAT)
      : tCommon("symbol.longDash"),

    isUnread: m.isUnread,
  }));

  const onRowClick = (chatId) => {
    const params = queryString.parse(window.location.search);
    const nextParams = {
      ...params,
      id: chatId,
    };
    history.push(`/inbox/sms/lead-ar?${queryString.stringify(nextParams)}`);
  };

  return (
    <div>
      {messages.isLoading && !messages.isSoftLoading && (
        <Skeleton count={15} height={60} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={messages.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={data}
            onRowClick={(row) => onRowClick(row.key)}
            getRowClassName={(row) =>
              row.isUnread && messages.type === MESSAGES_TYPE.all
                ? classes.unreadRow
                : ""
            }
            preventRowClickForAccessors={["action"]}
            cols={[
              {
                data: (
                  <Checkbox
                    inverted
                    size="small"
                    isChecked={selectedMessages.isSelectedAll()}
                    isIntermediate={selectedMessages.isSelectedPartial()}
                    onChange={selectedMessages.onToggleAll}
                  />
                ),
                accessor: "action",
              },
              {
                data: tInbox("leadAr.table.patient"),
                accessor: "patientName",
              },
              {
                data: tInbox("leadAr.table.message"),
                accessor: "message",
                className: classes.messageCol,
              },
              {
                data: tInbox("leadAr.table.date"),
                accessor: "date",
              },
              {
                data: tInbox("leadAr.table.time"),
                accessor: "time",
              },
            ]}
          />
          {messages.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={messages.fetchMore}
              isLoading={messages.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </div>
  );
}

Table.propTypes = {
  messages: PropTypes.object.isRequired,
  selectedMessages: PropTypes.object.isRequired,
};
