/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import InsightsSideBar from "./InsightsSideBar.js";
import {
  getIsAcceleratorPlan,
  getIsPosEnabled,
  toggleServicesPerformed,
} from "../../Utils";
import { acceleratorInsightsSales } from "../../Utils/privilege.js";
import UpgradeAccountModal from "../Common/UpgradeAccountModal.js";
import { MEMBERSHIP_REPORT_SLUGS } from "./Sales/Sales.consts.js";
import { tBi } from "../../i18n/useAppTranslation.js";
import { getShopifyData } from "../../Actions/Integrations/integrationActions";
import { isShopifyAvailable } from "../../helpers/featureFlags.js";
import { withGlobalSettings } from "../../hocs/withGlobalSettings.js";
import { uiNotification } from "../../services/UINotificationService.js";

const getAllReportTypes = () => {
  let reportOptions = [];

  reportOptions.push(
    {
      label: "Sales Summary",
      slug: "daily_sales_report",
    },
    {
      label: "BD + Aspire + Evolus",
      slug: "bd_aspire_report",
    },
    {
      label: "Discounts",
      slug: "discounts",
    },
    {
      label: "Commissions",
      slug: "commission_report",
    },
    {
      label: "Gross Sales",
      slug: "gross_sales",
    },
    {
      label: "Net Sales",
      slug: "net_sales",
    },
    {
      label: "Product Sales",
      slug: "item_sales",
    },
    {
      label: "Category Sales",
      slug: "category_sales",
    },
    {
      label: "Package Sales",
      slug: "package_sales",
    },
    {
      label: "IV Package Sales",
      slug: "iv_package_sales",
    },
    {
      label: "Refunds Report",
      slug: "refunds_report",
    },
    {
      label: "Cost to Company",
      slug: "cost_to_company",
    },
    {
      label: "eGift Cards Sold vs. Redeemed",
      slug: "egift_cards",
    },
    {
      label: "eGift Cards Revenue",
      slug: "egift_cards_revenue",
    },
    {
      label: "Payment Methods",
      slug: "payment_methods",
    },
    {
      label: "Tips per Provider",
      slug: "tip-report",
    },
    {
      label: "Taxes",
      slug: "taxes",
    },
    {
      label: "Treatment Plans",
      slug: "treatment_plans",
    },
    {
      label: "Short Term Liability",
      slug: "short_term_liability",
    },
    {
      label: "Membership Churn Report",
      slug: MEMBERSHIP_REPORT_SLUGS.churn,
    },
    {
      label: "Membership Decline Report",
      slug: MEMBERSHIP_REPORT_SLUGS.decline,
    },
    {
      label: "Staff Treatment",
      slug: "staff_treatments",
    },
    {
      label: "Cancellation Fees",
      slug: "cancelation_fees",
    },
    {
      label: "Retail Report",
      slug: "retail_report",
    },
  );

  return reportOptions;
};

const getShopifyReportTypes = () => {
  return [
    {
      label: "Shopify Product Sales",
      slug: "shopify-products",
    },
    {
      label: "Shopify Financials",
      slug: "shopify-sales",
    },
    {
      label: "Shopify Orders",
      slug: "shopify-orders",
    },
  ];
};

const getSalesReportTypes = () => {
  let salesReportOptions = [];
  salesReportOptions.push(
    {
      label: "Sales Summary",
      slug: "daily_sales_report",
    },
    {
      label: "Gross Sales",
      slug: "gross_sales",
    },
    {
      label: "Net Sales",
      slug: "net_sales",
    },
    {
      label: "Product Sales",
      slug: "item_sales",
    },
    {
      label: "Category Sales",
      slug: "category_sales",
    },
    {
      label: "Package Sales",
      slug: "package_sales",
    },
    {
      label: "IV Package Sales",
      slug: "iv_package_sales",
    },
    {
      label: "Discounts",
      slug: "discounts",
    },
    {
      label: "Commissions",
      slug: "commission_report",
    },
    {
      label: "Tips per Provider",
      slug: "tip-report",
    },
    {
      label: "Cancellation Fees",
      slug: "cancelation_fees",
    },
    {
      label: "Retail Report",
      slug: "retail_report",
    },
  );

  if (toggleServicesPerformed()) {
    salesReportOptions.push({
      label: "Services Performed",
      slug: "services_performed",
    });
  }
  return salesReportOptions;
};

const getPaymentReportTypes = () => {
  let paymentReportOptions = [];

  paymentReportOptions.push(
    {
      label: "BD + Aspire + Evolus",
      slug: "bd_aspire_report",
    },
    {
      label: "Refunds Report",
      slug: "refunds_report",
    },
    {
      label: "Cost to Company",
      slug: "cost_to_company",
    },
    {
      label: "eGift Cards Sold vs. Redeemed",
      slug: "egift_cards",
    },
    {
      label: "eGift Cards Revenue",
      slug: "egift_cards_revenue",
    },
    {
      label: "Payment Methods",
      slug: "payment_methods",
    },
    {
      label: "Taxes",
      slug: "taxes",
    },
    {
      label: "Treatment Plans",
      slug: "treatment_plans",
    },
    {
      label: "Short Term Liability",
      slug: "short_term_liability",
    },
    {
      label: "Staff Treatment",
      slug: "staff_treatments",
    },
    {
      label: "ASPIRE Redemption Report",
      slug: "redeemed_aspire_certificates",
    },
    {
      label: "RepeatMD Redemption Report",
      slug: "repeatmd_redemption",
    },
  );
  return paymentReportOptions;
};

const getMembershipReportTypes = () => {
  let membershipReportOptions = [];

  membershipReportOptions.push(
    {
      label: tBi("membershipOverviewReport.label"),
      slug: MEMBERSHIP_REPORT_SLUGS.membership,
    },
    {
      label: "Membership Churn Report",
      slug: MEMBERSHIP_REPORT_SLUGS.churn,
    },
    {
      label: "Membership Decline Report",
      slug: MEMBERSHIP_REPORT_SLUGS.decline,
    },
  );
  return membershipReportOptions;
};

class SalesInsights extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      allReporstList: getAllReportTypes(),
      languageData: languageData.business_insights,
      acceleratorPlan: acceleratorInsightsSales(),
      isShowUpgradeAlertModal: false,
      salesReportList: getSalesReportTypes(),
      paymentReportList: getPaymentReportTypes(),
      membershipReportList: getMembershipReportTypes(),
      shopifyReportList: getShopifyReportTypes(),
      section1: "#collapseOne1",
      countSectionOne: 1,
      activeClassOne: "card",
      section2: "#collapseOne2",
      countSectionTwo: 1,
      activeClassTwo: "card",
      section3: "#collapseOne3",
      section4: "#collapseOne4",
      countSectionThree: 1,
      countSectionFour: 1,
      activeClassThree: "card",
      activeClassFour: "card",
      activeSection2: "collapse",
      activeSection1: "collapse show",
      activeSection3: "collapse",
      activeSection4: "collapse",
      userData: userData,
      hiddenReports: [
        "gross_sales",
        "net_sales",
        "cancelation_fees",
        "commission_report",
        "cost_to_company",
        "taxes",
        "short_term_liability",
        MEMBERSHIP_REPORT_SLUGS.revenue,
        MEMBERSHIP_REPORT_SLUGS.mrr,
      ],
    };
  }

  componentDidMount() {
    let itemSection2 = localStorage.getItem("section2");
    let itemSection3 = localStorage.getItem("section3");
    let itemSection4 = localStorage.getItem("section4");
    if (itemSection2 == "true") {
      this.setState({
        activeSection2: "collapse show",
        countSectionTwo: 2,
        activeClassTwo: "card active",
        activeSection1: "collapse",
      });
    } else if (itemSection3 == "true") {
      this.setState({
        activeSection3: "collapse show",
        countSectionThree: 2,
        activeClassThree: "card active",
        activeSection1: "collapse",
      });
    } else if (itemSection4 == "true") {
      this.setState({
        activeSection4: "collapse show",
        countSectionFour: 2,
        activeClassFour: "card active",
        activeSection1: "collapse",
      });
    } else {
      this.setState({ activeClassOne: "card active", countSectionOne: 2 });
    }

    this.props.getShopifyData();
  }

  getReportDetails = (obj) => {
    let restrictedPrivilege = this.state.acceleratorPlan.find(
      (x) => x.slug == obj.slug,
    );
    if (restrictedPrivilege) {
      if (!getIsAcceleratorPlan()) {
        this.setState({ isShowUpgradeAlertModal: true });
        return false;
      }
    }
    if (getIsPosEnabled() === false) {
      return <div>{this.props.history.push(`/business-insight/clients`)}</div>;
    }
    return (
      <div>
        {this.props.history.push(`/business-insight/sales/${obj.slug}`)}
      </div>
    );
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState(state);
  };

  handleToggle = (e) => {
    if (e.target.name == "section1") {
      this.setState({
        section1: "#collapseOne1",
        countSectionOne: this.state.countSectionOne + 1,
      });
      if (this.state.countSectionOne % 2 !== 0) {
        this.setState({
          activeClassOne: "card active",
          activeClassTwo: "card",
          activeClassThree: "card",
          activeClassFour: "card",
          countSectionTwo: 1,
          countSectionThree: 1,
          countSectionFour: 1,
        });
        localStorage.setItem("section2", false);
        localStorage.setItem("section3", false);
        localStorage.setItem("section4", false);
      } else {
        this.setState({ activeClassOne: "card" });
      }
    }

    if (e.target.name == "section2") {
      this.setState({
        section2: "#collapseOne2",
        countSectionTwo: this.state.countSectionTwo + 1,
      });
      if (this.state.countSectionTwo % 2 !== 0) {
        this.setState({
          activeClassTwo: "card active",
          activeClassOne: "card",
          activeClassThree: "card",
          activeClassFour: "card",
          countSectionOne: 1,
          countSectionThree: 1,
          countSectionFour: 1,
        });
        localStorage.setItem("section2", true);
        localStorage.setItem("section3", false);
        localStorage.setItem("section4", false);
      } else {
        this.setState({ activeClassTwo: "card" });
        localStorage.setItem("section2", false);
      }
    }

    if (e.target.name == "section3") {
      this.setState({
        section3: "#collapseOne3",
        countSectionThree: this.state.countSectionThree + 1,
      });
      if (this.state.countSectionThree % 2 !== 0) {
        this.setState({
          activeClassThree: "card active",
          activeClassOne: "card",
          activeClassTwo: "card",
          activeClassFour: "card",
          countSectionOne: 1,
          countSectionTwo: 1,
          countSectionFour: 1,
        });
        localStorage.setItem("section3", true);
        localStorage.setItem("section2", false);
        localStorage.setItem("section4", false);
      }
    }

    if (e.target.name == "section4") {
      this.setState({
        section4: "#collapseOne4",
        countSectionFour: this.state.countSectionFour + 1,
      });
      if (this.state.countSectionFour % 2 !== 0) {
        this.setState({
          activeClassFour: "card active",
          activeClassOne: "card",
          activeClassTwo: "card",
          activeClassThree: "card",
          countSectionOne: 1,
          countSectionTwo: 1,
          countSectionThree: 1,
        });
        localStorage.setItem("section4", true);
        localStorage.setItem("section2", false);
        localStorage.setItem("section3", false);
      } else {
        this.setState({ activeClassFour: "card" });
        localStorage.setItem("section4", false);
      }
    }
  };

  get isRepeatMdEnabled() {
    return this.props.globalSettings?.["repeat-md"]?.isAvailable;
  }

  render() {
    const isShopifyEnabled = this.props.shopifyData?.is_enabled === 1;

    return (
      <div id="content">
        <UpgradeAccountModal
          notificaionType={"report"}
          isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
          handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}
        />

        <div className="container-fluid content setting-wrapper p-b-20">
          <InsightsSideBar />

          <div className="memberWalletOuter business-section m-b-0">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                {this.state.globalLang.header_sales}{" "}
                <span className="breadCrumb no-display">
                  <i className="fa fa-chevron-right" /> Create Report
                </span>
              </div>
            </div>
            <div
              className="accordion md-accordion"
              id="accordionEx"
              role="tablist"
              aria-multiselectable="true"
            >
              <div id="accordian">
                <ul>
                  <li className={this.state.activeClassOne}>
                    <h3 className="card-header" role="tab" id="headingOne1">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordionEx"
                        name="section1"
                        onClick={this.handleToggle}
                        href={this.state.section1}
                        aria-expanded="true"
                        aria-controls="collapseOne1"
                      >
                        Sales Reports
                      </a>
                    </h3>

                    <div
                      id="collapseOne1"
                      className={this.state.activeSection1}
                      role="tabpanel"
                      aria-labelledby="headingOne1"
                      data-parent="#accordionEx"
                    >
                      <div className="card-body">
                        <div className="overflow-hidden">
                          <table className="table-updated setting-table table-min-width no-td-border">
                            <tbody className="ajax_body">
                              {this.state.salesReportList.map((obj, idx) => {
                                let slug = obj.slug;
                                let isLocked = false;
                                let restrictedPrivilege =
                                  this.state.acceleratorPlan.find(
                                    (x) => x.slug == obj.slug,
                                  );
                                if (restrictedPrivilege) {
                                  if (!getIsAcceleratorPlan()) {
                                    isLocked = true;
                                  }
                                }

                                return (
                                  <tr key={idx} className="table-updated-tr">
                                    <td
                                      onClick={() => this.getReportDetails(obj)}
                                      className="col-xs-3 table-updated-td Questionnaire-name"
                                    >
                                      {this.state.languageData["bi_" + slug] ||
                                        obj.label}
                                      {isLocked === true && (
                                        <i className="fa fa-lock m-l-5" />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className={this.state.activeClassTwo}>
                    <h3 className="card-header" role="tab" id="headingOne2">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordionEx"
                        onClick={this.handleToggle}
                        name="section2"
                        href={this.state.section2}
                        aria-expanded="true"
                        aria-controls="collapseOne2"
                      >
                        Payments & Liabilities Reports
                      </a>
                    </h3>
                    <div
                      id="collapseOne2"
                      className={this.state.activeSection2}
                      role="tabpanel"
                      aria-labelledby="headingOne2"
                      data-parent="#accordionEx"
                    >
                      <div className="card-body">
                        <div className="overflow-hidden">
                          <table className="table-updated setting-table table-min-width no-td-border">
                            <tbody className="ajax_body">
                              {this.state.paymentReportList.map((obj, idx) => {
                                let slug = obj.slug;
                                let isLocked = false;
                                let restrictedPrivilege =
                                  this.state.acceleratorPlan.find(
                                    (x) => x.slug == obj.slug,
                                  );
                                if (restrictedPrivilege) {
                                  if (!getIsAcceleratorPlan()) {
                                    isLocked = true;
                                  }
                                }

                                if (
                                  obj.slug === "repeatmd_redemption" &&
                                  !this.isRepeatMdEnabled
                                ) {
                                  return null;
                                }

                                return (
                                  <tr key={idx} className="table-updated-tr">
                                    <td
                                      onClick={() => this.getReportDetails(obj)}
                                      className="col-xs-3 table-updated-td Questionnaire-name"
                                    >
                                      {this.state.languageData["bi_" + slug] ||
                                        obj.label}
                                      {isLocked === true && (
                                        <i className="fa fa-lock m-l-5" />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className={this.state.activeClassThree}>
                    <h3 className="card-header" role="tab" id="headingOne3">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordionEx"
                        name="section3"
                        onClick={this.handleToggle}
                        href={this.state.section3}
                        aria-expanded="true"
                        aria-controls="collapseOne3"
                      >
                        Membership Reports
                      </a>
                    </h3>
                    <div
                      id="collapseOne3"
                      className={this.state.activeSection3}
                      role="tabpanel"
                      aria-labelledby="headingOne3"
                      data-parent="#accordionEx"
                    >
                      <div className="card-body">
                        <div className="overflow-hidden">
                          <table className="table-updated setting-table table-min-width no-td-border">
                            <tbody className="ajax_body">
                              {this.state.membershipReportList.map(
                                (obj, idx) => {
                                  let slug = obj.slug;
                                  let isLocked = false;
                                  let restrictedPrivilege =
                                    this.state.acceleratorPlan.find(
                                      (x) => x.slug == obj.slug,
                                    );
                                  if (restrictedPrivilege) {
                                    if (!getIsAcceleratorPlan()) {
                                      isLocked = true;
                                    }
                                  }
                                  return (
                                    <tr key={idx} className="table-updated-tr">
                                      <td
                                        onClick={() =>
                                          this.getReportDetails(obj)
                                        }
                                        className="col-xs-3 table-updated-td Questionnaire-name"
                                      >
                                        {this.state.languageData[
                                          "bi_" + slug
                                        ] || obj.label}
                                        {isLocked === true && (
                                          <i className="fa fa-lock m-l-5" />
                                        )}
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </li>

                  {isShopifyEnabled && isShopifyAvailable() && (
                    <li className={this.state.activeClassFour}>
                      <h3 className="card-header" role="tab" id="headingOne4">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordionEx"
                          name="section4"
                          onClick={this.handleToggle}
                          href={this.state.section4}
                          aria-expanded="true"
                          aria-controls="collapseOne4"
                        >
                          Shopify Reports
                        </a>
                      </h3>
                      <div
                        id="collapseOne4"
                        className={this.state.activeSection4}
                        role="tabpanel"
                        aria-labelledby="headingOne4"
                        data-parent="#accordionEx"
                      >
                        <div className="card-body">
                          <div className="overflow-hidden">
                            <table className="table-updated setting-table table-min-width no-td-border">
                              <tbody className="ajax_body">
                                {this.state.shopifyReportList.map(
                                  (obj, idx) => (
                                    <tr key={idx} className="table-updated-tr">
                                      <td
                                        onClick={() =>
                                          this.getReportDetails(obj)
                                        }
                                        className="col-xs-3 table-updated-td Questionnaire-name"
                                      >
                                        {obj.label}
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  uiNotification.clear();
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.IntegrationReducer.action === "GET_SHOPIFY_DATA") {
    if (
      state.IntegrationReducer.data?.status === 200 ||
      state.IntegrationReducer.data?.status === 201
    ) {
      returnState.shopifyData = state.IntegrationReducer.data.data;
    } else {
      uiNotification.error(
        languageData.global[state.IntegrationReducer.data.message],
      );
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getShopifyData: getShopifyData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withGlobalSettings(SalesInsights)));
