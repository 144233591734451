import React, { useEffect } from "react";
import _ from "lodash/fp";
import { useForm, Controller } from "react-hook-form";
import { Input, Select, CheckBox, RadioButton } from '../../../Common/ReactHookForm'


const FormStep1 = (props) => {
  const { handleFormStepChange, formValue, resources, merchantNumber } = props;
  const { register, handleSubmit, watch, errors, control, getValues } = useForm({ defaultValues: formValue });
  const onSubmit = data => {
    handleFormStepChange(2, data)
    //alert(JSON.stringify(data));
  }; // your form submit function which will invoke after successful validation


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="setting-setion">
        <div className="setting-container p20">
        {merchantNumber != '' && <a className="easy-link p-l-0 pull-right"> Merchant Id - {merchantNumber}</a>}
          <div className="setting-title">Lets get started.</div>
          <div className="settings-subtitle m-b-20">Please fill out some initial details to speed up the process. The application will take the average user about 10-15 minutes.</div>
          <div className="row">
            <div className="col-sm-4 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">First Name<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.firstName) ? 'field_error' : ''}`}
                  placeholder="First Name"
                  name='firstName'
                  register={register}
                  isRequired={true}
                  maxLength={20}
                  errors={errors}
                  control={control}
                />
                { /*
                _.get("firstName.type", errors) === "required"  || 
                _.get("firstName.type", errors) === "maxLength" || 
                _.get("firstName.type", errors) === "pattern" 
                */
                }
              </div>
            </div>

            <div className="col-sm-4 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Last Name<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.lastName) ? 'field_error' : ''}`}
                  placeholder="Last Name"
                  name='lastName'
                  register={register}
                  isRequired={true}
                  maxLength={20}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>

          </div>
          <p className="p-text m-t-20">NOTE: Before submitting your application, you will need the owners Social Social Number a voided check per bank account and your Banking routing and account number.</p>
        </div>

        <div className="footer-static">
          <button className="new-blue-btn pull-right" type="submit">Begin</button>
        </div>
      </div>
    </form>
  );
}
export default FormStep1;
