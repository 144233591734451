/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import picClose from "../../_legacy/images/popupClose.png";

export default class SaveList extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      showSaveListModal: props.showSaveListModal
        ? props.showSaveListModal
        : false,
      listNameClass: props.listNameClass ? props.listNameClass : "simpleInput",
      listName: props.listName ? props.listName : "",
      reportMode: props.reportMode ? props.reportMode : "create",
      editReportID: props.editReportID ? props.editReportID : 0,
      propReportType: props.propReportType ? props.propReportType : "create",
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (
      nextProps.showSaveListModal !== undefined &&
      nextProps.showSaveListModal === true
    ) {
      returnState.showSaveListModal = nextProps.showSaveListModal;
    } else {
      returnState.showSaveListModal = false;
    }

    if (
      nextProps.listNameClass !== undefined &&
      nextProps.listNameClass !== ""
    ) {
      returnState.listNameClass = nextProps.listNameClass;
    }

    if (nextProps.listName !== undefined) {
      returnState.listName = nextProps.listName;
    }

    return returnState;
  }

  hideModal = () => {
    let returnState = {
      showSaveListModal: false,
      listName: "",
      listNameClass: "simpleInput",
    };

    if (this.state.reportMode && this.state.reportMode === "edit") {
      delete returnState.listName;
      delete returnState.listNameClass;
    }

    this.setState(returnState);
    this.props.hideModal(returnState);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let name = event.target.name;

    this.props.handleChildState({ [name]: value });
  };

  render() {
    return (
      <div
        className={
          this.state.showSaveListModal
            ? "blackOverlay"
            : "blackOverlay no-display"
        }
      >
        <div className="vert-middle">
          <div className="white-popup small-popup">
            <div className="white-popup-wrapper">
              <div className="membershipTypeTitle">
                Save List
                <a onClick={this.hideModal} className="popupClose">
                  <img alt="" src={picClose} />
                </a>
              </div>

              <div className="col-sm-12 col-xs-12">
                <div className="simpleField">
                  <div className="simpleLabel">List Name</div>
                  <input
                    type="text"
                    className={this.state.listNameClass}
                    name="listName"
                    value={this.state.listName}
                    onChange={this.handleInputChange}
                    placeholder={"Enter list name"}
                  />
                </div>
              </div>
            </div>
            <div className="footer-static">
              <a
                className="new-blue-btn pull-right"
                onClick={() => this.props.setList()}
              >
                Save
              </a>
              <a className="new-white-btn pull-right" onClick={this.hideModal}>
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
