/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./sass/TimeCardInsightReportDetail.module.scss";
import { tCommon } from "../../../i18n/useAppTranslation.js";
import { usePermission } from "../../../hooks/usePermission.js";
import { PERMISSIONS } from "../../../consts/api.js";
import { DeleteTimeLog } from "./DeleteTimeLog/DeleteTimeLog.js";
import { TriangleAlertIcon } from "../../../assets/Icons/TriangleAlertIcon.js";
import {
  numberFormat,
  showFormattedDate,
  getTimeFormat,
} from "../../../Utils/services.js";
import { updateTimeTracking } from "../../../Actions/BusinessInsights/businessInsightActions";
import { uiNotification } from "../../../services/UINotificationService.js";

const TIME_LOG_MODES = {
  manual: "manual",
};

class TimeCardInsightReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeFormat: getTimeFormat(),
      isEditingClockIn: false,
      detailData: this.props.detailData,
      editClockArr: this.props.detailData.map(({ id }) => {
        return {
          id: id,
          editClockIn: false,
          editClockOut: false,
        };
      }),
      userData: JSON.parse(localStorage.getItem("userData")),
      updatedTimeTracking: false,
      updatedTimeTrackingTimestamp: null,
    };
    window.onscroll = () => {
      return false;
    };
    this.calendarRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (
      props.detailData &&
      state.detailData &&
      JSON.stringify(props.detailData) !== JSON.stringify(state.detailData)
    ) {
      returnState.detailData = props.detailData;
      returnState.editClockArr = props.detailData.map(({ id }) => {
        return {
          id: id,
          editClockIn: false,
          editClockOut: false,
        };
      });
    }
    if (
      props.updatedTimeTracking &&
      props.updatedTimeTrackingTimestamp &&
      props.updatedTimeTrackingTimestamp !==
        state.updatedTimeTrackingTimestamp &&
      props.detailProviderId &&
      props.detailProviderName
    ) {
      returnState.updatedTimeTrackingTimestamp =
        props.updatedTimeTrackingTimestamp;
      props.fetchTimeCardReportDetails({
        detailProviderId: props.detailProviderId,
        detailProviderName: props.detailProviderName,
      });
    }
    return returnState;
  }

  timeCardPracticeBack = () => {
    this.props.timeCardPracticeBack();
  };

  handleDetailExport = () => {
    this.props.fetchTimeCardReportDetails(
      {
        export_name: "time_card_practice_details",
        export_type: "xls",
        detailProviderId: this.props.detailProviderId,
        detailProviderName: this.props.detailProviderName,
      },
      1,
    );
  };

  refetchLogs = () => {
    this.props.fetchTimeCardReportDetails({
      detailProviderId: this.props.detailProviderId,
      detailProviderName: this.props.detailProviderName,
    });
  };

  toggleEditDate = (obj, type, isEdit) => {
    const newEditClockArr = this.state.editClockArr.map((el) => {
      el.editClockIn = false;
      el.editClockOut = false;
      if (el.id === obj.id) {
        el[type] = isEdit;
      }
      return el;
    });
    this.setState(
      {
        editClockArr: newEditClockArr,
      },
      () => {
        if (this.calendarRef && this.calendarRef.current) {
          this.calendarRef.current.setOpen(true);
        }
      },
    );
  };

  handleEditDate = (data, type, newDate) => {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let newClockIn;
    let newClockOut;
    const newEditClockArr = this.state.editClockArr.map((el) => {
      if (el.id === data.id) {
        el[type] = !el[type];
      }
      return el;
    });
    const newDetailData = this.state.detailData.map((el) => {
      if (el.id === data.id) {
        if (type === "editClockIn") {
          newClockIn =
            moment(newDate).isAfter(moment(data.clock_out)) ||
            moment(newDate).isSame(moment(data.clock_out))
              ? moment(data.clock_out)
                  .add(-1, "minute")
                  .format("YYYY-MM-DD HH:mm:ss")
              : moment(newDate).format("YYYY-MM-DD HH:mm:ss");
          if (moment(newClockIn).diff(moment(el.clock_out), "hours") <= -24) {
            newClockOut = moment(newClockIn)
              .add(1, "day")
              .format("YYYY-MM-DD HH:mm:ss");
          } else {
            newClockOut = el.clock_out;
          }
        } else {
          newClockOut =
            moment(newDate).isBefore(moment(data.clock_in)) ||
            moment(newDate).isSame(moment(data.clock_in))
              ? moment(data.clock_in)
                  .add(1, "minute")
                  .format("YYYY-MM-DD HH:mm:ss")
              : moment(newDate).format("YYYY-MM-DD HH:mm:ss");
          newClockIn = el.clock_in;
        }
      }
      return el;
    });
    this.props
      .updateTimeTracking(data.id, {
        clock_in: newClockIn,
        clock_out: newClockOut,
      })
      .then((res) => {
        uiNotification.success(
          languageData.global[res.message] || "Edited Successfully",
        );
        this.setState({
          detailData: newDetailData,
          editClockArr: newEditClockArr,
        });
      })
      .catch((res) => {
        uiNotification.error(
          languageData.global[res.message] ||
            tCommon(`apiError.${res.message}`, "Unable To Edit"),
        );
      })
      .finally(() => {
        this.toggleEditDate(data, type, false);
      });
  };

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  render() {
    const userPrivileges =
      this.state.userData && this.state.userData.permissions
        ? this.state.userData.permissions
        : [];
    const isLogPermitted = Boolean(this.props.isLogPermitted);

    return (
      <div className="setting-setion m-b-10">
        <div className="table-responsive clients-table">
          <table className="table-updated setting-table table-min-width no-td-border  no-hover">
            <thead className="table-updated-thead">
              <tr>
                <th className="table-updated-th">Day</th>
                <th className="table-updated-th">Hours</th>
                <th className="table-updated-th">Clock In</th>
                <th className="table-updated-th">Clock Out</th>
                {isLogPermitted && (
                  <th className="table-updated-th max-w-30">Action</th>
                )}
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.state.detailData &&
                this.state.detailData.length > 0 &&
                this.state.detailData.map((obj) => {
                  const editClockIn =
                    moment(new Date()).diff(moment(obj.clock_in), "days") <= 14;
                  const editClockOut =
                    moment(new Date()).diff(moment(obj.clock_out), "days") <=
                    14;
                  const clockInMinDate = new Date(
                    new Date().setFullYear(
                      moment().add(-14, "day").year(),
                      moment().add(-14, "day").month(),
                      moment().add(-14, "day").date(),
                    ),
                  );
                  const clockInMaxDate = new Date(
                    new Date().setFullYear(
                      moment(obj.clock_out).year(),
                      moment(obj.clock_out).month(),
                      moment(obj.clock_out).date(),
                    ),
                  );
                  const clockOutMinDate = new Date(
                    new Date().setFullYear(
                      moment(obj.clock_in).year(),
                      moment(obj.clock_in).month(),
                      moment(obj.clock_in).date(),
                    ),
                  );
                  const clockInMaxTime = moment(obj.clock_in).isSame(
                    moment(obj.clock_out),
                    "days",
                  )
                    ? new Date(
                        new Date().setHours(
                          moment(obj.clock_out).hours(),
                          moment(obj.clock_out).minutes() - 1,
                          0,
                          0,
                        ),
                      )
                    : new Date(new Date().setHours(23, 59, 0, 0));
                  const clockOutMinTime = moment(obj.clock_out).isSame(
                    moment(obj.clock_in),
                    "days",
                  )
                    ? new Date(
                        new Date().setHours(
                          moment(obj.clock_in).hours(),
                          moment(obj.clock_in).minutes() + 1,
                          0,
                          0,
                        ),
                      )
                    : new Date(new Date().setHours(0, 0, 0, 0));
                  const clockOutMaxTime = moment(obj.clock_in).isSame(
                    moment(obj.clock_out),
                    "days",
                  )
                    ? new Date(new Date().setHours(23, 59, 0, 0))
                    : new Date(
                        new Date().setHours(
                          moment(obj.clock_in).hours(),
                          moment(obj.clock_in).minutes(),
                          0,
                          0,
                        ),
                      );
                  const clockOutMaxDate = new Date(
                    new Date().setFullYear(
                      moment(obj.clock_in).add(1, "day").year(),
                      moment(obj.clock_in).add(1, "day").month(),
                      moment(obj.clock_in).add(1, "day").date(),
                    ),
                  );
                  return (
                    <tr key={obj.id} className="table-updated-tr">
                      <td className="table-updated-td break-all">
                        <div className={classes.dayTd}>
                          <div className={classes.dayName}>
                            {obj.day}
                            {!!obj.is_overtime && (
                              <div className={classes.overtime}>
                                Overtime
                                <TriangleAlertIcon width="16px" />
                              </div>
                            )}
                          </div>
                          {obj.mode === TIME_LOG_MODES.manual && (
                            <div className={classes.manualChip}>Manual</div>
                          )}
                        </div>
                      </td>
                      <td className="table-updated-td">
                        {obj.total_hours ? obj.total_hours : "0"}
                      </td>
                      <td className="table-updated-td">
                        {this.state.editClockArr.find(
                          (e) => e.id === obj.id && e.editClockIn,
                        ) ? (
                          <div className="time-edit-wrapper">
                            <DatePicker
                              selected={new Date(moment(obj.clock_in).format())}
                              onChange={(date) =>
                                this.handleEditDate(obj, "editClockIn", date)
                              }
                              onChangeRaw={this.handleDateChangeRaw}
                              showTimeSelect
                              timeIntervals={1}
                              timeFormat="hh:mm aa"
                              dateFormat="MM/dd/yyyy hh:mm aa"
                              minDate={clockInMinDate}
                              maxDate={clockInMaxDate}
                              minTime={
                                new Date(new Date().setHours(0, 0, 0, 0))
                              }
                              maxTime={clockInMaxTime}
                              className="time-edit-datepicker"
                              ref={this.calendarRef}
                            />
                            {editClockIn &&
                              userPrivileges.indexOf("edit-time-card") > -1 && (
                                <div
                                  onClick={() =>
                                    this.toggleEditDate(
                                      obj,
                                      "editClockIn",
                                      false,
                                    )
                                  }
                                  className="time-edit-button new-blue-btn"
                                >
                                  Cancel
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="time-edit-wrapper">
                            <div className="time-edit-label">
                              {showFormattedDate(obj.clock_in, true)}
                            </div>
                            {editClockIn &&
                              userPrivileges.indexOf("edit-time-card") > -1 && (
                                <div
                                  onClick={() =>
                                    this.toggleEditDate(
                                      obj,
                                      "editClockIn",
                                      true,
                                    )
                                  }
                                  className="time-edit-button new-blue-btn"
                                >
                                  Edit
                                </div>
                              )}
                          </div>
                        )}
                      </td>
                      <td className="table-updated-td">
                        {this.state.editClockArr.find(
                          (e) => e.id === obj.id && e.editClockOut,
                        ) ? (
                          <div className="time-edit-wrapper">
                            <DatePicker
                              selected={
                                new Date(moment(obj.clock_out).format())
                              }
                              onChange={(date) =>
                                this.handleEditDate(obj, "editClockOut", date)
                              }
                              onChangeRaw={this.handleDateChangeRaw}
                              showTimeSelect
                              timeIntervals={1}
                              timeFormat="hh:mm aa"
                              dateFormat="MM/dd/yyyy hh:mm aa"
                              minDate={clockOutMinDate}
                              minTime={clockOutMinTime}
                              maxTime={clockOutMaxTime}
                              maxDate={clockOutMaxDate}
                              className="time-edit-datepicker"
                              ref={this.calendarRef}
                            />
                            {editClockOut &&
                              userPrivileges.indexOf("edit-time-card") > -1 && (
                                <div
                                  onClick={() =>
                                    this.toggleEditDate(
                                      obj,
                                      "editClockOut",
                                      false,
                                    )
                                  }
                                  className="time-edit-button new-blue-btn"
                                >
                                  Cancel
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="time-edit-wrapper">
                            <div className="time-edit-label">
                              {showFormattedDate(obj.clock_out, true)}
                            </div>
                            {editClockOut &&
                              userPrivileges.indexOf("edit-time-card") > -1 && (
                                <div
                                  onClick={() =>
                                    this.toggleEditDate(
                                      obj,
                                      "editClockOut",
                                      true,
                                    )
                                  }
                                  className="time-edit-button new-blue-btn"
                                >
                                  Edit
                                </div>
                              )}
                          </div>
                        )}
                      </td>
                      {isLogPermitted && (
                        <td className="table-updated-td max-w-30">
                          <DeleteTimeLog
                            id={obj.id}
                            refetchLogs={this.refetchLogs}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              {this.state.detailData && this.state.detailData.length === 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan="4"
                  >
                    {this.props.businessInsightLang.bi_no_record_found}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {this.state.detailData && this.state.detailData.length > 0 && (
          <div className="TimeCardTotalHours">
            <div className="detail-row">
              <label className="no-margin">Total Hours:</label>
              <span>
                {this.props.detailTotal && this.props.detailTotal.total_hours
                  ? this.props.detailTotal.total_hours
                  : "0"}
              </span>
            </div>
            <div className="detail-row">
              <label className="no-margin">Pay per Hour:</label>
              <span>
                {numberFormat(
                  this.props.detailTotal && this.props.detailTotal.hourly_rate
                    ? this.props.detailTotal.hourly_rate
                    : 0,
                  "currency",
                )}
              </span>
            </div>
            <div className="detail-row">
              <label className="no-margin">Total Gross Pay:</label>
              <span>
                {numberFormat(
                  this.props.detailTotal &&
                    this.props.detailTotal.total_gross_pay
                    ? this.props.detailTotal.total_gross_pay
                    : 0,
                  "currency",
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTimeTracking: updateTimeTracking,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.BusinessInsightReducer.action === "UPDATE_TIME_TRACKING") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.updatedTimeTracking = true;
      returnState.updatedTimeTrackingTimestamp = new Date().getTime();
    }
  }
  return returnState;
}

const withIsLogPermitted = (Component) => {
  return function Wrapper(props) {
    const logPermission = usePermission(
      PERMISSIONS.businessInsights.manualTimeLog,
    );
    return (
      <Component
        {...props}
        isLogPermitted={logPermission.loaded && logPermission.permitted}
      />
    );
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withIsLogPermitted(TimeCardInsightReportDetail));
