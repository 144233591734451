import moment from "moment";
import { useMemo } from "react";
import { useBiGiftCardHistoryQuery } from "../../../../api/businessInsight/useBiGiftCardHistoryQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { getPathParams } from "../utilities";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../../../consts/general";

export const useHistory = () => {
  const { giftCardId } = getPathParams();
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();

  const { data, isLoading } = useBiGiftCardHistoryQuery({
    payload: {
      giftCardId,
    },
    options: {
      enabled: !!giftCardId,
      onError: () => {
        uiNotification.error(tBi("giftCardHistory.error.fetch"));
      },
    },
  });

  const preparedData = useMemo(
    () =>
      data?.history?.map((item) => ({
        user: item.user_name,

        date: moment(item.created_at).format(
          `${user?.previewDateFormat || DEFAULT_DATE_FORMAT} ${
            user?.timeFormat || DEFAULT_TIME_FORMAT
          }`,
        ),

        type: item.type,

        before: item.before,

        after: item.after,

        reason: item.reason,
      })) || [],
    [data?.history, user?.dateFormat, user?.timeFormat],
  );

  return {
    value: preparedData,
    isLoading,
  };
};
