import React from "react";
import PropTypes from "prop-types";
import { Box } from "../../../../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import classes from "./Table.module.scss";

export function Table({ reports }) {
  const { tCommon } = useAppTranslation.Common();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const data = reports.value.map((r, index) => ({
    key: index,
    date: r.date,
    patientName: r.patientName,
    productName: r.productName || tCommon("symbol.longDash"),
    unitsCount: r.unitsCount,
    dollarValue: r.dollarValue,
    balanceUnitsCount: r.balanceUnitsCount,
    balanceDollarValue: r.balanceDollarValue,
  }));

  return (
    <Box overflowXScroll>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={39.06} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={data}
            cols={[
              {
                data: tCommon("label.expirationDate"),
                accessor: "date",
              },
              {
                data: tCommon("label.patient"),
                accessor: "patientName",
                className: classes.patientName,
              },
              {
                data: tCommon("label.productName"),
                accessor: "productName",
                className: classes.productName,
              },
              {
                data: tCommon("label.units"),
                accessor: "unitsCount",
              },
              {
                data: tCommon("label.dollarValue"),
                accessor: "dollarValue",
              },
              {
                data: tCommon("label.balanceUnits"),
                accessor: "balanceUnitsCount",
              },
              {
                data: tCommon("label.balanceDollarValue"),
                accessor: "balanceDollarValue",
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
};
