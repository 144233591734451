import React from "react";
import PropTypes from "prop-types";
import classes from "./PaymentsMobile.module.scss";
import { prettifyPaymentInfo } from "../../../../SalesCheckoutInvoice.utils";

export function PaymentsMobile({ list, currency }) {
  return (
    <div className={classes.root}>
      {list.map((payment) => {
        const { modeLabel, amount } = prettifyPaymentInfo(payment, currency);
        return (
          <div key={payment.id} className={classes.item}>
            <div className={classes.label}>{modeLabel}</div>
            <div className={classes.amount}>{amount}</div>
          </div>
        );
      })}
    </div>
  );
}

PaymentsMobile.propTypes = {
  list: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
};
