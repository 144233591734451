import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useEfaxIntegrationEnableMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      return http.post(HTTP_ENDPOINTS.efax.enable(), {
        countryCode: dto.countryCode,
        areaCode: dto.areaCode,
        addon_value: dto.addonValue,
      });
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.efax.status],
        });

        options?.onSuccess?.(...args);
      },
    },
  );
}
