import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classes from "./EditorFooter.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { ErrorLogs } from "../../../../../shared/ErrorLogs/ErrorLogs";

export function EditorFooter({
  onPostMultiple,
  onPostSingle,
  currentFile,
  files,
  isFilePending,
  isFilesParsing,
  errorLogs,
  isPosting,
  leftAdornment,
}) {
  const [isPostingSingle, setIsPostingSingle] = useState(false);
  const [isPostingMultiple, setIsPostingMultiple] = useState(false);

  const handlePostSingle = () => {
    setIsPostingSingle(true);
    onPostSingle();
  };

  const handlePostMultiple = () => {
    setIsPostingMultiple(true);
    onPostMultiple();
  };

  useEffect(() => {
    if (!isPosting) {
      setIsPostingMultiple(false);
      setIsPostingSingle(false);
    }
  }, [isPosting]);

  return (
    <div>
      <div className={classes.inner}>
        {leftAdornment}
        {files.length > 1 && (
          <Button
            size="small"
            variant="outlined"
            onClick={handlePostMultiple}
            isDisabled={isFilePending || isFilesParsing || isPosting}
            leftAdornment={
              isPostingMultiple && isPosting ? (
                <CircularProgress size="tiny" color="primary" />
              ) : (
                undefined
              )
            }
          >
            Upload All
          </Button>
        )}
        {currentFile && (
          <Button
            size="small"
            onClick={handlePostSingle}
            isDisabled={isFilePending || isFilesParsing || isPosting}
            leftAdornment={
              isPostingSingle && isPosting ? (
                <CircularProgress size="tiny" color="white" />
              ) : (
                undefined
              )
            }
          >
            Upload Only Current
          </Button>
        )}
      </div>
      {errorLogs.length > 0 && (
        <ErrorLogs
          logs={errorLogs.map((e) => ({
            label: e.fileName,
            errors: e.errors,
          }))}
          type="nested"
          className={classes.errorLogs}
        />
      )}
    </div>
  );
}

EditorFooter.propTypes = {
  onPostMultiple: PropTypes.func.isRequired,
  onPostSingle: PropTypes.func.isRequired,
  currentFile: PropTypes.object,
  files: PropTypes.array,
  isFilePending: PropTypes.bool,
  isFilesParsing: PropTypes.bool.isRequired,
  isPosting: PropTypes.bool.isRequired,
  errorLogs: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  leftAdornment: PropTypes.node,
};

EditorFooter.defaultProps = {
  currentFile: null,
  files: [],
  errorLogs: [],
  isFilePending: false,
  leftAdornment: null,
};
