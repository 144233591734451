import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useRevenuePerHour = (params, options = {}) => {
  return useQuery(
    ["revenuePerHour", params],
    async () => http.get(HTTP_ENDPOINTS.getRevenuePerHour(), { params }),
    { ...options, cacheTime: 0 },
  );
};
