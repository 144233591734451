import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";

export const useDeleteStripeDocument = (options = {}) => {
  return useMutation(
    (dto) => http.post(HTTP_ENDPOINTS.deleteStripeDocument(), dto),
    {
      onSuccess: (data) => uiNotification.success(data.data.message),
      onError: (error) => uiNotification.error(error.response.data.message),
      ...options,
    },
  );
};
