import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { CheckIcon } from "../../../../../../assets/Icons/CheckIcon";
import { CrossIcon } from "../../../../../../assets/Icons/CrossIcon";
import { createPasswordSchema } from "../../../../../../schemas/auth";

var errorKeys = ["length", "lower", "upper", "specialChar"];

export function PasswordRequirements({ password }) {
  var { tCommon: t } = useAppTranslation.Common();
  var getTranslationPath = (x) => `changePassword.requirements.${x}`;
  var initErrors = () => errorKeys.map((x) => t(getTranslationPath(x)));

  var [errors, setErrors] = useState(initErrors());

  var schema = createPasswordSchema({
    minError: t(getTranslationPath("length")),
    specialCharError: t(getTranslationPath("specialChar")),
    lowerLetterError: t(getTranslationPath("lower")),
    capitalLetterError: t(getTranslationPath("upper")),
  });

  useEffect(() => {
    if (password) {
      schema
        .validate(password, { abortEarly: false })
        .then(() => setErrors([]))
        .catch((err) => {
          setErrors(err?.inner?.map((x) => x.message));
        });
    } else {
      setErrors(initErrors());
    }
  }, [password]);

  var getIcon = useCallback(
    (x) => {
      if (errors.includes(t(getTranslationPath(x)))) {
        return (
          <div className={styles.error}>
            <CrossIcon width="18px" />
          </div>
        );
      }

      return (
        <div className={styles.success}>
          <CheckIcon width="18px" />
        </div>
      );
    },
    [errors],
  );

  return (
    <div className={styles.root}>
      <h5>{t("changePassword.requirements.title")}:</h5>
      <ul>
        {errorKeys.map((k) => (
          <li key={k}>
            {getIcon(k)}
            {t(getTranslationPath(k))}
          </li>
        ))}
      </ul>
    </div>
  );
}
