import moment from "moment";
import { useCreateOutOfOfficeEventMutation } from "../../../api/mutations/useCreateOutOfOfficeEventMutation";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";
import {
  APPT_API_TIME_FORMAT,
  CUSTOM_FREQUENCY_TYPE_SUFFIX,
  FREQUENCY_END_TYPE,
  FREQUENCY_REPEAT_MEASURE_TYPES,
  FREQUENCY_TYPES,
  OUT_OF_OFFICE_EDIT_TYPE,
} from "../Event.consts";

export function useMutateOutOfOffice({
  editEventId,
  editEventData,
  onSuccess = () => {},
} = {}) {
  const isEditMode = Boolean(editEventId);
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const { mutate: create, isLoading } = useCreateOutOfOfficeEventMutation({
    onSuccess: () => {
      uiNotification.success(
        `Successfully ${
          isEditMode ? "updated" : "created"
        } 'Out of Office' event`,
      );
      onSuccess();
    },
    onError: (e) => {
      const key = extractApiError(e);
      uiNotification.error(
        tCommon(
          `appointments.apiErrors.${key}`,
          key ||
            `Unable to ${
              isEditMode ? "update" : "create"
            } 'Out of Office' event. Try again later`,
        ),
      );
    },
  });

  const mutate = (values) => {
    if (!user) {
      return uiNotification.error(tCommon("error.userNotFound"));
    }

    const dto = {
      providerIds: isEditMode
        ? [editEventData.providerId]
        : values.providers.map((p) => p.id),
      clinicId: values.clinicId,
      date: values.date,
      fromTime: moment(values.timeStart, user.timeFormat).format(
        APPT_API_TIME_FORMAT,
      ),
      toTime: moment(values.timeEnd, user.timeFormat).format(
        APPT_API_TIME_FORMAT,
      ),
      frequency: values.frequency,
      name: values.eventName,
      cancelExistingAppointments: 0,
    };

    if (values.frequency !== FREQUENCY_TYPES.noRepeat) {
      dto.frequencyType = values.frequencyEndType;
      if (values.frequencyEndType === FREQUENCY_END_TYPE.date) {
        dto.endsOn = values.frequencyEndDate;
      } else {
        dto.occurrence = values.frequencyEndOccurrences;
      }
    }
    if (values.frequency === FREQUENCY_TYPES.custom) {
      dto.frequency =
        values.frequencyRepeatMeasure + CUSTOM_FREQUENCY_TYPE_SUFFIX;
      dto.interval = values.frequencyRepeatCount;
      if (
        values.frequencyRepeatMeasure === FREQUENCY_REPEAT_MEASURE_TYPES.month
      ) {
        dto.repeatType = values.frequencyRepeatMonthlyValue;
      } else if (
        values.frequencyRepeatMeasure === FREQUENCY_REPEAT_MEASURE_TYPES.week
      ) {
        dto.weekDays = values.frequencyRepeatOnWeekDays;
      }
    }
    if (isEditMode) {
      dto.id = editEventData.id;
      dto.appointmentId = editEventId;
      dto.allFollowing =
        values.editType === OUT_OF_OFFICE_EDIT_TYPE.current ? 0 : 1;
    }
    create(dto);
  };

  return {
    mutateOutOfOffice: mutate,
    isOutOfOfficeMutating: isLoading,
  };
}
