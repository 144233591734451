import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
// eslint-disable-next-line import/no-restricted-paths
import GuestHeader from "../../_legacy/Containers/Guest/Header";
import classes from "./LayoutGuest.module.scss";

export function LayoutGuest({ children, containerClassName, className }) {
  return (
    <div className={cx("guest no-padding", classes.layoutRoot)}>
      <GuestHeader />
      <div className={cx(classes.root, className)}>
        <div className={cx(classes.container, containerClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
}

LayoutGuest.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

LayoutGuest.defaultProps = {
  containerClassName: undefined,
  className: undefined,
};
