import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";

/*
  SCHEMAS
*/

const requestSchema = yup.object({
  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  filter: yup.object({
    clinicIds: yup.array().of(yup.number()).nullable(),
  }),
});

const responseSchema = yup.object({
  reports: yup
    .array()
    .of(
      yup.object({
        providerId: yup.number().required(),
        providerName: yup.string().nullable(),
        returningAmount: yup.number().nullable(),
        allAppointmentsAmount: yup.number().nullable(),
        percentage: yup.string().nullable(),
      }),
    )
    .required(),
});

/*
  COMPOSERS
*/

const composeResponse = (res) => {
  const reports = res?.data?.data || [];

  return {
    reports: reports.map((report) => ({
      providerId: report[0] || null,
      providerName: report[1] || null,
      returningAmount: report[2] || null,
      allAppointmentsAmount: report[3] || null,
      percentage: report[4] || null,
    })),
  };
};

// ---------

function createRetentionRateReportQueryKey({ fromDate, toDate, clinicIds }) {
  return [
    QUERY_KEYS.retentionRateReport,
    fromDate,
    toDate,
    clinicIds.join("-"),
  ];
}

export function useRetentionRateReportQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useQuery(
    createRetentionRateReportQueryKey({
      fromDate,
      toDate,
      clinicIds: req.filter.clinicIds || [],
    }),
    async () => {
      const res = await http.post(
        HTTP_ENDPOINTS.createRetentionRateReport(),
        removeNullishFromShape({
          start_date: fromDate,
          end_date: toDate,
          clinic_ids: req.filter.clinicIds || null,
        }),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
