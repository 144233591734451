import React, {useEffect, useState} from "react";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import { Input, Select, CheckBox, RadioButton } from '../../../Common/ReactHookForm'
import { Scrollbars } from "react-custom-scrollbars";

const FormStep6 = (props) => {
  const { handleFormStepChange, formValue, resources } = props;
  const { register, handleSubmit, watch, errors, control, setValue } = useForm({ defaultValues: formValue });
  
  const onSubmit = data => {
    handleFormStepChange(7, data)
  };

  useEffect(() => {
    if(formValue.timezone == '') {
      setValue('timezone', formValue.defaultTimezone);
    }
  }, [])

  const getStateOptions = (options) => {
    if(options && options.length > 0) {
      let finalOpt = options.map((obj, idx) => {
        return {value: obj.stateCode, label: obj.stateName}
      })
      return finalOpt;
    }
  }
  const getTimezones = () => {
    return [{value: "America/Juneau", label: "(UTC-09:00) Alaska"}, {value: "America/Los_Angeles", label: "(UTC-08:00) Pacific Time (US & Canada)"}, {value: "America/New_York", label: "(UTC-05:00) Eastern Time (US & Canada)"}, {value: "America/Chicago", label: "(UTC-06:00) Central Time (US & Canada)"}, {value: "America/Phoenix", label: "(UTC-07:00) Arizona"}, {value: "America/Denver", label: "(UTC-07:00) Mountain Time (US & Canada)"}, {value: "Pacific/Honolulu", label: "(UTC-10:00) Hawaii"}]
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="setting-setion">
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={window.innerHeight-240}
        className="full-width"
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
      >
        <div className="setting-container p20">

			 <a className="easy-link p-l-0" onClick={() => {
            handleFormStepChange(5)
          }}><i className="fa fa-angle-left m-r-5"></i> Go Back</a>

          <div className="merchant-title">Merchant Application</div>
          <p className="p-text m-b-20">Welcome to the merchant facing application! Thank you for taking the time to help speed up your company's boarding process. Please fill out the application below to the best of your ability and click send when you're finished. This will alert your local rep.</p>

          <div className="settings-subtitle m-b-10 merchantSteps">Please select timezone of your practice:</div>
          <div className="row m-b-20">
            <div className="col-sm-6 col-xs-12 m-b-15">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Timezone<span className="setting-require">*</span></div>
                <Select
                  className={`newSelectField ${(errors && errors.timezone) ? 'field_error' : ''}`}
                  name='timezone'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  options = {getTimezones()}
                />
              </div>
            </div>
          </div>
          <div className="settings-subtitle m-b-20 merchantSteps">Card Reader Shipping Details</div>


          <div className="merchant-subtitle m-b-10">Shipping Details:</div>

          <div className="row">
            <div className="col-sm-4 col-xs-12 m-b-15">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Shipping Street Address<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.streetAddress) ? 'field_error' : ''}`}
                  placeholder="Street Address"
                  name='streetAddress'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xs-12 m-b-15">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Suite/Apt Number<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.shippingStreet2) ? 'field_error' : ''}`}
                  placeholder="Suite/Apt Number"
                  name='shippingStreet2'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xs-12 m-b-15">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">City<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.shippingCity) ? 'field_error' : ''}`}
                  placeholder="City"
                  name='shippingCity'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xs-12 m-b-15">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">State<span className="setting-require">*</span></div>
                <Select
                  className={`newSelectField ${(errors && errors.shippingState) ? 'field_error' : ''}`}
                  name='shippingState'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  options = {getStateOptions(resources.StateOptions)}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xs-12 m-b-15">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Zip<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.shippingZipCode) ? 'field_error' : ''}`}
                  placeholder="Zip"
                  name='shippingZipCode'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>

          </div>
        </div>
    </Scrollbars>
		<div class="footer-static merchantFooter">
			<div class="footerProgressOuter"> 
			 <p><span>Wohoo!</span> Complete this page</p>
			 <p>and you are done!</p>
			<div class="footerProgressBg"><span class="footerProgressBar" style={{width: "75%"}}></span></div>
			</div>
			<button class="new-blue-btn m-t-5" type="submit">Next</button>
		</div>
      </div>
    </form>
  );
}

export default FormStep6;
