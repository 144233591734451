import { useMemo } from "react";
import {
  QUALIPHY_EXAM_TYPES,
  useQualiphyExamsQuery,
} from "../../../api/qualiphy/useQualiphyExamsQuery";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";

export function useExams({ clinicId }) {
  const { tCommon } = useAppTranslation.Common();

  const examsQuery = useQualiphyExamsQuery({
    payload: {
      clinicId: Number(clinicId),
    },
    options: {
      enabled: Boolean(clinicId),
      onError: () => {
        uiNotification.error(tCommon("error.qualiphy.getExams"));
      },
    },
  });

  const examOptions = useMemo(() => {
    var exams =
      examsQuery.data?.map((exam) => ({
        label: exam.name,
        value: exam.id,
        isAttachmentsRequired: exam.isAttachmentsRequired,
        rx: exam.rx,
        type: exam.type,
      })) || [];

    var rxExams = exams
      .filter((exam) => exam.type === QUALIPHY_EXAM_TYPES.rx)
      .map((exam) => ({
        ...exam,
        label: `RX - ${exam.label}`,
      }));

    var restExams = exams.filter(
      (exam) => exam.type !== QUALIPHY_EXAM_TYPES.rx,
    );

    return [...rxExams, ...restExams];
  }, [examsQuery.data]);

  return {
    examOptions,
    isExamsFetching: examsQuery.isFetching,
  };
}
