import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./LayoutSettings.module.scss";
// eslint-disable-next-line import/no-restricted-paths
import Sidebar from "../../_legacy/Containers/Settings/sidebar";

export function LayoutSettings({ children, contentClassName }) {
  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={cx(classes.content, contentClassName)}>{children}</div>
    </div>
  );
}

LayoutSettings.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  contentClassName: PropTypes.string,
};

LayoutSettings.defaultProps = {
  contentClassName: undefined,
};
