import React from "react";
import { Modal } from "../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./ModalSettings.module.scss";
import { Views } from "./boxes/Views/Views";
import { BmiRanges } from "./boxes/BmiRanges/BmiRanges";

export const ModalSettings = ({ onClose }) => {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Modal
      isOpen
      size="large"
      shouldCloseOnOverlayClick={false}
      onClose={onClose}
      header={<Modal.Title>{tCommon("label.settings")}</Modal.Title>}
      contentClassName={classes.root}
    >
      <Views />
      <BmiRanges />
    </Modal>
  );
};
