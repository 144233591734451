import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import CommonClinic from "../components/common/CommonClinic";
import OutOfOfficeDateTime from "../components/outOfOffice/OutOfOfficeDateTime";
import OutOfOfficeEnds from "../components/outOfOffice/OutOfOfficeEnds";
import OutOfOfficeFrequency from "../components/outOfOffice/OutOfOfficeFrequency";
import OutOfOfficeProviders from "../components/outOfOffice/OutOfOfficeProviders";
import OutOfOfficeRepeatEvery from "../components/outOfOffice/OutOfOfficeRepeatEvery";
import OutOfOfficeEditType from "../components/outOfOffice/OutOfOfficeEditType";
import {
  FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES,
  FREQUENCY_TYPES,
  OUT_OF_OFFICE_EDIT_TYPE,
} from "../Event.consts";
import {
  composeFrequencyRepeatMonthlyValue,
  wrapFormDate,
  initOutOfOfficeTimeEnd,
} from "../Event.utils";
import { useApiClinics } from "../hooks/useApiClinics";
import { useApiProvidersByClinics } from "../hooks/useApiProvidersByClinics";
import {
  initOutOfOfficeForm,
  useFormOutOfOffice,
} from "../hooks/useFormOutOfOffice";
import { useMutateOutOfOffice } from "../hooks/useMutateOutOfOffice";
import { EntityRow } from "../components/EntityRow";
import CommonType from "../components/common/CommonType";
import { event } from "../../../store/event";
import { CommonControlButtons } from "../components/common/CommonControlButtons";
import { Input } from "../../../shared/Input/Input";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { DEFAULT_TIME_FORMAT } from "../../../consts/general";

const OutOfOffice = ({
  match: { params },
  history,
  editEventId,
  editEventData,
  eventType,
  changeEventType,
  isEventSubmitActive,
}) => {
  const isEditMode = Boolean(editEventId);
  const { data: user } = useCurrentUserQuery();
  const userTimeFormat = user?.timeFormat || DEFAULT_TIME_FORMAT;

  const { form, hasError, setFormValue, submit } = useFormOutOfOffice({
    isEditMode,
    initialValues: initOutOfOfficeForm({
      editEventData,
      params,
      userTimeFormat,
    }),
  });

  const { clinics, isClinicsFetching } = useApiClinics();

  const { providersByClinic, isProvidersByClinicFetching } =
    useApiProvidersByClinics({
      clinicIds: form.clinicId ? [form.clinicId] : [],
      isActive: !isEditMode,
    });

  const { mutateOutOfOffice, isOutOfOfficeMutating } = useMutateOutOfOffice({
    editEventId,
    editEventData,
    onSuccess: () => {
      history.push("/appointment/index");
    },
  });

  const isLoading =
    isClinicsFetching || isProvidersByClinicFetching || isOutOfOfficeMutating;

  /* Clinics */

  const onChangeClinicId = (nextClinicId) => {
    setFormValue("clinicId", nextClinicId);
  };

  /* DateTime */

  const onDateChange = (nextDate) => {
    setFormValue("date", wrapFormDate(nextDate));
    if (
      form.frequencyRepeatMonthlyValue !==
      FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES.eventDate
    ) {
      setFormValue(
        "frequencyRepeatMonthlyValue",
        composeFrequencyRepeatMonthlyValue(wrapFormDate(nextDate)),
      );
    }
    if (moment(form.frequencyEndDate).isBefore(nextDate)) {
      setFormValue("frequencyEndDate", "");
    }
  };

  const onTimeStartChange = (nextTimeStart) => {
    setFormValue("timeStart", nextTimeStart);
    setFormValue(
      "timeEnd",
      moment(
        initOutOfOfficeTimeEnd(
          moment(nextTimeStart, userTimeFormat).format("hh:mm A"),
        ),
        "hh:mm A",
      ).format(userTimeFormat),
    );
  };

  const onTimeEndChange = (nextTimeEnd) => {
    setFormValue("timeEnd", nextTimeEnd);
  };

  /* Event name */

  const onEventNameChange = (nextEventName) => {
    setFormValue("eventName", nextEventName);
  };

  /* Providers */

  const onProvidersChange = (nextProviders) => {
    setFormValue("providers", nextProviders);
  };

  /* Frequency */

  const onFrequencyChange = (nextFrequency) => {
    setFormValue("frequency", nextFrequency);
  };

  const onFrequencyRepeatCountChange = (nextFrequencyRepeatCount) => {
    setFormValue("frequencyRepeatCount", nextFrequencyRepeatCount);
  };

  const onFrequencyRepeatMeasureChange = (nextFrequencyRepeatMeasure) => {
    setFormValue("frequencyRepeatMeasure", nextFrequencyRepeatMeasure);
  };

  const onFrequencyRepeatMonthlyValueChange = (
    nextFrequencyRepeatMonthlyValue,
  ) => {
    setFormValue(
      "frequencyRepeatMonthlyValue",
      nextFrequencyRepeatMonthlyValue,
    );
  };

  const onFrequencyRepeatOnWeekDaysChange = (nextFrequencyRepeatOnWeekDays) => {
    setFormValue("frequencyRepeatOnWeekDays", nextFrequencyRepeatOnWeekDays);
  };

  const onFrequencyEndTypeChange = (nextFrequencyEndType) => {
    setFormValue("frequencyEndType", nextFrequencyEndType);
  };

  const onFrequencyEndDateChange = (nextFrequencyEndDate) => {
    setFormValue("frequencyEndDate", wrapFormDate(nextFrequencyEndDate));
  };

  const onFrequencyEndOccurrencesChange = (nextFrequencyEndOccurrences) => {
    setFormValue("frequencyEndOccurrences", nextFrequencyEndOccurrences);
  };

  /* Edit type */

  const onEditTypeChange = (nextEditType) => {
    setFormValue("editType", nextEditType);
  };

  /* --------- */

  return (
    <div>
      {!isEditMode && (
        <EntityRow label="Type" width="none">
          <CommonType
            eventType={eventType}
            onChange={(nextEventType) =>
              changeEventType({ eventType: nextEventType })
            }
          />
        </EntityRow>
      )}
      {isEditMode && (
        <OutOfOfficeEditType
          type={form.editType}
          onTypeChange={onEditTypeChange}
        />
      )}
      <CommonClinic
        clinicError={hasError("clinicId")}
        clinicId={form.clinicId}
        onChange={onChangeClinicId}
        clinics={clinics}
      />
      <OutOfOfficeDateTime
        date={form.date}
        onDateChange={onDateChange}
        isDateError={hasError("date")}
        timeStart={form.timeStart}
        onTimeStartChange={onTimeStartChange}
        isTimeStartError={hasError("timeStart")}
        timeEnd={form.timeEnd}
        onTimeEndChange={onTimeEndChange}
        isTimeEndError={hasError("timeEnd")}
      />
      <EntityRow label="Name of event">
        <Input
          name="eventName"
          value={form.eventName}
          isError={hasError("eventName")}
          onChange={(e) => onEventNameChange(e.target.value)}
        />
      </EntityRow>
      {!isEditMode && (
        <OutOfOfficeProviders
          isError={hasError("providers")}
          onChange={onProvidersChange}
          options={providersByClinic}
          values={form.providers}
        />
      )}
      {((isEditMode && form.editType === OUT_OF_OFFICE_EDIT_TYPE.allFuture) ||
        !isEditMode) && (
        <>
          <OutOfOfficeFrequency
            value={form.frequency}
            onChange={onFrequencyChange}
            eventDate={form.date}
          />
          {form.frequency === FREQUENCY_TYPES.custom && (
            <OutOfOfficeRepeatEvery
              eventDate={form.date}
              frequencyRepeatCount={form.frequencyRepeatCount}
              onFrequencyRepeatCountChange={onFrequencyRepeatCountChange}
              isFrequencyRepeatCountError={hasError("frequencyRepeatCount")}
              frequencyRepeatMeasure={form.frequencyRepeatMeasure}
              onFrequencyRepeatMeasureChange={onFrequencyRepeatMeasureChange}
              frequencyRepeatMonthlyValue={form.frequencyRepeatMonthlyValue}
              onFrequencyRepeatMonthlyValueChange={
                onFrequencyRepeatMonthlyValueChange
              }
              frequencyRepeatOnWeekDays={form.frequencyRepeatOnWeekDays}
              onFrequencyRepeatOnWeekDaysChange={
                onFrequencyRepeatOnWeekDaysChange
              }
            />
          )}
          {form.frequency !== FREQUENCY_TYPES.noRepeat && (
            <OutOfOfficeEnds
              eventDate={form.date}
              frequencyEndType={form.frequencyEndType}
              onFrequencyEndTypeChange={onFrequencyEndTypeChange}
              frequencyEndDate={form.frequencyEndDate}
              onFrequencyEndDateChange={onFrequencyEndDateChange}
              isFrequencyEndDateError={hasError("frequencyEndDate")}
              frequencyEndOccurrences={form.frequencyEndOccurrences}
              onFrequencyEndOccurrencesChange={onFrequencyEndOccurrencesChange}
              isFrequencyEndOccurrencesError={hasError(
                "frequencyEndOccurrences",
              )}
            />
          )}
        </>
      )}
      <CommonControlButtons
        onCancel={() =>
          history.push(
            params.clientId
              ? `/clients/profile/${params.clientId}`
              : "/appointment/index",
          )
        }
        onSubmit={() => submit(mutateOutOfOffice)}
        isSubmitEnabled={isEventSubmitActive}
      />
      {isLoading && <Loader />}
    </div>
  );
};

OutOfOffice.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  history: PropTypes.object,
  editEventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editEventData: PropTypes.object,
  eventType: PropTypes.string,
  changeEventType: PropTypes.func,
  isEventSubmitActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  eventType: event.selectors.selectEventType(state),
  isEventSubmitActive: event.selectors.selectIsSubmitActive(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeEventType: event.actions.eventTypeChange,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OutOfOffice));
