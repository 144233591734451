import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
  amount: yup.number().required(),
});

export function useChargeWithKlarnaMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });

    return http.post(HTTP_ENDPOINTS.postChargeInvoiceWithKlarna(), {
      invoice_id: req.invoiceId,
      requested_payment: req.amount,
      isKlarnaAvailable: req.true,
    });
  }, options);
}
