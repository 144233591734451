import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import classes from "./TotalCount.module.scss";

export function TotalCount({ children }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      {tCommon("label.totalCount", {
        count: children,
      })}
    </div>
  );
}

TotalCount.propTypes = {
  children: PropTypes.number.isRequired,
};
