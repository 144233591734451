import { useMemo } from "react";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { usePPAnnouncementBannersQuery } from "../../../../../api/patientPortal/announcementBanner/usePPAnnouncementBannersQuery";
import { uiNotification } from "../../../../../services/UINotificationService";

export function useBanners() {
  const { tSmartConfig } = useAppTranslation.SmartConfig();

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    usePPAnnouncementBannersQuery({
      onError: () => {
        uiNotification.error(
          tSmartConfig("patientPortal.banner.error.fetchAll"),
        );
      },
    });

  const banners = useMemo(
    () =>
      data?.pages?.map((p) => p.data).reduce((r, p) => [...r, ...p], []) || [],
    [data?.pages],
  );

  return {
    banners,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
}
