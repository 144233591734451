import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Signature } from "../../../../../boxes/Signature/Signature";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import classes from "./ClientSignature.module.scss";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { InputError } from "../../../../../shared/InputError/InputError";

export function ClientSignature() {
  const { tCommon } = useAppTranslation.Common();
  const { sign } = useInvoiceActions();

  const schema = yup.object({
    signature: yup.string().required(tCommon("formError.signatureRequired")),
  });

  const { setFieldValue, handleSubmit, errors, isValid } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: ({ signature }) => {
      sign.initiate({ imageData: signature });
    },
    initialValues: {
      signature: "",
    },
  });

  return (
    <LabeledBox label={tCommon("label.clientSignature")}>
      <div>
        <Signature
          onChange={(signature) => setFieldValue("signature", signature)}
          onClear={() => setFieldValue("signature", "")}
          isError={!!errors.signature}
          isDisabled={sign.isLoading}
        />
        {!!errors.signature && <InputError>{errors.signature}</InputError>}
      </div>
      <Button
        onClick={handleSubmit}
        isDisabled={!isValid || sign.isLoading}
        className={classes.completeBtn}
        leftAdornment={
          sign.isLoading ? (
            <CircularProgress size="small" color="white" />
          ) : undefined
        }
      >
        {tCommon("label.complete")}
      </Button>
    </LabeledBox>
  );
}
