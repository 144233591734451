import { useQuestionnaireDeleteMutation } from "../../../../api/questionnaires/useQuestionnaireDeleteMutation";
import { history } from "../../../../history";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export function useDelete(questionnaireId) {
  const { tCommon } = useAppTranslation.Common();

  const { mutate, isLoading } = useQuestionnaireDeleteMutation({
    onError: () => {
      uiNotification.error(tCommon("error.deleteQuestionnaire"));
    },
    onSuccess: () => {
      history.goBack();
    },
  });

  function initiate() {
    if (questionnaireId) {
      mutate({ id: questionnaireId });
    }
  }

  return {
    initiate,
    isLoading,
  };
}
