import { useUserLogsDiffQuery } from "../../../../../api/queries/useUserLogsDiffQuery";
import { getPathParams } from "../utils";

export function useDiff() {
  const { type, childId, objectId } = getPathParams();

  const { data, isLoading } = useUserLogsDiffQuery({
    payload: {
      type,
      childId,
      objectId,
    },
  });

  return {
    data,
    type,
    isLoading,
  };
}
