import { useMemo } from "react";
import { useClinicsQuery } from "../../../../../../api/queries/useClinicsQuery";

export const useClinicsOptions = () => {
  const { data: clinics } = useClinicsQuery();

  const clinicsOptions = useMemo(
    () =>
      clinics?.map((clinic) => ({
        label: clinic.name,
        value: clinic.id,
      })),
    [clinics],
  );

  return clinicsOptions;
};
