import { useState } from "react";
import { debounce } from "lodash";

export function useAutoResetState(initialState, resetTo, delay = 3000) {
  const [state, setState] = useState(initialState);

  const updateState = (next) => {
    if (typeof next === "function") {
      setState((prev) => next(prev));
    } else {
      setState(next);
    }

    const reset = debounce(() => {
      setState(resetTo);
    }, delay);

    reset();
  };

  return [state, updateState];
}
