import React from "react";
import classes from "./Form.module.scss";
import { Input } from "../../../../../../../../../shared/Input/Input";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../../../../shared/Button/Button";
import { SaveIcon } from "../../../../../../../../../assets/Icons/SaveIcon";
import { useForm } from "./hooks/useForm";
import { AlertBox } from "../../../../../../../../../shared/AlertBox/AlertBox";
import { CrossIcon } from "../../../../../../../../../assets/Icons/CrossIcon";
import { useSubmit } from "./hooks/useSubmit";
import { CircularProgress } from "../../../../../../../../../shared/CircularProgress/CircularProgress";
import { ColorSelect } from "../../../../../../../../../shared/ColorSelect/ColorSelect";

/**
 * @param {Object} param0
 *
 * @param {{
 *  id?: number;
 *  name?: string;
 *  min?: number;
 *  max?: number;
 *  color?: string;
 * }} param0.preloadedState
 *
 * @param {() => void} param0.reset
 */
export const Form = ({ preloadedState, reset }) => {
  const { tClients } = useAppTranslation.Clients();

  const submit = useSubmit();

  const form = useForm({
    initialValues: {
      id: preloadedState?.id,
      name: preloadedState?.name,
      min: preloadedState?.min,
      max: preloadedState?.max,
      color: preloadedState?.color,
    },
    onSubmit: (values, { resetForm }) => {
      submit.initiate(values).then(() => {
        resetForm();
        reset();
      });
    },
  });

  const firstError = form.getFirstError();

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <ColorSelect
          size="small"
          value={form.values.color}
          onChange={(color) => form.setFieldValue("color", color)}
        />
        <Input
          name="name"
          value={form.values.name}
          size="small"
          placeholder={tClients("vitals.settings.createBmi.namePlaceholder")}
          className={classes.name}
          onChange={form.handleChange}
        />
        <Input
          name="min"
          value={form.values.min}
          size="small"
          type="number"
          placeholder={tClients("vitals.settings.createBmi.minPlaceholder")}
          inputClassName={classes.range}
          onChange={form.handleChange}
        />
        <Input
          name="max"
          size="small"
          value={form.values.max}
          type="number"
          placeholder={tClients("vitals.settings.createBmi.maxPlaceholder")}
          inputClassName={classes.range}
          onChange={form.handleChange}
        />
        <div className={classes.actions}>
          <Button
            size="small"
            color="success"
            onClick={form.handleSubmit}
            isDisabled={submit.isLoading}
          >
            {submit.isLoading ? (
              <CircularProgress size="tiny" color="white" />
            ) : (
              <SaveIcon width="16px" />
            )}
          </Button>
          <Button
            size="small"
            color="error"
            variant="outlined"
            onClick={reset}
            isDisabled={submit.isLoading}
          >
            <CrossIcon width="16px" />
          </Button>
        </div>
      </div>
      {firstError && (
        <AlertBox size="small" variant="error">
          {firstError}
        </AlertBox>
      )}
    </div>
  );
};
