import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { http } from "../../../../../services/HttpService";

export const useCreateChartingFilterCategories = (options = {}) => {
  return useMutation(
    async (dto) =>
      await http.post(HTTP_ENDPOINTS.postChartingFilterCategories(), dto),
    options,
  );
};
