import { useProcedureDeleteMutation } from "../../../../api/procedure/useProcedureDeleteMutation";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { history } from "../../../../history";

export function useDeleteProcedure(procedureId) {
  const { tCommon } = useAppTranslation.Common();

  const { mutate, isLoading } = useProcedureDeleteMutation({
    onError: () => {
      uiNotification.error(tCommon("error.deleteProcedure"));
    },
    onSuccess: () => {
      history.goBack();
    },
  });

  const initiate = () => {
    if (procedureId) {
      mutate({ procedureId });
    }
  };

  return {
    initiate,
    isLoading,
  };
}
