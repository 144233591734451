import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";

export const useProviderDashboardWidget = (url, params, options = {}) => {
  const res = useQuery(
    [`providerDashboardWidget-${url}`, params],
    async () => {
      return http.get(url, { params });
    },
    { cacheTime: 0, select: (data) => data.data.data, options },
  );
  return res;
};
