import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createEditInvoiceText, fetchInvoiceText} from '../../../../Actions/Sales/salesActions.js';
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js'
import Loader from '../../../../Components/Common/Loader.js'
import { uiNotification } from '../../../../services/UINotificationService.js';

class InvoiceDisclaimerText extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      report_data: '',
      invoice_text: '',
      createEditInvoicesList: [],
      globalLang: languageData.global,
      salesLang: languageData.sales,
      showLoader: false
    };
    localStorage.setItem('loadFresh', false);
    localStorage.setItem('sortOnly', false);
  }

  componentDidMount(){
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.setState({'showLoader': true});
    this.props.fetchInvoiceText();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fetchInvoicesList !== undefined && props.fetchInvoicesList.status === 200 && state.fetchInvoicesList != props.fetchInvoicesList.data) {
      return {
        fetchInvoicesList: props.fetchInvoicesList.data,
        invoice_text : (state.userChanged) ? state.invoice_text : props.fetchInvoicesList.data.report_data,
        showLoader : false,
        };
      }
      else if (props.createEditInvoicesList !== undefined && props.createEditInvoicesList.status === 200 && state.createEditInvoicesList != props.createEditInvoicesList.data && props.showLoaderTime != state.showLoaderTime) {
        return {
          showLoader : false,
          showLoaderTime : props.showLoaderTime
          };
        }
      else
        return null;
  }

  handleSubmit = (event) => {
    event.preventDefault();
      let formData = {
        invoice_text : this.state.invoice_text
      }
      this.setState({showLoader : true})
      this.props.createEditInvoiceText(formData);
   }

    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({
        [event.target.name]: value, userChanged : true
      });
    }

  render(){
    return(
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar/>
          <div className="business-setion memberWalletOuter">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                Invoice Disclaimer Text
                <div className="memberRightActions">
                <input className="new-blue-btn pull-right edit_setting" type="button" id="save-profile" autoComplete="off" value={this.state.salesLang.sales_save} onClick={this.handleSubmit} />
                </div>
              </div>
            </div>
            <div className="setting-setion p25">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">{this.state.salesLang.sales_invoice_disclaimer}</div>
                    <textarea id="invoice_text" name="invoice_text" rows={10} className="newtextareaField invoice-textarea" value={this.state.invoice_text} onChange={this.handleInputChange} />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">{this.state.salesLang.sales_Invoice_preview}</div>
                    <div className="newtextareaField invoice-textarea">
                      <img src="/images/invoice45.png" className="invoice-img m-t-20" />
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
            <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  uiNotification.clear();
  if (state.SalesReducer.action === "FETCH_INVOICES") {
    if(state.SalesReducer.data.status === 200){
      return {fetchInvoicesList: state.SalesReducer.data }
  }
  else {
      uiNotification.error(languageData.global[state.SalesReducer.data.message]);
    }
  }
  if (state.SalesReducer.action === "CREATE_EDIT_INVOICES") {
    if(state.SalesReducer.data.status === 200){
      return {
        createEditInvoicesList: state.SalesReducer.data ,
        message : uiNotification.success(languageData.global[state.SalesReducer.data.message]) ,
        showLoaderTime : new Date()
      }
  }
  else {
      uiNotification.error(languageData.global[state.SalesReducer.data.message]);
    }
  }

  return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      createEditInvoiceText: createEditInvoiceText,
      fetchInvoiceText: fetchInvoiceText
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDisclaimerText);
