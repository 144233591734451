import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./CardReaderDesc.module.scss";

export function CardReaderDesc({ serialNumber, clinicName }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      <div className={classes.col}>
        <div className={classes.label}>{tCommon("label.serialNumber")}</div>
        {serialNumber || tCommon("symbol.longDash")}
      </div>
      <div className={classes.col}>
        <div className={classes.label}>{tCommon("label.clinic")}</div>
        {clinicName || tCommon("symbol.longDash")}
      </div>
    </div>
  );
}

CardReaderDesc.propTypes = {
  serialNumber: PropTypes.string.isRequired,
  clinicName: PropTypes.string.isRequired,
};
