import React from "react";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { Select } from "../../../../../../../../shared/Select/Select";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { InputError } from "../../../../../../../../shared/InputError/InputError";

const PaymentFormModal = ({
  cardOptions,
  form,
  errors,
  changeFormValue,
  submit,
  isValid,
}) => {
  const { paymentViewClose } = useInvoiceActions();

  return (
    <>
      <div className="col-sm-12 p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.creditCard")}
        </InputLabel>
        <Select
          size="medium"
          isError={errors.selectedCard}
          value={form.selectedCard}
          onChange={(option) => changeFormValue("selectedCard", option)}
          options={cardOptions || []}
        />
        {errors.selectedCard && <InputError>{errors.selectedCard}</InputError>}
      </div>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        isCharging={false}
        chargeLabel={tCommon("label.continue")}
        onCharge={submit}
        isChargeDisabled={!isValid}
      />
    </>
  );
};

export default PaymentFormModal;
