import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { dispatch } from "../../../../store/store";
import { providerRoom } from "../../../../store/providerRoom";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

function TabCosmeticRowActions({
  procedureId,
  isSentToMd,
  isComplete,
  isSigning,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isIncompleteSignModalOpen, setIsIncompleteSignModalOpen] =
    useState(false);

  const handleSign = () => {
    setIsIncompleteSignModalOpen(false);
    if (!isSentToMd) {
      dispatch(
        providerRoom.actions.signProceduresDataChange({
          procedureIds: [procedureId],
          isSendToMd: true,
        }),
      );
    }
  };

  const handleSendToMd = () => {
    if (isComplete) {
      handleSign();
    } else {
      setIsIncompleteSignModalOpen(true);
    }
  };

  return (
    <>
      <Button
        size="small"
        isDisabled={isSentToMd || isSigning}
        onClick={handleSendToMd}
        leftAdornment={
          isSigning ? <CircularProgress size="tiny" color="white" /> : undefined
        }
      >
        {tCommon("providerRoom.action.sendToMd")}
      </Button>
      <ConfirmModal
        isOpen={isIncompleteSignModalOpen}
        onClose={() => setIsIncompleteSignModalOpen(false)}
        onCancel={() => setIsIncompleteSignModalOpen(false)}
        onConfirm={handleSign}
      >
        {tCommon("providerRoom.confirm.signIncomplete")}
      </ConfirmModal>
    </>
  );
}

TabCosmeticRowActions.propTypes = {
  procedureId: PropTypes.number.isRequired,
  isSentToMd: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isSigning: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isSigning: providerRoom.selectors.selectProcedureSigningStatus(state),
  };
}

export default connect(mapStateToProps)(TabCosmeticRowActions);
