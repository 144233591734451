import React from "react";
import { connect } from "react-redux";
import classes from "./styles.module.scss";
import { Select } from "../../../../../../shared/Select/Select";
import { useUpdate } from "../hooks/useUpdate";
import { productPricingMember } from "../../../../../../store/inventory/productPricingMember";
import { dispatch } from "../../../../../../store/store";
import { useInit } from "./hooks/useInit";
import {
  isDefaultMembership,
  isPricesChanged,
} from "../../../../../../store/inventory/productPricingMember/utilities";
import { MemberPriceInfoLabel } from "./shared/MemberPriceInfoLabel/MemberPriceInfoLabel";
import { RouterPrompt } from "../../../../../../widgets/RouterPrompt/RouterPrompt";
import { Checkbox } from "../shared/Checkbox/Checkbox";
import { Input } from "../shared/Input/Input";
import { SaveButton } from "../shared/SaveButton/SaveButton";
import { ClinicRow } from "../shared/ClinicRow/ClinicRow";
import commonClasses from "../styles.module.scss";
import { UNSAVED_CHANGES_TEXT } from "../config";
import { PerUnitPrice } from "./shared/PerUnitPrice/PerUnitPrice";
import { PageOverlayLoader } from "../../../../../../shared/PageOverlayLoader/PageOverlayLoader";
import { ConfirmModal } from "../../../../../../boxes/ConfirmModal/ConfirmModal";

const { selectors, actions } = productPricingMember;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  forAllMemberships: selectors.selectForAllMemberships(state),
  membership: selectors.selectMembership(state),
  memberships: selectors.selectMemberships(state),
  clinics: selectors.selectClinics(state),
  getPerUnitPrice: selectors.selectPerUnitPrice(state),
  getMemberPrice: selectors.selectMemberPrice(state),
  getOriginalPrices: selectors.selectOriginalPrices(state),
  getChangedPrices: selectors.selectChangedPrices(state),
  getChangedPricesForRequest: selectors.selectChangedPricesForRequest(state),
  isInitialized: selectors.selectInitialized(state),
});

export const PricingMember = connect(mapStateToProps)(
  ({
    productId,
    initData,
    refetch,
    forAllClinics,
    forAllMemberships,
    membership,
    memberships,
    clinics,
    getPerUnitPrice,
    getMemberPrice,
    getOriginalPrices,
    getChangedPrices,
    getChangedPricesForRequest,
    isInitialized,
  }) => {
    useInit(initData, isInitialized);

    const [isConfirmSaveOpen, setIsConfirmSaveOpen] = React.useState(false);
    const updatePriceList = useUpdate(refetch);

    const changeMembershipAction = (action) => {
      const isChanged = isPricesChanged({
        preparedPrices: getChangedPrices(),
        initialPreparedPrices: getOriginalPrices(),
        forAllClinics,
      });

      if (isChanged && window.confirm(UNSAVED_CHANGES_TEXT)) {
        dispatch(actions.revertChangedPrices());
      }

      action();
    };

    const onSave = () => {
      const requestChangedPrices = getChangedPricesForRequest();

      updatePriceList.initiate({
        productId: Number(productId),
        priceList: requestChangedPrices,
      });
    };

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className={commonClasses.wrap}>
            <h4>Membership Price</h4>
            <div className={commonClasses.right}>
              <Checkbox
                label="Same for All Clinics"
                checked={forAllClinics}
                className={classes.checkbox}
                onChange={() => {
                  dispatch(actions.setForAllClinics(!forAllClinics));
                }}
              />
              <Checkbox
                label="Same for All Memberships"
                checked={forAllMemberships}
                className={classes.checkbox}
                onChange={() => {
                  changeMembershipAction(() =>
                    dispatch(actions.setForAllMemberships(!forAllMemberships)),
                  );
                }}
              />
              <div>
                <span className={classes.selectMembership}>
                  Select a membership
                </span>
                <Select
                  className={classes.membershipSelect}
                  size="small"
                  options={memberships}
                  value={membership}
                  onChange={(option) => {
                    changeMembershipAction(() =>
                      dispatch(actions.selectMembership(option)),
                    );
                  }}
                />
              </div>
              <SaveButton
                isLoading={updatePriceList.isLoading}
                onClick={() => {
                  if (forAllClinics && forAllMemberships) {
                    setIsConfirmSaveOpen(true);
                  } else {
                    onSave();
                  }
                }}
              />
            </div>
          </div>
          {forAllClinics ? (
            <div className="row">
              <div className="col-sm-4 col-xs-12">
                <PerUnitPrice>{getPerUnitPrice()}</PerUnitPrice>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-8 col-xs-12">
                <Input
                  placeholder="Price for Members"
                  value={getMemberPrice()}
                  onChange={(e) => {
                    dispatch(
                      actions.changePrice({
                        value: e.target.value,
                      }),
                    );
                  }}
                  label={
                    isDefaultMembership(membership)
                      ? "Member Price"
                      : membership?.label
                  }
                />
              </div>
            </div>
          ) : (
            clinics.map((clinic) => (
              <ClinicRow label={clinic.name} key={clinic.id}>
                <div className="col-sm-4 col-xs-12">
                  <PerUnitPrice>{getPerUnitPrice(clinic.id)}</PerUnitPrice>
                </div>
                <div className="col-md-4 col-sm-5 col-xs-12">
                  <Input
                    placeholder="Price for Members"
                    value={getMemberPrice(clinic.id)}
                    onChange={(e) => {
                      dispatch(
                        actions.changePrice({
                          value: e.target.value,
                          clinicId: clinic.id,
                        }),
                      );
                    }}
                    label={
                      !isDefaultMembership(membership) ? (
                        membership.label
                      ) : (
                        <MemberPriceInfoLabel />
                      )
                    }
                  />
                </div>
              </ClinicRow>
            ))
          )}
        </div>
        <RouterPrompt
          nativePrompt
          whenFn={() =>
            isPricesChanged({
              preparedPrices: getChangedPrices(),
              initialPreparedPrices: getOriginalPrices(),
              forAllClinics,
            })
          }
          title="Warning"
          text={UNSAVED_CHANGES_TEXT}
        />
        {updatePriceList.isLoading && (
          <PageOverlayLoader message="Saving changes. Please wait" />
        )}
        {isConfirmSaveOpen && (
          <ConfirmModal
            isOpen
            onClose={() => setIsConfirmSaveOpen(false)}
            onCancel={() => setIsConfirmSaveOpen(false)}
            onConfirm={() => {
              setIsConfirmSaveOpen(false);
              onSave();
            }}
          >
            You will override your individual pricing for each membership
          </ConfirmModal>
        )}
      </div>
    );
  },
);
