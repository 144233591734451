export const PICKER_KEY = "selection";

export const EMPTY_RANGE_PREVIEW = "--/--/----";

export const DEFAULT_VALUE = {
  startDate: undefined,
  endDate: undefined,
};

export const SIZE = {
  small: "small",
};

export const STATIC_RANGES = {
  mtd: "mtd",
  qtd: "qtd",
  ytd: "ytd",
};
