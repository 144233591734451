import { usePatientVitalsQuery } from "../../../../api/patientVitals/usePatientVitalsQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { getPathParams } from "../PatientVitals.utils";

export const useVitals = () => {
  const { patientId } = getPathParams();
  const { tClients } = useAppTranslation.Clients();

  return usePatientVitalsQuery({
    payload: {
      patientId,
    },
    options: {
      enabled: Boolean(patientId),
      onError: () => {
        uiNotification.error(tClients("vitals.error.getAll"));
      },
    },
  });
};
