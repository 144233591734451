import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";
import { useInvoice } from "./invoice/useInvoice";

export const useKeepMergerDraft = () => {
  const { invoice } = useInvoice();
  return useQuery(
    ["keepMergerDraft"],
    () => {
      http.post(HTTP_ENDPOINTS.keepMergerDraft(), {
        merger_invoice_id: invoice?.id,
      });
    },
    {
      enabled: Boolean(invoice?.id) && invoice?.isMerger,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
  );
};
