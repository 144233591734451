import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../../../../shared/Button/Button";
import { svg } from "../../../../../../../../../assets/svg";
import { CircularProgress } from "../../../../../../../../../shared/CircularProgress/CircularProgress";
import { ReportHeader } from "../../../../../../../components/ReportHeader/ReportHeader";

export function Header({
  total,
  downloadExcel,
  isReportsLoading,
  isDownloadExcelDisabled,
}) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();

  return (
    <ReportHeader
      withBackBtn
      label={tBi("liabilityReports.expiring.label")}
      left={total}
    >
      <Button
        size="small"
        variant="outlined"
        onClick={downloadExcel.initiate}
        isDisabled={
          downloadExcel.isLoading || isReportsLoading || isDownloadExcelDisabled
        }
        leftAdornment={
          downloadExcel.isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <img src={svg.downloadPrimary} alt="dl" width="20px" />
          )
        }
      >
        {tCommon("label.downloadExcel")}
      </Button>
    </ReportHeader>
  );
}

Header.propTypes = {
  downloadExcel: PropTypes.shape({
    initiate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
  isDownloadExcelDisabled: PropTypes.bool.isRequired,
};
