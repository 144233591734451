import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { usePaymentMethods } from "./hooks/usePaymentMethods";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import Header from "../../../components/Header";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { Box } from "../../../../../shared/Box/Box";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "paymentMethodsReport.info.title",
  rows: ["paymentMethodsReport.info.note"],
};

const PaymentMethods = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const { data, isLoading } = usePaymentMethods(requestParams);

  const isReportDataEmpty = !isLoading && !data?.reportData?.length;

  const reportData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        netCollected: data.net_collected,
        paymentMethod: data.payment_method,
        processingFees: data.processing_fees,
        totalCollected: data.total_collected,
        totalRefunds: data.total_refunds,
        transactions: data.transactions,
      })),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const totalDatas = [
    ...(reportData || []),
    !isReportDataEmpty &&
      !isLoading && {
        netCollected: <span className="bold">{data.total.net_collected}</span>,
        paymentMethod: (
          <span className="bold">{data.total.payment_method}</span>
        ),
        processingFees: (
          <span className="bold">{data.total.processing_fees}</span>
        ),
        totalCollected: (
          <span className="bold">{data.total.total_collected}</span>
        ),
        totalRefunds: <span className="bold">{data.total.total_refunds}</span>,
        transactions: <span className="bold">{data.total.transactions}</span>,
      },
  ].filter(Boolean);

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Payment Methods"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getPaymentMethods()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex-col gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Full Payments", accessor: "paymentMethod" },
              { data: "Transactions", accessor: "transactions" },
              { data: "Total Collected", accessor: "totalCollected" },
              { data: "Refunds", accessor: "totalRefunds" },
              { data: "Processing Fees", accessor: "processingFees" },
              { data: "Net Collected", accessor: "netCollected" },
            ]}
            data={totalDatas}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isReportDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
