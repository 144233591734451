import React from "react";
import classes from "./Option.module.scss";

export function Option({ list }) {
  return (
    <div className={classes.root}>
      {list.map((i, index) => (
        <div key={`${i.value}${index}`}>
          {!!i.label && <i>{i.label}:</i>}
          {i.value}
        </div>
      ))}
    </div>
  );
}
