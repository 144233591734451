const nameInitialState = {
  action: "",
  bookingImage: { status: "pending", data: null },
};

const inventory = (state = nameInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
      };
    }
    case "POS_BUTTON_LIST": {
      return { ...state, data: action.payload, action: "POS_BUTTON_LIST" };
    }
    case "FETCH_POS_BUTTON_ID": {
      return { ...state, data: action.payload, action: "FETCH_POS_BUTTON_ID" };
    }
    case "CREATE_POS_TABLE_BUTTON": {
      return {
        ...state,
        data: action.payload,
        action: "CREATE_POS_TABLE_BUTTON",
      };
    }
    case "CREATE_POS_BUTTON": {
      return { ...state, data: action.payload, action: "CREATE_POS_BUTTON" };
    }
    case "FETCH_PRODUCT_PACKAGE": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_PRODUCT_PACKAGE",
      };
    }
    case "DELETE_POS_BUTTON_TABLE": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_POS_BUTTON_TABLE",
      };
    }
    case "CHECK_BUTTON_NAME": {
      return { ...state, data: action.payload, action: "CHECK_BUTTON_NAME" };
    }
    case "UPDATE_POS_BUTTON": {
      return { ...state, data: action.payload, action: "UPDATE_POS_BUTTON" };
    }
    case "DELETE_POS_BUTTON_ID": {
      return { ...state, data: action.payload, action: "DELETE_POS_BUTTON_ID" };
    }
    case "DELETE_POS_BUTTON": {
      return { ...state, data: action.payload, action: "DELETE_POS_BUTTON" };
    }
    case "POS_ACTIVATE_DEACTIVATE": {
      return {
        ...state,
        data: action.payload,
        action: "POS_ACTIVATE_DEACTIVATE",
      };
    }
    case "PRODUCT_LIST": {
      return { ...state, data: action.payload, action: "PRODUCT_LIST" };
    }
    case "DELETE_DISCOUNT_COUPONS_ID": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_DISCOUNT_COUPONS_ID",
      };
    }
    case "DISCOUNT_COUPONS_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "DISCOUNT_COUPONS_LIST",
      };
    }
    case "UPDATE_DISCOUNT_COUPONS_ID": {
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_DISCOUNT_COUPONS_ID",
      };
    }
    case "CREATE_DISCOUNT_COUPONS_ID": {
      return {
        ...state,
        data: action.payload,
        action: "CREATE_DISCOUNT_COUPONS_ID",
      };
    }
    case "FETCH_DISCOUNT_COUPONS_ID": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_DISCOUNT_COUPONS_ID",
      };
    }
    case "INVENTORY_PRODUCT_LIST_ACTIVE": {
      return {
        ...state,
        data: action.payload,
        action: "INVENTORY_PRODUCT_LIST_ACTIVE",
      };
    }
    case "INVENTORY_PRODUCT_LIST_INACTIVE": {
      return {
        ...state,
        data: action.payload,
        action: "INVENTORY_PRODUCT_LIST_INACTIVE",
      };
    }
    case "INVENTORY_PRODUCT_LIST_CATEGORY": {
      return {
        ...state,
        data: action.payload,
        action: "INVENTORY_PRODUCT_LIST_CATEGORY",
      };
    }
    case "CATEGORY_LIST": {
      return { ...state, data: action.payload, action: "CATEGORY_LIST" };
    }

    case "PACKAGES_LIST": {
      return { ...state, data: action.payload, action: "PACKAGES_LIST" };
    }

    case "DISCOUNT_GROUP_LIST": {
      return { ...state, data: action.payload, action: "DISCOUNT_GROUP_LIST" };
    }

    case "EGIFT_CARD_LIST": {
      return { ...state, data: action.payload, action: "EGIFT_CARD_LIST" };
    }

    case "UPDATE_EGIFTCARD": {
      return { ...state, data: action.payload, action: "UPDATE_EGIFTCARD" };
    }

    case "CREATE_EGIFTCARD": {
      return { ...state, data: action.payload, action: "CREATE_EGIFTCARD" };
    }

    case "DELETE_EGIFTCARD": {
      return { ...state, data: action.payload, action: "DELETE_EGIFTCARD" };
    }

    case "FETCH_SELECTED_EGIFTCARD": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_SELECTED_EGIFTCARD",
      };
    }
    case "CREATE_DISCOUNT_GROUP": {
      return {
        ...state,
        data: action.payload,
        action: "CREATE_DISCOUNT_GROUP",
      };
    }
    case "FETCH_SELECTED_DISCOUNT_GROUP": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_SELECTED_DISCOUNT_GROUP",
      };
    }
    case "UPDATE_DISCOUNT_GROUP": {
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_DISCOUNT_GROUP",
      };
    }
    case "DELETE_DISCOUNT_GROUP": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_DISCOUNT_GROUP",
      };
    }
    case "ADD_DISCOUNT_GROUP_PRODUCT": {
      return {
        ...state,
        data: action.payload,
        action: "ADD_DISCOUNT_GROUP_PRODUCT",
      };
    }
    case "DELETE_DISCOUNT_GROUP_PRODUCT": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_DISCOUNT_GROUP_PRODUCT",
      };
    }

    case "CREATE_CATEGORY": {
      return { ...state, data: action.payload, action: "CREATE_CATEGORY" };
    }

    case "FETCH_CLINICS": {
      return { ...state, data: action.payload, action: "FETCH_CLINICS" };
    }

    case "FETCH_SELECTED_CATEGORY": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_SELECTED_CATEGORY",
      };
    }

    case "UPDATE_CATEGORIES": {
      return { ...state, data: action.payload, action: "UPDATE_CATEGORIES" };
    }

    case "DELETE_CATEGORIES": {
      return { ...state, data: action.payload, action: "DELETE_CATEGORIES" };
    }
    case "DEACTIVATE_ALL_CATEGORIES": {
      return {
        ...state,
        data: action.payload,
        action: "DEACTIVATE_ALL_CATEGORIES",
      };
    }
    case "PRODUCT_DEFAULT_DATA": {
      return { ...state, data: action.payload, action: "PRODUCT_DEFAULT_DATA" };
    }
    case "IS_PRODUCT_AVAILABLE": {
      return { ...state, data: action.payload, action: "IS_PRODUCT_AVAILABLE" };
    }
    case "PRODUCT_ADDED": {
      return { ...state, data: action.payload, action: "PRODUCT_ADDED" };
    }
    case "PRODUCT_DELETED": {
      return { ...state, data: action.payload, action: "PRODUCT_DELETED" };
    }
    case "RESET_ACTION": {
      return { ...state, action: "" };
    }
    case "EXPORT_DATA": {
      return { ...state, data: action.payload, action: "EXPORT_DATA" };
    }
    case "EXPORT_PRODUCT_DATA": {
      return { ...state, data: action.payload, action: "EXPORT_PRODUCT_DATA" };
    }
    case "DEFAULT_PACKAGE_DATA": {
      return { ...state, data: action.payload, action: "DEFAULT_PACKAGE_DATA" };
    }
    case "BOGO_PRODUCT_LIST": {
      return { ...state, data: action.payload, action: "BOGO_PRODUCT_LIST" };
    }
    case "BOGO_FREE_PRODUCT_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "BOGO_FREE_PRODUCT_LIST",
      };
    }
    case "PACKAGE_PRODUCT_LIST": {
      return { ...state, data: action.payload, action: "PACKAGE_PRODUCT_LIST" };
    }
    case "SAVE_PACKAGE_DATA": {
      return { ...state, data: action.payload, action: "SAVE_PACKAGE_DATA" };
    }
    case "EMPTY_INVENTROY": {
      return { ...state, data: {}, action: "EMPTY_INVENTROY" };
    }
    case "DELETE_DISCOUNT_PACKAGE": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_DISCOUNT_PACKAGE",
      };
    }
    case "ACTIVATE_DISCOUNT_PACKAGE": {
      return {
        ...state,
        data: action.payload,
        action: "ACTIVATE_DISCOUNT_PACKAGE",
      };
    }
    case "ACTIVATE_PRODUCT": {
      return { ...state, data: action.payload, action: "ACTIVATE_PRODUCT" };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    case "PRODUCT_UPDATE": {
      return { ...state, data: action.payload, action: "PRODUCT_ADDED" };
    }
    case "GET_RECONCILIATION_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "GET_RECONCILIATION_DATA",
      };
    }
    case "FETCH_CATEGORIES": {
      return { ...state, data: action.payload, action: "FETCH_CATEGORIES" };
    }
    case "SAVE_RECONCILED_DATA": {
      return { ...state, data: action.payload, action: "SAVE_RECONCILED_DATA" };
    }
    case "GET_PRODUCT_DETAILS": {
      return { ...state, data: action.payload, action: "GET_PRODUCT_DETAILS" };
    }
    case "GET_RECONCILED_REPORT": {
      return {
        ...state,
        data: action.payload,
        action: "GET_RECONCILED_REPORT",
      };
    }
    case "ADJUST_PRODUCT_INVENTORY": {
      return {
        ...state,
        data: action.payload,
        action: "ADJUST_PRODUCT_INVENTORY",
      };
    }
    case "ADD_PRODUCT_INVENTORY": {
      return {
        ...state,
        data: action.payload,
        action: "ADD_PRODUCT_INVENTORY",
      };
    }
    case "DOWNLOAD_RECONCILED_REPORT": {
      return {
        ...state,
        data: action.payload,
        action: "DOWNLOAD_RECONCILED_REPORT",
      };
    }
    case "CHECK_IF_RECONCILED_ALREADY": {
      return {
        ...state,
        data: action.payload,
        action: "CHECK_IF_RECONCILED_ALREADY",
      };
    }
    case "GET_PRODUCT_LOGS": {
      return { ...state, data: action.payload, action: "GET_PRODUCT_LOGS" };
    }
    case "MAKE_PRODUCT_INACTIVATE": {
      return {
        ...state,
        data: action.payload,
        action: "MAKE_PRODUCT_INACTIVATE",
      };
    }

    case "IN_EXPORT_FILE": {
      return { ...state, data: action.payload, action: "IN_EXPORT_FILE" };
    }
    case "GET_SUPPLIER_LIST": {
      return { ...state, data: action.payload, action: "GET_SUPPLIER_LIST" };
    }
    case "SUPPLIER_DETAILS": {
      return { ...state, data: action.payload, action: "SUPPLIER_DETAILS" };
    }
    case "ADD_EDIT_SUPPLIER": {
      return { ...state, data: action.payload, action: "ADD_EDIT_SUPPLIER" };
    }
    case "DELETE_SUPPLIER": {
      return { ...state, data: action.payload, action: "DELETE_SUPPLIER" };
    }
    case "SUPPLIER_BULK_UPLOAD": {
      return { ...state, data: action.payload, action: "SUPPLIER_BULK_UPLOAD" };
    }
    case "ORDER_RECEVING_LIST": {
      return { ...state, data: action.payload, action: "ORDER_RECEVING_LIST" };
    }
    case "PURCHASE_ORDERS_LIST": {
      return { ...state, data: action.payload, action: "PURCHASE_ORDERS_LIST" };
    }
    case "GET_ORDER_DETAILS": {
      return { ...state, data: action.payload, action: "GET_ORDER_DETAILS" };
    }
    case "SAVE_PURCHASE_ORDER": {
      return { ...state, data: action.payload, action: "SAVE_PURCHASE_ORDER" };
    }
    case "RECEIVE_PRODUCT": {
      return { ...state, data: action.payload, action: "RECEIVE_PRODUCT" };
    }
    case "SAVE_REORDER_SETTING": {
      return { ...state, data: action.payload, action: "SAVE_REORDER_SETTING" };
    }
    case "DELETE_PURCHASE_ORDER": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_PURCHASE_ORDER",
      };
    }
    case "DOWNLOAD_PURCHASE_ORDER": {
      return {
        ...state,
        data: action.payload,
        action: "DOWNLOAD_PURCHASE_ORDER",
      };
    }
    case "AUTO_PRODUCT_SEARCH_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "AUTO_PRODUCT_SEARCH_LIST",
      };
    }
    case "EDIT_ORDER_ITEM": {
      return { ...state, data: action.payload, action: "EDIT_ORDER_ITEM" };
    }
    // Stock Transfer - START
    case "GET_STOCK_TRANSFERS": {
      return { ...state, data: action.payload, action: "GET_STOCK_TRANSFERS" };
    }
    case "CREATE_STOCK_TRANSFER": {
      return {
        ...state,
        data: action.payload,
        action: "CREATE_STOCK_TRANSFER",
      };
    }
    case "VIEW_STOCK_TRANSFER": {
      return { ...state, data: action.payload, action: "VIEW_STOCK_TRANSFER" };
    }
    case "ACCEPT_STOCK_TRANSFER": {
      return {
        ...state,
        data: action.payload,
        action: "ACCEPT_STOCK_TRANSFER",
      };
    }
    case "GET_PRODUCT_STOCK_DETAIL": {
      return {
        ...state,
        data: action.payload,
        action: "GET_PRODUCT_STOCK_DETAIL",
      };
    }
    case "GET_SUPPLIER_CLINICS": {
      return { ...state, data: action.payload, action: "GET_SUPPLIER_CLINICS" };
    }
    case "SEARCH_CHARTING_PRODUCT": {
      return {
        ...state,
        data: action.payload,
        action: "SEARCH_CHARTING_PRODUCT",
      };
    }
    case "GET_CHARTING_PACKAGES": {
      return {
        ...state,
        data: action.payload,
        action: "GET_CHARTING_PACKAGES",
      };
    }
    case "GET_CHARTING_CLINICS": {
      return { ...state, data: action.payload, action: "GET_CHARTING_CLINICS" };
    }
    case "CREATE_CHARTING_PACKAGES": {
      return {
        ...state,
        data: action.payload,
        action: "CREATE_CHARTING_PACKAGES",
      };
    }
    case "GET_PACKAGE_DETAILS": {
      return { ...state, data: action.payload, action: "GET_PACKAGE_DETAILS" };
    }
    case "UPDATE_CHARTING_PACKAGES": {
      return {
        ...state,
        data: action.payload,
        action: "UPDATE_CHARTING_PACKAGES",
      };
    }
    case "PACKAGE_ACTIVE_INACTIVE": {
      return {
        ...state,
        data: action.payload,
        action: "PACKAGE_ACTIVE_INACTIVE",
      };
    }
    case "DELETE_PACKAGE_PRODUCT": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_PACKAGE_PRODUCT",
      };
    }
    case "EMAIL_PURCHASE_ORDER": {
      return { ...state, data: action.payload, action: "EMAIL_PURCHASE_ORDER" };
    }
    case "UPDATE_STOCK_ALERT": {
      return { ...state, data: action.payload, action: "UPDATE_STOCK_ALERT" };
    }
    case "UPDATE_CTC": {
      return { ...state, data: action.payload, action: "UPDATE_CTC" };
    }

    case "SEARCH_PO_PRODUCT": {
      return { ...state, data: action.payload, action: "SEARCH_PO_PRODUCT" };
    }
    case "CHARTING_PRODUCT_SEARCH": {
      return {
        ...state,
        data: action.payload,
        action: "CHARTING_PRODUCT_SEARCH",
      };
    }
    case "CHARTING_CATEGORY_SEARCH": {
      return {
        ...state,
        data: action.payload,
        action: "CHARTING_CATEGORY_SEARCH",
      };
    }
    case "CHARTING_CATEGORY_PRODUCT_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "CHARTING_CATEGORY_PRODUCT_LIST",
      };
    }
    case "CREATE_COLLECTIVE_PACKAGE": {
      return {
        ...state,
        data: action.payload,
        action: "CREATE_COLLECTIVE_PACKAGE",
      };
    }
    case "DELETE_BUCKET_PRODUCT": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_BUCKET_PRODUCT",
      };
    }
    case "DELETE_BUCKET": {
      return { ...state, data: action.payload, action: "DELETE_BUCKET" };
    }
    case "GET_MEDICAL_AND_RETAIL_PRODUCTS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_MEDICAL_AND_RETAIL_PRODUCTS",
      };
    }
    case "CHARTING_SINGLE_CATEGORY_PRODUCT_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "CHARTING_SINGLE_CATEGORY_PRODUCT_LIST",
      };
    }
    case "GET_DETAILED_PRODUCT_LOGS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_DETAILED_PRODUCT_LOGS",
      };
    }
    case "SAVE_BOOKING_IMAGE_LOADING": {
      return { ...state, bookingImage: { status: "loading", data: null } };
    }
    case "SAVE_BOOKING_IMAGE_SUCCESS": {
      return {
        ...state,
        bookingImage: { status: "success", data: action.payload },
      };
    }
    case "SAVE_BOOKING_IMAGE_ERROR": {
      return {
        ...state,
        bookingImage: { status: "error", data: action.payload },
      };
    }
    default:
      return { ...nameInitialState };
  }
};

export default inventory;
