import { useState } from "react";
import { isEqual } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../../consts/api";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { dispatch } from "../../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";
import { useCloseSmartReader } from "./useCloseSmartReader";

export const useCardReaderCharge = (selectedReader) => {
  const { invoice, refetchInvoice } = useInvoice();
  const { tSales } = useAppTranslation.Sales();
  const [isPolling, setIsPolling] = useState(false);

  const { mutate: closeSmartReader } = useCloseSmartReader();

  const req = useMutation(
    (dto) => http.post(HTTP_ENDPOINTS.postCheckoutPayment(), dto),
    {
      onSuccess: () => {
        const initialTransactions = invoice?.paymentTransaction;
        const initialTransactionsAmount = initialTransactions?.totalAmount || 0;
        const initialSignature = invoice?.paymentSignature;

        uiNotification.success(
          tSales("checkoutInvoice.success.transferPaymentToReader"),
        );

        let unchangedIterations = 0;
        const maxUnchangedIterations = 60;

        const pollInvoiceDetails = async () => {
          try {
            const response = await refetchInvoice();
            const currentTransactions = response.data.paymentTransaction;
            const currentTransactionsAmount =
              currentTransactions?.totalAmount || 0;
            const currentSignature = response.data.paymentSignature;

            if (
              !isEqual(
                initialTransactions?.payments,
                currentTransactions?.payments,
              ) &&
              currentTransactionsAmount > initialTransactionsAmount
            ) {
              if (selectedReader.collectSignature) {
                unchangedIterations++;
                if (
                  (response.data.paymentSignature &&
                    initialSignature !== currentSignature) ||
                  unchangedIterations >= maxUnchangedIterations
                ) {
                  clearPolling();
                  return;
                }
              } else {
                clearPolling();
                return;
              }
            } else {
              unchangedIterations++;
              if (
                response.data.status !== "pending" ||
                unchangedIterations >= maxUnchangedIterations
              ) {
                closeSmartReader(selectedReader.readerIdentifier);
                clearPolling(tSales("checkoutInvoice.failed.paymentFailed"));
                return;
              }
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        };

        const pollingInterval = setInterval(pollInvoiceDetails, 3000);

        const clearPolling = (errorMessage = null) => {
          clearInterval(pollingInterval);
          setIsPolling(false);
          if (errorMessage) uiNotification.error(errorMessage);
          dispatch(checkoutInvoice.actions.paymentViewClose());
        };

        setIsPolling(true);
      },
      onError: (error) => {
        uiNotification.error(error.response?.data?.message);
      },
    },
  );

  return {
    chargePayment: req.mutate,
    isLoading: req.isLoading,
    isPolling,
  };
};
