import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getEvolusConfig() {
  return (dispatch) => {
    dispatch({ type: "FETCH_EVOLUS_CONFIG_LOADING", payload: {} });
    http
      .get("evolus/setup")
      .then((response) => {
        dispatch({
          type: "FETCH_EVOLUS_CONFIG_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_EVOLUS_CONFIG_ERROR",
          payload: error.response.message,
        });
      });
  };
}

export function getEvolusFacilities(data) {
  return (dispatch) => {
    dispatch({ type: "FETCH_EVOLUS_FACILITIES_LOADING", payload: {} });
    http
      .post("evolus/setup/facilities", data)
      .then((response) => {
        if (response.data.evolus.errors) {
          dispatch({
            type: "FETCH_EVOLUS_FACILITIES_ERROR",
            payload: response.data.evolus.errors,
          });
        } else {
          dispatch({
            type: "FETCH_EVOLUS_FACILITIES_SUCCESS",
            payload: { evolusFacilities: response.data },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_EVOLUS_FACILITIES_ERROR",
          payload: error.response.data.message,
        });
      });
  };
}

export function saveEvolusConfig(data) {
  return (dispatch) => {
    dispatch({ type: "SAVE_EVOLUS_CONFIG_LOADING", payload: {} });
    http
      .post("evolus/setup", data)
      .then((response) => {
        dispatch({ type: "SAVE_EVOLUS_CONFIG_SUCCESS" });
        dispatch({
          type: "FETCH_EVOLUS_CONFIG_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_EVOLUS_CONFIG_ERROR",
          payload: error.response.data.message,
        });
      });
  };
}
