import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { DateRangePicker } from "react-date-range";
import Highcharts from "highcharts/highstock";
import Basicbar from "highcharts-react-official";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchProductSalesData } from "../../../Actions/Dashboard/dashboardActions.js";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import calenLogo from "../../../_legacy/images/calender.svg";
import {
  showFormattedDate,
  getCurrencySymbol,
} from "../../../Utils/services.js";
import { WidgetLoader } from "../components/WidgetLoader.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const options = (productNames, totalPrice) => {
  return {
    chart: {
      type: "bar",
      height: 335,
      margin: [50, 50, 50, 100],
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: productNames,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      valuePrefix: getCurrencySymbol(),
    },
    plotOptions: {
      bar: {
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: getCurrencySymbol() + "{point.y:.1f}",
        },
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: 150,
      y: 150,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: totalPrice,
        color: "#047ec3",
      },
    ],
  };
};

class WidgetProductSales extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    const psDateRange = JSON.parse(localStorage.getItem("psDateRange"));
    const valR = psDateRange ? psDateRange : "";
    const startDate = valR.startDate;
    const endDate = valR.endDate;
    let date = new Date();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    date =
      date.getFullYear() +
      "-" +
      month +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    this.state = {
      globalLang: languageData.global,
      productData: [],
      productList: [],
      productNames: [],
      productPrices: [],
      id: userData.user.id,
      showLoadingText: false,
      pageNumber: [],
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      lastPage: 1,
      showLoader3: false,
      page: 1,
      pagesize: 6,
      dateRangePicker: {
        selection: {
          startDate: startDate ? startDate : date,
          endDate: endDate ? endDate : date,
          key: "selection",
        },
      },
      to_date: date,
      from_date: date,
      showCalendar: false,
      term: "",
      clicked: 0,
      perPage: 6,
      totalNum: 1,
      activePage: 1,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const dateData = JSON.parse(localStorage.getItem("psDateRange"));
    document.addEventListener("click", this.handleClick, false);
    let formData = {
      params: {
        term: this.state.term,
        page: this.state.page,
        pagesize: this.state.pagesize,
        from_date: dateData ? dateData.startDate : this.state.from_date,
        to_date: dateData ? dateData.endDate : this.state.to_date,
      },
    };
    this.setState({
      showLoader3: true,
      to_date: dateData ? dateData.endDate : this.state.to_date,
      from_date: dateData ? dateData.startDate : this.state.from_date,
    });

    this.props.fetchProductSalesData(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.productData != undefined &&
      nextProps.productData.data != prevState.productData.data &&
      nextProps.productData.status == 200
    ) {
      returnState.productData = nextProps.productData.data;
      let productItems = [];
      let x = nextProps.productData.data;

      x.forEach((obj) => {
        if (
          obj.gross_sales !== undefined &&
          obj.gross_sales !== 0 &&
          obj.gross_sales !== 0.0
        ) {
          productItems.push(obj);
        }
      });

      returnState.productList = productItems;
      returnState.showLoader3 = false;
    }

    return returnState;
  }

  handleInputChange = () => {
    let returnState = {};
    returnState.dataChanged = true;
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.nodeSalesByProduct) {
      if (
        this.nodeSalesByProduct.contains(e.target) &&
        this.state.showCalendar === true
      ) {
        return;
      } else if (
        this.nodeSalesByProduct.contains(e.target) &&
        this.state.showCalendar === false
      ) {
        this.setState({ showCalendar: true });
      } else if (
        !this.nodeSalesByProduct.contains(e.target) &&
        this.state.showCalendar === true
      ) {
        this.setState({ showCalendar: false });
      }
    }
  };

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendarSalesByProduct = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendarSalesByProduct = true;
    } else {
      showCalendarSalesByProduct = false;
    }

    this.setState({ showCalendar: showCalendarSalesByProduct });
  };

  handleSubmit = (event, value) => {
    if (typeof event === "object") {
      event.preventDefault();
    }
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
        from_date:
          value != undefined && value.from_date != undefined
            ? value.from_date
            : this.state.from_date,
        to_date:
          value != undefined && value.to_date != undefined
            ? value.to_date
            : this.state.to_date,
      },
    };
    this.setState({
      activePage: 1,
      startFresh: true,
      loadMore: true,
      productData: [],
      showLoader3: true,
      innerbox_select_all: false,
    });

    this.props.fetchProductSalesData(formData);
  };

  handleRangeChange = (which, payload) => {
    let save_start_date = "";
    let save_end_date = "";
    if (payload) {
      let date_1 = new Date(payload.selection.startDate);
      let date_2 = new Date(payload.selection.endDate);
      let month_1 = date_1.getMonth() + 1;
      let month_2 = date_2.getMonth() + 1;
      let day_1 = date_1.getDate();
      let day_2 = date_2.getDate();
      if (month_1 < 10) {
        month_1 = "0" + month_1;
      }
      if (month_2 < 10) {
        month_2 = "0" + month_2;
      }
      day_1 = day_1 < 10 ? "0" + day_1 : day_1;
      day_2 = day_2 < 10 ? "0" + day_2 : day_2;
      save_start_date = date_1.getFullYear() + "-" + month_1 + "-" + day_1;
      save_end_date = date_2.getFullYear() + "-" + month_2 + "-" + day_2;
      let payloadValue = {
        startDate: save_start_date,
        endDate: save_end_date,
        key: "selection",
      };
      localStorage.setItem("psDateRange", JSON.stringify(payloadValue));
    }
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    startDate = save_start_date;
    endDate = save_end_date;

    let clicked = this.state.clicked + 1;

    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    let showCalendarSalesByProduct = true;

    if (clicked % 2 === 0) {
      showCalendarSalesByProduct = false;
    }

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      showCalendar: showCalendarSalesByProduct,
      from_date: startDate,
      to_date: endDate,
      clicked: clicked,
    });

    if (clicked && clicked % 2 === 0) {
      this.handleSubmit(which, { from_date: startDate, to_date: endDate });
    }
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  render() {
    let viewData = [];
    let currentPage = this.state.activePage;
    let limit = 6;
    let last = this.state.productList.length;
    let totalNum = Math.ceil(this.state.productList.length);
    let psData = viewData;
    let pNames = [];
    let sale = [];
    let startIndex = (currentPage - 1) * limit;
    let endIndex = limit + startIndex;
    if (endIndex > last) {
      endIndex = last;
    }
    for (let i = startIndex; i < endIndex; i++) {
      viewData.push(this.state.productList[i]);
    }
    psData.forEach((obj) => {
      pNames.push(obj.item);

      sale.push(parseFloat(obj.gross_sales));
    });

    return (
      <div className="setting-setion m-b-15">
        <div className="dashboardSectionTitle">
          <b>Sales by Product</b>
          <div className="memberRightActions">
            <div
              className="search-bg new-calender pull-left"
              ref={(nodeSalesByProduct) => {
                this.nodeSalesByProduct = nodeSalesByProduct;
              }}
            >
              <img src={calenLogo} alt="" />
              {this.state.showCalendar && (
                <DateRangePicker
                  ranges={[
                    {
                      ...this.state.dateRangePicker.selection,
                      startDate: new Date(
                        this.state.dateRangePicker.selection.startDate,
                      ),
                      endDate: new Date(
                        this.state.dateRangePicker.selection.endDate,
                      ),
                    },
                  ]}
                  onChange={this.handleRangeChange.bind(
                    this,
                    "dateRangePicker",
                  )}
                  className={"CalendarPreviewArea"}
                  maxDate={new Date()}
                  dragSelectionEnabled={false}
                  ref={(refSalesByProduct) => {
                    this.refSalesByProduct = refSalesByProduct;
                  }}
                />
              )}
              <input
                type="text"
                className="input-cal setting-search-input p-b-5"
                name="calendar-input"
                value={
                  this.state.from_date
                    ? showFormattedDate(this.state.from_date, false) +
                      `-` +
                      showFormattedDate(this.state.to_date, false)
                    : ""
                }
                autoComplete="off"
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
        <div
          className="dash-box-content egiftCardGraph businessGraphOuter dashBarGraph"
          data-highcharts-chart="0"
        >
          <div
            className={
              this.state.productData.length !== 0
                ? "highcharts-container"
                : "no-display"
            }
            data-highcharts-chart="0"
            style={{
              minWidth: "210px",
              maxWidth: "550px",
              height: "170px",
              margin: "0 auto",
            }}
          >
            <div className="dashboardSectionContent">
              <Basicbar
                highcharts={Highcharts}
                options={options(pNames, sale)}
              />
            </div>
          </div>
          {
            <div
              className={
                this.state.productData.length !== 0
                  ? ""
                  : "dashboardSectionContent"
              }
            >
              <div
                className={
                  this.state.productData.length !== 0
                    ? "no-record no-display"
                    : "no-record"
                }
              >
                {this.state.globalLang.sorry_no_record_found}
              </div>
            </div>
          }
        </div>
        <div className="dashboardSectionFooter">
          <div className="dashboardPagination">
            {this.state.productData.length > 0 && (
              <Pagination
                prevPageText={"Prev"}
                nextPageText={"Next"}
                firstPageText={<i className="fa fa-angle-double-left" />}
                lastPageText={<i className="fa fa-angle-double-right" />}
                activePage={this.state.activePage}
                itemsCountPerPage={6}
                totalItemsCount={totalNum}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            )}
          </div>
        </div>
        {this.state.showLoader3 && <WidgetLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.DashboardReducer.action === "DASHBOARD_PRODUCT_SALES_DATA") {
    if (state.DashboardReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.DashboardReducer.data.message],
      );
    } else {
      returnState.productData = state.DashboardReducer.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProductSalesData: fetchProductSalesData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetProductSales);
