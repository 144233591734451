import { useEffect, useState } from "react";
import { useCreateCherryPaymentMutation } from "../../../../api/cherry/useCreateCherryPaymentMutation";
import { useCherryAvailabilityQuery } from "../../../../api/cherry/useCherryAvailabilityQuery";
import { useCherryPatientProfileQuery } from "../../../../api/cherry/useCherryPatientProfileQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { extractApiError } from "../../../../utilities/api";
import { prepareAmount } from "../SalesCheckoutInvoice.utils";
import { useInvoice } from "./invoice/useInvoice";
import { useCherryPaymentQuery } from "../../../../api/cherry/useCherryPaymentQuery";
import { useCancelCherryPaymentMutation } from "../../../../api/cherry/useCancelCherryPaymentMutation";
import { useInterval } from "../../../../utilities/hooks/useInterval";
import { CHERRY_PAYMENT_AWAIT_REFRESH_RATE } from "../SalesCheckoutInvoice.consts";
import { dispatch } from "../../../../store/store";
import { checkoutInvoice } from "../../../../store/checkoutInvoice";
import { unwrapOr } from "../../../../utilities/general";

export function useCherry({ onPaymentSuccess, onPaymentCancel } = {}) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, refetchInvoice } = useInvoice();

  const cherryAvailabilityQuery = useCherryAvailabilityQuery();
  const [ongoingPaymentId, setOngoingPaymentId] = useState(null);
  const [pendingPayment, setPendingPayment] = useState(null);

  const cherryProfileQuery = useCherryPatientProfileQuery({
    payload: {
      patientId: invoice?.patient?.id,
      cherryPatientId: invoice?.patient?.cherryPatientId,
    },
    options: {
      enabled: Boolean(
        invoice?.patient?.id && invoice?.patient?.cherryPatientId,
      ),
    },
  });

  const ongoingPaymentQuery = useCherryPaymentQuery({
    payload: {
      invoiceId: invoice?.id,
      paymentId: ongoingPaymentId,
    },
    options: {
      enabled: Boolean(ongoingPaymentId),
      onError: () => {
        uiNotification.error(
          tSales("checkoutInvoice.error.fetchCherryOngoingPayment"),
        );
      },
    },
  });

  const watchInterval = useInterval({
    actionFn: ongoingPaymentQuery.refetch,
    intervalMs: CHERRY_PAYMENT_AWAIT_REFRESH_RATE,
    onStart: () => {
      dispatch(checkoutInvoice.actions.changeIsCherryPaymentWatchMode(true));
    },
    onStop: () => {
      dispatch(checkoutInvoice.actions.changeIsCherryPaymentWatchMode(false));
    },
  });

  const createPaymentMutation = useCreateCherryPaymentMutation({
    onMutate: () => {
      setPendingPayment(null);
      setOngoingPaymentId(null);
    },
    onSuccess: (res) => {
      setOngoingPaymentId(res.paymentId);
    },
    onError: (e) => {
      setOngoingPaymentId(null);
      const pendingPayment = unwrapOr(
        () => e.response.data.data.pendingCherryPayment,
        null,
      );
      if (pendingPayment?.id) {
        setPendingPayment({
          id: pendingPayment.id,
          message: unwrapOr(() => e.response.data.message, null),
        });
      } else {
        const apiError = extractApiError(e);
        uiNotification.error(apiError || tCommon("error.transaction"));
      }
    },
  });

  const cancelPaymentMutation = useCancelCherryPaymentMutation({
    onSuccess: () => {
      uiNotification.success(
        tSales("checkoutInvoice.success.cancelCherryPayment"),
      );
      setPendingPayment(null);
    },
    onError: (e) => {
      uiNotification.error(
        extractApiError(e) ||
          tSales("checkoutInvoice.error.cancelCherryPayment"),
      );
    },
  });

  const createPayment = ({ amount }) => {
    const preparedAmount = prepareAmount(amount);
    const amountOfCents = preparedAmount * 100;
    return createPaymentMutation.mutateAsync({
      cherryPatientId: invoice?.patient?.cherryPatientId,
      invoiceId: invoice?.id,
      isPartial: prepareAmount !== invoice?.totalAmount,
      patientId: invoice?.patient?.id,
      amountOfCents,
    });
  };

  const cancelPayment = (paymentId) => {
    if (paymentId) {
      return cancelPaymentMutation.mutateAsync({
        cherryPatientId: invoice?.patient?.cherryPatientId,
        cherryPaymentId: paymentId,
        invoiceId: invoice?.id,
        patientId: invoice?.patient?.id,
      });
    }
  };

  const isAvailable = Boolean(
    cherryAvailabilityQuery.data?.isAvailable &&
      cherryProfileQuery.data?.canPay,
  );

  useEffect(() => {
    if (!watchInterval.isRunning && ongoingPaymentId) {
      ongoingPaymentQuery.remove();
      watchInterval.start();
    }
  }, [ongoingPaymentId, watchInterval.isRunning]);

  useEffect(() => {
    const finish = () => {
      setOngoingPaymentId(null);
      watchInterval.stop();
      refetchInvoice();
      ongoingPaymentQuery.remove();
    };

    if (watchInterval.isRunning && ongoingPaymentQuery.data) {
      if (ongoingPaymentQuery.data.isFulfilled) {
        finish();
        uiNotification.success(tCommon("success.transaction"));
        onPaymentSuccess?.();
      } else if (ongoingPaymentQuery.data.isCancelled) {
        finish();
        uiNotification.warn(
          tSales("checkoutInvoice.warn.cherryPaymentCancelled"),
        );
        onPaymentCancel?.();
      } else if (!ongoingPaymentQuery.data.isProcessing) {
        finish();
        uiNotification.error(tCommon("error.transaction"));
      }
    }
  }, [
    watchInterval.isRunning,
    ongoingPaymentQuery.data?.isFulfilled,
    ongoingPaymentQuery.data?.isCancelled,
  ]);

  return {
    isCherryAvailable: isAvailable,

    isCherryLoading:
      cherryAvailabilityQuery.isFetching || cherryProfileQuery.isFetching,

    cherryPatient: cherryProfileQuery.data,

    isCherryPaymentWatching: watchInterval.isRunning,

    pendingCherryPayment: pendingPayment,

    chargeCherry: {
      isLoading: createPaymentMutation.isLoading,
      initiate: createPayment,
    },

    cancelOngoingCherryPayment: {
      isLoading: cancelPaymentMutation.isLoading,
      initiate: () => cancelPayment(ongoingPaymentId),
    },

    cancelPendingCherryPayment: {
      isLoading: cancelPaymentMutation.isLoading,
      initiate: () => cancelPayment(pendingPayment?.id),
    },

    ignorePendingCherryPayment: () => setPendingPayment(null),
  };
}
