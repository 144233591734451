import React from "react";
import cx from "clsx";
import { Editor } from "@tinymce/tinymce-react";
import { INIT } from "./TextEditor.consts";
import "./TextEditor.scss";
import "./tinymce.css";

/**
 * @param {Object} param0
 * @param {string?} param0.value
 * @param {(value: string) => void} param0.onChange
 * @param {string?} param0.className
 * @param {boolean?} param0.isDisabled
 * @param {RawEditorOptions?} param0.init
 */
export const TextEditor = ({
  value,
  onChange,
  className,
  isDisabled = false,
  init = {},
}) => {
  return (
    <div
      className={cx(
        "TextEditor-shared",
        {
          "TextEditor-shared__disabled": isDisabled,
        },
        className,
      )}
    >
      <Editor
        value={value}
        onEditorChange={onChange}
        init={{
          ...INIT,
          ...init,
        }}
      />
    </div>
  );
};
