import React from "react";
import PropTypes from "prop-types";
import styles from "../Dictation.module.scss";

export function NoteDisplay({ note }) {
  return (
    <div className={styles.noteContainer}>
      <pre className={styles.noteText}>{note}</pre>
    </div>
  );
}

NoteDisplay.propTypes = {
  note: PropTypes.string.isRequired,
};
