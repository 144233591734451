import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import picClose from "../../../../_legacy/images/close.png";
import {
  isFormSubmit,
  positionFooterCorrectly,
  capitalizeFirstLetter,
  isInt,
  isNumber,
} from "../../../../Utils/services.js";
import {
  saveTemplate,
  fetchTemplate,
  updateTemplate,
  deleteTreatmentTemplate,
  saveTemplatePayg,
  exportEmptyData,
} from "../../../../Actions/Settings/settingsActions.js";
import { searchProduct } from "../../../../Actions/Clients/clientsAction.js";
import Loader from "../../../../Components/Common/Loader.js";
import { getPrecision } from "../../../../utilities/general.js";
import { Maybe } from "../../../../utilities/fp.js";
import { uiNotification } from "../../../../services/UINotificationService.js";
import { isDerivedFromIncrement } from "../../../../helpers/general.js";

const initAddProduct = () => ({
  product_name: "",
  units: "",
  month: "",
  product_id: "",
  minUnits: "",
  countBy: "",
  product_nameClass: "newInputField",
  unitsClass: "newInputField",
  monthClass: "newInputField",
  product_idClass: "newInputField",
});

const initMonthlyAddProduct = () => {
  return [initAddProduct()];
};

const initPaygAddProduct = () => ({
  minUnits: "",
  countBy: "",
  product_name: "",
  product_id: "",
  units: "",
  rep_val: "",
  rep_by: "days",
  productError: false,
  productClass: "newInputField",
});

const initPaygAddClass = () => {
  return {
    product_name: "newInputField",
    product_id: "newInputField",
    units: "newInputField",
    rep_val: "newInputField p-r-5",
    rep_by: "newSelectField",
  };
};

const isNotEmpty = (value) => {
  return typeof value != undefined && value != null && value != "";
};

class CreateEditTreatmentPlanTemp extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      templateData: {},
      returnTo: "/settings/treatment-plan-templates",
      nameClass: "newInputField",
      productClass: "newInputField",
      durationClass: "newSelectField",
      planType: "monthly",
      showFeilds: false,
      addMonthlyProduct: [initMonthlyAddProduct()],
      addPaygProduct: [initPaygAddProduct()],
      addPaygProductClass: [initPaygAddClass()],
      templateName: "",
      planDuration: 3,
      skinCareGoal: "",
      planDurationList: [],
      showMonthsFeilds: [
        initMonthlyAddProduct(),
        initMonthlyAddProduct(),
        initMonthlyAddProduct(),
      ],
      product_name: "",
      units: "",
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      selectedItem: {},
      templateID: this.props.match.params.id ? this.props.match.params.id : 0,
      getTemplateData: {},
      treatmentPlanData: {},
      showModal: false,
      monthlyData: [],
      paygData: [],
      paygDuration: 3,
      paygName: "",
      selectedIds: [],
      autoSuggestionProducts: [],
      showAutoSuggestion: false,
      productList: [],
      showSearchResult: false,
      productData: [],
      seectedProIndex: "",
      selProMonthIndex: "",
      showLoader: false,
    };
  }

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.setState({
      yes_option: languageData.settings["yes_option"],
      no_option: languageData.settings["no_option"],
    });

    if (this.state.templateID && this.state.templateID > 0) {
      this.setState({ showLoader: true });
      this.props.fetchTemplate(this.state.templateID);
    } else {
      this.setState({ planDuration: 3 });
    }

    let i;
    let planDurationList = [];

    for (i = 3; i <= 24; i++) {
      planDurationList.push(i + " " + "months");
    }

    this.setState({ planDurationList: planDurationList });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }

    if (
      nextProps.getTemplateData !== undefined &&
      nextProps.getTemplateData.status === 200 &&
      nextProps.getTemplateData.data !== prevState.getTemplateData &&
      nextProps.getTemplateDataTimestamp != prevState.getTemplateDataTimestamp
    ) {
      returnState.getTemplateDataTimestamp = nextProps.getTemplateDataTimestamp;
      returnState.getTemplateData = nextProps.getTemplateData.data;
      returnState.treatmentPlanData =
        nextProps.getTemplateData.data.treatment_plan_template;
      returnState.templateName =
        nextProps.getTemplateData.data.treatment_plan_template.plan_name;
      returnState.planType =
        nextProps.getTemplateData.data.treatment_plan_template.plan_type;

      let plantype =
        nextProps.getTemplateData.data.treatment_plan_template.plan_type;

      returnState.skinCareGoal =
        nextProps.getTemplateData.data.treatment_plan_template.skincare_goal;

      let x = nextProps.getTemplateData.data.treatment_plan_template.duration;

      returnState.planDuration =
        nextProps.getTemplateData.data.treatment_plan_template.duration;
      returnState.monthlyData =
        nextProps.getTemplateData.data.template_schedule_monthly;
      returnState.paygData =
        nextProps.getTemplateData.data.template_schedule_pay_as_u_go;

      if (
        nextProps.getTemplateData.data.template_schedule_monthly &&
        nextProps.getTemplateData.data.template_schedule_monthly.length > 0
      ) {
        let showMonthsFeilds = [];
        nextProps.getTemplateData.data.template_schedule_monthly.map((obj) => {
          const monthIndex =
            obj.belong_to_month > 0 ? obj.belong_to_month - 1 : 0;

          const addProduct = initAddProduct();

          addProduct.product_name =
            obj.product && obj.product.product_name
              ? obj.product.product_name
              : "";

          addProduct.product_id = obj.product_id;
          addProduct.units = obj.units ? obj.units : 0;
          addProduct.product_nameClass = "newInputField";
          addProduct.countBy = obj.product?.count_units_by || "";
          addProduct.minUnits = obj.product?.start_at_unit || "";
          addProduct.unitsClass = "newInputField";

          if (showMonthsFeilds[monthIndex]) {
            let addMonthlyProduct = showMonthsFeilds[monthIndex];
            addMonthlyProduct.push(addProduct);
            showMonthsFeilds[monthIndex] = addMonthlyProduct;
          } else {
            showMonthsFeilds[monthIndex] = [addProduct];
          }
        });

        if (
          nextProps.getTemplateData.data.template_schedule_monthly.length - 1 <
          nextProps.getTemplateData.data.treatment_plan_template.duration
        ) {
          for (let i = 0; i < returnState.planDuration; i++) {
            if (typeof showMonthsFeilds[i] === "undefined") {
              showMonthsFeilds[i] = initMonthlyAddProduct();
            }
          }
        }

        returnState.showMonthsFeilds = showMonthsFeilds;
        returnState.showLoader = false;
      } else {
        returnState.showMonthsFeilds = [
          initMonthlyAddProduct(),
          initMonthlyAddProduct(),
          initMonthlyAddProduct(),
        ];
        returnState.showLoader = false;
      }

      if (
        nextProps.getTemplateData.data.template_schedule_pay_as_u_go.length > 0
      ) {
        returnState.paygName =
          nextProps.getTemplateData.data.treatment_plan_template.plan_name;
        returnState.paygDuration =
          nextProps.getTemplateData.data.treatment_plan_template.duration;
        returnState.skinCareGoal = "";
        returnState.planDuration = 3;
        let addPaygProduct = [];
        let addPaygProductClass = [];

        let paygData =
          nextProps.getTemplateData.data.template_schedule_pay_as_u_go;

        paygData.map((obj) => {
          if (isNotEmpty(obj) && obj.product !== null) {
            let addPayg = initPaygAddProduct();
            let addClass = initPaygAddClass();

            addPayg.units = obj.units;
            addPayg.countBy = obj.product?.count_units_by || "";
            addPayg.minUnits = obj.product?.start_at_unit || "";
            addPayg.rep_val = obj.repeat_value;
            addPayg.rep_by = obj.repeat_by;
            addPayg.product_name = obj.product.product_name
              ? obj.product.product_name
              : "";

            addPayg.product_id = obj.product.id;
            addPaygProduct.push(addPayg);
            addPaygProductClass.push(addClass);
          }

          if (addPaygProduct.length == 0) {
            addPaygProduct = [initPaygAddProduct()];
            addPaygProductClass = [initPaygAddClass()];
          }

          returnState.showLoader = false;
        });
        returnState.addPaygProduct = addPaygProduct;
        returnState.addPaygProductClass = addPaygProductClass;
      }

      let y = [];

      if (x > 1) {
        for (let i = 1; i <= x; i++) {
          y.push(i);
        }
      }

      if (plantype == "payg") {
        returnState.showFeilds = true;
      } else {
        returnState.showFeilds = false;
      }

      returnState.showLoadingText = false;
    }

    if (
      nextProps.productData !== undefined &&
      nextProps.productData.status === 200 &&
      nextProps.productData.data !== prevState.productData &&
      nextProps.productDataTimestamp != prevState.productDataTimestamp
    ) {
      returnState.productData = nextProps.productData.data;
      returnState.productData = nextProps.productData.data;
      returnState.showSearchResult = true;
    }

    if (nextProps.redirect != undefined && nextProps.redirect == true) {
      if (nextProps.redirect == true && nextProps.deleted == true) {
        uiNotification.success("Template deleted successfully.");
        returnState.showLoader = false;
        nextProps.history.push("/settings/treatment-plan-templates");
      } else {
        uiNotification.success(nextProps.message);
        returnState.showLoader = false;
        nextProps.history.push("/settings/treatment-plan-templates");
      }
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let returnState = {};

    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }
    }

    if (target.name == "plan_type") {
      if (value) {
        returnState.planType = value;
      }

      if (value == "payg") {
        returnState.showFeilds = true;
        positionFooterCorrectly();
      }

      if (value == "monthly") {
        returnState.showFeilds = false;
      }
    }

    if (target.name == "planDuration") {
      let showMonthsFeilds = this.state.showMonthsFeilds;
      let val = parseInt(value);

      if (val > this.state.showMonthsFeilds.length) {
        let i;
        for (i = this.state.showMonthsFeilds.length + 1; i <= val; i++) {
          showMonthsFeilds.push(initMonthlyAddProduct());
        }
      }

      if (val < this.state.showMonthsFeilds.length) {
        let i;
        for (i = this.state.showMonthsFeilds.length - 1; i >= val; i--) {
          showMonthsFeilds.pop(initMonthlyAddProduct());
        }
      }

      returnState.showMonthsFeilds = showMonthsFeilds;
      returnState.planDuration = val;
    }

    returnState[event.target.name] = value;
    this.setState(returnState);
  };

  buildOptions = () => {
    var arr = [];

    for (let i = 3; i <= 24; i++) {
      arr.push(
        <option key={i} value={i}>
          {i} months
        </option>,
      );
    }

    return arr;
  };

  buildOptions2 = () => {
    var arr = [];

    for (let i = 3; i <= 24; i++) {
      arr.push(
        <option key={i} value={i}>
          {i} months
        </option>,
      );
    }

    return arr;
  };

  handleProductInput = (event) => {
    const target = event.target;

    let value = target.value;
    let showMonthsFeilds = this.state.showMonthsFeilds;


    const index = event.target.dataset.index;
    const month = event.target.dataset.month;
    const precision = getPrecision(Number(value));
    const productPrecision = Maybe.of(
      showMonthsFeilds?.[month]?.[index]?.countBy,
    )
      .map(Number)
      .map(getPrecision)
      .orElse(() => 3)
      .value();

    if (precision > productPrecision) {
      return;
    }

    let formData = {
      term: value,
      patient_id: this.state.templateID,
    };

    showMonthsFeilds[month][index][event.target.name] = value;

    if (value.length >= 2 && event.target.name == "product_name") {
      this.setState(showMonthsFeilds);
      this.setState({ selectedProIndex: index, selProMonthIndex: month });
      this.props.searchProduct(formData);
    }

    if (value.length <= 2 && event.target.name == "product_name") {
      this.setState({ showSearchResult: false });
      this.setState(showMonthsFeilds);
    }

    this.setState(showMonthsFeilds);
  };

  handlePaygProductInput = (event) => {
    const target = event.target;

    let value = target.value;

    const index = event.target.dataset.index;
    let addPaygProduct = this.state.addPaygProduct;

    const precision = getPrecision(Number(value));
    const productPrecision = Maybe.of(addPaygProduct?.[index]?.countBy)
      .map(Number)
      .map(getPrecision)
      .orElse(() => 3)
      .value();

    if (precision > productPrecision) {
      return;
    }

    let formData = {
      term: value,
      patient_id: this.state.templateID,
    };

    addPaygProduct[index][event.target.name] = value;

    if (value.length >= 2 && event.target.name == "product_name") {
      this.setState(addPaygProduct);
      this.setState({ selProIndex: index });
      this.props.searchProduct(formData);
    }

    if (value.length <= 2 && event.target.name == "product_name") {
      this.setState({ showSearchResult: false });
      this.setState(addPaygProduct);
    }

    this.setState(addPaygProduct);
  };

  addProduct = (monthIdx) => {
    if (this.state.showMonthsFeilds[monthIdx]) {
      const showMonthsFeilds = this.state.showMonthsFeilds;
      const monthProduct = showMonthsFeilds[monthIdx];

      monthProduct.push(initAddProduct());
      showMonthsFeilds[monthIdx] = monthProduct;

      this.setState({ showMonthsFeilds: showMonthsFeilds });
    }
  };

  deleteProduct = (monthIdx, productIdx) => {
    if (this.state.showMonthsFeilds[monthIdx]) {
      const showMonthsFeilds = this.state.showMonthsFeilds;
      const monthProduct = showMonthsFeilds[monthIdx];

      if (monthProduct.length > 1) {
        monthProduct.splice(productIdx, 1);
      }

      showMonthsFeilds[monthIdx] = monthProduct;
      this.setState({ showMonthsFeilds: showMonthsFeilds });
    }
  };

  addPaygProduct = () => {
    if (this.state.addPaygProduct.length == 50) {
      return false;
    }

    let addProduct = this.state.addPaygProduct;
    let multiplePaygClassList = this.state.addPaygProductClass;

    addProduct.push(initPaygAddProduct());
    multiplePaygClassList.push(initPaygAddClass());

    this.setState({
      addPaygProduct: addProduct,
      addPaygProductClass: multiplePaygClassList,
    });
  };

  deletePaygProduct = (event) => {
    if (this.state.addPaygProduct.length == 1) {
      return false;
    }

    let addProduct = this.state.addPaygProduct;
    const index = event.target.dataset.index;

    addProduct.splice(index, 1);

    if (
      addProduct.length === 1 &&
      (addProduct[0]["product_name"] === undefined ||
        addProduct[0]["product_name"] === null ||
        addProduct[0]["product_name"] === "")
    ) {
      addProduct[0] = initPaygAddProduct();
    }

    let multiplePaygClassList = this.state.addPaygProductClass;

    if (multiplePaygClassList[index] !== undefined) {
      multiplePaygClassList.splice(index, 1);
    }

    this.setState({
      addPaygProduct: addProduct,
      addPaygProductClass: multiplePaygClassList,
    });
  };

  showDeleteModal = () => {
    this.setState({ showModal: true });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  deletetemplate = () => {
    this.setState({ showLoader: true });
    this.dismissModal();

    let Id = this.state.templateID;
    let formData = {
      template_id: Id,
    };

    this.setState({ showLoader: true });
    this.props.deleteTreatmentTemplate(formData);
  };

  selectProduct = (obj) => {
    let productID = 0;
    let productName = "";

    if (obj?.data?.id) {
      productID = obj.data.id;
      productName = obj.data.product_name;
    }

    if (this.state.planType === "payg") {
      const addPaygProduct = this.state.addPaygProduct;
      let selProIndex = this.state.selProIndex;

      if (productID && productID > 0 && productName) {
        if (selProIndex) {
          addPaygProduct[selProIndex]["units"] = "";
          addPaygProduct[selProIndex]["product_id"] = productID;
          addPaygProduct[selProIndex]["product_name"] = productName;
          addPaygProduct[selProIndex]["minUnits"] = Maybe.of(
            obj?.data?.start_at_unit,
          )
            .map(String)
            .value();
          addPaygProduct[selProIndex]["countBy"] = Maybe.of(
            obj?.data?.count_units_by,
          )
            .map(String)
            .value();
        }
      }

      this.setState(addPaygProduct);

      this.setState({
        showSearchResult: false,
        selProIndex: -1,
      });
    }

    if (this.state.planType === "monthly") {
      const showMonthsFeilds = this.state.showMonthsFeilds;
      let selProIndex = this.state.selectedProIndex;
      let selProMonthIndex = this.state.selProMonthIndex;

      if (productID && productID > 0 && productName) {
        if (selProIndex) {
          showMonthsFeilds[selProMonthIndex][selProIndex]["units"] = "";
          showMonthsFeilds[selProMonthIndex][selProIndex]["product_id"] =
            productID;
          showMonthsFeilds[selProMonthIndex][selProIndex]["product_name"] =
            productName;
          showMonthsFeilds[selProMonthIndex][selProIndex]["minUnits"] =
            Maybe.of(obj?.data?.start_at_unit).map(String).value();
          showMonthsFeilds[selProMonthIndex][selProIndex]["countBy"] = Maybe.of(
            obj?.data?.count_units_by,
          )
            .map(String)
            .value();
        }
      }

      this.setState(showMonthsFeilds);

      this.setState({
        showSearchResult: false,
        selectedProIndex: -1,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (isFormSubmit()) {
      let planType = this.state.planType;
      let formData = {};

      if (planType == "monthly") {
        let totalDataRows = 0;
        let totalUnfilledRows = 0;
        let error = false;
        let showMonthsFeilds = this.state.showMonthsFeilds;
        let skinGoal = this.state.skinCareGoal;
        let templateName = this.state.templateName;
        let planType = this.state.planType;
        let pDuration = this.state.planDuration;
        let isUnitMinValueError = false;
        let isUnitCountByError = false;

        if (
          templateName.trim() === "" ||
          templateName == undefined ||
          templateName == null
        ) {
          error = true;
          this.setState({ nameClass: "newInputField field_error" });
          return;
        }

        if (pDuration == undefined || pDuration == null) {
          error = true;
          this.setState({ durationClass: "newSelectField field_error" });
          return;
        }

        showMonthsFeilds.map((obj) => {
          totalDataRows += obj.length;

          obj.map((objx) => {
            if (!objx.product_id && !objx.units) {
              totalUnfilledRows = totalUnfilledRows + 1;
            }

            if (!objx.product_id && isNumber(objx.units)) {
              objx.product_nameClass = "newInputField field_error";
              error = true;
            } else {
              objx.product_nameClass = "newInputField";
            }

            if (objx.product_id && !isNumber(objx.units)) {
              objx.unitsClass = "newInputField field_error";
              error = true;
              return;
            } else if (
              objx.minUnits &&
              Number(objx.units) < Number(objx.minUnits)
            ) {
              objx.unitsClass = "newInputField field_error";
              error = true;
              isUnitMinValueError = true;
              return;
            } else if (objx.countBy && !isDerivedFromIncrement(Number(objx.units), Number(objx.countBy))) {
              objx.unitsClass = "newInputField field_error";
              error = true;
              isUnitCountByError = true;
              return;
            } else {
              objx.unitsClass = "newInputField";
            }
          });
        });

        if (totalDataRows === totalUnfilledRows) {
          error = true;
          uiNotification.clear();
          uiNotification.error(
            "At least one product and its units are required to start treatment plan",
          );
        }

        this.setState({
          showMonthsFeilds: showMonthsFeilds,
        });

        if (error) {
          if (isUnitMinValueError) {
            uiNotification.error(
              "Units cannot be less than products Start at Unit value",
            );
          }

          if (isUnitCountByError) {
            uiNotification.error(
              "Units value should be derived from \"Count by\" increment",
            );
          }

          return;
        }

        isUnitMinValueError = false;
        isUnitCountByError = false;

        formData = {
          template_id: this.state.templateID > 0 ? this.state.templateID : "",
          plan_type: planType,
          plan_name: templateName,
          duration: pDuration,
          skincare_goal: skinGoal,
          treatment_plan_template_data: showMonthsFeilds,
        };
      }

      if (planType == "payg") {
        let paygName = this.state.templateName;
        let duration = this.state.paygDuration;
        let multiplePaygClassList = this.state.addPaygProductClass;
        let error = false;
        let isUnitMinValueError = false;
        let isUnitCountByError = false;

        if (
          paygName.trim() === "" ||
          paygName == undefined ||
          paygName == null
        ) {
          error = true;
          this.setState({ nameClass: "newInputField field_error" });
          return;
        }

        if (duration == undefined || duration == null) {
          error = true;
          this.setState({ durationClass: "newSelectField field_error" });
          return;
        }

        this.state.addPaygProduct.map((obj, idx) => {
          if (!obj.product_id) {
            multiplePaygClassList[idx]["product_name"] =
              "newInputField field_error";
            error = true;
          } else {
            multiplePaygClassList[idx]["product_name"] = "newInputField";
          }

          if (!isNumber(obj.units)) {
            multiplePaygClassList[idx]["units"] = "newInputField field_error";
            error = true;
          } else if (obj.minUnits && Number(obj.units) < Number(obj.minUnits)) {
            multiplePaygClassList[idx]["units"] = "newInputField field_error";
            error = true;
            isUnitMinValueError = true;
          } else if (obj.countBy && !isDerivedFromIncrement(Number(obj.units), Number(obj.countBy))) {
            multiplePaygClassList[idx]["units"] = "newInputField field_error";
            error = true;
            isUnitCountByError = true;
          } else {
            multiplePaygClassList[idx]["units"] = "newInputField";
          }

          if (!isInt(obj.rep_val) || obj.rep_val <= 0) {
            multiplePaygClassList[idx]["rep_val"] =
              "newInputField p-r-5 field_error";
            error = true;
          } else {
            multiplePaygClassList[idx]["rep_val"] = "newInputField p-r-5";
          }
        });

        this.setState({
          addPaygProductClass: multiplePaygClassList,
        });

        if (error) {
          if (isUnitMinValueError) {
            uiNotification.error(
              "Units cannot be less than products Start at Unit value",
            );
          }

          if (isUnitCountByError) {
            uiNotification.error(
              "Units value should be derived from \"Count by\" increment",
            );
          }

          return;
        }

        isUnitMinValueError = false;
        isUnitCountByError = false;

        let treatProductName = [];
        let hProID = [];
        let treatProductUnits = [];
        let treatPlanRepValue = [];
        let treatPlanRepBy = [];

        this.state.addPaygProduct.map((obj) => {
          treatProductName.push(obj.product_name);
          hProID.push(obj.product_id);
          treatProductUnits.push(obj.units);
          treatPlanRepValue.push(obj.rep_val);
          treatPlanRepBy.push(obj.rep_by);
        });

        formData = {
          template_id: this.state.templateID > 0 ? this.state.templateID : "",
          plan_type: this.state.planType,
          h_pro_id: hProID,
          treat_product_units: treatProductUnits,
          treat_plan_rep_val: treatPlanRepValue,
          treat_plan_rep_by: treatPlanRepBy,
          duration: this.state.paygDuration,
          plan_name: this.state.templateName,
        };
      }

      if (planType == "monthly" && formData) {
        this.setState({ showLoader: true });
        this.props.saveTemplate(formData);
      } else {
        this.setState({ showLoader: true });
        this.props.saveTemplatePayg(formData);
      }
    }
  };

  render() {
    let returnTo = "";
    returnTo = this.state.returnTo;

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="business-setion memberWalletOuter">
            <div className="juvly-section full-width">
              <div className="juvly-container m-h-container">
                <div className="setting-title m-b-20">
                  {this.state.templateID
                    ? "Edit Treatment Plan Templates"
                    : "Create Treatment Plan Template"}
                  <Link to={returnTo} className="pull-right">
                    <img alt="" src={picClose} />
                  </Link>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="newInputFileldOuter m-b-20">
                      <div className="juvly-subtitle m-b-10 m-t-10">
                        Plan Type<span className="setting-require">*</span>
                      </div>
                      <div className="pull-left m-t-5 m-r-10">
                        <input
                          type="radio"
                          id="monthly"
                          name="plan_type"
                          className="basic-form-checkbox"
                          value="monthly"
                          onChange={this.handleInputChange}
                          checked={
                            this.state.planType == "monthly" ? "checked" : false
                          }
                        />
                        <label
                          htmlFor="UserIsDashboardEnabled0"
                          className="basic-form-text"
                        >
                          Monthly
                        </label>
                      </div>
                      <div className="pull-left m-t-5">
                        <input
                          type="radio"
                          id="pay_as_you_go"
                          name="plan_type"
                          className="basic-form-checkbox"
                          value="payg"
                          onChange={this.handleInputChange}
                          checked={
                            this.state.planType == "payg" ? "checked" : false
                          }
                        />
                        <label
                          htmlFor="UserIsDashboardEnabled1"
                          className="basic-form-text"
                        >
                          Pay As You Go
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.showFeilds == false && (
                  <div className="row">
                    <div className="col-sm-6 col-xs-8">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Template Name{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.nameClass}
                          type="text"
                          name="templateName"
                          value={this.state.templateName}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Duration of Plan{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <select
                          className="newSelectField"
                          name="planDuration"
                          value={this.state.planDuration}
                          onChange={this.handleInputChange}
                        >
                          {this.buildOptions2()}
                        </select>
                      </div>
                    </div>

                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">Your Skincare Goal</div>
                        <textarea
                          className="newtextareaField"
                          name="skinCareGoal"
                          value={this.state.skinCareGoal}
                          onChange={this.handleInputChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.showFeilds == false &&
                  this.state.showMonthsFeilds.map((monthObj, monthIdx) => {
                    return (
                      <div key={monthIdx}>
                        <div className="juvly-subtitle m-b-10 m-t-10">
                          {"Treatment in Month" + " " + (monthIdx + 1)}
                        </div>
                        <div className="row relative monthlyPlan">
                          {monthObj.map((productObj, productIdx) => {
                            return (
                              <div key={productIdx}>
                                <div className="col-sm-6 col-xs-8">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">
                                      Products
                                    </div>
                                    <div
                                      className="product_name_container"
                                      data-ref-container={
                                        "product_name_container"
                                      }
                                      ref={(ref_product_name_container) =>
                                      (this.ref_product_name_container =
                                        ref_product_name_container)
                                      }
                                    >
                                      <input
                                        autoComplete="off"
                                        className={productObj.product_nameClass}
                                        name={"product_name"}
                                        value={productObj.product_name}
                                        onChange={(event) =>
                                          this.handleProductInput(event)
                                        }
                                        type="text"
                                        data-index={productIdx}
                                        data-month={monthIdx}
                                        placeholder={
                                          this.state.inventoryLang
                                            .inventory_type_to_search_products
                                        }
                                      />
                                      <ul
                                        key={
                                          `monthly_row_not_found-` +
                                          monthIdx +
                                          `-` +
                                          productIdx
                                        }
                                        className={
                                          this.state.productData &&
                                            this.state.productData.length &&
                                            this.state.showSearchResult &&
                                            this.state.showSearchResult &&
                                            productIdx ==
                                            parseInt(
                                              this.state.selectedProIndex,
                                            ) &&
                                            monthIdx ==
                                            parseInt(
                                              this.state.selProMonthIndex,
                                            )
                                            ? " search-dropdown"
                                            : "cal-dropdown clinicname-dropdown no-display"
                                        }
                                      >
                                        {this.state.productData &&
                                          this.state.productData.length &&
                                          this.state.productData.map(
                                            (obj, iidx) => {
                                              return (
                                                <li
                                                  key={"product_" + iidx}
                                                  onClick={() =>
                                                    this.selectProduct(obj)
                                                  }
                                                >
                                                  <a>
                                                    {obj &&
                                                      obj.data &&
                                                      obj.data.product_name &&
                                                      capitalizeFirstLetter(
                                                        obj.data.product_name,
                                                      )}
                                                  </a>
                                                </li>
                                              );
                                            },
                                          )}
                                      </ul>
                                      <ul
                                        key={
                                          `monthly_row_not_found_` +
                                          monthIdx +
                                          `_` +
                                          productIdx
                                        }
                                        className={
                                          this.state.productData &&
                                            !this.state.productData.length &&
                                            this.state.showSearchResult &&
                                            this.state.showSearchResult &&
                                            productIdx ===
                                            parseInt(
                                              this.state.selectedProIndex,
                                            ) &&
                                            monthIdx ===
                                            parseInt(
                                              this.state.selProMonthIndex,
                                            )
                                            ? "search-dropdown"
                                            : " cal-dropdown clinicname-dropdown no-display"
                                        }
                                      >
                                        {this.state.productData &&
                                          this.state.productData.length ===
                                          0 && (
                                            <li>
                                              <a>
                                                There were no matches, please
                                                enter relevant keywords
                                              </a>
                                            </li>
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-2 col-xs-4">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Units</div>
                                    <input
                                      className={productObj.unitsClass}
                                      type="number"
                                      key={productIdx}
                                      name={"units"}
                                      value={productObj.units}
                                      placeholder="Units"
                                      data-index={productIdx}
                                      data-month={monthIdx}
                                      onChange={(event) =>
                                        this.handleProductInput(event)
                                      }
                                    />
                                    {productObj.minUnits && (
                                      <div className="font-12">
                                        Min: {productObj.minUnits}
                                      </div>
                                    )}
                                    {productObj.countBy && (
                                      <div className="font-12">
                                        Count by: {productObj.countBy}
                                      </div>
                                    )}
                                    {productIdx == 0 ? (
                                      <button
                                        className="add-round-btn"
                                        data-index={productIdx}
                                        onClick={() =>
                                          this.addProduct(monthIdx)
                                        }
                                      >
                                        <span data-index={productIdx}>+</span>
                                      </button>
                                    ) : (
                                      <button
                                        className="add-round-btn"
                                        data-index={productIdx}
                                        onClick={() =>
                                          this.deleteProduct(
                                            monthIdx,
                                            productIdx,
                                          )
                                        }
                                      >
                                        <span data-index={productIdx}>-</span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}

                {this.state.showFeilds && (
                  <div className="row relative payasgo">
                    <div className="col-sm-6 col-xs-8">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Template Name{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.nameClass}
                          type="text"
                          name="templateName"
                          value={this.state.templateName}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Duration of Plan{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <select
                          className={this.state.durationClass}
                          name="paygDuration"
                          value={this.state.paygDuration}
                          onChange={this.handleInputChange}
                        >
                          {this.buildOptions()}
                          <option value={0}>Never</option>
                        </select>
                      </div>
                    </div>
                    <div></div>
                  </div>
                )}
                {this.state.showFeilds &&
                  this.state.addPaygProduct.map((objx, idx) => {
                    const paygElemClass = this.state.addPaygProductClass[idx]
                      ? this.state.addPaygProductClass[idx]
                      : "newInputField";
                    return (
                      <div className="row relative payasgo" key={idx}>
                        <div className="col-sm-6 col-xs-6">
                          <div className="newInputFileldOuter pay-as-you-go">
                            <div className="newInputLabel">Products</div>
                            <input
                              className={paygElemClass.product_name}
                              placeholder={
                                this.state.inventoryLang
                                  .inventory_type_to_search_products
                              }
                              autoComplete="off"
                              type="text"
                              name="product_name"
                              value={objx.product_name}
                              onChange={(e) => this.handlePaygProductInput(e)}
                              data-index={idx}
                            />
                            <ul
                              key={`payg_row_found-` + idx}
                              className={
                                this.state.planType == "payg" &&
                                  this.state.productData &&
                                  this.state.productData.length &&
                                  this.state.showSearchResult &&
                                  idx === parseInt(this.state.selProIndex)
                                  ? " search-dropdown"
                                  : "cal-dropdown clinicname-dropdown no-display"
                              }
                            >
                              {this.state.productData &&
                                this.state.productData.length &&
                                this.state.productData.map((obj, iidx) => {
                                  return (
                                    <li
                                      key={"product_" + iidx}
                                      onClick={() => this.selectProduct(obj)}
                                    >
                                      <a>
                                        {obj &&
                                          obj.data &&
                                          obj.data.product_name &&
                                          capitalizeFirstLetter(
                                            obj.data.product_name,
                                          )}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                            <ul
                              key={`payg_row_not_found_` + idx}
                              className={
                                this.state.productData &&
                                  !this.state.productData.length &&
                                  this.state.showSearchResult &&
                                  idx === parseInt(this.state.selProIndex)
                                  ? "search-dropdown"
                                  : " cal-dropdown clinicname-dropdown no-display"
                              }
                            >
                              {this.state.productData &&
                                this.state.productData.length === 0 && (
                                  <li>
                                    <a>
                                      There were no matches, please enter
                                      relevant keywords
                                    </a>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </div>
                        <div className="col-sm-2 col-xs-6">
                          <div className="newInputFileldOuter pay-as-you-go">
                            <div className="newInputLabel">Units</div>
                            <input
                              className={paygElemClass.units}
                              type="number"
                              name="units"
                              placeholder="Units"
                              value={objx.units}
                              onChange={(e) => this.handlePaygProductInput(e)}
                              data-index={idx}
                            />
                            {objx.minUnits && (
                              <div className="font-12">
                                Min: {objx.minUnits}
                              </div>
                            )}
                            {objx.countBy && (
                              <div className="font-12">
                                Count by: {objx.countBy}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">Duration</div>
                            <div className="row">
                              <div className="col-md-6 col-xs-6">
                                <input
                                  className={paygElemClass.rep_val}
                                  name="rep_val"
                                  type="text"
                                  placeholder="Value"
                                  value={objx.rep_val}
                                  onChange={(e) =>
                                    this.handlePaygProductInput(e)
                                  }
                                  data-index={idx}
                                />
                              </div>
                              <div className="col-md-5 col-xs-6">
                                <select
                                  className={paygElemClass.rep_by}
                                  name="rep_by"
                                  value={objx.rep_by}
                                  onChange={(e) =>
                                    this.handlePaygProductInput(e)
                                  }
                                  data-index={idx}
                                >
                                  <option
                                    value={
                                      objx.rep_by == "day" ? "days" : "days"
                                    }
                                  >
                                    Day(s)
                                  </option>
                                  <option
                                    value={
                                      objx.rep_by == "week" ? "weeks" : "weeks"
                                    }
                                  >
                                    Week(s)
                                  </option>
                                  <option
                                    value={
                                      objx.rep_by == "month"
                                        ? "months"
                                        : "months"
                                    }
                                  >
                                    Month(s)
                                  </option>
                                  <option
                                    value={
                                      objx.rep_by == "year" ? "year" : "year"
                                    }
                                  >
                                    Year(s)
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {idx == 0 ? (
                          <button
                            className="add-round-btn"
                            data-index={idx}
                            onClick={() => this.addPaygProduct(objx, idx)}
                          >
                            <span data-index={idx}>+</span>
                          </button>
                        ) : (
                          <button
                            className="add-round-btn"
                            data-index={idx}
                            onClick={this.deletePaygProduct}
                          >
                            <span data-index={idx}>-</span>
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>

              <div className="footer-static">
                <button
                  className="new-blue-btn pull-right"
                  onClick={this.handleSubmit}
                  id="saveform"
                >
                  {this.state.globalLang.label_save}
                </button>
                <Link
                  to={returnTo}
                  className="new-white-btn pull-right cancelAction"
                >
                  {this.state.globalLang.label_cancel}
                </Link>
                {this.props.match.params.id != undefined && (
                  <button
                    className="new-red-btn pull-left"
                    id="resetform"
                    onClick={this.showDeleteModal}
                  >
                    {this.state.globalLang.label_delete}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={this.state.showModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showModal ? "modal fade in displayBlock" : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.globalLang.delete_confirmation}
                    {this.state.showModal}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {"Are you sure you want to delete the template?"}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      {this.state.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deletetemplate}
                    >
                      {this.state.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.SettingReducer.action === "SAVE_TEMPLATE_DATA") {
    if (state.SettingReducer.data.status !== 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.saveTemplateData = state.SettingReducer.data;
      returnState.showLoader = false;
    } else {
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
      returnState.redirect = true;
    }
  }

  if (state.SettingReducer.action === "SAVE_TEMPLATE_DATA_PAYG") {
    if (state.SettingReducer.data.status !== 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  }

  if (state.SettingReducer.action === "GET_TEMPLATE") {
    if (state.SettingReducer.data.status !== 200) {
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.getTemplateData = state.SettingReducer.data;
      returnState.getTemplateDataTimestamp = new Date();
    }
  }

  if (state.SettingReducer.action === "TEMPLATE_DELETED") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
    } else {
      returnState.redirect = true;
      returnState.deleted = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  }

  if (state.ClientsReducer.action === "SEARCH_WALLET_PRODUCT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.productData = state.ClientsReducer.data;
      returnState.productDataTimestamp = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveTemplate,
      exportEmptyData,
      saveTemplatePayg,
      searchProduct,
      fetchTemplate,
      updateTemplate,
      deleteTreatmentTemplate,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateEditTreatmentPlanTemp);
