import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import { autoScrolling, capitalizeFirstLetter } from "../../../../Utils/services.js";
import Loader from '../../../../Components/Common/Loader.js'
import {fetchtreatmentplantemplate, exportEmptyData} from '../../../../Actions/Settings/settingsActions.js';


class TreatmentPlanTemplates extends Component {
    constructor(props) {
      super(props);
      window.scrollTo(0, 0)
      const languageData  = JSON.parse(localStorage.getItem('languageData'))
      this.state= {
        settingLang       : languageData.settings,
        showLoader : false,
        globalLang        : languageData.global,
        term: '',
        templateList : [],
        loadMore: true,
        startFresh: true,
        term:'',
        hasMoreItems: true,
        next_page_url: '',
        page:1,
        pagesize:15,
        sortorder:'asc',
        showLoadingText : false,
      }
      localStorage.setItem('loadFresh', false);
      localStorage.setItem('sortOnly', false);
    }


componentDidMount() {
  this.props.exportEmptyData()
  let formData = {'params':{
     page:this.state.page,
     pagesize:this.state.pagesize,
     //sortorder: 'asc',
     term:this.state.term,
     //sortby: this.state.sortby
    }
  }
  this.setState({'showLoader': true})
  autoScrolling(true)
  this.props.fetchtreatmentplantemplate(formData);
}

static getDerivedStateFromProps(nextProps, prevState){

  if(nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return {showLoader : false};
   }
  if (nextProps.templateList != undefined && nextProps.templateList.next_page_url !== prevState.next_page_url) {
      let returnState = {};
      if(prevState.next_page_url == null) {
        localStorage.setItem('sortOnly', false);
        autoScrolling(false)
        return returnState.next_page_url = null;
      }
      if(prevState.templateList.length == 0 && prevState.startFresh == true) {
        if(localStorage.getItem('sortOnly') == 'false') {
          returnState.templateList = nextProps.templateList;
          if(nextProps.templateList.next_page_url != null){
             returnState.page = prevState.page + 1;
           } else {
             returnState.next_page_url = nextProps.templateList.next_page_url;
           }
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
        } else {
          localStorage.setItem('sortOnly', false);
        }

      } else if(prevState.templateList != nextProps.templateList.data && prevState.templateList.length != 0) {
        returnState.questionnaireList = [...prevState.templateList,...nextProps.templateList.data];
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.templateList.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      }
      autoScrolling(false)
      return returnState;
  }
  return null;
}


updatetemplate = (id) => {
  return (
    <div>
      {this.props.history.push(`/settings/treatment-plan-templates/edit/${id}`)}
    </div>
  );
}

    render(){
      return(
        <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
             <div className="business-setion memberWalletOuter">
                <div className="setting-setion m-b-10">
                    <div className="membership-title">
                    Treatment Plan Templates
                       {/* <form  onSubmit={this.handleSubmit} className="searchForm">
                          <span className="searchOuter"><i className="fa fa-search search-icon" />
                         <input className="setting-search-input search-key" name="term" placeholder="Search" autoComplete="off" value={this.state.term}  onChange={this.handleInputChange} />
                          </span>
                        </form>*/}
                      <div className="memberRightActions">
                        <Link to="/settings/treatment-plan-templates/create" className="new-blue-btn pull-right edit_setting">Create Template</Link>
                      </div>
                    </div>
                </div>
            <div className="juvly-section full-width">
               <div className="table-responsive">
                 <table className="table-updated setting-table no-td-border no-hover table-min-width">
                   <thead className="table-updated-thead">
                     <tr>
                       <th className="col-xs-4 table-updated-th">Template Name</th>
                       <th className="col-xs-3 table-updated-th">Plan Type</th>
                       <th className="col-xs-3 table-updated-th">Plan Duration</th>
                       <th className="col-xs-2 table-updated-th"></th>
                     </tr>
                   </thead>
                   <tbody className="ajax_body" >

                   {
                     this.state.templateList.length > 0 && this.state.templateList.map((obj, idx) => {

                       return(
                         <tr key={idx} className="table-updated-tr">
                           <td className="col-xs-4  table-updated-td">{(obj.plan_name) ? capitalizeFirstLetter(obj.plan_name) : ''}</td>
                           <td className="col-xs-3  table-updated-td break-all">{(obj.plan_type =="monthly") ? "Monthly" : 'Pay As You Go'}</td>
                           <td className="col-xs-3  table-updated-td break-all">{(obj.duration) ? (obj.duration + " " +  "Months" ) : (obj.duration == "0") ? "Never" : ""}</td>
                           <td className="col-xs-2 table-updated-td text-right">
                           <a className="m-r-0 edit_setting easy-link p-l-0" onClick={() => this.updatetemplate(obj.id)}><i className="fa fa-pencil-alt m-r-5" />Edit</a>
                           </td>
                         </tr>
                       )
                     })
                    }
                  </tbody>
                 </table>
                 {(this.state.showLoader === false) && <div className={this.state.templateList && this.state.templateList && this.state.templateList.length > 0 ? "no-record no-display" : "no-record"}>Sorry, No Record Found</div>}
               </div>
            </div>

               <div className={(this.state.showLoadingText) ? "loading-please-wait no-margin-top" : "loading-please-wait no-margin-top no-display"}>{this.state.globalLang.loading_please_wait_text}</div>
             </div>
               <Loader showLoader={this.state.showLoader} isFullWidth={true} />
         </div>
      </div>
      )
    }  
  
} 

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.SettingReducer.action === "TREATMENT_PLAN_TEMPLATE") {
    localStorage.setItem('loadFresh', false);
    if(state.SettingReducer.data.status != 200){
      returnState.showLoader = false
    }
    else {
          returnState.templateList = state.SettingReducer.data.data

        }
      }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({fetchtreatmentplantemplate: fetchtreatmentplantemplate, exportEmptyData: exportEmptyData}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanTemplates);

