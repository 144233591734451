import React from "react";
import { Checkbox } from "../../../shared/Checkbox/Checkbox";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function useTableData(patients, selectHandlers) {
  const { tCommon } = useAppTranslation.Common();

  const {
    onToggleOne,
    onToggleAll,
    isSelected,
    isSelectedAll,
    isSelectedPartial,
  } = selectHandlers;

  const cols = [
    {
      data: (
        <Checkbox
          inverted
          size="small"
          isChecked={isSelectedAll()}
          isIntermediate={isSelectedPartial()}
          onChange={onToggleAll}
        />
      ),
      accessor: "checkbox",
    },
    {
      data: tCommon("label.name"),
      accessor: "name",
    },
  ];

  const data = patients.map((p) => ({
    key: p.groupId,
    name: p.name,
    checkbox: (
      <Checkbox
        size="small"
        isChecked={isSelected(p.groupId)}
        onChange={() => onToggleOne(p.groupId)}
      />
    ),
  }));

  return {
    cols,
    data,
  };
}
