import React from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { MessageCirclePlusIcon } from "../../../../assets/Icons/MessageCirclePlusIcon";
import { EditIcon } from "../../../../assets/Icons/EditIcon";
import styles from "./styles.module.scss";

export function FillButton({ onClick, status, isPartiallyFilled }) {
  var { tCommon } = useAppTranslation.Common();

  var getIcon = () => {
    if (status === "notFilled") {
      return <MessageCirclePlusIcon width="16px" />;
    }

    return <EditIcon width="16px" />;
  };

  var getLabel = () => {
    if (status === "notFilled" && isPartiallyFilled) {
      return tCommon("label.resume");
    }

    if (status === "notFilled" && !isPartiallyFilled) {
      return tCommon("label.fill");
    }

    return tCommon("label.edit");
  };

  return (
    <button onClick={onClick} className={styles.root}>
      {getIcon()}
      {getLabel()}
    </button>
  );
}
