import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ShopifyProducts = (props) => {
  const initialLanguageData = JSON.parse(localStorage.getItem("languageData"));
  const [reportData, setReportData] = useState(props.reportData || []);
  const [languageData] = useState(initialLanguageData.business_insights);

  useEffect(() => {
    if (props.reportData !== reportData) {
      setReportData(props.reportData);
    }
  }, [props.reportData, reportData]);

  return (
    <div className="table-responsive clients-table">
      <table className="table-updated setting-table table-min-width no-td-border no-hover">
        <thead className="table-updated-thead">
          <tr>
            <th className="table-updated-th">
              {reportData?.report_header?.name}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.vendor}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.type}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.net_quantity}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.gross_sales}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.net_sales}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.returns}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.discounts}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.tax}
            </th>
          </tr>
        </thead>
        <tbody className="ajax_body">
          {reportData?.report_data?.length > 0 &&
            reportData.report_data.map((obj) => (
              <tr key={obj.id} className="table-updated-tr">
                <td className="table-updated-td item-sales-item-td">
                  {obj.name}
                </td>
                <td className="table-updated-td">{obj.vendor}</td>
                <td className="table-updated-td">{obj.type}</td>
                <td className="table-updated-td">{obj.net_quantity}</td>
                <td className="table-updated-td">{obj.gross_sales}</td>
                <td className="table-updated-td">{obj.net_sales}</td>
                <td className="table-updated-td">{obj.returns}</td>
                <td className="table-updated-td">{obj.discounts}</td>
                <td className="table-updated-td">{obj.tax}</td>
              </tr>
            ))}
          {reportData?.report_data?.length === 0 && (
            <tr className="table-updated-tr">
              <td className="table-updated-td no-record no-float" colSpan="10">
                {languageData.bi_no_record_found}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ShopifyProducts.propTypes = {
  reportData: PropTypes.shape({
    report_header: PropTypes.shape({
      name: PropTypes.string,
      vendor: PropTypes.string,
      type: PropTypes.string,
      net_quantity: PropTypes.string,
      gross_sales: PropTypes.string,
      net_sales: PropTypes.string,
      returns: PropTypes.string,
      discounts: PropTypes.string,
      tax: PropTypes.string,
    }),
    report_data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        vendor: PropTypes.string,
        type: PropTypes.string,
        net_quantity: PropTypes.string,
        gross_sales: PropTypes.string,
        net_sales: PropTypes.string,
        returns: PropTypes.string,
        discounts: PropTypes.string,
        tax: PropTypes.string,
      }),
    ),
  }),
};

export default ShopifyProducts;
