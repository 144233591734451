import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  redemptionCode: yup.string().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  availableAmount: yup.number().required(),
  redemptionCode: yup.string().required(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return {
    id: data.id,
    availableAmount: data.balance || 0,
    redemptionCode: data.redemption_code.toString(),
  };
};

export function useValidateRedemptionCodeMutation(options = {}) {
  return useMutation(async (payload) => {
    const req = requestSchema.validateSync(payload, {
      strict: true,
    });
    const res = await http.post(HTTP_ENDPOINTS.postValidateRedemptionCode(), {
      redemption_code: req.redemptionCode,
    });
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
