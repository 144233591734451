import React, { useState } from "react";
import { usePrevious } from "../../../../utilities/hooks/usePrevious";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";

const VALUE_PATTERN = ":value";
const onBlurFallback = () => {};

export const withInputConfirm = (
  Component,
  { confirmMsg, valueAccessor, onBlurAccessor, valueWrapper = (value) => value }
) => (props) => {
  const nativeValue = props[valueAccessor] || null;
  const nativeOnBlur = props[onBlurAccessor] || onBlurFallback;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const prevValue = usePrevious(nativeValue);

  const handleBlur = (...args) => {
    nativeOnBlur(...args);
    if (nativeValue && nativeValue !== prevValue) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Component {...{ ...props, [onBlurAccessor]: handleBlur }} />
      {isModalOpen && (
        <ConfirmModal
          isOpen={isModalOpen}
          onConfirm={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          onClose={() => setIsModalOpen(false)}
        >
          {confirmMsg.replace(VALUE_PATTERN, valueWrapper(nativeValue) || "-")}
        </ConfirmModal>
      )}
    </>
  );
};
