import React from "react";
import moment from "moment";
import classes from "./Note.module.scss";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../../../consts/general";

/**
 * @param {object} prop0
 * @param {string} prop0.text
 * @param {string} prop0.date
 * @param {string} prop0.author
 * @param {() => void} prop0.onDelete
 * @param {() => void} prop0.onEdit
 */
export const Note = ({ text, date, author, onDelete, onEdit }) => {
  const { tCommon } = useAppTranslation.Common();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);
  const { data: user } = useCurrentUserQuery();

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h3>{tCommon("label.authorLeftNote", { author })}</h3>
        <div>
          {moment(date).format(
            `${user.dateFormat || DEFAULT_DATE_FORMAT} ${
              user.timeFormat || DEFAULT_TIME_FORMAT
            }`,
          )}
        </div>
      </div>
      <div className={classes.note}>{text}</div>
      <div className={classes.actions}>
        <button onClick={onEdit}>{tCommon("label.edit")}</button>
        <button onClick={() => setIsDeleteConfirmOpen(true)}>
          {tCommon("label.delete")}
        </button>
      </div>
      {isDeleteConfirmOpen && (
        <ConfirmModal
          isOpen
          onClose={() => setIsDeleteConfirmOpen(false)}
          onCancel={() => setIsDeleteConfirmOpen(false)}
          onConfirm={() => {
            onDelete();
            setIsDeleteConfirmOpen(false);
          }}
        >
          {tCommon("confirm.deleteNote")}
        </ConfirmModal>
      )}
    </div>
  );
};
