/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

export default class ReputationTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let name = event.target.name;

    this.props.handleChildState({ [name]: value });
  };

  redirectToMain = () => {
    return (
      <div>
        {this.props.history.push(`/business-insight/reputation-management`)}
      </div>
    );
  };

  render() {
    return (
      <div className="setting-setion m-b-10">
        <div className="membership-title">
          {this.props.isBreadcrumbs === true ? (
            <span className="cursor-pointer" onClick={this.redirectToMain}>
              {this.props.businessInsightLang.bi_reputation_management}
            </span>
          ) : (
            this.props.businessInsightLang.bi_reputation_management
          )}
          {this.props.isBreadcrumbs === true && (
            <span className="breadCrumb">
              <i className="fa fa-chevron-right" />{" "}
              <span className="breadCrumb-text">
                {this.props.businessInsightLang.bi_create_report}
              </span>
            </span>
          )}
          {this.props.isCreateButton === true && (
            <div className="memberRightActions">
              <a
                className="new-blue-btn"
                onClick={() => this.props.showCreateOptions("create")}
              >
                {this.props.businessInsightLang.bi_create_report}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
