import React, { Component } from 'react';
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { fetchWidget, submitWidget } from '../../../../Actions/Settings/settingsActions.js';
import Loader from '../../../../Components/Common/Loader.js'
import { isFormSubmit, displayName } from '../../../../Utils/services.js';
import { uiNotification } from '../../../../services/UINotificationService.js';

const WIDGET_LABELS = {
  net_sales_by_provider: "Net Sales by Provider",
};

class DashboardRoles extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.state = {
      is_status: false,
      roles: [],
      selectedRoles: [],
      users: [],
      selectedUsers: [],
      radioOption: "",
      selectedValues: [],
      userChanged: false,
      dashboadWidgets: [],
      selectRoleClass: "col-xs-12",
      selectUserClass: "col-xs-12",
      showLoader: false,
      dataChanged: false,
      globalLang: languageData.global,
      buttonTableId: '',
      toggleBtnDate: '',
      currentId: '',
      submitClicked: false,
      status: 0,
      dashTableId:'',
      statusId:[],
    }
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.fetchWidget(1).catch((res) => {
      uiNotification.error(this.state.globalLang[res.message]);
    }).finally(() => {
      this.setState({ showLoader: false });
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.widgetList != undefined && nextProps.widgetList != prevState.widgetList
      && nextProps.widgetList.status == 200  && prevState.widgetTime != nextProps.widgetTime ) {
      returnState.dashboadWidgets = nextProps.widgetList.data.dashboard_widgets;
      returnState.widgetTime = nextProps.widgetTime;

      let widgets = nextProps.widgetList.data.dashboard_widgets;
      let roles = nextProps.widgetList.data.roles;
      let users = nextProps.widgetList.data.users;

      if( widgets ) {
        widgets.map((widget, idx) => {
         if(widget.available_for != "all"){
          let id = [];
          if( widget.associated_ids ) {
            id = widget.associated_ids.map((id, asId) => {
              if( widget.available_for == 'role' ) {
                return { value: id, label: roles[id-1] }
              } else {
                let user = users.find(e => e.id == id);
                if (user) {
                  return { value: user.id, label: displayName(user) }
                }
                return null;
              }
            }).filter(Boolean);
          }
          if( widget.available_for == 'role' ) {
            returnState['user_selected_'+widget.name] = [];
          } else {
            returnState['role_selected_'+widget.name] = [];
          } 
          returnState[widget.available_for+'_selected_'+widget.name] = id;
         }   
         returnState[widget.name+"_options"] = widget.available_for;       
        })
        widgets.map((obj, idx) => {
          returnState['status-'+obj.id] = obj.status
        })
      }
  
      let sRoles = [];
      let sUsers = []

      sRoles = roles.map((obj, idx) => {
        return { value: idx+1, label: obj }
      })
      returnState.roles = sRoles

      sUsers = users.map((obj, id) => {
        return { value: obj.id, label: displayName(obj) }
      })

      returnState.users = sUsers
    
    }
    if (nextProps.savedWidgetList !== undefined  && prevState.widgetListTime != nextProps.widgetListTime)  {
      returnState.widgetListTime = nextProps.widgetListTime
    }
    return returnState;
  }

  handleActivateDeavtivate = (objIDVal, event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;  
    this.setState({
      ['status-' + objIDVal]: (!value) ? 1 : 0,  dashTableId: objIDVal,
    });
  }


  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value; 
    switch (target.type) {
      case 'checkbox':
      {
          value = target.checked;
          break;
      }
      default: 
        break;
    }
    this.setState({ [event.target.name]: value})   
  }

  handleSelectChange = (selectedValues, idx) => {
    let returnState = {};
    returnState["role_selected_"+idx] = selectedValues;
    this.setState(returnState);
  }

  handleSelectUsersChange = (selectedUsers, idx) => {
    let returnState = {};
    returnState["user_selected_"+idx] = selectedUsers;
    this.setState(returnState);
  }

  save = async (formData) => {
    try {
      this.setState({ showLoader: true });
      const res = await this.props.submitWidget(formData);
      uiNotification.success(this.state.globalLang[res.message] || "Successfully Updated");
      await this.props.fetchWidget(1);
    } catch (e) {
      uiNotification.error(this.state.globalLang[e.message] || "Unable To Update");
    } finally {
      this.setState({showLoader : false})
    }
  }

  handleSubmit = (event) => {
    let returnAPI = false
    if(isFormSubmit()) {
      event.preventDefault();
    
      this.setState({submitClicked:true})
      let dataToSend = [];
      this.state.dashboadWidgets.map((data, index) => {
    
      if(this.state[data.name + '_options'] == 'role' || this.state[data.name + '_options'] == 'user')
      {
        let internalIds = [];

        if( this.state[this.state[data.name+"_options"]+'_selected_'+ data.name] !== undefined){
        this.state[this.state[data.name+"_options"]+'_selected_'+ data.name].map((data2) => {
       
          if(this.state[data.name + '_options'] == 'role'){
           
          internalIds.push(data2.value);
          }else{
            internalIds.push(data2.value);
          }

        })
      }
        dataToSend.push({
          id: data.id,
          status:this.state['status-' + data.id],
          available_for: this.state[data.name + '_options'],
          associated_ids:internalIds
        })
      }
    
      else{
        dataToSend.push({
          id: data.id,
          status:this.state['status-' + data.id],
          available_for:"all",

        })
      }
    }) 

     let formData = {
       widgets_data: dataToSend
     }
     
   dataToSend.map((obj,  id)=>{
      if(obj.available_for == "role" || obj.available_for == "user"){
         if(obj.associated_ids.length ==0 || obj.associated_ids == undefined){
          returnAPI = true
           return;
         }
      }else if(obj.available_for == "role" || obj.available_for == "user") {
        if(obj.associated_ids.length > 0 || obj.associated_ids !== undefined){
          returnAPI = true
           
         }
      }
   })

    if (returnAPI == false) {
      this.save(formData);
    }
  }
  }

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="business-setion memberWalletOuter">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                Dashboard Widget Settings for Teammates
                  <div className="memberRightActions">
                  <button className="new-blue-btn pull-right edit_setting m-l-0" onClick={this.handleSubmit} >{"Save"}</button>
                </div>
              </div>
            </div>
            <div className="juvly-section full-width">

              <table className="table-updated setting-table no-td-border">
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-2 table-updated-th cursor-pointer sorting" >{"Widget Name"}</th>
                    <th className="col-xs-2 table-updated-th text-right p-r-20">{"Actions"}</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    this.state.dashboadWidgets !== undefined && this.state.dashboadWidgets.map((obj, idx) => {

                      return (
                        <tr key={idx}>
                          <td className="table-updated-td p20" colSpan="2" >
                            <div className="row">
                              <div className="col-xs-12">
                                {this.state.globalLang[obj.name] || WIDGET_LABELS[obj.name]}
                                <div className="setting-custom-switch product-active pull-right m-t-0">
                                  <label className="setting-switch pull-right">
                                    <input type="checkbox" name={"status-" + obj.id} className="setting-custom-switch-input" 
                                    checked={(!this.state['status-' + obj.id]) ? 'checked' : false} value={this.state['status-' + obj.id]} onChange={(e) => this.handleActivateDeavtivate(obj.id, e)} />
                                    <span className="setting-slider" />
                                  </label>
                                </div>
                              </div>
                            </div>
                            {this.state.dashboadWidgets &&
                              <div className={(!this.state['status-' + obj.id]) ? "row" : "no-display"} key={idx} >
                                <div className="col-xs-12 m-t-10">
                                  <div className="basic-checkbox-outer">
                                    <input className="basic-form-checkbox" name={obj.name+"_options"} type="radio" value="all" checked={this.state[obj.name+"_options"] == "all" }   onChange={this.handleInputChange} />
                                    <label className="basic-form-text" htmlFor="radio_button1">Enable for all</label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input id={idx} className="basic-form-checkbox" name={obj.name+"_options"}  type="radio" value="role" checked={this.state[obj.name+"_options"] == "role"} onChange={this.handleInputChange}  />
                                    <label className="basic-form-text" htmlFor="radio_button2">Enable by role</label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input id={idx} className="basic-form-checkbox" name={obj.name+"_options"}  type="radio" value="user" checked={this.state[obj.name+"_options"] == "user"} onChange={this.handleInputChange}  />
                                    <label className="basic-form-text" htmlFor="radio_button3">Enable for selected users</label>
                                  </div>
                                </div> 

                                <div className={(this.state[obj.name+"_options"] == 'role')  ?  "col-xs-12" : "no-display"}>
                                  <div className={this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name] && this.state[obj.name+"_options"] == 'role'
                                  && this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name].length == 0 || this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name] == null ? "newInputFileldOuter field_error"  : "newInputFileldOuter"}>
                                    <div className="newInputLabel">{"Select Roles"}</div>
                                    <div className="setting-input-outer">
                                      <div className="tag-auto-select">
                                        {
                                          this.state.roles && <Select
                                            onChange={(selectedValues)=>this.handleSelectChange(selectedValues, obj.name)}
                                            value={this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name]}
                                            isClearable
                                            isSearchable
                                            options={this.state.roles}
                                            isMulti={true}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={(this.state[obj.name+"_options"] == 'user') ?  "col-xs-12" : "no-display"}>
                                  <div className={( this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name] && this.state[obj.name+"_options"] == 'user') 
                                  && this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name].length == 0 || this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name] == null ? "newInputFileldOuter field_error" : "newInputFileldOuter"}>
                                    <div className="newInputLabel">{"Select Users"}</div>
                                    <div className="setting-input-outer">
                                      <div className="tag-auto-select">
                                  
                                        {
                                        
                                          this.state.users && <Select
                                            onChange={(selectedUsers)=>this.handleSelectUsersChange(selectedUsers, obj.name)}
                                            value={this.state[this.state[obj.name+"_options"]+'_selected_'+obj.name]}
                                            isClearable
                                            isSearchable
                                            options={this.state.users}
                                            isMulti={true}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};
  if (state.SettingReducer.action === "WIDGET_LIST") {
    if (state.SettingReducer.data.status != 200) {
      returnState.userChanged = false
    }
    else {
      returnState.widgetList = state.SettingReducer.data;
      returnState.widgetTime = new Date()
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchWidget, submitWidget, }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoles);
