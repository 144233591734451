import React, { Component } from "react";

class NetSales extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData
    ) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <div className="setting-setion m-b-15">
            <div className="table-responsive">
              <table className="table-updated sale-table no-td-border no-hover">
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-6 table-updated-th">
                      {this.state.languageData.bi_clinic_name}
                    </th>
                    <th className="col-xs-6 table-updated-th">
                      {this.state.languageData.bi_net_sales}
                    </th>
                  </tr>
                </thead>
                <tbody className="ajax_body">
                  {this.state.reportData &&
                    this.state.reportData.clinic_net_sales &&
                    this.state.reportData.clinic_net_sales.map((obj, idx) => {
                      return (
                        <tr className="table-updated-tr" key={idx}>
                          <td className="col-xs-6 table-updated-td">
                            {obj.clinic_name}
                          </td>
                          <td className="col-xs-6 table-updated-td">
                            {obj.gross_sales}
                          </td>
                        </tr>
                      );
                    })}

                  {this.state.reportData &&
                    this.state.reportData.clinic_net_sales &&
                    this.state.reportData.clinic_net_sales.length == 0 && (
                      <tr className="table-updated-tr">
                        <td
                          className="table-updated-td no-record no-float"
                          colSpan={2}
                        >
                          {this.state.languageData.bi_no_record_found}
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-6 memberProgram-right">
          <div className="setting-setion m-b-15">
            <div className="table-responsive">
              <table className="table-updated sale-table no-td-border no-hover">
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-6 table-updated-th">
                      {this.state.languageData.bi_provider_name}
                    </th>
                    <th className="col-xs-6 table-updated-th">
                      {this.state.languageData.bi_net_sales}
                    </th>
                  </tr>
                </thead>
                <tbody className="ajax_body">
                  {this.state.reportData &&
                    this.state.reportData.provider_net_sales &&
                    this.state.reportData.provider_net_sales.map((obj, idx) => {
                      return (
                        <tr className="table-updated-tr" key={idx}>
                          <td className="col-xs-6 table-updated-td">
                            {obj.provider}
                          </td>
                          <td className="col-xs-6 table-updated-td">
                            {obj.gross_sales}
                          </td>
                        </tr>
                      );
                    })}

                  {this.state.reportData &&
                    this.state.reportData.provider_net_sales &&
                    this.state.reportData.provider_net_sales.length == 0 && (
                      <tr className="table-updated-tr">
                        <td
                          className="table-updated-td no-record no-float"
                          colSpan={2}
                        >
                          {this.state.languageData.bi_no_record_found}
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NetSales;
