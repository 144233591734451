import React from "react";
import { Modal } from "../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./ModalQuestionBuilder.module.scss";
import { Footer } from "./shared/Footer/Footer";

export function ModalQuestionBuilder({
  isOpen,
  onClose,
  isEditMode,
  onSubmit,
  onReset,
  error,
  children,
}) {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      header={
        <Modal.Title>
          {isEditMode
            ? tSettings("questionnaires.questionBuilder.titleEdit")
            : tSettings("questionnaires.questionBuilder.titleAdd")}
        </Modal.Title>
      }
      footer={
        <Footer
          submit={onSubmit}
          reset={onReset}
          isEditMode={isEditMode}
          error={error}
        />
      }
    >
      {children}
    </Modal>
  );
}
