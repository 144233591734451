const initialState = {
  data: null,
  error: null,
  status: "pending",
};

const AspireHcpFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ASPIRE_HCP_FILTERS_LOADING":
      return { ...initialState, status: "loading" };
    case "FETCH_ASPIRE_HCP_FILTERS_SUCCESS":
      return { ...initialState, status: "success", data: action.payload };
    case "FETCH_ASPIRE_HCP_FILTERS_ERROR":
      return { ...initialState, status: "error", error: action.payload };
    default:
      return state;
  }
};

export default AspireHcpFiltersReducer;
