import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { useForm } from "./hooks/useForm";
import classes from "./CancelPlanModal.module.scss";
import { Radio } from "../../../../../../shared/Radio/Radio";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import { Button } from "../../../../../../shared/Button/Button";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { REASON_TYPES } from "./CancelPlanModal.consts";
import { getReasonMessage } from "./CancelPlanModal.utils";

export function CancelPlanModal({ isOpen, onClose, onSubmit }) {
  const handleSubmit = (values) => {
    onSubmit({
      reasonType: values.reasonType,
      message: getReasonMessage(values),
    });
  };

  const { reasonType, keyFeatureMessage, otherMessage, submit } = useForm({
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      headerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Title>We're sorry to see you go!</Modal.Title>}
    >
      <b>Please tell us why you're canceling:</b>
      <div className={classes.reasons}>
        <div>
          <Radio
            name="reasonType"
            label="System missing a key feature that I need"
            onChange={() => reasonType.onChange(REASON_TYPES.missingKeyFeature)}
            isChecked={reasonType.value === REASON_TYPES.missingKeyFeature}
          />
          {reasonType.value === REASON_TYPES.missingKeyFeature && (
            <div className={classes.reasonAdornment}>
              <Textarea
                rows={3}
                value={keyFeatureMessage.value}
                onChange={(e) => keyFeatureMessage.onChange(e.target.value)}
                placeholder="What feature was missing?"
                isError={Boolean(keyFeatureMessage.error)}
              />
              {keyFeatureMessage.error && (
                <InputError>{keyFeatureMessage.error}</InputError>
              )}
            </div>
          )}
        </div>
        <Radio
          name="reasonType"
          label="Poor adoption to the system"
          onChange={() => reasonType.onChange(REASON_TYPES.poorAdoption)}
          isChecked={reasonType.value === REASON_TYPES.poorAdoption}
        />
        <Radio
          name="reasonType"
          label="Opening a new Aesthetic Record account"
          onChange={() => reasonType.onChange(REASON_TYPES.openingNewAccount)}
          isChecked={reasonType.value === REASON_TYPES.openingNewAccount}
        />
        <Radio
          name="reasonType"
          label="Merging with a current Aesthetic Record account"
          onChange={() => reasonType.onChange(REASON_TYPES.mergingWithAccount)}
          isChecked={reasonType.value === REASON_TYPES.mergingWithAccount}
        />
        <div>
          <Radio
            name="reasonType"
            label="Something else"
            onChange={() => reasonType.onChange(REASON_TYPES.other)}
            isChecked={reasonType.value === REASON_TYPES.other}
          />
          {reasonType.value === REASON_TYPES.other && (
            <div className={classes.reasonAdornment}>
              <Textarea
                rows={3}
                value={otherMessage.value}
                onChange={(e) => otherMessage.onChange(e.target.value)}
                isError={Boolean(otherMessage.error)}
              />
              {otherMessage.error && (
                <InputError>{otherMessage.error}</InputError>
              )}
            </div>
          )}
        </div>
      </div>
      {Boolean(reasonType.value) && (
        <div className={classes.footer}>
          <b>Are you sure you want to cancel?</b>
          <div className={classes.footerButtons}>
            <Button onClick={submit}>Cancel My Account</Button>
            <Button variant="outlined" onClick={onClose}>
              I don't want to cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}

CancelPlanModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
