import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clinicId: yup.number().nullable(),
  goalId: yup.number().nullable(),
});

const responseSchema = yup.object({
  id: yup.number().nullable(),
  clinicId: yup.number().nullable(),
  goal: yup.number().nullable(),
  year: yup.number().nullable(),
  month: yup.string().nullable(),
  clinics: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .required(),
  months: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
        isDisabled: yup.boolean().required(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  const data = res?.data?.data?.ClinicSaleGoals || {};
  const clinics = res?.data?.data?.all_clinics || [];
  const months = res?.data?.data?.available_months || [];

  return {
    id: data.id,
    clinicId: data.clinic_id,
    goal: data.goal,
    year: data.year,
    month: data.month,
    clinics: clinics?.map((clinic) => ({
      id: clinic.id,
      name: clinic.clinic_name,
    })),
    months: months?.map((month) => ({
      id: month.id,
      name: month.month_value,
      isDisabled: month.show === 1,
    })),
  };
};

const createSalesGoalQueryKey = (...args) => [QUERY_KEYS.salesGoal, ...args];

export function useSalesGoalQuery(payload, options = {}) {
  return useQuery(
    createSalesGoalQueryKey(payload?.clinicId, payload?.goalId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.post(HTTP_ENDPOINTS.getSalesGoal(), {
        clinic_id: req.clinicId || 0,
        goal_id: req.goalId || 0,
      });
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
