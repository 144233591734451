import cx from "clsx";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SketchField, Tools } from "react-sketch";
import classes from "./sass/membership.module.scss";
import { AppTranslation } from "../../i18n/useAppTranslation";

const ClientSignatureCanvas = ({ membershipSketchRef }) => {
  const { control, setValue } = useFormContext();

  const signatureDetails = useWatch({
    control,
    name: "signatureDetails",
  });

  const { isSignatureSaved, signature_preview } = signatureDetails;

  const handleResetClearMembershipSignature = () => {
    if (isSignatureSaved) {
      // Reset
      setValue("signatureDetails", { isSignatureSaved: false });
    } else {
      // Clear
      if (membershipSketchRef.current) {
        membershipSketchRef.current.clear();
        membershipSketchRef.current.setBackgroundFromDataUrl("");
      }
    }
  };

  return (
    <>
      <div
        className={cx(
          "membershipSign protected",
          classes.patientSignatureContainer,
        )}
      >
        <div className="newPopupSubTitle"><AppTranslation.Common path="label.patient" /> Signature</div>
        {!isSignatureSaved && (
          <div className="signature-box sig-div">
            <SketchField
              width="400px"
              ref={membershipSketchRef}
              height="200px"
              tool={Tools.Pencil}
              lineColor="black"
              lineWidth={6}
            />
          </div>
        )}
        {isSignatureSaved && (
          <div className="img-src" id="img-src">
            <div
              className="input-outer "
              style={{ background: "#fff none repeat scroll 0 0" }}
            >
              <img
                className=""
                src={signature_preview}
                alt="client signature"
              />
            </div>
          </div>
        )}
        <div className="pull-left">
          <button
            type="button"
            onClick={handleResetClearMembershipSignature}
            className={"new-white-btn no-margin"}
          >
            {isSignatureSaved ? "Reset" : "Clear"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ClientSignatureCanvas;
