import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import { authWorkspaceSchema, composeAuthWorkspace } from "../../schemas/auth";

const requestSchema = yup.object({
  wpuId: yup.number().required(),
  otp: yup.string().required(),
});

const responseSchema = yup.object({
  accessToken: yup.string().nullable(),
  workspaces: yup.array().of(authWorkspaceSchema).required(),
});

const composeResponse = (res) => {
  const tokens = unwrapOr(() => res.data.data.tokens, {});
  const workspaces = unwrapOr(() => res.data.data.workspaces, []);

  return {
    accessToken: tokens.web || null,
    workspaces: workspaces.map(composeAuthWorkspace),
  };
};

export function useVerify2FaOtpMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    const res = await http.post(HTTP_ENDPOINTS.postVerify2FaOtp(), {
      wpuId: req.wpuId,
      otp: req.otp,
    });
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
