import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./Notes.module.scss";
import { usePatientVitalNotesQuery } from "../../../../../../../api/patientVitals/usePatientVitalNotesQuery";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { patientVitals } from "../../../../../../../store/patients/vitals";
import { Modal } from "../../../../../../../shared/Modal/Modal";
import { Loading } from "../../../../../../../boxes/Loading/Loading";
import { Notes as WidgetNotes } from "../../../../../../../widgets/Notes/Notes";
import { useAddNote } from "./hooks/useAddNote";
import { useSaveNote } from "./hooks/useSaveNote";
import { useDeleteNote } from "./hooks/useDeleteNote";
import { sort } from "../../../../../../../utilities/general";

var { selectors } = patientVitals;

var mapStateToProps = (state) => ({
  mutationRecord: selectors.selectMutationRecord(state),
});

/**
 * @param {Object} param0
 * @param {number?} param0.notesCount
 * @param {number?} param0.vitalId
 * @param {() => void} param0.onOpen
 * @param {() => void} param0.onClose
 */
export const Notes = connect(mapStateToProps)(
  ({ notesCount, vitalId, onOpen, onClose, mutationRecord }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { tCommon } = useAppTranslation.Common();
    const { tClients } = useAppTranslation.Clients();

    const { data, isLoading } = usePatientVitalNotesQuery({
      payload: {
        vitalId,
      },
      options: {
        enabled: isModalOpen && Boolean(vitalId),
        onError: () => {
          uiNotification.error(tClients("vitals.error.fetchNotes"));
        },
      },
    });

    const addNote = useAddNote(vitalId);
    const saveNote = useSaveNote(vitalId);
    const deleteNote = useDeleteNote(vitalId);

    const onModalOpen = () => {
      setIsModalOpen(true);
      onOpen();
    };

    const onModalClose = () => {
      setIsModalOpen(false);
      onClose();
    };

    const onSave = ({ id, note }) => {
      if (id) {
        return saveNote.initiate({ id, note });
      }
      return addNote.initiate(note);
    };

    const notes = useMemo(() => {
      if (data) {
        return sort(
          data.map((note) => ({
            id: note.id,
            note: note.note,
            date: note.updated_at,
            author: note.modifier.full_name,
          })),
          (a, b) => b.id - a.id,
        );
      }
      return (mutationRecord?.notes || []).map((note) => ({
        id: note.id,
        note: note.note,
        date: note.date,
        author: note.author,
      }));
    }, [data, mutationRecord?.notes]);

    const loading = vitalId ? isLoading : false;

    return (
      <>
        <div
          data-vt_prevent_col_click
          role="button"
          tabIndex={0}
          className={classes.btn}
          onClick={onModalOpen}
          onKeyDown={onModalOpen}
        >
          {tCommon("label.notes")} ({notesCount || 0})
        </div>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            size="large"
            onClose={onModalClose}
            header={<Modal.Title>{tCommon("label.notes")}</Modal.Title>}
            contentClassName={classes.modalContent}
          >
            {loading ? (
              <Loading center size="small" className={classes.loader} />
            ) : (
              <WidgetNotes
                data={notes}
                isSaving={saveNote.isLoading || addNote.isLoading}
                isDeleting={deleteNote.isLoading}
                onSave={(values, { resetForm }) =>
                  onSave(values).then(resetForm)
                }
                onDelete={(id) => deleteNote.initiate(id)}
                notesClassName={classes.notes}
                formClassName={classes.form}
              />
            )}
          </Modal>
        )}
      </>
    );
  },
);
