import React from "react";
import cx from "clsx";
import PropTypes from "prop-types";
import "./CalendarEvent.styles.scss";
import { GfeIcon } from "../../../../assets/Icons/GfeIcon";
import styles from "./CalendarEvent.module.scss";

function CalendarEvent({ event: { confirmed_status, gfeOnFile } }) {
  return (
    <div className={cx("appointmentConfirmIconWrapper", styles.iconsWrapper)}>
      {confirmed_status === "Confirmed" && (
        <span className="confirmedIcon">
          <i className="fa fa-check-circle" />
        </span>
      )}
      {gfeOnFile === false && (
        <div className={styles.gfeWrapper}>
          <GfeIcon width="19px" height="20px" />
        </div>
      )}
    </div>
  );
}

CalendarEvent.propTypes = {
  confirmed_status: PropTypes.string,
};

CalendarEvent.defaultProps = {
  confirmed_status: "Not Confirmed",
};

export default CalendarEvent;
