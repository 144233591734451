import { matchPath } from "react-router";
import { PAYMENT_MODES } from "../../../consts/api";
import { tCommon, tSales } from "../../../i18n/useAppTranslation";
import { formatCurrency } from "../../../utilities/general";
import { SALES_ROUTES } from "../../../consts/routes";

export const prettifyPaymentInfo = (payment, currency) => {
  const amount = formatCurrency(payment.amount, currency);

  const paymentModeLabelByType = {
    [PAYMENT_MODES.careCredit]: tSales(
      "checkoutInvoice.paymentOptions.careCredit",
    ),
    [PAYMENT_MODES.cash]: tSales("checkoutInvoice.paymentOptions.cash"),
    [PAYMENT_MODES.cc]: tSales("checkoutInvoice.paymentOptions.card"),
    [PAYMENT_MODES.cheque]: tSales("checkoutInvoice.paymentOptions.check"),
    [PAYMENT_MODES.cherry]: tSales("checkoutInvoice.paymentOptions.cherry"),
    [PAYMENT_MODES.greenSky]: tSales("checkoutInvoice.paymentOptions.greenSky"),
    [PAYMENT_MODES.wallet]: tSales("checkoutInvoice.paymentOptions.arWallet"),
    [PAYMENT_MODES.klarna]: tSales("checkoutInvoice.paymentOptions.klarna"),
    [PAYMENT_MODES.giftCard]: tSales("checkoutInvoice.paymentOptions.giftCard"),
  };

  const label =
    payment.card.type === "klarna"
      ? paymentModeLabelByType[PAYMENT_MODES.klarna]
      : paymentModeLabelByType[payment.mode] || tCommon("label.unknown");

  const getSuffix = () => {
    switch (payment.mode) {
      case PAYMENT_MODES.cheque:
        return `#${payment.chequeNumber || tCommon("label.empty")}`;
      case PAYMENT_MODES.cc:
        if (payment.card.type === "klarna") {
          return tSales("checkoutInvoice.paymentOptions.klarna");
        }
        if (payment.card.type === "link") {
          return tSales("checkoutInvoice.paymentOptions.link");
        }
        return `(${payment.card.type} ${tSales(
          "checkoutInvoice.stageSuccess.history.cc",
          {
            ccNumber: payment.card.number,
          },
        )})`;
      case PAYMENT_MODES.giftCard: {
        let ending_in =
          payment?.gift_card?.formatted_redemption_code.split("-");
        return `(*${ending_in[ending_in.length - 1]})`;
      }
      default:
        return "";
    }
  };

  const composeModeLabel = {
    klarna: label,
    link: getSuffix(),
    default: `${label} ${getSuffix()}`,
  };
  return {
    modeLabel:
      composeModeLabel[payment.card.type] || composeModeLabel["default"],
    amount,
  };
};

export const extractUrlInvoiceId = () => {
  const match = matchPath(window.location.pathname, {
    path: SALES_ROUTES.checkoutInvoice(":id", ":type"),
    exact: true,
    strict: false,
  });

  return Number(match.params.id);
};

/**
 * @param {string | number} amount
 * @returns {number}
 */
export const prepareAmount = (amount) => {
  const res = parseFloat(amount || 0);
  if (isNaN(res)) {
    throw new Error("Invalid amount value");
  }
  return parseFloat(res.toFixed(2));
};

/**
 * @param {{
 *   tipOptionValue: string | number;
 *   beforeTipAmount: number;
 * }} param0
 * @returns {number}
 */
export const tipOptionToAmount = ({ tipOptionValue, subtotalAmount }) => {
  const parsedValue = parseFloat(tipOptionValue);
  if (isNaN(parsedValue) || typeof subtotalAmount !== "number") {
    return 0;
  }
  return parseFloat((subtotalAmount * parsedValue).toFixed(2));
};
