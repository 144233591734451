import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { http } from "../../../../../../services/HttpService";

const getEfaxText = async (params) => {
  return await http.get(HTTP_ENDPOINTS.getEfaxText(), { params });
};

export const useEfaxText = (params, options = {}) => {
  return useQuery(["efaxText"], () => getEfaxText(params), options);
};
