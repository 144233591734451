/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { withRouter } from "react-router";
import AppointmentConfigNavbar from "./AppointmentConfigNavbar.js";
import CancellationPolicy from "./CancellationPolicy/CancellationPolicy.js";
import PatientPortal from "./PatientPortal/PatientPortal.js";
import AppointmentReminder from "./PatientAppointmentReminders/AppointmentReminder.js";
import { withQueryParamsState } from "../../utilities/hooks/useQueryParamsState.js";

const TAB_TYPES = {
  cancellationPolicy: "cancellationPolicy",
  patientPortalAccess: "clientPortalAccess",
  apptReminder: "appointmentReminder",
};

class OtherSettings extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const langData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      mode: null,
      CancellationPolicy: CancellationPolicy,
      PatientPortal: PatientPortal,
      AppointmentReminder: AppointmentReminder,
      userData: userData,
      langData: langData,
    };
  }

  componentDidMount() {
    const tab = this.props.queryParamsState?.t;
    if (!Object.values(TAB_TYPES).includes(tab)) {
      this.changeTab(TAB_TYPES.equipment);
    }
  }

  changeTab = (type) => {
    this.setState({ mode: null });
    this.props.setQueryParamsState({
      t: type,
    });
  };

  openReminderList = () => {
    this.changeTab(TAB_TYPES.apptReminder);
    this.setState({
      reminderId: undefined,
    });
  };

  openCreateAppointmentReminder = (id) => {
    import("./PatientAppointmentReminders/CreateEditReminder.js").then(
      (component) => {
        let returnState = {};
        returnState.CreateEditReminder = component.default;
        returnState.mode = "CreateEditReminder";
        returnState.reminderId = id ? id : undefined;
        this.setState(returnState);
      },
    );
  };

  render() {
    const {
      CancellationPolicy,
      PatientPortal,
      AppointmentReminder,
      CreateEditReminder,
      reminderId,
    } = this.state;

    const tab = this.props.queryParamsState?.t;

    return (
      <div id="content">
        <div className="container-fluid content">
          <AppointmentConfigNavbar activeSettings={true} />
          <div className="setting-setion full-width">
            <div className="p20">
              <ul className="appointment-tabs">
                <li>
                  <a
                    className={
                      tab === TAB_TYPES.cancellationPolicy ? "active" : ""
                    }
                    onClick={() => this.changeTab(TAB_TYPES.cancellationPolicy)}
                  >
                    {
                      this.state.langData["appointments"][
                        "app-cancellation-policy"
                      ]
                    }
                  </a>
                </li>
                <li>
                  <a
                    className={
                      tab === TAB_TYPES.patientPortalAccess ? "active" : ""
                    }
                    onClick={() =>
                      this.changeTab(TAB_TYPES.patientPortalAccess)
                    }
                  >
                    {this.state.langData["appointments"]["app-client-portal"]}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      tab === TAB_TYPES.apptReminder ||
                      this.state.mode == "CreateEditReminder"
                        ? "active"
                        : ""
                    }
                    onClick={() => this.changeTab(TAB_TYPES.apptReminder)}
                  >
                    {
                      this.state.langData["appointments"][
                        "app-appointment-reminder"
                      ]
                    }
                  </a>
                </li>
              </ul>
            </div>
            <div className="p20 p-t-0">
              {tab === TAB_TYPES.cancellationPolicy &&
                this.state.mode === null && <CancellationPolicy />}
            </div>
            <div className="p20 p-t-0">
              {tab === TAB_TYPES.patientPortalAccess &&
                this.state.mode === null && <PatientPortal />}
            </div>
            {tab === TAB_TYPES.apptReminder && this.state.mode === null && (
              <AppointmentReminder
                openCreateAppointmentReminder={
                  this.openCreateAppointmentReminder
                }
              />
            )}
            {this.state.mode == "CreateEditReminder" && (
              <CreateEditReminder
                reminderId={reminderId}
                openReminderList={this.openReminderList}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withQueryParamsState({
    t: TAB_TYPES.cancellationPolicy,
  })(OtherSettings),
);
