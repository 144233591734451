import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./AlertBox.module.scss";

export const ALERT_BOX_VARIANTS = {
  warn: "warn",
  success: "success",
  error: "error",
};

const SIZES = {
  small: "small",
};

export function AlertBox({
  className,
  children,
  variant,
  size,
  fitContent,
  center,
}) {
  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.warn]: variant === ALERT_BOX_VARIANTS.warn,
          [classes.success]: variant === ALERT_BOX_VARIANTS.success,
          [classes.error]: variant === ALERT_BOX_VARIANTS.error,
          [classes.sSmall]: size === SIZES.small,
          [classes.rootFitContent]: fitContent,
          [classes.center]: center,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

AlertBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(ALERT_BOX_VARIANTS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  fitContent: PropTypes.bool,
  center: PropTypes.bool,
};

AlertBox.defaultProps = {
  className: undefined,
  variant: ALERT_BOX_VARIANTS.warn,
  size: undefined,
  fitContent: false,
};
