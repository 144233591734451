import React from "react";

const HoldMembershipIcon = () => {
  return (
    <svg width="25" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 14.625v-6.25m4.5 6.25v-6.25m9 3.125c0 5.753-5.037 10.417-11.25 10.417S1.5 17.253 1.5 11.5 6.537 1.083 12.75 1.083 24 5.747 24 11.5Z"
        stroke="#188BF6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HoldMembershipIcon;
