import { getLanguageData } from "../../../../utilities/localStorage";

export const getLabel = () => {
  const languageData = getLanguageData("global");
  if (languageData && "header_clients" in languageData) {
    const label = languageData["header_clients"];
    if (
      typeof label === "string" &&
      label.length > 1 &&
      label[label.length - 1].toLowerCase() === "s"
    ) {
      return label.slice(0, -1);
    }
  }
  return "Client";
};
