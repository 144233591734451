import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { QUERY_KEYS } from "../../../../../../../consts/api";
import { useSetUsersObOrderMutation } from "../../../../../../../api/mutations/useSetUsersObOrderMutation";

export function useSaveOrder() {
  const queryClient = useQueryClient();
  const { tSettings } = useAppTranslation.Settings();

  const { isLoading, mutate } = useSetUsersObOrderMutation({
    onSuccess: () => {
      uiNotification.success(
        tSettings("users.obOrder.success.update"),
      );
      queryClient.invalidateQueries([QUERY_KEYS.usersObOrder]);
    },
    onError: () => {
      uiNotification.error(tSettings("users.obOrder.error.update"));
    },
  });

  const save = ({ selectedClinic, orderedUsers }) => {
    mutate({
      clinicId: selectedClinic.value,
      userIds: orderedUsers.map((p) => p.id),
    });
  };

  return {
    save,
    isSaving: isLoading,
  };
}
