import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { LayoutGuest } from "../../boxes/LayoutGuest/LayoutGuest";
import { authService } from "../../services/AuthService";
import classes from "./Login.module.scss";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { Input } from "../../shared/Input/Input";
import { useFormTwoFactorLogin } from "./hooks/useFormTwoFactorLogin";
import { TWO_FACTOR_TYPES } from "../../consts/api";
import { InputError } from "../../shared/InputError/InputError";
import { Button } from "../../shared/Button/Button";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import { useTwoFactorLogin } from "./hooks/useTwoFactorLogin";

function TwoFactorLogin({ history }) {
  const twoFactorType = authService.getPreLoginData().twoFactorType;

  const { tCommon } = useAppTranslation.Common();
  const { tAuth } = useAppTranslation.Auth();
  const { verify, isVerifying } = useTwoFactorLogin();

  const {
    form,
    isFormValid,
    onFormValueBlur,
    onFormValueChange,
    submit,
    hasError,
    errors,
  } = useFormTwoFactorLogin({ onSubmit: verify });

  const codePlaceholderByType = {
    [TWO_FACTOR_TYPES.email]: tAuth("label.enterOtp"),
    [TWO_FACTOR_TYPES.sms]: tAuth("label.enterOtp"),
    [TWO_FACTOR_TYPES.google]: tAuth("label.enterToken"),
  };

  useEffect(() => {
    if (!twoFactorType) {
      history.goBack();
    }
  }, [history]);

  return (
    <LayoutGuest>
      <div className={classes.root}>
        <h1 className={classes.header}>{tAuth("twoFactor.header")}</h1>
        <div className={classes.form}>
          <div>
            <Input
              autoFocus
              type="code"
              name="code"
              value={form.code}
              onChange={onFormValueChange}
              onBlur={onFormValueBlur}
              isError={hasError("code")}
              isDisabled={isVerifying}
              placeholder={codePlaceholderByType[twoFactorType]}
            />
            {hasError("code") && <InputError>{errors.code}</InputError>}
          </div>
          <Button
            fullWidth
            onClick={submit}
            isDisabled={!isFormValid || isVerifying}
            leftAdornment={
              isVerifying ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {tCommon("label.continue")}
          </Button>
        </div>
      </div>
    </LayoutGuest>
  );
}

TwoFactorLogin.propTypes = {
  history: PropTypes.object,
};

export default withRouter(TwoFactorLogin);
