import { getIsAcceleratorPlan } from "./services.js";

export function acceleratorInsightsSales() {
  return [
    {
      label: "Treatment Plans",
      slug: "treatment_plans",
    },
    {
      label: "Staff Treatment",
      slug: "staff_treatments",
    },
  ];
}

export function acceleratorInsightsClients() {
  return [
    {
      label: "Patient Referral Report",
      slug: "patient-referral-report",
    },
    {
      label: "Average Transaction Size",
      slug: "average-transaction-size",
    },
    {
      label: "Average # of Visits per month",
      slug: "average-number-of-visits-per-year",
    },
    {
      label: "Top 20% VIP patients who spend the most with our practice",
      slug: "top-20-percent-vip-patients-who-spend-the-most-with-our-practice",
    },
    {
      label: "Patients who spent $X in Y Months",
      slug: "patients-who-spent-$x-in-y-Months",
    },
    {
      label: "Client has had X and not Y",
      slug: "had-x-and-not-y",
    },
    {
      label: "Client has not visited in X days for X service",
      slug: "not-visited-in-x-days-for-x-service",
    },
    {
      label: "Client has had X done in X time",
      slug: "x-done-in-x-time",
    },
  ];
}

export function acceleratorInsightsInventory() {
  return [
    {
      label: "Product Usage Per Provider",
      slug: "product-usage-per-provider",
    },
    {
      label: "Lot Number",
      slug: "lot-number",
    },
    {
      label: "Previous year vs Current year",
      slug: "previous-year-vs-current-year",
    },
    {
      label: "Inventory Turn",
      slug: "inventory-turn",
    },
  ];
}

export function acceleratorInsightsAppointments() {
  return [
    {
      label: "New booking capacity reports",
      slug: "new-booking-capacity-reports",
    },
    {
      label: "Booked Appointments report per clinic",
      slug: "booked-appointments-per-clinic",
    },
    {
      label: "Booked Appointments report per provider",
      slug: "booked-appointments-per-provider",
    },
  ];
}

export function acceleratorInsightsReputation() {
  return [
    {
      label: "% Surveys Returned",
      slug: "percentage-surveys-returned",
    },
    {
      label: "NPS Score per Service",
      slug: "nps-score-per-service",
    },
    {
      label: "Patients who gave the Rating of X or below",
      slug: "patients-who-gave-the-rate-of-x-or-below",
    },
  ];
}

export function acceleratorInsightsStaff() {
  return [
    {
      label: "Staff Treatment Tracking",
      slug: "staff-treatment-tracking",
    },
    {
      label: "Average Sale Per Invoice/Appointment",
      slug: "average-sale-per-appointment",
    },
  ];
}

export function isRestrictedReport(list, slug) {
  list = list ? list : [];
  slug = slug ? slug : "";

  let isRestrictedPrivilege = false;
  let restrictedPrivilege = list.find((x) => x.slug == slug);

  if (restrictedPrivilege) {
    if (!getIsAcceleratorPlan()) {
      isRestrictedPrivilege = true;
    }
  }

  return isRestrictedPrivilege;
}
