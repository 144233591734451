import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import cx from "clsx";
import classes from "./FileDropzone.module.scss";
import { svg } from "../../assets/svg";
import {
  filterFiles,
  handleExtensionMismatchedFiles,
} from "./FileDropzone.utils";
import uuid from "../../Utils/uuid";
import { File } from "./components/File/File";

const VARIANTS = {
  default: "default",
  circle: "circle",
};

export function FileDropzone({
  onDrop,
  onError,
  className,
  text: _text,
  dragActiveText,
  multiple,
  supportedExtensions,
  selectedFiles,
  onRemoveFile,
  variant,
  isDisabled,
  components,
}) {
  const onDropInternal = (files) => {
    try {
      const { filteredFiles, extensionMismatchedFiles } = filterFiles(
        files,
        supportedExtensions,
      );
      handleExtensionMismatchedFiles(extensionMismatchedFiles, {
        onError: multiple ? onError : undefined,
        supportedExtensions,
      });
      if (filteredFiles.length > 0) {
        onDrop(
          filteredFiles.map((file) => ({
            id: uuid(),
            file,
          })),
        );
      }
    } catch (e) {
      onError(e.message);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropInternal,
    multiple,
  });

  const dropZoneClasses = cx(classes.dropZone, {
    [classes.dragActive]: isDragActive,
    [classes.vDefault]: variant === VARIANTS.default,
    [classes.vCircle]: variant === VARIANTS.circle,
    [classes.disabled]: isDisabled,
  });

  const { text, dragActive } = (() => {
    const extensions = Object.values(supportedExtensions).join(", ");
    const files = multiple ? "Files" : "File";
    return {
      text:
        supportedExtensions.length > 0
          ? `Select or Drop ${files} (${extensions})`
          : `Select or Drop ${files}`,
      dragActive: `Drop ${files} Here...`,
    };
  })();

  return (
    <div className={className}>
      <div {...getRootProps()} title={text} className={dropZoneClasses}>
        <input {...getInputProps()} disabled={isDisabled} />
        {variant === "default" ? (
          <div className={classes.defaultTextWrap}>
            {components.loader && (
              <div className={classes.loaderDefault}>{components.loader}</div>
            )}
            {isDragActive && !isDisabled
              ? dragActiveText || dragActive
              : _text || text}
          </div>
        ) : (
          components.loader || (
            <>
              <img src={svg.addFileGray1} className={classes.addFile} alt="+" />
              <img
                src={svg.addFilePrimary}
                className={classes.addFileActive}
                alt="+"
              />
            </>
          )
        )}
      </div>
      {selectedFiles.length > 0 && (
        <div className={classes.selectedFiles}>
          {selectedFiles.map(({ file, id }) => (
            <File
              key={id}
              name={file.name}
              isDisabled={isDisabled}
              onRemove={() => onRemoveFile(id)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

FileDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onError: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  dragActiveText: PropTypes.string,
  multiple: PropTypes.bool,
  supportedExtensions: PropTypes.arrayOf(PropTypes.string),
  selectedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      file: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  onRemoveFile: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  isDisabled: PropTypes.bool,
  components: PropTypes.shape({
    loader: PropTypes.node,
  }),
};

FileDropzone.defaultProps = {
  onError: () => {},
  className: undefined,
  text: undefined,
  dragActiveText: undefined,
  multiple: true,
  supportedExtensions: [],
  selectedFiles: [],
  onRemoveFile: () => {},
  variant: VARIANTS.default,
  isDisabled: false,
  components: {
    loader: null,
  },
};
