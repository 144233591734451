import React from "react";
import PropTypes from "prop-types";
import classes from "./Node.module.scss";
import { Select } from "../../../../../../../shared/Select/Select";
import { Button } from "../../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";

export function Node({ rows, onRemove }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      {rows.map((row, index) => (
        <div key={`${row.label}${index}`} className={classes.row}>
          <div className={classes.label}>{row.label}:</div>
          <Select
            isClearable
            size="small"
            value={row.value}
            options={row.options}
            onChange={row.onChange}
          />
        </div>
      ))}
      {onRemove && (
        <div className={classes.footer}>
          <Button size="small" variant="text" color="error" onClick={onRemove}>
            {tCommon("label.remove")}
          </Button>
        </div>
      )}
    </div>
  );
}

Node.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.any,
        }),
      ),
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
    }),
  ),
  onRemove: PropTypes.func,
};
