import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";
import { Match } from "../../../utilities/fp";

const requestSchema = yup.object({
  procedureId: yup.number().required(),
  consentIds: yup.array().of(yup.number()).required(),
  mark: yup.boolean().required(),
});

export function usePatientConsentsMarkOnFileMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      const endpoint = Match.of(req.mark)
        .on(true, () => HTTP_ENDPOINTS.patientConsents.markOnFile())
        .on(false, () => HTTP_ENDPOINTS.patientConsents.unmarkOnFile())
        .or(null)
        .join();

      return http.post(endpoint, {
        procedure_id: req.procedureId,
        patient_consent_ids: req.consentIds,
      });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientConsents.getByEntity],
        });
        options?.onSuccess?.();
      },
    },
  );
}
