import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import PropTypes from "prop-types";
import { Box } from "../../../../../../shared/Box/Box";
import classes from "./MessagesList.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Chat } from "../../../../../../shared/Chat/Chat";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { THEME } from "../../../../../../consts/theme";

export function MessagesList({ messages, isLoading }) {
  const { tInbox } = useAppTranslation.Inbox();
  const { data: user } = useCurrentUserQuery();

  return isLoading ? (
    <SkeletonTheme baseColor={THEME.colors.white}>
      <Skeleton count={1} height={600} containerClassName={classes.root} />
    </SkeletonTheme>
  ) : (
    <Box noPadding className={classes.root}>
      <Chat
        className={classes.chat}
        messages={messages}
        dateFormat={user?.previewDateFormat}
        timeFormat={user?.timeFormat}
        header={
          <div className={classes.header}>
            {tInbox("leadAr.messages.title")}
          </div>
        }
      />
    </Box>
  );
}

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
