import React from "react";
import cx from "clsx";
import { Select } from "../../../shared/Select/Select";
import classes from "./toolbarselects.module.scss";

const ToolbarSelects = ({
  toolbarSelects,
  setChoiceFilters,
  choiceFilters,
  setPage,
  initialPageFilters,
  updateFilters,
  defaultSort,
  search,
}) => {
  return (
    <div className={classes.choiceBoxes}>
      {toolbarSelects.map((item) => (
        <div className={cx(classes.simpleField, "simpleField")} key={item.key}>
          <div className={cx(classes.simpleLabel, "simpleLabel")}>
            {item.label}
          </div>
          <Select
            isDisabled={item.isDisabled}
            placeholder={item.label}
            value={item.options.find(
              (i) => i.value === choiceFilters[item.key],
            )}
            onChange={(nextOption) => {
              const filters = {
                ...initialPageFilters,
                [item.key]: nextOption.value,
                sort_by: defaultSort[nextOption.value]
                  ? defaultSort[nextOption.value]
                  : initialPageFilters.sort_by,
              };
              setChoiceFilters({
                ...filters,
              });
              setPage(1);
              updateFilters &&
                updateFilters({
                  ...filters,
                  page: 1,
                  search,
                });
            }}
            options={item.options}
          />
        </div>
      ))}
    </div>
  );
};

export default ToolbarSelects;
