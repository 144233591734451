import React, { useState } from "react";
import PropTypes from "prop-types";
import { ButtonMenu } from "../../../../../shared/ButtonMenu/ButtonMenu";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { useDeleteInvoiceMutation } from "../../../../../api/mutations/useDeleteInvoiceMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { svg } from "../../../../../assets/svg";
import { extractApiError } from "../../../../../utilities/api";
import { usePermission } from "../../../../../hooks/usePermission";
import { PERMISSIONS } from "../../../../../consts/api";

export function DraftInvoiceActions({
  invoiceId,
  patientId,
  onCheckout,
  onDeleteSuccess,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const viewInvoicesPermission = usePermission(PERMISSIONS.viewSalesInvoices);

  const deleteInvoice = useDeleteInvoiceMutation({
    onError: (e) => {
      const errorKey = extractApiError(e);
      uiNotification.error(
        tCommon([`apiError.deleteInvoice.${errorKey}`, "error.deleteInvoice"]),
      );
    },
    onSuccess: () => {
      onDeleteSuccess();
      uiNotification.success(tCommon("success.deleteInvoice"));
    },
  });

  const onDelete = () => {
    if (invoiceId && patientId) {
      setIsConfirmModalOpen(false);
      deleteInvoice.mutate({
        invoiceId: Number(invoiceId),
        patientId: Number(patientId),
      });
    }
  };

  const canViewInvoices =
    viewInvoicesPermission.loaded && viewInvoicesPermission.permitted;

  return canViewInvoices ? (
    <div>
      <ButtonMenu
        options={[
          {
            key: "checkout",
            label: tCommon("label.checkout"),
            onClick: onCheckout,
          },
          {
            key: "delete",
            label: tCommon("label.delete"),
            onClick: () => setIsConfirmModalOpen(true),
          },
        ]}
        renderButton={({ onToggleMenu, isMenuOpen }) => (
          <Button
            size="small"
            variant="outlined"
            onClick={onToggleMenu}
            isHovered={isMenuOpen}
            isDisabled={deleteInvoice.isLoading}
            rightAdornment={
              deleteInvoice.isLoading ? (
                <CircularProgress size="tiny" />
              ) : (
                <img src={svg.chevronDownPrimary} alt="" width="16px" />
              )
            }
          >
            {tCommon("label.action")}
          </Button>
        )}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={onDelete}
      >
        {tCommon("confirm.deleteInvoice")}
      </ConfirmModal>
    </div>
  ) : null;
}

DraftInvoiceActions.propTypes = {
  invoiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onCheckout: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
};

DraftInvoiceActions.defaultProps = {
  invoiceId: null,
  patientId: null,
};
