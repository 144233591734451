import React from "react";
import { Checkbox } from "../../../../../../../../../shared/Checkbox/Checkbox";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { QuestionChoices } from "../../../../../../boxes/QuestionChoices/QuestionChoices";
import { passOr } from "../../../../../../../../../utilities/general";

export function MultiImage({
  isMultipleSelection,
  isDisplayLabels,
  imageChoices,
}) {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <>
      <Checkbox
        label={tSettings("questionnaires.questionBuilder.multiSelection")}
        isChecked={isMultipleSelection.value}
        onChange={isMultipleSelection.toggle}
      />
      <Checkbox
        label={tSettings("questionnaires.questionBuilder.displayLabels")}
        isChecked={isDisplayLabels.value}
        onChange={isDisplayLabels.toggle}
      />
      <QuestionChoices
        choices={imageChoices.value}
        onAdd={imageChoices.add}
        onSortEnd={imageChoices.replace}
      >
        {(choice, index) => (
          <QuestionChoices.Image
            key={index}
            index={index}
            label={choice.label}
            image={choice.image}
            onChangeLabel={(next) =>
              imageChoices.changeByIndex({
                key: "label",
                value: next,
                index,
              })
            }
            onChangeImage={(next) =>
              imageChoices.changeByIndex({
                key: "image",
                value: next,
                index,
              })
            }
            onRemove={passOr(
              index !== 0,
              undefined,
              () => () => imageChoices.deleteByIndex(index),
            )}
          />
        )}
      </QuestionChoices>
    </>
  );
}
