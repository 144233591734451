import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

export const useDefaultDocumentExpirationDateQuery = (options = {}) => {
  const composeResponse = (data) => {
    return {
      data: data.data.data,
      message: data.data.message,
    };
  };
  return useQuery(
    [QUERY_KEYS.defaultDocumentExpirationDate],
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.getDefaultDocumentExpirationDate(),
      );
      return composeResponse(res);
    },
    options,
  );
};
