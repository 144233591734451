import { useMemo } from "react";
import { useClientsQuery } from "../../../../../../api/queries/useClientsQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useDebouncedState } from "../../../../../../utilities/hooks/useDebouncedState";

export const usePatientOptions = () => {
  const { tInbox } = useAppTranslation.Inbox();
  const [_, debouncedSearch, setSearch] = useDebouncedState("");

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useClientsQuery({
      payload: {
        pageSize: 50,
        searchTerm: debouncedSearch,
        sortBy: "firstname",
        sortOrder: "asc",
        context: "efax",
      },
      options: {
        onError: () => {
          uiNotification.error(tInbox("efaxOrders.error.fetchPatients"));
        },
      },
    });

  const options = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.clients)
        .reduce((r, p) => [...r, ...p], [])
        .map((c) => ({
          label: c.fullName,
          value: c.id,
        }));
    }
    return [];
  }, [data?.pages]);

  return {
    value: options,
    isLoading,
    hasMore: Boolean(hasNextPage),
    isNextFetching: isFetchingNextPage,
    fetchMore: fetchNextPage,
    search: {
      onChange: setSearch,
    },
  };
};
