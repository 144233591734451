import { useMemo } from "react";
import { useDiscountPackagesObOrderQuery } from "../../../../../../../../api/queries/useDiscountPackagesObOrderQuery";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";

export function usePackages(clinicId) {
  const { tSettings } = useAppTranslation.Settings();

  const { data, isLoading } = useDiscountPackagesObOrderQuery({
    payload: {
      clinicId: clinicId,
    },
    options: {
      enabled: Boolean(clinicId),
      onError: () => {
        uiNotification.error(tSettings("discountPackages.obOrder.error.fetch"));
      },
    },
  });

  const packages = useMemo(
    () =>
      data?.map((i) => ({
        id: i.id,
        content: i.name,
      })) || [],
    [data],
  );

  return {
    packages,
    isPackagesLoading: isLoading,
  };
}
