import React from "react";
import { Header } from "../../shared/Header/Header";
import { Table } from "./shared/Table/Table";
import { useReport } from "./hooks/useReport";
import { useDownload } from "./hooks/useDownload";
import { ReportStats } from "../../../../../../boxes/ReportStats/ReportStats";
import styles from "./styles.module.scss";
import { not } from "../../../../../../utilities/fp";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function SmsUsage() {
  const { tSettings } = useAppTranslation.Settings();
  const [isOpen, setIsOpen] = React.useState(false);
  const { reports, period, stats } = useReport();

  const download = useDownload({
    period,
  });

  return (
    <div className={styles.root}>
      <Header
        title={tSettings("smsUsageLog.title")}
        period={period}
        download={download}
        isLoading={reports.isLoading}
        collapse={{
          active: isOpen,
          toggle: () => setIsOpen(not),
        }}
      />
      {isOpen && (
        <>
          <ReportStats isLoading={reports.isLoading} data={stats} />
          <Table data={reports} />
        </>
      )}
    </div>
  );
}
