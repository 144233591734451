import React from "react";
import styles from "./styles.module.scss";
import { useCurrentAccountQuery } from "../../../../../../../../api/queries/useAccountQuery";
import { isNullish } from "../../../../../../../../utilities/general";

export function PerUnitPrice({ children }) {
  const { data: account } = useCurrentAccountQuery();

  return (
    <div className={styles.root}>
      <div className={styles.label}>Product Price Per Unit</div>
      {!isNullish(children) && children !== "" ? (
        <div>
          {account?.globalSettings?.currencySymbol} <b>{children}</b>
        </div>
      ) : null}
    </div>
  );
}
