import React from "react";
import { Button } from "../../../shared/Button/Button";
import { useExportLeadsMutation } from "../../../api/mutations/useExportLeadsMutation";
import { uiNotification } from "../../../services/UINotificationService";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { composeDownloadDataUrl } from "../../../utilities/api";

export const ButtonDownloadExcel = ({
  page,
  sortBy,
  order,
  filters,
  period,
}) => {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useExportLeadsMutation({
    onError: () => {
      uiNotification.error(tCommon("error.export"));
    },
  });

  const initiate = () => {
    mutateAsync({
      page,
      sort_by: sortBy,
      sort_dir: order,
      opt_it: filters?.opt_in,
      clinics: filters?.clinics,
      source: filters?.source,
      letter_key: filters?.letter_key,
      term: filters?.term,
      from_date: period?.from,
      to_date: period?.to,
    }).then((res) => {
      window.open(composeDownloadDataUrl(res.fileName), "_blank");
    });
  };

  return (
    <Button
      size="small"
      className="m-r-5"
      isDisabled={isLoading}
      onClick={initiate}
      leftAdornment={
        isLoading ? <CircularProgress size="small" color="white" /> : undefined
      }
    >
      {tCommon("label.downloadExcel")}
    </Button>
  );
};
