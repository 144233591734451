import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Checkbox } from "../../../../../shared/Checkbox/Checkbox";
import { Button } from "../../../../../shared/Button/Button";
import classes from "./Table.module.scss";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { PatientNames } from "./shared/PatientNames/PatientNames";

export function Table({ blackList, selectedItems, onRemoveOne, removingIds }) {
  const { tInbox } = useAppTranslation.Inbox();
  const { tCommon } = useAppTranslation.Common();

  const isEmpty = blackList.value.length === 0 && !blackList.isLoading;
  const isVisible = !blackList.isLoading && !isEmpty;

  const data = blackList.value.map((item, index) => ({
    key: `${item.id}${index}`,
    action: (
      <Checkbox
        size="small"
        isChecked={selectedItems.isSelected(item.id)}
        onChange={() => selectedItems.onToggleOne(item.id)}
      />
    ),
    patientName:
      item.patientNames.length > 0 ? (
        <PatientNames names={item.patientNames} />
      ) : (
        tCommon("symbol.longDash")
      ),
    phoneNumber: item.phoneNumber,
    actions: (
      <Button
        size="tiny"
        color="error"
        variant="outlined"
        onClick={() => onRemoveOne(item.id)}
        isDisabled={removingIds.includes(item.id)}
        leftAdornment={
          removingIds.includes(item.id) ? (
            <CircularProgress size="tiny" color="red" />
          ) : undefined
        }
      >
        {tInbox("blackList.action.remove")}
      </Button>
    ),
  }));

  const selectedStatuses = (() => {
    const similar = blackList.value.filter((i) =>
      selectedItems.selected.includes(i.id),
    );
    if (similar.length === blackList.value.length) {
      return {
        isAll: true,
        isPartial: false,
      };
    }
    return {
      isAll: selectedItems.isSelectedAll(),
      isPartial: selectedItems.isSelectedPartial(),
    };
  })();

  return (
    <div>
      {blackList.isLoading && !blackList.isSoftLoading && (
        <Skeleton count={15} height={50} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={blackList.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={data}
            cols={[
              {
                data: (
                  <Checkbox
                    inverted
                    size="small"
                    isChecked={selectedStatuses.isAll}
                    isIntermediate={selectedStatuses.isPartial}
                    onChange={selectedItems.onToggleAll}
                  />
                ),
                accessor: "action",
              },
              {
                data: tInbox("blackList.table.patient"),
                accessor: "patientName",
                className: classes.patientCol,
              },
              {
                data: tInbox("blackList.table.phone"),
                accessor: "phoneNumber",
              },
              {
                data: tInbox("blackList.table.action"),
                accessor: "actions",
              },
            ]}
          />
          {blackList.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={blackList.fetchMore}
              isLoading={blackList.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </div>
  );
}

Table.propTypes = {
  blackList: PropTypes.object.isRequired,
  selectedItems: PropTypes.object.isRequired,
  onRemoveOne: PropTypes.func.isRequired,
  removingIds: PropTypes.array.isRequired,
};
