import React from "react";
import styles from "../scss/viewTipsModal.module.scss";
import { formatCurrency } from "../../../../../../utilities/general";
import { useInvoice } from "../../../hooks/invoice/useInvoice";

const TipsModalField = ({ label, amount, info }) => {
  const { currencySymbol } = useInvoice();

  return (
    <div className={styles.field}>
      <span className={styles.label}>
        {label}{" "}
        {label === "Invoice Amount" && <span>{"(Product/Service Fees)"}</span>}
      </span>
      <span className={styles.sum}>
        {formatCurrency(amount, currencySymbol)}
      </span>
      {Boolean(info) && (
        <span>
          Product/Service Fees: <span className={styles.info}>{info}</span>
        </span>
      )}
    </div>
  );
};

export default TipsModalField;
