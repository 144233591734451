import { useState } from "react";
import { useCheckWorkspaceMutation } from "../../../../api/mutations/useCheckWorkspaceMutation";
import { extractApiError } from "../../../../utilities/api";
import { Exception, isNullish } from "../../../../utilities/general";

const ERROR_FALLBACK = "Unable To Validate OTP Code";
const OtpValidateException = new Exception(ERROR_FALLBACK);

export function useApiOtpValidate() {
  const [isValid, setIsValid] = useState(false);
  const [isNeedPassword, setIsNeedPassword] = useState(false);
  const [wpuId, setWpuId] = useState(null);
  const { mutateAsync, isLoading } = useCheckWorkspaceMutation();

  const initiate = async ({ workspaceId, code }) => {
    try {
      if (workspaceId && code) {
        const response = await mutateAsync({ workspaceId, code });
        const res = {
          isNeedPassword: response.data.data.need_password,
          wpuId: response.data.data.wpu_id,
        };
        if (!isNullish(res.isNeedPassword) && !isNullish(res.wpuId)) {
          setIsNeedPassword(res.isNeedPassword);
          setWpuId(res.wpuId);
          setIsValid(true);
          return res;
        } else {
          throw OtpValidateException;
        }
      } else {
        throw OtpValidateException;
      }
    } catch (e) {
      if (e instanceof Exception) {
        throw new Error(e.message);
      } else {
        throw new Error(extractApiError(e) || ERROR_FALLBACK);
      }
    }
  };

  return {
    initiate,
    isLoading,
    isValid,
    isNeedPassword,
    wpuId,
  };
}
