import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { img } from "../../../../../../assets/img";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { Input } from "../../../../../../shared/Input/Input";
import classes from "../ViewChargeKlarna.module.scss";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { uiNotification } from "../../../../../../services/UINotificationService";

const PaymentFormModal = ({
  form,
  changeFormValue,
  errors,
  hasError,
  showTipsModal,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();
  const [disabledInput, setDisabledInput] = useState(false);

  const klarnaPayment = async () => {
    if (!isValidPhoneNumber(form.phoneNumber)) {
      return uiNotification.error("Please Enter Phone Number!");
    }

    showTipsModal.update(true);
  };

  useEffect(() => {
    setDisabledInput(form.email);
  }, []);

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.klarna")}
      imageSrc={img.klarna}
      imageClassName={classes.titleKlarna}
      onCharge={klarnaPayment}
      chargeLabel={tCommon("label.continue")}
    >
      <ModalCharge.InputWrap className={classes.modalKlarna}>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.phoneNumber")}
        </InputLabel>
        <PhoneInput
          name="patientPhone"
          size="medium"
          className={classes.phoneKlarna + " klarna-tel-input"}
          country={"us"}
          countryCodeEditable={false}
          value={invoice?.patient?.phoneNumber || form.phoneNumber}
          onChange={(phone, country, event, formattedPhone) => {
            changeFormValue("country", country);
            changeFormValue("phoneNumber", formattedPhone);
          }}
          enableSearch={true}
          preferredCountries={["us"]}
          isError={hasError("phoneNumber")}
        />
        {hasError("phoneNumber") && (
          <InputError>{errors.phoneNumber}</InputError>
        )}
      </ModalCharge.InputWrap>

      <ModalCharge.InputWrap>
        <InputLabel>{tSales("checkoutInvoice.formInputLabel.mail")}</InputLabel>
        <Input
          size="medium"
          name="email"
          value={form.email}
          onChange={(event) => {
            changeFormValue("email", event.target.value);
          }}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.mail")}
          isDisabled={disabledInput}
        />
      </ModalCharge.InputWrap>
      <p className={classes.smsLink}>
        A link will be sent to the patient via SMS to finish the Klarna checkout
        process
      </p>
    </ModalCharge>
  );
};

export default PaymentFormModal;
