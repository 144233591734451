import React from "react";
import cx from "clsx";
import classes from "./AddTileButton.module.scss";
import { PlusIcon } from "../../../../../assets/Icons/PlusIcon";

export function AddTileButton({ size, children, onClick }) {
  return (
    <button
      className={cx(classes.root, {
        [classes.rootSmall]: size === "small",
      })}
      onClick={onClick}
    >
      <PlusIcon />
      {children}
    </button>
  );
}
