import { useMutation } from "@tanstack/react-query";
import { http } from "../../services/HttpService";

export const useCancelPendingInvoiceMutattion = (options = {}) => {
  return useMutation((payload) => {
    return http.post("cancel-pending-payment-invoice", {
      invoice_id: payload.invoiceId,
    });
  }, options);
};
