import React from "react";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../shared/Select/Select";
import { InputError } from "../../../../shared/InputError/InputError";

export function Dropdown({
  label,
  value,
  options,
  onChange,
  error,
  isLoading,
}) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Select
        options={options}
        value={options.find((option) => option.value === value)}
        onChange={(option) => onChange(option.value)}
        isError={!!error}
        isLoading={isLoading}
      />
      {!!error && <InputError>{error}</InputError>}
    </div>
  );
}
