const initialState = {
  stockAlertsData: { status: "pending", isEnabled: false, selectedDays: [] },
  saveStockAlertsData: { status: "pending", data: null },
};

const StockAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_STOCK_EMAIL_ALERTS": {
      return {
        ...state,
        stockAlertsData: { ...state.stockAlertsData, ...action.payload },
      };
    }
    case "SAVE_STOCK_EMAIL_ALERTS": {
      return {
        ...state,
        saveStockAlertsData: {
          ...state.saveStockAlertsData,
          ...action.payload,
        },
      };
    }
    case "RESET_STOCK_EMAIL_ALERTS": {
      return {
        ...state,
        saveStockAlertsData: {
          ...state.saveStockAlertsData,
          status: "pending",
        },
        stockAlertsData: { ...state.stockAlertsData, status: "pending" },
      };
    }
    default:
      return state;
  }
};

export default StockAlertsReducer;
