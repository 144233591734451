import React from "react";
import { Header } from "./components/Header";
import { Table } from "./components/Table";
import { useReports } from "./hooks/useReports";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { useDownloadExcel } from "./hooks/useDownloadExcel";

export function MembershipDeclineReport() {
  const { reports, order, period } = useReports();
  const downloadExcel = useDownloadExcel({ period });

  return (
    <ReportLayout>
      <Header
        period={period}
        downloadExcel={downloadExcel}
        isReportsLoading={reports.isLoading}
      />
      <Table reports={reports} order={order} />
    </ReportLayout>
  );
}
