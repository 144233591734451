import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

function createAppointmentFeesQueryKey({ appointmentId }) {
  return [QUERY_KEYS.appointmentFees, appointmentId];
}

export function useAppointmentFeesQuery({ appointmentId }, options = {}) {
  return useQuery(
    createAppointmentFeesQueryKey({ appointmentId }),
    () => http.get(HTTP_ENDPOINTS.getAppointmentFees(appointmentId)),
    options,
  );
}
