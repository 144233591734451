import React from "react";
import classes from "../sass/membership.module.scss";
import { numberFormat, showFormattedDate } from "../../../Utils";
import cx from "clsx";
import { usePayPastDueInvoiceQuery } from "../../../_legacy/Queries";

const paymentHistoryColumns = [
  "Invoice #",
  "Invoice date",
  "Amount",
  "Status",
  "Details",
];

function getDisplayText(modelDetails) {
  if (!modelDetails.period_start_date || !modelDetails.period_end_date) {
    return;
  }

  const startDate = showFormattedDate(modelDetails.period_start_date);
  const endDate = showFormattedDate(modelDetails.period_end_date);

  let displayText = "";
  if (modelDetails.one_time_setup_fee > 0) {
    displayText = "Setup fee + ";
  }
  if (modelDetails.pro_rated > 0) {
    displayText += "Prorated membership fee";
  } else {
    displayText += "Membership fee";
  }

  displayText += ` from ${startDate} to ${endDate}`;

  if (modelDetails.total_discount > 0) {
    displayText += " with discount applied";
  }

  return displayText;
}

const PaymentHistoryTable = ({ data, openLoader, closeLoader }) => {
  const { mutate: payPastDueInvoice } = usePayPastDueInvoiceQuery({
    closeLoader,
  });

  const renderTableRows = () => {
    return data?.monthly_membership_invoices.map((obj) => {
      return (
        <tr key={obj.invoice_number} className={classes.tableRow}>
          <td className={classes.membershipName}>{obj.invoice_number || ""}</td>
          <td>{obj.created ? showFormattedDate(obj.created) : ""}</td>
          <td className={classes.membershipFee}>
            {obj.amount
              ? numberFormat(obj.amount, "currency") +
                (obj.pro_rated === 1 ? "*" : "")
              : "$0.00"}
          </td>
          <td className={classes.paymentFrequency}>
            {obj.payment_status || ""}
          </td>
          <td className={`${classes.paymentFrequency} font-w-300`}>
            {obj?.created && obj?.period_start_date && obj?.period_end_date
              ? getDisplayText(obj)
              : "No details found "}
          </td>
        </tr>
      );
    });
  };

  const handlePayPastDueInvoice = (data) => {
    payPastDueInvoice(data);
    openLoader();
  };
  return (
    <div className={cx("m-t-20 p-b-25  p-l-25 p-r-24", classes.membershipCard, classes.tableContainer)}>
      <div className={classes.cardHeader}>
        <div className={classes.membershipTitle}>Payment History</div>
        {data.past_due_invoice_data?.invoice_id > 0 &&
          data.past_due_invoice_data?.show_button &&
          data?.active_membership &&
          !data?.marked_for_cancel ? (
            <div className="flex">
              <div className={cx(classes.amountTitle, "m-t-10 m-r-10")}>
                Total Amount Due:{" "}
                {numberFormat(data.past_due_invoice_data?.amount, "currency")}
              </div>

              <div
                onClick={() =>
                  handlePayPastDueInvoice({
                    invoice_id: data.past_due_invoice_data?.invoice_id,
                  })
                }
                className={classes.payButton}
              >
                Pay Now
              </div>
            </div>
          ) : ""}
      </div>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHeadingRow}>
            {paymentHistoryColumns?.map((columnName, idx) => (
              <th key={columnName} className='centerTableHeading'>{columnName}</th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
    </div>
  );
};

export default PaymentHistoryTable;
