import React from "react";
import PropTypes from "prop-types";
import { EntityRow } from "../EntityRow";
import { Select } from "../../../../shared/Select/Select";

const WaitListMultiselectEntity = ({
  label,
  name,
  isError,
  onChange,
  options,
  values,
  placeholder,
  size,
}) => (
  <EntityRow label={label} size={size}>
    <Select
      isError={isError}
      value={values.map((i) => ({
        value: i.id,
        label: i.name,
      }))}
      onChange={(nextValues) => {
        onChange(
          nextValues.map((i) => ({
            id: i.value,
            name: i.label,
          })),
        );
      }}
      options={options.map((i) => ({
        value: i.id,
        label: i.name,
      }))}
      isSearchable
      isMulti
      placeholder={placeholder}
      name={name}
      size={size}
    />
  </EntityRow>
);

const nodeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

WaitListMultiselectEntity.propTypes = {
  isError: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(nodeType),
  values: PropTypes.arrayOf(nodeType),
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
  label: PropTypes.string,
  name: PropTypes.string,
};

WaitListMultiselectEntity.defaultProps = {
  isError: false,
  onChange: () => {},
  options: [],
  values: [],
  placeholder: "",
  size: "large",
};

export default WaitListMultiselectEntity;
