import { uiNotification } from "../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useDeleteCCMutation } from "../../../../../api/mutations/useDeleteCCMutation";

export function useDeleteCC({ onSuccess }) {
  const { tCommon } = useAppTranslation.Common();
  const { mutateAsync, isLoading: isDeleting } = useDeleteCCMutation();

  const initiate = async (cardId) => {
    if (window.confirm(tCommon("confirm.deletePatientCC"))) {
      try {
        await mutateAsync({ cardId: Number(cardId) });
        onSuccess();
        uiNotification.success(tCommon("success.deletePatientCC"));
      } catch (e) {
        uiNotification.error(tCommon("error.deletePatientCC"));
        throw e;
      }
    }
  };

  return {
    initiate,
    isLoading: isDeleting,
  };
}
