import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Button } from "../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Modal } from "../../../../../shared/Modal/Modal";
import classes from "./MutateMid.module.scss";
import { Input } from "../../../../../shared/Input/Input";
import { InputError } from "../../../../../shared/InputError/InputError";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useMutate } from "./hooks/useMutate";
import { useForm } from "./hooks/useForm";

export function MutateMid({ legacyBtn, clinicId, merchantId, onSuccess, className }) {
  const { tCommon } = useAppTranslation.Common();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const mutate = useMutate({
    clinicId,
    merchantId,
    onSuccess: () => {
      onSuccess();
      setIsModalOpen(false);
    },
  });

  const form = useForm({
    onSubmit: (values) => mutate.initiate(values.mid),
    initialValues: {
      mid: merchantId || "",
    },
  });

  const onCloseModal = () => {
    setIsModalOpen(false);
    form.resetForm();
  };

  return (
    <>
      {legacyBtn ? (
        <button
          onClick={() => setIsModalOpen(true)}
          className={cx(classes.legacyBtn, className)}
        >
          {merchantId ? tCommon("label.updateMid") : tCommon("label.attachMid")}
        </button>
      ) : (
        <Button size="small" onClick={() => setIsModalOpen(true)} className={className}>
          {merchantId ? tCommon("label.updateMid") : tCommon("label.attachMid")}
        </Button>
      )}
      {isModalOpen && (
        <Modal
          footerNoBorder
          headerNoBorder
          size="small"
          isOpen={isModalOpen}
          onClose={onCloseModal}
          header={
            <Modal.Title>
              {merchantId
                ? tCommon("label.updateMid")
                : tCommon("label.attachMid")}
            </Modal.Title>
          }
          footer={
            <div className={classes.footer}>
              <Button
                onClick={form.handleSubmit}
                isDisabled={mutate.isLoading || !form.dirty}
                leftAdornment={
                  mutate.isLoading ? (
                    <CircularProgress size="small" color="white" />
                  ) : undefined
                }
              >
                {merchantId ? tCommon("label.save") : tCommon("label.attach")}
              </Button>
              <Button variant="outlined" onClick={onCloseModal}>
                {tCommon("label.cancel")}
              </Button>
            </div>
          }
        >
          <Input
            name="mid"
            type="number"
            value={form.values.mid}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isError={!!form.getError("mid")}
            placeholder={tCommon("label.merchantId")}
          />
          {!!form.getError("mid") && (
            <InputError>{form.getError("mid")}</InputError>
          )}
        </Modal>
      )}
    </>
  );
}

MutateMid.propTypes = {
  clinicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  merchantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func.isRequired,
};
