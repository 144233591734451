import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./ButtonSecondary.module.scss";

const COLORS = {
  primary: "primary",
  error: "error",
};

const SIZES = {
  small: "small",
};

const FONT_FAMILIES = {
  primary: "primary",
  secondary: "secondary",
};

export function ButtonSecondary({
  type,
  color,
  onClick,
  children,
  leftAdornment,
  rightAdornment,
  className,
  leftAdornmentClassName,
  rightAdornmentClassName,
  isDisabled,
  size,
  fontFamily,
}) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.cPrimary]: color === COLORS.primary,
      [classes.cError]: color === COLORS.error,
      [classes.disabled]: isDisabled,
      [classes.smallButton]: size === SIZES.small,
      [classes.rootFontPrimary]: fontFamily === FONT_FAMILIES.primary,
      [classes.rootFontSecondary]: fontFamily === FONT_FAMILIES.secondary,
    },
    className,
  );

  return (
    <button type={type} onClick={onClick} className={rootClasses}>
      {leftAdornment && (
        <div
          className={cx(
            classes.adornment,
            classes.leftAdornment,
            leftAdornmentClassName,
          )}
        >
          {leftAdornment}
        </div>
      )}
      {children}
      {rightAdornment && (
        <div
          className={cx(
            classes.adornment,
            classes.rightAdornment,
            rightAdornmentClassName,
          )}
        >
          {rightAdornment}
        </div>
      )}
    </button>
  );
}

ButtonSecondary.propTypes = {
  type: PropTypes.string,
  color: PropTypes.oneOf(Object.values(COLORS)),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  leftAdornment: PropTypes.node,
  rightAdornment: PropTypes.node,
  className: PropTypes.string,
  leftAdornmentClassName: PropTypes.string,
  rightAdornmentClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  fontFamily: PropTypes.oneOf(Object.values(FONT_FAMILIES)),
};

ButtonSecondary.defaultProps = {
  type: "button",
  color: "primary",
  leftAdornment: undefined,
  rightAdornment: undefined,
  className: undefined,
  leftAdornmentClassName: undefined,
  rightAdornmentClassName: undefined,
  isDisabled: false,
  size: undefined,
  fontFamily: undefined,
};
