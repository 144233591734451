import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { svg } from "../../assets/svg";
import classes from "./SortAdornment.module.scss";

export const SORT_ADORNMENT_VALUES = {
  asc: "asc",
  desc: "desc",
};

const POSITION = {
  left: "left",
  right: "right",
};

const ICON_WIDTH = "6px";

export function SortAdornment({
  children,
  position,
  className,
  onToggle,
  value,
  iconClassName,
}) {
  const renderIcon = () => (
    <div className={cx(classes.icon, iconClassName)}>
      <img
        src={svg.triangleUpPrimary}
        alt="up"
        width={ICON_WIDTH}
        style={{
          minWidth: ICON_WIDTH,
        }}
        className={cx(classes.up, {
          [classes.active]: value === SORT_ADORNMENT_VALUES.asc,
        })}
      />
      <img
        src={svg.triangleUpPrimary}
        alt="down"
        width={ICON_WIDTH}
        style={{
          minWidth: ICON_WIDTH,
        }}
        className={cx(classes.down, {
          [classes.active]: value === SORT_ADORNMENT_VALUES.desc,
        })}
      />
    </div>
  );

  return (
    <button onClick={onToggle} className={cx(classes.root, className)}>
      {position === POSITION.left && renderIcon()}
      {children}
      {position === POSITION.right && renderIcon()}
    </button>
  );
}

SortAdornment.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(Object.values(POSITION)),
  className: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(SORT_ADORNMENT_VALUES)),
  iconClassName: PropTypes.string,
};

SortAdornment.defaultProps = {
  position: POSITION.left,
  className: undefined,
  value: undefined,
  iconClassName: undefined,
};
