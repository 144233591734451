/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { isPositiveNumber, decimalPlaces } from "../../../Utils/services.js";
import { initTransferBatchStock } from "./stock-transfer.js";
import { getPrecision } from "../../../utilities/general.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class StockTransferAddProductRow extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,

      autoProductData: {},
      productList: [],
      searched_product_id: 0,
      searched_product_name: "",
      is_product_search: false,
      search_product_keyword: "",
      searched_product_units: "",
      search_product_keywordClass: "simpleInput",
      searched_product_unitsClass: "simpleInput",
      is_search_first_time: true,
      frequency_option: "monthly",
      membership_price_per_year: "",
      autoRenew: "",

      productStockData: null,
      transfer: null,
      batchStockList: [],
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOnClick, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.editRowIndex == nextProps.index) {
      if (
        nextProps.autoProductData !== undefined &&
        prevState.autoProductData !== nextProps.autoProductData
      ) {
        returnState.autoProductData = nextProps.autoProductData;
        returnState.productList = nextProps.autoProductData;
        returnState.is_search_first_time = prevState.is_search_first_time
          ? false
          : prevState.is_search_first_time;
        returnState.transfer = null;
        returnState.productStockData = null;
      } else if (nextProps.productStockData !== prevState.productStockData) {
        returnState.productStockData = nextProps.productStockData;

        let transfer = nextProps.transfer;
        let row = nextProps.row;
        row.type = returnState.productStockData.product_type;
        row.product_id = prevState.searched_product_id;
        row.product_name = prevState.searched_product_name;
        row.productStockData = returnState.productStockData;

        let batchStockList = [];
        let productInventory = [];
        if (row.type == "others") {
          productInventory =
            returnState.productStockData.product_per_clinic &&
            returnState.productStockData.product_inventory_other &&
            returnState.productStockData.product_inventory_other.length > 0
              ? returnState.productStockData.product_inventory_other
              : [];
        } else {
          productInventory =
            returnState.productStockData.product_inventory &&
            returnState.productStockData.product_inventory.length > 0
              ? returnState.productStockData.product_inventory
              : [];
        }

        if (productInventory.length > 0) {
          productInventory.map((obj) => {
            let batches = initTransferBatchStock();
            batches.inventory_id = obj.id;
            batches.available_units = obj.units;
            batches.batch_id = obj.batch_id;
            batchStockList.push(batches);
            if (row.type == "others") {
              batches.batch_id = "";
              row.inventory_id = obj.id;
              row.available_units = obj.units;
            }
          });
        } else {
          uiNotification.clear();
          uiNotification.error(prevState.globalLang.inventory_not_found);
        }

        row.batchStockList = batchStockList;
        transfer[nextProps.editRowIndex] = row;
        nextProps.handleChildState({
          transfer: transfer,
          editRowIndex: nextProps.editRowIndex,
        });
      }
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
          ? true
          : false
        : target.value;
    this.setState({
      [event.target.name]: value,
      userChanged: true,
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOnClick, false);
  }

  handleOnClick = (e) => {
    if (
      this.ref_search_product !== undefined &&
      this.ref_search_product !== null &&
      !this.ref_search_product.contains(e.target) &&
      this.state.is_product_search !== undefined &&
      this.state.is_product_search === true
    ) {
      this.setState({ is_product_search: false });
    }
  };

  searchProductStock = (value) => {
    let formData = { params: { limit: 20 } };
    let selected_product_ids = [];
    if (selected_product_ids.length) {
      let formIds = selected_product_ids.join(",");
      formData.params.selected_product_ids = formIds;
    }
    if (typeof value === "string") {
      value = value.trim();
      if (value.length >= 3) {
        formData.params.term = value.trim();
        value = value.trim();
        this.props.searchProductStock(formData, this.props.index);
      } else {
        this.setState({ is_search_first_time: true, productList: [] });
      }
    }
    if (
      this.props.editRowIndex == this.props.index &&
      this.props.row.product_id > 0 &&
      this.props.row.product_name !== value
    ) {
      let transfer = this.props.transfer;
      let row = this.props.row;
      row.product_id = "";
      row.product_name = "";
      row.batches = [];
      row.inventory_id = "";
      row.available_units = "";
      transfer[this.props.index] = row;
      this.props.handleChildState({ transfer: transfer });
    }
  };

  handleProductChange = (event) => {
    const target = event.target;
    let value = target.value;
    let returnState = {};

    returnState[event.target.name] = value;
    returnState.is_product_search = true;
    returnState.searched_product_id = 0;
    returnState.searched_product_name = "";
    this.setState(returnState);
    this.searchProductStock(value);
  };

  handleOnFocus = () => {
    this.setState({ is_search_first_time: true, productList: [] });
  };

  selectProduct = (event) => {
    let name = event.currentTarget.dataset.name;
    let id = event.currentTarget.dataset.id;
    this.setState({
      searched_product_id: id,
      searched_product_name: name,
      search_product_keyword: name,
      is_product_search: false,
      transfer: null,
    });
    this.props.getProductStockDetail(id);
  };

  handleRowInputChange = (event) => {
    const countUnitsBy =
      Number(this.state.productStockData?.count_units_by) || 1;
    const countUnitsByPrecision = getPrecision(countUnitsBy);
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
          ? true
          : false
        : target.value;
    if (event.target.name === "transfer_units") {
      if (decimalPlaces(event.target.value) > countUnitsByPrecision) {
        return;
      }
    }

    let row = this.props.row;
    let transfer = this.props.transfer;
    if (event.target.name === "inventory_id") {
      row.inventory_id = "";
      row.inventory_idClass = "simpleSelect field_error";
      row.available_units = "";
      row.batch_id = "";
      row.transfer_unitsClass = "simpleInput";
      row.transfer_units = "";
      if (value) {
        const batch = row.batchStockList.find((x) => x.inventory_id == value);
        if (batch) {
          row.inventory_id = batch.inventory_id;
          row.available_units = batch.available_units;
          row.batch_id = batch.batch_id;
          row.inventory_idClass = "simpleSelect";
        }
      }
    } else if (event.target.name === "transfer_units") {
      row.transfer_units = value;
      row.transfer_unitsClass = "simpleInput";
      if (value > 0) {
        if (
          !isPositiveNumber(
            value,
            0,
            row.available_units,
            countUnitsByPrecision === 0
              ? countUnitsByPrecision
              : countUnitsByPrecision + 1,
          )
        ) {
          row.transfer_unitsClass = "simpleInput field_error";
        } else {
          row.transfer_unitsClass = "simpleInput";
        }
      } else {
        row.transfer_unitsClass = "simpleInput field_error";
      }
    }

    transfer[this.props.index] = row;
    this.props.handleChildState({
      transfer: transfer,
      editRowIndex: this.props.editRowIndex,
    });
  };

  handleRowDelete = (index) => {
    this.setState(this.baseState);
    let transfer = this.props.transfer;
    if (transfer && transfer.length > 0) {
      this.setState({
        autoProductData: {},
        searched_product_id: 0,
        searched_product_name: "",
        is_product_search: false,
        search_product_keyword: "",
        searched_product_units: "",
        productList: [],
      });

      this.props.handleRowDelete(
        {
          editRowIndex: null,
          autoProductData: {},
        },
        index,
      );
    }
  };

  _removeItem = (items, i) => {
    let x = items.slice(0, i - 1);
    let y = items.slice(i, items.length);

    let z = x.concat(y);
    return z;
  };

  render() {
    return (
      <tr>
        <td className="table-updated-td">
          <div
            className="search_product_container"
            ref={(ref_search_product) =>
              (this.ref_search_product = ref_search_product)
            }
          >
            <div className="simpleField no-margin">
              <input
                type="text"
                className={this.state.search_product_keywordClass}
                autoComplete="off"
                name="search_product_keyword"
                value={
                  this.props.row.product_id
                    ? this.props.row.product_name
                    : this.state.search_product_keyword
                }
                onChange={this.handleProductChange.bind(this)}
                onFocus={this.handleOnFocus.bind(this)}
              />
              <ul
                className={
                  typeof this.state.search_product_keyword === "string" &&
                  this.state.search_product_keyword.trim() !== "" &&
                  this.state.is_product_search !== undefined &&
                  this.state.is_product_search === true &&
                  this.state.search_product_keyword.length > 2
                    ? " search-dropdown"
                    : "cal-dropdown clinicname-dropdown no-display"
                }
              >
                {this.state.productList.length > 0 ? (
                  this.state.productList.map((obj, idx) => {
                    return (
                      <li
                        key={"search_product-" + idx}
                        data-id={obj.id}
                        data-name={obj.product_name}
                        onClick={this.selectProduct}
                      >
                        <a>{obj && obj.product_name}</a>
                      </li>
                    );
                  })
                ) : (
                  <li
                    key={"search_product-norecord"}
                    data-id={0}
                    data-name={"product_match_not_found"}
                    data-index={-1}
                  >
                    <a>
                      {this.state.is_search_first_time
                        ? this.state.globalLang.label_searching_with_dot
                        : "Search will populate products having available stock for selected clinic."}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </td>
        <td className="table-updated-td">
          {this.props.row.product_id > 0 &&
            this.props.row.type !== "others" && (
              <div className="simpleField no-margin">
                <select
                  className={this.props.row.inventory_idClass}
                  autoComplete="off"
                  name={"inventory_id"}
                  value={this.props.row.inventory_id}
                  onChange={this.handleRowInputChange}
                >
                  <option value="">Select</option>
                  {this.props.row.batchStockList &&
                    this.props.row.batchStockList.length > 0 &&
                    this.props.row.batchStockList.map((obj, idx) => {
                      return (
                        <option
                          key={"batchStockList-" + idx}
                          value={obj.inventory_id}
                        >
                          {obj.batch_id}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
          {this.props.row.product_id > 0 &&
            this.props.row.type === "others" && <span>N/A</span>}
        </td>
        <td className="table-updated-td">
          {this.props.row.product_id > 0 && (
            <div className="simpleField no-margin">
              <input
                type={"text"}
                className={this.props.row.transfer_unitsClass}
                autoComplete="off"
                name={"transfer_units"}
                value={this.props.row.transfer_units}
                onChange={this.handleRowInputChange}
              />
              {this.props.row.available_units > 0 && (
                <span className="maxCharacterInstruction">
                  Max: {this.props.row.available_units}
                </span>
              )}
            </div>
          )}
        </td>
        <td className="table-updated-td text-right p-l-0">
          <a
            className="easy-link p-l-0 p-r-0"
            onClick={this.handleRowDelete.bind(this, this.props.index)}
          >
            <i className="fa fa-trash-alt m-r-5 m-t-2"></i>Delete
          </a>
        </td>
      </tr>
    );
  }
}
export default StockTransferAddProductRow;
