import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../shared/Button/Button";
import classes from "../../sass/components/CommonControlButtons.module.scss";

export function CommonControlButtons({ onSubmit, onCancel, isSubmitEnabled }) {
  return (
    <div className={classes.root}>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onSubmit} isDisabled={!isSubmitEnabled}>
        Save
      </Button>
    </div>
  );
}

CommonControlButtons.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitEnabled: PropTypes.bool,
};

CommonControlButtons.defaultProps = {
  isSubmitEnabled: true,
};
