import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clinicId: yup.number().required(),
});

export function useQualiphyCancelClinicEnrollMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.qualiphy.cancelClinicEnroll(), {
      clinic_id: req.clinicId,
    });
  }, options);
}
