import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./CircularProgress.module.scss";

const SIZES = {
  tiny: "tiny",
  small: "small",
  medium: "medium",
  big: "big",
};

export const CIRCULAR_PROGRESS_COLORS = {
  primary: "primary",
  gray1: "gray1",
  white: "white",
  red: "red",
  orange: "orange",
  green: "green",
};

export function CircularProgress({ size, color, className }) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.sTiny]: size === SIZES.tiny,
      [classes.sSmall]: size === SIZES.small,
      [classes.sMedium]: size === SIZES.medium,
      [classes.sBig]: size === SIZES.big,
      [classes.cPrimary]: color === CIRCULAR_PROGRESS_COLORS.primary,
      [classes.cGray1]: color === CIRCULAR_PROGRESS_COLORS.gray1,
      [classes.cWhite]: color === CIRCULAR_PROGRESS_COLORS.white,
      [classes.cRed]: color === CIRCULAR_PROGRESS_COLORS.red,
      [classes.cOrange]: color === CIRCULAR_PROGRESS_COLORS.orange,
      [classes.cGreen]: color === CIRCULAR_PROGRESS_COLORS.green,
    },
    className,
  );

  return (
    <div className={rootClasses}>
      <span></span>
    </div>
  );
}

CircularProgress.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZES)),
  color: PropTypes.oneOf(Object.values(CIRCULAR_PROGRESS_COLORS)),
  className: PropTypes.string,
};

CircularProgress.defaultProps = {
  size: SIZES.medium,
  color: CIRCULAR_PROGRESS_COLORS.primary,
  className: undefined,
};
