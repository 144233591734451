import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Button } from "../../../../shared/Button/Button";
import { useDownloadPriceListMutation } from "../../../../api/mutations/useDownloadPriceListMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { composeDownloadExcelUrl } from "../../../../utilities/api";

export function Export({ type, clinicId, searchTerm, sortBy, sortDir }) {
  const { tCommon } = useAppTranslation.Common();

  const exportMutation = useDownloadPriceListMutation({
    onError: () => {
      uiNotification.error(tCommon("error.export"));
    },
    onSuccess: (res) => {
      if (res.fileName) {
        window.location.href = composeDownloadExcelUrl(res.fileName);
      } else {
        uiNotification.error(tCommon("error.export"));
      }
    },
  });

  const onExport = () => {
    exportMutation.mutate({
      type,
      clinicId,
      searchTerm,
      sortBy,
      sortDir,
    });
  };

  return (
    <Button
      size="small"
      onClick={onExport}
      isDisabled={exportMutation.isLoading}
      leftAdornment={
        exportMutation.isLoading ? (
          <CircularProgress size="tiny" color="white" />
        ) : undefined
      }
    >
      {tCommon("label.download")}
    </Button>
  );
}

Export.propTypes = {
  type: PropTypes.string.isRequired,
  clinicId: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
};

Export.defaultProps = {
  searchTerm: null,
  sortBy: null,
  sortDir: null,
};
