import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  giftCardId: yup.number().required(),
  balance: yup.string().required(),
  reason: yup.string().required(),
});

export function useUpdateGiftCardBalanceMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.put(HTTP_ENDPOINTS.bi.updateGiftCardBalance(), {
        egift_voucher_id: req.giftCardId,
        balance: req.balance,
        reason: req.reason,
      });
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.bi.giftCardHistory],
        });
        options.onSuccess?.(...args);
      },
    },
  );
}
