import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

function createServicesByProviderIdClinicIdAndTypeQueryKey({
  providerId,
  clinicId,
  type,
}) {
  return [
    QUERY_KEYS.servicesByProviderIdClinicIdAndType,
    providerId,
    clinicId,
    type,
  ];
}

export function useServicesByProviderIdClinicIdAndTypeQuery(
  { providerId, clinicId, type },
  options = {},
) {
  return useQuery(
    createServicesByProviderIdClinicIdAndTypeQueryKey({
      providerId,
      clinicId,
      type,
    }),
    () =>
      http.get(
        HTTP_ENDPOINTS.getServicesByProviderIdClinicIdAndType({
          providerId,
          clinicId,
          type,
        }),
      ),
    options,
  );
}
