import React from "react";
import { Link } from "react-router-dom";
import { svg } from "../../../../assets/svg";
import classes from "../sass/Completed.module.scss";

export function Completed() {
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <img src={svg.checkGreen} alt="" width="20px" />
        User Created!
      </div>
      <div>
        Press
        <Link to="/login"> here </Link>
        {`if you haven't been redirected automatically`}
      </div>
    </div>
  );
}
