import React from "react";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import styles from "./styles.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { ChangePreview } from "./shared/ChangePreview/ChangePreview";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";

export function Table({ data, isLoading }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const userQuery = useCurrentUserQuery();

  const isEmpty = data.length === 0 && !isLoading;
  const isVisible = !isLoading && !isEmpty;

  const preparedData = data.map((r) => ({
    key: r.id,
    user: r.user,
    date: r.date,
    type: tBi(`giftCardHistory.changeType.${r.type}`),
    change: <ChangePreview type={r.type} before={r.before} after={r.after} />,
    reason: r.reason,
  }));

  return (
    <Box className={styles.root}>
      {(isLoading || userQuery.isLoading) && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <SharedTable
          bgColor="white"
          bodyClassName={styles.body}
          data={preparedData}
          cols={[
            {
              data: tBi("giftCardHistory.table.user"),
              accessor: "user",
              headColClassName: styles.col,
            },
            {
              data: tBi("giftCardHistory.table.date"),
              accessor: "date",
              headColClassName: styles.col,
            },
            {
              data: tBi("giftCardHistory.table.type"),
              accessor: "type",
              headColClassName: styles.col,
            },
            {
              data: tBi("giftCardHistory.table.change"),
              accessor: "change",
              headColClassName: styles.col,
            },
            {
              data: tBi("giftCardHistory.table.reason"),
              accessor: "reason",
              headColClassName: styles.col,
            },
          ]}
        />
      )}
    </Box>
  );
}
