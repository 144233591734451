import React from "react";
import moment from "moment";
import { useReports } from "./hooks/useReports";
import { Header } from "./components/Header/Header";
import { Table } from "./components/Table/Table";
import InsightsSideBar from "../../../../../../BusinessInsights/InsightsSideBar";
import { ReportLayout } from "../../../../../../BusinessInsights/components/ReportLayout/ReportLayout";
import { API_DATE_FORMAT } from "../../../../../../../consts/api";

export function NewExpiredLiabilityReport() {
  const { reports, period } = useReports();

  const requestParams = {
    fromDate: moment(period.value.from).format(API_DATE_FORMAT),
    toDate: moment(period.value.to).format(API_DATE_FORMAT),
    report_type: "expired",
  };

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <InsightsSideBar />
        <div className="memberWalletOuter business-section">
          <ReportLayout>
            <Header
              total={reports.total}
              period={period}
              isReportsLoading={reports.isLoading}
              requestParams={requestParams}
            />
            <Table reports={reports} />
          </ReportLayout>
        </div>
      </div>
    </div>
  );
}
