import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function signUp() {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
  };
}

export function getSignUpAccountStatus(formData) {
  return (dispatch) => {
    http
      .post("signup-account-status", formData)
      .then((response) => {
        dispatch({ type: "GET_SIGNUP_ACCOUNT_STATUS", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "GET_SIGNUP_ACCOUNT_STATUS",
            payload: error.response.data,
          });
        }
      });
  };
}

export function checkUniqueEmail(emailData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    http
      .post("check-email-id-new", emailData)
      .then((response) => {
        dispatch({ type: "UNIQUE_EMAIL", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "UNIQUE_EMAIL", payload: error.response.data });
        }
      });
  };
}

export function getSignUpPlans() {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    http
      .get("signup-plans")
      .then((response) => {
        dispatch({ type: "SIGN_UP_PLANS", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_PLANS", payload: error.response.data });
        }
      });
  };
}

export function signUpBasic(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    http
      .post("signup-basic", signUpData)
      .then((response) => {
        dispatch({ type: "SIGN_UP_BASIC", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_BASIC", payload: error.response.data });
        }
      });
  };
}

export function signUpPremium(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    http
      .post("signup-premium", signUpData)
      .then((response) => {
        dispatch({ type: "SIGN_UP_PREMIUM", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_PREMIUM", payload: error.response.data });
        }
      });
  };
}

export function signUpNationalMedicalDirectors(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    http
      .post("signup/national-medical-directors", signUpData)
      .then((response) => {
        dispatch({ type: "SIGN_UP_DIRECTORS", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_DIRECTORS", payload: error.response.data });
        }
      });
  };
}

export function verifyAccount(verificationCode) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    http
      .get("verify-account/" + verificationCode)
      .then((response) => {
        dispatch({ type: "SIGN_UP_VERIFY_ACCOUNT", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "SIGN_UP_VERIFY_ACCOUNT",
            payload: error.response.data,
          });
        }
      });
  };
}

export const checkEmail = (formData) => {
  return (dispatch) => {
    http
      .post("check-email-exists", formData)
      .then((response) => {
        dispatch({ type: "CHECK_EMAIL_ON_SIGNUP", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHECK_EMAIL_ON_SIGNUP",
          payload: error.response.data,
        });
      });
  };
};

export function signUpTouchMD(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    http
      .post("signup-external-services", signUpData)
      .then((response) => {
        dispatch({ type: "SIGN_UP_TOUCHMD", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_TOUCHMD", payload: error.response.data });
        }
      });
  };
}

export const searchPhoneNumberOauth = (formData) => {
  return (dispatch) => {
    http
      .post("search-phone-number-oauth", formData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_APP_BACKEND_TOKEN_TWILIO}`,
        },
      })
      .then((response) => {
        dispatch({ type: "SEARCH_PHONE_NUMBER_OAUTH", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SEARCH_PHONE_NUMBER_OAUTH",
          payload: error.response.data,
        });
      });
  };
};
