import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  searchTerm: yup.string(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    patients: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          firstName: yup.string().required(),
          lastName: yup.string().nullable(),
          email: yup.string().nullable(),
          deletedAt: yup.string().required(),
          imageUrl: yup.string().nullable(),
          countryName: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const data = unwrapOr(() => res.data.data.data, []);
  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data, {}),
      pageSize: req.pageSize,
    }),
    patients: data.map((p) => ({
      id: p.id,
      firstName: p.firstname,
      lastName: p.lastname || null,
      email: p.email,
      deletedAt: p.deleted_at,
      imageUrl: p.user_image_url || null,
      countryName: p.country_name || null,
    })),
  };
};

function createDeletedPatientsQueryKey({ searchTerm, pageSize }) {
  return [QUERY_KEYS.deletedPatients, searchTerm, pageSize].filter(Boolean);
}

export function useDeletedPatientsQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createDeletedPatientsQueryKey({
      searchTerm: req.searchTerm,
      pageSize: req.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getDeletedPatients(), {
        params: {
          page: pageParam,
          pagesize: req.pageSize,
          term: req.searchTerm || "",
        },
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
