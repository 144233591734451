import * as yup from "yup";
import { useFormik } from "formik";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

const MID_MIN_LEN = 16;
const MID_MAX_LEN = 18;

export function useForm({ onSubmit, initialValues }) {
  const { tCommon } = useAppTranslation.Common();

  const schema = yup.object({
    mid: yup
      .number()
      .min(1, tCommon("formError.invalid"))
      .test({
        test: (value) =>
          String(value).length >= MID_MIN_LEN &&
          String(value).length <= MID_MAX_LEN,
        message: tCommon("formError.lengthRange", {
          min: MID_MIN_LEN,
          max: MID_MAX_LEN,
        }),
      })
      .required(tCommon("formError.required")),
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    dirty,
    touched,
  } = useFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
  });

  const getError = (field) => {
    if (touched[field] && errors[field]) {
      return errors[field];
    }
  };

  return {
    values,
    getError,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    dirty,
  };
}
