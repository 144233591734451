import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  appointment_id: yup.number().required(),
});

export function useAppointmentsRestoreNoShowMutation(options = {}) {
  var queryClient = useQueryClient();

  return useMutation(
    async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(HTTP_ENDPOINTS.appointments.restoreNoShow(), req);
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.appointments.noShows],
        });
        options.onSuccess?.(...args);
      },
    },
  );
}
