import { http } from "../../services/HttpService";

export const fetchIsCherryAvailable = () => {
  return (dispatch) => {
    dispatch({
      type: "IS_CHERRY_AVAILABLE",
      payload: { status: "loading", data: { isCherryAvailable: false } },
    });
    http
      .get("pos-cherry/isAvailable")
      .then((response) => {
        dispatch({
          type: "IS_CHERRY_AVAILABLE",
          payload: {
            status: "success",
            data: { isCherryAvailable: response.data.data.cherryAvailable },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "IS_CHERRY_AVAILABLE",
          payload: {
            status: "error",
            data: { isCherryAvailable: false, message: error.response.data },
          },
        });
      });
  };
};

export const fetchCherryPatientByCherryPatientId = ({
  patientId,
  cherryPatientId,
  phoneNumber = "",
}) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID",
      payload: { status: "loading", data: null },
    });
    http
      .get(
        `pos-cherry/fetchPatientByCherryId/${patientId}/${cherryPatientId}/${phoneNumber}`,
      )
      .then((response) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const refreshCherryPatient = ({ patientId, cherryPatientId }) => {
  return (dispatch) => {
    dispatch({
      type: "REFRESH_CHERRY_PATIENT",
      payload: { status: "loading", data: null },
    });
    http
      .patch(`pos-cherry/refreshPatient/${patientId}/${cherryPatientId}`)
      .then((response) => {
        dispatch({
          type: "REFRESH_CHERRY_PATIENT",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "REFRESH_CHERRY_PATIENT",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const fetchCherryPatientByPhone = ({ patientId, phoneNumber }) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHERRY_PATIENT_BY_PHONE",
      payload: { status: "loading", data: null },
    });
    http
      .get(`pos-cherry/fetchPatientByPhone/${patientId}/${phoneNumber}`)
      .then((response) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_PHONE",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_PHONE",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const clearCurrentPatient = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_PATIENT" });
  };
};

export const createCherryPatient = (patientId, phone) => {
  return (dispatch) => {
    dispatch({
      type: "CREATE_CHERRY_PATIENT",
      payload: { status: "loading", data: null },
    });
    http
      .post("pos-cherry/createPatient", {
        patient: { patientId, phone },
      })
      .then((response) => {
        dispatch({
          type: "CREATE_CHERRY_PATIENT",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_CHERRY_PATIENT",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};
