import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

function createServicesByClinicIdsProviderIdsQueryKey({
  clinicIds,
  providerIds,
}) {
  return [
    QUERY_KEYS.servicesByClinicIdsProviderIds,
    ...clinicIds,
    ...providerIds,
  ];
}

export function useServicesByClinicIdsProviderIdsQuery(
  { clinicIds = [], providerIds = [] },
  options = {},
) {
  const url = HTTP_ENDPOINTS.getServicesByClinicIdsProviderIds();

  const config = {
    params: {
      clinic_ids: clinicIds,
      provider_ids: providerIds,
    },
  };
  return useQuery(
    createServicesByClinicIdsProviderIdsQueryKey({ clinicIds, providerIds }),
    () => http.get(url, config),
    options,
  );
}
