import { useEfaxFavouritesQuery } from "../../../../../api/efax/useEfaxFavouritesQuery";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export const useFavorites = (payload, options = {}) => {
  const { tSettings } = useAppTranslation.Settings();

  const { data, isLoading } = useEfaxFavouritesQuery(payload, {
    cacheTime: 0,
    onError: () => {
      uiNotification.error(tSettings("efaxIntegration.favourites.error.fetch"));
    },
    ...options,
  });

  return {
    isLoading,
    value: data?.data || [],
  };
};
