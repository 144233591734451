import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  search: yup.string(),
  filterMapped: yup.boolean().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    list: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          product_image_url: yup.string(),
          product_name: yup.string().required(),
          shopify_product_id: yup.string().required(),
          shopify_product_name: yup.string(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const list = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    list,
  };
};

function createShopifyProductListQueryKey(...args) {
  return [QUERY_KEYS.shopifyProductList.get, ...args];
}

export function useShopifyProductList({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createShopifyProductListQueryKey(
      req.search,
      req.pageSize,
      req.filterMapped,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.shopifyProductList.get(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          search: req.search,
          only_mapped: req.filterMapped ? 1 : 0,
        }),
      });

      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
