import React from "react";
import { Trans } from "react-i18next";
import classes from "./Notes.module.scss";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { PatternAutocomplete } from "../../../../../widgets/PatternAutocomplete/PatternAutocomplete";
import { useDotPhrasesPatterns } from "./hooks/useDotPhrasesPatterns";
import { PATTERNS } from "./Notes.consts";
import { useMedicalHistoryPatterns } from "./hooks/useMedicalHistoryPatterns";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Dictation } from "../../../../../widgets/Dictation/Dictation";
import { TwilioTranscription } from "../../../../../widgets/Dictation/TwilioTranscription";
import { useCombinedValue } from "./hooks/useCombinedValue";

export function Notes({ value, onChange, isLoading, clientId, appointmentId }) {
  const { tClients } = useAppTranslation.Clients();

  const dotPhrasesPatterns = useDotPhrasesPatterns();
  const medicalHistoryPatterns = useMedicalHistoryPatterns({ clientId });

  const {
    combinedValue,
    handleDictationChange,
    handleTranscriptionChange,
    handlePatternAutocompleteChange,
  } = useCombinedValue({ initialValue: value, onChange });

  return isLoading ? (
    <div className={classes.root}>
      <Skeleton count={1} height={80} />
    </div>
  ) : (
    <div className={classes.root}>
      <Dictation value={combinedValue} onChange={handleDictationChange} />
      <TwilioTranscription
        appointmentId={appointmentId}
        onChange={handleTranscriptionChange}
      />
      <AlertBox fitContent size="small">
        <Trans
          i18nKey={tClients("createEditHealthProcedure.notes.note")}
          components={{
            b: <b />,
          }}
        />
      </AlertBox>
      <div className={classes.notes}>
        <PatternAutocomplete
          value={combinedValue || ""}
          onChange={handlePatternAutocompleteChange}
          placeholder={tClients("createEditHealthProcedure.notes.placeholder")}
          patterns={[
            {
              match: PATTERNS.dot,
              substitutes: dotPhrasesPatterns.data,
            },
            {
              match: PATTERNS.medicalHistory,
              substitutes: medicalHistoryPatterns.data,
            },
          ]}
        />
        <div className={classes.note}>
          {tClients("createEditHealthProcedure.notes.dotPhrasesDesc")}
        </div>
        <div className={classes.note}>
          {tClients("createEditHealthProcedure.notes.callFuncDesc")}
        </div>
      </div>
    </div>
  );
}
