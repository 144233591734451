import * as yup from "yup";

export const authWorkspaceSchema = yup.object({
  id: yup.number().required(),
  accountId: yup.number().required(),
  name: yup.string().required(),
  status: yup.string().required(),
  imageUrl: yup.string().nullable(),
});

/**
 * @param {{
 *  workspace_id: number;
 *  account_id: number;
 *  account_name?: string;
 *  account_status: string;
 *  account_logo_url?: string;
 * }} rawAuthWorkspace
 */
export const composeAuthWorkspace = (rawAuthWorkspace) => ({
  id: rawAuthWorkspace.workspace_id,
  accountId: rawAuthWorkspace.account_id,
  name: rawAuthWorkspace.account_name || "—",
  status: rawAuthWorkspace.account_status,
  imageUrl: rawAuthWorkspace.account_logo_url || null,
});

export const createPasswordSchema = ({
  minError,
  noSpacesError,
  specialCharError,
  lowerLetterError,
  capitalLetterError,
}) =>
  yup
    .string()
    .min(8, minError)
    .test({ test: (x) => !new RegExp(/\s+/g).test(x), message: noSpacesError })
    .matches(/[!"#$%&()*,.:<>?@^{|}]/, specialCharError)
    .matches(/[a-z]/, lowerLetterError)
    .matches(/[A-Z]/, capitalLetterError);
