import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import validator from "validator";
import { tAuth, tCommon } from "../../i18n/useAppTranslation.js";
import Header from "../../_legacy/Containers/Guest/Header.js";
import Footer from "../../_legacy/Containers/Guest/Footer.js";
import { UserResetPasswordForm } from "../../Actions/ForgotPasswordAction.js";
import { Recaptcha } from "../../shared/Recaptcha/Recaptcha.js";
import { http } from "../../services/HttpService.js";
import { uiNotification } from "../../services/UINotificationService.js";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailError: "",
      showProcess: "",
      pwdchangeMessage: "",
      status: "",
      recaptcha: "",
      recaptchaInstance: "",
      recaptchaError: "",
      sitekey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };

  handleChange = (response) => {
    this.setState({ recaptchaError: response });
  };

  errorCallback = () => {};

  expiredCallback = () => {
    uiNotification.error("Recaptcha has expired. Please verify it again!");

    this.setState({
      recaptchaError: "Recaptcha has expired. Please verify it again!",
    });
  };

  resetRecaptcha = () => {
    const recaptchaInstance = this.state.recaptchaInstance;
    recaptchaInstance.reset();
  };

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    if (
      !languageData ||
      languageData.global === undefined ||
      !languageData.global
    ) {
      http
        .get("getLanguageText/1/guest")
        .then((res) => {
          const languageData = res.data.data;
          localStorage.setItem("languageData", JSON.stringify(languageData));

          this.setState({
            resetPasswordLabel: languageData["global"]["resetPasswordLabel"],
            enterYourEmail: languageData["global"]["enterYourEmail"],
            emailAddress: languageData["global"]["emailAddress"],
            backToLoginBtn: languageData["global"]["backToLoginBtn"],
            resetPasswordBtnn: languageData["global"]["resetPasswordBtnn"],
          });
        })
        .catch(function () {});
    } else {
      this.setState({
        resetPasswordLabel: languageData["global"]["resetPasswordLabel"],
        enterYourEmail: languageData["global"]["enterYourEmail"],
        emailAddress: languageData["global"]["emailAddress"],
        backToLoginBtn: languageData["global"]["backToLoginBtn"],
        resetPasswordBtnn: languageData["global"]["resetPasswordBtnn"],
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    let error = false;

    this.setState({ emailError: "" });

    if (
      typeof this.state.email === undefined ||
      this.state.email === null ||
      this.state.email === ""
    ) {
      uiNotification.error("Email can not be blank!");
      error = true;
    } else if (!validator.isEmail(this.state.email)) {
      uiNotification.error("Incorrect email address");
      error = true;
    }

    if (
      typeof this.state.recaptchaError === undefined ||
      this.state.recaptchaError === null ||
      this.state.recaptchaError.trim() === ""
    ) {
      uiNotification.error("Please verify recaptcha!");
      error = true;
    }

    if (error === true) {
      return;
    }

    this.setState({ showProcess: 1 });

    let formData = {
      email: this.state.email,
      language_id: 1,
    };

    this.props.UserResetPasswordForm(formData);
    this.setState({ email: "" });
  }

  render() {
    return (
      <div className="guest">
        <Header />
        <div className="login-container">
          <div className="login-main ar-login">
            <h1>{this.state.resetPasswordLabel}</h1>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label htmlFor="usr">{this.state.enterYourEmail}</label>
                <input
                  name="email"
                  className="form-control"
                  placeholder={this.state.emailAddress}
                  maxLength="500"
                  type="text"
                  id="UserEmailId"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={""}>
                <Recaptcha
                  style={{ display: "inline-block" }}
                  onChange={this.handleChange}
                  onExpired={this.expiredCallback}
                  onError={this.errorCallback}
                  asyncScriptOnLoad={this.asyncScriptOnLoad}
                />
              </div>

              <div className="form-group">
                <div className="backtologin pull-left">
                  <Link to="/login" className="back-btn">
                    <i className="fa fa-arrow-left"></i> &nbsp;
                    {this.state.backToLoginBtn}
                  </Link>
                </div>
                <div className="resetpswd pull-right">
                  <div className="submit">
                    <input
                      className="full-width"
                      type="submit"
                      value={this.state.resetPasswordBtnn}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if (state.UserReducer.action === "FORGOT_PASSWORD") {
    const returnState = {};
    uiNotification.clear();
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    if (state.UserReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.UserReducer.data.message] ||
          tAuth(
            `forgotPasswd.apiError.${state.UserReducer.data.message}`,
            tCommon("error.fallback"),
          ),
      );

      returnState.pwdchangeMessage = state.UserReducer.data.message;
      returnState.status = state.UserReducer.data.status;
    } else {
      returnState.status = 200;
      returnState.email = "";
      uiNotification.success(
        languageData.global[state.UserReducer.data.message],
      );
    }

    return returnState;
  } else {
    return {
      pwdchangeMessage: "",
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    UserResetPasswordForm: bindActionCreators(UserResetPasswordForm, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgotPassword));
