import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import cx from "clsx";
import classes from "./DatePickerSecondary.module.scss";
import { svg } from "../../assets/svg";

export const DatePickerSecondary = forwardRef(
  (
    {
      className,
      value,
      onChange,
      dateFormat,
      name,
      selected,
      showMonthDropdown,
      showYearDropdown,
      minDate,
      showDisabledMonthNavigation,
      isDisabled,
      pickerClassName,
      onClickOutside,
      isError,
    },
    ref,
  ) => {
    const getIcon = () => {
      let result = svg.calendarPrimary;
      if (isError) {
        result = svg.calendarError;
      }
      if (isDisabled) {
        result = svg.calendarGray0;
      }
      return result;
    };

    return (
      <div className={cx(classes.root, className)}>
        <ReactDatePicker
          value={value}
          onChange={onChange}
          dateFormat={dateFormat}
          name={name}
          selected={selected}
          autoComplete="off"
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          minDate={minDate}
          showDisabledMonthNavigation={showDisabledMonthNavigation}
          disabled={isDisabled}
          className={cx(
            { [classes.error]: isError, [classes.disabled]: isDisabled },
            pickerClassName,
          )}
          ref={ref}
          onClickOutside={onClickOutside}
        />
        <img src={getIcon()} alt="" />
      </div>
    );
  },
);

DatePickerSecondary.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  showDisabledMonthNavigation: PropTypes.bool,
  isDisabled: PropTypes.bool,
  pickerClassName: PropTypes.string,
  onClickOutside: PropTypes.func,
  isError: PropTypes.bool,
};

DatePickerSecondary.defaultProps = {
  className: undefined,
  value: undefined,
  onChange: () => {},
  dateFormat: undefined,
  name: undefined,
  selected: undefined,
  showMonthDropdown: false,
  showYearDropdown: false,
  minDate: undefined,
  showDisabledMonthNavigation: false,
  isDisabled: false,
  pickerClassName: undefined,
  onClickOutside: () => {},
  isError: false,
};
