import React, { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import {
  availableYears,
  daysOfWeek,
  monthsOfYear,
  timeSlots,
} from "../../../_legacy/Constants";
import CalendarIcon from "../../../_legacy/images/vuesax/linear-calendar.svg";
import ClockIcon from "../../../_legacy/images/vuesax/linear-clock.svg";
import HospitalIcon from "../../../_legacy/images/vuesax/linear-hospital.svg";
import InformationIcon from "../../../_legacy/images/vuesax/linear-information.svg";
import ServicesIcon from "../../../_legacy/images/vuesax/linear-password-check.svg";
import ProviderIcon from "../../../_legacy/images/vuesax/linear-profile.svg";
import {
  useGetClinicsQuery,
  useGetProvidersQuery,
  useGetServicesListQuery,
} from "../../../_legacy/Queries";
import { CommonDialog } from "../../Common";

const WaitlistStatus = [
  {
    id: "current",
    name: "Current",
  },
  {
    id: "expired",
    name: "Expired",
  },
];

const getOptionValue = (option) =>
  typeof option === "object" ? option.id : option;

const SelectComponentsProps = {
  isMulti: false,
  menuPosition: "fixed",
  components: {
    IndicatorSeparator: () => null,
  },
  styles: {
    container: (baseStyles) => ({
      ...baseStyles,
      padding: "0px !important",
      width: "100%",
    }),
    menu: (styles) => {
      return {
        ...styles,
        border: "1px solid #cad2d6",
        marginTop: "0px",
      };
    },
    control: (styles) => {
      return {
        ...styles,
        border: "1px solid #cad2d6 !important",
        borderRadius: "3px",
        boxShadow: "none",
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const defaultBgColor = isFocused ? "#dbeefd" : "#fff";

      return {
        ...styles,
        backgroundColor: isSelected ? "#047ec3" : defaultBgColor,
        color: isSelected ? "#fff" : "#404040",
        cursor: isDisabled ? "not-allowed" : "default",
        padding: "4px 12px !important",

        ":active": {
          ...styles[":active"],
          backgroundColor: isSelected ? "#047ec3" : "#fff",
          color: isSelected ? "#fff" : "#404040",
        },
      };
    },
    placeholder: () => ({
      display: "flex",
      alignItems: "center",
      color: "black",
    }),
  },
};

const WaitlistSearchModal = (props) => {
  const { onClose, searchParams, setSearchParams } = props;

  const [filters, setFliters] = useState(searchParams);

  const { data: clinicsData, dataUpdatedAt: clinicsListFetchedAt } =
    useGetClinicsQuery();

  const { data: providersData, dataUpdatedAt: providersListFetchedAt } =
    useGetProvidersQuery({
      selectedClinics: filters.clinic_id ? [filters.clinic_id] : null,
    });

  const {
    data: servicesData,
    dataUpdatedAt: servicesFetchedAt,
    isLoading: isFetchingServicesList,
  } = useGetServicesListQuery({
    providerIds: filters.provider_id ? [filters.provider_id] : null,
    clinicIds: filters.clinic_id ? [filters.clinic_id] : null,
    type: "in_person",
  });

  const clinicsList = useMemo(() => {
    if (clinicsListFetchedAt) {
      return (
        clinicsData?.data?.data.map((clinicDetails) => ({
          ...clinicDetails,
          name: clinicDetails.clinic_name,
        })) || []
      );
    } else {
      return [];
    }
  }, [clinicsListFetchedAt]);

  const providersList = useMemo(() => {
    if (providersListFetchedAt) {
      return (
        providersData?.data?.data?.map((providerDetails) => ({
          ...providerDetails,
          name: providerDetails.full_name,
        })) || []
      );
    } else {
      return [];
    }
  }, [providersListFetchedAt]);

  const servicesList = useMemo(() => {
    if (servicesFetchedAt) {
      return "services" in servicesData.data
        ? servicesData.data.services.map((serviceDetails) => ({
            ...serviceDetails,
            label: serviceDetails.name,
          }))
        : [];
    } else {
      return [];
    }
  }, [servicesFetchedAt]);

  const monthsList = useMemo(() => {
    return monthsOfYear.map((monthDetails) => ({
      ...monthDetails,
      label: monthDetails.name,
      isDisabled: Boolean(
        new Date().getUTCFullYear() === parseInt(`${filters.year}`, 10) &&
          monthDetails.id <= new Date().getUTCMonth(),
      ),
    }));
  }, [parseInt(`${filters.year}`, 10)]);

  const applyFilters = () => {
    // apply filters to the search API
    setSearchParams(filters);
    onClose();
  };

  const handleFilterChange = useCallback(
    (newValue, actionMeta) => {
      const { name } = actionMeta;
      const value = typeof newValue === "object" ? newValue.id : newValue;

      setFliters((currentFilters) => ({ ...currentFilters, [name]: value }));

      if (name === "year") {
        if (
          parseInt(value, 10) === availableYears[0].id &&
          filters.selectedMonth <= new Date().getUTCMonth()
        ) {
          setFliters((currentFilters) => ({
            ...currentFilters,
            selectedMonth: new Date().getUTCMonth() + 1,
          }));
        }
      }
    },
    [filters.selectedMonth, setFliters],
  );

  const getOptionName = useCallback(
    (option, field = "name") =>
      typeof option === "object" ? option[field] : option,
    [],
  );

  const getOptionDisabled = useCallback(
    (option) => Boolean(option.isDisabled),
    [],
  );

  return (
    <div>
      <CommonDialog title="" onClose={onClose} className="">
        <div className="col-xs-12 p-l-0 waitlist-search-modal">
          {/* Clinic Filter */}

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={HospitalIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Clinic
                </>
              }
              name="clinic_id"
              value={
                filters.clinic_id &&
                clinicsList &&
                clinicsList.find(
                  (clinicDetails) => clinicDetails.id === filters.clinic_id,
                )
              }
              options={clinicsList}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionName}
              {...SelectComponentsProps}
            />
          </div>

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={ProviderIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Provider
                </>
              }
              name="provider_id"
              value={
                filters.provider_id &&
                providersList &&
                providersList.find(
                  (providerDetails) =>
                    providerDetails.id === filters.provider_id,
                )
              }
              options={providersList}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionName}
              {...SelectComponentsProps}
            />
          </div>

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={ServicesIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Services
                </>
              }
              name="service_id"
              value={
                filters.service_id &&
                servicesList &&
                servicesList.find(
                  (serviceDetails) => serviceDetails.id === filters.service_id,
                )
              }
              options={servicesList}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionName}
              isLoading={isFetchingServicesList}
              {...SelectComponentsProps}
            />
          </div>

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={ClockIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Time Increments
                </>
              }
              name="timeslot_id"
              value={
                filters.timeslot_id &&
                timeSlots &&
                timeSlots.find(
                  (timeSlotDetails) =>
                    timeSlotDetails.id === filters.timeslot_id,
                )
              }
              options={timeSlots}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionName}
              {...SelectComponentsProps}
            />
          </div>

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={CalendarIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Days of the Week
                </>
              }
              name="day"
              value={
                filters.day &&
                daysOfWeek &&
                daysOfWeek.find(
                  (weekDayDetails) => weekDayDetails.id === filters.day,
                )
              }
              options={daysOfWeek}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionName}
              {...SelectComponentsProps}
            />
          </div>

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={CalendarIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Months
                </>
              }
              name="month"
              value={
                filters.month &&
                monthsList &&
                monthsList.find(
                  (monthDetails) => monthDetails.id === filters.month,
                )
              }
              options={monthsList}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              isOptionDisabled={getOptionDisabled}
              getOptionLabel={getOptionName}
              {...SelectComponentsProps}
            />
          </div>

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={CalendarIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Year
                </>
              }
              name="year"
              value={
                filters.year &&
                availableYears &&
                availableYears.find(
                  (yearDetails) => yearDetails.id === filters.year,
                )
              }
              options={availableYears}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionName}
              {...SelectComponentsProps}
            />
          </div>

          <div className="form-row">
            <Select
              placeholder={
                <>
                  <img
                    src={InformationIcon}
                    alt=""
                    className="search-field-placeholder-icon"
                  />
                  Status
                </>
              }
              name="status"
              value={
                filters.status &&
                WaitlistStatus &&
                WaitlistStatus.find(
                  (waitlistDetails) => waitlistDetails.id === filters.status,
                )
              }
              options={WaitlistStatus}
              onChange={handleFilterChange}
              isSearchable
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionName}
              {...SelectComponentsProps}
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary save-search-filter-buttons"
              onClick={applyFilters}
            >
              Search
            </button>
          </div>
        </div>
      </CommonDialog>
    </div>
  );
};

export default WaitlistSearchModal;
