import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, SHARE_REPORT_SERVICES } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  reportId: yup.number().nullable(),
  insightId: yup.number().required(),
  reportFilter: yup.object().required(),
});

export function useCreateLeadArShareReportMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.createLeadArShareReport(req.insightId),
      removeNullishFromShape({
        business_insight_reports_id: req.reportId || null,
        is_share_contact: 1,
        share_type: SHARE_REPORT_SERVICES.leadAr,
        with_id: 1,
        ...req.reportFilter,
      }),
    );
  }, options);
}
