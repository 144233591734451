import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getAspireConfig() {
  return (dispatch) => {
    dispatch({ type: "ASPIRE_CONFIG_LOADING", payload: {} });
    http
      .get("aspire-config", {})
      .then((response) => {
        dispatch({
          type: "GET_ASPIRE_CONFIG_SUCCESS",
          payload: { aspireConfig: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "ASPIRE_CONFIG_ERROR",
          payload: {
            error: error.response.data.message,
            invokeRedirect: error.response.data.status === 402,
          },
        });
      });
  };
}

export function patchAspireConfig(params) {
  return (dispatch) => {
    dispatch({ type: "ASPIRE_CONFIG_LOADING", payload: {} });
    http
      .patch("aspire-config", {
        aspireConfig: params,
      })
      .then((response) => {
        dispatch({
          type: "SAVE_ASPIRE_CONFIG_SUCCESS",
          payload: { aspireConfig: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "ASPIRE_CONFIG_ERROR",
          payload: {
            error: error.response.data.message,
            invokeRedirect: error.response.data.status === 402,
          },
        });
      });
  };
}

export function saveAspireConfig(params) {
  return (dispatch) => {
    dispatch({ type: "ASPIRE_CONFIG_LOADING", payload: {} });
    http
      .put("aspire-config", {
        aspireConfig: params,
      })
      .then((response) => {
        dispatch({
          type: "SAVE_ASPIRE_CONFIG_SUCCESS",
          payload: { aspireConfig: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "ASPIRE_CONFIG_ERROR",
          payload: {
            error: error.response.data.message,
            invokeRedirect: error.response.data.status === 402,
          },
        });
      });
  };
}
