import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { useCostToCompany } from "./hooks/useCostToCompany";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "costToCompanyReport.info.title",
  rows: [
    "costToCompanyReport.info.retail",
    "costToCompanyReport.info.medicalSupplies",
    "costToCompanyReport.info.service",
  ],
};

const CostToCompany = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };

  const { data, isLoading } = useCostToCompany(requestParams);

  const tableDataEmpty = !isLoading && !data?.reportData?.length;

  const tableData = useMemo(
    () =>
      data?.reportData.map((data) => ({
        item: data.product_name,
        unitsSold: data.category_Items_Sold,
        subTotal: data.category_gross_sales,
        costToCompany: data.cost_to_company,
        percentSales: data.total_sales_per,
        itemDiscount: data.discount_percentage,
        taxableAmount: data.taxable_amount,
      })),
    [data],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const totalDatas = [
    ...(tableData || []),
    !tableDataEmpty &&
      !isLoading && {
        item: <span className="bold">Total</span>,
        unitsSold: <span className="bold">{data?.total?.product_units}</span>,
        subTotal: (
          <span className="bold">{data?.total?.category_gross_sales}</span>
        ),
        costToCompany: (
          <span className="bold">{data?.total?.cost_to_company}</span>
        ),
        percentSales: (
          <span className="bold">{data?.total?.total_sales_per}</span>
        ),
        itemDiscount: (
          <span className="bold">{data?.total?.discount_percentage}</span>
        ),
        taxableAmount: (
          <span className="bold">{data?.total?.taxable_amount}</span>
        ),
      },
  ].filter(Boolean);

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Cost To Company"
        selectedClinic={selectedClinic}
        period={period}
        clinics={clinics}
        downloadRoute={HTTP_ENDPOINTS.getCostToCompany()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Item", accessor: "item" },
              { data: "Units Sold", accessor: "unitsSold" },
              { data: "Sub-Total", accessor: "subTotal" },
              { data: "Cost To Company", accessor: "costToCompany" },
              { data: "Percent Sales", accessor: "percentSales" },
              { data: "Item Discount", accessor: "itemDiscount" },
              { data: "Taxable Amount", accessor: "taxableAmount" },
            ]}
            data={totalDatas}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default CostToCompany;
