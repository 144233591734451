import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  searchTerm: yup.string(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    surveys: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          title: yup.string().nullable(),
          whenToSend: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const data = unwrapOr(() => res.data.data.data, []);
  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data, {}),
      pageSize: req.pageSize,
    }),
    surveys: data.map((s) => ({
      id: s.id,
      title: s.title || null,
      whenToSend: s.when_to_send || null,
    })),
  };
};

function createDeletedSurveysQueryKey({ searchTerm, pageSize }) {
  return [QUERY_KEYS.deletedSurveys, searchTerm, pageSize].filter(Boolean);
}

export function useDeletedSurveysQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createDeletedSurveysQueryKey({
      searchTerm: req.searchTerm,
      pageSize: req.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getDeletedSurveys(), {
        params: {
          page: pageParam,
          pagesize: req.pageSize,
          term: req.searchTerm || "",
        },
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
