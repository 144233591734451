import klarna from "./img/klarna.png";
import careCredit from "./img/img_care-credit.png";
import greenSky from "./img/img_green-sky.png";
import link from "./img/link.png";

export const img = {
  klarna,
  careCredit,
  greenSky,
  link,
};
