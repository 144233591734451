import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useGrossSales = (params, options = {}) => {
  return useQuery(
    ["netGrossSalesReport", params],
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getGrossSales(), {
        params,
      });
      const composeResponse = {
        clinics: res.data.data.clinics,
        clinicGrossSales: res.data.data.clinic_gross_sales,
        providerGrossSales: res.data.data.provider_gross_sales,
      };
      return composeResponse;
    },
    options,
  );
};
