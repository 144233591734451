import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../../assets/svg";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { useInvoice } from "../../../hooks/invoice/useInvoice";

const PaymentTipsModal = ({
  isTipsApplying,
  totalAmountToPay,
  showTipsModal,
  submit,
  isLoading,
}) => {
  const { invoice } = useInvoice();
  const onClose = () => {
    showTipsModal.update(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.text2pay")}
      imageSrc={svg.text2Pay}
      onCharge={submit}
      isCharging={isLoading}
      isChargeDisabled={isTipsApplying}
      chargeLabel={tCommon("label.apply")}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={totalAmountToPay}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          onChange={() => {}}
          isDisabled
        />
      </ModalCharge.InputWrap>
    </ModalCharge>
  );
};

export default PaymentTipsModal;
