import React from "react";

export function ClinicRow({ label, children }) {
  return (
    <div className="row">
      <div className="col-md-4 col-sm-3 col-xs-12">
        <div className="prod-price-clinic-name">{label}</div>
      </div>
      {children}
    </div>
  );
}
