import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./InputError.module.scss";

export function InputError({ children, className }) {
  return <div className={cx(classes.root, className)}>{children}</div>;
}

InputError.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

InputError.defaultProps = {
  className: undefined,
};
