import React from "react";
import choiceClasses from "../../shared/Choice/Choice.module.scss";
import { Input } from "../../../../../../../shared/Input/Input";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Choice } from "../../shared/Choice/Choice";

export function ChoiceText({ value, onChange, onRemove, index }) {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <Choice index={index} onRemove={onRemove}>
      <Input
        size="small"
        value={value}
        onChange={onChange}
        className={choiceClasses.input}
        placeholder={tSettings(
          "questionnaires.questionBuilder.choicePlaceholder",
        )}
      />
    </Choice>
  );
}
