import React from "react";
import { getPathParams } from "../../utils/utils";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { phoneUtil } from "../../../../../utilities/phone";
import styles from "./AditionalContactInformation.module.scss";
import cx from "clsx";

export function AdditionalContactInformation() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({clientId});

  return (
    <div className={cx(styles.infoWrapper, "col-xs-12")}>
      <div className={styles.sectionTitle}>Additional Contact Information</div>
      {[clientData?.phone2, clientData?.phone3].some(Boolean) && (
        <div>
          <div className={styles.itemTitle}>Phone</div>
          {Boolean(clientData?.phone2) && (
            <div className={styles.itemText}>
              {phoneUtil.formatWithDashes(clientData?.phone2)}
            </div>
          )}
          {Boolean(clientData?.phone3) && (
            <div className={styles.itemText}>
              {phoneUtil.formatWithDashes(clientData?.phone3)}
            </div>
          )}
        </div>
      )}
      {[clientData?.email2, clientData?.email3].some(Boolean) && (
        <div>
          <div className={styles.itemTitle}>Email</div>
          {Boolean(clientData?.email2) && (
            <div className={styles.itemText}>{clientData?.email2}</div>
          )}
          {Boolean(clientData?.email3) && (
            <div className={styles.itemText}>{clientData?.email3}</div>
          )}
        </div>
      )}
    </div>
  );
}
