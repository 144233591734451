import { ACTION_TYPES } from "./consts";

export const actions = {
  setInventoryProductsFilter: (inventoryFilter) => ({
    type: ACTION_TYPES.setFilter,
    payload: {
      inventoryFilter,
    },
  }),

  removeInventoryProductsFilter: () => ({
    type: ACTION_TYPES.removeFilter,
    payload: {
      inventoryFilter: null,
    },
  }),
};
