import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, matchPath } from "react-router-dom";
import { useEffect } from "react";
import { lockAppUntilAccountAddressUpdated } from "../../utilities/localStorage";

const ALLOWED_ROUTES = [
  // Added allowed routes for on hold or canceled account status
  "/settings/manage-subscription",
  "/settings/manage-invoices",
  "/settings/resource-usage",
  "/dashboard/user-logs",
  "/settings/manage-subscription-upgrade",
];

const REDIRECT_TO = ALLOWED_ROUTES[0];

function AccountStatusRedirect(props) {
  function redirectToAllowedRoute(location) {
    const isOnAllowedRoute = Boolean(
      matchPath(location.pathname, { path: ALLOWED_ROUTES }),
    );

    if (!lockAppUntilAccountAddressUpdated.get()) {
      if (
        (props.isAccountOnHold || props.isAccountCancelled) &&
        !isOnAllowedRoute
      ) {
        props.history.push(REDIRECT_TO); // Redirect to manage-subscription page if account status is on hold or canceled
      }
    }
  }

  useEffect(() => {
    redirectToAllowedRoute(props.location);
  }, [props.isAccountOnHold]);

  useEffect(() => {
    return props.history.listen(redirectToAllowedRoute);
  }, [redirectToAllowedRoute]);

  return null;
}

function mapStateToProps(state) {
  return {
    isAccountOnHold: state.AccountHoldRedirectReducer.isAccountOnHold,
    isAccountCancelled: state.AccountHoldRedirectReducer.isAccountCancelled,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AccountStatusRedirect));
