import * as yup from "yup";

export const consentSchema = yup.object({
  id: yup.number().required(),
  consent_name: yup.string().required(),
  consent_large_description: yup.string().nullable(),
  added_by: yup.string().nullable(),
  initials_per_page: yup.number().max(1).min(0).required(),
  witness_signature: yup.number().max(1).min(0).required(),
});
