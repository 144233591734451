import { useEffect } from "react";
import { getAccountId } from "../utilities/localStorage";
import { ROUTES } from "../consts/routes";
import { MAINTENANCE_MODE } from "../consts/general";

export const useMaintenanceModeRedirect = () => {
  const ALLOWED_TEST_ACCOUNT = [1300, 9374]; // TODO: Remove this line

  const redirectToMaintenancePage = () => {
    if (
      MAINTENANCE_MODE &&
      getAccountId() &&
      !ALLOWED_TEST_ACCOUNT.includes(getAccountId())
    ) {
      history.push(ROUTES.maintenance());
    }
  };

  useEffect(() => {
    redirectToMaintenancePage();
  }, [getAccountId(), window.location.pathname]);
};
