import React from "react";
import cx from "clsx";
import summaryClasses from "../scss/salesSummary.module.scss";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";

function SummaryTable({ report, isLoading = { isLoading } }) {
  return (
    <div className={summaryClasses.container}>
      {isLoading ? (
        <div className={summaryClasses.tableData}>
          <Skeleton height={32} count={8} />
        </div>
      ) : (
        <table className={summaryClasses.reportTable}>
          <thead className={summaryClasses.headTable}>
            <tr>
              {report.reportHeader.map((header, index) => {
                return (
                  <th
                    key={index}
                    className={cx(index === 1 && summaryClasses.alignRight)}
                  >
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {report.reportData?.map((row, index) => {
              return (
                <tr key={index}>
                  <td className={summaryClasses.tableData}>{row.label}</td>
                  <td
                    className={cx(
                      summaryClasses.tableData,
                      summaryClasses.alignRight,
                    )}
                  >
                    {row.value}
                  </td>
                </tr>
              );
            })}
            {report.negativeDatas?.map((row, index) => {
              return (
                <tr key={index}>
                  <td className={summaryClasses.negativeTableData}>
                    - {row.label}
                  </td>
                  <td
                    className={cx(
                      summaryClasses.tableData,
                      summaryClasses.alignRight,
                    )}
                  >
                    {row.value}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td
                className={cx(
                  summaryClasses.tableData,
                  summaryClasses.totalTable,
                )}
              >
                {report.total?.label}
              </td>
              <td
                className={cx(
                  summaryClasses.tableData,
                  summaryClasses.alignRight,
                  summaryClasses.totalTable,
                )}
              >
                {report.total.value}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SummaryTable;
