import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { useSalesExpiringLiabilityReportQuery } from "../../../../../../../../api/queries/useSalesExpiringLiabilityReportQuery";
import { useCurrentAccountQuery } from "../../../../../../../../api/queries/useAccountQuery";

export function useReports() {
  const { tCommon } = useAppTranslation.Common();
  const { data: account, isLoading: isAccountLoading } =
    useCurrentAccountQuery();

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useSalesExpiringLiabilityReportQuery({
    payload: {
      pageSize: 15,
    },
    options: {
      enabled:
        !isAccountLoading && account?.preferences?.isWalletExpirationConfigured,
      staleTime: 0,
      cacheTime: 0,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  });

  const isSoftLoading = isFetching && !isLoading && !isFetchingNextPage;

  const reports = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  return {
    reports: {
      total: data?.pages?.[0]?.stats?.total,
      value: reports,
      isLoading,
      isSoftLoading,
      hasMore: Boolean(hasNextPage),
      fetchMore: fetchNextPage,
      isNextFetching: isFetchingNextPage,
      isWalletExpirationConfigured: Boolean(
        account?.preferences?.isWalletExpirationConfigured,
      ),
      isAccountLoading,
    },
  };
}
