import { useEfaxTemplateCreateMutation } from "../../../../../../../api/efax/useEfaxTemplateCreateMutation";
import { useEfaxTemplateUpdateMutation } from "../../../../../../../api/efax/useEfaxTemplateUpdateMutation";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { getPathParams } from "../../../utilities";

export const useSubmit = () => {
  const { templateId } = getPathParams();
  const { tSettings } = useAppTranslation.Settings();

  const updateMutation = useEfaxTemplateUpdateMutation({
    onError: () => {
      uiNotification.error(tSettings("efaxTemplates.error.update"));
    },
    onSuccess: () => {
      uiNotification.success(tSettings("efaxTemplates.success.update"));
    },
  });

  const createMutation = useEfaxTemplateCreateMutation({
    onError: () => {
      uiNotification.error(tSettings("efaxTemplates.error.create"));
    },
    onSuccess: () => {
      uiNotification.success(tSettings("efaxTemplates.success.create"));
    },
  });

  const initiate = (formValues) => {
    if (templateId) {
      return updateMutation.mutateAsync({
        id: templateId,
        name: formValues.name,
        content: formValues.content,
      });
    }

    return createMutation.mutateAsync({
      name: formValues.name,
      content: formValues.content,
    });
  };

  return {
    initiate,
    isLoading: updateMutation.isLoading || createMutation.isLoading,
  };
};
