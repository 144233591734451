import { useClientQuery } from "../../../../api/queries/useClientQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { getPathParams } from "../PatientVitals.utils";

export function usePatient() {
  const { patientId } = getPathParams();
  const { tCommon } = useAppTranslation.Common();

  const { data, isFetching } = useClientQuery(
    {
      clientId: Number(patientId),
    },
    {
      enabled: Boolean(patientId),
      onError: () => {
        uiNotification.error(tCommon("error.fetchClient"));
      },
    },
  );

  return {
    data,
    isLoading: isFetching,
  };
}
