import queryString from "query-string";
import { usePPAnnoucementBannerQuery } from "../../../../../../../api/patientPortal/announcementBanner/usePPAnnouncementBannerQuery";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";

export function useBanner() {
  const bannerId = queryString.parse(window.location.search)?.bannerId;
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();

  const { data, isFetching, isError } = usePPAnnoucementBannerQuery(
    {
      bannerId: Number(bannerId),
    },
    {
      enabled: !!Number(bannerId),
      onError: () => {
        uiNotification.error(t("patientPortal.banner.error.fetchOne"));
      },
    },
  );

  return {
    data,
    isLoading: isFetching,
    isError,
  };
}
