import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  clinicId: yup.number().required(),
  goal: yup.number().required(),
  goalId: yup.number().nullable(),
  month: yup.string().required(),
  year: yup.number().required(),
});

export function useSalesGoalMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.mutateSalesGoal(),
        removeNullishFromShape({
          clinic_id: req.clinicId,
          goal: req.goal,
          goal_id: req.goalId,
          goal_month: req.month,
          goal_year: req.year,
        }),
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.salesGoals],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.salesGoal],
        });
        options.onSuccess?.(...args);
      },
    },
  );
}
