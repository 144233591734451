import thunk from "redux-thunk";
import logger from "redux-logger";
import { applyMiddleware, createStore as createReduxStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { rootReducer } from "./rootReducer";

let middlewares = [thunk];

if (process.env.REACT_APP_SHOW_REDUX_LOGGER === true) {
  middlewares.push(logger);
}

export const createStore = (preloadedState = {}) => {
  return createReduxStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
};

export const store = createStore();
export const dispatch = store.dispatch;
