import { useMutation } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../consts/api";

export const useChargeWithText2PayMutation = () => {
  return useMutation((req) => {
    return http.post(HTTP_ENDPOINTS.postChargeInvoiceWithText2Pay(), {
      invoice_id: req.invoiceId,
      amount: req.amount,
      patient_phone: req.phoneNumber,
      patient_email: req.email,
    });
  });
};
