import React from "react";
import { connect } from "react-redux";
import classes from "../../widgets/Amount/Amount.module.scss";
import { TipsApplier } from "../../boxes/TipsApplier/TipsApplier";
import { tSales } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { MergedInvoice } from "../../widgets/Amount/MergedInvoice/MergedInvoice";
import styles from "./scss/viewTipsModal.module.scss";
import TipsModalField from "./components/TipsModalField";
import { formatCurrency } from "../../../../../utilities/general";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";

const ViewTipsModal = ({ tipsDisabled, isTipsOnlyFlow }) => {
  const {
    invoice,
    beforeTipAmount,
    currencySymbol,
    isBalanceDueStage,
    amountToPay,
  } = useInvoice();
  const { applyTip } = useInvoiceActions();
  const { isTipsAvailable } = usePaymentFlow();

  const subtotalAmount = invoice?.subtotalAmount;

  const onApplyCustomTip = async ({ amount }) => {
    await applyTip.initiate({ tipAmount: amount });
  };

  const onApplyTip = (tipOptionValue) => {
    applyTip.initiate({
      tipAmount: applyTip.optionToAmount(tipOptionValue),
    });
  };

  const showTips = isTipsAvailable && !isTipsOnlyFlow;

  return (
    <div className={styles.modalView}>
      <TipsModalField label="Invoice Amount" amount={beforeTipAmount} />
      {showTips && (
        <div className={classes.tipsWrap}>
          <div className={classes.tipHeader}>
            {tSales("checkoutInvoice.tips.header")}
          </div>
          {!invoice?.isMerger && (
            <TipsApplier
              onApply={onApplyTip}
              onApplyCustom={onApplyCustomTip}
              tipAmount={invoice?.tipAmount}
              isApplying={tipsDisabled || applyTip.isLoading}
              currencySymbol={invoice?.currencySymbol}
              subtotalAmount={subtotalAmount}
            />
          )}
          {invoice?.isMerger &&
            invoice?.mergedInvoices?.map(
              (mergedInvoice, index) =>
                mergedInvoice.isTipsEnabled && (
                  <MergedInvoice key={index} mergedInvoice={mergedInvoice} />
                ),
            )}
        </div>
      )}
      <TipsModalField
        label="Total Due"
        amount={invoice.totalAmount}
        info={`${formatCurrency(
          beforeTipAmount,
          currencySymbol,
        )} + ${formatCurrency(invoice.tipAmount, currencySymbol)} tip`}
      />
      {isBalanceDueStage && (
        <TipsModalField label="Balance Due" amount={amountToPay} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTipsOnlyFlow: checkoutInvoice.selectors.selectIsTipsOnlyFlow(state),
});

export default connect(mapStateToProps)(ViewTipsModal);
