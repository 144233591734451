import { usePatientVitalsSettingRangeDeleteMutation } from "../../../../../../../../../../api/patientVitals/usePatientVitalsSettingRangeDeleteMutation";
import { useAppTranslation } from "../../../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../../../services/UINotificationService";

/**
 * @param {number} id
 */
export const useDelete = (id) => {
  const { tClients } = useAppTranslation.Clients();

  const { mutateAsync, isLoading } = usePatientVitalsSettingRangeDeleteMutation(
    {
      onError: () => {
        uiNotification.error(tClients("vitals.settings.error.deleteBmi"));
      },
      onSuccess: () => {
        uiNotification.success(tClients("vitals.settings.success.deleteBmi"));
      },
    },
  );

  return {
    initiate: () => mutateAsync({ id }),
    isLoading,
  };
};
