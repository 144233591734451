import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDebouncedState } from "../../../../../utilities/hooks/useDebouncedState";
import { InputSearch } from "../../../../../boxes/InputSearch/InputSearch";

export function ConsentSearchInput({ onSearch }) {
  const [state, debouncedState, setState] = useDebouncedState();

  useEffect(() => {
    onSearch(debouncedState);
  }, [debouncedState]);

  return <InputSearch value={state} onChange={setState} />;
}

ConsentSearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
