import {
  SALES_REPORT_EXPORT_NAMES,
  useSalesReportExportMutation,
} from "../../../../../../api/mutations/useSalesReportExportMutation";
import { FILE_EXPORT_TYPES } from "../../../../../../consts/api";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { composeDownloadDataUrl } from "../../../../../../utilities/api";

export function useDownloadExcel({ period, filter }) {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useSalesReportExportMutation({
    onError: () => {
      uiNotification.error(tCommon("error.exportXlsReports"));
    },
  });

  const initiate = async () => {
    const res = await mutateAsync({
      fromDate: period.value.from,
      toDate: period.value.to,
      type: FILE_EXPORT_TYPES.xls,
      name: SALES_REPORT_EXPORT_NAMES.membershipChurnReport,
      clinicIds: filter.value.clinics.map((c) => c.value),
      tierIds: filter.value.tiers.map((t) => t.value),
      churnReason: filter.value.churnReason,
    });

    window.open(composeDownloadDataUrl(res.fileName), "_blank");
  };

  return {
    initiate,
    isLoading,
  };
}
