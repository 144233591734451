import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Box } from "../../../../../shared/Box/Box";
import classes from "./LabeledBox.module.scss";

export function LabeledBox({ label, children, className, contentClassName }) {
  return (
    <Box className={cx(classes.root, className)}>
      <div className={classes.label}>{label}</div>
      <div className={contentClassName}>{children}</div>
    </Box>
  );
}

LabeledBox.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

LabeledBox.defaultProps = {
  className: undefined,
  contentClassName: undefined,
};
