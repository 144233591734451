import React, { useState } from "react";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { Checkbox } from "../../../../../../shared/Checkbox/Checkbox";
import { Button } from "../../../../../../shared/Button/Button";
import { not } from "../../../../../../utilities/fp";
import styles from "./styles.module.scss";

export function UpgradeAcknowledgement({ onClose, isOpen, onConfirm }) {
  var TERMS_URL = `${window.location.origin}/settings/ar-terms-of-use`;

  var [accepted, setAccepted] = useState(false);

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={styles.content}
      header={
        <Modal.Title>Important Information About Your Upgrade</Modal.Title>
      }
      footer={
        <div className={styles.footer}>
          <Checkbox
            isChecked={accepted}
            onChange={() => setAccepted(not)}
            className={styles.checkbox}
            labelClassName={styles.checkboxLabel}
            label={
              <>
                I acknowledge that I will be charged a non-refundable{" "}
                <b>
                  <i>Setup Fee</i>
                </b>{" "}
                by continuing with my account upgrade.
              </>
            }
          />
          <Button isDisabled={!accepted} onClick={onConfirm}>
            Continue
          </Button>
        </div>
      }
    >
      Please note, you will be charged a non-refundable{" "}
      <b>
        <i>Setup Fee</i>
      </b>{" "}
      to upgrade your existing trial account. By clicking continue, you are
      agreeing to and accepting these{" "}
      <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
        terms
      </a>
      .
    </Modal>
  );
}
