import React, { useState } from "react";
import cx from "clsx";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import styles from "./ClientSettings.module.scss";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { usePatientNotificationsMutation } from "../../../../../api/patient/usePatientNotificationsMutation";
import { useFirePatientMutation } from "../../../../../api/patient/useFirePatientMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { getPathParams } from "../../utils/utils";
import { useClientInfo } from "../../ClientInfo";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

export function ClientSettings() {
  const { clientId } = getPathParams();
  const { showLoader, hideLoader } = useClientInfo();
  const { tClients} = useAppTranslation.Clients();

  const [isOpen, setIsOpen] = useState(false);

  const { data: clientData } = useClientQuery({ clientId });

  const onOpen = (e) => {
    e.preventDefault();
    setIsOpen(true);
  }

  const onClose = () => {
    setIsOpen(false);
  };

  const changeNotification = usePatientNotificationsMutation({
    onSuccess: () => {
      uiNotification.success("Settings have been successfully saved");
    },
    onError: () => {
      uiNotification.error("Unable to save settings. Try again later");
    },
   onMutate: () => {
      showLoader();
   },
    onSettled: () => {
      hideLoader();
    }
  });

  const fireClient = useFirePatientMutation({
    onSuccess: () => {
      onClose();
      uiNotification.success(tClients("profile.fire.success"));
    },
    onError: () => {
      uiNotification.error(tClients("profile.fire.error"));
    },
  });

  const changeNotificationStatus = (event) => {
    const name = event.target.name;
    changeNotification.mutate({
      clientId,
      settingName: name,
    });
  };

  const getConfirmFireModalMessage  = () => {
    if (clientData?.isFired) {
      return tClients("profile.fire.message.activate");
    }

    return tClients("profile.fire.message.fire");
  };

  const changeFiredStatus  = () => {
    fireClient.mutate({ clientId });
  };

  return (
    <div className={cx(styles.patientSettingSection, "col-xs-12")}>
      <div className={cx(styles.title)}>Communication Consent</div>
      <div className={styles.settingsBlock}>
        <div className={styles.clientSetting}>
          <span>{tClients("profile.fire.label")}</span>
          <label className="setting-switch pull-right">
            <input
              type="checkbox"
              checked={clientData?.isFired}
              name="fireCheck"
              disabled={changeNotification.isLoading}
              className="setting-custom-switch-input"
              onClick={onOpen}
            />
            <span className="setting-slider"></span>
          </label>
        </div>
        <div className={styles.clientSetting}>
          <span>Do Not Call</span>
          <label className="setting-switch pull-right">
            <input
              type="checkbox"
              name="do_not_call"
              disabled={changeNotification.isLoading}
              checked={clientData?.doNotCall}
              onChange={changeNotificationStatus}
              className="filled-checkbox"
            />
            <span className="setting-slider"></span>
          </label>
        </div>
        <div className={styles.clientSetting}>
          <span>Do Not SMS</span>
          <label className="setting-switch pull-right">
            <input
              type="checkbox"
              name="do_not_sms"
              disabled={changeNotification.isLoading}
              checked={clientData?.doNotSms}
              onChange={changeNotificationStatus}
              className="filled-checkbox"
            />
            <span className="setting-slider"></span>
          </label>
        </div>
        <div className={styles.clientSetting}>
          <span>Do Not Email</span>
          <label className="setting-switch pull-right">
            <input
              type="checkbox"
              name="do_not_email"
              disabled={changeNotification.isLoading}
              checked={clientData?.doNotEmail}
              onChange={changeNotificationStatus}
              className="filled-checkbox"
            />
            <span className="setting-slider"></span>
          </label>
        </div>
      </div>
      {isOpen && (
        <ConfirmModal
          isOpen={isOpen}
          isConfirming={fireClient.isLoading}
          onConfirm={changeFiredStatus}
          onCancel={onClose}
          onClose={onClose}
        >{getConfirmFireModalMessage()}</ConfirmModal>
      )}
    </div>
  );
}
