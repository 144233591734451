import React from "react";

export const ArrowUpIcon = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ width }}
  >
    <path d="M8 6L12 2L16 6" />
    <path d="M12 2V22" />
  </svg>
);
