import React from "react";
import PropTypes from "prop-types";
import classes from "./FileBtn.module.scss";
import cx from "clsx";
import { EXAM_STATUSES } from "../../QualiphyExamProcedure.consts";

export function FileBtn({ type, children, downloadLink }) {
  const iconByType = {
    [EXAM_STATUSES.approved]: (
      <i className="fa fa-check" aria-hidden="true"></i>
    ),
    [EXAM_STATUSES.rejected]: (
      <i className="fa fa-times" aria-hidden="true"></i>
    ),
    [EXAM_STATUSES.notApplicable]: (
      <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
    ),
    [EXAM_STATUSES.expired]: null,
  };

  const isDisabled = !downloadLink;

  const onClick = () => {
    if (downloadLink) {
      window.open(downloadLink, "_blank");
    }
  };

  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={cx(classes.root, {
        [classes.warn]: type === EXAM_STATUSES.notApplicable,
        [classes.fade]: type === EXAM_STATUSES.expired,
        [classes.disabled]: isDisabled,
      })}
    >
      {children}
      {iconByType[type]}
    </button>
  );
}

FileBtn.propTypes = {
  type: PropTypes.string,
  children: PropTypes.string.isRequired,
  downloadLink: PropTypes.string,
};

FileBtn.defaultProps = {
  type: null,
  downloadLink: null,
};
