import React from "react";
import PropTypes from "prop-types";
import classes from "./ChoiceImage.module.scss";

export function ChoiceImage({ url, label }) {
  return (
    <div className={classes.root}>
      <img src={url} alt={label} />
      {label && <div>{label}</div>}
    </div>
  );
}

ChoiceImage.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
};
