import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.string().required();

const composeResponse = (res) => {
  return res?.data?.data;
};

const createQualiphyRxMessageQueryKey = () => {
  return [QUERY_KEYS.qualiphy.rxMessage];
};

export const useQualiphyRxMessageQuery = (options = {}) => {
  return useQuery(
    createQualiphyRxMessageQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.qualiphy.getRxMessage());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
