import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Chip.module.scss";

export function Chip({ children, isSelected, onSelect, className }) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.selected]: isSelected,
    },
    className,
  );
  return (
    <button className={rootClasses} type="button" onClick={onSelect}>
      {children}
    </button>
  );
}

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Chip.defaultProps = {
  className: undefined,
};
