import React from "react";
import { LayoutGuest } from "../../boxes/LayoutGuest/LayoutGuest";
import { getPathParams } from "./utilities";
import { useGuard } from "./hooks/useGuard";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { AlertBox } from "../../shared/AlertBox/AlertBox";
import styles from "./styles.module.scss";
import { ChangePassword as Widget } from "../../widgets/ChangePassword/ChangePassword";
import { ROUTES } from "../../consts/routes";
import { history } from "../../history";

export function ChangePassword() {
  useGuard();

  var { tAuth: t } = useAppTranslation.Auth();
  var { wpuId, withMessage, requirePasscode } = getPathParams();

  return (
    <LayoutGuest>
      <h2 className={styles.title}>{t("changePassword.title")}</h2>
      <Widget
        wpuId={wpuId}
        className={styles.root}
        requirePasscode={requirePasscode}
        header={
          withMessage ? (
            <AlertBox center size="small">
              {t("changePassword.message")}
            </AlertBox>
          ) : undefined
        }
        onSuccess={() => {
          history.push(ROUTES.login());
        }}
      />
    </LayoutGuest>
  );
}
