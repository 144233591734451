import _ from "lodash";

/**
 * @param {{
 *    [key: number]: {
 *      choices: string[];
 *    };
 * }} answers
 *
 * @param {ReturnType<typeof questionsAdapter>} adaptedQuestions
 */
export function getAnswersStatus(answers, adaptedQuestions) {
  var extractId = (x) => x.id;

  var questionIds = adaptedQuestions.map(extractId);

  var isCompleted = (() => {
    const requiredIds = adaptedQuestions
      .filter((question) => question.isRequired)
      .map(extractId);

    return requiredIds.every((id) => answers?.[id]?.choices?.length > 0);
  })();

  var untilLastRequiredNotCompletedIds = (() => {
    if (!isCompleted) {
      const lastCompletedIndex = _.findLastIndex(
        questionIds,
        (id) =>
          answers?.[id]?.choices?.length > 0 &&
          adaptedQuestions.find((q) => String(q.id) === String(id)).isRequired,
      );

      if (lastCompletedIndex === -1) {
        return [];
      }

      return questionIds.slice(0, lastCompletedIndex + 2);
    }
    return [];
  })();

  return {
    isCompleted,
    untilLastRequiredNotCompletedIds,
  };
}
