import { usePieChartsOptions } from "./usePieChartsOptions";

export const usePieChartsData = (data) => {
  const giftCardsPurchasedData = data?.gift_card_purchased.map((giftCard) => ({
    title: giftCard.name,
    y: giftCard.percentage,
    z: giftCard.percentage,
    totalNum: giftCard.purchased,
    name:
      giftCard.name +
      ", " +
      giftCard.purchased +
      " / " +
      giftCard.percentage +
      "%",
  }));

  const giftCardsRedeemedData = data?.gift_card_redeemed.map((giftCard) => ({
    title: giftCard.name,
    y: giftCard.percentage,
    z: giftCard.percentage,
    totalNum: giftCard.redeemed,
    name:
      giftCard.name +
      ", " +
      giftCard.redemption +
      " / " +
      giftCard.percentage +
      "%",
  }));

  const giftCardTransactionsData = [
    {
      name:
        "eGift cards sold, " +
        data?.gift_card_transactions.total_sold +
        " / " +
        data?.gift_card_transactions.sold_percentage +
        "%",
      y: data?.gift_card_transactions.sold_percentage,
      z: data?.gift_card_transactions.sold_percentage,
    },
    {
      name:
        "Redeems, " +
        data?.gift_card_transactions.total_redemption +
        " / " +
        data?.gift_card_transactions.total_redeemed_percentage +
        "%",
      y: data?.gift_card_transactions.total_redeemed_percentage,
      z: data?.gift_card_transactions.total_redeemed_percentage,
    },
  ];

  return {
    giftCardsPurchased: usePieChartsOptions(giftCardsPurchasedData),
    giftCardsRedeemed: usePieChartsOptions(giftCardsRedeemedData),
    giftCardTransactions: usePieChartsOptions(giftCardTransactionsData),
  };
};
