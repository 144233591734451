import React, { useRef } from "react";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { DatePicker } from "../../../../shared/DatePicker/DatePicker";
import { InputError } from "../../../../shared/InputError/InputError";
import { Textarea } from "../../../../shared/Textarea/Textarea";
import styles from "./styles.module.scss";
import { Button } from "../../../../shared/Button/Button";
import { useForm } from "./hooks/useForm";
import { useSubmit } from "./hooks/useSubmit";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

export const Form = ({ giftCardId, onSuccess, onCancel }) => {
  const { tCommon } = useAppTranslation.Common();
  const datePickerRef = useRef(null);

  const submit = useSubmit(giftCardId, onSuccess);
  const form = useForm(submit.initiate);

  return (
    <div className={styles.root}>
      <div className={styles.newExpiryDate}>
        <InputLabel>
          {tCommon("modalEditGiftCardExpiryDate.form.newExpireDate")}
        </InputLabel>
        <DatePicker
          selected={form.date.value}
          value={form.date.formattedValue}
          onChange={form.date.onChange}
          isError={!!form.date.getError()}
          minDate={new Date()}
          dateFormat="yyyy-MM-dd"
          showDisabledMonthNavigation
          ref={datePickerRef}
          onClickOutside={() => datePickerRef.current.setOpen(false)}
          onChangeRaw={(e) => e.preventDefault()}
        />
        {!!form.date.getError() && (
          <InputError>{form.date.getError()}</InputError>
        )}
      </div>
      <div>
        <InputLabel>
          {tCommon("modalEditGiftCardExpiryDate.form.reason")}
        </InputLabel>
        <Textarea
          value={form.reason.value}
          onChange={form.reason.onChange}
          isError={!!form.reason.getError()}
        />
        {!!form.reason.getError() && (
          <InputError>{form.reason.getError()}</InputError>
        )}
      </div>
      <div className={styles.footer}>
        <Button variant="outlined" onClick={onCancel}>
          {tCommon("modalEditGiftCardExpiryDate.form.cancel")}
        </Button>
        <Button
          onClick={form.submit}
          isDisabled={submit.isLoading}
          leftAdornment={
            submit.isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tCommon("modalEditGiftCardExpiryDate.form.submit")}
        </Button>
      </div>
    </div>
  );
};
