import { http } from "../../services/HttpService";
import { uiNotification } from "../../services/UINotificationService";

let timeoutId;

export const fetchKlarnaAvailability = () => {
  return (dispatch) => {
    dispatch({
      type: "KLARNA_AVAILABILITY",
      payload: { status: "loading", data: null },
    });
    http
      .get("stripe/checkout/klarna/capability")
      .then((response) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "success", data: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const updateKlarnaAvailability = (clinicId, isEnabled) => {
  return async (dispatch) => {
    dispatch({
      type: "KLARNA_AVAILABILITY",
      payload: { status: "loading", data: null },
    });
    return http
      .put(`stripe/checkout/klarna/capability/${clinicId}`, {
        is_klarna_enabled: isEnabled,
      })
      .then((response) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "success", data: response.data.data },
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "error", data: error.response.data },
        });
        dispatch(fetchKlarnaAvailability());
        return Promise.reject(error.response.data);
      });
  };
};

export const createKlarnaPayment = (klarnaPayment) => {
  return async (dispatch) => {
    dispatch({
      type: "CREATE_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    await http
      .post("stripe/checkout/klarna", {
        klarnaPayment,
      })
      .then((response) => {
        dispatch({
          type: "CREATE_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data },
        });
        dispatch({
          type: "FETCH_KLARNA_PAYMENT",
          payload: { status: "loading", data: null },
        });
        dispatch({
          type: "ONGOING_KLARNA_PAYMENT",
          payload: { status: "loading", data: null },
        });
        dispatch(
          fetchKlarnaPayment({
            invoiceId: klarnaPayment.invoiceId,
          }),
        );
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
        uiNotification.error(error.response.data.message);
      });
  };
};

const fetchKlarnaPayment = ({ invoiceId }) => {
  return (dispatch, getState) => {
    const state = getState();

    http
      .get(`stripe/checkout/${invoiceId}`)
      .then((response) => {
        if (state.KlarnaReducer.klarnaPaymentStatus.status !== "loading") {
          return;
        }

        const klarnaPayment = response.data.data.klarnaPayment;
        dispatch({
          type: "ONGOING_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        if (klarnaPayment.expired) {
          dispatch({
            type: "FETCH_KLARNA_PAYMENT",
            payload: { status: "error", data: response.data },
          });
          dispatch({ type: "RESET_KLARNA_PAYMENT" });
        }
        if (klarnaPayment.completed) {
          dispatch({
            type: "FETCH_KLARNA_PAYMENT",
            payload: { status: "success", data: response.data },
          });
          dispatch({ type: "RESET_KLARNA_PAYMENT" });
        } else {
          timeoutId = setTimeout(
            () =>
              dispatch(
                fetchKlarnaPayment({
                  invoiceId,
                }),
              ),
            5000,
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
      });
  };
};

export const cancelKlarnaPayment = ({ invoiceId }) => {
  return (dispatch) => {
    clearInterval(timeoutId);
    dispatch({
      type: "CANCEL_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    http
      .delete(`stripe/checkout/${invoiceId}`)
      .then((response) => {
        dispatch({
          type: "CANCEL_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
      })
      .catch((error) => {
        dispatch({
          type: "CANCEL_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
      });
  };
};

export const notifyViaSMS = ({ invoiceId, phone }) => {
  return (dispatch) => {
    dispatch({
      type: "NOTIFY_VIA_SMS_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    http
      .post(`stripe/checkout/notify-via-sms/${invoiceId}`, { phone })
      .then((response) => {
        dispatch({
          type: "NOTIFY_VIA_SMS_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "NOTIFY_VIA_SMS_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        uiNotification.error(error.response.data.message);
      });
  };
};

export const notifyViaEmail = ({ invoiceId, email }) => {
  return (dispatch) => {
    dispatch({
      type: "NOTIFY_VIA_EMAIL_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    http
      .post(`stripe/checkout/notify-via-email/${invoiceId}`, { email })
      .then((response) => {
        dispatch({
          type: "NOTIFY_VIA_EMAIL_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        uiNotification.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "NOTIFY_VIA_EMAIL_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        uiNotification.error(error.response.data.message);
      });
  };
};
