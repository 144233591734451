import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./AfterImages45.module.scss";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { ImageUploader } from "../../../../../../../boxes/ImageUploader/ImageUploader";
import { IMAGE_UPLOAD_TYPE } from "../../CosmeticCreateEdit.consts";
import { useDefaultImage } from "../../hooks/useDefaultImage";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { useImageUploader } from "../../../../../../../hooks/useImageUploader";

export function AfterImages45({ leftImage, rightImage, isLoading }) {
  const { tClients } = useAppTranslation.Clients();
  const defaultImage = useDefaultImage();

  const uploader = useImageUploader({
    distinguishByName: true,
    uploadType: IMAGE_UPLOAD_TYPE,
  });

  return (
    <div>
      <div className={classes.title}>
        {tClients("createEditCosmeticProcedure.images.after45degrees")}
      </div>
      {isLoading ? (
        <div className={classes.images}>
          {Array.from(Array(2).keys()).map((i) => (
            <Skeleton
              key={i}
              count={1}
              height={137}
              width="122px"
              borderRadius="4px"
            />
          ))}
        </div>
      ) : (
        <div className={classes.images}>
          <div>
            <InputLabel>
              {tClients("createEditCosmeticProcedure.images.afterLeft")}
            </InputLabel>
            <ImageUploader
              name="afterLeft45Image"
              value={leftImage.value}
              isLoading={
                uploader.isLoading &&
                uploader.uploadingName === "afterLeft45Image"
              }
              onChange={(e) =>
                uploader
                  .htmlOnChange(e)
                  .then(({ uploadedFileFullPath }) =>
                    leftImage.onChange(uploadedFileFullPath),
                  )
              }
              onRemove={() => leftImage.onChange(null)}
              defaultImage={defaultImage}
            />
          </div>
          <div>
            <InputLabel>
              {tClients("createEditCosmeticProcedure.images.rightAfter")}
            </InputLabel>
            <ImageUploader
              name="afterRight45Image"
              value={rightImage.value}
              isLoading={
                uploader.isLoading &&
                uploader.uploadingName === "afterRight45Image"
              }
              onChange={(e) =>
                uploader
                  .htmlOnChange(e)
                  .then(({ uploadedFileFullPath }) =>
                    rightImage.onChange(uploadedFileFullPath),
                  )
              }
              onRemove={() => rightImage.onChange(null)}
              defaultImage={defaultImage}
            />
          </div>
        </div>
      )}
    </div>
  );
}

AfterImages45.propTypes = {
  leftImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  rightImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};
