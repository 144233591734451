import React from "react";
import cx from "clsx";
import { Table } from "./shared/Table/Table";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { PlusIcon } from "../../../../assets/Icons/PlusIcon";
import styles from "./styles.module.scss";
import { ModalCreateEdit } from "./shared/ModalCreateEdit/ModalCreateEdit";
import { useQueryParamsState } from "../../../../utilities/hooks/useQueryParamsState";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useDeleteBanner } from "./hooks/useDeleteBanner";

export function PatientPortalBannerTab() {
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();
  const [deleteId, setDeleteId] = React.useState(null);
  const del = useDeleteBanner(() => setDeleteId(null));

  const [state, setState] = useQueryParamsState({
    bannerId: null,
  });

  return (
    <div className={cx("appointment-content", styles.root)}>
      <Button
        size="small"
        leftAdornment={<PlusIcon />}
        onClick={() =>
          setState({
            bannerId: 0,
          })
        }
      >
        {t("patientPortal.banner.create")}
      </Button>
      <Table
        onDelete={setDeleteId}
        onEdit={(id) =>
          setState({
            bannerId: id,
          })
        }
      />
      {Boolean(state.bannerId) && (
        <ModalCreateEdit
          bannerId={state.bannerId === "0" ? null : Number(state.bannerId)}
          onClose={() =>
            setState({
              bannerId: null,
            })
          }
        />
      )}
      {Boolean(deleteId) && (
        <ConfirmModal
          isOpen
          onConfirm={() => del.initiate({ id: deleteId })}
          onClose={() => setDeleteId(null)}
          onCancel={() => setDeleteId(null)}
          isConfirming={del.isLoading}
        >
          {t("patientPortal.banner.confirm.delete")}
        </ConfirmModal>
      )}
    </div>
  );
}
