import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../../../../../shared/Button/Button";
import { useQualiphyCancelClinicEnrollMutation } from "../../../../../../api/qualiphy/useQualiphyCancelClinicEnrollMutation";
import { QUERY_KEYS } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { passOr } from "../../../../../../utilities/general";
import { ConfirmModal } from "../../../../../../boxes/ConfirmModal/ConfirmModal";

export function RemoveClinic({ id, name }) {
  const queryClient = useQueryClient();
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const cancelClinicEnrollMutation = useQualiphyCancelClinicEnrollMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.qualiphy.enrolledClinics],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.qualiphy.clinicsToEnroll],
      });
      uiNotification.success(tCommon("success.qualiphy.cancelClinicEnroll"));
    },
    onError: () => {
      uiNotification.error(tCommon("error.qualiphy.cancelClinicEnroll"));
    },
  });

  return (
    <>
      <Button
        size="small"
        color="error"
        isDisabled={cancelClinicEnrollMutation.isLoading}
        onClick={() => setIsConfirmModalOpen(true)}
        leftAdornment={passOr(
          cancelClinicEnrollMutation.isLoading,
          undefined,
          () => (
            <CircularProgress size="tiny" color="white" />
          ),
        )}
      >
        {tCommon("label.remove")}
      </Button>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onCancel={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          cancelClinicEnrollMutation.mutate({ clinicId: id });
          setIsConfirmModalOpen(false);
        }}
      >
        {tSettings("qualiphyIntegration.enrolledClinics.removeConfirm", {
          clinicName: name,
        })}
      </ConfirmModal>
    </>
  );
}

RemoveClinic.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};
