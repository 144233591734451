import { useState } from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { hasLength, packedMapOr } from "../../../../../../utilities/general";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useRetentionRateReportQuery } from "../../../../../../api/queries/useRetentionRateReportQuery";

const prepareReqPeriod = (periodState) => {
  return {
    from: periodState.from || null,
    to: periodState.to || null,
  };
};

const prepareReqFilter = (filterState) => {
  return {
    clinicIds: packedMapOr(
      filterState.clinics.map((c) => c.value),
      null,
    ),
  };
};

export function useReports() {
  const { tCommon } = useAppTranslation.Common();

  /* Modifiers */

  const [filter, setFilter] = useState({
    clinics: [],
  });

  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  });

  /* Data */

  const preparedReqFilter = prepareReqFilter(filter);

  const { data, isLoading, isFetching } = useRetentionRateReportQuery({
    payload: {
      period: prepareReqPeriod(period),
      filter: preparedReqFilter,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  });

  /* Statuses */

  const isFilterActive = Object.values(filter).some(hasLength);
  const isPeriodActive = Object.values(period).some(Boolean);

  const isSoftLoading =
    (isFilterActive || isPeriodActive) && isFetching && !isLoading;

  return {
    reports: {
      value: data?.reports || [],
      isLoading,
      isSoftLoading,
    },
    filter: {
      value: filter,
      isActive: isFilterActive,
      update: setFilter,
      preparedForRequest: preparedReqFilter,
    },
    period: {
      value: period,
      update: setPeriod,
    },
  };
}
