import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import picClose from '../../../../_legacy/images/popupClose.png';
import { getSubscriptionInvoices, payInvoiceById, exportEmptyData, getSubscriptionInvoiceById, downloadInvoice } from "../../../../Actions/Settings/settingsActions.js";
import { showFormattedDate, autoScrolling, toggleBodyScroll, capitalizeFirstLetter } from "../../../../Utils/services.js";
import { formatBillingCurrency } from "../YourBilling.utils.js";
import { uiNotification } from "../../../../services/UINotificationService.js";

const specifiedFormat = "MMMM DD, YYYY";

class Invoices extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0)
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      invoiceList: [],
      page: 1,
      pagesize: 15,
      next_page_url: '',
      loadMore: true,
      startFresh: true,
      showLoader: false,
      isInvoicePastDue: false,
      pastDueAmount: 0,
      pastDueInvoiceId: 0,
      showModal: false,
      invoiceID: 0,
      invoiceData: {},
      invoiceInfo: {},
      invoiceTo: [],
      invoiceEmail: '',
      invoiceAddress: []
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <= 5 && this.state.next_page_url != null) {
        this.loadMore();
      }
    };
  }


  dismissModal = () => {
    this.setState({ showModal: false })
    toggleBodyScroll(false)
  }

  componentDidMount() {
    let formData = {
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
      }
    };
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.setState({ 'showLoader': true });
    autoScrolling(true);
    this.props.getSubscriptionInvoices(formData);

    this.showInvoiceViewModal = (id) => {
      const invoiceID = id
      this.props.getSubscriptionInvoiceById(invoiceID);
      this.setState({ showModal: true, invoiceID: id, showLoader: true })
      toggleBodyScroll(true)

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.redirect !== undefined && nextProps.redirect === true) {
      uiNotification.success(nextProps.message)
      window.location.reload();
    } else if (nextProps.showLoader !== undefined && nextProps.showLoader === false) {
      nextProps.exportEmptyData();
      returnState.showLoader = false;
    } else if (
      nextProps.invoiceList != undefined &&
      nextProps.invoiceList.next_page_url !== prevState.next_page_url
    ) {
      if (prevState.next_page_url == null) {
        localStorage.setItem("sortOnly", false);
        autoScrolling(false);
        nextProps.exportEmptyData();
        return (returnState.next_page_url = null);
      }
      if (nextProps.invoiceList && nextProps.invoiceList.past_due_invoice_data && nextProps.invoiceList.past_due_invoice_data.past_due_show) {
        returnState.isInvoicePastDue = true;
        returnState.pastDueAmount = nextProps.invoiceList.past_due_invoice_data.past_due_amount;
        returnState.pastDueInvoiceId = nextProps.invoiceList.past_due_invoice_data.past_due_invoice_id;
      } else {
        returnState.isInvoicePastDue = false;
        returnState.pastDueAmount = 0;
        returnState.pastDueInvoiceId = 0;
      }

      if (prevState.invoiceList.length == 0 && prevState.startFresh == true) {
        if (localStorage.getItem("sortOnly") == "false") {
          returnState.invoiceList = nextProps.invoiceList.data;

          if (nextProps.invoiceList.next_page_url != null) {
            returnState.page = prevState.page + 1;
          } else {
            returnState.next_page_url = nextProps.invoiceList.next_page_url;
          }
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
        } else {
          localStorage.setItem("sortOnly", false);
        }
      } else if (
        prevState.invoiceList != nextProps.invoiceList.data &&
        prevState.invoiceList.length != 0
      ) {
        returnState.invoiceList = [
          ...prevState.invoiceList,
          ...nextProps.invoiceList.data
        ];
        returnState.next_page_url = nextProps.invoiceList.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.page = prevState.page + 1;
      }
    }
    if (nextProps.invoiceData !== undefined && nextProps.invoiceData.status === 200 && nextProps.data !== prevState.invoiceData) {
      returnState.invoiceData = nextProps.invoiceData.data;
      returnState.invoiceInfo = nextProps.invoiceData.data.invoice_data;

      returnState.showLoader = false;
      let invoiceTo = [];
      let invoiceEmail = '';
      let invoiceAddress = [];
      if (returnState.invoiceInfo.account) {
        if (returnState.invoiceInfo.account.name) {
          invoiceTo.push(returnState.invoiceInfo.account.name + "," + " ");
        }
        if (returnState.invoiceInfo.account.admin) {
          invoiceTo.push(returnState.invoiceInfo.account.admin.firstname + " " + returnState.invoiceInfo.account.admin.lastname);
          invoiceEmail = returnState.invoiceInfo.account.admin.email_id;
          let addressLine = [];
          if (returnState.invoiceInfo.account.admin.address_line_1) {
            addressLine.push(returnState.invoiceInfo.account.admin.address_line_1);
          }
          if (returnState.invoiceInfo.account.admin.address_line_2) {
            addressLine.push(returnState.invoiceInfo.account.admin.address_line_2);
          }
          if (returnState.invoiceInfo.account.admin.address_line_3) {
            addressLine.push(returnState.invoiceInfo.account.admin.address_line_3);
          }
          if (returnState.invoiceInfo.account.admin.address_line_4) {
            addressLine.push(returnState.invoiceInfo.account.admin.address_line_4);
          }
          addressLine = addressLine.join(', ');
          if (addressLine) {
            invoiceAddress.push(addressLine);
          }
          if (returnState.invoiceInfo.account.admin.city) {
            invoiceAddress.push(returnState.invoiceInfo.account.admin.city);
          }
          if (returnState.invoiceInfo.account.admin.state) {
            invoiceAddress.push(returnState.invoiceInfo.account.admin.state);
          }
          if (returnState.invoiceInfo.account.admin.country) {
            invoiceAddress.push(returnState.invoiceInfo.account.admin.country);
          }
        }
      }
      returnState.invoiceTo = invoiceTo;
      returnState.invoiceEmail = invoiceEmail;
      returnState.invoiceAddress = invoiceAddress;

      nextProps.exportEmptyData();
    } else if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      nextProps.exportEmptyData();
    } else if (nextProps.invoiceFilePath != undefined && nextProps.invoiceFilePath !== null) {
      nextProps.exportEmptyData();
      returnState.showLoader = false;
      window.open(nextProps.invoiceFilePath)
    }
    autoScrolling(false);
    return returnState;

  }
  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({ 'loadMore': true, startFresh: true, showLoader: true, showLoadingText: true });
      let formData = {
        'params': {
          page: this.state.page,
          pagesize: this.state.pagesize,
        }
      };
      autoScrolling(true);
      this.props.getSubscriptionInvoices(formData);
    }
  };

  handleSubmit = () => {
    if (this.state.pastDueInvoiceId) {
      this.setState({ showLoader: true });
      this.props.payInvoiceById({ invoice_id: this.state.pastDueInvoiceId });
    }
  }

  downloadInvoice = () => {
    this.setState({ showLoader: true })
    this.props.downloadInvoice(this.state.invoiceID)
  }

  render() {
    let returnUrl = '';

    if (this.state.roomType && this.state.roomType === 'clients') {
      returnUrl = (this.props.match.params.type) ? "/" + this.state.roomType + "/" + this.props.match.params.type + "/" + this.props.match.params.patientID : "/" + this.state.roomType
    } else {
      returnUrl = '/settings/manage-invoices'
    }

    let notesClass = "settings-subtitle m-b-20 no-display";
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">

            <Sidebar />
            {/*Clinic Head Start*/}
            <div className="memberWalletOuter business-section">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  <span className="cursor-pointer">Invoices from AR</span>

                  {this.state.isInvoicePastDue ?
                    <div className="memberRightActions">
                      <a className="new-blue-btn pull-right" onClick={this.handleSubmit}>{this.state.settingsLang.billing_pay_now}</a>
                      <span className="setting-total-due pull-right">{this.state.settingsLang.billing_your_total_due} : {formatBillingCurrency(this.state.pastDueAmount)}</span>
                    </div>


                    :
                    <span className="pull-right"></span>
                  }

                </div>
              </div>

              {/*Clinic Head END*/}

              <div className="table-responsive">
                <table className="table-updated setting-table no-td-border">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-3 table-updated-th">{this.state.settingsLang.billing_invoice}#<i className="gray-gray" /></th>
                      <th className="col-xs-2 table-updated-th">{this.state.settingsLang.billing_invoice_date}</th>
                      <th className="col-xs-3 table-updated-th">{this.state.globalLang.label_status}</th>
                      <th className="col-xs-3 table-updated-th text-right">{this.state.globalLang.label_amount}</th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.invoiceList.length ?
                      this.state.invoiceList.map((obj, idx) => {
                        const createdDate = showFormattedDate(obj.created, false, 'MMMM DD, YYYY');
                        const splittedStatus = obj.payment_status.split('_')
                        return (
                          <tr className="table-updated-tr" key={'invoice-' + idx} onClick={this.showInvoiceViewModal.bind(this, obj.id)}>
                            <td className="col-xs-3 table-updated-td">{obj.invoice_number}</td>
                            <td className="col-xs-3 table-updated-td">{createdDate}</td>
                            <td className="col-xs-3 table-updated-td">{splittedStatus.length === 2 ? `${capitalizeFirstLetter(splittedStatus[0])} ${capitalizeFirstLetter(splittedStatus[1])}` : capitalizeFirstLetter(splittedStatus[0])}</td>
                            <td className="col-xs-3 table-updated-td text-right">{formatBillingCurrency(obj.invoice_amount)}</td>
                          </tr>
                        )
                      })
                      :
                      ((this.state.showLoader === false) && <tr className="table-updated-tr">
                        <td className="col-xs-3 table-updated-td text-center" colSpan={4}>
                          {this.state.globalLang.sorry_no_record_found}
                        </td>
                      </tr>)
                    }
                  </tbody>
                </table>

              </div>

            </div>


            {/*Invoice View Pop-UP Starts */}


            <div className={(this.state.showModal) ? 'blackOverlay' : 'blackOverlay no-display'}>
              <div className="vert-middle vert-middle-report-option">
                <div className="white-popup small-popup">
                  <div className="white-popup-wrapper">
                    <div className="membershipTypeTitle">{"Invoice Information"}<a onClick={this.dismissModal} className="popupClose"><img src={picClose} /></a></div>
                    <div>
                      <table height="100%" width="100%" style={{ maxWidth: '700px', lineHeight: '25px' }}>
                        <tbody>

                          <tr>
                            <td style={{ width: '100px', color: '#000000' }}>No</td>
                            <td style={{ color: '#667680' }}>{this.state.invoiceInfo.invoice_number}</td>
                          </tr>
                          <tr>
                            <td style={{ width: '100px', color: '#000000' }}>Date</td>
                            <td style={{ color: '#667680' }}>{this.state.invoiceInfo.created ? showFormattedDate(this.state.invoiceInfo.created, false, specifiedFormat) : ''}</td>
                          </tr>
                          <tr>
                            <td style={{ width: '100px', color: '#000000' }}>Paid on</td>
                            <td style={{ color: '#667680' }}>{this.state.invoiceInfo.modified ? showFormattedDate(this.state.invoiceInfo.modified, false, specifiedFormat) : ''}</td>
                          </tr>
                          <tr>
                            <td style={{ width: '100px', color: '#000000' }}>Amount</td>
                            <td style={{ color: '#667680' }}>{formatBillingCurrency(this.state.invoiceData.total_amount)}</td>
                          </tr>
                          <tr>
                            <td style={{ width: '100px', color: '#000000' }}>Tax</td>
                            <td style={{ color: '#667680' }}>{formatBillingCurrency(this.state.invoiceData.tax)}</td>
                          </tr>
                          {(this.state.invoiceInfo.invoice_number !== undefined) &&


                            (this.state.invoiceInfo !== 0 || this.state.invoiceInfo !== undefined || this.state.invoiceInfo !== null || this.state.invoiceInfo.length > 0)

                            ?
                            this.state.invoiceInfo.subscription_invoice_item.map((obj, idx) => {
                              const descriptionData = obj[Object.keys(obj)[0]];

                              return (
                                <tr key={"subscription_invoice_item-" + idx}>
                                  <td style={{ width: '100px', color: '#000000' }}>Description </td>
                                  <td style={{ color: '#667680' }}>{descriptionData.description}</td>
                                </tr>

                              )
                            })
                            :

                            <tr>
                              <td style={{ width: '100px', color: '#000000' }}>Description</td>
                              <td style={{ color: '#667680' }}></td>
                            </tr>
                          }

                          {
                            (this.state.invoiceInfo.invoice_type == 'dispute_fee') &&
                            <tr>
                              <td style={{ width: '100px', color: '#000000' }}>Description </td>
                              <td style={{ color: '#667680' }}>{this.state.invoiceInfo.recurly_invoice_description}</td>
                            </tr>
                          }

                        </tbody>
                      </table>
                      <hr style={{ borderColor: '#dddddd' }} />
                      <table height="100%" width="100%" style={{ maxWidth: '700px', lineHeight: '30px', lineHeight: '30px' }}>
                        <tbody>
                          <tr>
                            <td style={{ width: '100px', color: '#000000' }}>Invoice to</td>
                            <td style={{ color: '#667680' }}>
                              {this.state.invoiceTo.length > 1 ?
                                this.state.invoiceTo.map((obj, idx) => {

                                  return (
                                    <span key={'invoice-to' + idx} style={{ color: '#667680' }}>{obj}</span>)
                                })
                                :
                                this.state.invoiceTo
                              }
                            </td>

                          </tr>
                          <tr>
                            <td style={{ width: '100px', color: '#000000' }}>Email</td>
                            <td style={{ color: '#667680' }}>{this.state.invoiceEmail}</td>
                          </tr>

                          <tr>
                            <td valign="top" style={{ width: '100px', color: '#000000', lineHeight: '14px', paddingTop: '6px' }}>Address</td>
                            <td valign="top" style={{ color: '#667680', lineHeight: '14px', paddingTop: '6px' }}>
                              {this.state.invoiceAddress.length > 1 ?
                                this.state.invoiceAddress.map((obj, idx) => {

                                  return (<div key={'invoice-address' + idx} style={{ color: '#667680' }}>{obj}{((idx + 1) != this.state.invoiceAddress.length) ? ',' : null}</div>)
                                })
                                :
                                this.state.invoiceAddress
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr style={{ borderColor: '#dddddd' }} />
                      {(this.state.invoiceInfo.payment_status == 'paid') &&
                        <table height="100%" width="100%" style={{ maxWidth: '700px', lineHeight: '30px', marginBottom: '30px' }}>
                          <tbody>
                            {
                              (this.state.invoiceInfo.recurly_invoice_id != 0) &&
                              <tr>

                                <td style={{ width: '100px', color: '#000000' }}>Details</td>
                                <td style={{ color: '#667680' }}></td>
                                <td style={{ color: '#667680' }}>{formatBillingCurrency(this.state.invoiceData.total_amount)}</td>
                              </tr>

                            }
                            {(this.state.invoiceData.amount_from_card > 0 || this.state.invoiceData.credit_adjusted_amount > 0) &&
                              (this.state.invoiceInfo.recurly_invoice_id == 0 && this.state.invoiceData.show_credit_card) &&
                              <React.Fragment>
                                <tr>
                                  <td style={{ width: '100px', color: '#000000' }}>Details</td>
                                  <td>{this.state.invoiceData.card_details}</td>
                                </tr>
                                <tr>
                                  <td style={{ width: '100px', color: '#000000' }}>Amount</td>
                                  <td style={{ color: '#667680' }}>{formatBillingCurrency(this.state.invoiceData.amount_from_card)}</td>

                                </tr>
                              </React.Fragment>
                            }
                            {(this.state.invoiceData.amount_from_card > 0 || this.state.invoiceData.credit_adjusted_amount > 0) &&
                              (this.state.invoiceInfo.recurly_invoice_id == 0 && this.state.invoiceData.credit_adjusted_status) &&
                              <React.Fragment>
                                <tr>
                                  <td style={{ width: '100px', color: '#000000' }}>Details</td>
                                  <td >{this.state.invoiceData.credit_adjusted_string}</td>
                                </tr>

                                <tr>
                                  <td style={{ width: '100px', color: '#000000' }}>Amount</td>
                                  <td style={{ color: '#667680' }}> {formatBillingCurrency(this.state.invoiceData.credit_adjusted_amount)}</td>
                                </tr>
                              </React.Fragment>
                            }

                            {(this.state.invoiceData.amount_from_card <= 0 && this.state.invoiceData.credit_adjusted_amount <= 0) &&
                              <React.Fragment>
                                <tr>
                                  <td style={{ width: '100px', color: '#000000' }}>Details</td>
                                  <td >{this.state.globalLang.label_amount}</td>
                                </tr>
                                <tr>
                                  <td style={{ width: '100px', color: '#000000' }}>Amount</td>
                                  <td style={{ color: '#667680' }}>{formatBillingCurrency(this.state.invoiceData.total_amount)}</td>
                                </tr>
                              </React.Fragment>
                            }
                          </tbody>
                        </table>
                      }
                      <a className="line-btn pull-right" onClick={this.downloadInvoice}>{this.state.settingsLang.billing_download_invoice}</a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*popup Ends*/}
            <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock popup-loader' : 'new-loader text-left popup-loader'}>
              <div className="loader-outer">
                <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
                <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.Please_Wait}</div>
              </div>
            </div>
          </div>

          <div className={(this.state.showLoadingText) ? "loading-please-wait no-margin-top" : "loading-please-wait no-margin-top no-display "}>{this.state.globalLang.loading_please_wait_text}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.SettingReducer.action === "SUBSCRIPTION_INVOICE_LIST") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear()
      if (state.SettingReducer.data.message == 'third_party_error') {
        uiNotification.error(state.SettingReducer.data.data);
      } else {
        uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false
    } else {
      returnState.invoiceList = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "PAY_LAST_DUE_INVOICE") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear()
      if (state.SettingReducer.data.message == 'third_party_error') {
        uiNotification.error(state.SettingReducer.data.data);
      } else {
        uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.redirect = true;
    }
  }
  if (state.SettingReducer.action === "SUBSCRIPTION_INVOICE_DATA") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear()
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.invoiceData = state.SettingReducer.data
    }
  }
  else if (state.SettingReducer.action === "DOWNLOAD_INVOICE") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear()
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.invoiceFilePath = state.SettingReducer.data.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSubscriptionInvoices: getSubscriptionInvoices,
    payInvoiceById: payInvoiceById,
    exportEmptyData: exportEmptyData,
    getSubscriptionInvoiceById: getSubscriptionInvoiceById,
    downloadInvoice: downloadInvoice,
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoices);
