import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const responseSchema = yup
  .array()
  .of(
    yup.object({
      id: yup.number().required(),
      name: yup.string().required(),
      order: yup.number().nullable(),
    }),
  )
  .required();

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return Object.values(data).map((d) => ({
    id: d.id,
    name: d.name,
    order: d.ob_order || null,
  }));
};

function createGiftCardsObOrderQueryKey() {
  return [QUERY_KEYS.giftCardsObOrder];
}

export function useGiftCardsObOrderQuery({ options = {} }) {
  return useQuery(
    createGiftCardsObOrderQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getGiftCardsObOrder());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
