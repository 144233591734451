const nameInitialState = {
  action: "",
};

const ClockReducer = (state = nameInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return { action: "RESET_ALL" };
    }
    case "EMPTY_CLOCK": {
      return { ...state, data: {}, action: "EMPTY_CLOCK" };
    }
    case "LOGGED_CLOCK_STATUS": {
      return { ...state, data: action.payload, action: "LOGGED_CLOCK_STATUS" };
    }
    case "USER_CLOCK_STATUS": {
      return { ...state, data: action.payload, action: "USER_CLOCK_STATUS" };
    }
    case "CHANGE_SYSTEM_CLOCK_STATUS": {
      return {
        ...state,
        data: action.payload,
        action: "CHANGE_SYSTEM_CLOCK_STATUS",
      };
    }
    case "CHANGE_LOGGED_CLOCK_STATUS": {
      return {
        ...state,
        data: action.payload,
        action: "CHANGE_LOGGED_CLOCK_STATUS",
      };
    }
    case "CLOCK_LIST": {
      return { ...state, data: action.payload, action: "CLOCK_LIST" };
    }
    case "GET_PAY_PERIOD": {
      return { ...state, data: action.payload, action: "GET_PAY_PERIOD" };
    }
    case "SAVE_PAY_PERIOD": {
      return { ...state, data: action.payload, action: "SAVE_PAY_PERIOD" };
    }
    case "CHANGE_CLOCK_SETTING_STATUS": {
      return {
        ...state,
        data: action.payload,
        action: "CHANGE_CLOCK_SETTING_STATUS",
      };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    default:
      return state;
  }
};

export default ClockReducer;
