import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { SETTING_VIEW_NAMES } from "./config";

const requestSchema = yup.object({
  names: yup
    .array()
    .of(
      yup.string().test({
        test: (n) => Object.values(SETTING_VIEW_NAMES).includes(n),
      }),
    )
    .required(),
});

export function usePatientVitalsSettingViewsMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(HTTP_ENDPOINTS.patientVitals.updateSettingViews(), {
        names: req.names,
      });
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.settingViews],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.all],
        });

        options?.onSuccess?.(...args);
      },
    },
  );
}
