import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

function createProvidersQueryKey() {
  return [QUERY_KEYS.providers];
}

export function useProvidersQuery(options = {}) {
  return useQuery(
    createProvidersQueryKey(),
    () => http.get(HTTP_ENDPOINTS.getProviders()),
    options,
  );
}
