import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FREQUENCY_TYPES } from "../../Event.consts";
import { EntityRow } from "../EntityRow";
import { Select } from "../../../../shared/Select/Select";

const OutOfOfficeFrequency = ({ value, onChange, eventDate }) => {
  const options = [
    {
      label: "Does not repeat",
      value: FREQUENCY_TYPES.noRepeat,
    },
    {
      label: "Daily",
      value: FREQUENCY_TYPES.daily,
    },
    {
      label: "Every Weekdays (Mon to Fri)",
      value: FREQUENCY_TYPES.weekday,
    },
    {
      label: `Annualy on ${moment.utc(eventDate).format("MMMM DD")}`,
      value: FREQUENCY_TYPES.annual,
    },
    {
      label: "Custom",
      value: FREQUENCY_TYPES.custom,
    },
  ];

  return (
    <EntityRow label="Frequency">
      <Select
        value={options.find((i) => i.value === value)}
        onChange={(nextOption) => onChange(nextOption.value)}
        options={options}
      />
    </EntityRow>
  );
};

OutOfOfficeFrequency.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  eventDate: PropTypes.string,
};

OutOfOfficeFrequency.defaultProps = {
  value: "",
  onChange: () => {},
  eventDate: "",
};

export default OutOfOfficeFrequency;
