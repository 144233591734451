import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { svg } from "../../../../../assets/svg";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { ReportHeader } from "../../../../BusinessInsights/components/ReportHeader/ReportHeader";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { DateRangePicker } from "../../../../../shared/DateRangePicker/DateRangePicker";

export function Header({
  isFilterActive,
  onFilterOpen,
  period,
  downloadExcel,
  isReportsLoading,
}) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader label={tBi("reconciliationReport.label")}>
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />

      <Button
        size="small"
        variant="outlined"
        onClick={downloadExcel.initiate}
        isDisabled={downloadExcel.isLoading || isReportsLoading}
        leftAdornment={
          downloadExcel.isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <img src={svg.downloadPrimary} alt="dl" width="20px" />
          )
        }
      >
        {tCommon("label.downloadExcel")}
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={onFilterOpen}
        isDisabled={isReportsLoading}
        leftAdornment={
          isFilterActive ? (
            <img src={svg.filterPrimaryActive} alt="filter" width="16px" />
          ) : (
            <img src={svg.filterPrimary} alt="filter" width="14px" />
          )
        }
      >
        {tCommon("label.filter")}
      </Button>
    </ReportHeader>
  );
}

Header.propTypes = {
  isFilterActive: PropTypes.bool.isRequired,
  onFilterOpen: PropTypes.func.isRequired,
  period: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    update: PropTypes.func,
  }).isRequired,
  downloadExcel: PropTypes.shape({
    initiate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
};
