import React from "react";
import moment from "moment";
import styles from "./styles.module.scss";
import { MoveRightIcon } from "../../../../../../../assets/Icons/MoveRightIcon";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";
import { BI_GIFT_CARD_HISTORY_CHANGE_TYPES } from "../../../../../../../api/businessInsight/useBiGiftCardHistoryQuery";
import { DEFAULT_DATE_FORMAT } from "../../../../../../../consts/general";

export const ChangePreview = ({ type, before, after }) => {
  const { data: user } = useCurrentUserQuery();

  const previewDate = (date) => {
    return moment(date).format(user?.previewDateFormat || DEFAULT_DATE_FORMAT);
  };

  const getTimeChange = () => {
    return {
      before: previewDate(before),
      after: previewDate(after),
    };
  };

  const getMoneyChange = () => {
    return {
      before: `${user?.currencySymbol}${before}`,
      after: `${user?.currencySymbol}${after}`,
    };
  };

  const changeFormatterByType = {
    [BI_GIFT_CARD_HISTORY_CHANGE_TYPES.expireCard]: getTimeChange,
    [BI_GIFT_CARD_HISTORY_CHANGE_TYPES.editBalance]: getMoneyChange,
  };

  const getChange = () => {
    const change = changeFormatterByType?.[type]?.() || {};

    return (
      <>
        {change.before} <MoveRightIcon width="14px" /> {change.after}
      </>
    );
  };

  return <div className={styles.root}>{getChange()}</div>;
};
