const initialState = {
  stripeCheckData: {
    status: "pending",
    message: "no_action_required",
    accountId: null,
    error: null,
    accountNeedsUpdate: null,
    tosNeedsUpdate: null,
  },
  stripeUpdateUrlData: {
    status: "pending",
    url: null,
  },
  stripeUpdateTosAccounts: {
    accounts: [],
  },
};

const stripeUpdateInfo = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_STRIPE_UPDATE_CHECK": {
      return {
        ...state,
        stripeCheckData: { ...initialState.stripeCheckData, status: "loading" },
      };
    }
    case "FETCH_STRIPE_UPDATE_CHECK_SUCCESS": {
      return {
        ...state,
        stripeCheckData: {
          ...initialState.stripeCheckData,
          status: "success",
          message: action.payload.message,
          accountId: action.payload.accountId,
          accountNeedsUpdate: action.payload.accountNeedsUpdate,
          tosNeedsUpdate: action.payload.tosNeedsUpdate,
          accounts: action.payload.accounts,
        },
      };
    }
    case "FETCH_STRIPE_UPDATE_CHECK_ERROR": {
      return {
        ...state,
        stripeCheckData: {
          ...initialState.stripeCheckData,
          status: "error",
          error: action.payload,
        },
      };
    }
    case "FETCH_STRIPE_UPDATE_URL": {
      return {
        ...state,
        stripeUpdateUrlData: {
          ...initialState.stripeUpdateUrlData,
          status: "loading",
        },
      };
    }
    case "FETCH_STRIPE_UPDATE_URL_SUCCESS": {
      return {
        ...state,
        stripeUpdateUrlData: {
          ...initialState.stripeUpdateUrlData,
          status: "success",
          url: action.payload,
        },
      };
    }
    case "FETCH_STRIPE_UPDATE_URL_ERROR": {
      return {
        ...state,
        stripeUpdateUrlData: {
          ...initialState.stripeUpdateUrlData,
          status: "error",
          error: action.payload,
        },
      };
    }
    case "FETCH_STRIPE_TOS_UPDATE": {
      return {
        ...state,
        stripeUpdateTosAccounts: { accounts: action.payload },
      };
    }

    default:
      return state;
  }
};

export default stripeUpdateInfo;
