/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InsightsSideBar from "./InsightsSideBar.js";
import Loader from "../Common/Loader.js";
import AppointmentTitle from "./Appointments/AppointmentTitle.js";
import {
  resetReducerAction,
  getOptionAndList,
  deleteReport,
} from "../../Actions/BusinessInsights/businessInsightActions.js";
import { fromToDateRange } from "../../Utils/services.js";
import Dnd from "./Dnd.js";
import { AppointmentsStaticReports } from "./Appointments/AppointmentsStaticReports/AppointmentsStaticReports.js";
import { uiNotification } from "../../services/UINotificationService.js";

const FromToMonthDate = fromToDateRange("mtd");

const initReportFilter = () => {
  return {
    date: "mtd",
    from_date: FromToMonthDate.from_date,
    to_date: FromToMonthDate.from_date,
    month_of_birth: "1",
    spent_money: "",
    discount_type: "all",
    provider: "all",
    cachier: "all",
    product: "",
    service: "",
    cancellation_fee_charge: false,
  };
};

class AppointmentsInsights extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      businessInsightLang: languageData.business_insights,
      showCreateOptions: false,
      reportOptions: [],
      hasData: false,
      showBreadCrumb: false,
      reportVariableData: [],
      showFilters: false,
      reportFilter: initReportFilter(),
      reportToBeDeleted: 0,
      showConfirmation: false,
      searchTerm: "",
      isSearchTerm: false,
      savedReports: [],
    };
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
      reportToBeDeleted: 0,
      showConfirmation: false,
      searchTerm: "",
    });
    this.props.getOptionAndList("appointments");
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });
  };

  showCreateOptions = () => {
    this.setState({ showCreateOptions: true });
  };

  hideCreateOptions = (childState) => {
    this.setState(childState);
  };

  gotToNext = () => {
    return (
      <div>
        {this.props.history.push(`/business-insight/appointments/create`)}
      </div>
    );
  };

  getReportsVariable = (childSate) => {
    this.setState(childSate);
    this.props.getReportVariables(childSate.report_type_id);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportOptionAndList &&
      nextProps.reportOptionAndList !== prevState.reportOptionAndList
    ) {
      returnState.reportOptionAndList = nextProps.reportOptionAndList;
      returnState.savedReports = nextProps.reportOptionAndList.savedReports
        ? nextProps.reportOptionAndList.savedReports
        : [];
      returnState.hasData =
        nextProps.reportOptionAndList &&
        nextProps.reportOptionAndList.savedReports &&
        nextProps.reportOptionAndList.savedReports.length > 0
          ? true
          : false;
      returnState.showLoader = false;
      nextProps.resetReducerAction();
    }

    if (
      nextProps.deleteReportData !== undefined &&
      nextProps.deleteReportData.status === 200 &&
      nextProps.deleteReportData.data !== prevState.deleteReportData
    ) {
      returnState.deleteReportData = nextProps.deleteReportData.data;
      returnState.showLoader = false;
      returnState.savedReports = nextProps.deleteReportData.data
        ? nextProps.deleteReportData.data
        : [];
    }

    if (nextProps.showLoader && nextProps.showLoader === false) {
      returnState.showLoader = false;
      nextProps.resetReducerAction();
    }

    return returnState;
  }

  showDeleteModal = (obj) => {
    this.setState({ reportToBeDeleted: obj.id, showConfirmation: true });
  };

  hideDeleteModal = () => {
    this.setState({ reportToBeDeleted: 0, showConfirmation: false });
  };

  deleteThisReport = () => {
    if (this.state.reportToBeDeleted) {
      let formData = {
        business_insight_reports_id: this.state.reportToBeDeleted,
        term: this.state.searchTerm,
      };
      this.setState({
        showLoader: true,
        savedReports: [],
        showConfirmation: false,
      });
      this.props.deleteReport("appointments", formData);
    }
  };

  editThisReport = (obj) => {
    if (obj) {
      return (
        <div>
          {this.props.history.push(
            `/business-insight/appointments/edit/${obj.id}`,
          )}
        </div>
      );
    }
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  childFormSubmit = (e) => {
    e.preventDefault();

    let term = this.state.searchTerm;

    let formData = {
      term: term,
    };

    this.setState({
      showLoader: true,
      reportToBeDeleted: 0,
      showConfirmation: false,
      searchTerm: term,
      savedReports: [],
      reportOptionAndList: [],
      isSearchTerm: term.trim() ? true : false,
    });
    this.props.getOptionAndList("appointments", formData);
  };

  render() {
    let hasListData = false;
    let tableParentMainClass = "setting-setion m-b-10 noBorderShadow";
    let tableParentClass = "table-responsive scroll-x no-border";
    let tableClass =
      "table-updated setting-table table-min-width no-td-border no-bg";

    if (
      this.state.savedReports.length > 0 ||
      (this.state.savedReports.length <= 0 &&
        this.state.isSearchTerm === true &&
        this.state.showLoader === false)
    ) {
      tableParentMainClass = "setting-setion m-b-10";
      tableParentClass = "table-responsive scroll-x";
      tableClass = "table-updated setting-table table-min-width no-td-border";
    }

    if (this.state.savedReports && this.state.savedReports.length > 0) {
      hasListData = true;
    }

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div
            className={this.state.showConfirmation === true ? "overlay" : ""}
          ></div>
          <div
            role="dialog"
            className={
              this.state.showConfirmation === true
                ? "modal fade in displayBlock"
                : "modal fade no-display"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.hideDeleteModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title">
                    {this.state.globalLang.delete_confirmation}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {
                    this.state.businessInsightLang
                      .bi_are_you_sure_you_want_to_delete_this_report
                  }
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.hideDeleteModal}
                    >
                      {this.state.globalLang.label_no}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deleteThisReport}
                    >
                      {this.state.globalLang.label_yes}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <InsightsSideBar />

          <div className="memberWalletOuter business-section">
            {this.state.showLoader === false && (
              <AppointmentTitle
                hasData={this.state.hasData ? true : this.state.isSearchTerm}
                showBreadCrumb={this.state.showBreadCrumb}
                gotToNext={() => this.gotToNext()}
                showSearch={true}
                searchTerm={this.state.searchTerm}
                handleChildState={this.handleChildState}
                childFormSubmit={this.childFormSubmit}
                businessInsightLang={this.state.businessInsightLang}
                globalLang={this.state.globalLang}
              />
            )}

            {!hasListData && this.state.isSearchTerm === false && (
              <div className="not-create-report">
                <h3>
                  {
                    this.state.businessInsightLang
                      .bi_you_have_not_created_any_reports_yet
                  }
                </h3>
                <a
                  onClick={() => this.gotToNext()}
                  className="new-blue-btn m-t-25"
                >
                  {this.state.businessInsightLang.bi_create_report}
                </a>
              </div>
            )}

            <AppointmentsStaticReports />

            <div className={tableParentMainClass}>
              <div className={tableParentClass}>
                <table className={tableClass}>
                  {hasListData && (
                    <Dnd
                      showDeleteModal={this.showDeleteModal}
                      savedReports={this.state.savedReports}
                      module={"appointments"}
                      editThisReport={this.editThisReport}
                      globalLang={this.state.globalLang}
                    />
                  )}

                  {!hasListData &&
                    this.state.isSearchTerm === true &&
                    this.state.showLoader === false && (
                      <tbody className="ajax_body">
                        <tr className="table-updated-tr">
                          <td
                            className="table-updated-td no-record no-float"
                            colSpan={5}
                          >
                            {this.state.businessInsightLang.bi_no_record_found}
                          </td>
                        </tr>
                      </tbody>
                    )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (
    state.BusinessInsightReducer.appointmentsSavedReports &&
    state.BusinessInsightReducer.appointmentsSavedReports.length
  ) {
    returnState.savedReports =
      state.BusinessInsightReducer.appointmentsSavedReports;
  }

  if (state.BusinessInsightReducer.action === "GET_APPOINTMENTS_REPORT") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportOptionAndList = {
        savedReports: state.BusinessInsightReducer.appointmentsSavedReports,
        reportOptions: state.BusinessInsightReducer.appointmentsOptions,
      };
    }
  }

  if (state.BusinessInsightReducer.action === "DELETE_THIS_REPORT") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.deleteReportData = state.BusinessInsightReducer.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetReducerAction: resetReducerAction,
      getOptionAndList: getOptionAndList,
      deleteReport: deleteReport,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppointmentsInsights));
