const InboxReducer = (state = { data: "", status: "" }, action) => {
  switch (action.type) {
    case "GET_CARD_DETAILS": {
      return {
        ...state,
        data: action.payload,
        action: "GET_CARD_DETAILS",
        redirect_to: "",
      };
    }
    case "SEARCH_PHONE_NUMBER": {
      return {
        ...state,
        data: action.payload,
        action: "SEARCH_PHONE_NUMBER",
        redirect_to: "",
      };
    }
    case "LIST_SMS": {
      return {
        ...state,
        data: action.payload,
        action: "LIST_SMS",
        redirect_to: "",
      };
    }
    case "SEARCH_PATIENTS": {
      return { ...state, data: action.payload, action: "SEARCH_PATIENTS" };
    }
    case "SELECTED_SMS_POPUPS": {
      return { ...state, data: action.payload, action: "SELECTED_SMS_POPUPS" };
    }
    case "CREATE_REPLY_INBOX": {
      return { ...state, data: action.payload, action: "CREATE_REPLY_INBOX" };
    }
    case "GET_SMS_LIST": {
      return { ...state, data: action.payload, action: "GET_SMS_LIST" };
    }
    case "GET_OPTIONS_LIST": {
      return { ...state, data: action.payload, action: "GET_OPTIONS_LIST" };
    }
    case "SAVE_LIST": {
      return { ...state, data: action.payload, action: "SAVE_LIST" };
    }
    case "GET_LIST_DATA": {
      return { ...state, data: action.payload, action: "GET_LIST_DATA" };
    }
    case "DELETE_DATA": {
      return { ...state, data: action.payload, action: "DELETE_DATA" };
    }
    case "GET_SMS_CAMPAIGN_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "GET_SMS_CAMPAIGN_LIST",
      };
    }
    case "CREATE_SMS_CAMPAIGN_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "CREATE_SMS_CAMPAIGN_LIST",
      };
    }
    case "GET_SMS_TEMPLATE_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "GET_SMS_TEMPLATE_LIST",
      };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    case "ADD_NEW_SMS_TEMPLATE": {
      return { ...state, data: action.payload, action: "ADD_NEW_SMS_TEMPLATE" };
    }
    case "GET_SMS_TEMPLATE_BY_ID": {
      return {
        ...state,
        data: action.payload,
        action: "GET_SMS_TEMPLATE_BY_ID",
      };
    }
    case "DELETE_SMS": {
      return { ...state, data: action.payload, action: "DELETE_SMS" };
    }
    case "EDIT_SMS_CAMPAIGN_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "EDIT_SMS_CAMPAIGN_LIST",
      };
    }
    case "DELETE_CAMPAIGN": {
      return { ...state, data: action.payload, action: "DELETE_CAMPAIGN" };
    }
    case "SAVE_TWILIO_NUMBER": {
      return { ...state, data: action.payload, action: "SAVE_TWILIO_NUMBER" };
    }
    case "SAVE_SMS_LIST": {
      return { ...state, data: action.payload, action: "SAVE_SMS_LIST" };
    }
    case "RESET_ALL": {
      return { action: "RESET_ALL" };
    }
    case "DOWNLOAD_EXCEL_FILE": {
      return { ...state, data: action.payload, action: "DOWNLOAD_EXCEL_FILE" };
    }
    case "SMS_READ_UNREAD": {
      return { ...state, data: action.payload, action: "SMS_READ_UNREAD" };
    }
    default: {
      return state;
    }
  }
};

export default InboxReducer;
