import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  searchTerm: yup.string().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    data: yup.array().of(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
        package_type: yup.string().required(),
        user: yup
          .object({
            id: yup.number().required(),
            full_name: yup.string().required(),
          })
          .nullable(),
        created: yup.string().required(),
      }),
    ),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    data,
  };
};

const createChartingPackagesQueryKey = (...args) => [
  QUERY_KEYS.chartingPackages,
  ...args,
];

export function useChartingPackagesQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createChartingPackagesQueryKey(req.pageSize, req.searchTerm),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getChartingPackages(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          term: req.searchTerm,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
