import React, { useState } from "react";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { Box } from "../../../shared/Box/Box";
import { PageHeader } from "../../../boxes/PageHeader/PageHeader";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { getPathParams } from "./PatientConsent.utils";
import { usePatientConsentQuery } from "../../../api/consents/patientConsents/usePatientConsentQuery";
import { uiNotification } from "../../../services/UINotificationService";
import { getIsPatientConsentNeedsSign } from "../../../utilities/api";
import { Button } from "../../../shared/Button/Button";
import { PenLineIcon } from "../../../assets/Icons/PenLineIcon";
import { PatientConsentPreview } from "../../../widgets/PatientConsentPreview/PatientConsentPreview";
import classes from "./PatientConsent.module.scss";
import { Loading } from "../../../boxes/Loading/Loading";
import { ModalPatientConsentSign } from "../../../widgets/ModalPatientConsentSign/ModalPatientConsentSign";
import { useClientQuery } from "../../../api/queries/useClientQuery";

export function PatientConsent() {
  const { tCommon } = useAppTranslation.Common();
  const { patientConsentId } = getPathParams();
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);

  const { data, isLoading, refetch } = usePatientConsentQuery({
    payload: {
      patientConsentId,
    },
    options: {
      enabled: Boolean(patientConsentId),
      onError: () => {
        uiNotification.error(tCommon("error.fetchConsent"));
      },
    },
  });

  const { data: patient, isLoading: isPatientLoading } = useClientQuery(
    {
      clientId: data?.patient_id,
    },
    {
      enabled: Boolean(data),
    },
  );

  return (
    <LayoutMain>
      <Box className={classes.root}>
        <PageHeader
          title={tCommon("label.patientConsent")}
          rightAdornment={
            getIsPatientConsentNeedsSign(data) && !isPatientLoading ? (
              <Button
                size="small"
                variant="text"
                leftAdornment={<PenLineIcon width="16px" />}
                onClick={() => setIsSignModalOpen(true)}
              >
                {tCommon("label.sign")}
              </Button>
            ) : undefined
          }
        />
        {isLoading || isPatientLoading ? (
          <Loading center />
        ) : (
          <PatientConsentPreview
            noDivider
            consent={{
              name: data.consent.consent_name,
              description: data.consent.consent_large_description,
              witnessSignatureUrl: data.witness_signature_image_url,
              witnessName: data.witness_name,
              patientSignatureUrl: data.signature_image_url,
              patientName: data.initials,
              patientSignedOn: data.signed_signature_on,
              witnessSignedOn: data.signed_witness_on,
            }}
            patient={{
              id: data.patient_id,
            }}
          />
        )}
      </Box>
      {isSignModalOpen && (
        <ModalPatientConsentSign
          isOpen
          onClose={() => setIsSignModalOpen(false)}
          onSuccess={() => {
            setIsSignModalOpen(false);
            refetch();
          }}
          consent={{
            id: data.id,
            name: data.consent.consent_name,
            patientSignatureUrl: data.signature_image_url,
            witnessSignatureUrl: data.witness_signature_image_url,
            witnessName: data.witness_name,
            isWitnessSignatureRequired: !!data.consent.witness_signature,
            patientName: patient?.fullName,
          }}
        />
      )}
    </LayoutMain>
  );
}
