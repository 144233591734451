import { stringify } from "query-string";
import { positionFooterCorrectly } from "../../Utils";
import { http } from "../../services/HttpService";

positionFooterCorrectly();

export function resetReducerAction() {
  return (dispatch) => {
    dispatch({ type: "BUSINESS_INSIGHT_RESET_ACTION" });
  };
}

export function getOptionAndList(type, formData) {
  formData = formData || {};

  let actionType = "";

  switch (type) {
    case "inventory":
      actionType = "GET_INVENTORY_REPORT";
      break;
    case "clients":
      actionType = "GET_CLIENTS_REPORT";
      break;
    case "sales":
      actionType = "GET_SALES_REPORT";
      break;
    case "appointments":
      actionType = "GET_APPOINTMENTS_REPORT";
      break;
    case "reputation-management":
      actionType = "GET_REPUTATION_REPORT";
      break;
    case "staff":
      actionType = "GET_STAFF_REPORT";
      break;
  }

  let reportOptions = [];

  let savedReports = [];

  return (dispatch) => {
    http
      .post("business-insights/" + type, formData)
      .then((response) => {
        reportOptions =
          response.data && response.data.data
            ? response.data.data.templates
            : [];
        savedReports =
          response.data && response.data.data ? response.data.data.reports : [];

        dispatch({
          type: actionType,
          payload: response.data,
          reportOptions: reportOptions,
          savedReports: savedReports,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionType,
          payload: error.response,
          reportOptions: reportOptions,
          savedReports: savedReports,
        });
      });
  };
}

export function saveReport(type, formData) {
  let actionType = "";
  switch (type) {
    case "inventory":
      actionType = "UPDATE_INVENTORY_SAVED_REPORTS";
      break;
    case "clients":
      actionType = "UPDATE_CLIENTS_SAVED_REPORTS";
      break;
    case "sales":
      actionType = "UPDATE_SALES_SAVED_REPORTS";
      break;
    case "appointments":
      actionType = "UPDATE_APPOINTMENTS_SAVED_REPORTS";
      break;
    case "reputation-management":
      actionType = "UPDATE_REPUTATION_SAVED_REPORTS";
      break;
    case "staff":
      actionType = "UPDATE_STAFF_SAVED_REPORTS";
      break;
  }

  return (dispatch) => {
    http
      .post("save-business-insight-report", formData)
      .then((response) => {
        dispatch({
          type: actionType,
          payload: response.data,
          savedReports: [],
        });
      })
      .catch((error) => {
        dispatch({
          type: actionType,
          payload: error.response.data,
          savedReports: [],
        });
      });
  };
}

export function getReportVariables(reportID) {
  return (dispatch) => {
    http
      .get("business-insight-variables/" + reportID)
      .then((response) => {
        dispatch({ type: "GET_REPORT_VARIABLES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_REPORT_VARIABLES",
          payload: error.response.data,
        });
      });
  };
}

export function getReportData(reportID, formData) {
  return async (dispatch) => {
    return await http
      .post("business-insight-report/" + reportID, formData)
      .then((response) => {
        dispatch({ type: "GET_REPORT_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_REPORT_DATA", payload: error.response.data });
      });
  };
}

export function deleteReport(_, formData) {
  return (dispatch) => {
    http
      .post("delete-business-insight-report", formData)
      .then((response) => {
        dispatch({ type: "DELETE_THIS_REPORT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_THIS_REPORT", payload: error.response.data });
      });
  };
}

export function updateReportOrder(_, formData) {
  return (dispatch) => {
    http
      .post("reorder-business-insight-reports", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_REPORT_ORDER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_REPORT_ORDER", payload: error.response.data });
      });
  };
}

export function downloadReportData(reportID, formData) {
  return (dispatch) => {
    http
      .post("business-insight-report/" + reportID, formData)
      .then((response) => {
        dispatch({ type: "DOWNLOAD_REPORT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DOWNLOAD_REPORT_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function fetchAspireHcpFilters() {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASPIRE_HCP_FILTERS_LOADING" });

    http
      .get(`aspire/usedCertificates/filters`)
      .then((response) => {
        dispatch({
          type: "FETCH_ASPIRE_HCP_FILTERS_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_ASPIRE_HCP_FILTERS_ERROR",
          payload: error.response.data,
        });
      });
  };
}

function formatAspireFilters(filters) {
  return filters
    ? Object.entries(filters)
        .reduce((result, [name, ids]) => {
          if (ids.length) {
            result.push(`${name}:${ids.join(",")}`);
          }
          return result;
        }, [])
        .join(";")
    : null;
}

async function getAspireRedeemedCertificates(dispatch, data, action) {
  const filter_by = formatAspireFilters(data.filterBy);
  const order_by = data.orderBy
    ? Object.entries(data.orderBy)
        .map((v) => v.join(":"))
        .join(",")
    : null;

  const qs = stringify({
    from_date: data.fromDate,
    to_date: data.toDate,
    order_by,
    filter_by,
    pagination: 20,
    page: data.page || 1,
  });

  return http
    .get(`aspire/usedCertificates?${qs}`)
    .then((response) => {
      dispatch({ type: action, payload: response.data });
      return response.data;
    })
    .catch((error) => {
      dispatch({ type: action, payload: error.response.data });
      return Promise.reject(error.response.data);
    });
}

export function fetchAspireRedeemedCertificates(data) {
  return async (dispatch) => {
    return getAspireRedeemedCertificates(
      dispatch,
      data,
      "GET_ASPIRE_REDEEMED_CERTIFICATES",
    );
  };
}

export function getSalesReportDetails(slug, formData) {
  return async (dispatch) => {
    if (slug === "redeemed_aspire_certificates") {
      return getAspireRedeemedCertificates(
        dispatch,
        formData,
        "GET_BI_SALES_DETAILS",
      );
    } else {
      return http
        .post("sales/" + slug, formData)
        .then((response) => {
          dispatch({ type: "GET_BI_SALES_DETAILS", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "GET_BI_SALES_DETAILS",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    }
  };
}

export function exportData(formData) {
  if (formData.filter_by) {
    formData.filter_by = formatAspireFilters(formData.filter_by);
  }

  return (dispatch) => {
    http
      .post("sales/export", formData)
      .then((response) => {
        dispatch({ type: "BI_EXPORT_FILE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "BI_EXPORT_FILE", payload: error.response.data });
      });
  };
}

export function fetchMembershipRevenueDetail(formData) {
  return (dispatch) => {
    http
      .post("sales/membership_members", formData)
      .then((response) => {
        dispatch({ type: "MEMBERSHIP_REVENUE_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "MEMBERSHIP_REVENUE_DETAIL",
          payload: error.response.data,
        });
      });
  };
}

export function exportMembershipRevenue(formData) {
  return (dispatch) => {
    http
      .post("sales/export", formData)
      .then((response) => {
        dispatch({ type: "MEMBERSHIP_REVENUE_EXPORT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "MEMBERSHIP_REVENUE_EXPORT",
          payload: error.response.data,
        });
      });
  };
}

export function getReportDetail(reportID, formData) {
  return (dispatch) => {
    http
      .post("business-insight-report/" + reportID, formData)
      .then((response) => {
        dispatch({ type: "GET_REPORT_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_REPORT_DETAIL", payload: error.response.data });
      });
  };
}

export function downloadSurveyData(formData) {
  return (dispatch) => {
    http
      .post("marketing/export", formData)
      .then((response) => {
        dispatch({
          type: "DOWNLOAD_SURVEY_REPORT_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DOWNLOAD_SURVEY_REPORT_DATA",
          payload: error.response.data,
        });
      });
  };
}
export function fetchDiscountClinicDetail(formData) {
  return (dispatch) => {
    http
      .post("sales/discount-by-clinic", formData)
      .then((response) => {
        dispatch({ type: "DISCOUNT_CLINIC_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DISCOUNT_CLINIC_DETAIL",
          payload: error.response.data,
        });
      });
  };
}

export function fetchMrrReportDetail(formData) {
  return (dispatch) => {
    http
      .post("sales/mrr_report_details", formData)
      .then((response) => {
        dispatch({ type: "MRR_REPORT_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "MRR_REPORT_DETAIL", payload: error.response.data });
      });
  };
}

export function fetchChrunReportDetail(formData) {
  return (dispatch) => {
    http
      .post("sales/membership_chrun_report_details", formData)
      .then((response) => {
        dispatch({ type: "CHRUN_REPORT_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CHRUN_REPORT_DETAIL", payload: error.response.data });
      });
  };
}
export function saveSmsFilterReport(formData) {
  return (dispatch) => {
    http
      .post("save-sms-list-filter-report", formData)
      .then((response) => {
        dispatch({ type: "SAVE_SMS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_SMS_LIST", payload: error.response.data });
      });
  };
}
export function shareReportData(reportID, formData) {
  return (dispatch) => {
    http
      .post("business-insight-report/" + reportID, formData)
      .then((response) => {
        dispatch({ type: "SHARE_REPORT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SHARE_REPORT_DATA", payload: error.response.data });
      });
  };
}
export function fetchMembershipDeclineDetail(formData) {
  return (dispatch) => {
    http
      .post("sales/membership_decline_report_detail", formData)
      .then((response) => {
        dispatch({ type: "MEMBERSHIP_DECLINE_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "MEMBERSHIP_DECLINE_DETAIL",
          payload: error.response.data,
        });
      });
  };
}

export function hideDeclineInvoice(membershipInvoiceId) {
  return (dispatch) => {
    http
      .delete("sales/hide_membership_decline_invoice/" + membershipInvoiceId)
      .then((response) => {
        dispatch({
          type: "HIDE_MEMBERSHIP_DECLINE_INVOICE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "HIDE_MEMBERSHIP_DECLINE_INVOICE",
          payload: error.response.data,
        });
      });
  };
}

export function resendEGiftCardMail(id) {
  return (dispatch) => {
    http
      .patch("sales/resend-egift-card-mail/" + id)
      .then((response) => {
        dispatch({ type: "RESEND_EGIFT_CARD_MAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "RESEND_EGIFT_CARD_MAIL",
          payload: error.response.data,
        });
      });
  };
}

export function fetchBdAspireDetail(formData) {
  return (dispatch) => {
    http
      .post("sales/bd_aspire_report_detail", formData)
      .then((response) => {
        dispatch({ type: "BDASPIRE_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "BDASPIRE_DETAILS", payload: error.response.data });
      });
  };
}

export function fetchTimeCardReport(formData) {
  return (dispatch) => {
    http
      .get("time_card_reports", formData)
      .then((response) => {
        dispatch({ type: "TIME_CARD_REPORT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "TIME_CARD_REPORT", payload: error.response.data });
      });
  };
}

export function fetchTimeCardReportDetails(formData) {
  return (dispatch) => {
    http
      .get("time_card_details", formData)
      .then((response) => {
        dispatch({ type: "TIME_CARD_REPORT_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "TIME_CARD_REPORT_DETAILS",
          payload: error.response.data,
        });
      });
  };
}

export function exportTimeCardReport(formData) {
  return (dispatch) => {
    http
      .get("export_time_tracking", formData)
      .then((response) => {
        dispatch({ type: "TIME_CARD_REPORT_EXPORT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "TIME_CARD_REPORT_EXPORT",
          payload: error.response.data,
        });
      });
  };
}

export function fetchRetailReportDetail(formData) {
  return (dispatch) => {
    http
      .post("sales/retail_report_details", formData)
      .then((response) => {
        dispatch({ type: "RETAIL_REPORT_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "RETAIL_REPORT_DETAIL",
          payload: error.response.data,
        });
      });
  };
}

export function updateTimeTracking(timeTrackingId, formData) {
  return async (dispatch) => {
    return http
      .post("update_time_tracking/" + timeTrackingId, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_TIME_TRACKING", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_TIME_TRACKING",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}
