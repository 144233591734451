import React from "react";
import { Table } from "../../../../../shared/Table/Table";

const ProviderCommissions = ({ tableData }) => {
  return (
    <Table
      bgColor="white"
      cols={[
        { data: "Employee", accessor: "employee" },
        { data: "Total Sales", accessor: "totalSales" },
        { data: "Net Sales", accessor: "netSales" },
        {
          data: "Total Procedure Sales",
          accessor: "totalProcedureSales",
        },
        { data: "Commission Percent", accessor: "commissionPercent" },
        { data: "Commission Amount", accessor: "commissionAmount" },
      ]}
      data={tableData}
    />
  );
};

export default ProviderCommissions;
