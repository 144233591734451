import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "../sass/components/EntityRow.module.scss";

export const EntityRow = ({
  label,
  children,
  className,
  labelClassName,
  contentClassName,
  width,
  noMarginBottom,
  size,
}) => (
  <div
    className={cx(
      classes.root,
      {
        [classes.rootWNone]: width === "none",
        [classes.rootWMax]: width === "max",
        [classes.rootNoMarginBottom]: noMarginBottom,
        [classes.rootSmall]: size === "small",
      },
      className,
    )}
  >
    <div
      className={cx(classes.label, labelClassName, {
        [classes.smallLabel]: size === "small",
      })}
    >
      {label}
    </div>
    <div className={cx(classes.content, contentClassName)}>{children}</div>
  </div>
);

EntityRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  width: PropTypes.oneOf(["none", "max"]),
  noMarginBottom: PropTypes.bool,
  size: PropTypes.oneOf(["small", "large"]),
};

EntityRow.defaultProps = {
  className: undefined,
  labelClassName: undefined,
  contentClassName: undefined,
  width: "max",
  noMarginBottom: false,
  size: "large",
};
