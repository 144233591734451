import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ShopifyFinancials = (props) => {
  const [languageData] = useState(
    JSON.parse(localStorage.getItem("languageData")),
  );
  const [reportData, setReportData] = useState(props.reportData || []);

  useEffect(() => {
    if (props.reportData !== reportData) {
      setReportData(props.reportData);
    }
  }, [props.reportData, reportData]);

  return (
    <div className="table-responsive clients-table">
      <table className="table-updated setting-table table-min-width no-td-border no-hover">
        <thead className="table-updated-thead">
          <tr>
            <th className="table-updated-th">
              {reportData?.report_header?.gross_sales}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.discounts}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.returns}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.net_sales}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.shipping}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.return_fees}
            </th>
            <th className="table-updated-th">
              {reportData?.report_header?.tax}
            </th>
          </tr>
        </thead>
        <tbody className="ajax_body">
          {reportData?.report_data?.length > 0 &&
            reportData.report_data.map((obj, idx) => (
              <tr
                key={`ShopifyFinancialsRow_${idx.toString()}`}
                className="table-updated-tr"
              >
                <td className="table-updated-td">{obj.gross_sales}</td>
                <td className="table-updated-td">{obj.discounts}</td>
                <td className="table-updated-td">{obj.returns}</td>
                <td className="table-updated-td">{obj.net_sales}</td>
                <td className="table-updated-td">{obj.shipping}</td>
                <td className="table-updated-td">{obj.return_fees}</td>
                <td className="table-updated-td">{obj.tax}</td>
              </tr>
            ))}
          {reportData?.report_data?.length === 0 && (
            <tr className="table-updated-tr">
              <td className="table-updated-td no-record no-float" colSpan="10">
                {languageData.business_insights.bi_no_record_found}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ShopifyFinancials.propTypes = {
  reportData: PropTypes.shape({
    report_header: PropTypes.shape({
      gross_sales: PropTypes.string,
      shipping: PropTypes.string,
      return_fees: PropTypes.string,
      net_sales: PropTypes.string,
      returns: PropTypes.string,
      discounts: PropTypes.string,
      tax: PropTypes.string,
    }),
    report_data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        shipping: PropTypes.string,
        gross_sales: PropTypes.string,
        net_sales: PropTypes.string,
        returns: PropTypes.string,
        discounts: PropTypes.string,
        return_fees: PropTypes.string,
        tax: PropTypes.string,
      }),
    ),
  }),
};

export default ShopifyFinancials;
