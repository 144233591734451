import { useEffect, useState } from "react";
import { useDeletePosBankMutation } from "../../../../api/mutations/useDeleteInboxMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { getTwilioConfig, setTwilioConfig } from "../../../../Utils";

export function useInbox() {
  const [isInboxEnabled, setIsInboxEnabled] = useState(false);
  const [isToggleEnabled, setIsToggleEnabled] = useState(false);
  const [isDisableConfirmModalOpen, setIsDisableConfirmModalOpen] =
    useState(false);

  const { mutateAsync, isLoading } = useDeletePosBankMutation();

  const disableInbox = async () => {
    try {
      if (isDisableConfirmModalOpen) {
        setIsDisableConfirmModalOpen(false);
      }
      await mutateAsync();
      setIsToggleEnabled(false);
      setIsInboxEnabled(false);
      setTwilioConfig(0);
    } catch {
      uiNotification.error("Unable To Disable Inbox");
    }
  };

  const onToggle = () => {
    if (isToggleEnabled) {
      if (isInboxEnabled) {
        setIsDisableConfirmModalOpen(true);
      } else {
        setIsToggleEnabled(false);
      }
    } else {
      setIsToggleEnabled(true);
    }
  };

  useEffect(() => {
    const isTwilioEnabled = getTwilioConfig();
    setIsInboxEnabled(isTwilioEnabled);
    setIsToggleEnabled(isTwilioEnabled);
  }, []);

  return {
    isInboxEnabled,
    toggle: {
      value: isToggleEnabled,
      onChange: onToggle,
    },
    disable: {
      initiate: disableInbox,
      isLoading,
      isNeedConfirm: isDisableConfirmModalOpen,
      cancelConfirm: () => setIsDisableConfirmModalOpen(false),
    },
  };
}
