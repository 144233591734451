/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "clsx";
import moment from "moment";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { templateSchedule } from "../../../../_legacy/Constants";
import { useOverrideProviderScheduleQuery } from "../../../../_legacy/Queries";
import { convertTime12to24, handleScheduleMasking } from "../../../../Utils";
import DeleteIcon from "../../../../_legacy/images/delete-dustbin.svg";
import PlusIcon from "../../../../_legacy/images/plus.svg";
import { uiNotification } from "../../../../services/UINotificationService";
import { Button } from "../../../../shared/Button/Button";
import { Modal } from "../../../../shared/Modal/Modal";
import { Select } from "../../../../shared/Select/Select";
import CalendarDayPicker from "../../../Common/DayPicker";
import Loader from "../../../Common/Loader";
import classes from "../sass/schedule.module.scss";

import { checkOverlappingTime } from "../../../../utilities/general";

const customHoursSchema = yup.object().shape({
  selectedClinic: yup.object().shape({
    value: yup.number().typeError("Required").required("Required"),
  }),
  selectedDays: yup
    .array()
    .of(yup.date())
    .min(1, "Select at least 1 day to override"),
  timeSchedules: yup.array().of(
    yup.object().shape({
      fromTime: yup.string().required("Required"),
      toTime: yup
        .string()
        .required("Required")
        .test(
          "to time is greater than from time",
          "Invalid Schedule",
          (toTime, context) => {
            const { fromTime } = context.parent;
            return (
              toTime &&
              fromTime &&
              parseInt(toTime.replace(":", "")) >
                parseInt(fromTime.replace(":", ""))
            );
          },
        ),
      availableFor: yup.string().required("Required"),
    }),
  ),
});

const OverrideScheduleModal = ({
  isOpen,
  onClose,
  clinicsAssociated,
  providerId,
  selectedPickerDate,
  refetchSchedule,
  defaultClinic,
}) => {
  const { mutate, isLoading } = useOverrideProviderScheduleQuery();

  const formMethods = useForm({
    defaultValues: {
      availableClinicsOptions: clinicsAssociated.map((clinicDetails) => ({
        value: clinicDetails.id,
        label: clinicDetails.clinic_name,
      })),
      selectedClinic: clinicsAssociated?.length
        ? {
            label: defaultClinic.clinic_name,
            value: defaultClinic.id,
          }
        : {
            label: "",
            value: null,
          },
      selectedDays: selectedPickerDate ? [selectedPickerDate] : [],
      timeSchedules: [templateSchedule],
      calendarDefaultMonth: selectedPickerDate || new Date(),
    },
    resolver: yupResolver(customHoursSchema),
  });

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = formMethods;

  const formValues = watch();

  const {
    selectedDays,
    timeSchedules,
    selectedClinic,
    availableClinicsOptions,
    calendarDefaultMonth,
  } = formValues;

  const updateSelectedDays = (days) => {
    setValue("selectedDays", days);
  };

  const overrideDate = (formData) => {
    const { selectedDays, selectedClinic, timeSchedules } = formData;

    let overlappingTimeError = false;

    const isTimeSlotsOverlapping = checkOverlappingTime(timeSchedules);
    if (isTimeSlotsOverlapping) {
      overlappingTimeError = true;
      isTimeSlotsOverlapping.forEach((slotIndex) => {
        setError(`timeSchedules[${slotIndex}].fromTime`);
        setError(`timeSchedules[${slotIndex}].toTime`);
      });
    }

    if (overlappingTimeError) {
      uiNotification.error("Time entered for clinic is overlapping");
      return false;
    }

    mutate(
      {
        provider_id: providerId,
        clinic_id: selectedClinic.value,
        schedule_dates: selectedDays.map((date) =>
          moment(date).format("YYYY-MM-DD"),
        ),
        time_schedules: timeSchedules.map((scheduleDetails) => ({
          from_time: scheduleDetails.fromTime,
          to_time: scheduleDetails.toTime,
          available_for: scheduleDetails.availableFor,
        })),
      },
      {
        onSuccess: () => {
          if (refetchSchedule) {
            refetchSchedule();
          }
          onClose();
        },
      },
    );
  };

  const clinicChange = (clinicDetails) => {
    setValue("selectedClinic", clinicDetails, {
      shouldValidate: Boolean(errors.selectedClinic),
    });
  };

  const maskChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    return handleScheduleMasking(value, selection, cursorPosition, userInput);
  };

  const setDayTime = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const { scheduleindex: scheduleIndex, shouldvalidate } =
      event.currentTarget.dataset;

    const { timeSchedules } = watch();

    const tempSchedules = [...timeSchedules];

    tempSchedules[scheduleIndex][name] = value;

    switch (name) {
      case "fromTimeHour":
      case "fromTimeOption": {
        const { fromTimeHour, fromTimeOption } = tempSchedules[scheduleIndex];
        const convertedFromTime = convertTime12to24(
          [fromTimeHour, fromTimeOption].join(" "),
        );

        const fromTime =
          convertedFromTime === "00:00" ? null : convertedFromTime;

        tempSchedules[scheduleIndex].fromTime = fromTime;
        setValue(
          `timeSchedules.${scheduleIndex}`,
          tempSchedules[scheduleIndex],
          {
            shouldValidate: shouldvalidate,
          },
        );
        break;
      }
      case "toTimeHour":
      case "toTimeOption": {
        const { toTimeHour, toTimeOption } = tempSchedules[scheduleIndex];
        const convertedToTime = convertTime12to24(
          [toTimeHour, toTimeOption].join(" "),
        );

        const toTime = convertedToTime === "00:00" ? null : convertedToTime;

        tempSchedules[scheduleIndex].toTime = toTime;
        setValue(
          `timeSchedules.${scheduleIndex}`,
          tempSchedules[scheduleIndex],
          {
            shouldValidate: shouldvalidate,
          },
        );
        break;
      }
      default:
        setValue(name, value);
        break;
    }
  };

  const deleteScheduleItem = (scheduleItemIndex, shouldValidate = false) => {
    const tempTimeSchedules = [...timeSchedules];
    tempTimeSchedules.splice(scheduleItemIndex, 1);

    setValue(`timeSchedules`, tempTimeSchedules, { shouldValidate });
  };

  const addNewTimeSlot = () => {
    setValue(`timeSchedules`, [...timeSchedules, templateSchedule]);
  };

  const handleFormErrors = () => {
    if (errors.selectedDays) {
      uiNotification.error("Please select at least 1 date");
    }
  };

  return (
    <Modal
      className={cx("protected", classes.overrideScheduleModal)}
      isOpen={isOpen}
      header={<Modal.Title>Date Override</Modal.Title>}
      onClose={onClose}
      contentClassName={classes.modalContent}
      headerNoBorder
      footerNoBorder
    >
      <form
        className={classes.modalRoot}
        onSubmit={handleSubmit(overrideDate, handleFormErrors)}
      >
        {isLoading && <Loader showLoader />}
        <div>
          <div className={classes.titleAndDate}>
            <div className={classes.contentTitle}>
              Select the date(s) you want to assign specific hours
            </div>

            <CalendarDayPicker
              mode="multiple"
              selectedDays={selectedDays}
              onChange={updateSelectedDays}
              disabled={{
                before: new Date(),
              }}
              defaultMonth={calendarDefaultMonth}
            />
          </div>
          <div className={classes.scheduleDetails}>
            <div>
              <Select
                isError={!!errors.selectedClinic}
                value={selectedClinic}
                onChange={clinicChange}
                options={availableClinicsOptions}
                isSearchable
                placeholder="Select Clinic"
                name="select-clinic"
                size="small"
              />
            </div>

            <div className={classes.editScheduleModal}>
              <div className={classes.weekDaysSchedule}>
                <div className={classes.daySchedule}>
                  <div className={classes.dayScheduleSlots}>
                    {timeSchedules.map((slotDetails, scheduleItemIndex) => {
                      const {
                        fromTimeHour,
                        fromTimeOption,
                        toTimeHour,
                        toTimeOption,
                        availableFor,
                      } = slotDetails;

                      const { fromTime: fromTimeError, toTime: toTimeError } =
                        errors?.timeSchedules?.[scheduleItemIndex] || {};

                      return (
                        <div
                          className={cx(
                            classes.scheduleRow,
                            "fromandtoTime  no-padding row schedule-row items-center",
                          )}
                          key={`timeSchedules-${scheduleItemIndex}`}
                        >
                          <div className={classes.scheduleTimeFields}>
                            <div
                              className={cx(
                                classes.newInputFileldOuter,
                                "newInputFileldOuter",
                              )}
                            >
                              <InputMask
                                name="fromTimeHour"
                                data-scheduleindex={scheduleItemIndex}
                                data-shouldvalidate={fromTimeError}
                                value={fromTimeHour}
                                mask="99:99"
                                className={cx(
                                  {
                                    [classes.timeFieldError]: fromTimeError,
                                  },
                                  classes.inputTime,
                                  `setting-input-box hours-time from-time-input `,
                                )}
                                placeholder="00:00"
                                onChange={setDayTime}
                                maskChar=""
                                beforeMaskedValueChange={maskChange}
                                autoComplete="off"
                              />
                              <select
                                name="fromTimeOption"
                                data-scheduleindex={scheduleItemIndex}
                                data-shouldvalidate={fromTimeError}
                                value={fromTimeOption}
                                className={cx(
                                  classes.inputTimeOption,
                                  {
                                    [classes.timeOptionFieldError]:
                                      fromTimeError,
                                  },
                                  `newSelectField hours-pm fromTimeOption `,
                                )}
                                onChange={setDayTime}
                              >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                              </select>
                            </div>
                            <div
                              className={cx(
                                classes.newInputFileldOuter,
                                "newInputFileldOuter",
                              )}
                            >
                              &nbsp;-&nbsp;
                            </div>
                            <div
                              className={cx(
                                classes.newInputFileldOuter,
                                "newInputFileldOuter",
                              )}
                            >
                              <InputMask
                                name="toTimeHour"
                                data-scheduleindex={scheduleItemIndex}
                                data-shouldvalidate={toTimeError}
                                value={toTimeHour}
                                mask="99:99"
                                className={cx(
                                  classes.inputTime,
                                  {
                                    [classes.timeFieldError]: toTimeError,
                                  },
                                  `setting-input-box hours-time from-time-input`,
                                )}
                                placeholder="00:00"
                                onChange={setDayTime}
                                maskChar=""
                                beforeMaskedValueChange={maskChange}
                                autoComplete="off"
                              />
                              <select
                                name="toTimeOption"
                                data-scheduleindex={scheduleItemIndex}
                                data-shouldvalidate={toTimeError}
                                value={toTimeOption}
                                className={cx(
                                  classes.inputTimeOption,
                                  {
                                    [classes.timeOptionFieldError]: toTimeError,
                                  },
                                  `newSelectField hours-pm fromTimeOption `,
                                )}
                                onChange={setDayTime}
                              >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                              </select>
                            </div>
                          </div>

                          <div
                            className={cx(
                              classes.newInputFileldOuter,
                              classes.appointmentTypeField,
                              "newInputFileldOuter",
                            )}
                          >
                            <select
                              name="availableFor"
                              data-scheduleindex={scheduleItemIndex}
                              value={availableFor}
                              className={cx(
                                classes.appointmentTypeSelect,
                                " newSelectField ",
                              )}
                              onChange={setDayTime}
                            >
                              <option value="both">
                                All Appointment Types
                              </option>
                              <option value="inperson">In-Person Only</option>
                              <option value="virtual">Virtual Only</option>
                            </select>
                          </div>

                          <>
                            {timeSchedules.length > 1 && (
                              <div
                                className={classes.deleteScheduleIcon}
                                onClick={() =>
                                  deleteScheduleItem(
                                    scheduleItemIndex,
                                    Boolean(fromTimeError || toTimeError),
                                  )
                                }
                              >
                                <img src={DeleteIcon} alt="delete" />
                              </div>
                            )}
                            <span
                              onClick={addNewTimeSlot}
                              className="cursor-pointer"
                            >
                              <img src={PlusIcon} alt="new" />
                            </span>
                          </>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.footer}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Modal>
  );
};

export default OverrideScheduleModal;
