import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {getBAVideos} from '../../Actions/Clients/clientsAction.js'
import defVImage from '../../_legacy/images/no-image-vertical.png';
import picClose from '../../_legacy/images/close.png';
import ReactPlayer from 'react-player'
import { uiNotification } from "../../services/UINotificationService.js";


class BAVideos extends Component {
  constructor(props) {
    super(props);

    const languageData  = JSON.parse(localStorage.getItem('languageData'))

    this.state = {
      backURLType           : this.props.match.url.split('/')[1],
      action                : (this.props.match.params.type) ? this.props.match.params.type : 'profile',
      showLoader            : false,
      procedureID           : this.props.match.params.procedureID,
      globalLang            : languageData.global,
      def_no_image_vertical : defVImage,
      VideosData  : [],
      afterVideos : [],
      beforeVideos: [],
      showVideo :  false,
      url: '',
      showBefore :  true
    }
    window.onscroll = () => {
       return false;
    }
  }

  componentDidMount() {
    this.setState({
      showLoader: true
    });

    this.props.getBAVideos(this.state.procedureID);
  }

  static getDerivedStateFromProps(props, state) {
    let returnState ={};
    if ( props.VideosData !== undefined && props.VideosData.status === 200 && props.VideosData.data !== state.VideosData ) {
          let after = [];
          let before = [];
         returnState.VideosData = props.VideosData.data ? props.VideosData.data : []
         returnState.showLoader = false
         returnState.VideosData.map((obj, id)=>{
            if(obj.video_mode == "before"){
              before.push(obj)
            }else{
              after.push(obj)
            }
         }) 
       returnState.afterVideos = after
       returnState.beforeVideos = before
       return returnState
  

     } else if ( props.VideosData !== undefined && props.VideosData.status !== 200 && props.VideosData.data !== state.VideosData ) {
         let after = [];
         let before = [];
         returnState.VideosData = props.VideosData.data ? props.VideosData.data : []
         returnState.showLoader    =  false
         returnState.VideosData.map((obj, id)=>{
          if(obj.video_mode == "before"){
            before.push(obj)
          }else{
            after.push(obj)
          }
       }) 
     returnState.afterVideos = after
     returnState.beforeVideos = before
         return returnState
     }

    return null
  }

showVideo = (link)=>{
  if(link){
  this.setState({showVideo: true, url: link})
  }else{
    this.setState({error: true})
  }
}

closeVideo = ()=>{
  this.setState({showVideo: false, url:'', error: false})
} 
  render() {
  
    let beforeVideos = this.state.beforeVideos.length > 0 ? this.state.beforeVideos : []
    let afterVideos = this.state.afterVideos.length > 0 ? this.state.afterVideos : []

    let returnTo = '';

    if ( this.state.backURLType && this.state.backURLType === 'clients' ) {
      returnTo = (this.props.match.params.actionType) ? "/" + this.props.match.params.actionType + "/" + this.props.match.params.action + "/" + this.props.match.params.clientID  : "/" + this.props.match.params.actionType;
    }

   
    return (
     
      <div id="content">
         <div className="container-fluid content setting-wrapper">
            <div className="juvly-section full-width m-t-15">
               <div className="juvly-container">
                  <div className="juvly-title m-b-15">Procedure Videos
                    <Link to={returnTo} className="pull-right"><img src={picClose}/></Link>
                  </div>
        <div className="pdfImageContainer">
          <div className="procedureTabOuter">
          <ul class="appointment-tabs"><li>
              <a onClick={()=>{this.setState({showBefore: true})}} className={this.state.showBefore ? "active" : "" }>{this.state.globalLang["before_videos"]}</a>
            </li>
            <li><a onClick={()=>{this.setState({showBefore:false})}} className={this.state.showBefore ? "" :  "active"}>{this.state.globalLang["after_videos"]}</a></li>
          </ul>            
          </div>
          
        {this.state.showBefore &&
         
          <div className="row">
             {/*<video width="352" height="198" controls>
          <source src="https://ar-ba-videos.s3-us-west-2.amazonaws.com/video1577364957146.m3u8" type="application/x-mpegURL" />
        </video>*/}
           {(beforeVideos.length  == 0 || beforeVideos.length < 0)  ?
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="procedure-img relative procedureVideo">
                  <div className="videoThumbOuter"><img  src={ "/images/no-video-new.png" }></img></div>
                  
                </div>
              </div>
            :
          beforeVideos && beforeVideos.map((obj, id)=> {
          return(
              <div className="col-md-4 col-sm-6 col-xs-12" key={id}>
                <div className="procedure-img relative procedureVideo">
                <div className="videoThumbOuter"><img  src={obj.thumb_url ? obj.thumb_url : "" }></img>
                  <div onClick= {()=>this.showVideo(obj.cloudfront_url)}  className="video-play-outer"><i className="fa fa-play-circle video-play"></i></div>
                  </div>
                  <div className="videoTitle">{this.state.globalLang[obj.video_type]}</div>
                </div>
              </div>
                   ) }) }   
            </div>}
        {!this.state.showBefore &&
            <div className="row">
            {(afterVideos.length  == 0 || afterVideos.length < 0)  ?
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="procedure-img relative procedureVideo">
                  <div className="videoThumbOuter"><img  src={ "/images/no-video-new.png" }></img></div>
                  
                </div>
              </div>
        :
           afterVideos && afterVideos.map((obj, id)=> {
            return(
            <div className="col-md-4 col-sm-6 col-xs-12" key={id}>
              <div className="procedure-img relative procedureVideo">
              <div className="videoThumbOuter"><img  src={obj.thumb_url ? obj.thumb_url : "" }></img>
                  <div onClick= {()=>this.showVideo(obj.cloudfront_url)}  className="video-play-outer"><i className="fa fa-play-circle video-play"></i></div>
                  </div>
              <div className="videoTitle">{this.state.globalLang[obj.video_type]}</div>
                  </div>
                </div>
                ) }) }   
              </div>}
            </div>  
         </div>
        </div>
      </div>
        {this.state.url &&
           <div className={(this.state.showVideo) ? 'blackOverlay videoOverlay overflow-hidden' : 'blackOverlay no-display overflow-hidden'}>
            <div className="vert-middle vert-middle-report-option">
              <div className={"videoPreview"}>
                <div className="white-popup-wrapper p-b-10">
                  

                  <div className="mainVideoOuter">
                  <div className="membershipTypeTitle">{""}<a onClick={this.closeVideo} className="popupClose videoPopupClose"><i className="fa fa-times"></i></a></div>
                  
                  <ReactPlayer url={this.state.url} 
                            controls = {true}
                            playing ={(this.state.showVideo)}
                            config={{
                              file: {
                                hlsOptions: {
                                  forceHLS: true,
                                  debug: false,
                                },
                              },
                            }}

                          />
                 </div>
                </div>
              </div>
            </div>
          </div>}
          {/*popup Ends*/}
                   
         <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
           <div className="loader-outer">
             <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
             <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.loading_please_wait_text}</div>
           </div>
         </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState  = {};

  if ( state.ClientsReducer.action === "VIEW_BAVIDEOS" ) {
    if ( state.ClientsReducer.data.status !== 200 ) {
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.VideosData = state.ClientsReducer.data;
    } else {
      returnState.VideosData = state.ClientsReducer.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getBAVideos: getBAVideos}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps) (BAVideos);
