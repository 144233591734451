import axios from "axios";
import { getPaginationParams } from "./useLocalStorage";
import { responseInterceptors } from "../services/HttpService";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";
import { REQ_HEADERS } from "../consts/api";

/**
 * @deprecated - use HttpService from src/services
 */
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

AxiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken);

  if (token) {
    config.headers = {
      ...config.headers,
      [REQ_HEADERS.accessToken]: token,
    };
  }

  if (
    config &&
    (Boolean(config.data && "handlePagination" in config.data) ||
      Boolean(config.params && "handlePagination" in config.params))
  ) {
    config.params = { ...getPaginationParams(config.params) };
  }

  return config;
});

AxiosInstance.interceptors.response.use(
  responseInterceptors.success,
  responseInterceptors.error,
);

export default AxiosInstance;
