import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import Footer from "../../../_legacy/Containers/Guest/Footer.js";
import { getSignUpAccountStatus } from "../../../Actions/SignUp/signUpAction.js";
import FooterTouchMD from "../../../_legacy/Containers/Guest/FooterTouchMD.js";
import CandelaHeader from "./HeaderCandela";
import { http } from "../../../services/HttpService.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const redirectToLogin = () => {
  localStorage.removeItem("accountSetupFile");
  localStorage.removeItem("accountSetupStatus");
  window.location.href = "/login";
};

class CandelaAccountSetup extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { type },
      },
    } = this.props;
    this.state = {
      showModal: false,
      accountSetupFile: "",
      accountSetupStatus: 0,
      accountSetupData: {},
      accountType: "",
      currentStatus: "",
      checkingStatus: true,
      globalLang: {},
      isAccountSetupDone: false,
      interval: "",
      type: type || "",
    };
  }

  componentDidMount() {
    const storageLanguageData = localStorage.getItem("languageData");
    const { type } = this.state;
    const basicAccount = "basic";
    const premiumAccount = "premium";

    if (
      !storageLanguageData ||
      storageLanguageData.global === undefined ||
      !storageLanguageData.global
    ) {
      http
        .get("getLanguageText/1/global")
        .then((res) => {
          const languageData = res.data.data;
          localStorage.setItem("languageData", JSON.stringify(languageData));
          this.setState({
            globalLang: languageData.global,
            currentStatus: languageData.global.autobots_setting_up_your_account,
          });
        })
        .catch(function () {});
    } else {
      const languageData = JSON.parse(storageLanguageData);
      this.setState({
        globalLang: languageData.global,
        currentStatus: languageData.global.autobots_setting_up_your_account,
      });
    }

    const accountType = type || premiumAccount;
    if (accountType == basicAccount || accountType == premiumAccount) {
      this.setState({ accountType: accountType });
    } else {
      redirectToLogin();
    }
    const accountSetupFile = localStorage.getItem("accountSetupFile");
    if (
      accountSetupFile != undefined &&
      accountSetupFile != null &&
      accountSetupFile != ""
    ) {
      let accountSetupStatus = localStorage.getItem("accountSetupStatus");
      if (
        accountSetupStatus == undefined ||
        accountSetupStatus == null ||
        accountSetupStatus == "null" ||
        accountSetupStatus <= 0
      ) {
        accountSetupStatus = 0;
        localStorage.setItem("accountSetupStatus", accountSetupStatus);
      } else {
        accountSetupStatus = accountSetupStatus - 1;
        accountSetupStatus = parseInt(accountSetupStatus);
      }
      this.setState({
        accountSetupFile: accountSetupFile,
        accountSetupStatus: accountSetupStatus,
        checkingStatus: false,
      });
      uiNotification.clear();
      uiNotification.success(
        "Please verify your account, Verification link sent to your email.",
      );
      this.getSignUpAccountStatus(accountSetupFile, accountSetupStatus);
      const _self = this;
      let interval = setInterval(function () {
        _self.getSignUpAccountStatus(accountSetupFile);
      }, 2000);
      this.setState({ interval: interval });
      localStorage.setItem("accountSetupInterval", interval);
    } else {
      redirectToLogin();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
    } else if (
      nextProps.accountSetupData !== undefined &&
      nextProps.accountSetupData !== prevState.accountSetupData
    ) {
      returnState.accountSetupData = nextProps.accountSetupData;
      if (
        nextProps.accountSetupData.return_text != undefined &&
        nextProps.accountSetupData.return_text !== "" &&
        nextProps.accountSetupData.return_text !== prevState.currentStatus
      ) {
        returnState.currentStatus =
          prevState.globalLang[nextProps.accountSetupData.return_text];
        if (
          nextProps.accountSetupData.return_text.toLowerCase().trim() ==
          "autobots_setup_completed"
        ) {
          returnState.isAccountSetupDone = true;
        } else {
          returnState.accountSetupStatus = prevState.accountSetupStatus + 1;
          localStorage.setItem(
            "accountSetupStatus",
            parseInt(returnState.accountSetupStatus),
          );
        }
      }
    }

    return returnState;
  }

  getSignUpAccountStatus = (accountSetupFile, newAccountSetupStatus) => {
    const { history } = this.props;
    const { isAccountSetupDone, interval, type, accountSetupStatus } =
      this.state;

    const status = newAccountSetupStatus || accountSetupStatus;
    if (isAccountSetupDone) {
      clearInterval(interval);
      const _self = this;
      _self.setState({ currentStatus: "Almost Done" });

      const redirectTo =
        type == "" ? "/candela-account/success" : "/account/success";
      setTimeout(function () {
        history.push(redirectTo);
      }, 2000);
    } else {
      this.props.getSignUpAccountStatus({
        file_name: accountSetupFile,
        line_number: status,
      });
    }
  };

  render() {
    return (
      <div className="guest">
        <CandelaHeader />
        <div className="sign-up-account-setup">
          <div className="container">
            <div className="thanku">
              <h2 className="current-status">
                {!this.state.checkingStatus ? this.state.currentStatus : null}
              </h2>
              <h3 className="static-line">
                {!this.state.checkingStatus
                  ? this.state.globalLang.autobots_are_working_background
                  : null}
              </h3>
              <img
                alt=""
                src="/images/account-loader.gif"
                className="account-loader"
              />
            </div>
          </div>
        </div>
        {this.state.type == "" ? <FooterTouchMD /> : <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let returnState = {};
  if (state.SignUpReducer.action === "GET_SIGNUP_ACCOUNT_STATUS") {
    if (state.SignUpReducer.data.status != 200) {
      returnState.showLoader = false;
    } else if (state.SignUpReducer.data.status == 400) {
      redirectToLogin();
    } else {
      returnState.accountSetupData = state.SignUpReducer.data.data;
    }
  }
  return returnState;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSignUpAccountStatus: getSignUpAccountStatus,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CandelaAccountSetup));
