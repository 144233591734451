import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";

export const useUpdateChartCategory = (id, options = {}) => {
  return useMutation(
    (dto) => http.post(HTTP_ENDPOINTS.updateChartCategory(id), dto),
    options,
  );
};
