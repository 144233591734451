export const templateSchedule = {
  fromTime: null,
  fromTimeHour: "00:00",
  fromTimeOption: "AM",
  toTime: null,
  toTimeHour: "00:00",
  toTimeOption: "PM",
  availableFor: "both",
  isDeleted: false,
};

export const weekTemplate = [
  {
    id: 1,
    dayName: "SUN",
    dayFullName: "Sunday",
    timeSchedules: [templateSchedule],
  },
  {
    id: 2,
    dayName: "MON",
    dayFullName: "Monday",
    timeSchedules: [templateSchedule],
  },
  {
    id: 3,
    dayName: "TUE",
    dayFullName: "Tuesday",
    timeSchedules: [templateSchedule],
  },
  {
    id: 4,
    dayName: "WED",
    dayFullName: "Wednesday",
    timeSchedules: [templateSchedule],
  },
  {
    id: 5,
    dayName: "THU",
    dayFullName: "Thursday",
    timeSchedules: [templateSchedule],
  },
  {
    id: 6,
    dayName: "FRI",
    dayFullName: "Friday",
    timeSchedules: [templateSchedule],
  },
  {
    id: 7,
    dayName: "SAT",
    dayFullName: "Saturday",
    timeSchedules: [templateSchedule],
  },
];

export const cleanUpTimeOptions = [0, 5, 10, 15, 20, 25, 30];
