import React from "react";
import { Labeled } from "../../shared/Labeled/Labeled";
import { Select } from "../../../../../../shared/Select/Select";
import { dedupSelectOptions, shapeToString } from "./CategorySelect.utils";

export function CategorySelect({
  composeLabel,
  name,
  value,
  options,
  onChange,
}) {
  const valuesMap = options.reduce(
    (carry, option) => ({
      ...carry,
      [shapeToString(option)]: option,
    }),
    {},
  );

  return (
    <Labeled label={name}>
      <Select
        size="small"
        value={{
          label: composeLabel(value),
          value: shapeToString(value),
        }}
        options={dedupSelectOptions(
          options.map((option) => ({
            label: composeLabel(option),
            value: shapeToString(option),
          })),
        )}
        onChange={(next) => {
          onChange(valuesMap[next.value]);
        }}
      />
    </Labeled>
  );
}
