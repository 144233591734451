import React from "react";
import { PageHeader } from "../../../../../boxes/PageHeader/PageHeader";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { Box } from "../../../../../shared/Box/Box";
import classes from "./Header.module.scss";
import { Select } from "../../../../../shared/Select/Select";
import { history } from "../../../../../history";
import { SETTINGS_ROUTES } from "../../../../../consts/routes";

export function Header({ filter, clinicOptions }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();

  return (
    <Box>
      <PageHeader
        noBorderBottom
        noPadding
        withBackBtn
        title={tSettings("salesGoals.title")}
        rightAdornment={
          <div className={classes.right}>
            <div className={classes.clinic}>
              <span>{tCommon("label.clinic")}:</span>
              <Select
                isSearchable
                size="small"
                options={clinicOptions}
                value={filter.value.clinic}
                onChange={(clinic) => filter.update({ clinic })}
                className={classes.select}
              />
            </div>
            <Button
              size="small"
              onClick={() => history.push(SETTINGS_ROUTES.addSalesGoal())}
            >
              {tSettings("salesGoals.add")}
            </Button>
          </div>
        }
      />
    </Box>
  );
}
