import React from "react";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { InputPhone as Input } from "../../../../shared/InputPhone/InputPhone";
import { InputError } from "../../../../shared/InputError/InputError";

export function InputPhone({ value, onChange, error }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div>
      <InputLabel>
        {tCommon("createQualifyProcedure.formLabel.phone")}
      </InputLabel>
      <Input fullWidth value={value} onChange={onChange} isError={!!error} />
      {!!error && <InputError>{error}</InputError>}
    </div>
  );
}
