import React from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { useCurrentUserQuery } from "../../../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../../../consts/general";
import { Skeleton } from "../../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../../shared/Empty/Empty";
import { Table as SharedTable } from "../../../../../../../../shared/Table/Table";
import { ContentLoader } from "../../../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import styles from "./styles.module.scss";
import { Box } from "../../../../../../../../shared/Box/Box";
import { phoneUtil } from "../../../../../../../../utilities/phone";
import { capitalize } from "../../../../../../../../utilities/general";

/**
 * @param {object} param0
 *
 * @param {{
 *  value: Array<{ [key:string]: string | number }>;
 *  isLoading: boolean;
 *  isSoftLoading: boolean;
 *  hasMore: boolean;
 *  fetchMore: () => void;
 *  isNextFetching: boolean;
 * }} param0.data
 */
export const Table = ({ data }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const tableData = data.value.map((i) => ({
    key: i.id,

    phone: phoneUtil.formatWithDashes(i.phone_number),

    patient: i.patient ? i.patient.full_name : tCommon("symbol.longDash"),

    date: moment(i.updated_at).format(user?.previewDateFormat || PREVIEW_DATE_FORMAT),

    type: i.direction ? capitalize(i.direction) : tCommon("symbol.longDash"),

    segments: i.segments,

    status: capitalize(i.status),
  }));

  return (
    <Box className={styles.root}>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={tableData}
            cols={[
              {
                data: tSettings("smsUsageLog.table.phone"),
                accessor: "phone",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("smsUsageLog.table.patient"),
                accessor: "patient",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("smsUsageLog.table.date"),
                accessor: "date",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("smsUsageLog.table.type"),
                accessor: "type",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("smsUsageLog.table.segments"),
                accessor: "segments",
                headColClassName: styles.colMin,
              },
              {
                data: tSettings("smsUsageLog.table.status"),
                accessor: "status",
                headColClassName: styles.colMin,
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
};
