export const SETTING_VIEW_NAMES = {
  height: "height",
  weight: "weight",
  bmi: "bmi",
  bodyFat: "bodyFat",
  pulse: "pulse",
  respiration: "respiration",
  bloodPressure: "bloodPressure",
  temperature: "temperature",
};

export const GRAPH_FIELDS = {
  weight: "weight",
  bmi: "bmi",
  bodyFat: "body_fat",
  pulse: "pulse",
  respiration: "respiration",
  bloodPressureSystolic: "blood_pressure_systolic",
  bloodPressureDiastolic: "blood_pressure_diastolic",
  temperature: "temperature",
};

export const SETTING_BMI_NULL = 2000;
export const SETTING_BMI_RANGE_MAX = SETTING_BMI_NULL - 1;
