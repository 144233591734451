import React from "react";
import { Summary } from "../../widgets/Summary/Summary";
import { ClientSignature } from "../../widgets/ClientSignature/ClientSignature";
import { StageGrid } from "../../shared/StageGrid/StageGrid";
import { Success } from "../../widgets/Success/Success";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { PAYMENT_MODES } from "../../../../../consts/api";
import { SendReceipts } from "../../widgets/SendReceipts/SendReceipts";

export function StageSuccess() {
  const { invoice } = useInvoice();

  const needSignature = Boolean(
    invoice?.paymentTransaction?.payments?.find(
      (p) => p.mode === PAYMENT_MODES.cc,
    ),
  );

  const needClientSignature = needSignature && !invoice?.paymentSignature;

  return (
    <StageGrid>
      {needClientSignature ? <ClientSignature /> : <SendReceipts />}
      <Summary />
      <Success />
    </StageGrid>
  );
}
