import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useQualiphyCancelAllClinicsEnrollMutation } from "../../../../../api/qualiphy/useQualiphyCancelAllClinicsEnrollMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useQualiphyEnrolledClinicsQuery } from "../../../../../api/qualiphy/useQualiphyEnrolledClinicsQuery";
import { QUERY_KEYS } from "../../../../../consts/api";

export function useIsEnabled() {
  const queryClient = useQueryClient();
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const [isEnabled, setIsEnabled] = useState(false);

  const enrolledClinicsQuery = useQualiphyEnrolledClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.qualiphy.getEnrolledClinics"));
    },
  });

  const cancelAllEnrolledClinicsMutation =
    useQualiphyCancelAllClinicsEnrollMutation({
      onSuccess: () => {
        enrolledClinicsQuery.refetch();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.qualiphy.clinicsToEnroll],
        });
      },
      onError: () => {
        uiNotification.error(
          tSettings("qualiphyIntegration.error.disableIntegration"),
        );
        setIsEnabled(true);
      },
    });

  const toggleEnabled = () => {
    if (isEnabled) {
      if (enrolledClinicsQuery?.data?.length > 0) {
        cancelAllEnrolledClinicsMutation.mutate();
      }
      setIsEnabled(false);
    } else {
      setIsEnabled(true);
    }
  };

  useEffect(() => {
    setIsEnabled(enrolledClinicsQuery?.data?.length > 0);
  }, [enrolledClinicsQuery?.data?.length]);

  return {
    isEnabled,
    toggleEnabled,
    isLoading:
      cancelAllEnrolledClinicsMutation.isLoading ||
      enrolledClinicsQuery.isLoading,
  };
}
