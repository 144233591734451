import { useEffect, useState } from "react";

export function useStripe() {
  const [stripeInstance, setStripeInstance] = useState(null);

  useEffect(() => {
    let loadListener = null;
    const stripeDomNode = document.querySelector("#stripe-js");
    if (!stripeInstance) {
      if (window.Stripe) {
        setStripeInstance(
          window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
        );
      } else if (stripeDomNode) {
        loadListener = () => {
          setStripeInstance(
            window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
          );
        };
        stripeDomNode.addEventListener("load", loadListener);
      }
    }
    return () => {
      if (loadListener && stripeDomNode) {
        stripeDomNode.removeEventListener("load", loadListener);
      }
    };
  }, [window.Stripe, stripeInstance]);

  return stripeInstance;
}
