import { useMemo, useState } from "react";
import moment from "moment";
import { API_DATE_FORMAT, ORDER_TYPES } from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useQueryParamsState } from "../../../../utilities/hooks/useQueryParamsState";
import { useDebouncedQueryParamState } from "../../../../helpers/hooks/useDebouncedQueryParamState";
import { useEfaxesQuery } from "../../../../api/efax/useEfaxesQuery";

const prepareReqOrder = (state) => {
  if (state.sortBy && state.sortOrder) {
    return {
      sortBy: state.sortBy,
      sortOrder: state.sortOrder,
    };
  }

  return {};
};

export const useEfaxes = () => {
  const { tInbox } = useAppTranslation.Inbox();

  // State

  const [search, debouncedSearch, setSearch] = useDebouncedQueryParamState(
    "search",
    "",
  );

  const [state, setState] = useQueryParamsState({
    sortBy: "",
    sortOrder: "",

    from: moment().startOf("month").format(API_DATE_FORMAT),
    to: moment().endOf("month").format(API_DATE_FORMAT),
  });

  const [filters, setFilters] = useState({
    direction: "",
    isPatientAssigned: "",
  });

  const updateOrder = (orderFieldKey) => {
    setState((state) => ({
      sortBy: orderFieldKey,
      sortOrder:
        state.sortOrder === ORDER_TYPES.desc
          ? ORDER_TYPES.asc
          : ORDER_TYPES.desc,
    }));
  };

  const updatePeriod = ({ from, to }) => {
    setState({
      from: from,
      to: to,
    });
  };

  // Data

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useEfaxesQuery({
    payload: {
      pageSize: 15,
      patientName: debouncedSearch,
      fromDate: state.from,
      toDate: state.to,
      direction: filters.direction.value,
      isPatientAssigned: filters.isPatientAssigned.value,
      ...prepareReqOrder(state),
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tInbox("efaxOrders.error.fetchFaxes"));
      },
    },
  });

  const eFaxes = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.eFaxes).reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  // Statuses

  const isOrderActive = [state.sortBy, state.sortOrder].some(Boolean);
  const isPeriodActive = [state.from, state.to].some(Boolean);

  const isSoftLoading =
    (isOrderActive || isPeriodActive) &&
    isFetching &&
    !isLoading &&
    !isFetchingNextPage;

  // ===========

  return {
    eFaxes: {
      value: eFaxes,
      isLoading,
      isSoftLoading,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      fetchMore: fetchNextPage,
    },
    order: {
      value: {
        by: state.sortBy,
        direction: state.sortOrder,
      },
      update: updateOrder,
    },
    period: {
      value: {
        from: state.from,
        to: state.to,
      },
      update: updatePeriod,
    },
    search: {
      value: search,
      update: setSearch,
    },
    filters: {
      value: {
        direction: filters.direction,
        isPatientAssigned: filters.isPatientAssigned,
      },
      update: setFilters,
    },
  };
};
