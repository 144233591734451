import { isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { useAppointmentDatesQuery } from "../../../api/queries/useAppointmentDatesQuery";
import { APPT_API_TIME_FORMAT } from "../Event.consts";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { DEFAULT_TIME_FORMAT } from "../../../consts/general";

const availableDaysSchema = yup.lazy((value) => {
  if (isEmpty(value)) {
    return yup.object();
  } else {
    return yup.object(
      Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: yup.array().of(yup.string()),
        }),
        {},
      ),
    );
  }
});

const providerHoursByDatesSchema = yup.lazy((value) => {
  if (isEmpty(value)) {
    return yup.object();
  } else {
    return yup.object(
      Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: yup.array().of(
            yup.object().shape({
              start: yup.string(),
              end: yup.string(),
            }),
          ),
        }),
        {},
      ),
    );
  }
});

export function useApiAvailableDays({
  type,
  clinicId,
  services,
  isDoubleBooking,
  isOutsideScheduledHours,
  providerId,
  calendarStartDate,
  editEventId,
}) {
  const { data: user, isSuccess: isUserFetched } = useCurrentUserQuery();

  const [availableDaysCache, setAvailableDaysCache] = useState({});
  const [providerHoursCache, setProviderHoursCache] = useState({});

  const { isFetching } = useAppointmentDatesQuery(
    {
      appointmentType: type,
      bookWithFirst: false,
      clinicId,
      customServicesDuration: services.reduce(
        (duration, service) => (duration += service.durationInMin),
        0,
      ),
      doubleBooking: isDoubleBooking,
      outsideScheduledHours: isOutsideScheduledHours,
      providerIds: [providerId],
      services: services.map((service) => service.id),
      startDate: calendarStartDate,
      subdomain: user?.account?.patientPortalSubdomain,
      appointmentId: editEventId,
    },
    {
      staleTime: 0,
      enabled: services.length > 0 && isUserFetched,
      onSuccess: ({ data }) => {
        if (data.available_days) {
          setAvailableDaysCache(data.available_days[providerId] || {});
        }
        if (data.provider_hours) {
          setProviderHoursCache(data.provider_hours);
        }
      },
    },
  );

  const availableHoursByDates = useMemo(
    () =>
      availableDaysSchema.validateSync(availableDaysCache, {
        strict: true,
      }),
    [availableDaysCache],
  );

  const providerHoursByDates = useMemo(
    () =>
      providerHoursByDatesSchema.validateSync(providerHoursCache, {
        strict: true,
      }),
    [providerHoursCache],
  );

  const prepareTimeSlots = useCallback(
    (timeSlots) => {
      const timeFormat = user?.timeFormat || DEFAULT_TIME_FORMAT;

      return timeSlots.map((t) =>
        moment(t, APPT_API_TIME_FORMAT).format(timeFormat),
      );
    },
    [user?.timeFormat],
  );

  const getAvailableHoursInsideScheduleByDate = useCallback(
    (date) => {
      let result = [];
      if (typeof date === "string") {
        const hours = availableHoursByDates[date] || [];
        const providerHours = providerHoursByDates[date] || [];

        if (hours.length > 0 && providerHours.length > 0) {
          result = hours.filter((hour) =>
            moment(hour, APPT_API_TIME_FORMAT).isBetween(
              moment(providerHours[0].start, APPT_API_TIME_FORMAT),
              moment(
                providerHours[providerHours.length - 1].end,
                APPT_API_TIME_FORMAT,
              ),
              undefined,
              "[]",
            ),
          );
        }
      }

      return prepareTimeSlots(result);
    },
    [availableHoursByDates, providerHoursByDates, prepareTimeSlots],
  );

  const getAvailableHoursByDate = useCallback(
    (date) => {
      let result = [];

      if (typeof date === "string") {
        const times = availableHoursByDates[date] || [];
        result = prepareTimeSlots(times);
      }

      return result;
    },
    [availableHoursByDates, prepareTimeSlots],
  );

  const availableDays = useMemo(
    () =>
      Object.keys(availableHoursByDates)
        .filter(
          (k) =>
            Array.isArray(availableHoursByDates[k]) &&
            availableHoursByDates[k].length > 0,
        )
        .sort((a, b) => moment(a) - moment(b)),
    [availableHoursByDates],
  );

  return {
    availableDays,
    isAvailableDaysFetching: isFetching,
    getAvailableHoursByDate,
    getAvailableHoursInsideScheduleByDate,
  };
}
