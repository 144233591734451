import React from "react";
import { WIDGET_NAMES } from "../Dashboard.consts";
import { WidgetBox } from "../boxes/WidgetBox/WidgetBox.js";
import classes from "../sass/WidgetNetSalesByProvider.module.scss";
import { useShopifyQuery } from "../../../api/queries/useShopifyQuery";
import { showFormattedDate } from "../../../Utils";

export function WidgetShopify() {
  const { data: reportQueryData, isLoading: isReportQueryDataLoading } =
    useShopifyQuery({});

  return (
    <WidgetBox
      label={WIDGET_NAMES.shopify_orders}
      isLoading={isReportQueryDataLoading}
      isEmpty={reportQueryData?.orders?.length === 0}
      contentClassName={classes.content}
    >
      <div className="table-responsive dashboardSectionContent">
        <table className="records-table">
          <thead>
            <tr>
              <th className="col-xs-6 no-border">Order</th>
              <th className="text-left col-xs-6 no-border">Status</th>
              <th className="text-left col-xs-6 no-border">Total</th>
              <th className="text-left col-xs-6 no-border">Date</th>
            </tr>
          </thead>
          <tbody>
            {reportQueryData?.orders &&
              reportQueryData?.orders.map((obj) => {
                return (
                  <tr key={obj.id}>
                    <td className="col-xs-8">{obj.name}</td>
                    <td className="col-xs-8">{obj.displayFinancialStatus}</td>
                    <td className="col-xs-8">{obj.totalPrice}</td>
                    <td className="col-xs-8">
                      {showFormattedDate(obj.createdAt)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </WidgetBox>
  );
}
