import React from "react";
import { useAppTranslation } from "../../../../../../../../../../i18n/useAppTranslation";
import { Row } from "../../../../shared/Row";
import styles from "./styles.module.scss";
import { Question } from "./shared/Question";

export function Multi({ questions }) {
  const { tDashboard } = useAppTranslation.Dashbaord();

  return (
    <div>
      <Row label={tDashboard("user_logs_diff.questionnaires.questions")}>
        <div className={styles.questions}>
          {questions.map((q) => (
            <Question key={q.id} data={q} />
          ))}
        </div>
      </Row>
    </div>
  );
}
