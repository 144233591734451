import { useQueryClient } from "@tanstack/react-query";
import { useProviderRoomUnhideProceduresMutation } from "../../../api/mutations/useProviderRoomUnhideProceduresMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { QUERY_KEYS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";

export function useProceduresShow() {
  const queryClient = useQueryClient();
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useProviderRoomUnhideProceduresMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.providerRoomProcedures],
        refetchType: "active",
      });
      uiNotification.success(tCommon("providerRoom.success.show"));
    },
    onError: () => {
      uiNotification.error(tCommon("providerRoom.error.show"));
    },
  });

  return {
    showProcedures: mutateAsync,
    isShowProceduresLoading: isLoading,
  };
}
