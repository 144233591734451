export const Routes = {
  APPOINTMENT: {
    title: "Appointments",
    url: "/appointment/index",
  },
  WAITLIST: {
    title: "Waitlist",
    url: "/waitlist",
  },
};
