import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  procedureId: yup.number().required(),
  isAfterPhotos: yup.bool().required(),
});

export function useProviderRoomProcedureAfterPhotosStatusMutation(
  options = {},
) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.patch(
      HTTP_ENDPOINTS.patchProviderRoomProcedureAfterPhotosStatus(
        req.procedureId,
      ),
      {
        patient_ids: req.patientIds,
        is_marked: req.isAfterPhotos ? 1 : 0,
      },
    );
  }, options);
}
