import React from "react";
import cx from "clsx";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { MonthRangeSwitch } from "../../../../../../shared/MonthRangeSwitch/MonthRangeSwitch";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { EntityHeader } from "../../../../../../widgets/EntityHeader/EntityHeader";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { DownloadIcon } from "../../../../../../assets/Icons/DownloadIcon";
import { Maybe } from "../../../../../../utilities/fp";
import {
  apiDateStringToDate,
  dateToApiDateString,
} from "../../../../../../utilities/api";
import { DEFAULT_DATE_FORMAT } from "../../../../../../consts/general";
import styles from "./styles.module.scss";
import { ChevronLeftIcon } from "../../../../../../assets/Icons/ChevronLeftIcon";

/**
 * @param {object} param0
 *
 * @param {string} param0.title
 *
 * @param {{
 *    value: { from: string; to: string; };
 *    update: (value: { from: string; to: string; }) => void;
 *    isNextDisabled: boolean;
 * }?} param0.period
 *
 * @param {boolean} param0.isLoading
 *
 * @param {{
 *    isLoading: boolean;
 *    initiate: () => void;
 * }?} param0.download
 *
 * @param {{
 *    toggle: () => void;
 *    active: boolean;
 * }?} param0.collapse
 */
export function Header({ title, period, isLoading, download, collapse }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const startDate = Maybe.of(period.value.from)
    .map(apiDateStringToDate)
    .orElse(() => new Date())
    .value();

  const endDate = Maybe.of(period.value.to)
    .map(apiDateStringToDate)
    .orElse(() => new Date())
    .value();

  return (
    <EntityHeader
      title={
        <div className={styles.title}>
          {title}
          {collapse && (
            <Button
              size="small"
              variant="text"
              color="gray"
              className={styles.collapseButton}
              onClick={collapse.toggle}
            >
              <ChevronLeftIcon
                className={cx(styles.collapseArrow, {
                  [styles.collapseArrowActive]: collapse.active,
                })}
              />
            </Button>
          )}
        </div>
      }
      right={
        <div className={styles.right}>
          {period && (
            <MonthRangeSwitch
              isDisabled={isLoading}
              isNextDisabled={period.isNextDisabled}
              startDate={startDate}
              endDate={endDate}
              dateFormat={user?.previewDateFormat || DEFAULT_DATE_FORMAT}
              onChange={({ startDate, endDate }) => {
                period.update({
                  from: dateToApiDateString(startDate),
                  to: dateToApiDateString(endDate),
                });
              }}
            />
          )}
          {download && (
            <Button
              size="small"
              variant="outlined"
              onClick={download.initiate}
              isDisabled={download.isLoading}
              leftAdornment={
                download.isLoading ? (
                  <CircularProgress size="small" />
                ) : (
                  <DownloadIcon width="16px" />
                )
              }
            >
              {tCommon("label.downloadExcel")}
            </Button>
          )}
        </div>
      }
    />
  );
}
