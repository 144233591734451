import * as yup from "yup";
import { useFormik } from "formik";
import { tCommon } from "../../../../i18n/useAppTranslation";

const schema = yup.object({
  isSaveSignature: yup.bool().required(),
  signature: yup
    .string()
    .required(tCommon("providerRoom.signModal.error.requiredSignature")),
});

const withMdSchema = schema.concat(
  yup.object({
    mdId: yup
      .number()
      .typeError(tCommon("providerRoom.signModal.error.requiredMd"))
      .required(tCommon("providerRoom.signModal.error.requiredMd")),
  }),
);

export function useFormModalSign({
  defaultMdId,
  onSubmit,
  savedSignature,
  isSendToMd,
}) {
  const initialValues = {
    isSaveSignature: false,
    signature: savedSignature || "",
    mdId: isSendToMd ? defaultMdId : null,
  };

  const { values, touched, errors, handleSubmit, setFieldValue } = useFormik({
    validationSchema: isSendToMd ? withMdSchema : schema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit,
    initialValues,
  });

  const hasError = (field) => {
    return Boolean(touched[field] && errors[field]);
  };

  return {
    form: values,
    errors,
    submit: handleSubmit,
    hasError,
    setFormValue: setFieldValue,
  };
}
