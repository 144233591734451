/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import Tooltip from "react-tooltip";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SalesStatBoxes from "./SalesStatBoxes.js";
import SalesActions from "./SalesActions.js";
import picClose from "../../../_legacy/images/popupClose.png";
import { getSalesReportDetails } from "../../../Actions/BusinessInsights/businessInsightActions.js";
import { AlertBox } from "../../../shared/AlertBox/AlertBox.js";
import { MEMBERSHIP_REPORT_SLUGS } from "./Sales.consts.js";

const INFO_TITLE_BY_REPORT_SLUG = {
  [MEMBERSHIP_REPORT_SLUGS.dailySales]: "Sales Summary - Calculation Help",
  [MEMBERSHIP_REPORT_SLUGS.itemSales]:
    "Product Sales Report  - Calculation Help",
  [MEMBERSHIP_REPORT_SLUGS.grossSales]: "Total Sales Report - Calculation Help",
  [MEMBERSHIP_REPORT_SLUGS.netSales]: "Net Sales - Calculation Help",
  [MEMBERSHIP_REPORT_SLUGS.constToCompany]: "Cost to Company",
  _fallback: "Commissions - Calculation Help",
};

class SalesTitle extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      reportSlug: props.reportSlug,
      reportDetails: this.getDetailsBySlug(props.reportSlug),
      reportData: props.reportData ? props.reportData : [],
      showInfo: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportSlug !== this.props.reportSlug) {
      this.setState({
        reportDetails: this.getDetailsBySlug(this.props.reportSlug),
      });
    }
  }

  getDetailsBySlug = (slug) => {
    const languageData =
      JSON.parse(localStorage.getItem("languageData"))?.business_insights || {};

    let returnData = {};
    returnData.showComissionFilter = false;
    returnData.showMrrFilter = false;

    switch (slug) {
      case MEMBERSHIP_REPORT_SLUGS.tipReport:
        returnData.title = languageData["bi_tip-report"];
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.paymentMethods:
        returnData.title = languageData.bi_payment_methods;
        returnData.canShowTopActions = true;
        returnData.canShowLowerActions = false;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.giftCards:
        returnData.title = languageData.bi_label_egift_card;
        returnData.canShowTopActions = true;
        returnData.canShowLowerActions = false;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.constToCompany:
        returnData.title = languageData.bi_cost_to_company;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = true;
        break;

      case MEMBERSHIP_REPORT_SLUGS.categorySales:
        returnData.title = languageData.bi_category_sales;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.packageSales:
        returnData.title = languageData.bi_package_sales;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.ivPackageSales:
        returnData.title = languageData.bi_iv_package_sales;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.itemSales:
        returnData.title = languageData.bi_item_sales;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = true;
        break;

      case MEMBERSHIP_REPORT_SLUGS.shopifyProducts:
        returnData.title = "Shopify Product Sales";
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = false;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = false;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.shopifySales:
        returnData.title = "Shopify Financials";
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = false;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = false;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.netSales:
        returnData.title = languageData.bi_net_sales;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = true;
        break;

      case MEMBERSHIP_REPORT_SLUGS.grossSales:
        returnData.title = languageData.bi_gross_sales;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = true;
        break;

      case MEMBERSHIP_REPORT_SLUGS.commission:
        returnData.title = languageData.bi_commission_report;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.showComissionFilter = true;
        returnData.canShowinfoIcon = true;
        break;

      case MEMBERSHIP_REPORT_SLUGS.discounts:
        returnData.title = languageData.bi_discounts;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.bdAspire:
        returnData.title = languageData.bi_bd_aspire_report;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.dailySales:
        returnData.title = languageData.bi_daily_sales_report;
        returnData.canShowTopActions = true;
        returnData.canShowLowerActions = false;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = true;
        break;

      case MEMBERSHIP_REPORT_SLUGS.taxes:
        returnData.title = languageData.bi_taxes;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.treatmentPlans:
        returnData.title = languageData.bi_treatment_plans;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.shortTermLiability:
        returnData.title = languageData.bi_short_term_liability;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = false;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.churn:
        returnData.title = languageData.bi_membership_chrun_report;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = true;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.staffTreatments:
        returnData.title = languageData.bi_staff_treatments;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.giftCardsRevenue:
        returnData.title = languageData.bi_egift_cards_revenue;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.cancelationFees:
        returnData.title = languageData.bi_cancelation_fees;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.decline:
        returnData.title = languageData.bi_membership_decline_report;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.refunds:
        returnData.title = languageData.bi_refunds_report;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.retail:
        returnData.title = languageData.bi_retail_report;
        returnData.canShowTopActions = false;
        returnData.canShowLowerActions = true;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = true;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      case MEMBERSHIP_REPORT_SLUGS.redeemedAspire:
        returnData.title = "ASPIRE Redemption Report";
        returnData.canShowTopActions = true;
        returnData.canShowLowerActions = false;
        returnData.canShowStatBoxes = false;
        returnData.canShowLocFilter = false;
        returnData.canShowCalFilter = true;
        returnData.canShowMonthFilter = false;
        returnData.canShowChurnFilter = false;
        returnData.canShowDownloadBtn = true;
        returnData.canShowinfoIcon = false;
        break;

      default:
        break;
    }

    return returnData;
  };

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.reportSlug !== undefined) {
      returnState.reportSlug = nextProps.reportSlug;
    }

    if (nextProps.reportData !== undefined) {
      returnState.reportData = nextProps.reportData;
    }
    return returnState;
  }

  handleChildDownload = (formData) => {
    this.props.handleChildDownload(formData);
  };

  handleChildFilter = (downloadType) => {
    this.props.handleChildFilter(downloadType);
  };

  handleChildState = (childState) => {
    if (this.props.handleChildState) {
      this.props.handleChildState(childState);
    }
  };

  showInfo = () => {
    this.setState({ showInfo: true });
  };

  hideInfo = () => {
    this.setState({ showInfo: false });
  };

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      clinics_Search: languageData.settings["clinics_Search"],
    });
  }

  render() {
    return (
      <div>
        <div className="setting-setion m-b-10"></div>

        <div
          className={
            this.state.showInfo ? "blackOverlay" : "blackOverlay no-display"
          }
        >
          <div className="vert-middle vert-middle-report-option">
            <div
              className={
                (this.state.reportSlug &&
                  this.state.reportSlug === "daily_sales_report") ||
                this.state.reportSlug === "item_sales" ||
                this.state.reportSlug === "net_sales" ||
                (this.state.reportSlug === "commission_report" &&
                  this.state.reportData.commission_type &&
                  this.state.reportData.commission_type === "Net Sales")
                  ? "white-popup extra-large-popup"
                  : "white-popup large-popup"
              }
            >
              <div className="white-popup-wrapper">
                <div className="membershipTypeTitle">
                  {INFO_TITLE_BY_REPORT_SLUG[this.state.reportSlug] ||
                    INFO_TITLE_BY_REPORT_SLUG._fallback}
                  <a onClick={this.hideInfo} className="popupClose">
                    <img src={picClose} alt="" />
                  </a>
                </div>

                <div className="row">
                  {this.state.reportSlug &&
                    this.state.reportSlug === "item_sales" && (
                      <div className="col-sm-12">
                        <p>
                          <b>Definition</b> = The Product Sales Report will
                          include items that are located inside of your
                          inventory section and sold or charted. Keep in mind
                          that package or BOGO sales do not connect to the
                          Product Sales Report. Custom items, Services, Retail,
                          and Cancellation Fees will show on this report.
                        </p>
                        <p>
                          <b>Units Used</b>
                          {` = Refers to the total amount charted
                          on a patient's photo. This does not have anything to
                          do with traceability, solely refers to the treatment
                          markings on the photo. Retail items will always match
                          inventory used, versus sold.`}
                        </p>
                        <p>
                          <b>Units Sold</b> = Refers to the total amount of
                          inventory for a product that is sold. This does not
                          include package sales. Please note- if you use quick
                          pay for an individual item, it will display on the
                          product sales report.
                        </p>
                        <p>
                          <b>Sub-Total</b> = Refers to the line item price of
                          each item listed on an invoice.
                        </p>
                      </div>
                    )}

                  {this.state.reportSlug &&
                    this.state.reportSlug === "daily_sales_report" && (
                      <div className="col-sm-12">
                        <p>
                          <b>Sales from Procedures and Retail</b> = Invoice
                          Subtotals from Procedures &amp; Retail + Tips + Taxes
                        </p>
                        <p>
                          <b>Total Sales</b> = Invoice Subtotals for All
                          Invoices + Cancellation Fees + Retail + Tips + Taxes
                        </p>
                        <p>
                          <b> Net Sales</b> = Total Sales- Tips - Tax - Item
                          Wallet Debits - eGift Card Redemptions - Refunds -
                          Processing fees - Discounts - Wallet Payment
                        </p>
                      </div>
                    )}

                  {this.state.reportSlug &&
                    this.state.reportSlug === "gross_sales" && (
                      <div className="col-sm-12">
                        <p>
                          <b>Clinic Total Sales:</b>
                          <p>
                            <b>Total Sales</b> = Invoice Subtotals for All
                            Invoices + Cancellation Fees + Retail + Tips + Taxes
                          </p>
                        </p>
                        <p>
                          <b>Provider Total Sales:</b>
                          <p>
                            <b>Employee Total Sales</b> = Invoice Subtotals for
                            All Employee Sales + Tax + Cancellation Fees
                          </p>
                        </p>
                      </div>
                    )}

                  {this.state.reportSlug &&
                    this.state.reportSlug === "net_sales" && (
                      <div className="col-sm-12">
                        <p>
                          <b>Clinic Net Sales:</b>
                          <p>
                            <b>Clinic Net Sales</b> = Clinic Total Sales - Tips
                            - Tax - Item Wallet Debits - eGift Card Redemptions
                            - Refunds - Processing fees - Discounts - Wallet
                            Payments
                          </p>
                        </p>
                        <p>
                          <b>Employee Net Sales:</b>
                          <p>
                            <b>Employee Net Sales</b> = Employee Total Sales -
                            Tax - Item Wallet Debits - eGift Card Redemptions -
                            Refunds - Processing fees - Discounts - Wallet
                            Payments
                          </p>
                        </p>
                        <AlertBox fitContent size="small">
                          <b>Note:</b> Tips are not subtracted in Employee Net
                          Sales
                        </AlertBox>
                      </div>
                    )}

                  {this.state.reportSlug &&
                    this.state.reportSlug === "commission_report" && (
                      <div className="col-sm-12">
                        {this.state.reportData.commission_type &&
                          this.state.reportData.commission_type ===
                            "Net Value Services Performed" && (
                            <p>
                              <b>Net Value Services Performed:</b>
                              <p>
                                <b>NVSP</b> = Total Sales From All Sources -
                                Tips - Taxes
                              </p>
                            </p>
                          )}

                        {this.state.reportData.commission_type &&
                          this.state.reportData.commission_type ===
                            "Total Net Profit" && (
                            <p>
                              <b>Net Profit:</b>
                              <p>
                                <b>Net Profit</b> = Total Sales - Tips - Taxes -
                                Cost to company
                              </p>
                            </p>
                          )}

                        {this.state.reportData.commission_type &&
                          this.state.reportData.commission_type ===
                            "Net Sales" && (
                            <p>
                              <b>Net Sales:</b>
                              <p>
                                <b>Net Sales</b> = Total Sales From All Sources
                                - Wallet Debits - Refunds - Tips - Taxes -
                                Processing Fee - Discounts
                              </p>
                            </p>
                          )}

                        {this.state.reportData.commission_type &&
                          this.state.reportData.commission_type ===
                            "Gross Sales" && (
                            <p>
                              <b>Gross Sales:</b>
                              <p>
                                <b>Gross Sales</b> = Total Sales - Discounts -
                                Tips - Tax
                              </p>
                            </p>
                          )}

                        {this.state.reportData.commission_type &&
                          this.state.reportData.commission_type ===
                            "Net Profit" && (
                            <p>
                              <b>Total Procedure Sales:</b>
                              <p>
                                <b>Total Procedure Sales</b> = Total Sales -
                                Discounts - Tips - Taxes - Wallet Credits
                              </p>
                            </p>
                          )}
                      </div>
                    )}
                  {this.state.reportSlug === "cost_to_company" && (
                    <div className="col-sm-12">
                      <p>
                        Cost to company is calculated differently based on the
                        inventory type you are transacting.
                      </p>
                      <p>
                        <b>Retail</b> - calculated at the time of successful
                        payment
                      </p>
                      <p>
                        <b>Medical Supplies</b> - calculated at the time of
                        successful payment
                      </p>
                      <p>
                        <b>Service</b> - calculated when you associate
                        traceability to a procedure. Please note, this report
                        will not display CTC simply by taking a payment for a
                        service item.{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          <div className="setting-setion m-b-10">
            <div className="membership-title">
              {this.props.reportSlug !==
                MEMBERSHIP_REPORT_SLUGS.paymentMethods &&
                this.state.reportDetails.title}
              {this.props.reportSlug ===
                MEMBERSHIP_REPORT_SLUGS.paymentMethods && (
                <div className="d-flex align-center gap-8 float-left">
                  {this.state.reportDetails.title}
                  <div
                    data-tip
                    data-for="manufacturedRewards"
                    className="m-b-2"
                  >
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </div>
                  <Tooltip id="manufacturedRewards" aria-haspopup="true">
                    Manufactured Rewards are not counted in Sales tab export
                  </Tooltip>
                </div>
              )}
              {this.state.reportDetails &&
                this.state.reportDetails.canShowinfoIcon && (
                  <span onClick={this.showInfo} className="infoIcon">
                    {" "}
                    <i className="fa fa-info-circle"></i>
                  </span>
                )}

              {this.state.reportSlug &&
                (this.state.reportData.clinics ||
                  this.state.reportSlug === "shopify-products" ||
                  this.state.reportSlug === "shopify-sales" ||
                  this.state.reportSlug === "commission_report" ||
                  this.state.reportSlug === "taxes" ||
                  this.state.reportSlug === "tip-report" ||
                  this.state.reportSlug === "item_sales" ||
                  this.state.reportSlug === "membership_churn_report" ||
                  this.state.reportSlug === "membership_revenue" ||
                  this.state.reportSlug === "short_term_liability" ||
                  this.state.reportSlug === "mrr_report" ||
                  this.state.reportSlug === "membership_decline_report" ||
                  this.state.reportSlug === "redeemed_aspire_certificates") && (
                  <SalesActions
                    actionDetails={this.state.reportDetails}
                    reportSlug={this.state.reportSlug}
                    handleGrandChildDownload={this.handleChildDownload}
                    handleGrandChildFilter={this.handleChildFilter}
                    clinicList={this.state.reportData.clinics}
                    parentState={this.props.parentState}
                    handleChildState={this.handleChildState}
                  />
                )}

              {this.state.reportSlug === "egift_cards_revenue" && (
                <form
                  onSubmit={this.props.handleSubmit}
                  className="searchForm testttt-search"
                >
                  <span className="searchOuter">
                    <i className="fa fa-search search-icon" />
                    <input
                      className="setting-search-input search-key"
                      name="searchedTerm"
                      placeholder={this.state.clinics_Search}
                      autoComplete="off"
                      value={this.props.parentState.searchedTerm}
                      onChange={this.props.handleInputChange}
                    />
                  </span>
                </form>
              )}
            </div>
          </div>
        }

        {this.state.reportDetails.canShowStatBoxes && (
          <SalesStatBoxes
            reportData={this.state.reportData}
            reportSlug={this.state.reportSlug}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps() {}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getSalesReportDetails: getSalesReportDetails },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SalesTitle));
