import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const dtoSchema = yup.object({
  stripeUserId: yup.string().required(),
});

export function createStatementDescriptorQueryKey(stripeUserId) {
  return [QUERY_KEYS.statementDescriptor, stripeUserId];
}

export function useStatementDescriptorQuery({ payload = {}, options = {} }) {
  const dto = dtoSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createStatementDescriptorQueryKey(dto.stripeUserId),
    () =>
      http.get(HTTP_ENDPOINTS.getStatementDescriptor(), {
        params: {
          stripe_user_id: dto.stripeUserId,
        },
      }),
    options,
  );
}
