import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { Input } from "../../../../../../shared/Input/Input";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { svg } from "../../../../../../assets/svg";

const PaymentFormModal = ({
  isValid,
  submit,
  form,
  errors,
  hasError,
  changeFormValue,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.check")}
      imageSrc={svg.checkPayPrimary}
      onCharge={submit}
      chargeLabel={tCommon("label.continue")}
      isChargeDisabled={!isValid}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.checkNumber")}
        </InputLabel>
        <Input
          size="medium"
          value={form.checkNumber}
          isError={hasError("checkNumber")}
          onChange={(e) => changeFormValue("checkNumber", e.target.value)}
          placeholder={tSales(
            "checkoutInvoice.formInputPlaceholder.checkNumber",
          )}
        />
        {hasError("checkNumber") && (
          <InputError>{errors.checkNumber}</InputError>
        )}
      </ModalCharge.InputWrap>{" "}
    </ModalCharge>
  );
};

export default PaymentFormModal;
