export const PROCEDURE_AREA = {
  butt: "butt",
  chest: "chest",
  coolSculpting: "coolsculpting",
  face: "face",
  laser: "laser",
  thighs: "thighs",
  trunk: "trunk",
  other: "other",
};

export const PROCEDURE_AREA_OPTIONS = [
  {
    label: "Butt",
    value: PROCEDURE_AREA.butt,
  },
  {
    label: "Chest",
    value: PROCEDURE_AREA.chest,
  },
  {
    label: "Coolsculpting",
    value: PROCEDURE_AREA.coolSculpting,
  },
  {
    label: "Face",
    value: PROCEDURE_AREA.face,
  },
  {
    label: "Laser",
    value: PROCEDURE_AREA.laser,
  },
  {
    label: "Thighs",
    value: PROCEDURE_AREA.thighs,
  },
  {
    label: "Trunk",
    value: PROCEDURE_AREA.trunk,
  },
  {
    label: "Other",
    value: PROCEDURE_AREA.other,
  },
];
