import React from "react";
import { Input } from "../../shared/Input/Input";
import { EyeIcon } from "../../assets/Icons/EyeIcon";
import { EyeOffIcon } from "../../assets/Icons/EyeOffIcon";
import styles from "./styles.module.scss";
import { not } from "../../utilities/fp";

/**
 * @param {{
 *    name,
 *    value,
 *    onChange,
 *    onBlur,
 *    onFucus,
 *    placeholder,
 *    leftAdornment,
 *    className,
 *    inputClassName,
 *    leftAdornmentClassName,
 *    isError,
 *    isDisabled,
 *    autoFocus,
 * }} props
 */
export function InputPassword(props) {
  var [isVisible, setIsVisible] = React.useState(false);

  return (
    <Input
      {...props}
      type={isVisible ? "text" : "password"}
      rightAdornment={
        <button className={styles.eyeButton} onClick={() => setIsVisible(not)}>
          {isVisible ? <EyeIcon /> : <EyeOffIcon />}
        </button>
      }
    />
  );
}
