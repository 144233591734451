import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import cx from "clsx";
import classes from "./InputPhone.module.scss";
import { COUNTRY_CODES } from "../../consts/general";

const VARIANTS = {
  legacy: "legacy",
  legacyOutlined: "legacy-outlined",
};

export function InputPhone({
  country,
  value,
  onChange,
  onFocus,
  onBlur,
  onClick,
  containerClassName,
  inputClassName,
  flagButtonClassName,
  dropdownClassName,
  fullWidth,
  isDisabled,
  isError,
  preferredCountries,
  enableAreaCodes,
  withSearch,
  variant,
  inputProps,
  onlyCountries,
}) {
  const [resetValue, setResetValue] = useState("");

  const containerClasses = cx(
    classes.container,
    {
      [classes.fullWidth]: fullWidth,
    },
    containerClassName,
  );

  const inputClasses = cx(
    classes.input,
    {
      [classes.disabled]: isDisabled,
      [classes.error]: isError,
      [classes.legacy]: variant === VARIANTS.legacy,
      [classes.legacyOutlined]: variant === VARIANTS.legacyOutlined,
    },
    inputClassName,
  );

  const flagButtonClasses = cx(
    classes.flagButton,
    {
      [classes.legacy]: variant === VARIANTS.legacy,
      [classes.legacyOutlined]: variant === VARIANTS.legacyOutlined,
    },
    flagButtonClassName,
  );

  const dropdownClasses = cx(classes.dropdown, dropdownClassName);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setResetValue("1");
    }, 1);
    const timeout2 = setTimeout(() => {
      setResetValue("");
    }, 2);
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <PhoneInput
      country={country || COUNTRY_CODES.us}
      value={value || resetValue}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      specialLabel={""}
      containerClass={containerClasses}
      inputClass={inputClasses}
      buttonClass={flagButtonClasses}
      dropdownClass={dropdownClasses}
      disabled={isDisabled}
      disableDropdown={isDisabled}
      preferredCountries={preferredCountries}
      enableAreaCodes={enableAreaCodes}
      enableSearch={withSearch}
      inputProps={inputProps}
      onlyCountries={onlyCountries}
    />
  );
}

InputPhone.propTypes = {
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  flagButtonClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  enableAreaCodes: PropTypes.bool,
  withSearch: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

InputPhone.defaultProps = {
  enableAreaCodes: true,
};
