import React from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../consts/i18n";
import { i18n } from "./setup";
import { useI18nAccountSettings } from "./api/useI18nAccountSettings";
import { clientAliasEnhancer } from "./enhancers/clientAliasEnhancer";
import { isObjectInTranslation } from "./utilities";
import { capitalize } from "../utilities/general";
import { tFnNames } from "./config";

function getUseTranslation(ns, tFnName) {
  return () => {
    var { t, i18n, ...rest } = useTranslation(ns);
    var { clientGlobalName: clientAlias } = useI18nAccountSettings();

    var tFn = (path, ...args) => {
      const tRes = t(path, ...args);

      if (isObjectInTranslation(tRes, path)) {
        return t(
          clientAliasEnhancer.updatePath({
            path,
            i18n,
            ns,
            alias: clientAlias,
          }),
          ...args,
        );
      }

      return tRes;
    };

    return {
      ...rest,
      [tFnName]: tFn,
    };
  };
}

export const useAppTranslation = {
  Common: getUseTranslation(I18N_NAMESPACES.common, tFnNames.Common),

  Auth: getUseTranslation(I18N_NAMESPACES.auth, tFnNames.Auth),

  Settings: getUseTranslation(I18N_NAMESPACES.settings, tFnNames.Settings),

  BusinessInsights: getUseTranslation(
    I18N_NAMESPACES.businessInsights,
    tFnNames.BusinessInsights,
  ),

  Sales: getUseTranslation(I18N_NAMESPACES.sales, tFnNames.Sales),

  Inbox: getUseTranslation(I18N_NAMESPACES.inbox, tFnNames.Inbox),

  Clients: getUseTranslation(I18N_NAMESPACES.clients, tFnNames.Clients),

  Appointments: getUseTranslation(
    I18N_NAMESPACES.appointments,
    tFnNames.Appointments,
  ),

  Inventory: getUseTranslation(I18N_NAMESPACES.inventory, tFnNames.Inventory),

  Dashbaord: getUseTranslation(I18N_NAMESPACES.dashboard, tFnNames.Dashboard),

  SmartConfig: getUseTranslation(
    I18N_NAMESPACES.smartConfig,
    tFnNames.SmartConfig,
  ),
};

export const withAppTranslation = (ns) => (Component) => (props) => {
  var translation = useAppTranslation[capitalize(ns)]();
  return <Component {...props} {...translation} />;
};

function getTranslationComponent(ns, tFnName) {
  return ({ path, options }) => {
    var translation = useAppTranslation[capitalize(ns)]();
    return (
      <React.Fragment>{translation[tFnName](path, options)}</React.Fragment>
    );
  };
}

export const AppTranslation = {
  Common: getTranslationComponent(I18N_NAMESPACES.common, tFnNames.Common),

  Auth: getTranslationComponent(I18N_NAMESPACES.auth, tFnNames.Auth),

  Settings: getTranslationComponent(
    I18N_NAMESPACES.settings,
    tFnNames.Settings,
  ),

  BusinessInsights: getTranslationComponent(
    I18N_NAMESPACES.businessInsights,
    tFnNames.BusinessInsights,
  ),

  Sales: getTranslationComponent(I18N_NAMESPACES.sales, tFnNames.Sales),

  Inbox: getTranslationComponent(I18N_NAMESPACES.inbox, tFnNames.Inbox),

  Clients: getTranslationComponent(I18N_NAMESPACES.clients, tFnNames.Clients),

  Appointments: getTranslationComponent(
    I18N_NAMESPACES.appointments,
    tFnNames.Appointments,
  ),

  Inventory: getTranslationComponent(
    I18N_NAMESPACES.inventory,
    tFnNames.Inventory,
  ),

  Dashboard: getTranslationComponent(
    I18N_NAMESPACES.dashboard,
    tFnNames.Dashboard,
  ),

  SmartConfig: getTranslationComponent(
    I18N_NAMESPACES.smartConfig,
    tFnNames.SmartConfig,
  ),
};

const tBase =
  (ns) =>
  (...args) => {
    const [key, ...rest] = args;
    return i18n.t(`${ns}:${key}`, ...rest);
  };

export const tCommon = tBase(I18N_NAMESPACES.common);
export const tAuth = tBase(I18N_NAMESPACES.auth);
export const tBi = tBase(I18N_NAMESPACES.businessInsights);
export const tSales = tBase(I18N_NAMESPACES.sales);
export const tClients = tBase(I18N_NAMESPACES.clients);
export const tSettings = tBase(I18N_NAMESPACES.settings);
export const tInventory = tBase(I18N_NAMESPACES.inventory);
export const tAppointments = tBase(I18N_NAMESPACES.appointments);
