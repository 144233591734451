import React, { useMemo } from "react";
import classes from "../../Clients.module.scss";
import { Table } from "../../../../shared/Table/Table";
import { useRepeatMdWallet } from "../../hooks/useRepeatMdWallet";
import { withRouter } from "react-router";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

const RepeatMdWallet = ({ match }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();

  const patientId = match.params.clientID;
  const { data: mdWallet } = useRepeatMdWallet(patientId);

  const tableData = useMemo(
    () =>
      mdWallet?.items?.map((item) => ({
        item: item.name,
        clinic: item.clinicName,
        balanceUnits: item.available,
      })),
    [mdWallet],
  );

  return (
    <>
      <div className="juvly-container">
        <h1 className={classes.repeatMdTitle}>{tClients("repeatMd.title")}</h1>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          bgColor="white"
          keyExtractor={(row) => row?.id}
          colClassName={classes.clientWalletTableRow}
          headColClassName={classes.clientWalletTableHead}
          bodyClassName={classes.clientWalletTableBody}
          data={tableData}
          cols={[
            {
              data: tClients("repeatMd.table.item"),
              accessor: "item",
            },
            {
              data: tClients("repeatMd.table.clinic"),
              accessor: "clinic",
            },
            {
              data: tClients("repeatMd.table.balanceUnits"),
              accessor: "balanceUnits",
            },
          ]}
        />
        {!tableData?.length && (
          <p className={classes.noRecord}>{tCommon("label.noRecordFound")}</p>
        )}
      </div>
    </>
  );
};

export default withRouter(RepeatMdWallet);
