import React, { useState } from "react";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";
import { useEditStickyNoteQuery } from "../../_legacy/Queries";
import Loader from "../Common/Loader";

const defaultNotesClasses = {
  noteClass: "setting-textarea-box",
};

const NoteEditModal = ({
  noteDetails,
  closeNoteActionModal,
  updateClientNotesList,
  updateNotesList,
}) => {
  const [note, setNote] = useState({ ...defaultNotesClasses, ...noteDetails });

  const { mutate: editNote, isLoading: isUpdatingNote } =
    useEditStickyNoteQuery();

  const handleNoteTitleEdit = (event) => {
    const title = event.target.value;
    setNote((selectedNoteDetails) => ({
      ...selectedNoteDetails,
      noteTitleClass: !title.trim() ? "setting-input-box-invalid" : "",
      title,
    }));
  };

  const handleNoteEdit = (event) => {
    const notes = event.target.value;
    setNote((selectedNoteDetails) => ({
      ...selectedNoteDetails,
      noteClass: !notes.trim()
        ? "setting-textarea-box setting-input-box-invalid"
        : "setting-textarea-box",
      notes,
    }));
  };

  const updateCurrentEditingNote = () => {
    const editedNoteTitle = note.title.trim();
    const editedNoteContent = note.notes.trim();

    if (!editedNoteContent) {
      setNote((noteDetails) => ({
        ...noteDetails,
        noteClass: "setting-textarea-box setting-input-box-invalid",
      }));
    } else if (!editedNoteTitle) {
      setNote((noteDetails) => ({
        ...noteDetails,
        noteTitleClass: "setting-input-box-invalid",
      }));
    } else {
      editNote(
        {
          patient_id: note.patient_id,
          notes: editedNoteContent,
          is_sticky: 1,
          id: note.id,
          title: editedNoteTitle,
        },
        {
          onSuccess: (response) => {
            const { dot_phrases_list, patient_detail, patient_notes } =
              response.data.data;
            if (updateNotesList) {
              updateNotesList({
                customerNotes: patient_notes,
                patientData: patient_detail,
                dotPhrases: dot_phrases_list,
                dotPhrasesArr: dot_phrases_list,
              });
            }
            if (updateClientNotesList) {
              updateClientNotesList((clientNotes) =>
                clientNotes.map((notesDetails) => ({
                  ...notesDetails,
                  ...(notesDetails.id === note.id && {
                    notes: editedNoteContent,
                    title: editedNoteTitle,
                  }),
                })),
              );
            }
            closeNoteActionModal();
          },
        },
      );
    }
  };

  return (
    <ConfirmModal
      className="confirm-modal-center"
      isOpen
      title="Edit Sticky Note"
      onConfirm={updateCurrentEditingNote}
      onCancel={closeNoteActionModal}
      onClose={closeNoteActionModal}
      confirmTitle="Save"
    >
      <>
        <div className="sticky-note-editor">
          <input
            name="title"
            onChange={handleNoteTitleEdit}
            className={`${note.noteTitleClass} stick-note-title`}
            placeholder="Edit title"
            defaultValue={note.title}
          />
          <textarea
            placeholder="Type your notes and hashtags here"
            className={note.noteClass}
            name="noteText"
            onChange={handleNoteEdit}
            defaultValue={note.notes}
          ></textarea>
        </div>
        {isUpdatingNote && <Loader showLoader />}
      </>
    </ConfirmModal>
  );
};

export default NoteEditModal;
