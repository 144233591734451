import React, { Component } from "react";
import drilldown from "highcharts-drilldown";
import Highcharts from "highcharts";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchCProcedureGoals } from "../../../Actions/Dashboard/dashboardActions.js";
import { numberFormat } from "../../../Utils/services.js";
import { WidgetLoader } from "../components/WidgetLoader.js";
import { uiNotification } from "../../../services/UINotificationService.js";

drilldown(Highcharts);

class ProcedureGoals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthData: [],
      monthValueData: [],
    };
  }
  componentDidMount() {
    if (this.props.data.procedure_goals !== undefined) {
      for (let key in this.props.data.procedure_goals.monthly_procedure_array) {
        this.state.monthData.push(key);
        this.state.monthValueData.push(
          this.props.data.procedure_goals.monthly_procedure_array[key],
        );
      }
    }

    Highcharts.chart("procedureGoals", {
      chart: {
        type: "areaspline",
        height: 250,
        width: 550,
        margin: [50, 50, 50, 50],
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },

      xAxis: {
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
        tickLength: 0,
      },
      yAxis: {
        title: {
          text: "",
        },
        plotLines: [
          {
            value: this.props.Monthgoal,
            color: "green",
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: " ",
            },
          },
        ],
      },
      tooltip: {
        shared: true,
        valueSuffix: " " + "Procedures",
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      plotOptions: {
        areaspline: {
          fillOpacity: 0.5,
        },
      },

      series: [
        {
          name: "",
          data: this.state.monthValueData,
          marker: {
            width: 16,
            height: 16,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
  }
  render() {
    return (
      <div>
        <div id="procedureGoals"></div>
      </div>
    );
  }
}

class ProcedureGoalsWeek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekData: [],
      weekValueData: [],
    };
  }
  componentDidMount() {
    if (this.props.data.procedure_goals !== undefined) {
      for (let key in this.props.data.procedure_goals.weekly_procedure_array) {
        this.state.weekData.push(key);
        this.state.weekValueData.push(
          this.props.data.procedure_goals.weekly_procedure_array[key],
        );
      }
    }

    Highcharts.chart("procedureGoalsWeek", {
      chart: {
        type: "areaspline",
        height: 250,
        width: 550,
        margin: [50, 50, 50, 50],
      },
      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },
      xAxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        tickLength: 0,
      },
      yAxis: {
        title: {
          text: "",
        },
        plotLines: [
          {
            value: this.props.Weekgoal,
            color: "green",
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: " ",
            },
          },
        ],
      },
      tooltip: {
        shared: true,
        valueSuffix: " " + " Procedures",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      plotOptions: {
        areaspline: {
          fillOpacity: 0.5,
        },
      },

      series: [
        {
          name: "",
          data: this.state.weekValueData,
          marker: {
            width: 16,
            height: 16,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
  }

  render() {
    return (
      <div>
        <div id="procedureGoalsWeek"></div>
      </div>
    );
  }
}

class WidgetProviderProcedureGoals extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      monthClass: "Month",
      weekClass: "Week no-display",
      buttonClassMonth: "btn btn-default graph-btn select",
      buttonClassWeek: "btn btn-default graph-btn",
      totalGoals: 0,
      monthGoal: 0,
      weekGoal: 0,
      procedureGoalsData: [],
      globalLang: languageData.global,
      showLoader8: false,
    };
  }
  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      dash_total_text: languageData.dashboard["dash_total_text"],
      dash_your_procedure_goal_text:
        languageData.dashboard["dash_your_procedure_goal_text"],
      dash_lbl_month_text: languageData.dashboard["dash_lbl_month_text"],
      dash_lbl_week_text: languageData.dashboard["dash_lbl_week_text"],
    });
    let formData = {
      params: {},
    };
    this.setState({
      showLoader8: true,
    });

    this.props.fetchCProcedureGoals(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.procedureGoalsData != undefined &&
      nextProps.procedureGoalsData.data != prevState.procedureGoalsData.data &&
      nextProps.procedureGoalsData.status == 200
    ) {
      returnState.procedureGoalsData = nextProps.procedureGoalsData.data;
      returnState.showLoader8 = false;
      returnState.totalGoals =
        nextProps.procedureGoalsData.data.procedure_goals.monthly_procedure_goal;
      returnState.monthGoal =
        nextProps.procedureGoalsData.data.procedure_goals.monthly_procedure_goal;
      returnState.weekGoal =
        nextProps.procedureGoalsData.data.procedure_goals.weekly_procedure_goal;
    }
    return returnState;
  }

  handleMonth = () => {
    let dataArr = this.state.procedureGoalsData.procedure_goals;
    if (this.state.procedureGoalsData.procedure_goals) {
      this.setState({
        totalGoals: dataArr.monthly_procedure_goal,
        monthGoal: dataArr.monthly_sales_goal,
      });
    }
    this.setState({
      weekClass: "Week no-display",
      monthClass: "Month display",
      buttonClassMonth: "btn btn-default graph-btn select",
      buttonClassWeek: "btn btn-default graph-btn",
    });
  };

  handleWeek = () => {
    let dataArr = this.state.procedureGoalsData.procedure_goals;
    if (this.state.procedureGoalsData.procedure_goals) {
      this.setState({
        totalGoals: dataArr.weekly_procedure_goal,
        weekGoal: dataArr.weekly_sales_goal,
      });
    }

    this.setState({
      monthClass: "Month no-display",
      weekClass: "Week display",
      buttonClassWeek: "btn btn-default graph-btn select",
      buttonClassMonth: "btn btn-default graph-btn ",
    });
  };

  render() {
    return (
      <div className="setting-setion m-b-15">
        <div className="dashboardSectionTitle m-t-5">
          <b>{"Provider Procedure Goals"}</b>
          <div
            className="btn-group graph-toggle"
            id="pro-group-toggle"
            role="group"
            aria-label="..."
          >
            <button
              type="button"
              className={this.state.buttonClassMonth}
              id="procedure-goal-monthly"
              onClick={this.handleMonth}
              data-identifier="monthy"
            >
              {"Month"}
            </button>
            <button
              type="button"
              className={this.state.buttonClassWeek}
              id="procedure-goal-weekly"
              onClick={this.handleWeek}
              data-identifier="weekly"
            >
              {"Week"}
            </button>
          </div>
          <span className="pull-right">
            {this.state.dash_total_text}{" "}
            <b id="total-pro">{numberFormat(this.state.totalGoals)}</b>
          </span>
        </div>
        <div className={this.state.monthClass}>
          {this.state.procedureGoalsData.procedure_goals && (
            <ProcedureGoals
              data={this.state.procedureGoalsData}
              Monthgoal={this.state.monthGoal}
            />
          )}
        </div>
        <div className={this.state.weekClass}>
          {this.state.procedureGoalsData.procedure_goals && (
            <ProcedureGoalsWeek
              data={this.state.procedureGoalsData}
              Weekgoal={this.state.weekGoal}
            />
          )}
        </div>
        {this.state.showLoader8 && <WidgetLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.DashboardReducer.action === "PROCEDURE_GOALS_DATA") {
    if (state.DashboardReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.DashboardReducer.data.message],
      );
    } else {
      returnState.procedureGoalsData = state.DashboardReducer.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCProcedureGoals: fetchCProcedureGoals,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetProviderProcedureGoals);
