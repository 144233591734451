import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clientId: yup.number().required(),
  settingName: yup.string().required(),
});

export function usePatientNotificationsMutation({
  onError,
  onSuccess,
  onMutate,
  onSettled,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, { string: true });
      return http.put(
        HTTP_ENDPOINTS.patient.sendNotifications({
          clientId: req.clientId,
          settingName: req.settingName,
        }),
      );
    },
    onMutate: () => {
      onMutate?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.client],
      });
      onSuccess?.();
    },
    onError: () => {
      onError?.();
    },
    onSettled: () => {
      onSettled?.();
    },
  });
}
