import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const dtoSchema = yup.object({
  userId: yup.number().required(),
  clockInDate: yup.string().required(),
  clockOutDate: yup.string().required(),
});

export function useManualTimeLogMutation(options = {}) {
  return useMutation((payload) => {
    const dto = dtoSchema.validateSync(payload, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postManualTimeLog(), {
      user_id: dto.userId,
      clock_in: dto.clockInDate,
      clock_out: dto.clockOutDate,
    });
  }, options);
}
