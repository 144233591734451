import React, { useEffect, useMemo, useReducer, useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { FileDropzone } from "../../shared/FileDropzone/FileDropzone";
import {
  ASYNC_FILE_SELECT_DELAY,
  EDITOR_FILES_ACTIONS,
  SUPPORTED_EXTENSIONS,
  SUPPORTED_OUTPUTS,
  TABLET_BREAKPOINT,
} from "./FileParser.consts";
import { Editor } from "./components/Editor/Editor";
import { useParser } from "./hooks/useParser";
import classes from "./FileParser.module.scss";
import { useWindowSize } from "../../utilities/hooks/useWindowSize";
import { editorFilesReducer, dedupFiles } from "./FileParser.utils";
import { delay } from "../../utilities/general";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";

export function FileParser({
  className,
  output,
  onSuccess,
  onError,
  multiple,
  withEditor,
  editor,
  renderFooter,
  footerClassName,
  selectedFiles,
  text,
  dragActiveText,
  selectFileAsync,
  parsingText,
}) {
  const { width: windowWidth } = useWindowSize();

  const { onParse } = useParser({
    multiple,
    output,
  });

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [currentFileId, setCurrentFileId] = useState(null);
  const [pendingFileId, setPendingFileId] = useState(null);
  const [isFilesParsing, setIsFilesParsing] = useState(false);
  const [editorFiles, dispatchEditorFilesAction] = useReducer(
    editorFilesReducer,
    {},
  );

  const editorFilesList = useMemo(
    () => Object.values(editorFiles),
    [editorFiles],
  );

  /* Handlers */

  const handleEditorDrop = (parsedFiles) => {
    if (parsedFiles.length > 0) {
      parsedFiles.forEach((file) => {
        dispatchEditorFilesAction({
          type: EDITOR_FILES_ACTIONS.setFile,
          payload: {
            id: file.id,
            file,
          },
        });
      });
      if (!isEditorOpen) {
        setIsEditorOpen(true);
      }
      if (!currentFileId && windowWidth > TABLET_BREAKPOINT && parsedFiles[0]) {
        setCurrentFileId(parsedFiles[0].id);
      }
      if (currentFileId && windowWidth <= TABLET_BREAKPOINT) {
        setCurrentFileId(null);
      }
    }
  };

  const handleDrop = async (files) => {
    try {
      let nextFiles = files;
      if (withEditor && !editor.allowDuplicates) {
        const { next, duplicates } = dedupFiles(files, editorFilesList);
        if (duplicates.length > 0) {
          onError(`Already selected: ${duplicates.join(", ")}`);
          nextFiles = next;
        }
      }
      setIsFilesParsing(true);
      const parsed = await onParse(nextFiles);
      if (withEditor) {
        handleEditorDrop(parsed);
      } else {
        onSuccess(parsed);
      }
    } catch (e) {
      onError(e.message);
    } finally {
      setIsFilesParsing(false);
    }
  };

  const handleSelectFile = (id) => {
    if (selectFileAsync) {
      setPendingFileId(id);
      delay(ASYNC_FILE_SELECT_DELAY).then(() => {
        setCurrentFileId(id);
        setPendingFileId(null);
      });
    } else {
      setCurrentFileId(id);
    }
  };

  const handleRemoveEditorFile = (id) => {
    const nextEditorFiles = editorFilesList.filter((i) => i.id !== id);
    if (id === currentFileId) {
      handleSelectFile(
        nextEditorFiles.length > 0 ? nextEditorFiles[0].id : null,
      );
    }
    dispatchEditorFilesAction({
      type: EDITOR_FILES_ACTIONS.removeFile,
      payload: {
        id,
      },
    });
    editor.onRemoveFileCb();
  };

  const handleEditEditorFile = (id, data) => {
    dispatchEditorFilesAction({
      type: EDITOR_FILES_ACTIONS.updateFile,
      payload: {
        id,
        data,
      },
    });
  };

  const handleRemoveAllEditorFiles = () => {
    dispatchEditorFilesAction({
      type: EDITOR_FILES_ACTIONS.removeAll,
    });
    setCurrentFileId(null);
    editor.onRemoveAllCb();
  };

  /* Side effects */

  useEffect(() => {
    editor.onFilesChange(editorFilesList);
  }, [editorFilesList]);

  /* --------- */

  const renderProp = (fn) =>
    fn({
      files: editorFilesList,
      currentFile: currentFileId ? editorFiles[currentFileId] : null,
      pendingFileId,
      isFilePending: Boolean(pendingFileId),
      onEditFile: handleEditEditorFile,
      onSetCurrentFile: setCurrentFileId,
      onRemoveFile: handleRemoveEditorFile,
      onRemoveAll: handleRemoveAllEditorFiles,
      isFilesSelected: editorFilesList.length > 0,
      onOpenEditor: () => setIsEditorOpen(true),
      isFilesParsing,
    });

  return (
    <>
      <FileDropzone
        text={isFilesParsing ? parsingText : text}
        dragActiveText={dragActiveText}
        onDrop={handleDrop}
        className={className}
        multiple={multiple}
        supportedExtensions={SUPPORTED_EXTENSIONS}
        onError={onError}
        isDisabled={isFilesParsing}
        onRemoveFile={handleRemoveEditorFile}
        components={{
          loader: isFilesParsing ? (
            <CircularProgress size="tiny" color="gray1" />
          ) : null,
        }}
        selectedFiles={
          withEditor
            ? editorFilesList.map(({ id, raw }) => ({
                id,
                file: raw,
              }))
            : selectedFiles
        }
      />
      {editorFilesList.length > 0 && isEditorOpen && (
        <Editor
          isOpen={isEditorOpen}
          onClose={() => setIsEditorOpen(false)}
          files={editorFilesList}
          renderContent={() => renderProp(editor.renderContent)}
          renderFooter={() => renderProp(editor.renderFooter)}
          onRemoveFile={handleRemoveEditorFile}
          onSelectFile={handleSelectFile}
          selectedFile={currentFileId ? editorFiles[currentFileId] : null}
          onBack={() => setCurrentFileId(null)}
          isFilesParsing={isFilesParsing}
          renderFilesMobileFooter={() =>
            renderProp(editor.renderFilesMobileFooter)
          }
          onRemoveAll={handleRemoveAllEditorFiles}
          dropzone={{
            onDrop: handleDrop,
            onError,
            multiple,
            supportedExtensions: SUPPORTED_EXTENSIONS,
          }}
          pendingFileId={pendingFileId}
          isRemoveAllDisabled={editor.isRemoveAllDisabled}
          isSelectFilesDisabled={editor.isSelectFilesDisabled}
          isFilesDisabled={editor.isFilesDisabled}
        />
      )}
      {renderFooter && (
        <div className={cx(classes.footer, footerClassName)}>
          {renderProp(renderFooter)}
        </div>
      )}
    </>
  );
}

FileParser.propTypes = {
  className: PropTypes.string,
  output: PropTypes.oneOf(Object.values(SUPPORTED_OUTPUTS)),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  multiple: PropTypes.bool,
  withEditor: PropTypes.bool,
  editor: PropTypes.shape({
    allowDuplicates: PropTypes.bool,
    renderFooter: PropTypes.func,
    renderContent: PropTypes.func,
    renderFilesMobileFooter: PropTypes.func,
    onFilesChange: PropTypes.func,
    isRemoveAllDisabled: PropTypes.bool,
    isSelectFilesDisabled: PropTypes.bool,
    isFilesDisabled: PropTypes.bool,
    onRemoveAllCb: PropTypes.func,
    onRemoveFileCb: PropTypes.func,
  }),
  renderFooter: PropTypes.func,
  footerClassName: PropTypes.string,
  renderFilesMobileFooter: PropTypes.func,
  selectedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      file: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  text: PropTypes.string,
  dragActiveText: PropTypes.string,
  selectFileAsync: PropTypes.bool,
  parsingText: PropTypes.string,
};

FileParser.defaultProps = {
  onSuccess: () => {},
  className: undefined,
  output: SUPPORTED_OUTPUTS.text,
  onError: () => {},
  multiple: true,
  withEditor: false,
  editor: {
    allowDuplicates: false,
    renderFooter: () => null,
    renderContent: () => null,
    renderFilesMobileFooter: () => null,
    onFilesChange: () => {},
    isRemoveAllDisabled: false,
    isSelectFilesDisabled: false,
    isFilesDisabled: false,
    onRemoveAllCb: () => {},
    onRemoveFileCb: () => {},
  },
  renderFooter: undefined,
  footerClassName: undefined,
  renderFilesMobileFooter: undefined,
  selectedFiles: [],
  text: undefined,
  dragActiveText: undefined,
  selectFileAsync: false,
  parsingText: "Parsing files...",
};
