import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Appointment from "./containers/Appointment";
import OutOfOffice from "./containers/OutOfOffice";
import { EVENT_TYPES } from "../../store/event/consts";
import { event } from "../../store/event";
import "./sass/base.scss";
import { BreadCrumbs } from "../../Components/Common";
import { APPOINTMENT_ROUTES } from "../../consts/routes";

const breadCrumbs = [
  { name: "Appointments", link: APPOINTMENT_ROUTES.calendar() },
  { name: "Create Event" },
];

const CreateEvent = ({ eventType, changeEventType }) => {
  useEffect(() => {
    changeEventType({ eventType: EVENT_TYPES.appointment });
  }, []);

  return (
    <div id="content">
      <div className="container-fluid content">
        <div className="createEventOuter">
          <BreadCrumbs breadCrumbs={breadCrumbs} />
          <hr className="divider" />
          <div className="juvly-section full-width p2030 p-t-40">
            {eventType === EVENT_TYPES.appointment && <Appointment />}
            {eventType === EVENT_TYPES.outOfOffice && <OutOfOffice />}
          </div>
        </div>
      </div>
    </div>
  );
};

CreateEvent.propTypes = {
  eventType: PropTypes.string,
  changeEventType: PropTypes.func,
};

const mapStateToProps = (state) => ({
  eventType: event.selectors.selectEventType(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeEventType: event.actions.eventTypeChange,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
