import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

export function ModalAgreement({ text, onClose, onAgree }) {
  return (
    <div className="blackOverlay">
      <div className="vert-middle">
        <div
          id="filterModal"
          role="dialog"
          className="model loyaltyMembership large-popup clientMembershipPopup no-padding fade in displayBlock"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close agreementClose"
              data-dismiss="modal"
              onClick={onClose}
            >
              ×
            </button>
            <h4 className="modal-title" id="model_title">
              Membership Agreement
            </h4>
          </div>
          <div
            id="errorwindow"
            className="modal-body add-patient-form filter-patient"
          >
            <Scrollbars
              style={{ height: "563px" }}
              className="juvly-Agreement-content"
            >
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </Scrollbars>
          </div>
          <div className="modal-footer p-t-10 p-b-10">
            <div className="col-md-12 text-left no-padding" id="footer-btn">
              <button
                type="button"
                className="new-blue-btn pull-right"
                data-dismiss="modal"
                onClick={(e) => onAgree(e)}
              >
                I Agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
