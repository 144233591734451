import * as yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import {
  APPT_API_TIME_FORMAT,
  CUSTOM_FREQUENCY_TYPE_SUFFIX,
  FREQUENCY_END_TYPE,
  FREQUENCY_REPEAT_MEASURE_TYPES,
  FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES,
  FREQUENCY_TYPES,
  OUT_OF_OFFICE_EDIT_TYPE,
} from "../Event.consts";
import { uiNotification } from "../../../services/UINotificationService";
import {
  castTo12FormatTime,
  wrapFormDate,
  initFormDate,
  initOutOfOfficeTimeEnd,
  initOutOfOfficeTimeStart,
} from "../Event.utils";

/* Schemas */

const createModeSchema = yup.object({
  clinicId: yup.number().required("Select clinic"),

  date: yup.string().required("Select date"),

  timeStart: yup.string().required("Select start time"),

  timeEnd: yup.string().required("Select end time"),

  eventName: yup.string().required("Select name of event"),

  providers: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "Select at least one provider"),

  frequency: yup.string().required("Select frequency"),

  frequencyRepeatCount: yup.number().when("frequency", {
    is: FREQUENCY_TYPES.custom,
    then: yup
      .number()
      .min(1, "'Repeat every' must be greater or equal to 1")
      .required("Select 'Repeat every' amount"),
  }),

  frequencyRepeatMeasure: yup.string().when("frequency", {
    is: FREQUENCY_TYPES.custom,
    then: yup
      .string()
      .required("Select measurement for repetitions (day, month, etc)"),
  }),

  frequencyRepeatOnWeekDays: yup
    .array()
    .of(yup.string())
    .test({
      name: "frequencyRepeatOnWeekDaysRequired",
      message: "Select days for weeks repetition",
      test: function (value) {
        const frequency = this.parent.frequency;
        const frequencyRepeatMeasure = this.parent.frequencyRepeatMeasure;
        if (
          frequency === FREQUENCY_TYPES.custom &&
          frequencyRepeatMeasure === FREQUENCY_REPEAT_MEASURE_TYPES.week &&
          isEmpty(value)
        ) {
          return false;
        }
        return true;
      },
    }),

  frequencyRepeatMonthlyValue: yup.string().test({
    name: "frequencyRepeatMonthlyValueRequired",
    message: "Select value for monthly repetition",
    test: function (value) {
      const frequency = this.parent.frequency;
      const frequencyRepeatMeasure = this.parent.frequencyRepeatMeasure;
      if (
        frequency === FREQUENCY_TYPES.custom &&
        frequencyRepeatMeasure === FREQUENCY_REPEAT_MEASURE_TYPES.month &&
        !value
      ) {
        return false;
      }
      return true;
    },
  }),

  frequencyEndType: yup.string().required("Select type of frequency end"),

  frequencyEndDate: yup.string().test({
    name: "frequencyEndDateRequired",
    message: "Select frequency end date",
    test: function (value) {
      const frequency = this.parent.frequency;
      const frequencyEndType = this.parent.frequencyEndType;
      if (
        frequency !== FREQUENCY_TYPES.noRepeat &&
        frequencyEndType === FREQUENCY_END_TYPE.date &&
        isEmpty(value)
      ) {
        return false;
      }
      return true;
    },
  }),

  frequencyEndOccurrences: yup.number().test({
    name: "frequencyEndOccurrencesRequired",
    message: "Select amount of frequency end occurrences",
    test: function (value) {
      const frequency = this.parent.frequency;
      const frequencyEndType = this.parent.frequencyEndType;
      if (
        frequency !== FREQUENCY_TYPES.noRepeat &&
        frequencyEndType === FREQUENCY_END_TYPE.occurrence &&
        Number(value || "") < 1
      ) {
        return false;
      }
      return true;
    },
  }),
});

const editModeSchema = yup.object({
  ...createModeSchema.fields,
  editType: yup.string().required(""),
  providers: undefined,
});

/* Initializers */

const initEditModeForm = ({ data, params, userTimeFormat }) => {
  const extractRepeatMeasure = (fr) => {
    if (fr.includes(CUSTOM_FREQUENCY_TYPE_SUFFIX)) {
      return fr.split(CUSTOM_FREQUENCY_TYPE_SUFFIX)[0];
    }
    return "";
  };

  const extractFrequency = (fr) => {
    if (fr.includes(CUSTOM_FREQUENCY_TYPE_SUFFIX)) {
      return FREQUENCY_TYPES.custom;
    }
    return fr;
  };

  return {
    editType: OUT_OF_OFFICE_EDIT_TYPE.current,
    clinicId: params.clinicId ? Number(params.clinicId) : data.clinicId,
    date: data.date,

    timeStart: moment(
      initOutOfOfficeTimeStart(
        castTo12FormatTime(params.time || data.timeStart),
      ),
      "hh:mm A",
    ).format(userTimeFormat),

    timeEnd: params.time
      ? moment(
          initOutOfOfficeTimeEnd(
            initOutOfOfficeTimeStart(castTo12FormatTime(params.time)),
          ),
          "hh:mm A",
        ).format(userTimeFormat)
      : moment(data.timeEnd, APPT_API_TIME_FORMAT).format(userTimeFormat),

    eventName: data.eventName,
    frequency: data.frequency
      ? extractFrequency(data.frequency)
      : FREQUENCY_TYPES.noRepeat,
    frequencyRepeatCount: data.frequencyRepeatCount || 1,
    frequencyRepeatMeasure: data.frequency
      ? extractRepeatMeasure(data.frequency)
      : FREQUENCY_REPEAT_MEASURE_TYPES.day,
    frequencyRepeatOnWeekDays: data.frequencyRepeatOnWeekDays,
    frequencyRepeatMonthlyValue:
      data.frequencyRepeatMonthlyValue ||
      FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES.eventDate,
    frequencyEndType: data.frequencyEndType || FREQUENCY_END_TYPE.date,
    frequencyEndDate: data.frequencyEndDate,
    frequencyEndOccurrences: data.frequencyEndOccurrences || 1,
  };
};

const initCreateModeForm = ({ params, userTimeFormat }) => ({
  clinicId: params.clinicId ? Number(params.clinicId) : "",
  date: initFormDate({
    date: params.date,
    boundaryDate: wrapFormDate(new Date()),
  }),

  timeStart: moment(
    initOutOfOfficeTimeStart(
      params.time ? castTo12FormatTime(params.time) : null,
    ),
    "hh:mm A",
  ).format(userTimeFormat),

  timeEnd: moment(
    initOutOfOfficeTimeEnd(
      params.time
        ? initOutOfOfficeTimeStart(castTo12FormatTime(params.time))
        : null,
    ),
    "hh:mm A",
  ).format(userTimeFormat),

  eventName: "",
  providers: [],
  frequency: FREQUENCY_TYPES.noRepeat,
  frequencyRepeatCount: 1,
  frequencyRepeatMeasure: FREQUENCY_REPEAT_MEASURE_TYPES.day,
  frequencyRepeatOnWeekDays: [],
  frequencyRepeatMonthlyValue: FREQUENCY_REPEAT_MONTHLY_VALUE_TYPES.eventDate,
  frequencyEndType: FREQUENCY_END_TYPE.date,
  frequencyEndDate: initFormDate({
    date: params.date,
    boundaryDate: wrapFormDate(new Date()),
  }),
  frequencyEndOccurrences: 1,
});

export const initOutOfOfficeForm = ({
  editEventData,
  params,
  userTimeFormat,
}) => {
  if (editEventData) {
    return initEditModeForm({ data: editEventData, params, userTimeFormat });
  }
  return initCreateModeForm({ params, userTimeFormat });
};

/* --------- */

export function useFormOutOfOffice({ isEditMode, initialValues }) {
  const { values, errors, setFieldValue, validateForm } = useFormik({
    initialValues,
    validationSchema: isEditMode ? editModeSchema : createModeSchema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const submit = (submitter) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length === 0) {
        submitter(values);
      } else {
        Object.values(errors).forEach((message) => {
          if (message) {
            uiNotification.error(message);
          }
        });
      }
    });
  };

  const hasError = (field) => {
    return Object.keys(errors).includes(field);
  };

  return {
    form: values,
    setFormValue: setFieldValue,
    submit,
    hasError,
  };
}
