import React, { useEffect } from "react";
import * as yup from "yup";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { useChargeForm } from "../../../hooks/useChargeForm";
import { composeAmountSchema } from "../../../SalesCheckoutInvoice.schemas";
import { svg } from "../../../../../../assets/svg";
import RemainingBalanceDue from "../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentTipsModal = ({ isTipsApplying, checkNo, showTipsModal }) => {
  const { invoice, amountToPay } = useInvoice();
  const { chargeCheque } = useInvoiceActions();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: amountToPay,
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeCheque.initiate({ amount, chequeNumber: checkNo });
      },
      initialValues: {
        amount: amountToPay || "",
      },
    });

  useEffect(() => {
    changeFormValue("amount", amountToPay);
  }, [amountToPay]);

  const onClose = () => {
    showTipsModal.update(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={`${tSales("checkoutInvoice.paymentOptions.check")}: ${checkNo}`}
      imageSrc={svg.checkPayPrimary}
      onCharge={submit}
      isCharging={chargeCheque.isLoading}
      chargeLabel={tCommon("label.charge")}
      amount={form.amount}
      isChargeDisabled={isTipsApplying || !isValid}
    >
      <ViewTipsModal amountToPay={amountToPay} />
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <RemainingBalanceDue amount={form.amount} />
    </ModalCharge>
  );
};

export default PaymentTipsModal;
