import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import classes from "./sass/membership.module.scss";
import DrawDayStepper from "./DrawDayStepper";
import cx from "clsx";

const MembershipFeeSchedule = () => {
  const { control } = useFormContext();

  const feeSchedule = useWatch({
    control,
    name: "feeSchedule",
    defaultValue: [],
  });

  const filteredFeeSchedule = useMemo(
    () => feeSchedule.filter((scheduleDetails) => scheduleDetails.payment_date),
    [feeSchedule],
  );

  return (
    <>
      <div className="row">
        {feeSchedule.length > 0 && (
          <div className={cx("col-xs-12 ", classes.feeScheduleContainer)}>
            <div className={cx(classes.fieldTitle, classes.feeScheduleTitle)}>
              FEE SCHEDULE
            </div>
            {filteredFeeSchedule.map((scheduleDetails, scheduleIndex) => (
              <div key={scheduleDetails.payment_date}>
                <DrawDayStepper
                  item={scheduleDetails}
                  idx={scheduleIndex}
                  timelineData={filteredFeeSchedule}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default MembershipFeeSchedule;
