import * as yup from "yup";
import { useExtendedFormik } from "../../../../../../../../hooks/useExtendedFormik";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";

export const useForm = (onSubmit, initialValues) => {
  const { tClients } = useAppTranslation.Clients();

  const { values, handleSubmit, changeField, getError } = useExtendedFormik({
    enableReititialize: true,
    validationSchema: yup.object({
      field: yup
        .object({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
      period: yup
        .object({
          from: yup.date().required(),
          to: yup.date().required(),
        })
        .required(),
    }),
    initialValues: {
      field: initialValues.field
        ? {
            label: tClients(`vitals.graph.fieldOptions.${initialValues.field}`),
            value: initialValues.field,
          }
        : null,
      period: initialValues.period || null,
    },
    onSubmit,
  });

  return {
    submit: handleSubmit,
    field: {
      value: values.field,
      onChange: (next) => changeField("field", next),
      getIsError: () => Boolean(getError("field")),
    },
    period: {
      value: values.period,
      onChange: (next) => changeField("period", next),
      getIsError: () => Boolean(getError("period")),
    },
  };
};
