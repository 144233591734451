import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, ORDER_TYPES, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";
import { createQueryKeyFromShape } from "../../utilities/api";

export const DELETED_CUSTOMER_NOTES_SORT_FIELDS = {
  patient: "patient",
  createdBy: "added_by",
  deletedAt: "deleted_at",
};

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.string().nullable(),
      to: yup.string().nullable(),
    })
    .nullable(),

  order: yup
    .object({
      by: yup
        .string()
        .test({
          test: (x) =>
            Object.values(DELETED_CUSTOMER_NOTES_SORT_FIELDS).includes(x),
        })
        .required(),
      direction: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) => Object.values(ORDER_TYPES).includes(value),
        })
        .required(),
    })
    .nullable(),

  search: yup.string().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    data: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          deleted_at: yup.string().required(),
          added_by: yup.string().required(),
          patient: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    data,
  };
};

const createDeletedCustomerNotesQueryKey = (...args) => [
  QUERY_KEYS.deletedNotes.customer,
  ...args,
];

const createKeyFromShape = (shape) =>
  unwrapOr(() => createQueryKeyFromShape(removeNullishFromShape(shape)), null);

export function useDeletedCustomerNotesQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createDeletedCustomerNotesQueryKey(
      createKeyFromShape(payload.order),
      createKeyFromShape(payload.period),
      req.search,
      req.pageSize,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.deletedNotes.customer.get(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          sort_by: req.order?.by,
          order: req.order?.direction,
          fromDate: req.period.from,
          toDate: req.period.to,
          term: req.search,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
