import * as yup from "yup";
import { useFormik } from "formik";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function useForm({
  initialValues,
  onSubmit,
  isWitnessSignatureRequired,
}) {
  const { tCommon } = useAppTranslation.Common();

  const schema = yup.object({
    patientSignature: yup
      .string()
      .required(
        tCommon("signPatientConsent.form.error.requiredPatientSignature"),
      ),
    patientName: yup
      .string()
      .required(tCommon("signPatientConsent.form.error.requiredPatientName")),
    witnessSignature: yup.lazy(() => {
      if (isWitnessSignatureRequired) {
        return yup
          .string()
          .required(
            tCommon("signPatientConsent.form.error.requiredWitnessSignature"),
          );
      }
      return yup.string();
    }),
    witnessName: yup.lazy(() => {
      if (isWitnessSignatureRequired) {
        return yup
          .string()
          .required(
            tCommon("signPatientConsent.form.error.requiredWitnessName"),
          );
      }
      return yup.string();
    }),
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
  } = useFormik({
    onSubmit,
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    initialValues,
  });

  const hasError = (field) => {
    return Boolean(touched[field] && errors[field]);
  };

  return {
    values,
    errors,
    hasError,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  };
}
