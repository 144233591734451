/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
import React, { useMemo, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { showFormattedDate } from "../../Utils/services";
import { Button } from "../../shared/Button/Button";
import AcknowledgeHistoryModal from "./AcknowledgeHistoryModal";
import { ReadFullNote } from "./ReadFullNote";

const CustomerNoteWithActions = (props) => {
  const {
    noteobj,
    noteidx,
    editNote,
    deleteNote,
    userCanEdit,
    userCanDelete,
    acknowledgeNote,
    snoozeNote,
    restoreNote,
  } = props;

  const [historyModal, setHistoryModal] = useState({
    acknowledgeHistoryModal: false,
    notesHistoryModal: false,
  });

  const { created, added_by, modified, edited_by, id, title } = noteobj;
  const { acknowledgeHistoryModal, notesHistoryModal } = historyModal;

  const snoozeThisNote = (snooze_type) => {
    snoozeNote({
      snooze_type,
      snooze_count: 1,
      id,
    });
  };

  const noteHistory = useMemo(() => {
    if (modified !== created) {
      return `Edited by ${edited_by} on ${showFormattedDate(modified, true)}`;
    }

    return `Left by ${added_by} on ${showFormattedDate(created, true)}`;
  }, [modified, created]);

  const editThisNote = () => {
    editNote(noteobj);
  };

  const deleteThisNote = () => {
    deleteNote(noteobj);
  };

  const restoreThisNote = () => {
    restoreNote(noteobj);
  };

  const toggleAcknowledgeHistoryModal = () => {
    setHistoryModal((prevState) => ({
      ...prevState,
      acknowledgeHistoryModal: !prevState.acknowledgeHistoryModal,
      notesHistoryModal: false,
    }));
  };

  const toggleNotesHistoryModal = () => {
    setHistoryModal((prevState) => ({
      ...prevState,
      acknowledgeHistoryModal: false,
      notesHistoryModal: !prevState.notesHistoryModal,
    }));
  };

  return (
    <div id={id} className="sticky-note-item" key={noteidx}>
      <div className="note-label">
        <div className="label-text word-wrap-break">
          <LinesEllipsis
            maxLine={1}
            basedOn="letters"
            ellipsis={"..."}
            text={title || ""}
          />
        </div>
        <span className=" settings-icon">
          <span className="dropdown">
            <span
              className="dropdown-toggle"
              id="dropdownNoteSettings"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v   " />
            </span>

            <div
              className="dropdown-menu"
              aria-labelledby="dropdownNoteSettings"
            >
              <div
                class="dropdown-item p-x-10"
                onClick={toggleAcknowledgeHistoryModal}
              >
                Acknowledge&nbsp;History
              </div>
              <div
                className="dropdown-item p-x-10"
                onClick={toggleNotesHistoryModal}
              >
                Notes&nbsp;History
              </div>
              <div className="dropdown-item p-x-10" onClick={restoreThisNote}>
                Convert to Note
              </div>
              {userCanEdit && (
                <div className="dropdown-item p-x-10" onClick={editThisNote}>
                  Edit
                </div>
              )}
              {userCanDelete && (
                <div className="dropdown-item p-x-10" onClick={deleteThisNote}>
                  Delete
                </div>
              )}
            </div>
          </span>
        </span>
      </div>
      <div className="note-body">
        <div className="body-text">
          <ReadFullNote
            text={noteobj.notes.trim()}
            maxLines={"3"}
            title={noteobj.title || "Note"}
          />
        </div>
      </div>

      <div className="note-edit-history">{noteHistory}</div>
      <div className="note-actions flex">
        <Button onClick={() => acknowledgeNote(id)} size="small">
          Acknowledge
        </Button>

        <div className="note-action">
          <div className="dropdown">
            <div
              className="dropdown-toggle note-action"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Snooze <i className="fas fa-angle-down"></i>
            </div>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div
                className="dropdown-item p-x-10"
                onClick={() => snoozeThisNote("hourly")}
              >
                1 Hour
              </div>
              <div
                className="dropdown-item"
                onClick={() => snoozeThisNote("daily")}
              >
                1 Day
              </div>
            </div>
          </div>
        </div>
      </div>
      {(acknowledgeHistoryModal || notesHistoryModal) && (
        <AcknowledgeHistoryModal
          id={id}
          onClose={
            acknowledgeHistoryModal
              ? toggleAcknowledgeHistoryModal
              : toggleNotesHistoryModal
          }
          isAcknowledgeHistorOrNotesHistory={acknowledgeHistoryModal}
          noteType={notesHistoryModal ? "client-note" : false}
        />
      )}
    </div>
  );
};

export default CustomerNoteWithActions;
