import React, { useState } from "react";
import MembershipsList from "./MembershipsList";
import MembershipsDetails from "./MembershipsDetails";
import { useGetPatientMemershipsListQuery } from "../../../_legacy/Queries";

const ClientMembership = (props) => {
  const [selectedMembership, setSelectedMembership] = useState(null);
  const {
    data: patientMemberships,
    isFetching,
    dataUpdatedAt,
  } = useGetPatientMemershipsListQuery({
    id: props.clientID,
    lastUpdatedAt: props.newMembershipDataTime,
  });

  return selectedMembership && patientMemberships?.data ? (
    <MembershipsDetails
      {...props}
      setSelectedMembership={setSelectedMembership}
      selectedMembership={selectedMembership}
      dataUpdatedAt={dataUpdatedAt}
      patientMemberships={patientMemberships}
    />
  ) : (
    <MembershipsList
      {...props}
      isFetchingList={isFetching}
      patientMembershipsList={patientMemberships?.data?.data?.memberships}
      selectMembership={setSelectedMembership}
    />
  );
};

export default ClientMembership;
