import React, { useEffect, useState } from "react";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar";
import PosTitle from "../PosTitle";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import {
  fetchKlarnaAvailability,
  updateKlarnaAvailability,
} from "../../../../Actions/Sales/klarnaActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Loader from "../../../../pages/Event/components/Loader";
import cx from "clsx";
import { uiNotification } from "../../../../services/UINotificationService";

const PosKlarna = ({
  history,
  fetchKlarnaAvailability,
  updateKlarnaAvailability,
  klarnaAvailability,
}) => {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const [businessUrlModal, setBusinessUrlModal] = useState(null);

  const handleToggle = (clinicId, isKlarnaEnabled) => {
    setIsEnabled(isKlarnaEnabled);
    setClinicId(clinicId);
    setShowConfirmationModal(true);
  };

  const confirmationAction = () => {
    updateKlarnaAvailability(clinicId, isEnabled)
      .then((response) => {
        if (response.data.business_url) {
          setBusinessUrlModal(response.data.business_url);
        }
        uiNotification.success("Klarna status updated successfully");
      })
      .catch((e) => uiNotification.error(e.message))
      setShowConfirmationModal(false)
  };

  const cancelAction = () => {
    setShowConfirmationModal(false);
  };

  const capabilities = () => klarnaAvailability?.data?.capabilities || [];

  const isLoading = () => klarnaAvailability.status === "loading";

  const getStatus = (clinicConfig) => {
    let status;
    let icon;
    if (clinicConfig.stripe.status === "active") {
      status = "enabled";
      icon = <i className="fa fa-check-circle m-r-5 m-b-2" />;
    } else if (clinicConfig.stripe.status === "inactive") {
      status = "pending";
      icon = <i className="fa fa-spinner fa-pulse fa-3x fa-fw m-b-2" />;
    } else {
      status = "disabled";
      icon = <i className="fa fa-times-circle m-r-5 m-b-2" />;
    }

    const date =
      status !== "pending" && clinicConfig["klarna_modified"] !== "0000-00-00"
        ? `(authorized on ${clinicConfig["klarna_modified"]})`
        : "";

    return (
      <div>
        {icon} Status: {status} {date}
      </div>
    );
  };

  useEffect(() => {
    fetchKlarnaAvailability();
  }, []);

  return (
    <div className="main protected">
      <div id="content" className="content-pos-dashboard">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="business-setion memberWalletOuter">
            <PosTitle
              history={history}
              settingsLang={languageData.settings}
              globalLang={languageData.global}
              activeMenu={"pos-klarna"}
            />
            <div className="setting-setion m-b-10">
              <div className="col-lg-12 d-flex border-bottom">
                <div className="membership-title col-lg-6 p-l-0">
                  Klarna Payment Method
                </div>
                <div className="membership-title col-lg-6 text-right font-size-21">
                  Processing Fee: 6.1%
                </div>
              </div>
              <div className="col-lg-12 border-bottom">
                <p className="membership-title font-weight-600 p-l-0">
                  Offer flexible payment options and get paid up front with
                  Klarna.
                </p>
                <p className="font-size-16">
                  By enabling Klarna payments, you agree to follow the brand &
                  legal advertising guidelines provided by Klarna and found
                  here:&nbsp;
                  <a
                    href="https://docs.klarna.com/marketing/solutions/grab-and-go/us"
                    className="link-color"
                  >
                    https://docs.klarna.com/marketing/solutions/grab-and-go/us
                  </a>
                </p>
                <p className="font-size-16">
                  Klarna Payments are available as part of your Stripe Connect
                  account. Therefore, funds are deposited into the same bank
                  account and on the same standard schedule as your credit card
                  payments.
                </p>
              </div>
              <div className="membership-title fontsize16">
                {capabilities().map((clinicConfig) => (
                  <div
                    key={clinicConfig.clinic_id}
                    className="membership-title fontsize16"
                  >
                    <label className="setting-switch pull-right">
                      <input
                        type="checkbox"
                        className="setting-custom-switch-input"
                        checked={clinicConfig.is_klarna_enabled}
                        value={1}
                        onChange={() =>
                          handleToggle(
                            clinicConfig.clinic_id,
                            !clinicConfig.is_klarna_enabled,
                          )
                        }
                      />
                      <span className="setting-slider" />
                    </label>
                    <span>
                      {clinicConfig.name}
                      <span className="AuthorizedSync">
                        {getStatus(clinicConfig)}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        title="Klarna"
        isOpen={showConfirmationModal}
        onClose={() => cancelAction()}
        onCancel={() => cancelAction()}
        onConfirm={() => confirmationAction()}
      >
        {`Are you sure you want to change the status?`}
      </ConfirmModal>
      <ConfirmModal
        title="Klarna"
        isOpen={Boolean(businessUrlModal)}
        onConfirm={() => setBusinessUrlModal(null)}
        footerClassName={cx("flex", "justify-end")}
        onClose={() => setBusinessUrlModal(null)}
      >
        <p>Awesome, Klarna capability is now enabled!</p>
        <p>
          {`Additionally, we've set the business url for your Stripe account to ${businessUrlModal} to have the correct branding required by Klarna. This url can be changed at any time in the Stripe Dashboard.`}
        </p>
      </ConfirmModal>
      {isLoading() && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  klarnaAvailability: state.KlarnaReducer.klarnaAvailability,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchKlarnaAvailability, updateKlarnaAvailability },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PosKlarna));
