import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useDownloadPrescriptionPdf = ({ id, clientId }, options = {}) => {
  return useQuery(
    ["downloadPrescriptionPdf"],
    () => {
      return http.get(HTTP_ENDPOINTS.getPrescriptionPdf(id), {
        params: { patient_id: clientId },
      });
    },
    { ...options, enabled: false },
  );
};
