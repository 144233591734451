import React from "react";
import classes from "../styles/Calendar.module.scss";
import { useToggleGfeOnFile } from "../hooks/useToggleGfeOnFile";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

const GfeToggle = ({ appointmentId, gfeToggle, handleToggle }) => {
  const { mutate, isLoading } = useToggleGfeOnFile();

  const handleChange = () => {
    mutate(
      {
        appointmentId,
        gfeOnFile: !gfeToggle,
      },
      {
        onSuccess: () => handleToggle(),
      },
    );
  };

  return (
    <div>
      <span className={classes.toggleLabel}>GFE On File</span>
      <span>
        <label className="setting-switch pull-right flex" htmlFor="gfeToggle">
          {gfeToggle ? (
            <span className="radioTextLabel">Yes</span>
          ) : (
            <span className="radioTextLabel">No</span>
          )}
          <input
            type="checkbox"
            name="gfeToggle"
            id="gfeToggle"
            checked={Boolean(gfeToggle)}
            onChange={handleChange}
          />
          {!isLoading ? (
            <span className="setting-slider width-45"></span>
          ) : (
            <CircularProgress size="small" color="primary" />
          )}
        </label>
      </span>
    </div>
  );
};

export default GfeToggle;
