import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clinicId: yup.number().required(),
  year: yup.number().required(),
});

const responseSchema = yup
  .array()
  .of(
    yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
      isDisabled: yup.boolean().required(),
    }),
  )
  .required();

const composeResponse = (res) => {
  const data = res?.data?.data || [];

  return data?.map((month) => ({
    id: month.id,
    name: month.month_value,
    isDisabled: month.show === 1,
  }));
};

const createSalesGoalAvailableMonthsQueryKey = (...args) => [
  QUERY_KEYS.salesGoalAvailableMonths,
  ...args,
];

export function useSalesGoalAvailableMonthsQuery(payload, options = {}) {
  return useQuery(
    createSalesGoalAvailableMonthsQueryKey(payload?.clinicId, payload?.year),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.post(
        HTTP_ENDPOINTS.getSalesGoalAvailableMonths(),
        {
          clinic_id: req.clinicId,
          year: req.year,
          month: 0,
        },
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
