import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classes from "../../sass/ProcedureTableInfo.module.scss";
import { ButtonFollowLink } from "../../../../shared/ButtonFollowLink/ButtonFollowLink";
import { PREVIEW_DATE_FORMAT } from "../../../../consts/general";

export function ProcedureTableInfo({ title, rawDate, link }) {
  return (
    <>
      <div className={classes.head}>
        {link && <ButtonFollowLink url={link} />}
        <b>{title}</b>
      </div>
      {rawDate && (
        <div className={classes.date}>
          {moment.utc(rawDate).format(PREVIEW_DATE_FORMAT)}
        </div>
      )}
    </>
  );
}

ProcedureTableInfo.propTypes = {
  title: PropTypes.string.isRequired,
  rawDate: PropTypes.string,
  link: PropTypes.string,
};

ProcedureTableInfo.defaultProps = {
  rawDate: null,
  link: null,
};
