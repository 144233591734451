import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function fetchClockStatus(user_id = null) {
  let type = "LOGGED_CLOCK_STATUS";
  let formData = null;

  if (user_id) {
    type = "USER_CLOCK_STATUS";
    formData = {
      params: {
        user_id: user_id,
      },
    };
  }

  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
    http
      .get("clock_data", formData)
      .then((response) => {
        dispatch({ type: type, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: type, payload: error.response.data });
      });
  };
}
