import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import { createQueryKeyFromShape } from "../../utilities/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

export const SALES_GOALS_ORDER_BY = {
  year: "year",
  month: "month",
};

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  filter: yup.object({
    clinicId: yup.number().nullable(),
  }),

  limit: yup.number().nullable(),

  order: yup
    .object({
      by: yup.string().required(),
      direction: yup.string().required(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    data: yup
      .array()
      .of(
        yup
          .object({
            id: yup.number().required(),
            year: yup.number().required(),
            month: yup.string().required(),
            goal: yup.number().required(),
            format_month: yup.number().required(),
            clinic: yup.object({
              id: yup.number().required(),
              clinic_name: yup.string().required(),
            }),
          })
          .required(),
      )
      .required(),
    clinics: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          clinic_name: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data?.clinic_sale_goals?.data || [];
  const clinics = res?.data?.data?.clinics || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data?.clinic_sale_goals || {},
      pageSize: req.pageSize,
    }),
    data,
    clinics,
  };
};

const createSalesGoalsQueryKey = (...args) => [QUERY_KEYS.salesGoals, ...args];

const createKeyFromShape = (shape) =>
  createQueryKeyFromShape(removeNullishFromShape(shape));

export function useSalesGoalsQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createSalesGoalsQueryKey(
      createKeyFromShape(req.order),
      createKeyFromShape(req.filter),
      req.limit,
      req.pageSize,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getSalesGoals(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          clinic_id: req.filter.clinicId,
          sortby: req.order?.by,
          sortorder: req.order?.direction,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
