import { usePatientVitalsSettingRangesQuery } from "../../../../api/patientVitals/usePatientVitalsSettingRangesQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { sort } from "../../../../utilities/general";

export const useSettingRanges = () => {
  const { tClients } = useAppTranslation.Clients();

  const { data, isLoading } = usePatientVitalsSettingRangesQuery({
    onError: () => {
      uiNotification.error(tClients("vitals.settings.error.fetchRanges"));
    },
  });

  return {
    data: sort(data || [], (a, b) => a.start_value - b.start_value),
    isLoading,
  };
};
