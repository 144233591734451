import React from "react";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { ReportHeader } from "../../../../../../../../BusinessInsights/components/ReportHeader/ReportHeader";
import DownloadDropdown from "../../../../../../../components/DownloadDropdown";
import { HTTP_ENDPOINTS } from "../../../../../../../../../consts/api";

export function Header({ total }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const requestParams = {
    report_type: "expired",
  };

  return (
    <ReportHeader
      withBackBtn
      label={tBi("liabilityReports.expiring.label")}
      left={total}
    >
      <DownloadDropdown
        params={requestParams}
        downloadRoute={HTTP_ENDPOINTS.getShortTermLiability()}
      />
    </ReportHeader>
  );
}
