import React from "react";
import PropTypes from "prop-types";
import PaperworkTableRow from "./PaperworkTableRow";

const PaperworkTable = ({
  innerbox_select_all,
  handleAllChecked,
  recentlyDeletedList,
  selected,
  handlChecked,
  showLoader
}) => {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  return (
    <div className="juvly-section full-width">
      <div className="table-responsive">
        <table className="table-updated juvly-table table-min-width no-td-border">
          <thead className="table-updated-thead">
            <tr>
              <th className="col-xs-0 table-updated-th table-checkbox">
                <input
                  type="checkbox"
                  name="innerbox_select_all"
                  className="checkbox-recently-deleted"
                  checked={innerbox_select_all}
                  onClick={handleAllChecked}
                  disabled={!recentlyDeletedList.length}
                />
              </th>
              <th className="col-xs-6 table-updated-th">Title</th>
              <th className="col-xs-6 table-updated-th">Deleted</th>
            </tr>
          </thead>
          <tbody className="ajax_body">
            {recentlyDeletedList.map((obj, idx) => (
              <PaperworkTableRow
                key={"recently-deleted" + idx}
                item={obj}
                selected={selected}
                handlChecked={handlChecked}
              />
            ))}
          </tbody>
        </table>
        
        {showLoader === false && (
          <div className={recentlyDeletedList.length ? "no-record no-display" : "no-record"}>
            {languageData.global.sorry_no_record_found}
          </div>
        )}
      </div>
    </div>
  );
}

PaperworkTable.propTypes = {
  innerbox_select_all: PropTypes.bool.isRequired,
  handleAllChecked: PropTypes.func.isRequired,
  recentlyDeletedList: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  handlChecked: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
}

export default PaperworkTable;
