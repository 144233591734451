import { useState } from "react";
import { useInvoice } from "./invoice/useInvoice";

export const usePaymentFlow = () => {
  const { isStartStage, isTipsEnabled } = useInvoice();

  const [showTipsModal, setShowTipsModal] = useState(false);

  const isTipsAvailable = isStartStage && isTipsEnabled();

  return {
    showTipsModal: {
      value: showTipsModal,
      update: setShowTipsModal,
    },
    isTipsAvailable,
  };
};
