import React from "react";
import { Box } from "../../../../../shared/Box/Box";
import classes from "./Layout.module.scss";
import { LayoutSettings } from "../../../../../boxes/LayoutSettings/LayoutSettings";

export function Layout({ children }) {
  return (
    <LayoutSettings>
      <Box className={classes.content}>{children}</Box>
    </LayoutSettings>
  );
}
