export const SIMPLE_QUESTION_TYPES = {
  yesNo: "yesno",
  multiText: "multitext",
  multiImage: "multiimage",
};

export const MULTI_QUESTION_TYPES = {
  textBox: "Textbox",
  yesNo: "Yes/No",
  singleChoice: "Single Choice",
  multiChoice: "Multiple Choice",
  opinionScale: "Opinion Scale",
  fileUpload: "File Upload",
};

export const MULTI_QUESTION_LOGIC_JUMP_TYPES = {
  jump: "jump",
  else: "else",
};
