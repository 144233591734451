import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useSalesSummary = (params, options = {}) => {
  return useQuery(
    ["salesSummaryReport", params],
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getDailySalesReport(), {
        params,
      });

      const composeResponse = {
        clinics: res.data.data.clinics,
        dailyDepositSlip: res.data.data.daily_deposit_slip,
        dailyDiscountReport: res.data.data.daily_discount_report,
        dailyDiscountReportHeader: res.data.data.daily_discount_report_header,
        dailyDiscountReportTotal: res.data.data.daily_discount_report_total,
        dailyPaymentMethod: res.data.data.daily_payment_method,
        dailyProcedureTotalSales: res.data.data.daily_procedure_total_sales,
        dailyTotalSales: res.data.data.daily_total_sales,
      };
      return composeResponse;
    },
    options,
  );
};
