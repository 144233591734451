import { useQuery } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../consts/api";

export const useDocumentEditedBy = (params, options = {}) => {
  return useQuery(
    ["getEditedBy", params],
    () => http.get(HTTP_ENDPOINTS.getEditedBy(), { params }),
    {
      ...options,
      cacheTime: 0,
      select: (data) => ({
        editedBy: `${data.data.data.firstname} ${data.data.data.lastname}`,
        editedAt: data.data.data.updated_at,
        edited: Boolean(data.data.data.firstname),
      }),
    },
  );
};
