import React from "react";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { DateRangePicker } from "../../../../../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../../../../../api/queries/useUserQuery";
import { STATIC_RANGES } from "../../../../../../../../../shared/DateRangePicker/DateRangePicker.consts";
import { ReportHeader } from "../../../../../../../../BusinessInsights/components/ReportHeader/ReportHeader";
import DownloadDropdown from "../../../../../../../components/DownloadDropdown";
import { HTTP_ENDPOINTS } from "../../../../../../../../../consts/api";

export function Header({ total, period, isReportsLoading, requestParams }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader
      withBackBtn
      label={tBi("liabilityReports.expired.label")}
      left={total}
    >
      <DateRangePicker
        size="small"
        isDisabled={isReportsLoading}
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
        staticRanges={[STATIC_RANGES.mtd, STATIC_RANGES.ytd, STATIC_RANGES.qtd]}
      />
      <DownloadDropdown
        params={requestParams}
        downloadRoute={HTTP_ENDPOINTS.getShortTermLiability()}
      />
    </ReportHeader>
  );
}
