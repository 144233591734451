import { useStatementDescriptorQuery } from "../../../../../../api/queries/useStatementDescriptorQuery";
import { unwrapOr } from "../../../../../../utilities/general";

export function useApiDescriptor(stripeUserId) {
  const {
    data: descriptorQueryData,
    isFetching: isDescriptorLoading,
    isError: isDescriptorError,
  } = useStatementDescriptorQuery({
    payload: {
      stripeUserId,
    },
    options: {
      enabled: Boolean(stripeUserId),
    },
  });

  const descriptor = unwrapOr(
    () => descriptorQueryData.data.data.statement_descriptor,
    null,
  );

  return {
    descriptor,
    isDescriptorError,
    isDescriptorLoading,
  };
}
