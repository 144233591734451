import React from "react";
import { Empty } from "../../../../shared/Empty/Empty";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import classes from "./RecentSearches.module.scss";
import { SearchResultTile } from "../../shared/SearchResultTile/SearchResultTile";
import { useGlobalSearchService } from "../../hooks/useGlobalSearchService";
import { TOKENS_RESTRICTIONS } from "../../GlobalSearch.consts";

export function RecentSearches() {
  const { tCommon } = useAppTranslation.Common();
  const globalSearchService = useGlobalSearchService();
  const recentPatterns = globalSearchService.getSavedPatterns();

  return (
    <div>
      {recentPatterns.length === 0 ? (
        <Empty position="center">
          {tCommon("globalSearch.noRecentSearches")}
        </Empty>
      ) : (
        <div className={classes.content}>
          <div className={classes.label}>{tCommon("label.recent")}</div>
          {recentPatterns.map((pattern) => {
            const isTokenRestricted = TOKENS_RESTRICTIONS[pattern];
            if (!isTokenRestricted) {
              return <SearchResultTile key={pattern} pattern={pattern} />;
            }
          })}
        </div>
      )}
    </div>
  );
}
