import { useFormik } from "formik";
import { useEffect } from "react";
import {
  DEFAULT_QUESTION_TYPE,
  INITIAL_ELSE_LOGIC_JUMP,
  INITIAL_IF_LOGIC_JUMP,
  getNextInitialChoicesByQuestionType,
  getNextInitialLogicJumpsByQuestionType,
  questionSchema,
} from "../../../hooks/useForm";
import { MULTI_QUESTION_LOGIC_JUMP_TYPES } from "../../../../../../../../api/questionnaires/config";
import { isNullish, unwrapOr } from "../../../../../../../../utilities/general";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { useCheckAnswerDuplicates } from "../../../../../hooks/useCheckAnswerDuplicates";
import { NodeId } from "../../../../../utilities";
import { cond, identity, pipe } from "../../../../../../../../utilities/fp";

export function useForm({ onSubmit, preloadedQuestion }) {
  const { tSettings } = useAppTranslation.Settings();
  const checkAnswerDuplicates = useCheckAnswerDuplicates();

  const form = useFormik({
    enableReinitialize: true,
    validationSchema: questionSchema,
    initialValues: {
      id: preloadedQuestion?.id || NodeId.default,
      question: preloadedQuestion?.question || "",
      questionType: preloadedQuestion?.questionType || DEFAULT_QUESTION_TYPE,
      isRequired: preloadedQuestion?.isRequired || false,
      allowCommentsOnScale: preloadedQuestion?.allowCommentsOnScale || false,
      description: preloadedQuestion?.description || "",
      logicJumps:
        preloadedQuestion?.logicJumps?.length === 0
          ? getNextInitialLogicJumpsByQuestionType(
              preloadedQuestion?.questionType || DEFAULT_QUESTION_TYPE,
            )
          : preloadedQuestion?.logicJumps,
      choices:
        preloadedQuestion?.choices?.length === 0
          ? getNextInitialChoicesByQuestionType(
              preloadedQuestion?.questionType || DEFAULT_QUESTION_TYPE,
            )
          : preloadedQuestion?.choices,
    },
    onSubmit: (values) => {
      if (checkAnswerDuplicates(values.choices?.map((c) => c.value))) {
        onSubmit({
          ...values,
          id: pipe(
            values.id,
            cond(identity, [(x) => NodeId.isDefault(x), () => NodeId.make()]),
          ),
        });
      }
    },
  });

  function getError(key) {
    if (form.touched[key] && form.errors[key]) {
      switch (key) {
        case "logicJumps": {
          return tSettings("questionnaires.questionBuilder.logicJumpsError");
        }
        case "choices": {
          return tSettings("questionnaires.questionBuilder.choicesError");
        }
        default: {
          return form.errors[key];
        }
      }
    }
    return null;
  }

  useEffect(() => {
    if (preloadedQuestion && Object.values(form.touched).length === 0) {
      Object.keys(preloadedQuestion).forEach((key) =>
        form.setFieldTouched(key),
      );
    }
  }, [preloadedQuestion, form.touched]);

  const changeLogicJumpByIndex = ({ field, value, index }) => {
    const node = form.values.logicJumps?.[index];
    if (node) {
      const nextNode = { ...node, [field]: unwrapOr(() => value, null) };
      form.setFieldValue("logicJumps", [
        ...form.values.logicJumps.slice(0, index),
        nextNode,
        ...form.values.logicJumps.slice(index + 1),
      ]);
    }
  };

  return {
    ...form,
    getError,

    questionType: {
      value: form.values.questionType,
      onChange: (next) => {
        form.setFieldValue("questionType", next);
        form.setFieldValue("allowCommentsOnScale", false);
        form.setFieldValue(
          "logicJumps",
          getNextInitialLogicJumpsByQuestionType(next),
        );
        form.setFieldValue(
          "choices",
          getNextInitialChoicesByQuestionType(next),
        );
      },
    },

    logicJumps: {
      value: form.values.logicJumps,
      insert: () => {
        const elseClauseIndex = form.values.logicJumps.findIndex(
          (i) => i.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
        );
        if (elseClauseIndex === -1) {
          form.setFieldValue("logicJumps", [
            ...form.values.logicJumps,
            INITIAL_IF_LOGIC_JUMP,
            INITIAL_ELSE_LOGIC_JUMP,
          ]);
        } else {
          form.setFieldValue("logicJumps", [
            ...form.values.logicJumps.slice(0, elseClauseIndex),
            INITIAL_IF_LOGIC_JUMP,
            ...form.values.logicJumps.slice(elseClauseIndex),
          ]);
        }
      },
      changeByIndex: changeLogicJumpByIndex,
      removeByIndex: (index) => {
        if (!isNullish(index)) {
          form.setFieldValue("logicJumps", [
            ...form.values.logicJumps.slice(0, index),
            ...form.values.logicJumps.slice(index + 1),
          ]);
        }
      },
    },

    choices: {
      value: form.values.choices,

      add: () => {
        if (
          form.values.choices.every((choice) => Boolean(choice.value?.trim()))
        ) {
          form.setFieldValue("choices", [
            ...form.values.choices,
            {
              id: NodeId.make(),
              value: "",
            },
          ]);
        }
      },

      deleteByIndex: (index) => {
        if (!isNullish(index)) {
          const choice = form.values.choices[index];

          const affectedLogicJumpIndex = form.values.logicJumps.findIndex(
            (x) => x.ifSelectedChoiceId === choice.id,
          );

          form.setFieldValue("choices", [
            ...form.values.choices.slice(0, index),
            ...form.values.choices.slice(index + 1),
          ]);

          if (affectedLogicJumpIndex !== -1) {
            changeLogicJumpByIndex({
              field: "ifSelectedChoiceId",
              value: null,
              index: affectedLogicJumpIndex,
            });
          }
        }
      },

      changeByIndex: (nextValue, index) => {
        const prev = form.values.choices[index];

        if (prev) {
          const nextChoices = [...form.values.choices];
          nextChoices[index] = { ...prev, value: nextValue };
          form.setFieldValue("choices", nextChoices);
        }
      },

      replace: (next) => {
        if (next) {
          form.setFieldValue("choices", next);
        }
      },
    },
  };
}
