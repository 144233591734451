import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  hash: yup.string().required(),
});

const responseSchema = yup.object({
  last4: yup.string().required(),
});

const composeResponse = (res) => {
  return res?.data?.data || {};
};

const createUserPhoneLastDigitsQueryKey = (hash) => [
  QUERY_KEYS.userPhoneLastDigits,
  hash,
];

export const useUserPhoneLastDigitsQuery = ({ payload, options = {} }) => {
  return useQuery(
    createUserPhoneLastDigitsQueryKey(payload.hash),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.user.getPhoneLastDigits(req.hash),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
