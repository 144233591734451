import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";
import { removeNullishFromShape } from "../../../utilities/general";

const requestSchema = yup.object({
  patientConsentId: yup.number().required(),
  patientName: yup.string().required(),
  patientSignature: yup.string().required(),
  witnessName: yup.string().nullable(),
  witnessSignature: yup.string().nullable(),
});

export function usePatientConsentSignMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.patientConsents.sign(),
        removeNullishFromShape({
          patient_consent_id: req.patientConsentId,
          initials: req.patientName,
          patient_signature: req.patientSignature,
          witness_name: req.witnessName,
          witness_signature: req.witnessSignature,
        }),
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientProcedures],
        });
        options?.onSuccess?.();
      },
    },
  );
}
