import {
  SALES_REPORT_EXPORT_NAMES,
  useSalesReportExportMutation,
} from "../../../../api/mutations/useSalesReportExportMutation";
import { FILE_EXPORT_TYPES } from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { composeDownloadDataUrl } from "../../../../utilities/api";

export function useDownloadWaitListExcel({ pageFilters, searchParams }) {
  const { tCommon } = useAppTranslation.Common();

  const { mutateAsync, isLoading } = useSalesReportExportMutation({
    onError: () => {
      uiNotification.error(tCommon("error.exportXlsReports"));
    },
  });

  const initiate = async () => {
    const res = await mutateAsync({
      type: FILE_EXPORT_TYPES.xls,
      name: SALES_REPORT_EXPORT_NAMES.waitList,
      waitList: {
        sort_dir: pageFilters?.sort_dir,
        sort_by: pageFilters?.sort_by,
        month: searchParams?.month,
        clinic_id: searchParams?.clinic_id,
        provider_id: searchParams?.provider_id,
        service_id: searchParams?.service_id,
        timeslot_id: searchParams?.timeslot_id,
        day: searchParams?.day,
        year: searchParams?.year,
        status: searchParams?.status,
        search: pageFilters?.search || null,
      },
    });

    window.open(composeDownloadDataUrl(res.fileName), "_blank");
  };

  return {
    initiate,
    isLoading,
  };
}
