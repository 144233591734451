import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

export function ScrollBox({ profile, getPrescribedPlanData }) {
  return (
    <Scrollbars style={{ height: 177 }} className="custome-scroll">
      <div className="mCustomScrollbar _mCS_1">
        <div
          className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside"
          style={{ maxHeight: "none" }}
          tabIndex={0}
        >
          {profile.priscribed_treatment_plans.map((obj, idx) => {
            return (
              <div className="treatmentPlanTitle" key={idx}>
                <a
                  className="prescribeTreatmentHeading"
                  onClick={() => getPrescribedPlanData(obj)}
                >
                  Prescribed Treatment Plan:{" "}{obj.plan_name}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </Scrollbars>
  );
}
