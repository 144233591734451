export const droppedFileAdapter = (file) => ({
  name: file.file.name,
  path: URL.createObjectURL(file.file),
  original: file.file,
});

export const apiFileAdapter = (businessInfo) => {
  if (!businessInfo) {
    return null;
  }

  const name = businessInfo.freeTaxableDocName;
  const path = businessInfo.freeTaxableDocUrl;

  return name && path ? { name, path, original: null } : null;
};

export const isApiFile = (file) => {
  return file?.original === null;
};
