import { useSendOtpMutation } from "../../api/phone/useSendOtpMutation";
import { extractApiError } from "../../utilities/api";
import { uiNotification } from "../../services/UINotificationService";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { phoneUtil } from "../../utilities/phone";

export function useSendOtp({ onSuccess, onError } = {}) {
  const { tCommon } = useAppTranslation.Common();

  const sendOtpMutation = useSendOtpMutation({
    onError: (e) => {
      const message = extractApiError(e);
      uiNotification.error(message);
      onError?.();
    },
    onSuccess: () => {
      uiNotification.success(tCommon("success.sendOtp"));
      onSuccess?.();
    },
  });

  const sendOtp = async (phone) => {
    if (!phoneUtil.isPossible(phoneUtil.compose(phone))) {
      return uiNotification.error(tCommon("error.invalidPhone"));
    }

    return await sendOtpMutation.mutateAsync({
      phone_number: phone,
    });
  };

  return {
    initiate: sendOtp,
    isLoading: sendOtpMutation.isLoading,
  };
}
