import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useUploadStripeDocument = (options = {}) => {
  return useMutation(
    (dto) => http.post(HTTP_ENDPOINTS.uploadStripeDocument(), dto),
    options,
  );
};
