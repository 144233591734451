import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../Utils/services.js";
import Loader from "../Common/Loader.js";
import { tCommon } from "../../i18n/useAppTranslation.js";
import { Empty } from "../../shared/Empty/Empty.js";
import useUpcomingAppointment from "../../api/queries/useUpcomingAppointment.js";
import { TABLE_SIZE } from "../../pages/ProviderRoom/ProviderRoom.consts.js";
import { LoadMoreObserver } from "../../shared/LoadMoreObserver/LoadMoreObserver.js";
import { uiNotification } from "../../services/UINotificationService.js";

const options = [
  { value: "", text: "appointments.upcomingAppointments.allAppointments" },
  { value: "Upcoming", text: "appointments.upcomingAppointments.booked" },
  { value: "Cancelled", text: "appointments.upcomingAppointments.cancelled" },
  { value: "No Show", text: "appointments.upcomingAppointments.noShow" },
];

const headers = [
  { class: "col-xs-1", text: "label.status" },
  { class: "col-xs-2", text: "label.serviceName" },
  { class: "col-xs-2", text: "label.providerName" },
  { class: "col-xs-2", text: "label.clinic" },
  { class: "col-xs-2", text: "label.notes" },
  { class: "col-xs-3", text: "label.date" },
];

const statusClassNames = {
  booked: "payment-Succeeded",
  canceled: "payment-transit",
  noshow: "payment-failed",
};

const UpcomingAppointments = (props) => {
  const backURLType = props.match.params.actionType
    ? props.match.params.actionType
    : "clients";

  const [status, setStatus] = useState("");

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useUpcomingAppointment({
      payload: {
        per_page: TABLE_SIZE,
        sort_order: "desc",
        status,
        client_id: props.match.params.clientID,
      },
      options: {
        staleTime: 0,
        onError: () => {
          uiNotification.error(tCommon("error.fallback"));
        },
      },
    });

  const upcomingAppointmentList = useMemo(() => {
    if (data?.pages) {
      const upcomingAppointmentList = data.pages
        .map((p) => p.upcomingAppointment)
        .reduce((r, p) => [...r, ...p], []);

      return upcomingAppointmentList;
    }
    return [];
  }, [data]);

  const openAppointment = (obj) => {
    if (obj?.status !== "noshow" && obj?.status !== "canceled" && obj.id) {
      window.open(`/appointment/view/${obj.id}`);
    }
  };

  const getServiceNames = (services) =>
    services
      ?.map((sobj) => sobj.service?.name)
      .filter(Boolean)
      .join(", ") || "";

  let returnTo =
    backURLType === "clients" && props.match.params.type
      ? `/${backURLType}/${props.match.params.type}/${props.match.params.clientID}/1`
      : `/${backURLType}` || "";

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <div className="juvly-section full-width m-t-15">
          <div className="juvly-container h-85 m-b-10">
            <div className="juvly-title no-margin d-flex justify-between">
              <span> {tCommon("label.upcomingAppointments")} </span>
              <div className="d-flex gap-24 min-width-180 ">
                <div className="setting-search-outer">
                  <select
                    className="setting-select-box font-14 m-r-20 p-l-15 p-b-5"
                    value={status}
                    onChange={(event) => setStatus(event.target.value)}
                  >
                    {options.map((option) => (
                      <option value={option.value} key={option.value}>
                        {tCommon(option.text)}
                      </option>
                    ))}
                  </select>
                </div>
                <Link to={returnTo} className="pull-right">
                  <img
                    src="../../../../images/close.png"
                    height="14px"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table-updated juvly-table table-min-width no-hover">
              <thead className="table-updated-thead">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      className={`${header.class} table-updated-th`}
                      key={index}
                    >
                      {tCommon(header.text)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="ajax_body">
                {upcomingAppointmentList?.length > 0 &&
                  upcomingAppointmentList.map((obj, idx) => {
                    const statusclassName = statusClassNames[obj.status] || "";
                    const serviceName = getServiceNames(
                      obj.appointment_services,
                    );
                    const className = ["noshow", "canceled"].includes(
                      obj.status,
                    )
                      ? "table-updated-tr"
                      : "table-updated-tr cursor-pointer";

                    return (
                      <tr
                        key={idx}
                        className={className}
                        onClick={() => openAppointment(obj)}
                      >
                        <td className="table-updated-td">
                          {obj.display_status && (
                            <div className={statusclassName}>
                              {capitalizeFirstLetter(obj.display_status)}
                            </div>
                          )}
                        </td>
                        <td className="table-updated-td">{serviceName}</td>
                        <td className="table-updated-td">
                          {obj.provider?.full_name}
                        </td>
                        <td className="table-updated-td">
                          {obj.clinic?.clinic_name || ""}
                        </td>
                        <td className="table-updated-td">
                          {obj.appointment_note?.appointment_notes || ""}
                        </td>
                        <td className="table-updated-td">
                          {`${obj?.format_date} ${obj.format_time}  to ${
                            obj?.appointment_endtime || ""
                          }`}
                        </td>
                      </tr>
                    );
                  })}

                <tr
                  className={
                    upcomingAppointmentList?.length === 0
                      ? "table-updated-tr"
                      : "table-updated-tr no-display"
                  }
                >
                  <td colSpan="6" className="text-center">
                    <Empty position={"center"} />
                  </td>
                </tr>
              </tbody>
            </table>

            {hasNextPage && (
              <LoadMoreObserver
                loadMore={fetchNextPage}
                isLoading={isFetchingNextPage}
              />
            )}
          </div>
        </div>
      </div>
      <Loader showLoader={isLoading} isFullWidth={false} />
    </div>
  );
};

export default UpcomingAppointments;
