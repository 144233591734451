import { useEffect } from "react";
import { dispatch } from "../../../../../../../store/store";
import { productPricingMember } from "../../../../../../../store/inventory/productPricingMember";
import {
  prepareMemberships,
  preparePrices,
} from "../../../../../../../store/inventory/productPricingMember/utilities";

const { actions } = productPricingMember;

export function useInit(initData, isInitialized) {
  const { clinics, membership_tiers, product } = initData || {
    clinics: [],
    membership_tiers: [],
    product: {},
  };

  useEffect(() => {
    if (!isInitialized) {
      dispatch(actions.setMemberships(prepareMemberships(membership_tiers)));

      const prices = [...(product?.product_price_per_clinic || [])];
      prices.reverse();

      dispatch(
        actions.setPrices(
          preparePrices({
            clinics,
            rawPrices: prices,
          }),
          prices,
        ),
      );

      dispatch(
        actions.setClinics(
          clinics.map((c) => ({
            id: c.id,
            name: c.clinic_name,
          })),
        ),
      );

      dispatch(actions.setInitialized(true));
    }
  }, [
    clinics,
    product?.product_price_per_clinic,
    isInitialized,
    membership_tiers,
  ]);
}
