import React from "react";
import { Header } from "./shared/Header/Header";
import classes from "./SalesGoals.module.scss";
import { Table } from "./shared/Table/Table";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { useGoals } from "./hooks/useGoals";
import { useDelete } from "./hooks/useDelete";
import { LayoutSettings } from "../../../boxes/LayoutSettings/LayoutSettings";

export function SettingsSalesGoals() {
  const { tSettings } = useAppTranslation.Settings();
  const { data, filter, order, clinicOptions } = useGoals();
  const del = useDelete();

  return (
    <LayoutSettings contentClassName={classes.root}>
      <Header filter={filter} clinicOptions={clinicOptions} />
      <Table data={data} order={order} del={del} />
      {del.isConfirmOpen && (
        <ConfirmModal
          isOpen
          onClose={del.declineConfirm}
          onCancel={del.declineConfirm}
          onConfirm={del.initiate}
        >
          {tSettings("salesGoals.confirm.delete")}
        </ConfirmModal>
      )}
    </LayoutSettings>
  );
}
