import { useClinicsQuery } from "../../../api/queries/useClinicsQuery";
import { uiNotification } from "../../../services/UINotificationService";

export function useApiClinics() {
  const { data, isFetching } = useClinicsQuery({
    staleTime: 0,
    onError: () => {
      uiNotification.error("Unable to retrieve clinics. Try again later");
    },
  });

  return {
    clinics: data || [],
    isClinicsFetching: isFetching,
  };
}
