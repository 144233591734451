import React, { Component } from "react";

class Commisions extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData
    ) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    return (
      <div className="table-responsive clients-table">
        <table className="table-updated setting-table table-min-width no-td-border no-hover">
          <thead className="table-updated-thead">
            {
              <tr>
                {this.props.reportType === "employee" && (
                  <th className="table-updated-th">
                    {this.state.reportData &&
                      this.state.reportData.report_header &&
                      this.state.reportData.report_header.user_name}
                  </th>
                )}
                {this.props.reportType === "clinic_name" && (
                  <th className="table-updated-th">
                    {this.state.reportData &&
                      this.state.reportData.report_header &&
                      this.state.reportData.report_header.clinic_name}
                  </th>
                )}
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.pos_invoice_sub_total}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.emp_total_net_sales}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.payroll_amount}
                </th>
                {this.props.reportType === "employee" && (
                  <th className="table-updated-th">
                    {this.state.reportData &&
                      this.state.reportData.report_header &&
                      this.state.reportData.report_header.commission_percentage}
                  </th>
                )}
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.commission_amount}
                </th>
              </tr>
            }
          </thead>
          <tbody className="ajax_body">
            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length > 0 &&
              this.state.reportData.report_data.map((obj, idx) => {
                return (
                  <tr key={idx} className="table-updated-tr">
                    {this.props.reportType === "employee" && (
                      <td className="table-updated-td">{obj.user_name}</td>
                    )}
                    {this.props.reportType === "clinic_name" && (
                      <td className="table-updated-td">{obj.clinic_name}</td>
                    )}
                    <td className="table-updated-td">
                      {obj.pos_invoice_sub_total}
                    </td>
                    <td className="table-updated-td">
                      {obj.emp_total_net_sales}
                    </td>
                    <td className="table-updated-td">{obj.payroll_amount}</td>
                    {this.props.reportType === "employee" && (
                      <td className="table-updated-td">
                        {obj.commission_percentage}
                      </td>
                    )}
                    <td className="table-updated-td">
                      {obj.commission_amount}
                    </td>
                  </tr>
                );
              })}

            {this.state.reportData &&
              this.state.reportData.report_data &&
              this.state.reportData.report_data.length === 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan="10"
                  >
                    {this.state.languageData.bi_no_record_found}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Commisions;
