import React from "react";

export const PencilBoxIcon = ({ width, height }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H10C10.5523 4 11 4.44772 11 5C11 5.55228 10.5523 6 10 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H17C17.2652 22 17.5196 21.8946 17.7071 21.7071C17.8946 21.5196 18 21.2652 18 21V14C18 13.4477 18.4477 13 19 13C19.5523 13 20 13.4477 20 14V21C20 21.7957 19.6839 22.5587 19.1213 23.1213C18.5587 23.6839 17.7957 24 17 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.31607 22.5587 0 21.7957 0 21V7C0 6.20435 0.31607 5.44129 0.87868 4.87868Z"
        fill="currentColor"
      />
      <path
        d="M17.125 3.58594L20.6568 7.1177L12.9072 14.8673L8.73305 15.4411L9.37544 11.3355L17.125 3.58594Z"
        fill="currentColor"
      />
      <path
        d="M19.2967 1.41421C20.0778 0.633166 21.3441 0.633165 22.1252 1.41421L22.8295 2.11859C23.6106 2.89964 23.6106 4.16597 22.8295 4.94702L21.6744 6.10212L18.1416 2.56931L19.2967 1.41421Z"
        fill="currentColor"
      />
    </svg>
  );
};
