/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import {
  fadeInLeft,
  fadeOutRight,
  fadeIn,
  fadeOut,
  fadeInRight,
  fadeOutLeft,
} from "react-animations";
import { withRouter } from "react-router";
import Radium, { StyleRoot } from "radium";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchInventoryData,
  exportProducts,
  emptyInventoryReducer,
  fetchCategories,
  deactivateAllCat,
  fetchCategoriesDataID,
  fetchClinics,
  updateCategories,
  createCategory,
  exportData,
} from "../../../Actions/Inventory/inventoryActions.js";
import {
  checkIfPermissionAllowed,
  autoScrolling,
  isPositiveNumber,
} from "../../../Utils/services";
import Sidebar from "../InventorySidebar";
import Loader from "../../Common/Loader";
import { getPrecision, toFixed } from "../../../utilities/general.js";
import { tCommon } from "../../../i18n/useAppTranslation.js";
import { inventoryProductsFilter } from "../../../store/inventoryFilter";
import { dispatch } from "../../../store/store";
import { uiNotification } from "../../../services/UINotificationService.js";

const styles = {
  fadeInLeft: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
  },
  fadeInRight: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeOut: {
    animation: "x 1s",
    display: "none",
    opacity: 0,
    animationName: Radium.keyframes(fadeOut, "fadeOut"),
  },
  fadeOutRight: {
    transition: "visibility 0s linear 300ms, opacity 300ms, height 1s",
    animation: "x 1s",
    visibility: "hidden",
    opacity: 0,
    height: 0,
    animationName: Radium.keyframes(fadeOutRight, "fadeOutRight"),
  },
  fadeOutLeft: {
    transition: "visibility 0s linear 300ms, opacity 300ms, height 1s",
    animation: "x 1s",
    visibility: "hidden",
    opacity: 0,
    height: 0,
    animationName: Radium.keyframes(fadeOutLeft, "fadeOutLeft"),
  },

  fadeOutForm: { display: "none" },
};
var timeOut;

localStorage.removeItem("iapfCategory");
localStorage.removeItem("iapfStock");
localStorage.removeItem("iapfSortBy");
localStorage.removeItem("iapfSortOrder");

const { actions, selectors } = inventoryProductsFilter;

class InventoryProductsActive extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const iapfStock = localStorage.getItem("iapfStock");
    const iapfSortBy = localStorage.getItem("iapfSortBy");
    const iapfSortOrder = localStorage.getItem("iapfSortOrder");
    this.state = {
      loadMore: true,
      startFresh: true,
      showLoader: false,
      page: 1,
      pagesize: 20,
      is_custom_rule_tax_yes: "row",
      is_custom_rule_tax_no: "row no-display",
      term: props.match.params.searchTerm ? props.match.params.searchTerm : "",
      hasMoreItems: true,
      next_page_url: "",
      searchFunction: "",
      user_changed: false,
      tabClicked: false,
      InventoryData: [],
      defaultImage: "inventory-product-img",
      data: [],
      childCheck: false,
      action: "active",
      sortorder: iapfSortOrder ? iapfSortOrder : "asc",
      sortby: iapfSortBy ? iapfSortBy : "product_name",
      scopes: "category",
      selected: [],
      selectAll: 0,
      filterValue: false,
      show_below_stock: iapfStock ? 1 : 0,
      showListCatPop: false,
      categoryData: [],
      showCategoryList: false,
      showStatusList: false,
      filter_by_category_id: "",
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      showLoadingText: false,
      timeStamp: new Date(),
      apiInventoryData: {},
      selectedStatusTerm: "",
      selectedCategoryTerm: "",
      searchCategoryTerm: "",
      categoryList: [],
      inactivateModal: false,
      categoryName: "",
      categoryActive: false,
      is_custom_rule_tax: false,
      clinicsList: [],
      tax_percentage: "",
      categoryNameError: false,
      fadeInForm: false,
      fadeOutForm: { display: "none" },
      fadeInList: {},
      showActiveOnly: true,
      overFlowClass: "inventryCategoryList",
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
    if (
      document.body.scrollTop != 0 ||
      document.documentElement.scrollTop != 0
    ) {
      window.scrollBy(0, -50);
      timeOut = setTimeout("scrollToTop()", 10);
    } else clearTimeout(timeOut);
  }

  handleTaxOnBlur = (event) => {
    this.setState({
      [event.target.name]: Number(event.target.value).toFixed(4),
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState(
      {
        [event.target.name]: value,
      },
      () => {
        if (target.name == "showActiveOnly") {
          let formData = { params: {} };
          formData.params.term = this.state.searchCategoryTerm;
          formData.params.category_status = value ? 1 : 0;
          this.setState({ showLoader: true });
          this.props.fetchCategories(formData);
        }
      },
    );

    if (target.name === "show_below_stock") {
      localStorage.setItem("iapfStock", value ? 1 : 0);
    }
  };

  componentDidUpdate = (_, state) => {
    if (this.state.showListCatPop || this.state.filterValue) {
      //document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = "";
    }
    if (state.fadeInForm != this.state.fadeInForm) {
      setTimeout(() => {
        this.setState({
          overFlowClass: "inventryCategoryList overflow-hidden",
        });
      }, 500);
    } else if (
      state.fadeInForm == false &&
      this.state.fadeInForm == false &&
      this.state.overFlowClass == "inventryCategoryList overflow-hidden"
    ) {
      this.setState({ overFlowClass: "inventryCategoryList" });
    }
    if (this.state.action != "active") {
      let formData = {
        params: {
          page: 1,
          pagesize: this.state.pagesize,
          sortorder: this.state.sortorder,
          sortby: this.state.sortby,
          term: this.state.term,
          action: "active",
        },
      };
      if (
        this.state.filter_by_category_id != null &&
        this.state.filter_by_category_id != "" &&
        this.state.filter_by_category_id != undefined
      ) {
        formData.params.filter_by_category_id =
          this.state.filter_by_category_id;
      }
      this.setState({
        action: this.props.match.params.statusId,
        InventoryData: [],
        showLoader: true,
      });
      autoScrolling(true);
      this.props.fetchInventoryData(this.props.match.params.statusId, formData);
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let formData = {
      params: {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder,
        sortby: this.state.sortby,
        term: this.state.term,
        action: this.state.action,
      },
    };

    if (this.state.filter_by_category_id) {
      formData.filter_by_category_id = this.state.filter_by_category_id;
    }

    this.setState({
      action: "active",
    });
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }

    if (this.props.inventoryProductsFilter) {
      formData.params.filter_by_category_id =
        this.props.inventoryProductsFilter;
      this.setState({
        filter_by_category_id: this.props.inventoryProductsFilter,
      });
    }

    this.setState({ showLoader: true });
    if (
      document.body.scrollTop != 0 ||
      document.documentElement.scrollTop != 0
    ) {
      window.scrollBy(0, -50);
      timeOut = setTimeout("scrollToTop()", 10);
    } else clearTimeout(timeOut);
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    localStorage.setItem("showLoader", true);
    dispatch(
      actions.setInventoryProductsFilter(this.state.filter_by_category_id),
    );
    document.body.style.overflow = "";
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        sortby: "product_name",
        term: this.state.term,
        show_below_stock: this.state.show_below_stock == false ? 0 : 1,
        action: this.state.action,
      },
    };
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortorder: "asc",
      sortby: "product_name",
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      InventoryData: [],
      showLoader: true,
      filterValue: "false",
    });
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
    localStorage.setItem("iapfSortBy", "product_name");
    localStorage.setItem("iapfSortOrder", "asc");
  };

  onSort = (sortby) => {
    let sortorder =
      sortby == this.state.sortby
        ? this.state.sortorder === "asc"
          ? "desc"
          : "asc"
        : "asc";
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term,
        show_below_stock: this.state.show_below_stock == false ? 0 : 1,
      },
    };
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      InventoryData: [],
    });
    localStorage.setItem("sortOnly", true);
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
    localStorage.setItem("iapfSortBy", sortby);
    localStorage.setItem("iapfSortOrder", sortorder);
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: false,
        showLoadingText: true,
      });
      let formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortorder:
            this.state.sortorder && this.state.sortorder === "asc"
              ? "asc"
              : this.state.sortorder == "desc"
              ? "desc"
              : "asc",
          sortby: this.state.sortby,
          term: this.state.term,
          action: this.state.action,
        },
      };
      if (
        this.state.filter_by_category_id != null &&
        this.state.filter_by_category_id != "" &&
        this.state.filter_by_category_id != undefined
      ) {
        formData.params.filter_by_category_id =
          this.state.filter_by_category_id;
      }
      this.setState({ showLoader: true });
      autoScrolling(true);
      this.props.fetchInventoryData(this.state.action, formData);
    }
  };

  hideServicesCat = () => {
    this.setState({ showListCatPop: false, fadeInForm: false });
    document.body.style.overflow = "";
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.exportCsvData != undefined &&
      prevState.timeStamp != nextProps.timeStamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        localStorage.setItem("showLoader", true);
        window.open(
          process.env.REACT_APP_API_URL +
            "download-data/" +
            nextProps.exportCsvData.file,
          "_blank",
        );
        return { showLoader: false };
      }
      nextProps.emptyInventoryReducer();
    } else if (
      nextProps.showLoader != undefined &&
      nextProps.showLoader == false
    ) {
      return { showLoader: false };
    } else if (
      nextProps.InventoryData != undefined &&
      nextProps.InventoryData !== prevState.apiInventoryData &&
      nextProps.inventoryTimeStamp != prevState.inventoryTimeStamp &&
      (nextProps.InventoryData.data.next_page_url !== prevState.next_page_url ||
        nextProps.InventoryData.action != prevState.action)
    ) {
      document.body.style.overflow = "";
      let returnState = {};
      returnState.apiInventoryData = nextProps.InventoryData;
      returnState.categoryData = nextProps.InventoryData.category_list;
      if (localStorage.getItem("showLoader") == "false") {
        if (prevState.tabClicked === true) {
          return {
            tabClicked: false,
            InventoryData: [],
          };
        }
        if (
          prevState.next_page_url == null &&
          nextProps.InventoryData.action == prevState.action
        ) {
          autoScrolling(false);
          return (returnState.next_page_url = null);
        }

        if (prevState.InventoryData.length == 0) {
          if (localStorage.getItem("sortOnly") == "false") {
            returnState.InventoryData = nextProps.InventoryData.data;
            returnState.CategoryData = nextProps.InventoryData.category_list;
            if (nextProps.InventoryData.next_page_url != null) {
              returnState.page = prevState.page + 1;
            } else {
              returnState.next_page_url = nextProps.InventoryData.next_page_url;
              returnState.showLoader = false;
            }
            returnState.startFresh = false;
            returnState.showLoader = false;
            returnState.showLoadingText = false;
          } else {
            localStorage.setItem("sortOnly", false);
          }
        } else if (
          prevState.InventoryData != nextProps.InventoryData.data &&
          prevState.InventoryData.length != 0
        ) {
          if (prevState.tabClicked === false) {
            returnState.InventoryData = [
              ...prevState.InventoryData,
              ...nextProps.InventoryData.data,
            ];
            returnState.selectAll = 0;
            returnState.showLoader = false;
          } else {
            returnState.tabClicked = false;
            returnState.showLoader = false;
          }
          returnState.total = nextProps.InventoryData.total;
          returnState.page = prevState.page + 1;
          returnState.next_page_url = nextProps.InventoryData.next_page_url;
          returnState.CategoryData = nextProps.InventoryData.category_list;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
        }

        autoScrolling(false);
        return returnState;
      }
    }

    if (
      nextProps.categoryList != undefined &&
      nextProps.categoryTimeStamp != prevState.categoryTimeStamp
    ) {
      document.body.style.overflow = "hidden";
      let returnState = {};
      returnState.categoryList = nextProps.categoryList;
      returnState.showLoader = false;
      if (prevState.fadeInForm) {
        returnState.fadeInForm = false;
        returnState.fadeOutForm = styles.fadeOutLeft;
        returnState.fadeInList = styles.fadeInRight;
      }
      returnState.categoryTimeStamp = nextProps.categoryTimeStamp;
      returnState.showListCatPop = true;
      returnState.categoryName = "";
      returnState.categoryActive = false;
      returnState.is_custom_rule_tax = false;
      prevState.clinicsList.length != undefined &&
        prevState.clinicsList.map((obj) => {
          returnState["tax_percentage-" + obj.id] = "0.0000";
        });
      return returnState;
    }

    if (nextProps.deactivatedTime != prevState.deactivatedTime) {
      document.body.style.overflow = "";
      let returnState = {};
      returnState.showLoader = false;
      returnState.inactivateModal = false;
      returnState.showListCatPop = false;
      return returnState;
    }

    if (
      nextProps.clinicsList != undefined &&
      nextProps.fetchClinicsTimestamp != prevState.fetchClinicsTimestamp
    ) {
      let returnState = {};
      returnState.clinicsList = nextProps.clinicsList.data;
      returnState.showLoader = false;

      returnState.fetchClinicsTimestamp = nextProps.fetchClinicsTimestamp;
      let clinicTax = nextProps.clinicsList.data;
      clinicTax.length != undefined &&
        clinicTax.map((obj) => {
          returnState["tax_percentage-" + obj.id] = "0.0000";
        });
      return returnState;
    }

    if (
      nextProps.editCategoryData != undefined &&
      nextProps.editCategoryDataTimestamp != prevState.editCategoryDataTimestamp
    ) {
      document.body.style.overflow = "";
      let returnState = {};
      returnState.categoryName =
        nextProps.editCategoryData.product_category.category_name;
      returnState.categoryActive =
        nextProps.editCategoryData.product_category.cat_status;
      returnState.is_custom_rule_tax =
        nextProps.editCategoryData.product_category.tax;
      returnState.cat_id = nextProps.editCategoryData.product_category.id;
      returnState.showLoader = false;
      returnState.fadeInForm = true;
      returnState.editCategoryDataTimestamp =
        nextProps.editCategoryDataTimestamp;
      let clinicTax =
        nextProps.editCategoryData.product_category.category_clinic_tax;
      clinicTax.length != undefined &&
        clinicTax.map((obj) => {
          returnState["tax_percentage-" + obj.clinic_id] = parseFloat(
            Number(obj.tax_percentage).toFixed(4),
          );
        });
      return returnState;
    }
    return null;
  }

  inventoryEdit = (pid) => {
    if (checkIfPermissionAllowed("edit-product") === true) {
      return (
        <div>{this.props.history.push(`/inventory/product/edit/${pid}`)}</div>
      );
    }

    if (checkIfPermissionAllowed("manage-stocks") === true) {
      return (
        <div>
          {this.props.history.push(`/inventory/product/edit/${pid}/stock/1`)}
        </div>
      );
    }
  };

  onFilter = () => {
    this.setState({ filterValue: true }, () => {
      document.body.style.overflow = "hidden";
    });
  };

  onReset = () => {
    localStorage.setItem("sortOnly", true);
    dispatch(actions.removeInventoryProductsFilter());
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        sortby: "product_name",
        term: "",
        show_below_stock: 0,
        action: this.state.action,
      },
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortorder: "asc",
      sortby: "product_name",
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      InventoryData: [],
      showLoader: true,
      filterValue: "false",
      term: "",
      show_below_stock: 0,
      filter_by_category_id: "",
    });
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
    localStorage.removeItem("iapfCategory");
    localStorage.removeItem("iapfStock");
    localStorage.removeItem("iapfSortBy");
    localStorage.removeItem("iapfSortOrder");
    document.body.style.overflow = "";
  };

  exportProducts = (mode) => {
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        sortby: "product_name",
        term: this.state.term,
        show_below_stock: this.state.show_below_stock == false ? 0 : 1,
        action: this.state.action,
      },
    };
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }

    localStorage.setItem("showLoader", true);
    this.setState({
      showExportOptions: false,
      showStatusOptions: false,
      showLoader: true,
    });
    formData.params.product_status = "active";

    this.props.exportProducts(formData, mode);
  };
  setCategoryShow = () => {
    let value = this.state.showCategoryList;
    this.setState({ showCategoryList: !value });
  };
  selectCategory = (term) => {
    this.setState({
      selectedCategoryTerm: term.category_name,
      selectedCategoryId: term.id,
      showLoader: true,
    });
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        sortorder: this.state.sortorder,
        term: this.state.term,
        show_below_stock: this.state.show_below_stock == false ? 0 : 1,
        action: "active",
        filter_by_category_id: term.id,
      },
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      InventoryData: [],
    });
    localStorage.setItem("sortOnly", true);
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
  };
  showCatListPopup = () => {
    this.setState({
      showLoader: true,
      cat_id: "",
      categoryName: "",
      categoryActive: false,
      is_custom_rule_tax: false,
    });
    let formData = { params: {} };
    formData.params.term = this.state.searchCategoryTerm;
    formData.params.category_status = this.state.showActiveOnly ? 1 : 0;
    this.props.fetchCategories(formData);
    this.props.fetchClinics();
  };

  handleCategorySearch = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true });
    let formData = { params: {} };
    formData.params.term = this.state.searchCategoryTerm;
    formData.params.category_status = this.state.showActiveOnly ? 1 : 0;
    this.props.fetchCategories(formData);
  };
  deactiveCategories = () => {
    this.setState({ showLoader: true });
    this.props.deactivateAllCat();
  };

  handleAddCategorySubmit = (event) => {
    let error = false;
    let flag = false;
    let checkVal = false;
    let errorState = {};
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    let tax_rules = [];

    this.setState({
      categoryNameError: false,
    });

    if (this.state.is_custom_rule_tax) {
      for (let x in this.state) {
        let taxNew = x.split("tax_percentage-")[1];
        if (x.startsWith("tax_percentage-")) {
          if (
            this.state[x] === "" ||
            this.state[x] === undefined ||
            this.state[x] === null ||
            !isPositiveNumber(this.state[x], null, null, 5)
          ) {
            errorState["q-class-" + taxNew] = false;
            flag = true;
            checkVal = true;
          } else {
            tax_rules.push({
              clinic_id: taxNew,
              tax_percentage: this.state[x],
            });
            errorState["q-class-" + taxNew] = true;
            checkVal = true;
          }
        }
      }
    }

    this.setState(errorState);
    if (this.state.is_custom_rule_tax && !checkVal) {
      uiNotification.error(this.state.globalLang["tax_rules_is_required"]);
      return false;
    }
    if (flag) {
      return false;
    }

    if (
      typeof this.state.categoryName === undefined ||
      this.state.categoryName === null ||
      this.state.categoryName === ""
    ) {
      this.setState({
        categoryNameError: true,
      });
      error = true;
    }

    if (error === true) {
      return;
    }

    this.setState({ showLoader: true, showActiveOnly: true });
    let formData = {
      category_name: this.state.categoryName,
      is_custom_rule_tax: this.state.is_custom_rule_tax == true ? 1 : 0,
      tax: this.state.is_custom_rule_tax == true ? 1 : 0,
      cat_status: this.state.categoryActive == true ? 1 : 0,
      tax_percentage: this.state.tax_percentage,
      tax_rules: tax_rules,
    };

    if (this.state.cat_id) {
      this.props.updateCategories(formData, this.state.cat_id);
    } else {
      this.props.createCategory(formData);
    }
  };

  getEditCatData = (data) => {
    this.setState({ showLoader: true });
    this.props.fetchCategoriesDataID(data.id);
  };

  export = (mode) => {
    let formData = {
      params: {
        sortorder: "asc",
        sortby: "category_name",
        term: this.state.searchCategoryTerm,
      },
    };
    localStorage.setItem("showLoader", true);
    this.setState({
      showExportOptions: false,
      showStatusOptions: false,
      showLoader: true,
    });
    if (this.state.category_status != "all") {
      formData.params.category_status = this.state.category_status;
    }

    this.props.exportData(formData, mode);
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="">
            <div className="setting-setion m-b-10 auto-height">
              <div className="membership-title">
                <span className="cursor-pointer">Your Inventory</span>
              </div>
            </div>
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <form
                  onSubmit={this.handleSubmit}
                  className="searchForm settingAddProductOuter yourInventorySearch m-l-0"
                >
                  <span className="searchOuter">
                    <i className="fa fa-search search-icon" />
                    <input
                      className="setting-search-input search-key"
                      placeholder={
                        this.state.globalLang
                          .inventory_reconciliation_search_product
                      }
                      name="term"
                      autoComplete="off"
                      value={this.state.term}
                      onChange={this.handleInputChange}
                    />
                  </span>
                </form>
                <a
                  name="filter"
                  className="new-line-btn no-width"
                  onClick={this.onFilter}
                >
                  {this.state.inventoryLang.inventory_filter_btn_text}
                </a>
                <a
                  name="reset"
                  className="new-rest-btn no-width"
                  onClick={this.onReset}
                >
                  {this.state.inventoryLang.inventory_Reset}
                </a>
                <div className="memberRightActions invenoryRightButton">
                  {checkIfPermissionAllowed("add-product") === true && (
                    <div>
                      <a
                        className="new-blue-btn  m-l-5"
                        onClick={this.showCatListPopup}
                      >
                        Add/View Categories
                      </a>
                      <Link
                        to="/inventory/product/add"
                        className="new-blue-btn  m-l-5"
                      >
                        {this.state.inventoryLang.inventory_Add_Product}
                      </Link>
                      <div className="option-dropDown pull-right m-l-5 churnFilterExport">
                        <div className="dropdown pull-left show">
                          <button
                            className="btn btn-default dropdown-toggle"
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {"Options"}
                            <i className="fas fa-angle-down"></i>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="easy-link no-padding"
                                onClick={this.exportProducts.bind(this, "csv")}
                              >
                                Download CSV
                              </a>
                            </li>
                            <li>
                              <a
                                className="easy-link no-padding"
                                onClick={this.exportProducts.bind(this, "xls")}
                              >
                                Download Excel
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-responsive m-h-300">
                <table className="table-updated setting-table no-td-border">
                  <thead className="table-updated-thead">
                    <tr>
                      <th
                        className="col-xs-3 table-updated-th cursor-pointer sorting"
                        onClick={() => this.onSort("product_name")}
                        data-sort="product_name"
                        data-order="DESC"
                      >
                        {this.state.inventoryLang.inventory_Product_Name}
                        <i
                          className={
                            this.state.sortorder === "asc" &&
                            this.state.sortby === "product_name"
                              ? "blue-gray"
                              : this.state.sortorder === "desc" &&
                                this.state.sortby === "product_name"
                              ? "gray-blue"
                              : "gray-gray"
                          }
                        />
                      </th>
                      <th
                        className="col-xs-2 table-updated-th cursor-pointer sorting"
                        onClick={() => this.onSort("inventory_type")}
                        data-sort="inventory_type"
                        data-order="DESC"
                      >
                        Type
                        <i
                          className={
                            this.state.sortorder === "asc" &&
                            this.state.sortby === "inventory_type"
                              ? "blue-gray"
                              : this.state.sortorder === "desc" &&
                                this.state.sortby === "inventory_type"
                              ? "gray-blue"
                              : "gray-gray"
                          }
                        />
                      </th>
                      <th className="col-xs-2 table-updated-th">Status</th>
                      <th
                        className="col-xs-2 table-updated-th  cursor-pointer sorting"
                        onClick={() => this.onSort("category_name")}
                        data-sort="category_name"
                        data-order="DESC"
                      >
                        Category
                        <i
                          className={
                            this.state.sortorder === "asc" &&
                            this.state.sortby === "category_name"
                              ? "blue-gray"
                              : this.state.sortorder === "desc" &&
                                this.state.sortby === "category_name"
                              ? "gray-blue"
                              : "gray-gray"
                          }
                        />
                      </th>
                      <th className="col-xs-3 table-updated-th  cursor-pointer ">
                        Product Stock
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.InventoryData &&
                      this.state.InventoryData.map((obj, idx) => {
                        let statusId = this.state.action;
                        return (
                          <tr
                            className={`table-updated-tr ${
                              checkIfPermissionAllowed("edit-product") ===
                                true ||
                              checkIfPermissionAllowed("manage-stocks") === true
                                ? ""
                                : "cursor-default"
                            } `}
                            onClick={
                              obj &&
                              this.inventoryEdit.bind(
                                this,
                                obj.id,
                                statusId,
                                obj.category && obj.category.id
                                  ? obj.category.id
                                  : 0,
                              )
                            }
                            key={idx}
                          >
                            <td className="table-updated-td text-ellipsis inentory-product-name">
                              {obj.product_name ? obj.product_name : ""}
                            </td>
                            <td className="table-updated-td text-ellipsis">
                              {tCommon(`inventoryType.${obj.inventory_type}`)}
                            </td>
                            <td className="table-updated-td text-ellipsis">
                              {obj.is_product_active &&
                              obj.is_product_active === 1
                                ? "Active"
                                : "Inactive"}
                            </td>
                            <td className="table-updated-td text-ellipsis">
                              {obj.category ? obj.category.category_name : ""}
                            </td>
                            <td className="table-updated-td text-ellipsis">
                              {toFixed(
                                Number(obj.total_available_units),
                                getPrecision(obj.count_units_by) || 1,
                              ) +
                                " " +
                                this.state.inventoryLang.inventory_in_stock}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {this.state.showLoader === false && (
                  <div
                    className={
                      this.state.InventoryData != undefined &&
                      this.state.InventoryData.length == 0
                        ? "no-record"
                        : "no-record no-display"
                    }
                  >
                    <div
                      className=""
                      style={{
                        float: "left",
                        width: "100%",
                        fontSize: "13px",
                        textAlign: "center",
                        marginTop: "0px",
                        padding: "0px",
                      }}
                    >
                      {this.state.inventoryLang.inventory_No_record_found}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                this.state.showLoadingText
                  ? "loading-please-wait no-margin-top"
                  : "loading-please-wait no-margin-top no-display "
              }
            >
              {this.state.loading_please_wait_text}
            </div>
          </div>
          {/* ********************MODAL TO SHOW CATEGORIES**************** */}
          <div
            className={
              this.state.showListCatPop
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="small-popup-outer appointment-detail-main displayBlock extra-large-popup">
              <div className="small-popup-header removeBackgroundColor">
                <a className="small-cross" onClick={this.hideServicesCat}>
                  ×
                </a>
                <div className="backManageCategory">
                  <a
                    className={
                      this.state.fadeInForm
                        ? "easy-link m-t-15 no-padding"
                        : "no-display"
                    }
                    onClick={() =>
                      this.setState({
                        fadeInForm: false,
                        fadeOutForm: styles.fadeOutLeft,
                        fadeInList: styles.fadeInRight,
                      })
                    }
                  >
                    <i className="fa fa-angle-left"></i> Go Back
                  </a>
                </div>
                <div className="popup-name  p-t-15">
                  <h4>Manage Categories</h4>
                </div>
              </div>
              <div className="small-popup-content scroll-y inventryCategoryOuter manageCategoryPopupHeight">
                <div
                  className="juvly-container no-padding-bottom"
                  id="categoriesTable"
                >
                  <StyleRoot>
                    <div
                      className="prescription-content row"
                      style={
                        this.state.fadeInForm
                          ? styles.fadeInLeft
                          : this.state.fadeOutForm
                      }
                    >
                      <div className="col-sm-7 col-xs-12 m-b-20">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            Category Name
                            <span className="setting-require">*</span>
                          </div>
                          <div
                            className={
                              this.state.categoryNameError
                                ? "newInputField field_error"
                                : "newInputField"
                            }
                          >
                            <input
                              className=""
                              type="text"
                              name="categoryName"
                              autoComplete="off"
                              value={this.state.categoryName}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5 col-xs-12 manageCategoryProductActive">
                        <div className="newInputFileldOuter m-t-25 ">
                          <div className="switch-accordian-row " id="book">
                            Product Category Active
                            <label className="setting-switch pull-left m-r-10">
                              <input
                                type="checkbox"
                                id="app_booking"
                                onChange={this.handleInputChange}
                                name="categoryActive"
                                checked={
                                  this.state.categoryActive ? "checked" : false
                                }
                                className="setting-custom-switch-input"
                              />
                              <span className="setting-slider green-switch" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="switch-accordian-outer">
                        <div
                          className="switch-accordian-row custom-txt-rule-left-padding"
                          id="book"
                        >
                          <h5>Custom tax rule (In percentage)</h5>
                          <label className="setting-switch pull-right">
                            <input
                              type="checkbox"
                              name="is_custom_rule_tax"
                              className="setting-custom-switch-input"
                              checked={
                                this.state.is_custom_rule_tax
                                  ? "checked"
                                  : false
                              }
                              value={this.state.is_custom_rule_tax}
                              autoComplete="off"
                              onChange={this.handleInputChange}
                            />
                            <span className="setting-slider" />
                          </label>
                        </div>
                        <StyleRoot>
                          <div
                            className={
                              this.state.is_custom_rule_tax == true
                                ? "setting-container-2"
                                : ""
                            }
                            id="Appointment_Booking-form-title"
                          >
                            <div
                              style={
                                this.state.is_custom_rule_tax == true
                                  ? styles.fadeIn
                                  : styles.fadeOut
                              }
                              className="row"
                            >
                              {this.state.clinicsList !== undefined &&
                                this.state.clinicsList.map((obj, idx) => {
                                  return (
                                    <div
                                      className="col-xs-12 col-sm-6 col-md-4 col-lg-6"
                                      key={idx}
                                    >
                                      <div className="newInputFileldOuter">
                                        <div className="newInputLabel">
                                          {obj.clinic_name
                                            ? obj.clinic_name
                                            : ""}
                                        </div>
                                        <input
                                          className={
                                            this.state["q-class-" + obj.id] ||
                                            this.state["q-class-" + obj.id] ==
                                              undefined
                                              ? "newInputField"
                                              : "newInputField  field-error"
                                          }
                                          name={"tax_percentage-" + obj.id}
                                          type="number"
                                          min={0}
                                          value={
                                            this.state[
                                              "tax_percentage-" + obj.id
                                            ]
                                          }
                                          placeholder="0.0000"
                                          autoComplete="off"
                                          onChange={this.handleInputChange}
                                          onBlur={this.handleTaxOnBlur}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </StyleRoot>
                      </div>
                      <div className="col-xs-12 text-left m-b-20">
                        <button
                          className="new-blue-btn-2 m-r-20"
                          onClick={this.handleAddCategorySubmit}
                        >
                          Save
                        </button>
                        <button
                          className="new-blue-btn-2"
                          onClick={() =>
                            this.setState({
                              fadeInForm: false,
                              fadeOutForm: styles.fadeOutLeft,
                              fadeInList: styles.fadeInRight,
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </StyleRoot>
                  <StyleRoot>
                    <div
                      className={this.state.overFlowClass}
                      style={
                        this.state.fadeInForm
                          ? styles.fadeOutRight
                          : this.state.fadeInList
                      }
                    >
                      <div className="setting-search-outer no-padding m-t-20 manageCateSearchOuter">
                        <form onSubmit={this.handleCategorySearch}>
                          <div className="search-bg col-xs-4">
                            <i className="fas fa-search" />
                            <input
                              className="setting-search-input search-key"
                              autoComplete="off"
                              name="searchCategoryTerm"
                              placeholder={"Search for Category"}
                              value={this.state.searchCategoryTerm}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </form>
                        <div className="col-sm-4 col-xs-12 p-r-0 manageCategoryShowActive">
                          <div className="newInputFileldOuter">
                            <div className="switch-accordian-row " id="book">
                              Show Active Only
                              <label className="setting-switch pull-left m-r-10">
                                <input
                                  type="checkbox"
                                  id="app_booking"
                                  onChange={this.handleInputChange}
                                  name="showActiveOnly"
                                  checked={
                                    this.state.showActiveOnly
                                      ? "checked"
                                      : false
                                  }
                                  className="setting-custom-switch-input"
                                />
                                <span className="setting-slider green-switch" />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="option-dropDown pull-right m-l-5 churnFilterExport">
                          <div className="dropdown pull-left show">
                            <button
                              className="btn btn-default dropdown-toggle"
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {"Export"}
                              <i className="fas fa-angle-down"></i>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  onClick={this.export.bind(this, "csv")}
                                  name="expType"
                                >
                                  Export as CSV
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={this.export.bind(this, "xls")}
                                  name="expoType"
                                >
                                  Export as Excel
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <button
                          className="btn new-blue-btn m-l-0 pull-right"
                          onClick={() =>
                            this.setState({
                              fadeInForm: true,
                              categoryName: "",
                              categoryActive: false,
                              is_custom_rule_tax: false,
                            })
                          }
                        >
                          Add Category
                        </button>
                      </div>

                      <div className="table-responsive m-b-20">
                        <table className="table-updated setting-table no-td-border">
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-3 table-updated-th">
                                Category Name
                              </th>
                              <th className="col-xs-3 table-updated-th">
                                Status
                              </th>
                              <th className="col-xs-3 table-updated-th">Tax</th>
                              <th className="col-xs-3 table-updated-th">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.categoryList.length > 0 &&
                              this.state.categoryList.map((obj) => {
                                return (
                                  <tr
                                    className="table-updated-tr vertical-align-top"
                                    key={obj.id}
                                  >
                                    <td className="table-updated-td text-ellipsis inentory-product-name">
                                      {obj.category_name
                                        ? obj.category_name
                                        : ""}
                                    </td>
                                    <td className="table-updated-td text-ellipsis inentory-product-name">
                                      {obj.cat_status == 1
                                        ? "Active"
                                        : "Inactive"}
                                    </td>
                                    <td className="table-updated-td text-ellipsis inentory-product-name">
                                      {
                                        <ul className="no-list">
                                          {obj.category_clinic_tax &&
                                            obj.category_clinic_tax.length >
                                              0 &&
                                            obj.category_clinic_tax.map(
                                              (clinic, i) => {
                                                return (
                                                  <li key={i} className="m-b-5">
                                                    <b>
                                                      {clinic.clinic &&
                                                        clinic.clinic
                                                          .clinic_name}
                                                    </b>{" "}
                                                    -{" "}
                                                    {parseFloat(
                                                      Number(
                                                        clinic.tax_percentage,
                                                      ).toFixed(4),
                                                    )}
                                                    %
                                                  </li>
                                                );
                                              },
                                            )}
                                          {obj.category_clinic_tax &&
                                            obj.category_clinic_tax.length ==
                                              0 &&
                                            "NA"}
                                        </ul>
                                      }
                                    </td>
                                    <td className="table-updated-td text-ellipsis removeLeftBorder">
                                      <a
                                        className="easy-link no-padding"
                                        onClick={() => this.getEditCatData(obj)}
                                      >
                                        Edit
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            {this.state.categoryList.length == 0 && (
                              <tr className="table-updated-tr">
                                <td className="no-record no-float" colSpan={4}>
                                  No record found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </StyleRoot>
                </div>
              </div>
            </div>
          </div>

          <div className={this.state.inactivateModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.inactivateModal
                ? "modal fade in displayBlock"
                : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => this.setState({ inactivateModal: false })}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    Confirmation Required!!
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  Are you sure you want to deactive all categories?
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={() => this.setState({ inactivateModal: false })}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deactiveCategories}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div
            className={
              this.state.filterValue === true
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">
                  {this.state.inventoryLang.inventory_Filter_Products}
                </div>
                <a
                  name="cross"
                  className="small-cross"
                  onClick={() => {
                    this.setState({ filterValue: "false" });
                    document.body.style.overflow = "";
                  }}
                >
                  ×
                </a>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container no-padding-bottom">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter m-b-20">
                        <div className="newInputLabel">
                          {this.state.inventoryLang.inventory_Category}
                        </div>
                        <div className="setting-input-outer">
                          <select
                            name="filter_by_category_id"
                            className="newSelectField"
                            onChange={this.handleInputChange}
                            value={this.state.filter_by_category_id}
                          >
                            <option value="" disabled defaultValue>
                              {
                                this.state.inventoryLang
                                  .inventory_Please_Select_Option
                              }{" "}
                            </option>
                            {this.state.CategoryData !== undefined &&
                              this.state.CategoryData.map((obj, idx) => {
                                return (
                                  <option value={obj.id} key={idx}>
                                    {obj.category_name && obj.category_name
                                      ? obj.category_name
                                      : ""}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <input
                  className="new-blue-btn pull-right"
                  id="apply"
                  type="Submit"
                  defaultValue={this.state.inventoryLang.inventory_Apply}
                />
                <a
                  name="cancel"
                  className="new-white-btn pull-right"
                  onClick={() => {
                    this.setState({ filterValue: "false" });
                    document.body.style.overflow = "";
                  }}
                >
                  {this.state.inventoryLang.inventory_Cancel}
                </a>
              </div>
            </div>
          </div>
        </form>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  let returnState = {};
  returnState.inventoryProductsFilter = selectors.selectInventoryFilter(state);
  localStorage.setItem("showLoader", false);
  localStorage.setItem("sortOnly", false);
  if (state.InventoryReducer.action === "INVENTORY_PRODUCT_LIST_ACTIVE") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.InventoryData = state.InventoryReducer.data.data;
      returnState.inventoryTimeStamp = new Date();
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.inventoryTimeStamp = new Date();
    }
  }
  if (state.InventoryReducer.action === "EXPORT_PRODUCT_DATA") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.exportCsvData = state.InventoryReducer.data.data;
      returnState.timeStamp = new Date();
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }
  if (state.InventoryReducer.action === "EMPTY_INVENTROY") {
    returnState.InventoryData = undefined;
  }

  if (state.InventoryReducer.action === "FETCH_CATEGORIES") {
    if (state.InventoryReducer.data.status == 200) {
      returnState.categoryList = state.InventoryReducer.data.data;
      returnState.categoryTimeStamp = new Date();
    } else {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    }
  }

  if (state.InventoryReducer.action === "DEACTIVATE_ALL_CATEGORIES") {
    if (state.InventoryReducer.data.status == 200) {
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.deactivatedTime = new Date();
    } else {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.deactivatedTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "FETCH_CLINICS") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.clinicsList = state.InventoryReducer.data;
      returnState.fetchClinicsTimestamp = new Date();
    } else {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    }
  }

  if (state.InventoryReducer.action == "CREATE_CATEGORY") {
    if (
      state.InventoryReducer.data.status == 200 ||
      state.InventoryReducer.data.status == 201
    ) {
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.categoryList = state.InventoryReducer.data.data;
      returnState.categoryTimeStamp = new Date();
    } else {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    }
  }

  if (state.InventoryReducer.action == "FETCH_SELECTED_CATEGORY") {
    if (
      state.InventoryReducer.data.status == 200 ||
      state.InventoryReducer.data.status == 201
    ) {
      returnState.editCategoryData = state.InventoryReducer.data.data;
      returnState.editCategoryDataTimestamp = new Date();
    } else {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    }
  }

  if (state.InventoryReducer.action == "UPDATE_CATEGORIES") {
    if (
      state.InventoryReducer.data.status == 200 ||
      state.InventoryReducer.data.status == 201
    ) {
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.categoryList = state.InventoryReducer.data.data;
      returnState.categoryTimeStamp = new Date();
    } else {
      returnState.showLoader = false;
    }
  }

  if (state.InventoryReducer.action === "EXPORT_DATA") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.exportCsvData = state.InventoryReducer.data.data;
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInventoryData: fetchInventoryData,
      exportProducts: exportProducts,
      emptyInventoryReducer: emptyInventoryReducer,
      fetchCategories: fetchCategories,
      deactivateAllCat: deactivateAllCat,
      fetchClinics: fetchClinics,
      createCategory: createCategory,
      fetchCategoriesDataID: fetchCategoriesDataID,
      exportData: exportData,
      updateCategories: updateCategories,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Radium(InventoryProductsActive)));
