import * as yup from "yup";
import { useState } from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";

export const useForm = (submitter) => {
  const { tSettings } = useAppTranslation.Settings();

  const [isLoading, setIsLoading] = useState(false);
  const [validationErrorLogs, setValidationErrorLogs] = useState([]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(tSettings("efaxTemplates.formError.nameRequired")),
    content: yup
      .string()
      .required(tSettings("efaxTemplates.formError.contentRequired")),
  });

  const compose = (files) => {
    if (Array.isArray(files) && files.length > 0) {
      return files.map((file) => ({
        composed: {
          name: file.templateName || "",
          content: file.parsedContent || "",
        },
        fileName: file.name,
      }));
    } else {
      throw new Error(tSettings("efaxTemplates.errors.importEmptyFiles"));
    }
  };

  const validate = (composedFiles) =>
    composedFiles.reduce(
      (r, f) => {
        const nextRes = r;

        try {
          nextRes.valid.push(
            schema.validateSync(f.composed, {
              abortEarly: false,
            }),
          );
        } catch (e) {
          nextRes.errors.push({
            fileName: f.fileName,
            errors: e.errors,
          });
        }

        return nextRes;
      },
      {
        errors: [],
        valid: [],
      },
    );

  const submitMultiple = async (files) => {
    try {
      setIsLoading(true);
      setValidationErrorLogs([]);

      const { valid: nextFiles, errors } = validate(compose(files));

      if (errors.length > 0) {
        setValidationErrorLogs(errors);
        throw new Error(
          tSettings("efaxTemplates.formError.importFilesValidationError"),
        );
      }

      await Promise.all(nextFiles.map(submitter));
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    submit: submitMultiple,
    isLoading,
    validationErrorLogs,
    resetValidationErrorLogs: () => setValidationErrorLogs([]),
  };
};
