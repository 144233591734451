import React from "react";
import cx from "clsx";
import parentClasses from "../../Qualiphy.module.scss";
import { Box } from "../../../../../../shared/Box/Box";
import qualiphyLogo from "../../../../../../assets/img/qualiphy-logo.png";
import classes from "./Info.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function Info() {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <Box className={cx(parentClasses.box, classes.root)}>
      <div>
        <h2 className={classes.title}>
          {tSettings("qualiphyIntegration.info.title")}
        </h2>
        <div>{tSettings("qualiphyIntegration.info.text1")}</div>
        <div className={classes.links}>
          <div className={classes.link}>
            {tSettings("qualiphyIntegration.info.links.tutorial.label")}{" "}
            <a
              href="https://qualiphy.me/api-knowledge-base/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {tSettings("qualiphyIntegration.info.links.tutorial.value")}
            </a>
          </div>
          <div className={classes.link}>
            {tSettings("qualiphyIntegration.info.links.help.label")}{" "}
            <a
              href="https://qualiphy.me/contact-qualiphy-good-faith-exam/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {tSettings("qualiphyIntegration.info.links.help.value")}
            </a>
          </div>
        </div>
        <div>{tSettings("qualiphyIntegration.info.text2")}</div>
        <div className={classes.links}>
          <div className={classes.link}>
            <a
              href="https://referral.aestheticrecord.com/qualiphy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {tSettings("qualiphyIntegration.info.links.integration")}
            </a>
          </div>
        </div>
      </div>
      <img src={qualiphyLogo} alt="qualiphy-logo" className={classes.logo} />
    </Box>
  );
}
