import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { introNotificationPopup } from "../../../store/introNotificationPopup";
import { dispatch } from "../../../store/store";
import { Button } from "../../../shared/Button/Button";
import { useIntroNotificationAcknowledgeMutation } from "../../../api/mutations/useIntroNotificationAcknowledgeMutation";
import { uiNotification } from "../../../services/UINotificationService";
import { wpuId } from "../../../utilities/localStorage";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import {
  getScheduledIntroNotificationPopup,
  removeIntroNotificationPopupSchedule,
} from "../../../store/introNotificationPopup/utilities";
import styles from "./styles.module.scss";

const { selectors, actions } = introNotificationPopup;

const mapStateToProps = (state) => ({
  notification: selectors.selectNotification(state),
});

export const IntroNotificationPopup = connect(mapStateToProps)(
  ({ notification }) => {
    const { tCommon: t } = useAppTranslation.Common();

    const { mutate, isLoading } = useIntroNotificationAcknowledgeMutation({
      onError: () => {
        uiNotification.error(t("introNotificationPopup.error.acknowledge"));
      },
      onSuccess: () => {
        dispatch(actions.close());
      },
    });

    useEffect(() => {
      const notification = getScheduledIntroNotificationPopup();

      if (notification?.id && notification?.message) {
        dispatch(
          actions.open({
            id: notification.id,
            message: notification.message,
          }),
        );
        removeIntroNotificationPopupSchedule();
      }
    }, []);

    return (
      <Modal
        footerNoBorder
        isOpen={!!notification}
        shouldCloseOnOverlayClick={false}
        onClose={() => {
          dispatch(actions.close());
        }}
        footer={
          <div className={styles.footer}>
            <Button
              onClick={() =>
                mutate({ notificationId: notification?.id, wpuId: wpuId.get() })
              }
              isDisabled={isLoading}
              leftAdornment={
                isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              {t("introNotificationPopup.acknowledge")}
            </Button>
          </div>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: notification?.message,
          }}
        />
      </Modal>
    );
  },
);
