import { useMemo, useState } from "react";
import { useSubmit } from "../../../hooks/useSubmit";
import {
  buildIdToAdaptedQuestionMap,
  questionsAdapter,
} from "../../../utilities";
import { extractQuestionnaireStatus } from "../../../../../utilities/api";
import { useAnswers } from "./helpers/useAnswers";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { getAnswersStatus } from "../utilities";
import { SIMPLE_QUESTION_TYPES } from "../../../../../api/questionnaires/config";
import { QUESTIONNAIRE_YES_NO_ANSWERS } from "../../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireAnswersMutation";

export function useQuestionnaire({ patientQuestionnaire, onSuccess }) {
  var { tCommon } = useAppTranslation.Common();
  var [isForceSaving, setIsForceSaving] = useState(false);

  var submit = useSubmit();

  var questionnaireStatus = extractQuestionnaireStatus(
    patientQuestionnaire || {},
  );

  var adaptedQuestions = useMemo(
    () => questionsAdapter(patientQuestionnaire),
    [patientQuestionnaire],
  );

  var idToAdaptedQuestionMap = useMemo(
    () => buildIdToAdaptedQuestionMap(adaptedQuestions),
    [adaptedQuestions],
  );

  var { choices, reasons, getAnswers } = useAnswers(
    patientQuestionnaire,
    idToAdaptedQuestionMap,
  );

  var onSave = () => {
    const answers = getAnswers();
    const { isCompleted } = getAnswersStatus(answers, adaptedQuestions);

    if (!isCompleted) {
      return uiNotification.error(
        tCommon("fillPatientQuestionnaire.error.fillRequired"),
      );
    }

    submit.initiate(answers, idToAdaptedQuestionMap).then(onSuccess);
  };

  var onForceSave = () => {
    setIsForceSaving(true);

    const answers = getAnswers();

    const { untilLastRequiredNotCompletedIds } = getAnswersStatus(
      answers,
      adaptedQuestions,
    );

    submit
      .initiate(
        answers,
        idToAdaptedQuestionMap,
        untilLastRequiredNotCompletedIds,
      )
      .then(onSuccess)
      .finally(() => {
        setIsForceSaving(false);
      });
  };

  var yesNoToAll = (answer) => {
    adaptedQuestions.forEach((question) => {
      if (question.type === SIMPLE_QUESTION_TYPES.yesNo) {
        choices.update(question.id, [answer]);
      }
    });
  };

  var isYesNoAvailable = useMemo(
    () =>
      adaptedQuestions.some(
        (question) => question.type === SIMPLE_QUESTION_TYPES.yesNo,
      ),
    [adaptedQuestions],
  );

  return {
    questions: adaptedQuestions,
    choices,
    reasons,

    save: {
      initiate: onSave,
      isLoading: submit.isLoading && !isForceSaving,
    },

    forceSave:
      questionnaireStatus !== "filled"
        ? {
            initiate: onForceSave,
            isLoading: isForceSaving,
          }
        : null,

    yesToAll: () => yesNoToAll(QUESTIONNAIRE_YES_NO_ANSWERS.yes),
    noToAll: () => yesNoToAll(QUESTIONNAIRE_YES_NO_ANSWERS.no),
    isYesNoAvailable,
  };
}
