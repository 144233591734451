import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { composeDownloadDataUrl } from "../../../../../../utilities/api";

export const useExportReport = (route, params) => {
  const { refetch, isFetching } = useQuery(
    ["exportReport"],
    async () => {
      const res = await http.get(route, {
        params,
      });

      window.open(composeDownloadDataUrl(res.data?.data?.file), "_blank");
      return res;
    },
    { enabled: false },
  );

  return {
    refetch,
    isFetching,
  };
};
