import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./PatientNames.module.scss";

const LIMIT = 10;

export function PatientNames({ names }) {
  const { tInbox } = useAppTranslation.Inbox();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      {names.length > LIMIT ? (
        <div>
          {names.slice(0, isExpanded ? undefined : LIMIT).join(", ")}
          <button
            onClick={() => setIsExpanded((prev) => !prev)}
            className={classes.expandBtn}
          >
            {isExpanded
              ? tInbox("blackList.table.showLess")
              : tInbox("blackList.table.showMore", {
                  count: names.length - LIMIT,
                })}
          </button>
        </div>
      ) : (
        names.join(", ")
      )}
    </div>
  );
}

PatientNames.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
};
