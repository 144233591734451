import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  notifyViaSMS,
  notifyViaEmail,
  cancelKlarnaPayment,
} from "../../../../../Actions/Sales/klarnaActions";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { uiNotification } from "../../../../../services/UINotificationService";
import classes from "./ViewChargeKlarna.module.scss";

const ConfirmChargeKlarna = (props) => {
  const {
    notifyViaSMSKlarnaPayment,
    notifyViaEmailKlarnaPayment,
    notifyViaSMS,
    notifyViaEmail,
    ongoingKlarnaPayment,
    cancelKlarnaPayment,
    formValues,
  } = props;

  const { invoice } = useInvoice();

  const isNotificationBeingSent = () =>
    notifyViaSMSKlarnaPayment.status === "loading" ||
    notifyViaEmailKlarnaPayment.status === "loading";

  const isMailEmpty = () => !formValues.email?.trim();

  const resendCheckoutLinkViaSMS = () =>
    notifyViaSMS({
      invoiceId: invoice.id,
      phone: formValues.phoneNumber,
    });

  const resendCheckoutLinkViaEmail = () =>
    notifyViaEmail({
      invoiceId: invoice.id,
      email: formValues.email,
    });

  const getKlarnaCheckoutLink = () =>
    ongoingKlarnaPayment.status === "success"
      ? ongoingKlarnaPayment.data.klarnaPayment.url
      : "";

  const copyKlarnaCheckoutLink = () => {
    navigator.clipboard.writeText(getKlarnaCheckoutLink());
    uiNotification.success("Link Copied To Clickboard!", { autoClose: 2000 });
  };

  const cancelOngoingKlarnaPayment = () => {
    cancelKlarnaPayment({ invoiceId: invoice.id });
  };

  const ongoingKlarnaPaymentIsOpened = () =>
    ongoingKlarnaPayment?.data?.klarnaPayment?.open !== false;

  return (
    <div className={"modalOverlay klarna-modal"}>
      <div className="small-popup-outer">
        <div className="juvly-container">
          <div className="row">
            <div>
              <div className="col-sm-12 m-t-40">
                <h3 className={classes.titleConfirmKlarna}>
                  <img src="/images/klarna.png" alt="klarna" /> Waiting for
                  Payment Confirmation
                </h3>
              </div>
              {ongoingKlarnaPaymentIsOpened() ? (
                <>
                  <div className="col-sm-12 col-md-10 col-md-offset-1 m-t-40">
                    <button
                      onClick={resendCheckoutLinkViaSMS}
                      className="custom-klarna-btn d-flex text-center m-b-30"
                    >
                      <img src="/images/klarna-black-logo.png" alt="klarna" />
                      Send SMS to Patient
                    </button>
                    {isNotificationBeingSent() ? (
                      <img
                        className="spinner-load"
                        src="/images/loader.gif"
                        alt="loader"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-12 m-b-40 m-t-20">
                    <p className="bottom-text">
                      Having issues sending a text to {formValues.phoneNumber} ?
                    </p>
                    <p className="bottom-text">
                      {!isMailEmpty() ? (
                        <span>
                          Send link via{" "}
                          <button
                            className="link-btn"
                            onClick={resendCheckoutLinkViaEmail}
                          >
                            email
                          </button>{" "}
                          to {formValues.email} <span>or</span>{" "}
                        </span>
                      ) : (
                        ""
                      )}
                      <button
                        className="link-btn"
                        onClick={copyKlarnaCheckoutLink}
                      >
                        Copy link to clipboard
                      </button>
                    </p>
                  </div>
                  <div className="col-sm-12">
                    <button
                      className="new-blue-btn pull-right"
                      onClick={cancelOngoingKlarnaPayment}
                    >
                      Cancel Payment
                    </button>
                  </div>
                </>
              ) : (
                <img
                  className="spinner-load"
                  src="/images/loader.gif"
                  alt="loader"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmChargeKlarna.propTypes = {
  notifyViaSMSKlarnaPayment: PropTypes.object,
  notifyViaEmailKlarnaPayment: PropTypes.object,
  notifyViaSMS: PropTypes.func,
  notifyViaEmail: PropTypes.func,
  ongoingKlarnaPayment: PropTypes.object,
  cancelKlarnaPayment: PropTypes.func,
  formValues: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    notifyViaSMSKlarnaPayment: state.KlarnaReducer.notifyViaSMSKlarnaPayment,
    notifyViaEmailKlarnaPayment:
      state.KlarnaReducer.notifyViaEmailKlarnaPayment,
    ongoingKlarnaPayment: state.KlarnaReducer.ongoingKlarnaPayment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      notifyViaSMS,
      notifyViaEmail,
      cancelKlarnaPayment,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmChargeKlarna);
