import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Modal } from "../../../../shared/Modal/Modal";
import classes from "./sass/Editor.module.scss";
import { svg } from "../../../../assets/svg";
import { Button } from "../../../../shared/Button/Button";
import { FileDropzone } from "../../../../shared/FileDropzone/FileDropzone";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { File } from "./File";
import { fileUtil } from "../../../../utilities/file";

export function Editor({
  isOpen,
  onClose,
  files,
  onRemoveFile,
  onSelectFile,
  selectedFile,
  renderContent,
  renderFooter,
  onBack,
  renderFilesMobileFooter,
  onRemoveAll,
  dropzone,
  isFilesParsing,
  pendingFileId,
  isRemoveAllDisabled,
  isSelectFilesDisabled,
  isFilesDisabled,
}) {
  const filesClasses = cx(classes.files, {
    [classes.filesHide]: selectedFile,
  });

  return (
    <Modal
      size="large"
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      className={classes.modalRoot}
    >
      <div className={filesClasses}>
        <div className={classes.filesInner}>
          <div className={cx(classes.title, classes.filesTitle)}>Files</div>
          <div className={classes.filesSubtitle}>
            <Button
              fullWidth
              size="small"
              variant="outlined"
              color="error"
              onClick={onRemoveAll}
              isDisabled={Boolean(
                pendingFileId || isFilesParsing || isRemoveAllDisabled,
              )}
            >
              Remove All
            </Button>
            <FileDropzone
              variant="circle"
              onDrop={dropzone.onDrop}
              onError={dropzone.onError}
              multiple={dropzone.multiple}
              supportedExtensions={dropzone.supportedExtensions}
              className={classes.headerDropzone}
              isDisabled={Boolean(
                pendingFileId || isFilesParsing || isSelectFilesDisabled,
              )}
              components={{
                loader: isFilesParsing ? (
                  <CircularProgress size="tiny" color="gray1" />
                ) : null,
              }}
            />
          </div>
          <div className={classes.filesWrapper}>
            {files.map((file) => (
              <File
                key={file.id}
                name={file.name}
                isSelected={Boolean(
                  selectedFile && file.id === selectedFile.id,
                )}
                onSelect={() => onSelectFile(file.id)}
                onRemove={() => onRemoveFile(file.id)}
                isPending={file.id === pendingFileId}
                isDisabled={Boolean(
                  pendingFileId || isFilesParsing || isFilesDisabled,
                )}
              />
            ))}
          </div>
        </div>
        <div className={classes.filesFooter}>{renderFilesMobileFooter()}</div>
      </div>
      <div
        className={cx(classes.contentWrap, {
          [classes.contentHide]: !selectedFile,
        })}
      >
        <div className={classes.contentWrapInner}>
          <div className={classes.header}>
            <button
              className={classes.headerBack}
              onClick={onBack}
              disabled={Boolean(pendingFileId || isFilesParsing)}
            >
              <img src={svg.arrowLeftPrimary} alt="<" width="20px" />
            </button>
            <div className={classes.title}>
              {fileUtil.shortenName(selectedFile ? selectedFile.name : "", {
                start: 15,
                end: 8,
              })}
            </div>
          </div>
          <div className={classes.content}>{renderContent()}</div>
        </div>
        <div className={classes.footer}>{renderFooter()}</div>
      </div>
    </Modal>
  );
}

Editor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
  renderFooter: PropTypes.func,
  renderContent: PropTypes.func,
  onBack: PropTypes.func,
  renderFilesMobileFooter: PropTypes.func,
  onRemoveAll: PropTypes.func.isRequired,
  dropzone: PropTypes.shape({
    onDrop: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    multiple: PropTypes.bool.isRequired,
    supportedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  isFilesParsing: PropTypes.bool.isRequired,
  pendingFileId: PropTypes.string,
  isRemoveAllDisabled: PropTypes.bool.isRequired,
  isSelectFilesDisabled: PropTypes.bool.isRequired,
  isFilesDisabled: PropTypes.bool.isRequired,
};

Editor.defaultProps = {
  selectedFile: null,
  renderFooter: () => null,
  renderContent: () => null,
  onBack: undefined,
  renderFilesMobileFooter: () => {},
  pendingFileId: null,
};
