import React from "react";
import PropTypes from "prop-types";
import classes from "./Option.module.scss";

export function Option({ data }) {
  return (
    <div className={classes.root}>
      {data.map((o, index) => (
        <div key={`${o.value}${index}`} className={classes.row}>
          {!!o.label && <b>{o.label}:</b>}
          {o.value}
        </div>
      ))}
    </div>
  );
}

Option.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
