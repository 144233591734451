import { useCallback, useRef, useState } from "react";

export function useCombinedValue({ initialValue, onChange }) {
  const [combinedValue, setCombinedValue] = useState(initialValue || "");
  const transcriptionAdded = useRef(false);

  const handleDictationChange = useCallback(
    (newText) => {
      const updatedText = `${initialValue} ${newText}`.trim();
      setCombinedValue(updatedText);
      onChange?.(updatedText);
    },
    [initialValue, onChange],
  );

  const handleTranscriptionChange = useCallback(
    (newText) => {
      if (!transcriptionAdded.current) {
        const updatedText = `${combinedValue}\n${newText}`.trim();
        setCombinedValue(updatedText);
        onChange && onChange(updatedText);
        transcriptionAdded.current = true;
      }
    },
    [combinedValue, onChange],
  );

  const handlePatternAutocompleteChange = useCallback(
    (newText) => {
      setCombinedValue(newText);
      onChange?.(newText);
    },
    [onChange],
  );

  return {
    combinedValue,
    handleDictationChange,
    handleTranscriptionChange,
    handlePatternAutocompleteChange,
  };
}
