import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  const betaFeatures = state.betaFeatures.betaFeatures;

  const toggledOnBetaFeaturesLabels = betaFeatures
    .filter((feature) => feature.toggled === "on")
    .map((feature) => feature.label);

  return {
    betaFeatures,
    toggledOnBetaFeaturesLabels,
  };
};

export const withBetaFeatures = (Component) => {
  const Wrapper = (props) => {
    return <Component {...props} betaFeature={props} />;
  };

  return connect(mapStateToProps)(Wrapper);
};
