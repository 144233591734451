import { Routes } from "../_legacy/Constants";
import { defaultTableFilters } from "../_legacy/Constants/table";

export const updatePageFilter = (route, filter) => {
  if (Routes[route].url) {
    localStorage.setItem(
      Routes[route].url,
      JSON.stringify({ ...defaultTableFilters, ...filter }),
    );
  }
};
