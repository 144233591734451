/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../_legacy/Constants/routes.js";
import { checkIfPermissionAllowed } from "../../Utils/services.js";
import { APPOINTMENT_ROUTES } from "../../consts/routes.js";

class AppointmentHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenuTag: "",
    };
  }

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      appointment_calendar: languageData.appointments["appointment_calendar"],
      appointment_services: languageData.appointments["appointment_services"],
      appointment_services_packages:
        languageData.appointments["appointment_services_packages"],
      appointment_provider_schedule:
        languageData.appointments["appointment_provider_schedule"],
      appointment_equipment_schedule:
        languageData.appointments["appointment_equipment_schedule"],
      appointment_resource_schedule:
        languageData.appointments["appointment_resource_schedule"],
      appointment_booking_history:
        languageData.appointments["appointment_booking_history"],
      appointment_reports: languageData.appointments["appointment_reports"],
    });
  }

  static getDerivedStateFromProps(props) {
    let returnState = {};
    if (props.activeMenuTag != undefined) {
      returnState.activeMenuTag = props.activeMenuTag;
    }
    return returnState;
  }

  render() {
    let url = window.location.pathname;
    return (
      <div className="calSettingdropDown pull-right">
        <div className="dropdown pull-left">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          ></button>
          <ul className="dropdown-menu">
            {checkIfPermissionAllowed("view-appointments") && (
              <li>
                <Link
                  to="/appointment/index"
                  className={url == "/appointment/index" ? "active" : ""}
                >
                  {this.state.appointment_calendar}
                </Link>
              </li>
            )}

            {checkIfPermissionAllowed("view-appointments") && (
              <li>
                <Link
                  to="/appointment/providers/calendar"
                  className={
                    url == "/appointment/providers/calendar" ? "active" : ""
                  }
                >
                  Clinic View
                </Link>
              </li>
            )}
            {checkIfPermissionAllowed("view-appointments") && (
              <li>
                <Link
                  to="/appointment/booking-history"
                  className={
                    url == "/appointment/booking-history" ? "active" : ""
                  }
                >
                  {this.state.appointment_booking_history}
                </Link>
              </li>
            )}
            {(checkIfPermissionAllowed("manage-appointment-settings") ||
              checkIfPermissionAllowed("manage-provider-schedules")) && (
              <li>
                <Link
                  to="/appointment/config"
                  type="button"
                  className={url == "/appointment/config" ? "active" : ""}
                >
                  Smart Configuration
                </Link>
              </li>
            )}
            {(url.indexOf("/appointment/index") > -1 ||
              (url.indexOf("clinic") > -1 && url.indexOf("provider") > -1) ||
              url.indexOf("/appointment/providers/calendar") > -1) && (
              <li>
                <a onClick={this.props.togglePrint}>Print</a>
              </li>
            )}
            {checkIfPermissionAllowed("create-appointment") &&
              this.props.smartBookingUrl && (
                <li>
                  <a
                    id=""
                    href={this.props.smartBookingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Online Booking"}
                  </a>
                </li>
              )}
            {checkIfPermissionAllowed("view-appointments") && (
              <li>
                <Link
                  to={Routes.WAITLIST.url}
                  className={url == Routes.WAITLIST.url ? "active" : ""}
                >
                  Waitlist
                </Link>
              </li>
            )}
            {checkIfPermissionAllowed("view-appointments") && (
              <li>
                <Link
                  to={APPOINTMENT_ROUTES.noShows()}
                  className={
                    url == APPOINTMENT_ROUTES.noShows() ? "active" : ""
                  }
                >
                  No Shows
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default AppointmentHeader;
