/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

const reportsWithoutDownload = [
  "previous-period-vs-current-period",
  "previous-year-vs-current-year",
];

export default class InventoryReportTitle extends Component {
  showCreateOptions = () => {
    this.props.showCreateOptions();
  };

  saveReport = () => {
    this.props.saveReport();
  };

  canDownloadReport = () => {
    if (
      !this.props.parentState.reportData ||
      !(
        this.props.parentState.reportListData &&
        this.props.parentState.reportListData.length
      )
    ) {
      return false;
    }

    return !reportsWithoutDownload.includes(
      this.props.parentState.reportData.sys_name,
    );
  };

  render() {
    return (
      <div className="membership-title">
        {this.props.parentState.grammar && (
          <div className="col-sm-12 m-b-10">
            {this.props.parentState.grammar}
          </div>
        )}
        {!this.props.parentState.showLoader && (
          <div className="memberRightActions">
            {this.canDownloadReport() && (
              <a
                onClick={() => this.props.downloadData()}
                className="easy-link no-padding"
              >
                <i className="fa fa-download m-r-5" />
                {this.props.parentState.businessInsightLang.bi_download_excel}
              </a>
            )}
            {this.props.parentState.reportType !== "view" && (
              <a
                className="easy-link no-padding"
                onClick={this.showCreateOptions}
              >
                <i className="fa fa-pencil-alt m-r-5" />{" "}
                {this.props.parentState.businessInsightLang.bi_edit}
              </a>
            )}
            {this.props.parentState.dataLoadedOnce === true &&
              this.props.parentState.reportType !== "view" && (
                <a className="new-blue-btn" onClick={this.saveReport}>
                  {this.props.parentState.businessInsightLang.bi_save_report}
                </a>
              )}
          </div>
        )}
      </div>
    );
  }
}
