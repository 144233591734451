export const REDUCER_NAME = "patients/mutateProcedure";

export const DOC_DEDUP_TYPE = {
  consent: "consent",
  questionnaire: "questionnaire",
};

export const ACTION_TYPES = {
  startDocumentsDedup: `${REDUCER_NAME}/startDocumentsDedup`,
  finishDocumentsDedup: `${REDUCER_NAME}/finishDocumentsDedup`,
};
