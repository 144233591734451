import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export const emptyClockReducer = () => {
  return (dispatch) => {
    dispatch({ type: "EMPTY_CLOCK" });
  };
};

export function changeClockStatus(formData) {
  let type = "CHANGE_LOGGED_CLOCK_STATUS";

  if (formData && formData.user_id) {
    type = "CHANGE_SYSTEM_CLOCK_STATUS";
  }

  return async (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
    return http
      .post("clock_in_out", formData)
      .then((response) => {
        dispatch({ type: type, payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: type, payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function getClockList(formData) {
  return (dispatch) => {
    http
      .get("clock_list", formData)
      .then((response) => {
        dispatch({ type: "CLOCK_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLOCK_LIST", payload: error.response.data });
      });
  };
}

export function getClockPayPeriod() {
  return (dispatch) => {
    http
      .get("get_account_settings")
      .then((response) => {
        dispatch({ type: "GET_PAY_PERIOD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PAY_PERIOD", payload: error.response.data });
      });
  };
}

export function saveClockPayPeriod(formData) {
  return (dispatch) => {
    http
      .post("save_account_settings", formData)
      .then((response) => {
        dispatch({ type: "SAVE_PAY_PERIOD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_PAY_PERIOD", payload: error.response.data });
      });
  };
}

export function changeClockSettingStatus(formData) {
  return (dispatch) => {
    http
      .post("toggle_account_settings", formData)
      .then((response) => {
        dispatch({
          type: "CHANGE_CLOCK_SETTING_STATUS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_CLOCK_SETTING_STATUS",
          payload: error.response.data,
        });
      });
  };
}
