/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

class BdAspire extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.reportData !== undefined) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  fetchBdAspireDetail = (obj) => {
    if (obj) {
      let returnState = {
        detailClinicId: obj.invoice_clinic_id,
        detailClinicName: obj.client_name,
      };
      this.props.fetchBdAspireDetail(returnState);
    }
  };

  render() {
    if (this.props.reportData && this.props.reportData.report_header) {
      return (
        <div className="table-responsive clients-table">
          <table className="table-updated setting-table table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.client_name}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.bd_coupons}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.aspire_coupons}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.evolus_coupons}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData?.report_header?.xperience_coupons}
                </th>
                <th className="table-updated-th">
                  {this.state.languageData.bi_view_details}
                </th>
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.state.reportData &&
                this.state.reportData.report_data &&
                this.state.reportData.report_data.map((obj, idx) => {
                  return (
                    <tr className="table-updated-tr" key={idx}>
                      <td className="col-xs-4 table-updated-td">
                        {obj.client_name}
                      </td>
                      <td className="col-xs-4 table-updated-td">
                        {obj.bd_coupons}
                      </td>
                      <td className="col-xs-4 table-updated-td">
                        {obj.aspire_coupons}
                      </td>
                      <td className="col-xs-4 table-updated-td">
                        {obj.evolus_coupons}
                      </td>
                      <td className="col-xs-4 table-updated-td">
                        {obj.xperience_coupons}
                      </td>
                      <td className="table-updated-td">
                        <a
                          className="easy-link no-padding"
                          onClick={this.fetchBdAspireDetail.bind(this, obj)}
                        >
                          {this.state.languageData.bi_view_details}
                        </a>
                      </td>
                    </tr>
                  );
                })}

              {this.state.reportData &&
                this.state.reportData.report_data &&
                this.state.reportData.report_data.length == 0 && (
                  <tr className="table-updated-tr">
                    <td
                      className="table-updated-td no-record no-float"
                      colSpan={10}
                    >
                      {this.state.languageData.bi_no_record_found}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default BdAspire;
