import React, { useRef, useState } from "react";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { PERMISSIONS } from "../../../consts/api";
import { usePermission } from "../../../hooks/usePermission";
import { useDeleteManyPatientsMutation } from "../../../api/mutations/useDeleteManyPatientsMutation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiErrorData } from "../../../utilities/api";
import { ModalError } from "../../../boxes/ModalError/ModalError";

export function withBulkDelete(Component) {
  return (props) => {
    const permission = usePermission(
      PERMISSIONS.patientManagement.bulkDeletePatients,
    );

    const { tClients } = useAppTranslation.Clients();

    const [selected, setSelected] = useState([]);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [errors, setErrors] = useState([]);

    const onSuccess = useRef(null);

    const { mutate, isLoading } = useDeleteManyPatientsMutation({
      onError: (e) => {
        const errors = extractApiErrorData(e)?.errors || [];

        if (errors.length) {
          setErrors(errors);
          setIsConfirmOpen(false);
        } else {
          uiNotification.error(tClients("bulkDelete.error"));
        }
      },
      onSuccess: (data) => {
        const errors = data?.data?.data?.errors || [];

        if (errors.length) {
          setErrors(errors);
        } else {
          onSuccess?.current?.();

          setSelected([]);
          setErrors([]);

          uiNotification.success(tClients("bulkDelete.success"));
        }

        setIsConfirmOpen(false);
      },
    });

    const getIsSelected = (id) => selected.includes(id);

    const toggleSelected = (id) => {
      if (selected.includes(id)) {
        setSelected(selected.filter((i) => i !== id));
      } else {
        setSelected([...selected, id]);
      }
    };

    return (
      <>
        <Component
          {...props}
          bulkDelete={{
            isPermitted: permission.loaded && permission.permitted,
            getIsSelected,
            toggleSelected,
            count: selected.length,
            request: (nextOnSuccess) => {
              setIsConfirmOpen(true);
              onSuccess.current = nextOnSuccess;
            },
            reset: () => {
              setSelected([]);
            },
          }}
        />
        {isConfirmOpen && (
          <ConfirmModal
            isOpen
            isConfirming={isLoading}
            onConfirm={() => mutate({ patientIds: selected.map(Number) })}
            onCancel={() => setIsConfirmOpen(false)}
            onClose={() => setIsConfirmOpen(false)}
          >
            {tClients("bulkDelete.confirm", { count: selected.length })}
          </ConfirmModal>
        )}
        {errors.length > 0 && (
          <ModalError
            isOpen
            onClose={() => setErrors([])}
            onOk={() => setErrors([])}
          >
            <div className="d-flex flex-column gap-4">
              <h4 className="m-b-10">{tClients("bulkDelete.cannotDelete")}:</h4>
              {errors.map((error) => (
                <div key={error.patient_id}>
                  <b>{error.patient_name} </b>
                  {tClients(`bulkDelete.apiError.${error.error}`, error.error)}
                </div>
              ))}
            </div>
          </ModalError>
        )}
      </>
    );
  };
}
