import React from "react";
import PropTypes from "prop-types";
import { svg } from "../../../../../assets/svg";
import classes from "../sass/SuccessAlert.module.scss";

export function SuccessAlert({ children }) {
  return (
    <div className={classes.root}>
      <img src={svg.checkPrimary} alt="" width="16px" />
      <div>{children}</div>
    </div>
  );
}

SuccessAlert.propTypes = {
  children: PropTypes.string.isRequired,
};
