import * as yup from "yup";
import { useExtendedFormik } from "../../../../../../hooks/useExtendedFormik";
import { useSmsApptNotificationsQuery } from "../../../../../../api/smsApptNotifications/useSmsApptNotificationsQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";

export function useForm(submitter) {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();
  const userQuery = useCurrentUserQuery();

  const query = useSmsApptNotificationsQuery({
    enabled: userQuery.isSuccess && !userQuery?.data?.isSuperUser,
    onError: () => {
      uiNotification.error(tSettings("smsApptNotifications.error.fetch"));
    },
  });

  const originalPhone =
    query.data?.phone ||
    userQuery.data?.phone1 ||
    userQuery.data?.phone2 ||
    userQuery.data?.phone3 ||
    userQuery.data?.phone4 ||
    "";

  const { values, changeField, handleSubmit, getError } = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: yup.object({
      phone: yup.string().required(tCommon("formError.required")),
      originalPhone: yup.string().required(),
      rescheduling: yup.boolean().required(),
      cancelling: yup.boolean().required(),
      booking: yup.boolean().required(),
      isEnabled: yup.boolean().required(),
    }),
    initialValues: {
      phone: originalPhone,
      originalPhone,
      rescheduling: query.data?.rescheduling || false,
      cancelling: query.data?.cancelling || false,
      booking: query.data?.booking || false,
      isEnabled: query.data?.isEnabled || false,
    },
    onSubmit: submitter,
  });

  return {
    isLoading: query.isLoading || userQuery.isLoading,

    submit: handleSubmit,

    originalPhone: {
      value: values.originalPhone,
      reinit: () => changeField("originalPhone", values.phone),
    },

    isEnabledOriginal: query.data?.isEnabled || false,

    isEnabled: {
      value: values.isEnabled,
      onChange: (next) => changeField("isEnabled", next),
    },

    phone: {
      value: values.phone,
      onChange: (next) => changeField("phone", next),
      error: getError("phone"),
      reinit: () => changeField("phone", values.originalPhone),
    },

    rescheduling: {
      value: values.rescheduling,
      toggle: () => changeField("rescheduling", !values.rescheduling),
    },

    cancelling: {
      value: values.cancelling,
      toggle: () => changeField("cancelling", !values.cancelling),
    },

    booking: {
      value: values.booking,
      toggle: () => changeField("booking", !values.booking),
    },
  };
}
