// Some plugins are not included in the free version of TinyMCE and
// some free plugins were removed from the /public/tinymce folder.
// If you need a plugin that is not inside /public/tinymce, you can download
// the latest SDK from the official TinyMCE website and add plugins to the /public/tinymce folder.
// https://www.tiny.cloud/docs/tinymce/latest/plugins/ Open source plugins tab

export const INIT = {
  plugins: ["link", "autolink", "lists", "autoresize"],
  toolbar:
    "undo redo bold italic underline strikethrough subscript superscript alignleft aligncenter alignright alignjustify bullist numlist link hr blocks",
  statusbar: false,
  menubar: false,
  max_height: 500,
  min_height: 200,
  autoresize_bottom_margin: 15,
};
