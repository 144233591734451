import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "clsx";
import classes from "./ButtonFollowLink.module.scss";
import { svg } from "../../assets/svg";

export function ButtonFollowLink({ url, leave, children, className }) {
  const rootClasses = cx(classes.root, className);

  return (
    <Link
      to={url}
      target={leave ? "_blank" : undefined}
      className={rootClasses}
    >
      {children || <img src={svg.followLinkPrimary} alt="" />}
    </Link>
  );
}

ButtonFollowLink.propTypes = {
  url: PropTypes.string.isRequired,
  leave: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

ButtonFollowLink.defaultProps = {
  leave: false,
  children: null,
  className: undefined,
};
