import { ACTION_TYPES } from "./config";

export const actions = {
  open: ({ id, message }) => ({
    type: ACTION_TYPES.open,
    payload: {
      id,
      message,
    },
  }),
  close: () => ({
    type: ACTION_TYPES.close,
  }),
};
