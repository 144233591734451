import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { SortableList } from "./boxes/SortableList/SortableList";
import classes from "./ReorderModal.module.scss";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import { useReorder } from "./hooks/useReorder";

export function ReorderModal({
  isOpen,
  onClose,
  title,
  data,
  onSave,
  isLoading,
  isSaving,
  renderSubheader,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { orderedData, onSortEnd, onSortReset, isTouched } = useReorder(data);
  const isDisabled = isSaving || isLoading || !isTouched;

  return (
    <Modal
      headerNoBorder
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Title>{title || tCommon("label.reorder")}</Modal.Title>}
      footer={
        <div className={classes.footer}>
          <Button
            onClick={() => onSave(orderedData)}
            isDisabled={isDisabled}
            leftAdornment={
              isSaving ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {tCommon("label.save")}
          </Button>
          <Button
            variant="outlined"
            onClick={onSortReset}
            isDisabled={isDisabled}
          >
            {tCommon("label.reset")}
          </Button>
        </div>
      }
    >
      {renderSubheader && (
        <div className={classes.subheader}>
          {renderSubheader({ isTouched })}
        </div>
      )}
      <div className={classes.listWrap}>
        <SortableList
          items={orderedData}
          onSortEnd={onSortEnd}
          isLoading={isLoading}
          isSaving={isSaving}
        />
      </div>
    </Modal>
  );
}

ReorderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  renderSubheader: PropTypes.func,
};

ReorderModal.defaultProps = {
  title: null,
  isLoading: false,
  isSaving: false,
  renderSubheader: null,
};
