import React from "react";
import cx from "clsx";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import classes from "./Loading.module.scss";

/**
 * @param {object} prop0
 * @param {("tiny" | "small" | "medium" | "big")?} prop0.size
 * @param {string?} prop0.className
 * @param {boolean?} prop0.center
 */
export const Loading = ({ size = "medium", className, center }) => {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.rootTiny]: size === "tiny",
          [classes.rootSmall]: size === "small",
          [classes.rootMedium]: size === "medium",
          [classes.rootBig]: size === "big",
          [classes.rootCenter]: center,
        },
        className,
      )}
    >
      <CircularProgress size={size} />
      <span>{tCommon("label.loading")}...</span>
    </div>
  );
};
