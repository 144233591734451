import React from "react";
import {
  useGetGetClientNotesHistoryQuery,
  useGetStickyNotesAcknowledgeHistoryQuery,
} from "../../_legacy/Queries";
import { showFormattedDate } from "../../Utils";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";
import Loader from "../Common/Loader";

const AcknowledgeHistoryModal = ({
  id,
  onClose,
  isAcknowledgeHistorOrNotesHistory = true,
  noteType = false,
}) => {
  const { data, isSuccess, isLoading } =
    useGetStickyNotesAcknowledgeHistoryQuery(id, noteType);

  const {
    data: notesHistoryData,
    isSuccess: isNotesHistorySuccess,
    isLoading: isNotesHistoryLoading,
  } = useGetGetClientNotesHistoryQuery(id, noteType);

  const acknowledgeHistory = isSuccess ? data.data.data : [];
  const notesHistory = isNotesHistorySuccess ? notesHistoryData.data.data : [];

  if (isSuccess || isNotesHistorySuccess) {
    return (
      <ConfirmModal
        isOpen
        title={
          isAcknowledgeHistorOrNotesHistory
            ? "Acknowledge History"
            : "Notes History"
        }
        onConfirm={onClose}
        confirmTitle="Ok"
        className="confirm-modal-center acknowledge-history-modal"
        contentClassName={"note-history-modal-content"}
      >
        <div>
          {Boolean(noteType) && notesHistory.length === 0 ? (
            <p>No users have updated this note yet</p>
          ) : !noteType && acknowledgeHistory.length === 0 ? (
            <p>No users have acknowledged this note yet</p>
          ) : (
            <>
              <table>
                <tr>
                  <th>User</th>
                  <th>
                    {isAcknowledgeHistorOrNotesHistory
                      ? "Acknowledged"
                      : "Updated"}{" "}
                    at
                  </th>
                </tr>
                {[...(noteType ? notesHistory : acknowledgeHistory)].map(
                  (historyItem) => {
                    const { user, acknowledge_at, id, created } = historyItem;
                    return (
                      <tr key={`sticky-note-${id}`}>
                        <td>{user.full_name} </td>
                        <td className="date-cell">
                          {showFormattedDate(
                            noteType ? created : acknowledge_at,
                            true,
                          )}
                        </td>
                      </tr>
                    );
                  },
                )}
              </table>
            </>
          )}
        </div>
      </ConfirmModal>
    );
  } else if (isLoading || isNotesHistoryLoading) {
    return (
      <>
        <Loader showLoader />
      </>
    );
  } else {
    return null;
  }
};

export default AcknowledgeHistoryModal;
