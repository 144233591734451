import React from "react";
import cx from "clsx";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../shared/Button/Button";
import styles from "./styles.module.scss";
import { Input } from "../../../../../../shared/Input/Input";
import { TextEditor } from "../../../../../../shared/TextEditor/TextEditor";
import { DatePicker } from "../../../../../../shared/DatePicker/DatePicker";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { useForm } from "./hooks/useForm";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { useBanner } from "./hooks/useBanner";
import { arrayFrom } from "../../../../../../utilities/general";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { useSubmit } from "./hooks/useSubmit";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { Toggle } from "../../../../../../shared/Toggle/Toggle";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";

export function ModalCreateEdit({ bannerId, onClose }) {
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();

  const [intersects, setIntersects] = React.useState([]);
  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);

  const banner = useBanner();
  const submit = useSubmit({ onIntersect: setIntersects, onSuccess: onClose });

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    getError,
    days,
    startDate,
    endDate,
    setFieldValue,
  } = useForm(banner.data || {}, submit.initiate);

  const isError = banner.isError;
  const isLoading = banner.isLoading && !isError;
  const isContentVisible = !isError && !isLoading;

  return (
    <Modal
      isOpen
      size="large"
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      header={
        <Modal.Title>
          {bannerId
            ? t("patientPortal.banner.modal.edit")
            : t("patientPortal.banner.modal.create")}
        </Modal.Title>
      }
      footer={
        <Button
          onClick={() => {
            setIntersects([]);
            handleSubmit();
          }}
          isDisabled={banner.isLoading || submit.isLoading || isError}
          leftAdornment={
            submit.isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {t("patientPortal.banner.modal.submit")}
        </Button>
      }
      contentClassName={styles.root}
    >
      {isError && (
        <AlertBox variant="error" size="small">
          {t("patientPortal.banner.error.fetchOne")}
        </AlertBox>
      )}
      {isLoading &&
        arrayFrom(4).map((i) => (
          <Skeleton key={i} count={1} borderRadius="4px" height={50} />
        ))}
      {isContentVisible && (
        <>
          <div>
            <Input
              name="name"
              placeholder={t("patientPortal.banner.modal.name")}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={!!getError("name")}
              isDisabled={banner.isLoading}
            />
            {!!getError("name") && <InputError>{getError("name")}</InputError>}
          </div>
          <div>
            <TextEditor
              value={values.text}
              isDisabled={banner.isLoading}
              onChange={(x) => setFieldValue("text", x)}
              init={{
                plugins: ["link", "autolink"],
                toolbar:
                  "undo redo bold italic strikethrough subscript superscript link ",
              }}
            />
            {!!getError("text") && <InputError>{getError("text")}</InputError>}
          </div>
          <div className={styles.row}>
            <div>
              <InputLabel>
                {t("patientPortal.banner.modal.startDate")}
              </InputLabel>
              <DatePicker
                selected={startDate.value}
                value={startDate.formattedValue}
                onChange={startDate.onChange}
                isError={!!getError("startDate")}
                minDate={new Date()}
                dateFormat="yyyy-MM-dd"
                ref={startDateRef}
                onClickOutside={() => startDateRef.current.setOpen(false)}
                isDisabled={banner.isLoading}
              />
              {!!getError("startDate") && (
                <InputError>{getError("startDate")}</InputError>
              )}
            </div>
            <div>
              <InputLabel>{t("patientPortal.banner.modal.endDate")}</InputLabel>
              <DatePicker
                selected={endDate.value}
                value={endDate.formattedValue}
                onChange={endDate.onChange}
                isError={!!getError("endDate")}
                minDate={new Date(startDate.value || undefined)}
                dateFormat="yyyy-MM-dd"
                ref={endDateRef}
                onClickOutside={() => endDateRef.current.setOpen(false)}
                isDisabled={banner.isLoading}
              />
              {!!getError("endDate") && (
                <InputError>{getError("endDate")}</InputError>
              )}
            </div>
          </div>
          <div className={styles.footerRow}>
            <div>
              <div className={styles.days}>
                {days.list.map((day) => (
                  <button
                    key={day}
                    className={cx({
                      [styles.active]: days.value.includes(day),
                    })}
                    onClick={() => days.onToggle(day)}
                    disabled={banner.isLoading}
                  >
                    {day}
                  </button>
                ))}
              </div>
              {!!getError("days") && (
                <InputError>{getError("days")}</InputError>
              )}
            </div>
            <Toggle
              isEnabled={values.status}
              onChange={() => setFieldValue("status", !values.status)}
            >
              {t("patientPortal.banner.modal.status")}
            </Toggle>
          </div>
          {intersects.length > 0 && (
            <AlertBox variant="error" size="small">
              {t("patientPortal.banner.conflictTooltipSave", {
                list: intersects
                  .filter((x) => x.id !== bannerId)
                  .map((x) => x.name)
                  .join(", "),
              })}
            </AlertBox>
          )}
        </>
      )}
    </Modal>
  );
}
