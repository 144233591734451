import React, { useRef, useState } from "react";
import MaxCharCount from "../../../Common/MaxCharCount";
import EditorWithPreview from "../../../Common/EditorWithPreview";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import classes from "./declineSMSEmailConfigration.module.scss";
import { tCommon } from "../../../../i18n/useAppTranslation";
import { htmlMembershipPreview } from "../../../../Utils/email-template";
import SmsAndEmailTabForConfigration from "./SmsAndEmailTabForConfigration";
import Loader from "../../../Common/Loader";
import clsx from "clsx";
import EmaiSmsConfigration from "./EmaiSmsConfigration";
import DeclinePaymentEmailSmsSnippet from "./DeclinePaymentEmailSmsSnippet";
import { SmsChargeDisclaimer } from "../../../Appointment/shared/SmsChargeDisclaimer/SmsChargeDisclaimer";

export default function DeclinePaymentSMSEmailConfigration({
  handleEmailAndSMSConfigModal,
  handleChangeEmailAndSms,
  isEmailAndSMSConfigModalOpen,
  declined_payment_template_status,
  declined_payment_sms_body,
  declined_payment_email_body,
  declined_payment_email_subject,
  clinic_address,
  emailLogo,
  isEmailOrSms,
  handleSubmitMembership,
  handleEditorChange,
  originalData,
  showLoader,
  patientOrClient
}) {
  const [isSelectEmail, setIsSelectEmail] = useState(false);
  const [isPreviewBtnOpen, setIsPreviewBtnOpen] = useState(false);
  const emailFooter = useRef(htmlMembershipPreview());

  const handleIsPreviewBtnOpen = (isOpen) => {
    setIsPreviewBtnOpen(isOpen);
  };

  const onCloseMainModal = () => {
    const {
      declined_payment_template_status,
      declined_payment_sms_body,
      declined_payment_email_body,
      declined_payment_email_subject,
    } = originalData || {};
    handleEmailAndSMSConfigModal({
      isEmailAndSMSConfigModalOpen: false,
      declined_payment_template_status,
      declined_payment_sms_body,
      declined_payment_email_body,
      declined_payment_email_subject,
      isEmailOrSms,
    });
  };

  const handleEmailOrSms = (isEmail) => {
    setIsSelectEmail(isEmail);
    handleEmailAndSMSConfigModal({
      isEmailOrSms: !isEmail,
    });
  };

  if (declined_payment_email_subject) {
    emailFooter.current = emailFooter.current.replace(
      /{{MEMBERSHIP_EMAIL_SUBJECT}}/g,
      declined_payment_email_subject,
    );
  }

  let clinicCityStateZip = '';
  if (clinic_address?.clinic) {
    if (clinic_address.clinic.clinic_city) {
      clinicCityStateZip += `${clinic_address.clinic.clinic_city}, `;
    } else if (clinic_address.clinic.city) {
      clinicCityStateZip += `${clinic_address.clinic.city}, `;
    }

    if (clinic_address.clinic.clinic_state) {
      clinicCityStateZip += `${clinic_address.clinic.clinic_state}, `;
    } else if (clinic_address.clinic.country) {
      clinicCityStateZip += `${clinic_address.clinic.country}, `;
    }

    if (clinic_address.clinic.clinic_zipcode) {
      clinicCityStateZip += clinic_address.clinic.clinic_zipcode;
    }

  }



  return (
    <div>
      <ConfirmModal
        isOpen={isEmailAndSMSConfigModalOpen}
        onClose={onCloseMainModal}
        className={classes.mainContainer}
        title={tCommon("label.configureDeclineEmailSMS")}
      >
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className={"switch-accordian-row"} id="cancel">
              <div className={classes.declinesmsEmailTitleSection}>
                <SmsAndEmailTabForConfigration
                  classes={classes}
                  isSelectEmail={isSelectEmail}
                  handleEmailOrSms={handleEmailOrSms}
                />
                <EmaiSmsConfigration
                  declined_payment_template_status={
                    declined_payment_template_status
                  }
                  handleChangeEmailAndSms={handleChangeEmailAndSms}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xs-12">
            <div className="m-b-40">
              <EditorWithPreview
                name={"declined_payment_email_body"}
                placeholder={"Email Body"}
                value={declined_payment_email_body}
                className={clsx({
                  [classes.previewButtonOpen]: isPreviewBtnOpen || showLoader,
                  ["no-display"]: isSelectEmail,
                })}
                handleEditorChange={handleEditorChange}
                showPreviewButton={true}
                prePostHTML={emailFooter.current
                  ?.replace(
                    /{{MEMBERSHIP_EMAIL_HEADING}}/g,
                    `${declined_payment_email_subject}`,
                  )
                  .replace(/{{ACCOUNT_LOGO}}/g, `${emailLogo}`)
                  .replace(/{{CLINIC_ADDRESS}}/g, `${clinic_address?.clinic?.address || ''}`)
                  .replace(/{{CLINIC_CITY_STATE_COUNTRY_ZIP}}/g, `${clinicCityStateZip}`)}
                fromMembershipWallet={true}
                isPreviewBtnOpen={handleIsPreviewBtnOpen}
              />
              {isSelectEmail && (
                <>
                  <div className={classes.instructions}>Instructions</div>
                  <textarea
                    className={classes.newtextareaField}
                    placeholder="SMS Message"
                    id="membership_canceled_sms"
                    value={declined_payment_sms_body || ""}
                    rows={10}
                    name="declined_payment_sms_body"
                    onChange={handleChangeEmailAndSms}
                    style={{ resize: "none" }}
                  />

                  <MaxCharCount
                    currentValue={declined_payment_sms_body || ""}
                    maxCharCount={1300}
                  />
                  <div className='m-t-10'>
                  <SmsChargeDisclaimer />
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={clsx("col-md-12 col-xs-12", {
              [classes.previewButtonOpen]: isPreviewBtnOpen,
            })}
          >
            <div className="AppointmentSubtitle">Snippets</div>
            <DeclinePaymentEmailSmsSnippet title={patientOrClient} />
          </div>
        </div>
        <div className="footer-static p-r-24">
          <button
            className="new-blue-btn pull-right"
            onClick={handleSubmitMembership}
          >
            {tCommon("label.save")}
          </button>
        </div>
        {showLoader && <Loader showLoader={showLoader} isFullWidth={true} />}
      </ConfirmModal>
    </div>
  );
}
