import React from "react";
import cx from "clsx";
import styles from "./ClietLoyaltyProgram.module.scss";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { getPathParams } from "../../utils/utils";
import { Checkbox } from "../../../../../shared/Checkbox/Checkbox";

export function ClientLoyaltyProgram() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });

  return (
    <div>
      <div className={cx(styles.clientWrapper, "col-xs-12")}>
        <div className={styles.title}>Loyalty Programs Enrollment</div>
        <div className={styles.programBlock}>
          <div className={styles.item}>
            <div className={styles.itemText}>Alle:</div>
            <Checkbox name="alleStatus" isChecked={Boolean(clientData?.alleStatus)} isDisabled />
          </div>
          <div className={styles.item}>
            <div className={styles.itemText}>Aspire:</div>
            <Checkbox name="aspireStatus" isChecked={Boolean(clientData?.aspireStatus)} isDisabled />
          </div>
          <div className={styles.item}>
            <div className={styles.itemText}>Evolus:</div>
            <Checkbox name="evolusStatus" isChecked={Boolean(clientData?.evolusStatus)} isDisabled />
          </div>
          <div className={styles.item}>
            <div className={styles.itemText}>
              Xperience:
            </div>
            <Checkbox name="xperienceStatus" isChecked={Boolean(clientData?.xperienceStatus)} isDisabled />
          </div>
          <div className={styles.item}>
            <div className={styles.itemText}>
              RepeatMD:
            </div>
            <Checkbox name="repeatMdStatus" isChecked={Boolean(clientData?.repeatMdStatus)} isDisabled />
          </div>
        </div>
      </div>
    </div>
  );
}
