import moment from "moment";
import uuid from "uuid";
import {
  MUTATION_RECORD_CREATE_MODE_ID,
  MUTATION_RECORD_FIELDS,
} from "./config";
import { Maybe } from "../../../utilities/fp";
import { API_DATE_FORMAT } from "../../../consts/api";
import { graphEntrySchema } from "./schemas";
import { getTimezonedNow } from "../../../utilities/time";
import { clinicTimezone } from "../../../utilities/localStorage";

export const vitalToMutationRecordAdapter = (patientVital) => ({
  [MUTATION_RECORD_FIELDS.id]:
    patientVital?.id || MUTATION_RECORD_CREATE_MODE_ID,

  [MUTATION_RECORD_FIELDS.date]: Maybe.of(patientVital?.vital_date)
    .map((v) => moment(v).format(API_DATE_FORMAT))
    .orElse(moment().format(API_DATE_FORMAT))
    .value(),

  [MUTATION_RECORD_FIELDS.height]: patientVital?.height,

  [MUTATION_RECORD_FIELDS.weight]: patientVital?.weight,

  [MUTATION_RECORD_FIELDS.bmi]: patientVital?.bmi,

  [MUTATION_RECORD_FIELDS.bodyFat]: patientVital?.body_fat,

  [MUTATION_RECORD_FIELDS.bloodPressureSystolic]:
    patientVital?.blood_pressure_systolic,

  [MUTATION_RECORD_FIELDS.bloodPressureDiastolic]:
    patientVital?.blood_pressure_diastolic,

  [MUTATION_RECORD_FIELDS.pulse]: patientVital?.pulse,

  [MUTATION_RECORD_FIELDS.temperature]: patientVital?.temperature,

  [MUTATION_RECORD_FIELDS.respiration]: patientVital?.respiration,

  [MUTATION_RECORD_FIELDS.notes]: patientVital?.id ? undefined : [],
});

export const createGraphEntry = ({ field, graph, startDate, endDate }) =>
  graphEntrySchema.validateSync(
    {
      id: uuid.v4(),
      field,
      data: graph,
      startDate,
      endDate,
    },
    { strict: true },
  );

export const formatGraphDate = (date) => moment(date).format(API_DATE_FORMAT);

export const createMutationRecordNote = ({ note, author }) => ({
  id: uuid.v4(),
  date: getTimezonedNow(clinicTimezone.get()),
  note,
  author,
});

export const createMutationRecordSnapshot = (snapshot) =>
  JSON.stringify(snapshot);
