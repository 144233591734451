import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./ErrorLogs.module.scss";

export function ErrorLogs({ logs, className, label, isOpenInitial }) {
  const [isOpen, setIsOpen] = useState(isOpenInitial);

  return (
    <div className={cx(classes.root, className)}>
      <button
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
        className={cx(classes.btn, {
          [classes.btnOpen]: isOpen,
        })}
      >
        {label}
        <span
          className={cx(classes.triangle, {
            [classes.triangleUp]: isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div className={classes.logs}>
          {logs.map((log, index) => {
            if (typeof log === "string") {
              return (
                <div key={`${log}-${index}`} className={classes.error}>
                  {log}
                </div>
              );
            } else if (typeof log === "object" && log !== null) {
              return (
                <div
                  key={`${log.label}-${index}`}
                  className={classes.nestedLog}
                >
                  <div className={classes.nestedLogLabel}>{log.label}</div>
                  <div className={classes.nestedLogErrors}>
                    {log.errors.map((error, index) => (
                      <div key={`${error}-${index}`} className={classes.error}>
                        {error}
                      </div>
                    ))}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

ErrorLogs.propTypes = {
  logs: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
  ]),
  className: PropTypes.string,
  label: PropTypes.string,
  isOpenInitial: PropTypes.bool,
};

ErrorLogs.defaultProps = {
  logs: [],
  className: undefined,
  label: "Error Logs",
  isOpenInitial: false,
};
