import * as yup from "yup";
import { useFormik } from "formik";
import { phoneUtil } from "../../../../../utilities/phone";

export function useUpdateForm({ initialValues, onSubmit, isPhoneRequired }) {
  const schema = yup.object({
    name: yup.string().required("Name is required"),

    email: yup.string().email("Provide valid client email"),

    phone: yup
      .string()
      .test({
        message: "Please provide phone number",
        test: (value) => !(isPhoneRequired && !value),
      })
      .test({
        message: "Provide valid phone number",
        test: (value) => {
          return !value ? true : phoneUtil.isPossible(phoneUtil.compose(value));
        },
      }),
  });

  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    setTouched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit,
  });

  const validate = async () => {
    const errors = await setTouched(
      {
        name: true,
        email: true,
        phone: true,
      },
      true,
    );
    return Object.keys(errors).length === 0;
  };

  const setValue = (field, value) => {
    setFieldTouched(field);
    setFieldValue(field, value);
  };

  const getError = (field) => {
    if (errors[field] && touched[field]) {
      return errors[field];
    }
    return "";
  };

  return {
    form: values,
    setFormValue: setValue,
    getError,
    submit: handleSubmit,
    dirty,
    isValid,
    validate,
  };
}
