import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import Header from "../../../_legacy/Containers/Guest/Header.js";
import Footer from "../../../_legacy/Containers/Guest/Footer.js";
import { Button } from "../../../shared/Button/Button.js";
import { useApiOtpSend } from "./hooks/useApiOtpSend.js";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress.js";
import classes from "./sass/AuthorizeToLogin.module.scss";
import { uiNotification } from "../../../services/UINotificationService.js";
import { ConfirmForm } from "./components/ConfirmForm.js";
import { useApiOtpValidate } from "./hooks/useApiOtpValidate.js";
import { useApiSetPasswd } from "./hooks/useApiSetPasswd.js";
import { SetPasswdForm } from "./components/SetPasswdForm.js";
import { clearToken, clearUserData } from "../../../Utils/services.js";
import { delay } from "../../../utilities/general.js";
import { Completed } from "./components/Completed.js";
import { AlertBox } from "../../../shared/AlertBox/AlertBox.js";
import { useUserPhoneLastDigitsQuery } from "../../../api/user/useUserPhoneLastDigitsQuery.js";

function AuthorizeToLogin({ match, history }) {
  const workspaceId = match.params.id;
  const otpSend = useApiOtpSend();
  const otpValidate = useApiOtpValidate();
  const setPasswd = useApiSetPasswd();

  const [isCompleted, setIsCompleted] = useState(false);

  const onComplete = async () => {
    setIsCompleted(true);
    await delay(2000);
    history.push("/login");
  };

  const handleSend = async ({ onSuccess }) => {
    try {
      await otpSend.initiate({
        workspaceId,
      });
      if (onSuccess) {
        onSuccess();
      } else {
        uiNotification.success("Text message sent! Enter the code above");
      }
    } catch (e) {
      uiNotification.error(e.message);
    }
  };

  const handleResend = () => {
    handleSend({
      onSuccess: () => uiNotification.success("We've Resent Your OTP Code"),
    });
  };

  const handleValidate = async ({ code }) => {
    try {
      const { isNeedPassword } = await otpValidate.initiate({
        workspaceId,
        code,
      });
      if (!isNeedPassword) {
        onComplete();
      }
    } catch (e) {
      uiNotification.error(e.message);
    }
  };

  const handleSetPassword = async ({ password }) => {
    try {
      await setPasswd.initiate({ password, wpuId: otpValidate.wpuId });
      onComplete();
    } catch (e) {
      uiNotification.error(e.message);
    }
  };

  const phoneLast4Query = useUserPhoneLastDigitsQuery({
    payload: {
      hash: workspaceId,
    },
    options: {
      enabled: !!workspaceId,
    },
  });

  useEffect(() => {
    clearToken();
    clearUserData();
  }, []);

  const isSendOtpVisible = !isCompleted && !otpSend.isSent;
  const isConfirmVisible =
    !isCompleted && otpSend.isSent && !otpValidate.isValid;
  const isSetPasswdVisible =
    !isCompleted &&
    otpSend.isSent &&
    otpValidate.isValid &&
    otpValidate.isNeedPassword;

  return (
    <div className="guest no-padding arNextLoginguest">
      <Header />
      <div className={classes.content}>
        {isCompleted && <Completed />}
        {isSendOtpVisible && (
          <div className={classes.sendOtp}>
            {phoneLast4Query.data?.last4 && (
              <AlertBox size="small">
                {`You will receive OTP to phone number ***${phoneLast4Query.data.last4}`}
              </AlertBox>
            )}
            <Button
              onClick={handleSend}
              isDisabled={otpSend.isLoading}
              leftAdornment={
                otpSend.isLoading && (
                  <CircularProgress color="white" size="small" />
                )
              }
            >
              Send OTP Code
            </Button>
          </div>
        )}
        {isConfirmVisible && (
          <ConfirmForm
            resend={{
              initiate: handleResend,
              isLoading: otpSend.isLoading,
            }}
            confirm={{
              initiate: handleValidate,
              isLoading: otpValidate.isLoading,
            }}
          />
        )}
        {isSetPasswdVisible && (
          <SetPasswdForm
            set={{
              initiate: handleSetPassword,
              isLoading: setPasswd.isLoading,
            }}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(AuthorizeToLogin);
