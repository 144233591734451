import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tSales } from "../../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import RemainingBalanceDue from "../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentFormModal = ({ form, errors, hasError, changeFormValue }) => {
  const { invoice } = useInvoice();

  return (
    <>
      <>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <div>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.note")}
          </InputLabel>
          <Textarea
            value={form.note}
            onChange={(e) => changeFormValue("note", e.target.value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.note")}
          />
        </div>
        <RemainingBalanceDue amount={form.amount} />
      </>
    </>
  );
};

export default PaymentFormModal;
