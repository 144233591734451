import { ACTION_TYPES } from "./consts";

const initialState = {
  inventoryFilter: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.setFilter: {
      return {
        ...state,
        inventoryFilter: action.payload.inventoryFilter || null,
      };
    }
    case ACTION_TYPES.removeFilter: {
      return {
        ...state,
        inventoryFilter: null,
      };
    }
    default: {
      return state;
    }
  }
}
