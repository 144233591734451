import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getIsPosEnabled, toggleBodyScroll } from '../../../../Utils/services.js';

const userData = JSON.parse(localStorage.getItem('userData'));

export default class PosDisputeTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //stripeAccount : (userData && userData.account && userData.account.pos_gateway === 'stripe' && userData.account.account_type === 'paid' && userData.account.stripe_mode === 'custom' && userData.user_type === 'superadmin') ? true : false,
      //pos_enabled : getIsPosEnabled(),
      action: localStorage.getItem('disputeTabType')
    }
  }

  redirectToDispute = () => {
    return (
      <div>
        {this.props.history.push(`/settings/pos-disputes/${this.state.action}`)}
      </div>
    )
  }


  render() {
    return (
      <div className="title-sub-navigation">
        <div className="setting-setion m-b-10">
          <div className="membership-title">
            {(this.props.isNavigateToDispute === true) ? <span className="cursor-pointer" onClick={this.redirectToDispute}>{this.props.settingsLang.sidebar_POS_disputes_only_menu}</span> : this.props.settingsLang.sidebar_POS_disputes_only_menu}
            {(this.props.getPosDisputeSetting) &&
              <div className="memberRightActions">
                <a className="new-blue-btn" onClick={this.props.getPosDisputeSetting}>{this.props.settingsLang.settings}</a>
              </div>
            }
          </div>
        </div>

        </div>
    )
  }
}
