import React from "react";
import { DayPicker } from "react-day-picker";
import "./day-picker.css";
import "react-day-picker/dist/style.css";

const CalendarDayPicker = ({
  mode,
  selectedDays,
  onChange,
  disabled,
  ...rest
}) => {
  return (
    <DayPicker
      mode={mode}
      selected={selectedDays}
      onSelect={onChange}
      disabled={disabled}
      {...rest}
    />
  );
};

export default CalendarDayPicker;
