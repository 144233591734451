import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import {showFormattedDate, getDateFormat, dateFormatPicker, getTimeFormat } from '../../../Utils/services.js';


const valueToNull = (value) => {
  if(typeof value != undefined && value != null && value != "0000-00-00" && value != "0000-00-00 00:00:00"){
    return  moment(value).toDate();
  } else {
    return null;
  }
}

export default class SetTimeModal extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.state = {
      globalLang: languageData.global,
      confirmationAction: (props.confirmationAction) ? props.confirmationAction : '',
      confirmationMsg: (props.confirmationMsg) ? props.confirmationMsg : '',
      showConfirmationModal: (props.showConfirmationModal) ? props.showConfirmationModal : false,
      maxDate: (this.props.end_time) ? moment(this.props.end_time).toDate() : moment().format('YYYY-MM-DD 00:00'),
      minDate: (this.props.start_time) ? moment(this.props.start_time).toDate() : moment().format('YYYY-MM-DD HH:mm'),
      value:(this.props.clock_time) ? valueToNull(this.props.clock_time) :'',
      format: (getTimeFormat() === 'hh:mm A' ) ? 'hh:mm aa' : getTimeFormat(), //(this.props.format) ? this.props.format : 'yyyy-MM-dd',
      name:(this.props.name) ? this.props.name : 'date_picker',
      class: (this.props.class) ? this.props.class : 'simpleInput'
    }
  }

  confirmationAction = () => {
    if (this.props.confirmationAction) {
      this.props.confirmationAction();
      this.resetConfirmation();
    } else {
      this.resetConfirmation();
    }
  }

  resetConfirmation = () => {
    this.props.onResetConfirmation({ confirmationAction: '', confirmationMsg: '', showConfirmationModal: false });
  }


  onChangeDatePicker = (date) => {
    this.props.handleChildState({'clock_time': date })
  }

  render() {
    return (
      <div className=''>
        <div className={(this.props.showConfirmationModal) ? 'modalOverlay' : 'no-display'}>
          <div className="small-popup-outer xs-popup clockinOuter">
            <div className="small-popup-header p-l-15">
              <div className="popup-name">{this.props.modalTitle}</div>
              <a className="small-cross" onClick={this.resetConfirmation}>×</a>
            </div>
            <div className="small-popup-content">
              <div className="juvly-container">
                <div className="row">
                  <div className='col-lg-12'>
                    <div className="simpleField field-icon clock-time-picker">
                      <div className="simpleLabel">{this.props.modalTitle +" Time"}<span className="setting-require">*</span></div>
                      <DatePicker
                        className={this.state.class}
                        selected = {(this.props.clock_time) ? valueToNull(this.props.clock_time): null}
                        onChange={this.onChangeDatePicker}
                        maxTime={this.state.maxDate}
                        minTime={this.state.minDate}
                        autoComplete='off'
                        name={'clock_time'}
                        readOnly={this.props.readOnly}
                        placeholderText={this.state.format.toLowerCase()}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        timeFormat={this.state.format}
                        dateFormat={this.state.format}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-static">
                <a className="new-blue-btn pull-right no-width m-l-10" data-dismiss="modal" onClick={() => this.confirmationAction()}>{this.state.globalLang.label_yes}</a>
                <a className="new-blue-btn pull-right no-width"  data-dismiss="modal" onClick={() => this.resetConfirmation()}>{this.state.globalLang.label_no}</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
