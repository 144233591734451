export const DEFAULT_COLORS = [
  "#ec0b0b",
  "#e30bec",
  "#990bec",
  "#0c0bec",
  "#0b9eec",
  "#10d4dc",
  "#11da4d",
  "#87cd0f",
  "#c0c40f",
  "#e78d0b",
];
