import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";
import { patientConsentSchema } from "./schema";

const requestPayload = yup.object({
  patientConsentId: yup.number().required(),
});

const responseSchema = patientConsentSchema;

const composeResponse = (res) => {
  return res?.data?.data || null;
};

function createPatientConsentQueryKey(id) {
  return [QUERY_KEYS.patientConsents.getOne, id];
}

export function usePatientConsentQuery({ payload, options = {} } = {}) {
  return useQuery(
    createPatientConsentQueryKey(payload.patientConsentId),
    async () => {
      const req = requestPayload.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.patientConsents.getOne(req.patientConsentId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
