import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  cherryPatientId: yup.string().required(),
  invoiceId: yup.number().required(),
  isPartial: yup.bool().required(),
  patientId: yup.number().required(),
  amountOfCents: yup.number().required(),
});

const responseSchema = yup.object({
  paymentId: yup.string().required(),
});

const composeResponse = (res) => {
  const details = unwrapOr(() => res.data.data, {});
  return {
    paymentId: details.cherryPayment?.id,
  };
};

export function useCreateCherryPaymentMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });

    const res = await http.post(HTTP_ENDPOINTS.cherry.createPayment(), {
      payment: {
        // order matters
        patientId: req.patientId,
        cherryPatientId: req.cherryPatientId,
        paymentAmountOfCents: req.amountOfCents,
        invoiceId: req.invoiceId,
        partial: req.isPartial,
      },
    });

    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
