import { pipe, last } from "../../utilities/fp";

/**
 * @typedef {{
 *   label: string;
 *   description: string | null;
 *   value: string | number;
 *}} SubstituteItem
 *
 * @typedef {{
 *    match: string;
 *    substitutes: SubstituteItem[]
 * }} Pattern
 */

/**
 * @param {string} haystack
 * @param {Array<Pattern>} patterns
 * @param {number} caretPos
 * @returns {{
 *    match: string;
 *    start: number;
 *    end: number;
 *    substitutes: SubstituteItem[];
 * } | null}
 */
export function extractToken(haystack, patterns, caretPos) {
  if (
    typeof haystack !== "string" ||
    !Array.isArray(patterns) ||
    typeof caretPos !== "number" ||
    caretPos < 0
  ) {
    return null;
  }

  var lastWithCaret = pipe(
    haystack,
    (x) => x.slice(0, caretPos),
    (x) => x.split(/\s/),
    last,
  );

  var needle = patterns.find((p) => lastWithCaret.includes(p.match));

  if (needle) {
    const searchTerm = lastWithCaret.split(needle.match).join("") || "";

    const hasPattern = (text, pattern) =>
      text.toLowerCase().includes(pattern.toLowerCase());

    const searchPredicate = (s) =>
      hasPattern(s.label, searchTerm) || hasPattern(s.description, searchTerm);

    return {
      match: lastWithCaret,
      start: caretPos - lastWithCaret.length,
      end: caretPos,
      substitutes: needle.substitutes?.filter(searchPredicate) || [],
    };
  }

  return null;
}

/**
 * @param {string} text
 * @param {string | number} value
 * @param {number} start
 * @param {number} end
 */
export function substitute(text, value, start, end) {
  return text.slice(0, start) + value + text.slice(end);
}
