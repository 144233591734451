import { REDUCER_NAME } from "./consts";

export const selectors = {
  /**
   *
   * @returns {string}
   */
  selectPaymentView: (state) => state?.[REDUCER_NAME]?.paymentView,

  /**
   *
   * @returns {boolean}
   */
  selectIsInvoiceWatchMode: (state) =>
    state?.[REDUCER_NAME]?.isInvoiceWatchMode || false,

  /**
   *
   * @returns {boolean}
   */
  selectIsCherryPaymentWatchMode: (state) =>
    state?.[REDUCER_NAME]?.isCherryPaymentWatchMode || false,

  selectIsTipsApplying: (state) =>
    state?.[REDUCER_NAME]?.isTipsApplying || false,

  selectIsTipsOnlyFlow: (state) =>
    state?.[REDUCER_NAME]?.isTipsOnlyFlow || false,

  selectCalculatedChange: (state) =>
    state?.[REDUCER_NAME]?.calculatedChange || 0,
};
