import React from "react";
import classes from "./InfoRow.module.scss";

export function InfoRow({ title, children }) {
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}:</div>
      {children}
    </div>
  );
}
