import React from "react";
import PropTypes from "prop-types";
import classes from "./Section.module.scss";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";

export function Section({ label, options, isLoading }) {
  return (
    <div className={classes.root}>
      <h4>{label}</h4>
      {isLoading
        ? Array.from(Array(3).keys()).map((k) => (
            <Skeleton key={k} count={1} height={21} borderRadius="4px" />
          ))
        : options
            .filter((o) => o.value)
            .map((o) => (
              <div key={o.label}>
                <b>{o.label}:</b> {o.value}
              </div>
            ))}
    </div>
  );
}

Section.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
