import { http } from "../services/HttpService";

export const fetchStripeUpdateCheck = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_STRIPE_UPDATE_CHECK" });
    http
      .get("account/stripe-update-check")
      .then((response) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_CHECK_SUCCESS",
          payload: {
            message: response.data && response.data.message,
            accountId:
              response.data.data && response.data.data.account_update.acct,
            accountNeedsUpdate:
              response.data.data && response.data.data.account_needs_update,
            tosNeedsUpdate:
              response.data.data && response.data.data.tos_needs_update,
            accounts:
              response.data.data &&
              response.data.data.tos_update &&
              response.data.data.tos_update.accounts,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_CHECK_ERROR",
          payload: error.response.data.message,
        });
      });
  };
};

export const fetchStripeUpdateUrl = (accountId) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_STRIPE_UPDATE_URL" });
    http
      .get(`account/stripe-update/${accountId}`)
      .then((response) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_URL_SUCCESS",
          payload: response.data.data.url,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_URL_ERROR",
          payload: error.response.data.message,
        });
      });
  };
};

export const fetchStripeTosUpdate = (accounts) => {
  const accountsQueryString = accounts
    .map((item) => {
      return `accounts[]=${item}`;
    })
    .join("&");

  return (dispatch) => {
    http
      .get("account/stripe-update-tos?" + accountsQueryString)
      .then((response) => {
        dispatch({
          type: "FETCH_STRIPE_TOS_UPDATE",
          payload: response.data.data.accounts,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_STRIPE_TOS_UPDATE",
          payload: error.response.data.message,
        });
      });
  };
};
