const clearanceReducer = (state = { data: "", status: "" }, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
      };
    }
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    case "CLEARANCE_ALL_RESOURCES": {
      return {
        ...state,
        data: action.payload,
        action: "CLEARANCE_ALL_RESOURCES",
      };
    }
    case "CLEARANCE_CREATE_APPLICATION": {
      return {
        ...state,
        data: action.payload,
        action: "CLEARANCE_CREATE_APPLICATION",
      };
    }
    case "CLEARANCE_SAVE_DATA": {
      return { ...state, data: action.payload, action: "CLEARANCE_SAVE_DATA" };
    }
    case "CLEARENT_SAVED_DATA": {
      return { ...state, data: action.payload, action: "CLEARENT_SAVED_DATA" };
    }
    case "CLEARENT_UPDATE_SIGNATURE": {
      return {
        ...state,
        data: action.payload,
        action: "CLEARENT_UPDATE_SIGNATURE",
      };
    }
    case "DOWNLOAD_AGREEMENT": {
      return { ...state, data: action.payload, action: "DOWNLOAD_AGREEMENT" };
    }
    case "SAVE_APP_DATA": {
      return { ...state, data: action.payload, action: "SAVE_APP_DATA" };
    }
    case "DELETE_CLEARENT_CONTACT": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_CLEARENT_CONTACT",
      };
    }
    default: {
      return state;
    }
  }
};

export default clearanceReducer;
