import { useMemo } from "react";
import { useProviderAssociatedClinicsQuery } from "../../../../api/queries/useProviderAssociatedClinicsQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useClinicsQuery } from "../../../../api/queries/useClinicsQuery";

const clinicToOption = (c) => ({
  label: c.name,
  value: c.id,
});

export function useClinicOptions({ providerId, onProviderClinicsFetched }) {
  const { tCommon } = useAppTranslation.Common();

  const { data: allClinics, isFetching: isAllClinicsLoading } = useClinicsQuery(
    {
      enabled: !providerId,
      onError: () => {
        uiNotification.error(tCommon("error.fetchClinics"));
      },
    },
  );

  const { data: providerClinics, isFetching: isProviderClinicsLoading } =
    useProviderAssociatedClinicsQuery({
      payload: {
        providerId,
      },
      options: {
        enabled: Boolean(providerId),
        onError: () => {
          uiNotification.error(tCommon("error.fetchClinics"));
        },
        onSuccess: (data) => {
          onProviderClinicsFetched(data?.clinics?.map(clinicToOption) || []);
        },
      },
    });

  return useMemo(() => {
    const preparedAllClinics = allClinics?.map(clinicToOption) || [];

    const preparedProviderClinics =
      providerClinics?.clinics?.map(clinicToOption) || [];

    return {
      data: providerId ? preparedProviderClinics : preparedAllClinics,
      isLoading: isAllClinicsLoading || isProviderClinicsLoading,
    };
  }, [
    allClinics,
    providerClinics?.clinics,
    providerId,
    isAllClinicsLoading,
    isProviderClinicsLoading,
  ]);
}
