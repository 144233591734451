import React from "react";
import moment from "moment";
import { Header } from "./components/Header";
import { Table } from "./components/Table";
import { useReports } from "./hooks/useReports";
import { ReportLayout } from "../../../../BusinessInsights/components/ReportLayout/ReportLayout";
import { API_DATE_FORMAT } from "../../../../../consts/api";
import { SALES_DECLINE_REPORT_PAYMENT_STATUSES } from "./hooks/useMembershipDeclineReport";

export function MembershipDeclineReport() {
  const { reports, order, period } = useReports();

  const requestParams = {
    fromDate: moment(period.value.from).format(API_DATE_FORMAT),
    toDate: moment(period.value.to).format(API_DATE_FORMAT),
    payment_status: SALES_DECLINE_REPORT_PAYMENT_STATUSES.failed,
  };

  return (
    <ReportLayout>
      <Header
        period={period}
        isReportsLoading={reports.isLoading}
        requestParams={requestParams}
      />
      <Table reports={reports} order={order} />
    </ReportLayout>
  );
}
