import React from "react";
import moment from "moment";
import { Group } from "../../shared/Group/Group";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { MultiRowSelect } from "../../boxes/MultiRowSelect/MultiRowSelect";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../consts/general";
import { getIsSomeTruthy } from "../../../../../../helpers/general";
import { getPastMedicalHistoryCheck } from "../../../MergeProfilesMain.utils";

export function PastMedHistory({ intersection, pastHistories, onChange }) {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();
  const { data: currentUser } = useCurrentUserQuery();
  const isSomeTruthy = getIsSomeTruthy(pastHistories);

  return (
    <Group biggerGap label={tClients("medicalHistory.pastMedHistory.label")}>
      {isSomeTruthy(["surgeries"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.pastMedHistory.surgeries")}
          value={intersection.surgery || []}
          patientOptions={pastHistories.map((h) => h.surgery || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              surgery: value,
              surgeries: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getPastMedicalHistoryCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tClients("medicalHistory.pastMedHistory.surgeryType"),
                  value: option.type,
                },
                {
                  label: tClients("medicalHistory.pastMedHistory.surgeryDate"),
                  value: moment(option.date).format(
                    currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                  ),
                },
                {
                  label: tCommon("label.physician"),
                  value: option.physician,
                },
                {
                  label: tCommon("label.city"),
                  value: option.city,
                },
                {
                  label: tCommon("label.state"),
                  value: option.state,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["hospitalizations"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.pastMedHistory.hospitalizations")}
          value={intersection.hospitalization || []}
          patientOptions={pastHistories.map((h) => h.hospitalization || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              hospitalization: value,
              hospitalizations: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getPastMedicalHistoryCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tClients(
                    "medicalHistory.pastMedHistory.hospitalizationReason",
                  ),
                  value: option.reason,
                },
                {
                  label: tClients(
                    "medicalHistory.pastMedHistory.hospitalizationDate",
                  ),
                  value: moment(option.date).format(
                    currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                  ),
                },
                {
                  label: tCommon("label.hospital"),
                  value: option.hospital,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["implants"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.pastMedHistory.implants")}
          value={intersection.implant || []}
          patientOptions={pastHistories.map((h) => h.implant || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              implant: value,
              implants: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getPastMedicalHistoryCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tClients("medicalHistory.pastMedHistory.implantDate"),
                  value: moment(option.date).format(
                    currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                  ),
                },
                {
                  label: tClients("medicalHistory.pastMedHistory.implantType"),
                  value: option.type,
                },
                {
                  label: tClients("medicalHistory.pastMedHistory.implantPlace"),
                  value: option.place,
                },
              ]}
            />
          )}
        />
      )}
      {isSomeTruthy(["vaccines"]) && (
        <MultiRowSelect
          name={tClients("medicalHistory.pastMedHistory.vaccines")}
          value={intersection.vaccine || []}
          patientOptions={pastHistories.map((h) => h.vaccine || [])}
          onChange={(value) => {
            const nextIntersection = {
              ...intersection,
              vaccine: value,
              vaccines: value?.length === 0 ? 0 : 1,
            };
            onChange({
              ...nextIntersection,
              check: getPastMedicalHistoryCheck(nextIntersection),
            });
          }}
          renderOption={(option) => (
            <MultiRowSelect.Option
              data={[
                {
                  label: tClients("medicalHistory.pastMedHistory.vaccine"),
                  value: option.vaccine,
                },
                {
                  label: tClients(
                    "medicalHistory.pastMedHistory.vaccinationDate",
                  ),
                  value: moment(option.date).format(
                    currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                  ),
                },
              ]}
            />
          )}
        />
      )}
    </Group>
  );
}
