import { isEqual } from "lodash";
import React, { useState } from "react";

export const withFilesToDownload = (Component) => (props) => {
  const [filesToDownload, setFilesToDownload] = useState([]);

  const selectAll = (files) => setFilesToDownload(files);

  const deselectAll = () => setFilesToDownload([]);

  const selectOne = (f) => setFilesToDownload((prev) => [...prev, f]);

  const deselectOne = (f) =>
    setFilesToDownload((prev) => prev.filter((file) => !isEqual(f, file)));

  const isSelected = (f) =>
    Boolean(filesToDownload.find((file) => isEqual(f, file)));

  const isAllSelected = (files) =>
    filesToDownload.length === files.length &&
    files.every((f) =>
      Boolean(filesToDownload.find((file) => isEqual(f, file))),
    );

  const toggleAll = (files) => {
    if (isAllSelected(files)) {
      deselectAll();
    } else {
      selectAll(files);
    }
  };

  const toggleOne = (f) => {
    if (isSelected(f)) {
      deselectOne(f);
    } else {
      selectOne(f);
    }
  };

  const clear = () => {
    setFilesToDownload([]);
  };

  return (
    <Component
      {...props}
      filesToDownload={{
        isSelected,
        isAllSelected,
        toggleAll,
        toggleOne,
        clear,
        value: filesToDownload,
      }}
    />
  );
};
