import { positionFooterCorrectly } from "../Utils/services.js";
import { http } from "../services/HttpService.js";
import { uiNotification } from "../services/UINotificationService.js";

positionFooterCorrectly();

export const fetchClinics = (formData) => {
  return (dispatch) => {
    http
      .get("clinics", formData)
      .then((response) => {
        dispatch({ type: "CLINIC_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLINIC_LIST", payload: error.response.data });
      });
  };
};

export const fetchSelectedClinic = (formData, clinicId) => {
  return (dispatch) => {
    http
      .get("clinics/" + clinicId + "?scopes=business_hours", formData)
      .then((response) => {
        dispatch({ type: "SELECTED_CLINIC_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SELECTED_CLINIC_LIST",
          payload: error.response.data,
        });
      });
  };
};

export const fetchDefaultClinicData = () => {
  return (dispatch) => {
    http
      .get("get_clinic_default_data")
      .then((response) => {
        dispatch({ type: "DEFAULT_CLINIC_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DEFAULT_CLINIC_DATA", payload: error.response.data });
      });
  };
};

export const createClinic = (formData) => {
  return (dispatch) => {
    http
      .post("clinics", formData)
      .then((response) => {
        dispatch({ type: "CREATE_CLINIC", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_CLINIC", payload: error.response.data });
      });
  };
};

export const updateSelectedClinic = (formData, clinicId) => {
  return (dispatch) => {
    http
      .patch("clinics/" + clinicId, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_SELECTED_CLINIC", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SELECTED_CLINIC",
          payload: error.response.data,
        });
      });
  };
};

export const fetchAllTimezone = () => {
  return (dispatch) => {
    http
      .get("timezone-list")
      .then((response) => {
        dispatch({ type: "TIMEZONE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "TIMEZONE_LIST", payload: error.response.data });
      });
  };
};

export const fetchAllCountries = () => {
  return (dispatch) => {
    http
      .get("country-list")
      .then((response) => {
        dispatch({ type: "COUNTRIES_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "COUNTRIES_LIST", payload: error.response.data });
      });
  };
};

export const deleteClinic = (clinicId) => {
  return async (dispatch) => {
    return http
      .delete("clinics/" + clinicId)
      .then((response) => {
        dispatch({ type: "DELETE_CLINIC", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "DELETE_CLINIC_ERROR", payload: error.response.data });
        const message = error.response.data.message;

        if (message === "clinic_has_schedule") {
          uiNotification.error(
            "Unable to delete a clinic with a schedule built",
          );
        } else if (
          message !== "delete_clinic_active_membership_error" &&
          error.response.data.message !== "delete_clinic_appointment_error"
        ) {
          uiNotification.error(error.response.data.message);
        }

        return Promise.reject(error.response.data);
      });
  };
};

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}
