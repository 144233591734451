import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  clinicId: yup.number().required(),
});

const responseSchema = yup
  .array()
  .of(
    yup.object({
      id: yup.number().required(),
      name: yup.string().required(),
      order: yup.number().nullable(),
      discountPercent: yup.number().required(),
      bogoPrice: yup.number().required(),
    }),
  )
  .required();

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, []);
  return data.map((d) => ({
    id: d.id,
    name: d.name,
    order: d.ob_order || null,
    discountPercent: d.discount_percentage || 0,
    bogoPrice: d.package_bogo_price || 0,
  }));
};

function createDiscountPackagesObOrderQueryKey({ clinicId }) {
  return [QUERY_KEYS.discountPackagesObOrder, clinicId];
}

export function useDiscountPackagesObOrderQuery({ payload, options = {} }) {
  return useQuery(
    createDiscountPackagesObOrderQueryKey({
      clinicId: payload.clinicId,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.getDiscountPackagesObOrder(req.clinicId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
