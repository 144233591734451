import moment from "moment";
import { formatUTC } from "../../../../Utils/dateHelper";
import {
  CLOCK_IN_MIN_DATE_BACKOFF_AMOUNT_DAYS,
  FORM_TIME_FORMAT,
} from "./ManualTimeModal.consts";
import { PREVIEW_TIME_FORMAT } from "../../../../consts/general";

export const getHeader = (name) => {
  if (name) {
    return `Add Time for ${name}`;
  }
  return "Add Time";
};

export const wrapFormTime = (nextDate) => {
  return moment(nextDate).format(FORM_TIME_FORMAT);
};

export const unwrapFormTime = (formTime) => {
  if (formTime) {
    return moment.utc(formTime).format(PREVIEW_TIME_FORMAT);
  }
  return null;
};

export const unwrapSelectedFormTime = (formTime) => {
  if (formTime) {
    return formatUTC(moment.utc(formTime).toDate());
  }
  return null;
};

export const getClockInBoundaries = () => {
  return {
    minDate: moment(new Date())
      .subtract(CLOCK_IN_MIN_DATE_BACKOFF_AMOUNT_DAYS, "days")
      .toDate(),
    maxDate: new Date(),
    minTime: undefined,
    maxTime: undefined,
  };
};

export const getClockOutBoundaries = (formClockInTime) => {
  let result = {
    minDate: undefined,
    maxDate: new Date(),
    minTime: undefined,
    maxTime: undefined,
  };
  if (formClockInTime) {
    result.minDate = moment(formClockInTime).toDate();
  }
  return result;
};
