import { useAddFreeTaxableFileMutation } from "../../../../../../api/mutations/useAddFreeTaxableFileMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { isApiFile } from "../utilities";
import { extractApiError } from "../../../../../../utilities/api";

const messages = {
  file_must_be_less_20mb: "File must be less than 20MB",
  file_must_be_file: "Invalid file format",
};

export function useUploadFile() {
  const upload = useAddFreeTaxableFileMutation({
    onSuccess: () => {
      uiNotification.success("File uploaded successfully");
    },
    onError: (e) => {
      const message = extractApiError(e);
      uiNotification.error(messages[message] || "Failed to upload file");
    },
  });

  return {
    initiate: (file) => {
      if (!isApiFile(file)) {
        upload.mutate({ file: file.original });
      }
    },
    isLoading: upload.isLoading,
  };
}
