import React, { useState } from "react";
import { EntityHeader } from "../../../../../widgets/EntityHeader/EntityHeader";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import styles from "./styles.module.scss";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { DateRangePicker } from "../../../../../shared/DateRangePicker/DateRangePicker";

/**
 * @param {object} param0
 *
 * @param {{
 *  value: string;
 *  onChange: (v: string) => void;
 * }} param0.search
 *
 * @param {{
 *  count: number;
 *  isLoading: boolean;
 *  initiate: () => void;
 * }} param0.restore
 *
 * @param {{
 *  value: {
 *    from: Date;
 *    to: Date;
 *  };
 *  onChange: (v: { from: Date; to: Date }) => void;
 * }} param0.period
 */
export const Header = ({ search, restore, period }) => {
  const { tSettings } = useAppTranslation.Settings();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { data: user } = useCurrentUserQuery();

  return (
    <>
      <EntityHeader
        title={tSettings("deletedNotes.title")}
        searchValue={search.value}
        onChangeSearch={search.onChange}
        right={
          <div className={styles.right}>
            <DateRangePicker
              size="small"
              dateFormat={user?.dateFormat}
              onChange={({ startDate, endDate }) => {
                period.onChange({
                  from: startDate,
                  to: endDate,
                });
              }}
              value={{
                startDate: period.value.from,
                endDate: period.value.to,
              }}
            />
            <Button
              isDisabled={restore.count === 0 || restore.isLoading}
              size="small"
              onClick={() => setIsConfirmModalOpen(true)}
              leftAdornment={
                restore.isLoading ? (
                  <CircularProgress size="tiny" color="white" />
                ) : undefined
              }
            >
              {tSettings("deletedNotes.restore", { count: restore.count })}
            </Button>
          </div>
        }
      />
      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={() => {
            restore.initiate();
            setIsConfirmModalOpen(false);
          }}
        >
          {tSettings("deletedNotes.confirm.restore")}
        </ConfirmModal>
      )}
    </>
  );
};
