import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useSetUserPasswordMutation(options = {}) {
  return useMutation((dto) => {
    return http.post(HTTP_ENDPOINTS.postSetUserPassword(), {
      wpu_id: dto.wpuId,
      password: dto.password,
    });
  }, options);
}
