import React from "react";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputPhone } from "../../../../../../shared/InputPhone/InputPhone";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { InputError } from "../../../../../../shared/InputError/InputError";
import CopyIcon from "../../../../../../assets/Icons/CopyIcon";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { Select } from "../../../../../../shared/Select/Select";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";

/**
 * @param {object} param0
 * @param {Function} param0.onSubmit
 * @param {boolean} param0.isSubmitting
 * @param {object} param0.faxNumber
 * @param {string?} param0.faxNumber.value
 * @param {Function} param0.faxNumber.onChange
 * @param {string?} param0.faxNumber.error
 * @param {object} param0.packageVal
 * @param {number?} param0.packageVal.value
 * @param {Function} param0.packageVal.onChange
 * @param {string?} param0.packageVal.error
 * @param {Array<{
 *   label: string;
 *   value: number;
 * }>} param0.packages
 */
export const Form = ({
  onSubmit,
  isSubmitting,
  faxNumber,
  packageVal,
  isIntegrationEnabled,
  packages,
}) => {
  const { tSettings } = useAppTranslation.Settings();

  const copyNumber = () => {
    navigator.clipboard.writeText(faxNumber.value).then(() => {
      uiNotification.success(tSettings("efaxIntegration.success.copied"));
    });
  };

  return (
    <div className={styles.root}>
      {!isIntegrationEnabled && (
        <h5>
          <b>{tSettings("efaxIntegration.faxNumberTitle")}</b>
        </h5>
      )}
      <div className={styles.phoneBox}>
        <InputLabel>{tSettings("efaxIntegration.faxNumber")}</InputLabel>
        <div className={styles.phoneInputBox}>
          <InputPhone
            fullWidth
            withSearch
            value={faxNumber.value}
            onChange={faxNumber.onChange}
            isError={!!faxNumber.error}
            isDisabled={isIntegrationEnabled}
            onlyCountries={["us"]}
            flagButtonClassName={styles.flagButton}
          />
          {isIntegrationEnabled && (
            <button className={styles.copyIcon} onClick={copyNumber}>
              <CopyIcon height={24} />
            </button>
          )}
        </div>
        {faxNumber.error && <InputError>{faxNumber.error}</InputError>}
        <AlertBox size="small">
          {tSettings("efaxIntegration.feeAlert")}
        </AlertBox>
      </div>
      {!isIntegrationEnabled && (
        <>
          <h5>
            <b>{tSettings("efaxIntegration.packageTitle")}</b>
          </h5>
          <div className={styles.packageBox}>
            <InputLabel>{tSettings("efaxIntegration.package")}</InputLabel>
            <Select
              options={packages}
              value={packages.find((p) => p.value === packageVal.value)}
              onChange={(option) => packageVal.onChange(option.value)}
              isError={!!packageVal.error}
            />
            {packageVal.error && <InputError>{packageVal.error}</InputError>}
          </div>
        </>
      )}
      {!isIntegrationEnabled && (
        <>
          <div className={styles.desc}>
            <i>{tSettings("efaxIntegration.description.row1")}</i>
            <i>{tSettings("efaxIntegration.description.row2")}</i>
          </div>
          <Button
            onClick={onSubmit}
            isDisabled={isSubmitting || !faxNumber.value}
            leftAdornment={
              isSubmitting ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {tSettings("efaxIntegration.purchaseNumber")}
          </Button>
        </>
      )}
    </div>
  );
};
