import React from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Modal } from "../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import classes from "./AddModal.module.scss";
import { InputPhone } from "../../../../../shared/InputPhone/InputPhone";
import { phoneUtil } from "../../../../../utilities/phone";
import { InputError } from "../../../../../shared/InputError/InputError";

export function AddModal({ isOpen, onClose, onAdd, isAdding }) {
  const { tInbox } = useAppTranslation.Inbox();
  const { tCommon } = useAppTranslation.Common();

  const schema = yup.object({
    phone: yup
      .string()
      .test({
        test: (value) =>
          !value || phoneUtil.isPossible(phoneUtil.compose(value)),
        message: tCommon("formError.phoneInvalid"),
      })
      .required(tCommon("formError.phoneRequired")),
  });

  const form = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    onSubmit: (values) => onAdd(values),
  });

  const hasError = (field) => {
    return Boolean(form.touched[field] && form.errors[field]);
  };

  return (
    <Modal
      footerNoBorder
      size="small"
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Title>{tInbox("blackList.addModal.label")}</Modal.Title>}
      footer={
        <div className={classes.footer}>
          <Button
            onClick={form.handleSubmit}
            isDisabled={isAdding}
            leftAdornment={
              isAdding ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {tInbox("blackList.addModal.submit")}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {tInbox("blackList.addModal.cancel")}
          </Button>
        </div>
      }
    >
      <InputPhone
        fullWidth
        value={form.values.phone}
        onChange={(phone) => form.setFieldValue("phone", phone)}
        isError={hasError("phone")}
      />
      {hasError("phone") && <InputError>{form.errors.phone}</InputError>}
    </Modal>
  );
}

AddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
};
