import { usePatientVitalsSettingViewsQuery } from "../../../../api/patientVitals/usePatientVitalsSettingViewsQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export const useSettingViews = () => {
  const { tClients } = useAppTranslation.Clients();

  const { data, isLoading, isSuccess } = usePatientVitalsSettingViewsQuery({
    onError: () => {
      uiNotification.error(tClients("vitals.settings.error.fetchViews"));
    },
  });

  return {
    data: data?.map((i) => i.name) || [],
    isLoading,
    isSuccess,
  };
};
