import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getTraceData(clientID, procedureID) {
  return async (dispatch) => {
    return http
      .get("traceability_info/" + clientID + "/" + procedureID)
      .then((response) => {
        dispatch({ type: "GET_TRACE_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_TRACE_DATA", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getBatchByProcedure(productID, procedureID, type, traceID) {
  return async (dispatch) => {
    return http
      .get(
        "traceability/get_batch/" +
          procedureID +
          "/" +
          productID +
          "/" +
          type +
          "/" +
          traceID,
      )
      .then((response) => {
        dispatch({ type: "GET_BATCH_BY_PROCEDURE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_BATCH_BY_PROCEDURE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function getUnits(formData) {
  return async (dispatch) => {
    return http
      .post("traceability/get_units?type=diapason", formData)
      .then((response) => {
        dispatch({ type: "GET_UNITS_BY_INVENTORY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_UNITS_BY_INVENTORY",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveTrace(formData) {
  return async (dispatch) => {
    return http
      .post("traceability/save", formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "SAVE_TRACE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_TRACE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function updateTrace(formData) {
  return async (dispatch) => {
    return http
      .post("traceability/update", formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "UPDATE_TRACE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_TRACE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function deleteTrace(formData) {
  return async (dispatch) => {
    return http
      .post("traceability/delete", formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "DELETE_TRACE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "DELETE_TRACE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function searchProduct(formData) {
  return async (dispatch) => {
    return http
      .post("search-product", formData)
      .then((response) => {
        dispatch({ type: "SEARCH_TRACE_PRODUCTS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SEARCH_TRACE_PRODUCTS",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveTraceProducts(formData) {
  return async (dispatch) => {
    return http
      .post("add-injections", formData)
      .then((response) => {
        dispatch({ type: "ADD_INJECTIONS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "ADD_INJECTIONS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}
