import React from "react";
import PropTypes from "prop-types";
import { Input } from "../../shared/Input/Input";
import { svg } from "../../assets/svg";
import { removeNullishFromShape } from "../../utilities/general";
import { tCommon } from "../../i18n/useAppTranslation";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import classes from "./InputSearch.module.scss";

export function InputSearch({
  name,
  value,
  onChange,
  className,
  isError,
  isDisabled,
  isReadonly,
  placeholder,
  size,
  width,
  isSearching,
  autoFocus,
  autoComplete,
}) {
  return (
    <Input
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      size={size}
      leftAdornment={
        isSearching ? (
          <CircularProgress color="gray1" size="tiny" />
        ) : (
          <img
            src={svg.searchGray1}
            alt="search"
            className={classes.searchIcon}
          />
        )
      }
      className={className}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isError={isError}
      isReadonly={isReadonly}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      style={removeNullishFromShape({
        width,
      })}
    />
  );
}

InputSearch.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  isSearching: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
};

InputSearch.defaultProps = {
  name: "search",
  value: "",
  isError: false,
  isDisabled: false,
  isReadonly: false,
  placeholder: tCommon("label.searchPlaceholder"),
  size: "small",
  width: undefined,
  isSearching: false,
  autoFocus: false,
  autoComplete: undefined,
};
