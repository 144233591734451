import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useChargeForm } from "../../hooks/useChargeForm";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import ChargeFormKlarna from "./ChargeFormKlarna";
import ConfirmChargeKlarna from "./ConfirmChargeKlarna";

const ViewChargeKlarna = (props) => {
  const { klarnaPaymentStatus, cancelKlarnaPayment } = props;

  const [paymentDone, setPaymentDone] = useState(false);
  const { refetchInvoice, invoice, amountToPay } = useInvoice();
  const { klarna, paymentViewClose } = useInvoiceActions();
  const { tSales } = useAppTranslation.Sales();

  const onClose = () => {
    paymentViewClose();
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
    phoneNumber: yup.string().required("Please Enter Phone Number"),
    email: yup
      .string()
      .required(tSales("checkoutInvoice.formError.emailRequired")),
  });

  const chargeFormHandle = useChargeForm({
    schema,
    onSubmit: ({ amount, phoneNumber, email }) => {
      klarna
        .initiate({
          amount,
          phoneNumber,
          email,
        })
        .then(onClose);
    },
    initialValues: {
      amount: amountToPay || "",
      phoneNumber: invoice?.patient?.phoneNumber || "",
      email: invoice?.patient?.email || "",
    },
  });

  const hasKlarnaPaymentOngoing = () =>
    klarnaPaymentStatus.status === "loading" &&
    cancelKlarnaPayment.status === "pending";

  const showKlarnaForm = () => !hasKlarnaPaymentOngoing() && !paymentDone;

  useEffect(() => {
    if (
      klarnaPaymentStatus?.status === "success" ||
      klarnaPaymentStatus?.status === "error"
    ) {
      setPaymentDone(true);
      refetchInvoice().then(() => {});
    }
  }, [klarnaPaymentStatus]);

  return (
    <>
      {showKlarnaForm() && (
        <ChargeFormKlarna chargeFormHandle={chargeFormHandle} />
      )}
      {hasKlarnaPaymentOngoing() && (
        <ConfirmChargeKlarna formValues={chargeFormHandle.form} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ongoingKlarnaPayment: state.KlarnaReducer.ongoingKlarnaPayment,
    cancelKlarnaPayment: state.KlarnaReducer.cancelKlarnaPayment,
    klarnaPaymentStatus: state.KlarnaReducer.klarnaPaymentStatus,
  };
};

export default connect(mapStateToProps)(ViewChargeKlarna);
