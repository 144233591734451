const initialState = {
  aspireConfigData: {
    aspireConfig: {},
    status: "pending",
    error: null,
    invokeRedirect: false,
  },
};

const AspireIntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ASPIRE_CONFIG_LOADING": {
      return {
        ...state,
        aspireConfigData: {
          ...state.aspireConfigData,
          status: "loading",
          error: null,
        },
      };
    }
    case "GET_ASPIRE_CONFIG_SUCCESS": {
      return {
        ...state,
        aspireConfigData: {
          ...state.aspireConfigData,
          ...action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case "SAVE_ASPIRE_CONFIG_SUCCESS": {
      return {
        ...state,
        aspireConfigData: {
          ...state.aspireConfigData,
          ...action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case "ASPIRE_CONFIG_ERROR": {
      return {
        ...state,
        aspireConfigData: {
          ...state.aspireConfigData,
          status: "error",
          error: action.payload.error,
          invokeRedirect: action.payload.invokeRedirect,
        },
      };
    }
    default:
      return state;
  }
};

export default AspireIntegrationReducer;
