import { useFormik } from "formik";
import { useCallback } from "react";

/**
 * @template {FormikValues} Values
 * @param {FormikConfig<Values>} args
 */
export function useExtendedFormik(args) {
  const form = useFormik(args);

  const getError = useCallback(
    (field) => {
      if (form.touched[field] && form.errors[field]) {
        return form.errors[field];
      }
    },
    [form.touched, form.errors],
  );

  const getFirstError = useCallback(() => {
    const firstErrorEntry = Object.entries(form.errors)?.[0];

    if (form.touched?.[firstErrorEntry?.[0]]) {
      return firstErrorEntry[1];
    }
  }, [form.touched, form.errors]);

  /**
   * @deprecated use form.setFieldValue instead
   */
  const changeField = useCallback((field, value) => {
    form.setFieldTouched(field);
    form.setFieldValue(field, value);
  }, []);

  const setFormTouched = useCallback(() => {
    form.setTouched(
      Object.keys(form.values).reduce(
        (carry, key) => ({
          ...carry,
          [key]: true,
        }),
        {},
      ),
    );
  }, [form.values]);

  return {
    ...form,
    getError,
    getFirstError,
    changeField,
    setFormTouched,
  };
}
