/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

class DiscountClinicDetail extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      showLoadingText: false,
      languageData: languageData.business_insights,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.discountClinicDetail !== undefined &&
      nextProps.discountClinicDetail !== prevState.discountClinicDetail
    ) {
      returnState.discountClinicDetail = nextProps.discountClinicDetail;
      returnState.showLoader = false;
      returnState.isMembershipDetail = true;
    }
    return returnState;
  }

  discountClinicBack = () => {
    this.props.discountClinicBack();
  };

  handleDiscountClinicExport = (exportType) => {
    this.props.handleDiscountClinicExport(
      exportType,
      this.props.discountClinicId,
    );
  };

  fetchMembershipRevenueDetail = (action_type) => {
    this.setState({ action_type: action_type });
    let returnState = {
      discountClinicId: this.props.discountClinicId,
      discountClinicName: this.props.discountClinicName,
    };
    this.props.fetchMembershipRevenueDetail(returnState);
  };

  render() {
    return (
      <div className="juvly-section setting-setion m-b-10 m-t-10">
        <div className="juvly-container memberShip-revenue p-b-20">
          <div className="juvly-title no-margin">
            {this.state.languageData.bi_discounts +
              " - " +
              this.props.discountClinicName +
              " (" +
              this.props.selectedDate +
              ")"}{" "}
            <a
              onClick={() => this.discountClinicBack()}
              className="pull-right crossIcon m-b-10"
            >
              <img src="/images/close.png" alt="" />
            </a>
          </div>

          {this.props.discountClinicDetail &&
            this.props.discountClinicDetail.length > 0 && (
              <div className="mainRightActions">
                <a
                  className="easy-link relative"
                  onClick={() =>
                    this.handleDiscountClinicExport(
                      "xls",
                      this.props.discountClinicId,
                    )
                  }
                >
                  <i className="fa fa-download"></i> Download Excel
                </a>
              </div>
            )}
        </div>

        <div className="table-responsive">
          <table className="table-updated juvly-table no-hover">
            <thead className="table-updated-thead">
              <tr>
                <th className="table-updated-th">
                  {this.props.discountClinicHeader &&
                    this.props.discountClinicHeader.discount_name}
                </th>
                <th className="table-updated-th">
                  {this.props.discountClinicHeader &&
                    this.props.discountClinicHeader.times_applied}
                </th>
                <th className="table-updated-th">
                  {this.props.discountClinicHeader &&
                    this.props.discountClinicHeader.amount}
                </th>
              </tr>
            </thead>
            <tbody className="table-updated-tbody">
              {this.props.discountClinicDetail &&
              this.props.discountClinicDetail.length > 0 ? (
                this.props.discountClinicDetail.map((obj, idx) => {
                  return (
                    <tr key={idx} className="table-updated-tr">
                      <td className="table-updated-td">{obj.discount_name}</td>
                      <td className="table-updated-td">{obj.times_applied}</td>
                      <td className="table-updated-td">{obj.amount}</td>
                    </tr>
                  );
                })
              ) : (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float text-center"
                    colSpan={6}
                  >
                    {this.state.globalLang.sorry_no_record_found}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DiscountClinicDetail;
