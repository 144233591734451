import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useRestoreSurveysMutation } from "../../../../api/mutations/useRestoreSurveysMutation";

export function useRestoreSurveys({ selectedSurveyIds, onSuccess }) {
  const { tSettings } = useAppTranslation.Settings();

  const { mutate, isLoading } = useRestoreSurveysMutation({
    onSuccess: () => {
      uiNotification.success(tSettings("deletedSurveys.success.restore"));
      onSuccess();
    },
    onError: () => {
      uiNotification.error(tSettings("deletedSurveys.error.restore"));
    },
  });

  return {
    restoreSurveys: () => mutate({ surveyIds: selectedSurveyIds }),
    isSurveysRestoring: isLoading,
  };
}
