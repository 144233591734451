import { getPatientVitalsGraph } from "../../../api/patientVitals/usePatientVitalsGraphQuery";
import { ACTION_TYPES } from "./config";
import { createGraphEntry, formatGraphDate } from "./utilities";

const activeRecordIdChange = (id) => ({
  type: ACTION_TYPES.activeRecordIdChange,
  payload: {
    id,
  },
});

const setMutationRecord = (mutationRecord) => ({
  type: ACTION_TYPES.setMutationRecord,
  payload: {
    mutationRecord,
  },
});

const dropMutationRecord = () => ({
  type: ACTION_TYPES.dropMutationRecord,
});

const patchMutationRecordField = ({ field, value }) => ({
  type: ACTION_TYPES.patchMutationRecordField,
  payload: {
    field,
    value,
  },
});

const addMutationRecordNote = (note) => ({
  type: ACTION_TYPES.addMutationRecordNote,
  payload: {
    note,
  },
});

const updateMutationRecordNote = ({ id, note }) => ({
  type: ACTION_TYPES.updateMutationRecordNote,
  payload: {
    id,
    note,
  },
});

const deleteMutationRecordNote = (id) => ({
  type: ACTION_TYPES.deleteMutationRecordNote,
  payload: {
    id,
  },
});

const appendMutationRecordFieldErrors = (errors) => ({
  type: ACTION_TYPES.appendMutationRecordFieldErrors,
  payload: errors,
});

const clearMutationRecordFieldErrors = () => ({
  type: ACTION_TYPES.clearMutationRecordFieldErrors,
});

const graphDelete = ({ patientId, id }) => ({
  type: ACTION_TYPES.graphDelete,
  payload: {
    patientId,
    id,
  },
});

const graphAdd = ({ patientId, graph }) => ({
  type: ACTION_TYPES.graphAdd,
  payload: {
    patientId,
    graph,
  },
});

const graphUpdate = ({ patientId, id, graph }) => ({
  type: ACTION_TYPES.graphUpdate,
  payload: {
    patientId,
    id,
    graph,
  },
});

const graphSetIsMutating = (status) => ({
  type: ACTION_TYPES.graphSetIsMutating,
  payload: {
    status,
  },
});

const graphGenerate =
  ({ patientId, field, startDate, endDate, id }) =>
  async (dispatch) => {
    try {
      dispatch(graphSetIsMutating(true));

      const graphData = await getPatientVitalsGraph({
        patientId,
        field,
        period: {
          from: startDate,
          to: endDate,
        },
      });

      if (graphData.length > 0) {
        const graphEntry = createGraphEntry({
          field,
          graph: graphData,
          startDate: formatGraphDate(startDate),
          endDate: formatGraphDate(endDate),
        });

        if (id) {
          dispatch(
            graphUpdate({
              patientId,
              id,
              graph: graphEntry,
            }),
          );
        } else {
          dispatch(
            graphAdd({
              patientId,
              graph: graphEntry,
            }),
          );
        }

        return "success";
      } else {
        return "not_enough_data";
      }
    } catch {
      return "error";
    } finally {
      dispatch(graphSetIsMutating(false));
    }
  };

export const actions = {
  activeRecordIdChange,
  setMutationRecord,
  dropMutationRecord,
  patchMutationRecordField,
  appendMutationRecordFieldErrors,
  clearMutationRecordFieldErrors,
  graphDelete,
  graphGenerate,
  addMutationRecordNote,
  updateMutationRecordNote,
  deleteMutationRecordNote,
};
