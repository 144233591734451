import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchAgreement,
  exportEmptyData,
} from "../../../../Actions/Settings/settingsActions.js";
import {
  autoScrolling,
  capitalizeFirstLetter,
} from "../../../../Utils/services.js";
import Loader from "../../../../Components/Common/Loader.js";
import { tCommon } from "../../../../i18n/useAppTranslation.js";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";

const checkPermission = (globalPermission, specificPermission) => {
  return globalPermission.find((str) => str === specificPermission);
};

class MembershipAgreement extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    const userData = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      agreementList: [],
      page: 1,
      pagesize: 15,
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      showLoadingText: false,
      loadMore: true,
      startFresh: true,
      showLoader: false,
      isTouchMd:
        userData && userData.account
          ? userData.account.account_preference.is_touch_md
          : 0,
      plan_selected: userData.account.account_subscription.plan_code,
    };

    this.props.exportEmptyData({});

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  getAgreement(formData) {
    this.setState({showLoader: true});
    this.props.fetchAgreement(formData)
      .finally(() => {
        this.setState({showLoader: false}); 
      });
  }

  componentDidMount() {
    let formData = {
      params: {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        term: this.state.term,
        scopes: this.state.scopes,
      },
    };
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      membership_header: languageData.settings["membership_header"],
      title_name_patient_work: languageData.settings["title_name_patient_work"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      clinics_Search: languageData.settings["clinics_Search"],
    });
    autoScrolling(true)
    this.getAgreement(formData);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder,
        term: this.state.term,
        scopes: this.state.scopes,
      },
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortorder: this.state.sortorder == "asc" ? "asc" : "desc",
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      agreementList: [],
    });
    autoScrolling(true)
    this.getAgreement(formData);
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortorder:
            this.state.sortorder && this.state.sortorder === "asc"
              ? "asc"
              : this.state.sortorder == "desc"
              ? "desc"
              : "",
          term: this.state.term,
        },
      };
      autoScrolling(true)
      this.getAgreement(formData);
    }
  };

  componentWillUnmount() {
    this.props.exportEmptyData({});
  }

  componentDidUpdate() {
    if (this.state.showLoader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }

  agreementEdit = (id) => {
    this.props.history.push(`/settings/membership-agreements/${id}/edit`);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }
    if (
      nextProps.agreementList != undefined &&
      nextProps.agreementList.data.next_page_url !== prevState.next_page_url
    ) {
      let returnState = {};
      if (prevState.next_page_url == null) {
        localStorage.setItem("sortOnly", false);
        returnState.next_page_url = null;
        autoScrolling(false);
        return returnState;
      }
      if (prevState.agreementList.length == 0 && prevState.startFresh == true) {
        if (localStorage.getItem("sortOnly") == "false") {
          returnState.agreementList = nextProps.agreementList.data.data;
          if (nextProps.agreementList.data.next_page_url != null) {
            returnState.page = prevState.page + 1;
          } else {
            returnState.next_page_url =
              nextProps.agreementList.data.next_page_url;
          }
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
          localStorage.setItem("showLoader", false);
        } else {
          localStorage.setItem("sortOnly", false);
        }
      } else if (
        prevState.agreementList != nextProps.agreementList.data.data &&
        prevState.agreementList.length != 0
      ) {
        returnState.agreementList = [
          ...prevState.agreementList,
          ...nextProps.agreementList.data.data,
        ];
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.agreementList.data.next_page_url;
        localStorage.setItem("showLoader", false);
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      }
      autoScrolling(false);
      return returnState;
    }
    return null;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  render() {
    const userPermissions = JSON.parse(
      localStorage.getItem("userData"),
    ).permissions;

    let sideBarPermissions = [...userPermissions];

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  {this.state.title_name_patient_work}
                  <div className="memberRightActions">
                    <form
                      onSubmit={this.handleSubmit}
                      className="searchForm m-l-0"
                    >
                      <span className="searchOuter">
                        <i className="fa fa-search search-icon" />
                        <input
                          className="setting-search-input search-key"
                          data-url="/settings/questionnaires"
                          name="term"
                          placeholder={this.state.clinics_Search}
                          autoComplete="off"
                          value={this.state.term}
                          onChange={this.handleInputChange}
                        />
                      </span>
                    </form>
                    <Link
                      to="/settings/membership-agreements/create"
                      className="new-blue-btn pull-right"
                    >
                      {"Create Agreement"}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  <div className="newTabsOuter">
                    {this.state.isTouchMd == 1 &&
                    this.state.plan_selected == "practice_booking"
                      ? ""
                      : checkPermission(
                          sideBarPermissions,
                          "manage-questionnaires",
                        ) && (
                          <Link
                            to="/settings/questionnaires"
                            className="newTabs disabled-tab"
                          >
                            {tCommon("label.simpleQuestionnaires")}
                          </Link>
                        )}
                    {checkPermission(
                      sideBarPermissions,
                      "manage-questionnaires",
                    ) && (
                      <Link
                        to="/settings/procedure-templates"
                        className="newTabs disabled-tab"
                      >
                        {tCommon("label.multiQuestionnaires")}
                      </Link>
                    )}
                    {checkPermission(sideBarPermissions, "manage-consents") && (
                      <Link
                        to="/settings/consents"
                        className="newTabs disabled-tab"
                      >
                        Consents
                      </Link>
                    )}
                    {this.state.isTouchMd == 1 &&
                    this.state.plan_selected == "practice_booking" ? (
                      ""
                    ) : (
                      <Link
                        to="/settings/membership-agreements"
                        className="newTabs"
                      >
                        Membership Agreement
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="juvly-section full-width">
                <div className="table-responsive">
                  <table className="table-updated setting-table no-td-border no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-10 table-updated-th sorting">
                          {this.state.membership_header}
                        </th>
                        <th className="col-xs-2 table-updated-th sorting text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="ajax_body">
                      {this.state.agreementList &&
                        this.state.agreementList.length > 0 &&
                        this.state.agreementList.map((obj, idx) => {
                          return (
                            <tr key={idx} className="table-updated-tr">
                              <td className="col-xs-6  table-updated-td">
                                {obj.name
                                  ? capitalizeFirstLetter(obj.name)
                                  : ""}
                              </td>
                              <td className="col-xs-3  table-updated-td">
                                <a
                                  className="m-r-10 edit_setting easy-link p-l-0"
                                  onClick={() => this.agreementEdit(obj.id)}
                                >
                                  <i className="fa fa-pencil-alt m-r-5" />
                                  Edit
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <div
                      className={
                        this.state.agreementList
                          ? "no-record no-display"
                          : "no-record"
                      }
                    ></div>{" "}
                    {this.state.agreementList.length <= 0 &&
                      this.state.showLoader === false && (
                        <tbody>
                          <tr>
                            <td colSpan="3" className="table-updated-td">
                              <div className="no-record no-float text-center">
                                No Record Found
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                  </table>
                </div>
              </div>
              <div
                className={
                  this.state.showLoadingText
                    ? "loading-please-wait no-margin-top"
                    : "loading-please-wait no-margin-top no-display "
                }
              >
                {this.state.loading_please_wait_text}
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.SettingReducer.action === "AGREEMENT_LIST") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.agreementList = state.SettingReducer.data;
    }
  }

  if (state.SettingReducer.action === "CLINIC_SEARCH") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.clinicSearch = state.SettingReducer.data.data;
    }
  }

  if (state.SettingReducer.action === "SELECTED_CLINIC_LIST") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.clinicSearch = state.SettingReducer;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchAgreement, exportEmptyData },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembershipAgreement);
