import { useEffect, useState } from "react";
import { useQueryParamsState } from "../../utilities/hooks/useQueryParamsState";

/**
 * @param {string} initial
 * @param {string} key
 * @param {number?} delay
 */
export const useDebouncedQueryParamState = (key, initial, delay = 500) => {
  const [state, setState] = useState(initial);

  const [debouncedState, setDebouncedState] = useQueryParamsState({
    [key]: initial,
  });

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (state !== debouncedState?.[key]) {
        setDebouncedState({ [key]: state });
      }
    }, delay);

    return () => {
      clearInterval(debounce);
    };
  }, [delay, state]);

  useEffect(() => {
    if (state !== debouncedState?.[key]) {
      setState(debouncedState?.[key]);
    }
  }, [debouncedState?.[key]]);

  return [state, debouncedState?.[key], setState];
};
