/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import $ from "jquery";
import validator from "validator";
import IntlTelInput from "react-intl-tel-input";
import { Scrollbars } from "react-custom-scrollbars";
import Autocomplete from "react-google-autocomplete";
import { Creatable } from "react-select";
import { toggleBodyScroll, isFormSubmit } from "../../../Utils/services.js";
import picClose from "../../../_legacy/images/popupClose.png";
import { uiNotification } from "../../../services/UINotificationService.js";

const createOption = (label) => ({
  label,
  value: label.toLowerCase(),
});

const initClinicAccountNumbers = (clinicList) => {
  let clinicsAccountNumbers = [];
  if (clinicList.length > 0) {
    clinicList.map((obj) => {
      let accountNumber = {
        account_number: "",
        account_numberClass: "simpleInput",
        clinic_id: obj.id,
        clinic_name: obj.clinic_name,
      };
      clinicsAccountNumbers.push(accountNumber);
    });
    return clinicsAccountNumbers;
  }
};

class CreateEditSupplierModal extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    toggleBodyScroll(true);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      supplierDetails: {},
      email: "",
      defaultCountry: localStorage.getItem("cCode")
        ? localStorage.getItem("cCode")
        : "us",
      supplierId: 0,
      supplierName: "",
      supplierEmail: "",
      supplierPhone: "",
      supplierAddress: "",
      supplierCity: "",
      supplierState: "",
      supplierCountry: "",
      supplierZip: "",
      firstName: "",
      lastName: "",
      contactNumber: "",
      supplierPhoneClass: "simpleInput",
      contactNumberClass: "simpleInput",
      supplierNameClass: "simpleInput",
      supplierEmailClass: "simpleInput",
      supplierCityClass: "simpleInput",
      supplierStateClass: "simpleInput",
      supplierCountryClass: "simpleSelect",
      supplierZipClass: "simpleInput",
      emailClass: "simpleInput",
      firstNameClass: "simpleInput",
      lastNameClass: "simpleInput",
      supplierAddressClass: "simpleInput",
      userChanged: false,
      isRender: props.supplierId ? false : true,
      showModal: false,

      account_number: "",
      account_numberClass: "simpleInput",
      distributor_name: "",
      distributor_nameClass: "simpleInput",
      ordering_phoneError: false,
      ordering_phone: "",
      ordering_phoneClass: "simpleInput",
      ordering_url: "",
      ordering_urlClass: "simpleInput",

      geoLocationClass: "googleLocationInput",
      geoLocationFlag: props.supplierId ? false : true,
      countryList: "",
      countryName: "",
      clinicsAccountNumbers: props.clinic_account_number
        ? props.clinic_account_number
        : initClinicAccountNumbers(props.clinicList ? props.clinicList : []),
      isAccordionOpen: false,
      readOnly: false,

      isLoading: false,
      options: [],
      value: [],
    };
  }

  componentDidMount() {
    toggleBodyScroll(true);
    const _self = this;
    $("#accordionClinicAccounsModal").on(
      "hidden.bs.collapse, hide.bs.collapse",
      function () {
        _self.setState({ isAccordionOpen: false });
      },
    );
    $("#accordionClinicAccounsModal").on(
      "shown.bs.collapse, show.bs.collapse",
      function () {
        _self.setState({ isAccordionOpen: true });
      },
    );
    document.getElementById("new-input").value = this.state.supplierDetails
      .zipcode
      ? this.state.supplierDetails.zipcode
      : "";
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    let emailArray = [];

    if (
      nextProps.supplierDetails &&
      nextProps.supplierDetails !== prevState.supplierDetails
    ) {
      returnState.showLoader = false;
      returnState.supplierDetails = nextProps.supplierDetails;
      returnState.id = nextProps.supplierDetails.id;
      returnState.countryList = nextProps.supplierDetails.countries
        ? nextProps.supplierDetails.countries
        : [];
      returnState.supplierName = nextProps.supplierDetails.supplier_name;
      returnState.supplierEmail = nextProps.supplierDetails.supplier_email;

      if (nextProps.supplierDetails.supplier_email) {
        emailArray = nextProps.supplierDetails.supplier_email.split(",");

        if (emailArray) {
          let selectOptions = [];

          emailArray.map((obj) => {
            selectOptions.push(createOption(obj));
          });
          returnState.options = selectOptions;
          returnState.value = selectOptions;
        }
      }

      returnState.supplierPhone = nextProps.supplierDetails.supplier_phone;
      returnState.supplierAddress = nextProps.supplierDetails.address_1;
      returnState.supplierCity = nextProps.supplierDetails.city;
      returnState.supplierState = nextProps.supplierDetails.state;
      returnState.supplierCountry = nextProps.supplierDetails.country;
      returnState.countryName = nextProps.supplierDetails.country_name
        ? nextProps.supplierDetails.country_name
        : "";
      returnState.supplierZip = nextProps.supplierDetails.zipcode;
      returnState.firstName =
        nextProps.supplierDetails.contact_person_firstname;
      returnState.lastName = nextProps.supplierDetails.contact_person_lastname;
      returnState.email = nextProps.supplierDetails.contact_person_email;
      returnState.contactNumber =
        nextProps.supplierDetails.contact_person_phone;
      returnState.status =
        nextProps.supplierDetails.status == 0 ? "Active" : "Inactive";
      returnState.account_number = nextProps.supplierDetails.account_number;
      returnState.distributor_name = nextProps.supplierDetails.distributor_name;
      returnState.ordering_phone = nextProps.supplierDetails.ordering_phone;
      returnState.ordering_url = nextProps.supplierDetails.ordering_url;
      returnState.isRender = true;
      return returnState;
    }
    return null;
  }

  componentWillUnmount() {
    toggleBodyScroll(false);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (event.target.name === "clinic_account_number") {
      const index = target.dataset.index;
      let clinicsAccountNumbers = this.state.clinicsAccountNumbers;
      clinicsAccountNumbers[index]["account_number"] = value;
      this.setState(clinicsAccountNumbers);
    } else {
      this.setState({
        [event.target.name]: value,
      });
    }
  };

  handleModalSubmit = () => {
    if (isFormSubmit()) {
      let error = false;

      this.setState({
        supplierNameError: false,
        supplierEmailError: false,
        supplierAddressError: false,
        firstnameError: false,
        lastnameError: false,
        email_idError: false,
        stateError: false,
        cityError: false,
        countryError: false,
        supplierZipError: false,
      });

      if (
        typeof this.state.supplierName === undefined ||
        this.state.supplierName === null ||
        this.state.supplierName.trim() === ""
      ) {
        this.setState({
          supplierNameError: true,
          supplierNameClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.supplierName) {
        this.setState({
          supplierNameError: false,
          supplierNameClass: "simpleInput",
        });
      }

      if (
        typeof this.state.supplierAddress === undefined ||
        this.state.supplierAddress === null ||
        this.state.supplierAddress.trim() === ""
      ) {
        this.setState({
          supplierAddressError: true,
          supplierAddressClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.supplierAddress) {
        this.setState({
          supplierAddressError: false,
          supplierAddressClass: "simpleInput",
        });
      }

      const dataArray = this.state.value;
      let dataToPost = [];

      if (dataArray && dataArray.length === 0) {
        uiNotification.clear();
        uiNotification.error("Please select/add at least one recipient.");
        error = true;
      }

      if (dataArray && dataArray.length > 0) {
        dataArray.map((obj) => {
          dataToPost.push(obj.value);
        });
      }

      if (
        typeof this.state.supplierState === undefined ||
        this.state.supplierState === null ||
        this.state.supplierState.trim() === ""
      ) {
        this.setState({
          supplierStateError: true,
          supplierStateClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.supplierState) {
        this.setState({
          supplierStateError: true,
          supplierStateClass: "simpleInput",
        });
      }
      if (
        typeof this.state.supplierCity === undefined ||
        this.state.supplierCity === null ||
        this.state.supplierCity.trim() === ""
      ) {
        this.setState({
          supplierCityError: true,
          supplierCityClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.supplierCity) {
        this.setState({
          supplierCityError: false,
          supplierCityClass: "simpleInput",
        });
      }
      if (
        typeof this.state.supplierCountry === undefined ||
        this.state.supplierCountry === null ||
        this.state.supplierCountry.trim() === ""
      ) {
        this.setState({
          supplierCountryError: true,
          supplierCountryClass: "simpleSelect field-error",
        });
        error = true;
      } else if (this.state.supplierCountry) {
        this.setState({
          countryError: false,
          countryErrorClass: "simpleSelect",
        });
      }

      let oldZip = this.state.supplierZip;
      let newZip = document.getElementById("new-input").value;

      if (this.state.readOnly) {
        if (oldZip != newZip) {
          this.setState({
            supplierZipError: true,
            supplierZipClass: "simpleInput field-error",
          });
          error = true;
          return;
        }
      }

      if (this.state.readOnly) {
        if (
          typeof this.state.supplierZip === undefined ||
          this.state.supplierZip === null ||
          this.state.supplierZip.trim() == ""
        ) {
          this.setState({
            supplierZipError: true,
            supplierZipClass: "simpleInput field-error",
          });
          error = true;
        } else {
          this.setState({
            supplierZipError: false,
            supplierZipClass: "simpleInput",
          });
        }
      } else {
        if (
          typeof document.getElementById("new-input").value === undefined ||
          document.getElementById("new-input").value === null ||
          document.getElementById("new-input").value.trim() == ""
        ) {
          this.setState({
            supplierZipError: true,
            supplierZipClass: "simpleInput field-error",
          });
          error = true;
        } else {
          this.setState({
            supplierZipError: false,
            supplierZipClass: "simpleInput",
          });
        }
      }

      if (
        typeof this.state.email === undefined ||
        this.state.email === null ||
        this.state.email.trim() === ""
      ) {
        this.setState({
          EmailError: true,
          emailClass: "simpleInput field-error",
        });
        error = true;
      } else if (!validator.isEmail(this.state.email)) {
        this.setState({
          EmailError: true,
          emailClass: "simpleInput field-error",
        });
        uiNotification.error("Incorrect email address");
        error = true;
      } else if (this.state.email) {
        this.setState({
          EmailError: false,
          emailClass: "simpleInput",
        });
      }

      if (
        typeof this.state.firstName === undefined ||
        this.state.firstName === null ||
        this.state.firstName.trim() === ""
      ) {
        this.setState({
          firstNameError: true,
          firstNameClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.firstName) {
        this.setState({
          firstNameError: false,
          firstNameClass: "simpleInput",
        });
      }

      if (
        typeof this.state.lastName === undefined ||
        this.state.lastName === null ||
        this.state.lastName.trim() === ""
      ) {
        this.setState({
          lastNameError: true,
          lastNameClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.lastName) {
        this.setState({
          lastNameError: false,
          lastNameClass: "simpleInput",
        });
      }

      if (
        typeof this.state.supplierPhone === undefined ||
        this.state.supplierPhone === null ||
        this.state.supplierPhone.trim() === "" ||
        this.state.supplierPhoneClass == "simpleInput field-error"
      ) {
        this.setState({
          supplierPhoneError: true,
          supplierPhoneClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.supplierPhone) {
        this.setState({
          supplierPhoneError: false,
          supplierPhoneClass: "newInputField",
        });
      }

      if (
        typeof this.state.contactNumber === undefined ||
        this.state.contactNumber === null ||
        this.state.contactNumber.trim() === "" ||
        this.state.contactNumberClass == "simpleInput field-error"
      ) {
        this.setState({
          contactNumberError: true,
          contactNumberClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.contactNumber) {
        this.setState({
          contactNumberError: false,
          contactNumberClass: "newInputField",
        });
      }

      if (
        typeof this.state.distributor_name === undefined ||
        this.state.distributor_name === null ||
        this.state.distributor_name.trim() === ""
      ) {
        this.setState({ distributor_nameClass: "simpleInput field-error" });
        error = true;
      } else if (this.state.distributor_name) {
        this.setState({ distributor_nameClass: "simpleInput" });
      }

      if (
        typeof this.state.ordering_phone === undefined ||
        this.state.ordering_phone === null ||
        this.state.ordering_phone.trim() === "" ||
        this.state.ordering_phoneClass === "simpleInput field-error"
      ) {
        this.setState({
          ordering_phoneError: true,
          ordering_phoneClass: "simpleInput field-error",
        });
        error = true;
      } else if (this.state.ordering_phone) {
        this.setState({
          ordering_phoneError: false,
          ordering_phoneClass: "newInputField",
        });
      }

      if (
        typeof this.state.ordering_url === undefined ||
        this.state.ordering_url === null ||
        this.state.ordering_url.trim() === "" ||
        !validator.isURL(this.state.ordering_url, {
          protocols: ["http", "https"],
        })
      ) {
        this.setState({ ordering_urlClass: "simpleInput field-error" });
        error = true;
      } else if (this.state.ordering_url) {
        this.setState({ ordering_urlClass: "simpleInput" });
      }

      if (
        typeof this.state.account_number === undefined ||
        this.state.account_number === null ||
        this.state.account_number.trim() === ""
      ) {
        this.setState({ account_numberClass: "simpleInput field-error" });
        error = true;
      } else if (this.state.account_number) {
        this.setState({ account_numberClass: "simpleInput" });
      }

      if (error === true) {
        return;
      }

      let clinicsAccountNumbers = [];
      this.state.clinicsAccountNumbers.map((obj) => {
        let accountNumber = {
          clinic_id: obj.clinic_id,
          account_number: obj.account_number ? obj.account_number : "",
        };
        clinicsAccountNumbers.push(accountNumber);
      });

      let formData = {
        supplier_name: this.state.supplierName ? this.state.supplierName : "",
        supplier_email: dataToPost ? dataToPost : "",
        supplier_phone: this.state.supplierPhone
          ? this.state.supplierPhone
          : "",
        address_1: this.state.supplierAddress ? this.state.supplierAddress : "",
        city: this.state.supplierCity ? this.state.supplierCity : "",
        state: this.state.supplierState ? this.state.supplierState : "",
        country: this.state.supplierCountry ? this.state.supplierCountry : "",
        zipcode: this.state.supplierZip ? this.state.supplierZip : "",
        contact_person_firstname: this.state.firstName,
        contact_person_lastname: this.state.lastName,
        contact_person_phone: this.state.contactNumber,
        contact_person_email: this.state.email,
        distributor_name: this.state.distributor_name,
        ordering_phone: this.state.ordering_phone,
        ordering_url: this.state.ordering_url,
        account_number: this.state.account_number,
        clinics: clinicsAccountNumbers,
      };

      if (this.state.readOnly) {
        formData.zipcode = this.state.supplierZip ? this.state.supplierZip : "";
      } else {
        formData.zipcode = document.getElementById("new-input").value;
      }

      if (this.props.supplierId) {
        formData.supplier_id = this.props.supplierId;
      }
      this.props.createEditSupplier(formData);
    }
  };

  dismissModal = () => {
    toggleBodyScroll(false);
    this.setState({ showEditModal: false });
  };

  phoneNumberChanged = (index, t, x, y) => {
    const phoneRegex = new RegExp(/^\+[1-9]\d{1,14}$/);
    const dialCode = y && y.dialCode ? y.dialCode : "";
    const number = typeof x === "string" ? x : t;
    let phoneNumber = `+${dialCode}${number.replace(/[-\s]/g, "")}`;
    phoneNumber = phoneNumber === `+${dialCode}` ? "" : phoneNumber;
    if (phoneRegex.test(phoneNumber)) {
      this.setState({
        [index]: phoneNumber,
        [`${index}Class`]: "simpleInput",
        [index === "ordering_phone"
          ? "ordering_phoneError"
          : "contactError"]: false,
      });
    } else {
      this.setState({
        [index]: phoneNumber,
        [`${index}Class`]: "simpleInput field-error",
        [index === "ordering_phone"
          ? "ordering_phoneError"
          : "contactError"]: true,
      });
    }
  };

  selectLocation = ({ description }) => {
    let city = "";
    let state = "";
    let country = "";
    let countryCode = "";
    let statefill;
    if (description) {
      city = description.replace(/,/, "&").split("&");
      statefill = description.split(/\s*,\s*/);

      if (statefill.length == 3) {
        state = statefill[statefill.length - 2];
        country = statefill[statefill.length - 1];
      } else if (statefill.length == 2) {
        state = city[0];
        country = statefill[statefill.length - 1];
      } else if (statefill.length == 4) {
        state = statefill[statefill.length - 2];
        country = statefill[statefill.length - 1];
      }

      if (country && this.props.countryList) {
        const countryData = this.props.countryList.find(
          (x) =>
            x.country_name &&
            x.country_name.toLowerCase() == country.toLowerCase(),
        );
        if (countryData) {
          countryCode = countryData.country_code;
        }
      }
      this.setState({
        supplierCity: city[0],
        supplierState: state,
        supplierCountry: countryCode,
        geoLocationClass: "googleLocationInput",
        supplierCityClass: "simpleInput",
        geoLocationFlag: false,
      });
    }
  };

  handleGeoInputChange = (event) => {
    const value = event.target.value;
    this.setState({ supplierCity: value });
    if (value == "" || value.trim() == "") {
      this.setState({ geoLocationFlag: true });
      if (document.getElementById("-google-places-autocomplete-input")) {
        document.getElementById("-google-places-autocomplete-input").value = "";
        document.getElementById("-google-places-autocomplete-input").focus();
      }
    } else {
      if (document.getElementById("-google-places-autocomplete-input")) {
        document.getElementById("-google-places-autocomplete-input").value = "";
      }
    }
  };

  selectLocation2 = (place) => {
    let zipcode = "";
    let city = "";
    let state = "";
    let country = "";

    document.getElementById("new-input").value = "";
    if (place.address_components) {
      place.address_components.map((data) => {
        if (data.types[0] == "postal_code") {
          zipcode = data.long_name;
          document.getElementById("new-input").value = zipcode;
        }
        if (data.types[0] == "locality" || data.types[0] == "postal_town") {
          city = data.long_name;
        }
        if (data.types[0] == "administrative_area_level_1") {
          state = data.short_name;
        }
        if (data.types[0] == "country") {
          country = data.short_name;
        }
      });

      if (zipcode == "") {
        let value = place.address_components.find(
          (y) => y.types[0] == "postal_code_prefix",
        );
        if (value) {
          zipcode = value.long_name;
          document.getElementById("new-input").value = zipcode;
        }
      }
      if (city == "") {
        let value = place.address_components.find(
          (y) => y.types[0] == "administrative_area_level_2",
        );
        if (value) {
          city = value.long_name;
        }
      }

      if (zipcode) {
        this.setState({
          supplierCity: city,
          supplierZip: zipcode,
          supplierState: state,
          supplierCountry: country,
          readOnly: true,
        });
      } else {
        this.setState({
          supplierCity: city,
          supplierZip: zipcode,
          supplierState: state,
          supplierCountry: country,
          readOnly: false,
        });
      }
    }
  };

  handleChange = (newValue) => {
    this.setState({ value: newValue });
  };

  handleCreate = (inputValue) => {
    this.setState({ isLoading: true });

    setTimeout(() => {
      const { options } = this.state;
      const newOption = createOption(inputValue);
      let error = false;

      newOption.value = newOption.value.trim();

      if (!validator.isEmail(newOption.value)) {
        uiNotification.clear();
        uiNotification.error("You have entered an invalid email address.");
        error = true;
      }

      if (!error) {
        this.setState({
          isLoading: false,
          options: [...options, newOption],
          value: [...this.state.value, newOption],
        });
      } else {
        this.setState({ isLoading: false });
      }
    }, 1000);
  };

  render() {
    const { isLoading, options, value } = this.state;

    return (
      <div className={"blackOverlay"}>
        <div className="vert-middle vert-middle-report-option">
          <div className="white-popup large-popup edit-supplier">
            <div className="white-popup-wrapper p-b-0">
              <div className="membershipTypeTitle m-b-0">
                {this.props.supplierId ? "Edit Supplier" : "Add Supplier"}
                <a
                  onClick={this.props.dismissSupplierModal}
                  className="popupClose"
                >
                  <img alt="" src={picClose} />
                </a>
              </div>
            </div>
            <Scrollbars
              autoHeight
              autoHeightMax={520}
              className="full-width"
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  style={{ display: "none" }}
                  className="track-horizontal"
                />
              )}
            >
              <div className="white-popup-wrapper border-bottom p-b-15">
                <div className="juvly-subtitle m-b-15">Information</div>
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.inventoryLang.inventory_supplier_name}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.supplierNameClass}
                        type="text"
                        name="supplierName"
                        value={this.state.supplierName}
                        placeholder="Supplier Name"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {"Sold-To Account Number"}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.account_numberClass}
                        type="text"
                        name="account_number"
                        value={this.state.account_number}
                        placeholder="Account Number"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-xs-12">
                    <div className="setting-field-outer m-b-15 simpleField m-b-15 creatableZIndex">
                      <div className="simpleLabel">
                        {"Email"} <span className="setting-require">*</span>
                      </div>

                      {this.state.isRender && (
                        <Creatable
                          isClearable
                          isSearchable
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          onChange={this.handleChange}
                          onCreateOption={this.handleCreate}
                          options={options}
                          value={value}
                          isMulti={true}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="newInputFileldOuter m-t-0">
                      <div className="newInputLabel">
                        {this.state.settingsLang.pos_phone}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer flag-input">
                        <input type="hidden" name="full_number_2" />
                        {this.state.isRender && (
                          <IntlTelInput
                            className="form-control"
                            utilsScript={"libphonenumber.js"}
                            css={[
                              "intl-tel-input",
                              this.state.supplierPhoneClass,
                            ]}
                            defaultValue={
                              this.state.supplierPhone
                                ? this.state.supplierPhone
                                : ""
                            }
                            defaultCountry={this.state.defaultCountry}
                            fieldName="supplierPhone"
                            onPhoneNumberChange={this.phoneNumberChanged.bind(
                              this,
                              "supplierPhone",
                            )}
                            onPhoneNumberBlur={this.phoneNumberChanged.bind(
                              this,
                              "supplierPhone",
                            )}
                            fieldId="supplierPhone"
                            separateDialCode={`true`}
                            onSelectFlag={this.phoneNumberChanged.bind(
                              this,
                              "supplierPhone",
                            )}
                            autoPlaceholder={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-8 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.pos_address}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.supplierAddressClass}
                        type="text"
                        name="supplierAddress"
                        value={this.state.supplierAddress}
                        placeholder="Supplier Address"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.editUsers_Zip_Code}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <Autocomplete
                        id="new-input"
                        placeholder="Zipcode"
                        className={this.state.supplierZipClass}
                        onPlaceSelected={(place) => this.selectLocation2(place)}
                        types={["(regions)"]}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {"City"}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        name="supplierCity"
                        id="supplierCity"
                        className={this.state.supplierCityClass}
                        autoComplete="off"
                        placeholder="City"
                        maxLength="255"
                        type="text"
                        value={this.state.supplierCity}
                        onChange={this.handleGeoInputChange}
                        onBlur={this.handleGeoInputChange}
                        onFocus={this.handleGeoInputChange}
                        onSelect={this.handleGeoInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {"State"}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        name="supplierState"
                        id="supplierState"
                        className={this.state.supplierStateClass}
                        autoComplete="off"
                        placeholder="State"
                        maxLength="255"
                        type="text"
                        value={this.state.supplierState}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.editUsers_Country}
                        <span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.supplierCountryClass}
                        name="supplierCountry"
                        value={this.state.supplierCountry}
                        placeholder="Country"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      >
                        <option value="">Select</option>
                        {this.props.countryList &&
                          this.props.countryList.length > 0 &&
                          this.props.countryList.map((obj, idx) => {
                            return (
                              <option
                                key={"countryList-" + idx}
                                value={obj.country_code}
                              >
                                {obj.country_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-4 col-xs-12 no-display">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.pos_city}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.supplierCityClass}
                        type="text"
                        name="supplierCity"
                        value={this.state.supplierCity}
                        placeholder="Supplier City"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12  no-display">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.editUsers_State}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.supplierStateClass}
                        type="text"
                        name="supplierState"
                        value={this.state.supplierState}
                        placeholder="Supplier State"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12  no-display">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.editUsers_Country}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.supplierCountryClass}
                        type="text"
                        name="supplierCountry"
                        value={this.state.supplierCountry}
                        placeholder="Supplier Country"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="white-popup-wrapper border-bottom">
                <div className="juvly-subtitle m-b-15">
                  {this.state.inventoryLang.contact_person}{" "}
                  <span className="setting-require">*</span>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.profile_first_name}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.firstNameClass}
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        placeholder="First Name"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.settingsLang.profile_last_name}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.lastNameClass}
                        type="text"
                        name="lastName"
                        value={this.state.lastName}
                        placeholder="Last Name"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {this.state.inventoryLang.email}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.emailClass}
                        type="text"
                        name="email"
                        value={this.state.email}
                        placeholder="Email"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="newInputFileldOuter m-t-0">
                      <div className="newInputLabel">
                        {this.state.settingsLang.pos_phone}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer flag-input">
                        <input type="hidden" name="full_number_2" />
                        {this.state.isRender && (
                          <IntlTelInput
                            className="form-control"
                            utilsScript={"libphonenumber.js"}
                            css={[
                              "intl-tel-input",
                              this.state.contactNumberClass,
                            ]}
                            defaultValue={
                              this.state.contactNumber
                                ? this.state.contactNumber
                                : ""
                            }
                            defaultCountry={this.state.defaultCountry}
                            fieldName="contactNumber"
                            onPhoneNumberChange={this.phoneNumberChanged.bind(
                              this,
                              "contactNumber",
                            )}
                            onPhoneNumberBlur={this.phoneNumberChanged.bind(
                              this,
                              "contactNumber",
                            )}
                            fieldId="contactNumber"
                            separateDialCode={`true`}
                            onSelectFlag={this.phoneNumberChanged.bind(
                              this,
                              "contactNumber",
                            )}
                            autoPlaceholder={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="white-popup-wrapper border-bottom">
                <div className="juvly-subtitle m-b-15">
                  {"Order Information"}
                </div>
                <div className="row">
                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {"Distributor Name"}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.distributor_nameClass}
                        type="text"
                        name="distributor_name"
                        value={this.state.distributor_name}
                        placeholder="Distributor Name"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="newInputFileldOuter m-t-0">
                      <div className="newInputLabel">
                        {"Ordering Phone"}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer flag-input">
                        <input type="hidden" name="full_number_2" />
                        {this.state.isRender && (
                          <IntlTelInput
                            className="form-control"
                            utilsScript={"libphonenumber.js"}
                            css={[
                              "intl-tel-input",
                              this.state.ordering_phoneClass,
                            ]}
                            defaultValue={
                              this.state.ordering_phone
                                ? this.state.ordering_phone
                                : ""
                            }
                            defaultCountry={this.state.defaultCountry}
                            fieldName="ordering_phone"
                            onPhoneNumberChange={this.phoneNumberChanged.bind(
                              this,
                              "ordering_phone",
                            )}
                            onPhoneNumberBlur={this.phoneNumberChanged.bind(
                              this,
                              "ordering_phone",
                            )}
                            fieldId="ordering_phone"
                            separateDialCode={`true`}
                            onSelectFlag={this.phoneNumberChanged.bind(
                              this,
                              "ordering_phone",
                            )}
                            autoPlaceholder={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="simpleLabel">
                        {"Ordering URL"}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={this.state.ordering_urlClass}
                        type="text"
                        name="ordering_url"
                        value={this.state.ordering_url}
                        placeholder="Ordering URL"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="white-popup-wrapper p-t-0 p-b-0">
                <div
                  className="juvly-subtitle m-b-15 supplierAccordion"
                  data-toggle="collapse"
                  href="#accordionClinicAccounsModal"
                  role="button"
                  aria-expanded="false"
                  aria-controls="accordionClinicAccounsModal"
                >
                  <label
                    className={
                      this.state.isAccordionOpen === true ? "active" : ""
                    }
                  >
                    {"Ship-To Account Number"}
                  </label>
                </div>
                <div className="row collapse" id="accordionClinicAccounsModal">
                  <div className="col-sm-12 col-xs-12">
                    {this.state.clinicsAccountNumbers &&
                      this.state.clinicsAccountNumbers.length > 0 && (
                        <div
                          className={"setting-container no-padding p-b-0 "}
                          id="clinic-acount-number-form-title"
                        >
                          <div
                            className={
                              "table-responsive fixed-header-table m-t-0"
                            }
                          >
                            <table className="table-updated juvly-table no-hover no-td-border no-border table-min-width m-b-25 table-fixed">
                              <thead className="table-updated-thead">
                                <tr>
                                  <th className="table-updated-th col-xs-6">
                                    {this.state.inventoryLang.inventory_clinic}
                                  </th>
                                  <th className="table-updated-th text-left col-xs-6">
                                    {"Account Number"}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="ajax_body">
                                {this.state.clinicsAccountNumbers.map(
                                  (obj, index) => {
                                    return (
                                      <tr
                                        className="table-updated-tr"
                                        key={"clinicsAccountNumbers-" + index}
                                      >
                                        <td className="table-updated-td  col-xs-6">
                                          {obj.clinic_name}
                                        </td>
                                        <td className="table-updated-td text-left col-xs-6">
                                          <div className="setting-input-outer">
                                            <input
                                              type="text"
                                              autoComplete="off"
                                              name="clinic_account_number"
                                              className={
                                                obj.account_numberClass
                                              }
                                              value={obj.account_number}
                                              data-index={index}
                                              onChange={this.handleInputChange}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="footer-static">
                {this.props.supplierId && (
                  <a
                    className="new-red-btn pull-left"
                    onClick={this.props.deleteSupplier}
                  >
                    {"Delete"}
                  </a>
                )}
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.handleModalSubmit}
                >
                  {"Save"}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={this.props.dismissSupplierModal}
                >
                  {"Cancel"}
                </a>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEditSupplierModal;
