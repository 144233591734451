import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "clsx";
import classes from "../../sass/CompleteLink.module.scss";

export function CompleteLink({ children, to, isCompleted, isDisabled }) {
  return (
    <Link
      to={to}
      className={cx(classes.root, {
        [classes.rootSuccess]: isCompleted,
        [classes.rootFailure]: !isCompleted,
        [classes.isDisabled]: isDisabled,
      })}
    >
      {children}
    </Link>
  );
}

CompleteLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};
