const initialState = {
  isCherryAvailable: { status: "pending", data: null },
  cherryPatient: { status: "pending", data: null },
  createCherryPayment: { status: "pending", data: null },
  ongoingCherryPayment: { status: "pending", data: null },
  paymentStatus: { status: "pending", data: null },
  cancelCherryPayment: { status: "pending", data: null },
  createCherryPatient: { status: "pending", data: null },
  refreshCherryPatient: { status: "pending", data: null },
};

const cherry = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID": {
      return { ...state, cherryPatient: action.payload };
    }
    case "FETCH_CHERRY_PATIENT_BY_PHONE": {
      return { ...state, cherryPatient: action.payload };
    }
    case "IS_CHERRY_AVAILABLE": {
      return { ...state, isCherryAvailable: action.payload };
    }
    case "CREATE_CHERRY_PAYMENT": {
      return { ...state, createCherryPayment: action.payload };
    }
    case "ONGOING_CHERRY_PAYMENT": {
      return { ...state, ongoingCherryPayment: action.payload };
    }
    case "FETCH_CHERRY_PAYMENT": {
      return { ...state, paymentStatus: action.payload };
    }
    case "CLEAR_CHERRY_PAYMENT": {
      return {
        ...state,
        createCherryPayment: initialState.createCherryPayment,
        paymentStatus: initialState.paymentStatus,
        ongoingCherryPayment: initialState.ongoingCherryPayment,
        cancelCherryPayment: initialState.cancelCherryPayment,
      };
    }
    case "CREATE_CHERRY_PATIENT": {
      return { ...state, createCherryPatient: action.payload };
    }
    case "CANCEL_CHERRY_PAYMENT": {
      return { ...state, cancelCherryPayment: action.payload };
    }
    case "CLEAR_PATIENT": {
      return {
        ...state,
        isCherryAvailable: initialState.isCherryAvailable,
        cherryPatient: initialState.cherryPatient,
      };
    }
    case "REFRESH_CHERRY_PATIENT": {
      return { ...state, refreshCherryPatient: action.payload };
    }
    default:
      return state;
  }
};

export default cherry;
