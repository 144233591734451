import React from "react";
import { Skeleton as SharedSkeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { arrayFrom } from "../../../../../../../utilities/general";
import classes from "./Skeleton.module.scss";

export const Skeleton = () => {
  return (
    <div className={classes.root}>
      {arrayFrom(10).map((k) => (
        <SharedSkeleton key={k} width="149px" height={605} borderRadius="4px" />
      ))}
    </div>
  );
};
