import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  procedureId: yup.number().required(),
});

export function useProviderRoomProcedureHideMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.patch(
      HTTP_ENDPOINTS.patchProviderRoomProcedureHide(req.procedureId),
    );
  }, options);
}
