import React from "react";

const Meeting = () => (
  <div>
    <iframe
      title="meeting-ar-com"
      src="https://meeting.aestheticrecord.com"
      width="1600"
      height="800"
    ></iframe>
  </div>
);

export default Meeting;
