/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { numberFormat } from "../../../Utils/services.js";

export default class TimeCardInsightReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };
    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.reportData !== undefined) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  fetchTimeCardReportDetails = (obj) => {
    if (obj) {
      let returnState = {
        detailProviderId: obj.user_id,
        detailProviderName: obj.firstname + " " + obj.lastname,
      };
      this.props.fetchTimeCardReportDetails(returnState);
    }
  };

  downloadData = () => {
    let reportOptionFilter = {};
    if (
      this.state.reportType === "view" &&
      this.state.reportID &&
      this.state.insightID
    ) {
      reportOptionFilter.business_insight_reports_id = this.state.reportID;
      reportOptionFilter.is_download = 1;
      this.setState({ showLoader: true });
      this.props.downloadReportData(this.state.insightID, reportOptionFilter);
    } else {
      reportOptionFilter = this.state.reportFilter;
      reportOptionFilter.is_download = 1;
      this.setState({ showLoader: true });
      this.props.downloadReportData(
        reportOptionFilter.report_type_id,
        reportOptionFilter,
      );
    }
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  render() {
    return (
      <div className="table-responsive clients-table">
        <table className="table-updated setting-table table-min-width no-td-border  no-hover">
          <thead className="table-updated-thead">
            <tr>
              <th className="table-updated-th">{"Employee"}</th>
              <th className="table-updated-th">{"Total Hours"}</th>
              <th className="table-updated-th">{"Pay per Hour"}</th>
              <th className="table-updated-th">{"Total Gross Pay"}</th>
              <th className="table-updated-th">{"View Details"}</th>
            </tr>
          </thead>
          <tbody className="ajax_body">
            {this.props.reportData &&
              this.props.reportData.length > 0 &&
              this.props.reportData.map((obj, idx) => {
                return (
                  <tr
                    className="table-updated-tr"
                    key={"time-card-report-" + idx}
                  >
                    <td className="col-xs-4 table-updated-td">
                      {obj.firstname + " " + obj.lastname}
                    </td>
                    <td className="col-xs-3 table-updated-td">
                      {obj.total_hours}
                    </td>
                    <td className="col-xs-2 table-updated-td">
                      {numberFormat(obj.hourly_rate, "currency")}
                    </td>
                    <td className="col-xs-3 table-updated-td">
                      {numberFormat(obj.total_gross_pay, "currency")}
                    </td>
                    <td className="table-updated-td">
                      <a
                        className="easy-link no-padding"
                        onClick={this.fetchTimeCardReportDetails.bind(
                          this,
                          obj,
                        )}
                      >
                        {"View Details"}
                      </a>
                    </td>
                  </tr>
                );
              })}

            {this.props.reportData &&
              this.props.reportData &&
              this.props.reportData.length == 0 && (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan={5}
                  >
                    {this.props.businessInsightLang.bi_no_record_found}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    );
  }
}
