import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";
import { patientQuestionnaireSchema } from "./schema";

const requestPayload = yup.object({
  patientQuestionnaireId: yup.number().required(),
});

const responseSchema = patientQuestionnaireSchema;

const composeResponse = (res) => {
  return res?.data?.data || {};
};

function createPatientQuestionnaireQueryKey(id) {
  return [QUERY_KEYS.patientQuestionnaires.getOne, id];
}

export function usePatientQuestionnaireQuery({ payload, options = {} } = {}) {
  return useQuery(
    createPatientQuestionnaireQueryKey(payload.patientQuestionnaireId),
    async () => {
      const req = requestPayload.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.patientQuestionnaires.getOne(req.patientQuestionnaireId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
