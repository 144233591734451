import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar";
import PosTitle from "../PosTitle";
import {
  fetchKlarnaAvailability,
  updateKlarnaAvailability,
} from "../../../../Actions/Sales/klarnaActions";
import { ReportLayout } from "../../../BusinessInsights/components/ReportLayout/ReportLayout";
import { Header } from "./components/Header";
import { ReportStats } from "../../../../boxes/ReportStats/ReportStats";
import { Table } from "./components/Table";
import { ModalFilter } from "./components/ModalFilter";
import { useReports } from "./hooks/useReports";
import { useDownloadExcel } from "./hooks/useDownloadExcel";

const PosReconciliation = ({ history }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const { reports, filter, order, period, stats } = useReports();

  const downloadExcel = useDownloadExcel({
    period,
    filter: filter.preparedForRequest,
  });

  return (
    <div className="main protected">
      <div id="content" className="content-pos-dashboard">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="business-setion memberWalletOuter">
            <PosTitle
              history={history}
              settingsLang={languageData.settings}
              globalLang={languageData.global}
              activeMenu={"pos-reconciliation"}
            />
            <div className="setting-search-outer border-bottom no-padding">
                <ReportLayout>
                  <Header
                    isFilterActive={filter.isActive}
                    onFilterOpen={() => setIsFilterOpen(true)}
                    period={period}
                    downloadExcel={downloadExcel}
                    isReportsLoading={reports.isLoading}
                  />
                  <ReportStats isLoading={reports.isLoading} data={stats} />
                  <Table reports={reports} order={order} />
                  <ModalFilter
                    isOpen={isFilterOpen}
                    onClose={() => setIsFilterOpen(false)}
                    filter={filter}
                  />
                </ReportLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  klarnaAvailability: state.KlarnaReducer.klarnaAvailability,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchKlarnaAvailability, updateKlarnaAvailability },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PosReconciliation));
