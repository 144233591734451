import React, { useRef } from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { DatePicker } from "../../../../shared/DatePicker/DatePicker";
import { passOr } from "../../../../utilities/general";
import { PREVIEW_DATE_FORMAT } from "../../../../consts/general";
import { API_DATE_FORMAT } from "../../../../consts/api";
import { formatUTC } from "../../../../Utils/dateHelper";
import { InputError } from "../../../../shared/InputError/InputError";

export function DobPicker({ value, onChange, error }) {
  const dateOfBirthRef = useRef(null);
  const { tCommon } = useAppTranslation.Common();

  return (
    <div>
      <InputLabel>
        {tCommon("createQualifyProcedure.formLabel.dateOfBirth")}
      </InputLabel>
      <DatePicker
        showMonthDropdown
        showYearDropdown
        ref={dateOfBirthRef}
        onChangeRaw={(e) => e.preventDefault()}
        isError={!!error}
        maxDate={new Date()}
        onClickOutside={() => dateOfBirthRef.current.setOpen(false)}
        value={passOr(value, null, () =>
          moment.utc(value).format(PREVIEW_DATE_FORMAT),
        )}
        onChange={(next) => onChange(moment(next).format(API_DATE_FORMAT))}
        selected={passOr(value, null, () =>
          formatUTC(moment.utc(value).toDate()),
        )}
      />
      {!!error && <InputError>{error}</InputError>}
    </div>
  );
}
