import React from "react";

export function ThunderIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0 }}
    >
      <path
        d="M13 3L4 14h7l-2 7 9-11h-7l2-7z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
