import { useMemo } from "react";

export function usePaymentInfoWaitList({ paymentStatuses, clinics }) {
  const payableClinic = useMemo(
    () =>
      paymentStatuses &&
      paymentStatuses.clinicCanTakePaymentStatusById &&
      clinics.find((c) => paymentStatuses.clinicCanTakePaymentStatusById[c.id]),
    [clinics, paymentStatuses.clinicCanTakePaymentStatusById],
  );

  const clearentPublicKey = useMemo(() => {
    if (payableClinic) {
      return paymentStatuses.clearentPublicKeyByClinicIds[payableClinic.id];
    }
    return null;
  }, [payableClinic]);

  return {
    isCanTakePayment: Boolean(payableClinic),
    clearentPublicKey,
  };
}
