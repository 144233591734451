import React from "react";
import cx from "clsx";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "../Column.module.scss";

/**
 * @param {Object} param0
 * @param {string} param0.parentKey
 * @param {boolean?} param0.stretch
 * @param {boolean?} param0.accentFirstCell,
 * @param {boolean?} param0.isActive
 * @param {(() => void) | undefined} param0.onClick
 * @param {React.ReactNode[]} param0.rows
 * @param {string?} param0.className
 * @param {string?} param0.cellClassName
 */
export const ColumnBase = ({
  parentKey,
  stretch,
  accentFirstCell,
  isActive,
  onClick,
  rows,
  className,
  cellClassName,
}) => {
  const { tCommon } = useAppTranslation.Common();

  const rootClasses = cx(
    classes.col,
    {
      [classes.stretch]: stretch,
      [classes.active]: isActive,
      [classes.clickable]: Boolean(onClick),
    },
    className,
  );

  const renderRows = () =>
    rows.map((row, index) => (
      <div
        key={`${parentKey}-${index}`}
        className={cx(
          classes.cell,
          {
            [classes.firstCellAccent]: accentFirstCell && index === 0,
          },
          cellClassName,
        )}
      >
        {row || tCommon("symbol.longDash")}
      </div>
    ));

  if (onClick) {
    return (
      <button onClick={onClick} className={rootClasses}>
        {renderRows()}
      </button>
    );
  }

  return <div className={rootClasses}>{renderRows()}</div>;
};
