import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchDiscountGroupData, emptyInventoryReducer, createDiscountGroup, fetchDiscountGroupDataId, updateDiscountGroup, deleteDiscountGroup, addDiscountGroupProduct, deleteDiscountGroupProduct, resetAction, exportEmptyData } from '../../../../../Actions/Inventory/inventoryActions.js';
import { withRouter } from 'react-router';
import picClose from '../../../../../_legacy/images/popupClose.png';
import { autoScrolling, toggleBodyScroll, capitalizeFirstLetter } from '../../../../../Utils/services.js';
import Sidebar from '../../../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../../../Common/Loader.js'
import { uiNotification } from "../../../../../services/UINotificationService.js";

class DiscountGroups extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0)
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));

    this.state = {
      loadMore: true,
      startFresh: true,
      showLoader: false,
      page: 1,
      pagesize: 15,
      term: '',
      hasMoreItems: true,
      next_page_url: '',
      searchFunction: '',
      user_changed: false,
      tabClicked: false,
      DiscountGroupData: [],
      data: [],
      childCheck: false,
      action: props.match.params.statusId,
      sortorder: 'asc',
      sortby: 'name',
      scopes: 'category',
      selected: [],
      selectAll: 0,
      categoryName: '',
      show_below_stock: 0,
      createEditModal: false,
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      showLoadingText: false,
      status: 0,
      name: '',
      userChanged: false,
      discountGroupData: {},
      discountGroupProducts: [],
      productList: [],
      autoSuggestionProducts: [],
      selectedIds: [],
      selectedItem: {},
      nameClass: 'simpleInput',
      product_name: '',
      productNameClass: 'simpleInput',
      showModal: false,
      deleteType: '',
      deleteMsg: '',
      deleteProductId: 0,
      showAddProduct: false,
      showAutoSuggestion: false,
      discountGroupId: 0,
      formstatus: false,
      isAddDiscountProduct : false,
      group_type : 'product',
      autoSuggestionCategories:[],
      discountGroupCategories:[]
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <= 5 && this.state.next_page_url != null) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    let formData = {
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder,
        sortby: this.state.sortby,
        term: this.state.term,
        action: this.state.action
      }
    };
    this.setState({ 'showLoader': true });
    autoScrolling(true);
    this.props.fetchDiscountGroupData(formData);


    document.addEventListener('click', this.handleOnClick, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.exportEmptyData();
      return { showLoader: false }
    }
    if (nextProps.DiscountGroupData != undefined && nextProps.timestamp != prevState.timestamp) {
      let returnState = {};
      returnState.timestamp = nextProps.timestamp;

      if ((prevState.DiscountGroupData.length == 0 && prevState.startFresh == true) || nextProps.reloadData) {
        if (localStorage.getItem("sortOnly") == "false") {
          returnState.DiscountGroupData = nextProps.DiscountGroupData.data;
          if (nextProps.DiscountGroupData.next_page_url != null) {
            returnState.page = prevState.page + 1;
          } else {
            returnState.next_page_url = nextProps.DiscountGroupData.next_page_url;
          }
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
        } else {
          returnState.showLoader = false;
          localStorage.setItem("sortOnly", false);
        }
      } else if (prevState.DiscountGroupData != nextProps.DiscountGroupData.data && prevState.DiscountGroupData.length != 0) {

        returnState.DiscountGroupData = [
          ...prevState.DiscountGroupData,
          ...nextProps.DiscountGroupData.data
        ];

        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.DiscountGroupData.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      }

      autoScrolling(false);
      nextProps.exportEmptyData();
      return returnState;
    }
    let returnState = {};

    if ((nextProps.isUpdatedDiscountGroup != undefined && nextProps.isUpdatedDiscountGroup == true) || (nextProps.idDeletedDiscountGroup != undefined && nextProps.idDeletedDiscountGroup == true)) {
      nextProps.exportEmptyData();
      autoScrolling(true);
      toggleBodyScroll(false)
      let formData = {
        'params': {
          page: 1,
          pagesize: prevState.pagesize,
          sortorder: prevState.sortorder,
          sortby: prevState.sortby,
          term: prevState.term,
          action: prevState.action
        }
      };
      returnState.createEditModal = false
      returnState.showAutoSuggestion = false
      returnState.product_name = ''
      returnState.showLoader = true
      returnState.page =  1
      returnState.startFresh =  true
      returnState.loadMore =  true
      returnState.next_page_url =  ""
      returnState.DiscountGroupData =  []

      nextProps.fetchDiscountGroupData(formData);
      return returnState;


    } else if (nextProps.discountGroupData && nextProps.discountGroupData !== prevState.discountGroupData) {
      returnState.discountGroupData = nextProps.discountGroupData;
      returnState.showLoader = false;
      returnState.group_type = 'product'
      returnState.name = '';
      returnState.status = 0;
      returnState.discountGroupProducts = [];
      returnState.discountGroupCategories = [];
      returnState.productList = (nextProps.discountGroupData.products) ? nextProps.discountGroupData.products : [];
      returnState.categoryList = (nextProps.discountGroupData.categories) ? nextProps.discountGroupData.categories : [];
      returnState.autoSuggestionProducts = [];
      returnState.autoSuggestionCategories = [];
      returnState.selectedIds = [];
      if (prevState.discountGroupId) {
        returnState.name = (nextProps.discountGroupData.discount_group.name) ? nextProps.discountGroupData.discount_group.name : '';
        returnState.group_type = (nextProps.discountGroupData.discount_group.group_type) ? nextProps.discountGroupData.discount_group.group_type : 'product';
        returnState.status = nextProps.discountGroupData.discount_group.status == 1 ? 1 : 0;
        returnState.discountGroupProducts = (nextProps.discountGroupData.discount_group_products) ? nextProps.discountGroupData.discount_group_products : [];
        returnState.discountGroupCategories = (nextProps.discountGroupData.discount_group_categories) ? nextProps.discountGroupData.discount_group_categories : [];
        let selectedIds = [];
        if(returnState.group_type === 'product'){
          returnState.discountGroupProducts.map((obj, idx) => {
            selectedIds.push(obj.id);
          })
        } else {
          returnState.discountGroupCategories.map((obj, idx) => {
            selectedIds.push((obj.id) ? obj.id : obj);
          })
        }
        returnState.selectedIds = selectedIds;
      }
      returnState.createEditModal = true
      returnState.showAutoSuggestion = false
      returnState.product_name = ''
      returnState.isAddDiscountProduct = false
      nextProps.exportEmptyData();
    }
    else if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      nextProps.exportEmptyData();
    } else if (nextProps.isProductAdded != undefined && nextProps.isProductAdded == true) {
      returnState.showLoader = false;

      // add product in product list
      let discountGroupProducts = prevState.discountGroupProducts;
      discountGroupProducts.push(prevState.selectedItem);
      returnState.discountGroupProducts = discountGroupProducts;

      // add id into selectedIds
      let selectedIds = prevState.selectedIds;
      selectedIds.push(prevState.selectedItem.id);
      returnState.selectedIds = selectedIds;

      // reset selectedItem, product_name, autoSuggestionProducts
      returnState.selectedItem = {};
      returnState.product_name = '';
      returnState.autoSuggestionProducts = [];
      nextProps.resetAction();
    } else if (nextProps.isProductDeleted != undefined && nextProps.isProductDeleted == true) {
      returnState.showLoader = false;
      if (prevState.deleteProductId > 0) {
        // remove product from product list
        let deleteProductId = prevState.deleteProductId;
        let discountGroupProducts = [];
        prevState.discountGroupProducts.map((obj, idx) => {
          if (obj.id !== deleteProductId) {
            discountGroupProducts.push(obj);
          }
        })
        returnState.discountGroupProducts = discountGroupProducts;

        // remove id from selectedIds
        let selectedIds = prevState.selectedIds;
        var idIndex = selectedIds.indexOf(deleteProductId);
        if (idIndex > -1) {
          selectedIds.splice(idIndex, 1);
        }
        returnState.selectedIds = selectedIds;

        // reset deleteProductId
        returnState.deleteProductId = 0;
      }
      nextProps.resetAction();
    }
    return returnState;

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOnClick, false);
    this.props.exportEmptyData({});
    this.setState({
      showLoader: false
    });
  }

  onSort = (sortby) => {
    let sortorder = (this.state.sortorder === 'asc') ? 'desc' : 'asc';
    let formData = {
      'params': {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term
      }
    }
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: '',
      DiscountGroupData: []
    });
    //localStorage.setItem('sortOnly', true);
    autoScrolling(true);
    this.props.fetchDiscountGroupData(formData);
  }

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({ 'loadMore': true, startFresh: true, showLoader: false, showLoadingText: true });
      let formData = {
        'params': {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortorder: this.state.sortorder && this.state.sortorder === 'asc' ? 'asc' : this.state.sortorder == 'desc' ? 'desc' : 'asc',
          sortby: this.state.sortby,
          term: this.state.term,
          action: this.state.action
          //	scopes : this.state.scopes
        }
      };
      this.setState({ 'showLoader': true });
      autoScrolling(true);
      this.props.fetchDiscountGroupData(formData);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    //localStorage.setItem('sortOnly', true);
    let formData = {
      'params': {
        page: 1,
        sortorder: this.state.sortorder,
        sortby: this.state.sortby,
        term: this.state.term,
        name: this.state.name,
        //status: this.state.status,
      },

    }
    this.setState({
      page: 1,
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      DiscountGroupData: []
    });


    this.setState({ 'showLoader': true, filterValue: 'false' });
    autoScrolling(true);
    this.props.fetchDiscountGroupData(formData);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const packageindex = event.target.dataset.packageindex;
    if (target.name === 'product_name') {
      this.setState({ selectedItem: {} });
    }
    if (packageindex) {
      const productPackageArr = this.state.productPackageArr;
      productPackageArr[packageindex][target.name] = value;
      this.setState({ productPackageArr: productPackageArr });
    } else if(target.name === 'group_type') {
      this.setState({
        discountGroupProducts : [],
        discountGroupCategories : [],
        selectedItem: {}, 
        showAutoSuggestion: false, 
        product_name: "",
        group_type : value,
        userChanged: true,
        selectedIds : []
      })
    } else {
      this.setState({
        [event.target.name]: value,
        userChanged: true
      });
    }
  }


  showCreateModal = (discountGroupId) => {
    //this.setState({ createEditModal: true, name: "", status: true, discountGroupId: 0, discountGroupProducts: [] })
    this.setState({ showLoader: true, discountGroupId : (discountGroupId) ? discountGroupId : 0 })
    autoScrolling(false);
    toggleBodyScroll(true)
    this.props.fetchDiscountGroupDataId(discountGroupId);
  }

  dismissCreateEditModal = () => {
    this.setState({ createEditModal: false, discountGroupId: 0 })
    toggleBodyScroll(false)
    autoScrolling(true);
  }



  handleOnClick = (e) => {
    if (this.ref_product_name_container !== undefined && this.ref_product_name_container && !this.ref_product_name_container.contains(e.target)) {
      this.setState({ showAutoSuggestion: false });
      if (this.state.selectedItem !== undefined && (this.state.selectedItem.id === undefined || this.state.selectedItem.id <= 0)) {
        this.setState({ selectedItem: {}, showAutoSuggestion: false, product_name: "" });
      }
    }
  }


  handleAddDiscountProduct = (event) => {
    if (typeof event == 'object') {
      event.preventDefault();
    }

    if (typeof this.state.product_name === undefined || (this.state.product_name === '' && this.state.selectedItem.id === undefined)) {
      this.setState({ productNameClass: 'simpleInput field_error' })
      return
    } else if (this.state.product_name !== '' && this.state.selectedItem.id === undefined) {
      this.setState({ productNameClass: 'simpleInput field_error' })
      uiNotification.clear();
      uiNotification.error(this.state.inventoryLang.inventory_error_please_selectvalid_product_name);
      return
    } else {
      this.setState({ productNameClass: 'simpleInput' })
    }

    const selectedItem = this.state.selectedItem;
    if (typeof selectedItem == 'object' && selectedItem.id != undefined) {
      let selectedIds = this.state.selectedIds;
      selectedIds.push(selectedItem.id);
      let discountGroupProducts = this.state.discountGroupProducts;
      let discountGroupCategories = this.state.discountGroupCategories;
      if(this.state.group_type === 'category'){
        discountGroupCategories.push(selectedItem);
      } else {
        discountGroupProducts.push(selectedItem);
      }
      this.setState({selectedIds:selectedIds, discountGroupProducts:discountGroupProducts,selectedItem:{},product_name:'',showAutoSuggestion:false,discountGroupCategories:discountGroupCategories})

      //this.props.addDiscountGroupProduct(formData, this.state.discountGroupId)
    }
  }

  handleSelectedProduct = (selectedItem) => {
    if (typeof selectedItem == 'object' && selectedItem.id != undefined) {
      this.setState({ selectedItem: selectedItem, showAutoSuggestion: false, product_name: (this.state.group_type === 'category') ? selectedItem.category_name : selectedItem.product_name });
    }
  }

  handleAutoSuggestion = (event) => {
    let returnState = {}
    const target = event.target;
    let value = target.value;
    let name = event.target.name;
    returnState[event.target.name] = value;
    let autoSuggestionProducts = [];
    let autoSuggestionCategories = [];
    const selectedIds = this.state.selectedIds;
    let showAutoSuggestion = false;
    if (value.length > 2) {
      showAutoSuggestion = true;
      if(this.state.group_type === 'category'){
        this.state.categoryList.map((obj, idx) => {
          if (!selectedIds.includes(obj.id) && typeof obj.category_name == 'string') {
            let regex = new RegExp(value.toLowerCase(), "g");
            let productName = obj.category_name.toLowerCase()
            if (productName.match(regex)) {
              autoSuggestionCategories.push(obj);
            }
          }
        })
      } else {
        this.state.productList.map((obj, idx) => {
          if (!selectedIds.includes(obj.id) && typeof obj.product_name == 'string') {
            let regex = new RegExp(value.toLowerCase(), "g");
            let productName = obj.product_name.toLowerCase()
            if (productName.match(regex)) {
              autoSuggestionProducts.push(obj);
            }
          }
        })
      }
    }
    returnState.autoSuggestionProducts = autoSuggestionProducts;
    returnState.autoSuggestionCategories = autoSuggestionCategories;
    returnState.selectedItem = {};
    returnState.userChanged = true;
    returnState.showAutoSuggestion = showAutoSuggestion;
    this.setState(returnState);

  }

  toggleAddProduct = () => {
    this.setState({ isAddDiscountProduct: true})
  }

  deleteDiscountGroupProduct = (index) => {
    let selectedIds = this.state.selectedIds;
    let discountGroupProducts = this.state.discountGroupProducts;
    let discountGroupCategories = this.state.discountGroupCategories;
    selectedIds.splice(index, 1);
    if(this.state.group_type === 'category'){
      discountGroupCategories.splice(index, 1);
    } else {
      discountGroupProducts.splice(index, 1);
    }
    this.setState({ selectedIds: selectedIds, discountGroupProducts: discountGroupProducts, discountGroupCategories: discountGroupCategories });
  }


  handleDiscountSubmit = event => {
    if (typeof event == 'object') {
      event.preventDefault();
    }
    let error = false;

    if (typeof this.state.name === undefined || this.state.name === null || this.state.name === '') {
      this.setState({ nameClass: 'simpleInput field_error' })
      error = true;
    } else if (this.state.name) {
      this.setState({ nameClass: 'simpleInput' })
    }

    if (error) {
      return
    }

    let formData = {
      name: this.state.name,
      status: this.state.status,
      group_type: this.state.group_type
    }
    if(this.state.selectedIds && this.state.selectedIds.length > 0){
      formData.product_ids = this.state.selectedIds;
      this.setState({ showLoader: true })
      this.props.updateDiscountGroup(formData, this.state.discountGroupId);
    } else {
      uiNotification.clear();
      uiNotification.error('Select atleast one category/product');
      return false;
    }
    
    //this.dismissCreateEditModal();
  }

  toggleDeleteModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  deleteDiscountGroup = (id) => {
    if (this.state.discountGroupId > 0) {
      this.setState({ showLoader: true, showModal : false })
      this.props.deleteDiscountGroup(id);
    }
    this.dismissCreateEditModal();
  }





  redirectToPackages = () => {
    return (
      <div>
        {this.props.history.push(`/settings/discount-packages`)}
      </div>
    )
  }

  render() {

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">

              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span className="cursor-pointer" onClick={this.redirectToPackages}>{'Promotions'}</span> <span className="breadCrumb"><i className="fa fa-chevron-right" /> <span className="breadCrumb-text">{'Discount Groups'}</span></span>
                    <div className="memberRightActions">
                      <a className="new-blue-btn pull-right m-l-10" onClick={this.showCreateModal.bind(this,0)}>{'Create Groups'}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={"setting-setion m-b-10"}>
                <div className="setting-search-outer">
                  <form onSubmit={this.handleSubmit}>
                    <div className="search-bg new-search">
                      <i className="fas fa-search" />
                      <input className="setting-search-input search-key" placeholder="Search" name="term"
                        value={this.state.term} autoComplete="off" onChange={this.handleInputChange} />
                    </div>
                  </form>
                  <div className="dropdown pull-right page-export">
                    <div className="dropdown pull-right page-export">

                    </div>
                  </div>
                </div>
              </div>


              <div className="juvly-section full-width">

                <div className="table-responsive">
                  <table className="table-updated juvly-table no-tb-border">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-2 table-updated-th cursor-pointer sorting" onClick={() => this.onSort("name")}
                          data-sort="name" data-order="DESC" >{this.state.inventoryLang.inventory_name}<i className={(this.state.sortorder === 'asc' && this.state.sortby === 'name') ? "blue-gray" : (this.state.sortorder === 'desc' && this.state.sortby === 'name') ? "gray-blue" : "gray-gray"} /></th>
                        <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.inventory_discount_group_type}</th>
                        <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.inventory_Product_and_category}</th>
                        <th className="col-xs-2 table-updated-th" >{this.state.inventoryLang.inventory_Status}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.DiscountGroupData !== undefined && this.state.DiscountGroupData.map((obj, idx) => {
                          return (
                            <tr className="table-updated-tr" onClick={this.showCreateModal.bind(this, obj.id)} key={idx} >
                              <td className="table-updated-td">{(obj.name) ? obj.name : ''}</td>
                              <td className="table-updated-td">{(obj.group_type === 'category') ? this.state.inventoryLang.inventory_Category : this.state.inventoryLang.inventory_Product}</td>
                              <td className="table-updated-td">{(obj.product_names) ? obj.product_names : ''}</td>
                              <td className="table-updated-td">{(obj.status == 0) ? this.state.inventoryLang.inventory_active : this.state.inventoryLang.inventory_inactive}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {(this.state.showLoader === false) && <div className={(this.state.DiscountGroupData != undefined && this.state.DiscountGroupData.length == 0) ? 'no-record' : 'no-record no-display'} >
                    <div className="" style={{ float: "left", width: "100%", fontSize: "13px", textAlign: "center", marginTop: "0px", padding: "0px" }} >
                      {this.state.inventoryLang.inventory_No_record_found}
                    </div>
                  </div>}
                </div>
              </div>
              <div className={(this.state.showLoadingText) ? "loading-please-wait" : "loading-please-wait no-display "}>{this.state.globalLang.loading_please_wait_text}</div>
            </div>
          </div>

          {/*Pop-UP Starts */}
          <div className={(this.state.createEditModal) ? 'blackOverlay' : 'blackOverlay no-display'}>
            <div className="vert-middle vert-middle-report-option">
              <div className={"white-popup small-popup"}>
                <div className="white-popup-wrapper p-b-10">
                  <div className="membershipTypeTitle">{(this.state.discountGroupId <= 0) ? "Create Discount Group" : "Edit Discount Group"}<a onClick={this.dismissCreateEditModal} className="popupClose"><img src={picClose} /></a></div>

                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_group_name} <span className="setting-require">*</span></div>
                        <input autoComplete="off" className={this.state.nameClass} type="text" name='name' value={this.state.name} onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_group_type}</div>
                        <select className="simpleSelect" name="group_type" value={this.state.group_type} onChange={this.handleInputChange} >
                          <option value={'product'}>{this.state.inventoryLang.inventory_Product}</option>
                          <option value={'category'}>{this.state.inventoryLang.inventory_Category}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">Status Discount Group</div>
                        <select className="simpleSelect" name="status" value={this.state.status} onChange={this.handleInputChange} >
                          <option value={'0'}>Active</option>
                          <option value={'1'}>Inactive</option>
                        </select>
                      </div>
                    </div>

                  </div>


                    <div className="membershipTypeTitle m-b-10 m-t-15 p-r-0">{(this.state.isAddDiscountProduct  === true) ? ((this.state.group_type === 'category') ? this.state.inventoryLang.inventory_Categories : this.state.inventoryLang.inventory_products) : ''} {(this.state.isAddDiscountProduct  === false) ? <a className="new-blue-btn pull-right m-t-5-" onClick={this.toggleAddProduct} >{(this.state.group_type === 'category') ? this.state.inventoryLang.inventory_ADD_CATEGORY : this.state.inventoryLang.inventory_Add_Product}</a> : ""}</div>
                  <div className={(this.state.isAddDiscountProduct === false) ? "row no-display" : "row"}>

                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField relative">
                        <div className="simpleLabel">{(this.state.group_type === 'category') ? this.state.inventoryLang.inventory_select_category : this.state.inventoryLang.inventory_select_product} <span className="setting-require">*</span></div>
                        
                          <div className="product_name_container" data-ref-container={'product_name_container'} ref={(ref_product_name_container) => this.ref_product_name_container = ref_product_name_container} >
                          <input autoComplete="off" className={this.state.productNameClass} name="product_name" value={this.state.product_name} onChange={this.handleAutoSuggestion} type="text" placeholder={(this.state.group_type === 'category') ? 'Type to search Categories' : this.state.inventoryLang.inventory_type_to_search_products} />
                          
                          <ul className={(this.state.showAutoSuggestion && this.state.product_name.length > 2) ? " search-dropdown" : "cal-dropdown clinicname-dropdown no-display"} ref={(refAutoSuggestionProducts) => this.refAutoSuggestionProducts = refAutoSuggestionProducts}>
                            {/* Auto Suggestion Products - START  */}
                            {(this.state.group_type === 'product' && this.state.autoSuggestionProducts.length > 0) &&
                              this.state.autoSuggestionProducts.map((obj, idx) => {
                                return (
                                  <li key={"products-" + idx} data-id={obj.id} onClick={this.handleSelectedProduct.bind(this, obj)}>
                                    <a>
                                      {obj && capitalizeFirstLetter(obj.product_name)}
                                    </a>
                                  </li>
                                )
                              })
                            }
                            {/* Auto Suggestion Products - END  */}
                            {/* Auto Suggestion Categories - START  */}
                            {(this.state.group_type === 'category' && this.state.autoSuggestionCategories.length > 0) &&
                              this.state.autoSuggestionCategories.map((obj, idx) => {
                                return (
                                  <li key={"category-" + idx} data-id={obj.id} onClick={this.handleSelectedProduct.bind(this, obj)}>
                                    <a>
                                      {obj && capitalizeFirstLetter(obj.category_name)}
                                    </a>
                                  </li>
                                )
                              })
                            }
                            {/* Auto Suggestion Categories - END  */}
                            {(this.state.autoSuggestionProducts.length <= 0 && this.state.autoSuggestionCategories.length <= 0) &&
                              <li key={"discountProduct-norecord"} data-id={0}>
                                <a >
                                  {this.state.globalLang.product_match_not_found}
                                </a>
                              </li>
                            }
                          </ul>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 m-t-20">
                      <a className="new-blue-btn m-l-0" onClick={this.handleAddDiscountProduct}>{this.state.globalLang.label_add}</a>

                    </div>
                  </div>


                </div>
                
                <div className="edit-block">
                  {(this.state.discountGroupProducts.length > 0 || this.state.discountGroupCategories.length > 0) &&
                    <div className="table-responsive">
                      <table className="table-updated juvly-table no-hover product-list-outer">
                        <thead className="table-updated-thead">
                          <tr>
                            <th colSpan="2" className="col-xs-10 col-xs-2 table-updated-th no-padding-right">{this.state.inventoryLang.inventory_Product_and_category}</th>
                          </tr>
                        </thead>
                        {(this.state.group_type === 'product') &&
                          <tbody className="ajax_body">
                            {this.state.discountGroupProducts.map((obj, idx) => {
                              return (
                                <tr key={'discount-product' + idx} className="table-updated-tr">
                                  <td className="col-xs-10 table-updated-td sub-table">{obj.product_name}</td>
                                  <td className="col-xs-2 table-updated-td text-center">
                                    <a className="easy-link" onClick={() => this.deleteDiscountGroupProduct(idx)}>{this.state.globalLang.label_delete}</a>
                                  </td>
                                </tr>
                              )
                            })
                            }
                          </tbody>
                        }
                        {(this.state.group_type === 'category') &&
                          <tbody className="ajax_body">
                            {this.state.discountGroupCategories.map((obj, idx) => {
                              return (
                                <tr key={'discount-product' + idx} className="table-updated-tr">
                                  <td className="col-xs-10 table-updated-td sub-table">{obj.category_name}</td>
                                  <td className="col-xs-2 table-updated-td text-center">
                                    <a className="easy-link" onClick={() => this.deleteDiscountGroupProduct(idx)}>{this.state.globalLang.label_delete}</a>
                                  </td>
                                </tr>
                              )
                            })
                            }
                          </tbody>
                        }
                      </table>
                    </div>
                  }
                </div>
                
                <div className="footer-static">
                  <div>
                    <a className="new-blue-btn pull-right" onClick={this.handleDiscountSubmit} >{'Save'}</a>
                    {(this.state.discountGroupId > 0) &&
                      <button className="new-red-btn pull-right" onClick={() => this.toggleDeleteModal()}>{this.state.inventoryLang.inventory_delete}</button>
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/*popup Ends*/}

          {/* */}
          <div className={(this.state.showModal ? 'overlay' : '')}></div>
          <div id="filterModal" role="dialog" className={(this.state.showModal ? 'modal fade in displayBlock' : 'modal fade')}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" onClick={this.toggleDeleteModal}>×</button>
                  <h4 className="modal-title" id="model_title">{this.state.inventoryLang.inventory_Confirmation_required}</h4>
                </div>
                <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                  {this.state.inventoryLang.inventory_delete_discount_group_msg}
                </div>
                <div className="modal-footer" >
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.toggleDeleteModal}>{this.state.inventoryLang.inventory_No}</button>
                    <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={() => this.deleteDiscountGroup(this.state.discountGroupId)}>{this.state.inventoryLang.inventory_Yes}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* */}


          <Loader showLoader={this.state.showLoader} isFullWidth={true} />

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  let returnState = {}
  if (state.InventoryReducer.action === "DISCOUNT_GROUP_LIST") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.DiscountGroupData = state.InventoryReducer.data.data;
      returnState.timestamp = new Date();
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  }
  else if (state.InventoryReducer.action === "EMPTY_INVENTROY1") {
  }
  if (state.InventoryReducer.action === "CREATE_DISCOUNT_GROUP") {
    if (state.InventoryReducer.data.status == 201) {
      returnState.DiscountGroupData = state.InventoryReducer.data.data;
      returnState.timestamp = new Date();
      returnState.reloadData = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  } else if (state.InventoryReducer.action === "FETCH_SELECTED_DISCOUNT_GROUP") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
      toggleBodyScroll(false)
      autoScrolling(true);
    } else {
      returnState.discountGroupData = state.InventoryReducer.data.data;
      returnState.timestamp = new Date();
      returnState.reloadData = true;
      returnState.status = 200;
    }
  } else if (state.InventoryReducer.action === "UPDATE_DISCOUNT_GROUP") {
    if (state.InventoryReducer.data.status == 200) {
      returnState.isUpdatedDiscountGroup = true;
      //returnState.timestamp = new Date();
      //returnState.reloadData = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);

    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  } else if (state.InventoryReducer.action === "DELETE_DISCOUNT_GROUP") {
    if (state.InventoryReducer.data.status == 200) {
      returnState.idDeletedDiscountGroup = true;
      returnState.timestamp = new Date();
      returnState.reloadData = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);

    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  } else if (state.InventoryReducer.action === "ADD_DISCOUNT_GROUP_PRODUCT") {
    if (state.InventoryReducer.data.status == 201) {
      returnState.isProductAdded = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);


    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  } else if (state.InventoryReducer.action === "DELETE_DISCOUNT_GROUP_PRODUCT") {
    if (state.InventoryReducer.data.status == 200) {
      returnState.isProductDeleted = true;
      uiNotification.success(languageData.global[state.InventoryReducer.data.message]);
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchDiscountGroupData: fetchDiscountGroupData,
    emptyInventoryReducer: emptyInventoryReducer,
    exportEmptyData: exportEmptyData,
    createDiscountGroup: createDiscountGroup,
    fetchDiscountGroupDataId: fetchDiscountGroupDataId,
    updateDiscountGroup: updateDiscountGroup,
    deleteDiscountGroup: deleteDiscountGroup,
    addDiscountGroupProduct: addDiscountGroupProduct,
    deleteDiscountGroupProduct: deleteDiscountGroupProduct,
    resetAction: resetAction
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DiscountGroups));
