import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { getExtractFirst, getIsSomeTruthy } from "../../../helpers/general";
import { not, pipe } from "../../../utilities/fp";
import { hasLength, isNotZero, isZero } from "../../../utilities/general";
import { QUERY_KEYS } from "../../../consts/api";

const boolToCheck = (v) => (v ? 0 : 1);

export function getSectionVisibilityStatuses(medicalHistories) {
  const isSomeTruthy = getIsSomeTruthy(medicalHistories);

  return {
    patientInfo: isSomeTruthy(["height"]) || isSomeTruthy(["weight"]),

    socialHistory:
      isSomeTruthy(["social_history", "tobacco_use"]) ||
      isSomeTruthy(["social_history", "drug_use"]) ||
      isSomeTruthy(["social_history", "alcohol_use"]) ||
      isSomeTruthy(["social_history", "exercise_use"]) ||
      isSomeTruthy(["social_history", "weight"]),

    allergies:
      isSomeTruthy(["allergy", "check"], isZero) &&
      (isSomeTruthy(["allergy", "drugs"]) ||
        isSomeTruthy(["allergy", "foods"]) ||
        isSomeTruthy(["allergy", "environment"])),

    currentMedications:
      isSomeTruthy(["current_medication", "check"], isZero) &&
      (isSomeTruthy(["current_medication", "prescription_medications"]) ||
        isSomeTruthy(["current_medication", "vitamins"]) ||
        isSomeTruthy(["current_medication", "over_medications"])),

    familyHealth:
      isSomeTruthy(["family_health_history", "check"], isZero) &&
      isSomeTruthy(["family_health_history", "medical_issues"], hasLength),

    currentMedConditions:
      isSomeTruthy(["current_medical_history", "check"], isZero) &&
      (isSomeTruthy(["current_medical_history", "pregnancy"]) ||
        isSomeTruthy(["current_medical_history", "breastfeeding"]) ||
        isSomeTruthy(["current_medical_history", "ongoing_conditions"]) ||
        isSomeTruthy(["current_medical_history", "nutrition"])),

    pastMedHistory:
      isSomeTruthy(["past_medical_history", "check"], isZero) &&
      (isSomeTruthy(["past_medical_history", "surgeries"]) ||
        isSomeTruthy(["past_medical_history", "hospitalizations"]) ||
        isSomeTruthy(["past_medical_history", "implants"]) ||
        isSomeTruthy(["past_medical_history", "vaccines"])),
  };
}

export function getNextIntersection({ intersection, actionType, value }) {
  switch (actionType) {
    case "height": {
      return {
        ...intersection,
        height: value,
      };
    }
    case "weight": {
      return {
        ...intersection,
        weight: value,
      };
    }
    case "socialHistory": {
      return {
        ...intersection,
        social_history: {
          ...(intersection?.social_history || {}),
          ...value,
        },
      };
    }
    case "allergies": {
      return {
        ...intersection,
        allergy: {
          ...(intersection?.allergy || {}),
          ...value,
        },
      };
    }
    case "currentMedications": {
      return {
        ...intersection,
        current_medication: {
          ...(intersection?.current_medication || {}),
          ...value,
        },
      };
    }
    case "familyHealth": {
      return {
        ...intersection,
        family_health_history: {
          ...(intersection?.family_health_history || {}),
          ...value,
        },
      };
    }
    case "currentMedConditions": {
      return {
        ...intersection,
        current_medical_history: {
          ...(intersection?.current_medical_history || {}),
          ...value,
        },
      };
    }
    case "pastMedHistory": {
      return {
        ...intersection,
        past_medical_history: {
          ...(intersection?.past_medical_history || {}),
          ...value,
        },
      };
    }
  }
}

export function getPatientsHistoryIntersection(medicalHistories) {
  const extractFirst = getExtractFirst({
    shapes: medicalHistories,
  });

  return {
    height: extractFirst(["height"]),
    weight: extractFirst(["weight"]),

    social_history: {
      tobacco_use: extractFirst(
        ["social_history", "tobacco_use"],
        0,
        isNotZero,
      ),
      tobacco_use_week: extractFirst(["social_history", "tobacco_use_week"]),
      tobacco_use_year: extractFirst(["social_history", "tobacco_use_year"]),

      drug_use: extractFirst(["social_history", "drug_use"], 0, isNotZero),
      drug_use_period: extractFirst(["social_history", "drug_use_period"]),
      drug_use_type: extractFirst(["social_history", "drug_use_type"]),
      drug_use_week: extractFirst(["social_history", "drug_use_week"]),

      alcohol_use: extractFirst(
        ["social_history", "alcohol_use"],
        0,
        isNotZero,
      ),
      alcohol_use_week: extractFirst(["social_history", "alcohol_use_week"]),

      exercise_use: extractFirst(
        ["social_history", "exercise_use"],
        0,
        isNotZero,
      ),
      exercise_use_week: extractFirst(["social_history", "exercise_use_week"]),

      weight: extractFirst(["social_history", "weight"], 0, isNotZero),
      weight_info: extractFirst(["social_history", "weight_info"]),
    },

    allergy: {
      check: extractFirst(["allergy", "check"], 1, not),

      drugs: extractFirst(["allergy", "drugs"], 0, isNotZero),
      allergy_drug: extractFirst(["allergy", "allergy_drug"], [], hasLength),

      foods: extractFirst(["allergy", "foods"], 0, isNotZero),
      allergy_food: extractFirst(["allergy", "allergy_food"], [], hasLength),

      environment: extractFirst(["allergy", "environment"], 0, isNotZero),
      allergy_environment: extractFirst(
        ["allergy", "allergy_environment"],
        [],
        hasLength,
      ),
    },

    current_medication: {
      check: extractFirst(["current_medication", "check"], 1, not),

      prescription_medications: extractFirst(
        ["current_medication", "prescription_medications"],
        0,
        isNotZero,
      ),
      prescription_medication: extractFirst(
        ["current_medication", "prescription_medication"],
        [],
        hasLength,
      ),

      vitamins: extractFirst(["current_medication", "vitamins"], 0, isNotZero),
      vitamin: extractFirst(["current_medication", "vitamin"], [], hasLength),

      over_medications: extractFirst(
        ["current_medication", "over_medications"],
        0,
        isNotZero,
      ),
      over_medication: extractFirst(
        ["current_medication", "over_medication"],
        [],
        hasLength,
      ),
    },

    family_health_history: {
      check: extractFirst(["family_health_history", "check"], 1, not),

      medical_issues: extractFirst(
        ["family_health_history", "medical_issues"],
        [],
        hasLength,
      ),
    },

    current_medical_history: {
      check: extractFirst(["current_medical_history", "check"], 1, not),

      pregnancy: extractFirst(
        ["current_medical_history", "pregnancy"],
        0,
        isNotZero,
      ),

      breastfeeding: extractFirst(
        ["current_medical_history", "breastfeeding"],
        0,
        isNotZero,
      ),

      ongoing_conditions: extractFirst(
        ["current_medical_history", "ongoing_conditions"],
        0,
        isNotZero,
      ),

      ongoing_condition: extractFirst(
        ["current_medical_history", "ongoing_condition"],
        [],
        hasLength,
      ),

      nutrition: extractFirst(
        ["current_medical_history", "nutrition"],
        0,
        isNotZero,
      ),

      nutrition_history: extractFirst(
        ["current_medical_history", "nutrition_history"],
        "",
      ),
    },

    past_medical_history: {
      check: extractFirst(["past_medical_history", "check"], 1, not),

      surgeries: extractFirst(
        ["past_medical_history", "surgeries"],
        0,
        isNotZero,
      ),
      surgery: extractFirst(["past_medical_history", "surgery"], [], hasLength),

      hospitalizations: extractFirst(
        ["past_medical_history", "hospitalizations"],
        0,
        isNotZero,
      ),
      hospitalization: extractFirst(
        ["past_medical_history", "hospitalization"],
        [],
        hasLength,
      ),

      implants: extractFirst(
        ["past_medical_history", "implants"],
        0,
        isNotZero,
      ),
      implant: extractFirst(["past_medical_history", "implant"], [], hasLength),

      vaccines: extractFirst(
        ["past_medical_history", "vaccines"],
        0,
        isNotZero,
      ),
      vaccine: extractFirst(["past_medical_history", "vaccine"], [], hasLength),
    },
  };
}

export const getAllergyCheck = (intersection) =>
  pipe(
    [intersection.drugs, intersection.foods, intersection.environment],
    (v) => v.some(isNotZero),
    boolToCheck,
  );

export const getCurrentMedicationCheck = (intersection) =>
  pipe(
    [
      intersection.prescription_medications,
      intersection.vitamins,
      intersection.over_medications,
    ],
    (v) => v.some(isNotZero),
    boolToCheck,
  );

export const getFamilyHealthCheck = (intersection) =>
  pipe(intersection.medical_issues, hasLength, boolToCheck);

export const getCurrentMedicalHistoryCheck = (intersection) =>
  pipe(
    [
      intersection.pregnancy,
      intersection.breastfeeding,
      intersection.ongoing_conditions,
      intersection.nutrition,
    ],
    (v) => v.some(isNotZero),
    boolToCheck,
  );

export const getPastMedicalHistoryCheck = (intersection) =>
  pipe(
    [
      intersection.surgeries,
      intersection.hospitalizations,
      intersection.implants,
      intersection.vaccines,
    ],
    (v) => v.some(isNotZero),
    boolToCheck,
  );

export const withInvalidateTimeline = (Component) => (props) => {
  const queryClient = useQueryClient();

  return (
    <Component
      {...props}
      invalidateTimeline={() => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientProcedures],
        });
      }}
    />
  );
};
