import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  searchTerm: yup.string(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    patients: yup
      .array()
      .of(
        yup.object({
          groupId: yup.number().required(),
          name: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const data = unwrapOr(() => res.data.data.data, []);
  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data, {}),
      pageSize: req.pageSize,
    }),
    patients: data.map((p) => ({
      groupId: p.group_id,
      name: p.name,
    })),
  };
};

function createNonDupPatientsQueryKey({ searchTerm, pageSize }) {
  return [QUERY_KEYS.nonDupPatients, searchTerm, pageSize].filter(Boolean);
}

export function useNonDupPatientsQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createNonDupPatientsQueryKey({
      searchTerm: req.searchTerm,
      pageSize: req.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getNonDupPatients(), {
        params: {
          page: pageParam,
          pagesize: req.pageSize,
          term: req.searchTerm || "",
        },
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
