import { useVerify2FaGoogleMutation } from "../../../api/mutations/useVerify2FaGoogleMutation";
import { useVerify2FaOtpMutation } from "../../../api/mutations/useVerify2FaOtpMutation";
import { TWO_FACTOR_TYPES } from "../../../consts/api";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";
import { authService } from "../../../services/AuthService";
import { ROUTES } from "../../../consts/routes";
import { useSwitchWorkspace } from "../../../hooks/useSwitchWorkspace";

export function useTwoFactorLogin() {
  const wpuId = authService.getPreLoginData().wpuId;
  const preLoginData = authService.getPreLoginData();
  const { tAuth } = useAppTranslation.Auth();
  const { switchWorkspace, isWorkspaceSwitching } = useSwitchWorkspace();

  const { mutateAsync: mutateOtp, isLoading: isOtpLoading } =
    useVerify2FaOtpMutation();

  const { mutateAsync: mutateGoogle, isLoading: isGoogleLoading } =
    useVerify2FaGoogleMutation();

  const verifyOtp = async (code) => {
    const { accessToken, workspaces } = await mutateOtp({
      wpuId,
      otp: code,
    });
    return {
      accessToken,
      workspaces,
    };
  };

  const verifyGoogle = async (code) => {
    const { accessToken, workspaces } = await mutateGoogle({
      wpuId,
      googleAuthCode: preLoginData.googleAuthCode,
      authToken: code,
    });
    return {
      accessToken,
      workspaces,
    };
  };

  const verify = async (formValues, { resetForm }) => {
    try {
      if (!wpuId) throw new Error();

      const isOpt =
        preLoginData.twoFactorType === TWO_FACTOR_TYPES.email ||
        preLoginData.twoFactorType === TWO_FACTOR_TYPES.sms;

      const isGoggle =
        preLoginData.twoFactorType === TWO_FACTOR_TYPES.google &&
        Boolean(preLoginData.googleAuthCode);

      let res = {
        accessToken: null,
        workspaces: [],
      };

      if (isOpt) {
        res = await verifyOtp(formValues.code);
      } else if (isGoggle) {
        res = await verifyGoogle(formValues.code);
      } else {
        throw new Error();
      }

      authService.setLoginData({ accessToken: res.accessToken, wpuId });

      if (res.workspaces.length === 1) {
        switchWorkspace({ workspaceId: res.workspaces[0].id }).then(
          authService.afterLogin,
        );
      } else {
        window.location.href = ROUTES.workspaces();
      }
    } catch (e) {
      const key = extractApiError(e);
      uiNotification.error(
        tAuth([`apiError.${key}`, "error.fallback2StepLogin"]),
      );
      resetForm();
    }
  };

  return {
    verify,
    isVerifying: isOtpLoading || isGoogleLoading || isWorkspaceSwitching,
  };
}
