import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  id: yup.number().required(),
});

export function usePatientVitalsSettingRangeDeleteMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return http.delete(
        HTTP_ENDPOINTS.patientVitals.deleteSettingRange(req.id),
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.settingRanges],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.all],
        });

        options?.onSuccess?.(...args);
      },
    },
  );
}
