import { useMemo } from "react";
import queryString from "query-string";
import { useClinicsQuery } from "../../../../../api/queries/useClinicsQuery";

export function useClinic() {
  const queryParams = queryString.parse(window.location.search);
  const clinicId = queryParams?.clinicId;
  const { data } = useClinicsQuery();

  return useMemo(() => {
    if (clinicId) {
      return data?.find((c) => c.id === Number(clinicId));
    }
    return null;
  }, [data, clinicId]);
}
