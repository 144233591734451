/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { useSpring, a, animated } from "react-spring";
import { Link } from "react-router-dom";
import {
  useMeasure,
  usePrevious,
} from "../../../_legacy/Containers/Settings/SidebarHelper";

const toggle = {
  marginTop: 0,
  cursor: "pointer",
  verticalAlign: "top",
  display: "inline-block",
};

export const Tree = ({
  children,
  name,
  styleClass,
  styleLinkClass,
  childClass,
  navigateTo,
  activeMenu,
  parentIndex,
  handleClinicSelection,
  clinicObj,
}) => {
  const [isOpen, setOpen] = useState(activeMenu);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform, visibility } = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      visibility: "hidden",
    },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      visibility: isOpen ? "visible" : "hidden",
    },
  });

  return (
    <li className={styleClass}>
      {navigateTo || (handleClinicSelection && clinicObj && clinicObj.id) ? (
        handleClinicSelection && clinicObj && clinicObj.id ? (
          <a
            className={styleLinkClass}
            onClick={handleClinicSelection.bind(this, clinicObj)}
            data-index={2}
          >
            {name}
          </a>
        ) : navigateTo != "javascript:void(0);" ? (
          <Link className={styleLinkClass} to={navigateTo} data-index={1}>
            {" "}
            {name}
          </Link>
        ) : (
          <a className={styleLinkClass} data-index={3}>
            {name}
          </a>
        )
      ) : (
        <a
          className={styleLinkClass}
          style={{ ...toggle, opacity: children ? 1 : 0 }}
          onClick={() => setOpen(!isOpen)}
          data-is-parent-sidebar={1}
          data-is-opened={isOpen ? 1 : 0}
          data-parent-index={parentIndex}
        >
          {!navigateTo && isOpen && (
            <span
              style={{ ...toggle }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-up"
                data-is-parent-sidebar={1}
                data-is-opened={isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
          {!navigateTo && !isOpen && (
            <span
              style={{ ...toggle }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={!isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-down"
                data-is-parent-sidebar={1}
                data-is-opened={!isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
          &nbsp;{name}
        </a>
      )}

      <animated.div
        className={opacity == 0 ? "no-display frame-content" : "frame-content"}
        style={{
          opacity,
          height: isOpen && previous === isOpen ? "auto" : height,
          visibility,
        }}
      >
        <a.ul
          className={childClass}
          style={{ transform }}
          {...bind}
          children={children}
        />
      </animated.div>
    </li>
  );
};
