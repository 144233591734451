import { useState } from "react";
import { useInviteOTPMutation } from "../../../../api/mutations/useInviteOTPMutation";
import { extractApiError } from "../../../../utilities/api";
import { Exception } from "../../../../utilities/general";

const ERROR_FALLBACK = "Unable To Send OTP Invite";

export function useApiOtpSend() {
  const [isSent, setIsSent] = useState(false);
  const { mutateAsync, isLoading } = useInviteOTPMutation();

  const initiate = async ({ workspaceId }) => {
    try {
      if (workspaceId) {
        await mutateAsync({ workspaceId });
        setIsSent(true);
      } else {
        throw new Exception(ERROR_FALLBACK);
      }
    } catch (e) {
      if (e instanceof Exception) {
        throw new Error(e.message);
      } else {
        throw new Error(extractApiError(e) || ERROR_FALLBACK);
      }
    }
  };

  return {
    initiate,
    isLoading,
    isSent,
  };
}
