import { useMutation } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../consts/api";

export const useEditDefaultDocumentExpirationDate = (options = {}) => {
  return useMutation((payload) => {
    return http.post(HTTP_ENDPOINTS.editDefaultDocumentExpirationDate(), {
      document_type: payload.documentType,
      valability_days: payload.valabilityDays,
    });
  }, options);
};
