import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  ids: yup.array().of(yup.number()).required(),
});

export function useRemoveInboxBlackListEntryMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.delete(HTTP_ENDPOINTS.inboxBlacklist.deleteEntry(), {
      data: {
        ids: req.ids,
      },
    });
  }, options);
}
