import { FILE_EXTENSIONS } from "../../../consts/general";
import { useDocxParser } from "../../../hooks/fileParsers/useDocxParser";
import { usePdfParser } from "../../../hooks/fileParsers/usePdfParser";
import { fileUtil } from "../../../utilities/file";
import { SUPPORTED_OUTPUTS } from "../FileParser.consts";

export function useParser({ multiple, output }) {
  const docxParser = useDocxParser();
  const pdfParser = usePdfParser();

  const parsers = {
    [FILE_EXTENSIONS.docx]: {
      [SUPPORTED_OUTPUTS.html]: docxParser.parseToHtml,
      [SUPPORTED_OUTPUTS.text]: docxParser.parseToText,
    },
    [FILE_EXTENSIONS.pdf]: {
      [SUPPORTED_OUTPUTS.html]: pdfParser.parseToHtml,
      [SUPPORTED_OUTPUTS.text]: pdfParser.parseToText,
    },
  };

  const composeFile = ({ parsedContent, id, file, fileExtension }) => ({
    id: id,
    name: file.name,
    sizeBytes: file.size,
    lastModified: file.lastModified,
    ext: fileExtension,
    parsedContent,
    raw: file,
  });

  const onParse = async (files) => {
    const filesToParse = multiple ? files : [files[0]];
    const result = await Promise.all(
      filesToParse.map(({ id, file }) => {
        const fileExtension = fileUtil.getExtensionFromName(file.name);
        return parsers[fileExtension][output](file, {
          id,
          fileExtension,
          file,
        });
      }),
    );
    return result.map((r) =>
      composeFile({
        parsedContent: r.content,
        id: r.extra.id,
        file: r.extra.file,
        fileExtension: r.extra.fileExtension,
      }),
    );
  };

  return {
    onParse,
  };
}
