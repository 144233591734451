import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { numberFormat } from "../../../../Utils/services.js";

const options = (type, reportData) => {
  let data = [];

  if (type === "first") {
    if (reportData && reportData.length > 0) {
      reportData.map((obj1) => {
        let tempObj = {};
        tempObj.title = obj1.name;
        tempObj.name =
          obj1.name + ", " + obj1.purchased + " / " + obj1.percentage + "%";
        tempObj.y = obj1.percentage;
        tempObj.z = obj1.percentage;
        tempObj.totalNum = obj1.purchased;
        data.push(tempObj);
      });
    }
  }

  if (type === "second") {
    if (reportData && reportData.length > 0) {
      reportData.map((obj2) => {
        let tempObj = {};
        tempObj.title = obj2.name;
        tempObj.name =
          obj2.name + ", " + obj2.redemption + " / " + obj2.percentage + "%";
        tempObj.y = obj2.percentage;
        tempObj.z = obj2.percentage;
        tempObj.totalNum = obj2.redemption;
        data.push(tempObj);
      });
    }
  }

  if (type === "third") {
    if (reportData) {
      data.push(
        {
          name:
            "eGift cards sold, " +
            reportData.total_sold +
            " / " +
            reportData.sold_percentage +
            "%",
          y: reportData.sold_percentage,
          z: reportData.sold_percentage,
        },
        {
          name:
            "Redeems, " +
            reportData.total_redemption +
            " / " +
            reportData.total_redeemed_percentage +
            "%",
          y: reportData.total_redeemed_percentage,
          z: reportData.total_redeemed_percentage,
        },
      );
    }
  }

  return {
    chart: {
      type: "pie",
      margin: [-10, 250, 50, 15],
      height: 380,
      width: 430,
      backgroundColor: "rgba(255, 255, 255, 0.0)",
    },
    title: {
      text: "",
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "<b>{point.name}</b><br/>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      maxHeight: 160,
      symbolHeight: 14,
      symbolWidth: 14,
      symbolRadius: 3,
      width: 230,
      itemMarginBottom: 5,
      x: 0,
      y: 70,
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "50%",
        zMin: 0,
        data: data,
      },
    ],
  };
};

class EgiftCards extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData
    ) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  render() {
    let totalTransactions = 0;

    if (this.state.reportData && this.state.reportData.gift_card_transactions) {
      totalTransactions =
        this.state.reportData.gift_card_transactions.total_redemption +
        this.state.reportData.gift_card_transactions.total_sold;
    }

    return (
      <div>
        <div className="row new-sale-row">
          {this.state.reportData &&
            this.state.reportData.gift_card_purchased &&
            this.state.reportData.gift_card_purchased.length > 0 && (
              <div className="col-lg-6 new-sale-col">
                <div className="setting-setion m-b-15">
                  <div className="graphHeading">
                    {this.state.languageData.bi_types_of_egift_cards_sold}
                  </div>
                  <div
                    className="dash-box-content egiftCardGraph businessGraphOuter"
                    data-highcharts-chart="0"
                  >
                    <div
                      className="highcharts-container "
                      data-highcharts-chart="0"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "400px",
                        height: "200px",
                        textAlign: "left",
                        lineHeight: "normal",
                        zIndex: "0",
                        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                      }}
                    >
                      <PieChart
                        highcharts={Highcharts}
                        options={options(
                          "first",
                          this.state.reportData.gift_card_purchased,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

          {this.state.reportData &&
            this.state.reportData.gift_card_redeemed &&
            this.state.reportData.gift_card_redeemed.length > 0 && (
              <div className="col-lg-6 new-sale-col">
                <div className="setting-setion m-b-15">
                  <div className="graphHeading">
                    {this.state.languageData.bi_types_of_egift_cards_redeemed}
                  </div>
                  <div
                    className="dash-box-content egiftCardGraph businessGraphOuter"
                    data-highcharts-chart="0"
                  >
                    <div
                      className="highcharts-container "
                      data-highcharts-chart="0"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "400px",
                        height: "200px",
                        textAlign: "left",
                        lineHeight: "normal",
                        zIndex: "0",
                        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                      }}
                    >
                      <PieChart
                        highcharts={Highcharts}
                        options={options(
                          "second",
                          this.state.reportData.gift_card_redeemed,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

          {this.state.reportData &&
            ((this.state.reportData.gift_card_redeemed &&
              this.state.reportData.gift_card_redeemed.length > 0) ||
              (this.state.reportData &&
                this.state.reportData.gift_card_purchased &&
                this.state.reportData.gift_card_purchased.length > 0)) && (
              <div className="col-lg-6 new-sale-col">
                <div className="setting-setion m-b-15">
                  <div className="graphHeading">
                    {
                      this.state.languageData
                        .bi_types_of_egift_cards_sold_redeemed
                    }
                  </div>
                  <h3 className="totalTransaction">
                    {this.state.languageData.bi_total_gift_card_transaction}:{" "}
                    {totalTransactions}
                  </h3>
                  <div
                    className="dash-box-content egiftCardGraph businessGraphOuter"
                    data-highcharts-chart="0"
                  >
                    <div
                      className="highcharts-container "
                      data-highcharts-chart="0"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "400px",
                        height: "200px",
                        textAlign: "left",
                        lineHeight: "normal",
                        zIndex: "0",
                        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                      }}
                    >
                      <PieChart
                        highcharts={Highcharts}
                        options={options(
                          "third",
                          this.state.reportData.gift_card_transactions,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>

        <div className="table-responsive clients-table">
          <table className="table-updated setting-table table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              {
                <tr>
                  <th className="table-updated-th">
                    {this.state.reportData &&
                      this.state.reportData.report_header &&
                      this.state.reportData.report_header.name}
                  </th>
                  <th className="table-updated-th">
                    {this.state.reportData &&
                      this.state.reportData.report_header &&
                      this.state.reportData.report_header.purchased}
                  </th>
                  <th className="table-updated-th">
                    {this.state.reportData &&
                      this.state.reportData.report_header &&
                      this.state.reportData.report_header.redemption}
                  </th>
                  <th className="table-updated-th">
                    {this.state.reportData &&
                      this.state.reportData.report_header &&
                      this.state.reportData.report_header.outstanding_liability}
                  </th>
                </tr>
              }
            </thead>
            <tbody className="ajax_body">
              {this.state.reportData &&
                this.state.reportData.report_data &&
                this.state.reportData.report_data.length > 0 &&
                this.state.reportData.report_data.map((obj, idx) => {
                  return (
                    <tr key={idx} className="table-updated-tr">
                      <td className="table-updated-td">{obj.name}</td>
                      <td className="table-updated-td">{obj.purchased}</td>
                      <td className="table-updated-td">{obj.redemption}</td>
                      <td className="table-updated-td">
                        {numberFormat(obj.outstanding_liability, "currency")}
                      </td>
                    </tr>
                  );
                })}
              {this.state.reportData &&
                this.state.reportData.report_data &&
                this.state.reportData.report_data.length === 0 && (
                  <tr className="table-updated-tr">
                    <td
                      className="table-updated-td no-record no-float"
                      colSpan="10"
                    >
                      {this.state.languageData.bi_no_record_found}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EgiftCards;
