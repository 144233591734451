import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toDate } from "date-fns";
import { dateFormatPicker } from "../../Utils/services.js";

const valueToNull = (value) => {
  if (typeof value != undefined && value != null && value != "0000-00-00") {
    return toDate(value);
  } else {
    return null;
  }
};

export default class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userChanged: false,
      maxDate: this.props.maxDate
        ? this.props.maxDate
        : new Date(moment().add(20, "years").toDate()),
      minDate: this.props.minDate
        ? this.props.minDate
        : new Date(moment().subtract(20, "years").toDate()),
      value: this.props.value ? valueToNull(this.props.value) : "",
      format: dateFormatPicker(), //(this.props.format) ? this.props.format : 'yyyy-MM-dd',
      name: this.props.name ? this.props.name : "date_picker",
      class: this.props.class ? this.props.class : "setting-input-box",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.value !== undefined && nextProps.value !== prevState.value) {
      returnState.value = valueToNull(nextProps.value);
    }
    if (nextProps.class !== undefined && nextProps.class !== prevState.class) {
      returnState.class = nextProps.class;
    }
    return returnState;
  }

  onChangeDatePicker = (date) => {
    this.setState({ userChanged: true, value: date });
    this.props.onChangeDatePicker({
      userChanged: true,
      [this.state.name]: date,
    });
  };

  render() {
    return (
      <DatePicker
        className={this.state.class}
        selected={this.state.value ? this.state.value : null}
        onChange={this.onChangeDatePicker}
        maxDate={this.state.maxDate}
        minDate={this.state.minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        name={this.state.name}
        readOnly={this.props.readOnly}
        autoComplete="off"
        dateFormat={dateFormatPicker()}
        placeholderText={this.state.format.toLowerCase()}
        ref={this.props.reference}
        onClickOutside={this.props.onClickOutside}
        disabled={this.props.disabled || false}
      />
    );
  }
}
