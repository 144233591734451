import { useMemo } from "react";
import { useDebouncedState } from "../../../../utilities/hooks/useDebouncedState";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useInboxBlacklistQuery } from "../../../../api/inboxBlacklist/useInboxBlacklistQuery";

export function useBlackList() {
  const { tInbox } = useAppTranslation.Inbox();
  const [search, debouncedSearch, setSearch] = useDebouncedState("");

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInboxBlacklistQuery({
    payload: {
      pageSize: 15,
      searchTerm: debouncedSearch,
    },
    options: {
      staleTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tInbox("blackList.error.getList"));
      },
    },
  });

  const list = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.list).reduce((r, m) => [...r, ...m], []);
    }
    return [];
  }, [data?.pages]);

  const isSoftLoading = isFetching && !isLoading && !isFetchingNextPage;

  return {
    value: list,
    isLoading,
    isSoftLoading,
    hasMore: hasNextPage,
    isNextFetching: isFetchingNextPage,
    fetchMore: fetchNextPage,
    search: {
      value: search,
      onChange: setSearch,
    },
    refetch,
  };
}
