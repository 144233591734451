import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Box } from "../../../../shared/Box/Box";
import { InputSearch } from "../../../../boxes/InputSearch/InputSearch";
import {
  PROCEDURE_SKELETON_BORDER_RADIUS,
  SEARCH_INPUT_WIDTH,
  SKELETON_HEIGHT,
  TABLE_SIZE,
} from "../../ProviderRoom.consts";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../shared/Empty/Empty";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { LoadMoreObserver } from "../../../../shared/LoadMoreObserver/LoadMoreObserver";
import classes from "../../sass/Layout.module.scss";

export function Layout({
  search,
  children,
  status,
  loadMore,
  topRight,
  noTabs,
}) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Box>
      <div
        className={cx(classes.top, {
          [classes.topBottomGutter]: noTabs,
        })}
      >
        <InputSearch
          value={search.value}
          onChange={search.onChange}
          width={SEARCH_INPUT_WIDTH}
        />
        {topRight}
      </div>
      {children}
      {status.isLoading && (
        <Skeleton
          count={TABLE_SIZE}
          height={SKELETON_HEIGHT}
          borderRadius={PROCEDURE_SKELETON_BORDER_RADIUS}
        />
      )}
      {status.isSearching && (
        <Empty position="center">{tCommon("label.searching")}</Empty>
      )}
      {status.isEmpty && <Empty position="center" />}
      {status.hasMore && (
        <LoadMoreObserver
          loadMore={loadMore}
          isLoading={status.isNextFetching}
        />
      )}
    </Box>
  );
}

Layout.propTypes = {
  search: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    isLoading: PropTypes.bool,
    isNextFetching: PropTypes.bool,
    isSearching: PropTypes.bool,
    isEmpty: PropTypes.bool,
    hasMore: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node.isRequired,
  loadMore: PropTypes.func.isRequired,
  noTabs: PropTypes.bool,
  topRight: PropTypes.node,
};

Layout.defaultProps = {
  noTabs: false,
};
