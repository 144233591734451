/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import moment from "moment";
import picClose from "../../../_legacy/images/popupClose.png";
import ReportsFilters from "../ReportsFilters.js";
import { fromToDateRange } from "../../../Utils/services.js";
import {
  acceleratorInsightsReputation,
  isRestrictedReport,
} from "../../../Utils/privilege.js";
import UpgradeAccount from "../../Common/UpgradeAccount.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const FromToMonthDate = fromToDateRange("mtd");
const ApiDateFormat = "YYYY-MM-DD";
const initReportFilter = () => {
  return {
    date: "mtd",
    from_date: FromToMonthDate.from_date,
    to_date: FromToMonthDate.from_date,
    service: "",
    month_of_birth: "1",
    spent_money: "",
    discount_type: "0",
    provider: "0",
    cashier: "0",
    product: "",
    cancellation_fee_charge: false,
    lot_number: "",
    month: "1",
    month_year: "2017-01",
    year: "2017",
    rate: "0",
    clinic: 0,
  };
};

const initReportFilterClass = () => {
  return {
    date: "simpleSelect",
    from_date: FromToMonthDate.from_date,
    to_date: FromToMonthDate.from_date,
    service: "simpleSelect",
    month_of_birth: "simpleSelect",
    spent_money: "TP-discount-outer noShadow",
    discount_type: "simpleSelect",
    provider: "simpleSelect",
    cashier: "simpleSelect",
    product: "simpleSelect",
    cancellation_fee_charge: false,
    lot_number: "simpleInput",
    month: "simpleSelect",
    month_year: "simpleSelect",
    year: "simpleSelect",
    rate: "simpleSelect",
    clinic: "simpleSelect",
  };
};

export default class ReputationReportOptions extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      showCreateOptions: props.showCreateOptions
        ? props.showCreateOptions
        : false,
      type: "reputation-management",
      report_type_sysname: "",
      report_type_id: "",
      reportData: {},
      reportOptions: props.reportOptions ? props.reportOptions : [],
      reportVariableData: props.reportVariableData
        ? props.reportVariableData
        : [],
      showFilters: false,
      rangeClass: "col-sm-6 col-xs-12 calender no-display",
      reportFilter: initReportFilter(),
      reportFilterClass: initReportFilterClass(),
      resetEditMode: false,
      preSelectedData: {},
      languageData: languageData.business_insights,
      acceleratorPlan: acceleratorInsightsReputation(),
      isRestrictedPrivilege: false,
    };
  }

  componentDidMount() {
    const reportOptionFilter = JSON.parse(
      localStorage.getItem("reportOptionFilter"),
    );

    if (reportOptionFilter && reportOptionFilter.report_type_id) {
      let reportFilter = initReportFilter();
      reportFilter.cancellation_fee_charge =
        reportOptionFilter.cancellation_fee_charge === true ||
        reportOptionFilter.cancellation_fee_charge === "true"
          ? true
          : false;

      if (reportOptionFilter.date !== "custom") {
        let fromToDate = fromToDateRange(reportOptionFilter.date);
        reportFilter.from_date = fromToDate.from_date;
        reportFilter.to_date = fromToDate.to_date;
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.reportOptions !== undefined &&
      nextProps.reportOptions !== prevState.reportOptions
    ) {
      returnState.reportOptions = nextProps.reportOptions;

      if (
        nextProps.reportID &&
        nextProps.allData &&
        nextProps.allData.business_insight_id
      ) {
        returnState.report_type_id = nextProps.allData.business_insight_id;
        returnState.report_type_sysname =
          nextProps.allData.business_insight_sysname;
        returnState.reportVariableData =
          nextProps.allData.business_insight_variables;
        returnState.reportName = nextProps.allData.business_insight_report_name;
        returnState.reportFilter = initReportFilter();

        if (
          nextProps.allData.business_insight_variables &&
          nextProps.allData.business_insight_variables.length > 0
        ) {
          nextProps.allData.business_insight_variables.map((obj) => {
            if (obj.variable === "product") {
              returnState.reportFilter.product = obj.value ? obj.value : "";
            }
            if (obj.variable === "provider") {
              returnState.reportFilter.provider = obj.value ? obj.value : 0;
            }
            if (obj.variable === "month_year") {
              returnState.reportFilter.month_year = obj.value;
            }
            if (obj.variable === "year") {
              returnState.reportFilter.year = obj.value;
            }
            if (obj.variable === "month_of_birth") {
              returnState.reportFilter.month_of_birth = obj.value;
            }
            if (obj.variable === "date") {
              returnState.reportFilter.date = obj.value;

              if (
                obj.value !== "qtd" &&
                obj.value !== "mtd" &&
                obj.value !== "ytd"
              ) {
                returnState.reportFilter.date = "custom";
                if (obj.value) {
                  let dateArray = obj.value.split("|");
                  returnState.startDate = moment(dateArray[0]).toDate();
                  returnState.endDate = moment(dateArray[1]).toDate();

                  returnState.reportFilter.from_date = moment(
                    dateArray[0],
                  ).format(ApiDateFormat);
                  returnState.reportFilter.to_date = moment(
                    dateArray[1],
                  ).format(ApiDateFormat);
                }
              }
            }
            if (obj.variable === "rate") {
              returnState.reportFilter.rate = obj.value;
            }
            if (obj.variable === "service") {
              returnState.reportFilter.service = obj.value;
            }
            if (obj.variable === "clinic") {
              returnState.reportFilter.clinic = obj.value;
            }
          });
        }

        returnState.preSelectedData = {
          report_type_sysname: returnState.report_type_sysname,
          report_type_id: returnState.report_type_id,
          reportFilter: returnState.reportFilter,
        };

        let isRestrictedPrivilege = isRestrictedReport(
          prevState.acceleratorPlan,
          returnState.report_type_sysname,
        );
        returnState.isRestrictedPrivilege = isRestrictedPrivilege;
        if (!isRestrictedPrivilege) {
          returnState.resetEditMode = true;
          returnState.showLoader = true;
          nextProps.getReportVariables(returnState, !isRestrictedPrivilege);
        }
      }
    }
    if (
      nextProps.showCreateOptions !== undefined &&
      nextProps.showCreateOptions === true &&
      prevState.showCreateOptions === false
    ) {
      returnState.showCreateOptions = nextProps.showCreateOptions;
      if (
        prevState.showCreateOptions === false &&
        prevState.preSelectedData &&
        prevState.preSelectedData.report_type_sysname
      ) {
        returnState.report_type_sysname =
          prevState.preSelectedData.report_type_sysname;
        returnState.report_type_id = prevState.preSelectedData.report_type_id;
        returnState.reportFilter = prevState.preSelectedData.reportFilter;
        returnState.reportVariableData =
          prevState.preSelectedData.reportVariableData;
      }
    }
    if (
      nextProps.reportVariableData !== undefined &&
      nextProps.reportVariableData !== prevState.reportVariableData
    ) {
      returnState.reportVariableData = nextProps.reportVariableData;
      if (!prevState.resetEditMode) {
        returnState.reportFilter = initReportFilter();
        returnState.reportFilterClass = initReportFilterClass();
        if (
          returnState.reportVariableData &&
          returnState.reportVariableData.length > 0 &&
          prevState.report_type_id != 18
        ) {
          returnState.reportVariableData.map((obj) => {
            if (obj.variable == "product") {
              if (obj.variable_data && obj.variable_data.length > 0) {
                returnState.reportFilter.product = obj.variable_data[0].id;
              }
            }
            if (obj.variable == "provider") {
              if (obj.variable_data && obj.variable_data.length > 0) {
                returnState.reportFilter.provider = obj.variable_data[0].id;
              }
            }
          });
        }
      } else {
        if (
          prevState.preSelectedData.reportVariableData !==
          returnState.reportVariableData
        ) {
          returnState.preSelectedData = prevState.preSelectedData;
          returnState.preSelectedData.reportVariableData =
            returnState.reportVariableData;
        }
      }
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });
    if (event.target.name === "filter_date") {
      if (value === "custom") {
        this.setState({ rangeClass: "col-sm-6 col-xs-12 calender" });
      } else {
        this.setState({ rangeClass: "col-sm-6 col-xs-12 calender no-display" });
      }
    }
  };

  hideCreateOptions = () => {
    this.setState({ showCreateOptions: false });
    this.props.hideCreateOptions({ showCreateOptions: false });
  };

  getData = () => {
    if (!this.state.report_type_sysname) {
      uiNotification.clear();
      uiNotification.error(
        this.props.businessInsightLang.bi_please_select_a_report_type,
      );
      return;
    } else {
      let error = false;
      let msg = "";

      if (
        this.state.report_type_sysname ==
        "patients-who-gave-the-rate-of-x-or-below"
      ) {
        if (
          this.state.reportFilter.rate === "0" ||
          this.state.reportFilter.rate === 0
        ) {
          error = true;
          msg = this.state.languageData.bi_choose_rate;
        }
      }

      if (this.state.report_type_sysname == "nps-score-per-service") {
        if (
          this.state.reportFilter.service === "" ||
          this.state.reportFilter.service === null
        ) {
          error = true;
          msg = this.state.languageData.bi_choose_service;
        }
      }

      if (error) {
        uiNotification.clear();
        uiNotification.error(msg);
        return;
      } else {
        const preSelectedData = {
          report_type_sysname: this.state.report_type_sysname,
          report_type_id: this.state.report_type_id,
          reportFilter: this.state.reportFilter,
          reportVariableData: this.state.reportVariableData,
        };

        this.setState({
          showCreateOptions: false,
          preSelectedData: preSelectedData,
        });
        let reportOptionFilter = this.state.reportFilter;
        reportOptionFilter.report_type_sysname = this.state.report_type_sysname;
        reportOptionFilter.report_type_id = this.state.report_type_id;

        delete reportOptionFilter.is_download;
        this.props.getData({
          report_type_sysname: this.state.report_type_sysname,
          report_type_id: this.state.report_type_id,
          reportFilter: this.state.reportFilter,
          showCreateOptions: false,
        });
      }
    }
  };

  getReportVariables = (obj) => {
    let isRestrictedPrivilege = isRestrictedReport(
      this.state.acceleratorPlan,
      obj.sysname,
    );
    this.setState({ isRestrictedPrivilege: isRestrictedPrivilege });
    let returnState = {
      report_type_sysname: obj.sysname,
      report_type_id: obj.id,
      resetEditMode: false,
    };
    this.setState(returnState);
    if (!isRestrictedPrivilege) {
      returnState.showLoader = true;
    }
    this.props.getReportVariables(returnState, !isRestrictedPrivilege);
  };

  handleCalendarChange = (childState) => {
    this.setState(childState);
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  render() {
    return (
      <div
        className={
          this.state.showCreateOptions
            ? "blackOverlay"
            : "blackOverlay no-display"
        }
      >
        <div className="vert-middle vert-middle-report-option">
          <div className="white-popup small-popup">
            <div className="white-popup-wrapper">
              <div className="membershipTypeTitle">
                {this.props.businessInsightLang.bi_create_report}
                <a
                  onClick={() => this.hideCreateOptions()}
                  className="popupClose"
                >
                  <img src={picClose} alt="" />
                </a>
              </div>
              <div className="question-name-report">
                {
                  this.props.businessInsightLang
                    .bi_what_type_of_report_do_you_want_to_create
                }
              </div>
              {this.props.reportOptions &&
                this.props.reportOptions.length > 0 &&
                this.props.reportOptions.map((obj, idx) => {
                  let isRestrictedPrivilege = isRestrictedReport(
                    this.state.acceleratorPlan,
                    obj.sysname,
                  );
                  return (
                    <div key={idx} className="report-ans-outer">
                      <input
                        onChange={() => this.getReportVariables(obj)}
                        id={obj.sysname}
                        checked={this.state.report_type_sysname === obj.sysname}
                        className="basic-form-checkbox"
                        name="report_type_sysname"
                        value={obj.sysname}
                        type="radio"
                      />
                      <label className="basic-form-text" htmlFor={obj.sysname}>
                        {obj.name}{" "}
                        {isRestrictedPrivilege === true && (
                          <span className="fa-lock-upgrade-plan">
                            <i className="fa fa-lock" />
                          </span>
                        )}
                      </label>
                    </div>
                  );
                })}
              {this.state.isRestrictedPrivilege === false &&
                this.state.reportVariableData &&
                this.state.reportVariableData.length > 0 && (
                  <ReportsFilters
                    reportVariableData={this.state.reportVariableData}
                    reportSection={`clients`}
                    handleInputChange={this.handleInputChange}
                    parentState={this.state}
                    handleCalendarChange={this.handleCalendarChange}
                    handleChildState={this.handleChildState}
                    type={this.props.type}
                  />
                )}
              {this.state.isRestrictedPrivilege === true && (
                <div className="popp-up-upgrade-block">
                  <UpgradeAccount notificaionType={"report"} />
                </div>
              )}
            </div>
            {this.state.isRestrictedPrivilege === false && (
              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={() => this.getData()}
                >
                  {this.props.globalLang.label_continue}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={() => this.hideCreateOptions()}
                >
                  {this.props.globalLang.label_cancel}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
