import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useCreateAppointmentNotificationMutation(options = {}) {
  return useMutation((dto) => {
    return http.post(
      HTTP_ENDPOINTS.postAppointmentNotification(dto.appointmentId),
      {
        notification_type: dto.notificationType,
        notification_body: dto.notificationBody,
        appointment_sms: dto.sms,
        appointment_subject: dto.subject,
        appointment_id: dto.appointmentId,
        notifyFrom: dto.notifyFrom,
      },
    );
  }, options);
}
