import React, { Component } from "react";
import CheckboxTree from "react-checkbox-tree-enhanced";
import "react-checkbox-tree-enhanced/lib/react-checkbox-tree.css";

class ServicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: {},
      expanded: {},
    };
  }

  render() {
    const dataElements = this.props.providerServicesProviders
      ? Object.values(this.props.providerServicesProviders)
      : [];
    return (
      <tbody>
        {dataElements.map((category, i) => {
          return (
            <tr key={i} className={"table-updated-tr"}>
              <td
                className="table-updated-td col-xs-3 text-ellipsis services-name-checkbox"
                valign="top"
              >
                <CheckboxTree
                  nodes={[
                    {
                      value: category.name,
                      label: category.name,
                      children: category.services.map((services) => {
                        return {
                          value: `${services.id} - ${services.name}`,
                          label: services.name,
                        };
                      }),
                    },
                  ]}
                  checked={this.props.checkedServices[category.id] || []}
                  onCheck={(checked) => {
                    this.props.checkServices(checked, category);
                  }}
                  expanded={this.state.expanded[category.id] || []}
                  onExpand={(expanded) => {
                    this.setState({
                      expanded: {
                        ...this.state.expanded,
                        [category.id]: expanded,
                      },
                    });
                  }}
                />
              </td>
              <td
                className="col-xs-3 table-updated-td services-location"
                valign="top"
              >
                <CheckboxTree
                  nodes={[
                    {
                      value: category.name,
                      label: category.clinics.join(", "),
                      children: category.services.map((services, index) => {
                        return {
                          value: services.clinic_name + index,
                          label: services.clinic_name,
                        };
                      }),
                    },
                  ]}
                  checked={this.state.checked[category.id] || []}
                  onCheck={(checked) => {
                    this.setState({
                      checked: {
                        ...this.state.checked,
                        [category.id]: checked,
                      },
                    });
                  }}
                  expanded={this.state.expanded[category.id] || []}
                  onExpand={(expanded) => {
                    this.setState({
                      expanded: {
                        ...this.state.expanded,
                        [category.id]: expanded,
                      },
                    });
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}
export default ServicesList;
