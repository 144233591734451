import React from "react";

const ResumeMembershipIcon = () => {
  return (
    <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10Z"
        stroke="#188BF6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m9 7 6 4-6 4V7Z"
        stroke="#188BF6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResumeMembershipIcon;
