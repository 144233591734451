import React from "react";
import cx from "clsx";
import { useMessages } from "./hooks/useMessages";
import { Header } from "./shared/Header/Header";
import classes from "./Messages.module.scss";
import { useMultiselectEntity } from "../../../../utilities/hooks/useMultiselectEntity";
import { Box } from "../../../../shared/Box/Box";
import { Table } from "./shared/Table/Table";
import { Tabs } from "./shared/Tabs/Tabs";
import { Actions } from "./shared/Actions/Actions";
import { useClinic } from "./hooks/useClinic";

export function Messages() {
  const messages = useMessages();
  const clinic = useClinic();

  const selectedMessages = useMultiselectEntity({
    allEntities: messages.value.map((m) => m.id),
    initialSelect: [],
  });

  const onChangeType = (nextType) => {
    messages.changeType(nextType);
    selectedMessages.resetSelected();
  };

  return (
    <div className={cx("memberWalletOuter business-section", classes.root)}>
      <Header search={messages.search} clinicName={clinic?.name} />
      <Box>
        <div className={classes.header}>
          <Tabs
            unreadCount={messages.unreadCount}
            allCount={messages.allCount}
            type={messages.type}
            changeType={onChangeType}
          />
          {selectedMessages.selected.length > 0 && (
            <Actions
              type={messages.type}
              isMarkReadLoading={messages.markRead.isLoading}
              isMarkUnreadLoading={messages.markUnread.isLoading}
              markRead={() =>
                messages.markRead
                  .initiate(selectedMessages.selected)
                  .then(selectedMessages.resetSelected)
              }
              markUnread={() =>
                messages.markUnread
                  .initiate(selectedMessages.selected)
                  .then(selectedMessages.resetSelected)
              }
            />
          )}
        </div>
        <Table messages={messages} selectedMessages={selectedMessages} />
      </Box>
    </div>
  );
}
