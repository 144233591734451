import React from "react";
import queryString from "query-string";
import { Messages } from "./Messages/Messages";
import { Chat } from "./Chat/Chat";

export function LeadArInbox() {
  const queryParams = queryString.parse(window.location.search);
  const chatId = queryParams?.id;
  return chatId ? <Chat id={chatId} /> : <Messages />;
}
