import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function geClientNotes(formData) {
  return (dispatch) => {
    http
      .get("client-notes", formData)
      .then((response) => {
        dispatch({ type: "GET_CLIENT_NOTES", payload: response.data });
        dispatch({ type: "RESET_ALL" });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CLIENT_NOTES",
          payload: error.response?.data || {},
        });
      });
  };
}

export function saveClientNote({ formData, noteID }) {
  noteID = noteID || 0;

  if (noteID && noteID > 0) {
    return async (dispatch) => {
      return http
        .patch("client-notes/" + noteID, formData)
        .then((response) => {
          dispatch({ type: "UPDATE_CLIENT_NOTE", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "UPDATE_CLIENT_NOTE",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    };
  } else {
    return async (dispatch) => {
      return http
        .post("client-notes", formData)
        .then((response) => {
          dispatch({ type: "SAVE_CLIENT_NOTES", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({ type: "SAVE_CLIENT_NOTES", payload: error.response.data });
          return Promise.reject(error.response.data);
        });
    };
  }
}

export function deleteClientNote(noteID) {
  return async (dispatch) => {
    return http
      .delete("client-notes/" + noteID)
      .then((response) => {
        dispatch({ type: "DELETE_CLIENT_NOTE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "DELETE_CLIENT_NOTE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getAClientNote(noteID) {
  return async (dispatch) => {
    return http
      .get("client-notes/" + noteID)
      .then((response) => {
        dispatch({ type: "GET_CLIENT_NOTE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_CLIENT_NOTE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}
