import { useMemo } from "react";
import { useCardReadersQuery } from "../../../../api/queries/useCardReadersQuery";
import { useClinicsQuery } from "../../../../api/queries/useClinicsQuery";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { CARD_READER_PAYWORK_STATUSES } from "../../../../consts/api";
import { usePatchCardReaderMutation } from "../../../../api/mutations/usePatchCardReaderMutation";

export function useCardReaders() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { data: user } = useCurrentUserQuery();
  const clinicsQuery = useClinicsQuery();

  const cardReadersQuery = useCardReadersQuery({
    payload: {
      accountId: user?.account?.id,
    },
    options: {
      onError: () => {
        uiNotification.error(tSales("checkoutInvoice.error.cardReadersFetch"));
      },
      staleTime: 0,
    },
  });

  const { mutateAsync: patchCardReader } = usePatchCardReaderMutation();

  const cardReaders = useMemo(() => {
    const readers = cardReadersQuery.data || [];

    const isReaderActivePredicate = (reader) =>
      reader.payWorksStatus === CARD_READER_PAYWORK_STATUSES.active;

    return readers.filter(isReaderActivePredicate).map((reader) => {
      const clinic = clinicsQuery.data?.find((c) => c.id === reader.clinicId);
      return {
        id: reader.id,
        serialNumber: reader.serialNumber || tCommon("label.unknown"),
        clinic: {
          id: clinic?.id,
          name: clinic?.name || tCommon("label.unknown"),
        },
      };
    });
  }, [cardReadersQuery.data, clinicsQuery.data]);

  const defaultCardReader = cardReadersQuery.data?.find(
    (reader) => reader.isDefault,
  );

  return {
    cardReaders,

    isCardReadersLoading:
      clinicsQuery.isFetching || cardReadersQuery.isFetching,

    defaultCardReader,

    setDefaultCardReader: ({ clinicId, cardReaderId }) =>
      patchCardReader({
        cardReaderId,
        accountId: user?.account?.id,
        clinicId,
        isDefault: true,
        payWorksStatus: CARD_READER_PAYWORK_STATUSES.active,
      }),
  };
}
