/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { numberFormat, showFormattedDate } from "../../../../Utils/services.js";

class MembershipRevenueDetail extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      salesLang: languageData.sales,
      insightLang: languageData.business_insights,
      showLoadingText: false,
      action_type: props.action_type ? props.action_type : "active",
      sortby: "subscription_started_at",
      sortorder: "desc",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.membershipRevenueDetail !== undefined &&
      nextProps.membershipRevenueDetail !== prevState.membershipRevenueDetail
    ) {
      returnState.membershipRevenueDetail = nextProps.membershipRevenueDetail;
      returnState.showLoader = false;
      returnState.isMembershipDetail = true;
    }
    return returnState;
  }

  membershipRevenueBack = () => {
    this.props.membershipRevenueBack();
  };

  handleExport = () => {
    this.props.fetchMembershipRevenueDetail(
      {
        export_name: "memberships",
        type: "members",
        export_type: "xls",
        detailMembershipTierId: this.props.detailMembershipTierId,
        detailMembershipName: this.props.detailMembershipName,
        detailClinicId: this.props.detailClinicId,
        detailClinicName: this.props.detailClinicName,
        action_type: this.state.action_type,
      },
      1,
    );
  };

  fetchMembershipRevenueDetail = (action_type) => {
    this.setState({ action_type: action_type });
    let returnState = {
      action_type: action_type,
      detailMembershipTierId: this.props.detailMembershipTierId,
      detailMembershipName: this.props.detailMembershipName,
      detailClinicId: this.props.detailClinicId,
      detailClinicName: this.props.detailClinicName,
      sort_by: this.state.sortby,
      sort_order: this.state.sortorder,
    };
    this.props.fetchMembershipRevenueDetail(returnState);
  };

  clientProfile = (clientId) => {
    if (clientId) {
      window.open(`/clients/profile/${clientId}`, "_blank");
    }
  };

  onSort = (sortBy) => {
    let sortOrder = this.state.sortorder === "asc" ? "desc" : "asc";
    this.setState(
      {
        sortby: sortBy,
        sortorder: sortOrder,
      },
      () => {
        this.fetchMembershipRevenueDetail(this.state.action_type);
      },
    );
  };

  render() {
    return (
      <div className="juvly-section setting-setion m-b-10 m-t-10">
        <div className="juvly-container memberShip-revenue p-b-20">
          <div className="juvly-title no-margin">
            {this.state.salesLang.sales_membership_revenue +
              " in " +
              this.props.detailClinicName +
              " for " +
              this.props.detailMembershipName +
              " during " +
              this.props.selectedDate}{" "}
            <a
              onClick={() => this.membershipRevenueBack()}
              className="pull-right crossIcon m-b-10"
            >
              <img alt="" src="/images/close.png" />
            </a>
          </div>
          <div className="export pull-right m-l-5 churnFilterExport">
            <div className="dropdown pull-left m-r-10">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenuSearch"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {this.state.action_type === "active"
                  ? this.state.globalLang.label_active
                  : this.state.salesLang.sales_churned}
                <i className="fas fa-angle-down"></i>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuSearch"
              >
                <li>
                  <a
                    name="expType"
                    onClick={this.fetchMembershipRevenueDetail.bind(
                      this,
                      "active",
                    )}
                  >
                    {this.state.globalLang.label_active}
                  </a>
                </li>
                <li>
                  <a
                    name="expoType"
                    onClick={this.fetchMembershipRevenueDetail.bind(
                      this,
                      "churned",
                    )}
                  >
                    {this.state.salesLang.sales_churned}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {this.props.membershipRevenueDetail &&
            this.props.membershipRevenueDetail.length > 0 && (
              <div className="mainRightActions">
                <a
                  className="easy-link relative"
                  onClick={() => this.handleExport()}
                >
                  <i className="fa fa-download"></i> Download Excel
                </a>
              </div>
            )}
        </div>

        <div className="table-responsive">
          <table className="table-updated juvly-table table-min-width">
            <thead className="table-updated-thead">
              <tr>
                <th className="col-xs-3 col-md-2 table-updated-th">
                  {this.state.salesLang.sales_first_name}
                </th>
                <th className="col-xs-3 col-md-2 table-updated-th">
                  {this.state.salesLang.sales_last_name}
                </th>
                <th className="col-xs-3 col-md-2 table-updated-th">
                  {this.state.globalLang.label_email}
                </th>
                <th
                  className="col-xs-3 col-md-1 table-updated-th sorting sortData"
                  onClick={() => this.onSort("added_by")}
                >
                  Provider
                  <i
                    className={
                      this.state.sortorder === "asc" &&
                      this.state.sortby === "added_by"
                        ? "blue-gray"
                        : this.state.sortorder === "desc" &&
                          this.state.sortby === "added_by"
                        ? "gray-blue"
                        : "gray-gray"
                    }
                  />
                </th>
                <th
                  className="col-xs-3 col-md-1 table-updated-th sorting sortData text-nowrap p-r-30"
                  onClick={() => this.onSort("subscription_started_at")}
                >
                  {this.state.action_type === "active"
                    ? this.state.salesLang.sales_subscribed_on
                    : this.state.salesLang.sales_churned_on}
                  <i
                    className={
                      this.state.sortorder === "asc" &&
                      this.state.sortby === "subscription_started_at"
                        ? "blue-gray"
                        : this.state.sortorder === "desc" &&
                          this.state.sortby === "subscription_started_at"
                        ? "gray-blue"
                        : "gray-gray"
                    }
                  />
                </th>
                <th className="col-xs-3 col-md-1 table-updated-th">
                  {this.state.insightLang.bi_monthly_amount}
                </th>
                <th className="col-xs-3 col-md-1 table-updated-th">
                  Setup Fee
                </th>
                <th
                  className="col-xs-3 col-md-1 table-updated-th sorting sortData"
                  onClick={() => this.onSort("payment_datetime")}
                >
                  {this.state.insightLang.bi_paid_on}
                  <i
                    className={
                      this.state.sortorder === "asc" &&
                      this.state.sortby === "payment_datetime"
                        ? "blue-gray"
                        : this.state.sortorder === "desc" &&
                          this.state.sortby === "payment_datetime"
                        ? "gray-blue"
                        : "gray-gray"
                    }
                  />
                </th>
                <th
                  className="col-xs-2 col-md-1 table-updated-th sorting sortData"
                  onClick={() => this.onSort("payment_status")}
                >
                  Status
                  <i
                    className={
                      this.state.sortorder === "asc" &&
                      this.state.sortby === "payment_status"
                        ? "blue-gray"
                        : this.state.sortorder === "desc" &&
                          this.state.sortby === "payment_status"
                        ? "gray-blue"
                        : "gray-gray"
                    }
                  />
                </th>
              </tr>
            </thead>
            <tbody className="table-updated-tbody">
              {this.props.membershipRevenueDetail &&
              this.props.membershipRevenueDetail.length > 0 ? (
                this.props.membershipRevenueDetail.map((obj, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="table-updated-tr"
                      onClick={this.clientProfile.bind(
                        this,
                        obj.patient && obj.patient.id ? obj.patient.id : "",
                      )}
                    >
                      <td className="table-updated-td break-all">
                        {obj && obj.firstname ? obj.firstname : ""}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj && obj.lastname ? obj.lastname : ""}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj && obj.email ? obj.email : ""}
                      </td>
                      <td className="table-updated-td break-all">
                        {obj && obj.provider ? obj.provider : ""}
                      </td>

                      <td className="table-updated-td break-all text-nowrap">
                        {showFormattedDate(
                          this.state.action_type === "active"
                            ? obj.subscription_started_at
                            : obj.subscription_expired_on,
                        )}
                      </td>
                      <td className="table-updated-td">
                        {obj.monthly_fees > 0
                          ? numberFormat(
                              obj.invoice_amount - obj.setup_fees,
                              "currency",
                              2,
                            )
                          : this.state.insightLang.bi_free}
                      </td>
                      <td className="table-updated-td break-all">
                        {" "}
                        {numberFormat(obj.setup_fees, "currency", 2)}
                      </td>
                      <td className="table-updated-td">
                        {showFormattedDate(
                          obj && obj.payment_datetime
                            ? obj.payment_datetime
                            : "",
                        )}
                      </td>
                      <td className="table-updated-td break-all text-capitalize">
                        {obj && obj.payment_status ? obj.payment_status : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float text-center"
                    colSpan={10}
                  >
                    {this.state.salesLang.sales_no_record_found}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MembershipRevenueDetail;
