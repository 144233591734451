import React from "react";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import { Select } from "../../../../../../../../shared/Select/Select";
import { CardReaderDesc } from "../../../../../shared/CardReaderDesc/CardReaderDesc";
import { useCardReaders } from "../../../../../hooks/useCardReaders";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";

const PaymentFormModal = ({
  form,
  hasError,
  changeFormValue,
  errors,
  isValid,
  showTipsModal,
}) => {
  const { cardReaders, isCardReadersLoading, setDefaultCardReader } =
    useCardReaders();
  const { paymentViewClose } = useInvoiceActions();

  const handleSubmit = () => {
    showTipsModal.update(true);
  };

  const readerOptions = cardReaders.map((r) => ({
    label: `${r.serialNumber} (${r.clinic.name})`,
    value: r.id,
  }));

  const onSelectReader = (option) => {
    const cardReader = cardReaders.find((r) => r.id);
    changeFormValue("readerId", option.value);
    setDefaultCardReader({
      clinicId: cardReader?.clinic?.id,
      cardReaderId: option.value,
    });
  };

  return (
    <>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.cardReader")}
          </InputLabel>
          <Select
            size="medium"
            isError={hasError("readerId")}
            isLoading={isCardReadersLoading}
            value={readerOptions.find((o) => o.value === form.readerId)}
            onChange={onSelectReader}
            options={readerOptions}
          />
          {hasError("readerId") && <InputError>{errors.readerId}</InputError>}
        </ModalCharge.InputWrap>
        {form.readerId && (
          <ModalCharge.InputWrap>
            <CardReaderDesc
              serialNumber={
                cardReaders?.find((r) => r.id === form.readerId)?.serialNumber
              }
              clinicName={
                cardReaders.find((r) => r.id === form.readerId)?.clinic?.name
              }
            />
          </ModalCharge.InputWrap>
        )}
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        onCharge={handleSubmit}
        chargeLabel={tCommon("label.continue")}
        isChargeDisabled={!isValid}
      />
    </>
  );
};

export default PaymentFormModal;
