import React from "react";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useClientWalletLogExportMutation } from "../../../../api/mutations/useClientWalletLogExportMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { composeDownloadDataUrl } from "../../../../utilities/api";
import { svg } from "../../../../assets/svg";

export function DownloadWalletExcel({ clientId }) {
  const { tCommon } = useAppTranslation.Common();

  const exportMutation = useClientWalletLogExportMutation({
    onError: () => {
      uiNotification.error(tCommon("error.export"));
    },
    onSuccess: (res) => {
      window.open(composeDownloadDataUrl(res.fileName), "_blank");
    },
  });

  return (
    <button
      className="easy-link no-padding text-no-wrap d-flex align-center gap-8"
      disabled={exportMutation.isLoading}
      onClick={() => exportMutation.mutate({ clientId })}
    >
      {exportMutation.isLoading ? (
        <CircularProgress size="tiny" />
      ) : (
        <img src={svg.downloadPrimary} alt="dl" width="18px" />
      )}
      {tCommon("label.downloadExcel")}
    </button>
  );
}
