import uuid from "uuid";
import { useState } from "react";

export function useRandomKey() {
  const [key, setKey] = useState(uuid.v4());

  const refreshKey = () => {
    setKey(uuid.v4());
  };

  return [key, refreshKey];
}
