import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";

const dtoSchema = yup.object({
  id: yup.number().required(),
});

export function usePPAnnouncementBannerDeleteMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      const dto = dtoSchema.validateSync(payload, {
        strict: true,
      });
      return http.delete(
        HTTP_ENDPOINTS.patientPortal.announcementBanner.delete(dto.id),
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientPortal.announcementBanner.getOne],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientPortal.announcementBanner.getAll],
        });

        options?.onSuccess?.();
      },
    },
  );
}
