import React, { useState } from "react";
import cx from "clsx";
import { Button } from "../../../shared/Button/Button";
import classes from "./sass/TimeCardTitle.module.scss";
import { ManualTimeModal } from "./ManualTimeModal/ManualTimeModal";
import { PERMISSIONS } from "../../../consts/api";
import { usePermission } from "../../../hooks/usePermission";

export default function TimeCardTitle({
  reportTitle,
  isTimeCardPracticeDetail,
  backTimeCardReport,
  selectedUser,
  refetchUserLogs,
}) {
  const [isManualTimeModalOpen, setIsManualTimeModalOpen] = useState(false);
  const logPermission = usePermission(
    PERMISSIONS.businessInsights.manualTimeLog,
  );

  const isLogPermitted = logPermission.loaded && logPermission.permitted;

  const isTimeLogAvailable = isLogPermitted && Boolean(selectedUser.id);

  return (
    <div className="setting-setion m-b-10">
      <div className="membership-title">
        <span>{reportTitle}</span>
        {isTimeCardPracticeDetail && (
          <div className={cx("memberRightActions", classes.actionButtons)}>
            {isTimeLogAvailable && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => setIsManualTimeModalOpen(true)}
              >
                Add Time
              </Button>
            )}
            <Button size="small" onClick={backTimeCardReport}>
              Back
            </Button>
          </div>
        )}
      </div>
      {isTimeLogAvailable && isManualTimeModalOpen && (
        <ManualTimeModal
          isOpen={isManualTimeModalOpen}
          onClose={() => setIsManualTimeModalOpen(false)}
          refetchLogs={refetchUserLogs}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
}
