import React from "react";
import PropTypes from "prop-types";
import { Input } from "../../../../../shared/Input/Input";
import classes from "../../ManageClinics.module.scss";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { TextEditor } from "../../../../../shared/TextEditor/TextEditor";

export function MultiPostFromFilesContent({
  currentFile,
  onEditFile,
  isFilePending,
  isPosting,
}) {
  const onChangeTitle = (e) => {
    onEditFile(currentFile.id, {
      title: e.target.value,
    });
  };

  const onEditorChange = (content) => {
    onEditFile(currentFile.id, {
      parsedContent: content,
    });
  };

  return (
    <div className={classes.multiPostFromFilesEditorContent}>
      {isFilePending && (
        <>
          <Skeleton height={48} />
          <Skeleton fullHeight />
        </>
      )}
      {!isFilePending && currentFile && (
        <>
          <Input
            value={currentFile.title ? currentFile.title : ""}
            onChange={onChangeTitle}
            placeholder="Agreement Title"
            isDisabled={isPosting}
          />
          <TextEditor
            onChange={onEditorChange}
            isDisabled={isPosting}
            value={currentFile.parsedContent || ""}
          />
        </>
      )}
    </div>
  );
}

MultiPostFromFilesContent.propTypes = {
  currentFile: PropTypes.object,
  onEditFile: PropTypes.func.isRequired,
  isFilePending: PropTypes.bool.isRequired,
  isPosting: PropTypes.bool.isRequired,
};

MultiPostFromFilesContent.defaultProps = {
  currentFile: null,
};
