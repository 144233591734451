import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  id: yup.number().nullable(),
  patient_id: yup.number().required(),
  vital_date: yup.string().required(),
  height: yup.string().nullable(),
  weight: yup.number().nullable(),
  bmi: yup.number().nullable(),
  body_fat: yup.number().nullable(),
  temperature: yup.number().nullable(),
  blood_pressure_systolic: yup.number().nullable(),
  blood_pressure_diastolic: yup.number().nullable(),
  pulse: yup.number().nullable(),
  respiration: yup.number().nullable(),
  notes: yup.array().of(yup.string()).nullable(),
});

export const usePatientVitalsMutation = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.patientVitals.mutate(),
        removeNullishFromShape({
          ...req,
          notes: req.notes?.length ? req.notes : null,
        }),
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientVitals.all],
        });

        options?.onSuccess?.(...args);
      },
    },
  );
};
