import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import StripeCheckoutForm from "./StripeCheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripePaymentForm = (props) => {
  const {
    invoiceId,
    stripeIntent,
    type,
    onSubmit,
    validation,
    onCancel,
    onSubmitText,
    onCancelText,
    saveCard,
  } = props;

  const options = {
    clientSecret: stripeIntent && stripeIntent.clientSecret,
  };

  return options.clientSecret ? (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckoutForm
        invoiceId={invoiceId}
        type={type}
        onSubmit={onSubmit}
        validation={validation}
        onCancel={onCancel}
        onSubmitText={onSubmitText}
        onCancelText={onCancelText}
        saveCard={saveCard}
        paymentIntentId={stripeIntent.paymentIntentId}
      />
    </Elements>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  stripeIntent: state.StripeReducer.stripeIntent,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StripePaymentForm);
