import { connect } from "react-redux";
import React from "react";
import { patientVitals } from "../../../../../store/patients/vitals";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import classes from "./Errors.module.scss";

const { selectors } = patientVitals;

const mapStateToProps = (state) => ({
  errors: selectors.selectMutationRecordFieldErrors(state) || {},
});

export const Errors = connect(mapStateToProps)(({ errors }) => {
  const messages = Object.values(errors);

  if (messages.length > 0) {
    return (
      <div className={classes.root}>
        <AlertBox className={classes.errors} size="small" variant="error">
          {messages.map((m, i) => (
            <div key={i}>{m}</div>
          ))}
        </AlertBox>
      </div>
    );
  }

  return null;
});
