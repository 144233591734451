import { ACTION_TYPES } from "./consts";

export const actions = {
  eventTypeChange: ({ eventType }) => ({
    type: ACTION_TYPES.eventTypeChange,
    payload: {
      eventType,
    },
  }),
  isSubmitActiveChange: (status) => ({
    type: ACTION_TYPES.isSubmitActiveChange,
    payload: {
      status,
    },
  }),
};
