import React from "react";

const CommonCardList = ({ savedPaymentMethodView, cardsList, linkList }) => {
  return savedPaymentMethodView === "card"
    ? cardsList?.map((obj) => {
        return (
          <option value={obj.id} key={obj.id}>
            {`${obj.card.brand} ending with ${obj.card.last4}`}
          </option>
        );
      })
    : linkList?.map((obj) => {
        return (
          <option value={obj.id} key={obj.id}>
            {obj.card.email}
          </option>
        );
      });
};

export default CommonCardList;
