import React, { useMemo } from "react";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { PROVIDER_AND_MD_TAB_TYPE } from "../../../../api/queries/useChartAuditQuery";

export const useProviderAndMd = ({
  providerAndMdData,
  selectedType,
  onButtonClick,
}) => {
  const { tBi } = useAppTranslation.BusinessInsights();

  const mdCols = [
    {
      data: tBi("chartAuditReport.table.MdCol.md_name"),
      accessor: "name",
    },
    {
      data: tBi("chartAuditReport.table.MdCol.chart_md"),
      accessor: "chartAwaitMd",
    },
    {
      data: tBi("chartAuditReport.table.MdCol.chart_sign"),
      accessor: "chartSignedReturned",
    },

    {
      data: "",
      accessor: "actions",
    },
  ];

  const providerCols = [
    {
      data: tBi("chartAuditReport.table.ProviderCol.provider_name"),
      accessor: "name",
    },
    {
      data: tBi("chartAuditReport.table.ProviderCol.chart_provider"),
      accessor: "chartAwaitProvider",
    },
    {
      data: tBi("chartAuditReport.table.ProviderCol.chart_provider_signed"),
      accessor: "chartAwaitProviderSigned",
    },
    {
      data: tBi("chartAuditReport.table.ProviderCol.chart_md"),
      accessor: "chartAwaitMd",
    },
    {
      data: tBi("chartAuditReport.table.ProviderCol.chart_sign"),
      accessor: "chartSignedReturned",
    },

    {
      data: "",
      accessor: "actions",
    },
  ];
  const isMdChart = selectedType === PROVIDER_AND_MD_TAB_TYPE.md;
  const cols = isMdChart ? mdCols : providerCols;

  const data = useMemo(() => {
    if (!providerAndMdData) {
      return [];
    }
    return providerAndMdData.map((item) => {
      const userId = isMdChart ? item.md_user_id : item.user?.id;
      return {
        key: userId,
        name: isMdChart
          ? item.md_user?.full_name || "-"
          : item.user?.full_name || "-",
        chartAwaitProvider: item.pending,
        chartAwaitProviderSigned: item.signed,
        chartAwaitMd: item.sent_to_md,
        chartSignedReturned: item.signed_by_md,
        actions: (
          <Button
            size="small"
            isDisabled={false}
            onClick={() => onButtonClick(userId)}
          >
            {tBi("chartAuditReport.button_label")}
          </Button>
        ),
      };
    });
  }, [providerAndMdData, selectedType]);

  return { cols, data };
};
