import React, { useState } from "react";
import { connect } from "react-redux";
import commonClasses from "../styles.module.scss";
import { Checkbox } from "../shared/Checkbox/Checkbox";
import { SaveButton } from "../shared/SaveButton/SaveButton";
import { Input } from "../shared/Input/Input";
import { ClinicRow } from "../shared/ClinicRow/ClinicRow";
import { RouterPrompt } from "../../../../../../widgets/RouterPrompt/RouterPrompt";
import { UNSAVED_CHANGES_TEXT } from "../config";
import { productPricingRegular } from "../../../../../../store/inventory/productPricingRegular";
import { dispatch } from "../../../../../../store/store";
import { useUpdate } from "../hooks/useUpdate";
import { useInit } from "./hooks/useInit";
import {
  isPricesChanged,
  validateChangedPricesForRequest,
} from "../../../../../../store/inventory/productPricingRegular/utilities";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { PageOverlayLoader } from "../../../../../../shared/PageOverlayLoader/PageOverlayLoader";

const { selectors, actions } = productPricingRegular;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  clinics: selectors.selectClinics(state),
  getPerUnitPrice: selectors.selectPerUnitPrice(state),
  getOriginalPrices: selectors.selectOriginalPrices(state),
  getChangedPrices: selectors.selectChangedPrices(state),
  getChangedPricesForRequest: selectors.selectChangedPricesForRequest(state),
  isInitialized: selectors.selectInitialized(state),
});

export const PricingRegular = connect(mapStateToProps)(
  ({
    productId,
    initData,
    refetch,
    forAllClinics,
    clinics,
    getPerUnitPrice,
    getOriginalPrices,
    getChangedPrices,
    getChangedPricesForRequest,
    isInitialized,
    errors,
  }) => {
    useInit(initData, isInitialized);

    var [errors_, setErrors_] = useState(null);

    var updatePriceList = useUpdate(refetch);

    var onSave = () => {
      setErrors_(null);
      const requestChangedPrices = getChangedPricesForRequest();

      const errors = validateChangedPricesForRequest({
        requestChangedPrices,
        forAllClinics,
      });

      if (!errors.perUnitError && errors.perClinicErrors.length === 0) {
        updatePriceList.initiate({
          productId: Number(productId),
          priceList: requestChangedPrices,
          isRegularPrice: true,
          isForAllClinics: forAllClinics,
        });
      } else {
        setErrors_(errors);
        uiNotification.error(
          "Please fill all the required fields in Regular Pricing tab",
        );
      }
    };

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className={commonClasses.wrap}>
            <h4>Product Price</h4>
            <div className={commonClasses.right}>
              <div className="flex-col gap-8">
                <Checkbox
                  label="Same for All Clinics"
                  checked={forAllClinics}
                  onChange={() => {
                    dispatch(actions.setForAllClinics(!forAllClinics));
                  }}
                />
              </div>
              {Boolean(productId) && (
                <SaveButton
                  noMargin
                  isLoading={updatePriceList.isLoading}
                  onClick={onSave}
                />
              )}
            </div>
          </div>
          {forAllClinics ? (
            <div className="row">
              <div className="col-sm-4 col-xs-12">
                <Input
                  required
                  label="Product Price Per Unit"
                  placeholder="Product Price Per Unit"
                  value={getPerUnitPrice()}
                  isError={!!errors?.perUnitError || !!errors_?.perUnitError}
                  onChange={(e) => {
                    dispatch(
                      actions.changePrice({
                        value: e.target.value,
                      }),
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            clinics.map((clinic) => (
              <ClinicRow label={clinic.name} key={clinic.id}>
                <div className="col-sm-4 col-xs-12">
                  <Input
                    required
                    label="Product Price Per Unit"
                    placeholder="Product Price Per Unit"
                    value={getPerUnitPrice(clinic.id)}
                    isError={
                      !!errors?.perClinicErrors?.includes?.(clinic.id) ||
                      !!errors_?.perClinicErrors?.includes?.(clinic.id)
                    }
                    onChange={(e) => {
                      dispatch(
                        actions.changePrice({
                          value: e.target.value,
                          clinicId: clinic.id,
                        }),
                      );
                    }}
                  />
                </div>
              </ClinicRow>
            ))
          )}
        </div>
        <RouterPrompt
          nativePrompt
          whenFn={() =>
            isPricesChanged({
              preparedPrices: getChangedPrices(),
              initialPreparedPrices: getOriginalPrices(),
              forAllClinics,
            })
          }
          title="Warning"
          text={UNSAVED_CHANGES_TEXT}
        />
        {updatePriceList.isLoading && (
          <PageOverlayLoader message="Saving changes. Please wait" />
        )}
      </div>
    );
  },
);
