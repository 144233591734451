/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import cx from "clsx";
import styles from "./styles.module.scss";

export const CALENDAR_STEP_OPTIONS = [
  {
    label: "1 hour",
    value: 20,
  },
  {
    label: "30 min",
    value: 10,
  },
  {
    label: "15 min",
    value: 5,
  },
];

/**
 * @param {object} param0
 *
 * @param {{
 *    label: string;
 *    value: number;
 * }} param0.value
 *
 * @param {(option: {
 *    label: string;
 *    value: number;
 * }) => void} param0.onChange
 */
export const CalendarStepSelect = ({ value, onChange }) => {
  return (
    <div className={cx("calClinicdropDown", styles.root)}>
      <div className="dropdown">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          id="calendarStep"
        >
          {value.label}
          <i className="fas fa-angle-down"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="calendarStep">
          {CALENDAR_STEP_OPTIONS.map((o) => (
            <li key={o.value}>
              <a onClick={() => onChange(o)}>{o.label}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
