import React from "react";
import PropTypes from "prop-types";
import classes from "../sass/WorkspacesBox.module.scss";

export function WorkspacesBox({ header, workspaces }) {
  return (
    <div>
      {header && <div className={classes.header}>{header}</div>}
      <div className={classes.box}>
        {workspaces.map((w, idx) => (
          <div key={w.key} className={classes.node}>
            {w.node}
            {idx !== workspaces.length - 1 ? <hr /> : null}
          </div>
        ))}
      </div>
    </div>
  );
}

WorkspacesBox.propTypes = {
  header: PropTypes.node.isRequired,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      node: PropTypes.node.isRequired,
    }),
  ).isRequired,
};
