/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchProviderSchedule,
  dynamicOrderingProviderSchedule,
  updateProviderScheduleSortOrder,
  exportEmptyData,
} from "../../../Actions/Appointment/appointmentAction.js";

import Schedule from "./Schedule";
import ServicesProvided from "./ServicesProvided";

class ProviderSchedule extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      mode: "schedule",
      globalLang: languageData.global,
      appointmentLang: languageData.appointments,
      providerId: null,
      providerName: "",
    };
  }

  servicesProvidedView = (id, name) => {
    this.setState({
      mode: "provider",
      providerId: id,
      providerName: name,
    });
  };

  cancelServicesProvidedView = () => {
    this.setState({
      mode: "schedule",
    });
  };

  render() {
    return (
      <div className="setting-setion full-width">
        <div className="">
          <ul className={"appointment-tabs m-b-0 table-tabs"}>
            <li>
              <a
                className={"schedule"}
                style={{ height: "60px", paddingLeft: "0" }}
                onClick={() => {
                  this.setState({ mode: "schedule" });
                }}
              >
                {this.state.mode == "schedule"
                  ? "Schedule & Services Provided "
                  : ""}
              </a>
            </li>
            <li className={"hide"}>
              <a
                className={"provider"}
                onClick={() => {
                  this.setState({ mode: "provider" });
                }}
              >
                Services Provided
              </a>
            </li>
          </ul>
          {this.state.mode === "schedule" && (
            <Schedule
              scheduleView={this.props.scheduleView}
              servicesProvidedView={this.servicesProvidedView}
            />
          )}
          {this.state.mode === "provider" && (
            <ServicesProvided
              providerId={this.state.providerId}
              providerName={this.state.providerName}
              cancelServicesProvidedView={this.cancelServicesProvidedView}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  let returnState = {};
  localStorage.setItem("showLoader", "false");
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProviderSchedule: fetchProviderSchedule,
      dynamicOrderingProviderSchedule: dynamicOrderingProviderSchedule,
      updateProviderScheduleSortOrder: updateProviderScheduleSortOrder,
      exportEmptyData: exportEmptyData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSchedule);
