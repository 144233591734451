import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import {
  packedListOr,
  removeNullishFromShape,
  unwrapOr,
} from "../../utilities/general";
import { createQueryKeyFromShape } from "../../utilities/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  filter: yup.object({
    clinicIds: yup.array().of(yup.number()).nullable(),
    providerIds: yup.array().of(yup.number()).nullable(),
  }),

  limit: yup.number().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    data: yup
      .array()
      .of(
        yup
          .object({
            id: yup.number().required(),
            user_id: yup.number().required(),
            appointment_datetime: yup.string().required(),
            patient: yup
              .object({
                id: yup.number().required(),
                full_name: yup.string().required(),
              })
              .required(),
            provider: yup
              .object({
                id: yup.number().required(),
                full_name: yup.string().required(),
              })
              .nullable(),
            clinic: yup.object({
              id: yup.number().required(),
              clinic_name: yup.string().required(),
            }),
            services: yup
              .array()
              .of(
                yup.object({
                  id: yup.number().required(),
                  name: yup.string().required(),
                }),
              )
              .nullable(),
          })
          .required(),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    data,
  };
};

// ---------

const createAppointmentsNoShowsQueryKey = (...keys) => [
  QUERY_KEYS.appointments.noShows,
  ...keys,
];

const createKeyFromShape = (shape) =>
  createQueryKeyFromShape(removeNullishFromShape(shape));

export const useAppointmentsNoShowsQuery = ({
  payload = {},
  options = {},
} = {}) => {
  const fromDate = unwrapOr(
    () => moment(payload.period.from).format(API_DATE_FORMAT),
    null,
  );

  const toDate = unwrapOr(
    () => moment(payload.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createAppointmentsNoShowsQueryKey(
      createKeyFromShape(payload.filter),
      fromDate,
      toDate,
      payload.limit,
      payload.pageSize,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const req = requestSchema.validateSync(payload, { strict: true });

      const res = await http.get(HTTP_ENDPOINTS.appointments.getNoShows(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          start_date: fromDate,
          end_date: toDate,
          clinics: packedListOr(req.filter.clinicIds, null),
          providers: packedListOr(req.filter.providerIds, null),
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
};
