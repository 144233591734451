import React from "react";
import { Trans } from "react-i18next";
import moment from "moment";
import { AlertBox } from "../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import classes from "./AlreadyRequestedBox.module.scss";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";

export function AlreadyRequestedBox({ exams }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  return (
    <AlertBox>
      <div>
        <Trans
          i18nKey={tCommon(
            "createQualifyProcedure.warn.alreadyRequestedExams.label",
          )}
          components={{
            b: <b />,
          }}
        />
        <div className={classes.alreadyRequestedExams}>
          {exams.map((exam) => (
            <div key={exam.id}>
              • <b>{exam.name}</b> -{" "}
              {tCommon("label.requestedDate", {
                date: moment(exam.date).format(user.previewDateFormat),
              })}
            </div>
          ))}
        </div>
        {tCommon("createQualifyProcedure.warn.alreadyRequestedExams.text")}
      </div>
    </AlertBox>
  );
}
