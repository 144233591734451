import React from "react";
import cx from "clsx";
import styles from "./ClientReferral.module.scss";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { getPathParams } from "../../utils/utils";

export function ClientReferral() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });

  return (
    <div className={cx(styles.clientReferralWrapper, "col-xs-12")}>
      <div>
        <div className={styles.title}>Referral Source</div>
        <div className={styles.itemText}>
          {clientData?.referralSource || ""}
        </div>
      </div>
      <div>
        <div className={styles.title}>Referral source sub-category</div>
        <div className={styles.itemText}>
          {clientData?.referralSourceSubcategory || ""}
        </div>
      </div>

      {clientData?.referralPerson && (
        <div>
          <div className={styles.title}>Referral Person</div>
          <div className={styles.itemText}>{clientData?.referralPerson}</div>
        </div>
      )}
    </div>
  );
}
