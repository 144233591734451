import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useExportProcedureData = (
  { clientId, procedureId },
  options = {},
) => {
  return useQuery(
    ["exportProcedureData"],
    () => {
      return http.get(
        HTTP_ENDPOINTS.exportProcedureData(clientId, procedureId),
      );
    },
    { ...options, enabled: false },
  );
};
