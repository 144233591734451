import { matchPath } from "react-router";
import { CLIENTS_ROUTES } from "../../../consts/routes";

export const getPathParams = () => {
  const { params } = matchPath(window.location.pathname, {
    path: CLIENTS_ROUTES.vitals(":id"),
    exact: true,
    strict: false,
  });

  return {
    patientId: params.id ? Number(params.id) : undefined,
  };
};
