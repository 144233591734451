import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { Box } from "../../../../../../shared/Box/Box";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import parentClasses from "../../Qualiphy.module.scss";
import classes from "./EnrollClinic.module.scss";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../shared/Select/Select";
import { useQualiphyClinicsToEnrollQuery } from "../../../../../../api/qualiphy/useQualiphyClinicsToEnrollQuery";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { Button } from "../../../../../../shared/Button/Button";
import { Input } from "../../../../../../shared/Input/Input";
import { useQualiphyEnrollClinicMutation } from "../../../../../../api/qualiphy/useQualiphyEnrollClinicMutation";
import { passOr } from "../../../../../../utilities/general";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { QUERY_KEYS } from "../../../../../../consts/api";

export function EnrollClinic() {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const queryClient = useQueryClient();
  const enrollClinicMutation = useQualiphyEnrollClinicMutation();
  const [selectKey, setSelectKey] = useState(0);

  const clinicsToEnrolQuery = useQualiphyClinicsToEnrollQuery({
    onError: () => {
      uiNotification.error(tCommon("error.qualiphy.getClinicsToEnrol"));
    },
  });

  const schema = yup.object({
    clinicId: yup
      .number()
      .required(
        tSettings("qualiphyIntegration.enrolClinic.error.clinicRequired"),
      ),
    apiKey: yup
      .string()
      .required(
        tSettings("qualiphyIntegration.enrolClinic.error.apiKeyRequired"),
      ),
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      enrollClinicMutation
        .mutateAsync({
          clinicId: values.clinicId,
          apiKey: values.apiKey,
        })
        .then(() => {
          uiNotification.success(tCommon("success.qualiphy.enrollClinic"));
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.qualiphy.enrolledClinics],
          });
          clinicsToEnrolQuery.refetch();
          resetForm();
          setSelectKey((prev) => prev + 1);
        })
        .catch(() => {
          uiNotification.error(tCommon("error.qualiphy.enrollClinic"));
        });
    },
    initialValues: {
      clinicId: "",
      apiKey: "",
    },
  });

  const hasError = (field) => {
    return Boolean(touched[field] && errors[field]);
  };

  const setFormValue = (field, value) => {
    setFieldTouched(field);
    setFieldValue(field, value);
  };

  const clinicOptions =
    clinicsToEnrolQuery.data?.map((c) => ({
      label: c.name,
      value: c.id,
    })) || [];

  return (
    <Box className={parentClasses.box}>
      <div className={parentClasses.boxTitle}>
        {tSettings("qualiphyIntegration.enrolClinic.title")}
      </div>
      <div className={classes.row}>
        <div>
          <InputLabel>{tCommon("label.clinic")}</InputLabel>
          <Select
            key={selectKey}
            value={clinicOptions.find((c) => c.value === values.clinicId)}
            options={clinicOptions}
            onChange={(option) => setFormValue("clinicId", option.value)}
            isLoading={clinicsToEnrolQuery.isFetching}
            isError={hasError("clinicId")}
          />
          {hasError("clinicId") && <InputError>{errors.clinicId}</InputError>}
        </div>
        <div>
          <InputLabel>{tCommon("label.apiKey")}</InputLabel>
          <Input
            name="apiKey"
            value={values.apiKey}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={hasError("apiKey")}
          />
          {hasError("apiKey") && <InputError>{errors.apiKey}</InputError>}
        </div>
      </div>
      <Button
        isDisabled={!isValid || enrollClinicMutation.isLoading}
        onClick={handleSubmit}
        leftAdornment={passOr(enrollClinicMutation.isLoading, undefined, () => (
          <CircularProgress size="small" color="white" />
        ))}
      >
        {tCommon("label.enrol")}
      </Button>
    </Box>
  );
}
