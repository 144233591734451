import React from "react";
import cx from "clsx";
import classes from "./StaticReportsBox.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

export function StaticReportsBox({ reports, Wrapper, noLabel }) {
  const { tCommon } = useAppTranslation.Common();

  const isVisible = (report) => report.isVisible;

  const render = () => (
    <>
      {!noLabel && (
        <h2 className={classes.header}>{tCommon("label.staticReports")}</h2>
      )}
      <div
        className={cx(classes.reports, {
          [classes.reportsNoPadding]: noLabel,
        })}
      >
        {reports.filter(isVisible).map((report) => (
          <button
            key={report.key}
            onClick={report.onClick}
            className={classes.report}
          >
            {report.label}
            <div className={classes.link}>
              <i className="fa fa-link" aria-hidden="true"></i>
            </div>
          </button>
        ))}
      </div>
    </>
  );

  return Wrapper ? (
    <Wrapper>{render()}</Wrapper>
  ) : (
    <div className={classes.root}>{render()}</div>
  );
}
