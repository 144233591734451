import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { http } from "../../../../../services/HttpService";

export const useEnableQuickBooksIntegration = (options = {}) => {
  return useMutation((dto) => {
    return http.post(
      HTTP_ENDPOINTS.quickBooks.enableQuickBooksIntegration(),
      dto,
    );
  }, options);
};
