import React from "react";
import PropTypes from "prop-types";

const PaperworkTabs = ({
  activeTab,
  handleTabChange
}) => {
  return (
    <div className="setting-setion m-b-10">
      <div className="membership-title">
        <div className="newTabsOuter">
          <a
            className={activeTab === "simple-questionnaires" ? "newTabs": "newTabs disabled-tab"}
            onClick={() => handleTabChange("simple-questionnaires")}
          >
            Simple Questionnaires
          </a>
          <a
            className={activeTab === "multi-questionnaires" ? "newTabs": "newTabs disabled-tab"}
            onClick={() => handleTabChange("multi-questionnaires")}
          >
            Multi Questionnaires
          </a>
          <a
            className={activeTab === "consents" ? "newTabs": "newTabs disabled-tab"}
            onClick={() => handleTabChange("consents")}
          >
            Consents
          </a>
          <a
            className={activeTab === "membership" ? "newTabs": "newTabs disabled-tab"}
            onClick={() => handleTabChange("membership")}
          >
            Membership Agreements
          </a>
        </div>
      </div>
    </div>
  )
}

PaperworkTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
}

export default PaperworkTabs;
