import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestPayload = yup.object({
  patientId: yup.number().required(),
});

const responseSchema = yup.array().of(
  yup.object({
    key: yup.string().required(),
    title: yup.string().required(),
    value: yup.string().nullable(),
  }),
);

const composeResponse = (res) => {
  let result = [];
  if (res && Array.isArray(res.data.data)) {
    result = res.data.data.map((i) => ({
      key: i.key,
      title: i.title,
      value: i.value || null,
    }));
  }
  return result;
};

function createShortMedicalHistoryQueryKey(patientId) {
  return [QUERY_KEYS.shortMedicalHistory, patientId];
}

export function useShortMedicalHistoryQuery({
  payload = {},
  options = {},
} = {}) {
  return useQuery(
    createShortMedicalHistoryQueryKey(payload.patientId),
    async () => {
      const req = requestPayload.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.getShortMedicalHistory(req.patientId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
