import React from "react";
import { arrayMoveImmutable } from "array-move";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./Questions.module.scss";
import { SortableContainer } from "../../shared/SortableContainer/SortableContainer";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { AddTileButton } from "../../shared/AddTileButton/AddTileButton";
import { QuestionTile } from "./shared/QuestionTile/QuestionTile";
import { DND_PRESS_DELAY_MS } from "../../config";

export function Questions({
  value,
  onSortEnd,
  onQuestionAdd,
  isLoading,
  children,
}) {
  const { tSettings } = useAppTranslation.Settings();

  return (
    <div className={classes.root}>
      {isLoading ? (
        Array.from(Array(4).keys()).map((i) => (
          <Skeleton key={i} count={1} height={58} />
        ))
      ) : (
        <>
          <AddTileButton onClick={onQuestionAdd}>
            {tSettings("questionnaires.addQuestion")}
          </AddTileButton>
          <SortableContainer
            pressDelay={DND_PRESS_DELAY_MS}
            onSortEnd={({ oldIndex, newIndex }) => {
              onSortEnd(arrayMoveImmutable(value, oldIndex, newIndex));
            }}
          >
            {value?.map(children)}
          </SortableContainer>
        </>
      )}
    </div>
  );
}

Questions.Tile = QuestionTile;
