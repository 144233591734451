import React from "react";
import PropTypes from "prop-types";
import { APPOINTMENT_TYPES } from "../../Event.consts";
import { Radio } from "../../../../shared/Radio/Radio";
import classes from "../../sass/Event.module.scss";
import { getPathParams } from "../../Event.utils";

const AppointmentType = ({
  value,
  isDisabled,
  onChange,
  isConvertingWaitlist,
}) => {
  const { leadId } = getPathParams();

  return (
    <>
      <div className={classes.mb10}>
        <Radio
          name="appointmentType"
          isChecked={value === APPOINTMENT_TYPES.inPerson}
          isDisabled={isDisabled}
          onChange={() => onChange(APPOINTMENT_TYPES.inPerson)}
          label="In Person"
        />
      </div>
      <div className={classes.mb10}>
        <Radio
          name="appointmentType"
          isChecked={value === APPOINTMENT_TYPES.virtual}
          isDisabled={isDisabled}
          onChange={() => onChange(APPOINTMENT_TYPES.virtual)}
          label="Virtual"
        />
      </div>
      {!(isConvertingWaitlist || isDisabled) && !leadId && (
        <div>
          <Radio
            name="appointmentType"
            isChecked={value === APPOINTMENT_TYPES.waitList}
            isDisabled={isDisabled}
            onChange={() => onChange(APPOINTMENT_TYPES.waitList)}
            label="WaitList"
          />
        </div>
      )}
    </>
  );
};

AppointmentType.propTypes = {
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

AppointmentType.defaultProps = {
  isDisabled: false,
};

export default AppointmentType;
