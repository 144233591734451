import React, { Component } from "react";

class Discounts extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.reportData !== undefined) {
      returnState.reportData = nextProps.reportData;
    }

    return returnState;
  }

  fetchDiscountClinicDetail = (discountClinicId, discountClinicName) => {
    if (discountClinicId !== undefined) {
      let returnState = {
        discountClinicId: discountClinicId,
        discountClinicName: discountClinicName,
      };
      this.props.fetchDiscountClinicDetail(returnState);
    }
  };

  render() {
    return (
      <>
        <div className="table-responsive clients-table">
          <table className="table-updated setting-table table-min-width no-td-border">
            <thead className="table-updated-thead">
              <tr>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.clinic_name}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.times_applied}
                </th>
                <th className="table-updated-th">
                  {this.state.reportData &&
                    this.state.reportData.report_header &&
                    this.state.reportData.report_header.amount}
                </th>
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.state.reportData &&
                this.state.reportData.report_data &&
                this.state.reportData.report_data.length > 0 &&
                this.state.reportData.report_data.map((obj, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="table-updated-tr"
                      onClick={this.fetchDiscountClinicDetail.bind(
                        this,
                        obj.clinic_id,
                        obj.clinic_name,
                      )}
                    >
                      <td className="table-updated-td">{obj.clinic_name}</td>
                      <td className="table-updated-td">{obj.times_applied}</td>
                      <td className="table-updated-td">{obj.amount}</td>
                    </tr>
                  );
                })}

              {this.state.reportData &&
                this.state.reportData.report_data &&
                this.state.reportData.report_data.length === 0 && (
                  <tr className="table-updated-tr">
                    <td
                      className="table-updated-td no-record no-float"
                      colSpan="10"
                    >
                      {this.state.languageData.bi_no_record_found}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Discounts;
