import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  isConsult: yup.bool().required(),
  mdId: yup.number().nullable(),
  procedureIds: yup.array().of(yup.number()).required(),
  signature: yup.string().required(),
  saveSignature: yup.bool(),
});

export function useProviderRoomSignProceduresMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.postProviderRoomSignProcedures(),
      removeNullishFromShape({
        is_consult: req.isConsult ? 1 : 0,
        md_user_id: req.mdId,
        procedure_ids: req.procedureIds,
        signature: req.signature,
        signature_saved: req.saveSignature ? 1 : 0,
      }),
    );
  }, options);
}
