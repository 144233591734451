/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import CreatableSelect from "react-select/lib/Creatable";
import { debounce as _debounce } from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import validator from "validator";
import {
  getClientDetail,
  updateNewMedicalHistory,
  getMedicineNames,
} from "../../Actions/Clients/clientsAction";
import { isPositiveNumber } from "../../Utils/services";
import "react-datepicker/dist/react-datepicker.css";
import { unwrapOr } from "../../utilities/general";
import { withClientRefetch } from "../../api/queries/useClientQuery";
import { AppTranslation } from "../../i18n/useAppTranslation";
import { uiNotification } from "../../services/UINotificationService";

const formDataErrorsDefault = {
  social_history: {
    tobacco_use_week: false,
    tobacco_use_year: false,
    alcohol_use_week: false,
    drug_use_week: false,
    drug_use_type: false,
    exercise_use_week: false,
    weight_info: false,
  },
  allergy: {
    allergy_drug: [],
    allergy_food: [],
    allergy_environment: [],
  },
  current_medication: {
    prescription_medication: [],
    vitamin: [],
    over_medication: [],
  },
  family_health_history: {
    medical_issues: [],
  },
  current_medical_history: {
    ongoing_condition: [],
    nutrition_history: false,
  },
  past_medical_history: {
    surgery: [],
    hospitalization: [],
    implant: [],
    vaccine: [],
  },
};

const formDataAllergyDrugDefault = {
  medication: "",
  reaction: "",
};

const formDataAllergyFoodDefault = {
  food: "",
  reaction: "",
};

const formDataAllergyEnvironmentDefault = {
  environment: "",
  reaction: "",
};

const formDataCurrentMedicationDefault = {
  medication: "",
  dose: "",
  frequency: "",
};

const formDataMedicalIssuesDefault = {
  diagnosis: "",
  relationship: "",
};

const formDataOngoingConditionDefault = {
  type: "",
  date: "",
  name: "",
};

const formDataSurgeryDefault = {
  date: "",
  physician: "",
  city: "",
  state: "",
  type: "",
};

const formDataHospitalizationDefault = {
  reason: "",
  date: "",
  hospital: "",
};

const formDataImplantDefault = {
  type: "",
  place: "",
  date: "",
};

const formDataVaccineDefault = {
  vaccine: "",
  date: "",
};

const isEmptyOpts = {
  ignore_whitespace: true,
};

const drugUsePeriod = {
  week: "week",
  month: "month",
};

class MedicalHistory extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      backURLType: this.props.match.params.actionType
        ? this.props.match.params.actionType
        : "clients",
      action: this.props.match.params.type
        ? this.props.match.params.type
        : "profile",
      showLoader: false,
      userData: userData,
      globalLang: languageData.global,
      settingsLang: languageData.settings,
      clientsLang: languageData.clients,
      clientID: this.props.match.params.clientID,
      clientData: [],
      dataChanged: false,
      updateStatus: null,
      clientScopes: "newMedicalHistory",
      deleteItemRowModal: {
        isOpen: false,
        section: false,
        name: false,
        index: false,
      },
      visibility: {
        tobacco_use: false,
        alcohol_use: false,
        drug_use: false,
        exercise_use: false,
        weight: false,
        drugs: false,
        foods: false,
        environment: false,
        prescription_medications: false,
        vitamins: false,
        over_medications: false,
        family_health_history: false,
        medical_issues: false,
        pregnancy: false,
        ongoing_conditions: false,
        nutrition: false,
        surgeries: false,
        hospitalizations: false,
        implants: false,
        vaccines: false,
      },
      formData: {
        weight: "",
        height: "",
        social_history: {
          tobacco_use: 0,
          tobacco_use_week: "",
          tobacco_use_year: "",
          alcohol_use: 0,
          alcohol_use_week: "",
          drug_use: 0,
          drug_use_week: "",
          drug_use_period: drugUsePeriod.week,
          drug_use_type: "",
          exercise_use: 0,
          exercise_use_week: "",
          weight: 0,
          weight_info: "",
        },
        allergy: {
          check: 0,
          drugs: 0,
          foods: 0,
          environment: 0,
          allergy_drug: [],
          allergy_food: [],
          allergy_environment: [],
        },
        current_medication: {
          check: 0,
          prescription_medications: 0,
          vitamins: 0,
          over_medications: 0,
          prescription_medication: [],
          vitamin: [],
          over_medication: [],
        },
        family_health_history: {
          check: 0,
          medical_issues: [],
        },
        current_medical_history: {
          check: 0,
          pregnancy: 0,
          breastfeeding: 0,
          ongoing_conditions: 0,
          ongoing_condition: [],
          nutrition: 0,
          nutrition_history: "",
        },
        past_medical_history: {
          check: 0,
          surgeries: 0,
          hospitalizations: 0,
          implants: 0,
          vaccines: 0,
          surgery: [],
          hospitalization: [],
          implant: [],
          vaccine: [],
        },
      },
      copyArr: {
        allergy_drug: [],
        allergy_food: [],
        allergy_environment: [],
        prescription_medication: [],
        vitamin: [],
        over_medication: [],
        medical_issues: [],
        ongoing_condition: [],
        surgery: [],
        hospitalization: [],
        implant: [],
        vaccine: [],
      },
      formDataDefault: {},
      medicationsSuggestionList: [],
      updatedAutocompleteField: "",
      updatedAutocompleteFieldIndex: -1,
      formDataErrors: JSON.parse(JSON.stringify(formDataErrorsDefault)),
    };
  }

  fetchClientDetails() {
    this.setState({
      showLoader: true,
    });
    this.props
      .getClientDetail(this.state.clientID, this.state.clientScopes)
      .catch((res) => {
        uiNotification.error(
          this.state.globalLang[res.message] ||
            "Unable to fetch client details",
        );
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  }

  componentDidMount() {
    this.fetchClientDetails();

    if (window.innerWidth <= 768) {
      const list = document.querySelectorAll(".history-section");

      for (let i = 0; i < list.length; ++i) {
        if (!list[i].classList.contains("history-section_collapse")) {
          list[i].classList.add("history-section_collapse");
        }
      }
    }
  }

  componentDidUpdate = (_, prevState) => {
    if (
      this.state.updateStatus !== null &&
      this.state.updateStatus !== "" &&
      this.state.updateStatus !== prevState.updateStatus
    ) {
      this.fetchClientDetails();
    }
  };

  static getDerivedStateFromProps(props, state) {
    const formatFloatValue = (value) => {
      return parseFloat(parseFloat(value).toFixed(2)).toString();
    };

    let returnState = {};

    if (
      props.clientData !== undefined &&
      props.clientData.status === 200 &&
      props.clientData.data !== state.clientData
    ) {
      if (props.clientData.data.new_medical_history) {
        const social_history =
          props.clientData.data.new_medical_history.social_history || {};
        const allergy = props.clientData.data.new_medical_history.allergy || {};
        const current_medication =
          props.clientData.data.new_medical_history.current_medication || {};
        const family_health_history =
          props.clientData.data.new_medical_history.family_health_history || {};
        const current_medical_history =
          props.clientData.data.new_medical_history.current_medical_history ||
          {};
        const past_medical_history =
          props.clientData.data.new_medical_history.past_medical_history || {};

        returnState.visibility = {
          tobacco_use: state.dataChanged
            ? state.visibility.tobacco_use
            : social_history.tobacco_use_week !== null &&
              social_history.tobacco_use_year !== null,
          alcohol_use: state.dataChanged
            ? state.visibility.alcohol_use
            : social_history.alcohol_use_week !== null,
          drug_use: state.dataChanged
            ? state.visibility.drug_use
            : social_history.drug_use_week !== null,
          exercise_use: state.dataChanged
            ? state.visibility.exercise_use
            : social_history.exercise_use_week !== null,
          weight: state.dataChanged
            ? state.visibility.weight
            : social_history.weight_info !== null,
          drugs: state.dataChanged
            ? state.visibility.drugs
            : !!allergy.allergy_drug && !!allergy.allergy_drug.length,
          foods: state.dataChanged
            ? state.visibility.foods
            : !!allergy.allergy_food && !!allergy.allergy_food.length,
          environment: state.dataChanged
            ? state.visibility.environment
            : !!allergy.allergy_environment &&
              !!allergy.allergy_environment.length,
          prescription_medications: state.dataChanged
            ? state.visibility.prescription_medications
            : !!current_medication.prescription_medication &&
              !!current_medication.prescription_medication.length,
          vitamins: state.dataChanged
            ? state.visibility.vitamins
            : !!current_medication.vitamin &&
              !!current_medication.vitamin.length,
          over_medications: state.dataChanged
            ? state.visibility.over_medications
            : !!current_medication.over_medication &&
              !!current_medication.over_medication.length,
          medical_issues: state.dataChanged
            ? state.visibility.medical_issues
            : !!family_health_history.medical_issues &&
              !!family_health_history.medical_issues.length,
          pregnancy: state.dataChanged
            ? state.visibility.pregnancy
            : current_medical_history.pregnancy !== null,
          ongoing_conditions: state.dataChanged
            ? state.visibility.ongoing_conditions
            : !!current_medical_history.ongoing_condition &&
              !!current_medical_history.ongoing_condition.length,
          nutrition: state.dataChanged
            ? state.visibility.nutrition
            : current_medical_history.nutrition_history !== null,
          surgeries: state.dataChanged
            ? state.visibility.surgeries
            : !!past_medical_history.surgery &&
              !!past_medical_history.surgery.length,
          hospitalizations: state.dataChanged
            ? state.visibility.hospitalizations
            : !!past_medical_history.hospitalization &&
              !!past_medical_history.hospitalization.length,
          implants: state.dataChanged
            ? state.visibility.implants
            : !!past_medical_history.implant &&
              !!past_medical_history.implant.length,
          vaccines: state.dataChanged
            ? state.visibility.vaccines
            : !!past_medical_history.vaccine &&
              !!past_medical_history.vaccine.length,
        };
        returnState.formData = {
          weight: unwrapOr(
            () => props.clientData.data.new_medical_history.weight,
            "",
          ),
          height: unwrapOr(
            () => props.clientData.data.new_medical_history.height,
            "",
          ),
          social_history: {
            tobacco_use: state.dataChanged
              ? state.formData.social_history.tobacco_use
              : social_history.tobacco_use || 0,
            tobacco_use_week: state.dataChanged
              ? formatFloatValue(state.formData.social_history.tobacco_use_week)
              : social_history.tobacco_use_week
              ? formatFloatValue(social_history.tobacco_use_week)
              : "",
            tobacco_use_year: state.dataChanged
              ? formatFloatValue(state.formData.social_history.tobacco_use_year)
              : social_history.tobacco_use_year
              ? formatFloatValue(social_history.tobacco_use_year)
              : "",
            alcohol_use: state.dataChanged
              ? state.formData.social_history.alcohol_use
              : social_history.alcohol_use || 0,
            alcohol_use_week: state.dataChanged
              ? formatFloatValue(state.formData.social_history.alcohol_use_week)
              : social_history.alcohol_use_week
              ? formatFloatValue(social_history.alcohol_use_week)
              : "",
            drug_use: state.dataChanged
              ? state.formData.social_history.drug_use
              : social_history.drug_use || 0,
            drug_use_week: state.dataChanged
              ? formatFloatValue(state.formData.social_history.drug_use_week)
              : social_history.drug_use_week
              ? formatFloatValue(social_history.drug_use_week)
              : "",
            drug_use_period: state.dataChanged
              ? state.formData.social_history.drug_use_period
              : social_history.drug_use_period || drugUsePeriod.week,
            drug_use_type: state.dataChanged
              ? state.formData.social_history.drug_use_type
              : social_history.drug_use_type || "",
            exercise_use: state.dataChanged
              ? state.formData.social_history.exercise_use
              : social_history.exercise_use || 0,
            exercise_use_week: state.dataChanged
              ? formatFloatValue(
                  state.formData.social_history.exercise_use_week,
                )
              : social_history.exercise_use_week
              ? formatFloatValue(social_history.exercise_use_week)
              : "",
            weight: state.dataChanged
              ? state.formData.social_history.weight
              : social_history.weight || 0,
            weight_info: state.dataChanged
              ? state.formData.social_history.weight_info
              : social_history.weight_info || "",
          },
          allergy: {
            check: state.dataChanged
              ? state.formData.allergy.check
              : allergy.check || 0,
            drugs: state.dataChanged
              ? state.formData.allergy.drugs
              : allergy.drugs || 0,
            foods: state.dataChanged
              ? state.formData.allergy.foods
              : allergy.foods || 0,
            environment: state.dataChanged
              ? state.formData.allergy.environment
              : allergy.environment || 0,
            allergy_drug: state.dataChanged
              ? state.formData.allergy.allergy_drug
              : (allergy.allergy_drug || []).map((d) => {
                  if (d.medication) {
                    const defaultItem = {
                      value: "defaultValue",
                      label: d.medication,
                    };
                    d.medication = defaultItem;
                    d.medicationsSuggestionList = [defaultItem];
                    return d;
                  } else {
                    return Object.assign({}, formDataAllergyDrugDefault);
                  }
                }),
            allergy_food: state.dataChanged
              ? state.formData.allergy.allergy_food
              : allergy.allergy_food || [],
            allergy_environment: state.dataChanged
              ? state.formData.allergy.allergy_environment
              : allergy.allergy_environment || [],
          },
          current_medication: {
            check: state.dataChanged
              ? state.formData.current_medication.check
              : current_medication.check || 0,
            prescription_medications: state.dataChanged
              ? state.formData.current_medication.prescription_medications
              : current_medication.prescription_medications || 0,
            vitamins: state.dataChanged
              ? state.formData.current_medication.vitamins
              : current_medication.vitamins || 0,
            over_medications: state.dataChanged
              ? state.formData.current_medication.over_medications
              : current_medication.over_medications || 0,
            prescription_medication: state.dataChanged
              ? state.formData.current_medication.prescription_medication
              : (current_medication.prescription_medication || []).map((el) => {
                  if (el.medication) {
                    const defaultItem = {
                      value: "defaultValue",
                      label: el.medication,
                    };
                    el.medication = defaultItem;
                    el.medicationsSuggestionList = [defaultItem];
                    return el;
                  } else {
                    return Object.assign({}, formDataCurrentMedicationDefault);
                  }
                }),
            vitamin: state.dataChanged
              ? state.formData.current_medication.vitamin
              : (current_medication.vitamin || []).map((el) => {
                  if (el.medication) {
                    const defaultItem = {
                      value: "defaultValue",
                      label: el.medication,
                    };
                    el.medication = defaultItem;
                    el.medicationsSuggestionList = [defaultItem];
                    return el;
                  } else {
                    return Object.assign({}, formDataCurrentMedicationDefault);
                  }
                }),
            over_medication: state.dataChanged
              ? state.formData.current_medication.over_medication
              : (current_medication.over_medication || []).map((el) => {
                  if (el.medication) {
                    const defaultItem = {
                      value: "defaultValue",
                      label: el.medication,
                    };
                    el.medication = defaultItem;
                    el.medicationsSuggestionList = [defaultItem];
                    return el;
                  } else {
                    return Object.assign({}, formDataCurrentMedicationDefault);
                  }
                }),
          },
          family_health_history: {
            check: state.dataChanged
              ? state.formData.family_health_history.check
              : family_health_history.check || 0,
            medical_issues: state.dataChanged
              ? state.formData.family_health_history.medical_issues
              : family_health_history.medical_issues || [],
          },
          current_medical_history: {
            check: state.dataChanged
              ? state.formData.current_medical_history.check
              : current_medical_history.check || 0,
            pregnancy: state.dataChanged
              ? state.formData.current_medical_history.pregnancy
              : current_medical_history.pregnancy || 0,
            breastfeeding: state.dataChanged
              ? state.formData.current_medical_history.breastfeeding
              : current_medical_history.breastfeeding || 0,
            ongoing_conditions: state.dataChanged
              ? state.formData.current_medical_history.ongoing_conditions
              : current_medical_history.ongoing_conditions || 0,
            ongoing_condition: state.dataChanged
              ? state.formData.current_medical_history.ongoing_condition
              : current_medical_history.ongoing_condition || [],
            nutrition: state.dataChanged
              ? state.formData.current_medical_history.nutrition
              : current_medical_history.nutrition || 0,
            nutrition_history: state.dataChanged
              ? state.formData.current_medical_history.nutrition_history
              : current_medical_history.nutrition_history || "",
          },
          past_medical_history: {
            check: state.dataChanged
              ? state.formData.past_medical_history.check
              : past_medical_history.check || 0,
            surgeries: state.dataChanged
              ? state.formData.past_medical_history.surgeries
              : past_medical_history.surgeries || 0,
            hospitalizations: state.dataChanged
              ? state.formData.past_medical_history.hospitalizations
              : past_medical_history.hospitalizations || 0,
            implants: state.dataChanged
              ? state.formData.past_medical_history.implants
              : past_medical_history.implants || 0,
            vaccines: state.dataChanged
              ? state.formData.past_medical_history.vaccines
              : past_medical_history.vaccines || 0,
            surgery: state.dataChanged
              ? state.formData.past_medical_history.surgery
              : past_medical_history.surgery || [],
            hospitalization: state.dataChanged
              ? state.formData.past_medical_history.hospitalization
              : past_medical_history.hospitalization || [],
            implant: state.dataChanged
              ? state.formData.past_medical_history.implant
              : past_medical_history.implant || [],
            vaccine: state.dataChanged
              ? state.formData.past_medical_history.vaccine
              : past_medical_history.vaccine || [],
          },
        };
        returnState.copyArr = {
          allergy_drug: state.dataChanged
            ? state.copyArr.allergy_drug.slice(0, 2)
            : (allergy.allergy_drug || "").slice(0, 2),
          allergy_food: state.dataChanged
            ? state.copyArr.allergy_food.slice(0, 2)
            : (allergy.allergy_food || "").slice(0, 2),
          allergy_environment: state.dataChanged
            ? state.copyArr.allergy_environment.slice(0, 2)
            : (allergy.allergy_environment || "").slice(0, 2),
          prescription_medication: state.dataChanged
            ? state.copyArr.prescription_medication.slice(0, 2)
            : (current_medication.prescription_medication || "").slice(0, 2),
          vitamin: state.dataChanged
            ? state.copyArr.vitamin.slice(0, 2)
            : (current_medication.vitamin || "").slice(0, 2),
          over_medication: state.dataChanged
            ? state.copyArr.over_medication.slice(0, 2)
            : (current_medication.over_medication || "").slice(0, 2),
          medical_issues: state.dataChanged
            ? state.copyArr.medical_issues.slice(0, 2)
            : (family_health_history.medical_issues || "").slice(0, 2),
          ongoing_condition: state.dataChanged
            ? state.copyArr.ongoing_condition.slice(0, 2)
            : (current_medical_history.ongoing_condition || "").slice(0, 2),
          surgery: state.dataChanged
            ? state.copyArr.surgery.slice(0, 2)
            : (past_medical_history.surgery || "").slice(0, 2),
          hospitalization: state.dataChanged
            ? state.copyArr.hospitalization.slice(0, 2)
            : (past_medical_history.hospitalization || "").slice(0, 2),
          implant: state.dataChanged
            ? state.copyArr.implant.slice(0, 2)
            : (past_medical_history.implant || "").slice(0, 2),
          vaccine: state.dataChanged
            ? state.copyArr.vaccine.slice(0, 2)
            : (past_medical_history.vaccine || "").slice(0, 2),
        };
        returnState.formDataDefault = JSON.parse(
          JSON.stringify(returnState.formData),
        );
      }
      returnState.clientData = state.dataChanged
        ? state.clientData
        : props.clientData.data;
      returnState.showLoader = false;
      return returnState;
    }

    if (
      props.updateData !== undefined &&
      props.updateData !== null &&
      props.updateData.status === 200 &&
      props.updateData.data !== state.updateData
    ) {
      returnState.updateData = props.updateData.data;
      returnState.updateStatus = props.updateData.data;
      return returnState;
    }

    if (
      props.medicationsSuggestionList &&
      state.updatedAutocompleteField &&
      state.updatedAutocompleteFieldIndex >= 0
    ) {
      const section = state.updatedAutocompleteField.split(".")[0];
      const field = state.updatedAutocompleteField.split(".")[1];
      const formData = state.formData;
      formData[section][field][
        state.updatedAutocompleteFieldIndex
      ].medicationsSuggestionList = props.medicationsSuggestionList.map((e) => {
        return {
          value: e.replaceAll(" ", "_"),
          label: e,
        };
      });
      returnState.formData = formData;
    }

    return returnState;
  }

  handleDatepickerChangeRaw = (value, inputName, i) => {
    if (!moment(value).isValid()) {
      let section = inputName.split(".")[0];
      let field = inputName.split(".")[1];
      if (
        this.state.formDataDefault[section] &&
        this.state.formDataDefault[section][field] &&
        this.state.formDataDefault[section][field][i]
      ) {
        this.handleInputChange(
          this.state.formDataDefault[section][field][i].date,
          inputName,
          i,
        );
      } else {
        this.handleInputChange(
          moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
          inputName,
          i,
        );
      }
    }
  };

  onClickOutside = (refStr) => {
    this.refs[refStr].setOpen(false);
  };

  handleInputChange = (event, inputName, arrayIndex) => {
    event = event || "";
    let value;
    let section =
      event && event.target
        ? event.target.name.split(".")[0]
        : inputName.split(".")[0];
    let name =
      event && event.target
        ? event.target.name.split(".")[1]
        : inputName.split(".")[1];
    if (!event.target) {
      value = event;
    } else if (event.target.type === "radio") {
      value =
        event.target.value === "0" ||
        event.target.value === "1" ||
        event.target.value === "2"
          ? +event.target.value
          : event.target.value;
    } else if (event.target.type === "checkbox") {
      value = +event.target.checked;
    } else if (
      event.target.type === "number" &&
      !/^[\d]*\.?[\d]{0,2}$/.test(event.target.value)
    ) {
      return;
    } else {
      value = event.target.value;
    }
    if (
      (event && event.target && event.target.dataset.type === "array") ||
      arrayIndex !== undefined
    ) {
      const fieldName =
        arrayIndex !== undefined
          ? inputName.split(".")[2]
          : event.target.name.split(".")[2];
      const index =
        arrayIndex !== undefined ? arrayIndex : event.target.name.split(".")[3];
      const oldArray = this.state.formData[section][name];
      oldArray[index][fieldName] = value;
      value = oldArray;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [section]: {
          ...this.state.formData[section],
          [name]: value,
        },
      },
      dataChanged: true,
    });
  };

  addNewItem(section, name, arrayName, data) {
    let value = 1;
    let array = [];
    let extraField = {};
    let copyArr = {};
    if (name === "weight") {
      value = 2;
    }
    if (name === "pregnancy") {
      extraField = {
        breastfeeding: value,
      };
    }
    if (data) {
      array = this.state.formData[section][arrayName];
      array.push(Object.assign({}, data));
      copyArr = {
        [arrayName]: array,
      };
    }
    this.setState({
      visibility: {
        ...this.state.visibility,
        [name]: true,
      },
      formData: {
        ...this.state.formData,
        [section]: {
          ...this.state.formData[section],
          [name]: value,
          [arrayName]: array,
          ...extraField,
        },
      },
      copyArr: {
        ...this.state.copyArr,
        ...copyArr,
      },
    });
  }

  addNewItemRow(section, name, value) {
    const array = this.state.formData[section][name];
    array.push(Object.assign({}, value));
    this.setState({
      formData: {
        ...this.state.formData,
        [section]: {
          ...this.state.formData[section],
          [name]: array,
        },
      },
      copyArr: {
        ...this.state.copyArr,
        [name]: array,
      },
    });
  }

  deleteItemRow = () => {
    const { section, name, index } = this.state.deleteItemRowModal;
    this.setState({
      formData: {
        ...this.state.formData,
        [section]: {
          ...this.state.formData[section],
          [name]: this.state.formData[section][name].filter(
            (_, i) => index !== i,
          ),
        },
      },
      copyArr: {
        ...this.state.copyArr,
        [name]: this.state.formData[section][name].filter(
          (_, i) => index !== i,
        ),
      },
      deleteItemRowModal: {
        isOpen: false,
      },
    });
  };

  handleConfirmDeleteItemModal = (isOpen, section, name, index) => {
    this.setState({
      deleteItemRowModal: {
        isOpen: isOpen,
        section: isOpen && section,
        name: isOpen && name,
        index: isOpen && index,
      },
    });
  };

  collapseSection = (event) => {
    if (
      (event.target.classList.contains("history-section-head") ||
        event.target.classList.contains("history-section-title")) &&
      window.innerWidth <= 768
    ) {
      event.preventDefault();
      event.currentTarget.parentElement.classList.contains(
        "history-section_collapse",
      )
        ? event.currentTarget.parentElement.classList.remove(
            "history-section_collapse",
          )
        : event.currentTarget.parentElement.classList.add(
            "history-section_collapse",
          );
    }
  };

  handleCreatableSelectChange = (value, actionMeta, inputName, arrayIndex) => {
    let section = inputName.split(".")[0];
    let field = inputName.split(".")[1];

    const fieldName = inputName.split(".")[2];
    const oldArray = this.state.formData[section][field];

    if (actionMeta.action === "create-option") {
      value = {
        value: "newMedication",
        label: value,
      };
    } else {
      value = this.state.formData[section][field][
        arrayIndex
      ].medicationsSuggestionList.find((el) => el.value === value);
    }

    oldArray[arrayIndex].medicationsSuggestionList = [value];
    oldArray[arrayIndex][fieldName] = value;
    oldArray[arrayIndex].inputValue = "";

    this.setState({
      formData: {
        ...this.state.formData,
        [section]: {
          ...this.state.formData[section],
          [field]: oldArray,
        },
      },
      updatedAutocompleteField: "",
      updatedAutocompleteFieldIndex: -1,
      dataChanged: true,
    });
  };

  handleCreatableSelectInputChange = (
    value,
    actionMeta,
    inputName,
    arrayIndex,
  ) => {
    const searchQuery = value;

    let section = inputName.split(".")[0];
    let field = inputName.split(".")[1];

    const oldArray = this.state.formData[section][field].slice();

    if (
      actionMeta.action === "input-change" ||
      actionMeta.action === "menu-close"
    ) {
      oldArray[arrayIndex].inputValue =
        actionMeta.action === "menu-close" ? "" : searchQuery;
      this.setState({
        formData: {
          ...this.state.formData,
          [section]: {
            ...this.state.formData[section],
            [field]: oldArray,
          },
        },
      });

      if (searchQuery.length && searchQuery.trim().split(" ")[0].length >= 3) {
        this.handleReactInputChangeDebounce(
          searchQuery.split(" ")[0],
          inputName,
          arrayIndex,
        );
      }
    }
  };

  handleCreatableSelectFocus = (inputName, arrayIndex) => {
    let section = inputName.split(".")[0];
    let field = inputName.split(".")[1];

    const fieldName = inputName.split(".")[2];
    const oldArray = this.state.formData[section][field].slice();

    oldArray[arrayIndex].inputValue = oldArray[arrayIndex][fieldName].label
      ? oldArray[arrayIndex][fieldName].label
      : oldArray[arrayIndex][fieldName];

    this.setState({
      formData: {
        ...this.state.formData,
        [section]: {
          ...this.state.formData[section],
          [field]: oldArray,
        },
      },
    });
  };

  handleReactInputChangeDebounce = _debounce(
    (searchQuery, inputName, arrayIndex) => {
      this.setState(
        {
          updatedAutocompleteField: inputName,
          updatedAutocompleteFieldIndex: arrayIndex,
        },
        () => {
          this.props
            .getMedicineNames({
              q: searchQuery,
              limit: 10,
              accountId: this.state.userData.account.id,
              userId: this.state.userData.user.id,
            })
            .catch((res) => {
              uiNotification.error(
                this.state.globalLang[res.message] ||
                  "Unable to fetch medicine names",
              );
            });
        },
      );
    },
    300,
  );

  toggleListLength = (arr) => {
    let section = arr.split(".")[0];
    let arrName = arr.split(".")[1];

    if (
      JSON.stringify(this.state.formData[section][arrName]) ===
      JSON.stringify(this.state.copyArr[arrName])
    ) {
      this.setState({
        ...this.state,
        copyArr: {
          ...this.state.copyArr,
          [arrName]: this.state.formData[section][arrName].slice(0, 2),
        },
      });
    } else {
      this.setState({
        ...this.state,
        copyArr: {
          ...this.state.copyArr,
          [arrName]: this.state.formData[section][arrName].slice(0),
        },
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { formData } = this.state;

    let error = false;
    let formDataErrors = JSON.parse(JSON.stringify(formDataErrorsDefault));

    if (formData.social_history.tobacco_use) {
      if (
        formData.social_history.tobacco_use_week === null ||
        validator.isEmpty(formData.social_history.tobacco_use_week)
      ) {
        formDataErrors.social_history.tobacco_use_week =
          "This field is required";
        error = true;
      } else if (
        !isPositiveNumber(
          formData.social_history.tobacco_use_week,
          0,
          9999999,
          15,
        )
      ) {
        if (+formData.social_history.tobacco_use_week >= 9999999) {
          formDataErrors.social_history.tobacco_use_week =
            "You can not enter number greater than 9999999";
        } else {
          formDataErrors.social_history.tobacco_use_week =
            "This field requires number with a positive value";
        }
        error = true;
      }
      if (
        formData.social_history.tobacco_use_year === null ||
        validator.isEmpty(formData.social_history.tobacco_use_year)
      ) {
        formDataErrors.social_history.tobacco_use_year =
          "This field is required";
        error = true;
      } else if (
        !isPositiveNumber(
          formData.social_history.tobacco_use_year,
          0,
          9999999,
          15,
        )
      ) {
        if (+formData.social_history.tobacco_use_year >= 9999999) {
          formDataErrors.social_history.tobacco_use_year =
            "You can not enter number greater than 9999999";
        } else {
          formDataErrors.social_history.tobacco_use_year =
            "This field requires number with a positive value";
        }
        error = true;
      }
    }

    if (formData.social_history.alcohol_use) {
      if (
        formData.social_history.alcohol_use_week === null ||
        validator.isEmpty(formData.social_history.alcohol_use_week)
      ) {
        formDataErrors.social_history.alcohol_use_week =
          "This field is required";
        error = true;
      } else if (
        !isPositiveNumber(
          formData.social_history.alcohol_use_week,
          0,
          9999999,
          15,
        )
      ) {
        if (+formData.social_history.alcohol_use_week >= 9999999) {
          formDataErrors.social_history.alcohol_use_week =
            "You can not enter number greater than 9999999";
        } else {
          formDataErrors.social_history.alcohol_use_week =
            "This field requires number with a positive value";
        }
        error = true;
      }
    }

    if (formData.social_history.drug_use) {
      if (
        formData.social_history.drug_use_week === null ||
        validator.isEmpty(formData.social_history.drug_use_week)
      ) {
        formDataErrors.social_history.drug_use_week = "This field is required";
        error = true;
      } else if (
        !isPositiveNumber(formData.social_history.drug_use_week, 0, 9999999, 15)
      ) {
        if (+formData.social_history.drug_use_week >= 9999999) {
          formDataErrors.social_history.drug_use_week =
            "You can not enter number greater than 9999999";
        } else {
          formDataErrors.social_history.drug_use_week =
            "This field requires number with a positive value";
        }
        error = true;
      }
      if (
        formData.social_history.drug_use_type === null ||
        validator.isEmpty(formData.social_history.drug_use_type, isEmptyOpts)
      ) {
        formDataErrors.social_history.drug_use_type = "This field is required";
        error = true;
      }
    }

    if (formData.social_history.exercise_use) {
      if (
        formData.social_history.exercise_use_week === null ||
        validator.isEmpty(formData.social_history.exercise_use_week)
      ) {
        formDataErrors.social_history.exercise_use_week =
          "This field is required";
        error = true;
      } else if (
        !isPositiveNumber(
          formData.social_history.exercise_use_week,
          0,
          9999999,
          15,
        )
      ) {
        if (+formData.social_history.exercise_use_week >= 9999999) {
          formDataErrors.social_history.exercise_use_week =
            "You can not enter number greater than 9999999";
        } else {
          formDataErrors.social_history.exercise_use_week =
            "This field requires number with a positive value";
        }
        error = true;
      }
    }

    if (
      !!formData.social_history.weight &&
      formData.social_history.weight !== 0
    ) {
      if (
        formData.social_history.weight_info === null ||
        validator.isEmpty(formData.social_history.weight_info, isEmptyOpts)
      ) {
        formDataErrors.social_history.weight_info = "This field is required";
        error = true;
      }
    }

    if (!formData.allergy.check) {
      if (formData.allergy.drugs) {
        formData.allergy.allergy_drug.map((e, i) => {
          const el = Object.assign({}, e);
          el.medication =
            el.medication && el.medication.label
              ? el.medication.label
              : el.medication;
          formDataErrors.allergy.allergy_drug.push({
            medication: false,
            reaction: false,
          });
          if (validator.isEmpty(el.medication, isEmptyOpts)) {
            formDataErrors.allergy.allergy_drug[i].medication =
              "Medication is required";
            error = true;
          }
          if (validator.isEmpty(el.reaction, isEmptyOpts)) {
            formDataErrors.allergy.allergy_drug[i].reaction =
              "Reaction is required";
            error = true;
          }
        });
      }

      if (formData.allergy.foods) {
        formData.allergy.allergy_food.forEach((el, i) => {
          formDataErrors.allergy.allergy_food.push({
            food: false,
            reaction: false,
          });
          if (validator.isEmpty(el.food, isEmptyOpts)) {
            formDataErrors.allergy.allergy_food[i].food = "Food is required";
            error = true;
          }
          if (validator.isEmpty(el.reaction, isEmptyOpts)) {
            formDataErrors.allergy.allergy_food[i].reaction =
              "Reaction is required";
            error = true;
          }
        });
      }

      if (formData.allergy.environment) {
        formData.allergy.allergy_environment.forEach((el, i) => {
          formDataErrors.allergy.allergy_environment.push({
            environment: false,
            reaction: false,
          });
          if (validator.isEmpty(el.environment, isEmptyOpts)) {
            formDataErrors.allergy.allergy_environment[i].environment =
              "Environment is required";
            error = true;
          }
          if (validator.isEmpty(el.reaction, isEmptyOpts)) {
            formDataErrors.allergy.allergy_environment[i].reaction =
              "Reaction is required";
            error = true;
          }
        });
      }
    }

    if (!formData.current_medication.check) {
      if (formData.current_medication.prescription_medications) {
        formData.current_medication.prescription_medication.forEach((e, i) => {
          const el = Object.assign({}, e);
          el.medication =
            el.medication && el.medication.label
              ? el.medication.label
              : el.medication;
          formDataErrors.current_medication.prescription_medication.push({
            medication: false,
            dose: false,
            frequency: false,
          });
          if (validator.isEmpty(el.medication, isEmptyOpts)) {
            formDataErrors.current_medication.prescription_medication[
              i
            ].medication = "Medication is required";
            error = true;
          }
        });
      }

      if (formData.current_medication.vitamins) {
        formData.current_medication.vitamin.forEach((e, i) => {
          const el = Object.assign({}, e);
          el.medication =
            el.medication && el.medication.label
              ? el.medication.label
              : el.medication;
          formDataErrors.current_medication.vitamin.push({
            medication: false,
            dose: false,
            frequency: false,
          });
          if (validator.isEmpty(el.medication, isEmptyOpts)) {
            formDataErrors.current_medication.vitamin[i].medication =
              "Medication is required";
            error = true;
          }
        });
      }

      if (formData.current_medication.over_medications) {
        formData.current_medication.over_medication.forEach((e, i) => {
          const el = Object.assign({}, e);
          el.medication =
            el.medication && el.medication.label
              ? el.medication.label
              : el.medication;
          formDataErrors.current_medication.over_medication.push({
            medication: false,
            dose: false,
            frequency: false,
          });
          if (validator.isEmpty(el.medication, isEmptyOpts)) {
            formDataErrors.current_medication.over_medication[i].medication =
              "Medication is required";
            error = true;
          }
        });
      }
    }

    if (!formData.family_health_history.check) {
      formData.family_health_history.medical_issues.forEach((el, i) => {
        formDataErrors.family_health_history.medical_issues.push({
          diagnosis: false,
          relationship: false,
        });
        if (validator.isEmpty(el.diagnosis, isEmptyOpts)) {
          formDataErrors.family_health_history.medical_issues[i].diagnosis =
            "Diagnosis is required";
          error = true;
        }
        if (validator.isEmpty(el.relationship, isEmptyOpts)) {
          formDataErrors.family_health_history.medical_issues[i].relationship =
            "Relationship to Patient is required";
          error = true;
        }
      });
    }

    if (!formData.current_medical_history.check) {
      if (formData.current_medical_history.ongoing_conditions) {
        formData.current_medical_history.ongoing_condition.forEach((el, i) => {
          formDataErrors.current_medical_history.ongoing_condition.push({
            type: false,
            date: false,
            name: false,
          });
          if (validator.isEmpty(el.type, isEmptyOpts)) {
            formDataErrors.current_medical_history.ongoing_condition[i].type =
              "Condition/Diagnosis Type is required";
            error = true;
          }
          if (!el.date) {
            formDataErrors.current_medical_history.ongoing_condition[i].date =
              "Date of diagnosis is required";
            error = true;
          }
          if (validator.isEmpty(el.name, isEmptyOpts)) {
            formDataErrors.current_medical_history.ongoing_condition[i].name =
              "Name of Treating Physician is required";
            error = true;
          }
        });
      }

      if (formData.current_medical_history.nutrition) {
        if (
          validator.isEmpty(
            formData.current_medical_history.nutrition_history,
            isEmptyOpts,
          )
        ) {
          formDataErrors.current_medical_history.nutrition_history =
            "Considerations are required";
          error = true;
        }
      }
    }

    if (!formData.past_medical_history.check) {
      if (formData.past_medical_history.surgeries) {
        formData.past_medical_history.surgery.forEach((el, i) => {
          formDataErrors.past_medical_history.surgery.push({
            date: false,
            physician: false,
            city: false,
            state: false,
            type: false,
          });
          if (validator.isEmpty(el.type, isEmptyOpts)) {
            formDataErrors.past_medical_history.surgery[i].type =
              "Type of surgery is required";
            error = true;
          }
        });
      }

      if (formData.past_medical_history.hospitalizations) {
        formData.past_medical_history.hospitalization.forEach((el, i) => {
          formDataErrors.past_medical_history.hospitalization.push({
            reason: false,
            date: false,
            hospital: false,
          });
          if (validator.isEmpty(el.reason, isEmptyOpts)) {
            formDataErrors.past_medical_history.hospitalization[i].reason =
              "Reason for Hospitalization is required";
            error = true;
          }
          if (!el.date) {
            formDataErrors.past_medical_history.hospitalization[i].date =
              "Date of Hospitalization is required";
            error = true;
          }
        });
      }

      if (formData.past_medical_history.implants) {
        formData.past_medical_history.implant.forEach((el, i) => {
          formDataErrors.past_medical_history.implant.push({
            type: false,
            place: false,
            date: false,
          });
          if (validator.isEmpty(el.type, isEmptyOpts)) {
            formDataErrors.past_medical_history.implant[i].type =
              "Type of Implant is required";
            error = true;
          }
          if (validator.isEmpty(el.place, isEmptyOpts)) {
            formDataErrors.past_medical_history.implant[i].place =
              "This field is required";
            error = true;
          }
          if (!el.date) {
            formDataErrors.past_medical_history.implant[i].date =
              "Date of Implant is required";
            error = true;
          }
        });
      }

      if (formData.past_medical_history.vaccines) {
        formData.past_medical_history.vaccine.forEach((el, i) => {
          formDataErrors.past_medical_history.vaccine.push({
            vaccine: false,
            date: false,
          });
          if (validator.isEmpty(el.vaccine, isEmptyOpts)) {
            formDataErrors.past_medical_history.vaccine[i].vaccine =
              "Vaccine is required";
            error = true;
          }
          if (!el.date) {
            formDataErrors.past_medical_history.vaccine[i].date =
              "Date of Vaccination is required";
            error = true;
          }
        });
      }
    }

    if (error) {
      const isArrayHaveError = (arr) => {
        return arr.some((el) => {
          for (let prop in el) {
            if (el.hasOwnProperty(prop)) {
              return !!el[prop];
            }
          }
          return false;
        });
      };

      const copyArr = {
        allergy_drug: isArrayHaveError(formDataErrors.allergy.allergy_drug)
          ? this.state.formData.allergy.allergy_drug.slice()
          : this.state.formData.allergy.allergy_drug.slice(0, 2),
        allergy_food: isArrayHaveError(formDataErrors.allergy.allergy_food)
          ? this.state.formData.allergy.allergy_food.slice()
          : this.state.formData.allergy.allergy_food.slice(0, 2),
        allergy_environment: isArrayHaveError(
          formDataErrors.allergy.allergy_environment,
        )
          ? this.state.formData.allergy.allergy_environment.slice()
          : this.state.formData.allergy.allergy_environment.slice(0, 2),
        prescription_medication: isArrayHaveError(
          formDataErrors.current_medication.prescription_medication,
        )
          ? this.state.formData.current_medication.prescription_medication.slice()
          : this.state.formData.current_medication.prescription_medication.slice(
              0,
              2,
            ),
        vitamin: isArrayHaveError(formDataErrors.current_medication.vitamin)
          ? this.state.formData.current_medication.vitamin.slice()
          : this.state.formData.current_medication.vitamin.slice(0, 2),
        over_medication: isArrayHaveError(
          formDataErrors.current_medication.over_medication,
        )
          ? this.state.formData.current_medication.over_medication.slice()
          : this.state.formData.current_medication.over_medication.slice(0, 2),
        medical_issues: isArrayHaveError(
          formDataErrors.family_health_history.medical_issues,
        )
          ? this.state.formData.family_health_history.medical_issues.slice()
          : this.state.formData.family_health_history.medical_issues.slice(
              0,
              2,
            ),
        ongoing_condition: isArrayHaveError(
          formDataErrors.current_medical_history.ongoing_condition,
        )
          ? this.state.formData.current_medical_history.ongoing_condition.slice()
          : this.state.formData.current_medical_history.ongoing_condition.slice(
              0,
              2,
            ),
        surgery: isArrayHaveError(formDataErrors.past_medical_history.surgery)
          ? this.state.formData.past_medical_history.surgery.slice()
          : this.state.formData.past_medical_history.surgery.slice(0, 2),
        hospitalization: isArrayHaveError(
          formDataErrors.past_medical_history.hospitalization,
        )
          ? this.state.formData.past_medical_history.hospitalization.slice()
          : this.state.formData.past_medical_history.hospitalization.slice(
              0,
              2,
            ),
        implant: isArrayHaveError(formDataErrors.past_medical_history.implant)
          ? this.state.formData.past_medical_history.implant.slice()
          : this.state.formData.past_medical_history.implant.slice(0, 2),
        vaccine: isArrayHaveError(formDataErrors.past_medical_history.vaccine)
          ? this.state.formData.past_medical_history.vaccine.slice()
          : this.state.formData.past_medical_history.vaccine.slice(0, 2),
      };

      this.setState(
        {
          formDataErrors: formDataErrors,
          copyArr: copyArr,
          showLoader: false,
        },
        () => {
          uiNotification.clear();
          uiNotification.error("Complete missing information.");
          window.scrollTo(0, 0);
        },
      );
    } else {
      const {
        social_history,
        allergy,
        current_medication,
        family_health_history,
        current_medical_history,
        past_medical_history,
      } = this.state.formData;

      const { visibility } = this.state;
      const newMedications = [];

      let medicalHistory = {
        medical_history: {
          weight: this.state.formData.weight,
          height: this.state.formData.height,
          social_history: {
            tobacco_use: social_history.tobacco_use,
            tobacco_use_week:
              social_history.tobacco_use && visibility.tobacco_use
                ? Number(social_history.tobacco_use_week)
                : visibility.tobacco_use
                ? 0
                : null,
            tobacco_use_year:
              social_history.tobacco_use && visibility.tobacco_use
                ? Number(social_history.tobacco_use_year)
                : visibility.tobacco_use
                ? 0
                : null,
            alcohol_use: social_history.alcohol_use,
            alcohol_use_week:
              social_history.alcohol_use && visibility.alcohol_use
                ? Number(social_history.alcohol_use_week)
                : visibility.alcohol_use
                ? 0
                : null,
            drug_use: social_history.drug_use,
            drug_use_week:
              social_history.drug_use && visibility.drug_use
                ? Number(social_history.drug_use_week)
                : visibility.drug_use
                ? 0
                : null,
            drug_use_period:
              social_history.drug_use &&
              visibility.drug_use &&
              social_history.drug_use_period
                ? social_history.drug_use_period
                : drugUsePeriod.month,
            drug_use_type:
              social_history.drug_use && visibility.drug_use
                ? social_history.drug_use_type
                : visibility.drug_use
                ? ""
                : null,
            exercise_use: social_history.exercise_use,
            exercise_use_week:
              social_history.exercise_use && visibility.exercise_use
                ? Number(social_history.exercise_use_week)
                : visibility.exercise_use
                ? 0
                : null,
            weight: social_history.weight,
            weight_info:
              social_history.weight && visibility.weight
                ? social_history.weight_info
                : visibility.weight
                ? ""
                : null,
          },
          allergy: {
            check: allergy.check,
            drugs: !allergy.check ? allergy.drugs : 0,
            foods: !allergy.check ? allergy.foods : 0,
            environment: !allergy.check ? allergy.environment : 0,
            allergy_drug:
              allergy.drugs && !allergy.check
                ? allergy.allergy_drug.map((el) => {
                    if (el.medication && el.medication.label) {
                      if (el.medication.value === "newMedication") {
                        newMedications.push(el.medication.label);
                      }
                      el.medication = el.medication.label;
                    }
                    return el;
                  })
                : visibility.drugs && !allergy.check
                ? [formDataAllergyDrugDefault]
                : [],
            allergy_food:
              allergy.foods && !allergy.check
                ? allergy.allergy_food
                : visibility.foods && !allergy.check
                ? [formDataAllergyFoodDefault]
                : [],
            allergy_environment:
              allergy.environment && !allergy.check
                ? allergy.allergy_environment
                : visibility.environment && !allergy.check
                ? [formDataAllergyEnvironmentDefault]
                : [],
          },
          current_medication: {
            check: current_medication.check,
            prescription_medications: !current_medication.check
              ? current_medication.prescription_medications
              : 0,
            vitamins: !current_medication.check
              ? current_medication.vitamins
              : 0,
            over_medications: !current_medication.check
              ? current_medication.over_medications
              : 0,
            prescription_medication:
              current_medication.prescription_medications &&
              !current_medication.check
                ? current_medication.prescription_medication.map((el) => {
                    if (el.medication && el.medication.label) {
                      if (el.medication.value === "newMedication") {
                        newMedications.push(el.medication.label);
                      }
                      el.medication = el.medication.label;
                    }
                    return el;
                  })
                : visibility.prescription_medications &&
                  !current_medication.check
                ? [formDataCurrentMedicationDefault]
                : [],
            vitamin:
              current_medication.vitamins && !current_medication.check
                ? current_medication.vitamin.map((el) => {
                    if (el.medication && el.medication.label) {
                      if (el.medication.value === "newMedication") {
                        newMedications.push(el.medication.label);
                      }
                      el.medication = el.medication.label;
                    }
                    return el;
                  })
                : visibility.vitamins && !current_medication.check
                ? [formDataCurrentMedicationDefault]
                : [],
            over_medication:
              current_medication.over_medications && !current_medication.check
                ? current_medication.over_medication.map((el) => {
                    if (el.medication && el.medication.label) {
                      if (el.medication.value === "newMedication") {
                        newMedications.push(el.medication.label);
                      }
                      el.medication = el.medication.label;
                    }
                    return el;
                  })
                : visibility.over_medications && !current_medication.check
                ? [formDataCurrentMedicationDefault]
                : [],
          },
          family_health_history: {
            check: family_health_history.check,
            medical_issues: !family_health_history.check
              ? family_health_history.medical_issues
              : visibility.medical_issues && !family_health_history.check
              ? [formDataMedicalIssuesDefault]
              : [],
          },
          current_medical_history: {
            check: current_medical_history.check,
            pregnancy:
              !current_medical_history.check && visibility.pregnancy
                ? current_medical_history.pregnancy
                : null,
            breastfeeding:
              !current_medical_history.check && visibility.pregnancy
                ? current_medical_history.breastfeeding
                : null,
            ongoing_conditions: !current_medical_history.check
              ? current_medical_history.ongoing_conditions
              : 0,
            ongoing_condition:
              !current_medical_history.check &&
              current_medical_history.ongoing_conditions
                ? current_medical_history.ongoing_condition.map((e) => {
                    e.date = e.date
                      ? moment(e.date).format("YYYY-MM-DD hh:mm:ss")
                      : "";
                    return e;
                  })
                : visibility.ongoing_conditions &&
                  !current_medical_history.check
                ? [formDataOngoingConditionDefault]
                : [],
            nutrition: !current_medical_history.check
              ? current_medical_history.nutrition
              : 0,
            nutrition_history:
              !current_medical_history.check &&
              current_medical_history.nutrition
                ? current_medical_history.nutrition_history
                : visibility.nutrition && !current_medical_history.check
                ? ""
                : null,
          },
          past_medical_history: {
            check: past_medical_history.check,
            surgeries: !past_medical_history.check
              ? past_medical_history.surgeries
              : 0,
            hospitalizations: !past_medical_history.check
              ? past_medical_history.hospitalizations
              : 0,
            implants: !past_medical_history.check
              ? past_medical_history.implants
              : 0,
            vaccines: !past_medical_history.check
              ? past_medical_history.vaccines
              : 0,
            surgery:
              !past_medical_history.check && past_medical_history.surgeries
                ? past_medical_history.surgery.map((e) => {
                    e.date = e.date
                      ? moment(e.date).format("YYYY-MM-DD hh:mm:ss")
                      : "";
                    return e;
                  })
                : visibility.surgeries && !past_medical_history.check
                ? [formDataSurgeryDefault]
                : [],
            hospitalization:
              !past_medical_history.check &&
              past_medical_history.hospitalizations
                ? past_medical_history.hospitalization.map((e) => {
                    e.date = e.date
                      ? moment(e.date).format("YYYY-MM-DD hh:mm:ss")
                      : "";
                    return e;
                  })
                : visibility.hospitalizations && !past_medical_history.check
                ? [formDataHospitalizationDefault]
                : [],
            implant:
              !past_medical_history.check && past_medical_history.implants
                ? past_medical_history.implant.map((e) => {
                    e.date = e.date
                      ? moment(e.date).format("YYYY-MM-DD hh:mm:ss")
                      : "";
                    return e;
                  })
                : visibility.implants && !past_medical_history.check
                ? [formDataImplantDefault]
                : [],
            vaccine:
              !past_medical_history.check && past_medical_history.vaccines
                ? past_medical_history.vaccine.map((e) => {
                    e.date = e.date
                      ? moment(e.date).format("YYYY-MM-DD hh:mm:ss")
                      : "";
                    return e;
                  })
                : visibility.vaccines && !past_medical_history.check
                ? [formDataVaccineDefault]
                : [],
          },
          new_medications: [...new Set(newMedications)],
          patient_id: this.state.clientID,
        },
      };

      this.setState(
        {
          formDataErrors: formDataErrorsDefault,
          showLoader: true,
          dataChanged: false,
        },
        () => {
          this.props
            .updateNewMedicalHistory(medicalHistory)
            .then((res) => {
              uiNotification.success(
                this.state.globalLang[res.message] ||
                  "Medical history has been successfully updated",
              );
              this.props.refetchClient();
            })
            .catch((res) => {
              const isMessage = res.message?.indexOf("_") === -1;
              uiNotification.error(
                this.state.globalLang[res.message] ||
                  (isMessage
                    ? res.message
                    : "Unable to update medical history"),
              );
            })
            .finally(() => {
              this.setState({
                showLoader: false,
              });
            });
        },
      );
    }
  };

  render() {
    let returnTo = "";
    let clientName = "";

    if (this.state.backURLType && this.state.backURLType === "clients") {
      returnTo = this.props.match.params.type
        ? "/" +
          this.state.backURLType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.clientID +
          "/1"
        : "/" + this.state.backURLType;
    }

    if (this.state.clientData !== undefined) {
      if (this.state.clientData.firstname) {
        clientName += this.state.clientData.firstname;
      }

      if (this.state.clientData.lastname) {
        clientName += " " + this.state.clientData.lastname;
      }

      clientName +=
        " - " + this.state.clientsLang.clientprofile_medical_history;
    }

    return (
      <div id="content" className="medical-history">
        <div className="container-fluid content setting-wrapper">
          <form onSubmit={this.handleSubmit}>
            <div className="juvly-section full-width m-t-15">
              <div className="juvly-container">
                {this.state.showLoader === false && (
                  <div className="juvly-title medical-history-header m-b-30">
                    {clientName}
                    <Link to={returnTo} className="close-form" />
                  </div>
                )}
                <div className="history-section">
                  <div
                    className="history-section-head"
                    onClick={(e) => this.collapseSection(e)}
                  >
                    <h3 className="history-section-title">
                      <AppTranslation.Clients path="medicalHistory.patientInfo.label" />
                    </h3>
                  </div>
                  <div className="history-section-body">
                    <div className="col-xs-12 col-md-3">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">Height</div>
                        <div className="setting-input-outer">
                          <input
                            name="height"
                            id="height"
                            className="newInputField"
                            maxLength={300}
                            value={this.state.formData.height}
                            onWheel={(event) => event.currentTarget.blur()}
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  [e.target.name]: e.target.value,
                                },
                              });
                            }}
                            autoComplete="off"
                            placeholder="Enter"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-3">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">Weight</div>
                        <div className="setting-input-outer">
                          <input
                            name="weight"
                            id="weight"
                            className="newInputField"
                            maxLength={300}
                            value={this.state.formData.weight}
                            onWheel={(event) => event.currentTarget.blur()}
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  [e.target.name]: e.target.value,
                                },
                              });
                            }}
                            autoComplete="off"
                            placeholder="Enter"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="history-section">
                  <div
                    className="history-section-head"
                    onClick={(e) => this.collapseSection(e)}
                  >
                    <h3 className="history-section-title">Social History</h3>
                  </div>
                  <div className="history-section-body">
                    <div className="row row-flex-wrap">
                      {this.state.visibility.tobacco_use && (
                        <div className="col-sm-12 col-md-5 m-b-5">
                          <div className="history-section-radios-wrapper">
                            <h3 className="history-section-radio-title">
                              Tobacco Use
                            </h3>
                            <div className="basic-checkbox-outer-wrapper">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="tobacco_use_true"
                                  className="basic-form-checkbox"
                                  name="social_history.tobacco_use"
                                  type="radio"
                                  checked={
                                    this.state.formData.social_history
                                      .tobacco_use
                                  }
                                  value={1}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="tobacco_use_true"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="tobacco_use_false"
                                  className="basic-form-checkbox"
                                  name="social_history.tobacco_use"
                                  type="radio"
                                  checked={
                                    !this.state.formData.social_history
                                      .tobacco_use
                                  }
                                  value={0}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="tobacco_use_false"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          {!!this.state.formData.social_history.tobacco_use && (
                            <div className="newInputFileldOuter">
                              <div
                                className={
                                  this.state.formDataErrors.social_history
                                    .tobacco_use_week
                                    ? "newInputLabel danger-text"
                                    : "newInputLabel"
                                }
                              >
                                How many packs do you smoke per week?
                              </div>
                              <div className="setting-input-outer">
                                <input
                                  name="social_history.tobacco_use_week"
                                  type="number"
                                  id="social_history.tobacco_use_week"
                                  className={
                                    this.state.formDataErrors.social_history
                                      .tobacco_use_week
                                      ? "newInputField field_error"
                                      : "newInputField"
                                  }
                                  maxLength={200}
                                  value={
                                    this.state.formData.social_history
                                      .tobacco_use_week
                                  }
                                  placeholder="Enter"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  onChange={this.handleInputChange}
                                  autoComplete="off"
                                />
                                {this.state.formDataErrors.social_history
                                  .tobacco_use_week && (
                                  <label className="input-helper">
                                    {
                                      this.state.formDataErrors.social_history
                                        .tobacco_use_week
                                    }
                                  </label>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.visibility.tobacco_use &&
                        !!this.state.formData.social_history.tobacco_use && (
                          <div className="col-sm-12 col-md-5">
                            <div className="history-section-radios-wrapper" />
                            <div className="newInputFileldOuter">
                              <div
                                className={
                                  this.state.formDataErrors.social_history
                                    .tobacco_use_year
                                    ? "newInputLabel danger-text"
                                    : "newInputLabel"
                                }
                              >
                                How many years have you smoked?
                              </div>
                              <div className="setting-input-outer">
                                <input
                                  name="social_history.tobacco_use_year"
                                  type="number"
                                  className={
                                    this.state.formDataErrors.social_history
                                      .tobacco_use_year
                                      ? "newInputField field_error"
                                      : "newInputField"
                                  }
                                  maxLength={255}
                                  value={
                                    this.state.formData.social_history
                                      .tobacco_use_year
                                  }
                                  placeholder="Enter"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  onChange={this.handleInputChange}
                                  autoComplete="off"
                                />
                                {this.state.formDataErrors.social_history
                                  .tobacco_use_year && (
                                  <label className="input-helper">
                                    {
                                      this.state.formDataErrors.social_history
                                        .tobacco_use_year
                                    }
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      {this.state.visibility.drug_use && (
                        <div className="col-sm-12 col-md-5 m-b-5">
                          <div className="history-section-radios-wrapper">
                            <h3 className="history-section-radio-title">
                              Recreational Drug Use
                            </h3>
                            <div className="basic-checkbox-outer-wrapper">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="drug_use_true"
                                  className="basic-form-checkbox"
                                  name="social_history.drug_use"
                                  type="radio"
                                  checked={
                                    this.state.formData.social_history.drug_use
                                  }
                                  value={1}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="drug_use_true"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="drug_use_false"
                                  className="basic-form-checkbox"
                                  name="social_history.drug_use"
                                  type="radio"
                                  checked={
                                    !this.state.formData.social_history.drug_use
                                  }
                                  value={0}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="drug_use_false"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          {!!this.state.formData.social_history.drug_use && (
                            <div className="section-drug-wrapper">
                              <div className="newInputFileldOuter">
                                <div
                                  className={
                                    this.state.formDataErrors.social_history
                                      .drug_use_week
                                      ? "newInputLabel danger-text"
                                      : "newInputLabel"
                                  }
                                >
                                  How many times?
                                </div>
                                <div className="setting-input-outer">
                                  <input
                                    name="social_history.drug_use_week"
                                    type="number"
                                    className={
                                      this.state.formDataErrors.social_history
                                        .drug_use_week
                                        ? "newInputField field_error"
                                        : "newInputField"
                                    }
                                    maxLength={255}
                                    value={
                                      this.state.formData.social_history
                                        .drug_use_week
                                    }
                                    placeholder="Enter"
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                    onChange={this.handleInputChange}
                                    autoComplete="off"
                                  />
                                  {this.state.formDataErrors.social_history
                                    .drug_use_week && (
                                    <label className="input-helper">
                                      {
                                        this.state.formDataErrors.social_history
                                          .drug_use_week
                                      }
                                    </label>
                                  )}
                                </div>
                              </div>
                              <div className="history-section-radios-wrapper">
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="drug_use_period_true"
                                      className="basic-form-checkbox"
                                      name="social_history.drug_use_period"
                                      type="radio"
                                      checked={
                                        this.state.formData.social_history
                                          .drug_use_period ===
                                        drugUsePeriod.week
                                      }
                                      value={drugUsePeriod.week}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="drug_use_period_true"
                                    >
                                      Week
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="drug_use_period_false"
                                      className="basic-form-checkbox"
                                      name="social_history.drug_use_period"
                                      type="radio"
                                      checked={
                                        this.state.formData.social_history
                                          .drug_use_period ===
                                        drugUsePeriod.month
                                      }
                                      value={drugUsePeriod.month}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="drug_use_period_false"
                                    >
                                      Month
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.visibility.drug_use &&
                        !!this.state.formData.social_history.drug_use && (
                          <div className="col-sm-12 col-md-5 m-b-5">
                            <div className="history-section-radios-wrapper" />
                            <div className="newInputFileldOuter">
                              <div
                                className={
                                  this.state.formDataErrors.social_history
                                    .drug_use_type
                                    ? "newInputLabel danger-text"
                                    : "newInputLabel"
                                }
                              >
                                Type of drug used
                              </div>
                              <div className="setting-input-outer">
                                <input
                                  name="social_history.drug_use_type"
                                  type="text"
                                  className={
                                    this.state.formDataErrors.social_history
                                      .drug_use_type
                                      ? "newInputField field_error"
                                      : "newInputField"
                                  }
                                  maxLength={255}
                                  value={
                                    this.state.formData.social_history
                                      .drug_use_type
                                  }
                                  placeholder="Enter"
                                  onChange={this.handleInputChange}
                                  autoComplete="off"
                                />
                                {this.state.formDataErrors.social_history
                                  .drug_use_type && (
                                  <label className="input-helper">
                                    {
                                      this.state.formDataErrors.social_history
                                        .drug_use_type
                                    }
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      {this.state.visibility.alcohol_use && (
                        <div className="col-sm-12 col-md-5 m-b-5">
                          <div className="history-section-radios-wrapper">
                            <h3 className="history-section-radio-title">
                              Alcohol Use
                            </h3>
                            <div className="basic-checkbox-outer-wrapper">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="alcohol_use_true"
                                  className="basic-form-checkbox"
                                  name="social_history.alcohol_use"
                                  type="radio"
                                  checked={
                                    this.state.formData.social_history
                                      .alcohol_use
                                  }
                                  value={1}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="alcohol_use_true"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="alcohol_use_false"
                                  className="basic-form-checkbox"
                                  name="social_history.alcohol_use"
                                  type="radio"
                                  checked={
                                    !this.state.formData.social_history
                                      .alcohol_use
                                  }
                                  value={0}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="alcohol_use_false"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          {!!this.state.formData.social_history.alcohol_use && (
                            <div className="newInputFileldOuter">
                              <div
                                className={
                                  this.state.formDataErrors.social_history
                                    .alcohol_use_week
                                    ? "newInputLabel danger-text"
                                    : "newInputLabel"
                                }
                              >
                                How many drinks per week?
                              </div>
                              <div className="setting-input-outer">
                                <input
                                  name="social_history.alcohol_use_week"
                                  type="number"
                                  className={
                                    this.state.formDataErrors.social_history
                                      .alcohol_use_week
                                      ? "newInputField field_error"
                                      : "newInputField"
                                  }
                                  maxLength={255}
                                  value={
                                    this.state.formData.social_history
                                      .alcohol_use_week
                                  }
                                  placeholder="Enter"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  onChange={this.handleInputChange}
                                  autoComplete="off"
                                />
                                {this.state.formDataErrors.social_history
                                  .alcohol_use_week && (
                                  <label className="input-helper">
                                    {
                                      this.state.formDataErrors.social_history
                                        .alcohol_use_week
                                    }
                                  </label>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.visibility.exercise_use && (
                        <div className="col-sm-12 col-md-5 m-b-5">
                          <div className="history-section-radios-wrapper">
                            <h3 className="history-section-radio-title">
                              Exercise
                            </h3>
                            <div className="basic-checkbox-outer-wrapper">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="exercise_use_true"
                                  className="basic-form-checkbox"
                                  name="social_history.exercise_use"
                                  type="radio"
                                  checked={
                                    this.state.formData.social_history
                                      .exercise_use
                                  }
                                  value={1}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="exercise_use_true"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="exercise_use_false"
                                  className="basic-form-checkbox"
                                  name="social_history.exercise_use"
                                  type="radio"
                                  checked={
                                    !this.state.formData.social_history
                                      .exercise_use
                                  }
                                  value={0}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="exercise_use_false"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          {!!this.state.formData.social_history
                            .exercise_use && (
                            <div className="newInputFileldOuter">
                              <div
                                className={
                                  this.state.formDataErrors.social_history
                                    .exercise_use_week
                                    ? "newInputLabel danger-text"
                                    : "newInputLabel"
                                }
                              >
                                How many times per week?
                              </div>
                              <div className="setting-input-outer">
                                <input
                                  name="social_history.exercise_use_week"
                                  type="number"
                                  className={
                                    this.state.formDataErrors.social_history
                                      .exercise_use_week
                                      ? "newInputField field_error"
                                      : "newInputField"
                                  }
                                  maxLength={255}
                                  value={
                                    this.state.formData.social_history
                                      .exercise_use_week
                                  }
                                  placeholder="Enter"
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  onChange={this.handleInputChange}
                                  autoComplete="off"
                                />
                                {this.state.formDataErrors.social_history
                                  .exercise_use_week && (
                                  <label className="input-helper">
                                    {
                                      this.state.formDataErrors.social_history
                                        .exercise_use_week
                                    }
                                  </label>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.visibility.weight && (
                        <div className="col-sm-12 col-md-5 m-b-5">
                          <div className="history-section-radios-wrapper">
                            <h3 className="history-section-radio-title">
                              Weight Gain/Loss
                            </h3>
                            <div className="basic-checkbox-outer-wrapper">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="weight_gain"
                                  className="basic-form-checkbox"
                                  name="social_history.weight"
                                  type="radio"
                                  checked={
                                    this.state.formData.social_history
                                      .weight === 2
                                  }
                                  value={2}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="weight_gain"
                                >
                                  Gain
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="weight_loss"
                                  className="basic-form-checkbox"
                                  name="social_history.weight"
                                  type="radio"
                                  checked={
                                    this.state.formData.social_history
                                      .weight === 1
                                  }
                                  value={1}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="weight_loss"
                                >
                                  Loss
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="weight_none"
                                  className="basic-form-checkbox"
                                  name="social_history.weight"
                                  type="radio"
                                  checked={
                                    this.state.formData.social_history
                                      .weight === 0
                                  }
                                  value={0}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="weight_none"
                                >
                                  None
                                </label>
                              </div>
                            </div>
                          </div>
                          {this.state.formData.social_history.weight !== 0 && (
                            <div className="newInputFileldOuter">
                              <div
                                className={
                                  this.state.formDataErrors.social_history
                                    .weight_info
                                    ? "newInputLabel danger-text"
                                    : "newInputLabel"
                                }
                              >
                                How much?
                              </div>
                              <div className="setting-input-outer">
                                <input
                                  name="social_history.weight_info"
                                  type="text"
                                  className={
                                    this.state.formDataErrors.social_history
                                      .weight_info
                                      ? "newInputField field_error"
                                      : "newInputField"
                                  }
                                  maxLength={255}
                                  value={
                                    this.state.formData.social_history
                                      .weight_info
                                  }
                                  placeholder="Enter"
                                  onChange={this.handleInputChange}
                                  autoComplete="off"
                                />
                                {this.state.formDataErrors.social_history
                                  .weight_info && (
                                  <label className="input-helper">
                                    {
                                      this.state.formDataErrors.social_history
                                        .weight_info
                                    }
                                  </label>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="history-section-body">
                    {!this.state.visibility.tobacco_use && (
                      <div
                        onClick={() =>
                          this.addNewItem("social_history", "tobacco_use")
                        }
                        className="history-section-add-new"
                      >
                        Add Tobacco Use
                      </div>
                    )}
                    {!this.state.visibility.alcohol_use && (
                      <div
                        onClick={() =>
                          this.addNewItem("social_history", "alcohol_use")
                        }
                        className="history-section-add-new"
                      >
                        Add Alcohol Use
                      </div>
                    )}
                    {!this.state.visibility.drug_use && (
                      <div
                        onClick={() =>
                          this.addNewItem("social_history", "drug_use")
                        }
                        className="history-section-add-new"
                      >
                        Add Recreational Drug Use
                      </div>
                    )}
                    {!this.state.visibility.exercise_use && (
                      <div
                        onClick={() =>
                          this.addNewItem("social_history", "exercise_use")
                        }
                        className="history-section-add-new"
                      >
                        Add Exercise
                      </div>
                    )}
                    {!this.state.visibility.weight && (
                      <div
                        onClick={() =>
                          this.addNewItem("social_history", "weight")
                        }
                        className="history-section-add-new"
                      >
                        Add Weight Gain/Loss
                      </div>
                    )}
                  </div>
                </div>

                <div className="history-section">
                  <div
                    className="history-section-head"
                    onClick={(e) => this.collapseSection(e)}
                  >
                    <h3 className="history-section-title">Allergies</h3>
                    <label className="history-section-checkbox">
                      <input
                        className="history-section-checkbox-input"
                        type="checkbox"
                        name="allergy.check"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        checked={this.state.formData.allergy.check ? 1 : 0}
                      />
                      None
                    </label>
                  </div>
                  {!this.state.formData.allergy.check && (
                    <div className="history-section-body">
                      {this.state.visibility.drugs && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Drug Allergies
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="drugs_true"
                                      className="basic-form-checkbox"
                                      name="allergy.drugs"
                                      type="radio"
                                      checked={
                                        this.state.formData.allergy.drugs
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="drugs_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="drugs_false"
                                      className="basic-form-checkbox"
                                      name="allergy.drugs"
                                      type="radio"
                                      checked={
                                        !this.state.formData.allergy.drugs
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="drugs_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.allergy.drugs &&
                              this.state.copyArr.allergy_drug.map((_, i) => {
                                return (
                                  <div
                                    className="input-fields-row-wrapper"
                                    key={"allergy_drug-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-2-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-5">
                                        <div className="newInputFileldOuter">
                                          <div
                                            className={
                                              this.state.formDataErrors.allergy
                                                .allergy_drug[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_drug[i].medication
                                                ? "newInputLabel danger-text"
                                                : "newInputLabel"
                                            }
                                          >
                                            Medication
                                          </div>
                                          <div className="setting-input-outer">
                                            <CreatableSelect
                                              className={
                                                this.state.formDataErrors
                                                  .allergy.allergy_drug[i] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_drug[i]
                                                  .medication
                                                  ? "autocomplete-select field_error"
                                                  : "autocomplete-select"
                                              }
                                              name={
                                                "allergy.allergy_drug.medication." +
                                                i
                                              }
                                              options={
                                                this.state.formData.allergy
                                                  .allergy_drug[i]
                                                  .medicationsSuggestionList
                                              }
                                              value={
                                                this.state.formData.allergy
                                                  .allergy_drug[i].medication
                                              }
                                              inputValue={
                                                this.state.formData.allergy
                                                  .allergy_drug[i].inputValue
                                              }
                                              onChange={(value, actionMeta) =>
                                                this.handleCreatableSelectChange(
                                                  Object.values(value)[0],
                                                  actionMeta,
                                                  "allergy.allergy_drug.medication",
                                                  i,
                                                )
                                              }
                                              onInputChange={(
                                                value,
                                                actionMeta,
                                              ) =>
                                                this.handleCreatableSelectInputChange(
                                                  value,
                                                  actionMeta,
                                                  "allergy.allergy_drug.medication",
                                                  i,
                                                )
                                              }
                                              onFocus={() =>
                                                this.handleCreatableSelectFocus(
                                                  "allergy.allergy_drug.medication",
                                                  i,
                                                )
                                              }
                                              hideSelectedOptions={true}
                                              placeholder="Enter"
                                              noOptionsMessage={() =>
                                                "No suggestions"
                                              }
                                            />
                                            {this.state.formDataErrors.allergy
                                              .allergy_drug[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_drug[i].medication && (
                                                <label className="input-helper">
                                                  {
                                                    this.state.formDataErrors
                                                      .allergy.allergy_drug[i]
                                                      .medication
                                                  }
                                                </label>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-md-5">
                                        <div className="newInputFileldOuter">
                                          <div
                                            className={
                                              this.state.formDataErrors.allergy
                                                .allergy_drug[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_drug[i].reaction
                                                ? "newInputLabel danger-text"
                                                : "newInputLabel"
                                            }
                                          >
                                            Reaction
                                          </div>
                                          <div className="setting-input-outer">
                                            <input
                                              name={
                                                "allergy.allergy_drug.reaction." +
                                                i
                                              }
                                              type="text"
                                              className={
                                                this.state.formDataErrors
                                                  .allergy.allergy_drug[i] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_drug[i]
                                                  .reaction
                                                  ? "newInputField field_error"
                                                  : "newInputField"
                                              }
                                              maxLength={255}
                                              value={
                                                this.state.formData.allergy
                                                  .allergy_drug[i].reaction
                                              }
                                              placeholder="Enter"
                                              onChange={this.handleInputChange}
                                              autoComplete="off"
                                              data-type="array"
                                            />
                                            {this.state.formDataErrors.allergy
                                              .allergy_drug[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_drug[i].reaction && (
                                                <label className="input-helper">
                                                  {
                                                    this.state.formDataErrors
                                                      .allergy.allergy_drug[i]
                                                      .reaction
                                                  }
                                                </label>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.allergy.drugs ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "allergy",
                                              "allergy_drug",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.allergy.drugs && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "allergy",
                                      "allergy_drug",
                                      formDataAllergyDrugDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Drug Allergy
                                </div>
                                {this.state.formData.allergy.allergy_drug
                                  .length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "allergy.allergy_drug",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.allergy_drug.length ===
                                    2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {this.state.visibility.foods && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Food Allergies
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="foods_true"
                                      className="basic-form-checkbox"
                                      name="allergy.foods"
                                      type="radio"
                                      checked={
                                        this.state.formData.allergy.foods
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="foods_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="foods_false"
                                      className="basic-form-checkbox"
                                      name="allergy.foods"
                                      type="radio"
                                      checked={
                                        !this.state.formData.allergy.foods
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="foods_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.allergy.foods &&
                              this.state.copyArr.allergy_food.map((_, i) => {
                                return (
                                  <div
                                    className="input-fields-row-wrapper"
                                    key={"allergy_food-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-2-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-5">
                                        <div className="newInputFileldOuter">
                                          <div
                                            className={
                                              this.state.formDataErrors.allergy
                                                .allergy_food[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_food[i].food
                                                ? "newInputLabel danger-text"
                                                : "newInputLabel"
                                            }
                                          >
                                            Food
                                          </div>
                                          <div className="setting-input-outer">
                                            <input
                                              name={
                                                "allergy.allergy_food.food." + i
                                              }
                                              type="text"
                                              className={
                                                this.state.formDataErrors
                                                  .allergy.allergy_food[i] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_food[i].food
                                                  ? "newInputField field_error"
                                                  : "newInputField"
                                              }
                                              maxLength={255}
                                              disabled={
                                                !this.state.formData.allergy
                                                  .foods
                                              }
                                              value={
                                                this.state.formData.allergy
                                                  .allergy_food[i].food
                                              }
                                              placeholder="Enter"
                                              onChange={this.handleInputChange}
                                              autoComplete="off"
                                              data-type="array"
                                            />
                                            {this.state.formDataErrors.allergy
                                              .allergy_food[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_food[i].food && (
                                                <label className="input-helper">
                                                  {
                                                    this.state.formDataErrors
                                                      .allergy.allergy_food[i]
                                                      .food
                                                  }
                                                </label>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-md-5">
                                        <div className="newInputFileldOuter">
                                          <div
                                            className={
                                              this.state.formDataErrors.allergy
                                                .allergy_food[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_food[i].reaction
                                                ? "newInputLabel danger-text"
                                                : "newInputLabel"
                                            }
                                          >
                                            Reaction
                                          </div>
                                          <div className="setting-input-outer">
                                            <input
                                              name={
                                                "allergy.allergy_food.reaction." +
                                                i
                                              }
                                              type="text"
                                              className={
                                                this.state.formDataErrors
                                                  .allergy.allergy_food[i] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_food[i]
                                                  .reaction
                                                  ? "newInputField field_error"
                                                  : "newInputField"
                                              }
                                              maxLength={255}
                                              disabled={
                                                !this.state.formData.allergy
                                                  .foods
                                              }
                                              value={
                                                this.state.formData.allergy
                                                  .allergy_food[i].reaction
                                              }
                                              placeholder="Enter"
                                              onChange={this.handleInputChange}
                                              autoComplete="off"
                                              data-type="array"
                                            />
                                            {this.state.formDataErrors.allergy
                                              .allergy_food[i] &&
                                              this.state.formDataErrors.allergy
                                                .allergy_food[i].reaction && (
                                                <label className="input-helper">
                                                  {
                                                    this.state.formDataErrors
                                                      .allergy.allergy_food[i]
                                                      .reaction
                                                  }
                                                </label>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.allergy.foods ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "allergy",
                                              "allergy_food",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.allergy.foods && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "allergy",
                                      "allergy_food",
                                      formDataAllergyFoodDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Food Allergy
                                </div>
                                {this.state.formData.allergy.allergy_food
                                  .length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "allergy.allergy_food",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.allergy_food.length ===
                                    2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {this.state.visibility.environment && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Environmental Allergies
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="environment_true"
                                      className="basic-form-checkbox"
                                      name="allergy.environment"
                                      type="radio"
                                      checked={
                                        this.state.formData.allergy.environment
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="environment_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="environment_false"
                                      className="basic-form-checkbox"
                                      name="allergy.environment"
                                      type="radio"
                                      checked={
                                        !this.state.formData.allergy.environment
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="environment_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.allergy.environment &&
                              this.state.copyArr.allergy_environment.map(
                                (_, i) => {
                                  return (
                                    <div
                                      className="input-fields-row-wrapper"
                                      key={"allergy_environment-" + i}
                                    >
                                      <div
                                        className={
                                          i > 0
                                            ? "input-fields-row input-fields-2-row"
                                            : "input-fields-row"
                                        }
                                      >
                                        <div className="col-sm-12 col-md-5">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .allergy.allergy_environment[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_environment[
                                                  i
                                                ].environment
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Environment
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "allergy.allergy_environment.environment." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .allergy
                                                    .allergy_environment[i] &&
                                                  this.state.formDataErrors
                                                    .allergy
                                                    .allergy_environment[i]
                                                    .environment
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData.allergy
                                                    .environment
                                                }
                                                value={
                                                  this.state.formData.allergy
                                                    .allergy_environment[i]
                                                    .environment
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors.allergy
                                                .allergy_environment[i] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_environment[
                                                  i
                                                ].environment && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .allergy
                                                        .allergy_environment[i]
                                                        .environment
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-12 col-md-5">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .allergy.allergy_environment[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_environment[
                                                  i
                                                ].reaction
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Reaction
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "allergy.allergy_environment.reaction." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .allergy
                                                    .allergy_environment[i] &&
                                                  this.state.formDataErrors
                                                    .allergy
                                                    .allergy_environment[i]
                                                    .reaction
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData.allergy
                                                    .environment
                                                }
                                                value={
                                                  this.state.formData.allergy
                                                    .allergy_environment[i]
                                                    .reaction
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors.allergy
                                                .allergy_environment[i] &&
                                                this.state.formDataErrors
                                                  .allergy.allergy_environment[
                                                  i
                                                ].reaction && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .allergy
                                                        .allergy_environment[i]
                                                        .reaction
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-2">
                                        {i > 0 &&
                                        !!this.state.formData.allergy
                                          .environment ? (
                                          <div
                                            onClick={() =>
                                              this.handleConfirmDeleteItemModal(
                                                true,
                                                "allergy",
                                                "allergy_environment",
                                                i,
                                              )
                                            }
                                            className="history-section-delete"
                                          >
                                            <span className="history-section-delete-text">
                                              Delete
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="history-section-delete history-section-delete-empty" />
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            {!!this.state.formData.allergy.environment && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "allergy",
                                      "allergy_environment",
                                      formDataAllergyEnvironmentDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Environmental Allergy
                                </div>
                                {this.state.formData.allergy.allergy_environment
                                  .length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "allergy.allergy_environment",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.allergy_environment
                                      .length === 2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {!this.state.formData.allergy.check && (
                    <div className="history-section-body">
                      {!this.state.visibility.drugs && (
                        <div
                          onClick={() => {
                            this.addNewItem(
                              "allergy",
                              "drugs",
                              "allergy_drug",
                              formDataAllergyDrugDefault,
                            );
                          }}
                          className="history-section-add-new"
                        >
                          Add Drug Allergies
                        </div>
                      )}
                      {!this.state.visibility.foods && (
                        <div
                          onClick={() => {
                            this.addNewItem(
                              "allergy",
                              "foods",
                              "allergy_food",
                              formDataAllergyFoodDefault,
                            );
                          }}
                          className="history-section-add-new"
                        >
                          Add Food Allergies
                        </div>
                      )}
                      {!this.state.visibility.environment && (
                        <div
                          onClick={() => {
                            this.addNewItem(
                              "allergy",
                              "environment",
                              "allergy_environment",
                              formDataAllergyEnvironmentDefault,
                            );
                          }}
                          className="history-section-add-new"
                        >
                          Add Environmental Allergies
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="history-section">
                  <div
                    className="history-section-head"
                    onClick={(e) => this.collapseSection(e)}
                  >
                    <h3 className="history-section-title">
                      Current Medications
                    </h3>
                    <label className="history-section-checkbox">
                      <input
                        className="history-section-checkbox-input"
                        type="checkbox"
                        name="current_medication.check"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.formData.current_medication.check ? 1 : 0
                        }
                      />
                      None
                    </label>
                  </div>
                  {!this.state.formData.current_medication.check && (
                    <div className="history-section-body">
                      {this.state.visibility.prescription_medications && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Prescription Medications
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="prescription_medications_true"
                                      className="basic-form-checkbox"
                                      name="current_medication.prescription_medications"
                                      type="radio"
                                      checked={
                                        this.state.formData.current_medication
                                          .prescription_medications
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="prescription_medications_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="prescription_medications_false"
                                      className="basic-form-checkbox"
                                      name="current_medication.prescription_medications"
                                      type="radio"
                                      checked={
                                        !this.state.formData.current_medication
                                          .prescription_medications
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="prescription_medications_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.current_medication
                              .prescription_medications &&
                              this.state.copyArr.prescription_medication.map(
                                (_, i) => {
                                  return (
                                    <div
                                      className="input-fields-row-wrapper"
                                      key={"prescription_medication-" + i}
                                    >
                                      <div
                                        className={
                                          i > 0
                                            ? "input-fields-row input-fields-3-row"
                                            : "input-fields-row"
                                        }
                                      >
                                        <div className="col-sm-12 col-md-10">
                                          <div className="input-wrapper-3">
                                            <div className="newInputFileldOuter">
                                              <div
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[
                                                    i
                                                  ] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[i]
                                                    .medication
                                                    ? "newInputLabel danger-text"
                                                    : "newInputLabel"
                                                }
                                              >
                                                Medication
                                              </div>
                                              <div className="setting-input-outer">
                                                <CreatableSelect
                                                  className={
                                                    this.state.formDataErrors
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ] &&
                                                    this.state.formDataErrors
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].medication
                                                      ? "autocomplete-select field_error"
                                                      : "autocomplete-select"
                                                  }
                                                  name={
                                                    "current_medication.prescription_medication.medication." +
                                                    i
                                                  }
                                                  options={
                                                    this.state.formData
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].medicationsSuggestionList
                                                  }
                                                  value={
                                                    this.state.formData
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].medication
                                                  }
                                                  inputValue={
                                                    this.state.formData
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].inputValue
                                                  }
                                                  onChange={(
                                                    value,
                                                    actionMeta,
                                                  ) =>
                                                    this.handleCreatableSelectChange(
                                                      Object.values(value)[0],
                                                      actionMeta,
                                                      "current_medication.prescription_medication.medication",
                                                      i,
                                                    )
                                                  }
                                                  onInputChange={(
                                                    value,
                                                    actionMeta,
                                                  ) =>
                                                    this.handleCreatableSelectInputChange(
                                                      value,
                                                      actionMeta,
                                                      "current_medication.prescription_medication.medication",
                                                      i,
                                                    )
                                                  }
                                                  onFocus={() =>
                                                    this.handleCreatableSelectFocus(
                                                      "current_medication.prescription_medication.medication",
                                                      i,
                                                    )
                                                  }
                                                  hideSelectedOptions={true}
                                                  placeholder="Enter"
                                                  noOptionsMessage={() =>
                                                    "No suggestions"
                                                  }
                                                />
                                                {this.state.formDataErrors
                                                  .current_medication
                                                  .prescription_medication[i] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[i]
                                                    .medication && (
                                                    <label className="input-helper">
                                                      {
                                                        this.state
                                                          .formDataErrors
                                                          .current_medication
                                                          .prescription_medication[
                                                          i
                                                        ].medication
                                                      }
                                                    </label>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="input-wrapper-3">
                                            <div className="newInputFileldOuter">
                                              <div
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[
                                                    i
                                                  ] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[i]
                                                    .dose
                                                    ? "newInputLabel danger-text"
                                                    : "newInputLabel"
                                                }
                                              >
                                                Dose
                                              </div>
                                              <div className="setting-input-outer">
                                                <input
                                                  name={
                                                    "current_medication.prescription_medication.dose." +
                                                    i
                                                  }
                                                  type="text"
                                                  className={
                                                    this.state.formDataErrors
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ] &&
                                                    this.state.formDataErrors
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].dose
                                                      ? "newInputField field_error"
                                                      : "newInputField"
                                                  }
                                                  maxLength={255}
                                                  disabled={
                                                    !this.state.formData
                                                      .current_medication
                                                      .prescription_medications
                                                  }
                                                  value={
                                                    this.state.formData
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].dose
                                                  }
                                                  placeholder="Enter"
                                                  onChange={
                                                    this.handleInputChange
                                                  }
                                                  autoComplete="off"
                                                  data-type="array"
                                                />
                                                {this.state.formDataErrors
                                                  .current_medication
                                                  .prescription_medication[i] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[i]
                                                    .dose && (
                                                    <label className="input-helper">
                                                      {
                                                        this.state
                                                          .formDataErrors
                                                          .current_medication
                                                          .prescription_medication[
                                                          i
                                                        ].dose
                                                      }
                                                    </label>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="input-wrapper-3">
                                            <div className="newInputFileldOuter">
                                              <div
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[
                                                    i
                                                  ] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[i]
                                                    .frequency
                                                    ? "newInputLabel danger-text"
                                                    : "newInputLabel"
                                                }
                                              >
                                                Frequency
                                              </div>
                                              <div className="setting-input-outer">
                                                <input
                                                  name={
                                                    "current_medication.prescription_medication.frequency." +
                                                    i
                                                  }
                                                  type="text"
                                                  className={
                                                    this.state.formDataErrors
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ] &&
                                                    this.state.formDataErrors
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].frequency
                                                      ? "newInputField field_error"
                                                      : "newInputField"
                                                  }
                                                  maxLength={255}
                                                  disabled={
                                                    !this.state.formData
                                                      .current_medication
                                                      .prescription_medications
                                                  }
                                                  value={
                                                    this.state.formData
                                                      .current_medication
                                                      .prescription_medication[
                                                      i
                                                    ].frequency
                                                  }
                                                  placeholder="Enter"
                                                  onChange={
                                                    this.handleInputChange
                                                  }
                                                  autoComplete="off"
                                                  data-type="array"
                                                />
                                                {this.state.formDataErrors
                                                  .current_medication
                                                  .prescription_medication[i] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .prescription_medication[i]
                                                    .frequency && (
                                                    <label className="input-helper">
                                                      {
                                                        this.state
                                                          .formDataErrors
                                                          .current_medication
                                                          .prescription_medication[
                                                          i
                                                        ].frequency
                                                      }
                                                    </label>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-2">
                                        {i > 0 &&
                                        !!this.state.formData.current_medication
                                          .prescription_medications ? (
                                          <div
                                            onClick={() =>
                                              this.handleConfirmDeleteItemModal(
                                                true,
                                                "current_medication",
                                                "prescription_medication",
                                                i,
                                              )
                                            }
                                            className="history-section-delete"
                                          >
                                            <span className="history-section-delete-text">
                                              Delete
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="history-section-delete history-section-delete-empty" />
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            {!!this.state.formData.current_medication
                              .prescription_medications && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "current_medication",
                                      "prescription_medication",
                                      formDataCurrentMedicationDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Add Medications
                                </div>
                                {this.state.formData.current_medication
                                  .prescription_medication.length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "current_medication.prescription_medication",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.prescription_medication
                                      .length === 2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {this.state.visibility.vitamins && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Vitamins & Supplements
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="vitamins_true"
                                      className="basic-form-checkbox"
                                      name="current_medication.vitamins"
                                      type="radio"
                                      checked={
                                        this.state.formData.current_medication
                                          .vitamins
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="vitamins_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="vitamins_false"
                                      className="basic-form-checkbox"
                                      name="current_medication.vitamins"
                                      type="radio"
                                      checked={
                                        !this.state.formData.current_medication
                                          .vitamins
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="vitamins_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.current_medication
                              .vitamins &&
                              this.state.copyArr.vitamin.map((_, i) => {
                                return (
                                  <div
                                    className="input-fields-row-wrapper"
                                    key={"vitamin-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-3-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-10">
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[i]
                                                  .medication
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Medication
                                            </div>
                                            <div className="setting-input-outer">
                                              <CreatableSelect
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication.vitamin[
                                                    i
                                                  ] &&
                                                  this.state.formDataErrors
                                                    .current_medication.vitamin[
                                                    i
                                                  ].medication
                                                    ? "autocomplete-select field_error"
                                                    : "autocomplete-select"
                                                }
                                                name={
                                                  "current_medication.vitamin.medication." +
                                                  i
                                                }
                                                options={
                                                  this.state.formData
                                                    .current_medication.vitamin[
                                                    i
                                                  ].medicationsSuggestionList
                                                }
                                                value={
                                                  this.state.formData
                                                    .current_medication.vitamin[
                                                    i
                                                  ].medication
                                                }
                                                inputValue={
                                                  this.state.formData
                                                    .current_medication.vitamin[
                                                    i
                                                  ].inputValue
                                                }
                                                onChange={(value, actionMeta) =>
                                                  this.handleCreatableSelectChange(
                                                    Object.values(value)[0],
                                                    actionMeta,
                                                    "current_medication.vitamin.medication",
                                                    i,
                                                  )
                                                }
                                                onInputChange={(
                                                  value,
                                                  actionMeta,
                                                ) =>
                                                  this.handleCreatableSelectInputChange(
                                                    value,
                                                    actionMeta,
                                                    "current_medication.vitamin.medication",
                                                    i,
                                                  )
                                                }
                                                onFocus={() =>
                                                  this.handleCreatableSelectFocus(
                                                    "current_medication.vitamin.medication",
                                                    i,
                                                  )
                                                }
                                                hideSelectedOptions={true}
                                                placeholder="Enter"
                                                noOptionsMessage={() =>
                                                  "No suggestions"
                                                }
                                              />
                                              {this.state.formDataErrors
                                                .current_medication.vitamin[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[i]
                                                  .medication && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .current_medication
                                                        .vitamin[i].medication
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[i]
                                                  .dose
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Dose
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "current_medication.vitamin.dose." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication.vitamin[
                                                    i
                                                  ] &&
                                                  this.state.formDataErrors
                                                    .current_medication.vitamin[
                                                    i
                                                  ].dose
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .current_medication.vitamins
                                                }
                                                value={
                                                  this.state.formData
                                                    .current_medication.vitamin[
                                                    i
                                                  ].dose
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .current_medication.vitamin[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[i]
                                                  .dose && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .current_medication
                                                        .vitamin[i].dose
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[i]
                                                  .frequency
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Frequency
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "current_medication.vitamin.frequency." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication.vitamin[
                                                    i
                                                  ] &&
                                                  this.state.formDataErrors
                                                    .current_medication.vitamin[
                                                    i
                                                  ].frequency
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .current_medication.vitamins
                                                }
                                                value={
                                                  this.state.formData
                                                    .current_medication.vitamin[
                                                    i
                                                  ].frequency
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .current_medication.vitamin[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .current_medication.vitamin[i]
                                                  .frequency && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .current_medication
                                                        .vitamin[i].frequency
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.current_medication
                                        .vitamins ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "current_medication",
                                              "vitamin",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.current_medication
                              .vitamins && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "current_medication",
                                      "vitamin",
                                      formDataCurrentMedicationDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Add Vitamins/Supplements
                                </div>
                                {this.state.formData.current_medication.vitamin
                                  .length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "current_medication.vitamin",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.vitamin.length === 2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {this.state.visibility.over_medications && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Over-the-Counter Medications
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="over_medications_true"
                                      className="basic-form-checkbox"
                                      name="current_medication.over_medications"
                                      type="radio"
                                      checked={
                                        this.state.formData.current_medication
                                          .over_medications
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="over_medications_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="over_medications_false"
                                      className="basic-form-checkbox"
                                      name="current_medication.over_medications"
                                      type="radio"
                                      checked={
                                        !this.state.formData.current_medication
                                          .over_medications
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="over_medications_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.current_medication
                              .over_medications &&
                              this.state.copyArr.over_medication.map((_, i) => {
                                return (
                                  <div
                                    className="input-fields-row-wrapper"
                                    key={"over_medication-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-3-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-10">
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i] &&
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i].medication
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Medication
                                            </div>
                                            <div className="setting-input-outer">
                                              <CreatableSelect
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .over_medication[i] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .over_medication[i]
                                                    .medication
                                                    ? "autocomplete-select field_error"
                                                    : "autocomplete-select"
                                                }
                                                name={
                                                  "current_medication.over_medication.medication." +
                                                  i
                                                }
                                                options={
                                                  this.state.formData
                                                    .current_medication
                                                    .over_medication[i]
                                                    .medicationsSuggestionList
                                                }
                                                value={
                                                  this.state.formData
                                                    .current_medication
                                                    .over_medication[i]
                                                    .medication
                                                }
                                                inputValue={
                                                  this.state.formData
                                                    .current_medication
                                                    .over_medication[i]
                                                    .inputValue
                                                }
                                                onChange={(value, actionMeta) =>
                                                  this.handleCreatableSelectChange(
                                                    Object.values(value)[0],
                                                    actionMeta,
                                                    "current_medication.over_medication.medication",
                                                    i,
                                                  )
                                                }
                                                onInputChange={(
                                                  value,
                                                  actionMeta,
                                                ) =>
                                                  this.handleCreatableSelectInputChange(
                                                    value,
                                                    actionMeta,
                                                    "current_medication.over_medication.medication",
                                                    i,
                                                  )
                                                }
                                                onFocus={() =>
                                                  this.handleCreatableSelectFocus(
                                                    "current_medication.over_medication.medication",
                                                    i,
                                                  )
                                                }
                                                hideSelectedOptions={true}
                                                placeholder="Enter"
                                                noOptionsMessage={() =>
                                                  "No suggestions"
                                                }
                                              />
                                              {this.state.formDataErrors
                                                .current_medication
                                                .over_medication[i] &&
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i]
                                                  .medication && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .current_medication
                                                        .over_medication[i]
                                                        .medication
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i] &&
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i].dose
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Dose
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "current_medication.over_medication.dose." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .over_medication[i] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .over_medication[i].dose
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .current_medication
                                                    .over_medications
                                                }
                                                value={
                                                  this.state.formData
                                                    .current_medication
                                                    .over_medication[i].dose
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .current_medication
                                                .over_medication[i] &&
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i].dose && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .current_medication
                                                        .over_medication[i].dose
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i] &&
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i].frequency
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Frequency
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "current_medication.over_medication.frequency." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .over_medication[i] &&
                                                  this.state.formDataErrors
                                                    .current_medication
                                                    .over_medication[i]
                                                    .frequency
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .current_medication
                                                    .over_medications
                                                }
                                                value={
                                                  this.state.formData
                                                    .current_medication
                                                    .over_medication[i]
                                                    .frequency
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .current_medication
                                                .over_medication[i] &&
                                                this.state.formDataErrors
                                                  .current_medication
                                                  .over_medication[i]
                                                  .frequency && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .current_medication
                                                        .over_medication[i]
                                                        .frequency
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.current_medication
                                        .over_medications ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "current_medication",
                                              "over_medication",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.current_medication
                              .over_medications && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "current_medication",
                                      "over_medication",
                                      formDataCurrentMedicationDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Add OTC Medications
                                </div>
                                {this.state.formData.current_medication
                                  .over_medication.length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "current_medication.over_medication",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.over_medication
                                      .length === 2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {!this.state.formData.current_medication.check && (
                    <div className="history-section-body">
                      {!this.state.visibility.prescription_medications && (
                        <div
                          onClick={() =>
                            this.addNewItem(
                              "current_medication",
                              "prescription_medications",
                              "prescription_medication",
                              formDataCurrentMedicationDefault,
                            )
                          }
                          className="history-section-add-new"
                        >
                          Add Prescriptions/Medications
                        </div>
                      )}
                      {!this.state.visibility.vitamins && (
                        <div
                          onClick={() =>
                            this.addNewItem(
                              "current_medication",
                              "vitamins",
                              "vitamin",
                              formDataCurrentMedicationDefault,
                            )
                          }
                          className="history-section-add-new"
                        >
                          Add Vitamins/Supplements
                        </div>
                      )}
                      {!this.state.visibility.over_medications && (
                        <div
                          onClick={() =>
                            this.addNewItem(
                              "current_medication",
                              "over_medications",
                              "over_medication",
                              formDataCurrentMedicationDefault,
                            )
                          }
                          className="history-section-add-new"
                        >
                          Add OTC Medications
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="history-section">
                  <div
                    className="history-section-head"
                    onClick={(e) => this.collapseSection(e)}
                  >
                    <h3 className="history-section-title">
                      Family Health History
                    </h3>
                    <label className="history-section-checkbox">
                      <input
                        className="history-section-checkbox-input"
                        type="checkbox"
                        name="family_health_history.check"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.formData.family_health_history.check
                            ? 1
                            : 0
                        }
                      />
                      None
                    </label>
                  </div>
                  {!this.state.formData.family_health_history.check && (
                    <div className="history-section-body">
                      {this.state.visibility.medical_issues && (
                        <div className="row">
                          {this.state.copyArr.medical_issues.map((_, i) => {
                            return (
                              <div
                                className="input-fields-row-wrapper"
                                key={"medical_issues-" + i}
                              >
                                <div
                                  className={
                                    i > 0
                                      ? "input-fields-row input-fields-2-row"
                                      : "input-fields-row"
                                  }
                                >
                                  <div className="col-sm-12 col-md-5">
                                    <div className="newInputFileldOuter">
                                      <div
                                        className={
                                          this.state.formDataErrors
                                            .family_health_history
                                            .medical_issues[i] &&
                                          this.state.formDataErrors
                                            .family_health_history
                                            .medical_issues[i].diagnosis
                                            ? "newInputLabel danger-text"
                                            : "newInputLabel"
                                        }
                                      >
                                        Diagnosis
                                      </div>
                                      <div className="setting-input-outer">
                                        <input
                                          name={
                                            "family_health_history.medical_issues.diagnosis." +
                                            i
                                          }
                                          type="text"
                                          className={
                                            this.state.formDataErrors
                                              .family_health_history
                                              .medical_issues[i] &&
                                            this.state.formDataErrors
                                              .family_health_history
                                              .medical_issues[i].diagnosis
                                              ? "newInputField field_error"
                                              : "newInputField"
                                          }
                                          maxLength={255}
                                          value={
                                            this.state.formData
                                              .family_health_history
                                              .medical_issues[i].diagnosis
                                          }
                                          placeholder="Enter"
                                          onChange={this.handleInputChange}
                                          autoComplete="off"
                                          data-type="array"
                                        />
                                        {this.state.formDataErrors
                                          .family_health_history.medical_issues[
                                          i
                                        ] &&
                                          this.state.formDataErrors
                                            .family_health_history
                                            .medical_issues[i].diagnosis && (
                                            <label className="input-helper">
                                              {
                                                this.state.formDataErrors
                                                  .family_health_history
                                                  .medical_issues[i].diagnosis
                                              }
                                            </label>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-5">
                                    <div className="newInputFileldOuter">
                                      <div
                                        className={
                                          this.state.formDataErrors
                                            .family_health_history
                                            .medical_issues[i] &&
                                          this.state.formDataErrors
                                            .family_health_history
                                            .medical_issues[i].relationship
                                            ? "newInputLabel danger-text"
                                            : "newInputLabel"
                                        }
                                      >
                                        Relationship to Patient
                                      </div>
                                      <div className="setting-input-outer">
                                        <input
                                          name={
                                            "family_health_history.medical_issues.relationship." +
                                            i
                                          }
                                          type="text"
                                          className={
                                            this.state.formDataErrors
                                              .family_health_history
                                              .medical_issues[i] &&
                                            this.state.formDataErrors
                                              .family_health_history
                                              .medical_issues[i].relationship
                                              ? "newInputField field_error"
                                              : "newInputField"
                                          }
                                          maxLength={255}
                                          value={
                                            this.state.formData
                                              .family_health_history
                                              .medical_issues[i].relationship
                                          }
                                          placeholder="Enter"
                                          onChange={this.handleInputChange}
                                          autoComplete="off"
                                          data-type="array"
                                        />
                                        {this.state.formDataErrors
                                          .family_health_history.medical_issues[
                                          i
                                        ] &&
                                          this.state.formDataErrors
                                            .family_health_history
                                            .medical_issues[i].relationship && (
                                            <label className="input-helper">
                                              {
                                                this.state.formDataErrors
                                                  .family_health_history
                                                  .medical_issues[i]
                                                  .relationship
                                              }
                                            </label>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  {i > 0 &&
                                  !this.state.formData.family_health_history
                                    .check ? (
                                    <div
                                      onClick={() =>
                                        this.handleConfirmDeleteItemModal(
                                          true,
                                          "family_health_history",
                                          "medical_issues",
                                          i,
                                        )
                                      }
                                      className="history-section-delete"
                                    >
                                      <span className="history-section-delete-text">
                                        Delete
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="history-section-delete history-section-delete-empty" />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          {!this.state.formData.family_health_history.check && (
                            <div className="col-sm-10">
                              <div
                                onClick={() =>
                                  this.addNewItemRow(
                                    "family_health_history",
                                    "medical_issues",
                                    formDataMedicalIssuesDefault,
                                  )
                                }
                                className="history-section-row-add-new"
                              >
                                Add Medical Issue
                              </div>
                              {this.state.formData.family_health_history
                                .medical_issues.length >= 3 && (
                                <div
                                  className="history-section-row-expand"
                                  onClick={() =>
                                    this.toggleListLength(
                                      "family_health_history.medical_issues",
                                    )
                                  }
                                >
                                  {this.state.copyArr.medical_issues.length ===
                                  2
                                    ? "show more"
                                    : "show less"}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {!this.state.formData.family_health_history.check && (
                    <div className="history-section-body">
                      {!this.state.visibility.medical_issues &&
                        !this.state.formData.family_health_history.check && (
                          <div
                            onClick={() => {
                              this.addNewItemRow(
                                "family_health_history",
                                "medical_issues",
                                formDataMedicalIssuesDefault,
                              );
                              this.setState({
                                visibility: {
                                  ...this.state.visibility,
                                  medical_issues: true,
                                },
                              });
                            }}
                            className="history-section-add-new"
                          >
                            Add Medical Issue
                          </div>
                        )}
                    </div>
                  )}
                </div>

                <div className="history-section">
                  <div
                    className="history-section-head"
                    onClick={(e) => this.collapseSection(e)}
                  >
                    <h3 className="history-section-title">
                      Current Medical Conditions
                    </h3>
                    <label className="history-section-checkbox">
                      <input
                        className="history-section-checkbox-input"
                        type="checkbox"
                        name="current_medical_history.check"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.formData.current_medical_history.check
                            ? 1
                            : 0
                        }
                      />
                      None
                    </label>
                  </div>
                  {!this.state.formData.current_medical_history.check && (
                    <div className="history-section-body">
                      {this.state.visibility.pregnancy && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-10">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Pregnant
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="pregnancy_true"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.pregnancy"
                                      type="radio"
                                      checked={
                                        this.state.formData
                                          .current_medical_history.pregnancy
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="pregnancy_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="pregnancy_false"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.pregnancy"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .current_medical_history.pregnancy
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="pregnancy_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-10">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Breastfeeding
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="breastfeeding_true"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.breastfeeding"
                                      type="radio"
                                      checked={
                                        this.state.formData
                                          .current_medical_history.breastfeeding
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="breastfeeding_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="breastfeeding_false"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.breastfeeding"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .current_medical_history.breastfeeding
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="breastfeeding_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {this.state.visibility.ongoing_conditions && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Ongoing Medical Conditions or Diagnosis
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="ongoing_conditions_true"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.ongoing_conditions"
                                      type="radio"
                                      checked={
                                        this.state.formData
                                          .current_medical_history
                                          .ongoing_conditions
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="ongoing_conditions_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="ongoing_conditions_false"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.ongoing_conditions"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .current_medical_history
                                          .ongoing_conditions
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="ongoing_conditions_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.current_medical_history
                              .ongoing_conditions &&
                              this.state.copyArr.ongoing_condition.map(
                                (_, i) => {
                                  return (
                                    <div
                                      className="input-fields-row-wrapper"
                                      key={"ongoing_condition-" + i}
                                    >
                                      <div
                                        className={
                                          i > 0
                                            ? "input-fields-row input-fields-3-row"
                                            : "input-fields-row"
                                        }
                                      >
                                        <div className="col-sm-12 col-md-10">
                                          <div className="input-wrapper-3">
                                            <div className="newInputFileldOuter">
                                              <div
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i] &&
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i].type
                                                    ? "newInputLabel danger-text"
                                                    : "newInputLabel"
                                                }
                                              >
                                                Condition/Diagnosis Type
                                              </div>
                                              <div className="setting-input-outer">
                                                <input
                                                  name={
                                                    "current_medical_history.ongoing_condition.type." +
                                                    i
                                                  }
                                                  type="text"
                                                  className={
                                                    this.state.formDataErrors
                                                      .current_medical_history
                                                      .ongoing_condition[i] &&
                                                    this.state.formDataErrors
                                                      .current_medical_history
                                                      .ongoing_condition[i].type
                                                      ? "newInputField field_error"
                                                      : "newInputField"
                                                  }
                                                  maxLength={255}
                                                  disabled={
                                                    !this.state.formData
                                                      .current_medical_history
                                                      .ongoing_conditions
                                                  }
                                                  value={
                                                    this.state.formData
                                                      .current_medical_history
                                                      .ongoing_condition[i].type
                                                  }
                                                  placeholder="Enter"
                                                  onChange={
                                                    this.handleInputChange
                                                  }
                                                  autoComplete="off"
                                                  data-type="array"
                                                />
                                                {this.state.formDataErrors
                                                  .current_medical_history
                                                  .ongoing_condition[i] &&
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i]
                                                    .type && (
                                                    <label className="input-helper">
                                                      {
                                                        this.state
                                                          .formDataErrors
                                                          .current_medical_history
                                                          .ongoing_condition[i]
                                                          .type
                                                      }
                                                    </label>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="input-wrapper-3">
                                            <div className="newInputFileldOuter">
                                              <div
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i] &&
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i].date
                                                    ? "newInputLabel danger-text"
                                                    : "newInputLabel"
                                                }
                                              >
                                                Date of Diagnosis
                                              </div>
                                              <div className="setting-input-outer input-autocomplete">
                                                <DatePicker
                                                  className={
                                                    this.state.formDataErrors
                                                      .current_medical_history
                                                      .ongoing_condition[i] &&
                                                    this.state.formDataErrors
                                                      .current_medical_history
                                                      .ongoing_condition[i].date
                                                      ? "newInputField field_error"
                                                      : "newInputField"
                                                  }
                                                  selected={
                                                    this.state.formData
                                                      .current_medical_history
                                                      .ongoing_condition[i]
                                                      .date &&
                                                    new Date(
                                                      moment(
                                                        this.state.formData
                                                          .current_medical_history
                                                          .ongoing_condition[i]
                                                          .date,
                                                        "YYYY-MM-DD hh:mm:ss",
                                                      ).format(),
                                                    )
                                                  }
                                                  onChange={(date) =>
                                                    this.handleInputChange(
                                                      date,
                                                      "current_medical_history.ongoing_condition.date",
                                                      i,
                                                    )
                                                  }
                                                  onChangeRaw={(event) =>
                                                    this.handleDatepickerChangeRaw(
                                                      event.target.value,
                                                      "current_medical_history.ongoing_condition.date",
                                                      i,
                                                    )
                                                  }
                                                  onClickOutside={this.onClickOutside.bind(
                                                    this,
                                                    `ongoingConditionDate-${i}`,
                                                  )}
                                                  ref={`ongoingConditionDate-${i}`}
                                                  dateFormat="MM/dd/yyyy"
                                                  showYearDropdown
                                                  scrollableYearDropdown
                                                  yearDropdownItemNumber={100}
                                                  maxDate={new Date()}
                                                  disabled={
                                                    !this.state.formData
                                                      .current_medical_history
                                                      .ongoing_conditions
                                                  }
                                                  placeholderText="Enter"
                                                  data-type="array"
                                                  autoComplete="off"
                                                />
                                                {this.state.formDataErrors
                                                  .current_medical_history
                                                  .ongoing_condition[i] &&
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i]
                                                    .date && (
                                                    <label className="input-helper">
                                                      {
                                                        this.state
                                                          .formDataErrors
                                                          .current_medical_history
                                                          .ongoing_condition[i]
                                                          .date
                                                      }
                                                    </label>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="input-wrapper-3">
                                            <div className="newInputFileldOuter">
                                              <div
                                                className={
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i] &&
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i].name
                                                    ? "newInputLabel danger-text"
                                                    : "newInputLabel"
                                                }
                                              >
                                                Name of Treating Physician
                                              </div>
                                              <div className="setting-input-outer">
                                                <input
                                                  name={
                                                    "current_medical_history.ongoing_condition.name." +
                                                    i
                                                  }
                                                  type="text"
                                                  className={
                                                    this.state.formDataErrors
                                                      .current_medical_history
                                                      .ongoing_condition[i] &&
                                                    this.state.formDataErrors
                                                      .current_medical_history
                                                      .ongoing_condition[i].name
                                                      ? "newInputField field_error"
                                                      : "newInputField"
                                                  }
                                                  maxLength={255}
                                                  disabled={
                                                    !this.state.formData
                                                      .current_medical_history
                                                      .ongoing_conditions
                                                  }
                                                  value={
                                                    this.state.formData
                                                      .current_medical_history
                                                      .ongoing_condition[i].name
                                                  }
                                                  placeholder="Enter"
                                                  onChange={
                                                    this.handleInputChange
                                                  }
                                                  autoComplete="off"
                                                  data-type="array"
                                                />
                                                {this.state.formDataErrors
                                                  .current_medical_history
                                                  .ongoing_condition[i] &&
                                                  this.state.formDataErrors
                                                    .current_medical_history
                                                    .ongoing_condition[i]
                                                    .name && (
                                                    <label className="input-helper">
                                                      {
                                                        this.state
                                                          .formDataErrors
                                                          .current_medical_history
                                                          .ongoing_condition[i]
                                                          .name
                                                      }
                                                    </label>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-2">
                                        {i > 0 &&
                                        !this.state.formData
                                          .current_medical_history.check &&
                                        !!this.state.formData
                                          .current_medical_history
                                          .ongoing_conditions ? (
                                          <div
                                            onClick={() =>
                                              this.handleConfirmDeleteItemModal(
                                                true,
                                                "current_medical_history",
                                                "ongoing_condition",
                                                i,
                                              )
                                            }
                                            className="history-section-delete"
                                          >
                                            <span className="history-section-delete-text">
                                              Delete
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="history-section-delete history-section-delete-empty" />
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            {!this.state.formData.current_medical_history
                              .check &&
                              !!this.state.formData.current_medical_history
                                .ongoing_conditions && (
                                <div className="col-sm-10">
                                  <div
                                    onClick={() =>
                                      this.addNewItemRow(
                                        "current_medical_history",
                                        "ongoing_condition",
                                        formDataOngoingConditionDefault,
                                      )
                                    }
                                    className="history-section-row-add-new"
                                  >
                                    Add Condition/Diagnosis
                                  </div>
                                  {this.state.formData.current_medical_history
                                    .ongoing_condition.length >= 3 && (
                                    <div
                                      className="history-section-row-expand"
                                      onClick={() =>
                                        this.toggleListLength(
                                          "current_medical_history.ongoing_condition",
                                        )
                                      }
                                    >
                                      {this.state.copyArr.ongoing_condition
                                        .length === 2
                                        ? "show more"
                                        : "show less"}
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </>
                      )}
                      {this.state.visibility.nutrition && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Dietary Considerations
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="nutrition_true"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.nutrition"
                                      type="radio"
                                      checked={
                                        this.state.formData
                                          .current_medical_history.nutrition
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="nutrition_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="nutrition_false"
                                      className="basic-form-checkbox"
                                      name="current_medical_history.nutrition"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .current_medical_history.nutrition
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="nutrition_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.current_medical_history
                              .nutrition && (
                              <div className="col-sm-10">
                                <div className="newInputFileldOuter">
                                  <div
                                    className={
                                      this.state.formDataErrors
                                        .current_medical_history
                                        .nutrition_history
                                        ? "newInputLabel danger-text"
                                        : "newInputLabel"
                                    }
                                  >
                                    Considerations
                                  </div>
                                  <div className="setting-input-outer">
                                    <input
                                      name="current_medical_history.nutrition_history"
                                      type="text"
                                      className={
                                        this.state.formDataErrors
                                          .current_medical_history
                                          .nutrition_history
                                          ? "newInputField field_error"
                                          : "newInputField"
                                      }
                                      maxLength={255}
                                      disabled={
                                        !this.state.formData
                                          .current_medical_history.nutrition
                                      }
                                      value={
                                        this.state.formData
                                          .current_medical_history
                                          .nutrition_history
                                      }
                                      placeholder="Enter"
                                      onChange={this.handleInputChange}
                                      autoComplete="off"
                                    />
                                    {this.state.formDataErrors
                                      .current_medical_history
                                      .nutrition_history && (
                                      <label className="input-helper">
                                        {
                                          this.state.formDataErrors
                                            .current_medical_history
                                            .nutrition_history
                                        }
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {!this.state.formData.current_medical_history.check && (
                    <div className="history-section-body">
                      {!this.state.visibility.pregnancy &&
                        !this.state.formData.current_medical_history.check && (
                          <div
                            onClick={() =>
                              this.addNewItem(
                                "current_medical_history",
                                "pregnancy",
                              )
                            }
                            className="history-section-add-new"
                          >
                            Pregnancy
                          </div>
                        )}
                      {!this.state.visibility.ongoing_conditions &&
                        !this.state.formData.current_medical_history.check && (
                          <div
                            onClick={() =>
                              this.addNewItem(
                                "current_medical_history",
                                "ongoing_conditions",
                                "ongoing_condition",
                                {
                                  type: "",
                                  date: "",
                                  name: "",
                                },
                              )
                            }
                            className="history-section-add-new"
                          >
                            Add Ongoing Medical Conditions or Diagnosis
                          </div>
                        )}
                      {!this.state.visibility.nutrition &&
                        !this.state.formData.current_medical_history.check && (
                          <div
                            onClick={() =>
                              this.addNewItem(
                                "current_medical_history",
                                "nutrition",
                              )
                            }
                            className="history-section-add-new"
                          >
                            Add Dietary Considerations
                          </div>
                        )}
                    </div>
                  )}
                </div>

                <div className="history-section">
                  <div
                    className="history-section-head"
                    onClick={(e) => this.collapseSection(e)}
                  >
                    <h3 className="history-section-title">
                      Past Medical History
                    </h3>
                    <label className="history-section-checkbox">
                      <input
                        className="history-section-checkbox-input"
                        type="checkbox"
                        name="past_medical_history.check"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.formData.past_medical_history.check ? 1 : 0
                        }
                      />
                      None
                    </label>
                  </div>
                  <div className="history-section-body">
                    {!this.state.formData.past_medical_history.check &&
                      this.state.visibility.surgeries && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Surgeries
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="surgeries_true"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.surgeries"
                                      type="radio"
                                      checked={
                                        this.state.formData.past_medical_history
                                          .surgeries
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="surgeries_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="surgeries_false"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.surgeries"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .past_medical_history.surgeries
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="surgeries_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.past_medical_history
                              .surgeries &&
                              this.state.copyArr.surgery.map((_, i) => {
                                return (
                                  <div
                                    className="history-section-surgery-row input-fields-row-wrapper"
                                    key={"surgery-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-5-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-10">
                                        <div className="input-wrapper-5">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].type
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Type of Surgery
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.surgery.type." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i].type
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .surgeries
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .surgery[i].type
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.surgery[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].type && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .surgery[i].type
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-5">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].date
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Date of Surgery
                                            </div>
                                            <div className="setting-input-outer input-autocomplete">
                                              <DatePicker
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i].date
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                selected={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .surgery[i].date &&
                                                  new Date(
                                                    moment(
                                                      this.state.formData
                                                        .past_medical_history
                                                        .surgery[i].date,
                                                      "YYYY-MM-DD hh:mm:ss",
                                                    ).format(),
                                                  )
                                                }
                                                onChange={(date) =>
                                                  this.handleInputChange(
                                                    date,
                                                    "past_medical_history.surgery.date",
                                                    i,
                                                  )
                                                }
                                                onChangeRaw={(event) =>
                                                  this.handleDatepickerChangeRaw(
                                                    event.target.value,
                                                    "past_medical_history.surgery.date",
                                                    i,
                                                  )
                                                }
                                                onClickOutside={this.onClickOutside.bind(
                                                  this,
                                                  `surgery-${i}`,
                                                )}
                                                ref={`surgery-${i}`}
                                                dateFormat="MM/dd/yyyy"
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .surgeries
                                                }
                                                placeholderText="Enter"
                                                data-type="array"
                                                autoComplete="off"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.surgery[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].date && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .surgery[i].date
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-5">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].physician
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Physician
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.surgery.physician." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i].physician
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .surgeries
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .surgery[i].physician
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.surgery[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].physician && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .surgery[i].physician
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-5">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].city
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              City
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.surgery.city." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i].city
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .surgeries
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .surgery[i].city
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.surgery[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].city && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .surgery[i].city
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-5">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].state
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              State
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.surgery.state." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .surgery[i].state
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .surgeries
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .surgery[i].state
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.surgery[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.surgery[
                                                  i
                                                ].state && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .surgery[i].state
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.past_medical_history
                                        .surgeries ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "past_medical_history",
                                              "surgery",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.past_medical_history
                              .surgeries && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "past_medical_history",
                                      "surgery",
                                      formDataSurgeryDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Add Surgery
                                </div>
                                {this.state.formData.past_medical_history
                                  .surgery.length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "past_medical_history.surgery",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.surgery.length === 2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    {!this.state.formData.past_medical_history.check &&
                      this.state.visibility.hospitalizations && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Hospitalizations
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="hospitalizations_true"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.hospitalizations"
                                      type="radio"
                                      checked={
                                        this.state.formData.past_medical_history
                                          .hospitalizations
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="hospitalizations_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="hospitalizations_false"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.hospitalizations"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .past_medical_history.hospitalizations
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="hospitalizations_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.past_medical_history
                              .hospitalizations &&
                              this.state.copyArr.hospitalization.map((_, i) => {
                                return (
                                  <div
                                    className="input-fields-row-wrapper"
                                    key={"hospitalization-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-3-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-10">
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i] &&
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i].reason
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Reason for Hospitalization
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.hospitalization.reason." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .hospitalization[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .hospitalization[i].reason
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .hospitalizations
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .hospitalization[i].reason
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history
                                                .hospitalization[i] &&
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i]
                                                  .reason && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .hospitalization[i]
                                                        .reason
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i] &&
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i].date
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Date of Hospitalization
                                            </div>
                                            <div className="setting-input-outer input-autocomplete">
                                              <DatePicker
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .hospitalization[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .hospitalization[i].date
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                selected={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .hospitalization[i].date
                                                    ? new Date(
                                                        moment(
                                                          this.state.formData
                                                            .past_medical_history
                                                            .hospitalization[i]
                                                            .date,
                                                          "YYYY-MM-DD hh:mm:ss",
                                                        ).format(),
                                                      )
                                                    : null
                                                }
                                                onChange={(date) =>
                                                  this.handleInputChange(
                                                    date,
                                                    "past_medical_history.hospitalization.date",
                                                    i,
                                                  )
                                                }
                                                onChangeRaw={(event) =>
                                                  this.handleDatepickerChangeRaw(
                                                    event.target.value,
                                                    "past_medical_history.hospitalization.date",
                                                    i,
                                                  )
                                                }
                                                onClickOutside={this.onClickOutside.bind(
                                                  this,
                                                  `hospitalization-${i}`,
                                                )}
                                                ref={`hospitalization-${i}`}
                                                dateFormat="MM/dd/yyyy"
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .hospitalizations
                                                }
                                                placeholderText="Enter"
                                                data-type="array"
                                                autoComplete="off"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history
                                                .hospitalization[i] &&
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i].date && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .hospitalization[i].date
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i] &&
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i].hospital
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Hospital
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.hospitalization.hospital." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .hospitalization[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .hospitalization[i].hospital
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .hospitalizations
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .hospitalization[i].hospital
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history
                                                .hospitalization[i] &&
                                                this.state.formDataErrors
                                                  .past_medical_history
                                                  .hospitalization[i]
                                                  .hospital && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .hospitalization[i]
                                                        .hospital
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.past_medical_history
                                        .hospitalizations ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "past_medical_history",
                                              "hospitalization",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.past_medical_history
                              .hospitalizations && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "past_medical_history",
                                      "hospitalization",
                                      formDataHospitalizationDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Add Hospitalization
                                </div>
                                {this.state.formData.past_medical_history
                                  .hospitalization.length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "past_medical_history.hospitalization",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.hospitalization
                                      .length === 2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    {!this.state.formData.past_medical_history.check &&
                      this.state.visibility.implants && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Implants
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="implants_true"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.implants"
                                      type="radio"
                                      checked={
                                        this.state.formData.past_medical_history
                                          .implants
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="implants_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="implants_false"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.implants"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .past_medical_history.implants
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="implants_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.past_medical_history
                              .implants &&
                              this.state.copyArr.implant.map((_, i) => {
                                return (
                                  <div
                                    className="input-fields-row-wrapper"
                                    key={"implant-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-3-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-10">
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ].date
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Date of Implant
                                            </div>
                                            <div className="setting-input-outer input-autocomplete">
                                              <DatePicker
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .implant[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .implant[i].date
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                selected={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .implant[i].date &&
                                                  new Date(
                                                    moment(
                                                      this.state.formData
                                                        .past_medical_history
                                                        .implant[i].date,
                                                      "YYYY-MM-DD hh:mm:ss",
                                                    ).format(),
                                                  )
                                                }
                                                onChange={(date) =>
                                                  this.handleInputChange(
                                                    date,
                                                    "past_medical_history.implant.date",
                                                    i,
                                                  )
                                                }
                                                onChangeRaw={(event) =>
                                                  this.handleDatepickerChangeRaw(
                                                    event.target.value,
                                                    "past_medical_history.implant.date",
                                                    i,
                                                  )
                                                }
                                                onClickOutside={this.onClickOutside.bind(
                                                  this,
                                                  `implant-${i}`,
                                                )}
                                                ref={`implant-${i}`}
                                                dateFormat="MM/dd/yyyy"
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .implants
                                                }
                                                placeholderText="Enter"
                                                data-type="array"
                                                autoComplete="off"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.implant[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ].date && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .implant[i].date
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ].type
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Type of Implant
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.implant.type." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .implant[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .implant[i].type
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .implants
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .implant[i].type
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.implant[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ].type && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .implant[i].type
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="input-wrapper-3">
                                          <div className="newInputFileldOuter">
                                            <div
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ].place
                                                  ? "newInputLabel danger-text"
                                                  : "newInputLabel"
                                              }
                                            >
                                              Where is this implant placed?
                                            </div>
                                            <div className="setting-input-outer">
                                              <input
                                                name={
                                                  "past_medical_history.implant.place." +
                                                  i
                                                }
                                                type="text"
                                                className={
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .implant[i] &&
                                                  this.state.formDataErrors
                                                    .past_medical_history
                                                    .implant[i].place
                                                    ? "newInputField field_error"
                                                    : "newInputField"
                                                }
                                                maxLength={255}
                                                disabled={
                                                  !this.state.formData
                                                    .past_medical_history
                                                    .implants
                                                }
                                                value={
                                                  this.state.formData
                                                    .past_medical_history
                                                    .implant[i].place
                                                }
                                                placeholder="Enter"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                autoComplete="off"
                                                data-type="array"
                                              />
                                              {this.state.formDataErrors
                                                .past_medical_history.implant[
                                                i
                                              ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.implant[
                                                  i
                                                ].place && (
                                                  <label className="input-helper">
                                                    {
                                                      this.state.formDataErrors
                                                        .past_medical_history
                                                        .implant[i].place
                                                    }
                                                  </label>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.past_medical_history
                                        .implants ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "past_medical_history",
                                              "implant",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.past_medical_history
                              .implants && (
                              <div className="col-sm-10">
                                <div
                                  onClick={() =>
                                    this.addNewItemRow(
                                      "past_medical_history",
                                      "implant",
                                      formDataImplantDefault,
                                    )
                                  }
                                  className="history-section-row-add-new"
                                >
                                  Add Implants
                                </div>
                                {this.state.formData.past_medical_history
                                  .implant.length >= 3 && (
                                  <div
                                    className="history-section-row-expand"
                                    onClick={() =>
                                      this.toggleListLength(
                                        "past_medical_history.implant",
                                      )
                                    }
                                  >
                                    {this.state.copyArr.implant.length === 2
                                      ? "show more"
                                      : "show less"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    {!this.state.formData.past_medical_history.check &&
                      this.state.visibility.vaccines && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 m-b-5">
                              <div className="history-section-radios-wrapper">
                                <h3 className="history-section-radio-title">
                                  Vaccines
                                </h3>
                                <div className="basic-checkbox-outer-wrapper">
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="vaccines_true"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.vaccines"
                                      type="radio"
                                      checked={
                                        this.state.formData.past_medical_history
                                          .vaccines
                                      }
                                      value={1}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="vaccines_true"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="basic-checkbox-outer">
                                    <input
                                      id="vaccines_false"
                                      className="basic-form-checkbox"
                                      name="past_medical_history.vaccines"
                                      type="radio"
                                      checked={
                                        !this.state.formData
                                          .past_medical_history.vaccines
                                      }
                                      value={0}
                                      onChange={this.handleInputChange}
                                    />
                                    <label
                                      className="basic-form-text"
                                      htmlFor="vaccines_false"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {!!this.state.formData.past_medical_history
                              .vaccines &&
                              this.state.copyArr.vaccine.map((_, i) => {
                                return (
                                  <div
                                    className="input-fields-row-wrapper"
                                    key={"medical_issues-" + i}
                                  >
                                    <div
                                      className={
                                        i > 0
                                          ? "input-fields-row input-fields-2-row"
                                          : "input-fields-row"
                                      }
                                    >
                                      <div className="col-sm-12 col-md-5">
                                        <div className="newInputFileldOuter">
                                          <div
                                            className={
                                              this.state.formDataErrors
                                                .past_medical_history.vaccine[
                                                i
                                              ] &&
                                              this.state.formDataErrors
                                                .past_medical_history.vaccine[i]
                                                .vaccine
                                                ? "newInputLabel danger-text"
                                                : "newInputLabel"
                                            }
                                          >
                                            Vaccine
                                          </div>
                                          <div className="setting-input-outer">
                                            <input
                                              name={
                                                "past_medical_history.vaccine.vaccine." +
                                                i
                                              }
                                              type="text"
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.vaccine[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.vaccine[
                                                  i
                                                ].vaccine
                                                  ? "newInputField field_error"
                                                  : "newInputField"
                                              }
                                              maxLength={255}
                                              value={
                                                this.state.formData
                                                  .past_medical_history.vaccine[
                                                  i
                                                ].vaccine
                                              }
                                              placeholder="Enter"
                                              onChange={this.handleInputChange}
                                              autoComplete="off"
                                              disabled={
                                                !this.state.formData
                                                  .past_medical_history.vaccines
                                              }
                                              data-type="array"
                                            />
                                            {this.state.formDataErrors
                                              .past_medical_history.vaccine[
                                              i
                                            ] &&
                                              this.state.formDataErrors
                                                .past_medical_history.vaccine[i]
                                                .vaccine && (
                                                <label className="input-helper">
                                                  {
                                                    this.state.formDataErrors
                                                      .past_medical_history
                                                      .vaccine[i].vaccine
                                                  }
                                                </label>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-md-5">
                                        <div className="newInputFileldOuter">
                                          <div
                                            className={
                                              this.state.formDataErrors
                                                .past_medical_history.vaccine[
                                                i
                                              ] &&
                                              this.state.formDataErrors
                                                .past_medical_history.vaccine[i]
                                                .date
                                                ? "newInputLabel danger-text"
                                                : "newInputLabel"
                                            }
                                          >
                                            Date of Vaccination
                                          </div>
                                          <div className="setting-input-outer input-autocomplete">
                                            <DatePicker
                                              className={
                                                this.state.formDataErrors
                                                  .past_medical_history.vaccine[
                                                  i
                                                ] &&
                                                this.state.formDataErrors
                                                  .past_medical_history.vaccine[
                                                  i
                                                ].date
                                                  ? "newInputField field_error"
                                                  : "newInputField"
                                              }
                                              selected={
                                                this.state.formData
                                                  .past_medical_history.vaccine[
                                                  i
                                                ].date &&
                                                new Date(
                                                  moment(
                                                    this.state.formData
                                                      .past_medical_history
                                                      .vaccine[i].date,
                                                    "YYYY-MM-DD hh:mm:ss",
                                                  ).format(),
                                                )
                                              }
                                              onChange={(date) =>
                                                this.handleInputChange(
                                                  date,
                                                  "past_medical_history.vaccine.date",
                                                  i,
                                                )
                                              }
                                              onChangeRaw={(event) =>
                                                this.handleDatepickerChangeRaw(
                                                  event.target.value,
                                                  "past_medical_history.vaccine.date",
                                                  i,
                                                )
                                              }
                                              onClickOutside={this.onClickOutside.bind(
                                                this,
                                                `vaccine-${i}`,
                                              )}
                                              ref={`vaccine-${i}`}
                                              dateFormat="MM/dd/yyyy"
                                              showYearDropdown
                                              scrollableYearDropdown
                                              yearDropdownItemNumber={100}
                                              maxDate={new Date()}
                                              disabled={
                                                !this.state.formData
                                                  .past_medical_history.vaccines
                                              }
                                              placeholderText="Enter"
                                              data-type="array"
                                              autoComplete="off"
                                            />
                                            {this.state.formDataErrors
                                              .past_medical_history.vaccine[
                                              i
                                            ] &&
                                              this.state.formDataErrors
                                                .past_medical_history.vaccine[i]
                                                .date && (
                                                <label className="input-helper">
                                                  {
                                                    this.state.formDataErrors
                                                      .past_medical_history
                                                      .vaccine[i].date
                                                  }
                                                </label>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      {i > 0 &&
                                      !!this.state.formData.past_medical_history
                                        .vaccines ? (
                                        <div
                                          onClick={() =>
                                            this.handleConfirmDeleteItemModal(
                                              true,
                                              "past_medical_history",
                                              "vaccine",
                                              i,
                                            )
                                          }
                                          className="history-section-delete"
                                        >
                                          <span className="history-section-delete-text">
                                            Delete
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="history-section-delete history-section-delete-empty" />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {!!this.state.formData.past_medical_history
                              .vaccines &&
                              !this.state.formData.past_medical_history
                                .check && (
                                <div className="col-sm-10">
                                  <div
                                    onClick={() =>
                                      this.addNewItemRow(
                                        "past_medical_history",
                                        "vaccine",
                                        formDataVaccineDefault,
                                      )
                                    }
                                    className="history-section-row-add-new"
                                  >
                                    Add Vaccines
                                  </div>
                                  {this.state.formData.past_medical_history
                                    .vaccine.length >= 3 && (
                                    <div
                                      className="history-section-row-expand"
                                      onClick={() =>
                                        this.toggleListLength(
                                          "past_medical_history.vaccine",
                                        )
                                      }
                                    >
                                      {this.state.copyArr.vaccine.length === 2
                                        ? "show more"
                                        : "show less"}
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </>
                      )}
                  </div>
                  <div className="history-section-body">
                    {!this.state.visibility.surgeries &&
                      !this.state.formData.past_medical_history.check && (
                        <div
                          onClick={() =>
                            this.addNewItem(
                              "past_medical_history",
                              "surgeries",
                              "surgery",
                              formDataSurgeryDefault,
                            )
                          }
                          className="history-section-add-new"
                        >
                          Add Surgeries
                        </div>
                      )}
                    {!this.state.visibility.hospitalizations &&
                      !this.state.formData.past_medical_history.check && (
                        <div
                          onClick={() =>
                            this.addNewItem(
                              "past_medical_history",
                              "hospitalizations",
                              "hospitalization",
                              formDataHospitalizationDefault,
                            )
                          }
                          className="history-section-add-new"
                        >
                          Add Hospitalizations
                        </div>
                      )}
                    {!this.state.visibility.implants &&
                      !this.state.formData.past_medical_history.check && (
                        <div
                          onClick={() =>
                            this.addNewItem(
                              "past_medical_history",
                              "implants",
                              "implant",
                              formDataImplantDefault,
                            )
                          }
                          className="history-section-add-new"
                        >
                          Add Implants
                        </div>
                      )}
                    {!this.state.visibility.vaccines &&
                      !this.state.formData.past_medical_history.check && (
                        <div
                          onClick={() =>
                            this.addNewItem(
                              "past_medical_history",
                              "vaccines",
                              "vaccine",
                              formDataVaccineDefault,
                            )
                          }
                          className="history-section-add-new"
                        >
                          Add Vaccines
                        </div>
                      )}
                  </div>
                </div>
                <div className="m-t-10">
                  <Link
                    to={returnTo}
                    className="new-white-btn pull-right cancelAction"
                  >
                    {this.state.clientsLang.clientprofile_cancel}
                  </Link>
                  <input
                    className="new-blue-btn pull-right m-l-0 m-r-2"
                    type="submit"
                    value={this.state.clientsLang.clientprofile_save}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock clientLoader clientProfileLoader"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.globalLang.loading_please_wait_text}
            </div>
          </div>
        </div>

        <div
          className={this.state.deleteItemRowModal.isOpen ? "overlay" : ""}
        ></div>
        <div
          role="dialog"
          className={
            this.state.deleteItemRowModal.isOpen
              ? "modal fade in displayBlock"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => this.handleConfirmDeleteItemModal(false)}
                >
                  ×
                </button>
                <h4 className="modal-title">Confirmation Delete</h4>
              </div>
              <div className="modal-body add-patient-form filter-patient">
                Do you want to delete the history details?
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left">
                  <button
                    type="button"
                    className="btn logout pull-right"
                    data-dismiss="modal"
                    onClick={() => this.handleConfirmDeleteItemModal(false)}
                  >
                    {this.state.settingsLang.no_option}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.deleteItemRow}
                  >
                    {this.state.settingsLang.yes_option}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.ClientsReducer.action === "GET_CLIENT_DETAIL") {
    if (state.ClientsReducer.data.status === 200) {
      returnState.clientData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "UPDATE_NEW_MEDICAL_HISTORY") {
    if (state.ClientsReducer.data.status === 200) {
      returnState.updateData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_MEDICINE_NAMES") {
    if (state.ClientsReducer.data.status === 200) {
      returnState.medicationsSuggestionList = state.ClientsReducer.data
        ? state.ClientsReducer.data.items
        : [];
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClientDetail,
      updateNewMedicalHistory,
      getMedicineNames,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withClientRefetch(MedicalHistory));
