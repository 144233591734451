import React, { useState } from "react";
import { Header } from "./components/Header";
import { ModalFilter } from "./components/ModalFilter";
import { Table } from "./components/Table";
import { useReports } from "./hooks/useReports";
import { ReportLayout } from "../../../components/ReportLayout/ReportLayout";
import { useDownloadExcel } from "./hooks/useDownloadExcel";

export function MembershipChurnReport() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { reports, filter, order, period, clinics } = useReports();
  const downloadExcel = useDownloadExcel({ period, filter });

  return (
    <ReportLayout>
      <Header
        isFilterActive={filter.isActive}
        onFilterOpen={() => setIsFilterOpen(true)}
        period={period}
        downloadExcel={downloadExcel}
        isReportsLoading={reports.isLoading}
        totalCount={reports.total}
      />
      <Table reports={reports} order={order} />
      <ModalFilter
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={filter}
        clinics={clinics}
      />
    </ReportLayout>
  );
}
