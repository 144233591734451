import React, { useState } from "react";
import moment from "moment";
import { Header } from "./components/Header";
import { ModalFilter } from "./components/ModalFilter";
import { Table } from "./components/Table";
import { useReports } from "./hooks/useReports";
import { ReportLayout } from "../../../../BusinessInsights/components/ReportLayout/ReportLayout";
import { API_DATE_FORMAT } from "../../../../../consts/api";

export function MembershipChurnReport() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { reports, filter, order, period } = useReports();

  const requestParams = {
    fromDate: moment(period.value.from).format(API_DATE_FORMAT),
    toDate: moment(period.value.to).format(API_DATE_FORMAT),
    clinic_ids: filter.value.clinics?.map((clinic) => clinic.value),
    tier_ids: filter.value.tiers?.map((tier) => tier.value),
    churn_reason: filter.value.churnReason,
  };

  return (
    <ReportLayout>
      <Header
        isFilterActive={filter.isActive}
        onFilterOpen={() => setIsFilterOpen(true)}
        period={period}
        isReportsLoading={reports.isLoading}
        totalCount={reports.total}
        requestParams={requestParams}
      />
      <Table reports={reports} order={order} />
      <ModalFilter
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={filter}
      />
    </ReportLayout>
  );
}
