/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { displayName, isPositiveNumber } from "../../../Utils/services.js";
import { getPrecision } from "../../../utilities/general.js";

class OrderStockPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierList: [],
      supplierClass: "simpleSelect",
      clinicClass: "simpleSelect",
      order_placed_byClass: "simpleSelect",
      md_idClass: "simpleSelect",
      custom_mdClass: "simpleInput",
      noteClass: "simpleTextarea h-50",
      payment_term_typeClass: "simpleSelect",
      last_four_valueClass: "simpleInput",
      paymentTermsClass: "simpleTextarea h-50",
      payment_term_type: "last_4_digits_of_cc",
      quantity_class: "simpleInput",
      rate_class: "simpleInput",
      clinicList: [],
      userList: [],
      mdList: [],
      addProduct: [],
      supplier_id: "",
      order_placed_by: "",
      clinicId: "",
      custom_md: "",
      md_id: "",
      quantity: "",
      rate: "",
      last_4_digits_of_cc: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.stockOrderDataTime != prevState.stockOrderDataTime) {
      returnState.stockOrderDataTime = nextProps.stockOrderDataTime;
      returnState.supplierList = nextProps.stockOrderData.supplier_list;
      returnState.clinicList = nextProps.stockOrderData.clinic_list;
      returnState.userList = nextProps.stockOrderData.users;
      returnState.mdList = nextProps.stockOrderData.md_list;
      returnState.clinicId = nextProps.selectedClinicOrderId;
      returnState.productName = nextProps.productName;
      returnState.product_id = nextProps.productId;
      returnState.supplier_id = "";
      returnState.order_placed_by = "";
      returnState.custom_md = "";
      returnState.md_id = "";
      returnState.quantity = "";
      returnState.rate = "";
      returnState.last_4_digits_of_cc = "";
      returnState.note = "";
      returnState.payment_terms = "";
      returnState.payment_term_type = "last_4_digits_of_cc";
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const valuePresicion = getPrecision(Number(value));
    const countUnitsByPresicion = getPrecision(Number(this.props.countUnitsBy));

    if (event.target.name === "last_4_digits_of_cc") {
      if (value) {
        if (value.length === 5) {
          return;
        }
      }
    }

    if (
      event.target.name === "quantity" &&
      valuePresicion > countUnitsByPresicion
    ) {
      if (countUnitsByPresicion === 0) {
        return this.setState({
          [event.target.name]: value.split(/\.|,/)[0],
        });
      } else {
        return;
      }
    }

    this.setState({
      [event.target.name]: value,
    });

    if (event.target.name === "supplier_id") {
      if (value === "addSupplier") {
        this.setState({ isShowSupplierModal: true, supplier_id: "" });
      }
    }

    if (event.target.name === "md_id") {
      if (value === "" || value !== "custom") {
        this.setState({ custom_md: "", custom_mdClass: "simpleInput" });
      }
    }
  };

  dismissModal = () => {
    this.props.dismissOrderStockPopup();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true });
    let error = false;
    let returnState = {};
    if (this.state.supplier_id == null || this.state.supplier_id == "") {
      returnState.supplierClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.supplierClass = "simpleSelect";
    }

    if (this.state.clinicId == null || this.state.clinicId == "") {
      returnState.clinicClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.clinicClass = "simpleSelect";
    }

    if (
      this.state.order_placed_by == null ||
      this.state.order_placed_by == ""
    ) {
      returnState.order_placed_byClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.order_placed_byClass = "simpleSelect";
    }

    if (this.state.md_id == null || this.state.md_id == "") {
      returnState.md_idClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.md_idClass = "simpleSelect";
    }

    if (
      this.state.md_id &&
      this.state.md_id == "custom" &&
      (this.state.custom_md == "" || this.state.custom_md == null)
    ) {
      returnState.custom_mdClass = "simpleInput field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.custom_mdClass = "simpleInput";
    }

    if (
      this.state.quantity == null ||
      this.state.quantity == "" ||
      !isPositiveNumber(this.state.quantity, 0, null, 4)
    ) {
      returnState.quantity_class = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.quantity_class = "simpleSelect";
    }

    if (
      this.state.rate == null ||
      this.state.rate == "" ||
      !isPositiveNumber(this.state.rate, 0)
    ) {
      returnState.rate_class = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.rate_class = "simpleSelect";
    }

    if (
      this.state.note == null ||
      this.state.note == "" ||
      this.state.note.trim() == ""
    ) {
      returnState.noteClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.noteClass = "simpleSelect";
    }

    if (
      (this.state.payment_term_type == "last_4_digits_of_cc" ||
        this.state.payment_term_type == "both") &&
      (this.state.last_4_digits_of_cc == null ||
        this.state.last_4_digits_of_cc == "" ||
        this.state.last_4_digits_of_cc.length < 4)
    ) {
      returnState.payment_term_typeClass = "simpleSelect field_error";
      returnState.showLoader = false;
      error = true;
    } else {
      returnState.payment_term_typeClass = "simpleSelect";
    }
    this.setState(returnState);
    if (error) {
      return;
    }
    let purchase_order_items = [];
    purchase_order_items.push({
      price_per_unit: this.state.rate,
      product_id: this.state.product_id,
      product_name: this.state.productName,
      tax: 0,
      total: this.state.rate * this.state.quantity,
      units: this.state.quantity,
    });
    let formData = {
      clinic_id: this.state.clinicId,
      md_id: this.state.md_id,
      note: this.state.note,
      order_placed_by: this.state.order_placed_by,
      payment_term_type: this.state.payment_term_type,
      supplier_id: this.state.supplier_id,
      purchase_order_items: purchase_order_items,
      type: "inventory",
    };
    this.props.saveOrderInfo(formData);
  };

  render() {
    return (
      <div className="content no-scroll">
        <div className="juvly-container setPadding0">
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  Supplier Name<span className="setting-require">*</span>
                </div>
                <select
                  className={this.state.supplierClass}
                  name="supplier_id"
                  onChange={this.handleInputChange}
                  value={this.state.supplier_id}
                  disabled={this.state.supplierId ? true : false}
                >
                  <option value="">Select</option>
                  {this.state.supplierList.map((obj, id) => {
                    return (
                      <option key={"supplierList-" + id} value={obj.id}>
                        {obj.supplier_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  Clinic Name<span className="setting-require">*</span>
                </div>
                <select
                  disabled={true}
                  className={this.state.clinicClass}
                  name="clinicId"
                  onChange={this.handleInputChange}
                  value={this.state.clinicId}
                >
                  <option value="">Select</option>
                  {this.state.clinicList.map((obj, id) => {
                    return (
                      <option key={"clinicList-" + id} value={obj.id}>
                        {obj.clinic_name +
                          (obj.account_number
                            ? " - " + obj.account_number
                            : "")}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  PO Created By<span className="setting-require">*</span>
                </div>
                <select
                  className={this.state.order_placed_byClass}
                  name="order_placed_by"
                  onChange={this.handleInputChange}
                  value={this.state.order_placed_by}
                >
                  <option value="">Select</option>
                  {this.state.userList.map((obj, id) => {
                    return (
                      <option key={"userList-" + id} value={obj.id}>
                        {displayName(obj)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  Medical Director<span className="setting-require">*</span>
                </div>
                <select
                  className={this.state.md_idClass}
                  name="md_id"
                  onChange={this.handleInputChange}
                  value={this.state.md_id}
                >
                  <option value="">Select</option>
                  {this.state.mdList &&
                    this.state.mdList.map((obj, id) => {
                      return (
                        <option key={"mdList-" + id} value={obj.id}>
                          {displayName(obj)}
                        </option>
                      );
                    })}
                  <option value="custom">Custom</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.md_id && this.state.md_id === "custom" ? (
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">
                    Medical Director Name
                    <span className="setting-require">*</span>
                  </div>
                  <input
                    type="text"
                    name={"custom_md"}
                    value={this.state.custom_md}
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    className={this.state.custom_mdClass}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  Quantity<span className="setting-require">*</span>
                </div>
                <input
                  type="number"
                  name={"quantity"}
                  value={this.state.quantity}
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  className={this.state.quantity_class}
                />
              </div>
            </div>

            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  Rate<span className="setting-require">*</span>
                </div>
                <input
                  type="text"
                  name={"rate"}
                  value={this.state.rate}
                  autoComplete="off"
                  onChange={this.handleInputChange}
                  className={this.state.rate_class}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  Note<span className="setting-require">*</span>
                </div>
                <textarea
                  name="note"
                  className={this.state.noteClass}
                  value={this.state.note}
                  onChange={this.handleInputChange}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="simpleField m-b-15">
                <div className="simpleLabel">
                  Payment Terms Type<span className="setting-require">*</span>
                </div>
                <select
                  className={this.state.payment_term_typeClass}
                  name="payment_term_type"
                  onChange={this.handleInputChange}
                  value={this.state.payment_term_type}
                >
                  <option value="last_4_digits_of_cc">
                    Last 4 digits of CC
                  </option>
                  <option value="payment_term_text">Payment terms</option>
                  <option value="both">Both</option>
                </select>
              </div>
            </div>
            {this.state.payment_term_type === "last_4_digits_of_cc" ||
            this.state.payment_term_type === "both" ? (
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">
                    Last 4 digits Of CC
                    <span className="setting-require">*</span>
                  </div>
                  <input
                    type="text"
                    name={"last_4_digits_of_cc"}
                    value={this.state.last_4_digits_of_cc}
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    className={this.state.last_four_valueClass}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {(this.state.payment_term_type === "payment_term_text" ||
              this.state.payment_term_type === "both") && (
              <div className="col-lg-12 col-xs-12">
                <div className="simpleField">
                  <div className="simpleLabel">Payment Terms</div>
                  <textarea
                    name="payment_terms"
                    className={this.state.paymentTermsClass}
                    value={this.state.payment_terms}
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="footer-static">
          <a
            className={"new-blue-btn pull-right"}
            onClick={this.handleSubmit}
          >{`Order Stock`}</a>
          <a className="new-white-btn pull-left" onClick={this.dismissModal}>
            Cancel
          </a>
        </div>
      </div>
    );
  }
}

export default OrderStockPopup;
