import { useMemo } from "react";
import * as yup from "yup";
import { useProvidersByClinicIdsQuery } from "../../../api/queries/useProvidersByClinicIdsQuery";
import { uiNotification } from "../../../services/UINotificationService";

const schema = yup.array().of(
  yup.object({
    id: yup.number(),
    name: yup.string(),
  }),
);

const compose = (response) => {
  let result = [];
  if (response && Array.isArray(response.data.data)) {
    result = response.data.data.map((provider) => ({
      id: provider.id,
      name: provider.full_name,
    }));
  }
  return result;
};

export function useApiProvidersByClinics({ clinicIds, isActive = true }) {
  const { data, isFetching } = useProvidersByClinicIdsQuery(
    { clinicIds },
    {
      staleTime: 0,
      enabled: isActive ? clinicIds.length > 0 : false,
      onError: () => {
        uiNotification.error("Unable to retrieve providers. Try again later");
      },
    },
  );

  const { providersByClinic, providersDictionary } = useMemo(() => {
    const providersList = schema.validateSync(compose(data), {
      strict: true,
    });

    return {
      providersDictionary: providersList.reduce(
        (acc, providerDetails) => ({
          ...acc,
          [providerDetails.id]: providerDetails,
        }),
        {},
      ),
      providersByClinic: providersList,
    };
  }, [data]);

  return {
    providersByClinic,
    providersDictionary,
    isProvidersByClinicFetching: isFetching,
  };
}
