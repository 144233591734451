import { useCurrentAccountQuery } from "../../../../../../api/queries/useAccountQuery";
import { useClientQuery } from "../../../../../../api/queries/useClientQuery";
import { CLIENT_GLOBAL_NAME } from "../../../../../../consts/api";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function usePatient(patientId) {
  const { tCommon } = useAppTranslation.Common();

  const { data: account, isLoading: isCurrentAccountLoading } =
    useCurrentAccountQuery();

  const { data, isLoading } = useClientQuery(
    {
      clientId: patientId,
    },
    {
      enabled: !!account?.id,
      onError: () => {
        uiNotification.error(
          account?.preferences?.clientGlobalName === CLIENT_GLOBAL_NAME.client
            ? tCommon("error.fetchClient")
            : tCommon("error.fetchPatient"),
        );
      },
    },
  );

  return {
    data: data || {},
    isLoading: isLoading || isCurrentAccountLoading,
  };
}
