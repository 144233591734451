import React from "react";
import PropTypes from "prop-types";
import { ALERT_BOX_VARIANTS, AlertBox } from "../../shared/AlertBox/AlertBox";
import classes from "./LoadingAlert.module.scss";
import {
  CIRCULAR_PROGRESS_COLORS,
  CircularProgress,
} from "../../shared/CircularProgress/CircularProgress";

export function LoadingAlert({ children, variant }) {
  const progressColorByVariant = {
    [ALERT_BOX_VARIANTS.error]: CIRCULAR_PROGRESS_COLORS.red,
    [ALERT_BOX_VARIANTS.success]: CIRCULAR_PROGRESS_COLORS.green,
    [ALERT_BOX_VARIANTS.warn]: CIRCULAR_PROGRESS_COLORS.orange,
  };

  return (
    <AlertBox variant={variant} className={classes.root}>
      <CircularProgress size="small" color={progressColorByVariant[variant]} />
      <div className={classes.content}>{children}</div>
    </AlertBox>
  );
}

LoadingAlert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  variant: PropTypes.oneOf(Object.values(ALERT_BOX_VARIANTS)),
};

LoadingAlert.defaultProps = {
  variant: ALERT_BOX_VARIANTS.warn,
};
