/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { format } from "date-fns";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { showFormattedDate, displayName } from "../../../Utils/services";

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

class DetailedProductLogs extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    let startDate = new Date();
    let endDate = new Date();

    this.state = {
      productLogs: [],
      next_page_url: props.nextPageUrl,
      page: props.currentPage,
      showLoader: false,
      clinics: props.clinics,
      showClinics: false,
      showCalendar: false,
      clinicName: "All Clinics",
      clinicId: 0,

      dateRangePicker: {
        selection: {
          startDate: startDate,
          endDate: endDate,
          key: "selection",
        },
      },
      endDate: endDate,
      startDate: startDate,
      toDate: format(endDate, "YYYY-MM-DD"),
      fromDate: format(startDate, "YYYY-MM-DD"),
    };
    this.scrollRef = React.createRef();

    document.addEventListener("click", this.handleClick, false);
  }

  handleClick = (event) => {
    if (
      this.node &&
      this.node.contains(event.target) &&
      this.state.showCalendar === true
    ) {
      return;
    }

    if (
      event.target.className !== "easy-link no-padding" &&
      this.state.showClinics === true
    ) {
      this.setState({ showClinics: false });
    }

    this.toggleCalendar(event.target);
  };

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendar = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendar = true;
    } else {
      showCalendar = false;
    }

    this.setState({ showCalendar: showCalendar, clicked: 0 });
  };

  componentDidMount() {}
  onScroll = () => {
    const innerHeight = this.scrollRef.current.clientHeight;
    const scrollHeight = this.scrollRef.current.scrollHeight;
    const scrollTop = this.scrollRef.current.scrollTop;
    if (
      innerHeight + scrollTop >= scrollHeight &&
      this.state.next_page_url != null
    ) {
      this.setState({ showLoader: true });

      let formData = {};
      let ids = [];

      formData.fromDate = apiDateFormat(this.state.startDate);
      formData.toDate = apiDateFormat(this.state.endDate);

      if (this.state.clinicId == 0) {
        this.state.clinics &&
          this.state.clinics.map((obj) => {
            ids.push(obj.id);
          });

        formData.clinic_id = ids;
        formData.all_clinics = true;
      } else {
        formData.clinic_id = [this.state.clinicId];
        formData.all_clinics = false;
      }

      this.props.loadMoreProductLogs(formData);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.logsData != prevState.productLogs) {
      returnState.productLogs = nextProps.logsData;
      returnState.showLoader = false;
    }
    if (nextProps.nextPageUrl != prevState.next_page_url) {
      returnState.next_page_url = nextProps.nextPageUrl;
    }
    return returnState;
  }

  toggleClinic = () => {
    this.setState({ showClinics: !this.state.showClinics });
  };

  changeClinic = (clinicId) => {
    let formData = {};
    let ids = [];

    if (clinicId == 0) {
      this.state.clinics &&
        this.state.clinics.map((obj) => {
          ids.push(obj.id);
        });

      formData.clinic_id = ids;
      formData.all_clinics = true;
      this.setState({ clinicName: "All Clinics", clinicId: 0 });
    } else {
      formData.clinic_id = [clinicId];
      formData.all_clinics = false;
      let clinic = this.state.clinics.find((e) => e.id == clinicId);
      this.setState({ clinicName: clinic.clinic_name, clinicId: clinicId });
    }

    formData.fromDate = apiDateFormat(this.state.fromDate);
    formData.toDate = apiDateFormat(this.state.endDate);

    this.props.handleChildFilter(formData);
  };

  dateRangeOnChange = (payload) => {
    let payloadValue = {};
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    let clicked = this.state.clicked + 1;
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      showCalendar: showCalendar,
      fromDate: format(startDate, "YYYY-MM-DD"),
      toDate: format(endDate, "YYYY-MM-DD"),
      startDate: startDate,
      endDate: endDate,
      clicked: clicked,
      dateData: JSON.stringify(payloadValue),
    });

    if (clicked && clicked % 2 === 0) {
      if (payload) {
        payloadValue = {
          startDate: apiDateFormat(payload.selection.startDate),
          endDate: apiDateFormat(payload.selection.endDate),
          key: "selection",
        };
      }

      let formData = {};
      let ids = [];

      formData.fromDate = apiDateFormat(startDate);
      formData.toDate = apiDateFormat(endDate);

      if (this.state.clinicId == 0) {
        this.state.clinics &&
          this.state.clinics.map((obj) => {
            ids.push(obj.id);
          });

        formData.clinic_id = ids;
        formData.all_clinics = true;
      } else {
        formData.clinic_id = [this.state.clinicId];
        formData.all_clinics = false;
      }

      this.props.handleChildFilter(formData);
    }
  };

  handleDownload = (downloadType) => {
    let formData = {};
    let ids = [];

    formData.fromDate = apiDateFormat(this.state.startDate);
    formData.toDate = apiDateFormat(this.state.endDate);

    if (this.state.clinicId == 0) {
      this.state.clinics &&
        this.state.clinics.map((obj) => {
          ids.push(obj.id);
        });

      formData.clinic_id = ids;
      formData.all_clinics = true;
    } else {
      formData.clinic_id = [this.state.clinicId];
      formData.all_clinics = false;
    }

    formData.export_type = downloadType;

    this.props.handleChildDownload(formData);
  };

  render() {
    let selectionRange = {};
    selectionRange = {
      startDate: this.state.startDate ? this.state.startDate : new Date(),
      endDate: this.state.endDate ? this.state.endDate : new Date(),
      key: "selection",
    };

    return (
      <div
        ref={this.scrollRef}
        onScroll={this.onScroll}
        style={{
          height: "800px",
          overflow: "auto",
        }}
      >
        <div className="mainRightActions mrrActions">
          {this.state.clinics && (
            <span className="easy-link no-padding" onClick={this.toggleClinic}>
              <i className="fa fa-map-marker-alt"></i> {this.state.clinicName}
              <div
                className={
                  this.state.showClinics
                    ? "actionDropdownOuter clinicDropdown"
                    : "no-display"
                }
                ref={(clinicNode) => {
                  this.clinicNode = clinicNode;
                }}
              >
                <Scrollbars
                  style={{ minHeight: "200px" }}
                  className="full-width"
                  renderTrackHorizontal={(props) => (
                    <div
                      {...props}
                      style={{ display: "none" }}
                      className="track-horizontal"
                    />
                  )}
                >
                  <ul className="actionDropdown">
                    <li>
                      <a
                        onClick={this.changeClinic.bind(this, 0)}
                        className={this.state.clinicId == 0 ? "selected" : ""}
                      >
                        {"All Clinics"}
                      </a>
                    </li>
                    {this.state.clinics &&
                      this.state.clinics.map((obj) => {
                        return (
                          <li key={"clinic-" + obj.id} data-clinic-id={obj.id}>
                            <a
                              onClick={this.changeClinic.bind(this, obj.id)}
                              className={
                                this.state.clinicId == obj.id ? "selected" : ""
                              }
                            >
                              {obj.clinic_name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </Scrollbars>
              </div>
            </span>
          )}

          {
            <a
              className="easy-link no-padding"
              ref={(node) => {
                this.node = node;
              }}
            >
              <i className="fa fa-calendar-alt"></i>
              {this.state.showCalendar && (
                <DateRangePicker
                  value={selectionRange}
                  className={"CalendarPreviewArea detailedProductLogsCal"}
                  ranges={[selectionRange]}
                  onChange={this.dateRangeOnChange}
                  maxDate={new Date()}
                  dragSelectionEnabled={false}
                />
              )}
              <input
                type="text"
                className="b-s-sales sales-filter-cal input-cal setting-search-input"
                name="calendar-input"
                value={
                  showFormattedDate(this.state.fromDate) +
                  ` - ` +
                  showFormattedDate(this.state.toDate)
                }
                readOnly={true}
              />
            </a>
          }

          {
            <a
              className="easy-link no-padding"
              onClick={() => this.handleDownload("xls")}
            >
              <i className="fa fa-download"></i> Download Excel
            </a>
          }
        </div>

        <table
          className={
            "table-updated setting-table no-td-border no-hover min-w-850 editProTable table-fixed"
          }
        >
          <thead className="table-updated-thead">
            <tr>
              <th className="col-xs-2 table-updated-th">Clinic</th>
              <th className="col-xs-2 table-updated-th">Patient</th>
              <th className="col-xs-2 table-updated-th">Date</th>
              <th className="col-xs-1 table-updated-th">Action</th>
              <th className="col-xs-1 table-updated-th">Beginning Balance</th>
              <th className="col-xs-1 table-updated-th">Units</th>
              <th className="col-xs-1 table-updated-th">Change</th>
              <th className="col-xs-1 table-updated-th">New Balance</th>
              <th className="col-xs-1 table-updated-th">Done By</th>
            </tr>
          </thead>
          <tbody>
            {this.state.productLogs &&
              this.state.productLogs.length > 0 &&
              this.state.productLogs.map((obj, idx) => {
                return (
                  <tr key={"clinics-" + idx}>
                    <td className="col-xs-2 table-updated-td break-all">
                      {obj.clinic && obj.clinic_id
                        ? obj.clinic.clinic_name
                        : "--"}
                    </td>
                    <td className="col-xs-2 table-updated-td break-all">
                      {obj.patient_id == "0"
                        ? "Guest"
                        : obj.patient_id && obj.patient_id > 0
                        ? displayName(obj.patient)
                        : "-"}
                    </td>
                    <td className="col-xs-2 table-updated-td break-all">
                      {showFormattedDate(obj.created, true)}
                    </td>
                    <td className="col-xs-1 table-updated-td">
                      {obj.action_from_description}
                    </td>
                    <td className="col-xs-1 table-updated-td">
                      {obj.total_old_units}
                    </td>
                    <td className="col-xs-1 table-updated-td p-r-0">
                      {obj.units}
                    </td>
                    <td className="col-xs-1 table-updated-td">{obj.action}</td>
                    <td className="col-xs-1 table-updated-td">
                      {obj.total_new_units}
                    </td>
                    <td className="col-xs-1 table-updated-td">
                      {obj.user ? obj.user.full_name : ""}
                    </td>
                  </tr>
                );
              })}
            {this.state.productLogs && this.state.productLogs.length == 0 && (
              <tr>
                <td
                  colSpan={7}
                  className="col-xs-12 table-updated-td text-center"
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {this.state.showLoader && (
          <div className="text-center text-loading">
            Loading data. Please wait...
          </div>
        )}
      </div>
    );
  }
}

export default DetailedProductLogs;
