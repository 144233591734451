import PropTypes from "prop-types";
import { CHAT_MESSAGE_TYPE } from "./Chat.consts";

export const messageAuthorPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fullName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
});

export const messagePropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(Object.values(CHAT_MESSAGE_TYPE)).isRequired,
  date: PropTypes.string.isRequired,
  author: messageAuthorPropType.isRequired,
  body: PropTypes.string.isRequired,
});
