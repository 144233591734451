import React from "react";
import classes from "./Footer.module.scss";
import { Button } from "../../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { AlertBox } from "../../../../../../../shared/AlertBox/AlertBox";

export function Footer({ submit, reset, isEditMode, error }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Button onClick={submit}>
          {isEditMode ? tCommon("label.save") : tCommon("label.add")}
        </Button>
        {reset && (
          <Button variant="outlined" onClick={reset}>
            {tCommon("label.reset")}
          </Button>
        )}
      </div>
      {error && (
        <AlertBox variant="error" size="small">
          {error}
        </AlertBox>
      )}
    </div>
  );
}
