import React, { useState } from "react";
import cx from "clsx";
import { format } from "date-fns";
import styles from "./ClientPersonalInfo.module.scss";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { calculateAge } from "../../../../../utilities/general";
import { checkIfPermissionAllowed } from "../../../../../Utils";
import { getPathParams } from "../../utils/utils";
import { history } from "../../../../../history";
import { phoneUtil } from "../../../../../utilities/phone";
import nonBinaryLogo from "../../../../../_legacy/images/Face_NonBinary_new.png"
import { shortenClinicName } from "../../../../../helpers/general";
import { uiNotification } from "../../../../../services/UINotificationService";
import { MemberType } from "./shared/MemberType/MemberType";
import { useChangeClinicMutation } from "../../../../../api/patient/useChangeClinicMutation";
import { useClientInfo } from "../../ClientInfo";
import { useExportClientPdfMutation } from "../../../../../api/patient/useExportClientPdfMutation";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { useDelete } from "./hooks/useDelete";
import { GENDERS } from "../../../../../consts/api";
import { ALLERGIES_SHOW_LIMIT } from "./config";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

export function ClientPersonalInfo() {
  const { tClients } = useAppTranslation.Clients();
  const { clientId } = getPathParams();
  const { showLoader, hideLoader } = useClientInfo();
  const { data: client } = useClientQuery({ clientId });
  const { data: user } = useCurrentUserQuery();

  const [showClinicsDropdown, setShowClinicsDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const allergiesShowLimit = ALLERGIES_SHOW_LIMIT;
  const allergies = client?.allergies?.filter(Boolean) || [];
  const remainAllergiesCount = allergies.length - allergiesShowLimit;
  const allergiesInfo = allergies?.slice(0, 5).join(", ");
  const clinicData = client?.clinics.find((c) => c.id === client?.clinicId);
  const [clinicId, setClinicId] = useState(clinicData?.id || null);

  const changeClinic = useChangeClinicMutation({
    onError: () => {
      uiNotification.error("Unable to change clinic. Try again later");
    },
    onMutate: () => {
      showLoader();
    },
    onSettled: () => {
      hideLoader();
    },
  });

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const { onDelete } = useDelete(clientId, onClose);

  const onExportPdf = useExportClientPdfMutation({
    onError: () => {
      uiNotification.error("Unable to export PDF");
    },
    onSuccess: (response) => {
      window.open(response.data.data.file);
    },
    onMutate: () => {
      showLoader();
    },
    onSettled: () => {
      hideLoader();
    },
  });

  const onCancel = () => {
    setShowClinicsDropdown(false);
    setClinicId(clinicData.id);
  };

  const getUserImage = () => {
    if (client?.imageUrl) {
      return client?.imageUrl;
    }

    switch (client?.gender) {
      case GENDERS.female:
      return "/images/appfemale.png";

      case GENDERS.male:
        return "/images/appmale.png"

      case GENDERS.nonBinary:
        return nonBinaryLogo;

      default:
        return "/images/appmale.png";
    }
  };

  const exportPdf = () => {
    onExportPdf.mutate({
      clientId: +clientId,
    });
  };

  const editClientProfile = () => {
    history.push(`/clients/${clientId}/profile`);
  };

  const deletePatient = () => {
    onDelete.mutate({ clientId });
  };

  const saveClinic = async () => {
    const formData = {
      clinicId: +clinicId,
      patientId: clientId,
    };
    setShowClinicsDropdown(false);
    changeClinic.mutate(formData);
  };

  return (
    <div className={styles.infoSection}>
      <div className={styles.infoCard}>
        <div>
          <img
            src={getUserImage()}
            alt="userImage"
            className={styles.infoImg}
          />
          <MemberType />
        </div>
        <div className={styles.patientCard}>
          <div className={styles.patientInfoName}>{client?.fullNameWithMiddle}</div>
          {Boolean(client?.nickName) && <div className={styles.patientNick}>({client?.nickName})</div>}
          <div className={styles.personalDataContainer}>
            <div className={styles.patientPersonalInfo}>
              <div className={styles.patientInfoTitle}>DOB:</div>
              <div className={styles.personalInfoItem}>
                {Boolean(client?.dateOfBirth)
                  ? format(
                    client?.dateOfBirth,
                    user?.previewDateFormat,
                  ) : ""}
              </div>
            </div>
            <div className={styles.patientPersonalInfo}>
              <div className={styles.patientInfoTitle}>AGE:</div>
              <div className={styles.personalInfoItem}>
                {client?.dateOfBirth ? calculateAge(client?.dateOfBirth) : ""}
              </div>
            </div>
            {Boolean(client?.hasAllergy) && (
              <div
                className={cx(
                  styles.patientPersonalInfo,
                  styles.allergiesBlock,
                )}
              >
                <div>
                  <span className={styles.patientInfoTitle}>allergies:</span>
                  <span
                    className={cx(
                      styles.personalInfoItem,
                      styles.allergiesInfo,
                    )}
                  >
                    {allergiesInfo}{" "}
                    {remainAllergiesCount > 0 && `+${remainAllergiesCount}`}
                  </span>
                </div>
              </div>
            )}
          </div>
          {(checkIfPermissionAllowed("add-update-patients") ||
            checkIfPermissionAllowed("delete-patients") ||
            checkIfPermissionAllowed("export-patients")) && (
            <div className={cx(styles.patientActions, "dropdown")}>
              <button
                className="line-btn profile-actions no-margin"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                Actions
                <i className="fas fa-angle-down"></i>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                {checkIfPermissionAllowed("add-update-patients") && (
                  <li className={styles.btnLi}>
                    <button className={cx(styles.btnMenu, "modal-link")} onClick={editClientProfile}>
                      Edit
                    </button>
                  </li>
                )}
                {checkIfPermissionAllowed("delete-patients") && (
                  <li className={styles.btnLi}>
                    <button className={cx(styles.btnMenu, "confirm-model")} onClick={onOpen}>
                      Delete
                    </button>
                  </li>
                )}
                {checkIfPermissionAllowed("export-patients") && (
                  <li className={styles.btnLi}>
                    <button className={cx(styles.btnMenu, "modal-link")} onClick={() => exportPdf()}>Export PDF</button>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div
          className={cx("col-xs-12", styles.patientContactInformationSection)}
        >
          <div>
            <div className={styles.patientInfoItem}>Address</div>
            <div className={styles.patientItemField}>
              {client?.address?.length >= 1 ? client?.address?.join(", ") : ""}
            </div>
          </div>
          <div>
            <div className={styles.patientInfoItem}>Phone</div>
            <div className={styles.patientItemField}>
              {client?.phone ? phoneUtil.formatWithDashes(client?.phone) : ""}
            </div>
          </div>
          <div>
            <div className={styles.patientInfoItem}>Email</div>
            <div className={styles.patientItemField}>
              {client?.email || ""}
            </div>
          </div>
          <div>
            <label htmlFor="dropdownClinic" className={styles.patientInfoItem}>Primary Clinic</label>
            {!showClinicsDropdown &&
              <div
                id="dropdownClinic"
                className={styles.patientItemField}
              >
              <span className="profileLeftClinic text-ellipsis">
                {clinicData?.clinic_name
                  ? shortenClinicName(clinicData?.clinic_name)
                  : "No clinic assigned"}
              </span>
                <span className="profileLeftbtns">
                <button
                  className="xs-blue-btn pull-right"
                  onClick={() => setShowClinicsDropdown(true)}
                >
                  Edit
                </button>
              </span>
              </div>}
            {showClinicsDropdown &&
              <div
                className={styles.patientItemField}
              >
                <select
                  className="profileLeftSelect p-r-20"
                  name="patient_clinic_id"
                  value={clinicId}
                  onChange={(e) => setClinicId(e.target.value)}
                >
                  <option value="0">Select Clinic</option>
                  {client?.clinics?.map((obj, idx) => (
                      <option value={obj.id} key={"clinics-" + idx}>
                        {shortenClinicName(obj.clinic_name)}
                      </option>
                    ),
                  )}
                </select>
                <span className="profileLeftbtns">
                <button className="xs-blue-btn pull-right" onClick={saveClinic}>
                  Save
                </button>
                <button className="xs-white-btn pull-right" onClick={onCancel}>
                  Cancel
                </button>
              </span>
              </div>}
          </div>
        </div>
      </div>
      {isOpen && (
        <ConfirmModal
          isConfirming={onDelete.isLoading}
          isOpen={isOpen}
          onConfirm={deletePatient}
          onCancel={onClose}
          onClose={onClose}
        >
          <div>
            <h4>{tClients("profile.delete.message")}</h4>
          </div>
        </ConfirmModal>
      )}
    </div>
  );
}
