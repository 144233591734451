import { useMemo } from "react";
import {
  choiceToSelectOption,
  filterOptionByLabelPredicate,
  getAlreadySelectedChoiceIds,
  getAlreadySelectedQuestionIds,
  questionToSelectOptions,
} from "../LogicJumps.utils";
import { isNullish } from "../../../../../../utilities/general";

export function useOptions({ nodes, choices, questions, preloadedQuestionId }) {
  const choiceOptions = useMemo(() => {
    return choices
      .map(choiceToSelectOption)
      .filter(filterOptionByLabelPredicate);
  }, [choices]);

  const availableChoiceOptions = useMemo(() => {
    const alreadySelected = getAlreadySelectedChoiceIds(nodes);
    return choiceOptions.filter((c) => !alreadySelected.includes(c.value));
  }, [choiceOptions, nodes]);

  const questionOptions = useMemo(() => {
    const questionOptions = questions.map(questionToSelectOptions);

    const index = questions.findIndex((q) => q.id === preloadedQuestionId);

    if (!isNullish(index) && index !== -1) {
      const withoutCurrent = [
        ...questionOptions.slice(0, index),
        ...questionOptions.slice(index + 1),
      ];
      return withoutCurrent.filter(filterOptionByLabelPredicate);
    }

    return questionOptions.filter(filterOptionByLabelPredicate);
  }, [questions]);

  const availableQuestionOptions = useMemo(() => {
    const alreadySelected = getAlreadySelectedQuestionIds(nodes);
    return questionOptions.filter((c) => !alreadySelected.includes(c.value));
  }, [questionOptions, nodes]);

  return {
    choices: {
      all: choiceOptions,
      available: availableChoiceOptions,
    },
    questions: {
      all: questionOptions,
      available: availableQuestionOptions,
    },
  };
}
