/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  emptyInventoryReducer,
  viewStockTransfer,
  acceptStockTransfer,
} from "../../../Actions/Inventory/inventoryActions.js";
import {
  showFormattedDate,
  toggleBodyScroll,
  displayName,
  numberFormat,
} from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import StockTransferProductModal from "./StockTransferProductModal.js";
import InventorySidebar from "../InventorySidebar.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class StockTransferDetails extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      globalLang: languageData.global,
      showLoader: false,
      stockTransferId:
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
          ? this.props.match.params.id
          : 0,
      stockTransferData: {},
      transfer_number: "",
      from_clinic_id: 0,
      from_clinic: null,
      to_clinic_id: 0,
      to_clinic: null,
      transfer_by: null,
      received_by: null,
      transfer_on: null,
      received_on: null,
      products: [],
      transfer_type: "courier_service",
      shipping_provider: "",
      shipping_tracking_number: "",
      provider_name: "",
      tracking_number: "",
      shipping_status: "",
      responsible_staff_id: 0,
      responsible_staff: null,

      showStockTransferProductModal: false,
    };
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.viewStockTransfer(this.state.stockTransferId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader) {
      returnState.showLoader = false;
      nextProps.emptyInventoryReducer();
    } else if (nextProps.isRedirect) {
      returnState.showModal = false;
      nextProps.history.push(`/inventory/stock-transfers`);
    } else if (
      nextProps.stockTransferData &&
      nextProps.stockTransferData.data !== prevState.stockTransferData
    ) {
      nextProps.emptyInventoryReducer();
      returnState.showLoader = false;
      returnState.stockTransferData = nextProps.stockTransferData;
      returnState.transfer_number = nextProps.stockTransferData.transfer_number;
      returnState.from_clinic_id = nextProps.stockTransferData.from_clinic_id
        ? nextProps.stockTransferData.from_clinic_id
        : 0;
      returnState.from_clinic = nextProps.stockTransferData.from_clinic
        ? nextProps.stockTransferData.from_clinic
        : null;
      returnState.to_clinic_id = nextProps.stockTransferData.to_clinic_id
        ? nextProps.stockTransferData.to_clinic_id
        : 0;
      returnState.to_clinic = nextProps.stockTransferData.to_clinic
        ? nextProps.stockTransferData.to_clinic
        : null;
      returnState.transfer_by = nextProps.stockTransferData.transfer_by
        ? nextProps.stockTransferData.transfer_by
        : null;
      returnState.received_by = nextProps.stockTransferData.received_by
        ? nextProps.stockTransferData.received_by
        : null;
      returnState.transfer_on = nextProps.stockTransferData.transfer_on
        ? nextProps.stockTransferData.transfer_on
        : "";
      returnState.received_on = nextProps.stockTransferData.received_on
        ? nextProps.stockTransferData.received_on
        : "";
      returnState.transfer_type = nextProps.stockTransferData.transfer_type
        ? nextProps.stockTransferData.transfer_type
        : "courier_service";
      returnState.shipping_provider = nextProps.stockTransferData
        .shipping_provider
        ? nextProps.stockTransferData.shipping_provider
        : null;
      returnState.shipping_tracking_number = nextProps.stockTransferData
        .shipping_tracking_number
        ? nextProps.stockTransferData.shipping_tracking_number
        : null;
      returnState.provider_name = nextProps.stockTransferData.provider_name
        ? nextProps.stockTransferData.provider_name
        : null;
      returnState.tracking_number = nextProps.stockTransferData.tracking_number
        ? nextProps.stockTransferData.tracking_number
        : null;
      returnState.responsible_staff_id = nextProps.stockTransferData
        .responsible_staff_id
        ? nextProps.stockTransferData.responsible_staff_id
        : 0;
      returnState.responsible_staff = nextProps.stockTransferData
        .responsible_staff
        ? nextProps.stockTransferData.responsible_staff
        : null;
      returnState.shipping_status = nextProps.stockTransferData.shipping_status
        ? nextProps.stockTransferData.shipping_status
        : "";
      returnState.products = nextProps.stockTransferData.products
        ? nextProps.stockTransferData.products
        : [];
    }
    return returnState;
  }

  redirectToStockTransfer = () => {
    this.props.history.push("/inventory/stock-transfers");
  };

  handleChildState = (hildState) => {
    this.setState(hildState);
  };

  showStockTransferProductModal = () => {
    toggleBodyScroll(true);
    let transfer = [];
    if (this.state.products && this.state.products.length) {
      const products = this.state.products;
      products.map((objProduct) => {
        if (objProduct.product) {
          let data = JSON.parse(JSON.stringify(objProduct));

          data.batches.map((objBatch, idxBatch) => {
            let batch = objBatch;
            batch.stock_transfer_batch_id = objBatch.id;
            batch.received_type = "fully";
            batch.received_unitsClass = "simpleInput";
            batch.received_units = objBatch.units;
            batch.transfer_units = objBatch.units;
            batch.damage_unitsClass = "simpleInput";
            batch.damage_units = 0;
            batch.received_status = "received";
            batch.reasonClass = "simpleInput";
            batch.reason = "";
            batch.ctcClass = "simpleInput";
            batch.cost_to_company = "";

            data.batches[idxBatch] = batch;
          });
          transfer.push(data);
        }
      });
    }

    this.setState({ showStockTransferProductModal: true, transfer: transfer });
  };

  hideStockTransferProductModal = (resetState) => {
    toggleBodyScroll(false);
    this.setState(resetState);
  };

  acceptStockTransfer = (acceptStock) => {
    this.setState({ showLoader: true });
    let formData = {
      id: this.state.stockTransferId,
      to_clinic_id: this.state.to_clinic_id,
      from_clinic_id: this.state.from_clinic_id,
      products: acceptStock,
    };
    this.props.acceptStockTransfer(formData);
  };

  render() {
    return (
      <div className="main protected stock-transfer-maain">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <InventorySidebar />
            {this.state.shipping_status !== "completed" &&
              this.state.showStockTransferProductModal === true && (
                <StockTransferProductModal
                  handleChildState={this.handleChildState}
                  hideStockTransferProductModal={
                    this.hideStockTransferProductModal
                  }
                  acceptStockTransfer={this.acceptStockTransfer}
                  transfer={this.state.transfer}
                />
              )}
            <div className="business-setion memberWalletOuter">
              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span
                      className="cursor-pointer"
                      onClick={this.redirectToStockTransfer}
                    >
                      {"Stock Transfers"}
                    </span>
                    <span className="breadCrumb">
                      {" "}
                      <i className="fa fa-chevron-right" />{" "}
                      <span className="breadCrumb-text">
                        {"#" + this.state.transfer_number}
                      </span>
                    </span>
                    <div className="memberRightActions">
                      <button
                        className="new-blue-btn pull-right"
                        onClick={this.redirectToStockTransfer}
                      >
                        {"Back"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="juvly-section supplierInformation no-padding">
                <div className="mainSectionTitle bold m-b-0">
                  Transfer Number {"#" + this.state.transfer_number}
                </div>
                <div className="row p20">
                  <div className="col-sm-6 col-xs-12 supplierDetailsLeft stockTransferBorder">
                    <div className="stockTransfersubTitle m-b-15">From</div>
                    <div className="detail-row">
                      <label>Location:</label>
                      <span>
                        {this.state.from_clinic &&
                        this.state.from_clinic.clinic_name
                          ? this.state.from_clinic.clinic_name
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>Person:</label>
                      <span>
                        {this.state.transfer_by &&
                        this.state.transfer_by.firstname
                          ? displayName(this.state.transfer_by)
                          : ""}
                      </span>
                    </div>
                    <div className="detail-row">
                      <label>Date & Time:</label>
                      <span>
                        {this.state.transfer_on
                          ? showFormattedDate(this.state.transfer_on, true)
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-12 supplierDetailsLeft">
                    <div className="stockTransfersubTitle m-b-15">To</div>
                    <div className="detail-row">
                      <label>Location:</label>
                      <span>
                        {this.state.to_clinic &&
                        this.state.to_clinic.clinic_name
                          ? this.state.to_clinic.clinic_name
                          : ""}
                      </span>
                    </div>
                    {this.state.received_by &&
                      this.state.received_by.firstname && (
                        <div className="detail-row">
                          <label>Person:</label>
                          <span>{displayName(this.state.received_by)}</span>
                        </div>
                      )}
                    {this.state.received_on && (
                      <div className="detail-row">
                        <label>Date & Time:</label>
                        <span>
                          {showFormattedDate(this.state.received_on, true)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="juvly-section full-width supplierInformation no-padding">
                <div className="mainSectionTitle bold m-b-0">
                  Transfer Logistics
                </div>
                <div className="row p20">
                  <div className="col-xs-12">
                    <div className="stockTransfersubTitle m-b-15">
                      {this.state.transfer_type == "shipping_provider"
                        ? "Shipping Provider"
                        : this.state.transfer_type == "courier_service"
                        ? "Courier Service"
                        : "Responsible Staff"}
                    </div>
                  </div>

                  {this.state.transfer_type == "shipping_provider" && (
                    <React.Fragment>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="detail-row">
                          <label>Shipping Provider:</label>
                          <span>{this.state.shipping_provider}</span>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="detail-row">
                          <label>Tracking Number:</label>
                          <span>{this.state.shipping_tracking_number}</span>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.transfer_type == "courier_service" && (
                    <React.Fragment>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="detail-row">
                          <label>Provider Name:</label>
                          <span>{this.state.provider_name}</span>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="detail-row">
                          <label>Tracking Number:</label>
                          <span>{this.state.tracking_number}</span>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.transfer_type == "responsible_staff" &&
                    this.state.responsible_staff_id > 0 &&
                    this.state.responsible_staff && (
                      <React.Fragment>
                        <div className="col-sm-6 col-xs-12 shippingInfo">
                          <div className="detail-row">
                            <label>Staff:</label>
                            <span>
                              {displayName(this.state.responsible_staff)}
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                </div>
              </div>

              <div className="juvly-section full-width m-b-10 auto-height">
                <div className="setting-search-outer">
                  <span className="newTableTitle">Product Details</span>
                  <div className="header-select pull-right">
                    {this.state.shipping_status !== "completed" && (
                      <button
                        className="new-blue-btn pull-right"
                        onClick={this.showStockTransferProductModal}
                      >
                        {"Confirm & Accept"}
                      </button>
                    )}
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table-updated juvly-table min-w-850 no-td-border no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-2 table-updated-th">Name</th>
                        <th className="col-xs-2 table-updated-th">Batch Id</th>
                        <th className="col-xs-2 table-updated-th">
                          Transferred Stock
                        </th>
                        {this.state.shipping_status === "completed" && (
                          <React.Fragment>
                            <th className="col-xs-2 table-updated-th">
                              Received Stock
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              Damaged Stock
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              CTC/Unit
                            </th>
                            <th className="col-xs-2 table-updated-th">Notes</th>
                          </React.Fragment>
                        )}
                      </tr>
                    </thead>
                    <tbody className="ajax_body">
                      {this.state.products &&
                        this.state.products.length > 0 &&
                        this.state.products.map((objProduct, idxProduct) => {
                          return (
                            <React.Fragment
                              key={"transfer-objProduct-" + idxProduct}
                            >
                              {objProduct.batches.map((objBatch, idxBatch) => {
                                return (
                                  <tr
                                    className="table-updated-tr"
                                    key={
                                      "transfer-objProduct-" +
                                      idxProduct +
                                      "-objBatch-" +
                                      idxBatch
                                    }
                                  >
                                    <td className="table-updated-td">
                                      {objProduct.product &&
                                      objProduct.product.product_name
                                        ? objProduct.product.product_name
                                        : ""}
                                    </td>
                                    <td className="table-updated-td word-break">
                                      {objProduct.product &&
                                      objProduct.product.product_type ===
                                        "others"
                                        ? "N/A"
                                        : objBatch.batch_id}
                                    </td>
                                    <td className="table-updated-td">
                                      {objBatch.units}
                                    </td>
                                    {this.state.shipping_status ===
                                      "completed" && (
                                      <React.Fragment>
                                        <td className="col-xs-2 table-updated-th">
                                          {objBatch.received_units}
                                        </td>
                                        <td className="col-xs-2 table-updated-th">
                                          {objBatch.damage_units}
                                        </td>
                                        <td className="col-xs-2 table-updated-th">
                                          {objBatch.product_inventory
                                            ? numberFormat(
                                                objBatch.product_inventory
                                                  .cost_to_company,
                                                "currency",
                                                2,
                                              )
                                            : ""}
                                        </td>
                                        <td className="col-xs-2 table-updated-th">
                                          {objBatch.reason}
                                        </td>
                                      </React.Fragment>
                                    )}
                                  </tr>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      {(!this.state.products ||
                        this.state.products.length <= 0) && (
                        <tr>
                          <td
                            colSpan={5}
                            className="col-xs-12 table-updated-td text-center "
                          >
                            <div className={"no-record"}>
                              {"Product not selected yet!"}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "VIEW_STOCK_TRANSFER") {
    uiNotification.clear();
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = true;
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
    } else {
      returnState.stockTransferData = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "ACCEPT_STOCK_TRANSFER") {
    uiNotification.clear();
    if (state.InventoryReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = true;
    } else {
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.isRedirect = true;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      emptyInventoryReducer: emptyInventoryReducer,
      viewStockTransfer: viewStockTransfer,
      acceptStockTransfer: acceptStockTransfer,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StockTransferDetails);
