import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";

export const useFeaturesList = (options = {}) => {
  return useQuery(
    ["features-list"],
    async () => {
      const response = await http.get(
        HTTP_ENDPOINTS.betaProgram.getBetaProgramFeaturesList(),
      );
      return response.data;
    },
    { cacheTime: 0, ...options },
  );
};
