/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { withRouter } from "react-router";
import InsightsSideBar from "./InsightsSideBar.js";

class SampleInsights extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
    };
  }

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InsightsSideBar />

          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                Inventory <i className="fa fa-chevron-right" /> Create Report
              </div>
            </div>
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                128 birthdays in March
                <div className="memberRightActions">
                  <a className="easy-link no-padding">
                    <i className="fa fa-download m-r-5" /> Download Excel
                  </a>
                  <a className="easy-link no-padding">
                    <i className="fa fa-pencil-alt m-r-5" /> Edit
                  </a>
                  <a className="new-blue-btn">Save report</a>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table-updated setting-table table-min-width no-td-border">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-4 table-updated-th">
                        Provider Name
                      </th>
                      <th className="col-xs-4 table-updated-th">
                        Quantity Sold
                      </th>
                      <th className="col-xs-4 table-updated-th">Amount Sold</th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    <tr className="table-updated-tr">
                      <td className="col-xs-4 table-updated-td">
                        Justin Harper
                      </td>
                      <td className="col-xs-4 table-updated-td">43,678</td>
                      <td className="col-xs-4 table-updated-td">$121,678</td>
                    </tr>
                    <tr className="table-updated-tr">
                      <td className="col-xs-4 table-updated-td">
                        Justin Harper
                      </td>
                      <td className="col-xs-4 table-updated-td">43,678</td>
                      <td className="col-xs-4 table-updated-td">$121,678</td>
                    </tr>
                    <tr className="table-updated-tr">
                      <td
                        className="table-updated-td no-record no-float"
                        colSpan={3}
                      >
                        No record found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                Appointments <i className="fa fa-search search-icon" />
                <div className="memberRightActions">
                  <a className="easy-link no-padding">
                    <i className="fa fa-download m-r-5" /> Download Excel
                  </a>
                  <a className="new-blue-btn">Create report</a>
                </div>
              </div>
            </div>
            <div className="business-row">
              <div className="col-md-4 col-sm-6 col-xs-12 new-stats-outer">
                <div className="dash-box business-stats">
                  <div className="inventry-title">8,710</div>
                  <div className="dash-box-content">
                    Number of discounts applied
                  </div>
                </div>
                <div className="dash-box business-stats">
                  <div className="inventry-title">8,710</div>
                  <div className="dash-box-content">
                    Number of discounts applied
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-6 col-xs-12 new-stats-outer">
                <div className="dash-box business-graph"></div>
              </div>
            </div>
            <div className="setting-setion m-b-10">
              <div className="table-responsive">
                <table className="table-updated setting-table table-min-width no-td-border">
                  <tbody className="ajax_body">
                    <tr className="table-updated-tr">
                      <td className="col-xs-0 table-updated-td">
                        <input type="checkbox" className="new-chek" />
                      </td>
                      <td className="col-xs-3 table-updated-td Questionnaire-name">
                        <a className="eight-dots" />
                        Report for serg
                      </td>
                      <td className="col-xs-6 table-updated-td">
                        Client has had Add on Oxygenetix done in Mar 1, till
                        Mar, 30
                      </td>
                      <td className="col-xs-3 table-updated-td text-right no-padding">
                        <a className="easy-link no-padding m-r-20">
                          <i className="fa fa-pencil-alt m-r-5" />
                          Edit
                        </a>
                        <a className="easy-link no-padding m-r-15">
                          <i className="fa fa-trash-alt m-r-5" />
                          Delete
                        </a>
                      </td>
                    </tr>
                    <tr className="table-updated-tr">
                      <td
                        className="table-updated-td no-record no-float"
                        colSpan={4}
                      >
                        No record found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="not-create-report">
              <h3>{`You haven't create any reports yet`}</h3>
              <a className="new-blue-btn m-t-25">Create report</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SampleInsights);
