import React from "react";
import choiceClasses from "../../shared/Choice/Choice.module.scss";
import { Input } from "../../../../../../../shared/Input/Input";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { ImageUploader } from "../../../../../../../boxes/ImageUploader/ImageUploader";
import { UPLOAD_MEDIA_TYPE } from "../../../../../../../api/mutations/useUploadMediaMutation";
import { Choice } from "../../shared/Choice/Choice";
import { useImageUploader } from "../../../../../../../hooks/useImageUploader";

export function ChoiceImage({
  label,
  onChangeLabel,
  image,
  onChangeImage,
  onRemove,
  index,
}) {
  const { tSettings } = useAppTranslation.Settings();
  const uploader = useImageUploader({
    uploadType: UPLOAD_MEDIA_TYPE.questionnaires,
  });

  return (
    <Choice index={index} onRemove={onRemove}>
      <ImageUploader
        name="image"
        size="small"
        value={image}
        onRemove={() => onChangeImage("")}
        isLoading={uploader.isLoading}
        onChange={(e) =>
          uploader
            .htmlOnChange(e)
            .then(({ uploadedFileFullPath }) =>
              onChangeImage(uploadedFileFullPath),
            )
        }
      />
      <Input
        size="small"
        value={label}
        onChange={(e) => onChangeLabel(e.target.value)}
        className={choiceClasses.input}
        placeholder={tSettings("questionnaires.questionBuilder.imageLabel")}
      />
    </Choice>
  );
}
