import React from "react";
import PropTypes from "prop-types";
import { Input } from "../../../../../shared/Input/Input";
import { InputError } from "../../../../../shared/InputError/InputError";

export function InputEmail({ value, error, isError, isDisabled, onChange }) {
  return (
    <div>
      <Input
        type="email"
        name="clientEmail"
        placeholder="Enter email address"
        value={value}
        isDisabled={isDisabled}
        isError={Boolean(error) || isError}
        onChange={(e) => onChange(e.target.value)}
      />
      {Boolean(error) && <InputError>{error}</InputError>}
    </div>
  );
}

InputEmail.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

InputEmail.defaultProps = {
  value: "",
  error: null,
  isError: false,
  isDisabled: false,
};
