import React, { createRef, useEffect, useState } from "react";
import cx from "clsx";
import uuid from "uuid";
import { usePagination, useSortBy, useTable } from "react-table";
import { numberFormat, showFormattedDate } from "../../../Utils/services";
import ArrowLongDown from "../../../_legacy/images/arrow-long-down.svg";
import ArrowLongUp from "../../../_legacy/images/arrow-long-up.svg";
import { useLocalStorage } from "../../../Utils/";
import BreadCrumbs from "../BreadCrumbs";
import CalendarIcon from "../../../_legacy/images/table-icons/calendar.svg";
import CloseIcon from "../../../_legacy/images/table-icons/close.svg";
import DocumentIcon from "../../../_legacy/images/table-icons/document.svg";
import MessageIcon from "../../../_legacy/images/table-icons/message.svg";
import ToolbarSelects from "./ToolbarSelects";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";

export default function DataTableCustom(props) {
  const {
    data,
    columns,
    isFetching = false,
    paginationInformation,
    updateFilters,
    toolbarButtons,
    breadCrumbs,
    toolbarSelects,
    handleOnChangeRow,
    editMode = false,
    editableColumns,
    isTopDivider = true,
    className,
    moreFilters,
    getFormattedCells,
    defaultSort,
    style = "new",
    containerClassName = "",
    toolbarClassName = "",
    buttonsClassName = "",
    resetEditStates,
  } = props;

  const sortIcons = (isSorted, isSortedDesc) => {
    if (style === "old") {
      return (
        <span className="sorting ">
          <i
            className={
              isSorted
                ? isSortedDesc
                  ? "gray-blue"
                  : "blue-gray"
                : "gray-gray"
            }
          />
        </span>
      );
    } else {
      return (
        isSorted &&
        (isSortedDesc ? (
          <span className="sorted-svg-icon flex">
            <img src={ArrowLongDown} alt="sorted Desc" />
          </span>
        ) : (
          <span className="sorted-svg-icon">
            <img src={ArrowLongUp} alt="sorted Asc" />
          </span>
        ))
      );
    }
  };

  const { canFetchMore = false } = paginationInformation || {};

  const loadMoreDiv = createRef();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [confirmDiscardEditing, setConfirmDiscardEditing] = useState(false);

  const [pageFilters, setPageFilters] = useLocalStorage(
    window.location.pathname,
    {
      per_page: 20,
      sort_by: columns[0].id || columns[0].accessor || columns[0].Header,
      sort_dir: "asc",
      handlePagination: true,
      ...moreFilters,
    },
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: pageFilters.sort_by,
            desc: pageFilters.sort_dir === "desc",
          },
        ],
      },
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
  );

  const {
    state: { sortBy },
  } = tableInstance;

  useEffect(() => {
    if (sortBy[0]) {
      const sortByDetails = {
        sort_by: sortBy[0].id,
        sort_dir: sortBy[0].desc ? "desc" : "asc",
      };
      if (pageFilters) {
        setPageFilters((currentPageFilters) => ({
          ...currentPageFilters,
          ...sortByDetails,
          // do not update page in localStorage
        }));
      }
      setPage(1);
      updateFilters &&
        updateFilters({
          ...pageFilters,
          ...sortByDetails,
          page: 1,
        });
    }
  }, [sortBy]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (
        entry?.isIntersecting &&
        canFetchMore &&
        data.length > 0 &&
        !isFetching
      ) {
        setPage(page + 1);
        updateFilters &&
          updateFilters((prev) => ({
            ...prev,
            page: page + 1,
          }));
      }
    });

    if (loadMoreDiv.current) {
      observer.observe(loadMoreDiv.current);
    }

    return () => {
      if (loadMoreDiv.current) {
        observer.unobserve(loadMoreDiv.current);
      }
    };
  }, [loadMoreDiv]);

  useEffect(() => {
    if (pageFilters) {
      setPageFilters((currentPageFilters) => ({
        ...currentPageFilters,
        search,
      }));
    }
    setPage(1);
    updateFilters &&
      updateFilters({
        ...pageFilters,
        search,
        page: 1,
      });
  }, [search]);

  const renderEditableCell = (cell, row) => {
    const {
      column,
      value,
      row: {
        original: { id },
      },
    } = cell;
    return (
      <input
        key={`${column.id}-${id}`}
        defaultValue={value.toFixed(2)}
        type="number"
        className="table-number-input"
        onChange={(e) => handleOnChangeRow(column.id, e.target.value, row)}
      />
    );
  };

  const handleCustomCell = (cell, type, value, row, accessor) => {
    switch (type) {
      case "formatCells":
        return getFormattedCells(row.original, accessor);
      case "price":
        return value ? numberFormat(value, "currency") : "N/A";
      case "pricePerUnit":
        return (
          <div>
            <span>{value ? `${numberFormat(value, "currency")}` : "N/A"}</span>
            {Boolean(value) && row.original[cell?.column?.accessor2] && (
              <span className="per-unit">/unit</span>
            )}
          </div>
        );
      case "phone":
        return value;
      case "date":
        return (
          <span className="date-cell">{showFormattedDate(value, true)}</span>
        );
      case "concat":
        return row.original[cell?.column?.accessor2]
          ? `${value} - ${row.original[cell?.column?.accessor2]}`
          : value;

      case "actions": {
        const {
          handleBook,
          bookMessage = "Book",
          handleDelte,
          handleChat,
          handleEdit,
          editMessage = "Edit",
        } = cell.column;
        const { original: rowData } = cell.row;

        return (
          <div className="flex justify-start space-x-4">
            {handleBook && (
              <div className="rounded bg-white">
                <button
                  className="table-action-button"
                  title={bookMessage}
                  onClick={() => handleBook(rowData)}
                >
                  <img src={CalendarIcon} alt="book" />
                </button>
              </div>
            )}
            {handleDelte && (
              <div className="rounded bg-white">
                <button
                  className="table-action-button"
                  title="Delete"
                  onClick={() => handleDelte(rowData)}
                >
                  <img src={CloseIcon} alt="book" />
                </button>
              </div>
            )}
            {handleChat && (
              <div className="rounded bg-white">
                <button
                  className="table-action-button"
                  title="Send message"
                  onClick={() => handleChat(rowData)}
                >
                  <img src={MessageIcon} alt="book" />
                </button>
              </div>
            )}
            {handleEdit && (
              <div className="rounded bg-white">
                <button
                  className="table-action-button"
                  title={editMessage}
                  onClick={() => handleEdit(rowData)}
                >
                  <img src={DocumentIcon} alt="book" />
                </button>
              </div>
            )}
          </div>
        );
      }
      default:
        return null;
    }
  };

  const setSearchKeyword = (event) => {
    if (editMode) {
      event.preventDefault();
      confirmIfDiscardEditedContentModal();
    }
    if (event.key === "Enter" && !editMode) {
      event.preventDefault();
      setSearch(event.target.value);
    }
  };

  const resetSearch = (event) => {
    if (editMode) {
      event.preventDefault();
      confirmIfDiscardEditedContentModal();
    }
    if (search && event.target.value === "") {
      setSearch("");
    }
  };

  const confirmIfDiscardEditedContentModal = () => {
    if (editMode) {
      setConfirmDiscardEditing((editModalStatus) => !editModalStatus);
    }
  };
  const discardEditingChanges = () => {
    confirmIfDiscardEditedContentModal();
    resetEditStates();
  };

  return (
    <div className={`${className} new-table`}>
      <div className={cx(toolbarClassName, "toolbar-container")}>
        <div className="breadcrums-container">
          {toolbarSelects && (
            <ToolbarSelects
              toolbarSelects={toolbarSelects}
              setChoiceFilters={setPageFilters}
              choiceFilters={pageFilters}
              updateFilters={updateFilters}
              search={search}
              setPage={setPage}
              initialPageFilters={{
                ...pageFilters,
                sort_dir: "asc",
                per_page: 20,
                handlePagination: true,
              }}
              defaultSort={defaultSort}
            />
          )}
          {breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
        </div>
        <div className={buttonsClassName}>{toolbarButtons}</div>
        <div>
          <div className="navbar-form search search-drop-down relative pull-right">
            <form>
              <div className="field-outer search-bg">
                <i className="fas fa-search" />

                <input
                  placeholder="Search here..."
                  className="setting-search-input search-key"
                  name="term"
                  onKeyDown={setSearchKeyword}
                  onChange={resetSearch}
                  autoComplete="off"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {isTopDivider && <hr className="divider" />}
      <div className={cx(containerClassName, "table-container")}>
        <table
          {...tableInstance.getTableProps()}
          className="table w-full border-collapse table"
        >
          <thead className="text-semibold align-middle bg-gray-100">
            {tableInstance.headerGroups.map((headerGroup) => (
              <tr
                key={`table-header-group-${uuid.v4()}`}
                {...headerGroup.getHeaderGroupProps()}
                className="align-middle text-base font-semibold capitalize tracking-normal"
              >
                {headerGroup.headers.map((column, headerIndex) => (
                  <th
                    className={`border-t-0 border-b-2	py-4 text-left ${
                      headerIndex === 0 ? "pl-4" : null
                    }`}
                    key={`table-header-${uuid.v4()}`}
                    style={{ width: column.width || "120px" }}
                  >
                    <div
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          style: {
                            width: column.width || "100px",
                          },
                        }),
                      )}
                      className={`
                            ${editMode ? "disable-click" : ""}
                            flex items-center space-x-2 ${
                              style === "old" ? "old-table-header" : ""
                            }`}
                    >
                      <span
                        className={`text-sm !font-extrabold text-primaryText align-middle`}
                      >
                        {column.render("Header")}
                      </span>
                      <span className={`table-sort-icons`}>
                        {sortIcons(column.isSorted, column.isSortedDesc)}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...tableInstance.getTableBodyProps()}>
            {!isFetching && tableInstance.rows.length === 0 ? (
              <tr className={`h-[280px] !w-full `}>
                <td colSpan={columns.length} className="text-center">
                  <p>No Data to display</p>
                </td>
              </tr>
            ) : (
              <>
                {tableInstance.rows.map((row, i) => {
                  tableInstance.prepareRow(row);
                  return (
                    <tr
                      key={i}
                      {...row.getRowProps()}
                      className="hover:bg-gray-100"
                      style={{
                        borderTop: "1px solid #EFEFEF",
                      }}
                    >
                      {row.cells.map((cell, tableRowDataIndex) => {
                        const { column, value } = cell;

                        return (
                          <td
                            key={tableRowDataIndex}
                            {...cell.getCellProps()}
                            className={`text-[14px] opacity-[80%] text-primaryText break-word ${
                              cell.column.isSorted ? "sorted" : "not-sorted"
                            }  py-4 ${tableRowDataIndex === 0 ? "pl-4" : null}`}
                          >
                            {editMode && editableColumns.has(column.id)
                              ? renderEditableCell(cell, row)
                              : "type" in column
                              ? handleCustomCell(
                                  cell,
                                  column.type,
                                  value,
                                  row,
                                  column,
                                )
                              : cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {data.length > 0 && !isFetching && canFetchMore && (
                  <tr className="loading-div">
                    <td colSpan={columns.length}>
                      <div ref={loadMoreDiv}></div>
                    </td>
                  </tr>
                )}
                {isFetching && (
                  <tr>
                    <td colSpan={columns.length} className="m-auto text-center">
                      Loading data. Please wait...
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {confirmDiscardEditing && (
        <ConfirmModal
          isOpen={confirmDiscardEditing}
          title="Warning"
          onConfirm={discardEditingChanges}
          onCancel={confirmIfDiscardEditedContentModal}
        >
          Are you sure you want to change filters? Your work will not be saved.
        </ConfirmModal>
      )}
    </div>
  );
}
