import React, { useEffect } from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Section } from "../../shared/Section/Section";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";
import classes from "./Views.module.scss";
import { useSubmit } from "./hooks/useSubmit";
import { useMultiselectEntity } from "../../../../../../../utilities/hooks/useMultiselectEntity";
import { SETTING_VIEW_NAMES } from "../../../../../../../api/patientVitals/config";
import { Maybe } from "../../../../../../../utilities/fp";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { arrayFrom } from "../../../../../../../utilities/general";
import { useSettingViews } from "../../../../hooks/useSettingViews";

const NAMES = Object.values(SETTING_VIEW_NAMES);

export const Views = () => {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();

  const initalValues = useSettingViews();
  const submit = useSubmit();

  const names = useMultiselectEntity({
    allEntities: NAMES,
    initialSelect: initalValues.data,
  });

  const options = NAMES.map((n) => ({
    label: tClients(`vitals.settingViewNames.${n}`),
    value: n,
  }));

  const isDirty = !Maybe.of(initalValues.data)
    .map(
      (v) =>
        v.every((n) => names.isSelected(n)) &&
        v.length === names.selected.length,
    )
    .orElse(true)
    .value();

  useEffect(() => {
    names.setSelected(initalValues.data);
  }, [initalValues.isSuccess]);

  return (
    <Section
      label={tClients("vitals.settings.vitalViews")}
      contentClassName={classes.root}
      onSubmit={() => submit.initiate(names.selected)}
      onReset={() => names.setSelected(initalValues.data)}
      isSubmitting={submit.isLoading}
      isDirty={isDirty}
      isLoading={initalValues.isLoading}
    >
      {initalValues.isLoading ? (
        arrayFrom(NAMES.length + 1).map((k) => (
          <Skeleton key={k} width="160px" height={26} borderRadius="4px" />
        ))
      ) : (
        <>
          <Checkbox
            label={tCommon("label.selectAll")}
            isChecked={names.isSelectedAll()}
            onChange={names.onToggleAll}
            isIntermediate={names.isSelectedPartial()}
          />
          {options.map((o) => (
            <Checkbox
              key={o.value}
              label={o.label}
              isChecked={names.isSelected(o.value)}
              onChange={() => names.onToggleOne(o.value)}
            />
          ))}
        </>
      )}
    </Section>
  );
};
