import { useExportServicesPerformedReport } from "./useExportServicesPerformedReport";

export const useExportExcel = (payload) => {
  const { mutateAsync, isLoading } = useExportServicesPerformedReport(payload);

  return {
    initiate: async () => {
      return await mutateAsync();
    },
    exportLoading: isLoading,
  };
};
