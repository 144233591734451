import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../../../../consts/api";
import { http } from "../../../../../../services/HttpService";
import { removeNullishFromShape } from "../../../../../../utilities/general";
import {
  getNextPaginationPage,
  composePaginationSchema,
} from "../../../../../../schemas/pagination";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { useDebouncedState } from "../../../../../../utilities/hooks/useDebouncedState";
import { apiDateFormat } from "../../../../../../Utils";

const PAGE_SIZE = 15;

const composeResponse = (res) => {
  const procedures = res?.data?.data?.data || [];
  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: PAGE_SIZE,
    }),
    procedures,
  };
};

export function useProcedures(patientId) {
  const { tCommon } = useAppTranslation.Common();

  const [procedurePeriod, setProcedurePeriod] = useState({
    startDate: "",
    endDate: "",
  });

  const [categoryFilter, setCategoryFilter] = useState(null);
  const [providerFilter, setProviderFilter] = useState(null);

  const [procedureSearch, debouncedProcedureSearch, setProcedureSearch] =
    useDebouncedState("");

  const { data, hasNextPage, fetchNextPage, isFetching, refetch, isLoading } =
    useInfiniteQuery({
      queryKey: [
        QUERY_KEYS.patientProcedures,
        patientId,
        categoryFilter?.value,
        providerFilter?.value,
        debouncedProcedureSearch,
        procedurePeriod.startDate,
        procedurePeriod.endDate,
      ],
      queryFn: async ({ pageParam = 1 }) => {
        const res = await http.get(
          HTTP_ENDPOINTS.getPatientProcedures(patientId),
          {
            params: removeNullishFromShape({
              page: pageParam,
              pagesize: PAGE_SIZE,
              charting_category_id: categoryFilter?.value,
              provider_id: providerFilter?.value,
              procedure_name: debouncedProcedureSearch || null,
              min_date: procedurePeriod.startDate
                ? apiDateFormat(procedurePeriod.startDate)
                : null,
              max_date: procedurePeriod.endDate
                ? apiDateFormat(procedurePeriod.endDate)
                : null,
            }),
          },
        );
        return composeResponse(res);
      },
      onError: () => {
        uiNotification.error(tCommon("error.fetchProcedures"));
      },
      staleTime: 0,
      refetchOnWindowFocus: true,
      getNextPageParam: getNextPaginationPage,
    });

  const procedures = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.procedures)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const resetFilters = () => {
    setCategoryFilter(null);
    setProviderFilter(null);
    setProcedureSearch("");
    setProcedurePeriod({ startDate: "", endDate: "" });
  };

  return {
    data: procedures,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch,
    procedurePeriod: {
      value: procedurePeriod,
      update: setProcedurePeriod,
    },
    categoryFilter: {
      value: categoryFilter,
      update: setCategoryFilter,
    },
    providerFilter: {
      value: providerFilter,
      update: setProviderFilter,
    },
    search: {
      value: procedureSearch,
      update: setProcedureSearch,
    },
    resetFilters,
  };
}
