import { useEffect } from "react";
import { useInterval } from "../../../../utilities/hooks/useInterval";
import {
  INVOICE_PAYMENT_AWAIT_REFRESH_RATE,
  INVOICE_PAYMENT_AWAIT_TIMEOUT,
} from "../SalesCheckoutInvoice.consts";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { dispatch } from "../../../../store/store";
import { checkoutInvoice } from "../../../../store/checkoutInvoice";
import { useInvoice } from "./invoice/useInvoice";
import { usePrevious } from "../../../../utilities/hooks/usePrevious";

export function usePaymentsChangeWatcher({
  onPaymentsUpdated,
  onPaymentsRemainUnchanged,
} = {}) {
  const { tCommon } = useAppTranslation.Common();
  const { invoice, refetchInvoice } = useInvoice();

  const previousPaymentsAmount = usePrevious(
    invoice?.paymentTransaction?.totalAmount || 0,
  );

  const { start, stop, isRunning, isTimeoutElapsed } = useInterval({
    actionFn: refetchInvoice,
    intervalMs: INVOICE_PAYMENT_AWAIT_REFRESH_RATE,
    timeoutMs: INVOICE_PAYMENT_AWAIT_TIMEOUT,
    onStart: () => {
      dispatch(checkoutInvoice.actions.changeIsInvoiceWatchMode(true));
    },
    onStop: () => {
      dispatch(checkoutInvoice.actions.changeIsInvoiceWatchMode(false));
    },
  });

  useEffect(() => {
    const currentPaymentsAmount = invoice?.paymentTransaction?.totalAmount;
    if (isRunning && currentPaymentsAmount > previousPaymentsAmount) {
      uiNotification.success(tCommon("success.transaction"));
      stop();
      onPaymentsUpdated?.();
    } else if (isTimeoutElapsed) {
      uiNotification.error(tCommon("error.transaction"));
      onPaymentsRemainUnchanged?.();
    }
  }, [
    previousPaymentsAmount,
    isTimeoutElapsed,
    invoice?.paymentTransaction?.totalAmount,
  ]);

  return {
    watchPaymentsChange: {
      initiate: start,
      terminate: stop,
      isLoading: isRunning,
    },
  };
}
