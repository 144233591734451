import React, { Component } from "react";
import { isSafari } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import queryString from "query-string";
import Loader from "../Common/Loader.js";

const closeWindow = (params) => {
  if (window.opener != null) {
    if (isSafari === true) {
      window.opener.postMessage(
        { childWindow: "response", query: params },
        "*",
      );
    } else {
      window.opener.postMessage({ childWindow: "response", query: params });
    }
    window.close();
  }
};

class ShareReportReturnUrl extends Component {
  constructor(props) {
    super(props);
    const shareReportOptionFilter = JSON.parse(
      localStorage.getItem("shareReportOptionFilter"),
    );
    const query = queryString.parse(this.props.location.search);
    this.state = {
      showLoader: true,
    };

    let params = {
      code: "",
      error: false,
      error_message: false,
    };

    if (
      window.opener != null &&
      !window.opener.closed &&
      shareReportOptionFilter
    ) {
      if (Object.keys(query).length) {
        if (
          query.code !== undefined &&
          query.code !== "" &&
          query.code !== null
        ) {
          params.code = query.code;
        } else if (
          query.error !== undefined &&
          query.error !== "" &&
          query.error !== null
        ) {
          params.error = true;
          params.error_message = query.error_description
            ? query.error_description
            : query.error;
        }
      }
      closeWindow(params);
    } else {
      params.error = true;
      params.error_message = "Unknow error!";
      closeWindow(params);
    }
  }

  render() {
    return (
      <div id="content">
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ShareReportReturnUrl));
