import { useState } from "react";

export const useTemplateForm = () => {
  const [content, setContent] = useState({
    value: "",
    error: null,
  });

  return {
    content: {
      value: content,
      onChange: (value) => {
        if (!value) {
          setContent({ value, error: "Content is required" });
        } else {
          setContent({ value, error: null });
        }
      },
    },
  };
};
