import { ACTION_TYPES } from "./consts";

export const actions = {
  paymentViewChange: ({ paymentView }) => ({
    type: ACTION_TYPES.paymentViewChange,
    payload: {
      paymentView,
    },
  }),

  paymentViewClose: () => ({
    type: ACTION_TYPES.paymentViewClose,
  }),

  changeIsInvoiceWatchMode: (isInvoiceWatchMode) => ({
    type: ACTION_TYPES.changeIsInvoiceWatchMode,
    payload: {
      isInvoiceWatchMode,
    },
  }),

  changeIsCherryPaymentWatchMode: (isCherryPaymentWatchMode) => ({
    type: ACTION_TYPES.changeIsCherryPaymentWatchMode,
    payload: {
      isCherryPaymentWatchMode,
    },
  }),

  changeIsTipsApplying: (isTipsApplying) => ({
    type: ACTION_TYPES.changeIsTipsApplying,
    payload: {
      isTipsApplying,
    },
  }),

  changeIsTipsOnlyFlow: (isTipsOnlyFlow) => ({
    type: ACTION_TYPES.changeIsTipsOnlyFlow,
    payload: {
      isTipsOnlyFlow,
    },
  }),
  changeCalculatedChange: (calculatedChange) => ({
    type: ACTION_TYPES.changeCalculatedChange,
    payload: {
      calculatedChange,
    },
  }),
};
