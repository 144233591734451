import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useEditChargingFilterCategories = (id, options = {}) => {
  return useMutation(
    async (dto) =>
      await http.put(HTTP_ENDPOINTS.putCharginFilterCategories(id), dto),
    options,
  );
};
