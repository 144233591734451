import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  content: yup.string().required(),
});

export function useEfaxTemplateUpdateMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.put(HTTP_ENDPOINTS.efax.updateTemplate(req.id), {
        name: req.name,
        content: req.content,
      });
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.efax.templates],
        });
        queryClient.invalidateQueries({
          refetchType: "active",
          queryKey: [QUERY_KEYS.efax.template],
        });

        options?.onSuccess?.(...args);
      },
    },
  );
}
