import { useAddPosConnectionMutation } from "../../../../../../api/mutations/useAddPosConnectionMutation";
import { useUpdatePosConnectionMutation } from "../../../../../../api/mutations/useUpdatePosConnectionMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { extractApiError } from "../../../../../../utilities/api";

export function useMutate({ clinicId, merchantId, onSuccess }) {
  const { tCommon } = useAppTranslation.Common();

  const create = useAddPosConnectionMutation({
    onSuccess,
    onError: (e) => {
      const key = extractApiError(e);
      uiNotification.error(tCommon([`apiError.mutateMerchantId.${key}`, "error.addMerchantId"]));
    },
  });

  const update = useUpdatePosConnectionMutation({
    onSuccess,
    onError: (e) => {
      const key = extractApiError(e);
      uiNotification.error(tCommon([`apiError.mutateMerchantId.${key}`, "error.addMerchantId"]));
    },
  });

  const initiate = (nextMerchantId) => {
    const payload = {
      clinicId: Number(clinicId),
      merchantId: Number(nextMerchantId),
    };
    if (merchantId) {
      update.mutate(payload);
    } else {
      create.mutate(payload);
    }
  };

  return {
    initiate,
    isLoading: create.isLoading || update.isLoading,
  };
}
