import React from "react";
import InboxSidebar from "../Inbox/InboxSidebar.js";

export default class ComingSoon extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      Please_Wait: languageData.global
        ? languageData.global.Please_Wait
          ? languageData.global.Please_Wait
          : ""
        : "",
    };
  }

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          {this.props.sidebar === "inbox" && <InboxSidebar />}
          <div className="memberWalletOuter business-section">
            {this.props.title && (
              <div className="setting-setion m-b-10">
                <div className="membership-title">{this.props.title}</div>
              </div>
            )}
            {true && (
              <div className="setting-setion m-b-10">
                <div className="membership-title">Coming Soon</div>
              </div>
            )}
            {false && (
              <div className="setting-setion m-b-10 text-center aligin-center">
                <div
                  className="membership-title"
                  style={{ fontSize: "16px", color: "#282f6f" }}
                >
                  Coming Soon...
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
