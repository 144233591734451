import React from "react";
import StripePaymentForm from "../../Components/Sales/FrontdeskCheckout/StripePaymentForm";

export const InputStripeCard = ({ onClose, handleSubmit }) => (
  <>
    <StripePaymentForm
      type="setupIntent"
      onSubmit={() => handleSubmit()}
      onCancel={onClose}
      onSubmitText={"Submit"}
    />
  </>
);
