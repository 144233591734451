import { ACTION_TYPES } from "./consts";

export const actions = {
  signProceduresDataChange: ({ procedureIds, isConsult, isSendToMd }) => ({
    type: ACTION_TYPES.signProceduresDataChange,
    payload: {
      procedureIds,
      isSendToMd: isSendToMd || false,
      isConsult: isConsult || false,
    },
  }),
  signProceduresDataReset: () => ({
    type: ACTION_TYPES.signProceduresDataReset,
  }),
  proceduresSigningStatusChange: (status) => ({
    type: ACTION_TYPES.proceduresSigningStatusChange,
    payload: {
      status,
    },
  }),
  proceduresSignedStatusChange: (status) => ({
    type: ACTION_TYPES.proceduresSignedStatusChange,
    payload: {
      status,
    },
  }),
};
