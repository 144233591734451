import moment from "moment";
import { isNullish } from "../../utilities/general";
import { STATIC_RANGES } from "./DateRangePicker.consts";
import { tCommon } from "../../i18n/useAppTranslation";

export const getStaticRanges = (staticRanges) => {
  if (isNullish(staticRanges)) {
    return undefined;
  }

  const getIsSelected = (range, startDate) => {
    return Boolean(
      range &&
        moment(range.startDate).isSame(moment(startDate), "day") &&
        moment(range.endDate).isSame(new Date(), "day"),
    );
  };

  return [
    {
      range: () => ({
        startDate: moment().startOf("month").toDate(),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        return getIsSelected(range, moment().startOf("month").toDate());
      },
      label: tCommon("label.mtd"),
      key: STATIC_RANGES.mtd,
    },
    {
      range: () => ({
        startDate: moment().startOf("quarter").toDate(),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        return getIsSelected(range, moment().startOf("quarter").toDate());
      },
      label: tCommon("label.qtd"),
      key: STATIC_RANGES.qtd,
    },
    {
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        return getIsSelected(range, moment().startOf("year").toDate());
      },
      label: tCommon("label.ytd"),
      key: STATIC_RANGES.ytd,
    },
  ].filter((r) => staticRanges.includes(r.key));
};
