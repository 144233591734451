import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  HTTP_ENDPOINTS,
  QUERY_KEYS,
  QUESTIONNAIRE_TYPES,
} from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import { MULTI_QUESTION_TYPES } from "./config";

// Schemas

const baseQuestionSchema = yup.object({
  question_type: yup.string().required(),
  question: yup.string().required(),
  required: yup.bool().required(),
  description: yup.bool().required(),
  descriptionText: yup.string().nullable(),
});

const optionsSchema = yup.object({
  options: yup.array().of(yup.string()).required(),
});

const logicSchema = yup.object({
  logicJumpsAns: yup.array().of(yup.number()).nullable(),
  logicJumpsElse: yup.number().nullable(),
  logicJumpsQue: yup.array().of(yup.number()).nullable(),
});

const questionSchemasByType = {
  [MULTI_QUESTION_TYPES.textBox]: baseQuestionSchema,

  [MULTI_QUESTION_TYPES.yesNo]: baseQuestionSchema
    .concat(optionsSchema)
    .concat(logicSchema),

  [MULTI_QUESTION_TYPES.singleChoice]: baseQuestionSchema
    .concat(optionsSchema)
    .concat(logicSchema),

  [MULTI_QUESTION_TYPES.multiChoice]: baseQuestionSchema.concat(optionsSchema),

  [MULTI_QUESTION_TYPES.opinionScale]: baseQuestionSchema
    .concat(optionsSchema)
    .concat(logicSchema)
    .concat(
      yup.object({
        why_choose: yup.bool().required(),
      }),
    ),

  [MULTI_QUESTION_TYPES.fileUpload]: baseQuestionSchema,
};

const isEveryQuestionNodeValid = (nodes) =>
  nodes.every((node) => {
    const schema = questionSchemasByType?.[node?.question_type];
    if (schema) {
      return schema.isValidSync(node, {
        strict: true,
      });
    }
    return false;
  });

const requestSchema = yup.object({
  questionnaire_id: yup.number().nullable(),
  title: yup.string().required(),
  question_data: yup.mixed().test({
    test: isEveryQuestionNodeValid,
  }),
});

// ========================

export function useMultiQuestionnaireMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.questionnaires.createEditOne(),
        removeNullishFromShape({
          ...req,
          type: QUESTIONNAIRE_TYPES.multi,
        }),
      );
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.questionnaires.getAll],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.patientProcedures],
        });
        options?.onSuccess?.();
      },
    },
  );
}
