export const THEME = {
  fonts: {
    primary: "Poppins",
    secondary: "Open Sans",
  },
  colors: {
    white: "#fff",
    black: "#000",

    primary: "#188bf6",
    primaryDim: "#dbeefd",
    primaryDim1: "#7ebaf2",
    primaryDim2: "#e5eef2",
    primaryDim3: "#f9fafb",
    primaryDark: "#217acd",

    red: "#bf2828",
    redFade: "#d55d5d",
    redDim: "#f5e7e7",
    redDim2: "#fff8f8",
    redDim3: "#ffeded",
    redDark: "#972b2b",
    redLighten: "#ffc9c9",

    orange: "#ee971f",
    orangeDim: "#fff2e0",
    orangeDark: "#b2540f",

    green: "#23b023",
    greenDim: "#e2fde2",
    greenDark: "#1a821a",

    yellowDark: "#938E1A",
    yellowDim: "#F9F7C7",

    gray0Dark: "#7e7e7e",
    gray0: "#a4abb2",
    gray1: "#b5b5b5",
    gray2: "#c3c9ce",
    gray3: "#ededed",
    gray4: "#f3f3f3",
    gray5: "#f9f9f9",
  },
};
