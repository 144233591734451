import { http } from "../services/HttpService";

export function doAutoLogin(cipeher) {
  return (dispatch) => {
    http
      .get("switch-to-new/" + cipeher)
      .then((response) => {
        dispatch({ type: "DO_AUTO_LOGIN", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "DO_AUTO_LOGIN", payload: error.response.data });
        }
      });
  };
}

export function AvailableJobs(formData) {
  return (dispatch) => {
    http
      .get("available-jobs", formData)
      .then((response) => {
        dispatch({ type: "AVAILABLE_JOBS_SIMULATOR", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "AVAILABLE_JOBS_SIMULATOR",
            payload: error.response.data,
          });
        }
      });
  };
}

export function RunJob(formData) {
  return async (dispatch) => {
    return http
      .post("run-job", formData)
      .then((response) => {
        dispatch({ type: "RUN_JOB_RESPONSE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "RUN_JOB_RESPONSE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}
