import React from "react";
import PropTypes from "prop-types";
import { OUT_OF_OFFICE_EDIT_TYPE } from "../../Event.consts";
import { EntityRow } from "../EntityRow";
import { Radio } from "../../../../shared/Radio/Radio";

const OutOfOfficeEditType = ({ type, onTypeChange }) => (
  <EntityRow label="Do you want to edit">
    <Radio
      name="allFollowing"
      value={OUT_OF_OFFICE_EDIT_TYPE.current}
      isChecked={type === OUT_OF_OFFICE_EDIT_TYPE.current}
      onChange={() => onTypeChange(OUT_OF_OFFICE_EDIT_TYPE.current)}
      label="Just this out of office event"
    />
    <Radio
      name="allFollowing"
      value={OUT_OF_OFFICE_EDIT_TYPE.allFuture}
      isChecked={type === OUT_OF_OFFICE_EDIT_TYPE.allFuture}
      onChange={() => onTypeChange(OUT_OF_OFFICE_EDIT_TYPE.allFuture)}
      label="All future out of office events"
    />
  </EntityRow>
);

OutOfOfficeEditType.propTypes = {
  type: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
};

export default OutOfOfficeEditType;
