import React from "react";
import PropTypes from "prop-types";
import classes from "./Actions.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { MESSAGES_TYPE } from "../../hooks/useMessages";

export function Actions({
  isMarkReadLoading,
  markRead,
  isMarkUnreadLoading,
  markUnread,
  type,
}) {
  const { tInbox } = useAppTranslation.Inbox();

  return (
    <div className={classes.root}>
      <Button
        size="small"
        isDisabled={isMarkReadLoading}
        onClick={markRead}
        leftAdornment={
          isMarkReadLoading ? (
            <CircularProgress size="tiny" color="white" />
          ) : undefined
        }
      >
        {tInbox("leadAr.action.markRead")}
      </Button>
      {type === MESSAGES_TYPE.all && (
        <Button
          size="small"
          isDisabled={isMarkUnreadLoading}
          leftAdornment={
            isMarkUnreadLoading ? (
              <CircularProgress size="tiny" color="white" />
            ) : undefined
          }
          onClick={markUnread}
        >
          {tInbox("leadAr.action.markUnread")}
        </Button>
      )}
    </div>
  );
}

Actions.propTypes = {
  markRead: PropTypes.func.isRequired,
  isMarkReadLoading: PropTypes.bool.isRequired,
  markUnread: PropTypes.func.isRequired,
  isMarkUnreadLoading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};
