import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { parse } from 'query-string';
import { history } from '../../../../../history';
import validator from 'validator';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TagsInput from 'react-tagsinput'
import {
  getDefaultPackageData,
  searchProductByName,
  uploadImageBookingPortal,
  saveDiscountPackage,
  deleteDiscountPackage,
  activateDiscountPackage,
  emptyInventoryReducer
} from '../../../../../Actions/Inventory/inventoryActions.js';
import 'react-tagsinput/react-tagsinput.css'
import Select from 'react-select';
import { displayName, getCurrencySymbol, isPositiveNumber, dateFormatPicker } from '../../../../../Utils/services.js';
import Sidebar from '../../../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../../../Common/Loader.js'
import { uiNotification } from "../../../../../services/UINotificationService";
import { isNullish } from "../../../../../utilities/general.js";
import { isDerivedFromIncrement } from "../../../../../helpers/general.js";


const apiDateFormat = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

const defaultPackage = () => {
  return { product_id: "", units: "", dollar_value: "", product_name: "", dollar_value_per_unit: "", discount_type: "collective_total" }
};
const defaultErrorPackage = () => {
  return { product_id: false, units: false, dollar_value: false, product_name: false, dollar_value_per_unit: false, discount_type: false }
};

class CreateEditDiscountPackage extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('userData'));
    let languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      packageId: 0,
      discount_types: ['percentage', 'dollars', 'package', 'bogo', 'group'],
      type: 'percentage',
      package_buy_type: 'product',
      name: '',
      promotion_name_for_booking_portal: '',
      promotion_description_for_booking_portal: '',
      customer_type: 'all',
      keywords: [],
      clinics: [],
      providers: [],
      groups: [],
      discount_package_provider: [],
      discount_package_location: [],
      productPackageArr: [defaultPackage()],
      productPackageErrorArr: [defaultErrorPackage()],
      showLoader: false,
      is_in_current_offers: false,
      is_tax_enabled: false,
      all_locations: false,
      all_providers: false,
      active_from: moment().toDate(),
      active_untill: moment().add('years', 1).toDate(),
      active_untill_min_date: new Date(moment().add(1, 'days')),
      current_offer_days: "",
      packageDefaultData: {},
      globalLang: languageData.global,
      "bogo_product_id": 0,
      "bogo_product_quantity": 0,
      "bogo_buy_product_value": 0,
      "bogo_free_product_id": 0,
      "bogo_free_product_quantity": 0,
      "bogo_discount_percentage": '',
      "bogo_free_product_value": '0',
      bogo_product: "",
      bogo_product_error: false,
      bogoProductList: [],
      bogoFreeProductList: [],
      packageProductList: [],
      showBogoProducts: false,
      showBogoFreeProducts: false,
      showPackageProducts: false,
      package_product_price: '',
      package_price_for_members: '',
      nameError: false,
      typeError: false,
      discount_dollarsError: false,
      discount_percentageError: false,
      active_fromError: false,
      active_untillError: false,
      current_offer_daysError: false,
      discount_type: 'free',
      discount_percentage: '',
      inventoryLang: languageData.inventory,
      discount_dollars: '',
      package_bogo_price: '',
      bogo_free_product: '',
      status: 0,
      discount_typeError: false,
      discount_applied_on: "all",
      discount_applied_products: [],
      discount_applied_categories: [],
      discount_applied_product_options: [],
      discount_applied_category_options: [],
      discount_price_type: "collective_total",
      dollar_value_per_unit: '',
      discount_type_bogo_buy: 'collective_total',
      discount_type_bogo_free: 'collective_total',
      bogo_buy_product_value_per_unit: "",
      bogo_free_product_value_per_unit: "0",
      discount_applied_category_optionsClass: 'simpleField',
      discount_applied_product_optionsClass: 'simpleField',
      dollar_value_per_unitClass: 'simpleInput',
      bogo_buy_product_value_per_unitClass: "simpleInput",
      bogo_free_product_value_per_unitClass: "simpleInput",
      image_booking_portal: false,
      image_booking_portal_url: "",
      is_available_online: 0,
      userData: user,
      obPrivilegeEnabled: user.permissions.includes('booking-portal-configuration'),
      isCloneMode: parse(window.location.search)?.clone === 'true',
    };
  }

  showLoaderFunc = () => {
    this.setState({ showLoader: true });
    localStorage.setItem("showLoader", false);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOnClick, false);
    window.onscroll = () => {
      return false;
    }
    const packageId = (this.props.match.params.id) ? (this.props.match.params.id) : 0;
    let formData = { 'params': {} }
    this.setState({ packageId: packageId })
    this.showLoaderFunc();
    this.props.getDefaultPackageData(packageId);
    //disable datepicker input-field from manually enter the value
    const datePicker1 = document.getElementsByClassName("react-datepicker__input-container")[0];
    datePicker1.childNodes[0].setAttribute("readOnly", true);
    const datePicker2 = document.getElementsByClassName("react-datepicker__input-container")[1];
    datePicker2.childNodes[0].setAttribute("readOnly", true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingImage && this.props.bookingImage) {
      if (prevProps.bookingImage.status !== "loading" && this.props.bookingImage.status === "loading") {
        this.setState({ showLoader: true })
      }

      if (prevProps.bookingImage.status === "loading" && this.props.bookingImage.status !== "loading") {
        this.setState({ showLoader: false, image_booking_portalError: false })
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let returnState = {};
    if (nextProps.savedData != undefined && nextProps.savedData == true) {
      if (localStorage.getItem("showLoader") == "false") {
        setTimeout(() => {
          uiNotification.success(nextProps.message)

        }, 0);
        nextProps.history.push('/settings/discount-packages');
      }
    }
    else if (nextProps.redirect != undefined && nextProps.redirect == true) {
      setTimeout(() => {
        uiNotification.success(nextProps.message);

      }, 0);
      nextProps.history.push('/settings/discount-packages');
    }
    else if (nextProps.packageDefaultData !== undefined && nextProps.packageDefaultData !== prevState.packageDefaultData) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        returnState.packageDefaultData = nextProps.packageDefaultData;
        returnState.clinics = nextProps.packageDefaultData.clinics;
        returnState.providers = nextProps.packageDefaultData.providers;
        returnState.groups = (nextProps.packageDefaultData.discount_group) ? nextProps.packageDefaultData.discount_group : [];


        let discount_applied_products = [];
        let discount_applied_categories = [];
        let discount_applied_product_options = [];
        let discount_applied_category_options = [];
        let discount_applied_on = 'all';
        if (nextProps.packageDefaultData.categories && nextProps.packageDefaultData.categories.length > 0) {
          nextProps.packageDefaultData.categories.map((obj) => {
            discount_applied_category_options.push({
              label: (obj.category_name) ? obj.category_name : '',
              value: obj.id,
              id: obj.id,
            })
          })
        }
        if (nextProps.packageDefaultData.products && nextProps.packageDefaultData.products.length > 0) {
          nextProps.packageDefaultData.products.map((obj) => {
            discount_applied_product_options.push({
              label: (obj.product_name) ? obj.product_name : '',
              value: obj.id,
              id: obj.id,
            })
          })
        }

        if (nextProps.packageDefaultData.clinics && !prevState.userChanged) {
          nextProps.packageDefaultData.clinics.map((obj, idx) => {
            let clinicTax = 0
            if (obj.package_clinic_tax !== undefined && obj.package_clinic_tax !== null) {
              if (obj.package_clinic_tax.tax_percentage !== undefined && obj.package_clinic_tax.tax_percentage !== null) {
                clinicTax = Number(obj.package_clinic_tax.tax_percentage).toFixed(4);
              }
            } else {
              clinicTax = obj.tax
            }
            returnState['clinicTax-' + obj.id] = Number(clinicTax).toFixed(4);
            returnState['clinicTaxError-' + obj.id] = false;
          })
        }

        if (prevState.packageId) {
          let packageData = (nextProps.packageDefaultData.discount_package) ? nextProps.packageDefaultData.discount_package : {};

          const fromDate = prevState.isCloneMode
            ? moment().toDate()
            : moment(packageData.active_from).toDate();

          returnState.active_from = (prevState.userChanged) ? prevState.active_from : (packageData.active_from && moment(packageData.active_from).isValid()) ? fromDate : null;

          const untilDate = prevState.isCloneMode
            ? moment().add(1, "day").toDate()
            : moment(packageData.active_untill).toDate();

          returnState.active_untill = (prevState.userChanged) ? prevState.active_untill : (packageData.active_untill && moment(packageData.active_untill).isValid()) ? untilDate : null;
          returnState.active_untill_min_date = (returnState.active_from) ? new Date(moment(returnState.active_from).add(1, 'days')) : new Date()

          returnState.all_locations = (prevState.userChanged) ? prevState.all_locations : packageData.all_locations;
          returnState.all_providers = (prevState.userChanged) ? prevState.all_providers : packageData.all_providers;
          returnState.bogo_buy_product_value = (prevState.userChanged) ? prevState.bogo_buy_product_value : packageData.bogo_buy_product_value;
          returnState.bogo_buy_type = (prevState.userChanged) ? prevState.bogo_buy_type : packageData.bogo_buy_type;
          returnState.bogo_discount_percentage = (prevState.userChanged) ? prevState.bogo_discount_percentage : (packageData.bogo_discount_percentage) ? packageData.bogo_discount_percentage : '';
          returnState.discount_type = ((packageData.bogo_discount_percentage)) ? 'percentage' : 'free';
          returnState.bogo_free_product_id = (prevState.userChanged) ? prevState.bogo_free_product_id : (packageData.bogo_free_product_id) ? packageData.bogo_free_product_id : '';
          returnState.bogo_free_product_quantity = (prevState.userChanged) ? prevState.bogo_free_product_quantity : packageData.bogo_free_product_quantity;
          returnState.bogo_free_product_value = (prevState.userChanged) ? prevState.bogo_free_product_value : (packageData.bogo_free_product_value) ? packageData.bogo_free_product_value : '0';
          returnState.bogo_free_type = (prevState.userChanged) ? prevState.bogo_free_type : packageData.bogo_free_type;
          returnState.bogo_product_id = (prevState.userChanged) ? prevState.bogo_product_id : packageData.bogo_product_id;

          if (packageData.bogo_buy_type === 'group') {

            const product = nextProps.packageDefaultData?.discount_group?.find(x => x.id === returnState.bogo_product_id);
            const freeProduct = nextProps.packageDefaultData?.discount_group?.find(x => x.id === returnState.bogo_free_product_id);

            returnState.bogo_group_product_min = product?.min_start_at_unit;
            returnState.bogo_group_free_product_min = freeProduct?.min_start_at_unit;
            returnState.bogo_group_product_count_by = product?.min_count_units_by;
            returnState.bogo_group_free_product_count_by = freeProduct?.min_count_units_by;

          }

          returnState.bogo_product_quantity = (prevState.userChanged) ? prevState.bogo_product_quantity : packageData.bogo_product_quantity;
          returnState.current_offer_days = (prevState.userChanged) ? prevState.current_offer_days : packageData.current_offer_days;
          returnState.customer_type = (prevState.userChanged) ? prevState.customer_type : packageData.customer_type;
          returnState.discount_dollars = (prevState.userChanged) ? prevState.discount_dollars : packageData.discount_dollars;
          returnState.discount_percentage = (prevState.userChanged) ? Number(prevState.discount_percentage).toFixed(3) : Number(packageData.discount_percentage).toFixed(3);
          returnState.package_product_price = (prevState.userChanged) ? prevState.package_product_price : packageData.package_bogo_price;
          returnState.is_in_current_offers = (prevState.userChanged) ? prevState.is_in_current_offers : packageData.is_in_current_offers;
          returnState.is_tax_enabled = (prevState.userChanged) ? prevState.is_tax_enabled : packageData.is_tax_enabled;
          returnState.keywords = (prevState.userChanged) ? prevState.keywords : (packageData.keywords) ? packageData.keywords.split(',') : [];
          returnState.name = (prevState.userChanged) ? prevState.name : prevState.isCloneMode ? '' : packageData.name;
          returnState.package_buy_type = (prevState.userChanged) ? prevState.package_buy_type : packageData.package_buy_type;
          returnState.package_product_id = (prevState.userChanged) ? prevState.package_product_id : packageData.package_product_id;
          returnState.package_product_quantity = (prevState.userChanged) ? prevState.package_product_quantity : packageData.package_product_quantity;
          returnState.status = (prevState.userChanged) ? prevState.status : packageData.status;
          returnState.type = (prevState.userChanged) ? prevState.type : packageData.type;
          returnState.package_bogo_price = (prevState.userChanged) ? prevState.package_bogo_price : packageData.package_bogo_price;
          returnState.package_price_for_members = (prevState.userChanged) ? prevState.package_price_for_members : (packageData.package_price_for_members) ? packageData.package_price_for_members : '0.00';
          returnState.promotion_name_for_booking_portal = (prevState.userChanged) ? prevState.promotion_name_for_booking_portal : packageData.promotion_name_for_booking_portal;
          returnState.promotion_description_for_booking_portal = (prevState.userChanged) ? prevState.promotion_description_for_booking_portal : packageData.promotion_description_for_booking_portal;
          returnState.image_booking_portal = (prevState.userChanged) ? prevState.image_booking_portal : packageData.image_booking_portal;
          returnState.image_booking_portal_url = packageData.image_booking_portal_url;
          returnState.is_available_online = (prevState.userChanged) ? prevState.is_available_online : packageData.is_available_online;

          if (packageData.type == 'bogo' && !prevState.userChanged && packageData.bogo_buy_type != 'group') {
            returnState.bogo_free_product_id = packageData.bogo_offer_free_product_id;
            returnState.bogo_product_id = packageData.bogo_offer_product_id;
            returnState.bogo_free_product_quantity = packageData.bogo_offer_free_product_quantity;
            returnState.bogo_product_quantity = packageData.bogo_offer_product_quantity;
            let bogo_product = packageData.product_names.find(y => y.id === packageData.bogo_offer_product_id);
            let bogo_free_product = packageData.product_names.find(y => y.id === packageData.bogo_offer_free_product_id);
            returnState.bogo_product = bogo_product.product_name;
            returnState.bogo_free_product = (bogo_free_product.product_name) ? bogo_free_product.product_name : '';
            returnState.bogo_product_min = bogo_product.start_at_unit;
            returnState.bogo_free_product_min = bogo_free_product.start_at_unit;
            returnState.bogo_product_count_by = bogo_product.count_units_by;
            returnState.bogo_free_product_count_by = bogo_free_product.count_units_by;
          }

          if (packageData.bogo_buy_type == 'group' && !prevState.userChanged) {
            returnState.type = packageData.bogo_buy_type;
          }

          if (nextProps.packageDefaultData.discount_package_products && nextProps.packageDefaultData.discount_package_products.length && !prevState.userChanged) {
            let productPackageArr = [];
            let productPackageErrorArr = [];
            nextProps.packageDefaultData.discount_package_products.map((obj) => {
              productPackageArr.push({
                product_id: obj.product_id,
                product_name: (obj.product) ? obj.product.product_name : '',
                dollar_value: obj.dollar_value,
                units: obj.units,
                id: obj.id,
                discount_type: (obj.discount_type) ? obj.discount_type : 'collective_total',
                dollar_value_per_unit: obj.dollar_value_per_unit,
                min: obj.product?.start_at_unit,
                count_by: obj.product?.count_units_by,
              })
              productPackageErrorArr.push(defaultErrorPackage());
            })
            returnState.productPackageArr = productPackageArr;
            returnState.productPackageErrorArr = productPackageErrorArr;
          }

          if (packageData.discount_package_location && packageData.discount_package_location.length && !prevState.userChanged) {
            let discount_package_location = [];
            packageData.discount_package_location.map((obj, idx) => {
              let clinic = nextProps.packageDefaultData.clinics.find(y => y.id === obj.clinic_id);
              if (clinic) {
                discount_package_location.push({ value: obj.clinic_id, label: clinic.clinic_name })
              }
            });
            returnState.discount_package_location = discount_package_location;
          }
          if (packageData.discount_package_provider && packageData.discount_package_provider.length && !prevState.userChanged) {
            let discount_package_provider = [];
            packageData.discount_package_provider.map((obj, idx) => {
              let provider = nextProps.packageDefaultData.providers.find(y => y.id === obj.provider_id);
              if (provider) {
                discount_package_provider.push({ value: obj.provider_id, label: displayName(provider) })
              }
            });
            returnState.discount_package_provider = discount_package_provider;
          }
          returnState.discount_group_id = (prevState.userChanged) ? prevState.discount_group_id : (packageData.discount_group_id) ? packageData.discount_group_id : 0;

          if (packageData.type == 'percentage' || packageData.type == 'dollars') {
            discount_applied_on = packageData.discount_applied_on;
            if (packageData.discount_package_applied_products && packageData.discount_package_applied_products.length > 0) {
              packageData.discount_package_applied_products.map((obj) => {
                if (obj.product) {
                  discount_applied_products.push({
                    label: (obj.product.product_name) ? obj.product.product_name : '',
                    value: obj.product.id,
                    id: obj.product.id,
                  })
                }
              })
            }
            if (packageData.discount_package_applied_categories && packageData.discount_package_applied_categories.length > 0) {
              packageData.discount_package_applied_categories.map((obj) => {
                if (obj.category) {
                  discount_applied_categories.push({
                    label: (obj.category.category_name) ? obj.category.category_name : '',
                    value: obj.category.id,
                    id: obj.category.id,
                  })
                }
              })
            }
          }
          returnState.bogo_buy_product_value_per_unit = packageData.bogo_buy_product_value_per_unit;
          returnState.bogo_free_product_value_per_unit = (packageData.bogo_free_product_value_per_unit) ? packageData.bogo_free_product_value_per_unit : '0';
          returnState.discount_type_bogo_buy = (packageData.discount_type_bogo_buy) ? packageData.discount_type_bogo_buy : 'collective_total';
          returnState.discount_type_bogo_free = (packageData.discount_type_bogo_free) ? packageData.discount_type_bogo_free : 'collective_total';
        }

        returnState.discount_applied_on = discount_applied_on;
        returnState.discount_applied_products = discount_applied_products;
        returnState.discount_applied_categories = discount_applied_categories;
        returnState.discount_applied_product_options = discount_applied_product_options;
        returnState.discount_applied_category_options = discount_applied_category_options;
      }
    }
    else if (nextProps.bogoProductList != undefined && nextProps.bogoProductList.products != prevState.bogoProductList) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        returnState.bogoProductList = nextProps.bogoProductList.products;
        returnState.showBogoProducts = true;

      }
    }
    else if (nextProps.bogoFreeProductList != undefined && nextProps.bogoFreeProductList.products != prevState.bogoFreeProductList) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        returnState.bogoFreeProductList = nextProps.bogoFreeProductList.products;
        returnState.showBogoFreeProducts = true;
      }
    }
    else if (nextProps.packageProductList != undefined && nextProps.packageProductList.products != prevState.packageProductList) {
      returnState.showLoader = false;
      returnState.packageProductList = nextProps.packageProductList.products;
      returnState["showPackageProducts-" + prevState.packageindex] = true;
    } else if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.emptyInventoryReducer()
      returnState.showLoader = false;
      return returnState;
    }
    return returnState;
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOnClick, false);
  }

  handleOnClick = (e) => {
    if (this.ref_bogo_free_product_container !== undefined && this.ref_bogo_free_product_container !== null && !this.ref_bogo_free_product_container.contains(e.target) && (this.state.bogo_free_product_focus !== undefined && this.state.bogo_free_product_focus === true)) {
      this.setState({ bogo_free_product_focus: false, bogo_free_product_id: 0, bogo_free_product: '' })
    }
    if (this.ref_bogo_product_container !== undefined && this.ref_bogo_product_container !== null && !this.ref_bogo_product_container.contains(e.target) && (this.state.bogo_product_focus !== undefined && this.state.bogo_product_focus === true)) {
      this.setState({ bogo_product_focus: false, bogo_product_id: 0, bogo_product: '' })
    }
    if (this.ref_product_name_container !== undefined && this.ref_product_name_container !== null && !this.ref_product_name_container.contains(e.target)) {
      const state = this.state;
      for (var idx in state) {
        if (idx.includes("product_name_focus_")) {
          if (state[idx] === true) {
            const index = idx.split('_').pop();
            let returnState = {};
            const productPackageArr = this.state.productPackageArr;
            if (productPackageArr[index] !== undefined) {
              productPackageArr[index]["product_id"] = '';
              productPackageArr[index]["product_name"] = '';
              returnState.productPackageArr = productPackageArr;
              returnState["showPackageProducts-" + index] = false;
            }
            returnState[idx] = false
            this.setState(returnState)
          }
        }
      }
    }
  }

  handleTaxOnBlur = event => {
    if (event.target.name === "discount_percentage") {
      this.setState({
        discount_percentage: Number(event.target.value).toFixed(3)
      })
    } else {
      this.setState({
        [event.target.name]: Number(event.target.value).toFixed(4)
      })
    }
  }

  handleInputChange = event => {
    const target = event.target;
    let value = [];
    if (target.type === "checkbox") {
      value = target.checked
    } else if (target.type === ["image/png", "image/PNG", "image/jpg", "image/JPG"]) {
      value = target.files[0]
    } else {
      value = target.value
    }

    const packageindex = event.target.dataset.packageindex;
    if (packageindex) {
      const productPackageArr = this.state.productPackageArr;
      productPackageArr[packageindex][target.name] = value;
      this.setState({ productPackageArr: productPackageArr });
    } else {
      this.setState({
        [event.target.name]: value,
        userChanged: true
      });
    }
  }

  handleSearchDatePicker = (name, date) => {
    let returnState = {}
    returnState[name] = date
    this.setState(returnState);

    // reset active_till greater than from active_from
    if (name === 'active_from' && date) {
      let active_untill = (this.state.active_untill) ? this.state.active_untill : new Date()
      if (!moment(active_untill).isAfter(date)) {
        this.setState({
          active_untill: new Date(moment(date).add(1, 'years')),
          active_untill_min_date: new Date(moment(date).add(1, 'days'))
        });
      } else {
        this.setState({
          active_untill_min_date: new Date(moment(date).add(1, 'days'))
        });
      }
    }
  }

  handleTagChange = (value) => {
    this.setState({ keywords: value, userChanged: true });
  }

  handleChange = (selectedOption) => {
    this.setState({
      discount_package_location: selectedOption,
      userChanged: true
    });
    if (selectedOption.length > 0) {
      this.setState({ all_locations: true })
    } else {
      this.setState({ all_locations: false })
    }
  }

  handleProviderChange = (selectedOption) => {
    this.setState({
      discount_package_provider: selectedOption,
      userChanged: true
    });
    if (selectedOption.length > 0) {
      this.setState({ all_providers: true })
    } else {
      this.setState({ all_providers: false })
    }
  }

  handleProductChange = (mode, event) => {
    const target = event.target;
    let value = target.value;
    let returnState = {}
    let packageindex = -1
    if (mode == 'package') {
      packageindex = event.currentTarget.dataset.packageindex;
      const productPackageArr = this.state.productPackageArr;
      if (productPackageArr[packageindex]["product_name"] == value) {
        return false;
      }
      productPackageArr[packageindex]["product_name"] = value;
      productPackageArr[packageindex]["product_id"] = '';

      // close auto-suggestion block for other product name
      const state = this.state;
      for (var idx in state) {
        if (idx.includes("product_name_focus_")) {
          if (state[idx] === true && !idx.includes("product_name_focus_" + packageindex)) {
            this.setState({ [idx]: false })
          }
        }
      }

      this.setState({ productPackageArr: productPackageArr, packageindex: packageindex, ['product_name_focus_' + packageindex]: true });
    } else {
      returnState[event.target.name] = value;
      if (mode == 'bogo_product') {
        returnState.bogo_product_id = 0;
      } else if (mode == 'bogo_free_product') {
        returnState.bogo_free_product_id = 0
      }
    }
    this.setState(returnState);
    let formData = { params: {} }
    formData.params.term = value.trim();
    if (mode == 'bogo_product' && this.state.bogo_free_product_id) {
      //formData.params.selected_product_ids = this.state.bogo_free_product_id;
    } else if (mode == 'bogo_free_product' && this.state.bogo_product_id) {
      //formData.params.selected_product_ids = this.state.bogo_product_id;
    } else if (mode == 'package') {
      let selected_product_ids = []
      this.state.productPackageArr.map((obj, idx) => {
        if (obj.product_id && packageindex != idx)
          selected_product_ids.push(obj.product_id);
      })
      if (selected_product_ids.length) {
        let formIds = selected_product_ids.join(',')
        formData.params.selected_product_ids = formIds;
      }
    }
    if (typeof value === 'string') {
      if (value.length >= 2) {
        value = value.trim()
        this.props.searchProductByName(formData, mode);
      }
    }
  }

  handleOnBlur = (mode, event) => {
    this.setState({ [mode]: false })
  }

  handleOnFocus = (mode, index, event) => {
    index = index || 0
    if (mode === 'product_name_focus') {
      this.setState({ ['product_name_focus_' + index]: false })
    } else {
      this.setState({ [mode]: true })
    }
  }

  selectProduct = (event) => {
    let mode = event.currentTarget.dataset.mode;
    let id = event.currentTarget.dataset.id;
    let pname = event.currentTarget.dataset.pname;

    const min = event.currentTarget.dataset.min;
    const countBy = event.currentTarget.dataset.step;

    if (mode == 'bogo_product') {
      this.setState({
        bogo_product_id: id,
        bogo_product: pname,
        showBogoProducts: false,
        bogo_product_focus: false,
        bogo_product_min: min,
        bogo_product_count_by: countBy,
      });
    } else if (mode == 'bogo_free_product') {
      this.setState({
        bogo_free_product_id: id,
        bogo_free_product: pname,
        showBogoFreeProducts: false,
        bogo_free_product_focus: false,
        bogo_free_product_min: min,
        bogo_free_product_count_by: countBy,
      });
    }
  }

  selectProductPackage = (event) => {
    let id = event.currentTarget.dataset.id;
    let index = event.currentTarget.dataset.index;
    let pname = event.currentTarget.dataset.pname;

    const min = event.currentTarget.dataset.min;
    const countBy = event.currentTarget.dataset.step;

    let returnState = {};

    const productPackageArr = this.state.productPackageArr;

    productPackageArr[index]["product_id"] = id;
    productPackageArr[index]["product_name"] = pname;
    productPackageArr[index]["min"] = min;
    productPackageArr[index]["count_by"] = countBy;

    returnState.productPackageArr = productPackageArr;
    returnState["showPackageProducts-" + index] = false;
    returnState['product_name_focus_' + index] = false
    this.setState(returnState);
  }

  addMoreProduct = () => {
    const productPackageArr = this.state.productPackageArr;
    const productPackageErrorArr = this.state.productPackageErrorArr;
    let returnState = {};
    let tmpInventoryPackage = defaultPackage();
    //tmpInventoryPackage.inventoryClinic = this.state.clinics[0].id
    productPackageArr.push(tmpInventoryPackage);
    productPackageErrorArr.push(defaultErrorPackage());
    this.setState({ productPackageArr: productPackageArr, productPackageErrorArr: productPackageErrorArr });
  }

  removeCurrentProduct = (id) => {
    const productPackageArr = this.state.productPackageArr;
    const productPackageErrorArr = this.state.productPackageErrorArr;
    if (productPackageArr.length == 1) { return false }
    let servicepackageindex = id;
    productPackageArr.splice(servicepackageindex, 1);
    productPackageErrorArr.splice(servicepackageindex, 1);

    const state = this.state
    for (var idx in state) {
      if (idx.includes("product_name_focus_")) {
        this.setState({ [idx]: false })
      }
    }

    this.setState({ productPackageArr: productPackageArr, productPackageErrorArr: productPackageErrorArr });
  }

  handleSubmit = () => {
    this.setState({
      nameError: false,
      typeError: false,
      discount_percentageError: false,
      discount_dollarsError: false,
      active_untillError: false,
      active_fromError: false,
      discount_group_idError: false,
      package_product_priceError: false,
      package_price_for_membersError: false,
      current_offer_daysError: false,
      package_product_quantityError: false,
      bogo_productError: false,
      bogo_product_quantityError: false,
      bogo_buy_product_valueError: false,
      bogo_free_product_quantityError: false,
      bogo_free_product_valueError: false,
      package_bogo_priceError: false,
      bogo_product_idError: false,
      bogo_discount_percentageError: false,
      discount_typeError: false,
      promotion_nameError: false,
      promotion_descriptionError: false,
      image_booking_portalError: false,
      discount_applied_category_optionsClass: 'simpleField',
      discount_applied_product_optionsClass: 'simpleField',
      bogo_buy_product_value_per_unitClass: 'simpleInput',
      bogo_free_product_value_per_unitClass: 'simpleInput',
    });

    if (this.state.name === '') {
      this.setState({ nameError: true });
      uiNotification.error('Please provide discount name');
      return false
    }

    if (this.isOnlineBookingAvailable()) {
      if (!this.state.promotion_name_for_booking_portal && this.state.is_available_online) {
        this.setState({ promotion_nameError: true });
        return false
      }

      if (!this.state.promotion_description_for_booking_portal && this.state.is_available_online) {
        this.setState({ promotion_descriptionError: true });
        return false
      }

      if ((this.state.image_booking_portal_url === '' && !this.props.bookingImage.data) && this.state.is_available_online) {
        this.setState({ image_booking_portalError: true });
        return false
      }
    }

    if (this.state.type == '' || this.state.type == undefined || this.state.type == null) {
      this.setState({ discount_typeError: true });
      return false
    }

    if (this.state.type == 'percentage') {
      const percent = Number(this.state.discount_percentage);
      if (percent <= 0 || percent > 100) {
        this.setState({ discount_percentageError: true });
        uiNotification.error('Discount percentage should be greater than 0 and less than 100');
        return false
      }

      if (this.state.discount_applied_on == 'category' && this.state.discount_applied_categories.length <= 0) {
        this.setState({ discount_applied_category_optionsClass: 'simpleField field-error' });
        return false;
      }
      if (this.state.discount_applied_on == 'product' && this.state.discount_applied_products.length <= 0) {
        this.setState({ discount_applied_product_optionsClass: 'simpleField field-error' });
        return false;
      }

    } else if (this.state.type == 'dollars') {
      if (this.state.discount_dollars == '' || !isPositiveNumber(this.state.discount_dollars)) {
        this.setState({ discount_dollarsError: true });
        return false
      }
      if (this.state.discount_applied_on == 'category' && this.state.discount_applied_categories.length <= 0) {
        this.setState({ discount_applied_category_optionsClass: 'simpleField field-error' });
        return false;
      }
      if (this.state.discount_applied_on == 'product' && this.state.discount_applied_products.length <= 0) {
        this.setState({ discount_applied_product_optionsClass: 'simpleField field-error' });
        return false;
      }
    } else if (this.state.type == 'package') {
      let dollarSum = 0;
      let productPackageErrorArr = []
      if (this.state.package_buy_type == 'product') {
        productPackageErrorArr = this.state.productPackageErrorArr;
        this.state.productPackageArr.map((obj, idx) => {
          productPackageErrorArr[idx].product_name = false;
          productPackageErrorArr[idx].units = false;
          productPackageErrorArr[idx].dollar_value = false;
          productPackageErrorArr[idx].dollar_value_per_unit = false;
          productPackageErrorArr[idx].discount_type = false;
        })
        let error = false;

        this.state.productPackageArr.map((obj, idx) => {
          if (obj.product_id == '') {
            productPackageErrorArr[idx].product_name = true;
            error = true;
          }
          if (obj.units == '' || obj.units < 0.001 || !validator.isFloat(String(obj.units))) {
            productPackageErrorArr[idx].units = true;
            error = true;
            uiNotification.error("Quantity should be greater than 0.001");
          }

          if (!isNullish(obj.min) && !isNullish(obj.count_by)) {
            if (Number(obj.units) < obj.min) {
              productPackageErrorArr[idx].units = true;
              error = true;
              uiNotification.error('Minimum units should be ' + obj.min);
            }

            if (!isDerivedFromIncrement(Number(obj.units), Number(obj.count_by))) {
              productPackageErrorArr[idx].units = true;
              error = true;
              uiNotification.error('Units should be in increment of ' + obj.count_by);
            }
          }

          if (obj.discount_type === 'dollar_per_unit') {
            if (obj.dollar_value_per_unit == '' || !isPositiveNumber(obj.dollar_value_per_unit)) {
              productPackageErrorArr[idx].dollar_value_per_unit = true;
              error = true;
            } else {
              dollarSum += parseFloat(parseFloat(obj.units) * parseFloat(obj.dollar_value_per_unit));
            }
          } else {
            if (obj.dollar_value == '' || !isPositiveNumber(obj.dollar_value)) {
              productPackageErrorArr[idx].dollar_value = true;
              error = true;
            } else {
              dollarSum += parseFloat(obj.dollar_value)
            }
          }
        })
        if (error) {
          this.setState({ productPackageErrorArr: productPackageErrorArr });
          return false;
        }
      } else {
        if (this.state.discount_group_id == 0) {
          this.setState({ discount_group_idError: true });
          return false;
        }
        if (this.state.package_product_quantity == '' || !isPositiveNumber(this.state.package_product_quantity, 1)) {
          this.setState({ package_product_quantityError: true });
          return false;
        }
      }

      if (this.state.package_product_price == '' || !isPositiveNumber(this.state.package_product_price)) {
        this.setState({ package_product_priceError: true });
        return false;
      } else {
        if (this.state.package_buy_type == 'product' && parseFloat(dollarSum).toFixed(2) !== parseFloat(this.state.package_product_price).toFixed(2)) {
          uiNotification.error(this.state.inventoryLang.inventory_msg)
          this.state.productPackageArr.map((obj, idx) => {
            productPackageErrorArr[idx].dollar_value = (obj.discount_type === 'collective_total') ? true : false;
            productPackageErrorArr[idx].dollar_value_per_unit = (obj.discount_type === 'dollar_per_unit') ? true : false;
          })
          this.setState({ package_product_priceError: true, productPackageErrorArr: productPackageErrorArr });
          return false;
        }
      }

      if (this.state.customer_type === 'all') {
        if (this.state.package_price_for_members != undefined && this.state.package_price_for_members != null && this.state.package_price_for_members != '' && !isPositiveNumber(this.state.package_price_for_members)) {
          this.setState({ package_price_for_membersError: true });
          return false;
        }
      }
      /*if(this.state.package_price_for_members == '' || !isNumber(this.state.package_price_for_members)) {
        this.setState({package_price_for_membersError: true});
        return false;
      }*/

    } else if (this.state.type == 'bogo') {
      let dollarSum = 0;
      if (this.state.bogo_product_id == '') {
        this.setState({ bogo_productError: true });
        return false;
      }

      if (!isNullish(this.state.bogo_product_min) && !isNullish(this.state.bogo_product_count_by)) {
        if (Number(this.state.bogo_product_quantity) < this.state.bogo_product_min) {
          this.setState({ bogo_product_quantityError: true });
          uiNotification.error('Minimum units should be ' + this.state.bogo_product_min);
          return false;
        }

        if (!isDerivedFromIncrement(Number(this.state.bogo_product_quantity), Number(this.state.bogo_product_count_by))) {
          this.setState({ bogo_product_quantityError: true });
          uiNotification.error('Units should be in increment of ' + this.state.bogo_product_count_by);
          return false;
        }
      }

      if (this.state.bogo_product_quantity == '' || !isPositiveNumber(this.state.bogo_product_quantity, null, null, 4)) {
        this.setState({ bogo_product_quantityError: true });
        return false;
      }
      if (this.state.discount_type_bogo_buy === 'dollar_per_unit') {
        if (this.state.bogo_buy_product_value_per_unit == '' || !isPositiveNumber(this.state.bogo_buy_product_value_per_unit)) {
          this.setState({ bogo_buy_product_value_per_unitClass: 'simpleInput field-error' });
          return false;
        } else {
          dollarSum += parseFloat(this.state.bogo_product_quantity) * parseFloat(this.state.bogo_buy_product_value_per_unit);
        }
      } else {
        if (this.state.bogo_buy_product_value == '' || !isPositiveNumber(this.state.bogo_buy_product_value)) {
          this.setState({ bogo_buy_product_valueError: true });
          return false;
        } else {
          dollarSum += parseFloat(this.state.bogo_buy_product_value);
        }
      }

      if (this.state.bogo_free_product_id == '') {
        this.setState({ bogo_free_productError: true });
        return false;
      }

      if (!isNullish(this.state.bogo_free_product_min) && !isNullish(this.state.bogo_free_product_count_by)) {
        if (Number(this.state.bogo_free_product_quantity) < this.state.bogo_free_product_min) {
          this.setState({ bogo_free_product_quantityError: true });
          uiNotification.error('Minimum units should be ' + this.state.bogo_free_product_min);
          return false;
        }

        if (!isDerivedFromIncrement(Number(this.state.bogo_free_product_quantity), Number(this.state.bogo_free_product_count_by))) {
          this.setState({ bogo_free_product_quantityError: true });
          uiNotification.error('Units should be in increment of ' + this.state.bogo_free_product_count_by);
          return false;
        }
      }

      if (this.state.bogo_free_product_quantity == '' || !isPositiveNumber(this.state.bogo_free_product_quantity, null, null, 4)) {
        this.setState({ bogo_free_product_quantityError: true });
        return false;
      }
      if (this.state.discount_type_bogo_free === 'dollar_per_unit') {
        if (this.state.bogo_free_product_value_per_unit == '' || !isPositiveNumber(this.state.bogo_free_product_value_per_unit)) {
          this.setState({ bogo_free_product_value_per_unitClass: 'simpleInput field-error' });
          return false;
        } else {
          dollarSum += parseFloat(this.state.bogo_free_product_quantity) * parseFloat(this.state.bogo_free_product_value_per_unit);
        }
      } else {
        if (this.state.bogo_free_product_value == '' || !isPositiveNumber(this.state.bogo_free_product_value)) {
          this.setState({ bogo_free_product_valueError: true });
          return false;
        } else {
          dollarSum += parseFloat(this.state.bogo_free_product_value);
        }
      }
      if (this.state.package_bogo_price == '' || !isPositiveNumber(this.state.package_bogo_price)) {
        this.setState({ package_bogo_priceError: true });
        return false;
      } else {
        if (parseFloat(dollarSum) !== parseFloat(this.state.package_bogo_price)) {
          uiNotification.error(this.state.inventoryLang.inventory_bogo_msg)
          this.setState({
            package_bogo_priceError: true,
            bogo_free_product_valueError: (this.state.discount_type_bogo_buy === 'collective_total') ? true : false,
            bogo_buy_product_valueError: (this.state.discount_type_bogo_free === 'collective_total') ? true : false,
            bogo_buy_product_value_per_unitClass: (this.state.discount_type_bogo_buy === 'dollar_per_unit') ? 'simpleInput field-error' : 'simpleInput',
            bogo_free_product_value_per_unitClass: (this.state.discount_type_bogo_free === 'dollar_per_unit') ? 'simpleInput field-error' : 'simpleInput'
          });
          return false;
        }
      }


    } else if (this.state.type == 'group') {
      if (this.state.bogo_product_id == 0) {
        this.setState({ bogo_product_idError: true });
        return false;
      }

      if (!isNullish(this.state.bogo_group_product_min) && !isNullish(this.state.bogo_group_product_count_by)) {
        if (Number(this.state.bogo_product_quantity) < this.state.bogo_group_product_min) {
          this.setState({ bogo_product_quantityError: true });
          uiNotification.error('Minimum units should be ' + this.state.bogo_group_product_min);
          return false;
        }

        if (!isDerivedFromIncrement(Number(this.state.bogo_product_quantity), Number(this.state.bogo_group_product_count_by))) {
          this.setState({ bogo_product_quantityError: true });
          uiNotification.error('Units should be in increment of ' + this.state.bogo_group_product_count_by);
          return false;
        }

        if (Number(this.state.bogo_free_product_quantity) < this.state.bogo_group_free_product_min) {
          this.setState({ bogo_free_product_quantityError: true });
          uiNotification.error('Minimum units should be ' + this.state.bogo_group_free_product_min);
          return false;
        }

        if (!isDerivedFromIncrement(Number(this.state.bogo_free_product_quantity), Number(this.state.bogo_group_free_product_count_by))) {
          this.setState({ bogo_free_product_quantityError: true });
          uiNotification.error('Units should be in increment of ' + this.state.bogo_group_free_product_count_by);
          return false;
        }
      }

      if (this.state.bogo_product_quantity == '' || !isPositiveNumber(this.state.bogo_product_quantity, null, null, 4)) {
        this.setState({ bogo_product_quantityError: true });
        return false;
      }
      if (this.state.bogo_free_product_id == 0) {
        this.setState({ bogo_free_product_idError: true });
        return false;
      }
      if (this.state.discount_type == "percentage") {
        if (this.state.bogo_discount_percentage == '' || !isPositiveNumber(this.state.bogo_discount_percentage)) {
          this.setState({ bogo_discount_percentageError: true });
          return false;
        }
      }
      if (this.state.bogo_free_product_quantity == '' || !isPositiveNumber(this.state.bogo_free_product_quantity, 0.001, null, 4)) {
        this.setState({ bogo_free_product_quantityError: true });
        return false;
      }
    }


    if (!moment(this.state.active_from).isBefore(this.state.active_untill)) {
      this.setState({ active_fromError: true, active_untillError: true })
      uiNotification.error('"Available For Purchase On" date should be before "Available For Purchase Until" date');
      return false;
    }

    if (this.state.is_in_current_offers) {
      if ((this.state.current_offer_days == '' || !isPositiveNumber(this.state.current_offer_days, 1, 100))) {
        this.setState({ current_offer_daysError: true })
        return false;
      } else {
        if (parseInt(this.state.current_offer_days) > 100) {
          this.setState({ current_offer_daysError: true })
          return false;
        }
      }
    }

    /////////////////////////////////////commented for custom tax //////////////////////////////////
    if (this.state.is_tax_enabled) {
      let errorState = {},
        errorFlag = false;
      for (let x in this.state) {
        if (x.startsWith('clinicTax-')) {
          let id = x.split('-')[1]
          if (this.state[x] === '' || this.state[x] === undefined || this.state[x] === null || !isPositiveNumber(this.state[x], null, null, 5)) {
            errorState["clinicTaxError-" + id] = true;
            errorFlag = true;
          } else {
            errorState["clinicTaxError-" + id] = false;
          }
        }
      }
      this.setState(errorState);
      if (errorFlag) {
        return false;
      }
    }

    let formData = {};
    formData.packageId = this.state.isCloneMode ? 0 : this.state.packageId;
    formData.status = (this.state.packageId) ? this.state.status : 0;
    formData.name = this.state.name;
    formData.type = this.state.type;
    formData.promotion_name_for_booking_portal = this.state.promotion_name_for_booking_portal;
    formData.promotion_description_for_booking_portal = this.state.promotion_description_for_booking_portal;
    if (this.props.bookingImage.data) {
      formData.image_booking_portal = this.props.bookingImage.data.data.file_name;
    }
    formData.customer_type = this.state.customer_type;
    formData.active_from = apiDateFormat(this.state.active_from);
    formData.active_untill = apiDateFormat(this.state.active_untill);
    formData.keywords = (this.state.keywords.length) ? this.state.keywords.join(',') : "";
    if (this.state.is_in_current_offers) {
      formData.current_offer_days = this.state.current_offer_days;
      formData.is_in_current_offers = this.state.is_in_current_offers;
      formData.current_offer_valid_till = apiDateFormat(moment().add('days', parseInt(this.state.current_offer_days)));
    }

    if (this.state.type == "percentage") {
      formData.discount_percentage = this.state.discount_percentage;
    } else if (this.state.type == "dollars") {
      formData.discount_dollars = this.state.discount_dollars;
    } else if (this.state.type == "package") {
      formData.package_buy_type = this.state.package_buy_type;
      formData.package_product_price = this.state.package_product_price;

      if (this.state.package_price_for_members === null || this.state.package_price_for_members === '' || this.state.package_price_for_members === undefined) {
        formData.package_price_for_members = this.state.package_product_price
      } else {
        formData.package_price_for_members = this.state.package_price_for_members
      }

      if (this.state.customer_type === 'member') {
        formData.package_price_for_members = this.state.package_product_price
      }

      if (this.state.package_buy_type == 'product') {
        if (this.state.productPackageArr.length) {
          let productPackageArr = [];
          this.state.productPackageArr.map((obj) => {
            let tempObj = obj;
            if (obj.discount_type === 'dollar_per_unit') {
              tempObj.dollar_value = parseFloat(parseFloat(tempObj.dollar_value_per_unit) * parseFloat(tempObj.units)).toFixed(2);
            } else {
              tempObj.dollar_value_per_unit = parseFloat(parseFloat(tempObj.dollar_value) / parseFloat(tempObj.units)).toFixed(2);
            }
            productPackageArr.push(tempObj)
          })
          formData.products = productPackageArr;
        }
      } else {
        formData.discount_group_id = this.state.discount_group_id;
        formData.package_product_quantity = this.state.package_product_quantity;
      }
    } else if (this.state.type == "bogo") {
      let bogo_buy_product_value = this.state.bogo_buy_product_value
      let bogo_free_product_value = this.state.bogo_free_product_value
      let bogo_buy_product_value_per_unit = this.state.bogo_buy_product_value_per_unit
      let bogo_free_product_value_per_unit = this.state.bogo_free_product_value_per_unit
      if (this.state.discount_type_bogo_buy === 'dollar_per_unit') {
        bogo_buy_product_value = parseFloat(parseFloat(this.state.bogo_buy_product_value_per_unit) * parseFloat(this.state.bogo_product_quantity)).toFixed(2);
      } else {
        bogo_buy_product_value_per_unit = parseFloat(parseFloat(this.state.bogo_buy_product_value) / parseFloat(this.state.bogo_product_quantity)).toFixed(2);
      }
      if (this.state.discount_type_bogo_free === 'dollar_per_unit') {
        bogo_free_product_value = parseFloat(parseFloat(this.state.bogo_free_product_value_per_unit) * parseFloat(this.state.bogo_free_product_quantity)).toFixed(2);
      } else {
        bogo_free_product_value_per_unit = parseFloat(parseFloat(this.state.bogo_free_product_value) / parseFloat(this.state.bogo_free_product_quantity)).toFixed(2);
      }
      formData.bogo_offer_product_id = this.state.bogo_product_id;
      formData.bogo_offer_product_quantity = this.state.bogo_product_quantity;
      formData.bogo_offer_free_product_id = this.state.bogo_free_product_id;
      formData.bogo_offer_free_product_quantity = this.state.bogo_free_product_quantity;
      formData.package_bogo_price = this.state.package_bogo_price;
      formData.discount_type_bogo_buy = this.state.discount_type_bogo_buy;
      formData.discount_type_bogo_free = this.state.discount_type_bogo_free;
      formData.bogo_buy_product_value_per_unit = bogo_buy_product_value_per_unit;
      formData.bogo_free_product_value_per_unit = bogo_free_product_value_per_unit;
      formData.bogo_buy_product_value = bogo_buy_product_value;
      formData.bogo_free_product_value = bogo_free_product_value;
    } else if (this.state.type == "group") {
      formData.bogo_product_id = this.state.bogo_product_id;
      formData.bogo_product_quantity = this.state.bogo_product_quantity;
      formData.bogo_free_product_id = this.state.bogo_free_product_id;
      formData.bogo_free_product_quantity = this.state.bogo_free_product_quantity;
      formData.package_bogo_price = this.state.package_bogo_price;
      if (this.state.bogo_discount_percentage) {
        formData.bogo_discount_percentage = (this.state.bogo_discount_percentage) ? this.state.bogo_discount_percentage : "";
      }

    }

    if (this.state.all_locations) {
      let discount_package_location = []
      this.state.discount_package_location.map((obj, idx) => {
        discount_package_location.push(obj.value);
      })
      formData.clinics = discount_package_location;
    } else {
      formData.clinics = [];
    }
    if (this.state.all_providers) {
      let discount_package_provider = []
      this.state.discount_package_provider.map((obj, idx) => {
        discount_package_provider.push(obj.value);
      })
      formData.providers = discount_package_provider;
    } else {
      formData.providers = [];
    }
    if (this.state.is_tax_enabled) {
      let clinic_tax = []
      this.state.clinics.map((obj, idx) => {
        clinic_tax.push({ clinic_id: obj.id, tax: Number(this.state['clinicTax-' + obj.id]).toFixed(4) });
      })
      formData.clinic_tax = clinic_tax;
    }
    formData.all_providers = (this.state.all_providers) ? 1 : 0;
    formData.all_locations = (this.state.all_locations) ? 1 : 0;
    formData.is_tax_enabled = (this.state.is_tax_enabled) ? 1 : 0;
    formData.is_available_online = (this.state.is_available_online && this.isOnlineBookingAvailable()) ? 1 : 0;

    if (this.state.type == "percentage" || this.state.type == "dollars") {
      let discount_applied_products = [];
      let discount_applied_categories = [];
      if (this.state.discount_applied_on === 'category') {
        this.state.discount_applied_categories.map((obj) => {
          discount_applied_categories.push(obj.id);
        })
      }
      if (this.state.discount_applied_on === 'product') {
        this.state.discount_applied_products.map((obj) => {
          discount_applied_products.push(obj.id);
        })
      }
      formData.discount_applied_on = this.state.discount_applied_on;
      formData.discount_applied_products = discount_applied_products;
      formData.discount_applied_categories = discount_applied_categories;
    }
    this.showLoaderFunc();

    this.setState({ nameError: false });
  
    const packageData = this.state.packageDefaultData?.discount_package || {};

    if (this.state.packageId && formData.name === packageData.name) {
      delete formData.name;
    }

    if (
      this.state.packageId 
      && formData.promotion_name_for_booking_portal === packageData.promotion_name_for_booking_portal
    ) {
      delete formData.promotion_name_for_booking_portal;
    }

    this.props.saveDiscountPackage(formData)
      .catch(e => {
        if (e.message === "discount_applied_products_deleted") {
          uiNotification.error("Unable to update discount with a deleted product");
        } else if (e.message === "discount_name_unique") {
          uiNotification.error("Discount with same name already exists");
          this.setState({
            nameError: true,
          });
        } else if (e.message === "promotion_name_for_booking_portal_unique") {
          uiNotification.error("The promotion name for booking portal has already been taken");
          this.setState({
            promotion_nameError: true,
          });
        } else {
          uiNotification.error(
            this.state.globalLang[e.message] ||
            "Unable to save package",
          );
        }
      });
  }

  deleteDiscountPackage = () => {
    this.setState({ showModal: false })
    if (this.state.packageId > 0) {
      this.setState({ showLoader: true })
      this.props.deleteDiscountPackage(this.state.packageId);
    }
  }
  activateDiscountPackage = () => {
    if (this.state.packageId > 0) {
      this.setState({ showLoader: true })
      this.props.activateDiscountPackage(this.state.packageId);
    }
  }

  redirectToPackages = () => {
    return (
      <div>
        {this.props.history.push(`/settings/discount-packages`)}
      </div>
    )
  }

  handleDiscountAppliedProductChange = (discount_applied_products) => {
    this.setState({ discount_applied_products: discount_applied_products });
  }

  handleDiscountAppliedCategoryChange = (discount_applied_categories) => {
    this.setState({ discount_applied_categories: discount_applied_categories });
  }

  onFileUpload = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0])
    let formData = {};
    formData.selectedFile = data
    this.props.uploadImageBookingPortal(data);
  };

  removeBookingImage = () => {
    this.setState({ image_booking_portal_url: '', image_booking_portal: '' });
  }

  fileData = () => {
    if (this.state.image_booking_portal_url) {
      return (
        <div className="image-upload">
          <button className="new-blue-btn pull-right" onClick={this.removeBookingImage}>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.9919 1.68585L1.00854 17.9473" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16.9967 17.957L1 1.67859" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <img src={this.state.image_booking_portal_url} />
        </div>
      );
    } else if (!this.state.image_booking_portal_url && this.props.bookingImage && this.props.bookingImage.data) {
      return (
        <span className="file-upload-name">File name: {this.props.bookingImage.data.data.file_name}</span>
      );
    }
    else {
      return (
        <div className="icon-upload">
          <i>
            <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_31_541)">
                <path d="M13.8584 21C11.8274 21 9.79647 21 7.76549 21C4.20532 20.9763 1.14691 18.6824 0.262839 15.3716C-0.645126 11.9426 0.812396 8.39526 3.94248 6.69255C4.75487 6.24323 5.25664 5.69931 5.63895 4.87161C7.26372 1.48985 10.6805 -0.331094 14.5752 0.0472841C17.8965 0.354717 20.9788 2.95607 21.8628 6.33782C22.1257 7.37836 22.6035 7.99323 23.5832 8.48985C26.2354 9.88512 27.5257 13.0067 26.8089 15.8446C26.0204 18.8953 23.392 20.9763 20.3097 21C18.1593 21 16.0089 21 13.8584 21ZM13.4522 17.0034C14.3602 17.027 15.1726 16.2703 15.292 15.1824C15.3637 14.5676 15.3159 13.929 15.3159 13.2905C15.3159 11.5642 15.3159 11.5405 17.0124 11.375C17.4664 11.3277 17.8726 11.1858 18.0637 10.7365C18.231 10.3581 18.0159 10.0507 17.7531 9.79053C16.5823 8.60809 15.4115 7.44931 14.2168 6.29053C13.7867 5.86485 13.285 5.79391 12.8549 6.24323C11.6124 7.44931 10.346 8.65539 9.17523 9.93242C8.57788 10.5709 8.9124 11.0912 9.677 11.3513C9.89204 11.4223 10.1549 11.4223 10.3938 11.3986C11.4929 11.2567 11.7797 11.8007 11.708 12.7703C11.6602 13.5507 11.6602 14.3547 11.7319 15.1351C11.7558 16.1993 12.5204 16.9797 13.4522 17.0034Z" fill="#188BF6" />
              </g>
              <defs>
                <clipPath id="clip0_31_541">
                  <rect width="27" height="21" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>File Format Accepted: JPG, PNG</span>
          </i>
        </div>
      );
    }
  };

  isOnlineBookingAvailable = () => {
    return this.state.type === "bogo" || (this.state.type === 'package' && this.state.package_buy_type === 'product')
  }

  showOnlineBookingFields = () => {
    return this.state.is_available_online && this.isOnlineBookingAvailable()
  }

  getPageLabel = () => {
    if (this.state.isCloneMode) {
      return 'Clone Discount';
    } else if (this.state.packageId > 0) {
      return 'Edit Discounts';
    }
    return this.state.inventoryLang.inventory_add_discounts;
  }

  render() {
    var options = [];
    var defaultOptions = [];
    if (this.state.clinics != undefined && this.state.clinics.length > 0) {
      options = this.state.clinics.map((obj, idx) => {
        return { value: obj.id, label: obj.clinic_name }
      })
    }

    var providerOptions = [];
    if (this.state.providers != undefined && this.state.providers.length > 0) {
      providerOptions = this.state.providers.map((obj, idx) => {
        return { value: obj.id, label: displayName(obj) }
      })
    }

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">

              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span className="cursor-pointer" onClick={this.redirectToPackages}>{'Promotions'}</span> <span className="breadCrumb"><i className="fa fa-chevron-right" /> <span className="breadCrumb-text">{this.getPageLabel()}</span></span>

                  </div>
                </div>
              </div>
              <div className="juvly-section full-width">
                <div className="juvly-container p25">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="row">
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_Discount_Name}<span className="setting-require">*</span></div>
                            <input className={(this.state.nameError) ? "simpleInput field-error" : "simpleInput"} type="text" name="name" onChange={this.handleInputChange} autoComplete="off" value={this.state.name} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_type}<span className="setting-require">*</span></div>
                            <select
                              onChange={(e) => {
                                this.handleInputChange(e);
                              }}
                              name="type"
                              id="discount_type"
                              className={(this.state.discount_typeError) ? "simpleSelect field-error" : "simpleSelect"}
                              placeholder="Discount Type"
                              value={this.state.type}
                            >
                              <option id="package-type-percentage" value="percentage" >% {this.state.inventoryLang.inventory_percentageLBL}</option>
                              <option value="dollars" >{getCurrencySymbol()} {this.state.inventoryLang.inventory_figure}</option>
                              <option value="package" >{this.state.inventoryLang.inventory_package_v2}</option>
                              <option value="bogo" >{this.state.inventoryLang.inventory_bogo_offers}</option>
                              <option value="group" >{this.state.inventoryLang.inventory_bogo_discount_group}</option>
                            </select>
                          </div>
                        </div>
                        <div className={(this.state.type == "percentage") ? "col-lg-3 col-sm-6 col-xs-12" : "col-lg-3 col-sm-6 col-xs-12 no-display"}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_percantage}<span className="setting-require">*</span></div>
                            <input className={(this.state.discount_percentageError) ? "simpleInput field-error" : "simpleInput"} type="number" min={0} max={100} placeholder="0.000" name="discount_percentage" onChange={this.handleInputChange} onBlur={this.handleTaxOnBlur} autoComplete="off" value={this.state.discount_percentage} />
                          </div>
                        </div>
                        <div className={(this.state.type == "dollars") ? "col-lg-3 col-sm-6 col-xs-12" : "col-lg-3 col-sm-6 col-xs-12 no-display"}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_amounts}<span className="setting-require">*</span></div>
                            <input className={(this.state.discount_dollarsError) ? "simpleInput field-error" : "simpleInput"} type="text" name="discount_dollars" onChange={this.handleInputChange} autoComplete="off" value={this.state.discount_dollars} />
                          </div>
                        </div>

                        <div className={(this.state.type == "package") ? "col-lg-6 col-xs-12" : "no-display"}>
                          <div className="row">
                            <div className="col-sm-6 col-xs-12">
                              <div className="simpleField m-b-15">
                                <div className="simpleLabel">{this.state.inventoryLang.inventory_package_price}<span className="setting-require">*</span></div>
                                <input className={(this.state.package_product_priceError) ? "simpleInput field-error" : "simpleInput"} type="text" name="package_product_price" onChange={this.handleInputChange} value={this.state.package_product_price} autoComplete="off" />
                              </div>
                            </div>
                            <div className={(this.state.customer_type == 'member') ? "col-sm-6 col-xs-12 no-display" : "col-sm-6 col-xs-12"}>
                              <div className="simpleField m-b-15">
                                <div className="simpleLabel">{'Package Price For Members'}</div>
                                <input className={(this.state.package_price_for_membersError) ? "simpleInput field-error" : "simpleInput"} type="text" name="package_price_for_members" onChange={this.handleInputChange} value={this.state.package_price_for_members} autoComplete="off" />
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className={(this.state.type == "bogo") ? "col-lg-3 col-sm-6 col-xs-12 relative" : "no-display"}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_bogo_price}<span className="setting-require">*</span></div>
                            <input className={(this.state.package_bogo_priceError) ? "simpleInput field-error" : "simpleInput"} type="text" name="package_bogo_price" onChange={this.handleInputChange} autoComplete="off" value={this.state.package_bogo_price} />
                          </div>
                        </div>
                      </div>

                      <div className={(this.state.type == "percentage" || this.state.type == "dollars") ? "row" : "no-display"}>
                        <div className="col-lg-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_applied_on}<span className="setting-require">*</span></div>
                            <select className="simpleSelect" name="discount_applied_on" onChange={this.handleInputChange} value={this.state.discount_applied_on} >
                              <option value="all">{this.state.inventoryLang.inventory_all_products_and_categories}</option>
                              <option value="product">{this.state.inventoryLang.inventory_selected_products}</option>
                              <option value="category">{this.state.inventoryLang.inventory_selected_categories}</option>
                            </select>
                          </div>
                        </div>


                        <div className={`col-lg-6 col-xs-12 ${(this.state.discount_applied_on === 'product') ? '' : 'no-display'}`}>
                          <div className={this.state.discount_applied_product_optionsClass}>
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_product_s} : <span className="setting-require">*</span></div>
                            <div className="simpleInput auto-height no-padding-right">
                              <div className="tag-auto-select">
                                {(this.state.discount_applied_product_options) && <Select
                                  onChange={this.handleDiscountAppliedProductChange}
                                  value={this.state.discount_applied_products}
                                  isClearable
                                  isSearchable
                                  options={this.state.discount_applied_product_options}
                                  isMulti={true}
                                />}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`col-lg-6 col-xs-12 ${(this.state.discount_applied_on === 'category') ? '' : 'no-display'}`}>
                          <div className={this.state.discount_applied_category_optionsClass}>
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_category_s} : <span className="setting-require">*</span></div>
                            <div className="simpleInput auto-height no-padding-right">
                              <div className="tag-auto-select">
                                {(this.state.discount_applied_category_options) && <Select
                                  onChange={this.handleDiscountAppliedCategoryChange}
                                  value={this.state.discount_applied_categories}
                                  isClearable
                                  isSearchable
                                  options={this.state.discount_applied_category_options}
                                  isMulti={true}
                                />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className={`${(this.state.type == "package") ? 'col-lg-3 col-sm-6 col-xs-12' : 'no-display'}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_select_package_type}<span className="setting-require">*</span></div>
                            <select className="simpleSelect" name="package_buy_type" onChange={this.handleInputChange} value={this.state.package_buy_type} >
                              <option value="product">{this.state.inventoryLang.inventory_productLBL}</option>
                              <option value="group">{this.state.inventoryLang.inventory_groupLBL}</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15 field-icon">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_Available_for_purchase_on}<span className="setting-require">*</span><span title={this.state.inventoryLang.inventory_package_Available_for_purchase_on_tootip}></span></div>
                            <div className="relative">
                              <DatePicker
                                shouldCloseOnSelect={false}
                                todayButton="Today"
                                onChange={(date, event) => {
                                  if (!event.target.classList.contains('react-datepicker__today-button')) {
                                    this.refDatePickerFrom.setOpen(false);
                                  };
                                  this.handleSearchDatePicker('active_from', date)
                                }}
                                className={((this.state.active_fromError) ? "simpleInput field-error" : "simpleInput") ? "simpleInput" : "simpleField m-b-15 no-display"}
                                name={"active_from"}
                                selected={(this.state.active_from) ? this.state.active_from : null}
                                autoComplete="off"
                                minDate={new Date()}
                                maxDate={new Date(moment().add(10, 'years'))}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat={dateFormatPicker()}
                                placeholderText={dateFormatPicker().toLowerCase()}
                                ref={(refDatePicker) => this.refDatePickerFrom = refDatePicker}
                                onClickOutside={() => this.refDatePickerFrom.setOpen(false)}
                              />
                              <a name="calendar-input" className="simple-cal-icon"></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15 field-icon">
                            <div className="simpleLabel no-margin-right">{this.state.inventoryLang.inventory_Available_for_purchase_until} <span className="setting-require">*</span><span title={this.state.inventoryLang.inventory_package_Available_for_purchase_until_tootip}></span></div>
                            <div className="relative">
                              <DatePicker
                                shouldCloseOnSelect={false}
                                todayButton="Today"
                                onChange={(date, event) => {
                                  if (!event.target.classList.contains('react-datepicker__today-button')) {
                                    this.refDatePickerUntil.setOpen(false);
                                  };
                                  this.handleSearchDatePicker('active_untill', date);
                                }}
                                className={((this.state.active_untillError) ? "simpleInput field-error" : "simpleInput") ? "simpleInput" : "simpleField m-b-15 no-display"}
                                name={"active_untill"}
                                selected={(this.state.active_untill) ? this.state.active_untill : null}
                                minDate={new Date()}
                                maxDate={new Date(moment().add(10, 'years'))}
                                autoComplete="off"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat={dateFormatPicker()}
                                placeholderText={dateFormatPicker().toLowerCase()}
                                ref={(refDatePicker) => this.refDatePickerUntil = refDatePicker}
                                onClickOutside={() => this.refDatePickerUntil.setOpen(false)}
                              />
                              <a name="calendar-input" className="simple-cal-icon"></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_available_to_customers}<span className="setting-require">*</span></div>
                            <select
                              onChange={(e) => {
                                this.handleInputChange(e);
                              }}
                              name="customer_type"
                              id="discount_type"
                              className={(this.state.country_Error) ? "simpleSelect field_error" : "simpleSelect"}
                              placeholder="Discount Type"
                              value={this.state.customer_type}
                            >
                              <option value="">Select</option>
                              <option id="UserIsDashboardEnabled0" value="all">{this.state.inventoryLang.inventory_all}</option>
                              <option id="UserIsDashboardEnabled1" value="member"  >{this.state.inventoryLang.inventory_members_only}</option>
                            </select>
                          </div>
                        </div>

                        {this.state.package_buy_type == 'product' && this.state.productPackageArr.map((obj, idx) => {
                          return (
                            <div key={'productPackageArr-' + idx} className="col-xs-12">
                              <div key={'productPackageArr-' + idx} className={(this.state.type == "package") ? "row relative" : "row relative no-display"}>
                                <div className="col-lg-3 col-sm-6 col-xs-12">
                                  <div className="simpleField m-b-15">
                                    <div className="simpleLabel">{this.state.inventoryLang.inventory_select_buy_product}<span className="setting-require">*</span></div>
                                    <div className="product_name_container" data-package-index={idx} data-ref-container={'product_name_container'} ref={(ref_product_name_container) => this.ref_product_name_container = ref_product_name_container} >
                                      <input
                                        className={(this.state["productPackageErrorArr"][idx] && this.state["productPackageErrorArr"][idx].product_name) ? "simpleInput field-error" : "simpleInput"}
                                        name={"product_name"} value={obj.product_name}
                                        onChange={this.handleProductChange.bind(this, 'package')}
                                        //onBlur={this.handleOnBlur.bind(this, 'product_name_focus')}
                                        onFocus={this.handleOnFocus.bind(this, 'product_name_focus', idx)}
                                        type="text"
                                        autoComplete="off"
                                        data-packageindex={idx}
                                        placeholder={this.state.inventoryLang.inventory_type_initials_of_product}
                                      />
                                      <ul className={((this.state['product_name_focus_' + idx] !== undefined && this.state['product_name_focus_' + idx] === true && obj.product_name.length > 2) && this.state["showPackageProducts-" + idx]) ? " search-dropdown" : "cal-dropdown clinicname-dropdown no-display"}>
                                        {(this.state.packageProductList.length > 0) ?
                                          this.state.packageProductList.map((objInner, idxInner) => {
                                            return (
                                              <li
                                                key={"product_name-" + idx + "-" + idxInner}
                                                data-step={objInner.count_units_by}
                                                data-min={objInner.start_at_unit}
                                                data-id={objInner.id}
                                                data-mode={"package"}
                                                data-pname={objInner.product_name}
                                                data-index={idx}
                                                onClick={this.selectProductPackage}
                                              >
                                                <a>
                                                  {objInner && objInner.product_name}
                                                </a>
                                              </li>
                                            )
                                          })
                                          :
                                          <li key={"packageProduct-norecord"} data-id={0} data-mode={"package"} data-pname={'product_match_not_found'} data-index={-1}>
                                            <a >
                                              {this.state.globalLang.product_match_not_found}
                                            </a>
                                          </li>
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-xs-12">
                                  <div className="simpleField m-b-15">
                                    <div className="simpleLabel">{this.state.inventoryLang.inventory_select_quality}<span className="setting-require">*</span></div>
                                    <input
                                      className={(this.state["productPackageErrorArr"][idx] && this.state["productPackageErrorArr"][idx].units) ? "simpleInput field-error" : "simpleInput"}
                                      type="number"
                                      placeholder="Unit"
                                      data-packageindex={idx}
                                      name="units"
                                      onChange={this.handleInputChange}
                                      autoComplete="off"
                                      value={obj.units}
                                    />
                                    {!isNullish(obj.min) && (
                                      <div className="font-12">
                                        <span>Min:</span> {obj.min}
                                      </div>
                                    )}
                                    {!isNullish(obj.count_by) && (
                                      <div className="font-12">
                                        <span>Count by:</span> {obj.count_by}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-xs-12">
                                  <div className="simpleField">
                                    <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_price_type}<span className="setting-require">*</span></div>
                                    <select className={"simpleSelect"} data-packageindex={idx} name="discount_type" onChange={this.handleInputChange} autoComplete="off" value={obj.discount_type} >
                                      <option value={'collective_total'}>{this.state.inventoryLang.inventory_collective_total}</option>
                                      <option value={'dollar_per_unit'}>{this.state.inventoryLang.inventory_dollar_value_per_unit}</option>
                                    </select>
                                  </div>
                                </div>
                                <div className={`col-lg-3 col-sm-6 col-xs-12 ${(obj.discount_type === 'dollar_per_unit') ? '' : 'no-display'}`}>
                                  <div className="simpleField m-b-15">
                                    <div className="simpleLabel">{this.state.inventoryLang.inventory_price_per_unit}<span className="setting-require">*</span></div>
                                    <input className={(this.state["productPackageErrorArr"][idx] && this.state["productPackageErrorArr"][idx].dollar_value_per_unit) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Value" data-packageindex={idx} name="dollar_value_per_unit" onChange={this.handleInputChange} autoComplete="off" value={obj.dollar_value_per_unit} />
                                  </div>
                                </div>
                                <div className={`col-lg-2 col-sm-5 col-xs-12 ${(obj.discount_type === 'collective_total') ? '' : 'no-display'}`}>
                                  <div className="simpleField m-b-15">
                                    <div className="simpleLabel">{this.state.inventoryLang.inventory_dollar_value}<span className="setting-require">*</span></div>
                                    <input className={(this.state["productPackageErrorArr"][idx] && this.state["productPackageErrorArr"][idx].dollar_value) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Value" data-packageindex={idx} name="dollar_value" onChange={this.handleInputChange} autoComplete="off" value={obj.dollar_value} />
                                  </div>
                                </div>
                                <div className="col-lg-1 col-sm-1 col-xs-12">
                                  <a className={(idx == 0) ? "new-blue-btn AddBtn" : "add-round-btn AddBtn no-display"} onClick={this.addMoreProduct} ><span>+</span></a>
                                  <a className={(idx > 0) ? "new-blue-btn AddBtn" : "add-round-btn AddBtn no-display"} onClick={this.removeCurrentProduct.bind(this, idx)} ><span>-</span></a>
                                </div>
                              </div>
                            </div>
                          )
                        })
                        }


                        <div className="col-lg-9 col-sm-6 col-xs-12">
                          {this.state.package_buy_type == 'group' &&
                            <div className={(this.state.type == "package") ? "row relative" : "row relative no-display"}>
                              <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="simpleField m-b-15">
                                  <div className="simpleLabel">{this.state.inventoryLang.inventory_select_group}<span className="setting-require">*</span></div>
                                  <select className={(this.state.discount_group_idError) ? "simpleSelect field-error" : "simpleSelect"} name="discount_group_id" onChange={this.handleInputChange} value={this.state.discount_group_id} >
                                    <option value={0}>{this.state.inventoryLang.inventory_select}</option>
                                    {this.state.groups.length && this.state.groups.map((obj, idx) => {
                                      return (
                                        <option value={obj.id} key={"discount_group_id-" + idx}>{obj.name}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="simpleField m-b-15">
                                  <div className="simpleLabel">{this.state.inventoryLang.inventory_select_quality}<span className="setting-require">*</span></div>
                                  <input className={(this.state.package_product_quantityError) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Unit" name="package_product_quantity" onChange={this.handleInputChange} autoComplete="off" value={this.state.package_product_quantity} />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className={(this.state.type == "bogo") ? "row relative" : "no-display"}>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_select_buy_product}<span className="setting-require">*</span></div>
                            <div className="bogo_product_container" ref={(ref_bogo_product_container) => this.ref_bogo_product_container = ref_bogo_product_container} >
                              <input className={(this.state.bogo_productError) ? "simpleInput field-error" : "simpleInput"}
                                name="bogo_product" value={this.state.bogo_product}
                                autoComplete="off"
                                onChange={this.handleProductChange.bind(this, 'bogo_product')}
                                onFocus={this.handleOnFocus.bind(this, 'bogo_product_focus')}
                                type="text"
                                placeholder={this.state.inventoryLang.inventory_type_initials_of_product}
                              />
                              <ul className={((this.state.bogo_product_focus !== undefined && this.state.bogo_product_focus === true && this.state.bogo_product.length > 2) && this.state.showBogoProducts) ? " search-dropdown" : "cal-dropdown clinicname-dropdown no-display"}>
                                {(this.state.bogoProductList.length > 0) ? this.state.bogoProductList.map((obj, idx) => {
                                  return (
                                    <li
                                      key={"bogo_product-" + idx}
                                      data-id={obj.id}
                                      data-mode={"bogo_product"}
                                      data-pname={obj.product_name}
                                      data-min={obj.start_at_unit}
                                      data-step={obj.count_units_by}
                                      onClick={this.selectProduct}
                                    >
                                      <a >
                                        {obj && obj.product_name}
                                      </a>
                                    </li>
                                  )
                                })
                                  :
                                  <li key={"bogo_product-norecord"} data-id={0} data-mode={"bogo_product"} data-pname={'product_match_not_found'} data-index={-1}>
                                    <a >
                                      {this.state.globalLang.product_match_not_found}
                                    </a>
                                  </li>
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_select_quality}<span className="setting-require">*</span></div>
                            <input
                              className={(this.state.bogo_product_quantityError) ? "simpleInput field-error" : "simpleInput"}
                              type="number"
                              name="bogo_product_quantity"
                              onChange={this.handleInputChange}
                              autoComplete="off"
                              value={this.state.bogo_product_quantity}
                              placeholder="Unit"
                            />
                            {!isNullish(this.state.bogo_product_min) && (
                              <div className="font-12">
                                <span>Min:</span> {this.state.bogo_product_min}
                              </div>
                            )}
                            {!isNullish(this.state.bogo_product_count_by) && (
                              <div className="font-12">
                                <span>Count by:</span> {this.state.bogo_product_count_by}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_price_type}<span className="setting-require">*</span></div>
                            <select className="simpleSelect" name="discount_type_bogo_buy" value={this.state.discount_type_bogo_buy} onChange={this.handleInputChange} >
                              <option value={'collective_total'}>{this.state.inventoryLang.inventory_collective_total}</option>
                              <option value={'dollar_per_unit'}>{this.state.inventoryLang.inventory_dollar_value_per_unit}</option>
                            </select>
                          </div>
                        </div>
                        <div className={`col-lg-3 col-sm-6 col-xs-12 ${(this.state.discount_type_bogo_buy === 'dollar_per_unit') ? '' : 'no-display'}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_price_per_unit}<span className="setting-require">*</span></div>
                            <input className={this.state.bogo_buy_product_value_per_unitClass} type="text" placeholder="Value" name="bogo_buy_product_value_per_unit" onChange={this.handleInputChange} autoComplete="off" value={this.state.bogo_buy_product_value_per_unit} />
                          </div>
                        </div>
                        <div className={`col-lg-3 col-sm-6 col-xs-12 ${(this.state.discount_type_bogo_buy === 'collective_total') ? '' : 'no-display'}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_dollar_value}<span className="setting-require">*</span></div>
                            <input className={(this.state.bogo_buy_product_valueError) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Value" name="bogo_buy_product_value" onChange={this.handleInputChange} autoComplete="off" value={this.state.bogo_buy_product_value} />
                          </div>
                        </div>
                      </div>

                      <div className={(this.state.type == "bogo") ? "row relative" : "no-display"}>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_select_free_product}<span className="setting-require">*</span></div>
                            <div className="bogo_free_product_container" ref={(ref_bogo_free_product_container) => this.ref_bogo_free_product_container = ref_bogo_free_product_container} >
                              <input
                                className={(this.state.bogo_free_productError) ? "simpleInput field-error" : "simpleInput"}
                                name="bogo_free_product"
                                value={this.state.bogo_free_product}
                                autoComplete="off"
                                onChange={this.handleProductChange.bind(this, 'bogo_free_product')}
                                //onBlur={this.handleOnBlur.bind(this, 'bogo_free_product_focus')}
                                onFocus={this.handleOnFocus.bind(this, 'bogo_free_product_focus')}
                                type="text"
                                placeholder={this.state.inventoryLang.inventory_type_initials_of_product}
                              />
                              <ul className={((this.state.bogo_free_product_focus !== undefined && this.state.bogo_free_product_focus === true && this.state.bogo_free_product.length > 2) && this.state.showBogoFreeProducts) ? " search-dropdown" : "cal-dropdown clinicname-dropdown no-display"}>
                                {(this.state.bogoFreeProductList.length > 0) ? this.state.bogoFreeProductList.map((obj, idx) => {
                                  return (
                                    <li
                                      key={"bogo_free_product-" + idx}
                                      data-id={obj.id}
                                      data-mode={"bogo_free_product"}
                                      data-pname={obj.product_name}
                                      data-min={obj.start_at_unit}
                                      data-step={obj.count_units_by}
                                      onClick={this.selectProduct}
                                    >
                                      <a >
                                        {obj && obj.product_name}
                                      </a>
                                    </li>
                                  )
                                })
                                  :
                                  <li key={"bogo_free_product-norecord"} data-id={0} data-mode={"bogo_free_product"} data-pname={'product_match_not_found'} data-index={-1}>
                                    <a >
                                      {this.state.globalLang.product_match_not_found}
                                    </a>
                                  </li>
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_select_quality}<span className="setting-require">*</span></div>
                            <input
                              className={(this.state.bogo_free_product_quantityError) ? "simpleInput field-error" : "simpleInput"}
                              type="number"
                              placeholder="Unit"
                              name="bogo_free_product_quantity"
                              onChange={this.handleInputChange}
                              autoComplete="off"
                              value={this.state.bogo_free_product_quantity}
                            />
                            {!isNullish(this.state.bogo_free_product_min) && (
                              <div className="font-12">
                                <span>Min:</span> {this.state.bogo_free_product_min}
                              </div>
                            )}
                            {!isNullish(this.state.bogo_free_product_count_by) && (
                              <div className="font-12">
                                <span>Count by:</span> {this.state.bogo_free_product_count_by}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                          <div className="simpleField">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_price_type}<span className="setting-require">*</span></div>
                            <select className="simpleSelect" name="discount_type_bogo_free" value={this.state.discount_type_bogo_free} onChange={this.handleInputChange} >
                              <option value={'collective_total'}>{this.state.inventoryLang.inventory_collective_total}</option>
                              <option value={'dollar_per_unit'}>{this.state.inventoryLang.inventory_dollar_value_per_unit}</option>
                            </select>
                          </div>
                        </div>
                        <div className={`col-lg-3 col-sm-6 col-xs-12 ${(this.state.discount_type_bogo_free === 'dollar_per_unit') ? '' : 'no-display'}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_price_per_unit}<span className="setting-require">*</span></div>
                            <input className={this.state.bogo_free_product_value_per_unitClass} type="text" placeholder="Value" name="bogo_free_product_value_per_unit" onChange={this.handleInputChange} autoComplete="off" value={this.state.bogo_free_product_value_per_unit} />
                          </div>
                        </div>
                        <div className={`col-lg-3 col-sm-6 col-xs-12 ${(this.state.discount_type_bogo_free === 'collective_total') ? '' : 'no-display'}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_dollar_value}<span className="setting-require">*</span></div>
                            <input className={(this.state.bogo_free_product_valueError) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Value" name="bogo_free_product_value" onChange={this.handleInputChange} autoComplete="off" value={this.state.bogo_free_product_value} />
                          </div>
                        </div>
                      </div>
                      <div className={(this.state.type == "group") ? "row relative" : "row relative no-display"}>
                        <div className="col-lg-6 col-sm-4 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_select_group_t}<span className="setting-require">*</span></div>
                            <select
                              className={(this.state.bogo_product_idError) ? "simpleSelect field-error" : "simpleSelect"}
                              name="bogo_product_id"
                              onChange={(e) => {
                                this.handleInputChange(e);
                                const group = this.state.groups.find(group => group.id == e.target.value);
                                this.setState({
                                  bogo_group_product_min: group.min_start_at_unit,
                                  bogo_group_product_count_by: group.min_count_units_by,
                                });
                              }}
                              value={this.state.bogo_product_id}
                            >
                              <option value={0}>{this.state.inventoryLang.inventory_select}</option>
                              {this.state.groups.length && this.state.groups.map((obj, idx) => {
                                return (
                                  <option value={obj.id} key={"group_id-" + idx}>{obj.name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_units}<span className="setting-require">*</span></div>
                            <input
                              className={(this.state.bogo_product_quantityError) ? "simpleInput field-error" : "simpleInput"}
                              type="number"
                              placeholder="Unit"
                              name="bogo_product_quantity"
                              onChange={this.handleInputChange}
                              autoComplete="off"
                              value={this.state.bogo_product_quantity}
                            />
                            {!isNullish(this.state.bogo_group_product_min) && (
                              <div className="font-12">
                                <span>Min:</span> {this.state.bogo_group_product_min}
                              </div>
                            )}
                            {!isNullish(this.state.bogo_group_product_count_by) && (
                              <div className="font-12">
                                <span>Count by:</span> {this.state.bogo_group_product_count_by}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={(this.state.type == "group") ? "row relative " : "row relative no-display"}>
                        <div className="col-lg-6 col-sm-3 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_select_group_for}<span className="setting-require">*</span></div>
                            <select
                              className={(this.state.bogo_free_product_idError) ? "simpleSelect field-error" : "simpleSelect"}
                              name="bogo_free_product_id"
                              onChange={(e) => {
                                this.handleInputChange(e);
                                const group = this.state.groups.find(group => group.id == e.target.value);
                                this.setState({
                                  bogo_group_free_product_min: group.min_start_at_unit,
                                  bogo_group_free_product_count_by: group.min_count_units_by,
                                });
                              }}
                              value={this.state.bogo_free_product_id}
                            >
                              <option value={0}>{this.state.inventoryLang.inventory_select}</option>
                              {this.state.groups.length && this.state.groups.map((obj, idx) => {
                                return (
                                  <option
                                    value={obj.id}
                                    key={"discount_group_id-" + idx}
                                  >
                                    {obj.name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-3 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_discount_type}<span className="setting-require">*</span></div>
                            <select className="simpleSelect" name="discount_type" onChange={this.handleInputChange} value={this.state.discount_type} >
                              <option value="free">{this.state.inventoryLang.inventory_Free}</option>
                              <option value="percentage">% {this.state.inventoryLang.inventory_off_lbl}</option>
                            </select>
                          </div>
                        </div>
                        <div className={(this.state.discount_type == 'percentage') ? "col-lg-2 col-sm-3 col-xs-12" : "no-display col-lg-2 col-sm-3 col-xs-12"}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_percentageLBL}<span className="setting-require">*</span></div>
                            <input className={(this.state.bogo_discount_percentageError) ? "simpleInput field-error" : "simpleInput"} type="text" name="bogo_discount_percentage" onChange={this.handleInputChange} value={this.state.bogo_discount_percentage} autoComplete="off" placeholder="Value" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-3 col-xs-12">
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_units}<span className="setting-require">*</span></div>
                            <input
                              className={(this.state.bogo_free_product_quantityError) ? "simpleInput field-error" : "simpleInput"}
                              type="number"
                              name="bogo_free_product_quantity"
                              onChange={this.handleInputChange}
                              value={this.state.bogo_free_product_quantity}
                              autoComplete="off"
                              placeholder="Value"
                            />
                            {!isNullish(this.state.bogo_group_free_product_min) && (
                              <div className="font-12">
                                <span>Min:</span> {this.state.bogo_group_free_product_min}
                              </div>
                            )}
                            {!isNullish(this.state.bogo_group_free_product_count_by) && (
                              <div className="font-12">
                                <span>Count by:</span> {this.state.bogo_group_free_product_count_by}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                          <div className={(this.state.is_in_current_offers) ? "simpleField m-b-25" : "simpleField m-b-25 no-display"}>
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_offer_expire}<span className="setting-require">*</span></div>
                            <input className={(this.state.current_offer_daysError) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Days" maxLength="3" name="current_offer_days" onChange={this.handleInputChange} value={this.state.current_offer_days} autoComplete="off" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6">
                          <div className="simpleField m-b-25">
                            <div className="simpleLabel">Available at Selected locations</div>
                            <div className="simpleInput auto-height no-padding-right">
                              <div className="tag-auto-select">
                                {
                                  options && <Select
                                    onChange={this.handleChange}
                                    value={this.state.discount_package_location}
                                    isClearable
                                    isSearchable
                                    options={options}
                                    isMulti={true}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="simpleField m-b-25">
                            <div className="simpleLabel">Available only with these providers</div>
                            <div className="simpleInput auto-height no-padding-right">
                              <div className="tag-auto-select">
                                {
                                  providerOptions && <Select
                                    onChange={this.handleProviderChange}
                                    value={this.state.discount_package_provider}
                                    isClearable
                                    isSearchable
                                    options={providerOptions}
                                    isMulti={true}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-xs-12">
                          <div className="simpleField">
                            <div className="simpleLabel">{this.state.inventoryLang.inventory_keywords}</div>
                            <div className="simpleInput auto-height p-t-5">
                              <TagsInput value={(this.state.keywords) ? this.state.keywords : []} onChange={this.handleTagChange} />
                            </div>
                          </div>
                        </div>
                        {Boolean(this.state.obPrivilegeEnabled) && <div className={!this.isOnlineBookingAvailable() ? "no-display" : "col-sm-6 col-xs-12"}>
                          <p className="m-t-25">
                            <div className="m-b-10">Patient Portal activation is required to enable eCommerce options.</div>
                            <input id="is_available_online" type="checkbox" className="new-check" name="is_available_online" onChange={this.handleInputChange} checked={(this.state.is_available_online) ? "checked" : false} />
                            <label htmlFor="is_available_online">Available On Booking Portal</label>
                          </p>
                        </div>}
                        <div className={`col-sm-6 col-xs-12 ${!this.showOnlineBookingFields() ? 'no-display' : ''}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">Promotion Name For Booking Portal<span className="setting-require">*</span></div>
                            <input className={(this.state.promotion_nameError) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Promotion Name For Booking Portal" name="promotion_name_for_booking_portal" onChange={this.handleInputChange} value={this.state.promotion_name_for_booking_portal || ""} />
                          </div>
                        </div>
                        <div className={`col-sm-6 col-xs-12 ${!this.showOnlineBookingFields() ? 'no-display' : ''}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">Promotion Description For Booking Portal<span className="setting-require">*</span></div>
                            <textarea maxLength="150" style={{ height: 150, paddingTop: 10, paddingBottom: 10 }} className={(this.state.promotion_descriptionError) ? "simpleInput field-error" : "simpleInput"} type="text" placeholder="Promotion Description For Booking Portal" name="promotion_description_for_booking_portal" onChange={this.handleInputChange} value={this.state.promotion_description_for_booking_portal || ""} />
                          </div>
                        </div>
                        <div className={`col-sm-6 col-xs-12 booking-upload-img ${!this.showOnlineBookingFields() ? 'no-display' : ''}`}>
                          <div className="simpleField m-b-15">
                            <div className="simpleLabel">Upload An Image To Display On Booking Portal<span className="setting-require">*</span></div>
                            <div className={(this.state.image_booking_portalError) ? "simpleInput field-error" : "simpleInput"}>
                              <input type="file"
                                className={(this.state.image_booking_portalError) ? "image_questionnaire field-error" : "image_questionnaire"}
                                name="image_booking_portal" autoComplete="off" onChange={this.onFileUpload} readOnly />
                              {this.fileData()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 m-t-20">
                          {<div className={(this.state.type == "package" || this.state.type == "group" || this.state.type == "bogo") ? "switch-accordian-outer customTaxRule" : "switch-accordian-outer customTaxRule no-display"}>
                            <div className="switch-accordian-row " id="book">
                              {this.state.inventoryLang.inventory_custom_tax_rule}
                              <label className="setting-switch pull-right">
                                <input type="checkbox" id="app_booking" name="is_tax_enabled" onChange={this.handleInputChange} checked={(this.state.is_tax_enabled) ? "checked" : false} className="setting-custom-switch-input" />
                                <span className="setting-slider"></span>
                              </label>
                            </div>
                            <div className="table-responsive">
                              <table className={(this.state.is_tax_enabled) ? "table-updated setting-table no-td-border no-hover" : "table-updated setting-table no-td-border no-hover no-display"}>
                                <thead className="table-updated-thead">
                                  <tr>
                                    <th className="col-xs-3 table-updated-th">Clinic Name</th>
                                    <th className="col-xs-2 table-updated-th">Tax(in percentage)</th>
                                  </tr>
                                </thead>
                                <tbody >
                                  {this.state.clinics.length && this.state.clinics.map((obj, idx) => {
                                    return (
                                      <tr key={'clinics-' + idx}>
                                        <td className="col-xs-3 table-updated-td">{obj.clinic_name}<span className="setting-require">*</span></td>
                                        <td className="col-xs-0 table-updated-td"><input className={(this.state["clinicTaxError-" + obj.id]) ? "tableInputTax field-error" : "tableInputTax"} type="number" min={0} name={"clinicTax-" + obj.id} onChange={this.handleInputChange} onBlur={this.handleTaxOnBlur} value={this.state["clinicTax-" + obj.id]} placeholder="0.0000" /></td>
                                      </tr>
                                    )
                                  })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>}
                        </div>
                      </div>



                      <div className="row">
                        <div className="col-xs-12 m-t-50">
                          <div className="modal-footer">
                            {this.state.status == 0 && (
                              <button
                                className="new-blue-btn pull-right"
                                id="saveform"
                                onClick={this.handleSubmit}
                              >
                                {this.state.isCloneMode ? 'Clone' : this.state.inventoryLang.inventory_save}
                              </button>
                            )}
                            {this.state.packageId > 0 && this.state.status == 0 && !this.state.isCloneMode && (
                              <button
                                className="new-red-btn pull-right"
                                onClick={() => this.setState({ showModal: true })}
                              >
                                {this.state.inventoryLang.inventory_delete}
                              </button>
                            )}
                            {this.state.isCloneMode && (
                              <button
                                className="new-red-btn pull-right"
                                onClick={history.goBack}
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={(this.state.showModal ? 'overlay' : '')}></div>
                  <div id="filterModal" role="dialog" className={(this.state.showModal ? 'modal fade in displayBlock' : 'modal fade')}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" onClick={() => {
                            this.setState({ showModal: false })
                          }}>×</button>
                          <h4 className="modal-title" id="model_title">{this.state.inventoryLang.inventory_Confirmation_required}{this.state.showModal}</h4>
                        </div>
                        <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                          {this.state.inventoryLang.inventory_delete_discount_package_msg}
                        </div>
                        <div className="modal-footer" >
                          <div className="col-md-12 text-left" id="footer-btn">
                            <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={() => {
                              this.setState({ showModal: false })
                            }}>{this.state.inventoryLang.inventory_No}</button>
                            <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.deleteDiscountPackage}>{this.state.inventoryLang.inventory_Yes}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  uiNotification.clear();
  localStorage.setItem("showLoader", false);

  returnState.bookingImage = state.InventoryReducer.bookingImage


  if (state.InventoryReducer.action === "DEFAULT_PACKAGE_DATA") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.packageDefaultData = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "BOGO_FREE_PRODUCT_LIST") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.bogoFreeProductList = {
        products: []
      }
    } else {
      returnState.bogoFreeProductList = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "BOGO_PRODUCT_LIST") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.bogoProductList = {
        products: []
      }
    } else {
      returnState.bogoProductList = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "PACKAGE_PRODUCT_LIST") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.packageProductList = {
        products: []
      }
    } else {
      returnState.packageProductList = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "SAVE_PACKAGE_DATA") {
    if (state.InventoryReducer.data.status == 201) {
      returnState.savedPackageData = state.InventoryReducer.data.data;
      returnState.message = languageData.global[state.InventoryReducer.data.message];
      returnState.savedData = true;
    } else if (state.InventoryReducer.data.status == 200) {
      returnState.message = languageData.global[state.InventoryReducer.data.message];
      returnState.savedData = true;
    } else {
      returnState.showLoader = false;
    }
  } else if (state.InventoryReducer.action === "DELETE_DISCOUNT_PACKAGE") {
    if (state.InventoryReducer.data.status == 200) {
      returnState.savedData = true;
      returnState.message = languageData.global[state.InventoryReducer.data.message];
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  } else if (state.InventoryReducer.action === "ACTIVATE_DISCOUNT_PACKAGE") {
    if (state.InventoryReducer.data.status == 200) {
      returnState.redirect = true;
      returnState.message = languageData.global[state.InventoryReducer.data.message];
    } else {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false
    }
  }

  return returnState
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDefaultPackageData: getDefaultPackageData,
    uploadImageBookingPortal: uploadImageBookingPortal,
    searchProductByName: searchProductByName, saveDiscountPackage: saveDiscountPackage, deleteDiscountPackage: deleteDiscountPackage, activateDiscountPackage: activateDiscountPackage, emptyInventoryReducer: emptyInventoryReducer
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateEditDiscountPackage));
