import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function getAllResources(formData) {
  return (dispatch) => {
    http
      .get("get-all-resources", formData)
      .then((response) => {
        dispatch({ type: "CLEARANCE_ALL_RESOURCES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLEARANCE_ALL_RESOURCES",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function createApplication(formData) {
  return (dispatch) => {
    http
      .post("create-application", formData)
      .then((response) => {
        dispatch({
          type: "CLEARANCE_CREATE_APPLICATION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CLEARANCE_CREATE_APPLICATION",
          payload: error.response.data,
        });
      });
  };
}

export function saveClearentData(formData) {
  return (dispatch) => {
    http
      .post("save-clearent-data", formData)
      .then((response) => {
        dispatch({ type: "CLEARANCE_SAVE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLEARANCE_SAVE_DATA", payload: error.response.data });
      });
  };
}

export function getSavedClearentData(id) {
  return (dispatch) => {
    http
      .get("get-clearent-detail?id=" + id)
      .then((response) => {
        dispatch({ type: "CLEARENT_SAVED_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLEARENT_SAVED_DATA", payload: error.response.data });
      });
  };
}

export function updateSignature(formData) {
  return (dispatch) => {
    http
      .post("update-signatures", formData)
      .then((response) => {
        dispatch({ type: "CLEARENT_UPDATE_SIGNATURE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLEARENT_UPDATE_SIGNATURE",
          payload: error.response.data,
        });
      });
  };
}

export function submitApp(formData) {
  return (dispatch) => {
    http
      .post("submit-application-and-signature", formData)
      .then((response) => {
        dispatch({ type: "SAVE_APP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_APP_DATA", payload: error.response.data });
      });
  };
}

export function downloadAgreement(formData) {
  return (dispatch) => {
    http
      .post("download-terms", formData)
      .then((response) => {
        dispatch({ type: "DOWNLOAD_AGREEMENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DOWNLOAD_AGREEMENT", payload: error.response.data });
      });
  };
}

export function deleteContact(formData) {
  return (dispatch) => {
    http
      .post("delete-clearent-contact", formData)
      .then((response) => {
        dispatch({ type: "DELETE_CLEARENT_CONTACT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_CLEARENT_CONTACT",
          payload: error.response.data,
        });
      });
  };
}
