import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useRestorePatientsMutation } from "../../../../api/mutations/useRestorePatientsMutation";
import { extractApiError } from "../../../../utilities/api";

export function useRestorePatients({ selectedPatientIds, onSuccess }) {
  const { tSettings } = useAppTranslation.Settings();

  const { mutate, isLoading } = useRestorePatientsMutation({
    onSuccess: () => {
      uiNotification.success(tSettings("deletedPatients.success.restore"));
      onSuccess();
    },
    onError: (e) => {
      const errorKey = extractApiError(e);
      uiNotification.error(
        tSettings([
          `deletedPatients.apiError.${errorKey}`,
          "deletedPatients.error.restore",
        ]),
      );
    },
  });

  return {
    restorePatients: () => mutate({ patientIds: selectedPatientIds }),
    isPatientsRestoring: isLoading,
  };
}
