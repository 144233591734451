/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { isEqual } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getBookingURL,
  updateBookingURL,
  cancellationPolicyStatus,
  resetReducerAction,
  webBookingConfiguration,
  getWebBookingConfiguration,
} from "../../Actions/Settings/settingsActions.js";
import AppointmentConfigNavbar from "./AppointmentConfigNavbar.js";
import { withQueryParamsState } from "../../utilities/hooks/useQueryParamsState.js";
import { InputPhone } from "../../shared/InputPhone/InputPhone.js";
import { phoneUtil } from "../../utilities/phone";
import { PatientPortalBannerTab } from "./widgets/PatientPortalBannerTab/PatientPortalBannerTab.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { TextEditor } from "../../shared/TextEditor/TextEditor.js";
import { InputLabel } from "../../shared/InputLabel/InputLabel.js";

const TAB_TYPES = {
  domainConfig: "domainConfiguration",
  settings: "settings",
  webConfig: "webConfiguration",
  theme: "theme",
  banner: "banner",
};

class BookingPortal extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const langData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      importantReminders: "",
      importantRemindersWordCount: 0,
      pportal_subdomain: "",
      showLoader: false,
      userChanged: false,
      save: "new-blue-btn url-save m-r-5",
      cancel: "new-white-btn url-save",
      edit: "new-blue-btn url-save",
      editUrl: false,
      edit_time: false,
      clinic_name: "",
      clinic_url: "",
      booking_time_interval: "60",
      urlEnable: "row m-b-25",
      urlDisable: "row m-b-25 no-display",
      intervalEnable: "row m-b-15",
      intervalDisable: "row m-b-15 no-display",
      buttonEnable: "col-xs-12",
      buttonDisable: "col-xs-12 no-display",
      clinics: [],
      hostname: "",
      domain_name: "",
      pportal_subdomainClass: "newInputField ar-url",
      lead_time: false,
      leadEnable: "row m-b-15",
      leadDisable: "row m-b-15 no-display",
      minimum_lead_time: "",
      temp_minimum_lead_time: "",
      temp_minimum_lead_time_label: "",
      minimum_lead_time_options: [],
      temp_booking_time_interval_label: "",
      booking_time_interval_options: [],
      langData: langData,
      first_available: false,
      availEnable: "row",
      availDisable: "row no-display",
      first_available_value: false,
      temp_first_available_value: "Off",
      changePhoneNr: false,
      description: "",
      memberships: 0,
      gift_cards: 0,
      promotions: 0,
      staff: 0,
      online_reviews: 0,
      phone_number: "",
      analytics_tracking_id: null,
      phone_numberClass: "newInputField ar-url",
      editNumber: false,
      numberEnable: "row",
      numberDisable: "row no-display",
      web: "",
      webClass: "newInputField ar-url",
      editWeb: false,
      webEnable: "row",
      webDisable: "row no-display",
      mail: "",
      policy: "",
      mailClass: "newInputField ar-url",
      policyClass: "newInputField ar-url",
      editMail: false,
      editPolicy: false,
      policyEnable: "row",
      policyDisable: "row no-display",
      mailEnable: "row",
      mailDisable: "row no-display",
      obPrivilegeEnabled: userData.permissions.includes(
        "booking-portal-configuration",
      ),
      defaultCountry: localStorage.getItem("cCode"),
      isPhoneError: false,
      themes: [
        {
          name: "grey",
          color: "#0F172A",
        },
        {
          name: "teal",
          color: "#2ED3B7",
        },
        {
          name: "blue",
          color: "#0B2559",
        },
        {
          name: "rose",
          color: "#E34B79",
        },
        {
          name: "brown",
          color: "#331D0A",
        },
        {
          name: "pink",
          color: "#C11574",
        },
        {
          name: "purple",
          color: "#7F56D9",
        },
        {
          name: "forest",
          color: "#3B945E",
        },
        {
          name: "cyan",
          color: "#088AB2",
        },
      ],
      selectedTheme: "",
    };
  }

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      Configure_URL_Customer_Portal:
        languageData.settings["Configure_URL_Customer_Portal"],
      Configure_URL_Url: languageData.settings["Configure_URL_Url"],
      Appointment_Reminder_Edit:
        languageData.settings["Appointment_Reminder_Edit"],
      editUsers_CancelBtn: languageData.settings["editUsers_CancelBtn"],
      Configure_URL_Appointment_Booking:
        languageData.settings["Configure_URL_Appointment_Booking"],
      Configure_URL_dot_estheticrecord_dot_com:
        languageData.settings["Configure_URL_dot_estheticrecord_dot_com"],
      Configure_URL_Customize_Appointment_Booking:
        languageData.settings["Configure_URL_Customize_Appointment_Booking"],
      Configure_URL_Time_Slots_For_Appointment_Booking:
        languageData.settings[
          "Configure_URL_Time_Slots_For_Appointment_Booking"
        ],
      Edit_Appointment_Reminder_Select:
        languageData.settings["Edit_Appointment_Reminder_Select"],
      Configure_URL_Mins_Increments:
        languageData.settings["Configure_URL_Mins_Increments"],
      saveBtn: languageData.global["saveBtn"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      Configure_URL_http: languageData.settings["Configure_URL_http"],
      Configure_URL_Five: languageData.settings["Configure_URL_Five"],
      Configure_URL_Ten: languageData.settings["Configure_URL_Ten"],
      Configure_URL_Fifteen: languageData.settings["Configure_URL_Fifteen"],
      Configure_URL_Tweenty: languageData.settings["Configure_URL_Tweenty"],
      Configure_URL_Tweenty_Five:
        languageData.settings["Configure_URL_Tweenty_Five"],
      Configure_URL_Thirty: languageData.settings["Configure_URL_Thirty"],
      Configure_URL_Thirty_Five:
        languageData.settings["Configure_URL_Thirty_Five"],
      Configure_URL_Fourty: languageData.settings["Configure_URL_Fourty"],
      Configure_URL_Fourty_Five:
        languageData.settings["Configure_URL_Fourty_Five"],
      Configure_URL_Fifty: languageData.settings["Configure_URL_Fifty"],
      Configure_URL_Fifty_Five:
        languageData.settings["Configure_URL_Fifty_Five"],
      Configure_URL_Sixty: languageData.settings["Configure_URL_Sixty"],
      Configure_URL_Minimum_lead_time_for_booking:
        languageData.settings["Configure_URL_Minimum_lead_time_for_booking"],
      Configure_URL_increments:
        languageData.settings["Configure_URL_increments"],
      yes_option: languageData.settings["yes_option"],
      no_option: languageData.settings["no_option"],
    });
    this.setState({ showLoader: true });
    this.props.getBookingURL();
    this.fetchWebConfiguration().finally(() => {
      this.setState({ showLoader: false });
    });
    const tab = this.props.queryParamsState?.t;
    if (!Object.values(TAB_TYPES).includes(tab)) {
      this.changeTab(TAB_TYPES.equipment);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.webConfiguration) {
      if (
        !isEqual(prevProps.webConfiguration, this.props.webConfiguration) &&
        this.props.webConfiguration.status === 200
      ) {
        this.setState({
          memberships: this.props.webConfiguration.data[0].is_membership_active,
          promotions: this.props.webConfiguration.data[0].is_promotion_active,
          gift_cards: this.props.webConfiguration.data[0].is_gift_card_active,
          staff: this.props.webConfiguration.data[0].is_staff_active,
          phone_number: this.props.webConfiguration.data[0].phone_number,
          web: this.props.webConfiguration.data[0].website,
          mail: this.props.webConfiguration.data[0].email,
          online_reviews:
            this.props.webConfiguration.data[0].is_online_review_active,
          description:
            this.props.webConfiguration.data[0].practice_info_description,
          importantReminders:
            this.props.webConfiguration.data[0].important_reminders,
          selectedTheme: this.props.webConfiguration.data[0].theme,
          analytics_tracking_id:
            this.props.webConfiguration.data[0].analytics_tracking_id,
          showLoader: false,
        });
      }
    }
  }

  fetchWebConfiguration = () => {
    return this.props
      .getWebBookingConfiguration()
      .then((res) => {
        const data = res?.data?.[0] || {};
        this.setState({
          webConfiguration: data,
          memberships: data.is_membership_active,
          promotions: data.is_promotion_active,
          gift_cards: data.is_gift_card_active,
          staff: data.is_staff_active,
          phone_number: data.phone_number,
          web: data.website,
          mail: data.email,
          policy: data.privacy,
          online_reviews: data.is_online_review_active,
          description: data.practice_info_description,
          selectedTheme: data.theme,
          analytics_tracking_id: data.analytics_tracking_id,
        });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  saveWebConfiguration = (form) => {
    this.props
      .webBookingConfiguration(form)
      .then(() => {
        this.fetchWebConfiguration();
        uiNotification.success(
          "Web Configuration has been successfully updated",
        );
      })
      .catch(() => {
        uiNotification.error(
          "Unable to update Web Configuration. Try again later",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.bookingList !== undefined &&
      props.bookingList.status === 200 &&
      state.clinics !== props.bookingList.data.clinics &&
      state.bookingList != props.bookingList.data
    ) {
      let returnState = {
        bookingList: props.bookingList.data,
        pportal_subdomain: state.userChanged
          ? state.pportal_subdomain
          : props.bookingList.data.account_details.pportal_subdomain,
        booking_time_interval: state.userChanged
          ? state.booking_time_interval
          : props.bookingList.data.account_details.booking_time_interval,
        temp_booking_time_interval:
          props.bookingList.data.account_details.booking_time_interval !==
          undefined
            ? props.bookingList.data.account_details.booking_time_interval
            : "60",
        clinic_name: state.userChanged
          ? state.clinic_name
          : props.bookingList.data.clinic_name,
        clinics: props.bookingList.data.clinics,
        verticals: props.bookingList.data.verticals
          ? props.bookingList.data.verticals
          : [],
        clinic_url: state.userChanged
          ? state.clinic_url
          : props.bookingList.data.clinic_url,
        hostname: state.userChanged
          ? state.hostname
          : props.bookingList.data.hostname,
        domain_name: state.userChanged
          ? state.domain_name
          : props.bookingList.data.domain_name,
        showLoader: false,
        minimum_lead_time: state.userChanged
          ? state.minimum_lead_time
          : props.bookingList.data.account_details.minimum_lead_time,
        temp_minimum_lead_time:
          props.bookingList.data.account_details.minimum_lead_time !== undefined
            ? props.bookingList.data.account_details.minimum_lead_time
            : "0",
        minimum_lead_time_options: props.bookingList.data.minimum_lead_time,
        booking_time_interval_options:
          props.bookingList.data.booking_time_interval,
        allow_patients_to_manage_appt: props.bookingList.data.smart_booking
          ? true
          : false,
        portal_registration: props.bookingList.data.patient_sign_up
          ? true
          : false,
        toggle_display_recaptcha: props.bookingList.data.captcha_enabled
          ? true
          : false,
        first_available_value: props.bookingList.data.show_first_available_btn
          ? true
          : false,
        temp_first_available_value: props.bookingList.data
          .show_first_available_btn
          ? "On"
          : "Off",
        temp_phone_number: props.bookingList.data.account_details.phone_number,
        temp_web: props.bookingList.data.account_details.web,
        temp_mail: props.bookingList.data.account_details.mail,
      };

      const intervalValue = returnState.booking_time_interval_options.filter(
        (obj) => obj.value == returnState.temp_booking_time_interval,
      );
      returnState.temp_booking_time_interval_label =
        returnState.temp_booking_time_interval !== "" &&
        intervalValue.length === 1
          ? intervalValue[0]["label"]
          : "";

      const leadValue = returnState.minimum_lead_time_options.filter(
        (obj) =>
          obj.value !== "" && obj.value == returnState.temp_minimum_lead_time,
      );
      returnState.temp_minimum_lead_time_label =
        returnState.temp_minimum_lead_time !== "" && leadValue.length === 1
          ? leadValue[0]["label"]
          : "1 hr";
      returnState.showLoader = false;
      props.resetReducerAction();
      return returnState;
    }
    if (props.showLoader != undefined && props.showLoader == false) {
      props.resetReducerAction();
      return { showLoader: false };
    }
    return null;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (target.name === "description") {
      if (target.value.split(" ").filter((el) => el).length > 50) return;
    }

    this.setState({
      [event.target.name]: value,
    });

    let returnState = {};

    if (target.name == "allow_patients_to_manage_appt") {
      if (value) {
        returnState.showLoader = true;
        let userData = JSON.parse(localStorage.getItem("userData"));
        userData.account.account_preference.allow_patients_to_manage_appt = 1;
        localStorage.setItem("userData", JSON.stringify(userData));
        this.props
          .cancellationPolicyStatus({
            field_name: "allow_patients_to_manage_appt",
            is_enabled: value ? 1 : 0,
          })
          .then(() => {
            this.setState({
              allow_patients_to_manage_appt: true,
            });
          });
      } else {
        this.showDeleteModal();
        returnState.formData = {
          field_name: "allow_patients_to_manage_appt",
          is_enabled: value ? 1 : 0,
        };
      }
    }

    if (target.name == "portal_registration") {
      if (value) {
        returnState.showLoader = true;
        this.props
          .cancellationPolicyStatus({
            field_name: "patient_sign_up",
            is_enabled: value ? 1 : 0,
          })
          .then(() => {
            this.setState({
              portal_registration: true,
            });
          });
      } else {
        this.deleteCustomerPortalPopup();
        returnState.formData = {
          field_name: "patient_sign_up",
          is_enabled: value ? 1 : 0,
        };
      }
    }

    if (target.name === "toggle_display_recaptcha") {
      if (value) {
        returnState.showLoader = true;
        this.props.cancellationPolicyStatus({
          field_name: "is_captcha_enabled",
          is_enabled: 1,
        });
      } else {
        returnState.showLoader = true;
        this.props.cancellationPolicyStatus({
          field_name: "is_captcha_enabled",
          is_enabled: 0,
        });
      }
    }

    if (target.type === "radio") {
      this.setState({ selectedTheme: target.name });
    }

    returnState[event.target.getAttribute("dataTheme")] = value;
    returnState.userChanged = true;
    this.setState(returnState);
  };

  changeURLValue = () => {
    this.setState({
      editUrl: !this.state.editUrl,
    });
  };

  changeCancelURLValue = () => {
    this.setState({
      editUrl: !this.state.editUrl,
      pportal_subdomain:
        this.state.bookingList.account_details.pportal_subdomain,
    });
  };

  changeNumberValue = () => {
    this.setState({
      editNumber: !this.state.editNumber,
    });
  };

  changeCancelNumberValue = () => {
    this.setState({
      editNumber: !this.state.editNumber,
      phone_number: this.state.webConfiguration.phone_number,
    });
  };

  changeWebValue = () => {
    this.setState({
      editWeb: !this.state.editWeb,
    });
  };

  changeCancelWebValue = () => {
    this.setState({
      editWeb: !this.state.editWeb,
      web: this.state.webConfiguration.website,
    });
  };

  changeMailValue = () => {
    this.setState({
      editMail: !this.state.editMail,
    });
  };

  changePolicyValue = () => {
    this.setState({
      editPolicy: !this.state.editPolicy,
    });
  };

  changeCancelMailValue = () => {
    this.setState({
      editMail: !this.state.editMail,
      mail: this.state.webConfiguration.email,
    });
  };

  changeCancelPolicyValue = () => {
    this.setState({
      editPolicy: !this.state.editPolicy,
      policy: this.state.webConfiguration.privacy,
    });
  };

  changeIntervalValue = () => {
    this.setState({
      edit_time: !this.state.edit_time,
    });
  };

  changeCancelIntervalValue = () => {
    this.setState({
      edit_time: !this.state.edit_time,
      booking_time_interval: this.state.temp_booking_time_interval,
    });
  };

  changeLeadValue = () => {
    this.setState({
      lead_time: !this.state.lead_time,
    });
  };

  changeCancelLeadValue = () => {
    this.setState({
      lead_time: !this.state.lead_time,
      minimum_lead_time: this.state.temp_minimum_lead_time,
    });
  };

  changeAvailableValue = () => {
    this.setState({
      first_available: !this.state.first_available,
    });
  };

  changeCancelAvailableValue = () => {
    this.setState({
      first_available: !this.state.first_available,
      first_available_value:
        this.state.temp_first_available_value === "Off" ? false : true,
    });
  };

  saveUrl = () => {
    let error = false;
    this.setState({
      pportal_subdomainError: false,
    });

    if (
      typeof this.state.pportal_subdomain === undefined ||
      this.state.pportal_subdomain === null ||
      this.state.pportal_subdomain === ""
    ) {
      this.setState({
        pportal_subdomainError: true,
        pportal_subdomainClassError: "newInputField ar-url field-error",
      });
      error = true;
    } else if (this.state.pportal_subdomain) {
      this.setState({
        pportal_subdomainError: false,
        pportal_subdomainClass: "newInputField ar-url",
      });
    }

    if (error) {
      return;
    }

    let formData = {
      pportal_subdomain: this.state.pportal_subdomain,
    };
    this.props.updateBookingURL(formData);
    this.setState({ showLoader: true });
    this.changeURLValue();
  };

  saveNumber = () => {
    if (!phoneUtil.isPossible(phoneUtil.compose(this.state.phone_number))) {
      this.setState({
        isPhoneError: true,
      });
      return uiNotification.error("Invalid phone number");
    }

    this.setState({ editNumber: false });

    let formData = {
      phone_number: phoneUtil.compose(this.state.phone_number),
    };
    this.saveWebConfiguration(formData);
    this.changeURLValue();
  };

  saveWeb = () => {
    let error = false;

    if (error) {
      return;
    }

    this.setState({ editWeb: false });

    let formData = {
      website: this.state.web,
    };
    this.saveWebConfiguration(formData);
    this.changeURLValue();
  };

  saveMail = () => {
    let error = false;

    if (error) {
      return;
    }

    this.setState({ editMail: false });

    let formData = {
      email: this.state.mail,
    };
    this.saveWebConfiguration(formData);
    this.changeURLValue();
  };

  savePolicy = () => {
    let error = false;

    if (error) {
      return;
    }

    this.setState({ editPolicy: false });

    let formData = {
      privacy: this.state.policy,
    };
    this.saveWebConfiguration(formData);
    this.changeURLValue();
  };

  handleSubmit = (timeType, event) => {
    event.preventDefault();

    if (timeType === "edit_time") {
      let error = false;

      if (this.state.booking_time_interval === "select") {
        uiNotification.error("Please select Time Interval");
        error = true;
      }

      if (error) {
        return;
      }
      let formData = {
        booking_time_interval: this.state.booking_time_interval,
      };
      this.props.updateBookingURL(formData);
      this.changeIntervalValue();
    } else if (timeType === "lead_time") {
      let error = false;

      if (this.state.minimum_lead_time === "select") {
        uiNotification.error("Please select Minimum Lead Time");
        error = true;
      }

      if (error) {
        return;
      }
      let formData = {
        minimum_lead_time: this.state.minimum_lead_time,
      };
      this.props.updateBookingURL(formData);
      this.changeLeadValue();
    } else if (timeType === "first_available") {
      let formData = {
        show_first_available_btn: this.state.first_available_value ? 1 : 0,
      };

      this.props.updateBookingURL(formData);
      this.changeAvailableValue();
    }
  };

  showDeleteModal = () => {
    this.setState({ showModal: true });
  };

  dismissModal = () => {
    this.setState({
      showModal: false,
      formData: {},
      allow_patients_to_manage_appt: true,
    });
  };

  dismissCustomerPortalPopup = () => {
    this.setState({
      showCustomerPortalPopup: false,
      formData: {},
      portal_registration: true,
    });
  };

  toggleSetting = () => {
    this.setState({ showLoader: true, hideBtns: true, showModal: false });
    let formData = this.state.formData;
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.account.account_preference.allow_patients_to_manage_appt = 0;
    localStorage.setItem("userData", JSON.stringify(userData));
    this.props.cancellationPolicyStatus(formData).then(() => {
      this.setState({
        allow_patients_to_manage_appt: false,
      });
    });
  };

  toggleSettingForCustomer = () => {
    this.setState({
      showLoader: true,
      hideBtns: true,
      showCustomerPortalPopup: false,
    });
    let formData = this.state.formData;
    this.props.cancellationPolicyStatus(formData).then(() => {
      this.setState({
        portal_registration: false,
      });
    });
  };

  changeTab = (mode) => {
    this.props.setQueryParamsState({
      t: mode,
    });
  };

  deleteCustomerPortalPopup = () => {
    this.setState({ showCustomerPortalPopup: true });
  };

  handleSubmitConfiguration = () => {
    this.setState({
      descriptionError: false,
    });
    let error = false;
    let formData = {};
    formData.practice_info_description = this.state.description;
    formData.is_membership_active = this.state.memberships ? 1 : 0;
    formData.is_gift_card_active = this.state.gift_cards ? 1 : 0;
    formData.is_promotion_active = this.state.promotions ? 1 : 0;
    formData.is_staff_active = this.state.staff ? 1 : 0;
    formData.is_online_review_active = this.state.online_reviews ? 1 : 0;
    formData.analytics_tracking_id = this.state.analytics_tracking_id || null;
    formData.important_reminders = this.state.importantReminders || null;

    if (!this.state.description) {
      this.setState({
        descriptionError: true,
      });
      error = true;
    }

    if (error === true) {
      return;
    }

    this.setState({ showLoader: true });
    this.saveWebConfiguration(formData);
  };

  handleSubmitTheme = () => {
    let formData = {};
    formData.theme = this.state.selectedTheme;
    this.setState({ showLoader: true });
    this.saveWebConfiguration(formData);
  };

  phoneNumberChanged = (number) => {
    this.setState({ userChanged: true });
    if (
      this.state.isPhoneError &&
      phoneUtil.isPossible(phoneUtil.compose(number))
    ) {
      this.setState({
        isPhoneError: false,
      });
    }
    this.setState({
      phone_number: number,
    });
  };

  render() {
    let appointmentsForClinics = [];
    let appointmentsForVerticals = [];
    if (this.state.clinics != undefined && this.state.clinics.length > 0) {
      appointmentsForClinics = this.state.clinics.map((obj, idx) => {
        return (
          <div className="col-xs-12" key={idx}>
            <div className="newInputFileldOuter booking-url-wrapper">
              <div className="newInputLabel">
                {obj.clinic_name} {"Url"}{" "}
                <span className="setting-require"></span>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={obj.clinic_url}
                className="newInputField"
              >
                <span>{obj.clinic_url}</span>
              </a>
            </div>
          </div>
        );
      });
    }
    if (this.state.verticals != undefined && this.state.verticals.length > 0) {
      appointmentsForVerticals = this.state.verticals.map((obj, idx) => {
        return (
          <div className="col-xs-12" key={idx}>
            <div className="newInputFileldOuter booking-url-wrapper">
              <div className="newInputLabel">
                {obj.name} {"Url"} <span className="setting-require"></span>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={obj.vertical_url}
                className="newInputField"
              >
                <span>{obj.vertical_url}</span>
              </a>
            </div>
          </div>
        );
      });
    }
    let url = "";
    if (this.state.hostname != "") {
      url =
        this.state.hostname +
        this.state.pportal_subdomain +
        "." +
        this.state.domain_name;
    }

    const tab = this.props.queryParamsState?.t;

    return (
      <div id="content">
        <div className="container-fluid content">
          <AppointmentConfigNavbar activeBooking={true} />
          <div className="setting-setion full-width">
            <div className="appointment-container">
              <div className="juvly-title m-b-10">
                {this.state.langData["appointments"]["app-booking-portal"]}
              </div>
              <ul className="appointment-tabs">
                <li>
                  <a
                    className={tab === TAB_TYPES.domainConfig ? "active" : ""}
                    onClick={() => {
                      this.changeTab(TAB_TYPES.domainConfig);
                    }}
                  >
                    {this.state.langData["appointments"]["domain-config"]}
                  </a>
                </li>
                <li>
                  <a
                    className={tab === TAB_TYPES.settings ? "active" : ""}
                    onClick={() => {
                      this.changeTab(TAB_TYPES.settings);
                    }}
                  >
                    {this.state.langData["settings"]["settings"]}
                  </a>
                </li>

                {Boolean(this.state.obPrivilegeEnabled) && (
                  <li>
                    <a
                      className={tab === TAB_TYPES.webConfig ? "active" : ""}
                      onClick={() => {
                        this.changeTab(TAB_TYPES.webConfig);
                      }}
                    >
                      Web Configuration
                    </a>
                  </li>
                )}
                {Boolean(this.state.obPrivilegeEnabled) && (
                  <li>
                    <a
                      className={tab === TAB_TYPES.theme ? "active" : ""}
                      onClick={() => {
                        this.changeTab(TAB_TYPES.theme);
                      }}
                    >
                      Theme
                    </a>
                  </li>
                )}
                {Boolean(this.state.obPrivilegeEnabled) && (
                  <li>
                    <a
                      className={tab === TAB_TYPES.banner ? "active" : ""}
                      onClick={() => {
                        this.changeTab(TAB_TYPES.banner);
                      }}
                    >
                      Banner
                    </a>
                  </li>
                )}
              </ul>
              <div
                className={
                  tab === TAB_TYPES.domainConfig
                    ? "appointment-content"
                    : "appointment-content no-display"
                }
              >
                <p className="booking-url-title">Patient Portal</p>
                <div
                  className={
                    this.state.editUrl == false
                      ? this.state.urlEnable
                      : this.state.urlDisable
                  }
                  id="viewmode"
                >
                  <div className="col-xs-11">
                    <div className="newInputFileldOuter booking-url-wrapper">
                      <div className="newInputLabel">
                        {this.state.Configure_URL_Url}
                        <span className="setting-require">*</span>
                      </div>
                      <div>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={url}
                          className="newInputField"
                        >
                          {url}
                        </a>
                      </div>
                      <a onClick={this.changeURLValue} className="editField">
                        <img alt="" src="/images/editIcon.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.editUrl == true
                      ? this.state.urlEnable
                      : this.state.urlDisable
                  }
                  id="editmode"
                >
                  <div className="col-lg-5 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.Configure_URL_Url}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        type="text"
                        id="pportal_subdomain"
                        name="pportal_subdomain"
                        className={
                          this.state.pportal_subdomainError === true
                            ? this.state.pportal_subdomainClassError
                            : this.state.pportal_subdomainClass
                        }
                        value={this.state.pportal_subdomain}
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.pportal_subdomainError === true ? "" : ""}
                      </span>
                      <span className="url-first">https://</span>
                      <span className="url-last">
                        .{this.state.domain_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xs-12 m-t-5">
                    <button
                      className="new-blue-btn url-save m-r-10"
                      id="saveurl"
                      onClick={this.saveUrl}
                    >
                      {this.state.saveBtn}
                    </button>
                    <button
                      className="new-white-btn url-save m-l-10"
                      id="cancelurl"
                      onClick={this.changeCancelURLValue}
                    >
                      {this.state.editUsers_CancelBtn}
                    </button>
                  </div>
                </div>
                <p className="booking-url-title">
                  {
                    this.state.langData["appointments"][
                      "appointments_booking_urls_clinics"
                    ]
                  }
                </p>
                <div className={"row m-b-25"}>{appointmentsForClinics}</div>
                {!!appointmentsForVerticals.length && (
                  <p className="booking-url-title">
                    {
                      this.state.langData["appointments"][
                        "appointments_booking_urls_verticals"
                      ]
                    }
                  </p>
                )}
                <div className="row">{appointmentsForVerticals}</div>
              </div>

              <div
                className={
                  tab === TAB_TYPES.settings
                    ? "appointment-content"
                    : "appointment-content no-display"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="booking-setting-section clearfix">
                      <h3 className="booking-setting-title">
                        {this.state.Configure_URL_Customer_Portal}
                      </h3>
                      <div className="booking-setting-switch-wrapper">
                        <span className="booking-setting-caption">
                          {
                            this.state.langData["appointments"][
                              "app-manage-through-customer-portal"
                            ]
                          }
                        </span>
                        <label className="setting-switch pull-right sm-switch">
                          <input
                            type="checkbox"
                            id="portalAccess"
                            className="confirm-model setting-custom-switch-input"
                            name="allow_patients_to_manage_appt"
                            checked={
                              this.state.allow_patients_to_manage_appt
                                ? "checked"
                                : false
                            }
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider"></span>
                        </label>
                      </div>
                      <p className="booking-setting-subcaption m-b-20">
                        {
                          this.state.langData["appointments"][
                            "app-book-cancel-rescedule"
                          ]
                        }
                      </p>
                      <div className="booking-setting-switch-wrapper m-b-10">
                        <span className="booking-setting-caption">
                          {
                            this.state.langData["appointments"][
                              "appointments_enable_customer_posrtal"
                            ]
                          }
                        </span>
                        <label className="setting-switch pull-right sm-switch">
                          <input
                            type="checkbox"
                            id="portalRegistration"
                            className="confirm-model  setting-custom-switch-input"
                            name="portal_registration"
                            checked={
                              this.state.portal_registration ? "checked" : false
                            }
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <div className="booking-setting-section clearfix">
                      <h3 className="booking-setting-title">
                        {
                          this.state.langData["appointments"][
                            "appointments_appointment_times"
                          ]
                        }
                      </h3>

                      <div
                        className={
                          this.state.edit_time == true
                            ? this.state.intervalEnable
                            : this.state.intervalDisable
                        }
                        id="edit_time_box"
                      >
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter m-t-0 m-b-0">
                            <div className="p-r-25">
                              <span className="booking-setting-caption">
                                {
                                  this.state
                                    .Configure_URL_Time_Slots_For_Appointment_Booking
                                }
                                :&nbsp;
                              </span>
                              <select
                                name="booking_time_interval"
                                id="booking_time_interval"
                                className="setting-select-box ar-url"
                                value={this.state.booking_time_interval}
                                onChange={this.handleInputChange}
                              >
                                {this.state.booking_time_interval_options
                                  .length > 0 ? (
                                  this.state.booking_time_interval_options.map(
                                    (obj, idx) => {
                                      return (
                                        <option
                                          key={
                                            "booking_time_interval_options" +
                                            idx
                                          }
                                          value={obj.value}
                                        >
                                          {obj.label}
                                        </option>
                                      );
                                    },
                                  )
                                ) : (
                                  <option value="select">
                                    {
                                      this.state
                                        .Edit_Appointment_Reminder_Select
                                    }
                                  </option>
                                )}
                              </select>{" "}
                              &nbsp;
                              <span className="booking-setting-caption">
                                {this.state.Configure_URL_increments}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.edit_time == true
                              ? this.state.buttonEnable
                              : this.state.buttonDisable
                          }
                        >
                          <button
                            className="new-blue-btn url-save m-r-5"
                            id="save_time"
                            onClick={this.handleSubmit.bind(this, "edit_time")}
                          >
                            {this.state.saveBtn}
                          </button>
                          <button
                            className="new-white-btn url-save m-l-10"
                            id="cancel_time"
                            onClick={this.changeCancelIntervalValue}
                          >
                            {this.state.editUsers_CancelBtn}
                          </button>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.edit_time == false
                            ? this.state.intervalEnable
                            : this.state.intervalDisable
                        }
                        id="print_time_box"
                      >
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter m-t-0 m-b-0">
                            <div className="p-r-25">
                              <span className="booking-setting-caption">
                                {
                                  this.state
                                    .Configure_URL_Time_Slots_For_Appointment_Booking
                                }
                                :&nbsp;
                              </span>
                              <span
                                className="booking-setting-caption"
                                id="seltime"
                              >
                                {this.state.temp_booking_time_interval_label}
                                &nbsp;
                              </span>
                              <span className="booking-setting-caption">
                                {this.state.Configure_URL_increments}
                              </span>
                              <a
                                onClick={this.changeIntervalValue}
                                className="booking-editField editField appointmentTimeSettingsEdit"
                              >
                                <img
                                  alt=""
                                  className="booking-editField-img"
                                  src="/images/editIcon.png"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          this.state.lead_time == true
                            ? this.state.leadEnable
                            : this.state.leadDisable
                        }
                        id="lead_time_box"
                      >
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter m-t-0 m-b-0">
                            <div className="p-r-25">
                              <span className="booking-setting-caption">
                                {
                                  this.state
                                    .Configure_URL_Minimum_lead_time_for_booking
                                }
                                : &nbsp;
                              </span>
                              <select
                                name="minimum_lead_time"
                                id="minimum_lead_time"
                                className="setting-select-box ar-url"
                                value={this.state.minimum_lead_time}
                                onChange={this.handleInputChange}
                              >
                                {this.state.minimum_lead_time_options.length >
                                0 ? (
                                  this.state.minimum_lead_time_options.map(
                                    (obj, idx) => {
                                      return (
                                        <option
                                          key={
                                            "minimum_lead_time_options" + idx
                                          }
                                          value={obj.value}
                                        >
                                          {obj.label}
                                        </option>
                                      );
                                    },
                                  )
                                ) : (
                                  <option value="select">
                                    {
                                      this.state
                                        .Edit_Appointment_Reminder_Select
                                    }
                                  </option>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.lead_time == true
                              ? this.state.buttonEnable
                              : this.state.buttonDisable
                          }
                        >
                          <button
                            className="new-blue-btn url-save m-r-5"
                            id="save_time"
                            onClick={this.handleSubmit.bind(this, "lead_time")}
                          >
                            {this.state.saveBtn}
                          </button>
                          <button
                            className="new-white-btn url-save m-l-10"
                            id="cancel_time"
                            onClick={this.changeCancelLeadValue}
                          >
                            {this.state.editUsers_CancelBtn}
                          </button>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.lead_time == false
                            ? this.state.leadEnable
                            : this.state.leadDisable
                        }
                        id="print_time_box"
                      >
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter m-t-0 m-b-0">
                            <div className="p-r-25">
                              <span className="booking-setting-caption">
                                {
                                  this.state
                                    .Configure_URL_Minimum_lead_time_for_booking
                                }
                                :&nbsp;
                              </span>
                              <span
                                className="booking-setting-caption"
                                id="selleadtime"
                              >
                                {this.state.temp_minimum_lead_time_label}
                              </span>
                              <a
                                onClick={this.changeLeadValue}
                                className="booking-editField editField appointmentTimeSettingsEdit"
                              >
                                <img
                                  alt=""
                                  className="booking-editField-img"
                                  src="/images/editIcon.png"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          this.state.first_available == true
                            ? this.state.availEnable
                            : this.state.availDisable
                        }
                        id="first_available_box"
                      >
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter m-t-0 m-b-0">
                            <div className="p-r-25">
                              <span className="booking-setting-caption">
                                {`Book with First Available`}: &nbsp;
                              </span>
                              <label className="setting-switch ar-url sm-switch">
                                <input
                                  type="checkbox"
                                  id="firstAvailCheck"
                                  className="setting-custom-switch-input"
                                  name="first_available_value"
                                  checked={
                                    this.state.first_available_value
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <span className="setting-slider "></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.first_available == true
                              ? this.state.buttonEnable
                              : this.state.buttonDisable
                          }
                        >
                          <button
                            className="new-blue-btn url-save m-r-5"
                            id="save_time"
                            onClick={this.handleSubmit.bind(
                              this,
                              "first_available",
                            )}
                          >
                            {this.state.saveBtn}
                          </button>
                          <button
                            className="new-white-btn url-save m-l-10"
                            id="cancel_available"
                            onClick={this.changeCancelAvailableValue}
                          >
                            {this.state.editUsers_CancelBtn}
                          </button>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.first_available == false
                            ? this.state.availEnable
                            : this.state.availDisable
                        }
                        id="print_available_box"
                      >
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter m-t-0 m-b-0">
                            <div className="p-r-25">
                              <span className="booking-setting-caption">
                                {`Book with First Available`}:&nbsp;
                              </span>
                              <span
                                className="booking-setting-caption"
                                id="selAvail"
                              >
                                {this.state.temp_first_available_value}
                              </span>
                              <a
                                onClick={this.changeAvailableValue}
                                className="booking-editField editField appointmentTimeSettingsEdit"
                              >
                                <img
                                  alt=""
                                  className="booking-editField-img"
                                  src="/images/editIcon.png"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  tab === TAB_TYPES.webConfig
                    ? "appointment-content"
                    : "appointment-content no-display"
                }
              >
                <div className="row">
                  <div className="col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        Practice Introduction & Description{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer">
                        <textarea
                          name="description"
                          id="description"
                          className={
                            this.state.descriptionError === true
                              ? "newtextareaField h-130 field_error"
                              : "newtextareaField h-130"
                          }
                          type="textarea"
                          value={this.state.description}
                          onChange={this.handleInputChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <p className="pull-right">
                      Word Count{" "}
                      {
                        this.state.description
                          .replace(/\n/g, " ")
                          .split(" ")
                          .filter((el) => el).length
                      }
                      /50
                    </p>
                  </div>
                  <div className="col-xs-12">
                    <div className="m-b-20">
                      <InputLabel>
                        Patient Portal Important Reminders{" "}
                        <span className="setting-require">*</span>
                      </InputLabel>
                      <TextEditor
                        value={this.state.importantReminders}
                        onChange={(x, y) => {
                          if (y.plugins.wordcount.body.getWordCount() <= 50) {
                            this.setState({
                              importantReminders: x,
                              importantRemindersWordCount:
                                y.plugins.wordcount.body.getWordCount(),
                            });
                          }
                        }}
                        init={{
                          plugins: ["link", "autolink", "wordcount"],
                          toolbar:
                            "undo redo bold italic strikethrough subscript superscript link",
                          max_height: 300,
                        }}
                      />
                      <p className="pull-right">
                        Word Count {this.state.importantRemindersWordCount}
                        /50
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-lg-6">
                    <h5 className="booking-setting-title">
                      Display The Following Items On Booking Portal
                    </h5>

                    <div className="row m-b-10">
                      <div className="col-xs-6 col-md-6 col-lg-4">
                        <p className="m-t-15">
                          <input
                            id="memberships"
                            type="checkbox"
                            className="new-check"
                            name="memberships"
                            onChange={this.handleInputChange}
                            checked={this.state.memberships ? "checked" : false}
                          />
                          <label htmlFor="memberships">Memberships</label>
                        </p>
                      </div>
                      <div className="col-xs-6 col-md-6 col-lg-3">
                        <p className="m-t-15">
                          <input
                            id="gift_cards"
                            type="checkbox"
                            className="new-check"
                            name="gift_cards"
                            onChange={this.handleInputChange}
                            checked={this.state.gift_cards ? "checked" : false}
                          />
                          <label htmlFor="gift_cards">Gift Cards</label>
                        </p>
                      </div>
                      <div className="col-xs-6 col-md-6 col-lg-3">
                        <p className="m-t-15">
                          <input
                            id="promotions"
                            type="checkbox"
                            className="new-check"
                            name="promotions"
                            onChange={this.handleInputChange}
                            checked={this.state.promotions ? "checked" : false}
                          />
                          <label htmlFor="promotions">Promotions</label>
                        </p>
                      </div>
                      <div className="col-xs-6 col-md-6 col-lg-3">
                        <p className="m-t-15">
                          <input
                            id="staff"
                            type="checkbox"
                            className="new-check"
                            name="staff"
                            onChange={this.handleInputChange}
                            checked={this.state.staff ? "checked" : false}
                          />
                          <label htmlFor="staff">Staff</label>
                        </p>
                      </div>
                    </div>

                    <h5 className="booking-setting-title m-t-10 m-b-10">
                      Google Analytics Measurement Id
                    </h5>

                    <div className="newInputFileldOuter analytics-measurement-field">
                      <input
                        type="text"
                        id="analytics_tracking_id"
                        name="analytics_tracking_id"
                        className={"newInputField ar-url"}
                        value={this.state.analytics_tracking_id}
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-lg-6 m-b-40 custom-edit">
                    <h5 className="booking-setting-title">
                      Practice Information
                    </h5>

                    <div
                      className={
                        this.state.editNumber
                          ? this.state.numberDisable
                          : this.state.numberEnable
                      }
                    >
                      <div className="col-xs-11">
                        <div className="newInputFileldOuter booking-url-wrapper">
                          <div className="newInputLabel">Phone Number:</div>
                          <div>
                            <a className="newInputField">
                              {phoneUtil.compose(this.state.phone_number)}
                            </a>
                          </div>
                          <a
                            onClick={this.changeNumberValue}
                            className="editField"
                          >
                            <img alt="" src="/images/editIcon.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.editNumber
                          ? this.state.numberEnable
                          : this.state.numberDisable
                      }
                    >
                      <div className="col-lg-7 col-xs-12 position-phone">
                        <div className="newInputFileldOuter relative m-t-18">
                          <div className="newInputLabel absolute top--10">
                            Phone Number:
                          </div>
                          <InputPhone
                            variant="legacy-outlined"
                            value={this.state.phone_number}
                            onChange={this.phoneNumberChanged.bind(this)}
                            isError={this.state.isPhoneError}
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-xs-12 m-t-5">
                        <button
                          className="new-blue-btn url-save m-r-10"
                          id="saveNumber"
                          onClick={this.saveNumber}
                        >
                          {this.state.saveBtn}
                        </button>
                        <button
                          className="new-white-btn url-save"
                          id="changeCancelNumberValue"
                          onClick={this.changeCancelNumberValue}
                        >
                          {this.state.editUsers_CancelBtn}
                        </button>
                      </div>
                    </div>

                    <div
                      className={
                        this.state.editWeb
                          ? this.state.webDisable
                          : this.state.webEnable
                      }
                    >
                      <div className="col-xs-11">
                        <div className="newInputFileldOuter booking-url-wrapper">
                          <div className="newInputLabel">Website:</div>
                          <div>
                            <a className="newInputField">{this.state.web}</a>
                          </div>
                          <a
                            onClick={this.changeWebValue}
                            className="editField"
                          >
                            <img alt="" src="/images/editIcon.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.editWeb
                          ? this.state.webEnable
                          : this.state.webDisable
                      }
                    >
                      <div className="col-lg-7 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">Website:</div>
                          <input
                            type="text"
                            id="web"
                            name="web"
                            className={
                              this.state.webError === true
                                ? this.state.webClassError
                                : this.state.webClass
                            }
                            value={this.state.web}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                          />
                          <span style={{ color: "red" }}></span>
                        </div>
                      </div>
                      <div className="col-lg-5 col-xs-12 m-t-5">
                        <button
                          className="new-blue-btn url-save m-r-10"
                          id="saveWeb"
                          onClick={this.saveWeb}
                        >
                          {this.state.saveBtn}
                        </button>
                        <button
                          className="new-white-btn url-save"
                          id="changeCancelWebValue"
                          onClick={this.changeCancelWebValue}
                        >
                          {this.state.editUsers_CancelBtn}
                        </button>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.editPolicy
                          ? this.state.policyDisable
                          : this.state.policyEnable
                      }
                    >
                      <div className="col-xs-11">
                        <div className="newInputFileldOuter booking-url-wrapper">
                          <div className="newInputLabel">
                            Privacy Policy URL:
                          </div>
                          <div>
                            <a className="newInputField">{this.state.policy}</a>
                          </div>
                          <a
                            onClick={this.changePolicyValue}
                            className="editField"
                          >
                            <img alt="" src="/images/editIcon.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.editPolicy
                          ? this.state.policyEnable
                          : this.state.policyDisable
                      }
                    >
                      <div className="col-lg-7 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            Privacy Policy URL:
                          </div>
                          <input
                            type="text"
                            id="mail"
                            name="policy"
                            className={this.state.mailClass}
                            value={this.state.policy}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                          />
                          <span style={{ color: "red" }}></span>
                        </div>
                      </div>
                      <div className="col-lg-5 col-xs-12 m-t-5">
                        <button
                          className="new-blue-btn url-save m-r-10"
                          id="savePolicy"
                          onClick={this.savePolicy}
                        >
                          {this.state.saveBtn}
                        </button>
                        <button
                          className="new-white-btn url-save"
                          id="changeCancePolicyValue"
                          onClick={this.changeCancelPolicyValue}
                        >
                          {this.state.editUsers_CancelBtn}
                        </button>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.editMail
                          ? this.state.mailDisable
                          : this.state.mailEnable
                      }
                    >
                      <div className="col-xs-11">
                        <div className="newInputFileldOuter booking-url-wrapper">
                          <div className="newInputLabel">Email:</div>
                          <div>
                            <a className="newInputField">{this.state.mail}</a>
                          </div>
                          <a
                            onClick={this.changeMailValue}
                            className="editField"
                          >
                            <img alt="" src="/images/editIcon.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.editMail
                          ? this.state.mailEnable
                          : this.state.mailDisable
                      }
                    >
                      <div className="col-lg-7 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">Email:</div>
                          <input
                            type="text"
                            id="mail"
                            name="mail"
                            className={
                              this.state.mailError === true
                                ? this.state.mailClassError
                                : this.state.mailClass
                            }
                            value={this.state.mail}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                          />
                          <span style={{ color: "red" }}></span>
                        </div>
                      </div>
                      <div className="col-lg-5 col-xs-12 m-t-5">
                        <button
                          className="new-blue-btn url-save m-r-10"
                          id="saveMail"
                          onClick={this.saveMail}
                        >
                          {this.state.saveBtn}
                        </button>
                        <button
                          className="new-white-btn url-save"
                          id="changeCancelMailValue"
                          onClick={this.changeCancelMailValue}
                        >
                          {this.state.editUsers_CancelBtn}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className="footer-static">
                      <a
                        className="new-blue-btn pull-right"
                        id="submit_configuration"
                        onClick={this.handleSubmitConfiguration}
                      >
                        Save
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  tab === TAB_TYPES.theme
                    ? "appointment-content"
                    : "appointment-content no-display"
                }
              >
                <div className="row booking-theme-tab">
                  <div className="col-xs-12">
                    <h5 className="booking-setting-title">
                      Please Select Your Booking Portal Color Theme:
                    </h5>
                    <div className="row">
                      {this.state.themes.map((theme) => {
                        return (
                          <div
                            className="col-xs-6 col-md-4 col-lg-4 col-xl-3"
                            key={theme.name}
                          >
                            <p className="m-t-25">
                              <input
                                id={theme.name}
                                name={theme.name}
                                type="radio"
                                className="new-check"
                                onChange={this.handleInputChange}
                                checked={
                                  this.state.selectedTheme === theme.name
                                }
                              />
                              <label htmlFor={theme.name} className="m-b-0">
                                <svg
                                  className="m-l-5"
                                  width="245"
                                  height="40"
                                  viewBox="0 0 245 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="35"
                                    height="40"
                                    fill={theme.color}
                                  />
                                  <rect
                                    x="35"
                                    width="35"
                                    height="40"
                                    fillOpacity=".8"
                                    fill={theme.color}
                                  />
                                  <rect
                                    x="70"
                                    width="35"
                                    height="40"
                                    fillOpacity=".7"
                                    fill={theme.color}
                                  />
                                  <rect
                                    x="105"
                                    width="35"
                                    height="40"
                                    fillOpacity=".6"
                                    fill={theme.color}
                                  />
                                  <rect
                                    x="140"
                                    width="35"
                                    height="40"
                                    fillOpacity=".5"
                                    fill={theme.color}
                                  />
                                  <rect
                                    x="175"
                                    width="35"
                                    height="40"
                                    fillOpacity=".4"
                                    fill={theme.color}
                                  />
                                  <rect
                                    x="210"
                                    width="35"
                                    height="40"
                                    fillOpacity=".3"
                                    fill={theme.color}
                                  />
                                </svg>
                              </label>
                            </p>
                          </div>
                        );
                      })}
                      <div className="col-xs-12 m-t-30">
                        <div className="footer-static">
                          <a
                            className="new-blue-btn pull-right"
                            id="submit_theme"
                            onClick={this.handleSubmitTheme}
                          >
                            Save
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {tab === TAB_TYPES.banner && <PatientPortalBannerTab />}

              <div
                className={
                  this.state.showLoader
                    ? "new-loader text-left displayBlock"
                    : "new-loader text-left"
                }
              >
                <div className="loader-outer">
                  <img
                    alt=""
                    id="loader-outer"
                    src="/images/Eclipse.gif"
                    className="loader-img"
                  />
                  <div id="modal-confirm-text" className="popup-subtitle">
                    {this.state.clinic_Please_Wait}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.showModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showModal ? "modal fade in displayBlock" : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {
                      this.state.langData["appointments"][
                        "app-confirmation-required"
                      ]
                    }
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {
                    this.state.langData["appointments"][
                      "app-disable-manage-message"
                    ]
                  }
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      {this.state.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.toggleSetting}
                    >
                      {this.state.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal to enable/disable customer portal */}
          <div
            className={this.state.showCustomerPortalPopup ? "overlay" : ""}
          ></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showCustomerPortalPopup
                ? "modal fade in displayBlock"
                : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissCustomerPortalPopup}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {
                      this.state.langData["appointments"][
                        "app-confirmation-required"
                      ]
                    }
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {
                    this.state.langData["appointments"][
                      "app-disable-manage-message"
                    ]
                  }
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissCustomerPortalPopup}
                    >
                      {this.state.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.toggleSettingForCustomer}
                    >
                      {this.state.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.SettingReducer.action === "GET_BOOKING_URL_LIST") {
    if (state.SettingReducer.data.status == 200) {
      returnState.bookingList = state.SettingReducer.data;
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.bookingListUpdate = state.SettingReducer.data;
    }
  }
  if (state.SettingReducer.action === "UPDATE_BOOKING_URL_LIST") {
    if (state.SettingReducer.data.status == 200) {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.bookingList = state.SettingReducer.data;
      returnState.userChanged = false;
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.bookingListUpdate = state.SettingReducer.data;
    }
  }

  if (state.SettingReducer.action === "CANCELLATION_POLICY_STATUS") {
    if (state.SettingReducer.data.status == 200) {
      returnState.timeStamp = new Date();
    } else {
      returnState.policyData = state.SettingReducer.data;
    }
    returnState.showLoader = false;
  }

  returnState.privilegeList = state.SettingReducer.privilegeList;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBookingURL: getBookingURL,
      updateBookingURL: updateBookingURL,
      cancellationPolicyStatus: cancellationPolicyStatus,
      resetReducerAction: resetReducerAction,
      webBookingConfiguration,
      getWebBookingConfiguration,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withQueryParamsState({
    t: TAB_TYPES.domainConfig,
  })(BookingPortal),
);
