import React from "react";

const Select = React.forwardRef((props, _ref) => {
  const {
    name,
    className = "newSelectField",
    register,
    isRequired = false,
    options,
  } = props;

  return (
    <React.Fragment>
      <select
        type="select"
        className={className}
        name={name}
        ref={register({
          required: isRequired,
        })}
      >
        <option value="">Select</option>
        {options &&
          options.length > 0 &&
          options.map((obj, idx) => (
            <option key={idx} value={obj.value}>
              {obj.label}
            </option>
          ))}
      </select>
    </React.Fragment>
  );
});

export default Select;
