import queryString from "query-string";
import { useEffect } from "react";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { history } from "../../../history";
import { ROUTES } from "../../../consts/routes";

export function useSessionExpireMessage() {
  var { tAuth: t } = useAppTranslation.Auth();

  var searchParams = queryString.parse(window.location.search);
  var withMessage = searchParams?.sessionExpired === "true";

  useEffect(() => {
    if (withMessage) {
      uiNotification.warn(t("error.sessionExpired"));
      history.push(ROUTES.login());
    }
  }, [withMessage]);
}
