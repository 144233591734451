import React, { Component } from "react";
import Sidebar from "../../../_legacy/Containers/Settings/sidebar.js";
import Loader from "../../Common/Loader";
import ConfirmationModal from "../../Appointment/Calendar/ConfirmationModal/ConfirmationModal.js";
import { getLeadsSources, getRegisteredApiKeys, createLeadsApiKey, deleteLeadsApiKey } from "../../../Actions/LeadsApiIntegration/LeadsApiIntegrationAction.js";
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import {
  getIsAcceleratorPlan
} from '../../../Utils/services';
import UpgradeAccountModal from "../../Common/UpgradeAccountModal.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class LeadsApiIntegration extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.settingsLang = languageData.settings;
    this.globalLang = languageData.global;

    this.state = {
      is_active: getIsAcceleratorPlan(),
      showDeleteModal: false,
      selectedSource: '',
      apiKey: '',
      sourceCode: '',
      isShowUpgradeAlertModal: !getIsAcceleratorPlan(),
    };
  }

  componentDidMount () {
    this.props.getLeadsSources()
    this.props.getRegisteredApiKeys()
  }

  componentDidUpdate (prevProps) {
    if(prevProps.createApiKey.status !== 'success' && this.props.createApiKey.status === 'success') {
      // Call functions after request status is success
      this.props.getLeadsSources()
      this.props.getRegisteredApiKeys()
      this.setState({ selectedSource: '' })
      this.props.createApiKey.status = 'loading'
    }

    if(prevProps.deleteApiKey.status !== 'success' && this.props.deleteApiKey.status === 'success') {
      // Call functions after request status is success
      this.props.getLeadsSources()
      this.props.getRegisteredApiKeys()
      this.setState({ selectedSource: '' })
      this.props.deleteApiKey.status = 'loading'
    }

  }

  handleToggleActive() {
    this.setState({ is_active: !this.state.is_active });
  }

  copyToClipboard = (text) => { // Copy the key to clipboard
    navigator.clipboard.writeText(text)
    uiNotification.success("Key Copied To Clickboard!", {autoClose: 2000})
  }

  handleSelectSource(event) {
    this.setState({ selectedSource: event.target.value })
  }

  handleGenerateKeyButton() {
    if(this.state.selectedSource === "") {
      return uiNotification.error("Please Select A Source!")
    }
    this.props.createLeadsApiKey({ source_code: this.state.selectedSource })
  }

  handleDeleteKeyButton(source, key) {
    this.setState({ sourceCode: source, apiKey: key, showDeleteModal: true })
  }

  handleConfirmDelete() {
    this.props.deleteLeadsApiKey({ source_code: this.state.sourceCode, api_key: this.state.apiKey })
    this.setState( { apiKey: '', sourceCode: '', showDeleteModal: false } )
  }

  renderLeadsApiSources() {
    if(this.props.leadsApiSources.status !== 'success') {
      return;
    }
    return this.props.leadsApiSources.data.map(source => { // Render sources from request
      return (
        <option key={source.code} value={source.code}>{source.name}</option>
      )
    })
  }

  renderRegisteredApiKeys() {
    if(this.props.registeredApiKeys.status !== 'success') {
      return;
    }
    return this.props.registeredApiKeys.data.map(apikey => { // Render api keys datas from request
      return (
        <tr key={apikey.api_key}>
          <td className="col-xs-2 table-updated-td">{apikey.name}</td>
          <td className="col-xs-4 table-updated-td">{apikey.api_key}</td>
          <td className="col-xs-6 table-updated-td text-center"><a className="new-line-btn no-width no-margin m-r-2" onClick={() => this.copyToClipboard(apikey.api_key)}>Copy Key</a> <a className="new-line-btn no-width no-margin m-l-2" onClick={() => this.handleDeleteKeyButton(apikey.code, apikey.api_key)}>Delete</a></td>
        </tr>
      )
    })
  }

  handleShowUpgradeAlertModal = () => {
    this.setState({ isShowUpgradeAlertModal: !this.state.isShowUpgradeAlertModal });
  };

  render() {
    return (
      <div id="content">
        <UpgradeAccountModal notificaionType={'integration'}
                             isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
                             handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}/>
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                Leads API Integration
              </div>
            </div>
            {this.state.is_active && <>
            <div className="col-sm-12 p-l-0 p-r-0">
              <div className="setting-setion m-b-15">
                <div className="membershipSectionTitle">Generate New Key</div>
                <div className="wallet-edit-block">
                  <div className="membershipSectionContent p5">
                    <div className="row">
                      <div className="col-xs-12 m-t-20 m-b-15">
                        <div className="col-lg-6 col-xs-6">
                          <select className="simpleSelect" id="newKeySources" value={this.state.selectedSource} onChange={(event) => this.handleSelectSource(event)}>
                            <option value="" disabled>Select A Source</option>
                            {this.renderLeadsApiSources()}
                          </select>
                        </div>
                        <div className="col-lg-6 col-xs-6">
                          <button className="new-blue-btn pull-right m-t-0" onClick={() => this.handleGenerateKeyButton()}>
                            Generate Key
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 p-l-0 p-r-0">
              <div className="setting-setion">
                <div className="membershipSectionTitle">Existing Keys</div>
                <div className="wallet-edit-block">
                  <div className="membershipSectionContent p5">
                    <table className="table-updated setting-table table-min-width no-td-border">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="col-xs-2 table-updated-th">Source</th>
                          <th className="col-xs-4 table-updated-th">Key</th>
                          <th className="col-xs-5 table-updated-th text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderRegisteredApiKeys()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            </>}
          </div>
        </div>
        <Loader showLoader={(this.props.leadsApiSources.status !== 'success' && this.props.registeredApiKeys !== 'success') ? true : false} isFullWidth={true} />
        <ConfirmationModal isOpen={this.state.showDeleteModal} message={"Are you sure you want to detele this key?"} confirmFn={() => this.handleConfirmDelete()} cancelFn={() => this.setState({ showDeleteModal: false })} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    leadsApiSources: state.LeadsAPIReducer.leadsApiSources,
    registeredApiKeys: state.LeadsAPIReducer.registeredApiKeys,
    createApiKey: state.LeadsAPIReducer.createApiKey,
    deleteApiKey: state.LeadsAPIReducer.deleteApiKey,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getLeadsSources, getRegisteredApiKeys, createLeadsApiKey, deleteLeadsApiKey }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsApiIntegration);
