import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import {
  getEvolusConfig,
  saveEvolusConfig,
  getEvolusFacilities
} from '../../../Actions/Integrations/evolusIntegrationActions';
import {showFormattedDate} from "../../../Utils/services";
import { uiNotification } from '../../../services/UINotificationService.js';

class EvolusIntegration extends Component {
  constructor (props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.settingsLang = languageData.settings;
    this.globalLang = languageData.global;

    this.state = {
      evolus_account: '',
      evolus_email: '',
      showEditView: false,
      clinicMap: new Map(),
    };
  }

  componentDidMount () {
    this.props.getEvolusConfig();
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.evolusConfigData.status !== 'error' && this.props.evolusConfigData.status === 'error') {
      uiNotification.error(this.props.evolusConfigData.error);
    }

    if (prevProps.evolusFacilitiesData.status !== 'error' && this.props.evolusFacilitiesData.status === 'error') {
      uiNotification.error(this.props.evolusFacilitiesData.error);
    }

    if (prevProps.saveEvolusConfigData.status !== 'error' && this.props.saveEvolusConfigData.status === 'error') {
      uiNotification.error(this.props.saveEvolusConfigData.error);
    }

    if (prevProps.saveEvolusConfigData.status !== 'success' && this.props.saveEvolusConfigData.status === 'success') {
      this.setState({ showEditView: false, clincMap: new Map() });
    }

    if (prevProps.evolusConfigData.status === 'loading' && this.props.evolusConfigData.status === 'success') {
      if (this.props.evolusConfigData.config) {
        this.setState({ evolus_account: this.props.evolusConfigData.config.evolus_account, evolus_email: this.props.evolusConfigData.config.evolus_email })
      }
    }
  }

  isLoading = () => {
    return [
      this.props.evolusConfigData.status,
      this.props.evolusFacilitiesData.status,
      this.props.saveEvolusConfigData.status,
    ].includes('loading');
  }

  areFacilitiesLoaded = () => {
    return this.props.evolusFacilitiesData.status === 'success';
  }

  isEnabled = () => {
    return this.props.evolusConfigData.config ? this.props.evolusConfigData.config.is_active : false;
  }

  authorizedOn() {
    if (!this.props.evolusConfigData.config || !this.props.evolusConfigData.config.updated_at) {
      return null;
    }
    return <p className="AuthorizedSync">
      <i className="fa fa-check-circle"></i>
      Authorized on {showFormattedDate(this.props.evolusConfigData.config.updated_at, true)}
    </p>
  }

  handleContinue = () => {
    this.props.getEvolusFacilities({
      evolus_account: this.state.evolus_account,
      evolus_email: this.state.evolus_email,
    });
  };

  handleSave = () => {
    this.props.saveEvolusConfig({
      evolus_account: this.state.evolus_account,
      evolus_email: this.state.evolus_email,
      clinics: [...this.state.clinicMap.entries()].map(([id, facilityId]) => ({
        id,
        facilityId
      }))
    })
  }

  handleToggleEditView = () => {
    this.setState({ showEditView: !this.state.showEditView });
  };

  handleToggleEnabled = () => {
    if (!this.isEnabled()) {
      this.setState({ showEditView: true });
    } else {
      this.props.saveEvolusConfig({
        is_active: false,
        evolus_account: this.state.evolus_account,
        evolus_email: this.state.evolus_email,
        clinics: [{id: 1, facilityId: "F-621121"}]
      })
    }
  }

  handleAccountChange = (e) => {
    this.setState({ evolus_account: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ evolus_email: e.target.value });
  };

  cancel = () => {
    this.handleToggleEditView();
  };

  clinics = () => {
    if (!this.areFacilitiesLoaded()) {
      return [];
    }

    return this.props.evolusFacilitiesData.evolusFacilities.clinics;
  }

  facilities = () => {
    if (!this.areFacilitiesLoaded()) {
      return [];
    }

    return this.props.evolusFacilitiesData.evolusFacilities.evolus.data.facilities
  }

  handleMapFacility = (clinic, facilityId) => {
    let clinicMap = new Map(this.state.clinicMap);
    if (facilityId === "none") {
      clinicMap.delete(clinic.id);
    } else {
      clinicMap.set(clinic.id, facilityId);
    }
    this.setState({ clinicMap })
  }

  connectedView = () => {
    return <div className="membershipSectionContent p5">
      <div className="row">
        <div className="col-xs-12 m-t-20 m-b-15">
          <div className="col-lg-6 col-xs-12">
            {this.authorizedOn()}
            <p className="p-text m-t-15">If you would like to change the settings, please
              click the update button bellow.</p>
            <button className="new-blue-btn pull-left m-t-15 m-b-10 m-l-0"
                    onClick={this.handleToggleEditView}>Update Evolus Settings
            </button>
          </div>
        </div>
      </div>
    </div>;
  };

  editView = () => {
    const isContinueDisabled = !this.state.evolus_account;
    return <>
      <div className="membershipSectionContent p5">
        <div className="row">
          <div className="col-xs-12 m-t-20 m-b-15">
            <div className="col-lg-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Evolus Account<span className="setting-require">*</span></div>
                <div className="setting-input-outer">
                  <input
                    name="evolusAccount"
                    className="newInputField"
                    type="text"
                    value={this.state.evolus_account}
                    autoComplete="off"
                    onChange={this.handleAccountChange}
                  />
                </div>
              </div>

              <div className="newInputFileldOuter">
                <div className="newInputLabel">Evolus E-mail<span className="setting-require">*</span></div>
                <div className="setting-input-outer">
                  <input
                    name="email"
                    className="newInputField"
                    type="text"
                    value={this.state.evolus_email}
                    autoComplete="off"
                    onChange={this.handleEmailChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.areFacilitiesLoaded() ?
          <div className="row">
            <div className="col-sm-10">
              <div className="table-responsive">
                <table className="records-table">
                  <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-3">Clinic</th>
                    <th className="col-xs-6">Facility</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.clinics().map((clinic) => <tr key={clinic.clinic_name}>
                    <td>{clinic.clinic_name}</td>
                    <td>
                      <div className = 'newInputFileldOuter'>
                        <select name={"type-" + clinic.clinic_name}
                                className="newSelectField"
                                onChange={(e) => this.handleMapFacility(clinic, e.target.value)}
                                value={clinic.evolus_facility_id}>
                          <option value="none">None</option>
                          {this.facilities().map(f => <option key={f.id} value={f.facilityId}>{f.name}</option>)}
                        </select>
                      </div>
                    </td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div> : null}
      </div>
      <div className="footer-static">
        <button className="new-blue-btn pull-right"
                onClick={this.areFacilitiesLoaded() ? this.handleSave : this.handleContinue}
                disabled={isContinueDisabled}>{this.areFacilitiesLoaded() ? this.globalLang.label_save : this.globalLang.label_continue}</button>
        <button className="new-white-btn pull-right"
                onClick={this.cancel}>{this.globalLang.label_cancel}</button>
      </div>
    </>;
  };

  render () {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar/>
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">Evolus Integration
                <div className="memberRightActions">
                  <span className="switch-text"/>
                  <label className="setting-switch pull-right">
                    <input type="checkbox" name="is_active" id="is_active"
                           checked={this.isEnabled() || this.state.showEditView}
                           onChange={this.handleToggleEnabled}/>
                    <span className="setting-slider"/>
                  </label>
                </div>
              </div>
            </div>
            {this.state.showEditView || this.isEnabled() ?
              <div className="row">
                  <div className="col-sm-12">
                    <div className="setting-setion">
                      <div className="membershipSectionTitle">Evolus Settings</div>
                      <div className="wallet-edit-block">
                        {this.state.showEditView ? this.editView() : this.isEnabled() ? this.connectedView() : null}
                      </div>
                    </div>
                  </div>
              </div> : null}
          </div>
          <Loader showLoader={this.isLoading()} isFullWidth={true}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    evolusConfigData: state.EvolusIntegrationReducer.evolusConfigData,
    evolusFacilitiesData: state.EvolusIntegrationReducer.evolusFacilitiesData,
    saveEvolusConfigData: state.EvolusIntegrationReducer.saveEvolusConfigData,
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getEvolusConfig,
    saveEvolusConfig,
    getEvolusFacilities,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EvolusIntegration);
