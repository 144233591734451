import * as yup from "yup";
import { unwrapOr } from "../utilities/general";

export const paginationSchema = yup.object({
  currentPage: yup.number().required(),
  total: yup.number().required(),
  totalPages: yup.number().required(),
  hasNextPage: yup.bool().required(),
  nextPage: yup.string(),
});

/**
 * @param {{
 *   response: {
 *     current_page: number;
 *     total: number;
 *     next_page_url: string | null;
 *     has_next_page: boolean | undefined;
 *     next_page: string | undefined;
 *   },
 *   pageSize: number;
 * }} param0
 * @returns {{
 *   currentPage: number;
 *   total: number;
 *   totalPages: number;
 *   hasNextPage: boolean;
 *   nextPage: string | undefined;
 * }}
 */
export const composePaginationSchema = ({ response, pageSize }) => {
  const currentPage = unwrapOr(() => response.current_page, 1);
  const total = unwrapOr(() => response.total, 0);

  if (typeof total !== "number" || typeof pageSize !== "number") {
    throw new Error("total and pageSize should be a number type");
  }

  const totalPages = Math.ceil(total / pageSize);
  const nextPageUrl = unwrapOr(() => response.next_page_url, null);

  return {
    currentPage,
    total,
    totalPages,
    hasNextPage: Boolean(nextPageUrl) || !!response.has_next_page,
    nextPage: response.next_page,
  };
};

/**
 * @param {{
 *   currentPage: number;
 *   hasNextPage: boolean;
 * }} lastPage
 * @returns {number | undefined}
 */
export const getNextPaginationPage = (lastPage) => {
  const currentPage = lastPage.currentPage;
  if (lastPage.hasNextPage && typeof currentPage === "number") {
    return currentPage + 1;
  }
};

/**
 * @param {{
 *   currentPage: number;
 *   hasNextPage: boolean;
 *   nextPage: string | undefined;
 * }} lastPage
 * @returns {{
 *   page: number
 *   next_page: number | undefined
 * } | undefined}
 */
export const getNextPageParam = (lastPage) => {
  const currentPage = lastPage.currentPage;
  if (lastPage.hasNextPage && typeof currentPage === "number") {
    return {
      page: currentPage + 1,
      next_page: lastPage.nextPage,
    };
  }
};
