import React, { useMemo } from "react";
import moment from "moment";
import classes from "./sass/drawdaystepper.module.scss";
import cx from "clsx";
import { numberFormat, showFormattedDate } from "../../Utils";
import Tooltip from "react-tooltip";

const DrawDayStepper = ({ idx, item, timelineData, size = "small" }) => {
  const rootClasses = cx(classes.stepperContainer, {
    [classes.stepperLarge]: size === "large",
  });

  const interval = (idx) => {
    if (idx === 0) {
      return { shownIndex: 1, monthsInterval: 0 };
    }

    const firstDate = timelineData?.[0]?.payment_date;
    const prevDate = moment(timelineData?.[idx - 1]?.payment_date);
    const currentDate = moment(timelineData?.[idx]?.payment_date);

    const shownIndex = currentDate.diff(firstDate, "months") + 1;
    const monthsInterval = currentDate.diff(prevDate, "months");

    return { shownIndex, monthsInterval };
  };

  const isShowDashline = useMemo(
    () => Boolean(interval(idx).monthsInterval > 1),
    [idx, timelineData],
  );

  return (
    <div className={rootClasses}>
      <div
        className={cx(
          classes.stepperLine,
          isShowDashline ? classes.stepperLastLine : classes.stepperLineSolid,
        )}
      >
        {showFormattedDate(item.payment_date)}
        <div className={classes.stepperCircle}>{interval(idx).shownIndex}</div>
      </div>

      <div className={classes.stepperContent}>
        {Boolean(item.one_time_setup) && (
          <div>
            <div>One Time Setup Fee </div>{" "}
            <div>{numberFormat(item.one_time_setup, "currency", 2)}</div>
          </div>
        )}
        {Boolean(item.membership_fee) && (
          <div>
            <div>Membership Fee </div>{" "}
            <div>{numberFormat(item.membership_fee, "currency", 2)}</div>
          </div>
        )}
        {Boolean(item.discount) && (
          <div>
            <div>Discount </div>{" "}
            <div>{numberFormat(item.discount, "currency", 2)}</div>
          </div>
        )}

        <div className={classes.stepperContentTotal}>
          <div>Total</div> <div>{numberFormat(item.total, "currency", 2)}</div>
        </div>
        {Boolean(item.pro_rated_days) && (
          <div>
            <div
              className={cx(classes.stepperCircle, classes.stepperCircleInfo)}
              data-tip
              data-for="propratedMessage"
            >
              i
            </div>
            <Tooltip
              id="propratedMessage"
              aria-haspopup="true"
              border
              place="right"
              type="light"
              effect="solid"
            >
              The prorated amount is always calculated on a 30 days basis.
            </Tooltip>
            <div>
              <i>
                Prorated fee for {item.pro_rated_days} day
                {item.pro_rated_days > 1 ? "s" : ""} of membership.{" "}
              </i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DrawDayStepper;
