import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useNetSales = (params, options = {}) => {
  return useQuery(
    ["netSalesReport", params],
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getBiNetSales(), {
        params,
      });

      const composeResponse = {
        clinics: res.data.data.clinics,
        clinicNetSales: res.data.data.clinic_net_sales,
        providerNetSales: res.data.data.provider_net_sales,
      };
      return composeResponse;
    },
    options,
  );
};
