import { useMemo } from "react";
import { SURVEYS_PER_PAGE } from "../SettingsDeletedSurveys.consts";
import { useDeletedSurveysQuery } from "../../../../api/queries/useDeletedSurveysQuery";
import { useDebouncedState } from "../../../../utilities/hooks/useDebouncedState";

export function useDeletedSurveys() {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState("");

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useDeletedSurveysQuery({
    payload: {
      pageSize: SURVEYS_PER_PAGE,
      searchTerm: debouncedSearchTerm,
    },
    options: {
      staleTime: 0,
    },
  });

  const surveys = useMemo(() => {
    if (data && data.pages) {
      return data.pages
        .map((p) => p.surveys)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data]);

  return {
    surveys,
    isSurveysLoading: isLoading,
    isNextSurveysFetching: isFetchingNextPage,
    fetchNextSurveys: fetchNextPage,
    hasMoreSurveys: hasNextPage,
    refetchSurveys: refetch,
    searchTerm,
    setSearchTerm,
  };
}
