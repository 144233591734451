import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

export const LEADAR_CHAT_MESSAGE_TYPE = {
  message: "message",
  reply: "reply",
};

const requestSchema = yup.object({
  id: yup.number().required(),
});

const responseSchema = yup.object({
  messages: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        date: yup.string().nullable(),
        type: yup.string().nullable(),
        body: yup.string().required(),
        author: yup
          .object({
            id: yup.number().nullable(),
            fullName: yup.string().nullable(),
            imageUrl: yup.string().nullable(),
          })
          .required(),
      }),
    )
    .required(),

  patient: yup
    .object({
      id: yup.number().required(),
      fullName: yup.string().required(),
      email: yup.string().nullable(),
      phone: yup.string().nullable(),
      location: yup.string().nullable(),
      dateOfBirth: yup.string().nullable(),
      imageUrl: yup.string().nullable(),
      emergencyName: yup.string().nullable(),
      emergencyNumber: yup.string().nullable(),
      lastVisit: yup.string().nullable(),
      isMonthlyMembership: yup.bool().required(),
      totalSaleRelationship: yup.number().required(),
    })
    .required(),
});

const composeResponse = (res) => {
  const messages = unwrapOr(() => res.data.data.all_notifications, []);
  const patient = unwrapOr(() => res.data.data.notification.patient, {});

  return {
    messages: messages.map((m) => ({
      id: m.id,
      date: m.date_added || null,
      type: m.message_type || null,
      body: m.message || "",
      author: {
        id: m.patient?.id,
        fullName: m.patient?.full_name,
        imageUrl: m.patient?.user_image_url || null,
      },
    })),

    patient: {
      id: patient.id,

      fullName: patient.full_name,

      email:
        [
          patient.email,
          patient.email_2,
          patient.email_3,
          patient.email_4,
        ].filter(Boolean)?.[0] || null,

      phone:
        [
          patient.phoneNumber,
          patient.phoneNumber_2,
          patient.phoneNumber_3,
          patient.phoneNumber_4,
        ].filter(Boolean)?.[0] || null,

      location:
        [patient.address_line_1, patient.city, patient.state]
          .filter(Boolean)
          .join(", ") || null,

      dateOfBirth: patient.date_of_birth || null,

      imageUrl: patient.user_image || null,

      emergencyName: patient.emergency_contact_name || null,

      emergencyNumber: patient.emergency_contact_number || null,

      lastVisit:
        patient.last_appointment?.length > 0
          ? patient.last_appointment[0]?.appointment_datetime || null
          : null,

      isMonthlyMembership: Boolean(patient?.is_monthly_membership),

      totalSaleRelationship: patient?.total_sale_relationship || 0,
    },
  };
};

function createLeadArInboxChatQueryKey({ id }) {
  return [QUERY_KEYS.leadArInboxChat, id];
}

export function useLeadArInboxChatQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useQuery(
    createLeadArInboxChatQueryKey({
      id: req.id,
    }),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getLeadArInboxChat(), {
        params: {
          id: req.id,
        },
      });
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
