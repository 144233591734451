import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const CustomDatePickerHeader = ({ date, decreaseMonth, increaseMonth }) => (
  <div>
    <button
      onClick={decreaseMonth}
      className="react-datepicker__navigation react-datepicker__navigation--previous"
    >
      Prev Month
    </button>
    <div className="react-datepicker__current-month">
      {moment(date).format("MMMM YYYY")}
    </div>
    <button
      onClick={increaseMonth}
      className="react-datepicker__navigation react-datepicker__navigation--next"
    >
      Next Month
    </button>
  </div>
);

CustomDatePickerHeader.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
};

export default CustomDatePickerHeader;
