import React from "react";
import moment from "moment";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { useBanners } from "../../hooks/useBanners";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { DEFAULT_DATE_FORMAT } from "../../../../../../consts/general";
import styles from "./styles.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import { EditIcon } from "../../../../../../assets/Icons/EditIcon";
import { DeleteIcon } from "../../../../../../assets/Icons/DeleteIcon";
import { StatusToggle } from "./shared/StatusToggle/StatusToggle";

export function Table({ onEdit, onDelete }) {
  const { tSmartConfig: t } = useAppTranslation.SmartConfig();
  const { data: user } = useCurrentUserQuery();
  const [conflicts, setConflicts] = React.useState(null);

  const { banners, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useBanners({
      onError: () => {
        uiNotification.error(t("patientPortal.banner.error.fetchAll"));
      },
    });

  const isEmpty = banners.length === 0 && !isLoading;
  const isVisible = !isLoading && !isEmpty;

  const data = banners.map((b) => ({
    key: b.id,
    name: b.name,
    startDate: moment(b.start_date).format(
      user?.previewDateFormat || DEFAULT_DATE_FORMAT,
    ),
    endDate: moment(b.end_date).format(
      user?.previewDateFormat || DEFAULT_DATE_FORMAT,
    ),
    status: (
      <StatusToggle
        id={b.id}
        status={b.status}
        conflicts={conflicts}
        setConflicts={setConflicts}
      />
    ),
    actions: (
      <div className={styles.actions}>
        <Button size="tiny" variant="outlined" onClick={() => onEdit(b.id)}>
          <EditIcon width="16px" />
        </Button>
        <Button
          size="tiny"
          color="error"
          variant="outlined"
          onClick={() => onDelete(b.id)}
        >
          <DeleteIcon width="16px" />
        </Button>
      </div>
    ),
  }));

  return (
    <div className={styles.root}>
      {isLoading && <Skeleton count={15} height={40.5} borderRadius="0px" />}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <>
          <SharedTable
            bgColor="white"
            data={data}
            cols={[
              {
                data: t("patientPortal.banner.table.name"),
                accessor: "name",
                headColClassName: styles.col,
              },
              {
                data: t("patientPortal.banner.table.startDate"),
                accessor: "startDate",
              },
              {
                data: t("patientPortal.banner.table.endDate"),
                accessor: "endDate",
              },
              {
                data: t("patientPortal.banner.table.status"),
                accessor: "status",
              },
              {
                data: t("patientPortal.banner.table.actions"),
                accessor: "actions",
              },
            ]}
          />
          {hasNextPage && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={fetchNextPage}
              isLoading={isFetchingNextPage}
            />
          )}
        </>
      )}
    </div>
  );
}
