import React from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { ReportHeader } from "../../../../../components/ReportHeader/ReportHeader";
import { Button } from "../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import { svg } from "../../../../../../../assets/svg";

export const Header = ({ downloadExcel, isReportsLoading }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();

  return (
    <ReportHeader
      withBackBtn
      media="small"
      label={tBi("shortTermLiabilityReport.label")}
    >
      <Button
        size="small"
        variant="outlined"
        onClick={downloadExcel.initiate}
        isDisabled={downloadExcel.isLoading || isReportsLoading}
        leftAdornment={
          downloadExcel.isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <img src={svg.downloadPrimary} alt="dl" width="20px" />
          )
        }
      >
        {tCommon("label.downloadExcel")}
      </Button>
    </ReportHeader>
  );
};
