import React, { useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { useNetSales } from "./hooks/useNetSales";
import Header from "../../../components/Header";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import { Box } from "../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

const modalInfo = {
  title: "netSalesReport.info.title",
  rows: [
    "netSalesReport.info.clinicNetSales",
    "netSalesReport.info.employeeNetSales",
  ],
};

const NetSales = () => {
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };
  const { data, isLoading } = useNetSales(requestParams);

  const isClinicDataEmpty = !isLoading && !data?.clinicNetSales?.length;
  const isProviderDataEmpty = !isLoading && !data?.providerNetSales?.length;

  const clinicData = useMemo(
    () =>
      data?.clinicNetSales.map((clinic) => ({
        clinicName: clinic.clinic_name,
        netSales: clinic.gross_sales,
      })),
    [data],
  );

  const providerData = useMemo(() =>
    data?.providerNetSales.map(
      (provider) => ({
        providerName: provider.provider,
        providerTotalSales: provider.gross_sales,
      }),
      [data],
    ),
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Net Sales"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        downloadRoute={HTTP_ENDPOINTS.getBiNetSales()}
        requestParams={requestParams}
        modalInfo={modalInfo}
      />
      <div className="flex gap-16">
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Clinic Name", accessor: "clinicName" },
              { data: "Net Sales", accessor: "netSales" },
            ]}
            data={clinicData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isClinicDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: "Provider Name", accessor: "providerName" },
              { data: "Net Sales", accessor: "providerTotalSales" },
            ]}
            data={providerData}
          />
          {isLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {isProviderDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default NetSales;
