import { http } from "../../services/HttpService";

export function getLeadsSources() {
  return (dispatch) => {
    dispatch({ type: "GET_LEADS_SOURCES_LOADING" });
    http
      .get("get-leads-sources")
      .then((response) => {
        dispatch({
          type: "GET_LEADS_SOURCES_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({ type: "GET_LEADS_SOURCES_ERROR", payload: e.response.data });
      });
  };
}

export function createLeadsApiKey(formData) {
  return (dispatch) => {
    dispatch({ type: "CREATE_LEADS_API_KEY_LOADING" });
    http
      .post("create-leads-api-key", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_LEADS_API_KEY_SUCCESS",
          payload: response.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "CREATE_LEADS_API_KEY_ERROR",
          payload: e.response.data,
        });
      });
  };
}

export function getRegisteredApiKeys() {
  return (dispatch) => {
    dispatch({ type: "GET_REGISTERED_KEYS_LOADING" });
    http
      .get("get-registered-api-keys")
      .then((response) => {
        dispatch({
          type: "GET_REGISTERED_KEYS_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "GET_REGISTERED_KEYS_ERROR",
          payload: e.response.data,
        });
      });
  };
}

export function deleteLeadsApiKey(formData) {
  return (dispatch) => {
    dispatch({ type: "DELETE_LEADS_API_KEY_LOADING" });
    http
      .post("delete-leads-api-key", formData)
      .then((response) => {
        dispatch({
          type: "DELETE_LEADS_API_KEY_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "DELETE_LEADS_API_KEY_ERROR",
          payload: e.response.data,
        });
      });
  };
}
