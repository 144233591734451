import { useEfaxTemplateQuery } from "../../../../../../../api/efax/useEfaxTemplateQuery";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { getPathParams } from "../../../utilities";

export const useTemplate = () => {
  const { templateId } = getPathParams();
  const { tSettings } = useAppTranslation.Settings();

  const { data, isFetching } = useEfaxTemplateQuery(
    {
      id: templateId,
    },
    {
      enabled: !!templateId,
      onError: () => {
        uiNotification.error(tSettings("efaxTemplates.error.fetchOne"));
      },
    },
  );

  return {
    data,
    isLoading: isFetching,
  };
};
