import React from "react";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { BookmarkXIcon } from "../../../../assets/Icons/BookmarkXIcon";
import { BookmarkCheckIcon } from "../../../../assets/Icons/BookmarkCheckIcon";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

export function OnFileButton({ onClick, isLoading, isMarked }) {
  const { tCommon: t } = useAppTranslation.Common();

  return (
    <button onClick={onClick} disabled={isLoading}>
      {isLoading ? (
        <CircularProgress size="tiny" color="gray1" />
      ) : (
        <>
          {isMarked ? (
            <BookmarkXIcon width="16px" />
          ) : (
            <BookmarkCheckIcon width="16px" />
          )}
        </>
      )}
      {isMarked ? t("label.unmarkOnFile") : t("label.markOnFile")}
    </button>
  );
}
