import React, { useMemo } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import styles from "../EfaxTemplateCreateEdit/boxes/TabManual/styles.module.scss";
import { Box } from "../../../../shared/Box/Box";
import { history } from "../../../../history";
import { ROUTES } from "../../../../consts/routes";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../shared/Input/Input";
import { InputError } from "../../../../shared/InputError/InputError";
import { Button } from "../../../../shared/Button/Button";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { useEfaxTemplatesQuery } from "../../../../api/efax/useEfaxTemplatesQuery";
import { Select } from "../../../../shared/Select/Select";
import { useCreateFavorites } from "./hooks/useCreateFavorites";
import { InputPhone } from "../../../../shared/InputPhone/InputPhone";
import { TABS } from "../EfaxTemplates/config";
import { getPathParams } from "./utilities";
import { useFavorites } from "../EfaxTemplates/hooks/useFavorites";
import { useEditFavorites } from "./hooks/useEditFavorites";

const EfaxFavoriteCreateEdit = () => {
  const { favoriteId } = getPathParams();
  const { tSettings } = useAppTranslation.Settings();

  const { data } = useEfaxTemplatesQuery();
  const { initiate: createFavorite, isLoading: isCreatingFavorite } =
    useCreateFavorites();
  const { initiate: editFavorite, isLoading: isEditingFavorite } =
    useEditFavorites();

  const isLoading = isCreatingFavorite || isEditingFavorite;

  const favorites = useFavorites();

  const defaultTemplatesOptions = useMemo(() => {
    return (
      data?.templates?.map((t) => ({
        label: t.name,
        value: t.id,
        content: t.content,
      })) || []
    );
  }, [data]);

  const selectedFavorite = useMemo(() => {
    if (favoriteId) {
      return favorites?.value?.find((f) => f.id === favoriteId);
    }
    return null;
  }, [favorites, favoriteId]);

  const selectedTemplate = useMemo(() => {
    return defaultTemplatesOptions?.find(
      (t) => t.value === selectedFavorite?.templateId,
    );
  }, [defaultTemplatesOptions, favorites]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(tSettings("efaxTemplates.formError.nameRequired")),
    faxNumber: yup
      .string()
      .required(tSettings("efaxTemplates.formError.numberRequired")),
    template: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable(),
  });

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      name: selectedFavorite?.name || "",
      faxNumber: selectedFavorite?.faxNumber || "",
      template: selectedTemplate || null,
    },
    onSubmit: (values) => {
      if (favoriteId) {
        editFavorite({
          id: favoriteId,
          name: values.name,
          faxNumber: values.faxNumber,
          templateId: values.template.value,
        }).then(() =>
          history.push(ROUTES.settings.efaxTemplates(TABS.favorites)),
        );
        return;
      }
      createFavorite({ ...values, templateId: values.template?.value }).then(
        () => history.push(ROUTES.settings.efaxTemplates(TABS.favorites)),
      );
    },
  });

  const getTitle = () => {
    if (favoriteId) {
      return tSettings("efaxTemplates.editFavorite");
    }

    return tSettings("efaxTemplates.createFavorite");
  };

  return (
    <LayoutSettings contentClassName={styles.root}>
      <EntityHeader
        withBackBtn
        onBack={() =>
          history.push(ROUTES.settings.efaxTemplates(TABS.favorites))
        }
        title={getTitle()}
      />
      <Box>
        <div className={styles.root}>
          <div>
            <InputLabel>{tSettings("efaxTemplates.form.name")}</InputLabel>
            <Input
              value={values.name}
              onChange={(event) => setFieldValue("name", event.target.value)}
              isError={Boolean(errors.name)}
            ></Input>
            {Boolean(errors.name) && <InputError>{errors.name}</InputError>}
          </div>
          <div>
            <InputLabel>{tSettings("efaxTemplates.form.number")}</InputLabel>
            <InputPhone
              fullWidth
              isDisabled={isCreatingFavorite}
              value={values.faxNumber}
              onChange={(value) => setFieldValue("faxNumber", value)}
              isError={Boolean(errors.faxNumber)}
            />
            {Boolean(errors.faxNumber) && (
              <InputError>{errors.faxNumber}</InputError>
            )}
          </div>
          <div>
            <InputLabel>
              {tSettings("efaxTemplates.form.setDefaultTemplate")}
            </InputLabel>
            <Select
              options={defaultTemplatesOptions}
              value={values.template}
              onChange={(option) => setFieldValue("template", option)}
            />
          </div>
          <div>
            <Button
              onClick={handleSubmit}
              isDisabled={isLoading}
              leftAdornment={
                isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              {favoriteId
                ? tSettings("efaxTemplates.form.updateSubmit")
                : tSettings("efaxTemplates.form.createSubmit")}
            </Button>
          </div>
        </div>
      </Box>
    </LayoutSettings>
  );
};

export default EfaxFavoriteCreateEdit;
