import * as yup from "yup";
import { ERRORS, VALUE_MAX_LEN } from "../StatementDescriptor.consts";
import { useFormik } from "formik";

const schema = yup.object({
  descriptor: yup
    .string()
    .max(VALUE_MAX_LEN, ERRORS.maxLen.message)
    .test({
      name: "noSpaces",
      message: ERRORS.noSpaces.message,
      test: function(value) {
        return !/\s+/g.test(value);
      },
    })
    .test({
      name: "notAllowedSymbols",
      message: ERRORS.notAllowedSymbols.message,
      test: function(value) {
        return !/[^A-Za-z0-9-]/g.test(value);
      },
    })
    .required(ERRORS.required.message),
});

export function useForm({ initialValues, onSubmit }) {
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    dirty,
  } = useFormik({
    onSubmit,
    enableReinitialize: true,
    validationSchema: schema,
    initialValues,
  });

  return {
    form: values,
    handleSubmit,
    handleChange,
    isFormDirty: dirty,
    resetForm,
    errors,
  };
}
