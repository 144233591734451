import React from "react";
import { Box } from "../../../../../../shared/Box/Box";
import parentClasses from "../../Qualiphy.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { useQualiphyEnrolledClinicsQuery } from "../../../../../../api/qualiphy/useQualiphyEnrolledClinicsQuery";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { Table } from "../../../../../../shared/Table/Table";
import classes from "./EnrolledClinics.module.scss";
import { RemoveClinic } from "./RemoveClinic";

export function EnrolledClinics() {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();
  const enrolledClinicsQuery = useQualiphyEnrolledClinicsQuery();

  const data = enrolledClinicsQuery.data?.map((clinic) => {
    return {
      key: clinic.id,
      clinicName: clinic.name,
      actions: <RemoveClinic id={clinic.id} name={clinic.name} />,
    };
  });

  return (
    <Box className={parentClasses.box}>
      <div className={parentClasses.boxTitle}>
        {tSettings("qualiphyIntegration.enrolledClinics.title")}
      </div>
      <div className={classes.tableWrap}>
        {enrolledClinicsQuery.isLoading && (
          <Skeleton count={5} height={56.5} borderRadius="0px" />
        )}
        {!enrolledClinicsQuery.isLoading &&
          enrolledClinicsQuery.data?.length > 0 && (
            <Table
              data={data}
              cols={[
                {
                  data: tCommon("label.clinicName"),
                  accessor: "clinicName",
                },
                {
                  data: tCommon("label.actions"),
                  accessor: "actions",
                  headColClassName: classes.actionsCol,
                },
              ]}
            />
          )}
        {!enrolledClinicsQuery.isLoading &&
          enrolledClinicsQuery.data?.length === 0 && (
            <Empty position="center" />
          )}
      </div>
    </Box>
  );
}
