import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { settingRecordSchema } from "./schemas";
import { SETTING_BMI_NULL } from "./config";

const responseSchema = yup.array().of(settingRecordSchema);

const composeResponse = (res) => {
  return (
    res.data?.data?.map((i) => ({
      ...i,
      end_value: i.end_value === SETTING_BMI_NULL ? null : i.end_value,
    })) || []
  );
};

function createPatientVitalsSettingRangesQueryKey() {
  return [QUERY_KEYS.patientVitals.settingRanges];
}

export function usePatientVitalsSettingRangesQuery({ options = {} } = {}) {
  return useQuery(
    createPatientVitalsSettingRangesQueryKey(),
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.patientVitals.getSettingRanges(),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
