import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../shared/Select/Select";
import { ReportModalFilter } from "../../../../components/ReportModalFilter/ReportModalFilter";
import { selectOptionSchema } from "../../../../../../shared/Select/Select.schemas";
import { hasLength } from "../../../../../../utilities/general";
import { useClinicsQuery } from "../../../../../../api/queries/useClinicsQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

const schema = yup.object({
  clinics: yup.array().of(selectOptionSchema),
});

const initialValues = {
  clinics: [],
};

export function ModalFilter({ isOpen, onClose, filter }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: clinics, isLoading: isClinicsLoading } = useClinicsQuery();

  const handleApply = (values) => {
    filter.update(values);
    onClose();
  };

  const { values, setFieldValue, dirty, handleSubmit, setValues } = useFormik({
    onSubmit: handleApply,
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...filter.value,
    },
  });

  const clinicOptions = (clinics || []).map((clinic) => ({
    label: clinic.name,
    value: clinic.id,
  }));

  return (
    <ReportModalFilter
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitDisabled={!dirty}
      isFilterTouched={Object.values(values).some(hasLength)}
      onReset={() => setValues(initialValues)}
    >
      <div>
        <InputLabel>{tCommon("label.clinics")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.clinics}
          onChange={(options) => setFieldValue("clinics", options)}
          options={clinicOptions}
          isLoading={isClinicsLoading}
        />
      </div>
    </ReportModalFilter>
  );
}

ModalFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    value: PropTypes.object,
    update: PropTypes.func,
  }).isRequired,
};
