import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tabs } from "../../../../../../shared/Tabs/Tabs";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { CARD_FORM_TYPES } from "../ViewChargeCard.consts";
import FormNewCard from "./Forms/FormNewCard";
import FormCardOnFile from "./Forms/FormCardOnFile";
import FormSwipeCard from "./Forms/FormSwipeCard";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { checkoutInvoice } from "../../../../../../store/checkoutInvoice";

function Clearent({ isInvoiceWatchMode }) {
  const { tSales } = useAppTranslation.Sales();
  const [formType, setFormType] = useState(CARD_FORM_TYPES.new);
  const { invoice } = useInvoice();

  const formByType = {
    [CARD_FORM_TYPES.new]: <FormNewCard />,
    [CARD_FORM_TYPES.onFile]: <FormCardOnFile />,
    [CARD_FORM_TYPES.swipe]: <FormSwipeCard />,
  };

  return (
    <ModalCharge.Content>
      <Tabs
        variant="tiled"
        active={formType}
        isDisabled={isInvoiceWatchMode}
        options={[
          {
            label: tSales("checkoutInvoice.cardFormLabel.new"),
            value: CARD_FORM_TYPES.new,
            onClick: () => setFormType(CARD_FORM_TYPES.new),
          },
          ...(invoice?.patient?.cardOnFiles?.length > 0
            ? [
                {
                  label: tSales("checkoutInvoice.cardFormLabel.onFile"),
                  value: CARD_FORM_TYPES.onFile,
                  onClick: () => setFormType(CARD_FORM_TYPES.onFile),
                },
              ]
            : []),
          {
            label: tSales("checkoutInvoice.cardFormLabel.swipe"),
            value: CARD_FORM_TYPES.swipe,
            onClick: () => setFormType(CARD_FORM_TYPES.swipe),
          },
        ]}
      />
      {formByType[formType]}
    </ModalCharge.Content>
  );
}

Clearent.propTypes = {
  isInvoiceWatchMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isInvoiceWatchMode: checkoutInvoice.selectors.selectIsInvoiceWatchMode(state),
});

export default connect(mapStateToProps)(Clearent);
