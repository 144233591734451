import React from "react";
import PropTypes from "prop-types";
import { EntityRow } from "../EntityRow";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";

const CommonAdditionally = ({ isEnterCreditCard, onEnterCreditCardChange }) => (
  <EntityRow label="Additionally">
    <Checkbox
      name="card_data"
      isChecked={isEnterCreditCard}
      onChange={() => onEnterCreditCardChange(!isEnterCreditCard)}
      label="Enter Credit Card Details"
    />
  </EntityRow>
);

CommonAdditionally.propTypes = {
  isEnterCreditCard: PropTypes.bool.isRequired,
  onEnterCreditCardChange: PropTypes.func.isRequired,
};

export default CommonAdditionally;
