const serviceInitialState = {
  action: "",
};

const ServiceReducer = (state = serviceInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        action: "RESET_ALL",
      };
    }
    case "APPOINTMENT_SURVEY_UPDATE":
      return {
        ...state,
        data: action.payload,
        action: "APPOINTMENT_SURVEY_UPDATE",
      };
    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    default:
      return state;
  }
};

export default ServiceReducer;
