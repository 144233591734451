import React from "react";
import PropTypes from "prop-types";
import { ServicesPickerModal } from "../../boxes/ServicesPicker/ServicesPicker";
import { uiNotification } from "../../services/UINotificationService";
import {
  ASSIGN_TO_SERVICES_MODEL_TYPE,
  useAssignServiceToModelMutation,
} from "../../api/mutations/useAssignServiceToModelMutation";
import { useApiServicesByCategory } from "./hooks/useApiServicesByCategory";
import { useAppTranslation } from "../../i18n/useAppTranslation";

export function ModalAssignServices({
  id,
  modelType,
  servicesType,
  pickedIds,
  isOpen,
  onClose,
  onSuccess,
  title,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { servicesByCategory, isLoading: isServicesLoading } =
    useApiServicesByCategory({ servicesType });

  const { mutate, isLoading } = useAssignServiceToModelMutation({
    onSuccess: () => {
      onSuccess();
      onClose();
      uiNotification.success(tCommon("success.servicesAssign"));
    },
    onError: () => {
      uiNotification.error(tCommon("error.servicesAssign"));
    },
  });

  const handleUpdate = (services) => {
    if (id) {
      mutate({
        modelId: Number(id),
        modelType: modelType,
        serviceIds: services.map((s) => s.id),
      });
    }
  };

  return (
    <ServicesPickerModal
      pickedIds={pickedIds}
      isOpen={isOpen}
      onClose={onClose}
      onPick={handleUpdate}
      title={title}
      pickTitle={tCommon("label.assign")}
      isPickLoading={isLoading}
      servicesByCategory={servicesByCategory}
      isServicesLoading={isServicesLoading}
    />
  );
}

ModalAssignServices.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  modelType: PropTypes.oneOf(Object.values(ASSIGN_TO_SERVICES_MODEL_TYPE))
    .isRequired,
  pickedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
