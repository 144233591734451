import * as yup from "yup";
import moment from "moment";
import { API_DATE_FORMAT, HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { GRAPH_FIELDS } from "./config";
import { graphNodeSchema } from "./schemas";

const requestSchema = yup.object({
  patientId: yup.number().required(),
  field: yup
    .string()
    .test({
      test: (v) => Object.values(GRAPH_FIELDS).includes(v),
    })
    .required(),
  period: yup
    .object({
      from: yup.date().required(),
      to: yup.date().required(),
    })
    .required(),
});

const responseSchema = yup.array().of(graphNodeSchema);

const composeResponse = (res) => {
  return res.data?.data || [];
};

export const getPatientVitalsGraph = async (payload) => {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = moment(req.period.from).format(API_DATE_FORMAT);
  const toDate = moment(req.period.to).format(API_DATE_FORMAT);

  const res = await http.get(
    HTTP_ENDPOINTS.patientVitals.getGraph({
      patientId: req.patientId,
      field: req.field,
      startDate: fromDate,
      endDate: toDate,
    }),
  );
  return responseSchema.validateSync(composeResponse(res), {
    strict: true,
  });
};
