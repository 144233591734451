import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
} from "../../schemas/pagination";
import { http } from "../../services/HttpService";

export const PROVIDER_AND_MD_TAB_TYPE = {
  provider: "provider",
  md: "md",
};

const composeResponse = (res, req) => {
  const charReports = res?.data?.data?.chart_report_data?.data || [];
  const totalChartCount = res?.data?.data?.total_chart_count || {};
  return {
    ...composePaginationSchema({
      response: res?.data?.data?.chart_report_data || {},
      pageSize: req.pageSize,
    }),
    charReports,
    totalChartCount,
  };
};

export const useProviderAndMdQuery = ({ payload, options = {} }) => {
  const params = {
    pageSize: payload.pageSize,
    report_type: payload.selectedType,
    start_date: payload.startDate,
    end_date: payload.endDate,
    clinic_id: payload.clinic_id,
    status: payload.is_active ? "Active" : "Inactive",
  };
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS.GET_PROVIDER_AND_MD_LIST_DATA, { ...params }],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getChartAuditDataList(), {
        params: {
          page: pageParam,
          ...params,
        },
      });
      return composeResponse(res, { pageSize: params.pageSize });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
};

export function useDownloadChartAuditReportQuery({ options = {} } = {}) {
  return useMutation(async (payload = {}) => {
    const res = await http.post(
      HTTP_ENDPOINTS.downloadChartAuditDataReports(),
      payload,
    );
    return res;
  }, options);
}
