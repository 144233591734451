import React from "react";
import { Modal } from "../../../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./ModalCreateEdit.module.scss";
import { Select } from "../../../../../../../shared/Select/Select";
import { useSettingViews } from "../../../../hooks/useSettingViews";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { DateRangePicker } from "../../../../../../../shared/DateRangePicker/DateRangePicker";
import { Button } from "../../../../../../../shared/Button/Button";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";
import { DEFAULT_DATE_FORMAT } from "../../../../../../../consts/general";
import { useFieldOptions } from "./hooks/useFieldOptions";
import { useForm } from "./hooks/useForm";
import { arrayFrom } from "../../../../../../../utilities/general";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";

/**
 * @param {Object} param0
 * @param {() => void} param0.onClose
 * @param {() => void} param0.onSubmit
 * @param {boolean} param0.isEditMode
 * @param {boolean} param0.isSubmitting
 * @param {{
 *  field: string;
 *  period: { from: Date; to: Date };
 * }?} param0.initialValues
 */
export const ModalCreateEdit = ({
  onClose,
  onSubmit,
  isEditMode,
  isSubmitting,
  initialValues,
}) => {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();

  const settingViews = useSettingViews();
  const { data: user } = useCurrentUserQuery();
  const fieldOptions = useFieldOptions();
  const form = useForm(onSubmit, initialValues);

  return (
    <Modal
      isOpen
      size="small"
      shouldCloseOnOverlayClick={false}
      onClose={onClose}
      contentClassName={classes.root}
      header={
        <Modal.Title>
          {isEditMode
            ? tClients("vitals.graph.editTitle")
            : tClients("vitals.graph.createTitle")}
        </Modal.Title>
      }
    >
      {settingViews.isLoading ? (
        arrayFrom(3).map((k) => <Skeleton key={k} height={50} />)
      ) : (
        <>
          <div>
            <InputLabel>{tClients("vitals.graph.vitalSelectLabel")}</InputLabel>
            <Select
              options={fieldOptions}
              value={form.field.value}
              onChange={form.field.onChange}
              isError={form.field.getIsError()}
            />
          </div>
          <div>
            <InputLabel>{tClients("vitals.graph.dateSelectLabel")}</InputLabel>
            <DateRangePicker
              buttonClassName={classes.datePicker}
              isError={form.period.getIsError()}
              dateFormat={user?.dateFormat || DEFAULT_DATE_FORMAT}
              onChange={({ startDate, endDate }) => {
                form.period.onChange({
                  from: startDate,
                  to: endDate,
                });
              }}
              value={{
                startDate: form.period.value?.from,
                endDate: form.period.value?.to,
              }}
            />
          </div>
          <Button
            onClick={form.submit}
            isDisabled={isSubmitting}
            leftAdornment={
              isSubmitting ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {isEditMode ? tCommon("label.save") : tCommon("label.create")}
          </Button>
        </>
      )}
    </Modal>
  );
};
