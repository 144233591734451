/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import moment from "moment";

const ApiDateFormat = "YYYY-MM-DD";

export default class ReputationReportTitle extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
    };
  }

  showCreateOptions = () => {
    this.props.showCreateOptions();
  };

  saveReport = () => {
    this.props.saveReport();
  };

  viewAllSurveys = () => {
    if (
      this.props.parentState.reportFilter ||
      (this.props.parentState.reportData &&
        this.props.parentState.reportData.report_filter)
    ) {
      let mainData = {};

      if (this.props.parentState.reportData.report_filter) {
        mainData = this.props.parentState.reportData.report_filter;
      } else {
        mainData = this.props.parentState.reportFilter;
      }

      let startDate = mainData
        ? mainData.from_date
        : moment().format(ApiDateFormat);
      let endDate = mainData
        ? mainData.to_date
        : moment().format(ApiDateFormat);
      let clinicID = mainData ? mainData.clinic : 0;

      let payloadValue = {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
        clinicID: clinicID,
      };

      localStorage.setItem(
        "selectionRange-businessInsight",
        JSON.stringify(payloadValue),
      );

      window.open(`/business-insight/surveys/view-all`);
    }
  };

  render() {
    return (
      <div className="membership-title">
        {(this.props.parentState.totalRow ||
          this.props.parentState.grammar) && (
          <div
            className={
              this.props.parentState.report_type_sysname ===
              "percentage-surveys-returned"
                ? "col-sm-6 m-b-10"
                : "col-sm-12 m-b-10"
            }
          >
            {[28, "28", 29, "29", 30, "30", 31, "31"].indexOf(
              this.props.parentState.selected_report_type_id,
            ) === -1
              ? this.props.parentState.totalRow + " "
              : ""}
            {this.props.parentState.grammar}
          </div>
        )}
        {!this.props.parentState.showLoader && (
          <div className="memberRightActions">
            {this.props.parentState.reportListData &&
              this.props.parentState.reportListData.length > 0 &&
              this.props.parentState.report_type_sysname !==
                "percentage-surveys-returned" && (
                <a
                  onClick={() => this.props.downloadData()}
                  className="easy-link no-padding"
                >
                  <i className="fa fa-download m-r-5" />
                  {this.props.parentState.businessInsightLang.bi_download_excel}
                </a>
              )}

            {this.props.parentState.reportData &&
              ((this.props.parentState.reportData.clinic_score &&
                this.props.parentState.reportData.clinic_score.length > 0) ||
                (this.props.parentState.reportData.provider_score &&
                  this.props.parentState.reportData.provider_score.length >
                    0) ||
                (this.props.parentState.reportData.service_score &&
                  this.props.parentState.reportData.service_score.length >
                    0)) &&
              this.props.parentState.report_type_sysname ===
                "percentage-surveys-returned" && (
                <a
                  onClick={() => this.props.downloadData()}
                  className="easy-link no-padding"
                >
                  <i className="fa fa-download m-r-5" />
                  {this.props.parentState.businessInsightLang.bi_download_excel}
                </a>
              )}

            {this.props.parentState.reportType !== "view" && (
              <a
                className="easy-link no-padding"
                onClick={this.showCreateOptions}
              >
                <i className="fa fa-pencil-alt m-r-5" />{" "}
                {this.props.parentState.businessInsightLang.bi_edit}
              </a>
            )}
            {this.props.parentState.dataLoadedOnce === true &&
              this.props.parentState.reportType !== "view" && (
                <a className="new-blue-btn" onClick={this.saveReport}>
                  {this.props.parentState.businessInsightLang.bi_save_report}
                </a>
              )}

            {this.props.parentState.report_type_sysname ===
              "percentage-surveys-returned" && (
              <a className="new-blue-btn" onClick={() => this.viewAllSurveys()}>
                View All Surveys
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
}
