import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Button } from "../../../../../../shared/Button/Button";
import { svg } from "../../../../../../assets/svg";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { ReportHeader } from "../../../../components/ReportHeader/ReportHeader";
import { Modal } from "../../../../../../shared/Modal/Modal";
import classes from "../sass/Header.module.scss";
import { MonthRangeSwitch } from "../../../../../../shared/MonthRangeSwitch/MonthRangeSwitch";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";

const modalInfoRows = [
  "membershipOverviewReport.info.newMembers",
  "membershipOverviewReport.info.activeMemberships",
  "membershipOverviewReport.info.canceledMemberships",
  "membershipOverviewReport.info.nextAppt",
  "membershipOverviewReport.info.loyalty",
];

export function Header({
  isFilterActive,
  onFilterOpen,
  period,
  downloadExcel,
  isReportsLoading,
}) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader
      label={tBi("membershipOverviewReport.label")}
      left={
        <Button
          size="small"
          variant="text"
          onClick={() => setIsInfoModalOpen(true)}
        >
          <img src={svg.infoPrimary} alt="info" width="20px" />
        </Button>
      }
    >
      <MonthRangeSwitch
        isDisabled={isReportsLoading}
        startDate={period.value.from}
        endDate={period.value.to}
        dateFormat={user?.previewDateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
      />
      <Button
        size="small"
        variant="outlined"
        onClick={downloadExcel.initiate}
        isDisabled={downloadExcel.isLoading || isReportsLoading}
        leftAdornment={
          downloadExcel.isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <img src={svg.downloadPrimary} alt="dl" width="20px" />
          )
        }
      >
        {tCommon("label.downloadExcel")}
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={onFilterOpen}
        isDisabled={isReportsLoading}
        leftAdornment={
          isFilterActive ? (
            <img src={svg.filterPrimaryActive} alt="filter" width="16px" />
          ) : (
            <img src={svg.filterPrimary} alt="filter" width="14px" />
          )
        }
      >
        {tCommon("label.filter")}
      </Button>
      <Modal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        header={
          <Modal.Title>
            {tBi("membershipOverviewReport.info.title")}
          </Modal.Title>
        }
        contentClassName={classes.infoModalContent}
      >
        {modalInfoRows.map((key) => (
          <p key={key}>
            <Trans
              i18nKey={tBi(key)}
              components={{
                b: <b />,
              }}
            />
          </p>
        ))}
      </Modal>
    </ReportHeader>
  );
}

Header.propTypes = {
  isFilterActive: PropTypes.bool.isRequired,
  onFilterOpen: PropTypes.func.isRequired,
  period: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    update: PropTypes.func,
  }).isRequired,
  downloadExcel: PropTypes.shape({
    initiate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
  isReportsLoading: PropTypes.bool.isRequired,
};
