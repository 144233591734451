import { useQuestionnaireQuery } from "../../../../api/questionnaires/useQuestionnaireQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export function useQuestionnaire(questionnaireId) {
  const { tCommon } = useAppTranslation.Common();

  return useQuestionnaireQuery({
    payload: {
      id: questionnaireId,
    },
    options: {
      enabled: Boolean(questionnaireId),
      onError: () => {
        uiNotification.error(tCommon("error.fetchQuestionnaire"));
      },
    },
  });
}
