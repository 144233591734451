import { useMemo, useState } from "react";
import moment from "moment";
import { ORDER_TYPES } from "../../../../../../consts/api";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { hasLength } from "../../../../../../utilities/general";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useDebouncedState } from "../../../../../../utilities/hooks/useDebouncedState";
import { useShortTermLiability } from "./useShortTermLiability";

const prepareReqOrder = (orderState) => {
  if (Object.values(orderState).every(Boolean)) {
    return {
      by: orderState.by,
      direction: orderState.direction,
    };
  }
  return null;
};

const prepareReqPeriod = (periodState) => {
  return {
    from: periodState.from || null,
    to: periodState.to || null,
  };
};

export const useReports = () => {
  const { tCommon } = useAppTranslation.Common();

  /* Modifiers */

  const [search, debouncedSearch, setSearch] = useDebouncedState("");

  const [order, setOrder] = useState({
    by: "",
    direction: "",
  });

  const updateOrder = (orderFieldKey) => {
    setOrder({
      by: orderFieldKey,
      direction:
        order.direction === ORDER_TYPES.desc
          ? ORDER_TYPES.asc
          : ORDER_TYPES.desc,
    });
  };

  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: new Date(),
  });

  /* Data */

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useShortTermLiability({
    payload: {
      pageSize: 20,
      order: prepareReqOrder(order),
      period: prepareReqPeriod(period),
      searchTerm: debouncedSearch,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tCommon("error.getReports"));
      },
    },
  });

  const reports = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const totalLiability = data?.pages?.[0]?.totalLiability;

  /* Statuses */

  const isSoftLoading =
    (Object.values({
      ...order,
      ...period,
    }).some(hasLength) ||
      !!search) &&
    isFetching &&
    !isLoading &&
    !isFetchingNextPage;

  // ---------

  return {
    totalLiability,
    reports: {
      value: reports,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      isLoading: isLoading,
      fetchMore: fetchNextPage,
      isSoftLoading,
    },
    order: {
      value: order,
      update: updateOrder,
    },
    period: {
      value: period,
      update: setPeriod,
    },
    search: {
      value: search,
      onChange: (next) => setSearch(next),
    },
  };
};
