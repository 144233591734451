import React from "react";
import moment from "moment";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { DEFAULT_DATE_FORMAT } from "../../../../consts/general";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import styles from "./styles.module.scss";

export function OnFileParams({ date, userName }) {
  const { tCommon: t } = useAppTranslation.Common();

  const { data: user } = useCurrentUserQuery();

  return (
    <div className={styles.root}>
      <div>
        {user?.previewDateFormat &&
          `${t("label.date")}: ${moment(date).format(
            user?.previewDateFormat || DEFAULT_DATE_FORMAT,
          )}`}
      </div>
      <div>{`${t("label.by")}: ${userName}`}</div>
    </div>
  );
}
