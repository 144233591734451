export const BUTTON_VARIANT = {
  contained: "contained",
  text: "text",
};

export const VALUE_MAX_LEN = 21;

export const ERRORS = {
  noSpaces: {
    tip: "No spaces",
    message: "Descriptor cannot contain space characters",
  },
  maxLen: {
    tip: `Max length: ${VALUE_MAX_LEN}`,
    message: `Descriptor cannot be greater than ${VALUE_MAX_LEN} characters`,
  },
  notAllowedSymbols: {
    tip: "Allowed symbols: A-Z, 0-9, -",
    message: "Descriptor can have only following symbols: A-Z, 0-9, -",
  },
  required: {
    message: "Please provide descriptor value",
  },
};
