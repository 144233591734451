import React from "react";
import PropTypes from "prop-types";
import { LayoutMain } from "../../../../../boxes/LayoutMain/LayoutMain";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { tCommon, tSales } from "../../../../../i18n/useAppTranslation";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <LayoutMain>
          <LabeledBox label={tCommon("label.error")}>
            <AlertBox variant="error">
              {tSales("checkoutInvoice.error.unknown")}
            </AlertBox>
          </LabeledBox>
        </LayoutMain>
      );
    }

    return this.props.children;
  }
}
