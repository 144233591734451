/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useGetProviderTempaltesQuery } from "../../../../_legacy/Queries";
import { Button } from "../../../../shared/Button/Button";
import useAdvancedState from "../../../../utilities/hooks/useAdvancedState";
import classes from "../sass/schedule.module.scss";
import { truncateString } from "../../../../utilities/general";
import { usePermission } from "../../../../hooks/usePermission";
import { PERMISSIONS } from "../../../../consts/api";

export const ProviderTemplatesDropdown = ({
  providerId,
  selectTemplate,
  addNewTemplate,
  activeTemplate,
  selectedClinics = [],
}) => {
  const {
    state: { isMenuOpen },
    toggleField,
  } = useAdvancedState({
    isMenuOpen: false,
  });

  const { permitted: rescheduleAllowed } = usePermission(
    PERMISSIONS.appointments.manageProvidersSchedules,
  );

  const { data: providerTemplatesData, dataUpdatedAt } =
    useGetProviderTempaltesQuery({ id: providerId });

  const providerTemplates = useMemo(() => {
    if (selectedClinics.length) {
      return providerTemplatesData?.data?.data.filter(({ clinic_id }) =>
        selectedClinics.includes(clinic_id),
      );
    }

    return (
      providerTemplatesData?.data?.data?.filter(
        (templateData) => templateData.is_active,
      ) || []
    );
  }, [dataUpdatedAt, selectedClinics]);

  const selectActiveTemplate = (selectedTemplate) => {
    selectTemplate(selectedTemplate);
    toggleField("isMenuOpen");
  };

  return (
    <div className={classes.selectedTemplatesDropdown}>
      {providerTemplates.length === 0 && rescheduleAllowed && (
        <button
          onClick={() => {
            if (providerTemplates.length === 0) {
              addNewTemplate();
            } else {
              toggleField("isMenuOpen");
            }
          }}
          className={
            isMenuOpen
              ? "calendar-dropdown provider-clinic-filter show"
              : "calendar-dropdown provider-clinic-filter "
          }
        >
          {providerTemplates.length === 0 && "Add Template"}
          {providerTemplates.length > 0 && (
            <>
              {activeTemplate
                ? truncateString(activeTemplate.name)
                : "All Templates"}
              &nbsp;
              <a className="cal-arrow pull-right">
                <img
                  alt=""
                  src={
                    isMenuOpen ? "/images/cal-up.png" : "/images/cal-down.png"
                  }
                />
              </a>
            </>
          )}
        </button>
      )}
      {isMenuOpen && (
        <Dropdown
          id="provider-templates"
          open={isMenuOpen}
          onToggle={() => toggleField("isMenuOpen")}
        >
          <Dropdown.Toggle className="no-display" />
          <Dropdown.Menu className={classes.dropdownMenu}>
            <div className={classes.dropdownContent}>
              {activeTemplate?.id && providerTemplates.length > 1 && (
                <div
                  className={classes.templateName}
                  onClick={() => selectActiveTemplate(null)}
                >
                  All Templates
                </div>
              )}
              {providerTemplates?.map(({ name, id }) => {
                return (
                  <div
                    className={classes.templateName}
                    onClick={() => selectActiveTemplate({ name, id })}
                    key={`template-${id}`}
                  >
                    {truncateString(name)}
                  </div>
                );
              })}
            </div>
            {rescheduleAllowed && (
              <div className={classes.dropdownContent}>
                <Button
                  className={classes.addTemplateButton}
                  fullWidth
                  size="small"
                  onClick={() => {
                    addNewTemplate();
                    toggleField("isMenuOpen");
                  }}
                >
                  Add Template
                </Button>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};
