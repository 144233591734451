import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TemplateSelector } from "./templates/TemplateSelector/TemplateSelector";
import { NoteDisplay } from "./shared/NoteDisplay";
import { transcribeAudio, formatNote } from "./utils/Transcribe";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { MicMuteIcon } from "../../assets/Icons/MicMuteIcon";
import { MicIcon } from "../../assets/Icons/MicIcon";
import { ThunderIcon } from "../../assets/Icons/ThunderIcon";
import classes from "./Dictation.module.scss";

export function Dictation({ value, onChange, twilioTranscription }) {
  const { tCommon } = useAppTranslation.Common();
  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [formattedNote, setFormattedNote] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isGeneratingNote, setIsGeneratingNote] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [isChartSmartOpen, setIsChartSmartOpen] = useState(false);
  const [customPrompt] = useState("");

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      setTranscription("");

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: "audio/webm" });
        try {
          setIsTranscribing(true);
          const text = await transcribeAudio(audioBlob);
          setTranscription(text);
          if (onChange) {
            onChange(text);
          }
        } catch (error) {
          alert(tCommon("error.transcriptionFailed"));
        } finally {
          setIsTranscribing(false);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      alert(tCommon("error.audioNotSupported"));
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      setIsRecording(false);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const fullTranscription = [value, transcription, twilioTranscription]
    .filter(Boolean)
    .join(" ")
    .trim();

  const preparedPreviewText = fullTranscription;

  const handleTemplateSelection = async (template) => {
    if (!fullTranscription) {
      alert(tCommon("dictation.message.pleaseRecordFirst"));
      return;
    }
    setSelectedTemplate(template);
    setIsGeneratingNote(true);

    try {
      const formattedText = await formatNote(
        fullTranscription,
        template.customPrompt || customPrompt,
      );
      setFormattedNote(formattedText);
    } catch (error) {
      alert(tCommon("error.noteGenerationFailed"));
    } finally {
      setIsGeneratingNote(false);
    }
  };

  const handleSaveNote = () => {
    if (formattedNote && onChange) {
      onChange(formattedNote);

      setFormattedNote("");
      setSelectedTemplate(null);
      setIsChartSmartOpen(false);
    }
  };

  const handleCancel = () => {
    setFormattedNote("");
    setSelectedTemplate(null);
  };

  const handleChartSmartClick = () => {
    setIsChartSmartOpen(!isChartSmartOpen);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div className={classes.backIcon} />
        </div>
      </div>

      <div className={classes.mainContent}>
        <div className={classes.buttonGroup}>
          <Button
            variant="outlined"
            color={isRecording ? "error" : undefined}
            leftAdornment={isRecording ? <MicMuteIcon /> : <MicIcon />}
            onClick={toggleRecording}
            disabled={isGeneratingNote || isTranscribing}
            className={classes.recordButton}
          >
            {isRecording
              ? tCommon("dictation.button.stop")
              : tCommon("dictation.button.start")}
          </Button>

          <Button
            variant="outlined"
            leftAdornment={<ThunderIcon />}
            className={classes.chartSmartButton}
            onClick={handleChartSmartClick}
          >
            {tCommon("dictation.button.chartSmart")}
          </Button>
        </div>

        <div className={classes.content}>
          {preparedPreviewText ? (
            <div className={classes.valueWrap}>
              {isRecording && (
                <div className={clsx(classes.dot, classes.mt5)} />
              )}
              {preparedPreviewText}
            </div>
          ) : (
            <div>
              {isRecording ? (
                <div className={classes.placeholderRecording}>
                  <div className={classes.dot} />
                  <div>{tCommon("dictation.label.recording")}...</div>
                </div>
              ) : (
                <div className={classes.placeholder}></div>
              )}
            </div>
          )}
        </div>

        {isChartSmartOpen && (
          <TemplateSelector
            onTemplateSelect={(template) => {
              handleTemplateSelection(template);
              setIsChartSmartOpen(false);
            }}
            onClose={() => setIsChartSmartOpen(false)}
          />
        )}

        {formattedNote && (
          <div className={classes.noteSection}>
            <h2 className={classes.noteTitle}>
              {selectedTemplate?.label ||
                tCommon("dictation.label.formattedNote")}
            </h2>
            <NoteDisplay note={formattedNote} />

            <div className={classes.noteActions}>
              <Button
                variant="contained"
                onClick={handleSaveNote}
                className={classes.saveButton}
              >
                {tCommon("button.save")}
              </Button>
              <Button
                variant="contained"
                onClick={handleCancel}
                className={classes.cancelButton}
              >
                {tCommon("button.cancel")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

Dictation.propTypes = {
  value: PropTypes.string,
  twilioTranscription: PropTypes.string,
  onChange: PropTypes.func,
};
