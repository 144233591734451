/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AppointmentConfigNavbar from "./AppointmentConfigNavbar.js";
import ProviderSchedule from "./ProviderSchedule/ProviderSchedule.js";

class AppointmentConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerId: undefined,
      mode: "ProviderSchedule",
      selectedClinics: [],
    };
  }

  static getDerivedStateFromProps() {
    return null;
  }

  openSchedule = (id, name) => {
    import("./ProviderSchedule/ProviderScheduleView.js").then((component) => {
      this.setState({
        ProviderScheduleView: component.default,
        showSchedule: true,
        showSchedulePop: true,
        showScheduleDelete: false,
        providerId: id,
        providerName: name,
        mode: "ProviderScheduleView",
      });
    });
  };

  openDeleteSchedule = (id) => {
    import("./ProviderSchedule/ProviderScheduleDelete.js").then((component) => {
      this.setState({
        ProviderScheduleDelete: component.default,
        showSchedulePop: true,
        showSchedule: false,
        showScheduleDelete: true,
        providerId: id,
        mode: "ProviderScheduleDelete",
      });
    });
  };

  backToProviderSchedule = () => {
    this.setState({
      showSchedulePop: true,
      showSchedule: true,
      showScheduleDelete: false,
      providerId: this.state.providerId,
      providerName: this.state.providerName,
    });
  };

  componentDidUpdate = () => {
    if (this.state.showSchedulePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  closeModal = () => {
    if (this.state.showScheduleDelete) {
      this.setState({
        showSchedule: true,
        showScheduleDelete: false,
        mode: "ProviderScheduleView",
      });
    } else {
      this.setState({
        showSchedulePop: false,
        ProviderScheduleView: undefined,
        ProviderScheduleDelete: undefined,
        mode: "ProviderSchedule",
      });
    }
  };

  updateSelectedClinics = (selectedClinics) => {
    this.setState({ selectedClinics });
  };

  render() {
    const { ProviderScheduleView, ProviderScheduleDelete } = this.state;
    return (
      <div id="content">
        <div className="container-fluid content">
          <AppointmentConfigNavbar configActive={true} />
          {this.state.mode == "ProviderSchedule" && (
            <ProviderSchedule scheduleView={this.openSchedule} />
          )}
        </div>

        <div
          className={
            this.state.showSchedulePop
              ? "modalOverlay allFilters providerCal scroll-y"
              : "modalOverlay allFilters providerCal no-display scroll-y"
          }
        >
          <div className="small-popup-outer privider-calender-popup">
            <div className="small-popup-header">
              <div className="popup-name">
                Provider Schedule - {this.state.providerName}
              </div>
              <a onClick={this.closeModal} className="small-cross">
                ×
              </a>
            </div>
            <div className="privider-calender-content">
              {ProviderScheduleView && this.state.showSchedule && (
                <ProviderScheduleView
                  providerId={this.state.providerId}
                  openDeleteSchedule={this.openDeleteSchedule}
                  updateSelectedClinicsIds={this.updateSelectedClinics}
                />
              )}
              {ProviderScheduleDelete && this.state.showScheduleDelete && (
                <ProviderScheduleDelete
                  providerId={this.state.providerId}
                  backToProviderSchedule={this.backToProviderSchedule}
                  selectedClinics={this.state.selectedClinics}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppointmentConfig));
