import { usePatientVitalNotesMutation } from "../../../../../../../../api/patientVitals/usePatientVitalNotesMutation";
import { useCurrentUserQuery } from "../../../../../../../../api/queries/useUserQuery";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { patientVitals } from "../../../../../../../../store/patients/vitals";
import { createMutationRecordNote } from "../../../../../../../../store/patients/vitals/utilities";
import { dispatch } from "../../../../../../../../store/store";

const { actions } = patientVitals;

export const useAddNote = (vitalId) => {
  const { tClients } = useAppTranslation.Clients();
  const { data: user } = useCurrentUserQuery();

  const { mutateAsync, isLoading } = usePatientVitalNotesMutation({
    options: {
      onError: () => {
        uiNotification.error(tClients("vitals.error.saveNote"));
      },
    },
  });

  const initiate = (note) => {
    if (vitalId) {
      return mutateAsync({ vitalId, note });
    }
    return new Promise((res) => {
      dispatch(
        actions.addMutationRecordNote(
          createMutationRecordNote({
            note,
            author: user?.fullName,
          }),
        ),
      );
      res();
    });
  };

  return {
    initiate,
    isLoading,
  };
};
