import {
  IS_NONE_IMAGES_REQUIRED,
  IS_ONE_IMAGE_REQUIRED,
  REQUIRED_IMAGE_FORM_FIELDS,
} from "./config";

export function validateImages(formValues) {
  const images = [
    formValues.frontImage,
    formValues.image45,
    formValues.image45Left,
    formValues.image45Right,
    formValues.image90,
    formValues.image90Left,
    formValues.image90Right,
    formValues.afterFrontImage,
    formValues.afterImage45Left,
    formValues.afterImage45Right,
    formValues.afterImage90Left,
    formValues.afterImage90Right,
    formValues.backImage,
    formValues.back45LeftImage,
    formValues.back45RightImage,
    formValues.afterBackImage,
    formValues.afterBack45LeftImage,
    formValues.afterBack45RightImage,
  ];

  const procedureArea = formValues.procedureArea;

  if (IS_NONE_IMAGES_REQUIRED(procedureArea)) {
    return;
  }

  if (IS_ONE_IMAGE_REQUIRED(procedureArea)) {
    if (!images.some(Boolean)) {
      return "createEditCosmeticProcedure.error.imagesOneRequred";
    }
  } else if (
    !REQUIRED_IMAGE_FORM_FIELDS.map((x) => formValues[x]).every(Boolean)
  ) {
    return "createEditCosmeticProcedure.error.imagesThreeRequired";
  }
}
