import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  title: yup.string(),
});

const responseSchema = yup
  .array()
  .of(
    yup.object({
      id: yup.string().required(),
      title: yup.string().required(),
    }),
  )
  .required();

const composeResponse = (res) => {
  return res?.data?.data || [];
};

function createShopifyProductListQueryKey(...args) {
  return [QUERY_KEYS.shopifyProductList.search, ...args];
}

export function useShopifySearchProducts({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useQuery({
    queryKey: createShopifyProductListQueryKey(req.title),
    queryFn: async () => {
      if (!req.title || req.title.length < 3) {
        return [];
      }

      const res = await http.get(HTTP_ENDPOINTS.shopifyProductList.search(), {
        params: removeNullishFromShape({
          title: req.title,
        }),
      });

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    ...options,
  });
}
