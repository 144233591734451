import { usePatientVitalsSettingViewsMutation } from "../../../../../../../../api/patientVitals/usePatientVitalsSettingViewsMutation";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";

export const useSubmit = () => {
  const { tClients } = useAppTranslation.Clients();

  const { mutateAsync, isLoading } = usePatientVitalsSettingViewsMutation({
    onError: () => {
      uiNotification.error(tClients("vitals.settings.error.save"));
    },
    onSuccess: () => {
      uiNotification.success(tClients("vitals.settings.success.save"));
    },
  });

  const initiate = async (names = []) => {
    if (names.length === 0) {
      return uiNotification.error(
        tClients("vitals.settings.error.vitalNamesRequired"),
      );
    }

    return mutateAsync({ names });
  };

  return {
    initiate,
    isLoading,
  };
};
