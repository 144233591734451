import React, { useEffect } from "react";
import * as yup from "yup";
import { Trans } from "react-i18next";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { tCommon, tSales } from "../../../../../../../i18n/useAppTranslation";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { formatCurrency } from "../../../../../../../utilities/general";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import classes from "../ApplyGiftAmount.module.scss";
import RemainingBalanceDue from "../../../../shared/RemainingBalanceDue/RemainingBalanceDue";
import ViewTipsModal from "../../../ViewTipsModal/ViewTipsModal";
import NoTipsInfo from "../../../ViewTipsModal/components/NoTipsInfo";
import { composeAmountSchema } from "../../../../SalesCheckoutInvoice.schemas";
import { useGiftCardActions } from "../../../../hooks/useGiftCardActions";
import { useChargeForm } from "../../../../hooks/useChargeForm";

const PaymentFormModal = ({ giftCard, isTipsApplying, maxGiftcardAmount }) => {
  const { paymentViewClose } = useInvoiceActions();
  const { applyGiftCard } = useGiftCardActions();
  const { invoice, amountToPay } = useInvoice();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: Math.min(amountToPay, maxGiftcardAmount),
      currency: invoice?.currency,
    }),
  });

  const isLoading = applyGiftCard.isLoading || isTipsApplying;

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        applyGiftCard.initiate({
          redeemAmount: amount,
          giftCardId: giftCard.id,
          redemptionCode: giftCard.redemptionCode,
          giftCardAvailableAmount: giftCard.availableAmount,
        });
      },
      initialValues: {
        amount: Math.min(amountToPay, maxGiftcardAmount),
      },
    });

  const maxAmountToPay = Math.min(maxGiftcardAmount, amountToPay);

  useEffect(() => {
    changeFormValue("amount", maxAmountToPay);
  }, [maxAmountToPay, amountToPay]);

  return (
    <>
      <ModalCharge.Content>
        <ViewTipsModal amountToPay={maxAmountToPay} form={form} />
        <NoTipsInfo method="giftCard" />
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <div className={classes.giftAmount}>
          <Trans
            i18nKey={tSales("checkoutInvoice.giftCard.availableAmount")}
            values={{
              amount: formatCurrency(
                giftCard.availableAmount,
                invoice?.currency,
              ),
            }}
            components={{
              b: <b />,
            }}
          />
          <br />
          <br />
          <Trans i18nKey={tSales("checkoutInvoice.giftCard.consumerNotice")} />
        </div>
      </ModalCharge.Content>
      <RemainingBalanceDue amount={form.amount} />
      <ModalCharge.Footer
        onClose={paymentViewClose}
        isCharging={isLoading}
        onCharge={submit}
        chargeLabel={tCommon("label.redeem")}
        amount={form.amount}
        isChargeDisabled={!isValid || isLoading}
      />{" "}
    </>
  );
};

export default PaymentFormModal;
