import React from "react";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

export function useTableData(surveys, selectHandlers) {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();

  const {
    onToggleOne,
    onToggleAll,
    isSelected,
    isSelectedAll,
    isSelectedPartial,
  } = selectHandlers;

  const cols = [
    {
      data: (
        <Checkbox
          inverted
          size="small"
          isChecked={isSelectedAll()}
          isIntermediate={isSelectedPartial()}
          onChange={onToggleAll}
        />
      ),
      accessor: "checkbox",
    },
    {
      data: tCommon("label.title"),
      accessor: "title",
    },
    {
      data: tSettings("deletedSurveys.table.whenToSend"),
      accessor: "whenToSend",
    },
  ];

  const data = surveys.map((s) => ({
    key: s.id,
    title: s.title || tCommon("symbol.longDash"),
    whenToSend: s.whenToSend || tCommon("symbol.longDash"),
    checkbox: (
      <Checkbox
        size="small"
        isChecked={isSelected(s.id)}
        onChange={() => onToggleOne(s.id)}
      />
    ),
  }));

  return {
    cols,
    data,
  };
}
