/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

export default class AppointmentTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBreadCrumb: props.showBreadCrumb ? props.showBreadCrumb : false,
      hasData: props.hasData ? props.hasData : false,
      showSearch: props.showSearch ? props.showSearch : false,
      searchTerm: props.searchTerm ? props.searchTerm : "",
      reportMode: props.reportMode,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.searchTerm !== undefined) {
      returnState.searchTerm = nextProps.searchTerm;
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let name = event.target.name;

    this.props.handleChildState({ [name]: value });
  };

  redirectToMain = () => {
    return (
      <div>{this.props.history.push(`/business-insight/appointments`)}</div>
    );
  };

  render() {
    return (
      <div className="setting-setion m-b-10">
        <div className="membership-title">
          {this.props.showBreadCrumb === true ? (
            <span className="cursor-pointer" onClick={this.redirectToMain}>
              {this.props.globalLang.header_appointments}
            </span>
          ) : (
            this.props.globalLang.header_appointments
          )}
          {this.state.showBreadCrumb === true && (
            <span className="breadCrumb">
              <i className="fa fa-chevron-right" />{" "}
              <span className="breadCrumb-text">
                {this.props.businessInsightLang.bi_create_report}
              </span>
            </span>
          )}
          {(this.state.hasData === true ||
            this.state.reportMode === "view") && (
            <div className="pull-right">
              <a
                onClick={() => this.props.gotToNext()}
                className="new-blue-btn"
              >
                {this.props.businessInsightLang.bi_create_report}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
