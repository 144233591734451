import { useQuery } from "@tanstack/react-query";
import { getAccountId } from "../../utilities/localStorage";
import {
  CLIENT_GLOBAL_NAME,
  HTTP_ENDPOINTS,
  QUERY_KEYS,
} from "../../consts/api";
import { i18nHttp } from "./base";

export function useI18nAccountSettings() {
  var accountId = Number(getAccountId());

  var { data } = useQuery(
    [QUERY_KEYS.i18nAccountSettings, accountId],
    () => i18nHttp.get(HTTP_ENDPOINTS.getAccount(accountId)),
    {
      enabled: !!accountId,
      staleTime: 1000 * 60 * 10,
      cacheTime: 1000 * 60 * 10,
    },
  );

  var prefs = data?.data?.data?.account_preference || {};

  return {
    clientGlobalName:
      prefs.client_replacement_text || CLIENT_GLOBAL_NAME.patient,
  };
}
