import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

const responseSchema = yup.object({
  fileName: yup.string().nullable(),
});

const composeResponse = (res) => {
  return {
    fileName: res?.data?.data?.file || null,
  };
};

export function useClientWalletLogExportMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    const res = await http.get(
      HTTP_ENDPOINTS.getClientWalletLogExport(req.clientId),
    );
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
