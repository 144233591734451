import { FILE_EXTENSIONS } from "../../consts/general";

export const SUPPORTED_EXTENSIONS = [FILE_EXTENSIONS.docx, FILE_EXTENSIONS.pdf];

export const SUPPORTED_OUTPUTS = {
  text: "text",
  html: "html",
};

export const TABLET_BREAKPOINT = 800;

export const EDITOR_FILES_ACTIONS = {
  setFile: "setFile",
  removeFile: "removeFile",
  updateFile: "updateFile",
  removeAll: "removeAll",
};

export const ASYNC_FILE_SELECT_DELAY = 500;
